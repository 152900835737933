import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import ActiveDirectory from "../ActiveDirectory/component";
import Amplify from "../Amplify/component";

import * as config from '../../config'

class Auth extends Component {
    getAuth() {
        //console.log('authx', config.system.authMethod)
        return config.system.authMethod === 'AD'
        ? <ActiveDirectory />
        : <Amplify /> 
    }
    render() {
        return (this.getAuth())
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.amplify
        }
    }
}


export default connect(mapStateToProps, null)(Auth)
