import * as actions from './actions'
import * as types from './types'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as requestTypes from '../Request/types'
import * as config from '../../config'
import * as profileActions from '../Profile/actions'

export const getUserAccountsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_USER_ACCOUNTS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/query?cognito_key=' + store.value.user.cognito_key, //.cognito.authenticateUser.result.idToken.payload.sub, //payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'user_team.team.team_client_business_customer.client',
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setUserAccounts(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query?cognito_key=' + store.value.user.cognito_key) // payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query?cognito_key=' + store.value.user.cognito_key, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) => {
            //console.log('#################################################################################################',error)
        }
           // of$(routerActions.route('/support', false, error))
        )
        )


export const updateUserAccountDefaultSetting = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.UPDATE_USER_ACCOUNT_DEFAULT_SETTINGS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap((result) => {
                    //console.log("OOOOO",payload)
                    return(
                        [
                            actions.setSelectedAccount(payload.account),
                            actions.updateUserAccountDefaultSettingDone(),
                            requestActions.requestSucceeded(config.system.api + '/components/user/other')
                        ]
                    )
                }
                    
                )
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        ,catchError((error) => {
            //console.log('#################################################################################################',error)
        }
           // of$(routerActions.route('/support', false, error))
        )
        )

export const updateOrganisationSettings = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.UPDATE_ORGANISATION_SETTINGS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap((result) => {
                    return(
                        [
                            actions.updateUserAccountDefaultSettingDone(result),
                            requestActions.requestSucceeded(config.system.api + '/components/user/other')
                        ]
                    )
                }
                    
                )
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        ,catchError((error) => {
            //console.log('#################################################################################################',error)
        }
            // of$(routerActions.route('/support', false, error))
        )
        )
        
export const getLocaleList = ( action$,  store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
  ofType(types.CLIENTS_GET_LOCALE_LIST),
  mergeMap(() =>
    ajax$({
      url: config.system.api + "/components/locale/query",
      method: "GET",
      headers: {
        tokenx: store.value.user.user_id,
        auth: store.value.cognito.authenticateUser.token,
        salt: store.value.cognito.authenticateUser.jwt,
        datetime: store.value.cognito.authenticateUser.datetime,
      },
    }).pipe(
      mergeMap((payload) => [
        actions.setLocaleList(payload.response),
        requestActions.requestSucceeded(config.system.api + "/components/client" ),]),
      startWith(progressBarActions.progress(true)),
      catchError((error) => of$( requestActions.requestFailed( config.system.api + "/components/client", error ) ) )//,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
  ),
  catchError((error) => of$(routerActions.route("/support", false, error)))
);
