import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_QUOTEEDIT_ACCESSORY_LIST_LOOKUP_DATA:
            return {
                ...state,
                accessoryList : action.payload.data
            }
        default:
            return state
    }
}