import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'

import Button from '@mui/material/Button'
// import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Dropzone from 'react-dropzone'

import { Table } from '@mui/material'
import Iframe from 'react-iframe'

import IconButton from '@mui/material/IconButton'
import WorkflowQuoteItemGrid from '../WorkflowQuoteItemGrid/component'
import Upload from '../Upload/component'
import WorkflowQuoteView from '../WorkflowQuoteView/component'
import QuoteHeader from '../QuoteHeader/component'
import Icon from '../Icon/component'

import Card from '../../controls/card'
import Fields from '../../controls/cardfields'
import TextField from '../../controls/textField'
import DateControl from '../../controls/dateControl'
import AutoComplete from '../../controls/autocomplete'
import Section from '../../controls/section'
import * as styles from './styles'
import * as actions from './actions'
import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'
import { CardHeader } from '@mui/material'

class WorkflowQuoteHeader extends Component {
    componentDidMount() {
        this.props.actions.setInputValue({ prop: 'bulkreference', value: null })
        if (this.props.state.user.demouser) {
            const customer = this.props.state.customer.selected[0].original
            this.props.actions.getCustomerDerivativeLookupValue(
                customer.customer_id,
                'RT57'
            )
            this.props.actions.setInputValue({ prop: 'grid_data', value: [] })
        } else {
            this.props.actions.resetComponent()
            this.props.actions.setValidationState('workflowQuoteHeader')
            this.props.actions.setInputValue({ prop: 'grid_data', value: [] })
        }
    }

    componentWillUnmount() {
        this.props.actions.setValidationState(null)
    }

    getForm(component) {
        const customer = this.props.state.customer.selected[0].original

        //console.log('getWorkflowFLMTransnet customer',customer)

        return (
            <div>
                {
                    <Container style={{ margin: 0 }} className='custom-container'>
                        <Row>
                            <Col xl={12}>
                                <Section
                                    title={'Quotation Parameters'}
                                    subtitle={'Complete quotation parameters'}
                                />
                            </Col>
                            <Col xl={8}>
                                <Container style={{ margin: 0 }} className='custom-container'>
                                    <Row>
                                        <Col xl={12}>
                                            <br />
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '-5px' }}>
                                                <AutoComplete
                                                    label={'Select Contract Type'}
                                                    listStyle={{ maxHeight: 400 }}
                                                    id={'dll_contract_Type'}
                                                    key={'dll_contract_Type'}
                                                    hintText={'Select Customer Contract Type'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    fieldvalue={'customerderivative_id'}
                                                    displayfields={[
                                                        'contractname',
                                                        'contractdescription'
                                                    ]}
                                                    displaywidth={['20', '80']}
                                                    value={this.props.state.contractname}
                                                    dataSource={this.props.state.customerderivative_data}
                                                    onSelect={(args, index) => {
                                                        //console.log('args customer',args)

                                                        this.props.actions.setInputValue({
                                                            prop: 'grid_data',
                                                            value: []
                                                        })

                                                        this.props.actions.setInputValue({
                                                            prop: 'customerderivative_id',
                                                            value: args.value
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'contractname',
                                                            value: args.text
                                                        })
                                                        let _customerderivative =
                                                            this.props.state.customerderivative_data.filter(
                                                                x =>
                                                                    x.customerderivative_id ===
                                                                    args.value
                                                            )
                                                        _customerderivative = _customerderivative.length
                                                            ? _customerderivative[0]
                                                            : {}
                                                        this.props.actions.getContractTypeDetails(
                                                            args.value
                                                        )
                                                        //console.log('argsxx _customerderivative',_customerderivative)
                                                        this.props.actions.setInputValue({
                                                            prop: 'customerderivative',
                                                            value: _customerderivative
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'financert57Pricing',
                                                            value: _customerderivative.financert57Pricing
                                                                ? true
                                                                : false
                                                        })

                                                        let contractdistance =
                                                            parseInt(
                                                                _customerderivative.perioddefault
                                                                    ? _customerderivative.perioddefault
                                                                    : 0
                                                            ) *
                                                            parseInt(
                                                                _customerderivative.distancedefaultpetrol
                                                                    ? _customerderivative.distancedefaultpetrol
                                                                    : 0
                                                            )
                                                        this.props.actions.setInputValue({
                                                            prop: 'customerderivativecategory_data',
                                                            value: []
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'distancepermonth',
                                                            value: _customerderivative.distancedefaultpetrol
                                                                ? parseInt(
                                                                    _customerderivative.distancedefaultpetrol
                                                                )
                                                                : ''
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'contractperiod',
                                                            value: _customerderivative.perioddefault
                                                                ? parseInt(_customerderivative.perioddefault)
                                                                : ''
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'contractdistance',
                                                            value: contractdistance ? contractdistance : ''
                                                        })

                                                        this.props.actions.getCustomerDerivativeCategoryMetaData(
                                                            {
                                                                model: 'customerderivativecategorymake',
                                                                value: 0
                                                            }
                                                        )
                                                        this.props.actions.getCustomerDerivativeCategoryMetaData(
                                                            {
                                                                model: 'customerderivativecategorymmcode',
                                                                value: 0
                                                            }
                                                        )

                                                        this.props.actions.setInputValue({
                                                            prop: 'ratecard_id',
                                                            value: null
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'ratecard',
                                                            value: null
                                                        })

                                                        this.props.actions.setInputValue({
                                                            prop: 'grid_data',
                                                            value: []
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'customerderivativecategory_id',
                                                            value: null
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'customerderivativecategory',
                                                            value: null
                                                        })
                                                    }}
                                                    onClick={event => {
                                                        this.props.actions.getCustomerDerivativeLookupValue(
                                                            customer.customer_id,
                                                            ''
                                                        )
                                                        this.props.actions.setInputValue({
                                                            prop: 'interestratetype',
                                                            value: ''
                                                        })
                                                    }}
                                                    onFilter={(search, dataSource, params) => {
                                                        if (!search) {
                                                            this.props.actions.getCustomerDerivativeLookupValue(
                                                                customer.customer_id,
                                                                ''
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'interestratetype',
                                                                value: ''
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'customerderivative_id',
                                                                value: null
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'contractname',
                                                                value: ''
                                                            })
                                                        } else {
                                                            this.props.actions.getCustomerDerivativeLookupValue(
                                                                customer.customer_id,
                                                                search
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'contractname',
                                                                value: search
                                                            })
                                                        }

                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        {this.props.state.financert57Pricing ? (
                                            <Col xl={6}>
                                                <div style={{ marginLeft: '10px', marginTop: '-5px' }}>
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        popoverProps={{
                                                            style: { overflow: 'auto', width: 'auto' }
                                                        }}
                                                        id={'ratecard'}
                                                        key={'ratecard'}
                                                        hintText={'Select Vehicle Rate Card'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        label={'Vehicle Rate Card'}
                                                        data={this.props.state.contractvehiclecategory_data}
                                                        value={this.props.state.ratecard}
                                                        onSelect={args => {
                                                            this.props.actions.setInputValue({
                                                                prop: 'grid_data',
                                                                value: []
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'ratecard_id',
                                                                value: args.value
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'ratecard',
                                                                value: args.text
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'customerderivativecategory',
                                                                value: args.text
                                                            })

                                                            this.props.actions.getRateCard(
                                                                args.value,
                                                                this.props.state.distancepermonth
                                                            )
                                                        }}
                                                        onClick={() => {
                                                            if (this.props.state.distancepermonth)
                                                                this.props.actions.getLookupData(
                                                                    'contractvehiclecategory',
                                                                    'categorydescription',
                                                                    ''
                                                                )
                                                        }}
                                                        onFilter={(search) => {
                                                            if (search) {
                                                                if (this.props.state.distancepermonth) {
                                                                    this.props.actions.getLookupData(
                                                                        'contractvehiclecategory',
                                                                        'categorydescription',
                                                                        search
                                                                    )

                                                                    this.props.actions.setInputValue({
                                                                        prop: 'ratecard',
                                                                        value: search
                                                                    })

                                                                    this.props.actions.setInputValue({
                                                                        prop: 'customerderivativecategory',
                                                                        value: search
                                                                    })
                                                                }
                                                            } else {
                                                                if (this.props.state.distancepermonth) {
                                                                    this.props.actions.getLookupData(
                                                                        'contractvehiclecategory',
                                                                        'categorydescription',
                                                                        ''
                                                                    )
                                                                    this.props.actions.setInputValue({
                                                                        prop: 'ratecard',
                                                                        value: ''
                                                                    })
                                                                    this.props.actions.setInputValue({
                                                                        prop: 'ratecard_id',
                                                                        value: null
                                                                    })
                                                                    this.props.actions.setInputValue({
                                                                        prop: 'customerderivativecategory',
                                                                        value: ''
                                                                    })
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        ) : (
                                            <Col xl={6}>
                                                <div style={{ marginLeft: '10px', marginTop: '-5px' }}>
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400 }}
                                                        id={'dll_vehicle_category'}
                                                        key={'dll_vehicle_category'}
                                                        hintText={'Select Vehicle Category'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        label={'Vehicle Category'}
                                                        fieldvalue={'customerderivativecategory_id'}
                                                        displayfields={[
                                                            'customerderivativecategory',
                                                            'customerderivativecategorydescription'
                                                        ]}
                                                        displaywidth={['20', '80']}
                                                        value={this.props.state.customerderivativecategory}
                                                        dataSource={
                                                            this.props.state.customerderivativecategory_data
                                                        }
                                                        onSelect={(args, index) => {
                                                            this.props.actions.setInputValue({
                                                                prop: 'grid_data',
                                                                value: []
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'customerderivativecategory_id',
                                                                value: args.value
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'customerderivativecategory',
                                                                value: args.text
                                                            })

                                                            if (args.text == 'Bulk Quotation') {
                                                                this.props.actions.genBulkQuotation()
                                                            } else {
                                                                this.props.actions.setInputValue({
                                                                    prop: 'bulkreference',
                                                                    value: null
                                                                })
                                                            }

                                                            this.props.actions.getCustomerDerivativeCategoryMetaData(
                                                                {
                                                                    model: 'customerderivativecategorymake',
                                                                    value: args.value
                                                                }
                                                            )
                                                            this.props.actions.getCustomerDerivativeCategoryMetaData(
                                                                {
                                                                    model: 'customerderivativecategorymmcode',
                                                                    value: args.value
                                                                }
                                                            )
                                                        }}
                                                        onClick={event => {
                                                            this.props.actions.getCustomerDerivativeCategoryLookupValue(
                                                                this.props.state.customerderivative_id,
                                                                ''
                                                            )
                                                        }}
                                                        onFilter={(search, dataSource, params) => {
                                                            if (search) {
                                                                this.props.actions.getCustomerDerivativeCategoryLookupValue(
                                                                    this.props.state.customerderivative_id,
                                                                    search
                                                                )
                                                                this.props.actions.setInputValue({
                                                                    prop: 'customerderivativecategory',
                                                                    value: search
                                                                })
                                                            } else {
                                                                this.props.actions.getCustomerDerivativeCategoryLookupValue(
                                                                    this.props.state.customerderivative_id,
                                                                    ''
                                                                )
                                                                this.props.actions.setInputValue({
                                                                    prop: 'customerderivativecategory',
                                                                    value: ''
                                                                })
                                                                this.props.actions.setInputValue({
                                                                    prop: 'customerderivativecategory_id',
                                                                    value: null
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                        {this.props.state.customerderivativecategory ===
                                            'Bulk Quotation' ? (
                                            <>
                                                <Col xl={5}>
                                                    <div
                                                        style={{ marginLeft: '10px', marginTop: '-5px' }}
                                                    >
                                                        <TextField
                                                            variant='outlined'
                                                            id={'bulk_reference'}
                                                            label={'Bulk Reference'}
                                                            fullWidth={true}
                                                            value={this.props.state.bulkreference}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col xl={1}>
                                                    <Button
                                                        className={'global_button'}
                                                        variant='contained'
                                                        primary={true}
                                                        label='Add Vehicle'
                                                        icon={
                                                            <Icon
                                                                iclass={'material-icons'}
                                                                iname={'plus_one'}
                                                            />
                                                        }
                                                        onClick={event => {
                                                            this.props.actions.setInputValue({
                                                                prop: 'prev_bulkreference',
                                                                value: !this.props.state.prev_bulkreference
                                                            })
                                                        }}
                                                    >
                                                        Prev
                                                    </Button>
                                                </Col>
                                                <Col xl={6}>
                                                    {this.props.state.prev_bulkreference ? (
                                                        <div
                                                            style={{ marginLeft: '10px', marginTop: '-5px' }}
                                                        >
                                                            <AutoComplete
                                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                id={'bulkreference'}
                                                                key={'bulkreference'}
                                                                hintText={'Select Prev Bulk Reference'}
                                                                label={'Select Prev Bulk Reference'}
                                                                filter={AutoComplete.fuzzyFilter}
                                                                openOnFocus={true}
                                                                fullWidth={true}
                                                                data={this.props.state.bulkreference_data}
                                                                value={this.props.state.bulkreference}
                                                                onSelect={args => {
                                                                    //this.props.actions.setInputValue({prop: 'vehiclecategory_id', value: args.value})
                                                                    this.props.actions.setInputValue({
                                                                        prop: 'bulkreference',
                                                                        value: args.text
                                                                    })
                                                                    //this.props.actions.setTotalAdjustment()
                                                                }}
                                                                onClick={() => {
                                                                    this.props.actions.getLookupData(
                                                                        'bulkreference',
                                                                        'bulkreference',
                                                                        ''
                                                                    )
                                                                }}
                                                                onFilter={(search) => {
                                                                    if (search) {
                                                                        this.props.actions.getLookupData(
                                                                            'bulkreference',
                                                                            'bulkreference',
                                                                            search
                                                                        )
                                                                        this.props.actions.getLookupData(
                                                                            'bulkreference',
                                                                            'bulkreference',
                                                                            search
                                                                        )
                                                                    } else {
                                                                        this.props.actions.getLookupData(
                                                                            'bulkreference',
                                                                            'bulkreference',
                                                                            search
                                                                        )
                                                                        this.props.actions.getLookupData(
                                                                            'bulkreference',
                                                                            'bulkreference',
                                                                            ''
                                                                        )
                                                                    }

                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Col>
                                            </>
                                        ) : (
                                            ''
                                        )}

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '0px' }}>
                                                <TextField
                                                    variant='outlined'
                                                    id={'contractperiod'}
                                                    label={'Enter Contract Period'}
                                                    fullWidth={true}
                                                    value={this.props.state.contractperiod}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'contractperiod',
                                                            value: event.target.value
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'contractdistance',
                                                            value:
                                                                event.target.value *
                                                                this.props.state.distancepermonth
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '0px' }}>
                                                <TextField
                                                    variant='outlined'
                                                    id={'distancepermonth'}
                                                    label={'Enter Distance Per Month'}
                                                    fullWidth={true}
                                                    value={this.props.state.distancepermonth}
                                                    //hintText={component.data.usehours.input ? 'Hours per Month:' : 'Distance per Month:'}
                                                    //inputStyle={styles.confirmSelection}
                                                    //errorText={component.data.contractperiod.input ? null : '*'}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'distancepermonth',
                                                            value: event.target.value
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'contractdistance',
                                                            value:
                                                                this.props.state.contractperiod *
                                                                event.target.value
                                                        })
                                                        //this.props.actions.calcContractDistance(event.target.value, component.data.contractperiod.input, this.field.contractdistance)
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '0px' }}>
                                                <TextField
                                                    variant='outlined'
                                                    id={'contractdistance'}
                                                    label={'Enter Contract Distance'}
                                                    value={this.props.state.contractdistance}
                                                    fullWidth={true}
                                                    //inputStyle={styles.confirmSelection}
                                                    disabled={true}
                                                // onChange={(event, value) => {
                                                //     this.props.actions.setInputValue({prop: 'contractdistance', value: event.target.value})
                                                //     //this.props.actions.calcDistancePerMonth(event.target.value, component.data.contractperiod.input, this.field.distancepermonth)
                                                // }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '0px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'loadfactor'}
                                                    key={'loadfactor'}
                                                    hintText={'Select Load Factor'}
                                                    label={'Select Load Factor'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    data={this.props.state.vehiclecategory_data}
                                                    value={this.props.state.vehiclecategory}
                                                    onSelect={args => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'vehiclecategory_id',
                                                            value: args.value
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'vehiclecategory',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setTotalAdjustment()
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getLookupData(
                                                            'vehiclecategory',
                                                            'vehiclecategory',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={search => {
                                                        if (search) {
                                                            this.props.actions.getLookupData(
                                                                'vehiclecategory',
                                                                'vehiclecategory',
                                                                search
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'vehiclecategory',
                                                                value: search
                                                            })
                                                        } else {
                                                            this.props.actions.getLookupData(
                                                                'vehiclecategory',
                                                                'vehiclecategory',
                                                                ''
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'vehiclecategory_id',
                                                                value: null
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'vehiclecategory',
                                                                value: ''
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '0px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'vehiclegroup'}
                                                    key={'vehiclegroup'}
                                                    hintText={'Select Vehicle Group'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    label={'Vehicle Group'}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    value={this.props.state.vehiclegroup}
                                                    data={this.props.state.vehiclegroup_data}
                                                    onSelect={args => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'vehiclegroup',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'vehiclegroup_id',
                                                            value: args.value
                                                        })
                                                        this.props.actions.setTotalAdjustment()
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getLookupData(
                                                            'vehiclegroup',
                                                            'vehiclegroup',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={(search) => {
                                                        if (search) {
                                                            this.props.actions.getLookupData(
                                                                'vehiclegroup',
                                                                'vehiclegroup',
                                                                search
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'vehiclegroup',
                                                                value: search
                                                            })
                                                        } else {
                                                            this.props.actions.getLookupData(
                                                                'vehiclegroup',
                                                                'vehiclegroup',
                                                                ''
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'vehiclegroup',
                                                                value: ''
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'vehiclegroup_id',
                                                                value: null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '0px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'usage'}
                                                    key={'usage'}
                                                    hintText={'Select Vehicle Usage'}
                                                    label={'Usage'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    value={this.props.state.usage}
                                                    data={this.props.state.usage_data}
                                                    onSelect={args => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'usage',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'usage_id',
                                                            value: args.value
                                                        })
                                                        this.props.actions.setTotalAdjustment()
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getLookupData(
                                                            'usage',
                                                            'usage',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={(search) => {
                                                        if (search) {
                                                            this.props.actions.getLookupData(
                                                                'usage',
                                                                'usage',
                                                                search
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'usage',
                                                                value: search
                                                            })
                                                        } else {
                                                            this.props.actions.getLookupData(
                                                                'usage',
                                                                'usage',
                                                                '')
                                                            this.props.actions.setInputValue({
                                                                prop: 'usage',
                                                                value: ''
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'usage_id',
                                                                value: null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '-2px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'terrain'}
                                                    key={'terrain'}
                                                    hintText={'Select Vehicle Operating Terrain'}
                                                    label={'Terrain'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    value={this.props.state.terrain}
                                                    data={this.props.state.terrain_data}
                                                    onSelect={args => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'terrain',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'terrain_id',
                                                            value: args.value
                                                        })
                                                        this.props.actions.setTotalAdjustment()
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getLookupData(
                                                            'terrain',
                                                            'terrain',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={(search) => {
                                                        if (search) {
                                                            this.props.actions.getLookupData(
                                                                'terrain',
                                                                'terrain',
                                                                search
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'terrain',
                                                                value: search
                                                            })
                                                        } else {
                                                            this.props.actions.getLookupData(
                                                                'terrain',
                                                                'terrain',
                                                                ''
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'terrain',
                                                                value: ''
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'terrain_id',
                                                                value: null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '-2px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'specialistvehicle'}
                                                    key={'specialistvehicle'}
                                                    hintText={'Select Special List Vehicle'}
                                                    label={'Special List Vehicle'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    value={this.props.state.specialistvehicle}
                                                    data={this.props.state.specialistvehicle_data}
                                                    onSelect={args => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'specialistvehicle',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'specialistvehicle_id',
                                                            value: args.value
                                                        })
                                                        this.props.actions.setTotalAdjustment()
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getLookupData(
                                                            'specialistvehicle',
                                                            'specialistvehicle',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={(search) => {
                                                        if (search) {
                                                            this.props.actions.getLookupData(
                                                                'specialistvehicle',
                                                                'specialistvehicle',
                                                                search
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'specialistvehicle',
                                                                value: search
                                                            })
                                                        } else {
                                                            this.props.actions.getLookupData(
                                                                'specialistvehicle',
                                                                'specialistvehicle',
                                                                ''
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'specialistvehicle',
                                                                value: ''
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'specialistvehicle_id',
                                                                value: null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '-2px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'location'}
                                                    key={'location'}
                                                    hintText={'Select Vehicle Operating Location'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    label={'Location'}
                                                    value={this.props.state.location}
                                                    data={this.props.state.location_data}
                                                    onSelect={args => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'location',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'location_id',
                                                            value: args.value
                                                        })
                                                        this.props.actions.setTotalAdjustment()
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getLookupData(
                                                            'location',
                                                            'location',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={(search) => {
                                                        if (search) {
                                                            this.props.actions.getLookupData(
                                                                'location',
                                                                'location',
                                                                search
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'location',
                                                                value: search
                                                            })
                                                        } else {
                                                            this.props.actions.getLookupData(
                                                                'location',
                                                                'location',
                                                                ''
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'location',
                                                                value: ''
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'location_id',
                                                                value: null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '-2px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'color'}
                                                    key={'color'}
                                                    hintText={'Select Vehicle Color'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    label={'Vehicle Color'}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    value={this.props.state.color}
                                                    data={this.props.state.color_data}
                                                    onSelect={(args, index) => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'color',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'color_id',
                                                            value: args.value
                                                        })
                                                    }}
                                                    onClick={event => {
                                                        this.props.actions.getLookupData(
                                                            'color',
                                                            'color',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={(search, dataSource, params) => {
                                                        if (search) {
                                                            this.props.actions.getLookupData(
                                                                'color',
                                                                'color',
                                                                search
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'color',
                                                                value: search
                                                            })

                                                        } else {
                                                            this.props.actions.getLookupData(
                                                                'color',
                                                                'color',
                                                                ''
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'color',
                                                                value: ''
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'color_id',
                                                                value: null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xl={6}>
                                            <div style={{ marginLeft: '10px', marginTop: '-2px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'quotefor'}
                                                    key={'quotefor'}
                                                    hintText={'Select option from list'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    label={'Select Quote For'}
                                                    value={this.props.state.quotefor}
                                                    data={this.props.state.quotefor_data}
                                                    onSelect={args => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'quotefor',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'quotefor_id',
                                                            value: args.value
                                                        })
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getLookupData(
                                                            'quotefor',
                                                            'quotefor',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={(search) => {
                                                        if (search) {
                                                            this.props.actions.getLookupData(
                                                                'quotefor',
                                                                'quotefor',
                                                                search
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'quotefor',
                                                                value: search
                                                            })
                                                        } else {
                                                            this.props.actions.getLookupData(
                                                                'quotefor',
                                                                'quotefor',
                                                                ''
                                                            )
                                                            this.props.actions.setInputValue({
                                                                prop: 'quotefor',
                                                                value: ''
                                                            })
                                                            this.props.actions.setInputValue({
                                                                prop: 'quotefor_id',
                                                                value: null
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        {this.props.state.quotefor &&
                                            this.props.state.quotefor !== 'Additional Vehicle' && (
                                                <Col xl={6}>
                                                    <div
                                                        style={{ marginLeft: '10px', marginTop: '-2px' }}
                                                    >
                                                        <AutoComplete
                                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            id={'replace_vehicle'}
                                                            key={'replace_vehicle'}
                                                            hintText={'Select Replace Vehicle'}
                                                            label={'Replacement Vehicle'}
                                                            filter={AutoComplete.fuzzyFilter}
                                                            openOnFocus={true}
                                                            fullWidth={true}
                                                            value={this.props.state.licenseplateno}
                                                            data={this.props.state.vehicle_data}
                                                            onSelect={args => {
                                                                this.props.actions.setInputValue({
                                                                    prop: 'vehicle_id',
                                                                    value: args.value
                                                                })
                                                                this.props.actions.setInputValue({
                                                                    prop: 'licenseplateno',
                                                                    value: args.text
                                                                })
                                                            }}
                                                            onClick={() => {
                                                                this.props.actions.getLookupData(
                                                                    'vehicle',
                                                                    'licenseplateno',
                                                                    ''
                                                                )
                                                            }}
                                                            onFilter={(search) => {
                                                                if (search) {
                                                                    this.props.actions.getLookupData(
                                                                        'vehicle',
                                                                        'licenseplateno',
                                                                        search
                                                                    )
                                                                    this.props.actions.setInputValue({
                                                                        prop: 'licenseplateno',
                                                                        value: search
                                                                    })
                                                                } else {
                                                                    this.props.actions.getLookupData(
                                                                        'vehicle',
                                                                        'licenseplateno',
                                                                        ''
                                                                    )
                                                                    this.props.actions.setInputValue({
                                                                        prop: 'vehicle_id',
                                                                        value: null
                                                                    })
                                                                    this.props.actions.setInputValue({
                                                                        prop: 'licenseplateno',
                                                                        value: ''
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            )}
                                    </Row>
                                </Container>
                            </Col>
                            <Col xl={4}>
                                <Container style={{ margin: 0 }} className='custom-container'>
                                    <Row>
                                        <Card
                                            style={{ Color: '#D6E0EB' }}
                                            title={'Asset Selections for Quote'}
                                            subtitle={'Customer default values'}
                                            content={
                                                <div style={{ width: '100%', marginTop: '10px' }}>
                                                    <Fields
                                                        nocard={true}
                                                        leftalign={true}
                                                        nobold={true}
                                                        fields={[
                                                            {
                                                                size: 12,
                                                                label: 'Customer',
                                                                value: customer.customername
                                                            },
                                                            {
                                                                size: 12,
                                                                label: 'Cost Centre',
                                                                value: customer.costcentre
                                                            },
                                                            {
                                                                size: 12,
                                                                label: 'Account Number',
                                                                value: customer.accountnumber
                                                            }
                                                        ]}
                                                    />
                                                    <Row>
                                                        <Col xl={12}>
                                                            <br />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={12}>
                                                            <br />
                                                        </Col>
                                                    </Row>

                                                    <Fields
                                                        nocard={true}
                                                        leftalign={true}
                                                        nobold={true}
                                                        fields={[
                                                            {
                                                                size: 12,
                                                                label: 'Default Makes',
                                                                value:
                                                                    this.props.state.customerderivativecategorymake_data.map(
                                                                        x => {
                                                                            return x.vehiclemake + ', '
                                                                        }
                                                                    )
                                                            }
                                                        ]}
                                                    />
                                                    <Row>
                                                        <Col xl={12}>
                                                            <br />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={12}>
                                                            <br />
                                                        </Col>
                                                    </Row>
                                                    <Fields
                                                        nocard={true}
                                                        leftalign={true}
                                                        nobold={true}
                                                        fields={[
                                                            {
                                                                size: 12,
                                                                label: 'Default MMCode',
                                                                value:
                                                                    this.props.state.customerderivativecategorymmcode_data.map(
                                                                        x => {
                                                                            return x.vehiclemmcode + ', '
                                                                        }
                                                                    )
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            }
                                        />
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <Row>
                                    <Col xl={12}>
                                        <CardHeader
                                            style={{ backgroundColor: '#ff4545', color: 'white' }}
                                            actAsExpander={false}
                                            showExpandableButton={false}
                                            title={
                                                <div style={{ fontSize: '18px', color: 'white' }}>
                                                    {'Customer RFQ'}
                                                </div>
                                            }
                                            subheader={
                                                <div style={{ fontSize: '13px', color: 'white' }}>
                                                    {'Upload Customer RFQ Document'}
                                                </div>
                                            }
                                        ></CardHeader>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                            <Upload
                                                id={'upload_fml_customer_rfq'}
                                                prefix={'Customer_RFQ'}
                                                header={false}
                                                default={2}
                                                list={[
                                                    { value: 0, text: 'Customer RFQ' },
                                                    { value: 0, text: 'Customer PO' },
                                                    { value: 0, text: 'Supplier Quotation' }
                                                ]}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6}>
                                        <DateControl
                                            id={'dtRFQDate'}
                                            key={'dtRFQDate'}
                                            label={'Select Customer RFQ Date'}
                                            name={'dtRFQDate'}
                                            value={this.props.state.quotedate}
                                            onSelect={args => {
                                                this.props.actions.setInputValue({
                                                    prop: 'quotedate',
                                                    value: args
                                                })
                                            }}
                                            type={'date'}
                                        />
                                    </Col>
                                    <Col xl={6}>
                                        <div style={{ marginLeft: '10px', marginTop: '25px' }}>
                                            <TextField
                                                variant='outlined'
                                                id={'comments'}
                                                label={'Enter comments (Optional)'}
                                                value={this.props.state.comments}
                                                fullWidth={true}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue({
                                                        prop: 'comments',
                                                        value: event.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <Section
                                    title={'Quotation Notes'}
                                    subtitle={
                                        'Please review and annotate any relevant notes pertaining to the quote.'
                                    }
                                />
                            </Col>
                        </Row>
                        <Container style={{ margin: 0 }} className='custom-container'>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                                <Col xl={12}>
                                    <div style={{ marginLeft: '10px', marginTop: '-10px' }}>
                                        <TextField
                                            variant='outlined'
                                            id={'quotationnotes'}
                                            label={'Enter Notes'}
                                            fullWidth={true}
                                            multiline={true}
                                            rows={5}
                                            value={this.props.state.quotationnotes}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue({
                                                    prop: 'quotationnotes',
                                                    value: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Row>
                            <Col xl={12}>
                                <Row>
                                    <Col xl={12}>
                                        <Section
                                            title={'Payment Details'}
                                            subtitle={'Set Customer Payment Details'}
                                        />
                                    </Col>
                                </Row>

                                <Container style={{ margin: 0 }} className='custom-container'>
                                    <Row>
                                        <Col xl={4}>
                                            <div style={{ marginLeft: '16px', marginTop: '16px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'replace_vehicle'}
                                                    key={'replace_vehicle'}
                                                    hintText={'Payment Holiday'}
                                                    // filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    data={[
                                                        { text: 'Yes', value: '1' },
                                                        { text: 'No', value: '0' }
                                                    ]}
                                                    onSelect={args => { }}
                                                    onClick={() => { }}
                                                    onFilter={args => { }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={4}>
                                            <div style={{ marginLeft: '16px', marginTop: '16px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'replace_vehicle'}
                                                    key={'replace_vehicle'}
                                                    hintText={'Payment Type'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    // value={this.props.state.licenseplateno}
                                                    data={[
                                                        { text: 'Billing', value: '1' },
                                                        { text: 'EFT', value: '0' },
                                                        { text: 'Debit', value: '3' }
                                                    ]}
                                                    onSelect={args => { }}
                                                    onClick={() => { }}
                                                    onFilter={args => { }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={4}>
                                            <div style={{ marginLeft: '16px', marginTop: '16px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'replace_vehicle'}
                                                    key={'replace_vehicle'}
                                                    hintText={'Link Deal'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    value={this.props.state.contractnumber}
                                                    data={this.props.state.customercontract_data}
                                                    onSelect={args => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'customercontract_id',
                                                            value: args.value
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'contractnumber',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'linkdeal',
                                                            value: args.text
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'linkdeal_id',
                                                            value: args.value
                                                        })
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getLookupData(
                                                            'customercontract',
                                                            'contractnumber',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={args => {
                                                        this.props.actions.getLookupData(
                                                            'customercontract',
                                                            'contractnumber',
                                                            args
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={4}>
                                            <div style={{ marginLeft: '16px', marginTop: '16px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'interest_type'}
                                                    key={'interest_type'}
                                                    hintText={'Interest Rate Type'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    data={this.props.state.interestratetype_data}
                                                    value={this.props.state.interestratetype}
                                                    onSelect={args => {
                                                        this.props.actions.setInputValue({
                                                            prop: 'interestratetype_id',
                                                            value: args.value
                                                        })
                                                        this.props.actions.setInputValue({
                                                            prop: 'interestratetype',
                                                            value: args.text
                                                        })
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getLookupData(
                                                            'interestratetype',
                                                            'interestratetype',
                                                            ''
                                                        )
                                                    }}
                                                    onFilter={args => {
                                                        this.props.actions.getLookupData(
                                                            'interestratetype',
                                                            'interestratetype',
                                                            args
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                }
                {this.props.state.contractname ? (
                    <Container style={{ margin: 0 }} className='custom-container'>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        {!this.props.state.financert57Pricing && (
                            <Row>
                                <Col xl={6}>
                                    <Button
                                        className={'global_button'}
                                        variant='contained'
                                        primary={true}
                                        label='Add Vehicle'
                                        icon={<Icon iclass={'material-icons'} iname={'plus_one'} />}
                                        disabled={!this.props.state.grid_data.length > 1}
                                        onClick={event => {
                                            this.props.actions.addVehicleBlankRow(
                                                this.props.state.grid_data
                                            )
                                            //this.props.actions.setTotalAdjustment()
                                        }}
                                    >
                                        {window.t.en('Add Vehicle')}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            {this.props.state.grid_data.length
                                ? this.props.state.grid_data.map((x, i) => {
                                    return (
                                        <Col
                                            xl={
                                                12 /
                                                (this.props.state.grid_data.length === 1
                                                    ? 2
                                                    : this.props.state.grid_data.length)
                                            }
                                        >
                                            <QuoteHeader
                                                id={'quote_header_' + i}
                                                inx={i}
                                                grid={x}
                                                rt57={this.props.state.financert57Pricing}
                                                mmcodes={
                                                    this.props.state
                                                        .customerderivativecategorymmcode_data
                                                }
                                                makes={
                                                    this.props.state.customerderivativecategorymake_data
                                                }
                                                customer={
                                                    this.props.state.customer.selected[0].original
                                                }
                                                distancepermonth={this.props.state.distancepermonth}
                                                contractperiod={this.props.state.contractperiod}
                                                name={this.props.name}
                                            />
                                        </Col>
                                    )
                                })
                                : ''}
                        </Row>
                    </Container>
                ) : (
                    ''
                )}
            </div>
        )
    }

    getSecondaryAssets() {
        return (
            <div>
                <Button
                    className={'global_button'}
                    variant='contained'
                    label={'Add Item'}
                    primary={true}
                    onClick={event => {
                        this.props.actions.addSubAssetBlankRow()
                    }}
                >
                    {window.t.en('Add Asset')}
                </Button>

                <div className='cis-table1'>
                    <div className='table-responsive-md' id='cis-table1'></div>
                    <Table className='table table-borderless  mb-0'>
                        <thead>
                            <tr>
                                <th
                                    style={{ width: '25px' }}
                                    className='text-uppercase bg-secondary'
                                ></th>
                                <th className='text-uppercase bg-secondary'>{'Assets'}</th>
                                <th className='text-uppercase bg-secondary'>{'Description'}</th>
                                <th className='text-uppercase bg-secondary'>
                                    {'Retail Price'}
                                </th>
                                <th className='text-uppercase bg-secondary'>
                                    {'Selling Price'}
                                </th>
                                <th className='text-uppercase bg-secondary'>{'RV %'}</th>
                                <th className='text-uppercase bg-secondary'>{'RV Amount'}</th>
                                <th className='text-uppercase bg-secondary'>
                                    {'Assets Price'}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.state.secondary_asset.map((x, i) => {
                                return (
                                    <tr>
                                        <td>
                                            <IconButton
                                                key={'command_remove_' + i}
                                                style={styles.iconButton}
                                                iconStyle={styles.action}
                                                onClick={() => {
                                                    this.props.actions.removeSubAssetBlankRow(i)
                                                    this.props.actions.calSubAssetRv()
                                                }}
                                            >
                                                <Icon
                                                    istyle={styles.gridOptionsIcon}
                                                    ihovercolor={styles.actionHoverColor}
                                                    iclass={'material-icons'}
                                                    iname={'delete_forever'}
                                                />
                                            </IconButton>
                                        </td>
                                        <td style={{ width: 400 }}>
                                            <AutoComplete
                                                label={'Select Sub Asset'}
                                                listStyle={{ maxHeight: 400 }}
                                                id={'dll_contract_Type'}
                                                key={'dll_contract_Type'}
                                                hintText={'Select Sub Asset'}
                                                filter={AutoComplete.fuzzyFilter}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                fieldvalue={'sub_asset_id'}
                                                displayfields={['asset_name', 'asset_description']}
                                                displaywidth={['20', '80']}
                                                value={x.sub_asset}
                                                data={this.props.state.sub_asset_data}
                                                onSelect={(args, index) => {
                                                    //console.log('sub_asset customer',args)

                                                    let sub_asset =
                                                        this.props.state.sub_asset_data.filter(
                                                            x => x.sub_asset_id === args.value
                                                        )
                                                    sub_asset = sub_asset.length ? sub_asset[0] : {}
                                                    //console.log('sub_asset customer',sub_asset)

                                                    this.props.actions.setSubAssetInputValue(
                                                        'retailprice',
                                                        i,
                                                        sub_asset.retailprice
                                                    )
                                                    this.props.actions.setSubAssetInputValue(
                                                        'sellingprice',
                                                        i,
                                                        sub_asset.sellingprice
                                                    )
                                                    this.props.actions.setSubAssetInputValue(
                                                        'asset_price',
                                                        i,
                                                        sub_asset.sellingprice
                                                    )
                                                    this.props.actions.setSubAssetInputValue(
                                                        'asset_name',
                                                        i,
                                                        sub_asset.asset_name
                                                    )
                                                    this.props.actions.setSubAssetInputValue(
                                                        'asset_description',
                                                        i,
                                                        sub_asset.asset_description
                                                    )
                                                    this.props.actions.setSubAssetInputValue(
                                                        'sub_asset_id',
                                                        i,
                                                        args.value
                                                    )
                                                }}
                                                onClick={() => {
                                                    this.props.actions.getLookupData(
                                                        'sub_asset',
                                                        'asset_name',
                                                        ''
                                                    )
                                                }}
                                                onFilter={args => {
                                                    this.props.actions.getLookupData(
                                                        'sub_asset',
                                                        'asset_name',
                                                        args
                                                    )
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                variant='outlined'
                                                id={'description'}
                                                key={'description'}
                                                style={{ padding: '18.5px 14px !important' }}
                                                inputStyle={{ padding: '18.5px 14px !important' }}
                                                value={x.asset_description}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                variant='outlined'
                                                id={'retailprice'}
                                                key={'retailprice'}
                                                style={{ padding: '18.5px 14px !important' }}
                                                inputStyle={{ padding: '18.5px 14px !important' }}
                                                value={x.retailprice}
                                                onChange={event => {
                                                    this.props.actions.setSubAssetInputValue(
                                                        'retailprice',
                                                        i,
                                                        event.target.value
                                                    )
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                variant='outlined'
                                                id={'sellingprice'}
                                                key={'sellingprice'}
                                                style={{ padding: '18.5px 14px !important' }}
                                                inputStyle={{ padding: '18.5px 14px !important' }}
                                                value={x.sellingprice}
                                                onChange={event => {
                                                    this.props.actions.setSubAssetInputValue(
                                                        'sellingprice',
                                                        i,
                                                        event.target.value
                                                    )
                                                    this.props.actions.calSubAssetRv()
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                variant='outlined'
                                                id={'rv'}
                                                key={'rv'}
                                                style={{ padding: '18.5px 14px !important' }}
                                                inputStyle={{ padding: '18.5px 14px !important' }}
                                                value={x.rv}
                                                onChange={event => {
                                                    this.props.actions.setSubAssetInputValue(
                                                        'rv',
                                                        i,
                                                        event.target.value
                                                    )
                                                    this.props.actions.calSubAssetRv()
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                variant='outlined'
                                                id={'rv_amount'}
                                                key={'rv_amount'}
                                                style={{ padding: '18.5px 14px !important' }}
                                                inputStyle={{ padding: '18.5px 14px !important' }}
                                                value={x.rv_amount}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                variant='outlined'
                                                id={'asset_price'}
                                                key={'asset_price'}
                                                style={{ padding: '18.5px 14px !important' }}
                                                inputStyle={{ padding: '18.5px 14px !important' }}
                                                value={x.asset_price}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }

    getLayout() {
        const component = this.props.state.component

        return (
            <div>
                <Card content={this.getForm(component)} />
                <Card
                    title={'Secondary Assets'}
                    subtitle={'Add quote secondary assets'}
                    content={this.getSecondaryAssets()}
                />
                <Card
                    title={'Quotation Items'}
                    subtitle={'Add quote accessories, sundries and service'}
                    content={<WorkflowQuoteItemGrid />}
                />
            </div>
        )
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowQuoteHeader,
            customer: {
                ...state.components['customer']
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            { ...actions, ...workflowTaskInputActions },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowQuoteHeader)
