import moment from 'moment'
import * as types from './types'
import * as appTypes from '../../App/types'

export const loadWorkflowTimeline = (name, team_id, user_id, workflow_queue_id, is_visible, searchable) => {
    return (dispatch) => {
        dispatch({
            type: types.LOAD_WORKFLOW_TIMELINE,
            payload: {
                name: name,
                team_id: team_id,
                user_id: user_id,
                workflow_queue_id: workflow_queue_id,
                is_visible: is_visible,
                searchable: searchable
            }
        })

            dispatch(getWorkflowTimeline(name))
    }
}

export const getWorkflowTimeline = (name) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE,
            payload: {
                name: name
            }
        })
    }
}

export const togleTimeline = (name) => {
    return (dispatch, getState) => {
        let _name = getState().workflowTimeline.timeline
        dispatch({
            type: types.TOGGLE_WORKFLOW_TIMELINE_STATE_BY_TITLE,
            payload:  _name ? '' : name
        })
    }
}

export const toggleTimelineTask = (task) => {
    return (dispatch, getState) => {
        let _task = getState().workflowTimeline.timelinetask
        dispatch({
            type: types.TOGGLE_WORKFLOW_TIMELINE_TASK_BY_TASKNUMBER,
            payload:  _task ? 0 : task
        })
    }
}

export const assignToMe = (user_id, task_id) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOW_TIMELINE_ASSIGN_TASK_TO_ME,
            payload: {
                user_id: user_id,
                task_id: task_id
            }
        })
    }
}

export const unAssign = (user_id, task_id) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOW_TIMELINE_UNASSIGN_TASK,
            payload: {
                user_id: user_id,
                task_id: task_id
            }
        })
    }
}

export const showNotification = (payload, msg) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: msg,
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setWorkflowTimeline = (name, data) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOW_TIMELINE_SET_WORKFLOW_TIMELINE,
            payload: {
                name: name,
                data: data
            }
        })
    }
}

export const setSelectedWorkflowTimeLineQueue = (index) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOW_TIMELINE_SET_SELECTED_WORKFLOW_TIMELINE_QUEUE,
            payload: {
                index: index 
            }
        })
    }
}

export const toggleWorkflowTimeline = (name, status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_WORKFLOW_TIMELINE,
            payload: {
                name: name,
                status: status
            }
        })

        if (getState().workflowTimeline[name].isOpen) {
            dispatch(getWorkflowTimeline(name))
        }
    }
}

export const showWorkflowTimeline = (name) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SHOW_WORKFLOW_TIMELINE,
            payload: {
                name: name
            }
        })

        if (getState().workflowTimeline[name].isOpen) {
            dispatch(getWorkflowTimeline(name))
        }
    }
}

export const closeWorkflowTimeline = (name) => {
    return (dispatch) => {
        dispatch({
            type: types.CLOSE_WORKFLOW_TIMELINE,
            payload: {
                name: name
            }
        })
    }
}

export const getWorkflowTasks = (workflow_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_TASK_STEPS,
            payload: workflow_id
        })
    }
}

export const setWorkflowTasks = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_STEPS,
            payload: response.data
        })
    }
}