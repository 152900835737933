import validator from 'validator'

export default () => {
    let validators = []

    validator['isStrongPassword'] = (password) => {
        const hasLowerCase = /[a-z]/g.test(password)
        const hasUpperCase = /[A-Z]/g.test(password)
        const hasSpecialCharacter = /[~`!#$%^&*+=\-[\]\\',/{}|\\":<>?]/g.test(password)
        const hasNumber = /[0-9]/g.test(password)
        const hasLength = password.length >= 8 ? true : false

        return (hasLowerCase && hasUpperCase && hasSpecialCharacter && hasNumber && hasLength)
    }

    return {
        alpha: function (locale) {
            validators.push({
                name: 'alpha',
                type: 'isAlpha',
                locale: locale
            })

            return this
        },

        alphanumeric: function (locale) {
            validators.push({
                name: 'alphanumeric',
                type: 'isAlphanumeric',
                locale: locale
            })

            return this
        },

        base64: function () {
            validators.push({
                name: 'base64',
                type: 'isBase64'
            })

            return this
        },

        decimal: function () {
            validators.push({
                name: 'decimal',
                type: 'isDecimal'
            })

            return this
        },

        boolean: function () {
            validators.push({
                name: 'boolean',
                type: 'isBoolean'
            })

            return this
        },

        creditCard: function () {
            validators.push({
                name: 'creditCard',
                type: 'isCreditCard'
            })

            return this
        },

        date: function () {
            validators.push({
                name: 'date',
                type: 'isDate'
            })

            return this
        },

        length: function (min, max) {
            validators.push({
                name: 'length',
                type: 'isLength',
                min: min,
                max: max
            })

            return this
        },

        time: function () {
            validators.push({
                name: 'time',
                type: 'isTime'
            })

            return this
        },

        email: function () {
            validators.push({
                name: 'email',
                type: 'isEmail'
            })

            return this
        },

        required: function () {
            validators.push({
                name: 'required',
                type: 'isEmpty'
            })

            return this
        },

        numeric: function () {
            validators.push({
                name: 'numeric',
                type: 'isNumeric'
            })

            return this
        },

        phone: function (locale) {
            validators.push({
                name: 'phone',
                type: 'isMobilePhone',
                locale: locale
            })

            return this
        },

        password: function () {
            validators.push({
                name: 'password',
                type: 'isStrongPassword'
            })

            return this
        },

        contains: function (field) {
            validators.push({
                name: 'contains',
                type: 'matches',
                field: field
            })

            return this
        },

        matches: function (field) {
            validators.push({
                name: 'matches',
                type: 'matches',
                field: field
            })

            return this
        },

        postalCode: function (locale) {
            validators.push({
                name: 'postalCode',
                type: 'isPostalCode',
                locale: locale
            })

            return this
        },

        set: function () {
            return validators
        },

        validate: (schema, data) => {
            const errors = Object.keys(schema).reduce((arr, x, i) => {
                let input = data[x]
                input = data[x] !== undefined ? data[x].trim() : undefined

                if (input === undefined) {
                    arr.push({
                        field: x,
                        message: 'Missing field'
                    })

                    return arr
                }

                schema[x].forEach((y) => {
                    switch (y.name) {
                        case 'postalCode':
                            if (!validator.isPostalCode(input, y.locale)) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid postal code'
                                })
                            }

                            break
                        case 'alpha':
                            if (!validator.isAlpha(input, y.locale)) {
                                arr.push({
                                    field: x,
                                    message: 'May only contain alphabetical characters'
                                })
                            }

                            break
                        case 'alphanumeric':
                            if (!validator.isAlphanumeric(input, y.locale)) {
                                arr.push({
                                    field: x,
                                    message: 'May only contain alphabetical and numerical characters'
                                })
                            }

                            break
                        case 'base64':
                            if (!validator.isBase64(input)) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid base64 encoded string'
                                })
                            }

                            break
                        case 'boolean':
                            if (!validator.isBoolean(input)) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid boolean'
                                })
                            }

                            break
                        case 'creditCard':
                            if (!validator.isCreditCard(input)) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid credit card number'
                                })
                            }

                            break
                        case 'date':
                            if (isNaN(Date.parse(input))) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid date'
                                })
                            }

                            break
                        case 'length':
                            if (!validator.isLength(input, { min: y.min ? y.min : 0, max: y.max })) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid field length'
                                })
                            }

                            break
                        case 'time':
                            if (isNaN(Date.parse(input))) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid time'
                                })
                            }

                            break
                        case 'decimal':
                            if (!validator.isDecimal(input)) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid decimal value'
                                })
                            }

                            break
                        case 'email':
                            if (!validator.isEmail(input)) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid e-mail address'
                                })
                            }

                            break
                        case 'required':
                            if (validator.isEmpty(input) || input === null) {
                                arr.push({
                                    field: x,
                                    message: 'Required field'
                                })
                            }

                            break
                        case 'numeric':
                            if (!validator.isNumeric(input)) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid numeric value'
                                })
                            }

                            break
                        case 'phone':
                            if (!validator.isMobilePhone(input, y.locale)) {
                                arr.push({
                                    field: x,
                                    message: 'Not a valid phone number'
                                })
                            }

                            break
                        case 'password':
                            if (!validator.isStrongPassword(input)) {
                                arr.push({
                                    field: x,
                                    message: 'Not a strong enough password. Needs 8 characters with at least a number, lowercase, uppercase and special character.'
                                })
                            }

                            break
                        case 'contains':
                            if (!validator.matches(input, data[y.field], 'g')) {
                                arr.push({
                                    field: x,
                                    message: 'Must contain value of ' + y.field
                                })
                            }

                            break
                        case 'matches':
                            if (input !== data[y.field]) {
                                arr.push({
                                    field: x,
                                    message: 'Must match value of ' + y.field
                                })
                            }

                            break
                        default:
                            arr.push({
                                field: x,
                                message: 'Unkown validator ' + validator
                            })

                            break
                    }
                })

                return arr
            }, [])

            return {
                valid: errors.length === 0 ? true : false,
                errors: errors
            }
        }
    }
}