export const GET_CONTRACT_RESTRCTURE_COMPONENT_GRID_DATA = 'GET_CONTRACT_RESTRCTURE_COMPONENT_GRID_DATA'
export const SET_CONTRACT_RESTRCTURE_COMPONENT_ROW_SELECTION = 'SET_CONTRACT_RESTRCTURE_COMPONENT_ROW_SELECTION'
export const SET_CONTRACT_RESTRCTURE_COMPONENT_DATA = 'SET_CONTRACT_RESTRCTURE_COMPONENT_DATA'
export const SET_CONTRACT_RESTRCTURE_COMPONENT_GRID_DATA = 'SET_CONTRACT_RESTRCTURE_COMPONENT_GRID_DATA'
export const SET_COMPONENT_CONTRACT_GRID_SELECTED_LOOKUP_VALUE = 'SET_COMPONENT_CONTRACT_GRID_SELECTED_LOOKUP_VALUE'
export const SET_COMPONENT_CONTRACT_GRID_LOOKUP_DATA = 'SET_COMPONENT_CONTRACT_GRID_LOOKUP_DATA'
export const GET_COMPONENT_CONTRACT_GRID_LOOKUP_DATA = 'GET_COMPONENT_CONTRACT_GRID_LOOKUP_DATA'
export const RESET_COMPONENT_STATE_DATA = 'RESET_COMPONENT_STATE_DATA'
export const SET_BULK_RESTRUCTURE_IMPORTED_ROWS = 'SET_BULK_RESTRUCTURE_IMPORTED_ROWS'
export const RESET_BULK_UPLOAD_DATA = 'RESET_BULK_UPLOAD_DATA'
export const SET_BULK_RESTRUCTURE = 'SET_BULK_RESTRUCTURE'