import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import Drawer from '../../../controls/drawer'
import Icon from '../../Icon/component'
import Card from '../../../controls/card'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDisposalDisposal extends Component {
    componentDidMount() {
        this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
        if(this.data) {
            //console.log('componentWillMount',this.data)
            this.props.actions.setSetinputField({prop: 'absa_contract_enddate', value: this.data.absa_contract_enddate})
            this.props.actions.setSetinputField({prop: 'absa_residual_value', value: this.data.absa_residual_value})
            this.props.actions.setSetinputField({prop: 'absa_remaining_months', value: this.data.absa_remaining_months})
        }
    }
    
    getViewForm() {     
        return <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>{'Funder Name:'}</Col><Col xl={6}>{this.data.financialinstitution ? this.data.financialinstitution.fundername : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Contract End Date:'}</Col><Col xl={6}>{moment(this.data.absa_contract_enddate).format('YYYY-MM-DD')}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}><hr /></Col>
                    </Row>
                   <Row>
                       <Col xl={6}>{'Funder RV:'}</Col><Col xl={6}>{this.data.absa_residual_value}</Col>
                   </Row>
                   <Row>
                       <Col xl={6}>{'Funder Month to Go:'}</Col><Col xl={6}>{this.data.absa_remaining_months}</Col>
                   </Row>
                    <Row>
                        <Col xl={12}><hr /></Col>
                    </Row>
           </Container>
       }

    getEditForm() {     
         return <Container style={{ margin: 0 }} className="custom-container">

                    <Row>
                        <Col xl={12}>
                                <b>{'Funder RV:*'}</b>
                                    <TextField
                                        variant="outlined" 
                                        id={'txtfunderrv'}
                                        hintText={'Enter Funder RV'}
                                        fullWidth={true}
                                        value={this.props.state.absa_residual_value}
                                        onChange={(event, value) => {
                                            this.props.actions.setSetinputField({prop: 'absa_residual_value', value: event.target.value})
                                        }}
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                                <b>{'Funder Months To-Go:*'}</b>
                                    <TextField
                                        variant="outlined" 
                                        id={'txtmonthtogo'}
                                        hintText={'Enter Months To-Go'}
                                        fullWidth={true}
                                        value={this.props.state.absa_remaining_months}
                                        onChange={(event, value) => {
                                            this.props.actions.setSetinputField({prop: 'absa_remaining_months', value: event.target.value})
                                        }}
                                    />
                        </Col>
                    </Row>
                </Container>
        }

        
        getView() {
            return <div> 
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Funder Details
                    </div>
                    <p className="text-black-50">Vehicle funder details</p>
                </div>
                {this.getViewForm()}
                {/* <div className="divider" /> */}
                <br />
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Disposal Details
                    </div>
                    <p className="text-black-50">Vehicle disposal details</p>
                </div>
                {this.getEditForm()}
            </div>
        }

        // getView() {
        //     return <Card
        //         title={'Disposal Details'}
        //         subtitle={'Disposal form'}
        //         content={this.getEditForm()}
        //     />
        // }

        getLayout() {
            return  <Drawer 
                onClose={(event, value) => {
                    this.props.actions.toggleInfoSheet(null,'inforsheet_disposal')
                }}
                open={this.props.state.inforsheet_disposal}
                title={'Disposal Details'}
                subtitle={'Disposal form'}
                content={this.getView()}
                buttons={<div>
                    <Button variant='contained' className='global_button'
                         label="Update Disposal Details"
                         primary={true}
                         onClick={(event) => {
                             event.preventDefault()
                             this.props.actions.updateDisposalDetails(this.data.vehicle_id)
                         }}
                     >
                     {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {window.t.en('Update Disposal Details')}
                     </Button>
                </div>}
            />
            // return <Drawer
            //         width={800}
            //         anchor={'right'}
            //         openSecondary={true}
            //         open={this.props.state.inforsheet_disposal}
            //         onRequestChange={(event) => {
            //             event.preventDefault()
            //             this.props.actions.toggleInfoSheet(null,'inforsheet_disposal')
            //         }}>
            //         {
            //             this.getView()
            //         }
            //     </Drawer>
        }

        render() {
            this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
            return this.getLayout()
        }
    }

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalDisposal)