import TextField from '@mui/material/TextField'
import AutoComplete from '../../controls/autocomplete'
import { Card, CardContent } from '@mui/material'
import Divider from '@mui/material/Divider'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Icon from '../Icon/component'
import * as actions from './actions'
import * as styles from './styles'




class WorkflowPOSupplier extends Component {
    // componentDidMount() {
    // }

    // componentWillUnmount() {
    // }

    render() {
        const component = this.props.state.component

        this.field = component.component_field.reduce((obj, x) => {
            obj[x.name] = x
            return obj
        }, {})

        return component.grid.data.length > 0
            ? (
                <Card style={styles.card}>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={6}>
                                    <strong>Vehicle Details : {this.props.inx + 1}</strong>
                                </Col>
                                <Col style={styles.remove}>
                                    <Icon
                                        iname="delete_forever"
                                        iclass="material-icons"
                                        icolor="red"
                                        istyle={{
                                            fontSize: '16px',
                                        }}
                                        onClick={(event) => {
                                            this.props.actions.removeSelectedRow(component, this.props.inx)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    &nbsp;
                                        <br />
                                    &nbsp;
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={4}>{this.field.supplier_id.title}:</Col>
                                <Col>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400 }}
                                        id={this.field.supplier_id.name}
                                        key={this.field.supplier_id.name}
                                        hintText={'Select make and model'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        dataSource={component.data.supplier_id.data}
                                        //errorText={this.component.data[field.name].validation.message}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                this.props.actions.setLookupValue(this.field.supplier_id, chosenRequest.value, chosenRequest.text, this.props.inx)
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.getLookupValue(this.props.state.supplier.name, this.field.supplier_id, '')
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.getLookupValue(this.props.state.supplier.name, this.field.supplier_id, search)
                                            }
                                        }
                                    />
                                </Col>
                            </Row>

                        </Container>
                        {
                            component.grid.data[this.props.inx].supplier_id
                                ? <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            &nbsp;
                                        <Divider />
                                            &nbsp;
                                </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>{'BEE Type'}</Col>
                                        <Col>
                                            <TextField
                                                id={'beetype_' + this.props.inx}
                                                value={component.grid.data[this.props.inx].beetype}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>{'BEE Level'}</Col>
                                        <Col>
                                            <TextField
                                                id={'beelevel_' + this.props.inx}
                                                value={component.grid.data[this.props.inx].beelevel}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>{'BEE Score'}</Col>
                                        <Col>
                                            <TextField
                                                id={'beescore_' + this.props.inx}
                                                value={component.grid.data[this.props.inx].beescore}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            &nbsp;
                                        <Divider />
                                            &nbsp;
                                </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>{'Contact Number'}</Col>
                                        <Col>
                                            <TextField
                                                id={'contactperson_' + this.props.inx}
                                                value={component.grid.data[this.props.inx].contactperson}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>{'Contact Number'}</Col>
                                        <Col>
                                            <TextField
                                                id={'contactnumber_' + this.props.inx}
                                                value={component.grid.data[this.props.inx].contactnumber}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>{'Contact Email'}</Col>
                                        <Col>
                                            <TextField
                                                id={'contactemail_' + this.props.inx}
                                                value={component.grid.data[this.props.inx].contactemail}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                                : ''
                        }
                    </CardContent>
                </Card>
            )
            : <div>{''}</div>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            component: {
                ...state.components[ownProps.name]
            },
            contractvehiclecategorymmcodegroup: {
                ...state.components['contractvehiclecategorymmcodegroup']
            },
            supplier: {
                ...state.components['supplier']
            },
            vehicle: {
                ...state.components['vehicle']
            },
            mmcode: {
                ...state.components['mmcode']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOSupplier)