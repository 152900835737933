import * as types from './types'
import _ from 'lodash'
import {ExcelRenderer, OutTable} from 'react-excel-renderer'


export const resetComponentData = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_STATE_DATA,
        })
    }
}

export const clearBulkRows = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_BULK_UPLOAD_DATA,
        })
    }
}
export const setBulk = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BULK_RESTRUCTURE,
            payload: !getState().contractSelectionGrid.is_build
        })
    }
}

// componentDidMount() {
//     this.props.actions.resetComponentData()
// ...state.contractRestructure,
// contractSelectionGrid: {
//     ...state.contractSelectionGrid,
// }


export const selectRow = (row, checked) => {
    return (dispatch, getState) => {
        const data = checked ? [row] : null
        dispatch({
            type: types.SET_CONTRACT_RESTRCTURE_COMPONENT_ROW_SELECTION,
            payload: data
        })
    }
}

export const getRestructureGridData = (component, link, paging, filter, id) => {
    return (dispatch, getState) => {
        const getRelated = () => {
            if (link) {
                const parent = getState().components[link.parent]
                const through = getState().components[link.through]

                const query = '?' + parent.component_field.filter(x => x.key)[0].name + '=' + link.id

                return {
                    component: through,
                    child: component,
                    query: query
                }
            }

            return undefined
        }

        const getQuery = () => {
            let query = paging.filtered.reduce((query, x) => {
                query.push(x.id.concat('=', x.value))
                return query
            }, [])

            return '?' + query.join('&')
        }

        const withRelatedId = (query) => {

            if (!id || !filter) {
                return query
            }

            const key = getState().components[filter].component_field.filter(x => x.key)[0]

            if (!key) {
                return query
            }

            return query.indexOf('?' > -1) && query.length > 1
                ? query + '&' + key.name + '=' + id
                : '?' + key.name + '=' + id
        }

        let query = withRelatedId(getQuery())

        const related = getRelated()

        window.glyco.log('related')
        window.glyco.log(related)
        window.glyco.log(query)
        //window.glyco.log(component.related !== null ? component.related : '')

        dispatch({
            type: types.GET_CONTRACT_RESTRCTURE_COMPONENT_GRID_DATA,
            payload: {
                id: id,
                component: '',
                input: '',
                paging: paging,
                sorting: '',
                query: query,
                related: '',
                child: {} //!related ? {} : related.child
            }
        })
    }
}

export const setRestructureGridData = (payload) => {

    //console.log('setComponentGridData',payload.data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTRACT_RESTRCTURE_COMPONENT_GRID_DATA,
            payload: payload.data
        })
    }
}


export const setInputValue = (field, inx, value) => {
    return (dispatch, getState) => {
        // dispatch({
        //     type: types.SET_COMPONENT_INPUT_GRID_LOOKUP_VALUE,
        //     payload: {
        //         field: field,
        //         value: value,
        //         inx: inx
        //     }
        // })

        const grid = getState().poBookingItems.grid
        if (grid.length) {
            const _tot = (parseFloat(grid[inx].Price) * parseFloat(grid[inx].Quantity))

            const dicount = ((parseFloat(grid[inx].Discount) / 100) * _tot)
            const amount_disc = (_tot - (parseFloat(grid[inx].Discount) / 100) * _tot)

            const amount_vat = amount_disc * 0.15
            const amount_inc = amount_disc * 1.15
            grid[inx].DiscountAmount = dicount.toFixed(2)
            grid[inx].VAT = amount_vat.toFixed(2)
            grid[inx].Total = _tot.toFixed(2)
            grid[inx].Amount = amount_inc.toFixed(2)
        }

        dispatch({
            type: types.SET_CONTRACT_RESTRCTURE_COMPONENT_GRID_DATA,
            payload: grid
        })
    }
}


export const getLookupValues = (component, field, inx, related, search) => {
    return (dispatch, getState) => {
        const query = related ? '&' + related.split('.')[0] + '=' + getState().poBookingItems.grid[inx][related.split('.')[1]] : ''
        dispatch({
            type: types.GET_COMPONENT_CONTRACT_GRID_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search + query
            }
        })
    }
}

export const setLookupValues = (payload) => {
    return (dispatch, getState) => {

        const data = payload.response.data.map(x => {
            return {
                value: x[payload.component + '_id'],
                text: x[payload.component]
            }
        })

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_GRID_LOOKUP_DATA,
            payload: {
                component: payload.component,
                data: data
            }
        })
    }
}

export const setSelectedLookupValue = (field, inx, value, text) => {
    return (dispatch, getState) => {
        const payload = {
            fieldpk: field + '_Id',
            field: field,
            value: value,
            text: text,
            inx: inx
        }

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_GRID_SELECTED_LOOKUP_VALUE,
            payload: payload
        })
    }
}


export const setAcceptedDocuments = (documents) => {
    return (dispatch, getState) => {
        documents.forEach(file => {
            ExcelRenderer(file, (err, resp) => {
                if (err) {
                    //console.log(err);
                }
                else {
                    //console.log(resp.rows)
                    
                    dispatch({
                        type: types.SET_BULK_RESTRUCTURE_IMPORTED_ROWS,
                        payload: resp.rows
                    })
                    //   this.setState({
                    //     cols: resp.cols,
                    //     rows: resp.rows
                    //   });
                }
            });
        });
    };
}

