import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewCustomer from '../../../controls/view_customer'

import * as actions from './actions'
import * as styles from './styles'

class CostCentreChangeApprove extends Component {
   
    componentDidMount() {
            this.props.actions.resetForm()
        }
    getView(data) {
        let confirm = data?.data
        //console.log('AccessoryMasterActivationx confirm', confirm)
        return data ? <div>
            <ViewVehicle
                key={confirm.vehicle.vehicle_id}
                id={confirm.vehicle.vehicle_id}
            />
            <ViewCustomer
                    key={confirm.from_customer_id }
                    id={confirm.from_customer_id }
                    title={"Current Vehicle CostCentre"}
                    subtitle={window.t.en('Confirm new vehicle costcentre details')}
                />
            <ViewCustomer
                    key={confirm.to_customer_id }
                    id={confirm.to_customer_id }
                    title={"New Vehicle CostCentre"}
                    subtitle={window.t.en('Confirm new vehicle costcentre details')}
                />
            <Card
                title={"Supplier Documents"}
                subtitle={window.t.en('Uploaded supplier supporting documents')}
                content={<PdfViewer
                    key={'supplier_pricing'}
                    name={'supplier_pricing'}
                    documents={confirm.documents}
                    edit={false}
                    multiple={false}
                    workflow_queue_data={null}
                />}
            />
            <Card
            title={'Approval Comments '}
            subtitle={'Approval approval comments'}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            variant='outlined'
                            id={'txt_comments'}
                            label={'Enter Comments'}
                            fullWidth={true}
                            value={this.props.state.comments}
                            onChange={(event, value) => {
                                this.props.actions.setInputValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>
            } />
        </div> : ''
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('CostCentreChangeApprovexx', data.context_data.data.data.data[0])
        data = data.context_data.data.data.data[0].step1
        return <div>
            {this.getView(data)}
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.costCentreChangeApprove
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CostCentreChangeApprove)