import * as props from './props'
import * as componentTypes from './types'
import * as gridTypes from '../Grid/types'
import * as workflowGridTypes from '../WorkflowGrid/types'
import * as quoteSelectGridTypes from '../QuoteSelectGrid/types'
import * as workflowInputTypes from '../WorkflowInput/types'
import * as workflowQuoteHeaderTypes from '../WorkflowQuoteHeader/types'
import * as workflowPOHeaderTypes from '../WorkflowPOHeader/types'
import * as workflowPOSupplierTypes from '../WorkflowPOSupplier/types'
import * as quoteHeaderTypes from '../QuoteHeader/types'
import * as quoteHeaderSTRTypes from '../QuoteHeaderSTR/types'
import * as licenseeTypes from '../LicenseeAccount/types'
import * as subAccountTypes from '../SubAccount/types'
import * as budgetManagementTypes from '../BudgetManagement/types'
import * as clientAccountTypes from '../ClientAccount/types'
import * as formTypes from '../Form/types'
import * as relatedFormTypes from '../RelatedForm/types'
import * as controlsTypes from '../Controls/types'
import * as pdfTypes from '../PDF/types'
import * as quoteEditTypes from '../QuoteEdit/types'
import * as htmlTypes from '../HTML/types'
import * as workflowTaskApprovalTypes from '../WorkflowTaskApproval/types'
import * as workflowQuoteItemGridTypes from '../WorkflowQuoteItemGrid/types'
import * as workflowTaskStepComponentQuoteCreator from '../WorkflowTaskStepComponentQuoteCreator/types'
import * as workflowTaskStepComponentSingleSelectorTypes from '../WorkflowTaskStepComponentSingleSelector/types'
import * as workflowViewFMLQuoteSummaryTypes from '../WorkflowViewFMLQuoteSummary/types'
import * as workflowUploadPIDTypes from '../WorkflowUploadPID/types'
import * as workflowUploadPODTypes from '../WorkflowUploadPOD/types'
import * as workflowPOReceiptVehicle from '../WorkflowPOReceiptVehicle/types'
import * as workflowPOApprovalByGMTypes from '../WorkflowPOApprovalByGM/types'
import * as workflowPOApprovalByCEOTypes from '../WorkflowPOApprovalByCEO/types'
import * as workflowPOAccessoriesTypes from '../WorkflowPOAccessories/types'
import * as workflowPODeliveryTypes from '../WorkflowPODelivery/types'
import * as workflowTaskStepQuoteSingleSelectorTypes from '../WorkflowTaskStepQuoteSingleSelector/types'
import * as supplierPaymentTypes from '../Custom/SUPPLIERPAYMENT/types'
import * as supplierReversePayTypes from '../Custom/SUPPLIERPAYMENTREVERSAL/types'
import * as supplierAllocationTypes from '../Custom/SUPPLIERPAYMENTALLOCATION/types'
//import * as customerInvoiceTypes from '../Custom/CUSTOMERINVOICE/types'
import * as customerReceiptTypes from '../Custom/CUSTOMERRECEIPT/types'
import * as customerAllocationTypes from '../Custom/CUSTOMERALLOCATION/types'
import * as customerJournalTypes from '../Custom/CUSTOMERJOURNAL/types'
import * as customerCreditNoteTypes from '../Custom/CUSTOMERCREDITNOTE/types'
import * as customerReverseReceiptTypes from '../Custom/CUSTOMERREVERSERECEIPT/types'
import * as customerStatementTypes from '../Custom/CUSTOMERSTATEMENT/types'
import * as supplierTransactionTypes from '../Custom/SUPPLIERTRANSACTIONCUSTOM/types'
import * as supplierCreditNoteTypes from '../Custom/SUPPLIERCREDITNOTE/types'
import * as supplierJournalTypes from '../Custom/SUPPLIERJOURNAL/types'
import * as generalledgerTypes from '../Custom/GENERALLEDGERCASHBOOK/types'
import * as customerTransactionTypes from '../Custom/CUSTOMERTRANSACTIONCUSTOM/types'
import * as disposaltypes from '../Custom/DISPOSAL/types'
import * as billingmoduletypes from '../Custom/BILLINGMODULE/types'
import * as customerremittancetypes from '../Custom/CUSTOMERREMITTANCE/types'
import * as contracttermtypes from '../Custom/CONTRACTTERMINATION/types'
import * as customerContactTypes from '../Custom/CUSTOMERCONTACTCUSTOM/types'
import * as costcentrechangetypes from '../Custom/CUSTOMCOSTCENTRECHANGE/types'
import * as registrationchangetypes from '../Custom/CUSTOMREGISTRATIONCHANGE/types'
import * as customerderivativeTypes from '../Custom/CUSTOMERDERIVATIVE/types'
import * as teamManagerTypes from '../TeamManagement/TeamManager/types'
import * as resellerTypes from '../Reseller/types'
import * as userOptionsTypes from '../TeamManagement/UserOptions/types'
import { actions } from 'react-table'

export default (state = props, action) => {
    switch (action.type) {
        case gridTypes.RESIZE_GRID_COLUMN:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    component_field: state[action.payload.component].component_field.map(x => {
                        if (x.name === action.payload.field) {
                            return {
                                ...x,
                                width: action.payload.width
                            }
                        }
                        else {
                            return x
                        }
                    })
                }
            }
        case gridTypes.GET_COMPONENT_GRID_DATA:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        loading: true,
                        selected: [],
                        paging: {
                            ...state[action.payload.component.name].grid.paging,
                            page: action.payload.paging.page,
                            pageSize: action.payload.paging.pageSize,
                            pageCount: action.payload.paging.pageCount
                        },
                        sorting: action.payload.sorting
                    }
                }
            }
        case gridTypes.SET_COMPONENT_GRID_DATA:
            console.log('SET_COMPONENT_GRID_DATAx component',action.payload.component.name )
            console.log('SET_COMPONENT_GRID_DATAx data', action.payload.data)
            console.log('SET_COMPONENT_GRID_DATAx state', state[action.payload.component.name])
            console.log('SET_COMPONENT_GRID_DATAx state', state)
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        loading: false,
                        selected: [],
                        //headers: action.payload.headers,
                        data: [...action.payload.data.data],
                        paging: {
                            ...state[action.payload.component.name].grid.paging,
                            page: action.payload.data.paging.page - 1,
                            pageSize: action.payload.data.paging.pageSize,
                            pageCount: action.payload.data.paging.pageCount,
                            rowCount: action.payload.data.paging.rowCount
                        }
                    }
                }
            }
        case gridTypes.SELECT_ROW:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    info: action.payload.info,
                    selected: action.payload.rows
                }
            }
        case workflowGridTypes.SELECT_WORKFLOW_GRID_ROW:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    info: action.payload.info,
                    selected: action.payload.rows
                }
            }
        case componentTypes.COMPONENTS_GET_COMPONENT_NAMES:
            return {
                ...action.payload,
                loading: true
            }
        case componentTypes.COMPONENTS_SET_COMPONENT_NAMES:
            return {
                ...action.payload,
                loading: false
            }
        case componentTypes.COMPONENTS_GET_COMPONENT:
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    retrieving: true,
                    loading: true
                }
            }
        case componentTypes.COMPONENTS_SET_COMPONENT:
            console.log('SET_COMPONENT_GRID_DATAx COMPONENTS_SET_COMPONENT',action.payload.name)
            return {
                ...state,
                [action.payload.name]: {
                    retrieving: false,
                    ...action.payload,
                    loading: false
                }
            }
        case componentTypes.COMPONENTS_CLEAR_COMPONENT_DATA:
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    data: {
                        ...state[action.payload].empty
                    }
                }
            }
        case workflowGridTypes.GET_WORKFLOW_COMPONENT_GRID_DATA:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        loading: true,
                        selected: [],
                        paging: {
                            ...state[action.payload.component.name].grid.paging,
                            page: action.payload.paging.page,
                            pageSize: action.payload.paging.pageSize,
                            pageCount: action.payload.paging.pageCount
                        },
                        sorting: action.payload.sorting
                    }
                }
            }
        case workflowGridTypes.SET_WORKFLOW_COMPONENT_GRID_DATA:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        loading: false,
                        selected: [],
                        data: [...action.payload.data.data],
                        paging: {
                            ...state[action.payload.component.name].grid.paging,
                            page: action.payload.data.paging.page - 1,
                            pageSize: action.payload.data.paging.pageSize,
                            pageCount: action.payload.data.paging.pageCount
                        }
                    }
                }
            }
        case workflowGridTypes.SET_SELECTED_WORKFLOW_GRID_ROW_PK:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        [action.payload.data.prop]: {
                            ...state[action.payload.component.name].data[action.payload.data.prop],
                            input: action.payload.data.val
                        }
                    }
                }
            }

        case quoteSelectGridTypes.GET_QUOTE_GRID_DATA:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        loading: true,
                        selected: [],
                        paging: {
                            ...state[action.payload.component.name].grid.paging,
                            page: action.payload.paging.page,
                            pageSize: action.payload.paging.pageSize,
                            pageCount: action.payload.paging.pageCount
                        },
                        sorting: action.payload.sorting
                    }
                }
            }
        case quoteSelectGridTypes.SET_QUOTE_GRID_DATA:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        loading: false,
                        selected: [],
                        data: [...action.payload.data.data],
                        paging: {
                            ...state[action.payload.component.name].grid.paging,
                            page: action.payload.data.paging.page - 1,
                            pageSize: action.payload.data.paging.pageSize,
                            pageCount: action.payload.data.paging.pageCount
                        }
                    }
                }
            }
        case quoteSelectGridTypes.SET_QUOTE_SELECT_ROW_PK:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        [action.payload.data.prop]: {
                            ...state[action.payload.component.name].data[action.payload.data.prop],
                            input: action.payload.data.val
                        }
                    }
                }
            }
        case workflowInputTypes.SET_SELECT_FIELD_PK:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        [action.payload.data.prop]: {
                            ...state[action.payload.component.name].data[action.payload.data.prop],
                            input: action.payload.data.val
                        }
                    }
                }
            }

        case workflowQuoteItemGridTypes.SET_COMPONENT_FIELD_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: [...action.payload.data]
                        }
                    }
                }
            }
        case workflowQuoteItemGridTypes.SET_SELECTED_QUOTE_ITEM_TYPES_INPUT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value
                        }
                    }
                }
            }
        case workflowQuoteItemGridTypes.SET_SELECTED_QUOTE_ITEM_TYPES_TEXT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            text: action.payload.value
                        }
                    }
                }
            }
        case workflowQuoteItemGridTypes.UPDATE_QUOTE_GRID_ITEMS:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data]
                    }
                }
            }
        case workflowTaskStepComponentQuoteCreator.SET_QUOTE_TYPES_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        data: [...action.payload.data.data]
                    }
                }
            }
        case workflowTaskStepComponentQuoteCreator.SET_QUOTE_VEHICLE_GROUP_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        data: [...action.payload.data.data]
                    }
                }
            }

        case workflowTaskStepComponentQuoteCreator.SET_QUOTE_VEHICLE_MMCODE_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        data: [...action.payload.data]
                    }
                }
            }
        case workflowTaskStepComponentQuoteCreator.ACC_COMPONET_GRID_SELECTED_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data]
                    }
                }
            }
        case workflowTaskStepComponentQuoteCreator.SET_QUOTE_TYPES_LOOKUP_SELECTION:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        [action.payload.field]: {
                            ...state[action.payload.component.name].data[action.payload.field],
                            text: action.payload.value,
                        }
                    }
                }
            }
        case workflowTaskStepComponentQuoteCreator.SET_QUOTE_VEHICLE_GROUP_LOOKUP_SELECTION:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        [action.payload.field]: {
                            ...state[action.payload.component.name].data[action.payload.field],
                            text: action.payload.value,
                        }
                    }
                }
            }
        case workflowTaskStepComponentQuoteCreator.SET_QUOTE_VEHICLE_MMCODE_LOOKUP_SELECTION:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        [action.payload.field]: {
                            ...state[action.payload.component.name].data[action.payload.field],
                            text: action.payload.value,
                        }
                    }
                }
            }
        case pdfTypes.SET_QUOTE_DOCUMENT_BYTES:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        key: {
                            ...state[action.payload.component.name].data.key,
                            text: action.payload.data,
                            filename: action.payload.filename,
                        }
                    }
                }
            }
        case workflowTaskApprovalTypes.EDIT_QUOTE_SET_PREVIEW_DOCUMENT_BYTES:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        key: {
                            ...state[action.payload.component.name].data.content,
                            text: action.payload.data,
                        }
                    }
                }
            }
        case htmlTypes.SET_HTML:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        html: {
                            ...state[action.payload.component.name].data.html,
                            text: action.payload.html,
                        }
                    }
                }
            }
        case quoteSelectGridTypes.SET_GRID_QUOTE_DOCUMENT_BYTES:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        content: {
                            ...state[action.payload.component.name].data.content,
                            text: action.payload.data,
                        }
                    }
                }
            }
        case quoteSelectGridTypes.CLEAR_AWS_QUOTE_DOCUMENT_LIST:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        content: {
                            ...state[action.payload.component.name].data.content,
                            text: "",
                        }
                    }
                }
            }

        case workflowTaskApprovalTypes.CLEAR_EDIT_QUOTE_DOCUMENT:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        content: {
                            ...state[action.payload.component.name].data.content,
                            text: "",
                        }
                    }
                }
            }
        case quoteSelectGridTypes.SET_QUOTE_GRID_DOCUMENTS:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        key: {
                            ...state[action.payload.component.name].data.content,
                            text: action.payload.data,
                        }
                    }
                }
            }
        case workflowQuoteItemGridTypes.REMOVE_QUOTE_GRID_ITEM:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data],
                        selected: []
                    }
                }
            }
        case workflowQuoteItemGridTypes.QUOTE_GRID_ADD_BLANK_ROW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data]
                    }
                }
            }
        case formTypes.SET_COMPONENT_DATA:
            //console.log('Form loadComponent FORM state', action.payload.name)
            //console.log('Form loadComponent FORM payload', action.payload.data)
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    data: action.payload.data
                }
            }
        case relatedFormTypes.SET_COMPONENT_RELATED_DATA:
            //console.log('Form loadComponent FORM state', action.payload.name)
            //console.log('Form loadComponent FORM payload', action.payload.data)
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    data: action.payload.data
                }
            }
        // case customerderivativeTypes.SET_COMPONENT_SELECTED_CUSTOMER_SETTINGS_DATA:
        //     return {
        //         ...state,
        //         [action.payload.name]: {
        //             ...state[action.payload.name],
        //             data: action.payload.data
        //         }
        //     }
        case formTypes.SET_COMPONENT_SCHEMA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    schema: action.payload.schema
                }
            }
        case formTypes.SET_COMPONENT_RELATIONSHIPS:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    relatedFull: action.payload.relatedFull
                }
            }
            case formTypes.SET_COMPONENT_FIELD_INPUT:
                //console.log('SET_COMPONENT_FIELD_INPUT FORM state', state)
                //console.log('SET_COMPONENT_FIELD_INPUT FORM payload', action.payload)
                return {
                    ...state,
                    [action.payload.component]: {
                        ...state[action.payload.component],
                        data: {
                            ...state[action.payload.component].data,
                            [action.payload.field]: {
                                ...state[action.payload.component].data[action.payload.field],
                                input: action.payload.input,
                                validation: action.payload.validation
                            }
                        }
                    }
                }
            case formTypes.SET_COMPONENT_FIELD_INPUT_AND_TEXT:
                return {
                    ...state,
                    [action.payload.component]: {
                        ...state[action.payload.component],
                        data: {
                            ...state[action.payload.component].data,
                            [action.payload.field]: {
                                ...state[action.payload.component].data[action.payload.field],
                                input: action.payload.input,
                                text: action.payload.text,
                                validation: action.payload.validation
                            }
                        }
                    }
                }
                case relatedFormTypes.SET_RELATED_FORM_COMPONENT_FIELD_INPUT:
                    //console.log('SET_RELATED_FORM_COMPONENT_FIELD_INPUT', action.payload)
                    return {
                        ...state,
                        [action.payload.component]: {
                            ...state[action.payload.component],
                            data: {
                                ...state[action.payload.component].data,
                                [action.payload.field]: {
                                    ...state[action.payload.component].data[action.payload.field],
                                    input: action.payload.input,
                                    validation: action.payload.validation
                                }
                            }
                        }
                    }
                case relatedFormTypes.SET_COMPONENT_RELATED_FIELD_INPUT_AND_TEXT:
                    return {
                        ...state,
                        [action.payload.component]: {
                            ...state[action.payload.component],
                            data: {
                                ...state[action.payload.component].data,
                                [action.payload.field]: {
                                    ...state[action.payload.component].data[action.payload.field],
                                    input: action.payload.input,
                                    text: action.payload.text,
                                    validation: action.payload.validation
                                }
                            }
                        }
                    }
                    case relatedFormTypes.SET_COMPONENT_RELATED_FIELD_LOOKUP_DATA:
                        return {
                            ...state,
                            [action.payload.component]: {
                                ...state[action.payload.component],
                                data: {
                                    ...state[action.payload.component].data,
                                    [action.payload.field]: {
                                        ...state[action.payload.component].data[action.payload.field],
                                        data: [...action.payload.data]
                                    }
                                }
                            }
                        }
                case budgetManagementTypes.SET_BUDGET_COMPONENT_FIELD_INPUT_AND_TEXT:
                    return {
                        ...state,
                        [action.payload.component]: {
                            ...state[action.payload.component],
                            data: {
                                ...state[action.payload.component].data,
                                [action.payload.field]: {
                                    ...state[action.payload.component].data[action.payload.field],
                                    input: action.payload.input,
                                    text: action.payload.text,
                                    validation: action.payload.validation
                                }
                            }
                        }
                    }
        case licenseeTypes.SET_LICENSEE_COMPONENT_FIELD_INPUT:
            //console.log('state',state)
            //console.log('state',state[action.payload.component].data[action.payload.field])
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            validation: action.payload.validation
                        }
                    }
                }
            }
        case subAccountTypes.SET_SUB_ACCOUNT_COMPONENT_FIELD_INPUT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            validation: action.payload.validation
                        }
                    }
                }
            }
            case subAccountTypes.SET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA:
                return {
                    ...state,
                    [action.payload.component]: {
                        ...state[action.payload.component],
                        data: {
                            ...state[action.payload.component].data,
                            [action.payload.field]: {
                                ...state[action.payload.component].data[action.payload.field],
                                data: action.payload.data
                            }
                        }
                    }
                }
        case resellerTypes.SET_RESELLER_COMPONENT_FIELD_INPUT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            validation: action.payload.validation
                        }
                    }
                }
            }
        case resellerTypes.SET_RESELLER_COMPONENT_FIELD_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case budgetManagementTypes.SET_BUDGET_COMPONENT_FIELD_INPUT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            validation: action.payload.validation
                        }
                    }
                }
            }
        case teamManagerTypes.SET_TEAM_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            validation: action.payload.validation
                        }
                    }
                }
            }
        case teamManagerTypes.SET_TEAM_COMPONENT_FIELD_INPUT_AND_TEXT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            text: action.payload.text,
                            validation: action.payload.validation
                        }
                    }
                }
            }
        case teamManagerTypes.SET_TEAM_COMPONENT_FIELD_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case userOptionsTypes.SET_USER_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            validation: action.payload.validation
                        }
                    }
                }
            }
        case userOptionsTypes.SET_USER_COMPONENT_FIELD_INPUT_AND_TEXT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            text: action.payload.text,
                            validation: action.payload.validation
                        }
                    }
                }
            }
        case userOptionsTypes.SET_USER_COMPONENT_LIST_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case clientAccountTypes.SET_CLIENT_ACCOUNT_COMPONENT_FIELD_INPUT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            validation: action.payload.validation
                        }
                    }
                }
            }
        case controlsTypes.SET_CONTROL_COMPONENT_INPUT_FIELD_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.input,
                            validation: action.payload.validation
                        }
                    }
                }
            }
        case formTypes.GET_COMPONENT_FIELD_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    data: {
                        ...state[action.payload.component.name].data,
                        [action.payload.field.name]: {
                            ...state[action.payload.component.name].data[action.payload.field.name],
                            text: action.payload.text
                        }
                    }
                }
            }
        case formTypes.SET_COMPONENT_FIELD_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case budgetManagementTypes.SET_BUDGET_COMPONENT_FIELD_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case workflowQuoteHeaderTypes.SET_COMPONENT_FIELD_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case quoteHeaderTypes.SET_COMPONENT_MMCODE_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case quoteHeaderSTRTypes.STR_SET_COMPONENT_MMCODE_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case quoteHeaderTypes.SET_FORM_MMCODE_DETAILS:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) =>
                            i === action.payload.inx
                                ? {
                                    ...x,
                                    retailprice: action.payload.data.retailprice,
                                    vehicleprice: action.payload.data.vehicleprice,
                                    discount: action.payload.data.discount,
                                    deliveryperiod: action.payload.data.deliveryperiod,
                                    mm_code: action.payload.data.mm_code,
                                    interestrate: action.payload.data.interestrate,
                                    primerate: action.payload.data.primerate,
                                    deviation: action.payload.data.deviation,
                                    rvpercentage: action.payload.data.rvpercentage,
                                    rv_amount: action.payload.data.rv_amount,
                                    tyrecpk: action.payload.data.tyrecpk,
                                    maintcpk: action.payload.data.maintcpk,
                                    imagefilename: action.payload.data.imagefilename,
                                    manualtotalmaintcpk: action.payload.data.manualtotalmaintcpk,
                                    financeexcess: action.payload.data.financeexcess,
                                    mmcode_fktext: action.payload.data.mmcode_fktext,
                                    mmcode_id: action.payload.data.mmcode_id
                                }
                                : x
                        )
                    }
                }
            }
        case quoteHeaderSTRTypes.STR_SET_FORM_MMCODE_DETAILS:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) =>
                            i === action.payload.inx
                                ? {
                                    ...x,
                                    vehicleprice: action.payload.data.vehicleprice,
                                    deliveryperiod: action.payload.data.deliveryperiod,
                                    discount: action.payload.data.discount,
                                    interestrate: action.payload.data.interestrate,
                                    deviation: action.payload.data.deviation,
                                    rvpercentage: action.payload.data.rvpercentage,
                                    tyrecpk: action.payload.data.tyrecpk,
                                    maintcpk: action.payload.data.maintcpk,
                                    manualtotalmaintcpk: action.payload.data.manualtotalmaintcpk,
                                    financeexcess: action.payload.data.financeexcess,
                                }
                                : x
                        )
                    }
                }
            }
        case workflowQuoteHeaderTypes.TOGGLE_CUSTOMER_VALUES:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    component_field: state[action.payload.component].component_field.map(x => {
                        return {
                            ...x,
                            'read_only': action.payload.state
                        }
                    })
                }
            }
        case workflowQuoteHeaderTypes.TOGGLE_CUSTOMER_VALUES:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value
                        }
                    }
                }
            }
        case quoteHeaderTypes.SET_QUOTEHEADER_GRID_FIELD_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) =>
                            (i === action.payload.index)
                                ? {
                                    ...x,
                                    [action.payload.field]: action.payload.value,
                                }
                                : x
                        )
                    }
                }
            }
        case quoteHeaderSTRTypes.STR_SET_FIELD_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) =>
                            i === action.payload.index
                                ? {
                                    ...x,
                                    [action.payload.field]: action.payload.value,
                                }
                                : x
                        )
                    }
                }
            }
        case workflowQuoteItemGridTypes.SET_QUOTE_GRID_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) =>
                            i === action.payload.index
                                ? {
                                    ...x,
                                    [action.payload.field]: action.payload.value,
                                }
                                : x
                        )
                    }
                }
            }
        case quoteHeaderTypes.REMOVE_MMCODE_VEHICLE_DETAILS:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data],
                        selected: []
                    }
                }
            }
        case quoteHeaderSTRTypes.STR_REMOVE_MMCODE_VEHICLE_DETAILS:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data],
                        selected: []
                    }
                }
            }
        case workflowQuoteHeaderTypes.SET_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case workflowQuoteHeaderTypes.SET_COMPONENT_QUOTE_HEADER_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) =>
                            i !== 99
                                ? {
                                    ...x,
                                    [action.payload.field]: action.payload.value
                                }
                                : x
                        )
                    }
                }
            }
        case workflowQuoteHeaderTypes.SET_COMPONENT_FIELD_INPUT_TEXT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            text: action.payload.value,
                        }
                    }
                }
            }



        case quoteHeaderTypes.SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case quoteHeaderTypes.SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_TEXT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            text: action.payload.value,
                        }
                    }
                }
            }
        case quoteHeaderTypes.SET_QUOTEHEADER_GRID_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data],
                    }
                }
            }
        case workflowQuoteHeaderTypes.SET_COMPONENT_LOOKUP_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case workflowQuoteHeaderTypes.SET_RFQ_QUOTE_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        content: {
                            ...state[action.payload.component].data.content,
                            text: action.payload.preview,
                            input: action.payload.filename,
                        }
                    }
                }
            }
        case workflowQuoteHeaderTypes.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        content: {
                            ...state[action.payload.component].data.content,
                            text: "",
                        }
                    }
                }
            }
        case workflowViewFMLQuoteSummaryTypes.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        content: {
                            ...state[action.payload.component].data.content,
                            text: "",
                        }
                    }
                }
            }
        case workflowViewFMLQuoteSummaryTypes.CLEAR_MOTIVATION_QUOTE_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        filename: {
                            ...state[action.payload.component].data.filename,
                            text: "",
                            input: "",
                        }
                    }
                }
            }
        case workflowViewFMLQuoteSummaryTypes.SET_MOTIVATION_QUOTE_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        filename: {
                            ...state[action.payload.component].data.filename,
                            text: action.payload.preview,
                            input: action.payload.filename,
                        }
                    }
                }
            }
        case workflowQuoteHeaderTypes.QUOTE_VEHICLE_BLANK_ROW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data]
                    }
                }
            }
        case quoteHeaderTypes.QUOTE_VEHICLE_BLANK_ROW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data]
                    }
                }
            }
        case workflowQuoteHeaderTypes.SET_CONTRACT_CATEGORY_VEHICLE_OPTIONS:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data]
                    }
                }
            }

        case workflowQuoteHeaderTypes.CLEAR_QUOTE_VEHICLE_BLANK_ROW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: []
                    }
                }
            }
        case workflowPOHeaderTypes.SET_PO_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case workflowPOHeaderTypes.SET_PO_COMPONENT_FIELD_INPUT_TEXT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            text: action.payload.value,
                        }
                    }
                }
            }
        case workflowPOHeaderTypes.SET_PO_QUOTE_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        content: {
                            ...state[action.payload.component].data.content,
                            text: action.payload.preview,
                        },
                        filename: {
                            ...state[action.payload.component].data.filename,
                            text: action.payload.filename,
                        }
                    }
                }
            }
        case workflowPOHeaderTypes.CLEAR_PO_QUOTE_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        content: {
                            ...state[action.payload.component].data.content,
                            text: "",
                        }
                    }
                }
            }
        case workflowPOHeaderTypes.SET_PO_COMPONENT_GRID_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [action.payload.data]
                    }
                }
            }

        case workflowPOHeaderTypes.SET_PO_COMPONENT_FIELD_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case workflowPOHeaderTypes.SET_PO_SUPPLIER_BLANK_ROW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data]
                    }
                }
            }
        case workflowPOSupplierTypes.SET_PO_SUPPLIER_COMPONENT_FIELD_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case workflowPOSupplierTypes.SET_PO_SUPPLIER_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case workflowPOSupplierTypes.SET_PO_SUPPLIER_COMPONENT_FIELD_INPUT_TEXT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            text: action.payload.value,
                        }
                    }
                }
            }
        case workflowPOSupplierTypes.SET_PO_SUPPLIER_COMPONENT_GRID_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [action.payload.data]
                    }
                }
            }
        case workflowPOSupplierTypes.SET_PO_SUPPLIERS_SELECTED_COMPONENT_GRID_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: action.payload.data
                    }
                }
            }
        case pdfTypes.PDF_CLEAR_SURROGATEHEADER_GRID:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: []
                    }
                }
            }

        case pdfTypes.PDF_QUOTE_GRID_ADD_ROW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data]
                    }
                }
            }
        case quoteEditTypes.LOAD_QUOTE_EDIT_GRID_DATA_ROWS:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: action.payload.data
                    }
                }
            }
        case quoteEditTypes.QUOTE_EDIT_SET_FIELD_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) =>
                            i === action.payload.index
                                ? {
                                    ...x,
                                    [action.payload.field]: action.payload.value,
                                }
                                : x
                        )
                    }
                }
            }

            case quoteEditTypes.QUOTE_EDIT_UPDATE_ACCESSORY_INPUT_FIELD_VALUE:
                return {
                    ...state,
                    [action.payload.component]: {
                        ...state[action.payload.component],
                        grid: {
                            ...state[action.payload.component].grid,
                            data: state[action.payload.component].grid.data.map((x, i) => {
                                return i === action.payload.index
                                    ? {
                                        ...x,
                                        accessory: state[action.payload.component].grid.data[action.payload.index].accessory.map((c, j) => {
                                            return j === action.payload.row
                                                ? {
                                                    ...c,
                                                    totalprice: action.payload.value
                                                }
                                                : c
                                        })
                                    }
                                    : x
                            })
                        }
                    }
                }
        case quoteEditTypes.QUOTE_EDIT_DELETE_ACCESSORY_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        data: state[action.payload.component.name].grid.data.map((x, i) => {
                            let sortedList = action.payload.accessory.sort((x, y) => x.itemname - y.itemname)
                           return i === action.payload.index?
                            {
                                ...x,
                                accessory: sortedList
                            }: x
                        })
                    }
                }
            }
        //edit

        case quoteEditTypes.QUOTE_ADD_NEW_ACCESSORY_ITEM_TO_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) => {
                            return {
                                ...x,
                                service_list: state[action.payload.component].grid.data[action.payload.index].service_list.concat(action.payload.accessory)
                            }
                        })
                    }
                }
                // [action.payload.component.name]: {
                //     ...state[action.payload.component.name],
                //     grid: {
                //         ...state[action.payload.component.name].grid,
                //         data: state[action.payload.component.name].grid.data.map((x, i) => {
                //             return {
                //                 ...x,
                //                 accessory: action.payload.accessory
                //             }
                //         })
                //     }
                // }
            }

        case quoteEditTypes.QUOTE_EDIT_ADD_ACCESSORY_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.component.name]: {
                    ...state[action.payload.component.name],
                    grid: {
                        ...state[action.payload.component.name].grid,
                        data: state[action.payload.component.name].grid.data.map((x, i) => {
                            return i === action.payload.index?
                            {
                                ...x,
                                row_inx: i,
                                accessory: action.payload.accessory.sort((x, y) => x.itemname - y.itemname)
                            }:x
                        })
                    }
                }
            }
        case quoteEditTypes.QUOTE_EDIT_SERVICE_SET_FIELD_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) => {
                            return i === action.payload.index
                                ? {
                                    ...x,
                                    service_list: state[action.payload.component].grid.data[action.payload.index].service_list.map((c, j) => {
                                        return j === action.payload.row
                                            ? {
                                                ...c,
                                                totalprice: action.payload.value
                                            }
                                            : c
                                    })
                                }
                                : x
                        })
                    }
                }
            }
        case quoteEditTypes.QUOTE_EDIT_SUNDRY_SET_FIELD_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) => {
                            return i === action.payload.index
                                ? {
                                    ...x,
                                    sundry_list: state[action.payload.component].grid.data[action.payload.index].sundry_list.map((c, j) => {
                                        return j === action.payload.row
                                            ? {
                                                ...c,
                                                totalprice: action.payload.value
                                            }
                                            : c
                                    })
                                }
                                : x
                        })
                    }
                }
            }
        case quoteEditTypes.RESET_QUOTE_EDIT_GRID_DATA_ROWS:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: []
                    }
                }
            }
        case quoteEditTypes.QUOTE_EDIT_SET_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case workflowViewFMLQuoteSummaryTypes.SET_ACCESSORY_SUPPLIERS_LIST_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: action.payload.data
                    }
                }
            }

        case workflowViewFMLQuoteSummaryTypes.SET_ACCESSORY_SUPPLIERS_SELECTED_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case workflowViewFMLQuoteSummaryTypes.SET_PO_SELECTED_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data,
                        }
                    }
                }
            }
        case workflowViewFMLQuoteSummaryTypes.SET_QUITE_PO_FIELD_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case workflowViewFMLQuoteSummaryTypes.SET_QUITE_PO_ACCESSORY_SUPPLUERFIELD_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: state[action.payload.component].grid.data.map((x, i) =>
                            i === action.payload.index
                                ? {
                                    ...x,
                                    supplier_id: action.payload.value,
                                    suppliername: action.payload.text,
                                }
                                : x
                        )
                    }
                }
            }
        case workflowTaskStepComponentSingleSelectorTypes.SET_COMPONET_GRID_SELECTED_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    selected: action.payload.selected
                }
            }
        case workflowPOReceiptVehicle.SET_RECEIPT_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        content: {
                            ...state[action.payload.component].data.content,
                            text: action.payload.preview,
                        },
                        filename: {
                            ...state[action.payload.component].data.filename,
                            text: action.payload.filename,
                        }
                    }
                }
            }
        case workflowPOReceiptVehicle.CLEAR_RECEIPT_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        content: {
                            ...state[action.payload.component].data.content,
                            text: "",
                        }
                    }
                }
            }
        case workflowPOReceiptVehicle.SET_COMPONENT_FIELD_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data,
                        }
                    }
                }
            }

        case workflowPOReceiptVehicle.SET_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            input: action.payload.value
                        }
                    }
                }
            }
        case workflowPOReceiptVehicle.SET_COMPONENT_FIELD_INPUT_TEXT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            text: action.payload.value
                        }
                    }
                }
            }
        case workflowTaskStepQuoteSingleSelectorTypes.RESET_COMPONENT_GRID_SELECTION:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    selected: []
                }
            }
        case workflowQuoteHeaderTypes.SET_VEHICLE_DEFAULT_ACCESSORY_LIST:

            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    grid: {
                        ...state[action.payload.component].grid,
                        data: [...action.payload.data]
                    }
                }
            }
        // case customerInvoiceTypes.SET_ANY_FIELD_INPUT_VALUE:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 [action.payload.prop]: {
        //                     ...state[action.payload.component].data[action.payload.prop],
        //                     input: action.payload.value,
        //                 }
        //             }
        //         }
        //     }
        case customerReceiptTypes.SET_ANY_FIELD_INPUT_TOGGLE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            text: action.payload.value,
                        }
                    }
                }
            }
        case supplierPaymentTypes.SET_PAY_CREDITORS_LIST:
            return {
                ...state,
                supplierpayment: {
                    ...state.supplierpayment,
                    data: {
                        ...state.supplierpayment.data,
                        supplier_id: {
                            ...state.supplierpayment.data.supplier_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierPaymentTypes.SET_PAY_BUSINESS_LIST:
            return {
                ...state,
                supplierpayment: {
                    ...state.supplierpayment,
                    data: {
                        ...state.supplierpayment.data,
                        business_id: {
                            ...state.supplierpayment.data.business_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierPaymentTypes.SET_PAY_CREDITORS_LOOKUP:
            return {
                ...state,
                supplierpayment: {
                    ...state.supplierpayment,
                    data: {
                        ...state.supplierpayment.data,
                        [action.payload.prop]: {
                            ...state.supplierpayment.data[action.payload.prop],
                            input: action.payload.value,
                            text: action.payload.text
                        }
                    }
                }
            }
        case supplierPaymentTypes.SET_PAY_FIELD_INPUT_VALUE:
            return {
                ...state,
                supplierpayment: {
                    ...state.supplierpayment,
                    data: {
                        ...state.supplierpayment.data,
                        [action.payload.prop]: {
                            ...state.supplierpayment.data[action.payload.prop],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case supplierPaymentTypes.SET_PAY_ACCOUNT_LIST:
            return {
                ...state,
                supplierpayment: {
                    ...state.supplierpayment,
                    data: {
                        ...state.supplierpayment.data,
                        accountingitem_id: {
                            ...state.supplierpayment.data.accountingitem_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierPaymentTypes.SET_PAY_DISCOUNT_LIST:
            return {
                ...state,
                supplierpayment: {
                    ...state.supplierpayment,
                    data: {
                        ...state.supplierpayment.data,
                        discountaccount_id: {
                            ...state.supplierpayment.data.discountaccount_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierPaymentTypes.SET_PAY_SELECTED_ITEM:
            return {
                ...state,
                supplierpayment: {
                    ...state.supplierpayment,
                    data: {
                        ...state.supplierpayment.data,
                        [action.payload.prop]: {
                            ...state.supplierpayment.data[action.payload.prop],
                            input: action.payload.value,
                            text: action.payload.text
                        }
                    }
                }
            }
        case supplierPaymentTypes.SET_SUPPLIER_PAYMENT_ACCOUNT:
            return {
                ...state,
                supplierpayment: {
                    ...state.supplierpayment,
                    data: {
                        ...state.supplierpayment.data,
                        credit: {
                            ...state.supplierpayment.data.credit,
                            input: action.payload.data.invoiceamount
                        },
                        nettvalue: {
                            ...state.supplierpayment.data.nettvalue,
                            input: action.payload.data.invoiceamount
                        }
                    }
                }
            }
        case supplierReversePayTypes.SET_REVERSE_PAY_ACCOUNT:
            return {
                ...state,
                supplierpaymentreversal: {
                    ...state.supplierpaymentreversal,
                    data: {
                        ...state.supplierpaymentreversal.data,
                        credit: {
                            ...state.supplierpaymentreversal.data.credit,
                            input: action.payload.data.invoiceamount
                        },
                        nettvalue: {
                            ...state.supplierpaymentreversal.data.nettvalue,
                            input: action.payload.data.invoiceamount
                        }
                    }
                }
            }

        case supplierReversePayTypes.SET_REVERSE_PAY_CREDITORS_LIST:
            return {
                ...state,
                supplierpaymentreversal: {
                    ...state.supplierpaymentreversal,
                    data: {
                        ...state.supplierpaymentreversal.data,
                        supplier_id: {
                            ...state.supplierpaymentreversal.data.supplier_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierReversePayTypes.SET_REVERSE_PAY_BUSINESS_LIST:
            return {
                ...state,
                supplierpaymentreversal: {
                    ...state.supplierpaymentreversal,
                    data: {
                        ...state.supplierpaymentreversal.data,
                        business_id: {
                            ...state.supplierpaymentreversal.data.business_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierReversePayTypes.SET_REVERSE_PAY_CREDITORS_LOOKUP:
            return {
                ...state,
                supplierpaymentreversal: {
                    ...state.supplierpaymentreversal,
                    data: {
                        ...state.supplierpaymentreversal.data,
                        [action.payload.prop]: {
                            ...state.supplierpaymentreversal.data[action.payload.prop],
                            input: action.payload.value,
                            text: action.payload.text
                        }
                    }
                }
            }
        case supplierReversePayTypes.SET_REVERSE_PAY_FIELD_INPUT_VALUE:
            return {
                ...state,
                supplierpaymentreversal: {
                    ...state.supplierpaymentreversal,
                    data: {
                        ...state.supplierpaymentreversal.data,
                        [action.payload.prop]: {
                            ...state.supplierpaymentreversal.data[action.payload.prop],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case supplierReversePayTypes.SET_REVERSE_PAY_DISCOUNT_LIST:
            return {
                ...state,
                supplierpaymentreversal: {
                    ...state.supplierpaymentreversal,
                    data: {
                        ...state.supplierpaymentreversal.data,
                        discountaccount_id: {
                            ...state.supplierpaymentreversal.data.discountaccount_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierReversePayTypes.SET_REVERSE_PAY_ACCOUNT_LIST:
            return {
                ...state,
                supplierpaymentreversal: {
                    ...state.supplierpaymentreversal,
                    data: {
                        ...state.supplierpaymentreversal.data,
                        accountingitem_id: {
                            ...state.supplierpaymentreversal.data.accountingitem_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierAllocationTypes.SET_ALLOCATION_CREDITORS_LIST:
            return {
                ...state,
                supplierpaymentallocation: {
                    ...state.supplierpaymentallocation,
                    data: {
                        ...state.supplierpaymentallocation.data,
                        supplier_id: {
                            ...state.supplierpaymentallocation.data.supplier_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierAllocationTypes.SET_ALLOCATION_CREDITORS_LOOKUP:
            return {
                ...state,
                supplierpaymentallocation: {
                    ...state.supplierpaymentallocation,
                    data: {
                        ...state.supplierpaymentallocation.data,
                        [action.payload.prop]: {
                            ...state.supplierpaymentallocation.data[action.payload.prop],
                            input: action.payload.value,
                            text: action.payload.text
                        }
                    }
                }
            }
        case supplierAllocationTypes.SET_SUPPLIER_ALLOCATION_DATA:
            return {
                ...state,
                supplierpaymentallocation: {
                    ...state.supplierpaymentallocation,
                    data: {
                        ...state.supplierpaymentallocation.data,
                        supplierpaymentallocation_id: {
                            ...state.supplierpaymentallocation.data.supplierpaymentallocation_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case customerTransactionTypes.SET_CUSTOMER_TRANSACTION_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case contracttermtypes.SET_ANY_VALUE_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierAllocationTypes.SET_FIELD_ALLOCATION_VALUE:
            return {
                ...state,
                supplierpaymentallocation: {
                    ...state.supplierpaymentallocation,
                    data: {
                        ...state.supplierpaymentallocation.data,
                        supplierpaymentallocation_id: {
                            ...state.supplierpaymentallocation.data.supplierpaymentallocation_id,
                            data: state.supplierpaymentallocation.data.supplierpaymentallocation_id.data.map((x, i) => {
                                return x.suppliertransaction_id === action.payload.data.id
                                    ? {
                                        ...x,
                                        allocationamount: action.payload.data.value,
                                        valueupdated: true,
                                    }
                                    : x
                            }
                            )
                        }
                    }
                }
            }
        case supplierAllocationTypes.SET_CALCULATED_ALLOCATION_DATA:
            return {
                ...state,
                supplierpaymentallocation: {
                    ...state.supplierpaymentallocation,
                    data: {
                        ...state.supplierpaymentallocation.data,
                        allocationbalance: {
                            ...state.supplierpaymentallocation.data.allocationbalance,
                            input: action.payload.data.allocationBalance
                        },
                        unallocated: {
                            ...state.supplierpaymentallocation.data.unallocated,
                            input: action.payload.data.unAllocated
                        },
                        total: {
                            ...state.supplierpaymentallocation.data.total,
                            input: action.payload.data.total
                        },
                        credit: {
                            ...state.supplierpaymentallocation.data.credit,
                            input: action.payload.data.credit
                        },
                        age30: {
                            ...state.supplierpaymentallocation.data.age30,
                            input: action.payload.data.age30
                        },
                        age60: {
                            ...state.supplierpaymentallocation.data.age60,
                            input: action.payload.data.age60
                        },
                        age90: {
                            ...state.supplierpaymentallocation.data.age90,
                            input: action.payload.data.age90
                        },
                        age120plus: {
                            ...state.supplierpaymentallocation.data.age120plus,
                            input: action.payload.data.age120plus
                        },
                        current: {
                            ...state.supplierpaymentallocation.data.current,
                            input: action.payload.data.current
                        },
                        allocateall: {
                            ...state.supplierpaymentallocation.data.allocateall,
                            input: action.payload.data.allocateAll
                        }
                    }
                }
            }
        // case customerInvoiceTypes.SET_DEBTORS_LIST:
        //     return {
        //         ...state,
        //         customerinvoice: {
        //             ...state.customerinvoice,
        //             data: {
        //                 ...state.customerinvoice.data,
        //                 customer_id: {
        //                     ...state.customerinvoice.data.customer_id,
        //                     data: action.payload.data
        //                 }
        //             }
        //         }
        //     }
        /* case customerInvoiceTypes.SET_INVOICE_LIST:
            return {
                ...state,
                customerinvoice: {
                    ...state.customerinvoice,
                    data: {
                        ...state.customerinvoice.data,
                        reference: {
                            ...state.customerinvoice.data.reference,
                            data: action.payload.data
                        }
                    }
                }
            } */
        // case customerInvoiceTypes.SET_ANY_BUSINESS_LIST:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 business_id: {
        //                     ...state[action.payload.component].data.business_id,
        //                     data: action.payload.data
        //                 }
        //             }
        //         }
        //     }
        // case customerInvoiceTypes.SET_ANY_INVOICE_ITEMS:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 [action.payload.field]: {
        //                     ...state[action.payload.component].data[action.payload.field],
        //                     data: action.payload.data
        //                 }
        //             }
        //         }
        //     }
        // case customerInvoiceTypes.ADD_ANY_BLANK_ROW:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 [action.payload.field]: {
        //                     ...state[action.payload.component].data[action.payload.field],
        //                     data: action.payload.data
        //                 }
        //             }
        //         }
        //     }
        // case customerInvoiceTypes.SET_ANY_ITEM_INPUT_VALUE:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 [action.payload.field]: {
        //                     ...state[action.payload.component].data[action.payload.field],
        //                     data: action.payload.data
        //                 }
        //             }
        //         }
        //     }
        // case customerInvoiceTypes.REMOVE_ANY_INVOICE_ITEM_ROW:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 [action.payload.field]: {
        //                     ...state[action.payload.component].data[action.payload.field],
        //                     data: action.payload.data
        //                 }
        //             }
        //         }
        //     }
        // case customerInvoiceTypes.SET_ANY_CONTRA_ACCOUNT_LIST:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 [action.payload.field]: {
        //                     ...state[action.payload.component].data[action.payload.field],
        //                     data: action.payload.data
        //                 }
        //             }
        //         }
        //     }
        // case customerInvoiceTypes.SET_ANY_SELECTED_ITEM:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 [action.payload.prop]: {
        //                     ...state[action.payload.component].data[action.payload.prop],
        //                     input: action.payload.value,
        //                     text: action.payload.text
        //                 }
        //             }
        //         }
        //     }

        case customerAllocationTypes.SET_ALLOCATION_DEBTORS_LIST:
            return {
                ...state,
                customerallocation: {
                    ...state.customerallocation,
                    data: {
                        ...state.customerallocation.data,
                        customer_id: {
                            ...state.customerallocation.data.customer_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case customerAllocationTypes.SET_ALLOCATION_MONTH_LIST:
            return {
                ...state,
                customerallocation: {
                    ...state.customerallocation,
                    data: {
                        ...state.customerallocation.data,
                        age: {
                            ...state.customerallocation.data.age,
                            data: action.payload.data
                        }
                    }
                }
            }
        case customerAllocationTypes.SET_CUSTOMER_ALLOCATION_DATA:
            return {
                ...state,
                customerallocation: {
                    ...state.customerallocation,
                    data: {
                        ...state.customerallocation.data,
                        customerallocation_id: {
                            ...state.customerallocation.data.customerallocation_id,
                            data: action.payload.data
                        }
                    }
                }
            }
        case customerAllocationTypes.SET_ANY_CALCULATED_ALLOCATION_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        allocationbalance: {
                            ...state[action.payload.component].data.allocationbalance,
                            input: action.payload.data.allocationBalance
                        },
                        unallocated: {
                            ...state[action.payload.component].data.unallocated,
                            input: action.payload.data.unAllocated
                        },
                        total: {
                            ...state[action.payload.component].data.total,
                            input: action.payload.data.total
                        },
                        credit: {
                            ...state[action.payload.component].data.credit,
                            input: action.payload.data.credit
                        },
                        age30: {
                            ...state[action.payload.component].data.age30,
                            input: action.payload.data.age30
                        },
                        age60: {
                            ...state[action.payload.component].data.age60,
                            input: action.payload.data.age60
                        },
                        age90: {
                            ...state[action.payload.component].data.age90,
                            input: action.payload.data.age90
                        },
                        age120plus: {
                            ...state[action.payload.component].data.age120plus,
                            input: action.payload.data.age120plus
                        },
                        current: {
                            ...state[action.payload.component].data.current,
                            input: action.payload.data.current
                        },
                        allocateall: {
                            ...state[action.payload.component].data.allocateall,
                            input: action.payload.data.allocateAll
                        },
                        canallocate: {
                            ...state[action.payload.component].data.canallocate,
                            input: action.payload.data.canallocate
                        }
                    }
                }
            }
        case supplierAllocationTypes.SET_ANY_SUPPLIER_ALLOCATION_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        allocationbalance: {
                            ...state[action.payload.component].data.allocationbalance,
                            input: action.payload.data.allocationBalance
                        },
                        unallocated: {
                            ...state[action.payload.component].data.unallocated,
                            input: action.payload.data.unAllocated
                        },
                        total: {
                            ...state[action.payload.component].data.total,
                            input: action.payload.data.total
                        },
                        credit: {
                            ...state[action.payload.component].data.credit,
                            input: action.payload.data.credit
                        },
                        debit: {
                            ...state[action.payload.component].data.debit,
                            input: action.payload.data.debit
                        },
                        age30: {
                            ...state[action.payload.component].data.age30,
                            input: action.payload.data.age30
                        },
                        age60: {
                            ...state[action.payload.component].data.age60,
                            input: action.payload.data.age60
                        },
                        age90: {
                            ...state[action.payload.component].data.age90,
                            input: action.payload.data.age90
                        },
                        age120plus: {
                            ...state[action.payload.component].data.age120plus,
                            input: action.payload.data.age120plus
                        },
                        current: {
                            ...state[action.payload.component].data.current,
                            input: action.payload.data.current
                        },
                        allocateall: {
                            ...state[action.payload.component].data.allocateall,
                            input: action.payload.data.allocateAll
                        },
                        canallocate: {
                            ...state[action.payload.component].data.canallocate,
                            input: action.payload.data.canallocate
                        }
                    }
                }
            }
        // case customerInvoiceTypes.SET_ANY_UNIT_MEASURE_LIST:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 reference: {
        //                     ...state[action.payload.component].data.reference,
        //                     data: action.payload.data
        //                 }
        //             }
        //         }
        //     }
        case customerCreditNoteTypes.SET_ANY_DATA_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }

        case customerCreditNoteTypes.REMOVE_ANY_ITEM_ROW:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case customerCreditNoteTypes.DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: false
                        }
                    }
                }
            }
        // case customerInvoiceTypes.INVOICE_DUPLICATE_FOUND:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 reference: {
        //                     ...state[action.payload.component].data.reference,
        //                     text: false
        //                 }
        //             }
        //         }
        //     }

        case customerCreditNoteTypes.NO_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: true
                        }
                    }
                }
            }
        // case customerInvoiceTypes.INVOICE_NO_DUPLICATE_FOUND:
        //     return {
        //         ...state,
        //         [action.payload.component]: {
        //             ...state[action.payload.component],
        //             data: {
        //                 ...state[action.payload.component].data,
        //                 reference: {
        //                     ...state[action.payload.component].data.reference,
        //                     text: true
        //                 }
        //             }
        //         }
        //     }

        case customerJournalTypes.JOURNAL_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: false
                        }
                    }
                }
            }

        case customerJournalTypes.JOURNAL_NO_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: true
                        }
                    }
                }
            }
        case customerReceiptTypes.RECEIPT_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: false
                        }
                    }
                }
            } 
        case customerReceiptTypes.SET_RCPT_MONTH_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            data: action.payload.data
                        }
                    }
                }
            }
        case customerReceiptTypes.SET_BULK_RECEIPT_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            data: action.payload.data
                        }
                    }
                }
            }
        case customerReceiptTypes.RECEIPT_NO_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: true
                        }
                    }
                }
            }

        case customerReverseReceiptTypes.REVERSE_RECEIPT_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: false
                        }
                    }
                }
            }
        case customerReverseReceiptTypes.REVERSE_RECEIPT_NO_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: true
                        }
                    }
                }
            }

        case supplierPaymentTypes.SET_SUPPLIER_PAYMENT_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierPaymentTypes.SUPPLIER_PAYMENT_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: false
                        }
                    }
                }
            }
        case supplierPaymentTypes.SUPPLIER_PAYMENT_NO_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: true
                        }
                    }
                }
            }



        case supplierReversePayTypes.SET_REVERSE_PAYMENT_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            data: action.payload.data
                        }
                    }
                }
            }
        case supplierReversePayTypes.REVERSE_PAYMENT_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: false
                        }
                    }
                }
            }
        case supplierReversePayTypes.REVERSE_PAYMENT_NO_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: true
                        }
                    }
                }
            }
        case customerCreditNoteTypes.SET_CUSTOMER_CREDIT_NOTE_PDF:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            data: action.payload.data
                        }
                    }
                }
            }
        case customerAllocationTypes.SET_ALLOCATION_FIELD_INPUT_VALUE:
            return {
                ...state,
                customerallocation: {
                    ...state.customerallocation,
                    data: {
                        ...state.customerallocation.data,
                        [action.payload.prop]: {
                            ...state.customerallocation.data[action.payload.prop],
                            input: action.payload.value,
                        }
                    }
                }
            }

        case customerStatementTypes.SET_CUSTOMER_STATEMENT_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            data: action.payload.data,
                        }
                    }
                }
            }

        case supplierTransactionTypes.SET_SUPPLIER_TRANSACTION_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            data: action.payload.data,
                        }
                    }
                }
            }
        case customerTransactionTypes.SET_CUSTOMER_TRANSACTION_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            data: action.payload.data,
                        }
                    }
                }
            }
        case customerContactTypes.EDIT_CONTACT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            data: action.payload.data,
                        }
                    }
                }
            }
        case supplierCreditNoteTypes.CN_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: false
                        }
                    }
                }
            }
        case supplierCreditNoteTypes.NO_CN_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: true
                        }
                    }
                }
            }
        case supplierJournalTypes.CREDITORS_JOURNAL_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: false
                        }
                    }
                }
            }
        case supplierJournalTypes.CREDITORS_JOURNAL_NO_DUPLICATE_FOUND:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        reference: {
                            ...state[action.payload.component].data.reference,
                            text: true
                        }
                    }
                }
            }
        case generalledgerTypes.SET_GL_POSTING_DATA:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        },
                        transactiontype: {
                            ...state[action.payload.component].data.transactiontype,
                            input: action.payload.type
                        },
                        transactiondate: {
                            ...state[action.payload.component].data.transactiondate,
                            input: action.payload.date
                        },
                        generalledgermonth: {
                            ...state[action.payload.component].data.generalledgermonth,
                            input: action.payload.month
                        }
                    }
                }
            }
        case contracttermtypes.SET_TERM_VEHICLE_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case costcentrechangetypes.SET_COST_CENTRE_CHANGE_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case registrationchangetypes.SET_REGISTRATION_CHANGE_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case costcentrechangetypes.SET_CHANGE_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case registrationchangetypes.SET_CHANGE_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case contracttermtypes.SET_TERM_LIST:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.field]: {
                            ...state[action.payload.component].data[action.payload.field],
                            data: action.payload.data
                        }
                    }
                }
            }
        case disposaltypes.SET_ANY_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    data: {
                        ...state[action.payload.component].data,
                        [action.payload.prop]: {
                            ...state[action.payload.component].data[action.payload.prop],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case disposaltypes.SET_TERMINATION_LIST:
            return {
                ...state,
                disposal: {
                    ...state.disposal,
                    data: {
                        ...state.disposal.data,
                        disposal_id: {
                            ...state.disposal.data.disposal_id,
                            data: action.payload.data
                        }
                    }
                }
            }

        case billingmoduletypes.SET_BILLING_LIST:
            return {
                ...state,
                billingmodule: {
                    ...state.billingmodule,
                    data: {
                        ...state.billingmodule.data,
                        billingmodule_id: {
                            ...state.billingmodule.data.billingmodule_id,
                            data: action.payload.data
                        }
                    }
                }
            }

        case customerremittancetypes.SET_REMITTANCE_FIELD_INPUT_VALUE:
            return {
                ...state,
                customerremittance: {
                    ...state.customerremittance,
                    data: {
                        ...state.customerremittance.data,
                        [action.payload.prop]: {
                            ...state.customerremittance.data[action.payload.prop],
                            input: action.payload.value,
                        }
                    }
                }
            }
        case customerAllocationTypes.SET_REMITTANCE_INVOICE_LIST:
            return {
                ...state,
                customerallocation: {
                    ...state.customerallocation,
                    data: {
                        ...state.customerallocation.data,
                        allocationreference: {
                            ...state.customerallocation.data.allocationreference,
                            data: action.payload.data
                        }
                    }
                }
            }

        case customerderivativeTypes.SET_CUSTOMER_DERIVATIVE_INPUT_VALUES:
            return {
                ...state,
                customerderivative: {
                    ...state.customerderivative,
                    data: {
                        ...state.customerderivative.data,
                        [action.payload.prop]: {
                            ...state.customerderivative.data[action.payload.prop],
                            input: action.payload.value,
                            text: action.payload.text
                        }
                    }
                }
            }
        default:
            return state
    }
}