import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'

// import { Card, CardHeader, CardContent } from '@material-ui/core'

import Icon from '../../Icon/component'
import Upload from '../../Upload/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'

import Card from '../../../controls/card'
import * as styles from './styles'
import * as actions from './actions'

class ContractRestructureApprove extends Component {
    componentDidMount() {
        //
    }

    getContract(data) {
        //console.log("quote data",data)
        return <Card 
        title={window.t.en('Contract Deviation')}
        subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
        content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={2} style={styles.fieldLabel}>{'Vehicle:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{data.licenseplateno}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Quote Number:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{data.quotenumber}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Contract Period:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{data.period}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Distance Per Month:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{data.distancepermonth}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Contract Distance:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{data.distance}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Contract Description:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{data.contractdescription}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Contract Type:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{data.contracttype ? data.contracttype.contracttype : ''}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Make Model:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{data.makemodelyear}</Col>
                    </Row>
                </Container>
        } />
    }

    getContractAcc(data) {
        console.log('contract data', data);

// Filter the data to include only items where `new` is truthy
let accessory_list = data.filter(x => x.new !== undefined && x.new !== null && x.new);

// Check if accessory_list is not empty, then render the Card component
return accessory_list.length ? (
    <Card
        title={'Accessory Details'}
        subtitle={'Accessory list details'}
        content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row style={styles.headerrow}>
                        <Col xl={4}>
                            {'accessory Name'}
                        </Col>
                        <Col xl={2}>
                            {'Amount'}
                        </Col>
                    </Row>
            </Container>
        }
    />
) : null;

    }
    getContractParameters(data) {
        return <Card 
        title={'Finance Details'}
        subtitle={'New contract details'}
        content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={2} style={styles.fieldLabel}>{'Vehicle Retail Price:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.retailprice).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Vehicle Price:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.vehicleprice).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'RV:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{data.rv}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'RV Amount:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.rvamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Accessory:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.accessoriesselling).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Sundry Selling:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.sundryselling).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Licence:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.licence).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Admin Fee:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.adminfee).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Maintenance:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.maintenance).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Tyres:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.tyres).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Finance Amount:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.finance).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Total Vat:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.monthly_vat).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Monthly Rental:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.monthly_incl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    </Row>
                </Container>
        } />
    }

    viewDocuments(data) {
        const documents = data.documents
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {
                        documents
                            ? <PdfViewer
                                key={'document_viewer_approval'}
                                name={'booking'}
                                workflow={'fml_customer_quote'}
                                task={'tsk_purchase_order_review_and_send'}
                                documents={documents}
                                edit={false}
                                default_document={'Rebill'}
                                workflow_queue_data={this.props.workflow_queue_data}
                            />
                            : <Loading message={'Retrieving Booking Document...'} />
                    }
                </Col>
            </Row>
        </Container>
    }

    uploadDocuments(data) {
        return <Card 
        title={'Document'}
        subtitle={'Upload any supporting document/s related to this deviation'}
        content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'Restructure_'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                } />
    }

    getView(data) {
        //console.log('documentsx',data)
        return <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {this.getContract(data.restrcture_data.confirm.contract[0])}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {this.getContractAcc(data.restrcture_data.confirm.contractaccessory)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {this.getContractParameters(data.restrcture_data.confirm)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {this.viewDocuments(data)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {this.uploadDocuments()}
                        </Col>
                    </Row>
                </Container>
    }

    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) {

        }

        return (
            workflow_queue_data
                ? this.getView(workflow_queue_data)
                : 'Loading...'
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.contractRestructure
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractRestructureApprove)