export const VALIDATE_FIELD = 'VALIDATE_FIELD'
export const VALIDATE_FORM = 'VALIDATE_FORM'
export const SET_FORM_STATUS = 'SET_FORM_STATUS'
export const VALIDATION_SUCCEEDED = 'VALIDATION_SUCCEEDED'
export const VALIDATION_FAILED = 'VALIDATION_FAILED'
export const SHOW_PASSWORD_STRENGTH = 'SHOW_PASSWORD_STRENGTH'
export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD'
export const TOGGLE_SWITCH = 'TOGGLE_SWITCH'
export const TOGGLE_PASSWORD_VISIBILITY = 'TOGGLE_PASSWORD_VISIBILITY'

export const IS_AFTER = 'IS_AFTER'
export const IS_ALPHA = 'IS_ALPHA'
export const IS_ALPHANUMERIC = 'IS_ALPHANUMERIC'
export const IS_ASCII = 'IS_ASCII'
export const IS_BASE64 = 'IS_BASE64'
export const IS_BEFORE = 'IS_BEFORE'
export const IS_BOOLEAN = 'IS_BOOLEAN'
export const IS_BYTELENGTH = 'IS_BYTELENGTH'
export const IS_CREDITCARD = 'IS_CREDITCARD'
export const IS_CURRENCY = 'IS_CURRENCY'
export const IS_DATAURI = 'IS_DATAURI'
export const IS_DECIMAL = 'IS_DECIMAL'
export const IS_DIVISIBLEBY = 'IS_DIVISIBLEBY'
export const IS_EMAIL = 'IS_EMAIL'
export const IS_FQDN = 'IS_FQDN'
export const IS_FLOAT = 'IS_FLOAT'
export const IS_FULLWIDTH = 'IS_FULLWIDTH'
export const IS_HALFWIDTH = 'IS_HALFWIDTH'
export const IS_HEXCOLOR = 'IS_HEXCOLOR'
export const IS_HEXADECIMAL = 'IS_HEXADECIMAL'
export const IS_IP = 'IS_IP'
export const IS_ISBN = 'IS_ISBN'
export const IS_ISSN = 'IS_ISSN'
export const IS_ISIN = 'IS_ISIN'
export const IS_SO8601 = 'IS_SO8601'
export const IS_SRC = 'IS_SRC'
export const IS_IN = 'IS_IN'
export const IS_INT = 'IS_INT'
export const IS_JSON = 'IS_JSON'
export const IS_LENGTH = 'IS_LENGTH'
export const IS_LOWERCASE = 'IS_LOWERCASE'
export const IS_MAC_ADDRESS = 'IS_MAC_ADDRESS'
export const IS_MD5 = 'IS_MD5'
export const IS_MOBILEPHONE = 'IS_MOBILEPHONE'
export const IS_MONGOID = 'IS_MONGOID'
export const IS_MULTIBYTE = 'IS_MULTIBYTE'
export const IS_NOT_EMPTY = 'IS_NOT_EMPTY'
export const IS_NUMERIC = 'IS_NUMERIC'
export const IS_STRONG_PASSWORD = 'IS_STRONG_PASSWORD'
export const IS_SURROGATE_PAIR = 'IS_SURROGATE_PAIR'
export const IS_URL = 'IS_URL'
export const IS_UUID = 'IS_UUID'
export const IS_UPPERCASE = 'IS_UPPERCASE'
export const IS_VARIABLE_WIDTH = 'IS_VARIABLE_WIDTH'
export const IS_WHITE_LISTED = 'IS_WHITE_LISTED'
export const CONTAINS = 'CONTAINS'
export const MATCHES = 'MATCHES'
export const SET_LOGIN_USER_INPUT_FIELDS = 'SET_LOGIN_USER_INPUT_FIELDS'
