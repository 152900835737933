import * as _ from 'lodash'

import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.CLEAR_WORKFLOW_TASK_APPROVAL_INFORMATION:
            return {
                edit: false,
                errors: [],
                finished: false,
                loading: true,
                progress: 0,
                stepIndex: 0,
                steps: 0,
                task: {},
                validate: 0,
                validationfields: null,
                workflow: {},
                workflow_queue: {}
            }
        case types.CLEAR_WORKFLOW_TASK_APPROVAL_DATA:
            return {
                anchorEl: false,
                edit: false,
                errors: [],
                finished: false,
                isopen: false,
                loading: true,
                progress: 0,
                reject: false,
                stepIndex: 0,
                steps: 0,
                task: {},
                viewpdf: false,
                workflow: {},
                workflow_queue: {}
            }
        // case types.GET_WORKFLOW_TASK_APPROVAL_INFORMATION:
        //     return {
        //         ...state,
        //         loading: true,
        //         task: {
        //             loading: true
        //         }
        //     }
        case types.SET_WORKFLOW_TASK_APPROVAL_INFORMATION:
            return {
                ...state,
                error: action.payload ? false : true,
                loading: true,
                task: {
                    ...action.payload,
                    workflow_task_step: action.payload.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: []
                                }
                            })
                        }
                    }),
                    components: _.uniq(
                        action.payload.workflow_task_step.reduce((arr, x) => {
                            x.workflow_task_step_component.forEach(y => {
                                arr.push(y.component.name)
                            })

                            return arr
                        }, [])
                    )
                }
            }
        case types.GET_WORKFLOW_TASK_APPROVAL_DATA:
            return {
                ...state,
                workflow_queue: action.payload,
                loading: true
            }
        case types.SET_WORKFLOW_TASK_APPROVAL_DATA:
            return {
                ...state,
                workflow_queue: action.payload,
                loading: false
            }
        case types.SET_NEXT_WORKFLOW_TEAM_USERS:
            return {
                ...state,
                next_team_users: action.payload
            }
        case types.OPEN_ESCALATION_TEAMS:
            return {
                ...state,
                isopen: true
            }
        case types.OPEN_VIEW_PDF_DOCUMENT:
            return {
                ...state,
                viewpdf: true
            }
        case types.CLOSE_VIEW_PDF_DOCUMENT:
            return {
                ...state,
                viewpdf: false
            }
        case types.OPEN_REJECT_STEP:
            return {
                ...state,
                reject: true
            }
        case types.CLOSE_REJECT_STEP:
            return {
                ...state,
                reject: false
            }
        case types.CLOSE_ESCALATION_TEAMS:
            return {
                ...state,
                isopen: false
            }
        case types.ENCHOR_ESCALATION_TEAMS:
            return {
                ...state,
                anchorEl: action.payload.anchor
            }
        case types.SET_APPROVAL_CONFIRM_TOGGLE_MENU_STATUS:
            return {
                ...state,
                confirm: action.payload.confirm,
                confirmEl: action.payload.confirmEl
            }
        case types.SET_WORKFLOW_TASK_CONNECTOR_DATA:
            return {
                ...state,
                workflow_task_connector: action.payload.workflow_task_connector,
                workflow_task_connector_id: action.payload.workflow_task_connector_id
            }
        case types.CONFIRM_WORKFLOW_TASK_APPROVAL:
            return state
        default:
            return state
    }
}