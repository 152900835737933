import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './hooks'

class Hooks extends Component {
    componentDidMount() {
        //console.log('componentWillMount', this.props.data)
        this.props.actions.signup_amplify(this.props.data)
    }

    render() {
        return (
            <div></div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: state
        }
    }

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hooks)