export const CONFIRM_SINGLE_ROW = 'CONFIRM_SINGLE_ROW'
export const REMOVE_SINGLE_ROW = 'REMOVE_SINGLE_ROW'
export const EXPAND_CREDITOR_VIEW = 'EXPAND_CREDITOR_VIEW'
export const EXPAND_DEBTOR_VIEW = 'EXPAND_DEBTOR_VIEW'
export const GET_COMPONENT_FIELD_LOOKUP_DATA = 'GET_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_COMPONENT_FIELD_LOOKUP_DATA = 'SET_COMPONENT_FIELD_LOOKUP_DATA'
export const CONFIRM_SELECTION = 'CONFIRM_SELECTION'
export const RESET_SELECTION = 'RESET_SELECTION'
export const CONFIRM_QUOTE_ITEMS_SELECTION = 'CONFIRM_QUOTE_ITEMS_SELECTION'
export const GET_QUOTE_TYPES_LOOKUP_DATA = 'GET_QUOTE_TYPES_LOOKUP_DATA'
export const SET_QUOTE_TYPES_LOOKUP_DATA = 'SET_QUOTE_TYPES_LOOKUP_DATA'
export const SET_QUOTE_TYPES_LOOKUP_SELECTION = 'SET_QUOTE_TYPES_LOOKUP_SELECTION'
export const GET_QUOTE_VEHICLE_GROUP_LOOKUP_DATA = 'GET_QUOTE_VEHICLE_GROUP_LOOKUP_DATA'
export const SET_QUOTE_VEHICLE_GROUP_LOOKUP_DATA = 'SET_QUOTE_VEHICLE_GROUP_LOOKUP_DATA'
export const SET_QUOTE_VEHICLE_GROUP_LOOKUP_SELECTION = 'SET_QUOTE_VEHICLE_GROUP_LOOKUP_SELECTION'

export const GET_QUOTE_VEHICLE_MMCODE_LOOKUP_DATA = 'GET_QUOTE_VEHICLE_MMCODE_LOOKUP_DATA'
export const SET_QUOTE_VEHICLE_MMCODE_LOOKUP_DATA = 'SET_QUOTE_VEHICLE_MMCODE_LOOKUP_DATA'
export const SET_QUOTE_VEHICLE_MMCODE_LOOKUP_SELECTION = 'SET_QUOTE_VEHICLE_MMCODE_LOOKUP_SELECTION'

export const SET_ITEM_GRID_PROP_SELECTION = 'SET_ITEM_GRID_PROP_SELECTION'
export const ACC_COMPONET_GRID_SELECTED_DATA = 'ACC_COMPONET_GRID_SELECTED_DATA'