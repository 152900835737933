import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_VEHICLE_DETAILS:
            return {
                ...state,
                description: action.payload.data.makemodelyear,
                category: action.payload.data.sapvehiclecat,
                mmcode: action.payload.data.mmcode,
                registrationdate: action.payload.data.registrationdate,
                yearmodel: action.payload.data.modelyear,
                customer: action.payload.data.customer,                
                customer_id: action.payload.data.customer_id,
                customercontract_id: action.payload.data.customercontract_id,
            }
        case types.SET_CURRENT_TAB:
            return {
                ...state,
                currentTab: action.payload
            }
        default:
            return state
    }
}