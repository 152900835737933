import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'
import Icon from '../Icon/component'
import { Card, CardHeader, CardContent, TextField, Button } from '@mui/material'

import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import CheckBoxControl from '../../controls/checkbox'
import TreeSelect from '../../controls/SelectionControl'
import "react-table/react-table.css"
import Loading from '../Loading/component'

import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";



import * as actions from './actions'
import * as styles from './styles'

class CardView extends Component {


    getFieldValue(field, index) {
        if(!this.props.state.licensee.data) return ''
        let value = ''
      
        try {
            value = this.props.state.licensee.data[field.name] 
                ? this.props.state.licensee.data[field.name].text
                    ? this.props.state.licensee.data[field.name].text 
                    : this.props.state.licensee.data[field.name].input
                : '' 
      
        } catch (error) {
        }
        
        
        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return value 
            case 'datePicker':
            case 'timePicker':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD hh:mm')
                    : 'Not Set'
            case 'textBox':
                return field.component_field_data_type.name === 'decimal' || field.component_field_data_type.name === 'float'
                    ? value !== null && value.length > 0 && !isNaN(value)
                        ? parseFloat(value).toFixed(2)
                        : value
                    : value
            case 'image':
                return <a href={"data:image/jpeg;base64," + value} download><img src={"data:image/jpeg;base64," + value} style={{ height: '500px',width: '500px' }} /></a>
            case 'toggle':
                const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value
                return value !== null
                    ? value
                        ? toggle[0]
                        : toggle[1]
                    : 'Not Set'
            default:
                return value
        }
    }

    getField(field, index) {
        return this.getFieldValue(field, index)
    }

    getSection(section) {
        let fields = this.props.state.licensee.component_field.filter(x => x.form === true).filter(x => x.component_section_id === section.component_section_id)
            .map((x, i) => {
                return <Col key={'col_' + x.name} xl={6}>
                     <FormControlLabel
                            fullWidth={true}
                            control={
                                <Typography variant="body1" component="div"
                                style={{fontWeight: 'bold'}}>
                                    {this.getField(x, i)} 
                                </Typography>
                            }
                            label={
                                <label style={{width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
                                    {x.title + ": "}
                                </label>  
                            }
                            labelPlacement="start"
                        />
                        </Col>
            })

        return <Card key={'card_' + section.name} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={'material-icons'} iname={'edit'} />}
                title={section.title}
                subtitle={section.description}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        {fields}
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getSave() {
        return <Card key={'card_btn'} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={'material-icons'} iname={'edit'} />}
                title={"Save"}
                subtitle={"Save License Account"}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row style={styles.pullright}>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            for(let x of this.props.state.licensee.component_field){
                                this.props.actions.setComponentFieldInput(x,null)
                            }

                            this.props.actions.changeTabView(0)
                        }}
                        variant="contained"
                        className="btn-warning pull-right d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                        >
                        {<Icon iclass={'material-icons'} iname={'cancel'} />} &nbsp; {'Cancel'}
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.saveLicenseeData(this.props.state.licensee, this.props.state.user.firstname + ' ' + this.props.state.user.lastname)
                        }}
                        variant="contained"
                        className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                        >
                        {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {'Save Account'}
                    </Button>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }
   
    getForm() {
        return this.props.state.licensee.component_section
            .map((x) => {
                return this.getSection(x)
            })
    }

    render() {
        return [this.getForm()]
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.licenseeAccount,
            licensee: {
                ...state.components.licensee
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardView)