import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'

export const getVehicles = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_VEHICLES)
        ,delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/mmcode/search?makemodelyear=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'makemodelyear',
                    'order': '-regyear'
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setVehicles(payload.id, result.response.data),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/mmcode/search' + payload.query)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mmcode/search' + payload.query, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
