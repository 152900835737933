import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import {MenuItem,Menu,Select,Stack} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import {ReactComponent as EditIcon} from '../../assets/img/svg/icons/edit_grid.svg';
import {ReactComponent as SettingsIcon} from '../../assets/img/svg/icons/Configuration-data.svg';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import ReactTable from "react-table"
import "react-table/react-table.css"

import Icon from '../Icon/component'
import Loading from '../Loading/component'

import { emphasize, styled, alpha } from '@mui/material/styles';

import { Tooltip, Box } from '@mui/material';
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    gridClasses,
    useGridApiRef
  } from '@mui/x-data-grid-premium';
  
  import { LicenseInfo } from '@mui/x-license-pro';
  import * as config from '../../config'
  
  
  LicenseInfo.setLicenseKey('0dc2c32f83edb803ade140958edb564aTz04MjQzNSxFPTE3MzcxOTI2NzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
  

import * as actions from './actions'
import * as styles from './styles'

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: 'red',//theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));

  const CustomNoRowsOverlay = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">No Rows</Box>
    );
  }

  const CustomNoResultsOverlay = () =>{
    return(
    <Box display="flex" justifyContent="center" alignItems="center">No Results</Box>
    );
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton width={'200px'} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }


class CardGrid extends Component {

    getCommands(row) {
        let field = this.props.state.cards_header.filter(x => x.key === true)[0]
        const id = row.original[field.name]
        let i = row.index
        let commands = []

        commands.push(
            <IconButton key={'command_view_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
                tooltip={'Vehicle details'} color="primary"
                onClick={
                    () => {
                        this.props.actions.setCardData({prop: 'card', data: row.original})
                        this.props.actions.loadCardData(id)
                    }
                }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'edit'} />
            </IconButton>
        )
        
        commands.push(
            <IconButton key={'command_decision_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => {
                    this.props.actions.cardSettingMenus(true, event.currentTarget)
                }
            }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'settings'} />
            </IconButton>
        )

        return commands
    }

    getValue(row) {       
        let field = this.props.state.cards_header.filter(x => x.name === row.column.meta)[0]
            switch (field.type) {
                case 'month':
                    return moment(row.value).format('MM')
                    case 'date':
                        return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
    }

    getFormFieldLabel(row) {
          return <div
          style={{
            width: '100%',
            marginTop: '3px',
            verticalAlign: 'baseline',
            fontSize: '15px'
        }} >{this.getValue(row)}</div>
    }

    // getColumns() {
    //     const fields = this.props.state.cards_header.filter(x => x.key === false)
    //     let columns =
    //         fields.map(x => {
    //             return {
    //                 meta: x.name,
    //                 Header: x.title,
    //                 accessor: x.display,
    //                 filterable: true,
    //                // width: x.width ? x.width : undefined,
    //                 headerStyle: {
    //                     fontSize: '13px',
    //                     fontWeight: 'bold',
    //                     textAlign: 'left',
    //                 },
    //                 style: {
    //                     verticalAlign: 'middle',
    //                     fontSize: 12,
    //                     letterSpacing: 0,
    //                     color: 'black',
    //                     //: x.bold ? 'bold' : 'normal',
    //                     textAlign:  'left'
    //                 }
    //             }
    //         })

    //         columns.unshift({
    //             //Header: '',
    //             accessor: 'options',
    //             filterable: false,
    //             maxWidth: 80,
    //             width: 80,
    //             Cell: (row) => (
    //                 this.getCommands(row)
    //             )
    //         })
    //     return columns
    // }

    getHeader() {
        const fields = this.props.state.cards_header.filter(x => x.key === false)
        let headers = fields.map(x => {
          return x.type === 'decimal' || x.type === 'integer'
          ?  {
              field: x.name, 
              headerName: x.title, 
              headerClassName: 'datagrid_header',
              flex: 1,
              editable: false,
              filterable: true,
              //type: 'number',
              //align: "center",
              renderCell: (params) => {
                let _value = x.display ? params.row[x.display.split('.')[0]] ? params.row[x.display.split('.')[0]][x.display.split('.')[1]] : '' : params.row[x.name]
                switch (x.type) {
                    case 'timestamp':
                        _value =  _value ? moment(_value).format('YYYY-DD-MM hh:mm') : ''
                        break;
                }
                return _value // ? _value.replace('.',',') : 0 //<div className="rowitem">{_value}</div>;
              },
          }
          : {
              field: x.name, 
              headerName: x.title, 
              headerClassName: 'datagrid_header',
              flex: 1,
              editable: false,
              renderCell: (params) => {
                let _value = params.row[x.name]
                switch (x.type) {
                      case 'timestamp':
                          _value = _value ? moment(_value).format('YYYY-MM-DD') : ''
                          break;
                      case 'date':
                        //console.log('date _value', moment(_value).format('YYYY-MM-DD'))
                          _value = _value ? moment(_value).format('YYYY-MM-DD') : ''
                          break;
                      case 'boolean':
                          _value = _value == null ? '' : _value ? 'Yes' : 'No'
                          break;
                          default: 
                          _value = _value
                          break;

                  }
                return _value //<div className="rowitem">{_value}</div>;
              },
          }
      })
      return headers
    }

    getColumns() {
        return  [{
              field: 'action',
              headerName: 'Action',
              headerClassName: 'datagrid_header',
              sortable: false,
              filterable: false,
              disableExport: true,
              width: this.props.state.name !== 'customercontract' && this.props.state.name !== 'supplier' ? 150 : 60,
              renderCell: (params) => {
                //console.log('params', params);
                return params.rowNode.type === 'leaf' ? 
                <Stack direction="row" alignItems="center" spacing={0}>
                {/* <Tooltip title="View" placement="bottom">
                <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                    //props.actions.setReadComponentView('view', state.component.name)
                    //props.actions.changeDetailComponentTab(0)
                    //props.actions.clearDetailComponentVisualisations()
                    
                    //props.actions.route(
                    //</Tooltip>    '/view/' + state.component.name + '/' + params.id
                    //)

                }}>
                <VisibilityIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
              </IconButton>
              </Tooltip> */}
              {
                <Tooltip title="Edit Card" placement="bottom">
                <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                    //console.log('___paramsx', params, '/view//' + params.id)
                    this.props.actions.setCardData({prop: 'card', data: params.row})
                    this.props.actions.loadCardData(params.id)
                }}>
                <EditIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
              </IconButton>
              </Tooltip>
              }
              {
                <Tooltip title="Manage Card" placement="bottom">
                <IconButton color="primary" aria-label="upload picture" component="div" onClick={(event) => {
                  this.props.actions.cardSettingMenus(true, event.currentTarget)
                }}>
                <SettingsIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
              </IconButton>
              </Tooltip>
              }
              </Stack> : <></>
            }
        }].concat(this.getHeader()) //state.component.grid.headers)
    }

    getGrid() {
        return <div>
        
            {/* <ReactTable
                data={this.props.state.cards}
                columns={this.getColumns()}
                manual
                loading={this.loading}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable> */}
            <StripedDataGrid 
                        getRowId={(x) =>  x['card_id']}
                        rows={this.props.state.cards} 
                        rowHeight={38}
                        loading={this.props.state.cards.length ? false : true}
                        columns={this.getColumns()}
                        slots={{ toolbar: CustomToolbar, noRowsOverlay: CustomNoRowsOverlay, noResultsOverlay: CustomNoResultsOverlay }}
                        // onPaginationModelChange={(event) => {
                        //     this.props.actions.getComponentGridData(props.state.component, null, {...event,sorted: []}, null, null)
                        // }}
                        pageSize={this.props.state.cards.length}
                        //rowsPerPageOptions={[5, 10, 20]}
                        //paginationMode={"server"}
                        rowCount={this.props.state.cards.length}
                        pagination getRowClassName={(params) => {
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }}
                        //filterMode={'server'}
                        // onFilterModelChange={(event, model) => {
                        //   //console.log('eventx filter',event.items)
                        //   this.props.actions.filterInProgress(event.items)
                        //   this.props.actions.getComponentGridData(props.state.component, null, {...event,sorted: []}, event.items, null)
                        // }}
                        autoHeight={true}
                        // disableColumnFilter
                        // unstable_headerFilters
                        // initialState={{
                        //     pagination: { paginationModel: { ...state.component.grid.paging } },
                        //     rowGrouping: {
                        //         model: [state.component.group],
                        //     },
                        //     // columns: {
                        //     //   columnVisibilityModel: { [state.component.group]: false, action: true},
                        //     // },
                        //     aggregation: {
                        //         model: { [state.component.aggregate]: 'sum' },
                        //     },
                        // }}
                        // groupingColDef={{
                        //  leafField: 'action'
                        // }}
                        // experimentalFeatures={{ aggregation: true }}
                        // onExcelExportStateChange={(inProgress) => { 
                        //   //console.log('inProgress', inProgress)
                        //   if(inProgress === 'finished'){
                        //     this.props.actions.exportInProgress(false)
                        //   }else{
                        //     this.props.actions.exportInProgress(true)
                        //   }
                        // }}
                        //apiRef={apiRef}
                    />
            <Menu
              anchorEl={this.props.state.cardsettingswinel}
              //anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              id={'primary-search-account-menu'}
              keepMounted
              //transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
             open={this.props.state.cardsettingswin}
             onClose={(event) => {
                event.preventDefault()
               this.props.actions.cardSettingMenus(false, null)
            }}
            >
            <MenuItem onClick={(event) => {
                event.preventDefault()
                this.props.actions.cardSettingMenus(false, null)
                this.props.actions.setCardData({prop: 'secretkey_dialog', data: true})
            }}>Request Secret Key</MenuItem>
            <MenuItem onClick={(event) => {
                event.preventDefault()
                this.props.actions.cardSettingMenus(false, null)
                this.props.actions.setCardData({prop: 'resetpin_dialog', data: true})
            }}>Reset PIN</MenuItem>
            <MenuItem onClick={(event) => {
                event.preventDefault()
                this.props.actions.cardSettingMenus(false, null)
                this.props.actions.setCardData({prop: 'decision_dialog', data: true})
            }}>
            Enable Automated FMS Decision Engine</MenuItem>
            <MenuItem onClick={(event) => {
                event.preventDefault()
                this.props.actions.cardSettingMenus(false, null)
                this.props.actions.setCardData({prop: 'currentpin_dialog', data: true})
            }}>Retrieve Current PIN</MenuItem>
            <MenuItem onClick={(event) => {
                event.preventDefault()
                this.props.actions.cardSettingMenus(false, null)
                this.props.actions.setCardData({prop: 'transactions_dialog', data: true})
            }}>Request Financial Transactions</MenuItem>
            <MenuItem onClick={(event) => {
                event.preventDefault()
                this.props.actions.cardSettingMenus(false, null)
                this.props.actions.setCardData({prop: 'statement_dialog', data: true})
            }}>Request Statement </MenuItem>
            </Menu>


            
        <Dialog
            open={this.props.state.secretkey_dialog}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setCardData({prop: 'secretkey_dialog', data: false})
            }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
             <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
              9ab396fb-aecb-45a7-b289-59ffe0147954
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                Please copy the key before you can 
              </p>
              <div className="pt-4">
                <Button 
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setCardData({prop: 'secretkey_dialog', data: false})
                    }}
                    className="btn-pill mx-1">
                    <span className="btn-wrapper--label">Ok</span>
                </Button>
              </div>
            </div> 
          </Dialog>

          <Dialog
            open={this.props.state.transactions_dialog}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setCardData({prop: 'transactions_dialog', data: false})
            }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
             <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
              You have requested new financial statement
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                You request has been queued, we will send you email once your requested has been completed.
              </p>
              <div className="pt-4">
                <Button 
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setCardData({prop: 'transactions_dialog', data: false})
                    }}
                    className="btn-pill mx-1">
                    <span className="btn-wrapper--label">Ok</span>
                </Button>
              </div>
            </div> 
          </Dialog>

          <Dialog
            open={this.props.state.statement_dialog}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setCardData({prop: 'statement_dialog', data: false})
            }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
             <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
              You have requested new statements
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                You request has been queued, we will send you email once your requested has been completed.
              </p>
              <div className="pt-4">
                <Button 
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setCardData({prop: 'statement_dialog', data: false})
                    }}
                    className="btn-pill mx-1">
                    <span className="btn-wrapper--label">Ok</span>
                </Button>
              </div>
            </div> 
          </Dialog>

           
        <Dialog
            open={this.props.state.currentpin_dialog}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setCardData({prop: 'secretkey_dialog', data: false})
            }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
             <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
              You have requested to view your current card PIN
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                PIN: 55245
              </p>
              <div className="pt-4">
                <Button 
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setCardData({prop: 'currentpin_dialog', data: false})
                    }}
                    className=" btn-pill mx-1">
                    <span className="btn-wrapper--label">Close</span>
                </Button>
              </div>
            </div> 
          </Dialog>
        <Dialog
            open={this.props.state.resetpin_dialog}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setCardData({prop: 'secretkey_dialog', data: false})
            }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
             <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
             You are about to reset your card pin?
              </h4>
              <strong className="mb-0 font-size-lg text-muted bold">
                Enter your current PIN
              </strong>
              <p className="mb-0 font-size-lg text-muted">
                <TextField id="outlined-basic" label="Enter Current PIN" variant="outlined" />
              </p>
              <hr />
              <strong className="mb-0 font-size-lg text-muted bold">
                Please enter new 4 or 5 digits PIN
              </strong>
              <p className="mb-0 font-size-lg text-muted">
                <TextField id="outlined-basic" label="Enter New PIN" variant="outlined" />
              </p>
              <div className="pt-4">
                <Button
                  onClick={(event) => {
                    event.preventDefault()
                    this.props.actions.setCardData({prop: 'resetpin_dialog', data: false})
                }}
                  className=" btn-pill mx-1">
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button 
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setCardData({prop: 'resetpin_dialog', data: false})
                    }}
                    className="btn-pill mx-1">
                    <span className="btn-wrapper--label">Ok</span>
                </Button>
              </div>
            </div> 
          </Dialog>
        <Dialog
            open={this.props.state.decision_dialog}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setCardData({prop: 'decision_dialog', data: false})
            }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
             <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
Enable Automated FMS Decision Engine?
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                
              </p>
              <hr />
              <p className="mb-0 font-size-lg text-muted">
              <Select
                    native
                    name="landing"
                    placeholder="none"
                    style={{width: '100px'}}
                    
                >
                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>

                </Select>
              </p>
              <div className="pt-4">
                <Button
                  onClick={(event) => {
                    event.preventDefault()
                    this.props.actions.setCardData({prop: 'decision_dialog', data: false})
                }}
                  className=" btn-pill mx-1">
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button 
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setCardData({prop: 'decision_dialog', data: false})
                    }}
                    className="btn-pill mx-1">
                    <span className="btn-wrapper--label">Ok</span>
                </Button>
              </div>
            </div> 
          </Dialog>
        </div>
    }

    render() {

        return this.props.state.cards.length
                ? this.getGrid()
                : <Loading message={'Grid Loading component...'} />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.cardManagement,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardGrid)