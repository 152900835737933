import _ from 'lodash'
import * as types from './types'

export const getComponentDescription = (component) => {
    return {
        type: types.SEARCH_COMPONENT_SET_DESCRIPTION,
        payload: getComponentDisplayText(component, component.concatDescription)
    }
}

export const getComponentTitle = (component) => {
    return {
        type: types.SEARCH_COMPONENT_SET_TITLE,
        payload: getComponentDisplayText(component, component ? component.concatTitle : '')
    }
}

const getComponentDisplayText = (component, key) => {
    if (key == null) return ''

    const fields = key.split(',').map(x => {
        if (x.indexOf('_id') > -1 && x.indexOf('.') > -1) {
            const field = component.component_field.filter(y => y.name === x.split('.').pop())[0]

            const accessors = field.display.split('|')
            const component = accessors[0].split('.')[0]

            return accessors.map(y => {
                return y
                    .split(',')
                    .map(z => {
                        return _.get(component.data[component], z.replace(component + '.', ''))
                    })
                    .join(' ')
            }).join(', ')
        }
        else {
            return component.data[x.replace(component.name + '.', '')]
                ? component.data[x.replace(component.name + '.', '')].input
                : ''
        }
    })

    return fields.join(' ')
}