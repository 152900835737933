import * as props from './props'

import * as types from './types'
import * as controlTypes from '../../../controls/types'

export default (state = props, action) => {
    switch (action.type) {
        case types.EMPTY_TYPE:
            return {
                ...state
            }
        case controlTypes.SET_CONTROL_VIEW_INPUT_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.data

            }
        case types.SET_CREDITORS_PAYMENT_COMPONENT_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value

            }
            case types.RESET_COMPONENT_CREDITORS_PAYMENT:
                return {
                    ...state,
                    select_supplier : {},
                    client_data : [],
                    supplier_data : [],
                    credited_data : [],
                    penalties_data : [],
                    payment_document : [],
                    supplier_transactions : [],
                    statement_amount : '0.00',
                    shortpayment_amount : '0.00',
                    discount_percentage : '0.00',
                    discount_amount :'0.00',
                    credited_amount : '0.00',
                    payable_amount : '0.00',
                    payment_date : '',
                    supplier_id : '',
                    suppliername : '',
                    client_id : '',
                    client: '',
                    title : '',
                    transactionx : {},
                    enatis_received : false,
                    selected_data : {},
                    accountingitem_data : [],
                    accountingitem_id : null,
                    itemname : ''
                }
        default:
            return state
    }
}