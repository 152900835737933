import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment';
import { Container, Row, Col } from 'react-grid-system'
import * as _ from 'lodash'
import Loading from '../Loading/component'
import PdfViewer from '../PDF/component'

import AdhocComponentFieldInput from '../Custom/AdhocComponentFieldInput/component'
import Button from '@mui/material/Button' // v1.x  //import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox'
import Icon from '../Icon/component'

import TextField from '@mui/material/TextField';
import Card from './../../controls/card'

import * as actions from './actions'
import * as styles from './styles'
import ViewMaintenance from './../../controls/view_maintenance'
import ViewVehicle from './../../controls/view_vehicle'


class CancelledTasksApprovalOPS extends Component {

    cancelDetails(data) {
        return data
            ? <div>
                <Card
                    title={window.t.en('Cancellation Details')}
                    subtitle={window.t.en('Cancellation Information')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Reject Reason:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.selectcomments}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'User Comments:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.comments}</Col>
                        </Row>
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Reject Date:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{moment(data.rejected_data).format('YYYY-MM-DD HH:mm')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Reject By:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.rejected_by}</Col>
                        </Row>

                    </Container>} />
            </div>
            : ''
    }
    uploadedDocuments(data) {
        //Check object if has documents before rendering 
        return data.length
            ? <div>
                <Card
                    title={'Uploaded Supporting Documents'}
                    subtitle={''}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {
                                    data
                                        ? <PdfViewer
                                            key={'document_viewer_approval_OPS'}
                                            name={'booking_OPS'}
                                            workflow={'fml_customer_quote'}
                                            task={'tsk_purchase_order_review_and_send'}
                                            documents={data}
                                            edit={false}
                                            multiple={true}
                                            workflow_queue_data={this.props.workflow_queue_data}
                                        />
                                        : <Loading message={'Retrieving Documents...'} />
                                }
                            </Col>
                        </Row>
                    </Container>} />
            </div>
            : ''
    }
    docDetails(data) {
        return data
            ? <div>
                <Card
                    title={window.t.en('Documents ')}
                    subtitle={window.t.en('Related Documents')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <PdfViewer
                                    key={'document_viewer_approval'}
                                    name={'booking'}
                                    workflow={'fml_customer_quote'}
                                    task={'tsk_purchase_order_review_and_send'}
                                    documents={data}
                                    edit={false}
                                    multiple={false}
                                    default_document={'Authorisation'}
                                    workflow_queue_data={this.props.workflow_queue_data}
                                />
                            </Col>
                        </Row>
                    </Container>} />
            </div>
            : ''
    }
    taskDetails(data) {
        const step = data.data.step1.context_data.data.data.data
        return step
            ? <div>
                <Card
                    title={window.t.en('Task Details: ')}
                    subtitle={window.t.en('Details for cancellation')}
                    content={
                        <div>
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={2} style={styles.fieldLabel}>{'Description:'}</Col>
                                    <Col xl={4} style={styles.selectedFieldName}>{step.step1.po}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'Created Date:'}</Col>
                                    <Col xl={4} style={styles.selectedFieldName}>{step.step1.preauthdate}</Col>
                                </Row>
                                <Row>
                                    <Col xl={2} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                                    <Col xl={4} style={styles.selectedFieldName}>{step.step1.preauthnumber}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'Vehicle Registration:'}</Col>
                                    <Col xl={4} style={styles.selectedFieldName}>{step.step1.vehicle}</Col>
                                </Row>
                                <Row>
                                    <Col xl={2} style={styles.fieldLabel}>{'Supplier:'}</Col>
                                    <Col xl={4} style={styles.selectedFieldName}>{step.step1.supplier}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'Book Date:'}</Col>
                                    <Col xl={4} style={styles.selectedFieldName}>{step.step1.datestamp}</Col>
                                </Row>
                            </Container>

                            {
                                step.step2
                                    ? <Container style={{ margin: 0 }} className="custom-container">
                                        <Row>
                                            <Col xl={2} style={styles.fieldLabel}>{'Quote Date:'}</Col>
                                            <Col xl={4} style={styles.selectedFieldName}>{step.step2.datestamp}</Col>
                                            <Col xl={2} style={styles.fieldLabel}>{'Quoted Amount Excl:'}</Col>
                                            <Col xl={4} style={styles.selectedFieldName}>{step.step2.quotetotal.total_amount_exl}</Col>
                                        </Row>
                                        <Row>
                                            <Col xl={2} style={styles.fieldLabel}>{'Discounted Amount:'}</Col>
                                            <Col xl={4} style={styles.selectedFieldName}>{step.step2.quotetotal.total_amount_dis}</Col>
                                            <Col xl={2} style={styles.fieldLabel}>{'Quoted Amount Inc:'}</Col>
                                            <Col xl={4} style={styles.selectedFieldName}>{step.step2.quotetotal.total_amount_inc}</Col>
                                        </Row>

                                    </Container>
                                    : ''
                            }
                            {
                                //Not all cancelled task will have step2 object check is exist before render item
                                step.step2
                                    ? <Container style={{ margin: 0 }} className="custom-container">
                                        <Row>
                                            <Col xl={12} style={styles.selectedFieldName}><br /></Col>
                                        </Row>
                                        <Row>
                                            <Col xl={2} style={styles.selectedFieldName}>{'Class'}</Col>
                                            <Col xl={3} style={styles.selectedFieldName}>{'Description'}</Col>
                                            <Col xl={1} style={styles.selectedFieldName}>{'Rebill'}</Col>
                                            <Col xl={1} style={styles.selectedFieldName}>{'Quantity'}</Col>
                                            <Col xl={1} style={styles.selectedFieldName}>{'Price'}</Col>
                                            <Col xl={1} style={styles.selectedFieldName}>{'Discount'}</Col>
                                            <Col xl={1} style={styles.selectedFieldName}>{'VAT'}</Col>
                                            <Col xl={2} style={styles.selectedFieldName}>{'Amount'}</Col>
                                        </Row>
                                        {
                                            step.step2.rebill_items && step.step2.rebill_items.map(x => {
                                                return <Row>
                                                    <Col xl={2}>{x.Class}</Col>
                                                    <Col xl={3}>{x.Description}</Col>
                                                    <Col xl={1}>{x.Rebill ? 'Yes' : 'No'}</Col>
                                                    <Col xl={1}>{x.Quantity}</Col>
                                                    <Col xl={1}>{x.Price}</Col>
                                                    <Col xl={1}>{x.DiscountAmount}</Col>
                                                    <Col xl={1}>{x.VAT}</Col>
                                                    <Col xl={2}>{x.Amount}</Col>
                                                </Row>
                                            })
                                        }
                                        {
                                            step.step2.auth_items && step.step2.auth_items.map(x => {
                                                return <Row>
                                                    <Col xl={2}>{x.Class}</Col>
                                                    <Col xl={3}>{x.Description}</Col>
                                                    <Col xl={1}>{x.Rebill ? 'Yes' : 'No'}</Col>
                                                    <Col xl={1}>{x.Quantity}</Col>
                                                    <Col xl={1}>{x.Price}</Col>
                                                    <Col xl={1}>{x.DiscountAmount}</Col>
                                                    <Col xl={1}>{x.VAT}</Col>
                                                    <Col xl={2}>{x.Amount}</Col>
                                                </Row>
                                            })
                                        }
                                    </Container> : ''
                            }</div>} />
            </div> : ''

    }
    defleetTaskDetails(data) {
        const step = data.data.step3 ? data.data.step3 : data.data.step2 ? data.data.step2 : data.data.step1
        const vehicle = data.data.step1.context_data.data.data.data[0].step1.data.selectedVehicleDetails.data[0]
        //console.log(step)
        return step
            ? <div>
                <Card
                    title={window.t.en('Task Details ')}
                    subtitle={window.t.en('Task Cancellation Information')}
                    content={<div><Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Created Date:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{moment(step.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Created By:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{step.user.firstname + ' ' + step.user.lastname}</Col>
                        </Row>
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Defleet Number:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.context_data.data.data.data[0].step1.data.defleetNumber}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Defleet Type:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.context_data.data.data.data[0].step1.data.selectedDefleetType.text}</Col>
                        </Row>
                    </Container>
                        {
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12} style={styles.selectedFieldName}><br /></Col>
                                </Row>
                                {data.data.step3 ?
                                    <Row>
                                        <Col xl={2} style={styles.selectedFieldName}>{'Class'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{'Description'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Rebill'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Quantity'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Price'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Discount'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'VAT'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{'Amount'}</Col>
                                    </Row> : ''}
                                {data.data.step3 ?
                                    data.data.step3.rebill_items.map(x => {
                                        return <Row>
                                            <Col xl={2}>{x.Class}</Col>
                                            <Col xl={3}>{x.Description}</Col>
                                            <Col xl={1}>{'Yes'}</Col>
                                            <Col xl={1}>{x.Quantity}</Col>
                                            <Col xl={1}>{x.Price}</Col>
                                            <Col xl={1}>{x.DiscountAmount}</Col>
                                            <Col xl={1}>{x.VAT}</Col>
                                            <Col xl={2}>{x.Amount}</Col>
                                        </Row>
                                    })
                                    : ''
                                }
                            </Container>
                        } </div>} />
                <br></br>
                <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
            </div>
            : ''
    }

    getForm(data) {
        //console.log(">>>>>>>>>>> Data", data)
        const step = data.data.step1.data.data.cancel_data
        return <div><Card
            title={'Technical Manager Aproval Comments '}
            subtitle={''}
            content={<div><Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.data.step1.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.step1.user.firstname + ' ' + data.data.step1.user.lastname}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.step1.user.email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.step1.user.phone}</Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txt_podcomments'}
                            fullWidth={true}
                            disabled={true}
                            value={step.comments ? step.comments : 'There are no comments from Technical Manager'}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>
            </div>} />
            <Card
                title={'Approval Comments '}
                subtitle={'Cancellation approval comments'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <TextField
                                variant='outlined'
                                id={'txt_podcomments'}
                                label={'Enter Comments'}
                                fullWidth={true}
                                value={this.props.state.comments}
                                onChange={(event, value) => {
                                    this.props.actions.setValue('comments', event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                </Container>
                } />
        </div>
    }
    

    getTech(data) {
        //console.log(">>>>>>>>>>> Data", data)
        const step = data.data.step1.data.data.cancel_data
        return <div><Card
            title={'Technical Manager Aproval Comments '}
            subtitle={''}
            content={<div><Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.data.step1.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.step1.user.firstname + ' ' + data.data.step1.user.lastname}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.step1.user.email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.step1.user.phone}</Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txt_podcomments'}
                            fullWidth={true}
                            disabled={true}
                            value={step.comments ? step.comments : 'There are no comments from Technical Manager'}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>
            </div>} />
        </div>
    }

    getComments() {
        return <div><Card
                title={'Approval Comments '}
                subtitle={'Cancellation approval comments'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <TextField
                                variant='outlined'
                                id={'txt_podcomments'}
                                label={'Enter Comments'}
                                fullWidth={true}
                                value={this.props.state.comments}
                                onChange={(event, value) => {
                                    this.props.actions.setValue('comments', event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                </Container>
                } />
        </div>
    }

    getDefleet(data) {
        //console.log("Switch for Defleet")
        //console.log("Switch for DefleetSwitch for Defleet", data)
        const step = data.data.step1.submitted_data.workflow.name ? data.data.step1.submitted_data.workflow.name : ''
        const documents = data.data.step1.context_data.data.data.data.length ? data.data.step1.context_data.data.data.data[0].documents : data.data.step1.context_data.data.data.data.documents
        const sup_docs = data.data.documents
        return <Card
            title={window.t.en('Cancelled Task ')}
            subtitle={window.t.en('Details of the cancelled task')}
            content={
                <div>
                    {this.cancelDetails(data)}
                    {step == 'defleet' ? this.defleetTaskDetails(data) : this.taskDetails(data)}
                    {documents ? this.docDetails(documents) : []}
                    {sup_docs ? this.uploadedDocuments(sup_docs) : []}
                    {data ? this.getForm(data) : ''}
                </div>
            } />
    }

    getAuth(data) {
        //console.log("Switch for Auth",data)
        
        let step1 = null
        try{
            step1 = data.data.step1.context_data.data.data.data.length
                ? data.data.step1.context_data.data.data.data[0].step1
                : data.data.step1.context_data.data.data.data.step1
        }
        catch(err)  {}
        
        //console.log("step1 Auth",step1)
        return step1
            ? <Container style={{ margin: 0 }} className="custom-container">
                {this.cancelDetails(data)}
                {this.getTech(data)}
                {step1 && <ViewMaintenance key={step1.maintenancetransaction_id} id={step1.maintenancetransaction_id} />}
                {this.getComments()}
            </Container>
            : ''
    }

    getView(data) {
        return data.cancelled_workflow === 'Prepare a Customer Rebill Request' || data.cancelled_workflow === 'Prepare a Technical Purchase Order'
            ? this.getAuth(data)
            : this.getDefleet(data)
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log(">> workflow_queue_data", workflow_queue_data)
        let data = null
        try {
            data = {
                data: workflow_queue_data.context_data.data.data.data[0],
                rejected_reason: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.comments,
                selectcomments: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.selectcomments,
                comments: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.comments,
                rejected_by: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.user[0].firstname,
                rejected_data: workflow_queue_data.context_data.created_at,
                cancelled_workflow: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.workflow.title,
                cancelled_task: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.workflow_task.title
            }
        }
        catch (err) { }

        return (
            data
                ? this.getView(data)
                : 'Loading, Please wait OPS...'
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.cancelledTasksApprovalOPS
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelledTasksApprovalOPS)