import * as types from './types'
import * as appTypes from '../../App/types'
import moment  from 'moment'

export const emptyAction = () => {
    return {
        type: types.EMPTY_TYPE,
        payload: ''
    }
}

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_CREDITORS_PAYMENT,
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CREDITORS_PAYMENT_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}
export const setListInputValue = (prop, value, inx) => {
    return (dispatch, getState) => {
        let supplier_transactions = getState().creditorsPaymentCreate.supplier_transactions
        supplier_transactions[inx][prop] = value
        dispatch({
            type: types.SET_CREDITORS_PAYMENT_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                prop: 'supplier_transactions',
                value: supplier_transactions
            }
        })
    }
}

export const getLookupData = (component, field, search, ) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CREDITORS_PAYMENT_COMPONENT_LOOKUP_DATA,
            payload: {
                component: component,
                query: search ? '?' + field + '=' + search : '',
                field: field,
                related: ''
            }
        })
    }
}

export const getAccountingLookupData = (component, field, search, ) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CREDITORS_PAYMENT_COMPONENT_LOOKUP_DATA,
            payload: {
                component: component,
                query: search ? '?accountingtype_id=4&' + field + '=' + search : '?accountingtype_id=4',
                field: field,
                related: 'bankaccount.financialinstitution,bankaccount.accounttype'
            }
        })
    }
}
export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        dispatch(setInputValue(payload.component + '_data', response.data.map(x => {
            return {
                ...x,
                value: x[payload.component + '_id'],
                text: x[payload.field]
            }
        })))
    }
}

export const getSupplierList = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CREDITORS_PAYMENT_COMPONENT_LOOKUP_DATA,
            payload: {
                component: component,
                query: search ? '?allocated=false&' + field + '=' + search : '?allocated=false',
                field: field
            }
        })
    }
}


export const addPenaltiesBlankRow = (response, payload) => {
    return (dispatch, getState) => {
        let penalties_data = getState().creditorsPaymentCreate.penalties_data
        penalties_data.push({
            date: moment().format('DD-MM-YYYY'),
            reference: '',
            reason: '',
            amount: '0.00',
        })
        dispatch(setInputValue('penalties_data', penalties_data))
    }
}

export const removePenaltiesRow = (inx) => {
    return (dispatch, getState) => {
        let penalties_data = getState().creditorsPaymentCreate.penalties_data.filter((x, i) => i !== (inx))
        dispatch(setInputValue('penalties_data', penalties_data))
    }
}

export const setPenRowInputValue = (inx, prop, value) => {
    return (dispatch, getState) => {
        let penalties_data = getState().creditorsPaymentCreate.penalties_data
        penalties_data[inx][prop] = value
        dispatch(setInputValue('penalties_data', penalties_data))
    }
}

export const addCreditedBlankRow = (response, payload) => {
    return (dispatch, getState) => {
        let credited_data = getState().creditorsPaymentCreate.credited_data
        credited_data.push({
            date: moment().format('DD-MM-YYYY'),
            reference: '',
            reason: '',
            amount: '0.00',
        })
        dispatch(setInputValue('credited_data', credited_data))
    }
}

export const removeCreditedRow = (inx) => {
    return (dispatch, getState) => {
        let credited_data = getState().creditorsPaymentCreate.credited_data.filter((x, i) => i !== (inx))
        dispatch(setInputValue('credited_data', credited_data))
    }
}

export const setCreRowInputValue = (inx, prop, value) => {
    return (dispatch, getState) => {
        let credited_data = getState().creditorsPaymentCreate.credited_data
        credited_data[inx][prop] = value
        dispatch(setInputValue('credited_data', credited_data))
    }
}

export const calcTotal = () => {
    return (dispatch, getState) => {
        let credited_data = getState().creditorsPaymentCreate.credited_data.concat(getState().creditorsPaymentCreate.penalties_data)
        let supplier_transactions = getState().creditorsPaymentCreate.supplier_transactions
        let discount_percentage = getState().creditorsPaymentCreate.discount_percentage
        let statement_amount = getState().creditorsPaymentCreate.statement_amount

        let shortpayment_amount = _.sumBy(supplier_transactions, (d) => { return parseFloat(d.shortpayment ? d.shortpayment : 0)}).toFixed(2)
        let allocated_amount = _.sumBy(supplier_transactions, (d) => { return parseFloat(d.allocationamount ? d.allocationamount : 0)}).toFixed(2)
        let credited_amount = _.sumBy(credited_data, (d) => { return parseFloat(d.amount ? d.amount : 0)}).toFixed(2)
        
        let payable_amount = ((Number(allocated_amount) ?  Number(allocated_amount) : 0) - ((Number(credited_amount) ?  Number(credited_amount) : 0)))// + (Number(discount_amount) ?  Number(discount_amount) : 0)))

        let discount_amount = _.sumBy(supplier_transactions, (d) => { return parseFloat(d.discountamount ? d.discountamount : 0)}).toFixed(2)
        
        //Number(payable_amount)
        //    ? (Number(payable_amount) * (Number(discount_percentage) / 100)) : 0

        //payable_amount = (Number(payable_amount) ? Number(payable_amount) : 0) - (Number(discount_amount) ? Number(discount_amount) : 0)

        dispatch(setInputValue('shortpayment_amount', Number(shortpayment_amount) ? Number(shortpayment_amount) : '0.00'))
        dispatch(setInputValue('allocated_amount', Number(allocated_amount) ? Number(allocated_amount) : '0.00'))
        dispatch(setInputValue('credited_amount', Number(credited_amount) ? Number(credited_amount) : '0.00'))
        dispatch(setInputValue('discount_amount', Number(discount_amount) ? Number(discount_amount) : '0.00'))
        dispatch(setInputValue('payable_amount', Number(payable_amount) ? Number(payable_amount) : '0.00'))
    }
}

export const setSupplierTransalationInputValue = (suppliertransaction_id, field, amount) => {
    return (dispatch, getState) => {
        let supplier_transactions = getState().creditorsPaymentCreate.supplier_transactions
        let _supplier_transactions = supplier_transactions.map(x => {
            return x.suppliertransaction_id == suppliertransaction_id ? {...x, [field]: amount} : x
        })
        dispatch(setInputValue('supplier_transactions', _supplier_transactions))
    }
}

export const getComponentSupplierTransaction = (id) => {
    return (dispatch, getState) => {
        //console.log('getComponentSupplierTransactionx get', id)
        dispatch(setInputValue('supplier_transactions', []))
        dispatch({
            type: types.GET_CONTROL_VIEW_SUPPLIER_TRANSACTION,
            payload: id
        })

    }
}

export const setComponentSupplierTransaction = (response) => {
    return (dispatch, getState) => {
        //console.log('getComponentSupplierTransactionx set', response.data)
        dispatch(setInputValue('supplier_transactions', response.data))
    }
}


export const genPaymentsDocuments = (data) => {
    return (dispatch, getState) => {
        //console.log('statex actions',data)
        dispatch({
            type: types.GENERATE_CREDITORS_PAYMENT_DOCUMENTS,
            payload: {
                type: 'GENERATE_CREDITORS_PAYMENT_DOCUMENTS',
                data: data
            }
        })
    }
}


export const downlaodCreditorsPaymentDocuments = (data) => {

    return (dispatch, getState) => {
    //console.log('downlaodCreditorsPaymentDocumentsx', data)
    let payment_document = data.data.documents
    dispatch(setInputValue('payment_document', payment_document))
    
    let _data = {...getState().creditorsPaymentCreate, documents: getState().upload.documents.concat(payment_document)}
    dispatch(setupWorkflowTaskInput(_data, 'creditors_payment', 'tsk_creditors_payment_create'))
}
    // const getFile = () => {
    //     const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
    //     return window.URL.createObjectURL(blob)
    // }

    // const saveByteArray = (function () {
    //     let a = document.createElement('a')
    //     document.body.appendChild(a)
    //     a.style = 'display: none'

    //     return (data, name) => {
    //         const blob = new Blob(data, { type: 'octet/stream' })
    //         const url = window.URL.createObjectURL(blob)

    //         a.href = url
    //         a.download = name
    //         a.click()
    //         window.URL.revokeObjectURL(url)
    //     }
    // }())

    // window.glyco.log('data.data.document')
    // window.glyco.log(data.data)

    // saveByteArray([data.data.content.Body], 'STR Workings Preview.pdf')

}

export const downlaodCreditorsPaymentDocumentsError = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'File import successfully completed...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_CREDITORS_PAYMENT_WORKFLOW_CREATE,
            payload: payload
        })
    }
}

