import * as props from './props';
import * as types from './types';

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_REPORT_SCHEDULE_COMPONENT_FIELD_INPUT_VALUES:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: action.payload.value
                }
            }
        case types.SET_CREATE_USER_INPUT:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_STATE_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_SCHEDULER_EDIT_DATA:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ...action.payload
                }
            }
        case types.SET_SCHEDULER_ADD_DATA:
            return {
                ...state,
                fields: state.defaultFields
            }
        default:
            return state
    }
}