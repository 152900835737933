import * as types from './types'
import * as _ from 'lodash'

export const changeTabView = (inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TEST_COMPONENT_TAB_INX,
            payload: inx
        })

//         var axios = require('axios');
// var data = JSON.stringify({"username":"Jitendra Solanki","firstName":"Jitendra","lastName":"Solanki","emailId":"jitendras@infleetint.com","tokenValidity":15});

// var config = {
//   method: 'post',
//   url: 'https://identity.document360.io/jwt/generateCode',
//   headers: { 
//     'Content-Type': 'application/json', 
//     'Authorization': 'Basic NTA0NzgzNmYtNTYwNS00YTA0LTllMDUtM2Q4MjkzZTg5NzBjOjlLNXpzZDN0RGJ1UTRPY3VHcFFuVTlpNDZlenNRSUN5U0YwZjRGX25RbU0=', 
//     'Cookie': 'ARRAffinity=39221f2383492da4d704c4a06de6e15c2c8b884b49b6dd2f0b5831841a7e1678; ARRAffinitySameSite=39221f2383492da4d704c4a06de6e15c2c8b884b49b6dd2f0b5831841a7e1678'
//   },
//   data : data
// };

// axios(config)
// .then(function (response) {
//   //console.log(JSON.stringify(response.data));
// })
// .catch(function (error) {
//   //console.log(error);
// });

    }
}

export const setTreeSelection = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TREE_VALUE,
            payload: data
        })
    }
}


export const setInputVal = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TEST_INPUT_VAL,
            payload: data
        })
    }
}
