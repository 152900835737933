import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const gridOptions = {

}

export const highlight = colours.red[200]


export const inputWidth = {
    width: '100%',
    fontSize: '16px',
    marginBottom: 25
}

export const chip = {
    margin: 4,
    float: 'left',
}

export const wrapper = {
    display: 'flex',
    flexWrap: 'wrap',
}

export const paper = {
    display: 'inline-block',
    display: 'flex',
    flexWrap: 'wrap',
  }

  export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const action = {
    fontSize: '20px',
    color: 'red'
}

export const actionHoverColor = {
    color: 'red'
}

export const gridOptionsIcon = {
    color: 'red'
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
}

export const textBox = {
    width: '100%',
    paddingLeft: 30,
    textAlign: 'right',
    float: 'right',
}

export const textBoxInput = {
    textAlign: 'right',
}

export const chipdiv = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  }
 