//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const icon = {
    fontSize: '10px'
}

export const row = {
    fontSize: 16
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
    //opacity: 0.85
}


export const numericFieldName = {
    fontWeight: 'bold',
    textAlign: 'right',
}

export const fieldNameRight = {
    textAlign: 'right',
}

export const numericFieldValues = {
    textAlign: 'right',
}

export const numericFields = {
    textAlign: 'right',
    fontWeight: 'bold',
}