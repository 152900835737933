import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import * as _ from 'lodash'
import AutoComplete from '../../../controls/autocomplete'
import { Card, CardHeader, CardContent } from '@mui/material'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import Icon from '../../Icon/component'
import PdfViewer from '../../PDF/component'
import * as actions from './actions'
import * as styles from './styles'






let supplier_id = 0

class DISPOSALDECISION extends Component {
    componentDidMount() {
        //   this.component = this.props.state
        this.actions = this.props.actions;
    }

    getGrid() {
        let updatedamt = false
        let updatedno = false

        return <Card initiallyExpanded={true} >
            <CardContent expandable={true}>
                <AdhocGrid
                    key={'key_adhoc_grid_disposal'}
                    name={'disposal_grid'}
                    uri={'/components/disposaldecision/other'}
                    filterable={true}
                    sortable={true}
                    body={{
                        type: 'get_disposal_list',
                        supplier_id: supplier_id
                    }}
                    search={''}
                    columns={[
                        { accessor: 'defleetnumber', Header: 'Defleet Number', filterable: true, width: 100 },
                        { accessor: 'licenseplateno', Header: 'Vehicle Reg', width: 100, filterable: true },
                        { accessor: 'mmcode', Header: 'MMCode', width: 100, filterable: true },
                        { accessor: 'location', Header: 'Location', width: 150, filterable: true },
                        { accessor: 'funder', Header: 'Funder', width: 100, filterable: true },
                        { accessor: 'buyer', Header: 'Buyer', width: 200, filterable: true },
                        {
                            accessor: 'fundercontractend', Header: 'Funder Contract End', width: 100, filterable: true, Cell: row => {
                                return (
                                    <div>{
                                        row.original['fundercontractend'] ?
                                            <TextField
                                                value={moment(new Date(row.original['fundercontractend'])).format('YYYY-MM-DD')}
                                                disabled={true}
                                                onChange={(event, value) => {
                                                    //row.original['invoicedate'] = (value)
                                                }}
                                            />
                                            : ''}
                                    </div>
                                )
                            }
                        },
                        { accessor: 'monthstogo', Header: 'Months to-go', width: 100, filterable: true },
                        { accessor: 'residualvalue', Header: 'RV', width: 100, filterable: true },
                        { accessor: 'bestofferdesc', Header: 'Best Offer', width: 200, filterable: true },
                        { accessor: 'bestoffertrade', Header: '% Trade', width: 100, filterable: true },
                        { accessor: 'bestoffermarket', Header: '% Market', width: 100, filterable: true },
                        { accessor: 'bestoffer', Header: 'Offer', width: 100, filterable: true },
                        { accessor: 'settlementexcl', Header: 'Settlement Excl', width: 150, filterable: true },
                        { accessor: 'settlementexclless', Header: 'Settlement Excl Less 4%', width: 200, filterable: true },
                        { accessor: 'settlementincl', Header: 'Settlement Incl', width: 150, filterable: true },
                        { accessor: 'invoicevalueincl', Header: 'Invoice Value Incl', width: 150, filterable: true },
                        { accessor: 'profitorloss', Header: 'Profit/Loss', width: 100, filterable: true },
                        { accessor: 'offerincrease', Header: 'Offer Increase', width: 100, filterable: true },
                        {
                            accessor: 'disposal_id', Header: 'Decision', filterable: false, width: 175, Cell: row => {
                                return (
                                    <div>
                                         <AutoComplete
                                        listStyle={{ maxHeight: 200, overflow: 'auto' }}
                                        id={'autocomplete_'}
                                        key={'autocomplete_'}
                                        hintText={'Select...'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        dataSource={[{text: "Sell", value:"sell"},{text:"Keep In Stock",value:"keepstock"}]}
                                        //errorText={this.component.data[field.name].validation.message}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                               // this.props.actions.setVehicles(this.calculator.id, chosenRequest.value)
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                //this.props.actions.getVehicles(this.calculator.id, this.calculator.search)
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                //this.props.actions.getVehicles(this.calculator.id, search)
                                            }
                                        }
                                    //maxSearchResults={3}
                                    />
                                    </div>
                                )
                            }
                        },
                        {
                            accessor: 'disposal_id', Header: 'Action', filterable: false, width: 175, Cell: row => {
                                return (
                                    <div>
                                       < Button className={"global_button"} variant="contained"
                                            label=""
                                            primary={true}
                                            icon={<Icon iclass={'material-icons'} iname={'check'} />}
                                            onClick={(event) => {                                                
                                                this.props.actions.ApproveDisposal(row.original['disposal_id'])
                                            }}
                                            ><Icon iclass={'material-icons'} iname={'check'} /></Button>
                                       < Button className={"global_button"} variant="contained"
                                            label=""
                                            primary={true}
                                            icon={<Icon iclass={'material-icons'} iname={'clear'} />}
                                            onClick={(event) => {
                                                this.props.actions.RejectDisposal(row.original['disposal_id'])
                                            }}
                                            ><Icon iclass={'material-icons'} iname={'clear'} /></Button>
                                    </div>
                                )
                            }
                        },
                    ]}
                    paging={{
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    }}
                    subComponent={row => {
                        return <div key={'grid_disposal_data_' + row.original['disposal_id']} style={{ padding: "2px" }}>
                            <Card style={styles.card} style={styles.row}  initiallyExpanded={false}>                           
                                <CardHeader
                                    actAsExpander={true}
                                    showExpandableButton={true}
                                    title={window.t.en('Disposal Offers')}
                                    subtitle={window.t.en('Disposal Decision Details')}
                                />
                                <CardContent expandable={true}>
                                    <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                        <Row>
                                            <Col xl={12}><br /></Col>
                                        </Row>
                                        <Row style={styles.tableheader}>
                                            <Col xl={4}>{'Offer'}</Col>
                                            <Col style={styles.numericFields} xl={1}>{'% Trade'}</Col>
                                            <Col style={styles.numericFields} xl={1}>{'% Market'}</Col>
                                            <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                                        </Row>
                                        {
                                            row.original['items']
                                                ? row.original['items']
                                                    .map((x, i) => {
                                                        return <div>
                                                            <Row style={styles.tablerow}>
                                                                <Col xl={4}>{x.offerdescription}</Col>
                                                                <Col style={styles.numericFields} xl={1}>{parseFloat(x.trade.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                <Col style={styles.numericFields} xl={1}>{parseFloat(x.market.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                <Col style={styles.numericFields} xl={1}>{parseFloat(x.amount.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            </Row>
                                                        </div>
                                                    })
                                                : <div>{'No Items Found...'}</div>
                                        }
                                        <Row style={styles.tablerow}>
                                            <Col xl={11}>{''}</Col>
                                            <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['items'], (d) => { return parseFloat(d.amount) }).toFixed(2)}</Col>
                                        </Row>
                                        <Row><Col xl={12}><br /></Col>
                                        </Row>
                                    </Container>
                                </CardContent>
                            </Card>
                            <br />
                            <Card style={styles.card} style={styles.row} initiallyExpanded={false}>                           
                                <CardHeader
                                    actAsExpander={true}
                                    showExpandableButton={true}
                                    title={window.t.en('Disposal Details')}
                                    subtitle={window.t.en('Disposal Decision Details')}
                                />
                                <CardContent expandable={true}>
                                    <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                        <Row>
                                            <Col xl={12}><br /></Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Funder:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['funder']}
                                                            onChange={(event, value) => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Funder Contract End:'}</div>
                                                    <div style={styles.fieldContent}>
                                                    <TextField
                                                            id={'txtchecksheet_odometer'}                                                            
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={moment(new Date(row.original['fundercontractend'])).format('YYYY-MM-DD')}
                                                            onChange={(event, value) => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Funder RV:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['residualvalue']}
                                                            onChange={(event, value) => {
                                                                //this.props.actions.setValue('funder_rv', value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Funder Months TO-GO:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['monthstogo']}
                                                            onChange={(event, value) => {
                                                                //this.props.actions.setValue('funder_month_togo', value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Trade Value:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['tradevalue']}
                                                            onChange={(event, value) => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Retail Value:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['retailvalue']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>

                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}><b>{'Market Value:'}</b></div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            disabled={true}
                                                            value={row.original['marketvalue']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardContent>
                            </Card>
                            <br></br>
                            <Card style={styles.card} style={styles.row} initiallyExpanded={false}>                           
                                <CardHeader
                                    actAsExpander={true}
                                    showExpandableButton={true}
                                    title={window.t.en('Settlement Details')}
                                    subtitle={window.t.en('Disposal Settlement Details.')}
                                >
                                </CardHeader>
                                <CardContent expandable={true}>
                                    <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                        <Row>
                                            <Col xl={12}><br /></Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Customer:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['customer']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Vehicle Cost Excl. Vat:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['vehiclecostexcl']}
                                                            onChange={(event, value) => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Vehicle Acc. Depreciation:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['vehicledepreciation']}
                                                            onChange={(event, value) => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Settlement Excl. Vat:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['settlementexcl']}
                                                            onChange={(event, value) => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Settlement Excl. Vat less 4%:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['settlementexclless']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xl={6}>

                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}><b>{'Settlement Incl.Vat:'}</b></div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['settlementincl']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Mantenance Fund Bal:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['maintenancefund']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Capital Equalisation Fund:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['capitalequalisation']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Revised NBV-Capital Eq Incl. Vat:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['nbvlesscpaitalequalisationincl']}
                                                            onChange={(event, value) => {
                                                                //this.props.actions.setValue('nbv_capital_incl',  (value))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Commission:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['commission']}
                                                            onChange={(event, value) => { }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>

                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}><b>{'Invoice Value Incl. Vat:'}</b></div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['invoicevalueincl']}
                                                            onChange={(event, value) => {
                                                                //this.props.actions.setValue('inv_value_incl', value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}><br></br></Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}><br></br></Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Diff. Between Best Offer & Settlement:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['differenceofferandsettlement']}
                                                            onChange={(event, value) => {
                                                                //this.props.actions.setValue('nbv_capital_incl',  (value))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Diff. Between Best Offer, Settlement & Capital Eq:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['differenceofferandsettlementequalisation']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}><br /></Col>
                                        </Row>
                                    </Container>
                                </CardContent>

                            </Card>
                            <br></br>
                            <Card style={styles.card} style={styles.row} initiallyExpanded={false}>                           
                                <CardHeader
                                    actAsExpander={true}
                                    showExpandableButton={true}
                                    title={window.t.en('Profit/Loss Details')}
                                    subtitle={window.t.en('Disposal Profit/Loss Details.')}

                                >
                                </CardHeader>
                                <CardContent expandable={true} >
                                    <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                        <Row>
                                            <Col xl={12}><br /></Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Profit/Loss Excl. Eq Fund:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['profitorlossexclequalisation']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}><b>{'Profit/Loss:'}</b></div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['profitorloss']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Funder Monthly Rental:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['fundermonthyrental']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}><b>{'Offer Increase:'}</b></div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtchecksheet_odometer'}
                                                            hintText={''}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['offerincrease']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}><br /></Col>
                                        </Row>
                                    </Container>
                                </CardContent>
                            </Card>
                            <br></br>
                            <Card style={styles.card} style={styles.row} initiallyExpanded={false}>                           
                                <CardHeader
                                    actAsExpander={true}
                                    showExpandableButton={true}
                                    title={window.t.en('Contract Termination Comments')}
                                    subtitle={window.t.en('Capture comments')}
                                >
                                </CardHeader>
                                <CardContent expandable={true}>
                                    <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                        <Row>
                                            <Col xl={12}><br /></Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Termination Date:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtcomment'}
                                                            fullWidth={true}
                                                            disabled={true}
                                                            multiLine={false}
                                                            value={moment(new Date(row.original['disposaldate'])).format('YYYY-MM-DD')}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Termination Odo:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtcomment'}
                                                            disabled={true}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['odometer']}
                                                            onChange={(event, value) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'Comments:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            id={'txtcomment'}
                                                            hintText={'Enter Comments'}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            value={row.original['technicalcomments']}
                                                            onChange={(event, value) => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}><br /></Col>
                                        </Row>
                                    </Container>
                                </CardContent>
                            </Card>
                        
                            <br></br>
                            <Card style={styles.card} style={styles.row} initiallyExpanded={false}>                           
                                <CardHeader
                                    actAsExpander={true}
                                    showExpandableButton={true}
                                    title={window.t.en('Contract Documents')}
                                    subtitle={window.t.en('List of Contract Documents')}
                                >
                                </CardHeader>
                                <CardContent expandable={true}>
                                    <Container style={styles.whitediv}>
                                        <Row><Col xl={12}><br /></Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                {
                                                    row.original['documents']
                                                        ? <PdfViewer
                                                            key={'document_viewer_main_history'}
                                                            name={'disposal_grid'}
                                                            workflow={'fml_customer_quote'}
                                                            task={'tsk_fml_quote_waiting_vehicle_delivery'}
                                                            documents={row.original['documents']}
                                                            edit={false}
                                                            multiple={false}
                                                            workflow_queue_data={null}
                                                        />
                                                        : <div>{''}</div>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardContent>  </Card>
                        
                            <br></br>
                        </div>
                    }}
                />
            </CardContent>
        </Card>
    }

    getLayout() {
        this.actions = this.props.actions;

        return <Card style={styles.card} style={styles.row}>
            <CardHeader
                title={window.t.en('Disposal Management')}
                subtitle={window.t.en('Disposal Decision')}
                actAsExpander={true}
                showExpandableButton={true}
            />
            <CardContent >
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={12}>
                            {this.getGrid()}
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.disposaldecision,
            view: {
                ...state.disposaldecisionview
            },
            user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DISPOSALDECISION)