import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getPaymentList =  (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
    types.GET_PAYMENT_LIST_DATA,
)
, mergeMap(({ payload }) =>
    ajax$({
        url: config.system.api + '/components/supplierpaymentapprovalfinance/other',
        method: 'POST',
        headers: {
            'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
            'salt': store.value.cognito.authenticateUser.jwt,
            'datetime': store.value.cognito.authenticateUser.datetime,
            'Content-Type': 'application/json'
        },
        body: payload
    })
        .pipe(
            mergeMap((result) => [
                actions.setPaymentList(result.response),
                requestActions.requestSucceeded(config.system.api + '')
            ])
            , startWith(progressBarActions.progress(true))
            , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
            //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        ))
    , catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

    export const updatePaymentData = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.UPDATE_PAYMENT_DATA_VALUES,
    )
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/supplierpaymentapprovalgm/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => [
                    //actions.setPaymentList(result.response, payload.type),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            ))
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))