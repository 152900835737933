import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'

export const getWorkflowTaskInformationEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_WORKFLOW_TASK_INFORMATION
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_task/query?name=' + payload.task,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'workflow,workflow_task_team,workflow_task_step.workflow_task_step_component.workflow_task_step_component_display_type,workflow_task_step,workflow_task_step.workflow_task_step_component.component'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflowTaskInformation(payload.workflow, payload.task, payload.queue, result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow_task/query?name=' + payload.task)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow_task/query?name=' + payload.task, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getWorkflowTaskTeamsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_WORKFLOW_TASK_TEAMS
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_task_team/query?workflow_task_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflowTaskTeamsData(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow_task/query?name=' + payload.workflow_task_name)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow_task/query?name=' + payload.workflow_task_name, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getWorkflowTaskActionDataEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_WORKFLOW_TASK_ACTION_DATA
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_queue_task/query?workflow_queue_id=' + payload.workflow_queue_id + '&workflow_queue_task_id=' + payload.workflow_queue_task_id,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'workflow_task.workflow,workflow_task.workflow_task_step.workflow_task_step_component.workflow_task_step_component_display_type,workflow_task,workflow_task.workflow_task_step,workflow_task.workflow_task_step.workflow_task_step_component.component'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflowTaskActionData(result.response, payload.workflow_queue_id, payload.workflow_queue_task_id),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow_queue_task/query?workflow_queue_id=' + payload.workflow_queue_id + '&workflow_queue_task_id=' + payload.workflow_queue_task_id)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow_queue_task/query?workflow_queue_id=' + payload.workflow_queue_id + '&workflow_queue_task_id=' + payload.workflow_queue_task_id, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


 
export const getWorkflowTaskConnectorDataEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_WORKFLOW_TASK_CONNECTOR_DATA)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_queue_task/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: {
                    query: 'workflow_task_connector',
                    task_id: payload,
                    user_id: store.value.user.user_id,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflowTaskConnectorDataEpic(result.response,payload),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )