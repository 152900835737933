export const CONFIGURE_APP = 'CONFIGURE_APP'
export const GET_USER_AGENT = 'GET_USER_AGENT'
export const SET_INIT_USE_MSAL = 'SET_INIT_USE_MSAL'
export const GET_USER_LOCALE = 'GET_USER_LOCALE'
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIGHLIGHT_WORKFLOW = 'HIGHLIGHT_WORKFLOW'
export const SET_APP_ACTION = 'SET_APP_ACTION'
export const SHOW_SESSION_EXPIRED_DIALOG = 'SHOW_SESSION_EXPIRED_DIALOG'
export const RENEW_APPLICATION_CACHE = 'RENEW_APPLICATION_CACHE'
export const CLEAR_APPLICATION_CACHE = 'CLEAR_APPLICATION_CACHE'
export const SET_APPLICATION_USER_LOCALE = 'SET_APPLICATION_USER_LOCALE'
export const SET_SHOW_DIALOG_NOTIFICATIOB = 'SET_SHOW_DIALOG_NOTIFICATIOB'
export const SHOW_CONFIRM_DIALOG = 'SHOW_CONFIRM_DIALOG'
export const TOGGLE_EXPIRY_DIALOG = 'TOGGLE_EXPIRY_DIALOG'
export const SESSION_EXPIRY_TIMER_COUNT_DOWN = 'SESSION_EXPIRY_TIMER_COUNT_DOWN'
export const SET_APP_USER_AUTH_STATUS = 'SET_APP_USER_AUTH_STATUS'
export const USER_SIGN_OUT_COGNITO_USER = 'USER_SIGN_OUT_COGNITO_USER'
export const SET_SHOW_DIALOG_NOTIFICATION_LOGGED_TWICE = 'SET_SHOW_DIALOG_NOTIFICATION_LOGGED_TWICE'
export const SET_SHOW_DIALOG_NOTIFICATION_LOGGED_TWICE_TEST = 'SET_SHOW_DIALOG_NOTIFICATION_LOGGED_TWICE_TEST'