
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

import * as actions from './actions'
import * as types from './types'

export const getLookupValues = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_GRID_PO_ITEMS_LOOKUP_FIELD_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.component + '/search?' + payload.component + '=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setLookupValues(result.response,payload),
                    requestActions.requestSucceeded(config.system.api + '/components/' + payload.component + '/search?' + payload.component + '=' + payload.search)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.component + '/search?' + payload.component + '=' + payload.search, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getRelatedRowItem = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SELECTED_ROW_RELATED_ITEMS_VALUES),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/maintenancetransaction/search?maintdescription=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'supplier',
                    'order': '-datestamp,amount',
                    'take': 1,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setRelatedRowItem(payload, result.response),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/maintenancetransaction/search?maintdescription=' + payload)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenancetransaction/search?maintdescription=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )



export const getRelatedRowItemMax = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SELECTED_ROW_RELATED_ITEMS_VALUES_MAX),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/maintenancetransaction/search?maintdescription=' + payload.item,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'supplier',
                    'order': '-datestamp',
                    'take': 1,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setRelatedRowItemMax(result.response, payload.data),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/maintenancetransaction/search?maintdescription=' + payload)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenancetransaction/search?maintdescription=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )



export const getMaintenanceItem = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_MAINTENANCE_ITEM_VALUE_VATTABLE),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/maintenanceitem/search?maintenanceitem_id=' + payload.id,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 1,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setMaintenanceItem(result.response, payload.inx),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/maintenanceitem/search?maintenanceitem_id=' + payload.id)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenanceitem/search?maintenanceitem_id=' + payload.id, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


        

        export const getExceptions = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(
                ofType(types.GET_EXCEPTIONS),
                delay(0)
                ,mergeMap(({ payload }) =>
                    ajax$({
                        url: config.system.api + '/components/maintenancetransaction/other',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                            'salt': store.value.cognito.authenticateUser.jwt,
                            'datetime': store.value.cognito.authenticateUser.datetime,
                        },
                        body: payload
                    })
                        .pipe(
                        mergeMap((result) => [
                            actions.setExceptions(result.response),
                            requestActions.requestSucceeded(config.system.api + '/components/maintenancetransaction/search?maintenancestatus_fktext=Open&vehicle_fktext=')
                        ])
                        ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenancetransaction/search?maintenancestatus_fktext=Open&vehicle_fktext=', error)))
                        //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
                )
                ,catchError((error) =>
                    of$(routerActions.route('/support', false, error))
                )
            )