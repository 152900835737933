import * as colours from '@mui/material/colors'

export const counter = {
    width: 30,
    height: 30,
    textAlign: 'center',
    verticalAlign: 'middle',
    border: '3px solid',
    borderColor: colours.red[500],
    display: 'table-cell',
    borderRadius: '50%',
    fontWeight: 'bold'
}

export const alignRight = {
    textAlign: 'right',
    width: '100%',
    verticalAlign: 'middle',
    display: 'table-cell',
}