import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from 'moment';

import Card from '@mui/material/Card'

//Styling
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import * as signedInActions from "../SignedIn/actions";
import * as cognitoActions from '../Cognito/actions'

import Logo from '../Logo/component'

import { signOut } from 'aws-amplify/auth'

class Permission extends Component {

  getLayout() {
    return <Container component="main" maxWidth="lg">
            <br /> <br /> <br /> <br /> <br /> <br />
            <div style={{ marginTop: '150px' }}>
                <Card style={{ backgroundColor: '#fff', padding: '25px' }}>
                <Logo />
                    <h2>Welcome, {this.props.state.firstname} {this.props.state.lastname} </h2><br />
                    <h4>No teams link to your profile…</h4>
                    <br/>
                    <strong>Please contact your system administrator for further assistance for the final step in this process. </strong>
                    <hr />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(event, value) => {
                            signOut()
                            .then(data => {
                                //console.log('handleSingout', data)
                                this.props.actions.signoutCognitoUser()
                            })
                            .catch(err => {
                                //console.log('handleSingout Err', err)
                            });
                        }}
                        >
                        {"Back to Login Screen "}
                    </Button>
                </Card>

                
            </div>
        </Container>
    }

  render() {
    return this.getLayout();
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
        ...state.user,
    }
    
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
      actions: bindActionCreators(
          {
              ...signedInActions,
              ...cognitoActions,
          },
          dispatch
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Permission);
