import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                filename: '',
                invoicepreview: ''
            }
        case types.SET_UPLOAD_BOOKING_INVOCE_DOCUMENT_PREVIEW:
        return {
            ...state,
            filename: action.payload.filename,
            invoicepreview: action.payload.preview,
        }
    case types.CLEAR_UPLOAD_BOOKING_INVOCE_DOCUMENT_PREVIEW:
        return {
            ...state,
            filename: action.payload,
            invoicepreview: action.payload,
        }
    case types.SET_BOOKING_INVOICE_COMPONENT_FIELD_INPUT_VALUE:
        return {
            ...state,
            [action.payload.prop]: action.payload.value
        }
        default:
            return state
    }
}