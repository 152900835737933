export const SET_NEW_FEATURE = 'SET_NEW_FEATURE'
export const SET_BUG_FIXES = 'SET_BUG_FIXES' 
export const SET_ENHANCEMENTS = 'SET_ENHANCEMENTS'
export const SET_RELEASE_DATE = 'SET_RELEASE_DATE'
export const SET_ITEM = 'SET_ITEM'
export const SET_RELEASE_VERSION = 'SET_RELEASE_VERSION'
export const SAVE_RELEASE_NOTE = 'SAVE_RELEASE_NOTE' 
export const SET_DESCRIPTION = 'SET_DESCRIPTION'
export const SET_DIALOG_STATUS = 'SET_DIALOG_STATUS' 
export const REMOVE_LAST = 'REMOVE_LAST'
export const CLEAR_ALL = 'CLEAR_ALL' 
export const UPDATE_READ_NOTE = 'UPDATE_READ_NOTE'
export const UPDATE_USER_READ_NOTE = 'UPDATE_USER_READ_NOTE'
export const GET_RELEASE_NOTE = 'GET_RELEASE_NOTE'
export const SET_RELEASE_NOTE = 'SET_RELEASE_NOTE' 
export const CLEAR_RELEASE_NOTE ='CLEAR_RELEASE_NOTE'
export const SET_RELEASE_NOTE_TAB = 'SET_RELEASE_NOTE_TAB'
export const CLEAR_ITEM = 'CLEAR_ITEM'
export const SET_NOTE = 'SET_NOTE'
export const SET_PAGE = 'SET_PAGE'
export const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE' 
