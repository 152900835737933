import * as types from './types'
import * as _ from 'lodash'




export const getInvoiceResolveDocuments= (resolvedocuments) => {


    return (dispatch, getState) => {
        dispatch({
            type: types.GET_RESOLVE_INVOICE_DOCUMENTS,
            payload: resolvedocuments
        })
    }
    
}


export const setInvoiceResolveDocuments= (payload) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_RESOLVE_INVOICE_DOCUMENTS,
            payload: payload
        })
    }
    
}
