import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'
import ViewQuotation from '../../../controls/view_quotation'
import TextField from '@mui/material/TextField'
import Fields from '../../../controls/cardfields'
import Card from '../../../controls/card'
import PdfViewer from '../../PDF/component'
import Table from '@mui/material/Table'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowSTRQuotationReview extends Component {
    componentDidMount() {
        this.props.actions.resetForm()
    }

    getQuotation() {
        let data = this.data
        //console.log('Quotation',data)
        //console.log('getQuotation data', data)
        return <div><Fields 
            fields={[
                {size: 4, label: 'Quote Number:', value: data.quotenumber},
                {size: 4, label: 'Quote Date:', value: data.datestamp},
                {size: 4, label: 'Created By:', value: data.user.firstname + ' ' + data.user.lastname },
                {size: 4, label: 'Customer', value: data.data.customer},
                {size: 4, label: 'RFQ Date:', value: data.data.rfqdate},
                {size: 4, label: 'RFQ Time:', value:  data.data.rfqtime},
                {size: 4, label: 'Quote For', value: data.data.additional ? 'Replace Vehicle' : 'Additional Vehicle'},
                {size: 4, label: 'Replace Vehicle', value: data.data.vehicle, dispay: data.data.additional},
                {size: 4, label: 'Pick Up Address', value: data.data.collection_address.text},
                {size: 4, label: 'Delivery Address', value: data.data.delivery_address.text}
            ]}
        />
        {
            this.step2 && <Fields 
                fields={[
                    {size:4, label: 'Review By:', value: this.step2.user.firstname + ' ' + this.step2.user.lastname},
                    {size:4, label: 'Comments:', value: this.step2.comments}
                ]}
            />
        }
        </div>
    }
          
    getContract() {
        let data = this.data.data
        return<Fields 
            fields={[
                {size: 4, label: 'Vehicle Category:', value: data.strratecard},
                {size: 4, label: 'Transmission:', value: data.transmission},
                {size: 4, label: 'Contact Period:', value: data.period},
                {size: 4, label: 'Monthly Inclusive:', value: data.distance},
                {size: 4, label: 'Start Date:', value: data.startdate},
                {size: 4, label: 'End Date:', value: data.enddate},
                {size: 4, label: 'Contract Unit:', value: data.contractunit},
                {size: 4, label: 'Waivers:', value: data.waiver}
            ]}
        />
    } 

    getFinance() {
        let data = this.data.data
        return <Fields 
            fields={[
                {size: 4, label: 'Monthly Rental:', value: data.str_monthly_rental},
                {size: 4, label: 'Accessories Total:', value: data.accessory_tot_monthly},
                {size: 4, label: 'Waiver Total:', value: data.waiver_tot},
                {size: 4, label: 'Sub Total:', value: data.monthly_excl},
                {size: 4, label: 'VAT:', value: data.monthly_vat},
                {size: 4, label: 'Total Rental:', value: data.monthly_incl},
            ]}
        />
    } 
    
    getAccessory() {
        let data = this.data.data
        return <Table className="table table-hover text-nowrap mb-0">
            <thead>
                <tr>
                    <th>Accessory</th>
                    <th>Accessory Cost </th>
                    <th>Rate Per Month </th>
                </tr>
            </thead>
            <tbody>
                {
                    data.accessory_list.map(x => {
                    return <tr>
                        <td>{x.accessory_name}</td>
                        <td>{x.totalprice}</td>
                        <td>{x.ratepermonth}</td>
                        </tr>
                    })
                }
            </tbody>
        </Table>
    }
   
    getDocument() {
        return this.data.documents.length
            ? <PdfViewer
                key={'document_viewer_' + this.data.documents.length}
                documents={this.data.documents}
                preview={'preview'}
            />
            : 'Retrieving Documents'
    }

    getComments() {
        return     <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                    <Col xl={12}>
                    {this.props.workflow_queue_data.workflow_queue_id && <ViewQuotation
                     key={this.props.workflow_queue_data.workflow_queue_id}
                    id={this.props.workflow_queue_data.workflow_queue_id}
                     />
                    }</Col>
                       <Col xl={12}>
                    <TextField
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        id={'txt_laodfactor'}
                        label={'Enter Notes'}
                        hintText={'Enter Load Factor %'}
                        value={this.props.state.comments}
                        multiline={true}
                        rows={5}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue({ prop: 'comments', value: event.target.value })
                        }}
                    />
                       </Col>
                       </Row>
                     </Container>
                }
   

    getLayout(data) {
        this.data = data //this.props.workflow_queue_data.context_data.data.data.data[0]
        return  this.data ? <div>
            <Card
                title={window.t.en('Adhoc Rental Quotation Confirm')}
                subtitle={window.t.en('Ensure that all values are captured correctly before your proceed ')}
                content={this.getQuotation()}
            /> 
            <Card
                title={window.t.en('Contract Details')}
                subtitle={window.t.en('Contract parameters ')}
                content={this.getContract()}
            /> 
            <Card
                title={window.t.en('Accessories')}
                subtitle={window.t.en('Contract accessories list')}
                content={this.getAccessory()}
            />
            <Card
                title={window.t.en('Finance and Rental')}
                subtitle={window.t.en('Contract finance details ')}
                content={this.getFinance()}
            /> 
            <Card
                title={window.t.en('Documents')}
                subtitle={window.t.en('Attached documets')}
                content={this.getDocument()}
            />
            <Card
                title={window.t.en('Quotation Notes')}
                subtitle={window.t.en('Enter Quotation Notes')}
                content={this.getComments()}
            />
        </div>
        : 'Please waiting...'
    }

    render() {
        //console.log('WorkflowReview this.props.workflow_queue_data',this.props.workflow_queue_data)
        let data = this.props.workflow_queue_data.context_data.data.data.data[0]
        data = data.step1 ? data.step1 : data
        this.step2 = data.step2 ? data.step2 : null
      
        //console.log('WorkflowReview getLayout',this.data)
        return data ? this.getLayout(data) : 'Loading'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.strQuotationReview,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowSTRQuotationReview)