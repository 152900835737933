export const filepreview = ''
export const filename = ''
export const comments = ''
export const completeddate = null
export const documents = []

export const invoicedate = ''
export const invoice_number = ''
export const invoice_amount = ''
export const isinvoice_uploaded = false

export const completedby = ''
export const odometer = ''


export const funder = ''
export const contractenddate = null
export const funder_rv = 0.00
export const funder_month_togo = 0.00
export const trade_value = 0.00
export const retail_value = 0.00
export const market_value = 0.00
export const bestoffer = 0.00
export const offers = [
    {
        id:0,
        description:'',
        amount: 0.00,
        trade: 0.00,
        market: 0.00
    }
]
export const offer_increase = 0.00
export const finance_comments = ''
export const ceo_comments_profit_loss = ''
export const funder_rental = 0.00
export const profit_loss_excl_eq = 0.00
export const profit_loss = 0.00
export const commission = 1250.00
export const inv_value_incl = 0.00
export const nbv_capital_incl = 0.00
export const ceo_comments_settle = ''
export const settle_excl_vat_less = 0.00
export const settle_incl = 0.00
export const settle_excl = 0.00
export const yardlist_comments = ''

export const maint_fund_bal = 0.00
export const cap_eq_fund_bal = 0.00
export const diff_best_offer_settle = 0.00
export const diff_best_offer_settle_cap_eq = 0.00
export const vehicle_cost = 0.00
export const vehicle_acc_depr = 0.00
