import React from 'react'
import moment from 'moment'
 
import { Store as b } from 'react-notifications-component';

import * as appTypes from './components/App/types'

export const notify = (store) => (next) => (action) => {
    if (action.type === appTypes.SHOW_NOTIFICATION) {
        b.addNotification({
            title: action.payload.title,
            message: action.payload.additionalText,
            type: action.payload.type ? action.payload.type : 'success',
            //type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
        });
    }
        next(action)
}