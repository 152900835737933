import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'

export const getTeamsWorkflowQueueTasksEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(
        types.GET_WORKFLOW_QUEUE_TASK_TEAMS
    ),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/team/in?team_id=' + (store.value.accounts.teams.length ? store.value.accounts.teams.map(t => t.team_id).join('.') : 0),
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    //'related': 'icon'
                }
            }).pipe(
                mergeMap(payload => [
                    actions.setWorkflowQueueTaskTeams(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/team/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )