import * as colours from '@mui/material/colors'

export const appBarContainer = {
    position: 'fixed',
    top: '0px',
    width: '100%',
    zIndex: 100
}

export const accountName = {

    marginTop:"6%"
}

export const table = {
    display: 'flex'
}

export const cell = {
    display: 'cell'
}

export const action = {
    position: 'absolute',
    bottom: 0,
    zIndex: 999999,
    fontSize: '10px',
    right: '25%',
    left: '50%',
    marginLeft: '-150px'
}

export const body = {
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    // marginLeft: 290,
}

export const contentes = {
    backgroundColor: 'red'
}

export const scrollbar = {
    backgroundColor: colours.red[500],
    width: 10,
    right: 0,
    padding: 0,
    margin: 0
}

export const workspace = {
    height: '100%',
    opacity: 0.96,
    backgroundColor: '#F8F8F8'
}

export const menuIcon = {
    //fontSize: 16,
    /*     width: 10,
        height: 10 */
}

export const clients = {
    position: 'fixed',
    top: 21,
    right: 525,
    zIndex: 100000
}

export const notificaationMargin = {
    float: 'left',
    marginTop: '4%',
    marginLeft: '-22%'
}


export const tabs = {
    height: 100
}

export const tab = {
    height: 100
}

export const logoSize = {
    width: 180,
}

export const khulaLogoSize = {
    marginTop: 14,
    width: 150
}

export const ignixxionLogoStyle = {
    marginTop: 8,
    width: 150
}

export const tree = {
    position: 'fixed',
    top: 12,
    right: 305,
    zIndex: 100000
}

export const versioning = {
    position: 'fixed',
    bottom: 2,
    marginLeft: 5,
    fontSize: 9,
    color: colours.grey[900],
    padding: 10,
    fontWeight: 'bold',
    //backgroundColor: '#dcd6d7',
    zIndex: 2000
}

export const appBar = {
    backgroundColor: 'white',

    //backgroundColor: colours.grey[50],
    //backgroundImage: 'url("' + logo + '")',
    /*     backgroundRepeat: 'no-repeat',
        backgroundSize: '200px',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center 10px',
        opacity: 0.85 */
}

export const appBarTitle = {
    color: colours.red[500]
}

export const appBarIconLeft = {
    color: colours.red[500]
}

export const appBarIconRight = {
    color: colours.red[500],
    fontSize: '22px'
}

export const content = {
    marginTop: '48px',
    paddingLeft: 300,
    backgroundColor: 'red',
    marginBottom: '65px'
}

const drawerWidth = 50;

export const drawer = {
    width: 400,
    flexShrink: 0,
}

export const drawerPaper = {
    width: 400,
}

export const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    appBar: {
        width: `calc(100% - ${drawer.width}px)`,
        marginLeft: 280,
    },
    appBar2: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: 50,
    },
    drawer: {
        width: 280,
        flexShrink: 0,
    },
    drawer2: {
        width: 50,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 280,
    },
    drawerPaper2: {
        width: 60,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 0,
        // backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1),
        paddingLeft: 300
    },
    content2: {
        flexGrow: 0,
        // backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1),
        paddingLeft: 80
    },

});

export const MuiFormControl = {
    width: "100%",
}

export const li = {
    fontSize: '12px',

}

export const cardDropdown = {
    backgroundColor: "white"
}

export const notificationTitle = {
    fontSize: 14,
}

export const notificationRoot = {
    minWidth: 275,
}

export const liPrimary = {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#000'
}

export const subPrimary = {
    fontSize: '15px',
    fontWeight: 'bold',
}

export const icon =  {
    width: 20,
    height: 20,
  }

export const link = {
    display: 'flex'
}

export const badge = {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  }
//   '@keyframes ripple': {
//     '0%': {
//       transform: 'scale(.8)',
//       opacity: 1
//     },
//     '100%': {
//       transform: 'scale(2.4)',
//       opacity: 0
//     }
//   }