import * as types from './types'
import * as props from './props'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_JSREPORT_BUFFER_STREAM_OBJECT:
            return {
                ...state,
                ...action.payload
            }
        case types.SET_JSREPORT_FILTER:
            return {
                ...state,
                [action.payload.prop]: action.payload.filter,
            }
        case types.SET_JSREPORT_FILTER_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.data,
            }          

        case types.SET_JSREPORT_DATA:
            return {
                ...state,
                report_data: action.payload.report_data
            } 
        default:
            return state
    }
}