import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'
import { responsiveStateReducer } from 'redux-responsive';
import * as cognitoActions from './components/Cognito/actions'

import oqlis from './components/Oqlis/reducer'
import financeImport from './components/FinanceImports/reducer'
import cis from './components/CIS/reducer'
import create_report from './components/CreateReport/reducer'
import resetPasswordDialog from './components/Dialog/reducer'
import acceptCustomerSignedSTR from './components/WorkflowSTRHeader/AcceptCustomerSignedSTR/reducer'
import accounts from './components/Account/reducer'
import adhocGrid from './components/Custom/AdhocGrid/reducer'
import app from './components/App/reducer'
import global from './components/App/global'
import assignedTaskCount from './components/WorkflowQueueTasks/AssignedTaskCount/reducer'
import assignedTasks from './components/WorkflowQueueTasks/AssignedTasks/reducer'
import businessCustomers from './components/BusinessCustomers/reducer'
import businessIntelligence from './components/BusinessIntelligence/reducer'
import calculator from './components/Calculator/reducer'
import calculatorContainer from './components/CalculatorContainer/reducer'
import calendar from './components/CalendarControl/reducer'
import captureSTR from './components/WorkflowSTRHeader/CaptureSTR/reducer'
import strItemGrid from './components/WorkflowSTRHeader/STRItemGrid/reducer'
import strContractActivation from './components/WorkflowSTRHeader/STRContractActivation/reducer'
import clients from './components/Clients/reducer'
import cognito from './components/Cognito/reducer'
import completedTaskCount from './components/WorkflowQueueTasks/CompletedTaskCount/reducer'
import completedTasks from './components/WorkflowQueueTasks/CompletedTasks/reducer'
import components from './components/Components/reducer'
import confirmAccount from './components/ConfirmAccount/reducer'
import connector from './components/Connector/reducer'
import contractRestructure from './components/WorkflowContractRestructure/ContractRestructure/reducer'
import contractRestructureLines from './components/WorkflowContractRestructure/ContractRestructureLines/reducer'
import contractSelectionGrid from './components/WorkflowContractRestructure/ContractSelectionGrid/reducer'
import customAdhocComponentFieldInput from './components/Custom/AdhocComponentFieldInput/reducer'
import dashboard from './components/Dashboard/reducer'
import detailComponentView from './views/DetailComponentView/reducer'
import document from './components/Document/reducer'
import executiveDashboard from './components/Dashboard/ExecutiveDashboard/reducer'
import visualDashboard from './components/Dashboard/VisualDashboard/reducer'
import forgotPassword from './components/ForgotPassword/reducer'
import form from './components/Form/reducer'
import view from './components/View/reducer'
import forwarder from './components/Forwarder/reducer'
import gate from './components/Gate/reducer'
import generalDashboard from './components/Dashboard/GeneralDashboard/reducer'
import history from './components/History/reducer'
import html from './components/HTML/reducer'
import imageUploader from './components/ImageUploader/reducer'
import infoSheet from './components/InfoSheet/reducer'
import invoiceap from './components/Invoiceap/reducer'
import invoiceapConfirm from './components/InvoiceapConfirm/reducer'
import invoiceapReject from './components/InvoiceapReject/reducer'
import invoiceapRejectConfirm from './components/InvoiceapRejectConfirm/reducer'
import licencerenewal from './components/Custom/LICENCERENEWAL/reducer'
import broadcast from './components/Custom/BROADCAST/reducer'
import loadOnDemand from './components/LoadOnDemand/reducer'
import login from './components/Login/reducer'
import masterComponentView from './views/MasterComponentView/reducer'
import messenger from './components/Messenger/reducer'
import minMap from './components/MinMap/reducer'
import modal from './components/Modal/reducer'
import multiSelect from './components/MultiSelect/reducer'
import navigationMenu from './components/NavigationMenu/reducer'
import newPassword from './components/NewPassword/reducer'
import pdf from './components/PDF/reducer'
import poAssessor from './components/WorkflowPOHeader/POAssessor/reducer'
import poBooking from './components/WorkflowPOHeader/POBooking/reducer'
import poBookingAcceptRebill from './components/WorkflowPOHeader/POBookingAcceptRebill/reducer'
import poBookingApproval from './components/WorkflowPOHeader/POBookingApproval/reducer'
import poBookingApprovalOps from './components/WorkflowPOHeader/POBookingApprovalOps/reducer'
import poBookingApprovalCEO from './components/WorkflowPOHeader/POBookingApprovalCEO/reducer'
import poBookingApprovalGM from './components/WorkflowPOHeader/POBookingApprovalGM/reducer'
import poBookingInvoice from './components/WorkflowPOHeader/POBookingInvoice/reducer'
import poBookingInvoiceUpload from './components/WorkflowPOHeader/POBookingInvoiceUpload/reducer'
import poBookingItems from './components/WorkflowPOHeader/POBookingItems/reducer'
import quoteItems from './components/WorkflowPOHeader/QuoteItems/reducer'
import poBookingRebillInvoiceUpload from './components/WorkflowPOHeader/POBookingRebillInvoiceUpload/reducer'
import poBookingSendAuth from './components/WorkflowPOHeader/POBookingSendAuth/reducer'
import poBookingSendCustomerRebillInvoice from './components/WorkflowPOHeader/POBookingSendCustomerRebillInvoice/reducer'
import poBookingSendRebill from './components/WorkflowPOHeader/POBookingSendRebill/reducer'
import poBookingSendRebillAuth from './components/WorkflowPOHeader/POBookingSendRebillAuth/reducer'
import poCloseBooking from './components/WorkflowPOHeader/POCloseBooking/reducer'
import poCloseRebillBooking from './components/WorkflowPOHeader/POCloseRebillBooking/reducer'
import poCorporateVehicle from './components/WorkflowPOHeader/POCorporateVehicle/reducer'
import poFMLTransnet from './components/WorkflowPOHeader/POFMLTransnet/reducer'
import poHeader from './components/WorkflowPOHeader/POHeader/reducer'
import poOfficeAdmin from './components/WorkflowPOHeader/POOfficeAdmin/reducer'
import poPanelBeaters from './components/WorkflowPOHeader/POPanelBeaters/reducer'
import poRebillApprovalByCEO from './components/WorkflowPOHeader/PORebillApprovalByCEO/reducer'
import poRebillApprovalByGM from './components/WorkflowPOHeader/PORebillApprovalByGM/reducer'
import poRebillApprovalByTech from './components/WorkflowPOHeader/PORebillApprovalByTech/reducer'
import poRebillApprovalByOps from './components/WorkflowPOHeader/PORebillApprovalByOps/reducer'
import poRepairs from './components/WorkflowPOHeader/PORepairs/reducer'
import poService from './components/WorkflowPOHeader/POService/reducer'
import doaGenericApproval from './components/WorkflowPOHeader/DOAGenericApproval/reducer'
import poSTRTransnetCorporate from './components/WorkflowPOHeader/POSTRTransnetCorporate/reducer'
import poSubscriptions from './components/WorkflowPOHeader/POSubscriptions/reducer'
import poTowing from './components/WorkflowPOHeader/POTowing/reducer'
import poTyres from './components/WorkflowPOHeader/POTyres/reducer'
import progressBar from './components/ProgressBar/reducer'
import queuedTaskCount from './components/WorkflowQueueTasks/QueuedTaskCount/reducer'
import queuedTasks from './components/WorkflowQueueTasks/QueuedTasks/reducer'
import quoteHeader from './components/QuoteHeader/reducer'
import quoteHeaderSTR from './components/QuoteHeaderSTR/reducer'
import quoteSelectGrid from './components/QuoteSelectGrid/reducer'
import readComponent from './components/ReadComponent/reducer'
import reassignTask from './components/WorkflowQueueTasks/ReassignTask/reducer'
import recaptcha from './components/Recaptcha/reducer'
import redirect from './components/Redirect/reducer'
import request from './components/Request/reducer'
import searchComponent from './components/SearchComponent/reducer'
import signedIn from './components/SignedIn/reducer'
import signUp from './components/SignUp/reducer'
import snackbarMessage from './components/SnackbarMessage/reducer'
import status from './components/Status/reducer'
import support from './components/Support/reducer'
import teamManagement from './components/TeamManagement/reducer'
import cardManagement from './components/CardManagement/reducer'
import teamOptions from './components/TeamManagement/TeamOptions/reducer'
import teams from './components/Teams/reducer'
import tiles from './components/Tiles/reducer'
import tracker from './components/Tracker/reducer'
import upload from './components/Upload/reducer'
import uploadAccessory from './components/UploadAccessory/reducer'
import user from './components/User/reducer'
import userOptions from './components/TeamManagement/UserOptions/reducer'
import vehicletransfer from './components/Custom/VEHICLETRANSFER/reducer'
import visualComponent from './components/VisualComponent/reducer'
import workflowInput from './components/WorkflowInput/reducer'
import workflowPOAccessories from './components/WorkflowPOAccessories/reducer'
import workflowPOAccessoriesConfirm from './components/WorkflowPOAccessoriesConfirm/reducer'
import WorkflowPOApprovalByCEO from './components/WorkflowPOApprovalByCEO/reducer'
import workflowPOContractActivation from './components/WorkflowPOContractActivation/reducer'
import workflowPOContractActivationChecklist from './components/WorkflowPOContractActivationChecklist/reducer'
import workflowPOContractActivationVAF from './components/WorkflowPOContractActivationVAF/reducer'
import workflowPODelivery from './components/WorkflowPODelivery/reducer'
import workflowPOGRV from './components/WorkflowPOGRV/reducer'
import workflowPOHeader from './components/WorkflowPOHeader/reducer'
import workflowPOReceiptAccessories from './components/WorkflowPOReceiptAccessories/reducer'
import workflowPOReceiptVehicle from './components/WorkflowPOReceiptVehicle/reducer'
import workflowPOSupplier from './components/WorkflowPOSupplier/reducer'
import workflowPOUploadQuote from './components/WorkflowPOUploadQuote/reducer'
import workflowQueueTasks from './components/WorkflowQueueTasks/reducer'
import workflowQueueTaskDocuments from './components/WorkflowQueueTasks/WorkflowQueueTaskDocuments/reducer'
import workflowQuoteHeader from './components/WorkflowQuoteHeader/reducer'
import workflowQuoteItemGrid from './components/WorkflowQuoteItemGrid/reducer'
import workflows from './components/Workflows/reducer'
import workflowTask from './components/WorkflowTask/reducer'
import workflowQuoteReview from './components/WorkflowQuoteReview/reducer'
import workflowQuoteApprove from './components/WorkflowQuoteApprove/reducer'
import workflowTaskManager from './components/ManageWorkFlowTask/reducer'
import workflowTaskAction from './components/WorkflowTaskAction/reducer'
import workflowTaskApproval from './components/WorkflowTaskApproval/reducer'
import workflowTaskInput from './components/WorkflowTaskInput/reducer'
import workflowTaskStepComponentMultipleCreator from './components/WorkflowTaskStepComponentMultipleCreator/reducer'
import workflowTaskStepComponentMultipleSelector from './components/WorkflowTaskStepComponentMultipleSelector/reducer'
import workflowTaskStepComponentSingleCreator from './components/WorkflowTaskStepComponentSingleCreator/reducer'
import workflowTaskStepComponentSingleSelector from './components/WorkflowTaskStepComponentSingleSelector/reducer'
import workflowTaskStepQuoteSingleSelector from './components/WorkflowTaskStepQuoteSingleSelector/reducer'
import workflowVehicleHistory from './components/WorkflowQueueTasks/WorkflowVehicleHistory/reducer'
import workflowDocuments from './components/WorkflowQueueTasks/WorkflowDocuments/reducer'
import workflowDisposal from './components/WorkflowQueueTasks/WorkflowDisposal/reducer'
import workflowTimeline from './components/WorkflowQueueTasks/WorkflowTimeline/reducer'
import workflowTriggers from './components/WorkflowQueueTasks/WorkflowTriggers/reducer'
import workflowUploadPID from './components/WorkflowUploadPID/reducer'
import workflowUploadPOD from './components/WorkflowUploadPOD/reducer'
import workflowVehicleDeliveryConfirm from './components/WorkflowVehicleDeliveryConfirm/reducer'
import workflowView from './components/WorkflowView/reducer'
import workflowPOApprovalByCOO from './components/WorkflowPOApprovalByCOO/reducer'
import workflowPOApprovalByCEO from './components/WorkflowPOApprovalByCEO/reducer'
import workflowPOApprovalByGM from './components/WorkflowPOApprovalByGM/reducer'
import workflowViewFMLQuoteConfirm from './components/WorkflowViewFMLQuoteConfirm/reducer'
import workflowViewFMLQuoteSummary from './components/WorkflowViewFMLQuoteSummary/reducer'
import createMMQuote from './components/WorkflowManageMaintenance/CreateMMQuote/reducer'
import billingRunView from './components/WorkflowBillingRun/BillingRunView/reducer'
import billinrunstart from './components/WorkflowBillingRun/BillingRunStart/reducer'
import billingrefresh from './components/WorkflowBillingRun/BillingRefresh/reducer'
import billingpreview from './components/WorkflowBillingRun/BillingPreview/reducer'
import billingsendinvoices from './components/WorkflowBillingRun/BillingSendInvoices/reducer'
import billinginterest from './components/WorkflowBillingRun/BillingInterest/reducer'
import billingweeklycis from './components/WorkflowBillingRun/BillingWeeklyCIS/reducer'
import billingodofile from './components/WorkflowBillingRun/BillingOdoFile/reducer'
import billingoocpfile from './components/WorkflowBillingRun/BillingOocpFile/reducer'
import activateMMContract from './components/WorkflowManageMaintenance/ActivateMMContract/reducer'
import disposalcomponentview from './components/Custom/CONTRACTTERMINATION/reducer'
import billingmoduleview from './components/Custom/BILLINGMODULE/reducer'
import accessory from './components/Custom/ACCESSORY/reducer'
import customerderivative from './components/Custom/CUSTOMERDERIVATIVE/reducer'
import costcentrechangecomponentview from './components/Custom/CUSTOMCOSTCENTRECHANGE/reducer'
import registrationchangecomponentview from './components/Custom/CUSTOMREGISTRATIONCHANGE/reducer'
import quoteEdit from './components/QuoteEdit/reducer'
import purchaseorderview from './components/Custom/PURCHASEORDER/reducer'
import cancelledTasksApproval from './components/CancelledTasksApproval/reducer'
import workflowDefleetView from './components/WorkflowDefleet/reducer'
import defleetVehicleInfo from './components/WorkflowDefleet/DefleetVehicleInfo/reducer'
import defleetGenerateChecksheetView from './components/WorkflowDefleet/DefleetGenerateChecksheet/reducer'
import defleetCaptureQuote from './components/WorkflowDefleet/DefleetCaptureQuote/reducer'
import defleetUploadQuotes from './components/WorkflowDefleet/DefleetUploadQuotes/reducer'
import defleetUploadQuotesConfirm from './components/WorkflowDefleet/DefleetUploadQuotesConfirm/reducer'
import defleetQuoteApproval from './components/WorkflowDefleet/DefleetQuoteApproval/reducer'
import defleetQuoteApprovalGM from './components/WorkflowDefleet/DefleetQuoteApprovalGM/reducer'
import defleetQuoteApprovalCEO from './components/WorkflowDefleet/DefleetQuoteApprovalCEO/reducer'
import defleetUploadChecksheet from './components/WorkflowDefleet/DefleetUploadChecksheet/reducer'
import defleetSendRebill from './components/WorkflowDefleet/DefleetSendRebill/reducer'
import defleetAcceptRebill from './components/WorkflowDefleet/DefleetAcceptRebill/reducer'
import defleetSendRebillAuth from './components/WorkflowDefleet/DefleetSendRebillAuth/reducer'
import defleetCloseBooking from './components/WorkflowDefleet/DefleetCloseBooking/reducer'
import defleetContractTermination from './components/WorkflowDefleet/DefleetContractTermination/reducer'
import defleetTerminationRequest from './components/WorkflowDefleet/DefleetTerminationRequest/reducer'
import defleetTerminationRequestApproval from './components/WorkflowDefleet/DefleetTerminationRequestApproval/reducer'
import defleetTerminationRequestApprovalGM from './components/WorkflowDefleet/DefleetTerminationRequestApprovalGM/reducer'
import defleetTerminationRequestApprovalCEO from './components/WorkflowDefleet/DefleetTerminationRequestApprovalCEO/reducer'
import defleetRepairChecksheet from './components/WorkflowDefleet/DefleetRepairChecksheet/reducer'
import defleetDisposal from './components/WorkflowDefleet/DefleetDisposal/reducer'
import defleetDisposalApproval from './components/WorkflowDefleet/DefleetDisposalApproval/reducer'
import defleetDisposalApprovalCEO from './components/WorkflowDefleet/DefleetDisposalApprovalCEO/reducer'
import defleetRebillDispute from './components/WorkflowDefleet/DefleetRebillDispute/reducer'
import workflowCreateQuote from './components/WorkflowFMLQuote/WorkflowCreateQuote/reducer'
import workflowReviewQuote from './components/WorkflowFMLQuote/WorkflowReviewQuote/reducer'
import workflowApproveQuote from './components/WorkflowFMLQuote/WorkflowApproveQuote/reducer'
import disposaldecisionview from './components/Custom/DISPOSALDECISION/reducer'
import supplierpaymentmanagement from './components/Custom/SUPPLIERPAYMENTMANAGEMENT/reducer'
import supplierpaymentconfirmation from './components/Custom/SUPPLIERPAYMENTCONFIRMATION/reducer'
import workflowPaymentView from './components/WorkflowPayment/reducer'
import paymentApproval from './components/WorkflowPayment/PaymentApproval/reducer'
import defleetItems from './components/WorkflowDefleet/DefleetItems/reducer'
import excelImport from './components/Import/reducer'
import userProfile from './components/Profile/reducer'
import jsReport from './components/JSReport/reducer'
import workqueue from './components/Workqueue/reducer'
import dashboardView from './components/BusinessIntelligence/DashboardView/reducer'
import controls from './controls/reducer'

import strHeaderQuote from './components/WorkflowSTRQuote/reducer'
import workflowGanttChart from './components/WorkflowSTRQuote/WorkflowGanttChart/reducer'
import strCreateQuote from './components/WorkflowSTRQuote/WorkflowCreateQuote/reducer'
import supplierpaymentapprovalgm from './components/Custom/SUPPLIERPAYMENTAPPROVALGM/reducer'
//import customerinvoice from './components/Custom/CUSTOMERINVOICE/reducer'
import accountingItemLink from './components/Custom/ACCOUNTINGITEMLINK/reducer'
import customertransaction from './components/Custom/CUSTOMERTRANSACTION/reducer'
import suppliertransaction from './components/Custom/SUPPLIERTRANSACTION/reducer'
import customerallocation from './components/Custom/CUSTOMERALLOCATION/reducer'
import supplierallocation from './components/Custom/SUPPLIERALLOCATION/reducer'
import defleetQuoteApprovalOPS from './components/WorkflowDefleet/DefleetQuoteApprovalOPS/reducer'
import paymentApprovalTech from './components/WorkflowPayment/PaymentApprovalTech/reducer'
import paymentApprovalOPS from './components/WorkflowPayment/PaymentApprovalOPS/reducer'
import paymentApprovalGM from './components/WorkflowPayment/PaymentApprovalGM/reducer'
import paymentApprovalFinance from './components/WorkflowPayment/PaymentApprovalFinance/reducer'
import paymentApprovalAdmin from './components/WorkflowPayment/PaymentApprovalAdmin/reducer'
import customerstatementview from './components/Custom/CUSTOMERSTATEMENT/reducer'
import paymentApprovalRelease from './components/WorkflowPayment/PaymentApprovalRelease/reducer'
import paymentApprovalAuthorisation from './components/WorkflowPayment/PaymentApprovalAuthorisation/reducer'
import paymentAllocation from './components/WorkflowPayment/PaymentAllocation/reducer'
import cancelledTasksApprovalOPS from './components/CancelledTasksApprovalOPS/reducer'
import statementPreview from './components/WorkflowStatementRun/StatementPreview/reducer'
import enterestReview from './components/WorkflowStatementRun/EnterestReview/reducer'
import statementReview from './components/WorkflowStatementRun/StatementReview/reducer'
import paymentCapture from './components/WorkflowCreditorsPayment/PaymentCapture/reducer'
import purchaseOrderCapture from './components/WorkflowCreditorsPayment/PurchaseOrderCapture/reducer'
import paymentItem from './components/WorkflowCreditorsPayment/PaymentItem/reducer'
import paymentDOAApproval from './components/WorkflowCreditorsPayment/PaymentDOAApproval/reducer'
import xero from './components/Xero/reducer'
import grid from './components/Grid/reducer'
import releaseNote from './components/ReleaseNote/reducer'
import subAccount from './components/SubAccount/reducer'
import clientAccount from './components/ClientAccount/reducer'
import licenseeAccount from './components/LicenseeAccount/reducer'
import strQuotationReview from './components/WorkflowSTRQuote/WorkflowReview/reducer'
import strQuotationBookout from './components/WorkflowSTRQuote/WorkflowBookout/reducer'
import strQuotationActivation from './components/WorkflowSTRQuote/WorkflowActivation/reducer'
import pTest from './components/Test/reducer'
import events from './components/Events/reducer'
import relatedGrid from './components/RelatedGrid/reducer'
import reportScheduler from './components/ReportScheduler/reducer'
import budgetmanagement from './components/BudgetManagement/reducer'
import reseller from './components/Reseller/reducer'
import teamManager from './components/TeamManagement/TeamManager/reducer'

import hooks from './components/Hooks/reducer'
import amplify from './components/Amplify/reducer'
import relatedForm from './components/RelatedForm/reducer'
import teamstructure from './components/TeamStructure/reducer'
import userstructure from './components/UserStructure/reducer'
import captureQuotation from './components/WorkflowSTRQuote/CaptureQuotation/reducer'
import customerDerivativeCategory from './components/CustomerDerivativeCategory/reducer'
import workflowFMLCustomerPO from './components/WorkflowFMLCustomerPO/reducer'

import customerMasterCapture from './components/CustomerMaster/CustomerMasterCapture/reducer'
import supplierMasterCapture from './components/SupplierMaster/SupplierMasterCapture/reducer'
import accessoryMasterCapture from './components/AccessoryMaster/AccessoryMasterCapture/reducer'
import vehicleMasterCapture from './components/VehicleMaster/VehicleMasterCapture/reducer'
import accessoryMasterActivation from './components/AccessoryMaster/AccessoryMasterActivation/reducer'
import pricingMasterActivation from './components/VehicleMaster/PricingMasterActivation/reducer'
import supplierMaster from './components/SupplierMaster/reducer'
import accessoryMaster from './components/AccessoryMaster/reducer'

import costCentreChangeCapture from './components/CostCentreChange/CostCentreChangeCapture/reducer'
import odometerChangeCapture from './components/OdometerChange/OdometerChangeCapture/reducer'
import registrationChangeCapture from './components/RegistrationChange/RegistrationChangeCapture/reducer'

import costCentreChangeApprove from './components/CostCentreChange/CostCentreChangeApprove/reducer'
import odometerChangeApprove from './components/OdometerChange/OdometerChangeApprove/reducer'
import registrationChangeApprove from './components/RegistrationChange/RegistrationChangeApprove/reducer'

import dashboardsView from './components/DashboardView/reducer'
import reportsView from './components/ReportView/reducer'
import manageComponent from './components/TeamManagement/ManageComponent/reducer'
import hierarchy from './components/Hierarchy/reducer'
import hierarchyStaff from './components/HierarchyStaff/reducer'
import hierarchyTeam from './components/HierarchyTeam/reducer'
import bulkQuotation from './components/BulkQuotation/reducer'
import supplierpaymemtallocation from './components/Custom/SUPPLIERPAYMENTALLOCATION/reducer'
import enatis from './components/eNatis/reducer'


import balancesheet_rpt from './components/Custom/BALANCESHEET_RPT/reducer'
import cashflow_rpt from './components/Custom/CASHFLOW_RPT/reducer'
import profitloss_rpt from './components/Custom/PROFITLOSS_RPT/reducer'
import trialbalance_rpt from './components/Custom/TRIALBALANCE_RPT/reducer'

import creditorsPaymentCreate from './components/CreditorsPayment/CreditorsPaymentCreate/reducer'
import creditorsPaymentReview from './components/CreditorsPayment/CreditorsPaymentReview/reducer'
import creditorsPaymentAllocate from './components/CreditorsPayment/CreditorsPaymentAllocate/reducer'
import workflowFMLReleaseNote from './components/WorkflowFMLReleaseNote/reducer'
import workflowFMLAcceptReleaseNote from './components/WorkflowFMLAcceptReleaseNote/reducer'
import creditNoteCreate from './components/CreditNote/CreditNoteCreate/reducer'
import creditNoteReview from './components/CreditNote/CreditNoteReview/reducer'

import interestRateCreate from './components/InterestRate/InterestRateCreate/reducer'
import interestRateReview from './components/InterestRate/InterestRateReview/reducer'

import cashbookArchiveCreate from './components/CashbookArchive/CashbookArchiveCreate/reducer'
import cashbookArchiveReview from './components/CashbookArchive/CashbookArchiveReview/reducer'

import cashBook from './components/Custom/CASH_BOOK/reducer'

import customerbankrecon from './components/Custom/CUSTOMERBANKRECON/reducer'
import supplierbankrecon from './components/Custom/SUPPLIERBANKRECON/reducer'

import bankReconciliation from './components/Custom/BANK_RECONCILIATION/reducer'
import activeDirectory from './components/ActiveDirectory/reducer'
import appAD from './components/AppAD/reducer'
import faq from './components/FAQ/reducer'

import activationSalesProcess from './components/SalesProcess/ActivationSalesProcess/reducer'
import salesProcessCapture from './components/SalesProcess/SalesProcessCapture/reducer'

import { connectRouter } from 'connected-react-router'

const appReducer = combineReducers({
    activationSalesProcess,
    salesProcessCapture,
    faq,
    appAD,
    activeDirectory,
    customerbankrecon,
    supplierbankrecon,
    cashbookArchiveCreate,
    cashbookArchiveReview,
    bankReconciliation,
    cashBook,
    interestRateReview,
    interestRateCreate,
    creditNoteReview,
    creditNoteCreate,
    supplierpaymemtallocation,
    enatis,
    workflowFMLAcceptReleaseNote,
    workflowFMLReleaseNote,
    bulkQuotation,
    accountingItemLink,
    creditorsPaymentAllocate,
    creditorsPaymentReview,
    creditorsPaymentCreate,
    balancesheet_rpt,
    cashflow_rpt,
    profitloss_rpt,
    trialbalance_rpt,
    hierarchy,
    hierarchyStaff,
    hierarchyTeam,
    manageComponent,
    costCentreChangeApprove,
    odometerChangeApprove,
    registrationChangeApprove,
    odometerChangeCapture,
    costCentreChangeCapture,
    registrationChangeCapture,
    pricingMasterActivation,
    vehicleMasterCapture,
    accessoryMasterActivation,
    accessoryMasterCapture,
    accessoryMaster,
    customerMasterCapture,
    supplierMasterCapture,
    supplierMaster,
    reportsView,
    dashboardsView,
    workflowFMLCustomerPO,
    workflowQuoteApprove,
    workflowQuoteReview,
    customerDerivativeCategory,
    captureQuotation,
    userstructure,
    teamstructure,
    teamManager,
    budgetmanagement,
    relatedForm,
    reseller,
    events,
    pTest,
    relatedGrid,
    strQuotationActivation,
    strQuotationBookout,
    strQuotationReview,
    workflowGanttChart,
    strHeaderQuote,
    subAccount,
    clientAccount,
    licenseeAccount,
    paymentDOAApproval,
    paymentItem,
    purchaseOrderCapture,
    paymentCapture,
    create_report,
    cis,
    financeImport,
    enterestReview,
    statementPreview,
    statementReview,
    xero,
    grid,
    doaGenericApproval,
    workqueue,
    hooks,
    amplify,
    resetPasswordDialog,
    cardManagement,
    jsReport,
    controls,
    userProfile,
    releaseNote,
    oqlis,
    router: connectRouter('/'),
    dashboardView,
    strCreateQuote,
    excelImport,
    workflowCreateQuote,
    workflowReviewQuote,
    workflowApproveQuote,
    customerderivative,
    accessory,
    acceptCustomerSignedSTR,
    accounts,
    adhocGrid,
    app,
    global,
    assignedTaskCount,
    assignedTasks,
    browser: responsiveStateReducer,
    businessCustomers,
    businessIntelligence,
    calculator,
    calculatorContainer,
    calendar,
    captureSTR,
    clients,
    cognito,
    completedTaskCount,
    completedTasks,
    components,
    confirmAccount,
    connector,
    contractRestructure,
    contractRestructureLines,
    contractSelectionGrid,
    customAdhocComponentFieldInput,
    dashboard,
    detailComponentView,
    document,
    executiveDashboard,
    visualDashboard,
    forgotPassword,
    form,
    view,
    forwarder,
    gate,
    generalDashboard,
    history: history,
    html,
    imageUploader,
    infoSheet,
    invoiceap,
    invoiceapConfirm,
    invoiceapReject,
    invoiceapRejectConfirm,
    licencerenewal,
    loadOnDemand,
    login,
    masterComponentView,
    messenger,
    minMap,
    modal,
    multiSelect,
    navigationMenu,
    newPassword,
    pdf,
    poAssessor,
    poBooking,
    poBookingAcceptRebill,
    poBookingApproval,
    poBookingApprovalOps,
    poBookingApprovalCEO,
    poBookingApprovalGM,
    poBookingInvoice,
    poBookingInvoiceUpload,
    poBookingItems,
    poBookingRebillInvoiceUpload,
    poBookingSendAuth,
    poBookingSendCustomerRebillInvoice,
    poBookingSendRebill,
    poBookingSendRebillAuth,
    poCorporateVehicle,
    poFMLTransnet,
    poHeader,
    poOfficeAdmin,
    poPanelBeaters,
    poRebillApprovalByCEO,
    poRebillApprovalByGM,
    poRebillApprovalByTech,
    poRebillApprovalByOps,
    poRepairs,
    poService,
    poSTRTransnetCorporate,
    poSubscriptions,
    poTowing,
    poTyres,
    progressBar,
    queuedTaskCount,
    queuedTasks,
    quoteHeader,
    quoteHeaderSTR,
    quoteSelectGrid,
    readComponent,
    reassignTask,
    recaptcha,
    redirect,
    request,
    //router: routerReducer,
    searchComponent,
    signedIn,
    signUp,
    snackbarMessage,
    status,
    support,
    teamManagement,
    teamOptions,
    teams,
    tiles,
    tracker,
    upload,
    uploadAccessory,
    user,
    userOptions,
    vehicletransfer,
    visualComponent,
    workflowTaskManager,
    workflowInput,
    workflowPOAccessories,
    workflowPOAccessoriesConfirm,
    workflowPOApprovalByCEO,
    workflowPOApprovalByCOO,
    workflowPOApprovalByGM,
    workflowPOContractActivation,
    workflowPOContractActivationChecklist,
    workflowPOContractActivationVAF,
    workflowPODelivery,
    workflowPOGRV,
    workflowPOHeader,
    workflowPOReceiptAccessories,
    workflowPOReceiptVehicle,
    workflowPOSupplier,
    workflowPOUploadQuote,
    workflowQueueTasks,
    workflowQueueTaskDocuments,
    workflowQuoteHeader,
    workflowQuoteItemGrid,
    workflows,
    workflowTask,
    workflowTaskAction,
    workflowTaskApproval,
    workflowTaskInput,
    workflowTaskStepComponentMultipleCreator,
    workflowTaskStepComponentMultipleSelector,
    workflowTaskStepComponentSingleCreator,
    workflowTaskStepComponentSingleSelector,
    workflowTaskStepQuoteSingleSelector,
    workflowTimeline,
    workflowTriggers,
    workflowUploadPID,
    workflowUploadPOD,
    workflowVehicleDeliveryConfirm,
    workflowView,
    workflowViewFMLQuoteConfirm,
    workflowViewFMLQuoteSummary,
    strItemGrid,
    poCloseBooking,
    poCloseRebillBooking,
    createMMQuote,
    billingRunView,
    billinrunstart,
    billingrefresh,
    billingpreview,
    billingsendinvoices,
    billinginterest,
    billingweeklycis,
    billingodofile,
    billingoocpfile,
    activateMMContract,
    strContractActivation,
    disposalcomponentview,
    workflowVehicleHistory,
    workflowDocuments,
    workflowDisposal,
    billingmoduleview,
    broadcast,
    costcentrechangecomponentview,
    registrationchangecomponentview,
    quoteEdit,
    workflowPOApprovalByGM,
    workflowPOApprovalByCEO,
    purchaseorderview,
    cancelledTasksApproval,
    workflowDefleetView,
    defleetVehicleInfo,
    defleetGenerateChecksheetView,
    defleetCaptureQuote,
    defleetUploadQuotes,
    defleetUploadQuotesConfirm,
    defleetQuoteApproval,
    defleetQuoteApprovalGM,
    defleetQuoteApprovalCEO,
    defleetUploadChecksheet,
    defleetSendRebill,
    defleetAcceptRebill,
    defleetSendRebillAuth,
    defleetCloseBooking,
    defleetContractTermination,
    defleetTerminationRequest,
    defleetTerminationRequestApproval,
    defleetTerminationRequestApprovalGM,
    defleetTerminationRequestApprovalCEO,
    defleetRepairChecksheet,
    defleetDisposal,
    defleetDisposalApproval,
    defleetDisposalApprovalCEO,
    defleetRebillDispute,
    disposaldecisionview,
    supplierpaymentmanagement,
    workflowPaymentView,
    paymentApproval,
    defleetItems,
    supplierpaymentapprovalgm,
    defleetQuoteApprovalOPS,
    //customerinvoice,
    customertransaction,
    suppliertransaction,
    customerallocation,
    supplierallocation,
    supplierpaymentconfirmation,
    paymentApprovalTech,
    quoteItems,
    paymentApprovalOPS,
    paymentApprovalGM,
    paymentApprovalFinance,
    paymentApprovalAdmin,
    customerstatementview,
    paymentApprovalRelease,
    paymentApprovalAuthorisation,
    cancelledTasksApprovalOPS,
    paymentAllocation,
    reportScheduler
})

const rootReducer = (state, action) => {
    //todo
    // if (action.type === 'SIGN_OUT_COGNITO_USER') {
    //     state = undefined
    // }
    //cognitoActions.refreshUserAuthenticationToken()
    return appReducer(state, action)
}

export default rootReducer