import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-grid-system';
import ReactTable from "react-table";
import moment from 'moment';
import _ from 'lodash';
import {
    Box,
    Button,
    Card as C,
    Dialog,
    Grid,
    Icon as MaterialIcon,
    Tab,
    Table,
    Tabs,
    TextField
} from '@mui/material';
import { LoopRounded, GetAppOutlined } from '@mui/icons-material';
import * as actions from './actions';
import Create from "./create";
import List from "./list";
import View from "./view";
import * as styles from './styles';
import Icon from '../Icon/component';
import Header from '../WorkflowTaskInput/header';
import AutoComplete from '../../controls/autocomplete';
import Card from '../../controls/card';
import MultiSelect from '../../controls/multiselect';
import "react-table/react-table.css";

class ReportScheduler extends Component {
    componentDidMount() {

    }

    getLayout() {
        return (
            <div>
                <Tabs
                    initialSelectedIndex={0}
                    value={this.props.state.tab}
                    inkBarStyle={{
                        height: 4
                    }}
                    onChange={(event, value) => {
                        if (value === 1) {
                            this.props.actions.loadAddData();
                        }
                        this.props.actions.changeTabView(value);
                    }}
                >
                    <Tab style={{minWidth:"50%"}} label={"Data"} />
                    <Tab style={{minWidth:"50%"}} label={"Create"} />
                </Tabs>
                <br/>
                <div>
                    {this.props.state.tab === 0 && <Box><List /></Box>}
                    {this.props.state.tab === 1 && <Box><Create /></Box>}
                    {this.props.state.tab === 2 && <Box><View /></Box>}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <br />
                <Header title={'Report Scheduler'} description={'Create a report scheduler'} />
                <br />
                {this.getLayout()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.reportScheduler,
            user: {...state.user}
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportScheduler)