import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import Iframe from 'react-iframe'
import 'react-quill/dist/quill.snow.css'

import { Container, Row, Col } from 'react-grid-system'

import { Table } from "@mui/material";

import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import TextField from '@mui/material/TextField'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import ViewCustomer from '../../controls/view_customer'

import PdfViewer from '../PDF/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowPOReceiptAccessoriesConfirm extends Component {
    componentDidMount() {
        this.props.actions.setupWorkflowTaskInput(this.props.state, 'purchaseorder', 'tsk_fml_quote_receipt_accessory')
    }

   getContract(data) {
        return <Card 
                title={window.t.en('Quote Confirmation')}
                subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
                content={
                    <Fields nocard={true}
                        fields={[
                        {size: 4, label: 'Contract Type:', value: data.contractname},
                        {size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory},
                        {size: 4, label: 'Monthly Distance:', value: data.distancepermonth},
                        {size: 4, label: 'Contract Period:', value: data.contractperiod},
                        {size: 4, label: 'Quote For:', value: data.quotefor},
                        {size: 4, label: 'RFQ Date:', value: data.quotedate},
                        {size: 4, label: 'Vehicle Color:', value: data.color},
                        {size: 4, label: 'Vehicle Location:', value: data.location},
                        {size: 4, label: 'Special Vehicle:', value: data.specialistvehicle},
                        {size: 4, label: 'Vehicle Group:', value: data.vehiclegroup},
                        {size: 4, label: 'Operating Terrain:', value: data.terrain},
                        {size: 4, label: 'Vehicle Usage:', value: data.usage},
                        {size: 4, label: 'Comments:', value: data.comments},
                    ]}
                />
                }
            />
    }

    getCustomer(customer) {
        let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        return <Card 
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                    <ViewCustomer id={customer.customer_id} nocard={true} min={true} />
                    // <Fields nocard={true}
                    //     fields={[
                    //         { size: 4, label: 'Customer Name:', value: customer.customername },
                    //         { size: 4, label: 'Cost Centre:', value: customer.costcentre },
                    //         { size: 4, label: 'Account Number:', value: customer.accountnumber },
                    //         { size: 4, label: 'Contact Person Name:', value: contact.fullname },
                    //         { size: 4, label: 'Contact Number:', value: contact.mobile },
                    //         { size: 4, label: 'Contact Email:', value: contact.email },
                    //     ]}
                    // />
                }
            />
    }

    getSupplier(data) {
        return <Card 
                title={window.t.en('Supplier Details')}
                subtitle={window.t.en('Selected Vehicle Supplier ')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Supplier Name:', value: data.suppliername},
                            {size: 4, label: 'Trading Name:', value: data.tradingnamename},
                            {size: 4, label: 'Account Number:', value: data.accountnumber},
                            {size: 4, label: 'VAT Number:', value: data.vatnumber},
                            {size: 4, label: 'Contact Person:', value: data.contactperson},
                            {size: 4, label: 'Contact Email:', value: data.contactemail},
                            {size: 4, label: 'Contact Number:', value: data.contactnumber},
                        ]}
                    />
                }
            />
    }

    getVehicle(data) {
        return <Card 
                title={window.t.en('Accepted Quote ' + data.quotenumber)}
                subtitle={window.t.en('Customer Quote Accepted')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Quote Number:', value: data.quotenumber},
                            {size: 4, label: 'System PO Number:', value: data.ponumber},

                            {size: 12, header: 'Vehicle Details'},
                            {size: 4, label: 'Vehicle Make:', value: data.mmcode.make},
                            {size: 4, label: 'Vehicle Model:', value: data.mmcode.cdescription},

                            {size: 4, label: 'MM Code:', value: data.mmcode.mmcode},
                            {size: 4, label: 'Transmission:', value: data.mmcode.manualauto},

                            {size: 4, label: 'Introduction Year:', value: data.mmcode.regyear},
                            {size: 4, label: 'Body Type:', value: data.mmcode.bodytype},

                            {size: 4, label: 'Contract Type:', value: data.contractname},
                            {size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory},

                            {size: 4, label: 'Distance Per Month:', value: data.distancepermonth},
                            {size: 4, label: 'Contract Period:', value: data.contractperiod},

                           // {size: 4, label: 'Rate Card:', value: data.ratecard},
                            {size: 4, label: 'Delivery Period:', value: data.deliveryperiod},
                            
                            {size: 12, header: 'Rental Breakdown'},
                            {size: 4, label: 'Retail Price:', value: data.retailprice},
                            {size: 4, label: 'Discount:', value: data.discount},

                            {size: 4, label: 'Interest Rate:', value: data.primerate},
                            {size: 4, label: 'Vehicle Price:', value: data.vehicleprice},

                            {size: 12, header: 'Monthly Rental'},
                            {size: 4, label: 'Rental Excl:', value: data.monthly_excl},

                            {size: 4, label: 'Vat:', value: data.monthly_vat},
                            {size: 4, label: 'Rental Incl:', value: data.monthly_incl},
                        ]}
                    />
                }
            />
    }
    getAccessories(accessory) {
        return <Card 
                title={window.t.en('Receipt Accessories')}
                subtitle={window.t.en('Customer Quote Accepted')}
                content={
                <Container style={{ margin: 0 }} className="custom-container">
                    {
                        accessory.map((x, i) => {
                            return <div>
                                <Row>
                                    <Col xl={6} style={styles.selectedFieldBorderL}>

                                        {'Sent Document Purchase Order Number: ' + x.ponumber}
                                    </Col>
                                    <Col xl={6}>
                                        {'Upload Receipt Purchase Order Number: ' + x.ponumber}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} style={styles.selectedFieldBorderL}>
                                        {
                                            x.filename
                                            ? <PdfViewer
                                                key={'uplaod_customer_viewer'}
                                                prop={'accessory_po_' + x.ponumber}
                                                documents={[{filename:  x.filename, filetype: x.filename}]}
                                                multiple={true}
                                                height={'500px'}
                                                preview={'preview'}
                                            />
                                            : 'Loading...'
                                        }
                                    </Col>
                                    <Col xl={6}>
                                        {
                                            this.props.state.documents.filter(p => p.ponumber === x.ponumber).length
                                            ? <PdfViewer
                                                key={'accessory_inv_' + x.ponumber}
                                                prop={'accessory_inv_' + x.ponumber}
                                                documents={this.props.state.documents.filter(p => p.ponumber === x.ponumber)}
                                                multiple={true}                                                
                                                inx={i + 100}
                                                height={'500px'}
                                                preview={'preview'}
                                            />
                                            : 'Loading...'
                                        }
                                    </Col>
                                </Row>
                            </div>
                        })
                    }
                </Container>
                }
            />
    }

    getVehicleForm() {
        return <Card 
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Customer Quote Accepted')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'VIN Number:', value: this.props.state.vinnumber},
                            {size: 4, label: 'Engine Number:', value: this.props.state.enginenumber},
                            {size: 4, label: 'Registration Number:', value: this.props.state.registrationnumber},
                            {size: 4, label: 'Opening Odometer:', value: this.props.state.openingodometer},
                            {size: 4, label: 'Registration Date:', value: this.props.state.registrationdate},
                            {size: 4, label: 'License Expiry Date:', value: this.props.state.licenceexpirydate},
                        ]}
                    />
                }
            />
    }

    getView(data) {
        return <div>
                {this.getContract(data.vehicle)}
                {this.getCustomer(data.customer)}
                {this.getSupplier(data.supplier)}
                {this.getVehicle(data.vehicle)}
                {this.getAccessories(data.pos)}
                {this.getVehicleForm()}
        </div>
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log('WorkflowPOReceiptAccessoriesConfirm workflow_queue_data', workflow_queue_data)
        let data = null
        try {
            data = this.props.workflow_queue_data.context_data.data.data.data[0]
        }
        catch (err) { }
        
        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowPOReceiptAccessories,
            ...state.uploadAccessory
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOReceiptAccessoriesConfirm)