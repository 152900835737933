
import * as colours from '@mui/material/colors'
import { transparent } from '@mui/material/colors';
export const right = {
    width: '100%',
    textAlign: 'right'
}

export const left = {
    width: '100%',
    textAlign: 'left'
}

export const verticalMiddle = {
    verticalAlign: 'middle'
}

export const hidden = {
    display: 'none'
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 2,
    verticalAlign: 'left'
}


export const headerContainer = {
    width: '100%',
    display: 'flex'
}

export const headerIcon = {
    width: 40,
    height: 40,
    fontSize: 40,
    margin: '0 auto'
}

export const headerTitle = {
    background: 'white',
    //opacity: 0.8,
    fontWeight: 'bold',
    fontSize: '34',
    padding: 5,
    marginRight: 20
}

export const headerDescription = {
    background: 'white',
    //opacity: 0.8,
    //color: 'white',
    width: '100%',
    fontSize: '34',
    padding: 5,
    borderRight: '1px solid black'
}

export const content = {
    height: '100%'
}

export const flex = {
    display: 'flex'
}

export const toggle = {
    marginBottom: 16,
  }

  
export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldTitleBold = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10,
    fontWeight: 'bold'
}

export const fieldContent = {
    display: 'table-cell',
    width: '100%',
    verticalAlign: 'middle'
}

export const card = {
    height: '100%'
}
export const rightpanel = {
    background: '#D1E0E6',
    borderLeft: '2px solid black'
}
export const fieldContentTot = {
    display: 'table-cell',
    width: '80px'
}
export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '19px',
}
export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'right',
}
export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}
export const numericFields = {
    textAlign: 'right',
}
export const whitediv = {
    backgroundColor: '#fff',
    padding: 2,
    verticalAlign: 'left'
}
//import Theme from '../../theme'

export const gridOptions = {

}

export const highlight = colours.red[200]

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const action = {
    fontSize: '15px',
    color: colours.red[500]
}

export const actionHoverColor = {
    color: colours.red[500]
}

export const gridOptionsIcon = {

}
export const miniTextBox = {
    letterSpacing: '0px !important', 
    color: 'black !important' ,
    fontWeight: 'normal !important',
    textAlign: 'right !important', 
    flex: '100 0 auto !important',
    width: '100px !important',
    height:'10px !important', 
    verticalAlign: 'middle',
    fontSize: '12px',
}

export const underlineStyle = {
    color:'transparent !important'
}