export const name = ''
export const description = ''
export const documents = false
export const images = []
export const relationships = []
export const title = ''
export const kpis = []
export const dashboards = []
export const slideShows = []
export const view = 'view'
export const grid = ''
export const grids = []
export const loading = false
export const relatedMenuIsVisible = true