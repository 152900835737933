import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent, CardActions } from '@mui/material'

import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
//import prettyFileIcons from 'pretty-file-icons'
import ReactTable from "react-table"
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDisposalImport extends Component {
    componentDidMount() {
        this.props.actions.resetImport()
    }

    getBulkCellValue(row, inx) {
        return <label style={styles.fullWidth}>{this.props.state.import_rows[row.index][inx]}</label>
    }

    getBulkColumns() {
        let header = this.props.state.import_rows[0]
        let columns = header.map((x, i) => {
            return {
                meta: x,
                Header: x,
                accessor: x,
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <input
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                            width: '100%',
                            height: 25,
                            border: '1px solid #E6E6E6',
                            borderRadius: 25
                        }}
                    />
                ),
                width: x.width ? x.width : undefined,
                headerStyle: {
                    fontSize: '13px',
                    fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                    //backgroundColor: x.highlight ? styles.highlight : '',
                },
                style: {
                    verticalAlign: 'middle',
                    fontSize: 12,
                    letterSpacing: 0,
                    color: 'black',
                    fontWeight: 'normal',
                    //textAlign: x.align
                },
                Cell: (row) => (
                    this.getBulkCellValue(row, i)
                )
            }
        })
        return columns
    }

    getRowGrid() {
        let _data = this.props.state.import_rows
        return <ReactTable
            key={'grid_booking_quote'}
            data={_data}
            columns={this.getBulkColumns()}
            filterable={true}
            loading={this.props.state.loading}
            manual
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.getRelatedRowItem(rowInfo, this.data)
                    },
                }
            }}
            onFetchData={
                (state) => {
                    //this.props.actions.getRestructureGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            minRows={1}
            showPagination={false}
            showPaginationBottom={false}
            style={{
                height: '600px'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }

    getAcceptedFiles() {
        return this.props.state.import_rows.length
            ? <Card initiallyExpanded={true}>
                <CardHeader
                    avatar={<Icon iclass={'material-icons'} iname={'done'} />}
                    title={'Imported File Data'}
                    subtitle={'Please ensure that the data import match the one the file you imported before you action.'}
                    actAsExpander={true}
                    showExpandableButton={true}
                />
                <CardContent expandable={true}>
                    <div style={{ verticalAlign: 'middle' }}>
                        {this.getRowGrid()}
                    </div>
                </CardContent>
                <CardHeader
                    avatar={<Icon iclass={'material-icons'} iname={'done'} />}
                    title={this.props.state.template ? 'Ready To Upload: ' + this.props.state.template : 'Select Template'}
                    subtitle={this.props.state.template ? 'Data will be loaded once you click load data': 'You need to select a template before your can proceed'}
                    actAsExpander={true}
                    showExpandableButton={true}
                />
                <CardActions style={{ width: '100%', textAlign: 'right' }}>
                    
                    <Button variant='contained' className='global_button'
                        label={'Upload File Data'}
                        disabled={this.props.state.template ? false : true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.uploadFileData({
                                template: this.props.state.template,
                                import_rows: this.props.state.import_rows,
                                import_bidder: this.props.state.import_bidder
                            })
                        }}
                    >{'Upload File Data'}</Button>
                </CardActions>
            </Card >
            : <div />
    }

    getRejectedFiles() {
        return !this.props.state.busy && !this.props.state.rows.length
            ? <Card initiallyExpanded={true}>
                <CardHeader
                    avatar={<Icon iclass={'material-icons'} iname={'clear'} />}
                    title={'Import Failed'}
                    subtitle={'Please review the reason to why the file failed to import and action accordinly!'}
                    actAsExpander={true}
                    showExpandableButton={true}
                />
                <CardContent expandable={true}>
                    {/* {this.props.state[this.props.state.component.name].rejected.map(x => {
                        return <div>
                            <Icon istyle={{ color: red500, fontSize: '16px' }} iclass={'material-icons'} iname={'clear'} />
                            &nbsp;
                            <img src={this.getFileTypeIcon(x.document.name)} style={{ width: '16px', cursor: 'pointer' }} />{x.document.name}
                        </div>
                    })} */}
                </CardContent>
                <CardActions style={{ width: '100%', textAlign: 'right' }}>
                    <Button variant='contained' className='global_button'
                        label={'Upload Again'}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.uploadAgain()
                        }}
                        >{'Upload Again'}</Button>
                </CardActions>
            </Card >
            : <div />
    }
    getFileTypeIcon(file) {
        // const type = prettyFileIcons.getIcon(file)
        // const src = require('../../../assets/img/png/icons/' + type + '.png')

        return ""
    }

    getImport() {
        return <div>
        <p>
        {'Bidders File Import'}
        </p> 
            <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Select Import Template:'}</div>
                                <div style={styles.fieldContent}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    style={styles.inputWidth}
                                    key={'import_type_select'}
                                    value={this.props.state.template}
                                    label={'Select Import Template'}
                                    onChange={(event, index, key) => {
                                        //this.props.actions.setSelectedTemplate(key)
                                        this.props.actions.setSetinputField({prop: 'template', value: event.target.value})
                                    }}>
                                    {
                                        this.props.state.template_list.sort((x, y) => x.value - y.key).map(x => {
                                            return <MenuItem key={'import_type_select_item_' + x.key} value={x.text} primaryText={x.text}>{x.text}</MenuItem>
                                        })
                                    }
                                </Select>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        this.props.state.template === 'Bidder Offers'
                        ? <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Select Bidder:'}</div>
                                    <div style={styles.fieldContent}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        style={styles.inputWidth}
                                        key={'import_bidder_select'}
                                        value={this.props.state.import_bidder}
                                        label={'Select Bidder'}
                                        onChange={(event, index, key) => {
                                            this.props.actions.setSetinputField({prop: 'import_bidder', value: event.target.value})
                                        }}>
                                        {
                                            this.props.state.bidders_list.sort((x, y) => x.value - y.key).map(x => {
                                                return <MenuItem key={'import_bidder_select_item_' + x.key} value={x.text} primaryText={x.text}>{x.text}</MenuItem>
                                            })
                                        }
                                    </Select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        : ''
                    }
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    {
                        <Row>
                                <Col xl={12}>
                                    <Dropzone
                                        accept={'.xls,.xlsx'}
                                        style={styles.dropZone}
                                        onDrop={(files) => {
                                            //this.props.actions.notifyUser('Checking documents...')
                                        }}
                                        onDropAccepted={(files) => {
                                            this.props.actions.setAcceptedDocuments(files)
                                        }}
                                        onDropRejected={(files) => {
                                            //this.props.actions.notifyUser('(' + files.length + ') files were rejected.')
                                            //this.props.actions.setRejectedDocuments(files, this.props.state.component.name)
                                        }}
                                    >
                                        <p style={styles.watermark}>
                                            Drag and drop the files you would like to upload inside this box. please ensure that your are uploading the correct file format:
                                            <br />
                                            .xls and .xlsx
                                        </p>
                                    </Dropzone>
                                </Col>
                            </Row>
                            
                    }
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {this.props.state.import_rows.length ? this.getAcceptedFiles() : ''}
                        </Col>
                    </Row>
                </Container>
                </div>
    }

    render() {
        return this.getImport()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalImport)