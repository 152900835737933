
export const fieldContainer = {
    display: 'table',
    paddingTop: 10
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldTitleLeft = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}
