import * as types from './types'
import * as _ from 'lodash'

export const getPersonStructure = (customer_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PERSON_HIERARCHY,
            payload: {
                type: 'hierachy',
                value: customer_id ? [customer_id] : getState().user.customers
            }
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PERSON_HIERARCHY_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const setPersonStructure = (payload) => {
    return (dispatch, getState) => {
        //console.log('setUserTeams', payload)
        dispatch({
            type: types.SET_PERSON_HIERARCHY,
            payload: payload.hierachy
        })
        dispatch(setInputValue('loading', false))
    }
}
export const toggleCollapse = (person_id) => {
    return (dispatch, getState) => {
        let data = getState().hierarchyStaff.tree
        let _data = data.map(x => {
            return x.person_id === person_id ? {...x, collapse: !x.collapse} : x
        })
        //console.log('toggleCollapse customer_id',person_id)
        //console.log('toggleCollapse _data',_data)
        dispatch({
            type: types.SET_PERSON_HIERARCHY,
            payload: _data
        })
    }
}

export const contextMenu = (customer_id, anchorEl) => {
    return (dispatch, getState) => {
        let data = getState().hierarchyStaff.tree
        let _data = data.map(x => {
            return x.customer_id === customer_id ? {...x, contextmenu: !x.contextmenu} : {...x, contextmenu: false}
        })
        //console.log('toggleCollapse customer_id',customer_id)
        //console.log('toggleCollapse _data',_data)
        dispatch({
            type: types.SET_PERSON_HIERARCHY,
            payload: _data
        })
        dispatch({
            type: types.SET_PERSON_CONTEXT_MENU_ANCHOREL,
            payload: anchorEl
        })
    }
}
export const toggleDetailWin = (customer_id) => {
    return (dispatch, getState) => {
        let data = getState().hierarchy.tree
        let _data = data.map(x => {
            return x.customer_id === customer_id ? {...x, detail_win: !x.detail_win} : {...x, detail_win: false}
        })
        //console.log('toggleCollapse customer_id',customer_id)
        //console.log('toggleCollapse _data',_data)
        dispatch({
            type: types.SET_PERSON_HIERARCHY,
            payload: _data
        })
    }
}
