import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'


import TextField from '../../controls/textField'
import DateControl from '../../controls/dateControl'
import * as actions from './actions'
import * as styles from './styles'

import Icon from '../Icon/component'
import Iframe from 'react-iframe'
import 'react-quill/dist/quill.snow.css'

function findUnique(arr, predicate) {
    var found = {};
    arr.forEach(d => {
        found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]);
}

class UploadAccessoryComponent extends Component {

    findUnique(arr, predicate) {
        var found = {};
        arr.forEach(d => {
            found[predicate(d)] = d;
        });
        return Object.keys(found).map(key => found[key]);
    }

    componentDidMount() {
        this.props.actions.cleareDocuments()
        try {

            if (this.props.documents) {
                let documents = this.props.state.documents.length ? this.props.documents.concat(this.props.state.documents) : this.props.documents

                const _documents = findUnique(documents, d => d.fileinx)

                this.props.actions.preLoadDocuments(_documents)
                this.props.actions.getDocumetnsPreview(_documents)
            }
        }
        catch (err) {
        }
    }

    getUploaderInx(inx) {
        return this.props.header
            ? <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
                <CardHeader
                    title={window.t.en('Documents')}
                    subtitle={window.t.en('Document upload')}
                    actAsExpander={false}
                />
                <CardContent expandable={false}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                < Button className={"global_button"}
                                    secondary={true}
                                    label={"Add "}
                                    icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                                    onClick={(event) => {
                                        this.props.actions.addBlankUpload(inx, this.props.id)
                                    }}
                                    ><Icon iclass={'material-icons'} iname={'file_upload'} /> Add</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        {
                            this.props.state.documents
                                .filter(x => x.inx === inx)
                                .map((x, i) => {

                                    return x
                                        ? <div>
                                            <Row>
                                                <Col xl={12}>
                                                    {
                                                        x.preview
                                                            ? <Iframe url={x.preview}
                                                                width="100%"
                                                                height="200px"
                                                                id={'receipt_' + x.fileinx}
                                                                className="myClassname"
                                                                display="initial"
                                                                position="relative"
                                                                allowFullScreen />
                                                            : <Dropzone
                                                                accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx.'}
                                                                style={styles.dropZone}
                                                                hintText={'Upload'}
                                                                onDrop={(files) => {
                                                                    //component.actions.toggleDropzoneVisibility()
                                                                }}
                                                                onDropAccepted={(files) => {
                                                                    this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.id, this.props.prefix)
                                                                }}
                                                                onDropRejected={(files) => {
                                                                    this.props.actions.resetDocuments(x.fileinx)
                                                                }}>
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                                            </Dropzone>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={12}>
                                                    <br />
                                                </Col>
                                            </Row>
                                            {
                                                this.props.view
                                                    ? ''
                                                    : <div>
                                                        <Row>
                                                            <Col xl={6} style={{paddingTop:'15px'}}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    id={'txtinvoicenumber'}
                                                                    label={'Enter Invoice Number'}
                                                                    fullWidth={true}
                                                                    value={x.invoicenumber}
                                                                    onChange={(event, value) => {
                                                                        this.props.actions.setUploadValue('invoicenumber', x.fileinx, event.target.value)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col xl={6} >
                                                                <div style={{marginTop:'px'}}>
                                                                <DateControl
                                                                    id={'txtinvoicedate'}
                                                                    key={'txtinvoicedate'}
                                                                    label={'Select Invoice Date'}
                                                                    name={'txtinvoicedate'}
                                                                    value={x.invoicedate}
                                                                    onSelect={(args) => {
                                                                        this.props.actions.setUploadValue('invoicedate', x.fileinx, args)
                                                                    }}
                                                                    type={'date'}
                                                                />
                                                                </div>
                                                            </Col>
                                                            
                                                            <Col xl={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    id={'txtfiledescription'}
                                                                    label={'Enter File Description'}
                                                                    fullWidth={true}
                                                                    value={x.filedescription}
                                                                    onChange={(event, value) => {
                                                                        this.props.actions.setUploadValue('filedescription', x.fileinx, event.target.value)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col xl={6}>
                                                               < Button className={"global_button"} variant="contained"
                                                                    primary={true}
                                                                    label="Remove"
                                                                    icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                                                    onClick={(event) => {
                                                                        this.props.actions.resetDocuments(x.fileinx)
                                                                    }}
                                                                    ><Icon iclass={'material-icons'} iname={'delete_forever'} /> Remove</Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                            }
                                            <Row>
                                                <Col xl={12}>
                                                    <br />
                                                </Col>
                                            </Row>
                                        </div>
                                        : ''
                                })
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            : <Container style={{ margin: 0 }} className="custom-container">
                {
                    this.props.view
                        ? ''
                        : <Row>
                            <Col xl={12}>
                                < Button className={"global_button"}
                                    secondary={true}
                                    label={"Add "}
                                    icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                                    onClick={(event) => {
                                        this.props.actions.addBlankUpload(inx, this.props.id)
                                    }}
                                    ><Icon iclass={'material-icons'} iname={'file_upload'} /> Add</Button>
                            </Col>
                        </Row>
                }
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                {
                    this.props.state.documents
                        ? this.props.state.documents
                            .filter(x => x.inx === inx)
                            .map((x, i) => {
                                return x
                                    ? <div>
                                        <Row>
                                            <Col xl={12}>
                                                {
                                                    x.preview
                                                        ? <Iframe url={x.preview}
                                                            width="100%"
                                                            height="200px"
                                                            id={'receipt_' + x.filename}
                                                            className="myClassname"
                                                            display="initial"
                                                            position="relative"
                                                            allowFullScreen />
                                                        : <Dropzone
                                                            accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx.'}
                                                            style={styles.dropZone}
                                                            hintText={'Upload'}
                                                            onDrop={(files) => {
                                                                //component.actions.toggleDropzoneVisibility()
                                                            }}
                                                            onDropAccepted={(files) => {
                                                                this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.id, this.props.prefix)

                                                                //this.props.actions.setupWorkflowSubmitInput(this.props.id)
                                                                //this.props.actions.submitWorkflowTaskInput()
                                                            }}
                                                            onDropRejected={(files) => {
                                                                this.props.actions.resetDocuments(x.fileinx)
                                                            }}>
                                                            <p style={styles.watermark}>
                                                                Drag and drop file here, or click to select file to upload.
                                                            </p>
                                                        </Dropzone>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        {
                                            this.props.view
                                                ? ''
                                                : <div>
                                                    <Row>
                                                        <Col xl={6} style={{marginTop:'15px'}}>
                                                            <TextField
                                                                variant="outlined"
                                                                id={'txtinvoicenumber'}
                                                                label={'Enter Invoice Number'}
                                                                fullWidth={true}
                                                                value={x.invoicenumber}
                                                                onChange={(event, value) => {
                                                                    this.props.actions.setUploadValue('invoicenumber', x.fileinx, event.target.value)
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xl={6}>
                                                            <DateControl
                                                                id={'txtinvoicedate'}
                                                                key={'txtinvoicedate'}
                                                                label={'Select Invoice Date'}
                                                                name={'txtinvoicedate'}
                                                                value={x.invoicedate}
                                                                onSelect={(args) => {
                                                                    this.props.actions.setUploadValue('invoicedate', x.fileinx, args)
                                                                }}
                                                                type={'date'}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={12}>
                                                            <br />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={6}>
                                                            <TextField
                                                                variant="outlined"
                                                                id={'txtfiledescription'}
                                                                label={'Enter File Description'}
                                                                fullWidth={true}
                                                                value={x.filedescription}
                                                                onChange={(event, value) => {
                                                                    this.props.actions.setUploadValue('filedescription', x.fileinx, event.target.value)
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xl={6}>
                                                           < Button className={"global_button"} variant="contained"
                                                                primary={true}
                                                                label="Remove"
                                                                icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                                                onClick={(event) => {
                                                                    this.props.actions.resetDocuments(x.fileinx)
                                                                }}
                                                                ><Icon iclass={'material-icons'} iname={'delete_forever'} /> Remove</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                        }
                                        <Row>
                                            <Col xl={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                    </div>
                                    : ''
                            })
                        : ''
                }
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>
    }

    getUploader() {
        return this.props.header
            ? <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
                <CardHeader
                    title={window.t.en('Documents')}
                    subtitle={window.t.en('Document upload')}
                    actAsExpander={false}
                />
                <CardContent expandable={false}>
                    <Container style={{ margin: 0 }} className="custom-container">     <Row>
                        <Col xl={12}>
                            < Button className={"global_button"}
                                secondary={true}
                                label={"Add"}
                                icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                                onClick={(event) => {
                                    this.props.actions.addBlankUpload()
                                }}
                                ><Icon iclass={'material-icons'} iname={'file_upload'} /> Add</Button>
                        </Col>
                    </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        {
                            this.props.state.documents.map((x, i) => {

                                return x
                                    ? <div><Row>
                                        <Col xl={12}>
                                            {
                                                x.preview
                                                    ? <Iframe url={x.preview}
                                                        width="100%"
                                                        height="100px"
                                                        id={'receipt_' + x.filename}
                                                        className="myClassname"
                                                        display="initial"
                                                        position="relative"
                                                        allowFullScreen />
                                                    : <Dropzone
                                                        accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx'}
                                                        style={styles.dropZone}
                                                        hintText={'Upload'}
                                                        onDrop={(files) => {
                                                            //component.actions.toggleDropzoneVisibility()
                                                        }}
                                                        onDropAccepted={(files) => {
                                                            this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                                        }}
                                                        onDropRejected={(files) => {
                                                            this.props.actions.resetDocuments(x.fileinx)
                                                        }}
                                                    >
                                                        <p style={styles.watermark}>
                                                            Drag and drop file here, or click to select file to upload.
                                                    </p>
                                                    </Dropzone>
                                            }
                                        </Col>

                                    </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={8}>

                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'File Name:'}</div>
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                    variant="outlined"
                                                            id={'txtDescirption'}
                                                            hintText={''}
                                                            fullWidth={true}
                                                            value={this.props.state.description}
                                                            onChange={(event, value) => {
                                                                this.props.actions.setValue(x.fileinx, event.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={4}>
                                               < Button className={"global_button"} variant="contained"
                                                    primary={true}
                                                    label="Remove"
                                                    icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                                    onClick={(event) => {
                                                        this.props.actions.resetDocuments(x.fileinx)
                                                    }}
                                                    ><Icon iclass={'material-icons'} iname={'delete_forever'} /> Remove</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                    </div>
                                    : ''
                            })
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            : <Container style={{ margin: 0 }} className="custom-container">     <Row>
                <Col xl={12}>
                    < Button className={"global_button"}
                        secondary={true}
                        label={"Add"}
                        icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                        onClick={(event) => {
                            this.props.actions.addBlankUpload()
                        }}
                        ><Icon iclass={'material-icons'} iname={'file_upload'} /> Add</Button>
                </Col>
            </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                {
                    this.props.state.documents.map((x, i) => {
                        return x
                            ? <div>
                                <Row>
                                    <Col xl={12}>
                                        {
                                            x.preview
                                                ? <Iframe url={x.preview}
                                                    width="100%"
                                                    height="100px"
                                                    id={'receipt_' + x.filename}
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : <Dropzone
                                                    accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx.'}
                                                    style={styles.dropZone}
                                                    hintText={'Upload'}
                                                    onDrop={(files) => {
                                                        //component.actions.toggleDropzoneVisibility()
                                                    }}
                                                    onDropAccepted={(files) => {
                                                        this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                                    }}
                                                    onDropRejected={(files) => {
                                                        this.props.actions.resetDocuments(x.fileinx)
                                                    }}
                                                >
                                                    <p style={styles.watermark}>
                                                        Drag and drop file here, or click to select file to upload.
                                        </p>
                                                </Dropzone>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={8}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'File Name:'}</div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    variant="outlined"
                                                    id={'txtDescirption'}
                                                    hintText={''}
                                                    fullWidth={true}
                                                    value={this.props.state.description}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setValue(x.fileinx, event.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={4}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Remove"
                                            icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                            onClick={(event) => {
                                                this.props.actions.resetDocuments(x.fileinx)
                                            }}
                                            ><Icon iclass={'material-icons'} iname={'delete_forever'} /> Remove</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        <br />
                                    </Col>
                                </Row>
                            </div>
                            : ''
                    })
                }
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>
    }

    getView(inx) {
        return inx
            ? this.getUploaderInx(inx)
            : this.getUploader()
    }

    render() {
        return (
            this.getView(this.props.inx)
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.uploadAccessory,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadAccessoryComponent)