import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const opacity = {
    //opacity: 0.85
}

export const pager = {
    textAlign: 'right',
    background: 'none'
}

export const stepperContainer = {
    ////backgroundColor: '#dcd6d7',
    //opacity: 0.85
}

export const stepper = {
    width: '100%'
}

export const taskCard = {
    //backgroundColor: colours.amber100
    //backgroundColor: '#dcd6d7',
    //opacity: 0.85
}

export const confirmIcon = {
    fontSize: '34px',
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
    //opacity: 0.85
}

export const icon = {
    fontSize: '34px'
}

export const componentStatusIconValid = {
    fontSize: '34px',
    color: colours.green[500]
}

export const componentStatusIconInvalid = {
    fontSize: '34px',
    color: colours.red[500]
}

export const field = {
    fontWeight: 'bold'
}

export const cardHeader = {
    textAlign: 'left',
    color: colours.green[500]
}

export const cardHeaderProgress = {
    textAlign: 'right'
}

export const badge = {
    high: {
        top: 12,
        right: 12,
        backgroundColor: colours.red[500],
        fontSize: '25px'
    },
    medium: {
        top: 12,
        right: 12,
        backgroundColor: colours.amber[500],
        fontSize: '15px'
    },
    low: {
        top: 12,
        right: 12,
        backgroundColor: colours.green[500],
        fontSize: '10px'
    }
}

export const buttonBar = {
    background: 'none'
}

export const row = {
    fontSize: 15,
    //backgroundColor: '#dcd6d7',
}