import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, IconButton } from '@mui/material'

import Icon from '../../Icon/component'

import VisibilityIcon from '@mui/icons-material/Visibility';
import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewSupplierTransactions from '../../../controls/view_supplier_transaction'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Form from '../../../controls/form'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'
import Drawer from '../../../controls/drawer'

import Checkbox from '../../../controls/checkbox'
import CardControl from '../../../controls/card'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import * as styles from './styles'
import * as actions from './actions'

class CreditNoteReview extends Component {
    
    componentDidMount() {
        this.props.actions.resetForm()
    }

    componentWillUnmount() {
        this.props.actions.resetForm()
    }


    getPenaltiesOtherConfirm(penalties_data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Reference</th>
                                        <th>Reason</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        penalties_data.map((x,inx) => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                <td>{x.date}</td>
                                                <td>{x.reference}</td>
                                                <td>{x.reason}</td>
                                                <td>{x.amount} </td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <th colSpan={3}></th>
                                        <th style={{textAlign: 'right', paddingLeft: '20px'}}>Total: {_.sumBy(penalties_data, (d) => { return parseFloat(d.amount)})}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    } 

    getPaymentsNotCreditedConfirm(credited_data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Reference</th>
                                        <th>Reason</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        credited_data.map((x,inx) => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                <td>{x.date}</td>
                                                <td>{x.reference}</td>
                                                <td>{x.reason}</td>
                                                <td>{x.creditnote_amount}</td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <th colSpan={3}></th>
                                        <th style={{textAlign: 'right', paddingLeft: '20px'}}>Total: {_.sumBy(credited_data, (d) => { return parseFloat(d.creditnote_amount)})}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    } 

    invoiceItems(customer_transactions) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    
                <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Invoice Number</th>
                                        <th>Auth Number</th>
                                        <th>Description</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customer_transactions.map((x,inx) => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.reference}</td>
                                            <td>{x.authnumber}</td>
                                            <td>{x.description}</td>
                                            <td>{moment(x.customertransactiondate).format('DD-MM-YYYY')}</td>
                                            <td>{x.creditnote_amount}</td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <th colSpan={4}></th>
                                        <th>{_.sumBy(customer_transactions, (d) => { return parseFloat(d.creditnote_amount ? d.creditnote_amount : 0)}).toFixed(2)}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    } 

    getConfirm(data) {
        return <div>
        <ViewSupplier key={data.data.customer_id} id={data.data.customer_id} />
        <Card
            title={'Invoice Item'}
            subtitle={'Capture invoice line items'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <h5>&nbsp;&nbsp;&nbsp;{'Selected Invoice List'}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.invoiceItems(data.customer_transactions)}
                    </Col>
                </Row>
            </Container>}
        />
        <Card
            title={'Documents'}
            subtitle={'Skip if already uploaded on purchase order step '}
            content={<PdfViewer
                key={'odometer_change'}
                name={'odometer_change'}
                documents={data.documents}
                edit={false}
                multiple={false}
                workflow_queue_data={null}
            />}
        /><Card
        title={'Approval Comments '}
        subtitle={'Approval approval comments'}
        content={<Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <TextField
                        variant='outlined'
                        id={'txt_comments'}
                        label={'Enter Comments'}
                        fullWidth={true}
                        value={this.props.state.comments}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('comments', event.target.value)
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
        </Container>
        } />
        </div>
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('CreditorsPaymentReviewx', data.context_data.data.data.data[0])
        data = data.context_data.data.data.data[0].step1
        //console.log('CreditorsPaymentReviewx step1', data)
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row>
        <Col xl={12}>
            {this.getConfirm(data)}
            </Col>
        </Row>
        </Container> 
    }

    render() {
        
        this.data = null
        try {
        } catch (error) {

        }
        
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.creditNoteReview,
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteReview)