export const type = 'UPLOAD_EXCEL_FILE_DATA'
export const rows = []
export const uploading = false
export const is_late = false
export const lateMonth = null
export const lateAge = 0
export const busy = false
export const document = null
export const template = null
export const templates = []
export const selectedImportType = ''

export const importTypes = [
    {
        value: 1,
        text: 'SAP Import',
        key: 'sap',
        types: []
    },
    {
        value: 2,
        text: 'AA Roadside Import',
        key: 'aa_side',
        types: []
    },
    {
        value: 2,
        text: 'RV Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Odometer Import',
        key: 'odometer',
        types: []
    },
    {
        value: 2,
        text: 'RT57 Rate Card Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Fine Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Driver Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Vehicle Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'STR Rate Card Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Fuel Transaction Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Oil Transaction Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Toll Transaction Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Maintenance Transaction Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Fleet Card Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Type Pricing Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Maintenance Rates Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Tyres Rates Import',
        key: 'import',
        types: []
    },
    {
        value: 2,
        text: 'Accessories Import',
        key: 'import',
        types: []
    },
]