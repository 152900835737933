import React, { useState,useEffect, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { Container, Row, Col } from "react-grid-system";
import Grid from '@mui/material/Grid';


import {ReactComponent as VisibilityIcon} from '../../assets/img/svg/icons/view_grid.svg';
import {ReactComponent as EditIcon} from '../../assets/img/svg/icons/edit_grid.svg';
import {ReactComponent as DeleteForeverIcon} from '../../assets/img/svg/icons/delete_forever_grid.svg';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { createSvgIcon } from '@mui/material/utils';
import CircularProgress from '@mui/material/CircularProgress';

import Icon from '../Icon/component'
import Loading from '../Loading/component'

import AutoComplete from '../../controls/autocomplete'

import * as actions from './actions'
import * as styles from './styles'
import * as readComponentActions from '../ReadComponent/actions'
//import * as infoSheetActions from '../InfoSheet/actions'
import * as redirectActions from '../Redirect/actions'
import * as masterComponentViewActions from '../../views/MasterComponentView/actions'
import * as detailComponentViewActions from '../../views/DetailComponentView/actions'

import { Button, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { emphasize, styled, alpha } from '@mui/material/styles';

import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridClasses,
  useGridApiRef
} from '@mui/x-data-grid-premium';

import { LicenseInfo } from '@mui/x-license-pro';
import * as config from '../../config'


// const initialState = useKeepGroupedColumnsHidden({
//   apiRef,
//   initialState: {
//     ...data.initialState,
//     rowGrouping: {
//       ...data.initialState?.rowGrouping,
//       model: ['commodity'],
//     },
//     sorting: {
//       sortModel: [{ field: '__row_group_by_columns_group__', sort: 'asc' }],
//     },
//   },
// });

LicenseInfo.setLicenseKey('0dc2c32f83edb803ade140958edb564aTz04MjQzNSxFPTE3MzcxOTI2NzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');



  const CustomNoRowsOverlay = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">No Rows</Box>
    );
  }

  const CustomNoResultsOverlay = () =>{
    return(
    <Box display="flex" justifyContent="center" alignItems="center">No Results</Box>
    );
  }

  const SlideTransition = (props) => {
    return <Slide {...props} direction="up" />;
  }


const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: 'red',//theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
  ); 

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

//   const handleEvent: GridEventListener<'fetchRows'> = (
//     params,  // GridFetchRowsParams
//     event,   // MuiEvent<{}>
//     details, // GridCallbackDetails
//   ) => {
//     //console.log('fetchRows')
//   }
  
//   // Imperative subscription
//   apiRef.current.subscribeEvent(
//     'fetchRows',
//     handleEvent,
//   );
  
//   // Hook subscription (only available inside the scope of the grid)
//   useGridApiEventHandler(apiRef, 'fetchRows', handleEvent);

const GridComponent = React.memo(function GridComponent(props) { 
  const state = useState();
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [exportInProgress, setExportInProgress] = useState(false);
  const apiRef = useGridApiRef();

  useEffect(() => {
      props.actions.loadComponent('customer') //props.state.component.name)
      props.actions.getComponentGridData(props.state.component, null, null, props.filter, props.id)
  }, [])

    // const handleExportAll = useCallback(async(event, exportType) => {
    //   console.log('exportType', exportType)
    //   switch (exportType) {
    //     case 'print':
    //       apiRef.current.exportDataAsPrint();
    //       break;
    //     case 'csv':
    //       apiRef.current.exportDataAsCsv({fileName: `${state.component.title}_${moment(new Date()).format('YYYY-MM-DD HH:mm')}`});
    //       break;
    //     default:
    //       await apiRef.current.exportDataAsExcel({fileName: `${state.component.title}_${moment(new Date()).format('YYYY-MM-DD HH:mm')}`});
    //       break;
    //   }
    // }

    // }, [])

    const exportAllExcelButton = (
      <Button
      {...buttonBaseProps}
      id={`export-excel`}
      key={`export-excel`}
      disabled={exportInProgress}
      onClick={(event) => {
         props.actions.getGridExportDataExcel(state.component.name)
      }}
        >
        Export All (Excel)
        </Button>
    );

    const exportAllCSVButton = (
      <Button
      {...buttonBaseProps}
      id={`export-csv`}
      key={`export-csv`}
      disabled={exportInProgress}
      onClick={(event) => {
        handleExportAll(event, 'csv')
      }}
        >
        Export All (CSV)
        </Button>
    );

    const exportAllPrintButton = (
      <Button
      {...buttonBaseProps}
      id={`export-print`}
      key={`export-print`}
      disabled={exportInProgress}
      onClick={(event) => {
        handleExportAll(event, 'print')
      }}
        >
        Export All (Print)
        </Button>
    );
    
    const CustomToolbar = () => {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton width={'200px'} />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          {/*<GridToolbarExport csvOptions={{fileName: `${state.component.title}_${moment(new Date()).format('YYYY-MM-DD HH:mm')}`}}
          excelOptions={{fileName: `${state.component.title}_${moment(new Date()).format('YYYY-MM-DD HH:mm')}`}} />*/}
          {exportAllExcelButton} 
          {/*{exportAllCSVButton}
          {exportAllPrintButton}*/}
        </GridToolbarContainer>
      );
    }

    const handleFilterModelChange = (newFilterModel) => {
      setFilterModel(newFilterModel);
    };
    

    const getHeader = () => {
        let headers = state.component.component_field.filter(x => x.grid).sort((x, y) => x.order - y.order).map(x => {
          return x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer'
          ?  {
              field: x.name, 
              //headerName: x.title, 
              headerName: window.t.en(x.title), 
              headerClassName: 'datagrid_header',
              flex: 1,
              editable: false,
              //type: 'number',
              //align: "center",
              renderCell: (params) => {
                let _value = x.display ? params.row[x.display.split('.')[0]] ? params.row[x.display.split('.')[0]][x.display.split('.')[1]] : '' : params.row[x.name]
                switch (x.component_field_data_type.name) {
                    case 'timestamp':
                        _value =  _value ? moment(_value).format('YYYY-DD-MM hh:mm') : ''
                        break;
                }
                return _value // ? _value.replace('.',',') : 0 //<div className="rowitem">{_value}</div>;
              },
          }
          : {
              field: x.name, 
              headerName: window.t.en(x.title), 
              headerClassName: 'datagrid_header',
              flex: 1,
              editable: false,
              renderCell: (params) => {
                  let params_row = params.rowNode.type === 'group'
                      ? state.component?.grid?.data ? state.component.grid.data.filter(r => r[params.row.groupingField] == params.row.groupingKey)[0] : ''
                      : params.row
                  
                  let _value = params.row[x.name]
                  
                  // let _value = params.rowNode.type === 'group'
                  //   ? x.display ? params_row[x.display.split('.')[0]] ? params_row[x.display.split('.')[0]][x.display.split('.')[1]] : '' : params.row[x.name]
                  //   : x.display ? params.row[x.display.split('.')[0]] ? params.row[x.display.split('.')[0]][x.display.split('.')[1]] : '' : params.row[x.name]
                  
                  switch (x.component_field_data_type.name) {
                      case 'timestamp':
                          _value = _value ? moment(_value).format('YYYY-MM-DD') : ''
                          break;
                      case 'date':
                          _value = _value ? moment(_value).format('YYYY-MM-DD') : ''
                          break;
                      case 'boolean':
                          _value = _value == null ? '' : _value ? 'Yes' : 'No'
                          break;
                          default: 
                          _value = _value
                          break;

                  }
                return _value //<div className="rowitem">{_value}</div>;
              },
          }
      })
      return headers
    }

    const getData = () => {
      let data = state.component.grid.data.length ? state.component.grid.data : []
      return data
    }

    
    


    

    const getColumns = () => {
        let pkey = state.component.component_field.filter(x => x.key)[0]?.name
        return  [{
              field: 'action',
              headerName: 'Action',
              headerClassName: 'datagrid_header',
              sortable: false,
              filterable: false,
              disableExport: true,
              width: props.state.name !== 'customercontract' && props.state.name !== 'supplier' ? 150 : 60,
              renderCell: (params) => {
                return params.rowNode.type === 'leaf' ? <Stack direction="row" alignItems="center" spacing={0}>
                {
                  state.component.disableview ? '' : <Tooltip title="View" placement="bottom">
                  <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                      props.actions.setReadComponentView('view', state.component.name)
                      props.actions.changeDetailComponentTab(0)
                      props.actions.clearDetailComponentVisualisations()
                      
                      props.actions.route(
                          '/view/' + state.component.name + '/' + params.id
                      )

                  }}>
                  <VisibilityIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
                </IconButton>
                </Tooltip>
              }
              {
                state.component.disableedit ? '' : <Tooltip title="Edit" placement="bottom">
                <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                    //console.log('___paramsx', params, '/view/' + state.component.name + '/' + params.id)
                    props.actions.setReadComponentView('form', state.component.name)
                    props.actions.setGridSelectedRow(state.component, params)
                    ///props.actions.changeMasterComponentTab(1, state.component)
                    props.actions.route('/view/' + state.component.name + '/' + params.id)
                }}>
                <EditIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
              </IconButton>
              </Tooltip>
              }
              {
                
                state.component.disabledelete ? '' : <Tooltip title="Delete" placement="bottom">
                <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                  props.actions.confirmDelete({[pkey]: params.id})
                }}>
                <DeleteForeverIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
              </IconButton>
              </Tooltip>
              }
              </Stack> : ''
            }
        }].concat(getHeader()) //state.component.grid.headers)
    }

    const getGrid = () => {
        return <div style={{ height: 800, width: '100%' }}>
          <h4>{state.component.group ? state.component.group.split(',')[0] : ''}</h4>
            {
                state?.component?.grid?.headers
                ? <>
                    <Box sx={{ '& .datagrid_header': { color: '#000', backgroundColor: '#fff !important', },width: '100%' }} >
                   
                    <StripedDataGrid 
                        getRowId={(x) =>  x[state.component.name + '_id']}
                        rows={getData()} 
                        rowHeight={38}
                        loading={state.component.grid.data ? false : true}
                        columns={getColumns()}
                        slots={{ toolbar: CustomToolbar, noRowsOverlay: CustomNoRowsOverlay, noResultsOverlay: CustomNoResultsOverlay }}
                        onPaginationModelChange={(event) => {
                            props.actions.getComponentGridData(props.state.component, null, {...event,sorted: []}, null, null)
                        }}
                        pageSize={state.component.grid.paging.pageSize}
                        pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                        rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
                        paginationMode={"server"}
                        rowCount={state.component.grid.paging.rowCount}
                        pagination getRowClassName={(params) => {
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }}
                        filterModel={filterModel}
                        filterMode={'server'}
                        onFilterModelChange={(event, model) => {
                          //console.log('eventx filter',event.items)
                          props.actions.filterInProgress(event.items)
                          handleFilterModelChange(event);
                          props.actions.getComponentGridData(props.state.component, null, {...event,sorted: []}, event.items, null)
                        }}
                        onPaginationModelChange={(event) => {
                          //console.log('model filter',filterModel)
                          props.actions.getComponentGridData(props.state.component, null, {...event,sorted: []}, filterModel.items, null)
                        }}
                        autoHeight={true}
                        disableColumnFilter
                        unstable_headerFilters
                        initialState={{
                            pagination: { paginationModel: { ...state.component.grid.paging } },
                            rowGrouping: {
                                model: [state.component.group],
                            },
                            //pagination: { 
                           //   paginationModel: { pageSize: 15 } 
                           // },
                            // columns: {
                            //   columnVisibilityModel: { [state.component.group]: false, action: true},
                            // },
                            aggregation: {
                                model: { [state.component.aggregate]: 'sum' },
                            },
                        }}
                        groupingColDef={{
                         leafField: 'action'
                        }}
                        experimentalFeatures={{ aggregation: true }}
                        onExcelExportStateChange={(inProgress) => { 
                          //console.log('inProgress', inProgress)
                          if(inProgress === 'finished'){
                            setExportInProgress(false)
                          }else{
                            setExportInProgress(true)
                          }
                        }}
                        apiRef={apiRef}
                    />
                    </Box>
                    <Snackbar open={exportInProgress} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={SlideTransition}>
                      <Alert severity="info" icon={<CircularProgress size={24} />}>
                        Exporting Excel file...
                      </Alert>
                    </Snackbar>
                    <Dialog
                        open={props.state.grid.deleterecordwin}
                        onClose={(event) => {
                            event.preventDefault()
                            props.actions.confirmDelete(null)
                            //props.actions.setCardData({prop: 'secretkey_dialog', data: false})
                        }}
                        //classes={{ paper: 'shadow-lg rounded' }}
                        >
                        <div className="text-center p-5">
                        <h4 className="font-weight-bold mt-4">
                            Delete Record
                        </h4>
                        <strong className="mb-0 font-size-lg text-muted bold">
                          Are you sure, you want to delete this record.
                        </strong>
                        <div className="pt-4">
                            {
                                !props.state.grid.deletingrecord && <Button
                                variant="contained"
                                className={'global_button btn-pill mx-1'}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        props.actions.confirmDelete(null)
                                        //props.actions.setCardData({prop: 'resetpin_dialog', data: false})
                                    }}>
                                    <span className="btn-wrapper--label">Cancel</span>
                                    </Button>
                            }
                            <Button 
                                onClick={(event) => {
                                    event.preventDefault()
                                    props.actions.deleteRecord(state.component.name,props.state.grid.deleterecord)
                                    //props.actions.setCardData({prop: 'resetpin_dialog', data: false})
                                        props.actions.confirmDelete(null)
                                }}
                                variant="contained"
                                disabled={props.state.grid.deletingrecord}
                                
                                className={'global_button'}>
                                <span className="btn-wrapper--label">{props.state.grid.deletingrecord ? 'Please wait..' : 'Delete'}</span>
                            </Button>
                        </div>
                        </div> 
                  </Dialog>

                </>
                : <h2>Loading</h2> //<Loading />
            } 
        </div>
    }

    const gridRender = () => {
      state.component = props.state.component
      // //console.log('state.component.grid.data',state.component.grid.data)
      return !state.component
          ? 'Unable to locate component definition...'
          : state.component.loading
              ? <Loading message={'Loading component...'} />
              : !props.surrogate
                  ? getGrid(state.component)
                  : 'render' //getSurrogateGrid(state.component)
    }

    return (
      <>
       {gridRender()}
    </>
    )
});

const mapStateToProps = (state, ownProps) => {
    window.glyco.log(ownProps)

    return {
        state: {
            ...state.gridComponent,
            component: {
                ...state.components[
                ownProps.name
                    ? ownProps.name
                    : ownProps.component
                        ? ownProps.component.name
                        : undefined
                ]
            },
            components: {
                ...state.components
            },
            grid: {
                ...state.grid
            }
        }
    }
}    

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            ...actions,
            ...redirectActions,
            ...readComponentActions,
            ...masterComponentViewActions,
            //...infoSheetActions,
            ...detailComponentViewActions
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GridComponent)