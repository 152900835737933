import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-grid-system';
import moment from 'moment';
import _ from 'lodash';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox as MuiCheckbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Autocomplete as MuiAutoComplete
} from '@mui/material';
import * as actions from './actions';
import * as styles from './styles';
import Icon from '../Icon/component';
import Loading from '../Loading/component';
import AutoComplete from '../../controls/autocomplete';
import CheckBoxControl from '../../controls/checkbox';
import DateControl from '../../controls/dateControl';
import TreeSelect from '../../controls/SelectionControl';
import "react-table/react-table.css";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subCategory: [],
            frequencyOptions: {'day': 'Daily', 'week': 'Weekly', 'month': 'Monthly', 'year': 'Yearly'},
            dayOptions: [
                { value: "0", label: "Sunday", fieldName: "week_day_sun" },
                { value: "1", label: "Monday", fieldName: "week_day_mon" },
                { value: "2", label: "Tuesday", fieldName: "week_day_tue" },
                { value: "3", label: "Wednesday", fieldName: "week_day_wed" },
                { value: "4", label: "Thursday", fieldName: "week_day_thu" },
                { value: "5", label: "Friday", fieldName: "week_day_fri" },
                { value: "6", label: "Saturday", fieldName: "week_day_sat" }
            ],
            yearsArray: [
                {value: "1", label: "January"},
                {value: "2", label: "February"},
                {value: "3", label: "March"},
                {value: "4", label: "April"},
                {value: "5", label: "May"},
                {value: "6", label: "June"},
                {value: "7", label: "July"},
                {value: "8", label: "August"},
                {value: "9", label: "September"},
                {value: "10", label: "October"},
                {value: "11", label: "November"},
                {value: "12", label: "December"}
            ],
            monthDatesArray: Array.from(new Array(31), (val, index) => index),
            hoursArray: Array.from(new Array(24), (val, index) => index),
            minutesArray: Array.from(new Array(60), (val, index) => index),
            //navReports: this.props.state.navReports,
            navReports: this.props.state.navReports.filter(o => o.js_report === true),
            showDays: false,
            showMonths: false,
            showYears: false,
            endTypeSelectedValue: this.props.state.fields.end_type ? this.props.state.fields.end_type : '3',
            defaultFrequency: this.props.state.fields.frequency ? this.props.state.fields.frequency : 'day'
        }
    }

    componentDidMount() {
        // Edit mode
        if (this.props.state.fields.scheduler_id > 0) {
            // Set General
            if (this.props.state.reportCategories.length) {
                let selectedSubCategory = this.props.state.reportCategories.filter(x => x.reportcategory_id === this.props.state.fields.report_category_id)[0]?.subcategory;
                if (selectedSubCategory) {
                    this.setState({subCategory: selectedSubCategory});
                    //this.setState({navReports: this.props.state.navReports});
                    this.setState({navReports: this.props.state.navReports.filter(o => o.js_report === true)});
                    if (this.props.state.fields.report_subcategory_id) {
                        //this.setState({navReports: this.props.state.navReports?.filter(o => o.subcategory_id === this.props.state.fields.report_subcategory_id)});
                        this.setState({navReports: this.props.state.navReports?.filter(o => o.subcategory_id === this.props.state.fields.report_subcategory_id && o.js_report === true)});
                    }
                }
            }

            // Set Schedule Pattern
            this.setState({showDays: this.state.defaultFrequency === 'week' ? true : false});
            this.setState({showMonths: (this.state.defaultFrequency === 'month' || this.state.defaultFrequency === 'year') ? true : false});
            this.setState({showYears: this.state.defaultFrequency === 'year' ? true : false});
        }
        this.props.actions.setInputValue('frequency', this.state.defaultFrequency);
        this.props.actions.setInputValue('end_type', this.state.endTypeSelectedValue);
        this.props.actions.setInputValue('start_date', moment().format('YYYY-MM-DD'))
    }

    handleChange(event, value) {
        this.setState({endTypeSelectedValue: event.target.value});
        this.props.actions.setInputValue('end_type', event.target.value);
    }

    setNativeValue(element, value) {
        let lastValue = element.value;
        element.value = value;
        let event = new Event("input", {target: element, bubbles: true});
        let tracker = element._valueTracker;
        if (tracker) {
            tracker.setValue(lastValue);
        }
        element.dispatchEvent(event);
    }

    getFormFieldValue(field, index) {
        switch (field.component_field_display_type.name) {
            case 'treeSelect':
                return <TreeSelect
                    name={field.name}
                    title={field.hint}
                    data_id={this.props.state.licensee.data.customer_id.input}
                    onChange={
                        (value) => {
                            this.props.actions.setComponentFieldInput(field, value)
                        }
                    }
                />
            case 'autoComplete':
                return <AutoComplete
                    name={field.name}
                    title={field.hint}
                    info={{
                        show: true,
                        component: field.component_reference.name,
                        title: field.component_reference.title,
                        field: field.name,
                        value: this.props.state.licensee.data[field.name].input
                    }}
                    component_reference={field.component_reference.name}
                    data={this.props.state.licensee.data[field.name].data}
                    value={this.props.state.licensee.data[field.name].text}
                    onSelect={(args) => this.props.actions.setComponentFieldInput(field, args.value)}
                    onClick={() => this.props.actions.getComponentFieldLookupData(field, this.props.state.licensee.data[field.name].text ? this.props.state.licensee.data[field.name].text : '')}
                    onFilter={(args) => this.props.actions.getComponentFieldLookupData(field, args)}/>
            case 'datePicker':
                return <div>
                    <DateControl
                        id={this.props.state.licensee.data[field.name].key}
                        key={this.props.state.licensee.data[field.name].key}
                        name={this.props.state.licensee.data[field.name].key}
                        label={window.t.en(field.hint)}
                        field={field}
                        value={this.props.state.licensee.data[field.name].input}
                        onSelect={(args) => {
                            //console.log('args date', args)
                            this.props.actions.setComponentFieldInput(field, args)
                        }}
                        type={'date'}
                    />
                </div>
            case 'hidden':
                return <input
                    key={'component_' + this.component.name + '_' + field.name + '_' + this.props.state.licensee.data[field.name]}
                    type='hidden'
                    value={this.props.state.licensee.data[field.name].input}
                />
            case 'timePicker':
                return <DateControl
                    id={this.props.state.licensee.data[field.name].key}
                    key={this.props.state.licensee.data[field.name].key}
                    name={this.props.state.licensee.data[field.name].key}
                    label={window.t.en(field.hint)}
                    field={field}
                    value={this.props.state.licensee.data[field.name].input}
                    setComponentFieldInput={
                        this.props.actions.setComponentFieldInput
                    }
                    type={'time'}
                />
            case 'textBox':
                return <TextField
                    id={this.props.state.licensee.data[field.name].key}
                    key={this.props.state.licensee.data[field.name].key}
                    label={window.t.en(field.hint)}
                    fullWidth={true}
                    variant="outlined"
                    color="secondary"
                    error={
                        (field.component_field_data_type.name === 'integer' || field.component_field_data_type.name === 'decimal') && this.props.state.licensee.data[field.name].input
                            ? !!Number(this.props.state.licensee.data[field.name].input)
                            : this.props.state.licensee.data[field.name].validation.message ? true : false}
                    helperText={(field.component_field_data_type.name === 'integer' || field.component_field_data_type.name === 'decimal') && this.props.state.licensee.data[field.name].input ? 'Invalid numeric value' : this.props.state.licensee.data[field.name].validation.message}
                    value={this.props.state.licensee.data[field.name].input}
                    onChange={
                        (event, value) => {
                            this.props.actions.setComponentFieldInput(field, event.target.value)
                        }
                    }
                />
            case 'toggle':
                return <div>
                    <CheckBoxControl
                        key={this.props.state.licensee.data[field.name].key}
                        id={this.props.state.licensee.data[field.name].key}
                        checked={this.props.state.licensee.data[field.name].input != null ? this.props.state.licensee.data[field.name].input : false}
                        field={field}
                        // setComponentFieldInput={this.props.actions.setComponentFieldInput}
                        onCheck={(checked) => {
                            this.props.actions.setComponentFieldInput(field, checked)
                        }}
                    />
                    {
                        this.props.state.licensee.data[field.name].validation.message
                            ? [
                                <br/>,
                                <div
                                    style={{color: 'red'}}>{this.props.state.licensee.data[field.name].validation.message}</div>
                            ]
                            : ''
                    }
                </div>
            default:
                return
        }
    }

    getGeneralFormFields() {
        return <>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>Report Category</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    name={'reportcategory_id'}
                                    title={'Report Category'}
                                    data={this.props.state.reportCategories}
                                    fieldtext={'category'}
                                    fieldvalue={'reportcategory_id'}
                                    returnAllFields={true}
                                    value={
                                        this.props.state.reportCategories.length && this.props.state.fields.scheduler_id > 0
                                        ?  this.props.state.reportCategories.filter(x => x.reportcategory_id === this.props.state.fields.report_category_id)[0]?.category
                                        : ''
                                    }
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue('report_category_id', args.reportcategory_id);
                                        this.setState({subCategory: args.subcategory});
                                        const el = document.getElementById("auto_report_sub_category");
                                        this.setNativeValue(el, "");
                                    }}
                                    onClick={() => {
                                        if (this.props.state.reportCategories.length === 0 || this.props.state.navReports.length === 0) {
                                            this.props.actions.getReportData();
                                            //this.setState({navReports: this.props.state.navReports});
                                            this.setState({navReports: this.props.state.navReports.filter(o => o.js_report === true)});
                                        }
                                    }}
                                    onFilter={(args) => {}} 
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>Sub Category</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    name={'auto_report_sub_category'}
                                    title={'Sub Category'}
                                    data={this.state.subCategory}
                                    fieldtext={'sub_category'}
                                    fieldvalue={'report_subcategory_id'}
                                    returnAllFields={true}
                                    value={
                                        this.state.subCategory.length && this.props.state.fields.scheduler_id > 0
                                        ?  this.state.subCategory.filter(y => y.subcategory_id === this.props.state.fields.report_subcategory_id)[0]?.sub_category
                                        : ''
                                    }
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue('report_subcategory_id', args.subcategory_id);
                                        //this.setState({navReports: this.props.state.navReports});
                                        this.setState({navReports: this.props.state.navReports.filter(o => o.js_report === true)});
                                        if (args?.subcategory_id) {
                                            //this.setState({navReports: this.props.state.navReports?.filter(o => o.subcategory_id === args.subcategory_id)});
                                            this.setState({navReports: this.props.state.navReports?.filter(o => o.subcategory_id === args.subcategory_id && o.js_report === true)});
                                        }
                                        const el = document.getElementById("report_id");
                                        this.setNativeValue(el, "");
                                    }}
                                    onClick={() => {}}
                                    onFilter={(args) => {}} 
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>Report</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    name={'report_id'}
                                    title={'Report'}
                                    data={this.state.navReports}
                                    fieldtext={'report_name'}
                                    fieldvalue={'report_id'}
                                    returnAllFields={true}
                                    value={
                                        this.state.navReports.length && this.props.state.fields.scheduler_id > 0
                                        ?  this.state.navReports.filter(b => b.report_id === this.props.state.fields.report_id)[0]?.report_name
                                        : ''
                                    }
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue('report_id', args.report_id);
                                    }}
                                    onClick={() => {
                                        //if (this.props.state.reportCategories.length === 0 || this.props.state.navReports.length === 0) {
                                            this.props.actions.getReportData();
                                            //this.setState({navReports: this.props.state.navReports});
                                            this.setState({navReports: this.props.state.navReports.filter(o => o.js_report === true)});
                                        //}
                                    }}
                                    onFilter={(args) => {}}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>Scheduler Name</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    id={"scheduler_name"}
                                    key={"scheduler_name"}
                                    label="Scheduler Name"
                                    fullWidth={true}
                                    variant="outlined"
                                    color="secondary"
                                    /*error={
                                        (field.component_field_data_type.name === 'integer' || field.component_field_data_type.name === 'decimal') && this.props.state.licensee.data[field.name].input
                                            ? !!Number(this.props.state.licensee.data[field.name].input)
                                            : this.props.state.licensee.data[field.name].validation.message ? true : false}*/
                                    //helperText={(field.component_field_data_type.name === 'integer' || field.component_field_data_type.name === 'decimal') && this.props.state.licensee.data[field.name].input ? 'Invalid numeric value' : this.props.state.licensee.data[field.name].validation.message}
                                    value={this.props.state.fields.scheduler_name}
                                    onChange={
                                        (event, value) => {
                                            this.props.actions.setInputValue('scheduler_name', event.target.value);
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    }

    getSchedulerFormFields() {
        return <>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>Frequency</div>
                            <div style={styles.fieldContent}>
                                <RadioGroup
                                    row={true}
                                    onChange={((event, value) => {
                                        this.props.actions.setInputValue('frequency', event.target.value);
                                        this.setState({showDays: event.target.value === 'week' ? true : false});
                                        this.setState({showMonths: (event.target.value === 'month' || event.target.value === 'year') ? true : false});
                                        this.setState({showYears: event.target.value === 'year' ? true : false});
                                    })}
                                    defaultValue={this.state.defaultFrequency}
                                >
                                    {
                                        Object.keys(this.state.frequencyOptions).map((key) => {
                                            return <FormControlLabel 
                                                key={key} 
                                                value={key} 
                                                control={<Radio/>}
                                                label={this.state.frequencyOptions[key]}
                                            />;
                                        })
                                    }
                                </RadioGroup>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {this.state.showDays &&
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>Week Days</div>
                                <div style={styles.fieldContent}>
                                    {
                                        this.state.dayOptions.map((weekDays) => {
                                            return <FormControlLabel
                                                value={weekDays.value}
                                                control={<MuiCheckbox
                                                    name={weekDays.fieldName}
                                                    checked={this.props.state.fields[weekDays.fieldName]}
                                                    onChange={((event, value) => {
                                                        this.props.actions.setInputValue(event.target.name, event.target.checked);
                                                    })}
                                                />}
                                                label={weekDays.label}
                                                labelPlacement="start"
                                            />;
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
            {this.state.showYears &&
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>Month</div>
                                <div style={styles.fieldContent}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor={`outlined-month-native-simple`}>Month</InputLabel>
                                        <Select
                                            native
                                            label={"Month"}
                                            variant='outlined'
                                            id={"report_month"}
                                            key={"report_month"}
                                            value={this.props.state.fields.report_month}
                                            onChange={((event, value) => {
                                                this.props.actions.setInputValue('report_month', event.target.value);
                                            })}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                this.state.yearsArray.map((year, key) => {
                                                    return <option key={key} value={year.value}>{year.label}</option>;
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
            {this.state.showMonths &&
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>Month Date</div>
                                <div style={styles.fieldContent}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor={`outlined-month-date-native-simple`}>Month Date</InputLabel>
                                        <Select
                                            native
                                            label="Month Date"
                                            variant='outlined'
                                            id={"report_date"}
                                            key={"report_date"}
                                            value={this.props.state.fields.report_date}
                                            onChange={((event, value) => {
                                                this.props.actions.setInputValue('report_date', event.target.value);
                                            })}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                this.state.monthDatesArray.map((date, key) => {
                                                    return <option key={key} value={parseInt(date) + 1}>{parseInt(date) + 1}</option>;
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>Hour</div>
                            <div style={styles.fieldContent}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor={`outlined-hour-native-simple`}>Hour</InputLabel>
                                    <Select
                                        native
                                        label="Hour"
                                        variant='outlined'
                                        id={"report_hour"}
                                        key={"report_hour"}
                                        value={this.props.state.fields.report_hour}
                                        onChange={((event, value) => {
                                            this.props.actions.setInputValue('report_hour', event.target.value);
                                        })}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            this.state.hoursArray.map((hour, key) => {
                                                return <option key={key} value={hour}>{hour}</option>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>Minute</div>
                            <div style={styles.fieldContent}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor={`outlined-minute-native-simple`}>Minute</InputLabel>
                                    <Select
                                        native
                                        label="Minute"
                                        variant='outlined'
                                        id={"report_min"}
                                        key={"report_min"}
                                        value={this.props.state.fields.report_min}
                                        onChange={((event, value) => {
                                            this.props.actions.setInputValue('report_min', event.target.value);
                                        })}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            this.state.minutesArray.map((min, key) => {
                                                return <option key={key} value={min}>{min}</option>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    }

    getSchedulerRangeFields() {
        return <>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>Start Date</div>
                            <div style={styles.fieldContent}>
                                <DateControl
                                    id={"start_date"}
                                    key={"start_date"}
                                    name={"start_date"}
                                    label={"Select Start Date"}
                                    value={this.props.state.fields.start_date}
                                    onSelect={(args) => {
                                        if (args) {
                                            this.props.actions.setInputValue('start_date', args.split(' ')[0]);
                                        }
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitleEndType}></div>
                            <div style={styles.fieldContent}>
                                <Radio
                                    value="1"
                                    name="end_type"
                                    inputProps={{ 'aria-label': '1' }}
                                    checked={this.state.endTypeSelectedValue === '1'}
                                    onChange={((event, value) => {
                                        this.handleChange(event, value);
                                    })}
                                />
                                <span style={styles.fieldTitleEndTypeSecond}>End Date</span>
                                <DateControl
                                    id={"end_date"}
                                    key={"end_date"}
                                    name={"end_date"}
                                    label={"Select End Date"}
                                    value={this.props.state.fields.end_date}
                                    onSelect={(args) => {
                                        if (args) {
                                            this.props.actions.setInputValue('end_date', args.split(' ')[0]);
                                        }
                                    }}
                                    type={'date'}
                                />
                            </div>      
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitleEndType}></div>
                            <div style={styles.fieldContent}>
                                <Radio
                                    value="2"
                                    name="end_type"
                                    inputProps={{ 'aria-label': '2' }}
                                    checked={this.state.endTypeSelectedValue === '2'}
                                    onChange={((event, value) => {
                                        this.handleChange(event, value);
                                    })}
                                />
                                <span style={styles.fieldTitleEndTypeSecond}>Occurances</span>
                                <TextField
                                    id={"run_count"}
                                    key={"run_count"}
                                    label="Occurances"
                                    variant="outlined"
                                    color="secondary"
                                    value={this.props.state.fields.run_count}
                                    onChange={
                                        (event, value) => {
                                            this.props.actions.setInputValue('run_count', event.target.value);
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitleEndType}></div>
                            <div style={styles.fieldContent}>
                                <Radio
                                    value="3"
                                    name="end_type"
                                    inputProps={{ 'aria-label': '3' }}
                                    checked={this.state.endTypeSelectedValue === '3'}
                                    onChange={((event, value) => {
                                        this.handleChange(event, value);
                                    })}
                                />
                                <span style={styles.fieldTitleEndTypeSecond}>No End Date</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    }

    getField(section) {
        switch (section) {
            case 'general':
                return this.getGeneralFormFields();
                break;
            case 'schedule_pattern':
                return this.getSchedulerFormFields();
                break;
            case 'schedule_range':
                return this.getSchedulerRangeFields();
                break;
        }
    }

    getSection(section) {
        let fields = this.getField(section.name);
        return <Card key={'card_' + section.name} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={'material-icons'} iname={'edit'}/>}
                title={section.title}
                subtitle={section.description}
            />
            <CardContent expandable={true}>
                {fields}
            </CardContent>
        </Card>
    }

    getSave() {
        return <Card key={'card_btn'} initiallyExpanded={true}>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row style={styles.pullright}>
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            /* for (let x of this.props.state.fields) {
                                this.props.actions.setInputValue(x, null);
                            } */
                            this.props.actions.loadAddData();
                            this.props.actions.changeTabView(0);
                        }}
                        variant="contained"
                        className="btn-warning pull-right d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                    >
                        {<Icon iclass={'material-icons'} iname={'cancel'}/>} &nbsp; {'Cancel'}
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            this.props.actions.saveSchedulerData(this.props.state.fields);
                        }}
                        variant="contained"
                        className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                    >
                        {<Icon iclass={'material-icons'} iname={'save'}/>} &nbsp; {'Save Schedular'}
                    </Button>
                </Row>
            </Container>
        </Card>
    }

    getForm() {
        const section = [
            {
                name: 'general', 
                title: 'General', 
                description: 'General Info'
            }, 
            {
                name: 'schedule_pattern',
                title: 'Schedule Pattern',
                description: 'Schedule Pattern'
            }, 
            {
                name: 'schedule_range',
                title: 'Schedule Range',
                description: 'Schedule Range'
            }
        ];

        return section
            .map((x) => {
                return this.getSection(x)
            })
    }

    render() {
        return [this.getForm(), this.getSave()]
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.reportScheduler,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({...actions}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Create)