import * as types from './types'
import * as workflowGridActions from '../WorkflowGrid/actions'
import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'

export const confirmSalesProcess = (component_name, rowInfo, task_name, task) => {
    return (dispatch, getState) => {
        console.log('salesprocessx componentDidMount', component_name,task_name)
        console.log('salesprocessx componentDidMount', rowInfo)

        
        dispatch(workflowGridActions.selectRow(component_name, rowInfo))
        dispatch(workflowGridActions.confirmSelection(task_name, component_name))
        dispatch(workflowTaskInputActions.goToNextStep(task))
    }
}

export const confirmSelection = (step, component) => {
    return (dispatch, getState) => {
        const data = getState().components[component].selected.map(x => {
            return x.original
        })

        dispatch({
            type: types.CONFIRM_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: getState().components[component].selected.map(x => {
                    return x.original
                })
            }
        })
    }
}

export const resetSelection = (step, component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: []
            }
        })
    }
}

export const laodSelectedData = (step, component) => {
    return (dispatch, getState) => {
        const data = getState().workflowTaskInput.task.workflow_task_step
            .filter(x =>
                x.name === step &&
                x.workflow_task_step_component.filter(y => y.component.name === component.name).length
            )
            .reduce((arr, x) => {
                if (x.workflow_task_step_component.filter(y => y.component.name === component.name).length) {
                    arr.push(x.workflow_task_step_component.filter(y => y.component.name === component.name)[0])
                }

                return arr
            }, [])

        if (data.length) {

            const payload = {
                component: component.name,
                selected: [{
                    original: data.length ? data[0][0] : []
                }]
            }

            dispatch({
                type: types.SET_COMPONET_GRID_SELECTED_DATA,
                payload: payload
            })
        }
    }
}


export const setComponentFieldInput = (component, prop, value) => {
    return (dispatch, getState) => {
        const _component = getState().components[component]

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT,
            payload: {
                component: _component.name,
                field: prop,
                input: value,
                validation: ''
            }
        })
    }
}

export const toggleView = (view) => {
    return (dispatch, getState) => {
        //console.log('toggleView SET_COMPONENT_CURRENT_VIEW', view)
        dispatch({
            type: types.SET_COMPONENT_CURRENT_VIEW,
            payload: view
        })
    }
}
