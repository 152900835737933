export const latitude = '-25.975587'
export const longitude = '28.105916'
export const open_search = false
export const open_directions = false
export const zoom = 6


export const endpoint = null
export const startpoint = null
export const getdirection = false

export const data = []
export const address_data = []

export const source = {
    source: 'lastposition',
    icon: 'vehicles',
    map: 'vehicle',
    title: 'Vehicles',
    display: 'vehicle.licenseplateno',
    single: true,
    latitude: 'currentlatitude',
    longitude: 'currentlongitude'
}

export const sources = [
    {
        source: 'lastposition',
        title: 'Vehicles',
        icn: 'vehicles',
        icon: 'drive_eta',
        map: 'vehicle',
        display: 'vehicle.licenseplateno',
        single: true,
        latitude: 'currentlatitude',
        longitude: 'currentlongitude'
    },
    {
        source: 'customer',
        title: 'Customers',
        icon: 'account_balance',
        map: 'customeraddress',
        display: 'customername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    {
        source: 'supplier',
        title: 'Suppliers',
        icon: 'domain',
        map: 'supplieraddress',
        display: 'suppliername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    {
        source: 'supplier',
        title: 'Road Side Assistance',
        icon: 'headset_mic',
        map: 'supplieraddress',
        key: 'suppliertype',
        query: 'Tow',
        display: 'suppliername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    // {
    //     source: 'supplier',
    //     title: 'Isuzu Dealers',
    //     icon: 'creditors',
    //     map: 'supplieraddress',
    //     key: 'suppliertype_fktext',
    //     query: 'Isuzu Dealer',
    //     display: 'suppliername',
    //     single: false,
    //     latitude: 'latitude',
    //     longitude: 'longitude'
    // },
    // {
    //     source: 'supplier',
    //     title: 'Yellow Equipment',
    //     icon: 'operations',
    //     map: 'supplieraddress',
    //     key: 'suppliertype_fktext',
    //     query: 'Yellow Equipment',
    //     display: 'suppliername',
    //     single: false,
    //     latitude: 'latitude',
    //     longitude: 'longitude'
    // },
    {
        source: 'supplier',
        title: 'Workshops',
        icon: 'business',
        map: 'supplieraddress',
        key: 'suppliertype',
        query: 'Workshop',
        display: 'suppliername',
        single: false,
        latitude: 'latitude',
        longitude: 'longitude'
    },
    {
        source: 'business',
        title: 'Infleet Branches',
        icon: 'store_mall_directory',
        single: true,
        map: '',
        display: 'businessname',
        latitude: 'latitude',
        longitude: 'longitude'
    }
]