import * as types from './types'
import moment  from 'moment'

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CREDITORS_PAYMENT_COMPONENT_REVIEW_AND_APPROVE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}