import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'


export const getMMCodeVehicles = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.STR_GET_COMPONENT_MMCODE_LOOKUP_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.model + '/search' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': payload.order,
                    'related': payload.related,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setComponentFieldLookupData(payload.component.name, payload.field, result.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/' + payload.model + '/search' + payload.query)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.model + '/search' + payload.query, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
        
export const getStockVehicleDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.STR_GET_STOCK_VEHICLE_RATECARD),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/vehicle/search?vehicle_id=' + payload.query,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': payload.related,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setMMCodeDetails(payload.component.name, payload.field, payload.inx, result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/vehicle/search' + payload.query)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search' + payload.query, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )
        


    export const getRateCardDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.STR_GET_VEHICLES),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/ratecard/search?mmcode_id=' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    //'order': 'makemodelyear',
                    'related': payload.related,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setRateCardDetails(payload.component.name, payload.field, payload.inx, result.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/ratecard/search' + payload.query)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/ratecard/search' + payload.query, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

    
export const getSTRVehicleDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.STR_GET_STR_VEHICLE_RATECARD),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/vehicle/search?vehicle_id=' + payload.query,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': payload.related,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setMMCodeDetails(payload.component.name, payload.field, payload.inx, result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/vehicle/search' + payload.query)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search' + payload.query, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )
        