import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete'
import NavigationExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
    Grid,
    Dialog,
    TextField,
    IconButton,
    Button,
    Chip
  } from '@mui/material';

import View from '../components/View/component'
import * as actions from './actions'

import { styled } from '@mui/material/styles';

const TextFieldx = styled(TextField)({
    "& .MuiFormLabel-root": {
        fontSize: '14px'
    },
    "& .MuiFormLabel-filled": {
        color: "black",
        fontWeight: 600,
        fontSize: '14px'
    },
    root: {
        "& .MuiFormLabel-filled": {
          color: "black",
          fontWeight: 600
        }
    }
});

const filter = createFilterOptions();

class _multiselect extends Component {
    
    componentDidMount() {
        this.props.actions.toggleAddItemDialog(false)
    }

    getAutoComplete() {

        return <div><AutoComplete 
            id={this.props.name} 
            key={this.props.name} 
            multiple
            autoHighlight 
            limitTags={this.props.limitTags ? this.props.limitTags : 5}
            fullWidth={true}
            size={"small"}
            options={this.props.data ? this.props.data : this.props.dataSource}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                if(option) {
                    if (option.inputValue) {
                        return  option[this.props.fieldtext ? this.props.fieldtext : 'text'] // option.inputValue;
                    }

                    return option[this.props.fieldtext ? this.props.fieldtext : 'text']
                }
            }}
            renderInput={(params) => {
                let _params = this.props.value ? {
                    ...params,
                    inputProps: {
                        ...params.inputProps,
                        value: this.props.value,
                        style: {fontSize: 14}
                    }
                } : params 
                return <TextFieldx
                    {..._params}
                    //error={this.props.value ? false : true}
                    name={this.props.name}
                    label={this.props.title?this.props.title:this.props.hintText?this.props.hintText:''}
                    variant="outlined"
                    style={{fontSize: 14}}
                    fullWidth={true}
                    size={"small"}
                    placeholder="Please select item"
                />
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if(params) {
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  [this.props.fieldtext ? this.props.fieldtext : 'text']: `Click To Add "${params.inputValue}"`,
                });
              }}
              //console.log('filtered',filtered)
              return filtered;
            }}
            helperText={this.props.title}
            onOpen={
                (chosenRequest, index) => {
                    this.props.onClick()
                }
            }
            onInputChange={
                (event, search) => {
                    this.props.onFilter?this.props.onFilter(search):this.props.onUpdateInput(search)
                }
            }
            onChange={
                (event, request) => {
                    //console.log("//console.log(request)",request)
                    //console.log(event)
                    console.log('requestx', request)
                    if (request && request.length) 
                    {
                        //console.log('ADD ITEM ...................')
                        this.props.actions.toggleAddItemDialog(true, request[0].inputValue)
                        // Create a new value from the user input
                        // setValue({
                        //   title: newValue.inputValue,
                        // });
                    } else {
                        //console.log('ON SELECT ITEM ...................')
                        this.props.onSelect(request) //{value: this.props.fieldvalue ? request[this.props.fieldvalue] : request.value, text: this.props.fieldtext ? request[this.props.fieldtext] : request.text})
                    }
                }
            }
        />
        
        <Dialog
            open={false} //this.props.state.additem_win}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.toggleAddItemDialog(false)
            }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
             <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
                {'Add Item: ' + this.props.state.additem_name}
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                You do not have permission to add new item, Please contact system admin.
              </p>
              <div className="pt-4">
                <Button 
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.toggleAddItemDialog(false)
                    }}
                    className=" btn-pill mx-1">
                    <span className="btn-wrapper--label">Close</span>
                </Button>
              </div>
            </div> 
          </Dialog>
        </div>
    }

    getWithFieldsAutoComplete() {
        let displaywidth = this.props.displaywidth
        return <div><AutoComplete 
            id={this.props.name} 
            key={this.props.name} 
            multiple
            autoHighlight 
            limitTags={this.props.limitTags ? this.props.limitTags : 5}
            fullWidth={true}
            options={this.props.data ? this.props.data : this.props.dataSource}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                if(option) {
                    if (option.inputValue) {
                        return  option[this.props.fieldtext ? this.props.fieldtext : 'text'] // option.inputValue;
                    }

                    return option[this.props.fieldtext ? this.props.fieldtext : 'text']
                }
            }}
            renderTags={(tagValue, getTagProps) => 
                tagValue.map((option, index) => (
                  <Chip
                    label={option[this.props.fieldtext ? this.props.fieldtext : 'text']}
                    color="primary"
                    {...getTagProps({ index })}
                  />
                ))}
            renderOption={(props, option) => (
                <React.Fragment>
                    <table style={{ width: '100%' }} {...props} >
                        <tr>
                            <td>
                                <label>{option[this.props.displayfields[0]]}</label><br />
                                {
                                    this.props.displayfields.map((x, i) => {
                                        return i === 0 ? '' : <small style={{ width: displaywidth ? displaywidth[i] + '%' : (100 / (this.props.displayfields.length - 1) + '%') }}>{option[x]}    </small>
                                    })
                                }
                            </td>
                        </tr>
                    </table>
                </React.Fragment>
            )}
            renderInput={(params) => {
                let _params = this.props.value ? {
                    ...params,
                    inputProps: {
                        ...params.inputProps,
                        value: this.props.value,
                        style: {fontSize: 14}
                    }
                } : params 
                return <TextFieldx
                    {..._params}
                    //error={this.props.value ? false : true}
                    name={this.props.name}
                    label={this.props.title?this.props.title:this.props.hintText?this.props.hintText:''}
                    variant="outlined"
                    placeholder="Please select item"
                    style={{fontSize: 14}}
                    fullWidth={true}
                    size={"small"}
                />
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if(params) {
                if (params.inputValue !== '') {
                    filtered.push({
                    inputValue: params.inputValue,
                    [this.props.fieldtext ? this.props.fieldtext : 'text']: `Click To Add "${params.inputValue}"`,
                    });
                }
                }
              //console.log('filtered',filtered)
              return filtered;
            }}
            helperText={this.props.title}
            onOpen={
                (chosenRequest, index) => {
                    this.props.onClick()
                }
            }
            onInputChange={
                (event, search) => {
                    this.props.onFilter?this.props.onFilter(search):this.props.onUpdateInput(search)
                }
            }
            onChange={
                (event, request) => {
                    //console.log("//console.log(request)",request)
                    console.log('requestx', request)
                    if (request && request.length) 
                    {
                        //console.log('ADD ITEM ...................')
                        this.props.actions.toggleAddItemDialog(true, request[0].inputValue)
                    } else {
                        //console.log('ON SELECT ITEM ...................')
                        this.props.onSelect(request) //{value: this.props.fieldvalue ? request[this.props.fieldvalue] : request.value, text: this.props.fieldtext ? request[this.props.fieldtext] : request.text})
                    }
                }
            }
        />
        </div>
    }

    getLayout() {
        return this.props.displayfields
            ? this.getWithFieldsAutoComplete()
            : this.getAutoComplete()
    }
    
    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.controls }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(_multiselect)