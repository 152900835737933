export const NOT_IMPLEMENTED = 'NOT_IMPLEMENTED'
export const GET_COMPONENTS = 'GET_COMPONENTS'
export const SET_COMPONENTS = 'SET_COMPONENTS'
export const SHOW_NAVIGATION_MENU = 'SHOW_NAVIGATION_MENU'
export const HIDE_NAVIGATION_MENU = 'HIDE_NAVIGATION_MENU'
export const TOGGLE_NAVIGATION_MENU_VISIBILITY = 'TOGGLE_NAVIGATION_MENU_VISIBILITY'
export const GET_WORKFLOW = 'GET_WORKFLOW'
export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_TEAM_DATA = 'GET_TEAM_DATA'
export const TOGGLE_RELATIONSHIP_MENU = 'TOGGLE_RELATIONSHIP_MENU'
export const TOGGLE_RELATIONSHIP_MENU_DRAWER = 'TOGGLE_RELATIONSHIP_MENU_DRAWER'

export const NAVIGATION_MENU_GET_MENU = 'NAVIGATION_MENU_GET_MENU'
export const NAVIGATION_MENU_SET_MENU = 'NAVIGATION_MENU_SET_MENU'
export const NAVIGATION_MENU_SET_HEADERS = 'NAVIGATION_MENU_SET_HEADERS'
export const STATIC_MENU_GET_MENU = 'STATIC_MENU_GET_MENU'
export const STATIC_MENU_SET_MENU = 'STATIC_MENU_SET_MENU'

export const GET_USER_WORKFLOW_NOTIFICATIONS_MENUS = 'GET_USER_WORKFLOW_NOTIFICATIONS_MENUS'
export const SET_USER_WORKFLOW_NOTIFICATIONS_MENUS = 'SET_USER_WORKFLOW_NOTIFICATIONS_MENUS'

export const SET_SELECTED_MENU_ITEM_TOGGLE_STATE = 'SET_SELECTED_MENU_ITEM_TOGGLE_STATE'
export const SET_SELECTED_SUB_MENU_ITEM_TOGGLE_STATE = 'SET_SELECTED_SUB_MENU_ITEM_TOGGLE_STATE'
export const TOGGLE_MEGA_MENUS = 'TOGGLE_MEGA_MENUS'
export const  SET_SELECTED_DASHBOARD_MENU_ITEM_TOGGLE_STATE = 'SET_SELECTED_DASHBOARD_MENU_ITEM_TOGGLE_STATE'
export const TOGGLE_DASH_MEGA_MENUS = 'TOGGLE_DASH_MEGA_MENUS'

export const SET_SELECTED_DASHBOARD = 'SET_SELECTED_DASHBOARD'
export const GET_JSREPORT_DATA = 'GET_JSREPORT_DATA'
export const SET_JSREPORT_DATA = 'SET_JSREPORT_DATA'

export const GET_DOCUMENT_360_TOKEN = 'GET_DOCUMENT_360_TOKEN';