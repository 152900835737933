import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_DASHBOARD_VIEW_DATA:
            return {
                loading: true,
                data: []
            }
        case types.SET_DASHBOARD_VIEW_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        default:
            return state
    }
}