
export const cognito = {
    clientId: process.env.REACT_APP_COGNITO_CLIENT_ID_LOC,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID_LOC,
    region: process.env.REACT_APP_COGNITO_REGION_LOC,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_LOC,
    login: process.env.REACT_APP_COGNITO_LOGIN_LOC
}

export const uri = {
    
    // logo: 'bidvest-logo.png',
    // header: 'bidvest-white-logo.svg'
    
    logo: 'bidvest-white-logo.svg',
    header: 'bidvest-white-logo.svg',
    // logo: 'visa2.jpg',
    // header: 'visa2.jpg',
    // logo: 'bidvest-white-logo.svg',
    // header: 'bidvest-white-logo.svg',
    // logo: 'INFLEET365 Logo.png',
    // header: 'INFLEET365 Logo.png'
}

export const system = {
    authMethod: process.env.REACT_APP_ATUHMETHOD,
    redirectUri: process.env.REACT_APP_AD_REDIRECT_URI,
    clientId: process.env.REACT_APP_AD_CLIENT_ID,
    authority: process.env.REACT_APP_AD_AUTHORITY,

    version: process.env.REACT_APP_VERSION,
    env: process.env.NODE_ENV,
    app: process.env.REACT_APP_NODE_ENV,
    locale: 'en', //+process.env.REACT_APP_THEME,

    api:process.env.REACT_APP_API_PROTOCOL_LOC + '://' + process.env.REACT_APP_API_URI_LOC ,
    apioff: process.env.REACT_APP_API_PROTOCOL_LOC + '://' + process.env.REACT_APP_API_URI_LOC,
    auth: process.env.REACT_APP_API_AUTH_KEY,
    textboxColor: 'transparent',  //[black,white]
    textColor: 'white',  //[black,white]
    clientTheme: 'bidvest',  //[innov,bidvest]
    bi: {
        address: '', //process.env.REACT_APP_BI_ADDRESS,
        script: '', //process.env.REACT_APP_BI_SCRIPT,
        username: '', //process.env.REACT_APP_BI_USERNAME,
        password: '', //process.env.REACT_APP_BI_PASSWORD,
        isWindowsLogon: '', //process.env.REACT_APP_BI_WINDOWS_LOGON
    },

    route: process.env.REACT_APP_ROUTE_URI,
    nominatim: process.env.REACT_APP_NOMINATIM_URI,

    cdn: process.env.REACT_APP_CDN_ADDRESS_LOC ,

    recaptcha: {
        siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY
    },

    messenger: {
        uri: process.env.REACT_APP_MESSENGER_PROTOCOL_LOC + '://' + process.env.REACT_APP_MESSENGER_URI_LOC,
    }
}


export const oq = {
    src:process.env.REACT_APP_OQ_SRC_LOC,
    api_key:process.env.REACT_APP_OQ_API_KEY_LOC,
    connection: process.env.REACT_APP_OQ_CONNECTION_LOC,
    url:  process.env.REACT_APP_OQ_URL_LOC,
    uri:  process.env.REACT_APP_OQ_URI_LOC,
    address:  process.env.REACT_APP_OQ_ADDRESS_LOC,
}
