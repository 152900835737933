// export const components = []
export const grid = []
// export const selected = {}

// export const componentname = ''
// export const component_id = ''
// export const item_id = ''

export const fields = [
    {
        name: 'Class',
        label: 'Item Category',
        type: 'select',
        width: 200,
        col: 2,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'maintenanceclass',
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Description',
        label: 'Description',
        type: 'select',
        width: 250,
        col: 2,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'maintenanceitem',
        related: 'maintenanceclass_id.Class_Id',
        text: null,
        value: null
    },
    {
        name: 'Quantity',
        label: 'Quantity',
        type: 'numeric',
        width: 150,
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Price',
        label: 'UnitPrice',
        type: 'numeric',
        width: 150,
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Total',
        label: 'Total',
        type: 'numeric',
        width: 130,
        col: 1,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Savings',
        label: 'Savings',
        type: 'numeric',
        width: 150,
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Discount',
        label: 'Discount(%)',
        type: 'numeric',
        width: 150,
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'DiscountAmount',
        label: 'DiscountAmount',
        type: 'numeric',
        width: 150,
        col: 1,
        align: 'right',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'VAT',
        label: 'VAT',
        type: 'numeric',
        width: 120,
        col: 1,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Amount',
        label: 'TotalAmount',
        type: 'numeric',
        width: 150,
        col: 2,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Rebill',
        label: 'Rebill',
        type: 'toggle',
        width: 100,
        col: 1,
        align: 'left',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Warranty',
        label: 'Warranty (Months)',
        type: 'numeric',
        width: 100,
        col: 1,
        align: 'right',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    // {
    //     name: 'action',
    //     label: '',
    //     type: 'action',
    //     width: 100,
    //     align: 'right',
    //     hide: false,
    //     read_only: false,
    //     related: null,
    //     text: null,
    //     value: null
    // }
    {
        name: 'Comment',
        label: 'Comments',
        type: 'text',
        width: 150,
        col: 1,
        align: 'left',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Vattable',
        label: 'Vattable',
        type: 'toggle',
        width: 0,
        col: 1,
        align: 'left',
        hide: true,
        read_only: true,
        related: null,
        text: null,
        value: null
    }
]
export const maintenanceclass = []
export const maintenanceitem = []
export const itemlist = []
export const loading = true
export const grid_item_inx = 0
export const grid_row = null
export const modelwin = false
export const servicehistory = false
export const tyreshistory = false
export const driverbehaviour = false
