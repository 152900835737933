import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
// import AutoComplete from '../../controls/autocomplete'


import TextField from '@mui/material/TextField'
// import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'

import Dropzone from 'react-dropzone'
import Iframe from 'react-iframe'

import Card from '../../controls/card'
import DateControl from '../../controls/dateControl'
import AutoComplete from '../../controls/autocomplete'

import * as actions from './actions'
import * as styles from './styles'

import AdhocComponentFieldInput from '../Custom/AdhocComponentFieldInput/component'

String.prototype.splice = function (idx, rem, str) {
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem))
}

class WorkflowViewFMLQuoteSummary extends Component {
    componentDidMount() {
        this.props.actions.clearMotivationDocuments(this.props.state.document)
        this.props.actions.clearRFQDocument(this.props.state.component)

        try {
            const selected = this.props.selected
            const workflow_queue_data = this.props.workflow_queue_data[0]
            const data = workflow_queue_data.context_data.data.data.data[0].data.data
            //console.log('data contractperiod 1',data)
            const vehicle = data.vehicles.filter(x => x.quotenumber === selected.quotenumber)
            this.props.actions.setupWorkflowTaskInput(vehicle[0])

            //console.log('data contractperiod',data)
            let field = this.props.state.purchaseorder.component_field.reduce((obj, x) => {
                obj[x.name] = x
                return obj
            }, {})
            let contractperiod = 90
            try { 
                contractperiod = parseInt(data.contractperiod)
            }
            catch (err) { }
            
            this.props.actions.setValue(field.poddate, moment().format('YYYY-MM-DD'))
            this.props.actions.setValue(field.receiptdate, moment().add(contractperiod, 'days').format('YYYY-MM-DD'))

        }
        catch (err) {
            //console.log('data contractperiod 1 error',err) 
            }
        
    }

    getAccessories() {
        const data = this.props.state.surrogateitems.grid.data
        return <Card 
                title={window.t.en('Accessory List')}
                subtitle={window.t.en('Selected vehicle accessory')}
                content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row style={styles.headerrow}>
                        <Col xl={5} style={styles.selectedFieldName}>
                            {'Accessory Name'}
                        </Col>
                        <Col xl={2} style={styles.selectedFieldName}>
                            {'Standard'}
                        </Col>
                        <Col xl={2} style={styles.selectedFieldName}>
                            {'OEM Fitted'}
                        </Col>
                        <Col xl={3} style={styles.numericFieldsHeader}>
                            {'Cost Price'}
                        </Col>
                        {/* <Col xl={3} style={styles.selectedFieldName}>
                            {'Select Supplier'}
                        </Col> */}
                    </Row>
                    {data.map((x, i) => {
                        return <Row style={styles.itemrow}>
                            <Col xl={5}>
                                {x.itemname}
                            </Col>
                            <Col xl={2}>
                                {parseInt(x.totalprice) > 0 ? 'No' : 'Yes'}
                            </Col>
                            <Col xl={2}>
                                {x.retrofittable ? 'Yes' : 'No'}
                            </Col>
                            <Col xl={3} style={styles.numericFields}>
                                {x.totalprice}
                            </Col>
                        </Row>
                    })
                    }
                </Container>
                 }
                />
    }

    getQuote(data) {
        //console.log('Quotation Details',data)
        return <Card 
                title={window.t.en('Quotation Details #')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Quote Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.quotenumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Created By:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.createdby}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Quote For:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.replacedvehicle}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Quote Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.rfqdate ? moment(data.rfqdate).format('DD/MM/YYYY HH:mm') : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Rate Card:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.ratecategorydescription}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Quote Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.description}</Col>
                    </Row>
                    {
                        data.reviewedby
                            ? <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Reviewed By:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.reviewedby}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Reviewed Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.revieweddate ? moment(data.revieweddate).format('DD/MM/YYYY HH:mm') : ''}</Col>
                            </Row>
                            : ''
                    }
                    {
                        data.approvedby
                            ? <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.approvedby}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.approvedate ? moment(data.approvedate).format('DD/MM/YYYY HH:mm') : ''}</Col>
                            </Row>
                            : ''
                    }
                </Container>
                    }
                />
    }

    getCustomer(data) {
        return <Card
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Customer Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.customername}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Business Unit:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.businessunit}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Region:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.customerregion}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Cost Centre:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.costcentre}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vatnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Account Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.accountnumber}</Col>
                    </Row>
                    {/* <Row>
                        <Col xl={12}>
                            <AdhocComponentFieldInput
                                title={window.t.en('customercontact')}
                                title={window.t.en('Contact List')}
                                description={'Please select contacts to send email to'}
                                id={'customercontact'}
                                parent={'customercontact'}
                                parentFilters={[
                                    {
                                        field: 'customer_id',
                                        value: data.customer_id
                                    }
                                ]}
                                child={'contact'}
                                childFilters={[
                                    {
                                        field: 'contacttype',
                                        value: ''
                                    }
                                ]}
                                extractChildData={true}
                                adhocFieldInput={[ //assumes Parent component fields if extractChild is set to false
                                    'fullname', 'email', 'mobile', 'contacttype'
                                ]}
                                selectMode={false}
                                addMode={false}
                            />
                        </Col>
                    </Row> */}
                </Container>
                }
            />
    }

    getVehicle(data) {
        return <Card 
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Requested vehicle on the quote')}
                content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.toString().splice(3, 0, "-").splice(6, 0, "-")}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.make}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.makemodelyear}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Maximum Mileage:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.contractdistance ? data.contractdistance : data.distance}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Contracted Distance per Month:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.distancepermonth}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Contract Period:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.contractperiod}</Col>
                    </Row>
                </Container>
                }
            />
    }

    getFinance(data) {
        return <Card 
                title={window.t.en('Finance Details')}
                subtitle={window.t.en('Vehicle finance details')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Retail Price:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{data.retailprice}</Col>
                        <Col xl={2}></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Tyres:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{data.tyres}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Discount:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{data.discount}</Col>
                        <Col xl={2}></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Maintenance:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.maint).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>

                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Price:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.vehicleprice).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'ROI @ ' + data.returnOnInvestment + '% :'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.roi).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>

                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'RV %:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.rv).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Finance:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.finance).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'RV Amount:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.rv_amount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Monthly Rental .excl:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.monthly_excl.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Accessory Total:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.accessory_tot).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Monthly Rental VAT:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.monthly_vat.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Service Total:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.service_tot).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Monthly Rental .incl:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.monthly_incl.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Sundry Total:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.sundry_tot).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Monthly Rental STR:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{parseFloat(data.monthly_str.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        <Col xl={2}></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Depreciation CPK:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{data.depreciation}</Col>
                        <Col xl={2}></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Finance Excess:'}</Col>
                        <Col xl={1} style={styles.numericFieldsHeader}>{data.financeexcess}</Col>
                        <Col xl={2}></Col>
                    </Row>
                </Container>
                }
            />
    }

    getForm(data) {
        const fields = this.props.state.purchaseorder.data

        return <Card 
                title={window.t.en('Quote Form')}
                subtitle={window.t.en('Complete form the form')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelA}>{'OEM On the Road Fee:'}</Col>
                        <Col xl={9}>
                            <TextField
                                                                        variant="outlined" 
                                id={'txt_onroad'}
                                hintText={'Enter on the road fee'}
                                fullWidth={true}
                                multiLine={false}
                                value={fields.ontheroadfee.input}
                                onChange={(event, value) => {
                                    this.props.actions.setValue(this.field.ontheroadfee, event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelA}>{'OEM License & Registration Fee:'}</Col>
                        <Col xl={9}>
                            <TextField
                                variant="outlined" 
                                id={'txt_licandreg'}
                                hintText={'Enter license and registration fee'}
                                fullWidth={true}
                                multiLine={false}
                                value={fields.licenseregistrationfee.input}
                                onChange={(event, value) => {
                                    this.props.actions.setValue(this.field.licenseregistrationfee, event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelA}>{'Non VAT Fee:'}</Col>
                        <Col xl={9}>
                            <TextField
                                variant="outlined" 
                                id={'txt_vattable'}
                                hintText={'Enter non vattable fee'}
                                fullWidth={true}
                                multiLine={false}
                                value={fields.nonvatamount.input}
                                onChange={(event, value) => {
                                    this.props.actions.setValue(this.field.nonvatamount, event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelA}>{'Select Vehicle Funder:'}</Col>
                        <Col xl={9}>
                            <AutoComplete
                                variant="outlined" 
                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                id={'ddl_funder'}
                                key={'ddl_funder'}
                                hintText={'Select Vehicle Funder '}
                                filter={AutoComplete.fuzzyFilter}
                                openOnFocus={true}
                                fullWidth={true}
                                dataSourceConfig={{
                                    text: 'fundername',
                                    value: 'financialinstitution_id'
                                }}
                                fieldvalue={'financialinstitution_id'}
                                displayfields={['fundername']}
                                dataSource={fields.financialinstitution_id.data}
                                onSelect={(args) => {
                                        this.props.actions.setLookupValue(this.props.state.purchaseorder, this.field.financialinstitution_id, args.value, args.text)
                                        this.props.actions.setComments(this.props.state.purchaseorder, this.field.financialinstitution_id, this.field.comments)
                                    }
                                }
                                onClick={
                                    () => {
                                        this.props.actions.searchLookupValue(this.props.state.financialinstitution, this.field.financialinstitution_id, '', '')
                                    }
                                }
                                onFilter={
                                    (args) => {
                                        this.props.actions.searchLookupValue(this.props.state.financialinstitution, this.field.financialinstitution_id, args, '')
                                    }
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelA}>{'Select OEM Supplier:'}</Col>
                        <Col xl={9}>
                            <AutoComplete
                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                id={'ddl_supplier'}
                                key={'ddl_supplier'}
                                hintText={'Select Supplier '}
                                filter={AutoComplete.fuzzyFilter}
                                openOnFocus={true}
                                fullWidth={true}
                                fieldvalue={'supplier_id'}
                                displayfields={['suppliername']}
                                dataSource={fields.supplier_id.data}
                                onSelect={(args) => {
                                        this.props.actions.setLookupValue(this.props.state.purchaseorder, this.field.supplier_id, args.value, args.text)
                                    }
                                }
                                onClick={() => {
                                        this.props.actions.searchLookupValue(this.props.state.supplier, this.field.supplier_id, '', 'suppliercontact.contact')
                                    }
                                }
                                onFilter={(args) => {
                                        this.props.actions.searchLookupValue(this.props.state.supplier, this.field.supplier_id, args, 'suppliercontact.contact')
                                    }
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelA}>{'Select Dealer:'}</Col>
                        <Col xl={9}>
                            <AutoComplete
                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                id={'ddl_dealer'}
                                key={'ddl_dealer'}
                                hintText={'Select Supplier '}
                                filter={AutoComplete.fuzzyFilter}
                                openOnFocus={true}
                                fullWidth={true}
                                fieldvalue={'supplier_id'}
                                displayfields={['suppliername']}
                                dataSource={fields.branch_id.data}
                                onSelect={(args) => {
                                        this.props.actions.setDealerLookupValue(this.props.state.purchaseorder, this.field.branch_id, args.value, args.text)
                                        ///this.props.actions.setInstruction(this.props.state.purchaseorder, this.field.branch_id, this.field.instruction, args.value)

                                    }
                                }
                                onClick={() => {
                                        this.props.actions.searchBranchLookupValue(this.props.state.supplier, this.field.supplier_id, this.field.branch_id, '', 'suppliercontact.contact,supplieraddress.address')
                                    }
                                }
                                onFilter={(args) => {
                                        this.props.actions.searchBranchLookupValue(this.props.state.supplier, this.field.supplier_id, this.field.branch_id, args, 'suppliercontact.contact,supplieraddress.address')
                                    }
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelA}>{'Vehicle Delivery Address:'}</Col>
                        <Col xl={9}>
                            <TextField
                                                                        variant="outlined" 
                                id={'txtinstruction'}
                                hintText={'Enter delivery instruction'}
                                fullWidth={true}
                                multiLine={true}
                                value={fields.instruction.input}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setValue(this.field.instruction, event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelA}>{'PO Comments:'}</Col>
                        <Col xl={9}>
                            <TextField
                                                                        variant="outlined" 
                                id={'txtcomments'}
                                hintText={'Enter comments'}
                                fullWidth={true}
                                multiLine={true}
                                value={fields.comments.input}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setValue(this.field.comments, event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    {/* {
                        this.props.state.document.data.content.text
                            ? <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Signed Quote:'}</Col>
                                <Col xl={9}>
                                    {
                                        this.props.state.document.data.content.text
                                            ? <Iframe url={this.props.state.document.data.content.text}
                                                width="100%"
                                                height="300"
                                                id="myId"
                                                className="myClassname"
                                                display="initial"
                                                position="relative"
                                                allowFullScreen />
                                            : 'No File..'
                                    }
                                   < Button className={"global_button"} variant="contained"
                                        primary={true}
                                        label="Clear"
                                        onClick={(event) => {
                                            this.props.actions.clearRFQDocument(this.component)
                                        }}
                                        >{window.t.en('Clear')}</Button>
                                </Col>
                            </Row>
                            : <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Upload Signed Quote:'}</Col>
                                <Col xl={9}>
                                    <Dropzone
                                        ////accept={'/*'}
                                        style={styles.dropZone}
                                        onDrop={(files) => {
                                            //component.actions.toggleDropzoneVisibility()
                                        }}
                                        onDropAccepted={(files) => {
                                            this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                        }}
                                        onDropRejected={(files) => {
                                            this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                        }}
                                    >
                                        <p style={styles.watermark}>
                                            Drag and drop file here, or click to select file to upload.
                                                                </p>
                                    </Dropzone>
                                </Col>
                            </Row>
                    }
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    {
                        this.props.state.document.data.filename.text
                            ? <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Additional Documents (Optional):'}</Col>
                                <Col xl={9}>
                                    {
                                        this.props.state.document.data.filename.text
                                            ? <Iframe url={this.props.state.document.data.filename.text}
                                                width="100%"
                                                height="300"
                                                id="myId"
                                                className="myClassname"
                                                display="initial"
                                                position="relative"
                                                allowFullScreen />
                                            : 'No File..'
                                    }
                                   < Button className={"global_button"} variant="contained"
                                        primary={true}
                                        label="Clear"
                                        onClick={(event) => {
                                            this.props.actions.clearMotivationDocuments(this.props.state.document)
                                        }}
                                        >{window.t.en('Clear')}</Button>
                                </Col>
                            </Row>
                            : <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Upload Additional Documents:'}</Col>
                                <Col xl={9}>
                                    <Dropzone
                                        ////accept={'/*'}
                                        style={styles.dropZone}
                                        onDrop={(files) => {
                                            //component.actions.toggleDropzoneVisibility()
                                        }}
                                        onDropAccepted={(files) => {
                                            this.props.actions.setMotivationDocuments(this.props.state.document, files)
                                        }}
                                        onDropRejected={(files) => {
                                            this.props.actions.setMotivationDocuments(this.props.state.document)
                                        }}
                                    >
                                        <p style={styles.watermark}>
                                            Drag and drop file here, or click to select file to upload.
                                                                </p>
                                    </Dropzone>
                                </Col>
                            </Row>
                    } */}

<Row>
                        <Col xl={3} style={styles.fieldLabelA}>{'Your Comments:'}</Col>
                        <Col xl={9}>
                            <TextField
                                                                        variant="outlined" 
                                id={'txtyocomments'}
                                hintText={'Enter Your comments'}
                                fullWidth={true}
                                multiLine={false}
                                value={fields.podcomments.input}
                                onChange={(event, value) => {
                                    this.props.actions.setValue(this.field.podcomments, event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelDates}>{'Date Customer Order Received:'}</Col>
                        <Col xl={9}>
                            <DateControl
                                id={'txtpoddate'}
                                key={'txtpoddate'}
                                label={'Select PO Date'}
                                name={'txtpoddate'}
                                value={fields.poddate.input}
                                onSelect={(args) => {
                                    this.props.actions.setValue(this.field.poddate, args)
                                }}
                                type={'date'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabelDates}>{'Date Vehicle Required:'}</Col>
                        <Col xl={9}>
                            <DateControl
                                id={'txtpoddate'}
                                key={'txtpoddate'}
                                label={'Select Order Date'}
                                name={'txtpoddate'}
                                value={fields.receiptdate.input}
                                onSelect={(args) => {
                                    this.props.actions.setValue(this.field.receiptdate, args)
                                }}
                                type={'date'}
                            />
                        </Col>
                    </Row>
                </Container>
                }
                />
    }

    getView(data, selected) {

        const vehicle = data.vehicles.filter(x => x.quotenumber === selected.quotenumber)

        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {this.getQuote(selected)}
                </Col>
                <Col xl={12}>
                    {this.getVehicle(vehicle[0])}
                </Col>
                <Col xl={12}>
                    {this.getCustomer(data.customer)}
                </Col>
                <Col xl={12}>
                    {this.getFinance(vehicle[0])}
                </Col>
                <Col xl={12}>
                    {this.getAccessories()}
                </Col>
                <Col xl={12}>
                    {this.getForm(vehicle[0])}
                </Col>
            </Row>
        </Container>
    }

    render() {
        const selected = this.props.selected
        const workflow_queue_data = this.props.workflow_queue_data[0]
        const data = workflow_queue_data.context_data.data.data.data[0].data.data
        this.component = this.props.state.component

        //console.log('WorkflowViewFMLQuoteSummary data.vehicles', data.vehicles)
        this.grid = this.props.state.purchaseorder.data
        this.field = this.props.state.purchaseorder.component_field.reduce((obj, x) => {
            obj[x.name] = x
            return obj
        }, {})

        return (
            data
                ? this.getView(data, selected)
                : 'Loading, Please wait...'
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            component: {
                ...state.components[ownProps.name]
            },
            document: {
                ...state.components['document']
            },
            supplier: {
                ...state.components['supplier']
            },
            purchaseorder: {
                ...state.components['purchaseorder']
            },
            color: {
                ...state.components['color']
            },
            surrogateitems: {
                ...state.components['surrogateitems']
            },
            contact: {
                ...state.components['contact']
            },
            financialinstitution: {
                ...state.components['financialinstitution']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowViewFMLQuoteSummary)