import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_SELECTED_DASHBOARD:
            return {
                ...state,
                value: action.payload
            }
        case types.SHOW_DASHBOARD:
            return {
                ...state,
                dashboard: action.payload
            }
        case types.SET_SELECTED_REPORT:
            return {
                ...state,
                reportValue: action.payload
            }
        case types.SHOW_REPORT:
            return {
                ...state,
                report: action.payload
            }
        case types.SET_MASTER_LOADING_STATUS:
            return {
                ...state,
                loading: action.payload
            }
        case types.CLEAR_MASTER_COMPONENT_VISUALISATIONS:
            return {
                ...state,
                dashboard: '',
                report: ''
            }
        // case types.CHANGE_MASTER_COMPONENT_TAB:
        //     return {
        //         ...state,
        //         tab: action.payload.value,
        //         tabs: state.tabs.map(x => {
        //             return {
        //                 ...x,
        //                 highlight: x.value === action.payload.value ? true : false,
        //                 visible: x.label === 'Visualisations'
        //                     ? action.payload.component.intelligent
        //                     : x.label === 'Reports'
        //                         ? action.payload.component.intelligent
        //                         : true
        //             }
        //         }),
        //         loading: false
        //     }
            case types.CHANGE_MASTER_COMPONENT_TAB:
            return {
                ...state,
                tab: action.payload.value,
                tabs: state.tabs.map(x => {
        
                    return {
                        ...x,
                        highlight: x.value === action.payload.value ? true : false,
                        visible: x.label === 'Visualisations'
                            ? action.payload.component.intelligent
                            : x.label === 'Reports'
                                ? action.payload.component.intelligent
                                : true
                    }
                }),
                loading: false
            }
        default:
            return state
    }
}