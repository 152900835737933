import * as finance from 'financejs'
import * as formulas from 'formulajs'
import moment from 'moment'
import get from 'lodash/get'

import * as types from './types'
import * as headerActions from '../WorkflowQuoteHeader/actions'


export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_TOGGLE_VALUES,
            payload: data
        })
    }
}

export const setHeaderInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch(headerActions.setGridInputValue(data))
    }
}

export const setTotalAdjustment = () => {
    return (dispatch, getState) => {
        dispatch(headerActions.setTotalAdjustment())
    }
}

export const removeSelectedRow = (inx) => {
    return (dispatch, getState) => {
        dispatch(headerActions.removeGridRow(inx))
    }
}

export const getMMCodeToGrid = (mmcode_id,contractperiod,distancepermonth,inx) => {
    return (dispatch, getState) => {
        //console.log('getMMCodeToGrid', mmcode_id)
        dispatch({
            type: types.GET_FML_QUOTE_MMCODE_RATE_CARD_DATA,
            payload: {
                inx: inx,
                type: 'GET_MMCODE',
                contractperiod: contractperiod,
                distancepermonth: distancepermonth,
                mmcode_id: mmcode_id
            }
        })
    }
}

export const setMMCodeToGrid = (reposense,payload) => {
    return (dispatch, getState) => {
        //console.log('setMMCodeToGrid payload', payload)
        //console.log('setMMCodeToGrid reposense', reposense)
        dispatch(headerActions.addVehicleToGrid(reposense[0], payload.inx))
    }
}

export const getMMCodeList = (search, mmcodes, makes) => {
    return (dispatch) => {
        //console.log('getMMCodeListx mmcodes',mmcodes, makes)
        dispatch({
            type: types.GET_QUOTE_COMPONENT_FML_MMCODE_DATA_LIST,
            payload: {
                type: 'GET_FML_MMCODE_DATA_LIST',
                search: search,
                mmcodes: mmcodes,
                makes: makes
            }
        })
    }
}

export const setMMCodeList = (result, payload) => {
    return (dispatch) => {

        //console.log('setMMCodeList result', result)
        //console.log('setMMCodeList payload', payload)
        let mmcode_data = payload.mmcodes ? result.response.data ? result.response.data : result.response : result.response.data ?  result.response.data :  result.response
        //console.log('setMMCodeList mmcode_data', mmcode_data)
        dispatch(setInputValue({prop: 'mmcode_data', value: mmcode_data}))
    }
}

export const getLookupValue = (model, field) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]
        const useratecard = component.data.useratecard.input

        const query = field.parameters ? field.parameters.indexOf('.') > -1
            ? '?' + field.parameters.split('.')[1] + '=' + getState().components[field.parameters.split('.')[0]].data[field.parameters.split('.')[1]].input
            : '?' + field.parameters + '=' + component.data[field.parameters].input
            : ''

        const payload = useratecard ? {
            component: component,
            model: 'mmcode',
            field: field,
            related: '',
            query: query,
            order: '',
        }
            :
            {
                component: component,
                model: model,
                field: field,
                related: field.related && model != 'supplier' ? field.display.split('.')[0] : '',
                query: query,
                order: field.display,
            }

        dispatch({
            type: types.GET_COMPONENT_MMCODE_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const searchLookupValue = (model, field, search) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]

        const query = field.parameters ? field.parameters.indexOf('.') > -1
            ? '?' + field.parameters.split('.')[1] + '=' + getState().components[field.parameters.split('.')[0]].data[field.parameters.split('.')[1]].input + '&mmcode.makemodelyear=' + search
            : '?' + field.parameters + '=' + component.data[field.parameters].input + '&mmcode.makemodelyear=' + search
            : + '' //'&mmcode.makemodelyear=' + search

        const payload = {
            component: component,
            model: model,
            field: field,
            related: field.related && model != 'supplier' ? field.display.split('.')[0] : '',
            query: query,
            order: field.display,
        }

        dispatch({
            type: types.GET_COMPONENT_MMCODE_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const getNonRateCardVehicle = (field, inx, value, text) => {
    
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })

        dispatch({
            type: types.SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name,
                value: text,
            }
        })

        dispatch({
            type: types.GET_NON_RATECARD_DETAILS,
            payload: {
                component: field.component.name,
                field: field,
                inx: inx,
                value: value,
            }
        })
    }
}

export const getNonRateCardYardListVehicle = (field, inx, value, text, yardlist) => {
    
    return (dispatch, getState) => {
    const quoteHeader = getState().quoteHeader
    const vehicledata = quoteHeader.vehicledata.filter(x => x.vehicle_id === value)
        dispatch({
            type: types.SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: vehicledata[0].value,
            }
        })

        dispatch({
            type: types.SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name,
                value: text,
            }
        })

        const component = getState().components[field.component.name]

        const data = component.grid.data
        data[inx].yardlist = yardlist
        data[inx].licenseplateno = text
        data[inx].vehicle_id = value

        dispatch({
            type: types.SET_QUOTEHEADER_GRID_DATA,
            payload: {
                component: component.name,
                data: data
            }
        })

        dispatch({
            type: types.GET_NON_RATECARD_DETAILS,
            payload: {
                component: field.component.name,
                field: field,
                inx: inx,
                value: value,
            }
        })
    }
}

export const setNonRateCardVehicle = (component, inx, payload) => {

    const data = payload.data.map(x => {
        return {
            retailprice: parseFloat(x.amount), //discountedpricing[0].retailprice),
            vehicleprice: parseFloat(x.rt57price), //parseFloat(x.discountedpricing[0].discountprice),
            discount:  parseFloat(x.dprice), //parseFloat(x.discountedpricing[0].retailprice) - parseFloat(x.discountedpricing[0].discountprice),   //?
            mm_code: parseFloat(x.mmcode),
            reg_year: x.regyear,
            deliveryperiod: parseFloat(90),  //?
            rvpercentage: 0, //parseFloat(x.specialpricing[0].rvpercentage1),   //?
            rv_amount: 0, //(parseFloat(x.specialpricing[0].rvpercentage1) / 100 * parseFloat(x.discountedpricing[0].retailprice)),
            primerate: parseFloat(10), //?
            deviation: parseFloat(-1), //?
            maintcpk: 0, //parseFloat(x.specialpricing[0].maintenance_cpk),
            tyrecpk: 0, //parseFloat(x.specialpricing[0].tyres_cpk),
            manualtotalmaintcpk: parseFloat(0), //?
            financeexcess: 0, //parseFloat(x.specialpricing[0].finance_cpk), //?
            interestrate: parseFloat(9), //?
            mmcode_fktext: x.makemodelyear,
            mmcode_id: x.mmcode_id,
            imagefilename: x.imagefilename
        }
    })
    
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FORM_MMCODE_DETAILS,
            payload: {
                component: component,
                inx: inx,
                data: data[0]
            }
        })
    }
}

export const setMMCodeDetails = (component, field, inx, payload) => {
    const data = payload.data.map(x => {
        return {
            retailprice: parseFloat(x.mmcode.amount),
            vehicleprice: parseFloat(x.mmcode.rt57price),
            discount: parseFloat(x.mmcode.dprice),
            reg_year: x.mmcode.regyear,
            mm_code: parseFloat(x.mmcode_id),
            deliveryperiod: parseFloat(x.deliveryperiod),
            rvpercentage: 0, // parseFloat(x.residualvalue),
            rv_amount: 0, //(parseFloat(x.residualvalue) / 100 * parseFloat(x.mmcode.amount)).toFixed(2),
            primerate: parseFloat(x.primerate),
            deviation: parseFloat(x.deviation),
            maintcpk: parseFloat(x.mmcode.maintcpk),
            tyrecpk: parseFloat(x.mmcode.tyrescpk),
            imagefilename: x.imagefilename,
            manualtotalmaintcpk: parseFloat(x.manualtotalmaintcpk),
            financeexcess: parseFloat(x.financeexcess),
            interestrate: parseFloat(x.primerate), //+ parseFloat(x.deviation),
            overall_rv: 0,
            overall_rv_amount: 0,
        }

    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FORM_MMCODE_DETAILS,
            payload: {
                component: component,
                inx: inx,
                data: data[0]
            }
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]
    const _data = payload.data.map(x => {
        return (typeof x.mmcode === 'object') ? x.mmcode : x
    })

    const data = _data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_MMCODE_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data
            }
        })

        
    }
}

export const setQHMMCodeRvAndTyres = (payload) => {
    return (dispatch, getState) => {
        const component = getState().components['surrogateheader']
        const grid = component.grid.data.map(x => {
            const _data = payload.data.filter(m => m.mmcode === x.mm_code.toString())

            return {
                ...x,
                rvpercentage: x.rvpercentage ? parseInt(_data.length ? _data[0].rows[0]._rv : x.rvpercentage) : 0,
                rv_amount: x.rvpercentage ? ((parseInt(_data.length ? _data[0].rows[0]._rv : x.rvpercentage) / 100) * parseFloat(x.retailprice)).toFixed(2) : 0,
                //tyrecpk: _data.length ? _data[0].tyrecpk : x.tyrecpk,
            }
        })

        dispatch({
            type: types.QUOTE_VEHICLE_BLANK_ROW,
            payload: {
                component: component.name,
                data: grid
            }
        })
    }
}

export const setValue = (field, index, value) => {
    return (dispatch, getState) => {
        const payload = {
            component: field.component.name,
            field: field.name,
            index: index,
            value: value
        }
        
        dispatch({
            type: types.SET_QUOTEHEADER_GRID_FIELD_DATA,
            payload: payload
        })


        const component = getState().components[field.component.name]

        const data = component.grid.data
        data[index].vehicleprice = (parseFloat(data[index].retailprice) - parseFloat(data[index].discount)).toFixed(2)
        data[index].rv_amount = ((parseInt(data[index].rvpercentage)/100) * parseFloat(data[index].retailprice)).toFixed(2)
        dispatch({
            type: types.SET_QUOTEHEADER_GRID_DATA,
            payload: {
                component: component.name,
                data: data
            }
        })
    }
}

export const calcValue = (component, i) => {
    return (dispatch, getState) => {
        const data = component.grid.data

        data[i].vehicleprice = (parseFloat(data[i].retailprice) - parseFloat(data[i].discount)).toFixed(2)
        data[i].rv_amount = ((parseInt(data[i].rvpercentage)/100) * parseFloat(data[i].retailprice)).toFixed(2)
  
        dispatch({
            type: types.SET_QUOTEHEADER_GRID_DATA,
            payload: {
                component: component.name,
                data: data
            }
        })
    }
}


export const toggleProps = (prop, value, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_TOGGLE_VALUES,
            payload: {
                prop: prop,
                value: value
            }
        })


        const data = {
            yardlist: value,
            yardlist_vehicle: [],
            retailprice: '',
            vehicleprice: '',
            discount: '',   //?
            mm_code: '',
            reg_year: '',
            deliveryperiod: '',  //?
            rvpercentage: '',   //?
            rv_amount: '',
            primerate: '', //?
            deviation: '', //?
            maintcpk: '',
            tyrecpk: '',
            manualtotalmaintcpk: '', //?
            financeexcess: '', //?
            interestrate: '', //?
            mmcode_fktext: '',
            mmcode_id: '',
            imagefilename: ''
        }
        dispatch({
            type: types.SET_FORM_MMCODE_DETAILS,
            payload: {
                component: 'surrogateheader',
                inx: inx,
                data: data
            }
        })
    }
}


export const searchByMMCode = (field, inx, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_SEARCH_MMCODE_DETAILS,
            payload: {
                field: field,
                value: value,
                inx: inx
            }
        })
    }
}

export const getPrimerate = (payload, field, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CURRENT_PRIMERATE,
            payload: {
                field: field,
                inx: inx,
                data: payload
            }
        })
    }
}

export const setByMMCode = (primerate, payload, field, inx) => {

    const x = payload.data[0]
    const data = {
            retailprice: parseFloat(x.amount).toFixed(2),
            vehicleprice: parseFloat(x.rt57price).toFixed(2),
            discount: parseFloat(x.dprice).toFixed(2),   //?
            reg_year: x.regyear,
            mm_code: x.mmcode,
            deliveryperiod: 90,  //?
            rvpercentage: 0,   //?
            rv_amount: 0.00,
            primerate: parseFloat(primerate.data[0].primerate), //?
            deviation: -1, //?
            maintcpk: parseFloat(x.maintcpk),
            tyrecpk: parseFloat(x.tyrescpk),
            manualtotalmaintcpk: 0, //?
            financeexcess: 0, //?
            interestrate: 9.25, //?
            mmcode_fktext: x.makemodelyear,
            mmcode_id: x.mmcode_id,
            imagefilename: x.imagefilename
        }
    
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FORM_MMCODE_DETAILS,
            payload: {
                component: field.component.name,
                inx: inx,
                data: data
            }
        })

        
        const surrogateheader = getState().components['surrogateheader']
        dispatch({
            type: types.GET_QUOTE_HEADER_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES,
            payload: {
                type: 'GET_QUOTE_HEADER_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES',
                mmcodes: x.mmcode,
                mmcode_id: x.mmcode_id,
                contracttype: surrogateheader.data.contracttype_id.input,
                distancepermonth: surrogateheader.data.distancepermonth.input,
                contractdistance: surrogateheader.data.contractdistance.input,
                contractperiod: surrogateheader.data.contractperiod.input
            }
        })
    }
}

export const getYardList = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_YARD_LIST_VEHICLE_LIST,
            payload: {
                value: value
            }
        })
    }
}

export const setYardList = (payload) => {
    return (dispatch, getState) => {
        const data = payload.data.map(x => {
            return {
                value: x.mmcode_id,
                vehicle_id: x.vehicle_id,
                text: x.licenseplateno + ' ' + x.makemodelyear
            }
        })
        dispatch({
            type: types.SET_COMPONENT_YARD_LIST_VEHICLE_LIST,
            payload: data
        })
        if(data.length)
        dispatch({
            type: types.SET_COMPONENT_YARD_LIST_VEHICLE_LIST_BAK,
            payload: data
        })
    }
}

export const setSelectedYardList = (value, text, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_SELECTED_YARD_LIST_VEHICLE,
            payload:  {
                    value: value,
                    text: text
                }
        })
    }
}

