export const container = {
    width: '100%',
    height: '100%'
}

export const text = {
    display: 'flex',
    flexWrap: 'wrap'
}

export const tile = {
    width: 200,
    height: 200,
    marginRight: 20,
    marginBottom: 20
}

export const card = {
    display: 'flex'
}

export const actions = {
    textAlign: 'right'
}