
export const dummyrun = true
export const finalbilling = false
export const comments = null
export const data = []
export const users = []
export const bccusers = ''
export const billingtracking = []  //billing_sending
export const refreshed = false

export const chkqueued = false
export const chkpending = false
export const chksent = false
export const chkcreated = false
export const confirm_win = false
export const chkfilter = ''
export const filter = ''

export const fields = {
    refreshed: {
        errorText: 'Please Refresh Billing.',
        value: null,
        valid: false,
        empty: true,
    },
    checked: {
        errorText: 'Please Confirm Billing Check',
        value: null,
        valid: false,
        empty: true,
    },
}