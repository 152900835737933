

export const GET_CREDIT_NOTE_LIST = 'GET_CREDIT_NOTE_LIST'
export const GET_DEBTORS_LIST = 'GET_DEBTORS_LIST'
export const GET_UNIT_MEASURE_LIST = 'GET_UNIT_MEASURE_LIST'
export const GET_BUSINESS_LIST = 'GET_BUSINESS_LIST'
export const GET_CONTRA_ACCOUNT_LIST = 'GET_CONTRA_ACCOUNT_LIST'
export const GET_ITEM_ACCOUNT_LIST = 'GET_ITEM_ACCOUNT_LIST'

export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'
export const SET_ANY_ITEM_INPUT_VALUE = 'SET_ANY_ITEM_INPUT_VALUE'

export const SAVED_CREDIT_NOTE_DATA = 'SAVED_CREDIT_NOTE_DATA'
export const SAVE_CREDIT_NOTE_DATA = 'SAVE_CREDIT_NOTE_DATA'

export const ADD_ANY_BLANK_ROW = 'ADD_ANY_BLANK_ROW'

export const REMOVE_ANY_ITEM_ROW = 'REMOVE_ANY_ITEM_ROW'
export const GET_DUPLICATE_REFERENCE_CHECK = 'GET_DUPLICATE_REFERENCE_CHECK'
export const DUPLICATE_FOUND = 'DUPLICATE_FOUND'
export const NO_DUPLICATE_FOUND = 'NO_DUPLICATE_FOUND'
export const GEN_CUSTOMER_CREDIT_NOTE_PDF = 'GEN_CUSTOMER_CREDIT_NOTE_PDF'
export const SET_CUSTOMER_CREDIT_NOTE_PDF = 'SET_CUSTOMER_CREDIT_NOTE_PDF'
