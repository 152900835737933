import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
// import BusinessIcon from "@mui/icons-material/Business";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
import Modal from '@mui/material/Modal';
import Badge from "@mui/material/Badge";
// import Tooltip from "@mui/material/Tooltip";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import HomeIcon from '@mui/icons-material/Home';

import { emphasize, styled } from '@mui/material/styles';

// import { Container, Row, Col } from 'react-grid-system'
import { Tree, TreeNode } from "react-organizational-chart";
// import Icon from '../Icon/component'

import Section from '../../controls/section'
// import View from '../View/component'
import * as actions from './actions'
import * as styles from './styles'



const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  }); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

class Hierarchy extends Component {

    componentDidMount() {
        this.props.actions.getUserCustomer()
    }


    getLabel(data, haschildren, countchildren) {
    return<div>
            
        <Card
  variant="outlined"
  style={styles.root}
>
  <CardHeader
   style={{ backgroundColor: "#D1E0E6", borderColor: 'silver'}}
    avatar={
      countchildren ? <Badge
              style={{ cursor: "pointer" }}
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              showZero
              invisible={!data.collapse}
              overlap="circle"
              badgeContent={countchildren}
              //onClick={onCollapse}
            >
                 <Avatar style={{backgroundColor: "#ECECF4"}}>
        <AccountBalanceIcon color="primary" />
      </Avatar>
            </Badge>
            : <Avatar style={{backgroundColor: "#ECECF4"}}>
            <AccountBalanceIcon color="primary" />
          </Avatar>
     
    }
    title={data.customername}
    subheader={data.costcentre}
    action={
        <IconButton size="small" onClick={(event) => {this.props.actions.contextMenu(data.customer_id, event.currentTarget)}}>
          <MoreVertIcon />
        </IconButton>
      }
  />
    <Menu open={data.contextmenu} anchorEl={this.props.state.anchorEl} onClose={() => {this.props.actions.contextMenu(0)}}>
    <MenuItem onClick={() => {this.props.actions.contextMenu(0);this.props.actions.toggleDetailWin(data.customer_id)}}>
        <ListItemIcon>
        <AccountBalanceIcon color="secondary" />
        </ListItemIcon>
        <ListItemText primary="View Details" />
    </MenuItem>
    </Menu>
    <Modal
    open={data.detail_win}
    style={{margin: 'auto', top: '100px', height: '70%', overflow: 'auto'}}
    onClose={() => {this.props.actions.toggleDetailWin(0)}}
    >
   <div style={styles.paper} >
    {"Loading"}
    {/* <View
            key={'customer_detail_win_' + data.customer_id}
            id={data.customer_id}
            name={'customer'}
            expanded={true}
            surrogate={false}
        /> */}
    </div>
    </Modal>
  {
    haschildren && <IconButton
        size="small"
        onClick={() => {this.props.actions.toggleCollapse(data.customer_id)}}
      >
       { data.collapse ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
      </IconButton>
    }
</Card>
</div>
}
    getChildren(data) {
          return data.map(x => {
            
              let _tot = this.countChildrens(x.customer_id,0)
              let children = this.props.state.tree.filter(t => t.parent_id === x.customer_id)
          return x.collapse ? <TreeNode label={this.getLabel(x, children.length ? true : false, _tot)}>
          </TreeNode>
          : <TreeNode label={this.getLabel(x, children.length ? true : false, _tot)}>
          {this.getChildren(children)}
      </TreeNode>
      })
    }

    recChildrens(customer_id, count) {
      let children = this.props.state.tree.filter(t => t.parent_id === customer_id)
      count = count + (children.length ? children.length : 1)
      for(let x of children){
          //count = count + 1
          this.recChildrens(x.customer_id,count)
      }
      return count
  }

    countChildrens(customer_id, count) {
        let children = this.props.state.tree.filter(t => t.parent_id === customer_id)
        count = count + children.length
        for(let x of children){
          count = count + this.recChildrens(x.customer_id,count)
        }
        return 0
    }

    recurse (customer_id, count) {
      let childrens = this.props.state.tree.filter(t => t.parent_id === customer_id)
     // count = count + childrens.length
          //console.log('recurse cust >>>>>>>>>>>>>>> ', customer_id, childrens.length, count)
      for(let x of childrens){
          let rec = this.props.state.tree.filter(r => r.parent_id === x.customer_id)
       
          count = count + 1
          //console.log('recurse cust ', x.customername, rec.length, count)
          if(rec.length) { 
            //count = count + rec.length
            count = count + this.recurse(x.customer_id,count)
          }
          
      }
      return count
  }


    buildTree(data) {
        return  data.map(x => {
          
          let _tot = this.recurse(x.customer_id,0)
          let children = this.props.state.tree.filter(t => t.parent_id === x.customer_id)
        return x.collapse ? <Tree
        label={this.getLabel(x,true, children.length)}
        lineWidth={"2px"}
        width={"300px"}
        lineColor={"#bbc"}
        lineBorderRadius={"12px"}
        >
      </Tree>
      : <Tree
      label={this.getLabel(x,true, children.length)}
      lineWidth={"2px"}
      width={"300px"}
      lineColor={"#bbc"}
      lineBorderRadius={"12px"}
      >
          {this.getChildren(children)}
    </Tree>
        }
      )
      }

      getBreadcrumbs() {
        return (
            <div >
            <Section title={'Organisation Structure'} subtitle={'My Organisation Hierarchical Structure'}  />
            <br />
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                component="a"
                href="#"
                label="Home"
                onClick={() => {
                    //console.log('')
                    // this.props.actions.setView('delete','grid')
                }}
                icon={<HomeIcon fontSize="small" />}
                />
                { this.props.state.breadcrumb && <StyledBreadcrumb label={this.props.state.breadcrumb} /> }
            </Breadcrumbs>
            </div>
        )
    }
    render() {
         let parent = this.props.state.tree.filter(x => !x.parent_id)
        return (
            <div style={{padding: '5px'}}>
            <div style={{width: '99%', height: '100%', overflow: 'auto'}}>
                        {this.buildTree(parent)}
                    </div>
                </div>
           
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.hierarchy }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hierarchy)