import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import * as actions from './actions'
import * as styles from './styles'

import Loading from '../Loading/component'
import PdfViewer from '../PDF/component'

class WorkflowDownloadVAFDocument extends Component {
    // componentDidMount() {
    //     const filename = workflow_queue_data.context_data.data.data.data[0].data[0].context_data.data.data.data[0].data.document.filename
    //     this.props.actions.getQuotationPDF({ key: 'quotes/' + filename })
    // }

    getSupplier(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('OEM Details')}
                subtitle={window.t.en('Vehicle supplier')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliername}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliertype}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registrationnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'VAT number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vatnumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier BBE Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.beetype}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier BBE Level:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.beelevel}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getDealer(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Dealer Details')}
                subtitle={window.t.en('Dealer details')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliername}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliertype}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registrationnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'VAT number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vatnumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer BBE Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.beetype}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer BBE Level:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.beelevel}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer Contact:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliercontact.length ? data.suppliercontact[0].contact.fullname : '' }</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer Email:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliercontact.length ? data.suppliercontact[0].contact.email : '' }</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer Mobile:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliercontact.length ? data.suppliercontact[0].contact.mobile : '' }</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer Phone:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliercontact.length ? data.suppliercontact[0].contact.telephone_work : '' }</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer Town:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.supplieraddress.length ? data.supplieraddress[0].address.town : ''}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Dealer Address:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.supplieraddress.length ? data.supplieraddress[0].address.line1 : ''}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getCustomer(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Customer Name:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{data.customername}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Cost Centre:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.costcentre}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Registered Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registeredname}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Account Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.accountnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.compregistrationnumber}</Col>
                    </Row>
                    {
                        data.contact.map(x => {
                            return <div>
                                <Row>
                                    <Col>
                                        <br/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={3} style={styles.fieldLabel}>{'Contact Person:'}</Col>
                                    <Col xl={3} style={styles.selectedFieldName}>{x.fullname}</Col>
                                    <Col xl={3} style={styles.fieldLabel}>{'Contact Email:'}</Col>
                                    <Col xl={3} style={styles.selectedFieldName}>{x.email}</Col>
                                </Row>
                                <Row>
                                    <Col xl={3} style={styles.fieldLabel}>{'Telephone Number:'}</Col>
                                    <Col xl={3} style={styles.selectedFieldName}>{x.telephone_home}</Col>
                                    <Col xl={3} style={styles.fieldLabel}>{'Mobile Telephone:'}</Col>
                                    <Col xl={3} style={styles.selectedFieldName}>{x.mobile}</Col>
                                </Row>
                            </div>
                        })
                    }
                </Container>
            </CardContent>
        </Card>
    }

    getVehicle(data, oem) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Requested vehicle on the quote')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{data.makemodelyear}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.make}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Delivery Period:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.deliveryperiod}</Col>
                        {/* <Col xl={3} style={styles.fieldLabel}>{'Vehicle First Registration Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.regyear}</Col> */}
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Fuel Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fueltype}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Status:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{''}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'VIN Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{oem.comments}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Receipt Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{moment(oem.date).format('DD/MM/YYYY')}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }


    getDownlaodDocs(documents) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Download Forms')}
                subtitle={window.t.en('Download all documents for new vehicle inspection')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    {
                        !documents
                            ? <Loading message={'Retrieving Purchase Order document...'} />
                            : <PdfViewer
                                key={'document_viewer_' + documents.filename}
                                name={'purchaseorder'}
                                workflow={'fml_customer_quote'}
                                task={'tsk_fml_quote_waiting_vehicle_delivery'}
                                documents={documents}
                                edit={false}
                                workflow_queue_data={this.props.workflow_queue_data}
                            />
                    }
                </Container>
            </CardContent>
        </Card>
    }

    getOldVehicle(contract) {
        return contract.oldvehicle
        ? <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Old Vehicle Details')}
                subtitle={window.t.en('Replaced vehicle details')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Customer Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{contract.oldvehicle[0].customername}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Region:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{contract.oldvehicle[0].businessname}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{contract.oldvehicle[0].licenseplateno}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Contract Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{contract.oldvehicle[0].customercontract}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Fleet Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{contract.oldvehicle[0].fleetno}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Odometer as Per Last Billing:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{contract.oldvehicle[0].closingodometer}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Replacement For:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{contract.oldvehicle[0].licenseplateno}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model to Be Replaced:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{contract.oldvehicle[0].makemodelyear}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Titleholder:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>
                            {
                                contract.oldvehicle[0].fundername 
                                    ? contract.oldvehicle[0].fundername 
                                    : 'Not Set'
                            }
                        </Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Current Vehicle Status:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{contract.oldvehicle[0].vehiclestatus}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
        : ''
    }

    getView(data, oem, documents) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Confirm Vehicle Delivery')}
                subtitle={window.t.en('Please approve this task to indicate that the correct vehicle was delivered to the dealership.')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                {this.getSupplier(data.confirm.supplier[0])}
            </CardContent>
            <CardContent expandable={false}>
                {this.getDealer(data.confirm.dealer[0])}
            </CardContent>
            <CardContent expandable={false}>
                {this.getCustomer(data.customer)}
            </CardContent>
            <CardContent expandable={false}>
                {this.getVehicle(data.confirm.vehicle, oem)}
            </CardContent>
            <CardContent expandable={false}>
                {this.getOldVehicle(data.confirm.vehicle.contract)}
            </CardContent>
            <CardContent expandable={false}>
                {this.getDownlaodDocs(documents)}
            </CardContent>
        </Card>
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        let oem = null
        let data = null
        let documents = []

        try {
            oem = workflow_queue_data.context_data.data.data.data[0].OEM
            const pid = workflow_queue_data.context_data.data.data.data[0].pid
            const _data = workflow_queue_data.context_data.data.data.data[0].data[0].context_data.data.data.data[0]
            data = _data.data.data

            if(pid)
                documents.push(pid)
            if(_data.signscheduler1)
                documents.push(_data.signscheduler1)
            const rfq = workflow_queue_data.context_data.data.data.data[0].data[0].context_data.data.data.data[0].data.data.confirm.data[0].data.documents.filter(x => x.documenttype === 'rfq')
            if(rfq.length)
                documents.push(rfq[0])
        } catch (err) {

        }

        //.data[0].context_data.data.data.data[0].data.document

        return (
            data
                ? this.getView(data, oem, documents)
                : 'Loading, Please wait...'
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            component: {
                ...state.components[ownProps.name]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDownloadVAFDocument)