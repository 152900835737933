import * as types from './types'
import moment from 'moment'
import validator from '../../validate'

import * as appTypes from '../App/types'
import { itemlist } from './props';
import { field } from '../../controls/props';



export const showNotification = (expirydate) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Price Expired',
                type: 'danger',
                additionalText: 'Accessory price has expired on ' + expirydate,
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FML_QUOTATION_ITEMS_INPUT_VALUE,
            payload: data
        })
    }
}

export const addBlankRow = () => {
    return (dispatch, getState) => {
        let data = getState().workflowQuoteItemGrid.data
        data.push({})
        dispatch(setInputValue({prop: 'data', value: data}))
    }
}

export const addChipRow = (component, accessory) => {
    return (dispatch, getState) => {
        let data = getState().workflowQuoteItemGrid.data
        data.push({
            accessory: accessory.accessoryname,
            accessory_id: accessory.accessory_id,
            costprice: accessory.retailprice,

            itemtype: 'accessory',
            itemtype_id: 1,

            quantity: 1,
            sellingprice: accessory.sellingprice,
            totalprice: accessory.sellingprice,
        })
        dispatch(setInputValue({prop: 'data', value: data}))
    }
}

export const deleteItemRow = (inx) => {
    return (dispatch, getState) => {
        let _data = []
        let data = getState().workflowQuoteItemGrid.data
        data.map((x,i) => {
            if(i != inx)
            _data.push(x)
        })
        //console.log('deleteItemRow data' ,data)
        //console.log('deleteItemRow _data' ,_data)
        dispatch(setInputValue({prop: 'data', value: _data}))
    }
}

export const setGridFieldValue = (field, value, inx) => {
    return (dispatch, getState) => {
        let data = getState().workflowQuoteItemGrid.data
        data[inx][field] = value
        dispatch(setInputValue({prop: 'data', value: data}))
        dispatch(calcTotalPrice(inx))
    }
}

export const calcTotalPrice = (inx) => {
    return (dispatch, getState) => {
        let data = getState().workflowQuoteItemGrid.data
        data[inx]['totalprice'] = (parseFloat(data[inx]['quantity']) * parseFloat(data[inx]['sellingprice'])).toFixed(2)
        dispatch(setInputValue({prop: 'data', value: data}))
    }
}

export const getLookupData = (component, field, search,query) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_FML_QUOTATION_ITEMS_DATA_LIST,
            payload: {
                component: component,
                field: field,
                search: search,
                query:query
            }
        })
    }
}


export const getLookupItemsData = (data, search,) => {
    return (dispatch, getState) => {
        //'supplieraccessorypricing.accessory,supplier.accessoryname,suppliername,sellingpricing',
        let suppliers_ids = getState().workflowQuoteItemGrid.supplierdistance_data.map(x => x.supplier_id)
        let _data = data.split('.')
        dispatch({
            type: types.GET_COMPONENT_FML_QUOTATION_ITEMS_LOOKUP_DATA,
            payload: {
                component: _data[0],
                related: _data[1],
                field: _data[2],
                search: search,
                supplier_id: suppliers_ids.length ? suppliers_ids.join('.') : ''
            }
        })
    }
}

export const setLookupItemsData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setLookupItemsData response', response)
        //console.log('setLookupItemsData payload', payload)
        //console.log('setLookupItemsData data', response.data)
        let data = response.map(x => {
            let field = payload.field.split(',')
            return {
                ...x,
                [field[0].includes('#') ? field[0].split('#')[1] : field[0]]: field[0].includes('#') ? x[field[0].split('#')[0]] ? x[field[0].split('#')[0]][field[0].split('#')[1]] : '' : x[field[0]],
                [field[1].includes('#') ? field[1].split('#')[1] : field[1]]: field[1].includes('#') ? x[field[1].split('#')[0]] ? x[field[1].split('#')[0]][field[1].split('#')[1]] : '' : x[field[1]],
                [field[2].includes('#') ? field[2].split('#')[1] : field[2]]: field[2].includes('#') ? x[field[2].split('#')[0]] ? x[field[2].split('#')[0]][field[2].split('#')[1]] : '' : x[field[2]]
            }
        })
        dispatch(setInputValue({prop: payload.component + '_data', value:  data}))
    }
}

export const loadChipAccessory = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_FML_QUOTATION_CHIP_DATA_LIST
        })
    }
}
export const setChipAccessory = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FML_QUOTATION_CHIP_DATA_LIST,
            payload: data.data
        })
    }
}
export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setLookupData response', response)
        //console.log('setLookupData payload', payload)
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.component + '_id']
            }
        })
        
        dispatch(setInputValue({prop: payload.component + '_data', value: data}))
    }
}
export const getSupplierData = () => {
    return (dispatch, getState) => {
            dispatch({
                type: types.GET_SUPPLIER_DATA,
                payload: {
                    type: 'GET_SUPPLIERS',
                }
            })
    }
}
export const setSupplierData = (response, payload) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'supplier_data', value: response}))
    }
}

export const getSupplierDistanceData = (supplier_id,distance,row) => {
    return (dispatch, getState) => {
            dispatch({
                type: types.GET_SUPPLIER_DISTANCE_DATA,
                payload: {
                    type: 'get_supplierswithdistances',
                    supplier_id: supplier_id,
                    distance: distance,
                    row:row
                }
            })
    }
}

export const setSupplierDistanceData = (response, payload) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'supplierdistance_data', value: response}))
     
    }
}
export const getSupplierDataAccessoryData = (accessory_id, row) => {
    return (dispatch, getState) => {
            dispatch({
                type: types.GET_SUPPLIER_ACC_DATA,
                payload: {
                    type: 'GET_SUPPLIERSACC',
                    accessory_id: accessory_id,
                    row:row
                }
            })
    }
}


export const setSupplierDataAccessoryData = (response, payload) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'supplieraccessory_data', value: response}))
        dispatch(setGridFieldValue('supplieraccessory_data',  response,payload.row))
    }
}

export const getSupplierAccessoryHistoryData = (supplier_id,accessory_id,row) => {
    return (dispatch, getState) => {
            dispatch({
                type: types.GET_SUPPLIER_HISTORY__DATA,
                payload: {
                    type: 'get_supplieraccessoryhistorydata',
                    supplier_id: supplier_id,
                    accessory_id: accessory_id,
                    row:row
                }
            })
    }
}
export const setSupplierAccessoryHistoryData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setSupplierAccessoryHistoryDataxxx response',response)
        dispatch(setInputValue({prop: 'suppliersacessoryhistory_data', value: response}))
        dispatch(setGridFieldValue('suppliersaccessoryhistory_data',response,payload.row))  
    }
}

