import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../../Icon/component'




import Checkbox from '@mui/material/Checkbox'
import Upload from '../../Upload/component'
import TextField from '@mui/material/TextField'
import * as actions from './actions'
import * as styles from './styles'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'
import ImageUploader from '../../ImageUploader/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'

class DefleetTerminationRequestApprovalGM extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }

    VehicleDetails(data) {
        return <Card>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('Vehicle Information: ' + data.businessname)}
                subtitle={window.t.en('Selected vehicle details.')}
                icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                style={styles.row}
            >
            </CardHeader>
            <CardContent>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.licenseplateno}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Fleet Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fleetno}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Status:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vehiclestatus}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Odometer:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.closingodometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.make}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mgdescription}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mmcode}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle First Registration Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registrationdate ? moment(data.registrationdate).format('DD-MM-YYYY') : data.registrationdate}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Chassis Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.chassisnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Engine Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.enginenumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Fuel Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fueltype}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Colour:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.color}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Funder:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fundername? data.fundername: 'Not Set'}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Supplier (OEM):'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliername ? data.suppliername: 'Not Set'}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    ServiceDealer(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Dealer Information: ')}
                    subtitle={window.t.en('Vehicle service dealer details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'contract_vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            data.map(x => {
                                return <div>
                                    <Row>
                                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.suppliername}</Col>
                                        <Col xl={3} style={styles.fieldLabel}>{'BEE Level:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beelevel}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Registration:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.registrationnumber}</Col>
                                        <Col xl={3} style={styles.fieldLabel}>{'BBE Score:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beescore}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={3} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.vatnumber}</Col>
                                        <Col xl={3} style={styles.fieldLabel}>{'BBE Type Number:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beetype}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={3} style={styles.fieldLabel}>{'BBE Certificate Received:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beecertificatereceived ? x.beecertificatereceived : 'Not Set'}</Col>
                                        <Col xl={3} style={styles.fieldLabel}>{'BEE Certificate Expry Date:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beecertificateexpdate ? x.beecertificateexpdate.split('T')[0] : 'Not Set'}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}><br /></Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <AdhocComponentFieldInput
                                                title={window.t.en('')}
                                                description={''}
                                                icon={''}
                                                id={'suppliercontact'}
                                                parent={'suppliercontact'}
                                                parentFilters={[
                                                    {
                                                        field: 'supplier_id',
                                                        value: x.supplier_id
                                                    }
                                                ]}
                                                child={'contact'}
                                                childFilters={[
                                                    {
                                                        field: 'contacttype',
                                                        value: 'Accounts2'
                                                    }
                                                ]}
                                                extractChildData={true}
                                                adhocFieldInput={[ //assumes Parent component fields if extractChild is set to false
                                                    'fullname', 'email', 'mobile', 'contacttype'
                                                ]}
                                                selectMode={false}
                                                addMode={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>{''}</Col>
                                    </Row>
                                </div>
                            })
                        }
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    defleetDocuments(documents) {
        return <Card>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('De-fleet Documents')}
                subtitle={window.t.en('De-fleet termination pack')}
                icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                style={styles.row}
            >
            </CardHeader>
            <CardContent>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <PdfViewer
                                key={'quotation_viewer_'}
                                documents={documents}
                                height={600}
                                multiple={false}
                                edit={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col><br /></Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    uploadInvoice(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Upload Termination Request')}
                    subtitle={window.t.en('Upload signed termination request.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'Signed_termiantion_request_' + data.licenseplateno + '_'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }


    commentsView(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Termination Comments')}
                    subtitle={window.t.en('Capture comments')}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Termination Date:'}</div>
                                    <div style={styles.fieldContent}>
                                    <TextField
                                            id={'txtcomment'}
                                            disabled={true}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={moment(data.incidentdate.value).format('YYYY-MM-DD')}
                                            onChange={(event, value) => {                                                
                                            }}
                                        />
                                </div>
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Termination Odo:'}</div>
                                    <div style={styles.fieldContent}>
                                    <TextField
                                            id={'txtcomment'}
                                            disabled={true}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.millage.value}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                </div>
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Comments:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtcomment'}
                                            hintText={'Enter Comments'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.comments}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('comments', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    renderView(data) {
        //console.log("renderView(data) {")
        //console.log(data)
        let steps = data
        let step1 = steps.step1.data 
        let step3 = steps.step3.data 
        let vehicle = step1.selectedVehicleDetails.data[0]
        let supplier = step3.selectedSupplierDetails.data

        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('')}
                    subtitle={window.t.en('')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {this.VehicleDetails(vehicle)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.ServiceDealer(supplier)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.defleetDocuments(data.documents)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.commentsView(step3)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <ImageUploader prefix={'img'} id={'img'} />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) {}
        return (
            workflow_queue_data
                ? this.renderView(workflow_queue_data)
                : <Loading Message={'Loading, Please wait...'} />
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetTerminationRequestApprovalGM
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetTerminationRequestApprovalGM)