import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.TOGGLE_AUTOCOMPLETE_INFO_SHEET:
        return {
            ...state,
            info: action.payload,
        }
        case types.TOGGLE_VEHICLE_HISTORY_VIEW_PDF:
        //console.log('TOGGLE_VEHICLE_HISTORY_VIEW_PDF',action.payload)
        return {
            ...state,
            showhistory: action.payload,
        }
        case types.SET_WORKFLOW_TASK_STEPS:
        //console.log('SET_WORKFLOW_TASK_STEPS',action.payload)
        return {
            ...state,
            workflow_stepper: action.payload,
        }
        case types.SET_CONTROL_VIEW_VEHICLE_DATA_BY_ID:
        return {
            ...state,
            vehicle: action.payload,
            vehicle_loading: false
        }
        case types.SET_CURRENT_TAB_VIEW:
        return {
            ...state,
            tab: action.payload
        }
        case types.SET_CONTROL_VIEW_AUDIT_DATA:
        return {
            ...state,
            auditdata: action.payload
        }
        case types.SET_CONTROL_VIEW_CUSTOMER_DATA_BY_ID:
        return {
            ...state,
            customer: action.payload,
        }
        case types.SET_CONTROL_VIEW_CUSTOMER_ADDRESS:
        return {
            ...state,
            customeraddress: action.payload,
        }
        case types.SET_MAPO_VEHICLE_SERVICE_PLAN:
        return {
            ...state,
            serviceplan: action.payload,
        }
        case types.SET_CONTROL_TREE_SELECTION_EXPANDED_LIST:
        return {
            ...state,
            expand_list: action.payload,
        }
        case types.SET_CONTROL_TREE_SELECTION_CHECKED_LIST:
        return {
            ...state,
            checked_list: action.payload,
        }
        case types.SET_TREE_VIEW_INIT_DATA:
        return {
            ...state,
            hierarchy: action.payload,
        }
        case types.TOGGLE_VEHICLE_SERVICE_HISTORY_VIEW:
        return {
            ...state,
            service_only: action.payload,
        }
        case types.TOGGLE_CALLAPSE_CONTENT_CARD:
        return {
            ...state,
            content_collapse: action.payload,
        }
        case types.TOGGLE_ADD_NEW_ITEM_WINDOW:
        return {
            ...state,
            additem_win: action.payload.status,
            additem_name: action.payload.name,
        }
        case types.SET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID:
        return {
            ...state,
            supplier: action.payload,
        }
        case types.SET_SUPPLIER_ACCESSORY_HISTORY_DATA:
         return {
            ...state,
            supplieracessorypricing_history: action.payload
            }
        case types.SET_CONTROL_VIEW_CONTRACT_DATA_BY_ID:
        return {
            ...state,
            contract: action.payload,
        }
        case types.SET_CONTROL_VIEW_MAINTENANCE_DATA_BY_ID:
        return {
            ...state,
            maintenance: action.payload,
        }
        case types.SET_CONTROL_VIEW_QUOTE_DATA_BY_ID:
            return {
                ...state,
                quotation: action.payload,
            }
        case types.SET_CONTROL_VIEW_MULTI_MAINTENANCE_DATA_BY_ID:
        return {
            ...state,
            multimaintenance: action.payload,
        }
        case types.SET_CONTROL_VIEW_SUPPLIER_TRANSACTION:
        return {
            ...state,
            supplier_transactions: action.payload,
        }
        case types.SET_CUSTOMER_HIE:
        return {
            ...state,
            [action.payload.prop]: action.payload.data,
        }
        case types.SET_CUSTOMER_DELIVERY_ADDRESS_INPUT:
        return {
            ...state,
            [action.payload.prop]: action.payload.value,
        }
        case types.SET_HIERARCHY_SELECTION_VALUE:
        return {
            ...state,
            [action.payload.prop]: action.payload.data,
        }
        
        case types.SET_SUPPLIER_MONTHLY_SPEND:
        return {
            ...state,
            supplier_monthly_spend: action.payload.spend,
            supplier_in_limit: 15000000,
        }
        default:
            return state
    }
}