import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_BILLING_VALUE_CHANGE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_BILLING_PREP_IMPORTED_DATA:
            return {
                ...state,
                datarows: action.payload
            }
        case types.RESET_BILLING_VALUES:
            return {
                ...state,
                dummyrun: true,
                finalbilling: false,
                comments: '',
                datarows: [],
                excessbilling: false,
                document: null
            }
        case types.SET_BILLING_PREP_UPLOADED_EXCEL_FILE_NAME:
            return {
                ...state,
                document: action.payload
            }
        case types.SET_BILLING_PREP_UPLOADED_EXCEL_FILE_BUSY:
            return {
                ...state,
                busy: action.payload
            }
        case types.UPLOAD_BILLING_PREP_EXCEL_FILE_DATA_DONE:
            return {
                ...state,
                uploading: action.payload
            }
        default:
            return state
    }
}