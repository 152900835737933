export const TOGGLE_AUTOCOMPLETE_INFO_SHEET = 'TOGGLE_AUTOCOMPLETE_INFO_SHEET'

export const GET_CONTROL_VIEW_VEHICLE_DATA_BY_ID = 'GET_CONTROL_VIEW_VEHICLE_DATA_BY_ID'
export const SET_CONTROL_VIEW_VEHICLE_DATA_BY_ID = 'SET_CONTROL_VIEW_VEHICLE_DATA_BY_ID'

export const GET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID = 'GET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID'
export const SET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID = 'SET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID'

export const GET_CONTROL_VIEW_CONTRACT_DATA_BY_ID = 'GET_CONTROL_VIEW_CONTRACT_DATA_BY_ID'
export const SET_CONTROL_VIEW_CONTRACT_DATA_BY_ID = 'SET_CONTROL_VIEW_CONTRACT_DATA_BY_ID'

export const GET_CONTROL_VIEW_CUSTOMER_DATA_BY_ID = 'GET_CONTROL_VIEW_CUSTOMER_DATA_BY_ID'
export const SET_CONTROL_VIEW_CUSTOMER_DATA_BY_ID = 'SET_CONTROL_VIEW_CUSTOMER_DATA_BY_ID'

export const GET_CONTROL_VIEW_MULTI_MAINTENANCE_DATA_BY_ID = 'GET_CONTROL_VIEW_MULTI_MAINTENANCE_DATA_BY_ID'
export const SET_CONTROL_VIEW_MULTI_MAINTENANCE_DATA_BY_ID = 'SET_CONTROL_VIEW_MULTI_MAINTENANCE_DATA_BY_ID'

export const GET_CONTROL_VIEW_MAINTENANCE_DATA_BY_ID = 'GET_CONTROL_VIEW_MAINTENANCE_DATA_BY_ID'
export const SET_CONTROL_VIEW_MAINTENANCE_DATA_BY_ID = 'SET_CONTROL_VIEW_MAINTENANCE_DATA_BY_ID'

export const GET_CONTROL_VIEW_QUOTE_DATA_BY_ID = 'GET_CONTROL_VIEW_QUOTE_DATA_BY_ID'
export const SET_CONTROL_VIEW_QUOTE_DATA_BY_ID = 'SET_CONTROL_VIEW_QUOTE_DATA_BY_ID'

export const TOGGLE_VEHICLE_HISTORY_VIEW_PDF = 'TOGGLE_VEHICLE_HISTORY_VIEW_PDF'
export const TOGGLE_VEHICLE_SERVICE_HISTORY_VIEW = 'TOGGLE_VEHICLE_SERVICE_HISTORY_VIEW'

export const SET_SUPPLIER_MONTHLY_SPEND = 'SET_SUPPLIER_MONTHLY_SPEND'
export const GET_SUPPLIER_MONTHLY_SPEND = 'GET_SUPPLIER_MONTHLY_SPEND'
export const TOGGLE_ADD_NEW_ITEM_WINDOW = 'TOGGLE_ADD_NEW_ITEM_WINDOW'
export const TOGGLE_CALLAPSE_CONTENT_CARD = 'TOGGLE_CALLAPSE_CONTENT_CARD'

export const GET_WORKFLOW_TASK_STEPS = 'GET_WORKFLOW_TASK_STEPS'
export const SET_WORKFLOW_TASK_STEPS = 'SET_WORKFLOW_TASK_STEPS'

export const GET_CUSTOMER_HIE = 'GET_CUSTOMER_HIE'
export const SET_CUSTOMER_HIE = 'SET_CUSTOMER_HIE'
export const SET_CURRENT_TAB_VIEW = 'SET_CURRENT_TAB_VIEW'
export const SET_HIERARCHY_SELECTION_VALUE = 'SET_HIERARCHY_SELECTION_VALUE'
export const SET_TREE_VIEW_INIT_DATA = 'SET_TREE_VIEW_INIT_DATA'
export const SET_CONTROL_TREE_SELECTION_CHECKED_LIST = 'SET_CONTROL_TREE_SELECTION_CHECKED_LIST'
export const SET_CONTROL_TREE_SELECTION_EXPANDED_LIST = 'SET_CONTROL_TREE_SELECTION_EXPANDED_LIST'
export const GET_CONTROL_FORM_TREE_DATA = 'GET_CONTROL_FORM_TREE_DATA'
export const GET_MAPO_VEHICLE_SERVICE_PLAN = 'GET_MAPO_VEHICLE_SERVICE_PLAN'
export const SET_MAPO_VEHICLE_SERVICE_PLAN = 'SET_MAPO_VEHICLE_SERVICE_PLAN'

export const GET_CONTROL_VIEW_FORM_AUTOCOMPLETE_DATA = 'GET_CONTROL_VIEW_FORM_AUTOCOMPLETE_DATA'
export const SET_CONTROL_VIEW_INPUT_DATA = 'SET_CONTROL_VIEW_INPUT_DATA'

export const GET_CONTROL_VIEW_SUPPLIER_TRANSACTION = 'GET_CONTROL_VIEW_SUPPLIER_TRANSACTION'
export const SET_CONTROL_VIEW_SUPPLIER_TRANSACTION = 'SET_CONTROL_VIEW_SUPPLIER_TRANSACTION'
export const GET_SUPPLIER_ACCESSORY_DATA = 'GET_SUPPLIER_ACCESSORY_DATA'
export const SET_SUPPLIER_ACCESSORY_HISTORY_DATA = 'SET_SUPPLIER_ACCESSORY_HISTORY_DATA'
export const GET_CONTROL_VIEW_MAINTENANCE_DATA_BY_TASKNO_ID = 'GET_CONTROL_VIEW_MAINTENANCE_DATA_BY_TASKNO_ID'

export const GET_CONTROL_VIEW_AUDIT_DATA = 'GET_CONTROL_VIEW_AUDIT_DATA'
export const SET_CONTROL_VIEW_AUDIT_DATA = 'SET_CONTROL_VIEW_AUDIT_DATA'

export const GET_CONTROL_VIEW_CUSTOMER_ADDRESS = 'GET_CONTROL_VIEW_CUSTOMER_ADDRESS'
export const SET_CONTROL_VIEW_CUSTOMER_ADDRESS = 'SET_CONTROL_VIEW_CUSTOMER_ADDRESS'

export const SET_CUSTOMER_DELIVERY_ADDRESS_INPUT = 'SET_CUSTOMER_DELIVERY_ADDRESS_INPUT'
export const UPDATE_CUSTOMER_DELIVERY_ADDRESS = 'UPDATE_CUSTOMER_DELIVERY_ADDRESS'

