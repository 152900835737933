export const filepreview = ''
export const filename = ''
export const comments = ''
export const completeddate = null
export const documents = []

export const invoicedate = ''
export const invoice_number = ''
export const invoice_amount = ''
export const isinvoice_uploaded = false
export const odometer = '0'
export const checksheet_completedby = ''
export const checksheet_completeddate = null
export const date_in_for_repairs = null
export const days_in_for_repairs = '0'
export const days_over = '0'
export const invoice_supplier = false
export const supplier_rate = '0'
export const supplier_inv_comments = ''
export const supplier_inv_amount = '0.00'
export const penaltydocuments_uploaded = false 
export const estimated_repair_days = '0'