export const GET_WQH_COMPONENT_FIELD_LOOKUP_DATA = 'GET_WQH_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_COMPONENT_FIELD_LOOKUP_DATA = 'SET_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_STR_COMPONENT_FIELD_INPUT_VALUE = 'SET_STR_COMPONENT_FIELD_INPUT_VALUE'
export const SET_COMPONENT_LOOKUP_DATA_VALUES = 'SET_COMPONENT_LOOKUP_DATA_VALUES const '
export const SET_COMPONENT_LOOKUP_INPUT_VALUE = 'SET_COMPONENT_LOOKUP_INPUT_VALUE'
export const QUOTE_VEHICLE_BLANK_ROW = 'QUOTE_VEHICLE_BLANK_ROW'
export const GET_QUOTE_VEHICLE_DETAILS = 'GET_QUOTE_VEHICLE_DETAILS'
export const TOGGLE_CUSTOMER_VALUES = 'TOGGLE_CUSTOMER_VALUES'
export const CONFIRM_QUOTE_HEADER_SELECTION = 'CONFIRM_QUOTE_HEADER_SELECTION'
export const RESET_QUOTE_HEADER_SELECTION = 'RESET_QUOTE_HEADER_SELECTION'
export const SET_COMPONENT_FIELD_VALUE = 'SET_COMPONENT_FIELD_VALUE'
export const UPLOAD_RFQ_QUOTE_DOCUMENT = 'UPLOAD_RFQ_QUOTE_DOCUMENT'
export const RESET_UPLOAD_RFQ_QUOTE_DOCUMENT = 'RESET_UPLOAD_RFQ_QUOTE_DOCUMENT'
export const SET_RFQ_QUOTE_DOCUMENT_PREVIEW = 'SET_RFQ_QUOTE_DOCUMENT_PREVIEW'
export const CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW = 'CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW'
export const LOAD_COMPONET_GRID_SELECTED_DATA = 'LOAD_COMPONET_GRID_SELECTED_DATA'
export const CLEAR_QUOTE_VEHICLE_BLANK_ROW = 'CLEAR_QUOTE_VEHICLE_BLANK_ROW'
export const GET_COMPONENT_FIELD_LOOKUP_VALUE = 'GET_COMPONENT_FIELD_LOOKUP_VALUE'
export const SET_COMPONENT_FIELD_INPUT_TEXT = 'SET_COMPONENT_FIELD_INPUT_TEXT'
export const SEARCH_WORKINGS_QUOTATION = 'SEARCH_WORKINGS_QUOTATION'
export const SET_COMPONENT_WORKINGS_GRID_DATA = 'SET_COMPONENT_WORKINGS_GRID_DATA'
export const GET_CONTRACT_CATEGORY_VEHICLE_OPTIONS = 'GET_CONTRACT_CATEGORY_VEHICLE_OPTIONS'
export const SET_CONTRACT_CATEGORY_VEHICLE_OPTIONS = 'SET_CONTRACT_CATEGORY_VEHICLE_OPTIONS'
export const TOGGLE_USE_RATECARD = 'TOGGLE_USE_RATECARD'
export const GET_RATECARD_COMPONENT_LOOKUP_DATA = 'GET_RATECARD_COMPONENT_LOOKUP_DATA'
export const SET_RATECARD_COMPONENT_LOOKUP_DATA = 'SET_RATECARD_COMPONENT_LOOKUP_DATA'
export const GET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA = 'GET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA'
export const SET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA = 'SET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA'
export const SET_VEHICLE_DEFAULT_ACCESSORY_LIST = 'SET_VEHICLE_DEFAULT_ACCESSORY_LIST'
export const GET_VEHICLE_DEFAULT_ACCESSORY_LIST = 'GET_VEHICLE_DEFAULT_ACCESSORY_LIST'
export const SET_VEHICLE_SERVICE_SELECTION_CATEGORY_LOOKUP_DATA = 'SET_VEHICLE_SERVICE_SELECTION_CATEGORY_LOOKUP_DATA'
export const SET_VEHICLE_SUNDRY_SELECTION_CATEGORY_LOOKUP_DATA = 'SET_VEHICLE_SUNDRY_SELECTION_CATEGORY_LOOKUP_DATA'
export const GET_VEHICLE_CONTRACT_DETAILS = 'GET_VEHICLE_CONTRACT_DETAILS'
export const TOGGLE_CUSTOM_PROPS_VALUES = 'TOGGLE_CUSTOM_PROPS_VALUES'
export const TOGGLE_OPS_CUSTOM_PROPS_VALUES = 'TOGGLE_OPS_CUSTOM_PROPS_VALUES'
export const SET_COMPONENT_VALIDATION_PROPS_FIELDS = 'SET_COMPONENT_VALIDATION_PROPS_FIELDS'
export const GET_COMPONENT_FIELD_INPUT_VALUE = 'GET_COMPONENT_FIELD_INPUT_VALUE'
export const CONFIRM_STR_HEADER_SELECTION = 'CONFIRM_STR_HEADER_SELECTION'