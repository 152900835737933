export const quotes = [0]
export const chip_accessory = []
export const chip_service = []
export const chip_sundry = []
export const grid_data = []
export const vehiclecategory_data = []
export const customerderivative_data = []
export const vehicle_default_accessory = []
export const customerderivativecategory_data = []
export const contractvehiclecategory_data = []
export const customerderivativecategorymake_data = []
export const customerderivativecategorymmcode_data = []

export const sub_asset_data = []
export const vehiclegroup_data = []
export const vehicle_data = []
export const usage_data = []
export const color_data = []
export const terrain_data = []
export const quotefor_data = []
export const specialistvehicle_data = []
export const location_data = []
export const secondary_asset = []
export const bulkreference_data = []
export const customercontract_data = []
export const bulkreference = ''


export const prev_bulkreference = false
export const _customerderivative = {}
export const costomerref = null
export const financert57Pricing = null

export const contractperiod = null
export const distancepermonth = null
export const contractdistance = null

export const linkdeal_id = null
export const linkdeal = ''
export const quotationnotes = ''
export const contractnumber = ''
export const contracttype = ''
export const customerderivative = ''
export const customerderivativecategory = ''

export const total_adjustment = 0
export const customerderivative_id = 0
export const customerderivativecategory_id = 0

export const chk_specialpricing = true
export const chk_mmcode = false
export const chk_usedvehicle = false
export const chk_cooperate = false
export const contracttypedetail = {}
export const interestratetype = ''
export const interestratetype_id = null
export const interestratetype_data = []

export const validation = {
    fields: [],
    component: 'workflowQuoteHeader',
    list: [],
    valid: false
}

export const fields = {

    serviceSellingPrice: {
        errorText: 'Please enter Service Selling Price',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['sellingprice'],
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Service Selling Price'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Service Selling Price'
            }
        ]
    },
    serviceCostPrice: {
        errorText: 'Please enter Service Cost Price',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['costprice'],
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Service Cost Price'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Service Cost Price'
            }
        ]
    },
    serviceQuantity: {
        errorText: 'Please enter Service Quantity',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['quantity'],
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Service Quantity'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Service Quantity'
            }
        ]
    },
    serviceDescription: {
        errorText: 'Please select Service Item Description',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['service']
    },
    serviceSupplierLocation: {
        errorText: 'Please ensure Service Supplier Location is selected',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['supplier']
    },
    serviceDistance: {
        errorText: 'Please enter Service Supplier Radius',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['distance'],
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Service Supplier Radius'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Service Supplier Radius'
            }
        ]
    },

    sundrySellingPrice: {
        errorText: 'Please enter Sundry Selling Price',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['sellingprice'],
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Sundry Selling Price'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Sundry Selling Price'
            }
        ]
    },
    sundryCostPrice: {
        errorText: 'Please enter Sundry Cost Price',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['costprice'],
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Sundry Cost Price'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Sundry Cost Price'
            }
        ]
    },
    sundryQuantity: {
        errorText: 'Please enter Sundry Quantity',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['quantity'],
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Sundry Quantity'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Sundry Quantity'
            }
        ]
    },
    sundryDescription: {
        errorText: 'Please select Sundry Item Description',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['sundry']
    },
    sundrySupplierLocation: {
        errorText: 'Please ensure Sundry Supplier Location is selected',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['supplier']
    },
    sundryDistance: {
        errorText: 'Please enter Sundry Supplier Radius',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['distance'],
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Sundry Supplier Radius'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Sundry Supplier Radius'
            }
        ]
    },

    accessorySellingPrice: {
        errorText: 'Please enter Accessory Selling Price',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['sellingprice'],
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Accessory Selling Price'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Accessory Selling Price'
            }
        ]
    },
    accessoryCostPrice: {
        errorText: 'Please enter Accessory Cost Price',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['costprice'],
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Accessory Cost Price'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Accessory Cost Price'
            }
        ]
    },
    accessoryQuantity: {
        errorText: 'Please enter Accessory Quantity',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['quantity'],
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Accessory Quantity'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Accessory Quantity'
            }
        ]
    },
    accessoryDescription: {
        errorText: 'Please select Accessory Item Description',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['accessory']
    },
    accessorySupplierLocation: {
        errorText: 'Please ensure Accessory Supplier Location is selected',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['supplier']
    },
    accessoryDistance: {
        errorText: 'Please enter Accessory Supplier Radius',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory').length > 0 ? getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory') : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['distance'],
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Accessory Supplier Radius'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Accessory Supplier Radius'
            }
        ]
    },
    accessoryItemType: {
        errorText: 'Please ensure Accessory List - Item Type is selected',
        state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory').length > 0 ? getState().workflowQuoteItemGrid.data : true",
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['itemtype']
    },

    // accessory: {
    //     errorText: 'Please add at least 1 item to the quote',
    //     state: "getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'accessory').length > 0 || getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'sundry').length > 0 || getState().workflowQuoteItemGrid.data.filter(i => i.itemtype === 'service').length > 0 ? true : false",
    //     data: false,
    //     valid: false,
    //     empty: true,
    //     popup: true,
    // },

    escalatedMaintenanceCPK: {
        id: 'escalated_cpk',
        name: 'escalated_cpk',
        errorText: 'Please enter Escalated Maintenance CPK',
        state: 'getState().workflowQuoteHeader.grid_data[0]?.mm_code ? getState().workflowQuoteHeader.grid_data[0]?.escalated_cpk : true',
        data: false,
        valid: false,
        empty: true,
        section: 'Vehicle Details',
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Escalated Maintenance CPK: decimal'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Escalated Maintenance CPK: greaterThanZero'
            }
        ]
    },
    inflationRate: {
        id: 'cpirate',
        name: 'cpirate',
        errorText: 'Please enter Inflation Rate',
        state: 'getState().workflowQuoteHeader.grid_data[0]?.mm_code ? getState().workflowQuoteHeader.grid_data[0]?.cpirate : true',
        data: false,
        valid: false,
        empty: true,
        section: 'Vehicle Details',
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Inflation Rate'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Inflation Rate'
            }
        ]
    },
    maintCPK: {
        id: 'maintcpk',
        name: 'maintcpk',
        errorText: 'Please enter Maintenance CPK',
        state: 'getState().workflowQuoteHeader.grid_data[0]?.mm_code ? getState().workflowQuoteHeader.grid_data[0]?.manualtotalmaintcpk : true',
        data: false,
        valid: false,
        empty: true,
        section: 'Vehicle Details',
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Maintenance CPK'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Maintenance CPK'
            }
        ]
    },
    tyresCPK: {
        id: 'tyrecpk',
        name: 'tyrecpk',
        errorText: 'Please enter Tyres CPK',
        state: 'getState().workflowQuoteHeader.grid_data[0]?.mm_code ? getState().workflowQuoteHeader.grid_data[0]?.financeexcess : true',
        data: false,
        valid: false,
        empty: true,
        section: 'Vehicle Details',
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Tyres CPK'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Tyres CPK'
            }
        ]
    },
    residualValue: {
        id: 'residualvalue',
        name: 'residualvalue',
        errorText: 'Please enter Residual Value Percentage',
        state: 'getState().workflowQuoteHeader.grid_data[0]?.mm_code ? getState().workflowQuoteHeader.grid_data[0]?.residualvalue : true',
        data: false,
        valid: false,
        empty: true,
        section: 'Vehicle Details',
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Residual Value Percentage'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Residual Value Percentage'
            }
        ]
    },
    deliveryPeriod: {
        id: 'deliveryperiod',
        name: 'deliveryperiod',
        errorText: 'Please enter Delivery Period',
        state: 'getState().workflowQuoteHeader.grid_data[0]?.mm_code ? getState().workflowQuoteHeader.grid_data[0]?.deliveryperiod : true',
        data: false,
        valid: false,
        empty: true,
        section: 'Vehicle Details',
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Delivery Period'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Delivery Period'
            }
        ]
    },
    discount: {
        id: 'discount',
        name: 'discount',
        errorText: 'Please enter Discount',
        state: 'getState().workflowQuoteHeader.grid_data[0]?.mm_code ? getState().workflowQuoteHeader.grid_data[0]?.discount : true',
        data: false,
        valid: false,
        empty: true,
        section: 'Vehicle Details',
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Discount'
            }
        ]
    },
    retailPrice: {
        id: 'retailprice',
        name: 'retailprice',
        errorText: 'Please enter Retail Price',
        state: 'getState().workflowQuoteHeader.grid_data[0]?.mm_code ? getState().workflowQuoteHeader.grid_data[0]?.retailprice : true',
        data: false,
        valid: false,
        empty: true,
        section: 'Vehicle Details',
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Retail Price'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Retail Price'
            }
        ]
    },

    quotationstep: {
        errorText: 'Please add vehicle to the quote',
        state: 'getState().workflowQuoteHeader.grid_data[0]?.mm_code',
        data: false,
        valid: false,
        empty: true,
        popup: true
    },

    quotedate: {
        errorText: 'Please select RFQ date',
        state: 'getState().workflowQuoteHeader.quotedate',
        data: false,
        valid: false,
        empty: true,
        popup: true,
    },

    documents: {
        errorText: 'Please add a RFQ document and select document type',
        data: false,
        valid: false,
        empty: true,
        popup: true,
        type: 'documents',
    },

    quoteFor: {
        id: 'quotefor',
        name: 'quotefor',
        errorText: 'Please select Quote For',
        state: 'getState().workflowQuoteHeader.quotefor',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    color: {
        id: 'color',
        name: 'color',
        errorText: 'Please select Vehicle Color',
        state: 'getState().workflowQuoteHeader.color',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    location: {
        id: 'location',
        name: 'location',
        errorText: 'Please select Vehicle Operating Location',
        state: 'getState().workflowQuoteHeader.location',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    specialistVehicle: {
        id: 'specialistvehicle',
        name: 'specialistvehicle',
        errorText: 'Please select Special List Vehicle',
        state: 'getState().workflowQuoteHeader.specialistvehicle',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    vehicleOperatingTerrain: {
        id: 'terrain',
        name: 'terrain',
        errorText: 'Please select Vehicle Operating Terrain',
        state: 'getState().workflowQuoteHeader.terrain',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    vehicleUsage: {
        id: 'usage',
        name: 'usage',
        errorText: 'Please select Vehicle Usage',
        state: 'getState().workflowQuoteHeader.usage',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    vehicleGroup: {
        id: 'vehiclegroup',
        name: 'vehiclegroup',
        errorText: 'Please select Vehicle Group',
        state: 'getState().workflowQuoteHeader.vehiclegroup',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    loadFactor: {
        id: 'loadfactor',
        name: 'loadfactor',
        errorText: 'Please select Load Factor',
        state: 'getState().workflowQuoteHeader.vehiclecategory',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    contractDistance: {
        id: 'contractdistance',
        name: 'contractdistance',
        errorText: 'Please enter Contract Distance',
        state: 'getState().workflowQuoteHeader.contractdistance',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    distancePerMonth: {
        id: 'distancepermonth',
        name: 'distancepermonth',
        errorText: 'Please enter Distance Per Month',
        state: 'getState().workflowQuoteHeader.distancepermonth',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters',
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Distance Per Month'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Distance Per Month'
            }
        ]
    },
    contractPeriod: {
        id: 'contractperiod',
        name: 'contractperiod',
        errorText: 'Please enter Contract Period',
        state: 'getState().workflowQuoteHeader.contractperiod',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters',
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Contract Period'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Contract Period'
            }
        ]
    },
    vehicleCategory: {
        id: 'dll_vehicle_category',
        name: 'dll_vehicle_category',
        errorText: 'Please select Vehicle Category',
        state: 'getState().workflowQuoteHeader.customerderivativecategory_id',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    contractType: {
        id: 'dll_contract_Type',
        name: 'dll_contract_Type',
        errorText: 'Please select Contract Type',
        state: 'getState().workflowQuoteHeader.customerderivative.contracttype_id',
        data: false,
        valid: false,
        empty: true,
        section: 'Quotation Parameters'
    },
    // bulkReference: {
    //     id: 'bulkreference',
    //     name: 'bulkreference',
    //     errorText: 'Please enter Bulk Reference',
    //     state: 'getState().workflowQuoteHeader.bulkreference',
    //     data: false,
    //     valid: false,
    //     empty: true,
    //     section: 'Quotation Parameters'
    // },
}