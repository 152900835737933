export const title = ''
export const description = ''
export const valid = false
export const prompt_win = false
export const prompt_accept = false
export const errors = []
export const tree_data = {
    label: 'search me',
    value: 'searchme',
    children: [
      {
        label: 'search me too',
        value: 'searchmetoo',
        children: [
          {
            label: 'No one can get me',
            value: 'anonymous',
          },
        ],
      },
    ],
  }