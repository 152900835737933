import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import { cognitoUser } from '../../cognito'
import * as config from '../../config'

export const getBusinessCustomerHierarchy = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(types.GET_BUSINESS_CUSTOMER_HIERARCHY),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/team_client_business_customer/in?team_id=' + store.value.accounts.teams.map(t => t.team_id).join('.')
                    + '&client_id=' + store.value.accounts.selectedAccount,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'business.customer'
                }
            }).pipe(
                mergeMap(payload => [
                    actions.setBusinessCustomerHierarchy(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query?cognito_key=' + cognitoUser.username)
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query?cognito_key=' + cognitoUser.username, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getUserNotificationsEpic = (action$, store, { ajax$, of$ }) =>
    action$.ofType(
        types.GET_USER_WORKFLOW_NOTIFICATIONS_MENUS
    )
        .mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/workflow_task_user_notification/search?user_id=' + store.getState().user.user_id,
                method: 'GET',
                headers: {
                    'auth': store.getState().cognito.authenticateUser.token,
                    'salt': store.getState().cognito.authenticateUser.jwt,
                    'datetime': store.getState().cognito.authenticateUser.datetime,
                    'take': 200,
                    'related': 'user,workflow,workflow_task.workflow_task_team, workflow_task_type'
                }
            })
                .mergeMap(payload => [
                    actions.setUserNotifications(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/team/query')
                ])
                .startWith(progressBarActions.progress(true))
                .catch((error) => of$(requestActions.requestFailed(config.system.api + '/components/team/query', error)))
                .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        .catch((error) =>
            of$(routerActions.route('/support', false, error))
        )


export const getDocumentPrevData = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_DOCUMENT_PREVIEW_DATA),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/document_preview/search', //cognitoUser.username,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            }).pipe(
                mergeMap((payload) => [
                    actions.setDocumentPrevData(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query?cognito_key=' + cognitoUser.username)
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query?cognito_key=' + cognitoUser.username, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

    export const getDashboardListEpic = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_COMPONENT_USER_PROFILE_DASHBOARD_DATA)
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/report/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            }).pipe(
                mergeMap((result) => [
                    actions.setDashboardList(result.response),
                    progressBarActions.progress(false)
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

    
    export const getWorkqueueList= (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_WORKQUEUE_LIST_ITEMS)
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workqueue/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    take: 999,
                    'Content-Type': 'application/json'
                },
            }).pipe(
                mergeMap((result) => [
                    actions.setWorkqueueList(result.response),
                    progressBarActions.progress(false)
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )