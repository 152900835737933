import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getAllocationCustomers = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_ALLOCATION_CUSTOMER_DATA,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customer/searchor?customername=' + payload + '&accountnumber=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'customername'
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setAllocationCustomers(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

    
export const getCustomerTransactions = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_CUSTOMER_TRANSACTIONS,
        )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customer/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setCustomerTransactions(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
export const updateCustomerTransaction = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.UPDATE_CUSTOMER_TRANSACTION_ALLOCATION,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customertransaction',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
                    mergeMap((result) => [
                        actions.updateCustomerTransactionDone(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const saveAllocationEpic = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.SAVE_CUSTOMER_ALLOCATION_DATA,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customerallocation/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
                    mergeMap((result) => [
                        actions.savedCustomerAllocationData(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const filterAllocationData = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.FILTER_ALLOCATION_DATA,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customertransaction/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setCustomerAllocationData(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

    export const unallocatedPaymentAllocationEpic = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(ofType(
            types.SAVE_UNALLOCATION_DATA,
        )
            , mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/customertransaction',
                    method: 'POST',
                    headers: {
                        'tokenx': store.value.user.user_id,
                        'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'Content-Type': 'application/json'
                    },
                    body: payload.data
                })
                    .pipe(
                        mergeMap((result) => [
                            actions.unallocatedPaymentAllocationEpicDone(result.response),
                            requestActions.requestSucceeded(config.system.api + '')
                        ])
                        , startWith(progressBarActions.progress(true))
                        , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                        //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                    )
            )
            , catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )
    