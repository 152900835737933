import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import DateControl from '../../../controls/dateControl'

import * as actions from './actions'
import * as styles from './styles'

class CustomerMasterActivation extends Component {
   

    getView(data) {
        let documents = data?.documents?.documents ? data?.documents?.documents : []
        return <div>
        <Card
            title={"Customer Details"}
            subtitle={window.t.en('Enter new customer details')}
            content={<Fields 
                fields={[
                    {size: 4, label: 'Customer Name', value: data.customername},
                    {size: 4, label: 'Trading Name', value: data.tradingname},
                    {size: 4, label: 'Account Number', value: data.accountnumber},
                    {size: 4, label: 'Account Executive:', value: data.firstname},
                    {size: 4, label: 'Branch:', value: data.branch},
                    {size: 4, label: 'Region:', value: data.region},
                    {size: 4, label: 'Registration Number', value: data.registrationnumber},
                    {size: 4, label: 'VAT Number', value: data.vatnumber},
                    {size: 4, label: 'Customer Type', value: data.organisationtype},
                    {size: 4, label: 'Business Type:', value: data.turnoverbusinesstype},
                    {size: 4, label: 'Individual Type:', value: data.turnoverindividualtype},
                    {size: 4, label: 'Group:', value: data.bidvestgroup? 'Yes' : 'No'},
                    {size: 4, label: 'Non Reporting:', value: data.non_reporting? 'Yes' : 'No'}
                ]}
                />}
        /> 
                <Card
            title={"Customer Fic Details"}
            subtitle={window.t.en('Enter new customer fic details')}
            content={<Fields 
                fields={[
                    {size: 4, label: 'Registration Number', value: data.registrationnumber},
                    {size: 4, label: 'Source of Funds:', value: data.sourceoffunds},
                    {size: 4, label: 'Source of Wealth:', value: data.sourceofwealth},
                    {size: 4, label: 'Trading Country:', value: data.country},
                    {size: 4, label: 'Relationship Period:', value: data.relationship_period},
                    {size: 4, label: 'Politically Exposed Person:', value: data.politically_exposed_person? 'Yes' : 'No'},
                    {size: 4, label: 'Domestic Prominent Influential Person:', value: data.domestic_prominent_influential_person? 'Yes' : 'No'},
                    {size: 4, label: 'Foreign Prominent Public Person:', value: data.foreign_prominent_public_official? 'Yes' : 'No'},
                    {size: 4, label: 'Enhanced Due Dilligence:', value: data.enhanced_due_diligence? 'Yes' : 'No'},
                    {size: 4, label: 'Standard Industrial Classification:', value: data.standard_industrial_classification},
                ]}
                />}
        /> 
        <Card
            title={"Customer Facility Detail"}
            subtitle={window.t.en('Customer Facility Detail')}
            content={<Fields 
                fields={[
                    {size: 4, label: 'Monthly Limit:', value: data.creditlimit},
                    {size: 4, label: 'Facility Renewal Period:', value: data.facilityrenewalperiod},
                    {size: 4, label: 'Facility Last Review Date:', value: data.facilitylastreviewdate},
                    {size: 4, label: 'Facility Next Review Date:', value: data.facilitynextreviewdate}
                ]}
                />}
        /> 
        <Card
            title={"Customer Address"}
            subtitle={window.t.en('Customer address details')}
            content={<Fields data={data.address} shift={[0]} columns={['Address Type', 'Address Line1', 'Address Line2', 'Address Line3', 'Address Line4']} />}
        /> 
        <Card
            title={"Customer Contact"}
            subtitle={window.t.en('Customer contact details')}
            content={<Fields data={data.contacts} shift={[0]} columns={['Contact Type', 'Firstname', 'Lastname', 'Email Address', 'Contact Number']} />}
        /> 
        <Card
            title={"Customer Fica"}
            subtitle={window.t.en('Uploaded customer supporting documents')}
            content={<PdfViewer
                key={'customer_fica'}
                name={'customer_fica'}
                documents={documents}
                edit={false}
                multiple={false}
                workflow_queue_data={null}
            />}
        />
        </div>
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('CustomerMasterActivation', data.context_data.data.data.data[0].data[0])
        data = data.context_data.data.data.data[0].data[0]
        return <div>
            {this.getView(data.data.confirm)}
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.customerMasterActivation
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMasterActivation)