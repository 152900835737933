
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'

import Icon from '../Icon/component'

import Card from '../../controls/card'
import TextField from '../../controls/textField'
import DateControl from '../../controls/dateControl'
import AutoComplete from '../../controls/autocomplete'

import * as config from '../../config'
import * as actions from './actions'
import * as styles from './styles'




String.prototype.splice = function (idx, rem, str) {
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

class QuoteHeader extends Component {

    componentDidMount() {
        this.props.actions.setHeaderInputValue({ 'prop': 'retailprice', 'value': this.props.grid.retailprice, 'inx': this.props.inx })
    }

    getLayout(_data) {
        console.log('mmocdexxxx _data', _data)
        console.log('mmocdexxxx props', this.props)
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col style={styles.remove}>
                    <Icon
                        iname="delete_forever"
                        iclass="material-icons"
                        icolor="red"
                        istyle={{
                            color: 'red',
                            fontSize: '20px',
                        }}
                        onClick={(event) => {
                            //console.log('removeSelectedRow', this.props.state.quoteHeader)
                            this.props.actions.removeSelectedRow(this.props.inx)
                        }}
                    />
                </Col>
            </Row>
            {
                !this.props.rt57 && <Row>
                    <Col xl={12}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_mmcode'}
                                    key={'dll_mmcode'}
                                    hintText={'Select / Search Vehicle MMCode'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    discdate={'discdate'}
                                    fieldvalue={'mmcode_id'}
                                    displayfields={['make', 'mmcode', 'cdescription']}
                                    displaywidth={['20', '20', '60']}
                                    value={_data.mmcode_fktext}
                                    dataSource={this.props.state.mmcode_data}
                                    onSelect={
                                        (args) => {
                                            //console.log('args mmcode',args)
                                            this.props.actions.setInputValue({ prop: 'mmcode_id', value: args.value })
                                            this.props.actions.setInputValue({ prop: 'mmcode', value: args.text })
                                            //console.log('getMMCodeToGrid', args, this.props.inx)
                                            //console.log('getMMCodeToGrid contractperiod', this.props.contractperiod)
                                            //console.log('getMMCodeToGrid distancepermonth', this.props.distancepermonth)
                                            this.props.actions.getMMCodeToGrid(args.value, this.props.contractperiod, this.props.distancepermonth, this.props.inx)
                                            //this.props.actions.getMMCodeToGrid(args.value,this.props.contractperiod,this.props.distancepermonth, this.props.inx)
                                            //this.props.actions.setTotalAdjustment()
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            let mmcodes = this.props.mmcodes.length ? this.props.mmcodes.map(x => { return x.vehiclemmcode }).join('.') : null
                                            let makes = this.props.makes.length ? this.props.makes.map(x => { return x.vehiclemake }).join('.') : null
                                            this.props.actions.getMMCodeList('', mmcodes, makes)
                                        }
                                    }
                                    onFilter={
                                        (search, dataSource, params) => {
                                            let mmcodes = this.props.mmcodes.length ? this.props.mmcodes.map(x => { return x.vehiclemmcode }).join('.') : null
                                            let makes = this.props.makes.length ? this.props.makes.map(x => { return x.vehiclemake }).join('.') : null

                                            if (search) {
                                                this.props.actions.setInputValue({ prop: 'mmcode', value: search })
                                                this.props.actions.getMMCodeList(search, mmcodes, makes)
                                            } else {
                                                this.props.actions.getMMCodeList('', mmcodes, makes)
                                                this.props.actions.setInputValue({ prop: 'mmcode_id', value: null })
                                                this.props.actions.setInputValue({ prop: 'mmcode', value: '' })
                                                this.props.actions.setHeaderInputValue({ 'prop': 'retailprice', 'value': '', 'inx': this.props.inx })
                                                this.props.actions.setHeaderInputValue({ 'prop': 'discount', 'value': '', 'inx': this.props.inx })
                                                this.props.actions.setHeaderInputValue({ 'prop': 'deliveryperiod', 'value': '', 'inx': this.props.inx })
                                                this.props.actions.setHeaderInputValue({ 'prop': 'residualvalue', 'value': '', 'inx': this.props.inx })
                                                this.props.actions.setHeaderInputValue({ 'prop': 'financeexcess', 'value': '', 'inx': this.props.inx })
                                                this.props.actions.setHeaderInputValue({ 'prop': 'manualtotalmaintcpk', 'value': '', 'inx': this.props.inx })
                                                this.props.actions.setHeaderInputValue({ 'prop': 'cpirate', 'value': '', 'inx': this.props.inx })
                                                this.props.actions.setHeaderInputValue({ 'prop': 'escalated_cpk', 'value': '', 'inx': this.props.inx })
                                            }
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            }
            <Row>
                <Col xl={7}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            {'Make:'} <b>{_data?.mmcode?.make}</b> <br />
                            {'Description:'} <b>{_data?.mmcode?.model}</b> <br />
                            {'Variant:'} <b>{_data?.mmcode?.variant}</b> <br />
                            {'MMCode:'} <b>{_data?.mmcode?.mmcode}</b> <br />
                            {'Intro Date:'} <b>{_data?.mmcode?.introdate}</b> <br />
                            {'Disc Date:'} <b>{_data?.mmcode?.discdate}</b>
                        </div>
                    </div>
                </Col>
                {
                    _data.mmcode
                        ? <Col xl={4}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldContent}>
                                    {
                                        <img style={styles.vehicle} src={config.system.cdn + '/' + (_data.mmcode.imagefilename !== null ? _data.mmcode.imagefilename : 'unknown.png')} />
                                    }
                                </div>
                            </div>
                        </Col>
                        : ''
                }
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            {'Abs:'} <b>{_data?.mmcode?.abs == 1 ? 'STD' : _data?.mmcode?.abs == 0 ? 'OPT' : 'NA' }</b> <br />
                            {'Airbag:'} <b>{_data?.mmcode?.airbag == 1 ? 'STD' : _data?.mmcode?.airbag == 0 ? 'OPT' : 'NA' }</b> <br />
                            {'Aircon:'} <b>{_data?.mmcode?.aircon == 1 ? 'STD' : _data?.mmcode?.aircon == 0 ? 'OPT' : 'NA' }</b> <br />
                            {'Electric Windows:'} <b>{_data?.mmcode?.electricwindows == 1 ? 'STD' : _data?.mmcode?.electricwindows == 0 ? 'OPT' : 'NA' }</b> <br />
                            {'Full 4x4:'} <b>{_data?.mmcode?.full4x4 == 1 ? 'STD' : _data?.mmcode?.full4x4 == 0 ? 'OPT' : 'NA' }</b> <br />
                            {'Hybrid:'} <b>{_data?.mmcode?.hybrid == 1 ? 'STD' : _data?.mmcode?.hybrid == 0 ? 'OPT' : 'NA' }</b><br />
                            {'Mp3:'} <b>{_data?.mmcode?.mp3 == 1 ? 'STD' : _data?.mmcode?.mp3 == 0 ? 'OPT' : 'NA' }</b><br />
                            {'Radio:'} <b>{_data?.mmcode?.radio == 1 ? 'STD' : _data?.mmcode?.radio == 0 ? 'OPT' : 'NA' }</b><br />
                            {'Power Steering:'} <b>{_data?.mmcode?.powersteering == 1 ? 'STD' : _data?.mmcode?.powersteering == 0 ? 'OPT' : 'NA' }</b><br />
                            {'Panoramic Roof:'} <b>{_data?.mmcode?.panoramicroof == 1 ? 'STD' : _data?.mmcode?.panoramicroof == 0 ? 'OPT' : 'NA' }</b><br />
                            {'Run Flat:'} <b>{_data?.mmcode?.runflat == 1 ? 'STD' : _data?.mmcode?.runflat == 0 ? 'OPT' : 'NA' }</b><br />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'retailprice'}
                                value={_data.retailprice}
                                fullWidth={true}
                                style={styles.TextField}
                                label={'Retail Price'}
                                inputStyle={styles.textBoxInput}
                                onChange={(event, value) => {
                                    this.props.actions.setHeaderInputValue({ 'prop': 'retailprice', 'value': event.target.value, 'inx': this.props.inx })
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'this.field.discount.name'}
                                value={_data.discount}
                                fullWidth={true}
                                label={'Discount Amount'}
                                style={styles.TextField}
                                inputStyle={styles.textBoxInput}
                                onChange={(event, value) => {
                                    this.props.actions.setHeaderInputValue({ 'prop': 'discount', 'value': event.target.value, 'inx': this.props.inx })
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'this.field.vehicleprice.name'}
                                value={_data.vehicleprice}
                                fullWidth={true}
                                disabled={true}
                                label={'Vehicle Price'}
                                style={styles.TextField}
                                inputStyle={styles.textBoxInput}
                            />
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'this.field.deliveryperiod.name'}
                                value={_data.deliveryperiod}
                                fullWidth={true}
                                style={styles.TextField}
                                label={'Delivery Period'}
                                inputStyle={styles.textBoxInput}
                                onChange={(event, value) => {
                                    this.props.actions.setHeaderInputValue({ 'prop': 'deliveryperiod', 'value': event.target.value, 'inx': this.props.inx })
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'this.field.rvpercentage.name'}
                                value={_data.residualvalue}
                                fullWidth={true}
                                style={styles.TextField}
                                inputStyle={styles.textBoxInput}
                                label={'RV Percentage'}
                                //hintText={'Offset RV'}
                                onChange={(event, value) => {
                                    this.props.actions.setHeaderInputValue({ 'prop': 'residualvalue', 'value': event.target.value, 'inx': this.props.inx })
                                }}
                            />

                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            {
                                _data.total_adjustment
                                    ? <small style={{ fontWeight: 400, color: '#d2d2d2' }}>
                                        RV {_data.rev_residualvalue ? _data.rev_residualvalue : ''} Offset By {_data.total_adjustment}
                                    </small>
                                    : ''
                            }
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'this.field.rv_amount.name'}
                                value={_data.rv_amount}
                                fullWidth={true}
                                disabled={true}
                                label={'RV Amount'}
                                style={styles.TextField}
                                inputStyle={styles.textBoxInput}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'this.field.overall_rv.name'}
                                value={_data.overall_rv}
                                fullWidth={true}
                                disabled={true}
                                label={'Overall RV'}
                                style={styles.TextField}
                                inputStyle={styles.textBoxInput}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <Row>
            <Col xl={12}>
                <div style={styles.fieldContainer}>
                    <div style={styles.fieldContent}>
                        <TextField
                            variant="outlined" 
                            id={'this.field.overall_rv_amount.name'}
                            value={_data.overall_rv_amount}
                            fullWidth={true}
                            disabled={true}
                            label={'Overall RV Amount'}
                            style={styles.TextField}
                            inputStyle={styles.textBoxInput}
                        />
                    </div>
                </div>
            </Col>
        </Row> */}
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'this.field.maintcpk.name'}
                                value={_data.financeexcess}
                                fullWidth={true}
                                label={'Tyres CPK'}
                                inputStyle={styles.textBoxInput}
                                style={styles.TextField}
                                onChange={(event, value) => {
                                    this.props.actions.setHeaderInputValue({ 'prop': 'financeexcess', 'value': event.target.value, 'inx': this.props.inx })
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>


            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'this.field.tyrecpk.name'}
                                value={_data.manualtotalmaintcpk}
                                inputStyle={styles.textBoxInput}
                                fullWidth={true}
                                label={'Maintenance CPK'}
                                style={styles.TextField}
                                onChange={(event, value) => {
                                    this.props.actions.setHeaderInputValue({ 'prop': 'manualtotalmaintcpk', 'value': event.target.value, 'inx': this.props.inx })
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id="this.field.cpirate.name"
                                value={_data.cpirate}
                                fullWidth={true}
                                label="Inflation Rate"
                                inputStyle={styles.textBoxInput}
                                style={styles.TextField}
                                onChange={(event) => {
                                    this.props.actions.setHeaderInputValue({ 'prop': 'cpirate', 'value': event.target.value, 'inx': this.props.inx })
                                    let inflationRate = 0
                                    let result = 0
                                    //console.log('_data.cpirate/100',_data.cpirate/100)
                                    //console.log('_data.manualtotalmaintcpk',_data.manualtotalmaintcpk)
                                    //console.log('this.props.contractperiod / 12',this.props.contractperiod / 12)
                                    //console.log('_data.manualtotalmaintcpk * Math.pow(1 + _data.cpirate/100, this.props.contractperiod / 12)',_data.manualtotalmaintcpk * Math.pow(1 + _data.cpirate/100, this.props.contractperiod / 12))
                                    if (_data.cpirate) {
                                        inflationRate = (
                                            parseFloat(_data.manualtotalmaintcpk) *
                                            Math.pow(1 + parseFloat(_data.cpirate) / 100, parseFloat(this.props.contractperiod) / 12) *
                                            (1 * parseFloat(this.props.distancepermonth) / parseFloat(this.props.distancepermonth))
                                        )

                                        let roundedInflationRate = parseFloat(inflationRate).toFixed(2);
                                        result = parseFloat(roundedInflationRate);

                                    }
                                    this.props.actions.setHeaderInputValue({ 'prop': 'escalated_cpk', 'value': result, 'inx': this.props.inx })
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'this.field.escalated_cpk.name'}
                                value={_data.escalated_cpk}
                                inputStyle={styles.textBoxInput}
                                fullWidth={true}
                                label={'Escalated Maintenance CPK'}
                                style={styles.TextField}
                                onChange={(event, value) => {
                                    this.props.actions.setHeaderInputValue({ 'prop': 'escalated_cpk', 'value': event.target.value, 'inx': this.props.inx })

                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>

    }
    render() {
        return <Card
            title={window.t.en('Vehicle Details')}
            subtitle={window.t.en('Quoted vehicle details')}
            content={this.getLayout(this.props.grid)}
        />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.quoteHeader,
            component: {
                ...state.components[ownProps.name]
            },
            contractvehiclecategorymmcodegroup: {
                ...state.components['contractvehiclecategorymmcodegroup']
            },
            surrogateheader: {
                ...state.components['surrogateheader']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteHeader)