export const SET_CURRENT_GRID_COMPONENT = 'SET_CURRENT_GRID_COMPONENT'
export const GET_CLIENT_COMPONENT_GRID_DATA = 'GET_CLIENT_COMPONENT_GRID_DATA'
export const GET_COMPONENT_GRID_DATA = 'GET_COMPONENT_GRID_DATA'
export const SET_COMPONENT_GRID_DATA = 'SET_COMPONENT_GRID_DATA'
export const SET_COMPONENT_GRID_PAGE = 'SET_COMPONENT_GRID_PAGE'
export const SELECT_ROW = 'SELECT_ROW'
export const SELECT_MULTIPLE_ROWS = 'SELECT_MULTIPLE_ROWS'
export const RESIZE_GRID_COLUMN = 'RESIZE_GRID_COLUMN'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const GET_COMPONENT_EXCEL_DATA = 'GET_COMPONENT_EXCEL_DATA'
export const VIEW_DOCUMENT = 'VIEW_DOCUMENT'
export const SET_DELETE_INPROGRESS = 'SET_DELETE_INPROGRESS'
export const SET_EXPORT_INPROGRESS = 'SET_EXPORT_INPROGRESS'
export const SET_FILTER_INPROGRESS = 'SET_FILTER_INPROGRESS'
export const DEL_COMPONENT_GRID_ROW = 'DEL_COMPONENT_GRID_ROW'

export const SET_COMPONENT_GRID_ROW_FIELD_VALUE = 'SET_COMPONENT_GRID_ROW_FIELD_VALUE'

export const UPD_COMPONENT_GRID_ROW_FIELD_VALUE = 'UPD_COMPONENT_GRID_ROW_FIELD_VALUE'
export const SET_COMPONENT_DELETE_ROW_FIELD_VALUE = 'SET_COMPONENT_DELETE_ROW_FIELD_VALUE'
export const SET_COMPONENT_GRID_EXPORT_EXCEL_DATA = 'SET_COMPONENT_GRID_EXPORT_EXCEL_DATA'
export const GET_COMPONENT_GRID_EXPORT_EXCEL_DATA = 'GET_COMPONENT_GRID_EXPORT_EXCEL_DATA'