import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { Container, Row, Col } from 'react-grid-system'

import Section from '../../controls/section'
import Header from '../Header/component'
import * as config from '../../config'
import * as actions from './actions'
import * as styles from './styles'



const ShadowBoxOff = ({ shadow, name, desc, color }) => (
    <Tooltip title={desc ? desc : 'Unspecified'} arrow placement={"right-end"} enterDelay={1000}>
        <>
            <Paper
                style={{
                    // // position: 'absolute',
                    // width: '232px',
                    // margin: '15px',
                    // height: '173px',
                    // // left: '122px',
                    // // top: '312px',
                    // background: '#ECF0F3',
                    // // boxShadow: '18px 18px 30px #FFFFFF, 18px 15px 30px #D1D9E6',
                    // borderRadius: '26px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // bottom: 0,
                    // // py: 3.5,
                    // fontWeight: '500',
                    // // // bgcolor: 'primary.light',
                    // // color: 'grey.800',
                    // fontSize: 16,
                    // //boxShadow: '0 4px 10px 0 #D1D9E6',
                    // // transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    // // // box-shadow: 'none',
                    // // backgroundImage: 'none',
                    // // borderRadius: '20px',
                    // // border: 'none rgba(144, 202, 249, 0.145)',
                    // // backgroundColor: 'rgb(30, 136, 229)',
                    // // color: 'rgb(238, 242, 246)',
                    // // overflow: 'hidden',
                    // // position: 'relative',
                    //position: 'absolute',
                    //width: '232px',
                    height: '173px',
                    left: '0px',
                    top: '0px',

                    background: '#ECF0F3',
                    margin: '50px',
                    //boxShadow: '10px 18px 20px #FFFFFF, -18px -15px 30px #D1D9E6',
                    boxShadow: '10px 18px 20px #FFFFFF, -18px -15px 30px #' + color,
                    borderRadius: '26px',
                    '&:hover, &:focus': {
                        boxShadow: '10px 18px 20px #FFFFFF, -8px -5px 30px #' + color,
                    },
                }}
            >
                <Container style={{ alignItems: 'center', width: '100%', color: '#131B3A', fontFamily: 'Poppins', fontWeight: 300, fontSize: '16px', lineHeight: '20px' }}>
                    <Row>
                        <Col xl={12} style={{ alignItems: 'center', textAlign: 'center' }}>
                            <img style={{ width: '80px', height: '80px', alignItems: 'center' }} src={`${config.system.cdn}/icons/${name.replace(/ /g, '').toLowerCase()}.png`} alt='' />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} style={{ alignItems: 'center', textAlign: 'center' }}>
                            <br />
                            <div style={{ color: 'inherit', alignContent: 'baseline', alignItems: 'baseline' }}>{name}</div>
                        </Col>
                    </Row>
                </Container>
            </Paper>
        </>
    </Tooltip>
);

const ShadowBox = ({ shadow, name, desc, color }) => (
    <Tooltip title={desc ? desc : 'Unspecified'} arrow placement={"right-end"} enterDelay={1000}>
        <div>
            <Paper
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '173px',
                    left: '0px',
                    top: '0px',

                    background: '#ECF0F3',
                    margin: '10px',
                    boxShadow: '0px 18px 10px #FFFFFF, 0px 5px 10px #' + color,
                    borderRadius: '14px',
                }}
            >
                <Container style={{ alignItems: 'center', width: '100%', fontWeight: 300, fontSize: '16px', lineHeight: '20px' }}>
                    <Row>
                        <Col xl={12} style={{ alignItems: 'center', textAlign: 'center' }}>
                            <img style={{ width: '80px', height: '80px', alignItems: 'center' }} src={`${config.system.cdn}/icons/${name.replace(/ /g, '').toLowerCase()}.png`} alt='' />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} style={{ alignItems: 'center', textAlign: 'center' }}>
                            <div style={{ color: 'inherit', alignContent: 'baseline', alignItems: 'baseline' }}>{name}</div>
                        </Col>
                    </Row>
                </Container>
            </Paper>
        </div>
    </Tooltip>
);

class DashboardView extends Component {
    componentDidMount() {
        this.props.actions.loadReports()
    }

    getGrid() {
        return <div style={{ flexGrow: 1 }}>
        <Header title={"Dashboard"} description={"Dashboard List"} route={"dashboard"} />
            {
                this.props.state.data.sort((x, y) => x.order - y.order).map(x => {

                    return <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>
                                {/* <GridCategory>{x.category}</GridCategory> */}
                                {
                                    x.subcategory.sort((x, y) => x.order - y.order).map(sub => {
                                        let reports = this.props.state.search ? sub.report.filter(report => report.report_name.includes(this.props.state.search)).sort((x, y) => x.order - y.order) : sub.report
                                        return reports.length ? <><div style={{   backgroundColor: 'transparent',
                                                margin: 0,
                                                // padding: theme.spacing(1),
                                                textAlign: 'left',
                                                color: '#131B3A',
                                                border: 'none',
                                                fontSize: 15,}}
                                            >
                                            <Container style={{border: 'none'}}>
                                                <Row>
                                                    {/* <Col xl={4}><hr style={{ border: '5px solid #152461', marginTop: '5px', left: '-60px', position: 'relative', width: '115%' }} />
                                                    </Col>
                                                    <Col xl={8} style={{ fontStyle: 'normal', fontWeight: 400, fontSize: '21px', lineHeight: '20px', color: '#131B3A' }}>{x.category} - {sub.sub_category}</Col>
                                                     */}
                                                    <Section title={x.category} subtitle={sub.sub_category}  />
                                                </Row>
                                            </Container>
                                        </div>
                                            <div style={{ padding: 25, flexGrow: 1 }}>
                                                <Container style={{ margin: 0 }} className="custom-container">
                                                    <Row>
                                                        {
                                                            reports.map(report => {
                                                                return <Col xl={2} onClick={() => this.props.actions.setReportView(report)}>
                                                                    <ShadowBox shadow="0" name={report.report_name} desc={report.report_desc} color={sub.reporttheme ? sub.reporttheme : 'D1D9E6'}></ShadowBox>
                                                                </Col>
                                                            })
                                                        }
                                                    </Row>
                                                </Container>
                                            </div>
                                            <br />
                                        </> : <></>
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                })
            }
        </div>
    }

    getView(report) {
        //console.log('{report.oq_report_key}', report)
        return <div style={{ flexGrow: 1 }}>
        <Header title={report.report_name} description={report.report_desc} onClick={() => this.props.actions.clearReport()}/>
        {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/"  onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.loadReports()
                        }}>
        Dashboard
        </Link>
        <Typography color="text.primary">{report.report_name}</Typography>
      </Breadcrumbs>
      <br/> */}
       <Grid container spacing={2}>

                <Grid item xs={12}>
                    <iframe
                        src={`${config.oq.uri}${report.oq_dashboard_key}`} width="100%"
                        height={"950px"} style={{ border: "none", alignSelf: "center", }}>
                    </iframe>
                </Grid>
            </Grid>
        </div>
    }

    getLayout() {
        return this.props.state.report ? this.getView(this.props.state.report) : this.getGrid()
    }

    render() {
        return <div style={{ padding: '25px' }}>
            {
                this.props.state.loading
                    ?   'Loading Dashboard...'
                    :   this.getLayout()
            }
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.dashboardsView }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView)