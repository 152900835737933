import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import {
    Routes,
    Route,
    Link,
    Navigate,
    useNavigate 
  } from "react-router-dom";


import AmplifySignIn from '../Amplify/component'
import Auth from '../Auth/component'
import Redirect from '../Redirect/component'
import * as redirectActions from '../Redirect/actions'

const SignedOut = (props) => {    
        let navigate = useNavigate();
        return (
            <div>
                <Redirect navigate={navigate} />
    
                <Routes>
                    <Route path="/" element={<Auth />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        )
    }


const mapStateToProps = (state) => {
    return {
        state: {
            ...state.app
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...redirectActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedOut)