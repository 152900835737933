import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import MenuItem from '@mui/material/MenuItem';
import NativeSelect from '@mui/material/NativeSelect';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container'; 
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Link from '@mui/material/Link';
import Loading from '../Loading/component'
import FormHelperText from '@mui/material/FormHelperText';
import Dialog from '@mui/material/Dialog'

import PublishNote from '../ReleaseNote/publishNote'
import * as styles from './styles'
import * as actions from './actions'
import * as redirectActions from '../Redirect/actions'
import * as navigationMenuActions from "../NavigationMenu/actions";
import * as releaseNoteActions from '../ReleaseNote/actions'


//Functions & Methods
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Innov8-365
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  } 
   
class Account extends Component {
    componentDidMount() {
        this.props.actions.getUserAccounts()
        this.props.actions.setDialogStatus()
    }

    getSelection() {
        return <Container component="main" maxWidth="xs" style={{border:'1px solid #e9ebf0',
         marginTop:30, marginBottom:30, borderRadius:5}}>
        <div style={{marginTop: '100px'}}>
        <Typography component="h1" variant="h5"  style={{textAlign: 'center',marginTop:-3}}>
            {window.t.en('Hi, ')}{this.props.state.user.firstname}
        <hr />
           <div style={{fontSize: '16px'}}>{window.t.en('Please select your default account settings')}</div> 
        </Typography>

        <FormControl variant="outlined" style={{width:'100%', marginTop: 30}}>
        <InputLabel htmlFor="select_language">{window.t.en('Choose Default Language')}</InputLabel>
        <Select
          native
          fullWidth
          onChange={
            (event, value) => {
                this.props.actions.setSelectedSetting({prop: 'error',value: ''})
                this.props.actions.setSelectedSetting({prop: 'locale',value: event.target.value})
            }
        }
          label="Choose Default Language"
          inputProps={{
            name: 'Language',
            id: 'select_language',
          }}
        >
            <option value={'en'}>{'Please select...'}</option>
        {

            this.props.state.locales.map(x => {
                return  <option value={x.code}>{x.locale}</option>
            })
        }
        </Select>
        <FormHelperText style={{color: 'red'}}>{this.props.state.error}</FormHelperText>
      </FormControl>
      
      <FormControl variant="outlined" style={{width:'100%', marginTop: 30}} fullWidth>
        <InputLabel htmlFor="select_account">{window.t.en('Choose Default Account')}</InputLabel>
        <Select
          native
          fullWidth
          onChange={
            (event, value) => {
                this.props.actions.setSelectedSetting({prop: 'error',value: ''})
                this.props.actions.setSelectedSetting({prop: 'account',value: event.target.value})
            }
            }
            label="Choose Default Account"
            inputProps={{
                name: 'Account',
                id: 'select_account',
            }}
            //value={''}
          >
          <option value={0}>{'Please select...'}</option>
            {
                this.props.state.accounts.map(x => {
                    return  <option value={x.client_id}>{x.title}</option>
                })
            }
        </Select>
        
        <FormHelperText style={{color: 'red'}}>{this.props.state.error}</FormHelperText>
      </FormControl>
        <br />
            <Button 
              fullWidth
              variant="contained"
              color="primary"
              style={{marginTop: 30}}
              onClick={
                (event, value) => {
                    this.props.state.account && this.props.state.locale
                    ? this.props.actions.updateUserAccountDefaultSetting({
                        type: 'UPDATE_USER_ACCOUNT_DEFAULT_SETTINGS',
                        user: this.props.state.user,
                        locale: this.props.state.locale,
                        account: this.props.state.account
                    })
                    : this.props.actions.setSelectedSetting({prop: 'error',value: 'Required!'})
                }
            }
            >
             {window.t.en('Accept and Next')}
            </Button>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    }
    getLoading() {
        return <Container component="main" maxWidth="xs">
        {
            this.props.state.user
                ? <div style={{marginTop: '100px'}}>
                <Typography component="h1" variant="h5"  style={{textAlign: 'center'}}>
                    {window.t.en('Hi, ')}{this.props.state.user.firstname}
                <hr />
                    {window.t.en('Please select your default account settings')}
                </Typography>
                <Loading message={window.t.en('Retrieving your account details...')} />
            </div>
            :   <div style={{marginTop: '100px'}}>
                    <Loading className={'login-loader'} message={window.t.en('Retrieving your account details...')} />
                </div>
        }
        </Container>
    }

    getRedirect() {
        
        //this.props.actions.setAppSignin()
        
        return <div style={{marginTop: '100px'}}>
        <Loading className={'login-loader'} message={window.t.en('Retrieving your account details')} />
    </div>
    }
    
    getNavigationMenu() {
        return <div style={{marginTop: '100px'}}>
        <Loading className={'login-loader'} message={window.t.en('Retrieving your account details')} />
    </div>
    }

    render() {
        return this.props.state.user && this.props.state.menu.data.length
            ? (this.props.state.user.defaultaccount && this.props.state.user.defaultlocale)
                ? this.getRedirect()
                : <Dialog
                    open={true}
                    fullWidth={true}
                    maxWidth="lg"
                    BackdropProps={{
                        style: {
                          backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        },
                      }}              
                >{this.getSelection()}</Dialog>
            : <div style={{marginTop: '100px'}}>
            <Loading className={'login-loader'} message={window.t.en('Retrieving your account details')} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.accounts,
            login: {
                ...state.login
            },
            user: {
                ...state.user
            },
            cognito: {
                ...state.cognito
            },
            menu: {
                ...state.navigationMenu
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ 
            ...actions, 
            ...redirectActions,
            ...navigationMenuActions,
            ...releaseNoteActions
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)