import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Icon } from '@mui/material';
import { Container, Row, Col } from 'react-grid-system'

import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';

import * as actions from './actions'
import * as styles from './styles'


class EventView extends Component {

    getEvent() {
        return  <div className="text-center p-1">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select Event Category"}:</div>
                                <div style={styles.fieldContent}>
                                    {this.props.state.category}
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select Component"}:</div>
                                <div style={styles.fieldContent}>
                                    {this.props.state.component}
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Event Name"}:</div>
                                <div style={styles.fieldContent}>
                                    {this.props.state.event_name}
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}></Col>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Event Start Date"}:</div>
                                <div style={styles.fieldContent}>
                                    {this.props.state.start_date}
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Event End Date"}:</div>
                                <div style={styles.fieldContent}>
                                    {this.props.state.end_date}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    getAction() {
        return  <div className="text-center p-1">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select Action"}:</div>
                                <div style={styles.fieldContent}>
                                    {this.props.state.action}
                                </div>
                            </div>
                        </Col>
                        {/* <Col xl={6}>
                            {this.props.state.action === "Ack" && <div>Ack will close the event once it is view</div>}
                            {this.props.state.action === "Action" && <div>Action will allow to view the details in form and will requere updated information</div>}
                            {this.props.state.action === "View" && <div>View is the same as Ack</div>}
                        </Col>
                         */}
                        <Col xl={6}>
                            {
                                this.props.state.actions.length
                                ? <Table className="table" >
                                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                            <th className="text-left">Select Actions</th>
                                        </thead>
                                        <tbody style={{zIndex:1}}>
                                            {
                                                this.props.state.actions.map(x => {
                                                    return <tr>
                                                            <td className="text-left">{x.workqueue_actions}</td>
                                                        </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    : ''
                            }
                        
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    getEscalation() {
        return  <div className="text-center p-1">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select Escalation Type"}:</div>
                                <div style={styles.fieldContent}>
                                    {this.props.state.escalation}
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            {this.props.state.team_list}
                        </Col>
                        <Col xl={12}>
                            {
                                this.props.state.selected_escalation.length 
                                ? this.props.state.escalation === "Team" 
                                ? <Table className="table" >
                                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                            <th className="text-left px-4" style={{width: "50px !important"}}></th>
                                            <th className="text-left">Team Title</th>
                                            <th className="text-left">Team Name</th>
                                        </thead>
                                        <tbody style={{zIndex:1}}>
                                            {
                                                this.props.state.selected_escalation.map(x => {
                                                    return <tr>
                                                            <td className="text-left">
                                                            </td>
                                                            <td className="text-left">{x.title}</td>
                                                            <td className="text-left">{x.description}</td>
                                                        </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    : <Table className="table" >
                                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                            <th className="text-left px-4" style={{width: "50px !important"}}></th>
                                            <th className="text-left">Firstname</th>
                                            <th className="text-left">Lastname</th>
                                            <th className="text-left">Email</th>
                                            <th className="text-left">Phone</th>
                                            <th className="text-left">Jobtitle</th>
                                        </thead>
                                        <tbody style={{zIndex:1}}>
                                            {
                                                this.props.state.selected_escalation.map(x => {
                                                    return <tr>
                                                            <td className="text-left">
                                                            </td>
                                                            <td className="text-left">{x.firstname}</td>
                                                            <td className="text-left">{x.lastname}</td>
                                                            <td className="text-left">{x.email}</td>
                                                            <td className="text-left">{x.phone}</td>
                                                            <td className="text-left">{x.jobtitle}</td>
                                                        </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }
    
    getRule() {
        return  <div className="text-center p-1">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    {
                        this.props.state.component 
                        ? <Row>
                            <Col xl={12} ></Col>
                            <Col xl={12}>
                            {
                                this.props.state.rules.length 
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                        {/* <th className="text-left px-4" style={{width: "50px !important"}}></th> */}
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{zIndex:1}}>
                                        {
                                                this.props.state.rules.map(x => {
                                                    //console.log('component_field_data_type',x)
                                                    return <tr>
                                                        {/* <td className="text-left">
                                                            <IconButton key={'command_remove_' + x.inx} style={styles.iconButton} iconStyle={styles.action}
                                                                onClick={
                                                                    () => {
                                                                        this.props.actions.removeRow(x.inx)
                                                                    }
                                                                }>
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        </td> */}
                                                        <td className="px-4">
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            <Row>
                                                                {/* <Col xl={12} style={{textAlign: "left"}}><h4>Rule Condition</h4></Col>
                                                                        <Col xl={12} style={{textAlign: "left"}}><hr /></Col> */}
                                                                <Col xl={1}>
                                                                    <br/>
                                                                </Col>
                                                                <Col xl={2}>
                                                                    <br/>{x.workqueue_indicator}
                                                                </Col>
                                                                <Col xl={3}>
                                                                    <br/>{x.field_name}
                                                                </Col>
                                                                <Col xl={3}>
                                                                    <br/>{x.operator_text}
                                                                </Col>
                                                                <Col xl={3}>
                                                                    {x.workqueue_field_condition}
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                        </td>
                                                    </tr>                               
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                            }
                            </Col>
                        </Row>
                        : ''
                    }
                </Container>
            </div>
        </div>
    }

    getButton() {
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row style={{float: 'right'}}>
        <Button
            onClick={(event) => {
                event.preventDefault()
                this.props.actions.setInputValue({ prop: 'tab', value: 0 })
            }}
            variant="contained"
            className="btn-warning pull-right d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
            >
            {<Icon iclass={'material-icons'} iname={'cancel'} />} &nbsp; {'Cancel'}
        </Button>
        </Row>
    </Container>
    //  <div className="text-right p-1">
    //         <div className="pt-4">
    //             <Button
    //                 onClick={(event) => {
    //                     event.preventDefault()
    //                     this.props.actions.setInputValue({ prop: 'tab', value: 0 })
    //                 }}
    //                 className=" btn-pill mx-1">
    //                 <span className="btn-wrapper--label">Cancel</span>
    //             </Button>
    //             <Button
    //                 onClick={(event) => {
    //                     event.preventDefault()
    //                     this.props.actions.setInputValue({ prop: 'tab', value: 0 })
    //                     this.props.actions.saveEvent(this.props.state)
    //                 }}
    //                 disabled={this.props.state.saving}
    //                 className="btn-pill mx-1">
    //                 <span className="btn-wrapper--label">{this.props.state.saving ? 'Saving..' : 'Save Event'}</span>
    //             </Button>
    //         </div>
    //         <br />
    //         <br />
    //     </div>
    }

    getLayout() {
        return <div>
            
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col key={'col_'} xl={12}>
                        <br/>
                        
                        </Col>
                    </Row>
                </Container>
                <Card title={window.t.en('Event Details')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getEvent()} />
                <Card title={window.t.en('Event Action')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getAction()} />
                <Card title={window.t.en('Event Escalation')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getEscalation()} />
                <Card title={window.t.en('Event Rule')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getRule()} />
                <Card title={window.t.en('Save Event')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getButton()} />
            </div>
    }
    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.events,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventView)