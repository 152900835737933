import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'


export const getContractParemets = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_CONTRACT_DETAILS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customercontract/search?contractnumber=' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'contractaccessory,contractsundry,mmcode,customercontractdocument.document,customer,financialinstitution',
                    'order': '-contractnumber'
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setContract(result.response),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/customercontract/search?contractnumber=' + payload.query)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontract/search?contractnumber=' + payload.query, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
        export const getAllContractParemets = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(
                ofType(types.GET_All_COMPONENT_CONTRACT_DETAILS),
                delay(0)
                ,mergeMap(({ payload }) =>
                    ajax$({
                        url: config.system.api + '/components/customercontract/search?contractnumber=' + payload + '&&' +'restructure = true',
                        method: 'GET',
                        headers: {
                            'tokenx': store.value.user.user_id,
                            'auth': store.value.cognito.authenticateUser.token,
                            'salt': store.value.cognito.authenticateUser.jwt,
                            'datetime': store.value.cognito.authenticateUser.datetime,
                            'related': 'contractaccessory,contractsundry,mmcode,customercontractdocument.document,customer,financialinstitution',
                            'order': '-contractnumber'
                        },
                    })
                        .pipe(
                    mergeMap((result) => [
                            actions.setAllContractParemets(result.response),
                            //progressBarActions.progress(false),
                            requestActions.requestSucceeded(config.system.api + '/components/customercontract/search?contractnumber=' + payload.query)
                        ])
                        //,startWith(progressBarActions.progress(true))
                        ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontract/search?contractnumber=' + payload.query, error)))
                        //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
                )
                ,catchError((error) =>
                    of$(routerActions.route('/support', false, error))
                )
                )


export const getAccessories = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_CONTRACT_ACCESSORY_LOOKUP_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accessory/search?accessoryname=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAccessories(result.response),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/accessory/search?accessoryname=' + payload)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/accessory/search?accessoryname=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )



export const getAmortisationClientEntry = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_CONTRACT_AMORTISATION_CLIENT_ENTRY),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/amortisationcliententry/search?registrationnumber=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': '-amortisationcliententry_id',
                    'take': '1',
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAmortisationClientEntry(result.response),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/amortisationcliententry/search?registrationnumber=' + payload)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/amortisationcliententry/search?registrationnumber=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getSupplierList = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SUPPLIER_LIST_LOOKUP_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplier/search?suppliername=' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'suppliername',
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setSupplierList(result.response),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/suppliername/search?suppliername=' + payload.query)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/suppliername/search?suppliername=' + payload.query, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getVehicleAmort = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_VEHICLE_AMORT_SCHEDULE_DETAILS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/amortisationcliententry/search?registrationnumber=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': '-cliententrydate'
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setVehicleAmort(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/amortisationcliententry/search?registrationnumber=' + payload)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/amortisationcliententry/search?registrationnumber=' + payload.query, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

