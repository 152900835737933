import * as types from './types'


export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FML_QUOTATION_RELEASE_NOTE_CHECKSHEET_INPUT_VALUE,
            payload: data
        })
    }
}


export const setupFMLWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_FML_QUOTATION_RELEASE_NOTE_CHECKSHEET_INPUT_VALUE,
            payload: payload
        })
    }
}