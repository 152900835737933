export const workflow = {}
export const workflow_queue = {}
export const loading = true
export const task = {}
export const stepIndex = 0
export const steps = 0
export const finished = false
export const edit = false
export const progress = 0
export const validate = 0
export const errors = []
export const validationfields = null

export const validate_state = null
export const confirmEl = null
export const confirm = false

export const dialog_msg = ''
export const dialog_win = false

export const fields = {
  tsk_vehicle_pricing_capture_vehicle: {
    valid: false,
    errorText: 'Please select a Vehicle',
    state: "getState().workflowTaskInput.task.name === 'tsk_vehicle_pricing_capture' ? getState().workflowTaskInput.task?.workflow_task_step[0]?.workflow_task_step_component[0]?.data[0]?.mmcode_id : true"
  },
  tsk_odometer_change_capture_vehicle: {
    valid: false,
    errorText: 'Please select a Vehicle',
    state: "getState().workflowTaskInput.task.name === 'tsk_odometer_change_capture' ? getState().workflowTaskInput.task?.workflow_task_step[0]?.workflow_task_step_component[0]?.data[0]?.vehicle_id : true"
  },
  tsk_registration_change_capture_vehicle: {
    valid: false,
    errorText: 'Please select a Vehicle',
    state: "getState().workflowTaskInput.task.name === 'tsk_registration_change_capture' ? getState().workflowTaskInput.task?.workflow_task_step[0]?.workflow_task_step_component[0]?.data[0]?.vehicle_id : true"
  },
  // tsk_fml_quote_create_customer_quote_customer: {
  //   valid: false,
  //   errorText: 'Please select a Customer',
  //   state: "getState().workflowTaskInput.task.name === 'tsk_fml_quote_create_customer_quote' ? getState().workflowTaskInput.task?.workflow_task_step[0]?.workflow_task_step_component[0]?.data[0]?.customer_id : true"
  // },
  tsk_costcentre_change_capture_vehicle: {
    valid: false,
    errorText: 'Please select a Vehicle',
    state: "getState().workflowTaskInput.task.name === 'tsk_costcentre_change_capture' ? getState().workflowTaskInput.task?.workflow_task_step[0]?.workflow_task_step_component[0]?.data[0]?.vehicle_id : true"
  },
  tsk_accessory_master_capture_supplier: {
    valid: false,
    errorText: 'Please select a Supplier',
    state: "getState().workflowTaskInput.task.name === 'tsk_accessory_master_capture' ? getState().workflowTaskInput.task?.workflow_task_step[0]?.workflow_task_step_component[0]?.data[0]?.supplier_id : true"
  }
}