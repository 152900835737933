export const GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS'
export const SET_USER_ACCOUNTS = 'SET_USER_ACCOUNTS'

export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT'
export const SET_DEFAULT_ACCOUNT = 'SET_DEFAULT_ACCOUNT'
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT'
export const CLEAR_ACCOUNT_SELECTION = 'CLEAR_ACCOUNT_SELECTION'
export const UPDATE_USER_ACCOUNT_DEFAULT_SETTINGS = 'UPDATE_USER_ACCOUNT_DEFAULT_SETTINGS'
export const SET_USER_ACCOUNT_DEFAULT_SETTING_INPUT = 'SET_USER_ACCOUNT_DEFAULT_SETTING_INPUT'


export const CLIENTS_GET_LOCALE_LIST = 'CLIENTS_GET_LOCALE_LIST'
export const CLIENTS_SET_LOCALE_LIST = 'CLIENTS_SET_LOCALE_LIST'
export const USER_SELECTED_ACCOUNT = 'USER_SELECTED_ACCOUNT'

export const UPDATE_ORGANISATION_SETTINGS = 'UPDATE_ORGANISATION_SETTINGS'
