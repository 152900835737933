import * as props from './props'
import * as types from './types'
import * as pdfTypes from '../PDF/types'

import * as workflowQuoteHeaderTypes from '../WorkflowQuoteHeader/types'
import * as workflowTaskStepComponentSingleSelectorTypes from '../WorkflowTaskStepComponentSingleSelector/types'
import * as workflowTaskStepQuoteSingleSelectorTypes from '../WorkflowTaskStepQuoteSingleSelector/types'
import * as workflowTaskStepComponentMultipleSelectorTypes from '../WorkflowTaskStepComponentMultipleSelector/types'
import * as workflowTaskStepComponentSingleCreatorTypes from '../WorkflowTaskStepComponentSingleCreator/types'
import * as workflowTaskStepComponentQuoteCreator from '../WorkflowTaskStepComponentQuoteCreator/types'
import * as workflowTaskStepComponentMultipleCreatorTypes from '../WorkflowTaskStepComponentMultipleCreator/types'
import * as workflowViewFMLQuoteConfirmTypes from '../WorkflowViewFMLQuoteConfirm/types'
import * as workflowPOAccessoriesConfirmTypes from '../WorkflowPOAccessoriesConfirm/types'
import * as workflowPOReceiptVehicleConfirmTypes from '../WorkflowPOReceiptVehicleConfirm/types'
import * as workflowPOReceiptAccessoriesTypes from '../WorkflowPOReceiptAccessories/types'
import * as workflowPOContractActivationConfirmTypes from '../WorkflowPOContractActivationConfirm/types'
import * as workflowTaskApprovalTypes from '../WorkflowTaskApproval/types'
import * as poBookingConfirm from '../WorkflowPOHeader/POBookingConfirm/types'
import * as poBookingInvoiceConfirm from '../WorkflowPOHeader/POBookingInvoiceConfirm/types'
import * as captureSTRConfirmTypes from '../WorkflowSTRHeader/CaptureSTRConfirm/types'
import * as workflowQuoteConfirmTypes from '../WorkflowQuoteConfirm/types'
import * as invoiceapConfirmTypes from '../InvoiceapConfirm/types'
import * as invoiceapRejectConfirmTypes from '../InvoiceapRejectConfirm/types'
import * as createMMQuoteTypes from '../WorkflowManageMaintenance/CreateMMQuoteConfirm/types'
import * as contractRestructureConfirm from '../WorkflowContractRestructure/ContractRestructureConfirm/types'
import * as defleetGeneratechecksheetConfirm from '../WorkflowDefleet/DefleetGenerateChecksheet/types'
import * as defleetUploadchecksheetConfirm from '../WorkflowDefleet/DefleetUploadChecksheetConfirm/types'
import * as paymentCapture from '../WorkflowCreditorsPayment/PaymentCapture/types'
import * as purchaseOrderCapture from '../WorkflowCreditorsPayment/PurchaseOrderCapture/types'
import * as billingrunConfirm from '../WorkflowBillingRun/BillingRunStartConfirm/types'
import * as defleetUploadQuotesConfirm from '../WorkflowDefleet/DefleetUploadQuotesConfirm/types'
import * as workflowCreateQuoteTypes from '../WorkflowFMLQuote/WorkflowCreateQuote/types'
import * as workflowSTRCreateQuoteTypes from '../WorkflowSTRQuote/WorkflowCreateQuote/types'
import * as loadpaymentConfirm from '../Custom/SUPPLIERPAYMENTCONFIRMATION/types'
import * as supplierMasterCaptureTypes from '../SupplierMaster/SupplierMasterCapture/types'
import * as customerMasterCaptureTypes from '../CustomerMaster/CustomerMasterCapture/types'
import * as salesProcessCaptureTypes from '../SalesProcess/SalesProcessCapture/types'
import * as accessoryMasterCapture from '../AccessoryMaster/AccessoryMasterCapture/types'
import * as vehicleMasterCapture from '../VehicleMaster/VehicleMasterCapture/types'
import * as costCentreChangeCapture from '../CostCentreChange/CostCentreChangeCapture/types'
import * as odometerChangeCapture from '../OdometerChange/OdometerChangeCapture/types'
import * as registrationChangeCapture from '../RegistrationChange/RegistrationChangeCapture/types'
import * as creditorsPaymentWorkflowCreate from '../CreditorsPayment/CreditorsPaymentCreate/types'
import * as workflowFMLReleaseNoteTypes from '../WorkflowFMLReleaseNote/types'
import * as creditNoteCreateTypes from '../CreditNote/CreditNoteCreate/types'
import * as interestRateCreateTypes from '../InterestRate/InterestRateCreate/types'
import * as cashbookArchiveCreateTypes from '../CashbookArchive/CashbookArchiveCreate/types'

import * as _ from 'lodash'

export default (state = props, action) => {
    switch (action.type) {
        case workflowTaskApprovalTypes.CLEAR_WORKFLOW_TASK_APPROVAL_DATA:
        case types.CLEAR_WORKFLOW_TASK_INFORMATION:
            return {
                edit: false,
                errors: [],
                finished: false,
                loading: true,
                progress: 0,
                stepIndex: 0,
                steps: 0,
                task: {},
                validate: 0,
                validationfields: null,
                workflow: {},
                workflow_queue: {},
                fields: props.fields
            }
        case types.GET_WORKFLOW_TASK_INFORMATION:
            return {
                ...state,
                loading: true,
                task: {
                    loading: true
                }
            }
        case types.SET_WORKFLOW_TASK_INFORMATION:
            return {
                ...state,
                error: action.payload ? false : true,
                loading: false,
                task: {
                    ...action.payload,
                    workflow_task_step: action.payload.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: []
                                }
                            })
                        }
                    }),
                    components: _.uniq(
                        action.payload.workflow_task_step.reduce((arr, x) => {
                            x.workflow_task_step_component.forEach(y => {
                                arr.push(y.component.name)
                            })

                            return arr
                        }, [])
                    )
                }
            }
        case types.SET_WORKFLOW_TASK_CONNECTOR_DATA:
            return {
                ...state,
                workflow_task_connector: action.payload.workflow_task_connector,
                workflow_task_connector_id: action.payload.workflow_task_connector_id
            }
        case types.CLEAR_WORKFLOW_TASK_ACTION_DATA:
            return {
                ...state,
                loading: true,
                workflow_queue: action.payload,
                task: {
                    loading: true
                }
            }
        case types.GET_WORKFLOW_TASK_ACTION_DATA:
            return {
                ...state,
                loading: true
            }
        case types.SET_COMPONENT_VALIDATION_PROPS_FIELDS:
            return {
                ...state,
                validate_state: action.payload
            }
        case types.SET_CONFIRM_TOGGLE_MENU_STATUS:
            return {
                ...state,
                confirm: action.payload.confirm,
                confirmEl: action.payload.confirmEl
            }
        case types.SET_WORKFLOW_TASK_ACTION_DATA:
            return {
                ...state,
                loading: false,
                workflow_queue: action.payload
            }
        case types.TOGGLE_NOTIFICATION_DIALOG:
            return {
                ...state,
                dialog_win: true,
                dialog_msg: action.payload
            }
        case types.CLOSE_NOTIFICATION_DIALOG:
            return {
                ...state,
                dialog_win: false,
                dialog_msg: null
            }
            case types.TOGGLE_CUSTOMER_NOTIFICATION_DIALOG:
                return {
                    ...state,
                    customer_inactive_win: action.payload.status,
                    customer_inactive_msg: action.payload.message
                }
        case workflowPOReceiptVehicleConfirmTypes.CONFIRM_PO_RECEIPT_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        vehiclereceipt: action.payload.selected
                                    }
                                }
                            })
                        }
                    })
                }
            }
        case workflowViewFMLQuoteConfirmTypes.CONFIRM_PO_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }

        case captureSTRConfirmTypes.CONFIRM_STR_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    }
                                }
                            })
                        }
                    })
                }
            }
        case workflowQuoteConfirmTypes.CONFIRM_FML_QUOTATION_HEADER_DATA:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    }
                                }
                            })
                        }
                    })
                }
            }
        case createMMQuoteTypes.CONFIRM_MM_CONTRACT_HEADER_SELECTION:
            window.glyco.log('CONFIRM_MM_CONTRACT_HEADER_SELECTION')
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
        case invoiceapConfirmTypes.CONFIRM_INVOICE_AP_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    }
                                }
                            })
                        }
                    })
                }
            }
        case invoiceapRejectConfirmTypes.CONFIRM_INVOICE_AP_REJECTION_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    }
                                }
                            })
                        }
                    })
                }
            }
        case poBookingConfirm.CONFIRM_VEHICLE_BOOKING_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
        case contractRestructureConfirm.CONFIRM_CONTRACT_RESTRUCTURE_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
        case poBookingInvoiceConfirm.CONFIRM_INVOICE_BOOKING_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
            
        case workflowCreateQuoteTypes.CONFIRM_WORKFLOW_CRAETE_FML_QUOTATION_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
        case workflowSTRCreateQuoteTypes.CONFIRM_WORKFLOW_CRAETE_STR_QUOTATION_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
        case defleetGeneratechecksheetConfirm.CONFIRM_GENERATE_CHECKSHEET:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
            case loadpaymentConfirm.CONFIRM_PAYMENT_LOAD:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                }
        case defleetUploadchecksheetConfirm.CONFIRM_UPLOAD_CHECKSHEET:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            } 
        case billingrunConfirm.CONFIRM_BILLING_INITIATION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            } 
        case accessoryMasterCapture.CONFIRM_ACCESSORY_MASTER_CAPTURE:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            } 
        case costCentreChangeCapture.CONFIRM_COSTCENTRE_CHANGE:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            } 
        case workflowFMLReleaseNoteTypes.CONFIRM_FML_QUOTATION_RELEASE_NOTE_CHECKSHEET_INPUT_VALUE:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            } 
        case odometerChangeCapture.CONFIRM_ODOMETER_CHANGE:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            } 
        case registrationChangeCapture.CONFIRM_REGISTRATION_CHANGE:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            } 
        case vehicleMasterCapture.CONFIRM_VEHICLE_MASTER_CAPTURE:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            } 
            case supplierMasterCaptureTypes.CONFIRM_SUPPLIER_MASTER_CAPTURE:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                } 
            case customerMasterCaptureTypes.CONFIRM_CUSTOMER_MASTER_CAPTURE:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                } 
            case salesProcessCaptureTypes.CONFIRM_SALES_PROCESS_CAPTURE:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                } 
            case paymentCapture.CONFIRM_CREDITORS_PAYMENT_CAPTURE:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                } 
            case creditorsPaymentWorkflowCreate.CONFIRM_CREDITORS_PAYMENT_WORKFLOW_CREATE:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                } 
            case creditNoteCreateTypes.CONFIRM_CREDIT_NOTE_CREATE:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                } 
            case interestRateCreateTypes.CONFIRM_INTEREST_RATE_CREATE:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                } 
            case cashbookArchiveCreateTypes.CONFIRM_CASH_BOOK_ARCHIVE_CREATE:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                } 
            case purchaseOrderCapture.CONFIRM_CREDITORS_PAYMENT_CAPTURE:
                return {
                    ...state,
                    task: {
                        ...state.task,
                        workflow_task_step: state.task.workflow_task_step.map(x => {
                            return {
                                ...x,
                                workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                    return {
                                        ...y,
                                        data: {
                                            ...y.data,
                                            confirm: action.payload.selected
                                        },
                                        meta: action.payload.meta
                                    }
                                })
                            }
                        })
                    }
                } 
         case defleetUploadQuotesConfirm.CONFIRM_UPLOAD_QUOTES:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    },
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
        case workflowPOContractActivationConfirmTypes.CONFIRM_CONTRACT_ACTIVATION_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        activation: action.payload.selected
                                    }
                                }
                            })
                        }
                    })
                }
            }
        case workflowPOReceiptAccessoriesTypes.CONFIRM_ACCESSORY_RECEIPT_PO_DOCUMENT_UPLOAD:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        accessory_pos: action.payload.selected
                                    }
                                }
                            })
                        }
                    })
                }
            }

        case workflowPOAccessoriesConfirmTypes.CONFIRM_PO_ACCESSORY_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: {
                                        ...y.data,
                                        confirm: action.payload.selected
                                    }
                                }
                            })
                        }
                    })
                }
            }
        case workflowQuoteHeaderTypes.CONFIRM_QUOTE_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {

                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? action.payload.selected
                                        : y.data,
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
        case workflowQuoteHeaderTypes.RESET_QUOTE_HEADER_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? []
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepComponentSingleSelectorTypes.CONFIRM_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? action.payload.selected
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepComponentSingleSelectorTypes.RESET_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? []
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepQuoteSingleSelectorTypes.CONFIRM_QUOTE_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? action.payload.selected
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepQuoteSingleSelectorTypes.RESET_QUOTE_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? []
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepComponentMultipleSelectorTypes.CONFIRM_MULTIPLE_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? y.data.concat(action.payload.selected)
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepComponentQuoteCreator.CONFIRM_QUOTE_ITEMS_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? y.data.concat(action.payload.selected)
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskApprovalTypes.CONFIRM_QUOTE_EDIT_ITEMS_SELECTION:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? y.data.concat(action.payload.selected)
                                        : y.data,
                                    meta: action.payload.meta
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepComponentMultipleSelectorTypes.REMOVE_SELECTED_ITEM:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? y.data.filter((x, i) => i !== action.payload.index).map(x => { return x })
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepComponentMultipleCreatorTypes.ADD_COMPONENT_ROW:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? y.data.concat(action.payload.row)
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepComponentMultipleCreatorTypes.REMOVE_COMPONENT_ROW:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? y.data.filter((x, i) => i !== action.payload.index).map(x => { return x })
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepComponentSingleCreatorTypes.CONFIRM_SINGLE_ROW:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? [action.payload.row]
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case workflowTaskStepComponentSingleCreatorTypes.REMOVE_SINGLE_ROW:
            return {
                ...state,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: x.name === action.payload.step && y.component.name === action.payload.component
                                        ? y.data.filter((x, i) => i !== action.payload.index).map(x => { return x })
                                        : y.data
                                }
                            })
                        }
                    })
                }
            }
        case types.SET_WORKFLOW:
            return {
                ...state,
                workflow: action.payload
            }
        case types.SET_TASK:
            return {
                ...state,
                task: {
                    ...action.payload,
                    loading: false,
                    components: _.uniq(
                        action.payload.workflow_task_step.reduce((arr, x) => {
                            x.workflow_task_step_component.forEach(y => {
                                arr.push(y.component.name)
                            })

                            return arr
                        }, [])
                    )
                },
            }
        case types.SET_TOTAL_STEPS:
            return {
                ...state,
                steps: action.payload
            }
        case types.RESET_WORKFLOW_TASK_WIZARD:
            return {
                ...state,
                stepIndex: 0,
                finished: false,
                progress: 0,
                task: {
                    ...state.task,
                    workflow_task_step: state.task.workflow_task_step.map(x => {
                        return {
                            ...x,
                            workflow_task_step_component: x.workflow_task_step_component.map(y => {
                                return {
                                    ...y,
                                    data: []
                                }
                            })
                        }
                    })
                }
            }
        case types.GO_TO_NEXT_STEP:
            return {
                ...state,
                stepIndex: action.payload.stepIndex,
                finished: action.payload.finished,
                progress: action.payload.progress
            }
        case types.GO_TO_PREVIOUS_STEP:
            return {
                ...state,
                stepIndex: action.payload.stepIndex,
                progress: action.payload.progress
            }
        case types.SHOW_COMPONENT_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case pdfTypes.OPEN_QUOTE_EDIT_VIEW:
            return {
                ...state,
                edit: true
            }
        case pdfTypes.CLOSE_QUOTE_EDIT_VIEW:
            return {
                ...state,
                edit: false
            }
        default:
            return state
    }
}