export const period = {
    errorText: 'Period field required',
    value: '',
    valid: false,
    empty: true,
}
export const terminationdate = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const distancepermonth = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const contractdistance = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const retailprice = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_retailprice = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_retailprice = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const discountamount = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_discountamount = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_discountamount = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const vehicleprice = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_vehicleprice = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_vehicleprice = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const capitalbalance = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_capitalbalance = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_capitalbalance = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const accessoriesselling = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const serviceselling = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const sundryselling = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_sundryselling = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_sundryselling = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const interestrate = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_interestrate = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_interestrate = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const rv = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const rvamount = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const finance = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const maintenance = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const tyres = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const licence = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_licence = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_licence = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const service = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const adminfee = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const funderadminfee = {
    errorText: 'Field required',
    value: 0,
    valid: false,
    empty: true,
}
export const six_month_adminfee = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_adminfee = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const funder_adminfee = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_funder_adminfee = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_funder_adminfee = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const roi = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const monthly_excl = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const monthly_vat  = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const monthly_incl  = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_monthly_incl  = {
    errorText: 'Field required',
    value: [],
    valid: false,
    empty: true,
}
export const ltd_monthly_incl  = {
    errorText: 'Field required',
    value: [],
    valid: false,
    empty: true,
}
export const financeaccess = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const maintenanceaccess = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const excess = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_monthly_excess = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}

export const recovery = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const contractaccessory = {
    errorText: 'Field required',
    value: [],
    valid: false,
    empty: true,
}
export const contractsundry = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const mmcode = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const makemodelyear = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const imagefilename = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}

export const effectivedate = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}

export const expirydate = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}

export const startdate = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}

export const remainingbillingcount = {
    errorText: 'Field required',
    value: '',
    valid: false,
    empty: true,
}
export const billingcount = {
    errorText: 'Field required',
    value: 0,
    valid: false,
    empty: true,
}
export const supplier_id = {
    errorText: 'Field required',
    value: 0,
    valid: false,
    empty: true,
}

export const roiper = {
    errorText: 'Field required',
    value: 16,
    valid: false,
    empty: true,
}
export const six_month_roiper = {
    errorText: 'Field required',
    value: 16,
    valid: false,
    empty: true,
}
export const ltd_roiper = {
    errorText: 'Field required',
    value: 16,
    valid: false,
    empty: true,
}

export const restructuretype = {
    errorText: 'Field required',
    value: false,
    valid: false,
    empty: true,
}
export const ltd_restructuretype = {
    errorText: 'Field required',
    value: false,
    valid: false,
    empty: true,
}
export const zero_value_accessory = {
    errorText: 'Field required',
    value: false,
    valid: false,
    empty: true,
}
export const six_month_recommendation = {
    errorText: 'Field required',
    value: false,
    valid: false,
    empty: true,
}
export const ltd_recommendation = {
    errorText: 'Field required',
    value: false,
    valid: false,
    empty: true,
}

export const maint_cpk = {
    errorText: 'Field required',
    value: 0,
    valid: false,
    empty: true,
}

export const tyres_cpk = {
    errorText: 'Field required',
    value: 0,
    valid: false,
    empty: true,
}

export const contract = {
    errorText: '',
    value: [],
    valid: false,
    empty: true,
}
export const ltd_period = {
    errorText: '',
    value: [],
    valid: false,
    empty: true,
}
export const ltd_kms = {
    errorText: '',
    value: [],
    valid: false,
    empty: true,
}
export const ltd_contractdistance = {
    errorText: '',
    value: [],
    valid: false,
    empty: true,
}
export const ltd_terminationdate = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_period = {
    errorText: '',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_kms = {
    errorText: '',
    value: [],
    valid: false,
    empty: true,
}
export const six_month_contractdistance = {
    errorText: '',
    value: [],
    valid: false,
    empty: true,
}
export const six_month_terminationdate = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_rvamount = {
    errorText: 'field required',
    value: [],
    valid: false,
    empty: true,
}
export const six_month_rv = {
    errorText: 'field required',
    value: [],
    valid: false,
    empty: true,
}
export const six_month_serviceselling = {
    errorText: 'field required',
    value: [],
    valid: false,
    empty: true,
}
export const six_month_maintenance = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_tyres = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_service = {
    errorText: 'field required',
    value:  '',
    valid: false,
    empty: true,
}
export const six_month_roi = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_finance = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_monthly_excl = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_monthly_vat = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_monthly_inc = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_financeaccess = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_maintenanceaccess = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_excess = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_accessoriesselling = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_rvamount = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_rv = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_serviceselling = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_maintenance = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_tyres = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_service = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_roi = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_finance = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_monthly_excl = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_monthly_vat = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_monthly_inc = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_financeaccess = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_maintenanceaccess = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_excess = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_accessoriesselling = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const ltd_roiamount = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}
export const six_month_roiamount = {
    errorText: 'field required',
    value: '',
    valid: false,
    empty: true,
}

export const accessories = []
export const suppliers = []
export const vehicleamort = []

export const validation = {
    fields: ['period',
'zero_value_acessory',
'ltd_period',
'six_month_period',
'ltd_kms ',
'ltd_contractdistance',
'ltd_terminationdate',
'terminationdate',
'averagedistancepermonth',
'distancepermonth',
'distance',
'vehicleprice',
'accessoriesselling',
'sundryselling',
'sellingtotal',
'sellingvat',
'deposit',
'depositcapital',
'rv',
'rvamount',
'finance',
'maintenance',
'tyres',
'licence',
'service',
'adminfee',
'monthly_excl',
'roi',
'monthly_vat',
'monthly_incl',
'financeaccess',
'maintenanceaccess',
'recovery'],
list: [],
component: 'captureSTR',
valid: false
}

export const fields = {
    recommendationOneOptionSelected: {
        errorText: 'Please select 1 contract recommendation, you have currently selected both',
        value: false,
        valid: false,
        empty: true,
        state: "!(getState().contractRestructure.ltd_recommendation.value && getState().contractRestructure.six_month_recommendation.value)",
    },
    recommendation: {
        errorText: 'Please select a contract recommendation',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_recommendation.value || getState().contractRestructure.six_month_recommendation.value",
    },
    // roiLtd: {
    //     errorText: 'Please input LTD Average ROI',
    //     value: false,
    //     valid: false,
    //     empty: true,
    //     state: "getState().contractRestructure.ltd_roi.value",
    // },
    // roiSix: {
    //     errorText: 'Please input Six Month Average ROI',
    //     value: false,
    //     valid: false,
    //     empty: true,
    //     state: "getState().contractRestructure.six_month_roi.value",
    // },
    funder_adminfeeLtd: {
        errorText: 'Please input LTD Average Funder Admin Fee',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_funder_adminfee.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Funder Admin Fee'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Funder Admin Fee greater than zero'
        }]
    },
    funder_adminfeeSix: {
        errorText: 'Please input Six Month Average Funder Admin Fee',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_funder_adminfee.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Funder Admin Fee'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Funder Admin Fee greater than zero'
        }]
    },
    adminfeeLtd: {
        errorText: 'Please input LTD Average Admin Fee',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_adminfee.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Admin Fee'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Admin Fee greater than zero'
        }]
    },
    adminfeeSix: {
        errorText: 'Please input Six Month Average Admin Fee',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_adminfee.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Admin Fee'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Admin Fee greater than zero'
        }]
    },
    // serviceLtd: {
    //     errorText: 'Please input LTD Average Service Amount',
    //     value: false,
    //     valid: true,
    //     empty: true,
    //     state: "getState().contractRestructure.ltd_service.value",
    // },
    // serviceSix: {
    //     errorText: 'Please input Six Month Average Service Amount',
    //     value: false,
    //     valid: true,
    //     empty: true,
    //     state: "getState().contractRestructure.six_month_service.value",
    // },
    licenceLtd: {
        errorText: 'Please input LTD Average Licence',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_licence.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Licence'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Licence License greater than zero'
        }]
    },
    licenceSix: {
        errorText: 'Please input Six Month Average License',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_licence.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average License'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average License greater than zero'
        }]
    },
    tyresLtd: {
        errorText: 'Please input LTD Average ROI Maintenance',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_tyres.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average ROI Maintenance'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average ROI Maintenance greater than zero'
        }]
    },
    tyresSix: {
        errorText: 'Please input Six Month Average ROI Maintenance',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_tyres.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average ROI Maintenance'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average ROI Maintenance greater than zero'
        }]
    },
    maintenanceLtd: {
        errorText: 'Please input LTD Average Maintenance',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_maintenance.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Maintenance'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Maintenance greater than zero'
        }]
    },
    maintenanceSix: {
        errorText: 'Please input Six Month Average Maintenance',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_maintenance.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Maintenance'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Maintenance greater than zero'
        }]
    },
    // sundrysellingLtd: {
    //     errorText: 'Please input LTD Average Sundry Selling',
    //     value: false,
    //     valid: true,
    //     empty: true,
    //     state: "getState().contractRestructure.ltd_sundryselling.value",
    // },
    // sundrysellingSix: {
    //     errorText: 'Please input Six Month Average Sundry Selling',
    //     value: false,
    //     valid: true,
    //     empty: true,
    //     state: "getState().contractRestructure.six_month_sundryselling.value",
    // },

    // roiperLtd: {
    //     errorText: 'Please input LTD Average ROI Amount',
    //     value: false,
    //     valid: false,
    //     empty: true,
    //     state: "getState().contractRestructure.ltd_roiper.value",
    // },
    // roiperSix: {
    //     errorText: 'Please input Six Month Average ROI Amount',
    //     value: false,
    //     valid: false,
    //     empty: true,
    //     state: "getState().contractRestructure.six_month_roiper.value",
    // },
    interestrateLtd: {
        errorText: 'Please input LTD Average Interest Rate',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_interestrate.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Interest Rate'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Interest Rate greater than zero'
        }]
    },
    interestrateSix: {
        errorText: 'Please input Six Month Average Interest Rate',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_interestrate.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Interest Rate'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Interest Rate greater than zero'
        }]
    },
    rvAmountLtd: {
        errorText: 'Please input LTD Average Residual Value Amount',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_rvamount.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Residual Value Amount'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Residual Value Amount greater than zero'
        }]
    },
    rvAmountSix: {
        errorText: 'Please input Six Month Average Residual Value Amount',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_rvamount.value",
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Residual Value Amount'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Residual Value Amount greater than zero'
        }]
    },
    rvLtd: {
        errorText: 'Please input LTD Average Residual Value',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_rv.value",
        validations :[{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Residual Value'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Residual Value greater than zero'
        }]
    },
    rvSix: {
        errorText: 'Please input Six Month Average Residual Value',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_rv.value",
        validations :[{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Residual Value'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Residual Value greater than zero'
        }]
    },
    // capitalbalanceLtd: {
    //     errorText: 'Please input LTD Average Capital Outstanding Balance',
    //     value: false,
    //     valid: false,
    //     empty: true,
    //     state: "getState().contractRestructure.ltd_capitalbalance.value",
    // },
    // capitalbalanceSix: {
    //     errorText: 'Please input Six Month Average Capital Outstanding Balance',
    //     value: false,
    //     valid: false,
    //     empty: true,
    //     state: "getState().contractRestructure.six_month_capitalbalance.value",
    // },

    accessorySupplier: {
        errorText: 'Please ensure Accessory Supplier is selected',
        state: 'getState().contractRestructure.contractaccessory.value.length ? getState().contractRestructure.contractaccessory.value : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['supplier_id']
    },
    accessorySellingPrice: {
        errorText: 'Please enter Accessory Selling Price',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['amount'],
        state: 'getState().contractRestructure.contractaccessory.value.length ? getState().contractRestructure.contractaccessory.value : true',
        validations :[{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Accessory Selling Price'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Accessory Selling Price greater than zero'
        }]
    },
    accessoryName: {
        errorText: 'Please input Six Month Average Residual Value',
        value: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['accessoryname'],
        state: 'getState().contractRestructure.contractaccessory.value.length ? getState().contractRestructure.contractaccessory.value : true'
    },

    distanceLtd: {
        errorText: 'Please input LTD Average Distance Per Month',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_kms.value",
        validations :[{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Distance Per Month'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Average Distance Per Month greater than zero'
        }]
    },
    distanceSix: {
        errorText: 'Please input Six Month Average Distance Per Month',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_kms.value",
        validations :[{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Distance Per Month'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Average Distance Per Month greater than zero'
        }]
    },
    periodLtd: {
        errorText: 'Please input LTD Period',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_period.value",
        validations :[{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Month Period'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid LTD Month Period greater than zero'
        }]
    },
    periodSix: {
        errorText: 'Please input Six Month Period',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_period.value",
        validations :[{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Period'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Six Month Period greater than zero'
        }]
    },
    endDateLtd: {
        errorText: 'Please input LTD Average End Date',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.ltd_terminationdate.value",
    },
    endDateSix: {
        errorText: 'Please input Six Month Average End Date',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractRestructure.six_month_terminationdate.value",
    },
}