import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, Dialog,CardHeader } from '@mui/material'

import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import PaymentItem from '../PaymentItem/component'
import * as styles from './styles'
import * as actions from './actions'

class PaymentCapture extends Component {

    componentDidMount() {
        let data = this.props.workflow_queue_data.context_data.data.data.data
        this.props.actions.setInputValue('date', moment().format('YYYY-MM-DD'))
        this.props.actions.setInputValue('month', moment().format('YYMM'))
        this.props.actions.setInputValue('invoice_number', data.step1.reference)
        this.props.actions.setInputValue('invoice_amount', data.step1.amount_incl)
        this.props.actions.resetComponent()
    }

    getDuplicateData(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th>Auth No</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Odometer</th>
                                    <th>Task No</th>
                                    <th>Booked By</th>
                                    <th>Booked Date</th>
                                    <th>Supplier</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map(x => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.authnumber}</td>
                                                <td>{x.maintenancetype}</td>
                                                <td>{x.comments}</td>
                                                <td>{x.odometer}</td>
                                                <td>{x.tasknumber}</td>
                                                <td>{x.bookedby}</td>
                                                <td>{x.bookeddate}</td>
                                                <td>{x.suppliertype}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    }  

    invoiceItems(data) {
        //console.log('invoiceItems, data', data)
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Unit</th>
                                    <th>Unit Price</th>
                                    <th>Quantity</th>
                                    <th>Vat</th>
                                    <th>Total</th>
                                    <th>Account</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map(x => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                <td>{x.Description}</td>
                                                <td>{x.unitmeasure}</td>
                                                <td>{x.unitprice}</td>
                                                <td>{x.quantity}</td>
                                                <td>{x.vat}</td>
                                                <td>{x.total}</td>
                                                <td>{x.accountingitem}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    } 

    getForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                            <Col xl={4}>

                                        <DateControl
                                                id={'date'}
                                                key={'date'}
                                                label={'Enter transaction date'}
                                                name={'date'}
                                                fullWidth={true}
                                                //error={this.props.state.fields.date.value ? false : true}
                                                //helperText={this.props.state.fields.date.value ? '' : 'Enter transaction date'}
                                                value={this.props.state.date}
                                                onSelect={(args) => {
                                                    this.props.actions.setInputValue('date',args)
                                                }}
                                                type={'date'}
                                            />

                             
                             </Col>
                            <Col xl={4} style = {{marginTop : '15px' }}>
                                    <TextField variant="outlined" 
                                            id={'month'}
                                            label={'Enter transaction month'}
                                            fullWidth={true}
                                            multiLine={false}
                                            //error={isNaN(this.props.state.fields.month.value) ? true : false}
                                            //helperText={isNaN(this.props.state.fields.month.value) ? '' : 'Enter month (YYYYMM)'}
                                            value={this.props.state.month}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue('month',event.target.value)
                                            }}
                                        />
                             </Col>
                             <Col xl={4} style = {{marginTop : '15px' }}>
                                    <TextField variant="outlined" 
                                            id={'reference'}
                                            label={'Enter Invoice Number'}
                                            fullWidth={true}
                                            multiLine={false}
                                            //error={this.props.state.fields.invoice_number.value ? false : true}
                                            //helperText={this.props.state.fields.invoice_number.value ? '' : 'Enter invoice number'}
                                            value={this.props.state.invoice_number}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue('invoice_number',event.target.value)
                                            }}
                                        />
                             </Col>
                            <Col xl={4}>
                                    <TextField variant="outlined" 
                                            id={'description'}
                                            label={'Invoice Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            //error={this.props.state.fields.invoice_amount.value ? false : true}
                                            //helperText={this.props.state.fields.invoice_amount.value ? '' : 'Enter amount'}
                                            value={this.props.state.invoice_amount}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue('invoice_amount',event.target.value)
                                            }}
                                        />
                             </Col>
                            </Row>
                        </Container>
    }

    viewForm() {
       return <Container style={{ margin: 0 }} className="custom-container">
       <Row>
        <Col  xl={12} >
        <CardHeader  style={{backgroundColor: '#ff4545', color: 'white'}}
                actAsExpander={false}
                showExpandableButton={false}
                title={<div style={{ fontSize: "18px", color: "white" }}>{window.t.en('Invoice Details')} </div>}
                subheader={<div style={{ fontSize: "13px", color: "white" }}>{window.t.en('Capture supplier details')}</div>}>
                     </CardHeader>
                <div >
                <Fields labelsize='130'
                fields={[
                    {size: 4, label: 'Supplier:', value: this.data.step1.supplier.suppliername},
                    {size: 4, label: 'Date', value: this.data.step1.date},
                    {size: 4, label: 'Reference', value: this.data.step1.reference},
                    {size: 4, label: 'Description', value: this.data.step1.description},
                    {size: 4, label: 'Pre Auth:', value: this.data.step1.preauthnumber},
                    {size: 4, label: 'Amount (Excl)', value: this.data.step1.amount_excl},
                    {size: 4, label: 'Vat', value: this.data.step1.amount_vat},
                    {size: 4, label: 'Amount (Incl)', value: this.data.step1.amount_incl},
                ]}
                /></div>
                    </Col>
                    <Col  xl={12} style={{textAlign:'right !important'}}>
                         {
                            this.data.step1.supplier_id && <ViewSupplier 
                                min={true}
                                key={this.data.step1.supplier_id} 
                                id={this.data.step1.supplier_id} 
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                    </Col>
                </Row>
            </Container>
    }

    
    getView() {
        return <div>
            <Card
            title={'Capture Payment Details'}
            subtitle={'Payment internal reference ' + this.props.state.preauth}
            content={this.getForm()}
        />
        <Card
            title={'Payment Items'}
            subtitle={'Capture payment items'}
            content={<PaymentItem />}
        />
        <Card
            title={'Invoice Details'}
            subtitle={'Creditor capture invoice details'}
            content={this.viewForm()}
        />
        <Card
            title={'Upload Document'}
            subtitle={'Upload creditor payment supporting document: '}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                <Col xl={12}>
                    <Upload
                        id={'upload_ctl'}
                        prefix={'Creditor_Attach_'}
                        header={false}
                    />
                </Col>
            </Row></Container>}
        />
        </div>
    }

    getConfirm() {
        //console.log('this.props.state.upload.documents', this.props.state.upload.documents)
        return <div>
            <Card
            title={'Capture Payment Details'}
            subtitle={'Payment internal reference ' + this.data.step1.preauthnumber}
            content={this.viewForm()}
        />
        <Card
            title={'Payment Items'}
            subtitle={'Capture payment items'}
            content={this.invoiceItems(this.data.step1.items)}
        />
        <Card
            title={'Purchase Document Document'}
            subtitle={'Uploaded Document On Purchase Document'}
            content={<PdfViewer             
                key={'document_viewer_uploaded_file'}
                name={'tsk_billing_run_start'}
                workflow={'billing_run'}
                task={'tsk_billing_run_start'}
                documents={this.data.documents}
                multiple={true} />}
        />
        <Card
            title={'Invoice Details'}
            subtitle={'Creditor capture invoice details'}
            content={this.getForm()}
        />
        <Card
            title={'Upload Invoice'}
            subtitle={'Skip if already uploaded on purchase order step '}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                <Col xl={12}>
                    <Upload
                        id={'upload_ctl'}
                        prefix={'Creditor_Attach_'}
                        header={false}
                    />
                </Col>
            </Row></Container>}
        />
        </div>
    }

    getLayout() {
        return this.getConfirm()
        //return this.props.mode === "confirm" ? this.getConfirm() : this.getView()
    }

    getErrorLayout() {
        return <Card
            title={'Error'}
            subtitle={'Connection did not respond  '}
            content={<div>
                <h4>Please check following:</h4>
                <ul>
                    <li>Please ensure that the engine is connected</li>
                    <li>Please ensure that you do not log in twice</li>
                </ul>
                <i>Alternatively, you can contact the system administrator for further assistance.</i>
            </div>}
        />
    }

    render() {
        
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data
            //console.log('this.data',this.data)
        } catch (error) {

        }
        
        return this.data
            ?  this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.paymentCapture,
            upload: {
                ...state.upload
            },
            items: {
                ...state.paymentItem
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCapture)
