// export const components = []
export const grid = []
// export const selected = {}

// export const componentname = ''
// export const component_id = ''
// export const item_id = ''

export const fields = [
    {
        name: 'Description',
        label: 'Description',
        type: 'text',
        width: 200,
        col: 2,
        align: 'left',
        hide: false,
        read_only: false,
        text: null,
        value: null
    },
    {
        name: 'unitmeasure',
        label: 'Unit',
        type: 'select',
        width: 200,
        col: 2,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'unitmeasure',
        related: null,
        text: null,
        value: null
    },
    {
        name: 'quantity',
        label: 'Quantity',
        type: 'numeric',
        col: 1,
        width: 200,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'unitprice',
        label: 'Unit Price',
        type: 'numeric',
        width: 150,
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'vattable',
        label: 'Vattable',
        type: 'toggle',
        col: 1,
        align: 'left',
        width: 100,
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'vat',
        label: 'VAT',
        type: 'numeric',
        width: 150,
        col: 1,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'total',
        label: 'Total',
        type: 'numeric',
        width: 150,
        col: 1,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'itemdescription',
        label: 'GL Account',
        type: 'select',
        width: 200,
        col: 2,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'accountingitem',
        related: null,
        text: null,
        value: null
    }
]

export const unitmeasure_data = []
export const accountingitem_data = []
export const unitmeasure = []
export const accountingitem = []
export const itemlist = []
export const loading = true
export const grid_item_inx = 0
export const grid_row = null
export const modelwin = false
export const servicehistory = false
export const tyreshistory = false
export const driverbehaviour = false
export const toggle_labourrate =  {
    errorText: '',
    value: false,
    valid: true,
    empty: true,
}

export const labourrate_exceeded =  {
    errorText: '',
    value: false,
    valid: true,
    empty: true,
}

export const exceptions = []
export const exceptionsfound = false

export const counter = 0