import AWS from "aws-sdk";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import CssBaseline from '@mui/material/CssBaseline';

import App from "./components/App/component";
import AppAD from "./components/ADInit/component";
import * as ReactDOMClient from "react-dom/client";
import * as app from "./store";
import * as config from "./config";

import i18n from "./i18next";
import { theme } from "./themes/infleet";

import { MemoryRouter } from "react-router-dom";

import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@mui/material';

import NavigationScroll from './helpers/navigationscroll';

import I18next from './components/I18next/component'


import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

AWS.config.region = config.cognito.region;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: config.cognito.identityPoolId,
});


import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const msalConfig = {
  auth: {
    clientId: config.system.clientId,
    authority: config.system.authority,
    redirectUri: config.system.redirectUri,
    postLogoutRedirectUri: config.system.redirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const handleBeforeUnload = (event) => {
  console.log('handleBeforeUnloadx_125')
  
  localStorage.clear();
  msalInstance.getAllAccounts().forEach(account => {
    msalInstance.logoutRedirect({
      account: account,
      postLogoutRedirectUri: '/',
    });
  });

  // event.preventDefault();
  // event.returnValue = '';
};

window.addEventListener('beforeunload', handleBeforeUnload);
window.addEventListener('beforeunload', handleBeforeUnload);

// window.addEventListener('load', () => {
//   sessionStorage.clear();
//   localStorage.clear();
// });

// window.addEventListener('unload', () => {
//   sessionStorage.clear();
//   localStorage.clear();
// });

// window.addEventListener('beforeunload', handleBeforeUnload);

// window.addEventListener("beforeunload", (ev) => {
//     ev.preventDefault();
//     debugger
//     //Auth.signOut();
//     localStorage.clear();
// });

// window.addEventListener('unload', (ev) => {
//     ev.preventDefault();
//     debugger
//     //Auth.signOut();
//     localStorage.clear();
// });

// window.addEventListener('onload', (ev) => {
//     ev.preventDefault();
//     debugger
//     //Auth.signOut();
//     localStorage.clear();
// });
// window.t = {
//   en: (args) => {
//       return <I18next i18n={args} lang={'en'} />
//   }
// }
const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
  <div>
    {
  config.system.authMethod === 'AD'
  ? <MsalProvider instance={msalInstance}>
    <Provider store={app.store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ReactNotifications />
          <CssBaseline enableColorScheme />
          <MemoryRouter initialEntries={["/"]}>
            <NavigationScroll>
              <AppAD />
            </NavigationScroll>
          </MemoryRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </MsalProvider>
  : <Provider store={app.store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ReactNotifications />
          <CssBaseline enableColorScheme />
          <MemoryRouter initialEntries={["/"]}>
            <NavigationScroll>
              <App />
            </NavigationScroll>
          </MemoryRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
    }
    </div>
  )