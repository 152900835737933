import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Table, Grid, Card as C, Button, Dialog, TextField, Icon as MaterialIcon} from '@mui/material'
import { LoopRounded, GetAppOutlined } from '@mui/icons-material';

import AutoComplete from '../../controls/autocomplete'
import MultiSelect from '../../controls/multiselect'

import Icon from '../Icon/component'

import * as actions from './actions'
import * as styles from './styles'
import Header from '../WorkflowTaskInput/header'
import Card from '../../controls/card'

import _ from 'lodash'

import ReactTable from "react-table"
import "react-table/react-table.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'


class CIS extends Component {
    componentDidMount() {
        this.props.actions.getCISEmailData()
        this.props.actions.getUser()
        this.props.actions.getCisHistoryTable()
    }
    addUserDialog() {
        return <Dialog
            open={this.props.state.addUser}
            style={{ width: '100% !important', maxWidth: '100% !important' }}
            fullWidth={true}
            maxWidth="lg"
        //onClose={this.props.state.dialog}
        //classes={{ paper: 'shadow-lg rounded' }}
        >

            <div className="text-center p-5">
                <div className="avatar-icon-wrapper rounded-circle m-0">

                </div>
                <h4 className="font-weight-bold mt-4">
                    Add user email to CIS emailing list
                </h4>
                {/* <p className="mb-0 font-size-lg text-muted">
                    CIS email list
                </p> */}
                {this.addUser()}
                <div className="pt-4">
                    <Button
                        onClick={(event) => {
                            this.props.actions.showAddDialog(false)
                        }}
                        className=" btn-pill mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                    <Button
                        onClick={(event) => {
                            this.props.actions.showAddDialog(false)
                            this.props.actions.addCISEmail()
                        }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Add</span>
                    </Button>
                </div>
            </div>
        </Dialog>

    }
    removeUserDialog() {
        return <Dialog
            open={this.props.state.removeUser}
            style={{ width: '100% !important', maxWidth: '100% !important' }}
            fullWidth={true}
            maxWidth="md"
        //onClose={this.props.state.dialog}
        //classes={{ paper: 'shadow-lg rounded' }}
        >

            <div className="text-center p-5">
                <div className="avatar-icon-wrapper rounded-circle m-0">

                </div>
                <h4 className="font-weight-bold mt-4">
                    Remove user email from CIS emailing list
                </h4>
                <p className="mb-0 font-size-lg text-muted">
                    {
                        this.props.state.removeUserId?
                        this.props.state.user.filter(u => u.user_id == this.props.state.removeUserId)[0].email
                        : null
                    }
                </p>
                <div className="pt-4">
                    <Button
                        onClick={(event) => {
                            this.props.actions.showRemoveDialog(false, null)
                        }}
                        className=" btn-pill mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                    <Button
                        onClick={(event) => {
                            this.props.actions.removeCISEmail(this.props.state.removeUserId)

                        }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Remove</span>
                    </Button>
                </div>
            </div>
        </Dialog>

    }

    addUser() {
        return <>
            {
                this.props.state.user ?
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Email List:'}</div>
                        <div style={styles.fieldContent}>
                            <MultiSelect
                                name={'autocomplete_supplier_contacts'}
                                title={window.t.en('Select email contact')}
                                info={{
                                    show: false
                                }}
                                fullWidth={true}
                                fieldvalue={'contact_id'}
                                fieldtext={'email'}
                                displayfields={['firstname', 'email']}
                                displaywidth={['75', '25']}
                                data={this.props.state.user}
                                // value={this.props.state.selectedUser ? this.props.state.selectedUser : ''}
                                onSelect={(args) => {
                                    //console.log('setSupplierAutoCompleteSelected', args)
                                    this.props.actions.setSelectedUser(args)

                                }}
                                onClick={() => {//this.props.actions.getSupplierContactsAutoCompleteItems(this.props.state.fields.selectedSupplier.data.value)
                                    //console.log('setSupplierAutoCompleteSelected')
                                }
                                }
                                onFilter={(args) => {
                                    ///this.props.actions.getCustomerContactsAutoCompleteItems({text: args, value: null})
                                    //this.props.actions.getSupplierContactsAutoCompleteItems(this.props.state.fields.selectedSupplier.data.value)
                                }}
                            />

                        </div>
                    </div>
                    : null
            }

        </>
    }
    getGrid() {
        return <div className="cis-grid"> <div className="mb-spacing-6">
            <Grid container spacing={6}>
                <Grid item xl={8}>
                    <C className="card-box">
                        <div className="font-size-lg px-3 py-4 font-weight-bold">
                            CIS history
                        </div>
                        <div className="cis-table1"><div className="table-responsive-md" id="cis-table1"></div>
                            <Table className="table table-borderless  mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase bg-secondary">
                                            File
                                        </th>
                                        <th className="text-uppercase bg-secondary">Date</th>
                                        <th className="text-uppercase bg-secondary">Subject</th>
                                        {/* <th className="text-uppercase bg-secondary">Status</th> */}
                                        <th className="text-uppercase bg-secondary text-center">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.state.cisHistory? this.props.state.cisHistory.sort((y, z) => z.cismailhistory_id - y.cismailhistory_id).map((h, ind)=>
                                        <tr key={ind}>
                                        <td>{h.file_name.substring(11, h.file_name.length)}</td>
                                        <td>{moment(h.created_at).format('YYYY-MM-DD hh:mm:ss')}</td>
                                        <td>{h.subject}</td>
                                        {/* <td>
                                            <div className="badge badge-success">Sent</div>
                                        </td> */}
                                        <td className="text-center">
                                            <Button
                                                size="small"
                                                onClick={()=>{this.props.actions.getCIShistoryFile(h.key)}}
                                                className="btn-primary shadow-none d-30 btn-icon hover-scale-sm p-0">
                                                <FontAwesomeIcon icon={faDotCircle} />
                                            </Button>
                                        </td>
                                    </tr>
                                        ):null
                                    }


                                </tbody>
                            </Table>
                        </div>
                    </C>
                </Grid>
                <Grid item xl={4}>
                    <C className="card-box">
                        <div className="font-size-lg px-3 py-4 font-weight-bold">
                            CIS email list
                            <Button className="btn-link p-0 btn-icon d-30">
                                <span className="btn-wrapper--icon d-flex">
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="text-success font-size-sm"
                                        onClick={() => { this.props.actions.showAddDialog(true) }}
                                    />
                                </span>
                            </Button>
                        </div>



                        <div className="table-responsive-md" id="cis-table2">
                            <Table className="table table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase bg-secondary">User</th>
                                        <th className="text-uppercase bg-secondary">Email</th>
                                        <th className="text-uppercase bg-secondary text-center">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.state.cisMail ? this.props.state.cisMail.map((f, ind) => {
                                            return <tr key={ind}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="avatar-icon-wrapper avatar-icon-sm mr-3">
                                                            <div className="avatar-icon">
                                                                <img alt="..." src="https://d1z1yg99cg50xo.cloudfront.net/enkw.jpg" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {`${f.firstname} ${f.lastname}`}
                                                            {/* <span className="text-black-50 font-size-sm d-block">
                                                                Member since 2016
                                                                </span> */}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="font-weight-bold">{f.email}</td>
                                                <td className="text-center">
                                                    <Button className="btn-link p-0 btn-icon d-30">
                                                        <span className="btn-wrapper--icon d-flex">
                                                            <FontAwesomeIcon
                                                                icon={faTimes}
                                                                className="text-danger font-size-sm"
                                                                //onClick={() => this.props.actions.removeCISEmail(f.user_id)}
                                                                onClick={() => this.props.actions.showRemoveDialog(true, f.user_id)}
                                                            />
                                                        </span>
                                                    </Button>
                                                </td>
                                            </tr>
                                        }) : 'Loading List'
                                    }
                                </tbody>
                            </Table>
                        </div>

                    </C>
                </Grid>
            </Grid>
        </div>
        </div>
    }

    getButton() {
        return <div>
            <Row>
                <Col xl={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={2} sm={4}>
                            <Button
                                label=""
                                primary="color"
                                onClick={(event) => {

                                    /// this.props.actions.showPopupDialog(true)
                                    this.props.actions.refreshCISData()
                                }}
                                startIcon={<LoopRounded/>}
                            > {'Refresh CIS'}
                            </Button>
                        </Grid>
                        <Grid item xs={2} sm={4}>
                            <Button
                                label=""
                                primary="color"
                                onClick={(event) => {

                                    /// this.props.actions.showPopupDialog(true)
                                    this.props.actions.getCISExcelData()
                                }}
                                endIcon={<GetAppOutlined/>}

                            > {'Download CIS'}
                            </Button>
                        </Grid>
                    </Grid>
                </Col>
            </Row>
            <Row>
                <Col xl={10}>

                </Col>
                <Col xl={2}>

                </Col>
            </Row>

        </div>
    }

    getUserInput() {
        return <div>
            <TextField
                variant="outlined"
                margin="normal"
                name="userMessage"
                label={'User Message'}
                required
                fullWidth
                value={this.props.state.userMessage}
                onChange={
                    (event, value) => {
                        this.props.actions.setUserInput('userMessage', event.target.value)
                    }
                }
            />
        </div>
    }

    getLayout() {
        return (
            <Card
                title={'CIS Report'}
                subtitle={''}
                content={<div>
                    <Row>
                        <Col xl={12}><Grid container spacing={2}>
                            <Grid item xs={2} sm={12}>
                                {this.getButton()}
                            </Grid>
                            <Grid item xs={2} sm={12}>
                                {this.getUserInput()}
                            </Grid>
                        </Grid>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col xl={4}>
                            <AutoComplete
                                listStyle={{ maxHeight: 400, maxWidth: 400 }}
                                id={'ddl_yardlist_selection'}
                                key={'ddl_yardlist_selection'}
                                hintText={'Select CIS Month'}
                                openOnFocus={true}
                                fullWidth={true}
                                // dataSourceConfig={{
                                //     text: 'text',
                                //     value: 'vehicle_id'
                                // }}
                                dataSource={['Jan 2021', 'Feb 2021']}
                                onNewRequest={
                                    (chosenRequest, index) => {
                                        //this.props.actions.setSelectedYardList(chosenRequest.value, chosenRequest.text)
                                        // this.props.actions.getNonRateCardYardListVehicle(this.field.mmcode_id, this.props.inx, chosenRequest.vehicle_id, chosenRequest.text, this.props.state.quoteHeader.yardlist)
                                    }
                                }
                                onClick={
                                    (event) => {
                                        // this.props.actions.getYardList('')
                                    }
                                }
                                onUpdateInput={
                                    (search, dataSource, params) => {
                                        // this.props.actions.getYardList(search)
                                    }
                                }
                                popoverProps={{ style: { overflow: 'auto', width: 'auto' } }}
                            />
                        </Col>*/}

                        <Col xl={12}>
                            {this.getGrid(this.props.state.cisHistory)}
                        </Col>

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                        >
                            <Grid item xs>
                                <Button
                                    variant="contained"
                                    color="success"
                                    disabled={this.props.state.userMessage.length >= 30? false: true}
                                    // className={classes.button}
                                    endIcon={<MaterialIcon>send</MaterialIcon>}
                                    onClick={()=>{this.props.actions.getCISSendEmailData()}}
                                >
                                    Send CIS
                                </Button>
                            </Grid>
                        </Grid>
                    </Row>
                </div>
                } />)

    }

    render() {
        return (
            <div>
                <br />
                <Header title={'CIS'} description={'Download or refresh CIS report'} />
                <br />
                {this.getLayout()}
                {this.addUserDialog()}
                {this.removeUserDialog()}
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.cis
        },
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CIS)