import * as colours from '@mui/material/colors'

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const confirmSelection = {
    textAlign: 'right'
}
export const leftLabel = {
    textAlign: 'left'
}
export const leftLabelTitle = {
    textAlign: 'left',
    fontWeight: 'bold',
    textTransform: 'capitalize'
}

export const card = {
    margin: '0 auto',
    width: '300px',
    maxWidth: '300px'
}

export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}

export const dropZone = {
    width: '90%',
    height: '300px',
    border: '1px solid red',
    padding: '10px'
}

export const rowTitle = {
    width: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    paddingRight: 10
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const inputWidth = {
    width: '100%'
}

export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 10
}
export const lintitems = {
    backgroundColor: '#eaf6ff',
    borderBottom: '2px solid gray',
    padding: 10
}
export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '19px',
}

export const numericFields = {
    textAlign: 'right',
}

export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}

export const fieldLabelA = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingTop: 20,
    paddingRight: 10
}

export const tableheader = {
    fontWeight: 'bold',
    fontSize: '16px',
    borderBottom: '2px solid red',
}
export const tablerow = {
    fontSize: '15px',
    background: 'white',
}
export const alttablerow = {
    fontSize: '15px',
    background: '#D1E0E6',
}
export const tablerowr = {
    fontSize: '15px',
    textAlign: 'right',
    borderBottom: '1px dash gray',
}

export const action = {
    fontSize: '20px',
    color: colours.red[500]
}

export const actionHoverColor = {
    color: colours.red[500]
}

export const gridOptionsIcon = {

}

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}


export const verticalMiddle = {
    verticalAlign: 'middle',
}
