export const report_data = null
export const end_date = null
export const start_date = null
export const accountingitem = {}
export const accountingitem_data = []
export const columns = [
    {name: 'audit_number', title: 'Audit Number', type: 'text'},
    {name: 'transaction_date', title: 'Date', type: 'date'},
    {name: 'reference', title: 'Reference', type: 'text'},
    {name: 'item_type', title: 'Description/Contra Account', type: 'text'},
    {name: 'contract_amount', title: 'Contra Amount', type: 'numeric'},
    {name: 'debit', title: 'Debits', type: 'numeric'},
    {name: 'credit', title: 'Credits', type: 'numeric'},
    {name: 'balance', title: 'Balance', type: 'numeric'}
]
export const excel_headers = [{
    name: 'transaction_date',
    title: 'Transaction Date',
    type: 'date'
},{
    name: 'reference',
    title: 'Transaction Reference',
    type: 'text'
},{
    name: 'item_type',
    title: 'Transaction Description',
    type: 'text'
},{
    name: 'contract_amount',
    title: 'Transaction Amount',
    type: 'text'
},{
    name: 'reconciled',
    title: 'Reconciled/Unreconciled',
    type: 'text'
},{
    name: 'balance',
    title: 'Balance',
    type: 'text'
}]
