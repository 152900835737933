// import * as progressBarActions from '../ProgressBar/actions'
// import * as requestActions from '../Request/actions'
// import * as routerActions from '../Redirect/actions'
// import * as types from './types'
// import * as requestTypes from '../Request/types'
// import * as actions from './actions'
// import * as config from '../../config'

// import { cognitoUser } from '../../cognito'

import * as actions from './actions'
import * as routerActions from '../Redirect/actions'

import * as types from './types'
import * as config from '../../config'

export const getUserTeams = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(types.GET_USER_TEAMS),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/user/query?cognito_key=' + store.value.user.cognito_key,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.login.accesstoken,
                    'salt': store.value.login.jwt,
                    'datetime': store.value.login.datetime,
                    'related': 'user_team'
                }
            }).pipe(
                mergeMap(payload => [
                    actions.setUserTeams(payload.response)
                ])
                ,catchError((error) => {
                    //console.log('catchError getUserTeams', error)
                }
                )
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getHierarchy = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_HIERARCHY),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/team/in?team_id=' +(store.value.accounts.teams.length ? store.value.accounts.teams.map(t => t.team_id).join('.') : 0),
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.login.accesstoken,
                    'salt': store.value.login.jwt,
                    'datetime': store.value.login.datetime,
                    'related': 'businessteam.customer,businessteam.business'
                }
            }).pipe(
                mergeMap(payload => [
                    actions.setHierarchy(payload.response)
                ])
                ,catchError((error) => {
                    //console.log('catchError getHierarchy', error)
                }
                )
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getUserCustomer = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_CUSTOMER_STRUCTURE),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/customer/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.login.accesstoken,
                    'salt': store.value.login.jwt,
                    'datetime': store.value.login.datetime,
                    'Content-Type': 'application/json'

                },
                body: payload
            }).pipe(
                mergeMap(payload => [
                    actions.setUserCustomer(payload.response)
                ])
                ,catchError((error) => {
                    //console.log('catchError getUserCustomer', error)
                }
                )
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )