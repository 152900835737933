import moment from 'moment'
import validator from 'validator';

import * as appTypes from '../components/App/types'
import * as approvalTypes from '../components/WorkflowTaskInput/types'

export const regXPattern = (maxLength = 0) => {
    return {
        maxLength: `^[a-zA-Z0-9 '-/]{0,` + maxLength + `}$`,
        integer: '^[0-9]\d*(\.\d+)?$',
        text: '^[a-zA-Z0-9 \\u0020-\\u007E]{1,150}$',
        phone: '^[+]{0,1}[0-9]{5,14}$',
        idNumber: '^[0-9]{5,16}$',
        accountNo: '^[0-9]{7,11}$',
        decimalOrInteger: '^[0-9]\d*(\.\d+)?$'
    }
}

export const schema = (field, schema, input, required, maxlength) => {
    try {
        const regxMaxLength = new RegExp("^[a-zA-Z0-9 '-/]{0," + maxlength + "}$");
        const regxText = new RegExp("^[a-zA-Z0-9 \\u0020-\\u007E]{1,150}$");
        const regxPhone = new RegExp("^[+]{0,1}[0-9]{5,14}$");
        const regxIDNumber = new RegExp("^[0-9]{5,16}$");
        const regxAccountNo = new RegExp("^[0-9]{7,11}$");
        const regxInteger = new RegExp("^[0-9]{1,}$");

        const greaterThanZero = (input) => { return parseFloat(input) > 0 };

        input = input || '';

        let isMaxLength = maxlength ? regxMaxLength.test(input) : true;

        let isrequired = required ? !(input === '' || input === null) : true;

        let isvalid = true;

        if (!required && (input === '' || input === null)) {
            isvalid = true;
        } else if (schema) {
            switch (schema) {
                case 'notNullUndefinedEmpty':
                    isvalid = input !== null || input !== "" || input !== undefined
                    break;
                case 'integer':
                    isvalid = regxInteger.test(input);
                    break;
                case 'greaterThanZero':
                    isvalid = greaterThanZero(input);
                    break;
                case 'postalCode':
                    isvalid = validator.isPostalCode(input);
                    break;
                case 'alpha':
                    isvalid = validator.isAlpha(input);
                    break;
                case 'alphanumeric':
                    isvalid = validator.isAlphanumeric(input);
                    break;
                case 'base64':
                    isvalid = validator.isBase64(input);
                    break;
                case 'boolean':
                    isvalid = validator.isBoolean(input);
                    break;
                case 'creditCard':
                    isvalid = validator.isCreditCard(input);
                    break;
                case 'date':
                    isvalid = !isNaN(Date.parse(input));
                    break;
                case 'length':
                    isvalid = validator.isLength(input);
                    break;
                case 'time':
                    isvalid = !isNaN(Date.parse(input));
                    break;
                case 'decimal':
                    isvalid = validator.isDecimal(input);
                    break;
                case 'email':
                    isvalid = validator.isEmail(input);
                    break;
                case 'required':
                    isvalid = !validator.isEmpty(input);
                    break;
                case 'numeric':
                case 'amount':
                case 'number':
                    isvalid = validator.isNumeric(String(input));
                    break;
                case 'phone':
                    isvalid = regxPhone.test(input);
                    break;
                case 'password':
                    isvalid = validator.isStrongPassword(input);
                    break;
                case 'idnumber':
                    isvalid = regxIDNumber.test(input);
                    break;
                case 'text':
                    isvalid = regxText.test(input);
                    break;
                case 'account':
                case 'accountno':
                    isvalid = regxAccountNo.test(input);
                    break;
                default:
                    isvalid = true;
                    break;
            }
        }

        return isvalid && isrequired && isMaxLength;
    } catch (error) {
        console.error('Validation error:', error);
        return false;
    }
};

export const validateSchema = (field, validations, baseErrorMessage) => {
    let valid = true;
    let errorText = baseErrorMessage;

    for (const v of validations) {
        const validated = schema(null, v.type ?? v.schema, field?.toString(), v.required, v.maxLength);
        if (!validated) {
            valid = false;
            errorText = v.errorText;
            break; // Exit the loop if validation fails, only return one error
        }
    }
    return { valid, errorText };
}

const VALIDATION_STATES = {
    VALID: true,
    INVALID: false,
    SKIP_ADDITIONAL: 'skipAdditionalValidations'
};

const EMPTY_VALUES = [null, "", " ", "Invalid Date"];

export const validateState = (getState, state, dispatch, approval = false) => {
    const stateFields = getState()[state].fields;
    const fields = Object.values(stateFields);
    const errorMessages = [];
    let isStateValid = true;

    for (const field of fields) {
        const { isValid, errorText } = validateField({ ...field }, getState);

        if (!isValid) {
            isStateValid = false;

            if (approval) {
                errorMessages.push(errorText);
            } else {
                createNotification(errorText, getState, dispatch, field.popup);
            }
        }
    }

    return { state_valid: isStateValid, errorMessages };
};

const createNotification = (errorText, getState, dispatch, isPopup = false) => {
    if (isPopup) {
        dispatch({
            type: approvalTypes.TOGGLE_NOTIFICATION_DIALOG,
            payload: errorText || 'Error'
        });
        return;
    }

    dispatch({
        type: appTypes.SHOW_NOTIFICATION,
        payload: {
            type: 'danger',
            title: 'Validation Failed',
            additionalText: errorText || 'Error',
            overflowText: getState().login.fields.email.input,
            autoHide: 7500,
            timestamp: moment().format('h:mm A')
        }
    });
};

const validateTableField = (tableData, tableFields, validations, errorText) => {
    let isValid = VALIDATION_STATES.VALID;
    let validationError = errorText;

    if (!tableData || !tableData.length) {
        return { isValid: VALIDATION_STATES.INVALID, errorText };
    }

    tableData.forEach(row => {
        tableFields.forEach(field => {
            const fieldValue = row[field]?.toString();
            const isRequired = schema(null, 'notNullUndefinedEmpty', fieldValue, true, null);

            if (!isRequired) {
                isValid = VALIDATION_STATES.INVALID;
                return;
            }

            if (validations?.length) {
                const validation = validateSchema(fieldValue, validations, errorText);
                isValid = validation.valid;
                validationError = validation.errorText;
            }
        });
    });

    return { isValid, errorText: validationError };
};

const validateDocuments = (documents, type, errorText) => {
    if (!documents.length) {
        return { isValid: VALIDATION_STATES.INVALID, errorText: 'Please upload a document' };
    }

    for (const doc of documents) {
        if (!doc.filename) {
            return { isValid: VALIDATION_STATES.INVALID, errorText: 'Please upload a document' };
        }

        if (type === 'documentsWithoutDescription') {
            return { isValid: VALIDATION_STATES.VALID, errorText: '' };
        }

        if (!doc.description) {
            return { isValid: VALIDATION_STATES.INVALID, errorText: 'Please select a document type' };
        }
    }

    return { isValid: VALIDATION_STATES.VALID, errorText: '' };
};

const validateField = (field, getState) => {
    let isValid = field.state ? eval(field.state) : field.valid;

    // Check additional states if defined and initial state is invalid
    if (field.states && !isValid) {
        isValid = eval(field.states);
    }

    if (field.type === 'table') {
        // Handle table validation
        const tableData = eval(field.state);
        if (Array.isArray(tableData)) return validateTableField(tableData, field.tableFields, field.validations, field.errorText);
    } else if (field.type === 'documents' || field.type === 'documentsWithoutDescription') {
        // Handle document validation
        return validateDocuments(getState().upload.documents, field.type, field.errorText);
    } else {
        // Handle basic field validation
        if (EMPTY_VALUES.includes(isValid) || (Array.isArray(isValid) && !isValid.length)) {
            return { isValid: VALIDATION_STATES.INVALID, errorText: field.errorText };
        }

        // Handle additional validations
        if (field.validations?.length && isValid !== VALIDATION_STATES.INVALID && isValid !== VALIDATION_STATES.SKIP_ADDITIONAL) {
            const validation = validateSchema(isValid, field.validations, field.errorText);
            return { isValid: validation.valid, errorText: validation.errorText };
        }
    }

    return { isValid, errorText: field.errorText };
};