import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent,Table} from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import * as styles from './styles'
import ReactQuill from 'react-quill'
import * as actions from './actions'

class ViewQuotation extends Component {
    componentDidMount() {
        //console.log('getComponentContractDate >>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
        this.props.actions.getComponentQuoteData(this.props.id)
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }
    getLabel(label, prop, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                <Typography variant="body1" component="div"
                style={{fontWeight: 'bold'}}>
                    {this.getField(this.props.state[prop], type)}
                </Typography>
            }
            label={
                <label style={{width: '200px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
                    {label}
                </label>  
            }
            labelPlacement="start"
        />
    }

    getLayout() {
        //console.log("View Quotation Dataxxx", this.data)
        return <div>
             <Row>
                        <Col xl={12} style={styles.title}>
                            {'Quotation Notes'}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                        <div className="table-responsive-md">
                            <Table className="table table-hover text-nowrap mb-0" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '5%' }}>Work Flow Name</th>
                                        <th style={{ width: '5%' }}>Work Flow Task Name</th>
                                        <th style={{ width: '5%' }}>Task Number</th>
                                        <th style={{ width: '5%' }}>Quote Number</th>
                                        <th style={{ width: '5%' }}>Created By</th>
                                        <th style={{ width: '5%' }}>Created Date</th>
                                        <th style={{ width: '5%' }}>Administrator</th>
                                        <th style={{ width: '5%' }}>Edited Date</th>
                                        <th style={{ width: '60%' }}>Notes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.data.map(x => (
                                        <tr key={x.id} style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.workflow_name}</td>
                                            <td>{x.workflow_task_name}</td>
                                            <td>{x.tasknumber}</td>
                                            <td>{x.quotenumber}</td>
                                            <td>{x.workflow_user}</td>
                                            <td>{x.workflow_task_created_at}</td>
                                            <td>{x.user ? x.user.firstname + ' ' + x.user.lastname : ''}</td>
                                            <td>{x.edited_date_time ? x.edited_date_time : ''}</td>
                                            <td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', wordBreak: 'break-all' }}>{x.quotation_notes}</td>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        }
    

    render() {
        this.data = []
        console.log
        try {
            this.data = this.props.state.quotation
            //console.log('quotation_view data', this.data)
        } catch (error) {
            
        }
        return this.data && this.props.id ? this.getLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuotation)