import * as validatorTypes from '../Validator/types'

export const keyword = {
    score: 0,
    strength: 0,
    color: '',
    min: 0,
    max: 100,
    value: 0,
    match: false
}

export const fields = {
    verificationCode: {
        id: 'verificationCode',
        name: 'verificationCode',
        errorText: '',
        hintText: 'Example: 123456',
        floatingLabelText: 'Verification Code',
        input: '',
        type: 'text',
        valid: false,
        empty: true,
        validators: [validatorTypes.IS_NOT_EMPTY, validatorTypes.IS_NUMERIC]
    },
    forgotKeyword: {
        id: 'forgotPassword',
        name: 'forgotPassword',
        errorText: '',
        hintText: 'Example: sw0rD#fiGht',
        floatingLabelText: 'New Password',
        input: '',
        type: 'password',
        valid: false,
        empty: true,
        validators: [validatorTypes.IS_NOT_EMPTY, validatorTypes.IS_STRONG_PASSWORD]
    }
}

export const userAttributes = {}

export const valid = false
