import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
// import { Card, CardHeader, CardContent, CardActions } from '@mui/material'

import GeneralContentView from '../../views/GeneralContentView/component'

import Button from '@mui/material/Button'
import AutoComplete from '../../controls/autocomplete'
import Dropzone from 'react-dropzone'


//import prettyFileIcons from 'pretty-file-icons'
import { green, red } from '@mui/material/colors'
import ReactTable from "react-table"
import Icon from '../Icon/component'
import Loading from '../Loading/component'
import Card from '../../controls/card'

import * as actions from './actions'
import * as styles from './styles'

class Import_old extends Component {
    componentDidMount() {
        this.props.actions.resetUpload()
    }

    getBulkCellValue(row, inx) {
        return <label style={styles.textBox}>{this.props.state.rows[row.index][inx]}</label>
    }

    getBulkColumns() {
        let header = this.props.state.rows[0]
        let columns = header.map((x, i) => {
            return {
                meta: x,
                Header: x,
                accessor: x,
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <input
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                            width: '90%',
                            height: 25,
                            border: '1px solid #E6E6E6',
                            borderRadius: 25
                        }}
                    />
                ),
                //width: x.width ? x.width : undefined,
                headerStyle: {
                    fontSize: '13px',
                    fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                    //backgroundColor: x.highlight ? styles.highlight : '',
                },
                style: {
                    verticalAlign: 'middle',
                    fontSize: 12,
                    letterSpacing: 0,
                    color: 'black',
                    fontWeight: 'normal',
                    //textAlign: x.align
                },
                Cell: (row) => (
                    this.getBulkCellValue(row, i)
                )
            }
        })
        return columns
    }

    getRowGrid() {
        return <ReactTable
            key={'grid_booking_quote'}
            data={this.props.state.rows}
            columns={this.getBulkColumns()}
            filterable={true}
            loading={this.props.state.loading}
            manual
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.getRelatedRowItem(rowInfo, this.data)
                    },
                }
            }}
            onFetchData={
                (state) => {
                    //this.props.actions.getRestructureGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            minRows={1}
            showPagination={false}
            showPaginationBottom={false}
            style={{
                height: '600px',
                position: 'relative'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }
    

    processAndFormatExceptions = () => {
        const processedExceptions = this.props.state.exceptions && this.props.state.exceptions.map(exception => ({
            message: exception.message.toUpperCase(), 
            data: exception.data.split('\n\n').join('; ')
        }));

        return processedExceptions && processedExceptions.map((exception, index) => (
            <div key={index} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f8f9fa' }}>
                <strong>Message:</strong> <br /> {exception.message}
                <br /><br />
                <strong>Data:</strong> <br /> {exception.data}
            </div>
        ));
    }

    getAcceptedFiles() {
        return this.props.state.rows.length
            ? <Card
            title={window.t.en('Imported File Data')}
            subtitle={window.t.en('Please ensure that the data import match the one the file you imported before you action.')}
            content={
                <div style={{ verticalAlign: 'middle', margin: '1px', overflow: 'auto' }}>
                    <Button className={"global_button"} variant="contained"
                        label={'Upload File Data'}
                        disabled={this.props.state.template ? false : true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.uploadFileData(this.props.state,this.props.state.user.client_id)
                        }}
                        >{window.t.en('Upload File Data')}</Button>
                        <br/><br/>
                        <div>{this.processAndFormatExceptions()}</div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={11}>
                    {this.getRowGrid()}</Col></Row></Container>
                </div>
            }/>
            : <div />
    }

    getRejectedFiles() {
        return !this.props.state.busy && !this.props.state.rows.length
            ? <Card
            title={window.t.en('Import Failed')}
            subtitle={window.t.en('Please review the reason to why the file failed to import and action accordinly!')}
            content={<Button className={"global_button"} variant="contained"
                        label={'Upload Again'}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.uploadAgain()
                        }}
                        >{window.t.en('Upload Again')}</Button>
                    }/>
            : <div />
    }
    getFileTypeIcon(file) {
        // const type = prettyFileIcons.getIcon(file)
        // const src = require('../../assets/img/png/icons/' + type + '.png')

        return ""
    }

    getImport() {
        
        return <div><Card
            title={window.t.en('Excel File Import')}
            subtitle={window.t.en('Import Excel File Data ')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Select Import Template:'}</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        id={'dll_import_template'}
                                        key={'dll_import_template'}
                                        hintText={'Search for template...'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'values'
                                        }}
                                        dataSource={this.props.state.templates}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                //console.log('>>>>>>>>>>>>>>>>>>>>> chosenRequest',chosenRequest)
                                                this.props.actions.setLookupValue({ value: chosenRequest.value, text: chosenRequest.text })
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.getTemplateList('')
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.getTemplateList(search)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    {
                        !this.props.state.document
                            ? <Row>
                                <Col xl={12}>
                                    <Dropzone
                                        accept={'.xls,.xlsx'}
                                        style={styles.dropZone}
                                        onDrop={(files) => {
                                            //this.props.actions.notifyUser('Checking documents...')
                                        }}
                                        onDropAccepted={(files) => {
                                            this.props.actions.setAcceptedDocuments(files)
                                        }}
                                        onDropRejected={(files) => {
                                            //this.props.actions.notifyUser('(' + files.length + ') files were rejected.')
                                            //this.props.actions.setRejectedDocuments(files, this.props.state.component.name)
                                        }}
                                    >
                                        <p style={styles.watermark}>
                                            Drag and drop the files you would like to upload inside this box. please ensure that your are uploading the correct file format:
                                            <br />
                                            .xls and .xlsx
                                        </p>
                                    </Dropzone>
                                </Col>
                            </Row>
                            : <Row>
                                <Col xl={12}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>
                                            {'Reset Imported File:'}
                                            <div>
                                                <Icon istyle={{ color: green[500], fontSize: '16px' }} iclass={'material-icons'} iname={'done'} />
                                                &nbsp;
                                                <strong>{'File Name:'} </strong>{this.props.state.document.filename}
                                            </div>
                                        </div>
                                        <div style={styles.fieldContent}>< Button className={"global_button"} variant="contained"
                                            label={'Reset Upload'}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.resetUpload()
                                            }}
                                            >{window.t.en('Reset Upload')}</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                    }
                </Container>
            }
        /> 
                            {this.props.state.document && !this.props.state.busy && this.getAcceptedFiles()}
                            {this.props.state.document && !this.props.state.busy && this.getRejectedFiles()}
                </div> 
    }

    gteView() {
        return <div>
            {this.getImport()}
        </div>
    }

    gteLayout() {
        return <GeneralContentView
            content={this.gteView()}
            headerImage={'test.png'}
            title={window.t.en('Import')}
            contentHeight={'900px'} />
    }

    render() {
        return (
            this.gteLayout()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.excelImport,
            user:{
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Import_old)