import React from 'react';
import TextField from '@mui/material/TextField';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { debounce } from 'lodash';

//Code Imports
import * as appActions from '../components/App/actions'
import * as cognitoActions from '../components/Cognito/actions'
import * as validatorActions from '../components/Validator/actions'
import { styled } from '@mui/material/styles';

const TextFieldx = styled(TextField)({
    "& .MuiFormLabel-root": {
        fontSize: '14px'
    },
    "& .MuiFormLabel-filled": {
        color: "black",
        fontWeight: 600,
        fontSize: '14px'
    },
    root: {
        "& .MuiFormLabel-filled": {
            color: "black",
            fontWeight: 600
        }
    }
});
function BasicTextFields(props) {

    const {
        name,
        title,
        type,
        description,
        placeholder,
        required,
        variant,
        value,
        fullWidth,
        field,
        id,
        key,
        label,
        margin,
        color,
        error,
        inputRef,
        helperText,
        disabled,
        hintText,
        multiline,
        rows,
        pattern,
        debounceDelay = 300, // Default delay of 300ms
        onDebounce     // New prop for debounced function
    } = props

    // Create a single debounced instance that persists
    const debouncedFunction = React.useRef(
        debounce((value) => {
            if (onDebounce) {
                onDebounce(value);
            }
        }, debounceDelay)
    ).current;

    // Cleanup on unmount
    React.useEffect(() => {
        return () => {
            debouncedFunction.cancel();
        };
    }, [debouncedFunction]);

    return (
        <div style={{ margin: '10px 0px' }}>
            <TextFieldx
                title={title}
                name={name}
                id={id}
                key={key}
                multiline={multiline}
                minRows={rows}
                label={label ? label : hintText ? hintText : ''}
                fullWidth={fullWidth}
                variant={variant}
                required={required}
                disabled={disabled}
                color={color}
                margin={margin}
                error={error}
                //helperText={helperText}
                value={value}
                size={"small"}
                pattern={pattern}
                onChange={(e) => {
                    const newValue = e.target.value;
                    // Call immediate onChange
                    props.onChange(e, newValue);
                    // Call debounced function if provided
                    debouncedFunction(newValue);
                }}
                onBlur={
                    (e) => {
                        props.onBlur ? props.onBlur(e, e.target.value) : null
                    }
                }
                type={type}
                placeholder={placeholder ? placeholder : hintText ? hintText : ''}
                //inputRef={register(inputRef)}
                InputLabelProps={{ shrink: (value === null || value === '') && type !== 'date' ? false : true }}
            />
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        state: {
            login: {
                ...state.login,
            },
            cognito: {
                ...state.cognito
            },
            components: {
                ...state.components.loading
            },
            workflows: {
                ...state.workflows.loading
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...cognitoActions, ...validatorActions, ...appActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicTextFields)