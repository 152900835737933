import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const gridOptions = {

}

export const highlight = colours.red[200]

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const action = {
    fontSize: '15px',
    //color: colours.red[500]
}

export const toggle = {
    fontSize: '15px',
    //color: colours.red[500]
}

export const actionHoverColor = {
    color: colours.red[500]
}
export const loginDialog = {
    color:'black',
    backgroundColor: '#c7fffa'
}

export const gridOptionsIcon = {
    backgroundColor: "#4167B2"
}

export const gridIconsTheme = (appName) => {
    if(appName === 'bidvest'){
        return {fill: '#2947AE'}
    }else{
        return {fill: '#141e30'}
    }
}