import * as types from './types'

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FML_ECCEPT_RELEASE_NOTE_CHECKSHEET_INPUT_VALUE,
            payload: data
        })
    }
}
