export const tasksCard = {
    textAlign: 'left'
}

export const row = (index, table) => {
    return index % 2 === 0
        ? {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            //backgroundColor: '#ffffe5',
            fontSize: '14px',
            width: '100%'
        }
        : {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            fontSize: '14px',
            width: '100%'
        }
}
export const actionIcon = (status) => {
    return status
    ? {
        fontSize: '20px',
        fontWeight: 'normal',
        color: '#1bc943',
        //padding: '25px 10px',
        width: 25,
        height: 25,
        fill: '#1bc943'
    }
    : {
        fontSize: '20px',
        fontWeight: 'normal',
        //color: '#1bc943',
        height: 25,
        color: '#253053',
        //padding: '25px 10px',
        width: 25,
        fill: '#2947AE'
    }
}

export const column = (width, right) => {
    return width === 0
        ? {
            display: 'table-cell',
            padding: '2px',
            fontSize: '14px',
            verticalAlign: 'middle !important',
            textAlign: right ? 'right' : 'left'
        }
        : {
            display: 'table-cell',
            padding: '2px',
            fontSize: '14px',
            width: width,
            verticalAlign: 'middle !important',
            textAlign: right ? 'right' : 'left'
        }
}

export const pagingControls = {
    verticalAlign: 'middle',
    textAlign: 'right'
}