import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import { Container, Row, Col } from "react-grid-system";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";


import Drawer from '../../../controls/drawer'
import Card from '../../../controls/card'
import Icon from "../../Icon/component";

import * as styles from "./styles";
import * as actions from "./actions";

class WorkflowDisposalForum extends Component {
    getComments() {
        return  <Container style={{ margin: 0 }} className="custom-container">
            <Row style={styles.rowHeader}>
                <Col xl={2}>
                    {"User"}
                </Col>
                <Col xl={5}>
                    {"Date"}
                </Col>
                <Col xl={5}>
                    {"Comments"}
                </Col>
                </Row>
        {
            this.data.disposalcomments.map(x => {
                return <Row style={styles.rowItem} >
                <Col xl={2}>
                    {x.commentby}
                </Col>
                <Col xl={5}>
                    {moment(x.commentdate).format('YYYY-MM-DD HH:mm')}
                </Col>
                <Col xl={5}>
                    {x.comment}
                </Col>
                </Row>
            }) 
        }
          </Container>
    }

    getEditForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
              <Col xl={12}>
              {this.getComments()}
              </Col>
            </Row>
            <Row>
              <Col xl={12}><br/></Col>
              <Col xl={12}>
                <TextField
                  variant="outlined" 
                  label={"Please enter your comments"}
                  errorText={
                    this.props.state.commentfield.length < 5
                      ? "Please provide atleast 5 char."
                      : ""
                  }
                  multiLine={true}
                  style={{ width: "100%" }}
                  value={this.props.state.commentfield}
                  rows={2}
                  onChange={(event, value) => {
                    event.preventDefault();
                    this.props.actions.setSetinputField({prop: 'commentfield', value: event.target.value})
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={12}><br/></Col>
              <Col xl={12}>
                <Button variant='contained' className='global_button'
                  label="Submit"
                  primary={true}
                  //style={{ position: 'fixed', bottom: 0 }}
                  onClick={(event) => {
                    event.preventDefault();
                    this.props.actions.saveUserComment(this.data.vehicle_id,this.props.state.user);
                  }}
               >{<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {window.t.en('Submit Comments')}</Button>
              
              </Col>
            </Row>
          </Container>
  }

//   getView() {
//     return <Card
//             title={"Forum"}
//             subtitle={"Take note of the comments below, action if required."}
//             content={this.getEditForm()}
//         />
//     }

  getLayout() {
    this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
    return  <Drawer 
        width={'800px'}
        onClose={(event, value) => {
            this.props.actions.toggleInfoSheet(null,'inforsheet_forum')
        }}
        open={this.props.state.inforsheet_forum}
        title={'Forum'}
        subtitle={'Take note of the comments below, action if required'}
        content={this.getEditForm()}
    />
    // return (
    //   <Drawer
    //     width={800}
    //     anchor={'right'}
    //     openSecondary={true}
    //     open={this.props.state.inforsheet_forum}
    //     onRequestChange={(event) => {
    //       event.preventDefault();
    //       this.props.actions.toggleInfoSheet(null, "inforsheet_forum");
    //     }}
    //   >
    //     {this.getView()}
    //   </Drawer>
    // );
  }

  render() {
    return this.getLayout();
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
      ...state.workflowDisposal,
      user: {
        ...state.user,
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
};

export default connect(mapStateToProps,  mapDispatchToProps)(WorkflowDisposalForum);
