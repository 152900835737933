import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab } from '@mui/material'
import GeneralContentView from '../../views/GeneralContentView/component'

import Loading from '../Loading/component'
import Form from './form'
import Grid from './grid'

import * as actions from './actions'

class ClientAccount extends Component {

    render() {
        return <div>
                <Tabs
                    initialSelectedIndex={0}
                    value={this.props.state.tab}
                    inkBarStyle={{
                        height: 4
                    }}
                    onChange={(event, value) => {
                        for(let x of this.props.state.client.component_field){
                            this.props.actions.setComponentFieldInput(x,null)
                        }
                        this.props.actions.changeTabView(value)
                    }}
                >
                    <Tab style={{minWidth:"50%"}} label={"Client Account"} />
                    <Tab style={{minWidth:"50%"}} label={"Create Client Account"} />
                </Tabs>
                <br/>
                <div>
                    {this.props.state.tab === 0 && <Box><Grid /></Box>}
                    {this.props.state.tab === 1 && <Box><Form /></Box>}
                </div>
            </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.clientAccount,
            client: {
                ...state.components.client
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAccount)