import * as types from './types'

export const setupWorkflowTaskInput = (data, component, step) => {
    return (dispatch) => {
        dispatch({
            type: types.CONFIRM_FML_QUOTATION_HEADER_DATA,
            payload: {
                step: step,
                component: component,
                selected: data
            }
        })
    }
}

export const setInputValue = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_FML_QUOTATION_APPROVAL_INPUT_VALUE,
            payload: data
        })
    }
}