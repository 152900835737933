import * as props from './props'
import * as types from './types'
import * as teamTypes from '../Teams/types'
import * as clientTypes from '../Clients/types'

export default (state = props, action) => {
    switch (action.type) {
        case clientTypes.CLIENTS_SET_CLIENTS_LIST:
            return {
                ...state,
                clients: action.payload.map(x => {
                    return {
                        label: x.title,
                        value: x.client_id
                    }
                })
            }
        case teamTypes.WORKFLOWS_SET_TEAMS_HIERARCHY:
            return {
                ...state,
                tree: action.payload
            }
        case types.SET_BUSINESS_CUSTOMER_HIERARCHY:
            return {
                ...state,
                data: action.payload.data,
                hierarchy: action.payload.hierarchy,
                allBusinesses: action.payload.allBusinesses,
                allCustomers: action.payload.allCustomers
            }
        case types.OPEN_USER_LANGUAGE_SELECTION:
            return {
                ...state,
                openlang: action.payload
            }
        case types.SET_TAB_VALUE:
            return {
                ...state,
                [action.name]: action.value
            }
        case types.TOGGLE_SIDE_BAR:
            return {
                ...state,
                sidebar: action.payload
            }
        case types.SET_WORKQUEUE_COUNT:
            return {
                ...state,
                workqueue_count: action.payload
            }
        case types.TOGGLE_APP_BAR_STATE:
            return {
                ...state,
                appBarState: action.payload
            }
        case types.TOGGLE_SIDE_BAR_STATE:
            return {
                ...state,
                sidebarstate: action.payload
            }
        case types.TOGGLE_ORGANISATION:
            return {
                ...state,
                openOrganisation: action.payload
            }
        case types.TOGGLE_LANGUAGE:
            return {
                ...state,
                openLanguage: action.payload
            }
        case types.SET_CLIENT_ID:
            return {
                ...state,
                account: action.payload

            }
        case types.TOGGLE_NOTIFICATIONS:
            return {
                ...state,
                notification: action.payload.status,
                anchorEl: action.payload.anchorEl
            }
        case types.TOGGLE_USER_PROFILE_MENUS:
            return {
                ...state,
                profileMenus: action.payload.status,
                profileMenusEl: action.payload.anchorEl
            }
            case types.SET_COMPONENT_USER_PROFILE_DASHBOARD_DATA:
                return {
                    ...state,
                    oqlis: action.payload
                }
    
        case types.TOGGLE_NOTIFICATIONS_LIST:
            return {
                ...state,
                openNotifications: action.payload,
            }

        case types.ANIMATED_NOTIFICATIONS_BUTTON:
            return {
                ...state,
                animated: action.payload,
            }
        case types.SET_WORKQUEUE_LIST_ITEMS:
            return {
                ...state,
                workqueue: action.payload,
            }
        case types.SET_RIGHT_SIDE_REPORT_DATA:
            return {
                ...state,
                [action.name]: action.payload,
            }
        case types.SET_DOCUMENT_PREVIEW_DATA:
            return {
                ...state,
                [action.name]: action.payload,
            }
        case types.SET_SELECTED_DOCUMENT_PREVIEW:
            return {
                ...state,
                [action.name]: action.payload,
            }

        case types.SET_USER_WORKFLOW_NOTIFICATIONS_MENUS:
            return {
                ...state,
                notifications_items: action.payload.notifications_items,
                notifications_headers: action.payload.notifications_headers,
            }
        default:
            return state
    }
}