import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'

import PdfViewer from '../../PDF/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowApproveQuote extends Component {


    getApprovalView(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col style={styles.viewTitle} xl={3}>{'Quoted Reviewed By:'}</Col>
                <Col style={styles.viewValue} xl={3}>{data.user.firstname} {data.user.lastname}</Col>
                <Col style={styles.viewTitle} xl={3}>{'Reviewer Title'}</Col>
                <Col style={styles.viewValue} xl={3}>{data.user.jobtitle}</Col>
            </Row>
        </Container>

    }

    getLayout(data) {
        return <Card><CardHeader
            title={"Approvals Details"}
            subtitle={window.t.en('steps list')}
            actAsExpander={false}
            showExpandableButton={false}
            style={styles.row}
        />
            <CardContent expandable={false} >
                {this.getApprovalView(data)}
            </CardContent>
            <CardHeader
                title={"FML Quotation Documents"}
                subtitle={window.t.en('Quotation documents')}
                actAsExpander={false}
                showExpandableButton={false}
                style={styles.row}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {
                                data.documents.length
                                    ? <PdfViewer
                                        key={'document_viewer_uploaded_quotes'}
                                        name={'FMLAPPROVAL'}
                                        workflow={'FMLAPPROVAL'}
                                        task={'documents'}
                                        documents={data.documents}
                                        edit={false}
                                        workflow_queue_data={null}
                                    />
                                    : 'Retrieving Document...'
                            }
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) {

        }
        return workflow_queue_data
            ? this.getLayout(workflow_queue_data)
            : 'Loading...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowApproveQuote
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowApproveQuote)