import * as colours from '@mui/material/colors'
import * as config from '../../config'

export const view = {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0
}

export const card = {
    height: '100%'
}

export const scrollbar = {
    backgroundColor: colours.red[500],
    color: colours.red[500],
}

export const spacer = {
    height: '65px',
    width: '100%',
}

export const headerBackground = (image) => {
    return image && image !== null
        ? {
            height: '130px',
            background: 'url(' + config.system.cdn + '/' + image + ')',
            backgroundSize: 1980,
            //opacity: 0.85,
        }
        : {
            height: '130px'
        }
}

export const headerContainer = {
    width: '100%',
    display: 'flex'
}

export const headerIcon = {
    width: 40,
    height: 40,
    fontSize: 40,
    margin: '0 auto'
}

export const headerTitle = {
    background: 'white',
    //opacity: 0.8,
    fontWeight: 'bold',
    fontSize: '34',
    padding: 5,
    marginRight: 20
}

export const headerDescription = {
    background: 'white',
    //opacity: 0.8,
    //color: 'white',
    width: '100%',
    fontSize: '34',
    padding: 5,
    borderRight: '1px solid black'
}

export const content = {
    height: '100%',
    display: 'flex',
    flexFlow: 'row wrap'
}

export const flex = {
    display: 'flex'
}


export const calculator = {
    width: '50%'
}
