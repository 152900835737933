import * as actions from './actions'
import * as types from './types'
//import * as jwt from 'jsonwebtoken'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'
import moment from 'moment'

export const getRestructureGridData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_CONTRACT_RESTRCTURE_COMPONENT_GRID_DATA),
        delay(0)
        ,mergeMap(({ payload, datetime = moment().format() }) =>
            ajax$({
                url: config.system.api + '/components/customercontract/search' + payload.query + '&activated=true&terminated=false&contractdescription=Full Maintenance Lease',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': '-startdate',
                    'related': 'contracttype,vehicle,customercontractderivative,contractaccessory,contractservicetransaction,contractsundry,amortisationcliententry,mmcode,customer.customerderivative,financialinstitution,vehicle.color'
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setRestructureGridData(result.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/customercontract/search' + payload.query, result.response)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontract/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )