import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import TextField from '@mui/material/TextField'; 
import Button from '@mui/material/Button'
import Icon from '../Icon/component'

import { Card, CardContent, CardHeader } from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Container, Row, Col } from 'react-grid-system'  

import 'react-router-dom'

//import DashboardView from './DashboardView/component'
import Oqlis from '../Oqlis/component'
import * as actions from './actions'
import * as styles from './styles'


export class ColumnMenu extends React.Component {

    render() {
        return (
            <div>
                {/* <GridColumnMenuSort  {...this.props} expanded={true} />
                <GridColumnMenuFilter {...this.props} expanded={true} /> */}
            </div>
        );
    }
}

export class ColumnMenuCheckboxFilter extends React.Component {
    render() {
        return (
            <div>
                {/* <GridColumnMenuCheckboxFilter {...this.props} data={[]} expanded={true} /> */}
            </div>
        );
    }
}

 

class BusinessIntelligence extends Component {
    
    componentDidMount() {
        this.props.actions.resetForm()
        this.props.actions.closeScheduleWin()
        this.props.actions.loadIntelligence(this.props.container, this.props.uuid, this.props.component, this.props.parameters, this.props.type)   
        //this.props.actions.getScheduler()
    }

    getReportTool() {
        return <div></div>
        // <div><Toolbar>
        //     <ToolbarItem>
        //         < Button className={"global_button"} icon="home" title="Back" onClick={
        //             () => { this.props.actions.backHome() }}>{window.t.en('Back')}</Button>
        //     </ToolbarItem>
        //     {
        //         this.props.state.report_key.oq_report_key || this.props.state.report_key.oq_dashboard_key? ''
        //             :
        //             <div>
        //                 <ToolbarItem>
        //                     < Button className={"global_button"} disabled={this.props.state.schedule_win ? true : false} icon="excel" title={this.props.type === "dashboard" || this.props.type === "executive" ? "Export to PDF" : "Export To Excel"} onClick={this.props.type === "dashboard" || this.props.type === "executive" ? this.pdfexport : this.export}>
        //                         {window.t.en(this.props.type === "dashboard" || this.props.type === "executive" ? "Export to PDF" : "Export To Excel")}
        //                     </Button>
        //                 </ToolbarItem>

        //                 {
        //                     this.props.type === 'reports' && <ToolbarItem>
        //                         {
        //                             this.props.state.schedule_win
        //                                 ? < Button className={"global_button"} icon="word" title="Back To Report" onClick={
        //                                     () => { this.props.actions.closeScheduleWin() }}>
        //                                     {window.t.en('Back To Report')}
        //                                 </Button>
        //                                 : < Button className={"global_button"} icon="email" title="Schedule Report" onClick={
        //                                     () => { this.props.actions.openScheduleWin({ report_name: this.props.state.report_view.report_name, user_id: this.props.state.user.user_id }) }}>
        //                                     {window.t.en('Schedule Report')}
        //                                 </Button>
        //                         }

        //                     </ToolbarItem>
        //                 }
        //             </div>
        //     }
            
        // </Toolbar>
        //     {/* {
        //     this.props.state.schedule_win && <Window title={"Status"} onClose={this.props.state.schedule_win} initialHeight={550}>
        //         </Window>
        //         } */}
        // </div>
    }

    setWidth = (minWidth) => {
        let width = minWidth + (this.state.gridCurrent - this.minGridWidth) // columns.length;
        //width < COLUMN_MIN ? width : width -= ADJUST_PADDING;
        return width;
    }

    getGridColumns(columns) {

        let headers = Object.keys(columns).map(function (key) {
            return key
        });

        return headers.map((x) => {
            return '' // <Column field={x} title={x} minResizableWidth={120} width={150} filter={'text'} columnMenu={ColumnMenu} />
        })
    }
    _export;
    _pdfExport;
    _grid;
    export = () => {
        this._export.save(this.props.state.result, this._grid.columns);
    }
    pdfexport = () => {
        this._pdfExport.save()  
    }

    getOqlisReportGrid(oq_report_key) {
        return <Oqlis
            displayType={'dashboard'}
            oq_key={oq_report_key}
            oq_theme={""}
        />
    }

    getKendoReportGrid() {
        let curr = moment().format('Do MMMM YYYY, h;mm a')
        return ''
        // return this.props.state.report_data
        //     ? <div>
        //         <ExcelExport fileName={this.props.state.report_view.report_name +' '+ curr + ".xlsx"} ref={exporter => this._export = exporter} />
        //         <Grid resizable
        //             ref={(grid) => { this._grid = grid; }}
        //             style={{ height: '600px' }}
        //             data={this.props.state.result}
        //             {...this.props.state.dataState}
        //             onDataStateChange={this.props.actions.dataStateChange}
        //             sortable={true}
        //         >
        //             {this.props.state.report_data[0]?this.getGridColumns(this.props.state.report_data[0]):<div>{"Report Data Loading....."}</div>}
        //         </Grid>
        //     </div>
        //     : <div>{"Report Data Loading....."}</div>
    }

    getReportGrid(oq_report_key) {
        return this.props.state.report_key.oq_report_key?
        this.getOqlisReportGrid(this.props.state.report_key.oq_report_key)
        :this.getKendoReportGrid()
    }

    getDashboardView() {
        return ''
        // return this.props.state.report_view
        //     ? <PDFExport
        //         ref={component => (this._pdfExport = component)}
        //         paperSize="auto"
        //         margin={40}
        //         fileName={"Dashboard "+this.props.state.report_view.report_name}
        //         author="Infleet Export"
        //     >
        //         <DashboardView dashboard={this.props.state} />
        //     </PDFExport>
        //     : <div>{"Dashboard View Loading....."}</div>
    }

    getExecutiveDashboardView() {
        return ''
        // return this.props.state.report_view
        //     ? <PDFExport
        //         ref={component => (this._pdfExport = component)}
        //         paperSize="auto"
        //         margin={40}
        //         fileName={"Dashboard "+this.props.state.report_view.report_name}
        //         author="Infleet Export"
        //     >
        //         <DashboardView dashboard={this.props.state} />
        //     </PDFExport>
        //     : <div>{"Dashboard View Loading....."}</div>
    }

    getReports() { 
        return ''
        // return <PanelBar expandMode={'single'} onSelect={
        //     (event) => {
        //         this.props.actions.setSeletedReport(event)
        //     }
        // }>
        //     {
        //         this.props.state.category.map(x => {

        //             return <PanelBarItem title={x.category} style={styles.panelbar_header} >
        //                 {
        //                     _.sortBy(x.report, [function (o) { return o.report_name; }]).map(r => {
        //                         return <PanelBarItem style={styles.panelbar_items} title={r.report_name} oqlis={r}/>
        //                     })
        //                 }
        //             </PanelBarItem >
        //         })
        //     }
        // </PanelBar>
    }

    getScheduleReport() {
        //console.log('getScheduleReport')
        //console.log(this.props.state)
        return <Container style={{ margin: 0 }} className="custom-container">

            <Row>
                <Col xl={12}>
                    <div><br /></div>
                </Col>
            </Row>
            <Row>
                <Col xl={8}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldHeader}>{'Schedule To'}</div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Full Name'}</div>
                                    <div style={styles.fieldContent}>{this.props.state.user.firstname} {this.props.state.user.lastname}</div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Division'}</div>
                                    <div style={styles.fieldContent}>{this.props.state.user.jobtitle}</div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Email Address'}</div>
                                    <div style={styles.fieldContent}>{this.props.state.user.email}</div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Phone Number'}</div>
                                    <div style={styles.fieldContent}>{this.props.state.user.phone}</div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldHeader}>{'Schedule Settings'}</div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Schedule Name'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            key={'txt_schedule_name'}
                                            id={'txt_schedule_name'}
                                            hintText={'Enter Scheldule Name'}
                                            value={this.props.state.schedulename}
                                            style={styles.textBox}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setInputValue({ prop: 'schedulename', value: value })
                                                }
                                            }
                                        /></div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Schedule Type'}</div>
                                    <div style={styles.fieldContent}>

                                       < Button className={"global_button"} variant="contained"
                                            label="Email"
                                            labelPosition="before"
                                            primary={!this.props.state.email}
                                            icon={<Icon iclass={'material-icons'} iname={this.props.state.email ? 'done' : 'add'} />}
                                            onClick={() => {
                                                this.props.actions.setInputValue({ prop: 'email', value: !this.props.state.email })
                                            }}
                                        >{window.t.en('Email')}</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Schedule Content'}</div>
                                    <div style={styles.fieldContent}>

                                       < Button className={"global_button"} variant="contained"
                                            label="Excel"
                                            labelPosition="before"
                                            primary={!this.props.state.excel}
                                            icon={<Icon iclass={'material-icons'} iname={this.props.state.excel ? 'done' : 'add'} />}
                                            onClick={() => {
                                                this.props.actions.setInputValue({ prop: 'excel', value: !this.props.state.excel })
                                            }}
                                            >{window.t.en('Excel')}</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Schedule Week Days'}</div>
                                    <div style={styles.fieldContent}>
                                        <div>
                                           < Button className={"global_button"} variant="contained"
                                                label="Mon"
                                                labelPosition="before"
                                                primary={!this.props.state.mon}
                                                icon={<Icon iclass={'material-icons'} iname={this.props.state.mon ? 'done' : 'add'} />}
                                                onClick={() => {
                                                    this.props.actions.setInputValue({ prop: 'mon', value: !this.props.state.mon })
                                                }}
                                                >{window.t.en('Mon')}</Button>
                                           < Button className={"global_button"} variant="contained"
                                                label="Tue"
                                                labelPosition="before"
                                                primary={!this.props.state.tue}
                                                icon={<Icon iclass={'material-icons'} iname={this.props.state.tue ? 'done' : 'add'} />}
                                                onClick={() => {
                                                    this.props.actions.setInputValue({ prop: 'tue', value: !this.props.state.tue })
                                                }}
                                                >{window.t.en('Tue')}</Button>
                                           < Button className={"global_button"} variant="contained"
                                                label="Wed"
                                                labelPosition="before"
                                                primary={!this.props.state.wed}
                                                icon={<Icon iclass={'material-icons'} iname={this.props.state.wed ? 'done' : 'add'} />}
                                                onClick={() => {
                                                    this.props.actions.setInputValue({ prop: 'wed', value: !this.props.state.wed })
                                                }}
                                                >{window.t.en('Wed')}</Button>
                                           < Button className={"global_button"} variant="contained"
                                                label="Thu"
                                                labelPosition="before"
                                                primary={!this.props.state.thu}
                                                icon={<Icon iclass={'material-icons'} iname={this.props.state.thu ? 'done' : 'add'} />}
                                                onClick={() => {
                                                    this.props.actions.setInputValue({ prop: 'thu', value: !this.props.state.thu })
                                                }}
                                                >{window.t.en('Thu')}</Button>
                                           < Button className={"global_button"} variant="contained"
                                                label="Fri"
                                                labelPosition="before"
                                                primary={!this.props.state.fri}
                                                icon={<Icon iclass={'material-icons'} iname={this.props.state.fri ? 'done' : 'add'} />}
                                                onClick={() => {
                                                    this.props.actions.setInputValue({ prop: 'fri', value: !this.props.state.fri })
                                                }}
                                                >{window.t.en('Fri')}</Button>
                                           < Button className={"global_button"} variant="contained"
                                                label="Sat"
                                                labelPosition="before"
                                                primary={!this.props.state.sat}
                                                icon={<Icon iclass={'material-icons'} iname={this.props.state.sat ? 'done' : 'add'} />}
                                                onClick={() => {
                                                    this.props.actions.setInputValue({ prop: 'sat', value: !this.props.state.sat })
                                                }}
                                                >{window.t.en('Sat')}</Button>
                                           < Button className={"global_button"} variant="contained"
                                                label="Sun"
                                                labelPosition="before"
                                                primary={!this.props.state.sun}
                                                icon={<Icon iclass={'material-icons'} iname={this.props.state.sun ? 'done' : 'add'} />}
                                                onClick={() => {
                                                    this.props.actions.setInputValue({ prop: 'sun', value: !this.props.state.sun })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Schedule Time '}</div>
                                    <div style={styles.fieldContent}>
                                    <TextField 
                                            label="Alarm clock"
                                            type="Enter Time"
                                            key={'tm_scheduletime'}
                                            id={'tm_scheduletime'}  
                                            value={this.props.state.scheduletime} 
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setInputValue({ prop: 'scheduletime', value: value })
                                                }
                                            }
                                        /></div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Schedule Start Date '}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            type="date"
                                            label="Enter Schedule Start Date"
                                            id={'dt_start_date'}
                                            key={'dt_start_date'} 
                                            container='inline'
                                            mode='landscape'
                                            value={this.props.state.startdate ? new Date(this.props.state.startdate) : null}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setInputValue({ prop: 'startdate', value: value })
                                                }
                                            }
                                        /></div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Schedule End Date'}</div>
                                    <div style={styles.fieldContent}>
                                    <TextField
                                            type="date"
                                            label="Enter Schedule End Date"
                                            id={'dt_end_date'}
                                            key={'dt_end_date'} 
                                            container='inline'
                                            mode='landscape'
                                            value={this.props.state.enddate ? new Date(this.props.state.enddate) : null}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setInputValue({ prop: 'enddate', value: value })
                                                }
                                            }
                                        /></div>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldHeader}>{'Save Schedule'}</div>
                            </Col>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{''}</div>
                                    <div style={styles.fieldContent}>
                                       < Button className={"global_button"} variant="contained"
                                            key={'add_more_button'}
                                            label={'Save Schedule'}
                                            icon={<Icon iclass={'material-icons'} iname={'save'} />}
                                            //primary={true}
                                            secondary={false}
                                            labelPosition="before"
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.saveSchedule(this.props.state)
                                            }}
                                            >{window.t.en('Save Schedule')}</Button></div>
                                </div>
                                <br /><br /><br /><br />
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xl={4}>
                    <Col xl={12}>
                        <div style={styles.fieldHeader}>{'My Schedule List'}</div>
                    </Col>
                    <div>
                    </div>
                    <List>
                        {
                            this.props.state.schedule_list.length
                                ? this.props.state.schedule_list.map(x => {
                                    return <ListItem key={'report_items_' + x.scheduler_id}
                                        style={x.schedule_name === this.props.state.schedulename ? styles.selected : {}}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.editSchedule(x)
                                        }}>
                                        <Container style={{ margin: 0 }} className="custom-container">
                                            <Row>
                                                <Col xl={10}>
                                                    {x.schedule_name}
                                                </Col>
                                                <Col xl={2}>
                                                    <Icon
                                                        iname="delete_forever"
                                                        iclass="material-icons"
                                                        istyle={{
                                                            fontSize: '16px',
                                                            color: 'Red'
                                                        }}
                                                        onClick={() => {
                                                            this.props.actions.removeSchedule(x)
                                                        }}
                                                    /></Col>
                                            </Row>
                                        </Container>
                                    </ListItem>
                                })
                                : 'No Schedules List'
                        }
                    </List>
                </Col>
            </Row></Container>
    }

    getScheduleHistory() {
        return 'His'
    }

    getScheduleWin() {
        return <Tabs
            initialSelectedIndex={0}
            inkBarStyle={{
                height: 4
            }}
        >
            <Tab
                // label={'Schedule '}
                value={0}>
                {this.getScheduleReport()}
            </Tab>
            <Tab
                // label={'History'}
                value={1}>
                {this.getScheduleHistory()}
            </Tab>
        </Tabs>
    }
    getReportView() {
        return <Card style={{ width: '100%', marginTop: '25px' }}>
            <CardHeader>
                <div>
                    {this.props.type.toUpperCase() + ': ' + this.props.state.report_view.report_name}
                </div>
            </CardHeader>
            <CardContent>
                {/* <CardTitle>
                    {this.getReportTool()}
                </CardTitle> */}
                {
                    this.props.state.schedule_win
                        ? <div style={styles.panelbar_wrapper}>
                            {this.getScheduleWin()}
                        </div>
                        : <div style={styles.panelbar_wrapper}>
                            {this.props.type === 'dashboard' && this.getDashboardView()}
                            {this.props.type === 'reports' && this.getReportGrid()}
                            {this.props.type === 'executive' && this.getExecutiveDashboardView()}
                        </div>

                }
            </CardContent>
        </Card>
    }

    getCategoryView() {
        return <div
            id={this.props.container}
            key={this.props.uuid}
            style={
                this.props.size
                    ? this.props.size
                    : this.props.primary ? styles.primary : styles.biDashboard
            }
        >
            <Card style={{ width: '100%', marginTop: '25px' }}>
                <CardContent>
                    <CardHeader>{ window.t.en(this.props.title)}</CardHeader>
                    <hr />
                    <div style={styles.panelbar_wrapper}>
                        {this.getReports()}
                    </div>
                </CardContent>
            </Card>
        </div>
    }

    getLayout() {
        return this.props.state.report_view ? this.getReportView() : this.getCategoryView()
    }

    render() {
        return (
            <div style={{ marginTop: 90 }}>{this.getLayout()}</div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.businessIntelligence,
            user: state.user
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessIntelligence)