//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const icon = {
    fontSize: '10px'
}

export const dropZone = {
    width: '80%',
    height: '50px',
    border: '1px solid red',
    padding: '10px'
}

export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}

export const row = {
    fontSize: 15,
    //backgroundColor: '#dcd6d7',
}
export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    //fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const textBox = {
    width: '100%'
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
}