import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

import { Container, Row, Col } from "react-grid-system";

import "react-table/react-table.css";
import TextField from '@mui/material/TextField';
import logo from "../../assets/img/visa_card.jpeg";
import Loading from "../Loading/component";

import * as actions from "./actions";
import * as styles from "./styles";

class CardTile extends Component {
  getTileView() {
    return (
      <Container style={{ margin: 0 }} className="custom-container">
        <Row>
          <Col xl={4}>Filter Card</Col>
          <Col xl={8}>
            <TextField
              id={"txtunallocated"}
              fullWidth={true}
              value={this.props.state.card_search}
              onChange={(event, value) => {
                this.props.actions.setCardData({
                  prop: "card_search",
                  data: event.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          {this.props.state.card_search
            ? this.props.state.cards
                .filter((f) =>
                  f.cardnumber.includes(this.props.state.card_search)
                )
                .map((x) => {
                  return (
                    <Col xl={3}
                      onClick={(event) => {
                        event.preventDefault();
                        this.props.actions.setCardData({
                          prop: "card",
                          data: x,
                        });
                        this.props.actions.loadCardData(x.card_id);
                      }}
                    >
                      <div style={styles.cardnumber}>{x.cardnumber}</div>
                      <div style={styles.cardexpiry}>
                        {x.expirymonth}/{x.expiryyear}
                      </div>
                      <div style={styles.cardname}>{x.cardname}</div>
                      <div style={styles.cardtype}>{x.cardtype}</div>
                      <img style={styles.image} src={logo} alt="Card" />
                    </Col>
                  );
                })
            : this.props.state.cards.map((x) => {
                return (
                  <Col xl={3}
                    onClick={(event) => {
                      event.preventDefault();
                      this.props.actions.setCardData({ prop: "card", data: x });
                      this.props.actions.loadCardData(x.card_id);
                    }}
                  >
                    <div style={styles.cardnumber}>{x.cardnumber}</div>
                    <div style={styles.cardexpiry}>
                      {x.expirymonth}/{x.expiryyear}
                    </div>
                    <div style={styles.cardname}>{x.cardname}</div>
                    <div style={styles.cardtype}>{x.cardtype}</div>
                    <img style={styles.image} src={logo} alt="Card" />
                  </Col>
                );
              })}
              <br />
              <br />
        </Row>
      </Container>
    );
  }

  render() {
    return this.props.state.cards.length ? (
      this.getTileView()
    ) : (
      <Loading message={"Tile Loading component..."} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
      ...state.cardManagement,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardTile);
