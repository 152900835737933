import * as types from './types'

export const action = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.PO_SUBCOMPONENT_ACTION,
            payload: {}
        })
    }
}


export const toggleWorkflowVehicleHistory = (name, status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_WORKFLOW_VEHICLE_HISTORY,
            payload: {
                name: name,
                status: status
            }
        })

        dispatch({
            type: types.RESET_WORKFLOW_VEHICLE_HISTORY
        })
    }
}

export const showWorkflowVehicleHistory = (name) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SHOW_WORKFLOW_VEHICLE_HISTORY,
            payload: {
                name: name
            }
        })

        // if (getState().workflowTimeline[name].isOpen) {
        //     dispatch(getWorkflowTimeline(name))
        // }
    }
}

export const closeWorkflowVehicleHistory = (name) => {
    return (dispatch) => {
        dispatch({
            type: types.CLOSE_WORKFLOW_VEHICLE_HISTORY,
            payload: {
                name: name
            }
        })
    }
}

export const chkHistoryType = (prop, value) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONET_HISTORY_INPUT_FILEDS,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const downloadMaintanancePDF = (data, list, vehicle_id, makemodelyear, licenseplateno, imagefilename, customercontractderivative) => {
    return (dispatch) => {
        const vehicle_history_data = {
            new_his: data,
            old_his: list,
            vehicle_id: vehicle_id,
            makemodelyear: makemodelyear,
            licenseplateno: licenseplateno,
            imagefilename: imagefilename,
            customercontractderivative: customercontractderivative
        };
        dispatch({
            type: types.GET_MAINTENANCE_HOSTORY_PDF_DOCUMENT,
            payload: {
                type: 'GET_MAINTENANCE_HOSTORY_PDF_DOCUMENT',
                data: vehicle_history_data
            }
        })
    }
}

export const setMaintenanceHistoryPDF = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], 'Vehicle Maintenance History.pdf')

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WS_MAINTENANCE_HOSTORY_PDF_DOCUMENT,
            payload: {
                preview: link,
                filename: data.data.key.replace('quotes/', '')
            }
        })
    }
}
