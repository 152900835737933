import moment from 'moment'
import * as componentActions from '../../Components/actions'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const loadComponent = (name) => {
    return (dispatch) => {
        dispatch(componentActions.getComponent(name))
    }
}

export const getComponentGridData = (component, link, paging, filter, id) => {
    window.glyco.log('setComponentGridData getComponentGridData')
    if (!paging) {
        paging = {
            page: 0,
            pageCount: 1,
            pageSize: 10,
            rowCount: 0,
            sorted: [],
            filtered: []
        }
    }
    
    return (dispatch, getState) => {
        
        component = component.name ? component : getState().components[component]

        const getRelated = () => {
            if (link) {
                const parent = getState().components[link.parent]
                const through = getState().components[link.through]

                const query = '?' + parent.component_field.filter(x => x.key)[0].name + '=' + link.id

                return {
                    component: through,
                    child: component,
                    query: query
                }
            }

            return undefined
        }

        const getQuery = (filters) => {
            //console.log('eventx filters',filters)
            let query = []
            filters.map((x) => {
                query.push(x.field.concat('=', x.value))
            })

            return '?' + query.join('&')
        }

        const withRelatedId = (query) => {

            if (!id || !filter) {
                return query
            }

            const key = getState().components[filter].component_field.filter(x => x.key)[0]

            if (!key) {
                return query
            }

            return query.indexOf('?' > -1) && query.length > 1
                ? query + '&' + key.name + '=' + id
                : '?' + key.name + '=' + id
        }

        //let query = paging.filtered ? withRelatedId(getQuery()) : ''
        const related = getRelated(filter)
        const query = filter ? getQuery(filter) : ''

        //query = filter ? '?' + filter.field +'='+ filter.value : ''

        //console.log('eventx query',query)

        dispatch({
            type: types.GET_COMPONENT_GRID_DATA,
            payload: {
                id: id,
                component: !related ? component : related.component,
                input: '',
                paging: paging,
                sorting: paging.sorted.length > 0
                    ? !paging.sorted[0].desc
                        ? paging.sorted[0].id
                        : '-' + paging.sorted[0].id
                    : '',
                query: !related ? query : related.query,
                related: component.related !== null ? component.related ? component.related : '' : '',
                child: !related ? {} : related.child
            }
        })
    }
}

export const setComponentGridData = (payload) => {
    return (dispatch, getState) => {
        dispatch(setInputValue('accountingitems',{
            type: types.SET_ACCOUNT_ITEM_LINK_COMPONENT_VALUE,
            payload: {
                component: payload.child.name ? payload.child : payload.component,
                data: payload.child.name
                    ? {
                        ...payload.data,
                        data: payload.data.data.map(x => {
                            return x[payload.child.name]
                        })
                    }
                    : payload.data
            }
        }))
    }
}


export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACCOUNT_ITEM_LINK_COMPONENT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const getAccountingItemList = (search) => {
    //console.log('setAccountingItemListx get', search)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ACCOUNTITEMS_LIST,
            payload: search
        })
    }
}

export const setAccountingItemList = (response) => {
    //console.log('setAccountingItemListx set', response)
    return (dispatch, getState) => {
        dispatch(setInputValue('accountingitems', (response.data ? response.data : [])))
    }
}

export const getCustomerList = (search) => {
    //console.log('getViewx cust get', search)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CUSTOMER_LIST,
            payload: {
                search: search
            }
        })
    }
}

export const setCustomerList = (response) => {
    //console.log('getViewx cust set', response)
    return (dispatch, getState) => {
        dispatch(setInputValue('customers', response.data))
    }
}

export const linkAcountingItem = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_COMPONENT_ACCOUNTING_ITEM_LINK,
            payload: data
        })
    }
}

export const deLinkAcountingItem = (data) => {
    return (dispatch, getState) => {
        //console.log('deLinkAcountingItemx', data)
        dispatch({
            type: types.DEL_COMPONENT_ACCOUNTING_ITEM_LINK,
            payload: data
        })
    }
}

export const savedAccountingItemLink = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Link Actions Completed!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        
        dispatch(getAccountingItemLinkList(payload.customer_id))
        dispatch(getSalesTypeList(''))
    }
}

export const getSalesTypeList = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SALESCODE_LIST,
            payload: {
                search: search
            }
        })
    }
}

export const setSalesTypeList = (response) => {
    //console.log('getViewx cust set', response)
    return (dispatch, getState) => {
        dispatch(setInputValue('salestypes', response.data))
    }
}

export const getAccountingItemLinkList = (customer_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ACCOUNTING_ITEM_LINK_LIST,
            payload: customer_id
        })
    }
}

export const setAccountingItemLinkList = (response) => {
    //console.log('getViewx cust set', response)
    return (dispatch, getState) => {
        dispatch(setInputValue('accountingitemlinks', response.data))
    }
}