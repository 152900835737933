import * as types from './types'
import * as actions from './actions'
import * as routerActions from '../Redirect/actions'

export const validateFieldEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.VALIDATE_FIELD)
        ,mergeMap(({ payload }) =>
            payload.validators.filter(x => x === types.IS_STRONG_PASSWORD)[0] !== undefined
                ? [
                    actions.showPasswordStrength(payload.input),
                    payload.valid
                        ? actions.validationSucceeded()
                        : actions.validationFailed()
                ]
                : [
                    payload.valid
                        ? actions.validationSucceeded()
                        : actions.validationFailed()
                ]
        )
        ,catchError((error) =>
            of$(
                routerActions.route('/support', false, error)
            )
        )
)

export const validateFormEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.IS_AFTER)
        ,mergeMap(({ payload }) =>
            Object.keys(payload).forEach(x => {
                payload[x].validators.filter(y => y === types.MATCHES)[0] !== undefined
                    ? actions.validateField(payload[x], payload[x].input, {}, false)
                    : actions.validateField(payload[x], payload[x].input, {}, false)
            })
        )
        ,catchError((error) =>
            of$(
                routerActions.route('/support', false, error)
            )
        )
)