import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'



export const getComponentFieldLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_WQH_COMPONENT_FIELD_LOOKUP_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.model + '/search'+ payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setComponentFieldLookupData(payload.component.name, payload.field, result.response),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/' + payload.model + '/search')
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.model + '/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getComponentFieldLookupValue = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
            ofType(types.GET_COMPONENT_FIELD_LOOKUP_VALUE),
            delay(0)
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/' + payload.model + '/search'+ payload.query,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        //'related': 'all' //payload.related
                    },
                })
                    .pipe(
            mergeMap((result) => [
                        actions.setComponentFieldLookupValue(payload.component.name, payload.field, result.response),
                        //progressBarActions.progress(false),
                        requestActions.requestSucceeded(config.system.api + '/components/' + payload.model + '/search')
                    ])
                    //,startWith(progressBarActions.progress(true))
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.model + '/search', error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            ),
            catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )

export const getContractTypeDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_CONTRACT_TYPE_DETAILS_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customerderivative/search?customerderivative_id='+ payload.customerderivative_id,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'interestratetype' //payload.related
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setContractTypeDetails(result.response),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customerderivative_id='+ payload.customerderivative_id)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customerderivative_id='+ payload.customerderivative_id+ '/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

        export const getQuoteVehicleDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(
                ofType(types.GET_QUOTE_VEHICLE_DETAILS),
                delay(0)
                ,mergeMap(({ payload }) =>
                    ajax$({
                        url: config.system.api + '/components/' + payload.component + '/search',//+ payload.query,
                        method: 'GET',
                        headers: {
                            'tokenx': store.value.user.user_id,
                            'auth': store.value.cognito.authenticateUser.token,
                            'salt': store.value.cognito.authenticateUser.jwt,
                            'datetime': store.value.cognito.authenticateUser.datetime,
                            'related': 'all' //payload.related
                        },
                    })
                        .pipe(
                    mergeMap((result) => [
                            actions.setQuoteVehicleDetails(payload.component.name, payload.field, result.response),
                            //progressBarActions.progress(false),
                            requestActions.requestSucceeded(config.system.api + '/components/' + payload.field.component_reference.name + '/search')
                        ])
                        //,startWith(progressBarActions.progress(true))
                        ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.field.component_reference.name + '/search', error)))
                        //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
                )
                ,catchError((error) =>
                    of$(routerActions.route('/support', false, error))
                )
                )

  

        
export const searchWorkings = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.SEARCH_WORKINGS_QUOTATION),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/' + payload.component + '/search' + payload.query,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'related': '' //payload.related
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setWorkings(payload.component, payload.field, result.response),
                //progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/' + payload.component + '/search')
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.component + '/search', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)
     
export const getContractVehicleOptions = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_CONTRACT_CATEGORY_VEHICLE_OPTIONS),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/' + payload.model + '/search' + payload.query,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setVehicleOptions(payload.component, result.response),
                requestActions.requestSucceeded(config.system.api + '/components/' + payload.component + '/search')
            ])
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.component + '/search', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)
    

export const getRateCard = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_RATECARD_COMPONENT_LOOKUP_PRIME_RATE_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/ratecard/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json',
                },
                body: payload
            })
            .pipe(
                mergeMap((result) => [
                    actions.setRateCard(result.response, payload),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/primerate/search')
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/primerate/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
    
export const getRatecardMMCodes = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_RATECARD_COMPONENT_LOOKUP_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/ratecard/search' + payload.query,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'mmcode.discountedpricing,contractvehiclecategory.contracttype.contracttypederivative',
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setRatecardMMCodes(result.response, payload),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/ratecard/search' + payload.query)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/ratecard/search' + payload.query, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)



export const getVehicleContractDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_VEHICLE_CONTRACT_DETAILS),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/customercontract/search?vehicle_id=' + payload.value,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': payload.related,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setVehicleContractDetails( payload.field, result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/customercontract/search?vehicle_id=' + payload.value)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontract/search?vehicle_id=' + payload.value, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getCategoryChipAccessories = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/contractvehiclecategorychipaccessory/search?contractvehiclecategory_id=' + payload.value,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'accessory',
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setCategoryChipAccessories(result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/contractvehiclecategorychipaccessory/search?contractvehiclecategory_id=' + payload.value)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/contractvehiclecategorychipaccessory/search?contractvehiclecategory_id=' + payload.value, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)


export const getContractMandatoryAccessory = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_VEHICLE_DEFAULT_ACCESSORY_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/mandatorycontracttypeaccessory/search?contractvehiclecategory_id=' + payload.value,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'accessory',
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setContractMandatoryAccessory(result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/mandatorycontracttypeaccessory/search?contractvehiclecategory_id=' + payload.value)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mandatorycontracttypeaccessory/search?contractvehiclecategory_id=' + payload.value, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getContractMandatorySundry = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_VEHICLE_DEFAULT_ACCESSORY_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/mandatorycontracttypesundry/search?contractvehiclecategory_id=' + payload.value,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'sundry',
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setContractMandatorySundry(result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/mandatorycontracttypesundry/search?contractvehiclecategory_id=' + payload.value)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mandatorycontracttypesundry/search?contractvehiclecategory_id=' + payload.value, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

    
export const getContractMandatoryService = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_VEHICLE_DEFAULT_ACCESSORY_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/mandatorycontracttypeservice/search?contractvehiclecategory_id=' + payload.value,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'service',
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setContractMandatoryService(result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/mandatorycontracttypeservice/search?contractvehiclecategory_id=' + payload.value)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mandatorycontracttypeservice/search?contractvehiclecategory_id=' + payload.value, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)


export const getContractTypesLookupValue = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_CUSTOMER_CONTRACT_TYPES_LOOKUP_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            ///url: config.system.api + '/components/customercontracttype/search?customer_id=' + payload.value,
            url: config.system.api + '/components/contracttype/search',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setContractTypesLookupValue(payload.field, result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/customercontracttype/search?customer_id=' + payload.value)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontracttype/search?customer_id=' + payload.value, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getMMLookupValue = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_MM_COMPONENT_MMCODE_LOOKUP_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/mmcode/search?mmcode=' + payload.search,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'order': '-regyear'
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setMMLookupValue(payload.field, result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/search/search?mmcode=' + payload.search)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/search/search?mmcode=' + payload.search, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getCustomerDerivativeLookupValue = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_CUSTOMER_DERIVATIVE_LOOKUP_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contractname=' + payload.search,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'order': 'contracttype'
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setCustomerDerivativeLookupValue(result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getCustomerDerivativeCategoryLookupValue = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_CUSTOMER_DERIVATIVE_CATEGORY_LOOKUP_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/customerderivativecategory/search?customerderivative_id=' + payload.customerderivative_id + '&customerderivativecategory=' + payload.search,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'order': 'contracttype'
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setCustomerDerivativeCategoryLookupValue(result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getCustomerDerivativeCategoryMetaData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_CUSTOMER_DERIVATIVE_CATEGORY_META_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/' + payload.model + '/search?customerderivativecategory_id=' + payload.value,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setCustomerDerivativeCategoryMetaData(result.response, payload),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.SET_COMPONENT_LOOKUP_DATA_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/' + payload.component + '/search?' + payload.field + '=' + payload.search,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setLookupData(result.response, payload),
                progressBarActions.progress(false),
                //requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)


export const genBulkQuotation = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GEN_CUSTOMER_BULK_QUOTATION_REFERENCE),
    delay(0)
    ,mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/bulkreference/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setBulkQuotation(result.response),
                progressBarActions.progress(false),
                //requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)