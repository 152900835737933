import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid,Card,CardContent,Button,List,ListItem,Tooltip } from '@mui/material';

import PerfectScrollbar from 'react-perfect-scrollbar';
import CalendarFullWidth from './calendar';

import * as actions from './actions'

class EventDashboard extends Component {
    getLayout() {
        return <div style={{height: 1000}}>
          <div className="app-inner-content-layout app-inner-content-layout-fixed-" style={{left: "0 !important"}}>
            {/* <div className="d-flex d-lg-none p-4 order-0 justify-content-between align-items-center">
              <Button
                onClick={(event, value) => {
                    this.props.actions.setInputValue({prop: 'add_win', value: true})
                }}
                size="small"
                className="btn-primary p-0 btn-icon d-40">
                Add
              </Button>
            </div> */}
            <div>
            <div className="card-header rounded-0 bg-white p-4 border-bottom">
                <div className="card-header--actions">
                  <Button
                    onClick={(event, value) => {
                        this.props.actions.reset_form()
                        this.props.actions.setInputValue({prop: 'add_win', value: true})
                    }}
                    size="small"
                    className="btn-first hover-scale-sm btn-pill">
                   &nbsp;&nbsp;&nbsp;Event Add&nbsp;&nbsp;&nbsp;
                  </Button>
                </div>
              </div>
                <div>
                    <List
                    component="div"
                    className="nav-pills nav-neutral-primary flex-column">
                    <ListItem className="nav-header px-0 d-flex pb-2 align-items-center">
                        <div className="text-primary font-weight-bold">
                        My Events
                        </div>
                        <div className="ml-auto font-size-xs">
                        </div>
                    </ListItem>
                    {
                        this.props.state.workqueue.map(x => {
                            return <ListItem
                                component="a"
                                button
                                onClick={() => {
                                    this.props.actions.loadEvent(x.workqueue_id)
                                    this.props.actions.setInputValue({prop: 'add_win', value: true})
                                }}>
                                <div className="badge badge-success badge-circle-inner shadow-none mr-2">
                                1
                                </div>
                                {x.workqueue_name}
                            </ListItem>
                        })
                    }
                    {/* <ListItem
                        component="a"
                        button
                        onClick={(e) => e.preventDefault()}>
                        <div className="badge badge-success badge-circle-inner shadow-none mr-2">
                        1
                        </div>
                        Upcoming events
                    </ListItem> */}
                    </List>
                </div>
                <div className="divider" />
                <div className="p-4-" style={{paddingLeft: "0 !important"}}>
                    <List
                    component="div"
                    className="nav-pills nav-neutral-primary flex-column">
                    {/* <ListItem className="nav-header p-0 m-0">
                        <div className="text-primary font-weight-bold">
                        Upcoming meetups
                        </div>
                    </ListItem> */}
                    </List>
                    
                    {
                        this.props.state.workqueue.map(x => {
                            return  <Card className="card-box mt-3 mb-4">
                            <div className="card-indicator bg-success" />
                            <CardContent className="px-4 py-3">
                                <div className="pb-3 d-flex justify-content-between">
                                <a href="#/" 
                                
                                onClick={() => {
                                    this.props.actions.setInputValue({prop: 'workqueue_id', value: x.workqueue_id})
                                    this.props.actions.setInputValue({prop: 'log_win', value: true})
                                }}>
                                {x.workqueue_name}
                                </a>
                                </div>
                                <div className="d-flex align-items-center justify-content-start">
                                <div className="badge badge-success px-3">Active</div>
                                <div className="font-size-sm text-danger px-2">
                                    <FontAwesomeIcon
                                    icon={['far', 'clock']}
                                    className="mr-1"
                                    />
                                    Event Logs {this.props.state.workqueue_calendar ? this.props.state.workqueue_calendar.filter(c => c.workqueue_id === x.workqueue_id).length : 0}
                                </div>
                                </div>
                            </CardContent>
                            </Card>
                        })
                    }
                   
                    {/* <Card className="card-box mb-4">
                    <div className="card-indicator bg-success" />
                    <CardContent className="px-4 py-3">
                        <div className="pb-3 d-flex justify-content-between">
                        <a href="#/" onClick={(e) => e.preventDefault()}>
                            Active
                        </a>
                        </div>
                        <div className="d-flex align-items-center justify-content-start">
                        <div className="badge badge-success px-3">Fixed</div>
                        <div className="font-size-sm text-dark px-2">
                            <FontAwesomeIcon
                            icon={['far', 'clock']}
                            className="mr-1"
                            />
                            09:41
                        </div>
                        </div>
                    </CardContent>
                    </Card> */}
                </div>
            </div>
            <div className="app-inner-content-layout--main card-box- bg-white p-0">
            
              <PerfectScrollbar>
                <div className="p-4">
                  <CalendarFullWidth />
                </div>
              </PerfectScrollbar>
            </div>
    
            <div
              onClick={(e) => e.preventDefault()}
              className={'sidebar-inner-layout-overlay'}
            />
          </div>
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.events 
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDashboard)