export const odometer = null
export const odometerdate = null

export const fields = { 
    documents: {
        errorText: 'Please add a document and select document type',
        data: false,
        valid: false,
        empty: true,
        popup: true,
        type: 'documents',
      },
      odometerdate: {
        errorText: 'Please select Odometer Change Date',
        valid:false,
        state: 'getState().odometerChangeCapture?.odometerdate'
    },
    odometer: {
        errorText: 'Please enter Vehicle New Odometer',
        valid:false,
        state: 'getState().odometerChangeCapture?.odometer',
        validations : [{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Vehicle New Odometer'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Vehicle New Odometer greater than zero'
        }]
    }
}