import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_CALENDAR_EVENTS:
        return {
            ...state,
            events: action.payload
        }
        case types.SET_GANTT_TASKS:
        return {
            ...state,
            tasks: action.payload
        }
        case types.SET_COMPONENT_SHOW_ALL_EVENTS:
        return {
            ...state,
            showall: action.payload
        }
        default:
            return state
    }
}