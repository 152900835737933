import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'

import * as compActions from '../../Components/actions'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import SUPPLIERPAYMENTALLOCATION from '../../Custom/SUPPLIERPAYMENTALLOCATION/component'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'

import * as actions from './actions'
import * as styles from './styles'
var view = false 
class SUPPLIERPAYMENTREVERSAL extends Component {
    componentDidMount() {
        if(this.props.state.loading) this.props.actions.getComponent('SUPPLIERPAYMENTREVERSAL')
    }

    addNew() {
        this.props.actions.setInputValue('supplierpaymentreversaldate', new Date())        
        this.props.actions.setInputValue('credit', 0)
        this.props.actions.setInputValue('debit', 0)
        this.props.actions.setInputValue('nettvalue', 0)
        this.props.actions.setInputValue('supplier_id', '')
        this.props.actions.setInputValue('supplier_fktext', '')

        var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')                                                          
        this.props.actions.setInputValue('supplierpaymentreversalmonth', _date)
        _date = moment(new Date()).format('YYYY-MM-DD')      
        this.props.actions.setInputValue('reference', _date)
        this.props.actions.setInputValue('description', '')
        this.props.actions.setInputValue('business_id', '')
        this.props.actions.setInputValue('businessname', '')        
        this.props.actions.setInputValue('accountingitem_id', '')
        this.props.actions.setInputValue('itemname', '')
        view = false
    }

    getView() {
        return <Tabs>
        <Tab label={'Capture'}>
        <br></br>
        <Card style={styles.card} style={styles.row}>
            <CardHeader
                title={window.t.en('Creditors Payment Reversal')}
                subtitle={window.t.en('Capture creditor payment reversal')}
                actAsExpander={true}
                showExpandableButton={true}
            />
           <CardContent>
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={12}>
                            <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}><strong>Reversed Payment:</strong></div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_invoice_id'}
                                                key={'dll_invoice_id'}
                                                hintText={'Search by reference number...'}
                                                filter={AutoComplete.fuzzyFilter}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.reference.data}
                                                onNewRequest={
                                                    (chosenRequest, index) => {
                                                        this.props.actions.setSelectedLookup("reference", chosenRequest.value, chosenRequest.text)

                                                        if (chosenRequest.value) {
                                                            view = true;
                                                            this.props.actions.setInputValue('supplier_id', chosenRequest.supplier_id)
                                                            this.props.actions.setInputValue('suppliername', chosenRequest.supplier_text)
                                                            this.props.actions.setInputValue('supplierpaymentreversaldate', new Date(chosenRequest.suppliertransactiondate))
                                                            this.props.actions.setInputValue('supplierpaymentreversalmonth', chosenRequest.suppliertransactionmonth)
                                                            this.props.actions.setInputValue('reference', chosenRequest.supplierreference)
                                                            this.props.actions.setInputValue('description', chosenRequest.description)
                                                            this.props.actions.setInputValue('business_id', chosenRequest.business_id)
                                                            this.props.actions.setInputValue('businessname', chosenRequest.business_text)   
                                                            this.props.actions.setInputValue('accountingitem_id', chosenRequest.accountingitem_id)
                                                            this.props.actions.setInputValue('itemname', chosenRequest.accountingitem_text)                                                         
                                                            this.props.actions.setInputValue('nettvalue', chosenRequest.debit)                                                          
                                                            this.props.actions.setInputValue('credit', chosenRequest.debit)
                                                        }
                                                    }
                                                }
                                                onClick={
                                                    (event) => {
                                                        this.props.actions.getAllReversedPayments('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search, dataSource, params) => {
                                                        this.props.actions.getAllReversedPayments(search)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                   < Button className={"global_button"} variant="contained" header={false}
                                        primary={true}
                                        label={'New Reversal'}
                                        onClick={(event) => {
                                            this.addNew()
                                        }}
                                        >{window.t.en('New Reversal')}</Button>
                                </Col>
                            </Row>
                                <Row>
                                    <Col xl={6}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Creditor:'}</div>
                                            <div style={styles.fieldContent}>
                                            {view ?
                                                    <TextField
                                                        disabled={true}
                                                        id={'txt_suppliername'}
                                                        hintText='creditor'
                                                        value={this.props.state.data.suppliername.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('suppliername', value)
                                                        }}
                                                    /> :<AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'dll_creditor_id'}
                                                    key={'dll_creditor_id'}
                                                    hintText={'Select a creditor...'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    dataSource={this.props.state.data.supplier_id.data}
                                                    onNewRequest={
                                                        (chosenRequest, index) => {
                                                            this.props.actions.setSelectedLookup("supplier_id", chosenRequest.value, chosenRequest.text)
                                                            this.props.actions.setInputValue('description', chosenRequest.text)
                                                            this.props.actions.getSupplierPaymentAccount(chosenRequest.value)
                                                            this.props.actions.setInputValue('nettvalue', this.props.state.data.credit.input)
                                                            this.props.actions.setInputValue('supplierpaymentreversaldate', new Date())

                                                            var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')                                                          
                                                            this.props.actions.setInputValue('supplierpaymentreversalmonth', _date)
                                                        }
                                                    }
                                                    onClick={
                                                        (event) => {
                                                            this.props.actions.getCreditorData('')
                                                        }
                                                    }
                                                    onUpdateInput={
                                                        (search, dataSource, params) => {
                                                            this.props.actions.getCreditorData(search)
                                                        }
                                                    }
                                                />
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Date:'}</div>
                                            <div style={styles.fieldContent}>
                                               <TextField
                                                type="date"
                                                    disabled={view}
                                                    id={'txt_invoicedate'}
                                                    hintText='Date'
                                                    container='inline'
                                                    mode='landscape'
                                                    value={this.props.state.data.supplierpaymentreversaldate.input}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue('supplierpaymentreversaldate', value)                                                        
                                                        var _date = moment(new Date(value)).format('YYYYMM')  
                                                        this.props.actions.setInputValue('supplierpaymentreversalmonth', _date)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Reference:'}</div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    disabled={view}
                                                    id={'txtreference'}
                                                    hintText='Reference Number'
                                                    value={this.props.state.data.reference.input}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue('reference', value)
                                                        this.props.actions.getDuplicateReferenceCheck(value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Description:'}</div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    disabled={view}
                                                    id={'txtdescription'}
                                                    value={this.props.state.data.description.input}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue('description', value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Branch:'}</div>
                                            <div style={styles.fieldContent}>
                                            {
                                                view ?
                                                    <TextField
                                                        disabled={true}
                                                        id={'txt_businessname'}
                                                        hintText='Branch'
                                                        value={this.props.state.data.businessname.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('businessname', value)
                                                        }}
                                                    /> :<AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'dll_business_id'}
                                                    key={'dll_business_id'}
                                                    hintText={'Select a branch...'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    dataSource={this.props.state.data.business_id.data}
                                                    onNewRequest={
                                                        (chosenRequest, index) => {
                                                            this.props.actions.setSelectedLookup("business_id", chosenRequest.value, chosenRequest.text)
                                                        }
                                                    }
                                                    onClick={
                                                        (event) => {
                                                            this.props.actions.getBusinessListData('')
                                                        }
                                                    }
                                                    onUpdateInput={
                                                        (search, dataSource, params) => {
                                                            this.props.actions.getBusinessListData(search)
                                                        }
                                                    }
                                                />}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Month:'}</div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    /* disabled={true} */
                                                    id={'txt_supplierpaymentreversalmonth'}
                                                    hintText='Month'
                                                    value={this.props.state.data.supplierpaymentreversalmonth.input}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue('supplierpaymentreversalmonth', value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} >
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12} style={styles.row}>
                                        <br />
                                        <Container style={{ margin: 0 }} className="custom-container">
                                            <br />
                                            <Row>
                                                <Col xl={2} ></Col>
                                                <Col xl={10}>
                                                    <strong style={styles.fieldContent}>{'Payment Amount'}</strong>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={2} ></Col>
                                                <Col xl={10} >
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            disabled={true}
                                                            id={'txtnonvatsubtotal'}
                                                            value={this.props.state.data.credit.input}
                                                            onChange={(event, value) => {
                                                                //this.props.actions.setInputValue('business_id', value)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={2} ></Col>
                                                <Col xl={10}>
                                                    <strong style={styles.fieldContent}>{'Settlement Discount Amount'}</strong>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col xl={2} ></Col>
                                                <Col xl={10} >
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            disabled={view}
                                                            id={'txtnonvatsubtotal'}
                                                            value={this.props.state.data.debit.input}
                                                            onChange={(event, value) => {
                                                                this.props.actions.setInputValue('debit', value)
                                                                var net = Number(this.props.state.data.nettvalue.input)
                                                                this.props.actions.setInputValue('credit', net - value)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col xl={2} ></Col>
                                                <Col xl={10}>
                                                    <strong style={styles.fieldContent}>{'Total Payment Amount'}</strong>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={2} ></Col>
                                                <Col xl={10} >
                                                    <div style={styles.fieldContent}>
                                                        <TextField
                                                            disabled={view}
                                                            id={'txttotal'}
                                                            value={this.props.state.data.nettvalue.input}
                                                            onChange={(event, value) => {
                                                                this.props.actions.setInputValue('nettvalue', value)
                                                                var settlementdiscount = Number(this.props.state.data.debit.input)
                                                                this.props.actions.setInputValue('credit', value - settlementdiscount)

                                                            }}
                                                        />
                                                    </div>
                                                </Col></Row>
                                        </Container>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Bank Account:'}</div>
                                            <div style={styles.fieldContent}>
                                            {
                                                view ?
                                                    <TextField
                                                        disabled={true}
                                                        id={'txt_itemname'}
                                                        hintText='Bank'
                                                        value={this.props.state.data.itemname.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('itemname', value)
                                                        }}
                                                    /> :<AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'dll_accountingitem_id'}
                                                    key={'dll_accountingitem_id'}
                                                    hintText={'Select an acccount...'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    dataSource={this.props.state.data.accountingitem_id.data}
                                                    onNewRequest={
                                                        (chosenRequest, index) => {
                                                            this.props.actions.setSelectedLookup("accountingitem_id", chosenRequest.value, chosenRequest.text)
                                                        }
                                                    }
                                                    onClick={
                                                        (event) => {
                                                            this.props.actions.getContraAccountListData('')
                                                        }
                                                    }
                                                    onUpdateInput={
                                                        (search, dataSource, params) => {
                                                            this.props.actions.getContraAccountListData(search)
                                                        }
                                                    }
                                                />}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Settlement Discount Received Account:'}</div>
                                            <div style={styles.fieldContent}>
                                            {
                                                view ?
                                                    <TextField
                                                        disabled={true}
                                                        id={'txt_discountaccount_text'}
                                                        hintText='Account'
                                                        value={this.props.state.data.discountaccount_text.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('discountaccount_text', value)
                                                        }}
                                                    /> :<AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'dll_discountaccount_id'}
                                                    key={'dll_discountaccount_id'}
                                                    hintText={'Select an acccount...'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    dataSource={this.props.state.data.discountaccount_id.data}
                                                    onNewRequest={
                                                        (chosenRequest, index) => {
                                                            this.props.actions.setSelectedLookup("discountaccount_id", chosenRequest.value, chosenRequest.text)
                                                        }
                                                    }
                                                    onClick={
                                                        (event) => {
                                                            this.props.actions.getDiscountAccountListData('')
                                                        }
                                                    }
                                                    onUpdateInput={
                                                        (search, dataSource, params) => {
                                                            this.props.actions.getDiscountAccountListData(search)
                                                        }
                                                    }
                                                />}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3}>
                        </Col>
                        <Col xl={3}>
                        </Col>
                        <Col xl={3}>
                        </Col>
                        <Col xl={3} styles={styles.right}>
                           < Button className={"global_button"} variant="contained" 
                                header={false}
                                disabled={view}
                                label={'Reverse Payment'}
                                onClick={(event) => {
                                    var validationfields =
                                            [
                                                {
                                                    value: this.props.state.data.supplier_id.input,
                                                    field: "Creditor"
                                                },
                                                {
                                                    value: this.props.state.data.credit.input,
                                                    field: "Amount"
                                                },
                                                {
                                                    value: this.props.state.data.accountingitem_id.input,
                                                    field: "Account"
                                                },
                                                {
                                                    value: this.props.state.data.description.input,
                                                    field: "Description"
                                                }
                                            ]

                                    if (this.props.state.data.reference.text === true) {
                                        var payload = {
                                            data: this.props.state.data,
                                            documents: this.props.state.upload.documents,
                                            user: this.props.state.user
                                        }                                        
                                        this.props.actions.saveSupplierPaymentReversalData(payload, validationfields)
                                    }
                                    if (this.props.state.data.reference.text === false) {
                                        this.props.actions.foundDuplicateReference()
                                    }   
                                }}
                                >{window.t.en('Reverse Payment')}</Button>
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
        </Tab>
        <Tab label={'Allocation'}>
            <br></br>
            <Container style={{ margin: 0 }} className="custom-container">
                <SUPPLIERPAYMENTALLOCATION></SUPPLIERPAYMENTALLOCATION>
            </Container>
        </Tab>
    </Tabs>   
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.supplierpaymentreversal,
            upload: {
                ...state.upload
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERPAYMENTREVERSAL)