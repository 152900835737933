import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Button from '@mui/material/Button'

import { Card, CardHeader, CardContent, CardActions } from '@mui/material'
import { Container, Row, Col } from 'react-grid-system'

import Icon from '../Icon/component'
import Loading from '../Loading/component'
import View from '../View/component'
import Workflow_Stepper from '../../controls/workflow_stepper'

import GeneralContentView from '../../views/GeneralContentView/component'

import * as redirectActions from '../Redirect/actions'
import * as actions from './actions'
import * as styles from './styles'

import WorkflowInboxMessage from '../WorkflowInboxMessage/component'

class WorkflowTaskAction extends Component {
    componentDidMount() {
        this.workflow = this.props.match ? this.props.match.params.workflow : this.props.name
        this.task = this.props.match ? this.props.match.params.task : this.props.step

        this.props.actions.getWorkflowTaskActionData(
            this.props.state.workflowQueueTasks.queue.workflow_queue_id,
            this.props.state.workflowQueueTasks.queue.workflow_queue_task_id
        )
    }

    getActionSubtitle() {
        return this.props.state.loading
            ? 'Loading...'
            : this.props.state.state.workflow_task.workflow_task_team[0].notify_message
    }

    getActionView(component, x) {

        switch (component.name) {
            case 'inbox_message':
                return <WorkflowInboxMessage
                    key={'workflow_task_step_' + component.name}
                    name={component.name}
                    data={x}
                    step={this.props.match.params.task}
                />
            default:
                return x.data.length
                    ? x.data.map((y, i) => {
                        return <View
                            key={'workflowTaskAction_view_' + component.name + '_' + i}
                            name={component.name}
                            data={y}
                            surrogate={false}
                        />
                    })
                    : <View
                        key={'workflowTaskAction_view_' + component.name}
                        name={component.name}
                        data={x.data[0]}
                        surrogate={false}
                    />
        }
    }

    getComponentViews(components) {
        return components.length
            ? components.map(x => {
                const component = x.name
                    ? this.props.state.components[x.name]
                    : this.props.state.components[x.component]

                return <Card
                    key={'workflowTaskAction_card_' + component.name}
                    style={styles.taskCard}
                    initiallyExpanded={true}
                >
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={component.title}
                        // avatar={
                        //     <Icon istyle={styles.icon} iclass={component.icon.unicode} iname={component.icon.name} />
                        // }
                        style={styles.cardHeader}
                    />
                    <CardContent expandable={true}>
                        {this.getActionView(component, x)}
                    </CardContent>
                </Card>
            })
            : 'Loading...'
    }

    getComponents() {
        let components = ''
        let workflow_name = ''
        let workflow_task_name = ''
        try {
            components = this.workflow_queue_data.data.data.data
            workflow_name = this.props.state.workflows.customer_fml_quote.workflow_trigger[0].workflow.name
            workflow_task_name = this.props.state.workflows.customer_fml_quote.workflow_trigger[0].workflow_task.name
        } catch (error) {

        }

        return components
            ? <Col xl={12}>
                {this.getComponentViews(components)}

                <br />

                <CardActions style={styles.textAlign}>
                   < Button className={"global_button"} variant="contained"
                        label="Reject"
                        primary={false}
                        onClick={(event) => {
                            this.props.actions.rejectWorkflowQueueTask()
                        }}
                        >{window.t.en('Reject')}</Button>

                    {
                        components[0].component === 'inbox_message'
                            ?< Button className={"global_button"} variant="contained"
                                label="Workflow"
                                primary={true}
                                onClick={(event) => {
                                    window.glyco.log('Workflow btn clicked')
                                    window.glyco.log(workflow_name)
                                    window.glyco.log(workflow_task_name)
                                    //this.props.actions.startQuoteflowQueueTask(workflow_name)
                                    this.props.actions.route('/workflow/' + workflow_name + '/' + workflow_task_name + '/input')
                                }}
                                >{window.t.en('Workflow')}</Button>
                            :< Button className={"global_button"} variant="contained"
                                label="Approve"
                                primary={true}
                                onClick={(event) => {
                                    this.props.actions.approveworkflowQueueTask()
                                }}
                                >{window.t.en('Approve')}</Button>
                    }
                </CardActions>
            </Col>
            : <Col xl={12}>{''}</Col>
    }

    getView() {
        this.workflow_queue_data = this.workflow_queue[0]
        //console.log('Workflow_Stepperx  queue', this.workflow_queue )
        //console.log('Workflow_Stepperx  data', this.workflow_queue_data )
        return <>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Card style={styles.taskCard}>
                        <CardHeader
                            title={window.t.en('Decisive Action')}
                            subtitle={window.t.en('')}
                            // avatar={
                            //     <Icon istyle={styles.icon} iclass={'material-icons'} iname={'warning'} />
                            // }
                            style={styles.cardHeader}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    
                    <Workflow_Stepper 
                        name={this.workflow_queue_data.name} 
                        order={this.workflow_queue_data.order} 
                        workflow_id={this.workflow_queue_data.workflow_id}
                    />
                </Col>
            </Row>
            <Row>
                {this.getComponents()}
            </Row>
        </>
    }

    render() {
        this.workflow_queue = this.props.state.workflow_queue.data ? this.props.state.workflow_queue.data : undefined

        return !this.workflow_queue
            ? <Loading message={'Retrieving task information...'} />
            : !this.workflow_queue.length
                ? <GeneralContentView
                    content={'Unable to retrieve this task\'s data!'}
                    headerImage={''}
                    title={window.t.en('Oops!')}
                />
                : <GeneralContentView
                    content={this.getView()}
                    headerImage={''}
                    title={window.t.en('Action')}
                />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowTaskAction,
            workflowQueueTasks: {
                ...state.workflowQueueTasks
            },
            workflows: {
                ...state.workflows
            },
            components: {
                ...state.components
            },
            teams: {
                ...state.teams
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...redirectActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskAction)