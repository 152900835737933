import React, { useRef, Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import DateControl from '../../controls/dateControl'
import TextField from '../../controls/textField'
import Card from '../../controls/card'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import { Col, Container, Row } from 'react-grid-system'

import Upload from '../Upload/component'
import * as actions from './actions'
import * as styles from './styles'

class eNatis extends Component {
    componentDidMount() {
        this.props.actions.setInputValue('scan_data',null)
    }

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    getData() {
        console.log('getDatax', this.props.state.vehicle )
        console.log('getDatax scan_data', this.props.state.scan_data )
        let customercontract = this.props.state.vehicle 
            ?  this.props.state.vehicle.customercontract    
                ? this.props.state.vehicle.customercontract.filter(x => x.activated == true) 
                : [] 
            : []
        customercontract = customercontract.length ? customercontract[0] : null
        
        console.log('vehicleDetailsx vehicle', this.props.state.vehicle)
        console.log('vehicleDetailsx cont', customercontract)
        return <Table className="table table-hover text-nowrap mb-0">
        <thead> 
            <tr>
                <th style={{height: '35px', padding: '5px'}}>{'Field'}</th>
                <th style={{height: '35px', padding: '5px'}}>{'Value'}</th>
            </tr>
        </thead>
        <tbody style={{ backgroundColor: '#fff', color: '#000' }}>
            {this.props.state.scan_data.form_type && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Form Type</td><td><TextField fullWidth={true} variant="outlined" disabled={true} inputRef={this.inputRef} value={this.props.state.scan_data.form_type} /></td></tr>}
            {this.props.state.scan_data.vehicle_register && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle Register</td><td><TextField fullWidth={true} variant="outlined" inputRef={this.inputRef} value={this.props.state.scan_data.vehicle_register} onChange={
                        (event, value) => {
                            this.props.actions.setScanInputValue('vehicle_register', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.vehicle_registration && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle Registration</td><td><TextField fullWidth={true} variant="outlined" inputRef={this.inputRef} value={this.props.state.scan_data.vehicle_registration} onChange={
                        (event, value) => {
                            this.props.actions.setScanInputValue('vehicle_registration', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.vehicle_make && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle Make</td><td><TextField fullWidth={true} variant="outlined" disabled={true} inputRef={this.inputRef} value={this.props.state.scan_data.vehicle_make} /></td></tr>}
            {this.props.state.scan_data.vehicle_model && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle Model</td><td><TextField fullWidth={true} variant="outlined" disabled={true} inputRef={this.inputRef} value={this.props.state.scan_data.vehicle_model} /></td></tr>}
            {this.props.state.scan_data.vehicle_type && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle Type</td><td><TextField fullWidth={true} variant="outlined" disabled={true} inputRef={this.inputRef} value={this.props.state.scan_data.vehicle_type} /></td></tr>}
            {this.props.state.scan_data.vehicle_color && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle Color</td><td><TextField fullWidth={true} variant="outlined" disabled={true} inputRef={this.inputRef} value={this.props.state.scan_data.vehicle_color} /></td></tr>}
            {this.props.state.scan_data.vin_number && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle VIN</td><td><TextField fullWidth={true} variant="outlined" disabled={true} inputRef={this.inputRef} value={this.props.state.scan_data.vin_number} onChange={
                        (event, value) => {
                            this.props.actions.setScanInputValue('vin_number', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.engine_number && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle Engine</td><td><TextField fullWidth={true} variant="outlined" inputRef={this.inputRef} value={this.props.state.scan_data.engine_number} onChange={
                        (event, value) => {
                            this.props.actions.setScanInputValue('engine_number', event.target.value)
                        }
                    } /></td></tr>}
            {this.props.state.scan_data.license_expiry && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Expiry Date</td><td><TextField fullWidth={true} variant="outlined" inputRef={this.inputRef} value={this.props.state.scan_data.license_expiry}  onChange={
                        (event, value) => {
                            this.props.actions.setScanInputValue('license_expiry', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.registration_data && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Registration Date</td><td><TextField fullWidth={true} variant="outlined" inputRef={this.inputRef} value={this.props.state.scan_data.registration_data}  onChange={
                        (event, value) => {
                            this.props.actions.setScanInputValue('registration_data', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.vehicle_brn && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle Owner BRN</td><td><TextField fullWidth={true} variant="outlined" disabled={true} inputRef={this.inputRef} value={this.props.state.scan_data.vehicle_brn} /></td></tr>}
            {this.props.state.scan_data.vehicle_owner && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Vehicle Owner Name</td><td><TextField fullWidth={true} variant="outlined" disabled={true} inputRef={this.inputRef} value={this.props.state.scan_data.vehicle_owner} /></td></tr>}
            
            {this.props.state.scan_data.contractnumber && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Deal Number</td><td>
                <TextField fullWidth={true} disabled={true} variant="outlined"  inputRef={this.inputRef} value={this.props.state.scan_data.contractnumber}
                    onChange={
                        (event, value) => {
                            this.props.actions.setScanInputValue('contractnumber', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.contractnumber && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Customer Name</td><td>
                <TextField fullWidth={true} disabled={true} variant="outlined"  inputRef={this.inputRef} value={this.props.state.scan_data.customername}
                    onChange={
                        (event, value) => {
                            //this.props.actions.setScanInputValue('contractnumber', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.contractnumber && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Customer Account Number</td><td>
                <TextField fullWidth={true} disabled={true} variant="outlined"  inputRef={this.inputRef} value={this.props.state.scan_data.accountnumber}
                    onChange={
                        (event, value) => {
                            //this.props.actions.setScanInputValue('contractnumber', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.contractnumber && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Deal Status</td><td>
                <TextField fullWidth={true} disabled={true} variant="outlined" inputRef={this.inputRef} value={this.props.state.scan_data.activated ? 'Active' : 'Terminated'}
                    onChange={
                        (event, value) => {
                            //this.props.actions.setScanText('scan_text', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.contractnumber && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Deal Start Date</td><td>
                <TextField fullWidth={true} disabled={true} variant="outlined"  inputRef={this.inputRef} value={this.props.state.scan_data.startdate}
                    onChange={
                        (event, value) => {
                            //this.props.actions.setScanText('scan_text', event.target.value)
                        }
                    }/></td></tr>}
            {this.props.state.scan_data.contractnumber && <tr style={{ backgroundColor: '#fff', color: '#000' }}><td style={{width: '350px'}}>Deal Start End</td><td>
                <TextField fullWidth={true} disabled={true} variant="outlined"  inputRef={this.inputRef} value={this.props.state.scan_data.terminationdate}
                    onChange={
                        (event, value) => {
                            //this.props.actions.setScanText('scan_text', event.target.value)
                        }
                    }/></td>
            </tr>}
            {!this.props.state.scan_data.contractnumber && <tr style={{ backgroundColor: '#fff', color: '#000' }}><th colSpan={2}>
                <strong style={{color: 'red'}}>Vehicle Deal Not Found</strong>
                </th>
            </tr>}
            </tbody>
    </Table>
    }

    getView() {
        return <div>
            <Card
                title={('Scanner')}
                subtitle={('Scan enatis document')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <table>
                                    <tr>
                                        <td style={{padding: '10px'}}>{'Enable Auto Save On Scan'}</td>
                                        <td>
                                <Checkbox
                                    key={'auto_save'}
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.auto_save}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue('auto_save', isInputChecked)
                                        }
                                    }
                                /></td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <code>Set focus before scanning the document.</code><br/>
                                {
                                    this.props.state.auto_save 
                                    ? <p style={{ width: '100px', color: '#3c763d', borderRadius: "5px", border: '1px solid #d6e9c6', width: '100%', backgroundColor: '#dff0d8', margin: "8px 0", padding: '5px', fontSize: '18px' }}>{'Auto Save NO'}</p>
                                    : ''
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {/* <input type="text" onChange={
                                        (event, value) => {
                                            this.props.actions.setScanText('scan_text', event.target.value)
                                            //this.inputRef.current.focus();
                                        }
                                    } /> */}
                                <TextField
                                    id={'scan_data'}
                                    key={'scan_data'}// fktext removed
                                    fullWidth={true}
                                    //disabled={true}
                                    variant="outlined"
                                    /* color="secondary" */
                                    inputRef={this.inputRef}
                                    //value={this.props.state.scan_text}
                                    onChange={
                                        (event, value) => {
                                            this.props.actions.setScanText('scan_text', event.target.value)
                                            //this.inputRef.current.focus();
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                        
                    </Container>}
            />
            {
                this.props.state.scan_data
                ? <>
                    <Card
                        title={('Vehicle Details')}
                        subtitle={('Scanned vehicle document details')}
                        content={
                            <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                    <Col xl={12}>
                                        {this.props.state.vehicle ? this.getData() : ''}
                                    </Col>
                                </Row>
                                
                            </Container>
                            }
                    />
                </>
                : ''
            }
            {
                this.props.state.scan_data
                ? <>
                    <Card
                        title={('Upload eNatis')}
                        subtitle={('Upload scanned document')}
                        content={
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12}>
                                        <Upload
                                            id={'upload_enatis'}
                                            prefix={'enatis'}
                                            header={false}
                                        />
                                    </Col>
                                </Row>
                                
                            </Container>}
                    />
            
                </>
                : ''
            }
            {
                this.props.state.scan_data
                ? <>
                    <Card
                        title={('Save Data')}
                        subtitle={('Save vehicle details')}
                        content={
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12} >
                                    &nbsp;&nbsp;<Button className={"global_button"} variant="contained"
                                        label="Mon"
                                        style={{float: 'right'}}
                                        labelPosition="before"
                                        primary={!this.props.state.mon}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            console.log('eNatisTypesx onClick', this.props.state.scan_data)
                                            this.props.actions.saveEnatisData(this.props.state.scan_data)
                                        }}
                                        >Save Vehicle Details </Button>
                                        &nbsp;&nbsp;
                                        <Button className={"global_button"} variant="contained"
                                        label="Mon"
                                        style={{float: 'right'}}
                                        labelPosition="before"
                                        primary={!this.props.state.mon}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            //console.log('eNatisTypesx onClick')
                                            this.props.actions.setScanText('scan_text', '')
                                        }}
                                        >Clear Scan Data </Button>&nbsp;&nbsp;
                                    </Col>
                                </Row>
                                
                            </Container>}
                    />
                </>
                : ''
            }
        </div>
    }
    render() {
        //console.log('eNatisx',this.props.state)
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.enatis }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(eNatis)