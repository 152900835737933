import * as redirectTypes from '../Redirect/types'
import * as requestTypes from '../Request/types'
import * as props from './props'

export default (state = props, action) => {
    switch (action.type) {
        case redirectTypes.ROUTE:
            return {
                ...state,
                original: action.payload.error,
                error: action.payload.error ? action.payload.error.message : false,
                info: action.payload.error ? action.payload.error.info : false
            }
        case requestTypes.REQUEST_FAILED:
            return {
                ...state,
                original: {
                    error: action.payload.error ? action.payload.error : undefined,
                    info: false
                },
                error: action.payload.toString(),
                info: 'Request Failed'
            }
        default:
            return state
    }
}