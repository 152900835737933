import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const opacity = {
    //opacity: 0.85
}

export const visibility = (visible) => {
    return {
        display: visible ? 'block' : 'none'
    }
}

export const search = {
    display: 'table',
    width: '100%',
}

export const triggerIcon = {
    display: 'table-cell',
    width: '100px',
    textAlign: 'left'
}

export const timelineIcon = {
    display: 'table-cell',
    width: '100px',
    textAlign: 'left'
}

export const timelineIconLast = {
    display: 'table-cell',
    //width: '100px',
    textAlign: 'left'
}

export const searchDateInput = {
    display: 'table-cell',
    width: '265px',
    textAlign: 'right'
}

export const parentTeamDiv = {
    width: '100%',
    fontWeight : '600'
}

export const hideTeamDiv = {
    display: 'table-cell',
    width: '100%',
    float: 'right'
}

export const searchInput = {
    display: 'table-cell',
    width: '265px',
    textAlign: 'right'
}
export const searchIcon = {
    display: 'table-cell',
    width: '100px',
    textAlign: 'right'
}

export const counters = {
    textAlign: 'right',
    width: '8%',
    display: 'table'
}

export const verticalAlignment = {
    verticalAlignment: 'middle',
    marginBottom: '5px',
}

export const verticalAlignmentNon = {
    verticalAlignment: 'middle',
    marginBottom: '5px',
}

export const pager = {
    textAlign: 'right',
    background: 'none'
}

export const stepperContainer = {
    ////backgroundColor: '#dcd6d7',
    //opacity: 0.85
}

export const stepper = {
    width: '100%'
}

export const taskCard = {
    //backgroundColor: colours.amber100
    //backgroundColor: '#dcd6d7',

    //opacity: 0.85
}

export const cardActions = {
    textAlign: 'right'
}

export const confirmIcon = {
    fontSize: '34px',
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
    ////backgroundColor: '#dcd6d7',
    //opacity: 0.85
}

export const icon = {
    fontSize: '34px'
}

export const componentStatusIconValid = {
    fontSize: '34px',
    color: colours.green[500]
}

export const componentStatusIconInvalid = {
    fontSize: '34px',
    color: colours.red[500]
}

export const field = {
    fontWeight: 'bold'
}

export const cardHeader = {
    textAlign: 'left',
    color: colours.green[500]
}

export const cardHeaderProgress = {
    textAlign: 'right'
}

export const inputWidth = {
    width: '100px',
}
export const badge = {
    high: {
        top: 12,
        right: 12,
        backgroundColor: colours.red[500],
        fontSize: '25px'
    },
    medium: {
        top: 12,
        right: 12,
        backgroundColor: colours.amber[500],
        fontSize: '15px'
    },
    low: {
        top: 12,
        right: 12,
        backgroundColor: colours.green[500],
        fontSize: '10px'
    }
}

export const buttonBar = {
    background: 'none'
}

export const chip = {
    margin: 4
}