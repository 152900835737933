export const loading = false
export const invoice_list = []
export const debtor_list = []
export const vehicle_list = []
export const licenseplateno = ''
export const vehicle_id = {}
export const invoice_items = []
export const customer_id = null
export const customertransaction_date = null
export const customertransaction_month = null
export const customer_reference = null
export const invoice_reference = null
export const invoice_description = null
export const unit_list = []
export const glaccount_list = []

export const sub_total = 0
export const vat_total = 0
export const none_total = 0
export const grand_total = 0

export const selected_invoice = {
    value: null,
    text: null
}
export const selected_customer = {
    value: null,
    text: null
}
export const transaction_type = {
    value: null,
    text: null
}
export const transaction_types = []
export const total_vat = null
export const total_amount = null
export const nettvalue = null
export const viewinvoice = false
