import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.TOGGLE_USER_FORM:
            return {
                ...state,
                user: true,
                edit: !state.edit,
                manage: false
            }
        case types.TOGGLE_MANAGEMENT_FORM:
            return {
                ...state,
                manage: true,
                user: false,
                edit: true
            }
        case types.GET_ALL_USERS:
            return {
                ...state,
                allUsersLoading: true
            }
        case types.SET_ALL_USERS:
            return {
                ...state,
                changed: false,
                allUsersLoading: false,
                allUsers: action.payload
            }
        case types.CHANGE_ON_USER_COMPONENT_FORM:
            return {
                ...state,
                changed: true
            }
        case types.TOGGLE_USER_ACTIVATION:
            return {
                ...state,
                changed: true,
                allUsers: state.allUsers.map(x => {
                    if (x.user_id === action.payload) {
                        return {
                            ...x,
                            is_active: !x.is_active
                        }
                    }
                    else {
                        return { ...x }
                    }
                })
            }
            case types.TOGGLE_USER_ADMIN:
            return {
                ...state,
                changed: true,
                allUsers: state.allUsers.map(x => {
                    if (x.user_id === action.payload) {
                        return {
                            ...x,
                            isadmin: !x.isadmin
                        }
                    }
                    else {
                        return { ...x }
                    }
                })
            }
        case types.SET_USER_TEAM :
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.SET_ALL_USER_TEAM :
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        default:
            return state
    }
}