import * as jose from 'jose';

class JwtHelper {
  static textEncoder = new TextEncoder();

  /**
   * Encode text into a JWT token
   * @param {string} text - Text to encode
   * @param {string} key - Secret key for encoding
   * @param {object} options - Optional configuration
   * @param {string} options.expiresIn - Token expiration time (e.g., '2h', '1d')
   * @returns {Promise<string>} Encoded JWT token
   */
  static async encodeText(text, key, options = { expiresIn: '2h' }) {
    try {
      if (!text || !key) {
        throw new Error('Text and key are required');
      }

      const jwt = await new jose.SignJWT({ data: text })
        .setProtectedHeader({ alg: 'HS256' })
        .setIssuedAt()
        .setExpirationTime(options.expiresIn)
        .sign(this.textEncoder.encode(key));

      return jwt;
    } catch (error) {
      throw new Error(`Failed to encode: ${error.message}`);
    }
  }

  /**
   * Decode a JWT token
   * @param {string} token - JWT token to decode
   * @param {string} key - Secret key for decoding
   * @returns {Promise<string>} Decoded text
   */
  static async decodeToken(token, key) {
    try {
      if (!token || !key) {
        throw new Error('Token and key are required');
      }

      const { payload } = await jose.jwtVerify(
        token,
        this.textEncoder.encode(key)
      );

      return payload.data;
    } catch (error) {
      throw new Error(`Failed to decode: ${error.message}`);
    }
  }

  /**
   * Verify if a token is valid and not expired
   * @param {string} token - JWT token to verify
   * @param {string} key - Secret key for verification
   * @returns {Promise<boolean>} True if token is valid
   */
  static async isTokenValid(token, key) {
    try {
      await jose.jwtVerify(token, this.textEncoder.encode(key));
      return true;
    } catch {
      return false;
    }
  }
}

export default JwtHelper;