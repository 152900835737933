import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Button from '@mui/material/Button'


import Icon from '../../Icon/component'
import Loading from '../../Loading/component'

import * as actions from './actions'

class WorkflowQueueTaskDocuments extends Component {
    componentDidMount() {
        this.props.actions.setWorkflowQueueTaskState(this.props.workflow_queue_task_id)
    }

    render() {
        return !this.props.state['workflow_queue_task_id_' + this.props.workflow_queue_task_id]
            ? <Loading message={'Retrieving documents...'} />
            : this.props.state['workflow_queue_task_id_' + this.props.workflow_queue_task_id] && this.props.state['workflow_queue_task_id_' + this.props.workflow_queue_task_id].loading
                ? <Loading message={'Retrieving documents...'} />
                : <div>
                   < Button className={"global_button"} variant="contained"
                        primary={true}
                        label='View Related Documents'
                        onClick={() => {
                            this.props.actions.getWorkflowQueueTaskDocuments(this.props.workflow_queue_task_id)
                        }}
                    >View Related Documents</Button>
                    {
                        [
                            <br />,
                            <br />,
                            this.props.state['workflow_queue_task_id_' + this.props.workflow_queue_task_id].data.map(x => {
                                return <div style={{margin: '2px'}}><Button className={"global_button"}
                                    key={x.filename}
                                    secondary={true}
                                    label={x.title}
                                    icon={<Icon iclass={'material-icons'} iname={'visibility'} />}
                                    onClick={() => {
                                        this.props.actions.downloadDocument(x)
                                    }}
                                    >{x.title}</Button></div>
                            })
                        ]
                    }
                </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.workflowQueueTaskDocuments }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowQueueTaskDocuments)