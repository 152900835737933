export const SOLVE_FOR_MONTHLY_PAYMENT = 'SOLVE_FOR_MONTHLY_PAYMENT'
export const SOLVE_FOR_PRESENT_VALUE = 'SOLVE_FOR_PRESENT_VALUE'
export const SOLVE_FOR_FUTURE_VALUE = 'SOLVE_FOR_FUTURE_VALUE'
export const SOLVE_FOR_INTEREST_RATE_PER_ANNUM = 'SOLVE_FOR_INTEREST_RATE_PER_ANNUM'
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE'
export const RESET_FIELD_VALUE = 'RESET_FIELD_VALUE'
export const SET_ERROR = 'SET_ERROR'
export const SET_VALID = 'SET_VALID'
export const CHANGE_SELECT = 'CHANGE_SELECT'
export const CALCULATE = 'CALCULATE'
export const RESET_FINANCIAL_CALCULATOR = 'RESET_FINANCIAL_CALCULATOR'
export const CREATE_CALCULATOR_STATE = 'CREATE_CALCULATOR_STATE'
export const REMOVE_CALCULATOR_STATE = 'REMOVE_CALCULATOR_STATE'
export const GET_VEHICLES = 'GET_VEHICLES'
export const SET_FORM_MMCODE_DETAILS = 'SET_FORM_MMCODE_DETAILS'
export const GET_COMPONENT_MMCODE_LOOKUP_DATA = 'GET_COMPONENT_MMCODE_LOOKUP_DATA'
export const SET_COMPONENT_MMCODE_LOOKUP_DATA = 'SET_COMPONENT_MMCODE_LOOKUP_DATA'
export const SET_COMPONENT_FIELD_INPUT = 'SET_COMPONENT_FIELD_INPUT'
export const REMOVE_MMCODE_VEHICLE_DETAILS = 'REMOVE_MMCODE_VEHICLE_DETAILS'
export const GET_NON_RATECARD_DETAILS = 'GET_NON_RATECARD_DETAILS'
export const SET_NON_RATECARD_DETAILS = 'SET_NON_RATECARD_DETAILS'
export const SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_VALUE = 'SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_VALUE'
export const SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_TEXT = 'SET_QUOTEHEADER_COMPONENT_FIELD_INPUT_TEXT'
export const SET_QUOTEHEADER_GRID_DATA = 'SET_QUOTEHEADER_GRID_DATA'
export const SET_QUOTEHEADER_GRID_FIELD_DATA = 'SET_QUOTEHEADER_GRID_FIELD_DATA'
export const SET_COMPONENT_TOGGLE_VALUES = 'SET_COMPONENT_TOGGLE_VALUES'
export const GET_COMPONENT_SEARCH_MMCODE_DETAILS = 'GET_COMPONENT_SEARCH_MMCODE_DETAILS'
export const SET_COMPONENT_SEARCH_MMCODE_DETAILS = 'SET_COMPONENT_SEARCH_MMCODE_DETAILS'
export const SET_COMPONENT_YARD_LIST_VEHICLE_LIST = 'SET_COMPONENT_YARD_LIST_VEHICLE_LIST'
export const GET_COMPONENT_YARD_LIST_VEHICLE_LIST = 'GET_COMPONENT_YARD_LIST_VEHICLE_LIST'
export const SET_COMPONENT_SELECTED_YARD_LIST_VEHICLE = 'SET_COMPONENT_SELECTED_YARD_LIST_VEHICLE'

export const QUOTE_VEHICLE_BLANK_ROW = 'QUOTE_VEHICLE_BLANK_ROW'
export const GET_COMPONENT_CURRENT_PRIMERATE = 'GET_COMPONENT_CURRENT_PRIMERATE'
export const GET_QUOTE_HEADER_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES = 'GET_QUOTE_HEADER_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES'
export const SET_COMPONENT_YARD_LIST_VEHICLE_LIST_BAK = 'SET_COMPONENT_YARD_LIST_VEHICLE_LIST_BAK'

export const GET_QUOTE_COMPONENT_FML_MMCODE_DATA_LIST = 'GET_QUOTE_COMPONENT_FML_MMCODE_DATA_LIST'
export const GET_FML_QUOTE_MMCODE_RATE_CARD_DATA = 'GET_FML_QUOTE_MMCODE_RATE_CARD_DATA'