import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import BusinessIntelligence from '../../../components/BusinessIntelligence/component'

import * as actions from './actions'

class GeneralDashboard extends Component {
    render() {
        return (
            <BusinessIntelligence
                component={'general_dashboard'}
                container={'general_dashboard'}
                type={'reports'}
                title={'Reports'}
                uuid={'d8356782-439c-4d2f-8361-7eec061c4ead'}
                primary={true}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.generalDashboard }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDashboard)