import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.REQUEST_FAILED:
            return {
                ...state,
                uri: action.payload.uri,
                result: action.payload.result
            }
        case types.REQUEST_SUCCEEDED:
            return {
                ...state,
                uri: action.payload.uri,
                //result: action.payload.result
            }
        default:
            return state
    }
}