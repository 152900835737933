import React, { Component } from 'react'

import * as styles from './styles'

class GeneralGridView extends Component {
    render() {
        return (
            <div style={styles.view(this.props.pad)}>
                <div style={styles.spacer}></div>
                <div style={styles.headerContainer}>
                    <div className={'global_header_title'} style={styles.headerTitle}>{this.props.title}</div>
                </div>
                <div style={styles.content(this.props.contentHeight)}>
                    {this.props.content}
                </div>
            </div>
        )
    }
}

export default GeneralGridView