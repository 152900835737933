


import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import * as _ from 'lodash'
import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'

import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import TextField from '@mui/material/TextField'

import ImageUploader from '../../ImageUploader/component'
import Upload from '../../Upload/component'
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'
let executed = 0
class DefleetUploadChecksheetConfirm extends Component {
    componentDidMount() {        
        let wf_data = this.props.workflow_queue_data.data[0].context_data.data.data.data[0]
        const data = {
            ...wf_data,
            checksheet_completedby: this.props.state.step1.checksheet_completedby.value,
            checksheet_completedat: this.props.state.step1.checksheet_completedat.value,
            user: this.props.state.user,
            images: this.props.state.imageUploader? this.props.state.imageUploader.images : [],
            documents: this.props.state.upload.documents ? [{ 
                filename: this.props.state.upload.documents[0].filename, 
                preview: this.props.state.upload.documents[0].preview,
                filetype: 'Check Sheet' }] :[],
        }
        const meta = [
            {
                header: true,
                title: 'Vehicle',
                description: 'Vehicle',
                meta_data: wf_data.step1.data.registration
            },
            {
                title: 'De-fleet Number',
                description: 'De-fleet Number',
                meta_data: wf_data.step1.data.defleetNumber
            },
        ]
        this.props.actions.setupWorkflowTaskInput(data, meta, 'defleet', 'tsk_defleet_upload_checksheet')

    }

    VehicleContract(data) {
        return data
            ? <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('Vehicle Contract Information: ')}
                        subtitle={window.t.en('Selected vehicle contract details.')}
                        icon={<Icon iclass={'custom-icons'} iname={'contract_vehicle_category'} />}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Contract Number:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.contractnumber}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Contract Status:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.contractstatus}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Contract Type:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.contracttype}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Contract Category:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{''}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Distance per Month:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.distancepermonth).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Contract Distance:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.distance).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Contract Start Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.startdate ? moment(data.startdate).format('DD/MM/YYYY') : 'Not Set'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Contract Start Odometer:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.openingodometer ? data.openingodometer : 0).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Projected Replacement Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.terminationdate ? moment(data.terminationdate).format('DD/MM/YYYY') : 'Not Set'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Projected Replacement Odometer:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.terminationodometer ? data.terminationodometer : 0).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }
    VehicleDetails(data) {
        return <Card>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('Vehicle Information: ' + data.businessname)}
                subtitle={window.t.en('Selected vehicle details.')}
                icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                style={styles.row}
            >
            </CardHeader>
            <CardContent>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.licenseplateno}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Fleet Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fleetno}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Status:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vehiclestatus}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Odometer:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.closingodometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.make}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mgdescription}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mmcode}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle First Registration Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registrationdate ? moment(data.registrationdate).format('DD-MM-YYYY') : data.registrationdate}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Chassis Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.chassisnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Engine Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.enginenumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Fuel Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fueltype}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Colour:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.color}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Funder:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fundername ? data.fundername: 'Not Set'}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Supplier (OEM):'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliername? data.suppliername: 'Not Set'}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }
    view(workflow_queue_data) {
        let step1 = workflow_queue_data.step1.data
        let vehicle = step1.selectedVehicleDetails.data[0]
        let documents = this.props.state.imageUploader ? this.props.state.imageUploader.images : this.props.state.upload.documents        
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Infleet Internal De-fleet Number Only: ' + (step1.defleetNumber ? step1.defleetNumber : '-'))}
                    subtitle={window.t.en('Uploaded De-fleet Check Sheet')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {this.VehicleDetails(vehicle)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.VehicleContract(vehicle)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardHeader
                                        actAsExpander={false}
                                        showExpandableButton={false}
                                        title={window.t.en('Upload De-fleet Check Sheet')}
                                        subtitle={window.t.en('Selected vehicle details')}
                                        icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                                        style={styles.row}
                                    >
                                    </CardHeader>
                                    <CardContent>
                                        <Container style={{ margin: 0 }} className="custom-container">
                                            <Row>
                                                <Col xl={3} style={styles.fieldLabel}>{'Check Sheet Completed By:'}</Col>
                                                <Col xl={3} style={styles.selectedFieldName}>{this.props.state.step1.checksheet_completedby.value}</Col>
                                            </Row>
                                            <Row>
                                                <Col xl={3} style={styles.fieldLabel}>{'Date Completed:'}</Col>
                                                <Col xl={3} style={styles.selectedFieldName}>{moment(this.props.state.step1.checksheet_completedat.value).format('DD-MM-YYYY')}</Col>
                                            </Row>
                                        </Container>
                                    </CardContent>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <Card style={styles.card} style={styles.row}>
                                    <CardHeader
                                        actAsExpander={false}
                                        showExpandableButton={false}
                                        title={window.t.en('Uploaded Documents')}
                                        subtitle={window.t.en('Uploaded supplier documents.')}
                                        icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                                        style={styles.row}
                                    >
                                    </CardHeader>
                                    <CardContent>
                                        <Container style={{ margin: 0 }} className="custom-container">
                                            <Row>
                                                <Col xl={12}>
                                                    {
                                                        documents
                                                            ? <PdfViewer
                                                                key={'document_viewer_uploaded_checksheet'}
                                                                name={'De-fleet'}
                                                                workflow={'defleet'}
                                                                task={'tsk_defleet_upload_checksheet'}
                                                                documents={documents}
                                                                edit={false}
                                                                workflow_queue_data={workflow_queue_data}
                                                            />
                                                            : <Loading message={'Retrieving Document...'} />
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    </CardContent>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <ImageUploader prefix={'img'} id={'img'} />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }


    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.data[0].context_data.data.data.data[0]
        } catch (error) {

        }
        return workflow_queue_data ? this.view(workflow_queue_data) : <strong style={styles.box}>{'Kindly check if your not logged in twice, if the error persist please contact system administrator.'}</strong>;
    }
}
const mapStateToProps = (state) => {
    return {
        state: {
            ...state.defleetUploadChecksheetConfirm,
            step1: {
                ...state.defleetUploadChecksheet,
            },
            upload: {
                ...state.upload
            },
            user: {
                ...state.user,
            }
        }
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetUploadChecksheetConfirm)
