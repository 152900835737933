import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Icon from '../../Icon/component'
import DetailView from './view'
import CommentsView from './comments'
import IconButton from '@mui/material/IconButton';
import ReactTable from "react-table"
import "react-table/react-table.css"
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import AutoComplete from '../../../controls/autocomplete'
import Checkbox from '../../../controls/checkbox'
import Card from '../../../controls/card'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import DateControl from '../../../controls/dateControl'
import ViewSupplier from '../../../controls/view_supplier'
import Upload from '../../Upload/component'
import * as actions from './actions'
import * as styles from './styles'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'
import Table from '@mui/material/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Tooltip, Zoom } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import Recon from '../../../components/JSReport/component'
import Requisition from '../../../components/JSReport/component'
import Remittance from '../../../components/JSReport/component'

class SUPPLIERPAYMENTMANAGEMENT extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.setValidationState('supplierpaymentmanagement')
        let data = {
            supplier_id: 0,
            search_text: ''
        }
        this.props.actions.updateValue('search_text', '')
        //this.props.actions.getPaymentList(data);
        //this.props.actions.getPaymentListAdmin(data);
        //this.props.actions.getPaymentListMaint(data);
        //this.props.actions.getPaymentListTyres(data); 
        this.actions = this.props.actions;
    }
    addBlankRow(type) {
        var maxid = 0;
        switch (type) {
            case 'payments':
                {
                    this.props.state.payments.map(function (obj) {
                        if (obj.id > maxid) maxid = obj.id;
                    });
                    this.props.actions.addBlankRow(type, (maxid ? Number(maxid) : 0) + 1)
                }
                break;
            case 'credits':
                {
                    this.props.state[type].map(function (obj) {
                        if (obj.id > maxid) maxid = obj.id;
                    });
                    this.props.actions.addBlankRow(type, (maxid ? Number(maxid) : 0) + 1)
                }
                break;
            case 'others':
                {
                    this.props.state[type].map(function (obj) {
                        if (obj.id > maxid) maxid = obj.id;
                    });
                    this.props.actions.addBlankRow(type, (maxid ? Number(maxid) : 0) + 1)
                }
                break;

        }
    }
    getTotals() {

        let payments = _.sumBy(this.props.state.payments, (d) => { return parseFloat(d.amount) + parseFloat(d.discount) })
        //console.log(">>>>>>>>>>>>> payments <<<<<<<<<<<<")
        //console.log(payments)
        let credits = _.sumBy(this.props.state.credits, (d) => { return parseFloat(d.amount) })
        //console.log(">>>>>>>>>>>>> credits <<<<<<<<<<<<")
        //console.log(credits)

        let others = _.sumBy(this.props.state.others, (d) => { return parseFloat(d.amount) })
        //console.log(">>>>>>>>>>>>> others <<<<<<<<<<<<")
        //console.log(others)

        //console.log("(parseFloat(payments)+parseFloat(credits)+parseFloat(others)).toFixed(2)")

        //console.log((parseFloat(payments)).toFixed(2))
        //console.log(parseFloat(credits).toFixed(2))
        //console.log(parseFloat(others).toFixed(2))

        //console.log((parseFloat(payments) + parseFloat(credits) + parseFloat(others)).toFixed(2))
        return (parseFloat(payments) + parseFloat(credits) + parseFloat(others)).toFixed(2)

    }
    getPayable(s, c, d) {
        //s: Statement Amount, c: Payments/credit not credittedd, d: discount %

        //console.log("s", s)
        //console.log("c", c)
        //console.log("d", d)


        let st = s ? parseFloat(s) : 0.00
        let di = d ? parseFloat(d) : 0.00
        let tot = (st)
        let disc = (tot * (di / 100))

        //console.log("tot")
        //console.log(tot)

        //console.log("disc")
        //console.log(disc)

        //console.log("parseFloat(tot-disc).toFixed(2)")
        //console.log(parseFloat(tot - disc).toFixed(2))
        return parseFloat(tot - disc).toFixed(2)

    }
    getDocuments(documents) {
        return <Card
            title={window.t.en('Supporting Documents')}
            subtitle={window.t.en('Invoice supporting documents')}
            content={<Container style={styles.whitediv}>
                <Row>
                    <Col xl={12}>
                        <PdfViewer
                            key={'quotation_viewer_'}
                            documents={documents}
                            height={600}
                            multiple={false}
                            edit={false}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    getCredits() {
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row><Col xl={12}><br /></Col></Row>
            <Row><Col xl={12}><b>{'CREDITS NOT YET PASSED'}</b></Col></Row>
            <Row><Col xl={12}><br /></Col></Row>
            <Row style={styles.tableheader}>
                <Col xl={1}>{''}</Col>
                <Col xl={1}>{'Date'}</Col>
                <Col xl={1}>{'Invoice Number'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                <Col xl={2}>{'Reason'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Total'}</Col>
            </Row>   {
                this.props.state.credits
                    ? this.props.state.credits
                        .map((x) => {
                            return <div>
                                <Row style={styles.tablerow}>
                                    <Col xl={1} style={styles.verticalMiddle}>
                                        <IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                            onClick={
                                                () => {
                                                    this.props.actions.removeRow('credits', x.id)
                                                }
                                            }>
                                            <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                    </Col>
                                    <Col xl={1}>
                                        <DateControl
                                            id={'txt_transactiondate'}
                                            key={'txt_transactiondate'}
                                            name={'txt_transactiondate'}
                                            label={window.t.en('Select Date...')}
                                            field={'txt_transactiondate'}
                                            fullWidth={true}
                                            value={x.date}
                                            onSelect={(args) => {
                                                this.props.actions.setUpdateItem('credits', x.id, 'date', args)
                                            }}
                                            type={'date'}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtdescription'}
                                            hintText={'Enter Invoice #'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={x.invoicenumber}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('credits', x.id, 'invoicenumber', event.target.value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.amount)}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('credits', x.id, 'amount', event.target.value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={2}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Reason'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.reason)}
                                            onChange={(event, value) => {

                                                this.props.actions.setUpdateItem('credits', x.id, 'reason', event.target.value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={(parseFloat(x.total)).toFixed(2)}
                                            onChange={() => {
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        })
                    : <div>{'No Items Found...'}</div>
            }
            <Row>
                <Col>
                    <br />
                    < Button className={"global_button"} variant="contained"
                        key={'add_more_button'}
                        label={'+ Add'}
                        primary={true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.addBlankRow('credits')
                        }}
                    >{window.t.en('+ Add')}</Button>
                    <br />
                    <br />
                    <br />
                </Col>
            </Row>
        </Container>
    }
    getPayments() {
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row><Col xl={12}><br /></Col></Row>
            <Row><Col xl={12}><b>{'PAYMENTS AND DISCOUNTS NOT YET CREDITED'}</b></Col></Row>
            <Row><Col xl={12}><br /></Col></Row>
            <Row style={styles.tableheader}>
                <Col xl={1}>{''}</Col>
                <Col xl={1}>{'Date'}</Col>
                <Col style={styles.numericFields} xl={1}>{'EFT'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                {/* <Col style={styles.numericFields} xl={1}>{'Discount'}</Col> */}
                <Col style={styles.numericFields} xl={1}>{'Total'}</Col>
            </Row>
            {
                this.props.state.payments
                    ? this.props.state.payments
                        .map((x) => {
                            return <div>
                                <Row style={styles.tablerow}>
                                    <Col xl={1} style={styles.verticalMiddle}>
                                        <IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                            onClick={
                                                () => {
                                                    this.props.actions.removeRow('payments', x.id)
                                                }
                                            }>
                                            <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                    </Col>
                                    <Col xl={1}>
                                        <DateControl
                                            id={'txt_transactiondate'}
                                            key={'txt_transactiondate'}
                                            name={'txt_transactiondate'}
                                            label={window.t.en('Select Date...')}
                                            field={'txt_transactiondate'}
                                            fullWidth={true}
                                            value={x.date}
                                            onSelect={(args) => {
                                                this.props.actions.setUpdateItem('payments', x.id, 'date', moment(args).format('YYYY-MM-DD'))
                                            }}
                                            type={'date'}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={x.eft}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('payments', x.id, 'eft', event.target.value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.amount)}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('payments', x.id, 'amount', event.target.value)
                                            }}
                                        />
                                    </Col>
                                    {/*  <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Discount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.discount)}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('payments', x.id, 'discount', value)
                                            }}
                                        />
                                    </Col> */}
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={(parseFloat(x.total)).toFixed(2)}
                                            onChange={() => {
                                                //this.props.actions.setValue('market', value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        })
                    : <div>{'No Items Found...'}</div>
            }
            <Row>
                <Col>
                    <br />
                    < Button className={"global_button"} variant="contained"
                        key={'add_more_button'}
                        label={'+ Add'}
                        primary={true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.addBlankRow('payments')
                        }}
                    >{window.t.en('+ Add')}</Button>
                    <br />
                    <br />
                    <br />
                </Col>
            </Row>
        </Container>
    }
    getOthers(data) {
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row><Col xl={12}><br /></Col></Row>
            <Row><Col xl={12}><b>{'OTHER'}</b></Col></Row>
            <Row><Col xl={12}><br /></Col></Row>
            <Row style={styles.tableheader}>
                <Col xl={2}>{'Date'}</Col>
                <Col xl={4}>{'Invoice Number'}</Col>
                <Col style={styles.numericFields} xl={2}>{'Amount'}</Col>
                <Col xl={2}>{'Reason'}</Col>
                <Col style={styles.numericFields} xl={2}>{'Total'}</Col>
            </Row>
            {
                data.length
                    ? data
                        .map((x) => {
                            return <div>
                                <Row style={styles.tablerow}>
                                    <Col xl={2}>
                                        <TextField
                                            id={'txtdescription'}
                                            hintText={'Date'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={moment(x.suppliertransactiondate).format("YYYY-MM-DD")}
                                            onChange={() => {
                                                //this.props.actions.setUpdateItem('others', x.id, 'invoicenumber', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={4}>
                                        <TextField
                                            id={'txtdescription'}
                                            hintText={'Invoice #'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={x.reference}
                                            onChange={() => {
                                                //this.props.actions.setUpdateItem('others', x.id, 'invoicenumber', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={2}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(parseFloat(x.credit)).toFixed(2)}
                                            onChange={() => {
                                                //this.props.actions.setUpdateItem('others', x.id, 'amount', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={2}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Reason'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={''}
                                            onChange={() => {
                                                //this.props.actions.setUpdateItem('others', x.id, 'reason', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={2}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={(parseFloat(x.credit)).toFixed(2)}
                                            onChange={() => {
                                                //this.props.actions.setValue('market', value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        })
                    : <div>{'No Items Found...'}</div>
            }
            {/*   <Row>
                <Col>
                    <br />
                   < Button className={"global_button"} variant="contained"
                        key={'add_more_button'}
                        label={'+ Add'}
                        primary={true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.addBlankRow('others')
                        }}
                    >{window.t.en('xxxxx')}</Button>
                    <br />
                    <br />
                    <br />
                </Col>
            </Row> */}
        </Container>
    }
    getLayout() {
        this.actions = this.props.actions;
        return <div><Card
            title={window.t.en('Supplier Payment')}
            description={window.t.en('Supplier Payment Details')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{window.t.en('Supplier Selection')}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    name={'dll_supplier_list_id'}
                                    title={window.t.en('Search...')}
                                    info={{
                                        show: false
                                    }}
                                    fullWidth={true}
                                    data={this.props.state.supplier_list}
                                    value={this.props.state.selected_supplier.text ? this.props.state.selected_supplier.text : ''}
                                    fieldvalue={'supplier_id'}
                                    displayfields={['suppliername', 'accountnumber', 'suppliertype']}
                                    displaywidth={['100', '50', '50']}
                                    onSelect={(args) => {
                                        this.props.actions.setLookupValue("selected_supplier", args.value, args.text)
                                        if (args.value) {
                                            let data = {
                                                supplier_id: args.value,
                                                search_text: this.props.state.search_text
                                            }
                                            this.props.actions.getPaymentList(data)
                                            this.props.actions.setValidationValue("selectedSupplier", args.value)
                                            this.props.actions.setValidationValue("supplierSuspended", this.props.state.supplier.suspended ? false : true)

                                        }
                                    }
                                    }
                                    onClick={() =>
                                        this.props.actions.getSupplierList('')
                                    }
                                    onFilter={
                                        (args) => {
                                            this.props.actions.setLookupValue("selected_supplier", args, null)
                                            this.props.actions.getSupplierList(args)
                                        }

                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{window.t.en('Business Account')}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    name={'dll_business_account_id'}
                                    title={window.t.en('Search...')}
                                    info={{
                                        show: false
                                    }}
                                    fullWidth={true}
                                    data={this.props.state.businessUnits}
                                    value={this.props.state.selectedBusinessUnit.text}
                                    onSelect={(args) => //this.props.actions.setComponentFieldInput(field, args.value)
                                    {
                                        this.props.actions.setLookupValue("selectedBusinessUnit", args.value, args.text)
                                    }
                                    }
                                    onClick={() =>
                                        this.props.actions.getBusinessList('')
                                    }
                                    onFilter={(args) =>
                                        this.props.actions.getBusinessList(args)
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{window.t.en('Statement Date')}</div>
                            <div style={styles.fieldContent}>
                                <DateControl
                                    id={'txt_statement_date'}
                                    key={'txt_statement_date'}
                                    name={'txt_statement_date'}
                                    label={window.t.en('Select Date...')}
                                    field={'statement_date'}
                                    fullWidth={true}
                                    value={this.props.state.statement_date}
                                    onSelect={(args) => {
                                        this.props.actions.setValue('statement_date', args)
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{window.t.en('Payment Date')}</div>
                            <div style={styles.fieldContent}>
                                <DateControl
                                    id={'txt_payment_date'}
                                    key={'txt_payment_date'}
                                    name={'txt_payment_date'}
                                    label={window.t.en('Select Date...')}
                                    field={'payment_date'}
                                    fullWidth={true}
                                    value={this.props.state.payment_date}
                                    onSelect={(args) => {
                                        this.props.actions.setValue('payment_date', args)
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{window.t.en('Statement Amount')}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    id={'txtquotenumber'}
                                    label={window.t.en('Enter amount...')}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.statement_amount}
                                    onChange={(event) => {
                                        this.props.actions.setValue('statement_amount', event.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{window.t.en('Discount %')}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    id={'txtquotenumber'}
                                    label={window.t.en('Enter value...')}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.discount_perc}
                                    onChange={(event) => {
                                        this.props.actions.setValue('discount_perc', event.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row> */}

                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{window.t.en('Payment Status')}</div>
                            <div style={styles.fieldContent}>
                                <Button variant="contained" style={
                                    this.props.state.payment_list.length ?
                                        this.props.state.payment_list[0].status == 'Submitted' ?
                                            styles.loaded :
                                            this.props.state.payment_list[0].status == 'Not Handled' ? styles.not_handled :
                                                this.props.state.payment_list[0].status == 'Rejected' ? styles.rejected :
                                                    this.props.state.payment_list[0].status == 'Approved' ? styles.approved :
                                                        styles.not_handled :
                                        styles.not_handled
                                }
                                    onClick={(event) => {
                                        event.preventDefault()
                                    }}
                                >{window.t.en(this.props.state.payment_list.length ? this.props.state.payment_list[0].status : 'Loading...')}
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>

                    </Col>
                </Row>
            </Container>} />
            <Card
                title={'Upload Statement'}
                subtitle={'Allows you to upload a statement'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {/* data.filename ? <PdfViewer
                                id={'document_viewer_main_history_' + this.props.state.payment_list[0].supplier_idd}
                                key={'document_viewer_main_history_' + this.props.state.payment_list[0].supplier_id}
                                name={'purchaseorder_grid_' + this.props.state.payment_list[0].supplier_id}
                                edit={false}
                                multiple={false}
                                workflow_queue_data={null}
                            />
                                : */ <Upload
                                    id={'upload_ctl_'}
                                    prefix={'supplier_doc_'}
                                    header={false}
                                    list={[{ value: 0, text: 'Supplier Attachment' }, { value: 0, text: 'Supplier Statement' }]}
                                />}
                        </Col>
                    </Row>
                </Container>} />
            {this.props.state.payment_list.length ? this.getSupplierInfo(this.props.state.payment_list[0].supplier_id) : ''}
            <Row>
                <Col xl={12}><br></br></Col>
            </Row>
            {this.getSupplierDetails()}
            {
                this.props.state.previewDocuments ?
                    <div>
                        {this.props.state.payment_list.length ? this.getRecon() : ''}
                        {this.props.state.payment_list.length ? this.getRequisition() : ''}
                        {this.props.state.payment_list.length ? this.getRemittance() : ''}

                        <Row>
                            <Col xl={10}></Col>
                            <Col xl={2}><Button style={styles.approved} variant="contained"
                                key={'refreshReports'}
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.refreshReports()
                                    this.props.actions.setValidationValue('saveChanges', true)
                                    let obj = {
                                        type: 'payment_update',
                                        data: {
                                            payment_list: this.props.state.payment_list,
                                            statement_date: this.props.state.statement_date,
                                            payment_date: this.props.state.payment_date,
                                            settlement: this.props.state.settlement,
                                            statement_amount: this.props.state.statement_amount,
                                        },
                                        user: this.props.state.user
                                    }
                                    this.props.actions.getCommitData(obj)
                                }}
                            >{window.t.en('Save Changes')}</Button></Col>
                        </Row>
                        <Row>
                            <Col xl={12}><br></br></Col>
                        </Row>
                    </div>
                    : ''}
        </div>
    }
    getSupplierDetails() {
        let x = this.props.state.payment_list.length
            ? this.props.state.payment_list[0] : null
        return <div>{
            x ? <div>
                <Card
                    title={'Payment Details'}
                    subtitle={'Payment summary'}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        {Math.abs(this.props.state.not_paying) ? <div> <Row><Col xl={12} style={styles.title}>{'Invoices Not Paying'} </Col></Row>
                            <Row>
                                <Col xl={12}>
                                    {this.getNotPaying(this.props.state.payment_list[0].items.filter(x => x.dontpay == true))}
                                </Col>
                            </Row> </div> : ''}
                        <Row><Col xl={12} style={styles.title}></Col></Row>
                        <Row><Col xl={12} style={styles.title}>{'Penalties/Other'} </Col></Row>
                        <Row>
                            <Col xl={12}>
                                {this.getCredits()}
                            </Col>
                        </Row>
                        <Row><Col xl={12} style={styles.title}> </Col></Row>
                        <Row><Col xl={12} style={styles.title}>{'Payments Not Credited'} </Col></Row>
                        <Row>
                            <Col xl={12}>
                                {this.getPayments()}
                            </Col>
                        </Row>
                    </Container>} />

                {this.getTransactions(x.items)}
            </div>
                : ''
        }</div>

    }
    getSupplierInfo(id) {
        return <div>
            <ViewSupplier key={id} id={id} />
        </div>
    }

    getNotPaying(data) {
        return <div className="table-responsive-md">
            <Table className="table table-hover text-nowrap mb-0">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Amount</th>
                        <th>Reason</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length ?
                        data.map(x => {
                            return <tr>
                                <td>{moment(x.date).format("YYYY-MM-DD")}</td>
                                <td>{x.reference}</td>
                                <td>{x.totalpayment ? parseFloat(Math.abs(x.totalpayment).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}</td>
                                <td>{x.comments}</td>
                                <td>{x.totalpayment ? parseFloat(Math.abs(x.totalpayment).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}</td>
                            </tr>
                        }) : 'No records found...'
                    }
                </tbody>
            </Table>
        </div>
    }
    getCredits() {
        return <div className="table-responsive-md">
            <Table className="table table-hover text-nowrap mb-0">
                <thead>
                    <tr>
                        <th></th>
                        <th>Date</th>
                        <th>Reference</th>
                        <th>Amount</th>
                        <th>Reason</th>
                        <th>Total</th>

                    </tr>
                </thead>
                <tbody>
                    {this.props.state.credits
                        ? this.props.state.credits.map(c => {
                            return <tr>
                                <td>{<IconButton key={'command_remove_' + c.id} style={styles.iconButton} iconStyle={styles.action}
                                    onClick={
                                        () => {
                                            this.props.actions.removeRow('credits', c.id)
                                        }
                                    }>
                                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                </IconButton>}</td>
                                <td>{
                                    <DateControl
                                        id={'txt_transactiondate'}
                                        key={'txt_transactiondate'}
                                        name={'txt_transactiondate'}
                                        label={window.t.en('Select Date...')}
                                        field={'txt_transactiondate'}
                                        fullWidth={true}
                                        value={c.date}
                                        onSelect={(args) => {
                                            c.date = args
                                            this.props.actions.EditLineItem('credits', c)
                                        }}
                                        type={'date'}
                                    />}</td>
                                <td>{<TextField
                                    id={'txtdescription'}
                                    label={'Reference'}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={c.invoicenumber}
                                    onChange={(event) => {
                                        c.invoicenumber = event.target.value
                                        this.props.actions.EditLineItem('credits', c)
                                    }}
                                />}</td>
                                <td>{<TextField
                                    id={'txtamount'}
                                    label={'Amount'}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={c.amount}
                                    onChange={(event) => {
                                        c.amount = event.target.value
                                        c.total = event.target.value
                                        this.props.actions.EditLineItem('credits', c)

                                        //data.amountpaid = this.getTotals()
                                        //data.payments = this.getPayable(value, this.getTotals(), data.discount)
                                    }}
                                />}</td>
                                <td>{<TextField
                                    id={'txtamount'}
                                    label={'Reason'}
                                    fullWidth={true}
                                    multiLine={false}
                                    value={(c.reason)}
                                    variant="outlined"
                                    onChange={(event) => {
                                        c.reason = event.target.value
                                        this.props.actions.EditLineItem('credits', c)
                                    }}
                                />}</td>
                                <td>{<TextField
                                    id={'txtamount'}
                                    label={'Total'}
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    variant="outlined"
                                    value={(parseFloat(c.total).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    onChange={(event, value) => {
                                    }}
                                />}</td>
                            </tr>
                        }) : 'No records found...'
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td>  < Button className={"global_button"} variant="contained"
                            key={'add_more_button'}
                            label={'+ Add'}
                            primary={true}
                            onClick={(event) => {
                                event.preventDefault()
                                this.addBlankRow('credits')
                            }}
                        >{window.t.en('+ Add')}</Button></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><div style={styles.column_big(200, 0, 0, 0)}> {parseFloat(_.sumBy(this.props.state.credits, (d) => { return parseFloat(d.total) }).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div></td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    }
    getPayments() {
        return <div className="table-responsive-md">
            <Table className="table table-hover text-nowrap mb-0">
                <thead>
                    <tr>
                        <th></th>
                        <th>Date</th>
                        <th>EFT</th>
                        <th>Amount</th>
                        <th>Reason</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.state.payments
                        ? this.props.state.payments.map(x => {
                            return <tr>
                                <td>{<IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                    onClick={
                                        () => {
                                            this.props.actions.removeRow('payments', x.id)
                                        }
                                    }>
                                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                </IconButton>}</td>
                                <td>{
                                    <DateControl
                                        id={'txt_transactiondate'}
                                        key={'txt_transactiondate'}
                                        name={'txt_transactiondate'}
                                        label={window.t.en('Select Date...')}
                                        field={'txt_transactiondate'}
                                        fullWidth={true}
                                        value={x.date}
                                        onSelect={(args) => {
                                            x.date = args
                                            this.props.actions.EditLineItem('payments', x)
                                        }}
                                        type={'date'}
                                    />}</td>
                                <td>{<TextField
                                    id={'txtamount'}
                                    hintText={'Enter EFT Amount'}
                                    value={x.eft}
                                    variant="outlined"
                                    onChange={(event) => {
                                        x.eft = event.target.value
                                        x.total = event.target.value
                                        this.props.actions.EditLineItem('payments', x)
                                    }}
                                />}</td>
                                <td>{<TextField
                                    id={'txtamount'}
                                    hintText={'Enter Amount'}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={(x.amount)}
                                    onChange={(event) => {
                                        x.amount = event.target.value
                                        x.total = event.target.value
                                        this.props.actions.EditLineItem('payments', x)
                                    }}
                                />}</td>
                                <td>{<TextField
                                    id={'txtamount'}
                                    hintText={'Enter Reason'}
                                    value={x.reason}
                                    variant="outlined"
                                    onChange={(event) => {
                                        x.reason = event.target.value
                                        this.props.actions.EditLineItem('payments', x)
                                    }}
                                />}</td>
                                <td>{<TextField
                                    id={'txtamount'}
                                    hintText={'Enter Amount'}
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    variant="outlined"
                                    value={(parseFloat(x.total)).toFixed(2)}
                                    onChange={(event) => {
                                        //this.props.actions.setValue('market', value)
                                    }}
                                />}</td>
                                <td></td>
                            </tr>
                        }) : 'No records found...'
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td>< Button className={"global_button"} variant="contained"
                            key={'add_more_button'}
                            label={'+ Add'}
                            primary={true}
                            onClick={(event) => {
                                event.preventDefault()
                                this.addBlankRow('payments')
                            }}
                        >{window.t.en('+ Add')}</Button></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><div style={styles.column_big(200, 0, 0, 0)}> {parseFloat(_.sumBy(this.props.state.payments, (d) => { return parseFloat(d.total) }).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div></td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    }
    getTransactions(data) {
        return <div>
            <Card
                title={'Supplier Transactions ( ' + this.props.state.selected_supplier.text + ' )'}
                subtitle={'Detailed transactions for payment'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row><Col xl={12}><br></br></Col></Row>
                    <Row>
                        <Col xl={2}>
                            <div style={styles.column_big(200, 0, 0, 0)}>
                                <TextField
                                    id={'statement_amount'}
                                    label={window.t.en('Statement Amount')}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.statement_amount}
                                    onChange={(event) => {
                                        this.props.actions.setValue('statement_amount', event.target.value)
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={2}>
                            <div style={styles.column_big(200, 0, 0, 0)}>
                                <TextField
                                    id={'selected_invoices'}
                                    label={window.t.en('Amount Selected')}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.selected_invoices ? parseFloat(this.props.state.selected_invoices.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}
                                    onChange={(event) => {
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={2}>
                            <div style={styles.column_big(200, this.props.state.not_paying ? this.props.state.not_paying : 0, 0, 0)}>
                                <TextField
                                    id={'not_paying'}
                                    label={window.t.en('Not Paying')}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.not_paying ? parseFloat(this.props.state.not_paying.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}
                                    onChange={(event) => {
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={2}>
                            <div style={styles.column_big(300, 0, this.props.state.short_pay ? this.props.state.short_pay : 0, 0)}>
                                <TextField
                                    id={'short_pay'}
                                    label={window.t.en('Short Pay')}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.short_pay ? parseFloat(this.props.state.short_pay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}
                                    //disabled={true}
                                    onChange={(event) => {
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={2}>
                            <div style={styles.column_big(300, 0, 0, 0)}>
                                <TextField
                                    id={'discount_perc'}
                                    label={window.t.en('Discount %')}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.discount_perc}
                                    onChange={(event) => {
                                        this.props.actions.setValue('discount_perc', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.getUpdatePaymentListHeader()
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={2}>
                            <div style={styles.column_big(200, 0, 0, this.props.state.total_payable ? this.props.state.total_payable : 0)}>
                                <TextField
                                    id={'total_payable'}
                                    label={window.t.en('Total Payable')}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.total_payable ? parseFloat(this.props.state.total_payable.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}
                                    //disabled={true}
                                    onChange={(event) => {
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row><Col xl={2}><br></br></Col></Row>
                    <Row>
                        <Col xl={9}>
                            <TextField
                                fullWidth={true}
                                label='Search text...'
                                /* variant="outlined" */
                                value={this.props.state.search_text}
                                onChange={(event) => {
                                    this.props.actions.updateValue('search_text', event.target.value)
                                }}
                            />
                        </Col>
                        <Col xl={1}>
                            <Tooltip TransitionComponent={Zoom} title="Search...">
                                <Button className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                    onClick={(event) => {
                                        event.preventDefault()
                                        let data = {
                                            supplier_id: this.props.state.selected_supplier.value,
                                            search_text: this.props.state.search_text
                                        }
                                        this.props.actions.getPaymentList(data)
                                    }}>
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className="font-size-sm"
                                    />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row><Col xl={2}><br></br></Col></Row>
                    <Row>
                        <div className="table-responsive-md">
                            <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        {!this.props.state.technical_payment ? <th>Auth #</th> : ''}
                                        {!this.props.state.technical_payment ? <th>Reg #</th> : ''}
                                        <th>Description</th>
                                        <th>Invoice #</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice Amount</th>
                                        <th>Dont Pay</th>
                                        <th>Accept</th>
                                        <th>Short Pay</th>
                                        <th>Payment</th>
                                        <th>< Button style={this.props.state.accept_all ? styles.rejected : styles.approved} variant="contained"
                                            key={'accept_button'}
                                            primary={true}
                                            onClick={(event) => {
                                                this.props.actions.updateValue('accept_all', this.props.state.accept_all ? false : true)
                                                this.props.actions.getUpdateAllPaymentList()
                                            }}
                                        >{window.t.en(this.props.state.accept_all ? 'UNDO ALL' : 'ACCEPT ALL')}</Button>{'    '}
                                            < Button className={"global_button"} variant="contained"
                                                key={'refreshReports'}
                                                primary={true}
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    this.props.actions.setValidationValue('saveChanges', false)
                                                    this.props.actions.refreshReports()
                                                    /* let obj = {
                                                        type: 'payment_update',
                                                        data: {
                                                            payment_list: this.props.state.payment_list,
                                                            statement_date: this.props.state.statement_date,
                                                            payment_date: this.props.state.payment_date,
                                                            settlement: this.props.state.settlement,
                                                            statement_amount: this.props.state.statement_amount,
                                                        },
                                                        user: this.props.state.user
                                                    }
                                                    this.props.actions.getCommitData(obj) */
                                                }}
                                            >{window.t.en('Preview Documents')}</Button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length
                                        ? data.map((i, inx) => {
                                            return <tr>
                                                {!this.props.state.technical_payment ? <td>{i.authnumber}</td> : ''}
                                                {!this.props.state.technical_payment ? <td>{i.vehicle_fktext}</td> : ''}
                                                <td>{i.description}</td>
                                                <td>{i.reference}</td>
                                                <td>{moment(i.date).format('YYYY-MM-DD')}</td>
                                                <td>{parseFloat(i.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td><div style={styles.column_big(150, 0, 0, 0)}>{
                                                    i.ps > 1 ? '' : <Checkbox
                                                        id={'dontpay'}
                                                        key={'dontpay'}
                                                        name={'dontpay'}
                                                        field={'dontpay'}
                                                        checked={i.dontpay}
                                                        labelPlacement={'end'}
                                                        label={window.t.en('')}
                                                        onCheck={(checked) => {
                                                            //this.props.actions.setInputValue("dontpay", checked)
                                                            let data = {
                                                                id: i.suppliertransaction_id,
                                                                parent_id: i.supplier_id,
                                                                column: 'dontpay',
                                                                search_text: this.props.state.search_text
                                                            }
                                                            this.props.actions.getUpdatePaymentList(data)
                                                        }}
                                                    />}</div>
                                                </td><td> <div style={styles.column_big(150, 0, 0, 0)}>
                                                    {i.ps > 1 ? '' : <Checkbox
                                                        id={'selected'}
                                                        key={'selected'}
                                                        name={'selected'}
                                                        field={'selected'}
                                                        checked={i.selected}
                                                        labelPlacement={'end'}
                                                        label={window.t.en('')}
                                                        onCheck={(checked) => {
                                                            let data = {
                                                                id: i.suppliertransaction_id,
                                                                parent_id: i.supplier_id,
                                                                type: i.dataset,
                                                                column: 'accept',
                                                                search_text: this.props.state.search_text
                                                            }
                                                            this.props.actions.getUpdatePaymentList(data)
                                                        }}
                                                    />}</div></td>
                                                <td>
                                                    <div style={styles.column_big(200, 0, 0, 0)}>
                                                        <TextField
                                                            fullWidth={false}
                                                            hintText='Shortpay'
                                                            value={i.shortpay}
                                                            variant="outlined"
                                                            disabled={i.ps > 1 ? true : false}
                                                            onChange={(event) => {
                                                                this.props.actions.updateValue('updatevalue', event.target.value)
                                                                this.props.actions.updateValue('updatevalue', event.target.value)
                                                                i.shortpay = event.target.value
                                                            }}
                                                            onBlur={(event) => {
                                                                let data = {
                                                                    id: i.suppliertransaction_id,
                                                                    parent_id: i.supplier_id,
                                                                    type: i.dataset,
                                                                    value: i.shortpay,
                                                                    column: 'shortpay',
                                                                    search_text: this.props.state.search_text
                                                                }
                                                                this.props.actions.getUpdatePaymentList(data)

                                                            }}
                                                        /></div>
                                                </td>
                                                <td>
                                                    <div style={styles.column_big(200, i.dontpay, Number(i.shortpay), Number(i.totalpayment))}>
                                                        <TextField
                                                            fullWidth={false}
                                                            value={i.totalpayment ? parseFloat(i.totalpayment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : i.totalpayment}
                                                            variant="outlined"
                                                            onChange={(event) => { }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={styles.column_big(200, 0, 0, 0)}>
                                                        {/* <Tooltip TransitionComponent={Zoom} title="View Details">
                                                            <Button className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    this.props.actions.toggleItemProps(inx + 1)

                                                                }}>
                                                                <FontAwesomeIcon
                                                                    className="font-size-sm"
                                                                />
                                                            </Button>
                                                        </Tooltip>{'  '} */}
                                                        < Button style={
                                                            i.status == 'Submitted' ?
                                                                styles.loaded :
                                                                i.status == 'Not Handled' ? styles.not_handled :
                                                                    i.status == 'Rejected' ? styles.rejected :
                                                                        i.status == 'Approved' ? styles.approved :
                                                                            styles.not_handled
                                                        } variant="contained"
                                                            key={'reject_button'}
                                                            primary={true}
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                this.props.actions.toggleItemProps(inx + 1)

                                                            }}>{window.t.en(i.status ? i.status : "Not Handled")}</Button>
                                                    </div>
                                                    {
                                                        this.props.state.prop_inx === (inx + 1) && <Drawer
                                                            anchor={'right'}
                                                            openSecondary={true}
                                                            open={this.props.state.prop_inx === (inx + 1)}
                                                            onRequestChange={(event) => {
                                                                event.preventDefault()
                                                                this.props.actions.toggleItemProps(inx + 1)
                                                            }}>
                                                            <div style={styles.drawer_size}>

                                                                <Card
                                                                    title={'Payment Documents'}
                                                                    subtitle={'Payment Supporting Documents'}
                                                                    content={<Container style={{ margin: 0 }} className="custom-container">
                                                                        < Button className={"global_button"} variant="contained"
                                                                            key={'close_button'}
                                                                            primary={true}
                                                                            onClick={(event) => {
                                                                                event.preventDefault()
                                                                                this.props.actions.toggleItemProps(inx + 1)
                                                                            }}
                                                                        >{window.t.en('Close')}</Button>

                                                                        <Row><Col xl={2}><br></br></Col></Row>
                                                                        <Row><Col xl={12}> <TextField
                                                                            fullWidth={true}
                                                                            label='Enter Comments...'
                                                                            variant="outlined"
                                                                            value={i.comments}
                                                                            onChange={(event) => {
                                                                                this.props.actions.updateValue('comments', event.target.value)
                                                                                i.comments = event.target.value
                                                                            }}
                                                                        /><br></br><br></br>
                                                                            {i.manager_comments ? <TextField
                                                                                fullWidth={true}
                                                                                label='Technical Manager Comments...'
                                                                                variant="outlined"
                                                                                value={i.manager_comments}
                                                                                onChange={(event) => {
                                                                                }}

                                                                            /> : ''}<br></br><br></br>
                                                                            {i.ops_comments ? <TextField
                                                                                fullWidth={true}
                                                                                label='GM Operations Comments...'
                                                                                variant="outlined"
                                                                                value={i.ops_comments}
                                                                                onChange={(event) => {
                                                                                }}
                                                                            /> : ''}<br></br><br></br>
                                                                            {i.finance_comments ? <TextField
                                                                                fullWidth={true}
                                                                                label='Finance Comments...'
                                                                                variant="outlined"
                                                                                value={i.finance_comments}
                                                                                onChange={(event) => {
                                                                                }}
                                                                            /> : ''}</Col></Row>
                                                                        <Row><Col xl={2}><br></br></Col></Row>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                {<PdfViewer
                                                                                    id={'document_viewer_main_history_' + i.supplier_id}
                                                                                    key={'document_viewer_main_history_' + i.supplier_id}
                                                                                    name={'purchaseorder_grid_' + i.supplier_id}
                                                                                    documents={i.documents}
                                                                                    edit={false}
                                                                                    multiple={false}
                                                                                    workflow_queue_data={null}
                                                                                />
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </Container>} />

                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                < Button className={"global_button"} variant="contained"
                                                                    key={'close_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.toggleItemProps(inx + 1)
                                                                    }}
                                                                >{window.t.en('Close')}</Button>
                                                            </div>


                                                        </Drawer>}
                                                </td>
                                            </tr>
                                        }) : 'No records found...'
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Row>

                </Container>} />
        </div>
    }

    getRecon() {
        let gui = moment().format('YYYYMMDDHHmmss')
        return <Card
            title={window.t.en('Payment Recon')}
            subtitle={window.t.en('Payment reconciliation document')}
            key={'payment_recon'}
            id={'payment_recon'}
            content={<Recon id={'payment_recon'} key={'payment_recon'} shortid={'payment_recon'} data={this.props.state.recon_data} />} />
    }
    getRequisition() {
        return <Card
            title={window.t.en('Payment Requisition')}
            subtitle={window.t.en('Payment requisition document')}
            key={'payment_requisition'}
            id={'payment_requisition'}
            content={<Requisition id={'payment_requisition'} key={'payment_requisition'} shortid={'payment_requisition'} data={this.props.state.requisition_data} />} />
    }
    getRemittance() {
        return <Card
            title={window.t.en('Payment Remittance')}
            subtitle={window.t.en('Payment remittance document')}
            key={'payment_remittance'}
            id={'payment_remittance'}
            content={<Remittance id={'payment_remittance'} key={'payment_remittance'} shortid={'payment_remittance'} data={this.props.state.remittance_data} />} />
    }
    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.supplierpaymentmanagement,
            user: {
                ...state.user
            },
            supplier: {
                ...state.controls.supplier
            }

        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERPAYMENTMANAGEMENT)