import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const gridOptions = {

}

export const highlight = colours.red[200]

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const action = {
    fontSize: '20px',
    color: colours.red[500]
}

export const actionHoverColor = {
    color: colours.red[500]
}

export const gridOptionsIcon = {

}