import moment from 'moment'

import * as types from './types'
import * as appTypes from '../App/types'

export const login = (container, key, name, parameters = []) => {
}

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_SCHEDULER_FORM_FIELDS,
        })
    }
}

export const setSeletedReport = (event) => {

    const {oqlis} = event.target.props

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_REPORT_VIEW,
            payload: event.target.props.children ? null
                : {
                    report_key: event.target.props.uniquePrivateKey,
                    report_name: event.target.props.title,
                    report_action: event.action,
                }
        })

        dispatch({
            type: types.SET_REPORT_KEY,
            payload: !event.target.props.oqlis ? {
                'oq_dashboard_key': false,
                'oq_report_key': false
            }
                : {
                    oq_report_key: oqlis.oq_report_key?oqlis.oq_report_key: false,
                    oq_dashboard_key: oqlis.oq_dashboard_key?oqlis.oq_dashboard_key:false,
                }
        })

        const {report_key} = getState().businessIntelligence
        //console.log("STATE",report_key);
        if(report_key.oq_report_key || report_key.oq_dashboard_key){
            return null
        }else{
            if (!event.target.props.children)
            if (event.target.props.route)
                dispatch(getDashboadView(event.target.props.route));
            else
                dispatch(getReportGridData(event.target.props.title));
        }

    }
}

export const getDashboadView = (dashboard_view) => {
    return {
        type: types.SET_BASHBOARD_VIEW,
        payload: dashboard_view
    }
}

export const getReportGridData = (report_name) => {
    return {
        type: types.GET_REPORT_GRID_DATA_BY_REPORT_NAME,
        payload: {
            type: 'GET_REPORT_GRID_DATA_BY_REPORT_NAME',
            report_name: report_name
        }
    }
}


export const getExcelReport = (event) => {
    return {
        type: types.BI_READY,
        payload: null
    }
}


export const dataStateChange = (event) => {

    return (dispatch, getState) => {

        dispatch({
            type : types.SET_REPORT_GRID_DATA_BY_FILTER,
            data : {}, //process( getState().businessIntelligence.report_data.slice(0), event.data),
            name : 'result'
        })
        dispatch({
            type : types.SET_REPORT_GRID_DATA_BY_FILTER,
            data : event.data,
            name : 'dataState'
        })
    }
}


export const setReportGridData = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_REPORT_GRID_DATA_BY_REPORT_NAME,
            payload: response.data
        })

        dispatch({
            type : types.SET_REPORT_GRID_DATA_BY_FILTER,
            data : {}, //process(response.data.slice(0), getState().businessIntelligence.dataState),
            name : 'result'
        })
    }
}
export const ready = (result) => {
    return {
        type: types.BI_READY,
        payload: result
    }
}
export const loadIntelligence = (container, key, name, parameters = [], type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.INTELLIGENCE_LOADING,
            payload: {
                container: container,
                key: key,
                name: name,
                parameters: parameters
            }
        })

        dispatch(getReportCategory(type))
        dispatch(backHome())
    }
}
export const backHome = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_REPORT_VIEW,
            payload: null
        })
        dispatch({
            type: types.SET_REPORT_GRID_DATA_BY_REPORT_NAME,
            payload: null
        })
        dispatch({
            type: types.SET_REPORT_KEY,
            payload: {
                'oq_dashboard_key': false,
                'oq_report_key': false
            }
        })
    }
}
export const getReportCategory = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_REPORT_CATEGORY_LIST,
            payload : {
                type : 'GET_REPORT_CATEGORY_LIST',
                user_id : getState().user.user_id
            },
        })
    }
}

export const setReportCategory = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_REPORT_CATEGORY_LIST,
            payload: payload.data.filter(x => x.category_type == "dashboard"),
            name : 'dashboard'
        })
        dispatch({
            type: types.SET_REPORT_CATEGORY_LIST,
            payload: payload.data.filter(x => x.category_type == "reports"),
            name : 'reports'
        })
        dispatch(getReportSubCategory())
    }
}

export const getReportSubCategory = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_REPORT_SUB_CATEGORY_LIST,
            payload: {
                type : 'GET_TEAM_REPORT',
                user_id : getState().user.user_id
            }
        })
    }
}



export const setReportSubCategory = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUB_REPORT_CATEGORY_LIST,
            payload: payload.filter(x => x.oq_report_key),
            name : 'new_report'
        })

        dispatch({
            type: types.SET_SUB_REPORT_CATEGORY_LIST,
            payload: payload.filter(x => x.oq_dashboard_key),
            name : 'new_dashboard'
        })
    }
}


export const openScheduleWin = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SCHEDULE_WINDOW_OPEN_AND_CLOSE,
            payload: true
        })

        dispatch(getScheduler(data))
    }
}

export const closeScheduleWin = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SCHEDULE_WINDOW_OPEN_AND_CLOSE,
            payload: false
        })
    }
}

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_INPUT_FIELD_VALUE,
            payload: data
        })
    }
}

export const getScheduler = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_REPORT_SCHEDULER_BY_USER_AND_REPORT,
            payload: data
        })
    }
}

export const setScheduler = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_REPORT_SCHEDULER_BY_USER_AND_REPORT,
            payload: response.data
        })
    }
}

export const saveSchedule = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_COMPONENT_SCHEDULE_USER_DATA,
            payload: {
                type: 'SAVE_COMPONENT_SCHEDULE_USER_DATA',
                data: data
            }
        })
    }
}
export const editSchedule = (schedule) => {
    return (dispatch, getState) => {
        // //console.log('>>>>>>>>>>>>>>>>>>> scheduletime')
        // //console.log(schedule)
        // //console.log(schedule.schedule_time)
        // //console.log(new Date('01 Jan 2020 ' + schedule.schedule_time))
        dispatch({
            type: types.LAOD_SELECTED_SCHEDULE_FOR_VIEW_OR_EDIT,
            payload: {...schedule, schedule_time: new Date('01 Jan 2020 ' + schedule.schedule_time)}

        })
    }
}
export const savedSchedule = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Schedule has been successfully added to your profile!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const removeSchedule = (x) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Schedule '+x.schedule_name+' has failed to delete',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}