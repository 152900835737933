import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_SUB_ACCOUNT_ACCOUNT_DATA:
            return {
                ...state,
                data: action.payload
            }
        case types.SET_SUB_ACCOUNT_ACCOUNT_CURRENT_TAB:
            return {
                ...state,
                tab: action.payload
            }
        default:
            return state
    }
}