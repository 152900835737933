import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
    Grid,
    Dialog,
    TextField,
    IconButton
  } from '@mui/material';

  import View from '../components/View/component'
import * as actions from './actions'

class _viewlabel extends Component {
    render() {

        return <div><div>{this.props.value}</div>
        {
            this.props.info.show && <IconButton touch={true} style={{position: 'absolute', right: '-14px', top: '10px'}}
            onClick={() => {
                //console.log(this.props.info)
                this.props.actions.onInfo(this.props.info)
            }}>
                <PlayArrowIcon />
            </IconButton>
              
         
        }
        {
        this.props.state.info
        ? this.props.state.info.component === this.props.component_reference && <Dialog
               scroll="body"
               maxWidth="lg"
               style={{minWidth: '50%'}}
               open={this.props.state.info !== null}
               onClose={() => {
                this.props.actions.onInfo(null)
            }}
              classes={{ paper: 'modal-content border-0 p-4' }}
              >
               <div className="p-4">
                 <Grid container spacing={2}>
                   <Grid item lg={12}>
                     <a
                       href="#/"
                       onClick={(e) => {
                        e.preventDefault()
                        this.props.actions.onInfo(null)
                    }}
                       className="font-size-xxl d-block mb-4">
                       {this.props.state.info ? this.props.state.info.title : ''}
                     </a>
                     {
                      this.props.state.info && <View
                        key={'readComponent_View_' + this.props.state.info.component + '_' + this.props.state.info.value}
                        id={this.props.state.info.value}
                        name={this.props.state.info.component}
                        expanded={true}
                        surrogate={false}
                    />
                     }
                   </Grid>
                 </Grid>
               </div>
             </Dialog>
             : ''
    }
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.controls }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(_viewlabel)