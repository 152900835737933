import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as _ from 'lodash'
import moment from 'moment'
import PdfViewer from '../../PDF/component'

import IconButton from '@mui/material/IconButton';
import { Container, Row, Col } from 'react-grid-system'

import AutoComplete from '../../../controls/autocomplete'

import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Card, CardHeader, CardContent } from '@mui/material'

import Dropzone from 'react-dropzone'
import Iframe from 'react-iframe'

import Button from '@mui/material/Button'
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'
import * as config from '../../../config'
import { monthly_vat } from '../../WorkflowSTRHeader/CaptureSTR/props'

function findUnique(arr, predicate) {
    var found = {};
    arr.forEach(d => {
        found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]);
}

class WorkflowReviewQuote extends Component {

    componentDidMount() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data

            let quotations = []
            for (let x of workflow_queue_data.vehicles) {
                let total = (parseFloat(x.contract.finance) + parseFloat(x.maintcost.value) + parseFloat(x.tyrescost.value) +
                    parseFloat(x.derivative.licencefee) + parseFloat(x.derivative.adminfee) + parseFloat(x.contract.roi)).toFixed(2)
                quotations.push({
                    ...x,
                    cprice: x.cprice.value,
                    dprice: x.dprice.value,
                    amount: x.amount.value,
                    defaultrv: x.defaultrv.value,
                    rvamount: x.rvamount.value,
                    accessory_list: x.accessories.accessory_list,
                    service_list: x.accessories.service_list,
                    sundry_list: x.accessories.sundry_list,
                    maintcpk: x.maintcpk.value,
                    maintcost: x.maintcost.value,
                    tyrescpk: x.tyrescpk.value,
                    tyrescost: x.tyrescost.value,
                    primerate: x.contract.interestrate,
                    deviation: x.derivative.deviation,
                    interestrate: parseFloat(x.contract.interestrate) + parseFloat(x.derivative.deviation),
                    finance: x.contract.finance,
                    maintcost: x.maintcost.value,
                    tyrescost: x.tyrescost.value,
                    maintcost: x.maintcost.value,
                    tyrescost: x.tyrescost.value,
                    licencefee: x.derivative.licencefee,
                    adminfee: x.derivative.adminfee,
                    roiadj: x.derivative.roiadj,
                    roiperc: x.derivative.roiperc,
                    nett: parseFloat(x.amount.value) + parseFloat(_.sumBy(x.accessories.accessory_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)) +
                        parseFloat(_.sumBy(x.accessories.sundry_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)),
                    acc_tot: _.sumBy(x.accessories.accessory_list, (d) => { return parseFloat(d.costprice) }).toFixed(2),
                    sun_tot: _.sumBy(x.accessories.sundry_list, (d) => { return parseFloat(d.costprice) }).toFixed(2),
                    ser_tot: _.sumBy(x.accessories.service_list, (d) => { return parseFloat(d.costprice) }).toFixed(2),
                    service: _.sumBy(x.accessories.service_list, (d) => { return parseFloat(d.costprice) }).toFixed(2),
                    roi: x.contract.roi,
                    financeexcess: x.contract.financeexcess,
                    maintananceexcess: x.contract.maintananceexcess,
                    excess: parseFloat(x.contract.financeexcess) + parseFloat(x.contract.maintananceexcess),
                    deliveryperiod: x.deliveryperiod.value,
                    monthly_excl: total,
                    monthly_vat: (parseFloat(total) * 0.15).toFixed(2),
                    monthly_incl: (parseFloat(total) * 1.15).toFixed(2),
                    monthly_str: ((parseFloat(total) * 1.15) * 1.14).toFixed(2),
                })
            }
            this.props.actions.setInitData(quotations)
        } catch (error) {

        }
    }

    getVehicleAccessory(data, inx) {
        return data.accessory_list.map((x, i) => {
            return <Row style={styles.itemsrow}>
                <Col style={styles.viewValue} xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.formLTitle}>{x.accessoryname}</div>
                        <div style={styles.formContent}>
                            <TextField
                                key={'accessory_list' + i}
                                id={'accessory_list' + i}
                                hintText={''}
                                value={x.costprice}
                                style={styles.textBox}
                                onChange={
                                    (event, value) => {
                                        this.props.actions.setItemInputValue('accessory_list', value, i, inx)
                                    }
                                }
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        })
    }

    getVehicleService(data, inx) {
        return data.service_list.map((x, i) => {
            return <Row style={styles.itemsrow}>
                <Col style={styles.viewValue} xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.formLTitle}>{x.servicedescription}</div>
                        <div style={styles.formContent}>
                            <TextField
                                key={'service_list' + i}
                                id={'service_list' + i}
                                hintText={''}
                                value={x.costprice}
                                style={styles.textBox}
                                onChange={
                                    (event, value) => {
                                        this.props.actions.setItemInputValue('service_list', value, i, inx)
                                    }
                                }
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        })
    }
    getVehicleSundry(data, inx) {
        return data.sundry_list.map((x, i) => {
            return <Row style={styles.itemsrow}>
                <Col style={styles.viewValue} xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.formLTitle}>{x.description}</div>
                        <div style={styles.formContent}>
                            <TextField
                                key={'sundry_list' + i}
                                id={'sundry_list' + i}
                                hintText={''}
                                value={x.costprice}
                                style={styles.textBox}
                                onChange={
                                    (event, value) => {
                                        this.props.actions.setItemInputValue('sundry_list', value, i, inx)
                                    }
                                }
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        })
    }

    getRFQ(documents) {
        
        return documents.length
            ? <Card><CardHeader
                title={"RFQ "}
                subtitle={"RFQ document"}
                actAsExpander={true}
                showExpandableButton={true}
                style={styles.row}
            /><CardContent expandable={true} >
                <PdfViewer
                key={'quotation_viewer_'}
                documents={documents}
                height={600}
                multiple={false}
                edit={false}
            /></CardContent></Card>
            : <div>{'RFQ NOT UPLOADED'}</div>
    }

    getContractView(data) {
        let _data = data.contract.fields
        return <Card><CardHeader
            title={"Contract Parameters"}
            subtitle={window.t.en('contract details quoted parameters')}
            actAsExpander={false}
            showExpandableButton={false}
            style={styles.row}
        />
            <CardContent expandable={false} >
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col style={styles.viewTitle} xl={3}>{'Customer:'}</Col>
                        <Col style={styles.viewValue} xl={3}>{data.customer.hierarchyname}</Col>
                        <Col style={styles.viewTitle} xl={3}>{'Cost Center'}</Col>
                        <Col style={styles.viewValue} xl={3}>{data.customer.costcentre}</Col>
                    </Row>
                    <Row>
                        <Col style={styles.viewTitle} xl={3}>{'Customer Account:'}</Col>
                        <Col style={styles.viewValue} xl={3}>{data.customer.accountnumber}</Col>
                        <Col style={styles.viewTitle} xl={3}>{'Customer Region'}</Col>
                        <Col style={styles.viewValue} xl={3}>{data.customer.region}</Col>
                    </Row>
                    <Row>
                        <Col style={styles.viewTitle} xl={3}>{'RFQ Date:'}</Col>
                        <Col style={styles.viewValue} xl={3}>{moment(_data.rfqdate.value).format('YYYY-MM-DD')}</Col>
                        <Col style={styles.viewTitle} xl={3}>{'Created By'}</Col>
                        <Col style={styles.viewValue} xl={3}>{data.user.firstname} {data.user.lastname}</Col>
                    </Row>
                    <Row>
                        <Col style={styles.viewTitle} xl={3}>{'Contract Tye:'}</Col>
                        <Col style={styles.viewValue} xl={3}>{_data.contracttype.text}</Col>
                        <Col style={styles.viewTitle} xl={3}>{'Vehicle Category'}</Col>
                        <Col style={styles.viewValue} xl={3}>{_data.contractvehiclecategory.text}</Col>
                    </Row>
                    <Row>
                        <Col style={styles.viewTitle} xl={3}>{'Distance Per Month:'}</Col>
                        <Col style={styles.viewValue} xl={3}>{_data.monthlyinclusive.value}</Col>
                        <Col style={styles.viewTitle} xl={3}>{'Contract Period'}</Col>
                        <Col style={styles.viewValue} xl={3}>{_data.contractperiod.value}</Col>
                    </Row>
                    <Row>
                        <Col style={styles.viewTitle} xl={3}>{'Inclusive Contracted KMs:'}</Col>
                        <Col style={styles.viewValue} xl={3}>{_data.contractinclusive.value}</Col>
                        <Col style={styles.viewTitle} xl={3}>{'Load Factor'}</Col>
                        <Col style={styles.viewValue} xl={3}>{_data.loadfactor.text}</Col>
                    </Row>
                    <Row>
                        <Col style={styles.viewTitle} xl={3}>{'Quotation For'}</Col>
                        <Col style={styles.viewValue} xl={3}>{_data.quotefor.text}</Col>
                        <Col style={styles.viewTitle} xl={3}>{'Replace Vehicle:'}</Col>
                        <Col style={styles.viewValue} xl={3}>{_data.quotefor.data.length ? _data.quotefor.data[0].licenseplateno : ''}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>

    }

    getView(data) {
        let vehicles = data.vehicles
        let quotations = this.props.state.quotations
        return quotations.length
            ? <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>{this.getContractView(vehicles[0])}</Col>
                </Row>
                <Row>
                    <Col xl={12}>{this.getRFQ(data.documents)}</Col>
                </Row>
                <Row>
                    {
                        vehicles.map((x, i) => {
                            let len = vehicles.length
                            let xl = parseInt(12 / len)

                            return <Col xl={xl}>
                                <Card><CardHeader
                                    title={"Quotation #" + (i + 1)}
                                    subtitle={"Quote Number: " + x.makemodelyear.value}
                                    actAsExpander={false}
                                    showExpandableButton={false}
                                    style={styles.row}
                                />
                                    <CardContent expandable={false} >
                                        <Container style={{ margin: 0 }} className="custom-container">
                                            <Col>
                                                <Row>
                                                    <Container style={{ margin: 0 }} className="custom-container">
                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Vehicle Details'} </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                {
                                                                    x.mmcode.imagefilename
                                                                        ? <img style={styles.vehicle} src={config.system.cdn + '/' + (x.mmcode.imagefilename !== null ? x.mmcode.imagefilename : 'unknown.png')} />
                                                                        : <div>{''}</div>
                                                                }</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"MM Code"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{x.mmcode.mmcode}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Vehicle Make"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{x.mmcode.make}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Vehicle Model"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{x.mmcode.cdescription}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Fuel Type"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{x.mmcode.fueltype}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Transmision"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{x.mmcode.manualauto}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Color"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{x.parameters.color.text}</Col>
                                                        </Row>


                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Pricing Details'} </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Retail Price'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'vehicle_price'}
                                                                        id={'vehicle_price'}
                                                                        hintText={'Retail Price'}
                                                                        value={quotations[i].cprice}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('cprice', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Discount Price'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'discount_price'}
                                                                        id={'discount_price'}
                                                                        hintText={'Discount Price'}
                                                                        value={quotations[i].dprice}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('dprice', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Vehicle Price'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'vehicle_price'}
                                                                        id={'vehicle_price'}
                                                                        hintText={'Vehicle Price'}
                                                                        value={quotations[i].amount}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('amount', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'RV Percentage'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'defaultrv'}
                                                                        id={'defaultrv'}
                                                                        hintText={'RV Percentage'}
                                                                        value={quotations[i].defaultrv}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('defaultrv', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'RV Amount'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'rvamount'}
                                                                        id={'rvamount'}
                                                                        hintText={'RV Amount'}
                                                                        value={quotations[i].rvamount}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('rvamount', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Accessories List'} </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={12}>{this.getVehicleAccessory(x.accessories, i)}</Col>
                                                        </Row>
                                                        <Row style={styles.itemsfooter}>
                                                            <Col xl={12}>{"Total " + _.sumBy(x.accessories.accessory_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)}</Col>
                                                        </Row>
                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Services List'} </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={12}>{this.getVehicleService(x.accessories, i)}</Col>
                                                        </Row>
                                                        <Row style={styles.itemsfooter}>
                                                            <Col xl={12}>{"Total " + _.sumBy(x.accessories.service_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)}</Col>
                                                        </Row>
                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Sundries List'} </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={12}>{this.getVehicleSundry(x.accessories, i)}</Col>
                                                        </Row>
                                                        <Row style={styles.itemsfooter}>
                                                            <Col xl={12}>{"Total " + _.sumBy(x.accessories.sundry_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)}</Col>
                                                        </Row>
                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Tyres and Maintenance Details'} </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Maintenance CPK'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'maintcpk'}
                                                                        id={'maintcpk'}
                                                                        hintText={'Maintenance CPK'}
                                                                        value={quotations[i].maintcpk}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('maintcpk', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Maintenance Cost'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'maintcost'}
                                                                        id={'maintcost'}
                                                                        hintText={'Maintenance Cost'}
                                                                        value={quotations[i].maintcost}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('maintcost', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Tyres CPK'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'tyrescpk'}
                                                                        id={'tyrescpk'}
                                                                        hintText={'Tyres CPK'}
                                                                        value={quotations[i].tyrescpk}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('tyrescpk', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Tyres Cost'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'tyrescost'}
                                                                        id={'tyrescost'}
                                                                        hintText={'Tyres Cost'}
                                                                        value={quotations[i].tyrescost}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('tyrescost', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Rates Details'} </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Prime Rate'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'primerate'}
                                                                        id={'primerate'}
                                                                        hintText={'Prime Rate'}
                                                                        value={quotations[i].primerate}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('interestrate', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Deviation'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'deviation'}
                                                                        id={'deviation'}
                                                                        hintText={'Deviation'}
                                                                        value={quotations[i].deviation}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('deviation', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={12}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.formLTitle}>{'Interest Rate'}</div>
                                                                    <div style={styles.formContent}><TextField
                                                                        key={'interestrate'}
                                                                        id={'interestrate'}
                                                                        hintText={'Interest Rate'}
                                                                        value={quotations[i].interestrate}
                                                                        style={styles.textBox}
                                                                        onChange={
                                                                            (event, value) => {
                                                                                this.props.actions.setInputValue('interestrate', value, i)
                                                                            }
                                                                        }
                                                                    /></div>
                                                                </div>
                                                            </Col>
                                                        </Row>



                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Finance Details'} </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Finance"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].finance}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Maintenance "}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].maintcost}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Tyres"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].tyrescost}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Service"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].service}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"License Fee"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].licencefee}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Admin Fee"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].adminfee}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"ROI"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].roi}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"VAT"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>
                                                                {quotations[i].monthly_vat}
                                                            </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Monthly Rental"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>
                                                                {quotations[i].monthly_incl}
                                                            </Col>
                                                        </Row>
                                                        {/* <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Monthly STR"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>
                                                                {quotations[i].monthly_str}
                                                            </Col>
                                                        </Row> */}
                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Excess Details'} </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Finance Excess"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].financeexcess}</Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Maintanance Excess"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].maintananceexcess}</Col>
                                                        </Row>
                                                        <Row style={styles.subsection}>
                                                            <Col xl={12}>{'Delivery'} </Col>
                                                        </Row>
                                                        <Row style={styles.lines}>
                                                            <Col style={styles.viewValue} xl={6}>{"Delivery Period"}</Col>
                                                            <Col style={styles.viewValue} xl={6}>{quotations[i].deliveryperiod}</Col>
                                                        </Row>
                                                    </Container>
                                                </Row>
                                            </Col>
                                        </Container>
                                    </CardContent>
                                </Card>
                            </Col>
                        })
                    }
                </Row>
            </Container>
            : 'Please wait...'
    }

    getLayout(data) {
        return data.vehicles
            ? this.getView(data)
            : 'Loading...'
    }

    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }

        return workflow_queue_data
            ? this.getLayout(workflow_queue_data)
            : 'Loading...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowReviewQuote
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowReviewQuote)