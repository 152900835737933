import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Icon from '../../Icon/component'
import { Button } from '@mui/material'
import AutoComplete from '../../../controls/autocomplete'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Container, Row, Col } from 'react-grid-system'
import { Table } from '@mui/material'

import * as actions from './actions'
import * as styles from './styles'

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('0dc2c32f83edb803ade140958edb564aTz04MjQzNSxFPTE3MzcxOTI2NzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

class CASH_BOOK extends Component {
    componentDidMount() {
        this.props.actions.setInputValue("report_data", null)
        this.props.actions.setInputValue("itemdescription", null)
       //this.props.actions.getProfitlossData(moment().format('YYYYMM'),moment().format('YYYYMM'))
    }

    getDatePicket() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={5}>
                    <label style={{paddingRight: '10px',paddingTop: '10px', fontSize: '16px', fontWeight: 'bold'}}>Select GL Account Number: </label><br/>
                    <AutoComplete
                        name={'dll_debtor_id'}
                        title={window.t.en('Search GL Account Number')}
                        info={{
                            show: false
                        }}
                        fullWidth={true}
                        data={this.props.state.accountingitem_data}
                        value={this.props.state.itemdescription}
                        fieldvalue={'accountingitem_id'}
                        displayfields={['accountnumber','itemdescription']}
                        displaywidth={['20','80']}
                        onSelect={(args) =>
                        {
                            //console.log('getViewx',args)
                            let accountingitem = this.props.state.accountingitem_data.filter(x => x.accountingitem_id === args.value)
                            accountingitem = accountingitem.length ? accountingitem[0] : {}
                            this.props.actions.setInputValue("accountingitem_id", args.value)
                            this.props.actions.setInputValue("itemdescription", args.text)
                            this.props.actions.setInputValue("accountingitem", accountingitem)
                            //this.props.actions.getAllocationData(args.value, this.props.state.user)
                            //this.props.actions.getCustomerTransactions(args.value)
                            //viewsearchbox = true
                            //cust_id = Number(args.value)
                        }
                        }
                        onClick={() =>
                            this.props.actions.getCashBookLookup('accountingitem','itemdescription','')
                        }
                        onFilter={(args) =>
                            this.props.actions.getCashBookLookup('accountingitem','itemdescription',args)
                        }
                    />
                </Col>
                <Col xl={5}> 
                    <label style={{paddingRight: '10px',paddingTop: '10px', fontSize: '16px', fontWeight: 'bold'}}>Select Period: </label><br/><br/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            localeText={{
                                start: 'Start Month',
                                end: 'End Month',
                            }}
                            slotProps={{ 
                                width: '300px',
                                textField: { 
                                    size: 'small',
                                    width: '300px',
                                    variant: 'outlined',
                                }
                            }}
                            sx={{width: '100%'}}
                            width={'100%'}
                            selectedSections={'month'}
                            slots={{ field: SingleInputDateRangeField }}
                            name="allowedRange"
                            onChange={(event, args) => {
                                if(event[0] && event[1]) {
                                    //console.log('eventx event', event)
                                    //console.log('eventx moment', moment(event[0].$d).format('YYYY-MM-DD'), moment(event[1].$d).format('YYYY-MM-DD'))
                                    ////console.log('eventx moment', event[0] ,  event[1] )
                                    
                                    this.props.actions.setInputValue("start_date", moment(event[0].$d).format('YYYY-MM-DD'))
                                    this.props.actions.setInputValue("end_date", moment(event[1].$d).format('YYYY-MM-DD'))
                                    //this.props.actions.getProfitlossData(moment(event[0].$d).format('YYYYMM'), moment(event[1].$d).format('YYYYMM'))
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xl={2} style={{paddingTop: '10px'}}>
                    <br/><br/>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.getCashBookReport(this.props.state.accountingitem_id,this.props.state.start_date,this.props.state.end_date)
                        }}
                        variant="contained"
                        className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                        >
                        {'GetReport'}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                <hr />
                </Col>
            </Row>
        </Container> 
    }

    getLayout() {
        //let accounttypes = _.uniq(_.map(this.props.state.report_data, 'accounttype'));
       
        ////console.log('rowsx accounttypes', accounttypes)
        return this.props.state.report_data
        ? this.props.state.report_data.length
        ? <Table size="small" aria-label="a dense table">
        <thead>
            <tr>
                <td className="text-uppercase bg-secondary" colSpan={8} style={{textAlign: 'right'}}>
                
                <Button
                        onClick={(event) => {
                            event.preventDefault()
                            //console.log('cashbookdata', JSON.stringify(this.props.state))
                            this.props.actions.getCashBookCSVReport(this.props.state)
                        }}
                        variant="contained"
                        className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                        >
                        {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {'CSV'}
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            //console.log('cashbookdata', JSON.stringify(this.props.state))
                            this.props.actions.getCashBookPDFReport(this.props.state)
                        }}
                        variant="contained"
                        className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                        >
                        {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {'PDF'}
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            //console.log('cashbookdata', JSON.stringify(this.props.state))
                            this.props.actions.getCashBookXLSReport(this.props.state)
                            //this.props.actions.getCashBookReport(this.props.state.accountingitem_id,this.props.state.start_date,this.props.state.end_date)
                        }}
                        variant="contained"
                        className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                        >
                        {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {'XLS'}
                    </Button>    
                </td>
            </tr> 
            <tr>
                <td className="text-uppercase bg-secondary" colSpan={8}><hr /></td>
            </tr> 
            <tr>
                <td className="text-uppercase bg-secondary" colSpan={8}>CASH BOOK REPORT {this.props.state.start_date} TO {this.props.state.end_date}</td>
            </tr> 
            <tr>
                <td className="text-uppercase bg-secondary" colSpan={8}>{moment().format('DD MMM YYYY')}</td>
            </tr> 
            <tr>
                <td className="text-uppercase bg-secondary" colSpan={8}>Account No: {this.props.state.accountingitem.accountnumber}</td>
            </tr> 
            <tr>
                <td className="text-uppercase bg-secondary" colSpan={8}>Description: {this.props.state.accountingitem.itemname}</td>
            </tr> 
            <tr>
                <td className="text-uppercase bg-secondary" colSpan={8}><hr /></td>
            </tr> 
            <tr>
                {
                    this.props.state.columns.map((col, inx) => {
                        return <th className="text-uppercase bg-secondary">{col.title}</th>
                    })
                }
            </tr> 
        </thead>
        <tbody>
            <tr style={{borderTop: '1px solid silver',borderBottom: '1px solid silver', fontSize: '16px', margin: '12px', padding: '12px'}}> 
                <td className="text-uppercase bg-secondary" colSpan={4}>Opening Balance - {this.props.state.start_date}</td>
                <td className="text-uppercase bg-secondary" colSpan={4} style={{textAlign: 'right'}}>{this.props.state.report_data[0].balance}</td>
            </tr> 
            {
                this.props.state.report_data.map(row => 
                {
                    return <tr> 
                        {
                            this.props.state.columns.map((col, inx) => {
                                return col.type === 'numeric' 
                                    ? <td className="text-uppercase bg-secondary" style={{textAlign: 'right'}}>{row[col.name]}</td> 
                                    : <td className="text-uppercase bg-secondary">{col.type === 'date' ? moment(row[col.name]).format('YYYY-MM-DD') : row[col.name]}</td>
                            })
                        }
                    </tr> 
                })
            }
        </tbody>
    </Table> 
    : <Table size="small" aria-label="a dense table">
    <thead>
        <tr>
            <td className="text-uppercase bg-secondary" colSpan={8}><hr /></td>
        </tr> 
        <tr>
            <td className="text-uppercase bg-secondary" colSpan={8}>CASH BOOK REPORT {this.props.state.start_date} TO {this.props.state.end_date}</td>
        </tr> 
        <tr>
            <td className="text-uppercase bg-secondary" colSpan={8}>{moment().format('DD MMM YYYY')}</td>
        </tr> 
        <tr>
            <td className="text-uppercase bg-secondary" colSpan={8}>
                    <strong>No Data Found for {this.props.state.itemdescription}</strong>
            </td>
        </tr> 
    </thead>
</Table> 
     : ''
    }
    
    render() {
        return [
            this.getDatePicket(),this.getLayout()
        ]
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.cashBook }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CASH_BOOK)