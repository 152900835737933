import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_TEAM_COMPONENT_CURRENT_TAB:
            return {
                ...state,
                tab: action.payload
            }
        case types.SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA:
            return {
                ...state,
                [action.payload.field]: action.payload.data
            }
        case types.TOGGLE_TEAM_COMPONENT_DELETE_WIN_STATUS:
            return {
                ...state,
                delete_record_id: action.payload
            }
        default:
            return state
    }
}