import * as types from './types'
import * as props from './props'

export default (state = props, action) => {
    switch (action.type) {
        case types.CLEAR_COMPONENT_RELATIONSHIPS:
            return {
                ...state,
                relationships: []
            }
        case types.READ_COMPONENT:
            return {
                ...state,
                name: action.payload
            }
        case types.READ_COMPONENT_SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            }
        case types.READ_COMPONENT_SET_DOCUMENTS:
            return {
                ...state,
                documents: action.payload
            }
        case types.READ_COMPONENT_SET_IMAGES:
            return {
                ...state,
                images: action.payload
            }
        case types.READ_COMPONENT_SET_RELATIONSHIPS:
            return {
                ...state,
                relationships: action.payload
            }
        case types.READ_COMPONENT_SET_TITLE:
            return {
                ...state,
                title: action.payload
            }
        case types.READ_COMPONENT_SET_VIEW:
            return {
                ...state,
                view: action.payload.view,
                grid: action.payload.grid
            }
        case types.READ_COMPONENT_SET_RELATED_COMPONENT_DATA:
            return {
                ...state,
                grids: !state.grids.filter(x => x.name === action.payload.related.name).length && action.payload.result.paging.rowCount > 0
                    ? [...state.grids, action.payload.related]
                    : state.grids
            }
        case types.READ_COMPONENT_TOGGLE_RELATED_COMPONENTS_MENU:
            return {
                ...state,
                relatedMenuIsVisible: !state.relatedMenuIsVisible
            }
        default:
            return state
    }
}