import * as types from './types'
import * as cognitoTypes from '../Cognito/types'
import * as messengerTypes from '../Messenger/types'
import get from 'lodash'

/* export const get = (url) => {
    return {
        type: types.GET,
        payload: {
            url: url
        }
    }
}

export const post = (url, body, headers) => {
    return {
        type: types.POST,
        payload: {
            url: url,
            body: body,
            headers: headers
        }
    }
} */

export const cancelRequests = () => {
    return {
        type: types.CANCEL_REQUESTS
    }
}

export const requesting = (uri, error) => {
    return {
        type: types.REQUESTING
    }
}

export const requestFailed = (uri, error) => {
    return (dispatch, getState) => {

        // //console.log('<<<<<<<<<<<<<<<<< requestFailed >>>>>>>>>>>>>>>>>>>>>')
        // //console.log(uri)
        // //console.log(error)
        if (error) {
            // if (error.message) {
            //     if (error.message === 'ajax error 0' && !error.request.headers.salt) {
            //         //console.log('COGNITO_USER_AUTHENTICATION_FAILED')
            //         dispatch({
            //             type: cognitoTypes.COGNITO_USER_AUTHENTICATION_FAILED,
            //             payload: {
            //                 error: {
            //                     code: "NotAuthorizedException",
            //                     message: "API Connection Error, Please Try In Few Minutes.",
            //                     name: "NotAuthorizedException"
            //                 },
            //                 result: {},
            //                 token: ''
            //             }
            //         })
            //     }
            // }
            if (error.response) {
                if (error.response.statusCode === 401) {
                    dispatch({
                        type: messengerTypes.UI_DISCONNECT_FROM_MESSENGER
                    })

                    dispatch({
                        type: types.CANCEL_REQUESTS
                    })

                    dispatch({
                        type: cognitoTypes.SIGN_OUT_COGNITO_USER
                    })
                }
            }


        }

        dispatch({
            type: types.REQUEST_FAILED,
            payload: {
                uri: uri,
                result: error
            }
        })
    }
}

export const requestSucceeded = (uri, response) => {
    return {
        type: types.REQUEST_SUCCEEDED,
        payload: {
            uri: uri,
            //result: response
        }
    }
}