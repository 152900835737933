export const retailprice = ''
export const discountamount = ''
export const rv = ''
export const interestrate = ''
export const sundrytotal = ''
export const oeminvoiceamount = ''
export const oeminvoicenumber = ''
export const oeminvoicedate = ''
export const tyrecpk = ''
export const maintcpk = ''
export const registrationnumber = ''
export const licensenumber = ''
export const openingodometer = ''
export const firstpaymentdate = ''
export const vinnumber = ''
export const enginenumber = ''
export const registrationdate = ''
export const licenceexpirydate = ''
export const regionactivationdate = ''
export const activationdate = ''
export const trackingdate = ''
export const trackingtype = ''
export const trackingtype_id = null
export const insurancecompany = ''
export const insurancecompany_id = null
export const _pos = []
export const _quote = []
export const _schedule1 = []
export const trackingtype_data = []
export const insurancecompany_data = []
export const licenceauthority_data = []
export const send_marketing = ''
export const marketingtype = ''
export const marketingtype_id = null
export const customerdata = {}
export const marketingtype_data = []

export const signed_vaf = false
export const signed_pod = false
export const signed_release_note = false
export const natis_registration_document = false

export const fields = {
    insurancecompany: {
        errorText: 'Insurance certificate is required',
        value: null,
        valid: false,
        empty: true,
        state: "getState().workflowPOContractActivation.insurancecompany !== 'Own Insurance'",
        states: "getState().upload.documents.filter(f => f.inx === 'insurancecertificate' && f.filename !== '').length > 0"
    },
    trackingtype: {
        errorText: 'Tracking certificate is required',
        value: '',
        valid: false,
        empty: true,
        state: "getState().workflowPOContractActivation.trackingtype !== 'Own Tracking'",
        states: "getState().upload.documents.filter(f => f.inx === 'trackingcertificate' && f.filename !== '').length > 0"
    },
    natis_registration_document: {
        errorText: 'eNatis registration document is required',
        value: null,
        valid: false,
        empty: true,
        state: "getState().workflowPOContractActivation.natis_registration_document",
    },
    signed_pod: {
        errorText: 'Signed POD is required',
        value: null,
        valid: false,
        empty: true,
        state: "getState().workflowPOContractActivation.signed_pod",
    },
    signed_vaf: {
        errorText: 'Signed VAF is required',
        value: null,
        valid: false,
        empty: true,
        state: "getState().workflowPOContractActivation.signed_vaf",
    },
    signed_release_note: {
        errorText: 'Signed release note is required',
        value: null,
        valid: false,
        empty: true,
        state: "getState().workflowPOContractActivation.signed_release_note",
    },
    insurancecompany_id: {
        state: 'getState().workflowPOContractActivation.insurancecompany',
        errorText: 'Insurance Company is required',
        value: false,
        valid: false,
        empty: true,
    },
    trackingtype_id: {
        state: 'getState().workflowPOContractActivation.trackingtype',
        errorText: 'Tracking Type is required',
        value: false,
        valid: false,
        empty: true,
    },
    trackingdate: {
        state: 'getState().workflowPOContractActivation.trackingdate',
        errorText: 'Tracking Date is required',
        value: false,
        valid: false,
        empty: true,
    },
    activationdate: {
        state: 'getState().workflowPOContractActivation.activationdate',
        errorText: 'Activation Date is required',
        value: false,
        valid: false,
        empty: true,
    },
    regionactivationdate: {
        state: 'getState().workflowPOContractActivation.regionactivationdate',
        errorText: 'Region Activation Date is required',
        value: false,
        valid: false,
        empty: true,
    },
    licenceexpirydate: {
        state: 'getState().workflowPOContractActivation.licenceexpirydate',
        errorText: 'License Expiry Date is required',
        value: false,
        valid: false,
        empty: true,
    },
    licenceauthority_id: {
        state: 'getState().workflowPOContractActivation.licenceauthority',
        errorText: 'License Authority is required',
        value: false,
        valid: false,
        empty: true,
    },
    registrationdate: {
        state: 'getState().workflowPOContractActivation.registrationdate',
        errorText: 'Registration Date is required',
        value: false,
        valid: false,
        empty: true,
    },
    enginenumber: {
        state: 'getState().workflowPOContractActivation.enginenumber',
        errorText: 'Engine Number is required',
        value: false,
        valid: false,
        empty: true,
    },
    vinnumber: {
        state: 'getState().workflowPOContractActivation.vinnumber',
        errorText: 'Vin Number is required',
        value: false,
        valid: false,
        empty: true,
    },
    openingodometer: {
        state: 'getState().workflowPOContractActivation.openingodometer',
        errorText: 'Opening Odometer is required',
        value: false,
        valid: false,
        empty: true,
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Opening Odometer'
            }
        ]
    },
    licensenumber: {
        state: 'getState().workflowPOContractActivation.licensenumber',
        errorText: 'Vehicle Register Number is required',
        value: false,
        valid: false,
        empty: true,
    },
    registrationnumber: {
        state: 'getState().workflowPOContractActivation.registrationnumber',
        errorText: 'Register Number is required',
        value: false,
        valid: false,
        empty: true,
    },
    maintcpk: {
        state: "getState().workflowPOContractActivation.maintcpk === '' ? true : getState().workflowPOContractActivation.maintcpk",
        errorText: 'Please input a valid Maintenance CPK',
        value: false,
        valid: false,
        empty: true,
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Maintenance CPK'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Maintenance CPK'
            }
        ]
    },
    tyrecpk: {
        state: "getState().workflowPOContractActivation.tyrecpk === '' ? true : getState().workflowPOContractActivation.tyrecpk",
        errorText: 'Please input a valid Maintenance CPK',
        value: false,
        valid: false,
        empty: true,
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Tyre CPK'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Tyre CPK'
            }
        ]
    },
    oeminvoicedate: {
        state: 'getState().workflowPOContractActivation.oeminvoicedate',
        errorText: 'OEM Invoice Date is required',
        value: false,
        valid: false,
        empty: true,
    },
    oeminvoicenumber: {
        state: 'getState().workflowPOContractActivation.oeminvoicenumber',
        errorText: 'OEM Invoice Number is required',
        value: false,
        valid: false,
        empty: true,
    },
    oeminvoiceamount: {
        state: 'getState().workflowPOContractActivation.oeminvoiceamount',
        errorText: 'Invoice Amount is required',
        value: false,
        valid: false,
        empty: true,
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Invoice Amount'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Invoice Amount'
            }
        ]
    },
    rv: {
        state: 'getState().workflowPOContractActivation.rv',
        errorText: 'Residual Value is required',
        value: false,
        valid: false,
        empty: true,
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Residual Value'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Residual Value'
            }
        ]
    },
    discountamount: {
        state: 'getState().workflowPOContractActivation.discountamount',
        errorText: 'Discount Amount is required',
        value: false,
        valid: false,
        empty: true,
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Discount Amount'
            }
        ]
    },
    retailprice: {
        state: 'getState().workflowPOContractActivation.retailprice',
        errorText: 'Retail Price is required',
        value: false,
        valid: false,
        empty: true,
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Retail Price'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Retail Price'
            }
        ]
    },
}