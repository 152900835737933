import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Card from '../../../controls/card'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types';
import * as validate from '../../../helpers/validator';

import Icon from '../../Icon/component'

import * as actions from './actions'
import * as styles from './styles'

import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"
import "react-table/react-table.css"

import AutoComplete from '../../../controls/autocomplete'
import Checkbox from '../../../controls/checkbox'
import TextField from '../../../controls/textField'

import * as compActions from '../../Components/actions'

var viewsearchbox = true, cust_id = 0


class CUSTOMERCONTACTCUSTOM extends Component {
    componentDidMount() {
        if (this.props.state.loading) {
            this.props.actions.getComponent('customercontactcustom')
            //this.props.actions.getAllTransactionsListing()
        }
    }

    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

       /*  commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        } */

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "remove") {
            return <div>< Button className={"global_button"} header={false}
                label={row.original.remove}
                onClick={(event) => {
                    var values = {
                        id: (row.original.contact_id),
                        field: 'remove',
                        value: row.original.remove == 'EDIT' ? 'SAVE' : 'EDIT'
                    }
                    if (row.original.remove == 'SAVE') {
                        var user = this.props.state.user
                        var contact = {
                            contact_id: row.original.contact_id,
                            customer_id: this.props.state.data.customer_id.input,
                            email: row.original.email,
                            telephonehome: row.original.telephone_home,
                            telephonework: row.original.telephone_work,
                            mobile: row.original.mobile
                        }
                        this.props.actions.updateContact(contact, user)
                    }
                    this.props.actions.setFieldValue(values, this.props.state.data.customercontactcustom_id.data)

                }}
            >{row.original.remove}</Button>{' '}{row.original.remove == 'EDIT' ? < Button className={"global_button"} variant="contained" header={false}
                label={'Unlink'}
                onClick={(event) => {
                    this.props.actions.removeContact(row.original.contact_id, this.props.state.data.customer_id.input)
                }}
            >{window.t.en('Unlink')}</Button> : < Button className={"global_button"} variant="contained" header={false}
                primary={true}
                label={'CANCEL'}
                onClick={(event) => {
                    var values = {
                        id: (row.original.contact_id),
                        field: 'remove',
                        value: 'EDIT'
                    }
                    this.props.actions.setFieldValue(values, this.props.state.data.customercontactcustom_id.data)
                }}
            >{window.t.en('CANCEL')}</Button>}</div>
        }
        else if (field.name == "email") {

            return row.original.remove == 'SAVE' ? <TextField
                variant="outlined"
                margin="normal"
                id={'txtreference'}
                value={row.original.email}
                onChange={(event, value) => {
                    var values = {
                        id: (row.original.contact_id),
                        field: 'email',
                        value: value
                    }
                    this.props.actions.setFieldValue(values, this.props.state.data.customercontactcustom_id.data)
                }}
            /> : row.original.email
        }
        else if (field.name == "mobile") {

            return row.original.remove == 'SAVE' ? <TextField
                variant="outlined"
                margin="normal"
                id={'txtreference'}
                value={row.original.mobile}
                onChange={(event, value) => {
                    var values = {
                        id: (row.original.contact_id),
                        field: 'mobile',
                        value: value
                    }
                    this.props.actions.setFieldValue(values, this.props.state.data.customercontactcustom_id.data)
                }}
            /> : row.original.mobile
        }
        else if (field.name == "telephone_work") {

            return row.original.remove == 'SAVE' ? <TextField
                variant="outlined"
                margin="normal"
                id={'txtreference'}
                value={row.original.telephone_work}
                onChange={(event, value) => {
                    var values = {
                        id: (row.original.contact_id),
                        field: 'telephone_work',
                        value: value
                    }
                    this.props.actions.setFieldValue(values, this.props.state.data.customercontactcustom_id.data)
                }}
            /> : row.original.telephone_work
        }
        else if (field.name == "telephone_home") {

            return row.original.remove == 'SAVE' ? <TextField
                variant="outlined"
                margin="normal"
                id={'txtreference'}
                value={row.original.telephone_home}
                onChange={(event, value) => {
                    var values = {
                        id: (row.original.contact_id),
                        field: 'telephone_home',
                        value: value
                    }
                    this.props.actions.setFieldValue(values, this.props.state.data.customercontactcustom_id.data)
                }}
            /> : row.original.telephone_home
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        /* if (!this.props.task && !surrogate) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        } */

        return columns
    }

    getGrid() {
        return <div><ReactTable
            key={'grid_' + this.component.name + '_' + this.component.component_id}
            data={this.props.state.data.customercontactcustom_id.data}
            columns={this.getColumns()}
            defaultPageSize={this.component.grid.paging.pageSize}
            manual
            onFetchData={
                (state) => {
                    //this.props.actions.getComponentGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.selectRow(this.component.name, rowInfo)

                        if (this.props.toggle) {
                            //this.props.actions.toggleInfoSheet()
                        }
                    },
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            onResizedChange={(newResized, event) => {
                //this.props.actions.resizeColumn(this.component.name, newResized)
            }}
            minRows={1}
            pages={this.component.grid.paging.pageCount}
            showPageJump={false}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
            <Container style={styles.whitediv}>
                <Row>
                    <Col xl={9}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Link/Create Contact:'}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'dll_debtor_id'}
                                    key={'dll_debtor_id'}
                                    title={'Select a contact...'}
                                    info={{
                                        show: false
                                    }}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    data={this.props.state.data.contact_id.data}
                                    onSelect={
                                        (chosenRequest) => {
                                            this.props.actions.setLookupValue("contact_id", chosenRequest.value, chosenRequest.text)
                                            //this.props.actions.getcontactlist(chosenRequest.value)
                                            viewsearchbox = true
                                            cust_id = Number(chosenRequest.value)
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getContactList('')
                                        }
                                    }
                                    onFilter={
                                        (search, dataSource, params) => {
                                            this.props.actions.getContactList(search)
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={3}>
                        < Button className={"global_button"} variant="contained"
                            label="Link"
                            primary={true}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.linkContact(this.props.state.data.contact_id.input, this.props.state.data.customer_id.input)
                            }}
                        >{window.t.en('Link')}</Button>
                        {/*< Button className={"global_button"} variant="contained"
                            label="New"
                            primary={false}
                            onClick={(event) => {
                                event.preventDefault()
                            }}
                        /> */}
                    </Col>
                </Row>
            </Container>
        </div>
    }


    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <Card
            title={window.t.en('Customer Contacts')}
            subtitle={window.t.en('Contacts')}
            content={<Container style={styles.whitediv}>
                <Row>
                    <Col xl={9}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Debtor:'}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'dll_debtor_id'}
                                    key={'dll_debtor_id'}
                                    title={'Select a debtor...'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    info={{
                                        show: false
                                    }}
                                    data={this.props.state.data.customer_id.data}
                                    onSelect={
                                        (chosenRequest, index) => {
                                            this.props.actions.setLookupValue("customer_id", chosenRequest.value, chosenRequest.text)
                                            this.props.actions.getallcontacts(chosenRequest.value)
                                            viewsearchbox = true
                                            cust_id = Number(chosenRequest.value)
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getCustomerList('')
                                        }
                                    }
                                    onFilter={
                                        (search, dataSource, params) => {
                                            this.props.actions.getCustomerList(search)
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br></br>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.getGrid()}
                    </Col>
                </Row>                
                <Row>
                    <Col xl={12}>
                        <br></br>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Contact Type:'}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'dll_debtor_id'}
                                    key={'dll_debtor_id'}
                                    title={'Select a contact...'}
                                    info={{
                                        show: false
                                    }}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    data={this.props.state.data.contacttype_id.data}
                                    onSelect={
                                        (chosenRequest, index) => {
                                            this.props.actions.setLookupValue("contacttype_id", chosenRequest.value, chosenRequest.text)
                                            //this.props.actions.getcontactlist(chosenRequest.value)
                                            viewsearchbox = true
                                            cust_id = Number(chosenRequest.value)
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getContactTypeList('')
                                        }
                                    }
                                    onFilter={
                                        (search, dataSource, params) => {
                                            this.props.actions.getContactTypeList(search)
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Title:'}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'dll_debtor_id'}
                                    key={'dll_debtor_id'}
                                    title={'Select a title...'}
                                    filter={AutoComplete.fuzzyFilter}
                                    info={{
                                        show: false
                                    }}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    data={this.props.state.data.title_id.data}
                                    onSelect={
                                        (chosenRequest, index) => {
                                            this.props.actions.setLookupValue("title_id", chosenRequest.value, chosenRequest.text)
                                            //this.props.actions.getcontactlist(chosenRequest.value)
                                            viewsearchbox = true
                                            cust_id = Number(chosenRequest.value)
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getTitleList('')
                                        }
                                    }
                                    onFilter={
                                        (search, dataSource, params) => {
                                            this.props.actions.getTitleList(search)
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'First Name:'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'txtreference'}
                                    title='First Name...'                                    
                                    fullWidth={true}
                                    value={this.props.state.data.firstname.input}
                                    onChange={(event, value) => {
                                        this.props.actions.setLookupValue("firstname", value, value)
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Last Name:'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'txtreference'}
                                    title='Last Name...'                                    
                                    fullWidth={true}
                                    value={this.props.state.data.lastname.input}
                                    onChange={(event, value) => {
                                        this.props.actions.setLookupValue("lastname", value, value)
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'E-Mail:'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'txtreference'}
                                    placeholder={'E-Mail...'}                                  
                                    fullWidth={true}
                                    value={this.props.state.data.email.input}
                                    onChange={(event, value) => {
                                        this.props.actions.setLookupValue("email", value, value)
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Mobile Number:'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'txtreference'}
                                    fullWidth={true}
                                    title='Mobile Number...'
                                    value={this.props.state.data.mobile.input}
                                    onChange={(event, value) => {
                                        this.props.actions.setLookupValue("mobile", value, value)
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Telephone(W):'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'txtreference'}
                                    fullWidth={true}
                                    title='Work Telephone Number...'
                                    value={this.props.state.data.telephone_work.input}
                                    onChange={(event, value) => {
                                        this.props.actions.setLookupValue("telephone_work", value, value)
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Telephone(H):'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'txtreference'}
                                    fullWidth={true}
                                    title='Home Telephone Number...'
                                    value={this.props.state.data.telephone_home.input}
                                    onChange={(event, value) => {
                                        this.props.actions.setLookupValue("telephone_home", value, value)
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={9}>

                    </Col>
                    <Col xl={3} style={styles.right}>
                        < Button className={"global_button"} variant="contained"
                            label="Save"
                            onClick={(event) => {
                                event.preventDefault()
                                var obj = {
                                    user: this.props.state.user,
                                    customer: this.props.state.data.customer_id.input,
                                    contacttype: this.props.state.data.contacttype_id ? this.props.state.data.contacttype_id.text : '',
                                    contacttype_id: this.props.state.data.contacttype_id ? Number(this.props.state.data.contacttype_id.input) : '',
                                    title: this.props.state.data.title_id ? this.props.state.data.title_id.text : '',
                                    title_id: this.props.state.data.title_id ? Number(this.props.state.data.title_id.input) : '',
                                    firstname: this.props.state.data.firstname ? this.props.state.data.firstname.input : '',
                                    lastname: this.props.state.data.lastname ? this.props.state.data.lastname.input : '',
                                    email: this.props.state.data.email ? this.props.state.data.email.input : '',
                                    mobile: this.props.state.data.mobile ? this.props.state.data.mobile.input : '',
                                    telephone_work: this.props.state.data.telephone_work ? this.props.state.data.telephone_work.input : '',
                                    telephone_home: this.props.state.data.telephone_home ? this.props.state.data.telephone_home.input : ''
                                }
                                this.props.actions.newContact(obj)
                            }}
                        >{window.t.en('Save')}</Button>
                        < Button className={"global_button"} variant="contained"
                            label="Cancel"
                            primary={true}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.setLookupValue("contacttype_id", '', '')
                                this.props.actions.setLookupValue("title_id", '', '')
                                this.props.actions.setLookupValue("firstname", '', '')
                                this.props.actions.setLookupValue("lastname", '', '')
                                this.props.actions.setLookupValue("email", '', '')
                                this.props.actions.setLookupValue("mobile", '', '')
                                this.props.actions.setLookupValue("telephone_work", '', '')
                                this.props.actions.setLookupValue("telephone_home", '', '')
                            }}
                        >{window.t.en('Cancel')}</Button>
                    </Col>
                </Row>
            </Container>} />

    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.customercontactcustom,
            user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERCONTACTCUSTOM)