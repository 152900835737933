import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
import * as appTypes from '../../App/types'

export const getGridData = () => {
    //console.log('getGridDatax get')
    return (dispatch) => {       
        dispatch({
            type: types.GET_BILLING_INVOICE_REFRESH_DATA,
            payload: {
                collection: 'billing_review_data',
                searchText: ''
            }
        })
    }
}

export const setGridData = (payload) => {
    //console.log('getGridDatax set', payload)
    return (dispatch) => {       
        dispatch({
            type: types.SET_BILLING_INVOICE_REFRESH_DATA,
            payload: payload.data
        })
    }
}

export const setValue = (prop, value) => {
    return (dispatch, getState) => {       
            dispatch({
                type: types.SET_BILLING_VALUE_CHANGE,
                payload: {
                    prop: prop,
                    value: value
                }
            })
    }
}

export const reset = () => {
    return (dispatch, getState) => {       
            dispatch({
                type: types.RESET_REFRESH_VALUES,
            })
    }
}
export const refreshing = (status) => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.BILLING_REFRESHING_STATUS,
            payload: status
        }) 
    }
}

export const exportBillingItems = (billingtracking_id) => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.EXPORT_BILLING_ITEMS,
            payload: {
                type: 'EXPORT_BILLING_ITEMS',
                billingtracking_id: billingtracking_id
            }
        }) 
    }
}

export const downloadBillingItems = (data) => {
    // return (dispatch, getState) => {       
    //     dispatch({
    //         type: types.EXPORT_BILLING_ITEMS,
    //         payload: {
    //             type: 'EXPORT_BILLING_ITEMS'
    //         }
    //     }) 
    // }

    

    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf], data.data.key)

    return (dispatch, getState) => {    
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Downloading billing export items....',
                overflowText: 'Downloading billing export items...',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const refreshBilling = (billingtracking_id) => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.REFRESH_BILLING,
            payload: {
                type: 'REFRESH_BILLING',
                billingtracking_id: billingtracking_id
            }
        })        
        dispatch(refreshing(true))     
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Refreshing billing please wait, we will notify you once done.',
                overflowText: 'Refreshing billing please wait, we will notify you once done',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const billingRefreshDon = () => {
    return (dispatch, getState) => {   
        //console.log('ENGINE_REFRESH_BILLINGx act')
        dispatch(refreshing(false)) 
        let billingtracking_id = getState()?.billingrefresh?.billingtracking?.[0]?.billingtracking_id 
        if(billingtracking_id)
        dispatch(getBillingTracking(billingtracking_id))   
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Error: System Notification',
                additionalText: 'Refreshing billing completed successfully.',
                overflowText: 'Refreshing billing completed successfully',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const previewInvoice = (billingrunview_id) => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.REFRESH_BILLING,
            payload: {
                type: 'GENRATE_PREVIEW_BILLING_INVOICE',
                billingrunview_id: billingrunview_id
            }
        })

           
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating invoice preview....',
                overflowText: 'Generating invoice preview...',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const downloadInvoice = (data) => {
    //console.log('downloadInvoice',data)
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())
    
    saveByteArray([data.data.content.Body], data.data.document)

    return (dispatch) => {     
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Preview invoice downloading....',
                overflowText: 'Preview invoice downloading...',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getBillingTracking = (billingtracking_id) => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.GET_BILLING_TRACKING,
            payload: billingtracking_id
        })
    }
}

export const setBillingTracking = (payload, billingtracking_id) => {
    //console.log('setBillingTracking payload', payload)
    //console.log('setBillingTracking billingtracking_id', billingtracking_id)
    return (dispatch, getState) => {       
        dispatch({
            type: types.SET_BILLING_TRACKING,
            payload: payload.data
        })
    }
}