import * as actions from "./actions";
import * as progressBarActions from "../../ProgressBar/actions";
import * as requestActions from "../../Request/actions";
import * as routerActions from "../../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../../Request/types";
import * as config from "../../../config";
import moment from "moment";


export const getLookupData = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(types.GET_COMPONENT_ACCESSORY_MASTER_CAPTURE_LOOKUP_DATA),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + `/components/${payload.component}/search${payload.query}`,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            }).pipe(
                mergeMap(results => [
                    actions.setLookupData(results.response,payload),
                ])
                , startWith(progressBarActions.progress(false))
                , catchError((error) => console.log(error))
                //, catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query?cognito _key=' + cognitoUser.username, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )


export const getSupplierAccessories = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(types.GET_COMPONENT_ACCESSORY_MASTER_SUPPLIER_ACCESSORIES_LIST),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + `/components/${payload.component}/search?supplier_id=${payload.supplier_id}`,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': payload.related
                }
            }).pipe(
                mergeMap(results => [
                    actions.setSupplierAccessories(results.response, payload),
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => console.log(error))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
