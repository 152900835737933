import moment from 'moment'
import * as types from './types'

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_REGISTRATION_CHANGE_FORM,
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_REGISTRATION_CHANGE_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_REGISTRATION_CHANGE_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
                query: `?${field}=${search}`
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        
        let data = response.data.map(x => {
            return {...x, text: x[payload.field], value: x[payload.component + '_id']}
        })
        dispatch(setInputValue(payload.component + '_data', data))
    }
}

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_REGISTRATION_CHANGE,
            payload: payload
        })
    }
}
