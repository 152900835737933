import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'

import Checkbox from '@mui/material/Checkbox'

import Icon from '../../Icon/component'
import Iframe from 'react-iframe'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import ImageUploader from '../../ImageUploader/component'

import * as styles from './styles'
import * as actions from './actions'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'

class DefleetSendRebill extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }


    SupplierLatter(data) {
        return this.props.state.supplierdocument
            ? <div>
                <Card initiallyExpanded={false}>
                    <CardHeader
                        actAsExpander={true}
                        showExpandableButton={true}
                        title={window.t.en('Dealer Letter - Labour and Discount')}
                        subtitle={window.t.en('data[0][0].suppliername')}
                        icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent expandable={true}>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{'data[0].suppliername'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{'data[0].suppliertype.text'}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{'data[0].registrationnumber'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{'data[0].vatnumber'}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Supplier Limit:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{'data[0].supplierage.suplierspendlimit'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Supplier Usage:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{'data[0].supplierage.suplierusage'}</Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row>

                                <Col xl={12}>
                                    {
                                        this.props.state.supplierdocument
                                            ? <Iframe url={this.props.state.supplierdocument}
                                                width="100%"
                                                height="300"
                                                id="supplierdocument"
                                                className="myClassname"
                                                display="initial"
                                                position="relative"
                                                allowFullScreen />
                                            : <Loading message={'Retrieving document...'} />
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }

    techPerson(data) {
        return data
            ? <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('Rebill Info')}
                        subtitle={window.t.en('Details of person who booked the repair. ')}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Type:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{'De-fleet'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{''}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{''}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Description:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{'De-fleet Repairs'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Done By:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                            </Row>
                            <Row>

                                <Col xl={3} style={styles.fieldLabel}>{'Quote Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{moment(data.quotedate.value).format("DD-MM-YYYY")}</Col>

                                <Col xl={3} style={styles.fieldLabel}>{'Contact Number:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                            </Row>
                            <Row>

                                <Col xl={3} style={styles.fieldLabel}>{'De-fleet Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{moment(data.incidentdate.value).format("DD-MM-YYYY")}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }

    techComments(data) {
        return data
            ? <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('Technical Manager Comments')}
                        subtitle={window.t.en('Techinical manage approval details. ')}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
                                <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }

    gmComments(data) {
        return data
            ? <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('General Manager Comments')}
                        subtitle={window.t.en('General manager approval details. ')}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
                                <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }

    ceoComments(data) {
        return data
            ? <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('CEO Comments')}
                        subtitle={window.t.en('ceo approval details. ')}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
                                <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }

    commentsView(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Contact List')}
                    subtitle={window.t.en('Please select contacts to recieve authorisation')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {/* <Row>
                            <Col xl={12}>
                                <Checkbox
                                    style={styles.checkbox}
                                    label={'Send Me a Copy of The Email'}
                                    checked={this.props.state.ccme}
                                    onCheck={
                                        (event, checked) => {
                                            this.props.actions.toggleCCME('ccme', checked)
                                        }
                                    } />
                            </Col>
                        </Row>
                                    <Row>
                                        <Col xl={12}><br/></Col>
                                    </Row> */}
                        <Row>
                            <Col xl={12}>
                                <AdhocComponentFieldInput
                                    title={window.t.en('customercontact')}
                                    title={window.t.en('Contact List')}
                                    description={'Please select contacts to send email to'}
                                    id={'customercontact'}
                                    parent={'customercontact'}
                                    parentFilters={[
                                        {
                                            field: 'customer_id',
                                            value: data.customer_id
                                        }
                                    ]}
                                    child={'contact'}
                                    childFilters={[
                                        {
                                            field: 'contacttype',
                                            value: ''
                                        }
                                    ]}
                                    extractChildData={true}
                                    adhocFieldInput={[ //assumes Parent component fields if extractChild is set to false
                                        'fullname', 'email', 'mobile', 'contacttype'
                                    ]}
                                    selectMode={true}
                                    addMode={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    renderView(data) {
        const documents = data.documents
        let step1 = data.step1.data
        let step3 = data.step3.data
        let step4 = data.step4
        let step5 = data.step5
        let step6 = data.step6
        let vehicle = step1.selectedVehicleDetails.data[0]
        let supplier = step3.selectedSupplierDetails.data[0]
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {
                        documents
                            ? <PdfViewer
                                key={'document_viewer_approval'}
                                name={'booking'}
                                workflow={'fml_customer_quote'}
                                task={'tsk_defleet_generate_rebill_document_and_send'}
                                documents={documents}
                                edit={false}
                                default_document={'Rebill'}
                                workflow_queue_data={this.props.workflow_queue_data}
                            />
                            : <Loading message={'Retrieving Booking Document...'} />
                    }
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.techPerson(step3)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.techComments(step4)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.gmComments(step5)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.ceoComments(step6)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.SupplierLatter(supplier)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.commentsView(vehicle.customer)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <ImageUploader prefix={'img'} id={'img'} />
                </Col>
            </Row>
        </Container>
    }

    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
            //console.log(workflow_queue_data)
        } catch (error) {

        }

        return workflow_queue_data
            ? this.renderView(workflow_queue_data)
            : 'Select vehicle...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetSendRebill
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetSendRebill)