import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import { Table } from "@mui/material";
import { Container, Row, Col } from 'react-grid-system'

import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import ViewCustomer from '../../controls/view_customer'
import PdfViewer from '../PDF/component'
import Loading from '../Loading/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowQuoteConfirmComponent extends Component {
    componentDidMount() {
        this.props.actions.setupWorkflowTaskInput(this.props.state, 'fml_quote', 'tsk_fml_quote_create_customer_quote')
    }

    getAccessories(data) {
        return <Card
                title={window.t.en('Accessory List')}
                subtitle={window.t.en('Selected vehicle accessory')}
                content={
                    <Table className="table" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            <th className="text-left ">Item Type</th>
                            <th className="text-left">Item Name</th>
                            <th className="text-left">Quantity</th>
                            <th className="text-right">Cost Price</th>
                            <th className="text-right">Selling Price</th>
                            <th className="text-right">Total Price</th>
                        </thead>
                        <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                        {
                            this.props.state.items.data.map((x, i) => {
                            return <tr key={'accessory_inx_' + i}>
                                    <td xl={2}>
                                        {x.itemtype}
                                    </td>
                                    <td xl={2}>
                                        {x[x.itemtype]}
                                    </td>
                                    <td xl={2} style={styles.numericFieldValues}>
                                        {parseFloat(x.quantity)}
                                    </td>
                                    <td xl={2} style={styles.numericFieldValues}>
                                        {parseFloat(x.costprice)}
                                    </td>
                                    <td xl={2} style={styles.numericFieldValues}>
                                        {parseFloat(x.sellingprice)}
                                    </td>
                                    <td xl={2} style={styles.numericFieldValues}>
                                        {parseFloat(x.totalprice)}
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                }
            />
    }

    getVehicles(data) {
        return <Card 
                title={window.t.en('Vehicle List')}
                subtitle={window.t.en('Selected vehicle for quote')}
                content={
                    <Table className="table" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            <th className="text-left ">Vehicle Make</th>
                            <th className="text-left">Vehicle Model</th>
                            <th className="text-left">Vehicle MMCode</th>
                            <th className="text-right">Retail Price</th>
                            <th className="text-right">Discount</th>
                            <th className="text-right">Vehicle Price</th>
                            <th className="text-right">RV %</th>
                            <th className="text-right">RV Amount</th>
                            <th className="text-right">Delivery</th>
                        </thead>
                        <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                        {
                            this.props.state.grid_data.map((x, i) => {
                            return  <tr key={'vehicle_inx_' + i}>
                                    <td xl={1}>
                                        {x.mmcode.make}
                                    </td>
                                    <td xl={2}>
                                        {x.mmcode.cdescription}
                                    </td>
                                    <td xl={1}>
                                        {x.mmcode_fktext}
                                    </td>
                                    <td xl={1} style={styles.numericFieldValues}>
                                        {parseFloat(x.retailprice)}
                                    </td>
                                    <td xl={1} style={styles.numericFieldValues}>
                                        {parseFloat(x.discount)}
                                    </td>
                                    <td xl={1} style={styles.numericFieldValues}>
                                        {parseFloat(x.vehicleprice)}
                                    </td>
                                    <td xl={1} style={styles.numericFieldValues}>
                                        {x.residualvalue}
                                    </td>
                                    <td xl={1} style={styles.numericFieldValues}>
                                        {x.rv_amount}
                                    </td>
                                    <td xl={1} style={styles.numericFieldValues}>
                                        {x.deliveryperiod + ' Days'}
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                }
            />
    }

    getContract() {
        let data = this.props.state
        return <Card 
                title={window.t.en('Quote Confirmation')}
                subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
                content={
                    <Fields nocard={true}
                        fields={[
                        {size: 4, label: 'Contract Type:', value: data.contractname},
                        {size: 4, label: 'Vehicle Category:', value: data.customerderivativecategory},
                        {size: 4, label: ' Monthly Distance:', value: data.distancepermonth},
                        {size: 4, label: 'Contract Period:', value: data.contractperiod},
                        {size: 4, label: 'Quote For:', value: data.quotefor},
                        {size: 4, label: 'RFQ Date:', value: data.quotedate},
                        {size: 4, label: 'Vehicle Color:', value: data.color},
                        {size: 4, label: 'Vehicle Location:', value: data.location},
                        {size: 4, label: 'Special Vehicle:', value: data.specialistvehicle},
                        {size: 4, label: 'Vehicle Group:', value: data.vehiclegroup},
                        {size: 4, label: 'Operating Terrain:', value: data.terrain},
                        {size: 4, label: 'Vehicle Usage:', value: data.usage},
                        {size: 12, label: 'Quotation Notes:', value: data.quotationnotes},
                        
                    ]}
                />
                }
            />
    }
    
    getCustomer() {
        const customer = this.props.state.customer
        let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        //console.log('Customer',customer)
        return <Card 
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                    <ViewCustomer id={customer.customer_id} nocard={true} min={true} />
                    // <Fields nocard={true}
                    //     fields={[
                    //         { size: 4, label: 'Customer Name:', value: customer.customername },
                    //         { size: 4, label: 'Cost Centre:', value: customer.costcentre },
                    //         { size: 4, label: 'Account Number:', value: customer.accountnumber },
                    //         { size: 4, label: 'Contact Person Name:', value: contact.fullname },
                    //         { size: 4, label: 'Contact Number:', value: contact.mobile },
                    //         { size: 4, label: 'Contact Email:', value: contact.email },
                    //     ]}
                    // />
                }
            />
    }

    getRFQDocument() {
        let documents = this.props.state.documents.documents
        return <Card 
                title={window.t.en('Customer RFQ')}
                subtitle={window.t.en('Uploaded Customer RFQ Document ')}
                content={
                    documents.length
                    ? <PdfViewer
                        key={'quotation_viewer_'}
                        documents={documents}
                        multiple={true}
                        height={500}
                        edit={false}
                    />
                    : <Loading message={'Retrieving Purchase Order document...'} />
                }
            />
    }
    getView(step) {

        return <div>
                {this.getContract()}
                {this.getCustomer()}
                {this.getVehicles()}
                {this.getRFQDocument()}
                {this.getAccessories()}
               
        </div>
    }

    render() {
        return (
            this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowQuoteHeader,
            items: {
                data: state.workflowQuoteItemGrid.data
            },
            documents: {
                ...state.upload
            },
            customer: {
                ...state.components['customer'].selected[0].original
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowQuoteConfirmComponent)