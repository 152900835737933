import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import { Card, CardHeader, CardContent } from '@mui/material'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import ReactTable from "react-table"
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import * as compActions from '../../Components/actions'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as styles from './styles'


import AutoComplete from '../../../controls/autocomplete'
import Checkbox from '../../../controls/checkbox'
import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'


var viewsearchbox = false, cust_id = 0
class CUSTOMERTRANSACTIONCUSTOM extends Component {
    componentDidMount() {
        if (this.props.state.loading) {
            this.props.actions.getComponent('customertransactioncustom')
            //this.props.actions.getAllTransactionsListing()
        }
    }

    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        /*  commands.push(
             <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                 onClick={
                     () => {
                         this.props.actions.setReadComponentView('view', this.component.name)
 
                         this.props.actions.changeDetailComponentTab(0)
                         this.props.actions.clearDetailComponentVisualisations()
 
                         this.props.actions.route(
                             '/view/' + this.component.name + '/' + id
                         )
                     }
                 }>
                 <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
             </IconButton>
         )
 
         if (this.props.commands) {
             commands.push(
                 <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                     onClick={
                         () => {
                             this.props.actions.setReadComponentView('form', this.component.name)
                             this.props.actions.route('/view/' + this.component.name + '/' + id)
                         }
                     }>
                     <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                 </IconButton>
             )
         } */

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "download") {
            if (row.value == "Invoice" || row.value == "Credit Note") {
                return < Button className={"global_button"} variant="contained" header={false}
                    label={'Download'}
                    onClick={(event) => {
                        if (row.value == "Invoice") {
                            //this.props.actions.getInvoicePDF(Number(row.original.customertransaction_id))
                        }
                        else {
                            //this.props.actions.getCreditNotePDF(Number(row.original.customertransaction_id))
                        }
                    }}
                >{window.t.en('Download')}</Button>
            }
        } else if (field.name == "querystatus") {
            return <Checkbox
                checked={row.original.querystatus ? true : false}
                onCheck={(event) => {
                    var values = {
                        id: row.original.customertransaction_id,
                        value: row.original.querystatus ? false : true,
                        field: "querystatus"
                    }
                    var data = this.props.state.data
                    var userdata = this.props.state.user
                    var filters = {
                        id: this.props.state.data.customer_id.input,
                        description: this.props.state.data.description.input,
                        amount: this.props.state.data.allocationamount.input,
                        date: this.props.state.data.customertransactionmonth.input,
                        type: this.props.state.data.transactiontype.input,
                        allocated: this.props.state.data.allocated.input
                    }
                    this.actions.setFieldValue(values, data, userdata, filters)
                }} />
        } else if (field.name == "queryresolved") {
            return <Checkbox
                checked={row.original.queryresolved ? true : false}
                onCheck={(event) => {
                    var values = {
                        id: row.original.customertransaction_id,
                        value: row.original.queryresolved ? false : true,
                        field: "queryresolved"
                    }
                    var data = this.props.state.data
                    var userdata = this.props.state.user
                    var filters = {
                        id: this.props.state.data.customer_id.input,
                        description: this.props.state.data.description.input,
                        amount: this.props.state.data.allocationamount.input,
                        date: this.props.state.data.customertransactionmonth.input,
                        type: this.props.state.data.transactiontype.input,
                        allocated: this.props.state.data.allocated.input
                    }
                    this.actions.setFieldValue(values, data, userdata, filters)
                }} />
        } else if (field.name == "querytype") {
            return row.original.querystatus && row.original.querytype ? <TextField
                variant="outlined"
                margin="normal"
                id={'txtreference'}
                disabled={true}
                title=''
                value={row.original.querytype}
                onChange={(event, value) => {
                }}
            /> : <AutoComplete
                info={{
                    show: false
                }}
                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                id={'dll_debtor_id'}
                key={'dll_debtor_id'}
                title={'Select a reason...'}
                filter={AutoComplete.fuzzyFilter}
                openOnFocus={true}
                fullWidth={true}
                dataSourceConfig={{
                    text: 'text',
                    value: 'value'
                }}
                data={this.props.state.data.querytype.data}
                onSelect={
                    (chosenRequest, index) => {
                        this.props.actions.setLookupValue("querytype", chosenRequest.value, chosenRequest.text)
                        var values = {
                            id: row.original.customertransaction_id,
                            value: chosenRequest.value ? chosenRequest.value : 0,
                            field: "querytype_id"
                        }
                        var data = this.props.state.data
                        var userdata = this.props.state.user
                        var filters = {
                            id: this.props.state.data.customer_id.input,
                            description: this.props.state.data.description.input,
                            amount: this.props.state.data.allocationamount.input,
                            date: this.props.state.data.customertransactionmonth.input,
                            type: this.props.state.data.transactiontype.input,
                            allocated: this.props.state.data.allocated.input
                        }
                        this.actions.setFieldValue(values, data, userdata, filters)

                        /*  var values = {
                             id: row.original.customertransaction_id,
                             value: chosenRequest.text ? chosenRequest.text : 0,
                             field: "querytype"
                         }
                         var data = this.props.state.data
                         var userdata = this.props.state.user
                         var filters = {
                             id: this.props.state.data.customer_id.input,
                             description: this.props.state.data.description.input,
                             amount: this.props.state.data.allocationamount.input,
                             date: this.props.state.data.customertransactionmonth.input,
                             type: this.props.state.data.transactiontype.input,
                             allocated: this.props.state.data.allocated.input
                         }
                         this.actions.setFieldValue(values, data, userdata, filters) */
                    }
                }
                onClick={
                    (event) => {
                        this.props.actions.getQueryTypeList('')
                    }
                }
                onFilter={
                    (search, dataSource, params) => {
                        this.props.actions.getQueryTypeList(search)
                    }
                }
            />
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        /* if (!this.props.task && !surrogate) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        } */

        return columns
    }

    getGrid() {
        return <div>< Button className={"global_button"}
            icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'refresh'} />}
            onClick={(event) => {
                event.preventDefault()
                var filters = {
                    id: this.props.state.data.customer_id.input,
                    description: this.props.state.data.description.input,
                    amount: this.props.state.data.allocationamount.input,
                    date: this.props.state.data.customertransactionmonth.input,
                    type: this.props.state.data.transactiontype.input,
                    allocated: this.props.state.data.allocated.input
                }
                this.props.actions.getAllTransactionsListing(filters)
            }}
        ><Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'refresh'} /></Button>< Button className={"global_button"}
            secondary={true}
            label="Excel Export"
            icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
            onClick={(event) => {
                this.props.actions.excelExport(this.component)
            }}
        ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Excel Export</Button><ReactTable
            key={'grid_' + this.component.name + '_' + this.component.component_id}
            data={this.props.state.data.customertransactioncustom_id.data}
            columns={this.getColumns()}
            defaultPageSize={this.component.grid.paging.pageSize}
            manual
            onFetchData={
                (state) => {
                    //this.props.actions.getComponentGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.selectRow(this.component.name, rowInfo)

                        if (this.props.toggle) {
                            //this.props.actions.toggleInfoSheet()
                        }
                    },
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            onResizedChange={(newResized, event) => {
                //this.props.actions.resizeColumn(this.component.name, newResized)
            }}
            minRows={1}
            pages={this.component.grid.paging.pageCount}
            showPageJump={false}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable></div>
    }


    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <Card
            title={window.t.en('Customer Transactions')}
            subtitle={window.t.en('Transactions')}
            content={<Container style={styles.whitediv}>
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Debtor:'}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    info={{
                                        show: false
                                    }}
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'dll_debtor_id'}
                                    key={'dll_debtor_id'}
                                    title={'Select a debtor...'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    data={this.props.state.data.customer_id.data}
                                    onSelect={
                                        (chosenRequest, index) => {
                                            this.props.actions.setLookupValue("customer_id", chosenRequest.value, chosenRequest.text)
                                            this.props.actions.getAllTransactions(chosenRequest.value)
                                            viewsearchbox = true
                                            cust_id = Number(chosenRequest.value)
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getCustomerList('')
                                        }
                                    }
                                    onFilter={
                                        (search, dataSource, params) => {
                                            this.props.actions.getCustomerList(search)
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>   <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Description:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                id={'txtreference'}
                                fullWidth={true}
                                title={'Description or Reference or Invoice No...'}
                                value={this.props.state.data.description.input}
                                onChange={(event, value) => {
                                    this.props.actions.setLookupValue("description", value, value)
                                }}
                            />
                        </div>
                    </div>

                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Allocated:'}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    info={{
                                        show: false
                                    }}
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'dll_debtor_id'}
                                    key={'dll_debtor_id'}
                                    title={'Select a allocation...'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    data={[{
                                        text: 'No', value: 'No'
                                    }, {
                                        text: 'Yes',
                                        value: 'Yes'
                                    }]}
                                    onSelect={
                                        (chosenRequest, index) => {
                                            this.props.actions.setLookupValue("allocated", chosenRequest.value, chosenRequest.text)
                                        }
                                    }
                                    onClick={
                                        (event) => {

                                        }
                                    }
                                    onFilter={
                                        (search, dataSource, params) => {

                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Date:'}</div>
                            <div style={styles.fieldContent}>
                                <DateControl
                                    variant="outlined"
                                    margin="normal"
                                    id={'txtreference'}
                                    title={'Key in Month/Invoice Date...'}
                                    value={this.props.state.data.customertransactionmonth.input}
                                    onSelect={(value) => {
                                        this.props.actions.setLookupValue("customertransactionmonth", value, value)
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>

                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Type:'}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    info={{
                                        show: false
                                    }}
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'dll_debtor_id'}
                                    key={'dll_debtor_id'}
                                    title={'Select a transaction type...'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    data={[{
                                        text: 'Invoice',
                                        value: 'IN'
                                    }, {
                                        text: 'Credit Note',
                                        value: 'CR'
                                    }, {
                                        text: 'Interest',
                                        value: 'IT'
                                    }, {
                                        text: 'Receipt',
                                        value: 'RC'
                                    }, {
                                        text: 'Journals',
                                        value: 'Journal'
                                    }, {
                                        text: 'Reversals',
                                        value: 'Rever'
                                    }]}
                                    onSelect={
                                        (chosenRequest, index) => {
                                            this.props.actions.setLookupValue("transactiontype", chosenRequest.value, chosenRequest.text)
                                        }
                                    }
                                    onClick={
                                        (event) => {

                                        }
                                    }
                                    onFilter={
                                        (search, dataSource, params) => {

                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Amount:'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'txtreference'}
                                    fullWidth={true}
                                    title={'Debit or Credit or Allocation Amount...'}
                                    value={this.props.state.data.allocationamount.input}
                                    onChange={(event, value) => {
                                        this.props.actions.setLookupValue("allocationamount", value, value)
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xl={12}>
                        {this.getGrid()}
                    </Col>
                </Row>
            </Container>} />
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.customertransactioncustom,
            user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERTRANSACTIONCUSTOM)