import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import TextField from '@mui/material/TextField'
import Iframe from 'react-iframe'

import Checkbox from '@mui/material/Checkbox'
import POBookingItems from '../POBookingItems/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import Icon from '../../Icon/component'
import ImageUploader from '../../ImageUploader/component'

import ViewSupplier from '../../../controls/view_supplier'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewMaintenance from '../../../controls/view_maintenance'
import Card from '../../../controls/card'


import * as styles from './styles'
import * as actions from './actions'

class POBookingApprovalOps extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.setValue('issupplier_correct', false)
    }


    commentsView() {
        return <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomments'}
                                hintText={'Enter comments'}
                                fullWidth={true}
                                label={'Enter approval comments'}
                                variant="outlined" 
                                multiLine={true}
                                value={this.props.state.comments}
                                onChange={(event, value) => {
                                    this.props.actions.setValue('comments', event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
        </div>
    }

    getLayout() {
        return  <div>
                <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
                <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
                <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
                <Card
                    title={'Authorisation Approval'}
                    subtitle={'Enter comments below'}
                    content={this.commentsView()}
                />
            </div>
    }

    render() {
        
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        
        return this.data
            ?  this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingApprovalOps
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingApprovalOps)