import * as types from './types'

export const displayDetailDashboard = (key) => {
    return {
        type: types.SHOW_DETAIL_DASHBOARD,
        payload: key
    }
}

export const changeDetailComponentTab = (value) => {
    return {
        type: types.CHANGE_DETAIL_COMPONENT_TAB,
        payload: value
    }
}

export const clearDetailComponentVisualisations = () => {
    return {
        type: types.CLEAR_DETAIL_COMPONENT_VISUALISATIONS
    }
}