import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.CARD_COMPONENT_TOGGLE_VIEW_STATE:
            return {
                ...state,
                tile_view: action.payload
            }
        case types.CARD_COMPONENT_TOGGLE_CREATE_STATE:
            return {
                ...state,
                create_mode: action.payload
            }
        case types.TOGGLE_CARD_STATUS_WINDOW:
            return {
                ...state,
                toggle_card_status: action.payload
            }
        case types.CHANGE_CARD_COMPONENT_CURRENT_TAB:
            return {
                ...state,
                tab: action.payload
            }
        case types.CHANGE_CARD_COMPONENT_CURRENT_MANADATE_TAB:
            return {
                ...state,
                mandatetab: action.payload
            }
        case types.TOGGLE_CARD_MANAGEMENT_NOTIFICATION_SECTION:
            return {
                ...state,
                cardnotificationtitle: action.payload
            }
        case types.TOGGLE_CARD_TRANSACTION_VIEW:
            return {
                ...state,
                cardtransactionview: action.payload
            }
        case types.SET_CARD_MANAGEMENT_TRANSACTION_LIST_DATA:
            return {
                ...state,
                card_transactions: action.payload
            }
        case types.TOGGLE_CARD_SETTINGS_VIEW:
            return {
                ...state,
                cardsettingswin: action.payload.cardsettingswin,
                cardsettingswinel: action.payload.cardsettingswinel
            }
            case types.SET_NEW_CARD_STATUS:
                return {
                    ...state,
                    toggle_card_status: action.payload.win_status,
                    update_card_status: action.payload.card_status,
                    cardstatuswin: false,
                    cardstatuswinel: null
                }
                case types.TOGGLE_CARD_STATUS_WIN:
                    return {
                        ...state,
                        cardstatuswin: action.payload.cardstatuswin,
                        cardstatuswinel: action.payload.cardstatuswinel
                    }
                case types.TOGGLE_CARD_NOTIFICATION_WIN:
                    return {
                        ...state,
                        cardnotificationwin: action.payload.cardnotificationwin,
                        cardnotificationwinel: action.payload.cardnotificationwinel
                    }
                case types.TOGGLE_CARD_MORE_WIN:
                    return {
                        ...state,
                        cardmorewin: action.payload.cardmorewin,
                        cardmorewinel: action.payload.cardmorewinel
                    }
        case types.SET_COMPONENT_CARD_MANAGEMENT_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        case types.SET_CARD_COMPONENT_INPUT_VAL:
            return {
                ...state,
                input: {
                    ...state.input,
                    [action.payload.display]: action.payload.input
                }
            }
        // case types.SET_COMPONENT_CARD_MANAGEMENT_DATA:
        //     return {
        //         ...state,
        //         cards: action.payload
        //     }
        default:
            return state
    }
}