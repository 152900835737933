import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_CUSTOMER_LIST_DATA:
            return {
                ...state,
                customer_list: action.payload
            }
        case types.SET_COMPONENT_CONTRACT_TYPE_LIST_DATA:
            return {
                ...state,
                contracttype_list: action.payload
            }
        case types.SET_COMPONENT_PRODUCT_TYPE_LIST_DATA:
            return {
                ...state,
                producttype_list: action.payload
            }
        case types.SET_SELECTED_CUSTOMER_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        default:
            return state
    }
}