// import React, { Component } from 'react'
// import { Card, CardHeader } from '@mui/material'
// import CircularProgress from '@mui/material/CircularProgress'

// class Loading extends Component {
//     render() {
//         return (
//             <div >
//                 <CardHeader 
//                     title={window.t.en(this.props.title ? this.props.title : 'Loading...')}
//                     subheader={this.props.message ? this.props.message : 'Your content is being rendered, please wait.'}
//                     avatar={<CircularProgress color="secondary" />} 
//                 />
//             </div>
//         )
//     }
// }

// export default Loading
import React from 'react';
// import { makeStyles } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//   },
// });

export default function LinearBuffer(props) {
  // const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={props.className}>
      {/* <LinearProgress />  variant="buffer" value={progress} valueBuffer={buffer} /> */}
        {
            props.message
            ? <div>
            <Fade color="light"
              in={true}
              style={{
                transitionDelay: '800ms',
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
            <h5>{props.message}</h5>
          </div>
            : <div>
            <Fade color="light"
              in={true}
              style={{
                transitionDelay: '800ms',
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
            <h5>{props.message}</h5>
          </div>
        }
      
    </div>
  );
}
