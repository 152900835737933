import * as props from './props'
import * as types from './types'
import * as redirectTypes from '../Redirect/types'

export default (state = props, action) => {
    switch (action.type) {
        case redirectTypes.ROUTE:
            return {
                ...state,
                infoSheetIsOpen: false
            }
        case types.TOGGLE_INFO_SHEET:
            return {
                ...state,
                infoSheetIsOpen: !state.infoSheetIsOpen
            }
        default:
            return state
    }
}