
import styled from 'styled-components'
import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const emptyStyle = {
    fontSize: '30px'
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 2,
    verticalAlign: 'left'
}


export const action = {
    fontSize: '20px',
    color: colours.red[500]
}
 
export const searchIcon = {
    display: 'table-cell',
    width: '100px',
    float: 'right',
    textAlign: 'right'
}
export const searchInput = {
    display: 'table-cell',
    //width: '265px',
    float: 'right',
    textAlign: 'right'
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'left',
}

export const lineitems = {
    marginBottom: '1px',
    fontSize: '16px',
    textAlign: 'left',
}

export const actionHoverColor = {
    color: colours.red[500]
}

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}
export const right = {
    textAlign: 'right',
    float: 'right',
}

export const gridOptionsIcon = {

}


export const verticalMiddle = {
    verticalAlign: 'middle',
}

export const alternating = {
    paddingTop: '5px',
    backgroundColor: '#dedede'
}
export const nonealternating = {
    backgroundColor: 'transaparent'
}


export const fieldContainer = {
    display: 'table',
    padding: '5px'
}

export const fieldContainerRow = {
    display: 'table',
    borderBottom: '1px solid black'
}

export const center = {
    margin: 'auto',
    width: '100%',
}
export const centerContainer = {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
    width: '450px',
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10
}
export const fieldTitleLeft = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10
}
export const fieldTitleLeftB = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10,
    fontWeight: 'bold'
}
export const rowTitle = {
    width: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const fieldContentTot = {
    display: 'table-cell',
    width: '65%'
}

export const textBox = {
    width: '100%'
}

export const error = {
    color: colours.red[600]
}

export const valid = {
    color: colours.green[500]
}

export const subheaders = {
    backgroundColor: "#D1E0E6",
    fontSize: '16px',
    textAlign: 'left',
    paddingTop: '10px',
    paddingBottom: '10px',
    margin: '0px',
}

export const nomargin = {
    margin: '0px',
}

export const delButton = {
    height: '25px',
    color: '#000',
    backgroundColor: '#fff',
    padding: 0,
    marginTop: 16
}


export const actionIcon = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000',
}


export const box1 = {
    width: '100%',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left', 
    content: "",
    clear: 'both',
    display: 'table',
}


export const tdValue = {
    width: '20%',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'right',
}
export const tdField = {
    textAlign: 'left',
}


export const trField = {
    width: '100%',
    borderTop: '1px solid gray',
    padding: 2,
}

export const Label = styled.label`

font-size: 25px;

`