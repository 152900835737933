import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"

import { Table } from '@mui/material'
import Icon from '../../Icon/component'

import { Container, Row, Col } from 'react-grid-system'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
// import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'

import TextField from '../../../controls/textField'
import AutoComplete from '../../../controls/autocomplete'
import CardField from '../../../controls/cardfields'

import * as actions from './actions'
import * as styles from './styles'
import Card from '../../../controls/card'

class QuoteItems extends Component {
    componentDidMount() {
        this.props.actions.clearGridItems()
        this.props.actions.setupComponent()
        // this.props.actions.setLabourRate(false, this.props.inx)
        // this.props.actions.toggleLabourRate(false, this.props.inx)
        // try {
        //     const quote = this.props.workflow_quote_data
        //     if (quote.length) {
        //         this.props.actions.loadQuoteData(quote)
        //     }
        // } catch (err) { }
    }

    getFooter(field) {
        // const total = field === 'Discount'
        //     ? _.sumBy(this.props.state.grid, (d) => { return parseFloat((d.DiscountAmount) ? d.DiscountAmount.toString().replace(/,/gi, '') : 0) })
        //     : _.sumBy(this.props.state.grid, (d) => { return parseFloat((d[field]) ? d[field].toString().replace(/,/gi, '') : 0) })
        // return (field == 'Amount' || field == 'VAT' || field == 'Savings' || field == 'Discount')
        //     ?
        //     <span>
        //         {
        //             parseFloat(total.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        //         }
        //     </span>
        //     :
        //     <span>
        //         <strong></strong>{" "}
        //     </span>
    }
    getGrid() {
        //console.log('exceptionsx', this.props.state.exceptions)
        return <div>
            <Dialog
                open={this.props.state.toggle_labourrate ? this.props.state.toggle_labourrate.value : false}
                onClose={(event) => {
                    event.preventDefault()
                    this.props.actions.toggleLabourRate(false, this.props.inx)
                }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
                <div className="text-center p-5">
                    <h4 className="font-weight-bold mt-4">
                        LABOUR RATES              </h4>
                    <p className="mb-0 font-size-lg text-muted">

                        {this.props.state.labourrate_exceeded && this.props.state.labourrate_exceeded.value ? 'SLA labour rate has been exceeded.' : 'Important! Please adhere to SLA labour rate:' + this.props.labour_rate}
                    </p>
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleLabourRate(false, this.props.inx)
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Ok</span>
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={this.props.state.toggle_exceptions}
                onClose={(event) => {
                    event.preventDefault()
                    this.props.actions.toggleExceptions(false)
                }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
                <div className="text-center p-5">
                    <h4 className="font-weight-bold mt-4">
                        POSSIBLE EXCEPTIONS              </h4>
                    <p className="mb-0 font-size-lg text-muted">
                        {this.props.state.toggle_exceptions ? ('There has been a recurrence of this item: ' + this.props.state.exception_item) : ''}
                    </p>
                    {
                        this.props.state.exceptions
                            ? typeof this.props.state.exceptions === 'array'
                                ? this.props.state.exceptions.map(x => {
                                    return <p className="mb-0 font-size-lg text-muted">{'Task No: ' + x.task_number} {x.suppliername} {x.component_type}</p>
                                })
                                : ''
                            : ''
                    }
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleExceptions(false)
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Ok</span>
                        </Button>
                    </div>
                </div>
            </Dialog>
            <br />
            <Table className="table table-hover text-nowrap mb-0" style={{ width: '100% !important' }}>
                <colgroup>
                    {!this.props.read_only ? <col width="25px" /> : ''}
                    <col width="200px" />
                    <col width="300px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                </colgroup>
                <thead>
                    <tr>
                        {!this.props.read_only ? <th style={{ width: '25px !important' }}>

                            <IconButton key={'command_add'} style={styles.iconButton} iconStyle={styles.action}
                                onClick={
                                    () => {
                                        this.props.actions.addQuotationBlankRow(this.auto_rebill, this.props.px)
                                        this.props.actions.updateCounter()
                                    }
                                }>
                                <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'add'} />
                            </IconButton>
                        </th> : ''}
                        <th style={{ width: '30% !important' }}>Item Category</th>
                        <th style={{ width: '30% !important' }}>Description</th>
                        <th style={{ width: '5% !important' }}>Quantity</th>
                        <th style={{ width: '5% !important' }}>Unit Price </th>
                        <th style={{ width: '5% !important' }}>Total</th>
                        <th style={{ width: '5% !important' }}>Savings</th>
                        <th style={{ width: '5% !important' }}>Discount(%)</th>
                        <th style={{ width: '5% !important' }}>Discount</th>
                        <th style={{ width: '5% !important' }}>VAT</th>
                        <th style={{ width: '5% !important' }}>Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.state.quotations.filter(x => x.px === this.props.px).length ? this.props.state.quotations.filter(x => x.px === this.props.px).map((x, i) => {
                            let row = {
                                index: i
                            }
                            //console.log('x.tyre_data',x.tyre_data)
                            return <><tr style={{ fontWeight: 'normal', fontSize: '14px' }} key={`quote-item-row-${i}-${this.props.state.gridCounter}`}>
                                {
                                    !this.props.read_only ? <td>
                                        <IconButton key={'command_remove_' + i} style={styles.iconButton} iconStyle={styles.action}
                                            onClick={
                                                () => {
                                                    this.props.actions.deleteQuotationRow(x.id)
                                                    this.props.actions.updateCounter()
                                                }
                                            }>
                                            <Icon istyle={{ fontSize: '22px', color: 'red' }} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                    </td> : ''
                                }
                                <td><div style={{ marginTop: '20px' }}>
                                    {
                                        this.props.read_only
                                            ? <TextField
                                                key={'key_field_' + row.index + '_' + this.props.state.counter + '_Class'}
                                                id={'id_field_' + row.index + '_' + this.props.state.counter + '_Class'}
                                                //label={field.name}
                                                variant="outlined"
                                                size="small"
                                                margin="normal"
                                                fullWidth={true}
                                                disabled={this.props.read_only}
                                                value={x.Class}
                                            />
                                            : <AutoComplete
                                                key={'field_' + row.index + '_' + this.props.state.counter + '_Class'}
                                                id={'id_field_' + row.index + '_' + this.props.state.counter + '_Class'}
                                                name={'id_field_' + row.index + '_' + this.props.state.counter + '_Class'}
                                                title={'Item Category'}
                                                info={{
                                                    show: false
                                                }}
                                                value={x.Class}
                                                width={100}
                                                selectOnFocus
                                                margin="normal"
                                                fieldtext={'maintenanceclass'}
                                                fieldvalue={'maintenanceclass_id'}
                                                // altRendering
                                                data={this.props.state.maintenanceclass_data}
                                                onClick={() => {
                                                    this.props.actions.getLookupValues('maintenanceclass', 'maintenanceclass', row.index, '', '', this.props.vattable)
                                                    this.props.actions.setQuotationItemInputValue(x.px, row.index, 'maintenanceclass', this.props.vattable)
                                                }}
                                                onFilter={(search) => {
                                                    if (search) {
                                                        this.props.actions.setQuotationItemInputValue(x.id, 'Class', search, this.props.vattable)
                                                        this.props.actions.setQuotationItemInputValue(x.px, row.index, 'maintenanceclass', this.props.vattable)
                                                    } else {
                                                        this.props.actions.setQuotationItemInputValue(x.id, 'Description', '', this.props.vattable)
                                                        this.props.actions.setQuotationItemInputValue(x.id, 'Description_Id', null, this.props.vattable)
                                                        this.props.actions.setQuotationItemInputValue(x.id, 'Class_Id', null, this.props.vattable)
                                                        this.props.actions.setQuotationItemInputValue(x.id, 'Class', '', this.props.vattable)
                                                        this.props.actions.updateCounter()
                                                    }
                                                }}
                                                onSelect={(args) => {
                                                    this.props.actions.setQuotationItemInputValue(x.id, 'Class_Id', args.value, this.props.vattable)
                                                    this.props.actions.setQuotationItemInputValue(x.id, 'Class', args.text, this.props.vattable)
                                                }}
                                            />
                                    }
                                </div></td>
                                <td>
                                    <div style={{ marginTop: '20px' }}>
                                        {
                                            this.props.read_only
                                                ? <TextField
                                                    key={'key_field_' + row.index + '_' + this.props.state.counter + '_Description'}
                                                    id={'id_field_' + row.index + '_' + this.props.state.counter + '_Description'}
                                                    //label={field.name}
                                                    variant="outlined"
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth={true}
                                                    disabled={this.props.read_only}
                                                    value={x.Description}
                                                />
                                                : <AutoComplete
                                                    key={'field' + row.index + '_' + this.props.state.counter + '_Description'}
                                                    id={'id_field_' + row.index + '_' + this.props.state.counter + '_Description'}
                                                    name={'id_field_' + row.index + '_' + this.props.state.counter + '_Description'}
                                                    title={'Description'}
                                                    info={{
                                                        show: false
                                                    }}
                                                    //size="small"
                                                    value={x.Description}
                                                    altRendering
                                                    // displayfields={['text']}
                                                    fieldtext={'text'}
                                                    fieldvalue={'value'}
                                                    //fullWidth={true}
                                                    // selectOnFocus
                                                    //clearOnBlur
                                                    disabled={this.props.read_only}
                                                    width={100}
                                                    margin="normal"
                                                    data={this.props.state.maintenanceitem_data}

                                                    onClick={() => {
                                                        //console.log('maintenanceitemx',x)
                                                        this.props.actions.getLookupValues('maintenanceitem', 'maintenanceitem', row.index, 'maintenanceclass_id.' + x.Class_Id, '', this.props.vattable)
                                                    }}
                                                    onFilter={(search) => {
                                                        if (search) {
                                                            const data = this.props.state.maintenanceitem_data
                                                            const item = data.find(i => i.maintenanceitem === search)

                                                            this.props.actions.setRelatedRowItem(search, x.id)
                                                            this.props.actions.checkExceptions(search);
                                                            let maintenanceitemx = null

                                                            if (x.Class === 'Tyres') {
                                                                maintenanceitemx = this.props.state.maintenanceitem_data.filter(x => x.text === search)
                                                                maintenanceitemx = maintenanceitemx.length ? maintenanceitemx[0] : null
                                                                if (maintenanceitemx) {
                                                                    this.props.actions.setQuotationItemInputValue(x.id, 'Price', maintenanceitemx.sellingprice, this.props.vattable)
                                                                    this.props.actions.setQuotationItemInputValue(x.id, 'Quantity', 1, this.props.vattable)
                                                                    this.props.actions.getLookupValues('tyre', 'tyredescription', x.id, 'tyredescription.' + search, '', this.props.vattable)
                                                                }
                                                            }

                                                            this.props.actions.setQuotationItemInputValue(x.id, 'maintenanceitemx', maintenanceitemx, this.props.vattable)
                                                            this.props.actions.setQuotationItemInputValue(x.id, 'Description', search, this.props.vattable)
                                                            if (item) this.props.actions.setQuotationItemInputValue(x.id, 'Description_Id', item.maintenanceitem_id, this.props.vattable)
                                                        } else {
                                                            this.props.actions.setQuotationItemInputValue(x.id, 'Description', '', this.props.vattable)
                                                            this.props.actions.setQuotationItemInputValue(x.id, 'Description_Id', null, this.props.vattable)
                                                        }
                                                        this.props.actions.updateCounter()
                                                    }}

                                                    onSelect={(args) => {
                                                        this.props.actions.setRelatedRowItem(args.text, x.id)
                                                        this.props.actions.checkExceptions(args.text);
                                                        let maintenanceitemx = null

                                                        if (x.Class === 'Tyres') {
                                                            maintenanceitemx = this.props.state.maintenanceitem_data.filter(x => x.value === args.value)
                                                            maintenanceitemx = maintenanceitemx.length ? maintenanceitemx[0] : null
                                                            if (maintenanceitemx) {
                                                                this.props.actions.setQuotationItemInputValue(x.id, 'Price', maintenanceitemx.sellingprice, this.props.vattable)
                                                                this.props.actions.setQuotationItemInputValue(x.id, 'Quantity', 1, this.props.vattable)
                                                                this.props.actions.getLookupValues('tyre', 'tyredescription', x.id, 'tyredescription.' + args.text, '', this.props.vattable)
                                                            }
                                                        }

                                                        this.props.actions.setQuotationItemInputValue(x.id, 'maintenanceitemx', maintenanceitemx, this.props.vattable)
                                                        this.props.actions.setQuotationItemInputValue(x.id, 'Description', args.text, this.props.vattable)
                                                        this.props.actions.setQuotationItemInputValue(x.id, 'Description_Id', args.value, this.props.vattable)
                                                        this.props.actions.updateCounter()
                                                    }}
                                                />
                                        }
                                    </div></td>
                                <td>
                                    <TextField
                                        key={'key_field_' + row.index + '_' + this.props.state.counter + '_Quantity'}
                                        id={'id_field_' + row.index + '_' + this.props.state.counter + '_Quantity'}
                                        //label={field.name}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        //fullWidth={true}
                                        width={100}
                                        //style={!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? styles.redbox : styles.textBox : styles.textBox}
                                        disabled={this.props.read_only}
                                        value={x.Quantity}
                                        onChange={
                                            (event, value) => {
                                                let _val = isNaN(event.target.value) ? 0 : event.target.value
                                                this.props.actions.setQuotationItemInputValue(x.id, 'Quantity', _val, this.props.vattable) //row.index, _val, this.vattable, this.auto_rebill_tyres)


                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                //     //this.props.actions.setLabourRate(true)
                                                //     // this.props.actions.toggleLabourRate(true)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                            }
                                        }
                                        onBlur={
                                            (event, value) => {
                                                let _val = event.target.value === '' || event.target.value === null || event.target.value === undefined ? 0 : isNaN(event.target.value) ? 0 : event.target.value

                                                this.props.actions.setQuotationItemInputValue(x.id, 'Quantity', _val, this.props.vattable)

                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                                // if (this.props.state.grid && this.props.state.grid.filter(x => x.labourrate_exceeded == true).length > 0 || (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false)) {
                                                //     this.props.actions.setLabourRate(true, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(true, this.props.inx)
                                                // }
                                                // else {
                                                //     this.props.actions.setLabourRate(false, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(false, this.props.inx)
                                                // }
                                            }
                                        }
                                    /></td>
                                <td>
                                    <TextField
                                        key={'key_field_' + row.index + '_' + this.props.state.counter + '_Price'}
                                        id={'id_field_' + row.index + '_' + this.props.state.counter + '_Price'}
                                        //label={field.name}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        //fullWidth={true}
                                        width={100}
                                        //style={!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? styles.redbox : styles.textBox : styles.textBox}
                                        disabled={this.props.read_only}
                                        value={x.Price}
                                        onChange={
                                            (event, value) => {
                                                let _val = isNaN(event.target.value) ? 0 : event.target.value
                                                this.props.actions.setQuotationItemInputValue(x.id, 'Price', _val, this.props.vattable)
                                                //this.props.actions.setQuotationItemInputValue(this.props.inx, 'Price', row.index, _val, this.vattable, this.auto_rebill_tyres)


                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                            }
                                        }
                                        onBlur={
                                            (event, value) => {
                                                let _val = event.target.value === '' || event.target.value === null || event.target.value === undefined ? 0 : isNaN(event.target.value) ? 0 : event.target.value
                                                //this.props.actions.setInputValue(this.props.inx, 'Price', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'Price', _val, this.props.vattable)
                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                                // if (this.props.state.grid && this.props.state.grid.filter(x => x.labourrate_exceeded == true).length > 0 || (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false)) {
                                                //     this.props.actions.setLabourRate(true, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(true, this.props.inx)
                                                // }
                                                // else {
                                                //     this.props.actions.setLabourRate(false, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(false, this.props.inx)
                                                // }
                                            }
                                        }
                                    /></td>
                                <td>
                                    <TextField
                                        key={'key_field_' + row.index + '_' + this.props.state.counter + '_Total'}
                                        id={'id_field_' + row.index + '_' + this.props.state.counter + '_Total'}
                                        //label={field.name}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        //fullWidth={true}
                                        width={100}
                                        //style={!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? styles.redbox : styles.textBox : styles.textBox}
                                        disabled={true}
                                        value={x.Total}
                                        onChange={
                                            (event, value) => {
                                                let _val = isNaN(event.target.value) ? 0 : event.target.value
                                                //.props.actions.setInputValue(this.props.inx, 'Total', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'Total', _val, this.props.vattable)

                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                //     //this.props.actions.setLabourRate(true)
                                                //     // this.props.actions.toggleLabourRate(true)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                            }
                                        }
                                        onBlur={
                                            (event, value) => {
                                                let _val = event.target.value === '' || event.target.value === null || event.target.value === undefined ? 0 : isNaN(event.target.value) ? 0 : event.target.value
                                                //this.props.actions.setInputValue(this.props.inx, 'Total', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'Total', _val, this.props.vattable)
                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                                // if (this.props.state.grid && this.props.state.grid.filter(x => x.labourrate_exceeded == true).length > 0 || (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false)) {
                                                //     this.props.actions.setLabourRate(true, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(true, this.props.inx)
                                                // }
                                                // else {
                                                //     this.props.actions.setLabourRate(false, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(false, this.props.inx)
                                                // }
                                            }
                                        }
                                    /></td>
                                <td>
                                    <TextField
                                        key={'key_field_' + row.index + '_' + this.props.state.counter + '_Savings'}
                                        id={'id_field_' + row.index + '_' + this.props.state.counter + '_Savings'}
                                        //label={field.name}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        //fullWidth={true}
                                        width={100}
                                        // style={!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? styles.redbox : styles.textBox : styles.textBox}
                                        disabled={this.props.read_only}
                                        value={x.Savings}
                                        onChange={
                                            (event, value) => {
                                                let _val = isNaN(event.target.value) ? 0 : event.target.value
                                                // this.props.actions.setInputValue(this.props.inx, 'Savings', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'Savings', _val, this.props.vattable)

                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                //     //this.props.actions.setLabourRate(true)
                                                //     // this.props.actions.toggleLabourRate(true)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                            }
                                        }
                                        onBlur={
                                            (event, value) => {
                                                let _val = event.target.value === '' || event.target.value === null || event.target.value === undefined ? 0 : isNaN(event.target.value) ? 0 : event.target.value
                                                //this.props.actions.setInputValue(this.props.inx, 'Savings', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'Savings', _val, this.props.vattable)
                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                                // if (this.props.state.grid && this.props.state.grid.filter(x => x.labourrate_exceeded == true).length > 0 || (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false)) {
                                                //     this.props.actions.setLabourRate(true, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(true, this.props.inx)
                                                // }
                                                // else {
                                                //     this.props.actions.setLabourRate(false, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(false, this.props.inx)
                                                // }
                                            }
                                        }
                                    /></td>
                                <td>
                                    <TextField
                                        key={'key_field_' + row.index + '_' + this.props.state.counter + '_Discount'}
                                        id={'id_field_' + row.index + '_' + this.props.state.counter + '_Discount'}
                                        //label={field.name}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        //fullWidth={true}
                                        width={100}
                                        //style={!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? styles.redbox : styles.textBox : styles.textBox}
                                        disabled={this.props.read_only}
                                        value={x.Discount}
                                        onChange={
                                            (event, value) => {
                                                let _val = isNaN(event.target.value) ? 0 : event.target.value
                                                //this.props.actions.setInputValue(this.props.inx, 'Discount', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'Discount', _val, this.props.vattable)

                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                //     //this.props.actions.setLabourRate(true)
                                                //     // this.props.actions.toggleLabourRate(true)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                            }
                                        }
                                        onBlur={
                                            (event, value) => {
                                                let _val = event.target.value === '' || event.target.value === null || event.target.value === undefined ? 0 : isNaN(event.target.value) ? 0 : event.target.value
                                                // this.props.actions.setInputValue(this.props.inx, 'Discount', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'Discount', _val, this.props.vattable) // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                                // if (this.props.state.grid && this.props.state.grid.filter(x => x.labourrate_exceeded == true).length > 0 || (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false)) {
                                                //     this.props.actions.setLabourRate(true, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(true, this.props.inx)
                                                // }
                                                // else {
                                                //     this.props.actions.setLabourRate(false, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(false, this.props.inx)
                                                // }
                                            }
                                        }
                                    /></td>
                                <td>
                                    <TextField
                                        key={'key_field_' + row.index + '_' + this.props.state.counter + '_Discount_Amount'}
                                        id={'id_field_' + row.index + '_' + this.props.state.counter + '_Discount_Amount'}
                                        //label={field.name}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        //fullWidth={true}
                                        width={100}
                                        //style={!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? styles.redbox : styles.textBox : styles.textBox}
                                        disabled={this.props.read_only}
                                        value={x.DiscountAmount}
                                        onChange={
                                            (event, value) => {
                                                let _val = isNaN(event.target.value) ? 0 : event.target.value
                                                //this.props.actions.setInputValue(this.props.inx, 'DiscountAmount', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'DiscountAmount', _val, this.props.vattable)

                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                //     //this.props.actions.setLabourRate(true)
                                                //     // this.props.actions.toggleLabourRate(true)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                            }
                                        }
                                        onBlur={
                                            (event, value) => {
                                                let _val = event.target.value === '' || event.target.value === null || event.target.value === undefined ? 0 : isNaN(event.target.value) ? 0 : event.target.value
                                                //this.props.actions.setInputValue(this.props.inx, 'DiscountAmount', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'DiscountAmount', _val, this.props.vattable)
                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                                // if (this.props.state.grid && this.props.state.grid.filter(x => x.labourrate_exceeded == true).length > 0 || (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false)) {
                                                //     this.props.actions.setLabourRate(true, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(true, this.props.inx)
                                                // }
                                                // else {
                                                //     this.props.actions.setLabourRate(false, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(false, this.props.inx)
                                                // }
                                            }
                                        }
                                    /></td>
                                <td>
                                    <TextField
                                        key={'key_field_' + row.index + '_' + this.props.state.counter + '_VAT'}
                                        id={'id_field_' + row.index + '_' + this.props.state.counter + '_VAT'}
                                        //label={field.name}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        //fullWidth={true}
                                        width={100}
                                        //style={!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? styles.redbox : styles.textBox : styles.textBox}
                                        disabled={true}
                                        value={x.VAT}
                                        onChange={
                                            (event, value) => {
                                                let _val = isNaN(event.target.value) ? 0 : event.target.value
                                                //this.props.actions.setInputValue(this.props.inx, 'VAT', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'VAT', _val, this.props.vattable)

                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                //     //this.props.actions.setLabourRate(true)
                                                //     // this.props.actions.toggleLabourRate(true)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                            }
                                        }
                                        onBlur={
                                            (event, value) => {
                                                let _val = event.target.value === '' || event.target.value === null || event.target.value === undefined ? 0 : isNaN(event.target.value) ? 0 : event.target.value
                                                //this.props.actions.setInputValue(this.props.inx, 'VAT', row.index, _val, this.vattable, this.auto_rebill_tyres)

                                                this.props.actions.setQuotationItemInputValue(x.id, 'VAT', _val, this.props.vattable)

                                                // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, true, this.vattable, false)
                                                // }
                                                // else {
                                                //     this.props.actions.setInputValue(this.props.inx, 'labourrate_exceeded', row.index, false, this.vattable, false)
                                                // }
                                                // if (this.props.state.grid && this.props.state.grid.filter(x => x.labourrate_exceeded == true).length > 0 || (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false)) {
                                                //     this.props.actions.setLabourRate(true, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(true, this.props.inx)
                                                // }
                                                // else {
                                                //     this.props.actions.setLabourRate(false, this.props.inx)
                                                //     this.props.actions.toggleLabourRate(false, this.props.inx)
                                                // }
                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <TextField
                                        key={'key_field_' + row.index + '_' + this.props.state.counter + '_Amount'}
                                        id={'id_field_' + row.index + '_' + this.props.state.counter + '_Amount'}
                                        //label={field.name}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        //fullWidth={true}
                                        width={100}
                                        //style={!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? styles.redbox : styles.textBox : styles.textBox}
                                        disabled={true}
                                        value={x.Amount}
                                        onChange={
                                            (event, value) => {
                                            }
                                        }
                                        onBlur={
                                            (event, value) => {

                                            }
                                        }
                                    />
                                </td>
                            </tr>
                                {
                                    x.Class === 'Tyres' && x.tyre_data
                                        ? x.tyre_data.length
                                            ? x.tyre_data.map(x => {

                                                return <tr style={{ fontWeight: 'normal', fontSize: '14px', background: '#e5f9ed' }}>
                                                    <td colSpan={3} style={{ background: '#fff' }}>
                                                        Desc <b>{x.tyredescription}</b>
                                                    </td>
                                                    <td colSpan={3} style={{ background: '#fff' }}>
                                                        Make <b>{x.tyremake_fktext}</b>
                                                    </td>
                                                    <td colSpan={3} style={{ background: '#fff' }}>
                                                        Price <b>{x.retailprice}</b>
                                                    </td>
                                                    <td colSpan={3} style={{ background: '#fff' }}>
                                                        Disc <b>{x.discount_1}</b>
                                                    </td>
                                                </tr>
                                            })
                                            : ''
                                        : ''
                                }
                                {
                                    x.history
                                        ? x.history.transaction_max.totalamount || x.history.transaction_min.totalamount
                                            ? <tr style={{ fontWeight: 'normal', fontSize: '14px', background: '#fff' }}>
                                                <td>
                                                </td>
                                                <td colSpan={3} style={{ background: '#fff' }}>
                                                    Item <b>{x.Description}</b> spend history
                                                </td>
                                                <td colSpan={3} style={{ background: '#e5f9ed' }}>
                                                    Maximum Spend: {x.history.transaction_max.totalamount} At {x.history.transaction_max.maintenancetransaction ? x.history.transaction_max.maintenancetransaction.supplier ? x.history.transaction_max.maintenancetransaction.supplier.suppliername : '' : ''}<br />
                                                    Date: {x.history.transaction_min.maintenancetransaction ? moment(x.history.transaction_min.maintenancetransaction.authdate).format('YYYY-MM-DD') : ''}
                                                </td>
                                                <td colSpan={3} style={{ background: '#fffbf2' }}>
                                                    Minimum Spend: {x.history.transaction_min.totalamount} At {x.history.transaction_max.maintenancetransaction ? x.history.transaction_max.maintenancetransaction.supplier ? x.history.transaction_max.maintenancetransaction.supplier.suppliername : '' : ''}<br />
                                                    Date: {x.history.transaction_min.maintenancetransaction ? moment(x.history.transaction_min.maintenancetransaction.authdate).format('YYYY-MM-DD') : ''}
                                                </td>
                                            </tr>
                                            : ''
                                        : ''
                                }
                            </>
                        }) : 'No Items'
                    }
                    <tr style={{ background: '#DFDFDF' }}>
                        {
                            !this.props.read_only ? <td style={{ paddingLeft: '20px', fontWeight: '500', width: '25px !important' }}> </td> : ''}
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '150px !important' }}> </td>
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '250px !important' }}> </td>
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '5% !important' }}> </td>
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '5% !important' }}> </td>
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '5% !important' }}>{_.sumBy(this.props.state.quotations.filter(x => x.px === this.props.px), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '5% !important' }}>{_.sumBy(this.props.state.quotations.filter(x => x.px === this.props.px), (d) => { return parseFloat((d['Savings']) ? d['Savings'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '5% !important' }}> </td>
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '5% !important' }}>{_.sumBy(this.props.state.quotations.filter(x => x.px === this.props.px), (d) => { return parseFloat((d['DiscountAmount']) ? d['DiscountAmount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '5% !important' }}>{_.sumBy(this.props.state.quotations.filter(x => x.px === this.props.px), (d) => { return parseFloat((d['VAT']) ? d['VAT'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{ paddingLeft: '20px', fontWeight: '500', width: '5% !important' }}>{_.sumBy(this.props.state.quotations.filter(x => x.px === this.props.px), (d) => { return parseFloat((d['Amount']) ? d['Amount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                </tbody>
            </Table>
        </div >
    }

    render() {
        this.serviceinterval = this.props.serviceinterval
        this.mmcode = this.props.mmcode
        this.data = this.props.workflow_queue_data
        this.vattable = this.props.vattable
        this.auto_rebill = false
        this.auto_rebill_tyres = false
        this.labour_rate = this.props.labour_rate ? this.props.labour_rate : 0
        return <div>{this.props.state.quotations ? this.getGrid() : 'Loading Index'}</div>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.quoteItems,
            capture: {
                ...state.defleetUploadQuotes
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteItems)