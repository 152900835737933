import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'


import TextField from '../../../controls/textField'
import CheckBox from '../../../controls/checkbox'
import Fields from '../../../controls/cardfields'
import Card from '../../../controls/card'
import PdfViewer from '../../PDF/component'
import Table from '@mui/material/Table'
import Upload from '../../Upload/component'
import ViewCustomer from '../../../controls/view_customer'
import AutoComplete from '../../../controls/autocomplete'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowSTRActivation extends Component {
    componentDidMount() {
        this.props.actions.resetForm()
        //.props.actions.setValidationState(this.props.state.validation)
        let data = this.props.workflow_queue_data.context_data.data.data.data[0]
        //console.log('componentWillMount data', data)
        if(data) {
            this.props.actions.setInputValue({ prop: 'ponumber', value: data.step4.ponumber })
            this.props.actions.setInputValue({ prop: 'registration', value: data.step4.vehicle.licenseplateno })
            this.props.actions.setInputValue({ prop: 'odometer', value: data.step4.vehicle.yardlist_dometer })
            this.props.actions.setInputValue({ prop: 'podate', value: moment().format('YYYY-MM-DD') })
            this.props.actions.setInputValue({ prop: 'collectiondate', value: moment().format('YYYY-MM-DD') })
            this.props.actions.setInputValue({ prop: 'activationdate', value: moment().format('YYYY-MM-DD') })
        }
        this.props.actions.getCustomerData(data.step1.data.customer_id) 
    }

    componentWillUnmount() {
        this.props.actions.resetForm()
    }

    getQuotation() {
        let data = this.data
        //console.log('WorkflowReview getQuotation', data)
        return <div><Fields 
                fields={[
                    {size: 4, label: 'RFQ Date:', value: data.step1.data.rfqdate},
                    {size: 4, label: 'RFQ Time:', value:  data.step1.data.rfqtime},
                    {size: 4, label: 'Quote For', value: data.step1.additional ? 'Replace Vehicle' : 'Additional Vehicle'},
                    {size: 4, label: 'Replace Vehicle', value: data.step1.data.vehicle, dispay: data.step1.data.additional},
                    {size: 4, label: 'Pick Up Address', value: data.step1.data.collection_address.text},
                    {size: 4, label: 'Delivery Address', value: data.step1.data.delivery_address.text},
                    {size: 4, label: 'Created By:', value:  data.step1.user.firstname + ' ' + data.step1.user.lastname},
                    {size: 4, label: 'Date:', value:  data.step1.datestamp},
                    {size: 4, label: 'Comments:', value:  'Capture Customer Quote'},
                    {size: 4, label: '', value: ''},
                    {size: 4, label: 'Review By:', value:  data.step2.user.firstname + ' ' + data.step2.user.lastname },
                    {size: 4, label: 'Date:', value:  data.step2.datestamp},
                    {size: 4, label: 'Comments:', value:  data.step2.comments},
                    {size: 4, label: 'Approve By:', value: data.step3.user.firstname +' ' + data.step3.user.lastname  },
                    {size: 4, label: 'Date:', value:  data.step3.datestamp},
                    {size: 4, label: 'Comments:', value:  data.step3.comments},
                    {size: 4, label: 'Bookout By Name:', value: data.step4.user.firstname + ' ' + data.step4.user.lastname } ,
                    {size: 4, label: 'Date:', value:  data.step4.datestamp},
                    {size: 4, label: 'Comments:', value:  data.step4.comments}
                ]}
            />
        </div>
    }     
    getContract() {
        let data = this.data.step1.data
        //console.log('WorkflowReview getContract', data)
        return<Fields 
            fields={[
                {size: 4, label: 'Vehicle Category:', value: data.strratecard},
                {size: 4, label: 'Transmission :', value: data.transmission},
                {size: 4, label: 'Contact Period:', value: data.period},
                {size: 4, label: 'Monthly Inclusive:', value: data.distance},
                {size: 4, label: 'Start Date:', value: data.startdate},
                {size: 4, label: 'End Date:', value: data.enddate},
                {size: 4, label: 'Contract Unit:', value: data.contractunit},
                {size: 4, label: 'Waivers:', value: data.waiver}
            ]}
        />
    } 

    getFinance() {
        let data = this.data.step1.data
        //console.log('WorkflowReview getFinance', data)
        return <Fields 
            fields={[
                {size: 4, label: 'Monthly Rental:', value: data.str_monthly_rental},
                {size: 4, label: 'Accessories Total:', value: data.accessory_tot_monthly},
                {size: 4, label: 'Waiver Total:', value: data.waiver_tot},
                {size: 4, label: 'Sub Total:', value: data.monthly_excl},
                {size: 4, label: 'VAT:', value: data.monthly_vat},
                {size: 4, label: 'Total Rental:', value: data.monthly_incl},
            ]}
        />
    } 
    
    getAccessory() {
        let data = this.data.step1.data
        //console.log('WorkflowReview getAccessory', data)
        return <Table className="table table-hover text-nowrap mb-0">
            <thead>
                <tr>
                    <th>Accessory</th>
                    <th>Accessory Cost </th>
                    <th>Rate Per Month </th>
                </tr>
            </thead>
            <tbody>
                {
                    data.accessory_list.map(x => {
                    return <tr>
                        <td>{x.accessory_name}</td>
                        <td>{x.totalprice}</td>
                        <td>{x.ratepermonth}</td>
                        </tr>
                    })
                }
            </tbody>
        </Table>
    }
   

    getUpload() {
        return <Upload
            id={'upload_str'}
            prefix={'STR_Capture_Att'}
            header={false}
        />
    }
    
    getDocument() {
        //console.log('WorkflowReview getDocument', this.data)
        return this.data.documents.length
            ? <PdfViewer
                key={'document_viewer_' + this.data.documents.length}
                documents={this.data.documents}
                preview={'preview'}
            />
            : 'Retrieving Documents'
    }

    getView(data) {
        this.data = data //this.props.workflow_queue_data.context_data.data.data.data[0]
        return  this.data ? <div>
             <ViewCustomer
                key={data.step1.data.customer_id}
                id={data.step1.data.customer_id }
            />
            <Card
                title={('Adhoc Rental Quotation Confirm')}
                subtitle={('Ensure that all values are captured correctly before your proceed ')}
                content={this.getQuotation()}
            />      
            <Card
                title={('Contract Details')}
                subtitle={('Contract parameters ')}
                content={this.getContract()}
            /> 
            <Card
                title={('Accessories')}
                subtitle={('Contract accessories list')}
                content={this.getAccessory()}
            />
            <Card
                title={('Finance and Rental')}
                subtitle={('Contract finance details ')}
                content={this.getFinance()}
            /> 
            <Card
                title={('Documents')}
                subtitle={('Attached documets')}
                content={this.getDocument()}
            />
            <Card
                title={('Upload')}
                subtitle={('Upload booking supporting documents')}
                content={this.getUpload()}
            />
            <Card
                title={('Contract Activation')}
                subtitle={('Adhoc Rental Activation')}
                content={this.getComments()}
            />
        </div>
        : 'Please waiting...'
    }

    getComments() {
        return <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4}>
                                <TextField
                                    fullWidth={true}
                                    variant="outlined"
                                    margin="normal"
                                    id={'txt_registration'}
                                    label= {'Vehicle Registration'}
                                    hintText={'Enter Vehicle Registration'}
                                    value={this.props.state.registration}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'registration', value: event.target.value })
                                    }}
                                />
                    </Col>
                    <Col xl={4}>
                                <TextField
                                    fullWidth={true}
                                    variant="outlined"
                                    margin="normal"
                                    id={'txt_odometer'}
                                    label={'Vehicle Odometer'}
                                    hintText={'Enter Vehicle Odometer'}
                                    value={this.props.state.odometer}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'odometer', value: event.target.value })
                                    }}
                                />
                    </Col>
                    <Col xl={4}>
                                <TextField
                                    fullWidth={true}
                                    variant="outlined"
                                    margin="normal"
                                    id={'txt_ponumber'}
                                    label={'Customer PO Number'}
                                    hintText={'Enter Customer PO Number'}
                                    value={this.props.state.ponumber}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'ponumber', value: event.target.value })
                                    }}
                                />
                    </Col>
                        <Col xl={4}>
                                <TextField
                                    fullWidth={true}
                                        variant="outlined"
                                        margin="normal"
                                        type="date"
                                        id={'dt_startdate'}
                                        hintText='Select Start Date'
                                        container='inline'
                                        mode='landscape'
                                        label ={'Customer PO Date'}
                                        value={this.props.state.podate}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({ prop: 'podate', value: event.target.value })
                                        }}
                                        minDate={new Date()}
                                    />
                        </Col>
                       {/*  <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Contract End Date:'}</div>
                                <div style={styles.fieldContent}>
                                <TextField
                                    fullWidth={true}
                                        variant="outlined"
                                        margin="normal"
                                        type="date"
                                        id={'dt_terminationdate'}
                                        hintText='Select End Date'
                                        container='inline'
                                        mode='landscape'
                                        value={this.props.state.terminationdate}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({ prop: 'terminationdate', value: event.target.value })
                                        }}
                                        minDate={new Date()}
                                    />
                                </div>
                            </div>
                        </Col> */}
                        <Col xl={4}>
                                <TextField
                                    fullWidth={true}
                                        variant="outlined"
                                        margin="normal"
                                        type="date"
                                        id={'dt_start_date'}
                                        hintText='Select Collection Date'
                                        container='inline'
                                        mode='landscape'
                                        label={'Collection Date'}
                                        value={this.props.state.collectiondate}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({ prop: 'activationdate', value: event.target.value })
                                            this.props.actions.setInputValue({ prop: 'collectiondate', value: event.target.value })
                                        }}
                                        minDate={new Date()}
                                    />
                        </Col>
                        {/* <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Activation Date:'}</div>
                                <div style={styles.fieldContent}>
                                <TextField
                                    fullWidth={true}
                                        variant="outlined"
                                        margin="normal"
                                        type="date"
                                        id={'dt_end_date'}
                                        hintText='Select Activation Date'
                                        container='inline'
                                        mode='landscape'
                                        value={this.props.state.activationdate}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({ prop: 'activationdate', value: event.target.value })
                                        }}
                                        minDate={new Date()}
                                    />
                                </div>
                            </div>
                        </Col> */}
                    <Col xl={4}>
                                <TextField
                                    fullWidth={true}
                                    variant="outlined"
                                    margin="normal"
                                    id={'txt_laodfactor'}
                                    label={'Comments'}
                                    hintText={'Enter Comments'}
                                    value={this.props.state.comments}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'comments', value: event.target.value })
                                    }}
                                />
                    </Col>
                    <Col xl={4}>
                    <CheckBox
                        id={'chk_send_marketing'}
                        key={'chk_send_marketing'}
                        name={'chk_send_marketing'}
                        field={'send_marketing'}s
                        checked={this.props.state.send_marketing}
                        labelPlacement={'end'}
                        label={('Send Marketing')}
                        onCheck={(checked) => {
                            this.props.actions.setInputValue({ prop: 'send_marketing',value: checked})
                        }}
                    />
                 </Col>
                 {this.props.state.send_marketing && ( <Col xl={4}>
                    <AutoComplete
                                                        listStyle={{ maxHeight: 400 }}
                                                        id={'dll_marketingtype_id'}
                                                        key={'dll_marketingtype_id'}
                                                        hintText={'Select Marketing Type from the List'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        value={this.props.state.marketingtype}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.marketingtype_data}
                                                        onNewRequest={
                                                            (chosenRequest, index) => {
                                                                this.props.actions.setInputValue({prop:'marketingtype',value: chosenRequest.text})
                                                                this.props.actions.setInputValue({prop:'marketingtype_id',value: chosenRequest.value})
                                                            }
                                                        }
                                                        onClick={
                                                            (event) => {
                                                                this.props.actions.getLookupValueData('marketingtype', 'marketingtype', '')
                                                            }
                                                        }
                                                        onUpdateInput={
                                                            (search, dataSource, params) => {
                                                                this.props.actions.getLookupValueData('marketingtype', 'marketingtype', search)
                                                            }
                                                        }
                                                    />
                                        </Col>)}
                </Row>
        </Container>
    }

    getLayout() {
        this.data = this.props.workflow_queue_data.context_data.data.data.data[0]
        //console.log('WorkflowSTRActivation WorkflowReview',this.data)
        
        return <div>
            {this.getView(this.data)}
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.strQuotationActivation,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowSTRActivation)