import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import * as config from '../../config'
import { Container, Row, Col } from 'react-grid-system'
import { Table } from "@mui/material"

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import TextField from '../../controls/textField'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import ViewCustomer from '../../controls/view_customer'

import Icon from '../Icon/component'
import PdfViewer from '../PDF/component'
import Upload from '../Upload/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'
import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'

class WorkflowPOContractActivationComponent extends Component {

    constructor(props) {
        super(props)
        this.state = { data: this.props.workflow_queue_data.context_data.data.data.data[0] }
    }

    componentDidMount() {

        //console.log('componentWillUnmount props', this.props.workflow_queue_data)
        // let data = this.props.workflow_queue_data.context_data.data.data.data[0]

        //console.log('componentWillUnmount data', data)
        this.props.actions.setInputValue({ prop: 'enginenumber', value: this.state.data.reciept.enginenumber })
        this.props.actions.setInputValue({ prop: 'licenceexpirydate', value: this.state.data.reciept.licenceexpirydate })
        this.props.actions.setInputValue({ prop: 'openingodometer', value: this.state.data.reciept.openingodometer })
        this.props.actions.setInputValue({ prop: 'registrationdate', value: this.state.data.reciept.registrationdate })
        this.props.actions.setInputValue({ prop: 'registrationnumber', value: this.state.data.reciept.registrationnumber })
        this.props.actions.setInputValue({ prop: 'vinnumber', value: this.state.data.reciept.vinnumber })
        this.props.actions.setInputValue({ prop: 'sundrytotal', value: this.state.data.vehicle.sundry_tot })
        this.props.actions.setInputValue({ prop: 'tyrecpk', value: this.state.data.vehicle.tyrescpk })
        this.props.actions.setInputValue({ prop: 'maintcpk', value: this.state.data.vehicle.maintenancecpk })
        this.props.actions.setInputValue({ prop: 'retailprice', value: this.state.data.vehicle.retailprice })
        this.props.actions.setInputValue({ prop: 'discountamount', value: this.state.data.vehicle.discount })
        this.props.actions.setInputValue({ prop: 'vehicleprice', value: this.state.data.vehicle.vehicleprice })
        this.props.actions.setInputValue({ prop: 'rv', value: this.state.data.vehicle.rv })
        //this.props.actions.setInputValue({prop: 'retailprice', value: this.state.data.vehicle.rv_amount})

        if (this.props.state.user.demouser) {
            this.props.actions.setInputValue({ prop: 'registrationnumber', value: 'Infleet' + moment().format('HHMMSS') })
            this.props.actions.setInputValue({ prop: 'licensenumber', value: 'Infleet' + moment().format('MMSS') })
            this.props.actions.setInputValue({ prop: 'openingodometer', value: 55 })
            this.props.actions.setInputValue({ prop: 'enginenumber', value: 'ENG' + moment().format('YYMMDDHHMMSS') })

            this.props.actions.setInputValue({ prop: 'registrationdate', value: moment().format('YYYY-MM-DD') })
            this.props.actions.setInputValue({ prop: 'licenceexpirydate', value: moment().format('YYYY-MM-DD') })
            this.props.actions.setInputValue({ prop: 'regionactivationdate', value: moment().format('YYYY-MM-DD') })
            this.props.actions.setInputValue({ prop: 'activationdate', value: moment().format('YYYY-MM-DD') })
            this.props.actions.setInputValue({ prop: 'trackingdate', value: moment().format('YYYY-MM-DD') })
        }


        this.props.actions.setValidationState('workflowPOContractActivation')
        this.props.actions.getCustomerData(this.state.data.customer.customer_id)
        console.log('WorkflowPOContractActivationComponent', this.state.data)
    }

    getSystem(data) {
        //console.log('WorkflowPOContractActivation getSystem')
        return <Card
            title={window.t.en('Quoted History ')}
            subtitle={window.t.en('List Of Steps')}
            content={
                <Table className="table" >
                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                        <th className="text-left ">Step Name</th>
                        <th className="text-left ">System User</th>
                        <th className="text-left">Datetime</th>
                        <th className="text-left">Comments</th>
                    </thead>
                    <tbody style={{ zIndex: 1, fontWeight: 'normal' }}>
                        <tr>
                            <td>{'Quote Created'}</td>
                            <td>{data.vehicle.assignto.firstname}</td>
                            <td>{data.vehicle.quotedate}</td>
                            <td>{data.vehicle.comments}</td>
                        </tr>
                        <tr>
                            <td>{'Quote Review'}</td>
                            <td>{data.review.approvedby}</td>
                            <td>{moment(data.review.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{data.review.comments}</td>
                        </tr>
                        <tr>
                            <td>{'Quote Approve'}</td>
                            <td>{data.approval.approvedby}</td>
                            <td>{moment(data.approval.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{data.approval.comments}</td>
                        </tr>
                        <tr>
                            <td>{'Quote Accepted'}</td>
                            <td>{data.accepted.acceptedby}</td>
                            <td>{moment(data.accepted.acceptdate).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{data.accepted.acceptedcomments}</td>
                        </tr>
                        {/* <tr>
                                <td>{'GM Approval'}</td>
                                <td>{data.gm.approvedby}</td>
                                <td>{moment(data.gm.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.gm.comments}</td>
                            </tr>
                            <tr>
                                <td>{'COO Approval'}</td>
                                <td>{data.coo.approvedby}</td>
                                <td>{moment(data.coo.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.coo.comments}</td>
                            </tr> */}
                    </tbody>
                </Table>
            }
        />
    }
    getContract(data) {
        return <Card
            title={window.t.en('Quote Confirmation')}
            subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Contract Type:', value: data.contractname },
                        { size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory },
                        { size: 4, label: 'Monthly Distance:', value: data.distancepermonth },
                        { size: 4, label: 'Contract Period:', value: data.contractperiod },
                        { size: 4, label: 'Quote For:', value: data.quotefor },
                        { size: 4, label: 'RFQ Date:', value: data.quotedate },
                        { size: 4, label: 'Vehicle Color:', value: data.color },
                        { size: 4, label: 'Vehicle Location:', value: data.location },
                        { size: 4, label: 'Special Vehicle:', value: data.specialistvehicle },
                        { size: 4, label: 'Vehicle Group:', value: data.vehiclegroup },
                        { size: 4, label: 'Operating Terrain:', value: data.terrain },
                        { size: 4, label: 'Vehicle Usage:', value: data.usage },
                        { size: 4, label: 'Comments:', value: data.comments },
                    ]}
                />
            }
        />
    }

    getCustomer(customer) {
        //let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        return <div>
            <ViewCustomer
                min={true}
                key={customer.customer_id}
                id={customer.customer_id}
            />
        </div>
    }

    getSupplier(data) {
        //console.log('WorkflowPOContractActivation getSupplier')
        return <Card
            title={window.t.en('Supplier Details')}
            subtitle={window.t.en('Selected Vehicle Supplier ')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Supplier Name:', value: data.suppliername },
                        { size: 4, label: 'Trading Name:', value: data.tradingnamename },
                        { size: 4, label: 'Account Number:', value: data.accountnumber },
                        { size: 4, label: 'VAT Number:', value: data.vatnumber },
                        { size: 4, label: 'Contact Person:', value: data.contactperson },
                        { size: 4, label: 'Contact Email:', value: data.contactemail },
                        { size: 4, label: 'Contact Number:', value: data.contactnumber },
                    ]}
                />
            }
        />
    }

    getVehicles(data) {
        //console.log('WorkflowPOContractActivation getVehicles')
        return <Card
            title={window.t.en('Quoted Vehicles ')}
            subtitle={window.t.en('Quoted Vehicle List')}
            content={
                <Table className="table" >
                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                        <th className="text-left "></th>
                        <th className="text-left ">Vehicle Description</th>
                        <th className="text-left">Vehicle Pricing</th>
                        <th className="text-left">Residual Values</th>
                        <th className="text-right">Vehicle Component </th>
                        <th className="text-right">Rental</th>
                    </thead>
                    <tbody style={{ zIndex: 1, fontWeight: 'normal' }}>
                        {
                            data.map((x, i) => {
                                return <>
                                    <tr key={'vehicle_inx_' + i}>
                                        <td xl={3}>
                                            <img style={styles.vehicle} src={config.system.cdn + '/' + (x.worksheet.mmcode.imagefilename !== null ? x.worksheet.mmcode.imagefilename : 'unknown.png')} />
                                        </td>
                                        <td xl={3}>
                                            MM: {x.worksheet.mmcode.mmcode} <br />
                                            {x.worksheet.mmcode.make}<br />
                                            {x.worksheet.mmcode.cdescription}
                                        </td>
                                        <td xl={3} style={styles.numericFieldValues}>
                                            RP: {parseFloat(x.worksheet.retailprice)}<br />
                                            DS: {parseFloat(x.worksheet.discount)}<br />
                                            VP: {parseFloat(x.worksheet.vehicleprice)}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            RV %: {x.worksheet.rv}<br />
                                            RV: {x.worksheet.rv_amount}<br />
                                            {x.worksheet.deliveryperiod + ' Days'}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            Maint: {x.worksheet.maintenance}<br />
                                            Tyres: {x.worksheet.tyres}<br />
                                            Service: {x.worksheet.service}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            {x.worksheet.monthly_excl}<br />
                                            {x.worksheet.monthly_vat}<br />
                                            {x.worksheet.monthly_incl}
                                        </td>
                                    </tr>
                                </>
                            })
                        }
                    </tbody>
                </Table>
            }
        />
    }

    getDocuments(data) {
        //console.log('WorkflowPOContractActivation getDocuments')
        return <Card
            title={window.t.en('Document')}
            subtitle={window.t.en('Uploaded Customer Supporting Documents')}
            content={
                data.length
                    ? <PdfViewer
                        key={'uplaod_customer_viewer'}
                        documents={data}
                        multiple={true}
                        preview={'preview'}
                    />
                    : 'Loading...'
            }
        />
    }

    getVehicle(data) {
        //console.log('WorkflowPOContractActivation getVehicle')
        return <Card
            title={window.t.en('Accepted Quote ' + data.quotenumber)}
            subtitle={window.t.en('Customer Quote Accepted')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Quote Number:', value: data.quotenumber },
                        { size: 4, label: 'System PO Number:', value: data.ponumber },

                        { size: 12, header: 'Vehicle Details' },
                        { size: 4, label: 'Vehicle Make:', value: data.mmcode.make },
                        { size: 4, label: 'Vehicle Model:', value: data.mmcode.cdescription },

                        { size: 4, label: 'MM Code:', value: data.mmcode.mmcode },
                        { size: 4, label: 'Transmission:', value: data.mmcode.manualauto },

                        { size: 4, label: 'Introduction Year:', value: data.mmcode.regyear },
                        { size: 4, label: 'Body Type:', value: data.mmcode.bodytype },

                        { size: 4, label: 'Contract Type:', value: data.contractname },
                        { size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory },

                        { size: 4, label: 'Monthly Distance', value: data.distancepermonth },
                        { size: 4, label: 'Contract Period:', value: data.contractperiod },

                        //{size: 4, label: 'Rate Card:', value: data.ratecard},
                        { size: 4, label: 'Delivery Period:', value: data.deliveryperiod },

                        { size: 12, header: 'Rental Breakdown' },
                        { size: 4, label: 'Retail Price:', value: data.retailprice },
                        { size: 4, label: 'Discount:', value: data.discount },

                        { size: 4, label: 'Interest Rate:', value: data.primerate },
                        { size: 4, label: 'Vehicle Price:', value: data.vehicleprice },

                        { size: 4, label: 'Residual %:', value: data.rv },
                        { size: 4, label: 'Residual Value:', value: data.rv_amount },

                        { size: 4, label: 'ROI %:', value: data.roipercentage },
                        { size: 4, label: 'ROI:', value: data.roi },

                        { size: 4, label: 'Tyre CPK:', value: data.tyrescpk },
                        { size: 4, label: 'Tyre:', value: data.tyres },

                        { size: 4, label: 'Maintenance CPK:', value: data.maintenancecpk },
                        { size: 4, label: 'Maintenance:', value: data.maintenance },

                        { size: 4, label: 'Admin Fee:', value: data.adminfee },
                        { size: 4, label: 'License Fee:', value: data.licence },
                        { size: 4, label: 'Service:', value: data.service },
                        { size: 4, label: 'Sundry:', value: data.sundry_tot },

                        { size: 12, header: 'Monthly Rental' },
                        { size: 4, label: 'Rental Excl:', value: data.monthly_excl },

                        { size: 4, label: 'Vat:', value: data.monthly_vat },
                        { size: 4, label: 'Rental Incl:', value: data.monthly_incl },
                    ]}
                />
            }
        />
    }

    getAccessories(data) {
        //console.log('WorkflowPOContractActivation getAccessories')
        return <Card
            title={window.t.en('Accessory')}
            subtitle={window.t.en('Select Accessory Supplier')}
            content={
                <Table className="table" >
                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                        <th className="text-left ">Accessory Description</th>
                        <th className="text-left">Supplier Name</th>
                        <th className="text-left">Cost Price</th>
                        <th className="text-left">Total Price</th>
                    </thead>
                    <tbody style={{ zIndex: 1, fontWeight: 'normal' }}>
                        {
                            data.map((x, i) => {
                                return <>
                                    <tr key={'vehicle_inx_' + i}>
                                        <td xl={3}>{x.accessory[0][x.accessory[0].itemtype]}</td>
                                        <td xl={3}>{x.accessory[0].supplier}</td>
                                        <td xl={3}>{x.accessory[0].costprice}</td>
                                        <td xl={3}>{x.accessory[0].totalprice}</td>
                                    </tr>
                                </>
                            })
                        }
                    </tbody>
                </Table>
            }
        />
    }

    getForm() {
        //console.log('WorkflowPOContractActivation getForm')
        return <Card
            title={window.t.en('Activation')}
            subtitle={window.t.en('Activation Details')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={4}>
                            <div style={{ marginTop: '25px' }}>
                                <TextField
                                    variant="outlined"
                                    id={'txtRetailsPrice'}
                                    label={'Enter Retails Price'}
                                    fullWidth={true}
                                    value={this.props.state.retailprice}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'retailprice', value: event.target.value })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div style={{ marginTop: '25px' }}>
                                <TextField
                                    variant="outlined"
                                    id={'txtDiscountPrice'}
                                    label={'Enter Discount Amount'}
                                    fullWidth={true}
                                    value={this.props.state.discountamount}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'discountamount', value: event.target.value })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div style={{ marginTop: '25px' }}>
                                <TextField
                                    variant="outlined"
                                    id={'txtrv'}
                                    label={'Enter RV'}
                                    fullWidth={true}
                                    value={this.props.state.rv}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'rv', value: event.target.value })
                                    }}
                                />
                            </div>
                        </Col>
                        {/* <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Interest Rate %:'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    id={'txtinterestrate'}
                                    label={'Enter Interest Rate'}
                                    fullWidth={true}
                                    value={this.props.state.interestrate}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({prop: 'interestrate', value: event.target.value})
                                    }}
                                />
                            </div>
                        </div>
                    </Col> */}
                        {/* <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Sundry Total:'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    id={'txtSundrtPrice'}
                                    label={'Enter Sundry Total'}
                                    fullWidth={true}
                                    value={this.props.state.sundrytotal}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({prop: 'sundrytotal', value: event.target.value})
                                    }}
                                />
                            </div>
                        </div>
                    </Col> */}
                        <Col xl={4}>
                            <div style={{ marginTop: '25px' }}>
                                <TextField
                                    variant="outlined"
                                    id={'oem_invoice_amount'}
                                    label={'Enter Invoice Amount'}
                                    fullWidth={true}
                                    value={this.props.state.oeminvoiceamount}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'oeminvoiceamount', value: event.target.value })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div style={{ marginTop: '25px' }}>
                                <TextField
                                    variant="outlined"
                                    id={'oem_invoice_number'}
                                    label={'Enter OEM Invoice Number'}
                                    fullWidth={true}
                                    value={this.props.state.oeminvoicenumber}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'oeminvoicenumber', value: event.target.value })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={4} >
                            <DateControl
                                id={'oem_invoice_date'}
                                key={'oem_invoice_date'}
                                label={'Select OEM Invoice Date'}
                                name={'oem_invoice_date'}
                                value={this.props.state.oeminvoicedate}
                                onSelect={(args) => {
                                    this.props.actions.setInputValue({ prop: 'oeminvoicedate', value: args })
                                }}
                                type={'date'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <TextField
                                variant="outlined"
                                id={'txttyrecpk'}
                                label={'Enter Tyre CPK (optional)'}
                                fullWidth={true}
                                value={this.props.state.tyrecpk}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue({ prop: 'tyrecpk', value: event.target.value })
                                }}
                            />
                        </Col>
                        <Col xl={4}>
                            <TextField
                                variant="outlined"
                                id={'txtmaintcpk'}
                                label={'Enter Maintenance CPK (optional)'}
                                fullWidth={true}
                                value={this.props.state.maintcpk}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue({ prop: 'maintcpk', value: event.target.value })
                                }}
                            />
                        </Col>
                        <Col xl={4}>
                            <TextField
                                variant="outlined"
                                id={'txtRegnumber'}
                                label={'Enter Registration Number'}
                                fullWidth={true}
                                value={this.props.state.registrationnumber}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue({ prop: 'registrationnumber', value: event.target.value })
                                }}
                            />
                        </Col>
                        <Col xl={4}>
                            <div style={{ marginTop: '25px' }}>
                                <TextField
                                    variant="outlined"
                                    id={'txtLicense'}
                                    label={'Vehicle Register Number'}
                                    fullWidth={true}
                                    value={this.props.state.licensenumber}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'licensenumber', value: event.target.value })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div style={{ marginTop: '25px' }}>
                                <TextField
                                    variant="outlined"
                                    id={'txtodo'}
                                    label={'Enter Opening Odometer'}
                                    fullWidth={true}
                                    value={this.props.state.openingodometer}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'openingodometer', value: event.target.value })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={4} >

                            <DateControl
                                id={'txtfirstpaymentdate'}
                                key={'txtfirstpaymentdate'}
                                name={'txtfirstpaymentdate'}
                                label={'First Payment Date (Optional)'}
                                fullWidth={true}
                                value={this.props.state.start_date}
                                onSelect={(args) => {
                                    this.props.actions.setInputValue({ prop: 'firstpaymentdate', value: args })
                                }}
                                type={'date'}
                            />

                        </Col>
                        <Col xl={4}>
                            <TextField
                                variant="outlined"
                                id={'txtVIN'}
                                label={'Enter VIN Number'}
                                fullWidth={true}
                                value={this.props.state.vinnumber}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue({ prop: 'vinnumber', value: event.target.value })
                                }}
                            />
                        </Col>
                        <Col xl={4}>
                            <div style={{ marginTop: '0px' }}>
                                <TextField
                                    variant="outlined"
                                    id={'txtEngine'}
                                    label={'Enter Engine Number'}
                                    fullWidth={true}
                                    //disabled={this.props.state.enginenumber ? true : false}
                                    value={this.props.state.enginenumber}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'enginenumber', value: event.target.value })
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xl={4} >
                            <div style={{ marginTop: '-15px' }}>
                                <DateControl
                                    id={'dtRegistrationDate'}
                                    key={'dtRegistrationDate'}
                                    label={'Select Registration Date'}
                                    name={'dtRegistrationDate'}
                                    value={this.props.state.registrationdate}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'registrationdate', value: args })
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </Col>
                        <Col xl={4} >
                            <div style={{ marginTop: '-10px' }}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'ddlLicenseAuthority'}
                                    key={'ddlLicenseAuthority'}
                                    hintText={'Select License Authority'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    fieldvalue={'licenceauthority_id'}
                                    displayfields={['licenceauthority']}
                                    data={this.props.state.licenceauthority_data}
                                    onSelect={
                                        (args) => {
                                            this.props.actions.setInputValue({ prop: 'licenceauthority', value: args.text })
                                            this.props.actions.setInputValue({ prop: 'licenceauthority_id', value: args.value })
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getLookupData('licenceauthority', 'licenceauthority', '')
                                        }
                                    }
                                    onFilter={
                                        (search) => {
                                            if (search) {
                                                this.props.actions.getLookupData('licenceauthority', 'licenceauthority', search)
                                                this.props.actions.setInputValue({ prop: 'licenceauthority', value: search })
                                            } else {
                                                this.props.actions.setInputValue({ prop: 'licenceauthority', value: '' })
                                                this.props.actions.setInputValue({ prop: 'licenceauthority_id', value: null })
                                                this.props.actions.getLookupData('licenceauthority', 'licenceauthority', '')
                                            }
                                        }
                                    }
                                />
                            </div>
                        </Col>
                        <Col xl={4} >
                            <div style={{ marginTop: '-15px' }}>
                                <DateControl
                                    id={'dtLicenceExpiryDate'}
                                    key={'dtLicenceExpiryDate'}
                                    label={'Select Licence Expiry Date'}
                                    name={'dtLicenceExpiryDate'}
                                    value={this.props.state.licenceexpirydate}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'licenceexpirydate', value: args })
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </Col>
                        <Col xl={4} >
                            <div style={{ marginTop: '-15px' }}>
                                <DateControl
                                    id={'dtVehicleDate'}
                                    key={'dtVehicleDate'}
                                    label={'Select Region Activation Date'}
                                    name={'dtVehicleDate'}
                                    value={this.props.state.regionactivationdate}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'regionactivationdate', value: args })
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </Col>
                        <Col xl={4} >
                            <div style={{ marginTop: '-15px' }}>
                                <DateControl
                                    id={'dtActivationDate'}
                                    key={'dtActivationDate'}
                                    label={'Select Activation Date'}
                                    name={'dtActivationDate'}
                                    value={this.props.state.activationdate}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'activationdate', value: args })
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div>Send Marketing</div>
                            <Checkbox
                                id={'chk_send_marketing'}
                                key={'chk_send_marketing'}
                                name={'chk_send_marketing'}
                                field={'send_marketing'}
                                checked={this.props.state.send_marketing}
                                labelPlacement={'end'}
                                label={window.t.en('Send Marketing')}
                                // onCheck={(checked) => {
                                //     this.props.actions.setInputValue({prop:'send_marketing',value: checked})
                                // }}
                                // checked={this.props.state.signed_vaf}
                                onChange={
                                    (event, checked) => {
                                        this.props.actions.setInputValue({ prop: 'send_marketing', value: checked })
                                    }
                                }
                            />
                        </Col>
                        {this.props.state.send_marketing && (<Col xl={4} >
                            <div style={{ marginTop: '-10px' }}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'ddlmarketingtype'}
                                    key={'ddlmarketingtype'}
                                    hintText={'Select Marketing Type'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    fieldvalue={'marketingtype_id'}
                                    displayfields={['marketingtype']}
                                    data={this.props.state.marketingtype_data}
                                    value={this.props.state.marketingtype}
                                    onSelect={
                                        (args) => {
                                            this.props.actions.setInputValue({ prop: 'marketingtype', value: args.text })
                                            this.props.actions.setInputValue({ prop: 'marketingtype_id', value: args.value })
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getLookupData('marketingtype', 'marketingtype', '')
                                        }
                                    }
                                    onFilter={
                                        (search) => {
                                            if (search) {
                                                const data = this.props.state.marketingtype_data
                                                const marketingType = data.find(m=>m.text === search)
                                                this.props.actions.getLookupData('marketingtype', 'marketingtype', search)
                                                this.props.actions.setInputValue({ prop: 'marketingtype', value: search })
                                                if (marketingType) this.props.actions.setInputValue({ prop: 'marketingtype_id', value: marketingType.value })
                                            } else {
                                                this.props.actions.getLookupData('marketingtype', 'marketingtype', '')
                                                this.props.actions.setInputValue({ prop: 'marketingtype', value: '' })
                                                this.props.actions.setInputValue({ prop: 'marketingtype_id', value: null })
                                            }
                                        }
                                    }
                                />
                            </div>
                        </Col>)}
                        <Col xl={4} >
                            <div style={{ marginTop: '-15px' }}>
                                <DateControl
                                    id={'dtRegistrationDate'}
                                    key={'dtRegistrationDate'}
                                    label={'Select Tracking Fitted Date'}
                                    name={'dtRegistrationDate'}
                                    value={this.props.state.trackingdate}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'trackingdate', value: args })
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} >
                            <div style={{ marginTop: '-10px' }}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'ddlTracking'}
                                    key={'ddlTracking'}
                                    hintText={'Select Vehicle Tracking'} //{this.props.state.trackingsupplier}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    fieldvalue={'trackingtype_id'}
                                    displayfields={['trackingtype']}
                                    data={[{ trackingtype: 'Please select..', trackingtype_id: null }, { trackingtype: 'Own Tracking', trackingtype_id: 0 }].concat(this.props.state.trackingtype_data)}
                                    onSelect={
                                        (args) => {
                                            this.props.actions.setInputValue({ prop: 'trackingtype', value: args.text })
                                            this.props.actions.setInputValue({ prop: 'trackingtype_id', value: args.value })
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getLookupData('trackingtype', 'trackingtype', '')
                                        }
                                    }
                                    onFilter={
                                        (search) => {
                                            if (search) {
                                                this.props.actions.getLookupData('trackingtype', 'trackingtype', search)
                                                this.props.actions.setInputValue({ prop: 'trackingtype', value: search })
                                            } else {
                                                this.props.actions.getLookupData('trackingtype', 'trackingtype', '')
                                                this.props.actions.setInputValue({ prop: 'trackingtype', value: '' })
                                                this.props.actions.setInputValue({ prop: 'trackingtype_id', value: null })
                                            }
                                        }
                                    }
                                />
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} >
                            <div style={{ marginTop: '10px' }}>
                                {
                                    this.props.state.trackingtype === 'Own Tracking'
                                        ? <Upload
                                            id={'up_trackingtype'}
                                            inx={'trackingcertificate'}
                                            prefix={'up_trackingtype'}
                                            header={false}
                                        />
                                        : ''
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} >
                            <div style={{ marginTop: '-10px' }}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'ddlinsurancecompany'}
                                    key={'ddlinsurancecompany'}
                                    hintText={'Select Vehicle Insurance'} //{this.props.state.trackingsupplier}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    fieldvalue={'insurancecompany_id'}
                                    displayfields={['insurancecompany']}
                                    data={[{ insurancecompany: 'Please select..', insurancecompany_id: null }, { insurancecompany: 'Own Insurance', insurancecompany_id: 0 }].concat(this.props.state.insurancecompany_data)}
                                    onSelect={
                                        (args) => {
                                            this.props.actions.setInputValue({ prop: 'insurancecompany', value: args.text })
                                            this.props.actions.setInputValue({ prop: 'insurancecompany_id', value: args.value })
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getLookupData('insurancecompany', 'insurancecompany', '')
                                        }
                                    }
                                    onFilter={
                                        (search) => {
                                            if (search) {
                                                this.props.actions.getLookupData('insurancecompany', 'insurancecompany', search)
                                                this.props.actions.setInputValue({ prop: 'insurancecompany', value: search })
                                            } else {
                                                this.props.actions.getLookupData('insurancecompany', 'insurancecompany', '')
                                                this.props.actions.setInputValue({ prop: 'insurancecompany', value: '' })
                                                this.props.actions.setInputValue({ prop: 'insurancecompany_id', value: null })
                                            }
                                        }
                                    }
                                />
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} >
                            <div style={{ margin: '10px' }}>
                                {
                                    this.props.state.insurancecompany === 'Own Insurance'
                                        ? <Upload
                                            id={'up_insurancecompany'}
                                            inx={'insurancecertificate'}
                                            prefix={'up_insurancecompany'}
                                            header={false}
                                        />
                                        : ''
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
        />
    }

    getChecklist() {
        return <Card
            title={window.t.en('Mandatory Tick List')}
            subtitle={window.t.en('The following documents are required')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Signed Release Note? *'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <Checkbox
                                key={'signed_release_note'}
                                label={'Signed Release Note?'}
                                labelPosition='left'

                                style={styles.toggle}
                                trackStyle={{
                                    backgroundColor: 'gray'
                                }}
                                trackSwitchedStyle={{
                                    backgroundColor: 'green'
                                }}
                                checked={this.props.state.signed_release_note}
                                onChange={
                                    (event, isInputChecked) => {
                                        this.props.actions.setInputValue({ prop: 'signed_release_note', value: isInputChecked })
                                    }
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Signed Vehicle Acceptance Form (VAF)? *'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>

                            <Checkbox
                                key={'signed_vaf'}
                                label={'Signed Vehicle Acceptance Form (VAF)?'}
                                labelPosition='left'

                                style={styles.toggle}
                                trackStyle={{
                                    backgroundColor: 'gray'
                                }}
                                trackSwitchedStyle={{
                                    backgroundColor: 'green'
                                }}
                                checked={this.props.state.signed_vaf}
                                onChange={
                                    (event, isInputChecked) => {
                                        this.props.actions.setInputValue({ prop: 'signed_vaf', value: isInputChecked })
                                    }
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Signed Proof of Delivery document ( POD)? *'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>

                            <Checkbox
                                key={'signed_pod'}
                                label={'Signed Proof of Delivery document ( POD)?'}
                                labelPosition='left'

                                style={styles.toggle}
                                trackStyle={{
                                    backgroundColor: 'gray'
                                }}
                                trackSwitchedStyle={{
                                    backgroundColor: 'green'
                                }}
                                checked={this.props.state.signed_pod}
                                onChange={
                                    (event, isInputChecked) => {
                                        this.props.actions.setInputValue({ prop: 'signed_pod', value: isInputChecked })
                                    }
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Original Natis Registration Document received and in storage? *'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>

                            <Checkbox
                                key={'natis_registration_document'}
                                label={'Original Natis Registration Document received and in storage?'}
                                labelPosition='left'

                                style={styles.toggle}
                                trackStyle={{
                                    backgroundColor: 'gray'
                                }}
                                trackSwitchedStyle={{
                                    backgroundColor: 'green'
                                }}
                                checked={this.props.state.natis_registration_document}
                                onChange={
                                    (event, isInputChecked) => {
                                        this.props.actions.setInputValue({ prop: 'natis_registration_document', value: isInputChecked })
                                    }
                                }
                            />
                        </Col>
                    </Row>
                </Container>
            }
        />
    }

    getView(data) {
        return <div>
            {this.getSystem(data)}
            {this.getContract(data.vehicle)}
            {this.getCustomer(data.customer)}
            {this.getVehicles(data.quotations)}
            {this.getSupplier(data.supplier)}
            {this.getVehicle(data.vehicle)}
            {this.getAccessories(data.pos)}
            {this.getDocuments(data.documents)}
            {this.getForm()}
            {this.getChecklist()}
        </div>
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log('WorkflowPOContractActivation workflow_queue_data', workflow_queue_data)
        let data = null
        try {
            // data = this.props.workflow_queue_data.context_data.data.data.data[0]
            data = this.state.data
        }
        catch (err) { }

        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowPOContractActivation,
            user: {
                ...state.user
            },
            documents: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOContractActivationComponent)