import * as actions from './actions'
// import * as progressBarActions from '../ProgressBar/actions'
import * as routerActions from '../Redirect/actions'

import * as types from './types'
import * as config from '../../config'


export const loadReports = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_DASHBOARD_VIEW_DATA),
    delay(500),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/reportcategory/query?category_type=dashboard',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'all'
                },
            }).pipe(
                mergeMap((response) => [
                    actions.setReport(response.response),
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => {
                    //console.log('catchError setUser', payload)
                }
                )
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
