import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getVehicles = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_VEHICLES_ACTION,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                //url: config.system.api + '/components/vehicle/search,
                url: config.system.api + '/components/vehicle/search?licenseplateno=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 10,
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setVehichlesAutoCompleteEpic(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getCustomer = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_CUSTOMER_ACTION,
    )
    , mergeMap(({ payload }) =>
        ajax$({
            //url: config.system.api + '/components/vehicle/search,
            url: config.system.api + '/components/customer/search?customername=' + payload,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'take': 10,
            }
        })
            .pipe(
                mergeMap((result) => [
                    actions.setCustomerAutoCompleteEpic(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            ))
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))




    export const finalizeVehicleTransferEpic = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.FINALIZE_VEHICLE_TRANSFER_ACTION,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                //url: config.system.api + '/components/vehicle/search,
                url: config.system.api + '/components/vehiclelicencerenewal/other',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,

                },
                body: {
                    transfer: payload
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setFinalizeRenewalResponse(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

