import * as types from './types'

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
    }
}

export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setAccessoryList = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const updAccessoryValue = (prop, inx, value, data) => {
    return (dispatch, getState) => {
        data[inx][prop] = value
        window.glyco.log('updAccessoryValue')
        window.glyco.log(data)
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'accessory_list',
                value: data
            }
        })
    }
}

export const getCustomerData = (customer_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MM_CUSTOMER_DATA_LIST,
            payload: {
               customer_id:customer_id
            }
        })
    }
}

export const setCustomerData = (response) => {
    //console.log('customerdatax', response.data)
    let customerdata = response.data.length ? response.data[0] : {}
    return (dispatch, getState) => {
        dispatch(setValue('customerdata', customerdata))
        dispatch(setValue('marketingtype',  customerdata.marketingtype? customerdata.marketingtype.marketingtype : null))
        dispatch(setValue('marketingtype_id',  customerdata.marketingtype? customerdata.marketingtype.marketingtype_id : null))
        dispatch(setValue('send_marketing', customerdata.send_marketing))
    }
}


export const getMMLookupValueData = (component, field,  search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                field: field,
                component: component,
                query: field + '=' + search,
            }
        })
    }
}

export const setMMLookupValueData = (component, field,payload) => {
    return (dispatch, getState) => {

        window.glyco.log('setLookupValueData....')
        window.glyco.log(payload.data)
        const data = payload.data.map(x => {
            window.glyco.log('x')
            window.glyco.log(x)
            return {
                ...x,
                value: x[component + '_id'],
                text:  x[field] //+ ' (' + x[description] + ')'
            }
        })

        window.glyco.log(data)
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: component + '_data',
                value: data,
            }
        })
    }
}
