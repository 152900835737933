
import AutoComplete from '../../../controls/autocomplete'
import DateControl from '../../../controls/dateControl'
import TextField from '../../../controls/textField'
import Card from '../../../controls/card'
import Checkbox from '@mui/material/Checkbox'

import moment from 'moment'
import React, { useState,useEffect } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as compActions from '../../Components/actions'
import Upload from '../../Upload/component'
import * as actions from './actions'
import * as styles from './styles'
import * as _ from 'lodash'

import {ReactComponent as DeleteForeverIcon} from '../../../assets/img/svg/icons/delete_forever_grid.svg';

import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { emphasize, styled, alpha } from '@mui/material/styles';

import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    gridClasses,
    useGridApiRef
} from '@mui/x-data-grid-premium';

import * as config from '../../../config'
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('0dc2c32f83edb803ade140958edb564aTz04MjQzNSxFPTE3MzcxOTI2NzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: 'red',//theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));


function ACCOUNTINGITEMLINK(props) {
    //     props.actions.resetForm()
    //     if(this?.props?.params?.id)
    //         props.actions.getInvoiceDetails(this?.props?.params?.id)
    // }
    const state = useState();
    const apiRef = useGridApiRef();

    // useEffect(() => {
    //     //console.log('props.actions', props.actions);
    //     props.actions.loadComponent('accountingitemlink')
    //     props.actions.getComponentGridData('accountingitemlink', null, null, null, null)
    // }, [])

    const getData = () => {
        let data = props.state.accountingitems.length ? props.state.accountingitems : []
        return data
    }

    const getHeader = () => {
        let headers = props.state.columns.map(x => {
          return x.type === 'decimal' || x.type === 'integer'
          ?  {
              field: x.name, 
              headerName: x.title, 
              headerClassName: 'datagrid_header',
              flex: 1,
              editable: false,
              renderCell: (params) => {
                let _value = params.row[x.name]
                switch (x.type) {
                    case 'timestamp':
                        _value =  _value ? moment(_value).format('YYYY-DD-MM hh:mm') : ''
                        break;
                }
                return _value // ? _value.replace('.',',') : 0 //<div className="rowitem">{_value}</div>;
              },
          }
          : {
              field: x.name, 
              headerName: x.title, 
              headerClassName: 'datagrid_header',
              flex: 1,
              editable: false,
              renderCell: (params) => {
                  let _value = x.name === 'customer' ? props.state.customername :  params.row[x.name]
                  
                  switch (x.type) {
                      case 'timestamp':
                          _value = _value ? moment(_value).format('YYYY-MM-DD') : ''
                          break;
                      case 'date':
                          _value = _value ? moment(_value).format('YYYY-MM-DD') : ''
                          break;
                      case 'boolean':
                          _value = _value == null ? '' : _value ? 'Yes' : 'No'
                          break;
                          default: 
                          _value = _value
                          break;

                  }
                return _value //<div className="rowitem">{_value}</div>;
              },
          }
      })
      //console.log('headersx',headers)
      return headers
    }

    const getColumns = () => {
        return getHeader().concat([{
            field: 'action',
            headerName: 'Action',
            headerClassName: 'datagrid_header',
            sortable: false,
            filterable: false,
            disableExport: true,
            width: 400,
            renderCell: (params) => {
                
                //console.log('renderCellx row ',params.row)

                let accountingitemlinks = props.state.accountingitemlinks
                let accountingitemlink = accountingitemlinks.filter((acc) => acc.customer_id === props.state.customer_id && acc.salestype_id === params.row.salestype_id)
                accountingitemlink = accountingitemlink.length ? accountingitemlink[0] : {}
                let itemname = accountingitemlink.accountingitem
                //console.log('renderCellx item',itemname)

                return params.rowNode.type === 'leaf' ? <Stack direction="row" alignItems="center" spacing={0} style={{margin: '15px 0px'}}>
                    {
                    itemname ? <div>{itemname.itemname}</div> : <AutoComplete
                        name={'dll_accountingitem'}
                        title={('Search...')}
                        info={{
                            show: false
                        }}
                        width={300}
                        style={{ width: '300px', margin: '10px'}}
                        data={props.state.accountingitems}
                        value={''}
                        fieldvalue={'accountingitem_id'}
                        fieldtext={'itemdescription'}
                        dataSourceConfig={{
                            text: 'itemdescription',
                            value: 'accountingitem_id'
                        }}
                        onSelect={(args) => {
                            if (args) {
                                //console.log('AutoCompletez args', args.value)
                                //console.log('AutoCompletez id', props.state.customer_id)
                                //console.log('AutoCompletez name', props.state.customername)
                                //console.log('AutoCompletez params', params.id)
                                
                                props.actions.setInputValue("accountingitem_id", args.value)
                                props.actions.setInputValue("accountingitem", args.text)

                                props.actions.linkAcountingItem({
                                    accountingitem_id: args.value,
                                    customer_id: props.state.customer_id,
                                    salestype_id: params.id
                                })
                                props.actions.getAccountingItemLinkList(props.state.customer_id)
                            }
                        }}
                        onClick={() =>
                            props.actions.getAccountingItemList('')
                        }
                        onFilter={(args) => {
                            //props.actions.setLookupValue("transaction_type", null, null)
                            props.actions.getAccountingItemList(args)
                        }
                        }
                    />
                    }
                </Stack> : ''
            }
        },{
            field: 'delete',
            headerName: '',
            headerClassName: 'datagrid_header',
            sortable: false,
            filterable: false,
            disableExport: true,
            width: 80,
            renderCell: (params) => {
                
                //console.log('renderCellx row ',params.row)

                let accountingitemlinks = props.state.accountingitemlinks
                let accountingitemlink = accountingitemlinks.filter((acc) => acc.customer_id === props.state.customer_id && acc.salestype_id === params.row.salestype_id)
                accountingitemlink = accountingitemlink.length ? accountingitemlink[0] : {}
                let itemname = accountingitemlink.accountingitem
                //console.log('renderCellx item',itemname)
                //console.log('renderCellx accountingitemlink',accountingitemlink)

                return params.rowNode.type === 'leaf' ? <Stack direction="row" alignItems="center" spacing={0} style={{margin: '15px 0px'}}>
                    {
                        itemname ? <Tooltip title="Delete" placement="bottom">
                            <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                                
                                props.actions.setInputValue("salestypes", [])
                                props.actions.setInputValue("accountingitemlinks", [])

                                props.actions.deLinkAcountingItem({
                                    accountingitemlink_id: accountingitemlink.accountingitemlink_id,
                                    accountingitem_id: props.state.accountingitem_id,
                                    customer_id: props.state.customer_id,
                                    salestype_id: params.id
                                })
                            }}>
                                <DeleteForeverIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)} />
                            </IconButton>
                        </Tooltip> : <div></div>
                    }
                </Stack> : ''
            }
        }]) //state.component.grid.headers)
    }

    const getView = () => {
        //console.log('getViewx', props.state)
        return <div>
            <Card
                title={('Entity')}
                subtitle={('Select entity')}
                content={
                    <Container style={styles.whitediv}>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{('Select Customer')}</strong></div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                            name={'dll_customer'}
                                            title={('Search...')}
                                            info={{
                                                show: false
                                            }}
                                            fullWidth={true}
                                            data={props.state.customers}
                                            value={props.state.customername ? props.state.customername : ''}
                                            fieldvalue={'customer_id'}
                                            fieldtext={'customername'}
                                            displayfields={['customername', 'accountnumber']}
                                            displaywidth={['75', '25']}
                                            onSelect={(args) => {
                                                
                                                props.actions.setInputValue("salestypes", [])
                                                props.actions.setInputValue("accountingitemlinks", [])
                                                if (args) {
                                                    //console.log('getViewx sel', args)
                                                    props.actions.setInputValue("customer_id", args.value)
                                                    props.actions.setInputValue("customername", args.text)
                                                    props.actions.getAccountingItemLinkList(args.value)
                                                    props.actions.getSalesTypeList('')
                                                }
                                            }}
                                            onClick={() =>
                                                props.actions.getCustomerList('')
                                            }
                                            onFilter={(args) => {
                                                //props.actions.setLookupValue("transaction_type", null, null)
                                                props.actions.getCustomerList(args)
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>

                }
            />

        {
            props.state.salestypes.length 
            ?  <Card
                title={('Link Items')}
                subtitle={('Capture transaction items')}
                content={<div>

                    <StripedDataGrid
                        getRowId={(x) => x['salestype_id']}
                        rows={props.state.salestypes.length ? props.state.salestypes : []}
                        rowHeight={55}
                        //loading={props.state.types.length ? false : true}
                        columns={getColumns()}
                        //slots={{ toolbar: CustomToolbar, noRowsOverlay: CustomNoRowsOverlay, noResultsOverlay: CustomNoResultsOverlay }}
                        //onPaginationModelChange={(event) => {
                       //     props.actions.getComponentGridData('accountingitemlink', null, { ...event, sorted: [] }, null, null)
                        //}}
                        pageSize={15}
                        pageSizeOptions={[5, 10, 20, 50, 100, 200]}
                        rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
                        paginationMode={"client"}
                        rowCount={props.state.salestypes.length}
                        // pagination getRowClassName={(params) => {
                        //     params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        // }}
                        filterMode={'client'}
                        // onFilterModelChange={(event, model) => {
                        //     //console.log('eventx filter', event.items)
                        //     props.actions.filterInProgress(event.items)
                        //     props.actions.getComponentGridData(props.state.component, null, { ...event, sorted: [] }, event.items, null)
                        // }}
                        autoHeight={false}
                        disableColumnFilter
                        unstable_headerFilters
                        initialState={{
                            pagination: { 
                              paginationModel: { pageSize: 15 } 
                            }
                        }}
                        apiRef={apiRef}
                    />
                </div>
                }
            />
            : <></>
            }
            {/* <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={10}></Col>
                    <Col xl={2}>
                        < Button className={"global_button"} variant="contained" header={false}
                            onClick={() => {
                                props.actions.saveCustomerInvoiceData('payload')
                            }}
                        >{('Save Transaction')}</Button>
                    </Col>
                </Row>
            </Container> */}
        </div>
    }

    return (
        <>{props.state.loading ? 'loading...' : getView()}</>
    )
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.accountingItemLink,
            upload: {
                ...state.upload
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCOUNTINGITEMLINK)