import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Avatar from '@mui/material/Avatar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  Table } from '@mui/material';

import Chip from '@mui/material/Chip'
import CreateIcon from '@mui/icons-material/Create';
import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import SVGLoader from '../../../svgloader';

import * as actions from './actions'
import * as redirectActions from '../../Redirect/actions'
import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'
import * as styles from './styles'

class WorkflowTriggers extends Component {
    componentDidMount() {
        //this.props.actions.getWorkflowTriggers()
    }

    getIcon (workflowName) {
            return <SVGLoader svgFileName={`workflow-tasks/${workflowName}`} styleName={'icon-style-workflow'} isKPI={false}/>

    }

    renderWorkflowTriggersOff() {
        return !this.props.state.data.length || this.props.state.loading
            ? <Loading message={'Retrieving your task menu, please wait...'} />
            : this.props.state.data.map((x, i) => {
                return <div
                    key={'workflow_trigger' + x.workflow_task_id + '_' + i}
                    style={styles.chipRow}
                >
                    <div style={styles.chipContainer}>
                        <Chip
                            //style={styles.chip}
                            label={x.workflow_task_title}
                            avatar={<Avatar icon={<Icon iclass={x.icon_unicode} iname={x.icon_name} />} />}
                            onClick={(event) => {
                                event.preventDefault()

                                this.props.actions.closeWorkflowTriggers()                               
                                this.props.actions.route('/workflow/' + x.workflow_name + '/' + x.workflow_task_name + '/input')
                            }}
                        >
                            
                            {x.workflow_task_title}
                        </Chip>
                    </div>
                    <div style={styles.chipDescription}>
                        {x.workflow_task_description}
                    </div>
                </div>
            })
    }

    renderWorkflowTriggers() {
        return !this.props.state.data.length || this.props.state.loading
            ? <Loading message={'Retrieving your task menu, please wait...'} />
            : this.props.state.data.map((x, i) => {
                //console.log('renderWorkflowTriggers', this.props.state.data)
                return   <tr>
                <td> <a
                        href="#/"
                        onClick={(event) => {
                            event.preventDefault()

                            this.props.actions.closeWorkflowTriggers()                               
                            this.props.actions.route('/workflow/' + x.workflow_name + '/' + x.workflow_task_name + '/input')
                        }}
                        className="font-weight-bold- text-black"
                        title="...">
                  <div className="d-flex align-items-center">
                    <div className="avatar-icon-wrapper mr-3">
                      <div>
                      {this.getIcon(x.workflow_name)}
                      </div>
                    </div>
                    <div style={{fontWeight: '700 !important'}}>
                     
                         {x.workflow_task_title}
                     
                      <span className="text-black-50 d-block">
                        {x.workflow_task_description}
                      </span>
                    </div>
                  </div> </a>
                </td>
              </tr>
            })
    }
    render() {
        return  <Card
        title={window.t.en('Start New Task')}
        subtitle={window.t.en('Click on the task to get started!')}
        content={<Table className="table table-borderless table-hover- text-nowrap mb-0">
        <tbody>
        
          
          {this.renderWorkflowTriggers()}
         </tbody>
      </Table>}
    />
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.workflowTriggers }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...redirectActions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTriggers)