import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'


import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'
import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'
import Iframe from 'react-iframe'

import Icon from '../../Icon/component'
// import TextField from '@mui/material/TextField'
import * as actions from './actions'
import * as styles from './styles'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'
import ImageUploader from '../../ImageUploader/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import Upload from '../../Upload/component'
import TextField from '../../../controls/textField'
import DefleetVehicleInfo from '../DefleetVehicleInfo/component'
import ViewVehicle from '../../../controls/view_vehicle'

class DefleetRebillDispute extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
    }

    SupplierLatter(data) {
        return <div>
            <Card
                title={window.t.en('Supplier Details')}
                subtitle={data.suppliername}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliername}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliertype}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registrationnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vatnumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Limit:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.supplierage.length ? data.supplierage[0].suplierspendlimit : ''}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Usage:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.supplierage.length ? data.supplierage[0].suplierusage : ''}</Col>
                    </Row>

                </Container>} />
        </div>
    }

    LabourRates(data, maint) {
        const supplier = data
        return <div>
            <Card
                title={window.t.en('Labour Rates')}
                subtitle={window.t.en('Customer labour rates.')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Spares Discount:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{supplier.sparesdiscount ? supplier.sparesdiscount : 'Not Set'}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Labour Rate Per Hour:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{supplier.labourrateperhour ? supplier.labourrateperhour : 'Not Set'}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'In Warranty Labour Rate:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{supplier.inwarrantylabourrate ? supplier.inwarrantylabourrate : 'Not Set'}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Out of Warranty Labour Rate:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{supplier.outwarrantylabourrate ? supplier.outwarrantylabourrate : 'Not Set'}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Special Labour Rate:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{supplier.speciallabourrate ? supplier.speciallabourrate : 'Not Set'}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'After Hours Labour Rate:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{supplier.afterhourslabourrate ? supplier.afterhourslabourrate : 'Not Set'}</Col>
                    </Row>
                </Container>} />
        </div>
    }

    AuthDocument(documents) {
        return <Card
            title={window.t.en('De-fleet Documents')}
            subtitle={window.t.en('Vehicle de-fleet documents')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <PdfViewer
                            key={'quotation_viewer_'}
                            documents={documents}
                            height={600}
                            multiple={true}
                            edit={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col><br /></Col>
                </Row>
            </Container>} />
    }

    uploadDocuments(data) {
        return <div>
            <Card
                title={window.t.en('Upload Supporting Documents')}
                subtitle={window.t.en('Upload dispute related documents.')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <Upload
                                id={'upload_ctl'}
                                prefix={'dispute_supporting_documents_' + data.licenseplateno + '_'}
                                header={false}
                            />
                        </Col>
                    </Row>
                </Container>} />
        </div>
    }

    commentsView(data) {
        return <div>
                <Card
                    title={window.t.en('Rebill Dispute Comments')}
                    subtitle={window.t.en('Rebill dispute comments...')}
                    content={<div><Row>
                        <Col xl={3}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Dispute Date:'}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtinvnumber'}
                                        hintText={'Enter comments'}
                                        fullWidth={true}
                                        multiLine={false}
                                        disabled={true}
                                        variant="outlined"
                                        value={moment(data.rebill_dispute_date).format("YYYY-MM-DD")}
                                        onChange={(event, value) => {
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    id={'txtinvnumber'}
                                    hintText={'Enter comments'}
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    variant="outlined"
                                    value={data.rebill_dispute_comments}
                                    onChange={(event, value) => {
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>} />
                <Card
                    title={window.t.en('Additional Comments')}
                    subtitle={window.t.en('Rebill dispute comments...')}
                    content={<div>
                        <Row>
                            <Col xl={12}>
                                <Checkbox
                                    id={'dispute_resolved'}
                                    key={'dispute_resolved'}
                                    name={'dispute_resolved'}
                                    field={'dispute_resolved'}
                                    checked={this.props.state.dispute_resolved ? true : false}
                                    labelPlacement={'end'}
                                    variant="outlined"
                                    label={window.t.en('Dispute Resolved')}
                                    /*  setComponentFieldInput={
                                         this.props.actions.setValue9
                                     } */
                                    onCheck={(checked) => {
                                        this.props.actions.setValue('dispute_resolved', checked)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}><br></br></Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    id={'txtinvnumber'}
                                    label={'Enter comments'}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.rebill_dispute_comments}
                                    onChange={(event) => {
                                        this.props.actions.setValue('rebill_dispute_comments', event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}><br></br></Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                            <Checkbox
                                    id={'dispute_confirmation'}
                                    key={'dispute_confirmation'}
                                    name={'dispute_confirmation'}
                                    field={'dispute_confirmation'}
                                    checked={this.props.state.dispute_confirmation ? true : false}
                                    labelPlacement={'end'}
                                    variant="outlined"
                                    label={'Please confirm the submission of information by TICKING THE CHECKBOX'}
                                    /*  setComponentFieldInput={
                                         this.props.actions.setValue9
                                     } */
                                    onCheck={(checked) => {
                                        this.props.actions.setValue('dispute_confirmation', checked)
                                    }}
                                />
                            </Col>
                        </Row> </div>} />
        </div>
    }

    renderView(data) {
        //console.log("renderView(data) {")
        //console.log(data)
        let step1 = data.step1.data
        let step3 = data.step3.data
        let step9 = data.step9.rebill
        let vehicle = step1.selectedVehicleDetails.data[0]
        let supplier = step3.selectedSupplierDetails.data[0]

        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
                <Row>
                    <Col xl={12}>
                        {this.SupplierLatter(supplier)}
                    </Col>
                </Row>
                {/* <Row>
                    <Col xl={12}>
                        {this.LabourRates(supplier, vehicle.maintenancetransaction)}
                    </Col>
                </Row> */}
                <Row>
                    <Col xl={12}>
                        {this.AuthDocument(data.documents)}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.uploadDocuments(vehicle)}
                    </Col>
                </Row>

                <Row>
                    <Col xl={12}>
                        {this.commentsView(step9)}
                    </Col>
                </Row>
            </Container>
        </div>
    }

    render() {
        let workflow_queue_data = null
        let validate = false
        try {
            validate = this.props.task === this.props.workflow_queue_data.workflow_task.name
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) {

        }

        return validate
            ? this.renderView(workflow_queue_data)
            : 'Select vehicle...'
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetRebillDispute
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetRebillDispute)