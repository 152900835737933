
import * as colours from '@mui/material/colors'
import Theme from '../../../theme'
import { LEFT } from 'formulajs'

export const card = {
    backgroundColor: '#D1E0E6'
}

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const actionHoverColor = {
    color: colours.red[500]
}

export const gridOptionsIcon = {

}

export const action = {
    fontSize: '20px',
    color: colours.red[500]
}
export const frmHeader = {
    backgroundColor: '#f0f0f0'
}
export const frmtotal = {
    backgroundColor: '#dcd6d7'
}
export const theme = Theme

export const icon = {
    color: colours.red[500]
}

export const related = {
    //fontSize: '10px'
}

export const fieldContainer = {
    display: 'table',
}

export const rightpanel = {
    background: '#D1E0E6',
}
export const fieldTitle = {
    width: 300,
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const viewTitle = {
    width: 300,
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    fontWeight: 'bold',
    paddingRight: 10
}
export const viewValue = {
    width: 300,
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10
}
export const formTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '80%'
}

export const formContent = {
    display: 'table-cell',
    width: '50%'
}

export const headerButton = {
    width: 250
}

export const right = {
    float: 'right'
}

export const toggle = {
    width: '100%'
}

export const textBox = {
    width: '100%'
}

export const minheight = {
    minHeight: '300px !important'
}

export const row = {
    fontSize: 16,
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
    opacity: '0.8'
}
export const dropZone = {
    width: '90%',
    height: '150px',
    border: '1px solid black',
    padding: '10px'
}

export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}

export const content = {
    width: '33%',
    height: '95%',
    float: 'left',
    marginTop: '15px',
    border: '1px solid red',
}

export const remove = {
    textAlign: 'right'
}
export const amountfield = {
    textAlign: 'right',
    fontWeight: 'bold',
}
export const chip = {
    margin: 4,
    float: 'left',
}
export const itemsheader = {
    //backgroundColor: '#dcd6d7',
    textAlign: 'left',
    fontSize: '16px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
}
export const itemscolheader = {
    textAlign: 'left',
    textTransform: 'capitalize',
    border: '1px solid #dcd6d7',
    fontWeight: 'bold',
}
export const itemsfooter = {
    textAlign: 'right',
    textTransform: 'capitalize',
    border: '1px solid #dcd6d7',
    fontWeight: 'bold',
}
export const itemsrow = {
    textAlign: 'left',
    border: '1px solid #dcd6d7',
}
export const lines = {
    border: '1px solid #dcd6d7',
}
export const txtright = {
    textAlign: 'right',
}