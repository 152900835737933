import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Card from '../../../controls/card'
import Button from '@mui/material/Button'
import Upload from '../../Upload/component'

import * as _ from 'lodash'

import TextField from '@mui/material/TextField'
import Drawer from '@mui/material/Drawer';
import Checkbox from '@mui/material/Checkbox'
import { green } from '@mui/material/colors'

import IconButton from '@mui/material/IconButton';
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'
import Table from '@mui/material/Table'
import DateControl from '../../../controls/dateControl'
import PdfViewer from '../../PDF/component'

class PaymentManagementView extends Component {
    render() {
        //console.log("this.props.items")
        //console.log(this.props.data)
        let data = this.props.data
        return <div> 
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.supplierpaymentmanagement,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentManagementView)