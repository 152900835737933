import * as finance from 'financejs'
import * as formulas from 'formulajs'
import moment from 'moment'
import get from 'lodash/get'

import * as types from './types'


export const getLookupValue = (model, field) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]

        const query = field.parameters ? field.parameters.indexOf('.') > -1
            ? '?' + field.parameters.split('.')[1] + '=' + getState().components[field.parameters.split('.')[0]].data[field.parameters.split('.')[1]].input
            : '?' + field.parameters + '=' + component.data[field.parameters].input
            : ''

        const payload = {
            component: component,
            model: model,
            field: field,
            related: field.related && model != 'supplier' ? field.display.split('.')[0] : '',
            query: query,
            order: field.display,
        }

        dispatch({
            type: types.STR_GET_COMPONENT_MMCODE_LOOKUP_DATA,
            payload: payload
        })
    }
}


export const getSTRVehicle = (model, field) => {
    return (dispatch, getState) => {


        const component = getState().components[field.component.name]

        const payload = {
            component: component,
            model: model,
            field: field,
            related: 'ratecard',
            query: '',
            order: field.display,
        }

        dispatch({
            type: types.STR_GET_COMPONENT_MMCODE_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const getStockVehicle = (model, field) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]

        const query = field.parameters ? field.parameters.indexOf('.') > -1
            ? '?' + field.parameters.split('.')[1] + '=' + getState().components[field.parameters.split('.')[0]].data[field.parameters.split('.')[1]].input
            : '?' + field.parameters + '=' + component.data[field.parameters].input
            : ''

        const payload = {
            component: component,
            model: model,
            field: field,
            related: '',
            query: '?stock_vehicle=true',
            order: field.display,
        }

        dispatch({
            type: types.STR_GET_COMPONENT_MMCODE_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setLookupValue = (field, index, input, text) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const value = format(field, input)
        dispatch({
            type: types.STR_SET_FIELD_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                index: index,
                value: value,
            }
        })

        dispatch({
            type: types.STR_SET_FIELD_VALUE,
            payload: {
                component: field.component.name,
                field: field.name.replace('_id',''),
                index: index,
                value: text,
            }
        })
    }
}

export const setRelatedData = (component, field, inx, value) => {
    return (dispatch, getState) => {

        const payload = {
            component: component,
            field: field,
            inx: inx,
            related: field.related ? field.display.split('.')[0] : 'all',
            query: value,
        }

        dispatch({
            type: types.STR_GET_VEHICLES,
            payload: payload
        })
    }
}

export const setStockRelatedData = (component, field, inx, value) => {
    return (dispatch, getState) => {

      
        const payload = {
            component: component,
            field: field,
            inx: inx,
            related: 'mmcode.ratecard',
            query: value,
        }

        dispatch({
            type: types.STR_GET_STOCK_VEHICLE_RATECARD,
            payload: payload
        })
    }
}

export const setSTRRelatedData = (component, field, inx, value) => {
    return (dispatch, getState) => {
        const payload = {
            component: component,
            field: field,
            inx: inx,
            related: 'ratecard',
            query: value,
        }

        dispatch({
            type: types.STR_GET_STR_VEHICLE_RATECARD,
            payload: payload
        })
    }
}

export const setRateCardDetails = (component, field, inx, payload) => {
    const data = payload.data.map(x => {
        return {
            vehicleprice: parseFloat(x.vehicleprice),
            discount: parseFloat(x.discount),
            deliveryperiod: parseInt(x.deliveryperiod),
            rvpercentage: parseInt(x.residualvalue),
            primerate: parseFloat(x.primerate),
            deviation: parseFloat(x.deviation),
            maintcpk: parseFloat(x.mmcode.maintcpk),
            tyrecpk: parseFloat(x.mmcode.tyrescpk),
            manualtotalmaintcpk: parseFloat(x.manualtotalmaintcpk),
            financeexcess: parseFloat(x.financeexcess),
            interestrate: parseFloat(x.primerate) + parseFloat(x.deviation),
        }

    })

    return (dispatch, getState) => {
        dispatch({
            type: types.STR_SET_FORM_MMCODE_DETAILS,
            payload: {
                component: component,
                inx: inx,
                data: data[0]
            }
        })
    }
}

export const setMMCodeDetails = (component, field, inx, payload) => {
      const _data = payload.data.map(x => {
        return x.mmcode 
            ? x.mmcode.ratecard
                ?  {...x.mmcode.ratecard[0], mmcode: x.mmcode }
                : x.mmcode
            : x
    })

    const data = _data.map(x => {
        return {
            vehicleprice: parseFloat(x.vehicleprice),
            discount: parseFloat(x.discount),
            deliveryperiod: parseInt(x.deliveryperiod),
            rvpercentage: parseInt(x.residualvalue),
            primerate: parseFloat(x.primerate),
            deviation: parseFloat(x.deviation),
            maintcpk: parseFloat(x.mmcode.maintcpk),
            tyrecpk: parseFloat(x.mmcode.tyrescpk),
            manualtotalmaintcpk: parseFloat(x.manualtotalmaintcpk),
            financeexcess: parseFloat(x.financeexcess),
            interestrate: parseFloat(x.primerate) + parseFloat(x.deviation),
        }

    })

    return (dispatch, getState) => {
        dispatch({
            type: types.STR_SET_FORM_MMCODE_DETAILS,
            payload: {
                component: component,
                inx: inx,
                data: data[0]
            }
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]
   
    const _data = payload.data.map(x => {
        return (typeof x.mmcode === 'object') ? x.mmcode : x
    })

    const data = _data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.STR_SET_COMPONENT_MMCODE_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data
            }
        })
    }
}

export const setValue = (field, index, value) => {
    const payload = {
        component: field.component.name,
        field: field.name,
        index: index,
        value: value
    }

    return (dispatch, getState) => {
        dispatch({
            type: types.STR_SET_FIELD_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                index: index,
                value: value
            }
        })
    }
}


export const removeSelectedRow = (component, row) => {
    return (dispatch, getState) => {
        const payload = component.grid.data.filter((x, i) => i !== row.index)

        const data = {
            component: component.name,
            data: payload
        }
        dispatch({
            type: types.STR_REMOVE_MMCODE_VEHICLE_DETAILS,
            payload: data
        })
    }
}


