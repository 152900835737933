export const customer_id = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}

export const customer = {
    errorText: 'Customer field required',
    value: '',
    valid: false,
    empty: true,
}

export const mmcode = {
    errorText: 'MM code field required',
    value: '',
    valid: false,
    empty: true,
}
export const mmcode_id = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}

export const vehicle = {
    errorText: 'MM code field required',
    value: '',
    valid: false,
    empty: true,
}

export const vehicle_id = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}

export const licenseplateno = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}

export const licensediskno = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}

export const engineno = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}

export const chassisno = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}


export const yearmodel = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}
export const contracttype = {
    errorText: 'MM code field required',
    value: '',
    valid: false,
    empty: true,
}
export const contracttype_id = {
    errorText: 'Vehicle selection category field required',
    value: '',
    valid: false,
    empty: true,
}
export const supplier = {
    errorText: 'SUpplier field required',
    value: '',
    valid: false,
    empty: true,
}
export const supplier_id = {
    errorText: 'SUpplier field required',
    value: '',
    valid: false,
    empty: true,
}
export const startdate = {
    errorText: 'Start date field required',
    value: '',
    valid: false,
    empty: true,
}
export const enddate = {
    errorText: 'End date field required',
    value: '',
    valid: false,
    empty: true,
}
export const regdate = {
    errorText: 'reg date field required',
    value: '',
    valid: false,
    empty: true,
}
export const licenseexpiry = {
    errorText: 'License Expiry Date',
    value: '',
    valid: false,
    empty: true,
}
export const service = {
    errorText: 'End date field required',
    value: '',
    valid: false,
    empty: true,
}
export const odometer = {
    errorText: 'End date field required',
    value: '',
    valid: false,
    empty: true,
}
export const tracking = {
    errorText: 'Month field required',
    value: '',
    valid: false,
    empty: true,
}
export const period = {
    errorText: 'Month field required',
    value: '',
    valid: false,
    empty: true,
}
export const contractdistance = {
    errorText: 'Month field required',
    value: '',
    valid: false,
    empty: true,
}
export const distancepermonth = {
    errorText: 'Month field required',
    value: '',
    valid: false,
    empty: true,
}
export const color = {
    errorText: 'Color field required',
    value: '',
    valid: false,
    empty: true,
}

export const color_data = []
export const customer_data = []
export const supplier_data = []
export const mmcode_data = []
export const vehicle_data = []
export const mm_service_data = []
export const contracttype_data = []
export const mm_service_selected = []

export const quotationnotes = {
    errorText: 'Quotation Notes is empty',
    value: '',
    valid: false,
    empty: true,
}

export const monthly_excl = {
    errorText: 'Montly excl rate field empty',
    value: '',
    valid: false,
    empty: true,
}
export const monthly_incl = {
    errorText: 'Montly incl rate field empty',
    value: '',
    valid: false,
    empty: true,
}

export const monthly_vat = {
    errorText: 'Montly vat field empty',
    value: '',
    valid: false,
    empty: true,
}

export const validation = {
    fields: ['customer', 'mmcode'],
    list: [],
    component: 'createMMQuote',
    valid: false
}
export const mm_customer_selected = {}
export const isChecked = 'false'

export const fields = {
    documents: {
        errorText: 'Please add a document and select document type',
        data: false,
        valid: false,
        empty: true,
        popup: true,
        type: 'documents',
    },


    mmItems: {
        state: 'getState().createMMQuote.mm_service_data.length ? (getState().createMMQuote.mm_service_data.find(x => x.checked === true) ? true : false) : true',
        errorText: 'Please select at least one Item',
        valid: false
    },


    color: {
        state: 'getState().createMMQuote.color.value',
        errorText: 'Please select Vehicle Color',
        valid: false
    },
    mmcode: {
        state: 'getState().createMMQuote.mmcode.value',
        errorText: 'Please select Make and Model',
        valid: false
    },
    licenseexpiry: {
        state: 'getState().createMMQuote.licenseexpiry.value',
        errorText: 'Please input License Expiry Date',
        valid: false
    },
    odometer: {
        state: 'getState().createMMQuote.odometer.value',
        errorText: 'Please input Odometer',
        valid: false,
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Odometer'
            }
        ]
    },
    yearmodel: {
        state: 'getState().createMMQuote.yearmodel.value',
        errorText: 'Please input Vehicle Registration Date',
        valid: false
    },
    chassisno: {
        state: 'getState().createMMQuote.chassisno.value',
        errorText: 'Please input Chasis Number',
        valid: false
    },
    engineno: {
        state: 'getState().createMMQuote.engineno.value',
        errorText: 'Please input Engine Number',
        valid: false
    },
    licensediskno: {
        state: 'getState().createMMQuote.licensediskno.value',
        errorText: 'Please input Vehicle Register',
        valid: false
    },
    licenseplateno: {
        state: 'getState().createMMQuote.licenseplateno.value',
        errorText: 'Please input Vehicle Registration',
        valid: false
    },


    enddate: {
        state: 'getState().createMMQuote.enddate.value',
        errorText: 'End date field required',
        valid: false
    },
    startdate: {
        state: 'getState().createMMQuote.startdate.value',
        errorText: 'Start date field required',
        valid: false
    },
    period: {
        state: 'getState().createMMQuote.period.value',
        errorText: 'Please enter Contract Period',
        valid: false,
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Contract Period'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Contract Period'
            }
        ]
    },
    customer_id: {
        state: 'getState().createMMQuote.customer_id.value',
        errorText: 'Please select a Customer',
        valid: false
    },
    contracttype_id: {
        state: 'getState().createMMQuote.contracttype_id.value',
        errorText: 'Please select a Contract Type',
        valid: false
    }
}