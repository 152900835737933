import * as types from './types'
import * as appTypes from '../../App/types'
import * as uploadTypes from '../../Upload/types'

import moment from 'moment'
import { ExcelRenderer, OutTable } from 'react-excel-renderer'

export const setSetinputField = (data) => {
    return (dispatch, getState) => {
        //console.log('setSetinputField')
        //console.log(data)
        dispatch({
            type: types.SET_DISPOSAL_COMPONENT_INPUT_FIELD,
            payload: data
        })
    }
}

export const chipFilter = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DISPOSAL_COMPONENT_CHIP_FILTER,
            payload: filter
        }) 
    }
}
export const setCurrentTab = (tab) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DISPOSAL_CURRENT_TAB,
            payload: tab
        })
    }
}

export const doRefresh = () => {
    return (dispatch) => {
        dispatch(getDisposalList())
        dispatch(getOTP())
        dispatch(getBidders())
    }
}
export const openDisposal = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.OPEN_VEHICLE_DISPOSAL_SCREEN,
        })
    }
}

export const setDataFilter = (filters) => {
    return (dispatch, getState) => {
        //console.log(filters)
    }
}

export const closeComments = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DISPOSAL_COMPONENT_INPUT_FIELD,
        })
    }
}
export const submitAndCloseComment = (id,comment,firstname) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DISPOSAL_USER_COMMENTS_INPUT,
            payload: {
                type: 'SET_DISPOSAL_USER_COMMENTS_INPUT',
                disposal_id: id,
                comment: comment,
                commentby: firstname
            }
        })
    }
}
export const submitSeeingStatus = (id,firstname) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DISPOSAL_USER_SEEING_INPUT,
            payload: {
                type: 'SET_DISPOSAL_USER_SEEING_INPUT',
                disposalcomments_id: id,
                seeingby: firstname
            }
        })
    }
}
export const setCommentsAndClose = (response,payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DISPOSAL_USER_COMMENTS_INPUT_FIELD,
        })
        dispatch(closeComments())
    }
}

export const getDisposalList = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_DISPOSAL_DATA_LIST,
        })
    }
}

export const getOTP = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_DISPOSAL_OPT_LIST,
        })
    }
}
export const setOTP = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_DISPOSAL_OPT_LIST,
            payload: response.data
        })
    }
}
export const setDisposalList = (response) => {
    return (dispatch, getState) => {
        let data = response.data.map(x => {
            return {
                ...x,
                showdetails: false,
                showcomments: false
            }
        })
        dispatch({
            type: types.SET_WORKFLOW_DISPOSAL_DATA_LIST,
            payload: data
        })
    }
}

export const toggleWorkflowDisposal = (name) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_WORKFLOW_DISPOSAL,
            payload: {
                name: name
            }
        })
    }
}

export const toggleItemProps = (inx, prop, val) => {
    return (dispatch, getState) => {
        let data = getState().workflowDisposal.yardlist
        //console.log('toggleItemProps ', data)
        //console.log('inx ', inx)
        //console.log('prop ', prop)
        //console.log('val ', val)
        data[inx][prop] = !val
        dispatch({
            type: types.SET_WORKFLOW_DISPOSAL_DATA_LIST,
            payload: data
        })
    }
}

export const setCheckSelection = (inx, checked) => {
    return (dispatch, getState) => {
        let data = getState().workflowDisposal.yardlist
        data[inx]['checked'] = checked
        dispatch({
            type: types.SET_WORKFLOW_DISPOSAL_DATA_LIST,
            payload: data
        })
    }
}
export const toggleAddOffer = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_ADD_VEHICLE_OFFER,
            payload: !val
        })
    }
}

export const loadOtpList = (listnumber) => {
    //console.log('listnumber',listnumber)
    return (dispatch, getState) => {
        let otp_list = getState().workflowDisposal.yardlist.filter(x => x.listnumber === listnumber)
        dispatch({
            type: types.SET_OTP_VEHICLE_LIST,
            payload: otp_list
        })
    }
}

export const toggleInfoSheet = (inx, infosheet) => {
    return (dispatch, getState) => {
        let status = getState().workflowDisposal[infosheet]
        //console.log('toggleInfoSheet', status)
        //console.log('toggleInfoSheet', !status)
        //console.log('toggleInfoSheet', infosheet)


        dispatch(getDisposalList())
        dispatch(getOTP())
        dispatch(getBidders())

        dispatch({
            type: types.TOGGLE_WORKFLOW_DISPOSAL_INFO_SHEET,
            payload: {
                infosheet: infosheet,
                status: !status,
                inx: inx
            }
        })
    }
}
export const downlaodDocument = (document) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.DOWNLAOD_DISPOSAL_COMPONENT_DOCUMENT,
            payload: {
                ...document,
                type: 'DOWNLAOD_DISPOSAL_COMPONENT_DOCUMENT'
            }
        })
    }
}

export const exportToExcel = () => {
    return (dispatch, getState) => {
        let data = getState().workflowDisposal.yardlist
        let header = getState().workflowDisposal.excel
        
        //console.log('header',header)
        dispatch({
            type: types.EXPORT_YARDLIST_TO_EXCEL,
            payload: {
                data: data,
                header: header,
                type: 'EXPORT_YARDLIST_TO_EXCEL'
            }
        })
    }
}
export const exportOffers = (listnumber) => {
    return (dispatch, getState) => {
        let data = getState().workflowDisposal.yardlist.filter(x => x.listnumber === listnumber)
        let header = getState().workflowDisposal.offer_export_headers //.filter(x => x.key === false)
        dispatch({
            type: types.EXPORT_YARDLIST_TO_EXCEL,
            payload: {
                data: data,
                header: header,
                listnumber: listnumber,
                type: 'EXPORT_YARDLIST_TO_EXCEL'
            }
        })
    }
}
export const viewPdfDocument = (data) => {

    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())

    window.glyco.log('data.data.document')
    window.glyco.log(data.data)

    saveByteArray([data.data.content.Body], 'STR Workings Preview.pdf')

    // return (dispatch, getState) => {
    //     dispatch({
    //         type: types.SET_STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT,
    //         payload: {
    //             url: data.data.document.key
    //         }
    //     })
    // }
}


export const getImportTemplateList = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_IMPORT_FILE_TEMPLATE_LIST,
            payload: search
        })
    }
}

export const setImportTemplateList = (payload) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>> setTemplateList')
        //console.log(payload)
        dispatch({
            type: types.SET_IMPORT_FILE_TEMPLATE_LIST,
            payload: (payload.data ? payload.data : [])
        })
    }
}

export const resetInfosheet = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_ALL_INFOSHEETS,
        })
    }
}

export const resetImport = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_IMPORT_COMPONENT_DATA,
            payload: []
        })
    }
}
export const setSelectedTemplate = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_IMPORT_TEMPLATE,
            payload: val
        })
    }
}

export const uploadFileData = (data) => {
    //console.log('uploadFileData', data)


    return (dispatch, getState) => {
        dispatch({
            type: types.UPLOAD_IMPORT_FILE_DATA,
            payload: {
                type: 'UPLOAD_IMPORT_FILE_DATA',
                rows: data.import_rows,
                template: data.template,
                import_bidder: data.import_bidder,
            }
        })
    }
}

export const uploadImportDataDone = (data) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> uploadFileDataDone', data)
        
        dispatch({
            type: types.RESET_IMPORT_DATA_SCREEN,
            payload: true
        })    
        
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'File import successfully completed...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const resetChecksheeetDocuments = (inx) => {
    return (dispatch, getState) => {
    dispatch({
        type: types.SET_DISPOSAL_COMPONENT_INPUT_FIELD,
        payload: {
            value: null,
            prop: 'checksheet_preview',
            inx: inx,
        }
    })
}
}


export const acceptedChecksheeetDocuments = (documents, inx) => {
    return (dispatch, getState) => {
        let data = getState().workflowDisposal.yardlist[inx]
        //console.log('acceptedChecksheeetDocuments',data)
        const filename = 'Signed_Disposal_Checkedsheet_'+data.licenseplateno+'.pdf'
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_DISPOSAL_SIGNED_CHECKSHEET,
                    payload: {
                            content: fileAsBinaryString,
                            filename: filename,
                            type: 'UPLOAD_DISPOSAL_SIGNED_CHECKSHEET', // file.type,
                            vehicle_id: data.vehicle_id,
                            filetype: file.type,
                            description: 'DISPOSAL CHECKSHEET'
                    }
                })
            };
        });

        dispatch({
            type: types.SET_DISPOSAL_COMPONENT_INPUT_FIELD,
            payload: {
                value: documents[0].preview,
                prop: 'checksheet_preview',
                inx: inx,
            }
        })
        
        dispatch({
            type: types.SET_DISPOSAL_COMPONENT_INPUT_FIELD,
            payload: {
                value: documents[0].preview,
                prop: inx + '_checksheet_preview',
                inx: inx,
            }
        })
    }
}

export const setAcceptedDocuments = (documents) => {
    return (dispatch, getState) => {
        // dispatch({
        //     type: types.SET_UPLOADED_EXCEL_FILE_BUSY,
        //     payload: true
        // })

        documents.forEach(file => {
            //console.log('File',file)
            ExcelRenderer(file, (err, resp) => {
                if (err) {
                    //console.log(err);
                }
                else {
                    //console.log(resp.rows.filter(x => x.length).filter(x => x.length > 1))

                    //console.log('resp.rows', resp.rows)

                    dispatch({
                        type: types.SET_IMPORTED_DATA_ROWS,
                        payload: resp.rows.filter(x => x.length).filter(x => x.length > 1)
                    })
                }
            });
        });
        //console.log('SET_UPLOADED_EXCEL_FILE_NAME')
        //console.log(documents[0])
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_NAME,
            payload: {
                preview: documents[0].preview,
                filename: documents[0].name,
            }
        })
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_BUSY,
            payload: false
        })
    };
}

//Save saveOffer


export const saveOffer = (vehicle_id,licenseplateno) => {
    return (dispatch, getState) => {
        let biddername = getState().workflowDisposal.biddername
        let repairoffer = getState().workflowDisposal.repairoffer
        let nonrepairoffer = getState().workflowDisposal.nonrepairoffer

        dispatch({
            type: types.POST_COMPONENT_DISPOSAL_DATA,
            payload: {
                data: {
                    biddername: biddername,
                    repairoffer: repairoffer,
                    nonrepairoffer: nonrepairoffer,
                    licenseplateno: licenseplateno
                    // rebillvalue: rebillvalue
                },
                vehicle_id: vehicle_id,
                done: 'Bidder offer successfully save',
                type: 'SAVE_COMPONENT_DISPOSAL_NEW_OFFER'
            }
        })
    }
}

export const acceptVehicleOffer = (disposalbidder_id,user_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.POST_COMPONENT_DISPOSAL_DATA,
            payload: {
                disposalbidder_id: disposalbidder_id,
                user_id: user_id,
                done: 'Bidder offer successfully accepted',
                type: 'ACCEPT_COMPONENT_DISPOSAL_VEHICLE_OFFER'
            }
        })
        dispatch(doRefresh())
    }
}

export const saveUserComment = (vehicle_id,user) => {
    return (dispatch, getState) => {
        let comment = getState().workflowDisposal.commentfield
        dispatch({
            type: types.POST_COMPONENT_DISPOSAL_DATA,
            payload: {
                comment: comment,
                user: user,
                vehicle_id: vehicle_id,
                done: 'Your comments has been successfully saved',
                type: 'SAVE_COMPONENT_DISPOSAL_VEHICLE_COMMENT'
            }
        })
        
        dispatch(setSetinputField({prop: 'commentfield', value: ''}))
        dispatch(doRefresh())
        dispatch(sendCommentsMail({
            comment: comment,
            user: user,
            vehicle_id: vehicle_id,
        }))
    }
}

export const saveUserDecision = (vehicle_id,user) => {
    return (dispatch, getState) => {
        let yardlist = getState().workflowDisposal.yardlist.filter(x => x.checked === true).map(x => x.vehicle_id)
        //console.log('yardlist',yardlist)
        let decisioncomments = getState().workflowDisposal.decisioncomments 
        let decision  = getState().workflowDisposal.decision  
        if(yardlist.length){
            for (let x of yardlist){
                dispatch({
                    type: types.POST_COMPONENT_DISPOSAL_DATA,
                    payload: {
                        decisioncomments: decisioncomments,
                        user: user,
                        decision: decision,
                        vehicle_id: x,
                        done: 'Your decision has been successfully saved',
                        type: 'SAVE_COMPONENT_DECISION_COMMENT'
                    }
                })
            }
        }
        else {
            dispatch({
                type: types.POST_COMPONENT_DISPOSAL_DATA,
                payload: {
                    decisioncomments: decisioncomments,
                    user: user,
                    decision: decision,
                    vehicle_id: vehicle_id,
                    done: 'Your decision has been successfully saved',
                    type: 'SAVE_COMPONENT_DECISION_COMMENT'
                }
            })
        }
        dispatch(setSetinputField({prop: 'decisioncomments', value: ''}))
        dispatch(setSetinputField({prop: 'decision', value: ''}))
        dispatch(doRefresh())
    }
}

export const generateOPTList = (user) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.POST_COMPONENT_DISPOSAL_DATA,
            payload: {
                user: user,
                done: 'OTP has been successfully generated',
                fail: 'OTP failed not records found',
                type: 'GENERATE_VEHICLE_OTP_LIST'
            }
        })
    }
}

export const updateVehiclePrice = (vehicle_id) => {
    return (dispatch, getState) => {
        let yardlist = getState().workflowDisposal.yardlist.filter(x => x.checked === true).map(x => x.vehicle_id)
        //console.log('yardlist',yardlist)
        let retailvalue = getState().workflowDisposal.retailvalue
        let tradevalue = getState().workflowDisposal.tradevalue
        let marketvalue = getState().workflowDisposal.marketvalue
        if(yardlist.length){
            for (let x of yardlist){
                dispatch({
                    type: types.POST_COMPONENT_DISPOSAL_DATA,
                    payload: {
                        data: {
                            retailvalue: retailvalue,
                            tradevalue: tradevalue,
                            marketvalue: marketvalue,
                        },
                        vehicle_id: x,
                        done: 'Vehicle price successfully update',
                        type: 'UPDATE_COMPONENT_DISPOSAL_VEHICLE_PRICE'
                    }
                })
            }
        }
        else {
            dispatch({
                type: types.POST_COMPONENT_DISPOSAL_DATA,
                payload: {
                    data: {
                        retailvalue: retailvalue,
                        tradevalue: tradevalue,
                        marketvalue: marketvalue,
                    },
                    vehicle_id: vehicle_id,
                    done: 'Vehicle price successfully update',
                    type: 'UPDATE_COMPONENT_DISPOSAL_VEHICLE_PRICE'
                }
            })
        }
        dispatch(doRefresh())
    }
}

export const updateSettlementDetails = (vehicle_id) => {
    return (dispatch, getState) => {
        let funder_settlement_amount = getState().workflowDisposal.funder_settlement_amount
        let acc_depreciation = getState().workflowDisposal.acc_depreciation
        let retailvalue = getState().workflowDisposal.retailvalue
        dispatch({
            type: types.POST_COMPONENT_DISPOSAL_DATA,
            payload: {
                data: {
                    funder_settlement_amount: funder_settlement_amount,
                    acc_depreciation: acc_depreciation,
                    retailvalue: retailvalue,
                },
                vehicle_id: vehicle_id,
                done: 'Vehicle price settlement update',
                type: 'UPDATE_COMPONENT_DISPOSAL_SETTLEMENT_DETAILS'
            }
        })
    }
}
export const updateDisposalDetails = (vehicle_id) => {
    return (dispatch, getState) => {
        let absa_residual_value = getState().workflowDisposal.absa_residual_value
        let absa_remaining_months = getState().workflowDisposal.absa_remaining_months
        dispatch({
            type: types.POST_COMPONENT_DISPOSAL_DATA,
            payload: {
                data: {
                    absa_residual_value: absa_residual_value,
                    absa_remaining_months: absa_remaining_months,
                },
                vehicle_id: vehicle_id,
                done: 'Vehicle disposal value update',
                type: 'UPDATE_COMPONENT_DISPOSAL_DETAILS'
            }
        })
    }
}
export const calcMarketVal = () => {
    return (dispatch, getState) => {
        let retailvalue = getState().workflowDisposal.retailvalue
        let tradevalue = getState().workflowDisposal.tradevalue
        let marketvalue = parseFloat(retailvalue) + parseFloat(tradevalue)
        dispatch(setSetinputField({prop: 'marketvalue', value: marketvalue}))
    }
}
export const postDisposalDataDone = (response, payload) => {
    return (dispatch, getState) => {
        
        // dispatch({
        //     type: types.RESET_IMPORT_DATA_SCREEN,
        //     payload: true
        // })    
        if(response.response.data.status === 'failed'){
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    type: 'danger',
                    title: 'System Notification',
                    additionalText: payload.fail,
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
        else 
        {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: payload.done,
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
            dispatch(getOTP())
        }
    }
}

export const getBidders = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BIDDERS_DISPOSAL_DATA_LIST,
            payload: {}
        })
    }
}

export const getChecksheeet = (inx) => {
    return (dispatch, getState) => {
        let data = getState().workflowDisposal.yardlist
        //console.log('GET_DISPOSAL_CHEEKSHEET_DOCUMENT', {...data[inx], user:  getState().user})
        dispatch({
            type: types.GET_DISPOSAL_CHEEKSHEET_DOCUMENT,
            payload: {...data[inx], user:  getState().user,type: 'GET_DISPOSAL_CHEEKSHEET_DOCUMENT'}
        })
    }
}

export const getInvoice = (inx) => {
    return (dispatch, getState) => {
        let data = getState().workflowDisposal.yardlist
        //console.log('GET_DISPOSAL_INVOICE_DOCUMENT', {...data[inx], user:  getState().user})
        dispatch({
            type: types.GET_DISPOSAL_INVOICE_DOCUMENT,
            payload: {...data[inx], user:  getState().user,type: 'GET_DISPOSAL_INVOICE_DOCUMENT'}
        })
    }
}

export const downlaodDisposalChecksheet = (data) => {
    return (dispatch, getState) => {
        const saveByteArray = (function () {
            let a = document.createElement('a')
            document.body.appendChild(a)
            a.style = 'display: none'
    
            return (data, name) => {
                const blob = new Blob(data, { type: 'octet/stream' })
                const url = window.URL.createObjectURL(blob)
    
                a.href = url
                a.download = name
                a.click()
                window.URL.revokeObjectURL(url)
            }
        }())
    
        window.glyco.log('data.data.disposal check sheet',data)
    
        saveByteArray([data.data.pdf.Body], 'Disposal_Checksheet.pdf')
    
    }
}
export const downlaodDisposalInvoice = (data) => {
    return (dispatch, getState) => {
        const saveByteArray = (function () {
            let a = document.createElement('a')
            document.body.appendChild(a)
            a.style = 'display: none'
    
            return (data, name) => {
                const blob = new Blob(data, { type: 'octet/stream' })
                const url = window.URL.createObjectURL(blob)
    
                a.href = url
                a.download = name
                a.click()
                window.URL.revokeObjectURL(url)
            }
        }())
    
        window.glyco.log('data.data.disposal check sheet',data)
    
        saveByteArray([data.data.pdf.Body], 'Disposal_Invoice.pdf')
    
    }
}
export const setBidders = (response) => {
    return (dispatch, getState) => {
        //console.log('setBidders',response)
        dispatch({
            type: types.SET_BIDDERS_DISPOSAL_DATA_LIST,
            payload: response.response.data
        })
    }
}

export const sendBidderMail = (bidder, data) => {
    return (dispatch, getState) => {
        let header = getState().workflowDisposal.offer_export_headers ///.filter(x => x.key === false)
        dispatch({
            type: types.EXPORT_YARDLIST_TO_EXCEL,
            payload: {
                data: data,
                header: header,
                bidder: bidder,
                type: 'SEND_OFFER_EMAIL_TO_BIDDER'
            }
        })
    }
}

export const sendCommentsMail = (data) => {
    return (dispatch, getState) => {
        let header = getState().workflowDisposal.offer_export_headers ///.filter(x => x.key === false)
        dispatch({
            type: types.EXPORT_YARDLIST_TO_EXCEL,
            payload: {
                data: data,
                type: 'SEND_DISPOSAL_TEAM_COMMENTS_EMAIL'
            }
        })
    }
}

export const uploadUserDocument = (vehicle_id) => {
    return (dispatch, getState) => {
        let documents = getState().upload.documents
        dispatch({
            type: types.EXPORT_YARDLIST_TO_EXCEL,
            payload: {
                data: documents,
                vehicle_id: vehicle_id,
                type: 'UPLOAD_USER_DISPOSAL_VEHICLE_DOCUMENT'
            }
        })
        dispatch({
            type: uploadTypes.UPLOAD_COMPONENT_ADD_BLANK_UPLOAD,
            payload: []
        })
    }
}