import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.UPLOAD_COMPONENT_ADD_BLANK_UPLOAD:
            return {
                ...state,
                documents: action.payload,
            }
        case types.UPLOAD_COMPONENT_PRE_LOAD_UPLOADED_DOCUMENTS:
            return {
                ...state,
                documents: action.payload
            }
        case types.CLEAR_COMPONENT_PRE_LOAD_UPLOADED_DOCUMENTS:
            return {
                ...state,
                documents: null
            }
        case types.UPLOAD_COMPONENT_SET_FIELD_VALUE:
            return {
                ...state,
                documents: state.documents.map((x, i) =>
                action.payload.id === x.fileinx
                        ? {
                            ...x,
                            [action.payload.prop]: action.payload.value
                        }
                        : x
                )
            }
        case types.SET_PO_ACCESSORY_DOCUMENT_PREVIEW:
            return {
                ...state,
                documents: state.documents.map((x, i) =>
                action.payload.filename === x.filename
                        ? {
                            ...x,
                            preview: action.payload.preview
                        }
                        : x
                )
            }
        case types.SET_UPLOAD_DOCUMENT_PREVIEW:
            return {
                ...state,
                documents: state.documents.map((x, i) =>
                    action.payload.id === x.fileinx
                        ? {
                            ...x,
                            preview: action.payload.preview,
                            ponumber: action.payload.ponumber,
                            filename: action.payload.filename
                        }
                        : x
                )
            }
        default:
            return state
    }
}