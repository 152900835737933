import * as types from './types'

export const  getWorkFlowTasks = () => {

    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_TASKS,
            payload: {
                type: 'GET_WORKFLOW_TASKS'
            }
        })
    }
}

export const  setWorkFlowTasks = (payload) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASKS,
            payload: payload
        })
    }
}

export const updateWorkflowTaskUser = (workflow_task_id, value, doa, workflow_task_to_on_condition_value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_WORKFLOW_TASK_USER,
            payload: {
                type: 'UPDATE_WORKFLOW_TASK_USER',
                workflow_task_id: workflow_task_id,
                value: value,
                doa: doa,
                workflow_task_to_on_condition_value: workflow_task_to_on_condition_value
            }
        })
    }
}

export const setUpdateWorkflowTaskUser = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_UPDATE_WORKFLOW_TASK_USER,
            payload: payload
        })
    }
}

