import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getCustomerList = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_CUSTOMER_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customer/searchor?customername=' + payload.search + '&accountnumber=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'customername'
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setCustomerList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getSalesTypeList = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_SALESCODE_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/salestype/search?salestype=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'salestype'
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setSalesTypeList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
export const getAccountingItemList = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_ACCOUNTITEMS_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accountingitem/search?itemdescription=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setAccountingItemList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getAccountingItemLinkList = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_ACCOUNTING_ITEM_LINK_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accountingitemlink/search?customer_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'customer,salestype,accountingitem'
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setAccountingItemLinkList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const saveAccountingItemLink = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.SAVE_COMPONENT_ACCOUNTING_ITEM_LINK,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accountingitemlink',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
                    mergeMap((result) => [
                        actions.savedAccountingItemLink(payload),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const deLinkAcountingItem = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.DEL_COMPONENT_ACCOUNTING_ITEM_LINK,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accountingitemlink',
                method: 'DELETE',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
                    mergeMap((result) => [
                        actions.savedAccountingItemLink(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

// export const getAccountingItemList = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
//     action$.pipe(ofType(
//         types.GET_ACCOUNTITEMS_LIST,
//     )
//         , mergeMap(({ payload }) =>
//             ajax$({
//                 url: config.system.api + '/components/accountingitemlink/other',
//                 method: 'POST',
//                 headers: {
//                     'tokenx': store.value.user.user_id,
//                     'auth': store.value.cognito.authenticateUser.token,
//                     'salt': store.value.cognito.authenticateUser.jwt,
//                     'datetime': store.value.cognito.authenticateUser.datetime,
//                     'Content-Type': 'application/json'
//                 },
//                 body: payload
//             })
//                 .pipe(
//                     mergeMap((result) => [
//                         actions.setAccountingItemList(result.response),
//                         requestActions.requestSucceeded(config.system.api + '')
//                     ])
//                     , startWith(progressBarActions.progress(true))
//                     , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
//                 )
//         )
//         , catchError((error) =>
//             of$(routerActions.route('/support', false, error))
//         )
//     )