import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'


export const setInputField = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MANAGE_COMPONENT_INPUT_FIELD,
            payload: {
                prop: prop,
                value: value
            }
        })

    }
}

export const updateComponent = (component_id, field_name, field_value) => {
    return (dispatch, getState) => {
        //console.log('updateComponentx',{
        //     component_id: component_id,
        //     [field_name]: field_value
        // })
        dispatch({
            type: types.UPDATE_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                component_id: component_id,
                [field_name]: field_value
            }
        })

    }
}

export const updateComponentField = (component_field_id, field_name, field_value) => {
    return (dispatch, getState) => {
        //console.log('updateComponentFieldx',{
        //     component_field_id: component_field_id,
        //     [field_name]: field_value
        // })
        dispatch({
            type: types.UPDATE_COMPONENT_FIELD_INPUT_FIELD_VALUE,
            payload: {
                component_field_id: component_field_id,
                [field_name]: field_value
            }
        })

    }
}

export const updateComponentDone = (data) => {
    return (dispatch, getState) => {
        //console.log('updateComponentDone', data)
        dispatch(setInputField('done', true))
        // dispatch({
        //     type: appTypes.SHOW_NOTIFICATION,
        //     payload: {
        //         title: 'Update',
        //         additionalText: 'Update component field',
        //         overflowText: '',
        //         autoHide: 7500,
        //         timestamp: moment().format('h:mm A')
        //     }
        // })
    }
}

export const showNotification = (error) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                type: 'danger',
                title: 'System Error',
                additionalText: error,
                overflowText: getState().login.fields.email.input,
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}