import * as props from './props'

import * as types from './types'
import * as controlTypes from '../../../controls/types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_CREDITORS_PAYMENT_COMPONENT_REVIEW_AND_APPROVE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value

            }
        default:
            return state
    }
}