import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'

export const getComponentGridData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_QUOTE_GRID_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.component.name + '/search' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': payload.paging.pageSize,
                    'page': payload.paging.page + 1,
                    'order': payload.sorting.length ? payload.sorting : '-' + payload.component.orderby,
                    'related': payload.related
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setComponentGridData({
                        related: payload.related,
                        child: payload.child,
                        component: payload.component,
                        data: result.response
                    }),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/' + payload.component.name + '/search' + payload.query, result.response)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getQuoteDocumentList = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_QUOTE_DOCUMENT_LIST),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customerquotedocument/search?documenttype='+ payload.documenttype +'&quotenumber=' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': payload.related
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setQuoteDocumentList(result.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/customerquotedocument/search?quotenumber=' + payload.query, result.response)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


        
export const getQuoteDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.SEARCH_PO_QUOTE_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/' + payload.component + '/search' + payload.query,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'quotevehicleaccessory,customer,contracttype,mmcode'
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setQuoteDetails(payload.component, result.response),
                requestActions.requestSucceeded(config.system.api + '/components/' + payload.model + '/search')
            ])
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.model + '/search', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )
        