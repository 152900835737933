
export const fields = {
    days: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text !== 'Road Side') ? getState().poBooking.fields.days.value : true",
        errorText: 'Enter booking days',
        value: '',
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Number of Booking Days'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Number of Booking Days'
            }
        ],  
        valid: false,
        empty: true,
    },
    comments: {
        errorText: 'Please enter comments',
        value: '',
        valid: true,
        empty: true,
    },
    servicedescription: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Scheduled Maintenance' || getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Service' || getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Unscheduled Maintenance' || getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Panel Beaters' ) ? getState().poBooking.fields.servicedescription.value : true",
        errorText: 'Please enter Service description',
        value: '',
        valid: false,
        empty: true,
    },
    towdescription: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Road Side') ? getState().poBooking.fields.servicedescription.value : true",
        errorText: 'Please enter Tow description',
        value: '',
        valid: false,
        empty: true,
    },
    tyrereason: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Tyres') ? getState().poBooking.fields.servicedescription.value : true",
        errorText: 'Please enter reason for new tyres',
        value: '',
        valid: false,
        empty: true,
    },
    assessmentreason: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Assessor Fee') ? getState().poBooking.fields.servicedescription.value : true",
        errorText: 'Please enter reason for assessing',
        value: '',
        valid: false,
        empty: true,
    },
    serviceinterval: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Scheduled Maintenance' || getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Service') ? getState().poBooking.fields.serviceinterval.value : true",
        errorText: 'Next service interval field required',
        value: '',
        valid: false,
        empty: true,
    },
    millage: {
        errorText: 'Please enter current vehicle odometer',
        state: 'getState().poBooking.fields.millage.value ',
        value: '',
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Vehicle Odometer'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Vehicle Odometer'
            }
        ],        
        valid: false,
        empty: true,
    },
    servicedate: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Scheduled Maintenance' || getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Service' || getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Panel Beaters' ) ? getState().poBooking.fields.servicedate.value : true",
        errorText: 'Service date field required',
        value: '',
        valid: false,
        empty: true,
    },
    repairdate: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Unscheduled Maintenance') ? getState().poBooking.fields.servicedate.value : true",
        errorText: 'Repair date field required',
        value: '',
        valid: false,
        empty: true,
    },
    towDate: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Road Side') ? getState().poBooking.fields.servicedate.value : true",
        errorText: 'Tow date field required',
        value: '',
        valid: false,
        empty: true,
    },
    fitmentDate: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Tyres') ? getState().poBooking.fields.servicedate.value : true",
        errorText: 'Fitment date field required',
        value: '',
        valid: false,
        empty: true,
    },
    assessmentDate:{
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Assessor Fee') ? getState().poBooking.fields.servicedate.value : true",
        errorText: 'Assessment date field required',
        value: '',
        valid: false,
        empty: true,
    },
    incidentdate: {
        errorText: 'Enter incident date',
        value: null,
        valid: false,
        empty: true,
    },
    drivable: {
        state: 'getState().poBooking.fields.drivable.value === false ? true : getState().poBooking.fields.drivable.value',
        errorText: 'Enter whether the car is drivable?',
        value: false,
        valid: false,
        empty: true,
    },

    drivername: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Unscheduled Maintenance' || getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Road Side' || getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Tyres') ? getState().poBooking.fields.drivername.value : true",
        errorText: 'Driver name field required',
        value: '',
        valid: false,
        empty: true,
    },
    tyrescount: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType.data.text === 'Tyres') ? getState().poBooking.fields.tyrescount.value : true",
        errorText: 'Tyre count required',
        value: '',
        valid: false,
        empty: true,
    },
    supplier_over_limit: {
        errorText: 'Selected supplier have exceeded its monthly spent',
        state: 'getState().controls.supplier_in_limit',
        data: false,
        valid: false,
        empty: true,
        popup: true,
    }
}

// export const validation: {
//    // fields: ['servicedate', 'millage', 'servicedescription', 'comments','incidentdate'],
//     list: [],
//     component: 'poBooking',
//     valid: false
// }
// list: [
//     {
//         comp: 'poBookingItems',
//         prop: 'grid',
//         errorText: 'Please enter quote items'
//     }
// ],
//export const servicedata = {}
