import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_THIS_COMPONENT:
            return {
                ...state,
                users: [],
                vehicles: [],
                supplierage: [],
                bookinginfo: [],
                selectedVehicle: [],
                geoSuppliers: null,
                vehicleserviceinfo: [],
                defleetpdf_preview: '',
                defleetpdf_filename: '',
            }
        case types.SET_DEFLEET_SIGNOFF:
            return {
                ...state,
                defleetpdf_preview: action.payload.preview,
                defleetpdf_filename: action.payload.filename
            }
        case types.SET_QUOTE_ITEMS:
            return {
                ...state,
                quoteItems: action.payload
            }
        default:
            return state
    }
}