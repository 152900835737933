import * as actions from "./actions";
import * as progressBarActions from "../../ProgressBar/actions";
import * as requestActions from "../../Request/actions";
import * as routerActions from "../../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../../Request/types";
import * as config from "../../../config";
import moment from "moment";

export const getCreditNoteLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_CREDIT_NOTE_COMPONENT_LOOKUP_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.component + '/search' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setLookupData(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )


export const getCreditNoteSupplierTransaction = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_CONTROL_VIEW_SUPPLIER_TRANSACTION),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customertransaction/search?allocated=false&customer_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'customertransactionitem'

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setComponentCustomerTransaction(result.response,payload),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )