import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';

import Form from './form'
import Grid from './grid'
import View from './view'

import * as styles from './styles'
import * as actions from './actions'

class TeamManager extends Component {
    render() {
        return <div>
                <Tabs
                    initialSelectedIndex={0}
                    value={this.props.state.tab}
                    inkBarStyle={{
                        height: 4
                    }}
                    onChange={(event, value) => {
                        for(let x of this.props.state.teamManager.component_field){
                            this.props.actions.setComponentFieldInput(x,null)
                        }
                        this.props.actions.changeTabView(value)
                    }}
                >
                    <Tab style={{minWidth:"50%"}} label={'Teams'} />
                    <Tab style={{minWidth:"50%"}} label={'Create'} />
                </Tabs>
                <br/>
                <div>
                    {this.props.state.tab === 0 && <Box><Form datasource={'teamManager'} fields={this.props.state.fields} /></Box>}
                    {this.props.state.tab === 1 && <Box><Grid /></Box>}
                    {this.props.state.tab === 2 && <Box><View /></Box>}
                </div>

                <Dialog
                    open={this.props.state.delete_record_id ? true : false}
                    style={{ width: '100% !important', maxWidth: '100% !important' }}
                    fullWidth={true}
                    maxWidth="xl"
                    >
                    <div className="text-center p-5">
                    <div className="avatar-icon-wrapper rounded-circle m-0">
                    
                    </div>
                    <h4 className="font-weight-bold mt-4">
                        Delete
                    </h4>  
                    <p className="mb-0 font-size-lg text-muted">
                        Are you you want to delete selected record
                    </p>
                    <div className="pt-4">
                        <Button
                        onClick={(event) => {
                            this.props.actions.toggleDeleteConfirm(0)
                        }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                        </Button>
                        <Button 
                            onClick={(event) => {
                                this.props.actions.toggleDeleteConfirm(0)
                                this.props.actions.deleteReselller(this.props.state.team_id)
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Delete</span>
                        </Button>
                    </div>
                    </div> 
                </Dialog>
            </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.teamManager,
            teamManager: {
                ...state.components.team
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamManager)