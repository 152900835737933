export const CLEAR_COMPONENT_UPLOAD_DOCUMENT_PREVIEW = 'CLEAR_COMPONENT_UPLOAD_DOCUMENT_PREVIEW'
export const SET_COMPONENT_UPLOAD_DOCUMENT_PREVIEW = 'SET_COMPONENT_UPLOAD_DOCUMENT_PREVIEW'
export const SET_COMPONENT_UPLOAD_DOCUMENT_FIELD_VALUE = 'SET_COMPONENT_UPLOAD_DOCUMENT_FIELD_VALUE'
export const SET_COMPONENT_UPLOAD_DOCUMENT_FIELD_TEXT = 'SET_COMPONENT_UPLOAD_DOCUMENT_FIELD_TEXT'
export const GET_CONTRACT_VAF_PREVIEW_DOCUMENTS = 'GET_CONTRACT_VAF_PREVIEW_DOCUMENTS'
export const UPLOAD_COMPONENT_UPLOAD_DOCUMENT = 'UPLOAD_COMPONENT_UPLOAD_DOCUMENT'
export const SET_VAT_DOCUMENT_PREVIEW = 'SET_VAT_DOCUMENT_PREVIEW'
export const SET_MAIN_LEASE_DOCUMENT_PREVIEW = 'SET_MAIN_LEASE_DOCUMENT_PREVIEW'
export const GET_CONTRACT_MAIN_LEASE_PREVIEW_DOCUMENTS = 'GET_CONTRACT_MAIN_LEASE_PREVIEW_DOCUMENTS'
export const SET_ACTIVATION_DOCUMENT_PREVIEW = 'SET_ACTIVATION_DOCUMENT_PREVIEW'
export const GET_CONTRACT_ACTIVATIONE_PREVIEW_DOCUMENTS = 'GET_CONTRACT_ACTIVATIONE_PREVIEW_DOCUMENTS'