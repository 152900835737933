import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card, CardHeader, CardContent, IconButton, Collapse, CardActions } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button";

import * as actions from './actions'
import * as styles from './styles'

class _section extends Component {  
    render() {
        return <CardHeader 
                title={<div style={{ fontSize: "18px", color: "white" }}>{this.props.title}</div>}
                subheader={<div style={{ fontSize: "13px", color: "white" }}>{this.props.description ? this.props.description : this.props.subtitle}</div>}
            >
            </CardHeader>
        
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.controls }
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(_section)
