import * as types from './types'
import moment from 'moment'

export const resetComponentPoBooking = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
    }
}

export const action = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.PO_SUBCOMPONENT_ACTION,
            payload: {}
        })
    }
}

export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setInputNumericValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_COMPONENT_FIELD_INPUT_VALUES,
            payload: {
                prop: prop,
                value: parseInt(value)
            }
        })
    }
}

export const setInputValue = (prop, value, valid) => {
    //console.log('setInputValue >>>>>>>>>> ', prop)
    //console.log('setInputValue >>>>>>>>>> ', value)
    //console.log('setInputValue >>>>>>>>>> ', valid)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_COMPONENT_FIELD_INPUT_VALUES,
            payload: {
                prop: prop,
                value: value,
                valid: valid === undefined ? (value ? true : false) : valid
            }
        })
    }
}


export const getServiceDetails = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICLE_SERVICE_DUE_FUNCTION,
            payload: {
                type: 'GET_VEHICLE_SERVICE_DUE_FUNCTION',
                vehicle_id: id
            }
        })
    }
}

export const setServiceDetails = (data) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_COMPONENT_FIELD_INPUT_VALUES,
            payload: {
                prop: 'serviceinterval',
                value: data.nextserviceodo + ' Km'
            }
        })
        
        dispatch({
            type: types.SET_BOOKING_COMPONENT_FIELD_INPUT_VALUES,
            payload: {
                prop: 'servicedate',
                value: new Date(data.nextservicedate)
            }
        })
        
        dispatch({
            type: types.SET_BOOKING_COMPONENT_FIELD_INPUT_VALUES,
            payload: {
                prop: 'millage',
                value: data.currentodo
            }
        })
    }
}