import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.MODAL_OPEN_MODAL:
            return {
                ...state,
                isOpen: true
            }
        case types.MODAL_CLOSE_MODAL:
            return {
                ...state,
                isOpen: false
            }
        default:
            return state
    }
}