import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.GET_ADHOC_FIELD_INPUT_DATA:
            return {
                ...state,
                [action.payload.key]: {
                    loading: true,
                    key: action.payload.key,
                    data: []
                }
            }
        case types.SET_ADHOC_FIELD_INPUT_DATA:
            return {
                ...state,
                [action.payload.key]: {
                    ...action.payload,
                    loading: false,
                    data: action.payload.data
                }
            }
        case types.ADD_ADHOC_FIELD_INPUT_DATA:
            return {
                ...state,
                [action.payload.key]: {
                    ...action.payload,
                    data: action.payload.data
                }
            }
        case types.SET_ADHOC_COMPONENT_FIELD_INPUT:
            return {
                ...state,
                [action.payload.key]: {
                    ...action.payload,
                    data: action.payload.data
                }
            }
        case types.VALIDATE_ADHOC_FIELD_INPUT_DATA:
            return state
        case types.REMOVE_ADHOC_FIELD_INPUT_DATA:
            return state
        default:
            return state
    }
}