import * as types from './types'
import * as appTypes from '../../App/types'
import moment from 'moment'
import _ from 'lodash'
import * as formulas from 'formulajs'

import * as headerTypes from '../types'

export const setView = (data) => {
    return (dispatch, getState) => {
        //console.log('setView', data)
        dispatch({
            type: headerTypes.SET_STR_COMPONENT_VIEW,
            payload: data
        })
    }
}

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_COMPONENT_RESET_FORM,
        })
    }
}

export const changeTabView = (inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_COMPONENT_SET_CURRENT_TAB_VIEW,
            payload: inx
        })
    }
}

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {

        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_WORKFLOW_CRAETE_STR_QUOTATION_HEADER_SELECTION,
            payload: payload
        })
    }
}
export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
            payload: data
        })
    }
}

export const setComponentProp = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setInputListValue = (data) => {
    return (dispatch, getState) => {
        let list = getState().strCreateQuote.accessory_list
        list[data.inx].ratepermonth = data.value
        list[data.inx].noofmonth = data.noofmonth
        list[data.inx].totalincome = (data.value * list[data.inx].noofmonth)
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
            payload: {
                prop: 'accessory_list',
                value: list
            }
        })
        dispatch(calTotals())
        dispatch(calWorkings())
    }
}

export const addNewItem = (data) => {
    return (dispatch, getState) => {
        let list = getState().strCreateQuote.accessory_list
        let startdate = getState().strCreateQuote.startdate.value
        let enddate = getState().strCreateQuote.enddate.value

        const _fromdate = moment(startdate)
        const _todate = moment(enddate)
        let fullmonths = _todate.diff(_fromdate, "months")


        list.push({
            accessoryname: null,
            accessory_id: null,
            ratepermonth: 0.00,
            noofmonth: fullmonths,
            totalincome: 0.00
        })
        if (parseInt(fullmonths) > 0) {
            dispatch({
                type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
                payload: {
                    prop: 'accessory_list',
                    value: list
                }
            })
        } else {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Please select period before you add accessories',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}
export const setAccesoryList = (data) => {
    return (dispatch, getState) => {
        let list = getState().strCreateQuote.accessory_list
        list[data.inx].accessoryname = data.text
        list[data.inx].accessory_id = data.value
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
            payload: {
                prop: 'accessory_list',
                value: list
            }
        })
    }
}
export const removeSelectedAccessory = (data) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>> removeSelectedAccessory')
        //console.log(data)
        let list = getState().strCreateQuote.accessory_list
        list = list.filter(x => x.accessory_id !== data.accessory_id)

        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
            payload: {
                prop: 'accessory_list',
                value: list
            }
        })
    }
}

export const calTotals = () => {
    return (dispatch, getState) => {
        let _rental = parseFloat(getState().strCreateQuote.rentalamount.value)
        let _roi = parseFloat(getState().strCreateQuote.roi.value)
        let _loadfactorperc = parseFloat(getState().strCreateQuote.loadfactorperc.value)
        let _accessory_list = getState().strCreateQuote.accessory_list
        let _totalaccessory = _.sumBy(_accessory_list, (d) => { return parseFloat((d.ratepermonth) ? d.ratepermonth : 0) }).toFixed(2)

        //console.log('>>>>>>>>>>>>>>>>> calTotals')
        //console.log(_totalaccessory)
        //console.log(_rental)
        //console.log(_roi)
        //console.log(_rental + _roi)
        let __stramount = (_rental + _roi + parseFloat(_totalaccessory)).toFixed(2)

        
        let _loadfactoramount = (__stramount * (_loadfactorperc / 100)).toFixed(2)
        let _total = (parseFloat(__stramount) + parseFloat(_loadfactoramount)).toFixed(2)

        //console.log(_total)
        dispatch(setInputValue({ prop: 'monthlyexc', value: _total }))
        dispatch(setInputValue({ prop: 'monthlyvat', value: (parseFloat(_total) * 0.15).toFixed(2) }))
        dispatch(setInputValue({ prop: 'monthlyinc', value: (parseFloat(_total) * 1.15).toFixed(2) }))
        dispatch(setInputValue({ prop: 'loadfactoramount', value: _loadfactoramount }))
    }
}

export const calWorkings = () => {
    return (dispatch, getState) => {
        let _retailprice = parseFloat(getState().strCreateQuote.retailprice.value)
        let _discount = parseFloat(getState().strCreateQuote.discount.value)
        let _primerate = parseFloat(getState().strCreateQuote.primerate.value)
        let _diviation = parseFloat(getState().strCreateQuote.diviation.value)
        let _period = parseInt(getState().strCreateQuote.contractperiod.value)
        let _strrate = parseFloat(getState().strCreateQuote.strrate.value)
        let _roipercentage = parseFloat(getState().strCreateQuote.roipercentage.value)
        let _loadfactorperc = parseFloat(getState().strCreateQuote.loadfactorperc.value)
        let _accessory_list = getState().strCreateQuote.accessory_list

        let _adminfee = parseFloat(getState().strCreateQuote.adminfee.value)
        let _licensefee = parseFloat(getState().strCreateQuote.licensefee.value)

        let _rv = parseFloat(getState().strCreateQuote.rv.value)

        //console.log(_accessory_list)

        let _totalaccessory = _.sumBy(_accessory_list, (d) => { return parseFloat((d.ratepermonth) ? d.ratepermonth : 0) }).toFixed(2)

        //console.log(_totalaccessory)

        let _interestrate = _primerate && _diviation ? (_primerate + _diviation).toFixed(2) : 0
        let _vehicleprice = (_retailprice - _discount).toFixed(2)

        let _rvamount = _rv && _retailprice ? (_retailprice * (_rv / 100)).toFixed(2) : 0
        let _nett = _vehicleprice

        //console.log('>>>>>>>>>>>>>> PMT')
        //console.log(_primerate)
        //console.log(_period)
        //console.log(_nett)
        //console.log(_rvamount)

        
        let _fin = formulas.PMT(((parseFloat(_interestrate ? _interestrate : 0) / 100) / 12), (_period ? _period : 0), -(_nett ? _nett : 0), (_rvamount ? _rvamount : 0), 0).toFixed(2)
        let _finance = parseFloat(_fin) && isFinite(_fin) ? parseFloat(_fin) : 0

        //console.log('>>>>>>>>>>>>>> _finance')
        //console.log(_fin)
        //console.log(_finance)

        let _sub_total = _finance + _adminfee + _licensefee

        let _roi = (_sub_total * (_roipercentage / 100)).toFixed(2)
        //console.log('>>>>>>>>>>>>>> _sub_total')
        //console.log(_sub_total)
        //console.log(_roi)

        let _workingsubtotal = (parseFloat(_sub_total) + parseFloat(_roi)).toFixed(2)
        let _workingvat = (_workingsubtotal * 0.15).toFixed(2)
        let _workingrental = (_workingsubtotal * 1.15).toFixed(2)
        let __stramount = (_workingsubtotal * _strrate).toFixed(2)
        let _loadfactoramount = (__stramount * (_loadfactorperc / 100)).toFixed(2)
        let _stramount = (parseFloat(__stramount) + parseFloat(_loadfactoramount)).toFixed(2)

        dispatch(setInputValue({ prop: 'rvamount', value: _rvamount }))
        dispatch(setInputValue({ prop: 'interestrate', value: _interestrate }))
        dispatch(setInputValue({ prop: 'vehicleprice', value: _vehicleprice }))
        dispatch(setInputValue({ prop: 'finance', value: _finance }))
        dispatch(setInputValue({ prop: 'roi', value: _roi }))
        dispatch(setInputValue({ prop: 'workingsubtotal', value: _workingsubtotal }))
        dispatch(setInputValue({ prop: 'workingvat', value: _workingvat }))
        dispatch(setInputValue({ prop: 'workingrental', value: _workingrental }))
        dispatch(setInputValue({ prop: 'stramount', value: _stramount }))
        dispatch(setInputValue({ prop: 'totalaccessory', value: _totalaccessory }))
        dispatch(setInputValue({ prop: 'loadfactoramount', value: _loadfactoramount }))
    }
}

export const getStartEndDate = (period) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({ prop: 'startdate', value: moment().format('YYYY-MM-DD') }))
        dispatch(setInputValue({ prop: 'enddate', value: moment().add(period, 'months').format('YYYY-MM-DD') }))
    }
}

export const calcRIO = (rental_amount, roi_perc) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({ prop: 'roi', value: (rental_amount * (roi_perc / 100)) }))
    }
}
export const setLookupValue = (prop, data, set = false) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                value: data,
                prop: prop
            }
        })


        if (set && getState().strCreateQuote.customer_data.length) {
            //console.log('################################################# setLookupValue')
            let _data = getState().strCreateQuote.customer_data[0].customerderivative
            let derivative = _data.length ? _data[0] : null

            //console.log(derivative)
            //console.log(derivative.perioddefault)
            if (derivative) {
                dispatch(setInputValue({ prop: 'contractperiod', value: derivative.perioddefault }))
                dispatch(setInputValue({ prop: 'roipercentage', value: derivative.roiperc }))
                dispatch(setInputValue({ prop: 'monthlyinclusive', value: derivative.distancedefaultdiesel }))
                dispatch(setInputValue({ prop: 'adminfee', value: derivative.adminfee }))
                dispatch(setInputValue({ prop: 'licensefee', value: derivative.licencefee }))
                dispatch(setInputValue({ prop: 'loadfactorperc', value: derivative.loadfactor }))
                dispatch(getStartEndDate(derivative.perioddefault))
            }
        }

        //console.log('>>>>>>>>>>>>>>>> data')
        //console.log(data)
    }
}

export const getLookupValueData = (table, field, search, related = '') => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CREATE_STR_DATA_FIELD_INPUT_LIST,
            payload: {
                table: table,
                field: field,
                search: search,
                related: related
            }
        })
    }
}

export const getRateCard = (table, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CREATE_STR_RATECARD_LIST,
            payload: {
                table: table,
                field: field,
                search: search
            }
        })
    }
}


export const getRateCardVehicles = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CREATE_STR_RATECARD_VEHICLE_LIST,
            payload: {
                type: 'get_ratecard_vehicles',
                search: search
            }
        })
    }
}

export const setRateCardVehicles = (payload) => {
    //console.log('setRateCardVehicles',payload.response)
    return (dispatch) => {
        
        let data = payload.response.map(x => {
            return {
                text: x.variant,
                value: x.mmcode_id
            }
        })

        dispatch(setComponentProp('vehicles_list',data),)
    }
}

export const setRateCard = (response, payload) => {
    const findUnique = (arr, predicate) => {
        var found = {};
        arr.forEach(d => {
            found[predicate(d)] = d;
        });
        return Object.keys(found).map(key => found[key]);
    }

    return (dispatch, getState) => {
        //console.log('setRateCard',response.data)
        
        let data = response.data.map(x => {
            return {
                text: x[payload.field],
                value: x[payload.table + '_id']
            }
        })
        data = findUnique(data, d => d.text)
        //console.log('setRateCard data',data)
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
            payload: {
                value: data,
                prop: 'ratecategory_list'
            }
        })
    }
}

// export const setSelectedRateCard = (response, payload) => {
//     return (dispatch, getState) => {
//         //console.log('setRateCard',response.data)
        
//         let data = response.data.map(x => {
//             return {
//                 ...x,
//                 text: x[payload.field],
//                 value: x[payload.table + '_id']
//             }
//         })

//         //console.log('setRateCard data',data)
//         dispatch({
//             type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
//             payload: {
//                 value: data,
//                 prop: 'ratecategory_list'
//             }
//         })
//     }
// }
export const setLookupValueData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('response', response)
        //console.log('payload', payload)
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.table + '_id']
            }
        })

        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
            payload: {
                value: data,
                prop: payload.table + '_data'
            }
        })
    }
}

export const downloadWorking = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT,
            payload: {
                type: 'STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT',
                data: data
            }
        })
    }
}
export const getCustomerList = (search) => {
    //console.log('getViewx cust get', search)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CUSTOMER_LIST,
            payload: {
                search: search
            }
        })
    }
}

export const setCustomerList = (response) => {
    //console.log('getViewx cust set', response)
    return (dispatch, getState) => {
        dispatch(setComponentProp('customers', response.data))
    }
}

export const viewPdfDocument = (data) => {
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())

    window.glyco.log('data.data.document')
    window.glyco.log(data.data)

    saveByteArray([data.data.content.Body], 'STR Workings Preview.pdf')

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT,
            payload: {
                url: data.data.document.key
            }
        })
    }
}