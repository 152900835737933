import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import * as _ from 'lodash'
import { Container, Row, Col } from 'react-grid-system'

import TextField from '@mui/material/TextField'

import Icon from '../../Icon/component'
import Iframe from 'react-iframe'
import Loading from '../../Loading/component'


import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import Card from '../../../controls/card'

import * as styles from './styles'
import * as actions from './actions'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'

class POBookingSendRebillAuth extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }

    renderView(data) {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomments'}
                            label={'Enter comments'}
                            fullWidth={true}
                            label={'Enter approval comments'}
                            variant="outlined" 
                            multiLine={true}
                            value={this.props.state.comments}
                            onChange={(event, value) => {
                                this.props.actions.setValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getLayout() {
        return this.data.step1.vehicle_id
        ? <div>
                <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
                <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
                <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
                <Card
                    title={'Send Authorisation'}
                    subtitle={'Enter your comments'}
                    content={this.renderView()}
                />
            </div>
            : <div>
            <ViewVehicle key={this.data.step1.data.vehicle[0].vehicle_id} id={this.data.step1.data.vehicle[0].vehicle_id} />         
            <ViewSupplier key={this.data.step1.data.supplier[0].supplier_id} id={this.data.step1.data.supplier[0].supplier_id} />
            <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
            <Card
                title={'Send Authorisation'}
                subtitle={'Enter your comments'}
                content={this.renderView()}
            />
        </div>
        // return  <div>
        //         <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
        //         <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
        //         <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
        //         <Card
        //             title={'Send Authorisation'}
        //             subtitle={'Enter your comments'}
        //             content={this.renderView()}
        //         /> 
        //     </div>
    }

    render() {
        // this.data = null
        // try {
        //     this.data = this.props.workflow_queue_data.context_data.data.data.data
        // } catch (error) {

        // }
        // 
        
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data.length
            ? this.props.workflow_queue_data.context_data.data.data.data[0]
            : this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        

        return this.data
            ?  this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingSendRebillAuth
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingSendRebillAuth)