import * as types from './types'
import { ExcelRenderer, OutTable } from 'react-excel-renderer'
import moment from 'moment'

import * as appTypes from '../App/types'

export const setLookupValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA,
            payload: data
        })
    }
}


export const uploadFileData = (data, clientId) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> uploadFileDataDone', data);

        // Extract header row
        const headerRow = data.rows[0];
        
        // Determine the highest header index
        const highestHeaderIndex = headerRow.length - 1;
        
        // Create the modified header row
        const modifiedHeaderRow = [...headerRow, 'client_id'];

        // Transform the rows to arrays of values with client_id
        const modifiedRows = data.rows.map((row, index) => {
            if (index === 0) {
                // First row (header)
                return modifiedHeaderRow;
            }

            // Other rows
            return [...row, parseInt(clientId, 10)];
        });

        // Dispatch actions
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA_DONE,
            payload: true
        });
        
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA_ERROR,
            payload: null
        });
        
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA,
            payload: {
                type: data.type,
                rows: modifiedRows,
                template: data.template,
                document: data.document,
            }
        });
    }
}


export const uploadFileDataDone = (data) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> uploadFileDataDone', data)
        
        if(data.response.data.status === 'done') 
        {
            dispatch({
                type: types.UPLOAD_EXCEL_FILE_DATA_DONE,
                payload: false
            })    
            dispatch(resetUpload())
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'File import successfully completed...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }else {
            
            dispatch({
                type: types.UPLOAD_EXCEL_FILE_DATA_ERROR,
                payload: data.response.data.status
            }) 
        }
    }
}

export const setErrorValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA_PROCESSED_ERROR,
            payload: data
        })
    }
}

export const getTemplateList = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_EXCEL_FILE_TEMPLATE_DATA_LIST,
            payload: search
        })
    }
}

export const setTemplateList = (payload) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>> setTemplateList')
        //console.log(payload)
        var data = payload.data ? payload.data.map(x => { return {...x, text: x.template_name, value: x.importtemplate_id}}) : []
        dispatch({
            type: types.SET_EXCEL_FILE_TEMPLATE_DATA_LIST,
            payload: (data)
        })
    }
}

export const resetUpload = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS,
            payload: []
        })
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_NAME,
            payload: null
        })
        dispatch({
            type: types.SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA,
            payload: null
        })
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA_ERROR,
            payload: null
        }) 
    }
}
export const setAcceptedDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_BUSY,
            payload: true
        })

        documents.forEach(file => {
            console.log('File',file)
            ExcelRenderer(file, (err, resp) => {
                if (err) {
                    console.log(err);
                }
                else {
                    console.log(resp.rows.filter(x => x.length).filter(x => x.length > 1))

                    console.log('resp.rows', resp.rows)
                    dispatch({
                        type: types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS,
                        payload: resp.rows.filter(x => x.length).filter(x => x.length > 1)
                    })
                }
            });
        });
        console.log('SET_UPLOADED_EXCEL_FILE_NAME')
        console.log(documents[0])
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_NAME,
            payload: {
                preview: documents[0].preview,
                filename: documents[0].name,
            }
        })
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_BUSY,
            payload: false
        })
    };
}

