import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Card, CardActions, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'


import Icon from '../Icon/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

class Tiles extends Component {
    componentDidMount() {
        this.component = this.props.component
            ? this.props.component
            : this.props.state.component

        this.key = this.component.component_field.filter(x => x.key)[0].name

        this.props.actions.getComponentTileData(this.component, this.props.state.paging)
    }

    getTileHeaders(row) {
        return this.component.component_field
            .filter(x => x.tileHeader)
            .map(x => {
                return row[x.name]
            })
    }

    getTiles() {
        return this.props.state.loading
            ? <Loading messsage={'Retrieving your data..'} />
            : this.props.state.data.length
                ? this.props.state.data.map(x => {
                    return <Card
                        style={styles.tile}
                        key={'tile_' + this.component.name + '_' + x[this.key]}
                    >
                        <CardContent initiallyExpanded={true} expandable={false} /* style={styles.center} */>
                            {this.getTileHeaders(x)}
                        </CardContent>
                    </Card>
                })
                : 'We could not find any records for this component....'
    }

    getPagers() {
        return <CardActions style={styles.actions} expandable={false}>
           < Button className={"global_button"} variant="contained"
                primary={false}
                label={'Back'}
                disabled={this.props.state.paging.page < 2}
                onClick={(event) => {
                    event.preventDefault()

                    this.props.actions.getComponentTileData(this.component, {
                        ...this.props.state.paging,
                        page: this.props.state.paging.page - 1
                    })
                }}
                >Back</Button>

           < Button className={"global_button"} variant="contained"
                primary={true}
                label='Forward'
                disabled={this.props.state.paging.page === this.props.state.paging.pageCount}
                onClick={(event) => {
                    event.preventDefault()

                    this.props.actions.getComponentTileData(this.component, {
                        ...this.props.state.paging,
                        page: this.props.state.paging.page + 1
                    })
                }}
                >Forward</Button>
        </CardActions>
    }

    render() {
        return <Card>
            {/*             <CardHeader
                title={this.component.title}
                subtitle={this.component.description}
                avatar={
                    <Icon
                        iclass={'custom-icons'}
                        iname={this.component.icon.name}
                    />
                }
            /> */}

            {this.getPagers()}

            <CardContent initiallyExpanded={true} expandable={false} style={styles.text}>
                {this.getTiles()}
            </CardContent>

            {this.getPagers()}
        </Card>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.tiles,
            component: {
                ...state.components[ownProps.name]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tiles)