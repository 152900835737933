import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../Icon/component'

import * as actions from './actions'
import * as styles from './styles'

//import '../../assets/css/infleetnew.scss';

class WorkflowTaskInformation extends Component {
    getApprovalInformation(component) {
        return 'Approval'
    }

    getNotificationInformation(component) {
        return 'Notification'
    }

    getEscalationInformation(component) {
        return 'Esclation'
    }

    getNextTasksInformation(component) {
        return 'Next Tasks'
    }

    render() {
        const component = {
            state: this.props.state,
            actions: this.props.actions,
            styles: styles
        }

        this.task = this.props.task

        return (
            <Card style={styles.taskCard} initiallyExpanded={false}>
                <CardHeader
                    actAsExpander={true}
                    title={window.t.en('Task Information')}
                    subtitle={window.t.en('Expand this section if you would like to know more about the details of this task.')}
                    // avatar={
                    //     <Icon istyle={styles.icon} iclass={'material-icons'} iname={'info_outline'} />
                    // }
                    //style={styles.cardHeader}
                    className="custom-card"
                    
                />
                <CardContent expandable={true}>
                    <Card style={styles.taskCard} initiallyExpanded={false}>
                        <CardHeader
                            actAsExpander={true}
                            title={window.t.en('Approval Information')}
                            subtitle={window.t.en('Details who is responsible for approving this task')}
                            // avatar={
                            //     <Icon istyle={styles.icon} iclass={'material-icons'} iname={'info_outline'} />
                            // }
                            //style={styles.cardHeader}
                    className={'custom-card'}
                        />
                        <CardContent expandable={true}>
                            {this.getApprovalInformation(component)}
                        </CardContent>
                    </Card>

                    <Card style={styles.taskCard} initiallyExpanded={false}>
                        <CardHeader
                            actAsExpander={true}
                            title={window.t.en('Notification Information')}
                            subtitle={window.t.en('Details who will be notified of events related to this task')}
                            // avatar={
                            //     <Icon istyle={styles.icon} iclass={'material-icons'} iname={'info_outline'} />
                            // }
                            //style={styles.cardHeader}
                    className={'custom-card'}
                        />
                        <CardContent expandable={true}>
                            {this.getNotificationInformation(component)}
                        </CardContent>
                    </Card>

                    <Card style={styles.taskCard} initiallyExpanded={false}>
                        <CardHeader
                            actAsExpander={true}
                            title={window.t.en('Escalation Information')}
                            subtitle={window.t.en('Details who this task will be escalated to if not completed within a specific time period')}
                            // avatar={
                            //     <Icon istyle={styles.icon} iclass={'material-icons'} iname={'info_outline'} />
                            // }
                            //style={styles.cardHeader}
                    className={'custom-card'}
                        />
                        <CardContent expandable={true}>
                            {this.getEscalationInformation(component)}
                        </CardContent>
                    </Card>

                    <Card style={styles.taskCard} initiallyExpanded={false}>
                        <CardHeader
                            actAsExpander={true}
                            title={window.t.en('Next Tasks')}
                            subtitle={window.t.en('Lists the task or tasks that will be created when this task has been confirmed and approved')}
                            // avatar={
                            //     <Icon istyle={styles.icon} iclass={'material-icons'} iname={'info_outline'} />
                            // }
                            //style={styles.cardHeader}
                    className={'custom-card'}
                        />
                        <CardContent expandable={true}>
                            {this.getNextTasksInformation(component)}
                        </CardContent>
                    </Card>
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.workflowTaskInput, ...state.app }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskInformation)