
export const derivative = null
export const customer = null
export const quotefor = null
export const vehiclecategory = null
export const replacevehicle = null
export const fields = [
    {
        name: 'customer',
        title: 'Customer',
        type: 'autoComplete',
        error: 'Customer Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: [],
        append_text: 'costcentre',
        data_text: 'hierarchyname',
        data_value: 'customer_id',
        section: 'Contract Details'
    },
    {
        name: 'contracttype',
        title: 'Contract Type',
        type: 'autoComplete',
        error: 'Contract Type Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: [],
        data_text: 'contracttype',
        data_value: 'contracttype_id',
        section: 'Contract Details'
    },
    {
        name: 'contractvehiclecategory',
        title: 'Vehicle Category',
        type: 'autoComplete',
        error: 'Vehicle Category Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: [],
        data_text: 'categorydescription',
        data_value: 'contractvehiclecategory_id',
        section: 'Contract Details'
    },
    {
        name: 'contractperiod',
        title: 'Contract Period',
        type: 'textBox',
        error: 'Contract Period Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Contract Details',
        formula: {
            operator: 'mi',
            prop: 'contractinclusive',
            field1: 'monthlyinclusive',
            field2: 'contractperiod'
        }
    },
    {
        name: 'monthlyinclusive',
        title: 'Monthly Inclusive',
        type: 'textBox',
        error: 'Monthly Inclusive Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Contract Details',
        formula: {
            operator: 'mi',
            prop: 'contractinclusive',
            field1: 'monthlyinclusive',
            field2: 'contractperiod'
        }
    },
    {
        name: 'contractinclusive',
        title: 'Contract Inclusive',
        type: 'textBox',
        error: 'Contract Inclusive Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Contract Details',
        formula: {
            operator: 'mi',
            prop: 'contractinclusive',
            field1: 'monthlyinclusive',
            field2: 'contractperiod'
        },
        disabled: true
    },
    {
        name: 'rfqdate',
        title: 'RFQ Date',
        type: 'datePicker',
        error: 'RFQ Date Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'RFQ Details'
    },
    {
        name: 'rfqdocument',
        title: 'Upload RFQ',
        type: 'upload',
        error: 'RFQ Document Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'RFQ Details'
    },
    {
        name: 'quotefor',
        title: 'Quote For',
        type: 'autoComplete',
        error: 'Quote For Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: [],
        data_text: 'quotefor',
        data_value: 'quotefor_id',
        section: 'Quotation For'
    },
    {
        name: 'customercontract',
        title: 'Replace Vehicle',
        type: 'autoComplete',
        error: 'Contract Type Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: [],
        data_text: 'licenseplateno',
        data_value: 'customercontract_id',
        section: 'Quotation For'
    },
    {
        name: 'color',
        title: 'Vehicle Color',
        type: 'autoComplete',
        error: 'Color Type Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: [],
        data_text: 'color',
        data_value: 'color_id',
        section: 'Contract Details',
    },
    {
        name: 'loadfactor',
        title: 'Load Factor',
        type: 'autoComplete',
        error: 'Load Factor Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: [],
        data_text: 'loadfactor',
        data_value: 'loadfactor_id',
        section: 'Contract Details',
    },
    {
        name: 'comments',
        title: 'Comments',
        type: 'textBox',
        error: '',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Contract Details'
    },
    {
        name: "vehicles",
        title: "Vehicle Detail",
        section: 'Vehicle Details',
        fields: [],
        populate: ['cprice', 'dprice', 'amount', 'maintcpk', 'tyrescpk', 'makemodelyear', 'defaultrv']
    }
]

export const form = [
    {
        parent: 'vehicles',
        name: 'mmcode',
        title: 'MM Code',
        type: 'autoComplete',
        error: 'MMCode Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: [],
        append_text: 'makemodelyear',
        data_text: 'mmcode',
        data_value: 'mmcode_id',
        section: 'Vehicle Details'
    },
    {
        parent: 'vehicles',
        name: 'makemodelyear',
        title: 'Description',
        type: 'textBox',
        error: 'Retail Price Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
    },
    // {
    //     parent: 'vehicles',
    //     name: 'rt57price',
    //     title: 'RT57 Pricing',
    //     type: 'toggle',
    //     error: '',
    //     value: false,
    //     valid: false,
    //     empty: true,
    //     index: 0,
    //     section: 'Vehicle Details'
    // },
    {
        parent: 'vehicles',
        name: 'cprice',
        title: 'Retail Price',
        type: 'textBox',
        error: 'Retail Price Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
        formula: {
            sub: 'sub',
            operator: 's',
            prop: 'amount',
            field1: 'cprice',
            field2: 'dprice'
        }
    },
    {
        parent: 'vehicles',
        name: 'dprice',
        title: 'Discount Price',
        type: 'textBox',
        error: 'Discount Price Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
        formula: {
            sub: 'sub',
            operator: 's',
            prop: 'amount',
            field1: 'cprice',
            field2: 'dprice'
        }
    },
    {
        parent: 'vehicles',
        name: 'amount',
        title: 'Vehicle Price',
        type: 'textBox',
        error: 'Vehicle Price Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
        formula: {
            sub: 'sub',
            operator: 's',
            prop: 'amount',
            field1: 'cprice',
            field2: 'dprice'
        }
    },
    {
        name: 'separator',
        section: 'Vehicle Details',
    },
    {
        parent: 'vehicles',
        name: 'defaultrv',
        title: 'RV Percentage',
        type: 'textBox',
        error: 'RV Perc Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
        formula: {
            sub: 'sub',
            operator: 'rv',
            prop: 'rvamount',
            field1: 'cprice',
            field2: 'defaultrv'
        }
    },
    {
        parent: 'vehicles',
        name: 'rvamount',
        title: 'RV Amount',
        type: 'textBox',
        error: 'RV Amount Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details'
    },
    {
        name: 'separator',
        section: 'Vehicle Details',
    },
    {
        parent: 'vehicles',
        name: 'deliveryperiod',
        title: 'Delivery Period',
        type: 'textBox',
        error: 'Delivery Period Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
    },
    {
        name: 'separator',
        section: 'Vehicle Details',
    },
    {
        parent: 'vehicles',
        name: 'maintcpk',
        title: 'Maintenance CPK',
        type: 'textBox',
        error: 'Maintenance CPK Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
        formula: {
            sub: 'sub',
            operator: 'm',
            prop: 'maintcost',
            field2: 'maintcpk',
            parent_field1: 'monthlyinclusive'
        }
    },
    {
        parent: 'vehicles',
        name: 'maintcost',
        title: 'Maintenance Cost',
        type: 'textBox',
        error: 'Maintenance Amount Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
    },
    {
        parent: 'vehicles',
        name: 'tyrescpk',
        title: 'Tyre CPK',
        type: 'textBox',
        error: 'Tyre CPK Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
        formula: {
            sub: 'sub',
            operator: 'm',
            field2: 'tyrescpk',
            prop: 'tyrescost',
            parent_field1: 'monthlyinclusive'
        }
    },
    {
        parent: 'vehicles',
        name: 'tyrescost',
        title: 'Tyre Cost',
        type: 'textBox',
        error: 'Tyre Amount Required!!',
        value: null,
        valid: false,
        empty: true,
        index: 0,
        data: null,
        section: 'Vehicle Details',
    },
    {
        name: "accessories",
        title: "Vehicle Accessory",
        section: 'Vehicle Accessory',
    }
]

export const accessory_list = []
export const service_list = []
export const sundry_list = []
export const accessories_chip = []