
export const GET_CUSTOMER_STATEMENT_LIST = 'GET_CUSTOMER_STATEMENT_LIST'
export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST'
export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const SET_CUSTOMER_STATEMENT_LIST = 'SET_CUSTOMER_STATEMENT_LIST'
export const GEN_CUSTOMER_STATEMENT_PDF = 'GEN_CUSTOMER_STATEMENT_PDF'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const GET_CONTRA_ACCOUNT_LIST = 'GET_CONTRA_ACCOUNT_LIST'
export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'
export const SAVE_GL_CASH_BOOK_DATA = 'SAVE_GL_CASH_BOOK_DATA'
export const SET_ANY_INVOICE_ITEMS = 'SET_ANY_INVOICE_ITEMS'
export const SET_ANY_ITEM_INPUT_VALUE = 'SET_ANY_ITEM_INPUT_VALUE'
export const ADD_ANY_BLANK_ROW = 'ADD_ANY_BLANK_ROW'
export const REMOVE_ANY_INVOICE_ITEM_ROW = 'REMOVE_ANY_INVOICE_ITEM_ROW'
export const GET_LINK_NUMBER_LIST = 'GET_LINK_NUMBER_LIST'
export const SET_LINK_NUMBER_LIST = 'SET_LINK_NUMBER_LIST'
export const GET_LINK_NUMBER_TRANS = 'GET_LINK_NUMBER_TRANS'
export const SET_LINK_NUMBER_TRANS = 'SET_LINK_NUMBER_TRANS'
export const SAVED_GL_POSTING_DATA = 'SAVED_GL_POSTING_DATA'
export const SET_GL_POSTING_DATA = 'SET_GL_POSTING_DATA' 