import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'

export const getItemData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(
        types.GET_WORKQUEUE_ITEM_DATA
    ),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workqueue/search?workqueue_title=' + payload.workqueue_title,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'component.component_field.component_field_data_type,workqueue_field,workqueue_field_actions.workqueue_actions,workqueue_field_actions.workflow.workflow_trigger.workflow_task'
                }
            }).pipe(
                mergeMap((result) => [
                    actions.getFormData(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/person/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/person/query', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


   
export const getUser = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(
        types.GET_WORKQUEUE_USER_LIST
    ),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/search?email=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            }).pipe(
                mergeMap((result) => [
                    actions.setUser(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/person/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/person/query', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

     
export const getFormData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType( types.GET_WORKQUEUE_FORM_DATA ),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workqueue/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            }).pipe(
                mergeMap((result) => [
                    actions.setFormData(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workqueue/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue/other', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )  

export const UpdateEventAck = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(
        types.UPDATE_EVENT_MANAGEMENT_ACK_STATUS
    ),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workqueue/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            }).pipe(
                mergeMap((result) => [
                    actions.UpdateEventAckDone(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/person/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/person/query', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


        
export const sendEmail = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.SEND_EVENTS_MANAGMENT_EMAIL),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/webapi/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
        .pipe(
            mergeMap(payload => [
                actions.sendEmailDone(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/webapi/other')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/webapi/other', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))
