import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import IconButton from '@mui/material/IconButton'

import * as _ from 'lodash'
// import moment from 'moment'
// import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
// import { Timeline, TimelineEvent } from 'react-event-timeline'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import Toolbar from '@mui/material/Toolbar';

import DetailView from './view'
import DetailGrid from './grid'
import EditView from './edit'
import ForumView from './forum'
import OffersView from './offers'
import DocumentsView from './documents'
import ImportView from './import'
import EmailView from './mail'
import DisposalView from './disposal'
import SettlementView from './settlement'
import DecisionView from './decision'
import OTPListView from './otplist'
import ChecksheetView from './checksheet'
import BidderInvoice from './invoice'


import '../../../assets/css/react-vertical-timeline.css'

import Card from '../../../controls/card'
import Icon from '../../Icon/component'
import Loading from '../../Loading/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDisposal extends Component {
    componentDidMount() {
        this.props.actions.getDisposalList()
        this.props.actions.getOTP()
        this.props.actions.getBidders()
    }

    getTooplBar() {
        return <Toolbar>
                <Select 
                value={1} 
                primary={true}
                onChange={
                    (event) => {
                        this.props.actions.setDataFilter(event)
                }}>
                    <MenuItem value={1} primaryText="View Yard List" />
                    <MenuItem value={2} primaryText="View Sold Vehicles" />
                </Select>
                {/* <Button
                    label="Disposal Vehicle"
                    primary={true}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.openDisposal()
                    }} /> */}
                    <Button
                        label="Refresh"
                        variant='contained' className='global_button'
                        primary={true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.getDisposalList()
                            this.props.actions.getOTP()
                            this.props.actions.getBidders()
                        }} />
                {/* <IconMenu
                    iconButtonElement={
                        <IconButton touch={true}>
                            <NavigationExpandMoreIcon />
                        </IconButton>
                    }
                >
                    <MenuItem primaryText="Export List To Excel" />
                </IconMenu> */}
        </Toolbar>
    }

    getDisposalListOff() {
        return this.props.state.yardlist.length
            ? this.props.state.yardlist
                .sort((x, y) => y['workflow_queue_task#created_at'] - x['workflow_queue_task#created_at'])
                .map((x, i) => {
                    return <div>
                        <div key={'workflow_queue_task_data' + x['workflow_queue_task#workflow_queue_task_id']} style={styles.row(i, true)}>
                            {/* <div style={styles.column(100, false)}>
                                <    
                                    tooltip={'View Details'}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.toggleItemProps(i, 'showdetails', x.showdetails)
                                    }}>
                                    <Icon istyle={{ fontSize: '22px' }} iclass={'material-icons'} iname={'forward'} />
                                </><br />
                                <IconButton
                                    tooltip={'Comments'}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.toggleItemProps(i, 'showcomments', x.showcomments)
                                    }}
                                >
                                    <Icon istyle={{ fontSize: '22px' }} iclass={'material-icons'} iname={'feedback'} />
                                </IconButton>
                            </div>

                            <div style={styles.column(185, false)}>
                                {x.customercontract.businessname_fktext}<br />
                            </div>
                            <div style={styles.column(300, false)}>
                                <strong>{x.customercontract.licenseplateno}</strong>
                            </div>
                           <div style={styles.column(300, false)}>
                                <strong>{x.vehicle.vehiclestatus_fktext}</strong>
                            </div>
                            <div style={styles.column(300, false)}>
                                {x.customercontract.makemodelyear}<br />
                            </div>
                            <div style={styles.column(200, false)}>
                                {x.vehicle.financialinstitution_fktext}
                            </div>
                            <div style={styles.column(150, false)}>
                                {x.customercontract.distancepermonth + ' Kms'}
                            </div>
                            <div style={styles.column(175, false)}>
                                <strong>{'Mothly Rental: '}</strong> {x.customercontract.customercontractderivative[0].monthlysellingtotal}<br />
                                <strong>{'Billing Count: '}</strong> {x.customercontract.customercontractderivative[0].billingcount}<br />
                                <strong>{'COB: '}</strong> {x.customercontract.customercontractderivative[0].capitalbalance}
                            </div>
                            <div style={styles.column(140, false)}>
                                <strong>{'Rebill Cost: '}</strong> {x.maintenancetransaction.totalamount}<br />
                            </div>
                          <div style={styles.column(175, false)}>
                                <strong>{'Disposal Type: '}</strong> {x.vehicle.financialinstitution_fktext}<br />
                                <strong>{'Disposal Type: '}</strong> {x.vehicle.financialinstitution_fktext}<br />
                            </div>
                              <div style={styles.column(200, false)}>
                                {'Funder: '}
                                {'Oustanding : '}
                            </div>
                            <div style={styles.column(200, false)}>
                                {'Retail Value'}
                            </div> 
                            <div style={styles.column(100, true)}>
                                <Button
                                    icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'forward'} />}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.toggleItemProps(i, 'showdetails', x.showdetails)
                                    }}
                                />
                                <Button
                                    icon={<div>
                                        <Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'feedback'} />
                                        <Badge badgeContent={x.disposalcomments.length} primary={true} style={{ position: 'absolute', top: '0px', right: '-5px', color: 'white' }} ></Badge></div>
                                    }
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.toggleItemProps(i, 'showcomments', x.showcomments)
                                    }}
                                />
                            </div>
                            <div style={styles.column(0, false)}>
                                {(x['workflow_queue_task#assigned_to_team_message']) &&
                                    [
                                        <a data-tip data-for={'tooltip_' + x['workflow_queue_task#workflow_queue_task_id']} data-tip='Referrer Comments' data-event='click focus'>
                                            <IconButton tooltip={'Referrer Comment'}>
                                                <Icon istyle={{ fontSize: '22px' }} iclass={'material-icons'} iname={'feedback'} />
                                            </IconButton>
                                        </a>,
                                        <ReactTooltip
                                            id={'tooltip_' + x['workflow_queue_task#workflow_queue_task_id']}
                                            globalEventOff='click'
                                        >
                                            {x['workflow_queue_task#assigned_to_team_message']}
                                        </ReactTooltip>
                                    ]
                                }
                            </div>*/}
                        </div>
                        {x.showdetails && <Drawer
                            width={800}
                            openSecondary={true}
                            open={x.showdetails ? true : false}
                            onRequestChange={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleItemProps(i, 'showdetails', false)
                            }}>
                            <br /> <br /> <br /> <br />
                            <Button variant='contained' className='global_button'
                                label="Close"
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleItemProps(i, 'showdetails', x.showdetails)
                                }}
                            />
                            <DetailView inx={i} data={x} />
                        </Drawer>}
                    </div>
                })
            : <Row style={styles.row(0, false)}>
                <Col xl={12}>
                    <Loading message={'Please wait while retrieving data...'} />
                </Col>
            </Row>
    }

    getDisposalStats() {
        let chip = Array.from(new Set(this.props.state.yardlist.map(x => x.vehiclestatus_fktext)))
        
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
            {/* <Chip  
                    style={styles.chip}
                    onClick={(event) => {
                        this.props.actions.chipFilter('')
                    }}
                    label={'All'}>
                    <Avatar>{this.props.state.yardlist.length}</Avatar>
                   {'All'}
                </Chip> */}
                <Button 
                        variant='contained' className='global_button'
                        primary={true}
                        style={{ width: '120px'}}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.chipFilter('')
                        }}>
                            {<Icon iclass={'material-icons'} iname={'check'} />} &nbsp; {window.t.en('All')}
                    </Button>
                &nbsp;
                {
                    chip.map(x => {
                        return <><Button 
                        variant='contained' className='global_button'
                        primary={true}
                        //style={{ width: '120px'}}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.chipFilter(x)
                        }}>
                            {<Icon iclass={'material-icons'} iname={'check'} />} &nbsp; {window.t.en(x)}
                    </Button>
                &nbsp;</>
                        
                        
                    //     <Chip  
                    //     style={styles.chip}
                    //     onClick={(event) => {
                    //         this.props.actions.chipFilter(x)
                    //     }}
                    //     label={x}>
                    //     <Avatar>{this.props.state.yardlist.filter(f => f.vehiclestatus_fktext === x).length}</Avatar>
                    //     {x}
                    // </Chip>
                    })
                }
                {/* <Chip  
                    style={styles.chip}
                    onClick={(event) => {
                        this.props.actions.chipFilter('Yard List')
                    }}>
                    <Avatar>0</Avatar>
                    Yard List
                </Chip>
                <Chip  
                    style={styles.chip}
                    onClick={(event) => {
                        this.props.actions.chipFilter('Operational')
                    }}>
                    <Avatar>0</Avatar>
                    Operational
                </Chip>
                <Chip  
                    style={styles.chip}
                    onClick={(event) => {
                        this.props.actions.chipFilter('')
                    }}>
                    <Avatar>0</Avatar>
                    Disposals
                </Chip>
                <Chip  
                    style={styles.chip}
                    onClick={(event) => {
                        this.props.actions.chipFilter('')
                    }}>
                    <Avatar>0</Avatar>
                    At Supplier
                </Chip>
                <Chip  
                    style={styles.chip}
                    onClick={(event) => {
                        this.props.actions.chipFilter('')
                    }}>
                    <Avatar>0</Avatar>
                    Sold
                </Chip> */}
            </Row>
        </Container>
    }
    
    getDisposalView() {
        return <Container style={{ margin: 0 }} className="custom-container">
        <Col>
            <br />
        </Col>
                <Row>
                    <Col>
                        {this.getDisposalStats()}
                    </Col>
                </Row> 
                <Row>
                    <Col>
                        <br />
                    </Col>
                <Button
                    label="Refesh"
                    variant='contained' className='global_button'
                    primary={true}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.getDisposalList()
                        this.props.actions.getOTP()
                        this.props.actions.getBidders()
                    }}>
                    {"Refesh"}
                </Button>
                &nbsp;
                <Button
                    primary={true}
                    label="Excel Export"
                    variant='contained' className='global_button'
                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                    onClick={(event) => {
                        this.props.actions.exportToExcel()
                    }}>
                    {"Excel Export"}
                </Button> 
                
                </Row>
                <Row>
                    <Col>
                   <DetailGrid />
                    </Col>
            </Row>
        </Container>
    }

    getView() {
        return <div>
            <Tabs 
                initialSelectedIndex={0}
                value={this.props.tab}
                onChange={
                    (event, value) => {
                        this.props.actions.setCurrentTab(value)
                    }
                }>
                <Tab style={{minWidth:"33%"}} label={'Disposal'} />
                <Tab style={{minWidth:"33%"}} label={'Offer to Purchase (O.T.P.)'} />
                <Tab style={{minWidth:"33%"}} label={'Imports'} />
            </Tabs> 
            <div>
                {this.props.state.tab === 0 && this.getDisposalView()}
                {this.props.state.tab === 1 && <EmailView />}
                {this.props.state.tab === 2 && <ImportView />}
            </div>
            
        <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col>
                    {this.props.state.inforsheet_forum && <ForumView />}
                    {this.props.state.inforsheet_edit && <EditView />}
                    {this.props.state.inforsheet_view && <DetailView /> }
                    {this.props.state.inforsheet_offers && <OffersView /> }
                    {this.props.state.inforsheet_documents && <DocumentsView /> }
                    {this.props.state.inforsheet_disposal && <DisposalView /> }
                    {this.props.state.inforsheet_settlement && <SettlementView /> }
                    {this.props.state.inforsheet_decision && <DecisionView /> }
                    {this.props.state.inforsheet_otp_list && <OTPListView /> }
                    {this.props.state.inforsheet_checksheet && <ChecksheetView /> }
                    {this.props.state.inforsheet_invoice && <BidderInvoice /> }
                </Col>
            </Row>
        </Container>
        </div> 
    }

    getlayout() {
        return <Card
            title={'Workflow Disposal'}
            subtitle={'Handle disposal activities.'}
            content={this.getView()}
        />
    }

    render() {
        return this.getlayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposal)