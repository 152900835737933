import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import { Container, Row, Col } from 'react-grid-system'

import { Table } from "@mui/material";

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import TextField from '../../controls/textField'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import ViewCustomer from '../../controls/view_customer'

import Icon from '../Icon/component'
import PdfViewer from '../PDF/component'
import Upload from '../Upload/component'
import Loading from '../Loading/component'
import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowFMLReleaseNote extends Component {

    componentDidMount() {
        
        if(this.props.confirm) {
            //console.log('componentWillMount WorkflowViewFMLQuoteConfirm', this.props.state)
            const data = this.props.state
            this.props.actions.setupFMLWorkflowTaskInput(data, null, 'customer_fml_quote', 'tsk_fml_quote_release_note')
        }else{
        this.props.actions.setValidationState('workflowFMLReleaseNote')
        }
    }

    getContract(data) {
        return <Card 
                title={window.t.en('Quote Confirmation')}
                subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
                content={
                    <Fields nocard={true}
                        fields={[
                        {size: 4, label: 'Contract Type:', value: data.contractname},
                        {size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory},
                        {size: 4, label: 'Monthly Distance:', value: data.distancepermonth},
                        {size: 4, label: 'Contract Period:', value: data.contractperiod},
                        {size: 4, label: 'Quote For:', value: data.quotefor},
                        {size: 4, label: 'RFQ Date:', value: data.quotedate},
                        {size: 4, label: 'Vehicle Color:', value: data.color},
                        {size: 4, label: 'Vehicle Location:', value: data.location},
                        {size: 4, label: 'Special Vehicle:', value: data.specialistvehicle},
                        {size: 4, label: 'Vehicle Group:', value: data.vehiclegroup},
                        {size: 4, label: 'Operating Terrain:', value: data.terrain},
                        {size: 4, label: 'Vehicle Usage:', value: data.usage},
                        {size: 4, label: 'Comments:', value: data.comments},
                    ]}
                />
                }
            />
    }

    getCustomer(customer) {
        let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        return <Card 
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                    <ViewCustomer id={customer.customer_id} nocard={true} min={true} />
                    // <Fields nocard={true}
                    //     fields={[
                    //         { size: 4, label: 'Customer Name:', value: customer.customername },
                    //         { size: 4, label: 'Cost Centre:', value: customer.costcentre },
                    //         { size: 4, label: 'Account Number:', value: customer.accountnumber },
                    //         { size: 4, label: 'Contact Person Name:', value: contact.fullname },
                    //         { size: 4, label: 'Contact Number:', value: contact.mobile },
                    //         { size: 4, label: 'Contact Email:', value: contact.email },
                    //     ]}
                    // />
                }
            />
    }

    getSupplier(data) {
        return <Card 
                title={window.t.en('Supplier Details')}
                subtitle={window.t.en('Selected Vehicle Supplier ')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Supplier Name:', value: data.suppliername},
                            {size: 4, label: 'Trading Name:', value: data.tradingnamename},
                            {size: 4, label: 'Account Number:', value: data.accountnumber},
                            {size: 4, label: 'VAT Number:', value: data.vatnumber},
                            {size: 4, label: 'Contact Person:', value: data.contactperson},
                            {size: 4, label: 'Contact Email:', value: data.contactemail},
                            {size: 4, label: 'Contact Number:', value: data.contactnumber},
                        ]}
                    />
                }
            />
    }

    getVehicle(data) {
        //console.log('Vehicle',data)
        return <Card 
                title={window.t.en('Accepted Quote ' + data.quotenumber)}
                subtitle={window.t.en('Customer Quote Accepted')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Quote Number:', value: data.quotenumber},
                            {size: 4, label: 'System PO Number:', value: data.ponumber},

                            {size: 12, header: 'Vehicle Details'},
                            {size: 4, label: 'Vehicle Make:', value: data.mmcode.make},
                            {size: 4, label: 'Vehicle Model:', value: data.mmcode.cdescription},

                            {size: 4, label: 'MM Code:', value: data.mmcode.mmcode},
                            {size: 4, label: 'Transmission:', value: data.mmcode.manualauto},

                            {size: 4, label: 'Introduction Year:', value: data.mmcode.regyear},
                            {size: 4, label: 'Body Type:', value: data.mmcode.bodytype},

                            {size: 4, label: 'Contract Type:', value: data.contractname},
                            {size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory},

                            {size: 4, label: 'Monthly Distance', value: data.distancepermonth},
                            {size: 4, label: 'Contract Period:', value: data.contractperiod},

                           // {size: 4, label: 'Rate Card:', value: data.ratecard},
                            {size: 4, label: 'Delivery Period:', value: data.deliveryperiod},
                            
                            {size: 12, header: 'Rental Breakdown'},
                            {size: 4, label: 'Retail Price:', value: data.retailprice},
                            {size: 4, label: 'Discount:', value: data.discount},

                            {size: 4, label: 'Interest Rate:', value: data.primerate},
                            {size: 4, label: 'Vehicle Price:', value: data.vehicleprice},

                            {size: 12, header: 'Monthly Rental'},
                            {size: 4, label: 'Rental Excl:', value: data.monthly_excl},

                            {size: 4, label: 'Vat:', value: data.monthly_vat},
                            {size: 4, label: 'Rental Incl:', value: data.monthly_incl},
                        ]}
                    />
                }
            />
    }


    getDownload(documents) {
        //console.log('documentsx Release Note', documents)
        return <Card 
                title={window.t.en('Release Note')}
                subtitle={window.t.en('Release note document')}
                content={
                    documents.length > 0
                    ? <PdfViewer
                        key={'rn'}
                        documents={documents}
                        multiple={true}
                        height={'500px'}
                        preview={'preview'}
                    />
                    : 'Loading...'
            }
        />
    }

    getChecklist() {
        return <Card 
                title={window.t.en('Mandatory Tick List')}
                subtitle={window.t.en('The following documents are required')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Signed Customer Quotation? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'signed_customer_quotation'}
                                    label={'Signed Customer Quotation?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.signed_customer_quotation}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'signed_customer_quotation', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'PO Supplier / Dealer? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'po_supplier'}
                                    label={'PO Supplier / Dealer?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.po_supplier}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'po_supplier', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Invoice Supplier / Dealer? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'invoice_supplier'}
                                    label={'Invoice Supplier / Dealer?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.invoice_supplier}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'invoice_supplier', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Accessories POs ( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'accessories_po'}
                                    label={'Accessories POs?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.accessories_po}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'accessories_po', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Accessories Job Cards ( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'accessories_job_cards'}
                                    label={'Accessories Job Cards ( If Applicable)'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.accessories_job_cards}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'accessories_job_cards', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Invoices from Accessories Suppliers( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'accessories_suppliers'}
                                    label={'Invoices from Accessories Suppliers( If Applicable)?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.accessories_suppliers}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'accessories_suppliers', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Sundries PO ( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'sundries_po'}
                                    label={'Sundries PO ( If Applicable)?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.sundries_po}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'sundries_po', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Invoices from Sundries Suppiers( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'sundries_invoices'}
                                    label={'Invoices from Sundries Suppiers( If Applicable)?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.sundries_invoices}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'sundries_invoices', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Pre Inspection Delivery document ( PID)? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'pre_inspection'}
                                    label={'Pre Inspection Delivery document ( PID)?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.pre_inspection}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'pre_inspection', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Registration ( Natis Documents)? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'registration_doc'}
                                    label={'Registration ( Natis Documents)?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.registration_doc}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'registration_doc', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Tracker Certificate? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'tracker_certificate'}
                                    label={'Tracker Certificate?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.tracker_certificate}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'tracker_certificate', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Insurance Certificate? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <Checkbox
                                    key={'insurance_certificate'}
                                    label={'Insurance Certificate?'}
                                    labelPosition='left'
                                    
                                    style={styles.toggle}
                                    trackStyle={{
                                        backgroundColor: 'gray'
                                    }}
                                    trackSwitchedStyle={{
                                        backgroundColor: 'green'
                                    }}
                                    checked={this.props.state.insurance_certificate}
                                    onChange={
                                        (event, isInputChecked) => {
                                            this.props.actions.setInputValue({prop: 'insurance_certificate', value: isInputChecked})
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.selectedFieldName}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                }
           />
    }

    getChecklistView() {
        return <Card 
                title={window.t.en('Confirm Mandatory Tick List')}
                subtitle={window.t.en('The following documents are required')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Signed Customer Quotation? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.signed_customer_quotation ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'PO Supplier / Dealer? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.po_supplier ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Invoice Supplier / Dealer? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.invoice_supplier ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Accessories POs ( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.accessories_po ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Accessories Job Cards ( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.accessories_job_cards ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Invoices from Accessories Suppliers( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.accessories_suppliers ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Sundries PO ( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.sundries_po ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Invoices from Sundries Suppiers( If Applicable)?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.sundries_invoices ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Pre Inspection Delivery document ( PID)? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.pre_inspection ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Registration ( Natis Documents)? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.registration_doc ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Tracker Certificate? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.tracker_certificate ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Insurance Certificate? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.insurance_certificate ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.selectedFieldName}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                }
           />
    }

    getView(data) {
        //console.log('documentsx release_note_gen', data.release_note_gen)
        //console.log('documentsx release_note_gen', [data.release_note_gen])
        return this.props.confirm ? <div>
                {this.getContract(data.vehicle)}
                {this.getCustomer(data.customer)}
                {this.getSupplier(data.supplier)}
                {this.getVehicle(data.vehicle)}
                {this.getDownload([data.release_note_gen])}
                {this.getChecklistView()}
        </div>
        : <div>
                {this.getContract(data.vehicle)}
                {this.getCustomer(data.customer)}
                {this.getSupplier(data.supplier)}
                {this.getVehicle(data.vehicle)}
                {this.getDownload([data.release_note_gen])}
                {this.getChecklist()}
        </div>
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log('WorkflowPODelivery workflow_queue_data', workflow_queue_data)
        let data = null
        try {
            data = this.props.workflow_queue_data.context_data.data.data.data[0]
            //console.log('WorkflowPODelivery workflow_queue_data2', workflow_queue_data)
        }
        catch (err) { 
            //console.log('WorkflowPODelivery err', err)
        }
        
        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.workflowFMLReleaseNote }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowFMLReleaseNote)