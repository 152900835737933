import * as types from './types'

export const resetReportView = () => {
    return (dispatch) => {
        dispatch({
            type: types.RESET_REPORT_VIEW_DATA
        })
        dispatch({
            type: types.GET_REPORT_VIEW_DATA
        })
    }
}

export const loadReports = () => {
    return (dispatch) => {
        //console.log('setReport view get')
        dispatch({
            type: types.RESET_REPORT_VIEW_DATA
        })
        dispatch({
            type: types.GET_REPORT_VIEW_DATA
        })
    }
}

export const clearReport = (payload) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_REPORT_VIEW_DATA,
            payload: {
                prop: 'report', 
                value: null
            }
        })
    }
}

export const setReport = (payload) => {
    return (dispatch) => {
        //console.log('setReport view',payload)
        dispatch({
            type: types.SET_REPORT_VIEW_DATA,
            payload: {
                prop: 'data', 
                value: payload
            }
        })
        dispatch({
            type: types.SET_REPORT_VIEW_DATA,
            payload: {
                prop: 'loading', 
                value: false
            }
        })
    }
}

export const setReportView = (report) => {
    return (dispatch) => {
        //console.log('setReportView',report)
        dispatch({
            type: types.SET_REPORT_VIEW_DATA,
            payload: {
                prop: 'report', 
                value: report
            }
        })
        dispatch({
            type: types.SET_REPORT_VIEW_DATA,
            payload: {
                prop: 'breadcrumb', 
                value: report ? report.report_name : report
            }
        })
    }
}




export const filterReport = (search) => {
    //console.log('filterReport',search)
    return (dispatch) => {
        //console.log('filterReport',search)
        dispatch({
            type: types.SET_REPORT_VIEW_DATA,
            payload: {
                prop: 'search', 
                value: search
            }
        })
    }
}

