import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
import { ExcelRenderer, OutTable } from 'react-excel-renderer'

export const setValue = (prop, value) => {
    return (dispatch, getState) => {       
            dispatch({
                type: types.SET_BILLING_VALUE_CHANGE,
                payload: {
                    prop: prop,
                    value: value
                }
            })
    }
}
export const reset = () => {
    return (dispatch, getState) => {       
            dispatch({
                type: types.RESET_BILLING_VALUES,
            })
    }
}
export const setAcceptedDocuments = (documents) => {
    return (dispatch, getState) => {
        documents.forEach(file => {
            //console.log('File',file)
            ExcelRenderer(file, (err, resp) => {
                if (err) {
                    //console.log(err);
                }
                else {
                    //console.log(resp.rows.filter(x => x.length).filter(x => x.length > 1))

                    //console.log('resp.rows', resp.rows)
                    dispatch({
                        type: types.SET_BILLING_PREP_IMPORTED_DATA,
                        payload: resp.rows.filter(x => x.length).filter(x => x.length > 1)
                    })
                }
            });
        });
        //console.log('SET_UPLOADED_EXCEL_FILE_NAME')
        //console.log(documents[0])
        dispatch({
            type: types.SET_BILLING_PREP_UPLOADED_EXCEL_FILE_NAME,
            payload: {
                preview: documents[0].preview,
                filename: documents[0].name,
            }
        })
        dispatch({
            type: types.SET_BILLING_PREP_UPLOADED_EXCEL_FILE_BUSY,
            payload: false
        }) 
    };
}
