import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import * as config from '../../config'
import { Table} from "@mui/material"
import { Container, Row, Col } from 'react-grid-system'
import ViewQuotation from '../../controls/view_quotation'

//import TextField from '@mui/material/TextField'
import TextField from '../../controls/textField'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import ViewCustomer from '../../controls/view_customer'

import PdfViewer from '../PDF/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowPOApprovalByGMComponent extends Component {

    getSystem(data) {
        return <Card 
                title={window.t.en('Quoted History ')}
                subtitle={window.t.en('List Of Steps')}
                content={
                    <Table className="table" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            <th className="text-left ">Step Name</th>
                            <th className="text-left ">System User</th>
                            <th className="text-left">Datetime</th>
                            <th className="text-left">Comments</th>
                        </thead>
                        <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                            <tr>
                                <td>{'Quote Created'}</td>
                                <td>{data.vehicle.assignto.firstname}</td>
                                <td>{moment(data.vehicle.quotedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.vehicle.comments}</td>
                            </tr>
                            <tr>
                                <td>{'Quote Review'}</td>
                                <td>{data.review.approvedby}</td>
                                <td>{moment(data.review.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.review.comments}</td>
                            </tr>
                            <tr>
                                <td>{'Quote Approve'}</td>
                                <td>{data.approval.approvedby}</td>
                                <td>{moment(data.approval.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.approval.comments}</td>
                            </tr>
                            <tr>
                                <td>{'Quote Accepted'}</td>
                                <td>{data.accepted.acceptedby}</td>
                                <td>{moment(data.accepted.acceptdate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.accepted.acceptedcomments}</td>
                            </tr>
                        </tbody>
                    </Table>
                }
            />
    }

    getContract(data) {
        //console.log('Contract',data)
        return <Card 
                title={window.t.en('Quote Confirmation')}
                subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                    <Col xl={12}>
                    {this.props.workflow_queue_data.workflow_queue_id && <ViewQuotation
                     key={this.props.workflow_queue_data.workflow_queue_id}
                    id={this.props.workflow_queue_data.workflow_queue_id}
                     />}
                     </Col>
                     <Col xl={12}>
                    <br></br>
                  </Col>
                     <Col xl={12}>
                    <Fields nocard={true}
                        fields={[
                        {size: 4, label: 'Contract Type:', value: data.contractname},
                        {size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory},
                        {size: 4, label: ' Monthly Distance:', value: data.distancepermonth},
                        {size: 4, label: 'Contract Period:', value: data.contractperiod},
                        {size: 4, label: 'Quote For:', value: data.quotefor},
                        {size: 4, label: 'RFQ Date:', value: data.quotedate},
                        {size: 4, label: 'Vehicle Color:', value: data.color},
                        {size: 4, label: 'Vehicle Location:', value: data.location},
                        {size: 4, label: 'Special Vehicle:', value: data.specialistvehicle},
                        {size: 4, label: 'Vehicle Group:', value: data.vehiclegroup},
                        {size: 4, label: 'Operating Terrain:', value: data.terrain},
                        {size: 4, label: 'Vehicle Usage:', value: data.usage},
                        {size: 4, label: 'Comments:', value: data.comments},
                    ]}
                />
                </Col>
                </Row>
                </Container>
                }
            />
    }

    getCustomer(customer) {
        let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        return <Card 
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                    <ViewCustomer id={customer.customer_id} nocard={true} min={true} />
                    // <Fields nocard={true}
                    //     fields={[
                    //         { size: 4, label: 'Customer Name:', value: customer.customername },
                    //         { size: 4, label: 'Cost Centre:', value: customer.costcentre },
                    //         { size: 4, label: 'Account Number:', value: customer.accountnumber },
                    //         { size: 4, label: 'Contact Person Name:', value: contact.fullname },
                    //         { size: 4, label: 'Contact Number:', value: contact.mobile },
                    //         { size: 4, label: 'Contact Email:', value: contact.email },
                    //     ]}
                    // />
                }
            />
    }

    getSupplier(data) {
        //console.log('Supplier',data)
        return <Card 
                title={window.t.en('Supplier Details')}
                subtitle={window.t.en('Selected Vehicle Supplier ')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Supplier Name:', value: data.suppliername},
                            {size: 4, label: 'Trading Name:', value: data.tradingnamename},
                            {size: 4, label: 'Account Number:', value: data.accountnumber},
                            {size: 4, label: 'VAT Number:', value: data.vatnumber},
                            {size: 4, label: 'Contact Person:', value: data.contactperson},
                            {size: 4, label: 'Contact Email:', value: data.contactemail},
                            {size: 4, label: 'Contact Number:', value: data.contactnumber},
                        ]}
                    />
                }
            />
    }

    getVehicles(data) {
        return <Card 
                title={window.t.en('Quoted Vehicles ')}
                subtitle={window.t.en('Quoted Vehicle List')}
                content={
                    <Table className="table" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            <th className="text-left "></th>
                            <th className="text-left ">Vehicle Description</th>
                            <th className="text-left">Vehicle Pricing</th>
                            <th className="text-left">Residual Values</th>
                            <th className="text-right">Vehicle Component </th>
                            <th className="text-right">Rental</th>
                        </thead>
                        <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                        {
                            data.map((x, i) => {
                            return <> 
                                    <tr key={'vehicle_inx_' + i}>
                                        <td xl={3}>
                                            <img style={styles.vehicle} src={config.system.cdn + '/' + (x.worksheet.mmcode.imagefilename !== null ? x.worksheet.mmcode.imagefilename : 'unknown.png')} />
                                        </td>
                                        <td xl={3}>
                                            MM: {x.worksheet.mmcode.mmcode} <br />
                                            {x.worksheet.mmcode.make}<br />
                                            {x.worksheet.mmcode.cdescription}
                                        </td>
                                        <td xl={3} style={styles.numericFieldValues}>
                                            RP: {parseFloat(x.worksheet.retailprice)}<br />
                                            DS: {parseFloat(x.worksheet.discount)}<br />
                                            VP: {parseFloat(x.worksheet.vehicleprice)}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            RV %: {x.worksheet.rv}<br />
                                            RV: {x.worksheet.rv_amount}<br />
                                            {x.worksheet.deliveryperiod + ' Days'}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            Maint: {x.worksheet.maintenance}<br />
                                            Tyres: {x.worksheet.tyres}<br />
                                            Service: {x.worksheet.service}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            {x.worksheet.monthly_excl}<br />
                                            {x.worksheet.monthly_vat}<br />
                                            {x.worksheet.monthly_incl}
                                        </td>
                                    </tr>
                                </> 
                            })
                        }
                        </tbody>
                    </Table>
                }
            />
    }

    getDocuments(data) {
        return <Card 
                title={window.t.en('Document')}
                subtitle={window.t.en('Uploaded Customer Supporting Documents')}
                content={
                    data.length
                    ? <PdfViewer
                        key={'uplaod_customer_viewer'}
                        documents={data}
                        multiple={true}
                        preview={'preview'}
                    />
                    : 'Loading...'
                }
            />
        }

    getVehicle(data) {
        return <Card 
                title={window.t.en('Accepted Quote ' + data.quotenumber)}
                subtitle={window.t.en('Customer Quote Accepted')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Quote Number:', value: data.quotenumber},
                            {size: 4, label: 'System PO Number:', value: data.ponumber},

                            {size: 12, header: 'Vehicle Details'},
                            {size: 4, label: 'Vehicle Make:', value: data.mmcode.make},
                            {size: 4, label: 'Vehicle Model:', value: data.mmcode.cdescription},

                            {size: 4, label: 'MM Code:', value: data.mmcode.mmcode},
                            {size: 4, label: 'Transmission:', value: data.mmcode.manualauto},

                            {size: 4, label: 'Introduction Year:', value: data.mmcode.regyear},
                            {size: 4, label: 'Body Type:', value: data.mmcode.bodytype},

                            {size: 4, label: 'Contract Type:', value: data.contractname},
                            {size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory},

                            {size: 4, label: 'Monthly Distance', value: data.distancepermonth},
                            {size: 4, label: 'Contract Period:', value: data.contractperiod},

                           // {size: 4, label: 'Rate Card:', value: data.ratecard},
                            {size: 4, label: 'Delivery Period:', value: data.deliveryperiod},
                            
                            {size: 12, header: 'Rental Breakdown'},
                            {size: 4, label: 'Retail Price:', value: data.retailprice},
                            {size: 4, label: 'Discount:', value: data.discount},

                            {size: 4, label: 'Interest Rate:', value: data.primerate},
                            {size: 4, label: 'Vehicle Price:', value: data.vehicleprice},

                            {size: 4, label: 'Residual %:', value: data.rv},
                            {size: 4, label: 'Residual Value:', value: data.rv_amount},

                            {size: 4, label: 'ROI %:', value: data.roipercentage},
                            {size: 4, label: 'ROI:', value: data.roi},

                            {size: 4, label: 'Tyre CPK:', value: data.tyrescpk},
                            {size: 4, label: 'Tyre:', value: data.tyres},

                            {size: 4, label: 'Maintenance CPK:', value: data.maintenancecpk},
                            {size: 4, label: 'Maintenance:', value: data.maintenance},

                            {size: 4, label: 'Admin Fee:', value: data.adminfee},
                            {size: 4, label: 'License Fee:', value: data.licence},
                            {size: 4, label: 'Service:', value: data.service},
                            {size: 4, label: 'Sundry:', value: data.sundry_tot},
                            
                            {size: 12, header: 'Monthly Rental'},
                            {size: 4, label: 'Rental Excl:', value: data.monthly_excl},

                            {size: 4, label: 'Vat:', value: data.monthly_vat},
                            {size: 4, label: 'Rental Incl:', value: data.monthly_incl},
                        ]}
                    />
                }
            />
    }

    getComments() {
        return <Card 
                title={window.t.en('Comments')}
                subtitle={window.t.en('Enter Approval Comments')}
                content={
                    <TextField
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        id={'txt_laodfactor'}
                        hintText={'Enter Approval Comments'}
                        value={this.props.state.comments}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue({ prop: 'comments', value: event.target.value })
                        }}
                    />
                }
            />
    }

    getView(data) {
        return <div>
                {this.getSystem(data)}
                {this.getContract(data.vehicle)}
                {this.getCustomer(data.customer)}
                {this.getVehicles(data.quotations)}
                {this.getSupplier(data.supplier)}
                {this.getVehicle(data.vehicle)}
                {this.getDocuments(data.documents)}
                {this.getComments()}
        </div>
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log('WorkflowPOApprovalByGM workflow_queue_data', workflow_queue_data)
        let data = null
        try {
            data = this.props.workflow_queue_data.context_data.data.data.data[0]
        }
        catch (err) { }
        
        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowPOApprovalByGM
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOApprovalByGMComponent)