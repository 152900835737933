export const title = ''
export const description = ''
export const bubble = {}
export const bottomSheetIsOpen = false
export const dashboard = ''
export const report = ''
export const value = 0
export const reportValue = 0

export const loading = true

export const tab = 0

export const tabs = [
    {
        label: 'Data',
        value: 0,
        highlight: true,
        visible: true
    },
    {
        label: 'Dashboards',
        value: 1,
        highlight: true,
        visible: true
    },
    {
        label: 'Reports',
        value: 2,
        highlight: true,
        visible: true
    },
    {
        label: 'Create',
        value: 3,
        highlight: true,
        visible: true
    }
]