import moment from 'moment'
import validator from '../../validate'
import get from 'lodash/get'

import * as appTypes from '../App/types'
import * as types from './types'
import * as readComponentActions from '../ReadComponent/actions'
import { getComponentTitle, getComponentDescription } from '../../helper';

export const loadComponent = (component, id) => {
    return (dispatch, getState) => {
        dispatch(getComponentSchema(component, id))
        //dispatch(getComponentRelationships(component))
        //dispatch(getComponentData(component, id))
    }
}

export const saveComponentData = (component) => {
    const getData = () => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name] && !get(component.data[x.name].input, x.name + '_id')) {
                obj[x.name] = component.data[x.name].input
            }

            return obj
        }, {})
    }

    return (dispatch, getState) => {
        dispatch(validateForm(component.name))

        const data = getData()

        if (getState().form.valid) {
            dispatch({
                type: types.SAVE_COMPONENT_DATA,
                payload: {
                    component: component,
                    data: data
                }
            })
        }
    }
}

export const componentDataSaved = (component) => {
    return (dispatch, getState) => {
        dispatch(readComponentActions.setReadComponentView('view', ''))

        dispatch({
            type: types.COMPONENT_DATA_SAVED
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: getComponentTitle(component) + ' workflowinput successfully saved on ' + moment(new Date()).format('LLLL'),
                overflowText: 'Action',
                text: '', //getComponentDescription(component)
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

    }
}

export const componentDataSaveFailed = (component) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Unable to save ' + getComponentTitle(component) + ' on ' + moment(new Date()).format('LLLL'),
                overflowText: 'Action',
                text: '', //getComponentDescription(component)
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.COMPONENT_DATA_SAVE_FAILED
        })
    }
}

const validate = (schema, field, input) => {
    if (!schema[field.name]) {
        return {
            valid: true,
            errors: []
        }
    }

    const adhocSchema = {
        [field.name]: schema[field.name]
    }

    const adhocData = {
        [field.name]: input.toString()
    }

    const result = validator().validate(adhocSchema, adhocData)

    return {
        ...result,
        message: result.errors.map(x => { return x.message }).join(', ')
    }
}

export const validateForm = (name) => {
    return (dispatch, getState) => {
        const component = getState().components[name]
        let valid = true

        component.component_field.forEach((x) => {
            const result = validate(component.schema, x, component.data[x.name].input)

            if (!result.valid) {
                valid = false
            }

            dispatch({
                type: types.SET_COMPONENT_FIELD_INPUT,
                payload: {
                    component: component.name,
                    field: x.name,
                    input: component.data[x.name].input,
                    validation: result
                }
            })
        })

        dispatch({
            type: types.SET_FORM_VALIDATION_RESULT,
            payload: valid
        })
    }
}

export const resetForm = (component) => {
    return {
        type: types.RESET_FORM,
        payload: component
    }
}

export const getComponentRelationships = (component) => {
    return {
        type: types.GET_COMPONENT_RELATIONSHIPS,
        payload: component
    }
}

export const setComponentRelationship = (component, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_RELATIONSHIPS,
            payload: {
                component: component,
                relatedFull: payload
            }
        })
    }
}

export const getComponentSchema = (component) => {
    return {
        type: types.GET_COMPONENT_SCHEMA,
        payload: component
    }
}

export const setComponentSchema = (component, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_SCHEMA,
            payload: {
                component: component,
                schema: payload
            }
        })
    }
}

export const getComponentData = (name, id) => {
    return (dispatch, getState) => {
        const component = getState().components[name]

        if (component && !isNaN(id) && id > 0) {
            dispatch({
                type: types.GET_COMPONENT_DATA,
                payload: {
                    component: component.name,
                    query: '?' + component.component_field.filter(x => x.key)[0].name + '=' + id,
                    id: id ? id : 0
                }
            })
        }
    }
}

export const setComponentData = (name, data, id) => {
    const formatDate = (value) => {
        if (value === null) {
            return null
        }

        if (isNaN(Date.parse(value))) {
            const time = moment(value, 'HH:mm:ss', true)

            if (time.isValid()) {
                const date = moment().set({
                    'hour': time.get('hour'),
                    'minute': time.get('minute'),
                    'second': time.get('second')
                })

                return date.toDate();
            }

            return
        }

        return new Date(value)
    }

    const getInputValue = (field, data) => {
        if (!field.component_field_display_type) {
            return ''
        }

        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return data[field.name]
            case 'datePicker':
                return formatDate(data[field.name])
            case 'timePicker':
                return formatDate(data[field.name])
            case 'toggle':
                return (typeof data[field.name] !== 'undefined' && data[field.name] !== null)
            case 'hidden':
                return data[field.name] !== null ? data[field.name] : 0
            default:
                return data[field.name] === null ? '' : data[field.name]
        }
    }

    const getLookupData = (field, data) => {
        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                const key = field.name.replace('_id', '')
                const fields = field.display.split('|')
                const accessor = fields[0].split('.')[0]

                return [{
                    value: data[field.name] !== null ? data[field.name] : '', //temp fix, the data MUST be correct
                    text: fields.map(x => {
                        return x.split(',').map(y => {
                            return get(data[key], y.replace(accessor + '.', ''))
                        }).join(' ')
                    }).join(', ')
                }]
            default:
                return []
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[name]

        data = Object.keys(data).reduce((obj, x) => {
            const field = component.component_field.filter(y => y.name === x)[0]

            /*             let lookup = []
            
                        if (x.component_field_display_type.name === 'autoComplete') {
                            lookup.push()
                        } */

            if (field) {
                const lookup = getLookupData(field, data)

                obj[x] = {
                    key: ''.concat('component_', component.name, '_field_', field.name, '_id_', id),
                    input: getInputValue(field, data),
                    data: lookup,
                    text: lookup.length ? lookup[0].text : '',
                    validation: {
                        valid: true,
                        errors: []
                    }
                }
            }
            else {
                obj[x] = data[x]
            }

            return obj
        }, {})

        //console.log('Form loadComponent FORM action5', data)
        dispatch({
            type: types.SET_COMPONENT_DATA,
            payload: {
                component: name,
                data: data
            }
        })

        /*         if (component.relatedFull.hasMany.length) {
                    component.relatedFull.hasMany.forEach(x => {
                        const related = getState().components[x.key]
                        const key = component.component_field.filter(x => x.key)[0].name
                        const id = data[key].input
        
                        if (related) {
                            dispatch(readComponentActions.getRelatedComponentData(component, related, key, id))
                        }
                    })
                } */
    }
}

export const setComponentFieldInput = (field, input) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = format(field, input)

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                validation: validate(component.schema, field, value)
            }
        })

        const data ={
            val: value,
            prop: field.name
        }
        
        // dispatch({
        //     type: types.SET_SELECT_FIELD_PK,
        //     payload: {
        //         component: getState().components[field.display.split('.')[0]],
        //         data: data
        //     }
        // })
    }
}

export const getComponentFieldLookupData = (field, searchText, dataSource, params) => {
    return (dispatch, getState) => {
     
        const component = getState().components[field.component.name]
   
        const search = searchText.length > 0 ? '?' + field.search + '=' + searchText : ''

        const param = field.parameters ? field.parameters.indexOf('.') > -1
                        ? (search.length > 0 ? '&' : '?') + field.parameters.split('.')[1] + '=' +  getState().components[field.parameters.split('.')[0]].data[field.parameters.split('.')[1]].input
                        : (search.length > 0 ? '&' : '?') + field.parameters + '=' +  component.data[field.parameters].input 
                    : ''

        const payload = {
            component: component,
            field: field,
            related: field.related ? field.display.split('.')[0] : 'all',
            query: search + param,
            text: searchText
        }

        dispatch({
            type: types.GET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component.name,
                field: field.name,
                data: data
            }
        })
    }
}
