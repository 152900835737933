import * as colours from '@mui/material/colors'

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 10
}
export const lintitems = {
    padding: 2
}
export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '19px',
}

export const numericFields = {
    textAlign: 'right',
}

export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    textAlign: 'left',
}