

import Button from '@mui/material/Button'
import { Card, CardHeader, CardContent } from '@mui/material'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AutoComplete from '../../../controls/autocomplete'
import Checkbox from '../../../controls/checkbox'
import TextField from '../../../controls/textField'

import * as compActions from '../../Components/actions'
import SUPPLIERPAYMENTALLOCATION from '../../Custom/SUPPLIERPAYMENTALLOCATION/component'
import * as actions from './actions'
import * as styles from './styles'




var view = false
class SUPPLIERJOURNAL extends Component {
    componentDidMount() {
        if (this.props.state.loading) this.props.actions.getComponent('supplierjournal')
    }
    addNew() {
        this.props.actions.setLookupValue("reference", '', '')
        this.props.actions.setInputValue('supplier_id', '')
        this.props.actions.setInputValue('suppliername', '')
        this.props.actions.setInputValue('suppliertransactiondate', new Date())
        var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')                                                          
        this.props.actions.setInputValue('suppliertransactionmonth', _date)
        this.props.actions.setInputValue('description', '')
        this.props.actions.setInputValue('business_id', '')
        this.props.actions.setInputValue('businessname', '')
        this.props.actions.setInputValue('transactiontype', '')
        this.props.actions.setInputValue('accountingitem_id', '')
        this.props.actions.setInputValue('itemname', '')
        this.props.actions.setInputValue('credit', 0)
        view = false
    }

    getView() {
        return <Tabs>
            <Tab label={'Capture'}>
                <br></br>
                <Card style={styles.card} style={styles.row}>
                    <CardHeader
                        title={window.t.en('Supplier Journal')}
                        subtitle={window.t.en('Capture supplier journal')}
                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                    <CardContent>
                        <Container style={styles.whitediv}>
                            <Row>
                                {/*Left Side*/}
                                <Col xl={12}>
                                    <Row><Col xl={6}><br></br></Col><Col xl={6}>
                                        < Button className={"global_button"} variant="contained" header={false}
                                            primary={true}
                                            label={'New Journal'}
                                            onClick={(event) => {
                                                this.addNew()
                                            }}
                                        >{window.t.en('New Journal')}</Button>
                                    </Col></Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><strong>Journal:</strong></div>
                                                <div style={styles.fieldContent}>
                                                    <AutoComplete
                                                        info={{
                                                            show: false
                                                        }}
                                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        id={'dll_invoice_id'}
                                                        key={'dll_invoice_id'}
                                                        hintText={'Search by reference number...'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        data={this.props.state.data.reference.data}
                                                        onSelect={
                                                            (chosenRequest, index) => {
                                                                this.props.actions.setLookupValue("reference", chosenRequest.value, chosenRequest.text)

                                                                if (chosenRequest.value) {
                                                                    view = true;
                                                                    this.props.actions.setInputValue('supplier_id', chosenRequest.supplier_id)
                                                                    this.props.actions.setInputValue('suppliername', chosenRequest.supplier_text)
                                                                    this.props.actions.setInputValue('suppliertransactiondate', new Date(chosenRequest.suppliertransactiondate))
                                                                    this.props.actions.setInputValue('suppliertransactionmonth', chosenRequest.suppliertransactionmonth)
                                                                    this.props.actions.setInputValue('description', chosenRequest.description)
                                                                    this.props.actions.setInputValue('business_id', chosenRequest.business_id)
                                                                    this.props.actions.setInputValue('businessname', chosenRequest.business_text)
                                                                    this.props.actions.setInputValue('transactiontype', chosenRequest.transactiontype)
                                                                    this.props.actions.setInputValue('itemname', chosenRequest.accountingitem_text)
                                                                    this.props.actions.setInputValue('credit', chosenRequest.amount)
                                                                }
                                                            }
                                                        }
                                                        onClick={
                                                            (event) => {
                                                                this.props.actions.getAllJournals('')
                                                            }
                                                        }
                                                        onFilter={
                                                            (search, dataSource, params) => {
                                                                this.props.actions.getAllJournals(search)
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Creditor:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {view ?
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            disabled={true}
                                                            id={'txt_supplier_fktext'}
                                                            hintText='creditor'
                                                            value={this.props.state.data.suppliername.input}
                                                            onChange={(event, value) => {
                                                                this.props.actions.setInputValue('suppliername', value)
                                                            }}
                                                        /> : <AutoComplete
                                                            info={{
                                                                show: false
                                                            }}
                                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            id={'dll_creditor_id'}
                                                            key={'dll_creditor_id'}
                                                            hintText={'Select a creditor...'}
                                                            filter={AutoComplete.fuzzyFilter}
                                                            errorText={this.props.state.data.supplier_id.input ? null : '*'}
                                                            openOnFocus={true}
                                                            fullWidth={true}
                                                            dataSourceConfig={{
                                                                text: 'text',
                                                                value: 'value'
                                                            }}
                                                            data={this.props.state.data.supplier_id.data}
                                                            onSelect={
                                                                (chosenRequest, index) => {
                                                                    var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')  
                                                                    this.props.actions.setLookupValue("supplier_id", chosenRequest.value, chosenRequest.text)
                                                                    this.props.actions.setInputValue('description', _date)
                                                                    this.props.actions.setInputValue('suppliertransactiondate', new Date())
                                                                    this.props.actions.setInputValue('suppliertransactionmonth', _date)
                                                                }
                                                            }
                                                            onClick={
                                                                (event) => {
                                                                    this.props.actions.getLookupValueData('')
                                                                }
                                                            }
                                                            onFilter={
                                                                (search, dataSource, params) => {
                                                                    this.props.actions.getLookupValueData(search)
                                                                }
                                                            }
                                                        />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Date:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        type="date"
                                                        disabled={view}
                                                        id={'txt_invoicedate'}
                                                        hintText='Date'
                                                        container='inline'
                                                        mode='landscape'
                                                        value={this.props.state.data.suppliertransactiondate.input}
                                                        errorText={this.props.state.data.suppliertransactiondate.input ? null : '*'}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('suppliertransactiondate', value)
                                                            var _date = moment(new Date(value)).format('YYYYMM')
                                                            this.props.actions.setInputValue('suppliertransactionmonth', _date)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Reference:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        disabled={view}
                                                        id={'txtreference'}
                                                        hintText='Reference'
                                                        errorText={this.props.state.data.reference.input ? null : '*'}
                                                        value={this.props.state.data.reference.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('reference', value)
                                                            this.props.actions.getDuplicateReferenceCheck(value)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Description:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        disabled={view}
                                                        id={'txtdescription'}
                                                        value={this.props.state.data.description.input}
                                                        errorText={this.props.state.data.description.input ? null : '*'}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('description', value)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Branch:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {
                                                        view ?
                                                            <TextField
                                                                variant="outlined"
                                                                margin="normal"
                                                                disabled={true}
                                                                id={'txt_businessname'}
                                                                hintText='Branch'
                                                                value={this.props.state.data.businessname.input}
                                                                onChange={(event, value) => {
                                                                    this.props.actions.setInputValue('businessname', value)
                                                                }}
                                                            /> : <AutoComplete
                                                                info={{
                                                                    show: false
                                                                }}
                                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                id={'dll_business_id'}
                                                                key={'dll_business_id'}
                                                                hintText={'Select a branch...'}
                                                                filter={AutoComplete.fuzzyFilter}
                                                                openOnFocus={true}
                                                                fullWidth={true}
                                                                dataSourceConfig={{
                                                                    text: 'text',
                                                                    value: 'value'
                                                                }}
                                                                data={this.props.state.data.business_id.data}
                                                                onSelect={
                                                                    (chosenRequest, index) => {
                                                                        this.props.actions.setLookupValue("business_id", chosenRequest.value, chosenRequest.text)
                                                                    }
                                                                }
                                                                onClick={
                                                                    (event) => {
                                                                        this.props.actions.getBusinessListData('')
                                                                    }
                                                                }
                                                                onFilter={
                                                                    (search, dataSource, params) => {
                                                                        this.props.actions.getBusinessListData(search)
                                                                    }
                                                                }
                                                            />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Transaction:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {
                                                        view ?
                                                            <TextField
                                                                variant="outlined"
                                                                margin="normal"
                                                                disabled={true}
                                                                id={'txt_transactiontype'}
                                                                hintText='Transaction'
                                                                value={this.props.state.data.transactiontype.input}
                                                                onChange={(event, value) => {
                                                                    this.props.actions.setInputValue('transactiontype', value)
                                                                }}
                                                            /> : <AutoComplete
                                                                info={{
                                                                    show: false
                                                                }}
                                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                id={'dll_transaction'}
                                                                key={'dll_transaction'}
                                                                hintText={'Select transaction type...'}
                                                                errorText={this.props.state.data.transactiontype.input ? null : '*'}
                                                                filter={AutoComplete.fuzzyFilter}
                                                                openOnFocus={true}
                                                                fullWidth={true}
                                                                dataSourceConfig={{
                                                                    text: 'text',
                                                                    value: 'value'
                                                                }}
                                                                data={this.props.state.data.transactiontype.data}
                                                                onSelect={
                                                                    (chosenRequest, index) => {
                                                                        this.props.actions.setLookupValue("transactiontype", chosenRequest.value, chosenRequest.text)
                                                                    }
                                                                }
                                                                onClick={
                                                                    (event) => {
                                                                        this.props.actions.setTransactionTypeList()
                                                                    }
                                                                }
                                                                onFilter={
                                                                    (search, dataSource, params) => {
                                                                        this.props.actions.setTransactionTypeList()
                                                                    }
                                                                }
                                                            />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><strong>{'Amount:'}</strong></div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        disabled={view}
                                                        id={'txtamount'}
                                                        errorText={this.props.state.data.credit.input ? null : '*'}
                                                        value={this.props.state.data.credit.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('credit', value)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Contra Account:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {view ?
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            disabled={true}
                                                            id={'txt_accountingitem_id'}
                                                            hintText='Contra account'
                                                            value={this.props.state.data.itemname? this.props.state.data.itemname.input : ''}
                                                            onChange={(event, value) => {
                                                                this.props.actions.setInputValue('itemname', value)
                                                            }}
                                                        /> : <AutoComplete
                                                            info={{
                                                                show: false
                                                            }}
                                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            id={'dll_business_id'}
                                                            key={'dll_business_id'}
                                                            errorText={this.props.state.data.accountingitem_id.input ? null : '*'}
                                                            hintText={'Select a account...'}
                                                            filter={AutoComplete.fuzzyFilter}
                                                            openOnFocus={true}
                                                            fullWidth={true}
                                                            dataSourceConfig={{
                                                                text: 'text',
                                                                value: 'value'
                                                            }}
                                                            data={this.props.state.data.accountingitem_id.data}
                                                            onSelect={
                                                                (chosenRequest, index) => {
                                                                    this.props.actions.setLookupValue("accountingitem_id", chosenRequest.value, chosenRequest.text)
                                                                }
                                                            }
                                                            onClick={
                                                                (event) => {
                                                                    this.props.actions.getContractAccountListData('')
                                                                }
                                                            }
                                                            onFilter={
                                                                (search, dataSource, params) => {
                                                                    this.props.actions.getContractAccountListData(search)
                                                                }
                                                            }
                                                        />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Month:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        /* disabled={true} */
                                                        id={'txt_suppliertransactionmonth'}
                                                        hintText='Month'
                                                        value={this.props.state.data.suppliertransactionmonth.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('suppliertransactionmonth', value)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                        <Row>
                            <Col xl={12}><br></br></Col>
                        </Row>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={10}></Col>
                                <Col xl={2}>
                                    <Row>

                                        <Col xl={12}>
                                            < Button className={"global_button"} variant="contained"
                                                disabled={view}
                                                header={false}
                                                label={'Save Journal'}
                                                onClick={(event) => {
                                                    var validationfields =
                                                        [
                                                            {
                                                                value: this.props.state.data.supplier_id.input,
                                                                field: "Creditor"
                                                            },
                                                            {
                                                                value: this.props.state.data.credit.input,
                                                                field: "Amount"
                                                            },
                                                            {
                                                                value: this.props.state.data.accountingitem_id.input,
                                                                field: "Account"
                                                            },
                                                            {
                                                                value: this.props.state.data.transactiontype.input,
                                                                field: "Transaction Type"
                                                            },
                                                            {
                                                                value: this.props.state.data.description.input,
                                                                field: "Description"
                                                            }
                                                        ]
                                                    if (this.props.state.data.reference.text === true) {
                                                        var payload = {
                                                            data: this.props.state.data,
                                                            documents: this.props.state.upload.documents,
                                                            user: this.props.state.user
                                                        }
                                                        this.props.actions.saveData(payload, validationfields)
                                                    }
                                                    if (this.props.state.data.reference.text === false) {
                                                        this.props.actions.foundDuplicateReference()
                                                    }
                                                }}
                                            >{window.t.en('Save Journal')}</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </Tab>
            <Tab label={'Allocation'}>
                <br></br>
                <Container style={{ margin: 0 }} className="custom-container">
                    <SUPPLIERPAYMENTALLOCATION></SUPPLIERPAYMENTALLOCATION>
                </Container>
            </Tab>
        </Tabs>
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.supplierjournal,
            upload: {
                ...state.upload
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERJOURNAL)