import moment from 'moment'
import * as _ from 'lodash'
import * as componentActions from '../Components/actions'

import * as appTypes from '../App/types'
import * as types from './types'

import * as validator from '../../helpers/validator'

export const clearWorkflowTaskApprovalInformation = () => {
    return {
        type: types.CLEAR_WORKFLOW_TASK_APPROVAL_INFORMATION
    }
}


export const setToggleConfirm = (status, el) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_APPROVAL_CONFIRM_TOGGLE_MENU_STATUS,
            payload: {
                confirm: status,
                confirmEl: el,
            }
        })
    }
}

export const clearWorkflowTaskApprovalData = () => {
    return (dispatch) => {
        dispatch({
            type: types.CLEAR_WORKFLOW_TASK_APPROVAL_DATA,
            payload: {
                data: []
            }
        })
    }
}

export const getWorkflowTaskApprovalInformation = (workflow, task, amount) => {
    return (dispatch, getState) => {
        const queue = getState().workflowQueueTasks.queue

        dispatch(clearWorkflowTaskApprovalInformation())
        dispatch(getNextTeamUserList(queue, amount))
        dispatch(clearWorkflowTaskApprovalData())

        dispatch({
            type: types.GET_WORKFLOW_TASK_APPROVAL_INFORMATION,
            payload: {
                workflow: workflow,
                task: task,
                queue: queue.workflow_queue_id && queue.workflow_queue_task_id
                    ? queue
                    : {}
            }
        })
    }
}

export const getNextTeamUserList = (queue, amount) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_NEXT_WORKFLOW_TEAM_USERS,
            payload: {
                team_id: null,
                user_id: null,
                body: {
                    query: 'next_workflow_team_users',
                    queue: queue,
                    amount: amount
                }
            }
        })
    }
}

export const setNextTeamUserList = (response, queue) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_NEXT_WORKFLOW_TEAM_USERS,
            payload: response
        })
    }
}

export const setWorkflowTaskApprovalInformation = (workflow, task, queue, data) => {
    return (dispatch, getState) => {
        window.glyco.log(data)

        if (!data[0]) {
            dispatch({
                type: types.SET_WORKFLOW_TASK_APPROVAL_INFORMATION,
                payload: undefined
            })

            return
        }

        dispatch({
            type: types.SET_WORKFLOW_TASK_APPROVAL_INFORMATION,
            payload: data[0]
        })

        const components = _.uniq(
            data[0].workflow_task_step.reduce((arr, x) => {
                x.workflow_task_step_component.forEach(y => {
                    arr.push(y.component.name)
                })

                return arr
            }, [])
        )

        components.forEach(x => {
            dispatch(componentActions.getComponent(x))
        })

        if (queue.workflow_queue_id && queue.workflow_queue_task_id) {
            dispatch(getWorkflowTaskApprovalData(queue.workflow_queue_id, queue.workflow_queue_task_id))
        }

        window.glyco.log("data[0].workflow_task_id >>>>>>>>>>>>>>>>>>>>>>>>>>>> ", data[0])
        dispatch(getWorkflowTaskConnectorDataEpic(data[0].workflow_task_id))

        //dispatch(getWorkflowTaskConnectorDataEpic(workflow_queue_task_id))
    }
}

export const getWorkflowTaskApprovalData = (workflow_queue_id, workflow_queue_task_id) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_WORKFLOW_TASK_APPROVAL_DATA,
            payload: {
                workflow_queue_id: workflow_queue_id,
                workflow_queue_task_id: workflow_queue_task_id
            }
        })
    }
}

export const setWorkflowTaskApprovalData = (data, workflow_queue_id, workflow_queue_task_id) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_APPROVAL_DATA,
            payload: {
                workflow_queue_id: workflow_queue_id,
                workflow_queue_task_id: workflow_queue_task_id,
                data: data
            }
        })
    }
}

export const rejectWorkflowQueueTask = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REJECT_WORKFLOW_QUEUE_TASK,
            payload: {
                task: getState().workflowTaskInput.task,
                reason: getState().workflowTaskApproval.reject_reason,
                comments: 'Task reject comment'
            }
        })

        dispatch(clearWorkflowTaskApprovalInformation())
    }
}

export const openEscalation = (event) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ENCHOR_ESCALATION_TEAMS,
            payload: { anchor: event.currentTarget }
        })
        dispatch({
            type: types.OPEN_ESCALATION_TEAMS,
            payload: { isopen: true }
        })
    }
}

export const openReject = (event) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.OPEN_REJECT_STEP,
            payload: { reject: true }
        })
    }
}

// export const openEdit = (event) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.OPEN_QUOTE_EDIT_VIEW,
//             payload: {reject: true}
//         })
//     }
// }
// export const closeEdit = (event) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.CLOSE_QUOTE_EDIT_VIEW,
//             payload: {reject: true}
//         })
//     }
// }
export const closeReject = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLOSE_REJECT_STEP,
            payload: { reject: false }
        })
    }
}

export const closeEscalation = (team, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ESCALATION_TASK_TO_NEW_TEAMS,
            payload: { team: team, id: id }
        })


        dispatch({
            type: types.CLOSE_ESCALATION_TEAMS,
            payload: { isopen: false }
        })
    }
}

export const approveWorkflowQueueTask = (workflow, step, name, user) => {
    //console.log('approveWorkflowQueueTask', user)
    //console.log('approveWorkflowQueueTask step', step)
    return (dispatch, getState) => {
        const formatDate = (value) => {
            const now = moment(new Date())
            return new Date(moment(value).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
        }

        const purchaseorder = getState().components['purchaseorder']
        const component = getState().components['surrogateheader']
        const surrogateitems = getState().components['surrogateitems']
        let data = {}
        let meta = [
            {
                title: 'TBA',
                description: 'TBA',
                meta_data: ''
            }
        ]
        let bValid = true;
        let errorMessage = '';
        const errorMessages = [];
        const supportingDocuments = getState().upload.documents;

        switch (step) {
            case 'tsk_creditors_invoice_capture':
                bValid = true;

                const monthValidations = [{
                    'schema': 'integer',
                    'required': true,
                    'maxLength': null,
                    'errorText': 'Please input a valid Month'
                },
                {
                    'schema': 'greaterThanZero',
                    'required': true,
                    'maxLength': null,
                    'errorText': 'Please input a valid Month greater than zero'
                }];

                const validateField = getState().paymentCapture?.month;

                const validation = validator.validateSchema(validateField, monthValidations, null);
                bValid = validation.valid;
                if (!bValid) {
                    errorMessages.push(validation.errorText)
                }

                if (getState().paymentCapture?.date === '' || getState().paymentCapture?.date === null) {
                    bValid = false
                    errorMessages.push('Please enter a Transaction Date')
                }
                if (getState().paymentCapture?.month === '' || getState().paymentCapture?.month === null) {
                    bValid = false
                    errorMessages.push('Please enter a Transaction Month')
                }
                if (getState().paymentCapture?.invoice_number === '' || getState().paymentCapture?.invoice_number === null) {
                    bValid = false
                    errorMessages.push('Please enter a Invoice Number')
                }

                const invDocuments = getState().upload.documents;

                if (invDocuments.length > 0) {
                    invDocuments.forEach(x => {
                        if (x.filename === '') {
                            bValid = false;
                            errorMessages.push('Please upload supporting documents');
                        }
                        if (x.description === '') {
                            bValid = false;
                            errorMessages.push('Please input a document description');
                        }
                    });
                } else {
                    bValid = false;
                    errorMessages.push('Please upload supporting documents');
                }

                data = {
                    data: getState().paymentCapture,
                    documents: getState().upload.documents,
                }

                break;
            case 'tsk_fml_quote_upload_preinspection_documents':
                bValid = true;

                if (supportingDocuments.length > 0) {
                    supportingDocuments.forEach(x => {
                        if (x.filename === '') {
                            bValid = false;
                            errorMessages.push('Please upload supporting documents');
                        }
                        if (x.description === '') {
                            bValid = false;
                            errorMessages.push('Please input a document description');
                        }
                    });
                } else {
                    bValid = false;
                    errorMessages.push('Please upload supporting documents');
                }

                data = {
                    preinspection: getState().workflowUploadPID,
                    documents: supportingDocuments,
                }
                break;
            case 'tsk_fml_quote_upload_pod_documents':
                const tsk_fml_quote_upload_pod_documents_validation = validator.validateState(getState, 'workflowUploadPOD', dispatch, true);
                bValid = tsk_fml_quote_upload_pod_documents_validation.state_valid;
                if (!bValid) {
                    tsk_fml_quote_upload_pod_documents_validation.errorMessages.forEach(em => errorMessages.push(em));
                }
                data = {
                    pod: getState().workflowUploadPOD,
                    documents: supportingDocuments,
                }
                break;
            case 'tsk_fml_quote_accept_release_note':
                const documents = getState().upload.documents;
                const hasVafDocument = documents.some(x => x.description === "VAF Document");
                const hasReleaseNote = documents.some(x => x.description === "Signed Release Note");
                const result = (hasVafDocument && hasReleaseNote)
                bValid = result
                errorMessage = 'Please upload VAF and Release Note Documents'
                data = {
                    confirm: getState().workflowFMLAcceptReleaseNote,
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_purchase_order_upload_invoice':
                bValid = getState().upload.documents.length ? true : false
                errorMessage = 'Please upload document'
                data = {
                    documents: getState().upload.documents,
                    images: getState().imageUploader.images,
                    invoice: {
                        vatincl: getState().poBookingInvoiceUpload.vatincl,
                        invoicedate: getState().poBookingInvoiceUpload.invoicedate,
                        invoice_number: getState().poBookingInvoiceUpload.invoice_number,
                        invoice_amount: getState().poBookingInvoiceUpload.invoice_amount,
                    }
                }
                meta = [
                    {
                        title: 'PO Invoice Number',
                        description: 'Invoice Number',
                        meta_data: getState().poBookingInvoiceUpload.invoice_number
                    }
                ]
                break;
            case 'tsk_contract_restructure_approve':
                bValid = true;

                if (supportingDocuments.length > 0) {
                    supportingDocuments.forEach(x => {
                        if (x.filename === '') {
                            bValid = false;
                            errorMessages.push('Please upload supporting documents');
                        }
                        if (x.description === '') {
                            bValid = false;
                            errorMessages.push('Please input a document description');
                        }
                    });
                } else {
                    bValid = false;
                    errorMessages.push('Please upload supporting documents');
                }

                data = {
                    documents: getState().upload.documents
                }
                break;
            case 'tsk_str_quotation_review':
            case 'tsk_str_quotation_approve':
                data = {
                    data: getState().strQuotationReview
                }
                break;
            case 'tsk_str_quotation_vehicle_bookout':
                bValid = true;

                if (getState().strQuotationBookout.rfqdate === null || getState().strQuotationBookout.rfqdate === '' || !getState().strQuotationBookout.rfqdate) {
                    bValid = false;
                    errorMessages.push('Please input Rental Quote Accepted Date');
                }

                if (getState().strQuotationBookout.ponumber === null || getState().strQuotationBookout.ponumber === '' || !getState().strQuotationBookout.ponumber) {
                    bValid = false;
                    errorMessages.push('Please input Purchase Order Number');
                }

                if (getState().strQuotationBookout.deliverydate === null || getState().strQuotationBookout.deliverydate === '' || !getState().strQuotationBookout.deliverydate) {
                    bValid = false;
                    errorMessages.push('Please input Delivery Date');
                }

                if (getState().strQuotationBookout.rfqtime === null || getState().strQuotationBookout.rfqtime === '' || !getState().strQuotationBookout.rfqtime) {
                    bValid = false;
                    errorMessages.push('Adhoc Rental Quote Accepted Time');
                }

                if (getState().strQuotationBookout.yardlist) {
                    if (!getState().strQuotationBookout.yardlist_vehicle.find(x => x.checked === true)) {
                        bValid = false;
                        errorMessages.push('Please select a vehicle');
                    }
                }
                else {
                    if (!getState().strQuotationBookout.supplierratecard_data.find(x => x.checked === true)) {
                        bValid = false;
                        errorMessages.push('Please select a vehicle');
                    }
                }

                if (supportingDocuments.length > 0) {
                    supportingDocuments.forEach(x => {
                        if (x.filename === '') {
                            bValid = false;
                            errorMessages.push('Please upload supporting documents');
                        }
                        if (x.description === '') {
                            bValid = false;
                            errorMessages.push('Please input a document description');
                        }
                    });
                } else {
                    bValid = false;
                    errorMessages.push('Please upload supporting documents');
                }

                data = {
                    data: getState().strQuotationBookout
                }
                break;
            case 'tsk_str_quotation_activation':
                const tsk_str_quotation_activation_validation = validator.validateState(getState, 'strQuotationActivation', dispatch, true);
                bValid = tsk_str_quotation_activation_validation.state_valid;
                if (!bValid) {
                    tsk_str_quotation_activation_validation.errorMessages.forEach(em => errorMessages.push(em));
                }

                data = {
                    data: getState().strQuotationActivation
                }

                break;
            case 'tsk_accessory_master_review_and_approve':
                data = {
                    data: getState().accessoryMasterActivation
                }
                break;
            case 'tsk_vehicle_pricing_review_and_approve':
                data = {
                    data: getState().pricingMasterActivation
                }
                break;
            case 'tsk_costcentre_change_review_and_approve':
                data = {
                    data: getState().costCentreChangeApprove
                }
                break;
            case 'tsk_odometer_change_review_and_approve':
                data = {
                    data: getState().odometerChangeApprove
                }
                break;
            case 'tsk_registration_change_review_and_approve':
                data = {
                    data: getState().registrationChangeApprove
                }
                break;
            case 'tsk_purchase_order_reveiw_and_approve_quote_tech':
                bValid = getState().poBookingApproval.issupplier_correct

                errorMessage = 'Is the correct supplier selected?'
                data = {
                    comments: getState().poBookingApproval.comments,
                    issupplier_correct: getState().poBookingApproval.issupplier_correct,
                    images: getState().imageUploader.images,
                    quote: getState().quoteItems.grid,
                }
                meta = [
                    {
                        title: 'Comments',
                        description: 'Comments',
                        meta_data: getState().poBookingApproval.comments
                    },
                    {
                        title: 'Approved By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_purchase_order_reveiw_and_approve_quote_ops':
                data = {
                    comments: getState().poBookingApprovalOps.comments
                }
                break;
            case 'tsk_purchase_order_reveiw_and_approve_quote_gm':
                data = {
                    comments: getState().poBookingApprovalGM.comments,
                }
                meta = [
                    {
                        title: 'Approve PO Comments',
                        description: 'Comments',
                        meta_data: getState().poBookingApprovalGM.comments
                    },
                    {
                        title: 'Approved By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_purchase_order_reveiw_and_approve_quote_ceo':
                data = {
                    comments: getState().poBookingApprovalCEO.comments,
                }
                meta = [
                    {
                        title: 'Approve PO Comments',
                        description: 'Comments',
                        meta_data: getState().poBookingApprovalCEO.comments
                    },
                    {
                        title: 'Approved By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_rebill_upload_invoice':
                bValid = getState().upload.documents.length ? true : false
                errorMessage = 'Please upload document'
                data = {
                    documents: getState().upload.documents,
                    images: getState().imageUploader.images,
                    comments: getState().poBookingRebillInvoiceUpload.comments,
                    invoice: {
                        vatincl: getState().poBookingRebillInvoiceUpload.vatincl,
                        invoicedate: getState().poBookingRebillInvoiceUpload.invoicedate,
                        invoice_number: getState().poBookingRebillInvoiceUpload.invoice_number,
                        invoice_amount: getState().poBookingRebillInvoiceUpload.invoice_amount,
                    }
                }
                break;
            case 'tsk_rebill_review_and_approve_tech':
                bValid = getState().poRebillApprovalByTech.issupplier_correct
                //console.log('tsk_rebill_review_and_approve_tech')
                //console.log(getState().poRebillApprovalByTech.issupplier_correct)
                //console.log(bValid)
                errorMessage = 'Is the correct supplier selected?'
                data = {
                    tech: {
                        comments: getState().poRebillApprovalByTech.comments,
                        issupplier_correct: getState().poRebillApprovalByTech.issupplier_correct,
                        images: getState().imageUploader.images,
                        date: new Date(),
                    }
                }
                break;
            case 'tsk_rebill_review_and_approve_ops':
                data = {
                    comments: getState().poRebillApprovalByOps.comments,
                }
                break;
            case 'tsk_purchase_order_close_booking':
                bValid = true;

                if (supportingDocuments.length > 0) {
                    supportingDocuments.forEach(x => {
                        if (x.filename === '') {
                            bValid = false;
                            errorMessages.push('Please upload supporting documents');
                        }
                        if (x.description === '') {
                            bValid = false;
                            errorMessages.push('Please input a document description');
                        }
                    });
                } else {
                    bValid = false;
                    errorMessages.push('Please upload supporting documents');
                }

                if (getState().poCloseBooking.completeddate === '' || getState().poCloseBooking.completeddate === null || !getState().poCloseBooking.completeddate) {
                    bValid = false;
                    errorMessages.push('Please enter Completed Date');
                }

                if (getState().poCloseBooking.supplier_invoiceno === '' || getState().poCloseBooking.supplier_invoiceno === null || !getState().poCloseBooking.supplier_invoiceno) {
                    bValid = false;
                    errorMessages.push('Please enter Supplier Invoice Number ');
                }

                data = {
                    close: {
                        supplier_invoiceno: getState().poCloseBooking.supplier_invoiceno,
                        isinvoice_uploaded: getState().poCloseBooking.isinvoice_uploaded,
                        comments: getState().poCloseBooking.comments,
                        completeddate: getState().poCloseBooking.completeddate,
                        documents: getState().upload.documents
                    }
                }
                break;

            case 'tsk_enterest_run':
                bValid = true;

                if (supportingDocuments.length > 0) {
                    supportingDocuments.forEach(x => {
                        if (x.filename === '') {
                            bValid = false;
                            errorMessages.push('Please upload supporting documents');
                        }
                        if (x.description === '') {
                            bValid = false;
                            errorMessages.push('Please input a document description');
                        }
                    });
                } else {
                    bValid = false;
                    errorMessages.push('Please upload supporting documents');
                }

                if (!getState().enterestReview.confirm) {
                    bValid = false;
                    errorMessages.push('Please confirm that all checks has been done');
                }

                // let uploaded = getState().upload.documents.length ? true : false
                // let checked = getState().enterestReview.confirm
                // if(!uploaded) {
                //     bValid = false
                //     errorMessage = 'Please upload the enterest file that you reviewed'
                // }
                // if(!checked) {
                //     bValid = false
                //     errorMessage = 'Please confirm that all checkes has been done'
                // }
                data = {
                    comments: getState().enterestReview.comments,
                    documents: getState().upload.documents
                }
                break;
            case 'tsk_mm_approve_quote':
                data = {
                    contacts: [] //getState().customAdhocComponentFieldInput.customercontact.data
                }
                break;
            case 'tsk_mm_activate_contract':
                bValid = true;
                const tsk_mm_activate_contract_validation = validator.validateState(getState, 'activateMMContract', dispatch, true);
                bValid = tsk_mm_activate_contract_validation.state_valid;
                if (!bValid) {
                    tsk_mm_activate_contract_validation.errorMessages.forEach(em => errorMessages.push(em));
                }

                data = {
                    documents: getState().upload.documents,
                    confirm: getState().activateMMContract
                }

                break;
            case 'tsk_creditors_payment_create_review_and_approve':
                data = {
                    confirm: getState().creditorsPaymentReview
                }
                break;
            case 'tsk_creditors_payment_allocate':
                bValid = true;

                if (getState().creditorsPaymentAllocate.allocationreason === '' || getState().creditorsPaymentAllocate.allocationreason === null || !getState().creditorsPaymentAllocate.allocationreason) {
                    bValid = false;
                    errorMessages.push('Please enter Allocation Reason');
                }

                if (getState().creditorsPaymentAllocate.allocationreference === '' || getState().creditorsPaymentAllocate.allocationreference === null || !getState().creditorsPaymentAllocate.allocationreference) {
                    bValid = false;
                    errorMessages.push('Please enter Allocation Reference');
                }

                data = {
                    confirm: getState().creditorsPaymentAllocate
                }
                break;
            case 'tsk_credit_note_review_and_approve':
                data = {
                    confirm: getState().creditNoteReview
                }
                break;
            case 'tsk_interest_rate_review_and_approve':
                data = {
                    confirm: getState().interestRateReview
                }
                break;
            case 'tsk_rebill_review_and_approve_gm':
                data = {
                    comments: getState().poRebillApprovalByGM.comments,
                    date: new Date(),
                }
                break;
            case 'tsk_rebill_review_and_approve_ceo':
                data = {
                    ceo: {
                        comments: getState().poRebillApprovalByCEO.comments,
                        date: new Date(),
                    }
                }
                break;
            case 'tsk_purchase_order_send_authorisation':
                data = {
                    data: {
                        images: getState().imageUploader.images,
                        comments: getState().poBookingSendAuth.comments //poBookingSendRebillAuth
                        //adhoc: getState().customAdhocComponentFieldInput.suppliercontact.data
                    }
                }
                break;
            case 'tsk_rebill_send_customer_rebill':
                data = {
                    data: {
                        images: getState().imageUploader.images,
                        ccme: getState().poBookingSendRebill.ccme,
                    }
                }
                break;
            case 'tsk_defleet_send_customer_rebill':
                data = {
                    data: {
                        adhoc: getState().customAdhocComponentFieldInput.customercontact.data,
                        images: getState().imageUploader.images,
                        ccme: getState().defleetSendRebill.ccme
                    }
                }
                break;
            case 'tsk_rebill_send_authorisation':
                data = {
                    data: {
                        images: getState().imageUploader.images,
                        ccme: getState().poBookingSendRebillAuth.ccme,
                        comments: getState().poBookingSendRebill.comments,
                    }
                }
                break;
            case 'tsk_defleet_send_authorisation':
                data = {
                    data: {
                        adhoc: getState().customAdhocComponentFieldInput.suppliercontact.data,
                        images: getState().imageUploader.images,
                        ccme: getState().poBookingSendRebillAuth.ccme,
                    }
                }
                break;
            case 'tsk_workflow_cancelled_task_approval':
                data = {
                    cancel_data: getState().cancelledTasksApproval,
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_workflow_cancelled_task_approve_ops':
                data = {
                    cancel_data: getState().cancelledTasksApprovalOPS
                }
                break;
            case 'tsk_workflow_cancelled_task_approve_gm':
                data = {
                    cancel_data: getState().cancelledTasksApprovalGM
                }
                break;
            case 'tsk_rebill_close_booking':
                bValid = true;

                if (getState().poCloseRebillBooking.supplier_invoiceno === null || getState().poCloseRebillBooking.supplier_invoiceno === '') {
                    bValid = false;
                    errorMessages.push('Please enter Supplier Invoice Number')
                }

                if (getState().poCloseRebillBooking.completeddate === null || getState().poCloseRebillBooking.completeddate === '') {
                    bValid = false;
                    errorMessages.push('Please select Completed Date')
                }

                if (supportingDocuments.length > 0) {
                    supportingDocuments.forEach(x => {
                        if (x.filename === '') {
                            bValid = false;
                            errorMessages.push('Please upload supporting documents');
                        }
                        if (x.description === '') {
                            bValid = false;
                            errorMessages.push('Please input a document description');
                        }
                    });
                } else {
                    bValid = false;
                    errorMessages.push('Please upload supporting documents');
                }

                data = {
                    data: {
                        isinvoice_uploaded: getState().poCloseRebillBooking.isinvoice_uploaded,
                        supplier_invoiceno: getState().poCloseRebillBooking.supplier_invoiceno,
                        documents: getState().upload.documents,
                        comments: getState().poCloseRebillBooking.comments,
                        completeddate: getState().poCloseRebillBooking.completeddate,
                        //invoice_number: getState().poCloseRebillBooking.invoice_number,
                        //invoice_amount: getState().poCloseRebillBooking.invoice_amount
                    }
                }
                break;
            case 'tsk_defleet_close_booking':
                bValid = true
                let over = getState().defleetCloseBooking.days_over
                let charge = getState().defleetCloseBooking.invoice_supplier
                let sup_docs = getState().defleetCloseBooking.penaltydocuments_uploaded

                // if (getState().defleetCloseBooking.days_in_for_repairs === 'null' || getState().defleetCloseBooking.days_in_for_repairs === '' || !getState().defleetCloseBooking.days_in_for_repairs){
                //     bValid = false;
                //     errorMessage = 'Please input days in for repairs'
                // }

                // if (getState().defleetCloseBooking.days_over === 'null' || getState().defleetCloseBooking.days_over === '' || !getState().defleetCloseBooking.days_over){
                //     bValid = false;
                //     errorMessage = 'Please input date in for repairs'
                // }

                // if (getState().defleetCloseBooking.defleetrequestdate === 'null' || getState().defleetCloseBooking.defleetrequestdate === '' || !getState().defleetCloseBooking.defleetrequestdate) {
                //     bValid = false;
                //     errorMessages.push('Please input Completed Date')
                // }

                if (over > 0) {
                    const supplierratevalidations = [{
                        'schema': 'decimal',
                        'required': true,
                        'maxLength': null,
                        'errorText': 'Please input a valid Supplier Rate'
                    },
                    {
                        'schema': 'greaterThanZero',
                        'required': true,
                        'maxLength': null,
                        'errorText': 'Please input a valid Supplier Rate greater than zero'
                    }];

                    const validaterateField = getState().defleetCloseBooking?.supplier_rate;

                    const validationrate = validator.validateSchema(validaterateField, supplierratevalidations, null);
                    bValid = validationrate.valid;
                    if (!bValid) {
                        errorMessages.push(validationrate.errorText)
                    }
                }

                if ((!charge || !sup_docs) && over > 0) {
                    bValid = getState().defleetCloseBooking.supplier_inv_comments ? true : false
                    errorMessages.push("Please give reason why we shouldn't charge supplier penalty and ensure that you upload supporting documents ")
                }

                const odovalidations = [{
                    'schema': 'integer',
                    'required': true,
                    'maxLength': null,
                    'errorText': 'Please input a valid Odometer Reading'
                },
                {
                    'schema': 'greaterThanZero',
                    'required': true,
                    'maxLength': null,
                    'errorText': 'Please input a valid Odometer Reading greater than zero'
                }];

                const validateodoField = getState().defleetCloseBooking?.odometer;

                const validationodo = validator.validateSchema(validateodoField, odovalidations, null);
                bValid = validationodo.valid;
                if (!bValid) {
                    errorMessages.push(validationodo.errorText)
                }

                if (getState().defleetCloseBooking.checksheet_completeddate === 'null' || getState().defleetCloseBooking.checksheet_completeddate === '' || !getState().defleetCloseBooking.checksheet_completeddate) {
                    bValid = false;
                    errorMessages.push('Please input Completed Date')
                }

                if (getState().defleetCloseBooking.checksheet_completedby === 'null' || getState().defleetCloseBooking.checksheet_completedby === '' || !getState().defleetCloseBooking.checksheet_completedby) {
                    bValid = false;
                    errorMessages.push('Please input Completed By')
                }

                if (getState().defleetCloseBooking.supplier_invoicenumber === 'null' || getState().defleetCloseBooking.supplier_invoicenumber === '' || !getState().defleetCloseBooking.supplier_invoicenumber) {
                    bValid = false;
                    errorMessages.push('Please input Supplier Invoice Number')
                }

                if (!getState().defleetCloseBooking.isinvoice_uploaded) {
                    bValid = false;
                    errorMessages.push('Please check that you have uploaded the invoice')
                }

                if (supportingDocuments.length > 0) {
                    supportingDocuments.forEach(x => {
                        if (x.filename === '') {
                            bValid = false;
                            errorMessages.push('Please upload supporting documents');
                        }
                        if (x.description === '') {
                            bValid = false;
                            errorMessages.push('Please input a document description');
                        }
                    });
                } else {
                    bValid = false;
                    errorMessages.push('Please upload supporting documents');
                }

                if (getState().defleetCloseBooking.completeddate === 'null' || getState().defleetCloseBooking.completeddate === '' || !getState().defleetCloseBooking.completeddate) {
                    bValid = false;
                    errorMessages.push('Please input Repair Completed Date')
                }

                if (getState().defleetCloseBooking.date_in_for_repairs === 'null' || getState().defleetCloseBooking.date_in_for_repairs === '' || !getState().defleetCloseBooking.date_in_for_repairs) {
                    bValid = false;
                    errorMessages.push('Please input Date in for repairs')
                }

                data = {
                    data: {
                        isinvoice_uploaded: getState().defleetCloseBooking.isinvoice_uploaded,
                        documents: supportingDocuments,
                        comments: getState().defleetCloseBooking.comments,
                        completeddate: getState().defleetCloseBooking.completeddate,
                        checksheet_completedby: getState().defleetCloseBooking.checksheet_completedby,
                        checksheet_completeddate: getState().defleetCloseBooking.checksheet_completeddate,
                        odometer: getState().defleetCloseBooking.odometer,
                        date_in_for_repairs: getState().defleetCloseBooking.date_in_for_repairs,
                        invoice_supplier: getState().defleetCloseBooking.invoice_supplier,
                        supplier_rate: getState().defleetCloseBooking.supplier_rate,
                        supplier_inv_comments: getState().defleetCloseBooking.supplier_inv_comments,
                        supplier_inv_amount: getState().defleetCloseBooking.supplier_inv_amount,
                        days_in_for_repairs: getState().defleetCloseBooking.days_in_for_repairs,
                        days_over: getState().defleetCloseBooking.days_over,
                        penaltydocuments_uploaded: getState().defleetCloseBooking.penaltydocuments_uploaded,
                        supplier_invoicenumber: getState().defleetCloseBooking.supplier_invoicenumber,
                    }
                }

                break;
            case 'tsk_defleet_contract_termination':

                const tsk_defleet_contract_termination_validation = validator.validateState(getState, 'defleetContractTermination', dispatch, true);
                bValid = tsk_defleet_contract_termination_validation.state_valid;
                if (!bValid) {
                    tsk_defleet_contract_termination_validation.errorMessages.forEach(em => errorMessages.push(em));
                }
                // bValid = true;

                // if (getState().defleetContractTermination.terminationdate === null || getState().defleetContractTermination.terminationdate === '' || getState().defleetContractTermination.terminationdate === false) {
                //     bValid = false;
                //     errorMessage = 'Please input Termination Date'
                // }

                // if (getState().defleetContractTermination.terminationodo === null || getState().defleetContractTermination.terminationodo === '' || getState().defleetContractTermination.terminationodo === false) {
                //     bValid = false;
                //     errorMessage = 'Please input Termination Odo'
                // }

                // if (!getState().defleetContractTermination.odochecked) {
                //     bValid = false;
                //     errorMessage = 'Please ensure Odo is checked'
                // }

                data = {
                    data: {
                        comments: getState().defleetContractTermination.comments,
                        terminationdate: getState().defleetContractTermination.terminationdate,
                        terminationodo: getState().defleetContractTermination.terminationodo
                    }
                }
                break;
            case 'tsk_defleet_termination_request':
                bValid = getState().upload.documents.length ? true : false
                errorMessage = 'Please upload document and selected completed date'
                data = {
                    data: {
                        documents: getState().upload.documents,
                        comments: getState().defleetTerminationRequest.comments,
                        terminationdate: getState().defleetTerminationRequest.terminationdate,
                        terminationodo: getState().defleetTerminationRequest.terminationodo
                    }
                }
                break;
            case 'tsk_defleet_repair_checksheet':
                data = {
                    data: {
                        comments: getState().defleetRepairChecksheet.comments,
                        completeddate: getState().defleetRepairChecksheet.completeddate,
                        completedby: getState().defleetRepairChecksheet.completedby,
                        odometer: getState().defleetRepairChecksheet.odometer,
                        documents: getState().upload.documents,
                    }
                }
                break;
            case 'tsk_defleet_disposal':
                data = {
                    data: {
                        documents: getState().upload.documents,
                        images: getState().imageUploader.images,
                        funder: getState().defleetDisposal.funder,
                        contractenddate: getState().defleetDisposal.contractenddate,
                        funder_rv: getState().defleetDisposal.funder_rv,
                        funder_month_togo: getState().defleetDisposal.funder_month_togo,
                        trade_value: getState().defleetDisposal.trade_value,
                        retail_value: getState().defleetDisposal.retail_value,
                        market_value: getState().defleetDisposal.market_value,
                        comments: getState().defleetDisposal.comments,
                        offer_increase: getState().defleetDisposal.offer_increase,
                        finance_comments: getState().defleetDisposal.finance_comments,
                        ceo_comments_profit_loss: getState().defleetDisposal.ceo_comments_profit_loss,
                        funder_rental: getState().defleetDisposal.funder_rental,
                        profit_loss_excl_eq: getState().defleetDisposal.profit_loss_excl_eq,
                        profit_loss: getState().defleetDisposal.profit_loss,
                        commission: getState().defleetDisposal.commission,
                        inv_value_incl: getState().defleetDisposal.inv_value_incl,
                        nbv_capital_incl: getState().defleetDisposal.nbv_capital_incl,
                        ceo_comments_settle: getState().defleetDisposal.ceo_comments_settle,
                        settle_excl_vat_less: getState().defleetDisposal.settle_excl_vat_less,
                        settle_incl: getState().defleetDisposal.settle_incl,
                        settle_excl: getState().defleetDisposal.settle_excl,
                        yardlist_comments: getState().defleetDisposal.yardlist_comments,
                        offers: getState().defleetDisposal.offers,
                        maint_fund_bal: getState().defleetDisposal.maint_fund_bal,
                        cap_eq_fund_bal: getState().defleetDisposal.cap_eq_fund_bal,
                        diff_best_offer_settle: getState().defleetDisposal.diff_best_offer_settle,
                        diff_best_offer_settle_cap_eq: getState().defleetDisposal.diff_best_offer_settle_cap_eq,
                        bestoffer: getState().defleetDisposal.bestoffer,
                        vehicle_cost: getState().defleetDisposal.vehicle_cost,
                        vehicle_acc_depr: getState().defleetDisposal.vehicle_acc_depr
                    }
                }
                break;
            case 'tsk_defleet_disposal_approve':
                data = {
                    data: {
                        documents: getState().upload.documents,
                        images: getState().imageUploader.images,
                        funder: getState().defleetDisposalApproval.funder,
                        contractenddate: getState().defleetDisposalApproval.contractenddate,
                        funder_rv: getState().defleetDisposalApproval.funder_rv,
                        funder_month_togo: getState().defleetDisposalApproval.funder_month_togo,
                        trade_value: getState().defleetDisposalApproval.trade_value,
                        retail_value: getState().defleetDisposalApproval.retail_value,
                        market_value: getState().defleetDisposalApproval.market_value,
                        comments: getState().defleetDisposalApproval.comments,
                        offer_increase: getState().defleetDisposalApproval.offer_increase,
                        finance_comments: getState().defleetDisposalApproval.finance_comments,
                        ceo_comments_profit_loss: getState().defleetDisposalApproval.ceo_comments_profit_loss,
                        funder_rental: getState().defleetDisposalApproval.funder_rental,
                        profit_loss_excl_eq: getState().defleetDisposalApproval.profit_loss_excl_eq,
                        profit_loss: getState().defleetDisposalApproval.profit_loss,
                        commission: getState().defleetDisposalApproval.commission,
                        inv_value_incl: getState().defleetDisposalApproval.inv_value_incl,
                        nbv_capital_incl: getState().defleetDisposalApproval.nbv_capital_incl,
                        ceo_comments_settle: getState().defleetDisposalApproval.ceo_comments_settle,
                        settle_excl_vat_less: getState().defleetDisposalApproval.settle_excl_vat_less,
                        settle_incl: getState().defleetDisposalApproval.settle_incl,
                        settle_excl: getState().defleetDisposalApproval.settle_excl,
                        yardlist_comments: getState().defleetDisposalApproval.yardlist_comments,
                        offers: getState().defleetDisposalApproval.offers,
                        maint_fund_bal: getState().defleetDisposalApproval.maint_fund_bal,
                        cap_eq_fund_bal: getState().defleetDisposalApproval.cap_eq_fund_bal,
                        diff_best_offer_settle: getState().defleetDisposalApproval.diff_best_offer_settle,
                        diff_best_offer_settle_cap_eq: getState().defleetDisposalApproval.diff_best_offer_settle_cap_eq,
                        vehicle_cost: getState().defleetDisposalApproval.vehicle_cost,
                        vehicle_acc_depr: getState().defleetDisposalApproval.vehicle_acc_depr
                    }
                }
                break;
            case 'tsk_defleet_disposal_approve_ceo':
                data = {
                    data: {
                        documents: getState().upload.documents,
                        images: getState().imageUploader.images,
                        funder: getState().defleetDisposalApprovalCEO.funder,
                        contractenddate: getState().defleetDisposalApprovalCEO.contractenddate,
                        funder_rv: getState().defleetDisposalApprovalCEO.funder_rv,
                        funder_month_togo: getState().defleetDisposalApprovalCEO.funder_month_togo,
                        trade_value: getState().defleetDisposalApprovalCEO.trade_value,
                        retail_value: getState().defleetDisposalApprovalCEO.retail_value,
                        market_value: getState().defleetDisposalApprovalCEO.market_value,
                        comments: getState().defleetDisposalApprovalCEO.comments,
                        offer_increase: getState().defleetDisposalApprovalCEO.offer_increase,
                        finance_comments: getState().defleetDisposalApprovalCEO.finance_comments,
                        ceo_comments_profit_loss: getState().defleetDisposalApprovalCEO.ceo_comments_profit_loss,
                        funder_rental: getState().defleetDisposalApprovalCEO.funder_rental,
                        profit_loss_excl_eq: getState().defleetDisposalApprovalCEO.profit_loss_excl_eq,
                        profit_loss: getState().defleetDisposalApprovalCEO.profit_loss,
                        commission: getState().defleetDisposalApprovalCEO.commission,
                        inv_value_incl: getState().defleetDisposalApprovalCEO.inv_value_incl,
                        nbv_capital_incl: getState().defleetDisposalApprovalCEO.nbv_capital_incl,
                        ceo_comments_settle: getState().defleetDisposalApprovalCEO.ceo_comments_settle,
                        settle_excl_vat_less: getState().defleetDisposalApprovalCEO.settle_excl_vat_less,
                        settle_incl: getState().defleetDisposalApprovalCEO.settle_incl,
                        settle_excl: getState().defleetDisposalApprovalCEO.settle_excl,
                        yardlist_comments: getState().defleetDisposalApprovalCEO.yardlist_comments,
                        offers: getState().defleetDisposalApprovalCEO.offers,
                        maint_fund_bal: getState().defleetDisposalApprovalCEO.maint_fund_bal,
                        cap_eq_fund_bal: getState().defleetDisposalApprovalCEO.cap_eq_fund_bal,
                        diff_best_offer_settle: getState().defleetDisposalApprovalCEO.diff_best_offer_settle,
                        diff_best_offer_settle_cap_eq: getState().defleetDisposalApprovalCEO.diff_best_offer_settle_cap_eq
                    }
                }
                break;
            case 'tsk_defleet_termination_request_approve_tech':
                data = {
                    data: {
                        documents: getState().upload.documents,
                        comments: getState().defleetTerminationRequestApproval.comments
                    }
                }
                break;
            case 'tsk_defleet_termination_request_approve_gm':
                data = {
                    data: {
                        documents: getState().upload.documents,
                        comments: getState().defleetTerminationRequestApproval.comments
                    }
                }
                break;
            case 'tsk_defleet_termination_request_approve_ceo':
                data = {
                    data: {
                        documents: getState().upload.documents,
                        comments: getState().defleetTerminationRequestApproval.comments
                    }
                }
                break;
            case 'tsk_payment_approve_invoice':
                data = {
                    data: getState().paymentApprovalAdmin
                }
                break;
            case 'tsk_payment_approval_finance':
                data = {
                    data: getState().paymentApprovalFinance
                }
                break;
            case 'tsk_payment_approval_gm':
                data = {
                    data: getState().paymentApprovalGM
                }
                break;
            case 'tsk_payment_approval_ops':
                data = {
                    data: getState().paymentApprovalOPS
                }
                break;
            case 'tsk_payment_approval_tech':
                data = {
                    data: getState().paymentApprovalTech
                }
                break;
            case 'tsk_payment_release':
                data = {
                    data: getState().paymentApprovalRelease
                }
                break;
            case 'tsk_payment_authorisation':
                data = {
                    data: getState().paymentApprovalAuthorisation
                }
                break;
            case 'tsk_rebill_tech_payment_approval':
            case 'tsk_rebill_gm_payment_approval':
            case 'tsk_rebill_coo_payment_approval':
            case 'tsk_defleet_tech_payment_approval':
            case 'tsk_defleet_gm_payment_approval':
            case 'tsk_defleet_coo_payment_approval':
            case 'tsk_purchase_order_tech_payment_approval':
            case 'tsk_purchase_order_gm_payment_approval':
            case 'tsk_purchase_order_coo_payment_approval':
                data = {
                    data: getState().doaGenericApproval
                }
                break;
            case 'tsk_billing_run_refresh':
                data = {
                    data: getState().billingrefresh,
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_billing_run_preview':
                data = {
                    data: getState().billingpreview,
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_billing_run_prep':
                data = {
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_billing_run_invoices':
                data = {
                    data: getState().billinginterest,
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_billing_interest':
                data = {
                    data: getState().billingsendinvoices,
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_billing_run_odofile':
                data = {
                    data: getState().billingodofile,
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_billing_run_oocpfile':
                data = {
                    data: getState().billingoocpfile,
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_billing_weeklycis':
                data = {
                    data: getState().billingweeklycis,
                    documents: getState().upload.documents,
                }
                break;
            case 'tsk_creditors_invoice_first_approval':
            case 'tsk_creditors_invoice_second_approval':
            case 'tsk_creditors_invoice_third_approval':
                data = {
                    data: getState().paymentDOAApproval
                }
                break;
            case 'tsk_defleet_upload_checksheet':
                const tsk_defleet_upload_checksheet_validation = validator.validateState(getState, 'defleetUploadChecksheet', dispatch, true);
                bValid = tsk_defleet_upload_checksheet_validation.state_valid;
                if (!bValid) {
                    tsk_defleet_upload_checksheet_validation.errorMessages.forEach(em => errorMessages.push(em));
                }
                data = {
                    data: {
                        documents: getState().upload.documents,
                        images: getState().imageUploader.images,
                        completeddate: getState().defleetUploadChecksheet.checksheet_completedat.value,
                        completedby: getState().defleetUploadChecksheet.checksheet_completedby.value,
                        location: getState().defleetUploadChecksheet.location.value,
                        has_damages: getState().defleetUploadChecksheet.has_damages.value,
                        has_sparekey: getState().defleetUploadChecksheet.has_sparekey.value,
                        skip_to_close: getState().defleetUploadChecksheet.has_damages.value ? false : true,
                        comments: getState().defleetUploadChecksheet.comments.value,
                        fuelamount: getState().defleetUploadChecksheet.fuelamount.value,
                        odoreading: getState().defleetUploadChecksheet.odoreading.value,
                        defleetdate: getState().defleetUploadChecksheet.defleetdate.value,
                        correctdocument_type: getState().defleetUploadChecksheet.correctdocument_type.value
                    },
                }
                break;

                // bValid = true
                //     if (bValid) {
                //         bValid = getState().defleetUploadChecksheet.checksheet_completedby.value ? true : false
                //         errorMessage = getState().defleetUploadChecksheet.checksheet_completedby.errorText
                //     }
                //     if (bValid) {
                //         bValid = getState().defleetUploadChecksheet.checksheet_completedat.value ? true : false
                //         errorMessage = getState().defleetUploadChecksheet.checksheet_completedat.errorText
                //     }
                //     if (bValid) {
                //         bValid = getState().defleetUploadChecksheet.location.value ? true : false
                //         errorMessage = getState().defleetUploadChecksheet.location.errorText
                //     }
                //     if (bValid) {
                //         bValid = getState().defleetUploadChecksheet.odoreading.value ? true : false
                //         errorMessage = getState().defleetUploadChecksheet.odoreading.errorText
                //     }
                //     if (bValid) {
                //         bValid = getState().defleetUploadChecksheet.defleetdate.value ? true : false
                //         errorMessage = getState().defleetUploadChecksheet.defleetdate.errorText
                //     }
                //     if (bValid) {
                //         bValid = getState().defleetUploadChecksheet.defleet_date_match.value ? true : false
                //         errorMessage = getState().defleetUploadChecksheet.defleet_date_match.errorText
                //     }
                //     if (bValid) {
                //         bValid = getState().upload.documents.length ? true : false
                //         errorMessage = 'Please upload document and complete relevant details'
                //     }
                //     if (bValid) {
                //         bValid = getState().defleetUploadChecksheet.correctdocument_type.value ? true : false
                //         errorMessage = getState().defleetUploadChecksheet.correctdocument_type.errorText
                //     }
                //     if (bValid) {
                //         bValid = getState().upload.documents.length ? !getState().upload.documents.filter(x => x.description == "" || x.description == null).length ? true : false : false
                //         errorMessage = 'Please select document type(s)'
                //     }

                /* meta = [
                    {
                        header: true,
                        title: 'Vehicle',
                        description: 'Vehicle',
                        meta_data: wf_data.step1.data.registration
                    },
                    {
                        title: 'De-fleet Number',
                        description: 'De-fleet Number',
                        meta_data: wf_data.step1.data.defleetNumber
                    },
                ] */
                break;

            case 'tsk_rebill_accept_customer_rebill':
                bValid = false;
                const tsk_rebill_accept_customer_rebill_validation = validator.validateState(getState, 'poBookingAcceptRebill', dispatch, true);
                bValid = tsk_rebill_accept_customer_rebill_validation.state_valid;
                if (!bValid) {
                    tsk_rebill_accept_customer_rebill_validation.errorMessages.forEach(em => errorMessages.push(em));
                }

                data = {
                    rebill: {
                        date: getState().poBookingAcceptRebill.po_date,
                        number: getState().poBookingAcceptRebill.po_number,
                        amount: getState().poBookingAcceptRebill.po_amount,
                        comments: getState().poBookingAcceptRebill.comments,
                        bookingdate: getState().poBookingAcceptRebill.bookingdate,
                    },
                    images: getState().imageUploader.images,
                    documents: getState().upload.documents,
                }
                meta = [
                    {
                        title: 'Accept Rebill Comments',
                        description: 'Comments',
                        meta_data: getState().imageUploader.comments
                    },
                    {
                        title: 'Rebill Accept By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_defleet_accept_customer_rebill':
                const tsk_defleet_accept_customer_rebill_validation = validator.validateState(getState, 'defleetAcceptRebill', dispatch, true);
                bValid = tsk_defleet_accept_customer_rebill_validation.state_valid;
                if (!bValid) {
                    tsk_defleet_accept_customer_rebill_validation.errorMessages.forEach(em => errorMessages.push(em));
                }
                data = {
                    data: {
                        date: getState().defleetAcceptRebill.po_date,
                        number: getState().defleetAcceptRebill.po_number,
                        amount: getState().defleetAcceptRebill.po_amount,
                        comments: getState().defleetAcceptRebill.comments,
                        bookingdate: getState().defleetAcceptRebill.bookingdate,
                        customer_po: getState().defleetAcceptRebill.customer_po,
                        rebill_amount: getState().defleetAcceptRebill.rebill_amount,
                        dispute_rebill: getState().defleetAcceptRebill.dispute_rebill,
                        rebill_dispute_comments: getState().defleetAcceptRebill.rebill_dispute_comments,
                        rebill_dispute_date: getState().defleetAcceptRebill.rebill_dispute_date,
                        issupplier_correct: getState().defleetAcceptRebill.issupplier_correct,
                        requested_termination_date: getState().defleetAcceptRebill.requested_termination_date,
                        requested_termination_odo: getState().defleetAcceptRebill.requested_termination_odo,
                        requested_termination_comments: getState().defleetAcceptRebill.requested_termination_comments
                    },
                    images: getState().imageUploader.images,
                    documents: getState().upload.documents,
                }
                meta = [
                    {
                        title: 'Accept Rebill Comments',
                        description: 'Comments',
                        meta_data: getState().imageUploader.comments
                    },
                    {
                        title: 'Rebill Accept By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_defleet_rebill_dispute':
                bValid = getState().defleetRebillDispute.rebill_dispute_comments && getState().defleetRebillDispute.dispute_confirmation ? true : false
                errorMessage = 'Please enter dispute comments & confirm the submission of information'

                //bValid = getState().upload.documents.length ? true : false
                //errorMessage = 'Please upload document'

                data = {
                    data: {
                        date: getState().defleetRebillDispute.po_date,
                        number: getState().defleetRebillDispute.po_number,
                        amount: getState().defleetRebillDispute.po_amount,
                        comments: getState().defleetRebillDispute.comments,
                        bookingdate: getState().defleetRebillDispute.bookingdate,
                        customer_po: getState().defleetRebillDispute.customer_po,
                        dispute_rebill: getState().defleetRebillDispute.dispute_rebill,
                        rebill_dispute_comments: getState().defleetRebillDispute.rebill_dispute_comments,
                        rebill_dispute_date: getState().defleetRebillDispute.rebill_dispute_date,
                        dispute_resolved: getState().defleetRebillDispute.dispute_resolved,
                        dispute_confirmation: getState().defleetRebillDispute.dispute_confirmation
                    },
                    images: getState().imageUploader.images,
                    documents: getState().upload.documents,
                }
                meta = [
                    {
                        title: 'Accept Rebill Comments',
                        description: 'Comments',
                        meta_data: getState().imageUploader.comments
                    },
                    {
                        title: 'Rebill Accept By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_defleet_review_and_approve_tech':
                bValid = true;
                if (!getState().defleetQuoteApproval.issupplier_correct.value) {
                    errorMessage = getState().defleetQuoteApproval.issupplier_correct.errorText
                    bValid = false;
                }

                data = {
                    data: {
                        comments: getState().defleetQuoteApproval.comments,
                        send_authorisation: getState().defleetQuoteApproval.send_authorisation.value,
                        issupplier_correct: getState().defleetQuoteApproval.issupplier_correct.value,
                    }
                }
                meta = [
                    {
                        title: 'Approve Rebill Comments',
                        description: 'Comments',
                        meta_data: getState().defleetQuoteApproval.comments
                    },
                    {
                        title: 'Approved By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_defleet_review_and_approve_ops':
                data = {
                    data: {
                        comments: getState().defleetQuoteApprovalOPS.comments,
                        send_authorisation: getState().defleetQuoteApprovalOPS.send_authorisation.value,
                        issupplier_correct: getState().defleetQuoteApprovalOPS.issupplier_correct.value,
                    }
                }
                meta = [
                    {
                        title: 'Approve Rebill Comments',
                        description: 'Comments',
                        meta_data: getState().defleetQuoteApprovalOPS.comments
                    },
                    {
                        title: 'Approved By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_defleet_review_and_approve_gm':
                data = {
                    data: {
                        comments: getState().defleetQuoteApprovalGM.comments,
                        send_authorisation: getState().defleetQuoteApprovalGM.send_authorisation.value,
                    }
                }
                meta = [
                    {
                        title: 'Approve Rebill Comments',
                        description: 'Comments',
                        meta_data: getState().defleetQuoteApprovalGM.comments
                    },
                    {
                        title: 'Approved By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_defleet_review_and_approve_ceo':
                data = {
                    data: {
                        comments: getState().defleetQuoteApprovalCEO.comments,
                        send_authorisation: getState().defleetQuoteApprovalCEO.send_authorisation.value,
                    }
                }
                meta = [
                    {
                        title: 'Approve Rebill Comments',
                        description: 'Comments',
                        meta_data: getState().defleetQuoteApprovalCEO.comments
                    },
                    {
                        title: 'Approved By',
                        description: 'Approved By',
                        meta_data: getState().user.firstname
                    }
                ]
                break;
            case 'tsk_fml_quote_approval_by_gm':
                data = {
                    data: getState().workflowPOApprovalByGM
                }
                break;
            case 'tsk_fml_quote_approval_by_coo':
                data = {
                    data: getState().workflowPOApprovalByCOO
                }
                break;
            case 'tsk_fml_quote_approval_by_ceo':
                data = {
                    data: getState().workflowPOApprovalByCEO
                }
                break;
            case 'tsk_str_quote_contract_activation':
                data = {
                    documents: getState().upload.documents,
                    recieveddate: formatDate(getState().strContractActivation.recieveddate),
                    activationdate: formatDate(getState().strContractActivation.activationdate),
                    mmcode: getState().strContractActivation.mmcode,
                    mmcode_id: getState().strContractActivation.mmcode_id,
                    vehicle: getState().strContractActivation.vehicle,
                    vin: getState().strContractActivation.vin,
                    odometer: getState().strContractActivation.odometer,
                    starttime: getState().strContractActivation.starttime,
                }
                break;
            case 'tsk_str_quote_accept_customer_str_quote':
                data = {
                    data: {
                        comments: getState().acceptCustomerSignedSTR.comments,
                        recieveddate: getState().acceptCustomerSignedSTR.recieveddate,
                        ponumber: getState().acceptCustomerSignedSTR.ponumber,
                        vehicle: getState().acceptCustomerSignedSTR.vehicle,
                        documents: getState().upload.documents,
                    }
                }
                break;
            case 'tsk_fml_quote_waiting_vehicle_delivery':
                const tsk_fml_quote_waiting_vehicle_delivery_validation = validator.validateState(getState, 'workflowVehicleDeliveryConfirm', dispatch, true);
                bValid = tsk_fml_quote_waiting_vehicle_delivery_validation.state_valid;
                if (!bValid) {
                    tsk_fml_quote_waiting_vehicle_delivery_validation.errorMessages.forEach(em => errorMessages.push(em));
                }
                data = getState().workflowVehicleDeliveryConfirm
                break;
            case 'tsk_full_maintenance_lease_review':
                data = getState().workflowReviewQuote
                break;
            case 'tsk_fml_quote_approve_customer_quote':
                const tsk_fml_quote_approve_customer_quote_validation = validator.validateState(getState, 'workflowQuoteReview', dispatch, true);
                bValid = tsk_fml_quote_approve_customer_quote_validation.state_valid;
                if (!bValid) {
                    tsk_fml_quote_approve_customer_quote_validation.errorMessages.forEach(em => errorMessages.push(em));
                }
                data = getState().workflowQuoteReview
                break;
            case 'tsk_fml_quote_final_approve_customer_quote':
                data = getState().workflowQuoteApprove
                break;
            case 'tsk_fml_quote_approve_and_send_po':
                data = getState().workflowFMLCustomerPO
                break;
            default:
                try {

                    data = [
                        {
                            form: {
                                startdate: component.data.startdate.input,
                                distancepermonth: component.data.distancepermonth.input,
                                contractperiod: component.data.contractperiod.input,
                                contractdistance: component.data.contractdistance.input,
                                contracttype: component.data.contracttype.input,
                                contracttype_id: component.data.contracttype_id.input,
                                contractvehiclecategory_id: component.data.contractvehiclecategory_id.input,
                                contractvehiclecategory: component.data.contractvehiclecategory_id.text,
                                vehicle_id: component.data.vehicle_id.input,
                                vehicle: component.data.vehicle_id.text,
                                customer_id: component.data.customer_id.input,
                                customername: component.data.customername.input,
                                rfq: component.data.rfq.input,
                            },
                            grid: component.grid.data,
                            items: surrogateitems.grid.data
                        }
                    ]

                    meta = [
                        {
                            title: 'Distance',
                            description: 'Distance',
                            meta_data: component.data.distancepermonth.input
                        },
                        {
                            title: 'Period',
                            description: 'Period',
                            meta_data: component.data.contractperiod.input
                        },
                        {
                            title: 'Contract Type',
                            description: 'Contract Type',
                            meta_data: component.data.contracttype.input
                        },
                        {
                            title: 'Contract Category',
                            description: 'Contract Category',
                            meta_data: component.data.contractvehiclecategory_id.text
                        },
                        {
                            title: 'Quote Reviewed By',
                            description: 'Quote Reviewed By',
                            meta_data: getState().user.firstname
                        }
                    ]

                } catch (error) {

                }
                break;
        }

        window.glyco.log(getState().accounts.selectedAccount)
        if (data.data) {
            data.data = {
                ...data.data,
                datestamp: formatDate(moment(new Date())),
                selectedaccount: {
                    id: getState().accounts.selectedAccount,
                    name: getState().accounts.accountName
                }
            }
        }
        const payload = {
            step: step,
            component: component.name,
            selected: {
                ...data,
                // currdate: formatDate(moment(new Date())), 
                // datestamp: formatDate(moment(new Date())), 
                // selectedaccount: {
                //     id: getState().accounts.selectedAccount,                    
                //     name: getState().accounts.accountName
                // }
                submitted_at: formatDate(moment(new Date())),
                selectedaccount: {
                    account_id: getState().accounts.selectedAccount,
                    account_name: getState().accounts.accountName
                }
            },
            selected: data,
            meta: meta,
            assignto: user
        }

        if (bValid) {
            dispatch({
                type: types.CONFIRM_QUOTE_EDIT_ITEMS_SELECTION,
                payload: payload
            })

            dispatch({
                type: types.APPROVE_WORKFLOW_QUEUE_TASK,
                payload: {
                    ...data,
                    assignto: user
                }
            })

            dispatch(clearWorkflowTaskApprovalInformation())
        }
        else {
            if (errorMessages.length > 0) {
                errorMessages.forEach(errorMessage => {
                    dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            type: 'danger',
                            title: 'Validation Failed',
                            additionalText: errorMessage,
                            overflowText: getState().login.fields.email.input,
                            autoHide: 7500,
                            timestamp: moment().format('h:mm A')
                        }
                    })
                })
            } else {
                dispatch({
                    type: appTypes.SHOW_NOTIFICATION,
                    payload: {
                        type: 'danger',
                        title: 'Validation Failed',
                        additionalText: errorMessage,
                        overflowText: getState().login.fields.email.input,
                        autoHide: 7500,
                        timestamp: moment().format('h:mm A')
                    }
                })
            }
        }
    }

}

export const confirmWorkflowTaskApproval = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CONFIRM_WORKFLOW_TASK_APPROVAL,
            payload: getState().workflowTaskInput.task
        })
    }
}


export const getQuotationPDF = (payload) => {
    return (dispatch, getState) => {

        dispatch({
            type: types.OPEN_VIEW_PDF_DOCUMENT,
            payload: { viewpdf: true }
        })

        dispatch({
            type: types.EDIT_QUOTE_GET_RFQ_DOCUMENT,
            payload: payload
        })
    }
}

export const getClearDocuments = () => {
    return (dispatch, getState) => {

        dispatch({
            type: types.CLOSE_VIEW_PDF_DOCUMENT,
            payload: { viewpdf: false }
        })

        dispatch({
            type: types.CLEAR_EDIT_QUOTE_DOCUMENT,
            payload: {
                component: getState().components['document'],
                data: ""
            }
        })
    }
}

export const setDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.EDIT_QUOTE_SET_DOCUMENT,
            payload: documents
        })
    }
}

export const viewDocument = (data) => {
    let link = ''
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)
    return (dispatch, getState) => {
        dispatch({
            type: types.EDIT_QUOTE_SET_PREVIEW_DOCUMENT_BYTES,
            payload: {
                component: getState().components['document'], //getFile(data),
                data: link
            }
        })
    }
}



export const getDownloadDocument = (file) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.DOWNLOAD_QUOTE_REVIEW_RFQ_DOCUMENTS,
            payload: {
                type: 'DOWNLOAD_QUOTE_REVIEW_RFQ_DOCUMENTS',
                key: 'quotes/' + file
            }
        })
    }
}


export const getDownloadchedule = () => {
    return (dispatch, getState) => {
        const component = getState().components['surrogateheader']
        const surrogateitems = getState().components['surrogateitems']
        dispatch({
            type: types.DOWNLOAD_REVIEW_SCHEDULE_DOCUMENTS,
            payload: {
                type: 'DOWNLOAD_REVIEW_SCHEDULE_DOCUMENTS',
                data: {
                    form: {
                        startdate: component.data.startdate.input,
                        distancepermonth: component.data.distancepermonth.input,
                        contractperiod: component.data.contractperiod.input,
                        contractdistance: component.data.contractdistance.input,
                        contracttype: component.data.contracttype.input,
                        contracttype_id: component.data.contracttype_id.input,
                        contractvehiclecategory_id: component.data.contractvehiclecategory_id.input,
                        categorydescription: component.data.contractvehiclecategory_id.text,
                        vehicle_id: component.data.vehicle_id.input,
                        licenseplateno: component.data.vehicle_id.text,
                        customer_id: component.data.customer_id.input,
                        customername: component.data.customername.input,
                        rfq: component.data.rfq.input,
                    },
                    grid: component.grid.data,
                    items: surrogateitems.grid.data
                }
            }
        })
    }
}
export const setDownloadDocument = (data) => {
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        dispatch({
            type: types.VIEW_DOWNLOAD_QUOTE_REVIEW_RFQ_DOCUMENTS,
            payload: {
                url: data.data.document.key, //getFile(data),
                type: data.data.document.extension
            }
        })
    }
}


export const getWorkflowTaskConnectorDataEpic = (workflow_task_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_TASK_CONNECTOR_DATA,
            payload: workflow_task_id
        })
    }
}
export const setWorkflowTaskConnectorDataEpic = (payload, task) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_CONNECTOR_DATA,
            payload: {
                workflow_task_connector: payload,
                workflow_task_connector_id: task
            }
        })
    }
}