import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
import * as appTypes from '../../App/types'
import { bccusers } from './props'

export const setValue = (prop, value) => {
    return (dispatch, getState) => {  
            dispatch({
                type: types.SET_BILLING_VALUE_CHANGE,
                payload: {
                    prop: prop,
                    value: value
                }
            })
    }
}

export const setCheckValue = (prop, value) => {
    return (dispatch, getState) => {  
            dispatch(resetFilters())
            dispatch({
                type: types.SET_BILLING_VALUE_CHANGE,
                payload: {
                    prop: prop,
                    value: value
                }
            })
    }
}

export const resetFilters = () => {
    return (dispatch) => {       
            dispatch({
                type: types.RESET_BILLING_VALUE_CHANGE,
            })
    }
}


export const reset = () => {
    return (dispatch, getState) => {       
            dispatch({
                type: types.RESET_REFRESH_VALUES,
            })
    }
}

export const getInvoiceData = () => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.GET_BILLING_INVOICE_DATA,
            payload: {
                collection: 'billing_preview_data',
                searchText: ''
            }
        })
    }
}

export const setInvoiceData = (payload) => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.SET_BILLING_INVOICE_DATA,
            payload: payload.data
        })
    }
}
export const getBillingTracking = (billingtracking_id) => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.GET_BILLING_TRACKING,
            payload: billingtracking_id
        })
    }
}
export const refreshStatusData = () => {
    return (dispatch, getState) => {  
        dispatch(getInvoiceData())
        //console.log('refreshStatusDatazx ui')
        // dispatch({
        //     type: appTypes.SHOW_NOTIFICATION,
        //     payload: {
        //         title: 'System Notification',
        //         additionalText: 'Reload....',
        //         overflowText: 'Reload...',
        //         autoHide: 10000,
        //         timestamp: moment().format('h:mm A')
        //     }
        // })
    }
}

export const setBillingTracking = (payload, billingtracking_id) => {
    //console.log('setBillingTracking payload', payload)
    //console.log('setBillingTracking billingtracking_id', billingtracking_id)
    return (dispatch, getState) => {       
        dispatch({
            type: types.SET_BILLING_TRACKING,
            payload: payload.data
        })
    }
}


export const previewInvoice = (customertransaction_id, email) => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.GENERATE_BILLING_INVOICE_FOR_PREVIEW,
            payload: {
                type: 'GENERATE_BILLING_INVOICE_FOR_PREVIEW',
                customertransaction_id: customertransaction_id,
                email: email
            }
        })

           
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating invoice preview....',
                overflowText: 'Generating invoice preview...',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const sendBillingInvoice = (start) => {
    return (dispatch, getState) => {    
        let billingtracking_id = getState()?.billingpreview?.billingtracking?.[0]?.billingtracking_id    
        dispatch({
            type: types.SEND_CUSTOMER_BILLING_INVOICE,
            payload: {
                type: 'SEND_CUSTOMER_BILLING_INVOICE',
                start: start,
                billingtracking_id: billingtracking_id
            }
        })

           
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: start ? 'Initializing sending customer invoice process....' : 'Stopping sending customer invoice....',
                overflowText: start ? 'Initializing sending customer invoice process....' : 'Stopping sending customer invoice....',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const sendBillingInvoiceNotify = () => {
    return (dispatch, getState) => {  
        
        let billingtracking_id = getState()?.billingpreview?.billingtracking?.[0]?.billingtracking_id 
        if(billingtracking_id)
        dispatch(getBillingTracking(billingtracking_id))   

        dispatch(getInvoiceData())
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Send customer invoice process started....',
                overflowText: 'Send customer invoice process started...',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const updateBillingBccUsersDone = () => {
    return (dispatch, getState) => { 
        
        let billingtracking_id = getState()?.billingpreview?.billingtracking?.[0]?.billingtracking_id 
        if(billingtracking_id)
        dispatch(getBillingTracking(billingtracking_id)) 
         
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Copy user update',
                overflowText: 'Copy user updates',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const exportBillingInvoice = (billingtracking_id) => {
    return (dispatch, getState) => {       
        dispatch({
            type: types.EXPORT_BILLING_INVOICE_LIST,
            payload: {
                type: 'EXPORT_BILLING_INVOICE_LIST',
                billingtracking_id: billingtracking_id
            }
        }) 
    }
}

export const downloadInvoice = (data) => {
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    if(data.data.email){
        return (dispatch) => {     
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Preview invoice emailed to ' + data.data.email,
                    overflowText: 'Preview invoice emailed',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else 
    {
        saveByteArray([data.data.content.Body], data.data.document)

        return (dispatch) => {     
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Preview invoice downloading',
                    overflowText: 'Preview invoice downloading',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}

export const downloadBillingInviceList = (data) => {
    //console.log('downloadBillingInviceList >>>>>>>>>>>>>>>>>>>>>>>>', data)
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf], data.data.key)

    return (dispatch, getState) => {    
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Downloading billing export invices',
                overflowText: 'Downloading billing export invices',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}


export const getUserAutoCompleteMenuItems = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BILLING_BCC_COPY_USERS,
            payload: {
                query: '?firstname=' + value
            }
        })
    }
}

export const setUserAutoCompleteMenuItems = (result) => {
    return (dispatch, getState) => {
        //console.log('setUserAutoCompleteMenuItems',result )
        dispatch({
            type: types.SET_BILLING_BCC_COPY_USERS,
            payload: result.data.map(x => {
                        return {
                            value: x.user_id,
                            text: x.email
                        }
                    })
        })
    }
}

export const setUserAutoCompleteSelected = (request, billingtracking_id) => {
    return (dispatch, getState) => {
        console.log('setUserAutoCompleteSelected',request )
        let bccusers = ""
        for(let x of request){
            bccusers += x.text + ','
        }
        dispatch({
            type: types.SET_BILLING_BCC_COPY_SELECTED_USERS,
            payload: bccusers
        })
        dispatch({
            type: types.SAVE_BILLING_BCC_COPY_SELECTED_USERS,
            payload: {
                billing_bcc_copy_users: bccusers,
                billingtracking_id: billingtracking_id
            }
        })
    }
}