export const SET_COMPONENT_CONTRACT_DETAILS = 'SET_COMPONENT_CONTRACT_DETAILS'
export const GET_COMPONENT_CONTRACT_DETAILS = 'GET_COMPONENT_CONTRACT_DETAILS'
export const GET_All_COMPONENT_CONTRACT_DETAILS = 'GET_ALL_COMPONENT_CONTRACT_DETAILS'
export const SET_COMPONENT_INPUT_VALUUES = 'SET_COMPONENT_INPUT_VALUUES'
export const SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE = 'SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE'
export const SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE_PROP = 'SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE_PROP'
export const GET_COMPONENT_CONTRACT_ACCESSORY_LOOKUP_DATA = 'GET_COMPONENT_CONTRACT_ACCESSORY_LOOKUP_DATA'
export const GET_COMPONENT_CONTRACT_AMORTISATION_CLIENT_ENTRY = 'GET_COMPONENT_CONTRACT_AMORTISATION_CLIENT_ENTRY'
export const SET_COMPONENT_CONTRACT_AMORTISATION_CLIENT_ENTRY = 'SET_COMPONENT_CONTRACT_AMORTISATION_CLIENT_ENTRY'
export const GET_SUPPLIER_LIST_LOOKUP_DATA = 'GET_SUPPLIER_LIST_LOOKUP_DATA'
export const SET_SUPPLIER_LIST_LOOKUP_DATA = 'SET_SUPPLIER_LIST_LOOKUP_DATA'

export const GET_VEHICLE_AMORT_SCHEDULE_DETAILS = 'GET_VEHICLE_AMORT_SCHEDULE_DETAILS'
export const SET_VEHICLE_AMORT_SCHEDULE_DETAILS = 'SET_VEHICLE_AMORT_SCHEDULE_DETAILS'
export const SET_ZERO_VALUE_ACCESSORY = 'SET_ZERO_VALUE_ACCESSORY'