export const new_registration = null
export const registrationchangedate = null

export const fields = { 
    documents: {
        errorText: 'Please add a document and select document type',
        data: false,
        valid: false,
        empty: true,
        popup: true,
        type: 'documents',
      },
      registrationchangedate: {
        errorText: 'Please select Registration Change Date',
        valid:false,
        state: 'getState().registrationChangeCapture?.registrationchangedate'
    },
    new_registration: {
        errorText: 'Please Enter Vehicle New Registration Number',
        valid:false,
        state: 'getState().registrationChangeCapture?.new_registration'
    }
}