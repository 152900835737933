import React, { Component } from 'react'
import ReactTable from "react-table"
import "react-table/react-table.css"
import moment from 'moment'
import Icon from '../Icon/component'
import Theme from '../../theme'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import AutoComplete from '../../controls/autocomplete'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox';


import Upload from '../Upload/component'


import Loading from '../Loading/component'
import PdfViewer from '../PDF/component'


import * as actions from './actions'
import * as styles from './styles'

class InvoiceapReject extends Component {


    componentWillMount(){

        
         window.glyco.log('The workflow_queue object Passed data ')
         window.glyco.log(this.props.workflow_queue_data)
        //  const data = this.props.workflow_queue_data.data[0].context_data.data.data.data[0].data.data.data.data
        
        //  window.glyco.log('The invoiceapRejectState Component State')
        //  window.glyco.log(this.props.state.InvoiceapReject) 
         
       
       
        
    }

   
    
    invDocument(data) {
        let documents = null
        

        try{
            documents = data.data[0].context_data.data.data.data[0].data[0].data.data.data[0].data.confirm.selectedPO.purchaseorderdocument.map(x => {
                window.glyco.log('x')
                window.glyco.log(x)

                return {
                    filename: x.document.filename,
                    filetype: x.document.description,
                    documenttype: x.document.description,
                }
            })
          

        }catch(err){
        }

        window.glyco.log('invDocument')
        window.glyco.log(documents)

        return documents
        ? <Card>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('Documents')}
                subtitle={window.t.en('Documents list ')}
                style={styles.row}
            >
            </CardHeader>
            <CardContent>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {
                                documents
                                    ? <PdfViewer
                                        key={'quotation_viewer_'}
                                        documents={documents}
                                        multiple={true}
                                        height={500}
                                        edit={false}
                                    />
                                    : <Loading message={'Retrieving Purchase Order document...'} />
                            }
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
        : ''
    }

   

    confirm(data) {

        let invC = ''
        
        try{
            
            invC = data.data[0].context_data.data.data.data[0].data[0].data.data.data[0].data.confirm.invoice_rejection_comment

        }catch(err){
        }


        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Resolve rejected Invoice')}
                    subtitle={window.t.en('')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                    <div style={styles.fieldContainer} >
                    
                                    <div style={styles.fieldTitle}>{'Rejection reason:'}</div>
                                    <div style={styles.fieldContent}>
                                    
                                        <TextField
                                            id={'invoicereject'}
                                            value={invC}
                                            disabled = {true}
                                            fullWidth={true}
                                         
                                            
                                        />
                                    
                                    </div>
                                </div>
                            
                                <span style={styles.offsetcommentbox} />
       
                                <div style={styles.caption}>Below are all documents relevant to this invoice:</div>
                              

                                {this.invDocument(data)} 


                                <span style={styles.offsetcommentbox} />
       
                                <div style={styles.caption}>Add new document(s) to update this invoice:</div>

                                <Upload />
                                 
                            

                               
                       
                        <Row>

                            <Col xl={12}>
                                {}
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

   

    render() {


        window.glyco.log('this.props.workflow_queue_data Surprise')
        window.glyco.log(this.props.workflow_queue_data)
        window.glyco.log(this.props.workflow_queue_data.data)
        // const data = this.props.workflow_queue_data.data[0].context_data.data.data.data[0].data.data.data.data
       
        
        // const workflow_queue_data = this.props.workflow_queue_data
        // window.glyco.log(workflow_queue_data.context_data.data.data.data[0])



      

        window.glyco.log('The stepIndex value')
        window.glyco.log(this.props.stepIndex)
        return this.props.stepIndex === 0
        ? this.confirm(this.props.workflow_queue_data)
        : <div />;
      
      
       

    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.invoiceapReject }
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceapReject)
