import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.UPLOAD_IMAGES_ADD_ROW:
            return {
                ...state,
                images: action.payload,
            }
        case types.SET_UPLOAD_IMAGE_PREVIEW:
            return {
                ...state,
                images: state.images.map((x, i) =>
                    action.payload.id === x.fileinx
                        ? {
                            ...x,
                            preview: action.payload.preview,
                            filename: action.payload.filename
                        }
                        : x
                )
            }
        default:
            return state
    }
}