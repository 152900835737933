import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Select from '@mui/material/Select'
import { Card, CardHeader, CardContent, Dialog } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'

import * as actions from './actions'
import * as workflowQueueTaskActions from '../actions'

import * as styles from './styles'

class ReassignTask extends Component {
    componentDidMount() {
        this.props.actions.getTeams()

        if (this.props.workflow_queue_task_id) {
            this.props.actions.getTask(this.props.workflow_queue_task_id)
        }
    }

    getTeamMenu() {
        switch (this.props.state.mode) {
            case 'assign_task':
            case 'reject_task':
            case 'reassign_task':
                return this.props.state.data.map(x => {
                    return <MenuItem value={x.team_id} primaryText={window.t.en(x.title)} >{x.title}</MenuItem>
                })
            case 'cancel_task':
            case 'unassign_task':
            default:
                return <div />
        }
    }

    getUserMenu() {
        switch (this.props.state.mode) {
            case 'assign_task':
            case 'reassign_task':
            case 'reject_task':
                return this.props.state.data
                    .filter(x => x.team_id === this.props.state.selected_team_id)[0].users
                    .map(x => {
                        return <MenuItem value={x.user_id} primaryText={x.firstname + ' ' + x.lastname} >{x.firstname + ' ' + x.lastname}</MenuItem>
                    })
            case 'cancel_task':
            case 'unassign_task':
            default:
                return <div />
        }
    }

    getTitle() {
        switch (this.props.state.mode) {
            case 'assign_task':
                return 'Assign Task'
            case 'cancel_task':
                return 'Cancel Workflow'
            case 'reassign_task':
                return 'Reassign Task'
            case 'reject_task':
                return 'Reject Task'
            case 'unassign_task':
                return 'Unassign Task'
            default:
                return 'WARNING: Unknown Mode!'
        }
    }

    getSubtitle() {
        switch (this.props.state.mode) {
            case 'assign_task':
                return 'Assign this task to a user.'
            case 'cancel_task':
                return 'Cancel this task and it\'s associated workflow.'
            case 'reassign_task':
                return 'Reassign this task to user.'
            case 'reject_task':
                return 'Reject this task and send it back to it\'s originator. You may choose to assign it to another another user.'
            case 'unassign_task':
                return 'Unassign this task. This will place it back into the currently assigned user\'s team queue.'
            default:
                return 'WARNING: Unknown Mode!'
        }
    }

    getCommandTitle() {
        switch (this.props.state.mode) {
            case 'assign_task':
                return 'Assign'
            case 'cancel_task':
                return 'Cancel Task'
            case 'reassign_task':
                return 'Reassign'
            case 'reject_task':
                return 'Reject'
            case 'unassign_task':
                return 'Unassign'
            default:
                return 'WARNING: Unknown Mode!'
        }
    }

    getTaskAssignmentMenus() {
        switch (this.props.state.mode) {
            case 'assign_task':
            case 'reassign_task':
                return <div>
                    <Select
                        style={styles.Select}
                        menuStyle={styles.Select}
                        menuItemStyle={styles.Select}
                        value={this.props.state.selected_team_id}
                        onChange={(event, index, value) => {
                            this.props.actions.selectTeam(event.target.value)
                        }}>
                        {this.getTeamMenu()}
                    </Select>
                    <br />
                    {/* <br />
                    <Select
                        style={styles.Select}
                        menuStyle={styles.Select}
                        menuItemStyle={styles.Select}
                        value={this.props.state.selected_user_id}
                        onChange={(event, index, value) => {
                            this.props.actions.selectUser(event.target.value)
                        }}>
                        {this.getUserMenu()}
                    </Select>
                    <br /> */}
                    <br />
                </div>
            case 'cancel_task':
            case 'unassign_task':
            case 'reject_task':
            default:
                return <div />
        }
    }

    getCommentsFloatingLabelText() {
        switch (this.props.state.mode) {
            case 'assign_task':
                return 'Please state your reason for assigning this task to another team.'
            case 'cancel_task':
                return 'Please state your reason for cancelling this workflow.'
            case 'reassign_task':
                return 'Please state your reason for reassigning this task to another user.'
            case 'reject_task':
                return 'Please state your reason for rejecting this task.'
            case 'unassign_task':
                return 'Please state your reason for unassigning this task.'
            default:
                return 'WARNING: Unknown Mode!'
        }
    }

    getCommentsHintText() {
        switch (this.props.state.mode) {
            case 'assign_task':
                return 'Please state your reason for assigning this task to another user.'
            case 'cancel_task':
                return 'Please state your reason for cancelling this workflow.'
            case 'reassign_task':
                return 'Please state your reason for reassigning this task to another user.'
            case 'reject_task':
                return 'Please state your reason for rejecting this task.'
            case 'unassign_task':
                return 'Please state your reason for unassigning this task.'
            default:
                return 'WARNING: Unknown Mode!'
        }
    }

    dispatchAction() {
        //console.log('setReassignTaskComment dispatchAction',this.props.state.comments)
        //console.log('dispatchAction mode',this.props.state.mode)
        switch (this.props.state.mode) {
            case 'assign_task':
                this.props.actions.assignTask(this.props.state.team_id, this.props.state.user_id, this.props.state.task, this.props.state.comments, this.props.state.selected_team_id, this.props.state.selected_user_id)
                break
            case 'cancel_task':
                this.props.actions.cancelTask(this.props.state.team_id, this.props.state.user_id, this.props.state.task, this.props.state.comments, this.props.state.selectcomments)
                break
            case 'reassign_task':
                this.props.actions.reassignTask(this.props.state.team_id, this.props.state.user_id, this.props.state.task, this.props.state.comments, this.props.state.selected_team_id, this.props.state.selected_user_id)
                break
            case 'reject_task':
                this.props.actions.rejectTask(this.props.state.team_id, this.props.state.user_id, this.props.state.task, this.props.state.comments, this.props.state.selected_team_id, this.props.state.selected_user_id)
                break
            case 'unassign_task':
                this.props.actions.unassignTask(this.props.state.team_id, this.props.state.user_id, this.props.state.task, this.props.state.comments)
                break
            default:
                break
        }

        this.props.actions.hideReassignTask()
    }

    getTaskInformation() {
        //console.log('getTaskInformation mode', this.props.state.mode)
        //console.log('getTaskInformation task', this.props.state.task)
        if(this.props.state.task) {
            switch (this.props.state.mode) {
                case 'assign_task':
                    return 'You are about to assign task #' + this.props.state.task['workflow_queue_task#workflow_queue_id'] + '/' + (this.props.state.task['workflow_task#order'] ? this.props.state.task['workflow_task#order'] : '?') + ' "' + this.props.state.task['workflow_task#title'] + '" of the "' + this.props.state.task['workflow#title'] + '" workflow.'
                case 'cancel_task':
                    return 'You are about to cancel task #' + this.props.state.task['workflow_queue_task#workflow_queue_id'] + '/' + (this.props.state.task['workflow_task#order'] ? this.props.state.task['workflow_task#order'] : '?') + ' "' + this.props.state.task['workflow_task#title'] + '" of the "' + this.props.state.task['workflow#title'] + '" workflow.'
                case 'reassign_task':
                    return 'You are about to reassign task #' + this.props.state.task['workflow_queue_task#workflow_queue_id'] + '/' + (this.props.state.task['workflow_task#order'] ? this.props.state.task['workflow_task#order'] : '?') + ' "' + this.props.state.task['workflow_task#title'] + '" of the "' + this.props.state.task['workflow#title'] + '" workflow.'
                case 'reject_task':
                    return 'You are about to reject task #' + this.props.state.task['workflow_queue_task#workflow_queue_id'] + '/' + (this.props.state.task['workflow_task#order'] ? this.props.state.task['workflow_task#order'] : '?') + ' "' + this.props.state.task['workflow_task#title'] + '" of the "' + this.props.state.task['workflow#title'] + '" workflow.'
                case 'unassign_task':
                    return 'You are about to unassign task #' + this.props.state.task['workflow_queue_task#workflow_queue_id'] + '/' + (this.props.state.task['workflow_task#order'] ? this.props.state.task['workflow_task#order'] : '?') + ' "' + this.props.state.task['workflow_task#title'] + '" of the "' + this.props.state.task['workflow#title'] + '" workflow.'
                default:
                    return 'WARNING: Unknown Mode!'
            }
        }
    }

    confirmDialog() {

        return <Dialog
            open={this.props.state.rejectDialog}
            onClose={(event) => {
                event.preventDefault()
                //this.props.actions.toggleSupplierSuspended(false)
            }}
        //classes={{ paper: 'shadow-lg rounded' }}
        >
            <div className="text-center p-5">
                <h4 className="font-weight-bold mt-4">
                    Task reject
                </h4>
                <p className="mb-0 font-size-lg text-muted">
                    {this.getTaskInformation()}
                </p>
                <p className="mb-0 font-size-lg text-muted">
                    Do you want to reject this task?
                </p>
                <div className="pt-4">
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            //this.props.actions.toggleSupplierSuspended(false)
                            this.props.actions.setToggleRejectConfirm(false)
                        }}
                        className=" btn-pill mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.setReassignTaskComment(null,this.taskname)
                            this.props.actions.setToggleRejectConfirm(false)
                            this.dispatchAction()
                        }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Reject</span>
                    </Button>

                </div>
            </div>
        </Dialog>
    }

    getLayout(){
        return this.props.state.loading || !this.props.state.data.length || this.props.mode === '' || ((this.props.workflow_queue_task_id && this.props.taskLoading) || this.props.state.loading || !this.props.state.data.length || this.props.mode === '')
            ? <Loading message={'Retrieving data...'} />
                :  <Card initiallyExpanded={true}>
                    <CardContent expandable={true}>
                    {this.getTaskInformation()}

                    <br />
                    <br />

                    {this.getTaskAssignmentMenus()}

                    {
                        this.props.state.mode === 'cancel_task'
                            ? <div> <Select
                                style={styles.Select}
                                variant="outlined"
                                label={'Select reason'}
                                menuStyle={styles.Select}
                                menuItemStyle={styles.Select}
                                value={this.props.state.selectcomments}
                                onChange={(event, index, value) => {
                                    this.props.actions.setSelectedReassignTaskComment(event.target.value)
                                }}>
                                {
                                    this.props.state.workflow_cancel_reason
                                        ? this.props.state.workflow_cancel_reason.map((r, i) => {
                                            return <MenuItem value={r.Name} primaryText={r.Name} >{r.Name}</MenuItem>
                                        })
                                        : ''
                                }
                            </Select>
                            <br /> <br />
                                <TextField variant="outlined"
                                    id={'txt_cancel_task_comment'}
                                    label={this.getCommentsHintText()}
                                    fullWidth={true}
                                    multiLine={true}
                                    error={this.props.state.comments.length < 10}
                                    helperText={this.props.state.comments.length < 10 ? 'Please provide your comments.' : ''}
                                    value={this.props.state.comments}
                                    rows={1}
                                    onChange={(event, value) => {
                                        event.preventDefault() 
                                        this.props.actions.setReassignTaskComment(event.target.value)
                                    }}
                                />
                            </div>

                            // <SelectField
                            //     multiple={true}
                            //     hintText="Select Reject Reason"
                            //     value={this.props.state.comments}
                            //     onChange={(event, value) => {
                            //         //console.log(value)
                            //         //console.log(event)
                            //         //this.props.actions.setSelectedLookupValue(value)

                            //         this.props.actions.setReassignTaskComment(value)
                            //     }}
                            // >
                            //     {
                            //         this.props.state.workflow_cancel_reason
                            //             ? this.props.state.workflow_cancel_reason.map((r, i) => {
                            //                 return <MenuItem
                            //                     key={'dd_workflow_reject_reason_'+i}
                            //                     insetChildren={true}
                            //                     //checked={this.props.state.reject_reason && this.props.state.reject_reason.indexOf(r.Name) > -1}
                            //                     value={r.Name}
                            //                     primaryText={r.Name}
                            //                 />
                            //             })
                            //             : ''
                            //     }
                            // </SelectField>
                            : <TextField variant="outlined"
                                    id={'txtdriver'}
                                    label={this.getCommentsHintText()}
                                    error={this.props.state.comments.length > 10 ? false : true}
                                    helperText={this.props.state.comments ? '' : this.getCommentsHintText()}
                                    fullWidth={true}
                                    value={this.props.state.comments}
                                    onChange={(event, value) => {
                                        this.props.actions.setReassignTaskComment(event.target.value)
                                    }}
                                />
                        // <TextField
                        //         hintText={this.getCommentsHintText()}
                        //         errorText={this.props.state.comments.length < 10 ? 'Please provide your comments.' : ''}
                        //         floatingLabelText={this.getCommentsFloatingLabelText()}
                        //         value={this.props.state.comments}
                        //         style={styles.Select}
                        //         multiLine={true}
                        //         rows={4}
                        //         onChange={(event, value) => {
                        //             event.preventDefault()
                        //             this.props.actions.setReassignTaskComment(value)
                        //         }}
                        //     />
                    }



                    <br />
                    <br />
                    <br />

                    <div style={{ textAlign: 'right' }}>
                       < Button className={"global_button"} variant="contained"
                            primary={true}
                            label='Back'
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.hideReassignTask()
                            }}
                            >{window.t.en('Back')}</Button>

                        &nbsp; &nbsp;

                       <Button className={"global_button"} variant="contained"
                            primary={true}
                            disabled={this.props.state.comments.length < 10}
                            label={this.getCommandTitle()}
                            onClick={(event) => {
                                event.preventDefault()
                                this.dispatchAction()
                            }}
                            >{this.getCommandTitle()}</Button>
                    </div>
                </CardContent>
            </Card>
    }


    render() {
        //console.log('this.props.state',this.props.state)
        let taskname = null
        try {
            taskname = this.props.state.task['workflow_task#name']
        } catch (error) {
            
        } 
        this.taskname = taskname
        //console.log('this.taskname',this.taskname)
        
        return (
            <div>
                {/* {this.getLayout()} */}
                {this.props.state.mode !== 'reject_task' && this.getLayout()}
                {this.confirmDialog()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.reassignTask }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowQueueTaskActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReassignTask)