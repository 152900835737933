import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import AutoComplete from '../../../controls/autocomplete'
import DateControl from '../../../controls/dateControl'


import Upload from '../../Upload/component'
import STRItemGrid from '../STRItemGrid/component'

import * as styles from './styles'
import * as actions from './actions'
import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'
import * as  strGridItemsAction from '../STRItemGrid/actions'

class CaptureSTR extends Component {
    componentDidMount() {
        this.props.actions.setStartdate()
       /// this.props.actions.setValidationState(this.props.state.validation)
    }

    getForm() {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Short Term Rental')}
                    subtitle={window.t.en('Capture vehicle details')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Adhoc Quote:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <Checkbox
                                                        checked={this.props.state.adhoc.value}
                                                        onClick={(event, checked) => {
                                                            //console.log(this.props.state.adhoc.value)
                                                            //console.log(checked)
                                                            this.props.actions.setInputValue('adhoc', !this.props.state.adhoc.value)
                                                        }} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Selection Category:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        id={'dll_contractvehiclecategory_id'}
                                                        key={'dll_contractvehiclecategory_id'}
                                                        hintText={'Select make and model'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.contractvehiclecategory_data}
                                                        onSelect={
                                                            (chosenRequest) => {
                                                                this.props.actions.setLookupValue('contractvehiclecategory', chosenRequest.value, chosenRequest.text)
                                                            }
                                                        }
                                                        onClick={
                                                            (event) => {
                                                                this.props.actions.getLookupValueData('contractvehiclecategory', 'categorydescription', '')
                                                            }
                                                        }
                                                        onFilter={
                                                            (search, dataSource, params) => {
                                                                this.props.actions.getLookupValueData('contractvehiclecategory', 'categorydescription', search)
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Rental Supplier:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400 }}
                                                        id={'dll_supplier_id'}
                                                        key={'dll_supplier_id'}
                                                        hintText={'Select vehice rental supplier'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.supplier_data}
                                                        onSelect={
                                                            (chosenRequest) => {
                                                                this.props.actions.setLookupValue('supplier', chosenRequest.value, chosenRequest.text)
                                                            }
                                                        }
                                                        onClick={
                                                            (event) => {
                                                                this.props.actions.getLookupValueData('supplier', 'suppliername', '')
                                                            }
                                                        }
                                                        onFilter={
                                                            (search, dataSource, params) => {
                                                                this.props.actions.getLookupValueData('supplier', 'suppliername', search)
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        !this.props.state.adhoc.value
                                            ? <div>
                                                <Row>
                                                    <Col xl={12}>

                                                        <div style={styles.fieldContainer}>
                                                            <div style={styles.fieldTitle}>{'Customer:'}</div>
                                                            <div style={styles.fieldContent}>
                                                                <AutoComplete
                                                                    listStyle={{ maxHeight: 400 }}
                                                                    id={'dll_customer_id'}
                                                                    key={'dll_customer_id'}
                                                                    hintText={'Select Customer fromthe List'}
                                                                    filter={AutoComplete.fuzzyFilter}
                                                                    openOnFocus={true}
                                                                    fullWidth={true}
                                                                    dataSourceConfig={{
                                                                        text: 'text',
                                                                        value: 'value'
                                                                    }}
                                                                    dataSource={this.props.state.customer_data}
                                                                    onSelect={
                                                                        (chosenRequest) => {
                                                                            this.props.actions.setLookupValue('customer', chosenRequest.value, chosenRequest.text)
                                                                        }
                                                                    }
                                                                    onClick={
                                                                        (event) => {
                                                                            this.props.actions.getLookupValueData('customer', 'hierarchyname', '')
                                                                        }
                                                                    }
                                                                    onFilter={
                                                                        (search, dataSource, params) => {
                                                                            this.props.actions.getLookupValueData('customer', 'hierarchyname', search)
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            : <div>
                                                <Row>
                                                    <Col xl={12}>
                                                        <div style={styles.fieldContainer}>
                                                            <div style={styles.fieldTitle}>{'Customer Name:'}</div>
                                                            <div style={styles.fieldContent}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    id={'txt_customer_name'}
                                                                    hintText={'Enter Customer Name'}
                                                                    value={this.props.state.cust_name.value}
                                                                    //disabled={true}
                                                                    onChange={(event, value) => {
                                                                        this.props.actions.setInputValue('cust_name', event.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={12}>
                                                        <div style={styles.fieldContainer}>
                                                            <div style={styles.fieldTitle}>{'Customer Contact Person:'}</div>
                                                            <div style={styles.fieldContent}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    id={'txt_customer_contact_person'}
                                                                    hintText={'Enter Customer Contact Person'}
                                                                    value={this.props.state.cust_person.value}
                                                                    //disabled={true}
                                                                    onChange={(event, value) => {
                                                                        this.props.actions.setInputValue('cust_person', event.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={12}>
                                                        <div style={styles.fieldContainer}>
                                                            <div style={styles.fieldTitle}>{'Customer Address:'}</div>
                                                            <div style={styles.fieldContent}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    id={'txt_customer_address'}
                                                                    hintText={'Enter Customer Address'}
                                                                    value={this.props.state.cust_address.value}
                                                                    //disabled={true}
                                                                    onChange={(event, value) => {
                                                                        this.props.actions.setInputValue('cust_address', event.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={12}>
                                                        <div style={styles.fieldContainer}>
                                                            <div style={styles.fieldTitle}>{'Delivery Cost:'}</div>
                                                            <div style={styles.fieldContent}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    id={'txt_deliverycost'}
                                                                    hintText={'Enter Delivery Cost'}
                                                                    value={this.props.state.deliverycost.value}
                                                                    //disabled={true}
                                                                    onChange={(event, value) => {
                                                                        this.props.actions.setInputValue('deliverycost', event.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                    }
                                    <Row>
                                        <Col xl={12}>
                                            <br />
                                        </Col>
                                    </Row><Row>
                                        <Col xl={12}>

                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Rental Units:'}</div>
                                                <div style={styles.fieldContent}>
                                               <AutoComplete
                                                        listStyle={{ maxHeight: 400 }}
                                                        id={'dll_units'}
                                                        key={'dll_units'}
                                                        hintText={'Default Set To Months'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.units}
                                                        onSelect={
                                                            (chosenRequest) => {
                                                                this.props.actions.setInputValue('unit', chosenRequest.value)
                                                            }
                                                        }
                                                        onClick={
                                                            (event) => {
                                                                //console.log(event)
                                                            }
                                                        }
                                                        onFilter={
                                                            (search) => {
                                                                //console.log(search)
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Contract Start Date:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <Container style={{ margin: 0 }} className="custom-container">
                                                        <Row>
                                                            <Col xl={4}>
                                                                <DateControl
                                                                    id={'txt_startdate'}
                                                                    key={'txt_startdate'}
                                                                    label='Select Contract Start Date'
                                                                    name={'txt_startdate'}
                                                                    value={this.props.state.startdate.value}
                                                                    defaultDate={this.props.state.startdate.value}
                                                                    onSelect={(args) => {
                                                                        //console.log('args date',args)
                                                                        this.props.actions.setInputValue('startdate', args)
                                                                        this.props.actions.calcEndDate('startdate', args)
                                                                        this.props.actions.updSTRGridItems()
                                                                    }}
                                                                    type={'date'}
                                                                />
                                                            </Col>
                                                            <Col xl={8}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.fieldTitle}>{'End:'}</div>
                                                                    <div style={styles.fieldContent}>
                                                                        <DateControl
                                                                            id={'txt_enddate'}
                                                                            key={'txt_enddate'}
                                                                            label='Select Contract End Date'
                                                                            name={'txt_enddate'}
                                                                            value={this.props.state.enddate.value}
                                                                            defaultDate={this.props.state.enddate.value}
                                                                            onSelect={(args) => {
                                                                                //console.log('args date',args)
                                                                                this.props.actions.calcPeriod(this.props.state.startdate.value, args)
                                                                                this.props.actions.finValue(this.props.state.strItemGrid)
                                                                                this.props.actions.updSTRGridItems()
                                                                            }}
                                                                            type={'date'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Contract Duration ('+this.props.state.unit.value+'s):'}</div>
                                                <div style={styles.fieldContent}>
                                                                <TextField
                                                                    id={'txt_months'}
                                                                    variant="outlined"
                                                                    value={this.props.state.months.value}
                                                                    onChange={(event, value) => {
                                                                        this.props.actions.setInputValue('months', event.target.value)
                                                                        this.props.actions.setEndMonth('months', event.target.value)

                                                                        this.props.actions.finValue(this.props.state.strItemGrid)

                                                                        //this.props.actions.calcContractDistance(this.props.state.contractperiod.value, value, 'contractdistance')

                                                                        this.props.actions.updSTRGridItems()
                                                                    }}
                                                                />
                                                    {/* <Container style={{ margin: 0 }} className="custom-container">
                                                        <Row>
                                                            <Col xl={4}>
                                                            </Col>

                                                            <Col xl={8}>
                                                                <div style={styles.fieldContainer}>
                                                                    <div style={styles.fieldTitle}>{'(days):'}</div>
                                                                    <div style={styles.fieldContent}>
                                                                        <TextField
                                                                            id={'txtdays'}
                                                                            value={this.props.state.days.value}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setInputValue('days', value)
                                                                                this.props.actions.setEndDays('days', value)

                                                                                this.props.actions.finValue(this.props.state.strItemGrid)
                                                                                this.props.actions.updSTRGridItems()
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Contract Distance per Month:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        id={'txt_distancepermonth'}
                                                        variant="outlined"
                                                        value={this.props.state.distancepermonth.value}
                                                        hintText={'Enter Contract Distance per Month'}
                                                        //disabled={this.field.contractperiod.read_only}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('distancepermonth', event.target.value)
                                                            this.props.actions.calcContractDistance(this.props.state.months.value, this.props.state.days.value, event.target.value, 'contractdistance')
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Contract Distance (km):'}</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        id={'txt_contractdistance'}
                                                        variant="outlined"
                                                        hintText={'Enter Contract Distance (km)'}
                                                        value={this.props.state.contractdistance.value}
                                                        disabled={true}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('contractdistance', event.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Rental Rate:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        id={'txt_rentalamount'}
                                                        variant="outlined"
                                                        hintText={'Enter Rental Amount'}
                                                        errorText={this.props.state.rentalamount.valid ? null : this.props.state.rentalamount.errorText}
                                                        value={this.props.state.rentalamount.value}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('rentalamount', event.target.value)
                                                            this.props.actions.setInputValue('monthly_str', event.target.value)
                                                            this.props.actions.finValue(this.props.state.strItemGrid)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Excess KM Rates:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        id={'txt_accesskm'}
                                                        variant="outlined"
                                                        hintText={'Enter Excess KM Rates'}
                                                        errorText={this.props.state.accesskm.valid ? null : this.props.state.accesskm.errorText}
                                                        value={this.props.state.accesskm.value}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('accesskm', event.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Quote Valid For:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        id={'txt_quotevalidfor'}
                                                        variant="outlined"
                                                        hintText={'Enter Quote Valid For'}
                                                        errorText={this.props.state.quotevalidfor.valid ? null : this.props.state.quotevalidfor.errorText}
                                                        value={this.props.state.quotevalidfor.value}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('quotevalidfor', event.target.value)
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Replce Vehicle:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        id={'txt_replacevehicle'}
                                                        hintText={'Enter Replce Vehicle'}
                                                        errorText={this.props.state.replacevehicle.valid ? null : this.props.state.replacevehicle.errorText}
                                                        value={this.props.state.replacevehicle.value}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('replacevehicle', event.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xl={6} style={styles.rightpanel}>
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <h4>{'Summary Costs'}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitleLeft}>{'Rental:'}</div>
                                                <div style={styles.fieldContentTot}>
                                                    <TextField
                                                        id={'txt_monthly_str'}
                                                        value={this.props.state.monthly_str.value}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitleLeft}>{'Accessories:'}</div>
                                                <div style={styles.fieldContentTot}>
                                                    <TextField
                                                        id={'accessory_tot'}
                                                        value={this.props.state.strItemGrid.accessory_tot}
                                                        onChange={(event, value) => {
                                                            this.props.actions.finValue(this.props.state.strItemGrid)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitleLeft}>{'Waiver:'}</div>
                                                <div style={styles.fieldContentTot}>
                                                    <TextField
                                                        id={'waiver_tot'}
                                                        value={this.props.state.waiver_tot.value}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitleLeft}>{'Sundry:'}</div>
                                                <div style={styles.fieldContentTot}>
                                                    <TextField
                                                        id={'sundry_tot'}
                                                        // value={
                                                        //     _.sumBy(this.props.state.strItemGrid.grid
                                                        //         .filter(x => x.itemtype === 'Sundry'), (d) => {
                                                        //             return parseFloat((d.totalprice) ? d.totalprice : 0)
                                                        //         })
                                                        // }
                                                        onChange={(event, value) => {
                                                            this.props.actions.finValue(this.props.state.strItemGrid)
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <h4>{'Summary Totals'}</h4>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitleLeft}>{'Subtotal:'}</div>
                                                <div style={styles.fieldContentTot}>
                                                    <TextField
                                                        id={'monthly_excl'}
                                                        value={
                                                            (parseFloat(this.props.state.monthly_excl.value) + parseFloat(this.props.state.strItemGrid.accessory_tot)).toFixed(2)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitleLeft}>{'Total VAT:'}</div>
                                                <div style={styles.fieldContentTot}>
                                                    <TextField
                                                        id={'monthly_vat'}
                                                        value={
                                                            (parseFloat(this.props.state.monthly_vat.value) + (parseFloat(this.props.state.strItemGrid.accessory_tot) * 0.15)).toFixed(2)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitleLeft}>{'Montly Total:'}</div>
                                                <div style={styles.fieldContentTot}>
                                                    <TextField
                                                        id={'monthly_incl'}
                                                        value={
                                                            (parseFloat(this.props.state.monthly_incl.value) + (parseFloat(this.props.state.strItemGrid.accessory_tot) * 1.15)).toFixed(2)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'str'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    render() {


        let data = []
        return (
            data.length
                ? (
                    <div>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    {/* <WorkflowQuoteView
                                        key={'view_' + component.name}
                                        name={component.name}
                                        workflow={workflow}
                                        step={this.props.step}
                                        data={this.data[0]}
                                    /> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                   < Button className={"global_button"} variant="contained"
                                        label={'Change'}
                                        primary={true}
                                        style={styles.right}
                                        //disabled={!component.grid.data.length}
                                        onClick={(event) => {
                                            this.props.actions.resetSelection(this.props.step, this.props.name)
                                        }}
                                        >{window.t.en('Change')}</Button>
                                </Col>
                            </Row>
                        </Container>

                        <br />
                    </div>
                )
                : (<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {this.getForm()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <STRItemGrid />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                </Container>
                )
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.captureSTR,
            workflowTaskInput: {
                ...state.workflowTaskInput
            },
            strItemGrid: {
                ...state.strItemGrid
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputActions, ...strGridItemsAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureSTR)