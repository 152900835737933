import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const gridOptions = {

}

export const highlight = colours.red[200]

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const action = {
    fontSize: '20px',
    color: colours.red[500]
}
export const chip = {
    margin: 4,
    float: 'left',
}

export const wrapper = {
    display: 'flex',
    flexWrap: 'wrap',
}

export const paper = {
    display: 'inline-block',
    display: 'flex',
    flexWrap: 'wrap',
}


export const actionHoverColor = {
    color: colours.red[500]
}

export const gridOptionsIcon = {

}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
}

export const textBox = {
    width: '100%',
    textAlign: 'right',
    float: 'right',
}

export const textBoxInput = {
    textAlign: 'right',
}

export const chipdiv = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
}

export const toggle = {
    marginBottom: 16,
    textAlign: 'centre'
}

export const popup = {
    width: '1500px',
    height: '1000px',
    padding: 0,
    margin: 0
}
export const bold = {
    fontWeight: 'bold'
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'right',
}

export const box = {
    width: '100%',
    backgroundColor: '#fffbe5',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}

export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}

export const numericFields = {
    textAlign: 'right',
}

export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '19px',
}

export const colHeader = {
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#D1E0E6',
}

export const colFooter = {
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'right',
    backgroundColor: '#D1E0E6',
}

export const box1 = {
    width: '100%',
    backgroundColor: '#f9cfcf',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}

export const redbox = {
    backgroundColor: '#cbe9f7',
   // border: '1px solid #cbe9f7',
}

export const redboxtext = {
    fontSize: '16px',
}

export const separator = {
    margin: '1px 0px',
}
export const ac = {
    paddingTop: 21,
    width: '100%',
    textAlign: 'right',
    float: 'right'
}




