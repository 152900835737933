import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Table } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import Checkbox from './checkbox'
import CardControl from './card'
import TextField from './textField'
import ReactQuill from 'react-quill'

import * as actions from './actions'
import * as styles from './styles'

class ViewSupplierTransactions extends Component {
    componentDidMount() {
        this.props.actions.getComponentSupplierTransaction(this.props.id)
    }

    getLayout() {
        return <div>
            <CardControl
                title={'Supplier Transaction'}
                subtitle={'Supplier Name'}
                content={this.getTransactions()}
                callapse={true}
            />
         </div> 
    }

    render() {
        return this.props.id ? this.getLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSupplierTransactions)