import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        // case types.RESET_COMPONENT:
        //     return {
        //         ...state,
        //         users: [],
        //         vehicles: [],
        //         supplierage: [],
        //         bookinginfo: [],
        //         selectedVehicle: [],
        //         selectedSupplier: null,
        //         vehicleserviceinfo: [],
        //         maintpdf_preview: '',
        //     }
        case types.SELECT_PURCHASE_ORDER_TYPE:
            return {
                ...state,
                fields: {
                    ...state.fields,  
                    selectedPurchaseOrderType: {
                             ...state.fields.selectedPurchaseOrderType,  
                             data: action.payload,   
            },
                    maintenanceclass:{
                        ...state.fields.maintenanceclass,
                        valid: (action.payload.text === 'Scheduled Maintenance' || action.payload.text === 'Unscheduled Maintenance') ? false : true
                    }
                }
            }
        case types.SET_USER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                users: action.payload
            }
        case types.SET_USER_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    selectedUser: {
                        ...state.selectedUser,
                        data: action.payload,
                        valid: action.payload ? true : false
                    }
                }
            }
        case types.SELECT_MAINTENANCE_CLASS_TYPE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    maintenanceclass: {
                        ...state.fields.maintenanceclass,
                        data: action.payload,
                        valid: action.payload ? true : false
                    }
                }
            }
        case types.SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                vehicles: action.payload
            }
        case types.SET_VEHICLE_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    selectedVehicle: {
                        ...state.fields.selectedVehicle,
                        data: action.payload
                    }
                }
            }
        case types.SET_COMPONENT_RECENT_MAINTENANCE_AUTHS:
            return {
                ...state,
                recent_auth: action.payload.data
            }
        case types.SET_SELECTED_VEHICLE_DETAILS:
            return {
                ...state,
                selectedVehicleDetails: {
                    ...state.selectedVehicleDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_SELECTED_SUPPLIER_DETAILS:
            return {
                ...state,
                selectedSupplierDetails: {
                    ...state.selectedSupplierDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
            case types.SET_PO_CUSTOMER_CONTACTS_AUTOCOMPLETE_DATA:
                return {
                    ...state,
                    customercontacts: action.payload
                }
            case types.SET_PO_SUPPLIER_CONTACTS_AUTOCOMPLETE_DATA:
                return {
                    ...state,
                    suppliercontacts: action.payload
                }
            case types.SET_SELECTED_PO_CUSTOMER_CONTACTS_DATA:
                return {
                    ...state,
                    selectedcustomercontacts: action.payload
                }
            case types.SET_SELECTED_PO_SUPPLIER_CONTACTS_DATA:
                return {
                    ...state,
                    selectedsuppliercontacts: action.payload
                }
        case types.SET_SUSPENDED_SUPPLIER_DETAILS:
            return {
                ...state,
                supplier: action.payload.supplier,
                selectedSupplierSuspended: {
                    ...state.selectedSupplierSuspended,
                    data: action.payload.data,
                    valid: action.payload.status,
                    suspendedreason: action.payload.suspendedreason
                }
            }
        case types.SET_GEO_MAP_SUPPLIERS:
            return {
                ...state,
                geoSuppliers: action.payload
            }
        case types.SET_VEHICLE_SERVICE_INFORMATION:
            return {
                ...state,
                vehicleserviceinfo: action.payload
            }
        case types.SET_SUPPLIER_MONTHLY_SPEND_USAGE:
            return {
                ...state,
                suppliermonthlyusage: action.payload
            }
        case types.SET_SUPPLIER_MONTHLY_OVER_THE_LIMIT:
            return {
                ...state,
                overthelimit: {
                    ...state.overthelimit,
                    value: action.payload,
                    valid: action.payload
                }
            }
        case types.TOGGLE_UPCOMING_SUPPLIER_SUSPENDED_STATUS:
            return {
                ...state,
                upcoming: {
                    upcoming: action.payload.upcoming,
                    tax: action.payload.tax,
                    bee: action.payload.bee,
                    sla: action.payload.sla
                }
            }
        case types.SET_SUPPLIER_AGE:
            return {
                ...state,
                supplierage: action.payload
            }
        case types.SET_PRE_AUTHORISATION_NUMBER:
            return {
                ...state,
                preauth: action.payload
            }
        case types.SET_PRE_TICKET_NUMBER:
            return {
                ...state,
                ticketnumber: action.payload
            }
        case types.SET_VEHICLE_CURRENT_BOOKING_INFO:
            return {
                ...state,
                bookinginfo: action.payload.bookinginfo
            }
        case types.SET_SUPPLIER_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    selectedSupplier: {
                        ...state.fields.selectedSupplier,
                        data: action.payload
                    }
                }
            }
        case types.RESET_PO_COMPOMENT_STATE:
            return {
                ...state,
                fields: props.fields
            }
        case types.SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                suppliers: action.payload
            }
        case types.SET_SUPPLIE_SUSPENDED_STATUS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    supplierStatus: {
                        ...state.supplierStatus,
                        data: action.payload,
                        valid: !action.payload
                    }
                }
            }
        case types.TOGGLE_SUPPLIE_SUSPENDED_WIN:
            return {
                ...state,
                suspended_win: action.payload
            }
        case types.SET_MAINTENANCE_CLASS_ITEMS:
            return {
                ...state,
                maintenanceclass: action.payload
            }
        case types.TOGGLE_WIN_SELECT_MAINTENANCE_TYPE:
            return {
                ...state,
                selectmaintenance_win: action.payload
            }
        case types.TOGGLE_WIN_DUPLICATE_MAINTENANCE:
            return {
                ...state,
                duplicatemaintenance_win: action.payload,
                fields: {
                    ...state.fields,
                    duplicate: {
                        ...state.fields.duplicate,
                        valid: !action.payload
                    }
                }
            }
        case types.SET_MAINTENANCE_DUPLICATED_TRANSACTION:
            return {
                ...state,
                duplicate_data: action.payload
            }
        case types.SELECT_MAINTENANCE_VEHICLE:
            return {
                ...state,
                selectedVehicle: action.payload
            }
        case types.SET_COMPONENT_PO_SUPPLIER_TYPES:
            return {
                ...state,
                suppliertypes: action.payload
            }
        case types.SELECT_MAINTENANCE_VEHICLE:
            return {
                ...state,
                selectedVehicle: action.payload
            }
        case types.SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT:
            return {
                ...state,
                maintpdf_preview: action.payload.preview,
                maintpdf_filename: action.payload.filename,
            }
        default:
            return state
    }
}