
export const cognito = {
    clientId: process.env.REACT_APP_COGNITO_CLIENT_ID_INF,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID_INF,
    region: process.env.REACT_APP_COGNITO_REGION_INF,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_INF,
    login: process.env.REACT_APP_COGNITO_LOGIN_INF
    
}

export const uri = {
    // logo_: 'visa2.jpg',
    // header_: 'visa2.jpg',
    // logo: 'bidvest-white-logo.svg',
    // header: 'bidvest-white-logo.svg',
    logo: 'INFLEET365 Logo.png',
    header: 'INFLEET365 Logo.png'
}

export const system = {
    
    authMethod: process.env.REACT_APP_ATUHMETHOD_INF,
    redirectUri: process.env.REACT_APP_AD_REDIRECT_URI_INF,
    clientId: process.env.REACT_APP_AD_CLIENT_ID_INF,
    authority: process.env.REACT_APP_AD_AUTHORITY_INF,
    version: process.env.REACT_APP_VERSION_INF,
    
    env: process.env.NODE_ENV_INF,
    locale: 'ar', //+process.env.REACT_APP_THEME,

    api: process.env.REACT_APP_API_PROTOCOL_INF + '://' + process.env.REACT_APP_API_URI_INF,
    auth: process.env.REACT_APP_API_AUTH_KEY_INF,
    textColor: 'white',  //[black,white]
    clientTheme: 'bidvest', 
    bi: {
        address: '', //process.env.REACT_APP_BI_ADDRESS,
        script: '', //process.env.REACT_APP_BI_SCRIPT,
        username: '', //process.env.REACT_APP_BI_USERNAME,
        password: '', //process.env.REACT_APP_BI_PASSWORD,
        isWindowsLogon: '', //process.env.REACT_APP_BI_WINDOWS_LOGON
    },

    route: process.env.REACT_APP_ROUTE_URI,
    nominatim: process.env.REACT_APP_NOMINATIM_URI,

    cdn: process.env.REACT_APP_CDN_ADDRESS_INF,

    recaptcha: {
        siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY_INF
    },

    messenger: {
        uri: process.env.REACT_APP_MESSENGER_PROTOCOL_INF + '://' + process.env.REACT_APP_MESSENGER_URI_INF,
    }
}


export const oq = {
    src:process.env.REACT_APP_OQ_SRC_INF,
    api_key:process.env.REACT_APP_OQ_API_KEY_INF,
    connection: process.env.REACT_APP_OQ_CONNECTION_INF,
    url:  process.env.REACT_APP_OQ_URL_INF,
    uri:  process.env.REACT_APP_OQ_URI_INF,
    address:  process.env.REACT_APP_OQ_ADDRESS_INF,
}
