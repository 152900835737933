import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import CardControl from './card'
import ReactQuill from 'react-quill'

import * as actions from './actions'
import * as styles from './styles'

class ViewSupplierSpend extends Component {
    componentDidMount() {
        this.props.actions.getComponentSupplierDate(this.props.id)
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value) ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'Not Set'
                    : ''
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }
    
    getLabel(label, value, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                <Typography variant="body1" component="div"
                    style={{  fontWeight: 'bold',fontSize: '14px' }}>
                    {this.getField(value, type)}
                </Typography>
            }
            label={
                <label style={{ width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                    {window.t.en(label)}:
                </label>
            }
            labelPlacement="start"
        />
    }

    getView() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12} style={styles.title}>
                        {'Supplier Monthly Spent Limit'}
                    </Col>
                </Row> 
                <Row style={this.props.state.supplier_in_limit ? styles.box2 : styles.box1}>
                    <Col xl={4}>
                        {this.getLabel('Monthly Limit:', (this.supplier_monthly_spend ? this.supplier_monthly_spend.spendlimit : 0), 'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Monthly Spent:', (this.supplier_monthly_spend ? this.supplier_monthly_spend.curr_usage : 0), 'numeric')}
                    </Col>
                </Row> 
                {/* <Row>
                    <Col xl={12} style={styles.title}>
                        {'Labour Rate'}
                    </Col>
                </Row>
                <Row>
                    <Col xl={4}>
                        {this.getLabel('Labour:', this.data.labourrateperhour,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Special Labour:', this.data.speciallabourrate,'numeric')}
                    </Col>
                </Row> 
                <Row>
                    <Col xl={4}>
                        {this.getLabel('In warranty :', this.data.inwarrantylabourrate,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Out Of Warranty:', this.data.outwarrantylabourrate,'numeric')}
                    </Col>
                </Row> 
                <Row>
                    <Col xl={4}>
                        {this.getLabel('SLA Rate per Hour:', this.data.slarateperhour,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('After Hours:', this.data.afterhourslabourrate,'numeric')}
                    </Col>
                </Row> 
                <Row>
                    <Col xl={4}>
                        {this.getLabel('Service Rate:', this.data.servicediscount,'numeric')}
                    </Col>
                </Row>  */}
            </Container>
         </div> 
    }

    getSuspended() {
        return <div>
        <Container style={{ margin: 0 }} className="custom-container">
                <Row className={"custom_view_text"}>
                    <Col xl={4}>
                        {this.getLabel('Suspended Status:', this.data.suspended, 'bool')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Suspended Reason:', this.data.suspendedreason)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('VAT Number:', this.data.vatnumber)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('BEE Expiry Date:', this.data.beecertificateexpdate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('SLA Expiry Date:', this.data.slaexpirydate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Tax Expiry Date:', this.data.taxclearancecertificateexpirydate, 'date')}
                    </Col>
                
                </Row> 
            </Container>
        </div> 
    }

    getSuspensionDue() {
        let sla = this.data.slqexpirydate ?  moment(this.data.slqexpirydate).diff(moment(), "days") : 0
        let bee = this.data.beecertificateexpdate ?  moment(this.data.beecertificateexpdate).diff(moment(), "days") : 0
        let tax = this.data.taxclearancecertificateexpirydate ?  moment(this.data.taxclearancecertificateexpirydate).diff(moment(), "days") : 0

        return <div>
            {
                ((sla > 0 && sla < 30) || (bee > 0 && bee < 30) || (tax > 0 && tax < 30)) && <CardControl 
                    title={'Suspension Due Soon'}
                    subtitle={'Supplier will be suspended with in 30 days due to:'}
                    content={
                        <Container style={{ margin: 0 }} className="custom-container">
                            {
                                sla > 0 && sla < 30 && <Row>
                                    <Col xl={4}>
                                        {this.getLabel('SLA in :', sla + ' days')}
                                    </Col>
                                    <Col xl={4}>
                                        {this.getLabel('SLA Expiry Date:', this.data.slqexpirydate, 'date')}
                                    </Col>
                                </Row> 
                            }
                            {
                                bee > 0 && bee < 30 && <Row>
                                    <Col xl={4}>
                                        {this.getLabel('Bee in :', bee + ' days')}
                                    </Col>
                                    <Col xl={4}>
                                        {this.getLabel('Bee Expiry Date:', this.data.beecertificateexpdate, 'date')}
                                    </Col>
                                </Row> 
                            }
                            {
                                tax > 0 && tax < 30 && <Row>
                                    <Col xl={4}>
                                        {this.getLabel('Tax in :', tax + ' days')}
                                    </Col>
                                    <Col xl={4}>
                                        {this.getLabel('Tax Expiry Date:', this.data.taxclearancecertificateexpirydate, 'date')}
                                    </Col>
                                </Row> 
                            }
                        </Container>
                    }
                />
            }
        </div> 
    }

    getLayout() {
        return <div>
            {/* {this.getSuspensionDue()} */}
            {
                !this.data.suspended
                ? <CardControl 
                    title={'Supplier'}
                    subtitle={'Supplier Name: ' + this.data.suppliername}
                    content={this.getView()}
                />
                : <CardControl error={true}
                title={'Supplier Suspended'}
                subtitle={'Supplier Name: ' + this.data.suppliername}
                content={this.getSuspended()}
            />
            }
         </div> 
    }

    render() {
        this.data = null
        this.supplierage = null
        try {
            this.data = this.props.state.supplier
            this.supplierage = this.props.state.supplier.supplierage[0]
            this.supplier_monthly_spend = this.props.state.supplier_monthly_spend[0]

            //console.log('supplier_send_view data', this.data)
            //console.log('supplier_send_view supplierage', this.supplierage)
            //console.log('supplier_send_view supplier_monthly_spend', this.supplier_monthly_spend)
        } catch (error) {
            
        }
        return this.data && this.supplierage ? this.getLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSupplierSpend)