import * as props from './props'
import * as types from './types'
import * as signedInTypes from '../SignedIn/types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_BUSINESS_CUSTOMER_HEADERS:
            return {
                ...state,
                allBusinesses: action.payload
            }
        case signedInTypes.SET_BUSINESS_CUSTOMER_HIERARCHY:
            return {
                ...state,
                allBusinesses: action.payload.allBusinesses,
                allCustomers: action.payload.allCustomers
            }
        case types.SET_BUSINESS_CUSTOMER_TREE_EXPANDED_STATE:
            return {
                ...state,
                businessCustomerTreeExpanded: action.payload
            }
        case types.SET_BUSINESS_CUSTOMER_TREE_SELECTION:
            return {
                ...state,
                businessCustomerTreeChecked: action.payload
            }
        case types.TOGGLE_BUSINESS_CUSTOMER_SELECTION:
            return {
                ...state,
                open: !state.open
            }
        default:
            return state
    }
}