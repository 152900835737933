import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Table } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import CardControl from './card'

import PdfViewer from '../components/PDF/component'

import ReactQuill from 'react-quill'

import * as actions from './actions'
import * as styles from './styles'

class ViewMaintenance extends Component {
    componentDidMount() {
        //console.log('getComponentMaintenanceData >>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
        if (this.props.id) this.props.actions.getComponentMaintenanceData(this.props.id, this.props.taskno)
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : ''
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }
    
    getLabel(label, prop, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                <Typography variant="body1" component="div"
                    style={{  fontWeight: 'bold',fontSize: '14px' }}>
                    {this.getField(this.props.state[prop], type)}
                </Typography>
            }
            label={
                <label style={{ width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                    {window.t.en(label)}:
                </label>
            }
            labelPlacement="start"
        />
    }

    getTotal(data, prop) {
        var sum = 0
        for(let x of data) {
            sum = sum + parseFloat(x[prop].toString().replace(/,/g, ''))
        }
        return this.getField(sum, 'numeric')
    }

    getView() {
        return  <Container style={{ margin: 0 }} className="custom-container">
                    {/* <Row className={"custom_view_text"}>
                    
                        {this.getLabel('Rebill:', 'rebill', 'bool')}
                        {this.getLabel('Status:', 'maintenancestatus_fktext')}
                        {this.getLabel('PreAuth Number:', 'preauthnumber')}
                        {this.getLabel('Auth Number:', 'authnumber')}
                        {this.getLabel('Created At:', 'created_at', 'datetime')}
                        {this.getLabel('Incident date:', 'incidentdate', 'datetime')}
                        {this.getLabel('Maintenance Description:', 'maintdescription')}
                        {this.getLabel('Vehicle Registration:', 'licenseplateno')}
                        {this.getLabel('Supplier Name:', 'suppliername')}
                        {this.getLabel('Vehicle Odometer:', 'odometer')}
                        {this.getLabel('Comments:', 'comments')}
                    </Row>
                    <Row>
                    <Col xl={12}>
                        <hr />
                    </Col>
                    </Row>  */}
                    {
                    this.props.state.bookedby
                    ? <Row>
                        <Col xl={12} style={styles.title}>
                            {'Maintenance Progress Steps'}
                        </Col>
                    </Row>
                    : ''
                    }
                    <Row>
                    {
                    this.props.state.bookedby
                    ? <Col xl={12}>
                    <div className="table-responsive-md">
                        <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th>Step</th>
                                    <th>Actioned By</th>
                                    <th>Actioned At</th>
                                    <th>Description</th>
                                </tr>
                                </thead>
                            <tbody style={{fontWeight: 'normal',fontSize: '14px'}}>
                                {
                                    this.props.state.bookedby && <tr>
                                        <td>{"Booking"}</td>
                                            <td>{this.props.state.bookedby}</td>
                                            <td>{this.getField(this.props.state.bookeddate, 'datetime')}</td>
                                            <td>{this.props.state.comments}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.capturedby && <tr>
                                        <td>{"Capture Quote"}</td>
                                            <td>{this.props.state.capturedby}</td>
                                            <td>{this.getField(this.props.state.capturedate, 'datetime')}</td>
                                            <td>{this.props.state.quotenumber}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.approvedby && <tr>
                                        <td>{"Tech Approval"}</td>
                                            <td>{this.props.state.approvedby}</td>
                                            <td>{this.getField(this.props.state.approvedate, 'datetime')}</td>
                                            <td>{this.props.state.approvedcomments}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.opsapprovedby && <tr>
                                        <td>{"GM Approval"}</td>
                                            <td>{this.props.state.opsapprovedby}</td>
                                            <td>{this.getField(this.props.state.opsapprovedate, 'datetime')}</td>
                                            <td>{this.props.state.opscomments}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.gmapprovedby && <tr>
                                        <td>{"COO Approval"}</td>
                                            <td>{this.props.state.gmapprovedby}</td>
                                            <td>{this.getField(this.props.state.gmapprovedate, 'datetime')}</td>
                                            <td>{this.props.state.gmcomments}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.ceoapprovedby && <tr>
                                        <td>{"CEO Approval"}</td>
                                            <td>{this.props.state.ceoapprovedby}</td>
                                            <td>{this.getField(this.props.state.ceoapprovedate, 'datetime')}</td>
                                            <td>{this.props.state.ceocomments}</td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                    </Col>
                    : ''
                    }
                    </Row>
                    {
                    this.props.state.maintenancetransactionitem
                    ? this.props.state.maintenancetransactionitem.length
                    ? <>
                    <Row>
                        <Col xl={12}>
                            <hr />
                        </Col>
                    </Row> 
                    <Row>
                        <Col xl={12} style={styles.title}>
                            {'Quoted Items'}
                        </Col>
                    </Row> 
                    <Row>
                        <Col xl={12}>
                            <div>
                            <Table className="table table-hover text-nowrap mb-0">
                                        <thead>
                                        <tr>
                                            <th>Class</th>
                                            <th>Description</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Savings</th>
                                            <th>Discount %</th>
                                            <th>Discount</th>
                                            <th>VAT %</th>
                                            <th>VAT</th>
                                            <th>Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.state.maintenancetransactionitem.map(x => {
                                                    return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                        <td>{x.itemtype}</td>
                                                        <td>{x.itemdescription}</td>
                                                        <td>{x.quantity}</td>
                                                        <td>{this.getField(x.unitprice, 'numeric')}</td>
                                                        <td>{this.getField(x.savings, 'numeric')}</td>
                                                        <td>{this.getField(x.discountpercentage, 'numeric')}</td>
                                                        <td>{this.getField(x.discountamount, 'numeric')}</td>
                                                        <td>{this.getField('15', 'numeric')}</td>
                                                        <td>{this.getField(x.vat, 'numeric')}</td>
                                                        <td>{this.getField(x.amount, 'numeric')}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>{this.getTotal(this.props.state.maintenancetransactionitem, 'unitprice')}</th>
                                            <th>{this.getTotal(this.props.state.maintenancetransactionitem, 'savings')}</th>
                                            <th></th>
                                            <th>{this.getTotal(this.props.state.maintenancetransactionitem, 'discountamount')}</th>
                                            <th></th>
                                            <th>{this.getTotal(this.props.state.maintenancetransactionitem, 'vat')}</th>
                                            <th>{this.getTotal(this.props.state.maintenancetransactionitem, 'amount')}</th>
                                        </tr>
                                        </thead>
                                    </Table>
                                </div>
                        </Col>
                    </Row>  
                    </>
                    : ''
                    : ''
                    }
                    {
                    this.props.state.maintenancedocument 
                    ? <>
                        <Row>
                            <Col xl={12}>
                                <hr />
                            </Col>
                        </Row>    
                        <Row>
                            <Col xl={12} style={styles.title}>
                                {'Maintenance Documents'}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {
                                    this.props.state.maintenancedocument 
                                    ?  <PdfViewer
                                        key={'quotation_viewer_' + this.props.id}
                                        id={'quotation_viewer_' + this.props.id}
                                        documents={this.props.state.maintenancedocument.sort((x, y) => x.maintenancedocument_id - y.maintenancedocument_id).map(x => {
                                            return {
                                                filename: x.document.filename,filetype: x.document.description
                                            }})
                                        }
                                        multiple={false}
                                        height={600}
                                        edit={false}
                                    />
                                    : ''
                                }
                            </Col>
                        </Row>
                    </>
                    : ''
                    }                
                    </Container>

    }

    getLayout() {
        return this.props.state.bookedby ? <div>
            <CardControl 
                title={'Maintenance Details  ' + (this.props.state.authnumber ? this.props.state.authnumber : '')}
                subtitle={''}
                content={this.getView()}
                callapse={true}
            />
            {/* <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={'Maintenance Details  ' + (this.props.state.authnumber ? this.props.state.authnumber : '')}
                    subtitle={''}
                >
                </CardHeader>
                <CardContent>
                      </CardContent>
            </Card> */}
         </div> : ''
    }

    render() {
        return this.props.state ? this.getLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls.maintenance
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewMaintenance)