export const selectedPurchaseOrderType =  {
    value: 4,
    text: 'De-fleet',
    key: 'vehicle_defleet',
    types: []
}

export const purchaseOrderTypes = [
    {
        value: 1,
        text: 'Assessor',
        key: 'assessor',
        types: []
    },
    // {
    //     value: 2,
    //     text: 'Corporate Vehicle',
    //     key: 'corporate_vehicle',
    //     types: []
    // },
    // {
    //     value: 3,
    //     text: 'FML Transnet',
    //     key: 'fml_transnet',
    //     types: []
    // },
    {
        value: 4,
        text: 'De-fleet',
        key: 'vehicle_defleet',
        types: []
    },
    {
        value: 5,
        text: 'Panel Beaters',
        key: 'panel_beatears',
        types: []
    },
    {
        value: 6,
        text: 'Repairs',
        key: 'repairs',
        types: []
    },
    {
        value: 7,
        text: 'Service',
        key: 'service',
        types: []
    },
    // {
    //     value: 8,
    //     text: 'STR Transnet / Corporate',
    //     key: 'str_transnet_corporate',
    //     types: []
    // },
    // {
    //     value: 9,
    //     text: 'Subscriptions',
    //     key: 'subscriptions',
    //     types: []
    // },
    {
        value: 10,
        text: 'Towing',
        key: 'towing',
        types: []
    },
    {
        value: 11,
        text: 'Tyres',
        key: 'tyres',
        types: []
    }
]

export const users = []

export const selectedUser = {
    errorText: 'Copy selection field required',
    data: [],
    valid: false,
    empty: true,
}

export const selectedVehicleDetails = {
    errorText: 'Please select vehicle from the list',
    data: [],
    valid: false,
    empty: true,
}

export const selectedSupplierDetails = {
    errorText: 'Please select supplier from the list',
    data: [],
    valid: false,
    empty: true,
}

export const selectedSupplierSuspended = {
    errorText: 'Selected supplier is suspended please select another supplier',
    data: [],
    valid: false,
    empty: true,
}
export const overthelimit = {
    errorText: 'Selected supplier is over the limit, please another supplier',
    data: [],
    valid: false,
    empty: true,
}
export const vehicles = []
export const supplierage = []
export const bookinginfo = []
export const selectedVehicle = []
export const preAuth = null
export const geoSuppliers = null
export const vehicleserviceinfo = []
export const recent_auth = []
export const maintpdf_preview = ''
export const maintpdf_filename = ''
export const suspended_win = false

export const supplierAutoCompleteAmount = 1

export const supplierAutoCompletes = [
    {
        id: 0,
        data: [],
        selected: []
    }
]


export const validation = {
    fields: ['selectedSupplierDetails'],
    component: 'defleetCaptureQuote',
    list: [],
    valid: false
}

export const servicedate = {
    errorText: 'Service date field required',
    value: '',
    valid: true,
    empty: true,
}
export const servicedescription = {
    errorText: 'Service descriptoin field required',
    value: '',
    valid: true,
    empty: true,
}
export const serviceinterval = {
    errorText: 'Next service interval field required',
    value: '',
    valid: true,
    empty: true,
}
export const millage = {
    errorText: 'Please enter current vehicle odometer',
    value: '',
    valid: true,
    empty: true,
}
export const comments = {
    errorText: 'Please enter comments',
    value: '',
    valid: true,
    empty: true,
}
export const drivername = {
    errorText: 'Driver name field required',
    value: '',
    valid: true,
    empty: true,
}
export const tyrescount = {
    errorText: 'Tyre count required',
    value: '',
    valid: true,
    empty: true,
}
export const quotenumber = {
    errorText: 'Enter qoute number',
    value: '',
    valid: true,
    empty: true,
}
export const days = {
    errorText: 'Enter booking days',
    value: null,
    valid: true,
    empty: true,
}

export const incidentdate = {
    errorText: '',
    value: null,
    valid: true,
    empty: true,
}
export const drivable = {
    errorText: '',
    value: null,
    valid: true,
    empty: true,
}
export const mechanicalcheck = {
    errorText: '',
    value: null,
    valid: false,
    empty: true,
}
export const quotedate = {
    errorText: 'Enter qoute date',
    value: '',
    valid: true,
    empty: true,
}

export const suppliers = []

export const selectedSupplier = {
    errorText: 'Please select supplier from the list',
    data: null,
    valid: false,
    empty: true,
}

export const upcoming = {
    upcoming: false,
    tax: false,
    bee: false,
    sal: false,
}

export const fields = {
    selectedSupplier: {
        state: 'true',
        errorText: 'Please select supplier from the list',
        data: null,
        valid: true,
        empty: true,
    },
    supplierStatus: {
        state: 'true',
        errorText: 'Selected supplier is suspended',
        data: null,
        valid: true,
        empty: true,
    },
    supplier_over_limit: {
        state: 'true',
        errorText: 'Selected supplier have exceeded its monthly spent',
        state: 'getState().controls.supplier_in_limit',
        data: false,
        valid: true,
        empty: true,
        popup: true,
    },
    mechanicalcheck: {
        errorText: 'Please select Mechanical Check Checkbox',
        state: 'getState().defleetCaptureQuote.mechanicalcheck.value',
        data: '',
        valid: false,
        empty: true,
    },
    days : {
        errorText: 'Please enter Repair Days',
        state: 'getState().defleetCaptureQuote.days.value',
        validations : [{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please enter a valid number of Repair Days'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please enter valid number of Repair Days Odo that is greater than zero.'
        }],
        value: null,
        valid: true,
        empty: true,
    }
}

