import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent, IconButton, Dialog, Button } from '@mui/material'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import * as helper from '../../helpers/components'
import Icon from '../Icon/component'
import SurrogateGrid from '../SurrogateGrid/component'
import SurrogateView from '../SurrogateView/component'

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RelatedGrid from '../RelatedGrid/component'
import RelatedView from '../RelatedView/component'
import Loading from '../Loading/component'

import Section from '../../controls/section'
import Oqlis from '../Oqlis/component'
import DateControl from '../../controls/dateControl'
import AuditView from '../../controls/AuditView'
import ViewLabel from '../../controls/viewlabel'
import * as actions from '../Form/actions'
import * as viewActions from './actions'
import * as styles from './styles'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'



class View extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.loadComponent(this.props.name, this.props.id, this.props.stop, this.props.surrogate)
    }

    getFieldValue(field, index) {
        if(!this.component.data) return ''
        let value = ''
      
        if(field.name === 'tyresizerear') {
        }
        try {
            value = this.component.data[field.name] 
                ? field.display ? this.component.data[field.display.split('.')[0]][field.display.split('.')[1]] : this.component.data[field.name].input 
                : field.display ? this.component.data[field.display.split('.')[0]][field.display.split('.')[1]] : ''
      
        } catch (error) {
            value = ''
            //this.component.data[field.name] 
            //    ? this.component.data[field.name].input 
            //    : field.display ? this.component.selected[0].original[field.display.split('.')[0]][field.display.split('.')[1]] : ''
        
        }
        // //console.log('getFieldValue',this.component.data[field.name])
        if (this.component.data[field.name] && typeof this.component.data[field.name].input === 'object') {
            const child = this.props.state.components[field.name]

            switch (field.component_field_display_type.name) {
                case 'autoComplete':
                    // return field.component_reference
                    // ? <ViewLabel value={helper.getComponentTitle(child, value)}
                    //     info={{
                    //         show: true,
                    //         component: field.component_reference.name,
                    //         title: field.component_reference.title,
                    //         field: field.name,
                    //         value: value
                    //     }} />
                    // : 
                    return value
                case 'datePicker':
                case 'timePicker':
                    return value !== null
                        ? moment(value).format('YYYY-MM-DD hh:mm') // + ', ' + moment(value).fromNow()
                        : 'Not Set'
                case 'toggle':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value
                    return value !== null
                        ? value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'html':
                    return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
                case 'image':
                    return <a href={"data:image/jpeg;base64," + value} download><img src={"data:image/jpeg;base64," + value} style={{ height: '500px',width: '500px' }} /></a>
                case 'textBox':
                    //EUGENE NEEDS TO CHECK THIS, NOT SUPPOSED TO BE TEXT BOX RELATED HERE....
                    return helper.getComponentTitle(child, value)
                default:
                    return ''
            }
        }
        if(field.name.indexOf('') >= 0 && field.component_reference){
            return value
            //  <ViewLabel value={value}
            // component_reference={field.component_reference.name}
            // info={{
            //     show: true,
            //     component: field.component_reference.name,
            //     title: field.component_reference.title,
            //     field: field.name,
            //     value: value
            // }} />
        }
        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                // return field.component_reference
                // ? <ViewLabel value={value}
                // info={{
                //     show: true,
                //     component: field.component_reference.name,
                //     title: field.component_reference.title,
                //     field: field.name,
                //     value: value
                // }} />
                // : 
                return value 
            case 'datePicker':
            case 'timePicker':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD hh:mm') //+ ', ' + moment(value).fromNow()
                    : 'Not Set'
            case 'textBox':
                return field.component_field_data_type.name === 'decimal' || field.component_field_data_type.name === 'float'
                    ? value !== null && value.length > 0 && !isNaN(value)
                        ? parseFloat(value).toFixed(2)
                        : value
                    : value
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            case 'image':
                return <a href={"data:image/jpeg;base64," + value} download><img src={"data:image/jpeg;base64," + value} style={{ height: '500px',width: '500px' }} /></a>
            case 'toggle':
                const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value
                return value !== null
                    ? value
                        ? toggle[0]
                        : toggle[1]
                    : 'Not Set'
            default:
                return value
        }
    }

    getField(field, index) {
        let value = this.getFieldValue(field, index)
        return value ? value : 'Not Set'
    }

    getRelatedGrid(field, readOnly) {
        //console.log('getRelatedGrid field',field)
        let _field = this.props.state.component.component_field.filter(x => x.key === true)[0]
        //console.log('getRelatedGrid _field',_field)
        let value = null
        
        try {
            value = this.component.data[_field.name].input
        } catch (error) {
            
        }
        
        //console.log('getRelatedGrid value',value)
        return value ? <RelatedGrid
            readonly={readOnly}
            // id={value ? value : 0}
            // key={field.title + "_" + value}
            // name={field.title}
            // expanded={true}
            // stop={true}
            // component={field.title}
            // model={{
            //     id: value,
            //     model: field.display.split('.')[0],
            //     related: field.display.split('.').length === 1 ? '' : field.display.split('.')[1],
            //     component: field.display.split('.').length === 1 ? field.display.split('.')[0] : field.display.split('.')[1],
            //     primary: this.props.state.component.name
            // }}
            id={field.name}
            key={field.name}
            fieldname={field.name}
            name={field.title}
            expanded={true}
            stop={true}
            component={field.title}
            model={{[field.name]: {
                id: value,
                name: field.title,
                component_title: field.title,
                model: field.display.split('.')[0],
                related: field.display.split('.').length === 1 ? '' : field.display.split('.')[1],
                component: field.display.split('.').length === 1 ? field.display.split('.')[0] : field.display.split('.')[1],
                primary: this.props.state.component.name
            }}}
            data={[]}
        /> : ''
    }
    getAuditSection() {
        let _field = this.props.state.component.component_field.filter(x => x.key === true)[0]
        let value =  this.component.data[_field.name].input
        console.log('getAuditSectionx name', _field.name)
        console.log('getAuditSectionx data', this.component.data)
        console.log('getAuditSectionx value', value)
        return (
            <div>
                <AuditView id={value} pk={value} component={this.props.state.component.name} /><br />
            </div>
        );
    }
    
    getSection(section, index) {
        const fields = !section.feature
            ? section.component_field
                .filter(x => !x.key && x.view)
                .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
                .reduce((arr, x, i) => {
                    
                    if(x.component_field_display_type.name === 'grid' && !this.props.surogate){
                        arr.push(<Col key={'col_' + x.name} xl={12}>{this.getRelatedGrid(x, true)}</Col>)
                    }
                    else { 
                    arr.push(
                            <Col className={'custom-col-label'} xl={4}>
                                    <FormControlLabel
                                        fullWidth={true}
                                        control={
                                            <Typography variant="body1" component="div"
                                            style={{fontSize: '14px', fontWeight: '500', textAlign: 'left', width: '100%', fontWeight: 800, padding: '5px 0px'}}>
                                                {this.getField(x, i)} 
                                                {
                                                    x.infoview && x.infoview.indexOf('.') >= 0
                                                    ? <IconButton touch={true} style={{ padding: 0 }}
                                                        onClick={() => {
                                                            const value = this.component.data[x.infoview.split('.')[1]].input
                                                            this.props.actions.toggleInfoView(true, value, x.infoview.split('.')[0],x.hint)
                                                        }}>
                                                        <PlayArrowIcon />
                                                    </IconButton>
                                                    : ''
                                                }
                                            </Typography>
                                        }
                                        label={
                                            <div style={{fontSize: '14px', width: '300px', textAlign: 'left', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
                                                {window.t.en(x.title.length > 40 ? x.title.substring(0,40).concat('...') : x.title)}{": "}
                                            </div>  
                                        }
                                        labelPlacement="top"
                                    />
                            </Col>
                    )
                     }
                    return arr
                }, [])
            : section.component_field
                .filter(x => !x.related && !x.key)
                .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
                .reduce((arr, x, i) => {
                    if(x.component_field_display_type.name === 'grid' && !this.props.surogate){
                        arr.push(<Col key={'col_' + x.name} xl={12}>{this.getRelatedGrid(x, true)}</Col>)
                    }
                    else{ 
                        if (this.component.data[x.name] && this.component.data[x.name].input !== -1) {
                            arr.push(
                                    <Col xl={6}>
                                        {window.t.en(x.title)}
                                        {this.component.data[x.name].input === 1 ? 'Yes' : 'No'}
                                    </Col>
                            )
                        }
                    }

                    return arr
                }, [])

                //console.log("fields", JSON.stfields)
        return <div style={{boxShadoww: '0 2px 15px 0 rgb(32 40 45 / 13%)'}}>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}><br/></Col>
                </Row>
                <Row>
                    <Section title={window.t.en(section.description)}  />
                </Row>
                <Row>
                    <Col xl={12}>&nbsp;</Col>
                </Row>
                <Row>
                    {fields}
                </Row>
            </Container><br/>
        </div>
    }

    getSectionView() {
        return this.component.data
            ? this.component.sections
                .sort((x, y) => x.order - y.order)
                .map((x, i) => {
                    return this.getSection(x, i)
                })
            : 'Loading...'      
    }

    getSurrogateView(filter, component, key, id) {
        return <SurrogateView
            id={id}
            //name={component.name}
            name={filter}
            expanded={true}
            stop={true}
            component={filter}
        />
    }

    getSurrogateGrid(filter, component, key, id, data) {
        return <SurrogateGrid
            id={id}
            name={component.name}
            expanded={true}
            stop={true}
            component={component}
            data={data}
        />
    }

    getInforView() {
        return <div>
        <Dialog
            open={this.props.state.infoview}
            style={{ width: '100% !important', maxWidth: '100% !important' }}
            fullWidth={true}
            maxWidth="lg"
            >
            <DialogTitle id="scroll-dialog-title">{this.props.state.infoview_title}</DialogTitle>
            <DialogContent dividers={true}>
                <RelatedView
                            key={'readComponent_View_' + this.props.state.infoview_id}
                            id={this.props.state.infoview_id}
                            name={this.props.state.infoview_name}
                            expanded={true}
                            surogate={true}
                            surrogate={false}
                            component={{
                                name: this.props.state.infoview_title,
                                title: this.props.state.infoview_title,
                                description: this.props.state.infoview_title,
                    }}
                /> 
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={(event) => {
                        event.preventDefault();
                        this.props.actions.closeInfoView(false)
                        //this.props.actions.toggleInfoView(false,null,null,null)
                    }}>
                    Close
                </Button>
            </DialogActions>
           
        </Dialog>
    </div> 
    }

    getSectionRelatedComponents() {
        if (!this.component.data) {
            return []
        }

        if (this.component.stop) {
            return []
        }

        if (this.component.view === null) {
            return []
        }

        const relations = this.component.view.split(',')

        return relations.map(x => {
            let surrogate = false
            let relation, parent, child

            if (x.indexOf('.') > -1) {
                relation = x.split('.')
                parent = relation[0]
                child = relation[1]

                if (!this.component.data[parent]) {
                    return []
                }

                surrogate = true
            }
            else {
                if (!this.component.data[x]) {
                    return []
                }
            }

            x = surrogate ? parent : x

            //console.log('surrogate ? child : x',surrogate ? child : x)
            const component = this.props.state.components[surrogate ? child : x]
            //console.log('component',component)
            if(!component.component_field)
                return <></>
                
            const key = component.component_field.filter(y => y.key)[0]
            let id = surrogate ? undefined : this.component.data[x][key.name]

            if (!id) {
                const pkey = this.component.component_field.filter(y => y.key)[0]

                const val = this.component.data[pkey.name].input
                //console.log(val)
                id = val
            }
            let data = surrogate ? _.get(this.component.data, x) : []

            const surrogateData = data.map(y => {
                return _.get(y, child)
            })

            return surrogate
                ? this.getSurrogateGrid(x, component, key, id, surrogateData)
                : this.getSurrogateView(x, component, key, id)
        })
    }

    getStats() {
        return <Card key={'card_stats'} initiallyExpanded={true}> {/* initiallyExpanded={index === 0} */}
            <CardHeader
                avatar={<Icon iclass={'material-icons'} iname={'data_usage'} />}
                title={window.t.en('Vehicle Stats')}
                subtitle={window.t.en('')}
                actAsExpander={true}
                showExpandableButton={true}
                style={styles.row}
                action={
                    <IconButton touch={true} style={{float: 'right', color: '#fff'}}
                    onClick={() => {
                        this.props.actions.toggleSectionInfosheet(!this.props.state.section_infosheet)
                    }}>
                        {this.props.state.section_infosheet ? <ExpandLess style={{color: '#fff'}} /> : <ExpandMore style={{color: '#fff'}} />}
                    </IconButton>
                }
            />
            <CardContent expandable={true}>
                {
                    this.props.state.section_infosheet 
                    ? <Container style={{ margin: 0 }} className="custom-container">
                            <Row className={'custom_view_text'}>
                                <Col xl={6}>
                                    <br/>
                                   <h2>Vehicle Expense DashBoard</h2> 
                                </Col>
                                <Col xl={3}>
                                   
                                    <DateControl
                                        id={'filter_date_from'}
                                        key={'filter_date_from'}
                                        name={'filter_date_from'}
                                        label={window.t.en('Select Date From')}
                                        style={{float: 'right'}}
                                        //field={field}
                                        value={this.props.state.date_from}
                                        onSelect={(args) => {
                                            //console.log('args date', args)
                                            this.props.actions.setComponentInputValue({prop: 'date_from', value: args})
                                        }}
                                        type={'date'}
                                    /> 
                                    </Col>   
                                <Col xl={3}>
                                    <DateControl
                                        id={'filter_date_to'}
                                        key={'filter_date_to'}
                                        name={'filter_date_to'}
                                        label={window.t.en('Select Date To')}
                                        style={{float: 'right'}}
                                        //field={field}
                                        value={this.props.state.date_to}
                                        onSelect={(args) => {
                                            //console.log('args date', args)
                                            this.props.actions.setComponentInputValue({prop: 'date_to', value: args})
                                        }}
                                        type={'date'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                            <Col  xl={12}><hr />
                            </Col></Row>
                            <Row className={'custom_view_text'}>
                                <Col xl={12}>
                                    <Oqlis
                                        displayType={this.props.state.display_type}
                                        oq_key={this.props.state.infosheet_key + '&filters=vehicle_id=' + this.props.id + ' and \"Date\" between \'' + moment(this.props.state.date_from ? this.props.state.date_from : new Date()).format('YYYY-MM-DD') + '\' and \'' + moment(this.props.state.date_to ? this.props.state.date_to : new Date()).format('YYYY-MM-DD') + '\''}
                                        oq_theme={""}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    : <></>
                }  
            </CardContent>
        </Card>
    }

    getLayout() {
        return this.props.state.section_infosheet
            ? this.getStats()
            : [
                this.getSectionView(),
                this.getAuditSection()
            ]
    }
    render() {
        this.component = this.props.state.component

        return this.component.loading
            ? <Loading message={'Retrieving component data...'} />
            : this.props.surogate ? this.getSectionView() : this.getLayout() 
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.view,
            component: {
                ...state.components[ownProps.name]
            },
            components: {
                ...state.components
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...viewActions, ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(View)