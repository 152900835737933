import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_DEFLEET_COMPOMENT_STATE:
            return {
                ...state,
                selectedDefleetType: {
                    value: null,
                    text: null,
                    key: null,
                    types: []
                },
                selectedDefleetReason: {
                    value: null,
                    text: null,
                    key: null,
                    types: []
                },
                defleetrequestdate : '',
                defleetrequesttime : null,
                comments: '',
                sight_of_payment:false,
                fields: props.fields
            }


        case types.SET_DEFLEET_VALIDATION_VALUE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: {
                        ...state.fields[action.payload.prop],
                        data: action.payload.value,
                        valid: action.payload.value ? true : false
                    }
                }
            }
        case types.SELECT_DEFLEET_TYPE:
            return {
                ...state,
                selectedDefleetType: action.payload,
                supplierAutoCompletes: [{
                    id: 0,
                    data: [],
                    selected: []
                }]
            }
        case types.SELECT_DEFLEET_REASON:
            return {
                ...state,
                selectedDefleetReason: action.payload,
                supplierAutoCompletes: [{
                    id: 0,
                    data: [],
                    selected: []
                }]
            }
        case types.SET_DEFLEET_TYPES:
            return {
                ...state,
                selectedDefleetType: {
                    value: null,
                    text: null,
                    key: null,
                    types: []
                }
            }
        case types.SET_DEFLEET_REASON:
            return {
                ...state,
                selectedDefleetReason: {
                    value: null,
                    text: null,
                    key: null,
                    types: []
                }
            }
        case types.SET_DEFLEET_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_DEFLEET_VEHICLE_LIST_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                vehicles: action.payload
            }
        case types.SET_DEFLEET_TYPES_LIST:
            return {
                ...state,
                defleetTypes: action.payload
            }
        case types.SET_VEHICLE_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                selectedVehicle: action.payload
            }
        case types.SET_COMPONENT_RECENT_MAINTENANCE_AUTHS:
            return {
                ...state,
                recent_auth: action.payload.data
            }
        case types.SET_SELECTED_VEHICLE_DETAILS:
            return {
                ...state,
                selectedVehicleDetails: {
                    ...state.selectedVehicleDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_SELECTED_VEHICLE_CONTRACT:
            return {
                ...state,
                selectedVehicleContractAccessories: {
                    ...state.selectedVehicleContractAccessories,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_SELECTED_SUPPLIER_DETAILS:
            return {
                ...state,
                selectedSupplierDetails: {
                    ...state.selectedSupplierDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_SUSPENDED_SUPPLIER_DETAILS:
            return {
                ...state,
                selectedSupplierSuspended: {
                    ...state.selectedSupplierSuspended,
                    data: action.payload.data,
                    valid: action.payload.status
                }
            }
        case types.SET_GEO_MAP_SUPPLIERS:
            return {
                ...state,
                geoSuppliers: action.payload
            }
        case types.SET_VEHICLE_SERVICE_INFORMATION:
            return {
                ...state,
                vehicleserviceinfo: action.payload
            }
        case types.SET_SUPPLIER_AGE:
            return {
                ...state,
                supplierage: action.payload
            }
        case types.SET_DEFLEET_NUMBER:
            return {
                ...state,
                defleetNum: action.payload
            }
        case types.SET_TASK_NUMBER:
            return {
                ...state,
                taskNum: action.payload
            }
        case types.SET_VEHICLE_CURRENT_BOOKING_INFO:
            return {
                ...state,
                bookinginfo: action.payload.bookinginfo
            }
        case types.SET_SUPPLIER_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                supplierAutoCompletes: state.supplierAutoCompletes.map(x => {
                    if (x.id === action.payload.id) {
                        x.selected = action.payload.request
                    }

                    return x
                })
            }
        case types.SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                supplierAutoCompletes: state.supplierAutoCompletes.map(x => {
                    if (x.id === action.payload.id) {
                        x.data = action.payload.data
                    }

                    return x
                })
            }
        case types.ADD_SUPPLIER_AUTOCOMPLETE:
            return {
                ...state,
                supplierAutoCompletes: state.supplierAutoCompletes.concat({
                    id: state.supplierAutoCompletes.length,
                    data: [],
                    selected: []
                })
            }
        case types.REMOVE_SUPPLIER_AUTOCOMPLETE:
            return {
                ...state,
                supplierAutoCompletes: action.payload > 0
                    ? state.supplierAutoCompletes.filter(x => x.id !== action.payload)
                    : state.supplierAutoCompletes
            }
        case types.UPDATE_ACCESSORIES:
            return {
                ...state,
                accessories: action.payload
            }
        default:
            return state
    }
}