import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Table } from "@mui/material";

import AutoComplete from '../../controls/autocomplete'
// import TextField from '@mui/material/TextField'
import TextField from '../../controls/textField'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import ViewCustomer from '../../controls/view_customer'

import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowPOAccessoriesComponent extends Component {

    componentDidMount() {
        try {
            let data = this.props.workflow_queue_data.context_data.data.data.data[0]
        
            //console.log('Accessory Supplierx data', data)
            this.props.actions.setInputValue({prop: 'accessory_list', value: data.vehicle.accessory_list})
            this.props.actions.setInputValue({prop: 'service_list', value: data.vehicle.service_list})
            let i = 0
            for(let x of data.vehicle.accessory_list){
                this.props.actions.setListValue({data: 'accessory_list', prop: 'supplier', value: x.supplierpricing ? x.supplierpricing.supplier.suppliername : '', inx: i})
                this.props.actions.setListValue({data: 'accessory_list', prop: 'supplier_id', value: x.supplierpricing ? x.supplierpricing.supplier.supplier_id : '', inx: i})
                i = i + 1
            }
            i = 0
            for(let x of data.vehicle.service_list){
                this.props.actions.setListValue({data: 'service_list', prop: 'supplier', value: x.supplierpricing ? x.supplierpricing.supplier.suppliername : '', inx: i})
                this.props.actions.setListValue({data: 'service_list', prop: 'supplier_id', value: x.supplierpricing ? x.supplierpricing.supplier.supplier_id : '', inx: i})
                i = i + 1
            }
        }
        catch (err) { }
    }

    getContract(data) {
        return <Card 
                title={window.t.en('Quote Confirmation')}
                subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
                content={
                    <Fields nocard={true}
                        fields={[
                        {size: 4, label: 'Contract Type:', value: data.contractname},
                        {size: 4, label: 'Vehicle Category:', value: data.customerderivativecategory},
                        {size: 4, label: ' Monthly Distance:', value: data.distancepermonth},
                        {size: 4, label: 'Contract Period:', value: data.contractperiod},
                        {size: 4, label: 'Quote For:', value: data.quotefor},
                        {size: 4, label: 'RFQ Date:', value: data.quotedate},
                        {size: 4, label: 'Vehicle Color:', value: data.color},
                        {size: 4, label: 'Vehicle Location:', value: data.location},
                        {size: 4, label: 'Special  Vehicle:', value: data.specialistvehicle},
                        {size: 4, label: 'Vehicle Group:', value: data.vehiclegroup},
                        {size: 4, label: 'Operating Terrain:', value: data.terrain},
                        {size: 4, label: 'Vehicle Usage:', value: data.usage},
                        {size: 4, label: 'Comments:', value: data.comments},
                    ]}
                />
                }
            />
    }

    getCustomer(customer) {
        let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        return <Card 
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                    <ViewCustomer id={customer.customer_id} nocard={true} min={true} />
                    // <Fields nocard={true}
                    //     fields={[
                    //         { size: 4, label: 'Customer Name:', value: customer.customername },
                    //         { size: 4, label: 'Cost Centre:', value: customer.costcentre },
                    //         { size: 4, label: 'Account Number:', value: customer.accountnumber },
                    //         { size: 4, label: 'Contact Person Name:', value: contact.fullname },
                    //         { size: 4, label: 'Contact Number:', value: contact.mobile },
                    //         { size: 4, label: 'Contact Email:', value: contact.email },
                    //     ]}
                    // />
                }
            />
    }

    getSupplier(data) {
        return <Card 
                title={window.t.en('Supplier Details')}
                subtitle={window.t.en('Selected Vehicle Supplier ')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Supplier Name:', value: data.suppliername},
                            {size: 4, label: 'Trading Name:', value: data.tradingnamename},
                            {size: 4, label: 'Account Number:', value: data.accountnumber},
                            {size: 4, label: 'VAT Number:', value: data.vatnumber},
                            {size: 4, label: 'Contact Person:', value: data.contactperson},
                            {size: 4, label: 'Contact Email:', value: data.contactemail},
                            {size: 4, label: 'Contact Number:', value: data.contactnumber},
                        ]}
                    />
                }
            />
    }

    getVehicle(data) {
        return <Card 
                title={window.t.en('Accepted Quote ' + data.quotenumber)}
                subtitle={window.t.en('Customer Quote Accepted')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Quote Number:', value: data.quotenumber},
                            {size: 4, label: 'System PO Number:', value: data.ponumber},

                            {size: 12, header: 'Vehicle Details'},
                            {size: 4, label: 'Vehicle Make:', value: data.mmcode.make},
                            {size: 4, label: 'Vehicle Model:', value: data.mmcode.cdescription},

                            {size: 4, label: 'MM Code:', value: data.mmcode.mmcode},
                            {size: 4, label: 'Transmission:', value: data.mmcode.manualauto},

                            {size: 4, label: 'Introduction Year:', value: data.mmcode.regyear},
                            {size: 4, label: 'Body Type:', value: data.mmcode.bodytype},

                            {size: 4, label: 'Contract Type:', value: data.contractname},
                            {size: 4, label: 'Vehicle Category:', value: data.categorydescription},

                            {size: 4, label: 'Monthly Distance', value: data.distancepermonth},
                            {size: 4, label: 'Contract Period:', value: data.contractperiod},

                            {size: 4, label: 'Rate Card:', value: data.ratecard},
                            {size: 4, label: 'Delivery Period:', value: data.deliveryperiod},
                            
                            {size: 12, header: 'Rental Breakdown'},
                            {size: 4, label: 'Retail Price:', value: data.retailprice},
                            {size: 4, label: 'Discount:', value: data.discount},

                            {size: 4, label: 'Interest Rate:', value: data.primerate},
                            {size: 4, label: 'Vehicle Price:', value: data.vehicleprice},

                            {size: 12, header: 'Monthly Rental'},
                            {size: 4, label: 'Rental Excl:', value: data.monthly_excl},

                            {size: 4, label: 'Vat:', value: data.monthly_vat},
                            {size: 4, label: 'Rental Incl:', value: data.monthly_incl},
                        ]}
                    />
                }
            />
    }

    getAccessories(data) {
        return this.props.state.accessory_list && this.props.state.accessory_list.length ? <Card 
                title={window.t.en('Accessory')}
                subtitle={window.t.en('Select Accessory Supplier')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.selectedFieldName}>
                                {'Accessory Name'}
                            </Col>
                            <Col xl={3} style={styles.selectedFieldName}>
                                {'Price Expiry Date'}
                            </Col>
                            <Col xl={3} style={styles.selectedFieldName}>
                                {'Accessory Cost Price'}
                            </Col>
                            <Col xl={3} style={styles.selectedFieldName}>
                                {'Accessory Supplier'}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.selectedFieldName}>
                                <br/>
                            </Col>
                        </Row>
                        {
                            this.props.state.accessory_list.map((x, i) => {
                                //console.log('Accessory Supplierx', x)
                                return <Row>
                                    <Col xl={3}>
                                        {x.accessory}
                                    </Col>
                                    <Col xl={3} >
                                        {x.supplierpricing ? moment(x.supplierpricing.expirydate).format('YYYY-MM-DD') : ''}
                                    </Col>
                                    <Col xl={3} style={{marginTop:'-5px'}}>
                                        <TextField
                                            variant="outlined" 
                                            id={'txtaccessoryname_' + x.accessory_id}
                                            //label={'Enter Updated Total Price'}
                                            disabled={true}
                                            value={x.costprice}
                                            style={styles.numericFields}
                                            inputStyle={styles.numericFields}
                                            onChange={(event, value) => {
                                                this.props.actions.setListValue({data: 'accessory_list', prop: 'costprice', value: event.target.value, inx: i})
                                            }}
                                        />
                                    </Col>
                                    <Col xl={3} style={{marginTop:'-15px'}}>
                                        <AutoComplete
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'accessory_id_' + x.accessory_id}
                                            key={'accessory_key_' + x.accessory_id}
                                            //hintText={'Select ' + x.accessory + ' Supplier '}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            fieldvalue={'supplier_id'}
                                            displayfields={['suppliername']}
                                            disabled={true}
                                            value={x.supplier}
                                            data={this.props.state.supplier_data}
                                            onSelect={
                                                (args) => {
                                                    this.props.actions.setListValue({data: 'accessory_list', prop: 'supplier', value: args.text, inx: i})
                                                    this.props.actions.setListValue({data: 'accessory_list', prop: 'supplier_id', value: args.value, inx: i})
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getLookupData('supplier', 'suppliername', '')
                                                }
                                            }
                                            onFilter={
                                                (args) => {
                                                    this.props.actions.getLookupData('supplier', 'suppliername', args)
                                                }
                                            }
                                        />
                                    </Col>
                                    
                                    <Col xl={12}><br/></Col>
                                </Row>
                            })
                        }
                    </Container>
                }
            /> : ''
    }

    getServices(data) {
        return this.props.state.service_list && this.props.state.service_list.length ? <Card 
            title={window.t.en('Service')}
            subtitle={window.t.en('Select Service Supplier')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.selectedFieldName}>
                            {'Service Name'}
                        </Col>
                        <Col xl={3} style={styles.selectedFieldName}>
                            {'Price Expiry Date'}
                        </Col>
                        <Col xl={3} style={styles.selectedFieldName}>
                            {'Service Cost Price'}
                        </Col>
                        <Col xl={3} style={styles.selectedFieldName}>
                            {'Service Supplier'}
                        </Col>
                    </Row>
                    {
                        this.props.state.service_list.map((x, i) => {
                        
                            return <Row>
                                <Col xl={3} style={styles.fieldLabelAL}>
                                    {x.service}
                                </Col>
                                <Col xl={3} style={styles.fieldLabelAL}>
                                {x.supplierpricing ? moment(x.supplierpricing.expirydate).format('YYYY-MM-DD') : ''}
                                </Col>
                                <Col xl={3}>
                                    <TextField
                                        variant="outlined"
                                        id={'txtservicename_' + x.service_id}
                                        //={'Enter Updated Total Price'}
                                        fullWidth={true}
                                        disabled={true}
                                        value={x.costprice}
                                        style={styles.numericFields}
                                        inputStyle={styles.numericFields}
                                        onChange={(event, value) => {
                                            this.props.actions.setListValue({data: 'service_list', prop: 'costprice', value: event.target.value, inx: i})
                                        }}
                                    />
                                </Col>
                                <Col xl={3}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        id={'accessory_id_' + x.service_id}
                                        key={'accessory_key_' + x.service_id}
                                        //hintText={'Select ' + x.service + ' Supplier '}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        fieldvalue={'supplier_id'}
                                        displayfields={['suppliername']}
                                        disabled={true}
                                        value={x.supplier}
                                        data={this.props.state.supplier_data}
                                        onSelect={
                                            (args) => {
                                                this.props.actions.setListValue({data: 'service_list', prop: 'supplier', value: args.text, inx: i})
                                                this.props.actions.setListValue({data: 'service_list', prop: 'supplier_id', value: args.value, inx: i})
                                            }
                                        }
                                        onClick={
                                            () => {
                                                this.props.actions.getLookupData('supplier', 'suppliername', '')
                                            }
                                        }
                                        onFilter={
                                            (args) => {
                                                this.props.actions.getLookupData('supplier', 'suppliername', args)
                                            }
                                        }
                                    />
                                </Col>
                            <Col xl={12}><br/></Col>
                            </Row>
                        })
                    }
                </Container>
            }
        /> : ''
    }
    
    getView(data) {
        return <div>
                {this.getContract(data.vehicle)}
                {this.getCustomer(data.customer)}
                {this.getSupplier(data.supplier)}
                {this.getVehicle(data.vehicle)}
                {this.getAccessories(data.vehicle.accessory_list)}
                {this.getServices(data.vehicle.service_list)}
        </div>
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log('WorkflowPOAccessories workflow_queue_data', workflow_queue_data)
        let data = null
        try {
            data = this.props.workflow_queue_data.context_data.data.data.data[0]
        }
        catch (err) { }
        
        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowPOAccessories
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOAccessoriesComponent)