
//console.log('process.env.NODE_ENVx', process.env.REACT_APP_NODE_ENV)
//console.log('process.env.NODE_ENVx all', process.env)


import * as local from './config/local/config.js'
import * as visa_qa from './config/visa_qa/config.js'
import * as nexxt_prod from './config/nexxt_prod/config.js'
import * as visa_prod from './config/visa_prod/config.js'
import * as bidvest_uat from './config/bidvest_uat/config.js'
import * as office_uat from './config/office_uat/config.js'
import * as bidvest_prod from './config/bidvest_prod/config.js'

console.log('conf env',process.env.REACT_APP_NODE_ENV)  

let _oq = null
let _uri = null
let _system = null
let _cognito = null

switch (process.env.REACT_APP_NODE_ENV) {
    case 'local':
            _oq = {...local.oq}
            _uri = {...local.uri}
            _system = {...local.system}
            _cognito = {...local.cognito}
        break;
    case 'visa_qa':
            _oq = {...visa_qa.oq}
            _uri = {...visa_qa.uri}
            _system = {...visa_qa.system}
            _cognito = {...visa_qa.cognito}
        break;
    case 'office_uat':
            _oq = {...office_uat.oq}
            _uri = {...office_uat.uri}
            _system = {...office_uat.system}
            _cognito = {...office_uat.cognito}
        break;
    case 'nexxt_prod':
            _oq = {...nexxt_prod.oq}
            _uri = {...nexxt_prod.uri}
            _system = {...nexxt_prod.system}
            _cognito = {...nexxt_prod.cognito}
        break;
    case 'visa_prod':
            _oq = {...visa_prod.oq}
            _uri = {...visa_prod.uri}
            _system = {...visa_prod.system}
            _cognito = {...visa_prod.cognito}
        break;
        case 'bidvest_uat':
            _oq = {...bidvest_uat.oq}
            _uri = {...bidvest_uat.uri}
            _system = {...bidvest_uat.system}
            _cognito = {...bidvest_uat.cognito}
        break;
        case 'bidvest_prod':
            _oq = {...bidvest_prod.oq}
            _uri = {...bidvest_prod.uri}
            _system = {...bidvest_prod.system}
            _cognito = {...bidvest_prod.cognito}
        break;        
}

//console.log('process.env.NODE_ENVx _uri', _uri)
//console.log('process.env.NODE_ENVx _system', _system)

console.log('conf _cognito',_cognito) 
console.log('conf _system',_system) 
console.log('conf _uri',_uri)  

export const oq = _oq
export const uri = _uri
export const system = _system
export const cognito = _cognito
