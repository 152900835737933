
export const defleetpdf_preview = ''
export const defleetpdf_filename = ''
export const location = {
    errorText: 'Location is required',
    value: null,
    valid: false,
    empty: true,
}
export const checksheet_completedby = {
    errorText: 'Please enter Check Sheet Completed',
    value: null,
    valid: false,
    empty: true,
}

export const checksheet_completedat = {
    errorText: 'Please select Check Sheet Completion Date',
    value: null,
    valid: false,
    empty: true,
}

export const selectedSupplierDetails = {
    errorText: 'Please select supplier from the list',
    data: [],
    valid: false,
    empty: true,
}
export const selectedVehicleDetails = {
    errorText: 'Please select vehicle from the list',
    data: [],
    valid: false,
    empty: true,
}
export const has_damages = {
    errorText: '',
    value: true,
    valid: false,
    empty: true,
}
export const comments = {
    errorText: '',
    value: null,
    valid: false,
    empty: true,
}
export const fuelamount = {
    errorText: '',
    value: null,
    valid: false,
    empty: true,
}
export const locations = {
    data: [
        {
            text: 'Loading...', value: 'Loading...'
        }
    ]
}
export const odoreading = {
    errorText: 'Please enter Odo Reading',
    value: null,
    valid: false,
    empty: true,
}
export const defleetdate = {
    errorText: 'Please select De-fleet Date',
    value: null,
    valid: false,
    empty: true,
}
export const has_sparekey = {
    errorText: '',
    value: false,
    valid: false,
    empty: true,
}
export const correctdocument_type = {
    errorText: 'Please confirm that you selected the correct document type(s)',
    value: false,
    valid: false,
    empty: true,
}
export const defleet_date_match = {
    errorText: "Please confirm that the De-fleet Date matches the De-fleet Check-In Date as per Check Sheet",
    value: false,
    valid: false,
    empty: true,
}


export const fields = {
  
    defleet_date_match: {
        errorText: "Please confirm that the De-fleet Date matches the De-fleet Check-In Date as per Check Sheet",
        state: 'getState().defleetUploadChecksheet.defleet_date_match.value',
        value: null,
        valid: false,
        empty: true,
    }, 
    documents: {
        errorText: 'Please add a document and select document type',
        data: false,
        valid: false,
        empty: true,
        popup: true,
        type: 'documents'
      },
      correctdocument_type: {
        errorText: 'Please confirm that you selected the correct document type(s)',
        state:'getState().defleetUploadChecksheet.correctdocument_type.value',
        value: null,
        valid: false,
        empty: true,
    },
    fuelamount: {
        errorText: 'Please enter Fuel Amount',
        state: "getState().workflowTaskApproval.workflow_queue.data[0].context_data.data.data.data[0].step1.data.selectedVehicleDetails.data[0].customercontract.find(c => c.licenseplateno === getState().workflowTaskApproval.workflow_queue.data[0].context_data.data.data.data[0].step1.data.selectedVehicleDetails.data[0].licenseplateno).contracttype_fktext?.indexOf('Short') >= 0 ? getState().defleetUploadChecksheet.fuelamount.value : 'skipAdditionalValidations'",
        value: null,
        valid: false,
        empty: true,
        validations : [{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please enter a valid Fuel Amount'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please enter a valid Fuel Amount that is greater than zero.'
        }]
    },
    has_damages : {
        errorText: 'Please select does a vehicle have any damages',
        state: '!!getState()?.defleetUploadChecksheet?.has_damages?.value || true',
        value: true,
        valid: true,
        empty: true,
    },
    odoreading: {
        errorText: 'Please enter Odo Reading',
        state: "getState().defleetUploadChecksheet.odoreading.value",
        value: null,
        valid: false,
        empty: true,
        validations : [{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please enter a valid Odo Reading'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please enter a valid Odo Reading that is greater than zero.'
        }]
    },
    location: {
        errorText: "Please select a Location",
        state : 'getState().defleetUploadChecksheet.location.value',
        value: null,
        valid: false,
        empty: true,
    },
    checksheet_completedby: {
        errorText: 'Check Sheet Completed By is required',
        state: 'getState().defleetUploadChecksheet.checksheet_completedby.value',
        value: null,
        valid: false,
        empty: true,
    },
    checksheet_completedat: {
        errorText: 'Check Sheet Completion Date is required',
        state: 'getState().defleetUploadChecksheet.checksheet_completedat.value',
        value: null,
        valid: false,
        empty: true,
    },
    defleetdate: {
        errorText: 'Please select a De-fleet Date',
        state:'getState().defleetUploadChecksheet.defleetdate.value',
        value: null,
        valid: false,
        empty: true,
    }
}

