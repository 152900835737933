export const chipRow = {
    display: 'table',
    margin: 5
}

export const chip = {
    width: 285
}

export const chipContainer = {
    display: 'table-cell',
    width: 300,
    verticalAlign: 'middle'
}

export const chipDescription = {
    display: 'table-cell',
    verticalAlign: 'middle'
}

export const fullWidth = {
    width: '100%',
}

export const inputWidth = {
    width: '100%',
    fontSize: '16px',
}

export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '20px',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '19px',
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid #253053',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'right',
    fontWeight: 'bold'
}
export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}
export const numericFields = {
    textAlign: 'right',
}
export const whitediv = {
    backgroundColor: '#fff',
    padding: 2,
    verticalAlign: 'left'
}
export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 2,
    verticalAlign: 'left'
}
export const selectedFieldName = {
    fontWeight: 'bold'
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const boxDefault = {
    textAlign: 'left'
}

export const boxError = {
    width: '100%',
    backgroundColor: '#cbe9f7',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '2px',
    textAlign: 'left',
}