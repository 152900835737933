// import * as types from './types'
// import * as workflowTaskInputTypes from '../WorkflowTaskInput/types'
// import * as formActions from '../Form/actions'

import moment from 'moment'
import validator from '../../validate'
import get from 'lodash/get'

import * as appTypes from '../App/types'
import * as types from './types'
import * as readComponentActions from '../ReadComponent/actions'
import { getComponentTitle, getComponentDescription } from '../../helper';


export const getComponentFieldLookupData = (field, searchText, dataSource, params) => {
    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const payload = {
            component: component,
            field: field,
            /*             related: field.related
                            ? field.display.split('.')[0]
                            : 'all', */
            query: searchText.length > 0 ? '?' + field.search + '=' + searchText : '',
            text: searchText
        }

        dispatch({
            type: types.GET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component.name,
                field: field.name,
                data: data
            }
        })
    }
}


export const confirmSelection = (step, component) => {
    return (dispatch, getState) => {
        window.glyco.log('confirmSelection')
        window.glyco.log(getState().components[component.name].grid.data)

        dispatch({
            type: types.CONFIRM_QUOTE_ITEMS_SELECTION,
            payload: {
                step: step,
                component: component.name,
                selected: getState().components[component.name].grid.data
            }
        })
    }
}

export const resetSelection = (step, component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: []
            }
        })
    }
}


export const getContractTypeComponent = (component, customer_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_QUOTE_TYPES_LOOKUP_DATA,
            payload: {
                query: '', //'?customer_id=' + customer_id,
                component: component
            }
        })
    }
}

export const getVehicleGroupComponents = (component, contracttype_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_QUOTE_VEHICLE_GROUP_LOOKUP_DATA,
            payload: {
                query: '?contracttype_id=' + contracttype_id,
                component: component
            }
        })
    }
}

export const getVehicleMMCodeComponents = (component, contractvehiclecategory_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_QUOTE_VEHICLE_MMCODE_LOOKUP_DATA,
            payload: {
                query: '?contractvehiclecategory_id=' + contractvehiclecategory_id,
                component: component,
                related: 'mmcode'
            }
        })
    }
}

export const setContractTypeComponent = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTE_TYPES_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setVehicleGroupComponents = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTE_VEHICLE_GROUP_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setVehicleMMCodeComponents = (payload) => {
    return (dispatch, getState) => {
        const data = {
            component: getState().components['mmcode'],
            data: payload.data.data.map(x => { return x.mmcode })
        }

        dispatch({
            type: types.SET_QUOTE_VEHICLE_MMCODE_LOOKUP_DATA,
            payload: data
        })
    }
}

export const setQuoteTypesSelection = (component, req) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTE_TYPES_LOOKUP_SELECTION,
            payload: {
                component: component,
                field: 'contracttype_id',
                value: req.contracttype_id
            }
        })
        dispatch({
            type: types.SET_QUOTE_TYPES_LOOKUP_SELECTION,
            payload: {
                component: component,
                field: 'contracttype',
                value: req.contracttype
            }
        })
    }
}


export const setVehicleGroupSelection = (component, req) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTE_VEHICLE_GROUP_LOOKUP_SELECTION,
            payload: {
                component: component,
                field: 'categorydescription',
                value: req.categorydescription
            }
        })
        dispatch({
            type: types.SET_QUOTE_VEHICLE_GROUP_LOOKUP_SELECTION,
            payload: {
                component: component,
                field: 'contractvehiclecategory_id',
                value: req.contractvehiclecategory_id
            }
        })
    }
}

export const setVehicleMMCodeSelection = (component, req) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTE_VEHICLE_MMCODE_LOOKUP_SELECTION,
            payload: {
                component: component,
                field: 'mmcode',
                value: req.mmcode
            }
        })
        dispatch({
            type: types.SET_QUOTE_VEHICLE_MMCODE_LOOKUP_SELECTION,
            payload: {
                component: component,
                field: 'mmcode_id',
                value: req.mmcode_id
            }
        })
    }
}

export const laodSelectedData = (step, component) => {
    return (dispatch, getState) => {
        const data = getState().workflowTaskInput.task.workflow_task_step
            .filter(x =>
                x.name === step &&
                x.workflow_task_step_component.filter(y => y.component.name === component.name).length
            )
            .reduce((arr, x) => {
                if (x.workflow_task_step_component.filter(y => y.component.name === component.name && y.data.length).length) {
                    arr.push(x.workflow_task_step_component.filter(y => y.component.name === component.name && y.data.length)[0].data)
                }

                return arr
            }, [])

        if (data.length) {

            const payload = {
                component: component.name,
                data: data
            }

            dispatch({
                type: types.ACC_COMPONET_GRID_SELECTED_DATA,
                payload: payload
            })
        }
    }
}
// export const confirmSingleRow = (step, name) => {
//     const getRowData = (component) => {
//         return component.component_field.reduce((obj, x) => {
//             obj[x.name] = component.data[x.name].input
//             return obj
//         }, {})
//     }

//     return (dispatch, getState) => {
//         dispatch(formActions.validateForm(name))

//         const component = getState().components[name]

//         Object.keys(component.data).filter(x => !component.data[x].validation.valid).length
//             ? dispatch({
//                 type: workflowTaskInputTypes.SHOW_COMPONENT_ERRORS,
//                 payload: [component]
//             })
//             : dispatch({
//                 type: types.CONFIRM_SINGLE_ROW,
//                 payload: {
//                     step: step,
//                     component: name,
//                     row: getRowData(component)
//                 }
//             })
//     }
// }

// export const removeSingleRow = (step, component, index) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.REMOVE_SINGLE_ROW,
//             payload: {
//                 step: step,
//                 component: component,
//                 index: index
//             }
//         })
//     }
// }

// export const setCreditorSelectFieldInput = () => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.EXPAND_CREDITOR_VIEW,
//             payload: {
//                 step: step,
//                 component: component,
//                 index: index
//             }
//         })
//     }
// }

// export const setDebtorSelectFieldInput = () => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.EXPAND_DEBTOR_VIEW,
//             payload: {
//                 step: step,
//                 component: component,
//                 index: index
//             }
//         })
//     }
// }