import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import { Table } from '@mui/material'
import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"
import "react-table/react-table.css"

import Icon from '../Icon/component'
import Theme from '../../theme'

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
// import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'

import Card from '../../controls/card'
import DateControl from '../../controls/dateControl'
import TextField from '../../controls/textField'
import AutoComplete from '../../controls/autocomplete'

import * as actions from './actions'
import * as styles from './styles'
import ViewSupplier from '../../controls/view_supplier'

import MenuItem from '@mui/material/MenuItem';

import * as strActions from '../WorkflowContractRestructure/ContractSelectionGrid/actions'



class WorkflowQuoteItemGridComponent extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = { counter: 0 };
    }

    updateCounter = () => {
        this.setState({ counter: this.state.counter + 1 })
    }

    componentDidMount() {
        this.props.actions.loadChipAccessory()
    }
    getCommands(row) {
        let commands = []
        commands.push(
            <IconButton key={'command_remove_' + row.viewIndex} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.deleteItemRow(row.viewIndex)
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
            </IconButton>
        )
        return commands
    }

    getValue(field, row) {
        switch (field.type) {
            case 'checkbox':
                return <Checkbox
                    id={field.name + '_' + row.viewIndex}
                    key={field.name + '_' + row.viewIndex + '_' + this.state.counter}
                    checked={this.props.state.data[row.viewIndex][field.name]}
                    onChange={(event, checked) => {
                        this.props.actions.setGridFieldValue(field.name, checked, row.viewIndex)
                    }} />
            case 'textbox':
                return <TextField
                    variant="outlined"
                    id={field.name + '_' + row.viewIndex}
                    key={field.name + '_' + row.viewIndex + '_' + this.state.counter}
                    style={{ padding: '18.5px 14px !important' }}
                    inputStyle={{ padding: '18.5px 14px !important' }}
                    value={this.props.state.data[row.viewIndex][field.name]}
                    onChange={
                        (event) => {
                            if (field.name === 'distance') {
                                this.props.actions.setGridFieldValue(field.name, event.target.value, row.viewIndex)
                                this.props.actions.setInputValue({ prop: field.name, value: event.target.value })
                            } else {
                                this.props.actions.setGridFieldValue(field.name, event.target.value, row.viewIndex)
                            }
                            if (field.name === 'distance') {
                                this.props.actions.setGridFieldValue('supplier', '', row.viewIndex)
                                this.props.actions.setGridFieldValue('supplier_id', null, row.viewIndex)
                                this.props.actions.setInputValue({ prop: 'supplieraccessorypricing_data', value: [] })
                                this.props.actions.setGridFieldValue('supplieraccessory_data', [], row.viewIndex)
                                this.props.actions.setGridFieldValue('supplier_data', [], row.viewIndex)
                                this.props.actions.setGridFieldValue('supplieraccessorypricing_data', [], row.viewIndex)
                                this.props.actions.setGridFieldValue('quantity', 0, row.viewIndex)
                                this.props.actions.setGridFieldValue('costprice', '', row.viewIndex)
                                this.props.actions.setGridFieldValue('sellingprice', '', row.viewIndex)
                                this.props.actions.setGridFieldValue('accessory', '', row.viewIndex)
                                this.props.actions.setGridFieldValue('accessory_id', null, row.viewIndex)
                                this.props.actions.setGridFieldValue('suppliersaccessoryhistory_data', [], row.viewIndex)
                            }


                        }
                    }
                />
            case 'calculated':
                return <TextField
                    variant="outlined"
                    id={field.name + '_' + row.viewIndex}
                    key={field.name + '_' + row.viewIndex + '_' + this.state.counter}
                    style={{ padding: '18.5px 14px !important' }}
                    inputStyle={{ padding: '18.5px 14px !important' }}
                    value={this.props.state.data[row.viewIndex][field.name]}
                    disabled={true}
                />
            case 'autocomplete':
                return <AutoComplete
                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                    popoverProps={{ style: { overflow: 'auto', width: 'auto' } }}
                    id={field.name + '_' + row.viewIndex}
                    key={field.name + '_' + row.viewIndex + '_' + this.state.counter}
                    hintText={'Select ' + field.title}
                    filter={AutoComplete.fuzzyFilter}
                    openOnFocus={true}
                    fullWidth={true}
                    // altRendering
                    // displayfields={[`${field.field}`]}
                    // fieldtext={[`${field.field}`]}
                    // fieldvalue={this.props.state.data[row.viewIndex][field.name]}
                    data={this.props.state[field.component + '_data'] ? this.props.state[field.component + '_data'] : []}
                    value={this.props.state.data[row.viewIndex][field.name]}
                    onSelect={(args) => {
                        this.updateCounter()
                        this.props.actions.setGridFieldValue(field.name, args.text.toLowerCase(), row.viewIndex)
                        this.props.actions.setGridFieldValue(field.name + '_id', args.value, row.viewIndex)
                        if (field.name === 'supplier') {
                            this.props.actions.getSupplierDistanceData(args.value, this.props.state.distance, row.viewIndex)
                        }
                    }}
                    onClick={() => {
                        this.props.actions.getLookupData(field.component, field.field, '', field.query)
                    }}

                    onFilter={(args) => {
                        if (args) {
                            this.props.actions.getLookupData(field.component, field.field, args, field.query)
                            this.props.actions.setGridFieldValue(field.name, args.toLowerCase(), row.viewIndex)

                            this.props.actions.setGridFieldValue('supplier', '', row.viewIndex);
                            this.props.actions.setGridFieldValue('supplier_id', null, row.viewIndex);

                            this.props.actions.setGridFieldValue('quantity', 0, row.viewIndex);
                            this.props.actions.setGridFieldValue('costprice', 0, row.viewIndex);
                            this.props.actions.setGridFieldValue('sellingprice', 0, row.viewIndex);

                            this.props.actions.setGridFieldValue('accessory', '', row.viewIndex);
                            this.props.actions.setGridFieldValue('accessory_id', null, row.viewIndex);
                            this.props.actions.setGridFieldValue('sundry', '', row.viewIndex);
                            this.props.actions.setGridFieldValue('sundry_id', null, row.viewIndex);
                            this.props.actions.setGridFieldValue('service', '', row.viewIndex);
                            this.props.actions.setGridFieldValue('service_id', null, row.viewIndex);
                        } else {
                            this.props.actions.setGridFieldValue(field.name, '', row.viewIndex)
                            this.props.actions.setGridFieldValue(field.name + '_id', null, row.viewIndex)

                            this.props.actions.setInputValue({ prop: 'supplieraccessorypricing_data', value: [] })
                            this.props.actions.setGridFieldValue('suppliersacessoryhistory_data', [], row.viewIndex);
                            this.props.actions.setGridFieldValue('supplieraccessory_data', [], row.viewIndex);

                            this.props.actions.setInputValue({ prop: 'suppliersundrypricing_data', value: [] })
                            this.props.actions.setGridFieldValue('suppliersundrypricing_data', [], row.viewIndex);

                            this.props.actions.setInputValue({ prop: 'supplierservicepricing_data', value: [] })
                            this.props.actions.setGridFieldValue('supplierservicepricing_data', [], row.viewIndex);

                            this.props.actions.setGridFieldValue('quantity', 0, row.viewIndex);
                            this.props.actions.setGridFieldValue('costprice', 0, row.viewIndex);
                            this.props.actions.setGridFieldValue('sellingprice', 0, row.viewIndex);
                            this.props.actions.setGridFieldValue('accessory', '', row.viewIndex);
                            this.props.actions.setGridFieldValue('accessory_id', null, row.viewIndex);
                            this.props.actions.setGridFieldValue('sundry', '', row.viewIndex);
                            this.props.actions.setGridFieldValue('sundry_id', null, row.viewIndex);

                            if (field.name === 'itemtype') {
                                this.updateCounter()
                                this.props.actions.setGridFieldValue('supplier', '', row.viewIndex);
                                this.props.actions.setGridFieldValue('supplier_id', null, row.viewIndex);
                            }
                            if (field.name === 'supplier') {
                                this.updateCounter()
                                this.props.actions.getSupplierDistanceData(null, this.props.state.distance, row.viewIndex)
                            }

                            this.props.actions.getLookupData(field.component, field.field, '', field.query)
                        }
                    }}
                />
            case 'items':
                let _field = field[row['itemtype']] ? field[row['itemtype']] : ''
                let _fields = _field.split('.')
                let _component = _fields.length > 1 ? _fields[0] : ''

                let displayfields = _field ? _fields[2].split(',').map(fi => { return fi.includes('#') ? fi.split('#')[1] : fi }) : ''
                return <AutoComplete
                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                    popoverProps={{ style: { overflow: 'auto', width: 'auto' } }}
                    id={field.name + '_' + row.viewIndex + '_' + this.state.counter}
                    key={field.name + '_' + row.viewIndex + '_' + this.state.counter}
                    hintText={'Select ' + field.title}
                    filter={AutoComplete.fuzzyFilter}
                    openOnFocus={true}
                    fullWidth={true}
                    fieldvalue={_component + '_id'}
                    displayfields={displayfields ? displayfields : ''}
                    // altRendering
                    descfields={'expirydate'}
                    displaywidth={['40', '40', '20']}
                    data={this.props.state[_component + '_data'] ? this.props.state[_component + '_data'] : []}
                    value={this.props.state.data[row.viewIndex][row['itemtype']] ? this.props.state.data[row.viewIndex][row['itemtype']] : null}
                    errorText={'Err'}
                    onSelect={(args) => {
                        //console.log('descfields args',args.value)
                        //console.log('descfields data',this.props.state[_component + '_data'].filter(x => x.supplieraccessorypricing_id == args.value))
                        try {
                            let _list = this.props.state[_component + '_data'] ? this.props.state[_component + '_data'] : []
                            let _data = _list.length ? _list.filter(x => x[_component + '_id'] === args.value) : []
                            let isAfter = false
                            //console.log('Accessory IDxx',_data[0]['accessory_id'])
                            //console.log('descfields expirydate data',_data)
                            //console.log('descfields expirydate',_data[0]['expirydate'])
                            if (_data[0]['expirydate']) {
                                isAfter = moment().isAfter(_data[0]['expirydate'], 'day')
                            }
                            if (isAfter) {
                                this.props.actions.showNotification(moment(_data[0]['expirydate']).format('YYYY-MM-DD'))
                                this.props.actions.setGridFieldValue(row['itemtype'], null, row.viewIndex)
                                this.props.actions.setGridFieldValue(row['itemtype'] + '_id', null, row.viewIndex)
                                this.props.actions.setGridFieldValue('quantity', 0, row.viewIndex)
                                this.props.actions.setGridFieldValue('costprice', 0, row.viewIndex)
                                this.props.actions.setGridFieldValue('sellingprice', 0, row.viewIndex)
                                this.props.actions.deleteItemRow(row.viewIndex)

                            } else {
                                this.props.actions.setGridFieldValue('quantity', 1, row.viewIndex)
                                this.props.actions.setGridFieldValue('costprice', _data.length ? _data[0]['costprice'] : 0, row.viewIndex)
                                this.props.actions.setGridFieldValue('sellingprice', _data.length ? _data[0]['sellingprice'] : 0, row.viewIndex)
                                this.props.actions.setGridFieldValue('supplierpricing', _data.length ? _data[0] : {}, row.viewIndex)

                                this.props.actions.setGridFieldValue(row['itemtype'], args.text, row.viewIndex)
                                this.props.actions.setGridFieldValue(row['itemtype'] + '_id', args.value, row.viewIndex)
                                    (_data[0]['accessory_id'] && (this.props.actions.getSupplierDataAccessoryData(_data[0]['accessory_id'], row.viewIndex)))
                                    //console.log('supplierdistancedataxxxx state',this.props.state)
                                    //console.log('supplierdistancedataxxxx _data',this.props.state.supplierdistance_data)
                                    //console.log('supplierdistancedataxxxx _data',_data[0]['supplier_id'])
                                    (_data[0]['supplier_id'] && _data[0]['accessory_id'] && (this.props.actions.getSupplierAccessoryHistoryData(_data[0]['supplier_id'], _data[0]['accessory_id'], row.viewIndex)))
                                let sp_data = this.props.state.supplierdistance_data.filter(x => x.supplier_id == _data[0]['supplier_id'])
                                this.props.actions.setGridFieldValue('suppliersdistance_data', sp_data, row.viewIndex)
                                this.props.actions.setGridFieldValue('accessoryhistory_id', _data[0]['accessory_id'], row.viewIndex)
                                this.props.actions.setGridFieldValue('supplierhistory_id', _data[0]['supplier_id'], row.viewIndex)
                            }

                        }
                        catch (err) { }
                    }}
                    onClick={() => {
                        try {
                            this.props.actions.getLookupItemsData(field[row['itemtype']], '')

                        }
                        catch (err) { }
                    }}
                    onFilter={(args) => {
                        try {
                            if (args) {
                                this.props.actions.getLookupItemsData(field[row['itemtype']], args)
                            } else {
                                this.updateCounter()
                                this.props.actions.setInputValue({ prop: 'supplieraccessorypricing_data', value: [] })
                                this.props.actions.setGridFieldValue('suppliersacessoryhistory_data', [], row.viewIndex);
                                this.props.actions.setGridFieldValue('supplieraccessory_data', [], row.viewIndex);

                                this.props.actions.setInputValue({ prop: 'suppliersundrypricing_data', value: [] })
                                this.props.actions.setGridFieldValue('suppliersundrypricing_data', [], row.viewIndex);

                                this.props.actions.setInputValue({ prop: 'supplierservicepricing_data', value: [] })
                                this.props.actions.setGridFieldValue('supplierservicepricing_data', [], row.viewIndex);

                                this.props.actions.setGridFieldValue('quantity', 0, row.viewIndex);
                                this.props.actions.setGridFieldValue('costprice', 0, row.viewIndex);
                                this.props.actions.setGridFieldValue('sellingprice', 0, row.viewIndex);
                                this.props.actions.setGridFieldValue('accessory', '', row.viewIndex);
                                this.props.actions.setGridFieldValue('accessory_id', null, row.viewIndex);
                                this.props.actions.setGridFieldValue('sundry', '', row.viewIndex);
                                this.props.actions.setGridFieldValue('sundry_id', null, row.viewIndex);
                                this.props.actions.getLookupItemsData(field[row['itemtype']], '')
                            }
                            if (this.props.state.supplieraccessorypricing_data.length > 0) {
                                this.props.actions.setInputValue({ prop: 'supplieraccessorypricing_data', value: [] })
                                this.props.actions.setGridFieldValue('quantity', 0, row.viewIndex)
                                this.props.actions.setGridFieldValue('costprice', 0, row.viewIndex)
                                this.props.actions.setGridFieldValue('sellingprice', 0, row.viewIndex)
                                this.props.actions.setGridFieldValue('accessory', '', row.viewIndex)
                                this.props.actions.setGridFieldValue('accessory_id', null, row.viewIndex)
                                this.props.actions.setGridFieldValue('suppliersaccessoryhistory_data', [], row.viewIndex)
                            }
                        }
                        catch (err) { }
                    }}
                />
        }
    }

    getColumns(component) {
        const fields = this.props.state.fields

        let columns = fields.map(x => {
            return {
                meta: x.name,
                Header: window.t.en(x.title),
                accessor: x.display,
                filterable: false,
                width: x.width ? x.width : undefined,
                headerStyle: {
                    fontSize: '12px',
                    fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                    textAlign: 'left'
                    //backgroundColor: x.highlight ? styles.highlight : '',
                },
                style: {
                    verticalAlign: 'middle',
                    //backgroundColor: x.highlight ? styles.highlight : '',
                    fontSize: 12,
                    letterSpacing: 0,
                    color: 'black', //'#4A4A4A',
                    textAlign: 'left',
                    fontWeight: x.bold ? 'bold' : 'normal',
                    // textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                    //     ? 'right'
                    //     : 'left'
                },
                Cell: (row) => (
                    this.getValue(x, row)
                ),
                Footer: (
                    this.getFooter(x)
                )
            }
        })

        if (!this.props.task) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 50,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        return columns
    }


    getFooter(field) {
        const tot = _.sumBy(this.props.state.data, (d) => { return parseFloat((d.totalprice) ? d.totalprice.toString().replace(/,/gi, '') : 0) })
        return field.name === 'totalprice'
            ? <span>
                <strong>{'Total: '}</strong>
                {parseFloat(tot.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
            : <span></span>
    }

    getGrid() {
        return <div>
            <Button className={"global_button"} variant="contained"
                label={'Add Item'}
                primary={true}
                onClick={(event) => {
                    this.props.actions.addBlankRow()
                }}
            >{window.t.en('Add Item')}</Button>

            <div className="cis-table1"><div className="table-responsive-md" id="cis-table1"></div>
                <Table className="table table-borderless  mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: '25px' }} className="text-uppercase bg-secondary"></th>
                            {
                                this.props.state.fields.map(x => {
                                    return <th style={{ width: x.width + 'px' }} className="text-uppercase bg-secondary">{x.title}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.state.data.map((x, i) => {
                                return <>
                                    <tr>
                                        <td>
                                            <IconButton key={'command_remove_' + i} style={styles.iconButton} iconStyle={styles.action}
                                                onClick={
                                                    () => {
                                                        this.props.actions.deleteItemRow(i)
                                                    }
                                                }>
                                                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                            </IconButton>
                                        </td>
                                        {
                                            this.props.state.fields.map(f => {
                                                return <td>{this.getValue(f, { ...x, viewIndex: i })}</td>
                                            })
                                        }
                                    </tr>
                                    {x.supplieraccessory_data && x.supplieraccessory_data.length > 0 && (
                                        <tr style={{ fontWeight: 'normal', fontSize: '14px', background: '#fff' }}>
                                            <td colSpan={4} style={{ background: '#e5f9ed' }}>
                                                Approximate distance from the Location selected to the supplier <b>{x.suppliersdistance_data ? x.suppliersdistance_data[0].supplier_name : ''}</b> is <b>{x.suppliersdistance_data ? x.suppliersdistance_data[0].distance.toFixed(2) : ''}KMs</b>
                                            </td>
                                            <td colSpan={2} style={{ background: '#fffbf2' }}>
                                                Item <b>{x.supplierpricing ? x.supplierpricing.accessoryname : ''}</b> pricing history
                                            </td>
                                            <td colSpan={3} style={{ background: '#e5f9ed' }}>
                                                Maximum Price: {x.supplieraccessory_data ? x.supplieraccessory_data.max_price : ''} At {x.supplieraccessory_data[0] ? x.supplieraccessory_data[0].max_supplier_name : ''}
                                            </td>
                                            <td colSpan={3} style={{ background: '#fffbf2' }}>
                                                Minimum Price: {x.supplieraccessory_data ? x.supplieraccessory_data.min_price : ''} At At {x.supplieraccessory_data[0] ? x.supplieraccessory_data[0].min_supplier_name : ''}
                                            </td>
                                        </tr>
                                    )}
                                    {x.suppliersaccessoryhistory_data && x.suppliersaccessoryhistory_data.length > 0 && (<tr>
                                        <td colSpan={12} style={{ background: '#fffbf2' }}>
                                            <div className="table-responsive-md">
                                                <Table className="table table-hover text-nowrap mb-0" style={{ width: '100% !important' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '20%' }}>Cost Price</th>
                                                            <th style={{ width: '10%' }}>Selling Price</th>
                                                            <th style={{ width: '10%' }}>Expiry Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            x.suppliersaccessoryhistory_data.map(sh => {
                                                                return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                                                    <td>{sh.costprice}</td>
                                                                    <td>{sh.sellingprice}</td>
                                                                    <td>{sh.expirydate}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </td>
                                    </tr>)}

                                </>
                            })
                        }
                    </tbody>
                </Table>

            </div>
            {/* 
            <ReactTable
                key={'workflow_grid_items'}
                data={this.props.state.data}
                columns={this.getColumns()}
                filterable={false}
                //loading={component.grid.loading}
                manual
                // onFetchData={
                //     (state) => {
                //         this.props.actions.getComponentGridData(component.name, this.props.link, state, this.props.filter, this.props.id)
                //     }
                // }
                getTrProps={(state, rowInfo) => {
                    return {
                        // onClick: (event) => {
                        //     //this.props.actions.selectGridRow(component, rowInfo)

                        //     if (this.props.toggle) {
                        //         this.props.actions.toggleBottomSheet()
                        //     }
                        // },
                        style: {
                            // background: component.grid.selected
                            //     ? component.grid.selected.filter(x => x.index === rowInfo.index)[0] ? Theme.palette.primary1Color : 'white'
                            //     : 'white',
                            // color: component.grid.selected
                            //     ? component.grid.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            //     : 'black'
                        }
                    }
                }}
                minRows={1}
                showPagination={false}
                showPaginationBottom={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable> */}
            {
                this.props.state.data.length > 3 && <Button className={"global_button"} variant="contained"
                    label={'Add Item'}
                    primary={true}
                    onClick={(event) => {
                        this.props.actions.addBlankRow()
                    }}
                >{window.t.en('Add Item')}</Button>
            }
            {/* <Row>
                <Col xl={12}>
                    {
                        this.props.state.mode !== 'str'
                            ? component.grid.data.length > 4
                                ?< Button className={"global_button"} variant="contained"
                                    id={'btn_add_bt'}
                                    label={'Add Item'}
                                    primary={true}
                                    disabled={
                                        (component.grid.data.length)
                                            ?
                                            (component.grid.data[component.grid.data.length - 1].itemname) ? false : true
                                            :
                                            false
                                    }
                                    onClick={(event) => {
                                        this.props.actions.addBlankRow(component)
                                    }}
                                    >{window.t.en('Add Item')}</Button>
                                : ''
                            : ''
                    }
                </Col>
            </Row> */}
        </div>
    }

    getLayout() {
        return <div>
            {/*  <Container style={{ margin: 0 }} className="custom-container">
                     <Row>
                        <Col xl={12}>
                            {
                                this.props.state.chip_accessory.map(x => {
                                    return <Chip
                                        onClick={(event) => {
                                            this.props.actions.addChipRow(this.props.state.component, x)
                                        }}
                                        label={x.accessoryname}
                                        style={styles.chip}>
                                        <Avatar size={32}>{'Ac'}</Avatar>
                                        {x.accessoryname}
                                    </Chip>
                                })
                            }
                        </Col>
                    </Row>
                    </Container>
                  <Row>
                        <Col xl={12}>
                            {
                                this.props.state.chip_service.map(x => {
                                    return <Chip
                                        id={x.item_id}
                                        key={x.item_id}
                                        onClick={(event) => {
                                            this.props.actions.addSundryRow(this.props.state.component, x)
                                        }}
                                        label={x.itemname}
                                        style={styles.chip}>
                                        <Avatar size={32}>{'Se'}</Avatar>
                                        {x.itemname}
                                    </Chip>
                                })
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {
                                this.props.state.chip_sundry.map(x => {
                                    return <Chip
                                        id={x.item_id}
                                        key={x.item_id}
                                        onClick={(event) => {
                                            this.props.actions.addSundryRow(this.props.state.component, x)
                                        }}
                                        label={x.itemname}
                                        style={styles.chip}>
                                        <Avatar size={32}>{'Su'}</Avatar>
                                        {x.itemname}
                                    </Chip>
                                })
                            }
                        </Col>
                    </Row> */}
            {this.getGrid()}
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowQuoteItemGrid,
            header: {
                ...state.workflowQuoteHeader,
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowQuoteItemGridComponent)