import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_TERM_VEHICLE_DETAILS:
            return {
                ...state,
                details: action.payload.data
                /* age: action.payload.data.age,
                tradevalue: action.payload.data.tradevalue,
                retailvalue: action.payload.data.retailvalue,
                purchasedate: action.payload.data.purchasedate,
                purchaseprice: action.payload.data.purchaseprice,
                financetype: action.payload.data.financetype */
            }
        default:
            return state
    }
}