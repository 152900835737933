export const NOT_IMPLEMENTED = 'NOT_IMPLEMENTED'
export const START_COGNITO_USER_REGISTRATION = 'START_COGNITO_USER_REGISTRATION'
export const COGNITO_CONFIGURATION_STARTED = 'COGNITO_CONFIGURATION_STARTED'
export const GET_COGNITO_USER_POOL = 'GET_COGNITO_USER_POOL'
export const GET_COGNITO_USER_ATTRIBUTES = 'GET_COGNITO_USER_ATTRIBUTES'
export const COGNITO_CONFIGURATION_SUCCEEDED = 'COGNITO_CONFIGURATION_SUCCEEDED'
export const COGNITO_CONFIGURATION_FAILED = 'COGNITO_CONFIGURATION_FAILED'
export const SUBMIT_COGNITO_USER = 'SUBMIT_COGNITO_USER'
export const CREATE_COGNITO_USER = 'CREATE_COGNITO_USER'
export const CREATE_COGNITO_USER_FAILED = 'CREATE_COGNITO_USER_FAILED'
export const CREATE_COGNITO_USER_SUCCEEDED = 'CREATE_COGNITO_USER_SUCCEEDED'
export const SUBMIT_REGISTRATION_CONFIRMATION = 'SUBMIT_REGISTRATION_CONFIRMATION'
export const REGISTRATION_CONFIRMATION_FAILED = 'REGISTRATION_CONFIRMATION_FAILED'
export const REGISTRATION_CONFIRMATION_SUCCEEDED = 'REGISTRATION_CONFIRMATION_SUCCEEDED'
export const SUBMIT_RESEND_REGISTRATION_CONFIRMATION_CODE = 'SUBMIT_RESEND_REGISTRATION_CONFIRMATION_CODE'
export const RESEND_REGISTRATION_CONFIRMATION_CODE = 'RESEND_REGISTRATION_CONFIRMATION_CODE'
export const RESEND_REGISTRATION_CONFIRMATION_CODE_FAILED = 'RESEND_REGISTRATION_CONFIRMATION_CODE_FAILED'
export const RESEND_REGISTRATION_CONFIRMATION_CODE_SUCCEEDED = 'RESEND_REGISTRATION_CONFIRMATION_CODE_SUCCEEDED'
export const SUBMIT_COGNITO_USER_AUTHENTICATION = 'SUBMIT_COGNITO_USER_AUTHENTICATION'
export const GET_COGNITO_USER_AUTHENTICATION_DETAILS = 'GET_COGNITO_USER_AUTHENTICATION_DETAILS'
export const SET_COGNITO_USER_AUTHENTICATION_DETAILS = 'SET_COGNITO_USER_AUTHENTICATION_DETAILS'
export const COGNITO_USER_AUTHENTICATION = 'COGNITO_USER_AUTHENTICATION'
export const COGNITO_USER_AUTHENTICATION_FAILED = 'COGNITO_USER_AUTHENTICATION_FAILED'
export const COGNITO_USER_AUTHENTICATION_SUCCEEDED = 'COGNITO_USER_AUTHENTICATION_SUCCEEDED'
export const ACCOUNT_USER_AUTHENTICATION_SUCCEEDED = 'ACCOUNT_USER_AUTHENTICATION_SUCCEEDED'
export const GET_COGNITO_USER = 'GET_COGNITO_USER'
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER'
export const SET_DEVICE_STATUS = 'SET_DEVICE_STATUS'
export const SET_DEVICE_STATUS_REMEMBERED = 'SET_DEVICE_STATUS_REMEMBERED'
export const SET_DEVICE_STATUS_REMEMBERED_FAILED = 'SET_DEVICE_STATUS_REMEMBERED_FAILED'
export const SET_DEVICE_STATUS_REMEMBERED_SUCCEEDED = 'SET_DEVICE_STATUS_REMEMBERED_SUCCEEDED'
export const SET_DEVICE_STATUS_NOT_REMEMBERED = 'SET_DEVICE_STATUS_NOT_REMEMBERED'
export const SET_DEVICE_STATUS_NOT_REMEMBERED_FAILED = 'SET_DEVICE_STATUS_NOT_REMEMBERED_FAILED'
export const SET_DEVICE_STATUS_NOT_REMEMBERED_SUCCEEDED = 'SET_DEVICE_STATUS_NOT_REMEMBERED_SUCCEEDED'
export const GET_COGNITO_USER_DATA = 'GET_COGNITO_USER_DATA'
export const SET_COGNITO_USER_DATA = 'SET_COGNITO_USER_DATA'
export const SEND_COGNITO_USER_AUTHENTICATION_REQUEST = 'SEND_COGNITO_USER_AUTHENTICATION_REQUEST'
export const GET_CURRENT_COGNITO_USER = 'GET_CURRENT_COGNITO_USER'
export const SET_CURRENT_COGNITO_USER = 'SET_CURRENT_COGNITO_USER'
export const GET_COGNITO_USER_SESSION = 'GET_COGNITO_USER_SESSION'
export const GET_COGNITO_USER_SESSION_FAILED = 'GET_COGNITO_USER_SESSION_FAILED'
export const GET_COGNITO_USER_SESSION_SUCCEEDED = 'GET_COGNITO_USER_SESSION_SUCCEEDED'
export const SIGN_OUT_COGNITO_USER = 'SIGN_OUT_COGNITO_USER'
export const PROCESS_COGNITO_USER_REGISTRATION = 'PROCESS_COGNITO_USER_REGISTRATION'
export const COGNITO_USER_AUTHENTICATION_NEW_PASSWORD_REQUIRED = 'COGNITO_USER_AUTHENTICATION_NEW_PASSWORD_REQUIRED'
export const SUBMIT_NEW_PASSWORD = 'SUBMIT_NEW_PASSWORD'
export const SUBMIT_VERIFICATION_PASSWORD = 'SUBMIT_VERIFICATION_PASSWORD'
export const FORGOT_PASSWORD_REQUEST_SENT = 'FORGOT_PASSWORD_REQUEST_SENT'
export const GET_USER_STATUS = 'GET_USER_STATUS'
export const KEEPLIVE_USER_SESSION = 'KEEPLIVE_USER_SESSION'
export const KEEPLIVE_USER_AUTHENTICATION_SUCCEEDED = 'KEEPLIVE_USER_AUTHENTICATION_SUCCEEDED'
export const COMPONENTS_GET_COMPONENT = 'COMPONENTS_GET_COMPONENT'
export const GET_COGNITO_USER_NEW_PASSWORD_AUTHENTICATION_DETAILS = 'GET_COGNITO_USER_NEW_PASSWORD_AUTHENTICATION_DETAILS'
export const UPDATE_USER_ACCOUNT_DEFAULT_SETTINGS_DONE = 'UPDATE_USER_ACCOUNT_DEFAULT_SETTINGS_DONE'
export const COGNITO_UPDATE_USER_AUTHENTICATION_DETAILS = 'COGNITO_UPDATE_USER_AUTHENTICATION_DETAILS'