import * as _ from 'lodash'
import * as types from './types'

export const setBusinessCustomerTreeSelection = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BUSINESS_CUSTOMER_TREE_SELECTION,
            payload: data
        })

        dispatch({
            type: types.SET_BUSINESS_CUSTOMER_HEADERS,
            payload: _.uniq(data.map(x => {
                return x.split('_')[1]
            })).filter(Boolean).join('.'),
        })
    }
}

export const setBusinessCustomerTreeExpandedState = (checked) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BUSINESS_CUSTOMER_TREE_EXPANDED_STATE,
            payload: checked
        })
    }
}

export const toggleBusinessCustomerSelection = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_BUSINESS_CUSTOMER_SELECTION
        })
    }
}