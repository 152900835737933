import * as types from './types'
import * as appTypes from '../App/types'

import validator from '../../validate'
import * as componentActions from '../Components/actions'

import * as readComponentActions from '../ReadComponent/actions'
import { getComponentTitle } from '../../helper';
import moment from 'moment'
import _ from 'lodash'


export const loadComponentData = (component) => {
    //console.log('loadComponentData', component)
    return (dispatch) => {
        dispatch(componentActions.hardReloadComponent(component))
    }
}

export const setComponentFieldInput = (field, input) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = input ? format(field, input) : ''

        dispatch({
            type: types.SET_SUB_ACCOUNT_COMPONENT_FIELD_INPUT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                validation: true // validate(component.schema, field, value)
            }
        })
    }
}

export const changeTabView = (tab) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_SUB_ACCOUNT_ACCOUNT_CURRENT_TAB,
            payload: tab
        })
    }
}

export const delCustomerContact = (id, data) => {
    return (dispatch) => {
        //console.log('delCustomerContactx id', id)
        //console.log('delCustomerContactx data', data)
        //console.log('delCustomerContactx pkid', data.id)
        //console.log('delCustomerContactx pk', [data.primary + "_id"])
        dispatch({
            type: types.DEL_RELATED_DATA_BY_IDS,
            payload: {...data, query: 'component_del_related', clause: {[data.component + "_id"]: id, [data.primary + "_id"]: data.id}}
        })
    }
}

export const delCustomerContactDone = (response,payload) => {
    return (dispatch, getState) => {
        //console.log('delCustomerContactx done', payload)
        dispatch(getRelatedGridData(payload))
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Contact Deleted',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const updCustomerContact = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.UPD_RELATED_DATA_BY_IDS,
            payload: {...data, query: 'component_upd_related'}
        })
    }
}

export const updCustomerContactDone = (response, payload) => {
    return (dispatch, getState) => {
        let data = getState().relatedGrid[payload.component]
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Contact Deleted',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const toggleCrudView = (status, id, title) => {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_CRID_VIEW_WIN,
            payload: {
                state: status,
                title: title,
                id: id
            }
        })
    }
}

export const getRelatedGridData = (model) => {
    //console.log('getRelatedGridData', model)
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPOENT_RELATED_GRID_DATA,
            payload: model
        })
    }
}

export const setRelatedGridData = (response, payload) => {
    var data = payload.related ? response.data.map(x => {return x[payload.component]}) : response.data
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPOENT_RELATED_GRID_DATA,
            payload: {
                prop: payload.component+"_data",
                value: data
            }
        })
    }
}
export const addBlankRow = (component, pk, id) => {
    return (dispatch, getState) => {
        // let data = getState().relatedGrid[component]
        // data.push({[pk]: id})
        dispatch({
            type: types.ADD_RELATED_DATA_BY_IDS,
            payload: {component: component,pk: pk, id: id, query: 'component_add_related'}
            // type: types.SET_COMPOENT_RELATED_GRID_DATA,
            // payload: {
            //     prop: component,
            //     value: data
            // }
        })
    }
}

export const addCustomerContact = (component, pk, model) => {
    return (dispatch, getState) => {
        // let data = getState().relatedGrid[component]
        // data.push({[pk]: id})
        dispatch({
            type: types.ADD_RELATED_DATA_BY_IDS,
            payload: {component: component,pk: pk, model: model, query: 'component_add_related'}
            // type: types.SET_COMPOENT_RELATED_GRID_DATA,
            // payload: {
            //     prop: component,
            //     value: data
            // }
        })
    }
}
export const addCustomerContactDone = (response, payload) => {
    return (dispatch) => {
        dispatch(getRelatedGridData(payload.model))
    }
}
export const setComponentInput = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPOENT_RELATED_GRID_DATA,
            payload: data
        })
    }
}


export const setDeletedInput = (component, field, id) => {
    return (dispatch, getState) => {
        let data = getState().relatedGrid[component].filter(x => x[field] !== id)
        dispatch({
            type: types.SET_COMPOENT_RELATED_GRID_DATA,
            payload: {
                prop: component,
                value: data
            }
        })
    }
}

export const setRowInputValue = (component, field, inx, value) => {
    return (dispatch, getState) => {
        let data = getState().relatedGrid[component]
        data[inx][field] = value
        dispatch({
            type: types.SET_COMPOENT_RELATED_GRID_DATA,
            payload: {
                prop: component,
                value: data
            }
        })
    }
}
export const setLicenseeAccount = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUB_ACCOUNT_ACCOUNT_DATA,
            payload: payload
        })
    }
}

export const saveSubAccountDone = (payload) => {
    return (dispatch, getState) => {
        dispatch(componentActions.hardReloadComponent('licensee'))
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'License account save',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(changeTabView(0))
    }
}

export const saveSubAccount = (component, user) => {
    const getData = () => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name]) {
                if (x.key) {
                    if (component.data[x.name].input !== '') {
                        obj[x.name] = component.data[x.name].input
                    }
                }
                else {
                    obj[x.name] = component.data[x.name].input !== '' ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }

    return (dispatch, getState) => {
        let data = getData()
        
        dispatch({
            type: types.SAVE_SUB_ACCOUNT_COMPONENT_CUSTOMER_DATA,
            payload: {
                sub_account: {...data, client_id: 2 }, 
                user: user,
                type: 'crud_subaccount'
            }
        })
    }
}

export const getComponentFieldLookupData = (field, searchText, dataSource, params) => {
    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const payload = {
            component: component,
            field: field,
            related: '',
            query: searchText.length > 0 ? '?' + field.search + '=' + searchText : '',
            text: searchText
        }

        dispatch({
            type: types.GET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return _.get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch) => {
        dispatch({
            type: types.SET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component.name,
                field: field.name,
                data: data
            }
        })
    }
}



export const saveComponentData = (model, cudview) => {
    const getData = (component) => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name]) {
                if (x.key) {
                    if (component.data[x.name] && component.data[x.name].input) {
                        obj[x.name] = component.data[x.name].input
                    }
                }
                else {
                    obj[x.name] = component.data[x.name] ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }
    
    const getValidation = (component) => {
        return component.component_field.reduce((obj, x) => {
            if (!component.data[x.name].validation.valid) {
                obj[x.name] = component.data[x.name].validation.message // !== '' ? component.data[x.name].input : null
            }
            return obj
        }, {})
    }

    return (dispatch, getState) => {
        let component = getState().components[model.component]
        
        dispatch(validateForm(component.name))

        const data = getData(component)
        //let prompt_accept = getState().relatedGrid.prompt_accept
        //dispatch(redirectFine(false))
        
        //console.log('getCrudx cudview', cudview)
        //console.log('getCrudx cudview_id', cudview.cudview_id,cudview.cudview_title+'_id')
        //console.log('getCrudx model', model, component)
        //console.log('getCrudx data', cudview.cudview_id ? {...data, [model.component+'_id']: cudview.cudview_id} :  {...data, [model.primary+'_id']: model.id})
       
        if (getState().relatedGrid.valid) 
        {
            dispatch({
                type: types.SAVE_RELATED_GRID_COMPONENT_DATA,
                payload: {
                    component: component,
                    model: model,
                    data: cudview.cudview_id ? {...data, [model.component+'_id']: cudview.cudview_id} :  {...data, [model.primary+'_id']: model.id}, 
                }
            })
        }
        else
        {
            // for(let err of getState().relatedGrid.errors){
                dispatch({
                    type: appTypes.SHOW_NOTIFICATION,
                    payload: {
                        type: 'danger',
                        title: 'System Notification',
                        additionalText: 'Field ' + err.errors[0].field + ' is ' +  err.errors[0].message,
                        overflowText: 'Action',
                        text: '',
                        autoHide: 10000,
                        timestamp: moment().format('h:mm A')
                    }
                })
            // }
        }
    }
}

export const componentDataSaved = (response, model) => {
    return (dispatch) => {
        //dispatch(readComponentActions.setReadComponentView('view', ''))
        // dispatch({
        //     type: types.COMPONENT_DATA_SAVED
        // })
        // todo
        
        //console.log('getCrudx saved res', response)
        
        //console.log('getCrudx saved model', model)
        if(model.model) {
            dispatch(loadComponentData(model.model.model))
            
            dispatch(getRelatedGridData(model.model))
            dispatch(toggleCrudView(false, null, null))
            if(model.model.related) {
                dispatch({
                    type: appTypes.SHOW_NOTIFICATION,
                    payload: {
                        title: 'System Notification',
                        additionalText: 'Saving link data',
                        overflowText: 'Action',
                        text: '',
                        autoHide: 10000,
                        timestamp: moment().format('h:mm A')
                    }
                })
                
            //console.log('getCrudx link', {
            //     component: model.model.model,
            //     data: {
            //         [model.model.primary + '_id']: model.model.id,
            //         [model.model.related + '_id']: response[model.model.related + '_id']
            //     }
            // })
                dispatch({
                    type: types.SAVE_RELATED_GRID_COMPONENT_DATA,
                    payload: {
                        component: {
                            name: model.model.model
                        },
                        data: {
                            [model.model.primary + '_id']: model.model.id,
                            [model.model.related + '_id']: response[model.model.related + '_id']
                        }
                    }
                })
            }

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Data successfully saved on ' + moment(new Date()).format('LLLL'),
                    overflowText: 'Action',
                    text: '',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }else{
            
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Link data successfully saved on ' + moment(new Date()).format('LLLL'),
                    overflowText: 'Action',
                    text: '',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}


export const validateForm = (name) => {
    return (dispatch, getState) => {
        const component = getState().components[name]

        let valid = true
        let errors = []
        component.component_field.forEach((x) => {
            const result = validate(component.schema, x, component.data[x.name].input)

            if (!result.valid) {
                valid = false
                errors.push(result)
            }

            dispatch({
                type: types.SET_COMPONENT_FIELD_INPUT,
                payload: {
                    component: component.name,
                    field: x.name,
                    input: x.component_field_display_type.name === 'toggle'
                        ? (component.data[x.name].input == 'undefined' || component.data[x.name].input == '' || component.data[x.name].input == 'null') ? false : component.data[x.name].input
                        : component.data[x.name].input,
                    validation: result
                }
            })
        })

        dispatch({
            type: types.SET_FORM_VALIDATION_RESULT,
            payload: {
                valid: valid,
                errors: errors
            }
        })
    }
}


const validate = (schema, field, input) => {
    if (!schema[field.name]) {
        return {
            valid: true,
            errors: []
        }
    }

    const adhocSchema = {
        [field.name]: schema[field.name]
    }

    let adhocData = {}

    if (adhocSchema[field.name].filter(x => x.name === 'boolean').length) {
        adhocData = {
            [field.name]: input ? 'true' : 'false'
        }
    }
    else {
        adhocData = {
            [field.name]: input ? input.toString() : ''
        }
    }

    const result = validator().validate(adhocSchema, adhocData)

    return {
        ...result,
        message: result.errors.map(x => { return x.message }).join(', ')
    }
}


export const componentDataSaveFailed = (component) => {
    return (dispatch) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Unable to save ' + getComponentTitle(component) + ' on ' + moment(new Date()).format('LLLL'),
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.COMPONENT_DATA_SAVE_FAILED
        })
    }
}