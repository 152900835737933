import * as types from './types'
import moment from 'moment'

export const setInputValue = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_FML_QUOTATION_ACCESSORY_INPUT_VALUE,
            payload: data
        })
    }
}

export const setListValue = (data) => {
    return (dispatch, getState) => {
        let list = getState().workflowPOAccessories[data.data]
        list[data.inx][data.prop] = data.value
        dispatch(setInputValue({prop: data.data, value: list}))
    }
}

export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FML_QUOTATION_ACCESSORY_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setLookupData response', response)
        //console.log('setLookupData payload', payload)
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.component + '_id']
            }
        })
        
        //console.log('setLookupData data', data)
        dispatch(setInputValue({prop: payload.component + '_data', value: data}))
    }
}