import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import Drawer from '../../../controls/drawer'
import Card from '../../../controls/card'
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDisposalDecision extends Component {

    getViewForm() {     
         return <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>{'Disposal Decision:'}</Col><Col xl={6}>{this.disposal.decision}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Decision Comments:'}</Col><Col xl={6}>{this.disposal.decisioncomments}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Decision By:'}</Col><Col xl={6}>{this.disposal.decision_by}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Decision Date:'}</Col><Col xl={6}>{moment(this.disposal.decision_at).format('YYYY-MM-DD HH:mm')}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br /></Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Vehicle Registration:'}</Col><Col xl={6}>{this.data.licenseplateno}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Vehicle Description:'}</Col><Col xl={6}>{this.data.makemodelyear}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}></Col>
                    </Row>
            </Container>
        }

    getEditForm() {     
         return <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <b>{'Select Decision:'}</b>
                            <Select
                                style={styles.inputWidth}
                                variant="outlined" 
                                key={'decision_type_select'}
                                value={this.props.state.decision}
                                onChange={(event, index, value) => {
                                    this.props.actions.setSetinputField({prop: 'decision', value: event.target.value})
                                }}>
                                {
                                    this.props.state.decision_list.sort((x, y) => x.value - y.value).map(x => {
                                        return <MenuItem key={'decision_type_select_item_' + x.value} value={x.value} primaryText={x.text}>{x.text}</MenuItem>
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <b>{'Decision Comments:'}</b>
                            <TextField
                                variant="outlined" 
                                id={'txtDecisionComms'}
                                hintText={'Enter Decision Comments'}
                                fullWidth={true}
                                value={this.props.state.decisioncomments}
                                onChange={(event, value) => {
                                    this.props.actions.setSetinputField({prop: 'decisioncomments', value: event.target.value})
                                }}
                            />
                        </Col>
                    </Row>
            </Container>
        }
        getView() {
            return <div> 
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Vehicle Details
                    </div>
                    <p className="text-black-50">Vehicle details</p>
                </div>
                {this.getViewForm()}
                <div className="divider" />
                <br />
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Make Disposal Decision
                    </div>
                    <p className="text-black-50">Vehicle disposal details</p>
                </div>
                {this.getEditForm()}
            </div>
        }
            
        getLayout() {
            return  <Drawer 
                onClose={(event, value) => {
                    this.props.actions.toggleInfoSheet(null,'inforsheet_decision')
                }}
                open={this.props.state.inforsheet_decision}
                title={'Disposal Decision'}
                subtitle={'Disposal decision details'}
                content={this.getView()}
                buttons={<div>
                    <Button variant='contained' className='global_button'
                         primary={true}
                         onClick={(event) => {
                             event.preventDefault()
                             this.props.actions.saveUserDecision(this.data.vehicle_id,this.props.state.user.firstname)
                         }}>
                         {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {window.t.en('Update Decision')}
                     </Button>
                </div>}
            />
            // return <Drawer
            //         width={800}
            //         anchor={'right'}
            //         openSecondary={true}
            //         open={this.props.state.inforsheet_decision}
            //         onRequestChange={(event) => {
            //             event.preventDefault()
            //             this.props.actions.toggleInfoSheet(null,'inforsheet_decision')
            //         }}>
            //         {this.getView()}
            //     </Drawer>
        }

        render() {
            this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
            this.disposal = this.data.disposal.length ? this.data.disposal[0] : {}
            return this.getLayout()
        }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalDecision)