export const RESET_COMPONENT = 'RESET_COMPONENT'
export const SET_BILLING_VALUE_CHANGE = 'SET_BILLING_VALUE_CHANGE'
export const RESET_REFRESH_VALUES = 'RESET_REFRESH_VALUES'

export const REFRESH_BILLING = 'REFRESH_BILLING'
export const EXPORT_BILLING_ITEMS = 'EXPORT_BILLING_ITEMS'
export const BILLING_REFRESHING_STATUS = 'BILLING_REFRESHING_STATUS'
export const GENRATE_PREVIEW_BILLING_INVOICE = 'GENRATE_PREVIEW_BILLING_INVOICE'
export const GET_BILLING_TRACKING = 'GET_BILLING_TRACKING'
export const SET_BILLING_TRACKING = 'SET_BILLING_TRACKING'
export const GET_BILLING_INVOICE_REFRESH_DATA = 'GET_BILLING_INVOICE_REFRESH_DATA'
export const SET_BILLING_INVOICE_REFRESH_DATA = 'SET_BILLING_INVOICE_REFRESH_DATA'
