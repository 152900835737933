import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
// import TextField from '@mui/material/TextField'

import Table from '@mui/material/Table'
import Checkbox from '@mui/material/Checkbox'
import Icon from '../../Icon/component'

import * as actions from './actions'
import * as styles from './styles'

import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"
import "react-table/react-table.css"
import * as compActions from '../../Components/actions'

import AutoComplete from '../../../controls/autocomplete'
import TextField from '../../../controls/textField'
import Card from '../../../controls/card'


var viewsearchbox = false, cust_id = 0, bus_unit = ""

class SUPPLIERALLOCATION extends Component {
    componentDidMount() {
        this.props.actions.resetForm()
        this.props.actions.setInputValue('tot_debit', 0)
        this.props.actions.setInputValue('tot_credit', 0)
        if (this.props.state.loading) this.props.actions.getComponent('supplierallocation')
    }


    getGrid() {
        //console.log('getGridx',this.props.state.transactions)
        return <div>
            <br/>
            <Table className="table table-hover text-nowrap mb-0">
                <thead>
                    <tr>
                        <td colSpan={7} style={{textAlign: 'right'}}>
                                    <TextField
                                        id={'txtallocationbalance'}
                                        variant={"outlined"}
                                        fullWidth={false}
                                        disabled
                                        //disabled={this.props.state.allocationbalance}
                                        label={'Allocation Balance'}
                                        value={(parseFloat(this.props.state.tot_debit ? this.props.state.tot_debit : 0) - parseFloat(this.props.state.tot_credit ? this.props.state.tot_credit : 0)).toFixed(2)}
                                        onChange={(event, value) => {
                                            //this.props.actions.setInputValue('description', value)
                                        }}
                                    />
                                    </td><td>
                                    {
                                        <Button variant="contained" className="btn-Accept"
                                        disabled={!((this.props.state.tot_debit && this.props.state.tot_credit) && this.props.state.tot_debit == this.props.state.tot_credit)}
                                        label={'Allocate All'}
                                        //primary={true}
                                        style={{height: '40px'}}
                                        onClick={(event) => {
                                            //console.log('allocateSelectedTransactionx', this.props.state.transactions)
                                            this.props.actions.allocateSelectedTransaction(this.props.state.transactions)
                                        }}
                                        >{window.t.en('Allocate')}</Button> 
                                    }
                        </td>
                    </tr>
                    {
                    (this.props.state.tot_debit || this.props.state.tot_credit) && <tr>
                        <td colSpan={7} style={{textAlign: 'right'}}>
                        </td>
                        <td>
                           <b>Total Debit: {this.props.state.tot_debit} </b><br/>
                           <b>Total Credit: {this.props.state.tot_credit} </b>
                        </td>
                    </tr>
                    }
                </thead>
                <thead>
                    <tr>
                        <th>{'Date'}</th>
                        <th>{'Age'}</th>
                        <th>{'Type'}</th>
                        <th>{'Reference'}</th>
                        <th style={{width: '150px'}}>{'Debit'}</th>
                        <th style={{width: '150px'}}>{'Credit'}</th>
                        <th>{'Allocated Amount'}</th>
                        <th style={{width: '100px'}}>{'Allocate'}</th>
                    </tr>
                </thead>
                <tbody style={{ backgroundColor: '#fff', color: '#000', fontSize: '14px'}}>
                    {
                        this.props.state.transactions.map((x,i) => {
                            //console.log('transactionx',x)
                            return <tr style={{ backgroundColor: '#fff', color: '#000' }}>
                                <td>{moment(x.customertransactiondate).format('DD-MM-YYYY')}</td>
                                <td>{x.age}</td>
                                <td>{x.transactiontype}</td>
                                <td>{x.reference}</td>
                                <td>{x.debit}</td>
                                <td>{x.credit}</td>
                                <td>{x.allocationamount ? x.allocationamount : x.allocated === 1 ? (Number(x.debit) + Number(x.credit)) : 0}</td>
                                <td>
                                    {
                                        x.allocated 
                                        ? <Button variant="contained" className="btn-Accept"
                                        label={'Allocate'}
                                        //primary={true}
                                        style={{height: '40px'}}
                                        onClick={(event) => {
                                            var data = {
                                                allocation_ref: x.allocation_ref,
                                                allocated: false,
                                                allocationdescription: '',
                                                allocationamount: 0
                                            }
                                            console.log('unallocatex', data)
                                            this.props.actions.getAllocationByRef(x.allocation_ref)
                                        }}
                                        >{window.t.en('Un Allocate')}</Button>  
                                        : <Checkbox
                                        checked={x.toallocate}
                                        label={''}
                                        onChange={(event, checked) => {
                                            this.props.actions.setTransactionInputValue(i, event.target.checked)
                                            let tot_debit = _.sumBy(this.props.state.transactions, (d) => { return parseFloat((d.debit && d.toallocate == 1) ? parseFloat(d.debit) : 0) })
                                            let tot_credit = _.sumBy(this.props.state.transactions, (d) => { return parseFloat((d.credit && d.toallocate == 1) ? parseFloat(d.credit) : 0) })
                                        
                                            this.props.actions.setInputValue('tot_debit', tot_debit)
                                            this.props.actions.setInputValue('tot_credit', tot_credit)
                                        }}
                                    />
                        }
                                </td> 
                            </tr> 
                        })    
                    }
                    {
                        this.props.state.transactions.length > 0 && <tr style={{ backgroundColor: '#fff', color: '#000' }}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><strong>Total Allocated</strong></td>
                            <td>{this.props.state.tot_debit}</td>
                            <td>{this.props.state.tot_credit}</td>
                            <td></td>
                            <td>
                            </td> 
                        </tr>
                    }
                </tbody> 
            </Table>     
        </div>
    }


    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <div><Card
            title={window.t.en('Supplier Allocation')}
            subtitle={window.t.en('Allocations')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3}></Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Creditor:'}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    name={'dll_creditor_id'}
                                    title={window.t.en('Search Creditor...')}
                                    info={{
                                        show: false
                                    }}
                                    fullWidth={true}
                                    data={this.props.state.supplier_data}
                                    fieldvalue={'supplier_id'}
                                    displayfields={['suppliername','accountnumber']}
                                    displaywidth={['20','80']}
                                    //value={this.props.state.selected_invoice.text}
                                    onSelect={(args) => //this.props.actions.setComponentFieldInput(field, args.value)
                                    {
                                        this.props.actions.setInputValue('tot_debit', 0)
                                        this.props.actions.setInputValue('tot_credit', 0)
                                        this.props.actions.setInputValue('supplier_id',args.value)
                                        this.props.actions.getSupplierTransactions(args.value)
                                    }
                                    }
                                    onClick={() =>
                                        this.props.actions.getAllocationSupplier('')
                                    }
                                    onFilter={(args) =>
                                        this.props.actions.getAllocationSupplier(args)
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <Container style={styles.row}>
                    <Row>
                        <Col xl={12}>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <Row>
                                <Col xl={3}><strong style={styles.fieldContent}>{'120+ Days'}</strong></Col>
                                <Col xl={3}> <strong style={styles.fieldContent}>{'90 Days'}</strong></Col>
                                <Col xl={3}> <strong style={styles.fieldContent}>{'60 Days'}</strong></Col>
                                <Col xl={3}> <strong style={styles.fieldContent}>{'30 Days'}</strong></Col>
                            </Row>
                            <Row>
                                <Col xl={3}> <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtage120plus'}
                                        variant={"outlined"}
                                        label={'120+ Days'}
                                        value={this.props.state.data.age120plus.input}
                                        onChange={(event, value) => {
                                            //this.props.actions.setInputValue('description', value)
                                        }}
                                    />
                                </div></Col>
                                <Col xl={3}> <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtage90'}
                                        variant={"outlined"}
                                        label={'90 Days'}
                                        value={this.props.state.data.age90.input}
                                        onChange={(event, value) => {
                                            //this.props.actions.setInputValue('description', value)
                                        }}
                                    />
                                </div></Col>
                                <Col xl={3}> <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtage60'}
                                        variant={"outlined"}
                                        label={'60 Days'}
                                        value={this.props.state.data.age60.input}
                                        onChange={(event, value) => {
                                            //this.props.actions.setInputValue('description', value)
                                        }}
                                    />
                                </div></Col>
                                <Col xl={3}> <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtage30'}
                                        variant={"outlined"}
                                        label={'30 Days'}
                                        value={this.props.state.data.age30.input}
                                        onChange={(event, value) => {
                                            //this.props.actions.setInputValue('description', value)
                                        }}
                                    />
                                </div></Col>
                            </Row>
                        </Col>
                        <Col xl={6}>
                            <Row>
                                <Col xl={4}> <strong style={styles.fieldContent}>{'Current'}</strong></Col>
                                <Col xl={4}> <strong style={styles.fieldContent}>{'Unallocated'}</strong></Col>
                                <Col xl={4}> <strong style={styles.fieldContent}>{'Total'}</strong></Col>
                            </Row>
                            <Row>
                                <Col xl={4}> <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtcurrent'}
                                        variant={"outlined"}
                                        label={'Current'}
                                        value={this.props.state.data.current.input}
                                        onChange={(event, value) => {
                                            //this.props.actions.setInputValue('description', value)
                                        }}
                                    />
                                </div></Col>
                                <Col xl={4}> <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtunallocated'}
                                        variant={"outlined"}
                                        label={'Unallocated'}
                                        value={this.props.state.data.unallocated.input}
                                        onChange={(event, value) => {
                                            //this.props.actions.setInputValue('description', value)
                                        }}
                                    />
                                </div></Col>
                                <Col xl={3}> <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txttotal'}
                                        variant={"outlined"}
                                        label={'Total'}
                                        value={this.props.state.data.total.input}
                                        onChange={(event, value) => {
                                            //this.props.actions.setInputValue('description', value)
                                        }}
                                    />
                                </div></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container> */}

                <Row>
                    <Col xl={12}>
                        {this.getGrid()}
                    </Col>
                </Row>
            </Container>
            } />
         </div>
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.supplierallocation,
            user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERALLOCATION)