import * as validatorTypes from '../Validator/types'

export const password = {
    score: 0,
    strength: 0,
    color: '',
    min: 0,
    max: 100,
    value: 0,
    match: false
}

export const fields = {
    newPassword: {
        id: 'newPassword',
        name: 'newPassword',
        errorText: '',
        hintText: 'Example: sw0rD#fiGht',
        floatingLabelText: 'New Password',
        input: '',
        type: 'password',
        valid: false,
        empty: true,
        validators: [validatorTypes.IS_NOT_EMPTY, validatorTypes.IS_STRONG_PASSWORD]
    }
}

export const userAttributes = {}

export const valid = false
