import * as types from './types'
import * as formActions from '../Form/actions'

export const loadOnDemand = (component, relationship, query, state) => {
    return (dispatch, getState) => {
/*         dispatch({
            type: types.LOAD_ON_DEMAND,
            payload: ''
        }) */

        dispatch({
            type: types.GET_LOD_COMPONENT_RELATIONSHIPS,
            payload: component
        })
    }
}

export const getLODComponentRelationships = (component, id) => {
    return (dispatch, getState) => {
        const relatedFull = getState().components[component].relatedFull
        const pass = (relatedFull.hasMany || relatedFull.belongsTo)

        if (!pass) {
            dispatch({
                type: types.GET_LOD_COMPONENT_RELATIONSHIPS,
                payload: {
                    component: component
                }
            })
        }
    }
}

export const setLODComponentRelationship = (component, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_LOD_COMPONENT_RELATIONSHIPS,
            payload: {
                component: component,
                relatedFull: payload
            }
        })
    }
}