import * as types from './types'
//import { ExcelRenderer, OutTable } from 'react-excel-renderer'
import moment from 'moment'

import * as appTypes from '../App/types'

export const setLookupValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA,
            payload: data
        })
    }
}

export const setInput = (name, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_INPUT,
            payload: {
                name: name,
                data: data
            }
        })
    }
}


export const uploadFileData = (data) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA_DONE,
            payload: true
        })

        let type = ''
        switch (getState().financeImport.selectedImportType) {
            case 'SAP Import':
                type = 'UPLOAD_SAP_IMPORT_FILE_DATA'
            break
            case 'AA Roadside Import':
                type = 'UPLOAD_AA_IMPORT_FILE_DATA'
            break
            default:
                type = ''
        }

        dispatch({
            type: types.UPLOAD_FINANCE_IMPORT_FILE_DATA,
            payload: {
                type: type,
                rows: data.rows,
                template: data.template,
                document: data.document,
                customerTransDate: data.lateMonth,
                lateAge: data.lateAge,
                isLate: data.is_late
            }
        })
    }
}

export const uploadFileDataDone = (data) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> uploadFileDataDone', data)

        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA_DONE,
            payload: false
        })



        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'File import successfully completed...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getTemplateList = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_EXCEL_FILE_TEMPLATE_DATA_LIST,
            payload: search
        })
    }
}

export const setTemplateList = (payload) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>> setTemplateList')
        //console.log(payload)
        dispatch({
            type: types.SET_EXCEL_FILE_TEMPLATE_DATA_LIST,
            payload: (payload.data ? payload.data : [])
        })
    }
}

export const resetUpload = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS,
            payload: []
        })
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_NAME,
            payload: null
        })
        dispatch({
            type: types.SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA,
            payload: null
        })
    }
}

const getJsDateFromExcel = excelDate => {
    return new Date((excelDate - (25567 + 1)) * 86400 * 1000);
};

export const setAcceptedDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_BUSY,
            payload: true
        })

        documents.forEach(file => {
            //console.log('File',file)
            // ExcelRenderer(file, (err, resp) => {
            //     if (err) {
            //         //console.log(err);
            //     }
            //     else {
            //         let a = resp.rows.filter(x => x.length).filter(x => x.length > 1)
            //         if (getState().financeImport.selectedImportType == 'AA Roadside Import') {
            //             let n = a.map((b, i) => {
            //                 if (i > 0) {
            //                     b[0] = moment(getJsDateFromExcel(b[0])).format('YYYY-MM-DD')
            //                 }
            //             })
            //         }

            //         dispatch({
            //             type: types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS,
            //             payload: resp.rows.filter(x => x.length).filter(x => x.length > 1)
            //         })
            //     }
            // });
        });
        //console.log('SET_UPLOADED_EXCEL_FILE_NAME')
        //console.log(documents[0])
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_NAME,
            payload: {
                preview: documents[0].preview,
                filename: documents[0].name,
            }
        })
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_BUSY,
            payload: false
        })
    };
}

