import * as types from './types'
import { ExcelRenderer, OutTable } from 'react-excel-renderer'
import * as xlsx  from 'xlsx'
import moment from 'moment'
import * as formulas from 'formulajs'

import * as appTypes from '../App/types'

export const resetUpload = () => {
    return (dispatch, getState) => {
        dispatch(setComponentFieldInput('bulk_rows',[]))
        dispatch(setComponentFieldInput('document',null))
    }
}

export const setComponentFieldInput = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BULK_COMPONENT_INPUT_VALUE,
            payload: {
                prop: prop,
                value : value
            }
        })
    }
}

export const setComponentRowInput = (prop, value, inx) => {
    return (dispatch, getState) => {
        let bulk_rows = getState().bulkQuotation.bulk_rows
        bulk_rows[inx][prop] = value
        dispatch(setComponentFieldInput('bulk_rows',bulk_rows))
        dispatch(calcTot())
    }
}

export const confirmDelete = (inx) => {
    return (dispatch, getState) => {
        let bulk_rows = getState().bulkQuotation.bulk_rows
        delete bulk_rows[inx]
        bulk_rows = bulk_rows.filter(x => x)
        dispatch(setComponentFieldInput('bulk_rows',bulk_rows))
        dispatch(calcTot())
    }
}

export const duplicateRow = (row) => {
    return (dispatch, getState) => {
        let bulk_rows = getState().bulkQuotation.bulk_rows
        bulk_rows.push(row)
        dispatch(setComponentFieldInput('bulk_rows',bulk_rows))
        dispatch(calcTot())
    }
}
export const setAcceptedDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch(setComponentFieldInput('busy',true))

        documents.forEach(file => {
            //console.log('File',file)
            ExcelRenderer(file, (err, resp) => {
                if (err) {
                    //console.log(err);
                }
                else {
                    //console.log(resp.rows.filter(x => x.length).filter(x => x.length > 1))

                    //console.log('resp.rows', resp.rows)
                    let bulk_rows = resp.rows.filter(x => x.length).filter(x => x.length > 1)
                    let headers = bulk_rows[0]
                    const objects = [];
                    for (let i = 1; i < bulk_rows.length; i++) {
                        const row = bulk_rows[i];
                        const obj = {};
                      
                        // Iterate over each cell in the row
                        for (let j = 0; j < headers.length; j++) {
                          // Assign the cell value to the corresponding header property in the object
                          obj[headers[j]] = row[j];
                        }
                      
                        // Push the constructed object to the array
                        objects.push(obj);
                    }

                    dispatch(setComponentFieldInput('bulk_rows',objects))
                    dispatch(calcTot())
                }
            });
        });
        //console.log('SET_UPLOADED_EXCEL_FILE_NAME')
        //console.log(documents[0])
        
        dispatch(setComponentFieldInput('document',{
            preview: documents[0].preview,
            filename: documents[0].name,
        }))
        dispatch(setComponentFieldInput('busy',false))
        dispatch(calcTot())
    };
}

export const exportToExcel = (documents) => {
    return (dispatch, getState) => {
        
        let bulk_headers = getState().bulkQuotation.bulk_headers
        const data = getState().bulkQuotation.bulk_rows
        const rows = [[
            'Vehicle Make',
            'Vehicle Model',
            'Quantity',
            'Fuel',
            'Transmission',
            'Color',
            'M&M Code',
            'Retail Price',
            'Discount',
            'Vehicle Price',
            'Period',
            'Inclusive Kms',
            'Contract Kms',
            'RV',
            'Interest',
            'Maintenance CPK',
            'Tyres CPK',
            'Total Excl',
            'VAT',
            'Total Monthly Incl',
        ]]
        let row = Object.keys(bulk_headers)
        for (let i = 0; i < data.length; i++) {
            let datarow = data[i];
            let rowData = [];
            let row = Object.keys(datarow)
            for (let j of bulk_headers) {
                rowData.push(datarow[j.name]);
            }
            rows.push(rowData);
        }

        
                    
        const footer = [];
        for (let j of bulk_headers) {
            //console.log('headers[j]x', j.name)
            footer.push((j.name == 'excl' || j.name == 'vat' || j.name == 'incl' )
            ? _.sumBy(data, (d) => { return parseFloat((d[j.name]) ? d[j.name].toString().replace(/,/gi, '') : 0) })
            : '')
        }  

        rows.push(footer);

        const workbook = xlsx.utils.book_new();
        const worksheet = xlsx.utils.aoa_to_sheet(rows);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet01");

        //const excelBlob = new Blob([xlsx.write(workbook, { type: 'blob' })], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const excelBuffer = xlsx.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = 'data.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);


        // const table = <table><tbody>{rows}</tbody></table>;
        // //console.log('dataz table', JSON.stringify(table))
        // //console.log('dataz', JSON.stringify(data))
        // const blob = new Blob(data, {
        //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // });
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.setAttribute('href', url);
        // link.setAttribute('download', 'exported_data.xlsx');
        // document.body.appendChild(link);
        // link.click();

        // ExcelRenderer(table, (err, resp) => {
        //     if (err) {
        //         //console.log(err);
        //     }
        //     else {
        //         //console.log(err);
        //         const blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //         const url = window.URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         a.href = url;
        //         a.download = 'bulkquotation.xlsx';
        //         document.body.appendChild(a);
        //         a.click();
        //         document.body.removeChild(a);
        //     }
        // });
        dispatch(setComponentFieldInput('busy',false))
    };
}

export const calcTot = () => {
    return (dispatch, getState) => {
        const data = getState().bulkQuotation.bulk_rows
      
        let bulk_rows = data.map(x => {
            let finance = formulas.PMT(((parseFloat(x.interest) / 100) / 12), parseFloat(x.period), -((parseFloat(x.price) * parseFloat(x.quantity)) + parseFloat(0)), parseInt(x.rv), 0)//.toFixed(2)
            let maintenance = parseFloat(x.kms) * parseFloat(x.maintenance)
            let tyres = parseFloat(x.kms) * parseFloat(x.tyres)
            let sub_tot = finance + maintenance + tyres
            let contractkms = parseInt(x.period) * parseInt(x.kms)
            let price = parseInt(x.retail) - parseInt(x.discount)
            //console.log('bulk_rowsx x',x)
            return {
                ...x,
                price: parseFloat(price),
                contractkms: parseFloat(contractkms),
                excl: parseFloat(sub_tot),
                vat: (parseFloat(sub_tot) * 0.15).toFixed(2),
                incl: (parseFloat(sub_tot) * 1.15).toFixed(2)
            }
        })
        //console.log('bulk_rowsx',bulk_rows)
        dispatch(setComponentFieldInput('bulk_rows',bulk_rows))
    };
}
