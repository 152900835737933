import * as props from './props'
import * as validatorTypes from '../Validator/types'

export default (state = props, action) => {
    switch (action.type) {
        case validatorTypes.VALIDATE_FIELD:
            //console.log('VALIDATE_FIELD', action.payload)
            //console.log('VALIDATE_FIELD state', state)
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.id]: {
                        ...action.payload,
                        input: action.payload.input,
                        empty: action.payload.empty,
                        errorText: action.payload.valid ? '' : action.payload.errorText
                    }
                }
            }
        case validatorTypes.SET_FORM_STATUS:
            return {
                ...state,
                valid: action.payload
            }
        default:
            return state
    }
}