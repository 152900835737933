import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { Card, CardHeader, CardContent, IconButton, Collapse, CardActions } from '@mui/material'

import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { Container, Row, Col } from 'react-grid-system'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button";

import Section from './section'
import * as actions from './actions'
import * as styles from './styles'

class AuditView extends Component {  
    componentDidMount() {
        console.log('getAuditSectionx mount', this.props.pk)
        this.props.actions.getAuditData(this.props.pk, this.props.component)
    }

    render() {
        console.log('auditdata', this.props.state.auditdata)
        let auditdata = this.props.state.auditdata //.length ? this.props.state.auditdata[0] : null
        return auditdata
        ? <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}><br /></Col>
            </Row>
            <Row>
                
                <Col xl={12}>
                    <Section title={"Record Audit Details"} />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>&nbsp;</Col>
            </Row>
            <Row>
                <Col className={'custom-col-label'} xl={6}>
                    <FormControlLabel
                        fullWidth={true}
                        control={
                            <Typography variant="body1" component="div"
                                style={{ fontSize: '14px', fontWeight: '500', textAlign: 'left', width: '100%', fontWeight: 800, padding: '5px 0px' }}>
                                {auditdata.created_by}
                            </Typography>
                        }
                        label={
                            <div style={{ fontSize: '14px', width: '300px', textAlign: 'left', paddingRight: '10px', height: '100%', verticalAlign: 'top' }}>
                                Created by:
                            </div>
                        }
                        labelPlacement="top"
                    />
                </Col>
                <Col className={'custom-col-label'} xl={6}>
                    <FormControlLabel
                        fullWidth={true}
                        control={
                            <Typography variant="body1" component="div"
                                style={{ fontSize: '14px', fontWeight: '500', textAlign: 'left', width: '100%', fontWeight: 800, padding: '5px 0px' }}>
                                {auditdata.created_at ? moment(auditdata.created_at).format('YYYY-MM-DD HH:mm') : ''}
                            </Typography>
                        }
                        label={
                            <div style={{ fontSize: '14px', width: '300px', textAlign: 'left', paddingRight: '10px', height: '100%', verticalAlign: 'top' }}>
                                Created Date :
                            </div>
                        }
                        labelPlacement="top"
                    />
                </Col>
                <Col className={'custom-col-label'} xl={6}>
                    <FormControlLabel
                        fullWidth={true}
                        control={
                            <Typography variant="body1" component="div"
                                style={{ fontSize: '14px', fontWeight: '500', textAlign: 'left', width: '100%', fontWeight: 800, padding: '5px 0px' }}>
                                {auditdata.updated_by}

                            </Typography>
                        }
                        label={
                            <div style={{ fontSize: '14px', width: '300px', textAlign: 'left', paddingRight: '10px', height: '100%', verticalAlign: 'top' }}>
                                Updated By :
                            </div>
                        }
                        labelPlacement="top"
                    />
                </Col>
                <Col className={'custom-col-label'} xl={6}>
                    <FormControlLabel
                        fullWidth={true}
                        control={
                            <Typography variant="body1" component="div"
                                style={{ fontSize: '14px', fontWeight: '500', textAlign: 'left', width: '100%', fontWeight: 800, padding: '5px 0px' }}>
                                {auditdata.updated_at ? moment(auditdata.updated_at).format('YYYY-MM-DD HH:mm') : ''}
                            </Typography>
                        }
                        label={
                            <div style={{ fontSize: '14px', width: '300px', textAlign: 'left', paddingRight: '10px', height: '100%', verticalAlign: 'top' }}>
                                Updated Date :
                            </div>
                        }
                        labelPlacement="top"
                    />
                </Col>
            </Row>
        </Container>
        : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.controls }
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditView)
