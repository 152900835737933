import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { Container, Row, Col } from 'react-grid-system'
import Card from '../../../controls/card'
// import TextField from '@mui/material/TextField'
import ImageUploader from '../../ImageUploader/component'
import Upload from '../../Upload/component'
import Icon from '../../Icon/component'
import * as styles from './styles'
import * as actions from './actions'
import ViewVehicle from '../../../controls/view_vehicle'
import AutoComplete from '../../../controls/autocomplete'
import DateControl from '../../../controls/dateControl'
import TextField from '../../../controls/textField'
import Checkbox from '../../../controls/checkbox'
import moment from 'moment';
import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'

class DefleetUploadChecksheet extends Component {
    componentDidMount() {      
        this.props.actions.resetComponent()
        //this.props.actions.setValidationState('defleetUploadChecksheet')
    }
    commentsView() {
        return <div>
            <Card 
                    title={window.t.en('Check Sheet Upload Comments')}
                    subtitle={window.t.en('Capture comments')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    id={'txtcomment'}
                                    key={'txtcomment'}
                                    label={window.t.en('Enter Comments...')}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.props.state.comments.value}
                                    onChange={
                                        (event) => {
                                            this.props.actions.setValue('comments', event.target.value)
                                        }
                                    }
                                /></Col>
                        </Row>
                    </Container>} />
        </div>
    }
    view(workflow_queue_data) {
        let step1 = workflow_queue_data.step1.data
        let vehicle = step1.selectedVehicleDetails.data[0]
        let contract = vehicle.customercontract.filter(x => x.terminated == false && x.activated == true)
        //console.log('contract[0].contracttype', contract[0].contracttype)
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Card title={window.t.en('Upload De-fleet Check Sheet')}
                            subtitle={window.t.en('De-fleet Check Sheet Details ')}
                            content={<Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={4} >
                                        <div style={{margingTop:'0px'}}>
                                                <DateControl
                                                    id={'txt_checksheetdefleet_date'}
                                                    key={'txt_checksheetdefleet_date'}
                                                    name={'txt_checksheetdefleet_date'}
                                                    label={window.t.en('De-fleet Date')}
                                                    field={'defleetdate'}
                                                    fullWidth={true}
                                                    maxDate={moment()}
                                                    minDate={workflow_queue_data.step1.data.defleetrequestdate ? moment(workflow_queue_data.step1.data.defleetrequestdate) : null}
                                                    value={this.props.state.defleetdate.value}
                                                    onSelect={(args) => {
                                                        this.props.actions.setValue('defleetdate', args)
                                                    }}
                                                    type={'date'}
                                                />
                                                </div>
                                    </Col>
                                    <Col xl={4} style={{margingTop:'0px'}}>
                                                <DateControl
                                                    id={'txt_checksheetcompleted_date'}
                                                    key={'txt_checksheetcompleted_date'}
                                                    name={'txt_checksheetcompleted_date'}
                                                    label={window.t.en('Check Sheet Completed Date')}
                                                    field={'checksheet_completedat'}
                                                    fullWidth={true}
                                                    maxDate={moment()}
                                                    minDate={workflow_queue_data.step1.data.defleetrequestdate ? moment(workflow_queue_data.step1.data.defleetrequestdate) : null}
                                                    value={this.props.state.checksheet_completedat.value}
                                                    onSelect={(args) => {
                                                        this.props.actions.setValue('checksheet_completedat', args)
                                                    }}
                                                    type={'date'}
                                                />

                                    </Col>
                                    <Col xl={4} style={{paddingTop:15}}>
                                                <TextField
                                                    id={'txtchecksheet_completedby'}
                                                    key={'txtchecksheet_completedby'}
                                                    label={'Check Sheet Completed By'}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    value={this.props.state.checksheet_completedby.value}
                                                    onChange={
                                                        (event) => {
                                                            this.props.actions.setValue('checksheet_completedby', event.target.value)
                                                        }
                                                    }
                                                />
                                    </Col>
                                    <Col xl={4} style={{paddingTop:'15px'}}>
                                                <AutoComplete
                                                    name={'dll_location'}
                                                    title={window.t.en('Search Location...')}
                                                    label={'Location'}
                                                    info={{
                                                        show: false
                                                    }}
                                                    fullWidth={true}
                                                    data={this.props.state.locations.data}
                                                    value={this.props.state.location.value} 
                                                    onSelect={(args) => {
                                                        console.log('Locationset' ,args)
                                                        this.props.actions.setValue("location", args.value)
                                                    }
                                                    }
                                                    onClick={() =>
                                                        this.props.actions.getDefleetLocationList('')
                                                    }
                                                    onFilter={(search) =>{
                                                        console.log('Locationxx',search)
                                                        console.log('Locationxx set',this.props.actions.setValue("location", search))
                                                        if (search){
                                                            this.props.actions.getDefleetLocationList(search)
                                                            this.props.actions.setValue("location", search)
                                                        }else{
                                                        this.props.actions.getDefleetLocationList(''),
                                                        this.props.actions.setValue("location", '')
                                                        }
                                                    }
                                                    }
                                                />
                                    </Col>
                                    <Col xl={4} >
                                                <TextField
                                                    id={'txtchecksheet_odoreading'}
                                                    key={'txtchecksheet_odoreading'}
                                                    label={window.t.en('Odo Reading')}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    margin="normal"
                                                    value={this.props.state.odoreading.value}
                                                    onChange={
                                                        (event) => {
                                                            this.props.actions.setValue('odoreading', event.target.value)
                                                        }
                                                    }
                                                />
                                    </Col>
                                {contract[0].contracttype && contract[0].contracttype.indexOf('Short') >= 0 ?
                           
                                       <Col xl={4} style={{ marginTop: '15px' }}>
                                            {
                                                        <TextField
                                                            id={'txtchecksheet_fuelamount'}
                                                            key={'txtchecksheet_fuelamount'}
                                                            label={window.t.en('Enter Fuel Amount...')}
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            value={this.props.state.fuelamount.value}
                                                            onChange={
                                                                (event) => {
                                                                    this.props.actions.setValue('fuelamount', event.target.value)
                                                                }
                                                            }
                                                        />
                                            }
                                        </Col>
                                   : ''}
                      
                                    <Col xl={4} style={{paddingTop:'15px'}}>
                                        <Checkbox
                                            id={'txt_has_damages'}
                                            key={'txt_has_damages'}
                                            name={'txt_has_damages'}
                                            field={'has_damages'}
                                            checked={this.props.state.has_damages.value}
                                            labelPlacement={'end'}
                                            label={window.t.en('Does the vehicle have any damage?')}
                                            // setComponentFieldInput={
                                            //     this.props.actions.setValue
                                            // }
                                            onCheck={(checked) => {
                                                this.props.actions.setValue('has_damages', checked)
                                            }}
                                        />
                                    </Col>
                                    <Col xl={4}>
                                        <Checkbox
                                            id={'txt_has_damages'}
                                            key={'txt_has_damages'}
                                            name={'txt_has_damages'}
                                            field={'has_damages'}
                                            checked={this.props.state.has_sparekey.value}
                                            labelPlacement={'end'}
                                            label={window.t.en('Does the vehicle have spare keys?')}
                                            // setComponentFieldInput={
                                            //     this.props.actions.setValue
                                            // }
                                            onCheck={(checked) => {
                                                this.props.actions.setValue('has_sparekey', checked)
                                            }}
                                        />

                                    </Col>
                                </Row>
                            </Container>} />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.commentsView()}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Upload
                        id={'upload_ctl'}
                        prefix={'Defleet_Uploaded_Checksheet_' + step1.registration + '_' + step1.defleetNumber}
                        header={false}
                        list={[{ value: 0, text: 'Check Sheet' }, { value: 0, text: 'Pictures' } , { value: 0, text: 'E-mail' }]}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={6}>
                    <Checkbox
                        id={'txt_correctdocument_type'}
                        key={'txt_correctdocument_type'}
                        name={'txt_correctdocument_type'}
                        field={'correctdocument_type'}
                        checked={this.props.state.correctdocument_type.value}
                        labelPlacement={'end'}
                        label={window.t.en('Please confirm that you have selected the correct document type')}
                        // setComponentFieldInput={
                        //     this.props.actions.setValue
                        // }
                        onCheck={(checked) => {
                            this.props.actions.setValue('correctdocument_type', checked)
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={6}>
                    <Checkbox
                        id={'txt_defleet_date_match'}
                        key={'txt_defleet_date_match'}
                        name={'txt_defleet_date_match'}
                        field={'defleet_date_match'}
                        checked={this.props.state.defleet_date_match.value}
                        labelPlacement={'end'}
                        label={window.t.en('Please confirm that the check-in and Check Sheet dates match')}
                        // setComponentFieldInput={
                        //     this.props.actions.setValue
                        // }
                        onCheck={(checked) => {
                            this.props.actions.setValue('defleet_date_match', checked)
                        }}
                    />
                </Col>
            </Row>
        </Container>
    }

    getDaysPast(d_date) {
        var oneDay = 1000 * 60 * 60 * 24;
        var difference_ms = Math.abs((new Date()).getTime() - (new Date(d_date)).getTime())
        var diffValue = Math.round(difference_ms / oneDay);
        return diffValue
    }
    render() {
        let workflow_queue_data = null
        try {
            //console.log(">>>>>><<<<< this.props.workflow_queue_data")
            //console.log(this.props.workflow_queue_data.context_data.data.data.data[0])
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) {

        }
        return workflow_queue_data ? this.view(workflow_queue_data) : <strong style={styles.box}>{window.t.en('Kindly check if your not logged in twice, if the error persist please contact system administrator.')}</strong>;
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.defleetUploadChecksheet,
            user: {
                ...state.user
            },
            step1: {
                ...state.defleetUploadChecksheetConfirm
            }
        }
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction  }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetUploadChecksheet)
