import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import AutoComplete from '../../../controls/autocomplete'


import Icon from '../../Icon/component'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import * as actions from './actions'
import * as styles from './styles'
import * as compActions from '../../Components/actions'
import CUSTOMERALLOCATION from '../../Custom/CUSTOMERALLOCATION/component'
var view = false

class CUSTOMERREVERSERECEIPT extends Component {
    componentDidMount() {
        if(this.props.state.loading) this.props.actions.getComponent('customerreversereceipt')
    }
    addNew()
    {        
        this.props.actions.setLookupValue("reference", '', '')
        this.props.actions.setInputValue('customer_id', '')
        this.props.actions.setInputValue('customername', '')
        this.props.actions.setInputValue('customertransactiondate', new Date())
        var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')                                                          
        this.props.actions.setInputValue('customertransactionmonth', _date)
        this.props.actions.setInputValue('customerreference', '')
        this.props.actions.setInputValue('description', '')
        this.props.actions.setInputValue('business_id', '')
        this.props.actions.setInputValue('businessname', '')   
        this.props.actions.setInputValue('accountingitem_id', '') 
        this.props.actions.setInputValue('itemname', '')         
        this.props.actions.setInputValue('debit', 0)                                           
        view = false
    }
    getView() {
        return <Tabs>
        <Tab label={'Capture'}>
        <Card style={styles.card} style={styles.row}>
            <CardHeader
                title={window.t.en('Customer Receipt Reversal')}
                subtitle={window.t.en('Reverse customer receipt')}
                actAsExpander={true}
                showExpandableButton={true}
            />
             <CardContent>
                <Container style={styles.whitediv}>
                    <Row>
                        {/*Left Side*/}
                        <Col xl={12}>
                        <Row><Col xl={6}><br></br></Col><Col xl={6}>
                                   < Button className={"global_button"} variant="contained" header={false}
                                        primary={true}
                                        label={'New Reversal'}
                                        onClick={(event) => {
                                            this.addNew()
                                        }}
                                        >{window.t.en('New Reversal')}</Button>
                                </Col></Row>
                        <Row>
                                <Col xl={10}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}><strong>Reversed Receipt:</strong></div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_invoice_id'}
                                                key={'dll_invoice_id'}
                                                hintText={'Search by reference number...'}
                                                filter={AutoComplete.fuzzyFilter}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.reference.data}
                                                onNewRequest={
                                                    (chosenRequest, index) => {
                                                        this.props.actions.setLookupValue("reference", chosenRequest.value, chosenRequest.text)

                                                        if (chosenRequest.value) {
                                                            view = true;
                                                            this.props.actions.setInputValue('customer_id', chosenRequest.customer_id)
                                                            this.props.actions.setInputValue('customername', chosenRequest.customer_text)
                                                            this.props.actions.setInputValue('customertransactiondate', new Date(chosenRequest.customertransactiondate))
                                                            this.props.actions.setInputValue('customertransactionmonth', chosenRequest.customertransactionmonth)
                                                            this.props.actions.setInputValue('customerreference', chosenRequest.customerreference)
                                                            this.props.actions.setInputValue('description', chosenRequest.description)
                                                            this.props.actions.setInputValue('business_id', chosenRequest.business_id)
                                                            this.props.actions.setInputValue('businessname', chosenRequest.business_text)                                                            
                                                            this.props.actions.setInputValue('itemname', chosenRequest.accountingitem_text)                                                            
                                                            this.props.actions.setInputValue('debit', chosenRequest.amount)
                                                        }
                                                    }
                                                }
                                                onClick={
                                                    (event) => {
                                                        this.props.actions.getAllReversedReceipts('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search, dataSource, params) => {
                                                        this.props.actions.getAllReversedReceipts(search)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col xl={10}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Debtor:'}</div>
                                        <div style={styles.fieldContent}>
                                            { view ?
                                                    <TextField
                                                        disabled={true}
                                                        id={'txt_customer_fktext'}
                                                        hintText='debtor'
                                                        value={this.props.state.data.customername.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('customername', value)
                                                        }}
                                                    /> :<AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_debtor_id'}
                                                key={'dll_debtor_id'}
                                                hintText={'Select a debtor...'}
                                                filter={AutoComplete.fuzzyFilter}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.customer_id.data}
                                                onNewRequest={
                                                    (chosenRequest, index) => {
                                                        var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')  
                                                        this.props.actions.setLookupValue("customer_id", chosenRequest.value, chosenRequest.text)
                                                        this.props.actions.setInputValue('description', _date)
                                                        this.props.actions.setInputValue('customertransactiondate', new Date())
                                                        this.props.actions.setInputValue('customertransactionmonth', _date)
                                                    }
                                                }
                                                onClick={
                                                    (event) => {
                                                        this.props.actions.getLookupValueData('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search, dataSource, params) => {
                                                        this.props.actions.getLookupValueData(search)
                                                    }
                                                }
                                            />}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={10}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Date:'}</div>
                                        <div style={styles.fieldContent}>
                                            {<TextField
                                                type="date"
                                                disabled={view}
                                                id={'txt_invoicedate'}
                                                hintText='Date'
                                                container='inline'
                                                mode='landscape'
                                                value={this.props.state.data.customertransactiondate.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('customertransactiondate', value)
                                                    var _date = moment(new Date(value)).format('YYYYMM')  
                                                    this.props.actions.setInputValue('customertransactionmonth', _date)
                                                }}
                                            />}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={10}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Reference:'}</div>
                                        <div style={styles.fieldContent}>
                                            {<TextField
                                                 disabled={view}
                                                id={'txtreference'}
                                                hintText='Reference'                                                                                       
                                                errorText={this.props.state.data.reference.input ? null : '*'}
                                                value={this.props.state.data.reference.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('reference', value)
                                                    this.props.actions.getDuplicateReferenceCheck(value)
                                                }}
                                            />}
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col xl={10}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Description:'}</div>
                                        <div style={styles.fieldContent}>
                                            {<TextField
                                                disabled={view}
                                                id={'txtdescription'}
                                                value={this.props.state.data.description.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('description', value)
                                                }}
                                            />}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                           {/*  <Row>
                                <Col xl={10}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Branch:'}</div>
                                        <div style={styles.fieldContent}>
                                        {
                                                view ?
                                                    <TextField
                                                        disabled={true}
                                                        id={'txt_business_fktext'}
                                                        hintText='Branch'
                                                        value={this.props.state.data.business_fktext.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('business_fktext', value)
                                                        }}
                                                    /> :<AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_business_id'}
                                                key={'dll_business_id'}
                                                hintText={'Select a branch...'}
                                                filter={AutoComplete.fuzzyFilter}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.business_id.data}
                                                onNewRequest={
                                                    (chosenRequest, index) => {
                                                        this.props.actions.setLookupValue("business_id", chosenRequest.value, chosenRequest.text)
                                                    }
                                                }
                                                onClick={
                                                    (event) => {
                                                        this.props.actions.getBusinessListData('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search, dataSource, params) => {
                                                        this.props.actions.getBusinessListData(search)
                                                    }
                                                }
                                            />}
                                        </div>
                                    </div>
                                </Col>
                            </Row>         */}                   
                            <Row style={styles.row}>
                                <Col xl={10}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}><strong>{'Amount:'}</strong></div>
                                        <div style={styles.fieldContent}>
                                            {<TextField
                                                disabled={view}
                                                id={'txtamount'}
                                                value={this.props.state.data.debit.input}                                                                                       
                                                errorText={this.props.state.data.debit.input ? null : '*'}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('debit', value)
                                                }}
                                            />}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={10}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Bank Account:'}</div>
                                        <div style={styles.fieldContent}>
                                            {  view ?
                                                    <TextField
                                                        disabled={true}
                                                        id={'txt_accountingitem_id'}
                                                        hintText='Bank account'
                                                        value={this.props.state.data.itemname ? this.props.state.data.itemname.input:''}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('itemname', value)
                                                        }}
                                                    /> :<AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_business_id'}
                                                key={'dll_business_id'}
                                                hintText={'Select a account...'}
                                                errorText={this.props.state.data.accountingitem_id.input ? null : '*'}
                                                filter={AutoComplete.fuzzyFilter}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.accountingitem_id.data}
                                                onNewRequest={
                                                    (chosenRequest, index) => {
                                                        this.props.actions.setLookupValue("accountingitem_id", chosenRequest.value, chosenRequest.text)
                                                    }
                                                }
                                                onClick={
                                                    (event) => {
                                                        this.props.actions.getContractAccountListData('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search, dataSource, params) => {
                                                        this.props.actions.getContractAccountListData(search)
                                                    }
                                                }
                                            />}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xl={10}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Month:'}</div>
                                        <div style={styles.fieldContent}>
                                            {<TextField
                                                /* disabled={true} */
                                                id={'txt_customertransactionmonth'}
                                                hintText='Month'
                                                value={this.props.state.data.customertransactionmonth.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('customertransactionmonth', value)
                                                }}
                                            />}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Row>
                    <Col xl={12}><br></br></Col>
                </Row>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={10}></Col>
                        <Col xl={2}>
                            <Row>

                                <Col xl={12}>
                                   < Button className={"global_button"} variant="contained" 
                                        disabled={view}
                                        header={false}
                                        label={'Save Reversal'}
                                        onClick={(event) => {
                                            var validationfields =
                                            [
                                                {
                                                    value: this.props.state.data.customer_id.input,
                                                    field: "Debtor"
                                                },
                                                {
                                                    value: this.props.state.data.debit.input,
                                                    field: "Amount"
                                                },                                           
                                                {
                                                    value: this.props.state.data.description.input,
                                                    field: "Description"
                                                },                                           
                                                {
                                                    value: this.props.state.data.accountingitem_id.input,
                                                    field: "Account"
                                                }
                                            ]
                                            if (this.props.state.data.reference.text === true) {
                                                var payload = {
                                                   data: this.props.state.data,
                                                   documents: this.props.state.upload.documents,
                                                   user:  this.props.state.user
                                           }
                                           this.props.actions.saveData(payload, validationfields)                                                
                                       }
                                       if (this.props.state.data.reference.text === false) {
                                           this.props.actions.foundDuplicateReference()
                                       }   
                                        }}
                                        >{window.t.en('Save Reversal')}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Row><Col><br></br></Col></Row>
                
            </CardContent>
        </Card>
        </Tab>
        <Tab label={'Allocation'}>
            <Container style={{ margin: 0 }} className="custom-container">
                <CUSTOMERALLOCATION></CUSTOMERALLOCATION>
            </Container>
        </Tab>
    </Tabs>

        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
       
    }

    render() {
        return (
            this.props.state.loading ? 'Loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.customerreversereceipt,
            upload: {
                ...state.upload
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions,...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERREVERSERECEIPT)