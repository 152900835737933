import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Container, Row, Col } from "react-grid-system";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardMedia,
  CardTitle,
  CardExpandable,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
// import MarkerClusterGroup from "react-leaflet-markercluster";
import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";

import Icon from "../Icon/component";
import View from "../View/component";

import * as actions from "./actions";
import * as routerActions from "../Redirect/actions";
import * as styles from "./styles";
import moment from "moment";

import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AutoComplete from "@mui/material/Autocomplete";

import ReactLeafletSearch from "react-leaflet-search";
import Routing from "./RoutingMachine";

import "react-leaflet-markercluster/dist/styles.min.css";

import demo from "./demo";
import "./stylesheet.css";


class Tracker extends Component {
    constructor() {
        super();
        this.state = {
          lat: -25.99,
          lng: 28.064,
          zoom: 7,
          isMapInit: false
        };
      }
      state = {
        lat: 47.445745,
        lng: 40.272891666666666,
        zoom: 15,
        type: "distance",
        demo: demo
      };
      saveMap = map => {
        this.map = map;
        this.setState({
          isMapInit: true
        });
      };
  componentDidMount() {
    this.props.actions.getTrackingData(this.props.state.source);
  }

  getMarkers(position) {
    return this.props.state.data.length ? (
      this.props.state.data.map((x) => {
        return (
          <Marker
            position={[
              x[this.props.state.source.latitude],
              x[this.props.state.source.longitude],
            ]}
          >
            <Popup>
              <span
                onClick={(event) => {
                  this.props.actions.route(
                    "/view/" +
                      this.props.state.source.source +
                      "/" +
                      x[this.props.state.source.source + "_id"]
                  );
                }}
              >
                <div>
                  <strong>
                    <Icon iname={x.icon} iclass="custom-icons" /> {x.display}
                  </strong>
                </div>
              </span>
            </Popup>
          </Marker>
        );
      })
    ) : (
      <Marker position={position}>
        <Popup>
          <span>Loading tracking data...</span>
        </Popup>
      </Marker>
    );
  }

  getIcons() {
    const position = [this.props.state.latitude, this.props.state.longitude];

    return (
      <div style={styles.sources}>
        <div>
          {/* <Tooltip arrow title={"Location"} placement="left">
            <IconButton
              tooltip={"Location"}
              onClick={(event) => {
                event.preventDefault();
                this.props.actions.setInputValue({ prop: "open_search", value: !this.props.state.open_search });
                this.props.actions.setInputValue({ prop: "open_directions", value: false });
              }}
            >
              <Icon
                istyle={{ fontSize: "22px", color: "#253053" }}
                color="primary"
                iclass={"material-icons"}
                iname={"location_searching"}
              />
            </IconButton>
          </Tooltip>
          <br />
          <Tooltip arrow title={"Location"} placement="left">
            <IconButton
              tooltip={"Location"}
              onClick={(event) => {
                event.preventDefault();
                this.props.actions.setInputValue({ prop: "open_directions", value: !this.props.state.open_directions });
                this.props.actions.setInputValue({ prop: "open_search", value: false });
              }}
            >
              <Icon
                istyle={{ fontSize: "22px", color: "#253053" }}
                color="primary"
                iclass={"material-icons"}
                iname={"directions"}
              />
            </IconButton>
          </Tooltip>
          <br /> */}
          {this.props.state.sources.map((x) => {
            return (
              <div>
                <Tooltip arrow title={x.title} placement="left">
                  <IconButton
                    tooltip={x.title}
                    onClick={(event) => {
                      event.preventDefault();
                      this.props.actions.setTrackingSource(x);
                      this.props.actions.getTrackingData(x);
                    }}
                  >
                    <Icon
                      istyle={{ fontSize: "22px", color: "#253053" }}
                      color="primary"
                      iclass={"material-icons"}
                      iname={x.icon}
                    />
                  </IconButton>
                </Tooltip>
                <br />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  getSearchControl() {
    return this.props.state.open_search 
        ? <div style={{ padding: 10, backgroundColor: "white !important" }}>
                  <AutoComplete
                    id="combo-box-demo"
                    listStyle={{ maxHeight: 400 }}
                    style={{ position: "fixed", width: 400, right: 80, top: 100, backgroundColor: "white", }}
                    getOptionLabel={(option) => option.text}
                    options={
                      this.props.state.address_data
                        ? this.props.state.address_data
                        : []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search location"
                        variant="outlined"
                      />
                    )}
                    onOpen={(chosenRequest, index) => {
                      //this.props.onClick()
                      //console.log("searching event", chosenRequest);
                    }}
                    onInputChange={(event, search) => {
                      //console.log("searching event", search);
                      this.props.actions.locationSearch(search);
                    }}
                    onChange={(event, request) => {
                      //console.log("searching request", request);
                      if (request) {
                        this.props.actions.setLocation({
                          latitude: request.lat,
                          longitude: request.lon,
                          zoom: 15,
                        });
                        this.props.actions.setMarker([
                          {
                            currentlatitude: request.lat,
                            currentlongitude: request.lon,
                            display: request.text,
                            icon: "my_location",
                          },
                        ]);
                      }
                    }}
                  />
                </div>
       : <div></div>
  }

  getDirectionControls() {
    const position = [this.props.state.latitude, this.props.state.longitude];

    return this.props.state.open_directions
      ? <div>
        <Container style={{ zIndex: "99999989" }}>
          <Row>
            <Col xl={5}>
              <AutoComplete
                id="combo-box-demo"
                listStyle={{ maxHeight: 400 }}
                style={{ backgroundColor: "white" }}
                getOptionLabel={(option) => option.text}
                options={
                  this.props.state.address_data
                    ? this.props.state.address_data
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search start location"
                    variant="outlined"
                  />
                )}
                onOpen={(chosenRequest, index) => {
                  //this.props.onClick()
                  //console.log("searching event", chosenRequest);
                }}
                onInputChange={(event, search) => {
                  if (search.length > 4) {
                    //console.log("searching event", search);
                    this.props.actions.locationSearch(search);
                  } else {
                    //console.log("searching not enough", search);
                  }
                  //this.props.onFilter ? this.props.onFilter(search) : this.props.onUpdateInput(search)
                }}
                onChange={(event, request) => {
                  //console.log("searching request", request);

                  this.props.actions.setInputValue({
                    prop: "startpoint",
                    value: {
                      latitude: request.lat,
                      longitude: request.lon,
                      zoom: 15,
                    },
                  });
                  t; //his.props.actions.setMarker([{currentlatitude: request.lat, currentlongitude: request.lon, display: 'WTF', icon: 'my_location'}])
                  // if(search && search.length > 2)
                  // this.props.actions.addressSearch('delivery_data',search)
                }}
              />
            </Col>
            <Col xl={5}>
              <AutoComplete
                id="combo-box-demo"
                listStyle={{ maxHeight: 400 }}
                style={{ backgroundColor: "white" }}
                getOptionLabel={(option) => option.text}
                options={
                  this.props.state.address_data
                    ? this.props.state.address_data
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search destination"
                    variant="outlined"
                  />
                )}
                onOpen={(chosenRequest, index) => {
                  //this.props.onClick()
                  //console.log("searching event", chosenRequest);
                }}
                onInputChange={(event, search) => {
                  if (search.length > 4) {
                    //console.log("searching event", search);
                    this.props.actions.locationSearch(search);
                  } else {
                    //console.log("searching not enough", search);
                  }
                  //this.props.onFilter ? this.props.onFilter(search) : this.props.onUpdateInput(search)
                }}
                onChange={(event, request) => {
                  //console.log("searching request", request);

                  this.props.actions.setInputValue({
                    prop: "endpoint",
                    value: {
                      latitude: request.lat,
                      longitude: request.lon,
                      zoom: 15,
                    },
                  });
                  //this.props.actions.setMarker([{currentlatitude: request.lat, currentlongitude: request.lon, display: 'WTF', icon: 'my_location'}])
                  // if(search && search.length > 2)
                  // this.props.actions.addressSearch('delivery_data',search)
                }}
              />
            </Col>
            <Col xl={2}>
              <hr />
              {this.props.state.getdirection ? (
                <Button
                  onClick={() =>
                    this.props.actions.setInputValue({
                      prop: "getdirection",
                      value: false,
                    })
                  }
                  color="secondary"
                >
                  Stop
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    this.props.actions.setInputValue({
                      prop: "getdirection",
                      value: true,
                    })
                  }
                  color="secondary"
                >
                  Start
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Map
                center={position}
                zoom={this.props.state.zoom}
                style={styles.tracker}
                ref={this.saveMap}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {this.props.state.getdirection ? (
                  <Routing
                    map={this.map}
                    startpoint={this.props.state.startpoint}
                    endpoint={this.props.state.endpoint}
                  />
                ) : (
                //   <MarkerClusterGroup
                //     wrapperOptions={{ enableDefaultStyle: true }}
                //   >
                <>
                    {this.getMarkers(position)}
                    </>
                )}
              </Map>
            </Col>
          </Row>
        </Container>
      </div>
    : ''
  }

  render() {
    const position = [this.props.state.latitude, this.props.state.longitude];

    return (
      <div>
        <Container style={{ zIndex: "99999989" }}>
            <Row>
                <Col xl={12}>
                        {this.getIcons()}
                    <Map
                    center={position}
                    zoom={this.props.state.zoom}
                    style={styles.tracker}
                    ref={this.saveMap}
                    >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {this.state.isMapInit && <Routing map={this.map} />} 
                    <ReactLeafletSearch position="topleft" provider="OpenStreetMap" serviceUrl="https://route-uatfalms.bidvestbank.co.za/nominatim/"   />
                    {/* {this.props.state.getdirection ? (
                        <Routing
                        map={this.map}
                        startpoint={this.props.state.startpoint}
                        endpoint={this.props.state.endpoint}
                        />
                    ) : ( )} */}
                        {/* <MarkerClusterGroup wrapperOptions={{ enableDefaultStyle: true }}> */}
                        {this.getMarkers(position)}
                        {/* </MarkerClusterGroup> */}
                   
                    </Map>
                </Col>
            </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
      ...state.tracker,
      component: {
        ...state.components["vehicle"],
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...actions, ...routerActions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tracker);
