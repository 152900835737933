export const visibility = (toggled) => {
    return toggled
        ? { display: 'block' }
        : { display: 'none' }
}

export const commands = {
    textAlign: 'right'
}

export const item = {
    display: 'flex'
}

export const flex = {
    width: '100%',
    display: 'flex'
}

export const table = {
    width: '100%',
    display: 'flex',
    padding: '6px'
}

export const cell = {
    display: 'cell',
    width: '70%'
}

export const dropdown = {
    width: '100%'
}

export const right = {
    textAlign: 'right',
    display: 'cell',
    width: '30%'
}

export const MuiFormControl = {
    width: "100%",
}
