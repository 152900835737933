import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_SUPPLIER_MASTER_CAPTURE_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.RESET_COMPONENT_SUPPLIER_MASTER_CAPTURE_FORM:
            return {
                ...state,
                contacttypes: [{text: 'Fleet Manage', value: 1},{text: 'Fleet Account', value: 2}],
                addresstypes: [{text: 'Physical', value: 1},{text: 'Postal', value: 2}],
                address: [],
                contacts: [],
                products : [],
                product_data : [],
                suppliertype_data: [],
                contacttype_data : [],
                businesstype_data : [],
                paymentterm_data : [],
                discountterm_data : [],
                financialinstitution_data :[],
                businesstype: '',
                businesstype_id : '',
                suppliertype: '',
                suppliertype_id : '',
                fundername: '',
                financialinstitution_id : '',
                product:'',
                product_id:'',
                paymentterm:'',
                paymentterm_id:'',
                discountterm: '',
                discountterm_id: '',
                suppliername: '',
                tradingname: '',
                accountnumber: '',
                registrationnumber: '',
                vatnumber: '',
                bankaccountname: '',
                bankbranchnumber: '',
                bankaccountnumber: '',
                discount_fktext : '',
                monthlylimit: '',
                line1: '',
                line2: '',
                line3 : '',
                line4 : '',
                firstname: '',
                lastname: '',
                email : '',
                telephone : '',
                auth_signatory: '',
                company_reg_document_recieved: false,
                taxclearancecertificatereceived: false,
                beecertificatereceived: false,
                confirmation_banking_details_recieved: false,
                signed_proc_fraud_policies_recieved : false,
                interest_decleration_recieved: false,
                ethical_purchasing_recieved : false,
                service_provider_agreement_recieved : false,
                proof_of_insurance_recieved:false,
                landlords_waiver_recieved: false,
                empty: true
            }
        default:
            return state
    }
}