import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'


export const getSupplierPaymentList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_SUPPLIER_PAYMENT_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                //url: config.system.api + '/components/customertransaction/search?transactiontype=PM&reference='+ payload.search_text,
                url: config.system.api + '/components/suppliertransaction/searchor?supplier_fktext=' + payload.search_text + '&reference=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'reference',
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setPaymentList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getPaymentCreditorsList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_PAY_CREDITORS_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplier/searchor?suppliername=' + payload.search_text + '&accountnumber=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'suppliername',
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setCreditorList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
export const getPaymentBusinessList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_PAY_BUSINESS_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/business/search?businessname=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'businessname',
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setBusinessList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getPaymentAccountList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_PAY_ACCOUNT_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accountingitem/search?accountingtype_id=4&itemdescription=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'itemdescription',
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setContraAccountList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )


export const getPaymentDiscountAccountList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_PAY_DISCOUNT_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accountingitem/search?itemdescription=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'itemdescription',
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setDiscountAccountList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const savePaymentEpic = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.SAVE_SUPPLIER_PAYMENT_DATA,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplierpayment/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload.data
            })
                .pipe(
                    mergeMap((result) => [
                        actions.savedSupplierPaymentData(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
