import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_WORKFLOW_QUEUE_TASKS_SEARCH_VALUE:
            return {
                ...state,
                value: action.payload,
                search: true
            }
            case types.SET_WORKFLOW_QUEUE_TASKS_ACTIVE_TEAM:
                return {
                    ...state,
                    active_team: action.payload,
                    search: true
                }
        case types.SET_WORKFLOW_QUEUE_TASKS_DATE_SEARCH:
            return {
                ...state,
                filter_date: action.payload,
            }
        case types.SET_WORKFLOW_QUEUE_TASK_CURRENT_VIEW:
            return {
                ...state,
                view_workflow: action.payload,
            }
        case types.SET_CURRENT_WORKFLOW_ACTIVE_TAB:
            return {
                ...state,
                tab: action.payload,
            }
        case types.SET_CURRENT_WORKFLOW_TASKS_COLLAPSE:
            return {
                ...state,
                collapse: action.payload,
            }
        case types.SEARCH_WORKFLOW_QUEUE_TASKS:
            return {
                ...state,
                search: false,
                value: action.payload,
                loading: true
            }
        case types.SET_WORKFLOW_QUEUE_TASKS_SEARCH_RESULTS:
            return {
                ...state,
                results: action.payload,
                search: false,
                loading: false
            }
        case types.SET_WORKFLOW_QUEUE_TASK_TEAMS:
            return {
                ...state,
                teams: action.payload
            }
        case types.SET_FILTER_DATE:
            return {
                ...state,
                filter_date: action.payload
            }
        case types.SET_COMPONENT_OPEN_CLOSE_OPTIONS:
            return {
                ...state,
                openOptions: action.payload.value,
                openOptionsEl: action.payload.el
            }
        case types.SET_COMPONENT_OPEN_CLOSE_SEARCH:
            return {
                ...state,
                openSearch: action.payload.value,
                openSearchEl: action.payload.el
            }
        case types.SET_COMPONENT_OPEN_CLOSE_SETTINGS:
            return {
                ...state,
                openSettings: action.payload.value,
                openSettingsEl: action.payload.el
            }
        case types.ASSIGN_TASK:
            return {
                ...state,
                user_id: action.payload.user_id,
                team_id: action.payload.team_id,
                event: action.payload.event,
                workflow: action.payload.workflow,
                workflow_task: action.payload.workflow_task,
                data: action.payload.data,
                queue: action.payload.queue
            }
        case types.HANDLE_TASK:
            return {
                ...state,
                event: action.payload.event,
                workflow: action.payload.workflow,
                workflow_task: action.payload.workflow_task,
                data: action.payload.data,
                queue: action.payload.queue
            }
        case types.CLEAR_WORKFLOW_QUEUE_TASKS:
            return {
                ...state,
                event: '',
                workflow: {},
                workflow_task: {},
                data: [],
                queue: {},
                //value: '',
                task: {}
            }
        case types.GET_WORKFLOW_QUEUE_TASK_TEAMS:
            return {
                ...state,
                teams: []
            }
        default:
            return state
    }
}