import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const li = {
    fontSize: '12px',

}

export const icon = {

    fontSize: '35px',

}