export const SELECT_PURCHASE_ORDER_TYPE = 'SELECT_PURCHASE_ORDER_TYPE'
export const SET_USER_AUTOCOMPLETE_MENU_ITEMS = 'SET_USER_AUTOCOMPLETE_MENU_ITEMS'
export const GET_USER_AUTOCOMPLETE_MENU_ITEMS = 'GET_USER_AUTOCOMPLETE_MENU_ITEMS'
export const SET_USER_AUTOCOMPLETE_SELECTED = 'SET_USER_AUTOCOMPLETE_SELECTED'
export const SET_SUPPLIER_AUTOCOMPLETE_AMOUNT = 'SET_SUPPLIER_AUTOCOMPLETE_AMOUNT'
export const SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS = 'SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS'
export const GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS = 'GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS'
export const SET_SUPPLIER_AUTOCOMPLETE_SELECTED = 'SET_SUPPLIER_AUTOCOMPLETE_SELECTED'
export const ADD_SUPPLIER_AUTOCOMPLETE = 'ADD_SUPPLIER_AUTOCOMPLETE'
export const REMOVE_SUPPLIER_AUTOCOMPLETE = 'REMOVE_SUPPLIER_AUTOCOMPLETE'

export const GET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS = 'GET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS'
export const SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS = 'SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS'
export const SET_VEHICLE_AUTOCOMPLETE_SELECTED = 'SET_VEHICLE_AUTOCOMPLETE_SELECTED'
export const GET_SELECTED_VEHICLE_DETAILS = 'GET_SELECTED_VEHICLE_DETAILS'
export const SET_SELECTED_VEHICLE_DETAILS = 'SET_SELECTED_VEHICLE_DETAILS'
export const GET_SELECTED_SUPPLIER_DETAILS = 'GET_SELECTED_SUPPLIER_DETAILS'
export const SET_SELECTED_SUPPLIER_DETAILS = 'SET_SELECTED_SUPPLIER_DETAILS'
export const SET_SUSPENDED_SUPPLIER_DETAILS = 'SET_SUSPENDED_SUPPLIER_DETAILS'
export const GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST = 'GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST'
export const SET_VEHICLE_SERVICE_INFORMATION = 'SET_VEHICLE_SERVICE_INFORMATION'
export const SET_GEO_MAP_SUPPLIERS = 'SET_GEO_MAP_SUPPLIERS'
export const RESET_COMPONENT = 'RESET_COMPONENT'
export const GET_SUPPLIER_AGE = 'GET_SUPPLIER_AGE'
export const SET_SUPPLIER_AGE = 'SET_SUPPLIER_AGE'
export const GET_PRE_AUTHORISATION_NUMBER = 'GET_PRE_AUTHORISATION_NUMBER'
export const SET_PRE_AUTHORISATION_NUMBER = 'SET_PRE_AUTHORISATION_NUMBER'
export const GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const SET_COMPONENT_VALIDATION_PROPS_FIELDS = 'SET_COMPONENT_VALIDATION_PROPS_FIELDS'
export const SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const SET_VEHICLE_CURRENT_BOOKING_INFO = 'SET_VEHICLE_CURRENT_BOOKING_INFO'
export const SET_COMPONENT_RECENT_MAINTENANCE_AUTHS = 'SET_COMPONENT_RECENT_MAINTENANCE_AUTHS'
export const SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUES = 'SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUES'
export const SET_DEFLEET_CAPTUREQUOTE_VALIDATION_VALUE = 'SET_DEFLEET_CAPTUREQUOTE_VALIDATION_VALUE'
export const RESET_DEFLEET_CAPTUREQUOTE_COMPOMENT_STATE = 'RESET_DEFLEET_CAPTUREQUOTE_COMPOMENT_STATE'
export const SET_DEFLEET_SELECTED_SUPPLIER_DETAILS = 'SET_DEFLEET_SELECTED_SUPPLIER_DETAILS'
export const GET_CAPTUREQUOTE_SUPPLIERS = 'GET_CAPTUREQUOTE_SUPPLIERS'
export const SET_CAPTUREQUOTE_SUPPLIERS = 'SET_CAPTUREQUOTE_SUPPLIERS'
export const SET_SUPPLIER_SUSPENDED_STATUS = 'SET_SUPPLIER_SUSPENDED_STATUS'
export const TOGGLE_SUPPLIER_SUSPENDED = 'TOGGLE_SUPPLIER_SUSPENDED'
export const SET_DEFLEET_SUSPENDED_SUPPLIER_DETAILS = 'SET_DEFLEET_SUSPENDED_SUPPLIER_DETAILS'
export const TOGGLE_DEFLEET_UPCOMING_SUPPLIER_SUSPENDED_STATUS = 'TOGGLE_DEFLEET_UPCOMING_SUPPLIER_SUSPENDED_STATUS'
export const SET_DEFLEET_SUPPLIER_MONTHLY_OVER_THE_LIMIT = 'SET_DEFLEET_SUPPLIER_MONTHLY_OVER_THE_LIMIT'
export const SET_DEFLEET_SUPPLIER_MONTHLY_SPEND_USAGE = 'SET_DEFLEET_SUPPLIER_MONTHLY_SPEND_USAGE'
export const SET_CAPTUREQUOTE_SUPPLIER_AUTOCOMPLETE_SELECTED = 'SET_CAPTUREQUOTE_SUPPLIER_AUTOCOMPLETE_SELECTED'
export const GET_SELECTED_SUPPLIER_DETAILS_DEFLEET = 'GET_SELECTED_SUPPLIER_DETAILS_DEFLEET'