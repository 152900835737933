import * as types from './types'
import validation from 'validator'

export const resetComponent = () => {
    return {
        type: types.VIEW_RESET_COMPONENT
    }
}

export const toggleInfoView = (status, id, name, title) => {
    return {
        type: types.VIEW_COMPONENT_TOGGLE_INFOR_VIEW,
        payload: {
            id: id,
            name: name,
            title: title,
            status: status
        } 
    }
}
export const closeInfoView = (status) => {
    return {
        type: types.VIEW_COMPONENT_CLOSE_INFOR_VIEW,
        payload: status
    }
}
export const toggleSectionInfosheet = (section_infosheet,display_type,infosheet_key) => {
    //console.log('toggleSectionInfosheet',section_infosheet)
    return {
        type: types.VIEW_COMPONENT_TOGGLE_SECTION_INFOSHEET,
        payload: {
            section_infosheet: section_infosheet,
            infosheet_key: infosheet_key,
            display_type: display_type,
        }
    }
}

export const setComponentInputValue = (data) => {
    return {
        type: types.VIEW_COMPONENT_SET_INPUT_VALUES,
        payload: data
    }
}
