import React, { Component } from 'react'

class SvgIcon extends Component {
    svgIcon(icon) {
        var useTag = '<use xlink:href="../../../icomoon/symbol-defs.svg#' + icon + '" />'
        return <svg style={this.props.style} className={'custom-icons ' + icon} dangerouslySetInnerHTML={{ __html: useTag }} />
    }

    render() {
        return this.svgIcon(this.props.name)
    }
}

export default SvgIcon