export const LOAD_WORKFLOW_DOCUMENTS = 'LOAD_WORKFLOW_DOCUMENTS'
export const LOAD_WORKFLOW_DOCUMENTS_LIST = 'LOAD_WORKFLOW_DOCUMENTS_LIST'

export const WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE = 'WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE'
export const WORKFLOW_TIMELINE_SET_WORKFLOW_TIMELINE = 'WORKFLOW_TIMELINE_SET_WORKFLOW_TIMELINE'
export const CLOSE_WORKFLOW_DOCUMENTS = 'CLOSE_WORKFLOW_DOCUMENTS'
export const SHOW_WORKFLOW_DOCUMENTS = 'SHOW_WORKFLOW_DOCUMENTS'

export const TOGGLE_WORKFLOW_DOCUMENTS = 'TOGGLE_WORKFLOW_DOCUMENTS'
export const GET_WORKFLOW_DOCUMENTS_LIST = 'GET_WORKFLOW_DOCUMENTS_LIST'
export const SET_WORKFLOW_DOCUMENTS_LIST = 'SET_WORKFLOW_DOCUMENTS_LIST'
export const GET_WORKFLOW_DOCUMENTS = 'GET_WORKFLOW_DOCUMENTS'
export const SET_WORKFLOW_DOCUMENTS = 'SET_WORKFLOW_DOCUMENTS'

export const RESET_WORKFLOW_DOCUMENTS = 'RESET_WORKFLOW_DOCUMENTS'
export const SEARCH_WORKFLOW_DOCUMENTS_LIST = 'SEARCH_WORKFLOW_DOCUMENTS_LIST'
export const WORKFLOW_DOWNLOAD_DOCUMENTS = 'WORKFLOW_DOWNLOAD_DOCUMENTS'
export const VIEW_DOCUMENT = 'VIEW_DOCUMENT'
export const DOWNLOAD_CEO_DOCUMENTS = 'DOWNLOAD_CEO_DOCUMENTS'
export const GET_WORKFLOW_DOCUMENT_VEHICLE_LIST = 'GET_WORKFLOW_DOCUMENT_VEHICLE_LIST'
export const SET_WORKFLOW_DOCUMENT_VEHICLE_LIST = 'SET_WORKFLOW_DOCUMENT_VEHICLE_LIST'

export const GET_WORKFLOW_DOCUMENT_FROM_DOWNLAOD_TABLE = 'GET_WORKFLOW_DOCUMENT_FROM_DOWNLAOD_TABLE'