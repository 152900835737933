import Theme from '../../theme'

export const theme = Theme

export const documentButton = {
    width: '50px'
}

export const dropZone = {
    width: '100%',
    height: '150px',
    border: '1px solid black',
    padding: '10px'
}

export const row = {
    //backgroundColor: '#dcd6d7',
    textAlign: 'left',
    padding: 10
}

export const commandRow = {
    textAlign: 'right'
}

export const documentCard = {
    margin: '5px'
}

export const documentCardTitle = {
    fontWeight: 'bold'
}

export const tab = {
    textTransform: 'capatilize',
    //whiteSpace: 'nowrap'
}

export const showDropzone = {
    display: 'block'
}

export const hideDropzone = {
    display: 'none'
}

export const leftLabelTitle = {
    textAlign: 'left',
    fontWeight: 'bold',
    textTransform: 'capitalize'
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const ButtonMargins = {
    margin: 12
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
    width: '100%',
    display: 'block'
}

export const popup = {
    display: 'block',
    width: 900,
    maxWidth: '80%',
    left: '100px',
    top: '50px',
    position: 'relative',
    padding: '1.2rem',
    background: '#000',
    backgroundClip: 'padding-box',
    boxShadow: '0 12px 15px 0 rgba(0,0,0,0.25)',
}