export const SET_SELECTED_CUSTOMER_DATA = 'SET_SELECTED_CUSTOMER_DATA'
export const GET_COMPONENT_CUSTOMER_LIST_DATA = 'GET_COMPONENT_CUSTOMER_LIST_DATA'
export const SET_COMPONENT_CUSTOMER_LIST_DATA = 'SET_COMPONENT_CUSTOMER_LIST_DATA'

export const SAVE_COMPONENT_DATA = 'SAVE_COMPONENT_DATA'
export const COMPONENT_DATA_SAVED = 'COMPONENT_DATA_SAVED'
export const COMPONENT_DATA_SAVE_FAILED = 'COMPONENT_DATA_SAVE_FAILED'
export const SAVE_COMPONENT_CUSTOMER_DATA = 'SAVE_COMPONENT_CUSTOMER_DATA'
export const GET_COMPONENT_SELECTED_CUSTOMER_SETTINGS_DATA = 'GET_COMPONENT_SELECTED_CUSTOMER_SETTINGS_DATA'
export const SET_COMPONENT_SELECTED_CUSTOMER_SETTINGS_DATA = 'SET_COMPONENT_SELECTED_CUSTOMER_SETTINGS_DATA'
export const SET_COMPONENT_CONTRACT_TYPE_LIST_DATA = 'SET_COMPONENT_CONTRACT_TYPE_LIST_DATA'
export const GET_COMPONENT_CONTRACT_TYPE_LIST_DATA = 'GET_COMPONENT_CONTRACT_TYPE_LIST_DATA'
export const SET_COMPONENT_PRODUCT_TYPE_LIST_DATA = 'SET_COMPONENT_PRODUCT_TYPE_LIST_DATA'
export const GET_COMPONENT_PRODUCT_TYPE_LIST_DATA = 'GET_COMPONENT_PRODUCT_TYPE_LIST_DATA'
export const SET_CUSTOMER_DERIVATIVE_INPUT_VALUES = 'SET_CUSTOMER_DERIVATIVE_INPUT_VALUES'