export const LOAD_DOCUMENT_STATE = 'LOAD_DOCUMENT_STATE'
export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS'
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT'
export const VIEW_DOCUMENT = 'VIEW_DOCUMENT'
export const SET_ACCEPTED_DOCUMENTS = 'SET_ACCEPTED_DOCUMENTS'
export const SET_REJECTED_DOCUMENTS = 'SET_REJECTED_DOCUMENTS'
export const DOCUMENT_GET_DOCUMENT_TYPES = 'DOCUMENT_GET_DOCUMENT_TYPES'
export const DOCUMENT_SET_DOCUMENT_TYPES = 'DOCUMENT_SET_DOCUMENT_TYPES'
export const DOCUMENT_UPLOAD_ACCEPTED_DOCUMENTS = 'DOCUMENT_UPLOAD_ACCEPTED_DOCUMENTS'
export const DOCUMENT_CHANGE_SELECTED_DOCUMENT_TYPE = 'DOCUMENT_CHANGE_SELECTED_DOCUMENT_TYPE'
export const DOCUMENT_UPLOAD_COMPONENT_DOCUMENT = 'DOCUMENT_UPLOAD_COMPONENT_DOCUMENT'
export const DOCUMENT_UPLOAD_COMPONENT_DOCUMENTX = 'DOCUMENT_UPLOAD_COMPONENT_DOCUMENTX'
export const DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_SUCCESSFUL = 'DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_SUCCESSFUL'
export const DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_FAILED = 'DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_FAILED'
export const DOCUMENT_LINK_UPLOADED_DOCUMENTS = 'DOCUMENT_LINK_UPLOADED_DOCUMENTS'
export const DOCUMENT_LINK_UPLOADED_DOCUMENTS_SUCCESSFUL = 'DOCUMENT_LINK_UPLOADED_DOCUMENTS_SUCCESSFUL'
export const DOCUMENT_LINK_UPLOADED_DOCUMENTS_FAILED = 'DOCUMENT_LINK_UPLOADED_DOCUMENTS_FAILED'
export const SET_DOCUMENTS_CURRENT_TAB = 'SET_DOCUMENTS_CURRENT_TAB'