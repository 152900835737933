import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'

import Checkbox from '@mui/material/Checkbox'

import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'
import Iframe from 'react-iframe'
import Icon from '../../Icon/component'

import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import TextField from '../../../controls/textField'
import Card from '../../../controls/card'

import * as actions from './actions'
import * as styles from './styles'

import Upload from '../../Upload/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import ImageUploader from '../../ImageUploader/component'
import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'

class POBookingInvoiceUpload extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }

    commentsView() {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'GRV_Supplier_Invoice_' + this.data.step1.licenseplateno + '_'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Invoice Date:'}</div>
                                    <div style={styles.fieldContent}>
                                       <TextField
                                                type="date"
                                            id={'txtinvdate'}
                                            hintText='Select invoice date'
                                            container='inline'
                                            variant="outlined" 
                                            mode='landscape'
                                            maxDate={new Date()}
                                            value={this.props.state.invoicedate}
                                            defaultDate={this.props.state.invoicedate}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('invoicedate', event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Invoice Number:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtinvnumber'}
                                            hintText={'Enter invoice number'}
                                            variant="outlined" 
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.invoice_number}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('invoice_number', event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Vat Exclusive:'}</div>
                                    <div style={styles.fieldContent}>
                                        <Checkbox
                                            id={'chkvat'}
                                            checked={this.props.state.vatincl}
                                            onClick={(event, checked) => {
                                                this.props.actions.setValue('vatincl', checked)
                                            }} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Invoice Total Amount:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtinvamount'}
                                            variant="outlined" 
                                            hintText={'Enter invoice total amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.invoice_amount}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('invoice_amount', event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
        </div>
    }

    getLayout() {
        return <div>
        <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
        <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
        <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
            <Card
            title={'Close Maintenance Auth'}
            subtitle={'Complete the form below to create booking'}
            content={this.commentsView()}
        />
        </div>
    }

    render() {
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        //console.log('render >>>>>>>>>>>>>>>>>>>> this.data',this.data)
        return this.data
            ? this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingInvoiceUpload
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingInvoiceUpload)