import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import Icon from '../../Icon/component'

import Checkbox from '@mui/material/Checkbox'


import DateControl from '../../../controls/dateControl'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import Card from '../../../controls/card'

import Upload from '../../Upload/component'
import TextField from '../../../controls/textField'
import * as actions from './actions'
import * as styles from './styles'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'
import ImageUploader from '../../ImageUploader/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'

class POBookingRebillInvoiceUpload extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.setValue('invoicedate',  Date())
    }

    renderView() {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'GRV_Rebill_Supplier_Invoice_'+this.data.step1.vehicle+'_'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4} >
                                         <DateControl
                                            id={'txtinvdate'}
                                            key={'txtinvdate'}
                                            label={'Select Invoice Date'}
                                            name={'txtinvdate'}
                                            value={this.props.state.invoicedate}
                                            error={this.props.state.invoicedate ? false : true}
                                            helperText={this.props.state.invoicedate ? '' : 'Enter invoice date'}
                                            onSelect={(args) => {
                                                this.props.actions.setValue('invoicedate', args)
                                            }}
                                            type={'date'}
                                        />
                            </Col>
                            <Col xl={4} style={{paddingTop:'15px'}} >
                                        <TextField
                                            id={'txtinvnumber'}
                                            hintText={'Enter invoice number'}
                                            fullWidth={true}
                                            multiLine={false}
                                            variant="outlined" 
                                            label={'Select Invoice Number'}
                                            error={this.props.state.invoice_number ? false : true}
                                            helperText={this.props.state.invoice_number ? '' : 'Enter invoice number'}
                                            value={this.props.state.invoice_number}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('invoice_number',  event.target.value)
                                            }}
                                        />
                            </Col>
                            <Col xl={4} style={{paddingTop:'15px'}} >
                                    <div style={styles.fieldTitle}>{'Vat Exclusive:'}</div>
                                    <div style={styles.fieldContent}>
                                         <Checkbox
                                            id={'chkvat'}
                                            checked={this.props.state.vatincl}
                                            onClick={(event, checked) => {
                                                this.props.actions.setValue('vatincl', checked)
                                            }} />
                                    </div>
                            </Col>
                            <Col xl={4}>
                                        <TextField
                                            id={'txtinvamount'}
                                            hintText={'Enter invoice total amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            variant="outlined" 
                                            label={'Invoice Total Amount'}
                                            error={this.props.state.invoice_amount ? false : true}
                                            helperText={this.props.state.invoice_amount ? '' : 'Enter invoice total amount'}
                                            value={this.props.state.invoice_amount}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('invoice_amount',  event.target.value)
                                            }}
                                        />
                            </Col>
                        </Row>
                    </Container>
        </div>
    }

    getLayout() {
        return  <div>
                <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
                <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
                <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
                <Card
                    title={'Authorisation Approval'}
                    subtitle={'Complete the form below to create booking'}
                    content={this.renderView()}
                />
            </div>
    }

    render() {
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        
        return this.data
            ?  this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingRebillInvoiceUpload
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingRebillInvoiceUpload)