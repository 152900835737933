import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                maintenanceclass: [],
                maintenanceitem: [],
                itemlist: [],
                loading: true,
                grid: []
            }
        case types.COMPONENT_GRID_LOADED:
            return {
                ...state,
                loading: action.payload.loading
            }
        case types.OPEN_QUOTE_ITEM_HISTORY:
            return {
                ...state,
                modelwin: action.payload.status,
                grid_item_inx: action.payload.inx,
            }
        case types.CLOSE_QUOTE_ITEM_HISTORY:
            return {
                ...state,
                modelwin: action.payload,
                grid_item_inx: 0,
            }
        case types.SET_SELECTED_ROW_RELATED_ITEMS_VALUES:
            return {
                ...state,
                grid_row: action.payload,
            }
        case types.TOGGLE_VEHICLE_TYRES_HISTORY_LIST:
            return {
                ...state,
                tyreshistory: action.payload
            }
        case types.TOGGLE_DRIVER_BEHAVIOUR:
            return {
                ...state,
                driverbehaviour: action.payload
            }
        case types.OPEN_VEHICLE_SERVICE_HISTORY_LIST:
            return {
                ...state,
                servicehistory: action.payload
            }
        case types.CLOSE_VEHICLE_SERVICE_HISTORY_LIST:
            return {
                ...state,
                servicehistory: action.payload
            }
        case types.ADD_QUOTE_ITEM_BLANK_ROW:
            return {
                ...state,
                grid: action.payload
            }
        case types.SET_COMPONENT_GRID_LOOKUP_FIELD_DATA:
            return {
                ...state,
                [action.payload.component]: action.payload.data
            }
        case types.SET_COMPONENT_GRID_PO_ITEMS_LOOKUP_FIELD_DATA:
            return {
                ...state,
                [action.payload.component]: action.payload.data
            }
        case types.SET_COMPONENT_SELECTED_GRID_LOOKUP_VALUE:
            return {
                ...state,
                grid: state.grid.map((x, i) =>
                    i === action.payload.inx
                        ? {
                            ...x,
                            [action.payload.field]: action.payload.text,
                            [action.payload.fieldpk]: action.payload.value
                        }
                        : x
                )
            }
        case types.SET_COMPONENT_INPUT_GRID_LOOKUP_VALUE:
            return {
                ...state,
                grid: state.grid.map((x, i) =>
                    i === action.payload.inx
                        ? {
                            ...x,
                            [action.payload.field]: action.payload.value
                        }
                        : x
                )
            }
        default:
            return state
    }
}