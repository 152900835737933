import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'

import { Table } from "@mui/material";

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import TextField from '../../controls/textField'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'

import Icon from '../Icon/component'
import PdfViewer from '../PDF/component'
import Upload from '../Upload/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'

class WorkflowPODeliveryComponent extends Component {

    componentDidMount() {
        this.props.actions.setValidationState('workflowPODelivery')
    }

    componentWillUnmount() {
        this.props.actions.setValidationState(null)
    }

    getContract(data) {
        return <Card
            title={window.t.en('Quote Confirmation')}
            subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Contract Type:', value: data.contractname },
                        { size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory },
                        { size: 4, label: 'Monthly Distance:', value: data.distancepermonth },
                        { size: 4, label: 'Contract Period:', value: data.contractperiod },
                        { size: 4, label: 'Quote For:', value: data.quotefor },
                        { size: 4, label: 'RFQ Date:', value: data.quotedate },
                        { size: 4, label: 'Vehicle Color:', value: data.color },
                        { size: 4, label: 'Vehicle Location:', value: data.location },
                        { size: 4, label: 'Special Vehicle:', value: data.specialistvehicle },
                        { size: 4, label: 'Vehicle Group:', value: data.vehiclegroup },
                        { size: 4, label: 'Operating Terrain:', value: data.terrain },
                        { size: 4, label: 'Vehicle Usage:', value: data.usage },
                        { size: 4, label: 'Comments:', value: data.comments },
                    ]}
                />
            }
        />
    }

    getCustomer(customer) {
        let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        return <Card
            title={window.t.en('Customer Details')}
            subtitle={window.t.en('Customer quote created for')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Customer Name:', value: customer.customername },
                        { size: 4, label: 'Cost Centre:', value: customer.costcentre },
                        { size: 4, label: 'Account Number:', value: customer.accountnumber },
                        { size: 4, label: 'Contact Person Name:', value: contact.fullname },
                        { size: 4, label: 'Contact Number:', value: contact.mobile },
                        { size: 4, label: 'Contact Email:', value: contact.email }
                    ]}
                />
            }
        />
    }

    getSupplier(data) {
        return <Card
            title={window.t.en('Supplier Details')}
            subtitle={window.t.en('Selected Vehicle Supplier ')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Supplier Name:', value: data.suppliername },
                        { size: 4, label: 'Trading Name:', value: data.tradingnamename },
                        { size: 4, label: 'Account Number:', value: data.accountnumber },
                        { size: 4, label: 'VAT Number:', value: data.vatnumber },
                        { size: 4, label: 'Contact Person:', value: data.contactperson },
                        { size: 4, label: 'Contact Email:', value: data.contactemail },
                        { size: 4, label: 'Contact Number:', value: data.contactnumber },
                    ]}
                />
            }
        />
    }

    getVehicle(data) {
        //console.log('Vehicle',data)
        return <Card
            title={window.t.en('Accepted Quote ' + data.quotenumber)}
            subtitle={window.t.en('Customer Quote Accepted')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Quote Number:', value: data.quotenumber },
                        { size: 4, label: 'System PO Number:', value: data.ponumber },

                        { size: 12, header: 'Vehicle Details' },
                        { size: 4, label: 'Vehicle Make:', value: data.mmcode.make },
                        { size: 4, label: 'Vehicle Model:', value: data.mmcode.cdescription },

                        { size: 4, label: 'MM Code:', value: data.mmcode.mmcode },
                        { size: 4, label: 'Transmission:', value: data.mmcode.manualauto },

                        { size: 4, label: 'Introduction Year:', value: data.mmcode.regyear },
                        { size: 4, label: 'Body Type:', value: data.mmcode.bodytype },

                        { size: 4, label: 'Contract Type:', value: data.contractname },
                        { size: 4, label: 'Vehicle Category:', value: data.mmcode.vehiclecategory },

                        { size: 4, label: 'Monthly Distance', value: data.distancepermonth },
                        { size: 4, label: 'Contract Period:', value: data.contractperiod },

                        // {size: 4, label: 'Rate Card:', value: data.ratecard},
                        { size: 4, label: 'Delivery Period:', value: data.deliveryperiod },

                        { size: 12, header: 'Rental Breakdown' },
                        { size: 4, label: 'Retail Price:', value: data.retailprice },
                        { size: 4, label: 'Discount:', value: data.discount },

                        { size: 4, label: 'Interest Rate:', value: data.primerate },
                        { size: 4, label: 'Vehicle Price:', value: data.vehicleprice },

                        { size: 12, header: 'Monthly Rental' },
                        { size: 4, label: 'Rental Excl:', value: data.monthly_excl },

                        { size: 4, label: 'Vat:', value: data.monthly_vat },
                        { size: 4, label: 'Rental Incl:', value: data.monthly_incl },
                    ]}
                />
            }
        />
    }


    getDownload(documents) {
        let document = documents.filter(x => x.filetype === 'VAF')
        return <Card
            title={window.t.en('Vehicle Acceptance Form')}
            subtitle={window.t.en('Download Customer Vehicle Acceptance Form')}
            content={
                document.length
                    ? <PdfViewer
                        key={'vaf'}
                        documents={document}
                        multiple={true}
                        height={'500px'}
                        preview={'preview'}
                    />
                    : 'Loading...'
            }
        />
    }

    getDelivery() {
        return <Card
            title={window.t.en('Vehicle Acceptance Form')}
            subtitle={window.t.en('Download Customer Vehicle Acceptance Form')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Do you require courier service to deliver the vehicle?'}</Col>
                        <Col xl={6} style={styles.selectedFieldName}>
                            <Checkbox
                                key={'chk_Khula'}
                                label={'Do you require courier service to deliver the vehicle?'}
                                labelPosition='left'

                                style={styles.toggle}
                                trackStyle={{
                                    backgroundColor: 'gray'
                                }}
                                trackSwitchedStyle={{
                                    backgroundColor: 'green'
                                }}
                                checked={this.props.state.khula}
                                onChange={
                                    (event, isInputChecked) => {
                                        this.props.actions.setInputValue({ prop: 'khula', value: isInputChecked })
                                    }
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} style={styles.selectedFieldName}>
                            <br />
                        </Col>
                    </Row>
                    {
                        this.props.state.courier
                            ? <div>
                                <Row>
                                    <Col xl={12} style={styles.fieldHeader}>
                                        {'Infleet Delivery Service'}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Enter Fuel Amount (excl):'}</div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    variant="outlined"
                                                    id={'txt_fuel_cost'}
                                                    hintText={'Enter Fuel Amount'}
                                                    fullWidth={true}
                                                    value={this.props.state.khulaprice}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue({ prop: 'khulaprice', value: event.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Expected Delivery Date:'}</div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    type="date"
                                                    id={this.field.preinspectiondate.name}
                                                    hintText='Select Date'
                                                    container='inline'
                                                    mode='landscape'
                                                    value={this.props.state.deliverydate}
                                                    defaultDate={this.props.state.deliverydate}
                                                    onSelect={(args) => {
                                                        this.props.actions.setInputValue({ prop: 'deliverydate', value: args })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Do we Need to Make Provision for The Drivers Return:'}</div>
                                            <div style={styles.fieldContentRight}>
                                                <Checkbox
                                                    key={'chk_isreturn'}
                                                    label={' '}
                                                    labelPosition='right'

                                                    style={styles.toggle}
                                                    trackStyle={{
                                                        backgroundColor: 'gray'
                                                    }}
                                                    trackSwitchedStyle={{
                                                        backgroundColor: 'green'
                                                    }}
                                                    checked={this.props.state.isreturn}
                                                    onChange={
                                                        (event, isInputChecked) => {
                                                            this.props.actions.setInputValue({ prop: 'isreturn', value: isInputChecked })
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Enter Delivery Persons Name:'}</div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    variant="outlined"
                                                    id={'txt_drivername'}
                                                    hintText={'Enter Driver Name'}
                                                    fullWidth={true}
                                                    value={this.props.state.drivername}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue({ prop: 'drivername', value: event.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Select Fuel Supplier:'}</div>
                                            <div style={styles.fieldContent}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'ddl_khula_input'}
                                                    key={'key_khula_input'}
                                                    hintText={'Select Fuel Supplier '}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    fieldvalue={'value'}
                                                    displayfields={['text']}
                                                    data={this.props.state.supplier_data}
                                                    onSelect={
                                                        (chosenRequest, index) => {
                                                            this.props.actions.setInputValue({ prop: 'fuelsupplier_id', value: chosenRequest.value })
                                                            this.props.actions.setInputValue({ prop: 'fuelsupplier', value: chosenRequest.text })
                                                        }
                                                    }
                                                    onClick={
                                                        (event) => {
                                                            this.props.actions.getLookupData('supplier', 'suppliername', '')
                                                        }
                                                    }
                                                    onFilter={
                                                        (search) => {
                                                            if (search) {
                                                                this.props.actions.getLookupData('supplier', 'suppliername', search)
                                                                const data = this.props.state.supplier_data
                                                                const supplier = data.find(s => s.text === search)
                                                                if (supplier) this.props.actions.setInputValue({ prop: 'fuelsupplier_id', value: supplier.value })
                                                                this.props.actions.setInputValue({ prop: 'fuelsupplier', value: search })
                                                            } else {
                                                                this.props.actions.getLookupData('supplier', 'suppliername', '')
                                                                this.props.actions.setInputValue({ prop: 'fuelsupplier_id', value: null })
                                                                this.props.actions.setInputValue({ prop: 'fuelsupplier', value: '' })
                                                            }

                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            : ''
                    }
                    {

                        this.props.state.khula
                            ? <div>
                                <Row>
                                    <Col xl={12} style={styles.fieldHeader}>
                                        {'Courier Delivery Service'}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={4}>
                                        <TextField
                                            variant="outlined"
                                            id={'txt_cuorier_price'}
                                            hintText={'Invoice Amount (excl):'}
                                            fullWidth={true}
                                            value={this.props.state.courierprice}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue({ prop: 'courierprice', value: event.target.value })
                                            }}
                                        />
                                    </Col>
                                    <Col xl={4} style={{ marginTop: '-15px' }}>
                                        <DateControl
                                            id={'txt_poddate'}
                                            key={'txt_poddate'}
                                            label={'Expected Delivery Date:'}
                                            name={'txt_poddate'}
                                            value={this.props.state.deliverydate}
                                            onSelect={(args) => {
                                                this.props.actions.setInputValue({ prop: 'deliverydate', value: args })
                                            }}
                                            type={'date'}
                                        />
                                    </Col>
                                    <Col xl={4} style={{ marginTop: '-10px' }}>
                                        <AutoComplete
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'ddl_courier_input'}
                                            key={'key_courier_input'}
                                            hintText={'Select Courier Supplier'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            fieldvalue={'value'}
                                            displayfields={['text']}
                                            data={this.props.state.supplier_data}
                                            onSelect={
                                                (chosenRequest, index) => {
                                                    this.props.actions.setInputValue({ prop: 'couriersupplier_id', value: chosenRequest.value })
                                                    this.props.actions.setInputValue({ prop: 'couriersupplier', value: chosenRequest.text })
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getLookupData('supplier', 'suppliername', '')
                                                }
                                            }
                                            onFilter={(search) => {
                                                if (search) {
                                                    this.props.actions.getLookupData('supplier', 'suppliername', search)
                                                    this.props.actions.setInputValue({ prop: 'couriersupplier', value: search })
                                                    const data = this.props.state.supplier_data
                                                    const supplier = data.find(s => s.couriersupplier === search)
                                                    if (supplier) this.props.actions.setInputValue({ prop: 'couriersupplier_id', value: supplier.couriersupplier_id })
                                                } else {
                                                    this.props.actions.getLookupData('supplier', 'suppliername', '')
                                                    this.props.actions.setInputValue({ prop: 'couriersupplier_id', value: null })
                                                    this.props.actions.setInputValue({ prop: 'couriersupplier', value: '' })
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} style={styles.uploadControl}>
                                        <Upload
                                            id={'delivery_po_inv'}
                                            prefix={'delivery_po_inv'}
                                            header={false}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            : ''
                    }
                </Container>
            }
        />
    }

    getView(data) {
        return <div>
            {this.getContract(data.vehicle)}
            {this.getCustomer(data.customer)}
            {this.getSupplier(data.supplier)}
            {this.getVehicle(data.vehicle)}
            {this.getDownload(data.documents)}
            {this.getDelivery()}
        </div>
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log('WorkflowPODelivery workflow_queue_data', workflow_queue_data)
        let data = null
        try {
            data = this.props.workflow_queue_data.context_data.data.data.data[0]
            //console.log('WorkflowPODelivery workflow_queue_data2', workflow_queue_data)
        }
        catch (err) {
            //console.log('WorkflowPODelivery err', err)
        }

        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowPODelivery
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            { ...actions, ...workflowTaskInputActions },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPODeliveryComponent)