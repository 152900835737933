export const WORKFLOW_FML_QUOTE_SUBMIT_FORM = 'WORKFLOW_FML_QUOTE_SUBMIT_FORM'
export const SET_WORKFLOW_COMPONENT_INPUT_FIELD_VALUE = 'SET_WORKFLOW_COMPONENT_INPUT_FIELD_VALUE'
export const GET_WORKFLOW_COMPONENT_INPUT_LOOKUP_DATA = 'GET_WORKFLOW_COMPONENT_INPUT_LOOKUP_DATA'
export const SET_WORKFLOW_COMPONENT_INPUT_LOOKUP_DATA = 'SET_WORKFLOW_COMPONENT_INPUT_LOOKUP_DATA'
export const SET_WORKFLOW_FORM_INPUT_FIELD_VALUE = 'SET_WORKFLOW_FORM_INPUT_FIELD_VALUE'
export const SET_WORKFLOW_FORM_INPUT_FIELD_TEXT = 'SET_WORKFLOW_FORM_INPUT_FIELD_TEXT'
export const SET_WORKFLOW_COMPONENT_INPUT_FIELD_TEXT = 'SET_WORKFLOW_COMPONENT_INPUT_FIELD_TEXT'
export const SET_WORKFLOW_FORM_COMPONENT_INPUT_LOOKUP_DATA = 'SET_WORKFLOW_FORM_COMPONENT_INPUT_LOOKUP_DATA'
export const GET_CUSTOMER_DERIVATIVE_VALUES = 'GET_CUSTOMER_DERIVATIVE_VALUES'
export const SET_CUSTOMER_DERIVATIVE_VALUES = 'SET_CUSTOMER_DERIVATIVE_VALUES'
export const UPLOAD_MULTIPLE_IMAGE_COMPONENT_UPLOADER = 'UPLOAD_MULTIPLE_IMAGE_COMPONENT_UPLOADER'
export const SET_CONTRACT_REPLACE_VEHICLE_DATA = 'SET_CONTRACT_REPLACE_VEHICLE_DATA'
export const GET_CONTRACT_REPLACE_VEHICLE_DATA = 'GET_CONTRACT_REPLACE_VEHICLE_DATA'
export const ADD_COMPONENT_SUBFORM_FIELDS_DATA = 'ADD_COMPONENT_SUBFORM_FIELDS_DATA'
export const SET_SELECTED_VEHICLE_MMCODE_DATA = 'SET_SELECTED_VEHICLE_MMCODE_DATA'
export const SET_SELECTED_CUSTOMER_DATA = 'SET_SELECTED_CUSTOMER_DATA'
export const SET_SELECTED_VEHICLE_CATEGORY = 'SET_SELECTED_VEHICLE_CATEGORY'
export const GET_SELECTED_VEHICLE_CATEGORY_CHIP = 'GET_SELECTED_VEHICLE_CATEGORY_CHIP'
export const SET_SELECTED_VEHICLE_CATEGORY_CHIP = 'SET_SELECTED_VEHICLE_CATEGORY_CHIP'
export const SET_SELECTED_QUOTE_FOR = 'SET_SELECTED_QUOTE_FOR'

export const GET_COMPONENT_LIST_LOOKUP_DATA = 'GET_COMPONENT_LIST_LOOKUP_DATA'
export const SET_COMPONENT_LIST_LOOKUP_DATA = 'SET_COMPONENT_LIST_LOOKUP_DATA'
export const SET_COMPONENT_SELECTED_LIST_LOOKUP_DATA = 'SET_COMPONENT_SELECTED_LIST_LOOKUP_DATA'
export const CONFIRM_WORKFLOW_CRAETE_FML_QUOTATION_HEADER_SELECTION = 'CONFIRM_WORKFLOW_CRAETE_FML_QUOTATION_HEADER_SELECTION'