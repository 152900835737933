export const SET_SELECTED_SUPPLIER = 'SET_SELECTED_SUPPLIER'
export const GET_SUPPLIER_LIST = 'GET_SUPPLIER_LIST'
export const SET_SUPPLIER_LIST = 'SET_SUPPLIER_LIST'

export const GET_SELECTED_SUPPLIER_ACCESSORY_LIST = 'GET_SELECTED_SUPPLIER_ACCESSORY_LIST'
export const SET_SELECTED_SUPPLIER_ACCESSORY_LIST = 'SET_SELECTED_SUPPLIER_ACCESSORY_LIST'
export const SET_ACCESSORY_LIST_SEARCH_TEXT = 'SET_ACCESSORY_LIST_SEARCH_TEXT'
export const ADD_NEW_SUPPLIER_PRICING_PROFILE_ITEM = 'ADD_NEW_SUPPLIER_PRICING_PROFILE_ITEM'

export const UPDATE_SUPPLIER_ACCESSORY_SELLING_PRICE = 'UPDATE_SUPPLIER_ACCESSORY_SELLING_PRICE'
export const UPDATE_SUPPLIER_ACCESSORY_COST_PRICE = 'UPDATE_SUPPLIER_ACCESSORY_COST_PRICE'
export const REMOVE_SELECTED_SUPPLIER_ACCESSORY_LIST = 'REMOVE_SELECTED_SUPPLIER_ACCESSORY_LIST'

export const GET_ALL_ACCESSORY_LIST = 'GET_ALL_ACCESSORY_LIST'
export const SET_ALL_ACCESSORY_LIST = 'SET_ALL_ACCESSORY_LIST'
export const SET_SELECTED_ACCESSORY = 'SET_SELECTED_ACCESSORY'
export const UPDATE_SELECTED_ACCESSORY = 'UPDATE_SELECTED_ACCESSORY'