import * as types from './types'

export const addCalculator = () => {
    return {
        type: types.ADD_CALCULATOR
    }
}

export const removeCalculator = (id) => {
    return {
        type: types.REMOVE_CALCULATOR,
        payload: id
    }
}