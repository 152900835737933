import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import SignedIn from '../SignedIn/component'
import SignedOut from '../SignedOut/component'
// import SignedOut from '../SignedOut/component'
import I18next from '../I18next/component'
import Loading from '../Loading/component'
import Permission from '../Permission/component'


import { useSearchParams } from 'react-router-dom'

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import * as actions from './actions'
import * as accountActions from '../Account/actions'
import * as cognitoActions from '../Cognito/actions'
import * as routerActions from '../Redirect/actions'
import * as componentActions from '../Components/actions'
import * as workflowActions from '../Workflows/actions'

import CountDown from 'react-number-count-down';

import { signIn, signOut, signUp } from 'aws-amplify/auth'
// import '../../import'
import '../../sass-sub'


import * as styles from './styles'
import * as config from '../../config'

class App extends Component {
    resize = () => this.forceUpdate()

    static propTypes = {
        serverSideScreenClass: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    }

    static defaultProps = {
        serverSideScreenClass: 'xl',
    }

    static childContextTypes = {
        serverSideScreenClass: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
        breakpoints: PropTypes.arrayOf(PropTypes.number),
        containerWidths: PropTypes.arrayOf(PropTypes.number)
    }

    getChildContext = () => ({
        serverSideScreenClass: this.props.serverSideScreenClass,
        breakpoints: [576, 768, 800, 1200],
        containerWidths: [576, 768, 800, 1900],
    })

    componentWillMount() {
        this.props.actions.setSelectedSetting({ prop: 'locale', value: config.system.locale })
        window.addEventListener('resize', this.resize)
        //document.body.addEventListener('click', this.props.actions.hideHierarchy())

        window.glyco = {
            log: (...args) => {
                if (process.env.NODE_ENV !== 'production') {
                    //console.log(...args)
                }
            }
        }

        window.t = {
            en: (args) => {
                return args // <I18next i18n={args} lang={this.props.state.accounts.locale ? this.props.state.accounts.locale : 'ar'} />
            }
        }

        // Auth.currentAuthenticatedUser()
        //     .then(user => {
        //         //console.log('currentAuthenticatedUser',user)
        //         this.props.actions.setCognitoUserAuthenticationDetails(user)
        //     })
        //     .catch(() => //console.log("AppTracking Not signed in"));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    setBackground() {
        this.props.state.signedIn && this.props.state.accounts.teams.length
            ? document.body.className = 'logged_in'
            : document.body.className = 'logged_out'
    }

    handleSingout = () => {
        //this.props.actions.refreshUserAuthenticationToken();
        signOut()
            .then(data => {
                this.props.actions.signoutCognitoUser()
            })
            .catch(err => {
                //console.log('handleSingout Err', err)
            });
    }

    getLayout() {
        return (
            <div style={{overflow: 'hidden'}}>
                {
                    this.props.state.signedIn
                        ? <SignedIn />
                        : <SignedOut /> 
                        // <AmplifySignIn />
                        // ? this.props.state.messenger.connected //|| this.props.state.router.pathname === '/permission'
                        //     ? <SignedIn />
                        //     : <Loading message={window.t.en('Establishing socket connection, please wait')}/> // style={{color: 'white'}}>Connecting, please wait...</Loading> 
                        //  : <Loading message={window.t.en('Connection not available, please wait, retrying')}/> // style={{color: 'white'}}>Loading, please wait....</Loading> 
                }

                {
                    <Dialog
                        open={this.props.state.sessionexpired}
                        style={{ width: '100% !important', maxWidth: '100% !important' }}
                        fullWidth={true}
                        maxWidth="xl"
                    >
                        <div className="text-center p-5">
                            <div className="avatar-icon-wrapper rounded-circle m-0">

                            </div>
                            <h4 className="font-weight-bold mt-4">
                                Your Session Will Expire In:
                            </h4>
                            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                                <h2>
                                    <CountDown from={30} to={1} type={'-'} addon={'seconds'} interval={1} onComplete={() => {
                                        this.props.actions.closeExpiryDialog(false)
                                        this.handleSingout();
                                        this.props.actions.clearApplicationCache();
                                    }} />
                                </h2>
                            </div>
                            <p className="mb-0 font-size-lg text-muted">
                                If no action, the application will automatically log you out.
                            </p>
                            <div className="pt-4">
                                <Button
                                    onClick={(event) => {
                                        this.props.actions.closeExpiryDialog(false)
                                        //this.props.actions.getCognitoUserAuthenticationDetails(this.props.state.login.email.input,this.props.state.login.keyword.input)
                                    }}
                                    className="btn-pill mx-1">
                                    <span className="btn-wrapper--label">RENEW</span>
                                </Button>
                                <Button
                                    onClick={(event) => {
                                        this.props.actions.closeExpiryDialog(false)
                                        this.handleSingout()
                                        this.props.actions.clearApplicationCache()
                                    }}
                                    className=" btn-pill mx-1">
                                    <span className="btn-wrapper--label">LOGOUT</span>
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                }

                <Dialog
                    open={this.props.state.dialog}
                >
                    <div className="text-center p-5">
                        <div className="avatar-icon-wrapper rounded-circle m-0">
                            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">

                            </div>
                        </div>
                        <h4 className="font-weight-bold mt-4">
                            Are you sure you want to delete this entry?
                        </h4>
                        <p className="mb-0 font-size-lg text-muted">
                            You cannot undo this operation.
                        </p>
                        <div className="pt-4">
                            <Button
                                onClick={(event) => {
                                    this.props.actions.closePopupDialog(false)
                                }}
                                className="btn-neutral-secondary btn-pill mx-1">
                                <span className="btn-wrapper--label">Cancel</span>
                            </Button>
                            <Button
                                onClick={(event) => {
                                    this.props.actions.closePopupDialog(false)
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">Ok</span>
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    open={this.props.state.loggedTwice}
                    style={{ width: '100% !important', maxWidth: '100% !important' }}
                    fullWidth={true}
                    maxWidth="xl"
                //onClose={this.props.state.dialog}
                //classes={{ paper: 'shadow-lg rounded' }}
                >

                    <div className="text-center p-5">
                        <div className="avatar-icon-wrapper rounded-circle m-0">

                        </div>
                        <h4 className="font-weight-bold mt-4">
                            You have logged in twice
                        </h4>
                        <h4 className="font-weight-bold mt-6">
                            logging out in:
                        </h4>
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                            <h2>
                                <CountDown from={10} to={0} type={'-'} addon={'seconds'} interval={1} onComplete={() => { this.props.actions.clearApplicationCache(); this.handleSingout(); }} />
                            </h2>
                        </div>
                        <p className="mb-0 font-size-lg text-muted">
                            You have logged in twice, logout or close the open tabs.
                        </p>
                        <div className="pt-4">
                            <Button
                                onClick={(event) => {
                                    this.handleSingout()
                                    this.props.actions.closeExpiryDialog(false)
                                    this.props.actions.clearApplicationCache()
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">LOGOUT</span>
                            </Button>
                            <Button
                                onClick={(event) => {
                                    this.props.actions.disconectMessenger(false)
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">Force Logout and Continue</span>
                            </Button>
                        </div>
                    </div>
                </Dialog>

            </div>
        )
    }

    render() {
        return (
            [this.setBackground(),this.getLayout()]
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.app,
            accounts: {
                ...state.accounts
            },
            user: {
                ...state.user
            },
            messenger: {
                ...state.messenger
            },
            login: {
                ...state.login.fields
            },
            router: {
                ...state.router.location
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            ...actions,
            ...cognitoActions,
            ...routerActions,
            ...componentActions,
            ...workflowActions,
            ...accountActions
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)