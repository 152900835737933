import * as types from './types'

export const addContact = () => {
    return (dispatch, getState) => {
        let contacts = getState().supplierMasterCapture.contacts
        contacts.push({})
        dispatch(setInputValue('contacts',contacts))
    }
}

export const delContact = (inx) => {
    return (dispatch, getState) => {
        let contacts = getState().supplierMasterCapture.contacts
        delete contacts[inx]
        contacts = contacts.filter(x => x)
        dispatch(setInputValue('contacts',contacts))
    }
}


export const setContactInputValue = (data) => {
    return (dispatch, getState) => {
        let contacts = getState().supplierMasterCapture.contacts
        contacts[data.inx][data.prop] = data.value
        dispatch(setInputValue('contacts',contacts))
    }
}

export const addProduct= () => {
    return (dispatch, getState) => {
        let products = getState().supplierMasterCapture.products
        products.push({})
        dispatch(setInputValue('products',products))
    }
}

export const delProduct = (inx) => {
    return (dispatch, getState) => {
        let products = getState().supplierMasterCapture.products
        delete products[inx]
        products = products.filter(x => x)
        dispatch(setInputValue('products',products))
    }
}


export const setProductInputValue = (data) => {
    return (dispatch, getState) => {
        let products = getState().supplierMasterCapture.products
        products[data.inx][data.prop] = data.value
        dispatch(setInputValue('products',products))
    }
}

export const addAddress = () => {
    return (dispatch, getState) => {
        let address = getState().supplierMasterCapture.address
        address.push({})
        dispatch(setInputValue('address',address))
    }
}

export const delAddress = (inx) => {
    return (dispatch, getState) => {
        let address = getState().supplierMasterCapture.address
        delete address[inx]
        address = address.filter(x => x)
        dispatch(setInputValue('address',address))
    }
}

export const setAddressInputValue = (data) => {
    return (dispatch, getState) => {
        let address = getState().supplierMasterCapture.address
        address[data.inx][data.prop] = data.value
        dispatch(setInputValue('address',address))
    }
}
export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_SUPPLIER_MASTER_CAPTURE_FORM,
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_SUPPLIER_MASTER_CAPTURE_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_SUPPLIER_MASTER_CAPTURE_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
                query: `?${field}=${search}`
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setLookupData response', response)
        //console.log('setLookupData payload', payload)
        dispatch(setInputValue(payload.component + '_data',response.data))
    }
}

export const setupSupplierMasterWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            //type: types.CONFIRM_CREDITORS_PAYMENT_CAPTURE,
            
            type: types.CONFIRM_SUPPLIER_MASTER_CAPTURE,
            payload: payload
        })
    }
}