import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

// import TextField from '@mui/material/TextField'

import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'
import * as actions from './actions'
import * as styles from './styles'

import Loading from '../../Loading/component'

import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'
import Card from '../../../controls/card'
import Upload from '../../Upload/component'

class POBookingAcceptRebill extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.setValidationState('poBookingAcceptRebill')
    }

    renderView(data) {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'Rebill_PurchaseOrder_'+this.data.step1.vehicle+'_'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4} style = {styles.dc}>
                                        <DateControl
                                            id={'txtbookingdate'}
                                            key={'txtbookingdate'}
                                            label={'Select vehicle booking date'}
                                            name={'txtbookingdate'}
                                            error={this.props.state.bookingdate ? false : true}
                                            helperText={this.props.state.bookingdate ? '' : 'Enter booking date'}
                                            value={this.props.state.bookingdate}
                                            onSelect={(args) => {
                                                //console.log('args date',args)
                                                this.props.actions.setValue('bookingdate', args)
                                            }}
                                            type={'date'}
                                        />
                            </Col>
                            <Col xl={4} style = {styles.dc}>
                                        <DateControl
                                            id={'txtinvdate'}
                                            key={'txtinvdate'}
                                            label={'Select PO signed date'}
                                            name={'txtinvdate'}
                                            error={this.props.state.po_date ? false : true}
                                            helperText={this.props.state.po_date ? '' : 'Enter PO signed date'}
                                            value={this.props.state.po_date}
                                            onSelect={(args) => {
                                                //console.log('args date',args)
                                                this.props.actions.setValue('po_date', args)
                                            }}
                                            type={'date'}
                                        />
                            </Col>
                            <Col xl={4} style = {styles.bc}>
                                        <TextField
                                            id={'txtinvnumber'}
                                            variant="outlined" 
                                            hintText={'Enter purchase order number'}
                                            fullWidth={true}
                                            label={'Purchase Order Number'}
                                            multiLine={false}
                                            error={this.props.state.po_number ? false : true}
                                            helperText={this.props.state.po_number ? '' : 'Enter purchase order number'}
                                            value={this.props.state.po_number}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('po_number', event.target.value)
                                            }}
                                        />
                            </Col>
                            <Col xl={4}>
                                        <TextField
                                            id={'txtinvamount'}
                                            variant="outlined" 
                                            hintText={'Enter purchase order total amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            label={'Purchase Order Total Amount'}
                                            error={this.props.state.po_amount ? (this.props.state?.totalamount === this.props.state.po_amount) : true}
                                            helperText={this.props.state.po_amount ? '' : 'Enter purchase order total amount'}
                                            value={this.props.state.po_amount}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('po_amount', event.target.value, isNaN(this.props.state.po_amount) ? (this.props.state.totalamount === event.target.value) : true)
                                            }}
                                        />
                            </Col>
                        </Row>
                    </Container>
        </div>
    }

    getLayout() {
        return  this.data.step1.vehicle_id
        ? <div>
                <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
                <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
                <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
                <Card
                    title={'Accept Customer Rebill'}
                    subtitle={'Upload customer rebill and completed the form below'}
                    content={this.renderView()}
                />
            </div>
            : <div>
            <ViewVehicle key={this.data.step1.data.vehicle[0].vehicle_id} id={this.data.step1.data.vehicle[0].vehicle_id} />         
            <ViewSupplier key={this.data.step1.data.supplier[0].supplier_id} id={this.data.step1.data.supplier[0].supplier_id} />
            <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
            <Card
                title={'Accept Customer Rebill'}
                subtitle={'Upload customer rebill and completed the form below'}
                content={this.renderView()}
            />
        </div>
    }

    render() {
        // this.data = null
        // try {
        //     this.data = this.props.workflow_queue_data.context_data.data.data.data
        // } catch (error) {

        // }
        // 

        
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data.length
            ? this.props.workflow_queue_data.context_data.data.data.data[0]
            : this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        

        return this.data
            ?  this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingAcceptRebill,
            maintenance: {
                ...state.controls.maintenance
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingAcceptRebill)