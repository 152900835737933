import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
//import jwt from 'jsonwebtoken'

import I18next from '../I18next/component'
// import '../../import'
// import '../../sass-sub'

import { Amplify } from 'aws-amplify';
import { signIn, signOut, signUp, confirmSignIn, confirmResetPassword, confirmSignUp, resetPassword, fetchAuthSession} from 'aws-amplify/auth'

import awsconfig from '../../aws-exports';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOpen';
import Link from '@mui/material/Link';
import { Card, Dialog, InputAdornment, IconButton, InputLabel, Avatar, OutlinedInput, FormControl } from '@mui/material'
import { styled } from '@mui/system';
import Container from '@mui/material/Container';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import CssBaseline from '@mui/material/CssBaseline';
import Hooks from '../Hooks/component'
import Logo from '../Logo/component'
import App from "../App/component";

import * as actions from './actions'
import * as config from '../../config'
import { color } from '../WorkflowPayment/PaymentApprovalRelease/styles'


Amplify.configure(awsconfig);

// class authAmplify extends Component {
//     render() {
//         return (
//            <App auth={'Auth'} />
//         )
//     }
// }

// const CustomTextField = styled(TextField)({
//     '& .MuiInputBase-input': {
//         borderColor: 'white !important', 
//         color: '#000 !important',
//     },
//     '& .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#253053 !important', 
//       color: '#000 !important',
//     },
//     '&:hover .MuiOutlinedInput-notchedOutline': {
//       borderColor: 'white !important', 
//       color: '#000 !important',
//     },
//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: 'white !important', 
//       color: '#000 !important',
//     },
//   });

//   const CustomOutlinedInput = styled(OutlinedInput)({
//     '& .MuiOutlinedInput-notchedOutline': {
//       borderColor: '#253053 !important',
//       color: '#000 !important',
//     },
//     '&:hover .MuiOutlinedInput-notchedOutline': {
//       borderColor: 'white !important', 
//       color: '#000 !important',
//     },
//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: 'white !important', 
//       color: '#000 !important',
//     },
//   });


  const CustomTextField = styled(TextField)(({ theme, textColor }) => ({
    '& .MuiInputBase-input': {
        borderColor: `${textColor} !important`,
        color: `${textColor} !important`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `#253053 !important`, 
      color: `${textColor} !important`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${textColor} !important`,
      color: `${textColor} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${textColor} !important`,
      color: `${textColor} !important`,
    },
  }));

  const CustomOutlinedInput = styled(OutlinedInput)(({ theme, textColor }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `#253053 !important`,
      color: `${textColor} !important`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${textColor} !important`,
      color: `${textColor} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${textColor} !important`,
      color: `${textColor} !important`,
    },
  }));

  //euginen@infleetint.com
  //iFfuO92

class AmplifySignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
            username: 'euginen@infleetint.com',
            password: 'iFfuO92.',
            //password: 'Enkw#610230',
            firstname: '',
            lastname: '',
            name: '',
            error: '',
            code: '',
            key: '',
            form: 'login',
            success: '',
            cognito_key: '',
            loading: false,
            initsignup: false,
            tandc_win: false,
            showpassword: false
        }
    }
    componentDidMount() {
        signOut();

        // window.t = {
        //     en: (args) => {

        //         console.log('i18n.language args am',this.props.state.accounts.locale ? this.props.state.accounts.locale : 'ar',args)
        //         return <I18next i18n={args} lang={this.props.state.accounts.locale ? this.props.state.accounts.locale : 'ar'} />
        //     }
        // }

        var queryString = window.location.search.substring(1);
        if (queryString) {
            try {
                const sp = new URLSearchParams(queryString);
                let token = sp.get('token')

                let decoded = jwt.verify(token, 'SuperSecretKey');

                if (decoded.email_address) {
                    this.setState({ form: 'sign_up' })
                    this.setState({ username: decoded.email_address })
                    this.setState({ name: decoded.firstname })
                    this.setState({ firstname: decoded.firstname })
                    this.setState({ lastname: decoded.lastname })
                    this.setState({ phone: decoded.contact_number })
                }

                if (decoded.email) {
                    this.setState({ form: 'sign_up' })
                    this.setState({ username: decoded.email })
                    this.setState({ name: decoded.firstname })
                    this.setState({ firstname: decoded.firstname })
                    this.setState({ lastname: decoded.lastname })
                    this.setState({ phone: decoded.phone })
                }
            }
            catch (err) { }
        }
    }

    setBackground() {
        document.body.className = 'logged_out'
    }

    showPasswordInput = () => {
        this.setState({ showpassword: !this.state.showpassword })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleLogin()
        }
    }

    handleLogin = () => {
        this.setState({ loading: true })
        this.setState({ error: '' })
        this.setState({ success: 'Please wait...' })
        signOut()
            .then(_user => {
                signIn({ username: this.state.username, password: this.state.password})
                    .then(user => {
                        console.log('amplifyx user', user)
                        console.log('amplifyx isSignedIn', user.isSignedIn)

                        if(user.nextStep){
                            if(user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                                    this.setState({ error: '' })
                                    this.setState({ success: 'Accepting password challenge' })
                                    //const { requiredAttributes } = user.challengeParam; //the array of required attributes, e.g ['email', 'phone_number']

                                    const challengeResponse = this.state.password;
                                    //await confirmSignIn({ this.state.password });

                                    // confirmSignIn({
                                    //     username: this.state.username,
                                    //     password: this.state.password
                                    // })

                                    confirmSignIn({ challengeResponse })
                                    .then((user) => {
                                        // at this time the user is logged in if no MFA required
                                        console.log(user);
                                        
                                        this.setState({ error: '' })
                                        this.setState({ success: 'Challenge accepted, Login Again' })
                                        this.setState({ loading: false })
                                        //signIn(this.state.username, this.state.password)
                                        // signIn({ username: this.state.username, password: this.state.password})
                                        // .then(user => {
                                        //     this.setState({ error: '' })
                                        //     this.setState({ success: 'Successfully login' })
                                        //     this.props.actions.login(user)
                                        // })
                                    })
                                    .catch((err) => {
                                        this.setState({ success: '' })
                                        this.setState({ error: err.message })
                                        console.log(err);
                                    });
                            }
                        }


                        if(user.isSignedIn) {
                            fetchAuthSession().then(session => {
                                console.log('amplifyx fetchAuthSession', session)

                                if (session.userConfirmed === false) {
                                    this.setState({ success: '' })
                                    this.setState({ form: 'confirm_signup' })
                                    this.setState({ loading: false })
                                }
                                else if (session.challengeName === "NEW_PASSWORD_REQUIRED") {
                                    
                                    this.setState({ error: '' })
                                    this.setState({ success: 'Accepting password challenge' })
                                    const { requiredAttributes } = user.challengeParam; //the array of required attributes, e.g ['email', 'phone_number']
                                    confirmSignIn({
                                        username: this.state.username,
                                        password: this.state.password
                                    })
                                        .then((user) => {
                                            // at this time the user is logged in if no MFA required
                                            console.log(user);
                                            //signIn(this.state.username, this.state.password)
                                            signIn({ username: this.state.username, password: this.state.password})
                                            .then(user => {
                                                this.setState({ error: '' })
                                                this.setState({ success: 'Successfully login' })
                                                console.log('amplifyx user', user)
                                                let su = {
                                                    username: user.tokens.signInDetails,
                                                    attributes: {
                                                        sub: user.userSub
                                                    },
                                                    tokens: user.tokens,
                                                    credentials: user.credentials,
                                                    signInUserSession: {
                                                        idToken: {
                                                            jwtToken: user.credentials.sessionToken
                                                        }
                                                    }

                                                }
                                                this.props.actions.login(su)
                                            })
                                        })
                                        .catch((err) => {
                                            this.setState({ success: '' })
                                            this.setState({ error: err.message })
                                            console.log(e);
                                        });
                                } else {
                                    this.setState({ error: '' })
                                    this.setState({ success: 'Successfully login' })
                                    console.log('amplifyx sessions', session)
                                    
                                    let su = {
                                        username: session.tokens.signInDetails,
                                        attributes: {
                                            sub: session.userSub
                                        },
                                        tokens: session.tokens,
                                        credentials: session.credentials,
                                        signInUserSession: {
                                            idToken: {
                                                jwtToken: session.credentials.sessionToken
                                            }
                                        }

                                    }
                                    this.props.actions.login(su)
                                }
                            });
                        }
                    })
                    .catch(err => {
                        console.log('handleLogin', err)
                        this.setState({ success: '' })
                        if (err.code === 'UserNotConfirmedException') {
                            this.setState({ form: 'confirm_signup' })
                        }
                        this.setState({ error: err.message })
                        this.setState({ loading: false })
                    });
            })
    }

    handleVerification = () => {
        this.setState({ error: '' })
        this.setState({ success: 'Please wait...' })
        confirmResetPassword({ username: this.state.username, confirmationCode: this.state.code, newPassword: this.state.password })
            .then(data => {
                this.setState({ error: '' })
                this.setState({ form: 'login' })
                this.setState({ success: 'Verification completed successfully.' })
            })
            .catch(err => {
                this.setState({ success: '' })
                this.setState({ error: err.message })
            });
    }

    handleConfirmSignup = () => {
        this.setState({ error: '' })
        this.setState({ success: 'Please wait...' })
        confirmSignUp({ username: this.state.username, confirmationCode: this.state.code })
            .then(data => {
                this.setState({ error: '' })
                this.setState({ form: 'login' })
                this.setState({ success: 'Confirm completed successfully.' })
            })
            .catch(err => {
                this.setState({ success: '' })
                this.setState({ error: err.message })
            });
    }

    handleForgotPassword = () => {
        this.setState({ error: '' })
        this.setState({ success: 'Please wait...' })
        resetPassword({ username: this.state.username })
            .then(data => {
                this.setState({ error: '' })
                this.setState({ success: 'Enter verification code.' })
                this.setState({ form: 'verification_code' })
            })
            .catch(err => {
                this.setState({ success: '' })
                this.setState({ error: err })
            });
    }

    handleSignup = () => {
        this.setState({ error: '' })
        this.setState({ success: 'Please wait...' })
        signUp({
            username: this.state.username,
            password: this.state.password,
            attributes: {
                email: this.state.username,          // optional
                phone_number: this.state.phone,   // optional - E.164 number convention
            }
        })
            .then(data => {
                console.log('handleSignup', data);
                this.setState({ cognito_key: data.userSub })
                this.setState({ success: 'An activation code has been ' + data.codeDeliveryDetails.DeliveryMedium + ' to ' + data.codeDeliveryDetails.Destination })
                this.setState({ form: 'confirm_signup' })

            })
            .catch(err => {
                console.log('handleSignup err', err)
                this.setState({ success: '' })
                this.setState({ error: err.message })
            });
    }

    getLogin() {
        return <div>
            <center>
                {/* <Avatar style={{ marginBottom: '25px' }}>
                    <LockOutlinedIcon />
                </Avatar> */}
                <Logo />
                <div variant="h6" style={{ color: `${config.system.textColor}` }}>
                    <br />
                    <h4>{window.t.en('Administration Portal')}</h4>
                    <div style={{ fontSize: '10px !important' }}>{window.t.en('Enter your account login details')}</div>
                </div>
            </center>

            <div style={{ top: '0 px !important', marginTop:20 }}>
                <CustomTextField
                    textColor={`${config.system.textColor}`}
                    variant="outlined"
                    margin="normal"
                    name="email"
                    id="txt_login_username"
                    // error={!!errors.email}
                    label={window.t.en('Enter Your Username')}
                    required
                    fullWidth
                    InputLabelProps={{
                        style: {color:`${config.system.textColor}`},
                        shrink: true
                    }}
                    style={{color: `${config.system.textColor} !important`}}
                    inputProps={{
                        style: {
                          color: `${config.system.textColor} !important`,
                          backgroundColor: 'transparent !important',
                          WebkitBoxShadow: 'transparent 0px 0px 0px 1000px inset',
                          caretColor: `${config.system.textColor} !important`
                        },
                      }}
                      sx={{
                        color: `${config.system.textColor} !important`,
                        caretColor: `${config.system.textColor} !important`,
                        '& input:-webkit-autofill': {
                            background: 'transparent !important',
                            color: `${config.system.textColor} !important`,
                            caretColor: `${config.system.textColor} !important`,
                            '-webkit-text-fill-color': `${config.system.textColor} !important`
                        },
                        marginBottom: 5
                      }}
                    // inputRef={register({
                    //     required: 'You must provide the email address!',
                    //     pattern: {
                    //       value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    //       message: 'You must provide a valid email address!',
                    //     },
                    //   })}
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    value={this.state.username}
                    onChange={
                        (event, value) => {
                            //props.actions.validateField(props.state.login.fields.email, event.target.value)
                            console.log('username', this.state.username)
                            this.setState({ username: event.target.value })
                        }
                    }
                />
                {/* {errors.email && <p>{errors.email.message}</p>} */}
                <br/>
                <FormControl
                    fullWidth
                    variant="outlined">
                    <InputLabel shrink htmlFor="outlined-adornment-password" style={{color: `${config.system.textColor}`}}><p >{window.t.en('Enter Your Password *')}</p></InputLabel>
                    <CustomOutlinedInput
                        textColor={`${config.system.textColor}`}
                        //={props.state.login.showPassword ? 'text' : 'password'}
                        type={this.state.showpassword ? 'text' : 'password'}
                        value={this.state.password}
                        name="password"
                        id="txt_login_password"
                        label={window.t.en('Enter Your Password')}
                        InputLabelProps={{
                            style: { color: `${config.system.textColor} !important` }
                        }}
                        inputProps={{
                            style: {
                              color: `${config.system.textColor} !important`,
                              backgroundColor: 'transparent !important',
                              WebkitBoxShadow: 'transparent 0px 0px 0px 1000px inset',
                            },
                          }}
                          sx={{
                                color: `${config.system.textColor} !important`,
                                caretColor: `${config.system.textColor} !important`,
                                '& input:-webkit-autofill': {
                                  background: 'transparent !important',
                                  color: `${config.system.textColor} !important`,
                                  caretColor: `${config.system.textColor} !important`,
                                  '-webkit-text-fill-color': `${config.system.textColor} !important`
                                },
                          }}
                        // inputRef={register({
                        //     required: true,
                        //     minLength: 5,
                        //   })}
                        //onKeyDown={(e)=>handleKeyDown(e, 'password')}onKeyDown={this._handleKeyDown}
                        onChange={
                            (event, value) => {
                                //props.actions.validateField(props.state.login.fields.keyword, event.target.value)
                                this.setState({ password: event.target.value })
                            }
                        }
                        onKeyPress={this._handleKeyDown}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.showPasswordInput}
                                    //onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {this.state.showpassword ? <Visibility className='icon-pass-style' /> : <VisibilityOff className='icon-pass-style' />}
                                </IconButton>
                            </InputAdornment>
                        }

                    />
                </FormControl>
                <br /><br />
                {
                    this.state.error
                        ? <p style={{ color: '#a94442', borderRadius: "5px", border: '1px solid #ebccd1', width: '100%', backgroundColor: '#f2dede', margin: "8px 0", padding: '5px', fontSize: '18px' }}>{this.state.error}</p>
                        : <div></div>
                }
                {
                    this.state.success
                        ? <p style={{ color: '#3c763d', borderRadius: "5px", border: '1px solid #d6e9c6', width: '100%', backgroundColor: '#dff0d8', margin: "8px 0", padding: '5px', fontSize: '18px' }}>{this.state.success}</p>
                        : <div></div>
                }
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    id="btn_user_login"
                    disabled={this.state.loading}
                    onClick={this.handleLogin}
                    sx={{marginTop: 2}}
                >
                    <label style={{ fontSize: '20px' }}> {'Sign In'}</label>
                </Button>
                <br />
            </div>
        </div>
    }

    getForgetPassword() {
        return <div>
            <center>
                <Avatar style={{ marginBottom: '25px' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Logo />

                <Typography variant="h6" style={{ color: '#000000' }}>
                    <br />
                    {('Forgot Password')}
                </Typography>

            </center>

            <form>
                <TextField
                    variant="outlined"
                    margin="normal"
                    name="email"
                    label={('Enter Your Email Address')}
                    required
                    fullWidth
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    //={props.state.login.fields.email.input}
                    onChange={
                        (event, value) => {
                            this.setState({ username: event.target.value })
                        }
                    }
                />
                {
                    this.state.error
                        ? <p style={{ color: ' #D8000C', borderRadius: "5px", border: '1px solid #D8000C', width: '100%', backgroundColor: '#FFBABA', margin: "8px 0", padding: '5px', font: '25px' }}>{this.state.error}</p>
                        : <p style={{ color: ' #FFF', borderRadius: "5px", border: '1px solid #FFF', width: '100%', backgroundColor: '#FFF', margin: "8px 0", padding: '5px', font: '25px' }}>&nbsp;</p>
                }
                <Button
                    fullWidth
                    variant="contained"
                    margin="normal"
                    size="large"
                    onClick={this.handleForgotPassword}
                >
                    <label style={{ fontSize: '20px' }}> {'Send Password Reset Request'}</label>
                </Button>

                <br /><br />
                <center>
                    <Link
                        onClick={() => {
                            this.setState({ error: '' })
                            this.setState({ form: 'login' })
                        }
                        }
                    >
                        {('Back to Login')}
                    </Link>
                </center>
                <br /><br />
                <center>
                    <Link
                        href="#"
                    //onClick={()=>props.actions.toggleScreen('forgot_password')}
                    >
                        By clicking sign in you agree to our <br /><strong>Terms and Conditions</strong>
                    </Link>
                </center>
            </form>
        </div>
    }

    getVerification() {
        return <div>
            <center>
                <Avatar style={{ marginBottom: '25px' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Logo />

                <Typography variant="h6" style={{ color: '#000000' }}>
                    <br />
                    {('Account Verification')}
                </Typography>

            </center>

            <form>
                <TextField
                    variant="outlined"
                    margin="normal"
                    name="email"
                    label={('Your Email Address')}
                    fullWidth
                    disabled={true}
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    value={this.state.username}
                    onChange={
                        (event, value) => {
                            this.setState({ username: event.target.value })
                        }
                    }
                />
                <br />
                <TextField
                    variant="outlined"
                    margin="normal"
                    name="code"
                    label={('Enter Verification Code')}
                    required
                    fullWidth
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    //={props.state.login.fields.email.input}
                    onChange={
                        (event, value) => {
                            this.setState({ code: event.target.value })
                        }
                    }
                />
                <hr /> <FormControl
                    fullWidth
                    variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{'New Password'}</InputLabel>
                    <OutlinedInput
                        id={'input_password'}
                        //={props.state.login.showPassword ? 'text' : 'password'}
                        type={this.state.showpassword ? 'text' : 'password'}
                        value={this.state.password}
                        name="password"
                        label={'Create New Password'}
                        // inputRef={register({
                        //     required: true,
                        //     minLength: 5,
                        //   })}
                        //onKeyDown={(e)=>handleKeyDown(e, 'password')}
                        onChange={
                            (event, value) => {
                                //props.actions.validateField(props.state.login.fields.keyword, event.target.value)
                                this.setState({ password: event.target.value })
                            }
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.showPasswordInput}
                                    //onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {this.state.showpassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }

                    />
                </FormControl>
                {
                    this.state.error
                        ? <p style={{ color: ' #D8000C', borderRadius: "5px", border: '1px solid #D8000C', width: '100%', backgroundColor: '#FFBABA', margin: "8px 0", padding: '5px', font: '25px' }}>{this.state.error}</p>
                        : <div></div>
                }
                {
                    this.state.success
                        ? <p style={{ color: '#3c763d', borderRadius: "5px", border: '1px solid #d6e9c6', width: '100%', backgroundColor: '#dff0d8', margin: "8px 0", padding: '5px', fontSize: '18px' }}>{this.state.success}</p>
                        : <div></div>
                }
                <Button
                    fullWidth
                    variant="contained"
                    margin="normal"
                    size="large"
                    onClick={this.handleVerification}
                >
                    <label style={{ fontSize: '20px' }}> {'Reset Password'}</label>
                </Button>

                <br /><br />
                <center>
                    <Link
                        onClick={() => {
                            this.setState({ error: '' })
                            this.setState({ form: 'login' })
                        }
                        }
                    >
                        {('Back to Login')}
                    </Link>
                </center>
                <br /><br />
                <center>
                    <Link
                        href="#"
                    //onClick={()=>props.actions.toggleScreen('forgot_password')}
                    >
                        By clicking sign in you agree to our <br /><strong>Terms and Conditions</strong>
                    </Link>
                </center>
            </form>

        </div>
    }

    getConfirm() {
        return <div>
            <center>
                <Avatar style={{ marginBottom: '25px' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Logo />

                <Typography variant="h6" style={{ color: '#000000' }}>
                    <br />
                    {('Account Verification')}
                </Typography>

            </center>

            <form>
                <TextField
                    variant="outlined"
                    margin="normal"
                    name="email"
                    label={('Your Email Address')}
                    fullWidth
                    disabled={true}
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    value={this.state.username}
                    onChange={
                        (event, value) => {
                            this.setState({ username: event.target.value })
                        }
                    }
                />
                <br />
                <TextField
                    variant="outlined"
                    margin="normal"
                    name="code"
                    label={('Enter Verification Code')}
                    required
                    fullWidth
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    //={props.state.login.fields.email.input}
                    onChange={
                        (event, value) => {
                            this.setState({ code: event.target.value })
                        }
                    }
                />
                {
                    this.state.error
                        ? <p style={{ color: ' #D8000C', borderRadius: "5px", border: '1px solid #D8000C', width: '100%', backgroundColor: '#FFBABA', margin: "8px 0", padding: '5px', font: '25px' }}>{this.state.error}</p>
                        : <div></div>
                }
                {
                    this.state.success
                        ? <p style={{ color: '#3c763d', borderRadius: "5px", border: '1px solid #d6e9c6', width: '100%', backgroundColor: '#dff0d8', margin: "8px 0", padding: '5px', fontSize: '18px' }}>{this.state.success}</p>
                        : <div></div>
                }
                <Button
                    fullWidth
                    variant="contained"
                    margin="normal"
                    size="large"
                    onClick={this.handleConfirmSignup}
                >
                    <label style={{ fontSize: '20px' }}> {'Confirm'}</label>
                </Button>

                <br /><br />
                <center>
                    <Link
                        href='#'
                        onClick={() => {
                            this.setState({ error: '' })
                            this.setState({ form: 'login' })
                        }
                        }
                    >
                        {('Back to Login')}
                    </Link>
                </center>
                <br /><br />
                <center>
                    <Link
                        href="#"
                    //onClick={()=>props.actions.toggleScreen('forgot_password')}
                    >
                        By clicking sign in you agree to our <br /><strong>Terms and Conditions</strong>
                    </Link>
                </center>
            </form>
        </div>
    }

    getCreate() {
        return <div>
            <center>
                <Avatar style={{ marginBottom: '25px' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Logo />

                <Typography variant="h6" style={{ color: '#000000' }}>
                    <br />
                    {('Create Account')}
                </Typography>

            </center>


            <form>
                <TextField
                    variant="outlined"
                    margin="normal"
                    name="name"
                    label={('Enter Your First Name')}
                    fullWidth
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    value={this.state.name}
                    onChange={
                        (event, value) => {
                            this.setState({ name: event.target.value })
                            this.setState({ firstname: event.target.value })
                        }
                    }
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    name="name"
                    label={('Enter Your Last Name')}
                    fullWidth
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    value={this.state.lastname}
                    onChange={
                        (event, value) => {
                            this.setState({ lastname: event.target.value })
                        }
                    }
                />
                <br />
                <TextField
                    variant="outlined"
                    margin="normal"
                    name="email"
                    label={('Enter Your Email Address')}
                    fullWidth
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    value={this.state.username}
                    onChange={
                        (event, value) => {
                            this.setState({ username: event.target.value })
                        }
                    }
                />
                <br />
                <TextField
                    variant="outlined"
                    margin="normal"
                    name="phone"
                    label={('Enter Your Mobile Number (+27)')}
                    fullWidth
                    //errorText={props.state.login.fields.email.empty ? '' : props.state.login.fields.email.errorText}
                    value={this.state.phone}
                    onChange={
                        (event, value) => {
                            this.setState({ phone: event.target.value })
                        }
                    }
                />
                <hr /> <FormControl
                    fullWidth
                    variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{'Password'}</InputLabel>
                    <OutlinedInput
                        id={'input_password'}
                        //={props.state.login.showPassword ? 'text' : 'password'}
                        type={this.state.showpassword ? 'text' : 'password'}
                        value={this.state.password}
                        name="password"
                        label={'Create Your Password'}
                        // inputRef={register({
                        //     required: true,
                        //     minLength: 5,
                        //   })}
                        //onKeyDown={(e)=>handleKeyDown(e, 'password')}
                        onChange={
                            (event, value) => {
                                //props.actions.validateField(props.state.login.fields.keyword, event.target.value)
                                this.setState({ password: event.target.value })
                            }
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.showPasswordInput}
                                    //onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {this.state.showpassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }

                    />
                </FormControl><br />
                {
                    this.state.error
                        ? <p style={{ color: ' #D8000C', borderRadius: "5px", border: '1px solid #D8000C', width: '100%', backgroundColor: '#FFBABA', margin: "8px 0", padding: '5px', font: '25px' }}>{this.state.error}</p>
                        : <div></div>
                }
                {
                    this.state.success
                        ? <p style={{ color: '#3c763d', borderRadius: "5px", border: '1px solid #d6e9c6', width: '100%', backgroundColor: '#dff0d8', margin: "8px 0", padding: '5px', fontSize: '18px' }}>{this.state.success}</p>
                        : <div></div>
                }
                <br />
                <Button
                    fullWidth
                    variant="contained"
                    margin="normal"
                    size="large"
                    onClick={this.handleSignup}
                >
                    <label style={{ fontSize: '20px' }}> {'Create Account'}</label>
                </Button>

                <br /><br />
                <center>
                    <Link
                        href='#'
                        onClick={() => {
                            this.setState({ error: '' })
                            this.setState({ form: 'login' })
                        }
                        }
                    >
                        {('Back to Login')}
                    </Link>
                </center>
                <br /><br />
                <center>
                    <Link
                        href="#"
                    //onClick={()=>props.actions.toggleScreen('forgot_password')}
                    >
                        By clicking sign in you agree to our <br /><strong>Terms and Conditions</strong>
                    </Link>

                </center>
            </form>
        </div>
    }

    getLayout() {
        return <>
            <CssBaseline />
            <div  style={{ marginTop: '20px', right: '20px', width: '450px', position: 'absolute', alignItems: 'right' }}>
                <Card className={`${config.system.clientTheme === 'bidvest' ? 'blurred-box' : ''}`} style={config.system.clientTheme === 'bidvest' ? {
                    padding: '25px',
                    boxShadow: '#f8f9fa38 0 0 0 300px inset'
                }
                :{
                    backgroundColor: 'rgba(255, 255, 255, 0.5)', 
                    padding: '25px'
                }}>

                    {this.state.form === 'login' && this.getLogin()}
                    {this.state.form === 'sign_up' && this.getCreate()}
                    {this.state.form === 'confirm_signup' && this.getConfirm()}
                    {this.state.form === 'forgot_password' && this.getForgetPassword()}
                    {this.state.form === 'verification_code' && this.getVerification()}
                </Card>
                {
                    this.state.form === 'confirm_signup' && <Hooks data={{
                        type: 'amplify_signup',
                        cognito_key: this.state.cognito_key,
                        email: this.state.username,
                        phone: this.state.phone,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        is_active: true,
                        client_id: 2,
                        date: moment().format('YYYY-MM-DD HH:mm')
                    }} />
                }
            </div>
            <Dialog
                open={this.state.tandc_win}
                style={{ width: '100% !important', maxWidth: '100% !important' }}
                fullWidth={true}
                maxWidth="xl"
            >

                <div className="text-center p-5">
                    <div className="avatar-icon-wrapper rounded-circle m-0">

                    </div>
                    <h4 className="font-weight-bold mt-4">
                        Terms and Conditions
                    </h4>
                    <iframe src={config.system.cdn + '/tandc/terms_of_use_ignixxion_int.htm'} width="100%" height="2100px" allowfullscreen frameborder='0'
                        style={{ overflow: 'hidden' }} seamless="seamless" scrolling="no"></iframe>
                    <hr />
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                this.setState({ 'tandc_win': false })
                            }}
                            className="btn-pill mx-1">
                            <span className="btn-wrapper--label">CLOSE</span>
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    }

    render() {
        this.setBackground()
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.amplify
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AmplifySignIn)

// export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(authAmplify,AmplifySignIn), false, [
//     <AmplifySignIn />
// ]);
