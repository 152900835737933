import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import DateControl from '../../../controls/dateControl'
import ViewVehicle from '../../../controls/view_vehicle'
import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

import * as actions from './actions'
import * as styles from './styles'

class RegistrationChangeCapture extends Component {
    componentDidMount() {
        this.props.actions.setValidationState('registrationChangeCapture')
        if(this.props.mode === "confirm") {
            this.props.actions.setupWorkflowTaskInput(this.props.state, [], 'registration_change', 'tsk_registration_change_capture')
        }
        else{
            this.props.actions.resetForm()
        }
    }

    getVehicle() {
        return <div>
            <ViewVehicle
                key={this.vehicle.vehicle_id}
                id={this.vehicle.vehicle_id}
            />
        </div>
    }

    getForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                        <Col xl={12} style={{paddingTop: '15px'}}>
                            <h5>Registration Details</h5>
                            <ul>
                                <li>Enter a new vehicle registration to change</li>
                            </ul>
                        </Col>
                        <Col xl={6} style={{paddingTop: '25px'}}>
                            <TextField
                                variant="outlined"
                                size="small"
                                margin="normal"
                                fullWidth={true}
                                id={'txt_registration'}
                                label={'Enter Vehicle New Registration Number'}
                                value={this.props.state.new_registration}
                                style={{marginTop: 0}}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('new_registration', event.target.value)
                                }}
                            />
                        </Col>
                        <Col xl={6}>
                            <DateControl
                                id={'txtregistrationchangedate'}
                                key={'txtregistrationchangedate'}
                                label='Select Registration Change Date'
                                name={'registrationchangedate'}
                                value={this.props.state.registrationchangedate}
                                defaultDate={this.props.state.registrationchangedate}
                                onSelect={(args) => {
                                    //console.log('args date',args)
                                    this.props.actions.setInputValue('registrationchangedate', args)
                                }}
                                type={'date'}
                            />
                        </Col>
                    </Row>
            </Container>
        </div>
    }

    getDocuments() {
        return <div>
        <Upload
            id={'registration_change'}
            prefix={'Registration_Change'}
            header={false}
        /></div>
    }

    getCapture() {
        return <div>
            {this.getVehicle()}
            <Card
                title={"Vehicle Registration Change Details"}
                subtitle={window.t.en('Enter new vehicle registration number')}
                content={this.getForm()}
            /> 
            <Card
                title={"Support Documents"}
                subtitle={window.t.en('Upload supplier supporting documents')}
                content={this.getDocuments()}
            /> 
        </div>
    }


    getConfirm() {
        return <div>
        {this.getVehicle()} 
        <Card
            title={"Vehicle Registration Change Details"}
            subtitle={window.t.en('Confirm new vehicle registration details')}
            content={<Fields  fields={[
                    {size: 4, label: 'New Registration:', value: this.props.state.new_registration},
                    {size: 4, label: 'Change Date:', value: this.props.state.registrationchangedate}
                ]} />}
        /> 
        <Card
            title={"Vehicle Registration Supporting Documents"}
            subtitle={window.t.en('Uploaded vehicle registration supporting documents')}
            content={<PdfViewer
                key={'registration_change'}
                name={'registration_change'}
                documents={this.props.state.documents.documents}
                edit={false}
                multiple={false}
                workflow_queue_data={null}
            />}
        />
        </div>
    }

    getLayout() {
        return <div>{
            this.props.mode === 'confirm'
                ? this.getConfirm()
                : this.getCapture()
        }
        </div>
    }

    render() {
        this.vehicle = this.props.data[0].workflow_task_step_component[0].data[0]
        //console.log('RegistrationChangex this.vehicle',this.vehicle)
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.registrationChangeCapture,
            documents: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;
    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationChangeCapture)