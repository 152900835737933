
import * as types from './types'

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        window.glyco.log('CONFIRM_PO_HEADER_SELECTION')
        window.glyco.log(payload)

        dispatch({
            type: types.CONFIRM_PO_HEADER_SELECTION,
            payload: payload
        })
    }
}

export const setInputValue = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_FML_QUOTATION_ACEPT_INPUT_VALUE,
            payload: data
        })
    }
}


export const getLookupValueData = (table, field, search, alias, related = '') => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_FML_COMPONENT_ACCEPT_QUOTE_DATA_FIELD_INPUT_LIST,
            payload: {
                alias: alias,
                table: table,
                field: field,
                search: search,
                related: related
            }
        })
    }
}

export const setLookupValueData = (response, payload) => {
    return (dispatch, getState) => {
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.table + '_id']
            }
        })
        dispatch(setInputValue({prop: (payload.alias ? payload.alias : payload.table) + '_data', value: data}))
    }
}