import * as types from './types'

export const getQueuedTasks = (team_id, user_id, search, paging) => {
    return {
        type: types.GET_QUEUED_TASKS,
        payload: {
            team_id: team_id,
            body: {
                query: 'queued_tasks',
                team_id: team_id,
                user_id: user_id,
                search: search,
                state: paging
                    ? paging
                    : {
                        page: 0,
                        pages: 0,
                        pageSize: 15,
                        count: 0
                    }
            }
        }
    }
}

export const setQueuedTasks = (team_id, search, data) => {
    return {
        type: types.SET_QUEUED_TASKS,
        payload: {
            team_id: team_id,
            search: search,
            data: data
        }
    }
}