import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Grid from '../Grid/component'
import Button from '@mui/material/Button'
import { Container, Row, Col } from 'react-grid-system'
import IconButton from '@mui/material/IconButton';

import Icon from '../Icon/component'
import View from '../View/component'

import * as actions from './actions'
import * as redirectActions from '../Redirect/actions'
import * as styles from './styles'

import Theme from '../../theme'

class WorkflowTaskStepComponentMultipleSelector extends Component {
    getSelectedItem(data) {
        return this.state.component.component_field
            .filter(x => x.grid && !x.related)
            .map(x => {
                return <Row key={'selected_row_' + x.name}>
                    <Col xl={2} style={styles.selectedFieldName}>
                        {x.title}:
                    </Col>
                    <Col xl={10}>
                        {data[0][0].original[x.name]}
                    </Col>
                </Row>
            })
    }

    render() {
        const component = {
            actions: this.props.actions,
            state: this.props.state,
            styles: styles
        }

        this.state = this.props.state

        this.data = this.state.task.workflow_task_step
            .filter(x =>
                x.name === this.props.step &&
                x.workflow_task_step_component.filter(y => y.component.name === this.props.name).length
            )
            .reduce((arr, x) => {
                if (x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length).length) {
                    arr.push(x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length)[0].data)
                }

                return arr
            }, [])

        return (
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <Grid
                            key={'workflow_task_step_single_selector_' + this.props.name + '_' + this.props.step}
                            name={this.props.name}
                            task={true}
                            mode='select'
                            type='multiple'
                            filterable={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} style={styles.confirmSelection}>
                       < Button className={"global_button"} variant="contained"
                            label={'Select'}
                            primary={true}
                            disabled={!this.state.component.selected.length}
                            onClick={(event) => {
                                component.actions.confirmMultipleSelection(this.props.step, this.props.name)
                            }}
                            >{window.t.en('Select')}</Button>
                    </Col>
                </Row>
                {this.data.length
                    ? <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    : ''
                }
                {this.data.length
                    ? this.data[0].map((x, i) => {
                        return <Row>
                            <Col xl={0.5}>
                                <IconButton
                                    iconStyle={styles.deleteIcon}
                                    tooltip="Remove"
                                    onClick={(event) => {
                                        component.actions.removeSelectedItem(this.props.step, this.props.name, i)
                                    }}
                                >
                                    <Icon ihovercolor={Theme.palette.primary1Color} iclass={'material-icons'} iname={'delete_forever'} />
                                </IconButton>
                            </Col>
                            <Col xl={11.5}>
                                <View
                                    key={'view_' + this.state.component.name}
                                    name={this.state.component.name}
                                    data={x}
                                    surrogate={false}
                                />
                            </Col>
                        </Row>
                    })
                    : ''
                }
            </Container >
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowTaskInput,
            component: {
                ...state.components[ownProps.name]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...redirectActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskStepComponentMultipleSelector)