import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_SUPPLIER_LIST:
            return {
                ...state,
                supplier_list: action.payload
            }
        case types.SET_SELECTED_SUPPLIER:
            return {
                ...state,
                supplier: action.payload
            }
        case types.SET_SELECTED_SUPPLIER_ACCESSORY_LIST:
            return {
                ...state,
                accessory_list: action.payload
            }
        case types.SET_ACCESSORY_LIST_SEARCH_TEXT:
            return {
                ...state,
                search_text: action.payload
            }
        case types.SET_ALL_ACCESSORY_LIST:
            return {
                ...state,
                accessory_selection: action.payload
            }
        case types.SET_SELECTED_ACCESSORY:
            return {
                ...state,
                selected_accessory: action.payload
            }
        default:
            return state
    }
}