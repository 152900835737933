import * as actions from './actions'
import * as teamActions from '../Teams/actions'
import * as clientActions from '../Clients/actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as messengerTypes from '../Messenger/types'
import * as requestTypes from '../Request/types'
import { cognitoUser } from '../../cognito'
import * as config from '../../config'

export const getDashboardList = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_COMPONENT_PROFILE_DASHBOARD_DATA),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/oqlis/query', //cognitoUser.username,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            }).pipe(
                mergeMap((payload) => [
                    actions.setDashboardList(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query?cognito_key=' + cognitoUser.username)
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query?cognito_key=' + cognitoUser.username, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const updateUsertDefaultSetting = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.UPDATE_USER_DEFAULTS,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
                    mergeMap((result) => {
                        return(
                            [
                                actions.setUser(result),
                                requestActions.requestSucceeded(config.system.api + '/components/user/other')
                            ]
                        )
                    })
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) => {
            //console.log('#################################################################################################', error)
        }
            // of$(routerActions.route('/support', false, error))
        )
    )




