import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowPOReceiptVehicleConfirmComponent extends Component {
     componentDidMount() {
         
        const data = this.props.state.purchaseorder.data
        const business = data.branch_id.data.filter(x => x.business_id === data.branch_id.input)
        const payload = {
            orderdate: new Date(),
            business: business,
            order:  this.props.state.purchaseorder.data.orderdate.data,
            receipt:  this.props.state.document.data.filename.text,
        }
        this.props.actions.setupWorkflowTaskInput(payload, 'purchaseorder', 'tsk_fml_quote_vehicle_receipt')
     }


    getCustomer(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3}>{'Customer Name:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{data.customername}</Col>
                    </Row>
                    <Row>
                        <Col xl={3}>{'Cost Centre:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.costcentre}</Col>
                        <Col xl={3}>{'Registered Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registeredname}</Col>
                    </Row>
                    <Row>
                        <Col xl={3}>{'Account Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.accountnumber}</Col>
                        <Col xl={3}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.compregistrationnumber}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getVehicle(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Requested vehicle on the quote')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3}>{'Vehicle Model:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{data.makemodelyear}</Col>
                    </Row>
                    <Row>
                        <Col xl={3}>{'Vehicle MM Code:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode}</Col>
                        <Col xl={3}>{'Vehicle Make:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.make}</Col>
                    </Row>
                    <Row>
                        <Col xl={3}>{'Delivery Period:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.deliveryperiod}</Col>
                        {/* <Col xl={3}>{'Vehicle First Registration Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.regyear}</Col> */}
                    </Row>
                    <Row>
                        <Col xl={3}>{'Fuel Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fueltype}</Col>
                        <Col xl={3}>{'Status:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{''}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getForm() {
        const data = this.props.state.purchaseorder.data
        const business = data.branch_id.data.filter(x => x.business_id === data.branch_id.input)
        const order = data.orderdate.input
        const vehiclereceipt = data.vehiclereceipt.input

        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Infleet Branch: ' + business[0].businessname)}
                subtitle={window.t.en('Contact list to be notified')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={4} style={styles.selectedFieldName}>{'Contact Person'}</Col>
                        <Col xl={4} style={styles.selectedFieldName}>{'Contact Email'}</Col>
                        <Col xl={4} style={styles.selectedFieldName}>{'Contact Telephone'}</Col>
                    </Row>
                    {
                        business[0].businesscontact.map(x => {
                            return <Row>
                                <Col xl={4}>{x.contact.fullname}</Col>
                                <Col xl={4}>{x.contact.email}</Col>
                                <Col xl={4}>{x.contact.telephone_work}</Col>
                            </Row>
                        })
                    }

                </Container>
            </CardContent>
        </Card>
    }

    getView(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('FML Vehicle Receipt Confirm')}
                subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                {this.getCustomer(data.customer)}
            </CardContent>
            <CardContent expandable={false}>
                {this.getVehicle(data.confirm.vehicle)}
            </CardContent>
            <CardContent expandable={false}>
                {this.getForm()}
            </CardContent>
        </Card>
    }


    render() {

        const component = this.props.state.component
        const workflow_queue_data = this.props.workflow_queue_data.data[0]
        const data = workflow_queue_data.context_data.data.data.data[0].data.data

        return (this.getView(data))
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowQueueTasks,
            step: {
                ...state.workflowTaskInput
            },
            component: {
                ...state.components[ownProps.name]
            },
            document: {
                ...state.components['document']
            },
            purchaseorder: {
                ...state.components['purchaseorder']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOReceiptVehicleConfirmComponent)