import * as types from './types'


export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_COSTCENTRE_CHANGE_APPROVE_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}
export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_COSTCENTRE_CHANGE_FORM_APPROVE,
        })
    }
}