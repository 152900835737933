import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';

import * as actions from './actions'


const TextFieldx = styled(TextField)({
  "& .MuiFormLabel-root": {
      fontSize: '14px'
  },
  "& .MuiFormLabel-filled": {
      color: "black",
      fontWeight: 600,
      fontSize: '14px'
  },
  root: {
      "& .MuiFormLabel-filled": {
        color: "black",
        fontWeight: 600
      }
  }
});

class _treeselect extends Component {
    getParent() {
        const parent = (() => {
          if ("states" in this.value) {
            return null;
          } else if ("cities" in this.value) {
            return (
              sampleData.find(({ states }) =>
                states.some(({ id }) => id === this.value.id)
              ) || null
            );
          } else {
            for (const { states } of sampleData) {
              const state = states.find(({ cities }) =>
                cities.some(({ id }) => id === this.value.id)
              );
              if (state) {
                return state;
              }
            }
            return null;
          }
        })();
        return parent ? new Node(parent) : parent;
      }
      getChildren() {
        if ("states" in this.value) {
          return this.value.states.map((state) => new Node(state));
        } else if ("cities" in this.value) {
          return this.value.cities.map((city) => new Node(city));
        } else {
          return null;
        }
      }
      isBranch() {
        return "states" in this.value || "cities" in this.value;
      }
      isEqual(to) {
        return to.value.id === this.value.id;
      }
      toString() {
        return this.value.name;
      }
    
    getTreeView(parent) {
        let treeView = []
        this.props.data.filter(x => parent ? x.parent_id == parent : !x.parent_id).map(x => {
          treeView.push(<TreeItem nodeId={x[this.props.fieldid]} label={x[this.props.fieldname].trim()}>{this.getTreeView(x[this.props.fieldid])}</TreeItem>)
        })
        return treeView.length ? treeView : ''
    }

    getAutoComplete() {
      //console.log('_autocompletex data',this.props.data)
        return <div style={{padding: '10px 0'}}>
          <Autocomplete
            id="grouped-demo"
            options={[{name: 'name', value: 1}]}
            //groupBy={(option) => option.name}
            getOptionLabel={(option) => option.name}
            // sx={{ width: 300 }}
            size={"small"}
            sx={this.props.sx ? this.props.sx : {}}
            fullWidth={false}
            inputProps={{style: {fontSize: 14}}}
            
            onClick={
              (e) => {
                  props.onClick ? props.onClick(e, e.target.value) : null
              }
            }
            renderInput={(params) => {
              
              let _params = this.props.value ? {
                ...params,
                inputProps: {
                    ...params.inputProps,
                    value: this.props.value,
                    style: {fontSize: 14}
                }
            } : params
            
            return <TextFieldx {..._params} label={this.props.title} style={{fontSize: 14}} size={"small"} />
            }}
            renderOption={(props, option) => {
              //console.log('_autocompletex renderOption',option)
              //console.log('_autocompletex data',this.props.data)
              return <React.Fragment>
                  <Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300, marginTop: 15 }}>
                    <TreeView
                      aria-label="file system navigator"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      onNodeSelect={(event, nodeIds) => {
                        //console.log('nodeIdsx', nodeIds)
                        let _args = {} 
                        if(nodeIds) {
                          let _selected = this.props.data.filter(x => x[this.props.fieldid] === nodeIds)
                          if(_selected.length) {
                            _args.value = _selected[0][this.props.fieldid]
                            _args.text = _selected[0][this.props.fieldname].trim()
                            }
                          }
                        
                        this.props.onChange(_args)
                      }}
                    >
                      {
                        this.getTreeView()
                      }
                    </TreeView>
                  </Box>
                </React.Fragment>
            }}
          />
        </div>
    }

    getLayout() {
        return this.getAutoComplete()
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.controls }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(_treeselect)