import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import WorkflowReview from './WorkflowReview/component'
import WorkflowBookout from './WorkflowBookout/component'
import WorkflowActivation from './WorkflowActivation/component'

import Card from './../../controls/card'
import * as actions from './actions'

class WorkflowSTRQuote extends Component {
    componentDidMount() {
        //this.props.actions.getDashboard()
    }
    
    getLayout() {
        //console.log('getLayout this.props',this.props)
        return <div>
            {this.props.mode === 'str_quotation_review' && <WorkflowReview />}
            {this.props.mode === 'str_quotation_bookout' && <WorkflowBookout />}
            {this.props.mode === 'str_quotation_activation' && <WorkflowActivation />}
        </div>
    }
    
    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.strHeaderQuote,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowSTRQuote)