import * as actions from "./actions";
import * as progressBarActions from "../ProgressBar/actions";
import * as requestActions from "../Request/actions";
import * as routerActions from "../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../Request/types";
import * as config from "../../config";
import moment from "moment";


export const getCategory = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_COMPONENT_EVENT_CATEGORY),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/workqueue_category/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
            .pipe(
                mergeMap(payload => [
                    actions.setCategory(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workqueue_category/query')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue_category/query', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))


export const getComponent = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_COMPONENT_EVENT_COMPONENT),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/component/query?event_management=true',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
            .pipe(
                mergeMap(payload => [
                    actions.setComponent(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/component/query')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component/query', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

        

export const getField = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_COMPONENT_FIELD),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/component_field/query?event_management=true&component_id=' + payload.component_id,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'component_field_data_type'
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setField(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/component_field/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component_field/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const getOperator = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_OPERATOR),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/workqueue_operator/query?workqueue_operator_type=' + payload.operator,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setOperator(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue_operator/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue_operator/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const getTeam = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_TEAM),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/team/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setTeam(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue_operator/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue_operator/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const getUser = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_USER),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/user/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setUser(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/user/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

    
export const getSchedule = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_SCHEDULE),
    mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/workqueue_schedule/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setSchedule(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue_category/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue_category/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

 
export const getIndicator = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_INDICATOR),
    mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/workqueue_indicator/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setIndicator(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue_indicator/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue_indicator/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))
   
export const getEscalation = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_ESCALATION),
    mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/workqueue_escalation/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setEscalation(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue_category/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue_category/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const getActions = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_ACTION),
    mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/workqueue_actions/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setActions(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue_actions/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue_actions/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))


export const getWorkflow = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_WORKFLOW),
    mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/workflow/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setWorkflow(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workflow/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const saveEvent = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.SAVE_COMPONENT_EVENT_DATA),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/workqueue/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
        .pipe(
            mergeMap(payload => [
                actions.saveEventDone(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue_actions/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue_actions/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const getEventsData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.LOAD_COMPONENT_EVENT_DATA),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/workqueue/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setEventsData(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const loadEvent = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_LOAD_EVENT_DATA),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/workqueue/query?workqueue_id=' + payload,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'workqueue_field_actions.workqueue_actions,workqueue_field_actions.workflow,component.component_field,workqueue_field.component_field.component_field_data_type,workqueue_escalation_user.user,workqueue_escalation_team.team'
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.loadEventDone(payload.response[0]),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const loadEventLogs = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.LOAD_COMPONENT_EVENT_LOGS),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/workqueue_log/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'workqueue'
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setEventLogs(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const getEscalationTypes = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_EVENT_ESCALATION_TYPES),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/workqueue_escalation_type/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setEscalationTypes(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/workqueue_escalation_type/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workqueue_escalation_type/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const sendEmail = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.SEND_EVENTS_MANAGMENT_EMAIL),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/webapi/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
        .pipe(
            mergeMap(payload => [
                actions.sendEmailDone(payload.response),
                requestActions.requestSucceeded(config.system.api + '/components/webapi/other')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/webapi/other', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))
