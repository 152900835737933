import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.MM_COMPONENT_RESET_FORM:
            return {
                customer_id: {
                    value: '',
                    valid: false,
                },
                customer: {
                    value: '',
                    valid: false,
                },
                mmcode: {
                    value: '',
                    valid: false,
                },
                mmcode_id: {
                    value: '',
                    valid: false,
                },
                vehicle: {
                    value: '',
                    valid: false,
                },
                vehicle_id: {
                    value: '',
                    valid: false,
                },
                licenseplateno: {
                    value: '',
                    valid: false,
                },
                licensediskno: {
                    value: '',
                    valid: false,
                },
                engineno: {
                    value: '',
                    valid: false,
                },
                chassisno: {
                    value: '',
                    valid: false,
                },
                yearmodel: {
                    value: '',
                    valid: false,
                },
                contracttype: {
                    value: '',
                    valid: false,
                },
                contracttype_id: {
                    value: '',
                    valid: false,
                },
                supplier: {
                    value: '',
                    valid: false,
                },
                supplier_id: {
                    value: '',
                    valid: false,
                },
                startdate: {
                    value: '',
                    valid: false,
                },
                enddate: {
                    value: '',
                    valid: false,
                },
                regdate: {
                    value: '',
                    valid: false,
                },
                licenseexpiry: {
                    value: '',
                    valid: false,
                },
                service: {
                    value: '',
                    valid: false,
                },
                odometer: {
                    value: '',
                    valid: false,
                },
                tracking: {
                    value: '',
                    valid: false,
                },
                period: {
                    value: '',
                    valid: false,
                },
                contractdistance: {
                    value: '',
                    valid: false,
                },
                distancepermonth: {
                    value: '',
                    valid: false,
                },
                color: {
                    value: '',
                    valid: false,
                },
                color_data: [],
                customer_data: [],
                supplier_data: [],
                mmcode_data: [],
                vehicle_data: [],
                mm_service_data: [],
                contracttype_data: [],
                mm_service_selected: [],
                quotationnotes: {
                    value: '',
                    valid: false,
                },
                monthly_excl: {
                    value: '',
                    valid: false,
                },
                monthly_incl: {
                    value: '',
                    valid: false,
                },
                monthly_vat: {
                    value: '',
                    valid: false,
                },
                validation: {
                    valid: false
                },
                mm_customer_selected: {},
                isChecked: 'false',
                fields: props.fields
            }
        case types.SET_MM_COMPONENT_LOOKUP_DATA_VALUES:
            return {
                ...state,
                [action.payload.prop]: action.payload.value,
            }
        case types.SET_MM_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                }
            }
        case types.SET_MM_COMPONENT_FIELD_ITEM_INPUT_VALUE:
            return {
                ...state,
                mm_service_data:
                    state.mm_service_data.map((x, i) => {
                        const row = i === action.payload.index
                            ? {
                                ...x,
                                'sellingprice': action.payload.value
                            }
                            : x
                        return row
                    })

            }
        case types.SET_MM_COMPONENT_FIELD_INPUT_ACCESSORY_VALUE:
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                }
            }
        case types.SET_COMPONENT_VALIDATION_PROPS_FIELDS:
            return {
                ...state,
                workflowTaskInput: {
                    ...state.validationfields,
                    validationfields: action.payload
                }
            }
        default:
            return state
    }
}