import * as types from './types'

export const tagglePanel = (prop,value) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_FAQ_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const loadFAQDone = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_FAQ_INPUT_VALUE,
            payload: {
                prop: 'faq',
                value: data.data
            }
        })
    }
}

export const loadFAQ = () => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_FAQ_DATA
        })
    }
}