import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, IconButton } from '@mui/material'

import Icon from '../../Icon/component'

import VisibilityIcon from '@mui/icons-material/Visibility';
import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewSupplierTransactions from '../../../controls/view_supplier_transaction'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Form from '../../../controls/form'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'
import Drawer from '../../../controls/drawer'

import Checkbox from '../../../controls/checkbox'
import CardControl from '../../../controls/card'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import * as styles from './styles'
import * as actions from './actions'

class CreditNoteReview extends Component {

    componentDidMount() {
            this.props.actions.resetForm()
    }
    
    getTransactionItems(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Contract Number</th>
                                    <th>Vehicle Registration</th>
                                    <th>Customer Name</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Interest Rate</th>
                                    <th>Finance</th>
                                    <th>ROI</th>
                                    <th>Sub Total</th>
                                    <th>VAT</th>
                                    <th>Monthly Rental</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td colSpan={8}>
                                        <TextField
                                            id={'short_payment'}
                                            multiLine={false}
                                            fullWidth={true}
                                            label={'Enter Search'}
                                            variant="outlined"
                                            value={this.props.state.search}
                                            onChange={(event) => {
                                                //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                            }}
                                        />
                                    </td>
                                </tr> */}
                                {
                                    data.contracts.map((x,inx) => {
                                        let derivative = x.customercontractderivative.length ? x.customercontractderivative[0] : {}
                                        return  <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.contractnumber} </td>
                                            <td>{x.licenseplateno}</td>
                                            <td>{x.customer.customername}</td>
                                            <td>{moment(x.startdate).format('DD-MM-YYYY')}</td>
                                            <td>{moment(x.terminationdate).format('DD-MM-YYYY')}</td>
                                            <td>{derivative.xinterestrate}</td>
                                            <td>{derivative.monthlyreturnoninvestment}</td>
                                            <td>{derivative.financemonthlyselling}</td>
                                            <td>{derivative.monthlysellingsubtotal}</td>
                                            <td>{derivative.monthlysellingvat}</td>
                                            <td>{derivative.monthlysellingtotal}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getConfirm(data) {
        
        return <div>
            <Card title={window.t.en('Interest Rate Details')}
        subtitle={window.t.en('Confirm interest rate details')}
        content={
                <div> <Fields labelsize='130'
        fields={[
            {size: 6, label: 'Interest Rate:', value: data.interest_rate},
            {size: 6, label: 'Affective Date', value: data.effective_date},
        ]}
        /></div>}
        />
        <Card
            title={'Contract List Review'}
            subtitle={'Contracts Management List'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <h5>{'Interest Rate Applied  List'}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.getTransactionItems(data)}
                    </Col>
                </Row>
            </Container>}
        />
        <Card
            title={'Supporting Documents'}
            subtitle={'Interest rate uploaded supporting documents '}
            content={
                <PdfViewer
                    key={'payment_document'}
                    name={'payment_document'}
                    documents={data.upload.documents}
                />
            }
        />
        </div>
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('CreditorsPaymentReviewx', data.context_data.data.data.data[0])
        data = data.context_data.data.data.data[0].step1.data
        //console.log('CreditorsPaymentReviewx step1', data)
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row>
        <Col xl={12}>
            {this.getConfirm(data)}
            </Col>
        </Row>
        </Container> 
    }

    render() {
        
        this.data = null
        try {
        } catch (error) {

        }
        
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.interestRateReview,
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteReview)