import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as _ from 'lodash'
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../components/Icon/component'
import NumberFormat from 'react-number-format';
// import Grid from '../Grid/component'
import TextField from './textField'
import TreeSelect from './treeselect'
import AutoComplete from './autocomplete'
import DateControl from './dateControl'
import CheckBoxControl from './checkbox'

import * as actions from './actions'
import * as styles from './styles'
import * as config from '../config'


function NumberFormatCustom(props) {
    const { inputRef, onChange, type, ...other } = props;
    
    switch (type) {
        case 'phone':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                isNumericString
                format="+27 (###) ###-####" 
                mask="_"
            />
        case 'amount':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                fixedDecimalScale
                isNumericString
            />
        case 'number':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                thousandSeparator={false}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                isNumericString
            />
        case 'month':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="##/##" placeholder="MM/YY" 
                mask={['M', 'M', 'Y', 'Y']}
                isNumericString
            />
        case 'card':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="#### #### #### ####" 
                mask="_"
                isNumericString
            />
        case 'idnumber':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="###### #### ###" 
                mask="_"
                isNumericString
            />
        case 'code':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="####"
                mask="_"
                isNumericString
            />
    } 
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };


class MasterForm extends Component {
    componentDidMount() {
        //this.props.actions.loadFormComponent(this.props.component)
    }

    getFormFieldValue(field, index) {
        switch (field.type) {
            case 'treeSelect':
                return <div style={{padding: '-5px'}}><TreeSelect
                    name={field.name}
                    title={field.hint}
                    data={[{customername: ''}]}
                    hierarchy={this.props.state[field.name+"_data"] ? this.props.state[field.name+"_data"] : []}
                    value={this.props.state[field.name]}
                    multiple={false}
                    valuefield={field.value}
                    displayfield={field.display}
                    onSelect={(args) => {
                        //console.log('args',args)
                        //console.log('args value',field.value)
                        //console.log('args display',field.display)
                        this.props.actions.setComponentFieldInputAndText(field.name, args[field.value], args[field.display])
                    }}
                    onClick={() => {
                        this.props.actions.getTreeSelectData(field.name, this.props.datasource, 1)
                    }}
                    onFilter={(args) => console.log()} 
                /> </div>
            case 'autoComplete':
            case 'select':
            case 'dll':
                //console.log('autoCompletex',field.data)
                return <div style={{padding: '-15px 0'}}><AutoComplete {...field} /></div> 
            case 'datePicker':
            case 'date':
            case 'd':
                return <div style={{margin: '-15px 0'}}>
                    <DateControl {...field}   />
                </div>
            case 'hidden':
                return <input
                    key={'component_' + this.component.name + '_' + field.name + '_' + this.component.data[field.name]}
                    type='hidden'
                    value={this.component.data[field.name].input}
                />
            case 'timePicker':
                return <DateControl
                    id={field.name}
                    key={field.name}
                    name={field.name}
                    label={window.t.en(field.title)}
                    field={field}
                    value={field.name}
                    setComponentFieldInput={
                        this.props.actions.setComponentFieldInput
                    }
                    type={'time'}
                />
            case 'textBox':
            case 'text':
            case 't':
                return <TextField {...field}   />
            case 'toggle':
                return <div>
                    <CheckBoxControl
                        key={this.component.data[field.name].key}
                        id={this.component.data[field.name].key}
                        checked={this.component.data[field.name].input != null ? this.component.data[field.name].input : false}
                        field={field}
                        onCheck={(checked) => {
                            this.props.actions.setComponentFieldInput(field, checked)
                        }}
                    />
                    {
                        this.component.data[field.name].validation.message
                            ? [
                                <br />,
                                <div style={{ color: 'red' }}>{this.component.data[field.name].validation.message}</div>
                            ]
                            : ''
                    }
                </div>
            default: 
                return
        }
    } 

    getRequiredIndicator(required) {
        return required ? <span style={{color: 'red'}}>*</span> : <span></span>
    }

    getField(field, index, hidden) {
        return this.getFormFieldValue(field, index)
    }

    getFieldNonLabel(field, index, hidden) {
        return this.getFormFieldValue(field, index)
    }

    getSection(field, index) {
        let fields = this.props.fields
            .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
            .filter(x => x.section === field.section)
            .map((x, i) => {
                switch (x.type) {
                    case 'hidden':
                        return this.getField(x, i, true)
                    case 'grid':
                        return this.getRelatedGrid(x)
                    case 'autoComplete':
                        return this.props.label ? <Col key={'col_' + x.name} xl={x.size}>{this.getField(x, i)}</Col> : <Col key={'col_' + x.name} xl={x.size}>{this.getFieldNonLabel(x, i)}</Col>
                    default:
                        return this.props.label ? <Col key={'col_' + x.name} xl={x.size}>{this.getField(x, i)}</Col> : <Col key={'col_' + x.name} xl={x.size}>{this.getFieldNonLabel(x, i)}</Col>
                }
            })

        let _res = fields.length ? fields[0].toString() : ''

        return _res ? <Card key={'card_' + field.name} initiallyExpanded={true}>
            <CardHeader
                // avatar={<Icon iclass={'material-icons'} iname={field.icon} />}
                title={field.section}
                subtitle={field.section}
                actAsExpander={true}
                showExpandableButton={true}
                style={styles.row}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        {fields}
                    </Row>
                </Container>
            </CardContent>
        </Card> : ''
    }
    
    getNonSection(field, index) {
        let fields = this.props.fields
            .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
            .filter(x => x.section === field.section)
            .map((x, i) => {
                switch (x.type) {
                    case 'hidden':
                        return this.getField(x, i, true)
                    case 'grid':
                        return this.getRelatedGrid(x)
                    case 'autoComplete':
                        return this.props.label ? <Col key={'col_' + x.name} xl={x.size}>{this.getField(x, i)}</Col> : <Col key={'col_' + x.name} xl={x.size}>{this.getFieldNonLabel(x, i)}</Col>
                    default:
                        return this.props.label ? <Col key={'col_' + x.name} xl={x.size}>{this.getField(x, i)}</Col> : <Col key={'col_' + x.name} xl={x.size}>{this.getFieldNonLabel(x, i)}</Col>
                }
            })

        let _res = fields.length ? fields[0].toString() : ''

        return _res ? fields : ''
    }

    getLayout() {
        //console.log('this.props.fields',this.props.fields)
        const sections = _.uniqBy(this.props.fields, 'section')
            .map((x, i) => {
                //console.log('x',x)
                return x.section 
                ? <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        {this.getSection(x, i)}
                    </Row>
                </Container>
                :  <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        {this.getNonSection(x, i)}
                    </Row>
                </Container>
            })

        return sections
    }

    render() {
        return <div >
             {this.getLayout()}
        </div>
        
    }

}


const mapStateToProps = (state, ownProps) => {
    //console.log('ownProps',ownProps)
    return {
        state: {
            ...state,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        actions: bindActionCreators(
            {
                ...actions
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)((MasterForm));
