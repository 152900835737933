import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton'

import ReactTable from "react-table"
import "react-table/react-table.css"
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../../Icon/component'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowDisposalOTPList extends Component {

    getCommands(row) {
        let field = this.props.state.optlist_data_header.filter(x => x.key === true)[0]
        const id = row.original[field.name]
        let i = row.index
        let commands = []

        commands.push(
            <IconButton key={'command_otp_list_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.accepted)}
                onClick={
                    () => {
                        this.props.actions.acceptVehicleOffer(id,this.props.state.user.user_id)
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={row.original.accepted ? 'done_all' : 'check'} />
            </IconButton>
        )

        return commands
    }

    getFormFieldInput(row) {
        let field = this.props.state.optlist_data_header.filter(x => x.name === row.column.meta)[0]
        let index = row.index

        switch (field.type) {
            case 'date':
            case 'dateTime':
            case 'time':
            case 'timestamp':
                return <div>
                    <TextField
                        key={field.name + '_' + index}
                        container='inline'
                        mode='landscape'
                        value={this.data[index][field.name] ? new Date(this.data[index][field.name]) : null}
                    />
                </div>
            case 'boolean':
                return <div>
                    <Checkbox
                        key={field.name + '_' + index}
                        //label={field.hint}
                        labelPosition='right'
                        //defaultToggled={false}
                        style={styles.toggle}
                        trackStyle={{
                            backgroundColor: 'gray'
                        }}
                        trackSwitchedStyle={{
                            backgroundColor: 'green'
                        }}
                        toggled={this.data[index][field.name] === true}
                    />
                </div>

            default:
                return <TextField
                key={field.name + '_' + index}
                value={this.data[index][field.name]} 
            />
        }
          
    }

    getValue(row) {
        let field = this.props.state.optlist_data_header.filter(x => x.name === row.column.meta)[0]
            if (!row.value) return row.value
            switch (field.type) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
    }

    getFormFieldLabel(row) {
          return <div
          style={{
            width: '100%',
            marginTop: '3px',
            verticalAlign: 'baseline',
            fontSize: '15px'
        }} >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.optlist_data_header.filter(x => x.key === false)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.display,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.right
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getFormFieldLabel(row)
                    )
                }
            })

            // columns.unshift({
            //     //Header: '',
            //     accessor: 'options',
            //     filterable: false,
            //     maxWidth: 80,
            //     width: 80,
            //     Cell: (row) => (
            //         this.getCommands(row)
            //     )
            // })
        return columns
    }

    getGrid() {
        return <div>
            <ReactTable
                data={this.props.state.optlist_data}
                columns={this.getColumns()}
                manual
                loading={this.loading}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div>
    }

    getBidder() {
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row>
            <Col><Button variant='contained' className='global_button'
                    label="Send Mail"
                    primary={true}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.sendBidderMail(null,this.props.state.optlist_data)
                    }}
                >{'Send Offer Mail To Bidders'}</Button>
                <br />
                {'Offer email will be send to the following bidders'}
                </Col>
            </Row>
            <Row style={styles.rowHeader}>
                <Col>Bidder Name</Col> 
                <Col>Bidder Email</Col>
            </Row>
            {
                this.props.state.bidders.map(x => {
                    return <Row>
                    <Col>{x.suppliername}</Col> 
                    <Col>{x.contactemail}</Col> 
                </Row>
                })
            }
        </Container>
    }

    gridLayout() {
        return <Card  expandable={false}>
            <CardHeader
                
                actAsExpander={true}
                showExpandableButton={true}
                title={'Bidders Mail'}
                subtitle={'Mailing list'}
                avatar={
                    <Icon iclass={'custom-icons'} iname={'workflow'} />
                }
            />
            <CardContent expandable={false}>
                {this.getBidder()}
            </CardContent>
            <CardHeader
                
                actAsExpander={true}
                showExpandableButton={true}
                title={'OPT List'}
                subtitle={'Vehicle list'}
                avatar={
                    <Icon iclass={'custom-icons'} iname={'workflow'} />
                }
            />
            <CardContent expandable={false}>
                {this.getGrid()}
            </CardContent>
        </Card>
    }

    getLayout() {
        return <Drawer
                width={800}
                anchor={'right'}
                openSecondary={true}
                open={this.props.state.inforsheet_otp_list}
                onRequestChange={(event) => {
                    event.preventDefault()
                    this.props.actions.toggleInfoSheet(null,'inforsheet_otp_list')
                }}>
                <br /> <br /> 
                <Button variant='contained' className='global_button'
                    style= {{ width: '120px'}}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.toggleInfoSheet(null,'inforsheet_otp_list')
                    }}>
                        {<Icon iclass={'material-icons'} iname={'close'} />} &nbsp; {window.t.en('Close')}
                </Button><br /> <br />
                   { this.gridLayout() }
            </Drawer>
    }

    render() {
        this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalOTPList)