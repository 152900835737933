
export const GET_VEHICLES_ACTION = 'GET_VEHICLES_ACTION'
export const GET_VEHICLES_SELECTED_ACTION = 'GET_VEHICLES_SELECTED_ACTION'
export const SET_VEHICLES_ACTION = 'SET_VEHICLES_ACTION'
export const SET_RENEWAL_SELECTED_ACTION = 'SET_RENEWAL_SELECTED_ACTION'
export const SET_VEHICLE_CUSTOMER_VALUE = 'SET_VEHICLE_CUSTOMER_VALUE'
export const GET_CUSTOMER_ACTION = 'GET_CUSTOMER_ACTION'
export const SET_CUSTOMER_ACTION = 'SET_CUSTOMER_ACTION'
export const SET_NEW_CUSTOMER_VALUE = 'SET_NEW_CUSTOMER_VALUE'
export const FINALIZE_VEHICLE_TRANSFER_ACTION = 'FINALIZE_VEHICLE_TRANSFER_ACTION'
export const SET_FINALIZE_VEHICLE_TRANSFER = 'SET_FINALIZE_VEHICLE_TRANSFER'
export const SET_DATE_FIELD_INPUT_VALUE = 'SET_DATE_FIELD_INPUT_VALUE'


export const SET_RENEWAL_DETAIL = 'SET_RENEWAL_DETAIL'
export const SET_LICENCERENEWAL_FIELD_VALUE = 'SET_LICENCERENEWAL_FIELD_VALUE'
export const SET_CALCULATED_TOTAL = 'SET_CALCULATED_TOTAL'





