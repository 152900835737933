//import { applyMiddleware, createStore } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { createMemoryHistory, createBrowserHistory  } from 'history'
import { createEpicMiddleware ,ofType } from 'redux-observable'

import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'

import { concat as concat$,of as of$, merge as merge$, from as from$, bindNodeCallback as bindNodeCallback$, takeUntil, map, mergeMap, delay, catchError, startWith, switchMap} from 'rxjs'
import { ajax } from 'rxjs/ajax';
import * as Rx from 'rxjs'

import { responsiveStoreEnhancer } from 'redux-responsive'

import { monitor } from './monitor'
import { notify } from './notify'
import { messenger } from './messenger'
import { cognito } from './cognito'
import { router } from './router'

import epics from './epics'
import reducers from './reducers'

const history =  createMemoryHistory({
    initialEntries: ['/'],
    initialIndex: 0,
})

const middlewareRouter = routerMiddleware(history)

const epicMiddleware = createEpicMiddleware({
    dependencies: {
        ajax$: ajax,
        concat$: concat$,
        get$: ajax.getJSON,
        merge$: merge$,
        bindNodeCallback: bindNodeCallback$,
        of$: of$,
        from$: from$,
        post$: ajax.post,
        Rx: Rx,
        map,
        mergeMap, 
        startWith,
        delay, 
        catchError, 
        ofType,
        takeUntil,
        switchMap
    }
})

const applyMiddlewareByEnv = () => {
    return process.env.NODE_ENV === 'local'
        ? applyMiddleware(
            thunk,
            epicMiddleware,
            middlewareRouter,
            monitor,
            logger,
            notify,
            messenger,
            cognito,
            router
        )
        : applyMiddleware(
            thunk,
            epicMiddleware,
            middlewareRouter,
            monitor,
            logger,
            notify,
            messenger,
            cognito,
            router
        )
}

if (process.env.NODE_ENV !== "development") {
    //console.log('module.hot',module.hot)
    if (module.hot) {
        module.hot.accept();
        module.hot.accept('./reducers', () => {
            store.replaceReducer(reducers)
        })
    }
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancer(
    responsiveStoreEnhancer,
    applyMiddlewareByEnv()));

epicMiddleware.run(epics);

export {
    history,
    store
}