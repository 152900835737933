import * as types from './types'

export const setWorkflowQueueTaskState = (workflow_queue_task_id) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOW_QUEUE_TASK_DOCUMENTS_SET_WORKFLOW_QUEUE_TASK_DOCUMENT_STATE,
            payload: workflow_queue_task_id
        })
    }
}

export const downloadDocument = (document) => {
    return (dispatch) => {
        dispatch({
            type: 'DOWNLOAD_DOCUMENT',
            payload: document.path + document.filename
        })
    }
}

export const getWorkflowQueueTaskDocuments = (workflow_queue_task_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.WORKFLOW_QUEUE_TASK_DOCUMENTS_GET_WORKFLOW_QUEUE_TASK_DOCUMENTS,
            payload: workflow_queue_task_id
        })
    }
}

export const setWorkflowQueueTaskDocuments = (workflow_queue_task_id, payload) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOW_QUEUE_TASK_DOCUMENTS_SET_WORKFLOW_QUEUE_TASK_DOCUMENTS,
            payload: {
                workflow_queue_task_id: workflow_queue_task_id,
                data: payload
            }
        })
    }
}