import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as gridTypes from '../AdhocGrid/types'
import * as appTypes from '../../App/types'
import * as types from './types'
let btype = 'fixed'

export const getBilling = () => {
    return {
        type: types.GET_BILLING_LIST,
        payload: {
            type: "SUM"
        }
    }
}

export const setBillingList = (payload) => {
    return {
        type: types.SET_BILLING_LIST,
        payload: {
            data: payload
        }
    }
}

export const getBillingInvoices = (search, type) => {
    btype = type
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BILLING_RERUN_FLAG,
            payload: true
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Refreshing Billing...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'billing_module',
                uri: '/components/billingmodule/other',
                body: {
                    type: type,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const exportBilling = (type, doc) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Exporting ' + type + ' Billing...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.EXPORT_BILLING,
            payload: {
                type: type,
                top: 1000000,
                searchText: '',
                export: doc
            }
        })
    }
}

export const setBillingRerun = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Completed Re-running Billing...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.SET_BILLING_RERUN_FLAG,
            payload: true
        })
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'billing_module',
                uri: '/components/billingmodule/other',
                body: {
                    type: btype,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const getRerunBilling = (type) => {
    btype = type
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BILLING_RERUN_FLAG,
            payload: false
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Re-running Billing...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.BILLING_RERUN,
            payload: {
                type: "BILLING_RERUN",
                top: 200,
                searchText: '',
                actiontype: type
            }
        })
    }
}

export const setBillingInvoiceList = (payload, type) => {
    return (dispatch, getState) => {
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'billing_module',
                uri: '/components/billingmodule/other',
                body: {
                    type: type,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        const format = (field, input) => {
            const definition = getState().components.billingmodule.component_field.filter(x => x.name === field)[0]

            if (definition) {
                switch (definition.component_field_display_type.name) {
                    case 'datePicker':
                        input = input.toString().trim()
                        const now = moment(new Date())
                        return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
                    case 'timePicker':
                        input = input.toString().trim()
                        return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
                    default:
                        return input
                }
            }
            else {
                return input
            }
        }

        dispatch({
            type: types.SET_ANY_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: format(field, value),
                component: 'billingmodule'
            }
        })
    }
}

export const previewinvoice = (invoicenumber) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.GENERATE_PREVIEW_INVOICE,
            payload: {
                type: "GENERATE_PREVIEW_INVOICE",
                invoicenumber: invoicenumber
            }
        })
    }
}

export const selectall = (flag) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SELECT_ALL,
            payload: flag
        })
    }
}

export const approved_selectall = (flag) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVED_SELECT_ALL,
            payload: flag
        })
    }
}
export const dummyrun = (flag) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.DUMMY_RUN,
            payload: flag
        })
    }
}
export const sendinvoices = (flag) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SEND_INVOICES,
            payload: flag
        })
    }
}

export const linkinvoices = (flag) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.LINK_INVOICES,
            payload: flag
        })
    }
}

export const billingapproval = (action, data, list) => {
    let new_list = list ? list : []
    switch (action) {
        case "addrow":
            {
                new_list.push(data)
            }
            break;
        case "removerow":
            {
                new_list.splice(new_list.indexOf(data), 1)
            }
            break;
    }
    return (dispatch, getState) => {
        dispatch({
            type: types.BILLING_APPROVAL_LIST,
            payload: new_list
        })
        //dispatch(setBillingList(new_list))
    }
}

export const customemail = (email) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CUSTOM_EMAIL,
            payload: email
        })
    }
}

export const setCurrentTab = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CURRENT_TAB,
            payload: value
        })
    }
}

export const setMainTab = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MAIN_TAB,
            payload: value
        })
    }
}

export const setDefaultTab = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFAULT_TAB,
        })
    }
}
export const setPreviewInvoice = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const excelExport = (res, type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: type + " " + moment().format('YYYYMMDD h:mm A'),
                data: res.data,
                header: res.headers
            }
        })
    }
}
export const getCISExcelExport = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BILLING_RERUN_FLAG,
            payload: false
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.GEN_CIS_EXCEL,
            payload: {
                type: "GEN_CIS_EXCEL",
                data: {}
            }
        })
    }
}
export const setCISExcel = (data) => {
    return (dispatch, getState) => {
        const header = [
            { key: 'Month', name: 'Month', default: ' ', },
            { key: 'Business Unit', name: 'Business Unit', default: ' ' },
            { key: 'Division/Depot', name: 'Division/Depot', default: ' ', },
            { key: 'Cost Centre', name: 'Cost Centre', default: ' ' },
            { key: 'Region', name: 'Region', default: '' },
            { key: 'Account No', name: 'Account No', default: ' ' },
            { key: 'Location', name: 'Location', default: ' ' },
            { key: 'Area', name: 'Area', default: ' ' },
            { key: 'Fleet Manager', name: 'Fleet Manager', default: '0' },
            { key: 'RFQ Received Date', name: 'RFQ Received Date', default: '' },
            { key: 'Quote Sent Date', name: 'Quote Sent Date', default: '' },
            { key: 'Actual Days1', name: 'Actual Days', default: '' },
            { key: 'Target Days1', name: 'Target Days', default: '' },
            { key: 'Variance1', name: 'Variance', default: '' },
            { key: 'Approval Received Date', name: 'Approval Received Date', default: '' },
            { key: 'Actual Days2', name: 'Actual Days', default: '' },
            { key: 'Target Days2', name: 'Target Days', default: '' },
            { key: 'Variance2', name: 'Variance', default: '' },
            { key: 'Vehicle Delivery Date', name: 'Vehicle Delivery Date', default: '' },
            { key: 'Actual Days3', name: 'Actual Days', default: '' },
            { key: 'Target Days3', name: 'Target Days', default: '' },
            { key: 'Variance3', name: 'Variance', default: '' },
            { key: 'Reg No', name: 'Reg No', default: '' },
            { key: 'Contract No', name: 'Contract No', default: '' },
            { key: 'Contract Type', name: 'Contract Type', default: '' },
            { key: 'Rate Category', name: 'Rate Category', default: '' },

            { key: 'Make', name: 'Make', default: '' },
            { key: 'Model', name: 'Model', default: '' },
            { key: 'Year', name: 'Year', default: '' },
            { key: 'Fuel Type', name: 'Fuel Type', default: '' },
            { key: 'Engine Capacity', name: 'Engine Capacity', default: '' },
            { key: 'Tyres Allocated', name: 'Tyres Allocated', default: '' },
            { key: 'Tyres Used', name: 'Tyres Used', default: '' },
            { key: 'Retail Price', name: 'Retail Price', default: '' },
            { key: 'RT 57 Price', name: 'RT 57 Price', default: '' },
            { key: 'Discount Amount', name: 'Discount Amount', default: '' },
            { key: 'Discount %', name: 'Discount %', default: '' },
            { key: 'Accessory Amount', name: 'Accessory Amount', default: '' },
            { key: 'Finance Sundries', name: 'Finance Sundries', default: '' },
            { key: 'Interest %', name: 'Interest %', default: '' },
            { key: 'Residual Value', name: 'Residual Value', default: '' },
            { key: 'RV %', name: 'RV %', default: '' },
            { key: 'Start Date', name: 'Start Date', default: '' },
            { key: 'End Date', name: 'End Date', default: '' },
            { key: 'Contract Period', name: 'Contract Period', default: '' },
            { key: 'Inclusive KM per Month', name: 'Inclusive KM per Month', default: '' },
            { key: 'Total Contract KM', name: 'Total Contract KM', default: '' },
            { key: 'Licence Expiry Date', name: 'Licence Expiry Date', default: '' },
            { key: 'Odo Start', name: 'Odo Start', default: '' },
            { key: 'Odo End', name: 'Odo End', default: '' },
            { key: 'KM Travelled', name: 'KM Travelled', default: '' },
            { key: 'KM Travelled LTD', name: 'KM Travelled LTD', default: '' },
            { key: 'Average LTD Km', name: 'Average LTD Km', default: '' },
            { key: 'Fixed Rental', name: 'Fixed Rental', default: '' },
            { key: 'Repairs and Maintenance', name: 'Repairs and Maintenance', default: '' },
            { key: 'Licence Fee', name: 'Licence Fee', default: '' },
            { key: 'Tracking Cost and Services', name: 'Tracking Cost and Services', default: '' },
            { key: 'Admin Fee', name: 'Admin Fee', default: '' },
            { key: 'Total Fixed Rental (Excl. VAT)', name: 'Total Fixed Rental (Excl. VAT)', default: '' },
            { key: 'VAT1', name: 'VAT', default: '' },
            { key: 'Total Fixed Rental (Incl. VAT)', name: 'Total Fixed Rental (Incl. VAT)', default: '' },
            { key: 'Start Date of Excess Billing', name: 'Start Date of Excess Billing', default: '' },
            { key: 'End Date of Excess Billing', name: 'End Date of Excess Billing', default: '' },
            { key: 'Start Odo of Excess Billing', name: 'Start Odo of Excess Billing', default: '' },
            { key: 'End Odo of Excess Billing', name: 'End Odo of Excess Billing', default: '' },
            { key: 'Total KM Travelled', name: 'Total KM Travelled', default: '' },
            { key: 'Allowed KM', name: 'Allowed KM', default: '' },
            { key: 'Excess KM Travelled', name: 'Excess KM Travelled', default: '' },
            { key: 'Excess RM (CPK)', name: 'Excess RM (CPK)', default: '' },
            { key: 'Excess RV (CPK)', name: 'Excess RV (CPK)', default: '' },
            { key: 'Excess RM Amount', name: 'Excess RM Amount', default: '' },
            { key: 'Excess RV Amount', name: 'Excess RV Amount', default: '' },
            { key: 'Total Excess Amount Excl VAT', name: 'Total Excess Amount Excl VAT', default: '' },
            { key: 'VAT2', name: 'VAT', default: '' },
            { key: 'Total Excess Amount Incl VAT', name: 'Total Excess Amount Incl VAT', default: '' },
            { key: 'Operator Default', name: 'Operator Default', default: '' },
            { key: 'Accident and Write Offs', name: 'Accident and Write Offs', default: '' },
            { key: 'Tyres', name: 'Tyres', default: '' },
            { key: 'Additional Costs', name: 'Additional Costs', default: '' },
            { key: 'Auxiliary Amount', name: 'Auxiliary Amount', default: '' },
            { key: 'OOCP', name: 'OOCP', default: '' },
            { key: 'Fuel Cost', name: 'Fuel Cost', default: '' },
            { key: 'Fuel Litres', name: 'Fuel Litres', default: '' },
            { key: 'Oil Cost', name: 'Oil Cost', default: '' },
            { key: 'Oil Litres', name: 'Oil Litres', default: '' },
            { key: 'Monthly Fines Cost', name: 'Monthly Fines Cost', default: '' },
            { key: 'Monthly E-Toll Cost', name: 'Monthly E-Toll Cost', default: '' },
            { key: 'Subtotal Variable Cost', name: 'Subtotal Variable Cost', default: '' },
            { key: 'VAT3', name: 'VAT', default: '' },
            { key: 'Total Variable Cost', name: 'Total Variable Cost', default: '' },
            { key: 'Total Cost Incl VAT', name: 'Total Cost Incl VAT', default: '' },
            { key: 'Input VAT', name: 'Input VAT', default: '' },
            { key: 'Total Cost excl Input VAT', name: 'Total Cost excl Input VAT', default: '' },
            { key: 'CPK TCO', name: 'CPK TCO', default: '' },
            { key: 'Maintenance Days', name: 'Maintenance Days', default: '' },
            { key: 'Accidents Days', name: 'Accidents Days', default: '' },
            { key: 'Breakdowns Days', name: 'Breakdowns Days', default: '' },
            { key: 'Total Availability %', name: 'Total Availability %', default: '' },
            { key: 'Deviation Contract End Date', name: 'Deviation Contract End Date', default: '' },
            { key: 'Deviation Contract Period', name: 'Deviation Contract Period', default: '' },
            { key: 'Deviation Inclusive KM per Month', name: 'Deviation Inclusive KM per Month', default: '' },
            { key: 'Fixed Rental Prior to Restructure', name: 'Fixed Rental Prior to Restructure', default: '' },
            { key: 'Overs and Unders (Incl 5% Tolerance)', name: 'Overs and Unders (Incl 5% Tolerance)', default: '' },
            { key: 'Estimated Replacement Date Time & KM', name: 'Estimated Replacement Date Time & KM', default: '' },
            { key: 'Estimated Replacement Time', name: 'Estimated Replacement Time', default: '' },
            { key: 'Estimated Replacement KM', name: 'Estimated Replacement KM', default: '' },
            { key: 'Deal Status', name: 'Deal Status', default: '' },
            { key: 'CLO Name', name: 'CLO Name', default: '' },
            { key: 'Excess RM YTD', name: 'Excess RM YTD', default: '' },
            { key: 'Excess RV YTD', name: 'Excess RV YTD', default: '' },
        ]

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: "Billing CIS " + moment().format('YYYYMMDD h:mm A'),
                data: data.data.excel,
                header: header
            }
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.SET_BILLING_RERUN_FLAG,
            payload: true
        })
    }

}

export const setApprovedBillingList = (type, data, flag, user) => {
    let approved = data.length ? data.map((x, i) => {
        return {
            ...x,
            allocated: false
        }
    }) : []
    return (dispatch, getState) => {
        dispatch({
            type: gridTypes.ADHOC_GRID_SET_DATA,
            payload: {
                name: 'billing_module_approved_' + type,
                data: [],
                paging: {
                    page: 0,
                    pageSize: 10,
                    pages: 0,
                    count: 0
                },
                loading: true
            }
        })
        dispatch({
            type: types.APPROVE_BILLING,
            payload: {
                action: "approve",
                list: data,
                type: type,
                selectedall: flag,
                user: user
            }
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Approved [' + (approved.length >= 50 ? '50 or more...' : approved.length) + '] ' + type.toUpperCase() + ' Billing Invoices',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

    }
}
export const approvedBillingList = (res, payload) => {
    if (res.length > 0) {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Action Successful...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
            dispatch({
                type: gridTypes.ADHOC_GRID_SET_DATA,
                payload: {
                    name: 'billing_module_approved_' + payload.type,
                    data: payload.list,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    loading: false
                }
            })
        }
    }
}
export const setCancelBillingList = (type, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_BILLING,
            payload: {
                action: "cancel",
                list: [],
                type: type
            }
        })
    }
}
export const getOdoTemplateExport = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.EXPORT_BILLING,
            payload: {
                type: 'odofile',
                top: 1000000,
                searchText: '',
                export: 'Odo File'
            }
        })
    }
}
export const getCPKExport = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.EXPORT_BILLING,
            payload: {
                type: 'cpkfile',
                top: 1000000,
                searchText: '',
                export: 'CPK File'
            }
        })
    }
}
export const generateBilling = (type, data, flag, user) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_BILLING,
            payload: {
                action: "generate",
                list: data,
                type: type,
                selectedall: flag,
                user: user
            }
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating [' + (data.length >= 50 ? '50 or more...' : data.length) + '] ' + type.toUpperCase() + ' Billing Invoices',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

    }
}