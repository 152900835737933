import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import EnterestGrid from './enterestreview'
import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'

import * as styles from './styles'
import * as actions from './actions'
import moment from 'moment';
import TextField from '@mui/material/TextField'
import Upload from '../../Upload/component'
import Dropzone from 'react-dropzone'


import Button from '@mui/material/Button'
import ReactTable from "react-table"
import Icon from '../../Icon/component'

import { green, red } from '@mui/material/colors'


class EnterestReview extends Component {
    componentDidMount() {
        this.props.actions.reset()
        this.props.actions.getEnterestData()
    }

    getView() {  
        return  <Card title={window.t.en('Statements Run')}
                subtitle={window.t.en('Statement preview and run')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                    <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <EnterestGrid />
                            </Col>
                        </Row>
                    </Container>
                }
                headerAction={
                    <div style={{float: 'right'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            className="btn-primary"
                            onClick={(event, value) => {
                                event.preventDefault()
                                this.props.actions.downlaodEnterest()
                                
                            }}
                            >
                            {"Download Data"}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="btn-default"
                            onClick={(event, value) => {
                                event.preventDefault()
                                this.props.actions.setValue('refreshing',true)
                                this.props.actions.getEnterestData()
                            }}
                            >
                            {"Refresh Data"}
                        </Button>
                    </div>
                }
            />
    }

    commentsView() {
        return <div>
            <Card 
                title={window.t.en('Confirm Interest')}
                subtitle={window.t.en('By ticking the box you confirm you have check Interest')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                    <Col xl={12}>
                        <br/>
                        <Upload
                            id={'upload_file'}
                            prefix={'Enterest_Check_upload_' + moment().format("YYYYMMDDmmss")}
                            header={false}
                            list={[{ value: 0, text: 'Interest Check File' }]}
                        />
                    </Col>
                    <Col xl={12}>
                        <br/>
                    </Col>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomment'}
                                key={'txtcomment'}
                                label={window.t.en('Enter Comments...')}
                                fullWidth={true}
                                variant="outlined"
                                value={this.props.state.comments}
                                onChange={
                                    (event) => {
                                        this.props.actions.setValue('comments', event.target.value)
                                    }
                                }
                            /></Col>
                            
                    <Col xl={12}><hr /></Col>
                    <Col xl={12}>
                        <div>
                            <h4>Confirm </h4>
                            <ul>
                                <li>That the enterest file has been check</li>
                                <li>Uploaded the enterest file that was checked</li>
                            </ul>
                        </div>
                    </Col>
                        <Col xl={12}>
                            <Checkbox 
                                checked={this.props.state.confirm} 
                                label="I confirm"
                                onClick={(event) => {
                                    window.glyco.log('chk cheched',event)
                                    this.props.actions.setValue('confirm', event)
                            }} />
                        </Col>
                        
                    </Row>
                </Container>} />
        </div>
    }

    getLayout() {
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row>
            <Col xl={12}>
                {this.getView()}
            </Col>
        </Row>
        <Row>
            <Col xl={12}>
                {this.commentsView()}
            </Col>
        </Row>
    </Container>
    }
    render() {
        return (this.getLayout())
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.enterestReview
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterestReview)