import React from 'react';
import { Checkbox, FormControlLabel, FormLabel, FormControl, FormHelperText } from '@mui/material';

export default function Checkboxes(props) {
    const { id, key, name, label, field, checked, type, labelPlacement, defaultValue,onCheck, onClick } = props

 
    return (
        <div>
            <FormControlLabel sx={{fontSize: '14px'}}
                control={
                    <Checkbox
                    checked={checked}
                    onChange={
                        (event) => {
                            onCheck?onCheck(event.target.checked):onClick(event.target.checked)
                        }
                    }
                    sx={{fontSize: '14px'}}
                    label={label}
                    defaultValue={defaultValue}
                />
                }
                label={<div style={{fontSize: '14px'}}>{label}</div>}
            />
        </div>
    );
}
