import * as types from './types'
import * as appTypes from '../App/types'
import * as signinTypes from '../SignedIn/types'
import moment from 'moment'

export const confirmDelete = (value) => {
    return (dispatch, getState) => {
        // dispatch({
        //     type: types.SET_COMPONENT_EVENT_PROPS_VALUES,
        //     payload: value
        // })
        //console.log('confirmDelete', value)
        dispatch(setInputValue({'deleterecordwin': value}))
    }
}

export const addRule = () => {
    return (dispatch, getState) => {
        let rules = getState().events.rules
        rules.push({inx: rules.length, field_id: 0, field: '', operator: '', condition: '',escalation_condition: '',chk_escalation: false,field_escalation_teams:[],field_escalation_users:[]})
        dispatch(setInputValue({prop: 'rules', value: rules}))
    }
}

export const removeRow = (inx) => {
    return (dispatch, getState) => {
        let rules = getState().events.rules.filter(x => x.inx !== inx)
        rules = rules.map((x,i) => { return { ...x, inx: i }})
        dispatch(setInputValue({prop: 'rules', value: rules}))
    }
}

export const setRuleValue = (data) => {
    return (dispatch, getState) => {
        let rules = getState().events.rules
        rules[data.inx][data.prop] = data.value
        dispatch(setInputValue({prop: 'rules', value: rules}))
    }
}

export const getCategory = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_CATEGORY,
            payload: search
        })
    }
}

export const reset_form = () => {
    return (dispatch) => {
        dispatch({
            type: types.RESET_FORM,
        })
    }
}
export const getComponent = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_COMPONENT,
            payload: search
        })
    }
}

export const getOperator = (operator, search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_OPERATOR,
            payload: {
                operator: operator,
                search: search
            }
        })
    }
}

export const getTeam = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_TEAM,
            payload: search
        })
    }
}

export const getUser = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_USER,
            payload: search
        })
    }
}

export const getSchedule = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_SCHEDULE,
            payload: search
        })
    }
}

export const getActions = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_ACTION,
            payload: search
        })
    }
}

export const getEscalation = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_ESCALATION,
            payload: search
        })
    }
}

export const getIndicator = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_INDICATOR,
            payload: search
        })
    }
}

export const getField = (component_id, search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_COMPONENT_FIELD,
            payload: {
                component_id: component_id,
                search: search
            }
        })
    }
}


export const getWorkflow = (component_id, search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_WORKFLOW,
            payload: search
        })
    }
}

export const getEscalationTypes = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_EVENT_ESCALATION_TYPES,
            payload: search
        })
    }
}
export const setCategory = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'category_list', value: payload.map(x => {return {text: x.workqueue_category, value: x.workqueue_category_id}})}))
    }
}

export const setComponent = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'component_list', value: payload.map(x => {return {text: x.title, value: x.component_id}})}))
    }
}

export const setField = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'field_list', value: payload.map(x => {return {...x, text: x.title, value: x.component_field_id}})}))
    }
}

export const setOperator = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'operator_list', value: payload.map(x => {return {...x, text: x.workqueue_operator, value: x.workqueue_operator_id}})}))
    }
}

export const setSchedule = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'schedule_list', value: payload.map(x => {return {...x, text: x.workqueue_schedule, value: x.workqueue_schedule_id}})}))
    }
}

export const setEscalation = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'escalation_list', value: payload.map(x => {return {...x, text: x.workqueue_escalation, value: x.workqueue_escalation_id}})}))
    }
}

export const setUser = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'user_list', value: payload.map(x => {return {...x, text: x.firstname + ' ' + x.lastname, value: x.user_id}})}))
    }
}

export const setTeam = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'team_list', value: payload.map(x => {return {...x, text: x.title, value: x.team_id}})}))
    }
}

export const setActions = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'actions_list', value: payload.map(x => {return {...x, text: x.workqueue_actions, value: x.workqueue_actions_id}})}))
    }
}

export const setIndicator = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'workqueue_indicator', value: payload.map(x => {return {...x, text: x.workqueue_indicator, value: x.workqueue_indicator_id}})}))
    }
}

export const setWorkflow = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'workflow_list', value: payload.map(x => {return {...x, text: x.title, value: x.workflow_id}})}))
    }
}
export const setEscalationTypes = (payload) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'escalation_types', value: payload.map(x => {return {...x, text: x.workqueue_escalation_type, value: x.workqueue_escalation_type_id}})}))
    }
}

export const addTeamEscalation = (data) => {
    return (dispatch, getState) => {
        let teams = getState().events.selected_escalation
        teams.push(data.value[0])
        dispatch(setInputValue({prop: data.prop, value: teams}))
    }
}

export const rmTeamEscalation = (data) => {
    return (dispatch, getState) => {
        let teams = getState().events.selected_escalation.filter(x => x.team_id != data.value)
        dispatch(setInputValue({prop: data.prop, value: teams}))
    }
}

export const rmUserActions = (data) => {
    return (dispatch, getState) => {
        //console.log('rmUserActions', data)
        //console.log('getState().events.actions', getState().events.actions)
        let actions = getState().events.actions.filter(x => x.workqueue_actions_id != data.value)
        //console.log('actions', actions)
        dispatch(setInputValue({prop: data.prop, value: actions}))
    }
}

export const addUserEscalation = (data) => {
    return (dispatch, getState) => {
        let users = getState().events.selected_escalation
        users.push(data.value[0])
        dispatch(setInputValue({prop: data.prop, value: users}))
    }
}

export const addActions = (data) => {
    return (dispatch, getState) => {
        let actions = getState().events.actions
        actions.push(data.value[0])
        dispatch(setInputValue({prop: data.prop, value: actions}))
    }
}
export const rmUserEscalation = (data) => {
    return (dispatch, getState) => {
        //{prop: 'selected_escalation', value: x.user_id}
        let users = getState().events.selected_escalation.filter(x => x.user_id !== data.value)
        dispatch(setInputValue({prop: data.prop, value: users}))
    }
}

export const setInputValue = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_EVENT_PROPS_VALUES,
            payload: data
        })
    }
}


export const sendEmail = (to, name,message) => {
    return (dispatch) => {
        dispatch({
            type: types.SEND_EVENTS_MANAGMENT_EMAIL,
            payload: {
                to: to,
                key: "HyM9bxT5O",
                type: "send_email",
                subject: "Event Management - Notification",
                replyTo: "noreply@infleet.co.za",
                data: {
                    fullname: name,
                    message: message
                }
            }
            
        })
    }
}

export const sendEmailDone = (data) => {
    return (dispatch,getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Email sent',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const saveEvent = (data) => {
    return (dispatch, getState) => {
        //console.log('saveEvent', data)
        dispatch(setInputValue({ prop: 'saving', value: true }))
        dispatch({
            type: types.SAVE_COMPONENT_EVENT_DATA,
            payload: {
                ...data,
                user_id: getState().user.user_id,
                user: getState().user.firstname + ' ' + getState().user.lastname,
                type: 'save_event'
            }
        })
    }
}

export const saveEventDone = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Event successfully saved',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        
        // dispatch({
        //     type: signinTypes.GET_WORKQUEUE_LIST_ITEMS,
        // })
        // dispatch(setInputValue({ prop: 'saving', value: false }))
        // dispatch(setInputValue({ prop: 'add_win', value: false }))
        // dispatch(loadEvents())
        // dispatch(setInputValue({ prop: 'tab', value: 0 }))
    }
}

export const loadEvents = () => {
    return (dispatch) => {
        dispatch({
            type: types.LOAD_COMPONENT_EVENT_DATA
        })
        dispatch({
            type: types.LOAD_COMPONENT_EVENT_LOGS
        })
    }
}

export const setEventsData = (data) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'workqueue', value: data}))
    }
}

export const setEventLogs = (data) => {
    return (dispatch) => {
        dispatch(setInputValue({prop: 'workqueue_calendar', value: data.map(x => {return {...x, start: x.created_at, end: x.created_at, title: x.workqueue_log}})}))
    }
}
export const loadEvent = (event_id) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_LOAD_EVENT_DATA,
            payload: event_id
        })
    }
}

export const loadEventDone = (data) => {
    //console.log('loadEventDone',data)
    let _data = {
        event_name: data.workqueue_name,
        rules: data.workqueue_field.map(x => {
            return {
                ...x,field_name: x.workqueue_field_name,operator_text: x.workqueue_field_operator, chk_escalation: x.workqueue_escalate,condition: x.workqueue_field_condition,
                escalation_condition: x.workqueue_escalation_condition, escalation_type_name: x.workqueue_escalation_type, escalation_name: x.workqueue_escalation,
                escalation_name:x.workqueue_escalation,operator: [{workqueue_operator_input: x.workqueue_field_condition}], field: [{...x.component_field, text: x.workqueue_field_name, value: x.component_field_id} ]
            }}),
        component: data.workqueue_model,
        component_id: data.component_id,
        category: data.workqueue_section,
        start_date: data.workqueue_start_date,
        end_date: data.workqueue_end_date,
        //operator: ''
        //escalation: ''
        action: data.workqueue_action,
        escalation: data.workqueue_escalation,
        //action_id: ''
        //schedule: ''
        //user: ''
        selected_escalation: data.workqueue_escalation_user.length ? data.workqueue_escalation_user.map(x => x.user) : data.workqueue_escalation_team.map(x => x.team),
        //operator_text: ''
        field: '',//[{...data.workqueue_field[0].component_field, text: data.workqueue_field[0].workqueue_field_name, value: data.workqueue_field[0].component_field_id}],
        //field_list: data.component.component_field, // [{...data.workqueue_field[0].component_field, text: data.workqueue_field[0].workqueue_field_name, value: data.workqueue_field[0].component_field_id}],
        field_name: data.workqueue_field[0].workqueue_field_name,
        //team: ''
        actions: data.workqueue_field_actions.map(x => {return x.workqueue_actions}),
        indicator: data.workqueue_indicator,

        ind_good: data.workqueue_ind_good,
        ind_low: data.workqueue_ind_low,
        ind_medium: data.workqueue_ind_medium,
        ind_high: data.workqueue_ind_high,
        ind_critical: data.workqueue_ind_critical,
    }
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_LOAD_EVENT_DATA,
            payload: _data
        })
    }
}