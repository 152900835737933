import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                users: []
            }
        case types.SET_PAYMENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }       
        case types.SET_BATCH_NUMBER:
            return {
                ...state,
                batchNumber: action.payload
            }
        case types.SET_PAYMENT_TASK_NUMBER:
            return {
                ...state,
                taskNumber: action.payload
            }
        default:
            return state
    }
}