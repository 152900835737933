import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import moment from 'moment'
import _ from 'lodash'

import { Card, CardHeader, CardContent } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import Loading from '../../Loading/component'
import Icon from '../../Icon/component'
import * as styles from './styles'
import * as actions from './actions'

class BillingRunView extends Component {
    componentDidMount() {
        //this.props.actions.setValidationState(this.props.state.validation)
        this.props.actions.loadBillingData('')
    }

    getView() {
        const data = this.props.state.billingdata
        return data.length
        ? <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Billing Invoice')}
                    subtitle={window.t.en('List of billing run invoice')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row style={styles.headerrow}>
                            <Col xl={10}>
                            <div key={'workflow_queue_task_search_input'} style={styles.searchInput}>
                            <TextField
                                onChange={(event) => {
                                    this.props.actions.setSearchText(event.target.value)
                                }}
                                value={this.props.state.searchtext}
                                key={'workflow_queue_task_search_input_text'}
                                hintText={'Search...'}
                            />
                               < Button className={"global_button"} variant="contained"
                                icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.loadBillingData(this.props.state.searchtext)
                                }}
                                ><Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} /></Button>
                        </div>
                            </Col>
                            <Col xl={2}>
                           < Button className={"global_button"} variant="contained"
                                        primary={true}
                                        label="Download All Invoice"
                                        icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                                        onClick={(event) => {
                                            this.props.actions.downlaodAllDocuments(data)
                                        }}
                                        ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Download All Invoice</Button>
                            </Col>
                        </Row>
                        <Row style={styles.headerrow}>
                            <Col xl={1}>
                                {'Vehicle Reg'}
                            </Col>
                            <Col xl={2}>
                                {'Contract Number'}
                            </Col>
                            <Col xl={5}>
                                {'Customer'}
                            </Col>
                            <Col xl={2}>
                                {'Billing Date'}
                            </Col>
                            <Col xl={2}>
                                {'Header'}
                            </Col>
                        </Row>
                            {
                                data.map(x => {
                                    return  <Row style={styles.itemrow}>
                                    <Col xl={1}>
                                        {x.vehiclereg}
                                    </Col>
                                    <Col xl={2}>
                                        {x.contractnumber}
                                    </Col>
                                    <Col xl={5}>
                                        {x.customer}
                                    </Col>
                                    <Col xl={2}>
                                        {moment(x.billingdate).format('DD/MM/YYYY HH:mm')}
                                    </Col>
                                    <Col xl={2}>
                                   < Button className={"global_button"} variant="contained"
                                        primary={true}
                                        label="View Invoice"
                                        icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                                        onClick={(event) => {
                                            this.props.actions.downlaodDocuments(x.document.key)
                                        }}
                                        ><Icon iclass={'material-icons'} iname={'cloud_download'} /> View Invoice</Button>
                                    </Col>
                        </Row>
                                })
                            }
                    </Container>
                </CardContent>
            </Card>
        </div>
        : <Loading message={'Retrieving billing data. this may take a while, please wait...'} />
    }
    
    render() {
        return (
            <div style={{ margin: 0 }}>
                        {this.getView()}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.billingRunView
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingRunView)