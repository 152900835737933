export const CONNECT_USER_TO_MESSENGER = 'CONNECT_USER_TO_MESSENGER'
export const ENGINE_ACCESSORY_DOCUMENT_WORKFLOW_TASK_SUBMITTED = 'ENGINE_ACCESSORY_DOCUMENT_WORKFLOW_TASK_SUBMITTED'
export const ENGINE_ACTIVATION_DOCUMENT_CONTENT = 'ENGINE_ACTIVATION_DOCUMENT_CONTENT'
export const ENGINE_ACTIVATION_PO_DOCUMENT_CONTENT = 'ENGINE_ACTIVATION_PO_DOCUMENT_CONTENT'
export const ENGINE_ACTIVATION_QUOTE_DOCUMENT_CONTENT = 'ENGINE_ACTIVATION_QUOTE_DOCUMENT_CONTENT'
export const ENGINE_ACTIVATION_SCHEDULE1_DOCUMENT_CONTENT = 'ENGINE_ACTIVATION_SCHEDULE1_DOCUMENT_CONTENT'
export const ENGINE_CEO_DOCUMENT_CONTENT = 'ENGINE_CEO_DOCUMENT_CONTENT'
export const ENGINE_WORKFLOW_DOCUMENT_CONTENT = 'ENGINE_WORKFLOW_DOCUMENT_CONTENT'
export const ENGINE_CONNECTED_TO_MESSENGER = 'ENGINE_CONNECTED_TO_MESSENGER'
export const ENGINE_CONNECTION_LOST = 'ENGINE_CONNECTION_LOST'
export const ENGINE_COURIER_DOCUMENT_CONTENT = 'ENGINE_COURIER_DOCUMENT_CONTENT'
export const ENGINE_CUSTOMER_ALLOCATION_DATA = 'ENGINE_CUSTOMER_ALLOCATION_DATA'
export const ENGINE_CUSTOMER_CREDIT_NOTE_PDF = 'ENGINE_CUSTOMER_CREDIT_NOTE_PDF'
export const ENGINE_CUSTOMER_INVOICE_PDF = 'ENGINE_CUSTOMER_INVOICE_PDF'
export const ENGINE_DELIVERY_VAF_DOCUMENT_CONTENT = 'ENGINE_DELIVERY_VAF_DOCUMENT_CONTENT'
export const ENGINE_DISCONNECTED_FROM_MESSENGER = 'ENGINE_DISCONNECTED_FROM_MESSENGER'
export const ENGINE_DOCUMENT_CONTENT = 'ENGINE_DOCUMENT_CONTENT'
export const ENGINE_DOCUMENT_DISCOUNT_LETTER = 'ENGINE_DOCUMENT_DISCOUNT_LETTER'
export const ENGINE_DOCUMENT_DOES_NOT_EXIST = 'ENGINE_DOCUMENT_DOES_NOT_EXIST'
export const ENGINE_DUPLICATE_REFERENCE_CHECK = 'ENGINE_DUPLICATE_REFERENCE_CHECK'
export const ENGINE_EDIT_QUOTE_DOCUMENT_CONTENT = 'ENGINE_EDIT_QUOTE_DOCUMENT_CONTENT'
export const ENGINE_EDIT_QUOTE_PDF_DOCUMENT_CONTENT = 'ENGINE_EDIT_QUOTE_PDF_DOCUMENT_CONTENT'
export const ENGINE_EDIT_QUOTE_RFQ_DOCUMENT_CONTENT = 'ENGINE_EDIT_QUOTE_RFQ_DOCUMENT_CONTENT'
export const ENGINE_EXCEL_DOCUMENT = 'ENGINE_EXCEL_DOCUMENT'
export const ENGINE_GM_DOCUMENT_CONTENT = 'ENGINE_GM_DOCUMENT_CONTENT'
export const ENGINE_GRID_QUOTE_DOCUMENT_CONTENT = 'ENGINE_GRID_QUOTE_DOCUMENT_CONTENT'
export const ENGINE_INVOICE_DUPLICATE_REFERENCE_CHECK = 'ENGINE_INVOICE_DUPLICATE_REFERENCE_CHECK'
export const ENGINE_JOURNAL_DUPLICATE_REFERENCE_CHECK = 'ENGINE_JOURNAL_DUPLICATE_REFERENCE_CHECK'
export const ENGINE_MAIL_WORKFLOW_TASK_APPROVED = 'ENGINE_MAIL_WORKFLOW_TASK_APPROVED'
export const ENGINE_MAIN_LEASE_DOCUMENT_CONTENT = 'ENGINE_MAIN_LEASE_DOCUMENT_CONTENT'
export const ENGINE_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'ENGINE_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const ENGINE_WS_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'ENGINE_WS_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const ENGINE_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES = 'ENGINE_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES'
export const ENGINE_NEW_WORKFLOW_TASK = 'ENGINE_NEW_WORKFLOW_TASK'
export const ENGINE_OEM_DOCUMENT_CONTENT = 'ENGINE_OEM_DOCUMENT_CONTENT'
export const ENGINE_PDF_MULTIPLE_DOCUMENT_CONTENT = 'ENGINE_PDF_MULTIPLE_DOCUMENT_CONTENT'
export const ENGINE_PO_DOCUMENT_CONTENT = 'ENGINE_PO_DOCUMENT_CONTENT'
export const ENGINE_PRE_AUTHORISATION_NUMBER = 'ENGINE_PRE_AUTHORISATION_NUMBER'
export const ENGINE_PURCHASE_ORDERS_SEARCH = 'ENGINE_PURCHASE_ORDERS_SEARCH'
export const ENGINE_QUOTE_DOCUMENT_CONTENT = 'ENGINE_QUOTE_DOCUMENT_CONTENT'
export const ENGINE_QUOTE_DOCUMENT_DOES_NOT_EXIST = 'ENGINE_QUOTE_DOCUMENT_DOES_NOT_EXIST'
export const ENGINE_QUOTE_HEADER_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES = 'ENGINE_QUOTE_HEADER_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES'
export const ENGINE_QUOTE_REVIEW_RFQ_DOCUMENT_CONTENT = 'ENGINE_QUOTE_REVIEW_RFQ_DOCUMENT_CONTENT'
export const ENGINE_RECEIPT_ACCESSORY_DOCUMENT_CONTENT = 'ENGINE_RECEIPT_ACCESSORY_DOCUMENT_CONTENT'
export const ENGINE_RECEIPT_DUPLICATE_REFERENCE_CHECK = 'ENGINE_RECEIPT_DUPLICATE_REFERENCE_CHECK'
export const ENGINE_REMITTANCE_ADVICE_PDF = 'ENGINE_REMITTANCE_ADVICE_PDF'
export const ENGINE_REVERSE_PAYMENT_DUPLICATE_REFERENCE_CHECK = 'ENGINE_REVERSE_PAYMENT_DUPLICATE_REFERENCE_CHECK'
export const ENGINE_REVERSE_RECEIPT_DUPLICATE_REFERENCE_CHECK = 'ENGINE_REVERSE_RECEIPT_DUPLICATE_REFERENCE_CHECK'
export const ENGINE_SEND_DOCUMENT = 'ENGINE_DOCUMENT_DOWNLOAD_STREAM'
export const ENGINE_SUPPLIER_ALLOCATION_DATA = 'ENGINE_SUPPLIER_ALLOCATION_DATA'
export const ENGINE_SUPPLIER_INVOICE_DUPLICATE_REFERENCE_CHECK = 'ENGINE_SUPPLIER_INVOICE_DUPLICATE_REFERENCE_CHECK'
export const ENGINE_SUPPLIER_PAYMENT_ACCOUNT = 'ENGINE_SUPPLIER_PAYMENT_ACCOUNT'
export const ENGINE_SUPPLIER_PAYMENT_DUPLICATE_REFERENCE_CHECK = 'ENGINE_SUPPLIER_PAYMENT_DUPLICATE_REFERENCE_CHECK'
export const ENGINE_VAF_DOCUMENT_CONTENT = 'ENGINE_VAF_DOCUMENT_CONTENT'
export const ENGINE_VEHICLE_BOOKING_SUPPLIER = 'ENGINE_VEHICLE_BOOKING_SUPPLIER'
export const ENGINE_SUPPLIER_MONTHLY_USAGE = 'ENGINE_SUPPLIER_MONTHLY_USAGE'
export const ENGINE_SUPPLIER_MONTHLY_SPEND = 'ENGINE_SUPPLIER_MONTHLY_SPEND'
export const ENGINE_VEHICLE_LASTPOSITION = 'ENGINE_VEHICLE_LASTPOSITION'
export const ENGINE_VEHICLE_SERVICE_DUE_FUNCTION = 'ENGINE_VEHICLE_SERVICE_DUE_FUNCTION'
export const ENGINE_WORKFLOW_COMPLETED = 'ENGINE_WORKFLOW_COMPLETED'
export const ENGINE_WORKFLOW_QUEUE_TASK_ACTIVE = 'ENGINE_WORKFLOW_QUEUE_TASK_ACTIVE'
export const ENGINE_WORKFLOW_QUEUE_TASK_CLEANED = 'ENGINE_WORKFLOW_QUEUE_TASK_CLEANED'
export const ENGINE_WORKFLOW_QUEUE_TASK_COMPLETED = 'ENGINE_WORKFLOW_QUEUE_TASK_COMPLETED'
export const ENGINE_WORKFLOW_QUEUE_TASK_ERROR = 'ENGINE_WORKFLOW_QUEUE_TASK_ERROR'
export const ENGINE_WORKFLOW_QUEUE_TASK_FAILED = 'ENGINE_WORKFLOW_QUEUE_TASK_FAILED'
export const ENGINE_WORKFLOW_QUEUE_TASK_PAUSED = 'ENGINE_WORKFLOW_QUEUE_TASK_PAUSED'
export const ENGINE_WORKFLOW_QUEUE_TASK_PROGRESS = 'ENGINE_WORKFLOW_QUEUE_TASK_PROGRESS'
export const ENGINE_WORKFLOW_QUEUE_TASK_RESUMED = 'ENGINE_WORKFLOW_QUEUE_TASK_RESUMED'
export const ENGINE_BILLING_INVOICE_PDF = 'ENGINE_BILLING_INVOICE_PDF'
export const ENGINE_STR_WORKINGS_PDF_DOWNLOAD = 'ENGINE_STR_WORKINGS_PDF_DOWNLOAD'

export const ENGINE_CUSTOMER_STATEMENT_LIST = 'ENGINE_CUSTOMER_STATEMENT_LIST'
export const ENGINE_CUSTOMER_STATEMENT_PDF = 'ENGINE_CUSTOMER_STATEMENT_PDF'
export const ENGINE_WORKFLOW_QUEUE_TASK_STALLED = 'ENGINE_WORKFLOW_QUEUE_TASK_STALLED'
export const ENGINE_WORKFLOW_TASK_APPROVED = 'ENGINE_WORKFLOW_TASK_APPROVED'
export const ENGINE_WORKFLOW_TASK_ASSIGNED = 'ENGINE_WORKFLOW_TASK_ASSIGNED'
export const ENGINE_WORKFLOW_TASK_CANCELLED = 'ENGINE_WORKFLOW_TASK_CANCELLED'
export const ENGINE_WORKFLOW_TASK_ESCALATED = 'ENGINE_WORKFLOW_TASK_ESCALATED'
export const ENGINE_WORKFLOW_TASK_REASSIGNED = 'ENGINE_WORKFLOW_TASK_REASSIGNED'
export const ENGINE_WORKFLOW_TASK_REJECTED = 'ENGINE_WORKFLOW_TASK_REJECTED'
export const ENGINE_WORKFLOW_TASK_REJECTED_FAILED = 'ENGINE_WORKFLOW_TASK_REJECTED_FAILED'
export const ENGINE_WORKFLOW_TASK_SUBMITTED = 'ENGINE_WORKFLOW_TASK_SUBMITTED'
export const ENGINE_WORKFLOW_TASK_UNASSIGNED = 'ENGINE_WORKFLOW_TASK_UNASSIGNED'
export const ENGINE_WORKFLOW_TASK_REQUEUED = 'ENGINE_WORKFLOW_TASK_REQUEUED'
export const MESSENGER_ACKNOWLEDGED_REQUEST = 'MESSENGER_ACKNOWLEDGED_REQUEST'
export const UI_CONNECTED_TO_MESSENGER = 'UI_CONNECTED_TO_MESSENGER'
export const UI_DISCONNECT_FROM_MESSENGER = 'UI_DISCONNECT_FROM_MESSENGER'
export const UI_DISCONNECTED_FROM_MESSENGER = 'UI_DISCONNECTED_FROM_MESSENGER'
export const UI_FAILED_TO_CONNECT_TO_MESSENGER = 'UI_FAILED_TO_CONNECT_TO_MESSENGER'
export const UI_RECEIVED_EVENT_FROM_MESSENGER = 'UI_RECEIVED_EVENT_FROM_MESSENGER'
export const UI_REQUEST_DOCUMENT_DOWNLOAD = 'UI_REQUEST_DOCUMENT_DOWNLOAD'

export const ENGINE_SUPPLIER_TRANSACTION_LIST = 'ENGINE_SUPPLIER_TRANSACTION_LIST'
export const ENGINE_CN_DUPLICATE_REFERENCE_CHECK = 'ENGINE_CN_DUPLICATE_REFERENCE_CHECK'
export const ENGINE_CREDITORS_JOURNAL_DUPLICATE_REFERENCE_CHECK = 'ENGINE_CREDITORS_JOURNAL_DUPLICATE_REFERENCE_CHECK'

export const ENGINE_CUSTOMER_BILLING_INVOICE = 'ENGINE_CUSTOMER_BILLING_INVOICE'
export const ENGINE_CUSTOMER_TRANSACTION_LIST = 'ENGINE_CUSTOMER_TRANSACTION_LIST'
export const ENGINE_BROADCAST_MESSAGE = 'ENGINE_BROADCAST_MESSAGE'
export const ENGINE_TERMINATION_INVOICE_PDF = 'ENGINE_TERMINATION_INVOICE_PDF'
export const ENGINE_TRIAL_BALANCE_PDF = 'ENGINE_TRIAL_BALANCE_PDF'
export const ENGINE_PREVIEW_INVOICE = 'ENGINE_PREVIEW_INVOICE'
export const ENGINE_BILLING_RERUN = 'ENGINE_BILLING_RERUN'
export const ENGINE_CIS_EXCEL = 'ENGINE_CIS_EXCEL'
export const ENGINE_TRIAL_BALANCE_REFRESH = 'ENGINE_TRIAL_BALANCE_REFRESH'

export const ENGINE_DEFLEET_NUMBER = 'ENGINE_DEFLEET_NUMBER'
export const ENGINE_DEFLEET_CHECKSHEET = 'ENGINE_DEFLEET_CHECKSHEET'
export const ENGINE_DEFLEET_SIGNOFF = 'ENGINE_DEFLEET_SIGNOFF'
export const ENGINE_KPO_NUMBER = 'ENGINE_KPO_NUMBER'
export const ENGINE_ESCALATION_MESSAGE = 'ENGINE_ESCALATION_MESSAGE'
export const ENGINE_REBILLS = 'ENGINE_REBILLS'
export const ENGINE_PAYMENT_DOCUMENT = 'ENGINE_PAYMENT_DOCUMENT'
export const ENGINE_PAYMENT_NOTIFICATION = 'ENGINE_PAYMENT_NOTIFICATION'

export const ENGINE_BATCH_NUMBER = 'ENGINE_BATCH_NUMBER'
export const ENGINE_SUPPLIER_TRANSACTION_DOCUMENT = 'ENGINE_SUPPLIER_TRANSACTION_DOCUMENT'
export const ENGINE_PAYMENT_DOCUMENT_BY_ID = 'ENGINE_PAYMENT_DOCUMENT_BY_ID'
export const ENGINE_DOWNLAOD_DISPOSAL_COMPONENT_DOCUMENT = 'ENGINE_DOWNLAOD_DISPOSAL_COMPONENT_DOCUMENT'
export const ENGINE_DOWNLOAD_PDF_DOCUMENT_FROM_TABLE = 'ENGINE_DOWNLOAD_PDF_DOCUMENT_FROM_TABLE'

export const ENGINE_TZ_DATE = 'ENGINE_TZ_DATE'
export const ENGINE_SUBMITTED_PAYMENT = 'ENGINE_SUBMITTED_PAYMENT'
export const ENGINE_AUTHORISED_PAYMENT = 'ENGINE_AUTHORISED_PAYMENT'
export const ENGINE_ALLOCATED_PAYMENT = 'ENGINE_ALLOCATED_PAYMENT'
export const ENGINE_DISPOSAL_CHEEKSHEET_DOCUMENT = 'ENGINE_DISPOSAL_CHEEKSHEET_DOCUMENT'
export const ENGINE_DISPOSAL_INVOICE_DOCUMENT = 'ENGINE_DISPOSAL_INVOICE_DOCUMENT'
export const ENGINE_COMPONENT_FINE_NOMINATION_FORM = 'ENGINE_COMPONENT_FINE_NOMINATION_FORM'
export const ENGINE_CARD_COMPONENT_REPORT_DATA_EXCEL = 'ENGINE_CARD_COMPONENT_REPORT_DATA_EXCEL'
export const ENGINE_CIS_DOWNLOAD_DATA = 'ENGINE_CIS_DOWNLOAD_DATA'
export const ENGINE_CIS_SEND_EMAIL = 'ENGINE_CIS_SEND_EMAIL'
export const ENGINE_GET_CIS_HISTORY_FILE = 'ENGINE_GET_CIS_HISTORY_FILE'
export const ENGINE_REFRESH_BILLING = 'ENGINE_REFRESH_BILLING'
export const ENGINE_GENRATE_PREVIEW_BILLING_INVOICE = 'ENGINE_GENRATE_PREVIEW_BILLING_INVOICE'
export const ENGINE_EXPORT_BILLING_IETMS_EXCEL_DOCUMENT = 'ENGINE_EXPORT_BILLING_IETMS_EXCEL_DOCUMENT'
export const ENGINE_EXPORT_BILLING_INVOICE_EXCEL_DOCUMENT = 'ENGINE_EXPORT_BILLING_INVOICE_EXCEL_DOCUMENT'
export const ENGINE_GENERATE_BILLING_INVOICE_FOR_PREVIEW = 'ENGINE_GENERATE_BILLING_INVOICE_FOR_PREVIEW'
export const ENGINE_GENERATE_BILLING_INVOICE_FOR_REVIEW = 'ENGINE_GENERATE_BILLING_INVOICE_FOR_REVIEW'
export const ENGINE_SENT_CUSTOMER_INVOICE_NOTIFICATION = 'ENGINE_SENT_CUSTOMER_INVOICE_NOTIFICATION'
export const ENGINE_SEND_CUSTOMER_BILLING_INVOICE = 'ENGINE_SEND_CUSTOMER_BILLING_INVOICE'
export const ENGINE_EXPORT_ENTEREST_EXCEL_DOCUMENT = 'ENGINE_EXPORT_ENTEREST_EXCEL_DOCUMENT'
export const ENGINE_EXPORT_STATEMENT_EXCEL_DOCUMENT = 'ENGINE_EXPORT_STATEMENT_EXCEL_DOCUMENT'
export const ENGINE_GENERATE_STATEMENT_FOR_REVIEW = 'ENGINE_GENERATE_STATEMENT_FOR_REVIEW'
export const ENGINE_QUEUE_STATEMENT_SEND_OUT = 'ENGINE_QUEUE_STATEMENT_SEND_OUT'
export const ENGINE_XERO_TRANSACTIONS = 'ENGINE_XERO_TRANSACTIONS'

export const CLIENT_DISCONNECT_FROM_MESSENGER = 'CLIENT_DISCONNECT_FROM_MESSENGER'

export const GENERATE_CREDITORS_PAYMENT_DOCUMENTS_DONE = 'GENERATE_CREDITORS_PAYMENT_DOCUMENTS_DONE'
export const GENERATE_CREDITORS_PAYMENT_DOCUMENTS_ERROR = 'GENERATE_CREDITORS_PAYMENT_DOCUMENTS_ERROR'
export const GET_JSREPORT_PREVIEW_DOCUMENT_DONE = 'GET_JSREPORT_PREVIEW_DOCUMENT_DONE'
export const GET_JSREPORT_PREVIEW_DOCUMENT_DONE_ERROR = 'GET_JSREPORT_PREVIEW_DOCUMENT_DONE_ERROR'
export const ENGINE_BANKRECON_PDF_REPORT_DONE = 'ENGINE_BANKRECON_PDF_REPORT_DONE'
export const ENGINE_CASHBOOK_PDF_REPORT_DONE = 'ENGINE_CASHBOOK_PDF_REPORT_DONE'
export const ENGINE_ONBASE_JSREPORT = 'ENGINE_ONBASE_JSREPORT'