import React, { Component } from 'react'

import * as config from '../../config'
import * as styles from './styles'


export default class Logo extends Component {
    render() {
        const component = {
            styles: styles
        }


        return (
            <div style={component.styles.container}>
            <img style={component.styles.image} src={config.system.clientTheme !== 'bidvest' ? `${config.system.cdn}/${config.uri.logo}` :
             require(`../../assets/img/${config.uri.logo}`)} alt='Fleet Management' />
            </div>
        )
    }
}