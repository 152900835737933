import * as types from './types'

export const connectToMessenger = () => {
    return (dispatch, getState) => {
        if (getState().app.signedIn) {
            dispatch({
                type: types.CONNECT_USER_TO_MESSENGER
            })
        }
    }
}
