import * as finance from 'financejs'
import * as formulas from 'formulajs'
import moment from 'moment'
import get from 'lodash/get'

import * as types from './types'


export const getLookupValue = (model, field, value) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]

        const param = field.parameters
            ? field.parameters.indexOf('.') > -1
                ? '?' + field.parameters.split('.')[1] + '=' + getState().components[field.parameters.split('.')[0]].data[field.parameters.split('.')[1]].input
                : '?' + field.parameters + '=' + component.data[field.parameters].input
            : ''

        const payload = {
            component: component,
            model: model,
            field: field,
            related: '?suppliername='+value,
            query: param,
        }

        dispatch({
            type: types.GET_PO_SUPPLIER_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}


export const setLookupValue = (field, input, text, inx) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }


    return (dispatch, getState) => {
        const value = format(field, input)

        dispatch({
            type: types.SET_PO_SUPPLIER_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })

        dispatch({
            type: types.SET_PO_SUPPLIER_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name,
                value: text,
            }
        })

        dispatch({
            type: types.GET_PO_SUPPLIER_SELECTED_DETAILS,
            payload: {
                component: field.component.name,
                model: 'supplier',
                query: '?supplier_id='+input,
                inx: inx,
            }
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_SUPPLIER_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component,
                field: field.name,
                data: data
            }
        })
    }
}


export const setFieldLookupData = (component, field, payload) => {

    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_SUPPLIER_COMPONENT_GRID_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data
            }
        })
    }
}

export const setSupplierDetails = (component, inx, payload) => {
    return (dispatch, getState) => {
        
        const data = getState().components[component].grid.data

        data.splice(inx, 1, payload.data[0])

        
        dispatch({
            type: types.SET_PO_SUPPLIERS_SELECTED_COMPONENT_GRID_DATA,
            payload: {
                component: component,
                data: data
            }
        })
    }
}


