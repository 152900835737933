import React, { Component, useEffect  } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import MasterComponentView from '../../views/MasterComponentView/component'
import GeneralContentView from '../../views/GeneralContentView/component'

import { useParams  } from "react-router-dom";

import Grid from '../Grid/component'
import Loading from '../Loading/component'
import Tiles from '../Tiles/component'

import * as actions from './actions'
import * as componentActions from '../Components/actions'

//class SearchComponent extends Component {
    
const SearchComponent = (props) => {   
    const _params = useParams(); 
    
    useEffect(() => {
        //console.log('loadingx useEffect', _params.component)
        props.actions.getComponent(_params.component)
    },[]);

    const getView = () => {
        const view = props.state.components[_params.component].component_display_type
            ? props.state.components[_params.component].component_display_type.name
            : undefined

        switch (view) {
            case 'tiles':
                return <Tiles
                    key={'search_component_tiles_' + props.state.components[_params.component].name}
                    name={props.state.components[_params.component].name} commands={true}
                    toggle
                />
            default:
                return <Grid
                    key={'search_component_grid_' + props.state.components[_params.component].name}
                    name={props.state.components[_params.component].name} commands={true}
                    toggle
                />
        }
    }

    //render() {
        //console.log('loadingx getting ready', _params.component)
        return props.state.components[_params.component].loading
            ? <GeneralContentView
                title={window.t.en('Please wait!')}
                id={_params.component}
                key={_params.component}
                component={_params.component}
                headerImage={''}
                content={
                    <Loading message={window.t.en('We are getting things ready...')} />
                }
            />
            : <MasterComponentView
                key={'master_component_view_' + props.state.components[_params.component].name}
                component={props.state.components[_params.component]}
                data={getView()}
            />
   // }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.searchComponent,
            components: {
                ...state.components
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...componentActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent)