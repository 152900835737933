import React, { Component } from 'react'

import FontIcon from '@mui/material/Icon' 
import SvgIcon from '../SvgIcon/component'

class Icon extends Component {
    getSvgStyleEquivalent(style) {
        return style
            ? style.fontSize
                ? {
                    ...style,
                    width: style.fontSize,
                    height: style.fontSize,
                    marginTop: style.marginTop ? style.marginTop : 'auto'
                }
                : {}
            : {
                ...style,
                width: '16px',
                height: '16px'
            }
    }

    render() {
        return this.props.iclass === 'material-icons'
            ? (
                <FontIcon
                    style={{ ...this.props.style, ...this.props.istyle }}
                    className={this.props.iclass}
                    color={this.props.icolor ? this.props.icolor : ''}
                    onClick={this.props.onClick ? this.props.onClick : undefined}
                >
                    {this.props.iname}
                </FontIcon>
            )
            : (
                <SvgIcon
                    style={{ ...this.props.style, ...this.getSvgStyleEquivalent(this.props.istyle) }}
                    name={this.props.iname}
                    onClick={this.props.onClick ? this.props.onClick : undefined}
                />
            )
    }
}

export default Icon