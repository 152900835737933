import * as types from './types'
import * as appTypes from '../App/types'

import * as componentActions from '../Components/actions'
import * as formActions from '../Form/actions'

import moment from 'moment'
import _ from 'lodash'


export const getTreeViewData = (field,datasource,value) => {
    return (dispatch) => {
        let data = {field: field,datasource: datasource,value:value,query: datasource + "/other", type: 'hierachy'}
        dispatch({
            type: types.GET_BUDGET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: data
        })
    }
}

export const setTreeViewData = (response, payload) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_BUDGET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: {
                prop: payload.field+"_data",
                data: response.hierachy
            }
        })
    }
}

export const setComponentFieldInputAndText = (field, input, text) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = format(field, input)
        dispatch({
            type: types.SET_BUDGET_COMPONENT_FIELD_INPUT_AND_TEXT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                text: text,
                validation: validate(component.schema, field, value)
            }
        })
    }
}

const validate = (schema, field, input) => {
    let result = []
    if(!input && field.required){
        return {
            valid: false,
            field: field.title,
            message: field.title + ' is required',
        }
    }
    return {
        valid: true,
        field: field.title,
        message: '',
    }

    // if (!field.required) {
    //     return {
    //         valid: true,
    //         errors: []
    //     }
    // }else {
    //     if(!input && field.required)
    // }

    // // const adhocSchema = {
    // //     [field.name]: schema[field.name]
    // // }

    // // let adhocData = {}

    // // if (adhocSchema[field.name].filter(x => x.name === 'boolean').length) {
    // //     adhocData = {
    // //         [field.name]: input ? 'true' : 'false'
    // //     }
    // // }
    // // else {
    // //     adhocData = {
    // //         [field.name]: input ? input.toString() : ''
    // //     }
    // // }

    // // const result = validator().validate(adhocSchema, adhocData)

    // return {
    //     ...result,
    //     message: result.errors.map(x => { return x.message }).join(', ')
    // }
}

export const setComponentFieldInput = (field, input) => {
    //console.log('setComponentFieldInput',field, input)
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = input ? format(field, input) : ''

        dispatch({
            type: types.SET_BUDGET_COMPONENT_FIELD_INPUT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                validation: true // validate(component.schema, field, value)
            }
        })
    }
}

export const loadBudgetData = () => {
    return (dispatch) => {
        dispatch(componentActions.hardReloadComponent('budgetmanagement'))
    }
}

export const getBudgetManagementData = (id) => {
    return (dispatch) => {
        dispatch(formActions.getComponentData('budgetmanagement', id, false))
    }
}

export const toggleDeleteConfirm = (id) => {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_DELETED_WIN_STATUS,
            payload: id
        })
    }
}

export const deleteBuget = (id) => {
    return (dispatch) => {
        dispatch({
            type: types.DELETE_COMPONENT_BUDGET_RECORD,
            payload: {budgetmanagement_id: id}
        })
    }
}

export const deleteBugetDone = (res) => {
    return (dispatch, getState) => {
        dispatch(getBudgetManagementData(0))
        dispatch(changeTabView(0))
        dispatch(loadBudgetData())
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Record successfully deleted',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const changeTabView = (tab) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_BUDGET_ACCOUNT_CURRENT_TAB,
            payload: tab
        })
    }
}
export const setLicenseeAccount = (payload) => {
    //console.log('setLicenseeAccount',payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BUDGET_ACCOUNT_DATA,
            payload: payload
        })
    }
}

export const saveBudgetManagementDone = (payload) => {
    //console.log('saveSubAccountDone',payload)
    return (dispatch, getState) => {
        dispatch(componentActions.hardReloadComponent('budgetmanagement'))
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Budget successfully saved',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(changeTabView(0))
    }
}

export const saveBudgetManagement = (component, user) => {
    //console.log('component',component)
    const getData = () => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name]) {
                if (x.key) {
                    if (component.data[x.name].input !== '') {
                        obj[x.name] = component.data[x.name].input
                    }
                }
                else {
                    obj[x.name] = component.data[x.name].input !== '' ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }

    return (dispatch, getState) => {
        let data = getData()        
        //console.log('SAVE_SUB_ACCOUNT_COMPONENT_CUSTOMER_DATA data',data)
        dispatch({
            type: types.SAVE_BUDGET_COMPONENT_CUSTOMER_DATA,
            payload: {...data, client_id: 2, user_id: user.user_id, created_at: moment().format('YYYY-MM-DD HH:mm:ss')}
            
            // {
            //     sub_account: {...data, client_id: 2 }, 
            //     user: user,
            //     type: 'crud_subaccount'
            // }this.props.state.budgetmanagement.data.budgetlevel_id.input
        })
    }
}

export const getComponentFieldLookupData = (field, searchText, dataSource, params) => {
    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const payload = {
            component: component,
            field: field,
            related: '',
            query: searchText.length > 0 ? '?' + field.search + '=' + searchText : '',
            text: searchText
        }

        dispatch({
            type: types.GET_BUDGET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return _.get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch) => {
        dispatch({
            type: types.SET_BUDGET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component.name,
                field: field.name,
                data: data
            }
        })
    }
}