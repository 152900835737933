export const GET_COMPONENT_SCHEMA = 'GET_COMPONENT_SCHEMA'
export const SET_COMPONENT_SCHEMA = 'SET_COMPONENT_SCHEMA'
export const GET_COMPONENT_RELATIONSHIPS = 'GET_COMPONENT_RELATIONSHIPS'
export const SET_COMPONENT_RELATIONSHIPS = 'SET_COMPONENT_RELATIONSHIPS'
export const GET_COMPONENT_DATA = 'GET_COMPONENT_DATA'
export const SET_COMPONENT_DATA = 'SET_COMPONENT_DATA'
export const SET_COMPONENT_FIELD_INPUT = 'SET_COMPONENT_FIELD_INPUT'
export const SET_COMPONENT_FIELD_INPUT_AND_TEXT = 'SET_COMPONENT_FIELD_INPUT_AND_TEXT'
export const GET_COMPONENT_FIELD_LOOKUP_DATA = 'GET_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_COMPONENT_FIELD_LOOKUP_DATA = 'SET_COMPONENT_FIELD_LOOKUP_DATA'
export const VALIDATE_FORM = 'VALIDATE_FORM'
export const RESET_FORM = 'RESET_FORM'
export const SAVE_COMPONENT_DATA = 'SAVE_COMPONENT_DATA'
export const COMPONENT_DATA_SAVED = 'COMPONENT_DATA_SAVED'
export const COMPONENT_DATA_SAVE_FAILED = 'COMPONENT_DATA_SAVE_FAILED'
export const SET_FORM_VALIDATION_RESULT = 'SET_FORM_VALIDATION_RESULT'
export const FORM_LOAD_COMPONENT = 'FORM_LOAD_COMPONENT'
export const SET_COMPONENT_FINE_REDIRECT_WIN = 'SET_COMPONENT_FINE_REDIRECT_WIN'
export const SET_COMPONENT_FINE_REDIRECT_STATUS = 'SET_COMPONENT_FINE_REDIRECT_STATUS'
export const GEN_COMPONENT_FINE_NOMINATION_FORM = 'GEN_COMPONENT_FINE_NOMINATION_FORM'
export const SET_COMPONENT_TREE_SELECTION_DATA = 'SET_COMPONENT_TREE_SELECTION_DATA'
export const GET_COMPONENT_TREE_SELECTION_DATA = 'GET_COMPONENT_TREE_SELECTION_DATA'
export const GET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA = 'GET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA'
export const SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA = 'SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA'
export const SET_COMPONENT_FIELD_INPUT_VALIDATION = 'SET_COMPONENT_FIELD_INPUT_VALIDATION'
export const LINK_USER_TO_NEW_CUSTOMER = 'LINK_USER_TO_NEW_CUSTOMER'