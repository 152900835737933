import * as types from './types'
import moment from 'moment'
import validator from '../../validate'

import * as appTypes from '../App/types'
import { itemlist } from './props';

export const selectRow = (component, row) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SELECT_ROW,
            payload: {
                component: component,
                rows: [row]
            }
        })
    }
}

export const getQuoteTypesComponents = (component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.LOAD_QUOTE_ITEM_TYPES,
            payload: {
                component: component.name,
                related: 'component',
            }
        })
    }
}

export const getQuoteComponentItems = (component, value) => {

    let model = ''
    let query = ''
    switch (component.data.component_id.text) {
        case 'Accessories':
        model = 'accessory'
        query = '?accessoryname=' + value
            break;
        case 'mmcode':
        query = '?makemodelyear=' + value
            break;
        case 'Sundry':
        model = 'sundry'
        query = '?description=' + value
            break;
        case 'mmcodeoption':
        query = '?mmcodeoptionname=' + value
            break;
        case 'Service':
        model = 'service'
        query = '?servicedescription=' + value
            break;
    }
    
    return (dispatch, getState) => {
        dispatch({
            type: types.LOAD_QUOTE_TYPE_ITEMS_LIST,
            payload: {
                component: model,
                query: query //(component.name === 'mmcodeoption') ? '?mmcode_id=' + getState().components['mmcode'].data.mmcode_id.input : ''
            }
        })
    }
}


export const setQuoteTypesComponents = (component, value) => {
    return (dispatch, getState) => {
        const row = getState().components['surrogateitems'].data.component_id.data.filter(x => x.value === value)
        dispatch({
            type: types.SET_SELECTED_QUOTE_ITEM_TYPES_INPUT,
            payload: {
                component: 'surrogateitems',
                field: 'component_id',
                value: value
            }
        })
        dispatch({
            type: types.SET_SELECTED_QUOTE_ITEM_TYPES_TEXT,
            payload: {
                component: 'surrogateitems',
                field: 'component_id',
                value: row[0].title
            }
        })

        const item = component.data.item_id.data.filter(x => x.value === value)
        let data = component.grid.data
        const inx = data.length - 1
        if(item.length){
            data[inx].component_id = value
            data[inx].itemtype = row[0].title
        }

        dispatch({
            type: types.UPDATE_QUOTE_GRID_ITEMS,
            payload: {
                data: data,
                component: component.name
            }
        })
    }
}

export const setQuoteComponentItems = (component, value, text) => {
    return (dispatch, getState) => {
        const types = component.data.component_id.data.filter(x => x.value === component.data.component_id.input)
        const item = component.data.item_id.data.filter(x => x.value === value)

        let data = component.grid.data
        const inx = data.length - 1

        if(item.length){
        data[inx].totalprice = parseFloat(item[0].selling).toFixed(2)
        data[inx].costprice = parseFloat(item[0].cost).toFixed(2)
        data[inx].sellingprice = parseFloat(item[0].selling).toFixed(2)
        data[inx].quantity = 1
        data[inx].item_id = value //component.data.item_id.input
        data[inx].component_id = component.data.component_id.input
        data[inx].itemname = item[0].text
        data[inx].itemtype = component.data.component_id.text
        }
        
        dispatch({
            type: types.SET_SELECTED_QUOTE_ITEM_TYPES_INPUT,
            payload: {
                component: component.name,
                field: 'item_id',
                value: value
            }
        })
        dispatch({
            type: types.SET_SELECTED_QUOTE_ITEM_TYPES_TEXT,
            payload: {
                component: component.name,
                field: 'item_id',
                value: text
            }
        })

        dispatch({
            type: types.UPDATE_QUOTE_GRID_ITEMS,
            payload: {
                data: data,
                component: component.name
            }
        })
    }
}

export const loadQuoteGridItems = (component) => {
    return (dispatch, getState) => {
        const item = component.data.item_id.data.filter(x => x.value === component.data.item_id.input)
        const types = component.data.component_id.data.filter(x => x.value === component.data.component_id.input)


        let data = component.grid.data
        const inx = data.length - 1

        data[inx].totalprice = parseFloat(item[0].selling).toFixed(2)
        data[inx].costprice = parseFloat(item[0].cost).toFixed(2)
        data[inx].sellingprice = parseFloat(item[0].selling).toFixed(2)
        data[inx].quantity = 1
        data[inx].item_id = component.data.item_id.input
        data[inx].component_id = component.data.component_id.input
        data[inx].itemname = item[0].text
        data[inx].itemtype = getState().components[component.data.component_id.text].title

        dispatch({
            type: types.UPDATE_QUOTE_GRID_ITEMS,
            payload: {
                data: data,
                component: component.name
            }
        })
    }
}

export const prepQuoteTypesComponents = (payload) => {
    const data = payload.data.map(x => {
        return {
            value: x.component.component_id,
            text: x.component.name,
            title: x.component.title,
        }
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_DATA,
            payload: {
                component: 'surrogateitems',
                field: 'component_id',
                data: data
            }
        })
    }
}

export const prepQuoteComponentItems = (payload, component) => {

    let i_prop = ''
    let v_prop = ''
    let s_prop = ''
    let c_prop = ''
    switch (component) {
        case 'accessory':
            i_prop = 'accessory_id'
            v_prop = 'accessoryname'
            c_prop = 'costprice'
            s_prop = 'retailprice'
            break;
        case 'mmcode':
            i_prop = 'mmcode_id'
            v_prop = 'makemodelyear'
            c_prop = 'amount'
            s_prop = 'amount'
            break;
        case 'sundry':
            i_prop = 'sundry_id'
            v_prop = 'description'
            c_prop = 'costprice'
            s_prop = 'costprice'
            break;
        case 'mmcodeoption':
            i_prop = 'mmcodeoption_id'
            v_prop = 'mmcodeoptionname'
            c_prop = 'costprice'
            s_prop = 'sellingprice'
            break;
        case 'service':
            i_prop = 'service_id'
            v_prop = 'servicedescription'
            c_prop = 'costprice'
            s_prop = 'sellingprice'
            break;
    }

    const data = payload.data.map(x => {
        return {
            value: x[i_prop],
            text: x[v_prop],
            cost: x[c_prop],
            selling: x[s_prop],
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_DATA,
            payload: {
                component: 'surrogateitems',
                field: 'item_id',
                data: data
            }
        })
    }
}


export const selectMultipleRows = (component, rows) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SELECT_MULTIPLE_ROWS,
            payload: {
                component: component,
                rows: rows
            }
        })
    }
}

export const setComponentGridPage = (component, page) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_GRID_PAGE,
            payload: component,
            page: page
        })
    }
}


export const validateAddBlankRow = (component) => {
    let data = component.grid.data

    const invalid = data.map(x => {
        return {
            valid: (x.component_id && x.item_id && x.quantity)
        }
    }).filter(x => !x.valid)

    return (invalid && data.length)
}

export const addBlankRow = (component) => {
    let data = component.grid.data
    const payload = {
        component: component.name,
        data: data.concat({
            component_id: null,
            item_id: null,
            itemtype: null,
            itemname: null,
            quantity: null,
            quotetype_id: null,
            surrogateitem_id: data.length,
            totalprice: null,
            costprice: null,
            sellingprice: null,
        }),
    }

    return (dispatch, getState) => {
        dispatch({
            type: types.QUOTE_GRID_ADD_BLANK_ROW,
            payload: payload
        })

        dispatch({
            type: types.LOAD_QUOTE_ITEM_TYPES,
            payload: {
                component: getState().components['quotetypecomponent'].name,
                related: 'component',
            }
        })
    }
}


export const addSundryRow = (component, x) => {
    let data = component.grid.data
    const payload = {
        component: component.name,
        data: data.concat({
            component_id: 161,
            item_id: x.item_id,
            itemtype: x.itemtype,
            itemname: x.itemname,
            quantity: 1,
            quotetype_id: 161,
            surrogateitem_id: data.length,
            totalprice: x.sellingprice,
            costprice: x.costprice,
            sellingprice: x.sellingprice,
        })
    }

    return (dispatch, getState) => {

        // dispatch({
        //     type: types.LOAD_QUOTE_ITEM_TYPES,
        //     payload: {
        //         component: component.name,
        //         related: 'component'
        //     }
        // })

        // dispatch({
        //     type: types.LOAD_QUOTE_TYPE_ITEMS_LIST,
        //     payload: {
        //         component: types[0].text,
        //         query: (component.name === 'mmcodeoption') ? '?mmcode_id=' + getState().components['mmcode'].data.mmcode_id.input : ''
        //     }
        // })

        // const item = component.data.item_id.data.filter(x => x.text === 'On The Road Fee')

        dispatch({
            type: types.QUOTE_GRID_ADD_BLANK_ROW,
            payload: payload
        })

        dispatch({
            type: types.LOAD_QUOTE_ITEM_TYPES,
            payload: {
                component: getState().components['quotetypecomponent'].name,
                related: 'component',
            }
        })
    }
}

export const addAccessoryBuzzerRow = (component) => {
    let data = component.grid.data
    const payload = {
        component: component.name,
        data: data.concat({
                component_id: 156,
                item_id: 10,
                itemtype: 'Service',
                itemname: 'Speed Buzzer',
                quantity: 1,
                quotetype_id: 156,
                surrogateitem_id: data.length + 1,
                totalprice: 16.05,
                costprice: 16.05,
                sellingprice: 16.05,
            })
    }
    return (dispatch, getState) => {
        dispatch({
            type: types.QUOTE_GRID_ADD_BLANK_ROW,
            payload: payload
        })

        dispatch({
            type: types.LOAD_QUOTE_ITEM_TYPES,
            payload: {
                component: getState().components['quotetypecomponent'].name,
                related: 'component',
            }
        })
    }
}

export const addAccessoryDecalsRow = (component) => {
    let data = component.grid.data
    const payload = {
        component: component.name,
        data: data.concat({
                component_id: 156,
                item_id: 10,
                itemtype: 'Accessories',
                itemname: 'DBN - Decals',
                quantity: 1,
                quotetype_id: 156,
                surrogateitem_id: data.length + 1,
                totalprice: 400.25,
                costprice: 400.25,
                sellingprice: 400.25,
            })
    }
    return (dispatch, getState) => {
        dispatch({
            type: types.QUOTE_GRID_ADD_BLANK_ROW,
            payload: payload
        })

        dispatch({
            type: types.LOAD_QUOTE_ITEM_TYPES,
            payload: {
                component: getState().components['quotetypecomponent'].name,
                related: 'component',
            }
        })
    }
}
export const addAccessoryTrackingRow = (component) => {
    let data = component.grid.data
    const payload = {
        component: component.name,
        data: data.concat({
            component_id: 156,
            item_id: 1,
            itemtype: 'Service',
            itemname: 'Tracking',
            quantity: 1,
            quotetype_id: 156,
            surrogateitem_id: data.length,
            totalprice: 374.71,
            costprice: 374.71,
            sellingprice: 374.71,
        })
    }
    return (dispatch, getState) => {
        dispatch({
            type: types.QUOTE_GRID_ADD_BLANK_ROW,
            payload: payload
        })

        dispatch({
            type: types.LOAD_QUOTE_ITEM_TYPES,
            payload: {
                component: getState().components['quotetypecomponent'].name,
                related: 'component',
            }
        })
    }
}

export const addAccessoryLimiterRow = (component) => {
    let data = component.grid.data
    const payload = {
        component: component.name,
        data: data.concat({
                component_id: 156,
                item_id: 10,
                itemtype: 'Service',
                itemname: 'Speed Limiter',
                quantity: 1,
                quotetype_id: 156,
                surrogateitem_id: data.length + 1,
                totalprice: 128.40,
                costprice: 128.40,
                sellingprice: 128.40,
            })
    }
    return (dispatch, getState) => {
        dispatch({
            type: types.QUOTE_GRID_ADD_BLANK_ROW,
            payload: payload
        })

        dispatch({
            type: types.LOAD_QUOTE_ITEM_TYPES,
            payload: {
                component: getState().components['quotetypecomponent'].name,
                related: 'component',
            }
        })
    }
}

export const addOnRoadFee = (component) => {
    return (dispatch, getState) => {
        this.addBlankRow(component)
        this.setQuoteComponentItems(component, '161', 'Sundry')
        this.setQuoteTypesComponents(component, '5', 'On The Road Fee')
    }

}

export const getLoadGridData = (name, filter, paging, id) => {
    return (dispatch, getState) => {
        const component = getState().components[name]

        const key = getState().components[filter].component_field.filter(x => x.key)[0]
        let query = key.id + '=' + key.value

        dispatch({
            type: types.GET_COMPONENT_GRID_DATA,
            payload: {
                id: id,
                component: component,
                input: '',
                paging: paging,
                query: query,
                related: '',
                child: {}
            }
        })
    }
}

export const getComponentGridData = (name, link, paging, filter, id) => {
    return (dispatch, getState) => {
        const component = getState().components[name]

        const getRelated = () => {
            if (link) {
                const parent = getState().components[link.parent]
                const through = getState().components[link.through]

                const query = '?' + parent.component_field.filter(x => x.key)[0].name + '=' + link.id

                return {
                    component: through,
                    child: component,
                    query: query
                }
            }

            return undefined
        }

        const getQuery = () => {
            let query = paging.filtered.reduce((query, x) => {
                query.push(x.id.concat('=', x.value))
                return query
            }, [])

            return '?' + query.join('&')
        }

        const withRelatedId = (query) => {

            if (!id || !filter) {
                return query
            }

            const key = getState().components[filter].component_field.filter(x => x.key)[0]

            if (!key) {
                return query
            }

            return query.indexOf('?' > -1) && query.length > 1
                ? query + '&' + key.name + '=' + id
                : '?' + key.name + '=' + id
        }


        const query = withRelatedId(getQuery())
        const related = getRelated()


        dispatch({
            type: types.GET_COMPONENT_GRID_DATA,
            payload: {
                id: id,
                component: !related ? component : related.component,
                input: '',
                paging: paging,
                sorting: paging.sorted.length > 0
                    ? !paging.sorted[0].desc
                        ? paging.sorted[0].id
                        : '-' + paging.sorted[0].id
                    : '',
                query: !related ? query : related.query,
                related: component.related !== null ? component.related : '',
                child: !related ? {} : related.child
            }
        })
    }
}

export const setComponentGridData = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_GRID_DATA,
            payload: {
                component: payload.child.name ? payload.child : payload.component,
                data: payload.child.name
                    ? {
                        ...payload.data,
                        data: payload.data.data.map(x => {
                            return x[payload.child.name]
                        })
                    }
                    : payload.data
            }
        })
    }
}


export const loadQuoteGridRowData = (component) => {
    return (dispatch, getState) => {
        const data = getState().workflowQuoteItemGrid;
        const itemlist = data.itemlist.filter(x => x.value === data.item_id)
        const itemtype = data.itemtype.filter(x => x.value === data.component_id)
        let payload = component.grid.data
        const inx = payload.length - 1 //component.selected[0].index


        payload[inx].totalprice = parseFloat(itemlist[0].selling).toFixed(2)
        data[inx].costprice = parseFloat(itemlist[0].cost).toFixed(2)
        data[inx].sellingprice = parseFloat(itemlist[0].selling).toFixed(2)
        payload[inx].quantity = 1
        payload[inx].item_id = data.item_id
        payload[inx].component_id = data.component_id
        payload[inx].itemname = itemlist[0].text
        payload[inx].itemtype = itemtype[0].text

        dispatch({
            type: types.UPDATE_QUOTE_GRID_ITEMS,
            payload: payload
        })

        // dispatch({
        //     type: types.SELECT_ROW,
        //     payload: {
        //         component: component,
        //         rows: [row]
        //     }
        // })
    }
}


export const removeSingleRow = (component, row) => {
    return (dispatch, getState) => {
        const payload = component.grid.data.filter((x, i) => i !== row.index)

        const data = {
            component: component.name,
            data: payload
        }
        dispatch({
            type: types.REMOVE_QUOTE_GRID_ITEM,
            payload: data
        })
    }
}


export const setFieldValue = (field, value, row) => {
    return (dispatch, getState) => {
       
        dispatch({
            type: types.SET_QUOTE_GRID_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
                index: row.index
            }
        })

        const data = getState().components[field.component.name].grid.data
        const quantity = data[row.index].quantity
        const costprice = data[row.index].sellingprice

        dispatch({
            type: types.SET_QUOTE_GRID_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'totalprice',
                value: (parseInt(quantity ? quantity : 1) * parseFloat(costprice ? costprice : 0)).toFixed(2),
                index: row.index
            }
        })

    }
}
