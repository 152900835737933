import * as types from './types'
import * as _ from 'lodash'

export const getUserTeams = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_TEAM_HIERARCHY,
            payload: {
                type: 'user_team',
                user_id: getState().user.user_id
            }
        })
    }
}

export const setUserTeams = (payload) => {
    return (dispatch, getState) => {
        //console.log('setUserTeams', payload)
        dispatch({
            type: types.SET_TEAM_HIERARCHY,
            payload: payload
        })
    }
}

export const toggleCollapse = (team_id) => {
    return (dispatch, getState) => {
        let data = getState().hierarchyteam.tree
        let _data = data.map(x => {
            return x.team_id === team_id ? {...x, collapse: !x.collapse} : x
        })
        //console.log('toggleCollapse customer_id',team_id)
        //console.log('toggleCollapse _data',_data)
        dispatch({
            type: types.SET_TEAM_HIERARCHY,
            payload: _data
        })
    }
}

export const contextMenu = (customer_id, anchorEl) => {
    return (dispatch, getState) => {
        let data = getState().hierarchyteam.tree
        let _data = data.map(x => {
            return x.customer_id === customer_id ? {...x, contextmenu: !x.contextmenu} : {...x, contextmenu: false}
        })
        //console.log('toggleCollapse customer_id',customer_id)
        //console.log('toggleCollapse _data',_data)
        dispatch({
            type: types.SET_TEAM_HIERARCHY,
            payload: _data
        })
        dispatch({
            type: types.SET_CONTEXT_MENU_ANCHOREL,
            payload: anchorEl
        })
    }
}
export const toggleDetailWin = (customer_id) => {
    return (dispatch, getState) => {
        let data = getState().hierarchy.tree
        let _data = data.map(x => {
            return x.customer_id === customer_id ? {...x, detail_win: !x.detail_win} : {...x, detail_win: false}
        })
        //console.log('toggleCollapse customer_id',customer_id)
        //console.log('toggleCollapse _data',_data)
        dispatch({
            type: types.SET_TEAM_HIERARCHY,
            payload: _data
        })
    }
}
