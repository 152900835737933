import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment';

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, IconButton, Checkbox, Chip } from '@mui/material';
import { Container, Row, Col } from 'react-grid-system'

import Icon from '../Icon/component'
import MultiSelect from '../../controls/multiselect'
import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import TextField from '../../controls/textField'
import Card from '../../controls/card'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Table from '@mui/material/Table';

import * as actions from './actions'
import * as styles from './styles'


class EventForm extends Component {

    componentDidMount() {
        this.props.actions.setInputValue({prop: 'start_date', value: moment().format('YYYY-MM-DD')})
        this.props.actions.setInputValue({prop: 'end_date', value: moment().add(3, 'months').format('YYYY-MM-DD')})
    }

    getEvent() {
        return  <div className="text-center">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>
                            <br/>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select Event Category"}:</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        name={'event_category'}
                                        title={'Select category'}
                                        data={this.props.state.category_list}
                                        value={this.props.state.category}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        onSelect={(args) => this.props.actions.setInputValue({prop: 'category', value: args.text})}
                                        onClick={() => this.props.actions.getCategory('')}
                                        onFilter={(args) => this.props.actions.getCategory(args)} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select Component"}:</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        name={'event_component'}
                                        title={'Select component'}
                                        data={this.props.state.component_list}
                                        value={this.props.state.component}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        onSelect={(args) => {
                                            this.props.actions.setInputValue({prop: 'component', value: args.text})
                                            this.props.actions.setInputValue({prop: 'component_id', value: args.value})
                                        }}
                                        onClick={() => this.props.actions.getComponent('')}
                                        onFilter={(args) => this.props.actions.getComponent(args)} />
                                </div>
                            </div>
                        </Col>
                    </Row>    
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Event Name"}:</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth={true}
                                        id={'txt_monthly_inclusive'}
                                        hintText={'Enter Monthly Inclusive'}
                                        value={this.props.state.event_name}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({prop: 'event_name', value: event.target.value})
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>    
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Event Start Date"}:</div>
                                <div style={styles.fieldContent}>
                                    <DateControl
                                        id={'event_start_date'}
                                        key={'event_start_date'}
                                        name={'event_start_date'}
                                        fullWidth={true}
                                        value={this.props.state.start_date}
                                        onSelect={(args) => {
                                            this.props.actions.setInputValue({prop: 'start_date', value: args})
                                        }}
                                        type={'date'}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Event End Date"}:</div>
                                <div style={styles.fieldContent}>
                                    <DateControl
                                        id={'event_end_date'}
                                        key={'event_end_date'}
                                        name={'event_end_date'}
                                        fullWidth={true}
                                        value={this.props.state.end_date}
                                        onSelect={(args) => {
                                            this.props.actions.setInputValue({prop: 'end_date', value: args})
                                        }}
                                        type={'date'}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    getAction() {
        return  <div className="text-center">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select Action"}:</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        name={'event_action'}
                                        title={'Select Action'}
                                        data={this.props.state.actions_list}
                                        value={this.props.state.action}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        onSelect={(args) => {
                                            this.props.actions.setInputValue({prop: 'action', value: args.text})
                                            this.props.actions.setInputValue({prop: 'action_id', value: args.value})
                                            this.props.actions.addActions({prop: 'actions', value: this.props.state.actions_list.filter(x => x.workqueue_actions_id === args.value)})
                                    
                                        }}
                                        onClick={() => this.props.actions.getActions('')}
                                        onFilter={(args) => this.props.actions.getActions(args)} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            {
                                this.props.state.actions.length
                                ? <Table className="table" >
                                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                            <th className="text-left px-4" style={{width: "50px !important"}}></th>
                                            <th className="text-left">Select Actions</th>
                                            <th className="text-left"></th>
                                        </thead>
                                        <tbody style={{zIndex:1}}>
                                            {
                                                this.props.state.actions.map(x => {
                                                    return <tr>
                                                            <td className="text-left">
                                                                <IconButton key={'command_remove_actions_' + x.workqueue_actions_id} style={styles.iconButton} iconStyle={styles.action}
                                                                    onClick={() => {
                                                                            //console.log('x',x)
                                                                            this.props.actions.rmUserActions({prop: 'actions', value: x.workqueue_actions_id})
                                                                        }}>
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </td>
                                                            <td className="text-left">{x.workqueue_actions}</td>
                                                            <td className="text-left">
                                                                {
                                                                    x.workqueue_actions === 'Action By Workflow'
                                                                    && <AutoComplete
                                                                        name={'workflow_select_' + x.workqueue_actions_id}
                                                                        title={'Select escalation'}
                                                                        data={this.props.state.workflow_list}
                                                                        value={this.props.state.workflow_name}
                                                                        dataSourceConfig={{
                                                                            text: 'text',
                                                                            value: 'value'
                                                                        }}
                                                                        onSelect={(args) => {
                                                                            this.props.actions.setInputValue({prop: 'workflow_name', value: args.text})
                                                                            this.props.actions.setInputValue({prop: 'workflow_id', value: args.value})
                                                                            this.props.actions.setInputValue({prop: 'workflow', value: this.props.state.workflow_list.filter(x => x.workflow_id === args.value)})
                                                                        }}
                                                                        onClick={() => this.props.actions.getWorkflow('')}
                                                                        onFilter={(args) => this.props.actions.getWorkflow(args)} />
                                                                }
                                                            </td>
                                                        </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    : ''
                            }
                        
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    getEscalation() {
        return  <div className="text-center">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Assign Event To"}:</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        name={'event_schedule'}
                                        title={'Select Who To Assigned Event To'}
                                        data={this.props.state.escalation_list}
                                        value={this.props.state.escalation}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        onSelect={(args) => {
                                            this.props.actions.setInputValue({prop: 'selected_escalation', value: []})
                                            this.props.actions.setInputValue({prop: 'escalation', value: args.text})
                                            this.props.actions.setInputValue({prop: 'escalation_id', value: args.value})
                                        }}
                                        onClick={() => this.props.actions.getEscalation('')}
                                        onFilter={(args) => this.props.actions.getEscalation(args)} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            {
                            this.props.state.escalation === "Team" && <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select Team To Assigned Event To"}:</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                    name={'field_team'}
                                    title={'Select Team'}
                                    data={this.props.state.team_list}
                                    //value={this.props.state.team}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({prop: 'team', value: args.text})
                                        this.props.actions.addTeamEscalation({prop: 'selected_escalation', value: this.props.state.team_list.filter(x => x.team_id === args.value)})
                                    }}
                                    onClick={() => this.props.actions.getTeam('')}
                                    onFilter={(args) => this.props.actions.getTeam(args)} />
                                </div>
                            </div>
                            }
                            {
                            this.props.state.escalation === "User" && <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select User To Assigned Event To"}:</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                    name={'field_user'}
                                    title={'Select User'}
                                    data={this.props.state.user_list}
                                    //value={this.props.state.user}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({prop: 'user_name', value: args.text})
                                        this.props.actions.addUserEscalation({prop: 'selected_escalation', value: this.props.state.user_list.filter(x => x.user_id === args.value)})
                                    }}
                                    onClick={() => this.props.actions.getUser('')}
                                    onFilter={(args) => this.props.actions.getUser(args)} />
                                </div>
                            </div>
                            }
                        </Col>
                        <Col xl={12}>&nbsp;</Col>
                        <Col xl={12}>
                            {
                                this.props.state.selected_escalation.length 
                                ? this.props.state.escalation === "Team" 
                                ? <Table className="table" >
                                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                            <th className="text-left px-4" style={{width: "50px !important"}}></th>
                                            <th className="text-left">Team Title</th>
                                            <th className="text-left">Team Name</th>
                                        </thead>
                                        <tbody style={{zIndex:1}}>
                                            {
                                                this.props.state.selected_escalation.map(x => {
                                                    return <tr>
                                                            <td className="text-left">
                                                                <IconButton key={'command_remove_' + x.team_id} style={styles.iconButton} iconStyle={styles.action}
                                                                    onClick={
                                                                        () => {
                                                                            this.props.actions.rmTeamEscalation({prop: 'selected_escalation', value: x.team_id})
                                                                        }
                                                                    }>
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </td>
                                                            <td className="text-left">{x.title}</td>
                                                            <td className="text-left">{x.description}</td>
                                                        </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    : <Table className="table" >
                                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                            <th className="text-left px-4" style={{width: "50px !important"}}></th>
                                            <th className="text-left">Firstname</th>
                                            <th className="text-left">Lastname</th>
                                            <th className="text-left">Email</th>
                                            <th className="text-left">Phone</th>
                                            <th className="text-left">Job Title</th>
                                        </thead>
                                        <tbody style={{zIndex:1}}>
                                            {
                                                this.props.state.selected_escalation.map(x => {
                                                    return <tr>
                                                            <td className="text-left">
                                                                <IconButton key={'command_remove_' + x.user_id} style={styles.iconButton} iconStyle={styles.action}
                                                                    onClick={
                                                                        () => {
                                                                            this.props.actions.rmUserEscalation({prop: 'selected_escalation', value: x.user_id})
                                                                        }
                                                                    }>
                                                                    {/* 
                                                                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                                                     */}
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </td>
                                                            <td className="text-left">{x.firstname}</td>
                                                            <td className="text-left">{x.lastname}</td>
                                                            <td className="text-left">{x.email}</td>
                                                            <td className="text-left">{x.phone}</td>
                                                            <td className="text-left">{x.jobtitle}</td>
                                                        </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }
    
    getRule() {
        return  <div className="text-center">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                            <Col xl={2}  key={'col_add'}>
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.addRule()
                                    }}
                                    className="btn-pill mx-1">
                                    <span className="btn-wrapper--label">Add Rule</span>
                                </Button>
                            </Col>
                            <Col xl={10} ></Col>
                            <Col xl={12}>
                            {
                                this.props.state.rules.length 
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                        {/* <th className="text-left px-4" style={{width: "50px !important"}}></th> */}
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{zIndex:1}}>
                                        {
                                                this.props.state.rules.map((x,i) => {
                                                    //console.log('component_field_data_type',x)
                                                    return <tr style={{margin: '2px'}}>
                                                        <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            <Row>
                                                                <Col xl={1}>
                                                                    <br/>
                                                                    <IconButton key={'command_remove_' + x.inx} style={styles.delButton} iconStyle={styles.action}
                                                                        onClick={
                                                                            () => {
                                                                                this.props.actions.removeRow(x.inx)
                                                                            }
                                                                        }>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </Col>
                                                                <Col xl={1}>
                                                                    <br/>
                                                                    <AutoComplete
                                                                        name={'op_' + x.inx}
                                                                        title={'Op'}
                                                                        data={[{text: 'And', value: 0},{text: 'Or', value: 1}]}
                                                                        value={x.indicator_name}
                                                                        dataSourceConfig={{
                                                                            text: 'text',
                                                                            value: 'value'
                                                                        }}
                                                                        onSelect={(args) => {
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'clause_name', value: args.text})
                                                                        }}
                                                                        onClick={() => {return}}
                                                                        onFilter={(args) => {return}}
                                                                    />
                                                                    {/* &nbsp;&nbsp;
                                                                    <Checkbox style={styles.delButton}
                                                                        checked={x.chk_escalation}
                                                                        onClick={(event, checked) => {
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'chk_escalation', value: !x.chk_escalation})
                                                                        }} /> */}
                                                                </Col>
                                                                <Col xl={3}>
                                                                    <br/>
                                                                    <AutoComplete
                                                                        name={'event_component_' + x.inx}
                                                                        title={'Select component'}
                                                                        data={this.props.state.component_list}
                                                                        value={x.component}
                                                                        dataSourceConfig={{
                                                                            text: 'text',
                                                                            value: 'value'
                                                                        }}
                                                                        onSelect={(args) => {
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'component', value: args.text})
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'component_id', value: args.value})
                                                                        }}
                                                                        onClick={() => this.props.actions.getComponent('')}
                                                                        onFilter={(args) => this.props.actions.getComponent(args)} />
                                                                </Col>
                                                                <Col xl={3}>
                                                                    <br/>
                                                                    <AutoComplete
                                                                        name={'field_' + x.inx}
                                                                        title={'Select field'}
                                                                        data={this.props.state.field_list}
                                                                        value={x.field_name}
                                                                        dataSourceConfig={{
                                                                            text: 'text',
                                                                            value: 'value'
                                                                        }}
                                                                        onSelect={(args) => {
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'field', value: this.props.state.field_list.filter(x => x.component_field_id === args.value)})
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'field_id', value: args.value})
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'field_name', value: args.text})
                                                                        }}
                                                                        onClick={() => this.props.actions.getField(x.component_id, '')} //this.props.state.component_id,'')}
                                                                        onFilter={(args) => this.props.actions.getField(x.component_id, args)} 
                                                                        />
                                                                </Col>
                                                                <Col xl={2}>
                                                                    <br/>
                                                                    { 
                                                                        x.field.length 
                                                                        ? <AutoComplete
                                                                            name={'field_' + x.inx}
                                                                            title={'Select Operators for ' + x.field[0].component_field_data_type.name}
                                                                            data={this.props.state.operator_list}
                                                                            value={x.operator_text}
                                                                            dataSourceConfig={{
                                                                                text: 'text',
                                                                                value: 'value'
                                                                            }}
                                                                            onSelect={(args) => {
                                                                                this.props.actions.setRuleValue({inx: x.inx, prop: 'operator', value: this.props.state.operator_list.filter(x => x.workqueue_operator === args.text)})
                                                                                this.props.actions.setRuleValue({inx: x.inx, prop: 'operator_id', value: args.value})
                                                                                this.props.actions.setRuleValue({inx: x.inx, prop: 'operator_text', value: args.text})
                                                                            }}
                                                                            onClick={() => this.props.actions.getOperator(x.field[0].component_field_data_type.name, '')}
                                                                            onFilter={(args) => this.props.actions.getOperator(x.field[0].component_field_data_type.name, args)} /> 
                                                                        : <div></div>
                                                                    }
                                                                </Col>
                                                                <Col xl={2}>
                                                                            <br/>
                                                                    {
                                                                        x.operator.length && x.operator[0].workqueue_operator_input
                                                                        ? <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'txt_condition_' + x.inx}
                                                                            label={'Enter Condition'}
                                                                            value={x.condition}
                                                                            style={{marginTop: 0}}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setRuleValue({inx: x.inx, prop: 'condition', value: event.target.value})
                                                                            }}
                                                                        /> 
                                                                        : ''
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl={12}>&nbsp;</Col>
                                                            </Row>
                                                            {
                                                                x?.operator[0]?.workqueue_indicator_input ? <Row>
                                                                        <Col xl={2}></Col>
                                                                        <Col xl={2}>
                                                                            <div label={'Informational'} style={{ color: '#fff', backgroundColor: '#59981A', width: '100%' }}>Informational</div>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'txt_info_' + x.inx}
                                                                                label={'Value Format +2m, -10d'}
                                                                                size="small"
                                                                                value={x.indicator_info}
                                                                                style={{marginTop: 0}}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'indicator_info', value: event.target.value})
                                                                                }}
                                                                            /> 
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            <div label={'Low'} style={{ color: '#fff', backgroundColor: '#eac41b', width: '100%' }}>Low</div>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'txt_low_' + x.inx}
                                                                                label={'Value Format +2m, -10d'}
                                                                                size="small"
                                                                                value={x.indicator_low}
                                                                                style={{marginTop: 0}}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'indicator_low', value: event.target.value})
                                                                                }}
                                                                            /> </Col>
                                                                        <Col xl={2}>
                                                                            <div label={'Moderate'} style={{ color: '#fff', backgroundColor: '#d27f0e', width: '100%' }}>Moderate</div>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'txt_moderate_' + x.inx}
                                                                                label={'Value Format +2m, -10d'}
                                                                                size="small"
                                                                                value={x.indicator_moderate}
                                                                                style={{marginTop: 0}}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'indicator_moderate', value: event.target.value})
                                                                                }}
                                                                            /> </Col>
                                                                        <Col xl={2}>
                                                                            <div label={'High'} style={{ color: '#fff', backgroundColor: '#bf1910', width: '100%' }}>High</div>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'txt_high_' + x.inx}
                                                                                label={'Value Format +2m, -10d'}
                                                                                size="small"
                                                                                value={x.indicator_high}
                                                                                style={{marginTop: 0}}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'indicator_high', value: event.target.value})
                                                                                }}
                                                                            /> </Col>
                                                                        <Col xl={2}>
                                                                            <div label={'Critical'} style={{ color: '#fff', backgroundColor: '#8d110a', width: '100%' }}>Critical</div>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'txt_critical_' + x.inx}
                                                                                label={'Value Format +2m, -10d'}
                                                                                size="small"
                                                                                value={x.indicator_critical}
                                                                                style={{marginTop: 0}}
                                                                                //error={(/[+-]{1}d/.match(x.indicator_critical)) ? false : true}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'indicator_critical', value: event.target.value})
                                                                                }}
                                                                            /> </Col>
                                                                    </Row> : ''
                                                                }
                                                            <Row>
                                                                {
                                                                    x.chk_escalation
                                                                    ? <>
                                                                <Col xl={12}><hr /></Col>
                                                                <Col xl={1}></Col>
                                                                {/* <Col xl={2}>
                                                                    <AutoComplete
                                                                        name={'field_' + x.inx}
                                                                        title={'Escalation Type field'}
                                                                        data={this.props.state.escalation_types}
                                                                        value={x.escalation_type_name}
                                                                        dataSourceConfig={{
                                                                            text: 'text',
                                                                            value: 'value'
                                                                        }}
                                                                        onSelect={(args) => {
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'escalation_type', value: this.props.state.escalation_types.filter(x => x.workqueue_escalation_type_id === args.value)})
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'escalation_type_id', value: args.value})
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'escalation_type_name', value: args.text})
                                                                        }}
                                                                        onClick={() => this.props.actions.getEscalationTypes('')}
                                                                        onFilter={(args) => this.props.actions.getEscalationTypes(args)} 
                                                                        />
                                                                </Col>
                                                                {
                                                                    x.escalation_type_name
                                                                ? <Col xl={3}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        fullWidth={true}
                                                                        id={'escalation_condition' + x.inx}
                                                                        label={'Enter ' + x.escalation_type_name}
                                                                        value={x.escalation_condition}
                                                                        style={{marginTop: 0}}
                                                                        onChange={(event, value) => {
                                                                            this.props.actions.setRuleValue({inx: x.inx, prop: 'escalation_condition', value: event.target.value})
                                                                        }}
                                                                    />
                                                                </Col> : ''
                                                                } */}
                                                                <Col xl={3}>
                                                                            <br/>
                                                                            <AutoComplete
                                                                                name={'event_schedule'}
                                                                                title={'Select escalation'}
                                                                                data={this.props.state.escalation_list}
                                                                                value={this.props.state.escalation_name}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'escalation', value: this.props.state.escalation_list.filter(x => x.workqueue_escalation_id === args.value)})
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'escalation_name', value: args.text})
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'escalation_id', value: args.value})
                                                                                }}
                                                                                onClick={() => this.props.actions.getEscalation('')}
                                                                                onFilter={(args) => this.props.actions.getEscalation(args)} />
                                                                        </Col>
                                                                <Col xl={8}>
                                                                            <br/>
                                                                    {
                                                                        x.escalation_name === "Team" &&  <MultiSelect
                                                                                name={'field_team'}
                                                                                title={'Select Team'}
                                                                                limitTags={1}
                                                                                data={this.props.state.team_list}
                                                                                //value={this.props.state.team}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                onSelect={(args) => {
                                                                                    //console.log('Multi Team',args)
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'field_escalation_teams', value: args})
                                                                                    //this.props.actions.addTeamEscalation({inx: x.inx, prop: 'team', value: this.props.state.team_list.filter(x => x.team_id === args.value)})
                                                                                }}
                                                                                onClick={() => this.props.actions.getTeam('')}
                                                                                onFilter={(args) => this.props.actions.getTeam(args)} />
                                                                    }
                                                                    {
                                                                        x.escalation_name === "User" && <MultiSelect
                                                                                name={'field_user'}
                                                                                title={'Select User'}
                                                                                limitTags={1}
                                                                                data={this.props.state.user_list}
                                                                                //value={this.props.state.user}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                onSelect={(args) => {
                                                                                    //console.log('Multi User',args)
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'field_escalation_users', value: args})
                                                                                    //this.props.actions.addUserEscalation({inx: x.inx, prop: 'selected_escalation', value: this.props.state.user_list.filter(x => x.user_id === args.value)})
                                                                                }}
                                                                                onClick={() => this.props.actions.getUser('')}
                                                                                onFilter={(args) => this.props.actions.getUser(args)} />
                                                                    }
                                                                </Col>
                                                                    </>
                                                                    :''
                                                                }
                                                            </Row>
                                                        </Container>
                                                        </td>
                                                    </tr>                               
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                            }
                            </Col>
                        </Row>
                </Container>
            </div>
        </div>
    }

    getButton() {
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row style={{float: 'right'}}>
        <Button
            onClick={(event) => {
                event.preventDefault()
                this.props.actions.setInputValue({ prop: 'tab', value: 0 })
            }}
            variant="contained"
            className="btn-warning pull-right d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
            >
            {<Icon iclass={'material-icons'} iname={'cancel'} />} &nbsp; {'Cancel'}
        </Button>
        <Button
            onClick={(event) => {
                event.preventDefault()
                //this.props.actions.setInputValue({ prop: 'tab', value: 0 })
                this.props.actions.saveEvent(this.props.state)
            }}
            variant="contained"
            className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
            >
            {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {'Save Event'}
        </Button>
        </Row>
    </Container>
    //  <div className="text-right p-1">
    //         <div className="pt-4">
    //             <Button
    //                 onClick={(event) => {
    //                     event.preventDefault()
    //                     this.props.actions.setInputValue({ prop: 'tab', value: 0 })
    //                 }}
    //                 className=" btn-pill mx-1">
    //                 <span className="btn-wrapper--label">Cancel</span>
    //             </Button>
    //             <Button
    //                 onClick={(event) => {
    //                     event.preventDefault()
    //                     this.props.actions.setInputValue({ prop: 'tab', value: 0 })
    //                     this.props.actions.saveEvent(this.props.state)
    //                 }}
    //                 disabled={this.props.state.saving}
    //                 className="btn-pill mx-1">
    //                 <span className="btn-wrapper--label">{this.props.state.saving ? 'Saving..' : 'Save Event'}</span>
    //             </Button>
    //         </div>
    //         <br />
    //         <br />
    //     </div>
    }

    getLayout() {
        return <div>
            
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col key={'col_'} xl={12}>
                        <br/>
                        
                        </Col>
                    </Row>
                </Container>
                <Card title={window.t.en('Event Details')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getEvent()} />
                <Card title={window.t.en('Event Action')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getAction()} />
                <Card title={window.t.en('Event Escalation')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getEscalation()} />
                <Card title={window.t.en('Event Rule')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getRule()} />
                <Card title={window.t.en('Save Event')} 
                subtitle={<div>{'Complete the form with event details'}</div>}
                content={this.getButton()} />
            </div>
    }
    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.events,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventForm)