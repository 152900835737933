import * as types from './types'
import * as props from './props'

export default (state = props, action) => {
    switch (action.type) {
        case types.GET_USER_ACCOUNTS:
            return {
                ...state,
                loading: true
            }
        case types.SET_USER_ACCOUNT_DEFAULT_SETTING_INPUT:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.CLIENTS_SET_LOCALE_LIST:
            return {
                ...state,
                locales: action.payload
            }
        case types.SET_USER_ACCOUNTS:
            return {
                ...state,
                teams: action.payload.teams,
                accounts: action.payload.accounts,
                loading: false
            }
        case types.SET_DEFAULT_ACCOUNT:
            return {
                ...state,
                selectedAccount: action.payload.client_id,
                accountName: action.payload.title
            }
        case types.SET_SELECTED_ACCOUNT:
            return {
                ...state,
                selectedAccount: action.payload.defaultaccount,
                accountName: action.payload.client ? action.payload.client.title : '',
                accountTheme: action.payload.client ? action.payload.client.cdnclassname : '',
                accountLogo: action.payload.client ? action.payload.client.cdnlogoname : '',
                accounts: state.accounts.map(x => {
                    return x.client_id === action.payload
                        ? { ...x, selected: true }
                        : { ...x, selected: false }
                })
            }
        case types.SELECT_ACCOUNT:
            return {
                ...state,
                selectedAccount: action.payload.defaultaccount,
                accountTheme: action.payload.client ? action.payload.client.cdnclassname : '',
                accountLogo: action.payload.client ? action.payload.client.cdnlogoname : '',
                accountName: action.payload.client ? action.payload.client.title : '',
            }
        case types.CLEAR_ACCOUNT_SELECTION:
            return {
                ...state,
                selectedAccount: 0
            }
        case types.USER_SELECTED_ACCOUNT:
            return{
                ...state,
                accountName : action.payload.data,
                defaultaccount : action.payload.defaultaccount,
                selectedAccount : action.payload.selectedAccount,
                accountTheme: state.clients.filter(x => x.client_id === action.payload.selectedAccount)[0].cdnclassname,
                accountLogo: state.clients.filter(x => x.client_id === action.payload.selectedAccount)[0].cdnlogoname,
            }
        default:
            return state
    }
}