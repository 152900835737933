import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.UPLOAD_COMPONENT_SET_LIMIT:
            return {
                ...state,
                limit: action.payload,
            }
        case types.UPLOAD_COMPONENT_ADD_BLANK_UPLOAD:
            return {
                ...state,
                documents: action.payload,
            }
        case types.SET_UPLOAD_COMPONENT_DOCUMENTS_TYPES_LOOKUP_DATA:
            return {
                ...state,
                documentypes: action.payload.data,
            }
        case types.UPLOAD_COMPONENT_SET_FIELD_VALUE:
            return {
                ...state,
                documents: state.documents.map((x, i) =>
                action.payload.id === x.fileinx
                        ? {
                            ...x,
                            description: action.payload.description
                        }
                        : x
                )
            }
        case types.SET_UPLOAD_COMPONENT_FILE_NAME_VALUE:
            return {
                ...state,
                documents: action.payload
            }
        case types.SET_UPLOAD_DOCUMENT_PREVIEW:
            return {
                ...state,
                documents: state.documents.map((x, i) =>
                    action.payload.id === x.fileinx
                        ? {
                            ...x,
                            preview: action.payload.preview,
                            filename: action.payload.filename
                        }
                        : x
                )
            }
        default:
            return state
    }
}