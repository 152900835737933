export const EMPTY_TYPE = 'EMPTY_TYPE'
export const RESET_FORM = 'RESET_FORM'
export const LOAD_COMPONENT_EVENT_LOGS = 'LOAD_COMPONENT_EVENT_LOGS'
export const ADD_COMPONENT_EVENT_RULE_BLANK = 'ADD_COMPONENT_EVENT_RULE_BLANK'
export const GET_COMPONENT_EVENT_COMPONENT_FIELD = 'GET_COMPONENT_EVENT_COMPONENT_FIELD'
export const SET_COMPONENT_EVENT_PROPS_VALUES = 'SET_COMPONENT_EVENT_PROPS_VALUES'
export const GET_COMPONENT_EVENT_COMPONENT = 'GET_COMPONENT_EVENT_COMPONENT'
export const GET_COMPONENT_EVENT_OPERATOR = 'GET_COMPONENT_EVENT_OPERATOR'
export const GET_COMPONENT_EVENT_CATEGORY = 'GET_COMPONENT_EVENT_CATEGORY'
export const GET_COMPONENT_EVENT_ESCALATION = 'GET_COMPONENT_EVENT_ESCALATION'
export const GET_COMPONENT_EVENT_SCHEDULE = 'GET_COMPONENT_EVENT_SCHEDULE'
export const GET_COMPONENT_EVENT_TEAM = 'GET_COMPONENT_EVENT_TEAM'
export const GET_COMPONENT_EVENT_USER = 'GET_COMPONENT_EVENT_USER'
export const GET_COMPONENT_EVENT_ACTION = 'GET_COMPONENT_EVENT_ACTION'
export const SAVE_COMPONENT_EVENT_DATA = 'SAVE_COMPONENT_EVENT_DATA'
export const LOAD_COMPONENT_EVENT_DATA = 'LOAD_COMPONENT_EVENT_DATA'
export const GET_COMPONENT_LOAD_EVENT_DATA = 'GET_COMPONENT_LOAD_EVENT_DATA'
export const SET_COMPONENT_LOAD_EVENT_DATA = 'SET_COMPONENT_LOAD_EVENT_DATA'
export const GET_COMPONENT_EVENT_INDICATOR = 'GET_COMPONENT_EVENT_INDICATOR'
export const GET_COMPONENT_EVENT_WORKFLOW = 'GET_COMPONENT_EVENT_WORKFLOW'
export const SEND_EVENTS_MANAGMENT_EMAIL = 'SEND_EVENTS_MANAGMENT_EMAIL'
export const GET_COMPONENT_EVENT_ESCALATION_TYPES = 'GET_COMPONENT_EVENT_ESCALATION_TYPES'