import moment from 'moment'

import * as appTypes from '../../App/types'
import * as types from './types'
import * as _ from 'lodash'

export const getCustomerLookupData = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CUSTOMER_LIST_DATA,
            payload: search
        })
    }
}

export const setCustomerLookupData = (response) => {
    return (dispatch, getState) => {
        let data = response.data.map(x => {
            return {
                text: x['hierarchyname'],
                value: x['customer_id']
            }
        })
        dispatch({
            type: types.SET_COMPONENT_CUSTOMER_LIST_DATA,
            payload: data
        })
    }
}


export const getContractTypeLookupData = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CONTRACT_TYPE_LIST_DATA,
            payload: search
        })
    }
}

export const setContractTypeLookupData = (response) => {
    return (dispatch, getState) => {
        let data = response.data.map(x => {
            return {
                text: x['contracttype'],
                value: x['contracttype_id']
            }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_TYPE_LIST_DATA,
            payload: data
        })
    }
}



export const getProductTypeLookupData = (search) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_PRODUCT_TYPE_LIST_DATA,
            payload: search
        })
    }
}

export const setProductTypeLookupData = (response) => {
    return (dispatch) => {
        let data = response.data.map(x => {
            return {
                text: x['producttype'],
                value: x['producttype_id']
            }
        })
        dispatch({
            type: types.SET_COMPONENT_PRODUCT_TYPE_LIST_DATA,
            payload: data
        })
    }
}


export const setLookupValue = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_SELECTED_CUSTOMER_DATA,
            payload: data
        })
        dispatch({
            type: types.SET_CUSTOMER_DERIVATIVE_INPUT_VALUES,
            payload: data
        })
    }
}

export const getCustomerSettingData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_SELECTED_CUSTOMER_SETTINGS_DATA,
            payload: data
        })
    }
}

export const setCustomerSettingData = (payload) => {
    return (dispatch, getState) => {
    if(payload.data.length)
        dispatch(loadCustomerSettingData(payload.data[0]))
    }
}

export const loadCustomerSettingData = (data) => {
    const formatDate = (value) => {
        if (value === null) {
            return null
        }

        if (isNaN(Date.parse(value))) {
            const time = moment(value, 'HH:mm:ss', true)

            if (time.isValid()) {
                const date = moment().set({
                    'hour': time.get('hour'),
                    'minute': time.get('minute'),
                    'second': time.get('second')
                })

                return date.toDate();
            }

            return
        }

        return new Date(value)
    }

    const getInputValue = (field, data) => {
        if (!field.component_field_display_type) {
            return ''
        }

        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return data[field.name]
            case 'datePicker':
                return formatDate(data[field.name])
            case 'timePicker':
                return formatDate(data[field.name])
            case 'toggle':
                return (typeof data[field.name] != 'undefined' && data[field.name] != 'null') ? data[field.name] : false
            case 'hidden':
                return data[field.name] !== null ? data[field.name] : 0
            default:
                return data[field.name] === null ? '' : data[field.name]
        }
    }

    const getLookupData = (field, data) => {
        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                const key = field.name.replace('_id', '')
                const fields = field.display.split('|')
                const accessor = fields[0].split('.')[0]

                return [{
                    value: data[field.name] !== null ? data[field.name] : '',
                    text: fields.map(x => {
                        return x.split(',').map(y => {
                            return _.get(data[key], y.replace(accessor + '.', ''))
                        }).join(' ')
                    }).join(', ')
                }]
            default:
                return []
        }
    }

    return (dispatch, getState) => {
        const component = getState().components['customerderivative']
        let id = 0
        data = Object.keys(data).reduce((obj, x) => {
            const field = component.component_field.filter(y => y.name === x)[0]

            if (field) {
                const lookup = getLookupData(field, data)

                obj[x] = {
                    key: ''.concat('component_', component.name, '_field_', field.name, '_id_', id),
                    input: getInputValue(field, data),
                    data: lookup,
                    text: lookup.length ? lookup[0].text : '',
                    validation: {
                        valid: true,
                        errors: []
                    }
                }
            }
            else {
                obj[x] = data[x]
            }

            return obj
        }, {})

        dispatch({
            type: types.SET_COMPONENT_SELECTED_CUSTOMER_SETTINGS_DATA,
            payload: {
                name: 'customerderivative',
                data: data,
            }
        })
    }
}

export const saveComponentData = (component) => {
    const getData = () => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name]) {
                if (x.key && component.data[x.name].input) {
                    obj[x.name] = component.data[x.name].input
                }
                else {
                    obj[x.name] = component.data[x.name].input !== '' ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }

    return (dispatch, getState) => {
        //dispatch(validateForm(component.name))

        let data = getData()
        data = {
            ...data,
            description: getState().customerderivative.description,
            customer_id: getState().customerderivative.customer_id,
            contracttype: getState().customerderivative.contracttype,
            financert57Pricing: getState().customerderivative.financert57Pricing,
        }
        delete data.customerderivative_id
        //console.log('saveComponentData',data)
        //if (getState().form.valid) {
            dispatch({
                type: types.SAVE_COMPONENT_CUSTOMER_DATA,
                payload: {
                    component: component,
                    data: data
                }
            })
        //}
    }
}

export const componentDataSaved = (component) => {
    return (dispatch) => {
        //dispatch(readComponentActions.setReadComponentView('view', ''))

        dispatch({
            type: types.COMPONENT_DATA_SAVED
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Customerderivative successfully saved on ' + moment(new Date()).format('LLLL'),
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

    }
}

export const componentDataSaveFailed = (component) => {
    return (dispatch) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Unable to save on ' + moment(new Date()).format('LLLL'),
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.COMPONENT_DATA_SAVE_FAILED
        })
    }
}
