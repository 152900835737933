import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_DEFLEET_UPLOADQUOTE_COMPOMENT_STATE:
            return {
                ...state,                
                quotedate:   {                        
                    ...state.quotedate,
                   data: null,
                   value: '',
                   valid: false,
                   empty: true
               },
               quotenumber:   {                        
                    ...state.quotenumber,
                   data: null,
                   value: '',
                   valid: false,
                   empty: true
               }
            }           
            
        case types.SET_DEFLEET_UPLOADQUOTE_VALIDATION_VALUE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: {
                        ...state.fields[action.payload.prop],
                        data: action.payload.value,
                        valid: action.payload.value ? true : false
                    }
                }
            }
        case types.SET_COMPONENT_DEFLEET_QUOTE_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                }
            }
        case types.SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW:
            return {
                ...state,
                filename: action.payload.filename,
                invoicepreview: action.payload.preview,
            }
        case types.CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW:
            return {
                ...state,
                filename: action.payload,
                invoicepreview: action.payload,
            }
        case types.SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW:
            return {
                ...state,
                supplierdocument: action.payload.preview
            }
        case types.SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE:
            return {
                ...state,
                customercontractderivative: action.payload
            }
        case types.SET_BOOKING_VEHICLE_SERVICE_PART_BASKET:
            return {
                ...state,
                servicebasket: action.payload
            }
        case types.SET_VEHICLE_LASTPOSITION:
            return {
                ...state,
                geoVehiclePosition: action.payload
            }
        case types.SET_QUOTE_ITEMS:
            return {
                ...state,
                quoteItems: action.payload
            }
        case types.SET_COMPONENT_DEFLEET_SUPPLIERS_LIST:
            return {
                ...state,
                supplier_data: action.payload
            }
        case types.SET_COMPONENT_DEFLEET_SELECTED_SUPPLIER:
            return {
                ...state,
                selected_supplier: action.payload
            }
        default:
            return state
    }
}