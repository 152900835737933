import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Table, Grid, Card as C, Button, Dialog, TextField, Icon as MaterialIcon } from '@mui/material'
import { LoopRounded, GetAppOutlined } from '@mui/icons-material';

import AutoComplete from '../../controls/autocomplete'
import MultiSelect from '../../controls/multiselect'

import Icon from '../Icon/component'

import * as actions from './actions'
import * as redirectActions from "../Redirect/actions";
import * as styles from './styles'
import Header from '../WorkflowTaskInput/header'
import Card from '../../controls/card'

import _ from 'lodash'

import ReactTable from "react-table"
import "react-table/react-table.css"



class CIS extends Component {
    componentDidMount() {

    }

    getUserInput() {
        return <div >
                <Grid style={{alignItems:'center', alignContent:'center', padding: 10}} container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <AutoComplete
                            name={'selected_report_type'}
                            title={window.t.en('Select type')}
                            margin="normal"
                            info={{
                                show: false
                            }}
                            fullWidth={true}
                            data={this.props.state.report_type}
                            value={this.props.state.selected_report_type}
                            onSelect={(args) => //this.props.actions.setComponentFieldInput(field, args.value)
                            {

                                this.props.actions.setUserInput('selected_report_type', args.text)
                                this.props.actions.getSubData(args.text)
                            }
                            }
                            onClick={() => {
                                // this.props.actions.getDefleetTypes()
                            }
                            }
                            onFilter={(args) => {
                                // this.props.actions.getDefleetTypes()
                            }
                            }
                        />

                    </Grid>

                    {
                        this.props.state.selected_report_type ?
                            <>
                                <Grid item xs={5} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        name="report_title"
                                        label={'Title'}
                                        required
                                        fullWidth
                                        value={this.props.state.report_title}
                                        onChange={
                                            (event, value) => {
                                                this.props.actions.setUserInput('report_title', event.target.value)
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={5} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        name="key"
                                        label={'Key'}
                                        required
                                        fullWidth
                                        value={this.props.state.key}
                                        onChange={
                                            (event, value) => {
                                                this.props.actions.setUserInput('key', event.target.value)
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={5} sm={8}>
                                   {this.props.state.cartegory? <AutoComplete
                                        name={'sub_category'}
                                        title={window.t.en('Catergory')}
                                        margin="normal"
                                        info={{
                                            show: false
                                        }}
                                        fullWidth={false}
                                        data={this.props.state.cartegory}
                                        value={this.props.state.selected_cartegory}
                                        onSelect={(args) => //this.props.actions.setComponentFieldInput(field, args.value)
                                        {
                                            //console.log("Selected aregs", args)
                                            this.props.actions.setUserInput('selected_cartegory', args.text)
                                            this.props.actions.setUserInput('subcategory_id', args.value)
                                        }
                                        }
                                        onClick={() => {
                                            // this.props.actions.getDefleetTypes()
                                        }
                                        }
                                        onFilter={(args) => {
                                        // this.props.actions.getDefleetTypes()
                                    }
                                    }
                                />: 'Loading categies'}
                            </Grid>
                            <Grid item xs={5} sm={4}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    disabled={this.props.state.selected_cartegory
                                        && this.props.state.report_title
                                        && this.props.state.selected_report_type
                                        && this.props.state.key
                                         ? false: true}
                                    // className={classes.button}
                                    endIcon={<MaterialIcon>send</MaterialIcon>}
                                    onClick={() => {
                                        this.props.actions.saveReport()
                                        this.props.actions.route('/'+this.props.state.user.defaultlandingpage)
                                    }}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </>
                        : null
                    }


                </Grid>


        </div>
    }

    getLayout() {
        return (
            <Card
                title={'Create Report'}
                subtitle={'Fill up the following form'}
                content={<div>
                    <Row xl={12}>
                        {this.getUserInput()}
                    </Row>
                </div>
                } />)

    }

    render() {
        return (
            <div>
                <br />
                <Header title={'Business Intelligence (BI)'} description={'Creat a report or dashboard'} />
                <br />
                {this.getLayout()}
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.create_report,
            bi: { ...state.businessIntelligence },
            user: {...state.user}
        },
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions,...redirectActions, }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CIS)