import * as props from './props'
import * as types from './types'
export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_PAYMENT_COMPOMENT_STATE:
            return {
                ...state,
                previewDocuments: false,
                fields: {
                    ...state.fields,
                    selectedSupplier:   {                        
                         ...state.fields.selectedSupplier,
                        data: null,
                        valid: false,
                        empty: true
                    },
                    supplierSuspended:   {                        
                         ...state.fields.supplierSuspended,
                        data: null,
                        valid: false,
                        empty: true
                    },
                    saveChanges:   {                        
                         ...state.fields.saveChanges,
                        data: null,
                        valid: false,
                        empty: true,
                        value: true
                    },
                    documents: {
                        errorText: 'Please upload statement',
                        state: 'getState().upload.documents.length',
                        valid: false,
                        empty: true,
                    }
                }
            }  
        case types.CLEAR_REPORT:
            return {
                ...state,
                jsReport: null
            }  
        case types.SET_PAYMENT_VALIDATION_VALUE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: {
                        ...state.fields[action.payload.prop],
                        data: action.payload.value,
                        valid: action.payload.value ? true : false
                    }
                }
            }  
        case types.UPDATE_VALUE:
            return {
                ...state,
                [action.prop]: action.payload,
            }
        case types.SET_BUSINESSLIST:
            return {
                ...state,
                businessUnits: action.payload,
            }
        case types.SET_PAYMENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value,
            }
        case types.SET_PAYMENT_SELECTED_ITEM:
            return {
                ...state,
                [action.payload.prop]: action.payload.values,
            }
        case types.ADD_BLANK:
            return {
                ...state,
                [action.payload.prop]: action.payload.obj,
            }
        case types.SET_PAYMENT_DATA_LIST:
            return {
                ...state,
                payment_list: action.payload
            }
        case types.SET_PAYMENT_DATA_LIST_ADMIN:
            return {
                ...state,
                payment_list_admin: action.payload
            }
        case types.SET_PAYMENT_DATA_LIST_MAINT:
            return {
                ...state,
                payment_list_maint: action.payload
            }
        case types.SET_PAYMENT_MAIN_DATA:
            return {
                ...state,
                main_payment_list: action.payload
            }

        case types.SET_PAYMENT_DATA_LIST_TYRES:
            return {
                ...state,
                payment_list_tyres: action.payload
            }

        case types.SET_AUTOMATED_SUPPLIER_PAYMENT_LIST:
            return {
                ...state,
                supplier_list: action.payload
            }
        case types.SET_PAYMENT_PROP_INDEX:
            return {
                ...state,
                prop_inx: action.payload
            }
        case types.SET_PROP_DRAWER_INDEX:
            return {
                ...state,
                prop_drawer: action.payload
            }
        case types.UPDATE_ITEM_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.data,
            }
        default:
            return state
    }
}