import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_BILLING_VALUE_CHANGE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
            case types.SET_BILLING_TRACKING:
                return {
                    ...state,
                    billingtracking: action.payload
                }
            case types.SET_BILLING_INVOICE_REFRESH_DATA:
                return {
                    ...state,
                    data: action.payload
                }
        case types.RESET_REFRESH_VALUES:
            return {
                ...state,  
                dummyrun: true,
                finalbilling: false,
                refreshing: false,
                comments: '',             
                fields: {
                    ...state.fields,                  
                    refreshed:   {                        
                         ...state.fields.refreshed,
                        data: null,
                        valid: false,
                        empty: true
                    },
                    checked:   {                        
                        ...state.fields.checked,
                       data: null,
                       valid: false,
                       empty: true
                   },
                }
            }
        case types.BILLING_REFRESHING_STATUS:
            return {
                ...state,  
                refreshing: action.payload
            }
        default:
            return state
    }
}