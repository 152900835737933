import moment from 'moment'
import * as _ from 'lodash'
import * as types from './types'
import * as appTypes from '../App/types'
import { validateState } from '../../helpers/validator'
import * as componentActions from '../Components/actions'
import * as quoteItemsActions from '../WorkflowPOHeader/QuoteItems/actions'

export const clearWorkflowTaskInformation = (workflow, task, queue) => {
    return {
        type: types.CLEAR_WORKFLOW_TASK_INFORMATION
    }
}
export const toggleCustomerDialog = (status, message) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CUSTOMER_NOTIFICATION_DIALOG,
            payload: {
                status: status,
                message: message
            }
        })
    }
}
export const toggleDialog = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLOSE_NOTIFICATION_DIALOG,
            payload: status
        })
    }
}

export const clearWorkflowTaskActionData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_WORKFLOW_TASK_ACTION_DATA,
            payload: {
                data: []
            }
        })
    }
}

export const setToggleConfirm = (status, el) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONFIRM_TOGGLE_MENU_STATUS,
            payload: {
                confirm: status,
                confirmEl: el,
            }
        })
    }
}

export const getWorkflowTaskInformation = (workflow, task) => {
    return (dispatch, getState) => {
        const queue = getState().workflowQueueTasks.queue

        dispatch(clearWorkflowTaskInformation())
        dispatch(clearWorkflowTaskActionData())

        console.log("xTaskInformation get>>>>>>>>>>>>>>>>>>>>>>>>>>>> ", workflow, task)
        dispatch({
            type: types.GET_WORKFLOW_TASK_INFORMATION,
            payload: {
                workflow: workflow,
                task: task,
                queue: queue.workflow_queue_id && queue.workflow_queue_task_id
                    ? queue
                    : {}
            }
        })
    }
}

export const setWorkflowTaskInformation = (workflow, task, queue, data) => {
    return (dispatch, getState) => {
        console.log("xTaskInformation set >>>>>>>>>>>>>>>>>>>>>>>>>>>> ", data)

        if (!data[0]) {
            dispatch({
                type: types.SET_WORKFLOW_TASK_INFORMATION,
                payload: undefined
            })

            return
        }

        dispatch({
            type: types.SET_WORKFLOW_TASK_INFORMATION,
            payload: data[0]
        })

        dispatch({
            type: types.SET_TOTAL_STEPS,
            payload: data[0].workflow_task_step.length // + 1
        })

        const components = _.uniq(
            data[0].workflow_task_step.reduce((arr, x) => {
                x.workflow_task_step_component.forEach(y => {
                    arr.push(y.component.name)
                })

                return arr
            }, [])
        )

        components.forEach(x => {
            dispatch(componentActions.getComponent(x))
        })

        if (queue.workflow_queue_id && queue.workflow_queue_task_id) {
            dispatch(getWorkflowTaskActionData(queue.workflow_queue_id, queue.workflow_queue_task_id))
        }

        dispatch(getWorkflowTaskConnectorDataEpic(data[0].workflow_task_id))
    }
}

export const getWorkflowTaskConnectorDataEpic = (workflow_task_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_TASK_CONNECTOR_DATA,
            payload: workflow_task_id
        })
    }
}
export const setWorkflowTaskConnectorDataEpic = (payload, task) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_CONNECTOR_DATA,
            payload: {
                workflow_task_connector: payload,
                workflow_task_connector_id: task
            }
        })
    }
}

export const getWorkflowTaskTeamsData = (workflow_task_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_TASK_TEAMS,
            payload: workflow_task_id
        })
    }
}

export const setWorkflowTaskTeamsData = (teams) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_TEAMS,
            payload: teams
        })
    }
}

export const getWorkflowTaskActionData = (workflow_queue_id, workflow_queue_task_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_TASK_ACTION_DATA,
            payload: {
                workflow_queue_id: workflow_queue_id,
                workflow_queue_task_id: workflow_queue_task_id
            }
        })
    }
}


export const setWorkflowTaskActionData = (data, workflow_queue_id, workflow_queue_task_id) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_ACTION_DATA,
            payload: {
                workflow_queue_id: workflow_queue_id,
                workflow_queue_task_id: workflow_queue_task_id,
                data: data
            }
        })

        dispatch(setupWorkflowTaskInput(data))
    }
}

export const setupWorkflowTaskInput = (workflow_queue_data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_WORKFLOW_TASK_WIZARD
        })
        const selectedWorkflow = workflow_queue_data[0]
        const selectedTask = selectedWorkflow.workflow_task
        //const queuedTask = getState().workflowQueueTasks.queue
        const data = selectedWorkflow.data.data
        //const context_data = selectedWorkflow.context_data.data.data.data[0]

        dispatch({
            type: types.SET_WORKFLOW,
            payload: selectedWorkflow
        })

        dispatch({
            type: types.SET_TASK,
            payload: {
                ...selectedTask,
                workflow_task_step: selectedTask.workflow_task_step.map(x => {
                    return {
                        ...x,
                        workflow_task_step_component: x.workflow_task_step_component.map(y => {
                            return {
                                ...y,
                                data: data
                            }
                        })
                    }
                })
            }
        })
    }
}

export const submitWorkflowTaskInput = (user) => {
    return (dispatch, getState) => {
        const formatDate = (value) => {
            const now = moment(new Date())
            return new Date(moment(value).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
        }

        const getMetaData = (task) => {
            //add you state checks to determine what task send what meta data, this is an example array.
            // return [
            //     {
            //         title: 'Meta One Title ',
            //         description: 'Meta One Description...',
            //         meta_data: 'Meta One Data' 
            //     },
            //     {
            //         title: 'Meta Two Title ',
            //         description: 'Meta Two Description...',
            //         meta_data: 'Meta Two Data' 
            //     }
            // ]
            const data = task.workflow_task_step.reduce((arr, x) => {
                x.workflow_task_step_component.forEach(y => {
                    arr.push({
                        meta: y.meta
                    })
                })

                return arr
            }, [])

            return data[0].meta ? data[0].meta : []
        }

        const getTaskData = () => {
            const task = getState().workflowTaskInput.task

            //const queued = getState().workflowQueueTasks.queue
            const data = task.workflow_task_step.reduce((arr, x) => {
                x.workflow_task_step_component.forEach(y => {
                    arr.push({
                        component: y.component.name,
                        data: y.data,
                        selectedaccount: {
                            datestamp: formatDate(moment(new Date())),
                            account_id: getState().accounts.selectedAccount,
                            account_name: getState().accounts.accountName
                        }
                    })
                })

                return arr
            }, [])

            const submit_data = {
                team_id: 2, //task.workflow_task_team[0].team_id, //2
                user_id: getState().user.user_id,
                event: types.SUBMIT_WORKFLOW_TASK_INPUT,
                workflow: {
                    workflow_id: task.workflow.workflow_id,
                    name: task.workflow.name,
                    title: task.workflow.title
                },
                workflow_task: {
                    workflow_task_id: task.workflow_task_id,
                    name: task.name,
                    title: task.title
                },
                data: data,
                queue: getState().workflowQueueTasks.queue,
                meta: getMetaData(task),
                assignto: {
                    client_id: user.client_id,
                    email: user.email,
                    firstname: user.firstname,
                    user_id: user.user_id
                }
            }
            // console.log('submit_datax ', submit_data)
            return submit_data
        }

        dispatch({
            type: types.SUBMIT_WORKFLOW_TASK_INPUT,
            payload: getTaskData()
        })

        dispatch(clearWorkflowTaskInformation())
        dispatch(clearWorkflowTaskActionData())
    }
}

export const resetWorkflowTaskInput = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_WORKFLOW_TASK_WIZARD
        })

        dispatch(quoteItemsActions.setQuotations([]))
    }
}

export const setValidationState = (state) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_VALIDATION_PROPS_FIELDS,
            payload: state
        })
    }
}

export const goToNextStep = (task) => {
    return (dispatch, getState) => {
        const { workflowTaskInput } = getState();
        const nextIndex = workflowTaskInput.stepIndex + 1;
        const stepMetrics = {
            stepIndex: nextIndex,
            finished: nextIndex >= workflowTaskInput.steps,
            progress: (nextIndex / workflowTaskInput.steps * 100)
        };

        // If no validation required, proceed to next step
        if (!workflowTaskInput.validate_state) {
            return dispatch({
                type: types.GO_TO_NEXT_STEP,
                payload: stepMetrics
            });
        }

        // Validate the current state
        const { state_valid } = validateState(getState, workflowTaskInput.validate_state, dispatch);

        if (state_valid) {
            dispatch({
                type: types.SHOW_COMPONENT_ERRORS,
                payload: []
            });

            dispatch({
                type: types.GO_TO_NEXT_STEP,
                payload: stepMetrics
            });
        }
    };
};

export const goToPreviousStep = () => {
    return (dispatch, getState) => {
        const index = getState().workflowTaskInput.stepIndex - 1
        //const finished = index >= getState().workflowTaskInput.steps
        const progress = (index / getState().workflowTaskInput.steps * 100)

        dispatch({
            type: types.GO_TO_PREVIOUS_STEP,
            payload: {
                stepIndex: getState().workflowTaskInput.stepIndex - 1,
                progress: progress
            }
        })
        dispatch(quoteItemsActions.setQuotations([]))
    }
}

export const showErrors = (error) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Please Note',
                additionalText: error,
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

