export const GET_PAY_CREDITORS_LIST = 'GET_PAY_CREDITORS_LIST'
export const SET_PAY_CREDITORS_LIST = 'SET_PAY_CREDITORS_LIST'

export const SET_PAY_CREDITORS_LOOKUP = 'SET_PAY_CREDITORS_LOOKUP'
export const SET_PAY_FIELD_INPUT_VALUE = 'SET_PAY_FIELD_INPUT_VALUE'

export const GET_PAY_BUSINESS_LIST = 'GET_PAY_BUSINESS_LIST'
export const SET_PAY_BUSINESS_LIST = 'SET_PAY_BUSINESS_LIST'

export const GET_PAY_ACCOUNT_LIST = 'GET_PAY_ACCOUNT_LIST'
export const SET_PAY_ACCOUNT_LIST = 'SET_PAY_ACCOUNT_LIST'

export const GET_PAY_DISCOUNT_LIST = 'GET_PAY_DISCOUNT_LIST'
export const SET_PAY_DISCOUNT_LIST = 'SET_PAY_DISCOUNT_LIST'

export const GET_SUPPLIER_PAYMENT_ACCOUNT = 'GET_SUPPLIER_PAYMENT_ACCOUNT'
export const SET_SUPPLIER_PAYMENT_ACCOUNT = 'SET_SUPPLIER_PAYMENT_ACCOUNT'
export const SAVE_SUPPLIER_PAYMENT_DATA = 'SAVE_SUPPLIER_PAYMENT_DATA'
export const SAVED_SUPPLIER_PAYMENT_DATA = 'SAVED_SUPPLIER_PAYMENT_DATA'

export const SET_PAY_SELECTED_ITEM = 'SET_PAY_SELECTED_ITEM'

export const SET_SUPPLIER_PAYMENT_LIST = 'SET_SUPPLIER_PAYMENT_LIST'
export const GET_SUPPLIER_PAYMENT_LIST = 'GET_SUPPLIER_PAYMENT_LIST'

export const GET_SUPPLIER_PAYMENT_DUPLICATE_REFERENCE_CHECK = 'GET_SUPPLIER_PAYMENT_DUPLICATE_REFERENCE_CHECK'
export const SUPPLIER_PAYMENT_DUPLICATE_FOUND = 'SUPPLIER_PAYMENT_DUPLICATE_FOUND'
export const SUPPLIER_PAYMENT_NO_DUPLICATE_FOUND = 'SUPPLIER_PAYMENT_NO_DUPLICATE_FOUND'
