import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT_DASHBOARD_FORM_FIELDS:
            return {
                ...state,
                dashboard_name: null,
                dashboard_views: []
            }
        case types.SET_SELECTED_DASHBOARD_VIEW:
            return {
                ...state,
                dashboard_name: action.payload
            }
        case types.SET_COMPONENT_SELECTED_DASHBOAD_VIEW:
            return {
                ...state,
                dashboard_views: action.payload
            }
        case types.SET_COMPONENT_SELECTED_DASHBOAD_VIEW_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        default:
            return state
    }
}