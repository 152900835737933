import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
// import { Card, CardHeader, CardContent, CardActions } from '@mui/material'

import GeneralContentView from '../../views/GeneralContentView/component'

import AutoComplete from '../../controls/autocomplete'
import Dropzone from 'react-dropzone'

import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import TextField from '../../controls/textField'

import {ReactComponent as EditIcon} from '../../assets/img/svg/icons/edit_grid.svg';
import {ReactComponent as DeleteForeverIcon} from '../../assets/img/svg/icons/delete_forever_grid.svg';
//import prettyFileIcons from 'pretty-file-icons'
import { green, red } from '@mui/material/colors'
import ReactTable from "react-table"
import Icon from '../Icon/component'
import Loading from '../Loading/component'
import Card from '../../controls/card'

import * as config from '../../config'
import * as actions from './actions'
import * as styles from './styles'

class Import_old extends Component {
    componentDidMount() {
        this.props.actions.resetUpload()
    }
    getCommands(row) {
        //const id = row.original[component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            
            <Tooltip title="Delete" placement="bottom">
            <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                    //console.log('___paramsx row', row.viewIndex)
                    ////console.log('___paramsx params', params)
                this.props.actions.confirmDelete(row.viewIndex)
            }}>
            <DeleteForeverIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
          </IconButton>
          </Tooltip>
        )
        commands.push(
            <Tooltip title="Duplicate Row" placement="bottom">
                <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                    //console.log('___paramsx', row.bulk_rows)
                    this.props.actions.duplicateRow(row.row)
                    //props.actions.setReadComponentView('form', state.component.name)
                    //props.actions.setGridSelectedRow(state.component, params)
                    ///props.actions.changeMasterComponentTab(1, state.component)
                    //props.actions.route('/view/' + state.component.name + '/' + params.id)
                }}>
                    <EditIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
                </IconButton>
            </Tooltip>
        )
        return commands
    }
    getBulkCellValue(row, inx) {
        //console.log('getBulkCellValuex val', this.props.state.bulk_rows[row.index])
        //console.log('getBulkCellValuex bulk_rows', this.props.state.bulk_rows)
        //console.log('getBulkCellValuex inx', row.index,inx)
        //console.log('getBulkCellValuex row', row)
        return <TextField
            id={row.column.id}
            key={row.column.id}
            label={row.column.id}
            fullWidth={true}
            variant="outlined"
            color="secondary"
            value={row.value}
            onChange={
                (event, value) => {
                    //console.log('onBlur',event.target.value)
                    this.props.actions.setComponentRowInput(row.column.id, event.target.value, row.index)
                }
            } 
            onBlur={
                (event, value) => {
                    //console.log('onBlur',event.target.value)
                    //this.props.actions.setComponentRowInput(row.column.id, event.target.value, row.index)
                    //this.props.actions.setComponentFieldInput(field, event.target.value)
                    
                }
            }
        />
    }

    getBulkColumns() {
        let header = this.props.state.bulk_headers
        let columns = header.map((x, i) => {
            return {
                meta: x.title,
                Header: x.title,
                accessor: x.name,
                filterable: false,
                headerStyle: {
                    fontSize: '13px',
                    fontWeight: 'bold',
                },
                style: {
                    verticalAlign: 'middle',
                    fontSize: 12,
                    letterSpacing: 0,
                    color: 'black',
                    fontWeight: 'normal',
                },
                Cell: (row) => (
                    this.getBulkCellValue(row, i)
                ),
                Footer: (
                    this.getFooter(x.name)
                )
            }
        })
        
        columns.unshift({
            //Header: '',
            accessor: 'options',
            filterable: false,
            maxWidth: 100,
            width: 100,
            Cell: (row) => (
                this.getCommands(row)
            )
        })
        return columns
    }

    getFooter(field) {
        //console.log('getFooterx field', field)
        //console.log('getFooterx rows', this.props.state.bulk_rows)
        const total = field ? _.sumBy(this.props.state.bulk_rows, (d) => { return parseFloat((d[field]) ? d[field].toString().replace(/,/gi, '') : 0) }) : ''
        //console.log('getFooterx', field)
        return (field == 'excl' || field == 'vat' || field == 'incl' )
            ? <span>
                { parseFloat(total).toFixed(2) }
            </span>
            : <span>
                <strong></strong>{" "}
            </span>
    }
    getRowGrid() {
        return <ReactTable
            key={'grid_booking_quote'}
            data={this.props.state.bulk_rows}
            columns={this.getBulkColumns()}
            filterable={false}
            loading={this.props.state.loading}
            manual
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                    },
                }
            }}
            onFetchData={
                (state) => {
                    //this.props.actions.getRestructureGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            minRows={1}
            showPagination={false}
            showPaginationBottom={false}
            style={{
                height: '600px',
                position: 'relative'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }

    getAcceptedFiles() {
        return this.props.state.bulk_rows.length
            ? <div >
                    {
                    this.props.state.document 
                        ? <><Button className={"global_button"} variant="contained"
                        label={'Reset Upload'}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.resetUpload()
                        }}
                        >{window.t.en('Reset Upload')}
                          </Button>
                    &nbsp;
                    <Button className={"global_button"} variant="contained"
                            label={'Reset Upload'}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.exportToExcel()
                            }}
                            >{window.t.en('Export To Excel')}
                        </Button>
                         </> 
                        : <></>
                    }
                    {/* <Button className={"global_button"} variant="contained"
                        label={'Upload File Data'}
                        disabled={this.props.state.template ? false : true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.uploadFileData(this.props.state)
                        }}
                        >{window.t.en('Upload File Data')}</Button> */}
                        <br/><br/>
                        <div>{this.props.state.exceptions}</div>
                        
                    <div>
                        {this.getRowGrid()}
                    </div>
                </div>
            : <div />
    }

    getRejectedFiles() {
        return !this.props.state.busy && !this.props.state.bulk_rows.length
            ? <Button className={"global_button"} variant="contained"
                        label={'Upload Again'}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.resetUpload()
                        }}
                        >{window.t.en('Upload Again')}</Button>
            : <div />
    }
    getFileTypeIcon(file) {
        // const type = prettyFileIcons.getIcon(file)
        // const src = require('../../assets/img/png/icons/' + type + '.png')

        return ""
    }

    getImport() {
        
        return <div><Card
            title={window.t.en('Bulk Quotation')}
            subtitle={window.t.en('Import excel template with data')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    {
                        !this.props.state.document
                            ? <Row>
                                <Col xl={12}>
                                    <Dropzone
                                        accept={'.xls,.xlsx'}
                                        style={styles.dropZone}
                                        onDrop={(files) => {
                                            //this.props.actions.notifyUser('Checking documents...')
                                        }}
                                        onDropAccepted={(files) => {
                                            this.props.actions.setAcceptedDocuments(files)
                                        }}
                                        onDropRejected={(files) => {
                                            //this.props.actions.notifyUser('(' + files.length + ') files were rejected.')
                                            //this.props.actions.setRejectedDocuments(files, this.props.state.component.name)
                                        }}
                                    >
                                        <p style={styles.watermark}>
                                            Drag and drop the files you would like to upload inside this box. please ensure that your are uploading the correct file format:
                                            <br />
                                            .xls and .xlsx
                                        </p>
                                    </Dropzone>
                                </Col>
                            </Row>
                            : <></>
                    }
                </Container>
            }
        /> 
                            {this.props.state.document && !this.props.state.busy && this.getAcceptedFiles()}
                            {this.props.state.document && !this.props.state.busy && this.getRejectedFiles()}
                </div> 
    }

    gteView() {
        return <div>
            {this.getImport()}
        </div>
    }

    gteLayout() {
        return <GeneralContentView
            content={this.gteView()}
            headerImage={'test.png'}
            title={window.t.en('')}
            contentHeight={'900px'} />
    }

    render() {
        return (
            this.gteLayout()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.bulkQuotation,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Import_old)