import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import DateControl from '../../../controls/dateControl'
import ViewSupplier from '../../../controls/view_supplier'

import * as actions from './actions'
import * as styles from './styles'

class PricingMasterActivation extends Component {
    componentWillUnmount() { 
        this.props.actions.resetForm()
    }
   
    getMMCode(mmcode) {
        return <div>
            <Card 
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Selected vehicle details ')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size:4, label: 'MM Code:', value: mmcode.mmcode},
                            {size:4, label: 'Model Code:', value: mmcode.modelcode},
                            {size:4, label: 'Vehicle Make:', value: mmcode.make},
                            {size:4, label: 'Vehicle Model:', value: mmcode.model},
                            {size:4, label: 'Vehicle Type:', value: mmcode.vehicletype},
                            {size:4, label: 'Registration Year:', value: mmcode.regyear},
                            {size:4, label: 'Introduction Type:', value: mmcode.introdate},
                            {size:4, label: 'Discontinue Date:', value: mmcode.discdate},
                            {size:4, label: 'Transmission:', value: mmcode.manualauto},
                            {size:4, label: 'Fuel Type:', value: mmcode.fueltype},
                            {size:4, label: 'Body Type:', value: mmcode.bodytype},
                            {size:4, label: 'Cost Price:', value: mmcode.cprice},
                            {size:4, label: 'Discount Price:', value: mmcode.dprice},
                            {size:4, label: 'Vehicle Price:', value: mmcode.amount},
                        ]}
                    />
                }
            />
        </div>
    }

    reviewPricing(pricing) {
        return <div>
            {
                                pricing.length 
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <tbody style={{zIndex:1}}>
                                        {
                                                pricing.map((x, inx) => {
                                                    
                                                    return <tr>
                                                        <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            <Row>     
                                                                <Col xl={3} style={{paddingTop: '15px'}}> <strong>Supplier Name</strong> </Col>
                                                                <Col xl={2} style={{paddingTop: '15px'}}> <strong>Cost Price</strong> </Col>
                                                                <Col xl={2} style={{paddingTop: '15px'}}> <strong>Selling Price</strong> </Col>
                                                                <Col xl={2} style={{paddingTop: '15px'}}> <strong>Price Expiry Date</strong> </Col>
                                                                <Col xl={2} style={{paddingTop: '15px'}}> <strong>Price Vatable</strong> </Col>
                                                            </Row>  
                                                            {
                                                                <Row>
                                                                <Col xl={3} style={{paddingTop: '15px'}}>
                                                                        {x.suppliername}
                                                                    </Col>
                                                                    <Col xl={2} style={{paddingTop: '15px'}}>
                                                                    {x.costprice}
                                                                    </Col>
                                                                    <Col xl={2} style={{paddingTop: '15px'}}>
                                                                    {x.sellingprice}
                                                                    </Col>
                                                                    <Col xl={2} style={{paddingTop: '15px'}}>
                                                                    {moment(x.expirydate).format('YYYY-MM-DD')}
                                                                    </Col>
                                                                    <Col xl={2} style={{paddingTop: '15px'}}>
                                                                    {x.vatable}
                                                                    </Col>
                                                                    <Col xl={1}>
                                                                    </Col>
                                                                    {
                                                                        x.suppliername
                                                                        ? <div style={{width: '100%'}}>
                                                                        <hr />
                                                                            <Col xl={12} style={{paddingTop: '15px', borderTop: '1px solid gray'}}>
                                                                                <h5>Selected Customers</h5>
                                                                            </Col>
                                                                            <Col xl={12} style={{paddingTop: '15px'}} className="clearFix">
                                                                                {
                                                                                    x.customers 
                                                                                    ? <Container className="table table-alternate-spaced mb-0">
                                                                                            <Row style={{background: '#d0d0d0'}}>     
                                                                                                <Col xl={4} style={{paddingTop: '15px'}}>
                                                                                                    <strong>Selected Name</strong>
                                                                                                </Col>
                                                                                                <Col xl={4} style={{paddingTop: '15px'}}>
                                                                                                    <strong>Account Number</strong>
                                                                                                </Col>
                                                                                                <Col xl={4} style={{paddingTop: '15px'}}>
                                                                                                    <strong>Costcentre </strong>
                                                                                                </Col>
                                                                                            </Row>  
                                                                                            {
                                                                                                x.customers.map((cus, i) => {
                                                                                                    return <Row style={{border: 'none !important'}}>
                                                                                                        <Col xl={4} style={{border: 'none !important', background: 'none !important'}}>{cus.customername}</Col>
                                                                                                        <Col xl={4} style={{border: 'none !important', background: 'none !important'}}>{cus.accountnumber}</Col>
                                                                                                        <Col xl={4} style={{border: 'none !important', background: 'none !important'}}>{cus.costcentre}</Col>
                                                                                                    </Row>
                                                                                                })
                                                                                            }
                                                                                        </Container>
                                                                                    : ''
                                                                                }
                                                                            </Col>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </Row>
                                                        }
                                                        </Container>
                                                        </td>
                                                    </tr>                               
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                                    }
        </div>
    }

    getView(data) {
        let confirm = data?.confirm
        //console.log('AccessoryMasterActivationx confirm', confirm)
        return data ? <div>
            {this.getMMCode(data.mmcode)}
            <Card
                title={"Supplier Accessories"}
                subtitle={window.t.en('Supplier accessories pricing')}
                content={this.reviewPricing(confirm.pricing)}
            />  
            <Card
                title={"Supplier Documents"}
                subtitle={window.t.en('Uploaded supplier supporting documents')}
                content={<PdfViewer
                    key={'supplier_pricing'}
                    name={'supplier_pricing'}
                    documents={confirm.documents.documents}
                    edit={false}
                    multiple={false}
                    workflow_queue_data={null}
                />}
            />
            <Card
            title={'Approval Comments '}
            subtitle={'Approval approval comments'}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            variant='outlined'
                            id={'txt_comments'}
                            label={'Enter Comments'}
                            fullWidth={true}
                            value={this.props.state.comments}
                            onChange={(event, value) => {
                                this.props.actions.setInputValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>
            } />
        </div> : ''
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('AccessoryMasterActivationx', data.context_data.data.data.data[0])
        data = data.context_data.data.data.data[0]
        return <div>
            {this.getView(data)}
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.pricingMasterActivation
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingMasterActivation)