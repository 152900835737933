import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as gridTypes from '../AdhocGrid/types'
import * as types from './types'


export const getPOs = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPLETED_POS,

        })
    }
}
export const refresh_invno = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REFRESH_INVNO,
            payload: val
        })

    }
}

export const updateValue = (prop,val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_VALUE,
            payload: val,
            prop: prop
        })

    }
}

export const toggleItem = (user_id, data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_ITEM,
            payload:{...data,user_id: user_id},
        })

    }
}

export const approveItem = (data) => {
    //console.log("<<<<<<<<<< data >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_ITEM,
            payload: data
        })

    }
}

export const processPayment = (data) => {
    //console.log("<<<<<<<<<< processPayment >>>>>>>>>>>")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.PROCESS_PAYMENT,
            payload: data
        })

    }
}
export const refresh_invamt = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REFRESH_INVAMT,
            payload: val
        })

    }
}

export const getposupplierlist = (search) => {
    //console.log('>>>>>>>>>>>>> action : getposupplierlist <<<<<<<<<<<<<')
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_POSUPPLIERLIST,
            payload: {
                search_text: search
            }
        })
    }

}
export const setposupplierlist = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.suppliername,
            value: x.supplier_id
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_DATA_LIST,
            payload: {
                data: data,
                component: 'purchaseorder',
                field: 'purchaseorder_id'
            }
        })
    }
}

export const setPOs = (payload) => {
    window.glyco.log('setPOs')
    window.glyco.log(payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPLETED_POS,

        })
    }
}

export const uploadPODocument = (id, supplier_id) => {
    window.glyco.log('setPOs')
    window.glyco.log(id)
    return (dispatch, getState) => {
        const data = {
            id: id,
            documents: getState().upload.documents,
            supplier_id: supplier_id
        }
        window.glyco.log(' uploadPODocument data')
        window.glyco.log(data)
        dispatch({
            type: types.UPLOAD_PO_DOCUMENTS,
            payload: JSON.stringify(data),
        })
    }
}

export const processedSupplier = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'payment_grid',
                uri: '/components/supplierpaymentmanagement/other',
                
                body: {
                    type: 'get_completed_pos',
                    user_id: getState().user,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
        dispatch({
            type: types.GENERATE_PAYMENT_NOTIFICATION,
            payload: {
                type: types.GENERATE_PAYMENT_NOTIFICATION,
                data: id
            }
        })
    }
}
export const refreshbysupplier = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'payment_grid',
                uri: '/components/supplierpaymentmanagement/other',
                
                body: {
                    type: 'get_completed_pos',
                    user_id: getState().user,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}
export const setApprovePO = (payload) => {
    window.glyco.log('setApprovePO')
    window.glyco.log(payload)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Approved',
                additionalText: 'Purchase order has been successfully approved',
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const approvePO = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_PO,
            payload: data
        })
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const rejectsPO = (id) => {
    window.glyco.log('setPOs')
    window.glyco.log(id)
    return (dispatch, getState) => {
        dispatch({
            type: types.REJECT_PO,
            payload: id
        })
    }
}

export const setRejectPO = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Rejected',
                additionalText: 'Purchase order has been successfully rejected',
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const setUploadPO = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Uploaded',
                additionalText: 'Purchase order document successfully uploaded',
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const alert = (msg) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Approval',
                additionalText: msg,
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}


export const getBusinessList = (search) => {
    //console.log('>>>>>>>>>>>>> action : getBusinessList <<<<<<<<<<<<<')
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BUSINESSLIST,
            payload: {
                search_text: search
            }
        })
    }

}

export const setBusinessList = (payload) => {
    //console.log('>>>>>>>>>>>>> action : getBusinessList <<<<<<<<<<<<<')
    //console.log(payload)
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.title,
            value: x.client_id
        }
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BUSINESSLIST,
            payload: data
        })
    }

}

export const setLookupValue = (prop, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_ITEM,
            payload: {
                prop: prop,
                values: {
                    value: value,
                    text: text
                }
            }
        })
    }

}

export const getDocument = (data) => {
    //console.log(">>>>>>>>>>>>>>> getRemittance <<<<<<<<<<<")
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating ' + data.document + '...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GET_PAYMENT_DOCUMENT,
            payload: {
                type: types.GET_PAYMENT_DOCUMENT,
                data: data
            }
        })
    }
}
export const setPaymentNotification = (data) => {
      return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Payment Loaded',
                additionalText: 'Payment processed for approval.',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setPaymentDocumentPDF = (data) => {
    //console.log("Does it hit")
    //console.log(data)
    return (dispatch, getState) => {

        switch (data.data.document) {
            case 'Remittance': {
                //console.log(">>>>>>>>>>>>>>> setRemittancePDF <<<<<<<<<<<")
                setRemittancePDF(data)
            }
                break;
            case 'Recon': {
                //console.log(">>>>>>>>>>>>>>> setReconPDF <<<<<<<<<<<")
                setReconPDF(data)
            }
                break;
            case 'Requisition': {
                //console.log(">>>>>>>>>>>>>>> setRequisitionPDF <<<<<<<<<<<")
                setRequisitionPDF(data)
            }
                break;
        }
    }
}

export const setReconPDF = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setRemittancePDF = (data) => {

    return (dispatch, getState) => {
        const header = [
            { key: 'date', name: 'Date', default: '' },
            { key: 'reference', name: 'Invoice Number', default: '' },
            { key: 'description', name: 'Description', default: '' },
            { key: 'credit', name: 'Credit', default: '' },
            { key: 'maintenancefund', name: 'Maintenance Fund', default: '' },
            { key: 'rebill', name: 'Rebill', default: '' },
            { key: 'shortpaid', name: 'Short Payment', default: '' },
            { key: 'invoiceamount', name: 'Invoice Total', default: '' },
            { key: 'payable', name: 'Amount Payable', default: '' },
        ]

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: data.data.document,
                data: data.data.excel,
                header: header
            }
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setRequisitionPDF = (data) => {
}

export const addBlankRow = (type, id) => {
    //console.log("Blank ID")
    //console.log(id)

    return (dispatch, getState) => {
        let obj = []
        let prop = type
        switch (type) {
            case 'payments':
                {
                    obj = getState().supplierpaymentmanagement[type]
                    obj.push(
                        {
                            id: id,
                            date: '',
                            eft: 0.00,
                            amount: 0.00,
                            discount: 0.00,
                            total: 0.00
                        })
                } break;          
            default:
                {
                    obj = getState().supplierpaymentmanagement[type]
                    obj.push(
                        {
                            id: 0,
                            date: '',
                            invoicenumber: '',
                            amount: 0.00,
                            reason: '',
                            total: 0.00
                        })
                }
        }


        dispatch({
            type: types.ADD_BLANK,
            payload: obj,
            prop: prop
        })
    }
}


export const setUpdateItem = (type, id, prop, value) => {
    return (dispatch, getState) => {
        let obj = getState().supplierpaymentmanagement[type]     
        var data = obj.map((x, i) => {
            return (x.id === id && (prop === 'date')) ?
                {
                    ...x,
                    date: value
                } : (x.id === id && (prop === 'eft')) ?
                    {
                        ...x,
                        eft: (value),
                        amount: (value), 
                        total: parseFloat(value)+parseFloat(x.discount)
                    } : (x.id === id && (prop === 'amount')) ?
                        {
                            ...x,
                            amount: (value),                       
                            total: (value)
                        } : (x.id === id && (prop === 'discount')) ?
                            {
                                ...x,
                                discount: value,       
                                total:  parseFloat(value)+parseFloat(x.amount)
                            }
                            : (x.id === id && (prop === 'reason')) ?
                                {
                                    ...x,
                                    reason: value,
                                } : (x.id === id && (prop === 'invoicenumber')) ?
                                    {
                                        ...x,
                                        invoicenumber: value,
                                    } : x
        })
        dispatch({
            type: types.UPDATE_ITEM_VALUE,
            payload: { 
                data:data,
                prop: type
            }
        })
    }
}

export const removeRow = (type, id) => {
    return (dispatch, getState) => {
        let obj = getState().supplierpaymentmanagement[type] .filter(x => x.id !== id)
        dispatch({
            type: types.UPDATE_ITEM_VALUE,
            payload: { 
                data:obj,
                prop: type
            }
        })
    }
}


export const getSupplierTransactionDocument = (filename) => {
    return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating Document...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })          
        
        dispatch({
            type: types.GET_SUPPLIER_TRANSACTION_DOCUMENT,
            payload: {
                type: "GET_SUPPLIER_TRANSACTION_DOCUMENT",
                filename: filename
            }
        })
    }
}

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_PAYMENT_LOAD,
            payload: payload
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PAYMENT_CONFIRM_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}
