import * as actions from './actions'
import * as types from './types'

import * as config from '../../config'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as requestTypes from '../Request/types'

export const getTemplateList = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_EXCEL_FILE_TEMPLATE_DATA_LIST),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/importtemplate/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
            .pipe(
                mergeMap((result) => [
                    actions.setTemplateList(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/importtemplate/search?template_name=' + payload)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/importtemplate/search?template_name=' + payload, error)))
            )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const uploadFileData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.UPLOAD_EXCEL_FILE_DATA)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/importtemplate/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap((result) => [
                    actions.uploadFileDataDone(result),
                    requestActions.requestSucceeded(config.system.api + '/components/importtemplate/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/importtemplate/other', error)))
            )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )