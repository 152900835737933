import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Card, CardHeader, CardContent, InputAdornment, Table, Grid, FormControl, Menu, MenuItem, List, ListItem, InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'

import Box from '@mui/material/Box';
import { Container, Row, Col } from 'react-grid-system'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Badge from '@mui/material/Badge';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton'; 

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import AcceptButton from '../../themes/ComponentStyles/AcceptButton'

import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';

import ArrowDownwardTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@mui/icons-material/RadioButtonUncheckedTwoTone';

import TrendingDownTwoToneIcon from '@mui/icons-material/TrendingDownTwoTone';
import TrendingUpTwoToneIcon from '@mui/icons-material/TrendingUpTwoTone';

import Icon from '../Icon/component'
import Loading from '../Loading/component'
import WorkflowTimeline from './WorkflowTimeline/component'
import WorkflowTriggers from './WorkflowTriggers/component'
import WorkflowVehicleHistory from './WorkflowVehicleHistory/component'
import WorkflowDocuments from './WorkflowDocuments/component'

import GeneralContentView from '../../views/GeneralContentView/component'
import AssignedTaskCount from './AssignedTaskCount/component'
import AssignedTasks from './AssignedTasks/component'
import CompletedTaskCount from './CompletedTaskCount/component'
import CompletedTasks from './CompletedTasks/component'
import QueuedTaskCount from './QueuedTaskCount/component'
import QueuedTasks from './QueuedTasks/component'
import WorkflowTeamHide from './WorkflowTeamHide/component'
import WorkflowDisposal from './WorkflowDisposal/component'
import BI from "../../components/Oqlis/component";

import * as actions from './actions'
import * as messengerActions from '../Messenger/actions'
import * as workflowTimelineActions from './WorkflowTimeline/actions'
import * as workflowTriggerActions from './WorkflowTriggers/actions'
import * as WorkflowVehicleHistoryActions from './WorkflowVehicleHistory/actions'
import * as WorkflowDocumentsActions from './WorkflowDocuments/actions'
import * as WorkflowDisposalActions from './WorkflowDisposal/actions'


import Typography from "@mui/material/Typography";
import { styled  } from '@mui/material/styles';
import { ReactComponent as HomeIconCustom} from '../../assets/img/svg/icons/home.svg'
import { Link as RouterLink  } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
const CustomTextTypography = styled(Typography)(({ theme }) => ({
    color: '#131B3A'
  }));
import * as styles from './styles'

class WorkflowQueueTasks extends Component {
    state = {open : false}
    componentDidMount() {
        this.props.actions.clearWorkflowQueueTasks()
        this.props.actions.getWorkflowQueueTaskTeams()
    }

    setOpen(open){
        this.setState({open: open})
    }

    getLoadingMessage(messenger, engine, user) {
        return !messenger || !engine
            ? 'The system\'s workflow engine is offline. Normal functionality will resume when it is available.'
            : 'Retrieving your account information, please wait...'
    }

    getTeamsList(teams, user_id) {
        return   <>
        <div>
          
          <div className="pt-4 px-4">
            <Table className="table">
              <tbody>
                  {
                       teams.map((team, i) => {
                        return <> <tr onClick={() => {
                                    this.setOpen(!this.state.open)
                                    this.props.actions.toggleWorkflowTasks(this.props.state.collapse === -1 ? i : -1, team.team_id, user_id)
                                }}>
                           <td className="px-4">
                             <div className="d-flex align-items-center">
                               {/* <div className="d-40 text-primary d-flex align-items-center justify-content-center rounded-pill mr-3 bg-neutral-first-off text-first" style={{color: 'red'}}>
                                <Icon iclass={team.icon.unicode} iname={team.icon.name}  style={{color: 'red'}}/>
                               </div> */}
                               <div>
                                 <div style={{fontWeight: 400, fontSize: '16px'}}> 
                                    {team.title}
                                 </div>
                                 <div style={{fontSize: '12px'}}>
                                 {team.description}
                                 </div>
                               </div>
                             </div>
                           </td>
                           <td className="text-left">
                             <div>
                               <div className="font-size-sm font-weight-bold">
                                
                               </div>
                               <div className="font-size-sm opacity-7">
                                 
                               </div>
                             </div>
                           </td>
                           <td className="text-left">
                             <div>
                               <div className="font-size-sm font-weight-bold">
                                 
                               </div>
                               <div className="font-size-sm opacity-7 text-danger d-flex align-items-center">
                                 <div className="badge badge-danger mr-1 border-0 badge-circle">
                                  
                                 </div>
                                 
                               </div>
                             </div>
                           </td>
                           <td className="text-right">
                             <div className="px-4 py-1 h-auto text-danger text-capitalize font-size-sm border-0 badge badge-neutral-danger" style={{borderRadius: '20px'}}>
                                <QueuedTaskCount team_id={team.team_id} user_id={user_id} />
                             </div>
                           </td>
                           <td className="text-right">
                             <div className="px-4 py-1 h-auto text-warning text-capitalize font-size-sm border-0 badge badge-neutral-warning" style={{borderRadius: '20px'}}>
                                <AssignedTaskCount team_id={team.team_id} user_id={user_id} />
                             </div>
                           </td>
                           <td className="text-right">
                             <div className="px-4 py-1 h-auto text-success text-capitalize font-size-sm border-0 badge badge-neutral-success" style={{borderRadius: '20px'}}>
                                <CompletedTaskCount team_id={team.team_id} user_id={user_id} />
                             </div>
                           </td>
                           <td className="text-center">
                             {/* <Button className="btn-neutral-primary mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                               <FontAwesomeIcon
                                 icon={['fas', 'search']}
                                 className="font-size-sm"
                               />
                             </Button>
                             <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                               <FontAwesomeIcon
                                 icon={['far', 'edit']}
                                 className="font-size-sm"
                               />
                             </Button>
                             <Button className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                               <FontAwesomeIcon
                                 icon={['fas', 'times']}
                                 className="font-size-sm"
                               />
                             </Button> */}
                      
                           </td>
                         </tr>
                       
                             {
                                 this.props.state.collapse === i 
                                 ? <tr style={{ paddingBottom: 0, paddingTop: 0 }} >
                                 <td colspan="7" >
                                         <Collapse in={this.props.state.collapse === i} timeout="auto" unmountOnExit>
                                             <Row>
                                                 <Col  style={{margin: 0, padding: 0, }}>
                                                     <Tabs
                                                             initialSelectedIndex={0}
                                                             value={this.props.state.tab}
                                                             inkBarStyle={{
                                                                 height: 4
                                                             }}
                                                             indicatorColor="primary"
                                                             onChange={(event, value) => {
                                                                 this.props.actions.changeWorkflowTab(value)
                                                                 this.props.actions.refreshflowTasksCount(team.team_id, this.props.state.user.user_id)
                                                             }}
                                                        >
                                                         <Tab className='' style={{minWidth:"50%"}} label={'My Team\'s Queued Tasks'} />
                                                         <Tab style={{minWidth:"50%"}} label={'My Assigned Tasks *'} />
                                                     </Tabs>
                                                     <div>
                                                         {this.props.state.tab === 0 && <QueuedTasks team_id={team.team_id} user_id={this.props.state.user.user_id} />}
                                                         {this.props.state.tab === 1 && <AssignedTasks team_id={team.team_id} user_id={this.props.state.user.user_id} />}
                                                     </div>
                                                 </Col>
                                             </Row>
                                            
                                         </Collapse>
                                     </td>
                                 </tr>
                                 :''
                             }
                         </>
                       })
                  }
               </tbody>
            </Table>
          </div>
          {/* <div className="divider mt-3" /> */}
            {/*<div className="card-footer p-4 d-flex justify-content-center">
           <Pagination className="pagination-primary" count={10} /> 
          </div>*/}
        </div>
      </>
  
    }

    getTeamsTable(teams, user_id){
        return !this.props.state.messenger.engine || !this.props.state.user /// !this.props.state.messenger.connected || 
            ?  <Loading className={'workflow-task-loader'} message={this.getLoadingMessage(this.props.state.messenger.connected, this.props.state.messenger.engine, this.props.state.user)} />
            : !this.props.state.user || !this.props.state.teams.length
                ? <Loading message={!this.props.state.teams.length ? 'You dont have team/s with workflow linked' : 'Retrieving your team and task data'} />
                : <>
                  <div className="d-flex" style={{paddingLeft: '23px'}}>
                    <Button variant="contained" className="btn-Accept"
                        startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'note_add'} /> }
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleWorkflowView('view_workflow_trigger')
                                // this.props.actions.toggleWorkflowTimeline('general_workflow_timeline', false)
                                // this.props.actions.toggleWorkflowDocuments('general_workflow_documents', false)
                                // this.props.actions.toggleWorkflowVehicleHistory('general_workflow_history', false)
                                // this.props.actions.toggleWorkflowDisposal('general_workflow_disposal', false)
                            }}
                        > NewTask            
                    </Button>
                    &nbsp;
                    <div>
                    <Button variant="contained" className="btn-timelines"
                        startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'timeline'} />}
                        onClick={(event) => {
                            event.preventDefault()
                                this.props.actions.toggleWorkflowView('view_workflow_timeline')
                                // this.props.actions.toggleWorkflowTimeline('general_workflow_timeline')
                                // this.props.actions.toggleWorkflowTriggers(false)
                                // this.props.actions.toggleWorkflowDocuments('general_workflow_documents', false)
                                // this.props.actions.toggleWorkflowVehicleHistory('general_workflow_history', false)
                                // this.props.actions.toggleWorkflowDisposal('general_workflow_disposal', false)
                                
                        }}
                        >Timeline 
                    </Button> 
                    </div>
                    &nbsp;
                    <div key={'workflow_task_menu_history_icon'} >
                        <Button variant="contained"
                            startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'history'} />}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleWorkflowView('view_workflow_history')
                                // this.props.actions.toggleWorkflowVehicleHistory('general_workflow_history')
                                // this.props.actions.toggleWorkflowTriggers(false)
                                // this.props.actions.toggleWorkflowTimeline('general_workflow_timeline', false)
                                // this.props.actions.toggleWorkflowDisposal('general_workflow_disposal', false)
                                // this.props.actions.toggleWorkflowDocuments('general_workflow_documents', false)
    
                            }}
                            >History 
                            </Button>
                    </div>
                    &nbsp;
                    <div key={'workflow_task_menu_documents_icon'} >
                        <Button variant="contained"
                            startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'file_download'} />}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.toggleWorkflowView('view_workflow_documents')
                                // this.props.actions.toggleWorkflowDocuments('general_workflow_documents')
                                // this.props.actions.toggleWorkflowTriggers(false)
                                // this.props.actions.toggleWorkflowTimeline('general_workflow_timeline', false)
                                // this.props.actions.toggleWorkflowVehicleHistory('general_workflow_history', false)
                                // this.props.actions.toggleWorkflowDisposal('general_workflow_disposal', false)
                                
                            }}
                            >Documents 
                        </Button>
                    </div>
                  </div>
                <br/>
                    { this.props.state.view_workflow === 'view_workflow_trigger' && <WorkflowTriggers /> }
                    { this.props.state.view_workflow === 'view_workflow_timeline' && <WorkflowTimeline key={'general_workflow_timeline'} name={'general_workflow_timeline'} searchable={true} /> }
                    { this.props.state.view_workflow === 'view_workflow_history' && <WorkflowVehicleHistory key={'general_workflow_history'} name={'general_workflow_history'} searchable={true} /> }
                    { this.props.state.view_workflow === 'view_workflow_documents' && <WorkflowDocuments key={'general_workflow_documents'} name={'general_workflow_documents'} searchable={true} /> }
                    {/* <WorkflowDisposal key={'general_workflow_disposal'} name={'general_workflow_disposal'} searchable={true} /> */}
                <div className="px-4 py-3">
                <div style={{width: '100%'}}
                    // className={clsx(
                    // 'search-wrapper search-wrapper--alternate search-wrapper--grow',
                    // { 'is-active': true }
                    // )}
                    >
                            <TextField
                                variant="outlined"
                                color="primary"
                                size="medium"
                                fullWidth={true}
                                //style={{width:'350px'}}
                                id="input-with-icon-textfield22-2"
                                placeholder="Search Registration, Task Number..."
                                // onFocus={openSearch}
                                // onBlur={closeSearch}
                                onChange={(event) => {
                                    event.preventDefault()
                                    this.props.actions.setWorkflowQueueTasksSearchValue(event.target.value)
                                }}
                                key={'workflow_queue_task_search_input_text'}
                                hintText={'Search...'}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        this.props.actions.searchWorkflowQueueTasks()
                                    }
                                }}
                                value={this.props.state.value}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" >
                                            <SearchTwoToneIcon className={"custom-svg-icon-search"} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                </div>
                </div>
                {
                    this.getTeamsList(teams, user_id)
                }
                {/* <div className="search-wrapper">
                    <div key={'workflow_queue_task_search_input'} style={styles.searchInput}>
                            <TextField
                                onChange={(event) => {
                                    event.preventDefault()
                                    this.props.actions.setWorkflowQueueTasksSearchValue(event.target.value)
                                }}
                                
                                value={this.props.state.value}
                                key={'workflow_queue_task_search_input_text'}
                                hintText={'Search...'}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        this.props.actions.searchWorkflowQueueTasks()
                                    }
                                }}
                            />
                        </div>
                        <div key={'workflow_queue_task_search_icon'} style={styles.searchIcon}>
                            < Button className="btn-Search"
                                variant="contained"
                                icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.searchWorkflowQueueTasks()
                                }}
                                
                            >{<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
                            </Button>
                        </div>
                </div> */}
                {/* <div className="pt-4 px-4">
                  <Table className="table table-alternate-spaced text-nowrap mb-0">
                    <thead className="bg-white font-size-sm text-uppercase">
                      <tr>
                        <th className="bg-white text-left px-4">Workflow Tasks</th>
        
                        <th className="bg-black text-left px-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                        teams
                        ? teams.map((team, i) =>{
                            return <div>
                                    <tr onClick={() => {
                                            this.setOpen(!this.state.open)
                                            this.props.actions.toggleWorkflowTasks(this.props.state.collapse === -1 ? i : -1)
                                        }} style={{marginTop:"30px"}}> 
                                            <td  className="px-4">
                                            <div className="d-flex align-items-center">
                                                <div className="d-30 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 bg-second">
                                                <Icon iclass={team.icon.unicode} iname={team.icon.name} />
                                                </div>
                                                <div>
                                                <div  className="font-weight-bold">{team.title}</div>
                                                <div className="opacity-7"></div>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <div style={styles.counters} >
                                                    <QueuedTaskCount team_id={team.team_id} user_id={user_id} />
                                                </div>
                                            </td>
                                            <td >
                                                <div style={styles.counters} >
                                                    <AssignedTaskCount team_id={team.team_id} user_id={user_id} />
                                                </div>
                                            </td>
                                            <td >
                                                <div style={styles.counters} >
                                                    <CompletedTaskCount team_id={team.team_id} user_id={user_id} />
                                                </div>
                                            </td>
                                            <td>
                                            </td>
                                            
                                            <td className="px-1">
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    className={"custom-svg-icon"}
                                                    onClick={() => {
                                                        this.setOpen(!this.state.open)
                                                        this.props.actions.toggleWorkflowTasks(this.props.state.collapse === -1 ? i : -1)
                                                    }}
                                                >
                                                    {this.props.state.collapse === -1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>     
                                            </td>
                                        </tr>
                           
                        </div>
                        })
                        : '....Loading'    
                    } 
                    </tbody>
                  </Table>
                </div> */}
                
                </>
                      
    }

    getTeams() {
        return !this.props.state.messenger.engine || !this.props.state.user /// !this.props.state.messenger.connected || 
            ?  <Loading className={'workflow-task-loader'} message={this.getLoadingMessage(this.props.state.messenger.connected, this.props.state.messenger.engine, this.props.state.user)} />
            : !this.props.state.user || !this.props.state.teams.length
                ? <Loading message={!this.props.state.teams.length ? 'You dont have team/s with workflow linked' : 'Retrieving your team and task data.'} />
                : <div >
                    <div key={'workflow_queue_task_search_container'} style={styles.search}>
                        <div key={'workflow_task_menu_trigger_icon'} style={styles.triggerIcon}>
                           <Button variant="contained" className="btn-Accept"  
                                startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'note_add'} />}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleWorkflowTriggers()
                                }}
                            > NewTask 
                            </Button>
                        </div>
                        <div key={'workflow_task_menu_timeline_icon'} style={styles.timelineIcon}>
                           <Button variant="contained" className="btn-timelines"
                                startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'timeline'} />}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleWorkflowTimeline('general_workflow_timeline')
                                }}
                                >Timeline 
                            </Button>
                        </div>
                        <div key={'workflow_task_menu_history_icon'} style={styles.timelineIcon}>
                           <Button variant="contained"
                                startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'history'} />}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleWorkflowVehicleHistory('general_workflow_history')
                                }}
                                >History
                             </Button>
                        </div>
                        <div key={'workflow_task_menu_documents_icon'} style={styles.timelineIcon}>
                           <Button variant="contained"
                                startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'file_download'} />}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleWorkflowDocuments('general_workflow_documents')
                                }}
                                >Documents
                            </Button>
                        </div>
                        {/* <div key={'workflow_task_menu_disposal_icon'} style={styles.timelineIconLast}>
                            {
                                this.props.state.accounts.teams.length
                                ? this.props.state.accounts.teams.filter(t => t.title === 'Disposal').length 
                                ?< Button variant="contained" 
                                    startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'call_to_action'} />}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.toggleWorkflowDisposal('general_workflow_disposal')
                                    }}
                                    >{window.t.en('Disposal')}
                                </Button>
                                : '' : ''
                            }
                        </div> */}
                        {/* <div key={'workflow_queue_task_date_search_input'} style={styles.searchDateInput}>
                           <TextField
                                                type="date"
                                style={styles.inputWidth}
                                id={'dt_filter'}
                                hintText='Filter By Date'
                                container='inline'
                                mode='landscape'
                                value={this.props.state.filter_date}
                                onChange={(event, value) => {
                                    this.props.actions.setWorkflowQueueTasksDateSearch(value)
                                }}
                            />
                        </div> */}
                        <div key={'workflow_queue_task_search_input'} style={styles.searchInput}>
                            <TextField
                                onChange={(event) => {
                                    event.preventDefault()
                                    this.props.actions.setWorkflowQueueTasksSearchValue(event.target.value)
                                }}
                                placeholder="Search..."
                                value={this.props.state.value}
                                key={'workflow_queue_task_search_input_text'}
                                hintText={'Search...'}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        this.props.actions.searchWorkflowQueueTasks()
                                    }
                                }}
                            />
                        </div>
                        <div key={'workflow_queue_task_search_icon'} style={styles.searchIcon}>
                            < Button className="btn-Search"
                                variant="contained"
                                icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.searchWorkflowQueueTasks()
                                }}
                                
                            >{<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
                            </Button>
                        </div>
                    </div>
                    <br />
                    <WorkflowTriggers />
                    <WorkflowTimeline key={'general_workflow_timeline'} name={'general_workflow_timeline'} searchable={true} />
                    <WorkflowVehicleHistory key={'general_workflow_history'} name={'general_workflow_history'} searchable={true} />
                    <WorkflowDocuments key={'general_workflow_documents'} name={'general_workflow_documents'} searchable={true} />
                    {/* <WorkflowDisposal key={'general_workflow_disposal'} name={'general_workflow_disposal'} searchable={true} /> */}
                    {
                        this.props.state.teams.map((team, i) => {
                            let active_team = this.props.state.active_team
                            return <div key={'workflowqueuetasks_team_' + team.team_id} className ="teamCardHeaderDiv">
                                {/* <Card initiallyExpanded={active_team ? active_team === team.title : i === 0 ? true : false} style={styles.workflowTaskStepComponentCard}> */}
                                <Card initiallyExpanded={active_team ? active_team === team.title : false}  style={styles.workflowTaskStepComponentCard}>
                                    <CardHeader className="bg-workflowHeaderCard"
                                        title={
                                            <div style={styles.parentTeamDiv}>{team.title}
                                                {/* <div key={'workflow_queue_task_hide_team'} style={styles.hideTeamDiv}>
                                                    <WorkflowTeamHide team_id={team.team_id} user_id={this.props.state.user.user_id} />
                                                </div> */}
                                            </div>
                                        }
                                        subheader={
                                            <div>
                                            <div style={styles.counters} >
                                                <QueuedTaskCount team_id={team.team_id} user_id={this.props.state.user.user_id} />&nbsp;
                                                <AssignedTaskCount team_id={team.team_id} user_id={this.props.state.user.user_id} />&nbsp;
                                                <CompletedTaskCount team_id={team.team_id} user_id={this.props.state.user.user_id} />
                                                <IconButton
                                                    className="teamHeaderExpandButton"
                                                // className={clsx(classes.expand, {
                                                //     [classes.expandOpen]: expanded,
                                                // })} 
                                                onClick={(event, value) => {
                                                    this.props.actions.toggleWorkflowTasks(this.props.state.collapse === -1 ? i : -1)
                                                }}
                                                //aria-expanded={expanded}
                                                aria-label="show more"
                                                >
                                                <ExpandMoreIcon />
                                                </IconButton>
                                            </div>
                                            </div>
                                        }
                                        avatar={
                                            <Icon iclass={team.icon.unicode} iname={team.icon.name} />
                                        }
                                    />
                                    <Collapse in={this.props.state.collapse === i} timeout="auto" unmountOnExit>
                                        <CardContent>
                                        <Container style={{ margin: 0 }} className="custom-container">
                                            <Row>
                                                <Col xl={12} style={{margin: 0, padding: 0}}>
                                                    <Tabs
                                                            initialSelectedIndex={0}
                                                            value={this.props.state.tab}
                                                            inkBarStyle={{
                                                                height: 4
                                                            }}
                                                            indicatorColor="primary"
                                                            onChange={(event, value) => {
                                                                this.props.actions.changeWorkflowTab(value)
                                                            }}
                                                        >
                                                        <Tab className='' style={{minWidth:"50%"}} label={'My Team\'s Queued Tasks'} />
                                                        <Tab style={{minWidth:"50%"}} label={'My Assigned Tasks'} />
                                                    </Tabs>
                                                    <div>
                                                        {this.props.state.tab === 0 && <Box><QueuedTasks team_id={team.team_id} user_id={this.props.state.user.user_id} /></Box>}
                                                        {this.props.state.tab === 1 && <Box><AssignedTasks team_id={team.team_id} user_id={this.props.state.user.user_id} /></Box>}
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col xl={12}>
                                                    <CompletedTasks team_id={team.team_id} user_id={this.props.state.user.user_id} />
                                                </Col>
                                            </Row> */}
                                        </Container>
                                    </CardContent>
                                    </Collapse>
                                </Card>
                            </div>
                        })
                    }
                </div>
    }

    render() {
        return (
            <div>
                <BI
                    displayType={'dashboard'}
                    oq_key={"b3739a49b9fe85b3dfb8c87bb17aaa59f50da1b9383993f5dc93467633d36eca7a97e2c33f061f25a3931d3f363cb7f419a3a8a51bc56efd6a031f0e45d90726"}
                    kpi_view={"get_workflow_tasks_stats3"}
                    oq_theme={""}
                    height={'150px'}
                    noheader={1}
                    isworkflow={'isworkflow'}
                    
                />
                <GeneralContentView
                    headerImage={''}
                    dashboard={''}
                    pad={true}
                    title={window.t.en('')}
                    description={'All teams you are assigned to and their related tasks.'}
                    content={this.getTeamsTable(this.props.state.teams, this.props.state.user.user_id)
                   }
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowQueueTasks,
            messenger: {
                ...state.messenger
            },
            user: {
                ...state.user
            },
            accounts: {
                ...state.accounts
            },
            nav: {
                ...state.navigationMenu
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...messengerActions, ...workflowTimelineActions, ...workflowTriggerActions, ...WorkflowVehicleHistoryActions, ...WorkflowDocumentsActions, ...WorkflowDisposalActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowQueueTasks)