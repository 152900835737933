export const loading = true
export let cisData = null
export let cisMail = null
export let user = null
export let userMessage = ''
export let selectedUser = null
export let cisHistory = null
export let addUser = false
export let removeUser = false
export let removeUserId = null
