import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton'

import ReactTable from "react-table"
import "react-table/react-table.css"
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'


import {ReactComponent as CommentsIcon} from '../../../assets/img/svg/icons/comments.svg'
import {ReactComponent as DecisionIcon} from '../../../assets/img/svg/icons/decision.svg'
import {ReactComponent as DownloadCheckSheetIcon} from '../../../assets/img/svg/icons/download-checksheet.svg'
import {ReactComponent as InvoiceIcon} from '../../../assets/img/svg/icons/invoice.svg'
import {ReactComponent as OfferDetailsIcon} from '../../../assets/img/svg/icons/offer-details.svg'
import {ReactComponent as PriceDetailsIcon} from '../../../assets/img/svg/icons/price-details.svg'
import {ReactComponent as SettlementIcon} from '../../../assets/img/svg/icons/settlement.svg'
import {ReactComponent as VehicleDetailIcon} from '../../../assets/img/svg/icons/vehicle-details.svg'
import {ReactComponent as DisposalIcon} from '../../../assets/img/svg/icons/disposal-grid.svg'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'

import * as actions from './actions'
import * as styles from './styles'
import * as infoSheetActions from '../../InfoSheet/actions'

class DisposalGrid extends Component {

    getCommands(row) {
        let field = this.props.state.fields.filter(x => x.key === true)[0]
        const id = row.original[field.name]
        let i = row.index
        let commands = []

        commands.push(
            <Tooltip arrow title="Vehicle details" placement="top">
            <IconButton key={'command_view_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon('')}
                onClick={
                    () => {
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_view')
                    }
                }>
                {/* <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} /> */}
                <VehicleDetailIcon style={styles.gridOptionsIcon}/>
            </IconButton>
            </Tooltip>
        )
        
        commands.push(
            <Tooltip arrow title="Invoice" placement="top">
            <IconButton key={'command_invoice_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.vehicledocument.filter(x => x.client_id === 147).length)}
                onClick={
                    () => {
                        //console.log('row.originalx inforsheet_invoice', row.original)
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_invoice')
                        ///this.props.actions.getChecksheeet(i)
                    }
                }>
                <InvoiceIcon style={styles.actionIcon(row.original.vehicledocument.filter(x => x.client_id === 147).length)} />
            </IconButton>
            </Tooltip>
        )

        commands.push(
            <Tooltip arrow title="Download checksheet" placement="top">
            <IconButton key={'command_down_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.vehicledocument.filter(x => x.client_id === 147).length)}
                onClick={
                    () => {
                        //console.log('row.originalx inforsheet_checksheet', row.original)
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_checksheet')
                        ///this.props.actions.getChecksheeet(i)
                    }
                }>
                <DownloadCheckSheetIcon style={styles.actionIcon(row.original.vehicledocument.filter(x => x.client_id === 147).length)} />
            </IconButton>
            </Tooltip>
        )
        
        commands.push(
            <Tooltip arrow title="Disposal details" placement="top">
            <IconButton key={'command_disposal_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.absa_residual_value)}
                onClick={
                    () => {
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_disposal')
                    }
                }>
                <DisposalIcon style={styles.actionIcon(row.original.absa_residual_value)}/>
            </IconButton>
            </Tooltip>
        )

        commands.push(
            <Tooltip arrow title="Settlement details" placement="top">
            <IconButton key={'command_settlement_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.funder_settlement_amount)}
            tooltip={'Settlement details'}
                onClick={
                    () => {
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_settlement')
                    }
                }>
                <SettlementIcon style={styles.actionIcon(row.original.funder_settlement_amount)}/>
            </IconButton>
            </Tooltip>
        )

        commands.push(
            <Tooltip arrow title="Comments details" placement="top">
            <IconButton key={'command_forum_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.disposalcomments)}
                onClick={
                    () => {
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_forum')
                    }
                }>
                <CommentsIcon style={styles.actionIcon(row.original.disposalcomments.length)}/>
                <span style={{position: 'absolute', top: -15, left: 1}}>
                    <sup>{row.original.disposalcomments.length ? row.original.disposalcomments.length : ''}</sup>
                </span>
            </IconButton>
            </Tooltip>
        )
        commands.push(
            <Tooltip arrow title="Price details" placement="top">
            <IconButton key={'command_edit_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.marketvalue)}
                onClick={
                    () => {
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_edit')
                    }
                }>
                <PriceDetailsIcon style={styles.actionIcon(row.original.marketvalue)}/>
            </IconButton>
            </Tooltip>
        )

        commands.push(
            <Tooltip arrow title="Offers details" placement="top">
            <IconButton key={'command_offers_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.disposalbidder.length)}
                onClick={
                    () => {
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_offers')
                    }
                }>
                <OfferDetailsIcon tooltip={styles.actionIcon(row.original.disposalbidder.length)} style={styles.actionIcon(row.original.bestoffer)} />
                <div style={{position: 'absolute',  top: -15, left: 1}}>
                    <sup>{row.original.disposalbidder.length ? row.original.disposalbidder.length : ''}</sup>
                </div>
            </IconButton>
            </Tooltip>
        )

        commands.push(
            <Tooltip arrow title="Desicion" placement="top">
            <IconButton key={'command_decision_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.disposal.length ? row.original.disposal.length : null)}
                onClick={
                    () => {
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_decision') //decision
                    }
                }>
                <DecisionIcon style={styles.actionIcon(row.original.disposal.length ? row.original.disposal.length : null)}/>
            </IconButton>
            </Tooltip>
        )
        // commands.push(
        //     <Tooltip arrow title="Documents details" placement="top">
        //     <IconButton key={'command_documents_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon(row.original.vehicledocument.length)}
        //         onClick={
        //             () => {
        //                 this.props.actions.resetInfosheet()
        //                 this.props.actions.toggleInfoSheet(i, 'inforsheet_documents')
        //             }
        //         }>
        //         <Icon istyle={styles.actionIcon(row.original.vehicledocument.length)} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'folder_open'} />
        //         <div style={{position: 'absolute'}}>
        //             <sup>{row.original.vehicledocument.length ? row.original.vehicledocument.length : ''}</sup>
        //         </div>
        //     </IconButton>
        //     </Tooltip>
        // )

        return commands
    }

    getCheckbox(row) {
        let field = this.props.state.fields.filter(x => x.key === true)[0]
        const id = row.original[field.name]
        let i = row.index
        let commands = []

        commands.push(
            <Checkbox
            checked={row.original.checked}
            onCheck={(event,checked) => {
                //console.log(id, checked)
                this.props.actions.setCheckSelection(i, checked)
            }}></Checkbox>
        )

        return commands
    }

    getFormFieldInput(row) {
        let field = this.props.state.fields.filter(x => x.name === row.column.meta)[0]
        let index = row.index

        switch (field.type) {
            case 'date':
            case 'dateTime':
            case 'time':
            case 'timestamp':
                return <div>
                    <TextField
                        key={field.name + '_' + index}
                        //floatingLabelText={field.description}
                        //hintText='Click to select a date...'
                        container='inline'
                        mode='landscape'
                        //defaultDate={new Date()}
                        value={this.data[index][field.name] ? new Date(this.data[index][field.name]) : null}
                        // onChange={
                        //     (event, value) => {
                        //         //console.log('datetime',value)
                        //         this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:value}, index, field.name, value)
                        //     }
                        // }
                    />
                </div>
            case 'boolean':
                return <div>
                    <Checkbox
                        key={field.name + '_' + index}
                        //label={field.hint}
                        labelPosition='right'
                        //defaultToggled={false}
                        style={styles.toggle}
                        trackStyle={{
                            backgroundColor: 'gray'
                        }}
                        trackSwitchedStyle={{
                            backgroundColor: 'green'
                        }}
                        toggled={this.data[index][field.name] === true}
                        // onToggle={
                        //     (event, isInputChecked) => {
                        //         this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:isInputChecked}, index, field.name, isInputChecked)
                        //     }
                        // }
                    />
                </div>

            default:
                return <TextField
                key={field.name + '_' + index}
                //errorText=''
                //hintText={field.hint}
                //floatingLabelText={field.description}
                value={this.data[index][field.name]} 
                //defaultValue={''}
                //style={styles.textBox}
                // onChange={
                //     (event, value) => {
                //         this.props.actions.keyComponentFieldInput(
                //             this.component,
                //             {[pkey]:row.original[pkey],[field.name]:value}, 
                //             index, 
                //             field.name, 
                //             value)
                //     }
                // }
                // onBlur={
                // (event) => {
                //     this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:event.target.value}, index, field.name, event.target.value)
                // }
            //}
            />
            // <input type={"input"} 
            //         style={{
            //             width: '95%',
            //             height: 25,
            //             border: '1px solid #E6E6E6',
            //             borderRadius: 25,
            //             fontSize: 'small'
            //         }} 
            //         key={field.name + '_' + index}
            //         value={this.component.grid.data[index][field.name]} 
            //         onChange={(event) => {
            //                 this.props.actions.keyComponentFieldInput(
            //                     this.component,
            //                     {[pkey]:row.original[pkey],[field.name]:event.target.value}, 
            //                     index, 
            //                     field.name, 
            //                     event.target.value)
            //             }
            //         }
            //         onBlur={
            //         (event) => {
            //             this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:event.target.value}, index, field.name, event.target.value)
            //         }
            //     }/>
        }
          
    }

    getValue(row) {       
        let field = this.props.state.fields.filter(x => x.name === row.column.meta)[0]
            switch (field.type) {
                case 'month':
                    return row.value ? moment(row.value).format('MM') : ''
                    case 'date':
                        return row.value ? moment(row.value).format('YYYY-MM-DD') : ''
                case 'dateTime':
                    return row.value ? moment(row.value).format('YYYY-MM-DD HH:mm ZZ') : ''
                case 'time':
                    return row.value ? moment(row.value).format('HH:mm ZZ') : ''
                case 'timestamp':
                    return row.value ? moment(row.value).format('YYYY-MM-DD HH:mm ZZ') : ''
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
    }

    getFormFieldLabel(row) {
          return <div
          style={{
            width: '100%',
            marginTop: '3px',
            verticalAlign: 'baseline',
            fontSize: '15px'
        }} >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.fields.filter(x => x.key === false)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.display,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                    width: x.width ? x.width : 120,
                    headerStyle: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        //fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.right
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                            x.edit
                            ? this.getFormFieldInput(row)
                            : this.getFormFieldLabel(row)
                    )
                }
            })

            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                width: 350,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
            // columns.unshift({
            //     //Header: '',
            //     accessor: 'options',
            //     filterable: false,
            //     maxWidth: 40,
            //     width: 40,
            //     Cell: (row) => (
            //         this.getCheckbox(row)
            //     )
            // })
        return columns
    }

    getGrid() {
        return <div>
        
            <ReactTable
                data={
                    this.props.state.chip_filter
                    ? this.data.filter(x => x.vehiclestatus_fktext === this.props.state.chip_filter)
                    : this.data
                }
                columns={this.getColumns()}
                manual
                loading={this.loading}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div>
    }

    render() {
        this.data = this.props.state.yardlist

        return this.getGrid()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisposalGrid)