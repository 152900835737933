import moment from 'moment'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

let debitobj = { partialallocationvalue: 0, debit: 0, remaining: 0 }
let creditobj = { partialallocationvalue: 0, credit: 0, remaining: 0 }
let debitallocationaffected = false
let creditallocationaffected = false
let PartialCreditAllocation = []
let PartialDebitAllocation = []


export const getAllocationSupplier = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ALLOCATION_SUPPLIER_DATA,
            payload: search
        })
    }
}

export const getAllocationByRef = (allocation_ref) => {
    return (dispatch, getState) => {
        //console.log('unallocatedPaymentAllocationEpic', data)
        console.log('AllocationByRefx get', allocation_ref)
        dispatch({
            type: types.GET_ALLOCATION_BYREF_SUPPLIER_DATA,
            payload: {
                data: allocation_ref,
            }
        })
    }
}

export const setAllocationByRef = (response) => {
    return (dispatch, getState) => {
        console.log('AllocationByRefx set', response.data)
        for(x of response.data) {
            var data = {
                suppliertransaction_id: x.suppliertransaction_id,
                allocated: false,
                allocationdescription: '',
                allocationamount: 0
            }
            dispatch(unallocatedPaymentAllocationEpic(data))
        }
    }
}

export const unallocatedPaymentAllocationEpic = (data) => {
    return (dispatch, getState) => {
        //console.log('unallocatedPaymentAllocationEpic', data)
        dispatch({
            type: types.SAVE_UNALLOCATION_DATA,
            payload: {
                data: data,
            }
        })
    }
}

export const unallocatedPaymentAllocationEpicDone = () => {
    return (dispatch, getState) => {
        let supplier_id = getState().supplierallocation.supplier_id
        dispatch(getSupplierTransactions(supplier_id))
    }
}

export const setAllocationSupplier = (response) => {
    return (dispatch, getState) => {
        //console.log('setAllocationSupplierx', response)
        dispatch(setInputValue('supplier_data', response.data))
    }
}


export const resetForm = (response) => {
    return (dispatch, getState) => {
        dispatch(setInputValue('transactions', []))
    }
}


export const getSupplierAllocationData = (id, user) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_ALLOCATION_DATA,
            payload: {
                type: "GET_SUPPLIER_ALLOCATION_DATA",
                id: Number(id),
                user: user
            }
        })
    }
}


export const getSupplierTransactions = (supplier_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_TRANSACTIONS,
            payload: {
                supplier_id: supplier_id,
                type: 'supplier_transaction'
            }
        })
    }
}

export const setSupplierTransactions = (response) => {
    return (dispatch, getState) => {
        //console.log('setSupplierTransactionsx', response)
        dispatch(setInputValue('transactions', response))
    }
}


export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_ALLOCATION_INPUT_DATA,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setTransactionInputValue = (inx, value) => {
    return (dispatch, getState) => {
        let transactions = getState().supplierallocation.transactions
        transactions[inx]['toallocate'] = value
        dispatch(setInputValue('transactions',transactions))
    }
}

export const saveSupplierAllocationData = (data, userdata) => {
    debitobj = { partialallocationvalue: 0, debit: 0, remaining: 0 }
    creditobj = { partialallocationvalue: 0, credit: 0, remaining: 0 }
    debitallocationaffected = false
    creditallocationaffected = false
    PartialCreditAllocation = []
    PartialDebitAllocation = []
    return {
        type: types.SAVE_SUPPLIER_ALLOCATION_DATA,
        payload: {
            data: data,
            user: userdata
        }
    }
}
export const savedSupplierAllocationData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_SUPPLIER_ALLOCATION_DATA
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Allocation Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const setSupplierAllocationData = (payload) => {
    return (dispatch, getState) => {
        if (payload.data != null) {
            dispatch(setCalculatedData(payload.data))
        }
        dispatch({
            type: types.SET_SUPPLIER_ALLOCATION_DATA,
            payload: {
                data: payload.data
            }
        })
    }
}
export const setCalculatedData = (payload) => {

    var data = payload
    var unAllocated = 0, total = 0, credit = 0, debit = 0, current = 0, allocated = 0, age30 = 0, age60 = 0, age90 = 0, age120plus = 0
    var allocateAll = false, canallocate = false
    var allocatedcredit = 0, allocateddebit = 0, allocationbalance = 0
    var overallocateddebit = 0
    var unAllocatedcredit = 0
    data.map((x, i) => {
        switch (Number(x.age)) {
            case 0:
                current += Number(x.debit) - Number(x.allocationamount) > 0 ? Number(x.debit) - Number(x.allocationamount) : 0
                overallocateddebit += Number(x.allocationamount) - Number(x.debit) > 0 && Number(x.debit) > 0  ? Number(x.allocationamount) - Number(x.debit) : 0    
                break
            case 30:
                age30 += Number(x.debit) - Number(x.allocationamount) > 0 ? Number(x.debit) - Number(x.allocationamount) : 0
                overallocateddebit += Number(x.allocationamount) - Number(x.debit) > 0 && Number(x.debit) > 0  ? Number(x.allocationamount) - Number(x.debit) : 0    
                break
            case 60:
                age60 += Number(x.debit) - Number(x.allocationamount) > 0 ? Number(x.debit) - Number(x.allocationamount) : 0
                overallocateddebit += Number(x.allocationamount) - Number(x.debit) > 0 && Number(x.debit) > 0  ? Number(x.allocationamount) - Number(x.debit) : 0  
                break
            case 90:
                age90 += Number(x.debit) - Number(x.allocationamount) > 0 ? Number(x.debit) - Number(x.allocationamount) : 0
                overallocateddebit += Number(x.allocationamount) - Number(x.debit) > 0 && Number(x.debit) > 0  ? Number(x.allocationamount) - Number(x.debit) : 0 
                break
            default:
                age120plus += Number(x.debit) - Number(x.allocationamount) > 0 ? Number(x.debit) - Number(x.allocationamount) : 0
                overallocateddebit += Number(x.allocationamount) - Number(x.debit) > 0 && Number(x.debit) > 0  ? Number(x.allocationamount) - Number(x.debit) : 0  
                break
        }
        //credit
        if (x.credit > 0) {            
            credit += Number(x.credit) - Number(x.allocationamount)
            allocatedcredit += x.valueupdated ? Number(x.allocationamount) : 0
            unAllocated += Number(x.allocationamount) == 0 ? Number(x.credit) : Number(x.allocationamount) > 0 ? Number(x.credit) - Number(x.allocationamount) : 0
            //unAllocated += overallocateddebit
        }

        //debit
        if (x.debit > 0) {
            debit += Number(x.debit) - Number(x.allocationamount)
            allocateddebit+= x.valueupdated ? Number(x.allocationamount) : 0
        }

        //credit
        let _partialCreditAllocation = PartialCreditAllocation.filter(function(item, index){
            return PartialCreditAllocation.indexOf(item) >= index;
        })

        //debit
        let _partialDebitAllocation = PartialDebitAllocation.filter(function(item, index){
            return PartialDebitAllocation.indexOf(item) >= index;
        })

        //Credit
        PartialCreditAllocation = _partialCreditAllocation
        if (x.credit > 0 && x.valueupdated) {            
            for(i of _partialCreditAllocation)
            {
                if(i.id == x.suppliertransaction_id && x.allocationamount > 0)
                {
                    allocatedcredit -= (i.value)
                    overallocateddebit += (i.value)
                }
            }
        }

        //Debit
        PartialDebitAllocation = _partialDebitAllocation
        if (x.debit > 0 && x.valueupdated) {            
            for(i of _partialDebitAllocation)
            {
                if(i.id == x.suppliertransaction_id && x.allocationamount > 0)
                {
                    allocateddebit -= (i.value)
                }
            }
        }
        
        //credit
        if (x.credit > 0 && x.fullallocation == 'Partial' && x.valueupdated == false) {
            creditobj.partialallocationvalue = Number(x.allocationamount)
            let cred = {
                id: x.suppliertransaction_id,
                value: Number(x.allocationamount)
            }
            PartialCreditAllocation.push(cred);
            _partialCreditAllocation = PartialCreditAllocation.filter(function(item, index){
                return PartialCreditAllocation.indexOf(item) >= index;
            })
            PartialCreditAllocation = _partialCreditAllocation
        }         
        
        //debit
        if (x.debit > 0 && x.fullallocation == 'Partial' && x.valueupdated == false) {
            debitobj.partialallocationvalue = Number(x.allocationamount)
            let deb = {
                id: x.suppliertransaction_id,
                value: Number(x.allocationamount)
            }
            PartialDebitAllocation.push(deb);
            _partialDebitAllocation = PartialDebitAllocation.filter(function(item, index){
                return PartialDebitAllocation.indexOf(item) >= index;
            })
            PartialDebitAllocation = _partialDebitAllocation
        }         
    })

    total = age120plus + age90 + age60 + age30 + current - unAllocated
    debit = isNaN(debit) ? 0 : debit
    total = isNaN(total) ? 0 : total
    
    allocationbalance =  allocateddebit - (allocatedcredit)
    
    var handledebit = (debit).toString().split('.')
    var handlecredit = (credit).toString().split('.')
    //var handleallocateddebit = (allocateddebit).toString().split('.')
    //var handleallocatedcredit = (allocatedcredit).toString().split('.')
    
    //allocateAll = Number(handlecredit[0]) == Number(handledebit[0]) && (allocateddebit > 0 && allocatedcredit > 0)  ? true : false
    let allocation_val = Math.abs(Number(handlecredit[0]) - Number(handledebit[0]))
    allocateAll = allocation_val <= 1
                    && (allocateddebit > 0 && allocatedcredit > 0)
                    && (allocationbalance).toString().split('.')[0]  == 0
                ? true : false
    //canallocate = (Number(handleallocateddebit[0]) == Number(handleallocatedcredit[0])) && ((allocateddebit > 0 && allocatedcredit > 0)) ? true : false
    //canallocate = (allocationbalance).toString().split('.')[0]  === 0 && (allocateddebit > 0 && allocatedcredit > 0) ? true : false    
    canallocate =  Math.abs((allocateddebit).toString().split('.')[0] - (allocatedcredit).toString().split('.')[0]) === 0  && (allocateddebit > 0 && allocatedcredit > 0) ? true : false    
    //(allocationbalance).toString().split('.')[0]  === 0 && (allocateddebit > 0 && allocatedcredit > 0) ? true : false

    let allocateall_val = (unAllocated + overallocateddebit)
    allocateAll = Math.abs(debit-allocateall_val) <= 1 && (allocationbalance).toString().split('.')[0]  == 0 ? true : allocateAll

    var calcFields = {
        allocationBalance: allocationbalance.toFixed(2).toString(),
        unAllocated: allocateall_val.toFixed(2).toString(),
        total: total.toFixed(2).toString(),
        debit: debit.toFixed(2).toString(),
        age30: age30.toFixed(2).toString(),
        age60: age60.toFixed(2).toString(),
        age90: age90.toFixed(2).toString(),
        age120plus: age120plus.toFixed(2).toString(),
        current: current.toFixed(2).toString(),
        allocateAll: allocateAll,
        canallocate: canallocate
    }

    return (dispatch, getState) => {

        dispatch({
            type: types.SET_ANY_CALCULATED_ALLOCATION_DATA,
            payload: {
                data: calcFields,
                component: 'supplierallocation'
            }
        })
    }
}
export const setFieldAllocation = (values, payload) => {
    var full = 'Full'
    var data = payload.supplierallocation_id.data.map((x, i) => {
        if (x.suppliertransaction_id === values.id) {
            if (values.value != (x.credit ? x.credit : x.debit)) {
                full = 'Partial'
            } else
                if (values.value == (x.credit ? x.credit : x.debit) && values.value > 0) {
                    full = 'Full'
                } else {
                    full = 'None'
                }
        }
        return x.suppliertransaction_id === values.id && (values.field == 'allocationamount' || values.field == "allocated") ?
            {
                ...x,
                valueupdated: true,
                allocationamount: values.value,
                allocated: Number(values.value) > 0 ? true : false,
                fullallocation: full
            }
            : (x.suppliertransaction_id === values.id && values.field == 'allocationreference') ?
                {
                    ...x,
                    allocationreference: values.value,
                } : (x.suppliertransaction_id === values.id && values.field == 'allocationdescription') ?
                    {
                        ...x,
                        allocationdescription: values.value,
                    } : x
    })

    return (dispatch, getState) => {
        if (data != null) {
            dispatch(setCalculatedData(data))
            dispatch({
                type: types.SET_SUPPLIER_ALLOCATION_DATA,
                payload: {
                    data: data
                }
            })
        }

    }
}
export const allocateSelectedTransaction = (transactions) => {
    return (dispatch, getState) => {
        transactions.filter(x => x.toallocate === true).map((x) => {
            let data = {
                suppliertransaction_id: x.suppliertransaction_id,
                allocationamount: Number(x.debit) + Number(x.credit),
                allocated: true,
                allocation: 'F',
                allocated_at:  moment().format('YYYY-MM-DD HH:mm')
            }
            //console.log('allocateSelectedTransaction',data)
            dispatch({
                type: types.UPDATE_SUPPLIER_TRANSACTION_ALLOCATION,
                payload: data
            })
            
            dispatch(unallocatedPaymentAllocationEpicDone())
        })
    }
}

export const updateSupplierTransactionDone = (results) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Allocation Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const allocateAll = (payload, userdata) => {
    var data = payload.map((x, i) => {
        return Number((x.debit ? x.debit : x.credit)) > 0 ?
            {
                ...x,
                valueupdated: true,
                allocationamount: (x.debit ? x.debit : x.credit),
                allocated: true
            }
            : x
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_SUPPLIER_ALLOCATION_DATA,
            payload: {
                data: data,
                user: userdata
            }
        })
        dispatch(setCalculatedData(data))
    }
}
export const excelExport = (component) => {
    return (dispatch, getState) => {
        const header = [{
            key: 'supplierallocationdate',
            name: 'Date',
            default: ' ',
        }, {
            key: 'age',
            name: 'Age',
            default: '0',
        }, {
            key: 'transactiontype',
            name: 'Type',
            default: ' ',
        }, {
            key: 'reference',
            name: 'Reference',
            default: ' ',
        }, {
            key: 'description',
            name: 'Description',
            default: ' ',
        }, {
            key: 'debit',
            name: 'Debit',
            default: '0',
        }, {
            key: 'credit',
            name: 'Credit',
            default: '0',
        }, {
            key: 'allocated',
            name: 'Allocated',
            default: false,
        }, {
            key: 'fullallocation',
            name: 'Allocation',
            default: 'None',
        }, {
            key: 'allocationamount',
            name: 'Allocation Amount',
            default: '0',
        }, {
            key: 'allocationreference',
            name: 'Reference',
            default: '',
        }, {
            key: 'allocationdescription',
            name: 'Description',
            default: '',
        }]

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: component.name,
                data: component.data.supplierallocation_id.data,
                header: header
            }
        })
    }
}
export const getSupplierFilterAllocationsData = (search, id) => {
    return {
        type: types.FILTER_ALLOCATION_DATA,
        payload: {
            search_text: search,
            id: id,
            action: 'allocationview'
        }
    }
}
export const setSupplierFilterAllocationsData = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            age: Number(x.age) * 30,
            allocated: false,
            allocationamount: x.allocationamount,
            credit: x.credit,
            supplierallocation_id: x.suppliertransaction_id,
            supplierallocationdate: x.supplierallocationdate,
            debit: x.debit,
            description: x.description,
            fullallocation: x.allocationamount != (x.credit ? x.credit : x.debit) && x.allocationamount > 0 ? "Partial" : (x.allocationamount == (x.credit ? x.credit : x.debit) && x.allocationamount > 0 ? "Full" : "None"),
            item: x.item,
            reference: x.reference,
            transactiontype: this.getAllocationType(x.transactiontype),
            valueupdated: false,
        }
    })
    return {
        type: types.SET_SUPPLIER_ALLOCATION_DATA,
        payload: {
            data: data
        }
    }
}
export const getAllocationType = (type) => {
    switch (type) {
        case 'IN':
            return 'Invoice'
        case 'RC':
            return 'Receipt'
        case 'JD':
            return 'Journal Debit'
        case 'JC':
            return 'Journal Credit'
        case 'RR':
            return 'Reverse Receipt'
        case 'IT':
            return 'Interest'
        case 'CR':
            return 'Credit Note'
        case 'RI':
            return 'Reverse Interest'
        default:
            return ''
    }
}