import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_ODOMETER_CHANGE_APPROVE_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.RESET_COMPONENT_ODOMETER_CHANGE_FORM_APPROVE:
            return {
                ...state,
                comments:''
            }
        default:
            return state
    }
}