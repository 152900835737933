import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import * as _ from 'lodash'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent, IconButton, Dialog, Button } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import RelatedGrid from '../RelatedGrid/component'

import * as helper from '../../helpers/components'
import Icon from '../Icon/component'
import SurrogateGrid from '../SurrogateGrid/component'
import SurrogateView from '../SurrogateView/component'

import * as actions from '../Form/actions'
import * as styles from './styles'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

class WorkflowViewComponent extends Component {
    componentDidMount() {
        //this.props.actions.loadComponent(this.props.name, this.props.id)
    }

    getLookupView() {
        return <Container style={{ margin: 0 }} className="custom-container">
            {this.component.component_field
                .filter(x => x.grid) // && !x.related)
                .sort((x, y) => x.order - y.order)
                .map((x, i) => {
                    return <Row key={'row_' + x.name + '_' + i}>
                        <Col xl={6} style={styles.selectedFieldName}>
                            {x.title}  :
                        </Col>
                        <Col xl={6}>
                            {
                                x.related
                                    ? (this.component.selected[0])
                                        ? this.component.selected[0].original[x.display.split('.')[0]] ? this.component.selected[0].original[x.display.split('.')[0]][x.display.split('.')[1]] : ''
                                        : this.component.data[x.name].text
                                    : (this.component.selected[0])
                                        ? this.component.selected[0].original[x.name]
                                        : this.component.data[x.name].input
                            }
                        </Col>
                    </Row>
                })
            }
        </Container>
    }

    getInputValue(x, index) {
        if(x.name === "industry_id"){
            //console.log('ilovvereoses x', x)
            //console.log('ilovvereoses x', x.display)
            //console.log('ilovvereoses selected', this.component.selected[0])
            //console.log('ilovvereoses selected value', this.component.selected[0].original[x.display.split('.')[0]])
        }
        const value = x.related
            ? (this.component.selected.length)
                ? this.component.selected[0].original[x.display.split('.')[0]] 
                    ? this.component.selected[0].original[x.display.split('.')[0]][x.display.split('.')[1]] : ''
                : this.component.data[x.name].text
            : (this.component.selected.length)
                ? this.component.selected[0].original[x.name]
                : this.component.data[x.name].input

        switch (x.component_field_display_type.name) {
            
            case 'autoComplete':
                return value
            case 'datePicker':
            case 'timePicker':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : 'Not Set'
            case 'textBox':
                return x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'float'
                    ? value !== null && value.length > 0 && !isNaN(value)
                        ? parseFloat(value).toFixed(2)
                        : value
                    : value
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            case 'toggle':
                const toggle = x.toggle && x.toggle !== null ? x.toggle.split(',') : value
                return value !== null
                    ? value
                        ? toggle[0]
                        : toggle[1]
                    : 'Not Set'
            default:
                return ''
        }
    }

    getFieldValue(field, index) {
        const value = this.component.data[field.name].input

        if (typeof this.component.data[field.name].input === 'object') {
            const child = this.props.state.components[field.name]

            switch (field.component_field_display_type.name) {
                case 'autoComplete':
                    return helper.getComponentTitle(child, value)
                case 'datePicker':
                case 'timePicker':
                    return value !== null
                        ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                        : 'Not Set'
                case 'toggle':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value
                    return value !== null
                        ? value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'html':
                    return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
                case 'textBox':
                    //EUGENE NEEDS TO CHECK THIS, NOT SUPPOSED TO BE TEXT BOX RELATED HERE....
                    return helper.getComponentTitle(child, value)
                default:
                    return ''
            }
        }

        switch (field.component_field_display_type.name) {
            case 'datePicker':
            case 'timePicker':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : 'Not Set'
            case 'textBox':
                return field.component_field_data_type.name === 'decimal' || field.component_field_data_type.name === 'float'
                    ? value !== null && value.length > 0 && !isNaN(value)
                        ? parseFloat(value).toFixed(2)
                        : value
                    : value
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            case 'toggle':
                const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value
                return value !== null
                    ? value
                        ? toggle[0]
                        : toggle[1]
                    : 'Not Set'
            default:
                return ''
        }
    }

    getField(field, index) {
        return this.getFieldValue(field, index)
    }

    getSectionOff(section, index) {
        const fields = !section.feature
            ? section.component_field
                .filter(x => !x.related && !x.key && x.view)
                .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
                .reduce((arr, x, i) => {
                    arr.push(
                            <Col xl={6} style={styles.selectedFieldName}>
                                {x.title + ':'}
                                <strong>{this.getInputValue(x, i)}</strong>
                            </Col>
                    )

                    return arr
                }, [])
            : section.component_field
                .filter(x => !x.related && !x.key && x.view)
                .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
                .reduce((arr, x, i) => {
                    if (this.component.data[x.name].input !== -1) {
                        arr.push(
                                <Col xl={6} style={styles.selectedFieldName}>
                                    {x.title + ':'}
                                <strong>{this.component.data[x.name].input === 1 ? 'Yes' : 'No'}</strong>
                                </Col>
                        )
                    }

                    return arr
                }, [])

        return fields.length
            ? <Card key={'card_' + section.name} initiallyExpanded={true}> {/* initiallyExpanded={index === 0} */}
                <CardHeader
                    // avatar={<Icon iclass={section.icon.unicode} iname={section.icon.name} />}
                    title={section.title}
                    subtitle={section.description}
                    actAsExpander={true}
                    showExpandableButton={true}
                    style={styles.row}
                />
                <CardContent expandable={true}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            {fields}
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            : ''
    }

    getRelatedGrid(field, readOnly) {
        //console.log('getRelatedGrid field',field)
        let _field = this.component.component_field.filter(x => x.key === true)[0]
        //console.log('getRelatedGrid _field',_field)
        let value = null
        
        try {
            value = this.component.data[_field.name].input
        } catch (error) {
            
        }
        
        //console.log('getRelatedGrid value',value)
        return value ? <RelatedGrid
            readonly={readOnly}
            id={value ? value : 0}
            key={field.title + "_" + value}
            name={field.title}
            expanded={true}
            stop={true}
            component={field.title}
            model={{
                id: value,
                model: field.display.split('.')[0],
                related: field.display.split('.').length === 1 ? '' : field.display.split('.')[1],
                component: field.display.split('.').length === 1 ? field.display.split('.')[0] : field.display.split('.')[1],
                primary: this.props.state.component.name
            }}
            data={[]}
        /> : ''
    }
    getSection(section, index) {
        const fields = !section.feature
            ? section.component_field
                .filter(x => !x.key && x.view)
                .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
                .reduce((arr, x, i) => {
                    if(x.component_field_display_type.name === 'grid' && !this.props.surogate){
                        arr.push(<Col key={'col_' + x.name} xl={12}>{this.getRelatedGrid(x, true)}</Col>)
                    }
                    else { 
                    arr.push(
                            <Col className={'custom-col-label'} xl={4}>
                                    <FormControlLabel
                                        fullWidth={true}
                                        control={
                                            <Typography variant="body1" component="div"
                                            style={{fontSize: '14px', fontWeight: '500', textAlign: 'left', width: '100%', fontWeight: 800, padding: '5px 0px'}}>
                                                {this.getInputValue(x, i)} 
                                                {
                                                    x.infoview && x.infoview.indexOf('.') >= 0
                                                    ? <IconButton touch={true} style={{ padding: 0 }}
                                                        onClick={() => {
                                                            const value = this.component.data[x.infoview.split('.')[1]].input
                                                            this.props.actions.toggleInfoView(true, value, x.infoview.split('.')[0],x.hint)
                                                        }}>
                                                        <PlayArrowIcon />
                                                    </IconButton>
                                                    : ''
                                                }
                                            </Typography>
                                        }
                                        label={
                                            <div style={{fontSize: '14px', width: '300px', textAlign: 'left', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
                                                {window.t.en(x.title.length > 40 ? x.title.substring(0,40).concat('...') : x.title)}{": "}
                                            </div>  
                                        }
                                        labelPlacement="top"
                                    />
                            </Col>
                    )
                     }
                    return arr
                }, [])
            : section.component_field
                .filter(x => !x.related && !x.key)
                .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
                .reduce((arr, x, i) => {
                    if(x.component_field_display_type.name === 'grid' && !this.props.surogate){
                        arr.push(<Col key={'col_' + x.name} xl={12}>{this.getRelatedGrid(x, true)}</Col>)
                    }
                    else{ 
                        if (this.component.data[x.name] && this.component.data[x.name].input !== -1) {
                            arr.push(
                                    <Col xl={6}>
                                        <FormControlLabel
                                            fullWidth={true}
                                            control={
                                                <Typography variant="body1" component="div"
                                                style={{fontSize: '14px', fontWeight: '500'}}>
                                                    <strong>{this.component.data[x.name].input === 1 ? 'Yes' : 'No'}</strong>
                                                </Typography>
                                            }
                                            label={
                                                <div style={{fontSize: '14px', width: '300px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
                                                    {window.t.en(x.title)}{": "}
                                                </div>  
                                            }
                                            labelPlacement="start"
                                        />
                                    </Col>
                            )
                        }
                    }

                    return arr
                }, [])

        //console.log("fields" + section.description,section.feature, fields,section.component_field)
        //console.log("fields" + section.description,section.feature, fields,section.component_field)
        return <div style={{boxShadoww: '0 2px 15px 0 rgb(32 40 45 / 13%)'}}>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}><br/></Col>
                </Row>
                <Row>
                    <Col xl={5}><hr style={{border: '5px solid #152461', marginTop: '5px', left: '-60px', position: 'relative', width: '115%'}}/>
                    </Col>
                    <Col xl={7} style={{fontStyle: 'normal', fontWeight: 1000, fontSize: '24px', lineHeight: '20px', color: '#131B3A' }}>{section.description}</Col>
                </Row>
                <Row>
                    <Col xl={12}>&nbsp;</Col>
                </Row>
                <Row>
                    {fields}
                </Row>
            </Container><br/>
        </div>
        // return <Card key={'card_' + section.name} initiallyExpanded={true}> {/* initiallyExpanded={index === 0} */}
        //     <CardHeader
        //         //avatar={<Icon iclass={section.icon.unicode} iname={section.icon.name} />}
        //         title={<div style={{fontSize: '15px'}}>{window.t.en(section.title)}</div>}
        //         subtitle={window.t.en(section.description)}
        //         actAsExpander={true}
        //         showExpandableButton={true}
        //         style={styles.row}
        //         action={ 
        //             section.infosheet 
        //             ? <IconButton touch={true} style={{float: 'right', color: '#fff'}}
        //              onClick={() => {
        //                  this.props.actions.toggleSectionInfosheet(!this.props.state.section_infosheet,section.display_type,section.infosheet_key)
        //              }}>
        //                  {this.props.state.section_infosheet ? <ExpandLess style={{color: '#fff'}} /> : <ExpandMore style={{color: '#fff'}} />}
        //              </IconButton>
        //              : ''
        //           }
        //     />
        //     <CardContent expandable={true}>
        //         <Container style={{ margin: 0 }} className="custom-container">
        //             <Row className={'custom_view_text'}>
        //             {fields}</Row>
        //         </Container>
        //     </CardContent>
        // </Card>
    }

    getSectionView() {
        return this.data
            ? this.component.sections
                .sort((x, y) => x.order - y.order)
                .map((x, i) => {
                    return this.getSection(x, i)
                })
            : 'Loading...'
    }

    getSurrogateView(filter, component, key, id) {
        return <SurrogateView
            id={id}
            name={component.name}
            expanded={true}
            stop={true}
            component={component}
        />
    }

    // getSurrogateGrid(filter, component, key, id, data) {
    //     window.glyco.log('getSurrogateGrid')
    //     window.glyco.log(component)
    //     window.glyco.log(id)
    //     window.glyco.log(key)
    //     window.glyco.log(data)
    //     window.glyco.log(filter)
    //     return <SurrogateGrid
    //         id={id}
    //         name={component.name}
    //         expanded={true}
    //         stop={true}
    //         component={component}
    //         data={data}
    //     />
    // }

    getContactView(data) {

        window.glyco.log('getContactView')
        window.glyco.log(data)
        return data.map((x, i) => {
            return <Card key={'contact_card_' + x.contact_id} initiallyExpanded={true}> {/* initiallyExpanded={index === 0} */}
                <CardHeader
                    title={window.t.en('Contact')}
                    subtitle={x.contacttype + ': Contact Detail'}
                    actAsExpander={true}
                    showExpandableButton={true}
                    style={styles.row}
                />
                <CardContent expandable={true}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row key={'row_' + i} className={'interactiveRow'}>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {'Contact Person:'}
                            </Col>
                            <Col>
                                {x.fullname}
                            </Col>
                        </Row>
                        <Row key={'row_' + i} className={'interactiveRow'}>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {'Mobile Number:'}
                            </Col>
                            <Col>
                                {x.mobile}
                            </Col>
                        </Row>
                        <Row key={'row_' + i} className={'interactiveRow'}>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {'Telephone Work:'}
                            </Col>
                            <Col>
                                {x.telephone_work}
                            </Col>
                        </Row>
                        <Row key={'row_' + i} className={'interactiveRow'}>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {'Email Address:'}
                            </Col>
                            <Col>
                                {x.email}
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        })
    }

    getAddressView(data) {

        window.glyco.log('getAddressView')
        window.glyco.log(data)
        return data.map((x, i) => {
            return <Card key={'address_card_' + x.address_id} initiallyExpanded={true}> {/* initiallyExpanded={index === 0} */}
                <CardHeader
                    title={window.t.en('Address')}
                    subtitle={window.t.en('Address Detail')}
                    actAsExpander={true}
                    showExpandableButton={true}
                    style={styles.row}
                />
                <CardContent expandable={true}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row key={'row_' + i} className={'interactiveRow'}>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {'Pysical Address:'}
                            </Col>
                            <Col>
                                {x.line1}
                            </Col>
                        </Row>
                        <Row key={'row_' + i} className={'interactiveRow'}>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {'Postal Address:'}
                            </Col>
                            <Col>
                                {x.line2}
                            </Col>
                        </Row>
                        <Row key={'row_' + i} className={'interactiveRow'}>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {'Postal Code:'}
                            </Col>
                            <Col>
                                {x.postalcode}
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        })
    }

    getSectionRelatedComponents() {
        if (!this.component.data) {
            window.glyco.log('this.component.data')
            return []
        }

        if (this.component.stop) {
            window.glyco.log('this.component.stop')
            return []
        }

        if (this.component.view === null) {
            window.glyco.log('this.component.view')
            return []
        }

        const relations = this.component.view.split(',')


        return relations.map(x => {
            let surrogate = false
            let relation, parent, child


            if (x.indexOf('.') > -1) {
                relation = x.split('.')
                parent = relation[0]
                child = relation[1]

                // window.glyco.log('parent child')
                // window.glyco.log(parent)
                // window.glyco.log(child)

                // if (!this.component.data[parent]) {
                //     return []
                // }

                surrogate = true
            }
            else {
                if (!this.component.data[x]) {
                    return []
                }
            }

            x = surrogate ? parent : x
         

            //const component = this.props.state.components[surrogate ? child : x]

            //const key = component.component_field.filter(y => y.key)[0]
        
            //const id = key.name  //this.props.state.components[surrogate ? child : x].data[key.name]


            //let data = surrogate ? _.get(this.component.data, x) : []

            const surrogateData = this.component.selected[0].original[parent].map(x => {
                return x[child]
            })

            // window.glyco.log('child')
            // window.glyco.log(child)
            // window.glyco.log(surrogateData)

            if(child === 'contact')
                return this.getContactView(surrogateData)
            else if(child === 'address')
                return this.getAddressView(surrogateData)
                else return ''
                //: this.getSurrogateView(x, component, key, id)
        })
    }

    render() {
        this.component = this.props.state.component

        this.data = this.props.data

        return this.data ? this.getSectionView() : 'Leading...'
            //this.getSectionRelatedComponents()
        // return (
        //     this.data
        //         ? this.getSectionView()
        //         : 'Loading...'
        // )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            component: {
                ...state.components[ownProps.name]
            },
            components: {
                ...state.components
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowViewComponent)