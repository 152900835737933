export const EMPTY_TYPE = 'EMPTY_TYPE'
export const SET_EXCEL_FILE_IMPORTED_DATA_ROWS = 'SET_EXCEL_FILE_IMPORTED_DATA_ROWS'
export const GET_EXCEL_FILE_TEMPLATE_DATA_LIST = 'GET_EXCEL_FILE_TEMPLATE_DATA_LIST'
export const SET_EXCEL_FILE_TEMPLATE_DATA_LIST = 'SET_EXCEL_FILE_TEMPLATE_DATA_LIST'
export const SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA = 'SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA'
export const SET_UPLOADED_EXCEL_FILE_NAME = 'SET_UPLOADED_EXCEL_FILE_NAME'
export const SET_UPLOADED_EXCEL_FILE_BUSY = 'SET_UPLOADED_EXCEL_FILE_BUSY'
export const UPLOAD_EXCEL_FILE_DATA_DONE = 'UPLOAD_EXCEL_FILE_DATA_DONE'
export const UPLOAD_EXCEL_FILE_DATA = 'UPLOAD_EXCEL_FILE_DATA'
export const UPLOAD_FINANCE_IMPORT_FILE_DATA = 'UPLOAD_FINANCE_IMPORT_FILE_DATA'
export const SET_INPUT = 'SET_INPUT'



