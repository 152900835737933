import * as config from '../../config'
import * as actions from './actions'
import * as requestActions from '../Request/actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as routerActions from '../Redirect/actions'
import * as type from './types'
import * as requestTypes from '../Request/types'

export const connectorEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(type.GET_CONNECTOR),
        mergeMap(() =>
            ajax$(
                config.system.api + '/connector'
                ).pipe(
                    mergeMap(payload => [
                    actions.setConnector(payload.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/connector', payload.response)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/connector', error)))
                ////,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )