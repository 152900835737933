import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Tabs, Box, Tab, Button, List, ListItem, Tooltip, Dialog, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import TextField from '../../../controls/textField'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import DateControl from '../../../controls/dateControl'
import ViewSupplier from '../../../controls/view_supplier'

import * as actions from './actions'
import * as styles from './styles'

import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

class VehicleMasterCapture extends Component {
    componentDidMount() {
        if (this.props.mode === "confirm") {
            this.props.actions.setupWorkflowTaskInput(this.props.state, [], 'vehicle_pricing', 'tsk_vehicle_pricing_capture')
        }
        else {
            this.props.actions.setValidationState('vehicleMasterCapture')
            // let supplier = this.props.data[0].workflow_task_step_component[0].data[0]
            this.props.actions.resetForm()
            // this.props.actions.setInputValue('supplier', supplier)
        }
    }

    getSupplier() {
        return <div>
            <Card
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Selected vehicle details ')}
                content={
                    <Fields nocard={true}
                        fields={[
                            { size: 4, label: 'MM Code:', value: this.mmcode.mmcode },
                            { size: 4, label: 'Model Code:', value: this.mmcode.modelcode },
                            { size: 4, label: 'Vehicle Make:', value: this.mmcode.make },
                            { size: 4, label: 'Vehicle Model:', value: this.mmcode.model },
                            { size: 4, label: 'Vehicle Type:', value: this.mmcode.vehicletype },
                            { size: 4, label: 'Registration Year:', value: this.mmcode.regyear },
                            { size: 4, label: 'Introduction Type:', value: this.mmcode.introdate },
                            { size: 4, label: 'Discontinue Date:', value: this.mmcode.discdate },
                            { size: 4, label: 'Transmission:', value: this.mmcode.manualauto },
                            { size: 4, label: 'Contact Type:', value: this.mmcode.fueltype },
                            { size: 4, label: 'Body Type:', value: this.mmcode.bodytype },
                            { size: 4, label: 'Cost Price:', value: this.mmcode.cprice },
                            { size: 4, label: 'Discount Price:', value: this.mmcode.dprice },
                            { size: 4, label: 'Vehicle Price:', value: this.mmcode.amount },
                        ]}
                    />
                }
            />
        </div>
    }

    getPricing() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} key={'col_add'}>
                        <Button
                            onClick={(event) => {
                                this.props.actions.addPricing()
                            }}
                            className="btn-pill-off">
                            <span className="btn-wrapper--label">Add Pricing</span>
                        </Button>
                    </Col>
                    <Col xl={10} ></Col>
                    <Col xl={12}>
                        {
                            this.props.state.pricing.length
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold-">
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{ zIndex: 1 }}>
                                        {
                                            this.props.state.pricing.map((x, inx) => {

                                                return <tr style={{ paddingBottom: '10px' }}>
                                                    <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                x.editing
                                                                    ? <Row>
                                                                        <Col xl={3} style={{ paddingTop: '5px' }}>
                                                                            <AutoComplete
                                                                                name={'field_' + inx}
                                                                                title={'Select supplier for pricing'}
                                                                                data={this.props.state.supplier_data}
                                                                                value={x.suppliername}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                fieldvalue={'supplier_id'}
                                                                                displayfields={['suppliername', 'accountnumber', 'address_suburb']}
                                                                                displaywidth={['50', '20', '30']}
                                                                                disabled={x.suppliername ? true : false}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setPricingInputValue({ inx: inx, prop: 'suppliername', value: args.text })
                                                                                    this.props.actions.setPricingInputValue({ inx: inx, prop: 'supplier_id', value: args.value })
                                                                                }}
                                                                                onClick={() =>
                                                                                    this.props.actions.getLookupData('supplier', 'suppliername', '')
                                                                                }
                                                                                onFilter={(args) =>
                                                                                    this.props.actions.getLookupData('supplier', 'suppliername', args)
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '0px' }}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'costprice' + inx}
                                                                                label={'Cost Price'}
                                                                                value={x.costprice}
                                                                                style={{ marginTop: 0 }}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setPricingInputValue({ inx: inx, prop: 'costprice', value: event.target.value })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '0px' }}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'sellingprice' + inx}
                                                                                label={'Selling Price'}
                                                                                value={x.sellingprice}
                                                                                style={{ marginTop: 0 }}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setPricingInputValue({ inx: inx, prop: 'sellingprice', value: event.target.value })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '0px' }}>
                                                                            <DateControl
                                                                                id={'expirydate' + inx}
                                                                                key={'expirydate' + inx}
                                                                                label={'Expiry Date'}
                                                                                value={x.expirydate}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setPricingInputValue({ inx: inx, prop: 'expirydate', value: args })
                                                                                }}
                                                                                type={'date'}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '20px' }}>
                                                                            <AutoComplete
                                                                                name={'field_' + inx}
                                                                                title={'Select VAT Type'}
                                                                                data={this.props.state.vatable_data}
                                                                                value={x.vatable}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setPricingInputValue({ inx: inx, prop: 'vatable', value: args.text })
                                                                                }}
                                                                                onClick={() => { }}
                                                                                onFilter={(search) => {
                                                                                    if (search) {
                                                                                        this.props.actions.setPricingInputValue({ inx: inx, prop: 'vatable', value: search })
                                                                                    } else {
                                                                                        this.props.actions.setPricingInputValue({ inx: inx, prop: 'vatable', value: '' })
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={1}>
                                                                            <div style={{ marginTop: '20px' }}>
                                                                                <IconButton
                                                                                    key={'command_add_supplier_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                    onClick={
                                                                                        () => {
                                                                                            this.props.actions.setPricingInputValue({ inx: inx, prop: 'editing', value: false })
                                                                                        }
                                                                                    }>
                                                                                    <SaveIcon />
                                                                                </IconButton>
                                                                                &nbsp;&nbsp;
                                                                                <IconButton
                                                                                    key={'command_remove_supplier_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                    onClick={
                                                                                        () => {
                                                                                            this.props.actions.setPricingInputValue({ inx: inx, prop: 'suppliername', value: '' })
                                                                                            this.props.actions.setPricingInputValue({ inx: inx, prop: 'costprice', value: '' })
                                                                                            this.props.actions.setPricingInputValue({ inx: inx, prop: 'sellingprice', value: '' })
                                                                                            this.props.actions.setPricingInputValue({ inx: inx, prop: 'expirydate', value: '' })
                                                                                            this.props.actions.setPricingInputValue({ inx: inx, prop: 'vatable', value: '' })
                                                                                            // this.props.actions.setPricingInputValue({inx: inx, prop: 'editing', value: false})
                                                                                            this.props.actions.delPricing(inx)
                                                                                        }
                                                                                    }>
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                        </Col>
                                                                        {
                                                                            x.suppliername
                                                                                ? <>
                                                                                    <Col xl={12} style={{ paddingTop: '15px' }}>
                                                                                        <hr />
                                                                                        <h6>Link Customers To This Pricing</h6>
                                                                                        <ul>
                                                                                            <li>Select customer from the list to link  to this pricing</li>
                                                                                        </ul>
                                                                                    </Col>
                                                                                    <Col xl={6} style={{ paddingTop: '15px' }}>
                                                                                        <strong>Select Customer</strong>
                                                                                    </Col>
                                                                                    <Col xl={6} style={{ paddingTop: '15px' }}>
                                                                                        <strong>Selected Customers</strong>
                                                                                    </Col>
                                                                                    <Col xl={5} style={{ paddingTop: '15px' }}>
                                                                                        <AutoComplete
                                                                                            name={'field_' + inx}
                                                                                            title={''}
                                                                                            data={this.props.state.customer_data}
                                                                                            value={x.customername}
                                                                                            dataSourceConfig={{
                                                                                                text: 'text',
                                                                                                value: 'value'
                                                                                            }}
                                                                                            fieldtext={'customername'}
                                                                                            fieldvalue={'customer_id'}
                                                                                            displayfields={['customername', '', 'costcentre']}
                                                                                            displaywidth={['50', '10', '20']}
                                                                                            onSelect={(args) => {
                                                                                                let customer = this.props.state.customer_data.filter(x => x.customer_id === args.value)
                                                                                                customer = customer.length ? customer[0] : null
                                                                                                if (customer) {
                                                                                                    this.props.actions.addCustomerToPricing({ inx: inx, prop: 'customers', value: customer })
                                                                                                }
                                                                                            }}
                                                                                            onClick={() =>
                                                                                                this.props.actions.getLookupData('customer', 'customername', '')
                                                                                            }
                                                                                            onFilter={(search) => {
                                                                                                if (search) {
                                                                                                    this.props.actions.getLookupData('customer', 'customername', search)
                                                                                                } else {
                                                                                                    this.props.actions.getLookupData('customer', 'customername', '')
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col xl={1} style={{ paddingTop: '15px' }}></Col>
                                                                                    <Col xl={6} style={{ paddingTop: '15px' }} className="clearFix">
                                                                                        {
                                                                                            x.customers
                                                                                                ? <Container className="table table-alternate-spaced mb-0">
                                                                                                    {
                                                                                                        x.customers.map((cus, i) => {
                                                                                                            return <Row style={{ border: 'none !important' }}>
                                                                                                                <Col>
                                                                                                                    <IconButton key={'command_customer_delete_' + i} iconStyle={styles.action}
                                                                                                                        onClick={
                                                                                                                            () => {
                                                                                                                                this.props.actions.delCustomerFromPricing(inx, i)
                                                                                                                            }
                                                                                                                        }>
                                                                                                                        <DeleteForeverIcon style={{ color: 'red' }} />
                                                                                                                    </IconButton>
                                                                                                                </Col>
                                                                                                                <Col style={{ border: 'none !important', background: 'none !important' }}>{cus.customername}</Col>
                                                                                                                <Col style={{ border: 'none !important', background: 'none !important' }}>{cus.accountnumber}</Col>
                                                                                                                <Col style={{ border: 'none !important', background: 'none !important' }}>{cus.costcentre}</Col>
                                                                                                            </Row>
                                                                                                        })
                                                                                                    }
                                                                                                </Container>
                                                                                                : ''
                                                                                        }
                                                                                    </Col>
                                                                                </>
                                                                                : ''
                                                                        }
                                                                    </Row>
                                                                    : <Row>
                                                                        <Col xl={3}>
                                                                            {x.suppliername}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.costprice}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.sellingprice}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {moment(x.expirydate).format('YYYY-MM-DD')}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.vatable}
                                                                        </Col>
                                                                        <Col xl={1}>
                                                                            {
                                                                                <div>
                                                                                    <IconButton key={'command_edit_' + x.inx} style={styles.delButton} iconStyle={styles.action}
                                                                                        onClick={
                                                                                            () => {
                                                                                                this.props.actions.setPricingInputValue({ inx: inx, prop: 'editing', value: true })
                                                                                            }
                                                                                        }>
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                    &nbsp;&nbsp;
                                                                                    <IconButton key={'command_delete_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                        onClick={
                                                                                            () => {
                                                                                                this.props.actions.delPricing(inx)
                                                                                            }
                                                                                        }>
                                                                                        <DeleteForeverIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                            }
                                                        </Container>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    }

    reviewPricing() {
        return <div>
            {
                this.props.state.pricing.length
                    ? <Table className="table table-alternate-spaced mb-0" >
                        <tbody style={{ zIndex: 1 }}>
                            {
                                this.props.state.pricing.map((x, inx) => {

                                    return <tr>
                                        <td>
                                            <Container style={{ margin: 0 }} className="custom-container">
                                                <Row>
                                                    <Col xl={3} style={{ paddingTop: '15px' }}> <strong>Supplier Name</strong> </Col>
                                                    <Col xl={2} style={{ paddingTop: '15px' }}> <strong>Cost Price</strong> </Col>
                                                    <Col xl={2} style={{ paddingTop: '15px' }}> <strong>Selling Price</strong> </Col>
                                                    <Col xl={2} style={{ paddingTop: '15px' }}> <strong>Price Expiry Date</strong> </Col>
                                                    <Col xl={2} style={{ paddingTop: '15px' }}> <strong>Price Vatable</strong> </Col>
                                                </Row>
                                                {
                                                    <Row>
                                                        <Col xl={3} style={{ paddingTop: '15px' }}>
                                                            {x.suppliername}
                                                        </Col>
                                                        <Col xl={2} style={{ paddingTop: '15px' }}>
                                                            {x.costprice}
                                                        </Col>
                                                        <Col xl={2} style={{ paddingTop: '15px' }}>
                                                            {x.sellingprice}
                                                        </Col>
                                                        <Col xl={2} style={{ paddingTop: '15px' }}>
                                                            {moment(x.expirydate).format('YYYY-MM-DD')}
                                                        </Col>
                                                        <Col xl={2} style={{ paddingTop: '15px' }}>
                                                            {x.vatable}
                                                        </Col>
                                                        <Col xl={1}>
                                                        </Col>
                                                        {
                                                            x.suppliername
                                                                ? <div style={{ width: '100%' }}>
                                                                    <hr />
                                                                    <Col xl={12} style={{ paddingTop: '15px', borderTop: '1px solid gray' }}>
                                                                        <h5>Selected Customers</h5>
                                                                    </Col>
                                                                    <Col xl={12} style={{ paddingTop: '15px' }} className="clearFix">
                                                                        {
                                                                            x.customers
                                                                                ? <Container className="table table-alternate-spaced mb-0">
                                                                                    <Row style={{ background: '#d0d0d0' }}>
                                                                                        <Col xl={4} style={{ paddingTop: '15px' }}>
                                                                                            <strong>Selected Name</strong>
                                                                                        </Col>
                                                                                        <Col xl={4} style={{ paddingTop: '15px' }}>
                                                                                            <strong>Account Number</strong>
                                                                                        </Col>
                                                                                        <Col xl={4} style={{ paddingTop: '15px' }}>
                                                                                            <strong>Costcentre </strong>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    {
                                                                                        x.customers.map((cus, i) => {
                                                                                            return <Row style={{ border: 'none !important' }}>
                                                                                                <Col xl={4} style={{ border: 'none !important', background: 'none !important' }}>{cus.customername}</Col>
                                                                                                <Col xl={4} style={{ border: 'none !important', background: 'none !important' }}>{cus.accountnumber}</Col>
                                                                                                <Col xl={4} style={{ border: 'none !important', background: 'none !important' }}>{cus.costcentre}</Col>
                                                                                            </Row>
                                                                                        })
                                                                                    }
                                                                                </Container>
                                                                                : ''
                                                                        }
                                                                    </Col>
                                                                </div>
                                                                : ''
                                                        }
                                                    </Row>
                                                }
                                            </Container>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                    : <h4></h4>
            }
        </div>
    }

    getDocuments() {
        return <div>
            <Upload
                id={'supplier'}
                prefix={'Supplier'}
                header={false}
            /></div>
    }

    getCapture() {
        return <div>
            {this.getSupplier()}
            <Card
                title={"Vehicle Pricing List"}
                subtitle={window.t.en('Vehicle dealer pricing list')}
                content={this.getPricing()}
            />
            {/* <Card
                title={"Supplier Sundries"}
                subtitle={window.t.en('Supplier sundries pricing')}
                content={this.getSundries()}
            /> 
            <Card
                title={"Supplier Services"}
                subtitle={window.t.en('Supplier services pricing')}
                content={this.getServices()}
            />  */}
            <Card
                title={"Support Documents"}
                subtitle={window.t.en('Upload supplier supporting documents')}
                content={this.getDocuments()}
            />
        </div>
    }


    getConfirm() {
        return <div>
            {this.getSupplier()}
            <Card
                title={"Vehicle Pricing"}
                subtitle={window.t.en('Vehicle pricing profile')}
                content={this.reviewPricing()}
            />
            {/* <Card
            title={"Supplier Sundries"}
            subtitle={window.t.en('Supplier sundries pricing')}
            content={<Fields data={this.props.state.sundries} props={['description','costprice','sellingprice','expirydate#','vatable',]} columns={['Sundry Name', 'Cost Price', 'Selling Price', 'Expiry Date', 'Vatable']} />}
        /> 
        <Card
            title={"Supplier Services"}
            subtitle={window.t.en('Supplier services pricing')}
            content={<Fields data={this.props.state.services} props={['servicedescription','costprice','sellingprice','expirydate#','vatable',]} columns={['Service Name', 'Cost Price', 'Selling Price', 'Expiry Date', 'Vatable']} />}
        />  */}
            <Card
                title={"Supplier Documents"}
                subtitle={window.t.en('Uploaded supplier supporting documents')}
                content={<PdfViewer
                    key={'supplier_pricing'}
                    name={'supplier_pricing'}
                    documents={this.props.state.documents.documents}
                    edit={false}
                    multiple={false}
                    workflow_queue_data={null}
                />}
            />
        </div>
    }

    getLayout() {
        return <div>{
            this.props.mode === 'confirm'
                ? this.getConfirm()
                : this.getCapture()
        }
        </div>
    }

    render() {
        this.mmcode = this.props.data[0].workflow_task_step_component[0].data[0]
        //console.log('VehicleMasterCapturex this.mmcode',this.mmcode)
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.vehicleMasterCapture,
            documents: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;
    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleMasterCapture)