import * as types from './types'
import moment from 'moment'

export const setupWorkflowTaskInput = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACCESSORY_SUPPLIERS_LIST_LOOKUP_DATA,
            payload: {
                component: 'surrogateitems',
                data: data.accessory ? data.accessory : [],
            }
        })
        
        dispatch({
            type: types.SET_PO_SELECTED_LOOKUP_DATA,
            payload: {
                component: 'purchaseorder',
                field: 'vehicle_id',
                data: data,
            }
        })
    }
}

export const searchLookupValue = (component, field, search, related) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ACCESSORY_SUPPLIERS_LIST_LOOKUP_DATA,
            payload: {
                component: component.name, 
                field: field,
                query: '?' + field.display.split('.')[1] + '=' + search,
                related: related
            }
        })
    }
}


export const searchBranchLookupValue = (component, xfield, field, search, related) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ACCESSORY_SUPPLIERS_LIST_LOOKUP_DATA,
            payload: {
                component: component.name, 
                field: field,
                query: '?' + xfield.display.split('.')[1] + '=' + search,
                related: related
            }
        })
    }
}

export const setLookupValue = (component, field, value, text) => {
    
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACCESSORY_SUPPLIERS_SELECTED_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })
        
        const data = component.data[field.name].data.filter(x => x[field.name] === value)

        dispatch({
            type: types.SET_PO_SELECTED_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data,
            }
        })
    }
}


export const setDealerLookupValue = (component, field, value, text) => {
    
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACCESSORY_SUPPLIERS_SELECTED_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })
        
        const data = component.data[field.name].data.filter(x => x.supplier_id === value)


        window.glyco.log('setDealerLookupValue')
        window.glyco.log(value)
        window.glyco.log(data)
        dispatch({
            type: types.SET_PO_SELECTED_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data,
            }
        })
    }
}


export const setComments = (component, field, comments) => {
    return (dispatch, getState) => {
        const data = component.data[field.name].data
        window.glyco.log('setComments')
        window.glyco.log(data)
        window.glyco.log(data[0].notes)
        window.glyco.log(comments.name)
        window.glyco.log(comments.component.name)

        dispatch({
            type: types.SET_QUITE_PO_FIELD_VALUE,
            payload: {
                component: comments.component.name,
                field: comments.name,
                value: data[0].notes,
            }
        })
    }
}


export const setInstruction = (component, field, comments, value) => {
    return (dispatch, getState) => {
        const data = component.data[field.name].data.filter(x => x.supplier_id === value)
        window.glyco.log('setInstruction')
        window.glyco.log(component.data[field.name])
        window.glyco.log('supplieraddress',data)
        // window.glyco.log(data[0].supplieraddress[0].address.line1)

        dispatch({
            type: types.SET_QUITE_PO_FIELD_VALUE,
            payload: {
                component: comments.component.name,
                field: comments.name,
                value: data.length ? data[0].supplieraddress[0].address.line1 : '',
            }
        })
    }
}
export const getSupplierContats = (component, field, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACCESSORY_SUPPLIERS_SELECTED_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })
        const data = component.data[field.name].data.filter(x => x[field.name] === value)

        dispatch({
            type: types.SET_PO_SELECTED_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data,
            }
        })

        dispatch({
            type: types.GET_SELECTED_SUPPLIER_CONTACTS,
            payload: {
                component: 'suppliercontact',
                related: 'contact',
                value: value
            }
        })
    }
}

export const setSupplierContats = (payload) => {
  
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACCESSORY_SUPPLIERS_LIST_LOOKUP_DATA,
            payload: {
                component: 'contact',
                data: payload.data.length ? [payload.data[0].contact] : []
            }
        })
    }
}

export const setSuppliersList = (field, payload) => {
    return (dispatch, getState) => {
        const data = {
            component: field.component.name,
            field: field.name,
            data: payload.data,
        }


        
        dispatch({
            type: types.SET_PO_SELECTED_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: payload.data,
            }
        })
    }
}

export const setValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUITE_PO_FIELD_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })
    }
}

export const setAccessorySupplierLookupValue = (component, inx, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUITE_PO_ACCESSORY_SUPPLUERFIELD_VALUE,
            payload: {
                component: component.name,
                index: inx,
                value: value,
                text: text,
            }
        })
    }
}

export const setAcceptedDocuments = (component, documents) => {
    const rfq = 'signed_quotation_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_RFQ_QUOTE_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: rfq,
                        type: file.type,
                    }
                })
            };
        });
        dispatch({
            type: types.SET_QUITE_PO_FIELD_VALUE,
            payload: {
                component: 'surrogateheader',
                field: 'rfq',
                value: rfq
            }
        })

        dispatch({
            type: types.SET_RFQ_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: component.name,
                preview: documents[0].preview,
                filename: rfq,
            }
        })
    }
}

export const setMotivationDocuments = (component, documents) => {
    const rfq = 'additional_document_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_RFQ_QUOTE_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: rfq,
                        type: file.type,
                    }
                })
            };
        });
        // dispatch({
        //     type: types.SET_QUITE_PO_FIELD_VALUE,
        //     payload: {
        //         component: 'surrogateheader',
        //         field: 'rfq',
        //         value: rfq
        //     }
        // })

        dispatch({
            type: types.SET_MOTIVATION_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: component.name,
                preview: documents[0].preview,
                filename: rfq,
            }
        })
    }
}


export const setRejectedDocuments = (component, documents) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: component.name
            }
        })
    }
}


export const clearMotivationDocuments = (component) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_MOTIVATION_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: component.name
            }
        })
    }
}

export const clearRFQDocument = (component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: 'document',
                field: 'content',
                value: '',
            }
        })
    }
}