export const GET_COMPLETED_POS = 'GET_COMPLETED_POS'
export const APPROVE_PO = 'APPROVE_PO'
export const REJECT_PO = 'REJECT_PO'
export const UPLOAD_PO_DOCUMENTS = 'UPLOAD_PO_DOCUMENTS'
export const GET_POSUPPLIERLIST = 'GET_POSUPPLIERLIST'
export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const REFRESH_INVAMT = 'REFRESH_INVAMT'
export const REFRESH_INVNO = 'REFRESH_INVNO'
export const UPDATE_VALUE = 'UPDATE_VALUE'
export const TOGGLE_ITEM = 'TOGGLE_ITEM'
export const GET_BUSINESSLIST = 'GET_BUSINESSLIST'
export const SET_BUSINESSLIST = 'SET_BUSINESSLIST'
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'
export const GET_PAYMENT_DOCUMENT = 'GET_PAYMENT_DOCUMENT'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const ADD_BLANK = 'ADD_BLANK'
export const UPDATE_ITEM_VALUE = 'UPDATE_ITEM_VALUE'
export const APPROVE_ITEM = 'APPROVE_ITEM'
export const PROCESS_PAYMENT = 'PROCESS_PAYMENT'
export const GENERATE_PAYMENT_NOTIFICATION = 'GENERATE_PAYMENT_NOTIFICATION'
export const APPROVE_ITEM_GM = 'APPROVE_ITEM_GM'
export const GET_SUPPLIER_TRANSACTION_DOCUMENT = 'GET_SUPPLIER_TRANSACTION_DOCUMENT'
export const GET_PAYMENT_DOCUMENT_BY_ID = 'GET_PAYMENT_DOCUMENT_BY_ID'
export const GET_PAYMENT_DATA_LIST_GM = 'GET_PAYMENT_DATA_LIST_GM'
export const SET_PAYMENT_DATA_LIST_GM = 'SET_PAYMENT_DATA_LIST_GM'
export const UPDATE_ITEM_GM = 'UPDATE_ITEM_GM'
export const RESET_COMPONENT = 'RESET_COMPONENT'
export const SET_PROP_INDEX_GM = 'SET_PROP_INDEX_GM'
export const SET_PROP_DRAWER_INDEX_GM = 'SET_PROP_DRAWER_INDEX_GM'
export const SET_PAYMENT_DATA_LIST_MAINT_GM = 'SET_PAYMENT_DATA_LIST_MAINT_GM'
export const SET_PAYMENT_DATA_LIST_TYRES_GM = 'SET_PAYMENT_DATA_LIST_TYRES_GM'
export const GET_EXCEPTION_DATA_LIST_GM = 'GET_EXCEPTION_DATA_LIST_GM'
export const SET_EXCEPTION_DATA_LIST_GM = 'SET_EXCEPTION_DATA_LIST_GM'
export const SET_SELECTED_VEHICLE_DETAILS_GM = 'SET_SELECTED_VEHICLE_DETAILS_GM'
export const GET_SELECTED_VEHICLE_DETAILS_GM = 'GET_SELECTED_VEHICLE_DETAILS_GM'
export const SET_LOADED_PAYMENT_GM = 'SET_LOADED_PAYMENT_GM'
export const COMMIT_PAYMENT_GM = 'COMMIT_PAYMENT_GM'
export const GET_GM_SUPPLIER_PAYMENT_LIST = 'GET_GM_SUPPLIER_PAYMENT_LIST'
export const SET_GM_SUPPLIER_PAYMENT_LIST = 'SET_GM_SUPPLIER_PAYMENT_LIST'
export const COMMIT_PAYMENT_REFRESH = 'COMMIT_PAYMENT_REFRESH'
export const UPDATE_PAYMENT_DATA_GM = 'UPDATE_PAYMENT_DATA_GM'
export const SET_MAIN_PAYMENT_DATA_GM = 'SET_MAIN_PAYMENT_DATA_GM'
export const PROCESS_PAYMENT_GM_REVIEW = 'PROCESS_PAYMENT_GM_REVIEW'
export const PROCESS_PAYMENT_APPROVAL_NOTIFICATION = 'PROCESS_PAYMENT_APPROVAL_NOTIFICATION'
export const SET_PAYMENT_APPROVAL_DATA_LIST = 'SET_PAYMENT_APPROVAL_DATA_LIST'
export const SET_FILTERED_DATA = 'SET_FILTERED_DATA'
export const SET_FILTERED_VALUE = 'SET_FILTERED_VALUE'
export const RESET_FILTERED_VALUES = 'RESET_FILTERED_VALUES'
export const SET_PROP_INDEX_SUB = 'SET_PROP_INDEX_SUB'
export const GET_PAYMENT_LIST_DATA = 'GET_PAYMENT_LIST_DATA'
export const GET_FRESH_DATA = 'GET_FRESH_DATA'
export const AUTHORISE_PAYMENT_REQUEST = 'AUTHORISE_PAYMENT_REQUEST'
export const UPDATE_PAYMENT_DATA_VALUES = 'UPDATE_PAYMENT_DATA_VALUES'
export const TOGGLE_SHOW_ALL = 'TOGGLE_SHOW_ALL'
export const SET_PROP_INDEX_GM_EXCEPTION = 'SET_PROP_INDEX_GM_EXCEPTION'
export const SET_PROP_INDEX_SUB_AUTH = 'SET_PROP_INDEX_SUB_AUTH'



