import * as types from './types'
import moment from 'moment'


import * as formulas from 'formulajs'
import * as lodash from 'lodash'
import { financeaccess, six_month_maintenance, six_month_service } from './props'

export const getAccessories = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CONTRACT_ACCESSORY_LOOKUP_DATA,
            payload: search
        })
    }
}

export const getVehicleAmort = (licenseplateno) => {
    //console.log('_capitalbalance getVehicleAmort',licenseplateno)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICLE_AMORT_SCHEDULE_DETAILS,
            payload: licenseplateno
        })
    }
}


export const setVehicleAmort = (payload) => {
    return (dispatch, getState) => {
        //console.log('_capitalbalance setVehicleAmort')
        dispatch({
            type: types.SET_VEHICLE_AMORT_SCHEDULE_DETAILS,
            payload: payload.data
        })
        if(payload.data.length){
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'capitalbalance', value: payload.data[0].carriedforward }
            }) 
        }
        dispatch(calcValues())
        dispatch(calcltdValues())
    }
}

export const getSupplierList = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_LIST_LOOKUP_DATA,
            payload: {
                query: search
            }
        })
    }
}

export const setSupplierList = (payload) => {
    return (dispatch, getState) => {
        const data = payload.data.map(x => {
            return {
                supplier_id: x.supplier_id,
                suppliername: x.suppliername
            }
        })

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE_PROP,
            payload: { 
                prop: 'suppliers', 
                data: data
            }
        })
    }
}

export const setSupplierValue = (value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'supplier_id', value: value }
        })
    }
}


export const getAmortisationClientEntry = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CONTRACT_AMORTISATION_CLIENT_ENTRY,
            payload: search
        })
    }
}

export const setAmortisationClientEntry = (payload) => {
    return (dispatch, getState) => {
        //console.log('_capitalbalance setAmortisationClientEntry')
        let amortisation = []
        try {
            amortisation = payload.data  //.filter(x => moment(x.date).format('YYYYMM') === moment().format('YYYYMM'))
        }
        catch (err) {
            //console.log(err)
        }

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'capitalbalance', value: amortisation.length ? amortisation[0].carriedforward : 0 } //data[0].vehicleprice }       
        })

    }
}

export const setDefaultValues = (data,licenseplateno) => {
    return (dispatch, getState) => {

        if (data.length) {

            let _period = data[0].recomended_period ? data[0].recomended_period : data[0].customercontractderivative[0].remainingbillingcount 
            let _distancepermonth = data[0].recomended_kms ? data[0].recomended_kms : data[0].distancepermonth
            let _ltdperiod = data[0].ltd_recomended_period ? data[0].ltd_recomended_period : data[0].customercontractderivative[0].remainingbillingcount
            let _ltddistancepermonth = data[0].ltd_recommended_kms ? data[0].ltd_recommended_kms : data[0].distancepermonth
            const excess = (parseFloat(data[0].customercontractderivative[0].maintenanceexcess) + parseFloat(data[0].customercontractderivative[0].financeexcess)).toFixed(2)
            const terminationdate = data[0].terminationdate.split('T')[0] // moment().add(data[0].customercontractderivative[0].period, 'months').format('YYYY-MM-DD');
            const ltdterminationdate = data[0].terminationdate.split('T')[0]
            const contractdistance = (parseInt(_distancepermonth) * parseInt(_period)).toFixed(0)
            const ltdcontractdistance = (parseInt(_ltddistancepermonth) * parseInt(_ltdperiod)).toFixed(0)


            const retailprice = data[0].customercontractderivative[0].retailprice
            const capitalbalance = data[0].customercontractderivative[0].vehicleprice
            const vehicleprice = data[0].customercontractderivative[0].vehicleprice
            const interestrate = data[0].customercontractderivative[0].interestrate


            const maint = parseFloat(data[0].customercontractderivative[0].maintenancemonthlyselling)
            const tyres = parseFloat(data[0].customercontractderivative[0].tyresmonthlyselling)
            const dist = parseFloat(data[0].distancepermonth)


            const maint_cpk = (maint / dist).toFixed(2)
            const tyres_cpk = (tyres / dist).toFixed(2)
            const smmaintenance = ((data[0].recomended_kms ? data[0].recomended_kms : data[0].distancepermonth) * (maint / dist))
            const ltdmaintenance = ((data[0].ltd_recommended_kms ? data[0].ltd_recommended_kms : data[0].distancepermonth) * (maint / dist))
            const smtyres = ((data[0].recomended_kms ? data[0].recomended_kms : data[0].distancepermonth) * (tyres / dist))
            const ltdtyres= ((data[0].ltd_recommended_kms ? data[0].ltd_recommended_kms : data[0].distancepermonth) * (tyres / dist))
           let startdate = null
            try {
                startdate = moment(data[0].startdate).format('YYYY-MM-DD') ///amortisationcliententry.filter(x => moment(x.date).format('YYYYMM') === moment().format('YYYYMM'))
            }
            catch (err) {
                //console.log(err)
            }


            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_period', value: data[0].recomended_period ? data[0].recomended_period : data[0].customercontractderivative[0].remainingbillingcount }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_period', value: data[0].ltd_recomended_period ? data[0].ltd_recomended_period : data[0].customercontractderivative[0].remainingbillingcount }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_kms', value: data[0].ltd_recommended_kms ? data[0].ltd_recommended_kms : data[0].distancepermonth}
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_contractdistance', value: ltdcontractdistance}
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'remainingbillingcount', value: data[0].customercontractderivative[0].remainingbillingcount }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'billingcount', value: data[0].customercontractderivative[0].billingcount }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_terminationdate', value: terminationdate }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_terminationdate', value: ltdterminationdate }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_kms', value: data[0].recomended_kms ? data[0].recomended_kms : data[0].distancepermonth }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_contractdistance', value: contractdistance }
            })
          
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'retailprice', value: retailprice }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'vehicleprice', value: vehicleprice }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'discountamount', value: '0' } //data[0].customercontractderivative[0].discountamount }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_discountamount', value: '0' } //data[0].customercontractderivative[0].discountamount }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_discountamount', value: '0' } //data[0].customercontractderivative[0].discountamount }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_interestrate', value: interestrate }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_interestrate', value: interestrate }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'accessoriesselling', value: data[0].customercontractderivative[0].accessoriesselling ? data[0].customercontractderivative[0].accessoriesselling : 0 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_accessoriesselling', value: data[0].customercontractderivative[0].accessoriesselling ? data[0].customercontractderivative[0].accessoriesselling : 0 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_accessoriesselling', value: data[0].customercontractderivative[0].accessoriesselling ? data[0].customercontractderivative[0].accessoriesselling : 0 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'serviceselling', value: data[0].customercontractderivative[0].servicesmonthlyselling ? data[0].customercontractderivative[0].servicesmonthlyselling : 0 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_serviceselling', value: data[0].customercontractderivative[0].servicesmonthlyselling ? data[0].customercontractderivative[0].servicesmonthlyselling : 0 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_serviceselling', value: data[0].customercontractderivative[0].servicesmonthlyselling ? data[0].customercontractderivative[0].servicesmonthlyselling : 0 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'sundryselling', value: data[0].customercontractderivative[0].sundryselling ? data[0].customercontractderivative[0].sundryselling : 0 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_sundryselling', value: data[0].customercontractderivative[0].sundryselling ? data[0].customercontractderivative[0].sundryselling : 0 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_sundryselling', value: data[0].customercontractderivative[0].sundryselling ? data[0].customercontractderivative[0].sundryselling : 0 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'rv', value: data[0].customercontractderivative[0].residualvaluepercentage }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_rv', value: data[0].customercontractderivative[0].residualvaluepercentage }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_rv', value: data[0].customercontractderivative[0].residualvaluepercentage }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'rvamount', value: data[0].customercontractderivative[0].residualvalue }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_rvamount', value: data[0].customercontractderivative[0].residualvalue }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_rvamount', value: data[0].customercontractderivative[0].residualvalue }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'finance', value: data[0].customercontractderivative[0].financemonthlyselling }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_finance', value: data[0].customercontractderivative[0].financemonthlyselling }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_finance', value: data[0].customercontractderivative[0].financemonthlyselling }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_maintenance', value: smmaintenance}
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_maintenance', value: ltdmaintenance }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'tyres', value: (parseFloat(data[0].customercontractderivative[0].tyresmonthlyselling)).toFixed(2) }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_tyres', value: smtyres}
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_tyres', value: ltdtyres}
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'licence', value: data[0].customercontractderivative[0].licencemonthlyselling }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_licence', value: data[0].customercontractderivative[0].licencemonthlyselling }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_licence', value: data[0].customercontractderivative[0].licencemonthlyselling }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'service', value: (parseFloat(data[0].customercontractderivative[0].servicesmonthlyselling)).toFixed(2)}
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_service', value: (parseFloat(data[0].customercontractderivative[0].servicesmonthlyselling)).toFixed(2)}
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_service', value: (parseFloat(data[0].customercontractderivative[0].servicesmonthlyselling)).toFixed(2)}
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'adminfee', value: (parseFloat(data[0].customercontractderivative[0].monthlyadminfee)).toFixed(2) }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_adminfee', value: (parseFloat(data[0].customercontractderivative[0].monthlyadminfee)).toFixed(2) }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_adminfee', value: (parseFloat(data[0].customercontractderivative[0].monthlyadminfee)).toFixed(2) }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'funder_adminfee', value: (parseFloat(data[0].financialinstitution.admin_fee)).toFixed(2)  }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_funder_adminfee', value: (parseFloat(data[0].financialinstitution.admin_fee)).toFixed(2) }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_funder_adminfee', value: (parseFloat(data[0].financialinstitution.admin_fee)).toFixed(2) }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'roi', value: data[0].customercontractderivative[0].monthlyreturnoninvestment }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_roi', value: data[0].customercontractderivative[0].monthlyreturnoninvestment }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_roi', value: data[0].customercontractderivative[0].monthlyreturnoninvestment }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'roiper', value: 12 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_roiper', value: 12 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_roiper', value: 12 }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'monthly_excl', value: data[0].customercontractderivative[0].monthlysellingsubtotal }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_monthly_excl', value: data[0].customercontractderivative[0].monthlysellingsubtotal }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_monthly_excl', value: data[0].customercontractderivative[0].monthlysellingsubtotal }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'monthly_vat', value: data[0].customercontractderivative[0].monthlysellingvat }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_monthly_vat', value: data[0].customercontractderivative[0].monthlysellingvat }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_monthly_vat', value: data[0].customercontractderivative[0].monthlysellingvat }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_monthly_incl', value: data[0].customercontractderivative[0].monthlysellingtotal }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_monthly_incl', value: data[0].customercontractderivative[0].monthlysellingtotal }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'monthly_incl', value: data[0].customercontractderivative[0].monthlysellingtotal }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'financeaccess', value: data[0].customercontractderivative[0].financeexcess }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_financeaccess', value: data[0].customercontractderivative[0].financeexcess }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_financeaccess', value: data[0].customercontractderivative[0].financeexcess }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'maintenanceaccess', value: data[0].customercontractderivative[0].maintenanceexcess }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_maintenanceaccess', value: data[0].customercontractderivative[0].maintenanceexcess }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_maintenanceaccess', value: data[0].customercontractderivative[0].maintenanceexcess }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'excess', value: excess }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_excess', value: excess }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_excess', value: excess }
            })
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'startdate', value: startdate }
            })
            
            
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'maint_cpk', value: maint_cpk }
            })
            
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'tyres_cpk', value: tyres_cpk }
            })

            dispatch(getVehicleAmort(licenseplateno))
           
        }
    }
}

export const setAccessories = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE_PROP,
            payload: {
                prop: 'accessories',
                data: payload.data
            }
        })

        const fields = getState().contractRestructure

        const rv_amount = fields.rvamount.value  // setValue(fields.retail price.value * (parseInt(fields.rv.value) / 100)).toFixed(2)
        const contractdistance = (parseInt(fields.distancepermonth.value) * parseInt(fields.period.value)).toFixed(0)
        const nett = parseInt(fields.capitalbalance.value) // - parseInt(fields.discountamount.value)).toFixed(2)

        const acc_list = fields.contractaccessory.value.filter(x => x.new)
        const acc_tot = lodash.sumBy(acc_list, (d) => { return parseFloat((d.amount) ? d.amount.replace(/,/gi, '') : 0) }).toFixed(2) // fields.accessoriesselling.value ? fields.accessoriesselling.value : 0

        const sun_tot = fields.sundryselling.value ? fields.sundryselling.value : 0
        const acc_sun_tot = (parseInt(acc_tot) + parseInt(sun_tot)).toFixed(2)
        const rate = parseFloat(fields.interestrate.value)

        let finance = formulas.PMT(((parseFloat(rate) / 100) / 12), parseFloat(fields.period.value), -(parseFloat(nett) + parseFloat(acc_sun_tot)), parseInt(rv_amount), 0)//.toFixed(2)
        finance = parseFloat(finance).toFixed(2)
    
        
        const maint = ((fields.maint_cpk.value) * parseInt(fields.distancepermonth.value)).toFixed(2)
        const tyre = ((fields.tyres_cpk.value) * parseInt(fields.distancepermonth.value)).toFixed(2)
        const lic = fields.licence.value
        const service = fields.service.value
        const admin = fields.adminfee.value
        const funder_adminfee = fields.funder_adminfee.value

        //let _roi = parseFloat(finance) + parseFloat(maint) + parseFloat(tyre) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)
        //let roi = (94 + (parseFloat(_roi) * 0.12)).toFixed(2)

        let _roi = parseFloat(finance) + parseFloat(maint) + parseFloat(tyre) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)
        let roi = fields.roiper.value === 0 ? 0 : (94.50 + (parseFloat(_roi) * (parseFloat(fields.roiper.value) / 100))).toFixed(2)

        const monthly_excl = (parseFloat(finance) + parseFloat(maint) + parseFloat(tyre) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)+parseFloat(funder_adminfee) + parseFloat(roi)).toFixed(2)
        const monthly_vat = (parseFloat(monthly_excl) * 0.15).toFixed(2)
        const monthly_incl = (parseFloat(monthly_excl) * 1.15).toFixed(2)

        let do_cpk = parseFloat(finance) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)
       

        const financeaccess = (parseFloat(do_cpk) / parseInt(fields.distancepermonth.value)).toFixed(2)
        const maintenanceaccess = ((parseFloat(maint) + parseFloat(tyre)) / parseInt(fields.distancepermonth.value)).toFixed(2)
        const excess = (parseFloat(financeaccess) + parseFloat(maintenanceaccess)).toFixed(2)
        // let terminationdate = null
        // try {

        //     terminationdate = moment(fields.startdate.value).add((fields.period.value, 'months').format('YYYY-MM-DD');
        // } catch (error) {
        //     terminationdate = moment().add(fields.period.value, 'months').format('YYYY-MM-DD');
        // }
        // dispatch({
        //     type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
        //     payload: { prop: 'rvamount', value: rv_amount }
        // })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'serviceselling', value: service }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'maintenance', value: maint }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'tyres', value: tyre }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'service', value: service }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'adminfee', value: admin }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'funder_adminfee', value: funder_adminfee }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'contractdistance', value: contractdistance }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'roi', value: roi }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'finance', value: finance }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'monthly_excl', value: monthly_excl }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'monthly_vat', value: monthly_vat }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'monthly_incl', value: monthly_incl }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'financeaccess', value: financeaccess }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'maintenanceaccess', value: maintenanceaccess }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'excess', value: excess }
        })
        // dispatch({
        //     type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
        //     payload: { prop: 'terminationdate', value: terminationdate }
        // })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'accessoriesselling', value: acc_tot }
        })
    }
}

export const setSelectedAccessory = (contractaccessory, inx, id) => {
    return (dispatch, getState) => {
        const accessory = getState().contractRestructure.accessories.filter(x => x.accessory_id === id)
        // window.glyco.log('setSelectedAccessory')
        // window.glyco.log(id)
        // window.glyco.log(accessory)
        contractaccessory[inx].accessory_id = accessory[0].accessory_id
        contractaccessory[inx].amount = accessory[0].retailprice
        contractaccessory[inx].accessoryname = accessory[0].accessoryname

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: {
                prop: 'contractaccessory',
                value: contractaccessory
            }
        })
        dispatch(calcValues())
        dispatch(calcltdValues())
    }
}

export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const setRVPerc = (prop, value) => {
    return (dispatch, getState) => {

        const fields = getState().contractRestructure
        const rv_perc = (parseFloat((value / fields.retailprice.value) * 100)).toFixed(2)

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'rv', value: rv_perc }
        })

    }
}

export const setRv = (prop, value) => {
    return (dispatch, getState) => {
        const fields = getState().contractRestructure
        const rv_amount = (fields.retailprice.value * (parseInt(value) / 100)).toFixed(2)

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'rv', value: value }
        })

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'rvamount', value: rv_amount }
        })
    }
}

export const setPeriod = (prop, value) => {
    return (dispatch, getState) => {
        const fields = getState().contractRestructure;

        let sixmonthterminationdate = null;
        let sixmonthcontractdistance = null;
        const billingcount = parseInt(fields.billingcount.value);
        const distancepermonth = parseFloat(fields.six_month_kms.value);
        const period = isNaN(billingcount) || isNaN(value) ? 0 : billingcount + parseInt(value);

        try {
            sixmonthterminationdate = moment(fields.startdate.value).add(period, 'months').format('YYYY-MM-DD');
            sixmonthcontractdistance = isNaN(distancepermonth) ? 0 : distancepermonth * parseInt(value);
        } catch (error) {
            sixmonthterminationdate = '';
            sixmonthcontractdistance = '';
        }

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: prop, value: value }
        });

        if (sixmonthterminationdate !== '' && !isNaN(sixmonthcontractdistance)) {
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_terminationdate', value: sixmonthterminationdate }
            });
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_contractdistance', value: sixmonthcontractdistance }
            });
        }
    };
};

export const setDistancepermonth = (prop, value) => {
    return (dispatch, getState) => {
        const fields = getState().contractRestructure;

        let sixmonthcontractdistance = null;
        const period = parseInt(fields.six_month_period.value);

        try {
            sixmonthcontractdistance = period * parseInt(value);
        } catch (error) {
            sixmonthcontractdistance = '';
        }

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: prop, value: value }
        });

        if (sixmonthcontractdistance !== '') {
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'six_month_contractdistance', value: sixmonthcontractdistance }
            });
        }
    };
};

export const setLtdDistancepermonth = (prop, value) => {
    return (dispatch, getState) => {
        const fields = getState().contractRestructure;

        let ltdcontractdistance = null;
        const period = parseInt(fields.ltd_period.value);

        try {
            ltdcontractdistance = period * parseInt(value);
        } catch (error) {
            ltdcontractdistance = '';
        }

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: prop, value: value }
        });

        if (ltdcontractdistance !== '') {
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_contractdistance', value: ltdcontractdistance }
            });
        }
    };
};



export const setltdPeriod = (prop, value) => {
    return (dispatch, getState) => {
        const fields = getState().contractRestructure

        let terminationdate = null
        const billingcount = fields.billingcount.value
        let ltdcontractdistance = null;
        const distancepermonth = parseFloat(fields.ltd_kms.value);
        const period = isNaN(billingcount) || isNaN(value) ? 0 : billingcount + parseInt(value);
        try {
            terminationdate = moment(fields.startdate.value).add(period, 'months').format('YYYY-MM-DD');
            ltdcontractdistance = isNaN(distancepermonth) ? 0 : distancepermonth * parseInt(value);
        } catch (error) {
            terminationdate = '';
            ltdcontractdistance ='';
        }
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: prop, value: value }
        })
        if (terminationdate !== '' && !isNaN(ltdcontractdistance)) {
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_terminationdate', value: terminationdate }
            });
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: { prop: 'ltd_contractdistance', value: ltdcontractdistance }
            });
        }
    };
};


export const setRVAmntValue = (prop, value) => {
    return (dispatch, getState) => {

        const fields = getState().contractRestructure
        const rv = (fields.retailprice.value / parseInt(fields.rv.value)).toFixed(2)


        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'rvamount', value: value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'rv', value: rv }
        })
    }
}

export const calcValues = () => {
    return (dispatch, getState) => {
        const fields = getState().contractRestructure
        const rv_amount = (fields.retailprice.value * (parseFloat(fields.six_month_rv.value) / 100)).toFixed(2)
        const contractdistance = (parseInt(fields.six_month_kms.value) * parseInt(fields.six_month_period.value)).toFixed(0)
        const nett = parseInt(fields.capitalbalance.value) // - parseInt(fields.discountamount.value)).toFixed(2)

        let acc_list = fields.contractaccessory.value.filter(x => x.new).filter(x => x.accessoryname !== 'Speed Limiter')
        acc_list = acc_list ? acc_list : 0 
        let service_list = fields.contractaccessory.value.filter(x => x.new).filter(x => x.accessoryname === 'Speed Limiter')
        service_list = service_list ? service_list:0
        const acc_tot = lodash.sumBy(acc_list, (d) => { return parseFloat((d.amount) ? d.amount.replace(/,/gi, '') : 0) }).toFixed(2) // fields.accessoriesselling.value ? fields.accessoriesselling.value : 0
        const ser_tot = lodash.sumBy(service_list, (d) => { return parseFloat((d.amount) ? d.amount.replace(/,/gi, '') : 0) }).toFixed(2) // fields.accessoriesselling.value ? fields.accessoriesselling.value : 0

        const sun_tot = fields.six_month_sundryselling.value ? fields.six_month_sundryselling.value : 0
        const acc_sun_tot = (parseInt(acc_tot) + parseInt(sun_tot)).toFixed(2)
        const rate = parseFloat(fields.six_month_interestrate.value)

        let finance = formulas.PMT(((parseFloat(rate) / 100) / 12), parseFloat(fields.six_month_period.value), -(parseFloat(nett) + parseFloat(acc_sun_tot)), parseInt(rv_amount), 0)//.toFixed(2)
        finance = parseFloat(finance).toFixed(2)

        const maint = fields.six_month_maintenance.value
        const tyre = fields.six_month_tyres.value
        const lic = fields.licence.value
        const tot_serv = (parseFloat(fields.six_month_service.value) + parseFloat(ser_tot))
        const service = tot_serv
        const admin = fields.six_month_adminfee.value
        const funder_admin = fields.six_month_funder_adminfee.value

        // let _roi = parseFloat(finance) + parseFloat(maint) + parseFloat(tyre) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)
        // let roi = fields.roiper.value = 0 ? 0 : (94.50 + (parseFloat(_roi) * (parseFloat(fields.roiper.value) / 100))).toFixed(2)

        let _roi = parseFloat(finance) + parseFloat(maint) + parseFloat(tyre) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)
        let roi = fields.roiper.value === 0 ? 0 : (94.50 + (parseFloat(_roi) * (parseFloat(fields.roiper.value) / 100))).toFixed(2)


        const monthly_excl = (parseFloat(finance) + parseFloat(maint) + parseFloat(tyre) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)+parseFloat(funder_admin)+ parseFloat(roi)).toFixed(2)
        const monthly_vat = (parseFloat(monthly_excl) * 0.15).toFixed(2)
        const monthly_incl = (parseFloat(monthly_excl) * 1.15).toFixed(2)

        let do_cpk = parseFloat(finance) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)
       
        const financeaccess = (parseFloat(do_cpk) / parseInt(fields.six_month_kms.value)).toFixed(2)
        const maintenanceaccess = ((parseFloat(maint) + parseFloat(tyre)) / parseInt(fields.six_month_kms.value)).toFixed(2)
        const excess = (parseFloat(financeaccess) + parseFloat(maintenanceaccess)).toFixed(2)


        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_rvamount', value: rv_amount }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_serviceselling', value: service }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_maintenance', value: maint }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_tyres', value: tyre }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_service', value: service }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_adminfee', value: admin }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_contractdistance', value: fields.six_month_contractdistance.value}
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_roi', value: roi }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_finance', value: finance }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_monthly_excl', value: monthly_excl }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_monthly_vat', value: monthly_vat }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_monthly_incl', value: monthly_incl }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_financeaccess', value: financeaccess }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_maintenanceaccess', value: maintenanceaccess }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_monthly_excess', value: excess }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_period', value: fields.six_month_period.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_kms', value: fields.six_month_kms.value }
        })
        /dispatch({
             type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_terminationdate', value: fields.six_month_terminationdate.value}
         })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_accessoriesselling', value: acc_tot }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'six_month_funder_adminfee', value: funder_admin }
        })
    }
}

export const setContractValues = () => {
    return (dispatch, getState) => {
        const fields = getState().contractRestructure
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'rvamount', value: fields.six_month_rvamount.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'serviceselling', value: fields.six_month_serviceselling.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'maintenance', value: fields.six_month_maintenance.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'tyres', value: fields.six_month_tyres.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'service', value: fields.six_month_service.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'adminfee', value: fields.six_month_adminfee.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'funderadminfee', value: fields.six_month_funder_adminfee }
        })


        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'contractdistance', value: fields.six_month_contractdistance.value}
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'roi', value: fields.six_month_roi.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'finance', value: fields.six_month_finance.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'monthly_excl', value: fields.six_month_monthly_excl.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'monthly_vat', value: fields.six_month_monthly_vat.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'monthly_incl', value: fields.six_month_monthly_incl.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'financeaccess', value: fields.six_month_financeaccess.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'maintenanceaccess', value: fields.six_month_maintenanceaccess.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'excess', value: fields.six_month_excess.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'period', value: fields.six_month_period.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'distancepermonth', value: fields.six_month_kms.value }
        })
        /dispatch({
             type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'terminationdate', value: fields.six_month_terminationdate.value}
         })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'accessoriesselling', value: fields.six_month_accessoriesselling.value}
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'interestrate', value: fields.six_month_interestrate.value }
        })
    }
}

export const setContractltdValues = () => {
    return (dispatch, getState) => {
        const fields = getState().contractRestructure
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'rvamount', value: fields.ltd_rvamount.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'serviceselling', value: fields.ltd_serviceselling.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'maintenance', value: fields.ltd_maintenance.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'tyres', value: fields.ltd_tyres.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'service', value: fields.ltd_service.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'adminfee', value: fields.ltd_adminfee.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'funderadminfee', value: fields.ltd_month_funder_adminfee }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'contractdistance', value: fields.ltd_contractdistance.value}
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'roi', value: fields.ltd_roi.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'finance', value: fields.ltd_finance.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'monthly_excl', value: fields.ltd_monthly_excl.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'monthly_vat', value: fields.ltd_monthly_vat.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'monthly_incl', value: fields.ltd_monthly_incl.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'financeaccess', value: fields.ltd_financeaccess.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'maintenanceaccess', value: fields.ltd_maintenanceaccess.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'excess', value: fields.ltd_excess.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'period', value: fields.ltd_period.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'distancepermonth', value: fields.ltd_kms.value }
        })
        dispatch({
             type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'terminationdate', value: fields.ltd_terminationdate.value}
         })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'accessoriesselling', value: fields.ltd_accessoriesselling.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'interestrate', value: fields.ltd_interestrate.value }
        })
    }
}

export const calcltdValues = () => {
    return (dispatch, getState) => {
        const fields = getState().contractRestructure
        const rv_amount = (fields.retailprice.value * (parseFloat(fields.ltd_rv.value) / 100)).toFixed(2)
        const contractdistance = (parseInt(fields.ltd_kms.value) * parseInt(fields.ltd_period.lueva)).toFixed(0)
        const nett = parseInt(fields.capitalbalance.value) // - parseInt(fields.discountamount.value)).toFixed(2)

        let acc_list = fields.contractaccessory.value.filter(x => x.new).filter(x => x.accessoryname !== 'Speed Limiter')
        acc_list = acc_list ? acc_list : 0 
        let service_list = fields.contractaccessory.value.filter(x => x.new).filter(x => x.accessoryname === 'Speed Limiter')
        service_list = service_list ? service_list:0
        const acc_tot = lodash.sumBy(acc_list, (d) => { return parseFloat((d.amount) ? d.amount.replace(/,/gi, '') : 0) }).toFixed(2) // fields.accessoriesselling.value ? fields.accessoriesselling.value : 0
        const ser_tot = lodash.sumBy(service_list, (d) => { return parseFloat((d.amount) ? d.amount.replace(/,/gi, '') : 0) }).toFixed(2) // fields.accessoriesselling.value ? fields.accessoriesselling.value : 0

        const sun_tot = fields.six_month_sundryselling.value ? fields.six_month_sundryselling.value : 0
        const acc_sun_tot = (parseInt(acc_tot) + parseInt(sun_tot)).toFixed(2)
        const rate = parseFloat(fields.ltd_interestrate.value)

        let finance = formulas.PMT(((parseFloat(rate) / 100) / 12), parseFloat(fields.ltd_period.value), -(parseFloat(nett) + parseFloat(acc_sun_tot)), parseInt(rv_amount), 0)//.toFixed(2)
        finance = parseFloat(finance).toFixed(2)

        const maint = fields.ltd_maintenance.value
        const tyre = fields.ltd_tyres.value
        const lic = fields.ltd_licence.value
        const tot_serv = (parseFloat(fields.ltd_service.value) + parseFloat(ser_tot))
        const service = tot_serv
        const admin = fields.ltd_adminfee.value
        const funder_admin = fields.ltd_funder_adminfee.value

        // let _roi = parseFloat(finance) + parseFloat(maint) + parseFloat(tyre) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)
        // let roi = fields.roiper.value = 0 ? 0 : (94.50 + (parseFloat(_roi) * (parseFloat(fields.roiper.value) / 100))).toFixed(2)

        let _roi = parseFloat(finance) + parseFloat(maint) + parseFloat(tyre) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)
        let roi = fields.roiper.value === 0 ? 0 : (94.50 + (parseFloat(_roi) * (parseFloat(fields.roiper.value) / 100))).toFixed(2)


        const monthly_excl = (parseFloat(finance) + parseFloat(maint) + parseFloat(tyre) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)+parseFloat(funder_admin)+ parseFloat(roi)).toFixed(2)
        const monthly_vat = (parseFloat(monthly_excl) * 0.15).toFixed(2)
        const monthly_incl = (parseFloat(monthly_excl) * 1.15).toFixed(2)

        let do_cpk = parseFloat(finance) + parseFloat(lic) + parseFloat(service) + parseFloat(admin)
       
        const financeaccess = (parseFloat(do_cpk) / parseInt(fields.ltd_kms.value)).toFixed(2)
        const maintenanceaccess = ((parseFloat(maint) + parseFloat(tyre)) / parseInt(fields.ltd_kms.value)).toFixed(2)
        const excess = (parseFloat(financeaccess) + parseFloat(maintenanceaccess)).toFixed(2)


        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_rvamount', value: rv_amount }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_serviceselling', value: service }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_maintenance', value: maint }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_tyres', value: tyre }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_service', value: service }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_adminfee', value: admin }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_funder_adminfee', value: funder_admin }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_contractdistance', value: fields.ltd_contractdistance.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_roi', value: roi }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_finance', value: finance }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_monthly_excl', value: monthly_excl }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_monthly_vat', value: monthly_vat }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_monthly_incl', value: monthly_incl }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_financeaccess', value: financeaccess }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_maintenanceaccess', value: maintenanceaccess }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_excess', value: excess }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_period', value: fields.ltd_period.value }
        })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_kms', value: fields.ltd_kms.value }
        })
         dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_terminationdate', value: fields.ltd_terminationdate.value }
         })
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: { prop: 'ltd_accessoriesselling', value: acc_tot }
        })
    }
}

export const setAccessory = (contractaccessory, inx, value) => {
    return (dispatch, getState) => {

        contractaccessory[inx].amount = value

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: {
                prop: 'contractaccessory',
                value: contractaccessory
            }
        })
        dispatch(calcValues())
        dispatch(calcltdValues())
    }
}

export const setZeroValueAccessory = (contractaccessory,inx, value) => {
        return (dispatch, getState) => {
            contractaccessory[inx].customer_settled_acessory = value
            dispatch({
                type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
                payload: {
                    prop: 'contractaccessory',
                    value: contractaccessory
                }
            })
        }
    }

export const setAccessorySupplier = (contractaccessory, inx, value, text) => {
    return (dispatch, getState) => {

        contractaccessory[inx].supplier_id = value
        contractaccessory[inx].suppliername = text

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: {
                prop: 'contractaccessory',
                value: contractaccessory
            }
        })
    }
}
export const delAccessory = (accessories, inx) => {
    return (dispatch, getState) => {
        delete accessories[inx]
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: {
                prop: 'contractaccessory',
                value: accessories
            }
        })
    }
}

export const addAccessory = (accessories) => {
    return (dispatch, getState) => {
        accessories.push({
            accessory_id: null,
            accessoryname: null,
            amount: null,
            client_id: null,
            contractaccessory_id: null,
            created_at: null,
            created_by_user_id: null,
            customercontract_id: null,
            deleted_at: null,
            deleted_by_user_id: null,
            description: null,
            isstandard: null,
            khulaaccessoryid: null,
            khulacontractid: null,
            khulafid: null,
            khulasupplierid: null,
            note: null,
            quantity: null,
            supplier_id: null,
            updated_at: null,
            updated_by_user_id: null,
            customersettled_accessory:null,
            new: true
        })

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE,
            payload: {
                prop: 'contractaccessory',
                value: accessories
            }
        })
    }
}

export const getContract = (value) => {
    //console.log('getContract',value)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CONTRACT_DETAILS,
            payload: {
                query: value
            }
        })
    }
}

export const getAllContractParemets = (value) => {
    //console.log('getAllContracts',value)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_All_COMPONENT_CONTRACT_DETAILS,
            payload: value.split('-')[0]
        })
    }
}
export const setAllContractParemets = (results) => {
    //console.log('getAllContracts',results)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE_PROP,
            payload:{
              prop:'contracts',
              data:results.data

            } 
        })
    }
}


export const setContract = (payload) => {
    return (dispatch, getState) => {
        const data = payload.data[0]
        //console.log("Final Contract Data",data)
        const contract = []
        contract.push({
            period: data.period,
            terminationdate: data.terminationdate,
            averagedistancepermonth: data.averagedistancepermonth,
            distancepermonth: data.distancepermonth,
            distance: data.distance,
            vehicleprice: data.capitalbalance,
            accessoriesselling: data.accessoriesselling,
            sundryselling: data.sundryselling,
            sellingtotal: data.sellingtotal,
            sellingvat: data.sellingvat,
            deposit: data.deposit,
            depositcapital: data.depositcapital,
            rv: data.rv,
            rvamount: data.rvamount,
            finance: data.finance,
            maintenance: data.maintenance,
            tyres: data.tyres,
            licence: data.licence,
            service: data.service,
            adminfee: data.adminfee,
            funderadminfee:data.financialinstitution.funderadminfee,
            monthly_excl: data.monthly_excl,
            roi: data.roi,
            monthly_vat: data.monthly_vat,
            monthly_incl: data.monthly_incl,
            financeaccess: data.financeaccess,
            maintenanceaccess: data.maintenanceaccess,
            recovery: data.recovery,
            contractaccessory: data.contractaccessory,
            contractsundry: data.contractsundry,
            contractservicetransaction: [],
            mmcode: data.mmcode.mmcode,
            makemodelyear: data.mmcode.makemodelyear,
            imagefilename: data.mmcode.imagefilename,
            chassisnumber: data.chassisnumber,
            contractdescription: data.contractdescription,
            contractnumber: data.contractnumber,
            customername: data.customername,
            rt57pricing: data.rt57pricing,
            openingodometer: data.openingodometer,
            pototal: data.pototal,
            documents: data.customercontractdocument.document,
        })

        contract.push({
            period: data.period,
            terminationdate: data.terminationdate,
            averagedistancepermonth: data.averagedistancepermonth,
            distancepermonth: data.distancepermonth,
            distance: data.distance,
            vehicleprice: data.capitalbalance,
            accessoriesselling: data.accessoriesselling,
            sundryselling: data.sundryselling,
            sellingtotal: data.sellingtotal,
            sellingvat: data.sellingvat,
            deposit: data.deposit,
            depositcapital: data.depositcapital,
            rv: data.rv,
            rvamount: data.rvamount,
            finance: data.finance,
            maintenance: data.maintenance,
            tyres: data.tyres,
            licence: data.licence,
            service: data.service,
            adminfee: data.adminfee,
            funderadminfee:data.funder_adminfee,
            monthly_excl: data.monthly_excl,
            roi: data.roi,
            monthly_vat: data.monthly_vat,
            monthly_incl: data.monthly_incl,
            financeaccess: data.financeaccess,
            maintenanceaccess: data.maintenanceaccess,
            recovery: data.recovery,
            contractaccessory: data.contractaccessory,
            contractsundry: data.contractsundry,
            contractservicetransaction: [],
            mmcode: data.mmcode.mmcode,
            makemodelyear: data.mmcode.makemodelyear,
            imagefilename: data.mmcode.imagefilename,
            chassisnumber: data.chassisnumber,
            contractdescription: data.contractdescription,
            contractnumber: data.contractnumber,
            customername: data.customername,
            rt57pricing: data.rt57pricing,
            openingodometer: data.openingodometer,
            pototal: data.pototal,
            documents: data.customercontractdocument.document,
        })

        dispatch({
            type: types.SET_COMPONENT_CONTRACT_DETAILS,
            payload: {
                prop: 'contract',
                data: contract
            }
        })
    }
}
