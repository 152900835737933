import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import Icon from '../Icon/component'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";

import * as actions from './actions'
import * as navigationMenuActions from '../NavigationMenu/actions'
import * as routerActions from '../Redirect/actions'
import * as styles from './styles'

class History extends Component {



    getHistory() {
        return this.props.state.history
            .sort((x, y) => {
                return new Date(y.date) - new Date(x.date)
            })
            .map((x, i) => {
                return (
                    <div style={{backgroundColor: '#131B3A', height: '100vh'}}>
                        <Container  disableGutters={true} >
                        <List id='boo'>
                            <ListItem
                            className="bg-asteroid"
                                key={x.name + '_' + i + '_' + moment(x.date).format('yyyyMMHHmmss')}
                                style={styles.li}
                                onClick={(event, val) => {
                                    this.props.actions.route(x.path)
                                }}
                            >
                                <ListItemIcon fontSize="small">
                                    <Icon
                                        istyle={styles.icon}
                                        iclass={'material-icons'}
                                        iname={x.icon}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                
                                    primary={window.t.en(x.title)}
                                    secondary={<Typography type="body1" style={{ color: '#FFFFFF' }}>{moment(x.date).fromNow()}</Typography>} />
                            </ListItem>
                            </List>
                        </Container>
                    </div>
                );
            });
    }
    render() {
        const component = {
            state: this.props.state,
            actions: this.props.actions,
            styles: styles
        }

        return (
            <List className="bg-asteroid " style={{height:927}}>
                {this.getHistory()}
            </List>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.history }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...navigationMenuActions, ...routerActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(History)