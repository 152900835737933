import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_TOGGLE_VALUES:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_COMPONENT_YARD_LIST_VEHICLE_LIST:
            return {
                ...state,
                vehicle: action.payload
            }
        case types.SET_COMPONENT_YARD_LIST_VEHICLE_LIST_BAK:
            return {
                ...state,
                vehicledata: action.payload
            }
        case types.SET_COMPONENT_SELECTED_YARD_LIST_VEHICLE:
            return {
                ...state,
                selectedVehicle: action.payload
            }
        default:
            return state
    }
}