import moment from 'moment'
import Checkbox from '../../controls/checkbox'
import Icon from '../Icon/component'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import Card from '../../controls/card'
import TextField from '../../controls/textField'
import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import * as actions from './actions'
import * as styles from './styles'
// import TextField from '@mui/material/TextField'

import ImageUploader from '../ImageUploader/component'
import Upload from '../Upload/component'
import * as _ from 'lodash'
import * as workflowTaskInputAction from '../WorkflowTaskInput/actions'
import ViewVehicle from '../../controls/view_vehicle'


class WorkflowDefleet extends Component {
    componentDidMount() {
        this.props.actions.getDefleetNumber()
        //this.props.actions.setTaskNumber(this.props.step.workflow_task_id)
        this.props.actions.resetComponent()
        this.props.actions.setValidationState('workflowDefleetView')
    }

    getDateDiff(d1, d2) {
        var t2 = d2.getTime();
        var t1 = d1.getTime();
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();
        var inDays = parseInt((t2 - t1) / (24 * 3600 * 1000));
        var inWeeks = parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
        var inMonths = (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
        var inYears = d2.getFullYear() - d1.getFullYear();

        return {
            inDays: inDays,
            inWeeks: inWeeks,
            inMonths: inMonths,
            inYears: inYears
        }
    }
    getTypesForm(type) {
        switch (type) {
            case 'EOC termination':
            case 'EOC purchase':
            case 'Early purchase':
            case 'Early Return':
                return <>
                    <Col xl={12}>
                        <Upload
                            id={'EOC'}
                            inx={'EOC'}
                            prefix={'EOC of Payment'}
                            header={false}
                        />
                    </Col>
                    <Col xl={4}>
                        <Checkbox
                            id={'sight_of_payment'}
                            key={'sight_of_payment'}
                            name={'sight_of_payment'}
                            checked={this.props.state.sight_of_payment}
                            labelPlacement={'end'}
                            label={'Sight of Payment'}
                            onCheck={(checked) => {
                                this.props.actions.setValue('sight_of_payment', checked)
                            }}
                        /></Col>
                </>
            case 'EOC Return':
                return <>
                    <Col xl={12}>
                        <Upload
                            id={'vehicle_return_form'}
                            inx={'vehicle_return_form'}
                            prefix={'Vehicle Return Form'}
                            header={false}
                        />
                    </Col>

                    <Col xl={4}>
                        <Checkbox
                            id={'vehicle_return_form'}
                            key={'vehicle_return_form'}
                            name={'vehicle_return_form'}
                            checked={this.props.state.vehicle_return_form}
                            labelPlacement={'end'}
                            label={'Vehicle Return Form'}
                            onCheck={(checked) => {
                                this.props.actions.setValue('vehicle_return_form', checked)
                            }}
                        />
                    </Col>
                    <Col xl={4}>
                        <Checkbox
                            id={'make_good_cost_invoice'}
                            key={'make_good_cost_invoice'}
                            name={'make_good_cost_invoice'}
                            checked={this.props.state.make_good_cost_invoice}
                            labelPlacement={'end'}
                            label={'Make Good Costs Invoice'}
                            onCheck={(checked) => {
                                this.props.actions.setValue('make_good_cost_invoice', checked)
                            }}
                        /></Col>
                </>
            case 'Breach of Contract':
                return <>
                    <Col xl={12}>
                        <Upload
                            id={'notification_from_collection'}
                            inx={'notification_from_collection'}
                            prefix={'Notification/Instructions from Collections Manager'}
                            header={false}
                        />
                    </Col>
                </>
            case 'Early settlement':
            case 'Early Purchase':
                return <>
                    <Col xl={4}>
                        <TextField
                            variant='outlined'
                            id={'net_book_value'}
                            label={'Enter Net Book Value'}
                            fullWidth={true}
                            value={this.props.state.net_book_value}
                            onChange={(event, value) => {
                                this.props.actions.setValue('net_book_value', event.target.value)
                            }}
                        />
                    </Col>
                    <Col xl={4}>
                        <TextField
                            variant='outlined'
                            id={'retail_value'}
                            label={'Enter Retail Value'}
                            fullWidth={true}
                            value={this.props.state.retail_value}
                            onChange={(event, value) => {
                                this.props.actions.setValue('retail_value', event.target.value)
                            }}
                        />
                    </Col>
                    <Col xl={4}>
                        <TextField
                            variant='outlined'
                            id={'trade_value'}
                            label={'Enter Trade Value'}
                            fullWidth={true}
                            value={this.props.state.trade_value}
                            onChange={(event, value) => {
                                this.props.actions.setValue('trade_value', event.target.value)
                            }}
                        />
                    </Col>
                </>
        }
    }
    view() {
        const vehicle = this.props.state.selectedVehicleDetails.data
        //console.log("vehicle :", vehicle)
        let activecontract = vehicle && vehicle.length ? vehicle[0].customercontract.filter(x => x.activated == true && x.terminated == false) : null // && x.contracttype.indexOf('Full') >= 0) : null
        let contract = activecontract && activecontract.length ? activecontract[0] : null
        //console.log("contract :", contract ? contract : ' NONE')
        var dString = contract ? contract.startdate : new Date();
        var date_from = new Date(dString);
        var date_to = new Date();
        var holder = this.getDateDiff(date_from, date_to)
        var months = holder ? holder.inMonths : null
        months < 12 && contract

        //console.log('selectedDefleetTypex months < 12 && contract', (months < 12 && contract))
        //console.log('selectedDefleetTypex months', months)
        //console.log('selectedDefleetTypex contract', contract)
        return <div>
            {
                this.props.state.defleetNum
                    ? <>
                        <Card
                            title={window.t.en('Vehicle De-fleet')}
                            subtitle={window.t.en('Select a vehicle to defleet')}
                            content={<Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={4} style={{ PaddingTop: '5px' }}>
                                        <div style={{ marginTop: '-5px' }}>
                                            <AutoComplete
                                                name={'autocomplete_defleet_vehicle_reg'}
                                                title={'Vehicle Registration'}
                                                label={'Vehicle Registration'}
                                                info={{
                                                    show: false
                                                }}
                                                selectOnfocus
                                                fullWidth={true}
                                                data={this.props.state.vehicles}
                                                fieldvalue={'vehicle_id'}
                                                //fieldtext={'licenseplateno'}
                                                displayfields={['licenseplateno', 'makemodelyear']}
                                                displaywidth={['20', '80']}
                                                value={this.props.state.fields.selectedVehicle.data ? this.props.state.fields.selectedVehicle.data.text : ''}
                                                onSelect={(args) => //this.props.actions.setComponentFieldInput(field, args.value)
                                                {
                                                    this.props.actions.setVehicleAutoCompleteSelected(args)
                                                    this.props.actions.getSelectedVehicleDetails(args)
                                                    // this.props.actions.getSelectedVehicleContract(args)
                                                }
                                                }
                                                onClick={() =>
                                                    this.props.actions.getVehicleListAutoCompleteMenuItems('')
                                                }
                                                onFilter={(search) => {
                                                      if (search) {
                                                        let vehicle_id = this?.props?.state?.vehicles?.find(
                                                          (v) => v?.licenseplateno === search
                                                        )?.vehicle_id
                                                        this.props.actions.setVehicleAutoCompleteSelected({
                                                          text: search,
                                                          value: vehicle_id ?? null
                                                        })
                                                      } else {
                                                        this.props.actions.setVehicleAutoCompleteSelected({
                                                          text: '',
                                                          value: null,
                                                        })
                                                        this.props.actions.getVehicleListAutoCompleteMenuItems('')
                                                      }
                                                    }
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col xl={4} >
                                        <div style={{ marginTop: '-10px' }}>
                                            <DateControl
                                                id={'txtdefleetrequestdate'}
                                                key={'txtdefleetrequestdate'}
                                                name={'txtdefleetrequestdate'}
                                                label={window.t.en('De-fleet Request Date')}
                                                value={this.props.state.defleetrequestdate}
                                                maxDate={moment()}
                                                onSelect={(args) => {
                                                    this.props.actions.setValue('defleetrequestdate', args)
                                                    this.props.actions.setValidationValue('defleetRequestDate', args)
                                                    this.props.actions.setValue('defleetrequesttime', moment(args).format("HH:mm"))
                                                }}
                                                type={'date'}
                                            />
                                        </div>

                                    </Col>
                                    <Col xl={4}>
                                        <div style={{ marginTop: '15px' }}>
                                            <AutoComplete
                                                name={'dropdown_po_type_select'}
                                                title={window.t.en('Select Type of Settlement')}
                                                label={'Type of Settlement'}
                                                info={{
                                                    show: false
                                                }}
                                                fullWidth={true}
                                                data={this.props.state.defleetTypes}
                                                value={this.props.state.selectedDefleetType?.text}

                                                onClick={() =>{
                                                    console.log('getDefleetTypesx click');
                                                    this.props.actions.getDefleetTypes('')
                                                    //this.props.actions.getDefleetTypes()
                                                }}
                                                onFilter={(search) => {
                                                    if (search) {
                                                        // console.log('getDefleetTypes(search)',this.props.actions.getDefleetTypes(search))
                                                      let terminationreason_id = this?.props?.state?.defleetTypes?.find(
                                                        (t) => t?.terminationreason === search
                                                      )?.terminationreason_id
                                                      this.props.actions.getDefleetTypes(search)
                                                      this.props.actions.selectDefleetType(terminationreason_id)
                                                    } else {
                                                        // 
                                                        this.props.actions.getDefleetTypes('')
                                                        // this.props.actions.selectDefleetType(null)
                                                        this.props.actions.resetDefleetTypes()
                                                    }
                                                  }
                                              }
                                                onSelect={(args) => {
                                                    console.log('getDefleetTypesx set', args)
                                                    this.props.actions.selectDefleetType(args.value)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xl={4}>
                                        <div style={{ marginTop: '15px' }}>
                                            <AutoComplete
                                                name={'dropdown_po_reason_select'}
                                                title={window.t.en('Select Reason for Settlement Request')}
                                                label={'Reason for Settlement Request'}
                                                info={{
                                                    show: false
                                                }}
                                                fullWidth={true}
                                                data={this.props.state.defleetReason}
                                                value={this.props.state?.selectedDefleetReason?.text}
                                                onSelect={(args) => //this.props.actions.setComponentFieldInput(field, args.value)
                                                {
                                                    console.log('onSelect selectDefleetReason',args)
                                                    this.props.actions.selectDefleetReason(args.value)
                                                }
                                                }
                                                onClick={() =>
                                                    this.props.actions.getDefleetReason()
                                                }
                                                onFilter={(search) => {
                                                    if (search) {
                                                      let reason_id = this.props?.state?.defleetReason?.find(
                                                        (t) => t?.text === search
                                                      )?.value
                                                      this.props.actions.getDefleetReason()
                                                      this.props.actions.selectDefleetReason(reason_id)
                                                    } else {
                                                        this.props.actions.selectDefleetReason()
                                                        this.props.actions.getDefleetReason()
                                                    }
                                                  }
                                              }
                                            />
                                        </div>
                                    </Col>
                                    {
                                        this.props?.state?.selectedDefleetReason?.text
                                            ? <Col xl={12}>
                                                <TextField
                                                    variant='outlined'
                                                    id={'txt_comments'}
                                                    label={'Enter Comments/Details'}
                                                    fullWidth={true}
                                                    value={this.props.state.comments}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setValue('comments', event.target.value)
                                                        this.props.actions.setValidationValue('comments', event.target.value)
                                                    }}
                                                />
                                            </Col>
                                            : ''
                                    }
                                </Row>
                                <Row>
                                    <Col xl={12}></Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                    </Col>
                                </Row>
                                {/* <Row>
                                <Col xl={12}>{(this.props.state.selectedDefleetType.value === 1 || this.props.state.selectedDefleetType.value === 3) ? this.rebillRequestView(this.props.state.selectedDefleetType.text) : ''
                                }</Col>
                            </Row> */}
                                <Row>
                                    <Col xl={12}>
                                        {
                                            months < 12 && contract
                                                ? <div style={styles.redbox}>
                                                    <h2>{'Vehicle Not Eligible For De-fleet'}</h2>
                                                    <div style={styles.redboxtext}>
                                                        {'The selected contract does not qualify for a defleet based on the '}<b>{'Contract Age.'}</b>
                                                        <br></br>
                                                        {'Please note that '}<b>{'Early Termination Penalty '}</b>  {'might be due for this contract.'}<br></br>
                                                        <br></br>
                                                        <b>{'Please contact the Finance Department before you proceed!'}</b>
                                                        <br></br>
                                                        <br></br>
                                                        <b>{'Contract Type: '}</b>{contract.contracttype}<br></br>
                                                        <b>{'Contract Start Date: '}</b>{moment(contract.startdate).format('YYYY-MM-DD')}<br></br>
                                                        <b>{'Contract Age: '}</b>{'[' + months + '] Months'}
                                                        <br></br>
                                                        <br></br>
                                                        <Checkbox
                                                            id={'txt_contactedfinance'}
                                                            key={'txt_contactedfinance'}
                                                            name={'txt_contactedfinance'}
                                                            field={'contactedfinance'}
                                                            checked={this.props.state.contactedfinance}
                                                            labelPlacement={'end'}
                                                            label={window.t.en('Please confirm that Finance Department was contacted')}
                                                            // setComponentFieldInput={
                                                            //     this.props.actions.setValue
                                                            // }
                                                            onCheck={(checked) => {
                                                                this.props.actions.setValue('contactedfinance', checked)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                    </Col>
                                </Row>

                            </Container>} />
                        {
                            this.getTypesForm(this.props.state.selectedDefleetType?.text)
                                ? <Card
                                    title={this.props.state.selectedDefleetType?.text}
                                    subtitle={'Complete the form'}
                                    content={this.getTypesForm(this.props.state.selectedDefleetType?.text)} />
                                : ''
                        }

                    </>
                    : <strong style={styles.box1}>{'Kindly check if your not logged in twice, if the error persist please contact system administrator.'}</strong>
            }
            {vehicle && vehicle.length ? <ViewVehicle key={vehicle[0].vehicle_id} id={vehicle[0].vehicle_id} /> : ''}
        </div>
    }
    rebillRequestView(type) {
        const vehicle = this.props.state.selectedVehicleDetails.data
        return <div>
            <Card
                title={type + ' Rebill Request'}
                subtitle={window.t.en('Rebill Details')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Incident Type:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'e.g Accident'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.maintenance_type}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('maintenance_type', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Description:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'e.g Uneconomical to Repair'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.maintenance_description}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('maintenance_description', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    value={''}
                                    onChange={(event, value) => {
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Funder:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Funder Name'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.funder}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('funder', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Termination Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            type="date"
                                            id={'txtincidentdate'}
                                            hintText='Select date'
                                            container='inline'
                                            mode='landscape'
                                            fullWidth={true}
                                            value={this.props.state.terminationdate}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('terminationdate', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Latest Odometer:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Odo Reading...'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.latest_odo}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('latest_odo', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    value={''}
                                    onChange={(event, value) => {
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Trade Value:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.trade_value}
                                            onChange={(event, value) => {
                                                let market = this.getMarketValue(value, parseFloat(this.props.state.retail_value))
                                                this.props.actions.setValue('trade_value', value)
                                                this.props.actions.setValue('market_value', market)


                                                let val = this.getHighestValue(market, this.props.state.nbv_value, this.props.state.settle_value)
                                                this.props.actions.setValue('amount', val)

                                                let salvage_perc = this.props.state.salvage_percentage
                                                let salvage = (parseFloat((salvage_perc / 100) * val)).toFixed(2)
                                                this.props.actions.setValue('salvage_value', salvage)
                                                this.props.actions.setValue('sub_total', (val - salvage).toFixed(2))
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>

                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Retail Value:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.retail_value}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('retail_value', value)
                                                let market = this.getMarketValue(parseFloat(this.props.state.trade_value), value)
                                                this.props.actions.setValue('market_value', (parseFloat(market)).toFixed(2))


                                                let val = this.getHighestValue(market, this.props.state.nbv_value, this.props.state.settle_value)
                                                this.props.actions.setValue('amount', val)

                                                let salvage_perc = this.props.state.salvage_percentage
                                                let salvage = (parseFloat((salvage_perc / 100) * val)).toFixed(2)
                                                this.props.actions.setValue('salvage_value', salvage)
                                                this.props.actions.setValue('sub_total', (val - salvage).toFixed(2))

                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><b>{'Market Value:'}</b></div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={this.props.state.market_value}
                                            onChange={(event, value) => {
                                                let market = this.getMarketValue(value, parseFloat(this.props.state.retail_value))
                                                this.props.actions.setValue('trade_value', value)
                                                this.props.actions.setValue('market_value', market)

                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><b>{'Settle. Excl. Vat:'}</b></div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.settle_value}
                                            onChange={(event, value) => {
                                                let val = this.getHighestValue(this.props.state.market_value, value, this.props.state.settle_value)
                                                let salvage_perc = this.props.state.salvage_percentage
                                                this.props.actions.setValue('settle_value', value)
                                                this.props.actions.setValue('amount', val)

                                                let salvage = (parseFloat((salvage_perc / 100) * val)).toFixed(2)
                                                this.props.actions.setValue('salvage_value', salvage)
                                                this.props.actions.setValue('sub_total', (val - salvage).toFixed(2))
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><b>{'NBV Excl. Vat:'}</b></div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.nbv_value}
                                            onChange={(event, value) => {
                                                let val = this.getHighestValue(this.props.state.market_value, this.props.state.settle_value, value)
                                                let salvage_perc = this.props.state.salvage_percentage
                                                this.props.actions.setValue('nbv_value', value)
                                                this.props.actions.setValue('amount', val)

                                                let salvage = (parseFloat((salvage_perc / 100) * val)).toFixed(2)
                                                this.props.actions.setValue('salvage_value', salvage)
                                                this.props.actions.setValue('sub_total', (val - salvage).toFixed(2))
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><b>{'Highest Amount:'}</b></div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={this.props.state.amount}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    value={''}
                                    onChange={(event, value) => {
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}><div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Salvage %:'}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtchecksheet_odometer'}
                                        hintText={'Enter Amount'}
                                        fullWidth={true}
                                        multiLine={false}
                                        value={this.props.state.salvage_percentage}
                                        onChange={(event, value) => {
                                            let market = this.props.state.market_value

                                            let val = this.getHighestValue(market, this.props.state.nbv_value, this.props.state.settle_value)
                                            this.props.actions.setValue('amount', val)

                                            let salvage = (parseFloat((value / 100) * val)).toFixed(2)
                                            this.props.actions.setValue('salvage_percentage', value)
                                            this.props.actions.setValue('salvage_value', salvage)
                                            this.props.actions.setValue('sub_total', (val - salvage).toFixed(2))
                                        }}
                                    />
                                </div>
                            </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><b>{'Less Salvage:'}</b></div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            disabled={true}
                                            multiLine={false}
                                            value={this.props.state.salvage_value}
                                            onChange={(event, value) => {

                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><b>{'Sub Total:'}</b></div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            disabled={true}
                                            multiLine={false}
                                            value={this.props.state.sub_total}
                                            onChange={(event, value) => {

                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}><br /></Col>
                        </Row>
                    </Container>
                } />
            <Card content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}><br /></Col>
                </Row>
                <Row style={styles.tableheader}>
                    <Col xl={1}>{''}</Col>
                    <Col xl={5}>{'Description'}</Col>
                    <Col xl={2}>{'Amount'}</Col>
                </Row>
                {
                    this.props.state.selectedVehicleContractAccessories
                        ? this.props.state.selectedVehicleContractAccessories.data
                            .map((x, i) => {
                                return <Row style={styles.tablerow}>
                                    <Col xl={1} style={styles.verticalMiddle}>
                                        {/* <IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                                        onClick={
                                                            () => {
                                                                this.props.actions.removeRow(x.id)
                                                            }
                                                        }>
                                                        <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                                    </IconButton> */}
                                    </Col>
                                    <Col xl={5}>
                                        <TextField
                                            id={'txtdescription'}
                                            hintText={'Enter Description'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={x.accessoryname}
                                            onChange={(event, value) => {
                                                //this.props.actions.setUpdateOfferItem(x.id, 'description', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={2}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={x.amount}
                                            onChange={(event, value) => {
                                                //this.props.actions.setUpdateOfferItem(x.id, 'amount', value)
                                                //this.props.actions.setUpdateOfferItem(x.id, 'trade', Math.round((value / this.props.state.trade_value) * 100))
                                                // this.props.actions.setUpdateOfferItem(x.id, 'market', Math.round((value / this.props.state.market_value) * 100))
                                            }}
                                        />
                                    </Col>
                                </Row>

                            })
                        : <div></div>
                }
                {
                    this.props.state.accessories
                        ? this.props.state.accessories
                            .map((x, i) => {
                                return <Row style={styles.tablerow}>
                                    <Col xl={1} style={styles.verticalMiddle}>
                                        <IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                            onClick={
                                                () => {
                                                    this.props.actions.removeRow(x.id)
                                                }
                                            }>
                                            <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                    </Col>
                                    <Col xl={5}>
                                        <TextField
                                            id={'txtdescription'}
                                            hintText={'Enter Description'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={x.accessoryname}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateAccessoriesItem(x.id, 'accessoryname', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={2}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={x.amount}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateAccessoriesItem(x.id, 'amount', value)
                                                //this.getTotalAccessories()
                                            }}
                                        />
                                    </Col>
                                </Row>

                            })
                        : <div></div>
                }
                <Row>
                    <Col xl={6}>
                        <br />
                        < Button className={"global_button"} variant="contained"
                            key={'add_more_button'}
                            label={'+ Add'}
                            primary={true}
                            onClick={(event) => {
                                event.preventDefault()
                                this.addBlankRow()
                                //this.getTotalAccessories()
                            }}
                        >{window.t.en('+ Add')}</Button>
                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}><b>{'Accessories Total:'}</b></div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    id={'txtchecksheet_odometer'}
                                    hintText={'Enter Amount'}
                                    fullWidth={true}
                                    disabled={true}
                                    multiLine={false}
                                    value={this.props.state.accessories_total}
                                    onChange={(event, value) => {

                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>

                    </Col>
                    <Col xl={6}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}><b>{'Rebill Total:'}</b></div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    id={'txtchecksheet_odometer'}
                                    hintText={'Enter Amount'}
                                    fullWidth={true}
                                    disabled={true}
                                    multiLine={false}
                                    value={(parseFloat(this.props.state.accessories_total) + parseFloat(this.props.state.sub_total)).toFixed(2)}
                                    onChange={(event, value) => {

                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>} />
            <Card
                title={window.t.en('Rebill Supporting Documents')}
                subtitle={window.t.en('Rebill Documents')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <Upload
                                id={'upload_ctl'}
                                prefix={'Rebill_supporting Documents_' + vehicle.licenceplateno}
                                header={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <ImageUploader prefix={'img'} id={'img'} />
                        </Col>
                    </Row>
                </Container>} />
        </div>
    }

    addBlankRow() {
        var maxid = 0;
        this.props.state.accessories.map(function (obj) {
            if (obj.id > maxid) maxid = obj.id;
        });
        if (maxid === 0) {
            this.props.state.selectedVehicleContractAccessories.data.map(function (obj) {
                if (obj.id > maxid) maxid = obj.id;
            });
        }
        this.props.actions.addBlankRow((maxid ? Number(maxid) : 0) + 1)
    }
    getTotalAccessories() {
        let contract_acc = _.sumBy(this.props.state.accessories, (d) => { return parseFloat(d.amount) }).toFixed(2)
        let acc = _.sumBy(this.props.state.selectedVehicleContractAccessories.data, (d) => { return parseFloat(d.amount) }).toFixed(2)

        this.props.actions.setValue('accessories_total', (parseFloat(contract_acc) + parseFloat(acc)).toFixed(2))
        return (parseFloat(contract_acc) + parseFloat(acc)).toFixed(2)
    }
    getMarketValue(t, r) {
        let tr = parseFloat(t)
        let re = parseFloat(r)
        let val = (tr ? tr : re) ? (tr ? tr : re) : 0.00
        if (tr & re) {
            val = (tr + re) / 2
        }
        return parseFloat(val)
    }
    getHighestValue(m, s, n) {
        let market = parseFloat(m)
        let settle = parseFloat(s)
        let nbv = parseFloat(n)

        let val = (market > settle ? market : settle) < nbv ? nbv : (market > settle ? market : settle)
        return parseFloat(val)
    }

    render() {
        return this.view();
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDefleetView
        }
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDefleet)
