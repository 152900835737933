export const po_filepreview = ''
export const po_filename = ''
export const sign_filepreview = ''
export const sign_filename = ''
export const comments = ''
export const documents = []

export const bookingdate = null
export const po_date = null
export const po_number = ''
export const po_amount = ''

export const fields = {
    po_amount: {
        state: 'getState().poBookingAcceptRebill.po_amount',
        errorText: 'Please enter Purchase Order Total Amount',
        valid: false,
        empty: true,
        value: '',
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Purchase Order Total Amount'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Purchase Order Total Amount'
            }
        ]
    },
    po_number: {
        state: 'getState().poBookingAcceptRebill.po_number',
        errorText: 'Please enter Purchase Order Number',
        value: '',
        valid: false,
        empty: true,
    },
    po_date: {
        state: 'getState().poBookingAcceptRebill.po_date',
        errorText: 'Please enter PO signed date',
        value: '',
        valid: false,
        empty: true,
    },
    bookingdate: {
        state: 'getState().poBookingAcceptRebill.bookingdate',
        errorText: 'Please enter Vehicle Booking Date',
        value: '',
        valid: false,
        empty: true,
    },

    documents: {
        errorText: 'Please add a document and select document type',
        data: false,
        valid: false,
        empty: true,
        popup: true,
        type: 'documents',
    }
}