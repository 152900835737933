// export const GET_ADHOC_FIELD_INPUT_DATA = 'GET_ADHOC_FIELD_INPUT_DATA'
// export const SET_ADHOC_FIELD_INPUT_DATA = 'SET_ADHOC_FIELD_INPUT_DATA'
// export const ADD_ADHOC_FIELD_INPUT_DATA = 'ADD_ADHOC_FIELD_INPUT_DATA'
// export const VALIDATE_ADHOC_FIELD_INPUT_DATA = 'VALIDATE_ADHOC_FIELD_INPUT_DATA'
// export const REMOVE_ADHOC_FIELD_INPUT_DATA = 'REMOVE_ADHOC_FIELD_INPUT_DATA'
// export const SET_ADHOC_COMPONENT_FIELD_INPUT = 'SET_ADHOC_COMPONENT_FIELD_INPUT'

// export const GET_DEBTORS_LIST = 'GET_DEBTORS_LIST'
// export const SET_DEBTORS_LIST = 'SET_DEBTORS_LIST'
// export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'

// export const GET_BUSINESS_LIST = 'GET_BUSINESS_LIST'
// export const SET_BUSINESS_LIST = 'SET_BUSINESS_LIST'
// export const SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS'

// export const GET_CONTRA_ACCOUNT_LIST = 'GET_CONTRA_ACCOUNT_LIST'
// export const SET_CONTRA_ACCOUNT_LIST = 'SET_CONTRA_ACCOUNT_LIST'
// export const SET_SELECTED_CONTRA_ACCOUNT = 'SET_SELECTED_CONTRA_ACCOUNT'
// export const SET_SELECTED_DELIVERY_ACCOUNT = 'SET_SELECTED_DELIVERY_ACCOUNT'

// export const SET_FIELD_INPUT_VALUE = 'SET_FIELD_INPUT_VALUE'

// export const SET_CREDITOR_INVOICE_ITEMS = 'SET_CREDITOR_INVOICE_ITEMS'
// export const REMOVE_INVOICE_ITEM_ROW = 'REMOVE_INVOICE_ITEM_ROW'
// export const SET_ITEM_INPUT_VALUE = 'SET_ITEM_INPUT_VALUE'

// export const SET_DELIVERY_CHARGE_ACCOUNT_LIST = 'SET_DELIVERY_CHARGE_ACCOUNT_LIST'
// export const SET_DISCOUNT_ACCOUNT_LIST = 'SET_DISCOUNT_ACCOUNT_LIST'

// export const GET_DISCOUNT_ACCOUNT_LIST = 'GET_DISCOUNT_ACCOUNT_LIST'
// export const GET_DELIVERY_CHARGE_ACCOUNT_LIST = 'GET_DELIVERY_CHARGE_ACCOUNT_LIST'

// export const SET_BANK_ACCOUNT_LIST = 'SET_BANK_ACCOUNT_LIST'

// export const SAVE_CUSTOMER_INVOICE_DATA = 'SAVE_CUSTOMER_INVOICE_DATA'
// export const GET_CUSTOMER_INVOICE_DATA = 'GET_CUSTOMER_INVOICE_DATA'
// export const SAVED_CUSTOMER_INVOICE_DATA = 'SAVED_CUSTOMER_INVOICE_DATA'

// export const CHECK_EXISTING_INVOICE = 'CHECK_EXISTING_INVOICE'
// export const SET_INVOICE_INVALID = 'SET_INVOICE_INVALID'

// export const GET_ITEM_ACCOUNT_LIST = 'GET_ITEM_ACCOUNT_LIST'
// export const SET_ITEM_ACCOUNT_LIST = 'SET_ITEM_ACCOUNT_LIST'
// export const SET_SELECTED_ITEM_ACCOUNT = 'SET_SELECTED_ITEM_ACCOUNT'

// export const SET_DEBTOR_INVOICE_ITEMS = 'SET_DEBTOR_INVOICE_ITEMS'
// export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'

// export const SET_ANY_BUSINESS_LIST = 'SET_ANY_BUSINESS_LIST'
// export const SET_ANY_INVOICE_ITEMS = 'SET_ANY_INVOICE_ITEMS'
// export const ADD_ANY_BLANK_ROW = 'ADD_ANY_BLANK_ROW'
// export const SET_ANY_ITEM_INPUT_VALUE = 'SET_ANY_ITEM_INPUT_VALUE'
// export const REMOVE_ANY_INVOICE_ITEM_ROW = 'REMOVE_ANY_INVOICE_ITEM_ROW'
// export const SET_ANY_CONTRA_ACCOUNT_LIST = 'SET_ANY_CONTRA_ACCOUNT_LIST'
// export const GET_DEBTORS_ACCOUNT_LIST = 'GET_DEBTORS_ACCOUNT_LIST'
// export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
// export const GET_UNIT_MEASURE_LIST = 'GET_UNIT_MEASURE_LIST'
// export const SET_ANY_UNIT_MEASURE_LIST = 'SET_ANY_UNIT_MEASURE_LIST'
// export const GET_INVOICE_LIST = 'GET_INVOICE_LIST'
// export const SET_INVOICE_LIST = 'SET_INVOICE_LIST'

// export const GET_INVOICE_DUPLICATE_REFERENCE_CHECK = 'GET_INVOICE_DUPLICATE_REFERENCE_CHECK'
// export const INVOICE_DUPLICATE_FOUND = 'INVOICE_DUPLICATE_FOUND'
// export const INVOICE_NO_DUPLICATE_FOUND = 'INVOICE_NO_DUPLICATE_FOUND'


export const GET_VEHICLES_ACTION = 'GET_VEHICLES_ACTION'
export const GET_VEHICLES_SELECTED_ACTION = 'GET_VEHICLES_SELECTED_ACTION'
export const SET_VEHICLES_ACTION = 'SET_VEHICLES_ACTION'
export const SET_VEHICLES_SELECTED_ACTION = 'SET_VEHICLES_SELECTED_ACTION'
export const SET_VEHICLE_CUSTOMER_VALUE = 'SET_VEHICLE_CUSTOMER_VALUE'
export const GET_CUSTOMER_ACTION = 'GET_CUSTOMER_ACTION'
export const SET_CUSTOMER_ACTION = 'SET_CUSTOMER_ACTION'
export const SET_NEW_CUSTOMER_VALUE = 'SET_NEW_CUSTOMER_VALUE'
export const FINALIZE_VEHICLE_TRANSFER_ACTION = 'FINALIZE_VEHICLE_TRANSFER_ACTION'
export const SET_FINALIZE_VEHICLE_TRANSFER = 'SET_FINALIZE_VEHICLE_TRANSFER'
export const SET_DATE_FIELD_INPUT_VALUE = 'SET_DATE_FIELD_INPUT_VALUE'



