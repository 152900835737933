export const newFeatures = []
export const bugFixes = []
export const releaseDate = ''
export const item = ''
export const releaseVersion = ''
export const releaseEnhancements = []
export const description = ''
export const dialogState = false
export const tab = 0
export const page = 0
export const rowsPerPage = 5
export const releaseNote = {}
