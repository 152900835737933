import * as types from './types'
import * as accountActions from '../Account/actions'

export const getClientsList = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLIENTS_GET_CLIENTS_LIST
        })
    }
}

export const setClientsList = (payload) => {
    payload.unshift()
    
    return (dispatch, getState) => {
        //console.log('YYYY CLIENTS_SET_CLIENTS_LIST',payload)
        dispatch({
            type: types.CLIENTS_SET_CLIENTS_LIST,
            payload: payload
        })
        dispatch(accountActions.setAppSignin(payload))
        
    }
}