
export const visibility = (toggled) => {
    return toggled
        ? { display: 'block' }
        : { display: 'none' }
}

export const commands = {
    textAlign: 'right'
}

export const item = {
    display: 'flex'
}

export const flex = {
    width: '100%',
    display: 'flex'
}

export const table = {
    width: '100%',
    display: 'flex'
}

export const cell = {
    display: 'cell',
    width: '50%'
}

export const dropdown = {
    width: '100%'
}

export const right = {
    textAlign: 'right',
    display: 'cell',
    width: '50%'
}

export const image = {
    textAlign: 'right',
    width: '350px',
    height: '200px'
}
export const cardaction = {
    marginRight: 20,
    fontSize: '10px',
    left: '25px',
}
export const cardnumber = {
    textAlign: 'left',
    display: 'cell',
    fontSize: '25px',
    color: '#fff',
    position: 'relative',
    top: '200px',
    left: '38px',
    textSpacing: 1
}

export const cardexpiry = {
    textAlign: 'left',
    display: 'cell',
    fontSize: '16px',
    color: '#fff',
    position: 'relative',
    top: '205px',
    left: '140px',
    width: '100%'
}
export const cardname = {
    textAlign: 'left',
    display: 'cell',
    fontSize: '14px',
    color: '#fff',
    position: 'relative',
    top: '195px',
    left: '35px',
    width: '100%'
}

export const cardtype = {
    textAlign: 'left',
    display: 'cell',
    fontSize: '14px',
    color: '#fff',
    position: 'relative',
    top: '195px',
    left: '35px',
    width: '100%'
}
export const actionHoverColor = {
    color: 'red',
}

export const iconEdit = {
    height: '18px',
    position: 'absolute',
    top: '200px',
    left: '35px',
    padding: 0,
    margin: 0
}
export const iconNotification = {
    height: '18px',
    position: 'absolute',
    top: '200px',
    left: '65px',
    padding: 0,
    margin: 0
}
export const actionIcon = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#fff',
}

export const liPrimary = {
    fontSize: '15px',
    fontWeight: 'bold',
}
export const cardDropdown = {
    backgroundColor: "white"
}
export const actionHeaderIcon = {
    fontSize: '25px',
    fontWeight: 'bold',
    color: 'silver'
}

export const list_subheader = {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#01458e'
}

export const mondateheader = {
    fontSize: '16px',
    color: 'white',
    padding: 0,
    backgroundColor: '#0070b9'
}


export const mondatesubheader = {
    fontSize: '16px',
    color: 'white',
    padding: 0,
    border: '#0062b4 2px solid'
}
export const iconButton = {
    height: '25px',
    color: 'white',
    backgroundColor: '#fff',
    padding: 0,
    margin: 0
}

export const gridOptionsIcon = {

}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 2,
    verticalAlign: 'left'
}

export const cardamount = {
    //backgroundColor: '#dcd6d7',
    padding: 2,
    fontWeight: 'bold',
    textAlign: 'right',
    verticalAlign: 'right'
}
export const fieldContainer = {
    display: 'table',
    padding: 4,
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '20px',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}


export const gridIconsTheme = (appName) => {
    if(appName === 'bidvest'){
        return {fill: '#2947AE'}
    }else{
        return {fill: '#141e30'}
    }
}