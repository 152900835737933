import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';

import Icon from '../Icon/component'
import View from '../View/component'
import Form from '../Form/component'
import Theme from '../../theme'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowTaskStepComponentMultipleCreator extends Component {
    render() {
        this.component = this.props.state.component

        this.data = this.props.state.task.workflow_task_step
            ? this.props.state.task.workflow_task_step
                .filter(x =>
                    x.name === this.props.step &&
                    x.workflow_task_step_component.filter(y => y.component.name === this.props.name).length
                )
                .reduce((arr, x) => {
                    if (x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length).length) {
                        arr.push(x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length)[0].data)
                    }

                    return arr
                }, [])
            : null

        return (
            <div>
                <Form
                    key={'workflowTaskInput_form' + this.props.name + this.props.id}
                    name={this.props.name}
                    id={this.props.id}
                    related={false}
                />

                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} style={styles.addComponentRow}>
                           < Button className={"global_button"} variant="contained"
                                label={'Add'}
                                primary={true}
                                onClick={(event) => {
                                    this.props.actions.actions.addComponentRow(this.props.step, this.props.name)
                                }}
                                >{window.t.en('Add')}</Button>
                        </Col>
                    </Row>
                    {this.data.length
                        ? <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        : ''
                    }
                    {this.data.length
                        ? this.data[0].map((x, i) => {
                            return <Row>
                                <Col xl={0.5}>
                                    <IconButton
                                        iconStyle={styles.deleteIcon}
                                        tooltip="Remove"
                                        onClick={(event) => {
                                            this.props.actions.removeComponentRow(this.props.step, this.props.name, i)
                                        }}
                                    >
                                        <Icon ihovercolor={Theme.palette.primary1Color} iclass={'material-icons'} iname={'delete_forever'} />
                                    </IconButton>
                                </Col>
                                <Col xl={11.5}>
                                    <View
                                        key={'view_' + this.component.name}
                                        name={this.component.name}
                                        data={x}
                                        surrogate={false}
                                    />
                                </Col>
                            </Row>
                        })
                        : ''
                    }
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowTaskInput,
            component: {
                ...state.components[ownProps.name]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskStepComponentMultipleCreator)