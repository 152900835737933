export const preview = ''
export const vinnumber = ''
export const comments = ''
export const oemdeliverydata = ''

export const validation = {
  fields: [],
  component: 'workflowVehicleDeliveryConfirm',
  list: [],
  valid: false
}

export const fields = {
  vin: {
    id: 'txt_vinnumber',
    name: 'txt_vinnumber',
    errorText: 'Please enter Vehicle VIN Number',
    state: 'getState().workflowVehicleDeliveryConfirm.vinnumber',
    data: false,
    valid: false,
    empty: true,
    section: 'Vehicle Details'
  },
  deliveryDate: {
    id: 'deliverydata',
    name: 'deliverydata',
    errorText: 'Please enter Vehicle Delivery Address',
    state: 'getState().workflowVehicleDeliveryConfirm.oemdeliverydata',
    data: false,
    valid: false,
    empty: true,
    section: 'Vehicle Details'
  },
};