export const GET_VEHICLE_LIST = 'GET_VEHICLE_LIST'
export const SET_VEHICLE_LIST = 'SET_VEHICLE_LIST'

export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const GET_VEHICLE_DETAILS = 'GET_VEHICLE_DETAILS'
export const SET_VEHICLE_DETAILS = 'SET_VEHICLE_DETAILS'
export const SAVE_DISPOSAL_DATA = 'SAVE_DISPOSAL_DATA'
export const GET_TERMINATION_LIST = 'GET_TERMINATION_LIST'
export const SET_TERMINATION_LIST = 'SET_TERMINATION_LIST'
export const APPROVE_TERMINATION = 'APPROVE_TERMINATION'