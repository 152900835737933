export const loading = false
export const data = []
export const tab = 0
export const delete_record_id = null
export const fields = [
    {
        name: 'parent',
        section: 'General Info',
        title: 'Parent Team',
        type: 'treeSelect',
        width: 200,
        order: 2,
        col: 2,
        align: 'left',
        icon: 'edit',
        hidden: false,
        read_only: false,
        component: 'client',
        related: null,
        display: 'customername',
        value: 'customer_id'
    },
    {
        name: 'name',
        section: 'General Info',
        title: 'Team Name',
        type: 'textBox',
        width: 200,
        order: 2,
        col: 2,
        align: 'left',
        icon: 'edit',
        hidden: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'name',
        section: 'General Info',
        title: 'Team Name',
        type: 'textBox',
        width: 200,
        order: 2,
        col: 2,
        align: 'left',
        icon: 'edit',
        hidden: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    }
]
