export const GET_BUDGET_ACCOUNT_DATA = 'GET_BUDGET_ACCOUNT_DATA'
export const SET_BUDGET_ACCOUNT_DATA = 'SET_BUDGET_ACCOUNT_DATA'
export const SET_BUDGET_ACCOUNT_CURRENT_TAB = 'SET_BUDGET_ACCOUNT_CURRENT_TAB'

export const TOGGLE_DELETED_WIN_STATUS = 'TOGGLE_DELETED_WIN_STATUS'
export const SET_BUDGET_COMPONENT_FIELD_INPUT = 'SET_BUDGET_COMPONENT_FIELD_INPUT'

export const SAVE_BUDGET_COMPONENT_CUSTOMER_DATA = 'SAVE_BUDGET_COMPONENT_CUSTOMER_DATA'
export const GET_BUDGET_COMPONENT_FIELD_LOOKUP_DATA = 'GET_BUDGET_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_BUDGET_COMPONENT_FIELD_LOOKUP_DATA = 'SET_BUDGET_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_BUDGET_COMPONENT_FIELD_INPUT_AND_TEXT = 'SET_BUDGET_COMPONENT_FIELD_INPUT_AND_TEXT'

export const DELETE_COMPONENT_BUDGET_RECORD = 'DELETE_COMPONENT_BUDGET_RECORD'
export const DELETE_COMPONENT_BUDGET_RECORD_DONE = 'DELETE_COMPONENT_BUDGET_RECORD_DONE'
export const GET_BUDGET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA = 'GET_BUDGET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA'
export const SET_BUDGET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA = 'SET_BUDGET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA'