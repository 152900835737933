import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'

import * as styles from './styles'
import * as actions from './actions'
import moment from 'moment';
import TextField from '@mui/material/TextField'
import Upload from '../../Upload/component'
import Dropzone from 'react-dropzone'


import Button from '@mui/material/Button'
import ReactTable from "react-table"
import Icon from '../../Icon/component'

import { green, red } from '@mui/material/colors'


class StatementReview extends Component {
    componentDidMount() {
        this.props.actions.reset()
    }

    getForm() {
        return <div>
            <Card title={window.t.en('Statements')}
                subtitle={window.t.en('Initialize statement workflow')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <div>
                                    <h4>Statement Run: {moment().format('YYYY MMM')}</h4>
                                    <ul>
                                        <li>Send statements to customers</li>
                                        <li>Ensure to run last day on the month</li>
                                        <li>Ensure technical have close all their tasks</li>
                                        <li>Interest scirpt will run next</li>
                                    </ul>
                                    <i>This step is to initialize the statement workflow process.</i>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                } />
        </div>
    }
    
    commentsView() {
        return <div>
                <Card 
                    title={window.t.en('Comments')}
                    subtitle={window.t.en('Enter comments')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    id={'txtcomment'}
                                    key={'txtcomment'}
                                    label={window.t.en('Enter Comments...')}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.props.state.comments}
                                    onChange={
                                        (event) => {
                                            this.props.actions.setValue('comments', event.target.value)
                                        }
                                    }
                                /></Col>
                        </Row>
                    </Container>
                } 
            />
        </div>
    }

    getConfirm() {
        return <div>
            <Card title={window.t.en('Statements Confirm')}
                subtitle={window.t.en('Initializing statement workflow')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <div>
                                    <h4>Statement Run For: {moment().format('YYYY MMM')}</h4>
                                    <ul>
                                        <li>Send statements to customers</li>
                                        <li>Ensure to run last day on the month</li>
                                        <li>Ensure technical have close all their tasks</li>
                                        <li>Interest scirpt will run next</li>
                                    </ul>
                                    <h4>Your Comments</h4>
                                    <ul>
                                        <li>{this.props.state.comments}</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                } />
        </div>
    }

    render() {
        return (this.props.mode === 'confirm' ? this.getConfirm() : [
            this.getForm(),
            this.commentsView()
        ])
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.statementReview
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatementReview)