import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'
export const getJournalList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_JOURNAL_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                //url: config.system.api + '/components/suppliertransaction/search?transactiontype=Journal&reference='+ payload.search_text,
                url: config.system.api + '/components/suppliertransaction/searchor?supplier_fktext=' + payload.search_text + '&reference=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'reference',
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setJournalList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
export const getCreditorsList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_CREDITORS_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplier/searchor?suppliername=' + payload.search_text + '&accountnumber=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'suppliername',
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setSupplierList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getBusinessList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_BUSINESS_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/business/search?businessname=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'businessname',
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setBusinessList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getDebtorsContraAccountList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_CONTRA_ACCOUNT_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accountingitem/search?itemdescription=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'itemdescription',
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setContraAccountList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const saveJournalEpic = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.SAVE_JOURNAL_DATA,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplierjournal/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload.data
            })
                .pipe(
                    mergeMap((result) => [
                        actions.savedJournalData(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
