export const GET_ALLOCATION_CREDITORS_LIST = 'GET_ALLOCATION_CREDITORS_LIST'
export const SET_ALLOCATION_CREDITORS_LIST = 'SET_ALLOCATION_CREDITORS_LIST'

export const SET_ALLOCATION_CREDITORS_LOOKUP = 'SET_ALLOCATION_CREDITORS_LOOKUP'
export const SET_ALLOCATION_FIELD_INPUT_VALUE = 'SET_ALLOCATION_FIELD_INPUT_VALUE'

export const GET_ALLOCATION_BUSINESS_LIST = 'GET_ALLOCATION_BUSINESS_LIST'
export const SET_ALLOCATION_BUSINESS_LIST = 'SET_ALLOCATION_BUSINESS_LIST'

export const GET_ALLOCATION_ACCOUNT_LIST = 'GET_ALLOCATION_ACCOUNT_LIST'
export const SET_ALLOCATION_ACCOUNT_LIST = 'SET_ALLOCATION_ACCOUNT_LIST'

export const GET_ALLOCATION_DISCOUNT_LIST = 'GET_ALLOCATION_DISCOUNT_LIST'
export const SET_ALLOCATION_DISCOUNT_LIST = 'SET_ALLOCATION_DISCOUNT_LIST'

export const GET_ALLOCATION_ACCOUNT = 'GET_ALLOCATION_ACCOUNT'
export const SET_ALLOCATION_ACCOUNT = 'SET_ALLOCATION_ACCOUNT'
export const SAVE_ALLOCATION_DATA = 'SAVE_ALLOCATION_DATA'
export const SAVED_PAYMENT_ALLOCATION_DATA = 'SAVED_PAYMENT_ALLOCATION_DATA'

export const GET_SUPPLIER_ALLOCATION_DATA = 'GET_SUPPLIER_ALLOCATION_DATA'
export const SET_SUPPLIER_ALLOCATION_DATA = 'SET_SUPPLIER_ALLOCATION_DATA'

export const SET_CALCULATED_ALLOCATION_DATA = 'SET_CALCULATED_ALLOCATION_DATA'
export const SET_FIELD_ALLOCATION_VALUE = 'SET_FIELD_ALLOCATION_VALUE'

export const GET_ALLOCATE_ALL_DATE = 'GET_ALLOCATE_ALL_DATE'

export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const SAVE_UNALLOCATION_DATA = 'SAVE_UNALLOCATION_DATA'

export const SET_ANY_SUPPLIER_ALLOCATION_DATA = 'SET_ANY_SUPPLIER_ALLOCATION_DATA'