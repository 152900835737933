import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

// import Drawer from '@mui/material/Drawer';
import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'

import Drawer from '../../../controls/drawer'
import Card from '../../../controls/card'	
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDisposalView extends Component {

    getVehicleView() {
        //console.log('this.props.state.infodatainx',this.props.state.infodatainx)
        let _data = this.props.state.yardlist[this.props.state.infosheet_inx]
        //console.log('this.props.state._data',_data)
        let mt = {}
        try {  
            mt = _data.maintenancetransaction.filter(x => x.deleted_at === null && x.maintenancetype === 'Defleet Repairs')[0]
        }catch{ }

        let cc = _data.customercontract.length ? _data.customercontract[0] : {}
        let ve = _data
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>{'Vehicle Registration'}</Col><Col xl={6}>{ve.licenseplateno}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Chassis Number'}</Col><Col xl={6}>{ve.chassisnumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Engine Number'}</Col><Col xl={6}>{ve.enginenumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Make Model'}</Col><Col xl={6}>{ve.makemodelyear}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'MM Code'}</Col><Col xl={6}>{ve.mmcode.mmcode}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Current Odometer'}</Col><Col xl={6}>{ve.openingodometer}</Col>
                    </Row>
                    {/* <Row>
                        <Col xl={6}>{'Funder Name'}</Col><Col xl={6}>{ve.fundername}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Supplier/OEM'}</Col><Col xl={6}>{ve.suppliername}</Col>
                    </Row> */}
                    <Row>
                        <Col xl={6}>{'Vehicle Status'}</Col><Col xl={6}>{ve.vehiclestatus ? ve.vehiclestatus.vehiclestatus : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Registration Date'}</Col><Col xl={6}>{ve.registrationdate ? moment(ve.registrationdate).format('YYYY-MM-DD') : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'License Expiry'}</Col><Col xl={6}>{ve.licenceexpirydate ? moment(ve.licenceexpirydate).format('YYYY-MM-DD') : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}><hr /></Col>
                    </Row>
                </Container>
            </div>
        }
        
        getContractView() {
        //console.log('this.props.state.infodatainx',this.props.state.infodatainx)
        let _data = this.props.state.yardlist[this.props.state.infosheet_inx]
        //console.log('this.props.state._data getContractView',_data)
        let mt = {}
        try {  
            mt = _data.maintenancetransaction.filter(x => x.deleted_at === null && x.maintenancetype === 'Defleet Repairs')[0]
        }catch{ }

        let cc = _data.customercontract.length ? _data.customercontract[0] : {}
        let ve = _data
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>{'Contract Number'}</Col><Col xl={6}>{cc.contractnumber}</Col>
                    </Row>
                    {/* <Row>
                            <Col xl={6}>{'Contract Status'}</Col><Col xl={6}>{cc.contractstatus}</Col>
                    </Row> */}
                    <Row>
                            <Col xl={6}>{'Start Date'}</Col><Col xl={6}>{cc.startdate ? moment(cc.startdate).format('YYYY-MM-DD') : ''}</Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Termination Date'}</Col><Col xl={6}>{cc.terminationdate ? moment(cc.terminationdate).format('YYYY-MM-DD') : ''}</Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Billing Count'}</Col><Col xl={6}>{cc.customercontractderivative ? cc.customercontractderivative[0].billingcount : ''}</Col>
                    </Row>
                    <Row>
                            <Col xl={12}><hr /></Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Contract Period'}</Col><Col xl={6}>{cc.period}</Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Monthly Inclusive'}</Col><Col xl={6}>{cc.distancepermonth}</Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Contract Distance'}</Col><Col xl={6}>{cc.distance}</Col>
                        </Row><Col xl={12}><hr /></Col>
                    </Container>
            </div>
        }
        
        getFundsView() {
        //console.log('this.props.state.infodatainx',this.props.state.infodatainx)
        let _data = this.props.state.yardlist[this.props.state.infosheet_inx]
        //console.log('this.props.state._data',_data)
        let mt = {}
        try {  
            mt = _data.maintenancetransaction.filter(x => x.deleted_at === null && x.maintenancetype === 'Defleet Repairs')[0]
        }catch{ }

        let cc = _data.customercontract.length ? _data.customercontract[0] : {}
        let ve = _data
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>{'Maintenance Income'}</Col><Col xl={6}>{cc.customercontractderivative ? cc.customercontractderivative[0].maintenanceincome : ''}</Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Maintenance Expenditure'}</Col><Col xl={6}>{cc.customercontractderivative ? cc.customercontractderivative[0].maintenanceexpenditure : ''}</Col>
                    </Row>
                    <Row>
                            <Col xl={12}><hr /></Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Tyre Income'}</Col><Col xl={6}>{cc.customercontractderivative ? cc.customercontractderivative[0].tyresincome : ''}</Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Tyre Expenditure'}</Col><Col xl={6}>{cc.customercontractderivative ? cc.customercontractderivative[0].tyresexpenditure : ''}</Col>
                        </Row><Col xl={12}><hr /></Col>
                    </Container>
       </div>
    }
    
    getOfferView() {
        let _data = this.props.state.yardlist[0]
        let mt = {}
        try {  
            mt = _data.disposalbidder
        }catch{ }

        return <Container style={{ margin: 0 }} className="custom-container"><Row>
                <Col xl={2}>{'Accepted'}</Col>
                <Col xl={4}>{'Bidder'}</Col>
                <Col xl={2}>{'Repaired'}</Col>
                <Col xl={2}>{'Un Repaired'}</Col>
                <Col xl={2}>{'Accepted Date'}</Col>
        </Row>
            {
                _data.disposalbidder.map(x => {
                    return  <Row>
                            <Col xl={2}>{x.accepted ? 'Yes' : 'No'}</Col>
                            <Col xl={4}>{x.biddername}</Col>
                            <Col xl={2}>{x.offerbasedonrepaired}</Col>
                            <Col xl={2}>{x.offerbasedonunrepaired}</Col>
                            <Col xl={2}>{x.accepted_at ? moment(x.accepted_at).format('YYYY-MM-DD') : 'N/A'}</Col>
                    </Row>
                })   
            }
        </Container>
        {/* </Container>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>{'Maintenance Income'}</Col><Col xl={6}>{cc.maintenanceincome}</Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Maintenance Expenditure'}</Col><Col xl={6}>{cc.maintenanceexpenditure}</Col>
                    </Row>
                    <Row>
                            <Col xl={12}><hr /></Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Tyre Income'}</Col><Col xl={6}>{cc.tyresincome}</Col>
                    </Row>
                    <Row>
                            <Col xl={6}>{'Tyre Expenditure'}</Col><Col xl={6}>{cc.tyresexpenditure}</Col>
                        </Row>
                    </Container>
       </div> */}
    }
    getMaintenanceView() {
        //console.log('this.props.state.infodatainx',this.props.state.infodatainx)
        let _data = this.props.state.yardlist[this.props.state.infosheet_inx]
        //console.log('this.props.state._data',_data)
        let mt = null
        try {  
            mt = _data.maintenancetransaction.filter(x => x.deleted_at === null && x.maintenancetype=== 'Defleet Repairs')[0]
        }catch{ }

        let cc = _data.customercontract.length ? _data.customercontract[0] : {}
        let ve = _data
        return mt ? <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>{'Maintenance Tye'}</Col><Col xl={6}>{mt.maintenancetype}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Maintenance Status'}</Col><Col xl={6}>{mt.maintenancestatus}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Create Date'}</Col><Col xl={6}>{mt.created_at ? moment(mt.created_at).format('YYYY-MM-DD HH:mm') : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}><hr /></Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Task Number'}</Col><Col xl={6}>{mt.tasknumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Auth Number'}</Col><Col xl={6}>{mt.authnumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Quote Number'}</Col><Col xl={6}>{mt.quotenumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Quote Date'}</Col><Col xl={6}>{mt.quotedate}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Supplier Name'}</Col><Col xl={6}>{mt.suppliername}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Booked Days'}</Col><Col xl={6}>{mt.bookingdays}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Total Amount Incl'}</Col><Col xl={6}>{mt.totalamount}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Booking Comments'}</Col><Col xl={6}>{mt.comments}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Booked By'}</Col><Col xl={6}>{mt.bookedby}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Booked Date'}</Col><Col xl={6}>{mt.bookeddate ? moment(mt.bookeddate).format('YYYY-MM-DD HH:mm') : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Captured By'}</Col><Col xl={6}>{mt.capturedby}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Captured Date'}</Col><Col xl={6}>{mt.capturedate ? moment(mt.capturedate).format('YYYY-MM-DD HH:mm') : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}><hr /></Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Tech Approved Date'}</Col><Col xl={6}>{mt.approved}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Tech Approved By'}</Col><Col xl={6}>{mt.approvedby}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'GM By'}</Col><Col xl={6}>{mt.gmapprovedby}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'GM Date'}</Col><Col xl={6}>{mt.gmapprovedate ? moment(mt.gmapprovedate).format('YYYY-MM-DD HH:mm') : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'CEO By'}</Col><Col xl={6}>{mt.ceoapprovedby}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'CEO Date'}</Col><Col xl={6}>{mt.ceoapprovedate ? moment(mt.ceoapprovedate).format('YYYY-MM-DD HH:mm') : ''}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Completed By'}</Col><Col xl={6}>{mt.closedby}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Completed Date'}</Col><Col xl={6}>{mt.completeddate ? moment(mt.completeddate).format('YYYY-MM-DD HH:mm') : ''}</Col>
                    </Row><Col xl={12}><hr /></Col>
                </Container>
        </div>
        : 'No Defleet Transaction'
    }
    
    getView() {
        return <div> 
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                        General Details
                    </div>
                    <p className="text-black-50">Vehicle details</p>
                </div>
                {this.getVehicleView()}
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Contract Details
                    </div>
                    <p className="text-black-50">Vehicle contract details</p>
                </div>
                {this.getContractView()}
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Vehicle Funds
                    </div>
                    <p className="text-black-50">Vehicle maintenance and tyres fund details</p>
                </div>
                {this.getFundsView()}
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Maintenance Details
                    </div>
                    <p className="text-black-50">Rebill maintenance details</p>
                </div>
                {this.getMaintenanceView()}
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Offer Details
                    </div>
                    <p className="text-black-50">Rebill maintenance details</p>
                </div>
                {this.getOfferView()}
        </div>
    }

    getLayout() {
        return  <Drawer 
            width={'950px'}
            onClose={(event, value) => {
                this.props.actions.toggleInfoSheet(null,'inforsheet_view')
            }}
            open={this.props.state.inforsheet_view}
            title={'Vehicle Details'}
            subtitle={''}
            content={this.getView()}
        />
        
        // <Drawer
        //         width={800}
        //         anchor={'right'}
        //         openSecondary={true}
        //         open={this.props.state.inforsheet_view}
        //         onRequestChange={(event) => {
        //             event.preventDefault()
        //             this.props.actions.toggleInfoSheet(null,'inforsheet_view')
        //         }}>
        //         {this.getView() }
        //     </Drawer>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalView)