import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import ImageUploader from '../../ImageUploader/component'

import * as styles from './styles'
import * as actions from './actions'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'

class POBookingSendCustomerRebillInvoice extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
    }


    VehicleDetails(data) {
        return <Card>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('Vehicle Information: ' + data.businessname)}
                subtitle={window.t.en('Selected vehicle details.')}
                icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                style={styles.row}
            >
            </CardHeader>
            <CardContent>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.licenseplateno}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Fleet Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fleetno}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Status:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vehiclestatus}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Odometer:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.closingodometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.make}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mgdescription}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mmcode}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle First Registration Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.registrationdate ? moment(data.registrationdate).format('DD-MM-YYYY') : data.registrationdate}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Chassis Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.chassisnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Engine Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.enginenumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Fuel Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fueltype}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Colour:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.color}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Funder:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fundername ? data.fundername: 'Not Set'}</Col> 
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Supplier (OEM):'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliername ? data.suppliername : 'Not Set'}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }


    VehicleCustomer(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Vehicle Customer Information: ')}
                    subtitle={window.t.en('Selected vehicle customer details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'debtor'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Customer Full Name:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.parentaccount}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Registered Name:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.registeredname}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Business Unit:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.businessunit}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Customer Status:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.customeractive ? 'Is Active' : 'No Active'}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}><br /></Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <AdhocComponentFieldInput
                                    title={window.t.en('')}
                                    description={''}
                                    icon={''}
                                    id={'customercontact'}
                                    parent={'customercontact'}
                                    parentFilters={[
                                        {
                                            field: 'customer_id',
                                            value: data.customer_id
                                        }
                                    ]}
                                    child={'contact'}
                                    childFilters={[
                                        {
                                            field: 'contacttype',
                                            value: ''
                                        }
                                    ]}
                                    extractChildData={true}
                                    adhocFieldInput={[ //assumes Parent component fields if extractChild is set to false
                                        'fullname', 'email', 'mobile', 'contacttype'
                                    ]}
                                    selectMode={true}
                                    addMode={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    renderView(data) {

        let documents = null
        let vehicle = null
        try {
            documents = data.steps.step9.tax_invoice
            vehicle = data.steps.steps.step1.data.vehicle[0]
        } catch (err) { }


        return documents
            ? <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        {
                            documents
                                ? <PdfViewer
                                    key={'document_viewer_approval'}
                                    name={'booking'}
                                    workflow={'fml_customer_quote'}
                                    task={'tsk_purchase_order_review_and_send'}
                                    documents={documents}
                                    edit={false}
                                    multiple={false}
                                    //default_document={'Rebill'}
                                    workflow_queue_data={this.props.workflow_queue_data}
                                />
                                : <Loading message={'Retrieving Booking Document...'} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.VehicleDetails(vehicle)}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.VehicleCustomer(vehicle.customer)}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <ImageUploader prefix={'img'} id={'img'} />
                    </Col>
                </Row>
            </Container>
            : 'Please Wait Loading...'
    }

    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) {

        }

        return workflow_queue_data
            ? this.renderView(workflow_queue_data)
            : 'Loading step data...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingSendCustomerRebillInvoice
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingSendCustomerRebillInvoice)