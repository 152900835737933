export const interest_rate = '0.00'
export const end_date = null
export const start_date = null
export const primarate = []
export const cashbook = []
export const client_data = []

export const fields = { 
    effective_date: {
        errorText: 'Please select End Date',
        valid:false,
        state: 'getState().cashbookArchiveCreate?.end_date'
    },
    start_date: {
        errorText: 'Please select Start Date',
        valid:false,
        state: 'getState().cashbookArchiveCreate?.start_date',
    }
}