import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import AutoComplete from '../../../controls/autocomplete'
import { Card, CardHeader, CardContent } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as compActions from '../../Components/actions'
import SUPPLIERPAYMENTALLOCATION from '../../Custom/SUPPLIERPAYMENTALLOCATION/component'
import Upload from '../../Upload/component'
import * as actions from './actions'
import * as styles from './styles'





var viewinvoice = false,id = 0

class SUPPLIERCREDITNOTE extends Component {
    componentDidMount() {
        if(this.props.state.loading) this.props.actions.getComponent('suppliercreditnote')
    }
    addNewCreditNote() {
        this.props.actions.setLookupValue("reference", '', '')
        this.props.actions.setItems([{ description: '', unit: '', quantity: 0, unitprice: 0, amount: 0, vat: 0, total: 0, key: 1, account_id: 0 }])
        this.props.actions.setInputValue('supplier_id', '')
        this.props.actions.setInputValue('suppliername', '')// fktext removed
        this.props.actions.setInputValue('suppliertransactiondate', new Date())
        var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')                                                          
        this.props.actions.setInputValue('suppliertransactionmonth', _date)
        this.props.actions.setInputValue('description', '')
        this.props.actions.setInputValue('business_id', '')
        this.props.actions.setInputValue('businessname', '')// fktext removed
        this.updateTotals()
        viewinvoice = false
    }
    updateTotals() {
        this.props.actions.setInputValue('vat', this.calculateTotals().vat)
        this.props.actions.setInputValue('debit', this.calculateTotals().totalamount)
        this.props.actions.setInputValue('nettvalue', this.calculateTotals().amount)
        this.props.actions.setInputValue('amount', this.calculateTotals().totalamount)          
    }
    calculateTotals() {
        var data = this.props.state.data.suppliertransaction_id.data
        var vattable = 0;
        var nonvattable = 0;
        var vatamount = 0;
       
        data.map((x, i) => {
            if(Number(x.vat) > 0){ vattable += Number(x.amount) } else { nonvattable += Number(x.amount) }
            vatamount = Number(x.vat) > 0 ? vatamount += Number(x.vat) : vatamount
        });

        var amount = vattable + nonvattable;
        return {
            vatincl: vattable,
            vatexcl: nonvattable,
            totalamount: Number(amount) + Number(vatamount),
            vat: vatamount,
            amount: Number(amount)
        }
    }

    getView() {
        return <Tabs>
        <Tab label={'Capture'}>
        <br></br>
        <Card style={styles.card} style={styles.row}>
            <CardHeader
                title={window.t.en('Supplier Credit Note ')}
                subtitle={window.t.en('Capture supplier credit note')}
                actAsExpander={true}
                showExpandableButton={true}
            />
            <CardContent>
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={6}>
                        </Col>
                        <Col xl={6}>  {/* viewinvoice ?< Button className={"global_button"} variant="contained" header={false}
                                primary={true}
                                label={'Download PDF'}
                                onClick={(event) => {
                                    this.props.actions.getCreditNotePDF(id)
                                }}
                            /> :'' */}
                           < Button className={"global_button"} variant="contained" header={false}
                                primary={true}
                                label={'New Credit Note'}
                                onClick={(event) => {
                                    this.addNewCreditNote()
                                }}
                                >{window.t.en('New Credit Note')}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}><strong>Credit Note:</strong></div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        id={'dll_creditnote_id'}
                                        key={'dll_creditnote_id'}
                                        hintText={'Search by reference number...'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        dataSource={this.props.state.data.reference.data}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                this.props.actions.setLookupValue("reference", chosenRequest.value, chosenRequest.text)

                                                if (chosenRequest.value) {
                                                    viewinvoice = true;
                                                    id = chosenRequest.id
                                                    this.props.actions.setInputValue('supplier_id', chosenRequest.supplier_id)
                                                    this.props.actions.setInputValue('suppliername', chosenRequest.supplier_text)// fktext removed
                                                    this.props.actions.setInputValue('suppliertransactiondate', new Date(chosenRequest.suppliertransactiondate))
                                                    this.props.actions.setInputValue('suppliertransactionmonth', chosenRequest.suppliertransactionmonth)
                                                    this.props.actions.setInputValue('description', chosenRequest.description)
                                                    this.props.actions.setInputValue('business_id', chosenRequest.business_id)
                                                    this.props.actions.setInputValue('businessname', chosenRequest.business_text)// fktext removed
                                                    if (chosenRequest.suppliertransactionitem) {
                                                        this.props.actions.setItems(chosenRequest.suppliertransactionitem)
                                                    }
                                                    else {
                                                        this.addNewCreditNote()
                                                    }
                                                }
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.getAllCreditNotes('')
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.getAllCreditNotes(search)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Creditor:'}</div>
                                <div style={styles.fieldContent}>
                                    {
                                        viewinvoice ?
                                            <TextField
                                                disabled={true}
                                                id={'txt_suppliername'}// fktext removed
                                                hintText='creditor'
                                                value={this.props.state.data.supplier_fktext.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('suppliername', value)// fktext removed
                                                }}
                                            /> :
                                            <AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_creditor_id'}
                                                key={'dll_creditor_id'}
                                                hintText={'Select a creditor...'}
                                                filter={AutoComplete.fuzzyFilter}                                                                                                       
                                                errorText={this.props.state.data.supplier_id.input ? null : '*'}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.supplier_id.data}
                                                onNewRequest={
                                                    (chosenRequest, index) => {
                                                        var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')  
                                                        this.props.actions.setLookupValue("supplier_id", chosenRequest.value, chosenRequest.text)
                                                        this.props.actions.setInputValue('description', _date)
                                                        this.props.actions.setInputValue('suppliertransactiondate', new Date())
                                                        this.props.actions.setInputValue('suppliertransactionmonth', _date)
                                                        this.props.actions.setItems([{ description: '', unit: '', quantity: 0, unitprice: 0, amount: 0, vat: 0, total: 0, key: 1, account_id: 0 }])
                                                    }
                                                }
                                                onClick={
                                                    (event) => {
                                                        this.props.actions.getCreditorsListData('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search, dataSource, params) => {
                                                        this.props.actions.getCreditorsListData(search)
                                                    }
                                                }
                                            />
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Date:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                                type="date"
                                        disabled={viewinvoice}
                                        id={'txt_creditnotedate'}
                                        hintText='Date'
                                        container='inline'
                                        mode='landscape'                                                                                                       
                                        errorText={this.props.state.data.suppliertransactiondate.input ? null : '*'}
                                        value={this.props.state.data.suppliertransactiondate.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('suppliertransactiondate', value)
                                            var _date = moment(new Date(value)).format('YYYYMM')  
                                            this.props.actions.setInputValue('suppliertransactionmonth', _date)
                                        }}
                                    />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Month:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                        /* disabled={true} */
                                        id={'txt_suppliertransactionmonth'}
                                        hintText='Month'                                          
                                        errorText={this.props.state.data.suppliertransactionmonth.input ? null : '*'}
                                        value={this.props.state.data.suppliertransactionmonth.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('suppliertransactionmonth', value)
                                        }}
                                    />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Reference:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                        disabled={viewinvoice}
                                        id={'txtreference'}
                                        hintText='Reference'                                        
                                        errorText={this.props.state.data.reference.input ? null : '*'}
                                        value={this.props.state.data.reference.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('reference', value)
                                            this.props.actions.getDuplicateReferenceCheck(value)
                                            //this.props.actions.checkExistingInvoice(this.props.state.data.reference.input)
                                        }}
                                    />}
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Description:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                        disabled={viewinvoice}
                                        id={'txtdescription'}
                                        errorText={this.props.state.data.description.input ? null : '*'}
                                        value={this.props.state.data.description.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('description', value)
                                        }}
                                    />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Branch:'}</div>
                                <div style={styles.fieldContent}>
                                    {
                                        viewinvoice ?
                                            <TextField
                                                disabled={true}
                                                id={'txt_businessname'}
                                                hintText='Branch'
                                                value={this.props.state.data.businessname.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('businessname', value)
                                                }}
                                            /> :
                                            <AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_business_id'}
                                                key={'dll_business_id'}
                                                hintText={'Select a branch...'}
                                                filter={AutoComplete.fuzzyFilter}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.business_id.data}
                                                onNewRequest={
                                                    (chosenRequest, index) => {
                                                        this.props.actions.setLookupValue("business_id", chosenRequest.value, chosenRequest.text)
                                                        this.props.actions.setLookupValue("businessname", chosenRequest.value, chosenRequest.text)
                                                    }
                                                }
                                                onClick={
                                                    (event) => {
                                                        this.props.actions.getBusinessListData('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search, dataSource, params) => {
                                                        this.props.actions.getBusinessListData(search)
                                                    }
                                                }
                                            />
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <CardHeader
                                title={window.t.en('Items')}
                                subtitle={window.t.en('Capture items')}
                                actAsExpander={true}
                                showExpandableButton={true}
                            />
                        </Col>
                    </Row>
                </Container>
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={12}><br></br></Col>
                    </Row>
                    <Row>
                        <Col xl={1}></Col>
                        <Col xl={6}>
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div><strong>{'Description'}</strong></div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div><strong>{'Contra Account'}</strong></div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={5}>
                            <Row>
                                <Col xl={2}><div style={styles.fieldContainer}>
                                    <div><strong>{'Amount'}</strong></div>
                                </div>
                                </Col>
                                <Col xl={2}><div style={styles.fieldContainer}>
                                    <div><strong>{'Vattable'}</strong></div>
                                </div>
                                </Col>
                                <Col xl={2}><div style={styles.fieldContainer}>
                                    <div><strong>{'VAT'}</strong></div>
                                </div>
                                </Col>
                                <Col xl={2}><div style={styles.fieldContainer}>
                                    <div><strong>{'Total'}</strong></div>
                                </div>
                                </Col>
                                <Col xl={4}><strong>{''}</strong></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br></br></Col>
                    </Row>
                    {
                        this.props.state.data.suppliertransaction_id.data
                            .map((x, i) => {
                                return <Row>
                                    <Col xl={1}></Col>
                                    <Col xl={6}>
                                        <Row>
                                            <Col xl={6}>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        disabled={viewinvoice}
                                                        id={'txtitemdescription'}
                                                        hintText='Description'
                                                        value={x.description}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setItemValue({ inx: x.key - 1, field: 'description', value: value })
                                                        }}
                                                    />}
                                                </div>
                                            </Col>
                                            <Col xl={6}> <div style={styles.fieldContent}>
                                                {viewinvoice ? <TextField
                                                    disabled={viewinvoice}
                                                    id={'txt_itemaccount'}
                                                    hintText='Contra Account'
                                                    value={x.account_text}
                                                    onChange={(event, value) => {
                                                    }}
                                                /> :
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        id={'dll_itemaccount'}
                                                        key={'dll_itemaccount'}
                                                        hintText={'Select a contra account...'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.data.accountingitem_id.data}
                                                        onNewRequest={
                                                            (chosenRequest, index) => {
                                                                this.props.actions.setItemValue({ inx: x.key - 1, field: 'account_id', value: chosenRequest.value })
                                                                this.props.actions.setItemValue({ inx: x.key - 1, field: 'account_text', value: chosenRequest.text })
                                                            }
                                                        }
                                                        onClick={
                                                            (event) => {
                                                                this.props.actions.getContractAccountListData('')
                                                            }
                                                        }
                                                        onUpdateInput={
                                                            (search, dataSource, params) => {
                                                                this.props.actions.getContractAccountListData(search)
                                                            }
                                                        }
                                                    />}
                                            </div>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col xl={5}>
                                        <Row>
                                            <Col xl={2}> <div style={styles.fieldContent}>
                                                {<TextField
                                                    disabled={viewinvoice}
                                                    id={'txtitemunitprice'}
                                                    hintText='Amount'
                                                    value={x.amount}
                                                    onChange={(event, value) => {
                                                        var vat = x.vat ? value * 0.15 : 0
                                                        var total = Number(value) + Number(vat)

                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'amount', value: (value) })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vat', value: Number(vat.toFixed(2).toString()) })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'total', value: Number(total.toFixed(2).toString()) })
                                                        this.updateTotals()
                                                    }}
                                                />}
                                            </div>
                                            </Col>
                                            <Col xl={2}> <div style={styles.fieldContent}>
                                                {<Checkbox
                                                    checked={x.vat ? true : false}
                                                    onClick={(event) => {
                                                        var vat = x.vat ? 0 : Number(x.amount) * 0.15
                                                        window.glyco.log('vat')
                                                        window.glyco.log(vat)
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vat', value: Number(vat.toFixed(2).toString()) })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'total', value:Number((Number(x.amount) + Number(vat)).toFixed(2).toString()) })
                                                        this.updateTotals()
                                                    }} />}
                                            </div>
                                            </Col>
                                            <Col xl={2}> <div style={styles.fieldContent}>
                                                {<TextField
                                                    disabled={true}
                                                    id={'txtitemvat'}
                                                    hintText='VAT'
                                                    //errorText={this.props.state.data.reference.validation.valid ? this.props.state.data.reference.input + " Already Exists!" :  null }
                                                    value={x.vat}
                                                    onChange={(event, value) => {
                                                        var vat = Number(value)
                                                        var total = Number(x.amount) + vat

                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'amount', value: Number(x.amount.toFixed(2).toString()) })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'quantity', value: Number(x.quantity.toFixed(2).toString()) })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vat', value: Number(vat.toFixed(2).toString()) })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'total', value: Number(total.toFixed(2).toString()) })
                                                        this.updateTotals()
                                                    }}
                                                />}
                                            </div>
                                            </Col>
                                            <Col xl={2}> <div style={styles.fieldContent}>
                                                {<TextField
                                                    disabled={true}
                                                    id={'txtitemtotal'}
                                                    hintText='Total'
                                                    value={x.total}
                                                    onChange={(event, value) => {
                                                    }}
                                                />}
                                            </div>
                                            </Col>
                                            <Col xl={4}>
                                                {
                                                    this.props.state.data.suppliertransaction_id.data.length > 1 ?
                                                       < Button className={"global_button"} variant="contained"
                                                            disabled={viewinvoice}
                                                            label={'Remove Item'}
                                                            primary={true}
                                                            onClick={(event) => {
                                                                this.props.actions.removeRow(x.key - 1)
                                                            }}
                                                            >{window.t.en('Remove Item')}</Button>
                                                        : ''
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            })
                    }
                    <Row>
                        <Col xl={7}>
                        </Col>
                        <Col xl={5}>
                            <Row>
                                <Col xl={9}></Col><Col xl={3}>< Button className={"global_button"}
                                    disabled={viewinvoice}
                                    label={'Add Item'}
                                    primary={true}
                                    onClick={(event) => {
                                        this.props.actions.addBlankRow()
                                    }}
                                /></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Row>
                    <Col xl={12}><br></br></Col>
                </Row>
                <Row>                        <Col xl={6}> </Col>
                    <Col xl={6} style={styles.rightpanel}>
                        <Row><Col xl={6}><br></br></Col></Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'Sub-Total (Vattable):'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txtcredit'}
                                            hintText='Sub-total (Vattable)'
                                            value={this.calculateTotals().vatincl}
                                            onChange={(event, value) => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'VAT:'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txtvat'}
                                            hintText='VAT'
                                            value={this.calculateTotals().vat}
                                            onChange={(event, value) => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'Sub-Total (Non-Vattable):'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txtnettvalue'}
                                            hintText='Sub-total (Non-Vattable)'
                                            value={this.calculateTotals().vatexcl}
                                            onChange={(event, value) => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'Total:'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txttotal'}
                                            hintText='Total'
                                            value={this.calculateTotals().totalamount}
                                            onChange={(event, value) => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={10}><Upload
                            disabled={viewinvoice}
                            id={'upload_ctl'}
                            prefix={'inv'}
                            header={false}
                        /></Col>
                         <Col xl={2}>
                            <Row>

                                <Col xl={12}>
                                   < Button className={"global_button"} variant="contained" header={false}
                                        disabled={viewinvoice}
                                        label={'Save Credit Note'}
                                        onClick={(event) => {
                                            window.glyco.log("this.props.state.data.reference.text" )
                                            window.glyco.log(this.props.state.data.reference.text )
                                            var validationfields =
                                            [
                                                {
                                                    value: this.props.state.data.supplier_id.input,
                                                    field: "Creditor"
                                                },
                                                {
                                                    value: this.props.state.data.description.input,
                                                    field: "Description"
                                                },
                                                {
                                                    value: this.props.state.data.reference.input,
                                                    field: "Reference"
                                                }
                                            ]
                                            if (this.props.state.data.reference.text === true) {
                                                     var payload = {
                                                        data: this.props.state.data,
                                                        documents: this.props.state.upload.documents,
                                                        user:  this.props.state.user
                                                }
                                                this.props.actions.saveData(payload, validationfields, this.props.state.data.suppliertransaction_id.data)                                                
                                            }
                                            if (this.props.state.data.reference.text === false) {
                                                this.props.actions.foundDuplicateReference()
                                            } 
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                     </Row>
                </Container>
           </CardContent>
        </Card>
        </Tab>
        <Tab label={'Allocation'}>
            <br></br>
            <Container style={{ margin: 0 }} className="custom-container">
                <SUPPLIERPAYMENTALLOCATION></SUPPLIERPAYMENTALLOCATION>
            </Container>
        </Tab>
    </Tabs> 
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.suppliercreditnote,
            upload: {
                ...state.upload
            }
            ,user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions ,...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERCREDITNOTE)