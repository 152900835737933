import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.REASSIGN_TASK_SHOW:
            return {
                ...state,
                visible: true,
                loading: true,
                mode: action.payload.mode,
                team_id: action.payload.team_id,
                user_id: action.payload.user_id,
                task: action.payload.task ? action.payload.task : undefined
            }
        case types.REASSIGN_TASK_HIDE:
            return {
                ...state,
                visible: false,
                team_id: 0,
                user_id: 0,
                selected_team_id: 0,
                selected_user_id: 0,
                comments: '',
                mode: '',
                task: {}
            }
        case types.REASSIGN_TASK_GET_TASK:
            return {
                ...state,
                taskLoading: true
            }
        case types.SET_REJECT_DIALOG:
            return {
                ...state,
                rejectDialog: action.payload.confirm,
            }
        case types.REASSIGN_TASK_SET_TASK:
            return {
                ...state,
                taskLoading: false,
                task: action.payload
            }
        case types.REASSIGN_TASK_GET_TEAMS:
            return {
                ...state,
                loading: true
            }
            case types.SET_SELECTED_WORKFLOW_REJECT_REASON:
                return {
                    ...state,
                    reject_reason: action.payload
                }
        case types.REASSIGN_TASK_SET_TEAMS:
            return {
                ...state,
                data: (() => {
                    return action.payload && action.payload.length ? action.payload.map(x => {
                        return {
                            ...x,
                            users: x.users
                        }
                    }) : []
                })(),
                loading: false,
                selected_team_id: action.payload.length ? action.payload[0].team_id : 0,
                selected_user_id: action.payload.length
                    ? action.payload[0].users.length
                        ? action.payload[0].users[0].user_id
                        : 0
                    : 0
            }
        case types.REASSIGN_TASK_SELECT_TEAM:
            return {
                ...state,
                selected_team_id: action.payload,
            }
        case types.REASSIGN_TASK_SELECT_USER:
            return {
                ...state,
                selected_user_id: action.payload,
            }
        case types.REASSIGNMENT_TASK_SET_COMMENT:
            return {
                ...state,
                comments: action.payload
            }
        case types.REASSIGNMENT_TASK_SET_SELECTION_COMMENT:
            return {
                ...state,
                selectcomments: action.payload
            }
        default:
            return state
    }
}