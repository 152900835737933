import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state
            }
        case types.SET_STATEMENT_PREVIEW_DATA:
            return {
                ...state,
                data: action.payload
            }
            case types.SET_STATEMENT_REVIEW_INPUT_VALUE:
                return {
                    ...state,
                    [action.payload.prop]: action.payload.val
                }
        default:
            return state
    }
}