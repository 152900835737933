import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs, Box, Tab, Button, List, ListItem, Tooltip, Dialog, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import TextField from '../../../controls/textField'

import * as actions from './actions'
import * as styles from './styles'

import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'

class SalesProcessCapture extends Component {
    componentDidMount() {
        this.props.actions.setValidationState('salesProcessCapture')
        if (this.props.mode === "confirm") {
            this.props.actions.setSalesProcessWorkflowTaskInput(this.props.state, [], 'sales_process', 'tsk_sales_process_capture_details')
        }
        else {
            this.props.actions.resetForm()
        }
    }

    getDetails() {
        return <div> <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'leadid'}
                        label={'Enter Lead ID'}
                        fullWidth={true}
                        multiLine={false}
                        error={this.props.state.leadid ? '' :  'Enter customer name'}
                        value={this.props.state.leadid}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('leadid', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'customername'}
                        label={'Enter customer name'}
                        fullWidth={true}
                        multiLine={false}
                        error={this.props.state.customername ? '' : 'Enter customer name'}
                        value={this.props.state.customername}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('customername', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'registrationno'}
                        label={'Enter Registration Number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.registrationno}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('registrationno', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'organisationtype'}
                        title={'Select Customer Type'}
                        data={this.props.state.organisationtype_data}
                        value={this.props.state.organisationtype}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        displayfields={['organisationtype']}
                        fieldtext={'organisationtype'}
                        fieldvalue={'organisationtype_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('organisationtype', args.text)
                            this.props.actions.setInputValue('organisationtype_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('organisationtype', 'organisationtype', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('organisationtype', 'organisationtype', search)
                                this.props.actions.setInputValue('organisationtype', search)
                            } else {
                                this.props.actions.getLookupData('organisationtype', 'organisationtype', '')
                                this.props.actions.setInputValue('organisationtype', '')
                                this.props.actions.setInputValue('organisationtype_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'contactnumber'}
                        label={'Enter Contact Number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.contactnumber}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('contactnumber', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'contactrole'}
                        label={'Enter Contact Role'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.contactrole}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('contactrole', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'contactemail'}
                        label={'Enter Contact Email'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.contactemail}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('contactemail', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'building'}
                        label={'Enter Building Name'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.building}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('building', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'streetaddress'}
                        label={'Enter Street Address'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.streetaddress}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('streetaddress', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'city'}
                        label={'Enter City'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.city}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('city', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'province'}
                        label={'Enter Province'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.province}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('province', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'pcode'}
                        label={'Enter Postal Code'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.pcode}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('pcode', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'contracttype'}
                        title={'Select Deal Type'}
                        data={this.props.state.contracttype_data}
                        value={this.props.state.contracttype}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        displayfields={['contracttype']}
                        fieldtext={'contracttype'}
                        fieldvalue={'contracttype_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('contracttype', args.text)
                            this.props.actions.setInputValue('contracttype_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('contracttype', 'contracttype', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('contracttype', 'contracttype', search)
                                this.props.actions.setInputValue('contracttype', search)
                            } else {
                                this.props.actions.getLookupData('contracttype', 'contracttype', '')
                                this.props.actions.setInputValue('contracttype', '')
                                this.props.actions.setInputValue('contracttype_id', '')
                            }
                        }}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    }
    
    getDocuments() {
        return <div>
            <Upload
                id={'customer'}
                prefix={'Customer'}
                header={false}
            /></div>
    }

    getCapture() {
        return <div>
            <Card
                title={"Customer Details"}
                subtitle={window.t.en('Enter new customer details')}
                content={this.getDetails()}
            />
        </div>
    }


    getConfirm() {
        //consolex.log('getConfirm', this.props.state.documents)
        return <div>
            <Card
                title={"Customer Details"}
                subtitle={window.t.en('Enter new customer details')}
                content={<Fields
                    fields={[
                        { size: 4, label: 'Lead Id:', value: this.props.state.leadid },
                        { size: 4, label: 'Customer Name:', value: this.props.state.customername },
                        { size: 4, label: 'Registration Number:', value: this.props.state.registrationno },
                        { size: 4, label: 'Organisation Type:', value: this.props.state.organisationtype },
                        { size: 4, label: 'Contact Number:', value: this.props.state.contactnumber },
                        { size: 4, label: 'Contact Role:', value: this.props.state.contactrole },
                        { size: 4, label: 'Contact Email:', value: this.props.state.contactemail },

                        { size: 4, label: 'Building Name:', value: this.props.state.building },
                        { size: 4, label: 'Street Address:', value: this.props.state.streetaddress },
                        { size: 4, label: 'City:', value: this.props.state.city },
                        { size: 4, label: 'Province:', value: this.props.state.province },
                        { size: 4, label: 'Postal Code:', value: this.props.state.pcode },
                        { size: 4, label: 'Contract Type:', value: this.props.state.contracttype },

                    ]}
                />}
            />
        </div>
    }

    getLayout() {
        return <div>{
            this.props.mode === 'confirm'
                ? this.getConfirm()
                : this.getCapture()
        }
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.salesProcessCapture,
            documents: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesProcessCapture)