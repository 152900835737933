import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import CardControl from './card'
import ReactQuill from 'react-quill'
import * as actions from './actions'
import * as config from '../config'

class ViewCustomer extends Component {
    componentDidMount() {
        //console.log('getComponentCustomerDate >>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
        this.props.actions.getComponentCustomerDate(this.props.id)
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }

    getLabel(label, value, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                <Typography variant="body1" component="div"
                    style={{  fontWeight: 'bold',fontSize: '14px' }}>
                    {this.getField(value, type)}
                </Typography>
            }
            label={
                <label style={{ width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                    {window.t.en(label)}:
                </label>
            }
            labelPlacement="start"
        />
    }
    
    minView() {
        let data = this.props.state
        return <div>
        <Container style={{ margin: 0 }} className="custom-container">
            <Row>
            <Col xl={4}>
                    {this.getLabel('Customer Name', data.customername)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Cost Centre', data.costcentre)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Customer Business Unit', data.businessunit)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Vat Number', data.vatnumber)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Account Number', data.accountnumber)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Contact Activated', data.customeractive)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Contact Suspended', data.customersuspended)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Send Marketing', data.send_marketing == true ? 'Yes' : 'No')}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Marketing Type', data?.marketingtype?.marketingtype)}
                </Col>
                {
                    data.customeraddress && data.customeraddress.length 
                    ? <div>
                        {/* <Col xl={12}>
                            <strong>Address</strong>
                        </Col> */}
                            {
                                data.customeraddress.map(x => {
                                    return  <CardControl 
                                            title={' '}
                                            subtitle={x.address.addresstype ? x.address.addresstype.name : 'Address Details'}
                                            content={<Row>
                                                <Col xl={4}>
                                                    {this.getLabel('Street Name', x.address.line1)}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Building Name', x.address.line2)}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Town', x.address.town)}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Province', x.address.province)}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Postal Code', x.address.postalcode)}
                                                </Col>
                                            </Row>}
                                            callapse={true}
                                        /> 
                                })
                            }
                    </div>
                    : ''
                }
            </Row>
        </Container>
         </div> 
    }
    
    getCustomerView() {
        let data = this.props.state
        let oq_key = 'a45cc3095246cdd6895faaedad652e8744feaefedbfaa7d974e7d68acf726bf2fefb622b7d2cb01713a91717bfc76a655559783b7792c8621832b675fef5c693'
        return <div>
        <Container style={{ margin: 0 }} className="custom-container">   
            <Row>
                <Col xl={4}>
                    {this.getLabel('Customer Name', data.customername)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Cost Centre', data.costcentre)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Account Number', data.accountnumber)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Contact Person Name', data.contactperson)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Contact Number', data.contactnumber)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Contact Email', data.contactemail)}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Send Marketing', data.send_marketing == true ?'Yes':'No')}
                </Col>
                <Col xl={4}>
                    {this.getLabel('Marketing Type', data.marketingtype?.marketingtype)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <iframe src={`${config.oq.uri}${oq_key}`} 
                    height="550px"
                    width="100%"
                    style={{
                        border: "none",
                        margin: 0,
                        padding: '-25px',
                        alignSelf: "center",
                        //  boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), -4px -4px 30px 19px rgba(0, 0, 0, 0.1)" 
                    }}/>
                </Col>
            </Row>
        </Container>
         </div> 
    }

    getLayout() {
        return <div>
            {/* <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={'Customer Details  ' + this.props.state.customername}
                    subtitle={''}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={4}>
                                {this.getLabel('Customer Name', 'customername')}
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
             */}
            {
                this.props.nocard 
                ? <div>{this.props.min ? this.minView() : this.getCustomerView()}</div>
                : <CardControl 
                    title={this.props.title ? this.props.title : 'Customer Details  '}
                    subtitle={this.props.subtitle ? this.props.subtitle : 'Customer Name  ' + this.props.state.customername}
                    content={this.props.min ? this.minView() : this.getCustomerView()}
                    callapse={true}
                />
            }
         </div> 
    }

    render() {
        return this.props.id ? this.getLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls.customer
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer)