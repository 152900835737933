import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import AutoComplete from '../../../controls/autocomplete'


import Icon from '../../Icon/component'

import * as actions from './actions'
import * as styles from './styles'

import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"
import "react-table/react-table.css"
import * as compActions from '../../Components/actions'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'


var view = false, viewsearchbox = false, cust_id = 0
class CUSTOMERRECEIPT extends Component {
    componentDidMount() {
        if (this.props.state.loading) this.props.actions.getComponent('customerreceipt')
    }
    addNew() {
        this.props.actions.setLookupValue("reference", '', '')
        this.props.actions.setInputValue('customer_id', '')
        this.props.actions.setInputValue('customername', '')
        this.props.actions.setInputValue('customertransactiondate', new Date())
        var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')                                                          
        this.props.actions.setInputValue('customertransactionmonth', _date)
        this.props.actions.setInputValue('customerreference', '')
        this.props.actions.setInputValue('description', '')
        this.props.actions.setInputValue('business_id', '')
        this.props.actions.setInputValue('businessname', '')
        this.props.actions.setInputValue('accountingitem_id', '')
        this.props.actions.setInputValue('itemname', '')
        this.props.actions.setInputValue('credit', 0)
        view = false
    }
    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "credit") {
            return <TextField
                style={styles.miniTextBox}
                underlineShow={false}
                id={'txtnonvatsubtotal'}
                value={row.original.credit}
                onChange={(event, value) => {
                    var values = {
                        id: row.original.customertransaction_id,
                        value: value,
                        field: field.name
                    }
                    var data = this.props.state.data
                    this.actions.setFieldAllocation(values, data)
                }}
            />
        }
        else if (field.name == "allocated") {
            return <Checkbox
                checked={row.original.allocated}
                onClick={() => {
                    var values = {
                        id: row.original.customertransaction_id,
                        value: row.original.allocated ? false : true,
                        field: field.name
                    }
                    var data = this.props.state.data
                    this.actions.setFieldAllocation(values, data)
                }} />
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black', //'#4A4A4A',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        }

        return columns
    }
    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <Tabs>
            <Tab label={'Capture'}>
                <Card style={styles.card} style={styles.row}>
                    <CardHeader
                        title={window.t.en('Customer Receipt')}
                        subtitle={window.t.en('Capture customer receipt')}
                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                    <CardContent>
                        <Container style={styles.whitediv}>
                            <Row>
                                {/*Left Side*/}
                                <Col xl={12}>
                                    <Row><Col xl={6}><br></br></Col><Col xl={6}>
                                       < Button className={"global_button"} variant="contained" header={false}
                                            primary={true}
                                            label={'New Receipt'}
                                            onClick={() => {
                                                this.addNew()
                                            }}
                                            >{window.t.en('New Receipt')}</Button>
                                    </Col></Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><strong>Receipt:</strong></div>
                                                <div style={styles.fieldContent}>
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        id={'dll_invoice_id'}
                                                        key={'dll_invoice_id'}
                                                        hintText={'Search by reference number...'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.data.reference.data}
                                                        onNewRequest={
                                                            (chosenRequest) => {
                                                                this.props.actions.setLookupValue("reference", chosenRequest.value, chosenRequest.text)

                                                                if (chosenRequest.value) {
                                                                    view = true;
                                                                    this.props.actions.setInputValue('customer_id', chosenRequest.customer_id)
                                                                    this.props.actions.setInputValue('customername', chosenRequest.customer_text)
                                                                    this.props.actions.setInputValue('customertransactiondate', new Date(chosenRequest.customertransactiondate))
                                                                    this.props.actions.setInputValue('customertransactionmonth', chosenRequest.customertransactionmonth)
                                                                    this.props.actions.setInputValue('customerreference', chosenRequest.customerreference)
                                                                    this.props.actions.setInputValue('description', chosenRequest.description)
                                                                    this.props.actions.setInputValue('business_id', chosenRequest.business_id)
                                                                    this.props.actions.setInputValue('businessname', chosenRequest.business_text)
                                                                    this.props.actions.setInputValue('itemname', chosenRequest.accountingitem_text)// removed
                                                                    this.props.actions.setInputValue('credit', chosenRequest.amount)
                                                                }
                                                            }
                                                        }
                                                        onClick={
                                                            () => {
                                                                this.props.actions.getAllReceipts('')
                                                            }
                                                        }
                                                        onUpdateInput={
                                                            (search) => {
                                                                this.props.actions.getAllReceipts(search)
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Debtor:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {view ?
                                                        <TextField
                                                            disabled={true}
                                                            id={'txt_customer_fktext'}
                                                            hintText='debtor'
                                                            value={this.props.state.data.customer_fktext.input}
                                                            onChange={(event, value) => {
                                                                this.props.actions.setInputValue('customername', value)
                                                            }}
                                                        /> : <AutoComplete
                                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            id={'dll_debtor_id'}
                                                            key={'dll_debtor_id'}
                                                            hintText={'Select a debtor...'}
                                                            filter={AutoComplete.fuzzyFilter}
                                                            openOnFocus={true}
                                                            fullWidth={true}
                                                            dataSourceConfig={{
                                                                text: 'text',
                                                                value: 'value'
                                                            }}
                                                            dataSource={this.props.state.data.customer_id.data}
                                                            onNewRequest={
                                                                (chosenRequest) => {
                                                                    var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')  
                                                                    this.props.actions.setLookupValue("customer_id", chosenRequest.value, chosenRequest.text)
                                                                    this.props.actions.setInputValue('description', _date)
                                                                    this.props.actions.setInputValue('customertransactiondate', new Date())
                                                                    this.props.actions.setInputValue('customertransactionmonth', _date)
                                                                }
                                                            }
                                                            onClick={
                                                                () => {
                                                                    this.props.actions.getLookupValueData('')
                                                                }
                                                            }
                                                            onUpdateInput={
                                                                (search) => {
                                                                    this.props.actions.getLookupValueData(search)
                                                                }
                                                            }
                                                        />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Date:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                type="date"
                                                        disabled={view}
                                                        id={'txt_invoicedate'}
                                                        hintText='Date'
                                                        container='inline'
                                                        mode='landscape'
                                                        value={this.props.state.data.customertransactiondate.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('customertransactiondate', value)
                                                            var _date = moment(new Date(value)).format('YYYYMM')  
                                                            this.props.actions.setInputValue('customertransactionmonth', _date)

                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Reference:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        disabled={view}
                                                        id={'txtreference'}
                                                        hintText='Reference'
                                                        value={this.props.state.data.reference.input}
                                                        errorText={this.props.state.data.reference.input ? null : '*'}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('reference', value)
                                                            this.props.actions.getDuplicateReferenceCheck(value)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Description:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        disabled={view}
                                                        id={'txtdescription'}
                                                        value={this.props.state.data.description.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('description', value)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={styles.row}>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><strong>{'Amount:'}</strong></div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        disabled={view}
                                                        id={'txtamount'}
                                                        value={this.props.state.data.credit.input}
                                                        errorText={this.props.state.data.credit.input ? null : '*'}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('credit', value)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Bank Account:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {view ?
                                                        <TextField
                                                            disabled={true}
                                                            id={'txt_accountingitem_id'}
                                                            hintText='Bank account'
                                                            value={this.props.state.data.accountingitem_fktext ? this.props.state.data.accountingitem_fktext.input : ''}
                                                            onChange={(event, value) => {
                                                                this.props.actions.setInputValue('itemname', value) 
                                                            }}
                                                        /> : <AutoComplete
                                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            id={'dll_business_id'}
                                                            key={'dll_business_id'}
                                                            hintText={'Select a account...'}
                                                            errorText={this.props.state.data.accountingitem_id.input ? null : '*'}
                                                            filter={AutoComplete.fuzzyFilter}
                                                            openOnFocus={true}
                                                            fullWidth={true}
                                                            dataSourceConfig={{
                                                                text: 'text',
                                                                value: 'value'
                                                            }}
                                                            dataSource={this.props.state.data.accountingitem_id.data}
                                                            onNewRequest={
                                                                (chosenRequest) => {
                                                                    this.props.actions.setLookupValue("accountingitem_id", chosenRequest.value, chosenRequest.text)
                                                                }
                                                            }
                                                            onClick={
                                                                () => {
                                                                    this.props.actions.getContractAccountListData('')
                                                                }
                                                            }
                                                            onUpdateInput={
                                                                (search) => {
                                                                    this.props.actions.getContractAccountListData(search)
                                                                }
                                                            }
                                                        />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl={10}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Month:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        /* disabled={true} */
                                                        id={'txt_customertransactionmonth'}
                                                        hintText='Month'
                                                        value={this.props.state.data.customertransactionmonth.input}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setInputValue('customertransactionmonth', value)
                                                        }}
                                                    />}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                        <Row>
                            <Col xl={12}><br></br></Col>
                        </Row>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={10}></Col>
                                <Col xl={2}>
                                    <Row>

                                        <Col xl={12}>
                                           < Button className={"global_button"} variant="contained"
                                                disabled={view}
                                                header={false}
                                                label={'Save Receipt'}
                                                onClick={() => {
                                                    var validationfields =
                                                        [
                                                            {
                                                                value: this.props.state.data.customer_id.input,
                                                                field: "Debtor"
                                                            },
                                                            {
                                                                value: this.props.state.data.credit.input,
                                                                field: "Amount"
                                                            },
                                                            {
                                                                value: this.props.state.data.description.input,
                                                                field: "Description"
                                                            },
                                                            {
                                                                value: this.props.state.data.accountingitem_id.input,
                                                                field: "Account"
                                                            }
                                                        ]
                                                    if (this.props.state.data.reference.text === true) {
                                                        var payload = {
                                                            data: this.props.state.data,
                                                            documents: this.props.state.upload.documents,
                                                            user: this.props.state.user
                                                        }
                                                        this.props.actions.saveData(payload, validationfields)
                                                    }
                                                    if (this.props.state.data.reference.text === false) {
                                                        this.props.actions.foundDuplicateReference()
                                                    }
                                                }}
                                                >{window.t.en('Save Receipt')}</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                        <Row><Col><br></br></Col></Row>
                    </CardContent>
                </Card>
            </Tab>
            <Tab label={'BULK RECEIPTING'}>
                <Card style={styles.card} style={styles.row}>
                    <CardHeader
                        title={window.t.en('Customer Receipting')}
                        subtitle={window.t.en('Receipt')}
                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                    <CardContent >
                        <Container style={styles.whitediv}>
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Business Unit:'}</div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_debtor_id'}
                                                key={'dll_debtor_id'}
                                                hintText={'Select a Business Unit...'}
                                                filter={AutoComplete.fuzzyFilter}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.customername.data}
                                                onNewRequest={
                                                    (chosenRequest) => {
                                                        let _date = moment(new Date()).format('YYYYMMDD')//format('MMMM YYYY')  
                                                        let description = chosenRequest.businessunit + ' - RCPT - ' + _date

                                                        //this.props.actions.setLookupValue("customer_id", chosenRequest.text, chosenRequest.text)
                                                        this.props.actions.setInputValue('description', description)
                                                        this.props.actions.setInputValue('reference', description)
                                                        this.props.actions.getDataToReceipt('', chosenRequest.value)
                                                    }
                                                }
                                                onClick={
                                                    () => {
                                                        this.props.actions.getBusinessUnits('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search) => {
                                                        this.props.actions.getBusinessUnits(search)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row> <Col xl={9}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}><b>{'Payment:'}</b></div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    id={'txtrcptdescription'}
                                                    fullWidth={true}
                                                    hintText={'Enter Payment...'}
                                                    value={(this.props.state.data.credit.input)}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue('credit', value)
                                                        this.props.actions.getBalance(this.props.state.data.customertransaction_id.data)
                                                    }}
                                                />
                                            </div>
                                        </div></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Receipt Description:'}</div>
                                        <div style={styles.fieldContent}>
                                            <TextField
                                                id={'txtrcptdescription'}
                                                fullWidth={true}
                                                value={this.props.state.data.description ? this.props.state.data.description.input : ''}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('description', value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row>
                                        <Col xl={9}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><b>{'Selected Invoices:'}</b></div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        id={'txtrcptdescription'}
                                                        fullWidth={true}
                                                        value={Number(this.props.state.data.amount.input).toFixed(2).toString()}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={3}>
                                            {this.props.state.data.amount.text ? 'Unselect All' : 'Select All'}<Checkbox
                                                checked={this.props.state.data.amount.text}
                                                onClick={() => {
                                                    var seletion = this.props.state.data.amount.text ? false : true
                                                    this.props.actions.setInputValueToggle('amount', seletion)
                                                    var values = {
                                                        value: seletion,
                                                        field: 'invoices'
                                                    }
                                                    var data = this.props.state.data
                                                    this.actions.setFieldAllocationToggle(values, data)
                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Reference:'}</div>
                                        <div style={styles.fieldContent}>
                                            <TextField
                                                id={'txtrcptdescription'}
                                                fullWidth={true}
                                                value={this.props.state.data.reference ? this.props.state.data.reference.input : ''}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('reference', value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row>
                                        <Col xl={9}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><b>{'Selected Credit:'}</b></div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        id={'txtrcptdescription'}
                                                        fullWidth={true}
                                                        value={Number(this.props.state.data.nettvalue.input).toFixed(2).toString()}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={3}>
                                            {this.props.state.data.nettvalue.text ? 'Unselect All' : 'Select All'}<Checkbox
                                                checked={this.props.state.data.nettvalue.text}
                                                onClick={() => {
                                                    var seletion = this.props.state.data.nettvalue.text ? false : true
                                                    this.props.actions.setInputValueToggle('nettvalue', seletion)
                                                    var values = {
                                                        value: seletion,
                                                        field: 'credit'
                                                    }
                                                    var data = this.props.state.data
                                                    this.actions.setFieldAllocationToggle(values, data)

                                                }} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Bank Account:'}</div>
                                        <div style={styles.fieldContent}>
                                            {view ?
                                                <TextField
                                                    disabled={true}
                                                    id={'txt_accountingitem_id'}
                                                    hintText='Bank account'
                                                    value={this.props.state.data.accountingitem_fktext ? this.props.state.data.itemname.input : ''}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue('accountingitem_fktext', value)
                                                    }}
                                                /> : <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'dll_business_id'}
                                                    key={'dll_business_id'}
                                                    hintText={'Select a account...'}
                                                    //errorText={this.props.state.data.accountingitem_id.input ? null : '*'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    dataSource={this.props.state.data.accountingitem_id.data}
                                                    onNewRequest={
                                                        (chosenRequest) => {
                                                            this.props.actions.setLookupValue("accountingitem_id", chosenRequest.value, chosenRequest.text)
                                                        }
                                                    }
                                                    onClick={
                                                        () => {
                                                            this.props.actions.getContractAccountListData('')
                                                        }
                                                    }
                                                    onUpdateInput={
                                                        (search) => {
                                                            this.props.actions.getContractAccountListData(search)
                                                        }
                                                    }
                                                />}
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <Row> <Col xl={9}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}><b>{'Payment Allocation:'}</b></div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    id={'txtrcptdescription'}
                                                    fullWidth={true}
                                                    value={this.props.state.data.allocationamount.input}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue('allocationamount', value)
                                                        //this.props.actions.getBalance(this.props.state.data.customertransaction_id.data)
                                                    }}
                                                />
                                            </div>
                                        </div></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <Row> </Row>
                                </Col>
                                <Col xl={6}>
                                    <Row>
                                        <Col xl={9}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><b>{'Remaining Balance:'}</b></div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        id={'txtrcptdescription'}
                                                        fullWidth={true}
                                                        value={(
                                                            //payment < (invoices+credit) ? (invoices+credit)-payment : (invoices+credit)-paymentallocation 
                                                            Number(this.props.state.data.credit.input) <
                                                                (Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) ?
                                                                ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.credit.input)) :
                                                                ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.allocationamount.input))
                                                        ).toFixed(2).toString()}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={3}>
                                           < Button className={"global_button"} variant="contained" header={false}
                                                disabled={
                                                    ((
                                                        Number(this.props.state.data.credit.input) <
                                                            (Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) ?
                                                            ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.credit.input)) :
                                                            ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.allocationamount.input))
                                                    ) < 1 && (
                                                        Number(this.props.state.data.credit.input) <
                                                            (Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) ?
                                                            ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.credit.input)) :
                                                            ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.allocationamount.input))
                                                    ) > -1) && Number(this.props.state.data.credit.input) > 0 && Number(this.props.state.data.allocationamount.input) > 0
                                                        ? false : true
                                                }
                                                label={'Save & Allocate'}
                                                onClick={() => {
                                                    var totaldue = Number(this.props.state.data.amount.input).toFixed(2).toString()
                                                    var balance = (Number(this.props.state.data.amount.input) - Number(this.props.state.data.allocationamount.input)).toFixed(2).toString()
                                                    var data =
                                                    {
                                                        items: this.props.state.data.customertransaction_id.data,
                                                        header: this.props.state.data,                                                        
                                                        user: this.props.state.user
                                                    }
                                                    if (!totaldue) {
                                                    }
                                                    if (Number(balance) >= 1) {
                                                    }
                                                    this.props.actions.saveAllocation(data)
                                                }}
                                                >{window.t.en('Save & Allocate')}</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </CardContent>
                    <Row>
                        <Col xl={12}>
                        </Col>
                    </Row>
                    <Container style={styles.whitediv}>
                        <Row>
                            <Col xl={12}>
                                {this.getGrid()}
                            </Col>
                        </Row>
                    </Container>
                    <Container style={styles.whitediv}>
                        <Row>
                            <Col xl={6}>
                                <Row> </Row>
                            </Col>
                            <Col xl={6}>
                                <Row>
                                    <Col xl={9}>

                                    </Col>
                                    <Col xl={3}>
                                       < Button className={"global_button"} variant="contained" header={false}
                                            disabled={
                                                ((
                                                    Number(this.props.state.data.credit.input) <
                                                        (Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) ?
                                                        ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.credit.input)) :
                                                        ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.allocationamount.input))
                                                ) < 1 && (
                                                    Number(this.props.state.data.credit.input) <
                                                        (Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) ?
                                                        ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.credit.input)) :
                                                        ((Number(this.props.state.data.amount.input) + Number(this.props.state.data.nettvalue.input)) - Number(this.props.state.data.allocationamount.input))
                                                ) > -1) && Number(this.props.state.data.credit.input) > 0 && Number(this.props.state.data.allocationamount.input) > 0
                                                    ? false : true
                                            }
                                            label={'Save & Allocate'}
                                            onClick={() => {
                                                var totaldue = Number(this.props.state.data.amount.input).toFixed(2).toString()
                                                var balance = (Number(this.props.state.data.amount.input) - Number(this.props.state.data.allocationamount.input)).toFixed(2).toString()
                                                var data =
                                                {
                                                    items: this.props.state.data.customertransaction_id.data,
                                                    header: this.props.state.data,                                                       
                                                    user: this.props.state.user
                                                }
                                                if (!totaldue) {
                                                }
                                                if (Number(balance) >= 1) {
                                                }
                                                this.props.actions.saveAllocation(data)
                                            }}
                                            >{window.t.en('Save & Allocate')}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </Tab>
        </Tabs>
    }
    getGrid() {
        return <div> < Button className={"global_button"}
            secondary={true}
            label="Excel Export"
            icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
            onClick={() => {
                this.props.actions.excelExport(this.component)
            }}
            ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Excel Export</Button>{viewsearchbox ? <TextField
            hintText={'Search by reference...'}
            value={this.props.state.data.customertransaction_id.input}
            onChange={(event, value) => {
                this.props.actions.setInputValue('customertransaction_id', value)
            }}
        /> : ''}{viewsearchbox ?< Button className={"global_button"} variant="contained"
            icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
            onClick={(event) => {
                event.preventDefault()
                this.props.actions.getFilterAllocationsData(this.props.state.data.customertransaction_id.input, cust_id)
            }}
            ><Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} /> </Button> : ''}<ReactTable
            key={'grid_' + this.component.name + '_' + this.component.component_id}
            //className='-striped -highlight'
            data={this.props.state.data.customertransaction_id.data}
            columns={this.getColumns()}
            defaultPageSize={this.component.grid.paging.pageSize}
            //filterable={this.props.filterable}
            //loading={this.component.grid.loading}
            manual
            onFetchData={
                () => {
                    //this.props.actions.getComponentGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: () => {
                        //this.props.actions.selectRow(this.component.name, rowInfo)

                        if (this.props.toggle) {
                            //this.props.actions.toggleInfoSheet()
                        }
                    },
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            onResizedChange={() => {
                //this.props.actions.resizeColumn(this.component.name, newResized)
            }}
            minRows={1}
            pages={this.component.grid.paging.pageCount}
            showPageJump={false}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
                {(state, render) => {
                    return (
                        render()
                    )
                }}
            </ReactTable></div>
    }
    render() {
        return (
            this.props.state.loading ? 'Loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.customerreceipt,
            upload: {
                ...state.upload
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERRECEIPT)