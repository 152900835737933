import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'


export const getFMLCreateQuoteInputookupDataEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_WORKFLOW_COMPONENT_INPUT_LOOKUP_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.field.name + '/search?' + payload.field.data_text + '=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setInputookupData(payload, result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/' + payload.field.name + '/search?' + payload.field.data_text + '=' + payload.search)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.field.name + '/search?' + payload.field.data_text + '=' + payload.search, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getCustomerDerivative = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_CUSTOMER_DERIVATIVE_VALUES),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customerderivative/search?customer_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setCustomerDerivative(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id' + payload)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getReplaceVehicleDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_CONTRACT_REPLACE_VEHICLE_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customercontract/search?customercontract_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setReplaceVehicleDetails(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id' + payload)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getVehicleCategoryChip = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SELECTED_VEHICLE_CATEGORY_CHIP),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehiclecategorychip/search?contractvehiclecategory_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'accessory'
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setVehicleCategoryChip(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id' + payload)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getListLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_LIST_LOOKUP_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/'+payload.field.table+'/search?'+payload.field.text+'=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setListLookupData(payload, result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id' + payload)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )