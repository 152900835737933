export const loading = false
export const invoice_list = []
export const creditor_list = []
export const invoice_items = []
export const supplier_id = null
export const suppliertransaction_date = null
export const suppliertransaction_month = null
export const invoice_reference = null
export const invoice_description = null
export const unit_list = []
export const glaccount_list = []
export const selected_invoice = {
    value: null,
    text: null
}
export const selected_supplier = {
    value: null,
    text: null
}
export const selected_account = {
    value: null,
    text: null
}
export const transaction_type = {
    value: '',
    text: ''
}
export const  sub_total = 0
export const  vat_total = 0
export const  none_total = 0
export const  grand_total = 0

export const transaction_types = []
export const total_vat = null
export const total_amount = null
export const nettvalue = null
export const payment_amount = null
export const settlement_discount = null
export const total_discount = null
export const settlement_discount_perc = null
export const total_payment = null
export const viewinvoice = false
