export const GET_COMPLETED_POS = 'GET_COMPLETED_POS'

export const APPROVE_PO = 'APPROVE_PO'

export const REJECT_PO = 'REJECT_PO'
export const UPLOAD_PO_DOCUMENTS = 'UPLOAD_PO_DOCUMENTS'

export const GET_POSUPPLIERLIST = 'GET_POSUPPLIERLIST'

export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'

export const REFRESH_INVAMT = 'REFRESH_INVAMT'
export const REFRESH_INVNO = 'REFRESH_INVNO'
export const GET_DISPOSAL_INVOICE = 'GET_DISPOSAL_INVOICE'
export const GEN_CUSTOMER_INVOICE_PDF = 'GEN_CUSTOMER_INVOICE_PDF'

