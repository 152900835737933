import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import AutoComplete from '../../../controls/autocomplete'
import { Card, CardHeader, CardContent } from '@mui/material'

import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from './actions'
import * as styles from './styles'





var viewinvoice = false;

class LICENCERENEWAL extends Component { 
    getView() {
        return <Card style={styles.row}>
                    <CardHeader
                        title={window.t.en('Vehicle Transfer ')}
                        subtitle={window.t.en('Capture Vehicle Transfer details')}
                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                <CardContent>
                    <Container style={styles.whitediv}>
                        
                        <Row>
                            <Col xl={6} style={styles.mainBoldTitle}>

                                <h2>Vehicle Licence Renewal</h2>

                            </Col>
                        
                        </Row>


                        <Row>
                                <Col xl={12}>

                                    <CardHeader

                                    style={styles.contentBottomOffSet}
                                    title={window.t.en('Vehicle Licence Renewal ')}
                                    subtitle={window.t.en('Capture Vehicle Licence Renewal details')}
                                    actAsExpander={true}
                                    showExpandableButton={true}
                                    />

                                </Col>
                            </Row>


                            
                            <Row>

                                <Col xl={12}>
                                   

                                            
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Registration Number:'}</div>
                                            <div style={styles.fieldContent}>
                                                <AutoComplete
                                                    style={styles.inputWidth}
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'autocomplete_vehicle_renewal_id'} 
                                                    key={'autocomplete_vehicle_renewal_id'}
                                                    hintText={'Registration Number'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'licenseplateno',
                                                        value: 'vehicle_id'
                                                    }}
                                                    dataSource={this.props.state.vehicles}
                                                    onNewRequest={
                                                        (request, index) => {
                                                            this.props.actions.setRenewalAutoCompleteSelected(request)
                                                        }
                                                    }
                                                    onClick={
                                                        (event) => {
                                                            this.props.actions.getVehichlesAutoCompleteEpic('')
                                                        }
                                                    }
                                                    onUpdateInput={
                                                        (search, dataSource, params) => {
                                                            this.props.actions.getVehichlesAutoCompleteEpic(search)
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>






                                </Col>

                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Description:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Description'
                                            value = {this.props.state.vehicleDescription}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setValue('vehicleDescription', value)
                                                }
                                            }
                                                            
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Vehicle Type:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Vehicle type'
                                            value = {this.props.state.vehicleType}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('vehicleType',value)
                                            }}
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>


                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Status Category:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Status Category'
                                            value = {this.props.state.vehicleStatusCat}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setValue('vehicleStatusCat', value)
                                                }
                                            }
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>



                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'PO Number:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Status Category'
                                            value = {this.props.state.poNumber}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setValue('poNumber', value)
                                                }
                                            }

                                            
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>


                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Submitted Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                                type="date"
                                        
                                            id={'txt_submitteddate'}
                                            fullWidth={true}
                                            hintText='Date'
                                            container='inline'
                                            mode='landscape'
                                            value={this.props.state.renewdate}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setValue('renewdate', value)
                                                }
                                            }
                                        />}
                                    </div>
                                </div>
                            

                            </Col>
                        </Row>

                       
                        
                    
                    
                    
                        
                    </Container>

                    <Container style={{ margin: 0 }} className="custom-container"></Container>
                        
                    <Container style={styles.whitediv}>
                        
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Country:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Country'
                                            value = {this.props.state.country}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setValue('country', value)
                                                }
                                            }
                                        
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Province:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Province'
                                            value = {this.props.state.province}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setValue('province', value)
                                                }
                                            }
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>


                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Licence Authority:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Licence Authority'
                                            value = {this.props.state.licenceauthority}
                                            onChange={
                                                (event, value) => {
                                                    this.props.actions.setValue('licenceauthority', value)
                                                }
                                            }
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>

                                    <div style={styles.fieldTitle}>{'Licence Authority Site:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Licence Authority Site'
                                        
                                        
                                        />}
                                    </div>

                                </div>

                           

                            </Col>
                        </Row>


                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Renewal Received Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                                type="date"
                                        
                                            id={'txt_renewalreceiveddate'}
                                            fullWidth={true}
                                            hintText='Date'
                                            container='inline'
                                            mode='landscape'
                                            value={this.props.state.renewalReceivedDate}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('renewalReceivedDate',value)
                                            }}
                                        />}
                                    </div>
                                </div>
                            

                            </Col>
                        </Row>


                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Licence Disc Number:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Licence Disk Number'
                                            value = {this.props.state.licenceDiscNumber}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('licenceDiscNumber',value)
                                            }}
                                            
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>


                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Licence Expiry Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                                type="date"
                                        
                                            id={'txt_effectdate'}
                                            fullWidth={true}
                                            hintText='Date'
                                            container='inline'
                                            mode='landscape'
                                            value={this.props.state.expirydate}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('expirydate',value)
                                            }}
                                        />}
                                    </div>
                                </div>
                            

                            </Col>
                        </Row>


                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'COF Required:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'COF Required'
                                            value = {this.props.state.cofrequired}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('cofrequired',value)
                                            }}
                                            
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>


                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Dispatch Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                                type="date"
                                        
                                            id={'txt_effectdate'}
                                            fullWidth={true}
                                            hintText='Date'
                                            container='inline'
                                            mode='landscape'
                                            value={this.props.state.licencedispatchdate}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('licencedispatchdate',value)
                                            }}
                                        />}
                                    </div>
                                </div>
                            

                            </Col>
                        </Row>



                                        

                    </Container >


                    <Container style={{ margin: 0 }} className="custom-container"></Container>

                    <Container style={styles.whitediv}>

                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Transaction Fee:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Transaction fee'
                                            value = {this.props.state.transactionfee}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('transactionfee',value)
                                                this.props.actions.calculateTotal(value)
                                            }}
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Licence Amount:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Licence amount'
                                            value = {this.props.state.licencefee}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('licencefee',value)
                                                this.props.actions.calculateTotal(value)
                                            }}
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Licence Penalty Amount:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Licence Penalty Amount'
                                            value = {this.props.state.penaltyfee}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('penaltyfee',value)
                                                this.props.actions.calculateTotal(value)
                                            }}
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'COF Amount:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'COF Amount'
                                            value = {this.props.state.coffee}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('coffee',value)
                                                this.props.actions.calculateTotal(value)
                                            }}
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Admin Fee:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Admin Fee'
                                            value = {this.props.state.adminfee}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('adminfee',value)
                                                this.props.actions.calculateTotal(value)
                                            }}
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Licence Total:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Licence Total'
                                            value = {this.props.state.totalamount}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('totalamount',value)
                                               
                                            }}
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>







                    </Container>

                    <Container style={{ margin: 0 }} className="custom-container"></Container>

                    <Container style={styles.whitediv}>


                        <Row>
                            <Col xl={12} style={styles.contentTopOffSet} >

                               

                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Comment:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'type your comment'
                                            value = {this.props.state.comment}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('comment',value)
                                                 
                                            }}
                                        
                                        />}
                                    </div>
                                </div>

                           

                            </Col>
                        </Row>


                        <Row>


                             <Col style={styles.contentTopOffSet}>

                               < Button className={"global_button"} variant="contained"
                                    label="Save Renewal"
                                    primary={true}
                                    onClick={(event) => {
                                          
                                            window.glyco.log("Finalize fires")
                                            this.props.actions.finalizeTransferEpic()
                                        }}
                                        >{window.t.en('Save Renewal')}</Button>

                            </Col>


                        </Row>


                      
                        

                    </Container>
                           
                
                   
                    
              
                
                         
                

                </CardContent>
        </Card>
    }

    render() {
        return (
            this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.licencerenewal
          
          
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LICENCERENEWAL)