import React, { Component } from 'react'
import GeneralContentView from '../../../views/GeneralContentView/component'
import ACCESSORY from '../ACCESSORY/component'
import CUSTOMERDERIVATIVE from '../CUSTOMERDERIVATIVE/component'
import WorkflowFMLQuote from '../../WorkflowFMLQuote/component'
import DISPOSALDECISION from '../DISPOSALDECISION/component'
import CUSTOMERSTATEMENT from '../CUSTOMERSTATEMENT/component'
import PURCHASEORDER from '../PURCHASEORDER/component'
import SUPPLIERPAYMENTMANAGEMENT from '../SUPPLIERPAYMENTMANAGEMENT/component'
import SUPPLIERPAYMENTMANAGEMENTAPPROVAL from '../SUPPLIERPAYMENTMANAGEMENTAPPROVAL/component'
import CONTRACTTERMINATION from '../CONTRACTTERMINATION/component'

class AdhocComponentTester extends Component {
    render() {
        return [
            //Import the components you want to test and add them to this array. It will be visible once clicking "Component Test" on the menu
            //Use the GeneralContentView component for spacing/display purposes
            //The "Component Test" menu item will only be visible in the development environment, it is hidden in production

            <GeneralContentView
                content={
                    //AdhocGrid component, use for custom grid implementations
                    //See https://react-table.js.org/#/story/readme for all column options
                    // <SUPPLIERINVOICE />
                    ''
                }
                headerImage={'test.png'}
                title={window.t.en('Adhoc Grid Test')}
                contentHeight={'100%'}
            />
        ]
    }
}

export default AdhocComponentTester