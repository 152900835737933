import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import JSReport from './component'
import Loading from '../Loading/component'
import moment from 'moment'
import * as _ from 'lodash'

import * as actions from './actions'

class Embed extends Component {

    componentDidMount() {
        this.props.actions.getJSReportData(this.props.report)
    }

    getLayout(){
        let report_data = this.props.state.report_data
        let report = this.props.report
        
        let data = null
        if (report_data) {
            
            data = {
                "account": this.props.state.user.client, 
                "reportheader": {
                    "reportname": report.report_name,
                    "reportdescription": report.report_desc,
                    "reportid": moment().format('HHmmss') + ' - ' + report.report_id,
                    "reportdate": moment().format('DD/MM/YYYY'),
                    "recordtotal": _.sumBy(report_data, (d) => { return parseFloat(d.amount ? d.amount : 0.00) }).toFixed(2),
                    "recordcount": Number(report_data.length),
                    "user": this.props.state.user.firstname + ' ' + this.props.state.user.lastname ,
                    "logoname": ''
                },
                "reportdata": report_data
            }
            return <JSReport shortid={null} name={report.name} data={data} report_id={report.report_id} id={report.report_id}/>
        }
    }

    render() {
        return this.props.state.report_data ? this.getLayout() : <Loading />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.jsReport,
            ...state.navigationMenu,
            user: { ...state.user },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Embed)