import * as appTypes from '../App/types'
import * as types from './types'
import moment from 'moment'

export const linkUploadedDocuments = (name) => {
    return (dispatch, getState) => {
        const uploading = getState().document[name].accepted.filter(x => x.uploading)
        const noSelection = getState().document[name].accepted.filter(x => !x.selected)
        const hasErrors = getState().document[name].accepted.filter(x => x.error)

        //console.log('linkUploadedDocumentsx')
        if (uploading.length || noSelection.length) {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'Document Uploader',
                    additionalText: '',
                    overflowText: '(' + uploading.length + ') are still being uploaded. (' + noSelection.length + ') have no selected document type.',
                    autoHide: 7500,
                    timestamp: moment().format('h:mm A')
                }
            })

            return
        }

        if (hasErrors.length) {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'Document Uploader',
                    additionalText: '',
                    overflowText: '(' + hasErrors.length + ') failed to upload. Please try again.',
                    autoHide: 7500,
                    timestamp: moment().format('h:mm A')
                }
            })

            return
        }

        //console.log('linkUploadedDocumentsx',hasErrors)
        
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Uploading...',
                additionalText: '',
                overflowText: 'Updating file type.',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.DOCUMENT_LINK_UPLOADED_DOCUMENTS,
            payload: {
                name: name,
                event: types.DOCUMENT_LINK_UPLOADED_DOCUMENTS,
                documents: getState().document[name].accepted.map(x => {
                    return {
                        document_id: x.document_id,
                        documenttype_id: x.selected
                    }
                })
            }
        })
    }
}

export const loadDocumentState = (name) => {
    return (dispatch) => {
        dispatch({
            type: types.LOAD_DOCUMENT_STATE,
            payload: name
        })

        dispatch(getDocumentTypes(name))
    }
}

export const uploadAcceptedDocuments = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.DOCUMENT_UPLOAD_ACCEPTED_DOCUMENTS,
            payload: getState().documents.accepted
        })
    }
}

export const getDocumentTypes = (name) => {
    return (dispatch) => {
        dispatch({
            type: types.DOCUMENT_GET_DOCUMENT_TYPES,
            payload: {
                name: name
            }
        })
    }
}

export const setDocumentTypes = (data, name) => {
    return (dispatch) => {
        dispatch({
            type: types.DOCUMENT_SET_DOCUMENT_TYPES,
            payload: {
                data: data,
                name: name
            }
        })
    }
}

export const notifyUser = (message) => {
    return (dispatch) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Document Uploader',
                additionalText: '',
                overflowText: message,
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setAcceptedDocuments = (documents, name, linker) => {
    return (dispatch, getState) => {
        
        //console.log('filesx act', documents)
        let acceptedDocuments = documents.map(x => {
            return {
                document: x,
                selected: 0,
                error: false
            }
        })
        let accepted = getState().document[name].accepted.concat(acceptedDocuments)
        dispatch({
            type: types.SET_ACCEPTED_DOCUMENTS,
            payload: {
                name: name,
                accepted: accepted
            }
        })

        //console.log('filesx st', getState().document[name].accepted)
        //console.log('filesx std', acceptedDocuments)
        //console.log('filesx file', acceptedDocuments[0])
        // /dispatch(uploadDocuments(getState().document[name].accepted, name, linker))
        dispatch(uploadDocuments(acceptedDocuments[0], name, linker))
    }
}

export const changeSelectedDocumentType = (name, index, value) => {
    return (dispatch) => {
        dispatch({
            type: types.DOCUMENT_CHANGE_SELECTED_DOCUMENT_TYPE,
            payload: {
                name: name,
                index: index,
                value: value
            }
        })
    }
}

export const setRejectedDocuments = (documents, name) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_REJECTED_DOCUMENTS,
            payload: {
                name: name,
                rejected: documents.map(x => {
                    return {
                        document: x,
                        selected: 0
                    }
                })
            }
        })
    }
}

export const getDocuments = () => {
    return (dispatch) => {
        dispatch({
            type: types.GET_DOCUMENTS
        })
    }
}

export const setDocuments = (documents) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_DOCUMENTS,
            payload: documents
        })
    }
}

export const changeDocumentTab = (tab) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_DOCUMENTS_CURRENT_TAB,
            payload: tab
        })
    }
}

export const uploadDocuments = (x, name, linker) => {
    return (dispatch) => {
        //console.log('filesx up', x)
        //documents.forEach((x, i) => {
        //    if (x.uploading || x.document_id) return

       //     //console.log('filesx uploading', x)
            dispatch({
                type: types.DOCUMENT_UPLOAD_COMPONENT_DOCUMENT,
                payload: {
                    event: 'DOCUMENT_UPLOAD_COMPONENT_DOCUMENT',
                    name: name,
                    file: x.document.name,
                    type: x.document.type,
                    document: x.document,
                    index: 0,
                    linker: linker,
                    datestamp: moment()
                }
            })
        //})
    }
}

export const setUploadedDocumentTypes = (documents, name, linker) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.DOCUMENT_LINK_UPLOADED_DOCUMENTS,
            payload: {
                name: name,
                documents
            }
        })
    }
}

export const downloadDocument = (document) => {
    return (dispatch) => {
        dispatch({
            type: types.DOWNLOAD_DOCUMENT,
            payload: document
        })
    }
}

export const viewDocument = (data) => {
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key ? data.data.document.key : data.data.document)

    return (dispatch) => {
        dispatch({
            type: types.VIEW_DOCUMENT,
            payload: {
                url: data.data.document.key, //getFile(data),
                type: data.data.document.extension
            }
        })
    }
}