import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux' 
import * as styles from './styles' 
import Icon from '../../components/Icon/component' 
//import { Card, CardHeader, CardContent, CardTitle, CardActions } from '@mui/material'
   
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Dialog,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip, Card, CardHeader, CardContent, CardTitle, CardActions
} from '@mui/material';
import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';

import get from 'lodash/get'
import Title from './title'

import * as redirectActions from "../../components/Redirect/actions";
import * as actions from './actions'  

class DetailComponentView extends Component {
    // componentDidMount() {
    //     this.props.actions.changeDetailComponentTab(0)
    // }

    headerTitle () {
        this.component = this.props.state.component
        let header_image = null 
        try{
            let arr_image = this.props.component.dataImage.split('.')

            header_image = this.props.component.dataImage 
                ? this.props.component.dataImage.indexOf('.') > 0
                    ? this.props.component.selected[0].original[arr_image[0]][arr_image[1]]
                    : this.props.component.selected[0].original[arr_image[0]]
                : null
        }
        catch(err) {}

        return (
        <>
            <div className="app-page-title app-page-title--shadow" style={{ marginBottom: '0px',padding: '20px 65px'}}>
            <div>
                <div className="app-page-title--first">
                {/* {(
                    <div className="app-page-title--iconbox d-70">
                    <div className="d-70 d-flex align-items-center justify-content-center display-1" 
                    style={{cursor: 'hand !important'}}
                    onClick={(event) => {
                        this.props.actions.route('/search/'+this.props.component.name)
                    }}>
                        <HomeWorkTwoToneIcon className="text-primary" />
                    </div>
                    </div>
                )} */}
                <div className="app-page-title--heading">
                    <h1>{this.props.component.info.title}</h1>
                    {(
                    <div className="app-page-title--description">
                        {this.props.component.info.description}
                    </div>
                    )}
                </div>
                    <div
                        key={'detail_component_view_kpi_image_' + this.props.component.name}
                        style={styles.kpi_image(
                            header_image !== null
                                ? header_image //get(this.props.component.data, this.props.component.dataImage)
                                : null
                        )}
                    />
                </div>

            </div>
            <div className="d-flex align-items-center">
                {/* {children} */}
                {/* <Button
                variant="contained"
                //onClick={openUserMenu}
                size="small"
                className="btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3">
                <span className="btn-wrapper--label">Actions</span>
                <span className="btn-wrapper--icon">
                    <FontAwesomeIcon
                    icon={['fas', 'angle-down']}
                    //className="opacity-5"
                    />
                </span>
                </Button> */}
                {/* {this.props.buttons} */}
                {/* <Tooltip title="Add new entry">
                <Button
                    variant="contained"
                    size="small"
                    className="d-40 btn-success"
                    //onClick={toggle1}
                    >
                    <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'plus']} className="opacity-8" />
                    </span>
                </Button>
                </Tooltip> */}
            </div>
            </div>
            </>
        )
    }

    
    render() {
        
        //console.log('detailComponentViewx',this.props.data)
        return (
            <div
                key={'detail_component_view_content_' + this.props.component.name}
                style={styles.content}
            >
                <div
                    key={'detail_component_view_title_' + this.props.component.name}
                    style={styles.title}
                >
                    <div style={styles.title_header}>
                        {this.headerTitle()}
                        {/* <TiheaderTitletle component={this.props.component} buttons={this.props.buttons}/> */}
                        {/* {window.t.en(this.props.component.title)} */}
                    </div>
                    <div style={styles.title_buttons}>
                    {/* {this.props.buttons} */}
                    </div>
                </div>

                <div
                    //key={'detail_component_view_kpi_' + this.props.component.name}
                    style={styles.kpi}
                >
                    <div
                        key={'detail_component_view_kpi_image_' + this.props.component.name}
                        style={styles.kpi_image(
                            this.props.component.dataImage !== null
                                ? get(this.props.component.data, this.props.component.dataImage)
                                : this.props.headerTitle
                        )}
                    />

                    <div
                        key={'detail_component_view_kpi_text_' + this.props.component.name}
                        style={styles.kpi_text}
                    >
                        {this.props.description ? this.props.description : this.props.component.description}
                    </div>

                    <div
                        key={'detail_component_view_kpi_separator_' + this.props.component.name}
                        style={styles.kpi_separator}
                    />

                    <div
                        key={'detail_component_view_kpi_elements_' + this.props.component.name}
                        style={styles.kpi_elements}
                    >

                    </div>

                    <div
                        key={'detail_component_view_kpi_download_' + this.props.component.name}
                        style={styles.kpi_download}
                        onClick={(event) => {
                            event.preventDefault()
                        }}
                    > Download
                        {/* <Icon iclass={'material-icons'} iname={'cloud_download'} istyle={styles.icon} /> */}
                    </div>
                </div>
                {
                    this.props.relationships
                    ? <div
                        key={'detail_component_view_kpi_' + this.props.component.name}
                        style={styles.relatedmenus} >
                            {this.props.menu}
                    </div>
                    : ''
                }
                <div
                    key={'detail_component_view_tabs_' + this.props.component.name}
                    style={styles.tabs}
                > 
                    {!this.props.loading
                        ? (
                                <div style={styles.data}>
                                    <div style={{textAlign: 'right', width: '100%'}}>
                                    {this.props.buttons}
                                    </div>
                                    {this.props.data}
                                    
                                    {/* <Card initiallyExpanded={false}>
                                        <CardHeader
                                            avatar={<Icon iclass={'material-icons'} iname={'attach_file'} />}
                                            title={window.t.en('Documents')}
                                            actAsExpander={true}
                                            showExpandableButton={true}
                                        />
                                        <CardContent expandable={true}>
                                            {this.props.documents}
                                        </CardContent>
                                    </Card> */}

                                    <br />
                                    <br />
                                </div>
                        )
                        : (
                            'Loading...'
                        )
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.detailComponentView }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...redirectActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailComponentView)