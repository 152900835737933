import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
// import TextField from '@mui/material/TextField'
import * as styles from './styles'
import * as actions from './actions'
import PdfViewer from '../../PDF/component'
import Upload from '../../Upload/component'
import ViewVehicle from '../../../controls/view_vehicle'
import Card from '../../../controls/card'
import Section from '../../../controls/section'
import Checkbox from '../../../controls/checkbox'
import DateControl from '../../../controls/dateControl'
import TextField from '../../../controls/textField'

class DefleetAcceptRebill extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
    }

    SupplierLatter(data) {
        return <Card
            title={window.t.en('Supplier Details')}
            description={data.suppliername}
            style={{ textAlign: 'left' }}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.suppliername}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.suppliertype}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.registrationnumber}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.vatnumber}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Supplier Limit:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.supplierage.length ? data.supplierage[0].suplierspendlimit : ''}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Supplier Usage:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.supplierage.length ? data.supplierage[0].suplierusage : ''}</Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>} />
    }
    checksheetComments(steps) {
        //console.log("checksheetComments - steps")
        //console.log(steps)
        return <Card
            title={window.t.en('Check Sheet Comments')}
            description={window.t.en('Comments captured on upload Check Sheet')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                 <Row>
                    <Col xl={2} style={styles.fieldLabel}>{'Uploaded By: '}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{steps.step2.user[0].firstname + " " + steps.step2.user[0].lastname}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Uploaded Date: '}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step2.data.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{steps.step2.user[0].email}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{steps.step2.user[0].phone}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Check Sheet Completed By: '}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{steps.step2.data.completedby}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Check Sheet Completed Date: '}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step2.data.completeddate).format('YYYY-MM-DD')}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Location:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{steps.step2.data.location}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Odometer Reading:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{steps.step2.data.odoreading}</Col>
                </Row>
                <Row> <Col xl={12}><br></br></Col></Row>   
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            label={'Enter Comments'}
                            disabled={true}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={steps.step2.data.comments}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    quotesComments(steps) {
        return <Card
            title={window.t.en('Quotes Comments')}
            description={window.t.en('Comments captured on quotes & upload')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                  <Row>
                    <Col xl={2} style={styles.fieldLabel}>{'Quote Date: '}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step3.data.quotedate).format("YYYY-MM-DD")}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Captured By: '}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{steps.step3.user.firstname + " " + steps.step3.user.lastname}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Captured Date: '}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step3.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{steps.step3.user.email}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{steps.step3.user.phone}</Col>
                </Row>
                <Row> <Col xl={12}><br></br></Col></Row> 
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            label={'Enter Comments'}
                            disabled={true}
                            fullWidth={true}
                            multiLine={false}
                            value={steps.step3.data.comments.value}
                            variant="outlined"
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    techManagerComments(steps) {
        return <div>
            <Card
                title={window.t.en('Rebill ')}
                description={window.t.en('Rebill summary')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={2} style={styles.fieldLabel}>{'De-fleet Date:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step2.datestamp).format("YYYY-MM-DD")}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{steps.step1.data.defleetNumber}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Quote Date:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step3.quotedate).format("YYYY-MM-DD")}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Service Plan:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{steps.step3.serviceplan}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Repair Days:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{(steps.step3.data.days.value)}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Warranty:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{steps.step3.warranty}</Col>
                    </Row>
                </Container>} />
            {
                steps.step4 && steps.step4.comments ?
                    <Card
                        title={window.t.en('Tech Manager Comments')}
                        description={window.t.en('Tech Manager approval comments for Rebill')}
                        content={<Container style={{ margin: 0 }} className="custom-container">
                             <Row>
                        <Col xl={2} style={styles.fieldLabel}>{'Approved By:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{steps.step4.user.firstname} {steps.step4.user.lastname}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step4.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Email:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{steps.step4.user.email}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Phone:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{steps.step4.user.phone}</Col>
                    </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                            <Row>
                                <Col xl={12}>
                                    <TextField
                                        id={'txtcomments'}
                                        label={'Enter comments'}
                                        fullWidth={true}
                                        multiLine={false}
                                        disabled={true}
                                        value={steps.step4.comments}
                                        variant="outlined"
                                    />
                                </Col>
                            </Row>
                        </Container>} /> : ''} 
                        {
                steps.step44 && steps.step44.comments ?
                <Card
                title={window.t.en('GM Operations Comments')}
                description={window.t.en('Tech Manager approval comments for Rebill')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={2} style={styles.fieldLabel}>{'Approved By:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{steps.step44.user.firstname} {steps.step44.user.lastname}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step44.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Email:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{steps.step44.user.email}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Phone:'}</Col>
                        <Col xl={2} style={styles.selectedFieldName}>{steps.step44.user.phone}</Col>
                    </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomments'}
                                label={'No comments'}
                                fullWidth={true}
                                multiLine={false}
                                disabled={true}
                                variant="outlined"
                                value={steps.step44.comments}
                            />
                        </Col>
                    </Row>
                </Container>} /> : ''}
            {
                steps.step5 && steps.step5.comments ? <Card
                    title={window.t.en('GM Comments')}
                    description={window.t.en('GM approval comments for Rebill')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Approved By:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{steps.step5.user.firstname} {steps.step5.user.lastname}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step5.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Email:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{steps.step5.user.email}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Phone:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{steps.step5.user.phone}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    id={'txtcomments'}
                                    label={'Enter comments'}
                                    disabled={true}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={steps.step5.comments}
                                    onChange={(event) => {
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>} /> : ''}
            {
                steps.step6 && steps.step6.comments ?
                    <Card
                        title={window.t.en('CEO Comments')}
                        description={window.t.en('CEO approval comments for Rebill')}
                        content={<Container style={{ margin: 0 }} className="custom-container">
                            
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Approved By:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{steps.step6.user.firstname} {steps.step6.user.lastname}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{moment(steps.step6.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Email:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{steps.step6.user.email}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Phone:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{steps.step6.user.phone}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                            <Row>
                                <Col xl={12}>
                                    <TextField
                                        id={'txtcomments'}
                                        label={'Enter comments'}
                                        disabled={true}
                                        fullWidth={true}
                                        multiLine={false}
                                        variant="outlined"
                                        value={steps.step6.comments}
                                    />
                                </Col>
                            </Row>
                        </Container>} /> : ''}
        </div>
    }
    LabourRates(data, maint) {
        const supplier = data
        return <Card
            title={window.t.en('Labour Rates')}
            description={window.t.en('Customer labour rates.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} style={styles.fieldLabel}>{'Spares Discount:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{supplier.sparesdiscount ? supplier.sparesdiscount : 'Not Set'}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Labour Rate Per Hour:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{supplier.labourrateperhour ? supplier.labourrateperhour : 'Not Set'}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'In Warranty Labour Rate:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{supplier.inwarrantylabourrate ? supplier.inwarrantylabourrate : 'Not Set'}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Out of Warranty Labour Rate:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{supplier.outwarrantylabourrate ? supplier.outwarrantylabourrate : 'Not Set'}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Special Labour Rate:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{supplier.speciallabourrate ? supplier.speciallabourrate : 'Not Set'}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'After Hours Labour Rate:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{supplier.afterhourslabourrate ? supplier.afterhourslabourrate : 'Not Set'}</Col>
                </Row>
            </Container>} />
    }
    AuthDocument(documents) {
        return <Card
            title={window.t.en('Supporting Documents')}
            description={window.t.en('Rebill supporting document')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <PdfViewer
                            key={'quotation_viewer_'}
                            documents={documents}
                            height={600}
                            multiple={false}
                            edit={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col><br /></Col>
                </Row>
            </Container>} />
    }
    uploadInvoice(data) {
        return <Card
            title={window.t.en('Upload Purchase Order')}
            description={window.t.en('Upload customer purchase order.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <Upload
                            id={'upload_ctl'}
                            prefix={'Rebill_PurchaseOrder_' + data.licenseplateno + '_'}
                            header={false}
                        />
                    </Col>
                </Row>
                 <Row>
                        <Col xl={12}>
                            <Checkbox
                                id={'txt_dispute_rebill'}
                                key={'txt_dispute_rebill'}
                                name={'txt_dispute_rebill'}
                                field={'dispute_rebill'}
                                checked={this.props.state.dispute_rebill ? true : false}
                                labelPlacement={'end'}
                                variant="outlined"
                                label={window.t.en('Dispute Rebill')}
                               /*  setComponentFieldInput={
                                    this.props.actions.setValue9
                                } */
                                onCheck={(checked) => {
                                    this.props.actions.setValue('dispute_rebill', checked)
                                }}
                            />
                        </Col>
                    </Row>
            </Container>} />
    }
    commentsView(steps) {
        return <div>
            <Card
                title={window.t.en('Capture Purchase Order')}
                subtitle={window.t.en('Capture purchase order details')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                   
            {this.props.state.dispute_rebill ?
                this.rebilldisputed() :
                <div>
                    <Row>
                    <Col xl={4} style={{paddingTop:'10px'}}>
                        <Checkbox
                            id={'txt_customer_po'}
                            key={'txt_customer_po'}
                            name={'txt_customer_po'}
                            field={'customer_po'}
                            checked={this.props.state.customer_po ? true : false}
                            labelPlacement={'end'}
                            variant="outlined"
                            label={window.t.en('Received Customer PO')}
                            // setComponentFieldInput={                                
                            //     this.props.actions.setValue
                            // }
                            onCheck={(checked) => {
                                this.props.actions.setValue('customer_po', checked)
                            }}
                        />
                    </Col>
                        <Col xl={4} style={{paddingTop:'10px'}}>
                            <Checkbox
                                id={'txt_issupplier_correct'}
                                key={'txt_issupplier_correct'}
                                name={'txt_issupplier_correct'}
                                field={'issupplier_correct'}
                                checked={this.props.state.issupplier_correct}
                                labelPlacement={'end'}
                                variant="outlined"
                                label={window.t.en('Is the correct supplier selected')}
                                // setComponentFieldInput={                                
                                //     this.props.actions.setValue
                                // }
                                onCheck={(checked) => {
                                    this.props.actions.setValue('issupplier_correct', checked)
                                }}
                            />
                        </Col>
                        <Col xl={4}>
                                    {this.props.state.customer_po ?
                                        <DateControl
                                            id={'txt_po_date'}
                                            key={'txt_po_date'}
                                            name={'txt_po_date'}
                                            label={window.t.en('Select PO Date')}
                                            field={'po_date'}
                                            fullWidth={true}
                                            value={this.props.state.po_date}
                                            onSelect={(args) => {
                                                this.props.actions.setValue('po_date', args)
                                            }}
                                            type={'date'}
                                        />
                                        :
                                        <TextField
                                            id={'txtinvnumber'}
                                            label={'Enter purchase order number'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            variant="outlined"
                                            value={this.props.state.po_date ? moment(this.props.state.po_date).format('YYYY-MM-DD') : ''}
                                            onChange={(event) => {
                                            }}
                                        />
                                    }
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        id={'txtinvnumber'}
                                        label={'Enter purchase order number'}
                                        fullWidth={true}
                                        multiLine={false}
                                        variant="outlined"
                                        disabled={this.props.state.customer_po ? false : true}
                                        value={this.props.state.po_number}
                                        onChange={(event) => {
                                            let value = event.target.value
                                            this.props.actions.setValue('po_number', value)
                                        }}
                                    />
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        id={'txtinvamount'}
                                        label={'Enter purchase order total amount'}
                                        fullWidth={true}
                                        multiLine={false}
                                        variant="outlined"
                                        value={this.props.state.po_amount}
                                        onChange={(event) => {
                                            let value = event.target.value
                                            this.props.actions.setValue('po_amount', value)
                                            this.props.actions.setValue('rebill_amount', steps.step3.data.defleetAmount)
                                        }}
                                    />
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        id={'txtinvamount'}
                                        label={'Enter rebill total amount'}
                                        fullWidth={true}
                                        disabled={true}
                                        variant="outlined"
                                        multiLine={false}
                                        value={steps.step3.data.defleetAmount}
                                        onChange={(event) => {
                                        }}
                                    />
                        </Col>
                    </Row>
                </div>}
          
          </Container>} />  
        </div>
    }
    rebilldisputed() {
        return <div>
            <Section
            title={window.t.en('Rebill Dispute Comments')}
            description={window.t.en('Capture Comments')}/>
           <Row>
                <Col xl={6}>
                            <DateControl
                                id={'txt_rebill_dispute_date'}
                                key={'txt_rebill_dispute_date'}
                                name={'txt_rebill_dispute_date'}
                                label={window.t.en('Enter Dispute Date')}
                                field={'rebill_dispute_date'}
                                fullWidth={true}
                                value={this.props.state.rebill_dispute_date}
                                onSelect={(args) => {
                                    this.props.actions.setValue('rebill_dispute_date', args)
                                }}
                                type={'date'}
                            />
                </Col>
                    <Col xl={6} style={{paddingTop:'15px'}}>
                        <TextField
                            id={'txtinvnumber'}
                            label={'Enter comments'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={this.props.state.rebill_dispute_comments}
                            onChange={(event) => {
                                let value = event.target.value
                                this.props.actions.setValue('rebill_dispute_comments', value)
                            }}
                        />
                    </Col>
                </Row>
                </div>
    }
    terminationRequestDetails() {
        return (
            <Card
                title={window.t.en('Termination Request Details')}
                description={window.t.en('Capture termination details')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={4}>
                                <DateControl
                                    id={'txt_requested_termination_date'}
                                    key={'txt_requested_termination_date'}
                                    name={'txt_requested_termination_date'}
                                    label={window.t.en('Select requested date')}
                                    field={'requested_termination_date'}
                                    fullWidth={true}
                                    value={this.props.state.requested_termination_date}
                                    onSelect={(args) => {
                                        this.props.actions.setValue('requested_termination_date', args);
                                    }}
                                    type={'date'}
                                />
                            </Col>
                            <Col xl={4} style={{ paddingTop: '15px' }}>
                                <TextField
                                    id={'txtinvnumber'}
                                    label={'Enter requested odometer'}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.requested_termination_odo}
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        this.props.actions.setValue('requested_termination_odo', value);
                                    }}
                                />
                            </Col>
                            <Col xl={4} style={{ paddingTop: '15px' }}>
                                <TextField
                                    id={'txtinvamount'}
                                    label={'Enter comments requested comments'}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.requested_termination_comments}
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        this.props.actions.setValue('requested_termination_comments', value);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                }
            />
        );
    }
    
    renderView(data) {
        let step1 = data.step1.data
        let step3 = data.step3.data
        let vehicle = step1.selectedVehicleDetails.data[0]
        let supplier = step3.selectedSupplierDetails.data[0]
        return <Container style={{ margin: 0 }} className="custom-container">
            <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />

            {/* <Row>
                            <Col xl={12}>
                                {this.SupplierLatter(supplier)}
                            </Col>
                        </Row> 
            <Row>
                <Col xl={12}>
                    {this.LabourRates(supplier, vehicle.maintenancetransaction)}
                </Col>
            </Row>*/}
         
                <Row>
                    <Col xl={12}>
                        {this.checksheetComments(data)}
                    </Col>
                </Row>
            
                <Row>
                    <Col xl={12}>
                        {this.quotesComments(data)}
                    </Col>
                </Row>
            <Row>
                <Col xl={12}>
                    {this.techManagerComments(data)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.AuthDocument(data.documents)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.uploadInvoice(vehicle)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.commentsView(data)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.terminationRequestDetails()}
                </Col>
            </Row>
        </Container>
    }


    render() {
        let workflow_queue_data = null
        let validate = false
        try {

            //console.log("this.props.task", this.props.task)
            validate = this.props.task === this.props.workflow_queue_data.workflow_task.name
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]

        } catch (error) {

        }

        return validate
            ? this.renderView(workflow_queue_data)
            : 'Select vehicle...'
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetAcceptRebill
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetAcceptRebill)