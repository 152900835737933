import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
// import TextField from '@mui/material/TextField'
import QuoteItems from '../../WorkflowPOHeader/QuoteItems/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import * as styles from './styles'
import * as actions from './actions'
import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'
import TextField from '../../../controls/textField'

import ViewVehicle from '../../../controls/view_vehicle'

class DefleetQuoteApprovalCEO extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
    }

    SupplierLatter(data) {
        return <Card
            title={window.t.en('Supplier Details')}
            description={data.suppliername}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.suppliername}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.suppliertype}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.registrationnumber}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.vatnumber}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Supplier Limit:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.supplierage.length ? data.supplierage[0].suplierspendlimit : ''}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Supplier Usage:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.supplierage.length ? data.supplierage[0].suplierusage : ''}</Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>} />
    }
    quoteItems(vehicle, data) {
        const maint = vehicle.maintenancetransaction
        const licenseplateno = vehicle.licenseplateno
        return <Card
            title={window.t.en('Quote Items')}
            description={window.t.en('Quote items captured.')}
            content={<QuoteItems workflow_queue_data={maint} workflow_quote_data={data} read_only={true} licenseplateno={licenseplateno} />} />
    }
    checksheetComments(steps) {
        return <Card
            title={window.t.en('Check Sheet Comments')}
            description={window.t.en('Comments captured on upload Check Sheet')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Uploaded By: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step2.user[0].firstname + " " + steps.step2.user[0].lastname}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Uploaded Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step2.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step2.user[0].email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step2.user[0].phone}</Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            hintText={'Enter Comments'}
                            disabled={true}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={steps.step2.data.comments}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    quotesComments(steps) {
        return <Card
            title={window.t.en('Quotes Comments')}
            description={window.t.en('Comments captured on quotes & upload')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Captured By: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step3.user.firstname + " " + steps.step3.user.lastname}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Captured Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step3.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step3.user.email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step3.user.phone}</Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            hintText={'Enter Comments'}
                            disabled={true}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={steps.step3.data.comments.value}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    techManagerComments(steps) {
        return <div><Card
            title={window.t.en('Rebill ')}
            description={window.t.en('Rebill summary')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'De-fleet Date:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step2.data.defleetdate ? steps.step2.data.defleetdate : steps.step2.datestamp).format("YYYY-MM-DD")}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step1.data.defleetNumber}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Quote Date:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step3.data.quotedate ? steps.step3.data.quotedate : steps.step3.quotedate).format("YYYY-MM-DD")}</Col>

                    <Col xl={3} style={styles.fieldLabel}>{'Service Plan:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step3.serviceplan}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Repair Days:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{(steps.step3.data.days.value)}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Warranty:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step3.warranty ? steps.step3.warranty : ''}</Col>
                </Row>
            </Container>} />
            {steps.step4.comments ?
                <Card
                    title={window.t.en('Tech Manager Comments')}
                    description={window.t.en('Tech Manager approval comments for Rebill')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{steps.step4.user.firstname} {steps.step4.user.lastname}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step4.datestamp).format('YYYY-MM-DD HH:mm')}</Col>

                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{steps.step4.user.email}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{steps.step4.user.phone}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    id={'txtcomments'}
                                    hintText={'Enter comments'}
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    variant="outlined"
                                    value={steps.step4.comments}
                                    onChange={(event, value) => {
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>} /> : ''}
            {steps.step44.comments ?
                <Card
                    title={window.t.en('GM Operations Comments')}
                    description={window.t.en('GM Operations approval comments for Rebill')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{steps.step44.user.firstname} {steps.step44.user.lastname}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step44.datestamp).format('YYYY-MM-DD HH:mm')}</Col>

                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{steps.step44.user.email}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{steps.step44.user.phone}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    id={'txtcomments'}
                                    hintText={'Enter comments'}
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    variant="outlined"
                                    value={steps.step44.comments}
                                    onChange={(event, value) => {
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>} /> : ''}
        </div>
    }
    GMComments(steps) {
        return <Card
            title={window.t.en('GM Approval Comments')}
            description={window.t.en('GM approval comments for Rebill')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step5.user.firstname} {steps.step5.user.lastname}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step5.datestamp).format('YYYY-MM-DD HH:mm')}</Col>

                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step5.user.email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{steps.step5.user.phone}</Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomments'}
                            hintText={'Enter comments'}
                            fullWidth={true}
                            disabled={true}
                            multiLine={false}
                            variant="outlined"
                            value={steps.step5.comments}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
    }

    commentsView(steps) {
        return <Card
            title={'Approval Comments'}
            subtilte={'Enter approval comments for Rebill'}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row >
                    <Col xl={12}>
                        <Checkbox
                            id={'txt_send_authorisation'}
                            key={'txt_send_authorisation'}
                            name={'txt_send_authorisation'}
                            field={'send_authorisation'}
                            checked={this.props.state.auth_status_changed.value ? this.props.state.send_authorisation.value : steps.step5.data.send_authorisation}
                            defaultValue={steps.step5.data.send_authorisation}
                            labelPlacement={'end'}
                            variant="outlined"
                            label={window.t.en('Send Authorisation to Supplier')}
                            // setComponentFieldInput={
                            //     this.props.actions.setValue
                            // }
                            onCheck={(checked) => {
                                this.props.actions.setValue('send_authorisation', checked)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomments'}
                            label={'Enter comments...'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={this.props.state.comments.value}
                            onChange={(event) => {
                                let value = event.target.value
                                this.props.actions.setValue('comments', value)
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    LabourRates(data, maint) {
        const supplier = data
        return <Card
            title={window.t.en('Labour Rates')}
            description={window.t.en('Customer labour rates.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Spares Discount:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.sparesdiscount ? supplier.sparesdiscount : 'Not Set'}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Labour Rate Per Hour:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.labourrateperhour ? supplier.labourrateperhour : 'Not Set'}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'In Warranty Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.inwarrantylabourrate ? supplier.inwarrantylabourrate : 'Not Set'}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Out of Warranty Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.outwarrantylabourrate ? supplier.outwarrantylabourrate : 'Not Set'}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Special Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.speciallabourrate ? supplier.speciallabourrate : 'Not Set'}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'After Hours Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.afterhourslabourrate ? supplier.afterhourslabourrate : 'Not Set'}</Col>
                </Row>
            </Container>} />
    }

    renderView(data) {
        let documents = data.documents
        let step1 = data.step1.data
        let step3 = data.step3.data
        let vehicle = step1.selectedVehicleDetails.data[0]
        let supplier = step3.selectedSupplierDetails.data[0]

        //console.log("data", data)
        return <Container style={{ margin: 0 }} className="custom-container">
            <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
            {/* <Row>
                <Col xl={12}>
                    {this.SupplierLatter(supplier)}
                </Col>
            </Row> 
            <Row>
                <Col xl={12}>
                    {this.LabourRates(supplier, vehicle.maintenancetransaction)}
                </Col>
            </Row>*/}
            <Row>
                <Col xl={12}>
                    {this.quoteItems(vehicle, step3.quotation)}
                </Col>
            </Row>
            {data.step2.data.comments ?
                <Row>
                    <Col xl={12}>
                        {this.checksheetComments(data)}
                    </Col>
                </Row> : ''}
            {data.step3.data.comments.value ?
                <Row>
                    <Col xl={12}>
                        {this.quotesComments(data)}
                    </Col>
                </Row> : ''}
            <Row>
                <Col xl={12}>
                    {this.techManagerComments(data)}
                </Col>
            </Row>
            {data.step5.comments ?
                <Row>
                    <Col xl={12}>
                        {this.GMComments(data)}
                    </Col>
                </Row>
                : ''}
            <Row>
                <Col xl={12}>
                    <br></br>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {
                        documents
                            ? <PdfViewer
                                key={'document_viewer_approval'}
                                name={'booking'}
                                workflow={'fml_customer_quote'}
                                task={'tsk_defleet_review_and_approve_tech'}
                                documents={documents}
                                edit={false}
                                workflow_queue_data={this.props.workflow_queue_data}
                            />
                            : <Loading message={'Retrieving Booking Document...'} />
                    }
                </Col>
            </Row>

            <Row>
                <Col xl={12}>
                    <br></br>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.commentsView(data)}
                </Col>
            </Row>
        </Container>
    }

    render() {
        let workflow_queue_data = null
        let validate = false
        try {

            //console.log("this.props.task", this.props.task)
            validate = this.props.task === this.props.workflow_queue_data.workflow_task.name
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]

        } catch (error) {

        }

        return validate
            ? this.renderView(workflow_queue_data)
            : 'Select vehicle...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetQuoteApprovalCEO
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetQuoteApprovalCEO)