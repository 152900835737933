import React, { Component, forwardRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


import { List, ListItem, ListItemButton, Link } from '@mui/material';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from '@mui/material/Avatar';
import { ReactComponent as HomeIcon} from '../../assets/img/svg/icons/home.svg'


import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {ReactComponent as AccountBalanceWalletIcon} from '../../assets/img/svg/icons/wallet.svg';
import {ReactComponent as AccountBalanceIcon} from '../../assets/img/svg/icons/opernations-module.svg';
import {ReactComponent as ComputerIcon} from '../../assets/img/svg/icons/asset-module.svg';
import {ReactComponent as GroupIcon} from '../../assets/img/svg/icons/human-resources.svg';
import {ReactComponent as TroubleshootIcon} from '../../assets/img/svg/icons/operation.svg';
import {ReactComponent as AccountCircleIcon} from '../../assets/img/svg/icons/administration.svg';
import {ReactComponent as SettingsIcon} from '../../assets/img/svg/icons/Configuration-data.svg';

import SVGLoader from '../../svgloader';
import Collapse from "@mui/material/Collapse";

import * as actions from "./actions";
import * as redirectActions from "../Redirect/actions";
import * as styles from "./styles";

import * as config from '../../config'

import {
    Typography,
} from '@mui/material';


const _icons = (category) => {
    let icon = null;

    switch (category) {
            case 'accounts':
            return <AccountBalanceWalletIcon style={{marginRight: 10, width: 24, height: 24}}/>
            break;
            case 'contracts':
            return <AccountBalanceIcon style={{marginRight: 10, width: 24, height: 24}}/>
            break;
            case 'admin':
            return <AccountCircleIcon style={{marginRight: 10, width: 24, height: 24}}/>
            break;
            case 'vehicles':
            return <ComputerIcon style={{marginRight: 10, width: 24, height: 24}}/>
            break;
            case 'drivers':
            return <GroupIcon style={{marginRight: 10, width: 24, height: 24}}/>
            break;
            case 'operations':
            return <TroubleshootIcon style={{marginRight: 10, width: 24, height: 24}}/>
            break;
        default:
            return <SettingsIcon style={{marginRight: 10, width: 24, height: 24}}/>
            break;
    }
    
}

class MenuComponent extends Component {

   
    getIcon (component, level) {
        component = component.toLowerCase().replace(/,/g, '').replace(/ /g, '')
        return <SVGLoader svgFileName={component} styleName={'icon-style'} isKPI={false}/>

    }

    getMainSectionIcon (index){
        return mainSectionIcon[index - 1]
    }

    getCollapse (section, level) {
        const menus = section.components?.map((component) => {
            return component.components
                ? this.getCollapse(component, level + 1)
                : this.getNavItem(component, level + 1, level)
        });

        // const Icon = section.icon;
        const menuIcon = this.getIcon(section.title, level)
        //   section.icon ? (
        //     // <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto', color: '#fff', }} />
        //     // <Icon iclass={section.icon.unicode} iname={section.icon.name} />
        //     this.getIcon(item.name, level)
        // ) : (
        //     <RadioButtonUncheckedIcon
        //         // style={{
        //         //     width: 8,//this.props.state.selected === section.title ? 8 : 6,
        //         //     height: 8,//this.props.state.selected === section.title ? 8 : 6
        //         // }}
        //         stroke={1.5} size="10px" style={{ marginTop: 'auto', marginBottom: 'auto' }}
        //         color={'#fff'}
        //         // fontSize={level > 0 ? 'medium' : 'medium'}
        //     />
        // );

        return (
            <>
                <ListItem
                    style={{
                        borderRadius: `0px`,
                        marginBottom: 0.5,
                        // alignItems: 'flex-start',
                        ///backgroundColor: 'transparent',
                        color: '#fff',
                        // borderLeft: '1px white solid',
                        //paddingTop: level > 1 ? 1 : 1.25,
                        //marginLeft: `${level * 24}px`,
                        paddingLeft: `${level * 24}px`,
                        "&:hover": {
                            color: "#fff",
                            //backgroundColor: 'transparent',
                            fontWeight: '500'
                        },
                        // '&:selected': {
                        //     backgroundColor: 'transparent'
                        // },
                        width: '310px',
                        height: '50px',
                        overflow: 'hidden'
                        
                    }}
                    // selected={selected === menu.id}
                    onClick={(event) => { 
                        event.stopPropagation()
                        this.props.actions.setInputValue('collapse', this.props.state.collapse === section.title ? "" : section.title)}
                    }
                >
                    <ListItemButton
                        className="icon-style"
                        onClick={() => {
                            //console.log('selectedItemMain', section);
                            this.props.actions.setInputValue('selectedItemMain', section.title)
                            this.props.actions.setInputValue('selectedItemMainTitle', section.title)
                            this.props.actions.setInputValue('selectedItemMainDesc', section?.description ? section.description : `${section.title} List`)
                        }}
                        selected={this.props.state.selectedItemMain === section.title}
                        sx={config.system.clientTheme === 'bidvest' ? styles.hoverColorBidvest : styles.hoverColor}>
                    <ListItemIcon style={{ marginTop: 'auto', marginBottom: 'auto', minWidth: !section.icon ? 18 : 18, paddingRight: '16px' }}>{menuIcon}</ListItemIcon>
                    <ListItemText
                        // primary={
                        //     <Typography variant='caption' color="white" style={{ fontSize: '14px',marginTop: 'auto', marginBottom: 'auto'  }}>
                        //         {section.title}
                        //     </Typography>
                        // }
                        primary={
                            <div style={{ fontWeight: '100', fontSize: '15px', marginTop: 'auto', marginBottom: 'auto'  }}>
                                {window.t.en(section.title)}
                            </div>
                        }
                        secondary={
                            section.description && (
                                <Typography variant="caption" style={{
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    color: '#131B3A', // theme.darkTextSecondary,
                                    textTransform: 'capitalize'
                                }} display="block" gutterBottom>
                                    {window.t.en(section.description)} 
                                </Typography>
                            )
                        }
                    />
                    {this.props.state.collapse === section.title ? (
                        <KeyboardArrowDownIcon stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    ) : (
                        <KeyboardArrowUpIcon stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    )}
                    </ListItemButton>
                </ListItem>
                <Collapse in={this.props.state.collapse === section.title} timeout="auto" unmountOnExit>
                    <List
                        component="div"
                        disablePadding
                        style={{
                            backgroundColor: 'transparent',
                            position: 'relative',
                            borderLeft: '1px solid grey',
                            marginLeft: `${level * 50}px`,
                            '&:after': {
                                content: "''",
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                left: '1px',
                                top: 0,
                                height: '100%',
                                width: '1px',
                                opacity: 1,
                                background: '#fff'
                            },
                            "&:hover": {
                                color: "#000",
                                backgroundColor: '#FFE516 !important'
                                //backgroundColor: 'transparent'
                              }
                        }}
                    >
                        {menus}
                    </List>
                </Collapse>
            </>
        );
    }



        getNavItem(item, level, ico ) {

            // const Icon = item.icon;
            // let itemIcon = item.icon ? (
            //     // <Icon iclass={item.icon.unicode} iname={item.icon.name}  />
            //     // <Icon
            //     //     iclass={item.icon.unicode}
            //     //     iname={item.icon.name}
            //     // />
            //     this.getIcon(item.name, level)
            //     // <RadioButtonUncheckedIcon
            //     //     // style={{
            //     //     //     width: 8,//this.props.state.selected === section.title ? 8 : 6,
            //     //     //     height: 8,//this.props.state.selected === section.title ? 8 : 6
            //     //     // }}
            //     //     stroke={1.5} size="10px" style={{ marginTop: 'auto', marginBottom: 'auto' }}
            //     //     color={'#fff'}
            //     //     // fontSize={level > 0 ? 'medium' : 'medium'}
            //     // />
            // ) : (
            //     <RadioButtonUncheckedIcon
            //         // style={{
            //         //     width: 8,//this.props.state.selected === section.title ? 8 : 6,
            //         //     height: 8,//this.props.state.selected === section.title ? 8 : 6
            //         // }}
            //         stroke={1.5} size="10px" style={{ marginTop: 'auto', marginBottom: 'auto' }}
            //         color={'#fff'}
            //         // fontSize={level > 0 ? 'medium' : 'medium'}
            //     />
            // );

            let itemIcon = ico 
                ? <FiberManualRecordIcon
                        style={{
                            width: 8,//this.props.state.selected === section.title ? 8 : 6,
                            height: 8,//this.props.state.selected === section.title ? 8 : 6
                            padding: 0,
                            margin: 0,
                            color: 'grey'
                        }}
                        // stroke={1.5} size="5px" style={{ marginTop: 'auto', marginBottom: 'auto' }}
                        color={'red'}
                        // fontSize={level > 0 ? 'medium' : 'medium'}
                    />
                : this.getIcon(item.name, level)


            let itemTarget = '_self';
            if (item.target) {
                itemTarget = '_blank';
            }

            let listItemProps = {
                component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
            };
            if (item?.external) {
                listItemProps = { component: 'a', href: item.url, target: itemTarget };
            }

            return (
                <ListItem
                    // {...listItemProps}
                    // disabled={item.disabled}
                    style={{
                        borderRadius: `0px`,
                        marginBottom: 0.5,
                        // alignItems: 'flex-start',
                        //backgroundColor: 'transparent',
                        color: '#fff',
                        //paddingTop: level > 1 ? 1 : 1.25,
                        paddingLeft: `${(level - 1) * 25}px`,
                        "&:hover": {
                            color: "#000",
                            backgroundColor: '#FFE516 !important'
                        },
                        overflow: 'auto',
                        width: '310px',
                        height: '50px',
                        overflow: 'hidden'
                    }}
                    selected={true}
                    // onClick={() => actions.setInput('component',item)}
                    
                    onClick={(event, value) => {
                        event.stopPropagation()
                        event.preventDefault();
                        if(item.route) {
                            switch (item.route) {
                                case '/tasks':
                                    this.props.actions.setOqlisDashboard(null)
                                    this.props.actions.setOqlisReport(null)
                                    this.props.actions.route('/tasks')
                                    break;
                                case '/releasenote':
                                    this.props.actions.clearReleaseNote()
                                    this.props.actions.route(item.route)
                                    break;
                                case '/create_report':
                                    window.open(config.oq.address)
                                    this.props.actions.route(item.route)
                                    break;
                                case '/knowledge_base':
                                    this.props.actions.checkDocument360()
                                    break;
                                default:
                                    this.props.actions.route(item.route)
                                    break;
                            }
                        }
                        else
                        {
                            this.props.actions.setOqlisDashboard(null)
                            this.props.actions.toggleMenus(false, null)
                            this.props.actions.route("/search/" + item.name);
                        }
                    }}
                    >
                        <ListItemButton
                        onClick={() => {
                            //console.log('selectedItemMain', item)
                        this.props.actions.setInputValue('selectedItemMain', item.name)
                        this.props.actions.setInputValue('selectedItemMainTitle', item.title)
                        this.props.actions.setInputValue('selectedItemMainDesc', item?.description ? item.description : `${item.title} List`)
                        }}
                        selected={this.props.state.selectedItemMain === item.name}
                        sx={config.system.clientTheme === 'bidvest' ? styles.hoverColorBidvest : styles.hoverColor}>
                    {/* <ListItemIcon style={{ my: 'auto', minWidth: !item?.icon ? 18 : 36,
                        fontSize: '14px !important',
                        color: "#fff",
                        "&:hover": {
                            color: "#fff",
                        },}}>
                            {itemIcon}
                    </ListItemIcon> */}
                    <ListItemIcon style={{ my: 'auto', minWidth: !item?.icon ? 18 : 36,
                        fontSize: '18px !important',
                        color: "#fff",
                        "&:hover": {
                            color: "#fff",
                        },}}>
                            {itemIcon}
                    </ListItemIcon>
                    <ListItemText
                        // primary={
                        //     <Typography variant='caption' style={{ 
                        //         fontSize: '14px !important',
                        //         color: "#fff",
                        //         "&:hover": {
                        //           color: "#fff",
                        //         }}}>
                        //         {item.title + "*"}
                        //     </Typography>
                        // }
                        
                        primary={
                            <div style={{ fontWeight: '100', fontSize: '15px', marginTop: 'auto', marginBottom: 'auto', width: '100% !important'  }}>
                                {window.t.en(item.title)}
                            </div>
                        }
                        secondary={
                            item.caption && (
                                <Typography variant="caption" style={{ fontSize: '15px !important' }} display="block" gutterBottom>
                                    {window.t.en(item.caption)}
                                </Typography>
                            )
                        }
                    />
                    {item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                    </ListItemButton>
                </ListItem>
            );
        }

    getList(category, level) {
        const items = category.sections.map((section) => {
            return section.section.title
                ? this.getCollapse(section.section, level)
                : section.section.components.map(x => {
                    return <div style={{paddingTop: '0px'}}>{this.getNavItem(x, level + 1)}</div> 
                })
        });
        

        return <div>
                <List
                     onClick={(event) => {
                        event.stopPropagation()
                        this.props.actions.setInputValue('collapseMain', this.props.state.collapseMain === category.title ? "" : category.title)
                    }}
                    style={{
                        backgroundColor: 'transparent',
                        "&:hover": {
                            color: "#000",
                            backgroundColor: '#FFE516 !important'
                            //backgroundColor: 'transparent'
                        },
                        padding: '2px !important',
                    }}
                    subheader={
                        category.title && (
                            <Typography variant="" style={{
                                //...theme.typography.menuCaption,
                                
                                fontSize: '0.975rem !important',
                                fontWeight: 400,
                                // color: theme.heading,
                                padding: '5px',
                                textTransform: 'capitalize',
                                marginTop: '5px',
                                color: '#fff',
                                // fontWeight: 600
                            }} display="block" className="icon-color" noWrap={true} gutterBottom>
                                {_icons(category.name)}
                                {window.t.en(category.title)}
                                {category.description && (
                                    <Typography
                                        variant="caption"
                                        style={{
                                           // ...theme.typography.subMenuCaption,
                                            fontSize: '0.7875rem !important',
                                            // fontWeight: 500,
                                            // color: theme.darkTextSecondary,
                                            textTransform: 'capitalize',
                                            color: "#fff",
                                            fontWeight: 300,
                                            letterSpacing: "0.04333rem",
                                            marginLeft: 35

                                        }}
                                        display="block"
                                        gutterBottom
                                    >
                                        {window.t.en(category.description)}
                                    </Typography>
                                )}
                            </Typography>
                        )
                    }
                >
                    <Collapse in={this.props.state.collapseMain === category.title} timeout={'auto'} unmountOnExit>
                    {items}
                    </Collapse>
                </List>
            </div>
    }

    getStatic() {
        let data = this.props.state.staticMenu
        let category = {
            title: 'Main',
            description: 'Static menu'
        }
        const items = data.filter(x => x.visible).sort((x, y) => x.order - y.order).map((x) => {
            
            return this.getNavItem(x, 2)
        });

        return this.props.state.staticMenu.length && <div style={{border: '1px solid transparent', padding: 0, margin: 0, paddingLeft: '20px', cursor: 'pointer'}}>
                <List  component="div" aria-labelledby="nested-list-subheader"

                    onClick={(event) => {
                        event.stopPropagation()
                        this.props.actions.setInputValue('collapseMainStatic', this.props.state.collapseMainStatic === category.title ? "" : category.title)
                    }}
                    style={{
                        backgroundColor: 'transparent',
                        "&:hover": {
                            color: "#000",
                            backgroundColor: '#FFE516 !important'
                        },
                        padding: '2px !important'
                    }}
                    subheader={
                        category.title && (
                            
                            <Typography variant="" style={{
                                //...theme.typography.menuCaption,
                                
                                fontSize: '0.975rem !important',
                                fontWeight: 400,
                                // color: theme.heading,
                                padding: '5px',
                                textTransform: 'capitalize',
                                marginTop: '5px',
                                color: '#fff',
                                // fontWeight: 600
                            }} display="block" className="icon-color" gutterBottom>
                            
                                        <HomeIcon  style={{marginRight: 10, width: 24, height: 24}}/>
                                {window.t.en(category.title)}
                                {category.description && (
                                    
                                    <Typography
                                        variant="caption"
                                        style={{
                                           // ...theme.typography.subMenuCaption,
                                            fontSize: '0.7875rem !important',
                                            // fontWeight: 500,
                                            // color: theme.darkTextSecondary,
                                            textTransform: 'capitalize',
                                            color: "#fff",
                                            fontWeight: 300,
                                            letterSpacing: "0.04333rem",
                                            marginLeft: 35
                                        }}
                                        display="block"
                                        gutterBottom
                                    >
                                        {window.t.en(category.description)}
                                    </Typography>
                                )}
                            </Typography>
                        )
                    }
                >
                <Collapse in={this.props.state.collapseMainStatic === category.title} timeout={'auto'} unmountOnExit>

                    {items}
                </Collapse>

                </List>
            </div>
    }

    getMenus() {
        return <div style={{paddingLeft: '20px', cursor: 'pointer' }}>
            {
                this.props.state.data.map(category => 
                    {   
                        return this.getList(category,1)
                    })
            }
        </div>
    }


    getLayout() {
        return [this.getStatic(), this.getMenus()]
    }

    render() {
        return this.getLayout();
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.navigationMenu,
            ...state.redirect,
            ...state.readComponent,
            ...state.jsReport,
            accounts: {
                ...state.accounts,
            },
            user: {
                ...state.user
            }
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            {
                ...actions,
                ...redirectActions,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);
