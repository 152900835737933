import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'
import AutoComplete from '../../../controls/autocomplete'
import Card from '../../../controls/card'
import Section from '../../../controls/section'

//import FloatingActionButton from '@mui/material/FloatingActionButton';
//import ContentAdd from 'material-u/svg-icons/content/add';

//import ContentAdd from '@mui/icons-material/Add';

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Upload from '../../Upload/component'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

class CaptureQuotation extends Component {

    componentDidMount() {
        this.props.actions.resetForm()
        this.props.actions.setValidationState('captureQuotation')
        this.props.actions.setInputValue({ prop: 'startdate', value: moment().format('YYYY-MM-DD') })
        this.props.actions.setInputValue({ prop: 'rfqdate', value: moment().format('YYYY-MM-DD') })
        this.props.actions.setInputValue({ prop: 'rfqtime', value: moment().format('HH:mm') })
    }

    renderAccessoryList() {
        if (!this.props.state?.accessory_list.length) return null;

        return (
            <div>
                <Row style={styles.tableheader}>
                    <Col xl={4}>Accessory Name</Col>
                    <Col xl={4}>Total Cost</Col>
                    <Col xl={3}>Rate Per Month</Col>
                    <Col xl={1}></Col>
                </Row>
                {this.props.state?.accessory_list.map((a, i) => (
                    <Row key={`accessory-row-${i}-${this.props.state.gridCounter}`}>
                        <Col xl={4} style={{ paddingTop: 15, verticalAlign: 'Middle' }}>
                            <AutoComplete
                                listStyle={{ maxHeight: 400 }}
                                key={`accessory_${i}_accessoryname`}
                                id={`dll_accessory_${i}_accessoryname`}
                                name={`dll_accessory_${i}_accessoryname`}
                                title={'Select Accessory'}
                                info={{ show: false }}
                                fullWidth={true}
                                dataSource={this.props.state.straccessory_data}
                                value={this.props.state.accessory_list[i].accessory_name}
                                dataSourceConfig={{ text: 'text', value: 'value' }}
                                fieldtext={'text'}
                                fieldvalue={'value'}
                                onSelect={(args) => {
                                    const straccessory = this.props.state.straccessory_data.find(x => x.straccessory_id === args.value);
                                    this.props.actions.setAccesoryList({ value: straccessory, text: args.text, inx: i });
                                    this.props.actions.setInputListValue(args.text);
                                }}
                                onClick={() => this.props.actions.getLookupValueData('straccessory', 'accessoryname', '')}
                                onFilter={(search) => {
                                    if (!search) {
                                        this.props.actions.getLookupValueData('straccessory', 'accessoryname', '');
                                        this.props.actions.setAccesoryList({ value: {}, text: '', inx: i });
                                        this.props.actions.setInputListValue('');
                                    } else {
                                        this.props.actions.getLookupValueData('straccessory', 'accessoryname', search);
                                        const straccessory = this.props.state.straccessory_data.find(x => x.accessoryname === search);
                                        this.props.actions.setAccesoryList({ value: straccessory, text: search, inx: i });
                                        this.props.actions.setInputListValue(search);
                                    }
                                }}
                            />
                            <br />
                        </Col>
                        <Col xl={4} style={{ paddingTop: '15px' }}>
                            <TextField
                                id={'txt_accessories_total' + i}
                                key={'txt_accessories_total' + i}
                                hintText={'Enter Rate Per Day'}
                                variant="outlined"
                                disabled={true}
                                fullWidth={true}
                                label={'Enter Rate per Day'}
                                value={this.props.state.accessory_list[i].totalprice}
                            />
                        </Col>
                        <Col xl={3} style={{ paddingTop: '15px' }}>
                            <TextField
                                id={'txt_accessories_day' + i}
                                key={'txt_accessories_day' + i}
                                hintText={'Enter Rate Per Day'}
                                variant="outlined"
                                fullWidth={true}
                                label={'Enter Rate per Day'}
                                disabled={true}
                                value={this.props.state.accessory_list[i].ratepermonth}
                            />
                        </Col>
                        <Col xl={1} style={{ paddingTop: 15, verticalAlign: 'Middle' }}>
                            <Icon
                                iname="delete_forever"
                                iclass="material-icons"
                                icolor="red"
                                istyle={{
                                    fontSize: '20px',
                                    color: 'red'
                                }}
                                onClick={() => this.props.actions.removeSelectedAccessory(a, i)}
                            />
                        </Col>
                    </Row>
                ))}
            </div>
        );
    }


    getSTRForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={8}>
                    <Container style={styles.nomargin}>
                        <Row>
                            <Col xl={6} >
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_customer'}
                                    key={'dll_customer'}
                                    hintText={'Select the Debtor/Client Account'}
                                    label={'Debtor/Client Account'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    // dataSourceConfig={{
                                    //     text: 'text',
                                    //     value: 'value'
                                    // }}
                                    fieldtext={'registeredname'}
                                    fieldvalue={'customer_id'}
                                    displayfields={['registeredname', 'hierarchyname']}
                                    displaywidth={['20', '80']}
                                    value={this.props.state.customer}
                                    dataSource={this.props.state.customer_data}
                                    //onCustomerIcon={() => this.props.actions.setInputValue({prop: 'add_customer_win', value: true})}
                                    onSelect={(args) => {
                                        //console.log('args customer',args)
                                        console.log('onSelect', args)
                                        this.props.actions.setInputValue({ prop: 'customer_id', value: args.value })
                                        this.props.actions.setInputValue({ prop: 'customer', value: args.text })
                                        //console.log('customerdata',this.props.state.customer_data)
                                        //console.log('customerdata',args)
                                        let str_customer_selected = this.props.state.customer_data.filter(x => x.customer_id == args.value)
                                        str_customer_selected = str_customer_selected.length ? str_customer_selected : {}
                                        //console.log('selected_customer',str_customer_selected)
                                        this.props.actions.setInputValue({ prop: 'str_customer_selected', value: str_customer_selected })
                                    }}
                                    onClick={() => {
                                        console.log('onClick')
                                        this.props.actions.getLookupValueData('customer', 'customername', '')
                                    }}
                                    onFilter={(search) => {
                                        console.log('onFilter', search)
                                        if (search && search.length > 2) {
                                            this.props.actions.getLookupValueData('customer', 'customername', search)
                                            this.props.actions.setInputValue({ prop: 'customer', value: search })
                                        } else {
                                            this.props.actions.setInputValue({ prop: 'customer_id', value: null })
                                            this.props.actions.setInputValue({ prop: 'customer', value: '' })
                                            this.props.actions.setInputValue({ prop: 'str_customer_selected', value: [] })
                                        }
                                    }}
                                />

                            </Col>
                            <Col xl={6} style={{ padding: '10px' }}>
                                <Checkbox
                                    checked={this.props.state.additional}
                                    label={'Additional Vehicle'}
                                    onChange={(event, checked) => {
                                        this.props.actions.setInputValue({ prop: 'additional', value: true })
                                    }}
                                />
                                Additional Vehicle
                                <Checkbox
                                    checked={!this.props.state.additional}
                                    label={'Replace Vehicle'}
                                    onChange={(event, checked) => {
                                        this.props.actions.setInputValue({ prop: 'additional', value: false })
                                    }}
                                />
                                Replace Vehicle
                            </Col>
                            {
                                !this.props.state.additional &&
                                <Col xl={6}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400 }}
                                        id={'dll_category'}
                                        key={'dll_category'}
                                        hintText={'Select Replacement Vehicle Registration:'}
                                        label={'Replacement Vehicle Registration'}
                                        filter={AutoComplete.fuzzyFilter}
                                        fullWidth={true}
                                        fieldvalue={'vehicle_id'}
                                        displayfields={['licenseplateno', 'makemodelyear']}
                                        displaywidth={['20', '80']}
                                        value={this.props.state.vehicle}
                                        dataSource={this.props.state.vehicle_data}
                                        onSelect={(args) => {
                                            //console.log('args vehicle',args)
                                            this.props.actions.setInputValue({ prop: 'vehicle_id', value: args.value })
                                            this.props.actions.setInputValue({ prop: 'vehicle', value: args.text })
                                        }}
                                        onClick={() => {
                                            this.props.actions.getLookupValueData('vehicle', 'licenseplateno', '')
                                        }}
                                        onFilter={(search) => {
                                            this.props.actions.getLookupValueData('vehicle', 'licenseplateno', search)
                                            this.props.actions.setInputValue({ prop: 'vehicle', value: null })
                                        }}
                                    />
                                </Col>
                            }
                            <Col xl={6} style={{ padding: '15px' }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    label={'Contract Period'}
                                    id={'txt_period'}
                                    hintText={'Enter Contract Period'}
                                    errorText={this.props.state.period}
                                    value={this.props.state.period}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'period', value: event.target.value })
                                        this.props.actions.setInputValue({ prop: 'enddate', value: moment(this.props.state.startdate).add(event.target.value, 'months').format('YYYY-MM-DD') })
                                    }}
                                />
                            </Col>
                            <Col xl={6} style={{ padding: '15px' }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    label={'Contract Distance'}
                                    id={'txt_distance'}
                                    hintText={'Enter Contract Distance'}
                                    errorText={this.props.state.distance}
                                    value={this.props.state.distance}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'distance', value: event.target.value })
                                    }}
                                />
                            </Col>
                            <Col xl={6} style={{ paddingTop: '5px' }}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_category'}
                                    key={'dll_category'}
                                    hintText={'Select Rate Card'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    label={'Rate card based on vehicle class'}
                                    // dataSourceConfig={{
                                    //     text: 'text',
                                    //     value: 'value'
                                    // }}
                                    fieldvalue={'strratecard_id'}
                                    displayfields={['strratecard', 'vehicle_class']}
                                    displaywidth={['20', '80']}
                                    value={this.props.state.strratecard}
                                    dataSource={this.props.state.strratecard_data}
                                    onSelect={(args) => {
                                        //console.log('onselect args',args)
                                        let ratecards = this.props.state.strratecards.filter(x => x.strratecard === args.text)
                                        //console.log('onselect list',ratecards)

                                        let ratecard = ratecards.length ? ratecards[0] : null
                                        //console.log('onselect ratecard',ratecard)

                                        if (ratecard) {
                                            this.props.actions.setInputValue({ prop: 'strratecard_id', value: ratecard.strratecard_id })
                                            this.props.actions.setInputValue({ prop: 'strratecard', value: ratecard.strratecard })
                                            this.props.actions.calc_rental(ratecards)
                                            this.props.actions.calc_totals()
                                        }
                                    }}
                                    onClick={() => {
                                        this.props.actions.getRateCardVehicles('strratecard', 'strratecard', '?strratecard=')
                                    }}
                                    onFilter={(search) => {
                                        this.props.actions.getRateCardVehicles('strratecard', 'strratecard', '?strratecard=' + search)
                                        if (!search) {
                                            this.props.actions.setInputValue({ prop: 'strratecard', value: null })
                                            this.props.actions.setInputValue({ prop: 'strratecard_id', value: null })
                                        }
                                    }}
                                />
                            </Col>
                            <Col xl={6} style={{ padding: '15px' }}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_transmission'}
                                    key={'dll_transmission'}
                                    hintText={'Select Transmission Type'}
                                    label={'Transmission Type'}
                                    fullWidth={true}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus
                                    //clearOnBlur
                                    value={this.props.state.transmission}
                                    dataSource={this.props.state.transmission_data}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'transmission_id', value: args.value })
                                        this.props.actions.setInputValue({ prop: 'transmission', value: args.text })
                                    }}
                                    onClick={() => {
                                        this.props.actions.getLookupValueData('transmission', 'transmission', '')
                                    }}
                                    onFilter={(search) => {
                                        if (!search) {
                                            this.props.actions.setInputValue({ prop: 'transmission_id', value: null })
                                            this.props.actions.setInputValue({ prop: 'transmission', value: '' })
                                        } else {
                                            this.props.actions.getLookupValueData('transmission', 'transmission', search)
                                            this.props.actions.setInputValue({ prop: 'transmission', value: search })
                                        }
                                    }}
                                />
                            </Col>
                            <Col xl={6} style={{ padding: '15px' }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    label={'Excess'}
                                    id={'txt_excess'}
                                    hintText={'Enter Excess'}
                                    errorText={this.props.state.excess}
                                    value={this.props.state.excess}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'excess', value: event.target.value })
                                    }}
                                />
                            </Col>
                            <Col xl={6}>
                                <DateControl
                                    id={'start_date'}
                                    key={'start_date'}
                                    name={'start_date'}
                                    label={window.t.en('Estimated Contract Start Date')}
                                    field={'start_date'}
                                    fullWidth={true}
                                    value={this.props.state.startdate}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'startdate', value: args })
                                        this.props.actions.setInputValue({ prop: 'enddate', value: moment(args).add(this.props.state.period, 'months').format('YYYY-MM-DD') })
                                    }}
                                    type={'date'}
                                />
                            </Col>
                            <Col xl={6}>
                                <DateControl
                                    id={'end_date'}
                                    key={'end_date'}
                                    name={'end_date'}
                                    label={window.t.en('Estimated Contract End Date')}
                                    field={'end_date'}
                                    fullWidth={true}
                                    styles={{ padding: 0, maring: 0, float: 'right' }}
                                    value={this.props.state.enddate}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'enddate', value: args })
                                    }}
                                    type={'date'}
                                />
                            </Col>
                            <Col xl={6} style={{ padding: '15px' }}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_contractunit'}
                                    key={'dll_contractunit'}
                                    label={'Contract Unit'}
                                    hintText={'Select Contract Unit'}
                                    filter={AutoComplete.fuzzyFilter}
                                    fullWidth={true}
                                    openOnFocus
                                    //clearOnBlur
                                    value={this.props.state.contractunit}
                                    dataSource={this.props.state.contractunit_data}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'contractunit_id', value: args.value })
                                        this.props.actions.setInputValue({ prop: 'contractunit', value: args.text })
                                    }}
                                    onClick={() => {
                                        this.props.actions.getLookupValueData('contractunit', 'contractunit', '')
                                    }}
                                    onFilter={(search) => {
                                        if (!search) {
                                            this.props.actions.setInputValue({ prop: 'contractunit_id', value: null })
                                            this.props.actions.setInputValue({ prop: 'contractunit', value: '' })
                                        } else {
                                            this.props.actions.getLookupValueData('contractunit', 'contractunit', search)
                                            this.props.actions.setInputValue({ prop: 'contractunit', value: search })
                                        }
                                    }}
                                />
                            </Col>
                            <Col xl={6} style={{ padding: '20px' }}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_delivery'}
                                    key={'dll_delivery'}
                                    hintText={'Select Delivery Address'}
                                    label={'Delivery Address'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    //value={this.props.state.delivery}
                                    dataSource={this.props.state.delivery_data ? this.props.state.delivery_data : []}
                                    onSelect={
                                        (chosenRequest, index) => {
                                            console.log('chosenRequestx delivery ', chosenRequest)
                                            this.props.actions.setInputValue({ prop: 'delivery_address', value: chosenRequest })
                                            this.props.actions.getDistance({ prop: 'delivery', value: chosenRequest.value, client_id: this.props.state.accounts.selectedAccount })
                                            this.props.actions.calc_totals()
                                            //this.props.actions.setComponentProp('vehicle', { value: args.value, text: args.text }, true)
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            //this.props.actions.getRateCardVehicles(this.props.state.ratecategory.text)
                                        }
                                    }
                                    onUpdateInput={
                                        (search, dataSource, params) => {
                                            //this.props.actions.setInputValue({prop: 'delivery', value: [{text: search, value: 1}]})
                                            //console.log('delivery',search)

                                            if (search && search.length > 2)
                                                this.props.actions.addressSearch('delivery_data', search)
                                        }}
                                />
                                {
                                    this.props.state.delivery_kms && <div xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Delivery Kms:'}</div>
                                            <div style={styles.fieldContent}>
                                                {this.props.state.delivery_kms} Kms
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Col>
                            <Col xl={6} style={{ padding: '20px' }}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_collection'}
                                    key={'dll_collection'}
                                    hintText={'Select Collection Address'}
                                    label={'Collection Address'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    //value={this.props.state.collection}
                                    dataSource={this.props.state.collection_data ? this.props.state.collection_data : []}
                                    onNewRequest={
                                        (chosenRequest, index) => {
                                            console.log('chosenRequestx chosenRequest ', chosenRequest)
                                            console.log('chosenRequestx collection_data ', this.props.state.collection_data)
                                            this.props.actions.setInputValue({ prop: 'collection_address', value: chosenRequest })
                                            //this.props.actions.setInputValue({ prop: 'collection_data', value: { ...chosenRequest, text: chosenRequest.text, value: chosenRequest.value } })
                                            this.props.actions.getDistance({ prop: 'collection', value: chosenRequest.value, client_id: this.props.state.accounts.selectedAccount })
                                            this.props.actions.calc_totals()
                                            //this.props.actions.setComponentProp('vehicle', { value: args.value, text: args.text }, true)
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            //this.props.actions.getRateCardVehicles(this.props.state.ratecategory.text)
                                        }
                                    }
                                    onUpdateInput={
                                        (search, dataSource, params) => {
                                            //this.props.actions.setInputValue({prop: 'delivery', value: [{text: search, value: 1}]})
                                            //console.log('collection_data',search)
                                            //new AddressAutocomplete('.billing-address', result => //console.log(result));
                                            if (search && search.length > 2)
                                                this.props.actions.addressSearch('collection_data', search)
                                        }
                                    }
                                />
                                {
                                    this.props.state.collection_kms && <div xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Collection Kms:'}</div>
                                            <div style={styles.fieldContent}>
                                                {this.props.state.collection_kms} Kms
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Col>
                            {/* <Col xl={12}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Insurance Included:'}</div>
                                        <div style={styles.fieldContent}>
                                            <Checkbox
                                                checked={this.props.state.insurance}
                                                label={'Yes' }
                                                onChange={(event, checked) => {
                                                    this.props.actions.setInputValue({prop: 'insurance', value: true})
                                                }}
                                            />
                                            Yes
                                            <Checkbox
                                                checked={!this.props.state.insurance}
                                                label={'No' }
                                                onChange={(event, checked) => {
                                                    this.props.actions.setInputValue({prop: 'insurance', value: false})
                                                }}
                                            />
                                            No
                                        </div>
                                    </div>
                                </Col> */}
                            {
                                this.props.state.insurance && <Col xl={6} style={{ padding: '20px' }}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400 }}
                                        id={'dll_waivers'}
                                        key={'dll_waivers'}
                                        hintText={'Select Waivers Options'}
                                        label={'Waivers'}
                                        filter={AutoComplete.fuzzyFilter}
                                        fullWidth={true}
                                        openOnFocus
                                        //clearOnBlur
                                        value={this.props.state.waiver}
                                        dataSource={this.props.state.waiver_data}
                                        onSelect={(args) => {
                                            this.props.actions.setInputValue({ prop: 'waiver_id', value: args.value })
                                            this.props.actions.setInputValue({ prop: 'waiver', value: args.text })
                                            this.props.actions.setInputValue({ prop: 'waiver_code', value: this.props.state.waiver_data.filter(x => x.waiver_id === args.value)[0].waiver_code })
                                            this.props.actions.calc_totals()
                                        }}
                                        onClick={() => {
                                            this.props.actions.getLookupValueData('waiver', 'waiver', '')
                                        }}
                                        onFilter={(search) => {
                                            if (!search) {
                                                this.props.actions.setInputValue({ prop: 'waiver_id', value: null })
                                                this.props.actions.setInputValue({ prop: 'waiver', value: '' })
                                                this.props.actions.setInputValue({ prop: 'waiver_code', value: '' })
                                            } else {
                                                this.props.actions.getLookupValueData('waiver', 'waiver', search)
                                                this.props.actions.setInputValue({ prop: 'waiver', value: search })
                                            }
                                        }}
                                    />
                                </Col>
                            }
                            <Col xl={6} style={{ paddingTop: '10px' }} >
                                <DateControl
                                    id={'rfqdate'}
                                    key={'rfqdate'}
                                    name={'rfqdate'}
                                    label={window.t.en('RFQ Date')}
                                    field={'rfqdate'}
                                    fullWidth={true}
                                    value={this.props.state.rfqdate}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({ prop: 'rfqdate', value: args })
                                    }}
                                    type={'date'}
                                />
                            </Col>

                            <Col xl={6} style={{ paddingTop: '10px' }}>
                                <TextField
                                    variant="outlined"
                                    type="time"
                                    id={'rfqtime'}
                                    label={'RFQ Time'}
                                    value={this.props.state.rfqtime}
                                    style={{ padding: 0, maring: 0, float: 'right', marginLeft: '15px', width: '150px' }}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'rfqtime', value: event.target.value })
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <br />
                </Col>
                <Col style={styles.subheaders} xl={4}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <h4>{'Summary Costs'}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainerRow}>
                                    <div style={styles.fieldTitleLeft}>{'Rental (M):'}</div>
                                    <div style={styles.fieldContentTot}>
                                        {this.props.state.str_monthly_rental}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainerRow}>
                                    <div style={styles.fieldTitleLeft}>{'Rental (D):'}</div>
                                    <div style={styles.fieldContentTot}>
                                        {this.props.state.str_daily_rental}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainerRow}>
                                    <div style={styles.fieldTitleLeft}>{'Accessories (M):'}</div>
                                    <div style={styles.fieldContentTot}>
                                        {this.props.state.accessory_tot_monthly}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainerRow}>
                                    <div style={styles.fieldTitleLeft}>{'Waiver:'}</div>
                                    <div style={styles.fieldContentTot}>
                                        {this.props.state.waiver_tot}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainerRow}>
                                    <div style={styles.fieldTitleLeft}>{'Delivery Cost:'}</div>
                                    <div style={styles.fieldContentTot}>
                                        {this.props.state.delivery_cost}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainerRow}>
                                    <div style={styles.fieldTitleLeft}>{'Collection Cost:'}</div>
                                    <div style={styles.fieldContentTot}>
                                        {this.props.state.collection_cost}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                                <h4>{'Summary Totals'}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainerRow}>
                                    <div style={styles.fieldTitleLeft}>{'Subtotal:'}</div>
                                    <div style={styles.fieldContentTot}>
                                        {this.props.state.monthly_excl}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainerRow}>
                                    <div style={styles.fieldTitleLeft}>{'Total VAT:'}</div>
                                    <div style={styles.fieldContentTot}>
                                        {this.props.state.monthly_vat}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainerRow}>
                                    <div style={styles.fieldTitleLeft}>{'Monthly Total:'}</div>
                                    <div style={styles.fieldContentTot}>
                                        {this.props.state.monthly_incl}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
            </Row>
            <Section
                title={'Contract Accessories'}
                subtitle={'Add Accessories'}>
            </Section>
            <Row>
                <Col key={'col_'} xl={12}>

                    <br />
                    {
                        <Button className={"global_button"} variant="contained"
                            label="Add Item"
                            labelPosition="before"
                            primary={!this.props.state.sun}
                            style={{ marginLeft: 20, fontSize: 16 }}
                            icon={<Icon iclass={'material-icons'} iname={'add'} />}
                            onClick={() => {
                                this.props.actions.addNewItem()
                            }}
                        >
                            {window.t.en('Add Item')}
                        </Button>
                    }
                    <br />
                    <br />
                </Col>
            </Row>
            <Section
                title={'Quotation Notes'}
                subtitle={'Add Notes'}>
            </Section>
            <Row>
                <Col key={'col_'} xl={12}>

                    <br />
                    {
                        <Col xl={12} style={{ padding: '15px' }}>
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                label={'Enter Quotation Notes'}
                                id={'txt_notes'}
                                hintText={'Enter Quotation Notes'}
                                errorText={this.props.state.quotationnotes}
                                value={this.props.state.quotationnotes}
                                multiline={true}
                                rows={5}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue({ prop: 'quotationnotes', value: event.target.value })
                                }}
                            />
                        </Col>
                    }
                    <br />
                    <br />
                </Col>
            </Row>
            <Row>
                <Col key={'col_'} xl={12}></Col>
            </Row>
            {
                this.renderAccessoryList()
            }
            <Row>
                <Col
                    key={'col_'} xl={12}>
                    <Section
                        title={'Documents'}>
                    </Section>

                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                    <Upload
                        id={'upload_str'}
                        prefix={'STR_Capture_'}
                        header={false}
                        list={[{ value: 0, text: 'Customer RFQ' }, { value: 0, text: 'Customer PO' }, { value: 0, text: 'Supplier Quotation' }]}
                    />
                </Col>
            </Row>
        </Container>

    }

    getAddwin() {

        return <Dialog
            open={this.props.state.add_customer_win}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setInputValue({ prop: 'add_customer_win', value: false })
            }}
        >
            <div className="text-center- p-5">
                <h4 className="font-weight-bold mt-4-">
                    Customer
                </h4>
                <strong className="mb-0 font-size-lg text-muted bold">
                    Complete the form below
                </strong>
                <p className="mb-0 font-size-lg text-muted-">
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}

                                    id={'txt_period'}
                                    label={'Company Registered Name'}
                                    hintText={'Company Registered Name'}
                                    value={this.props.state.company_name}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'company_name', value: event.target.value })
                                    }}
                                />
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}

                                    id={'txt_period'}
                                    label={'Trading Name (If Applicable)'}
                                    value={this.props.state.company_trading_name}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'company_trading_name', value: event.target.value })
                                    }}
                                />
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}

                                    id={'txt_period'}
                                    label={'Business Unit (If Applicable)'}
                                    value={this.props.state.company_business_unit}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'company_business_unit', value: event.target.value })
                                    }}
                                />
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}

                                    id={'txt_period'}
                                    label={'Company Registration Number'}
                                    value={this.props.state.company_registration_no}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'company_registration_no', value: event.target.value })
                                    }}
                                />
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}

                                    id={'txt_period'}
                                    label={'Company Physical Address'}
                                    value={this.props.state.company_physical_address}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'company_physical_address', value: event.target.value })
                                    }}
                                />
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}

                                    id={'txt_period'}
                                    label={'Contact Person (Name & Surname)'}
                                    value={this.props.state.company_contact_person}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'company_contact_person', value: event.target.value })
                                    }}
                                />
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}

                                    id={'txt_period'}
                                    label={'Contact Person Designation'}
                                    value={this.props.state.company_contact_person_designation}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'company_contact_person_designation', value: event.target.value })
                                    }}
                                />
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}

                                    id={'txt_period'}
                                    label={'Contact Person Email Address'}
                                    value={this.props.state.company_contact_person_email_address}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({ prop: 'company_contact_person_email_address', value: event.target.value })
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>

                </p>
                <div className="pt-4">
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.setInputValue({ prop: 'add_customer_win', value: false })
                        }}
                        className=" btn-pill- mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.setInputValue({ prop: 'saving_supplier', value: true })
                            this.props.actions.saveSupplier()
                        }}
                        className="btn-pill- mx-1">
                        <span className="btn-wrapper--label">{this.props.state.saving_supplier ? 'Please Wait...' : 'Save'}</span>
                    </Button>
                </div>
            </div>
        </Dialog>
    }

    getLayout() {
        return <Card title={window.t.en('Adhoc Rental Quotation')}
            subtitle={window.t.en('Create adhoc rental customer quotation')}
            content={<div>
                {this.getSTRForm()}
                {this.getAddwin()}
            </div>
            } />
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.captureQuotation,
            user: {
                ...state.user
            },
            accounts: {
                ...state.accounts
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;

    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureQuotation)