import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'

import PropTypes from 'prop-types';
import moment from 'moment';
import * as validate from '../../helpers/validator'

import NumberFormat from 'react-number-format';
//import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import RelatedGrid from '../RelatedGrid/grid'

// import { Card, CardHeader, CardContent } from '@mui/material'

import Upload from '../Upload/component'
import Icon from '../Icon/component'
import Grid from '../Grid/component'

import Card from '../../controls/card'
import TextField from '../../controls/textField'
import AutoComplete from '../../controls/autocomplete'
import TreeSelect from '../../controls/treeselect'
import DateControl from '../../controls/dateControl'
import CheckBoxControl from '../../controls/checkbox'



import Section from '../../controls/section'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

import AdhocComponentFieldInput from '../Custom/AdhocComponentFieldInput/component'

import * as actions from './actions'
import * as controlActions from '../../controls/actions'
import * as styles from './styles'


import "./tree_select.scss";
// import "./style.css";



function NumberFormatCustom(props) {
    const { inputRef, onChange, type, ...other } = props;
    
    switch (type) {
        case 'phone':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                isNumericString
                format="+27 (###) ###-####" 
                mask="_"
            />
        case 'amount':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                fixedDecimalScale
                isNumericString
            />
        case 'number':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                thousandSeparator={false}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                isNumericString
            />
        case 'month':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="##/##" placeholder="MM/YY" 
                mask={['M', 'M', 'Y', 'Y']}
                isNumericString
            />
        case 'card':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="#### #### #### ####" 
                mask="_"
                isNumericString
            />
        case 'idnumber':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="###### #### ###" 
                mask="_"
                isNumericString
            />
        case 'code':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="####"
                mask="_"
                isNumericString
            />
    } 
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };


class FormComponent extends Component {
    componentDidMount() {
        this.props.actions.clearComponentData(this.props.name)
        //console.log('Form loadComponent', this.props.name, this.props.id)
        this.props.actions.loadComponent(this.props.name, this.props.id)

        if(this.component && this.component.data['ticketnumber']) {
            if(!this.component.data['ticketnumber'].input) {
            let ticketnumber = this.props.state.component.component_field.filter(x => x.name === 'ticketnumber')[0]
            this.props.actions.setComponentFieldInput(ticketnumber, moment().format('YYMMDDHHmmsss'))
            }
        }
    }

    getFormFieldValue(field, index) {
        
        if(!this.component.data[field.name])  return ''
        switch (field.component_field_display_type.name) {
            case 'treeSelect':
                //console.log('treeSelectxx', field)
                return <TreeSelect
                    name={field.name}
                    title={field.hint}
                    //data={[{[field.display.split('.')[0]]: ''}]}
                    data={this.props.state[field.datasource+"_data"] ? this.props.state[field.datasource+"_data"] : []}
                    value={this.component.data[field.name].text}
                    multiple={false}
                    
                    // data={this.props.state.hierachy}
                    // value={this.props.state.customername}
                    // fieldid={'customer_id'}
                    // fieldname={'customername'}
                    fieldid={field.display.split('.')[1]}
                    fieldname={field.display.split('.')[0]}
                    // onSelect={(args) => {
                    //     this.props.actions.setComponentFieldInputText(field, args[field.display.split('.')[1]], args[field.display.split('.')[0]])
                    // }}
                    onClick={() => {
                        this.props.actions.getTreeViewData(field.name, field.datasource, this.props.state.user.user_customer.map(x => x.customer_id))
                    }}
                    //onFilter={(args) => //console.log()} 
                    
                    onChange={
                        (args) => {
                        //console.log('nodeIdsx onChange', args)
                        this.props.actions.setComponentFieldInputText(field, args.value, args.text)
                        //this.props.actions.setMyTestInputValue('customername',args.text)
                        }
                    }
                /> 
            case 'autoComplete':
                return this.component.data[field.name] ? <AutoComplete
                name={field.name}
                inputRef={field.name+'Ref'}  
                //title={field.hint}
                title={window.t.en(field.isrequired ? field.hint + ' *' : field.hint)}
                // info={{
                //     show: true,
                //     component: field.component_reference ? field.component_reference.name : '',
                //     title: field.component_reference ? field.component_reference.title : '',
                //     field: field.name,
                //     value: this.component.data[field.name].input
                // }}
                //error={this.component.data[field.name].validation.message}
                error={
                    field.isrequired 
                    ? this.component.data[field.name].input 
                        ? field.validation
                            ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text, field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                            : '' 
                        : field.title + ' Is Required' 
                    :  this.component.data[field.name].input && field.validation
                        ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text , field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                        : ''
                }
                helperText={
                    field.isrequired 
                        ? this.component.data[field.name].input 
                            ? field.validation
                                ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text, field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                                : '' 
                            : field.title + ' Is Required' 
                        : field.validation
                            ? validate.schema(field.name, field.validation,this.component.data[field.name].input, field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                            : ''
                }
                component_reference={field.component_reference ? field.component_reference.name : ''}
                data={this.component.data[field.name].data}
                value={this.component.data[field.name].text}
                onSelect={(args) => {
                    //console.log('argsx',args)
                    //console.log('argsx field',this.component.data[field.name])
                    //console.log('argsx message',this.component.data[field.name].validation.message)
                     this.props.actions.setComponentFieldInput(field, args.value)
                }}
                onClick={() => {
                    //console.log('//console.log(searchx)', field.display ? field.display.split('.').length > 1 ? field.display.split('.')[1] : '' : '',)
                    this.props.actions.getComponentFieldLookupData(field, this.component.data[field.name].text ? this.component.data[field.name].text : '')
                }}
                onFilter={(args) => {
                    
                    //this.props.actions.setComponentFieldInput(field, '')
                    //if(args)
                    this.props.actions.getComponentFieldLookupData(field, args)
                }} /> : ''
                
                case 'datePicker':
                    return <div style={{margin: '-15px 0'}}>
                        <DateControl
                            inputRef={field.name+'Ref'}  
                            id={this.component.data[field.name].key}
                            key={this.component.data[field.name].key}
                            name={this.component.data[field.name].key}
                            label={field.isrequired ? field.hint + ' *' : field.hint}
                            ///error={this.component.data[field.name].validation.message}
                            field={field}
                            value={this.component.data[field.name].input ? moment(this.component.data[field.name].input).format('YYYY-MM-DD HH:mm') : ''}
                            onSelect={(args) => {
                                this.props.actions.setComponentFieldInput(field, args)
                            }}
                            error={
                                field.isrequired 
                                ? this.component.data[field.name].input 
                                    ? field.validation
                                        ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text, field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                                        : '' 
                                    : field.title + ' Is Required' 
                                :  this.component.data[field.name].input && field.validation
                                    ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text , field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                                    : ''
                            }
                            helperText={
                                field.isrequired 
                                ? this.component.data[field.name].input 
                                    ? field.validation
                                        ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text, field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                                        : '' 
                                    : field.title + ' Is Required' 
                                :  this.component.data[field.name].input && field.validation
                                    ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text , field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                                    : ''
                            }
                            type={'date'}
                        />
                    </div>
                case 'hidden':
                    return <input
                        key={'component_' + this.component.name + '_' + field.name + '_' + this.component.data[field.name]}
                        type='hidden'
                        value={this.component.data[field.name].input}
                    />
                case 'timePicker':
                    return <DateControl
                        id={this.component.data[field.name].key}
                        key={this.component.data[field.name].key}
                        name={this.component.data[field.name].key}
                        label={field.isrequired ? field.hint + ' *' : field.hint}
                        field={field}
                        value={this.component.data[field.name].input}
                        setComponentFieldInput={
                            this.props.actions.setComponentFieldInput
                        }
                        type={'time'}
                        onSelect={(args) => {
                            this.props.actions.setComponentFieldInput(field, args)
                        }}
                    />
                case 'textBox':
                    let htmlInputType;
                    let htmlInputPattern;
                    switch (field.component_field_data_type.name)
                    {
                        case 'integer':
                            htmlInputType = 'number'
                            htmlInputPattern = '^-?\d+$'
                            break;
                        case 'decimal':
                            htmlInputType = 'number'
                            htmlInputPattern = '^-?\d+(\.\d+)?$'
                            break;
                        case 'float':
                            htmlInputType = 'number'
                            htmlInputPattern = '-?\d+(\.\d+)?'
                            break;
                        case 'string':
                        case 'text':
                            htmlInputType = 'text'
                            htmlInputPattern = '.*'
                            break;
                        default:
                            htmlInputType = 'text'
                            htmlInputPattern = '.*'
                            break;
                    }
                    
                    return <TextField
                        inputProps={{ autoComplete: 'off' }}
                        autoComplete={'off'}
                        inputRef={field.name+'Ref'}  
                        id={this.component.data[field.name].key}
                        key={this.component.data[field.name].key}
                        label={window.t.en(field.isrequired ? field.hint + ' *' : field.hint)}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        pattern = {htmlInputPattern}
                        type={htmlInputType}
                        //error={field.validation || field.isrequired ? validate.schema(field.name, field.validation,this.component.data[field.name].input, field.isrequired) ? false : true : false}
                        //helperText={field.validation || field.isrequired ? validate.schema(field.name, field.validation,this.component.data[field.name].input, field.isrequired) ? '' : field.errorText ? field.errorText : 'Invalid '+field.validation+' input value' : ''}
                        error={
                            field.isrequired 
                                ? this.component.data[field.name].input 
                                    ? field.validation
                                        ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text, field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                                        : '' 
                                    : field.title + ' Is Required' 
                                :  this.component.data[field.name].input && field.validation
                                    ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text , field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                                    : ''
                        }
                        helperText={
                            field.isrequired 
                            ? this.component.data[field.name].input 
                                ? field.validation
                                    ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text, field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                                    : '' 
                                : field.title + ' Is Required' 
                            :  this.component.data[field.name].input && field.validation
                                ? validate.schema(field.name, field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : this.component.data[field.name].text , field.isrequired, field.maxLength) ? '' : field.errorText ? field.errorText : 'Invalid input value on ' + field.title
                                : ''
                        }
                        value={this.component.data[field.name].input ? this.component.data[field.name].input : field.default_value ? field.default_value : ''}
                        onChange={
                            (event, value) => {
                                this.props.actions.setComponentFieldInput(field, event.target.value)
                            }
                        } 
                        onBlur={
                            (event, value) => {
                                //console.log('onBlur',event.target.value)
                                this.props.actions.setComponentFieldInput(field, event.target.value)
                                
                            }
                        }
                    />
                case 'textarea':
                    return <TextField
                        inputProps={{ autoComplete: 'off' }}
                        id={this.component.data[field.name].key}
                        key={this.component.data[field.name].key}
                        label={window.t.en(field.isrequired ? field.hint + ' *' : field.hint)}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        multiline={true}
                        rows={4}
                        //helperText={this.component.data[field.name].validation.message}
                        //type={field.validation ? field.validation : 'text'}
                        //type={'string'}
                        //error={!validate.schema(field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : '')}
                        error={this.component.data[field.name].validation.message}
                        helperText={validate.schema(field.validation,this.component.data[field.name].input ? this.component.data[field.name].input : '') ? '' : field.errorText}
                        value={this.component.data[field.name].input ? this.component.data[field.name].input : field.default_value}
                        onChange={
                            (event, value) => {
                                this.props.actions.setComponentFieldInput(field, event.target.value)
                            }
                        }
                    />
                    case 'stardate':
                        
                        return  <TextField
                            id={this.component.data[field.name].key}
                            key={this.component.data[field.name].key}
                            label={field.isrequired ? field.hint + ' *' : field.hint}
                            fullWidth={true}
                            variant="outlined"
                            color="secondary"
                            disabled={true}
                            value={this.component.data[field.name].input}
                        />
                case 'toggle':
                    return <div>
                        <CheckBoxControl
                            key={this.component.data[field.name].key}
                            id={this.component.data[field.name].key}
                            checked={this.component.data[field.name].input != null ? this.component.data[field.name].input : false}
                            field={field}
                            label={window.t.en(field.isrequired ? field.hint + ' *' : field.hint)}
                            //label={field.title.length > 40 ? field.title.substring(0,40).concat('...') : field.title}
                            // setComponentFieldInput={this.props.actions.setComponentFieldInput}
                            onCheck={(checked) => {
                                this.props.actions.setComponentFieldInput(field, checked)
                            }}
                        />
                        {
                            this.component.data[field.name].validation.message
                                ? [
                                    <br />,
                                    <div style={{ color: 'red' }}>{this.component.data[field.name].validation.message}</div>
                                ]
                                : ''
                        }
                    </div>
                default:
                    return
            }
    }

    getRequiredIndicator(isrequired) {
        return isrequired ? <span style={{color: 'red'}}>*</span> : <span></span>
    }

    getField(field, index, hidden) {
        return !hidden
            ? <div style={styles.fieldContainer} id={'sec_'+field.name}>
                <a href={'#sec_'+field.name} id={'link_'+field.name}></a>
                {this.getFormFieldValue(field, index)}
            </div>
            : this.getFormFieldValue(field, index)
    }

    getRelatedGrid(field) {
        //console.log('getRelatedGrid',field)
        let _field = this.props.state.component.component_field.filter(x => x.key === true)[0]
        let value = this.component.data[_field.name].input
        return value ? <Col key={'col_' + field.name} xl={12}>
            <RelatedGrid
                id={field.name}
                key={field.name}
                fieldname={field.name}
                name={field.title}
                expanded={true}
                stop={true}
                component={field.title}
                model={{[field.name]: {
                    id: value,
                    name: field.title,
                    component_title: field.title,
                    model: field.display.split('.')[0],
                    related: field.display.split('.').length === 1 ? '' : field.display.split('.')[1],
                    component: field.display.split('.').length === 1 ? field.display.split('.')[0] : field.display.split('.')[1],
                    primary: this.props.state.component.name
                }}}
                data={[]}
            />
        </Col> : ''
    }
    
    getDisplayCondition(x) {
        let condition = true
        if(x.display_condition) {
            let _display_condition = x.display_condition ? x.display_condition.replace('!','') : ''
            if(x.display_condition_value) {
                condition = x.display_condition 
                    ? x.display_condition.includes('!') 
                        ?  !this.component.data[_display_condition][_display_condition.includes('_id') ? 'text' : 'input'] !== x.display_condition_value
                        :  this.component.data[_display_condition][_display_condition.includes('_id') ? 'text' : 'input'] === x.display_condition_value
                    : true
                    //console.log('display_condition_valuex x', x)
                    //console.log('display_condition_valuex _', _display_condition)
                    //console.log('display_condition_valuex display_condition cond', x.display_condition)
                    //console.log('display_condition_valuex display_condition val', x.display_condition_value)
                    //console.log('display_condition_valuex display_condition input', this.component.data[_display_condition][x.name.includes('_id') ? 'text' : 'input'])
                    //console.log('display_condition_valuex display_condition name', x.name)
                    //console.log('display_condition_valuex display_condition bool', condition)
            }
            else { 
                condition = x.display_condition 
                    ? x.display_condition.includes('!') 
                        ?  !this.component.data[_display_condition][_display_condition.includes('_id') ? 'text' : 'input'] 
                        :  this.component.data[_display_condition][_display_condition.includes('_id') ? 'text' : 'input'] 
                    : true
            }

        }
        return condition
    }

    getSection(section, index) {
        let _fieldsx = section.component_field.map(x => {
            return x.component_field_display_type.name === 'toggle' ? {
                ...x,
                orderx: 1
            }
            : {
                ...x,
                orderx: 0
            }
        })
        let fields = _fieldsx
            .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
            .sort((x, y) => x.orderx - y.orderx)
            .filter(x => x.form)
            .map((x, i) => {
                let condition = this.getDisplayCondition(x)

                // let _display_condition = x.display_condition ? x.display_condition.replace('!','') : ''
                // let _display_condition_field = x.display_condition ? x.display_condition.includes('==') ? x.display_condition.split('==')[0] : '' : ''
                // let _display_condition_val = x.display_condition ? x.display_condition.includes('==') ? x.display_condition.split('==')[1] : x.display_condition : ''
                // let condition = x.display_condition 
                // ? x.display_condition.includes('!') 
                //     ? x.related 
                //         ? _display_condition_val 
                //             ? this.component.data[_display_condition_field] ? this.component.data[_display_condition_field].text =! _display_condition_val : this.component.data[_display_condition_field] ?  !this.component.data[_display_condition_field].text   : true  : true
                //         : _display_condition_val 
                //             ? this.component.data[_display_condition_field] ? this.component.data[_display_condition_field].input =! _display_condition_val : this.component.data[_display_condition_field] ? !this.component.data[_display_condition_field].input  : true   : true
                //     : x.related 
                //         ? _display_condition_val 
                //             ? this.component.data[_display_condition_field] ? this.component.data[_display_condition_field].text == _display_condition_val : this.component.data[_display_condition_field] ? this.component.data[_display_condition_field].text  : true  : true
                //         : _display_condition_val 
                //             ? this.component.data[_display_condition_field] ? this.component.data[_display_condition_field].input == _display_condition_val : this.component.data[_display_condition_field] ? this.component.data[_display_condition_field].input : true  : true
                // : true
                // if(x.display_condition) {
                //     //console.log('display_conditionx', x.display_condition)
                //     //console.log('display_conditionx condition', condition)
                //     //console.log('display_conditionx data', this.component.data[_display_condition])
                //     //console.log('display_conditionx name', x.name)
                // }
                switch (x.component_field_display_type.name) {
                    case 'hidden':
                        return this.getField(x, i, true)
                    case 'grid':
                        return this.getRelatedGrid(x)
                    case 'autoComplete':
                        return condition ?  <Col key={'col_' + x.name} xl={4}>{this.getField(x, i)}</Col> :''
                    default:
                        return condition ? <Col key={'col_' + x.name} xl={4}>{this.getField(x, i)}</Col> : ''
                }
            })
        let fieldsxx = fields.filter(x => x)
        let _res = fields.length ? fieldsxx[0]?.toString() : ''
        //console.log('form fieldsxx',fields)
        //console.log('form fieldsxx fieldsxx',fieldsxx)
        //console.log('form fieldsxx section',section)

        return _res ?  <div style={{boxShadoww: '0 2px 15px 0 rgb(32 40 45 / 13%)'}}><>
        <Row>
            <Col xl={12}><br/></Col>
        </Row>
        <Row>
            {/* <Col xl={5}><hr style={{border: '5px solid #152461', marginTop: '5px', left: '-60px', position: 'relative', width: '115%'}}/>
            </Col>
            <Col xl={7} style={{fontStyle: 'normal', fontWeight: 1000, fontSize: '24px', lineHeight: '20px', color: '#131B3A' }}>{section.description}</Col>
             */}
            <Section title={window.t.en(section.description)}  />
        </Row>
        <Row>
            <Col xl={12}>&nbsp;</Col>
        </Row>
         <Row>
             {fields}
         </Row>
     </><br/><br/><br/></div>  : ''
    }

    getContactSection() {
        return <Card
            icon={<Icon iclass={'material-icons'} iname={'add'} />}
            title={'Contact Details'}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                    <AdhocComponentFieldInput
                        title={this.component.name}
                        description={'Manage Contacts'}
                        icon={''}
                        id={'suppliercontact'}
                        parent={'suppliercontact'}
                        parentFilters={[
                            {
                                field: 'supplier_id',
                                value: 2
                            }
                        ]}
                        child={'contact'}
                        // childFilters={[
                        //     {
                        //         field: 'contacttype',
                        //         value: 'Accounts2'
                        //     }
                        // ]}
                        extractChildData={true}
                        adhocFieldInput={[ //assumes Parent component fields if extractChild is set to false
                            'fullname', 'email', 'mobile', 'contacttype'
                        ]}
                        selectMode={false}
                        addMode={true}
                    />
                    </Col>
                </Row>
            </Container>}
        />
    }

    getUpload() {
        return <Card
            title={window.t.en('File Upload')}
            subtitle={window.t.en('Upload related documents')}
            content={<Upload
                id={'upload_ctl'}
                prefix={'po'}
                header={false}
            />}
        />
    }

    getRelationship(relationship, index) {
        return <Card
            title={relationship.title}
            subtitle={relationship.description}
            content={<Grid
                    name={relationship.name}
                    filterable={true}
                    link={{
                        parent: this.props.name,
                        id: this.props.id,
                        through: relationship.linker.name
                    }}
                />}
        />
    }

    getForm() {
        const sections = this.component.sections
            .filter(x => x.component_field.filter(y => y.form).length)
            .map((x, i) => {
                return this.getSection(x, i)
            })

        return <div>
            {sections}
            {/* {this.getContactSection()} */}

                    
            <Dialog
                open={this.props.state.prompt_win}
                style={{ width: '100% !important', maxWidth: '100% !important' }}
                fullWidth={true}
                maxWidth="xl"
                >
                <div className="text-center p-5">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                
                </div>
                <h4 className="font-weight-bold mt-4">
                Redirect Fine
                </h4>  
                <p className="mb-0 font-size-lg text-muted">
                       Are you you want to redirect
                </p>
                <div className="pt-4">
                    <Button
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.redirectFine(true)
                        this.props.actions.redirectFineWin(false)
                        //this.props.actions.saveComponentData (this.component)
                        //this.props.actions.getCognitoUserAuthenticationDetails(this.props.state.login.email.input,this.props.state.login.keyword.input)
                    }}
                    className="btn-pill mx-1">
                    <span className="btn-wrapper--label">Redirect</span>
                    </Button>
                    <Button 
                        onClick={(event) => {
                            this.props.actions.redirectFine(false)
                            this.props.actions.redirectFineWin(false)
                        }}
                        className=" btn-pill mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                </div>
                </div> 
            </Dialog>
        </div> 
    }

    render() {
        this.component = this.props.state.component
        return (
            this.getForm()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: { ...state.form, component: { ...state.components[ownProps.name] }, user: {...state.user}}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...controlActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent)