import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import QuoteSelectGrid from '../QuoteSelectGrid/component'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import { Card, CardHeader, CardContent } from '@mui/material'

import { Container, Row, Col } from 'react-grid-system'

import WorkflowView from '../WorkflowView/component'

import * as actions from './actions'
import * as redirectActions from '../Redirect/actions'
import * as styles from './styles'

import WorkflowViewFMLQuoteSummary from '../WorkflowViewFMLQuoteSummary/component'
import WorkflowViewFMLQuoteConfirm from '../WorkflowViewFMLQuoteConfirm/component'

class WorkflowTaskStepQuoteSingleSelector extends Component {
    componentDidMount() {
        this.props.actions.resetSelectedData(this.props.state.component)
    }

    getView(filter){

        window.glyco.log('filter 2')
        window.glyco.log(filter)
        window.glyco.log(this.selected)
        return (
            this.selected.length
                ? (
                    <div>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    <WorkflowViewFMLQuoteSummary
                                        id={'view_quote_' + this.component.name}
                                        selected={this.selected[0].original}
                                        workflow_queue_data={this.props.workflow_queue_data}
                                        step={this.props.step}
                                        name={this.props.name}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                        </Container>

                        <br />
                    </div>
                )
                : (
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <QuoteSelectGrid
                                    key={'workflow_task_step_single_selector_' + this.props.name + '_' + this.props.step}
                                    name={this.props.name}
                                    task={true}
                                    mode='select'
                                    type='single'
                                    filter={filter}
                                    filterable={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.confirmSelection}>
                               < Button className={"global_button"} variant="contained"
                                    label={'Select'}
                                    primary={true}
                                    disabled={!this.component.selected.length}
                                    onClick={(event) => {
                                        this.props.actions.confirmRowSelection(this.props.step, this.props.name)
                                    }}
                                    >{window.t.en('Select')}</Button>
                            </Col>
                        </Row>
                    </Container>
                )
        )
    }

    render() {
        const component = {
            actions: this.props.actions,
            state: this.props.state,
            styles: styles
        }

        this.component = this.props.state.component
        this.selected = this.props.state.component.selected
        this.data = this.props.workflow_queue_data

        let filter = null
        
        window.glyco.log('this.workflow_queue_data')
        window.glyco.log(this.props.workflow_queue_data)
        window.glyco.log(this.workflow_queue_data)
        try{
            filter = this.props.workflow_queue_data[0].context_data.data.data.data[0].quotegroup
            window.glyco.log('filter 1')
            window.glyco.log(filter)
        }
        catch(err){}

        return (
            filter
            ? this.getView(filter)
            : 'Loading...'
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowTaskInput,
            ...state.workflowTaskStepComponentSingleSelector,
            component: {
                ...state.components[ownProps.name]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...redirectActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskStepQuoteSingleSelector)