import * as actions from './actions';
import * as types from './types';
import * as progressBarActions from '../ProgressBar/actions';
import * as routerActions from '../Redirect/actions';
import * as requestActions from '../Request/actions';
import * as requestTypes from '../Request/types';
import * as config from '../../config';

export const getSchedulerData = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_REPORTS_SCHEDULER_DATA,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/scheduler/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'client-id': store.value.accounts.selectedAccount,
                    'Content-Type': 'application/json',
                    'related': 'report'
                },
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setSchedulerData(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const saveScheduler = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.SAVE_SCHEDULER,
    )
        , mergeMap(({ payload }) => {
                return ajax$({
                    url: config.system.api + '/components/scheduler',
                    method: 'POST',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'client-id': store.value.accounts.selectedAccount,
                        'Content-Type': 'application/json'
                    },
                    body: payload
                })
                    .pipe(
                        mergeMap((result) => [
                            //actions.saveReportFeedback(result.response),
                            actions.saveSchedulerDone(result.response),
                            requestActions.requestSucceeded(config.system.api + '/components/scheduler')
                        ])
                        , startWith(progressBarActions.progress(true))
                        , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/scheduler', error)))
                        //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                    )
        })
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getReports = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_REPORTS,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/report/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json',
                    'take': "999"
                },
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setReportData(result.response),
                        actions.getReportCatData(),
                        requestActions.requestSucceeded(config.system.api + '/components/report/search')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/report/search', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getReportCategories = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_REPORT_CATEGORIES,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/reportcategory/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json',
                    'take': "999",
                    'related': "subcategory"
                },
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setReportCatData(result.response),
                        requestActions.requestSucceeded(config.system.api + '/components/reportcategory/search')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/reportcategory/search', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )