import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_DEFLEET_UPLOADCHECKSHEET_STATE:
            return {
                ...state,
                comments:  {
                    ...state.comments,
                    data: null,
                    valid: false,
                    empty: true,
                    value: '',
                },
                correctdocument_type:  {
                    ...state.correctdocument_type,
                    data: null,
                    valid: false,
                    empty: true,
                    value: null,
                },
                defleet_date_match:  {
                    ...state.defleet_date_match,
                    data: null,
                    valid: false,
                    empty: true,
                    value: null,
                },
                odoreading:  {
                    ...state.odoreading,
                    data: null,
                    valid: false,
                    empty: true,
                    value: '',
                },
                checksheet_completedby:  {
                    ...state.checksheet_completedby,
                    data: null,
                    valid: false,
                    empty: true,
                    value: '',
                },
                location:  {
                    ...state.location,
                    data: null,
                    valid: false,
                    empty: true,
                    value: null,
                },
                defleetdate:  {
                    ...state.defleetdate,
                    data: null,
                    valid: false,
                    empty: true,
                    value: null,
                },
                checksheet_completedat:  {
                    ...state.checksheet_completedat,
                    data: null,
                    valid: false,
                    empty: true,
                    value: null,
                },
                has_sparekey:  {
                    ...state.has_sparekey,
                    data: null,
                    valid: false,
                    empty: true,
                    value: null,
                },
                has_damages:  {
                    ...state.has_damages,
                    data: null,
                    valid: false,
                    empty: true,
                    value: true,
                },
                fuelamount:  {
                    ...state.fuelamount,
                    data: null,
                    valid: false,
                    empty: true,
                    value: '',
                },
                fields: props.fields
            }           
            
        case types.SET_DEFLEET_UPLOADCHECKSHEET_VALIDATION_VALUE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: {
                        ...state.fields[action.payload.prop],
                        data: action.payload.value,
                        valid: action.payload.value ? true : false
                    }
                }
            }
        case types.SET_DEFLEET_CHECKSHEET:
            return {
                ...state,
                defleetpdf_preview: action.payload.preview,
                defleetpdf_filename: action.payload.filename
            }
        case types.SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                }
            }
        case types.SET_SELECTED_VEHICLE_DETAILS:
            return {
                ...state,
                selectedVehicleDetails: {
                    ...state.selectedVehicleDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_DEFLEET_LOCATION:
            return {
                ...state,
                locations: {
                    ...state.locations,
                    data: action.payload.data
                }
            }            
        default:
            return state
    }
}