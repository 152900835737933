import * as types from './types'

export const getComponentTileData = (component, paging) => {
    return (dispatch, getState) => {
        const getQuery = () => {
            let query = paging.filtered.reduce((query, x) => {
                query.push(x.id.concat('=', x.value))
                return query
            }, [])

            return '?' + query.join('&')
        }

        dispatch({
            type: types.GET_COMPONENT_TILE_DATA,
            payload: {
                component: component,
                paging: paging,
                order: '',
                sorting: paging.sorted.length
                    ? !paging.sorted[0].desc
                        ? paging.sorted[0].id
                        : '-' + paging.sorted[0].id
                    : '',
                query: getQuery()
            }
        })
    }
}

export const setComponentTileData = (component, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_TILE_DATA,
            payload: {
                component: component,
                data: data
            }
        })
    }
}