import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_GRID_ROW_FIELD_VALUE:
            return {
                ...state
            }
        case types.SET_COMPONENT_DELETE_ROW_FIELD_VALUE:
            return {
                ...state,
                deleterecord: action.payload,
                deleterecordwin: action.payload ? true : false
            }
        case types.SET_DELETE_INPROGRESS:
            return {
                ...state,
                deletingrecord: action.payload,
                deleterecordwin: action.payload
            }
        case types.SET_EXPORT_INPROGRESS:
            return {
                ...state,
                exportinprogress: action.payload
            }
        case types.SET_FILTER_INPROGRESS:
            return {
                ...state,
                filterinprogress: action.payload
            }
        case types.GET_EXCEL_GRID_EXPORT:
            return {
                ...state,
                exporteddata: action.payload
            }
        default:
            return state
    }
}