import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Button from '@mui/material/Button'
import Calculator from '../Calculator/component'
import Icon from '../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

class CalculatorContainer extends Component {
    render() {
        return (
            <div style={styles.view}>
                <div style={styles.spacer}></div>
                <div style={styles.headerBackground()}>
                    <div style={styles.headerContainer}>
                        <div style={styles.headerTitle}>Calculator</div>
                    </div>
                </div>
                <div>
                   < Button className={"global_button"} variant="contained"
                        className={"global_button"}
                        //primary={true}
                        label="Add Calculator"
                        icon={<Icon iclass={'material-icons'} iname={'plus_one'} />}
                        onClick={(event) => {
                            this.props.actions.addCalculator()
                        }}
                        >{window.t.en('Add Calculator')}</Button>
                </div>
                <div style={styles.content}>
                    {
                        this.props.state.calculators.map(x => {
                            return <div key={'calculator_container_' + x} style={styles.calculator}>
                                <Calculator id={x} />
                            </div>
                        })
                    }
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.calculatorContainer }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorContainer)