export const tree = []
export const clients = []
export const data = []
export const hierarchy = []
export const init = []

export const client = 2 
export const sidebar = true
export const sidebarstate = true
export const appbar = true
export const appbarstate = true

export const allBusinesses = ''
export const allCustomers = ''
export const openlang = false
export const tabValue = 0
export const openOrganisation = false
export const openLanguage = false
export const notification = false
export const anchorEl = null
export const openNotifications = ""
export const animated = 0
export const workqueue_count = 0

export const profileMenus = false
export const profileMenusEl = null

export const notifications_headers = null
export const notifications_items = null
export const workqueue = []

export const oqliskey = null
export const oqlis = []
export const reportData = []
export const document_preview = []
export const selected_document_preview = null
export const openDocumentSelection = false



