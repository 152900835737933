import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'

import ReactTable from "react-table"
import "react-table/react-table.css"

import Card from '../../../controls/card'
import * as styles from './styles'
import * as actions from './actions'

class ContractRestructureConfirm extends Component {
    componentDidMount() {
        let data = null
        try {
            data = {
                period: this.props.state.period.value,
                terminationdate: this.props.state.terminationdate.value,
                distancepermonth: this.props.state.distancepermonth.value,
                contractdistance: this.props.state.contractdistance.value,
                retailprice: this.props.state.retailprice.value,
                discountamount: this.props.state.discountamount.value,
                vehicleprice: this.props.state.vehicleprice.value,
                accessoriesselling: this.props.state.accessoriesselling.value,
                serviceselling: this.props.state.serviceselling.value,
                sundryselling: this.props.state.sundryselling.value,
                interestrate: this.props.state.interestrate.value,
                rv: this.props.state.rv.value,
                rvamount: this.props.state.rvamount.value,
                finance: this.props.state.finance.value,
                roi: this.props.state.roi.value,
                maintenance: this.props.state.maintenance.value,
                tyres: this.props.state.tyres.value,
                licence: this.props.state.licence.value,
                service: this.props.state.service.value,
                adminfee: this.props.state.adminfee.value,
                roi: this.props.state.roi.value,
                capitalbalance:this.props.state.capitalbalance.value,
                monthly_excl: this.props.state.monthly_excl.value,
                monthly_vat: this.props.state.monthly_vat.value,
                monthly_incl: this.props.state.monthly_incl.value,
                financeaccess: this.props.state.financeaccess.value,
                maintenanceaccess: this.props.state.maintenanceaccess.value,
                maintenanceexcess: this.props.state.maintenanceaccess.value,
                excess: this.props.state.excess.value,
                recovery: this.props.state.recovery.value,
                contractaccessory: this.props.state.contractaccessory.value,           
                contractsundry: this.props.state.contractsundry.value,
                mmcode: this.props.state.mmcode.value,
                makemodelyear: this.props.state.makemodelyear.value,
                imagefilename: this.props.state.imagefilename.value,
                effectivedate: this.props.state.effectivedate.value,
                expirydate: this.props.state.expirydate.value,
                contract: this.props.state.contract.value,
                restructuretype: this.props.state.restructuretype.value,
                supplier_id: this.props.state.supplier_id.value,
                accessories: this.props.state.accessories,
                is_bulk: this.props.state.contractSelectionGrid.is_bulk,
                bulk_rows: this.props.state.contractSelectionGrid.bulk_rows,
                funderadminfee:this.props.state.funder_adminfee.value
            }
            //console.log("confirm data",data)
        } catch (err) 
        { }
        const meta = this.props.state.contractSelectionGrid.is_bulk
        ? [{
            header: true,
            title: 'Bulk Restructure',
            description: 'Bulk Restructure',
            meta_data: 'Bulk Restructure'
        }]
        : [
            {
                header: true,
                title: 'Vehicle Reg',
                description: 'Vehicle Reg',
                meta_data: data.contract[0].licenseplateno
            },
            {
                title: 'Contract Number',
                description: 'Contract Number',
                meta_data: data.contract[0].contractnumber
            },
            {
                title: 'Vehicle Model',
                description: 'Vehicle Model',
                meta_data: data.makemodelyear
            }
        ]
        this.props.actions.setupWorkflowTaskInput(data, meta, 'customercontract', 'tsk_contract_restructure_select_contract')
    }

    getContract(data) {
        //console.log('Contract Data',data)
        return <Card 
            title={window.t.en('Contract Current Details')}
            subtitle={window.t.en('contract details')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} style={styles.fieldLabel}>{'Vehicle:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.licenseplateno}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Quote Number:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.quotenumber}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Contract Period:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.period}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Distance Per Month:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.distancepermonth}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Contract Distance:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.distance}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Contract Description:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.contractdescription}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Contract Type:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.contracttype ? data.contracttype.contracttype : ''}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Make Model:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.makemodelyear}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Customer Name:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.customer.customername}</Col>
                </Row>
            </Container>
       } />
    }

    getContractParameters(data) {
        return <Card 
            title={window.t.en('New Contract Parameters')}
            subtitle={window.t.en('new contract parameters')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} style={styles.fieldLabel}>{'Vehicle Retail Price:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.retailprice).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Vehicle Price:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.vehicleprice).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'RV:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{data.rv}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'RV Amount:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.rvamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Accessory:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.accessoriesselling).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Sundry Selling:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.sundryselling).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Licence:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.licence).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Admin Fee:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.adminfee).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Maintenance:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.maintenance).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Tyres:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.tyres).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Finance Amount:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.finance).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Total Vat:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.monthly_vat).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                    <Col xl={2} style={styles.fieldLabel}>{'Monthly Rental:'}</Col>
                    <Col xl={2} style={styles.selectedFieldName}>{parseFloat(data.monthly_incl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                </Row>
            </Container>
        } />
    }

    getBulkCellValue(row, inx) {
        //let rows = this.props.state.bulk_rows[0]
        return <label style={styles.textBox}>{ this.props.state.contractSelectionGrid.bulk_rows[row.index][inx]}</label>
    }

    getBulkColumns() {
        let header =  this.props.state.contractSelectionGrid.bulk_rows[0]
        let columns = header.map((x, i) => {
            return {
                meta: x,
                Header: x,
                accessor: x,
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <input
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                            width: '100%',
                            height: 25,
                            border: '1px solid #E6E6E6',
                            borderRadius: 25
                        }}
                    />
                ),
                width: x.width ? x.width : undefined,
                headerStyle: {
                    fontSize: '13px',
                    fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                    //backgroundColor: x.highlight ? styles.highlight : '',
                },
                style: {
                    verticalAlign: 'middle',
                    fontSize: 12,
                    letterSpacing: 0,
                    color: 'black',
                    fontWeight: 'normal',
                    //textAlign: x.align
                },
                Cell: (row) => (
                    this.getBulkCellValue(row, i)
                )
            }
        })
        return columns
    }
    getBulkGrid() {
        return <ReactTable
            key={'grid_booking_quote'}
            data={this.props.state.contractSelectionGrid.bulk_rows}
            columns={this.getBulkColumns()}
            filterable={true}
            loading={false}
            manual
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.getRelatedRowItem(rowInfo, this.data)
                    },
                }
            }}
            // onFetchData={
            //     (state) => {
            //         this.props.actions.getRestructureGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
            //     }
            // }
            minRows={1}
            showPagination={false}
            showPaginationBottom={false}
            style={{
                height: '100%'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }

    
    
    getAccessories() {
        let contractaccessory = this.props.state.contractaccessory.value
        return contractaccessory.length ? <Card
        title={'Contract Accessory'}
        subtitle={'Accessries list and values'}
        content={<Container style={{ margin: 0 }} className="custom-container"><Row style={{paddingTop: '15px'}}>
                <Col xl={4}>
                <b>{'Accessory Name'}</b>
                </Col>
                <Col xl={2}>
                <b>{'Cost Price'}</b>
                </Col>
                <Col xl={4}>
                    <b>{'Supplier Name'}</b>
                </Col>
                <Col xl={1}>
                    
                </Col>
            </Row>
            {
                contractaccessory
                    ? contractaccessory
                        .map((x, i) => {
                            return <Row style={{paddingTop: '15px'}}>
                                <Col xl={4}>
                                    {x.accessoryname}
                                </Col>
                                <Col xl={2}>
                                    {x.amount}
                                </Col>
                                <Col xl={4}>
                                    {x.suppliername ? x.suppliername : ''}
                                </Col>
                                <Col xl={1}>
                                    
                                </Col>
                            </Row>
                        })
                    : ''
            }
        </Container>
        }
    />  : ''
    }
    getView(data) {
         return data.is_bulk
          ? <div>
            {this.getBulkGrid()}
        </div>
        : <div>
            {this.getContract(data.contract[0])}
            {this.getAccessories(data)}
            {this.getContractParameters(data)}
        </div>
    }

    render() {
        let data = null
        try {
            data = {
                period: this.props.state.period.value,
                terminationdate: this.props.state.terminationdate.value,
                distancepermonth: this.props.state.distancepermonth.value,
                contractdistance: this.props.state.contractdistance.value,
                retailprice: this.props.state.retailprice.value,
                discountamount: this.props.state.discountamount.value,
                vehicleprice: this.props.state.capitalbalance.value,
                accessoriesselling: this.props.state.accessoriesselling.value,
                sundryselling: this.props.state.sundryselling.value,
                interestrate: this.props.state.interestrate.value,
                rv: this.props.state.rv.value,
                rvamount: this.props.state.rvamount.value,
                finance: this.props.state.finance.value,
                maintenance: this.props.state.maintenance.value,
                tyres: this.props.state.tyres.value,
                licence: this.props.state.licence.value,
                service: this.props.state.service.value,
                adminfee: this.props.state.adminfee.value,
                roi: this.props.state.roi.value,
                monthly_excl: this.props.state.monthly_excl.value,
                monthly_vat: this.props.state.monthly_vat.value,
                monthly_incl: this.props.state.monthly_incl.value,
                financeaccess: this.props.state.financeaccess.value,
                maintenanceaccess: this.props.state.maintenanceaccess.value,
                recovery: this.props.state.recovery.value,
                contractaccessory: this.props.state.contractaccessory.value,
                contractsundry: this.props.state.contractsundry.value,
                mmcode: this.props.state.mmcode.value,
                makemodelyear: this.props.state.makemodelyear.value,
                imagefilename: this.props.state.imagefilename.value,
                restructuretype: this.props.state.restructuretype.value,
                contract: this.props.state.contract.value,
                is_bulk: this.props.state.contractSelectionGrid.is_bulk,
                bulk_rows: this.props.state.contractSelectionGrid.bulk_rows,
                funderadminfee:this.props.state.funder_adminfee.value
            }
        } catch (err) 
        { }


        return (
            data
            ? this.getView(data)
            : 'Loading...'
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.contractRestructure,
            contractSelectionGrid: {
                ...state.contractSelectionGrid,
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractRestructureConfirm)