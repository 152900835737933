import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.OPEN_QUOTE_EDIT_VIEW:
            return {
                ...state,
                edit: true
            }
        case types.CLOSE_QUOTE_EDIT_VIEW:
            return {
                ...state,
                edit: false
            }
        case types.SET_DOCUMENT_VIEW_PROP_FLAG:
            return {
                ...state,
                view: action.payload
            }
        case types.SET_CURRENT_DOCUMENT_PREVIEW_TAB:
            return {
                ...state,
                tab: action.payload
            }
        case types.SET_PDF_MULTIPLE_VIEW_DOCUMENTS:
            return {
                ...state,
                documents: action.payload
            }
        case types.SET_PDF_MULTIPLE_VIEW_DOCUMENTS_WITH_PROP:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_PDF_MULTIPLE_DOCUMENTS_PREVIEW:
            return {
                ...state,
                documents: action.payload
            }
        case types.SET_COMPONENT_DOCUMENT_PREVIEW_FLAG:
            return {
                ...state,
                preview: action.payload
            }
        case types.CLEAR_DOCUMENTS_PREVIEW:
            return {
                ...state,
                documents: []
            }
        default:
            return state
    }
}