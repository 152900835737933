//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const icon = {
    fontSize: '10px'
}

export const row = {
    fontSize: 16
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
    //opacity: 0.85
}


export const numericFieldName = {
    fontWeight: 'bold',
    textAlign: 'right',
}

export const fieldNameRight = {
    textAlign: 'right',
}

export const numericFieldValues = {
    textAlign: 'right',
}

export const numericFields = {
    textAlign: 'right',
    fontWeight: 'bold',
}


export const fieldContainer = {
    width: '100%',
    padding: '30px 5px'
}

export const fieldTitle = {
    width: '60%',
    display: 'table-cell',
    // verticalAlign: 'top',
    paddingTop: '10px',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10,
}

export const fieldTitleBold = {
    width: '60%',
    display: 'table-cell',
    // verticalAlign: 'top',
    paddingTop: '10px',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10,
    fontWeight: 'bold'
}

export const fieldContent = {
    // display: 'table-cell',
    verticalAlign: 'top',
    width: '100%',
    padding: '20px',
    borderRadius: '20px',
    backgroundColor: '#fff'
}