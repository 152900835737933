import * as types from './types'
import moment from 'moment'

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPOMENT_STATE
        })
    }
}



export const getPreAuthNumber = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PRE_AUTHORISATION_NUMBER,
            payload: {
                type: 'GET_PRE_AUTHORISATION_NUMBER'
            }
        })
    }
}

export const setPreAuthNumber = (data) => {
    //console.log('SET_PRE_AUTHORISATION_NUMBER ...................................................................................', data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PRE_AUTHORISATION_NUMBER,
            payload: data.data
        })
    }
}

export const getSupplierAutoCompleteMenuItems = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS,
            payload: {
                query: '?suppliername=' + value + '&accountnumber=' + value 
            }
        })
    }
}

export const setSupplierAutoCompleteMenuItems = (result) => {
    //console.log(result.data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS,
            payload: result.data
        })
    }
}

// export const setSupplierAutoCompleteSelected = (args) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_SUPPLIER_AUTOCOMPLETE_SELECTED,
//             payload: args
//         })
//     }
// }

export const setInputValue = (prop, val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_INPUT_VALUE,
            payload: {prop:prop, val:val}
        })
    }
}

export const setUserAutoCompleteMenuItems = (result, selected) => {
    //console.log('result', result)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_AUTOCOMPLETE_MENU_ITEMS,
            payload: selected
                ? result.data
                : result.data.length
                    ? result.data.map(x => {
                        return {
                            value: x.user_id,
                            text: x.firstname + ' ' + x.lastname
                        }
                    })
                    : []
        })
    }
}


export const getUserAutoCompleteMenuItems = (value) => {
    //console.log('getUserAutoCompleteMenuItems', value)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_USER_AUTOCOMPLETE_MENU_ITEMS,
            payload: {
                query: '?firstname=' + value
            }
        })
    }
}

export const setUserAutoCompleteSelected = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_AUTOCOMPLETE_SELECTED,
            payload: args
        })
    }
}


export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_CREDITORS_PAYMENT_CAPTURE,
            payload: payload
        })
    }
}




















// export const selectPurchaseOrderType = (args) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SELECT_PURCHASE_ORDER_TYPE,
//             payload: args
//         })
//     }
// }

// export const selectMaintenanceClass = (args) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SELECT_MAINTENANCE_CLASS_TYPE,
//             payload: args
//         })
//     }
// }


// export const getMaintenanceClass = (value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_MAINTENANCE_CLASS_ITEMS,
//             payload: value
//         })
//     }
// }

// export const setMaintenanceClass = (payload,response) => {
//     //console.log('setMaintenanceClass >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ',response)
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_MAINTENANCE_CLASS_ITEMS,
//             payload: response.data
//         })
//     }
// }
// export const setUserAutoCompleteMenuItems = (result, selected) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_USER_AUTOCOMPLETE_MENU_ITEMS,
//             payload: selected
//                 ? result.data
//                 : result.data.length
//                     ? result.data.map(x => {
//                         return {
//                             value: x.user_id,
//                             text: x.firstname + ' ' + x.lastname
//                         }
//                     })
//                     : []
//         })
//     }
// }



// export const getVehicleListAutoCompleteMenuItems = (value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS,
//             payload: {
//                 query: '&licenseplateno=' + value // + + '&makemodelyear=' + value // + '&vehiclestatus_fktext=' + value
//             }
//         })
//     }
// }

// export const setVehicleListAutoCompleteMenuItems = (result, selected) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS,            
//             payload: result.data
//         })
//     }
// }

// export const setVehicleAutoCompleteSelected = (args) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_VEHICLE_AUTOCOMPLETE_SELECTED,
//             payload: args
//         })
//     }
// }

// export const getSelectedVehicleDetails = (value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_SELECTED_VEHICLE_DETAILS,
//             payload: {
//                 query: value
//             }
//         })
//     }
// }

// export const setSelectedVehicleDetails = (result, vehicle_id) => {
//     window.glyco.log('SET_SELECTED_VEHICLE_DETAILS')
//     window.glyco.log(result)
//     return (dispatch, getState) => {
        
//         //const curr_type = getState().poHeader.selectedPurchaseOrderType.key
//         dispatch({
//             type: types.SET_SELECTED_VEHICLE_DETAILS,
//             payload: {
//                 data: result.data
//             }
//         })
        
//         // let recent_auth = []
//         // try {
//         //     recent_auth = result.data[0].maintenancetransaction
//         //             .filter(x => moment().diff(x.created_at, 'hours') < 24)
//         //                         .filter(x => x.deleted_at === null)
//         //                         .filter(x => x.preauthnumber !== null)
//         //                         .filter(x => (x.maintenancetype_fktext ? x.maintenancetype_fktext : ''))
//         //                         //.toLowerCase() === (curr_type ? curr_type : '').toLowerCase())
            
//         // } catch (error) {
            
//         // }
        
        
//         // dispatch({
//         //     type: types.SET_COMPONENT_RECENT_MAINTENANCE_AUTHS,
//         //     payload: {
//         //         data: recent_auth
//         //     }
//         // })

//         // dispatch({
//         //     type: types.GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST,
//         //     payload: {
//         //         type: 'GET_VEHICLE_BOOKING_SUPPLIER',
//         //         vehicle: vehicle_id,
//         //         range: 6000,
//         //         suppliertype: 'Dealership'
//         //     }
//         // })
//     }
// }

// export const setSupplierAutoCompleteAmount = (value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_SUPPLIER_AUTOCOMPLETE_AMOUNT,

//             query: '?licenseplateno=' + value
//         })
//     }
// }


// export const getSelectedSupplierDetails = (value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_SELECTED_SUPPLIER_DETAILS,
//             payload: {
//                 query: '?supplier_id=' + value
//             }
//         })
//     }
// }

// export const toggleSupplierSuspended = (suspended) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.TOGGLE_SUPPLIE_SUSPENDED_WIN,
//             payload: suspended
//         })
//     }
// }

// export const setSelectedSupplierDetails = (result) => {
//     return (dispatch, getState) => {
//         window.glyco.log('setSelectedSupplierDetails' , result.data)

//         let suspended = result.data[0].suspended
        
//         dispatch(toggleSupplierSuspended(suspended))

//         dispatch({
//             type: types.SET_SUPPLIE_SUSPENDED_STATUS,
//             payload: suspended
//         })

//         dispatch({
//             type: types.SET_SUSPENDED_SUPPLIER_DETAILS,
//             payload: {
//                 supplier: result.data[0],
//                 data: result.data[0].suspended ? null : result.data,
//                 status: result.data[0].suspended ? false : true,
//                 suspendedreason: result.data[0].suspendedreason,
//             }
//         })


//         let sla = result.data[0].slqexpirydate ?  moment(result.data[0].slqexpirydate).diff(moment(), "days") : 0
//         let bee = result.data[0].beecertificateexpdate ?  moment(result.data[0].beecertificateexpdate).diff(moment(), "days") : 0
//         let tax = result.data[0].taxclearancecertificateexpirydate ?  moment(result.data[0].taxclearancecertificateexpirydate).diff(moment(), "days") : 0
        
//         dispatch({
//             type: types.TOGGLE_UPCOMING_SUPPLIER_SUSPENDED_STATUS,
//             payload: {
//                 upcoming: ((sla > 0 && sla < 30) || (bee > 0 && bee < 30) || (tax > 0 && tax < 30)),
//                 sla: (sla > 0 && sla < 30),
//                 bee: (bee > 0 && bee < 30),
//                 tax: (tax > 0 && tax < 30)
//             }
//         })   
//     }
// }

// export const toggleUpcomingSupplierSuspended = (status) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.TOGGLE_UPCOMING_SUPPLIER_SUSPENDED_STATUS,
//             payload: {
//                 upcoming: status,
//                 sla: status,
//                 bee: status,
//                 tax: status
//             }
//         })  
//     }
// }

// export const addSupplierAutoComplete = () => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.ADD_SUPPLIER_AUTOCOMPLETE
//         })
//     }
// }
// export const removeSupplierAutoComplete = () => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.REMOVE_SUPPLIER_AUTOCOMPLETE,
//             payload: getState().poHeader.supplierAutoCompletes.length - 1
//         })
//     }
// }



// export const getSupplierListInRange = (request) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST,
//             payload: {
//                 type: 'GET_VEHICLE_BOOKING_SUPPLIER',
//                 vehicle: request.value,
//                 range: 6000,
//                 suppliertype: 'Dealership'
//             }
//         })
//     }
// }

// export const getCustomerContactsAutoCompleteItems = (vehicle_id) => {
//     //console.log('getCustomerContactsAutoCompleteItems',vehicle_id)
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_PO_CUSTOMER_CONTACTS_AUTOCOMPLETE_DATA,
//             payload: vehicle_id
//         })
//     }
// }


// export const setCustomerContactsAutoCompleteItems = (response) => {
//     //console.log('setCustomerContactsAutoCompleteItems >>>>>>>>>>>>>>>>>>>.',response)
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_PO_CUSTOMER_CONTACTS_AUTOCOMPLETE_DATA,
//             payload: response.data[0].customer.customercontact.map(x => {
//                 return x.contact
//             })
//         })
//     }
// }
// export const getSupplierContactsAutoCompleteItems = (supplier_id) => {

//     //console.log('getSupplierContactsAutoCompleteItems >>>>>>>>>>>>>>>>>>>>>>>>>> ', supplier_id)
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_PO_SUPPLIER_CONTACTS_AUTOCOMPLETE_DATA,
//             payload: supplier_id
//         })
//     }
// }


// export const setSupplierContactsAutoCompleteItems = (response) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_PO_SUPPLIER_CONTACTS_AUTOCOMPLETE_DATA,
//             payload: response.data.map(x => {
//                 return x.contact
//             })
//         })
//     }
// }
// export const setSelectedCustomerContactList = (data) => {

//     //console.log('setSelectedCustomerContactList >>>>>>>>>>>>>>>>>>>>>>>>>> ', data)
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_SELECTED_PO_CUSTOMER_CONTACTS_DATA,
//             payload: data
//         })
//     }
// }
// export const setSelectedSupplierContactList = (data) => {

//     //console.log('setSelectedSupplierContactList >>>>>>>>>>>>>>>>>>>>>>>>>> ', data)
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_SELECTED_PO_SUPPLIER_CONTACTS_DATA,
//             payload: data
//         })
//     }
// }
// export const setBookingSuppliers = (payload) => {

//     window.glyco.log('setBookingSuppliers')
//     window.glyco.log(payload.data)

//     return (dispatch, getState) => {

//         window.glyco.log('getState().poHeader.selectedVehicleDetails[0]')
//         window.glyco.log(getState().poHeader.selectedVehicleDetails.data[0])
//         dispatch({
//             type: types.SET_GEO_MAP_SUPPLIERS,
//             payload: {
//                 markers: payload.data.markers
//             }
//         })
//         dispatch({
//             type: types.SET_VEHICLE_SERVICE_INFORMATION,
//             payload: payload.data.serviceinfo
//         })
//         dispatch({
//             type: types.SET_VEHICLE_CURRENT_BOOKING_INFO,
//             payload: {
//                 bookinginfo: payload.data.bookinginfo
//             }
//         })
//         dispatch({
//             type: types.GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT,
//             payload: {
//                 type: 'GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT',
//                 data: {
//                     vehicle: getState().poHeader.selectedVehicleDetails.data[0],
//                     serviceinfo: payload.data.serviceinfo
//                 }
//             }
//         })
//     }
// }

// export const getSupplierMonthlyUsage = (supplier_id) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_SUPPLIER_MONTHLY_SPEND_USAGE,
//             payload: {
//                 type: 'GET_SUPPLIER_MONTHLY_SPEND_USAGE',
//                 supplier_id: supplier_id
//             }
//         })
//     }
// }
// export const setSupplierMonthlyUsage = (payload) => {
//     return (dispatch, getState) => {
//         //let selectedSupplier = x.selected.value ? this.props.state.selectedSupplierDetails.data.filter(b => b.supplier_id === x.selected.value) : []
      
//         //console.log('payload.data',payload.data)
//         dispatch({
//             type: types.SET_SUPPLIER_MONTHLY_SPEND_USAGE,
//             payload: payload.data
//         })
//         //console.log('payload.data',payload.data)
//         dispatch({
//             type: types.SET_SUPPLIER_MONTHLY_OVER_THE_LIMIT,
//             payload: (parseInt(payload.data[0].curr_usage) >= parseInt(payload.data[0].spendlimit))
//         })
//     }
// }

// export const getSupplierAge = (request, inx) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_SUPPLIER_AGE,
//             payload: {
//                 query: '?supplier_id=' + request.value
//             }
//         })
//     }
// }
// export const setSupplierAge = (data) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_SUPPLIER_AGE,
//             payload: data.data
//         })
//     }
// }

// export const winSelectMaintenanceType = (status) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.TOGGLE_WIN_SELECT_MAINTENANCE_TYPE,
//             payload: status
//         })
//     }
// }

// export const winDuplicateMaintenance = (status) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.TOGGLE_WIN_DUPLICATE_MAINTENANCE,
//             payload: status
//         })
//     }
// }

// export const checkDuplicate = (type, mclass, args) => {
//     return (dispatch, getState) => {
//         //console.log('checkDuplicate.type',type)
//         //console.log('checkDuplicate.args',args)
//         //console.log('checkDuplicate.class',mclass)
        
//         dispatch(winDuplicateMaintenance(false))
//         dispatch({
//             type: types.SET_MAINTENANCE_DUPLICATED_TRANSACTION,
//             payload: []
//         })
//         dispatch({
//             type: types.GET_MAINTENANCE_TRANSACTION_BY_REGISTRATION,
//             payload: {
//                 type: 'CHECK_DUPLICATE_AUTH',
//                 maintenancetype: type.text, 
//                 maintenanceclass: mclass.text, 
//                 vehicle: args.text,
//                 args: args
//             }
//         })
//         // if(type.text === 'Unscheduled Maintenance' || type.text === 'Scheduled Maintenance')
//         // {
//         //     dispatch({
//         //         type: types.GET_MAINTENANCE_TRANSACTION_BY_CLASS,
//         //         payload: {
//         //             maintenancetype: type.text, 
//         //             maintenanceclass: mclass.text, 
//         //             vehicle: args.text
//         //         }
//         //     })
//         // }
//         // else
//         // {
//         //     dispatch({
//         //         type: types.GET_MAINTENANCE_TRANSACTION_BY_REGISTRATION,
//         //         payload: {
//         //             type: 'CHECK_DUPLICATE_AUTH',
//         //             maintenancetype: type.text, 
//         //             maintenanceclass: mclass.text, 
//         //             vehicle: args.text
//         //         }
//         //     })
//         // }
//     }
// }


// export const checkDuplicateDone = (payload, response) => {
//     return (dispatch, getState) => {
//         //console.log('response. checkDuplicateDone',response)
//         //console.log('response. checkDuplicateDone',payload)
//         if(response.length){
//             dispatch(winDuplicateMaintenance(true))
//             dispatch(setVehicleAutoCompleteSelected({text: '', value: null}))
//             dispatch({
//                 type: types.SET_MAINTENANCE_DUPLICATED_TRANSACTION,
//                 payload: response
//             })
//         }
//         else {
//             dispatch(setVehicleAutoCompleteSelected(payload.args))
//             dispatch(getSelectedVehicleDetails(payload.args.value))
//         }
//     }
// }

// export const setMaintenanceHistoryPDF = (data) => {


//     window.glyco.log('setMaintenanceHistoryPDF')
//     window.glyco.log(data)

//     let link = ''
   
//     const saveByteArray = (function () {
//         let a = document.createElement('a')
//         document.body.appendChild(a)
//         a.style = 'display: none'

//         return (data, name) => {
//             const blob = new Blob(data, { type: 'application/pdf' })
//             const url = window.URL.createObjectURL(blob)
//             link = url
//         }
//     }())

//     saveByteArray([data.data.Body], 'Vehicle Maintenance History.pdf')
    
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT,
//             payload: {
//                 preview: link,
//                 filename: data.data.key.replace('quotes/','')
//             }
//         })
//     }
// }
