import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AppBar, Tabs, Tab } from '@mui/material'

class _tabs extends Component {
    render() {
        return <div>
            <AppBar position="static">
                <Tabs value={this.props.value}
                initialSelectedIndex={0}
                onChange={
                    (event, value) => {
                        this.props.onChange(value)
                        //this.props.setTabInx = value
                    }
                }>
                {
                    this.props.tabs.map(x => {
                        return <Tab style={{minWidth: `${100/this.props.tabs.length}%`}} label={x} id={x} />
                    })
                }
                </Tabs>
            </AppBar>
            <div>
                {this.props.content}
            </div>
      </div> 
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.empty }
    }
}

export default connect(mapStateToProps)(_tabs)