import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent, Table, IconButton } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import ReactQuill from 'react-quill'

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import JSReport from '../components/JSReport/component'
import MinMap from '../components/MinMap/component'


import Checkbox  from '@mui/material/Checkbox';

import CardControl from './card'

import * as actions from './actions'
import * as styles from './styles'
import * as config from '../config'
import { service_only } from './props'

class ViewVehicle extends Component {
    componentDidMount() {
        //console.log('getComponentVehicleDate >>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
        this.props.actions.getVehicleMapoServicePlan(this.props.serviceinterval,this.props.mmcode)
    }

    getView() {
        //console.log('this.data >>>>>>>>>>>>>>',this.data)
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <strong></strong>
                                <div>Items suggested for this service</div>
                                <hr/>
                                <div className="table-responsive-md">
                                    <Table className="table table-hover text-nowrap mb-0" style={{width: '100% !impoertant'}}>
                                        <thead>
                                            <tr>
                                                <th style={{width: '20% !impoertant'}}>Description</th>
                                                <th style={{width: '10% !impoertant'}}>Part No</th>
                                                <th style={{width: '10% !impoertant'}}>Quantity</th>
                                                <th style={{width: '10% !impoertant'}}>Price </th>
                                                <th style={{width: '10% !impoertant'}}>Interval</th>
                                                <th style={{width: '10% !impoertant'}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.data.map((x,i) => {
                                                    return <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                        <td>{x.description}</td>
                                                        <td>{x.part_no}</td>
                                                        <td>{x.part_qty}</td>
                                                        <td>{x.part_price}</td>
                                                        <td>{x.service_info_interval}</td>
                                                        <td><Checkbox
                                                            key={'checkbox_' + i}
                                                            id={'Checkbox' + i}
                                                            checked={x.checked}
                                                            onChange={
                                                                (event, isInputChecked) => {
                                                                    this.props.actions.setVehicleMapoServicePlanStatus(i, isInputChecked, x)
                                                                }
                                                            } /></td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
           
        </div>
    }

    getLayout() {
        return <div>
            <CardControl 
                title={'Service Basket'}
                subtitle={'Items suggested for this service'}
                content={this.getView()}
                callapse={true}
            />
         </div> 
    }

    render() {
        this.data = []
        try {
            this.data = this.props.state.serviceplan
        } catch (error) {

        }
        //console.log('this.data.serviceplan  ',this.data )
        return this.data.length ? this.getLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.controls
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewVehicle)