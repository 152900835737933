import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"

import Icon from '../../Icon/component'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import AutoComplete from '../../../controls/autocomplete'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'


import * as actions from './actions'
import * as styles from './styles'

class POBookingItems extends Component {
    componentDidMount() {
        this.props.actions.setupComponent()
        try {
            const quote = this.props.workflow_quote_data
            if (quote.length) {
                this.props.actions.loadQuoteData(quote)
            }
        } catch (err) { }
    }

    getCommands(row) {
        //const id = row.original[component.component_field.filter(x => x.key)[0].name] 

        let commands = []

        commands.push(
            <IconButton key={'command_remove_' + row.index} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.removeSingleRow(row.index)
                    }
                }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  iclass={'material-icons'} iname={'delete_forever'} />
            </IconButton>
        )
        return commands
    }

    getValue(row, field) {
        switch (field.type) {
            case 'text':
                return <TextField
                    key={'field_' + row.index + '_' + field.name}
                    id={'id_field_' + row.index + '_' + field.name}
                    hintText={'Enter ' + field.name}
                   // style={styles.textBox}
                    variant="outlined"
                    disabled={this.props.read_only}
                    value={this.props.state.grid[row.index][field.name]}
                    onChange={
                        (event, value) => {
                            this.props.actions.setInputValue(field.name, row.index, event.target.value, this.vattable, this.auto_rebill_tyres)
                        }
                    }
                />
            case 'numeric':
                return <TextField
                    key={'key_field_' + row.index + '_' + field.name}
                    id={'id_field_' + row.index + '_' + field.name}
                    hintText={'Enter ' + field.name}
                  //  inputStyle={styles.textBoxInput}
                    // style={styles.textBox}
                     variant="outlined"
                    disabled={this.props.read_only}
                    value={this.props.state.grid[row.index][field.name]}
                    onChange={
                        (event, value) => {
                            this.props.actions.setInputValue(field.name, row.index, event.target.value, this.vattable, this.auto_rebill_tyres)
                        }
                    }
                />
            case 'toggle':
                return this.props.read_only
                    ? <TextField
                        key={'field_' + row.index + '_' + field.name}
                        id={'field_' + row.index + '_' + field.name}
                        hintText={' ' + field.name}
                        style={styles.textBox}
                        disabled={this.props.read_only}
                        value={this.props.state.grid[row.index][field.name] ? 'Yes' : 'No'}
                    />
                    : <Checkbox
                        key={'field_' + row.index + '_' + field.name}
                        id={'field_' + row.index + '_' + field.name}
                        
                        style={styles.toggle}
                        trackStyle={{
                            backgroundColor: 'gray'
                        }}
                        trackSwitchedStyle={this.props.read_only ? {
                            backgroundColor: 'gray'
                        }
                            : {
                                backgroundColor: 'green'
                            }}
                        disabled={this.props.read_only}
                        checked={this.props.state.grid[row.index][field.name]}
                        onChange={
                            (event, isInputChecked) => {
                                this.props.actions.setInputValue(field.name, row.index, !this.props.state.grid[row.index][field.name], this.vattable, this.auto_rebill_tyres)
                            }
                        } />
            case 'action':
                return < Button className={"global_button"}
                    secondary={true}
                    key={'field_' + row.index + '_' + field.name}
                    id={'field_' + row.index + '_' + field.name}
                    label={''}
                    icon={<Icon iclass={'material-icons'} iname={'history'} />}
                    onClick={(event) => {
                        this.props.actions.openHistory(row.index)
                    }}
                    ><Icon iclass={'material-icons'} iname={'history'} /></Button>
            case 'select':
                return this.props.read_only
                    ? <TextField
                        key={'field_' + row.index + '_' + field.name}
                        id={'field_' + row.index + '_' + field.name}
                        hintText={'Enter ' + field.name}
                        style={styles.textBox}
                        disabled={this.props.read_only}
                        value={this.props.state.grid[row.index][field.name]}
                    />
                    : <AutoComplete
                        key={'field_' + row.index + '_' + field.name}
                        id={'id_field_' + row.index + '_' + field.name}
                        //hintText={'Select ' + field.name}
                        filter={AutoComplete.fuzzyFilter}
                        fullWidth={true}
                        openOnFocus={true}
                        hintText={this.props.state.grid[row.index][field.name] ? this.props.state.grid[row.index][field.name] : ''}
                        //searchText={this.props.state.grid[row.index][field.name]}
                        options={this.props.state[field.component]}
                        // dataSourceConfig={{
                        //     text: 'text',
                        //     value: 'value'
                        // }}
                        getOptionLabel={(option) => option.text}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label={window.t.en('Select..')}
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            />
                        )}
                        listStyle={{ maxHeight: 200, overflow: 'auto' }}
                        onChange={
                            (event,request, index) => {
                                this.props.actions.setSelectedLookupValue(field.name, row.index, request.value, request.text)
                            }
                        }
                        onInputChange={
                            (event, searchText, params) => {
                                this.props.actions.getLookupValues(field.component, field.name, row.index, field.related, searchText)
                            }
                        }
                        onOpen={
                            (event) => {
                                this.props.actions.getLookupValues(field.component, field.name, row.index, field.related, '')
                            }
                        }
                    />
            default:
                return ''
        }
    }

    getGridColumns() {
        const fields = this.props.state.fields.filter(x => !x.hide)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.label,
                    accessor: x.name,
                    filterable: false,
                    Filter: ({ filter, onChange }) => (
                        <input
                            onChange={event => onChange(event.target.value)}
                            value={''}
                            style={{
                                width: '100%',
                                height: 25,
                                border: '1px solid #E6E6E6',
                                borderRadius: 25
                            }}
                        />
                    ),
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                        //backgroundColor: x.highlight ? styles.highlight : '',
                    },
                    // style: {
                    //     verticalAlign: 'middle',
                    //     fontSize: 12,
                    //     letterSpacing: 0,
                    //     color: 'black',
                    //     fontWeight: 'normal',
                    //     textAlign: x.align
                    // },
                    Cell: (row) => (
                        this.getValue(row, x)
                    ),
                    Footer: (
                        this.getFooter(x.name)
                    )
                }
            })

        if (!this.props.read_only) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 50,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        return columns
    }


    getFooter(field) {
        const total = field === 'Discount'
            ? _.sumBy(this.props.state.grid, (d) => { return parseFloat((d.DiscountAmount) ? d.DiscountAmount.toString().replace(/,/gi, '') : 0) })
            : _.sumBy(this.props.state.grid, (d) => { return parseFloat((d[field]) ? d[field].toString().replace(/,/gi, '') : 0) })
        return (field == 'Amount' || field == 'VAT' || field == 'Savings' || field == 'Discount')
            ?
            <span>
                <strong>{'Total ' + field + ': '}</strong>
                {
                    parseFloat(total.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            </span>
            :
            <span>
                <strong></strong>{" "}
            </span>
    }

    getGrid() {
        return <div>
            {
                this.props.state.grid_row
                    ? <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            {
                                this.props.state.grid_row.min_values.length
                                    ? <Col xl={6} style={styles.box}>
                                        <strong>{this.props.state.grid_row.min_values[0].suppliername}</strong><br />
                                        <label>{this.props.state.grid_row.min_values[0].maintdescription}</label>
                                        <label>{' @ ' + this.props.state.grid_row.min_values[0].paidamount}</label>
                                        {/* <label>{' On ' + this.props.state.grid_row.min_values[0].capturedate.indexOf('T') > -1 ? this.props.state.grid_row.min_values[0].capturedate.split('T')[0] : ''}</label> */}
                                    </Col>
                                    : <Col xl={6}></Col>
                            }
                            {
                                this.props.state.grid_row.max_values.length
                                    ? <Col xl={6} style={styles.box}>
                                        <strong>{this.props.state.grid_row.max_values[0].suppliername}</strong><br />
                                        <label>{this.props.state.grid_row.max_values[0].maintdescription}</label>
                                        <label>{' @ ' + this.props.state.grid_row.max_values[0].paidamount}</label>
                                        {/* <label>{' On ' + this.props.state.grid_row.max_values[0].capturedate.indexOf('T') > -1 ? this.props.state.grid_row.max_values[0].capturedate.split('T')[0] : ''}</label> */}
                                    </Col>
                                    : <Col xl={6}></Col>
                            }
                        </Row>
                    </Container>
                    : ''
            }
            {
                this.props.read_only
                    ? ''
                    :< Button className={"global_button"} variant="contained"
                        label={'Add Item'}
                        primary={true}
                        onClick={(event) => {
                            this.props.actions.addBlankRow(this.auto_rebill)
                        }}
                        >{window.t.en('Add Item')}</Button>
            }
            <br />
            {/* <Container style={{ margin: 0 }} className="custom-container">

<Row style={styles.colHeader}>
                            {
                                this.props.state.fields.filter(x => !x.hide).map(y => {
                                    return <Col xl={y.col}>{y.label}</Col>
                                })
                            }
                        </Row>
                {
                    this.props.state.grid.map((x, i) => {
                        return <Row>
                            {
                                this.props.state.fields.filter(x => !x.hide).map(y => {
                                    return <Col xl={y.col}>{this.getValue({ index: i }, y)}</Col>
                                })
                            }
                        </Row>
                    })
                }
                <Row style={styles.colFooter}>
                    <Col xl={5}></Col>
                    <Col xl={1}>{'Tot: '}{_.sumBy(this.props.state.grid, (d) => { return parseFloat((d.Price) ? d.Price.toString().replace(/,/gi, '') : 0) }).toFixed(2)}</Col>
                    <Col xl={1}>{'Tot: '}{_.sumBy(this.props.state.grid, (d) => { return parseFloat((d.Total) ? d.Total.toString().replace(/,/gi, '') : 0) }).toFixed(2)}</Col>
                    <Col xl={1}>{'Tot: '}{_.sumBy(this.props.state.grid, (d) => { return parseFloat((d.Savings) ? d.Savings.toString().replace(/,/gi, '') : 0) }).toFixed(2)}</Col>
                    <Col xl={1}>{''}</Col>
                    <Col xl={1}>{'Tot: '}{_.sumBy(this.props.state.grid, (d) => { return parseFloat((d.VAT) ? d.VAT : 0.00) }).toFixed(2)}</Col>
                    <Col xl={2}>{'Tot: '}{_.sumBy(this.props.state.grid, (d) => { return parseFloat((d.Amount) ? d.Amount : 0.00) }).toFixed(2)}</Col>
                </Row>
            </Container> */}
            <ReactTable
                key={'grid_booking_quote'}
                id={'grid_booking_quote'}
                data={this.props.state.grid}
                columns={this.getGridColumns()}
                filterable={false}
                loading={this.props.state.loading}
                manual
                getTrProps={(state, rowInfo) => {
                    return {
                        onClick: (event) => {
                            //this.props.actions.getRelatedRowItem(rowInfo, this.data)
                        }
                    }
                }}
                minRows={1}
                showPagination={false}
                showPaginationBottom={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div>
    }

    render() {
        this.data = this.props.workflow_queue_data
        this.vattable = this.props.vattable
        this.auto_rebill = this.props.auto_rebill
        this.auto_rebill_tyres = this.props.auto_rebill_tyres
        let list = null
        try {
            let result = _.sortBy(this.data, [function (o) { return o.odometer; }])
            result = result.reduce(function (r, a) {
                r[a.odometer] = r[a.odometer] || [];
                r[a.odometer].push(a);
                return r;
            }, Object.create(null));

            list = Object.values(result)
        } catch (err) { }

        this.list = list


        return this.list
            ? this.getGrid()
            : 'Please wait...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingItems
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingItems)