
export const GET_CUSTOMER_STATEMENT_LIST = 'GET_CUSTOMER_STATEMENT_LIST'
export const GET_CONTACTS_CUSTOMER_LIST = 'GET_CONTACTS_CUSTOMER_LIST'
export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const SET_CUSTOMER_STATEMENT_LIST = 'SET_CUSTOMER_STATEMENT_LIST'
export const GEN_CUSTOMER_STATEMENT_PDF = 'GEN_CUSTOMER_STATEMENT_PDF'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const GET_CUSTOMER_TRANSACTION_LIST = 'GET_CUSTOMER_TRANSACTION_LIST'
export const SET_CUSTOMER_TRANSACTION_LIST = 'SET_CUSTOMER_TRANSACTION_LIST'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const GEN_CUSTOMER_CREDIT_NOTE_PDF = 'GEN_CUSTOMER_CREDIT_NOTE_PDF'
export const GEN_CUSTOMER_INVOICE_PDF = 'GEN_CUSTOMER_INVOICE_PDF'
export const GET_ALL_CUSTOMER_TRANSACTION_LIST = 'GET_ALL_CUSTOMER_TRANSACTION_LIST'
export const GET_QUERYTYPE_LIST = 'GET_QUERYTYPE_LIST'
export const SET_CUSTOMER_TRANSACTION_DATA = 'SET_CUSTOMER_TRANSACTION_DATA'
export const SAVE_CUSTOMER_TRANSACTION_DATA = 'SAVE_CUSTOMER_TRANSACTION_DATA'
export const GET_CUSTOMER_CONTACT_LIST = 'GET_CUSTOMER_CONTACT_LIST'
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST'
export const LINK_CONTACT = 'LINK_CONTACT'
export const REMOVE_CONTACT = 'REMOVE_CONTACT'
export const GET_CONTACT_TYPE_LIST = 'GET_CONTACT_TYPE_LIST'
export const GET_TITLE_LIST = 'GET_TITLE_LIST'
export const NEW_CONTACT = 'NEW_CONTACT'
export const EDIT_CONTACT = 'EDIT_CONTACT'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
