import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
       case types.SET_STR_COMPONENT_SUB_VIEW:
        return {
            ...state,
            [action.payload.prop]: action.payload.value
        }
       case types.SET_STR_COMPONENT_RESTRUCTURE_DATA:
        return {
            ...state,
            restructure_veh: action.payload
        }
        default:
            return state
    }
}