import * as props from './props'
import * as types from './types'
import * as cognitoTypes from '../Cognito/types'
import * as gridTypes from '../Grid/types'
import * as formTypes from '../Form/types'
    
export default (state = props, action) => {
    switch (action.type) {
        case types.SET_APP_ACTION:
            return {
                ...state,
                action: action.payload
            }
        case types.SHOW_CONFIRM_DIALOG:
            // //console.log('SHOW_CONFIRM_DIALOG',action.payload.status)
            return {
                ...state,
                dialog: action.payload.status
            }
        case types.TOGGLE_EXPIRY_DIALOG:
            return {
                ...state,
                sessionexpired: action.payload
            }

            return {
                ...state,
                notifications: [...state.notifications, ...action.payload]
            }
        case types.SET_APPLICATION_USER_LOCALE:
            return {
                ...state,
                locale: action.payload
            }
        case types.SET_LOADING_STATUS:
            return {
                ...state,
                loading: action.payload
            }
        case types.GET_USER_AGENT:
            return {
                ...state,
                agent: action.payload.userAgent,
                mobile: action.payload.mobile
            }
        case types.GET_USER_LOCALE:
            return {
                ...state,
                locale: action.payload
            }
        case cognitoTypes.GET_COGNITO_USER_SESSION_FAILED:
            return {
                ...state,
                signedIn: action.payload.valid
            }
        case cognitoTypes.GET_COGNITO_USER_SESSION_SUCCEEDED:
            return {
                ...state,
                signedIn: action.payload.valid,
            }
        case types.SESSION_EXPIRY_TIMER_COUNT_DOWN:
            return {
                ...state,
                countdown: action.payload,
            }
        case types.SET_APP_USER_AUTH_STATUS:
            return {
                ...state,
                signedIn: action.payload,
            }
        case cognitoTypes.COGNITO_USER_AUTHENTICATION_FAILED:
            return {
                ...state,
                signedIn: false
            }
        case types.SET_SHOW_DIALOG_NOTIFICATION_LOGGED_TWICE:
            return {
                ...state,
                loggedTwice: action.payload.status
            }
        case cognitoTypes.COGNITO_USER_AUTHENTICATION_SUCCEEDED:
            return {
                ...state,
                signedIn: false
            }
        case cognitoTypes.ACCOUNT_USER_AUTHENTICATION_SUCCEEDED:
            return {
                ...state,
                signedIn: true
            }
        case cognitoTypes.SIGN_OUT_COGNITO_USER:
            return {
                ...state,
                signedIn: false
            }
        case types.USER_SIGN_OUT_COGNITO_USER:
            return {
                ...state,
                signedIn: false
            }
        case types.SHOW_SESSION_EXPIRED_DIALOG:
            return {
                ...state,
                sessionexpired: true
            }
        case types.RENEW_APPLICATION_CACHE:
            return {
                ...state,
                sessionexpired: false
            }
        default:
            return state
    }
}