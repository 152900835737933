import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.NAVIGATION_MENU_GET_MENU:
            return {
                ...state,
                loading: false
            }
        case types.STATIC_MENU_GET_MENU:
            return {
                ...state,
                loading: false
            }
        case types.TOGGLE_MEGA_MENUS:
            return {
                ...state,
                megamenu: action.payload.status,
                anchorEl: action.payload.anchorEl
            }
        case types.TOGGLE_DASH_MEGA_MENUS:
            return {
                ...state,
                [action.payload.menuName]: action.payload.status,
                [action.payload.anchorName]: action.payload.anchorEl
            }
        
        case types.NAVIGATION_MENU_SET_MENU:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case types.NAVIGATION_MENU_SET_HEADERS:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        case types.STATIC_MENU_SET_MENU:
            return {
                ...state,
                staticMenu: action.payload,
                loading: false
            }
        case types.TOGGLE_RELATIONSHIP_MENU:
            return {
                ...state,
                related: !state.related
            }
            
        case types.SET_SELECTED_DASHBOARD_MENU_ITEM_TOGGLE_STATE:
            return {
                ...state,
                [ action.payload.name]: action.payload.data,
            }
        case types.TOGGLE_RELATIONSHIP_MENU_DRAWER:
            return {
                ...state,
                relatedMenu: action.payload,
            }
        case types.SET_SELECTED_MENU_ITEM_TOGGLE_STATE:
            return {
                ...state,
                data: action.payload,
            }
        case types.SET_SELECTED_SUB_MENU_ITEM_TOGGLE_STATE:
            return {
                ...state,
                data: action.payload,
            }
        case types.SET_USER_WORKFLOW_NOTIFICATIONS_MENUS:
            return {
                ...state,
                notifications_items: action.payload.notifications_items,
                notifications_headers: action.payload.notifications_headers,
            }
        case types.SET_SELECTED_DASHBOARD:
            return {
                ...state,
                [action.payload.name]: action.payload,
            }
        default:
            return state
    }
}