import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_EVENT_PROPS_VALUES:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_COMPONENT_LOAD_EVENT_DATA:
            return {
                ...state,
                ...action.payload
            }
        case types.EMPTY_TYPE:
            return {
                ...state
            }
        case types.RESET_FORM:
            return {
                ...state,
                empty: true,
                tab: 0,view: 'form',
                component_list: [],
                category_list: [],
                operator_list: [],
                escalation_list: [],
                schedule_list: [],
                actions_list: [],
                field_list: [],
                team_list: [],
                user_list: [],
                rules: [],
                actions: [],
                component: '',
                component_id: null,
                category: '',
                operator: '',
                escalation: '',
                action: '',
                action_id: '',
                schedule: '',
                user: '',
                selected_escalation: [],
                operator_text: '',
                operator_id: null,
                field: null,
                field_name: '',
                event_name: '',
                start_date: null,
                end_date: null,
                team: '',
                add_win: false,
                log_win: false,
                saving: false,
                ind_good: null,
                ind_low: null,
                ind_medium: null,
                ind_high: null,
                ind_critical: null,
                workqueue_indicator: [],
                escalation_types: [],
                indicator: null,
                indicator_id: null,
                indicator_name: null,
                grid_fields: [
                    {
                        pk: true,
                        grid: false,
                        name: 'workqueue_id',
                        title: 'workqueue_id',
                        display: 'workqueue_id',
                    },
                    {
                        grid: true,
                        name: 'workqueue_description',
                        title: 'Event Name',
                        display: 'workqueue_description',
                    },
                    {
                        grid: true,
                        name: 'workqueue_user',
                        title: 'Created By',
                        display: 'workqueue_user',
                    },
                    {
                        grid: true,
                        name: 'created_at',
                        title: 'Date Create',
                        display: 'created_at',
                        type: 'dateTime'
                    }
                ]
            }
        default:
            return state
    }
}