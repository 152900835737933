import * as actions from "./actions";
import * as progressBarActions from "../ProgressBar/actions";
import * as requestActions from "../Request/actions";
import * as routerActions from "../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../Request/types";
import * as config from '../../config'
import moment from "moment";


export const getLookupData = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(types.GET_COMPONENT_VEHICLE_MASTER_LOOKUP_DATA),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + `/components/${payload.component}/search${payload.query}`,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'supplieraddress.address'
                }
            }).pipe(
                mergeMap(results => [
                    actions.setLookupData(results.response,payload),
                ])
                , startWith(progressBarActions.progress(false))
                , catchError((error) => console.log(error))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
