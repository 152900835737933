import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab } from '@mui/material'
import GeneralContentView from '../../views/GeneralContentView/component'

import Loading from '../Loading/component'
import Parameters from './parameters'
import Cards from './cards'

import * as actions from './actions'

class CardManagement extends Component {
    componentDidMount() {
        //console.log('setCardData', true)
    }

    render() {
        //console.log("Card Management",this.props.state)
        return <div>
                        <Tabs
                            initialSelectedIndex={0}
                            value={this.props.state.tab}
                            inkBarStyle={{
                                height: 4
                            }}
                            onChange={(event, value) => {
                                ////console.log("Card Management",this.props.state)
                                this.props.actions.changeTabView(value)
                            }}
                        >
                            <Tab style={{minWidth:"50%"}} label={"Card Management"} />
                            {/* <Tab style={{minWidth:"50%"}} label={"Card Parameters"} /> */}
                        </Tabs>
                        <br/>
                        <div>
                            <Box><Cards /></Box>
                            {/* {this.props.state.tab === 0 && <Box><Cards /></Box>} */}
                            {/* {this.props.state.tab === 1 && <Box><Parameters /></Box>} */}
                        </div>
                    </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.cardManagement,
            user: {
                ...state.user
            },
            team: {
                ...state.components['team']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardManagement)