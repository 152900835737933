export const tracker = {
    top: '0px',
    height: '150px'
}

export const sources = {
    position: 'relative',
    top: '0px',
    left: '5px',
    height: '0px',
    zIndex: 10000
}

export const labelTitle = {
    fontWeight: 'bold',
}