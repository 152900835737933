export const GET_ALLOCATION_DEBTORS_LIST = 'GET_ALLOCATION_DEBTORS_LIST'
export const SET_ALLOCATION_DEBTORS_LIST = 'SET_ALLOCATION_DEBTORS_LIST'

export const SET_ALLOCATION_DEBTORS_LOOKUP = 'SET_ALLOCATION_DEBTORS_LOOKUP'
export const SET_ALLOCATION_FIELD_INPUT_VALUE = 'SET_ALLOCATION_FIELD_INPUT_VALUE'

export const GET_ALLOCATION_BUSINESS_LIST = 'GET_ALLOCATION_BUSINESS_LIST'
export const SET_ALLOCATION_BUSINESS_LIST = 'SET_ALLOCATION_BUSINESS_LIST'

export const GET_ALLOCATION_ACCOUNT_LIST = 'GET_ALLOCATION_ACCOUNT_LIST'
export const SET_ALLOCATION_ACCOUNT_LIST = 'SET_ALLOCATION_ACCOUNT_LIST'

export const GET_ALLOCATION_DISCOUNT_LIST = 'GET_ALLOCATION_DISCOUNT_LIST'
export const SET_ALLOCATION_DISCOUNT_LIST = 'SET_ALLOCATION_DISCOUNT_LIST'

export const GET_ALLOCATION_ACCOUNT = 'GET_ALLOCATION_ACCOUNT'
export const SET_ALLOCATION_ACCOUNT = 'SET_ALLOCATION_ACCOUNT'
export const SAVE_SUPPLIER_ALLOCATION_DATA = 'SAVE_SUPPLIER_ALLOCATION_DATA'
export const SAVED_SUPPLIER_ALLOCATION_DATA = 'SAVED_SUPPLIER_ALLOCATION_DATA'

export const GET_SUPPLIER_ALLOCATION_DATA = 'GET_SUPPLIER_ALLOCATION_DATA'
export const SET_SUPPLIER_ALLOCATION_DATA = 'SET_SUPPLIER_ALLOCATION_DATA'

export const SET_CALCULATED_ALLOCATION_DATA = 'SET_CALCULATED_ALLOCATION_DATA'
export const SET_FIELD_ALLOCATION_VALUE = 'SET_FIELD_ALLOCATION_VALUE'

export const GET_ALLOCATE_ALL_DATE = 'GET_ALLOCATE_ALL_DATE'

export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const GET_ALLOCATION_BYREF_SUPPLIER_DATA = 'GET_ALLOCATION_BYREF_SUPPLIER_DATA'

export const SET_ANY_CALCULATED_ALLOCATION_DATA = 'SET_ANY_CALCULATED_ALLOCATION_DATA'
export const FILTER_ALLOCATION_DATA = 'FILTER_ALLOCATION_DATA'

export const SET_REMITTANCE_INVOICE_LIST = 'SET_REMITTANCE_INVOICE_LIST'
export const GET_REMITTANCE_INVOICE_LIST = 'GET_REMITTANCE_INVOICE_LIST'

export const GET_ALLOCATION_MONTH_LIST = 'GET_ALLOCATION_MONTH_LIST'
export const SET_ALLOCATION_MONTH_LIST = 'SET_ALLOCATION_MONTH_LIST'
export const GET_RECEIPT_ALLOCATION_DATA = 'GET_RECEIPT_ALLOCATION_DATA'

export const SAVE_UNALLOCATION_DATA = 'SAVE_UNALLOCATION_DATA'
export const GET_SUPPLIER_TRANSACTIONS = 'GET_SUPPLIER_TRANSACTIONS'
export const GET_ALLOCATION_SUPPLIER_DATA = 'GET_ALLOCATION_SUPPLIER_DATA'
export const SET_SUPPLIER_ALLOCATION_INPUT_DATA = 'SET_SUPPLIER_ALLOCATION_INPUT_DATA'
export const UPDATE_SUPPLIER_TRANSACTION_ALLOCATION = 'UPDATE_SUPPLIER_TRANSACTION_ALLOCATION'