import * as types from './types'

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FML_QUOTATION_ACTIVATION_INPUT_VALUE,
            payload: data
        })
    }
}

export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_FML_QUOTATION_ACTIVATION_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.component + '_id']
            }
        })
        dispatch(setInputValue({prop: payload.component + '_data', value: data}))
    }
}
export const getCustomerData = (customer_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_FML_CUSTOMER_DATA_LIST,
            payload: {
               customer_id:customer_id
            }
        })
    }
}

export const setCustomerData = (response) => {
    //console.log('customerdatax', response.data)
    let customerdata = response.data.length ? response.data[0] : {}
    return (dispatch, getState) => {
        dispatch(setInputValue({prop:'customerdata', customerdata}))
        dispatch(setInputValue({prop:'marketingtype', value:customerdata.marketingtype ? customerdata.marketingtype.marketingtype : null}))
        dispatch(setInputValue({prop:'marketingtype_id',value: customerdata.marketingtype ? customerdata.marketingtype.marketingtype_id : null}))
        dispatch(setInputValue({prop:'send_marketing',value: customerdata.send_marketing}))
    }
}