export const GET_REGISTRATION_CHANGE_VEHICLE_LIST = 'GET_REGISTRATION_CHANGE_VEHICLE_LIST'
export const SET_REGISTRATION_CHANGE_LIST = 'SET_REGISTRATION_CHANGE_LIST'

export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const GET_REGISTRATION_CHANGE_VEHICLE_DETAILS = 'GET_REGISTRATION_CHANGE_VEHICLE_DETAILS'
export const SET_VEHICLE_DETAILS = 'SET_VEHICLE_DETAILS'
export const SAVE_REGISTRATION_CHANGE_DATA = 'SAVE_REGISTRATION_CHANGE_DATA'
export const GET_CHANGE_LIST = 'GET_CHANGE_LIST'
export const SET_CHANGE_LIST = 'SET_CHANGE_LIST'
export const APPROVE_REGISTRATION_CHANGE = 'APPROVE_REGISTRATION_CHANGE'
export const SET_ANY_VALUE_DATA = 'SET_ANY_VALUE_DATA'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const CANCEL_REGISTRATION_CHANGE = 'CANCEL_REGISTRATION_CHANGE'
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
export const GET_REGISTRATION_CHANGE_LIST = 'GET_REGISTRATION_CHANGE_LIST'