
export const suppliers = []
export const users = []

export const preauth = null

export const selectedPurchaseOrderType = {
        errorText: 'Select purchase order type',
        data: null,
        value: null,
        valid: false,
        empty: true,
    }

export const fields = {
    description: {
        errorText: 'Please enter a Description',
        state: 'getState()?.purchaseOrderCapture?.fields?.description?.value',
        value: '',
        valid: false,
        empty: true,
    },
    reference: { 
        errorText: 'Please enter a Reference',
        state: 'getState()?.purchaseOrderCapture?.fields?.reference?.value',
        value: '',
        valid: false,
        empty: true,
    },
    month: {
        errorText: '',
        value: null,
        valid: true,
        empty: true,
    },
    date: {
        errorText: 'Please select a Purchase Order Date',
        value: '',
        state: 'getState()?.purchaseOrderCapture?.fields?.date?.value',
        valid: false,
        empty: true,
    },
      
    firstname: {
        errorText: '',
        value: null,
        valid: true,
        empty: true,
    },
    user_id: {
        errorText: '',
        value: null,
        valid: true,
        empty: true,
    },
    supplier_fktext: {
        errorText: 'Please select a Supplier', // This error should never appear based on the terinary
        state: 'getState()?.purchaseOrderCapture?.fields?.supplier_id?.value ? getState()?.purchaseOrderCapture?.fields?.supplier_fktext?.value : true',
        value: '',
        valid: false,
        empty: true,
    },
    supplier_id: {
        errorText: 'Please select a Supplier',
        state: 'getState()?.purchaseOrderCapture?.fields?.supplier_id?.value',
        value: '',
        valid: false,
        empty: true,
    },
    items: {
        errorText: 'Please add at least one item for the Purchase Order',
        state: 'getState()?.paymentItem?.grid?.length',
        valid: false
      },
     items_description: {
        errorText: 'Please enter a Description',
        state: 'getState()?.paymentItem?.grid?.length ? getState().paymentItem?.grid : true',
        valid: false,
        type: 'table',
        tableFields: ['Description']
    },

    items_unitmeasure: {
        errorText: 'Please enter a Unit',
        state: 'getState()?.paymentItem?.grid?.length ? getState().paymentItem?.grid : true',
        valid: false,
        type: 'table',
        tableFields: ['unitmeasure']
    },

    items_quantity: {
        errorText: 'Please enter a Quantity',
        state: 'getState()?.paymentItem?.grid?.length ? getState().paymentItem?.grid : true',
        valid: false,
        type: 'table',
        tableFields: ['quantity'],
        validations: [
            {
                'schema': 'integer',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a Quantity'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Quantity greater than zero.'
            }]
        
    },

     items_unitprice: {
        errorText: 'Please enter a Unit Price',
        state: 'getState()?.paymentItem?.grid?.length ? getState().paymentItem?.grid : true',
        valid: false,
        type: 'table',
        tableFields: ['unitprice'],
        validations: [
            {
                'schema': 'decimal',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a Unit Price'
            },
            {
                'schema': 'greaterThanZero',
                'required': true,
                'maxLength': null,
                'errorText': 'Please input a valid Unit Price greater than zero.'
            }]
    },

    itemdescription: {
        errorText: 'Please select a GL Account',
        state: 'getState()?.paymentItem?.grid?.length ? getState().paymentItem?.grid : true',
        valid: false,
        type: 'table',
        tableFields: ['itemdescription']
    },
    documents: {
        errorText: 'Please add a document and select document type',
        data: false,
        valid: false,
        empty: true,
        popup: true,
        type: 'documents',
      }

}
