import * as config from '../../../config'

import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'

import * as types from './types'
import * as requestTypes from '../../Request/types'

export const setUserAutoCompleteMenuItems = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_USER_AUTOCOMPLETE_MENU_ITEMS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/search' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setUserAutoCompleteMenuItems(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getVehicleListAutoCompleteMenuItems = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search?client_id='+store.value.accounts.selectedAccount+'&contractactive=true' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setVehicleListAutoCompleteMenuItems(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search?client_id='+store.value.accounts.selectedAccount + payload.query)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search?client_id='+store.value.accounts.selectedAccount, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getSupplierTypes = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_PO_SUPPLIER_TYPES),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/suppliertype/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 200
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setSupplierTypes(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/suppliertype/search=')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/suppliertype/search=', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
export const getSelectedVehicleDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SELECTED_VEHICLE_DETAILS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search?contractactive=true&vehicle_id=' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'customercontract.customercontractderivative,customer.customercontact.contact,maintenancetransaction.maintenancetransactionitem,mmcode,lastposition',

                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setSelectedVehicleDetails(result.response, payload.query),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getSelectedSupplierDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SELECTED_SUPPLIER_DETAILS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplier/search' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'suppliercontact.contact,supplieraddress.address,supplierdocument.document,supplierage',

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setSelectedSupplierDetails(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/supplier/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplier/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const setSupplierAutoCompleteMenuItems = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS),
        delay(0), 
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplier/search' + payload.query ,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
            .pipe(
                mergeMap((result) => [
                    actions.setSupplierAutoCompleteMenuItems(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/supplier/search' + payload.query)
                ])  
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplier/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getSupplierAge = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SUPPLIER_AGE),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplierage/search' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setSupplierAge(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/supplier/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplier/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getCustomerContactsAutoCompleteItems = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_PO_CUSTOMER_CONTACTS_AUTOCOMPLETE_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search?vehicle_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'customer.customercontact.contact',
                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setCustomerContactsAutoCompleteItems(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customercontacts/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontacts/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getSupplierContactsAutoCompleteItems = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_PO_SUPPLIER_CONTACTS_AUTOCOMPLETE_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/suppliercontact/search?supplier_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'contact',
                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setSupplierContactsAutoCompleteItems(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customercontacts/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontacts/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const checkDuplicate = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_MAINTENANCE_TRANSACTION_BY_REGISTRATION),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/maintenancetransaction/other',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
                body: payload
            })
                .pipe(
                mergeMap((result) => [
                    actions.checkDuplicateDone(payload, result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/maintenancetransaction/search?maintenancestatus_fktext=Open&vehicle_fktext=')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenancetransaction/search?maintenancestatus_fktext=Open&vehicle_fktext=', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

    export const checkDuplicateByClass = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
            ofType(types.GET_MAINTENANCE_TRANSACTION_BY_CLASS),
            delay(0)
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/maintenancetransaction/search?maintenancestatus_fktext=Open&vehicle_fktext=' + payload.args.text + '&maintenancetype_fktext=' + payload.type,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'related': 'maintenancetransactionitem',
                    },
                })
                    .pipe(
                    mergeMap((result) => [
                        actions.checkDuplicateDone(payload, result.response),
                        requestActions.requestSucceeded(config.system.api + '/components/maintenancetransaction/search?maintenancestatus_fktext=Open&vehicle_fktext=')
                    ])
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenancetransaction/search?maintenancestatus_fktext=Open&vehicle_fktext=', error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )
    

export const getMaintenanceClass = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_MAINTENANCE_CLASS_ITEMS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/maintenanceclass/search?maintenanceclass=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setMaintenanceClass(payload, result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/maintenancetransaction/search?maintenancestatus_fktext=Open&vehicle_fktext=')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenancetransaction/search?maintenancestatus_fktext=Open&vehicle_fktext=', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )