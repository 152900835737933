export const GET_COMPONENT_SCHEMA = 'GET_COMPONENT_SCHEMA'
export const SET_COMPONENT_SCHEMA = 'SET_COMPONENT_SCHEMA'
export const GET_COMPONENT_RELATIONSHIPS = 'GET_COMPONENT_RELATIONSHIPS'
export const SET_COMPONENT_RELATIONSHIPS = 'SET_COMPONENT_RELATIONSHIPS'
export const GET_COMPONENT_DATA = 'GET_COMPONENT_DATA'
export const SET_COMPONENT_DATA = 'SET_COMPONENT_DATA'
export const SET_COMPONENT_FIELD_INPUT = 'SET_COMPONENT_FIELD_INPUT'
export const GET_COMPONENT_FIELD_LOOKUP_DATA = 'GET_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_COMPONENT_FIELD_LOOKUP_DATA = 'SET_COMPONENT_FIELD_LOOKUP_DATA'
export const VALIDATE_FORM = 'VALIDATE_FORM'
export const RESET_FORM = 'RESET_FORM'
export const SAVE_COMPONENT_DATA = 'SAVE_COMPONENT_DATA'
export const COMPONENT_DATA_SAVED = 'COMPONENT_DATA_SAVED'
export const COMPONENT_DATA_SAVE_FAILED = 'COMPONENT_DATA_SAVE_FAILED'
export const SET_FORM_VALIDATION_RESULT = 'SET_FORM_VALIDATION_RESULT'
export const SET_SELECT_FIELD_PK = 'SET_SELECT_FIELD_PK'