import * as types from './types'
import * as formulas from 'formulajs'
import * as _ from 'lodash'

export const setComponentState = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.INIT_CONFIRM_FML_QUOTATION_REVIEW_STATE,
            payload: data
        })
    }
}

export const setInputValue = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_CONFIRM_FML_QUOTATION_REVIEW_INPUT,
            payload: data
        })
    }
}

export const setVehicleInput = (data) => {
    return (dispatch, getState) => {
        let quotations = getState().workflowQuoteReview.quotations
        //console.log('setVehicleInput quotations', quotations)
        //console.log('setVehicleInput data', data)
        if(data.hasOwnProperty('item')){
            
            //console.log('setVehicleInput item')
            quotations[data.inx][data.prop][data.item]['sellingprice'] = data.value
            quotations[data.inx][data.prop][data.item]['totalprice'] = (parseFloat(quotations[data.inx][data.prop][data.item]['sellingprice']) * parseFloat(quotations[data.inx][data.prop][data.item]['quantity'])).toFixed(2)
        }
        else {
            //console.log('setVehicleInput prop')
            quotations[data.inx][data.prop] = data.value
        }

        quotations[data.inx]['contractperiod'] = quotations[data.inx]['contractperiod'] ? quotations[data.inx]['contractperiod'] : 1
        quotations[data.inx]['roiadjustment'] = quotations[data.inx]['roiadjustment'] ? quotations[data.inx]['roiadjustment'] : 0
        quotations[data.inx]['roipercentage'] = quotations[data.inx]['roipercentage'] ? quotations[data.inx]['roipercentage'] : 0
        quotations[data.inx]['returnoninvestment'] = quotations[data.inx]['returnoninvestment'] ? quotations[data.inx]['returnoninvestment'] : 0

        quotations[data.inx]['vehicleprice'] = (parseFloat(quotations[data.inx]['retailprice']) - parseFloat(quotations[data.inx]['discount'])).toFixed(2);
        quotations[data.inx]['interestrate'] = (parseFloat(quotations[data.inx]['primerate']) + parseFloat(quotations[data.inx]['deviation'])).toFixed(2);
        quotations[data.inx]['rv_amount'] = (parseFloat(quotations[data.inx]['vehicleprice']) * (parseFloat(quotations[data.inx]['rv'])/100)).toFixed(2)
        quotations[data.inx]['tyres'] = (parseFloat(quotations[data.inx]['distancepermonth'] ? quotations[data.inx]['distancepermonth'] : 0) * parseFloat(quotations[data.inx]['tyrescpk'])).toFixed(2);
        quotations[data.inx]['maintenance'] = (parseFloat(quotations[data.inx]['distancepermonth'] ? quotations[data.inx]['distancepermonth'] : 0) * parseFloat(quotations[data.inx]['maintenancecpk'])).toFixed(2);

        const acc_tot = _.sumBy(quotations[data.inx].accessory_list, (d) => { return parseFloat((d.totalprice) ? d.totalprice.replace(/,/gi, '') : 0) }).toFixed(2)
        const sun_tot = _.sumBy(quotations[data.inx].sundry_list, (d) => { return parseFloat((d.totalprice) ? d.totalprice.replace(/,/gi, '') : 0) }).toFixed(2)
        const ser_tot = _.sumBy(quotations[data.inx].service_list, (d) => { return parseFloat((d.totalprice) ? d.totalprice.replace(/,/gi, '') : 0) }).toFixed(2)

        
        quotations[data.inx]['accessory_tot'] = acc_tot
        quotations[data.inx]['service_tot'] = ser_tot
        quotations[data.inx]['sundry_tot'] = sun_tot

        quotations[data.inx]['finance'] = formulas.PMT(((parseFloat(quotations[data.inx]['interestrate']) / 100) / 12), parseInt(quotations[data.inx]['contractperiod']), -(parseFloat(quotations[data.inx]['vehicleprice']) + (parseFloat(acc_tot) + parseFloat(sun_tot))), parseInt(quotations[data.inx]['rv_amount']), 0).toFixed(2)

        let subtotal = parseFloat(quotations[data.inx]['finance']) + 
        parseFloat(quotations[data.inx]['maintenance']) + 
        parseFloat(quotations[data.inx]['licence']) + 
        parseFloat(quotations[data.inx]['adminfee']) +
        parseFloat(quotations[data.inx]['tyres']) + 
        parseFloat(ser_tot);

        let returnoninvestment = (parseFloat(quotations[data.inx]['roiadjustment']) + (parseFloat(subtotal) * (parseFloat(quotations[data.inx]['roipercentage']) / 100))).toFixed(2)
        
        subtotal = (parseFloat(subtotal) + parseFloat(returnoninvestment)).toFixed(2)


        quotations[data.inx]['returnoninvestment'] = returnoninvestment
        quotations[data.inx]['monthly_excl'] = subtotal
        quotations[data.inx]['monthly_vat'] = (parseFloat(subtotal) * (0.15)).toFixed(2)
        quotations[data.inx]['monthly_incl'] = (parseFloat(subtotal) * (1.15)).toFixed(2)

        //console.log('setVehicleInput quotations', quotations)
        //console.log('setVehicleInput quotations workflowQuoteReview', getState().workflowQuoteReview)
        dispatch(setInputValue({prop: 'quotations', value: quotations}))

    }
}

export const setupWorkflowTaskInput = (data, component, step) => {
    return (dispatch) => {
        dispatch({
            type: types.CONFIRM_FML_QUOTATION_HEADER_DATA,
            payload: {
                step: step,
                component: component,
                selected: data
            }
        })
    }
}


export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        console.log('setLookupDatax comgetponent', component, field, search)
        dispatch({
            type: types.GET_COMPONENT_VEHICLE_MASTER_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
                query: `?${field}=${search}`
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        
        let data = response.data.map(x => {
            return {...x, text: x[payload.field], value: x[payload.component + '_id']}
        })
        console.log('setLookupDatax payload', payload.component)
        console.log('setLookupDatax data', data)
        dispatch(setInputValue({ prop: payload.component + '_data', value: data }))
    }
}