import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
// import TextField from '@mui/material/TextField'
import * as styles from './styles'
import * as actions from './actions'
import PdfViewer from '../../PDF/component'
import Upload from '../../Upload/component'
import Loading from '../../Loading/component'
import ViewVehicle from '../../../controls/view_vehicle'
import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'
import DateControl from '../../../controls/dateControl'
import TextField from '../../../controls/textField'

class DefleetCloseBooking extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
        
    }
    getDaysPast(r_date, c_date) {
        var oneDay = 1000 * 60 * 60 * 24;
        var difference_ms = Math.abs((new Date(c_date)).getTime() - (new Date(r_date)).getTime())
        var diffValue = Math.round(difference_ms / oneDay);

        return Number(diffValue)
    }
    AuthDocument(documents) {
        return <Card
            title={window.t.en('Rebill Documents')}
            subtitle={window.t.en('Rebill supporting documents')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <PdfViewer
                            key={'quotation_viewer_'}
                            documents={documents}
                            height={600}
                            multiple={false}
                            edit={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col><br /></Col>
                </Row>
            </Container>} />
    }
    uploadInvoice(data) {
        return <div><Card
            title={window.t.en('Upload Release Form & Repair Check Sheet')}
            subtitle={window.t.en('Upload signed release form and repair Check Sheet after repairs.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <Upload
                            id={'upload_ctl'}
                            prefix={'Close_Rebill_Maint_Checksheet_' + data.licenseplateno + '_'}
                            header={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={4}>
                        <Checkbox
                            id={'txt_isinvoice_uploaded'}
                            key={'txt_isinvoice_uploaded'}
                            name={'txt_isinvoice_uploaded'}
                            field={'isinvoice_uploaded'}
                            checked={this.props.state.isinvoice_uploaded}
                            labelPlacement={'end'}
                            label={window.t.en('Please indicate if invoice is uploaded')}
                            // setComponentFieldInput={
                            //     this.props.actions.setValue
                            // }
                            onCheck={(checked) => {
                                this.props.actions.setValue('isinvoice_uploaded', checked)
                            }}
                        />
                    </Col>
               {
               this.props.state.isinvoice_uploaded ?  
                    <Col xl={4}>

                                <TextField
                                    id={'txtchecksheet_completedby'}
                                    label={'Enter supplier invoice number...'}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.supplier_invoicenumber}
                                    onChange={(event) => {
                                        this.props.actions.setValue('supplier_invoicenumber', event.target.value)
                                    }}
                                />
                    </Col>
                : ''
                }
                {this.props.state.days_over > 0 ?
                            <Col xl={4}>
                                <Checkbox
                                    id={'txt_penaltydocuments_uploaded'}
                                    key={'txt_penaltydocuments_uploaded'}
                                    name={'txt_penaltydocuments_uploaded'}
                                    field={'penaltydocuments_uploaded'}
                                    checked={this.props.state.penaltydocuments_uploaded}
                                    labelPlacement={'end'}
                                    label={window.t.en('Please indicate if penalty supporting documents are uploaded')}
                                    // setComponentFieldInput={
                                    //     this.props.actions.setValue
                                    // }
                                    onCheck={(checked) => {
                                        this.props.actions.setValue('penaltydocuments_uploaded', checked)
                                    }}
                                />
                            </Col>
                     : ''}
                    <Col xl={4} style={{marginTop:'0px'}}>
                                <TextField
                                    id={'txtchecksheet_completedby'}
                                    label={'Enter Completed By'}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.checksheet_completedby}
                                    defaultValue={this.props.state.user.firstname + ' ' + this.props.state.user.lastname}
                                    onChange={(event) => {
                                        this.props.actions.setValue('checksheet_completedby', event.target.value)
                                    }}
                                />
                    </Col>
                    <Col xl={4}  style={{marginTop:'-15px'}}>
                                <DateControl
                                    id={'txtchecksheet_completeddate'}
                                    key={'txtchecksheet_completeddate'}
                                    name={'txtchecksheet_completeddate'}
                                    label={window.t.en('Select completed date')}
                                    field={'checksheet_completeddate'}
                                    fullWidth={true}
                                    value={this.props.state.checksheet_completeddate}
                                    onSelect={(args) => {
                                        let value = args.toString().trim()
                                        const now = moment(new Date())
                                        let date = new Date(moment(value).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))

                                        this.props.actions.setValue('checksheet_completeddate', date)
                                    }}
                                    type={'date'}
                                />
                    </Col>
                    <Col xl={4} style={{paddingTop:'0px'}}>
                                <TextField
                                    id={'txtchecksheet_odometer'}
                                    label={'Enter Odometer Reading'}
                                    fullWidth={true}
                                    multiLine={false}
                                    variant="outlined"
                                    value={this.props.state.odometer}
                                    onChange={(event) => {
                                        this.props.actions.setValue('odometer', event.target.value)
                                    }}
                                />
                    </Col>
                </Row>
            </Container>

            } />
            {this.supportingComments()}
        </div>
    }
    supportingComments() {
        return <Card
            title={window.t.en('General Comments')}
            subtitle={window.t.en('Please capture necessary comments')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            label={'Enter Comments...'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={this.props.state.comments}
                            onChange={(event) => {
                                this.props.actions.setValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row></Container>} />
    }
    penaltyComments() {
        return <Card
            title={window.t.en('Penalty Comments')}
            subtitle={window.t.en('Please capture necessary comments')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            label={'Enter Comments...'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={this.props.state.supplier_inv_comments}
                            onChange={(event) => {
                                this.props.actions.setValue('supplier_inv_comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row></Container>} />
    }
    getDate(value) {
        const now = moment(new Date())
        let date = new Date(moment(value).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
        return date
    }
    commentsView(steps) {
        return <div><Card
            title={window.t.en('Close Rebill')}
            subtitle={window.t.en('Capture necessary information for closing.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4}>
                                <DateControl
                                    id={'txt_date_in_for_repairs'}
                                    key={'txt_date_in_for_repairs'}
                                    name={'txt_date_in_for_repairs'}
                                    label={window.t.en('Select date in for repairs')}
                                    field={'date_in_for_repairs'}
                                    fullWidth={true}
                                    value={this.props.state.date_in_for_repairs}
                                    onSelect={(args) => {
                                        let value = args.toString().trim()
                                        let date = this.getDate(value)

                                        this.props.actions.setValue('date_in_for_repairs', date)
                                        if (this.props.state.completeddate) this.props.actions.getBusinessDays(moment(date).format("YYYY-MM-DD"), moment(this.props.state.completeddate).format("YYYY-MM-DD"), Number(steps.step3.data.days.value))


                                        //this.props.actions.setValue('completeddate', moment(this.props.state.completeddate).format("YYYY-MM-DD"))
                                        //let daysover = Number(this.props.state.days_in_for_repairs) - Number(steps.step3.data.days.value)
                                        //this.props.actions.setValue('days_over', daysover < 0 ? 0 : daysover)
                                        //this.props.actions.setValue('supplier_inv_amount', daysover < 0 ? Number(daysover) * Number(this.props.state.supplier_rate) : 0.00)


                                    }}
                                    type={'date'}
                                />
                    </Col>
                    <Col xl={4}>
                                <DateControl
                                    id={'txt_completeddate'}
                                    key={'txt_completeddate'}
                                    name={'txt_completeddate'}
                                    label={window.t.en('Select repair completed date')}
                                    field={'completeddate'}
                                    fullWidth={true}
                                    value={this.props.state.completeddate}
                                    onSelect={(args) => {
                                        let value = args.toString().trim()
                                        let date = this.getDate(value)

                                        this.props.actions.setValue('completeddate', date)
                                        if (this.props.state.date_in_for_repairs) this.props.actions.getBusinessDays(moment(this.props.state.date_in_for_repairs).format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD"), Number(steps.step3.data.days.value))


                                        //this.props.actions.setValue('date_in_for_repairs', moment(this.props.state.date_in_for_repairs).format("YYYY-MM-DD"))
                                        //let daysover = Number(this.props.state.days_in_for_repairs) - Number(steps.step3.data.days.value)
                                        //this.props.actions.setValue('days_over', daysover < 0 ? 0 : daysover)
                                        //this.props.actions.setValue('supplier_inv_amount', daysover < 0 ? Number(daysover) * Number(this.props.state.supplier_rate) : 0.00)
                                    }}
                                    type={'date'}
                                />
                    </Col>
                    <Col xl={4} style={{paddingTop:'15px'}}>
                                <TextField
                                    id={'txtdays_in_for_repairs'}
                                    label={'Enter days in for repairs'}
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    variant="outlined"
                                    value={this.props.state.days_in_for_repairs}
                                />
                    </Col>
                    <Col xl={4}>
                                <TextField
                                    id={'txtchecksheet_completedby'}
                                    label={'Enter estimated repair days'}
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    variant="outlined"
                                    value={steps.step3.data.days.value}
                                />
                    </Col>
                    <Col xl={4}>
                                {this.props.state.days_over ? <Checkbox
                                    id={'txt_invoice_supplier'}
                                    key={'txt_invoice_supplier'}
                                    name={'txt_invoice_supplier'}
                                    field={'invoice_supplier'}
                                    checked={this.props.state.invoice_supplier}
                                    labelPlacement={'end'}
                                    label={window.t.en('Charge Supplier')}
                                    // setComponentFieldInput={
                                    //     this.props.actions.setValue
                                    // }
                                    onCheck={(checked) => {
                                        this.props.actions.setValue('invoice_supplier', checked)
                                    }}
                                /> : ''}
                    </Col>
                    <Col xl={4}>
                                <TextField
                                    id={'txtchecksheet_completedby'}
                                    label={'Enter days Over Repair Days'}
                                    fullWidth={true}
                                    multiLine={false}
                                    disabled={true}
                                    variant="outlined"
                                    value={this.props.state.days_over}
                                />
                    </Col>
                </Row>
                {this.props.state.days_over > 0 ?
                    <div>
                        <Row>
                            <Col xl={4}>
                                        <TextField
                                            id={'txtchecksheet_completedby'}
                                            label={'Enter Supplier Rate'}
                                            fullWidth={true}
                                            multiLine={false}
                                            variant="outlined"
                                            value={this.props.state.supplier_rate}
                                            onChange={(event) => {
                                                this.props.actions.setValue('supplier_rate', event.target.value)
                                                this.props.actions.setValue('supplier_inv_amount', Number(this.props.state.days_over) > 0 ? Number(this.props.state.days_over) * Number(event.target.value) : 0.00)

                                            }}
                                        />
                            </Col>
                            <Col xl={4}>
                                        <TextField
                                            id={'txtchecksheet_completedby'}
                                            label={'Enter supplier invoice amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            variant="outlined"
                                            value={this.props.state.supplier_inv_amount}
                                        />
                            </Col>
                        </Row>
                    </div>
                    : ''}
            </Container>} />
            {this.penaltyComments()} </div>

    }
    renderView(data) {
        let step1 = data.step1.data
        let step3 = data.step3.data
        let vehicle = step1.selectedVehicleDetails.data[0]
        return <Container style={{ margin: 0 }} className="custom-container">
            <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
            <Row>
                <Col xl={12}>
                    {this.AuthDocument(data.documents)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.commentsView(data)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.uploadInvoice(vehicle)}
                </Col>
            </Row>
        </Container>
    }

    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
            //console.log("workflow_queue_data", workflow_queue_data)
        } catch (error) { }
        return (
            workflow_queue_data
                ? this.renderView(workflow_queue_data)
                : <Loading Message={'Loading, Please wait...'} />
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetCloseBooking,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetCloseBooking)