export const GET_ACCESSORY_SUPPLIERS_LIST_LOOKUP_DATA = 'GET_ACCESSORY_SUPPLIERS_LIST_LOOKUP_DATA'
export const SET_ACCESSORY_SUPPLIERS_LIST_LOOKUP_DATA = 'SET_ACCESSORY_SUPPLIERS_LIST_LOOKUP_DATA'
export const SET_QUITE_PO_ACCESSORY_SUPPLUERFIELD_VALUE = 'SET_QUITE_PO_ACCESSORY_SUPPLUERFIELD_VALUE'
export const SET_QUITE_PO_ACCESSORY_SUPPLUERFIELD_TEXT = 'SET_QUITE_PO_ACCESSORY_SUPPLUERFIELD_TEXT'
export const SET_ACCESSORY_SUPPLIERS_SELECTED_LOOKUP_DATA = 'SET_ACCESSORY_SUPPLIERS_SELECTED_LOOKUP_DATA'
export const SET_QUITE_PO_FIELD_VALUE = 'SET_QUITE_PO_FIELD_VALUE'
export const GET_SELECTED_SUPPLIER_CONTACTS = 'GET_SELECTED_SUPPLIER_CONTACTS'
export const SET_PO_SELECTED_LOOKUP_DATA = 'SET_PO_SELECTED_LOOKUP_DATA'
export const UPLOAD_RFQ_QUOTE_DOCUMENT = 'UPLOAD_RFQ_QUOTE_DOCUMENT'
export const RESET_UPLOAD_RFQ_QUOTE_DOCUMENT = 'RESET_UPLOAD_RFQ_QUOTE_DOCUMENT'
export const SET_RFQ_QUOTE_DOCUMENT_PREVIEW = 'SET_RFQ_QUOTE_DOCUMENT_PREVIEW'
export const CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW = 'CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW'
export const CLEAR_MOTIVATION_QUOTE_DOCUMENT_PREVIEW = 'CLEAR_MOTIVATION_QUOTE_DOCUMENT_PREVIEW'
export const UPLOAD_MOTIVATION_QUOTE_DOCUMENT = 'UPLOAD_MOTIVATION_QUOTE_DOCUMENT'
export const SET_MOTIVATION_QUOTE_DOCUMENT_PREVIEW = 'SET_MOTIVATION_QUOTE_DOCUMENT_PREVIEW'