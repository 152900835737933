import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'


export const getVehichlesAutoCompleteEpic = (request) => {
  
    window.glyco.log('The get vehicles method fires')

    return (dispatch, getState) => { 
        
                dispatch({
                    type: types.GET_VEHICLES_ACTION,
                    payload: request
                   
                })
    }
       
    
    
}



export const getCustomerAutoCompleteEpic = (request) => {
    
      window.glyco.log('The get customer method fires')
  
      return (dispatch, getState) => { 
          
                  dispatch({
                      type: types.GET_CUSTOMER_ACTION,
                      payload: request
                     
                  })
      }
         
      
      
  }



export const setVehichlesAutoCompleteEpic = (data) => {
    
    window.glyco.log('Set vehicles fires')
    window.glyco.log(data)
  
    return (dispatch, getState) => { 
        
                dispatch({
                    type: types.SET_VEHICLES_ACTION,
                    payload: data.data
                })
    }
               

      
  }


  export const setCustomerAutoCompleteEpic = (data) => {
    
    window.glyco.log('Set CUSTOMERS fires')
    window.glyco.log(data)
  
    return (dispatch, getState) => { 
        
                dispatch({
                    type: types.SET_CUSTOMER_ACTION,
                    payload: data.data
                })
    }
               

      
  }




export const setVehicleAutoCompleteSelected = (request) => {

    window.glyco.log('setVehicleAutoCompleteSelected')
    window.glyco.log(request)

    return (dispatch, getState) => {

        const selectedCustomerData = {

            selectedCustomerName : request.customername,
            selectedCustomer : request

        }
        
           
        dispatch({
            type: types.SET_VEHICLE_CUSTOMER_VALUE,
            payload:  selectedCustomerData
        })
    }
}


export const setRenewalAutoCompleteSelected = (request) => {
    
        window.glyco.log('setRenewalAutoCompleteSelected')
        window.glyco.log(request)
    
        return (dispatch, getState) => {
    
            // const renewalDetail = {
    
            //     vehicleDescription : request.makemodelyear,
            //     vehicleType : request.khulavehicletype,
            //     vehicleStatusCat : request.statuscategory_fktext,
            //     licenceDiscNumber  : request.licensediscno,
            //     expirydate : request.licenceexpirydate,
            //     licencedispatchdate  : request.licencedispatchdate,
            //     transactionfee  : request.licencetransfee,
            //     licencefee  : request.licenceamount,
            //     penaltyfee : request.licencepeniltyamount,
            //     coffee  : request.cofamount,
            //     adminfee  : request.adminfee,
            //     totalamount: '',
            //     poNumber: request.licenceponumber,
            //     odometer: '',
            //     vehicle_id: request.vehicle_id,
            //     customer_id: request.customer_id,
            //     licenceauthority_id: request.licenceauthority_id,
            //     vehicle_fktext: request.vehicle_fktext,
            //     licenceauthority_fktext: request.licenceauthority_fktext,
            //     client_id: request.client_id

               
            //     // country : request.make_model_year,
            //     // province : request.province,
            //     // vehicleDescription : request.make_model_year,
            //     // vehicleDescription : request.make_model_year,
            //     // vehicleDescription : request.make_model_year,
            //     // vehicleDescription : request.make_model_year,
            //     // vehicleDescription : request.make_model_year,

            
    
            // }
            
               
            dispatch({
                type: types.SET_RENEWAL_DETAIL,
                payload:  request
            })
        }
    }


export const setNewCustomer = (request) => {
    
        window.glyco.log('set New Customer')
        window.glyco.log(request)
    
        return (dispatch, getState) => {
    
           
            const newCustomer =  request

            dispatch({
                type: types.SET_NEW_CUSTOMER_VALUE,
                payload: newCustomer
            })
        }
    }
    

    

    export const finalizeTransferEpic = () => {
        
          window.glyco.log('The finalizeTransferEpic fires')
      


          return (dispatch, getState) => { 

                const renewalData = {
                    
                  
                 
                    expirydate : getState().licencerenewal.expirydate,
                    renewdate : getState().licencerenewal.renewdate,
                    transactionfee  : Number(getState().licencerenewal.transactionfee).toFixed(2),
                    licencefee  : Number(getState().licencerenewal.licencefee).toFixed(2),
                    penaltyfee : Number(getState().licencerenewal.penaltyfee).toFixed(2),
                    coffee  : Number(getState().licencerenewal.coffee).toFixed(2),
                    adminfee  : Number(getState().licencerenewal.adminfee).toFixed(2),
                    totalamount: Number(getState().licencerenewal.totalamount).toFixed(2),
                    odometer:  getState().licencerenewal.odometer,
                    vehicle_id: getState().licencerenewal.vehicle_id,
                    customer_id: getState().licencerenewal.customer_id,
                    licenceauthority_id: getState().licencerenewal.licenceauthority_id,
                    licenceplateno: getState().licencerenewal.licenceplateno,
                    licenceauthority: getState().licencerenewal.licenceauthority, 
                    client_id: getState().request.client_id
                    // updateData: getState().licencerenewal.renewalDetail
                    // transferedVehicleData:  getState().vehicletransfer.newCustomer
                }

                // transferData.transferedVehicleData.effectdate =  getState().vehicletransfer.effectdate

                window.glyco.log('The data from getstate')
                window.glyco.log(renewalData)
                
                dispatch({
                    type: types.FINALIZE_VEHICLE_TRANSFER_ACTION,
                    payload: renewalData
                })

          }
             
          
          
      }


      export const setFinalizeRenewalResponse = (data) => {
          window.glyco.log(data)

        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Licence Renewal Successful!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
      }

      export const setInputValue = (value) => {

        window.glyco.log('The date value')
        window.glyco.log(value)

        return (dispatch, getState) => {
            dispatch({
                type: types.SET_DATE_FIELD_INPUT_VALUE,
                payload: value
            })
        }
    }


export const setValue = (prop, value) => {
        return (dispatch, getState) => {
            dispatch({
                type: types.SET_LICENCERENEWAL_FIELD_VALUE,
                payload: {

                    prop: prop,
                    value: value
                }
            })
        }
    } 


    export const calculateTotal = (value) => {

        
        return (dispatch, getState) => {

           let totalamount = getState().licencerenewal.totalamount

           window.glyco.log('The total amount')
           window.glyco.log(totalamount)

            dispatch({
                type: types.SET_CALCULATED_TOTAL,
                payload: {
                    value: Number(value),
                    totalamount: Number(totalamount)

                }
            })
        }
    } 
    



    

