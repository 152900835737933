import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
import Card from '../../../controls/card'

import { Table } from '@mui/material'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'

import Upload from '../../Upload/component'
import * as styles from './styles'
import * as actions from './actions'
import ViewVehicle from '../../../controls/view_vehicle'
import QuoteItems from '../../WorkflowPOHeader/QuoteItems/component'

let executed = false
class DefleetUploadQuotesConfirm extends Component {

    componentDidMount() {
        executed = false
        //this.props.actions.resetComponent()
        const data = this.props.workflow_queue_data.data[0].context_data.data.data.data[0]
        let step1 = data.step1
        //console.log(">>>>>>>>>>> step1")
        //console.log(step1)
        let obj = {
            ...this.props.state.capture,
            defleetAmount: this.props.state.quote.grid ? _.sumBy(this.props.state.quote.grid, (d) => { return parseFloat(d.Amount) }).toFixed(2) : 0,
            quotenumber: this.props.state.uploadquote.quotenumber.value,
            quotedate: new Date(this.props.state.uploadquote.quotedate.value),
            datestamp: new Date(),
            suppliers: this.props.state.uploadquote.quotations,
            quotations: this.props.state.quote.quotations,
            user: this.props.state.user,
            documents: this.props.state.upload.documents
        }
        
        const meta = [
            {
                header: true,
                title: 'Vehicle',
                description: 'Vehicle',
                meta_data: step1.data.registration
            },
            {
                title: 'De-fleet Number',
                description: 'De-fleet Number',
                meta_data: step1.data.defleetNumber
            },
            {
                title: 'Amount',
                description: 'Amount',
                meta_data: obj.defleetAmount
            },
            {
                title: 'Supplier',
                description: 'Supplier',
                meta_data: this.props.state.uploadquote.selected_supplier.supplier_name
            }
        ]
        this.props.actions.setupWorkflowTaskInput(obj, meta, 'defleet', 'tsk_defleet_capture_quote')
    }

    Quotation(data) {
        return <Card 
                    title={window.t.en('Quotation Items')}
                    subtitle={window.t.en('De-fleet quotation items')}
                    content={<Container style={{ backgroundColor: '#fff', margin: '10px'}}>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}></Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Supplier Quote Number:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{this.props.state.uploadquote.quotenumber.value}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Quote Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(this.props.state.uploadquote.quotedate.value).format('YYYY-MM-DD')}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}<br></br></Col>
                            <Col xl={3} style={styles.selectedFieldName}>{this.props.state.uploadquote.selected_supplier.supplier_name}</Col>
                        </Row>
                        {
                            data ?
                            <QuoteItems workflow_quote_data={data} read_only={true}  />
                                : <div>{'No Items Found...'}</div>
                        }
                    </Container>
                }/>
    }

    UploadedDocuments(documents) {
        return <Card 
        title={window.t.en('Uploaded Documents')}
        subtitle={window.t.en('Uploaded supplier documents.')}
        content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {
                                    documents
                                        ? <PdfViewer
                                            key={'document_viewer_uploaded_quotes'}
                                            name={'De-fleet'}
                                            workflow={'defleet'}
                                            task={'tsk_defleet_upload_quotes'}
                                            documents={documents}
                                            edit={false}
                                            workflow_queue_data={null}
                                        />
                                        : <Loading message={'Retrieving Document...'} />
                                }
                            </Col>
                        </Row>
                    </Container>
              } />
    }
    

    getQuotation(quotation) {
        //console.log('getQuotation',quotation)
        //console.log('getQuotation',quotation)
        return  <tr key={'quotation_inx_' + quotation.inx}>
            <td>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={4} style={{marginTop:'15px'}}>
                           <b>Supplier Name:</b> {quotation.supplier_name}
                        </Col>
                        <Col xl={4} style={{marginTop:'0px'}}>
                        <b>Supplier Quote Number:</b> {quotation.quotenumber}
                        </Col>
                        <Col xl={4} style={{marginTop:'0px'}}>
                        <b>Supplier Quote Date:</b> {quotation.quotedate}
                        </Col>
                        <Col xl={12}>
                            <PdfViewer
                                key={'document_viewer_uploaded_quotes'}
                                name={'De-fleet'}
                                workflow={'defleet'}
                                task={'tsk_defleet_upload_quotes'}
                                documents={this.props.state.upload.documents.filter(d => d.inx === quotation.id)}
                                edit={false}
                                workflow_queue_data={null}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <QuoteItems 
                                px={quotation.id}
                                read_only={true}
                                //vattable={vattable} 
                                //auto_rebill={auto_rebill} 
                                //auto_rebill_tyres={auto_rebill_tyres} 
                                //labour_rate={labour_rate} 
                                />
                        </Col>
                    </Row>
                </Container>
            </td>
        </tr>
    }

    ItemsForm() {
        return <Card
            title={window.t.en('Quotation Items')}
            description={window.t.en('Capture quote line items.')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12}>
                                        
                                        <Table className="table" style={{border: '1px solid #DFDFDF'}}>
                                            <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                                            {
                                                this.props.state.uploadquote.quotations.map((x,inx) => { 
                                                    return this.getQuotation({...x, inx: inx}) 
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                        
                                    </Col>
                                </Row>
                    {
                        this.props.state.quote.quotations.length && <Row>
                            <Col xl={9}>
                            </Col>
                            <Col xl={3}>
                                <table style={{width: '100%', fontSize: '16px', fontWeight: '600'}}>
                                    <tr>
                                        <td>Vatable Total</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.quote.quotations.filter(x => x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>Non Vatable Total</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.quote.quotations.filter(x => !x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>VAT</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.quote.quotations, (d) => { return parseFloat((d['VAT']) ? d['VAT'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.quote.quotations, (d) => { return parseFloat((d['Amount']) ? d['Amount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                    }
                            </Container>
                        </Col>
                    </Row>
                </Container>
            } />
    }

    renderView() {
        let workflow_queue_data = null
        let step1 = null
        let vehicle = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.data[0].context_data.data.data.data[0]
            step1 = workflow_queue_data.step1.data
            vehicle = step1.selectedVehicleDetails.data[0]

            //console.log("workflow_queue_data")
            //console.log(workflow_queue_data)
        } catch (error) {}
        return <Container style={{ margin: 0 }} className="custom-container">
        <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
         <Row>
            <Col xl={12}>
                <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
                {/* {this.props.state.quote.grid.length ? this.Quotation(this.props.state.quote.grid) : 'No Quote Items Found!'} */}
            </Col>
        </Row>
        <Row>
            <Col xl={12}>
                {this.ItemsForm()}
            </Col>
        </Row>
        {/* <Row>
            <Col xl={12}>
                {
                    this.props.state.upload.documents.length ?
                        this.UploadedDocuments(this.props.state.upload.documents)
                        : 'Loading Documents...'}
            </Col>
        </Row> */}
    </Container>
    }

    render() {
        //console.log('this.statex', this.props.state)
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.state.capture
        } catch (error) { }
        return workflow_queue_data ? this.renderView() : 'Request Loading ....'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetUploadQuotesConfirm,
            capture: {
                ...state.defleetCaptureQuote
            },
            user: {
                ...state.user
            },
            uploadquote: {
                ...state.defleetUploadQuotes
            },
            quote: {
                ...state.quoteItems
            },
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetUploadQuotesConfirm)