import * as colours from '@mui/material/colors'
import * as config from '../../config'

export const view = (pad) => {
    return pad
        ? {
            width: '100%',
            height: '100%',
            padding: 0,
            margin: 0
        }
        : {
            width: '100%',
            height: '100%',
            padding: 0,
            margin: 0
        }
}

export const scrollbar = {
    backgroundColor: colours.red[500],
    color: colours.red[500],
}

export const spacer = {
    height: '40px',
    width: '100%',
}

export const headerBackground = (image) => {
    return image && image !== null && image.length
        ? {
            height: '30px',
            background: 'url(' + config.system.cdn + '/' + image + ')',
            backgroundSize: 1980,
            //background: 'white',
            //opacity: 0.85,
            //borderBottom: '1px solid black',
            //display: 'flex',
            //position: 'fixed'
        }
        : {
            //borderBottom: '1px solid black',
            height: '30px'
        }
}

export const headerContainer = {
    width: '100%',
    display: 'flex'
}

export const headerIcon = {
    width: 40,
    height: 40,
    fontSize: 40,
    margin: '0 auto'
}

export const headerTitle = {
    // background: 'white',
    //opacity: 0.8,
    fontWeight: 'bold',
    fontSize: '34',
    padding: 5,
    marginRight: 20,
}

export const headerDescription = {
    background: 'white',
    //opacity: 0.8,
    //color: 'white',
    width: '100%',
    fontSize: '34',
    padding: 5,
    borderRight: '1px solid black'
}

export const content = (height) => {
    return height
        ? {
            height: height
        }
        : {
            height: '100%'
        }
}

export const flex = {
    display: 'flex'
}