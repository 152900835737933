import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import AutoComplete from '../../../controls/autocomplete'
import Card from '../../../controls/card'

import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import ReactTable from "react-table"
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import * as compActions from '../../Components/actions'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as styles from './styles'






var view = false
class GENERALLEDGERCASHBOOK extends Component {
    componentDidMount() {
        if (this.props.state.loading) this.props.actions.getComponent('generalledgercashbook')
    }

    updateTotals() {
        this.props.actions.setInputValue('credit', this.calculateTotals().credit)
        this.props.actions.setInputValue('debit', this.calculateTotals().debit)
    }
    
    calculateTotals() {
        var data = this.props.state.data.generalledgercashbook_id.data
        let debit = 0;
        var credit = 0;
        data.map((x, i) => {
            debit += Number(x.debit)
            credit += Number(x.credit)
        });
        return {
            debit: debit.toFixed(2).toString(),
            credit: credit.toFixed(2).toString(),
            difference: (Number(debit) - Number(credit)).toFixed(2).toString()
        }
    }

    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        /* else if (field.name == "pdfstatement") {
            return< Button className={"global_button"} variant="contained" header={false}
                label={'Download'}
                onClick={(event) => {
                    const data = {
                        month: row.value,
                        id: this.props.state.data.customer_id.input
                    }
                    this.props.actions.getCustomerStatementPDF(data)
                }}
        >{window.t.en('xxxxx')}</Button>
        } */
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        }

        return columns
    }

    getGrid() {
        return <div><ReactTable
            key={'grid_' + this.component.name + '_' + this.component.component_id}
            data={this.props.state.data.generalledgercashbook_id.data}
            columns={this.getColumns()}
            defaultPageSize={this.component.grid.paging.pageSize}
            manual
            onFetchData={
                (state) => { }
            }
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        if (this.props.toggle) { }
                    },
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            onResizedChange={(newResized, event) => { }}
            minRows={1}
            pages={this.component.grid.paging.pageCount}
            showPageJump={false}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable></div>
    }


    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <Card
            title={window.t.en('Cash Book')}
            subtitle={window.t.en('Cash Book Postings')}
            content={<Container style={styles.whitediv}>
                <Row>
                    <Col xl={6}>
                        <Row>
                            <Col xl={8}>
                                <div style={styles.fieldTitle}><strong>{'Link Number:'}</strong></div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        id={'dll_transaction'}
                                        key={'dll_transaction'}
                                        hintText={'Search link number...'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        dataSource={this.props.state.data.entrydescription.data}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                this.props.actions.setLookupValue("entrydescription", chosenRequest.value, chosenRequest.text)
                                                window.glyco.log("chosenRequest.value")
                                                window.glyco.log(chosenRequest.value)
                                                this.props.actions.getAllLinkNumberTransactions(chosenRequest.value)
                                                view = true
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.getAllLinkNumbers('')
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.getAllLinkNumbers(search)
                                            }
                                        }
                                    />
                                </div>
                            </Col>
                            <Col xl={4}>
                                < Button className={"global_button"} variant="contained"
                                    // disabled={viewinvoice}
                                    label={'Create New'}
                                    primary={true}
                                    onClick={(event) => {
                                        view = false
                                        this.props.actions.setLookupValue("transactiontype", '', '')
                                        this.props.actions.setLookupValue("transactiondate", '', '')
                                        this.props.actions.setLookupValue("entrydescription", '', '')
                                        this.props.actions.setLookupValue("generalledgermonth", '', '')
                                        this.props.actions.setItems([{
                                            account_id: '',
                                            account: '',
                                            account_text: '',
                                            date: '',
                                            reference: '',
                                            description: '',
                                            debit: 0,
                                            credit: 0,
                                            key: 1,
                                        }])
                                    }}
                                >{window.t.en('Create New')}</Button></Col>
                        </Row>
                        <Row>
                            <Col xl={8}>
                                <div style={styles.fieldTitle}>{'Cash Book/Journal Posting:'}</div>
                                <div style={styles.fieldContent}>
                                    {view ? <TextField
                                        disabled={true}
                                        id={'txt_customertransactionmonth'}
                                        hintText='type'
                                        value={this.props.state.data.transactiontype.input}
                                        onChange={(event, value) => {
                                        }}
                                    /> :
                                        <AutoComplete
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'dll_transaction'}
                                            key={'dll_transaction'}
                                            hintText={'Select posting type...'}
                                            errorText={this.props.state.data.transactiontype.input ? null : '*'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            dataSource={this.props.state.data.transactiontype.data}
                                            onNewRequest={
                                                (chosenRequest, index) => {
                                                    this.props.actions.setLookupValue("transactiontype", chosenRequest.value, chosenRequest.text)

                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.setTransactionTypeList()
                                                }
                                            }
                                            onUpdateInput={
                                                (search, dataSource, params) => {
                                                    this.props.actions.setTransactionTypeList()
                                                }
                                            }
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                        {view ? '' : <Row>
                            <Col xl={8}>
                                <div style={styles.fieldTitle}>{'Month Selection:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                        type="date"
                                        id={'txt_transactiondate'}
                                        hintText='Date'
                                        container='inline'
                                        mode='landscape'
                                        value={this.props.state.data.transactiondate.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('transactiondate', value)
                                            var _date = moment(new Date(value)).format('YYYYMM')//format('MMMM YYYY')  
                                            this.props.actions.setInputValue('generalledgermonth', _date)
                                        }}
                                    />}
                                </div>
                            </Col>
                        </Row>
                        }
                        <Row>
                            <Col xl={8}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Month:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txt_customertransactionmonth'}
                                            hintText='Month'
                                            value={this.props.state.data.generalledgermonth.input}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue('generalledgermonth', value)
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row> </Col>
                    <Col xl={6} style={styles.rightpanel}>
                        <Row><Col xl={6}><br></br></Col>
                        </Row>
                        <Row>
                            <Col xl={8}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'Debit:'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txtdebit'}
                                            hintText='Debit'
                                            value={this.calculateTotals().debit}
                                            onChange={(event, value) => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'Credit:'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txtcredit'}
                                            hintText='Credit'
                                            value={this.calculateTotals().credit}
                                            onChange={(event, value) => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={8}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'Difference:'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txtdifference'}
                                            hintText='Difference'
                                            value={this.calculateTotals().difference}
                                            onChange={(event, value) => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}><br></br></Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <Row>
                            <Col xl={4}>
                                <div style={styles.fieldContainer}>
                                    <div><strong>{'Account'}</strong></div>
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div style={styles.fieldContainer}>
                                    <div><strong>{'Date'}</strong></div>
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div style={styles.fieldContainer}>
                                    <div><strong>{'Reference'}</strong></div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={6}>
                        <Row>
                            <Col xl={4}>
                                <div style={styles.fieldContainer}>
                                    <div><strong>{'Description'}</strong></div>
                                </div>
                            </Col>
                            <Col xl={3}>
                                <div style={styles.fieldContainer}>
                                    <div><strong>{'Debit'}</strong></div>
                                </div>
                            </Col>
                            <Col xl={3}>
                                <div style={styles.fieldContainer}>
                                    <div><strong>{'Credit'}</strong></div>
                                </div>
                            </Col>
                            <Col xl={2}>


                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    this.props.state.data.generalledgercashbook_id.data
                        .map((x, i) => {
                            return <Row>
                                <Col xl={6}>
                                    <Row>
                                        <Col xl={4}>
                                            <div style={styles.fieldContent}>
                                                {view ? <TextField
                                                    disabled={view}
                                                    id={'txt_Account'}
                                                    hintText='Account'
                                                    value={x.account_text}
                                                    onChange={(event, value) => {
                                                    }}
                                                /> : <AutoComplete
                                                    disabled={view}
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'dll_itemaccount'}
                                                    key={'dll_itemaccount'}
                                                    hintText={'Select a account...'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    dataSource={this.props.state.data.accountingitem_id.data}
                                                    onNewRequest={
                                                        (chosenRequest, index) => {
                                                            this.props.actions.setItemValue({ inx: x.key - 1, field: 'account_id', value: chosenRequest.value })
                                                            this.props.actions.setItemValue({ inx: x.key - 1, field: 'account_text', value: chosenRequest.text })
                                                            this.props.actions.setItemValue({ inx: x.key - 1, field: 'account', value: chosenRequest.account })
                                                            this.props.actions.setItemValue({ inx: x.key - 1, field: 'date', value: this.props.state.data.transactiondate.input })
                                                        }
                                                    }
                                                    onClick={
                                                        (event) => {
                                                            this.props.actions.getContractAccountListData('')
                                                        }
                                                    }
                                                    onUpdateInput={
                                                        (search, dataSource, params) => {
                                                            this.props.actions.getContractAccountListData(search)
                                                        }
                                                    }
                                                />}
                                            </div>
                                        </Col>
                                        <Col xl={4}>
                                            <div style={styles.fieldContent}>
                                                {view ? <TextField
                                                    disabled={view}
                                                    id={'txt_transactiondate'}
                                                    hintText='Date'
                                                    value={this.props.state.data.transactiondate.input}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'date', value: this.props.state.data.transactiondate.input })
                                                    }}
                                                /> : <TextField
                                                    type="date"
                                                    disabled={true}
                                                    id={'txt_transactiondate'}
                                                    hintText='Date'
                                                    container='inline'
                                                    mode='landscape'
                                                    value={x.date}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'date', value: value })

                                                    }}
                                                />}
                                            </div>
                                        </Col>
                                        <Col xl={4}>
                                            <div style={styles.fieldContent}>
                                                {<TextField
                                                    disabled={view}
                                                    id={'txtreference'}
                                                    hintText='Reference'
                                                    value={x.reference}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'reference', value: value })
                                                    }}
                                                />}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={6}>
                                    <Row>
                                        <Col xl={4}>
                                            <div style={styles.fieldContent}>
                                                {<TextField
                                                    disabled={view}
                                                    id={'txtitemdescription'}
                                                    hintText='Description'
                                                    value={x.description}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'description', value: value })
                                                    }}
                                                />}
                                            </div>
                                        </Col>
                                        <Col xl={3}>
                                            <div style={styles.fieldContent}>
                                                {<TextField
                                                    disabled={view}
                                                    id={'txtdebit'}
                                                    hintText='Debit'
                                                    value={x.debit}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'debit', value: value })
                                                    }}
                                                />}
                                            </div>
                                        </Col>
                                        <Col xl={3}>
                                            <div style={styles.fieldContent}>
                                                {<TextField
                                                    id={'txtcredit'}
                                                    hintText='Credit'
                                                    value={x.credit}
                                                    disabled={view}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'credit', value: value })
                                                    }}
                                                />}
                                            </div>
                                        </Col>
                                        {view ? '' : <Col xl={2}>
                                            {
                                                this.props.state.data.generalledgercashbook_id.data.length > 1 ?
                                                    < Button className={"global_button"} variant="contained"
                                                        label={'Remove'}
                                                        primary={true}
                                                        onClick={(event) => {
                                                            this.props.actions.removeRow(x.key - 1)
                                                        }}
                                                    >{window.t.en('Remove')}</Button>
                                                    : ''
                                            }

                                        </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        })}
                <Row>
                    <Col xl={7}>
                    </Col>
                    {view ? '' : <Col xl={5}>
                        <Row>
                            <Col xl={9}></Col><Col xl={3}>< Button className={"global_button"}
                                // disabled={viewinvoice}
                                label={'Add Item'}
                                primary={true}
                                onClick={(event) => {
                                    let x = this.props.state.data.generalledgercashbook_id.data[0]
                                    if (x != null) {
                                        if (x.debit) {
                                            this.props.actions.addRow(x.account_id, x.account, x.account_text, x.date, x.reference, x.description, 0, x.debit)
                                        } else {
                                            this.props.actions.addRow(x.account_id, x.account, x.account_text, x.date, x.reference, x.description, x.credit, 0)
                                        }
                                    }
                                    else {
                                        this.props.actions.addBlankRow()
                                    }
                                }}
                            >{window.t.en('Add Item')}</Button></Col>
                        </Row>
                    </Col>
                    }
                </Row>
                <Row>
                    <Col xl={10}></Col>
                    <Col xl={2}>
                        <Row>
                            {view ? '' : <Col xl={12}>
                                {this.calculateTotals().credit == this.calculateTotals().debit && this.calculateTotals().debit > 0 ? < Button className={"global_button"} variant="contained" header={false}
                                    label={'Save Posting'}
                                    onClick={(event) => {
                                        var validationfields =
                                            [
                                                /* {
                                                    value: this.props.state.data.accountingitem_id.input,
                                                    field: "Account"
                                                },
                                                {
                                                    value: this.props.state.data.entrydescription.input,
                                                    field: "Description"
                                                },
                                                {
                                                    value: this.props.state.data.referencecode.input,
                                                    field: "Reference"
                                                }
                                                ,
                                                {
                                                    value: this.props.state.data.credit.input,
                                                    field: "Credit"
                                                } ,
                                                {
                                                    value: this.props.state.data.debit.input,
                                                    field: "Debit"
                                                } */
                                            ]
                                        // if (this.props.state.data.reference.text === true) {
                                        var payload = {
                                            data: this.props.state.data,
                                            user: this.props.state.user
                                        }
                                        this.props.actions.saveData(payload, validationfields, this.props.state.data.generalledgercashbook_id.data)
                                        // }
                                        // if (this.props.state.data.reference.text === false) {
                                        //    this.props.actions.foundDuplicateReference()
                                        // } 
                                    }}
                                >{window.t.en('Save Posting')}</Button> : ''}
                            </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
            } />
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.generalledgercashbook, upload: {
                ...state.upload
            }
            , user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GENERALLEDGERCASHBOOK)