import * as types from './types'
import * as _ from 'lodash'

export const setupWorkflowTaskInput = (data, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data
        }

        window.glyco.log('CONFIRM_INVOICE_AP_HEADER_SELECTION')
        window.glyco.log(payload)

        dispatch({
            type: types.CONFIRM_INVOICE_AP_HEADER_SELECTION,
            payload: payload
        })
    }
}