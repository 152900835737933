import * as types from './types'
import * as appTypes from '../../App/types'
import moment  from 'moment'
import * as formulas from 'formulajs'

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_CASH_BOOK_ARCHIVE_CREATE,
            payload: payload
        })
    }
}

export const getCashBookTransactionList = () => {
    return (dispatch, getState) => {
        let end_date = getState().cashbookArchiveCreate.end_date
        let start_date = getState().cashbookArchiveCreate.start_date
        //console.log('
        // getCashBookTransactionListx', {
        //     end_date: end_date,
        //     start_date: start_date
        // })
        if(end_date && start_date) {
            dispatch({
                type: types.GET_CASH_BOOK_TRASNSACTIONS,
                payload: {
                    type: 'get_cashbook_archive',
                    end: end_date,
                    start: start_date
                }
            })
        }
        
        // dispatch({
        //     type: appTypes.SHOW_NOTIFICATION,
        //     payload: {
        //         title: 'System Notification',
        //         additionalText: 'Password reset successful!',
        //         overflowText: getState().login.fields.email.input,
        //         autoHide: 10000,
        //         timestamp: moment().format('h:mm A')
        //     }
        // })
    }
}

export const setCashBookTransactionList = (response) => {
    return (dispatch, getState) => {
        //console.log('setInterestRateContractsListx', response)
        dispatch(setInputValue('cashbook',response))
        //dispatch(calcTotal())
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_INTEREST_RATE_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_INTEREST_RATE_COMPONENT_LOOKUP_DATA,
            payload: {
                component: component,
                query: search ? '?' + field + '=' + search : '',
                field: field
            }
        })
    }
}

export const getPrimeRate = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_INTEREST_RATE_PRIMERATE
        })
    }
}

export const setPrimeRate = (response) => {
    return (dispatch, getState) => {
        //console.log('interest_ratex',response.data)
        //dispatch(setInputValue('interest_rate',response.data[0].primerate))
        dispatch(setInputValue('primarate',response.data))
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        dispatch(setInputValue(payload.component + '_data', response.data.map(x => {
            return {
                ...x,
                value: x[payload.component + '_id'],
                text: x[payload.field]
            }
        })))
    }
}

export const calcTotal = () => {
    return (dispatch, getState) => {
        let contracts = getState().interestRateCreate.contracts
        let interest_rate = getState().interestRateCreate.interest_rate
        let _contracts = contracts.map(x => {
            let derivative = x.customercontractderivative.length ? x.customercontractderivative[0] : {}
            //console.log(derivative.remainingbillingcount)

            //console.log('interest_ratex',interest_rate)
            //console.log('interest_ratex capitalbalance',derivative.capitalbalance)
            //console.log('interest_ratex remainingbillingcount',derivative.remainingbillingcount)

            if(derivative.interestrate) {
                let xinterestrate = parseFloat(derivative.interestrate) + parseFloat(interest_rate)
                let finance = derivative.interestrate
                ?  formulas.PMT(((parseFloat(xinterestrate) / 100) / 12), derivative.remainingbillingcount, -(derivative.capitalbalance), parseInt(derivative.residualvalue), 0).toFixed(2)
                : '0.00'

                derivative['financemonthlyselling'] = parseFloat(finance).toFixed(2)
                derivative['xinterestrate'] = parseFloat(xinterestrate).toFixed(2)
                
                let sub_total = parseFloat(finance) + 
                parseFloat(derivative.monthlyadminfee) + parseFloat(derivative.tyresmonthlyselling) +
                parseFloat(derivative.maintenancemonthlyselling) + parseFloat(derivative.licenceselling)
                
                let rio = parseFloat(sub_total) * 0.12
                sub_total = parseFloat(sub_total) + parseFloat(rio)

                derivative['monthlyreturnoninvestment'] = parseFloat(rio).toFixed(2)
                derivative['monthlysellingvat'] = parseFloat(sub_total * 0.15).toFixed(2)

                derivative['monthlysellingsubtotal'] = parseFloat(sub_total).toFixed(2)
                derivative['monthlysellingtotal'] = parseFloat(sub_total * 1.15).toFixed(2)
                derivative['monthlysellingvat'] = parseFloat(sub_total * 0.15).toFixed(2)
            }
            return {
                ...x,
                ['customercontractderivative']: [derivative]
            }
        })
        
        dispatch(setInputValue('contracts',_contracts))
        
    }
}
