import * as types from './types'

export const generateSyncError = (fake) => {
    fake.error()

    return {
        type: types.GENERATE_SYNC_ERROR
    }
}

export const generateAsyncError = (fake) => {
    //fake.error()

    return (dispatch, getState) => {
        fake.error()

        dispatch({
            type: types.GENERATE_ASYNC_ERROR
        })
    }
}