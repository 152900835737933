export const SELECT_PURCHASE_ORDER_TYPE = 'SELECT_PURCHASE_ORDER_TYPE'
export const SET_USER_AUTOCOMPLETE_MENU_ITEMS = 'SET_USER_AUTOCOMPLETE_MENU_ITEMS'
export const GET_USER_AUTOCOMPLETE_MENU_ITEMS = 'GET_USER_AUTOCOMPLETE_MENU_ITEMS'
export const SET_USER_AUTOCOMPLETE_SELECTED = 'SET_USER_AUTOCOMPLETE_SELECTED'
export const SET_SUPPLIER_AUTOCOMPLETE_AMOUNT = 'SET_SUPPLIER_AUTOCOMPLETE_AMOUNT'
export const SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS = 'SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS'
export const GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS = 'GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS'
export const SET_SUPPLIER_AUTOCOMPLETE_SELECTED = 'SET_SUPPLIER_AUTOCOMPLETE_SELECTED'
export const ADD_SUPPLIER_AUTOCOMPLETE = 'ADD_SUPPLIER_AUTOCOMPLETE'
export const REMOVE_SUPPLIER_AUTOCOMPLETE = 'REMOVE_SUPPLIER_AUTOCOMPLETE'

export const GET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS = 'GET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS'
export const SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS = 'SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS'
export const SET_VEHICLE_AUTOCOMPLETE_SELECTED = 'SET_VEHICLE_AUTOCOMPLETE_SELECTED'
export const GET_SELECTED_VEHICLE_DETAILS = 'GET_SELECTED_VEHICLE_DETAILS'
export const SET_SELECTED_VEHICLE_DETAILS = 'SET_SELECTED_VEHICLE_DETAILS'
export const GET_SELECTED_SUPPLIER_DETAILS = 'GET_SELECTED_SUPPLIER_DETAILS'
export const SET_SELECTED_SUPPLIER_DETAILS = 'SET_SELECTED_SUPPLIER_DETAILS'
export const SET_SUSPENDED_SUPPLIER_DETAILS = 'SET_SUSPENDED_SUPPLIER_DETAILS'
export const GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST = 'GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST'
export const SET_VEHICLE_SERVICE_INFORMATION = 'SET_VEHICLE_SERVICE_INFORMATION'
export const SET_GEO_MAP_SUPPLIERS = 'SET_GEO_MAP_SUPPLIERS'
export const RESET_COMPONENT = 'RESET_COMPONENT'
export const GET_SUPPLIER_AGE = 'GET_SUPPLIER_AGE'
export const SET_SUPPLIER_AGE = 'SET_SUPPLIER_AGE'
export const GET_PRE_AUTHORISATION_NUMBER = 'GET_PRE_AUTHORISATION_NUMBER'
export const SET_PRE_AUTHORISATION_NUMBER = 'SET_PRE_AUTHORISATION_NUMBER'
export const GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const SET_COMPONENT_VALIDATION_PROPS_FIELDS = 'SET_COMPONENT_VALIDATION_PROPS_FIELDS'
export const SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const SET_VEHICLE_CURRENT_BOOKING_INFO = 'SET_VEHICLE_CURRENT_BOOKING_INFO'
export const SET_COMPONENT_RECENT_MAINTENANCE_AUTHS = 'SET_COMPONENT_RECENT_MAINTENANCE_AUTHS'
export const SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUES = 'SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUES'

export const SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE = 'SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE'
export const CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW = 'CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW'
export const SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW = 'SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW'
export const UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT = 'UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT'

export const CONFIRM_UPLOAD_QUOTES = 'CONFIRM_UPLOAD_QUOTES'
export const DOWNLOAD_DOCUMENT_DISCOUNT_LETTER = 'DOWNLOAD_DOCUMENT_DISCOUNT_LETTER'
export const GENERATE_DEFLEET_SIGNOFF = 'GENERATE_DEFLEET_SIGNOFF'

export const GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE = 'GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE'
export const GET_BOOKING_VEHICLE_SERVICE_PART_BASKET = 'GET_BOOKING_VEHICLE_SERVICE_PART_BASKET'
export const GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER = 'GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER'
export const GET_VEHICLE_LASTPOSITION = 'GET_VEHICLE_LASTPOSITION'

export const SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE = 'SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE'
export const SET_BOOKING_VEHICLE_SERVICE_PART_BASKET = 'SET_BOOKING_VEHICLE_SERVICE_PART_BASKET'
export const SET_DEFLEET_SIGNOFF = 'SET_DEFLEET_SIGNOFF'

export const SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW = 'SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW'
export const SET_QUOTE_ITEMS = 'SET_QUOTE_ITEMS'
export const SET_VEHICLE_LASTPOSITION = 'SET_VEHICLE_LASTPOSITION'
