import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'

import * as itemsActions from '../WorkflowQuoteItemGrid/actions'
import * as quoteActions from '../QuoteHeader/actions'
//import validator from '../../validate'

export const addVehicleBlankRow = (grid_data) => {
    return (dispatch, getState) => {
        grid_data.push({})
        dispatch(setInputValue({prop: 'grid_data', value: grid_data}))
    }
}

export const addVehicleToGrid = (data, inx) => {
    return (dispatch, getState) => {
        let grid_data = getState().workflowQuoteHeader.grid_data
        grid_data[inx] = data
        dispatch(setInputValue({prop: 'grid_data', value: grid_data}))
        dispatch(setTotalAdjustment())
    }
}

export const removeGridRow = (inx) => {
    return (dispatch, getState) => {
        let _grid_data = []
        let grid_data = getState().workflowQuoteHeader.grid_data
        grid_data.map((x,i) => {
            if(i !== inx) _grid_data.push(x)
        })
        //console.log('grid_data', grid_data)
        dispatch(setInputValue({prop: 'grid_data', value: _grid_data}))
    }
}

export const loadContractTypes = (component, step) => {
    return (dispatch, getState) => {
        let contracttype = step === 'stp_fml_quote_capture_quote_details'
            ? 'Full Maintenance Lease'
            : 'Short Term Rental'

        const field = component.component_field.filter(x => x.name === 'contracttype_id')[0]
        const payload = {
            component: component,
            model: 'contracttype',
            field: field,
            related: '',
            query: '?contracttype=' + contracttype,
        }

        dispatch({
            type: types.GET_COMPONENT_FIELD_LOOKUP_VALUE,
            payload: payload
        })
    }
}
export const removeCachedGridRow = (component) => {

    return (dispatch, getState) => {
        // dispatch({
        //     type: types.CLEAR_QUOTE_VEHICLE_BLANK_ROW,
        //     payload: {
        //         component: component.name
        //     }
        // })

        dispatch({
            type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: 'document',
                field: 'content',
                value: '',
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: 'surrogateheader',
                field: 'replace_vehicle',
                data: []
            }
        })
    }
}

export const clearRFQDocument = (component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: 'document',
                field: 'content',
                value: '',
            }
        })
    }
}


export const resetComponent = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_FML_INPUT_DATA
        })
        
        dispatch(itemsActions.setInputValue({prop: 'data', value: []}))
    }
}
export const toggleCustomerValues = (component, toggle) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CUSTOMER_VALUES,
            payload: {
                component: component.name,
                state: toggle
            }
        })
    }
}

export const toggleProps = (props, toggle) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CUSTOM_PROPS_VALUES,
            payload: {
                props: props,
                state: toggle
            }
        })
    }
}

export const togglePropsOp = (props, toggle) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_OPS_CUSTOM_PROPS_VALUES,
            payload: {
                props: props,
                state: toggle
            }
        })
    }
}

export const toggleRateCard = (groupcomponent, mmcodefield, field, toggle) => {
    var now = new Date();
    //console.log('toggleRateCard field',field)
    //console.log('toggleRateCard toggle',toggle)
    //console.log('toggleRateCard mmcodefield',mmcodefield)
    //console.log('toggleRateCard groupcomponent',groupcomponent)
    var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_LOOKUP_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: toggle
            }
        })

        if (toggle) {

            const component = getState().components[field.component.name]
            dispatch({
                type: types.GET_RATECARD_COMPONENT_LOOKUP_PRIME_RATE_DATA,
                payload: {
                    component: component,
                    field: mmcodefield,
                    query: '?contractvehiclecategory_id=' + component.data.contractvehiclecategory_id.input,
                }
            })
        }
        else {
            dispatch({
                type: types.QUOTE_VEHICLE_BLANK_ROW,
                payload: {
                    component: field.component.name,
                    data: []
                }
            })
        }
    }
}


export const getRateCard = (contractvehiclecategory_id, distancepermonth) => {
    var now = new Date();
    return (dispatch, getState) => {
            dispatch({
                type: types.GET_RATECARD_COMPONENT_LOOKUP_PRIME_RATE_DATA,
                payload: {
                    type: 'GET_RATE_CARD',
                    distancepermonth: distancepermonth,
                    contractvehiclecategory_id: contractvehiclecategory_id
                }
            })
    }
}

export const setRateCard = (response, payload) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'grid_data', value: []}))
        dispatch(setInputValue({prop: 'grid_data', value: response}))
        // dispatch({
        //     type: types.GET_RATECARD_COMPONENT_LOOKUP_DATA,
        //     payload: payload
        // })
    }
}


export const setRatecardMMCodes = (response,  payload) => {


    //console.log('setRatecardMMCodes response',response)
    //console.log('setRatecardMMCodes payload',payload)

    const getClosest = _.curry((counts, goal) => {
        return counts
            .reduce((prev, curr) => Math.abs(curr.distancepermonth - goal) < Math.abs(prev.distancepermonth - goal) ? curr : prev);
    });

    let list = []
    const data = payload.data.map(x => {
        const _rv = x.residualvalue // x.distancepermonth === component.data.distancepermonth.input

        window.glyco.log('x.mmcode.imagefilename')
        window.glyco.log(x.mmcode)

        return {
            retailprice: parseFloat(x.mmcode.amount),
            vehicleprice: parseFloat(x.mmcode.rt57price),
            discount: parseFloat(x.mmcode.dprice),
            mm_code: x.mmcode_fktext,
            mmcode_id: x.mmcode_id,
            mmcode_fktext: x.mmcode.makemodelyear,
            distancepermonth: x.distancepermonth,
            deliveryperiod: parseInt(x.deliveryperiod),
            rvpercentage: parseInt(_rv),
            rv_amount: ((parseInt(_rv) / 100) * parseFloat(x.mmcode.amount)).toFixed(2),
            //primerate: parseFloat(primerate.primerate),
            deviation: parseFloat(x.deviation),
            maintcpk: parseFloat(x.mmcode.maintcpk),
            tyrecpk: parseFloat(x.mmcode.tyrescpk),
            imagefilename: x.mmcode.imagefilename,
            manualtotalmaintcpk: parseFloat(x.manualtotalmaintcpk),
            financeexcess: parseFloat(x.financeexcess),
            
            residualvalue: 0,
            //interestrate: parseFloat(primerate.primerate) + parseFloat(x.deviation),
        }
    })

    const mmcodes = data.map(x => {
        return x.mmcode_id
    })

    const distinct = Array.from(new Set(mmcodes))

    for (let x of distinct) {
        const _filter = data.filter(j => j.mmcode_id === x)
        list.push(getClosest(_filter, 2500))
    }

    return (dispatch, getState) => {
        dispatch({
            type: types.QUOTE_VEHICLE_BLANK_ROW,
            payload: {
                component: 'component.name',
                data: list
            }
        })
        //console.log('GET_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES vehicle_list', list)

        dispatch(setInputValue({prop: 'vehicle_list', value: list}))

        //console.log('GET_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES distinct', distinct)
        
        dispatch(setInputValue({prop: 'mmcodes', value: distinct}))
        // dispatch({
        //     type: types.GET_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES,
        //     payload: {
        //         type: 'GET_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES',
        //         mmcodes: distinct,
        //         contracttype: component.data.contracttype_id.input,
        //         distancepermonth: component.data.distancepermonth.input,
        //         contractdistance: component.data.contractdistance.input,
        //         contractperiod: component.data.contractperiod.input
        //     }
        // })
    }
}

export const setMMCodeRvAndTyres = (payload) => {
    window.glyco.log('setMMCodeRvAndTyres')
    window.glyco.log(payload.data)

    return (dispatch, getState) => {
        const component = getState().components['surrogateheader']
        const grid = component.grid.data.map(x => {
            window.glyco.log('x')
            window.glyco.log(x)
            const _data = payload.data.filter(m => m.mmcode === x.mm_code)

            window.glyco.log('_data')
            window.glyco.log(_data)
            return _data.length
            ? {
                ...x,
                rvpercentage: parseInt(_data[0].rv),
                rv_amount: ((parseInt(_data[0].rv) / 100) * parseFloat(x.retailprice)).toFixed(2),
                tyrecpk: parseFloat(_data[0].tyrecpk),
            }
            : x
        })

        window.glyco.log('grid tyrecpk')
        window.glyco.log(grid)

        dispatch({
            type: types.QUOTE_VEHICLE_BLANK_ROW,
            payload: {
                component: component.name,
                data: grid
            }
        })
    }
}

export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        //console.log('LookupDatax get',component,field )
        dispatch({
            type: types.SET_COMPONENT_LOOKUP_DATA_LIST,
            payload: {
                component: component,
                field: field,
                search: search,
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.component + '_id']
            }
        })
        //console.log('LookupDatax res',response.data )
        //console.log('LookupDatax data',data )
        //console.log('LookupDatax comp',payload.component + '_data' )
        dispatch(setInputValue({prop: payload.component + '_data', value: data}))
    }
}

export const toggleStockVehicle = (field, toggle) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_LOOKUP_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: toggle,
            }
        })
    }
}
export const getLookupValue = (model, field) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]

        const param = field.parameters
            ? field.parameters.indexOf('.') > -1
                ? '?' + field.parameters.split('.')[1] + '=' + getState().components[field.parameters.split('.')[0]].data[field.parameters.split('.')[1]].input
                : '?' + field.parameters + '=' + component.data[field.parameters].input
            : ''

        const payload = {
            component: component,
            model: model,
            field: field,
            related: field.related ? field.display.split('.')[0] : '',
            query: param,
        }

        window.glyco.log('getLookupValue')
        window.glyco.log(payload)

        dispatch({
            type: types.GET_WQH_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const getMMLookupValue = (field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MM_COMPONENT_MMCODE_LOOKUP_DATA,
            payload: {
                field: field,
                search: search
            }
        })
    }
}

export const setMMLookupValue = (field, payload) => {
    return (dispatch, getState) => {
        const data = payload.data.map(x => {
            return {
                value: x.mmcode_id,
                text: x.mmcode + ' (' + x.makemodelyear + ')',
            }
        })

        window.glyco.log('setMMLookupValue')
        window.glyco.log(field)
        window.glyco.log(data)
        //dispatch({
        //     type: types.SET_MM_COMPONENT_MMCODE_LOOKUP_DATA,
        //     payload: {
        //         component: field.component.name,
        //         field: field.name,
        //         data: data
        //     }
        // })

            dispatch({
                type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
                payload: {
                    component: field.component.name,
                    field: field.name,
                    data: data
                }
            })
    }
}

export const getLookupSelectionCategory = (model, field, contracttype_id, search) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]
        //console.log('?contracttype_id='+contracttype_id+'&categorydescription=' + search)
        //console.log(component.model)
        dispatch({
            type: types.GET_WQH_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component,
                model: model,
                field: field,
                related: '',
                query: '' //'?contracttype_id='+contracttype_id+'&categorydescription=' + search,
            }
        })
    }
}

export const searchLookupValue = (model, field, search) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]

        const payload = {
            component: component,
            model: model,
            field: field,
            related: '',
            query: '?licenseplateno=' + search,
        }

        dispatch({
            type: types.GET_WQH_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setLookupValue = (field, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_LOOKUP_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name,
                value: text,
            }
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {

    //console.log('setComponentFieldLookupData',payload)
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data
            }
        })
    }
}


export const setComponentFieldLookupValue = (component, field, payload) => {

    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: data[0].value
            }
        })
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name,
                value: data[0].text
            }
        })
    }
}
export const strBlankRow = (component) => {

    return (dispatch, getState) => {
        const contracttype_id = getState().components['contracttype'].data.contracttype_id.input
        var now = new Date();
        var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        const payload = {
            component: component.name,
            data: {
                surrogateheader_id: 0,
                startdate: moment(current).format('DD-MM-YYYY'),
                contractperiod: null,
                discount: null,
                deliveryperiod: null,
                contractdistance: null,
                contracttype_id: contracttype_id,
                interestrate: null,
                vehicleprice: null,
                distancepermonth: null,
                rvpercentage: null,
                deviation: null,
                contractvehiclecategory_id: null,
                mmcode_id: null,
                mmcode_fktext: null,
                tyrecpk: null,
                maintcpk: null,
                manualtotalmaintcpk: null,
                financeexcess: null,
                licenseplateno: null,
                vehicle_id: null //component.data.vehicle_id.input,
            },
        }

        dispatch({
            type: types.QUOTE_VEHICLE_BLANK_ROW,
            payload: payload
        })

        // dispatch({
        //     type: types.GET_QUOTE_VEHICLE_DETAILS,
        //     payload: payload
        // })
    }
}

export const newBlankRow = (component) => {
    let data = component.grid.data
    var now = new Date();
    var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    const payload = {
        component: component.name,
        data: data.concat({
            surrogateheader_id: data.length,
            startdate: moment(current).format('DD-MM-YYYY'),
            contractperiod: null,
            discount: null,
            deliveryperiod: null,
            contractdistance: null,
            contracttype_id: component.data.contracttype_id.input,
            interestrate: null,
            vehicleprice: null,
            distancepermonth: null,
            rvpercentage: null,
            deviation: null,
            contractvehiclecategory_id: component.data.contractvehiclecategory_id.input,
            mmcode_id: null,
            mmcode_fktext: null,
            tyrecpk: null,
            maintcpk: null,
            manualtotalmaintcpk: null,
            financeexcess: null,
            licenseplateno: null, 
            vehicle_id: null //component.data.vehicle_id.input,
        }),
    }

    if (!data.length) {
        return (dispatch, getState) => {
            dispatch({
                type: types.QUOTE_VEHICLE_BLANK_ROW,
                payload: payload
            })

            dispatch({
                type: types.GET_QUOTE_VEHICLE_DETAILS,
                payload: payload
            })
        }
    }
}

export const addBlankRow = (component) => {
    let data = component.grid.data
    var now = new Date();
    var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    const payload = {
        component: component.name,
        data: data.concat({
            surrogateheader_id: data.length,
            startdate: moment(current).format('DD-MM-YYYY'),
            contractperiod: null,
            discount: null,
            deliveryperiod: null,
            contractdistance: null,
            contracttype_id: component.data.contracttype_id.input,
            interestrate: null,
            vehicleprice: null,
            distancepermonth: null,
            rvpercentage: null,
            deviation: null,
            contractvehiclecategory_id: component.data.contractvehiclecategory_id.input,
            mmcode_id: null,
            mmcode_fktext: null,
            tyrecpk: null,
            maintcpk: null,
            maintcpk: null,
            manualtotalmaintcpk: null,
            financeexcess: null,
            licenseplateno: null,
            vehicle_id: null,
            mm_code: null,
            retailprice: null,
            quotefor_id: null,
        }),
    }

    if (data.length < 3) {
        return (dispatch, getState) => {
            dispatch({
                type: types.QUOTE_VEHICLE_BLANK_ROW,
                payload: payload
            })

            dispatch({
                type: types.GET_QUOTE_VEHICLE_DETAILS,
                payload: payload
            })
        }
    }
}


export const addSubAssetBlankRow = () => {
    return (dispatch, getState) => {
        let secondary_asset = getState().workflowQuoteHeader.secondary_asset
        secondary_asset.push({
            description: '',
            retail_price: 0,
            selling_price: 0,
            rv: 0,
            rv_amount: 0,
            asset_price: 0
        })
        dispatch(setInputValue({prop: 'secondary_asset', value: secondary_asset }))
    }
}

export const removeSubAssetBlankRow = (inx) => {
    return (dispatch, getState) => {
        let secondary_asset = getState().workflowQuoteHeader.secondary_asset
        delete secondary_asset[inx]
        secondary_asset = secondary_asset.filter(x => x) 
        dispatch(setInputValue({prop: 'secondary_asset', value: secondary_asset }))
    }
}

export const setSubAssetInputValue = (field, inx, value) => {
    return (dispatch, getState) => {
        let secondary_asset = getState().workflowQuoteHeader.secondary_asset
        secondary_asset[inx][field] = value
        dispatch(setInputValue({prop: 'secondary_asset', value: secondary_asset }))
    }
}
export const calSubAssetRv = (field, inx, value) => {
    return (dispatch, getState) => {
        let secondary_asset = getState().workflowQuoteHeader.secondary_asset
        secondary_asset.map((x,i) => {
            let rv_amount = !isNaN(x.sellingprice) && !isNaN(x.rv) ? (parseFloat(x.sellingprice) * parseFloat((parseFloat(x.rv) / 100))).toFixed(2) : 0
            let asset_price = !isNaN(x.sellingprice) && !isNaN(x.rv) ? (parseFloat(x.sellingprice) * parseFloat(((100 - parseFloat(x.rv)) / 100))).toFixed(2) : x.asset_price
            dispatch(setSubAssetInputValue('rv_amount', i, rv_amount ))
            dispatch(setSubAssetInputValue('asset_price', i, asset_price ))
        })
        dispatch(updateGridInputValue())
    }
}

export const setQuoteVehicleDetails = (component, field, payload) => {

    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data
            }
        })
    }
}

export const setWorkings = (component, field, payload) => {

    const data = {
        component: component,
        data: payload.data ? [payload.data[0]] : []
    }


    return (dispatch, getState) => {
        dispatch({
            type: types.QUOTE_VEHICLE_BLANK_ROW,
            payload: data
        })
    }
}

export const confirmSelection = (step, component) => {

    return (dispatch, getState) => {
        let quoteHeader = getState().quoteHeader

        const data = [{
            form: {
                yardlist: quoteHeader.yardlist,
                yardlist_vehicle: quoteHeader.selectedVehicle,
                contracttype: component.data.contracttype_id.text,
                customer: component.data.customer_id.text,
                vehicleprice: component.data.vehicleprice.input,
                retailprice: component.data.retailprice.input,
                distancepermonth: component.data.distancepermonth.input,
                rvpercentage: component.data.rvpercentage.input,
                mmcode_id: component.data.mmcode_id.input,
                vehicle_id: component.data.vehicle_id.input,
                surrogateheader_id: component.data.surrogateheader_id.input,
                mmcode_fktext: component.data.mmcode_id.text,
                mm_code: component.data.mm_code.input,
                licenseplateno: component.data.vehicle_id.text,
                startdate: component.data.startdate.input,
                contractperiod: component.data.contractperiod.input,
                rv_amount: component.data.rv_amount.input,
                interestrate: component.data.interestrate.input,
                tyrecpk: component.data.tyrecpk.input,
                comments: component.data.comments.input,
                service_tot: component.data.service_tot.input,
                tyres: component.data.tyres.input,
                adminfee: component.data.adminfee.input,
                monthly_vat: component.data.monthly_vat.input,
                waiver: component.data.waiver.input,
                quotedate: component.data.quotedate.input,
                quotenumber: component.data.quotenumber.input,
                contractvehiclecategory_id: component.data.contractvehiclecategory_id.input,
                supplier_id: component.data.supplier_id.input,
                categorydescription: component.data.contractvehiclecategory_id.text,
                replace_vehicle: component.data.replace_vehicle.input,
                suppliername: component.data.suppliername.input,
                deliveryperiod: component.data.deliveryperiod.input,
                contractdistance: component.data.contractdistance.input,
                discount: component.data.discount.input,
                deviation: component.data.deviation.input,
                rfq: component.data.rfq.input,
                accessory_tot: component.data.accessory_tot.input,
                finance: component.data.finance.input,
                licence: component.data.licence.input,
                roi: component.data.roi.input,
                monthly_incl: component.data.monthly_incl.input,
                stock_vehicle: component.data.stock_vehicle.input,
                months: component.data.months.input,
                customer_id: component.data.customer_id.input,
                primerate: component.data.primerate.input,
                maintcpk: component.data.maintcpk.input,
                pdf: component.data.pdf.input,
                sundry_tot: component.data.sundry_tot.input,
                maint: component.data.maint.input,
                service: component.data.service.input,
                monthly_excl: component.data.monthly_excl.input,
                monthly_str: component.data.monthly_str.input,
                enddate: component.data.enddate.input,
                days: component.data.days.input,
                contracttype_id: component.data.contracttype_id.input,
                manualtotalmaintcpk: component.data.manualtotalmaintcpk.input,
                financeexcess: component.data.financeexcess.input,
                accesskm: component.data.accesskm.input,
                validity: component.data.validity.input,
                quotefor_id: component.data.quotefor_id.input,
                color_id: component.data.color_id.input,
                color: component.data.color_id.text,
                opex: component.data.opex.input,

            },
            grid: component.grid.data
        }]

            const meta = component.data.vehicle_id.text
            ?  [
                {
                    title: 'Contract Type',
                    description: 'Contract Type',
                    meta_data: component.data.contracttype_id.text
                },
                {
                    title: 'Contract Category',
                    description: 'Contract Category',
                    meta_data: component.data.contractvehiclecategory_id.text
                },
                {
                    title: 'Replace Vehicle',
                    description: 'Replace Vehicle',
                    meta_data: component.data.vehicle_id.text
                },
                {
                    title: 'Comments',
                    description: 'Comments',
                    meta_data: component.data.comments.input
                }
            ]
            :  [
                {
                    title: 'Contract Type',
                    description: 'Contract Type',
                    meta_data: component.data.contracttype_id.text
                },
                {
                    title: 'Contract Category',
                    description: 'Contract Category',
                    meta_data: component.data.contractvehiclecategory_id.text
                },
                {
                    title: 'Comments',
                    description: 'Comments',
                    meta_data: component.data.comments.input
                }
            ]
            
        const payload = {
            step: step,
            component: component.name,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_QUOTE_HEADER_SELECTION,
            payload: payload
        })
    }
}

export const resetSelection = (step, component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_QUOTE_HEADER_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: []
            }
        })
    }
}

export const setValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value
            }
        })
    }
}


export const setQuoteHeaderValue = (component, field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_QUOTE_HEADER_FIELD_INPUT_VALUE,
            payload: {
                component: component,
                field: field,
                value: value
            }
        })
    }
}

export const decimalFormat = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: parseInt(value).toLocaleString()
            }
        })
    }
}

export const setEndMonth = (field, value) => {
    return (dispatch, getState) => {
        let enddate = getState().components[field.component.name].data.startdate.input
        let days = getState().components[field.component.name].data.days.input
        enddate = moment(enddate).add(value, 'months');
        enddate = moment(enddate._d).add(days, 'days');
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'enddate',
                value: enddate._d
            }
        })
    }
}


export const setEndDays = (field, value) => {
    return (dispatch, getState) => {
        let months = getState().components[field.component.name].data.months.input
        let enddate = getState().components[field.component.name].data.startdate.input
        enddate = moment(enddate).add(value, 'days');
        enddate = moment(enddate._d).add(months, 'months');
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'enddate',
                value: enddate._d
            }
        })
    }
}

export const setStartdate = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: 'surrogateheader',
                field: 'startdate',
                value: new Date()
            }
        })
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: 'surrogateheader',
                field: 'enddate',
                value: new Date()
            }
        })
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: 'surrogateheader',
                field: 'months',
                value: 0
            }
        })
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: 'surrogateheader',
                field: 'days',
                value: 0
            }
        })
    }
}

export const finValue = (field, surrogateitems) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]

        const _months = component.data.months.input
        const _days = component.data.days.input
        const _finance = component.data.finance.input

        const _accessory = surrogateitems.grid.data.filter(x => x.itemtype === 'Accessories')
        const _sundry = surrogateitems.grid.data.filter(x => x.itemtype === 'sundry')
        const accessory_tot = _.sumBy(_accessory, (d) => { return parseFloat((d.totalprice) ? d.totalprice : 0) }).toFixed(2)
        const sundry_tot = _.sumBy(_sundry, (d) => { return parseFloat((d.totalprice) ? d.totalprice : 0) }).toFixed(2)

        const monthly_str = (parseFloat(_finance * _months) + parseFloat(((_months > 0 ? (_finance / 30) : _finance) * _days))).toFixed(2)
        const monthly_excl = (parseFloat(monthly_str) + parseFloat(accessory_tot) + parseFloat(sundry_tot)).toFixed(2)

        let monthly_vat = parseFloat(monthly_excl * 0.15).toFixed(2)
        let monthly_incl = parseFloat(monthly_excl * 1.15).toFixed(2)

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'accessory_tot',
                value: accessory_tot
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'sundry_tot',
                value: sundry_tot
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_str',
                value: monthly_str
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_excl',
                value: monthly_excl
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_vat',
                value: monthly_vat
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_incl',
                value: monthly_incl
            }
        })
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'waiver',
                value: 0.00
            }
        })
    }
}

export const accValue = (field, fin, acc, sun, waiver) => {
    return (dispatch, getState) => {
        let _waiver = waiver ? waiver : 0
        const sum = (parseFloat(fin) + parseFloat(acc) + parseFloat(sun) + parseFloat(_waiver)).toFixed(2)
        let subtotal = sum
        let vat = parseFloat(sum * 0.15).toFixed(2)
        let total = parseFloat(sum * 1.15).toFixed(2)

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: acc
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_excl',
                value: subtotal
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_vat',
                value: vat
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_incl',
                value: total
            }
        })
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'waiver',
                value: parseFloat(_waiver)
            }
        })
    }
}

export const sunValue = (field, fin, acc, sun, waiver) => {
    return (dispatch, getState) => {

        let _waiver = waiver ? waiver : 0
        const sum = (parseFloat(fin) + parseFloat(acc) + parseFloat(sun) + parseFloat(_waiver)).toFixed(2)
        let subtotal = sum
        let vat = parseFloat(sum * 0.15).toFixed(2)
        let total = parseFloat(sum * 1.15).toFixed(2)

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: sun
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_excl',
                value: subtotal
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_vat',
                value: vat
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'monthly_incl',
                value: total
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'waiver',
                value: parseFloat(_waiver)
            }
        })
    }
}

export const calcEndDate = (field, value) => {
    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const _months = component.data.months.input
        const _days = component.data.days.input

        let enddate = component.data.startdate.input
        enddate = moment(enddate).add(_days, 'days');
        enddate = moment(enddate._d).add(_months, 'months');
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: 'enddate',
                value: enddate._d
            }
        })
    }
}

export const calcPeriod = (months, days, fromdate, todate) => {
    return (dispatch, getState) => {
        const _fromdate = moment(fromdate) //.format('YYYY-MM-DD')
        const _todate = moment(todate) //.format('YYYY-MM-DD')
        const _months = _todate.diff(_fromdate, "months")
        let _days = _todate.diff(_fromdate, "days") + 1
        _days = (_days - (_months * 30))
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: months.component.name,
                field: months.name,
                value: _months
            }
        })
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: days.component.name,
                field: days.name,
                value: _days
            }
        })
    }
}

export const getQuotation = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SEARCH_WORKINGS_QUOTATION,
            payload: {
                component: 'customerquote',
                field: field,
                query: '?quotenumber=' + value
            }
        })
    }
}

export const calcContractDistance = (distancepermonth, contractperiod, field) => {
    //console.log('calcContractDistance distancepermonth',distancepermonth)
    //console.log('calcContractDistance contractperiod',contractperiod)
    const distance = (parseInt(isNaN(distancepermonth) ? 0 : distancepermonth) * parseInt(isNaN(contractperiod) ? 0 : contractperiod))
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: distance ? distance : ''
            }
        })
    }
}

export const calcDistancePerMonth = (contractdistance, contractperiod, field) => {
    const distance = parseInt(parseInt(contractdistance.replace(/,/g, '')) / parseInt(contractperiod))
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: distance ? distance : ''
            }
        })
    }
}

export const setAcceptedDocuments = (component, documents) => {
    const rfq = 'RFQ_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_RFQ_QUOTE_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: rfq,
                        type: file.type,
                    }
                })
            };
        });
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: 'surrogateheader',
                field: 'rfq',
                value: rfq
            }
        })

        dispatch({
            type: types.SET_RFQ_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: component.name,
                preview: documents[0].preview,
                filename: documents[0].name,
            }
        })
    }
}

export const setRejectedDocuments = (component, documents) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: component.name
            }
        })
    }
}

export const laodComponentStepData = (step, component) => {
    return (dispatch, getState) => {
        const data = getState().workflowTaskInput.task.workflow_task_step
            .filter(x =>
                x.name === step &&
                x.workflow_task_step_component.filter(y => y.component.name === component.name).length
            )
            .reduce((arr, x) => {
                if (x.workflow_task_step_component.filter(y => y.component.name === component.name && y.data.length).length) {
                    arr.push(x.workflow_task_step_component.filter(y => y.component.name === component.name && y.data.length)[0].data)
                }

                return arr
            }, [])

        if (data.length) {
            if (component.name === 'surrogateheader') {
                const payload = {
                    component: component.name,
                    data: data[0][0].grid
                }

                dispatch({
                    type: types.QUOTE_VEHICLE_BLANK_ROW,
                    payload: payload
                })
            }
            else {
                const payload = {
                    component: component.name,
                    selected: [{
                        original: data[0][0]
                    }]
                }

                dispatch({
                    type: types.LOAD_COMPONET_GRID_SELECTED_DATA,
                    payload: payload
                })
            }
        }
    }
}

export const getVehicleOptions = (component, field, value, text) => {
    return (dispatch, getState) => {

        dispatch({
            type: types.SET_COMPONENT_LOOKUP_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name,
                value: text,
            }
        })
        
        dispatch({
            type: types.GET_CONTRACT_CATEGORY_VEHICLE_OPTIONS,
            payload: {
                component: component.name,
                model: 'contractvehiclecategory',
                query: '?contractvehiclecategory_id=' + value,
            }
        })
    }
}

export const setVehicleOptions = (component, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTRACT_CATEGORY_VEHICLE_OPTIONS,
            payload: {
                component: component.name,
                data: {
                    component_id: 156,
                    item_id: 10,
                    itemtype: 'Service',
                    itemname: 'Speed Buzzer',
                    quantity: 1,
                    quotetype_id: 156,
                    surrogateitem_id: 1,
                    totalprice: 16.05,
                    unitprice: 16.05,
                }
            }
        })
    }
}

export const getVehicleContractDetails = (field, value) => {
    return (dispatch, getState) => {

        dispatch({
            type: types.GET_VEHICLE_CONTRACT_DETAILS,
            payload: {
                field: field,
                value: value,
                related: 'vehicle'
            }
        })
    }
}

export const setVehicleContractDetails = (field, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: payload.data ? payload.data : []
            }
        })
    }
}

export const getCategoryChipAccessories = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA,
            payload: {
                value: value
            }
        })
    }
}

export const setCategoryChipAccessories = (payload) => {
    return (dispatch, getState) => {
        const data = payload.data.map((x, i) => {
            return {
                component_id: 161,
                item_id: x.accessory.accessory_id,
                itemtype: 'Accessories',
                itemname: x.accessory.accessoryname,
                quantity: 1,
                quotetype_id: 161,
                surrogateitem_id: i,
                retrofittable: false,
                vatable: false,
                totalprice: parseFloat(x.accessory.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                costprice: parseFloat(x.accessory.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                sellingprice: parseFloat(x.accessory.retailprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            }
        })
        dispatch({
            type: types.SET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA,
            payload: data
        })
    }
}

export const getContractMandatoryAccessory = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICLE_DEFAULT_ACCESSORY_LIST,
            payload: {
                value: value
            }
        })
        
    }
}

export const setContractMandatoryAccessory = (payload) => {

    return (dispatch, getState) => {
        const data = payload.data.map((x, i) => {
            return {
                component_id: 161,
                item_id: x.accessory.accessory_id,
                itemtype: 'Accessories',
                itemname: x.accessory.accessoryname,
                quantity: 1,
                quotetype_id: 161,
                surrogateitem_id: i,
                retrofittable: false,
                vatable: false,
                totalprice: parseFloat(x.accessory.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                costprice: parseFloat(x.accessory.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                sellingprice: parseFloat(x.accessory.retailprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            }
        })

        dispatch({
            type: types.SET_VEHICLE_DEFAULT_ACCESSORY_LIST,
            payload: {
                component: 'surrogateitems',
                data: data
            }
        })
    }
}

export const getContractMandatoryService = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICLE_DEFAULT_ACCESSORY_LIST,
            payload: {
                value: value
            }
        })
    }
}

export const setContractMandatoryService = (payload) => {
    return (dispatch, getState) => {

        const data = payload.data.map((x, i) => {
            return {
                component_id: 161,
                item_id: x.service.service_id,
                itemtype: 'Service',
                itemname: x.service.servicedescription,
                quantity: 1,
                quotetype_id: 161,
                surrogateitem_id: i,
                retrofittable: false,
                vatable: false,
                totalprice: parseFloat(x.service.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                costprice: parseFloat(x.service.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                sellingprice: parseFloat(x.service.sellingprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            }
        })
        dispatch({
            type: types.SET_VEHICLE_SERVICE_SELECTION_CATEGORY_LOOKUP_DATA,
            payload: data
        })
    }
}

export const getContractMandatorySundry = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICLE_DEFAULT_ACCESSORY_LIST,
            payload: {
                value: value
            }
        })
    }
}

export const setContractMandatorySundry = (payload) => {
    return (dispatch, getState) => {
        const data = payload.data.map((x, i) => {
            return {
                component_id: 161,
                item_id: x.sundry.sundry_id,
                itemtype: 'Sundry',
                itemname: x.sundry.description,
                quantity: 1,
                quotetype_id: 161,
                surrogateitem_id: i,
                retrofittable: false,
                vatable: false,
                totalprice: parseFloat(x.sundry.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                costprice: parseFloat(x.sundry.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                sellingprice: parseFloat(x.sundry.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            }
        })
        dispatch({
            type: types.SET_VEHICLE_SUNDRY_SELECTION_CATEGORY_LOOKUP_DATA,
            payload: data
        })
    }
}

export const getContractTypesLookupValue = (component, field, customer_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CUSTOMER_CONTRACT_TYPES_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field,
                value: customer_id,
            }
        })
    }
}

export const setContractTypesLookupValue = (field, payload) => {
    //console.log('setContractTypesLookupValue',payload)
    return (dispatch, getState) => {
        const data = payload.data.map(x => {
            return {
                value: x.contracttype_id,
                text: x.contracttype
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data
            }
        })
    }
}

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FML_INPUT_DATA,
            payload: data
        })
    }
}

export const getCustomerDerivativeLookupValue = (customer_id, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CUSTOMER_DERIVATIVE_LOOKUP_DATA,
            payload: {
                search: search,
                customer_id: customer_id,
            }
        })
    }
}

export const getCustomerDerivativeCategoryLookupValue = (customerderivative_id, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CUSTOMER_DERIVATIVE_CATEGORY_LOOKUP_DATA,
            payload: {
                search: search,
                customerderivative_id: customerderivative_id,
            }
        })
    }
}


export const genBulkQuotation = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GEN_CUSTOMER_BULK_QUOTATION_REFERENCE,
        })
    }
}


export const setBulkQuotation = (response) => {
    //console.log('setBulkQuotationx', response)
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'bulkreference', value: moment().format('YYYYMMDD') + '-' + response.nextval  }))
    }
}
export const getCustomerDerivativeCategoryMetaData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CUSTOMER_DERIVATIVE_CATEGORY_META_DATA,
            payload: data 
        })
    }
}
export const setCustomerDerivativeCategoryMetaData = (response, payload) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: payload.model + '_data', value: []}))
        dispatch(setInputValue({prop: payload.model + '_data', value: response.data}))
    }
}

export const setCustomerDerivativeLookupValue = (response) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'customerderivative_data', value: response.data}))
        if(getState().user.demouser)
            dispatch(autoPopulate(response.data))
    }
}

export const setCustomerDerivativeCategoryLookupValue = (response) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'customerderivativecategory_data', value: response.data}))
    }
}

export const setTotalAdjustment = () => {
    return (dispatch, getState) => {
        let workflowQuoteHeader = getState().workflowQuoteHeader
        let total_adjustment = 0
        try {
            let terrain_adjustment = workflowQuoteHeader.terrain_data
                ? workflowQuoteHeader.terrain_data.length && workflowQuoteHeader.terrain_id
                    ? workflowQuoteHeader.terrain_data.filter(x => x.terrain_id == workflowQuoteHeader.terrain_id)[0].adjustment
                    : 0 
                : 0
                
            let location_adjustment = workflowQuoteHeader.location_data
                ? workflowQuoteHeader.location_data.length && workflowQuoteHeader.location_id
                    ? workflowQuoteHeader.location_data.filter(x => x.location_id == workflowQuoteHeader.location_id)[0].adjustment
                    : 0 
                : 0

            let usage_adjustment = workflowQuoteHeader.usage_data
                ? workflowQuoteHeader.usage_data.length && workflowQuoteHeader.usage_id
                    ? workflowQuoteHeader.usage_data.filter(x => x.usage_id == workflowQuoteHeader.usage_id)[0].adjustment
                    : 0 
                : 0
                
            let specialistvehicle_adjustment = workflowQuoteHeader.specialistvehicle_data
                ? workflowQuoteHeader.specialistvehicle_data.length && workflowQuoteHeader.specialistvehicle_id
                    ? workflowQuoteHeader.specialistvehicle_data.filter(x => x.specialistvehicle_id == workflowQuoteHeader.specialistvehicle_id)[0].adjustment
                    : 0 
                : 0

            //console.log('total_adjustmentx terrain_adjustment',terrain_adjustment)
            //console.log('total_adjustmentx location_adjustment',location_adjustment)
            //console.log('total_adjustmentx usage_adjustment',usage_adjustment)
            //console.log('total_adjustmentx specialistvehicle_adjustment',specialistvehicle_adjustment)

            total_adjustment = parseFloat(terrain_adjustment) + parseFloat(location_adjustment)  + parseFloat(usage_adjustment)  + parseFloat(specialistvehicle_adjustment) 
            
        }
        catch(err) {}

        // //console.log('total_adjustmentxxx',total_adjustment)
        // dispatch(quoteActions.setInputValue({prop: 'residualvalue', value: total_adjustment}))
        // dispatch(quoteActions.setInputValue({prop: 'total_adjustment', value: total_adjustment}))
        
        let grid_data = getState().workflowQuoteHeader.grid_data
        let inx = 0
        for(let x of grid_data) {
            let rev_residualvalue = x['rev_residualvalue']

            let residualvalue = parseFloat(rev_residualvalue ? rev_residualvalue : 0) + parseFloat(total_adjustment ? total_adjustment : 0)

            dispatch(setGridInputValue({'prop': 'rev_residualvalue', 'value': rev_residualvalue, 'inx': inx}))
            dispatch(setGridInputValue({'prop': 'total_adjustment', 'value': total_adjustment, 'inx': inx}))
            dispatch(setGridInputValue({'prop': 'residualvalue', 'value': residualvalue, 'inx': inx}))

            inx = inx + 1
        }
        //dispatch(setInputValue({prop: 'grid_data', value: _grid_data}))
    }
}

export const setGridInputValue = (data) => {
    return (dispatch, getState) => {
        let grid_data = getState().workflowQuoteHeader.grid_data

        let secondary_asset = getState().workflowQuoteHeader.secondary_asset

        let tot_asset_sellingprice = _.sumBy(secondary_asset, (d) => { return parseFloat((d.sellingprice) ? d.sellingprice : 0) }).toFixed(2)
        let tot_asset_rvamount = _.sumBy(secondary_asset, (d) => { return parseFloat((d.rv_amount) ? d.rv_amount : 0) }).toFixed(2)
        let tot_asset_price = _.sumBy(secondary_asset, (d) => { return parseFloat((d.asset_price) ? d.asset_price : 0) }).toFixed(2)

        if(grid_data.length) {
            grid_data[data.inx][data.prop] = data.value
            let rv = parseFloat(grid_data[data.inx]['residualvalue'])
            let vehicleprice = parseFloat(grid_data[data.inx]['vehicleprice'])

            grid_data[data.inx]['vehicleprice'] = (parseFloat(grid_data[data.inx]['retailprice']) - parseFloat(grid_data[data.inx]['discount'])).toFixed(2)
            let rv_amount = (vehicleprice * (rv/100)).toFixed(2)
            grid_data[data.inx]['rv_amount'] = rv_amount

            grid_data[data.inx]['overall_rv'] = '0'
            grid_data[data.inx]['overall_rv_amount'] = '0'
            
            let excl_rv_vehicleprice = (vehicleprice - rv_amount).toFixed(2)

            tot_asset_rvamount = tot_asset_rvamount ? tot_asset_rvamount : 0
            rv_amount = rv_amount ? rv_amount : 0
            excl_rv_vehicleprice = excl_rv_vehicleprice ? excl_rv_vehicleprice : 0
            tot_asset_sellingprice = tot_asset_sellingprice ? tot_asset_sellingprice : 0
            if(parseInt(tot_asset_sellingprice ? tot_asset_sellingprice : 0) > 0 && parseInt(tot_asset_rvamount ? tot_asset_rvamount : 0) > 0) {
                
                //console.log('overall_rvx1  excl_rv_vehicleprice', vehicleprice)
                //console.log('overall_rvx1  tot_asset_sellingprice', tot_asset_sellingprice)
                //console.log('overall_rvx1  tot_asset_rvamount', tot_asset_rvamount)
                //console.log('overall_rvx1  rv_amount', rv_amount)
                //console.log('overall_rvx1  tot_asset_price', tot_asset_price)
                //console.log('overall_rvx1  tot price', (parseInt(vehicleprice) + parseInt(tot_asset_sellingprice)))
                //console.log('overall_rvx1  tot RV', (parseInt(tot_asset_rvamount) + parseInt(rv_amount)))

                if(parseInt((vehicleprice + tot_asset_sellingprice)) > 0) {
                    let overall_rv = tot_asset_price ? (((parseInt(tot_asset_rvamount) + parseInt(rv_amount))/(parseInt(vehicleprice) + parseInt(tot_asset_sellingprice))) * 100) : 0
                    let overall_rv_amount = tot_asset_price ? (parseInt(tot_asset_rvamount) + parseInt(rv_amount)) : 0
                    grid_data[data.inx]['overall_rv'] = (overall_rv).toFixed(2)
                    grid_data[data.inx]['overall_rv_amount'] = (overall_rv_amount).toFixed(2)
                }
            }
            dispatch(setInputValue({prop: 'grid_data', value: grid_data}))
        }
    }
}

export const updateGridInputValue = () => {
    return (dispatch, getState) => {
        let grid_data = getState().workflowQuoteHeader.grid_data

        let secondary_asset = getState().workflowQuoteHeader.secondary_asset

        let tot_asset_sellingprice = _.sumBy(secondary_asset, (d) => { return parseFloat((d.sellingprice) ? d.sellingprice : 0) }).toFixed(2)
        let tot_asset_rvamount = _.sumBy(secondary_asset, (d) => { return parseFloat((d.rv_amount) ? d.rv_amount : 0) }).toFixed(2)
        let tot_asset_price = _.sumBy(secondary_asset, (d) => { return parseFloat((d.asset_price) ? d.asset_price : 0) }).toFixed(2)

        for(let x of grid_data) {
            let rv = parseFloat(x['residualvalue'])
            let vehicleprice = parseFloat(x['vehicleprice'])

            x['vehicleprice'] = (parseFloat(x['retailprice']) - parseFloat(x['discount'])).toFixed(2)
            let rv_amount = (vehicleprice * (rv/100)).toFixed(2)
            x['rv_amount'] = rv_amount

            
            x['overall_rv'] = '0'
            x['overall_rv_amount'] = '0'

            let excl_rv_vehicleprice = (vehicleprice).toFixed(2)

            tot_asset_rvamount = tot_asset_rvamount ? tot_asset_rvamount : 0
            rv_amount = rv_amount ? rv_amount : 0
            excl_rv_vehicleprice = excl_rv_vehicleprice ? excl_rv_vehicleprice : 0
            tot_asset_sellingprice = tot_asset_sellingprice ? tot_asset_sellingprice : 0
            if(parseInt(tot_asset_sellingprice ? tot_asset_sellingprice : 0) > 0 && parseInt(tot_asset_rvamount ? tot_asset_rvamount : 0) > 0) {

                //console.log('overall_rvx  excl_rv_vehicleprice', vehicleprice)
                //console.log('overall_rvx  tot_asset_sellingprice', tot_asset_sellingprice)
                //console.log('overall_rvx  tot_asset_rvamount', tot_asset_rvamount)
                //console.log('overall_rvx  rv_amount', rv_amount)
                //console.log('overall_rvx  tot_asset_price', tot_asset_price)
                //console.log('overall_rvx  tot price', (parseInt(vehicleprice) + parseInt(tot_asset_sellingprice)))
                //console.log('overall_rvx  tot RV', (parseInt(tot_asset_rvamount) + parseInt(rv_amount)))
                if(parseInt((vehicleprice + tot_asset_sellingprice)) > 0) {
                    let overall_rv = tot_asset_rvamount ? (((parseInt(tot_asset_rvamount) + parseInt(rv_amount))/(parseInt(vehicleprice) + parseInt(tot_asset_sellingprice))) * 100) : 0
                    let overall_rv_amount = tot_asset_rvamount ? (parseInt(tot_asset_rvamount) + parseInt(rv_amount)) : 0
                    x['overall_rv'] = (overall_rv).toFixed(2)
                    x['overall_rv_amount'] = (overall_rv_amount).toFixed(2)
                }
            }else{
                
            }
            dispatch(setInputValue({prop: 'grid_data', value: grid_data}))
        }
    }
}
export const autoPopulate = (data) => {
    return (dispatch, getState) => {
        //console.log('autoPopulate data',data)
        dispatch(setInputValue({prop: 'customerderivative_id', value: data[0].customerderivative_id}))
        dispatch(setInputValue({prop: 'contractname', value: data[0].contractname}))

        let _customerderivative = data[0]
        //console.log('autoPopulate _customerderivative',_customerderivative)

        dispatch(setInputValue({prop: 'customerderivative', value: _customerderivative}))
        dispatch(setInputValue({prop: 'financert57Pricing', value: _customerderivative.financert57Pricing ? true : false}))


        dispatch(setInputValue({prop: 'distancepermonth', value:  _customerderivative.distancedefaultpetrol ? _customerderivative.distancedefaultpetrol : 0}))
        dispatch(setInputValue({prop: 'contractperiod', value: _customerderivative.perioddefault ? _customerderivative.perioddefault : 0}))
        dispatch(setInputValue({prop: 'contractdistance', value: ((_customerderivative.distancedefaultpetrol ? parseInt(_customerderivative.distancedefaultpetrol) : 0) * (_customerderivative.perioddefault ? parseInt(_customerderivative.perioddefault) : 0))}))
    
        //console.log('autoPopulate financert57Pricing',_customerderivative.financert57Pricing)
        if(_customerderivative.financert57Pricing) {
            //dispatch(getLookupData('contractvehiclecategory', 'categorydescription', 'Transnet: Minibus Petrol'))
            dispatch(getLookupData('contractvehiclecategory', 'categorydescription', 'Sedan Petrol'))

            setTimeout(() => {
                
                let contractvehiclecategory_data = getState().workflowQuoteHeader.contractvehiclecategory_data
                //console.log('autoPopulate contractvehiclecategory_data',contractvehiclecategory_data)

                dispatch(setInputValue({prop: 'ratecard_id', value: contractvehiclecategory_data[0].contractvehiclecategory_id}))
                dispatch(setInputValue({prop: 'ratecard', value: contractvehiclecategory_data[0].categorydescription}))
                
                dispatch(getRateCard(contractvehiclecategory_data[0].contractvehiclecategory_id, _customerderivative.distancedefaultpetrol ? _customerderivative.distancedefaultpetrol : 0))
            }, 3000)
        }
        else {
            dispatch(getCustomerDerivativeCategoryLookupValue(data[0].customerderivative_id,''))
            

            setTimeout(() => {
                let customerderivativecategory_data = getState().workflowQuoteHeader.customerderivativecategory_data
                //console.log('autoPopulate customerderivativecategory_data', customerderivativecategory_data)

                dispatch(setInputValue({prop: 'customerderivativecategory_id', value: customerderivativecategory_data[0].customerderivativecategory_id}))
                dispatch(setInputValue({prop: 'customerderivativecategory', value: customerderivativecategory_data[0].customerderivativecategory}))
                
                dispatch(getCustomerDerivativeCategoryMetaData({
                    model: 'customerderivativecategorymake',
                    value: customerderivativecategory_data[0].customerderivativecategory_id
                }))
                dispatch(getCustomerDerivativeCategoryMetaData({
                    model: 'customerderivativecategorymmcode',
                    value: customerderivativecategory_data[0].customerderivativecategory_id
                }))
            }, 3000)
        }

        dispatch(setInputValue({prop: 'comments', value: 'Demo Quotation'}))
        dispatch(setInputValue({prop: 'quotedate', value: moment().format('YYYY-MM-DD')}))

        dispatch(getLookupData('vehiclecategory', 'vehiclecategory', ''))
        dispatch(getLookupData('vehiclegroup', 'vehiclegroup', ''))
        dispatch(getLookupData('usage', 'name', ''))
        dispatch(getLookupData('terrain', 'terrain', ''))
        dispatch(getLookupData('specialistvehicle', 'specialistvehicle', ''))
        dispatch(getLookupData('location', 'location', ''))
        dispatch(getLookupData('color', 'color', 'white'))
        dispatch(getLookupData('quotefor', 'quotefor', 'Add'))


        setTimeout(() => {
            let vehiclecategory_data = getState().workflowQuoteHeader.vehiclecategory_data
            dispatch(setInputValue({prop: 'vehiclecategory_id', value: vehiclecategory_data[0].value}))
            dispatch(setInputValue({prop: 'vehiclecategory', value: vehiclecategory_data[0].text}))

            let vehiclegroup_data = getState().workflowQuoteHeader.vehiclegroup_data
            dispatch(setInputValue({prop: 'vehiclegroup', value: vehiclegroup_data[0].text}))
            dispatch(setInputValue({prop: 'vehiclegroup_id', value: vehiclegroup_data[0].value}))

            let usage_data = getState().workflowQuoteHeader.usage_data
            dispatch(setInputValue({prop: 'usage', value: usage_data[0].text}))
            dispatch(setInputValue({prop: 'usage_id', value: usage_data[0].value}))

            let terrain_data = getState().workflowQuoteHeader.terrain_data
            dispatch(setInputValue({prop: 'terrain', value: terrain_data[0].text}))
            dispatch(setInputValue({prop: 'terrain_id', value: terrain_data[0].value}))

            let specialistvehicle_data = getState().workflowQuoteHeader.specialistvehicle_data
            dispatch(setInputValue({prop: 'specialistvehicle', value: specialistvehicle_data[0].text}))
            dispatch(setInputValue({prop: 'specialistvehicle_id', value: specialistvehicle_data[0].value}))

            let location_data = getState().workflowQuoteHeader.location_data
            dispatch(setInputValue({prop: 'location', value: location_data[0].text}))
            dispatch(setInputValue({prop: 'location_id', value: location_data[0].value}))

            let color_data = getState().workflowQuoteHeader.color_data
            dispatch(setInputValue({prop: 'color', value: color_data[0].text}))
            dispatch(setInputValue({prop: 'color_id', value: color_data[0].value}))
            
            let quotefor_data = getState().workflowQuoteHeader.quotefor_data
            dispatch(setInputValue({prop: 'quotefor', value: quotefor_data[0].text}))
            dispatch(setInputValue({prop: 'quotefor_id', value: quotefor_data[0].value}))
        }, 3000)

        dispatch(itemsActions.getLookupData('quoteitemtype', 'quoteitemtype', ''))
        dispatch(itemsActions.getLookupData('sundry', 'description', ''))
        dispatch(itemsActions.getLookupData('accessory', 'accessoryname', ''))
        dispatch(itemsActions.getLookupData('service', 'servicedescription', ''))

        setTimeout(() => {
            let quoteitemtype = getState().workflowQuoteItemGrid.quoteitemtype_data
            let accessory_data = getState().workflowQuoteItemGrid.accessory_data
            let service_data = getState().workflowQuoteItemGrid.service_data
            let sundry_data = getState().workflowQuoteItemGrid.sundry_data

            //console.log('autoPopulate quoteitemtype', quoteitemtype)
            dispatch(itemsActions.addBlankRow())
            dispatch(itemsActions.addBlankRow())
            dispatch(itemsActions.addBlankRow())
            dispatch(itemsActions.addBlankRow())
            dispatch(itemsActions.addBlankRow())
            dispatch(itemsActions.addBlankRow())

            dispatch(itemsActions.setGridFieldValue('itemtype', quoteitemtype[0].text.toLowerCase(), 0))
            dispatch(itemsActions.setGridFieldValue('itemtype_id', quoteitemtype[0].value, 0))
            dispatch(itemsActions.setGridFieldValue('Accessory', accessory_data[0].text, 0))
            dispatch(itemsActions.setGridFieldValue('accessory', accessory_data[0].text, 0))
            dispatch(itemsActions.setGridFieldValue('accessory_id', accessory_data[0].value, 0))
            dispatch(itemsActions.setGridFieldValue('quantity', 1, 0))
            dispatch(itemsActions.setGridFieldValue('costprice', accessory_data[0]['costprice'], 0))
            dispatch(itemsActions.setGridFieldValue('sellingprice', accessory_data[0]['sellingprice'], 0))
            
            dispatch(itemsActions.setGridFieldValue('itemtype', quoteitemtype[0].text.toLowerCase(), 1))
            dispatch(itemsActions.setGridFieldValue('itemtype_id', quoteitemtype[0].value, 1))
            dispatch(itemsActions.setGridFieldValue('accessory', accessory_data[1].text, 1))
            dispatch(itemsActions.setGridFieldValue('Accessory', accessory_data[1].text, 1))
            dispatch(itemsActions.setGridFieldValue('accessory_id', accessory_data[1].value, 1))
            dispatch(itemsActions.setGridFieldValue('quantity', 1, 1))
            dispatch(itemsActions.setGridFieldValue('costprice', accessory_data[1]['costprice'], 1))
            dispatch(itemsActions.setGridFieldValue('sellingprice', accessory_data[1]['sellingprice'],1))

            dispatch(itemsActions.setGridFieldValue('itemtype', quoteitemtype[0].text.toLowerCase(), 2))
            dispatch(itemsActions.setGridFieldValue('itemtype_id', quoteitemtype[0].value, 2))
            dispatch(itemsActions.setGridFieldValue('accessory', accessory_data[3].text, 2))
            dispatch(itemsActions.setGridFieldValue('Accessory', accessory_data[3].text, 2))
            dispatch(itemsActions.setGridFieldValue('accessory_id', accessory_data[3].value, 2))
            dispatch(itemsActions.setGridFieldValue('quantity', 1, 2))
            dispatch(itemsActions.setGridFieldValue('costprice', accessory_data[3]['costprice'], 2))
            dispatch(itemsActions.setGridFieldValue('sellingprice', accessory_data[3]['sellingprice'], 2))

            dispatch(itemsActions.setGridFieldValue('itemtype', quoteitemtype[1].text.toLowerCase(), 3))
            dispatch(itemsActions.setGridFieldValue('itemtype_id', quoteitemtype[1].value, 3))
            dispatch(itemsActions.setGridFieldValue('Service', service_data[1].text, 3))
            dispatch(itemsActions.setGridFieldValue('service', service_data[1].text, 3))
            dispatch(itemsActions.setGridFieldValue('service_id', service_data[1].value, 3))
            dispatch(itemsActions.setGridFieldValue('quantity', 1, 3))
            dispatch(itemsActions.setGridFieldValue('costprice', service_data[1]['costprice'], 3))
            dispatch(itemsActions.setGridFieldValue('sellingprice', service_data[1]['sellingprice'], 3))

            dispatch(itemsActions.setGridFieldValue('itemtype', quoteitemtype[1].text.toLowerCase(), 4))
            dispatch(itemsActions.setGridFieldValue('itemtype_id', quoteitemtype[1].value, 4))
            dispatch(itemsActions.setGridFieldValue('Service', service_data[3].text, 4))
            dispatch(itemsActions.setGridFieldValue('service', service_data[3].text, 4))
            dispatch(itemsActions.setGridFieldValue('service_id', service_data[3].value, 4))
            dispatch(itemsActions.setGridFieldValue('quantity', 1, 4))
            dispatch(itemsActions.setGridFieldValue('costprice', service_data[3]['costprice'], 4))
            dispatch(itemsActions.setGridFieldValue('sellingprice', service_data[3]['sellingprice'], 4))

            dispatch(itemsActions.setGridFieldValue('itemtype', quoteitemtype[2].text.toLowerCase(), 5))
            dispatch(itemsActions.setGridFieldValue('itemtype_id', quoteitemtype[2].value, 5))
            dispatch(itemsActions.setGridFieldValue('Sundry', sundry_data[1].text, 5))
            dispatch(itemsActions.setGridFieldValue('sundry', sundry_data[1].text, 5))
            dispatch(itemsActions.setGridFieldValue('sundry_id', sundry_data[1].value, 5))
            dispatch(itemsActions.setGridFieldValue('quantity', 1, 5))
            dispatch(itemsActions.setGridFieldValue('costprice', sundry_data[1]['costprice'], 5))
            dispatch(itemsActions.setGridFieldValue('sellingprice', sundry_data[1]['sellingprice'], 5))
        }, 3000)
    }
}

export const getContractTypeDetails = (customerderivative_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CONTRACT_TYPE_DETAILS_DATA,
            payload: {
                customerderivative_id:customerderivative_id
            }
        })
    }
}

export const setContractTypeDetails = (response) => {
    //console.log('customerderivativex', response.data)
    let customerderivative = response.data.length ? response.data[0] : {}
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'customerderivative', value: customerderivative}))
        dispatch(setInputValue({prop: 'interestratetype',value: customerderivative.interestratetype? customerderivative.interestratetype.interestratetype : null}))
        dispatch(setInputValue({prop: 'interestratetype_id',value: customerderivative.interestratetype_id? customerderivative.interestratetype.interestratetype_id : null}))
    }
}
