
import sockets from 'socket.io-client'
import moment from 'moment'
import { Amplify, Auth } from 'aws-amplify';

import * as config from './config'
import * as appTypes from './components/App/types'
import * as messengerTypes from './components/Messenger/types'
import * as documentTypes from './components/Document/types'
import * as pdfTypes from './components/PDF/types'
import * as quoteSelectGridTypes from './components/QuoteSelectGrid/types'
import * as workflowQueueTasksTypes from './components/WorkflowQueueTasks/types'
import * as workflowVehicleHistoryTypes from './components/WorkflowQueueTasks/WorkflowVehicleHistory/types'
import * as workflowDocumentsTypes from './components/WorkflowQueueTasks/WorkflowDocuments/types'
import * as workflowQuoteHeaderTypes from './components/WorkflowQuoteHeader/types'
import * as quoteHeaderTypes from './components/QuoteHeader/types'
import * as workflowPOHeaderTypes from './components/WorkflowPOHeader/types'
import * as workflowTaskInputTypes from './components/WorkflowTaskInput/types'
import * as workflowTaskApprovalTypes from './components/WorkflowTaskApproval/types'
import * as workflowTaskActionTypes from './components/WorkflowTaskAction/types'
import * as cognitoTypes from './components/Cognito/types'
import * as workflowViewFMLQuoteSummaryTypes from './components/WorkflowViewFMLQuoteSummary/types'
import * as workflowInvoiceApTypes from './components/Invoiceap/types'
import * as workflowDisposalTypes from './components/WorkflowQueueTasks/WorkflowDisposal/types'
import * as cisTypes from './components/CIS/types'
import * as controlsTypes from './controls/types'

import { cognitoUser } from './cognito'

import * as workflowPOReceiptVehicle from './components/WorkflowPOReceiptVehicle/types'
import * as workflowPOGRV from './components/WorkflowPOGRV/types'
import * as uploadTypes from './components/Upload/types'
import * as imageUploaderTypes from './components/ImageUploader/types'
import * as uploadAccessoryTypes from './components/UploadAccessory/types'
import * as poBookingInvoiceTypes from './components/WorkflowPOHeader/POBookingInvoice/types'
import * as poHeaderTypes from './components/WorkflowPOHeader/POHeader/types'
import * as poBookingTypes from './components/WorkflowPOHeader/POBooking/types'
import * as poBookingInvoiceUploadTypes from './components/WorkflowPOHeader/POBookingInvoiceUpload/types'
import * as accessoryActionsTypes from './components/Custom/ACCESSORY/types'
import * as gridTypes from './components/Grid/types'
import * as supplierPaymentTypes from './components/Custom/SUPPLIERPAYMENT/types'
import * as supplierAllocationTypes from './components/Custom/SUPPLIERPAYMENTALLOCATION/types'
import * as customerAllocationTypes from './components/Custom/CUSTOMERALLOCATION/types'
import * as customerCreditNoteTypes from './components/Custom/CUSTOMERCREDITNOTE/types'
//import * as customerInvoiceTypes from './components/Custom/CUSTOMERINVOICE/types'
import * as customerTransactionTypes from './components/Custom/CUSTOMERTRANSACTION/types'
import * as contracttermtypes from './components/Custom/CONTRACTTERMINATION/types'
import * as customerJournalTypes from './components/Custom/CUSTOMERJOURNAL/types'
import * as customerReceiptTypes from './components/Custom/CUSTOMERRECEIPT/types'
import * as customerReverseReceiptTypes from './components/Custom/CUSTOMERREVERSERECEIPT/types'
import * as supplierInvoiceTypes from './components/Custom/SUPPLIERTRANSACTION/types'
import * as supplierPaymentReversalTypes from './components/Custom/SUPPLIERPAYMENTREVERSAL/types'
import * as customerStatementTypes from './components/Custom/CUSTOMERSTATEMENT/types'
import * as supplierTransactionTypes from './components/Custom/SUPPLIERTRANSACTIONCUSTOM/types'
import * as supplierCreditNoteTypes from './components/Custom/SUPPLIERCREDITNOTE/types'
import * as supplierJournalTypes from './components/Custom/SUPPLIERJOURNAL/types'
import * as billingRunViewTypes from './components/WorkflowBillingRun/BillingRunView/types'
import * as billingRefreshTypes from './components/WorkflowBillingRun/BillingRefresh/types'
import * as billingPreviewTypes from './components/WorkflowBillingRun/BillingPreview/types'
import * as reRUNTypes from './components/Custom/RERUN/types'
import * as broadcastTypes from './components/Custom/BROADCAST/types'
import * as workflowCreateQuoteTypes from './components/WorkflowFMLQuote/WorkflowCreateQuote/types'
import * as strTypes from './components/WorkflowSTRQuote/WorkflowCreateQuote/types'
import * as formTypes from './components/Form/types'
import * as cardManagementTypes from './components/CardManagement/types'
import * as cardManagementAction from './components/CardManagement/actions'
import * as jsreportTypes from './components/JSReport/types'
import * as jsReportActions from './components/JSReport/actions'

import * as enterestReviewTypes from './components/WorkflowStatementRun/EnterestReview/types'
import * as enterestReviewAction from './components/WorkflowStatementRun/EnterestReview/actions'
import * as statementPreviewTypes from './components/WorkflowStatementRun/StatementPreview/types'
import * as statementPreviewAction from './components/WorkflowStatementRun/StatementPreview/actions'

import * as xeroTypes from './components/Xero/types'
import * as xeroActions from './components/Xero/actions'

import * as cashbookTypes from './components/Custom/CASH_BOOK/types'
import * as bankReconTypes from './components/Custom/BANK_RECONCILIATION/types'
import * as cashbookActions from './components/Custom/CASH_BOOK/actions'
import * as bankReconActions from './components/Custom/BANK_RECONCILIATION/actions'
import * as broadcastActions from './components/Custom/BROADCAST/actions'
import * as documentActions from './components/Document/actions'
import * as pdfActions from './components/PDF/actions'
import * as gridActions from './components/Grid/actions'
import * as routerActions from './components/Redirect/actions'
import * as quoteSelectGridActions from './components/QuoteSelectGrid/actions'
import * as workflowTaskApprovalActions from './components/WorkflowTaskApproval/actions'
import * as workflowQueueTasksActions from './components/WorkflowQueueTasks/actions'
import * as workflowVehicleHistoryActions from './components/WorkflowQueueTasks/WorkflowVehicleHistory/actions'
import * as workflowDocumentsActions from './components/WorkflowQueueTasks/WorkflowDocuments/actions'
import * as uploadAccessoryActions from './components/UploadAccessory/actions'
import * as poHeaderActions from './components/WorkflowPOHeader/POHeader/actions'
import * as poBookingInvoiceActions from './components/WorkflowPOHeader/POBookingInvoice/actions'
import * as poBookingActions from './components/WorkflowPOHeader/POBooking/actions'
import * as accessoryActions from './components/Custom/ACCESSORY/actions'
import * as supplierPaymentActions from './components/Custom/SUPPLIERPAYMENT/actions'
import * as supplierAllocationActions from './components/Custom/SUPPLIERPAYMENTALLOCATION/actions'
import * as customerAllocationActions from './components/Custom/CUSTOMERALLOCATION/actions'
import * as customerCreditNoteActions from './components/Custom/CUSTOMERCREDITNOTE/actions'
//import * as customerInvoiceActions from './components/Custom/CUSTOMERINVOICE/actions'
import * as customerTransactionActions from './components/Custom/CUSTOMERTRANSACTION/actions'
import * as customerJournalActions from './components/Custom/CUSTOMERJOURNAL/actions'
import * as customerReceiptActions from './components/Custom/CUSTOMERRECEIPT/actions'
import * as customerReverseReceiptActions from './components/Custom/CUSTOMERREVERSERECEIPT/actions'
import * as workflowQuoteHeaderActions from './components/WorkflowQuoteHeader/actions'
import * as supplierInvoiceActions from './components/Custom/SUPPLIERTRANSACTION/actions'
import * as supplierPaymentReversalActions from './components/Custom/SUPPLIERPAYMENTREVERSAL/actions'
import * as workflowInvoiceApActions from './components/Invoiceap/actions'
import * as quoteHeaderActions from './components/QuoteHeader/actions'
import * as customerStatementActions from './components/Custom/CUSTOMERSTATEMENT/actions'
import * as supplierTransactionActions from './components/Custom/SUPPLIERTRANSACTIONCUSTOM/actions'
import * as supplierCreditNoteActions from './components/Custom/SUPPLIERCREDITNOTE/actions'
import * as supplierJournalActions from './components/Custom/SUPPLIERJOURNAL/actions'
import * as billingRunViewActions from './components/WorkflowBillingRun/BillingRunView/actions'
import * as billingRefreshActions from './components/WorkflowBillingRun/BillingRefresh/actions'
import * as billingPreviewActions from './components/WorkflowBillingRun/BillingPreview/actions'
import * as customerTransactionCustomTypes from './components/Custom/CUSTOMERTRANSACTIONCUSTOM/types'
import * as customerTransactionCustomActions from './components/Custom/CUSTOMERTRANSACTIONCUSTOM/actions'
import * as reRUNActions from './components/Custom/RERUN/actions'
import * as contracttermActions from './components/Custom/CONTRACTTERMINATION/actions'
import * as cisAction from './components/CIS/actions'
import * as trialbalanceactions from './components/Custom/TRIALBALANCE/actions'
import * as trialbalancetypes from './components/Custom/TRIALBALANCE/types'
import * as billingmoduleactions from './components/Custom/BILLINGMODULE/actions'
import * as billingmoduletypes from './components/Custom/BILLINGMODULE/types'
import * as workflowDefleetTypes from './components/WorkflowDefleet/types'
import * as workflowDefleetActions from './components/WorkflowDefleet/actions'
import * as workflowDefleetChecksheetActions from './components/WorkflowDefleet/DefleetGenerateChecksheet/actions'
import * as workflowDefleetChecksheetTypes from './components/WorkflowDefleet/DefleetGenerateChecksheet/types'
import * as defleetUploadQuotesConfirmActions from './components/WorkflowDefleet/DefleetUploadQuotesConfirm/actions'
import * as defleetUploadQuotesConfirmTypes from './components/WorkflowDefleet/DefleetUploadQuotesConfirm/types'
import * as defleetAcceptRebillTypes from './components/WorkflowDefleet/DefleetAcceptRebill/types'
import * as defleetAcceptRebillActions from './components/WorkflowDefleet/DefleetAcceptRebill/actions'
import * as paymentsActions from './components/Custom/SUPPLIERPAYMENTMANAGEMENT/actions'
import * as paymentsTypes from './components/Custom/SUPPLIERPAYMENTMANAGEMENT/types'
import * as workflowPaymentTypes from './components/WorkflowPayment/types'
import * as workflowPaymentActions from './components/WorkflowPayment/actions'
import * as strActions from './components/WorkflowSTRQuote/WorkflowCreateQuote/actions'
import * as workflowDisposalActions from './components/WorkflowQueueTasks/WorkflowDisposal/actions'
import * as controlsActions from './controls/actions'

import * as paymentApprovalFinanceTypes from './components/Custom/SUPPLIERPAYMENTAPPROVALFINANCE/types'
import * as paymentApprovalFinanceActions from './components/Custom/SUPPLIERPAYMENTAPPROVALFINANCE/actions'//PAYMENT_DOCUMENT_BY_ID
import * as paymentApprovalGMTypes from './components/Custom/SUPPLIERPAYMENTAPPROVALGM/types'
import * as paymentApprovalGMActions from './components/Custom/SUPPLIERPAYMENTAPPROVALGM/actions'//ENGINE_PAYMENT_DOCUMENT_BY_ID
import * as paymentReleaseTypes from './components/WorkflowPayment/PaymentApprovalRelease/types'
import * as paymentReleaseActions from './components/WorkflowPayment/PaymentApprovalRelease/actions'
import * as paymentAuthorisationTypes from './components/WorkflowPayment/PaymentApprovalAuthorisation/types'
import * as paymentAuthorisationActions from './components/WorkflowPayment/PaymentApprovalAuthorisation/actions'
import * as paymentAllocationTypes from './components/WorkflowPayment/PaymentAllocation/types'
import * as paymentAllocationActions from './components/WorkflowPayment/PaymentAllocation/actions'
import * as formActions from './components/Form/actions'
import * as creditorsPaymentCreateTypes from './components/CreditorsPayment/CreditorsPaymentCreate/types'
import * as CreditorsPaymentCreateActions from './components/CreditorsPayment/CreditorsPaymentCreate/actions'

let ui

const isCurrentUser = (store, data) => {
    return store.getState().user.firstname +'_'+ store.getState().user.cognito_key === data.cognito_key
}

const refreshWorkflowQueueTaskData = (store, message, event, data) => {
    let pathname = store.getState().router.location.pathname ? store.getState().router.location.pathname : store.getState().redirect.path
    if(pathname) {
        if (pathname.indexOf('/approval') > -1 && event === 'ENGINE_WORKFLOW_TASK_REJECTED' && isCurrentUser(store, data)) {
            store.dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'You successfully rejected this task!',
                    overflowText: store.getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })

            store.dispatch(routerActions.route('/tasks'))
        }
        else if (pathname === '/tasks') {
            store.dispatch(workflowQueueTasksActions.refreshTeamData(message))
        }
    }
}

const createEngineEventListeners = (store, ui) => {
    const engineEvents = [
        //messengerTypes.ENGINE_WORKFLOW_QUEUE_TASK_ACTIVE,
        //messengerTypes.ENGINE_WORKFLOW_QUEUE_TASK_CLEANED,
        messengerTypes.ENGINE_WORKFLOW_TASK_ASSIGNED,
        messengerTypes.ENGINE_WORKFLOW_TASK_CANCELLED,
        messengerTypes.ENGINE_WORKFLOW_QUEUE_TASK_COMPLETED,
        //messengerTypes.ENGINE_WORKFLOW_QUEUE_TASK_ERROR,
        //messengerTypes.ENGINE_WORKFLOW_QUEUE_TASK_FAILED,
        //messengerTypes.ENGINE_WORKFLOW_QUEUE_TASK_PAUSED,
        //messengerTypes.ENGINE_WORKFLOW_QUEUE_TASK_PROGRESS,
        messengerTypes.ENGINE_WORKFLOW_TASK_REASSIGNED,
        messengerTypes.ENGINE_WORKFLOW_TASK_REJECTED,
        messengerTypes.ENGINE_WORKFLOW_TASK_REQUEUED,
        //messengerTypes.ENGINE_WORKFLOW_QUEUE_TASK_RESUMED,
        //messengerTypes.ENGINE_WORKFLOW_QUEUE_TASK_STALLED,
        messengerTypes.ENGINE_WORKFLOW_TASK_UNASSIGNED
    ]

    engineEvents.forEach(x => {
        ui.on(x, (data) => {
            store.dispatch({
                type: x,
                payload: data
            })

            refreshWorkflowQueueTaskData(store, data, x, data)
        })
    })
}

///ui = sockets(config.system.messenger.uri, { origins:"*", transports: ["websocket"]})
export const messenger = (store) => (next) => (action) => {
    try {
        
        switch (action.type) {
            case cognitoTypes.SIGN_OUT_COGNITO_USER:
                //console.log('disconnectx SIGN_OUT_COGNITO_USER')
                ui.disconnect(true)  
            case messengerTypes.UI_DISCONNECT_FROM_MESSENGER:
                //console.log('disconnectx  UI_DISCONNECTED_FROM_MESSENGERx')
                ui.disconnect(true)
                break
            case messengerTypes.CLIENT_DISCONNECT_FROM_MESSENGER:
                    ui.emit('clients_deregister', store.getState().user.firstname +'_'+ store.getState().user.cognito_key, () => {
                    })
                    
                    store.dispatch({
                        type: appTypes.SET_SHOW_DIALOG_NOTIFICATION_LOGGED_TWICE,
                        payload: {
                            status: false
                        }
                    })
                break
            case messengerTypes.CONNECT_USER_TO_MESSENGER:
                
                ui = sockets(config.system.messenger.uri, { origins:"*", transports: ["websocket"]})
                createEngineEventListeners(store, ui)

                ui.on('connect', () => {
                    //console.log('store.getState().messenger.connected',store.getState().messenger.connected)

                    if(!store.getState().messenger.connected) {
                        ui.emit('clients_register', {name: store.getState().user.firstname, key: store.getState().user.cognito_key}, () => {
                        })
                    }

                    ui.on('clients_connected', (err) => {
                        
                        store.dispatch({
                            type: messengerTypes.UI_CONNECTED_TO_MESSENGER,
                            payload: {
                                connected: true
                            }
                        })
    
                        store.dispatch({
                            type: appTypes.SHOW_NOTIFICATION,
                            payload: {
                                title: 'System Notification',
                                additionalText: 'Messenger connected, workflow engine initialising...',
                                overflowText: store.getState().login.fields.email.input,
                                autoHide: 10000,
                                timestamp: moment().format('h:mm A')
                            }
                        })
                    })

                    ui.on('error_exists', (err) => {
                        store.dispatch({
                            type: appTypes.SHOW_NOTIFICATION,
                            payload: {
                                title: 'System Notification',
                                additionalText: 'User already logined...',
                                overflowText: store.getState().login.fields.email.input,
                                autoHide: 10000,
                                type: 'error',
                                timestamp: moment().format('h:mm A')
                            }
                        })
    
                        //showLoggedDialog
                        store.dispatch({
                            type: appTypes.SET_SHOW_DIALOG_NOTIFICATION_LOGGED_TWICE,
                            payload: {
                                status: true
                            }
                        })
                    })
                })

                ui.on('error', (err) => {
                    //console.log('ERROR_MESSENGER',err)
                    //console.log('ERROR_MESSENGER URI',config.system.messenger.uri)
                })

                ui.on('connect_error', (err) => {
                    //console.log('ERROR_MESSENGER',err)
                    //console.log('ERROR_MESSENGER URI',config.system.messenger.uri)
                    store.dispatch({
                        type: messengerTypes.UI_FAILED_TO_CONNECT_TO_MESSENGER,
                        payload: err
                    })
                })

                ui.on(documentTypes.DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_SUCCESSFUL, (data) => {
                    store.dispatch({
                        type: documentTypes.DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_SUCCESSFUL,
                        payload: data
                    })
                })

                ui.on(documentTypes.DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_FAILED, (data) => {
                    store.dispatch({
                        type: documentTypes.DOCUMENT_UPLOAD_COMPONENT_DOCUMENT_FAILED,
                        payload: data
                    })
                })

                ui.on(documentTypes.DOCUMENT_LINK_UPLOADED_DOCUMENTS_SUCCESSFUL, (data) => {
                    store.dispatch({
                        type: documentTypes.DOCUMENT_LINK_UPLOADED_DOCUMENTS_SUCCESSFUL,
                        payload: data
                    })

                    store.dispatch(documentActions.notifyUser('Your upload session was successfully concluded. You can upload more documents at any time.'))
                })

                ui.on(documentTypes.DOCUMENT_LINK_UPLOADED_DOCUMENTS_FAILED, (data) => {
                    store.dispatch({
                        type: documentTypes.DOCUMENT_LINK_UPLOADED_DOCUMENTS_FAILED,
                        payload: data
                    })
                })

                ui.on(messengerTypes.ENGINE_WORKFLOW_TASK_REJECTED_FAILED, (message) => {
                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: message.data.response,
                            overflowText: 'Task Rejection Failed',
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })
                })

                ui.on(messengerTypes.ENGINE_DOCUMENT_DOES_NOT_EXIST, (data) => {
                    //console.log('ENGINE_DOCUMENT_DOES_NOT_EXIST messengerTypes',data)
                    // store.dispatch({
                    //     type: appTypes.SHOW_NOTIFICATION,
                    //     payload: {
                    //         title: 'System Notification',
                    //         additionalText: 'Document download failed!',
                    //         overflowText: store.getState().login.fields.email.input,
                    //         autoHide: 10000,
                    //         timestamp: moment().format('h:mm A')
                    //     }
                    // })
                })
                ui.on(messengerTypes.GENERATE_CREDITORS_PAYMENT_DOCUMENTS_DONE, (data) => {
                    store.dispatch(CreditorsPaymentCreateActions.downlaodCreditorsPaymentDocuments(data))
                })
                ui.on(messengerTypes.GENERATE_CREDITORS_PAYMENT_DOCUMENTS_ERROR, (data) => {
                    store.dispatch(CreditorsPaymentCreateActions.downlaodCreditorsPaymentDocumentsError(data))
                })
                ui.on(messengerTypes.ENGINE_DOCUMENT_CONTENT, (data) => {
                    store.dispatch(documentActions.viewDocument(data))
                })

                ui.on(messengerTypes.ENGINE_EDIT_QUOTE_RFQ_DOCUMENT_CONTENT, (data) => {
                    store.dispatch(workflowTaskApprovalActions.viewDocument(data))
                })
                ui.on(messengerTypes.ENGINE_CARD_COMPONENT_REPORT_DATA_EXCEL, (data) => {
                    store.dispatch(cardManagementAction.viewDocument(data))
                })
                ui.on(messengerTypes.ENGINE_CIS_DOWNLOAD_DATA, (data) => {
                    store.dispatch(cisAction.viewDocument(data))
                })
                ui.on(messengerTypes.ENGINE_CIS_SEND_EMAIL, (data) => {
                    store.dispatch(cisAction.sendDocument(data))
                })
                ui.on(messengerTypes.ENGINE_GET_CIS_HISTORY_FILE, (data) => {
                    store.dispatch(cisAction.viewCIShistoryFile(data))
                })
                ui.on(messengerTypes.ENGINE_QUOTE_REVIEW_RFQ_DOCUMENT_CONTENT, (data) => {
                    store.dispatch(workflowTaskApprovalActions.setDownloadDocument(data))
                })

                ui.on(messengerTypes.ENGINE_QUOTE_DOCUMENT_CONTENT, (data) => {
                    store.dispatch(pdfActions.viewDocument(data))
                })
                ui.on(messengerTypes.ENGINE_WORKFLOW_DOCUMENT_CONTENT, (data) => {
                    store.dispatch(workflowDocumentsActions.viewDocument(data))
                })
                ui.on(messengerTypes.ENGINE_DOWNLOAD_PDF_DOCUMENT_FROM_TABLE, (data) => {
                    store.dispatch(workflowDocumentsActions.viewDocument(data))
                })

                ui.on(messengerTypes.ENGINE_COMPONENT_FINE_NOMINATION_FORM, (data) => {
                    store.dispatch(formActions.downloadNominationForm(data))
                })

                ui.on(messengerTypes.ENGINE_RECEIPT_ACCESSORY_DOCUMENT_CONTENT, (data) => {
                    store.dispatch(uploadAccessoryActions.setDocumentPreview(data))
                })

                ui.on(messengerTypes.ENGINE_GRID_QUOTE_DOCUMENT_CONTENT, (data) => {
                    store.dispatch(quoteSelectGridActions.viewDocument(data))
                })

                ui.on(messengerTypes.ENGINE_PDF_MULTIPLE_DOCUMENT_CONTENT, (data) => {
                    store.dispatch(pdfActions.setDocumentPreview(data))
                })
                ui.on(messengerTypes.ENGINE_CUSTOMER_STATEMENT_LIST, (data) => {
                    store.dispatch(customerStatementActions.setCustomerStatementList(data))
                })

                ui.on(messengerTypes.ENGINE_REBILLS, (data) => {
                    store.dispatch(customerStatementActions.setRebillPDF(data))
                })

                ui.on(messengerTypes.ENGINE_PAYMENT_DOCUMENT, (data) => {
                    //console.log(">>>>>>>>>>>>> Emitted <<<<<<<<<<<<<", data)
                    store.dispatch(paymentsActions.setPaymentDocumentPDF(data))
                })

                ui.on(messengerTypes.ENGINE_PAYMENT_DOCUMENT_BY_ID, (data) => {
                    //console.log(">>>>>>>>>>>>> Emitted <<<<<<<<<<<<<", data)
                    store.dispatch(paymentApprovalGMActions.setPaymentDocumentByIdPDF(data))
                })


                ui.on(messengerTypes.ENGINE_SUPPLIER_TRANSACTION_DOCUMENT, (data) => {
                    //console.log(">>>>>>>>>>>>> Emitted <<<<<<<<<<<<<", data)
                    store.dispatch(paymentsActions.setDownloadedPDF(data))
                })
                ui.on(messengerTypes.ENGINE_PAYMENT_NOTIFICATION, (data) => {
                    //console.log(">>>>>>>>>>>>> Emitted <<<<<<<<<<<<<", data)
                    store.dispatch(paymentsActions.setPaymentNotification(data))
                })
                ui.on(messengerTypes.ENGINE_VEHICLE_BOOKING_SUPPLIER, (data) => {
                    store.dispatch(poHeaderActions.setBookingSuppliers(data))
                })

                ui.on(messengerTypes.ENGINE_SUPPLIER_MONTHLY_USAGE, (data) => {
                    store.dispatch(poHeaderActions.setSupplierMonthlyUsage(data))
                })

                ui.on(messengerTypes.ENGINE_SUPPLIER_MONTHLY_SPEND, (data) => {
                    store.dispatch(controlsActions.setSupplierMonthlySpend(data))
                })

                ui.on(messengerTypes.ENGINE_DOCUMENT_DISCOUNT_LETTER, (data) => {
                    store.dispatch(poBookingInvoiceActions.setDiscountLetter(data))
                })

                ui.on(messengerTypes.ENGINE_SUPPLIER_TRANSACTION_LIST, (data) => {
                    store.dispatch(supplierTransactionActions.setSupplierTransactionList(data))
                })
                ui.on(messengerTypes.ENGINE_CUSTOMER_TRANSACTION_LIST, (data) => {
                    store.dispatch(customerTransactionCustomActions.setCustomerTransactionList(data))
                })
                ui.on(messengerTypes.ENGINE_VEHICLE_LASTPOSITION, (data) => {
                    store.dispatch(poBookingInvoiceActions.setVehicleLastPosition(data))
                })

                ui.on(messengerTypes.ENGINE_EXCEL_DOCUMENT, (data) => {
                    store.dispatch(gridActions.viewDocument(data))
                })

                ui.on(messengerTypes.ENGINE_VEHICLE_SERVICE_DUE_FUNCTION, (data) => {
                    store.dispatch(poBookingActions.setServiceDetails(data))
                })

                ui.on(messengerTypes.ENGINE_MAINTENANCE_HOSTORY_PDF_DOCUMENT, (data) => {
                    store.dispatch(poHeaderActions.setMaintenanceHistoryPDF(data))
                })
                ui.on(messengerTypes.ENGINE_WS_MAINTENANCE_HOSTORY_PDF_DOCUMENT, (data) => {
                    store.dispatch(workflowVehicleHistoryActions.setMaintenanceHistoryPDF(data))
                })

                ui.on(messengerTypes.ENGINE_CUSTOMER_CREDIT_NOTE_PDF, (data) => {
                    store.dispatch(customerCreditNoteActions.setCustomerCreditNotePDF(data))
                })

                ui.on(messengerTypes.ENGINE_ESCALATION_MESSAGE, (data) => {
                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: data.data.message,
                            overflowText: 'Task Overdue',
                            autoHide: 60000 * (data.data.interval > 2 ? data.data.interval - 2 : data.data.interval),
                            timestamp: moment().format('h:mm A')
                        }
                    })
                })

                // ui.on(messengerTypes.ENGINE_CUSTOMER_INVOICE_PDF, (data) => {
                //     store.dispatch(customerInvoiceActions.setCustomerInvoicePDF(data))
                // })
                ui.on(messengerTypes.ENGINE_CUSTOMER_INVOICE_PDF, (data) => {
                    store.dispatch(customerTransactionActions.setCustomerInvoicePDF(data))
                })
                ui.on(messengerTypes.ENGINE_TERMINATION_INVOICE_PDF, (data) => {
                    store.dispatch(contracttermActions.setTermInvoicePDF(data))
                })
                ui.on(messengerTypes.ENGINE_TRIAL_BALANCE_PDF, (data) => {
                    store.dispatch(trialbalanceactions.setTrialBalancePDF(data))
                })
                ui.on(messengerTypes.ENGINE_TRIAL_BALANCE_REFRESH, (data) => {
                    store.dispatch(trialbalanceactions.setRefreshedTrialBalance(data))
                })
                ui.on(messengerTypes.ENGINE_CIS_EXCEL, (data) => {
                    store.dispatch(billingmoduleactions.setCISExcel(data))
                })
                ui.on(messengerTypes.ENGINE_BILLING_RERUN, (data) => {
                    store.dispatch(billingmoduleactions.setBillingRerun(data))
                })
                ui.on(messengerTypes.ENGINE_EXPORT_ENTEREST_EXCEL_DOCUMENT, (data) => {
                    store.dispatch(enterestReviewAction.downloadEnterest(data))
                })
                ui.on(messengerTypes.ENGINE_EXPORT_STATEMENT_EXCEL_DOCUMENT, (data) => {
                    store.dispatch(statementPreviewAction.downloadStatement(data))
                })
                ui.on(messengerTypes.ENGINE_GENERATE_STATEMENT_FOR_REVIEW, (data) => {
                    store.dispatch(statementPreviewAction.previewStatement(data))
                })
                ui.on(messengerTypes.ENGINE_QUEUE_STATEMENT_SEND_OUT, (data) => {
                    store.dispatch(statementPreviewAction.statementsQueued(data))
                })
                ui.on(messengerTypes.ENGINE_XERO_TRANSACTIONS, (data) => {
                    store.dispatch(xeroActions.setXeroTransactions(data))
                })

                ui.on(messengerTypes.ENGINE_PREVIEW_INVOICE, (data) => {
                    store.dispatch(billingmoduleactions.setPreviewInvoice(data))
                })

                ui.on(messengerTypes.ENGINE_REMITTANCE_ADVICE_PDF, (data) => {
                    //hi moses, i commented this line because the action doesn't exist in the latest pull i did (2018/12/15 22:13)
                    //store.dispatch(supplierInvoiceActions.setRemittanceAdvicePDF(data))
                })

                ui.on(messengerTypes.ENGINE_PRE_AUTHORISATION_NUMBER, (data) => {
                    store.dispatch(poHeaderActions.setPreAuthNumber(data))
                })

                ui.on(messengerTypes.ENGINE_DEFLEET_NUMBER, (data) => {
                    store.dispatch(workflowDefleetActions.setDefleetNumber(data))
                })

                ui.on(messengerTypes.ENGINE_SUBMITTED_PAYMENT, (data) => {
                    store.dispatch(paymentReleaseActions.setPaymentResponse(data))
                })
                ui.on(messengerTypes.ENGINE_AUTHORISED_PAYMENT, (data) => {
                    store.dispatch(paymentAuthorisationActions.setPaymentResponse(data))
                })
                ui.on(messengerTypes.ENGINE_ALLOCATED_PAYMENT, (data) => {
                    store.dispatch(paymentAllocationActions.setPaymentResponse(data))
                })
                ui.on(messengerTypes.ENGINE_BATCH_NUMBER, (data) => {
                    store.dispatch(workflowPaymentActions.setBatchNumber(data))
                })
                ui.on(messengerTypes.ENGINE_KPO_NUMBER, (data) => {
                    store.dispatch(defleetAcceptRebillActions.setKPONumber(data))
                })

                ui.on(messengerTypes.ENGINE_DEFLEET_CHECKSHEET, (data) => {
                    store.dispatch(workflowDefleetChecksheetActions.setDefleetChecksheet(data))
                })

                ui.on(messengerTypes.ENGINE_DEFLEET_SIGNOFF, (data) => {
                    store.dispatch(defleetUploadQuotesConfirmActions.setDefleetSignoff(data))
                })

                ui.on(messengerTypes.ENGINE_SUPPLIER_PAYMENT_ACCOUNT, (data) => {
                    store.dispatch(supplierPaymentActions.setSupplierPaymentAccount(data))
                })

                ui.on(messengerTypes.ENGINE_SUPPLIER_ALLOCATION_DATA, (data) => {
                    store.dispatch(supplierAllocationActions.setSupplierAllocationData(data))
                })

                ui.on(messengerTypes.ENGINE_CUSTOMER_ALLOCATION_DATA, (data) => {
                    store.dispatch(customerAllocationActions.setCustomerAllocationData(data))
                })
                ui.on(messengerTypes.ENGINE_CUSTOMER_STATEMENT_PDF, (data) => {
                    store.dispatch(customerStatementActions.setCustomerStatementPDF(data))
                })

                ui.on(messengerTypes.ENGINE_CUSTOMER_BILLING_INVOICE, (data) => {
                    store.dispatch(customerStatementActions.setCustomerBillingInvoice(data))
                })

                ui.on(messengerTypes.ENGINE_DUPLICATE_REFERENCE_CHECK, (data) => {
                    store.dispatch(customerCreditNoteActions.setDuplicateReferenceCheck(data))
                })

                ui.on(messengerTypes.ENGINE_CN_DUPLICATE_REFERENCE_CHECK, (data) => {
                    window.glyco.log(data)
                    store.dispatch(supplierCreditNoteActions.setDuplicateReferenceCheck(data))
                })

                ui.on(messengerTypes.ENGINE_CREDITORS_JOURNAL_DUPLICATE_REFERENCE_CHECK, (data) => {
                    window.glyco.log(data)
                    store.dispatch(supplierJournalActions.setDuplicateReferenceCheck(data))
                })

                // ui.on(messengerTypes.ENGINE_INVOICE_DUPLICATE_REFERENCE_CHECK, (data) => {
                //     store.dispatch(customerInvoiceActions.setDuplicateReferenceCheck(data))
                // })

                ui.on(messengerTypes.ENGINE_INVOICE_DUPLICATE_REFERENCE_CHECK, (data) => {
                    store.dispatch(customerTransactionActions.setDuplicateReferenceCheck(data))
                })

                ui.on(messengerTypes.ENGINE_JOURNAL_DUPLICATE_REFERENCE_CHECK, (data) => {
                    store.dispatch(customerJournalActions.setDuplicateReferenceCheck(data))
                })

                ui.on(messengerTypes.ENGINE_RECEIPT_DUPLICATE_REFERENCE_CHECK, (data) => {
                    store.dispatch(customerReceiptActions.setDuplicateReferenceCheck(data))
                })

                ui.on(messengerTypes.ENGINE_REVERSE_RECEIPT_DUPLICATE_REFERENCE_CHECK, (data) => {
                    store.dispatch(customerReverseReceiptActions.setDuplicateReferenceCheck(data))
                })

                ui.on(messengerTypes.ENGINE_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES, (data) => {
                    store.dispatch(workflowQuoteHeaderActions.setMMCodeRvAndTyres(data))
                })

                ui.on(messengerTypes.ENGINE_QUOTE_HEADER_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES, (data) => {
                    store.dispatch(quoteHeaderActions.setQHMMCodeRvAndTyres(data))
                })

                ui.on(messengerTypes.ENGINE_SUPPLIER_INVOICE_DUPLICATE_REFERENCE_CHECK, (data) => {
                    store.dispatch(supplierInvoiceActions.setDuplicateReferenceCheck(data))
                })

                ui.on(messengerTypes.ENGINE_REVERSE_PAYMENT_DUPLICATE_REFERENCE_CHECK, (data) => {
                    store.dispatch(supplierPaymentReversalActions.setDuplicateReferenceCheck(data))
                })

                ui.on(messengerTypes.ENGINE_PURCHASE_ORDERS_SEARCH, (data) => {
                    store.dispatch(workflowInvoiceApActions.setPurchaseorderinvoiceAutoCompleteSelected(data))
                })

                ui.on(messengerTypes.ENGINE_SUPPLIER_PAYMENT_DUPLICATE_REFERENCE_CHECK, (data) => {
                    store.dispatch(supplierPaymentActions.setDuplicateReferenceCheck(data))
                })

                ui.on(messengerTypes.ENGINE_BILLING_INVOICE_PDF, (data) => {
                    store.dispatch(billingRunViewActions.viewPdfDocument(data))
                })
                ui.on(messengerTypes.ENGINE_REFRESH_BILLING, (data) => {
                    //console.log('ENGINE_REFRESH_BILLINGx msg',data)
                    store.dispatch(billingRefreshActions.billingRefreshDon(data))
                })
                ui.on(messengerTypes.ENGINE_GENRATE_PREVIEW_BILLING_INVOICE, (data) => {
                    store.dispatch(billingRefreshActions.downloadInvoice(data))
                })
                ui.on(messengerTypes.ENGINE_EXPORT_BILLING_IETMS_EXCEL_DOCUMENT, (data) => {
                    store.dispatch(billingRefreshActions.downloadBillingItems(data))
                })
                ui.on(messengerTypes.ENGINE_EXPORT_BILLING_INVOICE_EXCEL_DOCUMENT, (data) => {
                    store.dispatch(billingPreviewActions.downloadBillingInviceList(data))
                })
                ui.on(messengerTypes.ENGINE_GENERATE_BILLING_INVOICE_FOR_REVIEW, (data) => {
                    store.dispatch(billingRefreshActions.downloadInvoice(data))
                    ///store.dispatch(billingRefreshActions.downloadBillingItems(data))
                })
                ui.on(messengerTypes.ENGINE_GENERATE_BILLING_INVOICE_FOR_PREVIEW, (data) => {
                    store.dispatch(billingPreviewActions.downloadInvoice(data))
                })
                ui.on(messengerTypes.ENGINE_SEND_CUSTOMER_BILLING_INVOICE, (data) => {
                    store.dispatch(billingPreviewActions.sendBillingInvoiceNotify(data))
                })
                ui.on(messengerTypes.ENGINE_CASHBOOK_PDF_REPORT_DONE, (data) => {
                    store.dispatch(cashbookActions.viewPdfDocument(data))
                })
                ui.on(messengerTypes.ENGINE_BANKRECON_PDF_REPORT_DONE, (data) => {
                    store.dispatch(bankReconActions.viewPdfDocument(data))
                })
                ui.on(messengerTypes.ENGINE_SENT_CUSTOMER_INVOICE_NOTIFICATION, (data) => {
                    store.dispatch(billingPreviewActions.refreshStatusData(data))
                })
                ui.on(messengerTypes.ENGINE_STR_WORKINGS_PDF_DOWNLOAD, (data) => {
                    store.dispatch(strActions.viewPdfDocument(data))
                })
                ui.on(messengerTypes.ENGINE_DOWNLAOD_DISPOSAL_COMPONENT_DOCUMENT, (data) => {
                    store.dispatch(workflowDisposalActions.viewPdfDocument(data))
                })
                ui.on(messengerTypes.ENGINE_DISPOSAL_CHEEKSHEET_DOCUMENT, (data) => {
                    store.dispatch(workflowDisposalActions.downlaodDisposalChecksheet(data))
                })
                ui.on(messengerTypes.ENGINE_DISPOSAL_INVOICE_DOCUMENT, (data) => {
                    store.dispatch(workflowDisposalActions.downlaodDisposalInvoice(data))
                })
                ui.on(messengerTypes.GET_JSREPORT_PREVIEW_DOCUMENT_DONE, (data) => {
                    store.dispatch(jsReportActions.viewPdfDocument(data))
                })

                ui.on(messengerTypes.ENGINE_CONNECTION_LOST, () => {
                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'Warning! Engine is currently offline!',
                            overflowText: store.getState().login.fields.email.input,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })
                })


                ui.on(messengerTypes.ENGINE_NEW_WORKFLOW_TASK, (message) => {
                    const workflow = store.getState().workflows[message.data.workflow_id]

                    if (!workflow) {
                        return
                    }

                    store.dispatch({
                        type: messengerTypes.ENGINE_NEW_WORKFLOW_TASK,
                        payload: message.data
                    })

                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'A new task "(' + workflow.title + ') ' + message.data.workflow_task_title + '" has been assigned to your team!',
                            overflowText: store.getState().login.fields.email.input,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })
                })

                ui.on(messengerTypes.ENGINE_CONNECTED_TO_MESSENGER, (data) => {
                    store.dispatch({
                        type: messengerTypes.ENGINE_CONNECTED_TO_MESSENGER,
                    })
                })

                ui.on(messengerTypes.ENGINE_DISCONNECTED_FROM_MESSENGER, (data) => {
                    store.dispatch({
                        type: messengerTypes.ENGINE_DISCONNECTED_FROM_MESSENGER,
                    })
                })

                ui.on(messengerTypes.ENGINE_WORKFLOW_TASK_APPROVED, (message) => {
                    store.dispatch({
                        type: messengerTypes.ENGINE_WORKFLOW_TASK_APPROVED,
                        payload: message
                    })

                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'You successfully approved this task!',
                            overflowText: store.getState().login.fields.email.input,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    store.dispatch({
                        type: workflowQueueTasksTypes.CLEAR_WORKFLOW_QUEUE_TASKS
                    })

                    store.dispatch(routerActions.route('/tasks'))
                })

                ui.on(messengerTypes.ENGINE_MAIL_WORKFLOW_TASK_APPROVED, (message) => {
                    store.dispatch({
                        type: messengerTypes.ENGINE_MAIL_WORKFLOW_TASK_APPROVED,
                        payload: message
                    })

                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'You successfully approved a task',
                            overflowText: 'Workflow Activity',
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    store.dispatch({
                        type: workflowQueueTasksTypes.CLEAR_WORKFLOW_QUEUE_TASKS
                    })
                })

                ui.on(messengerTypes.ENGINE_WORKFLOW_TASK_ESCALATED, (message) => {
                    store.dispatch({
                        type: messengerTypes.ENGINE_WORKFLOW_TASK_ESCALATED,
                        payload: message
                    })

                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'You successfully escalated this task!',
                            overflowText: store.getState().login.fields.email.input,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    // store.dispatch({
                    //     type: workflowQueueTasksTypes.CLEAR_WORKFLOW_QUEUE_TASKS
                    // })

                    store.dispatch(routerActions.route('/tasks'))
                })

                ui.on(messengerTypes.ENGINE_WORKFLOW_TASK_SUBMITTED, (message) => {
                    store.dispatch({
                        type: messengerTypes.ENGINE_WORKFLOW_TASK_SUBMITTED,
                        payload: message
                    })

                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'Your request was acknowledged by the workflow engine.',
                            overflowText: store.getState().login.fields.email.input,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })
                    
                    store.dispatch({
                        type: workflowQueueTasksTypes.CLEAR_WORKFLOW_QUEUE_TASKS
                    })

                    store.dispatch(routerActions.route('/tasks'))
                })

                ui.on(messengerTypes.ENGINE_ACCESSORY_DOCUMENT_WORKFLOW_TASK_SUBMITTED, (message) => {
                    store.dispatch({
                        type: messengerTypes.ENGINE_ACCESSORY_DOCUMENT_WORKFLOW_TASK_SUBMITTED,
                        payload: message
                    })

                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'Your successfully submitted the task\'s input data!',
                            overflowText: store.getState().login.fields.email.input,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    // store.dispatch({
                    //     type: workflowQueueTasksTypes.CLEAR_WORKFLOW_QUEUE_TASKS
                    // })

                    // store.dispatch(routerActions.route('/tasks'))
                })

                ui.on(messengerTypes.ENGINE_WORKFLOW_COMPLETED, (message) => {
                    store.dispatch({
                        type: messengerTypes.ENGINE_WORKFLOW_COMPLETED,
                        payload: message
                    })

                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'Workflow cycle completed!',
                            overflowText: store.getState().login.fields.email.input,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })
                })

                ui.on('event', (data) => {
                    store.dispatch({
                        type: messengerTypes.UI_RECEIVED_EVENT_FROM_MESSENGER,
                        payload: {
                            message: data
                        }
                    })
                })

                ui.on('disconnect', () => {
                    //console.log('disconnectx UI_DISCONNECTED_FROM_MESSENGER')
                    store.dispatch({
                        type: messengerTypes.UI_DISCONNECTED_FROM_MESSENGER,
                        payload: {
                            connected: false
                        }
                    })
                })

                break
            case poHeaderTypes.GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case supplierPaymentTypes.GET_SUPPLIER_PAYMENT_ACCOUNT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case supplierAllocationTypes.GET_SUPPLIER_ALLOCATION_DATA:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case customerStatementTypes.GET_CUSTOMER_STATEMENT_LIST:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break

            case customerStatementTypes.GEN_CUSTOMER_STATEMENT_PDF:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case customerStatementTypes.GEN_CUSTOMER_BILLING_INVOICE:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case customerStatementTypes.GEN_CUSTOMER_BILLING_STATEMENT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case customerStatementTypes.GENERATE_CUSTOMER_STATEMENT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case customerStatementTypes.GET_REBILLS:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case cisTypes.GET_CIS_DOWNLOAD_DATA:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case cisTypes.GET_CIS_SEND_EMAIL_DATA:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case cisTypes.GET_CIS_HISTORY_FILE:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case paymentReleaseTypes.SUBMIT_PAYMENT_REQUEST:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case paymentAuthorisationTypes.AUTHORISE_PAYMENT_REQUEST:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case paymentAllocationTypes.ALLOCATE_PAYMENT_REQUEST:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case paymentsTypes.GET_PAYMENT_DOCUMENT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case paymentApprovalGMTypes.GET_PAYMENT_DOCUMENT_BY_ID:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case paymentsTypes.GENERATE_PAYMENT_NOTIFICATION:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case paymentsTypes.GET_SUPPLIER_TRANSACTION_DOCUMENT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case customerStatementTypes.GEN_CUSTOMER_BILLING_STATEMENT_EXCEL:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST
                    })
                })
                break
            case customerCreditNoteTypes.GET_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case supplierCreditNoteTypes.GET_CN_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            // case customerInvoiceTypes.GET_INVOICE_DUPLICATE_REFERENCE_CHECK:
            //     ui.emit('raw_handle', action.payload, (data) => {
            //         store.dispatch({
            //             type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
            //             payload: data
            //         })
            //     })
            //     break
            case customerTransactionTypes.GET_INVOICE_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break

            case supplierJournalTypes.GET_CREDITORS_JOURNAL_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case supplierInvoiceTypes.GET_SUPPLIER_INVOICE_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case supplierPaymentTypes.GET_SUPPLIER_PAYMENT_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case supplierPaymentReversalTypes.GET_REVERSE_PAYMENT_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowInvoiceApTypes.GET_PURCHASE_ORDERS_SEARCH:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case customerJournalTypes.GET_JOURNAL_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break

            case customerReceiptTypes.GET_RECEIPT_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case customerReverseReceiptTypes.GET_REVERSE_RECEIPT_DUPLICATE_REFERENCE_CHECK:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case customerAllocationTypes.GET_CUSTOMER_ALLOCATION_DATA:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break

            case poHeaderTypes.GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case poHeaderTypes.GET_SUPPLIER_MONTHLY_SPEND_USAGE:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case controlsTypes.GET_SUPPLIER_MONTHLY_SPEND:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowVehicleHistoryTypes.GET_MAINTENANCE_HOSTORY_PDF_DOCUMENT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            // case workflowVehicleHistoryTypes.GET_MAINTENANCE_HISTORY_PDF_DOCUMENT:
            //     ui.emit('raw_handle', action.payload, (data) => {
            //         store.dispatch({
            //             type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
            //             payload: data
            //         })
            //     })

            //     break
            case customerCreditNoteTypes.GEN_CUSTOMER_CREDIT_NOTE_PDF:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            // case customerInvoiceTypes.GEN_CUSTOMER_INVOICE_PDF:
            //     ui.emit('raw_handle', action.payload, (data) => {
            //         store.dispatch({
            //             type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
            //             payload: data
            //         })
            //     })

            //     break
            case customerTransactionTypes.GEN_CUSTOMER_INVOICE_PDF:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case contracttermtypes.GEN_TERMINATION_INVOICE_PDF:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingmoduletypes.GEN_CIS_EXCEL:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case trialbalancetypes.REFRESH_TRIAL_BALANCE:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case trialbalancetypes.GEN_TRIAL_BALANCE_PDF:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingmoduletypes.BILLING_RERUN:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingmoduletypes.GENERATE_PREVIEW_INVOICE:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case customerStatementTypes.GEN_CUSTOMER_SAP_BILLING_STATEMENT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case customerStatementTypes.GEN_CUSTOMER_TFR_BILLING_STATEMENT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case supplierInvoiceTypes.GEN_REMITTANCE_ADVICE_PDF:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case poBookingInvoiceTypes.GET_VEHICLE_LASTPOSITION:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case gridTypes.GET_EXCEL_GRID_EXPORT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case supplierTransactionTypes.GET_SUPPLIER_TRANSACTION_LIST:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case customerTransactionCustomTypes.GET_CUSTOMER_TRANSACTION_LIST:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case poHeaderTypes.GET_PRE_AUTHORISATION_NUMBER:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break

            case workflowDefleetTypes.GET_DEFLEET_NUMBER:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break

            case workflowPaymentTypes.GET_BATCH_NUMBER:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break            
            case creditorsPaymentCreateTypes.GENERATE_CREDITORS_PAYMENT_DOCUMENTS:
                //console.log('GENERATE_CREDITORS_PAYMENT_DOCUMENTSx', action.payload)
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break      
            case jsreportTypes.GET_JSREPORT_PREVIEW_DOCUMENT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break   
            case defleetAcceptRebillTypes.GET_KPO_NUMBER:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowDefleetChecksheetTypes.GENERATE_DEFLEET_CHECKSHEET:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case defleetUploadQuotesConfirmTypes.GENERATE_DEFLEET_SIGNOFF:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case poBookingTypes.GET_VEHICLE_SERVICE_DUE_FUNCTION:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowQuoteHeaderTypes.GET_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case quoteHeaderTypes.GET_QUOTE_HEADER_MMCODE_RV_AND_TYRE_MAINTENANCE_VALUES:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case reRUNTypes.PO_RERUN_STEP_ONE:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case reRUNTypes.PO_RERUN_STEP_TWO:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case documentTypes.DOCUMENT_UPLOAD_COMPONENT_DOCUMENT:
                //console.log('filesx DOCUMENT_UPLOAD_COMPONENT_DOCUMENTX', action)
                ui.emit('documents_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case documentTypes.DOCUMENT_LINK_UPLOADED_DOCUMENTS:
                ui.emit('documents_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break

            case workflowDisposalTypes.DOWNLAOD_DISPOSAL_COMPONENT_DOCUMENT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
                case workflowDisposalTypes.EXPORT_YARDLIST_TO_EXCEL:
                    //console.log('EXPORT_YARDLIST_TO_EXCEL')
                    ui.emit('raw_handle', action.payload, (data) => {
                        store.dispatch({
                            type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                            payload: data
                        })
                    })
                    break

                case workflowDisposalTypes.GET_DISPOSAL_CHEEKSHEET_DOCUMENT:
                    ui.emit('raw_handle', action.payload, (data) => {
                        store.dispatch({
                            type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                            payload: data
                        })
                    })
                    break
                case workflowDisposalTypes.GET_DISPOSAL_INVOICE_DOCUMENT:
                    ui.emit('raw_handle', action.payload, (data) => {
                        store.dispatch({
                            type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                            payload: data
                        })
                    })
                    break
            case workflowDisposalTypes.GET_EXCEL_GRID_EXPORT:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowDisposalTypes.UPLOAD_DISPOSAL_SIGNED_CHECKSHEET:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case documentTypes.DOWNLOAD_DOCUMENT:
                ui.emit('adhoc_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case pdfTypes.GET_QUOTE_DOCUMENTS:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case cashbookTypes.GET_CASHBOOK_PDF_REPORT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case cashbookTypes.GET_CASHBOOK_CSV_REPORT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case cashbookTypes.GET_CASHBOOK_XLS_REPORT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case bankReconTypes.GET_BANKRECON_PDF_REPORT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case bankReconTypes.GET_BANKRECON_CSV_REPORT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case bankReconTypes.GET_BANKRECON_XLS_REPORT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case strTypes.STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case workflowDisposalTypes.DOWNLAOD_DISPOSAL_COMPONENT_DOCUMENT:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case workflowDocumentsTypes.GET_WORKFLOW_DOCUMENT_FROM_DOWNLAOD_TABLE:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case poBookingInvoiceTypes.DOWNLOAD_DOCUMENT_DISCOUNT_LETTER:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowDocumentsTypes.WORKFLOW_DOWNLOAD_DOCUMENTS:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case workflowTaskApprovalTypes.DOWNLOAD_QUOTE_REVIEW_RFQ_DOCUMENTS:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case workflowTaskApprovalTypes.DOWNLOAD_REVIEW_SCHEDULE_DOCUMENTS:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case pdfTypes.GET_PDF_MULTIPLE_VIEW_DOCUMENTS_PREVIEW:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case uploadAccessoryTypes.GET_ACCESSORY_PO_RECEIPT_DOCUMENT_PREVIEW:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingRunViewTypes.GET_COMPONET_BILLING_RUN_INVOICE_PDF:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingRefreshTypes.REFRESH_BILLING:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingRefreshTypes.GENRATE_PREVIEW_BILLING_INVOICE:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingRefreshTypes.EXPORT_BILLING_ITEMS:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingPreviewTypes.EXPORT_BILLING_INVOICE_LIST:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case enterestReviewTypes.EXPORT_INTEREST_EXCEL_DATA:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case statementPreviewTypes.EXPORT_STATEMENT_PREVIEW_DATA:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case statementPreviewTypes.GET_STATEMENT_PREVIEW:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case statementPreviewTypes.QUEUE_STATEMENT_SEND_OUT:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case xeroTypes.GET_XERO_TRANSACTIONS:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingPreviewTypes.GENERATE_BILLING_INVOICE_FOR_PREVIEW:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case billingPreviewTypes.SEND_CUSTOMER_BILLING_INVOICE:
                ui.emit('po_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowTaskApprovalTypes.EDIT_QUOTE_GET_RFQ_DOCUMENT:
                ui.emit('rfq_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case quoteSelectGridTypes.GET_AWS_QUOTE_DOCUMENT_LIST:
                ui.emit('quotedocument_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowQuoteHeaderTypes.UPLOAD_RFQ_QUOTE_DOCUMENT:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowViewFMLQuoteSummaryTypes.UPLOAD_RFQ_QUOTE_DOCUMENT:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case uploadTypes.UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case imageUploaderTypes.UPLOAD_MULTIPLE_IMAGE_COMPONENT_UPLOADER:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowCreateQuoteTypes.UPLOAD_MULTIPLE_IMAGE_COMPONENT_UPLOADER:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case uploadAccessoryTypes.UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowDisposalTypes.UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowPOReceiptVehicle.UPLOAD_RECEIPT_DOCUMENT:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case workflowPOHeaderTypes.SET_PO_UPLOAD_QUOTE_DOCUMENT:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case poBookingInvoiceTypes.UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case poBookingInvoiceUploadTypes.UPLOAD_BOOKING_INVOCE_DOCUMENT:
                ui.emit('s3upload_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })

                break
            case formTypes.GEN_COMPONENT_FINE_NOMINATION_FORM:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case cardManagementTypes.GET_CARD_COMPONENT_REPORT_DATA_EXCEL:
                ui.emit('pdf_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })
                break
            case broadcastTypes.SEND_BROADCAST_MESSAGE:
                ui.emit('raw_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })
                })


                ui.on(messengerTypes.ENGINE_BROADCAST_MESSAGE, (data) => {
                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: data.message,
                            overflowText: 'Message send by: ' + data.sender,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })
                })

            case workflowTaskApprovalTypes.APPROVE_WORKFLOW_QUEUE_TASK:
                ui.emit('tasks_handle',
                    {
                        ...action.payload,
                        event: workflowTaskApprovalTypes.APPROVE_WORKFLOW_QUEUE_TASK,
                        queue: store.getState().workflowQueueTasks.queue
                    },
                    (data) => {
                        store.dispatch({
                            type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                            payload: data
                        })

                        store.dispatch(routerActions.route('/tasks'))
                    })

                break
            case workflowTaskActionTypes.APPROVE_MAIL_WORKFLOW_QUEUE_TASK:
                ui.emit('tasks_handle',
                    {
                        ...action.payload,
                        event: workflowTaskActionTypes.APPROVE_MAIL_WORKFLOW_QUEUE_TASK,
                        queue: store.getState().workflowQueueTasks.queue
                    },
                    (data) => {
                        store.dispatch({
                            type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                            payload: data
                        })

                    })

                break
            case workflowTaskApprovalTypes.REJECT_WORKFLOW_QUEUE_TASK:
                ui.emit('tasks_handle',
                    {
                        ...action.payload,
                        event: workflowTaskApprovalTypes.REJECT_WORKFLOW_QUEUE_TASK,
                        queue: store.getState().workflowQueueTasks.queue
                    },
                    (data) => {
                        store.dispatch({
                            type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                            payload: data
                        })
                    })

                break
            case workflowTaskApprovalTypes.ESCALATION_TASK_TO_NEW_TEAMS:
                ui.emit('tasks_handle',
                    {
                        ...action.payload,
                        event: workflowTaskApprovalTypes.ESCALATION_TASK_TO_NEW_TEAMS,
                        queue: store.getState().workflowQueueTasks.queue
                    },
                    (data) => {
                        store.dispatch({
                            type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                            payload: data
                        })
                    })

                break
            case workflowQueueTasksTypes.ASSIGN_TASK:
            case workflowQueueTasksTypes.REASSIGN_TASK:
            case workflowQueueTasksTypes.CANCEL_TASK:
            case workflowQueueTasksTypes.REJECT_TASK:
            case workflowQueueTasksTypes.UNASSIGN_TASK:
                const getTaskStateMessage = (event) => {
                    if (event === workflowQueueTasksTypes.ASSIGN_TASK) return 'assignment'
                    if (event === workflowQueueTasksTypes.CANCEL_TASK) return 'cancellation'
                    if (event === workflowQueueTasksTypes.REASSIGN_TASK) return 'reassignment'
                    if (event === workflowQueueTasksTypes.REJECT_TASK) return 'rejection'
                    if (event === workflowQueueTasksTypes.UNASSIGN_TASK) return 'unassignment'
                }

                ui.emit('tasks_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })

                    /*                     store.dispatch({
                                            type: appTypes.SHOW_NOTIFICATION,
                                            payload: {
                                                title: 'Workflow Activity',
                                                additionalText: 'Your task "' + action.payload.workflow_task.title + '" was successfully submitted for ' + getTaskStateMessage(action.payload.event) + '.',
                                                overflowText: action.payload.workflow.title,
                                                autoHide: 7500,
                                                timestamp: moment().format('h:mm A')
                                            }
                                        }) */

                    store.dispatch({
                        type: workflowQueueTasksTypes.CLEAR_WORKFLOW_QUEUE_TASKS
                    })
                })

                break
            case workflowTaskInputTypes.SUBMIT_WORKFLOW_TASK_INPUT:
                
                //console.log('submit_datax submitted', workflowTaskInputTypes.SUBMIT_WORKFLOW_TASK_INPUT, action.payload)
                try {
                   
                    ui.emit('tasks_handle', action.payload, (data) => {
                        //console.log('submit_datax tasks_handle ', messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST)
                        store.dispatch({
                            type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                            payload: data
                        })
                        //console.log('submit_datax emit', data)
                        store.dispatch({
                            type: appTypes.SHOW_NOTIFICATION,
                            payload: {
                                title: 'Workflow Activity',
                                additionalText: 'Your data for task "' + action.payload.workflow_task.title + '" was successfully submitted.',
                                overflowText: action.payload.workflow.title,
                                autoHide: 7500,
                                timestamp: moment().format('h:mm A')
                            }
                        })

                        store.dispatch({
                            type: workflowQueueTasksTypes.CLEAR_WORKFLOW_QUEUE_TASKS
                        })

                        //store.dispatch(routerActions.route('/tasks'))
                        store.dispatch(routerActions.route('/tasks'))
                    }) 
                } catch (error) {
                    //console.log('submit_datax error', error)
                }
                break
            case uploadAccessoryTypes.SUBMIT_ACCESSORY_DOCUMENT_UPDATE_WORKFLOW_TASK_INPUT:
                ui.emit('tasks_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })

                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'Workflow Activity',
                            additionalText: 'Purchase Order Accessory receipt document was successfully submitted.',
                            overflowText: '',
                            autoHide: 7500,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    // store.dispatch({
                    //     type: workflowQueueTasksTypes.CLEAR_WORKFLOW_QUEUE_TASKS
                    // })

                    //store.dispatch(routerActions.route('/tasks'))
                    //store.dispatch(routerActions.route('/tasks'))
                })
                break
            case pdfTypes.PDF_SUBMIT_EDIT_WORKFLOW_TASK_INPUT:
                ui.emit('tasks_handle', action.payload, (data) => {
                    store.dispatch({
                        type: messengerTypes.MESSENGER_ACKNOWLEDGED_REQUEST,
                        payload: data
                    })

                    store.dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'Workflow Activity',
                            additionalText: 'Your task "' + action.payload.workflow_task.title + '" was successfully submitted.',
                            overflowText: action.payload.workflow.title,
                            autoHide: 7500,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    // store.dispatch({
                    //     type: workflowQueueTasksTypes.CLEAR_WORKFLOW_QUEUE_TASKS
                    // })

                    store.dispatch(routerActions.route('/tasks'))
                })

                break
            default:
                break
        }

        next(action)
    } catch (error) {
    }
}