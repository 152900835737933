//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const icon = {
    fontSize: '10px'
}

export const row = {
    fontSize: 16
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const numericFieldsHeader = {
    textAlign: 'right',
    fontWeight: 'bold',
}

export const numericFields = {
    textAlign: 'right',
}


export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}