import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.PUSH_HISTORY:
            return {
                ...state,
                history: [...state.history, action.payload],
                index: [...state.history, action.payload].length - 1
            }
        case types.RESET_HISTORY:
            return {
                ...state,
                history: []
            }
        case types.GO_BACK_IN_HISTORY:
            return {
                ...state,
                index: state.index - 1
            }
        case types.GO_FORWARD_IN_HISTORY:
            return {
                ...state,
                index: state.index + 1
            }
        default:
            return state
    }
}