import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'

import TextField from '@mui/material/TextField'


import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import ImageUploader from '../../ImageUploader/component'
import Icon from '../../Icon/component'
import Upload from '../../Upload/component'

import AutoComplete from '../../../controls/autocomplete'
import DateControl from '../../../controls/dateControl'

import * as styles from './styles'
import * as actions from './actions'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'

class STRContractActivation extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }

    ContractDetails(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Contract Summary')}
                    subtitle={window.t.en('Contract details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Category:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.contractvehiclecategory}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Supplier Name:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.supplier}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Start Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data.startdate).format('DD/MM/YYYY')}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract End Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data.enddate).format('DD/MM/YYYY')}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Number Of Days:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.days}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Number Of Months:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.months}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Distance Per Month:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.distancepermonth}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Distance:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.contractdistance}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Access KMs:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.accesskm}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Quote Valid For:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.quotevalidfor}</Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    PaymentDetails(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Contract Payment Summary')}
                    subtitle={window.t.en('Contract payment details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Total Rental Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_excl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Rental Vat Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_vat).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Rental Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.monthly_incl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'STR Rental Amount:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.accessory_tot).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }


    AccessoriesList(data) {

        window.glyco.log('AccessoriesList')
        window.glyco.log(data)
        // window.glyco.log('this.props.state.surrogateitems.grid')
        // window.glyco.log(this.props.state.surrogateitems)
        // const data = this.props.state.surrogateitems.grid.filter(x => parseFloat(x.rateperday) > 0)

        // window.glyco.log('AccessoriesList')
        // window.glyco.log(data)
        return data.length
            ? <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
                <CardHeader
                    title={window.t.en('Accessory List')}
                    subtitle={window.t.en('Selected vehicle accessories')}
                    actAsExpander={false}
                    style={styles.row}
                />
                <CardContent expandable={false}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={7} style={styles.selectedFieldName}>
                                {'Accessory Name'}
                            </Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {'Rate Per Rate'}
                            </Col>
                            <Col xl={3} style={styles.numericFieldsHeader}>
                                {'Total Price'}
                            </Col>
                        </Row>
                        {data.map((x, i) => {
                            return <Row>
                                <Col xl={7}>
                                    {x.accessoryname}
                                </Col>
                                <Col xl={2}>
                                    {parseInt(x.rateperday)}
                                </Col>
                                <Col xl={3} style={styles.numericFields}>
                                    {parseFloat(x.totalprice.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                            </Row>
                        })
                        }
                    </Container>
                </CardContent>
            </Card>
            : ''
    }

    uploadInvoice() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Upload Supplier Document')}
                    subtitle={window.t.en('Upload supplier supporting documents')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'inv'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    formView() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Capture Invoice')}
                    subtitle={window.t.en('Capture invoice details')}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Vehicle MM Code:'}</div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'dll_contractvehiclecategory_id'}
                                            key={'dll_contractvehiclecategory_id'}
                                            hintText={'Select make and model'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            dataSource={this.props.state.mmcodes}
                                            onSelect={
                                                (chosenRequest) => {
                                                    this.props.actions.setMMLookupValue(chosenRequest.value, chosenRequest.text)
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getMMLookupValueData('')
                                                }
                                            }
                                            onFilter={
                                                (search, dataSource, params) => {
                                                    this.props.actions.getMMLookupValueData(search)
                                                }
                                            }

                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Vehicle Registration:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txt_vehicle_reg'}
                                            hintText={'Enter Vehicle Registration'}
                                            fullWidth={true}
                                            variant="outlined"
                                            multiLine={false}
                                            value={this.props.state.vehicle}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('vehicle', event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Recieved Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        <DateControl
                                            id={'txt_recieved'}
                                            key={'txt_recieved'}
                                            label='Select Recieved Date'
                                            name={'txt_recieved'}
                                            value={this.props.state.recieveddate}
                                            defaultDate={this.props.state.recieveddate}
                                            onSelect={(args) => {
                                                //console.log('args date',args)
                                                this.props.actions.setValue('recieveddate', args)
                                            }}
                                            type={'date'}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Activation Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        <DateControl
                                            id={'txt_activation'}
                                            key={'txt_activation'}
                                            label='Select Recieved Date'
                                            name={'txt_activation'}
                                            value={this.props.state.activationdate}
                                            defaultDate={this.props.state.activationdate}
                                            onSelect={(args) => {
                                                //console.log('args date',args)
                                                this.props.actions.setValue('activationdate', args)
                                            }}
                                            type={'date'}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Start Time:'}</div>
                                    <div style={styles.fieldContent}>
                                       
                                        <TextField
                                            type="time"
                                            key={'txt_starttime'}
                                            variant="outlined"
                                            format='24hr'
                                            hintText='Select Start Time'
                                            value={this.props.state.starttime}
                                            onChange={
                                                (event, value) => {
                                                this.props.actions.setValue('starttime', event.target.value)
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Vehicle Odometer:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txt_vehicle_reg'}
                                            hintText={'Enter Vehicle Odometer'}
                                            variant="outlined"
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.odometer}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('odometer', event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'VIN Number:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txt_vin'}
                                            hintText={'Enter Vehicle VIN'}
                                            variant="outlined"
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.vin}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('vin', event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    renderView(workflow_queue_data) {
        const data = workflow_queue_data.data[0].context_data.data.data.data[0].data[0].context_data.data.data.data[0]
        const documents = workflow_queue_data.data[0].context_data.data.data.data[0].documents
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {
                        documents
                            ? <PdfViewer
                                key={'document_viewer_approval'}
                                name={'booking'}
                                workflow={'fml_customer_quote'}
                                task={'tsk_purchase_order_review_and_send'}
                                documents={documents}
                                edit={false}
                                default_document={'Rebill'}
                                workflow_queue_data={this.props.workflow_queue_data}
                            />
                            : <Loading message={'Retrieving Booking Document...'} />
                    }
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.ContractDetails(data.contract)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.PaymentDetails(data.contract)}
                </Col>
            </Row>

            <Row>
                <Col xl={12}>
                    {this.AccessoriesList(data.accessory)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.uploadInvoice()}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.formView()}
                </Col>
            </Row>
        </Container>
    }

    render() {
        let workflow_queue_data = null
        window.glyco.log('render')
        window.glyco.log(this.props.workflow_queue_data)
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
            workflow_queue_data = workflow_queue_data.data[0].context_data.data.data.data[0].data[0].context_data.data.data.data[0]
        } catch (error) {

        }
        window.glyco.log('STRContractActivation')
        window.glyco.log(workflow_queue_data)

        return workflow_queue_data
            ? this.renderView(workflow_queue_data)
            : 'Loading...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.strContractActivation
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(STRContractActivation)