//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const header = {
    color: "black"
}

export const headerButton = {
    width: 250
}

export const triggers = {
    position: 'fixed',
    right: '75px',
    top: '38px',
    zIndex: 1000
}

export const dashboard = {
    margin: '0 auto',
    width: '500px',
    height: '500px'
}

export const MuiFormControl = {
    width: "60%",
    paddingTop: "4%"
}

export const cardDropdown = {
    backgroundColor: "white"
}

export const root = {
    flexGrow: 1,
    backgroundColor: "white"
}

export const paper = {
    padding: 20,
    textAlign: "center",
    fontFamily: "Roboto"
}

export const input = {
    display: "none",
}
export const large = {
    width: "100%",
    height: "100%",
}

export const avatarRoot = {
    alignSelf: 'left',
    justifyContent: "left",
    alignItems: "left",
    display: 'flex',
}

export const avatar = {
    height: "100%",
    width: "100%"
  }

  export const spacing = {
    paddingBottom: "1%",
  }


  export const save = {
      float: "right"
  }

  export const buttonSize ={
    minWidth: "162px",
    height: "39px"
  }