import React, { useState, useEffect } from 'react';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons';
//chevron-right
import {
    Grid,
    Box,
    Typography,
    Popover,
    Menu,
    Button,
    List,
    ListItem,
    Divider
} from '@mui/material';

import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import CollectionsTwoToneIcon from '@mui/icons-material/CollectionsTwoTone';
import DnsTwoToneIcon from '@mui/icons-material/DnsTwoTone';
import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';

import * as actions from "./actions";
import * as BIActions from "../BusinessIntelligence/actions";
import * as navActions from "../NavigationMenu/actions";
import * as redirectActions from "../Redirect/actions";


const rightData = (props, handleClose) => {
    const {reportData, report_length} = props.state
    return report_length >  0? reportData ?
        reportData.map((x, ind) =>
            x.oq_report_key ?
                (<ListItem
                    component="a"
                    key={ind}
                    button
                    onClick={(event, value) => {
                        event.preventDefault();
                        handleClose()
                        props.actions.setOqlisDashboard(x.oq_report_key)
                        props.actions.toggleDashboardMenus(false, null)
                        props.actions.route("/bi");
                    }}
                >
                    <div className="mr-2">
                        <FontAwesomeIcon icon={faCircle} className="font-size-xs opacity-3" />
                    </div>
                    <span>{x.report_name}</span>

                </ListItem>
                ) : ''
        )
        : '':'No reports under this section'
}


const HeaderMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'mega-menu-popover' : undefined;

    const [anchorElMenu, setAnchorElMenu] = useState(null);

    const handleClickMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };
    useEffect(() => {
        props.actions.loadIntelligence('', '', '', '', 'reports')
    }, []);

 
    return (

        <>
            <div className="app-header-menu">
                <Button
                    size="small"
                    onClick={handleClick}
                    className="btn-transition-none bg-neutral-first text-first font-size-lg p-0 d-inline-block-off shadow-none border-0 text-center d-44 rounded position-relative">
                    Reports
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}>
                    <div className="popover-custom-xxl p-0">
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <div className="divider-v divider-v-lg" />
                                <List component="div" className="nav-neutral-first p-3">
                                    <Typography
                                        component="div"
                                        className="pb-2 text-capitalize text-first font-size-lg">
                                        <span>Tabular Reports</span>
                                    </Typography>
                                    {
                                        props.state.bi.reports ? props.state.bi.reports.map((x, ind) => (
                                            <ListItem
                                                component="a"
                                                key={ind}
                                                button
                                                href="#/"
                                                onClick={(e) => props.actions.setRightSideList(x.report, x.report.filter(x=> x.oq_report_key).length)}>
                                                <div className="mr-2">
                                                    <FontAwesomeIcon icon={faChevronRight} className="font-size-xs opacity-3" />
                                                </div>
                                                <span>{x.category}</span>
                                                <div className="ml-auto badge badge-pill badge-success">
                                                    {x.report.filter(x=> x.oq_report_key).length}
                                                </div>
                                            </ListItem>
                                        )) : '...loading'
                                    }
                                </List>
                                <div className="divider-v divider-v-lg" />
                                <List component="div" className="nav-neutral-success p-3">
                                    <Typography className="pb-2 text-capitalize text-success font-size-lg">
                                        <span>Js Reports</span>
                                    </Typography>
                                    <ListItem
                                        component="a"
                                        button
                                        href="#/"
                                        onClick={(e) => e.preventDefault()}>
                                        <div className="mr-2">
                                            <FontAwesomeIcon icon={faChevronRight} className="font-size-xs opacity-3" />
                                        </div>
                                        <span>Calendar</span>
                                    </ListItem>
                                    <ListItem
                                        component="a"
                                        button
                                        href="#/"
                                        onClick={(e) => e.preventDefault()}>
                                        <div className="mr-2">
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </div>
                                        <span>Chat</span>
                                    </ListItem>
                                    <ListItem
                                        component="a"
                                        button
                                        href="#/"
                                        onClick={(e) => e.preventDefault()}>
                                        <div className="mr-2">
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </div>
                                        <span>Contacts</span>
                                    </ListItem>
                                    <ListItem
                                        component="a"
                                        button
                                        href="#/"
                                        onClick={(e) => e.preventDefault()}>
                                        <div className="mr-2">
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </div>
                                        <span>File Manager</span>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="right-side-data">
                                <div className="divider-v divider-v-lg" />
                                <div className="divider-v divider-v-lg" />
                                    {rightData(props, handleClose)}
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                </Popover>

                {/* <Button
          onClick={handleClickMenu}
          size="small"
          className="btn-transition-none btn-neutral-primary mr-3">
          Dashboards
        </Button> */}
                <Menu
                    anchorEl={anchorElMenu}
                    keepMounted
                    open={Boolean(anchorElMenu)}
                    onClose={handleCloseMenu}
                    classes={{ list: 'p-0' }}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}>
                    <Box className="overflow-hidden border-0 bg-midnight-bloom p-3 dropdown-mega-menu-md">
                        <div className="text-center">
                            <div className="font-weight-bold font-size-xl mb-1 text-white">
                                Dashboards
                            </div>
                            <p className="text-white-50 mb-3">
                                There are <b className="text-white">multiple</b> dashboard
                                layouts available!
                            </p>
                        </div>
                        <div className="d-flex flex-wrap">
                            <div className="w-50 p-2">
                                <Button
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="d-block bg-white p-3 text-primary">
                                    <div>
                                        <CalendarTodayTwoToneIcon className="h1 d-block my-2 text-success" />
                                        <div className="text-black font-size-md font-weight-bold">
                                            Projects
                                        </div>
                                    </div>
                                </Button>
                            </div>
                            <div className="w-50 p-2">
                                <Button
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="d-block bg-white p-3 text-primary">
                                    <div>
                                        <CollectionsTwoToneIcon className="h1 d-block my-2 text-danger" />
                                        <div className="text-black font-size-md font-weight-bold">
                                            Helpdesk
                                        </div>
                                    </div>
                                </Button>
                            </div>
                            <div className="w-50 p-2">
                                <Button
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="d-block bg-white p-3 text-primary">
                                    <div>
                                        <DnsTwoToneIcon className="h1 d-block my-2 text-warning" />
                                        <div className="text-black font-size-md font-weight-bold">
                                            CRM UI
                                        </div>
                                    </div>
                                </Button>
                            </div>
                            <div className="w-50 p-2">
                                <Button
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="d-block bg-white p-3 text-primary">
                                    <div>
                                        <HomeWorkTwoToneIcon className="h1 d-block my-2 text-first" />
                                        <div className="text-black font-size-md font-weight-bold">
                                            Customers
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Menu>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.signedIn
            ,
            bi: {
                ...state.businessIntelligence
            }
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            {
                ...actions,
                ...BIActions,
                ...navActions,
                ...redirectActions,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);

