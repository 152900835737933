import * as types from './types'

export const pushHistory = (location) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.PUSH_HISTORY,
            payload: location
        })
    }
}

export const resetHistory = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_HISTORY
        })
    }
}

export const goBackInHistory = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GO_BACK_IN_HISTORY
        })
    }
}

export const goForwardInHistory = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GO_FORWARD_IN_HISTORY
        })
    }
}