import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.LOAD_ON_DEMAND:
            return {
                ...state
            }
        default:
            return state
    }
}