import * as props from './props'

import * as types from './types'
import * as controlTypes from '../../../controls/types'

export default (state = props, action) => {
    switch (action.type) {
        case types.EMPTY_TYPE:
            return {
                ...state
            }
        case controlTypes.SET_CONTROL_VIEW_INPUT_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.data

            }
        case types.SET_CREDIT_NOTE_COMPONENT_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
            case types.RESET_COMPONENT_CRN:
                return {
                    ...state,
                    client: '',
                    client_id : '',
                    credited_amount : '0.00',
                    creditnote_action: '',
                    creditnote_corrective : '',
                    creditnote_description: '',
                    customer_id: '',
                    customer_transactions: [],
                    customername: '',
                    discount_amount: '0.00',
                    discount_amount: '0.00',
                    enatis_received: false,
                    payable_amount:'0.00',
                    payment_date:'',
                     supplier_id : '',
                    suppliername : '',
                    client_id : '',
                    title : '',
                    transactionx :  {},
                    select_supplier : {},
                    client_data : [],
                    customer_data : [],
                    supplier_data : [],
                    credited_data : [],
                    penalties_data : [],
                    payment_document: [],
                    supplier_transactions : [],
                    fields:props.fields

                }
        default:
            return state
    }
}