import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        // case types.RESET_COMPONENT:
        //     return {
        //         ...state,
        //         users: [],
        //         vehicles: [],
        //         supplierage: [],
        //         bookinginfo: [],
        //         selectedVehicle: [],
        //         selectedSupplier: null,
        //         vehicleserviceinfo: [],
        //         maintpdf_preview: '',
        //     }
        case types.SET_INPUT_VALUE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: {
                        ...state.fields[action.payload.prop],
                        value: action.payload.val,
                        valid: action.payload.val ? true : false
                    }
                }
            }
        case types.SET_PRE_AUTHORISATION_NUMBER:
            return {
                ...state,
                preauth: action.payload
            }
        case types.SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                suppliers: action.payload
            }
        case types.SET_USER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                users: action.payload
            }
        case types.RESET_COMPOMENT_STATE:
            return {
                ...state,
                supplier_id: '',
                supplier_fktext: '',
                date: '',
                reference: {value: ''},
                description: {value : ''},
                fields:props.fields
            }
        default:
            return state
    }
}