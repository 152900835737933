import * as types from './types';
import * as props from './props';

export default (state = props, action) => {
    switch (action.type) {
        case types.SHOW_MESSAGE:
            return {
                ...state,
                open: true,
                message: action.payload
            }
        default:
            return state;
    }
}