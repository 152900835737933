import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'
import * as types from './types'

export const getWorkflowQueueTaskDocumentsEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.WORKFLOW_QUEUE_TASK_DOCUMENTS_GET_WORKFLOW_QUEUE_TASK_DOCUMENTS)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_queue_task_document/query?workflow_queue_task_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflowQueueTaskDocuments(payload, result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow_queue_task_document/query?workflow_queue_task_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow_queue_task_document/query?workflow_queue_task_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )