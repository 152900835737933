import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  createFilterOptions
} from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox,Chip } from "@mui/material";
import { connect } from 'react-redux'

const MultiSelect = ({
    items,
    selectedValues,
    limitTags,
    onToggleOption,
    onClearOptions,
    onSelectAll,
    view = ''
}) => {

    // Checks if all the components have been selected by comparing the total components to those selected
    const allSelected = items.length === selectedValues.length;

    // Called when the select all option has been choosen
    const handleToggleSelectAll = () => { onSelectAll && onSelectAll(!allSelected); };

    const handleChange = (event, selectedOptions, reason) => {
        if (reason === "select-option" || reason === "remove-option" || reason === "selectOption" || reason === "removeOption") {
            if (selectedOptions.find((option) => option.value === "select-all")) {
                handleToggleSelectAll();
            } else {
                onToggleOption && onToggleOption(selectedOptions);
            }
        } else if (reason === "clear") {
            onClearOptions && onClearOptions();
        }
    };

    const optionRenderer = (props, option, { selected }) => {
      //console.log('option', option);
        const selectAllProps =
        option.value === "select-all" // To control the state of 'select-all' checkbox
            ? { checked: allSelected }
            : {};
            const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
            const checkedIcon = <CheckBoxIcon fontSize="small" />;
        return (
        <li {...props}>
            <Checkbox
            color="primary"
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            {...selectAllProps}
            />
            {`${view === 'reports' ? option.report_name : option.title}`}
        </li>
        );
    };
  
  const getOptionSelected = view === 'reports' ? (option, anotherOption) => option.report_id === anotherOption.report_id
        :(option, anotherOption) => option.component_id === anotherOption.component_id;
  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      limitTags={limitTags}
      value={selectedValues}
      options={items}
      disableCloseOnSelect
      getOptionLabel={(option) => `${view === 'reports' ? option.report_name : option.title}`}
      renderTags={(tagValue, getTagProps) => 
        tagValue.map((option, index) => (
          <Chip
            label={`${view === 'reports' ? option.report_name : option.title}`}
            color="primary"
            {...getTagProps({ index })}
          />
        ))}
      getOptionSelected={getOptionSelected}
      noOptionsText={window.t.en('No Components Found')}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return view === 'reports' ? [{ report_name: "Select All", value: "select-all" }, ...filtered] : [{ title: "Select All", value: "select-all" }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={(params) => (
        <TextField
        {...params}
        label={window.t.en(`${view === 'reports'? 'Please select report' : 'Please select components'}`)}
        variant="outlined"
        InputProps={{ ...params.InputProps, type: "search" }}
        />
    )}
    />
  );
};

MultiSelect.defaultProps = {
  limitTags: 4,
  items: [],
  selectedValues: [],
  getOptionLabel: (value) => value
};

export default MultiSelect;
