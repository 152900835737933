import React, { useEffect } from 'react'
import AppAD from '../AppAD/component'

const ADInit = (props) => {    
    const getLayout = () => {
        return (
            <AppAD />
        )
    }

    return  (
        getLayout()
    )
}

export default ADInit