import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import WorkflowGrid from '../WorkflowGrid/component'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import { Card, CardHeader, CardContent } from '@mui/material'

import { Container, Row, Col } from 'react-grid-system'

import WorkflowView from '../WorkflowView/component'

import * as actions from './actions'
import * as redirectActions from '../Redirect/actions'
import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'
import * as styles from './styles'

class WorkflowTaskStepComponentSingleSelector extends Component {
    componentDidMount() {
        
        // this.props.actions.setValidationState(null)
        
        this.props.actions.toggleView('grid')
        const component = {
            actions: this.props.actions,
            state: this.props.state,
            styles: styles,
            data: this.props.workflow_queue_data.data.length ? this.props.workflow_queue_data.data[0] : null
        }
        
        console.log('salesprocessx componentDidMount', component)
        if(component.data) {
        
            let task_name = component.state.task.name
            let component_name = component.state.component.name
            let sales_process = component.data.context_data.data.data.data[0].step0
            let task = this.props.state.task

            let rowInfo ={
                original: sales_process.customer
            }

            this.props.actions.confirmSalesProcess(component_name, rowInfo, task_name, task)
        }

    }

    getLayoutOld() {
        const component = {
            actions: this.props.actions,
            state: this.props.state,
            styles: styles
        }

        this.component = this.props.state.component

        this.selected = this.props.state.component.selected

        this.data =  component.state.task.workflow_task_step
            .filter(x =>
                x.name === this.props.step &&
                x.workflow_task_step_component.filter(y => y.component.name === this.props.name).length
            )
            .reduce((arr, x) => {
                if (x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length).length) {
                    arr.push(x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length)[0].data)
                }

                return arr
            }, [])


        let filter = []
        if (component.state.task.workflow_task_step[0].name === 'stp_quote_finalize_customer_quote') {

            if (this.data[0][0].context_data) {
                const groupname = this.data[0][0].context_data.data.data.data[0].data[0].group

                filter.push({ groupname })
            }
        }

        return (
            this.data && this.data.length && !(component.state.task.workflow_task_step[0].name === 'stp_quote_finalize_customer_quote')
                ? (
                    <div>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    <WorkflowView
                                        key={'view_' + this.component.name}
                                        name={this.component.name}
                                        data={this.data[0][0]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                   < Button className={"global_button"} variant="contained"
                                        label={'Change'}
                                        primary={true}
                                        style={styles.right}
                                        disabled={!this.component.selected.length}
                                        onClick={(event) => {
                                            this.props.actions.resetSelection(this.props.step, this.props.name)
                                        }}
                                        >{window.t.en('Change')}</Button>
                                </Col>
                            </Row>
                        </Container>

                        <br />
                    </div>
                )
                : (
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <WorkflowGrid
                                    key={'workflow_task_step_single_selector_' + this.props.name + '_' + this.props.step}
                                    name={this.props.name}
                                    task={true}
                                    mode='select'
                                    type='single'
                                    filter={filter}
                                    filterable={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.confirmSelection}>
                               < Button className={"global_button"} variant="contained"
                                    label={'Select'}
                                    primary={true}
                                    disabled={!this.component.selected.length}
                                    onClick={(event) => {
                                        component.actions.confirmSelection(this.props.step, this.props.name)
                                    }}
                                    >{window.t.en('Select')}</Button>
                            </Col>
                        </Row>
                    </Container>
                )
        )
    }

    getLayout() {
        const component = {
            actions: this.props.actions,
            state: this.props.state,
            styles: styles
        }

        this.component = this.props.state.component

        this.selected = this.props.state.component.selected

        this.data =  component.state.task.workflow_task_step
            .filter(x =>
                x.name === this.props.step &&
                x.workflow_task_step_component.filter(y => y.component.name === this.props.name).length
            )
            .reduce((arr, x) => {
                if (x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length).length) {
                    arr.push(x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length)[0].data)
                }

                return arr
            }, [])


        let filter = []
        if (component.state.task.workflow_task_step[0].name === 'stp_quote_finalize_customer_quote') {

            if (this.data[0][0].context_data) {
                const groupname = this.data[0][0].context_data.data.data.data[0].data[0].group

                filter.push({ groupname })
            }
        }
        //console.log('selectRowx WorkflowView ...',this.data)
        return this.props.state.current_grid_view === 'grid' ? <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <WorkflowGrid
                            key={'workflow_task_step_single_selector_' + this.props.name + '_' + this.props.step}
                            name={this.props.name}
                            step={this.props.step}
                            task={true}
                            mode='select'
                            type='single'
                            filter={filter}
                            filterable={true}
                        />
                    </Col>
                </Row>
            </Container>
            : <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {
                        this.data && this.data.length //&& !(component.state.task.workflow_task_step[0].name === 'stp_quote_finalize_customer_quote')
                        ? <WorkflowView
                            key={'view_' + this.component.name}
                            name={this.component.name}
                            data={this.data[0][0]}
                        />
                        : 'Loadingx...'
                    }
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <Button className={"global_button"} variant="contained"
                        label={'Change'}
                        primary={true}
                        style={styles.right}
                        disabled={!this.component.selected.length}
                        onClick={(event) => {
                            this.props.actions.toggleView('grid')
                            //this.props.actions.resetSelection(this.props.step, this.props.name)
                        }}
                        >{window.t.en('Change')}</Button>
                </Col>
            </Row>
        </Container>
    }
    
    render() {
        
        const component = {
            actions: this.props.actions,
            state: this.props.state,
            styles: styles,
            data: this.props.workflow_queue_data.data.length ? this.props.workflow_queue_data.data[0] : null
        }

        console.log('componentx component', component.data)
        //console.log('WorkflowTaskStepComponentSingleSelector component', component)
        //console.log('WorkflowTaskStepComponentSingleSelector workflow_task_step', component.state.task.workflow_task_step)
        return component.state.task.workflow_task_step ? this.getLayout() : 'Loading..'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowTaskInput,
            ...state.workflowTaskStepComponentSingleSelector,
            component: {
                ...state.components[ownProps.name]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...redirectActions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskStepComponentSingleSelector)