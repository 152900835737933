import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
//import prettyFileIcons from 'pretty-file-icons'
import Dropzone from 'react-dropzone'
import CircularProgress from '@mui/material/CircularProgress'
import { Card, CardActions, CardHeader, CardContent, IconButton } from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import Icon from '../Icon/component'
import AdhocGrid from '../Custom/AdhocGrid/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

import { green, red } from '@mui/material/colors'
//import { Button } from 'material-ui';

import Button from '@mui/material/Button'

class Document extends Component {
    componentDidMount() {
        this.props.actions.loadDocumentState(this.props.state.component.name)
    }

    getFormattedDocument(document) {
        return {
            document_id: document['document#document_id'],
            filename: document['document#filename'],
            extension: document['document#extension'],
            key: document['document#key'],
            description: document['document#description'],
            content: document['document#content'],
            typename: document['documenttype#typename'],
            created_at: document['document#created_at']
        }
    }


    getDownloadButton(document) {
        document = this.getFormattedDocument(document)

        //const type = prettyFileIcons.getIcon(document.key)
        //const src = require('../../assets/img/png/icons/' + type + '.png')

        // return <img src={src} style={{ width: '16px', cursor: 'pointer' }} onClick={(event) => {
        //     this.props.actions.downloadDocument(document)
        // }} />

        return <IconButton key={'command_deleted_' + document.key} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Vehicle details'} color="primary"
            onClick={
                () => {
                    this.props.actions.downloadDocument(document)
                }
            }>
            <Icon  istyle={{ fontSize: '25px', color: '#253053' }} iclass={'material-icons'} iname={'cloud_download'} />
        </IconButton>
    }

    getFileTypeIcon(file) {
        // const type = prettyFileIcons.getIcon(file)
        // const src = require('../../assets/img/png/icons/' + type + '.png')

        return null
    }

    getDocumentLinkerInfo() {
        const result = {
            linker: this.component.relatedFull.hasMany ? this.component.relatedFull.hasMany.filter(x => x.key.indexOf('document') > -1)[0].key : null,
            id_field: this.component.component_field.filter(x => x.key)[0].name,
            id_value: this.component.data[this.component.component_field.filter(x => x.key)[0].name].input
        }

        return result
    }

    getAcceptedFiles() {
        return this.props.state[this.props.state.component.name].accepted.length
            ? <Card initiallyExpanded={true}>
                <CardHeader
                    avatar={<Icon iclass={'material-icons'} iname={'done'} />}
                    title={window.t.en('Accepted Files')}
                    subtitle={window.t.en('Please select a document type for each file. Click the "Link Uploaded Files" button when all files have been uploaded.')}
                    actAsExpander={true}
                    showExpandableButton={true}
                />
                <CardContent expandable={true}>
                    <div style={{ verticalAlign: 'middle' }}>
                        {this.props.state[this.props.state.component.name].accepted.map((x, i) => {
                            return <div style={{ verticalAlign: 'middle', display: 'table' }}>
                                <div style={{ verticalAlign: 'middle', display: 'table-cell' }}>
                                    {
                                        x.uploading && !x.error
                                            ? <CircularProgress size={16} />
                                            : !x.uploading && !x.error
                                                ? < Icon istyle={{ color: green[500], fontSize: '16px' }} iclass={'material-icons'} iname={'done'} />
                                                : <Icon istyle={{ color: red[500], fontSize: '16px' }} iclass={'material-icons'} iname={'clear'} />
                                    }
                                    &nbsp;
                                    <img src={this.getFileTypeIcon(x.name)} style={{ width: '16px', cursor: 'pointer' }} />
                                </div>
                                <div style={{ verticalAlign: 'middle', display: 'table-cell' }}>
                                    {!this.props.state[this.props.state.component.name].documentTypesLoading &&
                                        <Select
                                            style={{ width: '250px' }}
                                            value={this.props.state[this.props.state.component.name].accepted[i].selected}
                                            onChange={(event, index, value) => {
                                                event.preventDefault()
                                                this.props.actions.changeSelectedDocumentType(this.props.state.component.name, i, event.target.value)
                                            }}
                                        >
                                            {
                                                [
                                                    <MenuItem key={'document_type_' + 0} value={0} primaryText={'Select a document type...'} />,
                                                    this.props.state[this.props.state.component.name].documentTypes.map(x => {
                                                        return <MenuItem key={'document_type_' + x.documenttype_id} value={x.documenttype_id}  >{x.typename}</MenuItem>
                                                    })
                                                ]
                                            }
                                        </Select>
                                    }
                                </div>
                                <div style={{ verticalAlign: 'middle', display: 'table-cell' }}>
                                    {x.document.name}
                                </div>
                            </div>
                        })}
                    </div>
                </CardContent>
                <CardActions style={{ width: '100%', textAlign: 'right' }}>
                   < Button className={"global_button"} variant="contained"
                        label={'Link Uploaded Files'}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.linkUploadedDocuments(this.props.state.component.name)
                        }}
                        >{window.t.en('Link Uploaded Files')}</Button>
                </CardActions>
            </Card >
            : <div />
    }

    getRejectedFiles() {
        return this.props.state[this.props.state.component.name].rejected.length
            ? <Card initiallyExpanded={true}>
                <CardHeader
                    avatar={<Icon iclass={'material-icons'} iname={'clear'} />}
                    title={window.t.en('Rejected Files')}
                    subtitle={window.t.en('The following files were rejected!')}
                    actAsExpander={true}
                    showExpandableButton={true}
                />
                <CardContent expandable={true}>
                    {this.props.state[this.props.state.component.name].rejected.map(x => {
                        return <div>
                            <Icon istyle={{ color: red[500], fontSize: '16px' }} iclass={'material-icons'} iname={'clear'} />
                            &nbsp;
                            <img src={this.getFileTypeIcon(x.document.name)} style={{ width: '16px', cursor: 'pointer' }} />{x.document.name}
                        </div>
                    })}
                </CardContent>
            </Card >
            : <div />
    }

    // render() {
    //     this.component = this.props.state.component

    //     return !this.props.state[this.props.state.component.name] || this.props.state[this.props.state.component.name].loading || !this.component || this.component.loading
    //         ? <Loading message={'Loading...'} />
    //         : <AdhocGrid
    //         key={'documents_' + this.component.name}
    //         name={'documents_' + this.component.name}
    //         uri={'/components/document/other'}
    //         filterable={true}
    //         sortable={true}
    //         body={{
    //             ...this.getDocumentLinkerInfo(),
    //             query: 'get_component_documents'
    //         }}
    //         search={''}
    //         columns={[
    //             {
    //                 accessor: '', Header: '', width: 32, filterable: false, Cell: row => {
    //                     return this.getDownloadButton(row.original)
    //                 }
    //             },
    //             { accessor: 'document#filename', Header: 'File', width: 250, filterable: true },
    //             { accessor: 'document#created_at', Header: 'Created', filterable: true, width: 155, Cell: (row) => (moment(row.value).format('YYYY-MM-DD HH:mm ZZ')) },
    //             { accessor: 'documenttype#typename', Header: 'Document Type', width: 150, filterable: true },
    //             { accessor: 'document#description', Header: 'Description', filterable: true }
    //         ]}
    //         paging={{
    //             page: 0,
    //             pageSize: 10,
    //             pages: 0,
    //             count: 0
    //         }}
    //     />
    // }

    getDocumentsView() {
        return <AdhocGrid
            key={'documents_' + this.component.name}
            name={'documents_' + this.component.name}
            uri={'/components/document/other'}
            filterable={true}
            sortable={true}
            body={{
                ...this.getDocumentLinkerInfo(),
                query: 'get_component_documents'
            }}
            search={''}
            columns={[
                {
                    accessor: '', Header: '', width: 32, filterable: false, Cell: row => {
                        return this.getDownloadButton(row.original)
                    }
                },
                { accessor: 'document#filename', Header: 'File', width: 250, filterable: true },
                { accessor: 'document#created_at', Header: 'Created', filterable: true, width: 155, Cell: (row) => (moment(row.value).format('YYYY-MM-DD HH:mm ZZ')) },
                { accessor: 'documenttype#typename', Header: 'Document Type', width: 150, filterable: true },
                { accessor: 'document#description', Header: 'Description', filterable: true }
            ]}
            paging={{
                page: 0,
                pageSize: 10,
                pages: 0,
                count: 0
            }}
        />
    }

    getDocumentsUpload() {
        return <div>
                <Dropzone
                    ////accept={'/*'}
                    style={styles.dropZone}
                    onDrop={(files) => {
                        //this.props.actions.notifyUser('Checking documents...')
                    }}
                    onDropAccepted={(files) => {
                        //this.props.actions.notifyUser('(' + files.length + ') files were accepted.')
                        //console.log('filesx', files)
                        this.props.actions.setAcceptedDocuments(files, this.props.state.component.name, this.getDocumentLinkerInfo())
                        //this.props.actions.uploadAcceptedDocuments()
                    }}
                    onDropRejected={(files) => {
                        //this.props.actions.notifyUser('(' + files.length + ') files were rejected.')
                        this.props.actions.setRejectedDocuments(files, this.props.state.component.name)
                    }}
                >
                    Drag and drop the files you would like to upload inside this box. The following commonly used image, document, e-mail, sound and video formats are accepted:
                    <br />
                    .doc, .docx, .jpeg, .jpg, .pdf, .png, .xls, .xlsx, .ppt, .eml, .msg, .mp3, .m4a, .ogg, .wav, .webm, .mp4, .avi, .mov, .wmv, .flv
                </Dropzone>
                {this.getRejectedFiles()}
                {this.getAcceptedFiles()}
        </div>
    }

    getLayout() {
        this.component = this.props.state.component

        return !this.props.state[this.props.state.component.name] || this.props.state[this.props.state.component.name].loading || !this.component || this.component.loading
            ? <Loading message={'Loading...'} />
            : <div>
                <Tabs
                    initialSelectedIndex={0}
                    value={this.props.state.tab}
                    inkBarStyle={{
                        height: 4
                    }}
                    indicatorColor="primary"
                    onChange={(event, value) => {
                        this.props.actions.changeDocumentTab(value)
                    }}
                >
                <Tab style={{minWidth:"50%"}} label={'List Documents'} />
                <Tab style={{minWidth:"50%"}} label={'Upload Files'} />
            </Tabs>
            <br />
            <div>
                {this.props.state.tab === 0 && this.getDocumentsView() }
                {this.props.state.tab === 1 && this.getDocumentsUpload() }
            </div>
        </div>
    }

    render() {
        this.component = this.props.state.component
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.document,
            component: {
                ...state.components[ownProps.component]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Document)