import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
// import TextField from '@mui/material/TextField'
import QuoteItems from '../../WorkflowPOHeader/QuoteItems/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import * as styles from './styles'
import * as actions from './actions'
import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'
import TextField from '../../../controls/textField'

import ViewVehicle from '../../../controls/view_vehicle'

let executed = false
let itemsLoaded = false
class DefleetQuoteApprovalGM extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
    }
    SupplierLatter(data) {
        return <Card
            title={window.t.en('Supplier Details')}
            description={data.suppliername}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.suppliername}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.suppliertype}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.registrationnumber}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.vatnumber}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Supplier Limit:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.supplierage.length ? data.supplierage[0].suplierspendlimit : ''}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Supplier Usage:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.supplierage.length ? data.supplierage[0].suplierusage : ''}</Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>} />
    }
    quoteItems(vehicle, data) {
        const maint = vehicle.maintenancetransaction
        const licenseplateno = vehicle.licenseplateno
        return <Card
            title={window.t.en('Quotation Items')}
            description={window.t.en('Captured quotation items')}
            content={<QuoteItems workflow_queue_data={maint} workflow_quote_data={data} read_only={true} licenseplateno={licenseplateno} />} />
    }  
    checksheetComments(data) 
    {        
        //console.log('Check Sheet Comments')
        //console.log(data)
        return <Card title={window.t.en('Check Sheet Comments')}
            description={window.t.en('Comments captured on upload Check Sheet')}
            content={<Container style={{ margin: 0 }} className="custom-container">                
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Uploaded By: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user[0].firstname + " " + data.user[0].lastname}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Uploaded Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user[0].email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user[0].phone}</Col>
                </Row>
                <Row> <Col xl={12}><br></br></Col></Row>      
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Check Sheet Completed By: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.completedby}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Check Sheet Completed Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.data.completeddate).format('YYYY-MM-DD')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Location:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.location}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Odometer Reading:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.odoreading}</Col>
                </Row>
                <Row> <Col xl={12}><br></br></Col></Row>   
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            label={'No Comments...'}
                            disabled={true}
                            fullWidth={true}
                            variant="outlined"
                            multiLine={false}
                            value={data.data.comments}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            </Container>}
        />
    }
    quotesComments(data) {
        return <Card title={window.t.en('Quotes Comments')}
            description={window.t.en('Comments captured on quotes & upload')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                 <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Quote Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.data.quotedate).format("YYYY-MM-DD")}</Col>

                </Row>
                <Row> <Col xl={12}><br></br></Col></Row>
                 <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Captured By: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname + " " + data.user.lastname}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Captured Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                </Row>
                <Row> <Col xl={12}><br></br></Col></Row> 
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            hintText={'No Comments'}
                            disabled={true}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={data.data.comments.value}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    techManagerComments(steps) {
        return <div>
            <Card
                title={window.t.en('Rebill ')}
                description={window.t.en('Rebill summary')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'De-fleet Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step2.data.defleetdate ? steps.step2.data.defleetdate : steps.step2.datestamp).format("YYYY-MM-DD")}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step1.data.defleetNumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Quote Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step3.data.quotedate ? steps.step3.data.quotedate : steps.step3.quotedate).format("YYYY-MM-DD")}</Col>

                        <Col xl={3} style={styles.fieldLabel}>{'Service Plan:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step3.serviceplan}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Repair Days:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{(steps.step3.data.days.value)}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Warranty:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step3.warranty ? steps.step3.warranty : ''}</Col>
                    </Row>
                </Container>} />
            <Card
                title={window.t.en('Tech Manager Comments')}
                description={window.t.en('Tech Manager approval comments for Rebill')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step4.user.firstname} {steps.step4.user.lastname}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step4.datestamp).format('YYYY-MM-DD HH:mm')}</Col>

                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step4.user.email}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step4.user.phone}</Col>
                    </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomments'}
                                hintText={'No comments'}
                                fullWidth={true}
                                multiLine={false}
                                disabled={true}
                                variant="outlined"
                                value={steps.step4.comments}
                            />
                        </Col>
                    </Row>
                </Container>} />
        </div>
    }    
    OPSComments(steps) {
        return <div>
            <Card
                title={window.t.en('GM Operations Comments')}
                description={window.t.en('Tech Manager approval comments for Rebill')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step44.user.firstname} {steps.step44.user.lastname}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step44.datestamp).format('YYYY-MM-DD HH:mm')}</Col>

                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step44.user.email}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step44.user.phone}</Col>
                    </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomments'}
                                hintText={'No comments'}
                                fullWidth={true}
                                multiLine={false}
                                disabled={true}
                                variant="outlined"
                                value={steps.step44.comments}
                            />
                        </Col>
                    </Row>
                </Container>} />
        </div>
    }
    commentsView() {
        return  <Card
        title={'Approval Comments'}
        subtilte={'Enter approval comments for Rebill'}
        content={<Container style={{ margin: 0 }} className="custom-container">
            <Row >
                <Col xl={12}>
                    <Checkbox
                    id={'txt_send_authorisation'}
                    key={'txt_send_authorisation'}
                    name={'txt_send_authorisation'}
                    field={'send_authorisation'}
                    checked={this.props.state.send_authorisation ? this.props.state.send_authorisation.value : false}
                    labelPlacement={'end'}
                    variant="outlined"
                    label={window.t.en('Send Authorisation to Supplier')}
                    // setComponentFieldInput={
                    //     this.props.actions.setValue
                    // }
                    onCheck={(checked) => {
                        this.props.actions.setValue('send_authorisation', checked)
                    }}
                />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                <TextField
                        id={'txtcomments'}
                        label={'Enter comments...'}
                        fullWidth={true}
                        multiLine={false}
                        variant="outlined"
                        value={this.props.state.comments.value}
                        onChange={(event) => {
                            let value = event.target.value
                            this.props.actions.setValue('comments', value)
                        }}
                    />
                </Col>
            </Row>
        </Container>} />
    }
    LabourRates(data, maint) {
        const supplier = data
        return <Card
            title={window.t.en('Labour Rates')}
            description={window.t.en('Customer labour rates')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Spares Discount:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.sparesdiscount ? supplier.sparesdiscount : 'Not Set'}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Labour Rate Per Hour:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.labourrateperhour ? supplier.labourrateperhour : 'Not Set'}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'In Warranty Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.inwarrantylabourrate ? supplier.inwarrantylabourrate : 'Not Set'}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Out of Warranty Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.outwarrantylabourrate ? supplier.outwarrantylabourrate : 'Not Set'}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Special Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.speciallabourrate ? supplier.speciallabourrate : 'Not Set'}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'After Hours Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.afterhourslabourrate ? supplier.afterhourslabourrate : 'Not Set'}</Col>
                </Row>
            </Container>} />
    }
    renderView(data) {
        let documents = data.documents
        let step1 = data.step1.data
        let step3 = data.step3
        let step2 = data.step2
        let vehicle = step1.selectedVehicleDetails.data[0]
        let supplier = step3.data.selectedSupplierDetails.data[0]

        return <Container style={{ margin: 0 }} className="custom-container">
            <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
            <Row>
                <Col xl={12}>
                    {this.SupplierLatter(supplier)}
                </Col>
            </Row>
            {/* <Row>
                <Col xl={12}>
                    {this.LabourRates(supplier, vehicle.maintenancetransaction)}
                </Col>
            </Row> */}
            <Row>
                <Col xl={12}>
                    {this.quoteItems(vehicle, step3.data.quotation)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.checksheetComments(step2)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.quotesComments(step3)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.techManagerComments(data)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.OPSComments(data)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br></br>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {
                        documents
                            ? <PdfViewer
                                key={'document_viewer_approval'}
                                name={'booking'}
                                workflow={'fml_customer_quote'}
                                task={'tsk_defleet_review_and_approve_tech'}
                                documents={documents}
                                edit={false}
                                workflow_queue_data={this.props.workflow_queue_data}
                            />
                            : <Loading message={'Retrieving Booking Document...'} />
                    }
                </Col>
            </Row>            
            <Row>
                <Col xl={12}>
                    <br></br>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.commentsView()}
                </Col>
            </Row>
        </Container>
    }

    render() {
        let workflow_queue_data = null
        let validate = false
        try {
            validate = this.props.task === this.props.workflow_queue_data.workflow_task.name
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) { }

        return validate
            ? this.renderView(workflow_queue_data)
            : 'Select vehicle...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetQuoteApprovalGM
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetQuoteApprovalGM)