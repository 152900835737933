import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox'
import PdfViewer from '../../PDF/component'
import Icon from '../../Icon/component'
import ContractRestructureLines from '../ContractRestructureLines/reducer'
import Card from '../../../controls/card'
import * as config from '../../../config'
import * as styles from './styles'
import * as actions from './actions'
import TextField from '../../../controls/textField'
import AutoComplete from '../../../controls/autocomplete'
import RelatedGrid from '../../RelatedGrid/component'
import ViewVehicle from '../../../controls/view_vehicle'

import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

class ContractRestructure extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: 0
        }
    }
    componentDidMount() {
        this.props.actions.setValidationState('contractRestructure')

        let data = null
        try {
            data = this.props.state.contractSelectionGrid.original
            this.props.actions.getAllContractParemets(this.props.state.contractSelectionGrid.original[0].contractnumber)
            //this.props.actions.getVehicleAmort(data[0].licenseplateno)
            this.props.actions.setDefaultValues(data, data[0].licenseplateno)
            this.props.actions.setValue('contract', data)
            //this.props.actions.setValue('contractaccessory', data[0].contractaccessory)
        } catch (err) { }


        window.glyco.log('ContractRestructure....')
        window.glyco.log(data)

    }

    getDocuments(data) {
        window.glyco.log('getDocuments')
        window.glyco.log(data)
        let documents = null
        try {
            documents = data[0].documents
            window.glyco.log(documents)
        }
        catch (err) { }

        return documents && data.length === 1
            ? <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <h3 style={styles.hr}>{'Contrract Documents'}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {
                            documents
                                ? <PdfViewer
                                    key={'contract_viewer_'}
                                    documents={documents}
                                    multiple={true}
                                    height={500}
                                    edit={false}
                                />
                                : ''
                        }
                    </Col>
                </Row>
            </Container>

            : ''
    }

    dashboardChange(dashindex) {
        this.setState({ dashindex: dashindex })
    }
    getForm(data) {
        //console.log('Restruture',data)
        let old_contract = null
        let new_contract = null
        let customerderivative = {}
        let contractaccessory = []
        let contracts = []


        try {
            old_contract = data[0]
            new_contract = data[0]

            contractaccessory = this.props.state.contractaccessory.value

            customerderivative = new_contract.customer
                ? new_contract.customer.customerderivative.length
                    ? new_contract.customer.customerderivative.filter(x => x.contractname == new_contract.contractdescription)
                    : []
                : []
            customerderivative = customerderivative.length ? customerderivative[0] : {}
        } catch (err) { }

        //console.log('customerderivativex new_contract.customer', customerderivative)
        //console.log('new_contractx new_contract', new_contract)
        //console.log('new_contractx distance', new_contract.distance)
        //console.log('new_contractx closingodometer', new_contract.vehicle.closingodometer)

        return data.length
            ? <div><Container style={{ margin: 0 }} className="custom-container">
                <Row style={styles.rows}>
                    <Col xl={3}>
                        {
                            new_contract.imagefilename
                                ? <img style={styles.vehicle} src={config.system.cdn + '/' + (new_contract.imagefilename !== null ? new_contract.imagefilename : 'unknown.png')} />
                                : <div>{''}</div>
                        }
                    </Col>
                </Row>
                <Row>
                    <ViewVehicle
                        key={'contract_deviation_vehicle_details'}
                        restructure_view={true}
                        id={new_contract.vehicle.vehicle_id}
                    />
                </Row>
            </Container>

                <Card
                    title={'Contract Parameters'}
                    subtitle={'contract deviation parameter'}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row style={{ marginBottom: '5px' }}>
                            <Col xl={2} style={styles.fieldLabel}>{'Contract Last Deviation Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{new_contract.startdate.split('T')[0]}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Number Of Deviation:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{(parseInt(new_contract.contractnumber.split('-')[1]) + 1)}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Income Maintenance:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{new_contract.customercontractderivative[0].maintenanceincome}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Income Tyres:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{new_contract.customercontractderivative[0].tyresincome}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Maintenance Expenditure:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{new_contract.customercontractderivative[0].maintenanceexpenditure}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Tyres Expenditure:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{new_contract.customercontractderivative[0].tyresexpenditure}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Maintenance Fund Balance:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{new_contract.customercontractderivative[0].maintenancefundbalance}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Tyres Fund Balance:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{new_contract.customercontractderivative[0].tyresfundbalance}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Tyres Allocated:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{(parseInt(new_contract.customercontractderivative[0].tyrequantityfront) + parseInt(new_contract.customercontractderivative[0].tyrequantityrear))}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Tyres Used:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{new_contract.customercontractderivative[0].tyresused ? new_contract.customercontractderivative[0].tyresused : 0}</Col>
                        </Row>
                        <Row>
                            <Col xl={3}></Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <Tabs
                                    value={this.state.dashindex}
                                    onChange={
                                        (event, dashindex) => {
                                            this.dashboardChange(dashindex)
                                            //this.props.setTabInx = value
                                        }
                                    }
                                >
                                    <Tab style={{ minWidth: '20%' }} label={'Vehicle Expenses'}>
                                    </Tab>
                                    <Tab style={{ minWidth: '20%' }} label={'Burn Rates and Funds'}>
                                    </Tab>
                                    <Tab style={{ minWidth: '20%' }} label={'Deviation Kilometer Prediction'}>
                                    </Tab>
                                    <Tab style={{ minWidth: '20%' }} label={'Deviation Cost Prediction'}>
                                    </Tab>
                                    <Tab style={{ minWidth: '20%' }} label={'Contract History'}>
                                    </Tab>
                                </Tabs>
                                {
                                    this.state.dashindex === 0 && new_contract.vehicle
                                        ? <div>
                                            <iframe src={`${config.oq.uri}29377378156a43392b2d770752882a049ffdc13928dc9ca282d2a96a7b6686e908d8fd5e928e15c4d5038b23977fdbeac92d949b25d5bef01ff3ad03aa6840de&filters=registration=%27${new_contract.vehicle.licenseplateno}%27#`}
                                                height="700px"
                                                width="100%"
                                                style={{
                                                    border: "none",
                                                    margin: 0,
                                                    //padding: '-25px',
                                                    alignSelf: "center",
                                                }} />
                                        </div>
                                        : <div></div>
                                }
                                {
                                    this.state.dashindex === 1 && new_contract.vehicle
                                        ? <div>
                                            <iframe src={`${config.oq.uri}592e84403483642179fa97e5fcaff2c64b7eff45aafcba4c98bdffc8de63f9e7393de40cf2c18b03e1e5e4593fe7290d23509939da86105b32af82aeb03b716e&filters=registration=%27${new_contract.vehicle.licenseplateno}%27#`}
                                                height="320px"
                                                width="100%"
                                                style={{
                                                    border: "none",
                                                    margin: 0,
                                                    //padding: '-25px',
                                                    alignSelf: "center",
                                                }} />
                                        </div>
                                        : <div></div>
                                }
                                {
                                    this.state.dashindex === 2 && new_contract.vehicle
                                        ? <div>
                                            <iframe src={`${config.oq.uri}2eac217e7108d58043b4ba484c0657d19cf4b40511df4f05bea54ef640cb7609977cae19443250a6a1ea65b7784bb28fc308d079919cd36d256fc3bc9e5fe2af&filters=licenseplateno=%27${new_contract.vehicle.licenseplateno}%27#`}
                                                height="1200px"
                                                width="100%"
                                                style={{
                                                    border: "none",
                                                    margin: 0,
                                                    //padding: '-25px',
                                                    alignSelf: "center",
                                                }} />
                                        </div>
                                        : <div></div>
                                }
                                {
                                    this.state.dashindex === 3 && new_contract.vehicle
                                        ? <div>
                                            <iframe src={`${config.oq.uri}8290d9bc05765d4537a8fa2f6e95e7609cdb2dfc0b43d4971e33b29ab7ca4ade7a76da2b5a99ec76fa7dc9f02b287c23cbcbb95ee8bfce2ec8237d647357db4e&filters=licenseplateno=%27${new_contract.vehicle.licenseplateno}%27#`}
                                                height="1500px"
                                                width="100%"
                                                style={{
                                                    border: "none",
                                                    margin: 0,
                                                    //padding: '-25px',
                                                    alignSelf: "center",
                                                }} />
                                        </div>
                                        : <div></div>
                                }
                                {
                                    this.state.dashindex === 4
                                        ? <div>
                                            <br />
                                            <br />

                                            <table style={{ width: '100%' }}>

                                                {

                                                    ['Contract Number',
                                                        'Start Date', ' Period', 'Distance',
                                                        'Distance Per Month', 'Finance', 'Tyres', 'Licence',
                                                        'Maintenance', 'ROI', 'Accessories Selling', 'Service',
                                                        'Sundry Selling', 'Monthly_Excl', 'Monthly_Incl', 'Monthly_Vat'].map((label, index) => (
                                                            <tr key={index}>
                                                                <td style={{ width: `${100 / 12}%` }}>{label}:</td>
                                                                {this.props.state.contracts?.map(x => (
                                                                    <td key={x?.contractnumber || index || x.customer.customername} style={styles.selectedFieldName}>
                                                                        {x ? (label === 'Start Date' ? moment(new Date(x[label.toLowerCase().replace(/\s+/g, '')].split('T')[0])).format('DD/MM/YYYY') : x[label.toLowerCase().replace(/\s+/g, '')]) : ''}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                            </table>

                                        </div>
                                        : <div></div>
                                }
                            </Col>
                        </Row>
                    </Container>}
                />
                <Card
                    title={'Contract Values'}
                    subtitle={'contract deviation values'}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{''}</div></Col>
                            <Col xl={3}>
                                <h3>{'Current Parameters'}</h3>
                            </Col>
                            <Col xl={3}>
                                <h3>{'Six Month Average Parameters '}</h3>
                            </Col>
                            <Col xl={3}>
                                <h3>{'LTD Average Parameters'}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Utilization Deviation'}</div></Col>
                            <Col xl={3}>
                            </Col>
                            <Col xl={3}>
                            </Col>
                            <Col xl={3}>
                                <Checkbox
                                    id={'type_new'}
                                    value={this.props.state.restructuretype.value}
                                    onChange={(event, checked) => {
                                        this.props.actions.setValue('restructuretype', event.target.checked)
                                    }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'End Date'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'terminationdate'}
                                    label={'Termination Date'}
                                    value={
                                        old_contract.terminationdate.split('T')[0]}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'terminationdate_new'}
                                    label={'End Date'}
                                    value={this.props.state.six_month_terminationdate.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_terminationdate', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'ltd_terminationdate_new'}
                                    label={'End Date'}
                                    value={this.props.state.ltd_terminationdate.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_terminationdate', event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Period'}</div></Col>
                            <Col xl={3}>
                                <Row>
                                    <small style={{ fontWeight: 'bold', color: 'gray', padding: '15px' }}>Max Period Allowed: {customerderivative.periodmax} Months </small>
                                </Row>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'period_old'}
                                    label={'Period'}
                                    value={old_contract.customercontractderivative[0].period}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <Row>
                                    <small style={{ fontWeight: 'bold', color: 'gray', padding: '15px' }}>Max Period Allowed: {customerderivative.periodmax} Months </small>
                                </Row>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'period_new'}
                                    label={'Period'}
                                    value={this.props.state.six_month_period.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    error={parseInt(this.props.state.period.value ? this.props.state.six_month_period.value : 0) > parseInt(customerderivative.periodmax ? customerderivative.periodmax : 0) ? 'Allowed period exceeded.' : null}
                                    helperText={parseInt(this.props.state.six_month_period.value ? this.props.state.six_month_period.value : 0) > parseInt(customerderivative.periodmax ? customerderivative.periodmax : 0) ? 'Allowed period exceeded.' : ''}
                                    onChange={(event, value) => {
                                        this.props.actions.setPeriod('six_month_period', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <Row><small style={{ fontWeight: 'bold', color: 'gray', padding: '15px' }}>Max Period Allowed: {customerderivative.periodmax} Months </small></Row>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'ltd_period_new'}
                                    label={'Period'}
                                    value={this.props.state.ltd_period.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    error={parseInt(this.props.state.ltd_period.value ? this.props.state.ltd_period.value : 0) > parseInt(customerderivative.periodmax ? customerderivative.periodmax : 0) ? 'Allowed period exceeded.' : null}
                                    helperText={parseInt(this.props.state.ltd_period.value ? this.props.state.ltd_period.value : 0) > parseInt(customerderivative.periodmax ? customerderivative.periodmax : 0) ? 'Allowed period exceeded.' : ''}
                                    onChange={(event, value) => {
                                        this.props.actions.setltdPeriod('ltd_period', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Distance Per Month:'}</div></Col>
                            <Col xl={3}>
                                <Row><small style={{ fontWeight: 'bold', color: 'gray', padding: '15px' }}>Max Distance Allowed: {new_contract.mmcode.fueltype == 'petrol' ? customerderivative.distancemaxpetrol : customerderivative.distancemaxpetrol} Kms</small></Row>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    label={'Inclusive Kilometers:'}
                                    id={'averagedistancepermonth'}
                                    value={old_contract.distancepermonth}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <Row><small style={{ fontWeight: 'bold', color: 'gray', padding: '15px' }}>Max Distance Allowed: {new_contract.mmcode.fueltype == 'petrol' ? customerderivative.distancemaxpetrol : customerderivative.distancemaxpetrol} Kms</small></Row>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'averagedistancepermonth_new'}
                                    label={'Distance Per Month'}
                                    value={this.props.state.six_month_kms.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    error={parseInt(this.props.state.six_month_kms.value ? this.props.state.six_month_kms.value : 0) > parseInt(new_contract.mmcode.fueltype == 'petrol' ? customerderivative.distancemaxpetrol : customerderivative.distancemaxpetrol) ? 'Allowed KMs exceeded.' : null}
                                    helperText={parseInt(this.props.state.six_month_kms.value ? this.props.state.six_month_kms.value : 0) > parseInt(new_contract.mmcode.fueltype == 'petrol' ? customerderivative.distancemaxpetrol : customerderivative.distancemaxpetrol) ? 'Allowed KMs exceeded.' : ''}
                                    onChange={(event, value) => {
                                        this.props.actions.setDistancepermonth('six_month_kms', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <Row><small style={{ fontWeight: 'bold', color: 'gray', padding: '15px' }}>Max Distance Allowed: {new_contract.mmcode.fueltype == 'petrol' ? customerderivative.distancemaxpetrol : customerderivative.distancemaxpetrol} Kms</small></Row>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'averageltddistancepermonth_new'}
                                    label={'Distance Per Month'}
                                    value={this.props.state.ltd_kms.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    error={parseInt(this.props.state.ltd_kms.value ? this.props.state.ltd_kms.value : 0) > parseInt(new_contract.mmcode.fueltype == 'petrol' ? customerderivative.distancemaxpetrol : customerderivative.distancemaxpetrol) ? 'Allowed KMs exceeded.' : null}
                                    helperText={parseInt(this.props.state.ltd_kms.value ? this.props.state.ltd_kms.value : 0) > parseInt(new_contract.mmcode.fueltype == 'petrol' ? customerderivative.distancemaxpetrol : customerderivative.distancemaxpetrol) ? 'Allowed KMs exceeded.' : ''}
                                    onChange={(event, value) => {
                                        this.props.actions.setLtdDistancepermonth('ltd_kms', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Contract Distance'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'distance_old'}
                                    label={'Contract Distance'}
                                    value={old_contract.distance}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        //this.props.actions.setValue('distance', value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'distance_new'}
                                    label={'Contract Distance'}
                                    value={this.props.state.six_month_contractdistance.value}
                                    style={styles.textBox}
                                    errorText={this.props.state.six_month_contractdistance.value > 180000 ? 'Contract distance must be less than 30000 km' : null}
                                    disabled={true} ND834033
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'ltd_distance_new'}
                                    label={'Contract Distance'}
                                    value={this.props.state.ltd_contractdistance.value}
                                    style={styles.textBox}
                                    errorText={this.props.state.ltd_contractdistance.value > 180000 ? 'Contract distance must be less than 30000 km' : null}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                            </Col>
                        </Row>
                    </Container>
                    }
                />
                <Card
                    title={'Contract Accessory'}
                    subtitle={'Accessries list and values'}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row style={{ marginBottom: '5px' }}>
                            <Col xl={2}>
                                <strong>Accessory Name</strong>
                            </Col>
                            <Col xl={2}>
                                <strong>Cost Price</strong>
                            </Col>
                            <Col xl={2}>
                                <strong>Selling Price</strong>
                            </Col>
                            <Col xl={2}>
                                <strong>Supplier</strong>
                            </Col>
                            <Col xl={1}>
                                <strong>Debtor Settled Accessory </strong>
                            </Col>
                            <Col xl={1}>
                            </Col>

                        </Row>
                        {
                            contractaccessory
                                ? contractaccessory
                                    .map((x, i) => {
                                        return <Row style={{ paddingTop: '15px' }}>

                                            <Col xl={2}>

                                                <div>

                                                    {
                                                        x.accessoryname
                                                            ? x.accessoryname
                                                            : <AutoComplete
                                                                info={{
                                                                    show: false
                                                                }}
                                                                hintText={'Select Accessory '}
                                                                listStyle={{ maxHeight: 200, overflow: 'auto' }}

                                                                filter={AutoComplete.fuzzyFilter}
                                                                fullWidth={true}
                                                                openOnFocus={true}
                                                                data={this.props.state.accessories}
                                                                dataSourceConfig={{
                                                                    text: 'accessoryname',
                                                                    value: 'accessory_id'
                                                                }}
                                                                fieldvalue={'accessory_id'}
                                                                displayfields={['accessoryname']}
                                                                displaywidth={['80', '80']}
                                                                onSelect={(args) => {
                                                                    this.props.actions.setSelectedAccessory(contractaccessory, i, args.value)
                                                                }}
                                                                onFilter={(search) => {
                                                                    if (search) {
                                                                        this.props.actions.getAccessories(search)
                                                                    } else {
                                                                        this.props.actions.getAccessories(search)
                                                                        this.props.actions.setSelectedAccessory(contractaccessory, i, null)
                                                                    }
                                                                }}
                                                                onClick={(event) => {
                                                                    this.props.actions.getAccessories('')
                                                                }}
                                                            />}</div></Col>
                                            <Col xl={2} style={{ paddingTop: '10px' }}>
                                                <TextField
                                                    variant="outlined"

                                                    id={'accessories_' + i + '_' + x.accessory_id}
                                                    // value={
                                                    //     old_contract.contractaccessory.filter(c => c.accessoryname === x.accessoryname).length
                                                    //         ? old_contract.contractaccessory.filter(c => c.accessoryname === x.accessoryname)[0].amount
                                                    //         : 0.00
                                                    // }
                                                    value={x.amount}
                                                    style={styles.textBox}
                                                    disabled={true}
                                                    inputStyle={styles.textBoxInput}
                                                    label={'Cost Price'}
                                                    onChange={(event, value) => {
                                                        //this.props.actions.setAcc(this.field.accessory_tot, this.props.inx, i, value)
                                                    }}
                                                    onBlur={(event) => {
                                                        //this.props.actions.calcValues(component)
                                                        //this.props.actions.decimalFormatAcc(this.field.accessory_tot, this.props.inx, i, event.target.value)
                                                    }}
                                                />
                                            </Col>
                                            <Col xl={2} style={{ paddingTop: '10px' }}>
                                                <TextField
                                                    variant="outlined"

                                                    id={'accessories_' + i + '_' + x.accessory_id}
                                                    value={x.amount}
                                                    style={styles.textBox}
                                                    label={'Selling Price'}
                                                    disabled={x.accessoryname ? false : true}
                                                    inputStyle={styles.textBoxInput}
                                                    onChange={(event, value) => {

                                                        this.props.actions.setAccessory(contractaccessory, i, event.target.value)
                                                    }
                                                    }
                                                    onBlur={(event) => {
                                                        this.props.actions.calcValues()
                                                        //this.props.actions.calcValues(component)
                                                        //this.props.actions.decimalFormatAcc(this.field.accessory_tot, this.props.inx, i, event.target.value)
                                                    }}
                                                />
                                            </Col>
                                            <Col xl={2}>
                                                <AutoComplete
                                                    info={{
                                                        show: false
                                                    }}
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'supplier_id_' + x.accessory_id}
                                                    key={'supplier_key_' + x.accessory_id}
                                                    hintText={'Select Supplier '}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'suppliername',
                                                        value: 'supplier_id'
                                                    }}
                                                    fieldvalue={'supplier_id'}
                                                    displayfields={['suppliername']}
                                                    displaywidth={['80', '80']}
                                                    data={this.props.state.suppliers}
                                                    onSelect={
                                                        (args) => {
                                                            this.props.actions.setAccessorySupplier(contractaccessory, i, args.value, args.text)
                                                            //this.props.actions.setSupplierValue(chosenRequest.supplier_id, chosenRequest.suppliername)
                                                        }
                                                    }
                                                    onClick={
                                                        (event) => {
                                                            this.props.actions.getSupplierList('')
                                                        }
                                                    }
                                                    onFilter={
                                                        (search, dataSource, params) => {
                                                            if (search){
                                                                this.props.actions.getSupplierList(search)
                                                                const data = this.props.state.suppliers
                                                                const supplier = data.find(s => s.suppliername === search)
                                                                if (supplier){
                                                                    this.props.actions.setAccessorySupplier(contractaccessory, i, supplier.supplier_id, search)
                                                                }
                                                            } else {
                                                                this.props.actions.getSupplierList('')
                                                                this.props.actions.setAccessorySupplier(contractaccessory, i, null, search)
                                                            }
                                                        }
                                                    }
                                                />
                                            </Col>


                                            <Col xl={3} style={{ paddingTop: '10px' }}>
                                                <Checkbox
                                                    id={'zero_value' + i}
                                                    value={this.props.state.zero_value_accessory.value}
                                                    onChange={(event, checked) => {
                                                        this.props.actions.setZeroValueAccessory(contractaccessory, i, event.target.checked)
                                                        if (event.target.checked == true) {
                                                            this.props.actions.setAccessory(contractaccessory, i, 0)
                                                        } else {
                                                            this.props.actions.setAccessory(contractaccessory, i, x.amount)
                                                        }
                                                    }} />
                                            </Col>
                                            <Col xl={1}>
                                                <IconButton key={'command_remove_accessory'} style={styles.iconButton} iconStyle={styles.action}
                                                    onClick={
                                                        () => {
                                                            this.props.actions.delAccessory(contractaccessory, i)
                                                        }
                                                    }>
                                                    <Icon istyle={{ fontSize: '22px', color: 'red' }} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                                </IconButton>
                                            </Col>
                                        </Row>
                                    })
                                : ''
                        }
                        <Row>
                            <Col xl={12}>
                                <hr />
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <Button className={"global_button"} variant="contained"
                                    label={'Add Accessory'}
                                    primary={true}
                                    disabled={false}
                                    onClick={(event) => {
                                        this.props.actions.addAccessory(contractaccessory)
                                    }}
                                >{window.t.en('Add Accessory')}</Button>
                            </Col>
                        </Row>
                    </Container>
                    }
                />

                <Card
                    title={'Vehicle Prices'}
                    subtitle={'contract deviation values'}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Retail Price:'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'retailprice_old'}
                                    label={'Retail Price'}
                                    value={old_contract.customercontractderivative[0].retailprice}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'retailprice_new'}
                                    value={this.props.state.retailprice.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    label={'Retail Price'}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_retailprice', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'retailprice_new_ltd'}
                                    value={this.props.state.retailprice.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    label={'Retail Price'}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_retailprice', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Discount Amount:'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'discpriceprice_old'}
                                    label={'Discount Amount'}
                                    value={old_contract.customercontractderivative[0].discountamount}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        //this.props.actions.setValue('vehicleprice', value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'discpriceprice_new'}
                                    label={'Discount Amount'}
                                    value={this.props.state.discountamount.value}
                                    style={styles.textBox}
                                    //disabled={true}

                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_discountamount', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'discprice_new_ltd'}
                                    label={'Discount Amount'}
                                    value={this.props.state.discountamount.value}
                                    style={styles.textBox}
                                    //disabled={true}

                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_discountamount', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Vehicle Price:'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'vehicleprice_old'}
                                    value={old_contract.vehicleprice}
                                    style={styles.textBox}
                                    label={'Vehicle Price'}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        //this.props.actions.setValue('vehicleprice', value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'vehicleprice_new'}
                                    label={'Vehicle Price'}
                                    value={old_contract.vehicleprice}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        //this.props.actions.setValue('vehicleprice', value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'vehicleprice_new_ltd'}
                                    label={'Vehicle Price'}
                                    value={old_contract.vehicleprice}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        //this.props.actions.setValue('vehicleprice', value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Capital Outstanding Balance:'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'capitalbalance_old'}
                                    label={'Capital Outstanding Balance'}
                                    value={old_contract.vehicleprice}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        //this.props.actions.setValue('vehicleprice', value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    label={'Capital Outstanding Balance'}
                                    id={'capitalbalance_new'}
                                    value={this.props.state.capitalbalance.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_capitalbalance', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    label={'Capital Outstanding Balance'}
                                    id={'capitalbalance_new_ltd'}
                                    value={this.props.state.capitalbalance.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_capitalbalance', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'RV'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'rv'}
                                    label={'Residual Value'}
                                    value={old_contract.customercontractderivative[0].residualvaluepercentage}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'rv_new'}
                                    label={'Residual Value'}
                                    value={this.props.state.six_month_rv.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_rv', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'rv_new_ltd'}
                                    label={'Residual Value'}
                                    value={this.props.state.ltd_rv.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_rv', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'RV Amount'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Residual Value Amount'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'rvamount_old'}
                                    value={old_contract.customercontractderivative[0].residualvalue}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('rvamount', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Residual Value Amount'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'rvamount_new'}
                                    value={this.props.state.six_month_rvamount.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_rvamount', event.target.value)
                                        this.props.actions.setRVPerc('six_month_rvamount', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Residual Value Amount'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'rvamount_new'}
                                    value={this.props.state.ltd_rvamount.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_rvamount', event.target.value)
                                        this.props.actions.setRVPerc('ltd_rvamount', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Interest Rate'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Interest Rate'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'interestrate_old'}
                                    value={old_contract.customercontractderivative[0].interestrate}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Interest Rate'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'interestrate_new'}
                                    value={this.props.state.six_month_interestrate.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_interestrate', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Interest Rate'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'interestrate_new_ltd'}
                                    value={this.props.state.ltd_interestrate.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_interestrate', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'ROI %'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'ROI'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'roiper_old'}
                                    value={'12'}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'ROI'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'roiper_new'}
                                    value={this.props.state.six_month_roiper.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    disabled={true}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_roiper', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'ROI'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'roiper_new_ltd'}
                                    value={this.props.state.ltd_roiper.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    disabled={true}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_roiper', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'ROI Amount'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'ROI Amount'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'roi_old'}
                                    value={old_contract.customercontractderivative[0].monthlyreturnoninvestment}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'ROI Amount'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'roi_new'}
                                    value={this.props.state.six_month_roi.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_roi', event.target.value)
                                    }}
                                // onBlur={(event) => {
                                //     this.props.actions.calcValues()
                                // }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'ROI Amount'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'roi_new_ltd'}
                                    value={this.props.state.ltd_roi.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_roi', event.target.value)
                                    }}
                                // onBlur={(event) => {
                                //     this.props.actions.calcValues()
                                // }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    }
                />


                <Card
                    title={'Finance Details'}
                    subtitle={'Finance details'}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Accessories Selling'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Accessories Selling'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'accessoriesselling'}
                                    value={old_contract.customercontractderivative[0].accessoriesselling}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('accessoriesselling', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Accessories Selling'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'accessoriesselling_new'}
                                    value={
                                        _.sumBy(contractaccessory, (d) => { return parseFloat(d ? d.amount : 0) }).toFixed(2)
                                    }
                                    disabled={true}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_accessoriesselling', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Accessories Selling'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'accessoriesselling_new_ltd'}
                                    value={
                                        _.sumBy(contractaccessory, (d) => { return parseFloat(d ? d.amount : 0) }).toFixed(2)
                                    }
                                    disabled={true}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_accessoriesselling', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Service Selling'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Service Selling'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'serviceselling'}
                                    value={old_contract.customercontractderivative[0].servicesmonthlyselling}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Service Selling'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'serviceselling_new'}
                                    value={this.props.state.six_month_serviceselling.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    disabled={true}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_serviceselling', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Service Selling'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'serviceselling_new_ltd'}
                                    value={this.props.state.ltd_serviceselling.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    disabled={true}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_serviceselling', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Sundry Selling'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Sundry Selling'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'sundryselling'}
                                    value={old_contract.customercontractderivative[0].sundriescost}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('sundryselling', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Sundry Selling'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'sundryselling_new'}
                                    value={this.props.state.six_month_sundryselling.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_sundryselling', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Sundry Selling'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'sundryselling_new_ltd'}
                                    value={this.props.state.ltd_sundryselling.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_sundryselling', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Finance'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Finance'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'finance'}
                                    value={old_contract.customercontractderivative[0].financemonthlyselling}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Finance'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'finance_new'}
                                    value={this.props.state.six_month_finance.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_finance', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Finance'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'finance_new_ltd'}
                                    value={this.props.state.ltd_finance.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_finance', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Maintenance'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Maintenance'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'maintenance'}
                                    value={old_contract.customercontractderivative[0].maintenancemonthlyselling}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Maintenance'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'maintenance_new'}
                                    value={this.props.state.six_month_maintenance.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_maintenance', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Maintenance'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'maintenance_new_ltd'}
                                    value={this.props.state.ltd_maintenance.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_maintenance', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Tyres'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Tyres'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'tyres_old'}
                                    value={old_contract.customercontractderivative[0].tyresmonthlyselling}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Tyres'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'tyres_new'}
                                    value={this.props.state.six_month_tyres.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_tyres', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Tyres'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'tyres_new'}
                                    value={this.props.state.ltd_tyres.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_tyres', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Licence'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Licence'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'licence_old'}
                                    value={old_contract.customercontractderivative[0].licencemonthlyselling}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Licence'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'licence_new'}
                                    value={this.props.state.six_month_licence.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_licence', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Licence'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'licence_new_ltd'}
                                    value={this.props.state.ltd_licence.value}
                                    style={styles.textBox}
                                    //disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_licence', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Service'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Service'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'service'}
                                    value={old_contract.customercontractderivative[0].servicesmonthlyselling}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Service'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'service_new'}
                                    value={this.props.state.six_month_service.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_service', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Service'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'service_new_ltd'}
                                    value={this.props.state.ltd_service.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_service', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Admin Fee'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Admin Fee'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'adminfee_old'}
                                    value={old_contract.customercontractderivative[0].monthlyadminfee}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Admin Fee'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'adminfee_new'}
                                    value={this.props.state.six_month_adminfee.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_adminfee', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Admin Fee'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'adminfee_new_ltd'}
                                    value={this.props.state.ltd_adminfee.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_adminfee', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Funder Admin Fee'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Funder Admin Fee'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'funder_adminfee_old'}
                                    value={old_contract.financialinstitution.admin_fee}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Funder Admin Fee'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'funder_adminfee_new'}
                                    value={this.props.state.six_month_funder_adminfee.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_funder_adminfee', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Funder Admin Fee'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'funder_adminfee_new_ltd'}
                                    value={this.props.state.ltd_funder_adminfee.value}
                                    style={styles.textBox}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_funder_adminfee', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'ROI'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'ROI'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'roi'}
                                    value={old_contract.customercontractderivative[0].monthlyreturnoninvestment}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'ROI'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'roi_new'}
                                    value={this.props.state.six_month_roi.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_roi', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcValues()
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'ROI'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'roi_new_ltd'}
                                    value={this.props.state.ltd_roi.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_roi', event.target.value)
                                    }}
                                    onBlur={(event) => {
                                        this.props.actions.calcltdValues()
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    }
                />
                <Card
                    title={'Contract Totals'}
                    subtitle={'Deviation final values'}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Monthly Excl'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Monthly Excl'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'monthly_excl'}
                                    value={old_contract.customercontractderivative[0].monthlysellingsubtotal}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('monthly_excl', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Monthly Excl'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'monthly_excl_new'}
                                    value={this.props.state.six_month_monthly_excl.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_monthly_excl', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Monthly Excl'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'monthly_excl_new_ltd'}
                                    value={this.props.state.ltd_monthly_excl.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_monthly_excl', event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Monthly Vat '}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Monthly Vat'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'monthly_vat '}
                                    value={old_contract.customercontractderivative[0].monthlysellingvat}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('monthly_vat', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Monthly Vat'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'monthly_vat_new'}
                                    value={this.props.state.six_month_monthly_vat.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_monthly_vat', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Monthly Vat'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'monthly_vat_new_ltd'}
                                    value={this.props.state.ltd_monthly_vat.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_monthly_vat', event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Monthly Incl '}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Monthly Incl'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'monthly_incl '}
                                    value={old_contract.customercontractderivative[0].monthlysellingtotal}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('monthly_incl', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Monthly Incl'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'monthly_incl_new'}
                                    value={this.props.state.six_month_monthly_incl.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_monthly_incl', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Monthly Incl'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'monthly_incl_new_ltd'}
                                    value={this.props.state.ltd_monthly_incl.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_monthly_incl', event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Finance Excess'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Finance Excess'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'financeaccess'}
                                    value={old_contract.customercontractderivative[0].financeexcess}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('financeaccess', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Finance Excess'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'financeaccess_new'}
                                    value={this.props.state.six_month_financeaccess.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_financeaccess', event.target.value)
                                    }}
                                />
                            </Col>

                            <Col xl={3}>
                                <TextField
                                    label={'Finance Excess'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'financeaccess_new_ltd'}
                                    value={this.props.state.ltd_financeaccess.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_financeaccess', event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Maintenance Excess'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Maintenance Excess'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'maintenanceaccess'}
                                    value={old_contract.customercontractderivative[0].maintenanceexcess}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('maintenanceaccess', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Maintenance Excess'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'maintenanceaccess_new'}
                                    value={this.props.state.six_month_maintenanceaccess.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_month_maintenanceaccess', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Maintenance Excess'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'maintenanceaccess_new_ltd'}
                                    value={this.props.state.ltd_maintenanceaccess.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_maintenanceaccess', event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Recovery Excess'}</div></Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Recovery Excess'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'recoveryexccess'}
                                    value={old_contract.customercontractderivative[0].excess}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('excess', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Recovery Excess'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'recoveryexccess_new'}
                                    value={this.props.state.six_monthly_excess.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('six_monthly_excess', event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={3}>
                                <TextField
                                    label={'Recovery Excess'}
                                    variant="outlined"
                                    margin="normal"
                                    id={'recoveryexccess_new_ltd'}
                                    value={this.props.state.ltd_excess.value}
                                    style={styles.textBox}
                                    disabled={true}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setValue('ltd_excess', event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}><div style={styles.fieldTitle}>{'Use Recommendation'}</div></Col>
                            <Col xl={3}>
                            </Col>
                            <Col xl={3}>
                                <Checkbox
                                    id={'recommendation_new'}
                                    key={'recommendation_new'}
                                    value={this.props.state.six_month_recommendation.value}
                                    onChange={(event, checked) => {
                                        this.props.actions.setValue('six_month_recommendation', checked)
                                        this.props.actions.setContractValues()
                                    }} />

                            </Col>
                            <Col xl={3}>
                                <Checkbox
                                    id={'ltd_recommendation_new'}
                                    key={'ltd_recommendation_new'}
                                    value={this.props.state.ltd_recommendation.value}
                                    onChange={(event, checked) => {
                                        this.props.actions.setValue('ltd_recommendation', checked)
                                        this.props.actions.setContractltdValues()
                                    }}
                                />
                            </Col>
                        </Row>



                    </Container>}
                />
            </div>
            : ''
    }

    getGrid() {
        return <ContractRestructureLines />
    }

    getRestructure(data) {
        return <div>
            {this.getForm(data)}
            {this.getDocuments()}
        </div>
    }

    getDetails(data) {
        let vehicleamort = this.props.state.vehicleamort
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                {/* <Row>
                            <Col xl={12} style={styles.rowHeader}>
                                < Button className={"global_button"}
                                    secondary={true}
                                    label="Download PDF"
                                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                                    onClick={(event) => {
                                        //this.props.actions.downloadMaintanancePDF(data, list, vehicle_id, makemodelyear, licenseplateno, imagefilename, customercontractderivative)
                                    }}
                               >{window.t.en('xxxxx')}</Button>
                            </Col>
                        </Row> */}

                <Row>
                    {
                        vehicleamort[0]
                            ? <Col xl={12}>
                                <h3 style={styles.cob}>{'Current COB: '} {vehicleamort.length ? vehicleamort.sort((x, y) => y.billingnumber - x.billingnumber)[0].carriedforward : ''}</h3>
                            </Col>
                            : <Col xl={12}>
                                <h3 style={styles.cob}>{'Current COB: '}</h3>
                            </Col>
                    }
                </Row>
                <Row>
                    <Col xl={1}>
                        <strong>{'Billing No'}</strong>
                    </Col>
                    <Col xl={1}>
                        <strong>{'Entry No'}</strong>
                    </Col>
                    <Col xl={1}>
                        <strong>{'Type'}</strong>
                    </Col>
                    <Col xl={2}>
                        <strong>{'Registration'}</strong>
                    </Col>
                    <Col xl={2}>
                        <strong>{'Entry Date'}</strong>
                    </Col>
                    <Col xl={1} style={styles.numericDiv}>
                        <strong style={styles.numericDiv}>{'Capital'}</strong>
                    </Col>
                    <Col xl={1} style={styles.numericDiv}>
                        <strong style={styles.numericDiv}>{'BF'}</strong>
                    </Col>
                    <Col xl={1} style={styles.numericDiv}>
                        <strong style={styles.numericDiv}>{'CF'}</strong>
                    </Col>
                    <Col xl={1} style={styles.numericDiv}>
                        <strong style={styles.numericDiv}>{'Interest'}</strong>
                    </Col>
                    <Col xl={1} style={styles.numericDiv}>
                        <strong style={styles.numericDiv}>{'Payment'}</strong>
                    </Col>
                </Row>
                {
                    vehicleamort.sort((x, y) => y.billingnumber - x.billingnumber).map(x => {
                        return <Row>
                            <Col xl={1}>
                                {x.billingnumber}
                            </Col>
                            <Col xl={1}>
                                {x.entrynumber}
                            </Col>
                            <Col xl={1}>
                                {x.entrytype}
                            </Col>
                            <Col xl={2}>
                                {x.registrationnumber}
                            </Col>
                            <Col xl={2}>
                                {moment(x.cliententrydate).format('YYYY-MM-DD')}
                            </Col>
                            <Col xl={1} style={styles.numericFields}>
                                {parseFloat(x.capital).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                            <Col xl={1} style={styles.numericFields}>
                                {parseFloat(x.broughtforward).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                            <Col xl={1} style={styles.numericFields}>
                                {parseFloat(x.carriedforward).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                            <Col xl={1} style={styles.numericFields}>
                                {parseFloat(x.interest).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                            <Col xl={1} style={styles.numericFields}>
                                {parseFloat(x.payment).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                        </Row>
                    })
                }
            </Container>
        </div>
    }

    tabChange(value) {
        this.setState({ value: value })
    }
    renderView(data) {
        return <div>

            <Tabs
                value={this.state.value}
                onChange={
                    (event, value) => {
                        this.tabChange(value)
                        //this.props.setTabInx = value
                    }
                }
            >
                <Tab style={{ minWidth: '50%' }} label={'Contract Deviation'}>

                </Tab>
                <Tab style={{ minWidth: '50%' }} label={'Amort Details'}>

                </Tab>
            </Tabs>
            {
                this.state.value === 0 && <div>{this.getRestructure(data)}</div>
            }
            {
                this.state.value === 1 && <div>{this.getDetails(data)}</div>
            }

        </div>
    }

    render() {
        let data = null
        try {
            data = this.props.state.contractSelectionGrid.original
        } catch (err) { }
        //console.log("Restrucx", data);

        window.glyco.log('ContractRestructure')
        window.glyco.log(data)
        return (
            data
                ? this.renderView(data)
                : 'Loading...'
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.contractRestructure,
            contractSelectionGrid: {
                ...state.contractSelectionGrid,
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractRestructure)