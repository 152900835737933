import * as types from './types'

export const getBalancesheetData = (start, end) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_BALANCESHEET_RPT_DATA,
            payload: {
                type: 'get_balancesheet',
                start: start,
                end: end
            }
        })
    }
}

export const setBalancesheetData = (response) => {
    return (dispatch, getState) => {
        dispatch(setInputValue('columns', response.columns))
        dispatch(setInputValue('report_data', response.data))
    }
}

export const setInputValue = (prop, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_BALANCESHEET_RPT_DATA,
            payload: {
                prop: prop,
                data: data
            }
        })
    }
}