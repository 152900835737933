import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import Icon from '../../Icon/component'

import DateControl from '../../../controls/dateControl'

import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import Card from '../../../controls/card'


import Checkbox from '@mui/material/Checkbox'
import Upload from '../../Upload/component'
import TextField from '../../../controls/textField'

import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'
import * as actions from './actions'
import * as styles from './styles'

import Loading from '../../Loading/component'

class POCloseRebillBooking extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.setValidationState('poCloseRebillBooking')
    }

    renderView() {
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'Close_Rebill_Maint_Checkedsheet_' + this.data.step1.vehicle + '_'}
                                    header={false}
                                />
                            </Col>
                        <Row>
                            <Col xl={12}>
                                <hr />
                            </Col>
                        </Row>
                            <Col xl={4} >
                                <div style={{marginTop:'-15px'}}>
                                    <DateControl
                                            id={'txtcompleteddate'}
                                            key={'txtcompleteddate'}
                                            label={'Select Completed Date'}
                                            name={'txtcompleteddate'}
                                            value={this.props.state.completeddate}
                                            error={this.props.state.completeddate ? false : true}
                                            helperText={this.props.state.completeddate ? '' : 'Enter completed date'}
                                            onSelect={(args) => {
                                                this.props.actions.setValue('completeddate', args)
                                            }}
                                            type={'date'}
                                        />
                            </div>
                            </Col>
                            <Col xl={4}>
                                        <TextField
                                            style = {styles.textboxh}
                                            id={'supplier_invoiceno'}
                                            hintText={'Enter supplier invoice number'}
                                            fullWidth={true}
                                            variant="outlined" 
                                            multiLine={false}
                                            label={'Enter supplier invoice number'}
                                            helperText={'Enter supplier invoice'}
                                            error={this.props.state.supplier_invoiceno ? false : true}
                                            value={this.props.state.supplier_invoiceno}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('supplier_invoiceno',  event.target.value)
                                            }}
                                        />
                            </Col>
                            <Col xl={4}>
                                        <TextField
                                            id={'txtcomment'}
                                            hintText={'Enter Comments'}
                                            fullWidth={true}
                                            style = {styles.textboxh}
                                            variant="outlined" 
                                            multiLine={false}
                                            label={'Enter Comments'}
                                            error={false}
                                            helperText={this.props.state.comments ? '' : 'Enter comments'}
                                            value={this.props.state.comments}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('comments',  event.target.value)
                                            }}
                                        />
                            </Col>
                        </Row>
                    </Container>
        </div>
    }

    getLayout() {
        return this.data.step1.vehicle_id
        ? <div>
                <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
                <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
                <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
                <Card
                    title={'Close Authorisation'}
                    subtitle={'Complete the form below to close rebill authorisation'}
                    content={this.renderView()}
                />
            </div>
            : <div>
            <ViewVehicle key={this.data.step1.data.vehicle[0].vehicle_id} id={this.data.step1.data.vehicle[0].vehicle_id} />         
            <ViewSupplier key={this.data.step1.data.supplier[0].supplier_id} id={this.data.step1.data.supplier[0].supplier_id} />
            <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
            <Card
                title={'Close Authorisation'}
                subtitle={'Complete the form below to close rebill authorisation'}
                content={this.renderView()}
            />
        </div>
        // return  <div>
        //         <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
        //         <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
        //         <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
        //         <Card
        //             title={'Close Authorisation'}
        //             subtitle={'Complete the form below to close rebill authorisation'}
        //             content={this.renderView()}
        //         />
        //     </div>
    }

    render() {
        // this.data = null
        // try {
        //     this.data = this.props.workflow_queue_data.context_data.data.data.data
        // } catch (error) {

        // }
        // 

        
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data.length
            ? this.props.workflow_queue_data.context_data.data.data.data[0]
            : this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        

        return this.data
            ?  this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poCloseRebillBooking
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POCloseRebillBooking)