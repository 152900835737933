import * as types from './types'


// export const viewDocument = (data) => {

//     window.glyco.log('viewDocument')
//     window.glyco.log(data)


//     const getFile = () => {
//         const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
//         return window.URL.createObjectURL(blob)
//     }

//     const saveByteArray = (function () {
//         let a = document.createElement('a')
//         document.body.appendChild(a)
//         a.style = 'display: none'

//         return (data, name) => {
//             const blob = new Blob(data, { type: 'octet/stream' })
//             const url = window.URL.createObjectURL(blob)

//             a.href = url
//             a.download = name
//             a.click()

//             window.URL.revokeObjectURL(url)
//         }
//     }())

//     saveByteArray([data.data.pdf], data.data.key)

//     return (dispatch, getState) => {
//         dispatch({
//             type: types.VIEW_DOCUMENT,
//             payload: ''
//         })
//     }
// }

export const getLookupValueData = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_LIST,
            payload: search
        })
    }
}

export const setLookupValueData = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_LIST,
            payload: payload.data
        })
    }
}

export const setLookupValue = (supplier_id, suppliername) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_SUPPLIER,
            payload: {
                supplier_id: supplier_id,
                suppliername: suppliername
            }

        })
        dispatch(getSupplierAccessoryData(supplier_id,''))
    }
}

export const refreshSupplierList = () => {
    return (dispatch, getState) => {
        let _sup = getState().accessory.supplier
        dispatch(getSupplierAccessoryData(_sup.supplier_id,_sup.suppliername))
    }
}

export const getSupplierAccessoryData = (supplier_id, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_SUPPLIER_ACCESSORY_LIST,
            payload: {
                supplier_id: supplier_id,
                search: search
            }
        })
    }
}

export const setSupplierAccessoryData = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_SUPPLIER_ACCESSORY_LIST,
            payload: payload.data
        })
    }
}

export const addNewSupplierPricingItem = (data) => {
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.ADD_NEW_SUPPLIER_PRICING_PROFILE_ITEM,
            payload: {
                type: 'ADD_NEW_SUPPLIER_PRICING_PROFILE_ITEM',
                data: data
            }
        })
    }
}
export const updSupplierAccessorySellingPrice = (inx, value) => {
    return (dispatch, getState) => {
        let accessory_list = getState().accessory.accessory_list
        accessory_list[inx].sellingprice = value
        let supplieraccessorypricing_id = accessory_list[inx].supplieraccessorypricing_id
         dispatch({
            type: types.SET_SELECTED_SUPPLIER_ACCESSORY_LIST,
            payload: accessory_list
         })
         
        dispatch(updateSupplierSellingPrice(supplieraccessorypricing_id,value, inx))
    }
}
export const updSupplierAccessoryCostPrice = (inx, value) => {
    return (dispatch, getState) => {
        let accessory_list = getState().accessory.accessory_list
        accessory_list[inx].costprice = value
        let supplieraccessorypricing_id = accessory_list[inx].supplieraccessorypricing_id
         dispatch({
            type: types.SET_SELECTED_SUPPLIER_ACCESSORY_LIST,
            payload: accessory_list
         })
         dispatch(updateSupplierCostPrice(supplieraccessorypricing_id,value, inx))
    }
}
export const updateSupplierSellingPrice = (supplieraccessorypricing_id, value, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_SUPPLIER_ACCESSORY_SELLING_PRICE,
            payload: {
                type: 'UPDATE_SUPPLIER_ACCESSORY_SELLING_PRICE',
                supplieraccessorypricing_id: supplieraccessorypricing_id,
                value: value,
                inx: inx,
            }
        })
    }
}
export const updateSupplierCostPrice = (supplieraccessorypricing_id, value, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_SUPPLIER_ACCESSORY_COST_PRICE,
            payload: {
                type: 'UPDATE_SUPPLIER_ACCESSORY_COST_PRICE',
                supplieraccessorypricing_id: supplieraccessorypricing_id,
                value: value,
                inx: inx,
            }
        })
    }
}
export const setUpdatedSupplierSellingPrice = (response, payload) => {
    return (dispatch, getState) => {
        let accessory_list = getState().accessory.accessory_list
        accessory_list[payload.inx].costprice = payload.value
         dispatch({
            type: types.SET_SELECTED_SUPPLIER_ACCESSORY_LIST,
            payload: accessory_list
         })
    }
}
export const setUpdatedSupplierCostPrice = (response, payload) => {
    return (dispatch, getState) => {
        let accessory_list = getState().accessory.accessory_list
        accessory_list[payload.inx].costprice = payload.value
         dispatch({
            type: types.SET_SELECTED_SUPPLIER_ACCESSORY_LIST,
            payload: accessory_list
         })
    }
}
export const removeSingleRow = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REMOVE_SELECTED_SUPPLIER_ACCESSORY_LIST,
            payload: {
                type: 'REMOVE_SELECTED_SUPPLIER_ACCESSORY_LIST',
                data: data
            }
        })
    }
}
export const setSearchValue = (data,search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACCESSORY_LIST_SEARCH_TEXT,
            payload: search
        })
        
        //dispatch(getSupplierAccessoryData(data.supplier_id,search))
    }
}



export const getAccessoriesList = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ALL_ACCESSORY_LIST,
            payload: search
        })
    }
}

export const setAccessoriesList = (paload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ALL_ACCESSORY_LIST,
            payload: paload.data
        })
    }
}
export const setSelectedAccessory = (accessory, accessoryname, accessory_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_ACCESSORY,
            payload: {
                accessoryname: accessoryname, 
                accessory_id: accessory_id
            }
        })
        dispatch({
            type: types.UPDATE_SELECTED_ACCESSORY,
            payload: {
                type: 'UPDATE_SELECTED_ACCESSORY',
                accessoryname: accessoryname, 
                accessory_id: accessory_id,
                accessory: accessory
            }
        })
    }
}