import * as props from './props'
import * as validatorTypes from '../Validator/types'

export default (state = props, action) => {
    switch (action.type) {
        case validatorTypes.TOGGLE_PASSWORD_VISIBILITY:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.field.id]: {
                        ...action.payload.field,
                        type: action.payload.type
                    }
                }
            }
        case validatorTypes.VALIDATE_FIELD:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.id]: {
                        ...action.payload,
                        input: action.payload.input,
                        empty: action.payload.empty,
                        errorText: action.payload.valid ? '' : action.payload.errorText
                    }
                }
            }
        case validatorTypes.SHOW_PASSWORD_STRENGTH:
            return {
                ...state,
                password: {
                    ...state.password,
                    value: action.payload
                }
            }
        case validatorTypes.VALIDATE_PASSWORD:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    password: {
                        ...state.fields.password,
                        valid: action.payload.valid,
                        input: action.payload.input,
                        empty: action.payload.emtpy,
                        errorText: action.payload.errorText
                    }
                }
            }
        case validatorTypes.SET_FORM_STATUS:
            return {
                ...state,
                valid: action.payload
            }
        default:
            return state
    }
}