export const purchaseorder = []
export const accountingitem = []
export const selectedPO = []
export const selectedCA = []
export const suppliername = ''
export const invoicereference = ''
export const authnumber = ''
export const transactiontype = 'Invoice'
export const supplierinvoiceamount = '0.00'
export const amount_excl = '0.00'
export const amount_vat = '0.00'
export const amount_disc = '0.00'
export const amount_incl = '0.00'
export const invoice_confirm_accept = 'You have chosen to accept an invoice, press "confirm" to confirm your action'
export const invoice_confirm_reject = 'You have chosen to reject an invoice, press "confirm" to confirm your action'