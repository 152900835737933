export const REFRESH_CIS = 'REFRESH_CIS'
export const SET_CIS_DATA = 'SET_CIS_DATA'
export const GET_CIS_DOWNLOAD_DATA = 'GET_CIS_DOWNLOAD_DATA'
export const GET_CIS_SEND_EMAIL_DATA = 'GET_CIS_SEND_EMAIL_DATA'
export const GET_CIS_HISTORY_FILE = 'GET_CIS_HISTORY_FILE'
export const GET_CIS_EMAIL_DATA = 'GET_CIS_EMAIL_DATA'
export const SET_CIS_EMAIL_DATA = 'SET_CIS_EMAIL_DATA'
export const ADD_USER_LIST = 'ADD_USER_LIST'
export const REMOVE_USER_FROM_LIST = 'REMOVE_USER_FROM_LIST'
export const GET_USER_CIS_LIST = 'GET_USER_CIS_LIST'
export const SET_USER_CIS_LIST = 'SET_USER_CIS_LIST'
export const SHOW_CIS_ADD_DIALOG = 'SHOW_CIS_ADD_DIALOG'
export const SET_CIS_USER_INPUT = 'SET_CIS_USER_INPUT'
export const GET_CIS_HISTORY_DATA = 'GET_CIS_HISTORY_DATA'
export const SET_CIS_HISTORY_DATA = 'SET_CIS_HISTORY_DATA'
export const SHOW_CIS_CONFIRM_DELETE_DIALOG = 'SHOW_CIS_CONFIRM_DELETE_DIALOG'





