import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as gridTypes from '../AdhocGrid/types'
import * as types from './types'


export const getPOs = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPLETED_POS,

        })
    }
}
export const refresh_invno = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REFRESH_INVNO,
            payload: val
        })

    }
}
export const refresh_invamt = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REFRESH_INVAMT,
            payload: val
        })

    }
}

export const getposupplierlist = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_POSUPPLIERLIST,
            payload: {
                search_text: search
            }
        })
    }

}
export const setposupplierlist = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.suppliername,
            value: x.supplier_id
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_DATA_LIST,
            payload: {
                data: data,
                component: 'purchaseorder',
                field: 'purchaseorder_id'
            }
        })
    }
}

export const setPOs = (payload) => {
    window.glyco.log('setPOs')
    window.glyco.log(payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPLETED_POS,

        })
    }
}

export const uploadPODocument = (id, supplier_id) => {
    window.glyco.log('setPOs')
    window.glyco.log(id)
    return (dispatch, getState) => {
        const data = {
            id: id,
            documents: getState().upload.documents,
            supplier_id: supplier_id
        }
        window.glyco.log(' uploadPODocument data')
        window.glyco.log(data)
        dispatch({
            type: types.UPLOAD_PO_DOCUMENTS,
            payload: JSON.stringify(data),
        })
    }
}

export const refreshbysupplier = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    supplier_id: id,
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}
export const setApprovePO = (payload) => {
    window.glyco.log('setApprovePO')
    window.glyco.log(payload)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Approved',
                additionalText: 'Purchase order has been successfully approved',
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const approvePO = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_PO,
            payload: data
        })
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const rejectsPO = (id) => {
    window.glyco.log('setPOs')
    window.glyco.log(id)
    return (dispatch, getState) => {
        dispatch({
            type: types.REJECT_PO,
            payload: id
        })
    }
}

export const setRejectPO = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Rejected',
                additionalText: 'Purchase order has been successfully rejected',
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const setUploadPO = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Uploaded',
                additionalText: 'Purchase order document successfully uploaded',
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const alert = (msg) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Approval',
                additionalText: msg,
                overflowText: '',
                autoHide: 7500,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: gridTypes.ADHOC_GRID_GET_DATA,
            payload: {
                name: 'purchaseorder',
                uri: '/components/purchaseorder/other',
                body: {
                    type: 'get_completed_pos',
                    paging: {
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    },
                    state: {
                        page: 1,
                        pageSize: 10,
                        sorted: undefined,
                        filtered: undefined
                    },
                    search: ''
                }
            }
        })
    }
}

export const ApproveDisposal = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Disposal Approval...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.GET_DISPOSAL_INVOICE,
            payload: {
                id: id,
                action: 'approve'
            }
        })
    }
}

export const RejectDisposal = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Disposal Rejection...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const setDisposalApprovalInvoice = (data) => {
    //console.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Disposal Invoice Generated...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(getInvoicePDF(data.data))
    }
}

export const getInvoicePDF = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.GEN_CUSTOMER_INVOICE_PDF,
            payload: {
                type: "GEN_CUSTOMER_INVOICE_PDF",
                id: id
            }
        })
    }
}


/* 
export const setTermInvoicePDF = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
} */