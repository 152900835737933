import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Table } from '@mui/material'
import * as actions from './actions'
import * as styles from './styles'

class TRIALBALANCE_RPT extends Component {
    componentDidMount() {
       this.props.actions.getTrialbalanceData()
    }
    
    getLayout() {
        return <Table className="table table-borderless  mb-0">
        <thead>
            <tr>
                <th className="text-uppercase bg-secondary">Account Code</th>
                <th className="text-uppercase bg-secondary">Account</th>
                <th className="text-uppercase bg-secondary">Account Type</th>
                <th className="text-uppercase bg-secondary">Debit - YTD</th>
                <th className="text-uppercase bg-secondary">Credit - YTD</th>
                <th className="text-uppercase bg-secondary">Date</th>
            </tr>
        </thead>
        <tbody>
            {
                this.props.state.report_data.map(x => { 
                    return <tr>
                        <td>{x.accountcode}</td>
                        <td>{x.accountname}</td>
                        <td>{x.accounttype}</td>
                        <td>{x.debit}</td>
                        <td>{x.credit}</td>
                        <td>{x.total}</td>
                    </tr>
                })
            }


        </tbody>
    </Table>
    }
    
    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.trialbalance_rpt }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TRIALBALANCE_RPT)