import * as colours from '@mui/material/colors'

export const required = {
    color: colours.red[500],
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10,
    fontWeight: 'bold'
}

export const redbox = {
    backgroundColor: colours.red[500],
    border: '2px solid red'
}

export const greenbox = {
    backgroundColor: colours.green[500],
    border: '2px solid red'
}


export const redboxtext = {
    fontSize: '16px',
}
export const selectedIcon = {
    width: 16
}
export const selectedFieldName = {
    fontWeight: 'bold'
}

export const confirmSelection = {
    textAlign: 'right'
}
export const leftLabel = {
    textAlign: 'left'
}
export const separator = {
    margin: '1px 0px',
}

export const card = {
    margin: '0 auto',
    width: '300px',
    maxWidth: '300px'
}

export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}

export const dropZone = {
    width: '90%',
    height: '300px',
    border: '1px solid red',
    padding: '10px'
}

export const lines = {
    borderBottom: '1px solid #f4f5f5',
}

export const rowTitle = {
    width: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    paddingRight: 10
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const inputWidth = {
    width: '100%'
}

export const fieldContainer = {
    display: 'table',
    padding: 5
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const row = {
    ////backgroundColor: '#dcd6d7',
    padding: 10
}
export const lintitems = {
    backgroundColor: '#eaf6ff',
    borderBottom: '2px solid gray',
    padding: 10
}
export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '19px',
}

export const numericFields = {
    textAlign: 'right',
}

export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
    fontWeight: 'bold',
}

export const warning = {
    width: '100%',
    backgroundColor: '#fffbe5',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}

export const info = {
    width: '100%',
    backgroundColor: '#C7FFFA',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}

export const textfield = {
    padding: 15
}
export const textfield2 = {
    padding: 20
}