import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getCustomerList = (search) => {
    return {
        type: types.GET_CONTACTS_CUSTOMER_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getContactList = (search) => {
    return {
        type: types.GET_CONTACT_LIST,
        payload: {
            searchtext: search
        }
    }
}
export const linkContact = (contact, debtor) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.LINK_CONTACT,
            payload: {
                contact_id: Number(contact),
                customer_id: Number(debtor)
            }
        })
    }
}

export const newContact = (contact) => {
    return (dispatch, getState) => {
        var text = 'Missing '
        text += (contact.customer ? '': '[Debtor] ') 
        text += (contact.contacttype ? '' : '[Contact Type] ') 
        text += (contact.firstname ? '' : '[First Name] ') 
        text += (contact.lastname ? '' : '[Last Name] ') 
        text += (contact.email ? '' : '[E-Mail] ') 
        text += (contact.title ? '' : '[Title] ') 

        if (contact.customer && contact.contacttype && contact.firstname && contact.lastname && contact.email) {
            dispatch({
                type: types.NEW_CONTACT,
                payload: {
                    contact: contact,
                    user: contact.user
                }
            })
        } else {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: text,
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}
export const removeContact = (contact, debtor) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REMOVE_CONTACT,
            payload: {
                contact_id: Number(contact),
                customer_id: Number(debtor),
                action: 'remove'
            }
        })
    }
}
export const updateContact = (contact, user) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_CONTACT,
            payload: {
                data: contact,
                user: user,
                action: 'update'
            }
        })
    }
}
export const setCustomerList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.hierarchyname + ' | ' + x.accountnumber,
            value: x.customer_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customercontactcustom',
            field: 'customer_id'
        }
    }
}
export const getContactTypeList = (search) => {
    return {
        type: types.GET_CONTACT_TYPE_LIST,
        payload: {
            searchtext: search,
            input: 'Contact Type'
        }
    }
}

export const setContactTypeList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.text,
            value: x.id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customercontactcustom',
            field: 'contacttype_id'
        }
    }
}

export const getTitleList = (search) => {
    return {
        type: types.GET_TITLE_LIST,
        payload: {
            searchtext: search,
            input: 'Title'
        }
    }
}

export const setTitleList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.text,
            value: x.id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customercontactcustom',
            field: 'title_id'
        }
    }
}

export const setContactList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.contacttype + ' | ' + x.fullname + ' | ' + x.email,
            value: x.contact_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customercontactcustom',
            field: 'contact_id'
        }
    }
}
export const getQueryTypeList = (search) => {
    return {
        type: types.GET_QUERYTYPE_LIST,
        payload: {
            search_text: search
        }
    }
}
export const setInvoiceQueryTypeList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.querytype,
            value: x.querytype_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customertransactioncustom',
            field: 'querytype'
        }
    }
}
export const setFieldValue = (values, payload) => {
    var data = payload.map((x, i) => {
        return x.contact_id === values.id && (values.field == 'remove') ?
            {
                ...x,
                remove: values.value,
            }
            : x.contact_id === values.id && (values.field == 'email') ?
            {
                ...x,
                email: values.value,
            } : x.contact_id === values.id && (values.field == 'telephone_work') ?
            {
                ...x,
                telephone_work: values.value,
            } : x.contact_id === values.id && (values.field == 'telephone_home') ?
            {
                ...x,
                telephone_home: values.value,
            } : x.contact_id === values.id && (values.field == 'mobile') ?
            {
                ...x,
                mobile: values.value,
            } : x
    })
    return (dispatch, getState) => {
        if (data != null) {
            dispatch({
                type: types.EDIT_CONTACT,
                payload: {
                    data: data,
                    component: 'customercontactcustom',
                    prop: 'customercontactcustom_id'
                }
            })
        }

    }
}


export const saveCustomerAllocationData = (data, userdata, filters) => {
    var id = filters.id, description = filters.description, amount = filters.amount, date = filters.date, type = filters.type, allocated = filters.allocated
    var query = ''
    if (id) query = ' and customer_id =' + id
    query = " and (description ilike '%" + (description ? description : '') + "%' or reference ilike '%" + (description ? description : '') + "%' or invoicenumber ilike '%" + (description ? description : '') + "%')"
    query = query + " and (debit::text ilike '%" + (amount ? amount : '') + "%' or credit::text ilike '%" + (amount ? amount : '') + "%' or allocationamount::text ilike '%" + (amount ? amount : '') + "%')"
    query = query + " and (customertransactionmonth::text ilike '%" + (date ? date : '') + "%' or customertransactiondate::text ilike '%" + (date ? date : '') + "%')"
    query = query + " and transactiontype ilike '%" + (type ? type : '') + "%'"
    if (allocated) query = query + " and allocated = " + (allocated == 'No' ? 'false' : 'true')
    return {
        type: types.SAVE_CUSTOMER_TRANSACTION_DATA,
        payload: {
            data: data,
            user: userdata,
            query: query
        }
    }
}

export const setLookupValue = (field, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_SELECTED_ITEM,
            payload: {
                value: value,
                text: text,
                prop: field,
                component: 'customercontactcustom'
            }
        })
    }
}

export const getallcontacts = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CUSTOMER_CONTACT_LIST,
            payload: {
                type: "GET_CUSTOMER_CONTACT_LIST",
                id: Number(id)
            }
        })
    }
}

export const getAllTransactionsListing = (filters) => {
    var id = filters.id, description = filters.description, amount = filters.amount, date = filters.date, type = filters.type, allocated = filters.allocated
    var query = ''
    if (id) query = ' and customer_id =' + id
    query = " and (description ilike '%" + (description ? description : '') + "%' or reference ilike '%" + (description ? description : '') + "%' or invoicenumber ilike '%" + (description ? description : '') + "%')"
    query = query + " and (debit::text ilike '%" + (amount ? amount : '') + "%' or credit::text ilike '%" + (amount ? amount : '') + "%' or allocationamount::text ilike '%" + (amount ? amount : '') + "%')"
    query = query + " and (customertransactionmonth::text ilike '%" + (date ? date : '') + "%' or customertransactiondate::text ilike '%" + (date ? date : '') + "%')"
    query = query + " and transactiontype ilike '%" + (type ? type : '') + "%'"
    if (allocated) query = query + " and allocated = " + (allocated == 'No' ? 'false' : 'true')


    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ALL_CUSTOMER_TRANSACTION_LIST,
            payload: {
                type: "GET_ALL_CUSTOMER_TRANSACTION_LIST",
                query: query
            }
        })
    }
}

export const setCustomerContactList = (payload) => {
    var msg = payload.id == 0 ? 'Link Already Exists...' : 'Action Successful!...'

    return (dispatch, getState) => {
        if (payload.id >= 0) {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: msg,
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
        dispatch({
            type: types.SET_CUSTOMER_TRANSACTION_LIST,
            payload: {
                data: payload.data,
                component: 'customercontactcustom',
                prop: 'customercontactcustom_id'
            }
        })
    }
}

export const excelExport = (component) => {
    return (dispatch, getState) => {
        const header = [{
            key: 'customertransactiondate',
            name: 'Date',
            default: ' ',
        }, {
            key: 'age',
            name: 'Age',
            default: '0',
        }, {
            key: 'transactiontype',
            name: 'Type',
            default: ' ',
        }, {
            key: 'reference',
            name: 'Reference',
            default: ' ',
        }, {
            key: 'description',
            name: 'Description',
            default: ' ',
        }, {
            key: 'debit',
            name: 'Debit',
            default: '0',
        }, {
            key: 'credit',
            name: 'Credit',
            default: '0',
        }, {
            key: 'allocation',
            name: 'Allocation',
            default: 'None',
        }, {
            key: 'allocationamount',
            name: 'Allocation Amount',
            default: '0',
        }, {
            key: 'allocated',
            name: 'Allocated',
            default: false,
        }, {
            key: 'querystatus',
            name: 'Queried',
            default: false,
        }, {
            key: 'querytype',
            name: 'Query Type',
            default: 'None',
        }, {
            key: 'queryresolved',
            name: 'Query Resolved',
            default: false,
        }]

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: component.name,
                data: component.data.customercontactcustom_id.data,
                header: header
            }
        })
    }
}

export const getCreditNotePDF = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GEN_CUSTOMER_CREDIT_NOTE_PDF,
            payload: {
                type: "GEN_CUSTOMER_CREDIT_NOTE_PDF",
                id: id
            }
        })
    }
}

export const setCustomerCreditNotePDF = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getInvoicePDF = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GEN_CUSTOMER_INVOICE_PDF,
            payload: {
                type: "GEN_CUSTOMER_INVOICE_PDF",
                id: id
            }
        })
    }
}

export const setCustomerInvoicePDF = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

