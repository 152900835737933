import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField'
import AutoComplete from '../../controls/autocomplete'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../Icon/component'
import Theme from '../../theme'
import WorkflowInput from '../WorkflowInput/component'
import WorkflowQuoteItemGrid from '../WorkflowQuoteItemGrid/component'
import Loading from '../Loading/component'
import WorkflowViewList from '../WorkflowViewList/component'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowTaskStepComponentQuoteCreator extends Component {
    componentDidMount() {
        // if (!this.props.state.component.selected.length)
        //     this.props.actions.laodSelectedData(this.props.step, this.props.state.component)
    }

    getContractTypeSelection() {
        const quotetypes = this.props.state.contracttype
        const contracttype = this.props.state.contracttype
        const customer_id = this.props.state.customer.selected[0].original.customer_id
        return <AutoComplete
            hintText={'Select Type'}
            onNewRequest={
                (chosenRequest, index) => {
                    this.props.actions.setQuoteTypesSelection(contracttype, chosenRequest)
                }
            }
            onUpdateInput={
                (searchText, dataSource, params) => {
                    this.props.actions.getContractTypeComponent(quotetypes, customer_id)
                }
            }
            dataSource={this.props.state.contracttype.grid.data}
            dataSourceConfig={{
                text: 'contracttype',
                value: 'contracttype_id'
            }}
        />
    }

    getVehicleGroupSelection() {

        const contractvehiclecategory = this.props.state.contractvehiclecategory
        const contracttype_id = this.props.state.contracttype.data.contracttype_id.text
        return <AutoComplete
            hintText={'Select Type'}
            onNewRequest={
                (chosenRequest, index) => {
                    this.props.actions.setVehicleGroupSelection(contractvehiclecategory, chosenRequest)
                }
            }
            onUpdateInput={
                (searchText, dataSource, params) => {
                    this.props.actions.getVehicleGroupComponents(contractvehiclecategory, contracttype_id)
                }
            }
            dataSource={this.props.state.contractvehiclecategory.grid.data}
            dataSourceConfig={{
                text: 'categorydescription',
                value: 'contractvehiclecategory_id'
            }}
        />
    }

    getVehicleMMCodeSelection() {

        const mmcode = this.props.state.mmcode
        const contractvehiclecategorymmcodegroup = this.props.state.contractvehiclecategorymmcodegroup
        const contractvehiclecategory_id = this.props.state.contractvehiclecategory.data.contractvehiclecategory_id.text
        return <AutoComplete
            hintText={'Select Type'}
            onNewRequest={
                (chosenRequest, index) => {
                    this.props.actions.setVehicleMMCodeSelection(mmcode, chosenRequest)
                }
            }
            onUpdateInput={
                (searchText, dataSource, params) => {
                    this.props.actions.getVehicleMMCodeComponents(contractvehiclecategorymmcodegroup, contractvehiclecategory_id)
                }
            }
            dataSource={this.props.state.mmcode.grid.data}
            dataSourceConfig={{
                text: 'makemodelyear',
                value: 'mmcode_id'
            }}
        />
    }

    setQuoteGrid() {
        // const quotetype = this.props.state.quotetype.selected[0].original.quotetype
        // if (!quotetype || !quotetype.length) {
        //     return <Card initiallyExpanded={false}>
        //         <CardHeader
        //             actAsExpander={false}
        //             showExpandableButton={false}
        //             title={window.t.en('Oops'}
        //             subtitle={window.t.en('Quote type not found, please try again.'}
        //         //avatar={<Icon istyle={styles.icon} iclass={'material-icons'} iname={'loading'} />}
        //         />
        //     </Card>
        // }

        return <WorkflowQuoteItemGrid
                name={this.props.state.surrogateitems.name}
                key={'workflow_quoteitem_' + this.props.state.surrogateitems.name} />
    }

    render() {

        this.component = this.props.state.surrogateitems.grid.data

        this.grid = this.props.state.surrogateitems.grid.data
        this.selected = this.props.state.surrogateitems.selected


        this.data = !this.props.state.step.task.workflow_task_step ? []
            : this.props.state.step.task.workflow_task_step
            .filter(x =>
                x.name === this.props.step &&
                x.workflow_task_step_component.filter(y => y.component.name === this.props.name).length
            )
            .reduce((arr, x) => {
                if (x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length).length) {
                    arr.push(x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length)[0].data)
                }

                return arr
            }, [])



        return (
            this.props.state.surrogateheader.data.contracttype_id.text === 'Managed Maintenance - Transnet'
            ? ''
            : this.data.length
                ? (
                    <div>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    <WorkflowViewList
                                        key={'view_' + this.component.name}
                                        name={this.component.name}
                                        data={this.data[0]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                   < Button className={"global_button"} variant="contained"
                                        label={'Change'}
                                        primary={true}
                                        style={styles.right}
                                        disabled={!this.grid.length}
                                        onClick={(event) => {
                                            this.props.actions.resetSelection(this.props.step, this.props.name)
                                        }}
                                        >{window.t.en('Change')}</Button>
                                </Col>
                            </Row>
                        </Container>

                        <br />
                    </div>
                )
                : (<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {/* {this.setQuoteGrid()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} style={styles.confirmSelection}>
                           < Button className={"global_button"} variant="contained"
                                label={'Done'}
                                primary={true}
                                disabled={!this.grid.length}
                                onClick={(event) => {
                                    this.props.actions.confirmSelection(this.props.step, this.props.state.surrogateitems)
                                }}
                                >{window.t.en('Done')}</Button> */}
                        </Col>
                    </Row>
                </Container>
                )
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowTaskInput,
            step: {
                ...state.workflowTaskInput
            },
            workflowQueueTasks: {
                ...state.workflowQueueTasks
            },
            component: {
                ...state.components[ownProps.name]
            },
            quotetype: {
                ...state.components['quotetype']
            },
            customer: {
                ...state.components['customer']
            },
            contracttype: {
                ...state.components['contracttype']
            },
            contractvehiclecategory: {
                ...state.components['contractvehiclecategory']
            },
            mmcode: {
                ...state.components['mmcode']
            },
            contractvehiclecategorymmcodegroup: {
                ...state.components['contractvehiclecategorymmcodegroup']
            },
            surrogateitems: {
                ...state.components['surrogateitems']
            },
            surrogateheader: {
                ...state.components['surrogateheader']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskStepComponentQuoteCreator)