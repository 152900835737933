import * as config from '../config'

import * as actions from './actions'
import * as requestActions from '../components/Request/actions'
import * as routerActions from '../components/Redirect/actions'

import * as types from './types'


export const getAutoCompleteDate = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_CONTROL_VIEW_FORM_AUTOCOMPLETE_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/'+payload.datasource+'/search' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setAutoCompleteDate(result.response,payload),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )



export const getComponentVehicleDate = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_CONTROL_VIEW_VEHICLE_DATA_BY_ID),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search?vehicle_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'statuscategory,customercontract.customercontractderivative,customercontract.contractaccessory,customer.customercontact.contact,maintenancetransaction.maintenancetransactionitem,maintenancetransaction.supplier,mmcode,lastposition,vehiclestatus,financialinstitution,mmcode.vehiclecategory,customercontract.contractstatus,vehicletype,fueltype',

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setComponentVehicleDate(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getWorkflowTasks = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_WORKFLOW_TASK_STEPS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_task/search?timeline=true&workflow_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'order'

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setWorkflowTasks(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow_task/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
export const getComponentSupplierDate = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplier/search?supplier_id=' + payload.id,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'supplierage,suppliertype',

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setComponentSupplierDate(result.response,payload),
                    requestActions.requestSucceeded(config.system.api + '/components/supplier/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplier/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getVehicleMapoServicePlan = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_MAPO_VEHICLE_SERVICE_PLAN),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/serviceplan/search?mmcode=' + payload.mmcode + '&service_info_interval=' + payload.serviceinterval,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setVehicleMapoServicePlan(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/serviceplan/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/serviceplan/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getComponentCustomerDate = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_CONTROL_VIEW_CUSTOMER_DATA_BY_ID),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customer/search?customer_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'customercontact.contact,marketingtype,customeraddress.address.addresstype'
                    //'related': 'customercontract.customercontractderivative,customercontact.contact,maintenancetransaction.maintenancetransactionitem,mmcode,lastposition',

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setComponentCustomerDate(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customer/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customer/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )

export const getComponentCustomerAddress = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_CONTROL_VIEW_CUSTOMER_ADDRESS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customeraddress/search?customer_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'address.addresstype,address.country,address.city'

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setComponentCustomerAddress(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customeraddress/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customeraddress/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
    export const getComponentContractDate = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(
            ofType(types.GET_CONTROL_VIEW_CONTRACT_DATA_BY_ID),
            delay(0)
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/customercontract/search?customercontract_id=' + payload,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                       // 'related':'customercontract.customercontractderivative,customer.customercontact.contact,maintenancetransaction.maintenancetransactionitem,mmcode,lastposition',
    
                    },
                })
                    .pipe(
                    mergeMap((result) => [
                        actions.setComponentContractDate(result.response),
                        requestActions.requestSucceeded(config.system.api + '/components/customercontract/search')
                    ])
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontract/search', error)))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )

    export const getComponentMaintenanceData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(
            ofType(types.GET_CONTROL_VIEW_MAINTENANCE_DATA_BY_ID),
            delay(0)
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/maintenancetransaction/search?maintenancetransaction_id=' + payload.id,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'related': 'maintenancetransactionitem,maintenancedocument.document,vehicle',
    
                    },
                })
                    .pipe(
                    mergeMap((result) => [
                        actions.setComponentMaintenanceData(result.response, payload.taskno),
                        requestActions.requestSucceeded(config.system.api + '/components/maintenancetransaction/search')
                    ])
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenancetransaction/search', error)))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )

    export const getComponentMaintenanceDataByTaskNo = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(
            ofType(types.GET_CONTROL_VIEW_MAINTENANCE_DATA_BY_TASKNO_ID),
            delay(0)
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/maintenancetransaction/search?tasknumber=' + payload,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'related': 'maintenancetransactionitem,maintenancedocument.document,vehicle',
    
                    },
                })
                    .pipe(
                    mergeMap((result) => [
                        actions.setComponentMaintenanceDataByTaskNo(result.response),
                        requestActions.requestSucceeded(config.system.api + '/components/maintenancetransaction/search')
                    ])
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenancetransaction/search', error)))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )

    export const getComponentMultiMaintenanceData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(
            ofType(types.GET_CONTROL_VIEW_MULTI_MAINTENANCE_DATA_BY_ID),
            delay(0)
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/maintenancetransaction/search?tasknumber=' + payload,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                        'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'related': 'maintenancetransactionitem,maintenancedocument.document,vehicle,supplier',
    
                    },
                })
                    .pipe(
                    mergeMap((result) => [
                        actions.setComponentMultiMaintenanceData(result.response),
                        requestActions.requestSucceeded(config.system.api + '/components/maintenancetransaction/search')
                    ])
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/maintenancetransaction/search', error)))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )

        
export const getHierarchyEpic = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.GET_CUSTOMER_HIE),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/oqlis/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.setHierarchy( result.response),
                // progressBarActions.progress(false)
            ]),
            //startWith(progressBarActions.progress(true)),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const updateCustomerAddress = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.UPDATE_CUSTOMER_DELIVERY_ADDRESS),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/customer/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.updateCustomerAddressDone( result.response),
                // progressBarActions.progress(false)
            ]),
            //startWith(progressBarActions.progress(true)),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)
export const getTreeSelectData = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.GET_CONTROL_FORM_TREE_DATA),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + "/components/team/other",
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.setTreeSelectData( result.response, payload),
                // progressBarActions.progress(false)
            ]),
            //startWith(progressBarActions.progress(true)),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getAuditData = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.GET_CONTROL_VIEW_AUDIT_DATA),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + `/components/component/other`,
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.setAuditData(result.response)
            ]),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)
  export const getSupplierAccessoryHistoryData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
                action$.pipe(
                        ofType(types.GET_SUPPLIER_ACCESSORY_DATA),
                        delay(0)
                        ,mergeMap(({ payload }) =>
                            ajax$({
                                url: config.system.api + '/components/supplieraccessorypricing/other',
                                method: 'POST',
                                headers: {
                                    'tokenx': store.value.user.user_id,
                                    'auth': store.value.cognito.authenticateUser.token,
                                    'salt': store.value.cognito.authenticateUser.jwt,
                                    'datetime': store.value.cognito.authenticateUser.datetime,
                                    'Content-Type': 'application/json',
                                },
                                body: payload
                            })
                            .pipe(
                                mergeMap((result) => [
                                    actions.setSupplierAccessoryHistoryData(result.response, payload),
                                    progressBarActions.progress(false),
                                    requestActions.requestSucceeded(config.system.api + '/components/supplieraccessorypricing/search')
                                ])
                                //,startWith(progressBarActions.progress(true))
                                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplieraccessorypricing/search', error)))
                                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                        )
                        )
                        ,catchError((error) =>
                            of$(routerActions.route('/support', false, error))
                        )
                        )
                        export const getComponentQuoteData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
                        action$.pipe(
                            ofType(types.GET_CONTROL_VIEW_QUOTE_DATA_BY_ID),
                            delay(0)
                            ,mergeMap(({ payload }) =>
                                ajax$({
                                    url: config.system.api + '/components/customerquotationnotes/search?tasknumber=' + payload,
                                    method: 'GET',
                                    headers: {
                                        'tokenx': store.value.user.user_id,
                                    'auth': store.value.cognito.authenticateUser.token,
                                        'salt': store.value.cognito.authenticateUser.jwt,
                                        'datetime': store.value.cognito.authenticateUser.datetime,
                                        'related': 'user',
                    
                                    },
                                })
                                    .pipe(
                                    mergeMap((result) => 
                                        [
                                        actions.setComponentQuoteData(result.response),
                                        requestActions.requestSucceeded(config.system.api + '/components/customerquotationnotes/search')
                                    ])
                                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerquotationnotes/search', error)))
                            )
                            )
                            ,catchError((error) =>
                                of$(routerActions.route('/support', false, error))
                            )
                        )
