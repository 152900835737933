
import * as config from './config'
//console.log('config.system.clientTheme', config.system.clientTheme)

let scssName = '' // import(`./assets/css/${config.system.clientTheme}sa.scss`)
switch (config.system.clientTheme) {
    case 'bidvest':
        scssName = import(`./assets/css/bidvestsa.scss`)
        break;
    case 'infleet':
        scssName = import('./assets/css/innovsa.scss')
        break;
    case 'visa':
        scssName = import('./assets/css/innovsa.scss')
        break;
}
export default scssName
