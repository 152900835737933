export const purchaseOrderTypes = [
    {
        value: 1,
        text: 'Assessor Fee',
        key: 'assessor',
        types: []
    },
    {
        value: 5,
        text: 'Panel Beaters',
        key: 'panel_beatears',
        types: []
    },
    {
        value: 6,
        text: 'Unscheduled Maintenance',
        key: 'repairs',
        types: []
    },
    {
        value: 7,
        text: 'Scheduled Maintenance',
        key: 'service',
        types: []
    },
    {
        value: 7,
        text: 'Service',
        key: 'service',
        types: []
    },
    {
        value: 10,
        text: 'Road Side',
        key: 'towing',
        types: []
    },
    {
        value: 11,
        text: 'Tyres',
        key: 'tyres',
        types: []
    }
]

export const customercontacts = []
export const suppliercontacts = []
export const maintenanceclass = []
export const suppliertypes = []
export const selectedcustomercontacts = null
export const selectedsuppliercontacts = null

export const selectedPurchaseOrderType = {
        errorText: 'Select purchase order type',
        data: null,
        valid: false,
        empty: true,
        selectedSupplierSuspended: ''
    }

export const fields = {
    duplicate: {
        errorText: 'Pontential duplicate detected',
        data: null,
        valid: true,
        empty: true,
    },
    selectedPurchaseOrderType: {
        errorText: 'Please select Maintenance Type',
        state: "getState().poHeader.fields.selectedPurchaseOrderType?.data?.text",
        data: null,
        valid: false,
        empty: true,
    },
    maintenanceclass: {
        state: "(getState().poHeader.fields.selectedPurchaseOrderType?.data?.text !== 'Unscheduled Maintenance' && getState().poHeader.fields.selectedPurchaseOrderType?.data?.text !== 'Scheduled Maintenance') ? 'true' : getState().poHeader.fields.maintenanceclass.data?.text",
        errorText: 'Select maintenance class',
        data: null,
        valid: true,
        empty: true,
    },
    selectedVehicle: {
        state: "getState().poHeader.fields.selectedVehicle?.data?.text",
        errorText: 'Please select vehicle',
        data: null,
        valid: true,
        empty: true,
    },
    selectedUser: {
        errorText: 'Copy selection field required',
        data: null,
        valid: true,
        empty: true,
    },
    selectedSupplier: {
        state: "getState().poHeader.fields.selectedSupplier?.data?.text",
        errorText: 'Please select supplier from the list',
        data: null,
        valid: false,
        empty: true,
    },
    // selectedVehicle: {
    //     errorText: 'Please select supplier from the list',
    //     data: null,
    //     valid: false,
    //     empty: true,
    // },
    supplierStatus: {
        state: 'getState().poHeader.fields.selectedSupplier?.text ? getState().poHeader.suspended : true',
        errorText: 'Selected supplier is suspended',
        data: null,
        valid: false,
        empty: true,
    },
    supplier_over_limit: {
        errorText: 'Selected supplier have exceeded its monthly spent',
        state: 'getState().controls.supplier_in_limit',
        data: false,
        valid: false,
        empty: true,
        popup: true,
    }
}

export const upcoming = {
    upcoming: false,
    tax: false,
    bee: false,
    sal: false,
}
export const vehicles = []
export const supplier = null
export const suppliers = []
export const users = []
export const duplicate_data = []

export const preauth = null
export const ticketnumber = null
export const suspended = false
export const suspended_win = false
export const selectmaintenance_win = false
export const duplicatemaintenance_win = false

export const validation = {
    fields: ['selectedVehicleDetails','selectedSupplierDetails','selectedSupplierSuspended','overthelimit'],
    component: 'poHeader',
    list: [],
    valid: false
}
