export const signedIn = false
export const mobile = false
export const locale = 'ar'
export const agent = 'Unknown'
export const loading = true
export const countdown = 60
export const dialog = false
export const notifications = []
export const action = 'Initializing...'
export const sessionexpired = false
export const loggedTwice = false
export const useMsal  = false

