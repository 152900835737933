import * as types from './types'

import * as config from '../../config'


export const getJSReportContent = (key, data, id, name) => {
    
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_JSREPORT_PREVIEW_DOCUMENT,
            payload: {
                type: 'GET_JSREPORT_PREVIEW_DOCUMENT',
                data: {
                    data: data,
                    key: key,
                    id: id,
                    name: name
                }
            } 
        })
        //dispatch(setJSReportContent(null,null,id))        
        // const options = {
        //     uri: 'https://repor t-uatfa lms.bidve stbank.co.za/api/report',
        //     headers: {
        //        'Authorization': 'Bearer dasdasddw23fsdfsdfr56hvFVdf3'
        //     },
        //     method: 'POST',
        //     json: {
        //         template: name ?  { name: name } :  { shortid: key } ,
        //         data: data,
        //         options: {
        //             preview: true,
        //             "reports": { "save": true },
        //             'Content-Disposition': "attachment; filename=myreport.pdf"
        //         }
        //     }
        // }
        // let mimeType = "";
        // let _res = request(options)
        // .on('response', function (response) {
        //     mimeType = response.headers['content-type'];
        // })
        // .pipe(bl(async (error, buffer) => {
        //     const blob = new Blob([new Uint8Array(buffer)], { type: mimeType })
        //     const url = window.URL.createObjectURL(blob)
        //     dispatch(setJSReportContent(blob,url,id))
        // }));
    }
}

export const viewPdfDocument = (data) => {
    return (dispatch, getState) => {
        //console.log('viewPdfDocumentx', data)
        let mimeType = "application/pdf";
        const blob = new Blob([new Uint8Array(data.data.buffer)], { type: mimeType })
        const url = window.URL.createObjectURL(blob)
        dispatch(setJSReportContent(blob,url,data.data.data.id))
    }
}

export const setJSReportContent = (buffer,url,id) => {
    return (dispatch, getState) => {
        //console.log('setJSReportContentx', url,id)
        dispatch({
            type: types.SET_JSREPORT_BUFFER_STREAM_OBJECT,
            payload: {
                [id]: {
                    url: url,
                    buffer: buffer
                }
            } 
        })
    }
}

export const getFilters = (id) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.GET_JSREPORT_FILTER,
            payload: {
                report_id: id,
                type: 'GET_JSREPORT_FILTER'
            } 
        })

        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter: id,
                prop: 'report_id'
            } 
        })

        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter: getState()?.navigationMenu?.oqlisMenuReport?.report_?.report_view,
                prop: 'report_view'
            } 
        })
    }
}

export const setFilters = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter: data.data.length > 0? data.data: null,
                prop: 'filter'
            } 

            
        })
        dispatch(bindFilters(data.data))
    }
}

export const bindFilters = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter: data? data.reduce((obj, item) => (obj[item.field_name] = '', obj) ,{}) : null,
                prop: 'searchFilter'
            } 
        })
    }
}


export const editFilters = (e, date, name) => {
    return (dispatch, getState) => {
        let searchFilter = getState().jsReport.searchFilter
        searchFilter[e?e.target.name:name ] = e?e.target.value: date
        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter:  Object.entries(searchFilter).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {}),
                prop: 'searchFilter'
            } 
        })
    }
}

export const editDateFilter = (e, date, name, field) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter:  date,
                prop: name
            }
            
        })
        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter:  true,
                prop: 'dateField'
            }
            
        })

        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter:  field,
                prop: 'dateColumn'
            }
            
        })
    }
}

export const clearDateFilter = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter:  null,
                prop: 'dateFrom'
            }
        })
        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter:  null,
                prop: 'dateTo'
            }
        })
        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter:  false,
                prop: 'dateField'
            }
            
        })

        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter:  null,
                prop: 'dateColumn'
            }
            
        })
    }
}
            
export const submitFilter = (e) => {
    e.preventDefault()
    return (dispatch, getState) => {
        dispatch(resetReportData())
        let reportDetails = getState().jsReport
        let searchFilter = getState().jsReport.searchFilter
        const {dateField, dateTo, dateFrom, dateColumn} = getState().jsReport

        let obj
        if (dateField) {
            obj = {
                dateTo: dateTo,
                dateFrom: dateFrom,
            }

            searchFilter[dateColumn] = obj
        }

        reportDetails.searchFilter = Object.entries(searchFilter).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})

        dispatch({
            type: types.GET_JSREPORT_FILTER_DATA,
            payload: {
                reportDetails: reportDetails,
                type: 'GET_JSREPORT_FILTER_DATA'
            } 
        })
    }
}

export const setFilterData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_JSREPORT_FILTER_DATA,
            payload: {
                data: data.data,
                prop: 'filteredData'
            } 
        })
    }
}

export const getJSReportData = (report_view) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_JSREPORT_FILTER,
            payload: {
                filter: null,
                prop: 'searchFilter'
            } 
        })

        dispatch({
            type: types.SET_JSREPORT_DATA,
            payload: {
                report_data: null
            }
        })

        dispatch({
            type: types.GET_JSREPORT_DATA,
            payload: {
                type: 'GET_JSREPORT_DATA',
                payload: {
                    report_view: report_view.report_view ? report_view.report_view : report_view,
                    customers: getState().user.customers
                }
            }
        })
    }

}

export const setJSReportData  = (report_data) => {

    if(report_data.error) {
        //console.log('>>>>>>>>>>>>> JSError >>>>>>>>>>>>>>>>>>> ', report_data)
    }
    return {
        type: types.SET_JSREPORT_DATA,
        payload: {
            report_data: report_data.data
        }
    }
}

export const resetReportData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_JSREPORT_DATA,
            payload: {
                report_data: null
            }
        })
    }
}
