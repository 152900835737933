import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'


export const getContractAccountListData = (search) => {
    return {
        type: types.GET_CONTRA_ACCOUNT_LIST,
        payload: {
            search_text: search
        }
    }
}
export const setContraAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id,            
            account: x.accountnumber,
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_DATA_LIST,
            payload: {
                data: data,
                component: 'generalledgercashbook',
                field: 'accountingitem_id'
            }
        })
    }
}
export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field,
            component:'generalledgercashbook'
        }
    }
}

export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: value,
                component:'generalledgercashbook'
            }
        })
    }
}

export const saveData = (payload, validatefields,items) => {
    var valid = true
    var text = ''
    for (var i = 0; i < validatefields.length; i++) {
        if (!validatefields[i].value) { valid = false; text += validatefields[i].field + " " }
    }
    for (var item = 0; item < items.length; item++) {
        if (!items[item].account_id) { valid = false; text =  " Check Line Items " }
    }
    
    if (!valid) {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'You missed a couple of fields [' + text + ']...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return {
            type: types.SAVE_GL_CASH_BOOK_DATA,
            payload: {
                data: payload
            }
        }
    }
}
export const setItemValue = (payload) => {
    return (dispatch, getState) => {
        let data = getState().components.generalledgercashbook.data.generalledgercashbook_id.data
        data[payload.inx][payload.field] = payload.value
        
        dispatch({
            type: types.SET_ANY_ITEM_INPUT_VALUE,
            payload: {
                data: data,
                component: 'generalledgercashbook',
                field: 'generalledgercashbook_id'
            }
        })
    }
}
export const addBlankRow = () => {
    return (dispatch, getState) => {
        let data = getState().components.generalledgercashbook.data.generalledgercashbook_id.data
        const payload = {
            data: data.concat({
                account_id: '',
                account_text: '',
                account: '',
                date: '',
                reference: '',
                description: '',
                debit: 0,
                credit: 0,                
                key: data.length + 1,
            }),
            component: 'generalledgercashbook',
            field: 'generalledgercashbook_id'
        }
        dispatch({
            type: types.ADD_ANY_BLANK_ROW,
            payload: payload
        })
    }
}
export const addRow = (account_id,account,account_text,date,reference,description,debit,credit) => {
    return (dispatch, getState) => {
        let data = getState().components.generalledgercashbook.data.generalledgercashbook_id.data
        const payload = {
            data: data.concat({
                account_id: account_id,
                account_text: account_text,
                account: account,
                date: date,
                reference: reference,
                description:description,
                debit: debit,
                credit: credit,                
                key: data.length + 1,
            }),
            component: 'generalledgercashbook',
            field: 'generalledgercashbook_id'
        }
        dispatch({
            type: types.ADD_ANY_BLANK_ROW,
            payload: payload
        })
    }
}
export const setItems = (data) => {
    return {
        type: types.SET_ANY_INVOICE_ITEMS, 
        payload: {
            data: data,
            component: 'generalledgercashbook',
            field: 'generalledgercashbook_id'
        }
    }
}
export const setTransactionTypeList = (payload) => {
    let data = [{
        text: 'Cash Book',
        value: 'C'
    },{
        text: 'Journal',
        value: 'J'
    }]
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'generalledgercashbook',
            field: 'transactiontype'

        }
    }
}

export const removeRow = (inx) => {
    return (dispatch, getState) => {
        let data = getState().components.generalledgercashbook.data.generalledgercashbook_id.data.filter((x, i) => i !== (inx))

        dispatch({
            type: types.REMOVE_ANY_INVOICE_ITEM_ROW,
            payload: {
                data: data,
                component: 'generalledgercashbook',
                field: 'generalledgercashbook_id'
            }
        })
    }
}

export const getAllLinkNumbers = (search) => {
    return {
        type: types.GET_LINK_NUMBER_LIST,
        payload: {
            search_text: search
        }
    }
}


export const setLinkNumberList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.linknumber,
            value: x.khulafid,
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'generalledgercashbook',
            field: 'entrydescription'
        }
    }
}

export const getAllLinkNumberTransactions = (id) => {
    return {
        type: types.GET_LINK_NUMBER_TRANS,
        payload: {
            id: id
        }
    }
}

export const savedData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_GL_POSTING_DATA
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'General Ledger Posting Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setlinkNumberTrans = (payload) => {
    window.glyco.log("payload")
    window.glyco.log(payload)
    let data = []
    data = payload.transactions.map(x => {
        return {
            description: x.entrydescription,
            reference: x.referencecode,
            date: moment(x.transactiondate).format('YYYY/MM/DD'),
            debit: x.debit,
            credit: x.credit,
            transactiontype: x.transactiontype == 'J' ? 'Journal' : 'Cash Book',
            generalledgermonth: x.generalledgermonth,
            account_id: x.accountingitem_id,
            key:x.sequence,
            account_text: this.getAccountingItemText(payload.accountingitems,x.accountingitem_id).itemdescription,
            account: this.getAccountingItemText(payload.accountingitems,x.accountingitem_id).accountnumber
        }
    })


    return (dispatch, getState) => {
       dispatch({
            type: types.SET_GL_POSTING_DATA,
            payload: {
                data: data,
                component: 'generalledgercashbook',
                field: 'generalledgercashbook_id',
                type: data[0].transactiontype,
                date: data[0].date,
                month: data[0].generalledgermonth
            }
        })
    }
}


export const getAccountingItemText = (items, id) => {
    for (var i = 0; i <= items.length; i++)
    {
        window.glyco.log(i)
        if(items[i].accountingitem_id === id) return items[i]
    }
}