import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_XERO_CONSENT_URL:
            return {
                ...state,
                consentUrl: action.payload,
            }
        case types.SET_XERO_CONTACTS_LIST:
            return {
                ...state,
                contacts: action.payload,
            }
        case types.SET_XERO_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload,
            }
        default:
            return state
    }
}