export const api_live = false

export const stage = ''

export const session = {}

export const userPool = {}

export const userData = {}

export const cognitoUser = {}

export const signUp = {
    attributes: {},
    error: {},
    fields: {},
    result: {},
    userData: {},
    cognitoUser: {}
}

export const confirmRegistration = {
    error: {},
    fields: {},
    result: {},
    userData: {}
}

export const resendConfirmationCode = {
    error: {},
    result: {},
    resendsLeft: 2,
    sending: false
}

export const userAttributes = {}

export const authenticationDetails = {}

export const authenticateUser = {
    error: {}
}