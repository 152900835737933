import * as cognitoTypes from "../Cognito/types";
import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//Styling
import Button from "@mui/material/Button";
import { Tooltip, Zoom, Card, Divider, Grid, Table } from "@mui/material";
import Container from "@mui/material/Container";
import Collapse from '@mui/material/Collapse';
import {Row, Col } from 'react-grid-system'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from "styled-components"

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';




import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import * as actions from "./actions";

class ManageWorkFlowTask extends Component {
   
    componentDidMount() {
        this.props.actions.getWorkFlowTasks()
        // this.props.actions.updateWorkflowTaskUser()
    }

    constructor(props) {
        super(props);
        this.state = {
            collapse: -1,
            open: false,
            user: [],
            multiTaskUser: [],
            assignOption: '',
            doa: false,
            selected: [],
            userSelected: false,
            multiUserSelected: false,
            filteredUser: null,
            taskSelected: [],
            selectAll: false,
            workflow_task_to_on_condition_value: 0
          };
    }

    handleToggleUser = (event, selectedOption, reason) => {
        if (reason === "select-option" || reason === "selectOption") {
            this.setState({
                user: selectedOption,
                userSelected: true
            })
        } else if (reason === "clear") {
            this.setState({
                user: [],
                userSelected: false
            })
        }
    }

    updateDOAValue = (event, selectedOption, reason) => {
        let workflow_task_to_on_condition_value = event.target.value
        //console.log('workflow_task_to_on_condition_value', workflow_task_to_on_condition_value)
        this.setState({
            workflow_task_to_on_condition_value: workflow_task_to_on_condition_value
        })
    }

    handleToggleMultitaskUser = (event, selectedOption, reason) => {
        if (reason === "select-option") {
            this.setState({
                multiTaskUser: selectedOption,
                multiUserSelected: true
            })
        } else if (reason === "clear") {
            this.setState({
                multiTaskUser: [],
                multiUserSelected: false
            })
        }
    }

    handleClickOpen = (assignOption, selected) => {
        this.setState({
            open: true,
            assignOption: assignOption,
            selected: selected
        })
        //console.log("+", selected)
    };
    
    handleClose = (taskID, userID, doa, workflow_task_to_on_condition_value) => {

        if (taskID == null) {
            this.setState({
                open: false,
                selected: [], 
                user: [],
            })
        }
        else if (Array.isArray(taskID)) {
            taskID.forEach(ID => {
                this.props.actions.updateWorkflowTaskUser(ID, userID)
            })
            this.setState({
                open: false,
                user: [],
                userSelected: false,
                selected: [],
                collapse: -1,
                taskSelected: [],
                selectAll: false
            })
        }
        else {
            this.setState({
                open: false,
                user: [],
                userSelected: false,
                selected: [],
                collapse: -1
            })
            this.props.actions.updateWorkflowTaskUser(taskID, userID, doa, workflow_task_to_on_condition_value)
        }
    };

    handleDOA = () => {
        this.setState({
            doa: !this.state.doa
        })
    }

    handleToggleUserFilter = (event, selectedOption, reason) => {
        if (reason === "select-option" || reason === "selectOption") {
            this.setState({
                filteredUser: selectedOption
            })
        } else if (reason === "clear") {
            this.setState({
                filteredUser: null
            })
        }
    } 

    filterByName = (searchArray, searchValue) => {
        if (searchValue == null) {
            return true
        }
        else {
            if (searchArray == searchValue) {
                return true
            }
            else {
                return false
            } 
        }
    }

    handleCheckbox = (option, ind, totalTasks) => {
        //console.log("+", option)
        //console.log("+", ind)
        //console.log("+", totalTasks)
        let allTaskID = []
        totalTasks.forEach(element => {
            allTaskID.push(element.workflow_task_id)
        });
        
        //console.log("+", allTaskID)
        if (option == "Select-All") {
            if (this.state.taskSelected.length == 0) {
                this.setState({
                    taskSelected: allTaskID,
                    selectAll: true
                });
            }
            else {
                //console.log("+", this.state.taskSelected)
                
                if (this.state.taskSelected.length == totalTasks.length) {
                    this.setState({
                        taskSelected: [],
                        selectAll: false
                    });
                } 
                else {
                    this.setState({
                        taskSelected: allTaskID,
                        selectAll: true
                    });
                }
            }
        }
        else if (option == "Select-One") {
            if ((this.state.taskSelected).includes(totalTasks[ind].workflow_task_id)) {
                // Remove from selected items if in the array
                let filteredArray = this.state.taskSelected.filter(item => item !== totalTasks[ind].workflow_task_id)
                this.setState({
                    taskSelected: filteredArray,
                    selectAll: false
                });
            }
            else {
                // Else add to the array
                if ((this.state.taskSelected.length + 1) == totalTasks.length) {
                    this.setState({
                        taskSelected: [...this.state.taskSelected, totalTasks[ind].workflow_task_id],
                        selectAll: true
                    });
                }
                else {
                    this.setState({
                        taskSelected: [...this.state.taskSelected, totalTasks[ind].workflow_task_id]
                    });
                }
            }
        }
        
    }

    getTable() {
        const { workflows, users } = this.props.state
        let data = users;
        const userData = Object.keys(data).map((key) => {
            return data[key];
        });
        const StyledDialog = styled(Dialog)`
            .MuiBackdrop-root {
                background-color: 25305382;
               display: none;
            }
            .MuiDialog-container .MuiPaper-root {
                box-shadow: none;
                border: 5px solid #253053;

            }
        `;
        
        let SearchValue = this.state.filteredUser ? (this.state.filteredUser).firstname + ' ' + (this.state.filteredUser).lastname : null
        let totalTasks = []
        

        return <div className="custom-task-assign">
                <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
                    <div style={{padding: "5px 0", flexDirection: "row", justifyContent: "flex-end"}}>
                        <Autocomplete
                            id="user-select"
                            style={{ width: "400px" }}
                            options={userData}
                            getOptionLabel={(option) => `${option.firstname + " " + option.lastname}`}
                            onChange={this.handleToggleUserFilter}
                            renderOption={(props, option) => (
                                    <li {...props}>
                                        {`${option.firstname + " " + option.lastname}`}
                                    </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label={window.t.en('Filter Task by User')}
                                variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="divider" />
        
                <div className="divider" />
                <div className="pt-4 px-4">
                    {/* <div className="d-flex align-items-center justify-content-between" style={{padding: "0 97px 0 12px",display: "none"}}>
                        <div >
                            <Checkbox
                                color="primary"
                                disabled={SearchValue == null ? true : false}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                onChange={() => {
                                    this.handleCheckbox("Select-All", -1, totalTasks)
                                }}
                                checked={this.state.selectAll}
                            />
                            <span>Select All</span>
                        </div>
                        <div style={{padding: "5px 0", flexDirection: "row", justifyContent: "flex-end", width: "550px"}}>
                            <Grid>
                                <Row>
                                    <Col xl={9}>
                                        <AutoComplete
                                            id="user-select"
                                            style={{ width: "400px" }}
                                            options={userData}
                                            getOptionLabel={(option) => `${option.firstname + " " + option.lastname}`}
                                            onChange={this.handleToggleMultitaskUser}
                                            renderOption={(option) => (
                                                    <>
                                                        {`${option.firstname + " " + option.lastname}`}
                                                    </>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                label={window.t.en('Please select user')}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col xl={3} style={{margin: "auto"}}>
                                        <Tooltip TransitionComponent={Zoom} title="Replace user assigned to the tasks filtered">
                                            <Button 
                                                className="btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                                disabled={this.state.taskSelected.length == 0? true : false}
                                                onClick={() => {
                                                    this.handleClickOpen("assign", this.state.taskSelected)
                                                }}
                                                
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    className="font-size-sm"
                                                />
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    </div>  */}
                    
                    <Table className="table table-alternate-spaced mb-0" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            {/* <th className="text-left px-4"></th> */}
                            <th className="text-left "></th>
                            <th className="text-left"></th>
                            <th className="text-left"></th>
                            <th className="text-right"></th>
                            <th className="text-right"></th>
                            <th className="text-right"></th>
                            <th className="text-center"></th>
                        </thead>
                        <tbody style={{zIndex:1}}>
                            {workflows.data 
                                ?   workflows.data
                                        .filter(x => this.filterByName(x['user#fname'] + " " + x['user#lname'], SearchValue))
                                        .map((x, ind) =>  {
                                        totalTasks.push(x)
                                        return <>
                                            <tr key={ind}>
                                                {/* {userData.forEach((value) => {
                                                    if (`${value.user_id}` == x.assign_task_to_user_id) {
                                                        user = value
                                                    }
                                                })} 
                                                <td >
                                                    <Checkbox
                                                        color="primary"
                                                        disabled={SearchValue == null ? true : false}
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        onChange={() => {
                                                            this.handleCheckbox("Select-One", ind, totalTasks)
                                                        }}
                                                        checked={(this.state.taskSelected).includes(totalTasks[ind].workflow_task_id) ? true : false}
                                                    />
                                                </td>*/}
                                                <td className="px-4">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <div className="font-size-sm opacity-7">
                                                                {`${x['workflow#title']}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-left">
                                                    <div>
                                                        <div className="font-size-sm opacity-7">
                                                            <b>{`${x['workflowTask#title']}`} {x['workflow_task_to_on_condition_value'] ? ' *' : ''}</b>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-left">
                                                    <div>
                                                        {/* <div className="font-size-sm font-weight-bold">
                                                        {`${x['workflowTask#name']}`}
                                                        </div>
                                                        <div className="font-size-sm text-warning opacity-7">
                                                        </div> */}
                                                    </div>
                                                </td>
                                                <td className="text-right"> 
                                                    {
                                                        x['user#fname']
                                                            ? <div className="font-size-sm opacity-7">
                                                                {x['user#fname'] + " " + x['user#lname']}
                                                            </div>
                                                            : <div className="font-size-sm text-warning opacity-7">
                                                                {'System Controlled'}
                                                            </div>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {/* <Tooltip TransitionComponent={Zoom} title="View Task">
                                                        <Button 
                                                            className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                                        >
                                                            <FontAwesomeIcon
                                                                className="font-size-sm"
                                                            />
                                                        </Button>
                                                    </Tooltip> */}
                                                    <Tooltip TransitionComponent={Zoom} title="Assign Task">
                                                        <Button 
                                                            className="btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                                            disabled={this.state.taskSelected.length > 0 ? true : false}
                                                            onClick={() => {
                                                                if (this.state.collapse == -1) {
                                                                    this.setState({
                                                                        collapse: ind,
                                                                        workflow_task_to_on_condition_value: x['workflow_task_to_on_condition_value'] 
                                                                    })
                                                                }
                                                                else {
                                                                    this.setState({
                                                                        collapse: -1,
                                                                        user: [],
                                                                        userSelected: false,
                                                                        selected: []
                                                                    })
                                                                }
                                                            }}
                                                            
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faArrowRight}
                                                                className="font-size-sm"
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </td>
                                            </tr>

                                            {/* The task collapse to edit user responsible for the task (assignment) */}
                                            { this.state.collapse == ind
                                                ?   <tr style={{ paddingBottom: 0, paddingTop: 0 }} >
                                                        <td colspan="6">
                                                            <Collapse 
                                                                in={this.state.collapse === ind} 
                                                                timeout="auto" 
                                                                unmountOnExit
                                                            >
                                                                <Container style={{ margin: 0 }} className="custom-container">
                                                                    <Row>
                                                                        <h5 style={{padding: "2px 0"}}><b>&nbsp;&nbsp;&nbsp;Manage Task</b></h5>
                                                                    </Row>
                                                                    <hr/>
                                                                    <Row>
                                                                        <Col xl={2} style={{paddingTop: "25px", paddingLeft: "16px"}}>
                                                                            <b>Assign User:</b>
                                                                        </Col>
                                                                        <Col xl={10}>
                                                                            <div style={{padding: "5px 0"}}>
                                                                                <Autocomplete
                                                                                    id="user-select"
                                                                                    style={{ width: "400px" }}
                                                                                    options={userData}
                                                                                    getOptionLabel={(option) => `${option.firstname + " " + option.lastname}`}
                                                                                    onChange={this.handleToggleUser}
                                                                                    renderOption={(props,option) => (
                                                                                            <li {...props}>
                                                                                                {`${option.firstname + " " + option.lastname}`}
                                                                                            </li>
                                                                                    )}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                        {...params}
                                                                                        label={window.t.en('Please select user')}
                                                                                        variant="outlined"
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    {
                                                                    this.state.workflow_task_to_on_condition_value && <Row>
                                                                        <Col xl={2} style={{paddingTop: "25px", paddingLeft: "16px"}}>
                                                                            <b>DOA Amount:</b>
                                                                        </Col>
                                                                        <Col xl={10}>
                                                                            <div style={{padding: "5px 0"}}>
                                                                                <TextField
                                                                                    id="user-select"
                                                                                    style={{ width: "900px" }}
                                                                                    value={this.state.workflow_task_to_on_condition_value}
                                                                                    onChange={this.updateDOAValue}
                                                                                    variant="outlined"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    }
                                                                    <hr style={{ padding: "3px 0"}}/>
                                                                    <Row style={{ flexDirection: "row", justifyContent: "space-between"}}>
                                                                        <Col xl={9}>
                                                                            {x['user#fname'] !== null
                                                                                ?   <div>
                                                                                        <Checkbox
                                                                                            color="primary"
                                                                                            disabled={this.state.userSelected == true ? false : true}
                                                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                                            onChange={this.handleDOA}
                                                                                            checked={this.state.doa}
                                                                                        />
                                                                                        <span>Move all open tasks to new reassign user</span>
                                                                                    </div>
                                                                                    
                                                                                :''
                                                                            }
                                                                        </Col>
                                                                        <Col xl={3}>
                                                                            <Tooltip TransitionComponent={Zoom} title="Assign task to the selected user">
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        this.handleClickOpen("assign", x)
                                                                                    }}
                                                                                >
                                                                                    Assign User
                                                                                </Button>
                                                                            </Tooltip>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <Tooltip TransitionComponent={Zoom} title="Remove the user assigned to the task">
                                                                                <Button
                                                                                    disabled={(this.state.selected)['user#fname'] === null ? true : false}
                                                                                    onClick={() => {
                                                                                        this.handleClickOpen("remove", x)
                                                                                    }}
                                                                                >
                                                                                        Remove User
                                                                                    </Button>
                                                                            </Tooltip>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                                
                                                            </Collapse>
                                                        </td>
                                                    </tr>
                                                :   ''
                                            }
                                            { this.state.open == true
                                                ?   //  Open dialog when true
                                                    this.state.assignOption === "assign" 
                                                        ?   //  Check whether the "User Assign" or "Team Assign" button clicked
                                                            this.state.userSelected === true 
                                                                ?   //  Notify the admin to select a user prior to trying to assign a task to a user
                                                                    (this.state.user).user_id == (this.state.selected).user_id
                                                                        ?   //  user already selected
                                                                            <div>
                                                                                <StyledDialog
                                                                                    open={this.state.open}
                                                                                    onClose={this.handleClose}
                                                                                    hideBackdrop={true}
                                                                                    BackdropProps={{style: {display: "none"}}}
                                                                                    fullWidth={true}
                                                                                    maxWidth={'md'} 
                                                                                    aria-labelledby="alert-dialog-title"
                                                                                    aria-describedby="alert-dialog-description"
                                                                                    
                                                                                >
                                                                                    <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                                                                                    <DialogContent>
                                                                                        <DialogContentText id="alert-dialog-description">
                                                                                            <b>{`${(this.state.user).firstname + " " + (this.state.user).lastname + " "}`}</b> is already assigned to the task
                                                                                        </DialogContentText>
                                                                                    </DialogContent>
                                                                                    <DialogActions>
                                                                                        <Button 
                                                                                            onClick={this.handleClose}
                                                                                        >
                                                                                            OK
                                                                                        </Button>
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    </DialogActions>
                                                                                </StyledDialog>
                                                                            </div>
                                                                        :   //  Ask admin confirmation to assign task to the selected user
                                                                            <div>
                                                                                <StyledDialog
                                                                                    open={this.state.open}
                                                                                    onClose={this.handleClose}
                                                                                    hideBackdrop={true}
                                                                                    BackdropProps={{style: {display: "none"}}}
                                                                                    fullWidth={true}
                                                                                    maxWidth={'md'} 
                                                                                    aria-labelledby="alert-dialog-title"
                                                                                    aria-describedby="alert-dialog-description"
                                                                                >
                                                                                    <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
                                                                                    <DialogContent>
                                                                                        <DialogContentText id="alert-dialog-description">
                                                                                            Are you sure you want to assign <b>{`${(this.state.user).firstname + " " + (this.state.user).lastname}`}</b> to the following task - <b>{`${(this.state.selected)['workflowTask#description']}`}</b>?
                                                                                        </DialogContentText>
                                                                                    </DialogContent>
                                                                                    <DialogActions style={{paddingBottom: "3px"}}>
                                                                                        <Button 
                                                                                            onClick={this.handleClose}
                                                                                        >
                                                                                            Disagree
                                                                                        </Button>
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                        <Button 
                                                                                            onClick={() => {
                                                                                                //console.log('Agree DOA',(this.state.selected).workflow_task_id, (this.state.user).user_id,this.state.doa)
                                                                                                this.handleClose((this.state.selected).workflow_task_id, (this.state.user).user_id,this.state.doa,this.state.workflow_task_to_on_condition_value)
                                                                                            }}
                                                                                            autoFocus
                                                                                        >
                                                                                            Agree
                                                                                        </Button>
                                                                                    </DialogActions>
                                                                                </StyledDialog>
                                                                            </div>
                                                                :   // Multitask select or No user selected
                                                                    this.state.taskSelected.length > 0 && this.state.multiUserSelected === true
                                                                        ?   //  multiple task select
                                                                            <div>
                                                                                <StyledDialog
                                                                                    open={this.state.open}
                                                                                    onClose={this.handleClose}
                                                                                    hideBackdrop={true}
                                                                                    BackdropProps={{style: {display: "none"}}}
                                                                                    fullWidth={true}
                                                                                    maxWidth={'md'} 
                                                                                    aria-labelledby="alert-dialog-title"
                                                                                    aria-describedby="alert-dialog-description"
                                                                                >
                                                                                    <DialogTitle id="alert-dialog-title">{"Assign user to task?"}</DialogTitle>
                                                                                    <DialogContent>
                                                                                        <DialogContentText id="alert-dialog-description">
                                                                                            Would you like to assign <b>{`${(this.state.multiTaskUser).firstname + " " + (this.state.multiTaskUser).lastname}`}</b> to the selected tasks?
                                                                                        </DialogContentText>
                                                                                    </DialogContent>
                                                                                    <DialogActions style={{paddingBottom: "3px"}}>
                                                                                        <Button 
                                                                                            onClick={this.handleClose}
                                                                                        >
                                                                                            Disagree
                                                                                        </Button>
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                        <Button 
                                                                                            onClick={() => {
                                                                                                this.handleClose(this.state.selected, (this.state.multiTaskUser).user_id, this.state.doa)
                                                                                            }}
                                                                                            autoFocus
                                                                                        >
                                                                                            Agree
                                                                                        </Button>
                                                                                    </DialogActions>
                                                                                </StyledDialog>
                                                                            </div>
                                                                        :   //  no user selected
                                                                            <div>
                                                                                <StyledDialog
                                                                                    open={this.state.open}
                                                                                    onClose={this.handleClose}
                                                                                    hideBackdrop={true}
                                                                                    BackdropProps={{style: {display: "none"}}}
                                                                                    fullWidth={true}
                                                                                    maxWidth={'md'} 
                                                                                    aria-labelledby="alert-dialog-title"
                                                                                    aria-describedby="alert-dialog-description"
                                                                                    
                                                                                >
                                                                                    <DialogTitle id="alert-dialog-title">{"WARNING!"}</DialogTitle>
                                                                                    <DialogContent>
                                                                                        <DialogContentText id="alert-dialog-description">
                                                                                            Please select a user to assign to the task first
                                                                                        </DialogContentText>
                                                                                    </DialogContent>
                                                                                    <DialogActions>
                                                                                        <Button 
                                                                                            onClick={this.handleClose}
                                                                                        >
                                                                                            OK
                                                                                        </Button>
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    </DialogActions>
                                                                                </StyledDialog>
                                                                            </div>
                                                        :   //  Ask admin confirmation to assign task to the team
                                                            <div>
                                                                <StyledDialog
                                                                    open={this.state.open}
                                                                    onClose={this.handleClose}
                                                                    hideBackdrop={true}
                                                                    BackdropProps={{style: {display: "none"}}}
                                                                    fullWidth={true}
                                                                    maxWidth={'md'} 
                                                                    aria-labelledby="alert-dialog-title"
                                                                    aria-describedby="alert-dialog-description"
                                                                    
                                                                >
                                                                    <DialogTitle id="alert-dialog-title">{"Assign task to team?"}</DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText id="alert-dialog-description">
                                                                            Would you like to remove the user assigned to task - <b>{`${(this.state.selected)["workflowTask#description"]}`}</b> 
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button 
                                                                            onClick={this.handleClose}
                                                                        >
                                                                            Disagree
                                                                        </Button>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <Button 
                                                                            onClick={() => {
                                                                                this.handleClose((this.state.selected).workflow_task_id, null, this.state.doa)
                                                                            }}
                                                                            autoFocus
                                                                        >
                                                                            Agree
                                                                        </Button>
                                                                    </DialogActions>
                                                                </StyledDialog>
                                                            </div>
                                                :   ''
                                            }
                                        </>
                                    })
                                :   'No Tasks Found'
                            }
                        </tbody>
                    </Table>
                </div>
                <div className="divider mt-3" />
                <div className="card-footer p-4 d-flex justify-content-center">
                    {/* <Pagination className="pagination-primary" count={10} /> */}
                </div>
        </div>

    }

    render() {
        return this.getTable();
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            accounts: {
                ...state.accounts,
            },
            user: {
                ...state.user,
            },
            teams: {
                ...state.teams
            },
            users:{
                ...state.userOptions.allUsers
            },
            workflows: {
                ...state.workflowTaskManager
            }
        },
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageWorkFlowTask);
