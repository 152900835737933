import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import Checkbox from '../../../controls/checkbox'
import Button from "@mui/material/Button";
import Icon from '../../Icon/component'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import { Table } from '@mui/material'

import moment from 'moment';
import TextField from '@mui/material/TextField'
import Upload from '../../Upload/component'

import ReactQuill from 'react-quill'

import * as actions from './actions'
import * as styles from './styles'

class StatementGrid extends Component {
    componentDidMount() {
    }

    getTotal(data, prop) {
        var sum = 0
        for(let x of data) {
            sum = sum + parseFloat(x[prop].toString().replace(/,/g, ''))
        }
        return this.getField(sum, 'numeric')
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : ''
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }

    getGrid() {
        return <AdhocGrid
            key={'adhoc_grid_billing_review_data'}
            name={'adhoc_grid_billing_review_data'}
            ////uri={'/components/collection/other'}
            filterable={false}
            sortable={false}
            body={{
                collection: 'billing_preview_data_off',
                searchText: ''
            }}
            height={'1000px'}
            loading={this.props.state.data ? false : true}
            data={this.props.state.data}
            search={''}
            style={{
                background: '#90ee90',
                color: 'white',
                width: '100%'
            }}
            width={'100%'}
            columns={[
                { accessor: 'customer.accountnumber', Header: 'Account Number', filterable: false, width: 200 },
                { accessor: 'customer.hierarchyname', Header: 'Debtor Name', filterable: false, width: 400 },
                { accessor: 'client', Header: 'Owner Name', filterable: false, width: 300},
                { accessor: 'accountnumber', Header: 'Owner Account No', filterable: false, width: 200 },
                {
                    accessor: 'invoicenumber', Header: 'Document', filterable: false, width: 250, Cell: row => {
                        return (
                            <div>
                                <Button className={"global_button"} variant="contained"
                                    primary={true}
                                    label="Preview"
                                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                                    onClick={(event) => {
                                        this.props.actions.getStatement(row.original['custid'],false)
                                    }}
                                    >{window.t.en('Preview')}</Button>
                                    
                                <Button className={"global_button"} variant="contained"
                                    primary={true}
                                    label="Preview"
                                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                                    onClick={(event) => {
                                        this.props.actions.getStatement(row.original['custid'],true)
                                    }}
                                    >{window.t.en('Email')}</Button>
                            </div>
                        )
                    }
                },
            ]}
            paging={{
                page: 0,
                pageSize: 10,
                pages: 0,
                count: 0
            }}
            subComponent={row => {
                //console.log('items',row)
                return <div key={'grid_statement_item_' + row.original['custid']} style={{ padding: "2px" }}>
                    <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12} style={styles.title}>
                            {'Maintenance Progress Steps'}
                        </Col>
                        <Col xl={12}>  
                            <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Age</th>
                                <th>Type</th>
                                <th>Reference</th>
                                <th>Description</th>
                                <th>Reg No</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Balance</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    row.original.items.map(x => {
                                        return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.trans_date}</td>
                                            <td>{x.trans_age}</td>
                                            <td>{x.trans_type}</td>
                                            <td>{x.trans_ref}</td>
                                            <td>{x.trans_desc}</td>
                                            <td>{x.trans_reg}</td>
                                            <td>{this.getField(x.trans_debit, 'numeric')}</td>
                                            <td>{this.getField(x.trans_credit, 'numeric')}</td>
                                            <td>{this.getField(x.trans_balance, 'numeric')}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                            <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{_.sumBy(row.original.items, (d) => { return parseFloat(Number(d.trans_debit)) }).toFixed(2)}</th>
                                <th>{_.sumBy(row.original.items, (d) => { return parseFloat(Number(d.trans_credit)) }).toFixed(2)}</th>
                                <th>{_.sumBy(row.original.items, (d) => { return parseFloat(Number(d.trans_balance)) }).toFixed(2)}</th>
                            </tr>
                            </thead>
                        </Table>
                        </Col>
                    </Row>
                    </Container>
                    </div>    
            }}
        />
    }

    getRefreshing () {
        return  <Row>
            <Col xl={12}>
                <div>
                    <h4>Statement Refreshing:</h4>
                    <ul>
                        <li>This will take couple of minutes</li>
                    </ul>
                </div>
            </Col>
        </Row>
    }

    getLayout() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>{this.props.state.refreshing ? this.getRefreshing() : this.getGrid()}</Col>
            </Row>
        </Container>
    }

    render() {
        
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.statementPreview
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatementGrid)