import * as actions from "./actions";
import * as progressBarActions from "../ProgressBar/actions";
import * as requestActions from "../Request/actions";
import * as routerActions from "../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../Request/types";
import * as config from "../../config";

export const getComponentGridData = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_COMPONENT_GRID_DATA),
    delay(0),
    mergeMap(({ payload }) =>
      ajax$({
        url:
          config.system.api + "/components/" + (payload.component.name ? payload.component.name : payload.component) + "/" + (payload.component.cypher ? "cypher" : "view") + payload.query,
        method: "GET",
        headers: {
            tokenx: store.value.user.user_id,
            locale: store.value.accounts.locale,
            auth: store.value.cognito.authenticateUser.token,
            salt: store.value.cognito.authenticateUser.jwt,
            datetime: store.value.cognito.authenticateUser.datetime,
            "client-id": store.value.accounts.selectedAccount,
            "business-ids": store.value.businessCustomers.allBusinesses,
            take: payload.paging.pageSize,
            page: payload.paging.page + 1,
            order: payload.sorting.length
              ? payload.sorting
              : "-" + payload.component.orderby,
            related: payload.related,
        }
      }).pipe(
        mergeMap((result) => [
          actions.setComponentGridData({
            related: payload.related,
            child: payload.child,
            component: payload.component,
            data: result.response,
          }),
          progressBarActions.progress(false),
          requestActions.requestSucceeded(
            config.system.api +
              "/components/" +
              payload.component.name +
              "/search" +
              payload.query,
            result.response
          ),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const getClientGridData = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_CLIENT_COMPONENT_GRID_DATA),
    delay(0),
    mergeMap(({ payload }) =>
      ajax$({
        url:
          config.system.api + "/components/" + (payload.component.name ? payload.component.name : payload.component) + "/" + (payload.component.cypher ? "cypher" : "search") + payload.query,
        method: "GET",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          //"client-id": store.value.accounts.selectedAccount,
          "business-ids": store.value.businessCustomers.allBusinesses,
          take: payload.paging.pageSize,
          page: payload.paging.page + 1,
          order: payload.sorting.length
            ? payload.sorting
            : "-" + payload.component.orderby,
          related: payload.related,
        },
      }).pipe(
        mergeMap((result) => [
          actions.setComponentGridData({
            related: payload.related,
            child: payload.child,
            component: payload.component,
            data: result.response,
          }),
          progressBarActions.progress(false),
          requestActions.requestSucceeded(
            config.system.api +
              "/components/" +
              payload.component.name +
              "/search" +
              payload.query,
            result.response
          ),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );
export const getExcelData = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_COMPONENT_EXCEL_DATA),
    delay(0),
    mergeMap(({ payload }) =>
      ajax$({
        url:
          config.system.api +
          "/components/" +
          payload.component.name +
          "/search" +
          payload.query,
        method: "GET",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "client-id": store.value.accounts.selectedAccount,
          "business-ids": store.value.businessCustomers.allBusinesses,
          take:
            payload.paging && payload.paging.pageSize
              ? payload.paging.pageSize
              : 9999,
          page: 0,
          order: payload.sorting.length
            ? payload.sorting
            : "-" + payload.component.orderby,
          related: payload.related,
        },
      }).pipe(
        mergeMap((result) => [
          actions.excelExport({
            component: payload.component,
            data: result.response,
          }),
          progressBarActions.progress(false),
          requestActions.requestSucceeded(
            config.system.api +
              "/components/" +
              payload.component.name +
              "/search" +
              payload.query,
            result.response
          ),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );


  
export const updateDowntimeVehicle = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.UPD_COMPONENT_GRID_ROW_FIELD_VALUE),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/downtimevehicle',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
                body: payload
            })
            .pipe(
                mergeMap((result) => [
                    actions.setDowntimeVehicle(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/documenttype/search?typename='+ payload.search)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/documenttype/search?typename=', error)))
            )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getGridExportDataExcel = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_GRID_EXPORT_EXCEL_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/'+payload+'/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 99999
                }
            })
            .pipe(
                mergeMap((result) => [
                    actions.setGridExportDataExcel(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/documenttype/search?typename='+ payload.search)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/documenttype/search?typename=', error)))
            )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
export const deleteRow = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.DEL_COMPONENT_GRID_ROW),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.component,
                method: 'DELETE',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload.data
            })
            .pipe(
                mergeMap((result) => [
                    actions.deleteRowDone(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/documenttype/search?typename='+ payload.search)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/documenttype/search?typename=', error)))
            )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
