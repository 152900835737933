import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getLookupValueData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_SUPPLIER_LIST,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplier/search?suppliername=' +  payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    // 'cognito_key': store.value.users[0].cognito_key,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setLookupValueData(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getSupplierAccessoryData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_SELECTED_SUPPLIER_ACCESSORY_LIST,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplieraccessorypricing/search?supplier_id=' +  payload.supplier_id, // + '&accessory_fktext=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 2000
                    //'related': 'accessory'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setSupplierAccessoryData(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getAccessoriesList = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_ACCESSORY_LIST,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accessory/search?accessoryname=' +  payload, // + '&accessory_fktext=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    //'related': 'accessory'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAccessoriesList(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const addSupplierPricingItem = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.ADD_NEW_SUPPLIER_PRICING_PROFILE_ITEM,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/accessory/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.refreshSupplierList(),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


        
export const updateSupplierSellingPrice = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.UPDATE_SUPPLIER_ACCESSORY_SELLING_PRICE)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/accessory/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
            mergeMap((result) => [
                //actions.setUpdatedSupplierSellingPrice(payload.result),
                progressBarActions.progress(false)
            ])
            ,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    )
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

      
export const updateSelectedSupplierItem = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.UPDATE_SELECTED_ACCESSORY)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/accessory/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
            mergeMap((result) => [
                actions.refreshSupplierList(),
                progressBarActions.progress(false)
            ])
            ,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    ))
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

    
export const updateSupplierCostPrice = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.UPDATE_SUPPLIER_ACCESSORY_COST_PRICE)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/accessory/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
            mergeMap((result) => [
                //actions.setUpdatedSupplierCostPrice(payload.result),
                progressBarActions.progress(false)
            ])
            ,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    ))
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))


export const removeSingleRow = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.REMOVE_SELECTED_SUPPLIER_ACCESSORY_LIST)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/accessory/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
            mergeMap((result) => [
                actions.refreshSupplierList(),
                progressBarActions.progress(false)
            ])
            ,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    ))
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))