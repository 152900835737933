export const team = true
export const edit = false
export const assign = false
export const selectedTeam = 0
export const users = []
export const allUsers = []
export const allUsersLoading = true
export const selectedUser = 0
export const allTeams = []
export const allTeamsLoading = true
export const event_users = []
export const event_teams = []
export const allEvents = []
export const allEventsLoading = true
export const allComponents = []
export const allComponentsLoading = true
export const selectedComponent = 0
export const components = []
export const changed = false
export const view = 'users'
export const allAccounts = []
export const allAccountsLoading = true
export const selectedAccount = 0
export const accounts = []

export const businessMode = true

export const selectedReport = 0
export const workqueue_id = 0
export const allReports = []
export const reports = []
export const allReportsLoading = true

export const allBusinesses = []
export const selectedBusiness = 0
export const allUserCustomers = []
export const allCustomers = []
export const allCustomersLoading = false
export const toLinkUser = null
export const toLinkCustomer = null
export const selectedCustomer = 0

export const accountBusinesses = []
export const accountBusinessCustomers = []