import * as actions from "./actions";
import * as requestActions from "../Request/actions";
import * as routerActions from "../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../Request/types";
import * as config from "../../config";

export const getDocumentTypesEpic = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.DOCUMENT_GET_DOCUMENT_TYPES),
    delay(0),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/documenttype/query",
        method: "GET",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
        },
      }).pipe(
        mergeMap((result) => [
          actions.setDocumentTypes(result.response, payload.name),
          requestActions.requestSucceeded(
            config.system.api + "/components/documenttype/query"
          ),
        ]),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/documenttype/query",
              error
            )
          )
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );
