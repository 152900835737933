import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import SignedIn from '../SignedIn/component'
import SignedOut from '../SignedOut/component'
import ActiveDirectory from '../ActiveDirectory/component'
import Permission from '../Permission/component'
// import SignedOut from '../SignedOut/component'
import I18next from '../I18next/component'
import Loading from '../Loading/component'

import { useSearchParams } from 'react-router-dom'

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import * as actions from './actions'
import * as accountActions from '../Account/actions'
import * as cognitoActions from '../Cognito/actions'
import * as routerActions from '../Redirect/actions'
import * as componentActions from '../Components/actions'
import * as workflowActions from '../Workflows/actions'

import CountDown from 'react-number-count-down';
import { acquireTokenSilently, fetchUserGroups } from './AuthService';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount,  useIsAuthenticated  } from '@azure/msal-react';

import { signOut } from 'aws-amplify/auth'

import * as config from '../../config'

// class App extends Component {
const App = (props) => {    

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    

    useEffect(() => {
        window.glyco = {
            log: (...args) => {
                if (process.env.NODE_ENV !== 'production') {
                    //console.log(...args)
                }
            }
        }

        window.t = {
            en: (args) => {
                return args //<I18next i18n={args} lang={props.state.accounts.locale ? props.state.accounts.locale : 'en'} />
            }
        }
    });


    // useEffect(() => {
        
    //     const fetchToken = async () => {
    //         //console.log('accountsxx isAuthenticated', isAuthenticated)
    //         //console.log('accountsxx isin', props.state.useMsal)
    //         //console.log('accountsxx fetchToken', accounts)
    //         //debugger
    //        if (accounts.length) {
    //             const silentRequest = {
    //                 scopes: ["user.read", "group.read.all"],
    //                 //scopes: ["openid", "profile", "User.Read", "Group.Read.All", "User.ReadBasic.All"],
    //                 account: accounts[0]
    //             };
            
    //             console.log('accountsxx accounts', accounts)
    //             console.log('accountsxx silentRequest', silentRequest)
    //             const tokenResponse = await instance.acquireTokenSilent(silentRequest);

    //             console.log('accountsxx tokenResponse', tokenResponse)

    //              const groups = await fetchUserGroups(tokenResponse.accessToken);
    //              let _groups = groups.map(x => { return x.displayName })
    //              console.log('accountsxx groups', groups.map(x => { return x.displayName }))
    //             let user = {
    //                 groups: _groups,
    //                 email: accounts[0].username,
    //                 username: accounts[0].username,
    //                 attributes: {
    //                     sub: accounts[0].idTokenClaims.oid,
    //                     username: accounts[0].username,
    //                 },
    //                 signInUserSession: {
    //                     idToken: 
    //                     {
    //                         jwtToken: accounts[0].idTokenClaims.oid,
    //                     }
    //                 }
    //             }
    //             console.log('accountsxx user', user)
    //             props.actions.updateUserAuthenticationDetails(user)
    //             props.actions.setSelectedSetting({ prop: 'locale', value: config.system.locale })
    //             props.actions.setCognitoUserAuthenticationDetails(user)
    //         }
    //     };
        
    //     // //console.log('isAuthenticatedx isAuthenticated', isAuthenticated)
    //     // //console.log('isAuthenticatedx isiuseMsaln', props.state.useMsal)
    //     // if(isAuthenticated && !props.state.useMsal) {
    //     //     //console.log('isAuthenticatedx logout', isAuthenticated && !props.state.useMsal)
    //     //    forceLogout()
    //     // }
    //     fetchToken();
    // }, [instance, accounts]);
    useEffect(() => {
        const fetchToken = async () => {
            if (accounts.length === 0) {
                console.log("No accounts found. User may not be logged in.");
                return; // Exit early if there are no accounts
            }

            const silentRequest = {
                scopes: ["user.read", "group.read.all"],
                account: accounts[0],
            };

            try {
                console.log('Fetching token for account:', accounts[0]);
                const tokenResponse = await instance.acquireTokenSilent(silentRequest);
                console.log('Token response:', tokenResponse);

                const groups = await fetchUserGroups(tokenResponse.accessToken);
                const _groups = groups.map(x => x.displayName);
                console.log('User groups:', _groups);

                const user = {
                    groups: _groups,
                    email: accounts[0].username,
                    username: accounts[0].username,
                    attributes: {
                        sub: accounts[0].idTokenClaims.oid,
                        username: accounts[0].username,
                    },
                    signInUserSession: {
                        idToken: {
                            jwtToken: accounts[0].idTokenClaims.oid,
                        },
                    },
                };

                console.log('User details:', user);
                props.actions.updateUserAuthenticationDetails(user);
                props.actions.setSelectedSetting({ prop: 'locale', value: config.system.locale });
                props.actions.setCognitoUserAuthenticationDetails(user);

            } catch (error) {
                console.error('Error acquiring token:', error);
                // Handle specific error cases, such as token acquisition failures
                if (error instanceof msal.InteractionRequiredAuthError) {
                    // If interaction is required, you may want to initiate a login
                    // For example, call `instance.loginPopup()` or `instance.loginRedirect()`
                    console.log('Interaction required, redirecting to login...');
                    await instance.loginPopup();
                }
            }
        };

        fetchToken();
    }, [instance, accounts]);
    
    const setBackground = () => {
        props.state.signedIn && props.state.accounts.teams.length
            ? document.body.className = 'logged_in'
            : document.body.className = 'logged_out'
    }

    const handleSingout = () => {
        signOut()
            .then(data => {
                props.actions.signoutCognitoUser()
            })
            .catch(err => {
                //console.log('handleSingout Err', err)
            });
    }

    const getAmplify = () => {
        return props.state.signedIn
            ? <SignedIn />
            : <SignedOut /> 
    }

    const getActiveDirectory = () => {
        //console.log('inProgressx get', inProgress)
        //console.log('inProgressx accounts', accounts)
        //console.log('inProgressx signedIn', props.state.app.signedIn)
        //console.log('inProgressx username', props.state.activedirectory.username)
        //console.log('accountsx ADInit 2', isAuthenticated)
       // debugger
        return <>
            <AuthenticatedTemplate>
                {<SignedIn />}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <SignedOut />
            </UnauthenticatedTemplate>
        </>
    }

    const getLayout = () => {
        return (
            <div style={{overflow: 'hidden'}}>
                
                {
                    config.system.authMethod === 'AD' ? getActiveDirectory() : getAmplify()
                }

                {
                    <Dialog
                        open={props.state.sessionexpired}
                        style={{ width: '100% !important', maxWidth: '100% !important' }}
                        fullWidth={true}
                        maxWidth="xl"
                    >
                        <div className="text-center p-5">
                            <div className="avatar-icon-wrapper rounded-circle m-0">

                            </div>
                            <h4 className="font-weight-bold mt-4">
                                Your Session Will Expire In:
                            </h4>
                            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                                <h2>
                                    <CountDown from={30} to={1} type={'-'} addon={'seconds'} interval={1} onComplete={() => {
                                        props.actions.closeExpiryDialog(false)
                                        this.handleSingout();
                                        props.actions.clearApplicationCache();
                                    }} />
                                </h2>
                            </div>
                            <p className="mb-0 font-size-lg text-muted">
                                If no action, the application will automatically log you out.
                            </p>
                            <div className="pt-4">
                                <Button
                                    onClick={(event) => {
                                        props.actions.closeExpiryDialog(false)
                                        //props.actions.getCognitoUserAuthenticationDetails(props.state.login.email.input,props.state.login.keyword.input)
                                    }}
                                    className="btn-pill mx-1">
                                    <span className="btn-wrapper--label">RENEW</span>
                                </Button>
                                <Button
                                    onClick={(event) => {
                                        props.actions.closeExpiryDialog(false)
                                        this.handleSingout()
                                        props.actions.clearApplicationCache()
                                    }}
                                    className=" btn-pill mx-1">
                                    <span className="btn-wrapper--label">LOGOUT</span>
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                }

                <Dialog
                    open={props.state.dialog}
                >
                    <div className="text-center p-5">
                        <div className="avatar-icon-wrapper rounded-circle m-0">
                            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">

                            </div>
                        </div>
                        <h4 className="font-weight-bold mt-4">
                            Are you sure you want to delete this entry?
                        </h4>
                        <p className="mb-0 font-size-lg text-muted">
                            You cannot undo this operation.
                        </p>
                        <div className="pt-4">
                            <Button
                                onClick={(event) => {
                                    props.actions.closePopupDialog(false)
                                }}
                                className="btn-neutral-secondary btn-pill mx-1">
                                <span className="btn-wrapper--label">Cancel</span>
                            </Button>
                            <Button
                                onClick={(event) => {
                                    props.actions.closePopupDialog(false)
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">Ok</span>
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    open={props.state.loggedTwice}
                    style={{ width: '100% !important', maxWidth: '100% !important' }}
                    fullWidth={true}
                    maxWidth="xl"
                >

                    <div className="text-center p-5">
                        <div className="avatar-icon-wrapper rounded-circle m-0">

                        </div>
                        <h4 className="font-weight-bold mt-4">
                            You have logged in twice
                        </h4>
                        <h4 className="font-weight-bold mt-6">
                            logging out in:
                        </h4>
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                            <h2>
                                <CountDown from={10} to={0} type={'-'} addon={'seconds'} interval={1} onComplete={() => { props.actions.clearApplicationCache(); this.handleSingout(); }} />
                            </h2>
                        </div>
                        <p className="mb-0 font-size-lg text-muted">
                            You have logged in twice, logout or close the open tabs.
                        </p>
                        <div className="pt-4">
                            <Button
                                onClick={(event) => {
                                    this.handleSingout()
                                    props.actions.closeExpiryDialog(false)
                                    props.actions.clearApplicationCache()
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">LOGOUT</span>
                            </Button>
                            <Button
                                onClick={(event) => {
                                    props.actions.disconectMessenger(false)
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">Force Logout and Continue</span>
                            </Button>
                        </div>
                    </div>
                </Dialog>

            </div>
        )
    }

    return  (
        //this.setBackground()
        getLayout()
    )
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.appAD,
            accounts: {
                ...state.accounts
            },
            user: {
                ...state.user
            },
            messenger: {
                ...state.messenger
            },
            login: {
                ...state.login.fields
            },
            app: {
                ...state.app
            },
            router: {
                ...state.router.location
            },
            activedirectory: {
                ...state.activeDirectory
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            ...actions,
            ...cognitoActions,
            ...routerActions,
            ...componentActions,
            ...workflowActions,
            ...accountActions
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)