import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) { 
        case types.SET_STATEMENT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            } 
            
        case types.SET_TIMETZ:
            return {
                ...state,
                timetz: action.payload.time
            }     
        default: 
            return state
    }
}