import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Card, CardHeader, CardContent, Table, IconButton } from '@mui/material'
import { Container, Row, Col } from 'react-grid-system'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import CustomerView from './view_customer'
import Tabs from './tabs'

import * as actions from './actions'
import * as config from '../config'

class VehicleTabs extends Component {
    componentDidMount() {
        this.props.actions.getComponentVehicleDate(this.props.id)
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm')
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }

    getLabel(label, value, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                <Typography variant="body1" component="div"
                    style={{ fontWeight: 'bold' }}>
                    {this.getField(value, type)}
                </Typography>
            }
            label={
                <label style={{ width: '200px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top' }}>
                    {window.t.en(label)}:
                </label>
            }
            labelPlacement="start"
        />
    }

    getVehicleView(data) {
        return <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row className={"custom_view_text"}>
                        <Col xl={4}>
                            {this.getLabel('Vehicle MMCode', data.mmcode.mmcode, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Current Odometer', data.closingodometer, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Registration', data.licenseplateno, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Register', data.licensediscno, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Make', data.mmcode.make, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Model',  data.mmcode.model, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Fleet No', data.fleetno, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Status', data.vehiclestatus ? data.vehiclestatus.vehiclestatus : '', 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Engine Number', data.enginenumber, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Chassis Number', data.chassisnumber, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('First Registration Date', data.registrationdate ? moment(data.registrationdate).format('YYYY-MM-DD') : '', 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('License Expiry Date', data.licenceexpirydate ? moment(data.licenceexpirydate).format('YYYY-MM-DD') : '', 'text')}
                        </Col>
                        
                        <Col xl={4}>
                            {this.getLabel('Tyre Size Front', data.mmcode.fronttyresize, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Tyre Size Rear', data.mmcode.reartyresize, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Fuel Type', data.mmcode.fueltype, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Transmission Type', data.mmcode.manualauto, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Maintenance CPK', data.mmcode.maintcpk, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Tyre CPK', data.mmcode.tyrecpk, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Type', data.mmcode.vehicletype, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Number Of Type', 4, 'text')}
                        </Col>
                    </Row>
                </Container>
            </div>
    }

    getWarrantyView(data) {
        return <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row className={"custom_view_text"}>
                        {/* <Col xl={4}>
                            {this.getLabel('Vehicle Status', data.vehiclestatus ? data.vehiclestatus.vehiclestatus : '', 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Odometer', data.closingodometer, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Make', data.mmcode.make, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle Model', data.mmcode.makemodelyear, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Vehicle MMCode', data.mmcode.mmcode, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Fuel Type', data.mmcode.fueltype, 'text')}
                        </Col> */}
                        
                        <Col xl={4}>
                            {this.getLabel('Current Odometer', data.closingodometer, 'text')}
                        </Col>
                        
                        <Col xl={4}>
                            {this.getLabel('First Registration Date', data.registrationdate ? moment(data.registrationdate).format('YYYY-MM-DD') : '', 'text')}
                        </Col>
                        {/* {
                            data.mmcode.warranty.split(',').map((x,i) => {
                                return <Col xl={4}>
                                    {this.getLabel('Warranty ' + i, x, 'text')}
                                </Col>
                            })
                        } */}
                        <Col xl={4}>
                            {this.getLabel('Maintenance Type', data.mmcode.smplan)}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Service (Month)', data.mmcode.service_mnth)}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Maintenance (Month)', data.mmcode.serviceplanperiod)}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Maintenance (KM)', data.mmcode.serviceplandistance)}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Warranty (Month)', data.mmcode.warrantyplan)}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Warranty Distance', data.mmcode.warrantykm)}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Service Interval 1', data.mmcode.serviceinterval)}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Service Interval 2', data.mmcode.serviceinterval2)}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Warranty', data.mmcode.warranty)}
                        </Col>
                    </Row>
                </Container>
            </div>
    }

    getContractView(data) {
        data = data.customercontract[0]
        return <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row className={"custom_view_text"}>
                        <Col xl={4}>
                            {this.getLabel('Contract Type', data.contracttype, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Contract Status', data.contractstatus.contractstatus, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Monthly Rental', data.monthly_incl, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('RT57 Pricing', data.rt57pricing ? 'Yes' : 'No', 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Contract Period', data.period, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Distance Per Month', data.distancepermonth, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Contract Start Date', data.startdate ? moment(data.startdate).format('YYYY-MM-DD') : '', 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Contract End Date', data.terminationdate ? moment(data.terminationdate).format('YYYY-MM-DD') : '', 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Tyre Monthly', data.tyres, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Maintenance Monthly', data.maintenance, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Tyre Monthly', parseFloat(data.tyres) * parseInt(data.period), 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Maintenance Monthly', parseFloat(data.maintenance) * parseInt(data.period), 'text')}
                        </Col>
                    </Row>
                </Container>
            </div>
    }
    
    getCustomerView(data) {
        return <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row className={"custom_view_text"}>
                        <Col xl={4}>
                            {this.getLabel('Cost Centre', data.costcentre, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Customer Name', data.customername, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Account Number', data.accountnumber, 'text')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Trading Name', data.tradingname, 'text')}
                        </Col>
                    </Row>
                </Container>
            </div>
    }
    getMaintenanceView(data) {
        return <div>
            <Table className="table table-hover text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>Odometer</th>
                            <th>Auth Number</th>
                            <th>Supplier</th>
                            <th>Description</th>
                            <th>Auth By</th>
                            <th>Auth Date</th>
                            <th>Rebill</th>
                            <th>Auth Total (Inc VAT)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.maintenancetransaction.filter(x => x.authnumber).map(x => {
                                return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                    <td>{x.odometer}</td>
                                    <td>{x.authnumber}</td>
                                    <td>{x.supplier ? x.supplier.suppliername : ''}</td>
                                    <td>{x.maintdescription}</td>
                                    <td>{x.authby}</td>
                                    <td>{x.authdate ? moment(x.authdate).format('YYYY-MM-DD') : ''}</td>
                                    <td>{x.rebill ? 'Yes' : 'No'}</td>
                                    <td>{this.getField(x.amount, 'numeric')}</td>
                                </tr>
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Total</th>
                            <th>{_.sumBy(data.maintenancetransaction, (d) => { return parseFloat(Number(d.amount)) }).toFixed(2)}</th>
                        </tr>
                    </tfoot>
                </Table>
            </div>
    }


    getAccessoriesView(data) {
        data = data.customercontract[0]
        return <div>
            <Table className="table table-hover text-nowrap mb-0">
                <thead>
                    <tr>
                        <th>Accessory Name</th>
                        <th>Description</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                    <tbody>
                        {
                            data.contractaccessory.map(x => {
                                return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                    <td>{x.accessoryname}</td>
                                    <td>{x.description}</td>
                                    <td>{this.getField(x.amount, 'numeric')}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </div>
    }

    getDashboardView(data) {
        //let oq_key = `api_key=0.7498-3000%201-602771-490888-1&dashboard=8d4bef71ce0d3e19ca39742aa7f7630ff22b8327b30ab5ee978b3594a7e982b441a18effc62363bd698932d7c5c68b86776ff093555885cd76a3c9c402f6b7d8&filters=registration='${data.licenseplateno}'`
        let oq_key = `api_key=0.7498-3000%201-602771-490888-1&dashboard=29377378156a43392b2d770752882a049ffdc13928dc9ca282d2a96a7b6686e908d8fd5e928e15c4d5038b23977fdbeac92d949b25d5bef01ff3ad03aa6840de&filters=registration='${data.licenseplateno}'`
        return <div>
            <iframe src={`${config.oq.uri}${oq_key}`} 
                height="600px"
                width="100%"
                style={{
                    border: "none",
                    margin: 0,
                    padding: '-25px',
                    alignSelf: "center",
            }}/>
        </div>
    }

    getPredictiondView(data) {
        //Ford Ranger 2.2 Tdci Xls 4x4 D/Cab
        //DMO3311DM
        //let oq_key = `api_key=0.7498-3000%201-602771-490888-1&dashboard=5673b780b48faf4d7a870aae27ca25cbbc2344b17ca0989d29f02bfd31137695a6bbbd3718c74a48294a685646d62ca27ce7c909a063f157904d99288f4b09ea&filters=reg in ('${data.makemodelyear}','${data.licenseplateno}')`
        let oq_key = `api_key=0.7498-3000%201-602771-490888-1&dashboard=5673b780b48faf4d7a870aae27ca25cbbc2344b17ca0989d29f02bfd31137695a6bbbd3718c74a48294a685646d62ca27ce7c909a063f157904d99288f4b09ea&filters=reg in ('Ford Ranger 2.2 Tdci Xls 4x4 D/Cab  ','JHJ891EC')`
        return <div>
            <iframe src={`${config.oq.uri}${oq_key}`} 
                height="700px"
                width="100%"
                style={{
                    border: "none",
                    margin: 0,
                    padding: '-25px',
                    alignSelf: "center",
            }}/>
        </div>
    }
    getTabs() {
        return <div style={{maxHeight: '200px !important'}}>
            <Tabs key={'quote_items_tabs'} 
                value={this.props.state.tab}
                tabs={["Vehicle Details","Maintenance History","Warranty Details","Contract Details","Accessories List","Customer Details","Expense Dashboard","Prediction Dashboard"]} 
                inkBarStyle={{
                    height: 4
                }}
                onChange={(value) => {
                    this.props.actions.changeTabView(value)
                }}
                content={<div  style={{maxHeight: '200px !important', padding: '15px', position: 'relative'}}>
                    {this.props.state.tab === 0 && this.getVehicleView(this.props.state.vehicle)}
                    {this.props.state.tab === 1 && this.getMaintenanceView(this.props.state.vehicle)}
                    {this.props.state.tab === 2 && this.getWarrantyView(this.props.state.vehicle)}
                    {this.props.state.tab === 3 && this.getContractView(this.props.state.vehicle)}
                    {this.props.state.tab === 4 && this.getAccessoriesView(this.props.state.vehicle)}
                    {this.props.state.tab === 5 && <CustomerView id={this.props.state.vehicle.customer.customer_id} nocard={true} />}
                    {this.props.state.tab === 6 && this.getDashboardView(this.props.state.vehicle)}
                    {this.props.state.tab === 7 && this.getPredictiondView(this.props.state.vehicle)}
                </div>} 
            />
      </div> 
    }

    render() {
        return <div>
            {this.props.state.vehicle.licenseplateno ? this.getTabs() : 'Loading...'}
      </div> 
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTabs)