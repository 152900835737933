import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getMonthList = (search) => {
    return {
        type: types.GET_MONTH_LIST,
        payload: {
            action: 'dropdown',
            search_text: search
        }
    }
}
export const setMonthList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.monthyear,
            value: x.intmonth,
        }
    })
    return {
        type: types.SET_TERM_VEHICLE_LIST,
        payload: {
            data: data,
            component: 'trialbalance',
            field: 'monthyear'
        }
    }
}

export const refreshTrialBalance = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'An Attempt to be awesome...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.REFRESH_TRIAL_BALANCE,
            payload: {
                type: "REFRESH_TRIAL_BALANCE",
                month: payload
            }
        })
    }
   /*  return {
        type: types.REFRESH_TRIAL_BALANCE,
        payload: {
            action: 'refreshtrialbalance',
            month: payload
        }
    } */
}

export const getTrialBalance = (payload) => {
    return {
        type: types.GET_TRIAL_BALANCE,
        payload: {
            action: 'trialbalance',
            month: payload.month,
            nozeros: payload.nozeros
        }
    }
}
export const setTrialBalance = (payload) => {
    return {
        type: types.SET_TERM_VEHICLE_LIST,
        payload: {
            data: payload.data,
            component: 'trialbalance',
            field: 'trialbalance_id'
        }
    }
}

export const setRefreshedTrialBalance = (payload) => {
    return{
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: payload ? false : true,
            text: payload ? false : true,
            prop: 'trialbalance_id',
            component: 'trialbalance'
        }
    }
}


export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field,
            component: 'trialbalance'
        }
    }
}

export const excelExport = (component) => {
    return (dispatch, getState) => {
        const header = [ {
                key: 'account',
                name: 'Account',
                default: ' ',
            }, {
                key: 'description',
                name: 'Description',
                default: ' ',
            }, {
                key: 'category',
                name: 'Category',
                default: ' ',
            },{
                key: 'openingbalance',
                name: 'OB',
                default: '0',
            },{
                key: 'monthtodate',
                name: 'MTD',
                default: '0',
            },
            {
                key: 'closingbalance',
                name: 'YTD',
                default: '0',
            }]

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: component.name,
                data: component.data.trialbalance_id.data,
                header: header
            }
        })
    }
}

export const pdfExport = (component) => {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating Document...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
            dispatch({
                type: types.GEN_TRIAL_BALANCE_PDF,
                payload: {
                    type: "GEN_TRIAL_BALANCE_PDF",
                    data: component.data.trialbalance_id.data
                }
            })
        }
}

export const setTrialBalancePDF = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}


