import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getAllStatements = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CUSTOMER_STATEMENT_LIST,
            payload: {
                type: "GET_CUSTOMER_STATEMENT_LIST",
                id: Number(id)
            }
        })
    }
}
export const getCustomerList = (search) => {
    return {
        type: types.GET_CUSTOMER_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getTimetz = () => {
    return {
        type: types.GET_TIMETZ, 
        payload: {
            type: "get_tz"
        }
    }
}
export const setTimetz = (res) => {
    //console.log("RESPOND!", res);
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TIMETZ,
            payload: {
                time:res.time
            }
        })
    }
}

export const postTimetz = () => {
    return (dispatch, getState) => {
        //console.log(getState().customerstatement);
        dispatch({
            type: types.POST_TIMETZ,
            payload: {
                time: getState().customerstatementview.timetz,
                type: "post_tz"
            }
        })
    }
}
export const setCustomerStatementList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            pdfstatement:x.statementmonth,
            statementmonth: 'Customer Statement for Month Ending - ' +  moment(moment((x.statementmonth + "01").toString()).endOf('month').toDate()).format('YYYY-MM-DD').toString() ,
        }
    })
    return {
        type: types.SET_CUSTOMER_STATEMENT_LIST,
        payload: {
            data: data,
            component:'customerstatement',
            prop:'customerstatement_id'
        } 
    }
}
export const setCustomerList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.hierarchyname + ' | ' + x.accountnumber ,
            value: x.customer_id,
            account: x.accountnumber
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component:'customerstatement',
            field:'customer_id'
        }
    }
}

export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field,
            component: 'customerstatement'
        }
    }
}

export const getCustomerStatementPDF = (data) => {
    return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating Statement...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })          
        
        dispatch({
            type: types.GEN_CUSTOMER_STATEMENT_PDF,
            payload: {
                type: "GEN_CUSTOMER_STATEMENT_PDF",
                parameters: data
            }
        })
    }
}


export const setCustomerStatementPDF = (data) => {
    window.glyco.log('setCustomerStatementPDF')
    window.glyco.log(data)
    let link = ''
   
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Statement Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })          
    }
}


export const setCustomerBillingInvoice = (data) => {
    window.glyco.log('setCustomerBillingInvoice')
    window.glyco.log(data)
    let link = ''
   
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Invoices Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })          
    }
}

export const getCustomerBillingInvoices = () => {
    return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating Invoices...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })          
        
        dispatch({
            type: types.GEN_CUSTOMER_BILLING_INVOICE,
            payload: {
                type: "GEN_CUSTOMER_BILLING_INVOICE",
                data: 'Monthly'
            }
        })
    }
}
export const getCustomerBillingStatements = () => {
    return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating Statements...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })          
        
        dispatch({
            type: types.GEN_CUSTOMER_BILLING_STATEMENT,
            payload: {
                type: "GEN_CUSTOMER_BILLING_STATEMENT",
                data: 'Monthly'
            }
        })
    }
}

export const getCustomerBillingStatementsExcel = () => {
    return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating EXCEL Statements...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })          
        
        dispatch({
            type: types.GEN_CUSTOMER_BILLING_STATEMENT_EXCEL,
            payload: {
                type: "GEN_CUSTOMER_BILLING_STATEMENT_EXCEL",
                data: 'Monthly'
            }
        })
    }
}

export const getCustomerBillingSAPStatement = (email) => {
    return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating SAP Statement...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })          
        
        dispatch({
            type: types.GEN_CUSTOMER_SAP_BILLING_STATEMENT,
            payload: {
                type: "GEN_CUSTOMER_SAP_BILLING_STATEMENT",
                data: 'Monthly',
                email:  email
            }
        })
    }
}

export const getCustomerBillingTFRStatements = (email) => {
    return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating TFR Statement...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })          
        
        dispatch({
            type: types.GEN_CUSTOMER_TFR_BILLING_STATEMENT,
            payload: {
                type: "GEN_CUSTOMER_TFR_BILLING_STATEMENT",
                data: 'Monthly',
                email:  email
            }
        })
    }
}


export const getRebills = () => {
    return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating Rebills...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })          
        
        dispatch({
            type: types.GET_REBILLS,
            payload: {
                type: "GET_REBILLS"
            }
        })
    }
}

export const setRebillPDF = (data) => {
    window.glyco.log('setRebillPDF')
    window.glyco.log(data)
    let link = ''
   
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Rebill Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })          
    }
}


export const getallcontacts = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_STATEMENT_CONTACT_LIST,
            payload: {
                type: "GET_STATEMENT_CONTACT_LIST",
                id: Number(id)
            }
        })
    }
}


export const setStatementContactList = (payload) => {
    var data = payload.data.map((x, i) => {
        return {
                ...x,
                allow_mail: false,
            }
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CUSTOMER_TRANSACTION_LIST,
            payload: {
                data: data,
                component: 'customerstatement',
                prop: 'customerstatement_id'
            }
        })
    }
}

export const setFieldValue = (values, payload) => {
    var data = payload.map((x, i) => {
        return x.contact_id === values.id && (values.field == 'allow_mail') ?
            {
                ...x,
                allow_mail: values.value,
            }
            : x
    })
    return (dispatch, getState) => {
        if (data != null) {
            dispatch({
                type: types.SET_CUSTOMER_TRANSACTION_DATA,
                payload: {
                    data: data,
                    component: 'customerstatement',
                    field: 'customerstatement_id'
                }
            })
        }

    }
}

export const getAdhocStatement = (payload) => {
    //console.log("getAdhocStatement", payload)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Statements...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GENERATE_CUSTOMER_STATEMENT,
            payload: {
                type: "GENERATE_CUSTOMER_STATEMENT",
                data: payload
            }
        })
    }
}


export const setValue = (field,value) => {
    return (dispatch, getState) => {      

        dispatch({
            type: types.SET_STATEMENT_VALUE,
            payload: {
                prop: field,
                value: value
            }
        })
    }
}

