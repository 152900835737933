import * as types from './types'
import * as appTypes from '../../App/types'
import moment from 'moment'

import { ExcelRenderer, OutTable } from 'react-excel-renderer'
import * as xlsx  from 'xlsx'

export const setInputValue = (prop, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_CASH_BOOK_INPUT_VALUE,
            payload: {
                prop: prop,
                data: data
            }
        })
    }
}

export const getCashBookLookup = (component, field, search) => {
    return (dispatch, getState) => {
    
        dispatch({
            type: types.GET_COMPONENT_CASH_BOOK_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
                query: `?${field}=${search}`
            }
        })
    }
}


export const setCashBookReport = (response) => {
    return (dispatch, getState) => {
        dispatch(setInputValue('report_data', response.data))
    }
}

export const getCashBookReport = (accountingitem_id, start, end) => {
    return (dispatch, getState) => {
     
        if(accountingitem_id && start && end) {
            dispatch({
                type: types.SET_COMPONENT_CASH_BOOK_REPORT_DATA,
                payload: {
                    type: 'get_cashbook',
                    accountingitem_id: accountingitem_id,
                    start: start,
                    end: end
                }
            })
        }
        else
        {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'Error: System Notification',
                    additionalText: 'Please complete all details.',
                    overflowText: '',
                    autoHide: 10000,
                    type: 'danger',
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}

export const viewPdfDocument = (data) => {
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())

    //console.log('data.data.documentx',data.data)

    saveByteArray([data.data.content.Body], data.data.filename)

    return (dispatch, getState) => {
        dispatch(setInputValue('cashbook_pdf_preview', data.data.document.key))
      
    }
}

export const getCashBookPDFReport = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CASHBOOK_PDF_REPORT,
            payload: {
                type: 'GET_CASHBOOK_PDF_REPORT',
                data: data
            }
        })
    }
}

export const getCashBookCSVReport = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CASHBOOK_CSV_REPORT,
            payload: {
                type: 'GET_CASHBOOK_CSV_REPORT',
                data: data
            }
        })
    }
}

export const getCashBookXLSReport = (data) => {
    return (dispatch, getState) => {
        // dispatch({
        //     type: types.GET_CASHBOOK_XLS_REPORT,
        //     payload: {
        //         type: 'GET_CASHBOOK_XLS_REPORT',
        //         data: data
        //     }
        // })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Excel report downloading.',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        let excel_headers = getState().cashBook.excel_headers
        const rows = [[
            'Transaction Date',
            'Transaction Reference',
            'Transaction Description',
            'Transaction Amount',
            'Reconciled/Unreconciled',
            'Balance'
        ]]
        //console.log('rowsx data', data)  
        
        for (let i = 0; i < data.report_data.length; i++) {
            let datarow = data.report_data[i];
            //console.log('rowsx datarow', datarow)  
            let rowData = [];
            for (let j of excel_headers) {
                //console.log('rowsx datarow row', datarow[j.name])  
                if(j.type === 'date') {
                    //console.log('rowsx datarow date', datarow[j.name])  
                    rowData.push(moment(datarow[j.name]).format('YYYY-MM-DD'));
                }
                else
                    rowData.push(datarow[j.name]);
            }
            //console.log('rowsx rowData', rowData)       
            rows.push(rowData);
        }

        
        //console.log('rowsx rows', rows)         
        // const footer = [];
        // for (let j of bulk_headers) {
        //     //console.log('headers[j]x', j.name)
        //     footer.push((j.name == 'excl' || j.name == 'vat' || j.name == 'incl' )
        //     ? _.sumBy(data, (d) => { return parseFloat((d[j.name]) ? d[j.name].toString().replace(/,/gi, '') : 0) })
        //     : '')
        // }  

        // rows.push(footer);

        const workbook = xlsx.utils.book_new();
        const worksheet = xlsx.utils.aoa_to_sheet(rows);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Cash Book Report");

        //const excelBlob = new Blob([xlsx.write(workbook, { type: 'blob' })], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const excelBuffer = xlsx.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        let filename = 'CashBook_Report_'+moment().format('YYMMDDHHmm')+'.xlsx'
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
}

export const setCashBookLookup = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setCashBookLookupx set',response.data)
        dispatch(setInputValue(payload.component + '_data',response.data))
    }
}