import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'
import ViewQuotation from '../../../controls/view_quotation'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DateControl from '../../../controls/dateControl'
import AutoComplete from '../../../controls/autocomplete'

import Upload from '../../Upload/component'
import TextField from '../../../controls/textField'
import Fields from '../../../controls/cardfields'
import Card from '../../../controls/card'
import PdfViewer from '../../PDF/component'
import Table from '@mui/material/Table'
import Icon from '../../Icon/component'

import * as config from '../../../config'
import * as styles from './styles'
import * as actions from './actions'

class WorkflowBookout extends Component {
    constructor(props) {
        super(props)
        this.state = { gridCounter: 0 }
    }

    updateCounter() {
        this.state = { gridCounter: this.state.gridCounter + 1 }
    }

    componentDidMount() {
        this.props.actions.resetForm()
        this.props.actions.setInputValue({ prop: 'rfqdate', value: moment().format('YYYY-MM-DD') })
        this.props.actions.setInputValue({ prop: 'rfqtime', value: moment().format('HH:mm') })
        this.props.actions.getYardlist()
    }

    getQuotation() {
        let data = this.data
        //console.log('WorkflowReview getQuotation', data)
        return <div><Fields
            fields={[
                { size: 4, label: 'Customer:', value: data.step1.data.customer },
                { size: 4, label: 'RFQ Date:', value: data.step1.data.rfqdate },
                { size: 4, label: 'RFQ Time:', value: data.step1.data.rfqtime },
                { size: 4, label: 'Quote For', value: data.step1.additional ? 'Replace Vehicle' : 'Additional Vehicle' },
                { size: 4, label: 'Replace Vehicle', value: data.step1.data.vehicle, dispay: data.step1.data.additional },
                { size: 4, label: 'Pick Up Address', value: data.step1.data.collection_address.text },
                { size: 4, label: 'Delivery Address', value: data.step1.data.delivery_address.text }
            ]}
        />
            <Fields
                fields={[
                    { size: 4, label: 'Created By:', value: data.step1.user.firstname + data.step1.user.lastname },
                    { size: 4, label: 'Date:', value: data.step1.datestamp },
                    { size: 4, label: 'Comments', value: 'Capture Customer Quote' },
                    { size: 4, label: 'Review By:', value: data.step2.user.firstname + data.step2.user.lastname },
                    { size: 4, label: 'Date:', value: data.step2.datestamp },
                    { size: 4, label: 'Comments:', value: data.step2.comments },
                    { size: 4, label: 'Approve By:', value: data.step3.user.firstname + ' ' + data.step3.user.lastname },
                    { size: 4, label: 'Date:', value: data.step3.datestamp },
                    { size: 4, label: 'Comments:', value: data.step3.comments }
                ]}
            />
        </div>
    }

    getContract() {
        let data = this.data.step1.data
        //console.log('WorkflowReview getContract', data)
        return <Fields
            fields={[
                { size: 4, label: 'Vehicle Category:', value: data.strratecard },
                { size: 4, label: 'Transmission:', value: data.transmission },
                { size: 4, label: 'Contact Period:', value: data.period },
                { size: 4, label: 'Monthly Inclusive:', value: data.distance },
                { size: 4, label: 'Start Date:', value: data.startdate },
                { size: 4, label: 'End Date:', value: data.enddate },
                { size: 4, label: 'Contract Unit:', value: data.contractunit },
                { size: 4, label: 'Waivers:', value: data.waiver }
            ]}
        />
    }

    getFinance() {
        let data = this.data.step1.data
        //console.log('WorkflowReview getFinance', data)
        return <Fields
            fields={[
                { size: 4, label: 'Monthly Rental:', value: data.str_monthly_rental },
                { size: 4, label: 'Accessories Total:', value: data.accessory_tot_monthly },
                { size: 4, label: 'Waiver Total:', value: data.waiver_tot },
                { size: 4, label: 'Sub Total:', value: data.monthly_excl },
                { size: 4, label: 'VAT:', value: data.monthly_vat },
                { size: 4, label: 'Total Rental:', value: data.monthly_incl },
            ]}
        />
    }

    getAccessory() {
        let data = this.data.step1.data
        //console.log('WorkflowReview getAccessory', data)
        return <Table className="table table-hover text-nowrap mb-0">
            <thead>
                <tr>
                    <th>Accessory</th>
                    <th>Accessory Cost </th>
                    <th>Rate Per Month </th>
                </tr>
            </thead>
            <tbody>
                {
                    data.accessory_list.map(x => {
                        return <tr>
                            <td>{x.accessory_name}</td>
                            <td>{x.totalprice}</td>
                            <td>{x.ratepermonth}</td>
                        </tr>
                    })
                }
            </tbody>
        </Table>
    }

    getDocument() {
        //console.log('WorkflowReview getDocument', this.data)
        return this.data.documents.length
            ? <PdfViewer
                key={'document_viewer_' + this.data.documents.length}
                documents={this.data.documents}
                preview={'preview'}
            />
            : 'Retrieving Documents'
    }


    getBooking() {
        return <Container style={styles.nomargin}>
            <Row>
                <Col xl={4}>
                    <DateControl
                        id={'rfqdate'}
                        key={'rfqdate'}
                        name={'rfqdate'}
                        label={window.t.en('Adhoc Rental Quote Accepted Date')}
                        field={'rfqdate'}
                        fullWidth={true}
                        style={{ width: '250px' }}
                        value={this.props.state.rfqdate}
                        onSelect={(args) => {
                            this.props.actions.setInputValue({ prop: 'rfqdate', value: args })
                        }}
                        type={'date'}
                    />
                </Col>

                <Col xl={4}>
                    <TextField
                        key="deliverycost"
                        variant="outlined"
                        margin="normal"
                        id={'rfqtime'}
                        label={'Purchase Order'}
                        value={this.props.state.ponumber}
                        fullWidth={true}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue({ prop: 'ponumber', value: event.target.value })
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <DateControl
                        id={'rfqdate'}
                        key={'rfqdate'}
                        name={'rfqdate'}
                        label={window.t.en(' Delivery Date')}
                        field={'rfqdate'}
                        fullWidth={true}
                        value={this.props.state.deliverydate}
                        onSelect={(args) => {
                            this.props.actions.setInputValue({ prop: 'deliverydate', value: args })
                        }}
                        type={'date'}
                    />
                </Col>
                <Col xl={4}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        type="time"
                        id={'rfqtime'}
                        label={'Adhoc Rental Quote Accepted Time'}
                        value={this.props.state.rfqtime}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue({ prop: 'rfqtime', value: event.target.value })
                        }}
                    />
                </Col>
                <Col xl={4} style={styles.chk}>
                    <Checkbox
                        checked={this.props.state.yardlist}
                        label={'User Yardlist Vehicle'}
                        onChange={(event, checked) => {
                            this.props.actions.getYardlist()
                            this.props.actions.setInputValue({ prop: 'yardlist', value: true })
                        }}
                    />
                    Use Yardlist Vehicle
                </Col>
                <Col xl={4} style={styles.chk}>
                    <Checkbox
                        checked={!this.props.state.yardlist}
                        label={'Use 3rd Party Supplier'}
                        onChange={(event, checked) => {
                            this.props.actions.setInputValue({ prop: 'yardlist', value: false })
                        }}
                    />
                    Use 3rd Party Supplier
                </Col>
                <Col xl={12}>
                    &nbsp;
                </Col>
            </Row>
            {
                this.props.state.yardlist
                    ? <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Registration</th>
                                    <th>Vehicle Description</th>
                                    <th>Vehicle Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ height: '60px', overflow: 'auto' }}>
                                {
                                    this.props.state.yardlist_vehicle.map((a, inx) => {
                                        return <tr key={`vehicle-booking-form-${inx}-${this.state.gridCounter}`} style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{a.licenseplateno}</td>
                                            <td>{a.makemodelyear}</td>
                                            <td>{a.vehiclestatus.vehiclestatus}</td>
                                            <td>
                                                <Icon
                                                    iname="pageview"
                                                    iclass="material-icons"
                                                    icolor="#253053"
                                                    istyle={{
                                                        fontSize: '20px',
                                                        color: '#253053'
                                                    }}
                                                    onClick={(event) => {
                                                        this.props.actions.setInputValue({ prop: 'view_vehicle', value: a })
                                                        this.props.actions.setInputValue({ prop: 'view_vehicle_win', value: true })
                                                    }}
                                                />
                                                &nbsp;&nbsp;
                                                <Checkbox
                                                    key={`vehicle-booking-form-checkbox-${inx}-${this.state.gridCounter}`}
                                                    checked={a.checked === true ? true : false}
                                                    label={''}
                                                    style={{ top: '-7px' }}
                                                    onChange={(event, checked) => {
                                                        if (!checked) {
                                                            console.log(a.value)
                                                            this.updateCounter()
                                                        } 
                                                            this.props.actions.setListValue({ inx: inx, prop: 'yardlist_vehicle', value: a.value, field: 'checked' })
                                                            //this.props.actions.setInputValue({prop: 'yardlist', value: true})
                                                        
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                    : <div>
                        <Row>
                            <Col xl={4}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_supplier'}
                                    key={'dll_supplier'}
                                    label={'3rd Party Supplier'}
                                    hintText={'Select the Debtor/Client Account'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    dataSource={this.props.state.supplier_data}
                                    onNewRequest={
                                        (chosenRequest, index) => {
                                            this.props.actions.setInputValue({ prop: 'supplier_id', value: chosenRequest.value })
                                            this.props.actions.setInputValue({ prop: 'supplier', value: chosenRequest.text })
                                            this.props.actions.getSupplierRateCardData('supplierratecard', 'category', chosenRequest.value)
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getLookupValueData('supplier', 'suppliername', '')
                                        }
                                    }
                                    onUpdateInput={
                                        (search, dataSource, params) => {
                                            this.props.actions.getLookupValueData('supplier', 'suppliername', search)
                                        }
                                    }
                                />

                            </Col>
                            <Col xl={12}>
                                &nbsp;
                            </Col>
                            <Col>
                                <Table className="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th>Distance</th>
                                            <th>Make Model</th>
                                            <th>Rental</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ height: '400px', overflow: 'auto' }}>
                                        {
                                            this.props.state.supplierratecard_data.map((a, inx) => {
                                                //console.log('a.checked',a.checked)
                                                return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                                    <td>{a.category}</td>
                                                    <td>{a.distance}</td>
                                                    <td>{a.makemodel}</td>
                                                    <td>{a.originalstrrental}</td>
                                                    <td>
                                                        <Checkbox
                                                            checked={a.checked ? true : false}
                                                            label={''}
                                                            onChange={() => {
                                                                this.props.actions.setListValue({ prop: 'supplierratecard_data', value: a.value, field: 'checked' })
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
            }
        </Container>

    }

    getUpload() {
        return <Upload
            id={'upload_str'}
            prefix={'STR_Capture_Att'}
            header={false}
        />
    }

    getComments() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {this.props.workflow_queue_data.workflow_queue_id && <ViewQuotation
                        key={this.props.workflow_queue_data.workflow_queue_id}
                        id={this.props.workflow_queue_data.workflow_queue_id}
                    />
                    }</Col>
                <Col xl={12}>
                    <TextField
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        id={'txt_laodfactor'}
                        label={'Enter Notes'}
                        hintText={'Enter Load Factor %'}
                        value={this.props.state.comments}
                        multiline={true}
                        rows={5}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue({ prop: 'comments', value: event.target.value })
                        }}
                    />
                </Col>
            </Row>
        </Container>
    }


    getView(data) {
        //console.log('View',data),
        this.data = data //this.props.workflow_queue_data.context_data.data.data.data[0]
        //console.log('WorkflowReview getView', this.data)
        return this.data ? <div>
            <Card
                title={window.t.en('Adhoc Rental Quotation Confirm')}
                subtitle={window.t.en('Ensure that all values are captured correctly before your proceed ')}
                content={this.getQuotation()}
            />
            <Card
                title={window.t.en('Contract Details')}
                subtitle={window.t.en('Contract parameters ')}
                content={this.getContract()}
            />
            <Card
                title={window.t.en('Finance and Rental')}
                subtitle={window.t.en('Contract finance details ')}
                content={this.getFinance()}
            />
            <Card
                title={window.t.en('Accessories')}
                subtitle={window.t.en('Contract accessories list')}
                content={this.getAccessory()}
            />
            <Card
                title={window.t.en('Documents')}
                subtitle={window.t.en('Attached documets')}
                content={this.getDocument()}
            />
            <Card
                title={window.t.en('Vehicle Booking')}
                subtitle={window.t.en('Vehicle Booking Form')}
                content={this.getBooking()}
            />
            <Card
                title={window.t.en('Upload')}
                subtitle={window.t.en('Upload booking supporting documents')}
                content={this.getUpload()}
            />
            <Card
                title={window.t.en('Comments')}
                subtitle={window.t.en('Approval comments')}
                content={this.getComments()}
            />
        </div>
            : 'Please waiting...'
    }

    getViewWin() {
        //console.log('ViewWin',this.props.state.view_vehicle)
        return <Dialog
            maxWidth="md"
            open={this.props.state.view_vehicle_win}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setInputValue({ prop: 'view_vehicle_win', value: false })
            }}
        >
            <div className="text-center- p-5">
                {/* <h4 className="font-weight-bold mt-4-">
              </h4>
              <strong className="mb-0 font-size-lg text-muted bold">
                
              </strong> */}
                <p className="mb-0 font-size-lg text-muted-">
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={4}>
                                <img style={{ width: '230px' }} src={config.system.cdn + '/' + (this.props.state.view_vehicle.mmcode ? this.props.state.view_vehicle.mmcode.imagefilename : 'enkw.jpg')} />
                            </Col>
                            <Col xl={8}>
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Registration Number:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {this.props.state.view_vehicle.licenseplateno}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Make:'}</div>
                                                <div style={styles.fieldContent}> {this.props.state.view_vehicle.makemodelyear}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Description:'}</div>
                                                <div style={styles.fieldContent}> {this.props.state.view_vehicle.makemodelyear}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Type:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {this.props.state.view_vehicle?.vehicletype?.vehicletype}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Year Model:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {this.props.state.view_vehicle.registrationdate}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Fuel Type:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {this.props.state.view_vehicle?.fueltype?.fueltype}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Transmission:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {this.props.state.view_vehicle?.mmcode?.manualauto}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Current Odometer:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {this.props.state.view_vehicle.yardlist_dometer}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Location:'}</div>
                                                <div style={styles.fieldContent}>
                                                    {this.props.state.view_vehicle.yard_name}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </p>
                <div className="pt-4">
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.setInputValue({ prop: 'view_vehicle_win', value: false })
                        }}
                        className=" btn-pill- mx-1">
                        <span className="btn-wrapper--label">Close</span>
                    </Button>
                </div>
            </div>
        </Dialog>
    }

    getLayout() {
        this.data = this.props.workflow_queue_data.context_data.data.data.data[0]
        //console.log('WorkflowReview ',this.data)
        return [this.getView(this.data), this.getViewWin()]
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.strQuotationBookout,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowBookout)