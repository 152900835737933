import * as props from './props'
import * as types from './types'
export default (state = props, action) => {
    switch (action.type) {
        case types.REFRESH_INVAMT:
            return {
                ...state,
                invoiceamount: action.payload,
            }
        case types.REFRESH_INVNO:           
            return {
                ...state,
                invoicenumber: action.payload,
            }

        default:
            return state
    }
}