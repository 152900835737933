import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'


import AutoComplete from '../../controls/autocomplete'
import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'

import * as routerActions from '../Redirect/actions'
import * as actions from './actions'
import * as styles from './styles'

import TextField from '@mui/material/TextField'
import Icon from '../Icon/component'
import Iframe from 'react-iframe'
import 'react-quill/dist/quill.snow.css'

class UploadComponent extends Component {
    componentDidMount() {
        if (!this.props.inx)
            this.props.actions.resetAllDocuments()

        this.props.actions.setUploadLimit(this.props.limit ? this.props.limit : null)
    }

    getUploaderInx(inx) {
        return this.props.header
            ? <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
                <CardHeader
                    title={window.t.en('Documents')}
                    subtitle={window.t.en('Document upload')}
                    actAsExpander={false}
                />
                <CardContent expandable={false}>
                    <Container style={{ margin: 0 }} className="custom-container">     <Row>
                        <Col xl={12}>
                            < Button className={"global_button"}
                                secondary={true}
                                label={"Add "}
                                icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                                onClick={(event) => {
                                    this.props.actions.addBlankUpload(inx)
                                }}
                            ><Icon iclass={'material-icons'} iname={'file_upload'} /> Add</Button>
                        </Col>
                    </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        {
                            this.props.state.documents
                                .filter(x => x.inx === inx)
                                .map((x, i) => {

                                    return x
                                        ? <div>
                                            <Row>
                                                <Col xl={12}>
                                                    {
                                                        x.preview
                                                            ? x.filename.toLowerCase().indexOf('.pdf') > 0
                                                                ? <Iframe url={x.preview}
                                                                    width="90%"
                                                                    height="200px"
                                                                    id={'receipt_' + x.filename}
                                                                    className="myClassname"
                                                                    display="initial"
                                                                    position="relative"
                                                                    allowFullScreen />
                                                                : < Button className={"global_button"}
                                                                    secondary={true}
                                                                    label={"Add Document"}
                                                                    icon={<Icon iclass={'material-icons'} iname={'file_download'} />}
                                                                    onClick={(event) => {
                                                                        // this.props.actions.addBlankUpload()
                                                                    }}
                                                                ><Icon iclass={'material-icons'} iname={'file_download'} /> {x.filename}</Button>
                                                            : <Dropzone
                                                                accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.msg'}
                                                                style={styles.dropZone}
                                                                hintText={'Upload'}
                                                                onDrop={(files) => {
                                                                    //component.actions.toggleDropzoneVisibility()
                                                                    let handleDropImages;
                                                                    //console.log('acceptedFiles.length', files)
                                                                }}
                                                                onDropAccepted={(files) => {
                                                                    this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                                                }}
                                                                onDropRejected={(files) => {
                                                                    this.props.actions.resetDocuments(x.fileinx)
                                                                }}
                                                            >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload....
                                                                </p>
                                                            </Dropzone>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={12}>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/* <Col xl={8}>

                                                    <div style={styles.fieldContainer}>
                                                        <div style={styles.fieldTitle}>{'File Name:'}</div>
                                                        <div style={styles.fieldContent}>
                                                            <AutoComplete
                                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                    popoverProps={{ style: { overflow: 'auto', width: 'auto' } }}
                                                                    id={'dll_filetypes1'}
                                                                    key={'dll_filetypes1'}
                                                                    hintText={'Select file type'}
                                                                    filter={AutoComplete.fuzzyFilter}
                                                                    openOnFocus={true}
                                                                    fullWidth={true}
                                                                    dataSourceConfig={{
                                                                        text: 'typename',
                                                                        value: 'documenttype_id'
                                                                    }}
                                                                    dataSource={this.props.state.documentypes}
                                                                    onNewRequest={
                                                                        (chosenRequest, index) => {
                                                                            this.props.actions.setValue(x.fileinx, chosenRequest.text)
                                                                        }
                                                                    }
                                                                    onClick={
                                                                        (event) => {
                                                                            this.props.actions.getLookupDataValues('')
                                                                        }
                                                                    }
                                                                    onUpdateInput={
                                                                        (search, dataSource, params) => {
                                                                            this.props.actions.getLookupDataValues(search)
                                                                        }
                                                                    }
                                                                />
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                <Col xl={4}>
                                                    < Button className={"global_button"} variant="contained"
                                                        primary={true}
                                                        label="Remove"
                                                        icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                                        onClick={(event) => {
                                                            this.props.actions.resetDocuments(x.fileinx)
                                                        }}
                                                    ><Icon iclass={'material-icons'} iname={'delete_forever'} /> Remove</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={12}>
                                                    <br />
                                                </Col>
                                            </Row>
                                        </div>
                                        : ''
                                })
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            : <Container style={{ margin: 0 }} className="custom-container">     <Row>
                <Col xl={12}>
                    < Button className={"global_button"}
                        secondary={true}
                        label={"Add " + inx}
                        icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                        onClick={(event) => {
                            this.props.actions.addBlankUpload(inx)
                        }}
                    ><Icon iclass={'material-icons'} iname={'file_upload'} /> {this.props.btnlabel ? this.props.btnlabel : "Add "}</Button>
                </Col>
            </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                {
                    this.props.state.documents
                        .filter(x => x.inx === inx)
                        .map((x, i) => {
                            return x
                                ? <div><Row>
                                    <Col xl={12}>
                                        {
                                            x.preview
                                                ? x.filename.toLowerCase().indexOf('.pdf') > 0
                                                    ? <Iframe url={x.preview}
                                                        width="90%"
                                                        height="200px"
                                                        id={'receipt_' + x.filename}
                                                        className="myClassname"
                                                        display="initial"
                                                        position="relative"
                                                        allowFullScreen />
                                                    : < Button className={"global_button"}
                                                        secondary={true}
                                                        label={"Add Document"}
                                                        icon={<Icon iclass={'material-icons'} iname={'file_download'} />}
                                                        onClick={(event) => {
                                                            // this.props.actions.addBlankUpload()
                                                        }}
                                                    ><Icon iclass={'material-icons'} iname={'file_download'} /> {x.filename}</Button>
                                                : <Dropzone
                                                    accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.msg'}
                                                    style={styles.dropZone}
                                                    hintText={'Upload'}
                                                    onDrop={(files) => {
                                                        //component.actions.toggleDropzoneVisibility()
                                                        let handleDropImages;
                                                        //console.log('acceptedFiles.length', files.length)
                                                    }}
                                                    onDropAccepted={(files) => {
                                                        this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                                    }}
                                                    onDropRejected={(files) => {
                                                        this.props.actions.resetDocuments(x.fileinx)
                                                    }}
                                                >
                                                    <p style={styles.watermark}>
                                                        Drag and drop file here, or click to select file to upload...
                                                    </p>
                                                </Dropzone>
                                        }
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* <Col xl={8}>

                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'File Name:'}</div>
                                                <div style={styles.fieldContent}>
                                                    
                                                <AutoComplete
                                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                    popoverProps={{ style: { overflow: 'auto', width: 'auto' } }}
                                                                    id={'dll_filetypes2'}
                                                                    key={'dll_filetypes2'}
                                                                    hintText={'Select file type'}
                                                                    filter={AutoComplete.fuzzyFilter}
                                                                    openOnFocus={true}
                                                                    fullWidth={true}
                                                                    dataSourceConfig={{
                                                                        text: 'typename',
                                                                        value: 'documenttype_id'
                                                                    }}
                                                                    dataSource={this.props.state.documentypes}
                                                                    onNewRequest={
                                                                        (chosenRequest, index) => {
                                                                            this.props.actions.setValue(x.fileinx, chosenRequest.text)
                                                                        }
                                                                    }
                                                                    onClick={
                                                                        (event) => {
                                                                            this.props.actions.getLookupDataValues('')
                                                                        }
                                                                    }
                                                                    onUpdateInput={
                                                                        (search, dataSource, params) => {
                                                                            this.props.actions.getLookupDataValues(search)
                                                                        }
                                                                    }
                                                                />
                                                </div>
                                            </div>
                                        </Col> */}
                                        <Col xl={4}>
                                            < Button className={"global_button"} variant="contained"
                                                primary={true}
                                                label="Remove"
                                                icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                                onClick={(event) => {
                                                    this.props.actions.resetDocuments(x.fileinx)
                                                }}
                                            ><Icon iclass={'material-icons'} iname={'delete_forever'} /> Remove</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                </div>
                                : ''
                        })
                }
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>
    }
    downloadFile(x) {
        let link = document.createElement('a');
        link.href = x.preview;
        link.download = x.preview.substr(x.preview.lastIndexOf('/') + 1);
        link.click();
    }

    getUploader() {
        return this.props.header
            ? <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
                <CardHeader
                    title={window.t.en('Documents')}
                    subtitle={window.t.en('Document upload')}
                    actAsExpander={false}
                />
                <CardContent expandable={false}>
                    <Container style={{ margin: 0 }} className="custom-container">     <Row>
                        <Col xl={12}>
                            <Button variant="contained" className="btn-Accept"
                                label={"Add Document"}
                                icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                                onClick={(event) => {
                                    this.props.actions.addBlankUpload()
                                }}
                            ><Icon iclass={'material-icons'} iname={'file_upload'} /> Add Document</Button>
                        </Col>
                    </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        {
                            this.props.state.documents.map((x, i) => {
                                return x
                                    ? <div>
                                        <Row>
                                            <Col xl={12}>
                                                {
                                                    x.preview
                                                        ? x.filename.toLowerCase().indexOf('.pdf') > 0
                                                            ? <Iframe url={x.preview}
                                                                width="90%"
                                                                height="200px"
                                                                id={'receipt_' + x.filename}
                                                                className="myClassname"
                                                                display="initial"
                                                                position="relative"
                                                                allowFullScreen />
                                                            : < Button className={"global_button"}
                                                                secondary={true}
                                                                label={"Add Document"}
                                                                icon={<Icon iclass={'material-icons'} iname={'file_download'} />}
                                                                onClick={(event) => {
                                                                    // this.props.actions.addBlankUpload()
                                                                }}
                                                            ><Icon iclass={'material-icons'} iname={'file_download'} /> {x.filename}</Button>
                                                        : <Dropzone
                                                            accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx'}
                                                            style={styles.dropZone}
                                                            hintText={'Upload'}
                                                            onDrop={(files) => {
                                                                //component.actions.toggleDropzoneVisibility()
                                                                let handleDropImages;
                                                                //console.log('acceptedFiles.length', files.length)
                                                            }}
                                                            onDropAccepted={(files) => {
                                                                this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                                            }}
                                                            onDropRejected={(files) => {
                                                                this.props.actions.resetDocuments(x.fileinx)
                                                            }}
                                                        >
                                                            <p style={styles.watermark}>
                                                                Drag and drop file here, or click to select file to upload..
                                                            </p>
                                                        </Dropzone>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/* <Col xl={8}>
                                                <div style={styles.fieldContainer}>
                                                    <div style={styles.fieldTitle}>{'File Name:'}</div>
                                                    <div style={styles.fieldContent}>
                                                       
                                                    <AutoComplete
                                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                    popoverProps={{ style: { overflow: 'auto', width: 'auto' } }}
                                                                    id={'dll_filetypes3'}
                                                                    key={'dll_filetypes3'}
                                                                    hintText={'Select file type'}
                                                                    filter={AutoComplete.fuzzyFilter}
                                                                    openOnFocus={true}
                                                                    fullWidth={true}
                                                                    dataSourceConfig={{
                                                                        text: 'typename',
                                                                        value: 'documenttype_id'
                                                                    }}
                                                                    dataSource={this.props.state.documentypes}
                                                                    onNewRequest={
                                                                        (chosenRequest, index) => {
                                                                            this.props.actions.setValue(x.fileinx, chosenRequest.text)
                                                                        }
                                                                    }
                                                                    onClick={
                                                                        (event) => {
                                                                            this.props.actions.getLookupDataValues('')
                                                                        }
                                                                    }
                                                                    onUpdateInput={
                                                                        (search, dataSource, params) => {
                                                                            this.props.actions.getLookupDataValues(search)
                                                                        }
                                                                    }
                                                                />
                                                    </div>
                                                </div>
                                            </Col> */}
                                            <Col xl={4}>
                                                < Button className={"global_button"} variant="contained"
                                                    primary={true}
                                                    label="Remove"
                                                    icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                                    onClick={(event) => {
                                                        this.props.actions.resetDocuments(x.fileinx)
                                                    }}
                                                ><Icon iclass={'material-icons'} iname={'delete_forever'} /> Remove</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                    </div>
                                    : ''
                            })
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            : <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <Button variant="contained" className="btn-Accept"
                            label={"Add Document"}
                            icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                            onClick={(event) => {
                                this.props.actions.addBlankUpload()
                            }}
                        ><Icon iclass={'material-icons'} iname={'file_upload'} /> Add Document</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
                {
                    this.props.state.documents.map((x, i) => {
                        let postfix = x.filename.substring(x.filename.indexOf('.'), x.filename.length).trim().toLowerCase()
                        let accept = ['.jpeg', '.jpg', '.pdf', '.png']
                        return x
                            ? <div><Row>
                                <Col xl={12}>
                                    {
                                        x.preview
                                            ? x.filename.toLowerCase().indexOf(postfix) > 0 && accept.includes(postfix)
                                                ? <Iframe url={x.preview}
                                                    width="90%"
                                                    height="300px"
                                                    id={'receipt_' + x.filename}
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : < Button className={"global_button"}
                                                    secondary={true}
                                                    label={"Add Document"}
                                                    icon={<Icon iclass={'material-icons'} iname={'file_download'} />}
                                                    onClick={(event) => {
                                                        // this.props.actions.addBlankUpload()
                                                        this.downloadFile(x)
                                                    }}
                                                ><Icon iclass={'material-icons'} iname={'file_download'} /> {x.filename}</Button>
                                            : <Dropzone
                                                accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.msg'}
                                                style={styles.dropZone}
                                                hintText={'Upload'}
                                                onDrop={(files) => {
                                                    //component.actions.toggleDropzoneVisibility()
                                                }}
                                                onDropAccepted={(files) => {
                                                    this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                                    ///this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)

                                                }}
                                                onDropRejected={(files) => {
                                                    //console.log("YYYYY",files)
                                                    this.props.actions.resetDocuments(x.fileinx)
                                                }}
                                            >
                                                <p style={styles.watermark}>
                                                    Drag and drop file here, or click to select file to upload.
                                                </p>
                                            </Dropzone>
                                    }
                                </Col>
                            </Row>
                                <Row>
                                    <Col xl={12}>
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={8}>
                                        <AutoComplete
                                            name={'dll_filetypes4'}
                                            title={window.t.en('Search...')}
                                            info={{
                                                show: false
                                            }}
                                            fullWidth={true}
                                            value={x.description}
                                            selectOnFocus
                                            data={this.props.list ? this.props.list : this.props.state.documentypes}
                                            onSelect={(args) => {
                                                this.props.actions.setFileType(i, args.text)
                                            }
                                            }
                                            onClick={() =>
                                                this.props.actions.getLookupDataValues('')
                                            }
                                            onFilter={(args) => {
                                                this.props.actions.setFileType(i, args)
                                                this.props.actions.getLookupDataValues(args)
                                            }}
                                            error={this.props.state.documents[i]?.filename !== '' ? this.props.state.documents[i]?.description !== '' ? false : true : false}
                                        />
                                    </Col>
                                    <Col xl={4}>
                                        < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                            onClick={(event) => {
                                                this.props.actions.resetDocuments(x.fileinx)
                                            }}
                                        ><Icon iclass={'material-icons'} iname={'delete_forever'} /> {window.t.en('Remove')}</Button>
                                    </Col>
                                </Row>
                            </div>
                            : ''
                    })
                }
            </Container>
    }

    getView(inx) {
        return inx
            ? this.getUploaderInx(inx)
            : this.getUploader()
    }

    getRN() {
        return <Container style={{ margin: 0 }} className="custom-container">     <Row>
            <Col xl={12} style={styles.btn}>
                < Button className={"global_button"}
                    secondary={true}
                    label={"Add Document"}
                    icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                    onClick={(event) => {
                        this.props.actions.addBlankUpload()
                    }}
                ><Icon iclass={'material-icons'} iname={'file_upload'} /> Add Document</Button>
            </Col>
        </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            {
                this.props.state.documents.map((x, i) => {
                    return x
                        ? <div><Row>
                            <Col xl={12}>
                                {
                                    x.preview
                                        ? x.filename.toLowerCase().indexOf('.pdf') > 0
                                            ? <Iframe url={x.preview}
                                                width="90%"
                                                height="200px"
                                                id={'receipt_' + x.filename}
                                                className="myClassname"
                                                display="initial"
                                                position="relative"
                                                allowFullScreen />
                                            : < Button className={"global_button"}
                                                secondary={true}
                                                label={"Add Document"}
                                                icon={<Icon iclass={'material-icons'} iname={'file_download'} />}
                                                onClick={(event) => {
                                                    // this.props.actions.addBlankUpload()
                                                }}
                                            ><Icon iclass={'material-icons'} iname={'file_download'} /> {x.filename}</Button>
                                        : <Dropzone
                                            accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.msg'}
                                            style={styles.dropZone}
                                            hintText={'Upload'}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                                // let handleDropImages;
                                                // //console.log('acceptedFiles.length', files)
                                                // const reader = new FileReader();
                                                // const fileInfo = files[0];
                                                // if (fileInfo) {
                                                //     reader.readAsBinaryString(files[0]);
                                                //     reader.onloadend = () => {
                                                //         const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                                                //         //console.log('Number of Pages:', count);
                                                //     }
                                                // }
                                            }}
                                            onDropAccepted={(files) => {
                                                let handleDropImages;
                                                if (!this.props.state.documents[i]) {
                                                    this.props.actions.showNotification('Please select file type first')
                                                    return
                                                }
                                                const reader = new FileReader();
                                                const fileInfo = files[0];

                                                if (this.props.state.documents[i].description === 'Release Note') {
                                                    this.props.actions.showNotification('Please select file type first')
                                                    if (fileInfo) {
                                                        reader.readAsBinaryString(files[0]);
                                                        reader.onloadend = () => {
                                                            const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                                                            //console.log('Number of Pages:', count);
                                                            //console.log('Number of Pages:', this.props.state.documents[i].description);
                                                            if (count === 1) {
                                                                this.props.actions.showNotification('Uploading please wait...' + count + ' of ' + this.props.state.documents[i].description)
                                                                this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                                            } else {
                                                                this.props.actions.showNotification('Cheecking page count. please wait...' + count + ' of ' + this.props.state.documents[i].description)
                                                            }
                                                        }
                                                    }
                                                }
                                                else {
                                                    this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                                }
                                                ///this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.resetDocuments(x.fileinx)
                                            }}
                                        >
                                            <p style={styles.watermark}>
                                                Drag and drop file here, or click to select file to upload.
                                            </p>
                                        </Dropzone>
                                }
                            </Col>
                        </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={8}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{window.t.en('File Type: ')}</div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                                name={'dll_filetypes4'}
                                                title={window.t.en('Search...')}
                                                info={{
                                                    show: false
                                                }}
                                                fullWidth={true}
                                                value={x.description}
                                                data={this.props.list ? this.props.list : this.props.state.documentypes}
                                                onSelect={(args) => {
                                                    this.props.actions.setFileType(i, args.text)
                                                }
                                                }
                                                onClick={() =>
                                                    this.props.actions.getLookupDataValues('')
                                                }
                                                onFilter={(args) => {
                                                    this.props.actions.setFileType(i, '')
                                                    this.props.actions.getLookupDataValues(args)
                                                }}
                                                error={this.props.state.documents[i]?.filename !== '' ? this.props.state.documents[i]?.description !== '' ? false : true : false}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={4}>
                                    < Button className={"global_button"} variant="contained"
                                        primary={true}
                                        icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                        onClick={(event) => {
                                            this.props.actions.resetDocuments(x.fileinx)
                                        }}
                                    ><Icon iclass={'material-icons'} iname={'delete_forever'} /> {window.t.en('Remove')}</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                        </div>
                        : ''
                })
            }
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
        </Container>
    }

    apiUpload() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    < Button className={"global_button"}
                        secondary={true}
                        label={"API Document"}
                        icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                        onClick={(event) => {
                            this.props.actions.addBlankUpload()
                        }}
                    ><Icon iclass={'material-icons'} iname={'file_upload'} /> API Document</Button>
                </Col>
                {
                    this.props.state.documents.map((x, i) => {
                        return <Col xl={this.props.colsize ? this.props.colsize : 12}>
                            {
                                x.preview
                                    ? <Iframe url={x.preview}
                                        width="90%"
                                        height="300px"
                                        id={'receipt_' + x.filename}
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        allowFullScreen />
                                    : <Dropzone
                                        accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.msg'}
                                        style={styles.dropZone}
                                        hintText={'Upload'}
                                        onDrop={(files) => {
                                            //component.actions.toggleDropzoneVisibility()
                                        }}
                                        onDropAccepted={(files) => {
                                            this.props.actions.uploadAPI(files, x.fileinx, this.props.prefix)
                                            ///this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)

                                        }}
                                        onDropRejected={(files) => {
                                            //console.log("YYYYY",files)
                                            this.props.actions.resetDocuments(x.fileinx)
                                        }}
                                    >
                                        <p style={styles.watermark}>
                                            Drag and drop file here, or click to select file to upload.
                                        </p>
                                    </Dropzone>
                            }
                        </Col>
                    })
                }

            </Row>
        </Container>
    }

    render() {
        return (
            this.props.api
                ? this.apiUpload()
                : this.props.rn ? this.getRN() : this.getView(this.props.inx)
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.upload,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, routerActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadComponent)