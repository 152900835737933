export const SET_STR_COMPONENT_INPUT_FIELD_VALUE = 'SET_STR_COMPONENT_INPUT_FIELD_VALUE'
export const SET_STR_COMPONENT_INPUT_FIELD_LIST_VALUE = 'SET_STR_COMPONENT_INPUT_FIELD_LIST_VALUE'
export const SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST = 'SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST'
export const GET_COMPONENT_CREATE_STR_DATA_FIELD_INPUT_LIST = 'GET_COMPONENT_CREATE_STR_DATA_FIELD_INPUT_LIST'
export const STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT = 'STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT'

export const GET_COMPONENT_CREATE_STR_RATECARD_LIST = 'GET_COMPONENT_CREATE_STR_RATECARD_LIST'
export const GET_COMPONENT_CREATE_STR_RATECARD_VEHICLE_LIST = 'GET_COMPONENT_CREATE_STR_RATECARD_VEHICLE_LIST'
export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST'
export const STR_COMPONENT_RESET_FORM = 'STR_COMPONENT_RESET_FORM'
export const STR_COMPONENT_SET_CURRENT_TAB_VIEW = 'STR_COMPONENT_SET_CURRENT_TAB_VIEW'
export const SET_STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT = 'SET_STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT'
export const CONFIRM_WORKFLOW_CRAETE_STR_QUOTATION_HEADER_SELECTION = 'CONFIRM_WORKFLOW_CRAETE_STR_QUOTATION_HEADER_SELECTION'