import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as _ from 'lodash'
import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
import TextField from '@mui/material/TextField'


import { Table } from '@mui/material'
import QuoteItems from '../../WorkflowPOHeader/QuoteItems/component'
import Loading from '../../Loading/component'

import PdfViewer from '../../PDF/component'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import MultipleMaintenance from '../../../controls/multiple_maintenance'
import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'

import * as styles from './styles'
import * as actions from './actions'

class DOAGenericApproval extends Component {
     componentDidMount() {
        this.props.actions.resetComponent()
    //     this.props.actions.setValue('issupplier_correct', false)
    }


    renderView(data) {
        return <div>
            <Container style={{ margin: 20 }} className="custom-container">
                    <Row>
                    <Col xl={12} style={{ marginTop: -25 }}>
                        <TextField
                            id={'txtcomments'}
                            variant="outlined" 
                            label={'Enter approval comments'}
                            fullWidth={true}
                            multiLine={true}
                            value={this.props.state.comments}
                            onChange={(event, value) => {
                                this.props.actions.setValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    }

    quoteItems(vehicle, quotations) {
        const maint = vehicle.maintenancetransaction
        const licenseplateno = vehicle.licenseplateno
        return <Card 
        title={'Quotation Items'}
        description={'Captured quotation items'}
        content={
            <div>
            {quotations.map(quotation => { 
            return <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4} style={{marginTop:'15px'}}>
                       <b>Supplier Name:</b> {quotation.supplier_name}
                    </Col>
                    <Col xl={4} style={{marginTop:'0px'}}>
                    <b>Supplier Quote Number:</b> {quotation.quotenumber}
                    </Col>
                    <Col xl={4} style={{marginTop:'0px'}}>
                    <b>Supplier Quote Date:</b> {quotation.quotedate}
                    </Col>
                    <Col xl={12}>
                        <PdfViewer
                            key={'document_viewer_uploaded_quotes'}
                            name={'De-fleet'}
                            workflow={'defleet'}
                            task={'tsk_defleet_upload_quotes'}
                            documents={quotation.documents.filter(d => d.inx === quotation.id)}
                            edit={false}
                            workflow_queue_data={null}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                    <Table className="table table-hover text-nowrap mb-0" style={{width: '100% !important'}}>
                <colgroup>
                    <col width="200px" />
                    <col width="300px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                </colgroup>
                <thead>
                    <tr>
                        <th style={{width: '30% !important'}}>Item Category</th>
                        <th style={{width: '30% !important'}}>Description</th>
                        <th style={{width: '5% !important'}}>Quantity</th>
                        <th style={{width: '5% !important'}}>Unit Price </th>
                        <th style={{width: '5% !important'}}>Total</th>
                        <th style={{width: '5% !important'}}>Savings</th>
                        <th style={{width: '5% !important'}}>Discount(%)</th>
                        <th style={{width: '5% !important'}}>Discount</th>
                        <th style={{width: '5% !important'}}>VAT</th>
                        <th style={{width: '5% !important'}}>Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        quotation.quotation.map(x => {
                            return <tr>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Class}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Description}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Quantity}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Price}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Total}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Savings}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Discount}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.DiscountAmount}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.VAT}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Amount}</div></td>
                            </tr>
                        })
                    }
                    <tr style={{background: '#DFDFDF'}}>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '150px !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '250px !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.quotation, (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.quotation, (d) => { return parseFloat((d['Savings']) ? d['Savings'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.quotation, (d) => { return parseFloat((d['DiscountAmount']) ? d['DiscountAmount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.quotation, (d) => { return parseFloat((d['VAT']) ? d['VAT'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.quotation, (d) => { return parseFloat((d['Amount']) ? d['Amount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                </tbody>
            </Table>
                    </Col>
                </Row>
            </Container>
        })}
            </div>
            } 
        />
    }

    getLayout() {
        var step1 = this.data.doa ? this.data.doa : this.data.length ? this.data[0].step1 : this.data.step1
        var supplier =  this.data.doa ? this.data.doa : this.data.length ? this.data[0].step3 ? this.data[0].step3.data.selectedSupplierDetails.data[0]  : this.data.step1 : this.data.step1
        return  <div>
                <ViewVehicle key={step1.vehicle_id} id={step1.vehicle_id} /> 
                {/* <div>
                        {this.data.length ? this.quoteItems(this.data[0].step3.vehicle, this.data[0].step3.quotations) : '' }
                  </div> */}
                {/* <ViewSupplier key={supplier.supplier_id} id={supplier.supplier_id} /> */}
                <MultipleMaintenance key={this.taskno} id={this.taskno} />
                <Card
                    title={this.title}
                    subtitle={'Please review and approve'}
                    content={this.renderView()}
                />
            </div>
    }

    render() {
        this.data = null
        try {
            //console.log('this.props.workflow_queue_data',this.props.workflow_queue_data)
            //console.log('this.props.workflow_queue_data',this.props.workflow_queue_data.workflow_queue_id)
            this.taskno = this.props.workflow_queue_data.workflow_queue_id

            this.title = this.props.workflow_queue_data.workflow_task.title
            this.data = this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        
        return this.data
            ?  this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.doaGenericApproval
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DOAGenericApproval)