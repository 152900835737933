export const contacttypes = [{ text: 'Fleet Manager', value: 1 }
    , { text: 'Fleet Accountant', value: 2 }, { text: 'Director', value: 2 },
]
export const addresstypes = [{ text: 'Physical', value: 1 }, { text: 'Postal', value: 2 }]
export const address = []
export const contacts = []
export const products = []
export const contacttype_data = []
export const suppliertype_data = []
export const businesstype_data = []
export const paymentterm_data = []
export const discountterm_data = []
export const suppliergroup_data = []
export const financialinstitution_data = []
export const fundername = ''
export const financialinstitution_id = null
export const product_data = []
export const description = ''
export const product = ''
export const product_id = null
export const empty = true
export const suppliername = ''
export const tradingname = ''
export const accountnumber = ''
export const registrationnumber = ''
export const vatnumber = ''
export const suppliertype = ''
export const suppliergroup = ''
export const businesstype_id = null
export const businesstype = ''
export const monthlylimit = ''
export const auth_signatory = false
export const contacttype = ''
export const contacttype_id = null
export const bankname = ''
export const bankaccountname = ''
export const bankaccountnumber = ''
export const bankbranchnumber = ''
export const paymentterm = ''
export const paymentterm_id = null
export const discount_fktext = ''
export const discountterm = ''
export const discountterm_id = null
export const proof_of_insurance_recieved = false
export const service_provider_agreement_recieved = false
export const ethical_purchasing_recieved = false
export const interest_decleration_recieved = false
export const signed_proc_fraud_policies_recieved = false
export const confirmation_banking_details_recieved = false
export const beecertificatereceived = false
export const taxclearancecertificatereceived = false
export const landlords_waiver_recieved = false
export const company_reg_document_recieved = false

export const fields = {
    landlords_waiver_recieved: {
        errorText: 'Landlords Waiver on File is required!',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.landlords_waiver_recieved",
    },
    proof_of_insurance_recieved: {
        errorText: 'Proof of Insurance / Insurance Schedule is required!',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.proof_of_insurance_recieved",
    },
    beecertificatereceived: {
        errorText: 'Valid B-BBEE Certificate / Affidavit is required!',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.beecertificatereceived",
    },
    taxclearancecertificatereceived: {
        errorText: 'Valid Tax Clearance Certificates / Valid PIN is required!',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.taxclearancecertificatereceived",
    },
    company_reg_document_recieved: {
        errorText: 'Company Registration Document is required!',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.company_reg_document_recieved",
    },

    documents: {
        errorText: 'Please add a document and select document type',
        data: false,
        valid: false,
        empty: true,
        popup: true,
        type: 'documents',
    },

    telephone: {
        errorText: 'Please input Contact Number',
        state: 'getState().supplierMasterCapture.contacts.length > 0 ? getState().supplierMasterCapture.contacts : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['telephone']
    },
    email: {
        errorText: 'Please input Contact Email',
        state: 'getState().supplierMasterCapture.contacts.length > 0 ? getState().supplierMasterCapture.contacts : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['email'],
        validations: [{'schema':'email', 'required':true, 'maxLength':null, 'errorText': 'Please input a valid email'}]
    },
    lastname: {
        errorText: 'Please input Contact Last Name',
        state: 'getState().supplierMasterCapture.contacts.length > 0 ? getState().supplierMasterCapture.contacts : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['lastname']
    },
    firstname: {
        errorText: 'Please input Contact First Name',
        state: 'getState().supplierMasterCapture.contacts.length > 0 ? getState().supplierMasterCapture.contacts : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['firstname']
    },
    contacttype: {
        errorText: 'Please input Contact Type',
        state: 'getState().supplierMasterCapture.contacts.length > 0 ? getState().supplierMasterCapture.contacts : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['contacttype']
    },
    contact: {
        errorText: 'Please add at least one Contact',
        state: 'getState().supplierMasterCapture.contacts.length > 0',
        data: false,
        valid: false,
        empty: true,
        popup: true,
    },

    addressPostalCode: {
        errorText: 'Please input Address Postal Code',
        state: 'getState().supplierMasterCapture.address.length > 0 ? getState().supplierMasterCapture.address : true',
        data: false,
        valid: true,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['line4'],
        validations : [{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Address Postal Code'
        }]
    },
    addressLine3: {
        errorText: 'Please input Address Line 3',
        state: 'getState().supplierMasterCapture.address.length > 0 ? getState().supplierMasterCapture.address : true',
        data: false,
        valid: true,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['line3']
    },
    addressLine2: {
        errorText: 'Please input Address Line 2',
        state: 'getState().supplierMasterCapture.address.length > 0 ? getState().supplierMasterCapture.address : true',
        data: false,
        valid: true,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['line2']
    },
    addressLine1: {
        errorText: 'Please input Address Line 1',
        state: 'getState().supplierMasterCapture.address.length > 0 ? getState().supplierMasterCapture.address : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['line1']
    },
    addressType: {
        errorText: 'Please select Address Type',
        state: 'getState().supplierMasterCapture.address.length > 0 ? getState().supplierMasterCapture.address : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['addresstype']
    },
    address: {
        errorText: 'Please add at least one address',
        state: 'getState().supplierMasterCapture.address.length > 0',
        data: false,
        valid: false,
        empty: true,
        popup: true,
    },
    monthlylimit: {
        errorText: 'Please enter Monthly Limit',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.monthlylimit",
        validations : [{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid  Monthly Limit'
        },
        {
            'schema': 'greaterThanZero',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid  Monthly Limit greater than zero'
        }]
    },
    discount: {
        errorText: 'Please enter Discount Percentage',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.discount_fktext",
        validations : [{
            'schema': 'decimal',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Discount Percentage'
        }]
    },
    discountterm: {
        errorText: 'Please select Discount Term',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.discountterm_id",
    },
    paymentterm: {
        errorText: 'Please select Payment Term',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.paymentterm_id",
    },

    bankaccountnumber: {
        errorText: 'Please enter Bank Account Number',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.bankaccountnumber",
        validations : [{
            'schema': 'number',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Bank Account Number'
        }]
    },
    bankbranchnumber: {
        errorText: 'Please enter Bank Branch Number',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.bankbranchnumber",
        validations : [{
            'schema': 'number',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid Bank Branch Number'
        }]
    },
    bankaccountname: {
        errorText: 'Please enter Bank Account Name',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.bankaccountname",
    },
    financialinstitution: {
        errorText: 'Please select Bank Name',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.financialinstitution_id",
    },

    productServiceDescription: {
        errorText: 'Please input Product/Service Description',
        state: 'getState().supplierMasterCapture.products.length > 0 ? getState().supplierMasterCapture.products : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['description']
    },
    productService: {
        errorText: 'Please select Product/Service',
        state: 'getState().supplierMasterCapture.products.length > 0 ? getState().supplierMasterCapture.products : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['product_id']
    },
    productServices: {
        errorText: 'Please add at least one product/service',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.products.length > 0 ",
        popup: true
    },

    suppliertype: {
        errorText: 'Please select Supplier Category',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.suppliertype_id",
    },
    businesstype: {
        errorText: 'Please select Company Type',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.businesstype_id",
    },
    vatnumber: {
        errorText: 'Please enter VAT Number',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.vatnumber",
        validations : [{
            'schema': 'integer',
            'required': true,
            'maxLength': null,
            'errorText': 'Please input a valid VAT Number'
        }]
    },
    registrationnumber: {
        errorText: 'Please enter Registration Number',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.registrationnumber",
    },
    suppliergroup: {
        errorText: 'Please enter Supplier Group',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.suppliergroup",
    },
    accountnumber: {
        errorText: 'Please enter Account Number',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.accountnumber",
    },
    tradingname: {
        errorText: 'Please enter Trading Name',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.tradingname",
    },
    suppliername: {
        errorText: 'Please enter Registered Name',
        value: false,
        valid: true,
        empty: true,
        state: "getState().supplierMasterCapture.suppliername",
    }
}