import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_TEAM_STRUCTURE_INPUT_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        case types.CLEAR_COMPONENT_TEAM_STRUCTURE_DATA:
            return {
                ...state,
                name: '',
                description: '',
                parentname: '',
                customername: ''
            }
        default:
            return state
    }
}