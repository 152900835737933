import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'

import * as styles from './styles'
import * as actions from './actions'
import moment from 'moment';
import TextField from '@mui/material/TextField'
import Upload from '../../Upload/component'
import Dropzone from 'react-dropzone'


import Button from '@mui/material/Button'
import ReactTable from "react-table"
import Icon from '../../Icon/component'

import { green, red } from '@mui/material/colors'
import { textAlign } from '../../QuoteEdit/styles'

class BillingOdoFile extends Component {
    componentDidMount() {
        this.props.actions.reset()
    }

    getForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        {this.uploadFile()}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.commentsView()}
                    </Col>
                </Row>
            </Container>
        </div>
    }

    uploadFile() {
        return <div><Card
            title={window.t.en('ODO File Import')}
            subtitle={window.t.en('Import File')}
            content={
                !this.props.state.datarows.length
                    ? <Row>
                        <Col xl={12}>
                            <Dropzone
                                accept={'.xls,.xlsx'}
                                style={styles.dropZone}
                                onDrop={(files) => {
                                    //this.props.actions.notifyUser('Checking documents...')
                                }}
                                onDropAccepted={(files) => {
                                    this.props.actions.setAcceptedDocuments(files)
                                }}
                                onDropRejected={(files) => {
                                    //this.props.actions.notifyUser('(' + files.length + ') files were rejected.')
                                    //this.props.actions.setRejectedDocuments(files, this.props.state.component.name)
                                }}
                            >
                                <p style={styles.watermark}>
                                    Drag and drop the files you would like to upload inside this box. please ensure that your are uploading the correct file format:
                                    <br />
                                    .xls and .xlsx
                                </p>
                            </Dropzone>
                        </Col>
                    </Row>
                    : <div><Row>
                        <Col xl={12}>
                                <div style={styles.fieldTitle}>
                                    {'Reset Imported File:'}
                                    <div>
                                        <Icon istyle={{ color: green[500], fontSize: '16px' }} iclass={'material-icons'} iname={'done'} />
                                        &nbsp;
                                        <strong>{'File Name:'} </strong>{this.props.state.document.filename}
                                    </div>
                                </div>
                                </Col>
                           <Col xl={12}>
                                <div style={styles.fieldContent}>< Button className={"global_button"} variant="contained"
                                    label={'Reset Upload'}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.props.actions.reset()
                                    }}
                                >{window.t.en('Reset Upload')}</Button>
                                </div>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <br></br>
                        </Col>
                    </Row>

                        {this.getRowGrid()}
                    </div>
            } /></div>
    }

    getRowGrid() {
        return <ReactTable
            key={'grid_booking_quote'}
            data={this.props.state.datarows}
            columns={this.getBulkColumns()}
            filterable={true}
            loading={this.props.state.loading}
            manual
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                    },
                }
            }}
            onFetchData={
                (state) => {
                }
            }
            minRows={1}
            showPagination={false}
            showPaginationBottom={false}
            style={{
                height: '600px'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }
    getBulkCellValue(row, inx) {
        return <label style={styles.fullWidth}>{this.props.state.datarows[row.index][inx]}</label>
    }

    getBulkColumns() {
        let header = this.props.state.datarows[0]
        let columns = header.map((x, i) => {
            return {
                meta: x,
                Header: x,
                accessor: x,
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <input
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                            width: '100%',
                            height: 25,
                            border: '1px solid #E6E6E6',
                            borderRadius: 25
                        }}
                    />
                ),
                width: x.width ? x.width : undefined,
                headerStyle: {
                    fontSize: '13px',
                    fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                    //backgroundColor: x.highlight ? styles.highlight : '',
                },
                style: {
                    verticalAlign: 'middle',
                    fontSize: 12,
                    letterSpacing: 0,
                    color: 'black',
                    fontWeight: 'normal',
                    //textAlign: x.align
                },
                Cell: (row) => (
                    this.getBulkCellValue(row, i)
                )
            }
        })
        return columns
    }
    commentsView() {
        return <div>
            <Card
                title={window.t.en('File Import Comments')}
                subtitle={window.t.en('Capture comments')}
                content={<Container style={{ margin: 20 }} className="custom-container">
                    <Row>
                        <Col xl={12} style={{ marginTop: -25 }}>
                            <TextField
                                id={'txtcomment'}
                                key={'txtcomment'}
                                label={window.t.en('Enter Comments...')}
                                fullWidth={true}
                                variant="outlined"
                                value={this.props.state.comments}
                                onChange={
                                    (event) => {
                                        this.props.actions.setValue('comments', event.target.value)
                                    }
                                }
                            /></Col>
                    </Row>
                </Container>} />
        </div>
    }
    render() {
        return this.getForm()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.billingodofile
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingOdoFile)