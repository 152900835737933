import * as actions from './actions'
import * as types from './types'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getAllUsersEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_ALL_USERS,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'firstname'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setAllUsers(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const saveUserManagementChanges = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.SAVE_USER_ASSIGNMENT_CHANGES,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.savedUserManagementChanges(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const resetUserPassword = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.RESET_USER_PASSWORD,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.userPasswordReset(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getUsersTeamEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_USER_TEAM,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user_team/search?user_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'team'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setUserTeam(result.response),
                    actions.setAllUserTeam(payload),
                    requestActions.requestSucceeded(config.system.api + '/components/user_team/search?user_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user_team/search?user_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const unlinkUserTeam = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.UNLINK_USER_TO_TEAM,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.getUserTeam(payload.user_id),                    
                    requestActions.requestSucceeded(config.system.api + '/components/user/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const saveUserData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.SAVE_USER_COMPONENT_INPUT_DATA,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.saveUserDataDone(result.response),                    
                    requestActions.requestSucceeded(config.system.api + '/components/user/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
export const getComponentListLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(ofType(
                types.GET_USER_COMPONENT_LIST_LOOKUP_DATA,
            ),
            mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.display.split('.')[0] + '/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
                .pipe(
                mergeMap((result) => [
                    actions.setComponentListLookupData(result.response, payload),                    
                    requestActions.requestSucceeded(config.system.api + '/components/user/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )