

export const GET_REVERSE_RECEIPT_LIST = 'GET_REVERSE_RECEIPT_LIST'
export const GET_DEBTORS_LIST = 'GET_DEBTORS_LIST'
export const GET_BUSINESS_LIST = 'GET_BUSINESS_LIST'
export const GET_CONTRA_ACCOUNT_LIST = 'GET_CONTRA_ACCOUNT_LIST'
export const GET_ITEM_ACCOUNT_LIST = 'GET_ITEM_ACCOUNT_LIST'

export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'
export const SET_ANY_ITEM_INPUT_VALUE = 'SET_ANY_ITEM_INPUT_VALUE'

export const SAVED_REVERSE_RECEIPT_DATA = 'SAVED_REVERSE_RECEIPT_DATA'
export const SAVE_REVERSE_RECEIPT_DATA = 'SAVE_REVERSE_RECEIPT_DATA'

export const GET_REVERSE_RECEIPT_DUPLICATE_REFERENCE_CHECK = 'GET_REVERSE_RECEIPT_DUPLICATE_REFERENCE_CHECK'
export const REVERSE_RECEIPT_DUPLICATE_FOUND = 'REVERSE_RECEIPT_DUPLICATE_FOUND'
export const REVERSE_RECEIPT_NO_DUPLICATE_FOUND = 'REVERSE_RECEIPT_NO_DUPLICATE_FOUND'
