import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'

//import TextField from '@mui/material/TextField'
import TextField from '../../../controls/textField'

import Checkbox from '@mui/material/Checkbox'

import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'

import Card from '../../../controls/card'
import DateControl from '../../../controls/dateControl'

import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'
import * as styles from './styles'
import * as actions from './actions'

class POBooking extends Component {
    componentDidMount() {
        // window.glyco.log('componentWillMount')
        this.props.actions.resetComponentPoBooking()
        this.props.actions.setValidationState('poBooking')
        // this.props.actions.setValidationState(this.props.state.fields.validation)


        const type = this.props.state.header.fields.selectedPurchaseOrderType.data.value
        switch (type) {
            case 10:
                this.props.actions.setInputValue('days', 1, true)
                break;
            default:
                break;
        }
        // window.glyco.log('this.props.state.fields.header.vehicleserviceinfo')
        // window.glyco.log(this.props.state.fields.header.vehicleserviceinfo)
        // window.glyco.log(this.props.state.header.fields.selectedPurchaseOrderType)
        // if (this.props.state.header.fields.selectedPurchaseOrderType.key === 'service') {
        //     if (this.props.state.fields.header.vehicleserviceinfo.length)
        //         this.props.actions.setServiceDetails(this.props.state.fields.header.vehicleserviceinfo[0])
        // }
        // else {
        //     this.props.actions.setInputValue('servicedate', new Date())
        // }
    }

    ServiceForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Non-driveable?'}</div>
                            <div style={styles.fieldContent}>
                                <Checkbox
                                    onCheck={
                                        (event, checked) => {
                                            this.props.actions.setInputValue('drivable', checked)
                                        }
                                    } />
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtincidentdate'}
                            key={'txtincidentdate'}
                            label={'Enter Incident Date'}
                            name={'incidentdate'}
                            error={this.props.state.fields.incidentdate.value ? false : true}
                            helperText={this.props.state.fields.incidentdate.value ? '' : 'Enter incident date'}
                            value={this.props.state.fields.incidentdate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('incidentdate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtservicedate'}
                            key={'txtservicedate'}
                            label={'Select vehicle service date'}
                            name={'servicedate'}
                            error={this.props.state.fields.servicedate.value ? false : true}
                            helperText={this.props.state.fields.servicedate.value ? '' : 'Enter fitment date'}
                            value={this.props.state.fields.servicedate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('servicedate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtmillage'}
                                label={'Enter vehicle currrent odometer'}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.millage.value && this.props.state.fields.millage.value >= this.closingodometer ? this.props.state.fields.millage.value : '-') ? true : false}
                                helperText={this.props.state.fields.millage.value ? this.props.state.fields.millage.value >= this.closingodometer ? '' : 'Odometer should be more that the current odometer of ' + this.closingodometer : 'Enter vehicle currrent odometer'}
                                value={this.props.state.fields.millage.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('millage', event.target.value, (this.props.state.fields.millage.value && event.target.value >= this.closingodometer) ? true : false)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtserviceinterval'}
                                label={'Enter Next Service Interval'}
                                fullWidth={true}
                                multiLine={false}
                                error={this.props.state.fields.serviceinterval.value ? false : true}
                                helperText={this.props.state.fields.serviceinterval.value ? '' : 'Enter next service interval'}
                                value={this.props.state.fields.serviceinterval.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('serviceinterval', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtdescription'}
                                label={'Enter service description'}
                                fullWidth={true}
                                multiLine={false}
                                error={this.props.state.fields.servicedescription.value ? false : true}
                                helperText={this.props.state.fields.servicedescription.value ? '' : 'Enter service description'}
                                value={this.props.state.fields.servicedescription.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('servicedescription', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField variant="outlined"
                                id={'txtcomments'}
                                label={'Enter comments'}
                                fullWidth={true}
                                multiLine={false}
                                error={false}
                                helperText={this.props.state.fields.comments.value ? '' : 'Enter comments'}
                                value={this.props.state.fields.comments.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('comments', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>

                            <TextField variant="outlined"
                                id={'txtdays'}
                                label={'Enter number of booking days'}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.days.value ? this.props.state.fields.days.value : '-') ? true : false}
                                helperText={this.props.state.fields.days.value ? '' : 'Enter number of days in service'}
                                value={this.props.state.fields.days.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('days', event.target.value, isNaN(this.props.state.fields.days.value) ? false : true)
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    RepairsForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Non-driveable?'}</div>
                            <div style={styles.fieldContent}>
                                <Checkbox
                                    onCheck={
                                        (event, checked) => {
                                            this.props.actions.setInputValue('drivable', checked)
                                        }
                                    } />
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtincidentdate'}
                            key={'txtincidentdate'}
                            label={'Enter Incident Date'}
                            name={'incidentdate'}
                            error={this.props.state.fields.incidentdate.value ? false : true}
                            helperText={this.props.state.fields.incidentdate.value ? '' : 'Enter incident date'}
                            value={this.props.state.fields.incidentdate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('incidentdate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtservicedate'}
                            key={'txtservicedate'}
                            label='Select Repair Date'
                            name={'servicedate'}
                            error={this.props.state.fields.servicedate.value ? false : true}
                            helperText={this.props.state.fields.servicedate.value ? '' : 'Enter fitment date'}
                            value={this.props.state.fields.servicedate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('servicedate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <TextField variant="outlined"
                            id={'txtdriver'}
                            label={'Enter vehicle driver name and surname'}
                            fullWidth={true}
                            multiLine={false}
                            error={this.props.state.fields.drivername.value ? false : true}
                            helperText={this.props.state.fields.drivername.value ? '' : 'Enter vehicle driver name and surname'}
                            value={this.props.state.fields.drivername.value}
                            onChange={(event, value) => {
                                this.props.actions.setInputValue('drivername', event.target.value)
                            }}
                        />
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtmillage'}
                                label={'Enter vehicle currrent odometer'}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.millage.value && this.props.state.fields.millage.value >= this.closingodometer ? this.props.state.fields.millage.value : '-') ? true : false}
                                helperText={this.props.state.fields.millage.value ? this.props.state.fields.millage.value >= this.closingodometer ? '' : 'Odometer should be more that the current odometer of ' + this.closingodometer : 'Enter vehicle currrent odometer'}
                                value={this.props.state.fields.millage.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('millage', event.target.value, (this.props.state.fields.millage.value && event.target.value >= this.closingodometer) ? true : false)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtdescription'}
                                label={'Enter service description'}
                                fullWidth={true}
                                multiLine={false}
                                error={this.props.state.fields.servicedescription.value ? false : true}
                                helperText={this.props.state.fields.servicedescription.value ? '' : 'Enter service description'}
                                value={this.props.state.fields.servicedescription.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('servicedescription', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField variant="outlined"
                                id={'txtcomments'}
                                label={'Enter comments'}
                                fullWidth={true}
                                multiLine={false}
                                error={false}
                                helperText={this.props.state.fields.comments.value ? '' : 'Enter comments'}
                                value={this.props.state.fields.comments.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('comments', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField variant="outlined"
                                id={'txtdays'}
                                label={'Enter number of booking days'}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.days.value ? this.props.state.fields.days.value : '-') ? true : false}
                                helperText={this.props.state.fields.days.value ? '' : 'Enter number of days in service'}
                                value={this.props.state.fields.days.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('days', event.target.value, isNaN(this.props.state.fields.days.value) ? false : true)
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    TowingForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Non-driveable?'}</div>
                            <div style={styles.fieldContent}>
                                <Checkbox
                                    onCheck={
                                        (event, checked) => {
                                            this.props.actions.setInputValue('drivable', checked)
                                        }
                                    } />
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtincidentdate'}
                            key={'txtincidentdate'}
                            label='Select Incident Date'
                            name={'servicedate'}
                            error={this.props.state.fields.incidentdate.value ? false : true}
                            helperText={this.props.state.fields.incidentdate.value ? '' : 'Enter incident date'}
                            value={this.props.state.fields.servicedate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('incidentdate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtservicedate'}
                            key={'txtservicedate'}
                            label='Select vehicle tow date'
                            name={'servicedate'}
                            error={this.props.state.fields.servicedate.value ? false : true}
                            helperText={this.props.state.fields.servicedate.value ? '' : 'Enter tow date'}
                            value={this.props.state.fields.servicedate.value}
                            onSelect={(args) => {
                                this.props.actions.setInputValue('servicedate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtdriver'}
                                label={'Enter vehicle driver'}
                                fullWidth={true}
                                multiLine={false}
                                error={this.props.state.fields.drivername.value ? false : true}
                                helperText={this.props.state.fields.drivername.value ? '' : 'Enter vehicle driver'}
                                value={this.props.state.fields.drivername.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('drivername', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtmillage'}
                                label={'Enter vehicle currrent odometer'}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.millage.value && this.props.state.fields.millage.value >= this.closingodometer ? this.props.state.fields.millage.value : '-') ? true : false}
                                helperText={this.props.state.fields.millage.value ? this.props.state.fields.millage.value >= this.closingodometer ? '' : 'Odometer should be more that the current odometer of ' + this.closingodometer : 'Enter vehicle currrent odometer'}
                                value={this.props.state.fields.millage.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('millage', event.target.value, (this.props.state.fields.millage.value && event.target.value >= this.closingodometer) ? true : false)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtdescription'}
                                label={'Enter Tow Description'}
                                fullWidth={true}
                                multiLine={false}
                                error={this.props.state.fields.servicedescription.value ? false : true}
                                helperText={this.props.state.fields.servicedescription.value ? '' : 'Enter tow description'}
                                value={this.props.state.fields.servicedescription.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('servicedescription', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField
                                variant="outlined"
                                id={'txtcomments'}
                                label={'Enter comments'}
                                fullWidth={true}
                                multiLine={false}
                                error={false}
                                helperText={this.props.state.fields.comments.value ? '' : 'Enter comments'}
                                value={this.props.state.fields.comments.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('comments', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    TyresForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Non-driveable?'}</div>
                            <div style={styles.fieldContent}>
                                <Checkbox
                                    onCheck={
                                        (event, checked) => {
                                            this.props.actions.setInputValue('drivable', checked)
                                        }
                                    } />
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtincidentdate'}
                            key={'txtincidentdate'}
                            label={'Enter incident Date'}
                            name={'incidentdate'}
                            error={this.props.state.fields.incidentdate.value ? false : true}
                            helperText={this.props.state.fields.incidentdate.value ? '' : 'Enter incident date'}
                            value={this.props.state.fields.incidentdate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('incidentdate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>

                        <DateControl
                            id={'txtservicedate'}
                            key={'txtservicedate'}
                            label='Select fitment date'
                            name={'servicedate'}
                            error={this.props.state.fields.servicedate.value ? false : true}
                            helperText={this.props.state.fields.servicedate.value ? '' : 'Enter fitment date'}
                            value={this.props.state.fields.servicedate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('servicedate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtdriver'}
                                label={'Enter vehicle driver and surname'}
                                error={this.props.state.fields.drivername.value ? false : true}
                                helperText={this.props.state.fields.drivername.value ? '' : 'Enter vehicle driver'}
                                fullWidth={true}
                                value={this.props.state.fields.drivername.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('drivername', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txttyrescount'}
                                label={'Enter Number of Tyres Required'}
                                error={this.props.state.fields.tyrescount.value ? false : true}
                                helperText={this.props.state.fields.tyrescount.value ? '' : 'Enter vehicle driver'}
                                fullWidth={true}
                                multiLine={false}
                                value={this.props.state.fields.tyrescount.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('tyrescount', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtmillage'}
                                label={'Enter vehicle currrent odometer'}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.millage.value && this.props.state.fields.millage.value >= this.closingodometer ? this.props.state.fields.millage.value : '-') ? true : false}
                                helperText={this.props.state.fields.millage.value ? this.props.state.fields.millage.value >= this.closingodometer ? '' : 'Odometer should be more that the current odometer of ' + this.closingodometer : 'Enter vehicle currrent odometer'}
                                value={this.props.state.fields.millage.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('millage', event.target.value, (this.props.state.fields.millage.value && event.target.value >= this.closingodometer) ? true : false)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField variant="outlined"
                                id={'txtdescription'}
                                label={'Enter reason for new tyres'}
                                error={this.props.state.fields.servicedescription.value ? false : true}
                                helperText={this.props.state.fields.servicedescription.value ? '' : 'Enter Enter Reason'}
                                fullWidth={true}
                                multiLine={false}
                                value={this.props.state.fields.servicedescription.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('servicedescription', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField variant="outlined"
                                id={'txtcomments'}
                                label={'Enter comments'}
                                error={false}
                                helperText={this.props.state.fields.comments.value ? '' : 'Enter comments'}
                                fullWidth={true}
                                multiLine={false}
                                value={this.props.state.fields.comments.value}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('comments', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField
                                variant="outlined"
                                id={'txtdays'}
                                label={'Enter number of booking days'}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.days.value ? this.props.state.fields.days.value : '-') ? true : false}
                                helperText={this.props.state.fields.days.value ? '' : 'Enter number of days in service'}
                                value={this.props.state.fields.days.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('days', event.target.value, isNaN(this.props.state.fields.days.value) ? false : true)
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    AssessorForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Non-driveable?'}</div>
                            <div style={styles.fieldContent}>
                                <Checkbox
                                    onCheck={
                                        (event, checked) => {
                                            this.props.actions.setInputValue('drivable', checked)
                                        }
                                    } />
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtincidentdate'}
                            key={'txtincidentdate'}
                            label={'Enter incident date'}
                            name={'incidentdate'}
                            error={this.props.state.fields.incidentdate.value ? false : true}
                            helperText={this.props.state.fields.incidentdate.value ? '' : 'Enter incident date'}
                            value={this.props.state.fields.incidentdate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('incidentdate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtservicedate'}
                            key={'txtservicedate'}
                            label='Select assessment date'
                            name={'servicedate'}
                            error={this.props.state.fields.servicedate.value ? false : true}
                            helperText={this.props.state.fields.servicedate.value ? '' : 'Enter fitment date'}
                            value={this.props.state.fields.servicedate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('servicedate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'drivername'}
                                label={'Enter accessor name (optional)'}
                                fullWidth={true}
                                multiLine={false}
                                error={false}
                                helperText={this.props.state.fields.drivername.value ? '' : 'Enter accessor name'}
                                value={this.props.state.fields.drivername.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('drivername', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtmillage'}
                                label={'Enter vehicle currrent odometer'}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.millage.value && this.props.state.fields.millage.value >= this.closingodometer ? this.props.state.fields.millage.value : '-') ? true : false}
                                helperText={this.props.state.fields.millage.value ? this.props.state.fields.millage.value >= this.closingodometer ? '' : 'Odometer should be more that the current odometer of ' + this.closingodometer : 'Enter vehicle currrent odometer'}
                                value={this.props.state.fields.millage.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('millage', event.target.value, (this.props.state.fields.millage.value && event.target.value >= this.closingodometer) ? true : false)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtdescription'}
                                label={'Enter reason for assessing'}
                                fullWidth={true}
                                multiLine={false}
                                error={this.props.state.fields.servicedescription.value ? false : true}
                                helperText={this.props.state.fields.servicedescription.value ? '' : 'Enter reason'}
                                value={this.props.state.fields.servicedescription.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('servicedescription', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField variant="outlined"
                                id={'txtcomments'}
                                label={'Enter comments'}
                                fullWidth={true}
                                multiLine={false}
                                error={false}
                                helperText={this.props.state.fields.comments.value ? '' : 'Enter comments'}
                                value={this.props.state.fields.comments.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('comments', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField variant="outlined"
                                id={'txtdays'}
                                label={'Enter number of booking days'}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.days.value ? this.props.state.fields.days.value : '-') ? true : false}
                                helperText={this.props.state.fields.days.value ? '' : 'Enter number of days in service'}
                                value={this.props.state.fields.days.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('days', event.target.value, isNaN(this.props.state.fields.days.value) ? false : true)
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    PanelBeatersForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Non-driveable?'}</div>
                            <div style={styles.fieldContent}>
                                <Checkbox
                                    onCheck={
                                        (event, checked) => {
                                            this.props.actions.setInputValue('drivable', checked)
                                        }
                                    } />
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtincidentdate'}
                            key={'txtincidentdate'}
                            label={'Enter incident date'}
                            name={'incidentdate'}
                            error={this.props.state.fields.incidentdate.value ? false : true}
                            helperText={this.props.state.fields.incidentdate.value ? '' : 'Enter incident date'}
                            value={this.props.state.fields.incidentdate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('incidentdate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <DateControl
                            id={'txtservicedate'}
                            key={'txtservicedate'}
                            label='Select vehicle service date'
                            name={'servicedate'}
                            error={this.props.state.fields.servicedate.value ? false : true}
                            helperText={this.props.state.fields.servicedate.value ? '' : 'Enter fitment date'}
                            value={this.props.state.fields.servicedate.value}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('servicedate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField
                                id={'txtmillage'}
                                variant="outlined"
                                label={'Enter vehicle currrent odometer'}
                                multiLine={false}
                                fullWidth={true}
                                error={isNaN(this.props.state.fields.millage.value && this.props.state.fields.millage.value >= this.closingodometer ? this.props.state.fields.millage.value : '-') ? true : false}
                                helperText={this.props.state.fields.millage.value ? this.props.state.fields.millage.value >= this.closingodometer ? '' : 'Odometer should be more that the current odometer of ' + this.closingodometer : 'Enter vehicle currrent odometer'}
                                value={this.props.state.fields.millage.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('millage', event.target.value, (this.props.state.fields.millage.value && event.target.value >= this.closingodometer) ? true : false)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtdescription'}
                                label={'Enter service description '}
                                fullWidth={true}
                                multiLine={false}
                                error={this.props.state.fields.servicedescription.value ? false : true}
                                helperText={this.props.state.fields.servicedescription.value ? '' : 'Enter service description'}
                                value={this.props.state.fields.servicedescription.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('servicedescription', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '0px' }}>
                            <TextField variant="outlined"
                                id={'txtcomments'}
                                label={'Enter comments'}
                                fullWidth={true}
                                multiLine={false}
                                error={false}
                                helperText={this.props.state.fields.comments.value ? '' : 'Enter comments'}
                                value={this.props.state.fields.comments.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('comments', event.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div style={{ marginTop: '25px' }}>
                            <TextField variant="outlined"
                                id={'txtdays'}
                                label={'Enter number of booking days '}
                                fullWidth={true}
                                multiLine={false}
                                error={isNaN(this.props.state.fields.days.value ? this.props.state.fields.days.value : '-') ? true : false}
                                helperText={this.props.state.fields.days.value ? '' : 'Enter number of days in service'}
                                value={this.props.state.fields.days.value}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('days', event.target.value, isNaN(this.props.state.fields.days.value) ? false : true)
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }

    PurchaseOrderType(type) {
        switch (type) {
            case 1:
                return this.AssessorForm()
            case 2:
                return '<POCorporateVehicle />'
            case 3:
                return '<POFMLTransnet />'
            case 4:
                return this.PanelBeatersForm()
            case 5:
                return this.PanelBeatersForm()
            case 6:
                return this.RepairsForm()
            case 7:
                return this.ServiceForm()
            case 8:
                return '<POSTRTransnetCorporate />'
            case 9:
                return '<POSubscriptions />'
            case 10:
                return this.TowingForm()
            case 11:
                return this.TyresForm()
            default:
                return ''
        }
    }

    renderView(po) {
        return <div>
            {/*             
            {
                this.props.state.header.fields.selectedVehicle && <ViewVehicle 
                    key={this.props.state.header.fields.selectedVehicle.data ? this.props.state.header.fields.selectedVehicle.data.value : null} 
                    id={this.props.state.header.fields.selectedVehicle.data ? this.props.state.header.fields.selectedVehicle.data.value : null} 
                    min={true}
                />
            }
            {
                this.props.state.header.fields.selectedSupplier && <ViewSupplier
                    key={this.props.state.header.fields.selectedSupplier.data ? this.props.state.header.fields.selectedSupplier.data.value : null} 
                    id={this.props.state.header.fields.selectedSupplier.data ? this.props.state.header.fields.selectedSupplier.data.value : null}  
                    min={true}
                />
            } */}
            <Card
                title={po.text + ' Vehicle Booking'}
                subtitle={'Complete the form below to create booking'}
                content={this.PurchaseOrderType(po.value)}
            />
        </div>
    }

    render() {
        const selectedPurchaseOrder = this.props.state.header.fields.selectedPurchaseOrderType.data
        this.closingodometer = this.props.state.header.vehicles.length ? this.props.state.header.vehicles[0].closingodometer : 0 // ? this.props.state.spend.curr_usage : null
        return this.renderView(selectedPurchaseOrder)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBooking,
            header: {
                ...state.poHeader,
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBooking)
