import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { Container, Row, Col } from 'react-grid-system'
import Card from '../../controls/card'
import TreeSelect from '../../controls/treeselect'
import AutoComplete from '../../controls/autocomplete'

import * as actions from './actions'
import * as styles from './styles'

class UserStructure extends Component {
    
    componentDidMount() {
        this.props.actions.setInputData({prop: 'client_id', data: this.props.state.accounts.selectedAccount})
    }

    getGeneral() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'First Name:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'firstname'}
                                hintText={'Enter First Name'}
                                fullWidth={true}
                                multiLine={true}
                                value={this.props.state.firstname}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputData({prop: 'firstname', data: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Last Name:'}</div>
                        <div style={styles.fieldContent}>
                            
                            <TextField
                                variant="outlined"
                                id={'lastname'}
                                hintText={'Enter Last Name'}
                                fullWidth={true}
                                multiLine={true}
                                value={this.props.state.lastname}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputData({prop: 'lastname', data: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Job Title:'}</div>
                        <div style={styles.fieldContent}>
                            
                            <TextField
                                variant="outlined"
                                id={'jobtitle'}
                                hintText={'Enter Job Title'}
                                fullWidth={true}
                                multiLine={true}
                                value={this.props.state.jobtitle}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputData({prop: 'jobtitle', data: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Business Area:'}</div>
                        <div style={styles.fieldContent}>
                            
                            <TextField
                                variant="outlined"
                                id={'businessarea'}
                                hintText={'Enter Business Area'}
                                fullWidth={true}
                                multiLine={true}
                                value={this.props.state.businessarea}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputData({prop: 'businessarea', data: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Location:'}</div>
                        <div style={styles.fieldContent}>
                            
                            <TextField
                                variant="outlined"
                                id={'location'}
                                hintText={'Enter Location'}
                                fullWidth={true}
                                multiLine={true}
                                value={this.props.state.location}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputData({prop: 'location', data: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Is Admin:'}</div>
                        <div style={styles.fieldContent}>
                            <Checkbox
                                key={'isadmin'}
                                labelPosition='right'
                                trackStyle={{
                                    backgroundColor: 'gray'
                                }}
                                trackSwitchedStyle={{
                                    backgroundColor: 'green'
                                }}
                                checked={this.props.state.isadmin}
                                onChange={
                                    (event, isInputChecked) => {
                                        this.props.actions.setInputData({prop: 'isadmin', data: isInputChecked})
                                    }
                                }
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    }
    
    getContact() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Phone Number:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'phone'}
                                hintText={'Enter Phone Number'}
                                fullWidth={true}
                                multiLine={true}
                                value={this.props.state.phone}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputData({prop: 'phone', data: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Email Address:'}</div>
                        <div style={styles.fieldContent}>
                            
                            <TextField
                                variant="outlined"
                                id={'email'}
                                hintText={'Enter Email Address'}
                                fullWidth={true}
                                multiLine={true}
                                value={this.props.state.email}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputData({prop: 'email', data: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getSettings() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Team Is Active:'}</div>
                        <div style={styles.fieldContent}>
                            <Checkbox
                                key={'clientstatus'}
                                labelPosition='right'
                                trackStyle={{
                                    backgroundColor: 'gray'
                                }}
                                trackSwitchedStyle={{
                                    backgroundColor: 'green'
                                }}
                                checked={this.props.state.active}
                                onChange={
                                    (event, isInputChecked) => {
                                        this.props.actions.setInputData({prop: 'is_active', data: isInputChecked})
                                    }
                                }
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Send System Notification:'}</div>
                        <div style={styles.fieldContent}>
                            <Checkbox
                                key={'notify'}
                                labelPosition='right'
                                trackStyle={{
                                    backgroundColor: 'gray'
                                }}
                                trackSwitchedStyle={{
                                    backgroundColor: 'green'
                                }}
                                checked={this.props.state.active}
                                onChange={
                                    (event, isInputChecked) => {
                                        this.props.actions.setInputData({prop: 'notify', data: isInputChecked})
                                    }
                                }
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Default Account:'}</div>
                        <div style={styles.fieldContent}>
                            <AutoComplete
                                name={'client_id'}
                                title={window.t.en('Select Default Account')}
                                info={{
                                    show: false
                                }}
                                fullWidth={true}
                                fieldvalue={'client_id'}
                                displayfields={['title','name']}
                                displaywidth={['20','80']}
                                data={this.props.state.clients}
                                value={this.props.state.client}
                                onSelect={(args) => {
                                    //console.log('setInputData client',args)
                                    this.props.actions.setInputData({prop: 'client', data: args['text']})
                                    this.props.actions.setInputData({prop: 'client_id', data: args['value']})
                                    this.props.actions.setInputData({prop: 'defaultlocale', data: args['value']})
                                }}
                                onClick={() => {
                                    this.props.actions.getClientsData()
                                }}
                                onFilter={(args) => { }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Default Locale:'}</div>
                        <div style={styles.fieldContent}>
                            <AutoComplete
                                name={'locale'}
                                title={window.t.en('Select Default Locale')}
                                info={{
                                    show: false
                                }}
                                fullWidth={true}
                                fieldvalue={'locale_id'}
                                displayfields={['code','locale']}
                                displaywidth={['20','80']}
                                data={this.props.state.locales}
                                value={this.props.state.locale}
                                onSelect={(args) => {
                                    //console.log('setInputData locale',args)
                                    this.props.actions.setInputData({prop: 'locale', data: args['text']})
                                    this.props.actions.setInputData({prop: 'locale_id', data: args['value']})
                                    this.props.actions.setInputData({prop: 'defaultaccount', data: args['text']})
                                }}
                                onClick={() => {
                                    this.props.actions.getLocaleData()
                                }}
                                onFilter={(args) => { }}
                            />
                            
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getStructure() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Team Structure:'}</div>
                        <div style={styles.fieldContent}>
                            <TreeSelect
                                name={'team'}
                                title={'team'}
                                data={[{teamname: ''}]}
                                hierarchy={this.props.state.teams}
                                value={this.props.state.parentname}
                                multiple={false}
                                valuefield={'team_id'}
                                displayfield={'teamname'}
                                onSelect={(args) => {
                                    this.props.actions.setInputData({prop: 'parent_id', data: args.team_id})
                                    this.props.actions.setInputData({prop: 'parentname', data: args.teamname})
                                    this.props.actions.setInputData({prop: 'team_structure', data: [args]})
                                }}
                                onClick={() => {
                                    this.props.actions.getTeamsData()
                                }}
                                onFilter={(args) => console.log()} 
                            /> 
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Customer Structure:'}</div>
                        <div style={styles.fieldContent}>
                            <TreeSelect
                                name={'customer'}
                                title={'customer'}
                                data={[{customername: ''}]}
                                hierarchy={this.props.state.customers}
                                value={this.props.state.customername}
                                multiple={false}
                                valuefield={'customer_id'}
                                displayfield={'customername'}
                                onSelect={(args) => {
                                    //console.log('Customer Structure:',args)
                                    this.props.actions.setInputData({prop: 'customer_id', data: args.customer_id})
                                    this.props.actions.setInputData({prop: 'customername', data: args.customername})
                                    this.props.actions.setInputData({prop: 'customer_structure', data: [args]})
                                }}
                                onClick={() => {
                                    this.props.actions.getCustomerData()
                                }}
                                onFilter={(args) => console.log()} 
                            /> 
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getLayout () {
        return <>
            <Card
                title={'General Info'}
                subtitle={''}
                content={this.getGeneral()} />
            <Card
                title={'Contact Info'}
                subtitle={''}
                content={this.getContact()} />
            <Card
                title={'Settings'}
                subtitle={''}
                content={this.getSettings()} />
            <Card
                title={'Structure'}
                subtitle={''}
                content={this.getStructure()} />
        </>
    }

    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: 
        { 
            ...state.userstructure,
            accounts: {
                ...state.accounts
            } 
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserStructure)