import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import CaptureQuotationConfirm from './CaptureQuotation/confirm'
import CaptureQuotation from './CaptureQuotation/component'

import Card from './../../controls/card'
import * as actions from './actions'

class WorkflowSTRQuote extends Component {
    
    getLayout() {
        return <div>
                {this.props.mode === 'create' && <CaptureQuotation />}
                {this.props.mode === 'confirm' && <CaptureQuotationConfirm />}
            </div>
    }
    
    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.strHeaderQuote,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowSTRQuote)