import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_SUB_ACCOUNT_ACCOUNT_DATA:
            return {
                ...state,
                data: action.payload
            }
        case types.SET_SUB_ACCOUNT_ACCOUNT_CURRENT_TAB:
            return {
                ...state,
                tab: action.payload
            }
        case types.TOGGLE_CRID_VIEW_WIN:
            return {
                ...state,
                cudview: action.payload.state,
                cudview_id: action.payload.id,
                cudview_title: action.payload.title
            }
        case types.SET_COMPOENT_RELATED_GRID_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        default:
            return state
    }
}