import * as formulas from 'formulajs'
import * as lodash from 'lodash'
import moment from 'moment'
import * as types from './types'
import * as appTypes from '../App/types'
import * as quotesActions from '../WorkflowQuoteReview/actions'

export const setVehicleInput = (data) => {
    return (dispatch) => {
        dispatch(quotesActions.setVehicleInput(data))
    }
}


// export const setupWorkflowTaskInput = (component, workflow, task, workflow_queue_data) => {
//     return (dispatch, getState) => {
//         const selectedWorkflow = workflow_queue_data
//         const selectedTask = workflow_queue_data.workflow_task
//         const data = workflow_queue_data.data.data
//         const context_data = workflow_queue_data.context_data.data.data.data[0]

//         dispatch({
//             type: types.QUOTE_EDIT_SET_WORKFLOW,
//             payload: selectedWorkflow
//         })

//         dispatch({
//             type: types.RESET_QUOTE_EDIT_GRID_DATA_ROWS,
//             payload: []
//         })

//         dispatch({
//             type: types.SET_TASK,
//             payload: {
//                 ...selectedTask,
//                 workflow_task_step: selectedTask.workflow_task_step.map(x => {
//                     return {
//                         ...x,
//                         workflow_task_step_component: x.workflow_task_step_component.map(y => {
//                             if (data.length) {
//                                 const _component = data.filter(z => z.component === y.component.name)

//                                 return {
//                                     ...y,
//                                     data: _component.length
//                                         ? _component[0].data
//                                         : []
//                                 }
//                             }
//                             else {
//                                 return {
//                                     ...y,
//                                     data: []
//                                 }
//                             }
//                         })
//                     }
//                 })
//             }
//         })

//         const vehicles = context_data.data.vehicles
//         const accessory = context_data.data.accessory
//         const service = context_data.data.service
//         const sundry = context_data.data.sundry
//         const contractperiod = context_data.data.contract.contractperiod

//         const _vehicles = vehicles.map((x, i) => {
//             return {
//                 row_inx: i,
//                 accessory: x.accessory,
//                 service_list: x.service_list,
//                 sundry_list: x.sundry_list,
//                 contract: x.contract,
//                 retail: parseFloat(x.retail.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 retailprice: parseFloat(x.retail.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 vehicleprice: x.vehicleprice,
//                 discount: parseFloat(x.discount.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 nett: x.vehicleprice,
//                 nett_tot: x.nett_tot,
//                 accessory_tot: x.accessory_tot.toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 accandsun_tot: x.accandsun_tot.toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 service_tot: x.service_tot.toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 sundry_tot: x.sundry_tot.toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 finance: x.finance,
//                 maint: x.maint,
//                 tyres: x.tyres,
//                 licence: x.licence,
//                 service: x.service,
//                 adminfee: x.adminfee,
//                 maintcpk: (x.maintcpk ? x.maintcpk : 0).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 tyrecpk: (x.tyrecpk ? x.tyrecpk : 0).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 manualtotalmaintcpk: x.manualtotalmaintcpk,
//                 financeexcess: x.financeexcess,
//                 tot_access_km: x.tot_access_km,
//                 returnOnInvestmentAdj: x.returnOnInvestmentAdj,
//                 returnOnInvestment: x.returnOnInvestment,
//                 roi: x.roi,
//                 rv: x.rv,
//                 rv_amount: x.rv_amount,
//                 quotenumber: x.quotenumber,
//                 deliveryperiod: x.deliveryperiod,
//                 primerate: x.primerate.toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 deviation: x.deviation.toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 interestrate: x.interestrate.toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 monthly_excl: x.monthly_excl,
//                 monthly_vat: x.monthly_vat,
//                 monthly_incl: x.monthly_incl,
//                 monthly_str: x.monthly_str,
//                 contracttype: x.contracttype,
//                 period: x.period,
//                 distancepermonth: x.distancepermonth,
//                 contractdistance: x.contractdistance,
//                 contractperiod: x.period,
//                 icontractperiod: contractperiod,
//                 mmcode: x.mmcode,
//                 mm_code: x.mm_code,
//                 mmcode_id: x.mmcode_id,
//                 mmcode_fktext: x.mmcode_fktext,
//                 make: x.make,
//                 makemodelyear: x.makemodelyear,
//                 makemodelyear: x.makemodelyear,
//                 co2: x.co2,
//                 fueltype: x.fueltype,
//                 regyear: x.regyear,
//                 maintanance: x.maintanance,
//                 depreciation: x.depreciation,
//                 quotenumber: x.quotenumber,
//                 txtheader: x.txtheader,
//                 txtretail: x.txtretail,
//                 txtdiscount: x.txtdiscount,
//                 txtnett: x.txtnett,
//                 txtaccept: x.txtaccept,
//                 imagefilename: x.imagefilename,
//                 iscorp: x.iscorp,
//             }
//         })

//         dispatch({
//             type: types.LOAD_QUOTE_EDIT_GRID_DATA_ROWS,
//             payload: {
//                 component: component.name,
//                 data: _vehicles
//             }
//         })

//         // dispatch({
//         //     type: types.LOAD_QUOTE_EDIT_GRID_DATA_ROWS,
//         //     payload: {
//         //         component: 'surrogateitems',
//         //         data: accessory.concat(service).concat(sundry)
//         //     }
//         // })
//     }
// }


// export const deleteAccessory = (id) => {
//     return (dispatch, getState) => {
//         const surrogateitems = getState().components['surrogateheader']

//         surrogateitems.grid.data.forEach((acc, i) => {
//             let newList = acc.accessory.filter(x => x.item_id !== id)
//             dispatch({
//                 type: types.QUOTE_EDIT_DELETE_ACCESSORY_INPUT_FIELD_VALUE,
//                 payload: {
//                     accessory: newList,
//                     index: i,
//                     component: surrogateitems
//                 }
//             })
//         })
//     }
// }




// export const setAccessoryTotal = (field, index, i, value, prop) => {

//     return (dispatch, getState) => {

//             dispatch({
//                 type: types.QUOTE_EDIT_UPDATE_ACCESSORY_INPUT_FIELD_VALUE,
//                 payload: {
//                     component: field.component.name,
//                     index: index,
//                     value: value,
//                     row: i
//                 }
//             })

//     }
// }
// export const setSundries = (field, index, i, value) => {

//     return (dispatch, getState) => {
//         // const component = getState().components[field.component.name]
//         // let sundry = component.grid.data[index].sundry_list

//         // sundry[i].totalprice = value.replace(/,/g, '') //parseFloat(value.replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })


//         dispatch({
//             type: types.QUOTE_EDIT_SUNDRY_SET_FIELD_VALUE,
//             payload: {
//                 component: field.component.name,
//                 index: index,
//                 row: i,
//                 value: value,
//                 list_type: 'sundry_list'
//             }
//         })
//     }
// }

// export const setService = (field, index, i, value) => {

//     return (dispatch, getState) => {
//         // const component = getState().components[field.component.name]
//         // let service = component.grid.data[index].service_list

//         // service[i].totalprice = value.replace(/,/g, '') 


//         dispatch({
//             type: types.QUOTE_EDIT_SERVICE_SET_FIELD_VALUE,
//             payload: {
//                 component: field.component.name,
//                 index: index,
//                 row: i,
//                 value: value,
//                 list_type: 'service_list'
//             }
//         })
//     }
// }
// export const decimalFormatSundry = (field, index, i, value) => {

//     return (dispatch, getState) => {
//         //const component = getState().components[field.component.name]
//         const surrogateitems = getState().components['surrogateitems']
//         const service = surrogateitems.grid.data.filter(x => x.itemtype === 'Service')
//         const sundry = surrogateitems.grid.data.filter(x => x.itemtype === 'Sundry')

//         window.glyco.log('sundry')
//         window.glyco.log(sundry)
//         sundry[i].totalprice = parseFloat(value.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

//         const payload = {
//             component: surrogateitems.name,
//             data: service.concat(sundry)
//         }

//         dispatch({
//             type: types.QUOTE_EDIT_SUNDRY_SET_FIELD_VALUE,
//             payload: payload
//         })
//     }
// }

// export const decimalFormatService = (field, index, i, value) => {

//     return (dispatch, getState) => {
//         //const component = getState().components[field.component.name]
//         const surrogateitems = getState().components['surrogateitems']
//         const service = surrogateitems.grid.data.filter(x => x.itemtype === 'Service')
//         const sundry = surrogateitems.grid.data.filter(x => x.itemtype === 'Sundry')

//         service[i].totalprice = parseFloat(value.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

//         const payload = {
//             component: surrogateitems.name,
//             data: service.concat(sundry)
//         }

//         dispatch({
//             type: types.QUOTE_EDIT_SUNDRY_SET_FIELD_VALUE,
//             payload: payload
//         })
//     }
// }


// export const decimalFormatAcc = (field, index, i, value, prop) => {

//     return (dispatch, getState) => {
//         const component = getState().components[field.component.name]

//         const accessory = component.grid.data[index][prop]

//         accessory[i].totalprice = parseFloat(value.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

//         dispatch({
//             type: types.QUOTE_EDIT_UPDATE_ACCESSORY_INPUT_FIELD_VALUE,
//             payload: {
//                 component: field.component.name,
//                 index: index,
//                 list: accessory,
//                 list_type: prop
//             }
//         })
//     }
// }

// export const decimalFormat = (field, index, value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.QUOTE_EDIT_SET_FIELD_VALUE,
//             payload: {
//                 component: field.component.name,
//                 field: field.name,
//                 index: index,
//                 value: parseFloat(value.replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
//             }
//         })
//     }
// }

// export const setValue = (field, index, value) => {
//     return (dispatch, getState) => {

//         dispatch({
//             type: types.QUOTE_EDIT_SET_FIELD_VALUE,
//             payload: {
//                 component: field.component.name,
//                 field: field.name,
//                 index: index,
//                 value: value.replace(/,/g, '')
//             }
//         })
//     }
// }

// export const calcCpk = (field, index, distancepermonth, value) => {
//     return (dispatch, getState) => {
//         const val = value.replace(/,/g, '')
//         const cpk = parseFloat(val) / parseFloat(distancepermonth ? distancepermonth : 1)

//         dispatch({
//             type: types.QUOTE_EDIT_SET_FIELD_VALUE,
//             payload: {
//                 component: field.component.name,
//                 field: field.name,
//                 index: index,
//                 value: cpk
//             }
//         })
//     }
// }

// export const calcValues = (component) => {
//     return (dispatch, getState) => {
//         const surrogateitems = getState().components['surrogateitems']
//         //const accessory = surrogateitems.grid.data.filter(x => x.itemtype === 'Accessories')
//         //const service = surrogateitems.grid.data.filter(x => x.itemtype === 'Service')
//         //const sundry = surrogateitems.grid.data.filter(x => x.itemtype === 'Sundry')

//         const vehicles = component.grid.data.map(x => {

//             const retailprice = x.retailprice.toString().replace(/,/g, '')
//             const discount = x.discount.toString().replace(/,/g, '')

//             const rate = (parseFloat(x.primerate) + parseFloat(x.deviation))
//             const acc_tot = lodash.sumBy(x.accessory, (d) => { return parseFloat((d.totalprice.toString().replace(/,/g, '')) ? d.totalprice.toString().replace(/,/g, '') : 0) })//..toFixed(2)
//             const sun_tot = lodash.sumBy(x.sundry_list, (d) => { return parseFloat((d.totalprice.toString().replace(/,/g, '')) ? d.totalprice.toString().replace(/,/g, '') : 0) })//.toFixed(2)
//             const ser_tot = lodash.sumBy(x.service_list, (d) => { return parseFloat((d.totalprice.toString().replace(/,/g, '')) ? d.totalprice.toString().replace(/,/g, '') : 0) })
//             const disc_amount = discount
//             const nett = (parseFloat(retailprice) - parseFloat(discount))//.toFixed(2)
//             const rv_amount = (retailprice * (parseFloat(x.rv) / 100))//.toFixed(2)

//             window.glyco.log('sun_tot')
//             window.glyco.log(sun_tot)
//             window.glyco.log(ser_tot)
//             window.glyco.log(acc_tot)
//             window.glyco.log(parseFloat(sun_tot))
//             window.glyco.log(parseFloat(ser_tot))
//             window.glyco.log(parseFloat(acc_tot))

//             const fin = formulas.PMT(((parseFloat(rate) / 100) / 12), parseFloat(x.contractperiod), -(parseFloat(nett) + (parseFloat(acc_tot) + parseFloat(sun_tot))), parseInt(rv_amount), 0)//.toFixed(2)

//             window.glyco.log('fin')
//             window.glyco.log(fin)

//             const maint = (x.maintcpk * ((parseFloat(x.contractperiod) * parseInt(x.distancepermonth)) / parseInt(x.contractperiod))).toFixed(2)
//             const tyres = (x.tyrecpk * ((parseFloat(x.contractperiod) * parseInt(x.distancepermonth)) / parseInt(x.contractperiod))).toFixed(2)
//             //const service = lodash.sumBy(service, (d) => { return parseFloat((d.totalprice) ? d.totalprice : 0) })
//             const _roi = parseFloat(fin) + parseFloat(maint) + parseFloat(tyres) + parseFloat(x.licence ? x.licence : 0) + parseFloat(ser_tot) + parseFloat(x.adminfee)
//             const roi = (parseFloat(x.returnOnInvestmentAdj ? x.returnOnInvestmentAdj : 0) + (parseFloat(_roi) * (x.returnOnInvestment / 100))).toFixed(2)


//             window.glyco.log('_roi')
//             window.glyco.log(_roi)
//             window.glyco.log(roi)

//             const _str = formulas.PMT((parseFloat(rate) / 12), parseInt(x.contractperiod), -(parseFloat(retailprice)), ((parseFloat(retailprice)) * (parseFloat(x.rv) / 100)), 1)//.toFixed(2)
//             const _tot = lodash.sum([parseFloat(fin), parseFloat(maint), parseFloat(tyres), parseFloat(x.licence), parseFloat(ser_tot), parseFloat(x.adminfee), parseFloat(roi ? roi : 0)])

//             window.glyco.log('_tot')
//             window.glyco.log(_tot)
//             const financeexcess = ((parseFloat(fin) + parseFloat(ser_tot) + parseFloat(x.adminfee) + parseFloat(x.licence)) / parseFloat(x.distancepermonth)).toFixed(2)
//             const manualtotalmaintcpk = ((parseFloat(maint) + parseFloat(tyres)) / parseFloat(x.distancepermonth)).toFixed(2)

//             return {
//                 accessory: x.accessory,
//                 service_list: x.service_list,
//                 sundry_list: x.sundry_list,
//                 contract: x.contract,
//                 retail: parseFloat(x.retail.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 retailprice: parseFloat(retailprice.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 vehicleprice: nett, //parseFloat(nett.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 discount: parseFloat(disc_amount.replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 nett: nett, //parseFloat(nett.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 nett_tot: (parseFloat(nett.toString().replace(/,/g, '')) + (parseFloat(acc_tot.toString().replace(/,/g, '')) + parseFloat(sun_tot.toString().replace(/,/g, '')))).toFixed(2),
//                 accessory_tot: acc_tot,
//                 accandsun_tot: (parseFloat(acc_tot.toString().replace(/,/g, '')) + parseFloat(sun_tot.toString().replace(/,/g, ''))).toFixed(2),
//                 service_tot: ser_tot,
//                 sundry_tot: sun_tot,
//                 finance: fin,
//                 maint: maint,
//                 tyres: tyres,
//                 licence: x.licence,
//                 service: x.service,
//                 adminfee: x.adminfee,
//                 maintcpk: x.maintcpk,
//                 tyrecpk: x.tyrecpk,
//                 manualtotalmaintcpk: parseFloat(manualtotalmaintcpk),
//                 financeexcess: parseFloat(financeexcess),
//                 tot_access_km: (parseFloat(manualtotalmaintcpk) + parseFloat(financeexcess)).toFixed(2),
//                 returnOnInvestmentAdj: x.returnOnInvestmentAdj,
//                 returnOnInvestment: x.returnOnInvestment,
//                 roi: roi,
//                 rv: x.rv,
//                 rv_amount: rv_amount,
//                 quotenumber: x.quotenumber,
//                 deliveryperiod: x.deliveryperiod,
//                 primerate: x.primerate,
//                 deviation: x.deviation,
//                 interestrate: rate,
//                 monthly_excl: _tot,
//                 monthly_vat: (parseFloat(_tot) * (15 / 100)).toFixed(2),
//                 monthly_incl: (parseFloat(_tot) * (115 / 100)).toFixed(2),
//                 monthly_str: (parseFloat(_tot) * 1.4).toFixed(2),
//                 contracttype: x.contracttype,
//                 period: x.period,
//                 distancepermonth: x.distancepermonth,
//                 contractdistance: x.contractdistance,
//                 contractperiod: x.contractperiod,
//                 mmcode: x.mmcode,
//                 mm_code: x.mm_code,
//                 mmcode_id: x.mmcode_id,
//                 mmcode_fktext: x.mmcode_fktext,
//                 make: x.make,
//                 makemodelyear: x.makemodelyear,
//                 co2: x.co2,
//                 fueltype: x.fueltype,
//                 regyear: x.regyear,
//                 maintanance: x.maintanance,
//                 depreciation: x.depreciation,
//                 quotenumber: x.quotenumber,
//                 txtheader: x.txtheader,
//                 txtretail: x.txtretail,
//                 txtdiscount: x.txtdiscount,
//                 txtnett: x.txtnett,
//                 txtaccept: x.txtaccept,
//                 iscorp: x.iscorp,
//             }
//         })
//         dispatch({
//             type: types.LOAD_QUOTE_EDIT_GRID_DATA_ROWS,
//             payload: {
//                 component: component.name,
//                 data: vehicles
//             }
//         })
//     }
// }

// export const getAccessoryList = (search) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_QUOTEEDIT_ACCESSORY_LIST_LOOKUP_DATA,
//             payload: {
//                 query: search,
//             }
//         })
//     }
// }

// export const setAccessoryList = (payload) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_QUOTEEDIT_ACCESSORY_LIST_LOOKUP_DATA,
//             payload: {
//                 data: payload.data,
//             }
//         })
//     }
// }

// export const addAccessory = (accs, component) => {
//     return (dispatch, getState) => {
//         const surrogateitems = getState().components['surrogateheader']


//         let a = {
//             component_id: 161,
//             isstandard: false,
//             item_id: accs.accessory_id,
//             itemname: accs.accessoryname,
//             itemtype: "Accessories",
//             quantity: 1,
//             quotetype_id: 161,
//             retrofittable: true,
//             totalprice: accs.costprice,
//             vatable: true,
//         }
//         let obj = surrogateitems.grid.data[0].accessory.find(item => item.itemname == a.itemname)

//         let newAccessoryList = []
//         surrogateitems.grid.data.forEach((acc, i) => {
//             if (obj) {
//                 newAccessoryList = [...acc.accessory]
//                 if (i == 0) {
//                     dispatch({
//                         type: appTypes.SHOW_NOTIFICATION,
//                         payload: {
//                             title: 'System Notification',
//                             additionalText: 'Accessory Already Exist...',
//                             overflowText: getState().login.fields.email.input,
//                             autoHide: 10000,
//                             timestamp: moment().format('h:mm A')
//                         }
//                     })
//                 }
//             }
//             else {
//                 newAccessoryList = [a, ...acc.accessory]

//                 if (i == 0) {

//                     dispatch({
//                         type: appTypes.SHOW_NOTIFICATION,
//                         payload: {
//                             title: 'System Notification',
//                             additionalText: 'Accessory Added...',
//                             overflowText: getState().login.fields.email.input,
//                             autoHide: 10000,
//                             timestamp: moment().format('h:mm A')
//                         }
//                     })
//                 }

//             }
//             dispatch({
//                 type: types.QUOTE_EDIT_ADD_ACCESSORY_INPUT_FIELD_VALUE,
//                 payload: {
//                     accessory: newAccessoryList,
//                     component: surrogateitems,
//                     index: i
//                 }
//             })
//         })

//         const vehicles = component.grid.data.map(x => {

//             const retailprice = x.retailprice.toString().replace(/,/g, '')
//             const discount = x.discount.toString().replace(/,/g, '')

//             const rate = (parseFloat(x.primerate) + parseFloat(x.deviation))
//             const acc_tot = lodash.sumBy(x.accessory, (d) => { return parseFloat((d.totalprice.toString().replace(/,/g, '')) ? d.totalprice.toString().replace(/,/g, '') : 0) })//..toFixed(2)
//             const sun_tot = lodash.sumBy(x.sundry_list, (d) => { return parseFloat((d.totalprice.toString().replace(/,/g, '')) ? d.totalprice.toString().replace(/,/g, '') : 0) })//.toFixed(2)
//             const ser_tot = lodash.sumBy(x.service_list, (d) => { return parseFloat((d.totalprice.toString().replace(/,/g, '')) ? d.totalprice.toString().replace(/,/g, '') : 0) })
//             const disc_amount = discount
//             const nett = (parseFloat(retailprice) - parseFloat(discount))//.toFixed(2)
//             const rv_amount = (retailprice * (parseFloat(x.rv) / 100))//.toFixed(2)

//             window.glyco.log('sun_tot')
//             window.glyco.log(sun_tot)
//             window.glyco.log(ser_tot)
//             window.glyco.log(acc_tot)
//             window.glyco.log(parseFloat(sun_tot))
//             window.glyco.log(parseFloat(ser_tot))
//             window.glyco.log(parseFloat(acc_tot))

//             const fin = formulas.PMT(((parseFloat(rate) / 100) / 12), parseFloat(x.contractperiod), -(parseFloat(nett) + (parseFloat(acc_tot) + parseFloat(sun_tot))), parseInt(rv_amount), 0)//.toFixed(2)

//             window.glyco.log('fin')
//             window.glyco.log(fin)

//             const maint = (x.maintcpk * ((parseFloat(x.contractperiod) * parseInt(x.distancepermonth)) / parseInt(x.contractperiod))).toFixed(2)
//             const tyres = (x.tyrecpk * ((parseFloat(x.contractperiod) * parseInt(x.distancepermonth)) / parseInt(x.contractperiod))).toFixed(2)
//             //const service = lodash.sumBy(service, (d) => { return parseFloat((d.totalprice) ? d.totalprice : 0) })
//             const _roi = parseFloat(fin) + parseFloat(maint) + parseFloat(tyres) + parseFloat(x.licence ? x.licence : 0) + parseFloat(ser_tot) + parseFloat(x.adminfee)
//             const roi = (parseFloat(x.returnOnInvestmentAdj ? x.returnOnInvestmentAdj : 0) + (parseFloat(_roi) * (x.returnOnInvestment / 100))).toFixed(2)


//             window.glyco.log('_roi')
//             window.glyco.log(_roi)
//             window.glyco.log(roi)

//             const _str = formulas.PMT((parseFloat(rate) / 12), parseInt(x.contractperiod), -(parseFloat(retailprice)), ((parseFloat(retailprice)) * (parseFloat(x.rv) / 100)), 1)//.toFixed(2)
//             const _tot = lodash.sum([parseFloat(fin), parseFloat(maint), parseFloat(tyres), parseFloat(x.licence), parseFloat(ser_tot), parseFloat(x.adminfee), parseFloat(roi ? roi : 0)])

//             window.glyco.log('_tot')
//             window.glyco.log(_tot)
//             const financeexcess = ((parseFloat(fin) + parseFloat(ser_tot) + parseFloat(x.adminfee) + parseFloat(x.licence)) / parseFloat(x.distancepermonth)).toFixed(2)
//             const manualtotalmaintcpk = ((parseFloat(maint) + parseFloat(tyres)) / parseFloat(x.distancepermonth)).toFixed(2)

//             return {
//                 accessory: x.accessory,
//                 service_list: x.service_list,
//                 sundry_list: x.sundry_list,
//                 contract: x.contract,
//                 retail: parseFloat(x.retail.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 retailprice: parseFloat(retailprice.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 vehicleprice: nett, //parseFloat(nett.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 discount: parseFloat(disc_amount.replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 nett: nett, //parseFloat(nett.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 nett_tot: (parseFloat(nett.toString().replace(/,/g, '')) + (parseFloat(acc_tot.toString().replace(/,/g, '')) + parseFloat(sun_tot.toString().replace(/,/g, '')))).toFixed(2),
//                 accessory_tot: acc_tot,
//                 accandsun_tot: (parseFloat(acc_tot.toString().replace(/,/g, '')) + parseFloat(sun_tot.toString().replace(/,/g, ''))).toFixed(2),
//                 service_tot: ser_tot,
//                 sundry_tot: sun_tot,
//                 finance: fin,
//                 maint: maint,
//                 tyres: tyres,
//                 licence: x.licence,
//                 service: x.service,
//                 adminfee: x.adminfee,
//                 maintcpk: x.maintcpk,
//                 tyrecpk: x.tyrecpk,
//                 manualtotalmaintcpk: parseFloat(manualtotalmaintcpk),
//                 financeexcess: parseFloat(financeexcess),
//                 tot_access_km: (parseFloat(manualtotalmaintcpk) + parseFloat(financeexcess)).toFixed(2),
//                 returnOnInvestmentAdj: x.returnOnInvestmentAdj,
//                 returnOnInvestment: x.returnOnInvestment,
//                 roi: roi,
//                 rv: x.rv,
//                 rv_amount: rv_amount,
//                 quotenumber: x.quotenumber,
//                 deliveryperiod: x.deliveryperiod,
//                 primerate: x.primerate,
//                 deviation: x.deviation,
//                 interestrate: rate,
//                 monthly_excl: _tot,
//                 monthly_vat: (parseFloat(_tot) * (15 / 100)).toFixed(2),
//                 monthly_incl: (parseFloat(_tot) * (115 / 100)).toFixed(2),
//                 monthly_str: (parseFloat(_tot) * 1.4).toFixed(2),
//                 contracttype: x.contracttype,
//                 period: x.period,
//                 distancepermonth: x.distancepermonth,
//                 contractdistance: x.contractdistance,
//                 contractperiod: x.contractperiod,
//                 mmcode: x.mmcode,
//                 mm_code: x.mm_code,
//                 mmcode_id: x.mmcode_id,
//                 mmcode_fktext: x.mmcode_fktext,
//                 make: x.make,
//                 makemodelyear: x.makemodelyear,
//                 co2: x.co2,
//                 fueltype: x.fueltype,
//                 regyear: x.regyear,
//                 maintanance: x.maintanance,
//                 depreciation: x.depreciation,
//                 quotenumber: x.quotenumber,
//                 txtheader: x.txtheader,
//                 txtretail: x.txtretail,
//                 txtdiscount: x.txtdiscount,
//                 txtnett: x.txtnett,
//                 txtaccept: x.txtaccept,
//                 iscorp: x.iscorp,
//             }
//         })
//         dispatch({
//             type: types.LOAD_QUOTE_EDIT_GRID_DATA_ROWS,
//             payload: {
//                 component: component.name,
//                 data: vehicles
//             }
//         })



//     }
// }