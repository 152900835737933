//import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const acquireTokenSilently = async (instance, accounts) => {
    //console.log('accountsx acquireTokenSilently', accounts.length)
    if (accounts.length === 0) return null;

    const silentRequest = {
        scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
        account: accounts[0]
    };

  try {
    //console.log('accountsx silentRequest', silentRequest)
    const tokenResponse = await instance.acquireTokenSilent(silentRequest);
    //console.log('accountsx Token acquired silently:', tokenResponse);
    return tokenResponse;
  } catch (error) {
    console.error('Silent token acquisition failed:', error);
    // if (error instanceof InteractionRequiredAuthError) {
    //   try {
    //     await instance.acquireTokenRedirect(silentRequest);
    //   } catch (redirectError) {
    //     console.error('Redirect token acquisition failed:', redirectError);silentRequest
    //   }
    // }
    return null;
  }
};

export const fetchUserGroups = async (accessToken) => {
  try {
    const response = await fetch('https://graph.microsoft.com/v1.0/me/memberOf', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const data = await response.json();
    console.log('accountsxx data', data)
    return data.value;
  } catch (error) {
    console.error('Error fetching groups:', error);
    return [];
  }
};
