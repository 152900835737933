

import Icon from '../Icon/component'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'

import SUPPLIERPAYMENTMANAGEMENT from './../Custom/SUPPLIERPAYMENTMANAGEMENT/component'
import SUPPLIERPAYMENTAPPROVAL from './../Custom/SUPPLIERPAYMENTAPPROVAL/component'
import React, { Component } from 'react'
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import Card from '../../controls/card'
import AutoComplete from '../../controls/autocomplete'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import * as actions from './actions'
import * as styles from './styles'
import TextField from '@mui/material/TextField'

import * as _ from 'lodash'

class WorkflowPayment extends Component {
    componentDidMount() {
        this.props.actions.setTaskNumber(this.props.step.workflow_task_id)
        this.props.actions.getBatchNumber()
    }
    view() {      
        return <div> {
            this.props.state.batchNumber
                ? <SUPPLIERPAYMENTMANAGEMENT/>
                : <strong style={styles.box1}>{'Kindly check if your not logged in twice, if the error persist please contact system administrator.'}</strong>
        }
        </div>
    }

    render() {
        return this.view();
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowPaymentView
        }
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPayment)
