export const TOGGLE_USER_FORM = 'TOGGLE_USER_FORM'
export const TOGGLE_MANAGEMENT_FORM = 'TOGGLE_MANAGEMENT_FORM'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const SAVE_USER_DATA = 'SAVE_USER_DATA'
export const SAVE_USER_ASSIGNMENT_CHANGES = 'SAVE_USER_ASSIGNMENT_CHANGES'
export const SAVED_USER_ASSIGNMENT_CHANGES = 'SAVED_USER_ASSIGNMENT_CHANGES'
export const TOGGLE_USER_ACTIVATION = 'TOGGLE_USER_ACTIVATION'
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const USER_PASSWORD_RESET = 'USER_PASSWORD_RESET'
export const GET_USER_TEAM = 'GET_USER_TEAM'
export const SET_USER_TEAM  = 'SET_USER_TEAM'
export const UNLINK_USER_TO_TEAM = 'UNLINK_USER_TO_TEAM'
export const SET_ALL_USER_TEAM='SET_ALL_USER_TEAM'
export const TOGGLE_USER_ADMIN='TOGGLE_USER_ADMIN'
export const SET_TOGGLED_USER_ADMIN='SET_TOGGLED_USER_ADMIN'

export const CHANGE_ON_USER_COMPONENT_FORM='CHANGE_ON_USER_COMPONENT_FORM'
export const SAVE_USER_COMPONENT_INPUT_DATA='SAVE_USER_COMPONENT_INPUT_DATA'
export const GET_USER_COMPONENT_LIST_LOOKUP_DATA='GET_USER_COMPONENT_LIST_LOOKUP_DATA'
export const SET_USER_COMPONENT_LIST_LOOKUP_DATA='SET_USER_COMPONENT_LIST_LOOKUP_DATA'
export const SET_USER_COMPONENT_FIELD_INPUT_VALUE='SET_USER_COMPONENT_FIELD_INPUT_VALUE'
export const SET_USER_COMPONENT_FIELD_INPUT_AND_TEXT_VALUE='SET_USER_COMPONENT_FIELD_INPUT_AND_TEXT_VALUE'
