export const SET_EXCEL_FILE_IMPORTED_DATA_ROWS = 'SET_EXCEL_FILE_IMPORTED_DATA_ROWS'
export const GET_EXCEL_FILE_TEMPLATE_DATA_LIST = 'GET_EXCEL_FILE_TEMPLATE_DATA_LIST'

export const SET_EXCEL_FILE_TEMPLATE_DATA_LIST = 'SET_EXCEL_FILE_TEMPLATE_DATA_LIST'
export const SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA = 'SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA'

export const SET_UPLOADED_EXCEL_FILE_NAME = 'SET_UPLOADED_EXCEL_FILE_NAME'
export const SET_UPLOADED_EXCEL_FILE_BUSY = 'SET_UPLOADED_EXCEL_FILE_BUSY'

export const UPLOAD_EXCEL_FILE_DATA_DONE = 'UPLOAD_EXCEL_FILE_DATA_DONE'
export const UPLOAD_EXCEL_FILE_DATA_ERROR = 'UPLOAD_EXCEL_FILE_DATA_ERROR'

export const UPLOAD_EXCEL_FILE_DATA = 'UPLOAD_EXCEL_FILE_DATA'
export const UPD_SUPPLIER_TRANSACTION_RECON = 'UPD_SUPPLIER_TRANSACTION_RECON'
export const SET_SUPPLIER_BANK_RECON_INPUT_VALUE = 'SET_SUPPLIER_BANK_RECON_INPUT_VALUE'
export const GET_SUPPLIER_TRANSACTION_BANK_RECON = 'GET_SUPPLIER_TRANSACTION_BANK_RECON'
export const GET_SUPPLIER_TRANSACTION_BY_AMOUNT = 'GET_SUPPLIER_TRANSACTION_BY_AMOUNT'
export const SET_SUPPLIER_BANK_RECON_MATCH_TRANSACTION = 'SET_SUPPLIER_BANK_RECON_MATCH_TRANSACTION'