import * as types from './types'
import * as appTypes from '../../App/types'
import moment from 'moment'
import _ from 'lodash'
import * as formulas from 'formulajs'

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_ACTIVATION_COMPONENT_RESET_FORM,
        })
    }
}

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_ACTIVATION_COMPONENT_INPUT_FIELD_VALUE,
            payload: data
        })
    }
}

export const getCustomerData = (customer_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_STR_CUSTOMER_DATA_LIST,
            payload: {
               customer_id:customer_id
            }
        })
    }
}

export const setCustomerData = (response) => {
    //console.log('customerdatax', response.data)
    let customerdata = response.data.length ? response.data[0] : {}
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'customerdata', value:customerdata}))
        dispatch(setInputValue({prop: 'marketingtype',value: customerdata.marketingtype? customerdata.marketingtype.marketingtype : null}))
        dispatch(setInputValue({prop: 'marketingtype_id',value: customerdata.marketingtype? customerdata.marketingtype.marketingtype_id : null}))
        dispatch(setInputValue({prop:'send_marketing',value: customerdata.send_marketing}))
    }
}



export const getLookupValueData = (component, field,  search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CREATE_STR_DATA_FIELD_INPUT_LIST,
            payload: {
                field: field,
                component: component,
                query: field + '=' + search,
            }
        })
    }
}
export const setLookupValueData = (component, field,payload) => {
    return (dispatch, getState) => {

        window.glyco.log('setLookupValueData....')
        window.glyco.log(payload.data)
        const data = payload.data.map(x => {
            window.glyco.log('x')
            window.glyco.log(x)
            return {
                ...x,
                value: x[component + '_id'],
                text:  x[field] //+ ' (' + x[description] + ')'
            }
        })

        window.glyco.log(data)
        dispatch({
            type: types.SET_STR_ACTIVATION_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                prop: component + '_data',
                value: data,
            }
        })
    }
}

