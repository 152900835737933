import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Container, Row, Col } from 'react-grid-system'
import IconButton from '@mui/material/IconButton';
// import TextField from '@mui/material/TextField'
import TextField from '../../../controls/textField'
import QuoteItems from '../../WorkflowPOHeader/QuoteItems/component'
import AutoComplete from '../../../controls/autocomplete'
import DateControl from '../../../controls/dateControl'
import ViewVehicle from '../../../controls/view_vehicle'
import VehiclePlan from '../../../controls/view_vehicle plan'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Card from '../../../controls/card'
import Upload from '../../Upload/component'
import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'
import * as styles from './styles'
import * as actions from './actions'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Icon from '../../Icon/component'
import { Table } from "@mui/material";

class DefleetUploadQuotes extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.setSelectedSupplierData(null, null)
       
        this.props.actions.setValidationState('defleetUploadQuotes')
    }

    getQuotation(quotation, vattable, auto_rebill, auto_rebill_tyres) {
        return  <tr key={'quotation_inx_' + quotation.inx}>
            <td>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12} style={{margin:'5px'}}>
                            {quotation.id}
                            <Icon
                                iname="delete_forever"
                                iclass="material-icons"
                                istyle={{
                                    fontSize: '18px',
                                    color: 'red',
                                    float: 'right',
                                    fontSize: '18px'
                                }}
                                onClick={(event) => {
                                    this.props.actions.remQuotationRow(quotation.id)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} style={{marginTop:'15px'}}>
                            <AutoComplete name={'autocomplete_select_supplier_quote'}
                                title={window.t.en('Select Supplier')}
                                label={''}
                                info={{
                                    show: false
                                }}
                                fullWidth={true}
                                data={this.props.state.supplier_data}
                                //value={this.props.state.selected_supplier.supplier_name}
                                onSelect={(args) => {
                                    if (args) {
                                        let _supplier = this.props.state.supplier_data.filter(s => s.supplier_id == args.value)
                                        _supplier = _supplier.length ? _supplier[0] : {}
                                        this.props.actions.setQuotationInputValue(quotation, 'supplier', _supplier)
                                        this.props.actions.setQuotationInputValue(quotation, 'supplier_name', args.text)
                                        this.props.actions.setQuotationInputValue(quotation, 'supplier_id', args.value)
                                    }}
                                }
                                onClick={() =>
                                    this.props.actions.getSupplierListData('')
                                }
                                onFilter={(search) => {
                                    console.log('supplieronfilter',search)
                                    if (search) {
                                    this.props.actions.getSupplierListData(search)
                                    //this.props.actions.setQuotationInputValue(quotation, 'supplier_name', args.text)
                                    }
                                    else{
                                    this.props.actions.getSupplierListData(args)
                                    this.props.actions.setQuotationInputValue(quotation, 'supplier_name', '')
                                    }
                                }
                                } />
                        </Col>
                        <Col xl={4} style={{marginTop:'0px'}}>
                            <TextField
                                id={'txtquotenumber'}
                                label={'Supplier Quote Number'}
                                fullWidth={true}
                                multiLine={false}
                                variant="outlined"
                                margin="normal"
                                size={"small"}
                                value={quotation.quotenumber ? quotation.quotenumber : ''}
                                onChange={(event) => {
                                    this.props.actions.setQuotationInputValue(quotation, 'quotenumber', event.target.value)
                                }}
                            />
                        </Col>
                        <Col xl={4} style={{marginTop:'0px'}}>
                            <DateControl
                                id={'txt_quoteedate'}
                                key={'txt_quoteedate'}
                                name={'txt_quoteedate'}
                                label={window.t.en('Quote Date')}
                                field={'docke'}
                                fullWidth={true}
                                value={quotation.quotedate ? quotation.quotedate : ''}
                                onSelect={(args) => {
                                    //console.log('txt_quoteedatex',args)
                                    this.props.actions.setQuotationInputValue(quotation, 'quotedate', args)
                                }}
                                type={'date'}
                            />
                        </Col>
                        <Col xl={12}>
                             <Upload
                                colsize={4}
                                header={false}
                                inx={quotation.id}
                                key={quotation.id}
                                btnlabel={'Upload Supplier Quote'}
                                id={'upload_ctl_' + quotation.id}
                                prefix={'supplier_quote_' + quotation.id}
                                list={[{ value: 0, text: 'Supplier Attachment' }, { value: 0, text: 'Supplier Quotation' },{ value: 0, text: 'Pictures' }]}
                            /> 
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {
                                quotation.supplier_id 
                                ? <QuoteItems 
                                    px={quotation.id}
                                    vattable={quotation.supplier ? quotation.supplier.vatnumber ? true : false : true} 
                                    auto_rebill={auto_rebill} 
                                    auto_rebill_tyres={auto_rebill_tyres} 
                                    labour_rate={0} />
                                : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </td>
        </tr>
    }

    ItemsForm(data, vehicle, maint, warranty, warrantyplan, vehicleinservice, serviceplan, warrantykm, vattable, contracttype) {
        const licenseplateno = vehicle.licenseplateno
        const auto_rebill = contracttype?.indexOf('Managed Maintenance') > -1 ? true : false 
        let auto_rebill_tyres = false

        try {
            const cc = this.props.state.customercontractderivative
            const cd = cc[0].customercontractderivative
            let tot_a = Number(cd.tyrequantityfront ? cd.tyrequantityfront : 0 + cd.tyrequantityrear ? cd.tyrequantityrear : 0)
            let tot_u = Number(cd.tyresused ? cd.tyresused : 0)
            auto_rebill_tyres = tot_u >= tot_a

        } catch (error) {

        }
        return <Card
            title={window.t.en('Quotation Items')}
            description={window.t.en('Capture quote line items.')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <VehiclePlan key={vehicle.vehicle_id + '_plan'} />
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12}>
                                            <div className="pt-4">
                                                <Button variant="contained"
                                                    label={'Add Item'}

                                                    className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
                                                    primary={true}
                                                    onClick={(event) => {
                                                        this.props.actions.addQuotationRow()
                                                    }}
                                                >{window.t.en('Add Quotation')}</Button>
                                            </div>
                                            <br/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        
                                        <Table className="table" style={{border: '1px solid #DFDFDF'}}>
                                            <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                                            {
                                                this.props.state.quotations.map((x,inx) => { 
                                                    return this.getQuotation({...x, inx: inx},vattable,auto_rebill,auto_rebill_tyres) 
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                        
                                    </Col>
                                </Row>
                    {
                        this.props.state.quoteitems.quotations.length ? <Row>
                            <Col xl={9}>
                            </Col>
                            <Col xl={3}>
                                <table style={{width: '100%', fontSize: '16px', fontWeight: '600'}}>
                                    <tr>
                                        <td>Vatable Total</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.quoteitems.quotations.filter(x => x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>Non Vatable Total</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.quoteitems.quotations.filter(x => !x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>VAT</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.quoteitems.quotations, (d) => { return parseFloat((d['VAT']) ? d['VAT'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.quoteitems.quotations, (d) => { return parseFloat((d['Amount']) ? d['Amount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                </table>
                            </Col>
                        </Row> : ''
                    }
                            </Container>
                        </Col>
                    </Row>
                </Container>
            } />
    }
    getSavings(price, discount) {
        let value = 0.00
        if (discount && price) {
            value = price * (discount / 100)
        }
        return parseFloat(value)
    }
    getTotal(price, savings) {
        let value = price
        if (savings && price) {
            value = price - savings
        }
        return parseFloat(value)
    }
    renderView(step) {
        let workflow_queue_data = null
        let step1 = null
        let vehicle = null
        let supplier = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.data[0].context_data.data.data.data[0]
            step1 = workflow_queue_data.step1.data
            vehicle = step1.selectedVehicleDetails.data[0]
            supplier = step.selectedSupplierDetails.data[0]

            //console.log(workflow_queue_data)
        } catch (error) {

        }
        const vehicleinservice = {}
        const warrantyplan = vehicle.mmcode.warrantyplan
        const warranty = vehicle.mmcode.warrantykm ? 'In Warranty' : 'None'
        const serviceplan = vehicle.mmcode.smplan
        const warrantykm = vehicle.mmcode.warrantykm
        let contracttype = ''
        try {
            contracttype = vehicle.customercontract[0].contracttype
        } catch (error) { }
        let vattable = false
        try {
            vattable = step.selectedSupplierDetails.data[0].vatnumber ? true : false
        } catch (error) { }
        return <Container style={{ margin: 0 }} className="custom-container">
            <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
              {/* <SupplierSpend key={supplier.supplier_id} id={supplier.supplier_id} /> */}
            <Row>
                <Col xl={12}>
                    {this.ItemsForm(step, vehicle, vehicle.maintenancetransaction, warranty, warrantyplan, vehicleinservice, serviceplan, warrantykm, vattable, contracttype)}
                </Col>
            </Row>
            
        </Container>
    }

    render() {
        let workflow_queue_data = null
        try {

            workflow_queue_data = this.props.state.capture
            window.glyco.log('renderView !2!2!!')
            window.glyco.log(workflow_queue_data)
        } catch (error) {

        }

        return workflow_queue_data ? this.renderView(workflow_queue_data) : 'Request Loading ....'
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.defleetUploadQuotes,
            capture: {
                ...state.defleetCaptureQuote
            },
            quoteitems: {
                ...state.quoteItems
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetUploadQuotes)