import * as types from './types'
import * as appTypes from '../App/types'

import moment from 'moment'

export const loadCardsData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CARD_MANAGEMENT_DATA
        })
    }
}

export const refreshCardData = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REFRESH_SELECTED_CARD_MANAGEMENT_MANDATE,
            payload: id
        })
       // dispatch(loadCardData(id))
    }
}

export const loadCardData = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CARD_MANAGEMENT_MANDATE_CATEGORY_DATA,
            payload: id
        })
        dispatch({
            type: types.GET_COMPONENT_CARD_MANAGEMENT_VEHICLE_DATA,
            payload: id
        })
        dispatch({
            type: types.GET_COMPONENT_CARD_MANAGEMENT_COSTCENTER_DATA,
            payload: id
        })
        dispatch({
            type: types.GET_COMPONENT_CARD_MANAGEMENT_DRIVER_DATA,
            payload: id
        })
    }
}

export const setCards = (response) => {
    //console.log('setCard',response)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_CARD_MANAGEMENT_DATA,
            payload: response
        })
    }
}
export const postCardData = (card_id, item_id,prop, component, checked) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.POST_COMPONENT_CARD_MANAGEMENT_DATA,
            payload: {
                type: 'UPDATE_CARD_MANDATE',
                card_id: card_id,
                item_id: item_id,
                component: component,
                checked: checked,
                prop: prop,
                done: 'Bidder offer successfully save',
            }
        })
    }
}

export const postCardDataDone = (response, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Mandate update successfully saved...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const createTeam = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CREATE_CARD
        })
    }
}
export const toggleTileView = (state) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CARD_COMPONENT_TOGGLE_VIEW_STATE,
            payload: state
        })
    }
}
export const changeTabView = (tab) => {
    //console.log('changeTabView >>>>>>>>>>>>>>>>>> ', tab)
    return (dispatch, getState) => {
        dispatch({
            type: types.CHANGE_CARD_COMPONENT_CURRENT_TAB,
            payload: tab
        })
    }
}
export const toggleMandateTab = (tab) => {
    //console.log('changeTabView >>>>>>>>>>>>>>>>>> ', tab)
    return (dispatch, getState) => {
        let curr = getState().cardManagement.mandatetab
        dispatch({
            type: types.CHANGE_CARD_COMPONENT_CURRENT_MANADATE_TAB,
            payload: curr === tab ? '' : tab
        })
    }
}
export const setCardData = (data) => {
    //console.log('setCardData', data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_CARD_MANAGEMENT_DATA,
            payload: data
        })
    }
}
export const toggleCreateView = (state) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CARD_COMPONENT_TOGGLE_CREATE_STATE,
            payload: state
        })
    }
}

export const toggleCardStatusWin = (state) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CARD_STATUS_WINDOW,
            payload: state
        })
    }
}

export const toggleTransactionView = (state) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CARD_TRANSACTION_VIEW,
            payload: !state
        })
    }
}
export const cardSettingMenus = (status, el) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CARD_SETTINGS_VIEW,
            payload: {
                cardsettingswin: status,
                cardsettingswinel: el
            }
        })
    }
}
export const toggleNotificationsList = (title) => {
    return (dispatch, getState) => {
        let curr = getState().cardManagement.cardnotificationtitle
        dispatch({
            type: types.TOGGLE_CARD_MANAGEMENT_NOTIFICATION_SECTION,
            payload: curr === title ? '' : title
        })
    }
}

export const updateCardStatus = (card_status, win_status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_NEW_CARD_STATUS,
            payload: {
                card_status: card_status,
                win_status: win_status
            }
        })
    }
}
export const toggleCardStausWin = (status, el) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CARD_STATUS_WIN,
            payload: {
                cardstatuswin: status,
                cardstatuswinel: el
            }
        })
    }
}
export const toggleCardMoreWin = (status, el) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CARD_MORE_WIN,
            payload: {
                cardmorewin: status,
                cardmorewinel: el
            }
        })
    }
}
export const toggleCardNotificationWin = (status, el) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CARD_NOTIFICATION_WIN,
            payload: {
                cardnotificationwin: status,
                cardnotificationwinel: el
            }
        })
    }
}
export const getCardTransaction = (card_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CARD_MANAGEMENT_TRANSACTION_LIST_DATA,
            payload: card_id
        })
    }
}
export const setCardTransaction = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CARD_MANAGEMENT_TRANSACTION_LIST_DATA,
            payload: payload.data
        })
    }
}
export const changeCardStatus = (card, card_status, status_reason) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.POST_COMPONENT_CARD_MANAGEMENT_DATA,
            payload: {
                type: 'UPDATE_CARD_STATUS',
                card_id: card.card_id,
                card_status: card_status,
                status_reason: status_reason,
                done: 'Card status successfully updated',
            }
        })
    }
}

export const getCardLookupData = (model,component, display, search, mandatecategory_id) => {
    return (dispatch) => {
        dispatch(setInput(display, search))
        dispatch({
            type: types.GET_CARD_LOOKUP_DATA,
            payload: {
                model: model,
                component: component,
                display: display,
                search: search,
                query: mandatecategory_id ? 'mandatecategory_id='+mandatecategory_id + '&' : ''
            }
        })
    }
}

export const setInput = (display, input) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_CARD_COMPONENT_INPUT_VAL,
            payload: {
                display: display,
                input: input
            }
        })
    }
}
export const getCardReportExcel = (Reportname) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CARD_COMPONENT_REPORT_DATA_EXCEL,
            payload: {
                type: 'GET_CARD_COMPONENT_REPORT_DATA_EXCEL',
                reportname: Reportname
            } 
        })
    }
}

export const viewDocument = (data) => {
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    //console.log('saveByteArray data',data)
    saveByteArray([data.data.pdf.Body], data.data.key)

    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'File downloading...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}