export const SET_INPUT_VALUE = 'SET_INPUT_VALUE'
export const RESET_COMPOMENT_STATE = 'RESET_COMPOMENT_STATE'
export const GET_PRE_AUTHORISATION_NUMBER = 'GET_PRE_AUTHORISATION_NUMBER'
export const SET_PRE_AUTHORISATION_NUMBER = 'SET_PRE_AUTHORISATION_NUMBER'
export const GET_USER_AUTOCOMPLETE_MENU_ITEMS = 'GET_USER_AUTOCOMPLETE_MENU_ITEMS'
export const SET_USER_AUTOCOMPLETE_MENU_ITEMS = 'SET_USER_AUTOCOMPLETE_MENU_ITEMS'

export const SET_USER_AUTOCOMPLETE_SELECTED = 'SET_USER_AUTOCOMPLETE_SELECTED'
export const GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS = 'GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS'
export const SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS = 'SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS'
export const CONFIRM_CREDITORS_PAYMENT_CAPTURE = 'CONFIRM_CREDITORS_PAYMENT_CAPTURE'