import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.GET_ASSIGNED_TASKS:
            return {
                ...state,
                ['tasks_' + action.payload.team_id]: {
                    loading: true,
                    searching: false,
                    searched: '',
                    data: [],
                    paging: action.payload.body.paging
                        ? action.payload.body.paging
                        : {
                            page: 0,
                            pages: 0,
                            pageSize: 10,
                            count: 0
                        }
                }
            }
        case types.SET_ASSIGNED_TASKS:
            return {
                ...state,
                ['tasks_' + action.payload.team_id]: {
                    ...action.payload.data,
                    searching: action.payload.search.length,
                    searched: action.payload.search,
                    loading: false
                }
            }
        default:
            return state
    }
}