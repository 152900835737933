import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'
import * as types from './types'

export const getWorkflowTimelineEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_queue_task/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: {
                    query: 'workflow_timeline',
                    search: store.value.workflowQueueTasks.value ? store.value.workflowQueueTasks.value : '',
                    team_id: store.value.workflowTimeline[payload.name].team_id,
                    user_id: store.value.workflowTimeline[payload.name].user_id,
                    workflow_queue_id: store.value.workflowTimeline[payload.name].workflow_queue_id
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflowTimeline(payload.name, result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const assignToMe = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.WORKFLOW_TIMELINE_ASSIGN_TASK_TO_ME)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_queue_task/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: {
                    query: 'workflow_task_assign_to_me',
                    task_id: payload.task_id,
                    user_id: store.value.user.user_id,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.showNotification(result.response,'Task successfully assigned to you'),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const unAssign = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.WORKFLOW_TIMELINE_UNASSIGN_TASK)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_queue_task/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: {
                    query: 'workflow_task_unassign',
                    task_id: payload.task_id,
                    user_id: payload.user_id,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.showNotification(result.response,'Task successfully assigned back to the team'),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow/query')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow/query', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

        export const getWorkflowTasks = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_WORKFLOW_TASK_STEPS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow_task/search?!order=0&workflow_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,

                },
            })
                .pipe(
                mergeMap((result) => [
                    actions.setWorkflowTasks(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow_task/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )