import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card, CardHeader, CardContent, IconButton, Collapse, CardActions } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button";

import * as actions from './actions'
import * as styles from './styles'

class _card extends Component {  
    render() {
        return this.props.error 
        ? <Card style={{padding: '0px !important'}}>
            { this.props.title && <CardHeader 
                actAsExpander={false}
                showExpandableButton={false}
                title={<div><div style={{ fontSize: "18px", color: "white" }}>{this.props.title}</div>{this.props.headerAction}</div>}
                subheader={<div style={{ fontSize: "13px", color: "white" }}>{this.props.description ? this.props.description : this.props.subtitle}</div>}
                action={ 
                   this.props.callapse 
                   ? <IconButton touch={true} style={{float: 'right', color: '#fff'}}
                    onClick={() => {
                        this.props.actions.toggleCallpaseContent(this.props.state.content_collapse)
                    }}>
                        {this.props.state.content_collapse ? <ExpandLess style={{color: '#fff'}} /> : <ExpandMore style={{color: '#fff'}} />}
                    </IconButton>
                    : ''
                  }
            >
            </CardHeader>
            }
            <CardContent>
                {
                    this.props.callapse 
                    ? <Collapse in={this.props.state.content_collapse}>
                        {this.props.content}
                    </Collapse>
                    : <div>
                        {this.props.content}
                    </div>
                }
            </CardContent>
        </Card>
        : <Card style={{padding: '0px !important'}}>
            { this.props.title && <CardHeader 
                actAsExpander={false}
                showExpandableButton={false}
                title={<div><div style={{ fontSize: "18px", color: "white" }}>{this.props.title}</div>{this.props.headerAction}</div>}
                subheader={<div style={{ fontSize: "13px", color: "white" }}>{this.props.description ? this.props.description : this.props.subtitle}</div>}
                action={ 
                   this.props.callapse 
                   ? <IconButton touch={true} style={{float: 'right', color: '#fff'}}
                    onClick={() => {
                        this.props.actions.toggleCallpaseContent(this.props.state.content_collapse)
                    }}>
                        {this.props.state.content_collapse ? <ExpandLess style={{color: '#fff'}} /> : <ExpandMore style={{color: '#fff'}} />}
                    </IconButton>
                    : ''
                  }
            >
            </CardHeader>
            }
            <CardContent style={{padding: '1px !important'}}>
                {
                    this.props.callapse 
                    ? <Collapse in={this.props.state.content_collapse}>
                        {this.props.content}
                    </Collapse>
                    : <div>
                        {this.props.content}
                    </div>
                }
            </CardContent>
            <CardActions>
                   <div>
                        {this.props.buttons}
                    </div>
            </CardActions>
        </Card>
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.controls }
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(_card)
