import React, { Component,} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Button from "@mui/material/Button";
import { Tooltip, Zoom, Card,Table,TablePagination} from '@mui/material';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'
import Pagination from '@mui/material/Pagination';
import moment from 'moment'

import * as actions from "./actions";

class Notes extends Component {
    
    getTable() {
        const note = this.props.state.releaseNote
        return <div className="custom-task-assign">
            <Card className="card-box mb-spacing-6-x2">
                <div className="divider" />
                <div className="pt-4 px-4">
                    <Table className="table table-alternate-spaced mb-0" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            <th className="text-left px-4"></th>
                            <th className="text-left"></th>
                            <th className="text-left"></th>
                            <th className="text-right"></th>
                            <th className="text-right"></th>
                            <th className="text-right"></th>
                            <th className="text-center"></th>
                        </thead>
                        <tbody style={{zIndex:1}}>
                            {note.releaseNotes ? note.releaseNotes.slice(note.page * note.rowsPerPage, note.page * note.rowsPerPage + note.rowsPerPage)
                            .map((x, ind) =>
                                    <tr key={ind}>
                                        <td className="px-4">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <div className="font-size-sm font-weight-bold">
                                                        {x.releaseversion}
                                                    </div>
                                                    <div className="font-size-sm opacity-7 text-danger">
            
                                                        {` ${moment(x.releasedate).format('Do MMMM YYYY')}`}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-left">
                                            <div>
                                                <div className="font-size-sm font-weight-bold">
                                                    {x.releasedescription}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <Tooltip TransitionComponent={Zoom} title="View Note">
                                                <Button className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                                    onClick ={
                                                        (e) =>{
                                                            e.preventDefault();
                                                            this.props.actions.setNote(x)
                                                            this.props.actions.setDialogStatus()
                                                        }
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEye}
                                                        className="font-size-sm"
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </td>
                                    </tr>                               
                            )
                                : ''
                            }

                        </tbody>
                    </Table>
                </div>
                <div className="divider mt-3" />
                <div className="card-footer p-4 d-flex justify-content-center">
                    <TablePagination className="pagination-primary" 
                        rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                        component="div"
                        count={note.releaseNotes ? note.releaseNotes.length : 0}
                        rowsPerPage={note.rowsPerPage}
                        page={note.page}
                        onChangePage={ (event,newPage) =>{
                            this.props.actions.handleChangePage(newPage)}}
                        onChangeRowsPerPage={(event)=>{
                            this.props.actions.handleChangeRowsPerPage(event.target.value)}}
                    />
                </div>
            </Card>
        </div>

    }

    render() {
        return this.getTable();
    }
}

const mapStateToProps = (state) => {
    return { 
       state : {
           ...state
       }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Notes)