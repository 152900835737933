export const CREATE_CARD = 'CREATE_CARD'
export const CARD_COMPONENT_TOGGLE_VIEW_STATE = 'CARD_COMPONENT_TOGGLE_VIEW_STATE'
export const CARD_COMPONENT_TOGGLE_CREATE_STATE = 'CARD_COMPONENT_TOGGLE_CREATE_STATE'
export const GET_COMPONENT_CARD_MANAGEMENT_DATA = 'GET_COMPONENT_CARD_MANAGEMENT_DATA'
export const SET_COMPONENT_CARD_MANAGEMENT_DATA = 'SET_COMPONENT_CARD_MANAGEMENT_DATA'
export const POST_COMPONENT_CARD_MANAGEMENT_DATA = 'POST_COMPONENT_CARD_MANAGEMENT_DATA'
export const TOGGLE_CARD_STATUS_WINDOW = 'TOGGLE_CARD_STATUS_WINDOW'
export const SET_NEW_CARD_STATUS = 'SET_NEW_CARD_STATUS'
export const TOGGLE_CARD_STATUS_WIN = 'TOGGLE_CARD_STATUS_WIN'
export const TOGGLE_CARD_MORE_WIN = 'TOGGLE_CARD_MORE_WIN'
export const TOGGLE_CARD_NOTIFICATION_WIN = 'TOGGLE_CARD_NOTIFICATION_WIN'
export const TOGGLE_CARD_TRANSACTION_VIEW = 'TOGGLE_CARD_TRANSACTION_VIEW'
export const TOGGLE_CARD_SETTINGS_VIEW = 'TOGGLE_CARD_SETTINGS_VIEW'
export const CHANGE_CARD_COMPONENT_CURRENT_TAB = 'CHANGE_CARD_COMPONENT_CURRENT_TAB'
export const TOGGLE_CARD_MANAGEMENT_NOTIFICATION_SECTION = 'TOGGLE_CARD_MANAGEMENT_NOTIFICATION_SECTION'
export const CHANGE_CARD_COMPONENT_CURRENT_MANADATE_TAB = 'CHANGE_CARD_COMPONENT_CURRENT_MANADATE_TAB'
export const GET_CARD_MANAGEMENT_TRANSACTION_LIST_DATA = 'GET_CARD_MANAGEMENT_TRANSACTION_LIST_DATA'
export const SET_CARD_MANAGEMENT_TRANSACTION_LIST_DATA = 'SET_CARD_MANAGEMENT_TRANSACTION_LIST_DATA'

export const REFRESH_SELECTED_CARD_MANAGEMENT_MANDATE = 'REFRESH_SELECTED_CARD_MANAGEMENT_MANDATE'
export const GET_COMPONENT_CARD_MANAGEMENT_DRIVER_DATA = 'GET_COMPONENT_CARD_MANAGEMENT_DRIVER_DATA'
export const GET_COMPONENT_CARD_MANAGEMENT_VEHICLE_DATA = 'GET_COMPONENT_CARD_MANAGEMENT_VEHICLE_DATA'
export const GET_COMPONENT_CARD_MANAGEMENT_COSTCENTER_DATA = 'GET_COMPONENT_CARD_MANAGEMENT_COSTCENTER_DATA'
export const GET_COMPONENT_CARD_MANAGEMENT_MANDATE_CATEGORY_DATA = 'GET_COMPONENT_CARD_MANAGEMENT_MANDATE_CATEGORY_DATA'
export const GET_CARD_COMPONENT_REPORT_DATA_EXCEL = 'GET_CARD_COMPONENT_REPORT_DATA_EXCEL'

export const GET_CARD_LOOKUP_DATA = 'GET_CARD_LOOKUP_DATA'
export const SET_CARD_LOOKUP_DATA = 'SET_CARD_LOOKUP_DATA'
export const SET_CARD_COMPONENT_INPUT_VAL = 'SET_CARD_COMPONENT_INPUT_VAL'