import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_COSTCENTRE_CHANGE_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.RESET_COMPONENT_COSTCENTRE_CHANGE_FORM:
            return {
                ...state,
                new_registration: null,
                customer_data: [],
                costcentre: '',
                costcentre_id : '',
                customer_id: '',
                costcentrechangedate : '',
                fields:props.fields,
            }
        default:
            return state
    }
}