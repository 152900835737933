import * as actions from "./actions";
import * as progressBarActions from "../ProgressBar/actions";
import * as requestActions from "../Request/actions";
import * as routerActions from "../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../Request/types";
import * as config from "../../config";

export const getComponentSchema = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_COMPONENT_RELATED_SCHEMA),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/" + payload.component + "/schema",
        method: "GET",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
        },
      }).pipe(
        mergeMap((result) => [
          actions.setComponentSchema(payload.component, result.response),
          actions.getComponentRelatedRelationships(
            payload.component,
            payload.id,
            payload.stop
          ),
          progressBarActions.progress(false),
          requestActions.requestSucceeded(
            config.system.api + "/components/component?client_id=2&menu=true",
            payload.response
          ),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const getComponentRelatedRelationships = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_COMPONENT_RELATED_RELATIONSHIPS),
    mergeMap(({ payload }) =>
      ajax$({
        url:
          config.system.api + "/components/" + payload.component + "/related",
        method: "GET",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
        },
      }).pipe(
        mergeMap((result) => [
          actions.setComponentRelationship(payload.component, result.response),
          actions.getComponentData(payload.component, payload.id),
          progressBarActions.progress(false),
          requestActions.requestSucceeded(
            config.system.api + "/components/component?client_id=2&menu=true",
            payload.response
          ),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const getComponentData = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_RELATED_FORM_COMPONENT_DATA),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/" + payload.component + payload.query,
        method: "GET",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "client-id": store.value.accounts.selectedAccount,
          'related': 'all',
        },
      }).pipe(
        mergeMap((result) => [
          actions.setComponentData(
            payload.component,
            result.response,
            payload.id,
            payload.surrogate
          ),
          progressBarActions.progress(false),
          requestActions.requestSucceeded(
            config.system.api + "/components/component?client_id=2&menu=true",
            payload.response
          ),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

  export const getComponentFieldLookupDataContact = (
    action$,
    store,
    { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
  ) =>
    action$.pipe(
      ofType(types.GET_COMPONENT_CONTACT_LOOKUP_DATA),
      delay(0),
      mergeMap(({ payload }) =>
        ajax$({
          url:
            config.system.api +
            "/components/customercontact/search" + payload.query,
          method: "GET",
          headers: {
              tokenx: store.value.user.user_id,
            auth: store.value.cognito.authenticateUser.token,
            salt: store.value.cognito.authenticateUser.jwt,
            datetime: store.value.cognito.authenticateUser.datetime,
            "client-id": store.value.accounts.selectedAccount,
            related: "contact", 
            order: payload.field.search ? payload.field.search : "",
          },
        }).pipe(
          mergeMap((result) => [
            actions.setComponentFieldLookupDataContact(
              payload.component,
              payload.field,
              result.response
            ),
            progressBarActions.progress(false),
            requestActions.requestSucceeded(
              config.system.api +
              config.system.api +
              "/components/customercontact/search" + payload.query
            ),
          ]),
          //,startWith(progressBarActions.progress(true))
          catchError((error) =>
            of$(
              requestActions.requestFailed(
                config.system.api + "/components/component?client_id=2&menu=true",
                error
              )
            )
          ),
          //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
      ),
      catchError((error) => of$(routerActions.route("/support", false, error)))
    );
  

export const getComponentFieldLookupData = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_COMPONENT_RELATED_FIELD_LOOKUP_DATA),
    delay(0),
    mergeMap(({ payload }) =>
      ajax$({
        url:
          config.system.api +
          "/components/" +
          payload.field.component_reference.name +
          "/search" +
          payload.query,
        method: "GET",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "client-id": store.value.accounts.selectedAccount,
          related: "", //payload.related
          order: payload.field.search ? payload.field.search : "",
        },
      }).pipe(
        mergeMap((result) => [
          actions.setComponentFieldLookupData(
            payload.component,
            payload.field,
            result.response
          ),
          progressBarActions.progress(false),
          requestActions.requestSucceeded(
            config.system.api +
              "/components/" +
              payload.field.component_reference.name +
              "/search" +
              payload.query
          ),
        ]),
        //,startWith(progressBarActions.progress(true))
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const saveComponentData = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.SAVE_RELATED_FORM_COMPONENT_DATA),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/" + payload.component.name,
        method: "POST",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "client-id": store.value.accounts.selectedAccount,
          "user-id": store.value.user.user_id,
          "Content-Type": "application/json",
        },
        body: payload.data,
      }).pipe(
        mergeMap((result) => [
          actions.componentDataSaved(payload.component),
          progressBarActions.progress(false),
          requestActions.requestSucceeded(
            config.system.api +
              "/components/" +
              payload.component.name +
              "/save",
            payload.response
          ),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(actions.componentDataSaveFailed(payload.component))
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const getTreeViewData = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/customer/other", // + payload.query,
        method: "POST",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "Content-Type": "application/json",
        },
        body: payload
      }).pipe(
        mergeMap((result) => [
          actions.setTreeViewData(result.response, payload),
          progressBarActions.progress(false),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(actions.componentDataSaveFailed(payload.component))
        ),
        //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );
