import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'

import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'
import AutoComplete from '../../../controls/autocomplete'

import Checkbox from '@mui/material/Checkbox'

import Upload from '../../Upload/component'

import * as workflowTaskInputAction from '../../WorkflowTaskInput/actions'
import * as styles from './styles'
import * as actions from './actions'

class CreateMMQuote extends Component {
    componentDidMount() {
        this.props.actions.resetForm()
        this.props.actions.setValidationState('createMMQuote')
        this.props.actions.loadServiceData()
    }

    getForm() {
        // const customer = this.props.state.customer_data.filter(x => x.customer_id === this.props.state.customer_id.value)

        //console.log('setLookupValueDatax form',this.props.state.contracttype_data)
        return <div>
            <Card >
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Create Managed Maintenance Quote')}
                    subtitle={window.t.en('Complete the form below')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={4} style={styles.textboxes}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_contracttype_data_id'}
                                    key={'dll_contracttype_data_id'}
                                    hintText={'Select a Contract Type'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    dataSource={this.props.state.contracttype_data.value ?? this.props.state.contracttype_data}
                                    onSelect={(args) => {
                                        this.props.actions.setMMLookupValue('contracttype', args.value, args.text)
                                    }}
                                    onClick={() => {
                                        this.props.actions.getMMLookupValueData('contracttype', 'contracttype', 'contractdescription', 'Managed Maintenance')
                                    }}
                                    onFilter={(search) => {
                                        if (!search) {
                                            this.props.actions.setMMLookupValue('contracttype', null, '')
                                            this.props.actions.getMMLookupValueData('contracttype', 'contracttype', 'contractdescription', 'Managed Maintenance')
                                        } else {
                                            const data = this.props.state.contracttype_data.value ?? this.props.state.contracttype_data
                                            const contractType = data.find(c => c.contracttype === search)
                                            if (contractType) this.props.actions.setMMLookupValue('contracttype', contractType.contracttype_id, search)
                                        }
                                    }}
                                />
                            </Col>
                            <Col xl={4} style={styles.textboxes}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400 }}
                                    id={'dll_customer_id'}
                                    key={'dll_customer_id'}
                                    hintText={'Select Customer from the List'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    dataSource={this.props.state.customer_data.value ?? this.props.state.customer_data}
                                    onSelect={(args) => {
                                        this.props.actions.setMMLookupValue('customer', args.value, args.text)
                                    }}
                                    onClick={() => {
                                        this.props.actions.getMMLookupValueData('customer', 'hierarchyname', 'costcentre', '')
                                    }}
                                    onFilter={(search) => {
                                        if (!search) {
                                            this.props.actions.getMMLookupValueData('customer', 'hierarchyname', 'costcentre', '')
                                            this.props.actions.setMMLookupValue('customer', null, '')
                                        } else {
                                            const data = this.props.state.customer_data.value ?? this.props.state.customer_data
                                            const customer_data = data.find(c => c.hierarchyname === search)
                                            this.props.actions.getMMLookupValueData('customer', 'hierarchyname', 'costcentre', search)
                                            if (customer_data) this.props.actions.setMMLookupValue('customer', customer_data.value, search)
                                        }
                                    }}
                                />
                            </Col>
                            <Col xl={4} >
                                <div style={{ marginTop: '15px' }}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth={true}
                                        margin="normal"
                                        id={'txt_period'}
                                        hintText={'Enter contract period'}
                                        value={this.props.state.period.value}
                                        onChange={(event, value) => {
                                            this.props.actions.setMMInputValue('period', event.target.value)
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>
                                <DateControl
                                    id={'txt_startdate'}
                                    key={'txt_startdate'}
                                    label={'Select Contract Start Date'}
                                    value={this.props.state.startdate.value}
                                    onSelect={(args) => {
                                        //console.log('txt_startdate',args)
                                        this.props.actions.setMMInputValue('startdate', args)
                                        if (this.props.state.period.value)
                                            this.props.actions.setMMInputValue('enddate', moment(args).add(this.props.state.period.value, 'months').format('YYYY-MM-DD'))
                                    }}
                                    type={'date'}
                                />
                            </Col>
                            <Col xl={4}>
                                <DateControl
                                    id={'txt_enddate'}
                                    key={'txt_enddate'}
                                    label={'Select Contract End Date'}
                                    value={this.props.state.enddate.value}
                                    onSelect={(args) => {
                                        //console.log('txt_enddate',args)
                                        this.props.actions.setMMInputValue('enddate', args)
                                    }}
                                    type={'date'}
                                />
                            </Col>
                        </Row>
                        {/* <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'License Expiry Date:'}</div>
                                        <div style={styles.fieldContent}>
                                            <TextField
                                        type="date"
                                                id={'txt_regdate'}
                                                hintText='Select Date'
                                                container='inline'
                                                mode='landscape'
                                                value={this.props.state.licenseexpiry.value}
                                                defaultDate={this.props.state.licenseexpiry.value}
                                                onChange={(event, value) => {
                                                    this.props.actions.setMMInputValue('licenseexpiry', value)
                                                }}
                                            //minDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
                    </Container>
                </CardContent>
            </Card>
            <Card >
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Vehicle and Items')}
                    subtitle={window.t.en('Add vehicle details and quote items')}
                >
                </CardHeader>
                <CardContent>

                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={8}>
                                <Container style={{ margin: 0 }} className="custom-container">

                                    <Row>

                                        <Col xl={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth={true}
                                                margin="normal"
                                                id={'licenseplateno'}
                                                hintText={'Enter vehicle registration'}
                                                value={this.props.state.licenseplateno.value}
                                                onChange={(event, value) => {
                                                    this.props.actions.setMMInputValue('licenseplateno', event.target.value)
                                                }}
                                            />
                                        </Col>
                                        <Col xl={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth={true}
                                                margin="normal"
                                                id={'licensediskno'}
                                                hintText={'Enter vehicle register'}
                                                value={this.props.state.licensediskno.value}
                                                onChange={(event, value) => {
                                                    this.props.actions.setMMInputValue('licensediskno', event.target.value)
                                                }}
                                            />
                                        </Col>
                                        <Col xl={6} >
                                            <TextField
                                                variant="outlined"
                                                fullWidth={true}
                                                margin="normal"
                                                id={'engineno'}
                                                hintText={'Enter engine number'}
                                                value={this.props.state.engineno.value}
                                                onChange={(event, value) => {
                                                    this.props.actions.setMMInputValue('engineno', event.target.value)
                                                }}
                                            />
                                        </Col>
                                        <Col xl={6} >
                                            <TextField
                                                variant="outlined"
                                                fullWidth={true}
                                                margin="normal"
                                                id={'chassisno'}
                                                hintText={'Enter chassis engineno'}
                                                value={this.props.state.chassisno.value}
                                                onChange={(event, value) => {
                                                    this.props.actions.setMMInputValue('chassisno', event.target.value)
                                                }}
                                            />
                                        </Col>
                                        <Col xl={6} styles={styles.txt2} >
                                            <DateControl
                                                id={'yearmodel'}
                                                key={'yearmodel'}

                                                label={'Enter vehicle registration date'}
                                                value={this.props.state.yearmodel.value}
                                                onSelect={(args) => {
                                                    //console.log('yearmodel',args)
                                                    this.props.actions.setMMInputValue('yearmodel', args)
                                                }}
                                                type={'date'}
                                            />
                                        </Col>
                                        <Col xl={6} >
                                            <TextField
                                                variant="outlined"
                                                fullWidth={true}
                                                margin="normal"
                                                id={'odometer'}

                                                hintText={'Enter vehicle odometer'}
                                                value={this.props.state.odometer.value}
                                                onChange={(event, value) => {
                                                    this.props.actions.setMMInputValue('odometer', event.target.value)
                                                }}
                                            />
                                        </Col>
                                        <Col xl={6}>
                                            <div style={{ marginTop: '-10px' }}>
                                                <DateControl
                                                    id={'txt_enddate'}
                                                    key={'txt_enddate'}
                                                    label={'Select license expiry date'}
                                                    value={this.props.state.licenseexpiry.value}
                                                    styles={styles.txt2}
                                                    onSelect={(args) => {
                                                        //console.log('txt_enddate',args)
                                                        this.props.actions.setMMInputValue('licenseexpiry', args)
                                                    }}
                                                    type={'date'}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={6} styles={styles.txt2}>
                                            <div style={{ marginTop: '-5px' }}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'dll_contractvehiclecategory_id'}
                                                    key={'dll_contractvehiclecategory_id'}
                                                    hintText={'Select make and model'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}


                                                    discdate={'discdate'}
                                                    fieldvalue={'mmcode_id'}
                                                    displayfields={['mmcode', 'make', 'cdescription']}
                                                    displaywidth={['20', '20', '60']}
                                                    value={this.props.state.mmcode.value}

                                                    dataSource={this.props.state.mmcode_data.value ?? this.props.state.mmcode_data}
                                                    onSelect={(args) => {
                                                        //console.log('MMCode',args)
                                                        this.props.actions.setMMLookupValue('mmcode', args.value, args.text)
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getMMLookupValueData('mmcode', 'mmcode', 'makemodelyear', '')
                                                    }}
                                                    onFilter={(search) => {
                                                        if (!search) {
                                                            this.props.actions.getMMLookupValueData('mmcode', 'mmcode', 'makemodelyear', '')
                                                            this.props.actions.setMMLookupValue('mmcode', null, '')
                                                        } else {
                                                            const data = this.props.state.mmcode_data.value ?? this.props.state.mmcode_data
                                                            const mmcode = data.find(m => m.mmcode === search)
                                                            this.props.actions.getMMLookupValueData('mmcode', 'mmcode', 'makemodelyear', search)
                                                            if (mmcode) this.props.actions.setMMLookupValue('mmcode', mmcode.value, search)
                                                        }

                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={6}  >
                                            <div style={{ marginTop: '15px' }}>
                                                <AutoComplete

                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'dll_contractvehiclecategory_id'}
                                                    key={'dll_contractvehiclecategory_id'}
                                                    hintText={'Select vehicle color'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    dataSource={this.props.state.color_data.value ?? this.props.state.color_data}
                                                    onSelect={(args) => {
                                                        this.props.actions.setMMLookupValue('color', args.value, args.text)
                                                    }}
                                                    onClick={() => {
                                                        this.props.actions.getMMLookupValueData('color', 'color', 'color', '')
                                                    }}
                                                    onFilter={(search) => {
                                                        if (!search) {
                                                            this.props.actions.getMMLookupValueData('color', 'color', 'color', '')
                                                            this.props.actions.setMMLookupValue('color', null, '')
                                                        } else {
                                                            const data = this.props.state.color_data.value ?? this.props.state.color_data
                                                            const color = data.find(c => c.color === search)
                                                            this.props.actions.getMMLookupValueData('color', 'color', 'color', search)
                                                            if (color) this.props.actions.setMMLookupValue('color', color.value, search)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <Container style={{ margin: 0 }} className="custom-container">
                                                <Row style={styles.headerrow}>
                                                    <Col xl={6}>
                                                        {'Item Description'}
                                                    </Col>
                                                    <Col xl={2}>
                                                        {'Cost Price'}
                                                    </Col>
                                                    <Col xl={2}>
                                                        {'Selling Price'}
                                                    </Col>
                                                    <Col xl={2}>
                                                        {''}
                                                    </Col>
                                                </Row>
                                                {
                                                    this.props.state.mm_service_data.map((x, i) => {
                                                        return <Row style={styles.itemrow}>
                                                            <Col xl={6}>
                                                                <br />
                                                                {x.servicedescription}
                                                            </Col>
                                                            <Col xl={2}>
                                                                <br />
                                                                {x.costprice}
                                                            </Col>
                                                            <Col xl={2}>
                                                                <TextField
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    id={'sellingprice_' + i}
                                                                    hintText='Selling Price'
                                                                    container='inline'
                                                                    mode='landscape'
                                                                    value={x.sellingprice}
                                                                    onChange={(event, value) => {
                                                                        this.props.actions.setSelectedPrice(x, event.target.value)
                                                                        //this.props.actions.setMMIItemnputValue(i, event.target.value)
                                                                        this.props.actions.calTotal()
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col xl={2}>
                                                                <br />
                                                                <Checkbox
                                                                    checked={x.checked}
                                                                    tabIndex='-1'
                                                                    onChange={
                                                                        (event, checked) => {
                                                                            this.props.actions.setSelectedService(x, checked)
                                                                            //this.props.actions.addService(x, checked)
                                                                            this.props.actions.calTotal()
                                                                        }
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    })
                                                }

                                            </Container>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xl={4} style={styles.rightpanel}>
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <br />
                                            <h4>{'Items Summary'}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <table style={{ width: '100%' }}>

                                                {
                                                    this.props.state.mm_service_data.filter(x => x.checked).length
                                                        ? this.props.state.mm_service_data.filter(x => x.checked).map((x, i) => {
                                                            return <tr style={{ width: '100%', borderBottom: '1px solid gray', }}>
                                                                <td>{x.servicedescription}</td>
                                                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}>{x.sellingprice}</td>
                                                            </tr>
                                                        })
                                                        : <tr style={{ width: '100%', borderBottom: '1px solid gray', }}>
                                                            <td style={{ fontWeight: 'bold', color: 'red' }}>No Item Selected</td>
                                                            <td ></td>
                                                        </tr>
                                                }
                                            </table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <br />
                                            <h4>{'Rental Summary'}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={{ textAlign: 'right' }}>
                                                <label>Monthly Excl:</label> <label style={{ fontWeight: 'bold', width: '60px' }}>{(parseFloat(this.props.state.monthly_excl.value ? this.props.state.monthly_excl.value : 0)).toFixed(2)}</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={{ textAlign: 'right' }}>
                                                <label>VAT:</label> <label style={{ fontWeight: 'bold', width: '60px' }}>{(parseFloat(this.props.state.monthly_vat.value ? this.props.state.monthly_vat.value : 0)).toFixed(2)}</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={{ textAlign: 'right' }}>
                                                <label>Monthly Incl:</label> <label style={{ fontWeight: 'bold', width: '60px' }}>{(parseFloat(this.props.state.monthly_incl.value ? this.props.state.monthly_incl.value : 0)).toFixed(2)}</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            <Card >
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Contract Document')}
                    subtitle={window.t.en('Add supporting documents')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <br />
                                <Upload
                                    id={'upload_str'}
                                    prefix={'MM_Capture_'}
                                    header={false}
                                    list={[{ value: 0, text: 'Customer RFQ' }, { value: 0, text: 'Customer PO' }, { value: 0, text: 'Supplier Quotation' }]}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            <Card >
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Quotation Notes')}
                    subtitle={window.t.en('Add Quotation Notes')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={8}>
                                <Container style={{ margin: 0 }} className="custom-container">

                                    <Row>

                                        <Col xl={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth={true}
                                                margin="normal"
                                                id={'licenseplateno'}
                                                multiline={true}
                                                rows={5}
                                                label={'Enter Quotation Notes'}
                                                hintText={'Quotation Notes'}
                                                value={this.props.state.quotationnotes.value}
                                                onChange={(event, value) => {
                                                    this.props.actions.setMMInputValue('quotationnotes', event.target.value)
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>

        </div>
    }

    render() {
        return this.getForm()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.createMMQuote
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMMQuote)