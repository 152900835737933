import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                maintenanceclass: [],
                maintenanceitem: [],
                itemlist: [],
                loading: true,
                grid: [],
                toggle_labourrate: {
                    ...state.toggle_labourrate,
                    data: null,
                    value: false,
                    valid: false
                },
                maintenanceitemx: null,
                tyre_data: [],
                exceptions: [],
                exceptionsfound: false,
                toggle_exceptions: false,
                exception_item: '',
                counter: 0,
            }
        case types.SET_LABOURATE_VALUE:
            //console.log("action.payload >> ")
            //console.log(action.payload)
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    data: action.payload.value,
                    valid: action.payload.value ? false : true,
                    value: action.payload.value
                }
            }
        case types.TOGGLE_LABOURATE_VALUE:
            //console.log("action.payload >> ")
            //console.log(action.payload)
            return {
                ...state,
                toggle_labourrate: {
                    ...state.toggle_labourrate,
                    data: action.payload.value,
                    valid: action.payload.value,
                    value: action.payload.value
                }
            }
        case types.TOGGLE_EXCEPTIONS:
            return {
                ...state,
                toggle_exceptions: action.payload
            }
        case types.SET_EXCEPTION_ITEM:
            return {
                ...state,
                exception_item: action.payload
            }
        case types.SET_EXCEPTIONS:
            return {
                ...state,
                exceptions: action.payload.exceptions,
                exceptionsfound: action.payload.exceptionsfound,
                toggle_exceptions: action.payload.exceptionsfound,
            }
        case types.COMPONENT_GRID_LOADED:
            return {
                ...state,
                loading: action.payload.loading
            }
        case types.OPEN_QUOTE_ITEM_HISTORY:
            return {
                ...state,
                modelwin: action.payload.status,
                grid_item_inx: action.payload.inx,
            }
        case types.CLOSE_QUOTE_ITEM_HISTORY:
            return {
                ...state,
                modelwin: action.payload,
                grid_item_inx: 0,
            }
        case types.SET_SELECTED_ROW_RELATED_ITEMS_VALUES:
            return {
                ...state,
                grid_items_pricing: action.payload,
            }
        case types.TOGGLE_VEHICLE_TYRES_HISTORY_LIST:
            return {
                ...state,
                tyreshistory: action.payload
            }
        case types.TOGGLE_DRIVER_BEHAVIOUR:
            return {
                ...state,
                driverbehaviour: action.payload
            }
        case types.OPEN_VEHICLE_SERVICE_HISTORY_LIST:
            return {
                ...state,
                servicehistory: action.payload
            }
        case types.CLOSE_VEHICLE_SERVICE_HISTORY_LIST:
            return {
                ...state,
                servicehistory: action.payload
            }
        case types.ADD_QUOTE_ITEM_BLANK_ROW:
            return {
                ...state,
                grid: action.payload
            }
        case types.SET_QUOTE_ITEM_INX_INPUT_VALUE:
            return {
                ...state,
                [action.payload.inx]: {
                    ...state[action.payload.inx],
                    [action.payload.prop]: action.payload.value
                }
            }
        case types.SET_QUOTATION_ITEMS_INPUT_DATA:
            console.log('setQuotationsx red', action.payload)
            return {
                ...state,
                quotations: action.payload
            }
        case types.SET_SELECTED_ROW_VALUES:
            return {
                ...state,
                grid: action.payload
            }
        case types.CLEAR_CAPTURE_QUOTE_INPUT_VALUES:
            return {
                ...state,
                grid: [],
                maintenanceitemx: null,
                maintenanceclass: [],
                maintenanceitem: [],
                tyre_data: [],
                itemlist: [],
                loading: true,
                grid_item_inx: 0,
                grid_row: null,
                modelwin: false,
                servicehistory: false,
                tyreshistory: false,
                driverbehaviour: false,
                exceptions: [],
                exceptionsfound: false
            }
        case types.SET_COMPONENT_GRID_LOOKUP_FIELD_DATA:
            return {
                ...state,
                [action.payload.component]: action.payload.data
            }
        case types.SET_COMPONENT_ITEMS_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_COMPONENT_GRID_PO_ITEMS_LOOKUP_FIELD_DATA:
            return {
                ...state,
                [action.payload.component]: action.payload.data
            }
        case types.SET_COMPONENT_SELECTED_GRID_LOOKUP_VALUE:
            return {
                ...state,
                grid: state.grid.map((x, i) =>
                    i === action.payload.inx
                        ? {
                            ...x,
                            [action.payload.field]: action.payload.text,
                            [action.payload.fieldpk]: action.payload.value
                        }
                        : x
                )
            }
        case types.SET_COMPONENT_INPUT_GRID_LOOKUP_VALUE:
            return {
                ...state,
                grid: state.grid.map((x, i) =>
                    i === action.payload.inx
                        ? {
                            ...x,
                            [action.payload.field]: action.payload.value
                        }
                        : x
                )
            }
        case types.UPDATE_COUNTER:
            return {
                ...state,
                counter: state.counter + 1
            }
        default:
            return state
    }
}