import * as types from './types'
import * as appTypes from '../../App/types'
import moment from 'moment'
import _ from 'lodash'
import * as formulas from 'formulajs'

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_REVIEW_COMPONENT_INPUT_FIELD_VALUE,
            payload: data
        })
    }
}

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_REVIEW_COMPONENT_RESET_FORM,
        })
    }
}