export const counter = 0
export const quotations = []

export const tyre_data = []
export const maintenanceitemx = null
export const maintenanceitem_data = []
export const maintenanceclass_data = []
export const grid = []
// export const selected = {}

// export const componentname = ''
// export const component_id = ''
// export const item_id = ''

[
    {
        "px": "241114090312491",
        "id": "241114210318324",
        "Class": "",
        "Class_Id": null,
        "Description": "",
        "Description_Id": null,
        "Quantity": 1,
        "Price": 0,
        "Discount": 0,
        "DiscountAmount": "0.00",
        "Savings": 0,
        "VAT": "0.00",
        "Amount": "0.00",
        "Total": "0.00",
        "Rebill": false,
        "Consumables": false,
        "Vattable": true,
        "Warranty": "",
        "Comment": "",
        "maintenanceitemx": null,
        "transaction_max": {
            "maintenancetransactionitem_id": "1076",
            "itemtype": "Components",
            "itemdescription": "Repair",
            "quantity": "1.00",
            "unitprice": "10000.00",
            "amount": "11500.00",
            "vat": "1500.00",
            "totalamount": "13225.00",
            "discountamount": "0.00",
            "rebillpercentage": 0,
            "discountpercentage": "0.00",
            "rebill": true,
            "vattable": true,
            "savings": "0.00",
            "warranty": "",
            "comments": "",
            "created_by_user_id": null,
            "updated_by_user_id": null,
            "deleted_by_user_id": null,
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "maintenancetransaction_id": "1587",
            "tyre_id": null,
            "client_id": null,
            "ps": null,
            "partno": null,
            "new_item": null,
            "itemcode": null,
            "operationslabourgroup": null,
            "labourcode": null,
            "uuid": null,
            "maintenancetransaction": {
                "maintenancetransaction_id": "1587",
                "reference": null,
                "maintdescription": "test",
                "transactiondate": null,
                "capturedate": "2024-04-08T12:00:00.000Z",
                "capturedby": "Vialli Fritz",
                "datestamp": "2024-04-07T22:00:00.000Z",
                "paiddate": null,
                "dayofweek": "Monday",
                "odometer": 150000,
                "quantity": null,
                "amount": "10000.00",
                "vat": "1500.00",
                "totalamount": "11500.00",
                "paidamount": null,
                "unitprice": null,
                "approvedamount": null,
                "invoicedamount": null,
                "invoicenumber": null,
                "invoiceby": null,
                "discountpercentage": null,
                "discountamount": "0.00",
                "rebillpercentage": null,
                "vattable": true,
                "invoiceamountincl": null,
                "rebill": true,
                "tyrereplacement": null,
                "timeofdayexception": null,
                "dayofweekexception": null,
                "pototal": null,
                "podcomments": null,
                "ponumber": null,
                "podate": null,
                "rebillnumber": "000062005",
                "rebilldate": "2024-04-08T12:03:00.000Z",
                "rebillby": "Vialli",
                "authnumber": "0025614-01",
                "authdate": "2024-04-08T12:03:00.000Z",
                "authby": null,
                "preauthnumber": "0054875-01",
                "bookeddate": "2024-04-07T22:00:00.000Z",
                "completeddate": null,
                "comments": "Completed",
                "quotenumber": "6766878876",
                "drivername": "",
                "bookingdays": "2",
                "serviceinterval": "",
                "closedby": null,
                "approved": true,
                "approvedby": "Vialli Fritz",
                "approvedate": "2024-04-08T12:02:00.000Z",
                "serviceplan": null,
                "warrantyplan": null,
                "preauthdate": "2024-04-08T11:57:00.000Z",
                "authsenddate": null,
                "invoicedate": null,
                "authsendby": null,
                "bookedby": "Vialli Fritz",
                "tasknumber": "557",
                "gmapprovedate": null,
                "gmapprovedby": null,
                "ceoapprovedate": null,
                "ceoapprovedby": null,
                "usercompleteddate": null,
                "incidentdate": "2024-04-07T22:00:00.000Z",
                "drivable": false,
                "isinvoice_uploaded": null,
                "khulafid": null,
                "ps": "0",
                "event_id": "182",
                "booking_id": null,
                "rebillto": "",
                "invoiceto": null,
                "archivedata": null,
                "archivereason": null,
                "opsapprovedby": "Vialli Fritz",
                "opsapproved": true,
                "opsapprovedate": "2024-04-08T12:03:00.000Z",
                "quotedate": "2024-04-08T22:00:00.000Z",
                "customer_invoice_number": null,
                "approvedcomments": "Completed",
                "opscomments": "Completed",
                "gmcomments": null,
                "ceocomments": null,
                "tech_doa_date": null,
                "tech_doa_by": null,
                "gm_doa_date": null,
                "gm_doa_by": null,
                "coo_doa_date": null,
                "coo_doa_by": null,
                "xero_id": null,
                "created_by_user_id": "411",
                "updated_by_user_id": null,
                "deleted_by_user_id": null,
                "created_at": "2024-04-08T11:57:00.000Z",
                "updated_at": null,
                "deleted_at": null,
                "client_id": null,
                "vehicle_id": "94",
                "maintenancetype_id": "35",
                "maintenanceauthority_id": null,
                "tyre_id": null,
                "rebillreason_id": null,
                "supplier_id": "1",
                "accountingitem_id": "656",
                "maintenancestatus_id": "4",
                "maintenanceclass_id": null,
                "customercontract_id": null,
                "customer_id": null,
                "jobcard_id": null,
                "contract_vehicle_id": null,
                "vehicle_fktext": null,
                "maintenancetype_fktext": null,
                "supplier_fktext": null,
                "makeandmodel": null,
                "regyear": null,
                "costcenter": null,
                "maintenancestatus_fktext": null,
                "creditlimit": null,
                "creditlimitused": null,
                "khulatyreid": null,
                "khulamaintclassid": null,
                "khulajobcardid": null,
                "khulasupid": null,
                "khulaauthid": null,
                "khulamaintauthitemid": null,
                "khularebillreasonid": null,
                "xero_error": null,
                "makemodel": null,
                "ai_status": 0,
                "sendauth": null,
                "costcentre": null,
                "nextserviceinterval": null,
                "person_id": null,
                "clientrejectioncode": null,
                "dealerrejectioncode": null,
                "edi": null,
                "jobcardno": null,
                "operationsprofiletype": null,
                "clientrejectiondesc": null,
                "edl": null,
                "edo": null,
                "edp": null,
                "edh": null,
                "delayreason_id": null,
                "uuid": null,
                "supplier": {
                    "supplier_id": "1",
                    "suppliername": "DemoSupplier1(T)",
                    "registrationnumber": "REG 7341/2769/01",
                    "vatnumber": "74523549",
                    "accountnumber": "SUB-3806",
                    "merchantcode": "MER2728",
                    "contactperson": "Jane Doe",
                    "contactnumber": "+27 12 277 2369",
                    "contactemail": "janed@supplier.com",
                    "tradingnamename": "DemoSupplier1(T)",
                    "beetype": "QSE",
                    "bankaccountname": "DemoSupplier1(T)",
                    "bankaccountnumber": "90-3464-98",
                    "bankbranchnumber": "632000",
                    "bankname": "Capitec",
                    "beelevel": 3,
                    "beescore": "150.00",
                    "discountterms": 30,
                    "labourrateperhour": "400.00",
                    "sparesdiscount": "0.00",
                    "inwarrantylabourrate": "400.00",
                    "outwarrantylabourrate": "400.00",
                    "speciallabourrate": "400.00",
                    "afterhourslabourrate": "400.00",
                    "servicediscount": "0.00",
                    "repairdiscount": "0.00",
                    "beeprocurementrecognitionpercent": "100.00",
                    "blackdisabledownershippercentage": "0.00",
                    "blackownershippercentage": "50.00",
                    "blackwomanownershippercentage": "20.00",
                    "blackyouthownershippercentage": "0.00",
                    "active": true,
                    "fueloilprovider": true,
                    "tyresprovider": true,
                    "repairmaintenanceprovider": true,
                    "vehiclehireprovider": null,
                    "vehiclepurchaseprovider": false,
                    "stockpurchaseprovider": true,
                    "accidentrepairsprovider": true,
                    "driverlicencerenewal": null,
                    "driverlicencetesting": null,
                    "vehiclelicencerenewal": null,
                    "vehicletesting": false,
                    "suspended": false,
                    "beecertificatereceived": true,
                    "taxclearancecertificatereceived": true,
                    "taxclearancecertificaterequired": true,
                    "beecertificateexpdate": "2023-11-12T22:00:00.000Z",
                    "beecertificateexpiryremindersentdate": null,
                    "taxclearancecertificateexpirydate": "2023-11-12T22:00:00.000Z",
                    "ftaxclearancecertificateexpiryremindersentdate": null,
                    "tradingpreferencelevel": "3.00",
                    "ethnicitygroup": "Indian",
                    "slamailnotificationreminder": null,
                    "sendmailnotification": null,
                    "suspendedreason": null,
                    "stopbeemail": null,
                    "dateslasigned": "2022-11-12T22:00:00.000Z",
                    "slaexpirydate": "2023-11-12T22:00:00.000Z",
                    "slarateperhour": "350.00",
                    "address_line1": "Sandton",
                    "address_line2": "Sandton",
                    "address_line3": "Sandton",
                    "address_line4": null,
                    "address_code": null,
                    "address_suburb": null,
                    "address_town": null,
                    "address_province": null,
                    "supplier_comments": null,
                    "khulafid": null,
                    "country": null,
                    "xero_id": null,
                    "xero_createddate": null,
                    "created_by_user_id": "6",
                    "updated_by_user_id": "3",
                    "deleted_by_user_id": null,
                    "created_at": "2022-11-15T06:49:19.524Z",
                    "updated_at": "2023-09-29T13:03:34.039Z",
                    "deleted_at": null,
                    "suppliertype_id": "7",
                    "client_id": "2",
                    "bankname_id": "1",
                    "popi_compliance": true,
                    "slqexpirydate": "2023-11-14T22:00:00.000Z",
                    "suppliertype_fktext": null,
                    "discount_fktext": null,
                    "labourrate_fktext": null,
                    "consumables_fktext": null,
                    "khulacardvendmerchid": null,
                    "xero_error": null,
                    "event_ack_at": null,
                    "imgfilename": null,
                    "etl_suptype": null,
                    "rebate": "10.00",
                    "fuel_station_rebate": "10.00",
                    "oil_company_rebate": "10.00",
                    "supplierage_id": null,
                    "stopbeeemail": null,
                    "beecertificaterequired": null,
                    "financialinstitution_id": null,
                    "accountingtype": null,
                    "notes": null,
                    "contact_id": null,
                    "address_id": null,
                    "beetype_id": null,
                    "beelevel_id": null,
                    "discountprice": null,
                    "reminderdiscount": null,
                    "paymentterm_id": "4",
                    "company_reg_document_recieved": null,
                    "confirmation_banking_details_recieved": null,
                    "ethical_purchasing_recieved": null,
                    "signed_proc_fraud_policies_recieved": null,
                    "interest_decleration_recieved": null,
                    "service_provider_agreement_recieved": null,
                    "landlords_waiver_recieved": null,
                    "proof_of_insurance_recieved": null,
                    "psi": 2024061201,
                    "rta": 2024061001,
                    "shippingterms_id": 1,
                    "shippingterm_id": null,
                    "vatvendor": null,
                    "termscode": null,
                    "creditorsgroupcode": null,
                    "edx": null,
                    "uuid": null
                }
            }
        },
        "transaction_min": {
            "maintenancetransactionitem_id": "29",
            "itemtype": "Components",
            "itemdescription": "Repair",
            "quantity": "1.00",
            "unitprice": "0.00",
            "amount": "0.00",
            "vat": "0.00",
            "totalamount": "0.00",
            "discountamount": "0.00",
            "rebillpercentage": 0,
            "discountpercentage": "0.00",
            "rebill": false,
            "vattable": false,
            "savings": "0.00",
            "warranty": "",
            "comments": "",
            "created_by_user_id": null,
            "updated_by_user_id": null,
            "deleted_by_user_id": null,
            "created_at": null,
            "updated_at": null,
            "deleted_at": null,
            "maintenancetransaction_id": "117",
            "tyre_id": null,
            "client_id": null,
            "ps": null,
            "partno": null,
            "new_item": null,
            "itemcode": null,
            "operationslabourgroup": null,
            "labourcode": null,
            "uuid": null,
            "maintenancetransaction": {}
        }
    }
]

export const fields = [
    {
        name: 'Class',
        label: 'Item Category',
        type: 'select',
        width: 200,
        col: 2,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'maintenanceclass',
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Description',
        label: 'Description',
        type: 'select',
        width: 250,
        col: 2,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'maintenanceitem',
        related: 'maintenanceclass_id.Class_Id',
        text: null,
        value: null
    },
    {
        name: 'Quantity',
        label: 'Quantity',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Price',
        label: 'Unit Price',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Total',
        label: 'Total',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Savings',
        label: 'Savings',
        type: 'numeric',
        //: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Discount',
        label: 'Discount(%)',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Discount Amount',
        label: 'DiscountAmount',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'VAT',
        label: 'VAT',
        type: 'numeric',
        //: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Amount',
        label: 'Total Amount',
        type: 'numeric',
        //width: '5%',
        col: 2,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Rebill',
        label: 'Rebill',
        type: 'toggle',
       // width: 100,
        col: 1,
        align: 'left',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Warranty',
        label: 'Warranty (Months)',
        type: 'numeric',
        //width: 100,
        col: 1,
        align: 'right',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    // {
    //     name: 'action',
    //     label: '',
    //     type: 'action',
    //     width: 100,
    //     align: 'right',
    //     hide: false,
    //     read_only: false,
    //     related: null,
    //     text: null,
    //     value: null
    // }
    {
        name: 'Comment',
        label: 'Comments',
        type: 'text',
        //width: '15%',
        col: 2,
        align: 'left',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Vattable',
        label: 'Vattable',
        type: 'toggle',
        //width: 0,
        col: 1,
        align: 'left',
        hide: true,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'labourrate_exceeded',
        label: 'labourrate_exceeded',
        type: 'toggle',
        //width: 0,
        col: 1,
        align: 'right',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    }/* ,
    {
        name: 'Exceptions',
        label: 'Exceptions',
        type: 'exceptions',
        //width: 0,
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    } */
]
// export const grid_items_pricing = []
// export const maintenanceclass = []
// export const maintenanceitem = []
// export const itemlist = []
// export const loading = true
// export const grid_item_inx = 0
// export const grid_row = null
// export const modelwin = false
// export const servicehistory = false
// export const tyreshistory = false
// export const driverbehaviour = false
// export const toggle_labourrate =  {
//     errorText: '',
//     value: false,
//     valid: true,
//     empty: true,
// }

// export const labourrate_exceeded =  {
//     errorText: '',
//     value: false,
//     valid: true,
//     empty: true,
// }

export const exceptions = []
export const exceptionsfound = false