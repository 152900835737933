//import * as colours from '@material-ui/core/colors'
import Theme from '../../theme'
import * as colours from '@mui/material/colors'

export const theme = Theme

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const gridIconsTheme = (appName) => {
    if(appName === 'bidvest'){
        return {fill: '#2947AE'}
    }else{
        return {fill: '#141e30'}
    }
}

export const action = {
    fontSize: '20px',
    color: colours.red[500]
}

export const selectedIcon = {
    width: 16
}
export const selectedFieldBorderL = {
    borderRight: '2px solid gray',
}

export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}

export const selectedFieldHeaderL = {
    fontWeight: 'bold',
    textAlign: 'center',
    borderRight: '2px solid gray',
    fontSize: '14px'
}

export const selectedFieldHeader = {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '14px'
}

export const selectedFieldName = {
    fontWeight: 'bold'
}
export const icon = {
    fontSize: '10px'
}

export const row = {
    fontSize: 15,
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
}

export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    //fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const textBox = {
    width: '100%'
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
}

export const dropZone = {
    //width: '100px',
    height: '200px',
    border: '2px solid red',
    padding: '2px'
}

export const numericFieldsHeader = {
    textAlign: 'right',
    fontWeight: 'bold',
}

export const numericFields = {
    textAlign: 'right',
}


export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    textAlign: 'left',
}