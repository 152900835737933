import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Grid, Card, CardHeader, CardContent,
    TextField,Button,Divider,Tab,Tabs, Box
} from '@mui/material'

import Icon from '../Icon/component'
import * as actions from './actions'
import PublishNote from './publishNote'
import Notes from './notes'

import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import * as styles from '../Custom/CUSTOMERTRANSACTIONCUSTOM/styles'
import DateControl from '../../controls/dateControl'
import * as style from './styles'
class ReleaseNote extends Component{
    componentWillMount(){
            this.props.actions.getReleaseNote()
        if(this.props.state.releaseNote.dialogState == true){
            this.props.actions.setDialogStatus()
        } 
    }
    getReleaseNote(){
        const note = this.props.state.releaseNote
        const isDisabled =  note.description.length > 0 && note.releaseVersion.length > 0 && note.releaseDate.length > 0  ? false : true
        return(
            <Card>
                <CardHeader
                    avatar={<NotesOutlinedIcon/>}
                    title={window.t.en('PUBLISH RELEASE NOTE')}
                    className='note-header'
                    titleTypographyProps={{variant:'h6'}}
                />
                <CardContent>
                    <PublishNote/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} alignContent='center'>
                            <TextField 
                                label='Release Verision'
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={note.releaseVersion}
                                onChange={
                                    (event) =>{
                                        this.props.actions.setReleaseVersion(event.target.value)
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>

                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldContent}>
                                <DateControl
                                    label='Date'
                                    variant="outlined"
                                    margin="normal"
                                    id={'txtreference'}
                                    title={'Key in Month/Invoice Date...'}
                                    value={note.releaseDate}
                                    onSelect={(value) => {
                                        this.props.actions.setReleaseDate(value)
                                    }}
                                    type={'date'}
                                />
                            </div>
                        </div>
                            
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    avatar={<Icon iclass={'custom-icons'} iname={'feature'} />}
                                    title={window.t.en('DESCRIPTION')}
                                    className="release-Note"
                                    titleTypographyProps={{variant:'h6'}}
                                />
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        variant="outlined"
                                        margin="normal"
                                        value={note.description}
                                        onChange={
                                            (event) =>{
                                                this.props.actions.setDescription(event.target.value)
                                            }
                                        }
                                    />
                                    <br/>
                                    <Grid container spacing={3} direction="row" alignContent="space-between" alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Item"
                                                variant="outlined"
                                                margin="normal"
                                                value={note.item}
                                                onChange={
                                                    (event) =>{
                                                        this.props.actions.setItem(event.target.value)
                                                    }
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}> 
                                            <Grid container direction="row" alignContent="space-between" alignItems="center">
                                                <Grid>
                                                    &nbsp;
                                                    <Button 
                                                    className={"global_button"} variant="contained"
                                                        //size="large"
                                                        onClick={
                                                            (event)=>{
                                                                event.preventDefault();
                                                                this.props.actions.setNewFeature(note.item)
                                                                this.props.actions.clearItem()
                                                            }
                                                        }> Add New Feature
                                                    </Button>
                                                </Grid>
                                                <Grid >
                                                    &nbsp;
                                                    <Button 
                                                    className={"global_button"} variant="contained"
                                                        //size="large"
                                                        onClick={
                                                            (event) =>{
                                                                event.preventDefault();
                                                                this.props.actions.setBugFix(note.item)
                                                                this.props.actions.clearItem()
                                                            }
                                                        }>Add Bug Fix
                                                    </Button>
                                                </Grid>
                                                <Grid>
                                                    &nbsp;
                                                    <Button className={"global_button"} variant="contained"
                                                        //size="large"
                                                        onClick={
                                                            (event)=>{
                                                                event.preventDefault();
                                                                this.props.actions.setEnhancements(note.item)
                                                                this.props.actions.clearItem()
                                                            }
                                                        }>Add Enhancement
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {note.newFeatures.length > 0 ? this.setNewFeatures() : ''}
                    {note.bugFixes.length > 0 ?  this.setBuxFixes() : ''}
                    {note.releaseEnhancements.length > 0 ? this.setEnhancements() : ''}
                    <Divider/>
                    <br/>
                    <Grid container direction="row" style={style.flex}> 
                        <Button className={"global_button"} variant="contained"
                            disabled={isDisabled}
                            style = {style.button}
                            onClick={
                                (event)=>{
                                    this.props.actions.saveReleaseNote()
                                    this.props.actions.updateReadNote()
                                    this.props.actions.clearReleaseNote()
                                }
                            }
                        >
                            Save
                        </Button>

                        <Button
                            style = {style.button}
                            className={"global_button"} variant="contained"
                            disabled={isDisabled}
                            onClick={
                                (event)=>{
                                    this.props.actions.setDialogStatus()
                                }
                            }
                        >
                            Preview
                        </Button>
                    </Grid>
                    
                </CardContent>
            </Card>
        )
    }

    setNewFeatures(){
        const note = this.props.state.releaseNote
        return(
            <Card>
                <CardHeader
                    avatar={<Icon iclass={'custom-icons'} iname={'note'} />}
                    title={window.t.en('NEW FEATURES')}
                    className="release-Note"
                    titleTypographyProps={{variant:'h6'}}
                />
                <CardContent>
                    <Grid>
                        {note.newFeatures.length > 0 ?
                        <ul>
                            {note.newFeatures.map(item =>
                                <li>{item}</li>)}
                        </ul> : 
                        <h6>Add New Feature</h6>
                        }
                    </Grid>
                    {note.newFeatures.length > 0 ?
                    <Grid>
                        <Button className={"global_button"} variant="contained"
                            onClick = {
                                ()=>{
                                    this.props.actions.removeLastItem('newFeatures')
                                }
                            }
                        >
                            Remove Last
                        </Button>
                        <Button className={"global_button"} variant="contained"
                            onClick = {
                                () =>{
                                    this.props.actions.removeAll('newFeatures')
                                }
                            }
                        >
                            Clear All
                        </Button>
                    </Grid> : ''}
                </CardContent>
            </Card>
        )
    }
    setBuxFixes(){
        const note = this.props.state.releaseNote
        return(
            <Card> 
                <CardHeader
                    avatar={<Icon iclass={'custom-icons'} iname={'note'} />}
                    title={window.t.en('BUG FIXES')}
                    className="release-Note"
                    titleTypographyProps={{variant:'h6'}}
                />
                <CardContent>
                    <Grid>
                        {note.bugFixes.length > 0 ?
                        <ul>
                            {note.bugFixes.map(item =>
                                <li>{item}</li>)}
                        </ul>
                        :
                        <h6>Add Bug Fix</h6>}
                    </Grid>
                    {note.bugFixes.length > 0 ?
                    <Grid>
                        <Button size="large"
                            onClick = {
                                ()=>{
                                    this.props.actions.removeLastItem('bugFixes')
                                }
                            }
                        >
                            Remove Last
                        </Button>
                        <Button size="large"
                            onClick = {
                                () =>{
                                    this.props.actions.removeAll('bugFixes')
                                }
                            }
                        >
                            Clear All
                        </Button>
                    </Grid> : ''}
                </CardContent>
            </Card>
        )
    }
    setEnhancements(){
        const note = this.props.state.releaseNote
        return(
            <Card>
                <CardHeader
                    avatar={<Icon iclass={'custom-icons'} iname={'note'} />}
                    title={window.t.en('ENHANCEMENTS')}
                    className="release-Note"
                    titleTypographyProps={{variant:'h6'}}
                />
                <CardContent>
                    <Grid>
                        {note.releaseEnhancements.length > 0 ?
                        <ul>
                            {note.releaseEnhancements.map(item =>
                                <li>{item}</li>)}
                        </ul>
                        :
                        <h6>Add Enhancement</h6>}
                    </Grid>
                    {note.releaseEnhancements.length > 0 ?
                    <Grid>
                        <Button size="large"
                            onClick = {
                                ()=>{
                                    this.props.actions.removeLastItem('releaseEnhancements')
                                }
                            }
                        >
                            Remove Last
                        </Button>
                        <Button size="large"
                            onClick = {
                                () =>{
                                    this.props.actions.removeAll('releaseEnhancements')
                                }
                            }
                        >
                            Clear All
                        </Button>
                    </Grid> : ''}
                </CardContent>
            </Card>
        )
    }
    getTabs() {
        const width = this.props.state.user.isadmin == true ? "50%" : "100%"
        const note = this.props.state.releaseNote
        return <div>
              <Tabs
                  initialSelectedIndex={0}
                  value={note.tab}
                  inkBarStyle={{
                      height: 4
                  }}
                  indicatorColor="primary"
                  onChange={(event, value) => {
                      this.props.actions.changeReleaseNoteTab(value)
                      if(note.releaseDate.length > 0){
                        this.props.actions.clearReleaseNote()
                      }
                  }}
              >
                  {<Tab className='' style={{minWidth:width}} label={'Notes'} />}
                  {this.props.state.user.isadmin == true ? <Tab style={{minWidth:"50%"}} label={'Create'} />: null}
              </Tabs>
              <div>
                  {note.tab === 0 && <Box><PublishNote/> <Notes/></Box>}
                  {note.tab === 1 && <Box>{this.getReleaseNote()}</Box>}
              </div>
          </div>  
      }
    render(){
        return(
           this.getTabs()
        )
    }
}
const mapStateToProps = (state) => {
    return { 
       state : {
           ...state
       }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReleaseNote)