import React, { useRef, Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Iframe from 'react-iframe'

class KnowledgeBase extends Component {

    getView() {
        return <div style={{height: '100vh'}}>
            <Iframe url={'http://172.30.1.110:8501'}
                width="100%"
                height="900px"
                id={'KnowledgeBase'}
                className="myClassname"
                display="initial"
                position="relative"
                allowFullScreen />
        </div>
    }

    render() {
        return (
            this.getView()
        )
    }
}

export default KnowledgeBase