import * as colours from '@mui/material/colors'

export const view = {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0
}

export const card = {
    height: '100%'
}

export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}

export const scrollbar = {
    backgroundColor: colours.red[500],
    color: colours.red[500],
}

export const spacer = {
    height: '65px',
    width: '100%',
}
export const headerContainer = {
    width: '100%',
    display: 'flex'
}

export const headerIcon = {
    width: 40,
    height: 40,
    fontSize: 40,
    margin: '0 auto'
}

export const headerTitle = {
    background: 'white',
    //opacity: 0.8,
    fontWeight: 'bold',
    fontSize: '34',
    padding: 5,
    marginRight: 20
}

export const headerDescription = {
    background: 'white',
    //opacity: 0.8,
    //color: 'white',
    width: '100%',
    fontSize: '34',
    padding: 5,
    borderRight: '1px solid black'
}

export const bgContainer = {
    background: 'white'
}

export const rightpanel = {
    background: '#D1E0E6',
    borderLeft: '2px solid black'
}

export const content = {
    height: '100%',
    display: 'flex',
    flexFlow: 'row wrap'
}

export const flex = {
    display: 'flex'
}


export const calculator = {
    width: '80%'
}

export const toggle = {
    marginBottom: 16,
  }

  
export const right = {
    float: 'right'
}

export const confirmSelection = {
    textAlign: 'right'
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const dropZone = {
    width: '80%',
    height: '50px',
    border: '1px solid red',
    padding: '10px'
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    textAlign: 'left',
}

export const fullWidth = {
    width: '100%',
}
export const inputWidth = {
    width: '100%',
    fontSize: '16px',
}

export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '20px',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const tdValue = {
    width: '20%',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'right',
}
export const tdField = {
    width: '20%',
    textAlign: 'left',
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const checkbox = {
    display: 'table-cell',
    verticalAlign: 'middle',
    width: '300px',
    fontWeight: 'bold'
}

export const trField = {
    width: '100%',
    borderTop: '1px solid gray',
    padding: 2,
}

export const vehicle = {
    width: '180px'
}

export const box = {
    width: '100%',
    backgroundColor: '#fffbe5',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}
export const box1 = {
    width: '100%',
    backgroundColor: '#cbe9f7',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 10
}