import * as actions from './actions'
import * as types from './types'

import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getComponentGridData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_STR_DEFAULT_ACCESSORY_LIST)
    ////.debounceTime(500)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/straccessory/search',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'take': '20',
                'order': 'accessoryname'
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setComponentGridData(result.response),
                requestActions.requestSucceeded(config.system.api + '/components/straccessory/search', result.response)
            ])
            ,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/straccessory/search', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)