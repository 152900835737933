import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import DateControl from '../../../controls/dateControl'

import AutoComplete from '../../../controls/autocomplete'


import Icon from '../../Icon/component'

import * as actions from './actions'
import * as styles from './styles'

import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"
import "react-table/react-table.css"
import * as compActions from '../../Components/actions'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

class CUSTOMREGISTRATIONCHANGE extends Component {
    componentDidMount() {
        if (this.props.state.loading) {
            this.props.actions.getComponent('customregistrationchange')
            this.props.actions.getRegistrationChangeList('')
        }
    }
    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "approved") {
            return <div>< Button className={"global_button"}
                disabled={row.original.approved ? true : false}
                label={row.original.approved ? "Approved" : "Approve"}
                primary={true}
                onClick={(event) => {

                    var obj = {
                        registrationchange_id: row.original.customregistrationchange_id,
                        customercontract_id: row.original.customercontract_id,
                        customer_id: row.original.customer_id,
                        vehicle_id: row.original.vehicle_id,
                        newregistration: row.original.newregistration,
                        currentregistration: row.original.currentregistration,
                        contractnumber: row.original.contractnumber,
                        user: this.props.state.user,
                        expiry_date: row.original.expiry_date,
                    }
                    this.props.actions.approveRegistrationChange(obj)
                }}
                >{row.original.approved ? "Approved" : "Approve"}</Button>{' '}{row.original.approved ? ' ' 
                :< Button className={"global_button"} variant="contained"
                disabled={row.original.approved ? true : false}
                label={'Cancel'}
                primary={true}
                onClick={(event) => {
                    var obj = {
                        registrationchange_id: row.original.customregistrationchange_id,
                        contractnumber: row.original.contractnumber,
                        user: this.props.state.user
                    }
                    this.props.actions.cancelRegistrationChange(obj)
                }}
                >{window.t.en('Cancel')}</Button>}</div>
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black', //'#4A4A4A',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        }

        return columns
    }

    getGrid() {
        return <div> < Button className={"global_button"}
            secondary={true}
            label="Excel Export"
            icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
            onClick={(event) => {
                this.props.actions.excelExport(this.component)
            }}
            ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Excel Export</Button>{<TextField
            hintText={'Search by Contract/Reg Number...'}
            value={this.props.state.data.customregistrationchange_id.input}
            //value={this.props.state.data.customerallocation_id.input}
            onChange={(event, value) => {
                this.props.actions.setInputValue('customregistrationchange_id', value)
                this.props.actions.getRegistrationChangeList(value)
            }}
        />}{< Button className={"global_button"}
            label="Refresh"
            //icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
            onClick={(event) => {
                event.preventDefault()
                //this.props.actions.getFilterAllocationsData(this.props.state.data.customerallocation_id.input,cust_id)
                ////console.log("this.props.state.data.disposal_id.data")
                this.props.actions.getRegistrationChangeList(this.props.state.data.customregistrationchange_id.input)
            }}
            >{window.t.en('Refresh')}</Button>}<ReactTable
            key={'grid_' + this.component.name + '_' + this.component.component_id}
            //className='-striped -highlight'
            data={this.props.state.data.customregistrationchange_id.data}
            columns={this.getColumns()}
            defaultPageSize={this.component.grid.paging.pageSize}
            manual
            onFetchData={
                (state) => {
                }
            }
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.selectRow(this.component.name, rowInfo)

                        if (this.props.toggle) {
                            //this.props.actions.toggleInfoSheet()
                        }
                    },
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            onResizedChange={(newResized, event) => {
                //this.props.actions.resizeColumn(this.component.name, newResized)
            }}
            minRows={1}
            pages={this.component.grid.paging.pageCount}
            showPageJump={false}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable></div>
    }

    getRegListTab(){
        return <Container style={{paddingTop: '20px'}}>
                    <Row>
                        <Col xl={12}>
                            {this.getGrid()}
                        </Col>
                    </Row>
                </Container>

    }
    changeRegTab(){
        return  <Card style={styles.card} style={styles.row}>
        <CardHeader
            title={window.t.en('Change Registration')}
            subtitle={window.t.en('Capture Registration Change')}
            actAsExpander={true}
            showExpandableButton={true}
        />
        <CardContent> <Container style={styles.whitediv}>
            <Row>
                <Col xl={7}>
                    <Container style={styles.whitediv}>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Change From:'}</div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'dll_invoice_id'}
                                            key={'dll_invoice_id'}
                                            hintText={'Search for vehicle...'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            dataSource={this.props.state.data.vehicle_id.data}
                                            onNewRequest={
                                                (chosenRequest, index) => {
                                                    this.props.actions.setLookupValue("vehicle_id", chosenRequest.value, chosenRequest.text)                                                               
                                                    this.props.actions.getVehicleDetails(chosenRequest.value)
                                                    this.props.actions.setInputValue('currentregistration', chosenRequest.display)
                                                    this.props.actions.setInputValue('customer_id', chosenRequest.customer_id)
                                                    this.props.actions.setInputValue('customercontract_id', chosenRequest.customercontract_id)
                                                    this.props.actions.setInputValue('customer', chosenRequest.Customer)                                                                
                                                    if(chosenRequest.expiry_date)this.props.actions.setInputValue('expiry_date', new Date(chosenRequest.expiry_date))
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getAllVehicles('')
                                                }
                                            }
                                            onUpdateInput={
                                                (search, dataSource, params) => {
                                                    this.props.actions.getAllVehicles(search)
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Change To:'}</div>
                                        <div style={styles.fieldContent} disabled={true}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                id={'txt_customername'}
                                                hintText='Change To...'
                                                value={this.props.state.data.newregistration.input}
                                                fullWidth={true}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('newregistration', value)
                                                }}
                                            />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Licence Expiry Date:'}</div>
                                    <div style={styles.fieldContent}>
                                    {/* <TextField
                                    type="date"
                                    id={'txt_invoicedate'}
                                    hintText='Date'
                                    container='inline'
                                    mode='landscape'
                                    value={this.props.state.data.expiry_date.input}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue('expiry_date', new Date(value))
                                    }}
                                /> */}
                                <DateControl
                                    id={'txt_invoicedate'}
                                    key={'txt_invoicedate'}
                                    name={'txt_invoicedate'}
                                    label={window.t.en('Select Date...')}
                                    field={'expiry_date'}
                                    value={this.props.state.data.expiry_date.input}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue('expiry_date', args)
                            
                                    }}
                                    type={'date'}
                                />
                                </div>
                                </div>
                            </Col>
                        </Row>
                       
                    </Container>
                </Col>
                <Col xl={5} style={styles.rightpanel}>
                    <Row>
                        <Col xl={12}>
                            <Row style={styles.headerrow}>
                                <Col xl={4}>
                                    {'Changed From'}
                                </Col>
                                <Col xl={4}>
                                    {'Changed To'}
                                </Col>
                                <Col xl={4}>
                                    {'Date'}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={styles.itemrow}>
                            <Col xl={12}>
                                <Row>
                                    <Col xl={4}>
                                        {'Changed From...'}
                                    </Col>
                                    <Col xl={4}>
                                        {'Changed To...'}
                                    </Col>
                                    <Col xl={4}>
                                        {'Date'}
                                    </Col>
                                </Row>
                            </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        <br/>
                <Row>
                    <Col xl={10}>

                    </Col>
                    <Col xl={2} style={{ textAlignLast: 'right' }}>
                        < Button className={"global_button"} variant="contained"
                            header={false}
                            //disabled={this.props.state.data.newRegistration_id.input && this.props.state.data.vehicle_id.input ? false : true }
                            label={'Save Change'}
                            style={{ textAlignLast: 'right' }}
                            onClick={(event) => {
                                var obj = {
                                    values: {
                                        customercontract_id: this.props.state.data.customercontract_id.input,
                                        customer_id: this.props.state.data.customer_id.input,
                                        vehicle_id: this.props.state.data.vehicle_id.input,
                                        newregistration: this.props.state.data.newregistration.input,
                                        currentregistration: this.props.state.data.currentregistration.input,
                                        expiry_date: this.props.state.data.expiry_date.input,
                                    },
                                    user: this.props.state.user
                                }
                                this.props.actions.saveRegistrationChange(obj)
                            }}
                        >{window.t.en('Save Change')}</Button>
                    </Col>
                </Row>
        </CardContent>
    </Card>

    }

    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <div>
            <Tabs value={this.props.state.details.currentTab}
                onChange={
                    (event, value) => {
                        this.props.actions.setCurrentTab(value)
                    }
                }>
                <Tab label={'Registration Change List'} />
                {/* <Tab label={'Change Registration'} /> */}

            </Tabs>
            {this.props.state.details.currentTab === 0 && this.getRegListTab()}
            {/* {this.props.state.details.currentTab === 1 && this.changeRegTab()} */}
        </div>
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.customregistrationchange,
            details: {
                ...state.registrationchangecomponentview,
            },
            upload: {
                ...state.upload
            }, user: {
                ...state.user
            },

        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMREGISTRATIONCHANGE)