export const loading = false;
export const tab = 0;
export const confirmDeleteWindow = false;
export const deleteSchedulerId = 0;
export const navReports = [];
export const reportCategories = [];
export const data = [];
export const gridFields = [
    {
        "id": "scheduler_id",
        "name": "scheduler_id",
        "title": "Scheduler Id",
        "display": "scheduler_id",
        "key": true,
        "grid": false,
        "filter": false
    },
    {
        "id": "scheduler_name",
        "name": "scheduler_name",
        "title": "Scheduler Name",
        "display": "scheduler_name",
        "key": false,
        "grid": true,
        "type": "text",
        "filter": true
    },
    {
        "id": "report_id",
        "name": "report_id",
        "title": "Report Name",
        "display": "report.report_name",
        "key": false,
        "grid": true,
        "type": "text",
        "filter": false,
        "related": true
    },
    {
        "id": "frequency",
        "name": "frequency",
        "title": "Frequency",
        "display": "frequency",
        "key": false,
        "grid": true,
        "type": "frequencyText",
        "filter": false
    },
    {
        "id": "start_date",
        "name": "start_date",
        "title": "Start Date",
        "display": "start_date",
        "key": false,
        "grid": true,
        "type": "date",
        "filter": true
    }
];
export const fields = {
    "scheduler_id": null,
    "user_id": null,
    "report_id": null,
    "report_category_id": null,
    "report_subcategory_id": null,
    "scheduler_name": null,
    "frequency": null,
    "report_min": null,
    "report_hour": null,
    "report_day": null,
    "report_date": null,
    "report_month": null,
    "start_date": null,
    "end_date": null,
    "end_type": null,
    "run_count": null,
    "last_ran_date": null,
    "next_ran_date": null,
    "schedule_expression": null,
    "week_day_mon": false,
    "week_day_tue": false,
    "week_day_wed": false,
    "week_day_thu": false,
    "week_day_fri": false,
    "week_day_sat": false,
    "week_day_sun": false
}
export const defaultFields = {
    "scheduler_id": null,
    "user_id": null,
    "report_id": null,
    "report_category_id": null,
    "report_subcategory_id": null,
    "scheduler_name": null,
    "frequency": null,
    "report_min": null,
    "report_hour": null,
    "report_day": null,
    "report_date": null,
    "report_month": null,
    "start_date": null,
    "end_date": null,
    "end_type": null,
    "run_count": null,
    "last_ran_date": null,
    "next_ran_date": null,
    "schedule_expression": null,
    "week_day_mon": false,
    "week_day_tue": false,
    "week_day_wed": false,
    "week_day_thu": false,
    "week_day_fri": false,
    "week_day_sat": false,
    "week_day_sun": false
}