import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'

import { Card, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'; 
import Select from '@mui/material/Select'
import AutoComplete from '../../controls/autocomplete'
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider'

import Icon from '../Icon/component'
import * as config from '../../config'

import * as styles from './styles'
import * as actions from './actions'
import * as calculatorContainerActions from '../CalculatorContainer/actions'

class Calculator extends Component {
    componentDidMount() {
        this.props.actions.createCalculatorState(this.props.id)
    }

    componentWillUnmount() {
        this.props.actions.removeCalculatorState(this.props.id)
    }

    render() {
        this.calculator = this.props.state.calculators.filter(x => x.id === this.props.id)[0]

       
        return this.calculator === undefined
            ? <div />
            : (
                <Card style={styles.card}>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3}>
                                    <strong>Mode:</strong>
                                </Col>
                                <Col>
                                    <Select
                                        key={'modeMenu'}
                                        value={this.calculator.mode}
                                        onChange={(event, index, value) => {
                                            this.props.actions.changeSelect(this.calculator.id, 'mode', event.target.value)
                                        }}>
                                        {
                                            this.props.state.modes.sort((x, y) => x - y).map(x => {
                                                return <MenuItem key={'mode_' + x.value} value={x.value} >{x.text}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Col>
                                <Col style={styles.remove}>
                                    <Icon
                                        iname="delete_forever"
                                        iclass="material-icons"
                                        istyle={{
                                            fontSize: '16px',
                                        }}
                                        onClick={(event) => {
                                            this.props.actions.removeCalculator(this.calculator.id)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>
                                    <strong>Solve For:</strong>
                                </Col>
                                <Col>
                                    <Select
                                        key={'solveForMenu'}
                                        value={this.calculator.solve}
                                        onChange={(event, index, value) => {
                                            this.props.actions.changeSelect(this.calculator.id, 'solve', event.target.value)
                                        }}>
                                        {
                                            this.props.state.solves.sort((x, y) => x - y).map(x => {
                                                return <MenuItem key={'solve_' + x.value} value={x.value} >{x.text}</MenuItem>
                                    
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>
                                    {
                                        this.calculator.vehicles && this.calculator.vehicles.length
                                            ? <img style={styles.vehicle} src={config.system.cdn + (this.calculator.vehicles[0].imagefilename !== null ? this.calculator.vehicles[0].imagefilename : 'unknown.png')} />
                                            : <div />
                                    }
                                </Col>
                                <Col>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 200, overflow: 'auto' }}
                                        id={'autocomplete_vehicle_' + this.calculator.id}
                                        key={'autocomplete_vehicle_' + this.calculator.id}
                                        hintText={'Select make and model'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        searchText={this.calculator.search}
                                        dataSourceConfig={{
                                            text: 'makemodelyear',
                                            value: 'mmcode_id'
                                        }}
                                        dataSource={this.calculator.vehicles}
                                        //errorText={this.component.data[field.name].validation.message}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                this.props.actions.setVehicles(this.calculator.id, chosenRequest.value)
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.getVehicles(this.calculator.id, this.calculator.search)
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.getVehicles(this.calculator.id, search)
                                            }
                                        }
                                    //maxSearchResults={3}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12}>
                                    &nbsp;
                                        <Divider />
                                    &nbsp;
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>Present Value:</Col>
                                <Col>
                                <TextField  
                                        type="number"
                                        id={'inputPresentValue_' + this.calculator.id}
                                        //required
                                        value={this.calculator.presentValue}
                                        disabled={this.calculator.presentValueDisabled}
                                        min={-9999999999}
                                        max={9999999999}
                                        strategy={'warn'}
                                        errorText={this.calculator.presentValueError}
                                        onError={(error) => {
                                            this.props.actions.setError(this.calculator.id, 'presentValue', error)
                                        }}
                                        onValid={(value) => {
                                            this.props.actions.setError(this.calculator.id, 'presentValue', '')
                                        }}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue(this.calculator.id, 'presentValue', value)
                                        }}
                                        onRequestValue={(value) => {
                                            this.props.actions.setValue(this.calculator.id, 'presentValue', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>Future Value:</Col>
                                <Col>
                                <TextField  
                                        type="number"
                                        id={'inputFutureValue_' + this.calculator.id}
                                        //required
                                        value={this.calculator.futureValue}
                                        disabled={this.calculator.futureValueDisabled}
                                        min={-9999999999}
                                        max={9999999999}
                                        strategy={'warn'}
                                        errorText={this.props.state.futureValueError}
                                        onError={(error) => {
                                            this.props.actions.setError(this.calculator.id, 'futureValue', error)
                                        }}
                                        onValid={(value) => {
                                            this.props.actions.setError(this.calculator.id, 'futureValue', '')
                                        }}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue(this.calculator.id, 'futureValue', value)
                                        }}
                                        onRequestValue={(value) => {
                                            this.props.actions.setValue(this.calculator.id, 'futureValue', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>Period In Months:</Col>
                                <Col>
                                <TextField  
                                        type="number"
                                        id={'inputPeriodInMonthsValue_' + this.calculator.id}
                                        //required
                                        value={this.calculator.periodInMonths}
                                        disabled={this.calculator.periodInMonthsDisabled}
                                        min={-9999999999}
                                        max={9999999999}
                                        strategy={'warn'}
                                        errorText={this.calculator.periodInMonthsError}
                                        onError={(error) => {
                                            this.props.actions.setError(this.calculator.id, 'periodInMonths', error)
                                        }}
                                        onValid={(value) => {
                                            this.props.actions.setError(this.calculator.id, 'periodInMonths', '')
                                        }}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue(this.calculator.id, 'periodInMonths', value)
                                        }}
                                        onRequestValue={(value) => {
                                            this.props.actions.setValue(this.calculator.id, 'periodInMonths', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>Interest Rate Per Annum (%):</Col>
                                <Col>
                                <TextField  
                                        type="number"
                                        id={'inputInterestRatePerAnnum_' + this.calculator.id}
                                        //required
                                        value={this.calculator.interestRatePerAnnum}
                                        disabled={this.calculator.interestRatePerAnnumDisabled}
                                        min={-9999999999}
                                        max={9999999999}
                                        strategy={'warn'}
                                        errorText={this.calculator.interestRatePerAnnumError}
                                        onError={(error) => {
                                            this.props.actions.setError(this.calculator.id, 'interestRatePerAnnum', error)
                                        }}
                                        onValid={(value) => {
                                            this.props.actions.setError(this.calculator.id, 'interestRatePerAnnum', '')
                                        }}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue(this.calculator.id, 'interestRatePerAnnum', value)
                                        }}
                                        onRequestValue={(value) => {
                                            this.props.actions.setValue(this.calculator.id, 'interestRatePerAnnum', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>Residual Value (%):</Col>
                                <Col>
                                <TextField  
                                        type="number"
                                        id={'inputInterestRatePerAnnum_' + this.calculator.id}
                                        //required
                                        value={this.calculator.residualValue}
                                        disabled={this.calculator.residualValueDisabled}
                                        min={-9999999999}
                                        max={9999999999}
                                        strategy={'warn'}
                                        errorText={this.calculator.residualValueError}
                                        onError={(error) => {
                                            this.props.actions.setError(this.calculator.id, 'residualValue', error)
                                        }}
                                        onValid={(value) => {
                                            this.props.actions.setError(this.calculator.id, 'residualValue', '')
                                        }}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue(this.calculator.id, 'residualValue', value)
                                        }}
                                        onRequestValue={(value) => {
                                            this.props.actions.setValue(this.calculator.id, 'residualValue', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>Monthly Payment:</Col>
                                <Col>
                                <TextField  
                                        type="number"
                                        id={'inputMonthlyPayment_' + this.calculator.id}
                                        //required
                                        value={this.calculator.monthlyPayment}
                                        disabled={this.calculator.monthlyPaymentDisabled}
                                        min={-9999999999}
                                        max={9999999999}
                                        strategy={'warn'}
                                        errorText={this.calculator.monthlyPaymentError}
                                        onError={(error) => {
                                            this.props.actions.setError(this.calculator.id, 'monthlyPayment', error)
                                        }}
                                        onValid={(value) => {
                                            this.props.actions.setError(this.calculator.id, 'monthlyPayment', '')
                                        }}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue(this.calculator.id, 'monthlyPayment', value)
                                        }}
                                        onRequestValue={(value) => {
                                            this.props.actions.setValue(this.calculator.id, 'monthlyPayment', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12}>
                                    &nbsp;<Divider />
                                    &nbsp;
                                    </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>
                                    <strong>Total Payments:</strong>
                                </Col>
                                <Col>
                                <TextField  
                                        type="number"
                                        id={'inputTotalPayments_' + this.calculator.id}
                                        //required
                                        value={this.calculator.totalPayments}
                                        disabled={this.calculator.totalPaymentsDisabled}
                                        min={-9999999999}
                                        max={9999999999}
                                        strategy={'warn'}
                                        errorText={this.calculator.totalPaymentsError}
                                        onError={(error) => {
                                            this.props.actions.setError(this.calculator.id, 'totalPayments', error)
                                        }}
                                        onValid={(value) => {
                                            this.props.actions.setError(this.calculator.id, 'totalPayments', '')
                                        }}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue(this.calculator.id, 'totalPayments', value)
                                        }}
                                        onRequestValue={(value) => {
                                            this.props.actions.setValue(this.calculator.id, 'totalPayments', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>
                                    <strong>Total Interest:</strong>
                                </Col>
                                <Col>
                                <TextField  
                                        type="number"
                                        id={'inputTotalInterest_' + this.calculator.id}
                                        //required
                                        value={this.calculator.totalInterest}
                                        disabled={this.calculator.totalInterestDisabled}
                                        min={-9999999999}
                                        max={9999999999}
                                        strategy={'warn'}
                                        errorText={this.calculator.totalInterestError}
                                        onError={(error) => {
                                            this.props.actions.setError(this.calculator.id, 'totalInterest', error)
                                        }}
                                        onValid={(value) => {
                                            this.props.actions.setError(this.calculator.id, 'totalInterest', '')
                                        }}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue(this.calculator.id, 'totalInterest', value)
                                        }}
                                        onRequestValue={(value) => {
                                            this.props.actions.setValue(this.calculator.id, 'totalInterest', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={12}>
                                    &nbsp;
                                        <Divider />
                                    &nbsp;
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={3}>&nbsp;</Col>
                                <Col>
                                   < Button className={"global_button"} variant="contained"
                                        className={"global_button"}
                                        label="Calculate"
                                        primary={true}
                                        onClick={(event) => {
                                            this.props.actions.calculate(this.calculator.id)
                                        }}
                                        >{window.t.en('Calculate')}</Button>

                                    &nbsp;
                                    &nbsp;

                                   < Button className={"global_button"} variant="contained"
                                        label="Reset"
                                        className={"global_button"}
                                        primary={false}
                                        onClick={(event) => {
                                            this.props.actions.reset(this.calculator.id)
                                        }}
                                        >{window.t.en('Reset')}</Button>

                                    {/* <MultiSelect 
                                    compname = {'customer'}
                                    key ={'customer_id'}
                                    size = {2}
                                    /> */}

                                       
                                   
                                </Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.calculator }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...calculatorContainerActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calculator)