import * as teamTypes from '../TeamManagement/TeamOptions/types'
import * as types from './types'
import * as _ from 'lodash'

export const getTeamsData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_TEAM_STRUCTURE_DATA,
            payload: {
                type: 'hierachy',
                value: 1, 
            }
        })
    }
}

export const getCustomerData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CUSTOMER_STRUCTURE_DATA,
            payload: {
                type: 'hierachy',
                value: 1, 
            }
        })
    }
}

export const getClientsData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CLIENT_STRUCTURE_DATA
        })
    }
}

export const clearTeamData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_COMPONENT_TEAM_STRUCTURE_DATA
        })
    }
}

export const setInputData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_TEAM_STRUCTURE_INPUT_DATA,
            payload: data
        })
        dispatch({
            type: teamTypes.ADD_NEW_TEAM_STRUCTURE
        })

    }
}

export const setTeamsData = (response) => {
    return (dispatch, getState) => {
        //console.log('setTeamsData', response)
        dispatch({
            type: types.SET_COMPONENT_TEAM_STRUCTURE_INPUT_DATA,
            payload: {
                prop: 'teams',
                data: response.hierachy
            }
        })

    }
}

export const setCustomerData = (response) => {
    return (dispatch, getState) => {
        //console.log('setCustomerData', response)
        dispatch({
            type: types.SET_COMPONENT_TEAM_STRUCTURE_INPUT_DATA,
            payload: {
                prop: 'customers',
                data: response.hierachy
            }
        })

    }
}

export const setClientsData = (response) => {
    return (dispatch, getState) => {
        //console.log('setClientData', response)
        dispatch({
            type: types.SET_COMPONENT_TEAM_STRUCTURE_INPUT_DATA,
            payload: {
                prop: 'clients',
                data: response.data
            }
        })

    }
}