export const chipRow = {
    display: 'table',
    margin: 5
}

export const chip = {
    width: 285
}

export const chipContainer = {
    display: 'table-cell',
    width: 300,
    verticalAlign: 'middle'
}

export const chipDescription = {
    display: 'table-cell',
    verticalAlign: 'middle'
}