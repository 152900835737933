import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import * as _ from 'lodash'
import { Container, Row, Col } from 'react-grid-system'
import Card from '../../../controls/card'
import Icon from '../../Icon/component'

import SUPPLIERPAYMENTCONFIRMATION from '../../Custom/SUPPLIERPAYMENTCONFIRMATION/component'
import * as styles from './styles'
import * as actions from './actions'

class PaymentConfirm extends Component {
    componentDidMount() {
        let wf_data = this.props.state
        const meta = [
            {
                header: true,
                title: 'Payment Loaded',
                description: 'Batch Number',
                meta_data: wf_data.batchNumber
            }
        ]
        const data = {
            ...this.props.state.workflowView,
            batchNumber: wf_data.batchNumber
        }

        this.props.actions.setupWorkflowTaskInput(data, meta, 'payment', 'tsk_payment_loading')
    }
    renderView() {
        return <div>
           {
                        this.props.state.batchNumber
                            ? <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12}>
                                        <SUPPLIERPAYMENTCONFIRMATION />
                                    </Col>
                                </Row>
                            </Container>
                            : <strong style={styles.box1}>{'Kindly check if your not logged in twice, if the error persist please contact system administrator.'}</strong>
                    }
        </div>
    }

    render() {
        return this.renderView()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowPaymentView
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirm)