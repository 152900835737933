import * as types from './types'
import moment from 'moment'

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
    }
}

export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUES,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const getMMCodeByVIN = (vin) => {
    window.glyco.log('getMMCodeByVIN')
    window.glyco.log(vin)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER,
            payload: vin
        })
    }
}
export const getServicePartBasket = (mmcode, interval) => {
    return (dispatch, getState) => {

        dispatch({
            type: types.GET_BOOKING_VEHICLE_SERVICE_PART_BASKET,
            payload: '?mmcode=' +  mmcode + '&service_info_interval=' + interval
        })
    }
}

export const setServicePartBasket = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_VEHICLE_SERVICE_PART_BASKET,
            payload: response.data
        })
    }
}

export const getVehicleContract = (vehicle_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE,
            payload: vehicle_id
        })
    }
}

export const setVehicleContract = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE,
            payload: response.data
        })
    }
}

export const setAcceptedDocuments = (documents) => {
    const _file = 'VSBQ_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: _file,
                        type: file.type,
                    }
                })
            };
        });
        
        // dispatch({
        //     type: types.SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW,
        //     payload: {
        //         preview: documents[0].preview,
        //         filename: _file,
        //     }
        // })
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'filename',
                value: _file
            }
        })  
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'invoicepreview',
                value: documents[0].preview
            }
        })
    }
}

export const setRejectedDocuments = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW,
            payload: null
        })
    }
}

export const downloadDocument = (document) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.DOWNLOAD_DOCUMENT_DISCOUNT_LETTER,
            payload: {
                type: 'DOWNLOAD_DOCUMENT_DISCOUNT_LETTER',
                document: document
            }
        })
    }
}

export const setDiscountLetter = (data) => {
    let link = ''
    const filekey = data.data.document.key
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW,
            payload: {
                preview: link
            }
        })
    }
}

export const getVehicleLastPosition = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICLE_LASTPOSITION,
            payload: {
                type: 'GET_VEHICLE_LASTPOSITION',
                vehicle: id
            }
        })
    }
}

export const setVehicleLastPosition = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_VEHICLE_LASTPOSITION,
            payload: data.data
        })
    }
}

export const getSelectedVehicleDetails = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_VEHICLE_DETAILS,
            payload: {
                query: request.value
            }
        })
    }
}

export const setSelectedVehicleDetails = (result, vehicle_id) => {
    return (dispatch, getState) => {
        
        const curr_type = getState().poHeader.selectedPurchaseOrderType.key
        dispatch({
            type: types.SET_SELECTED_VEHICLE_DETAILS,
            payload: {
                data: result.data
            }
        })/* 
        
        let recent_auth = []
        try {
            recent_auth = result.data[0].maintenancetransaction
                    .filter(x => moment().diff(x.created_at, 'hours') < 24)
                                .filter(x => x.deleted_at === null)
                                .filter(x => x.preauthnumber !== null)
                                .filter(x => (x.maintenancetype_fktext ? x.maintenancetype_fktext : '')
                                .toLowerCase() === (curr_type ? curr_type : '').toLowerCase())
            
        } catch (error) {
            
        }
        
        
        dispatch({
            type: types.SET_COMPONENT_RECENT_MAINTENANCE_AUTHS,
            payload: {
                data: recent_auth
            }
        })
 */
        /* dispatch({
            type: types.GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST,
            payload: {
                type: 'GET_VEHICLE_BOOKING_SUPPLIER',
                vehicle: vehicle_id,
                range: 6000,
                suppliertype: 'Dealership'
            }
        }) */
    }
}


export const generateDefleetSignoff = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GENERATE_DEFLEET_SIGNOFF,
            payload: {
                type: 'GENERATE_DEFLEET_SIGNOFF',
                data: data
            }
        })
    }
}

export const setDefleetSignoff = (data) => {
    
    //console.log("DEFLEET SIGNOFF")
    //console.log(data)
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
        
        /*  return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }  */
    }())

    saveByteArray([data.data.pdf.Body], 'Defleet Signoff.pdf')

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_SIGNOFF,
            payload: {
                preview: link,
                filename: data.data.key.replace('quotes/', '')
            }
        })
    }
}


export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_UPLOAD_QUOTES,
            payload: payload
        })
    }
}


export const setQuoteItems = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTE_ITEMS,
            payload: data.data
        })
    }
}