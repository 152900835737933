import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
//import validator from '../../validate'

export const clearRFQDocument = (component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_PO_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: 'document',
                field: 'content',
                value: '',
            }
        })
    }
}

export const getLookupValue = (model, field, value) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]

        const param = field.parameters
            ? field.parameters.indexOf('.') > -1
                ? '?' + field.parameters.split('.')[1] + '=' + getState().components[field.parameters.split('.')[0]].data[field.parameters.split('.')[1]].input
                : '?' + field.parameters + '=' + component.data[field.parameters].input
            : ''

        const payload = {
            component: component,
            model: model,
            field: field,
            related: '?suppliername=' + value,
            query: param,
        }

        dispatch({
            type: types.GET_PO_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}


export const setLookupValue = (field, input, text) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }


    return (dispatch, getState) => {
        const value = format(field, input)

        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })

        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name,
                value: text,
            }
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component,
                field: field.name,
                data: data
            }
        })
    }
}

export const setSelectedLookupValue = (field, input, text) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }


    return (dispatch, getState) => {
        const value = format(field, input)

        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })

        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name.replace('_id', ''),
                value: text,
            }
        })
    }
}

export const setFieldLookupData = (component, field, payload) => {

    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_COMPONENT_GRID_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: data
            }
        })
    }
}

export const setFieldLookupValue = (component, field, payload) => {

    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: data[0].value
            }
        })
        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name,
                value: data[0].text
            }
        })
    }
}

export const getQuoteDetails = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value
            }
        })

        dispatch({
            type: types.SEARCH_PO_QUOTE_DATA,
            payload: {
                component: 'customerquote',
                field: field.name,
                query: '?quotenumber=' + value,
            }
        })
    }
}

export const setQuoteDetails = (component, payload) => {

    const data = {
        component: component,
        data: (typeof payload.data[0] === 'object') ? payload.data[0] : []
    }

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_COMPONENT_GRID_DATA,
            payload: data
        })
    }
}

export const confirmSelection = (step, component) => {

    return (dispatch, getState) => {

        const data = [{
            form: {
                bookingtype_id: component.data.bookingtype_id.input,
                branch_id: component.data.branch_id.input,
                business_id: component.data.business_id.input,
                color_id: component.data.color_id.input,
                comments: component.data.comments.input,
                customer_id: component.data.customer_id.input,
                mmcode_id: component.data.mmcode_id.input,
                multiplequote: component.data.multiplequote.input,
                orderdate: component.data.orderdate.input,
                ordernumber: component.data.ordernumber.input,
                orderreason_id: component.data.orderreason_id.input,
                ordertype_id: component.data.ordertype_id.input,
                purchaseorder_id: component.data.purchaseorder_id.input,
                quotenumber: component.data.quotenumber.input,
                registration: component.data.registration.input,
                vehicle_id: component.data.vehicle_id.input

            },
            grid: component.grid.data
        }]

        const payload = {
            step: step,
            component: component.name,
            selected: data
        }

        dispatch({
            type: types.CONFIRM_QUOTE_HEADER_SELECTION,
            payload: payload
        })
    }
}

export const setValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value
            }
        })
    }
}

export const setAcceptedDocuments = (component, documents) => {
    const rfq = 'RFQ_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.SET_PO_UPLOAD_QUOTE_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: rfq,
                        type: file.type,
                    }
                })
            };
        });
        dispatch({
            type: types.SET_PO_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: 'surrogateheader',
                field: 'rfq',
                value: rfq
            }
        })

        dispatch({
            type: types.SET_PO_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: component.name,
                preview: documents[0].preview,
                filename: documents[0].name,
            }
        })
    }
}

export const setRejectedDocuments = (component, documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_PO_QUOTE_DOCUMENT_PREVIEW,
            payload: {
                component: component.name
            }
        })
    }
}

