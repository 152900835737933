import * as types from './types'

export const crearDocuments = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_DOCUMENTS_PREVIEW
        })

        dispatch({
            type: types.SET_QUOTE_DOCUMENT_BYTES,
            payload: {
                component: getState().components['document'],
                data: null
            }
        })
    }
}

export const getQuotationPDF = (payload) => {
    return (dispatch, getState) => {

        window.glyco.log('getQuotationPDF')
        window.glyco.log(payload)
        dispatch({
            type: types.GET_QUOTE_DOCUMENTS,
            payload: payload
        })
    }
}
export const setDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DOCUMENTS,
            payload: documents
        })
    }
}

export const onDocumentTabPreview = (inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CURRENT_DOCUMENT_PREVIEW_TAB,
            payload: inx
        })
    }
}

//Used to sey document preview
export const viewDocument = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name, preview) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob) 
           // if()
            link = url
            if (name.toLowerCase().indexOf('.pdf') === -1) {
                a.href = url
                //a.target = '_blank'
                a.download = name
                a.click()
                window.URL.revokeObjectURL(url)
            }
            else if (preview === 'buttons') {
                a.href = url
                a.target = '_blank'
                //a.download = name
                a.click()
                window.URL.revokeObjectURL(url)
            }
            else
            {
                link = url
            }
        }
    }())

    return (dispatch, getState) => {
        let preview = getState().user.defaultdocumentview
        saveByteArray([data.data.content.Body], data.data.document.key, preview)

        dispatch({
            type: types.SET_QUOTE_DOCUMENT_BYTES,
            payload: {
                component: getState().components['document'],
                data: link,
                filename: data.data.document.key
            }
        })
    }
}

export const setPreviewFlag = (preview) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_DOCUMENT_PREVIEW_FLAG,
            payload: preview
        })
    }
}
export const openEdit = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.OPEN_QUOTE_EDIT_VIEW,
            payload: { open: true }
        })
    }
}
export const closeEdit = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLOSE_QUOTE_EDIT_VIEW,
            payload: { open: false }
        })
    }
}

export const regenerateQuote = (component) => {
    return (dispatch, getState) => {
        const getTaskData = () => {
            const task = getState().workflowTaskInput.task

            return {
                event: types.PDF_SUBMIT_EDIT_WORKFLOW_TASK_INPUT,
                workflow: {
                    workflow_id: task.workflow.workflow_id,
                    name: task.workflow.name,
                    title: task.workflow.title
                },
                workflow_task: {
                    workflow_task_id: task.workflow_task_id,
                    name: task.name,
                    title: task.title
                },
                data: component.grid.data,
                regenerate: true,
                queue: getState().workflowQueueTasks.queue
            }
        }
        dispatch({
            type: types.PDF_SUBMIT_EDIT_WORKFLOW_TASK_INPUT,
            payload: getTaskData()
        })


        dispatch({
            type: types.CLOSE_QUOTE_EDIT_VIEW,
            payload: { open: false }
        }) 
    }
}

export const setMultiDocuments = (documents, prop) => {
    return (dispatch, getState) => {
        let data = []
        //console.log('setMultiDocuments',documents)
        data = documents.filter(x => x).map(x => {
            return {
                prop: prop,
                type: 'GET_PDF_MULTIPLE_VIEW_DOCUMENTS_PREVIEW',
                key: 'quotes/' + ( x.filename ? x.filename : ''),
                filename: x.filename ? x.filename : '',
                preview: x.preview ? x.preview : null,
                filetype: x.filetype ? x.filetype : 'PREVIEW',
            }
        })
        
        //console.log('setMultiDocuments data',data)
        if(prop){
            dispatch({
                type: types.SET_PDF_MULTIPLE_VIEW_DOCUMENTS_WITH_PROP,
                payload: {
                    prop: prop,
                    value: data
                }
            })
        }
        else {
            dispatch({
                type: types.SET_PDF_MULTIPLE_VIEW_DOCUMENTS,
                payload: data
            })
        }
    }
}

export const getMultiDocuments = (prop) => {
    return (dispatch, getState) => {
        const documents = prop ? getState().pdf[prop] : getState().pdf.documents
        documents.forEach(x => {
            dispatch({
                type: types.GET_PDF_MULTIPLE_VIEW_DOCUMENTS_PREVIEW,
                payload: x
            })
        })
    }
}

export const setDocumentPreview = (data) => {
    let link = ''
    const filekey = data.data.document.key.replace('quotes/', '')
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name, preview) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
             
             link = url
             if (preview === 'openall') {
                a.href = url
                a.target = '_blank'

                if (name.toLowerCase().indexOf('.pdf') === -1)
                    a.download = name
                a.click()
                window.URL.revokeObjectURL(url)
             }
             else if (preview === 'downloadall') {
                 a.href = url
                 a.download = name
                 a.click()
                 window.URL.revokeObjectURL(url)
             }
             else if (name.toLowerCase().indexOf('.pdf') === -1) {
                 a.href = url
                 a.download = name
                 a.click()
                 window.URL.revokeObjectURL(url)
             }
             else if (preview === 'buttons') {
                 a.href = url
                 a.target = '_blank'
                 a.click()
                 window.URL.revokeObjectURL(url)
             }
             else
             {
                 link = url
             }
        }
    }())

    return (dispatch, getState) => {
        let preview = getState().user.defaultdocumentview
        saveByteArray([data.data.content.Body], data.data.document.key,preview)

        //console.log('setDocumentPreview data.data', data.data)
        let documents = data.data.document.prop ? getState().pdf[data.data.document.prop] : getState().pdf.documents
        let payload = documents.map(x => {
            return x.filename === filekey
                ? {
                    ...x,
                    preview: link
                }
                : x
        })

        if(data.data.document.prop){
            dispatch({
                type: types.SET_PDF_MULTIPLE_VIEW_DOCUMENTS_WITH_PROP,
                payload: {
                    prop: data.data.document.prop,
                    value: payload
                }
            })
        }
        else {
            dispatch({
                type: types.SET_PDF_MULTIPLE_VIEW_DOCUMENTS,
                payload: payload
            })
        }
    }
}
