

export const GET_RECEIPT_LIST = 'GET_RECEIPT_LIST'
export const GET_DEBTORS_LIST = 'GET_DEBTORS_LIST'
export const GET_BUSINESS_LIST = 'GET_BUSINESS_LIST'
export const GET_CONTRA_ACCOUNT_LIST = 'GET_CONTRA_ACCOUNT_LIST'
export const GET_ITEM_ACCOUNT_LIST = 'GET_ITEM_ACCOUNT_LIST'

export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'
export const SET_ANY_ITEM_INPUT_VALUE = 'SET_ANY_ITEM_INPUT_VALUE'

export const SAVED_RECEIPT_DATA = 'SAVED_RECEIPT_DATA'
export const SAVE_RECEIPT_DATA = 'SAVE_RECEIPT_DATA'

export const GET_RECEIPT_DUPLICATE_REFERENCE_CHECK = 'GET_RECEIPT_DUPLICATE_REFERENCE_CHECK'
export const RECEIPT_DUPLICATE_FOUND = 'RECEIPT_DUPLICATE_FOUND'
export const RECEIPT_NO_DUPLICATE_FOUND = 'RECEIPT_NO_DUPLICATE_FOUND'

export const GET_RCPT_MONTH_LIST = 'GET_RCPT_MONTH_LIST'
export const SET_RCPT_MONTH_LIST = 'SET_RCPT_MONTH_LIST'
export const GET_RCPT_DATA_LIST = 'GET_RCPT_DATA_LIST'

export const GET_BUSINESS_UNIT_LIST = 'GET_BUSINESS_UNIT_LIST'
export const SET_BULK_RECEIPT_DATA = 'SET_BULK_RECEIPT_DATA'

export const SET_ANY_FIELD_INPUT_TOGGLE = 'SET_ANY_FIELD_INPUT_TOGGLE'