import * as types from './types'
import * as config from '../../config'

export const setDashboard = (component,displayType) => {

    return (dispatch) => {
        dispatch({
            type: types.SET_DASHBOARD_DATA,
            payload: {
                type: 'SET_DASHBOARD_DATA',
                component: component,
                displayType: displayType
            }
        })
    }
}

export const getDashboardData = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_DASHBOARD_DATA,
            payload: data?.data[0]
        })
    }
}


export const getReportToken = (url) => {
    return (dispatch) => {
        let key = ''
        const dashboardParam = "dashboard=";
        const startIndex = url.indexOf(dashboardParam);

        const urlIndex = url.indexOf("://") + 3;  // Skip '://'
        const endIndex = url.indexOf("/", urlIndex);
        let domain = endIndex === -1 ? url.substring(urlIndex) : url.substring(urlIndex, endIndex);
        domain = 'https://' + domain + '/api/dashboard?token='

        // If 'dashboard=' exists in the URL, extract the value
        if (startIndex !== -1) {
            const startValueIndex = startIndex + dashboardParam.length;
            const endIndex = url.indexOf('&', startValueIndex); // Find the next '&' or end of string
            
            key = endIndex === -1 ? url.substring(startValueIndex) : url.substring(startValueIndex, endIndex);
        }

        dispatch({
            type: types.GET_OQLIS_REPORT_TOKEN,
            payload: {
                type: "GET_TOKEN",
                domain: domain,
                key: key,
                url: url
            }
        })
    }
}

export const setReportToken = (data, payload) => {
    return (dispatch) => {
        console.log('setReportTokenx data', data.token)
        console.log('setReportTokenx payload', payload)
        let token = data.token ? data.token.token : ''
        let oqlis_token_url = payload.domain + token
        console.log('setReportTokenx oqlis_token_url', oqlis_token_url)
        dispatch({
            type: types.SET_OQLIS_REPORT_TOKEN,
            payload: oqlis_token_url
        })
    }
}

export const getDashboardDataUrl = ( dashboard_key , dashboard) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_DASHBOARD_URL,
            payload: {
                api_key: config.oq.api_key,
                dashboard:dashboard_key,
                url: config.oq.url,
                type: 'GET_DASHBOARD_URL'
            }
        })
    }
}

export const setDashboardDataUrl = (data) => {

    return (dispatch) => {
        dispatch({
            type: types.SET_DASHBOARD_URL,
            payload: data?.response?.token
        })
    }
}

export const getKPIData = (view_name, client_id) => {
    //console.log(view_name)
    return(dispatch) => {
       dispatch({
        type: types.GET_KPI_DATA,
        payload: {view_name: view_name,
            client_id: client_id
        }
       })
    }
}

export const setKPIData = (data) => {
    
    return(dispatch) => {
        dispatch({
            type: types.SET_KPI_DATA,
            payload: data?.response
        })
    }
}