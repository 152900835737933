import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import TreeSelect from '../../controls/treeselect'
import RelatedGrid from '../RelatedGrid/component'

import NumberFormat from 'react-number-format';


import { Tabs,Box, Tab } from '@mui/material'
import * as actions from './actions'


function NumberFormatCustom(props) {
    const { inputRef, onChange, type, ...other } = props;
    switch (type) {
        case 'phone':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                isNumericString
                format="+27 (###) ###-####" 
                mask="_"
            />
        case 'amount':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                fixedDecimalScale
                prefix={'R '}
                isNumericString
            />
        case 'number':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                thousandSeparator={false}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                isNumericString
            />
        case 'month':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="##/##" placeholder="MM/YY" 
                mask={['M', 'M', 'Y', 'Y']}
                isNumericString
            />
        case 'card':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="#### #### #### ####" 
                mask="_"
                isNumericString
            />
        case 'code':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="####"
                mask="_"
                isNumericString
            />
    } 
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

class Test extends Component {
    
    getTabs() {
        return <div>
                <Tabs
                    initialSelectedIndex={0}
                    value={this.props.state.tab}
                    inkBarStyle={{
                        height: 4
                    }}
                    onChange={(event, value) => {
                        this.props.actions.changeTabView(value)
                    }}
                >
                    <Tab style={{minWidth:"50%"}} label={"Test one"} />
                    <Tab style={{minWidth:"50%"}} label={"Test two"} />
                </Tabs>
                <br/>
                <div>
                    {this.props.state.tab === 0 && <div>
                        <TextField
                            label="Phone"
                            value={this.props.state.phone_val}
                            onChange={(event, value) => {
                                this.props.actions.setInputVal({prop: 'phone_val', val: event.target.value})
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            type="phone"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                        <hr />
                        <TextField
                            label="Number"
                            value={this.props.state.number_val}
                            onChange={(event, value) => {
                                this.props.actions.setInputVal({prop: 'number_val', val: event.target.value})
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            type="number"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                        <hr />
                        <TextField
                            label="Amount"
                            value={this.props.state.amount_val}
                            onChange={(event, value) => {
                                this.props.actions.setInputVal({prop: 'amount_val', val: event.target.value})
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            type="amount"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                        <hr />
                        <TextField
                            label="Month"
                            value={this.props.state.month_val}
                            onChange={(event, value) => {
                                this.props.actions.setInputVal({prop: 'month_val', val: event.target.value})
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            type="month"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                        <hr />
                        <TextField
                            label="Card"
                            value={this.props.state.card_val}
                            onChange={(event, value) => {
                                this.props.actions.setInputVal({prop: 'card_val', val: event.target.value})
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            type="card"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </div>}
                    {this.props.state.tab === 1 && <h2>Tabs two</h2>}
                </div>
            </div>
    }

    render() {
        return this.getTabs()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.pTest }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Test)