import * as types from './types'
import moment from 'moment'


export const setupWorkflowTaskInput = (data, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data
        }

        dispatch({
            type: types.CONFIRM_PO_HEADER_SELECTION,
            payload: payload
        })
    }
}

export const setValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_UPLOAD_DOCUMENT_FIELD_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })
    }
}

export const setAcceptedDocuments = (component, documents) => {
    const _file = 'POD_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    //type: types.UPLOAD_RFQ_QUOTE_DOCUMENT,
                    type: types.UPLOAD_COMPONENT_UPLOAD_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: _file,
                        type: file.type,
                    }
                })
            };
        });

        dispatch({
            //type: types.SET_QUITE_PO_FIELD_VALUE,
            type: types.SET_COMPONENT_UPLOAD_DOCUMENT_FIELD_VALUE,
            payload: {
                component: 'purchaseorder',
                field: 'poddocs',
                value: _file
            }
        })

        dispatch({
            //type: types.SET_RFQ_QUOTE_DOCUMENT_PREVIEW,
            type: types.SET_COMPONENT_UPLOAD_DOCUMENT_PREVIEW,
            payload: {
                component: component.name,
                preview: documents[0].preview,
                filename: documents[0].name,
            }
        })
    }
}

export const setRejectedDocuments = (component, documents) => {
    return (dispatch, getState) => {
        dispatch({
            //type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
            type: types.CLEAR_COMPONENT_UPLOAD_DOCUMENT_PREVIEW,
            payload: {
                component: component.name
            }
        })
    }
}

export const clearRFQDocument = (component) => {
    return (dispatch, getState) => {
        dispatch({
            //type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
            type: types.CLEAR_COMPONENT_UPLOAD_DOCUMENT_PREVIEW,
            payload: {
                component: 'document',
                field: 'content',
                value: '',
            }
        })
    }
}

export const loadVAFDocuments = (documents) => {
    return (dispatch, getState) => {
        documents.forEach(x => {
            if (x.filename) {
                dispatch({
                    type: types.GET_CONTRACT_VAF_PREVIEW_DOCUMENTS,
                    payload: {
                        type: 'GET_CONTRACT_VAF_PREVIEW_DOCUMENTS',
                        key: 'quotes/' + x.filename
                    }
                })
            }
        })
    }
}

export const setDocumentPreview = (data) => {
    let link = ''
    const filekey = data.data.document.key.replace('quotes/', '')
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_VAT_DOCUMENT_PREVIEW,
            payload: {
                preview: link,
                filename:filekey,
            }
        })
    }
}
