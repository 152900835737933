import * as types from './types'
import _ from 'lodash'

export const getDashboard = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_STR_DASHBOARD,
            payload: {
                type: 'get_dashboard',
                search: search
            }
        })
    }
}


export const setDashboard = (payload) => {
    //console.log('setDashboard',payload)
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_STR_DASHBOARD,
            payload: payload
        })
    }
}
