export const loading = true

export const paging = {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    rowCount: 0,
    sorted: [],
    filtered: []
}

export const data = []