import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'
import IconButton from '@mui/material/IconButton'
import Icon from '../Icon/component'
import { Card, CardHeader, CardContent, TextField } from '@mui/material'


import ReactTable from "react-table"
import "react-table/react-table.css"
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

class CardParameters extends Component {

    getFormFieldValue(field, index) {
       return  <TextField 
                id={'txt_' + field.name}
                key={'txt_' + field.name}
                label={'Enter ' + field.title}
                fullWidth={true}
                variant="outlined"
                color="secondary"
                // helperText={this.component.data[field.name].validation.message}
                // value={this.component.data[field.name].input}
                // onChange={
                //     (event, value) => {
                //         this.props.actions.setComponentFieldInput(field, value)
                //     }
                // }
                />
    }

    getField(field, index) {
        return <div style={styles.fieldContainer}>
                <div style={styles.fieldTitle}>{field.title}</div>
                <div style={styles.fieldContent}>{this.getFormFieldValue(field, index)}</div>
            </div>
    }

    getSection(section, index) {
        let fields = section.fields
            .map((x, i) => {
                return <Col key={'col_' + x.name} xl={6}>{this.getField(x, i)}</Col>
            })

        return <Card key={'card_' + section.name} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={'material-icons'} iname={'edit'} />}
                title={section.title}
                subtitle={section.description}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        {fields}
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }
   
    getForm() {
        return this.props.state.cardParameters
            .map((x, i) => {
                return this.getSection(x, i)
            })
    }

    render() {

        return this.props.state.cards.length
                ? this.getForm()
                : <Loading message={'Loading component...'} />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.cardManagement,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardParameters)