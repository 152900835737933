import * as types from './types'
import moment from 'moment'
import * as _ from 'lodash'


export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
    }
}

export const getBatchNumber = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BATCH_NUMBER,
            payload: {
                type: 'GET_BATCH_NUMBER'
            }
        })
    }
}

export const setBatchNumber = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BATCH_NUMBER,
            payload: data.data
        })
    }
}

export const setTaskNumber = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PAYMENT_TASK_NUMBER,
            payload: data
        })
    }
}


export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PAYMENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}
