import * as types from './types'
import * as appTypes from '../../App/types'
import moment from 'moment'

import { ExcelRenderer, OutTable } from 'react-excel-renderer'
import * as xlsx  from 'xlsx'

export const setInputValue = (prop, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_BANK_RECONCILIATION_INPUT_VALUE,
            payload: {
                prop: prop,
                data: data
            }
        })
    }
}

export const getBankReconDataLookup = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_BANK_RECONCILIATION_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
                query: `?${field}=${search}`
            }
        })
    }
}

export const setBankReconDataLookup = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setDataLookup set',response.data)
        dispatch(setInputValue(payload.component + '_data',response.data))
    }
}

export const setBankReconReport = (response) => {
    return (dispatch, getState) => {
        //console.log('setBankReconReportx set',response)
        dispatch(setInputValue('report_data', response.data))
    }
}
export const getBankReconReport = (accountingitem_id, start, end, report_type) => {
    return (dispatch, getState) => {
      
        if(accountingitem_id && start && end && report_type) {
            dispatch({
                type: types.SET_COMPONENT_BANK_RECONCILIATION_REPORT_DATA,
                payload: {
                    type: 'get_bankrecon_report',
                    accountingitem_id: accountingitem_id,
                    start: start,
                    end: end
                }
            })
        }
        else
        {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'Error: System Notification',
                    additionalText: 'Please complete all details.',
                    overflowText: '',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}

export const getBankReconPDFReport = (data) => {
    return (dispatch, getState) => {
        let report_data = data.report_data
            ? data.report_type_value === null 
                ? data.report_data 
                : data.report_type_value
                    ? data.report_data.filter(x => x.reconciled === true)
                    : data.report_data.filter(x => x.reconciled !== true)
            : []

        let _report_data = report_data.map(x => { return {...x, transaction_date: moment(x.transaction_date).format('YYYY-MM-DD')}})
        let _data = {...data, report_data: _report_data}
        dispatch({
            type: types.GET_BANKRECON_PDF_REPORT,
            payload: {
                type: 'GET_BANKRECON_PDF_REPORT',
                data: _data
            }
        })
    }
}

export const getBankReconCSVReport = (data) => {
    return (dispatch, getState) => {
        let report_data = data.report_data
            ? data.report_type_value === null 
                ? data.report_data 
                : data.report_type_value
                    ? data.report_data.filter(x => x.reconciled === true)
                    : data.report_data.filter(x => x.reconciled !== true)
            : []

        let _report_data = report_data.map(x => { return {...x, transaction_date: moment(x.transaction_date).format('YYYY-MM-DD')}})
        let _data = {...data, report_data: _report_data}
        dispatch({
            type: types.GET_BANKRECON_CSV_REPORT,
            payload: {
                type: 'GET_BANKRECON_CSV_REPORT',
                data: _data
            }
        })
    }
}

export const getBankReconXLSReport = (data) => {
    return (dispatch, getState) => {
        // dispatch({
        //     type: types.GET_BANKRECON_XLS_REPORT,
        //     payload: {
        //         type: 'GET_BANKRECON_XLS_REPORT',
        //         data: data
        //     }
        // })

        // dispatch({
        //     type: types.GET_CASHBOOK_XLS_REPORT,
        //     payload: {
        //         type: 'GET_CASHBOOK_XLS_REPORT',
        //         data: data
        //     }
        // })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Excel report downloading.',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        let excel_headers = getState().cashBook.excel_headers
        const rows = [[
            'Transaction Date',
            'Transaction Reference',
            'Transaction Description',
            'Transaction Amount',
            'Reconciled/Unreconciled',
            'Balance'
        ]]
        //console.log('rowsx data', data)  
        
        
        let report_data = data.report_data
            ? data.report_type_value === null 
                ? data.report_data 
                : data.report_type_value
                    ? data.report_data.filter(x => x.reconciled === true)
                    : data.report_data.filter(x => x.reconciled !== true)
            : []



        for (let i = 0; i < report_data.length; i++) {
            let datarow = report_data[i];
            //console.log('rowsx datarow', datarow)  
            let rowData = [];
            for (let j of excel_headers) {
                if(j.type === 'date') {
                    //console.log('rowsx datarow date mm', moment(datarow[j.name]).format('YYYY-MM-DD'))  
                    rowData.push(moment(datarow[j.name]).format('YYYY-MM-DD'));
                }
                else
                    rowData.push(datarow[j.name]);
            }
            //console.log('rowsx rowData', rowData)       
            rows.push(rowData);
        }

        
        //console.log('rowsx rows', rows)         
        // const footer = [];
        // for (let j of bulk_headers) {
        //     //console.log('headers[j]x', j.name)
        //     footer.push((j.name == 'excl' || j.name == 'vat' || j.name == 'incl' )
        //     ? _.sumBy(data, (d) => { return parseFloat((d[j.name]) ? d[j.name].toString().replace(/,/gi, '') : 0) })
        //     : '')
        // }  

        // rows.push(footer);

        const workbook = xlsx.utils.book_new();
        const worksheet = xlsx.utils.aoa_to_sheet(rows);
        xlsx.utils.book_append_sheet(workbook, worksheet, "Bank Recon Report");

        //const excelBlob = new Blob([xlsx.write(workbook, { type: 'blob' })], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const excelBuffer = xlsx.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        let filename = 'BankRecon_Report_'+moment().format('YYMMDDHHmm')+'.xlsx'
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

    }
}

export const viewPdfDocument = (data) => {
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())

    //console.log('data.data.documentx',data.data)

    saveByteArray([data.data.content.Body], data.data.filename)

    return (dispatch, getState) => {
        dispatch(setInputValue('cashbook_pdf_preview', data.data.document.key))
      
    }
}