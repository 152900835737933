import * as types from './types'

export const createTeam = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CREATE_TEAM
        })
    }
}

export const updateTeam = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_TEAM
        })
    }
}

export const getTeamUsers = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_TEAM_USERS
        })
    }
}

export const deactivateTeam = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.DEACTIVATE_TEAM
        })
    }
}

export const activateTeam = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ACTIVATE_TEAM
        })
    }
}

export const assignUserToTeam = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ASSIGN_USER_TO_TEAM
        })
    }
}

export const removeUserFromTeam = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REMOVE_USER_FROM_TEAM
        })
    }
}

export const resetUserPassword = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_USER_PASSWORD
        })
    }
}

export const deactivateUser = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.DEACTIVATE_USER
        })
    }
}

export const activateUser = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ACTIVATE_USER
        })
    }
}
export const changeTeamsTab = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CURRENT_TEAM_MANAGEMENT_TAB,
            payload: id
        })
    }
}