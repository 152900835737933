import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {

        case types.SET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA:
        return {
            ...state,
            chip_accessory: action.payload,
        }
        case types.SET_VEHICLE_SUNDRY_SELECTION_CATEGORY_LOOKUP_DATA:
        return {
            ...state,
            chip_sundry: action.payload,
        }
        case types.SET_VEHICLE_SERVICE_SELECTION_CATEGORY_LOOKUP_DATA:
        return {
            ...state,
            chip_service: action.payload,
        }
        case types.TOGGLE_CUSTOM_PROPS_VALUES:

        window.glyco.log('TOGGLE_CUSTOM_PROPS_VALUES')
        window.glyco.log(action.payload)
        return {
            ...state,
            [action.payload.props]: action.payload.state,
        }
        case types.SET_COMPONENT_LOOKUP_DATA_VALUES:
        return {
            ...state,
            [action.payload.prop]: action.payload.value,
        }
        case types.SET_STR_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                }
            }
        case types.SET_COMPONENT_VALIDATION_PROPS_FIELDS:
            return {
                ...state,
                workflowTaskInput: {
                    ...state.validationfields,
                    validationfields: action.payload
                }
            }
        // case types.SET_VEHICLE_DEFAULT_ACCESSORY_LIST:
        // return {
        //     ...state,
        //     vehicle_default_accessory: action.payload,
        // }
        default:
            return state
    }
}