export const isOpen = false;
export const loading = false;
export const infodatainx = null;
export const chip_filter = '';
export const showdetails = false;
export const inforsheet_view = false;
export const inforsheet_edit = false;
export const inforsheet_forum = false;
export const inforsheet_documents = false;
export const inforsheet_offers = false;
export const inforsheet_disposal = false;
export const inforsheet_settlement = false;
export const inforsheet_decision = false;
export const inforsheet_otp_list = false;
export const inforsheet_checksheet = false;
export const inforsheet_invoice = false;
export const checksheet_preview = null;
export const tab = 0;

export const inforsheet = {
  inforsheet_opt_list: false,
  inforsheet_view: false,
  inforsheet_edit: false,
  inforsheet_forum: false,
  inforsheet_documents: false,
  inforsheet_offers: false,
  inforsheet_disposal: false,
  inforsheet_settlement: false,
  inforsheet_decision: false,
  inforsheet_checksheet: false,
  inforsheet_invoice: false,
};

export const addoffer = false;
export const import_rows = [];
export const import_filename = null;

export const funder_settlement_amount = 0;
export const absa_contract_enddate = 0;
export const absa_residual_value = 0;
export const acc_depreciation = 0;
export const absa_remaining_months = 0;


export const infosheet_inx = 0;
export const yardlist = [];
export const bidders = [];
export const template = null;
export const disposalcomment_list = [];
export const fields = [
  {
    name: "vehicle_id",
    display: "vehicle_id",
    title: "vehicle_id",
    key: true,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "yardin_datetime",
    display: "yardin_datetime",
    title: "Month",
    key: false,
    type: "month",
    width: 80,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "listnumber",
    display: "listnumber",
    title: "List Number",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "licenseplateno",
    display: "licenseplateno",
    title: "Registration",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "bestoffer",
    display: "bestoffer",
    title: "Best Offer",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "bestofferfinal",
    display: "bestofferfinal",
    title: "Final Offer",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "vehiclestatus_fktext",
    display: "vehiclestatus_fktext",
    title: "Status",
    key: false,
    type: "text",
    width: 200,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
//   {
//     name: "registrationdate",
//     display: "registrationdate",
//     title: "Reg Date",
//     key: false,
//     type: "date",
//    // width: 200,
//     edit: false,
//     bold: true,
//     right: false,
//     toggle: "",
//   },
  {
    name: "chassisnumber",
    display: "chassisnumber",
    title: "Vin Number",
    key: false,
    type: "text",
    width: 180,
   // width: 200,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "closingodometer",
    display: "closingodometer",
    title: "Odometer",
    key: false,
    type: "text",
    width: 150,
    //width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "makemodelyear",
    display: "makemodelyear",
    title: "Make Model",
    key: false,
    type: "text",
    width: 400,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
];
export const otp = [];
export const otp_fields = [
  {
    name: "otp_id",
    display: "otp_id",
    title: "otp_id",
    key: true,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "listnumber",
    display: "listnumber",
    title: "List Number",
    key: false,
    type: "text",
    width: 200,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "bidnumber",
    display: "bidnumber",
    title: "Bid Number",
    key: false,
    type: "text",
    width: 200,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "vehiclecount",
    display: "vehiclecount",
    title: "Vehicle Count",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "created_at",
    display: "created_at",
    title: "Date Created",
    key: false,
    type: "date",
    width: 300,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "send_at",
    display: "send_at",
    title: "Send Date",
    key: false,
    type: "date",
    width: 200,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
];
export const document_headers = [
  {
    name: "document_id",
    display: "document_id",
    title: "document_id",
    key: true,
    type: "text",
    width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "filename",
    display: "document.filename",
    title: "Filename",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "description",
    display: "document.description",
    title: "Description",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: false,
    right: false,
    toggle: "",
  },
  {
    name: "date",
    display: "document.created_at",
    title: "File Date",
    key: false,
    type: "date",
    width: 100,
    edit: false,
    bold: false,
    right: false,
    toggle: "",
  },
];
export const offers_headers = [
  {
    name: "disposalbidder_id",
    display: "disposalbidder_id",
    title: "disposalbidder_id",
    key: true,
    type: "text",
    width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "biddername",
    display: "biddername",
    title: "Bidder Name",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "offerbasedonrepaired",
    display: "offerbasedonrepaired",
    title: "Offer With Repairs",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: false,
    right: false,
    toggle: "",
  },
  {
    name: "offerbasedonunrepaired",
    display: "offerbasedonunrepaired",
    title: "Offer With No Repairs",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: false,
    right: false,
    toggle: "",
  },
  {
    name: "rebillvalue",
    display: "rebillvalue",
    title: "Rebill Value",
    key: false,
    type: "text",
    width: 150,
    edit: false,
    bold: false,
    right: false,
    toggle: "",
  },
  {
    name: "created_at",
    display: "created_at",
    title: "Offer Send Date",
    key: false,
    type: "date",
    width: 100,
    edit: false,
    bold: false,
    right: false,
    toggle: "",
  },
  {
    name: "offerrecieveddate",
    display: "offerrecieveddate",
    title: "Offer Receive Date",
    key: false,
    type: "date",
    width: 100,
    edit: false,
    bold: false,
    right: false,
    toggle: "",
  },
];
export const template_list = [
  {
    value: 1,
    text: "Bidder Offers",
    key: "offers",
    types: [],
  },
//   {
//     value: 2,
//     text: "ABSA List",
//     key: "absa",
//     types: [],
//   },
];

export const offer_export_headers = [
  { key: "licenseplateno", header: "Reg No" },
  { key: "vehicle_has_damages", header: "Damages" },
  { key: "yard_name", header: "Location" },
  { key: "registrationdate", header: "Year of First Registration" },
  { key: "makemodelyear", header: "Vehicle Description" },
  { key: "enginenumber", header: "Engine Number " },
  { key: "chassisnumber", header: "Vin Number" },
  { key: "closingodometer", header: "Mileage" },
  { key: "accessories", header: "Accessories" },
  { key: "bestoffer", header: "Offer 1 based on Repaired" },
  { key: "bestofferbeforerebill", header: "Offer 2 based on Unrepaired" },
];
export const excel = [
  { key: "yardin_datetime", header: "Month" },
  { key: "list_number", header: "List Number" },
  { key: "licenseplateno", header: "Registration Number" },
  { key: "yard_name", header: "Location" },
  { key: "registrationdate", header: "Year Model" },
  { key: "makemodelyear", header: "Vehicle Description" },
  { key: "closingodometer", header: "Mileage" },
  { key: "chassisnumber", header: "VIN" },
  { key: "fundername", header: "Funder" },
  { key: "startdate", header: "Contract Start Date" },
  { key: "yardin_datetime", header: "Date Vehicle Returned" },
  { key: "billingcount", header: "Number of Months in Service" },
  { key: "contracttype", header: "Deal Type" },
  { key: "bidder_name", header: "Buyer" },
  { key: "bidder_invoice_date", header: "Buyers Invoice Date" },
  { key: "bidder_invoice_amount", header: "Buyers Invoice Value" },
  { key: "bidder_payment_date", header: "Buyers Payment Received Date" },
  { key: "absa_contract_enddate", header: "ABSA Contract End Data" },
  { key: "absa_residual_value", header: "ABSA Residual Value" },
  { key: "absa_remaining_months", header: "ABSA Months to Go" },
  { key: "mmcode", header: "M&M Code" },
  { key: "tradevalue", header: "Trade Value" },
  { key: "retailvalue", header: "Retail value" },
  { key: "marketvalue", header: "Market" },
  { key: "bestofferfinal", header: "Best Offer Final" },
  { key: "bidder_name", header: "Buyer" },
  { key: "customer_invoice_number", header: "Invoice Number" },
  { key: "rebilldate", header: "Rebill Invoice Date" },
  { key: "invoicedate", header: "Rebill Payment Date" },
  { key: "hierarchyname", header: "Customer Name" },
  { key: "yardlist_comments", header: "Yard List Comments" },
  { key: "yardin_datetime", header: "Date of Defleet" },
  { key: "bookingdays", header: "Estimated Repair Days" },
  { key: "questions_for_tech", header: "Questions for Technical" },
  { key: "tech_comments", header: "Technical Comment Why Vehicle Is Not Ready" },
  { key: "totalamount", header: "Rebill Amount Inclusive Vat" },
  { key: "storagefee", header: "Storage Fee" },
  { key: "transportfee", header: "Transport Cost" },
  { key: "amount", header: "Panel Beaters Quotation Fee " },
  {
    key: "nbv_settlement_incl",
    header: "Nett Book Value/Settlement Exclusive Vat",
  },
  {
    key: "nbv_settlement_excl",
    header: "Nett Book Value/Settlement Inclusive Vat",
  },
  {
    key: "maintenancefundbalance",
    header: "Maintenance Fund Balance Excluding Vat",
  },
  { key: "payment_received_from_bidder", header: "Payment Received from Buyer" },
  { key: "payment_outstanding_from_adn", header: "Payment Outstanding from Infleet" },
  { key: "commission", header: "Commission" },
  { key: "soldasis", header: "Is Vehicle Being Sold as Is" },
  {
    key: "difference_offer_settlement",
    header: "Difference Between Best Offer and Settlement",
  },
  {
    key: "difference_offer_settlement_equalisation",
    header:
      "Difference Between Best Offer and Settlement + Capital Equalisation Fund",
  },
  {
    key: "profit_lost_capital_equalisation",
    header: "Profit/Loss Exclusive Capital Equalisation Fund",
  },
  { key: "profit_lost", header: "Profit/Loss" },
  { key: "absa_monthly_rental", header: "ABSA Monthly Rental" },
  { key: "ceo_comments", header: "Darren Comments	" },
  { key: "fin_comments", header: "Finance Comments" },
];
export const commentfield = "";

export const retailvalue = null;
export const tradevalue = null;
export const marketvalue = null;
///bidder
export const biddername = null;
export const repairoffer = null;
export const nonrepairoffer = null;
export const oftrade = null;
export const ofmarket = null;
export const import_bidder = null;
export const bidders_list = [
  {
    value: "Mario",
    text: "Mario",
    key: "Mario",
    types: [],
  },
  {
    value: "Mandos",
    text: "Mandos",
    key: "Mandos",
    types: [],
  },
//   {
//     value: "Jawwal Investments",
//     text: "Jawwal Investments",
//     key: "Jawwal Investments",
//     types: [],
//   },
//   {
//     value: "Tirynn Investments",
//     text: "Tirynn Investments",
//     key: "Tirynn Investments",
//     types: [],
//   },
//   {
//     value: "SD Auto",
//     text: "SD Auto",
//     key: "SD Auto",
//     types: [],
//   },
//   {
//     value: "Lucky Penny Trading",
//     text: "Lucky Penny Trading",
//     key: "Lucky Penny Trading",
//     types: [],
//   },
//   {
//     value: "Repair City Holdings",
//     text: "Repair City Holdings",
//     key: "Repair City Holdings",
//     types: [],
//   },
//   {
//     value: "Mario Consignment",
//     text: "Mario Consignment",
//     key: "Mario Consignment",
//     types: [],
//   },
//   {
//     value: "SA Car",
//     text: "SA Car",
//     key: "SA Car",
//     types: [],
//   },
//   {
//     value: "H and Z Motors",
//     text: "H and Z Motors",
//     key: "H and Z Motors",
//     types: [],
//   },
//   {
//     value: "Aksons",
//     text: "Aksons",
//     key: "Aksons",
//   }
];
//Decision
export const decisioncomments = "";
export const decision = "";
export const decision_list = [
  {
    value: "Sell Vehicle",
    text: "Sell Vehicle",
    key: "sell",
    types: [],
  },
  {
    value: "Re-purpose",
    text: "Re-purpose",
    key: "repurpose",
    types: [],
  },
];

export const optlist_data = [];
export const optlist_data_header = [
  {
    name: "vehicle_id",
    display: "vehicle_id",
    title: "vehicle_id",
    key: true,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "licenseplateno",
    display: "licenseplateno",
    title: "Reg No",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "vehicle_has_damages",
    display: "vehicle_has_damages",
    title: "Damages",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "yard_name",
    display: "yard_name",
    title: "Location",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "registrationdate",
    display: "registrationdate",
    title: "Year of First Registration",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "makemodelyear",
    display: "makemodelyear",
    title: "Vehicle Description",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "enginenumber",
    display: "enginenumber",
    title: "Engine Number ",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "chassisnumber",
    display: "chassisnumber",
    title: "Vin Number",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "closingodometer",
    display: "closingodometer",
    title: "Mileage",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "vehicleaccessories",
    display: "vehicleaccessories",
    title: "Accessories",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "bestoffer",
    display: "bestoffer",
    title: "Offer 1 based on Repaired",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
  {
    name: "bestofferbeforerebill",
    display: "bestofferbeforerebill",
    title: "Offer 2 based on Unrepaired",
    key: false,
    type: "text",
    width: 120,
    edit: false,
    bold: true,
    right: false,
    toggle: "",
  },
];
