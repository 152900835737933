import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                accessory_list: [],
                send_marketing: '',
                marketingtype: '',
                marketingtype_id: null,
                customerdata: {},
                recieveddate: '',
                comments: '',
                regno: '',
                vin: '',
                engineno: '',
                marketingtype_data: []

            }
        case types.SET_MM_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        default:
            return state
    }
}