import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment';
import { Container, Row, Col } from 'react-grid-system'
import * as _ from 'lodash'
import Loading from '../Loading/component'
import PdfViewer from '../PDF/component'

import AdhocComponentFieldInput from '../Custom/AdhocComponentFieldInput/component'
import Button from '@mui/material/Button' // v1.x  //import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox'
import Icon from '../Icon/component'

import TextField from '@mui/material/TextField';
import Card from './../../controls/card'

import * as actions from './actions'
import * as styles from './styles'
import ViewVehicle from './../../controls/view_vehicle'


class CancelledTasksApprovalGM extends Component {

    cancelDetails(data) {
        return data
            ? <div>
                <Card
                    title={window.t.en('Cancel Details: ')}
                    subtitle={window.t.en('Cancellation Information')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Reject Reason:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.selectcomments}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'User Comments:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.comments}</Col>
                        </Row>
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Reject Date:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{moment(data.rejected_data).format('YYYY-MM-DD HH:mm')}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Reject By:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.rejected_by}</Col>
                        </Row>

                    </Container>} />
            </div>
            : ''
    }

    uploadedDocuments(data) {
        //Check object if has documents before rendering 
        return data.length
            ? <div>
                <Card
                    title={'Uploaded Supporting Documents'}
                    subtitle={''}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {
                                    data
                                        ? <PdfViewer
                                            key={'document_viewer_approval_GM'}
                                            name={'booking_GM'}
                                            workflow={''}
                                            task={''}
                                            documents={data}
                                            edit={false}
                                            multiple={true}
                                            workflow_queue_data={this.props.workflow_queue_data}
                                        />
                                        : <Loading message={'Retrieving Documents...'} />
                                }
                            </Col>
                        </Row>
                    </Container>} />
            </div>
            : ''
    }

    docDetails(data) {
        return data
            ? <div>
                <Card
                    title={window.t.en('Documents ')}
                    subtitle={window.t.en('Related Documents')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <PdfViewer
                                    key={'document_viewer_approval'}
                                    name={'booking'}
                                    workflow={'fml_customer_quote'}
                                    task={'tsk_purchase_order_review_and_send'}
                                    documents={data.data.documents}
                                    edit={false}
                                    default_document={'Authorisation'}
                                    workflow_queue_data={this.props.workflow_queue_data}
                                />
                            </Col>
                        </Row>
                    </Container>} />
            </div>
            : ''
    }
    taskDetails(data) {
        const step = data.data.step2 ? data.data.step2 : data.data.step1
        return data
            ? <div>
                <Card
                    title={'Task Details: '}
                    subtitle={''}
                    content={<div><Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Created Date:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.data.purchaseOrderType.text}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Created Date:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.preauthdate}</Col>
                        </Row>
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.preauthnumber}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Registration:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.data.vehicle[0].licenseplateno}</Col>
                        </Row>
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Description:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.data.vehicle[0].makemodelyear}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Customer:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.data.vehicle[0].customername}</Col>  
                        </Row>
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Supplier:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.data.supplier[0].suppliername}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.data.supplier[0].suppliertype}</Col>// fktext removed
                        </Row>
                    </Container>

                        {
                            data.data.step2
                                ? <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={2} style={styles.fieldLabel}>{'Quote Date:'}</Col>
                                        <Col xl={4} style={styles.selectedFieldName}>{data.data.step2.datestamp}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'Quoted Amount Excl:'}</Col>
                                        <Col xl={4} style={styles.selectedFieldName}>{data.data.step2.quotetotal.total_amount_exl}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={2} style={styles.fieldLabel}>{'Discounted Amount:'}</Col>
                                        <Col xl={4} style={styles.selectedFieldName}>{data.data.step2.quotetotal.total_amount_dis}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'Quoted Amount Inc:'}</Col>
                                        <Col xl={4} style={styles.selectedFieldName}>{data.data.step2.quotetotal.total_amount_inc}</Col>
                                    </Row>

                                </Container>
                                : ''
                        }
                        {
                            //Not all cancelled task will have step2 object check is exist before render item
                            data.data.step2
                                ? <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12} style={styles.selectedFieldName}><br /></Col>
                                    </Row>
                                    <Row>
                                        <Col xl={2} style={styles.selectedFieldName}>{'Class'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{'Description'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Rebill'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Quantity'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Price'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Discount'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'VAT'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{'Amount'}</Col>
                                    </Row>
                                    {
                                        data.data.step2.rebill_items.map(x => {
                                            return <Row>
                                                <Col xl={2}>{x.Class}</Col>
                                                <Col xl={3}>{x.Description}</Col>
                                                <Col xl={1}>{x.Rebill ? 'Yes' : 'No'}</Col>
                                                <Col xl={1}>{x.Quantity}</Col>
                                                <Col xl={1}>{x.Price}</Col>
                                                <Col xl={1}>{x.DiscountAmount}</Col>
                                                <Col xl={1}>{x.VAT}</Col>
                                                <Col xl={2}>{x.Amount}</Col>
                                            </Row>
                                        })
                                    }
                                    {
                                        data.data.step2.auth_items.map(x => {
                                            return <Row>
                                                <Col xl={2}>{x.Class}</Col>
                                                <Col xl={3}>{x.Description}</Col>
                                                <Col xl={1}>{x.Rebill ? 'Yes' : 'No'}</Col>
                                                <Col xl={1}>{x.Quantity}</Col>
                                                <Col xl={1}>{x.Price}</Col>
                                                <Col xl={1}>{x.DiscountAmount}</Col>
                                                <Col xl={1}>{x.VAT}</Col>
                                                <Col xl={2}>{x.Amount}</Col>
                                            </Row>
                                        })
                                    }
                                </Container>
                                : ''
                        }</div>} />
            </div>
            : ''
    }

    defleetTaskDetails(data) {
        //console.log("datadatadatadata", data)
        //const submitted_data = data.data.step1.submitted_data        
        const task_data = data.data.step1.context_data.data.data.data.length ? data.data.step1.context_data.data.data.data[0] : []
        const working_data = task_data.step3 ? task_data.step3 : task_data.step1
        const vehicle = working_data.data.selectedVehicleDetails.data.length ? working_data.data.selectedVehicleDetails.data[0] : task_data.step1.data.selectedVehicleDetails.data[0]
        return working_data
            ? <div>
                <Card
                    title={'Task Details: '}
                    subtitle={''}
                    content={<div><Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Created Date:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{working_data.datestamp}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Created By:'}</Col>
                            <Col xl={4} style={styles.selectedFieldName}>{working_data.user.firstname + ' ' + working_data.user.lastname}</Col>
                        </Row>
                    </Container>
                        {
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12} style={styles.selectedFieldName}><br /></Col>
                                </Row>
                                {data.data.step3 ?
                                    <Row>
                                        <Col xl={2} style={styles.selectedFieldName}>{'Class'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{'Description'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Rebill'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Quantity'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Price'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Discount'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'VAT'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{'Amount'}</Col>
                                    </Row> : ''}
                                {data.data.step3 ?
                                    data.data.step3.rebill_items.map(x => {
                                        return <Row>
                                            <Col xl={2}>{x.Class}</Col>
                                            <Col xl={3}>{x.Description}</Col>
                                            <Col xl={1}>{'Yes'}</Col>
                                            <Col xl={1}>{x.Quantity}</Col>
                                            <Col xl={1}>{x.Price}</Col>
                                            <Col xl={1}>{x.DiscountAmount}</Col>
                                            <Col xl={1}>{x.VAT}</Col>
                                            <Col xl={2}>{x.Amount}</Col>
                                        </Row>
                                    })
                                    : ''
                                }
                            </Container>
                        }
                    </div>} />
                <br></br>
                <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
            </div>
            : ''
    }
    getForm(data) {
        //console.log(">>>>>>>>>>> Data GM", data)
        const step = data.data.step1.data.data.cancel_data
        const step2 = data.data.step2 ? data.data.step2.data.data.cancel_data : {}
        return data.data.step2 && data.data.step1 ? <div>
            <Card
                title={'Technical Manager Comments '}
                subtitle={''}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}><b>{moment(data.data.step1.datestamp).format('YYYY-MM-DD HH:mm')}</b></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}><b>{data.data.step1.user.firstname + ' ' + data.data.step1.user.lastname}</b></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.data.step1.user.email}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.data.step1.user.phone}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txt_podcomments'}
                                fullWidth={true}
                                disabled={true}
                                value={step.comments ? step.comments : 'There are no comments from Technical Manager'}
                                onChange={(event, value) => {
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                </Container>} />
            <Card
                title={'General Operations Manager Comments '}
                subtitle={''}
                comtent={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}><b>{moment(data.data.step2.datestamp).format('YYYY-MM-DD HH:mm')}</b></Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}><b>{data.data.step2.user.firstname + ' ' + data.data.step2.user.lastname}</b></Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.data.step2.user.email}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.data.step2.user.phone}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txt_podcomments'}
                                fullWidth={true}
                                disabled={true}
                                value={step2.comments ? step2.comments : 'There are no comments from General Operations Manager'}
                                onChange={(event, value) => {
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txt_podcomments'}
                                hintText={'Enter Comments'}
                                fullWidth={true}
                                value={this.props.state.comments}
                                onChange={(event, value) => {
                                    this.props.actions.setValue('comments', event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                </Container>} />
        </div> : ''
    }

    getView(data) {
        //console.log("Switch for Defleet")
        //console.log("Switch for DefleetSwitch for Defleet", data)
        const step = data.data.step1.submitted_data.workflow.title ? data.data.step1.submitted_data.workflow.title : ''
        const documents = data.data.documents
        const sup_docs = data.data.step1.data.data.documents.length ? data.data.step1.data.data.documents : []
        return <Card
            title={'Cancelled Task '}
            subtitle={'Details of the cancelled task.'}
            content={<div>
                {this.cancelDetails(data)}
                {step == 'Defleet' ? this.defleetTaskDetails(data) : this.taskDetails(data)}
                {this.docDetails(documents)}
                {this.uploadedDocuments(sup_docs)}
                {data ? this.getForm(data) : ''}
            </div>} />
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        let data = null

        try {
            data = {
                data: workflow_queue_data.context_data.data.data.data[0],
                rejected_reason: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.comments,
                selectcomments: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.selectcomments,
                comments: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.comments,
                rejected_by: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.user[0].firstname,
                rejected_data: workflow_queue_data.context_data.created_at,
                cancelled_workflow: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.workflow.title,
                cancelled_task: workflow_queue_data.context_data.data.data.data[0].step1.submitted_data.workflow_task.title
            }
        }
        catch (err) { }

        return (
            data
                ? this.getView(data)
                : 'Loading, Please wait GM...'
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.cancelledTasksApprovalGM
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelledTasksApprovalGM)