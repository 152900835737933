import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
// import MarkerClusterGroup from 'react-leaflet-markercluster'

import Icon from '../Icon/component'
import * as poHeaderActions from '../WorkflowPOHeader/POHeader/actions'

import * as actions from './actions'
import * as styles from './styles'

class MinMap extends Component {
    componentDidMount() {
        // Code here
    }

    getMarkers() {
        const position = this.props.position

       return position.markers.length > 0
            ? position.markers
                .map(x => {
                    return x
                    ? <Marker position={
                        [x.lat, x.lon]
                    }
                    >
                        <Popup>
                            <span onClick={(event) => {
                                const request = {
                                    value: x.id,
                                    text: x.name
                                }
                                if(x.type === 'suppliers'){
                                    this.props.actions.setSupplierAutoCompleteSelected(request, 0)
                                    this.props.actions.getSelectedSupplierDetails(request, 0)
                                }
                            }}>
                                <div>
                                    <strong>
                                        <Icon iname={x.type === 'vehicle' ? 'vehicles' : 'debtor'} iclass='custom-icons' /> {x.name}
                                    </strong>
                                </div>
                            </span>
                        </Popup>
                    </Marker>
                    : ''
                })
            : ''
    }

    getView(data) {
        let position = null
        const vehiceLastPosition = this.props.vehiceLastPosition
        try{
            const vehicle_pos = this.props.position.markers.filter(x => x.type === 'vehicle')
            position = [vehicle_pos[0].lat, vehicle_pos[0].lon]
        }catch(err){
            position = [this.props.state.latitude, this.props.state.longitude]
        }

        return position
        ? (
            <div>
                {/* <div style={styles.sources}>
                
                    {
                        vehiceLastPosition.length
                            ? vehiceLastPosition[0].lastposition.length
                                ? <div>
                                    <table>
                                        <tr>
                                            <td>{'Last Position Date:'}</td>
                                            <td style={styles.labelTitle}>{moment(new Date(vehiceLastPosition[0].lastposition[0].positiondate.split('T')[0])).format('DD/MM/YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td>{'Tracking Odometer:'}</td>
                                            <td style={styles.labelTitle}>{vehiceLastPosition[0].lastposition[0].odometer}</td>
                                        </tr>
                                        <tr>
                                            <td>{'Reported Province:'}</td>
                                            <td style={styles.labelTitle}>{vehiceLastPosition[0].lastposition[0].geo_province}</td>
                                        </tr>
                                        <tr>
                                            <td>{'Reported Suburb:'}</td>
                                            <td style={styles.labelTitle}>{vehiceLastPosition[0].lastposition[0].geo_suburb}</td>
                                        </tr>
                                    </table>
                                    <br />
                                </div>
                                : ''
                            : ''
                    }
                </div>
                <br></br> */}
                <Map center={position} zoom={5} style={styles.tracker}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    {/* <MarkerClusterGroup wrapperOptions={{ enableDefaultStyle: true }}>
                        {this.getMarkers()}
                    </MarkerClusterGroup> */}
                </Map>
            </div>
        )
        : 'No Position'
    }

    getBlankMap() {
        return <Map center={[-25.8400691,28.319692]} zoom={13} style={styles.tracker}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker position={[-25.8400691,28.319692]}>
          <Popup>
            {this.props.desc}
          </Popup>
        </Marker>
    </Map>
    }

    render() {
        let markers = null
        try{
            markers = this.props.position.markers
        }
        catch(err){

        }
        return this.getBlankMap()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.minMap
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...poHeaderActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MinMap)