export const registration = ''
export const odometer = ''
export const collectiondate = null
export const activationdate = null
export const startdate = null
export const terminationdate = null
export const comments = ''
export const ponumber = ''
export const quotefor = ''
export const podate = ''
export const send_marketing = false
export const marketingtype = ''
export const marketingtype_id = null
export const customerdata = {}
export const marketingtype_data = []

export const fields = {
  documents: {
    errorText: 'Please add a document and select document type',
    data: false,
    valid: false,
    empty: true,
    popup: true,
    type: 'documents',
  },
  registration: {
    errorText: 'Please input Registration',
    state: 'getState().strQuotationActivation.registration',
    valid: false,
  },
  odometer: {
    errorText: 'Please input Odometer',
    state: 'getState().strQuotationActivation.odometer',
    valid: false,
    validations: [
      {
        'schema': 'integer',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Odometer'
      }
    ]
  },
  ponumber: {
    errorText: 'Please input PO Number',
    state: 'getState().strQuotationActivation.ponumber',
    valid: false,
  },
  podate: {
    errorText: 'Please input PO Date',
    state: 'getState().strQuotationActivation.podate',
    valid: false,
  },
  collectiondate: {
    errorText: 'Please input Collection Date',
    state: 'getState().strQuotationActivation.collectiondate',
    valid: false,
  },
}