import * as types from './types'

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
    }
}

export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_ACTIVATION_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const getMMLookupValueData = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_STR_ACTIVATION_COMPONENT_MMCODE_LIST,
            payload: {
                query: search,
            }
        })
    }
}

export const setMMLookupValueData = (payload) => {
    return (dispatch, getState) => {

        const data = payload.data.map(x => {
            return {
                ...x,
                value: x.mmcode_id,
                text:  x.mmcode + ' (' + x.makemodelyear + ')'
            }
        })

        window.glyco.log(data)
        dispatch({
            type: types.SET_STR_ACTIVATION_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'mmcodes',
                value: data
            }
        })
    }
}

export const setMMLookupValue = (value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_ACTIVATION_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'mmcode_id',
                value: value,
            }
        })

        dispatch({
            type: types.SET_STR_ACTIVATION_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'mmcode',
                value: text,
            }
        })
    }
}

