import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const opacity = {
    //opacity: 0.85
}

export const txtHeader = {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#000011'
}

export const txtItem = {
    fontSize: 14,
    color: '#000011'
}

export const itemHeader = {
    fontSize: 14,
    textAlign: 'center',
    //backgroundColor: '#dcd6d7',
    fontWeight: 'bold'
}

export const row = {
    fontSize: 16,
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
}


export const textAlign = {
    width: '100%',
    textAlign: 'right'
}

export const pager = {
    textAlign: 'right',
    background: 'none'
}

export const stepperContainer = {
    ////backgroundColor: '#dcd6d7',
    //opacity: 0.85
}

export const stepper = {
    width: '100%'
}

export const taskCard = {
    width: '100%',
    //backgroundColor: colours.amber100
    //backgroundColor: '#dcd6d7',
    //opacity: 0.85
}

export const confirmIcon = {
    fontSize: '34px',
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
    ////backgroundColor: '#dcd6d7',
    //opacity: 0.85
}

export const icon = {
    fontSize: '34px',
    color: colours.red[500]
}

export const componentStatusIconValid = {
    fontSize: '34px',
    color: colours.green[500]
}

export const componentStatusIconInvalid = {
    fontSize: '34px',
    color: colours.red[500]
}

export const field = {
    fontWeight: 'normal'
}

export const cardHeader = {
    textAlign: 'left',
    width: '100% !important',
    color: colours.green[500]
}

export const cardHeaderProgress = {
    textAlign: 'right'
}

export const fieldNameRight = {
    textAlign: 'right'
}

export const card = {
    height: '100%'
}

export const badge = {
    high: {
        top: 12,
        right: 12,
        backgroundColor: colours.red[500],
        fontSize: '25px'
    },
    medium: {
        top: 12,
        right: 12,
        backgroundColor: colours.amber[500],
        fontSize: '15px'
    },
    low: {
        top: 12,
        right: 12,
        backgroundColor: colours.green[500],
        fontSize: '10px'
    }
}

export const buttonBar = {
    background: 'none'
}

export const ButtonMargins = {
    margin: 12
}

export const popup = {
    width: '900px',
    height: '1000px',
    padding: 0,
    margin: 0
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid #253053',
    marginTop: 15,
    padding: 5,
    textAlign: 'left',
    fontSize: 16,
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const cpiLabel = {
    fontSize: '10px',
    textAlign: 'center',
    backgroundColor: '#fffbe5'

}

export const fieldTitleLeft = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10
}
export const selectedFieldName = {
    fontWeight: 'bold',

}