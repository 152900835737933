export const loading = false
export const valid = true
export const data = []
export const component_field = []
export const edit_row_inx = -1
export const tab = 0
export const cudview = false
export const cudview_id = 0
export const cudview_title = ''

