import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Card, CardHeader, CardContent, CardActions, Grid } from '@mui/material'

import GeneralContentView from '../../views/GeneralContentView/component'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import Checkbox from '../../controls/checkbox'

import Dropzone from 'react-dropzone'


//import prettyFileIcons from 'pretty-file-icons'
import { green, red } from '@mui/material/colors'
import ReactTable from "react-table"
import Icon from '../Icon/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

import Header from '../WorkflowTaskInput/header'
import GeneralCard from '../../controls/card'

class Import extends Component {
    componentDidMount() {
        this.props.actions.resetUpload()
    }

    getBulkCellValue(row, inx) {
        return <label style={styles.textBox}>{this.props.state.rows[row.index][inx]}</label>
    }

    getBulkColumns() {
        let header = this.props.state.rows[0]
        let columns = header.map((x, i) => {
            return {
                meta: x,
                Header: x,
                accessor: x,
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <input
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                            width: '100%',
                            height: 25,
                            border: '1px solid #E6E6E6',
                            borderRadius: 25
                        }}
                    />
                ),
                width: x.width ? x.width : undefined,
                headerStyle: {
                    fontSize: '13px',
                    fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                    //backgroundColor: x.highlight ? styles.highlight : '',
                },
                style: {
                    verticalAlign: 'middle',
                    fontSize: 12,
                    letterSpacing: 0,
                    color: 'black',
                    fontWeight: 'normal',
                    //textAlign: x.align
                },
                Cell: (row) => (
                    this.getBulkCellValue(row, i)
                )
            }
        })
        return columns
    }

    getRowGrid() {
        return <ReactTable
            key={'grid_booking_quote'}
            data={this.props.state.rows}
            columns={this.getBulkColumns()}
            filterable={true}
            loading={this.props.state.loading}
            manual
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.getRelatedRowItem(rowInfo, this.data)
                    },
                }
            }}
            onFetchData={
                (state) => {
                    //this.props.actions.getRestructureGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            minRows={1}
            showPagination={false}
            showPaginationBottom={false}
            style={{
                height: '600px'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }

    getSAPInputs() {
        return <Row className="sap-input-filter">
            <Col xl={4}>
                <Checkbox
                    id={'is_late'}
                    key={'is_late'}
                    name={'is_late'}
                    field={'is_late'}
                    checked={this.props.state.is_late}
                    labelPlacement={'end'}
                    label={window.t.en('Is it late import?')}
                    onCheck={(checked) => {
                        this.props.actions.setInput('is_late', checked)
                    }}
                />
            </Col>
            {
                this.props.state.is_late ? <>
                    <Col xl={4}>
                        <DateControl
                            variant="outlined"
                            margin="normal"
                            id={'lateMonth'}
                            title={'Key in Month/Sap Date...'}
                            value={this.props.state.lateMonth}
                            required={true}
                            onSelect={(value) => {
                                this.props.actions.setInput("lateMonth", value)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={4}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        name="age"
                        label={'Age'}
                        required
                        type='number'
                        value={this.props.state.lateAge}
                        onChange={
                            (event, value) => {
                                this.props.actions.setInput('lateAge',parseInt(event.target.value))
                            }
                        }
                    />
                    </Col>
                </> : <div>
                    <br />
                    <br />
                </div>
            }

        </Row>
    }

    getAcceptedFiles() {
        return this.props.state.rows.length
            ? <Card initiallyExpanded={true}>
                <CardHeader
                 style={{backgroundColor: green[500] }}
                    avatar={<Icon iclass={'material-icons'} iname={'done'} />}
                    title={window.t.en('Imported File Data')}
                    subtitle={window.t.en('Please ensure that the data import match the one the file you imported before you action.')}
                    actAsExpander={true}
                    showExpandableButton={true}
                />
                <CardContent >
                    <div>
                        {this.props.state.selectedImportType == 'SAP Import' ? this.getSAPInputs() : null}
                    </div>
                    <br/>
                    <div style={{ verticalAlign: 'middle', height: '100%' }}>
                        {this.getRowGrid()}
                    </div>
                    <Row>
                        <Col xl={12}>
                            <strong>{this.props.state.rows?`Rows: ${this.props.state.rows.length}`: ''}</strong>
                        </Col>
                    </Row>
                </CardContent>
                <CardActions style={{ width: '100%', textAlign: 'right' }}>
                    < Button className={"global_button"} variant="contained"
                        label={'Upload File Data'}
                        disabled={this.props.state.is_late
                            && this.props.state.lateAge > 0
                            && this.props.state.lateMonth ? false
                            : this.props.state.is_late === false
                            && this.props.state.selectedImportType.length > 0 ? false
                            : true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.uploadFileData(this.props.state)
                        }}
                    >{window.t.en('Upload File Data')}
                    </Button>
                </CardActions>
            </Card >
            : <div />
    }

    getRejectedFiles() {
        return !this.props.state.busy && !this.props.state.rows.length
            ? <Card initiallyExpanded={true}>
                <CardHeader
                 style={{backgroundColor: red[500] }}
                    avatar={<Icon iclass={'material-icons'} iname={'clear'} />}
                    title={window.t.en('Import Failed')}
                    subtitle={window.t.en('Please review the reason to why the file failed to import and action accordinly!')}
                    actAsExpander={true}
                    showExpandableButton={true}
                />
                <CardActions style={{ width: '100%', textAlign: 'right' }}>
                   < Button className={"global_button"} variant="contained"
                        label={'Upload Again'}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.uploadAgain()
                        }}
                        >{window.t.en('Upload Again')}</Button>
                </CardActions>
            </Card >
            : <div />
    }
    getFileTypeIcon(file) {
        // const type = prettyFileIcons.getIcon(file)
        // const src = require('../../assets/img/png/icons/' + type + '.png')

        return null
    }
    getImport() {
        return <Card expandable={false}>
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    {
                        !this.props.state.document
                            ? <Row>
                                <Col xl={12}>
                                    <Dropzone
                                        accept={'.xls,.xlsx'}
                                        style={styles.dropZone}
                                        onDrop={(files) => {
                                            //this.props.actions.notifyUser('Checking documents...')
                                        }}
                                        onDropAccepted={(files) => {
                                            this.props.actions.setAcceptedDocuments(files)
                                        }}
                                        onDropRejected={(files) => {
                                            //this.props.actions.notifyUser('(' + files.length + ') files were rejected.')
                                            //this.props.actions.setRejectedDocuments(files, this.props.state.component.name)
                                        }}
                                    >
                                        <p style={styles.watermark}>
                                            Drag and drop the files you would like to upload inside this box. please ensure that your are uploading the correct file format:
                                            <br />
                                            .xls and .xlsx
                                        </p>
                                    </Dropzone>
                                </Col>
                            </Row>
                            : <Row>
                                <Col xl={12}>
                                    <div >
                                        <div >
                                            {'Reset Imported File:'}
                                            <div>
                                                <Icon istyle={{ color: green[500], fontSize: '16px' }} iclass={'material-icons'} iname={'done'} />
                                                &nbsp;
                                                <strong>{'File Name:'} </strong>{this.props.state.document.filename}
                                            </div>
                                        </div>
                                        <br/>
                                        <div style={styles.fieldContent}>< Button className={"global_button"} variant="contained"
                                            label={'Reset Upload'}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.resetUpload()
                                            }}
                                            >{window.t.en('Reset Upload')}</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {this.props.state.document && !this.props.state.busy && this.getAcceptedFiles()}
                            {this.props.state.document && !this.props.state.busy && this.getRejectedFiles()}
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    gteView() {
        return <div>
            <br/>
            {this.props.state.selectedImportType.length > 0 ? this.getImport() : <h4>Select a file type to import</h4>}
        </div >
    }
    getGeneralCard() {
        return (
            <GeneralCard
                title={'Import File Below'}
                subtitle={'Excel File Import'}
                content={<div>
                      <Row>
                        <Col xl={4}>
                            <AutoComplete
                                name={'dropdown_po_type_select'}
                                title={window.t.en('Select import type')}
                                info={{
                                    show: false
                                }}
                                fullWidth={false}
                                data={this.props.state.importTypes}
                                value={this.props.state.selectedImportType}
                                onSelect={(args) => //this.props.actions.setComponentFieldInput(field, args.value)
                                {
                                    //console.log("Selected aregs", args)
                                    this.props.actions.setInput('selectedImportType',args.text)
                                }
                                }
                                onClick={() => {
                                    // this.props.actions.getDefleetTypes()
                                }
                                }
                                onFilter={(args) => {
                                    // this.props.actions.getDefleetTypes()
                                }
                                }
                            />
                        </Col>

                        <Col xl={12}>
                            {/* {this.getGrid(this.props.state.cisHistory)} */}
                        </Col>

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                        >

                        </Grid>
                    </Row>

                    <Row>
                        <Col xl={12}><Grid container spacing={2}>
                            <Grid item xs={2} sm={12}>
                                {this.gteView()}
                            </Grid>
                            <Grid item xs={2} sm={12}>

                            </Grid>
                        </Grid>
                        </Col>
                    </Row>

                </div>
                } />)

    }

    gteLayout() {
        return <div>
            <Header title={'General Imports'} description={''} />
            <br/>
            {this.getGeneralCard()}
            {/* <GeneralContentView
                content={this.gteView()}
                headerImage={'test.png'}
                title={window.t.en('Sap Import')}
                contentHeight={'100%'}

            /> */}
        </div>
    }

    render() {
        return (
            this.gteLayout()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.financeImport,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Import)