import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_PROPS_ACCESSORY_PO_DOCUMENTS_PREVIEW:
            return {
                ...state,
                _pos: action.payload,
            }
        
        case types.SET_PROPS_ACCESSORY_PO_DOCUMENTS_LIST:
            return {
                ...state,
                _pos: action.payload,
            }

            case types.SET_PROPS_QUOTE_DOCUMENTS_PREVIEW:
            return {
                ...state,
                _quote: action.payload,
            }
        case types.SET_PROPS_QUOTE_DOCUMENTS_LIST:
            return {
                ...state,
                _quote: action.payload,
            }
        case types.SET_PROPS_SCHEDULE1_DOCUMENTS_LIST:
            return {
                ...state,
                _schedule1: action.payload,
            }
        case types.SET_MMCODE_CPK_BY_MMCODE:
            return {
                ...state,
                tyrecpk: action.payload.tyrecpk,
                maintcpk: action.payload.maintcpk,
            }
        case types.SET_PROPS_COMPONENT_LOOKUP_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.data,
            }
        case types.SET_PROPS_COMPONENT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value,
            }
            case types.SET_ACCESSORY_LIST_PROP:
                return {
                    ...state,
                    accessories: action.payload,
                }
            case types.SET_CURRENT_INTEREST_RATE:
                return {
                    ...state,
                    interestrate: action.payload,
                }
        default:
            return state
    }
}