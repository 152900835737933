export const GET_TERM_VEHICLE_LIST = 'GET_TERM_VEHICLE_LIST'
export const SET_TERM_VEHICLE_LIST = 'SET_TERM_VEHICLE_LIST'

export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const GET_TERM_VEHICLE_DETAILS = 'GET_TERM_VEHICLE_DETAILS'
export const SET_TERM_VEHICLE_DETAILS = 'SET_TERM_VEHICLE_DETAILS'
export const SAVE_TERMINATION_DATA = 'SAVE_TERMINATION_DATA'
export const GET_TERM_LIST = 'GET_TERM_LIST'
export const SET_TERM_LIST = 'SET_TERM_LIST'
export const APPROVE_TERM = 'APPROVE_TERM'
export const SET_ANY_VALUE_DATA = 'SET_ANY_VALUE_DATA'
export const GEN_CUSTOMER_INVOICE_PDF = 'GEN_CUSTOMER_INVOICE_PDF'
export const GEN_TERMINATION_INVOICE_PDF = 'GEN_TERMINATION_INVOICE_PDF'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const CANCEL_TERM = 'CANCEL_TERM'