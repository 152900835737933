import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Table } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import CardControl from './card'

import PdfViewer from '../components/PDF/component'
import Card from './card'

import ReactQuill from 'react-quill'

import * as actions from './actions'
import * as styles from './styles'

class POQuotations extends Component {
    
    getQuotation(quotation) {
        //console.log('getQuotation',quotation)
        //console.log('getQuotation',quotation)
        return  <tr key={'quotation_inx_' + quotation.inx}>
            <td>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={4} style={{margin:'10px 0px'}}>
                           <b>Supplier Name:</b> {quotation.supplier_name}
                        </Col>
                        <Col xl={4} style={{margin:'10px 0px'}}>
                        <b>Supplier Quote Number:</b> {quotation.quotenumber}
                        </Col>
                        <Col xl={4} style={{margin:'10px 0px'}}>
                        <b>Supplier Quote Date:</b> {quotation.quotedate}
                        </Col>
                        <Col xl={12}>
                            <PdfViewer
                                key={'document_viewer_uploaded_quotes'}
                                name={'De-fleet'}
                                workflow={'defleet'}
                                task={'tsk_defleet_upload_quotes'}
                                documents={quotation.documents ? quotation.documents : []}
                                edit={false}
                                workflow_queue_data={null}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            
            <Table className="table table-hover text-nowrap mb-0" style={{width: '100% !important'}}>
                <colgroup>
                    <col width="200px" />
                    <col width="300px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                </colgroup>
                <thead>
                    <tr>
                        <th style={{width: '30% !important'}}>Item Category</th>
                        <th style={{width: '30% !important'}}>Description</th>
                        <th style={{width: '5% !important'}}>Quantity</th>
                        <th style={{width: '5% !important'}}>Unit Price </th>
                        <th style={{width: '5% !important'}}>Total</th>
                        <th style={{width: '5% !important'}}>Savings</th>
                        <th style={{width: '5% !important'}}>Discount(%)</th>
                        <th style={{width: '5% !important'}}>Discount</th>
                        <th style={{width: '5% !important'}}>VAT</th>
                        <th style={{width: '5% !important'}}>Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        quotation.items.map(x => {
                            return <tr>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Class}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Description}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Quantity}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Price}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Total}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Savings}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Discount}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.DiscountAmount}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.VAT}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.Amount}</div></td>
                            </tr>
                        })
                    }
                    <tr style={{background: '#DFDFDF'}}>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '150px !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '250px !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.items, (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.items, (d) => { return parseFloat((d['Savings']) ? d['Savings'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.items, (d) => { return parseFloat((d['DiscountAmount']) ? d['DiscountAmount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.items, (d) => { return parseFloat((d['VAT']) ? d['VAT'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.items, (d) => { return parseFloat((d['Amount']) ? d['Amount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                </tbody>
            </Table>
                        </Col>
                    </Row>
                </Container>
            </td>
        </tr>
    }

       getLayout(quotations) {
        return <Card
            title={window.t.en('Quotation Items')}
            description={window.t.en('Capture quote line items.')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12}>
                                        
                                        <Table className="table" style={{border: '1px solid #DFDFDF'}}>
                                            <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                                            {
                                                quotations.map((x,inx) => { 
                                                    return this.getQuotation({...x, inx: inx}) 
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                        
                                    </Col>
                                </Row>
                    {
                        quotations.length && <Row>
                            <Col xl={9}>
                            </Col>
                            <Col xl={3}>
                                <table style={{width: '100%', fontSize: '16px', fontWeight: '600'}}>
                                    <tr>
                                        <td>Vatable Total</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(quotations[0].quotation.filter(x => x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>Non Vatable Total</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(quotations[0].quotation.filter(x => !x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>VAT</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(quotations[0].quotation, (d) => { return parseFloat((d['VAT']) ? d['VAT'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td style={{textAlign: 'right'}}>{_.sumBy(quotations[0].quotation, (d) => { return parseFloat((d['Amount']) ? d['Amount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                    }
                            </Container>
                        </Col>
                    </Row>
                </Container>
            } />
    }

    render() {
        return this.props.quotations ? this.getLayout(this.props.quotations) : 'Loading...'
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POQuotations)