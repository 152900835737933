import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                comments: '',
            }
        case types.PO_SUBCOMPONENT_ACTION:
            return {
                ...state
            }
            case types.SET_BOOKING_COMPONENT_FIELD_INPUT_VALUE:
                return {
                    ...state,
                    [action.payload.prop]: action.payload.value
                }
        default:
            return state
    }
}