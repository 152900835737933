import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import { Container, Row, Col } from 'react-grid-system'

import TextField from '@mui/material/TextField'

import Icon from '../../Icon/component'
import Iframe from 'react-iframe'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'

import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import Card from '../../../controls/card'

import * as styles from './styles'
import * as actions from './actions'

import ImageUploader from '../../ImageUploader/component'
import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'

class POBookingSendAuth extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }


    // SupplierLatter(data) {
    //     return this.props.state.supplierdocument
    //         ? <div>
    //             <Card initiallyExpanded={false}>
    //                 <CardHeader
    //                     actAsExpander={true}
    //                     showExpandableButton={true}
    //                     title={window.t.en('Dealer Letter - Labour and Discount')}
    //                     subtitle={data[0][0].suppliername}
    //                     icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
    //                     style={styles.row}
    //                 >
    //                 </CardHeader>
    //                 <CardContent expandable={true}>
    //                     <Container style={{ margin: 0 }} className="custom-container">
    //                         <Row>
    //                             <Col xl={12}>
    //                                 <br />
    //                             </Col>
    //                         </Row> 
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data[0].suppliername}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data[0].suppliertype_fktext}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data[0].registrationnumber}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'VAT Number:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data[0].vatnumber}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Supplier Limit:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data[0].supplierage.suplierspendlimit}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Supplier Usage:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data[0].supplierage.suplierusage}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={12}>
    //                                 <br />
    //                             </Col>
    //                         </Row>
    //                         <Row>

    //                             <Col xl={12}>
    //                                 {
    //                                     this.props.state.supplierdocument
    //                                         ? <Iframe url={this.props.state.supplierdocument}
    //                                             width="100%"
    //                                             height="300"
    //                                             id="supplierdocument"
    //                                             className="myClassname"
    //                                             display="initial"
    //                                             position="relative"
    //                                             allowFullScreen />
    //                                         : <Loading message={'Retrieving document...'} />
    //                                 }
    //                             </Col>
    //                         </Row>
    //                     </Container>
    //                 </CardContent>
    //             </Card>
    //         </div>
    //         : ''
    // }

    // techPerson(data) {
    //     return data
    //         ? <div>
    //             <Card>
    //                 <CardHeader
    //                     actAsExpander={false}
    //                     showExpandableButton={false}
    //                     title={window.t.en('Booking Team')}
    //                     subtitle={window.t.en('Details of person who booked the service. ')}
    //                     style={styles.row}
    //                 >
    //                 </CardHeader>
    //                 <CardContent>
    //                     <Container style={{ margin: 0 }} className="custom-container">
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Schedule Date:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Booking Type:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.data.purchaseOrderType.text}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Booking Description:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.data.booking.servicedescription}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Booked Date:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.data.booking.servicedate}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Booking Done By:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Contact Number:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
    //                         </Row>
    //                     </Container>
    //                 </CardContent>
    //             </Card>
    //         </div>
    //         : ''
    // }

    // techComments(data) {
    //     return data
    //         ? <div>
    //             <Card>
    //                 <CardHeader
    //                     actAsExpander={false}
    //                     showExpandableButton={false}
    //                     title={window.t.en('Technical Manager Comments')}
    //                     subtitle={window.t.en('Techinical manage approval details. ')}
    //                     style={styles.row}
    //                 >
    //                 </CardHeader>
    //                 <CardContent>
    //                     <Container style={{ margin: 0 }} className="custom-container">
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
    //                             <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
    //                         </Row>
    //                     </Container>
    //                 </CardContent>
    //             </Card>
    //         </div>
    //         : ''
    // }

    // gmComments(data) {
    //     return data
    //         ? <div>
    //             <Card>
    //                 <CardHeader
    //                     actAsExpander={false}
    //                     showExpandableButton={false}
    //                     title={window.t.en('General Manager Comments')}
    //                     subtitle={window.t.en('General manager approval details. ')}
    //                     style={styles.row}
    //                 >
    //                 </CardHeader>
    //                 <CardContent>
    //                     <Container style={{ margin: 0 }} className="custom-container">
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
    //                             <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
    //                         </Row>
    //                     </Container>
    //                 </CardContent>
    //             </Card>
    //         </div>
    //         : ''
    // }

    // ceoComments(data) {
    //     return data
    //         ? <div>
    //             <Card>
    //                 <CardHeader
    //                     actAsExpander={false}
    //                     showExpandableButton={false}
    //                     title={window.t.en('CEO Comments')}
    //                     subtitle={window.t.en('ceo approval details. ')}
    //                     style={styles.row}
    //                 >
    //                 </CardHeader>
    //                 <CardContent>
    //                     <Container style={{ margin: 0 }} className="custom-container">
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
    //                             <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
    //                         </Row>
    //                         <Row>
    //                             <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
    //                             <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
    //                         </Row>
    //                     </Container>
    //                 </CardContent>
    //             </Card>
    //         </div>
    //         : ''
    // }


    renderView(data) {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomments'}
                            label={'Enter comments'}
                            fullWidth={true}
                            label={'Enter approval comments'}
                            variant="outlined" 
                            multiLine={true}
                            value={this.props.state.comments}
                            onChange={(event, value) => {
                                this.props.actions.setValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    }


    getLayout() {
        
        return this.data.step1.vehicle_id
        ? <div>
                <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
                <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
                <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
                <Card
                    title={'Send Authorisation'}
                    subtitle={'Send authorisation to supplier'}
                    content={this.renderView()}
                />
            </div>
            : <div>
            <ViewVehicle key={this.data.step1.data.vehicle[0].vehicle_id} id={this.data.step1.data.vehicle[0].vehicle_id} />         
            <ViewSupplier key={this.data.step1.data.supplier[0].supplier_id} id={this.data.step1.data.supplier[0].supplier_id} />
            <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
            <Card
                title={'Send Authorisation'}
                subtitle={'Send authorisation to supplier'}
                content={this.renderView()}
            />
        </div>  
        // return <div>
        // <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
        // <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
        // <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
        //     <Card
        //         title={'Send Authorisation'}
        //         subtitle={'Enter your comments'}
        //         content={this.renderView()}
        //     />
        // </div>
      
    }

    render() {
        // this.data = null
        // try {
        //     this.data = this.props.workflow_queue_data.context_data.data.data.data
        // } catch (error) {

        // }
        // //console.log('render >>>>>>>>>>>>>>>>>>>> this.data',this.data)
        // return this.data
        //     ? this.getLayout()
        //     :  <Loading message={'Retrieving data, please wait...'} />
        
         
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data.length
            ? this.props.workflow_queue_data.context_data.data.data.data[0]
            : this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        


        return this.data
            ? this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingSendAuth
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingSendAuth)