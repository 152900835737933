export const SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE = 'SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE'
export const CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW = 'CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW'
export const SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW = 'SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW'
export const UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT = 'UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT'

export const RESET_COMPONENT = 'RESET_COMPONENT'
export const GET_VEHICLE_LASTPOSITION = 'GET_VEHICLE_LASTPOSITION'
export const SET_VEHICLE_LASTPOSITION = 'SET_VEHICLE_LASTPOSITION'
export const DOWNLOAD_DOCUMENT_DISCOUNT_LETTER = 'DOWNLOAD_DOCUMENT_DISCOUNT_LETTER'
export const SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW = 'SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW'
export const SET_BOOKING_VEHICLE_SERVICE_PART_BASKET = 'SET_BOOKING_VEHICLE_SERVICE_PART_BASKET'
export const GET_BOOKING_VEHICLE_SERVICE_PART_BASKET = 'GET_BOOKING_VEHICLE_SERVICE_PART_BASKET'
export const GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER = 'GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER'
export const GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE = 'GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE'
export const SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE = 'SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE'
export const GET_SELECTED_VEHICLE_DETAILS = 'GET_SELECTED_VEHICLE_DETAILS'
export const GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST = 'GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST'
export const SET_COMPONENT_RECENT_MAINTENANCE_AUTHS = 'SET_COMPONENT_RECENT_MAINTENANCE_AUTHS'
export const SET_SELECTED_VEHICLE_DETAILS = 'SET_SELECTED_VEHICLE_DETAILS'
export const SET_DEFLEET_SIGNOFF = 'SET_DEFLEET_SIGNOFF'
export const GENERATE_DEFLEET_SIGNOFF = 'GENERATE_DEFLEET_SIGNOFF'
export const RESET_THIS_COMPONENT = 'RESET_THIS_COMPONENT'
export const CONFIRM_UPLOAD_QUOTES = 'CONFIRM_UPLOAD_QUOTES'
export const SET_QUOTE_ITEMS = 'SET_QUOTE_ITEMS'