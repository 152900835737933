import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
import * as styles from './styles'
import * as actions from '../Redirect/actions'

class Header extends Component {

    headerTitle () {
        return (
        <>
            <div className="app-page-title app-page-title--shadow" style={{ marginBottom: '0px',padding: '20px 65px'}}>
            <div>
                <div className="app-page-title--first">
                {(
                    <div className="app-page-title--iconbox d-70">
                    <div className="d-70 d-flex align-items-center justify-content-center display-1" 
                    style={{cursor: 'hand !important'}}
                    onClick={(event) => {
                        this.props.onClick()
                    }}>
                        <HomeWorkTwoToneIcon className="text-primary" />
                    </div>
                    </div>
                )}
                <div className="app-page-title--heading">
                    <h1>{this.props.title}</h1>
                    {(
                    <div className="app-page-title--description">
                        {this.props.description}
                    </div>
                    )}
                </div>
                </div>

            </div>
            <div className="d-flex align-items-center">
            </div>
            </div>
            </>
        )
    }

    headers() {
        return (
            <div
                key={'detail_component_view_content_budget_management'}
                style={styles.content}>
                <div
                    key={'detail_component_view_title_budget_management'}
                    style={styles.title}>
                    <div style={styles.title_header}>
                        {/* {this.headerTitle()} */}
                    </div>
                    <div style={styles.title_buttons}>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return <div>
                {this.headers()}
            </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(Header)