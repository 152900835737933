export const filepreview = ''
export const filename = ''
export const comments = ''
export const completeddate = null
export const documents = []

export const invoicedate = ''
export const supplier_invoiceno = ''
export const invoice_number = ''
export const invoice_amount = ''
export const isinvoice_uploaded = false


export const fields = {
    comments: {
        errorText: 'Enter comments',
        value: '',
        valid: false,
        empty: true,
    },
    completeddate: {
        errorText: 'Enter completed date',
        value: null,
        valid: false,
        empty: true,
    },
    isinvoice_uploaded: {
        errorText: 'Enter quote date',
        value: false,
        valid: true,
        empty: true,
    }
}