export const registration = ''
export const odometer = 0
export const startdate = null
export const enddate = null
export const comments = ''
export const ponumber = ''
export const rfqdate = null
export const rfqtime = null
export const deliverydate = null
export const yardlist = true
export const view_vehicle = {}
export const view_vehicle_win = false
export const supplier_data = []
export const yardlist_vehicle = []
export const supplierratecard_data = []