import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux' 
import * as actions from './actions'
import moment from 'moment'

import { Gantt, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";

import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
 
class CalendarControl extends Component {

    componentDidMount() {
        //this.props.actions.getEvents(this.props.state.showall)
        this.props.actions.getTasks()
    }

    headerTitle () {
        return (
        <>
            <div className="app-page-title app-page-title--shadow" style={{ marginBottom: '0px',padding: '20px 65px'}}>
            <div>
                <div className="app-page-title--first">
                {(
                    <div className="app-page-title--iconbox d-70">
                    <div className="d-70 d-flex align-items-center justify-content-center display-1" 
                    style={{cursor: 'hand !important'}}
                    onClick={(event) => {
                        this.props.actions.route('/calendar')
                    }}>
                        <HomeWorkTwoToneIcon className="text-primary" />
                    </div>
                    </div>
                )}
                <div className="app-page-title--heading">
                    <h1>{'Event Calendar'}</h1>
                    {(
                    <div className="app-page-title--description">
                        {'Event Calendar List'}
                    </div>
                    )}
                </div>
                </div>

            </div>
            <div className="d-flex align-items-center">
            </div>
            </div>
            </>
        )
    }

    headers() {
        return (
            <div
                key={'detail_component_view_content_budget_management'}
                style={{marginTop: 10,
                    height: "100%",
                    paddingLeft: 0,
                    paddingRight: 0,}}>
                <div
                    key={'detail_component_view_title_budget_management'}
                    style={{
                        fontSize: 30,
                        fontWeight: "bold",
                        marginBottom: 10,
                        display: "table",
                        width: "100%",
                      }}>
                    <div style={{
                        display: "table-cell",
                        width: "100%",
                    }}>
                        {this.headerTitle()}
                    </div>
                    <div style={{
    display: "table-cell",
    width: 400,
    textAlign: "right",
  }}>
                    </div>
                </div>
            </div>
        )
    }
    
    render() {
   
        return (
            <div>
                
                {/* {this.headers()} */}
                {
                    this.props.state.tasks.length
                    ? <div style={{overflow: 'auto !important'}}>
                        <Gantt tasks={this.props.state.tasks} 
                            viewMode={ViewMode.Month} 
                            //columnWidth={100}
                            //listCellWidth={"155px"}
                            ganttHeight={600}
                            //b//arBackgroundColor="red"
                            rowHeight={40}
                            fontSize={12}
                            handleWidth={100}
                        /> 
                    </div> 
                    : <div>Loading...</div>
                }
                <br/>
                {/* <Calendar 
                    localizer={localizer}
                    views={allViews}
                    step={60}
                    showMultiDayTimes
                    defaultDate={new Date()}
                    components={{
                    timeSlotWrapper: ColoredDateCellWrapper
                    }} 
                    events={this.props.state.events}
                    startAccessor="startDate"
                    endAccessor="endDate"
                    style={{ minHeight: 650 }}
                /> */}
            </div>
          ); 
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.calendar }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarControl)



// import React, { Component } from 'react'
// import { bindActionCreators } from 'redux'
// import { connect } from 'react-redux' 
// import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
// import * as actions from './actions'

// import format from 'date-fns/format';
// import parse from 'date-fns/parse';
// import startOfWeek from 'date-fns/startOfWeek';
// import getDay from 'date-fns/getDay';
// import events from './events';

// let allViews = Object.keys(Views).map((k) => Views[k]); 

// const ColoredDateCellWrapper = ({ children }) =>
//   React.cloneElement(React.Children.only(children), {
//     style: {
//       backgroundColor: '#eaf6ff'
//     }
//   });

//   const locales = {
//     'en-US': require('date-fns/locale/en-US')
//   };
//   const localizer = dateFnsLocalizer({
//     format,
//     parse,
//     startOfWeek,
//     getDay,
//     locales
//   });
 
// class CalendarControl extends Component {

//     componentDidMount() {
//         this.props.actions.getEvents(this.props.state.showall)
//     }

//     render() {
   
//         return (
//             <>
//               <Calendar 
//                 localizer={localizer}
//                 views={allViews}
//                 step={60}
//                 showMultiDayTimes
//                 defaultDate={new Date()}
//                 components={{
//                   timeSlotWrapper: ColoredDateCellWrapper
//                 }} 
//                 events={this.props.state.events}
//                 startAccessor="start"
//                 endAccessor="end"
//                 style={{ minHeight: 650 }}
//               />
//             </>
//           ); 
//     }
// }

// const mapStateToProps = (state) => {
//     return {
//         state: { ...state.calendar }
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         actions: bindActionCreators({ ...actions }, dispatch)
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(CalendarControl)
