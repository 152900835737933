import moment from 'moment'

import * as historyTypes from './components/History/types'
import * as routerTypes from './components/Redirect/types'
import * as routerActions from './components/Redirect/actions'
import * as historyActions from './components/History/actions'

export const router = (store) => (next) => (action) => {
    try {
        const index = store.getState().history.index

        const list = [...store.getState().history.history]
            .sort((x, y) => {
                return new Date(x.date) - new Date(y.date)
            })

        /*
        store.dispatch(masterComponentViewActions.changeMasterComponentTab(0))
        store.dispatch(detailComponentViewActions.changeDetailComponentTab(0))
        */
       
        switch (action.type) {
            case routerTypes.ROUTE:
                if (!action.payload.history) { //} && store.getState().redirect.path !== action.payload.path) {
                    store.dispatch(
                        historyActions.pushHistory({
                            date: moment(),
                            name: action.payload.path,
                            title: action.payload.path,
                            path: action.payload.path,
                            icon: 'cloud_download'
                        })
                    )
                }

                break
            case historyTypes.GO_BACK_IN_HISTORY:
                if (store.getState().history.index === 0)
                    return

                store.dispatch(
                    routerActions.route(list[index > -1 ? index - 1 : list.length - 1].path, true)
                )

                break
            case historyTypes.GO_FORWARD_IN_HISTORY:
                if (store.getState().history.index === store.getState().history.history.length - 1)
                    return

                store.dispatch(
                    routerActions.route(list[index < list.length - 1 ? index + 1 : list.length - 1].path, true)
                )

                break
            default:
                break
        }

        next(action)
    } catch (error) {
        throw new Error(error)
    }
}