import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
//import  from '@mui/material/TextField'

import { Card, CardHeader, CardContent } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import ReactTable from "react-table"
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import * as compActions from '../../Components/actions'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as styles from './styles'

import AutoComplete from '../../../controls/autocomplete'
import DateControl from '../../../controls/dateControl'
import TextField from '../../../controls/textField'

class SUPPLIERPAYMENTALLOCATION extends Component {
    componentDidMount() {
        if (this.props.state.loading) this.props.actions.getComponent('supplierpaymentallocation')
    }

    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "allocationamount") {
            return <TextField
                variant="outlined"
                required={true}
                margin="normal"
                style={styles.miniTextBox}
                underlineShow={false}
                id={'txtnonvatsubtotal'}
                label={'Enter Amount'}
                value={row.original.allocationamount}
                onChange={(event, value) => {
                    var values = {
                        id: row.original.suppliertransaction_id,
                        value: event.target.value,
                        field: field.name
                    }
                    var data = this.props.state.data
                    this.actions.setFieldAllocation(values, data)
                }}
            />
        }
        else if (field.name == "allocationreference") {
            return <TextField
                variant="outlined"
                required={true}
                margin="normal"
                style={styles.miniTextBox}
                underlineShow={false}
                id={'txtnonvatsubtotal'}
                label={'Enter Reference'}
                value={row.original.allocationreference}
                onChange={(event, value) => {
                    var values = {
                        id: row.original.suppliertransaction_id,
                        value: event.target.value,
                        field: field.name
                    }
                    var data = this.props.state.data
                    this.actions.setFieldAllocation(values, data)
                }}
            />
        }
        else if (field.name == "allocationdescription") {
            return <TextField
                variant="outlined"
                required={true}
                margin="normal"
                style={styles.miniTextBox}
                underlineShow={false}
                id={'txtnonvatsubtotal'}
                label={'Enter Description'}
                value={row.original.allocationdescription}
                onChange={(event, value) => {
                    var values = {
                        id: row.original.suppliertransaction_id,
                        value: event.target.value,
                        field: field.name
                    }
                    var data = this.props.state.data
                    this.actions.setFieldAllocation(values, data)
                }}
            />
        }
        else if (field.name == "allocated") {
            return <>dgg{row.value 
                ? <Checkbox
                checked={row.original.allocationamount ? true : false}
                onClick={(event) => {
                    var data = {
                        suppliertransaction_id: row.original.suppliertransaction_id,
                        allocated: false,
                        allocationdescription: '',
                        allocationamount: 0
                    }
                    this.actions.unallocatedPaymentAllocationEpic(data)
                }} />
                : <Checkbox
                checked={row.original.allocationamount ? true : false}
                onClick={(event) => {
                    var values = {
                        id: row.original.suppliertransaction_id,
                        value: row.original.debit ? row.original.debit : row.original.credit,
                        field: "allocationamount"
                    }
                    var data = this.props.state.data

                    if (!row.original.allocationamount) {
                        this.actions.setFieldAllocation(values, data)
                    } else {
                        values.value = 0
                        this.actions.setFieldAllocation(values, data)
                    }
                }} />}</>
        }
        else if (field.name == "fullallocation") {
            return row.original.allocationamount == (row.original.debit ? row.original.debit : row.original.credit) && row.original.allocationamount > 0 ? "Full" : (row.original.allocationamount > 0 && row.original.allocationamount != (row.original.debit ? row.original.debit : row.original.credit) ? "Partial" : "None")
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return <div style={{paddingTop: 15}}>{moment(row.value).format('YYYY-MM-DD')}</div>
                case 'dateTime':
                    return <div style={{paddingTop: 15}}>{moment(row.value).format('YYYY-MM-DD HH:mm ZZ')}</div>
                case 'time':
                    return <div style={{paddingTop: 15}}>{moment(row.value).format('HH:mm ZZ')}</div>
                case 'timestamp':
                    return <div style={{paddingTop: 15}}>{moment(row.value).format('YYYY-MM-DD HH:mm ZZ')}</div>
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? <div style={{paddingTop: 15}}>{toggle[0]}</div>
                            : <div style={{paddingTop: 15}}>{toggle[1]}</div>
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? <div style={{paddingTop: 15}}>{Number(row.value).toFixed(2).toString()}</div>
                        : <div style={{paddingTop: 15}}>{row.value}</div>
                default:
                    return <div style={{paddingTop: 15}}>{row.value}</div>
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: !x.related && !surrogate,
                    Filter: ({ filter, onChange }) => (
                        x.component_field_data_type.name !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                        //backgroundColor: x.highlight ? styles.highlight : '',
                    },
                    style: {
                        verticalAlign: 'middle',
                        //backgroundColor: x.highlight ? styles.highlight : '',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black', //'#4A4A4A',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    /*                     aggregate: x.aggregate
                                            ? values => _[x.aggregate](values)
                                            : undefined,
                                        Aggregated: x.aggregate
                                            ? row => {
                                                return (
                                                    <span>
                                                        {row.value} ({x.aggregate})
                                                    </span>
                                                )
                                            }
                                            : undefined, */
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
                /*                 columns.unshift({
                                    Header: '',
                                    accessor: 'options',
                                    filterable: false,
                                    Cell: (row) => (
                                        <Checkbox onClick={(event) => {
                                            alert(JSON.stringify(row))
                                        }}
                                        />
                                    )
                                }) */
            }
        }

        return columns
    }

    getGrid() {
        return <div> < Button className={"global_button"}
            secondary={true}
            label="Excel Export"
            icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
            onClick={(event) => {
                this.props.actions.excelExport(this.component)
            }}
        ><Icon iclass={'material-icons'} iname={'cloud_download'} />Excel Export </Button><ReactTable
            key={'grid_' + this.component.name + '_' + this.component.component_id}
            //className='-striped -highlight'
            data={this.props.state.data.supplierpaymentallocation_id.data}
            columns={this.getColumns()}
            defaultPageSize={this.component.grid.paging.pageSize}
            //filterable={this.props.filterable}
            //loading={this.component.grid.loading}
            manual
            onFetchData={
                (state) => {
                    //this.props.actions.getComponentGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.selectRow(this.component.name, rowInfo)

                        if (this.props.toggle) {
                            //this.props.actions.toggleInfoSheet()
                        }
                    },
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            onResizedChange={(newResized, event) => {
                //this.props.actions.resizeColumn(this.component.name, newResized)
            }}
            minRows={1}
            pages={this.component.grid.paging.pageCount}
            showPageJump={true}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable></div>
    }


    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <Card style={styles.card} style={styles.row}>
            <CardHeader
                title={window.t.en('Supplier Allocation')}
                subtitle={window.t.en('Allocations')}
                actAsExpander={true}
                showExpandableButton={true}

            />
            <CardContent >
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={4}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Creditor:'}</div>
                                <div style={styles.fieldContent}>
                                    {/* <AutoComplete
                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        id={'dll_creditor_id'}
                                        key={'dll_creditor_id'}
                                        hintText={'Select a creditor...'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        dataSource={this.props.state.data.supplier_id.data}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                this.props.actions.setSelectedLookup("supplier_id", chosenRequest.value, chosenRequest.text)
                                                this.props.actions.getAllocationData(chosenRequest.value)

                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.getAllocationCreditorData('')
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.getAllocationCreditorData(search)
                                            }
                                        }
                                    /> */}
                                    <div className="custom-auto-complete" style={{ paddingTop: '15px' }}>
                                        <AutoComplete
                                            name={'dll_invoice_id'}
                                            title={window.t.en('Search...')}
                                            info={{
                                                show: false
                                            }}
                                            id={'dll_creditor_id'}
                                            key={'dll_creditor_id'}
                                            hintText={'Select a creditor...'}

                                            fullWidth={true}
                                            data={this.props.state.data.supplier_id.data}
                                            value={this.props.state.selected_invoice ? this.props.state.selected_invoice.text : ''}
                                            onSelect={(args) => //this.props.actions.setComponentFieldInput(field, args.value)
                                            {
                                                //console.log("args", args)
                                                this.props.actions.setInputValue("supplier_id", args.value)
                                                this.props.actions.setSelectedLookup("supplier_id", args.value, args.text)
                                                this.props.actions.getAllocationData(args.value)
                                            }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getAllocationCreditorData('')
                                                }
                                            }
                                            onFilter={(args) => //this.props.actions.getComponentFieldLookupData(field, args)
                                                this.props.actions.getAllocationCreditorData(args)
                                            }
                                        />
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col xl={6}><div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Allocation Balance:'}</div>
                            <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    //required={true}
                                    margin="normal"
                                    label={'Allocation Balance'}
                                    id={'txtallocationbalance'}
                                    value={this.props.state.data.allocationbalance.input}
                                    onChange={(e, value) => {
                                        //this.props.actions.setInputValue('description', value)
                                    }}
                                />
                            </div>
                        </div></Col>
                        <Col xl={2}>
                            < Button className={"global_button"} variant="contained" header={false}
                                disabled={this.props.state.data.allocateall.input ? false : true}
                                label={'Allocate All'}
                                primary={true}
                                onClick={(event) => {
                                    var userdata = this.props.state.user
                                    this.props.actions.allocateAll(this.props.state.data.supplierpaymentallocation_id.data, userdata)
                                }}
                            >{window.t.en('Allocate All')}</Button></Col>
                    </Row>
                    <Container style={styles.row}>
                        <Row>
                            <Col xl={12}>&nbsp;</Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <Row>
                                    <Col xl={3}><strong style={styles.fieldContent}>{'120+ Days'}</strong></Col>
                                    <Col xl={3}> <strong style={styles.fieldContent}>{'90 Days'}</strong></Col>
                                    <Col xl={3}> <strong style={styles.fieldContent}>{'60 Days'}</strong></Col>
                                    <Col xl={3}> <strong style={styles.fieldContent}>{'30 Days'}</strong></Col>
                                </Row>
                                <Row>
                                    <Col xl={3}> <div style={styles.fieldContent}>
                                        <TextField
                                            variant="outlined"
                                            //required={true}
                                            margin="normal"
                                            id={'txtage120plus'}
                                            label={'120+ Days'}
                                            value={this.props.state.data.age120plus.input}
                                            onChange={( value) => {
                                                //this.props.actions.setInputValue('description', value)
                                            }}
                                        />
                                    </div></Col>
                                    <Col xl={3}> <div style={styles.fieldContent}>
                                        <TextField
                                            variant="outlined"
                                            //required={true}
                                            margin="normal"
                                            id={'txtage90'}
                                            label={'90 Days'}
                                            value={this.props.state.data.age90.input}
                                            onChange={(value) => {
                                                //this.props.actions.setInputValue('description', value)
                                            }}
                                        />
                                    </div></Col>
                                    <Col xl={3}> <div style={styles.fieldContent}>
                                        <TextField
                                            variant="outlined"
                                            //required={true}
                                            margin="normal"
                                            id={'txtage60'}
                                            label={'60 Days'}
                                            value={this.props.state.data.age60.input}
                                            onChange={(value) => {
                                                //this.props.actions.setInputValue('description', value)
                                            }}
                                        />
                                    </div></Col>
                                    <Col xl={3}> <div style={styles.fieldContent}>
                                        <TextField
                                            variant="outlined"
                                            //required={true}
                                            margin="normal"
                                            id={'txtage30'}
                                            label={'30 Days'}
                                            value={this.props.state.data.age30.input}
                                            onChange={(value) => {
                                                //this.props.actions.setInputValue('description', value)
                                            }}
                                        />
                                    </div></Col>
                                </Row>
                            </Col>
                            <Col xl={6}>
                                <Row>
                                    <Col xl={4}> <strong style={styles.fieldContent}>{'Current'}</strong></Col>
                                    <Col xl={4}> <strong style={styles.fieldContent}>{'Unallocated'}</strong></Col>
                                    <Col xl={4}> <strong style={styles.fieldContent}>{'Total'}</strong></Col>
                                </Row>
                                <Row>
                                    <Col xl={4}> <div style={styles.fieldContent}>
                                        <TextField
                                            variant="outlined"
                                            //required={true}
                                            margin="normal"
                                            id={'txtcurrent'}
                                            label={'Current'}
                                            value={this.props.state.data.current.input}
                                            onChange={(event, value) => {
                                                //this.props.actions.setInputValue('description', value)
                                            }}
                                        />
                                    </div></Col>
                                    <Col xl={4}> <div style={styles.fieldContent}>
                                        <TextField
                                            variant="outlined"
                                            //required={true}
                                            margin="normal"
                                            label={'Unallocated'}
                                            id={'txtunallocated'}
                                            value={this.props.state.data.unallocated.input}
                                            onChange={(event, value) => {
                                                //this.props.actions.setInputValue('description', value)
                                            }}
                                        />
                                    </div></Col>
                                    <Col xl={3}> <div style={styles.fieldContent}>
                                        <TextField
                                            variant="outlined"
                                            //required={true}
                                            margin="normal"
                                            id={'txttotal'}
                                            label={'Total'}
                                            value={this.props.state.data.total.input}
                                            onChange={(event, value) => {
                                                //this.props.actions.setInputValue('description', value)
                                            }}
                                        />
                                    </div></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>

                    <Row>
                        <Col xl={12}>
                            {this.getGrid()}
                        </Col>
                    </Row>
                </Container>
            </CardContent>
            <Row>
                <Col xl={3}>
                </Col>
                <Col xl={3}>
                </Col>
                <Col xl={3}>
                </Col>
                <Col xl={3} styles={styles.right}>
                    < Button className={"global_button"} variant="contained" header={false}
                        disabled={this.props.state.data.canallocate.input ? false : true}
                        label={'Save Allocation'}
                        onClick={(event) => {
                            var data = this.props.state.data.supplierpaymentallocation_id.data
                            window.glyco.log("data")
                            window.glyco.log(data)
                            this.props.actions.saveSupplierPaymentAllocationData(data, this.props.state.user)
                        }}
                    >{window.t.en('Save Allocation')}</Button>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br/>
                </Col>
            </Row>

        </Card>

    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.supplierpaymentallocation,
            user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERPAYMENTALLOCATION)