import * as _ from 'lodash'
import moment from 'moment'

import * as types from './types'
import * as appTypes from '../../App/types'

import * as formActions from '../../Form/actions'
import * as managerActions from '../TeamManager/actions'
import * as teamStructureActions from '../../TeamStructure/actions'
import * as componentActions from '../../Components/actions'

export const toggleTeamForm = () => {
    return {
        type: types.TOGGLE_TEAM_FORM
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_TEAM_OPTION_STATE,
            payload: {
                name: prop,
                data: value
            }
        })
    }
}

export const toggleAssignmentForm = (form) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_ASSIGNMENT_FORM,
            payload: form
        })

        const view = getState().teamOptions.view

        const team_id = _.find(
            getState().teamOptions.allTeams, {
                team_id: getState().teamOptions.selectedTeam === 0
                    ? getState().teamOptions.allTeams[0].team_id
                    : getState().teamOptions.selectedTeam
            }).team_id

        window.glyco.log('+++++THE TEAM ID IS: ' + team_id)

        if (!isNaN(team_id)) {
            view === 'users'
                ? dispatch({
                    type: types.GET_TEAM_USERS,
                    payload: team_id
                })
                : view === 'components'
                    ? dispatch({
                        type: types.GET_TEAM_COMPONENTS,
                        payload: team_id
                    })
                    : view === 'reports' 
                        ? dispatch({
                            type : types.GET_TEAM_REPORTS,
                            payload : team_id
                        })
                        : view === 'organisation' 
                            ? dispatch({
                                type : types.GET_TEAM_ACCOUNTS, //GET_ORGANISATION_REPORTS,
                                payload : team_id
                            })
                            : dispatch({
                                type: types.GET_TEAM_ACCOUNTS,
                                payload: team_id
                            })
        }
    }
}

export const clearTeamData = () => {
    return {
        type: types.CLEAR_TEAM_DATA
    }
}

export const loadSelectedTeam = () => {
    return {
        type: types.LOAD_SELECTED_TEAM
    }
}

export const saveTeamData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_TEAM_DATA
        })

       // dispatch(formActions.saveComponentData (getState().components.team))
        //dispatch(managerActions.saveTeam(getState().components.team))

        //dispatch(componentActions.clearComponentData('team'))

        //console.log('saveTeamData teamstructure',  getState().teamstructure)
        dispatch({
            type: types.SAVE_TEAM_MANAGEMENT_CHANGES,
            payload: {
                type: 'save_new_team',
                data: getState().teamstructure
            }
        })

        dispatch(teamStructureActions.clearTeamData())

        dispatch(toggleTeamForm())

        dispatch(getAllTeams())
    }
}

export const changeSelectedTeam = (value) => {
    //console.log("VALUE", value);
    return (dispatch, getState) => {
        dispatch({
            type: types.CHANGE_SELECTED_TEAM,
            payload: value
        })

        const view = getState().teamOptions.view

        view === 'users'
            ? dispatch({
                type: types.GET_TEAM_USERS,
                payload: value
            })
            : view === 'components'
                ? dispatch({
                    type: types.GET_TEAM_COMPONENTS,
                    payload: value
                })
                :view === 'reports'
                    ? dispatch({
                        type : types.GET_TEAM_REPORTS,
                        payload : value
                    })
                    : dispatch({
                        type: types.GET_TEAM_ACCOUNTS,
                        payload: value
                    })
    }
}

export const setTeamData = (data) => {
    //console.log("yyyyyyy",data)
    return (dispatch, getState) => {
        const view = getState().teamOptions.view
       
        console.log('SET_TEAM_COMPONENTSx data', data)

        window.glyco.log(data)

        const getTeamAccounts = (data) => {
            const accountBusinesses = data.filter(x => x.customer_id === null)
            const accountBusinessCustomers = data.filter(x => x.customer_id !== null)
            /*             let accounts = data.map(x => {
                            return x.client
                        })
            
                        let accountBusinesses = []
                        let accountBusinessCustomers = []
            
                        accounts = _.uniq(accounts)
            
                        if (Array.isArray(accounts) && accounts.length) {
                            accountBusinesses = _.find(data, {
                                client_id: accounts[0].client_id
                            }).map(x => {
                                return x.client
                            })
            
                            accountBusinesses = _.uniq(accountBusinesses)
            
                            if (Array.isArray(accountBusinesses) && Array.isArray(accountBusinesses)) {
                                accountBusinessCustomers = _.find(data, {
                                    client_id: accounts[0].client_id,
                                    business_id: accountBusinesses[0].business[0].business_id
                                }).map(x => {
                                    return x.customer
                                })
            
                                accountBusinessCustomers = _.uniq(accountBusinessCustomers)
                            }
                        } */

            return {
                //accounts: accounts ? accounts : [],
                accountBusinesses: accountBusinesses ? accountBusinesses : [],
                accountBusinessCustomers: accountBusinessCustomers ? accountBusinessCustomers : []
            }
        }

        view === 'users'
            ? dispatch({
                type: types.SET_TEAM_USERS,
                payload: data.filter(x => x.user)
            })
            : view === 'components'
                ? dispatch({
                    type: types.SET_TEAM_COMPONENTS,
                    payload: data.filter(x => x.component).filter(x => x.component.menu).map(x => {
                        return {
                            title: x.component.title,
                            description: x.component.description,
                            component_id: x.component.component_id,
                        }
                    })
                })
                :view === 'reports'
                    ? dispatch({
                        type : types.SET_TEAM_REPORTS,
                        payload : data
                    })
                    :dispatch({
                        type: types.SET_TEAM_ACCOUNTS,
                        payload: getTeamAccounts(data)
                    })
    }
}


export const getAllCustomers = () => {
    return {
        type: types.GET_ALL_CUSTOMERS
    }
}
export const setAllCustomers = (data) => {
    return {
        type: types.SET_ALL_CUSTOMERS,
        payload: data
    }
}

export const setUserLinkToCustomers = (customer_id) => {
    return {
        type: types.SET_CUSTOMER_TO_LINK,
        payload: customer_id
    }
}

export const getUserCustomers = (user_id) => {
    return {
        type: types.GET_ALL_USER_CUSTOMERS,
        payload: user_id
    }
}

export const setAllUserCustomers = (data, user_id) => {
    return (dispatch) => {
        //console.log('setAllUserCustomers data',data)
        //console.log('setAllUserCustomers user_id',user_id)
        dispatch({
            type: types.SET_ALL_USER_CUSTOMERS,
            payload: data
        })

        dispatch({
            type: types.SET_USER_TO_LINK,
            payload: user_id
        })
    }
}

export const unassignUserFromCustomer = (data) => {
    return {
        type: types.UNASSIGN_USER_TO_CUSTOMERS,
        payload: data
    }
}

export const assignUserFromCustomer = (customer_id, user_id) => {
    //console.log('assignUserFromCustomer d', customer_id, user_id)
    return {
        type: types.ASSIGN_USER_TO_CUSTOMERS,
        payload: {
            user_id: user_id,
            customer_id: customer_id
        }
    }
}

export const assignUserToCustomerDone = (user_id) => {
    return (dispatch) => {
        dispatch(getUserCustomers(user_id))
    }
}

export const unassignUserToCustomerDone = (user_id) => {
    return (dispatch) => {
        dispatch(getUserCustomers(user_id))
    }
}


export const getAllUsers = () => {
    //console.log('getAllUsers GET_ALL_USERS')
    return {
        type: types.GET_ALL_USERS
    }
}

export const setAllUsers = (data) => {
    //console.log('getAllUsers SET_ALL_USERS')
    return {
        type: types.SET_ALL_USERS,
        payload: data
    }
}

export const getAllTeams = () => {
    return {
        type: types.GET_ALL_TEAMS
    }
}

export const getAllEvents = () => {
    return {
        type: types.GET_ALL_EVENTS
    }
}

export const setEventData = (data) => {
    return {
        type: types.SET_ALL_EVENTS,
        payload: data
    }
}

export const setAllTeams = (data) => {
    return {
        type: types.SET_ALL_TEAMS,
        payload: data
    }
}

export const getAllComponents = () => {
    return (dispatch) => {
        dispatch({
            type: types.GET_ALL_COMPONENTS
        })
    }
}

export const setAllComponents = (data) => {
    return (dispatch) => {

        dispatch({
            type: types.SET_ALL_COMPONENTS,
            payload: data
        })
    }
}

export const getLinkedTeamAndUser = (id) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKQUEUE_SELECTED_WORKQUEUE_ID,
            payload: id
        })
        dispatch({
            type: types.GET_WORKQUEUE_USER_AND_TEAM_LINK,
            payload: id
        })
    }
}

export const setLinkedTeamAndUser = (payload) => {
    //console.log('SET_WORKQUEUE_USER_AND_TEAM_LINK', payload)
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKQUEUE_USER_AND_TEAM_LINK,
            payload: {
                users: payload[0].workqueue_user.map(x => {return x.user}),
                teams: payload[0].workqueue_team.map(x => {return x.team})
            }
        })
    }
}

export const getAllReports = () =>{
    return(dispatch) =>{
        dispatch({
            type : types.GET_ALL_REPORTS
        })
    }
}

export const setAllReports =(data)=> {
    return(dispatch) =>{
        dispatch({
            type : types.SET_ALL_REPORTS,
            payload : {
                data : data
            }
        })
    }
}

export const getAllAccounts = () => {
    return {
        type: types.GET_ALL_ACCOUNTS
    }
}

export const setAllAccounts = (data) => {
    return {
        type: types.SET_ALL_ACCOUNTS,
        payload: data
    }
}


export const addLinkTeam = (team) => {
    return (dispatch, getState) => {
        let teams = getState().teamOptions.event_teams
        teams.push(team)
        dispatch({
            type: types.SET_WORKQUEUE_LINKED_TEAM,
            payload: teams
        })
    }
}

export const rmEventTeam = (team_id) => {
    return (dispatch, getState) => {
        let teams = getState().teamOptions.event_teams.filter(x => x.team_id !== team_id)
        dispatch({
            type: types.SET_WORKQUEUE_LINKED_TEAM,
            payload: teams
        })
    }
}

export const addLinkUser = (user) => {
    return (dispatch, getState) => {
        let users = getState().teamOptions.event_users
        users.push(user)
        dispatch({
            type: types.SET_WORKQUEUE_LINKED_USER,
            payload: users
        })
    }
}

export const rmEventUser = (user_id) => {
    return (dispatch, getState) => {
        let users = getState().teamOptions.event_users.filter(x => x.user_id !== user_id)
        dispatch({
            type: types.SET_WORKQUEUE_LINKED_USER,
            payload: users
        })
    }
}
export const saveTeamAssignmentChanges = () => {
    return (dispatch, getState) => {
        const view = getState().teamOptions.view

        view === 'users'
            ? dispatch({
                type: types.SAVE_TEAM_ASSIGNMENT_CHANGES,
                payload: {
                    type: types.SAVE_TEAM_ASSIGNMENT_CHANGES,
                    view: view,
                    team_id: _.find(
                        getState().teamOptions.allTeams, {
                            team_id: getState().teamOptions.selectedTeam === 0
                                ? getState().teamOptions.allTeams[0].team_id
                                : getState().teamOptions.selectedTeam
                        }).team_id,
                    data: getState().teamOptions.users
                }
            })
            : view === 'components'
                ? dispatch({
                    type: types.SAVE_TEAM_ASSIGNMENT_CHANGES,
                    payload: {
                        type: types.SAVE_TEAM_ASSIGNMENT_CHANGES,
                        view: view,
                        team_id: _.find(
                            getState().teamOptions.allTeams, {
                                team_id: getState().teamOptions.selectedTeam === 0
                                    ? getState().teamOptions.allTeams[0].team_id
                                    : getState().teamOptions.selectedTeam
                            }).team_id,
                        data: getState().teamOptions.components
                    }
                })
                : view === 'reports'
                    ? dispatch({
                        type: types.SAVE_TEAM_ASSIGNMENT_CHANGES,
                        payload: {
                            type: types.SAVE_TEAM_ASSIGNMENT_CHANGES,
                            view: view,
                            team_id: _.find(
                                getState().teamOptions.allTeams, {
                                    team_id: getState().teamOptions.selectedTeam === 0
                                        ? getState().teamOptions.allTeams[0].team_id
                                        : getState().teamOptions.selectedTeam
                                }).team_id,
                            data: getState().teamOptions.reports
                        }
                    })
                    : dispatch({
                        type: types.SAVE_TEAM_ASSIGNMENT_CHANGES,
                        payload: {
                            type: types.SAVE_TEAM_ASSIGNMENT_CHANGES,
                            view: view,
                            team_id: _.find(
                                getState().teamOptions.allTeams, {
                                    team_id: getState().teamOptions.selectedTeam === 0
                                        ? getState().teamOptions.allTeams[0].team_id
                                        : getState().teamOptions.selectedTeam
                                }).team_id,
                            data: getState().teamOptions.businessMode
                                ? {
                                    mode: 'business',
                                    data: getState().teamOptions.accountBusinesses
                                }
                                : {
                                    mode: 'customer',
                                    data: getState().teamOptions.accountBusinessCustomers
                                }
                        }
                    })
        }
}

export const savedTeamAssignmentChanges = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_TEAM_ASSIGNMENT_CHANGES
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Team Assignments Saved!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const saveTeamAndUserChanges = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_TEAM_AND_USER_CHANGES,
            payload: {
                users: getState().teamOptions.event_users,
                teams: getState().teamOptions.event_teams,
                user_id: getState().user.user_id,
                workqueue_id: getState().teamOptions.workqueue_id,
                type: 'link_workqueue_user_and_team'
            }
        })
    }
}

export const saveTeamAndUserChangesDone = (response) => {
    //console.log('saveTeamAndUserChangesDone', response)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Action succesffully completed',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const assignUserToTeam = () => {
    return (dispatch, getState) => {
        let assignedUsers = getState().teamOptions.users

        const user = _.find(
            getState().teamOptions.allUsers, {
                user_id: getState().teamOptions.selectedUser === 0
                    ? getState().teamOptions.allUsers[0].user_id
                    : getState().teamOptions.selectedUser
            })

        assignedUsers.push(user)

        const users = _.uniq(assignedUsers)

        dispatch({
            type: types.ASSIGN_USER_TO_TEAM,
            payload: users
        })
    }
}

export const unassignUserFromTeam = (user_id) => {
    return (dispatch, getState) => {
        let assignedUsers = getState().teamOptions.users

        _.remove(assignedUsers, {
            user_id: user_id
        })

        dispatch({
            type: types.UNASSIGN_USER_FROM_TEAM,
            payload: assignedUsers
        })
    }
}

export const changeSelectedUser = (value) => {
    return {
        type: types.CHANGE_SELECTED_USER,
        payload: value
    }
}

export const assignComponentToTeam = () => {
    return (dispatch, getState) => {
        let assignedComponents = getState().teamOptions.components

        const component = _.find(
            getState().teamOptions.allComponents, {
                component_id: getState().teamOptions.selectedComponent === 0
                    ? getState().teamOptions.allComponents[0].component_id
                    : getState().teamOptions.selectedComponent
            })

        assignedComponents.push(component)

        const components = _.uniq(assignedComponents)

        dispatch({
            type: types.ASSIGN_COMPONENT_TO_TEAM,
            payload: components
        })
    }
}


export const assignReportToTeam = () => {
    return (dispatch, getState) => {
        let assignedReports = getState().teamOptions.reports

        const report = _.find(
            getState().teamOptions.allReports, {
                report_id: getState().teamOptions.selectedReport === 0
                    ? getState().teamOptions.allReports[0].report_id
                    : getState().teamOptions.selectedReport
            })

        assignedReports.push(report)

        const reports = _.uniq(assignedReports)

        dispatch({
            type: types.ASSIGN_REPORT_TO_TEAM,
            payload: reports
        })
    }
}

export const unassignComponentFromTeam = (id) => {
    return (dispatch, getState) => {
        let assignedComponents = getState().teamOptions.components

        _.remove(assignedComponents, {
            component_id: id
        })

        dispatch({
            type: types.UNASSIGN_COMPONENT_FROM_TEAM,
            payload: assignedComponents
        })
    }
}

export const unassignReportFromTeam = (id) => {
    return (dispatch, getState) => {
        let assignedReports = getState().teamOptions.reports

        _.remove(assignedReports, {
            report_id: id
        })

        dispatch({
            type: types.UNASSIGN_REPORT_FROM_TEAM,
            payload: assignedReports
        })
    }
}


export const changeSelectedComponent = (value) => {
    return {
        type: types.CHANGE_SELECTED_COMPONENT,
        payload: value
    }
}

export const changeSelectedReport = (value) =>{
    return {
        type : types.CHANGE_SELECTED_REPORT,
        payload : value
    }
}

export const assignAccountToTeam = () => {
    return (dispatch, getState) => {
        let assignedAccounts = getState().teamOptions.accounts

        const account = _.find(
            getState().teamOptions.allAccounts, {
                client_id: getState().teamOptions.selectedAccount === 0
                    ? getState().teamOptions.allAccounts[0].client_id
                    : getState().teamOptions.selectedAccount
            })

        assignedAccounts.push(account)

        const accounts = _.uniq(assignedAccounts)

        dispatch({
            type: types.ASSIGN_ACCOUNT_TO_TEAM,
            payload: accounts
        })
    }
}

export const unassignAccountFromTeam = (id) => {
    return (dispatch, getState) => {
        let assignedAccounts = getState().teamOptions.accounts

        _.remove(assignedAccounts, {
            client_id: id
        })

        dispatch({
            type: types.UNASSIGN_ACCOUNT_FROM_TEAM,
            payload: assignedAccounts
        })
    }
}

export const changeSelectedAccount = (value) => {
    return {
        type: types.CHANGE_SELECTED_ACCOUNT,
        payload: value
    }
}

export const changeSelectedBusiness = (value) => {
    return {
        type: types.CHANGE_SELECTED_BUSINESS,
        payload: value
    }
}

export const changeSelectedCustomer = (value) => {
    return {
        type: types.CHANGE_SELECTED_CUSTOMER,
        payload: value
    }
}



export const toggleTeamActivation = (team_id) => {
    return {
        type: types.TOGGLE_TEAM_ACTIVATION,
        payload: team_id
    }
}

export const saveTeamManagementChanges = () => {
    return (dispatch, getState) => {
        ////console.log(' getState().teamOptions.allTeams', getState().teamstructure)
        dispatch({
            type: types.SAVE_TEAM_MANAGEMENT_CHANGES,
            payload: {
                type: types.SAVE_TEAM_MANAGEMENT_CHANGES,
                teams: getState().teamOptions.allTeams
            }
        })
    }
}

export const savedTeamManagementChanges = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_TEAM_MANAGEMENT_CHANGES
        })

        dispatch(getAllTeams())

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Team Management Actions Completed!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const toggleBusinessMode = () => {
    return {
        type: types.TOGGLE_BUSINESS_MODE
    }
}

export const setAccountBusinesses = (data) => {
    return {
        type: types.SET_ACCOUNT_BUSINESSES,
        payload: data
    }
}

export const assignAccountBusinessToTeam = () => {
    return (dispatch, getState) => {
        let assignedAccountBusinesses = getState().teamOptions.accountBusinesses

        const account = _.find(
            getState().teamOptions.allAccounts, {
                client_id: getState().teamOptions.selectedAccount === 0
                    ? getState().teamOptions.allAccounts[0].client_id
                    : getState().teamOptions.selectedAccount
            })

        const business = _.find(
            getState().teamOptions.allBusinesses, {
                business_id: getState().teamOptions.selectedBusiness === 0
                    ? getState().teamOptions.allBusinesses[0].business_id
                    : getState().teamOptions.selectedBusiness
            })

        if (!_.find(assignedAccountBusinesses, { client_id: account.client_id, business_id: business.business_id })) {
            const accountBusiness = {
                client_id: account.client_id,
                business_id: business.business_id,
                client: account,
                business: business
            }

            assignedAccountBusinesses.push(accountBusiness)
        }

        dispatch({
            type: types.ASSIGN_ACCOUNT_BUSINESS_TO_TEAM,
            payload: assignedAccountBusinesses
        })
    }
}

export const setAccountBusinessCustomers = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACCOUNT_BUSINESS_CUSTOMERS,
            payload: data
        })
    }
}

export const assignAccountBusinessCustomerToTeam = () => {
    return (dispatch, getState) => {
        let assignedAccountBusinessCustomers = getState().teamOptions.accountBusinessCustomers

        const account = _.find(
            getState().teamOptions.allAccounts, {
                client_id: getState().teamOptions.selectedAccount === 0
                    ? getState().teamOptions.allAccounts[0].client_id
                    : getState().teamOptions.selectedAccount
            })

        const business = _.find(
            getState().teamOptions.allBusinesses, {
                business_id: getState().teamOptions.selectedBusiness === 0
                    ? getState().teamOptions.allBusinesses[0].business_id
                    : getState().teamOptions.selectedBusiness
            })

        const customer = _.find(
            getState().teamOptions.allCustomers, {
                customer_id: getState().teamOptions.selectedCustomer === 0
                    ? getState().teamOptions.allCustomers[0].customer_id
                    : getState().teamOptions.selectedCustomer
            })


        if (!_.find(assignedAccountBusinessCustomers, { client_id: account.client_id, business_id: business.business_id, customer_id: customer.customer_id })) {
            const accountBusinessCustomer = {
                client_id: account.client_id,
                business_id: business.business_id,
                customer_id: customer.customer_id,
                client: account,
                business: business,
                customer: customer
            }

            assignedAccountBusinessCustomers.push(accountBusinessCustomer)
        }

        dispatch({
            type: types.ASSIGN_ACCOUNT_BUSINESS_CUSTOMER_TO_TEAM,
            payload: assignedAccountBusinessCustomers
        })
    }
}

export const getTeamAccountBusinesses = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_TEAM_ACCOUNT_BUSINESSES,
            payload: {
                team_id: getState().teamOptions.selectedTeam,
                client_id: getState().teamOptions.selectedAccount
            }
        })
    }
}

export const getTeamAccountBusinessCustomers = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_TEAM_ACCOUNT_BUSINESS_CUSTOMERS,
            payload: {
                team_id: getState().teamOptions.selectedTeam,
                client_id: getState().teamOptions.selectedAccount,
                business_id: getState().teamOptions.selectedBusiness
            }
        })
    }
}

export const unassignAccountBusinessFromTeam = (team_id, client_id, business_id) => {
    return (dispatch, getState) => {
        let accountBusinesses = getState().teamOptions.accountBusinesses

        _.remove(accountBusinesses, {
            //team_id: team_id,
            client_id: client_id,
            business_id: business_id
        })

        dispatch({
            type: types.UNASSIGN_ACCOUNT_BUSINESS_FROM_TEAM,
            payload: accountBusinesses
        })
    }
}

export const unassignAccountBusinessCustomerFromTeam = (team_id, client_id, business_id, customer_id) => {
    return (dispatch, getState) => {
        let accountBusinessCustomers = getState().teamOptions.accountBusinessCustomers

        _.remove(accountBusinessCustomers, {
            //team_id: team_id,
            client_id: client_id,
            business_id: business_id,
            customer_id: customer_id
        })

        dispatch({
            type: types.UNASSIGN_ACCOUNT_BUSINESS_CUSTOMER_FROM_TEAM,
            payload: accountBusinessCustomers
        })
    }
}