import * as types from './types'

export const getCompletedTaskCount = (team_id, user_id) => {
    return {
        type: types.GET_COMPLETED_TASK_COUNT,
        payload: {
            team_id: team_id,
            user_id: user_id,
            body: {
                query: 'completed_tasks_count',
                team_id: team_id,
                user_id: user_id
            }
        }
    }
}

export const setCompletedTaskCount = (team_id, data) => {
    return {
        type: types.SET_COMPLETED_TASK_COUNT,
        payload: {
            team_id: team_id,
            data: data
        }
    }
}