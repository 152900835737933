import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs, Box, Tab, Button, List, ListItem, Tooltip, Dialog, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'
import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import * as actions from './actions'
import * as styles from './styles'

class SupplierMasterCapture extends Component {
    componentDidMount() {
        if (this.props.mode === "confirm") {
            this.props.actions.setupSupplierMasterWorkflowTaskInput(this.props.state, [], 'supplier_master', 'tsk_supplier_master_capture_details')
        }
        else {
            this.props.actions.resetForm()
            this.props.actions.setValidationState('supplierMasterCapture')
        }
    }

    getDetails() {
        return <div> <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'suppliername'}
                        label={'Enter registered name'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.suppliername}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('suppliername', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'tradingname'}
                        label={'Enter trading name'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.tradingname}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('tradingname', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'accountnumber'}
                        label={'Enter account number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.accountnumber}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('accountnumber', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'registrationnumber'}
                        label={'Enter registration number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.registrationnumber}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('registrationnumber', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'vatnumber'}
                        label={'Enter VAT number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.vatnumber}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('vatnumber', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'businesstype'}
                        title={'Select Company Type'}
                        data={this.props.state.businesstype_data}
                        value={this.props.state.businesstype}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        displayfields={['businesstype']}
                        fieldtext={'businesstype'}
                        fieldvalue={'businesstype_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('businesstype', args.text)
                            this.props.actions.setInputValue('businesstype_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('businesstype', 'businesstype', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.setInputValue('businesstype', search)
                                this.props.actions.getLookupData('businesstype', 'businesstype', search)
                            } else {
                                this.props.actions.setInputValue('businesstype', '')
                                this.props.actions.setInputValue('businesstype_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'suppliertype'}
                        title={'Select Supplier Category'}
                        data={this.props.state.suppliertype_data}
                        value={this.props.state.suppliertype}
                        displayfields={['suppliertype']}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        fieldtext={'suppliertype'}
                        fieldvalue={'suppliertype_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('suppliertype', args.text)
                            this.props.actions.setInputValue('suppliertype_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('suppliertype', 'suppliertype', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('suppliertype', 'suppliertype', search)
                                this.props.actions.setInputValue('suppliertype', search)
                            } else {
                                this.props.actions.setInputValue('suppliertype', '')
                                this.props.actions.setInputValue('suppliertype_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'suppliertype'}
                        title={'Select Supplier Group'}
                        data={this.props.state.suppliergroup_data}
                        value={this.props.state.suppliergroup}
                        displayfields={['suppliergroup']}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        fieldtext={'suppliergroup'}
                        fieldvalue={'suppliergroup_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('suppliergroup', args.text)
                            this.props.actions.setInputValue('suppliergroup_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('suppliergroup', 'suppliergroup', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('suppliergroup', 'suppliergroup', search)
                                this.props.actions.setInputValue('suppliergroup', search)
                            } else {
                                this.props.actions.setInputValue('suppliergroup', '')
                                this.props.actions.setInputValue('suppliergroup_id', '')
                            }
                        }}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    }
    getBankingDetails() {
        return <div> <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4}>
                    <AutoComplete
                        name={'financialinstitution'}
                        title={'Select Bank Name'}
                        data={this.props.state.financialinstitution_data}
                        value={this.props.state.fundername}
                        displayfields={['fundername']}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        fieldtext={'fundername'}
                        fieldvalue={'financialinstitution_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('fundername', args.text)
                            this.props.actions.setInputValue('financialinstitution_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('financialinstitution', 'fundername', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('financialinstitution', 'fundername', search)
                                this.props.actions.setInputValue('fundername', search)
                            } else {
                                this.props.actions.setInputValue('fundername', '')
                                this.props.actions.setInputValue('financialinstitution_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'bankaccountname'}
                        label={'Enter bank account name'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.bankaccountname}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('bankaccountname', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'bankbranchnumber'}
                        label={'Enter branch code number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.bankbranchnumber}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('bankbranchnumber', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'bankaccountnumber'}
                        label={'Enter account number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.bankaccountnumber}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('bankaccountnumber', event.target.value)
                        }}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    }

    getProducts() {
        return <div>

            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} key={'col_add'}>
                        <Button
                            onClick={(event) => {
                                this.props.actions.addProduct()
                            }}
                            className="btn-pill-off">
                            <span className="btn-wrapper--label">Add Item</span>
                        </Button>
                    </Col>
                    <Col xl={10} ></Col>
                    <Col xl={12}>
                        {
                            this.props.state.products.length
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{ zIndex: 1 }}>
                                        {
                                            this.props.state.products.map((x, inx) => {
                                                return <tr>
                                                    <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                <Row>
                                                                    <Col xl={2}>
                                                                        <AutoComplete
                                                                            name={'field_' + inx}
                                                                            title={'Select Product/Service'}
                                                                            data={this.props.state.product_data}
                                                                            value={this.props.state.product}
                                                                            displayfields={['product']}
                                                                            dataSourceConfig={{
                                                                                text: 'text',
                                                                                value: 'value'
                                                                            }}
                                                                            fieldtext={'product'}
                                                                            fieldvalue={'product_id'}
                                                                            onSelect={(args) => {
                                                                                //console.log('onSelect args',args)
                                                                                this.props.actions.setInputValue('product', args.text)
                                                                                this.props.actions.setInputValue('product_id', args.value)
                                                                                this.props.actions.setProductInputValue({ inx: inx, prop: 'product', value: args.text })
                                                                                this.props.actions.setProductInputValue({ inx: inx, prop: 'product_id', value: args.value })
                                                                            }}
                                                                            onClick={() => this.props.actions.getLookupData('product', 'product', '')}
                                                                            onFilter={(search) => {
                                                                                if (search) {
                                                                                    this.props.actions.getLookupData('product', 'product', search)
                                                                                    this.props.actions.setInputValue('product', search)
                                                                                    this.props.actions.setProductInputValue({ inx: inx, prop: 'product', value: search })
                                                                                } else {
                                                                                    this.props.actions.setInputValue('product', '')
                                                                                    this.props.actions.setInputValue('product_id', '')
                                                                                    this.props.actions.setProductInputValue({ inx: inx, prop: 'product', value: '' })
                                                                                    this.props.actions.setProductInputValue({ inx: inx, prop: 'product_id', value: null })
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={9} style={{ marginTop: '-18px' }}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'description' + inx}
                                                                            label={'Product/Service Description'}
                                                                            value={x.description}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setProductInputValue({ inx: inx, prop: 'description', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={1}>
                                                                        <div style={{ marginTop: '15px' }}>
                                                                            <IconButton key={'command_product_delete_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                onClick={
                                                                                    () => {
                                                                                        this.props.actions.delProduct(inx)
                                                                                    }
                                                                                }>
                                                                                <DeleteForeverIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Container>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getPaymentDetails() {
        return <div> <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4}>
                    <AutoComplete
                        name={'paymentterm'}
                        title={'Select Payment Term'}
                        data={this.props.state.paymentterm_data}
                        value={this.props.state.paymentterm}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        displayfields={['paymentterm']}
                        fieldtext={'paymentterm'}
                        fieldvalue={'paymentterm_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('paymentterm', args.text)
                            this.props.actions.setInputValue('paymentterm_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('paymentterm', 'paymentterm', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('paymentterm', 'paymentterm', search)
                                this.props.actions.setInputValue('paymentterm', search)
                            } else {
                                this.props.actions.setInputValue('paymentterm', '')
                                this.props.actions.setInputValue('paymentterm_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'discountterm'}
                        title={'Select Discount Term'}
                        data={this.props.state.discountterm_data}
                        value={this.props.state.discountterm}
                        displayfields={['discountterm']}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        fieldtext={'discountterm'}
                        fieldvalue={'discountterm_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('discountterm', args.text)
                            this.props.actions.setInputValue('discountterm_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('discountterm', 'discountterm', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('discountterm', 'discountterm', search)
                                this.props.actions.setInputValue('discountterm', search)
                            } else {
                                this.props.actions.setInputValue('discountterm', '')
                                this.props.actions.setInputValue('discountterm_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'discount_fktext'}
                        label={'Enter discount percentage'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.discount_fktext}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('discount_fktext', event.target.value)
                        }}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    }


    getLimits() {
        return <div> <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'monthlylimit'}
                        label={'Enter monthly limit'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.monthlylimit}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('monthlylimit', event.target.value)
                        }}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    }

    getAddress() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} key={'col_add'}>
                        <Button
                            onClick={(event) => {
                                this.props.actions.addAddress()
                            }}
                            className="btn-pill-off">
                            <span className="btn-wrapper--label">Add Item</span>
                        </Button>
                    </Col>
                    <Col xl={10} ></Col>
                    <Col xl={12}>
                        {
                            this.props.state.address.length
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{ zIndex: 1 }}>
                                        {
                                            this.props.state.address.map((x, inx) => {

                                                return <tr>
                                                    <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                <Row>
                                                                    <Col xl={2} style={{ paddingTop: '15px' }}>

                                                                        <AutoComplete
                                                                            name={'field_' + inx}
                                                                            title={'Select Type'}
                                                                            data={this.props.state.addresstypes}
                                                                            value={x.addresstype}
                                                                            displayfields={['text']}
                                                                            fieldtext={'text'}
                                                                            fieldvalue={'value'}
                                                                            dataSourceConfig={{
                                                                                text: 'text',
                                                                                value: 'value'
                                                                            }}
                                                                            onSelect={(args) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'addresstype', value: args.text })
                                                                            }}
                                                                            onFilter={(search) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'addresstype', value: search })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={3}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'line1' + inx}
                                                                            label={'Enter Line1'}
                                                                            value={x.line1}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'line1', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'line2' + inx}
                                                                            label={'Enter Line2'}
                                                                            value={x.line2}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'line2', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'line3' + inx}
                                                                            label={'Enter Line3'}
                                                                            value={x.line3}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'line3', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'line4' + inx}
                                                                            label={'Enter Postal Code'}
                                                                            value={x.line4}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'line4', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={1}>
                                                                        <div style={{ marginTop: '20px' }}>

                                                                            <IconButton key={'command_address_delete_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                onClick={
                                                                                    () => {
                                                                                        this.props.actions.delAddress(inx)
                                                                                    }
                                                                                }>
                                                                                <DeleteForeverIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Container>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getContact() {
        return <div>

            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} key={'col_add'}>
                        <Button
                            onClick={(event) => {
                                this.props.actions.addContact()
                            }}
                            className="btn-pill-off">
                            <span className="btn-wrapper--label">Add Item</span>
                        </Button>
                    </Col>
                    <Col xl={10} ></Col>
                    <Col xl={12}>
                        {
                            this.props.state.contacts.length
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{ zIndex: 1 }}>
                                        {
                                            this.props.state.contacts.map((x, inx) => {
                                                return <tr>
                                                    <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                <Row>
                                                                    <Col xl={2} style={{ marginTop: '15px' }}>
                                                                        <AutoComplete
                                                                            name={'field_' + inx}
                                                                            title={'Select Contact Type'}
                                                                            data={this.props.state.contacttype_data}
                                                                            value={this.props.state.contacttype}
                                                                            dataSourceConfig={{
                                                                                text: 'text',
                                                                                value: 'value'
                                                                            }}
                                                                            displayfields={['contacttype']}
                                                                            fieldtext={'text'}
                                                                            fieldvalue={'value'}
                                                                            onSelect={(args) => {
                                                                                //console.log('onSelect args',args)
                                                                                this.props.actions.setInputValue('', args.text)
                                                                                this.props.actions.setInputValue('contacttype_id', args.value)
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'contacttype', value: args.text })
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'contacttype_id', value: args.value })
                                                                            }}
                                                                            onClick={() => this.props.actions.getLookupData('contacttype', 'contacttype', '')}
                                                                            onFilter={(search) => {
                                                                                if (search) {
                                                                                    this.props.actions.setInputValue('', search)
                                                                                    this.props.actions.setContactInputValue({ inx: inx, prop: 'contacttype', value: search })
                                                                                    this.props.actions.getLookupData('contacttype', 'contacttype', search)
                                                                                } else {
                                                                                    this.props.actions.setContactInputValue({ inx: inx, prop: 'contacttype', value: '' })
                                                                                    this.props.actions.setContactInputValue({ inx: inx, prop: 'contacttype_id', value: '' })
                                                                                    this.props.actions.setInputValue('contacttype_id', '')
                                                                                    this.props.actions.setInputValue('contacttype', '')
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'firstname' + inx}
                                                                            label={'Enter Firstname'}
                                                                            value={x.firstname}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'firstname', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'lastname' + inx}
                                                                            label={'Enter Lastname'}
                                                                            value={x.lastname}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'lastname', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'email' + inx}
                                                                            label={'Enter Email Address'}
                                                                            value={x.email}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'email', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'telephone' + inx}
                                                                            label={'Enter Contact Number'}
                                                                            value={x.telephone}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'telephone', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={1} style={{ marginTop: '25px' }}>{'Auth Signatory'}</Col>
                                                                    <Col xl={1} style={{ marginTop: '25px' }}>
                                                                        <Checkbox
                                                                            id={'chk_auth'}
                                                                            key={'chk_auth'}
                                                                            name={'chk_auth'}
                                                                            field={'auth_signatory'}
                                                                            checked={this.props.state.auth_signatory}
                                                                            labelPlacement={'end'}
                                                                            label={window.t.en('Auth Signatory')}
                                                                            // onCheck={(checked) => {
                                                                            //     this.props.actions.setInputValue({prop:'send_marketing',value: checked})
                                                                            // }}
                                                                            // checked={this.props.state.signed_vaf}
                                                                            onChange={
                                                                                (event, checked) => {
                                                                                    this.props.actions.setInputValue('auth_signatory', checked)
                                                                                    this.props.actions.setContactInputValue({ inx: inx, prop: 'auth_signatory', value: checked })
                                                                                }
                                                                            }
                                                                        />
                                                                    </Col>

                                                                    <Col xl={1}>
                                                                        <div style={{ marginTop: '20px' }}>
                                                                            <IconButton key={'command_contact_delete_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                onClick={
                                                                                    () => {
                                                                                        this.props.actions.delContact(inx)
                                                                                    }
                                                                                }>
                                                                                <DeleteForeverIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Container>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getDocuments() {
        return <div>
            <Upload
                id={'supplier'}
                prefix={'Supplier'}
                header={false}
            /></div>
    }
    getChecklist() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Company Registration Documents? *'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'company_reg_document_recieved'}
                            label={'Company Registration Documents?'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.company_reg_document_recieved}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('company_reg_document_recieved', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Valid Tax Clearance Certificates / Valid PIN? *'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'taxclearancecertificatereceived'}
                            label={'Valid Tax Clearance Certificates / Valid PIN?'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.taxclearancecertificatereceived}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('taxclearancecertificatereceived', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Valid B-BBEE Certificate / Affidavit ? *'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'beecertificatereceived'}
                            label={'Valid B-BBEE Certificate / Affidavit ?'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.beecertificatereceived}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('beecertificatereceived', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Confirmation of Banking Details / Cancelled Cheque?'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'confirmation_banking_details_recieved'}
                            label={'Confirmation of Banking Details / Cancelled Cheque?'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.confirmation_banking_details_recieved}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('confirmation_banking_details_recieved', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Signed Supplier Acknowledgement of Procurement  and Anti Fraud Policies?'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'signed_proc_fraud_policies_recieved'}
                            label={'Signed Supplier Acknowledgement of Procurement and Anti Fraud Policies'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.signed_proc_fraud_policies_recieved}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('signed_proc_fraud_policies_recieved', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Conflict of Interest Declaration (where applicable)?'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'interest_decleration_recieved'}
                            label={'Conflict of Interest Declaration (where applicable) ?'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.interest_decleration_recieved}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('interest_decleration_recieved', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Signed Code of Ethical Purchasing?'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'ethical_purchasing_recieved'}
                            label={'Signed Code of Ethical Purchasing?'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.ethical_purchasing_recieved}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('ethical_purchasing_recieved', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Completed Service Provider Agreement (where applicable)?'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'service_provider_agreement_recieved'}
                            label={'Completed Service Provider Agreement (where applicable)?'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.service_provider_agreement_recieved}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('service_provider_agreement_recieved', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Proof of Insurance / Insurance Schedule (where applicable)? *'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'proof_of_insurance_recieved'}
                            label={'Proof of Insurance / Insurance Schedule (where applicable)?'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.proof_of_insurance_recieved}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('proof_of_insurance_recieved', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xl={6} style={styles.selectedFieldName}>{'Landlords Waiver on File (where applicable)? *'}</Col>
                    <Col xl={1} style={styles.selectedFieldName}>
                        <Checkbox
                            key={'landlords_waiver_recieved'}
                            label={'Landlords Waiver on File (where applicable)?'}
                            labelPosition='left'

                            style={styles.toggle}
                            trackStyle={{
                                backgroundColor: 'gray'
                            }}
                            trackSwitchedStyle={{
                                backgroundColor: 'green'
                            }}
                            checked={this.props.state.landlords_waiver_recieved}
                            onChange={
                                (event, isInputChecked) => {
                                    this.props.actions.setInputValue('landlords_waiver_recieved', isInputChecked)
                                }
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} style={styles.selectedFieldName}>
                        <br />
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getCapture() {
        return <div>
            <Card
                title={"Supplier Details"}
                subtitle={window.t.en('Enter new supplier details')}
                content={this.getDetails()}
            />
            <Card
                title={"Supplier Products/Services"}
                subtitle={window.t.en('Enter supplier products/services offered')}
                content={this.getProducts()}
            />
            <Card
                title={"Supplier Banking Details"}
                subtitle={window.t.en('Enter new supplier banking details')}
                content={this.getBankingDetails()}
            />
            <Card
                title={"Supplier Payment Details"}
                subtitle={window.t.en('Enter new supplier payment details')}
                content={this.getPaymentDetails()}
            />
            <Card
                title={"Supplier Limits"}
                subtitle={window.t.en('Enter supplier limits')}
                content={this.getLimits()}
            />
            <Card
                title={"Supplier Address"}
                subtitle={window.t.en('Enter supplier address details')}
                content={this.getAddress()}
            />
            <Card
                title={"Supplier Contact"}
                subtitle={window.t.en('Enter supplier contact details')}
                content={this.getContact()}
            />
            <Card
                title={"Supplier FICA"}
                subtitle={window.t.en('Upload supplier supporting documents')}
                content={this.getDocuments()}
            />
            <Card
                title={"Supplier Documents Checklist"}
                subtitle={window.t.en('Please ensure that all mandatory documents have been uploaded')}
                content={this.getChecklist()}
            />
        </div>
    }


    getConfirm() {
        //console.log('getConfirm', this.props.state.documents)
        const contactsWithoutId = this.props.state.contacts.map(({ contacttype_id, ...cont }) => cont);
        const productsWithoutId = this.props.state.products.map(({ product_id, ...prod }) => prod);
        //console.log('contactsWithoutId', contactsWithoutId)
        //console.log('productsWithoutId', productsWithoutId)
        return <div>
            <Card
                title={"Supplier Details"}
                subtitle={window.t.en('Enter new supplier details')}
                content={<Fields
                    fields={[
                        { size: 4, label: 'Supplier Name', value: this.props.state.suppliername },
                        { size: 4, label: 'Trading Name', value: this.props.state.tradingname },
                        { size: 4, label: 'Account Number', value: this.props.state.accountnumber },
                        { size: 4, label: 'Registration Number', value: this.props.state.registrationnumber },
                        { size: 4, label: 'VAT Number', value: this.props.state.vatnumber },
                        { size: 4, label: 'Supplier Type', value: this.props.state.suppliertype }
                    ]}
                />}
            />
            <Card
                title={"Supplier Products/Services"}
                subtitle={window.t.en('Supplier product/service details')}
                content={<Fields data={productsWithoutId} columns={['Product/Service', 'Description']} />}
            />
            <Card
                title={"Supplier Payment Details"}
                subtitle={window.t.en('Enter new supplier payment details')}
                content={<Fields
                    fields={[
                        { size: 4, label: 'Prefferd Payment Terms', value: this.props.state.paymentterm },
                        { size: 4, label: 'Preffered Discount Terms', value: this.props.state.discountterm },
                        { size: 4, label: 'Preffered Discount Percentage', value: this.props.state.discount_fktext }
                    ]}
                />}
            />
            <Card
                title={"Supplier Banking Details"}
                subtitle={window.t.en('Enter new supplier banking details')}
                content={<Fields
                    fields={[
                        { size: 4, label: 'Name of Bank', value: this.props.state.fundername },
                        { size: 4, label: 'Account Name', value: this.props.state.bankaccountname },
                        { size: 4, label: 'Branch Name', value: this.props.state.accountnumber },
                        { size: 4, label: 'Branch Code', value: this.props.state.bankbranchnumber },
                        { size: 4, label: 'Account Number', value: this.props.state.bankaccountnumber }
                    ]}
                />}
            />
            <Card
                title={"Supplier Limits"}
                subtitle={window.t.en('Supplier limits')}
                content={<Fields
                    fields={[
                        { size: 4, label: 'Monthly Limit', value: this.props.state.monthlylimit }
                    ]}
                />}
            />
            <Card
                title={"Supplier Address"}
                subtitle={window.t.en('Supplier address details')}
                content={<Fields data={this.props.state.address} columns={['Address Type', 'Address Line1', 'Address Line2', 'Address Line3', 'Address Line4']} />}
            />
            <Card
                title={"Supplier Contact"}
                subtitle={window.t.en('Supplier contact details')}
                content={<Fields data={contactsWithoutId} columns={['Contact Type', 'First Name', 'Last Name', 'Email Address', 'Contact Number']} />}
            />
            <Card
                title={"Supplier FICA"}
                subtitle={window.t.en('Uploaded supplier supporting documents')}
                content={<PdfViewer
                    key={'supplier_fica'}
                    name={'supplier_fica'}
                    documents={this.props.state.documents.documents}
                    edit={false}
                    multiple={false}
                    workflow_queue_data={null}
                />}
            />
            <Card
                title={"Supplier Documents Checklist"}
                subtitle={window.t.en('Please ensure that all mandatory documents have been uploaded')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Proof of Insurance / Insurance Schedule? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.proof_of_insurance_recieved ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Completed Service Provider Agreement? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.service_provider_agreement_recieved ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Conflict of Interest Declaration? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.interest_decleration_recieved ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Signed Supplier Acknowledgement of Procurement  and Anti Fraud Policies?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.signed_proc_fraud_policies_recieved ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Landlords Waiver on File?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.landlords_waiver_recieved ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Confirmation of Banking Details / Cancelled Cheque?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.confirmation_banking_details_recieved ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Signed Code of Ethical Purchasing?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.ethical_purchasing_recieved ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Company Registration Document?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.company_reg_document_recieved ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Valid Tax Clearance Certificates / Valid PIN? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                <strong>{this.props.state.taxclearancecertificatereceived ? "YES" : "NO"}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.selectedFieldName}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                }
            />
        </div>
    }

    getLayout() {
        return <div>{
            this.props.mode === 'confirm'
                ? this.getConfirm()
                : this.getCapture()
        }
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.supplierMasterCapture,
            documents: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierMasterCapture)