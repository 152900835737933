export const GET_RESELLER_ACCOUNT_DATA = 'GET_RESELLER_ACCOUNT_DATA'
export const SET_RESELLER_ACCOUNT_DATA = 'SET_RESELLER_ACCOUNT_DATA'
export const DELETE_COMPONENT_RESELLER_RECORD = 'DELETE_COMPONENT_RESELLER_RECORD'
export const TOGGLE_RESELLER_DELETED_WIN_STATUS = 'TOGGLE_RESELLER_DELETED_WIN_STATUS'

export const SET_RESELLER_ACCOUNT_CURRENT_TAB = 'SET_RESELLER_ACCOUNT_CURRENT_TAB'
export const SET_RESELLER_COMPONENT_FIELD_INPUT = 'SET_RESELLER_COMPONENT_FIELD_INPUT'

export const SAVE_RESELLER_COMPONENT_CUSTOMER_DATA = 'SAVE_RESELLER_COMPONENT_CUSTOMER_DATA'
export const GET_RESELLER_COMPONENT_FIELD_LOOKUP_DATA = 'GET_RESELLER_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_RESELLER_COMPONENT_FIELD_LOOKUP_DATA = 'SET_RESELLER_COMPONENT_FIELD_LOOKUP_DATA'