export const contacttypes = [{text: 'Fleet Manage', value: 1},{text: 'Fleet Account', value: 2}]
export const vatable_data = [{text: 'VAT Inclusive', value: 1},{text: 'VAT Exclusive', value: 2}]
export const pricing = []
export const pricing_data = []
export const supplier_data = []
export const customer_data = []
export const empty = true

export const fields = {
  documents: {
    errorText: 'Please add a document and select document type',
    data: false,
    valid: false,
    empty: true,
    popup: true,
    type: 'documents',
  },

  vehicleSaved: {
    errorText: "Please ensure that you have saved the Vehicle items you've added, if you do not want to add an item, please remove it before proceeding",
    state: 'getState().vehicleMasterCapture.pricing?.length ? (!getState().vehicleMasterCapture.pricing?.find(x=>x.editing === true) ? true : false) : true',
    valid: false
  },

  vehicleCustomer: {
    errorText: 'Please select Vehicle Customer',
    state: 'getState().vehicleMasterCapture.pricing?.length ?  getState().vehicleMasterCapture.pricing : true',
    valid: false,
    type: 'table',
    tableFields: ['customers']
  },

  vehicleVatable: {
    errorText: 'Please input Vehicle Vat Type',
    state: 'getState().vehicleMasterCapture.pricing?.length ?  getState().vehicleMasterCapture.pricing : true',
    valid: false,
    type: 'table',
    tableFields: ['vatable']
  },
  vehicleExpiryDate: {
    errorText: 'Please input Vehicle Expiry Date',
    state: 'getState().vehicleMasterCapture.pricing?.length ?  getState().vehicleMasterCapture.pricing : true',
    valid: false,
    type: 'table',
    tableFields: ['expirydate']
  },
  vehicleSellingPrice: {
    errorText: 'Please input Vehicle Selling Price',
    state: 'getState().vehicleMasterCapture.pricing?.length ?  getState().vehicleMasterCapture.pricing : true',
    valid: false,
    type: 'table',
    tableFields: ['sellingprice'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Vehicle Selling Price'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Vehicle Selling Price'
      }
    ]
  },
  vehicleCostPrice: {
    errorText: 'Please input Vehicle Cost Price',
    state: 'getState().vehicleMasterCapture.pricing?.length ?  getState().vehicleMasterCapture.pricing : true',
    valid: false,
    type: 'table',
    tableFields: ['costprice'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Vehicle Cost Price'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Vehicle Cost Price'
      }
    ]
  },
  vehicleSupplier: {
    errorText: 'Please select Vehicle Supplier',
    state: 'getState().vehicleMasterCapture.pricing?.length ?  getState().vehicleMasterCapture.pricing : true',
    valid: false,
    type: 'table',
    tableFields: ['supplier_id']
  },

  vehicle: {
    errorText: 'Please add at least one item for Vehicle Pricing',
    state: 'getState().vehicleMasterCapture.pricing?.length',
    valid: false
  }
}