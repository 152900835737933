import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import ViewVehicle from '../../../controls/view_vehicle'


import * as actions from './actions'
import * as styles from './styles'

class OdometerChangeApprove extends Component {
   
    componentDidMount() {
        this.props.actions.resetForm()
    }
    
    getVehicle(vehicle) {
        return <div>
            <ViewVehicle
                key={vehicle.vehicle_id}
                id={vehicle.vehicle_id}
            />
        </div>
    }

    getView(data) {
        let confirm = data?.data
        //console.log('OdometerChangeApprovex confirm', confirm)
        return data ? <div>
            {this.getVehicle(confirm.vehicle)} 
            <Card
                title={"Vehicle Odometer Details"}
                subtitle={window.t.en('Confirm new vehicle odometer')}
                content={<Fields  fields={[
                        {size: 4, label: 'Current Odometer:', value: confirm.vehicle.closingodometer},
                        {size: 4, label: 'New Odometer:', value: confirm.odometer},
                        {size: 4, label: 'Change Date:', value: confirm.odometerdate}
                    ]} />}
            /> 
            <Card
                title={"Vehicle Odometer Supporting Documents"}
                subtitle={window.t.en('Uploaded vehicle odometer supporting documents')}
                content={<PdfViewer
                    key={'odometer_change'}
                    name={'odometer_change'}
                    documents={confirm.documents.documents}
                    edit={false}
                    multiple={false}
                    workflow_queue_data={null}
                />}
            />
            <Card
            title={'Approval Comments '}
            subtitle={'Approval approval comments'}
            content={<Container style={{ margin: 15 }} className="custom-container">
                <Row>
                    <Col xl={12} style={{ marginTop: -25 }}>
                        <TextField
                            variant='outlined'
                            id={'txt_comments'}
                            label={'Enter Comments'}
                            fullWidth={true}
                            value={this.props.state.comments}
                            onChange={(event, value) => {
                                this.props.actions.setInputValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>
            } />
        </div> : ''
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('OdometerChangeApprovex', data.context_data.data.data.data[0])
        data = data.context_data.data.data.data[0].step1
        return <div>
            {this.getView(data)}
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.odometerChangeApprove
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OdometerChangeApprove)