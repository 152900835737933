import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'


import { Table, Button, IconButton  } from '@mui/material'

import Icon from '../../Icon/component'

import VisibilityIcon from '@mui/icons-material/Visibility';
import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewSupplierTransactions from '../../../controls/view_supplier_transaction'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Form from '../../../controls/form'
import Drawer from '../../../controls/drawer'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'
import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

import Checkbox from '../../../controls/checkbox'
import CardControl from '../../../controls/card'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import * as styles from './styles'
import * as actions from './actions'

class InterestRateCreate extends Component {

    componentDidMount() {
        this.props.actions.setValidationState('interestRateCreate')
        if(this.props.mode === "confirm") { 
            this.props.actions.setupWorkflowTaskInput(this.props.state, [], 'interest_rate', 'tsk_interest_rate_create')
        }
        else {
            this.props.actions.getPrimeRate()
            this.props.actions.getInterestRateContractsList()
            //console.log('statex',JSON.stringify(this.props.state))
            this.props.actions.resetForm()
        }
    }

    getDuplicateData() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th>Auth No</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Odometer</th>
                                    <th>Task No</th>
                                    <th>Booked By</th>
                                    <th>Booked Date</th>
                                    <th>Supplier</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        [{}].map(x => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.authnumber}</td>
                                                <td>{x.maintenancetype}</td>
                                                <td>{x.comments}</td>
                                                <td>{x.odometer}</td>
                                                <td>{x.tasknumber}</td>
                                                <td>{x.bookedby}</td>
                                                <td>{x.bookeddate}</td>
                                                <td>{x.suppliertype}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    }  

    invoiceItems() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    
                <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Invoice Number</th>
                                        <th>Auth Number</th>
                                        <th>Description</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.state.supplier_transactions.map((x,inx) => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.reference}</td>
                                            <td>{x.authnumber}</td>
                                            <td>{x.description}</td>
                                            <td>{moment(x.suppliertransactiondate).format('DD-MM-YYYY')}</td>
                                            <td>{x.amount}</td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <th colSpan={5}></th>
                                        <th>{_.sumBy(this.props.state.supplier_transactions.filter(x => x.pay_status), (d) => { return parseFloat(d.amount ? d.amount : 0)}).toFixed(2)}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    } 
    
    paymentSummary() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    
                <Table className="table table-hover text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>Statement Amount</th>
                            <th>Allocated Amount</th>
                            {/* <th>Short Payment</th> */}
                            <th>Credited Amount</th>
                            <th>Discount Percentage</th>
                            <th>Discount Amount</th>
                            <th>Payable Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                            <td>
                                <TextField
                                    id={'short_payment'}
                                    multiLine={false}
                                    width={160}
                                    label={'Statement Amount'}
                                    variant="outlined"
                                    value={this.props.state.statement_amount}
                                    onChange={(event) => {
                                        this.props.actions.setInputValue('statement_amount', event.target.value)
                                        this.props.actions.calcTotal()
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={'short_payment'}
                                    multiLine={false}
                                    width={160}
                                    label={'Allocated Amount'}
                                    variant="outlined"
                                    value={this.props.state.allocated_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //this.props.actions.setInputValue('statement_amount', event.target.value)
                                    }}
                                />
                            </td>
                            {/* <td>
                                <TextField
                                    id={'short_payment'}
                                    multiLine={false}
                                    width={160}
                                    label={'Short Payment'}
                                    variant="outlined"
                                    value={this.props.state.shortpayment_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                    }}
                                />
                            </td> */}
                            <td>
                                <TextField
                                    id={'credited_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Credited Amount'}
                                    variant="outlined"
                                    value={this.props.state.credited_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={'discount_percentage'}
                                    multiLine={false}
                                    width={160}
                                    label={'Discount Percentage'}
                                    variant="outlined"
                                    value={this.props.state.discount_percentage}
                                    //disabled={true}
                                    onChange={(event) => {
                                        this.props.actions.setInputValue('discount_percentage', event.target.value)

                                        this.props.actions.calcTotal()
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={'discount_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Discount Amount'}
                                    variant="outlined"
                                    value={this.props.state.discount_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        this.props.actions.setInputValue('discount_amount', event.target.value)
                                        this.props.actions.calcTotal()
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={'payable_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Payable Amount'}
                                    variant="outlined"
                                    value={this.props.state.payable_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                    }}
                                />
                            </td>
                    </tbody>
                </Table>
                </Col>
            </Row>
        </Container>
    }

    getForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                            <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Invoice Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        
                                        <DateControl
                                                id={'date'}
                                                key={'date'}
                                                label={'Enter transaction date'}
                                                name={'date'}
                                                //error={this.props.state.fields.date.value ? false : true}
                                                //helperText={this.props.state.fields.date.value ? '' : 'Enter transaction date'}
                                                value={this.props.state.date}
                                                onSelect={(args) => {
                                                    this.props.actions.setInputValue('date',args)
                                                }}
                                                type={'date'}
                                            />
                                    </div>
                                </div>
                             
                             </Col>
                            </Row>
                            <Row>
                            <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Month:'}</div>
                                    <div style={styles.fieldContent}>
                                    <TextField variant="outlined" 
                                            id={'month'}
                                            label={'Enter transaction month'}
                                            fullWidth={true}
                                            multiLine={false}
                                            //error={isNaN(this.props.state.fields.month.value) ? true : false}
                                            //helperText={isNaN(this.props.state.fields.month.value) ? '' : 'Enter month (YYYYMM)'}
                                            value={this.props.state.month}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue('month',event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                             
                             </Col>
                            </Row>
                            <Row>
                            <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Invoice Number:'}</div>
                                    <div style={styles.fieldContent}>
                                    <TextField variant="outlined" 
                                            id={'reference'}
                                            label={'Enter Invoice Number'}
                                            fullWidth={true}
                                            multiLine={false}
                                            //error={this.props.state.fields.invoice_number.value ? false : true}
                                            //helperText={this.props.state.fields.invoice_number.value ? '' : 'Enter invoice number'}
                                            value={this.props.state.invoice_number}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue('invoice_number',event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                             
                             </Col>
                            </Row>
                            <Row>
                            <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Invoice Amount:'}</div>
                                    <div style={styles.fieldContent}>
                                    <TextField variant="outlined" 
                                            id={'description'}
                                            label={'Invoice Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            //error={this.props.state.fields.invoice_amount.value ? false : true}
                                            //helperText={this.props.state.fields.invoice_amount.value ? '' : 'Enter amount'}
                                            value={this.props.state.invoice_amount}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue('invoice_amount',event.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                             
                             </Col>
                            </Row>
                        </Container>
    }

    viewForm() {
       return <Container style={{ margin: 0 }} className="custom-container">
       <Row>
        <Col  xl={12}>
          <Card title={window.t.en('Invoice Details')}
        subtitle={window.t.en('Capture supplier details')}
        content={
                <div>
                <Fields labelsize='130'
                fields={[
                    {size: 6, label: 'Supplier:', value: ''},
                    {size: 6, label: 'Date', value: ''},
                    {size: 6, label: 'Reference', value: ''},
                    {size: 6, label: 'Description', value: ''},
                ]}
                /></div>
            }
        />
                    </Col>
                    <Col  xl={12}>
                         {/* {
                            this.data.step1.supplier_id && <ViewSupplier
                                min={true}
                                key={this.data.step1.supplier_id} 
                                id={this.data.step1.supplier_id} 
                            />
                        } */}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                    </Col>
                </Row>
            </Container>
    }

    
    getTransactionItems(confirm) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Contract Number</th>
                                    <th>Vehicle Registration</th>
                                    <th>Customer Name</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Interest Rate</th>
                                    <th>New Interest Rate</th>
                                    <th>Finance</th>
                                    <th>ROI</th>
                                    <th>Sub Total</th>
                                    <th>VAT</th>
                                    <th>Monthly Rental</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td colSpan={8}>
                                        <TextField
                                            id={'short_payment'}
                                            multiLine={false}
                                            fullWidth={true}
                                            label={'Enter Search'}
                                            variant="outlined"
                                            value={this.props.state.search}
                                            onChange={(event) => {
                                                //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                            }}
                                        />
                                    </td>
                                </tr> */}
                                {
                                    this.props.state.contracts.map((x,inx) => {
                                        let derivative = x.customercontractderivative.length ? x.customercontractderivative[0] : {}
                                        return  <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.contractnumber} </td>
                                            <td>{x.licenseplateno}</td>
                                            <td>{x.customer.customername}</td>
                                            <td>{moment(x.startdate).format('DD-MM-YYYY')}</td>
                                            <td>{moment(x.terminationdate).format('DD-MM-YYYY')}</td>
                                            <td>{derivative.interestrate}</td>
                                            <td>{derivative.xinterestrate}</td>
                                            <td>{derivative.monthlyreturnoninvestment}</td>
                                            <td>{derivative.financemonthlyselling}</td>
                                            <td>{derivative.monthlysellingsubtotal}</td>
                                            <td>{derivative.monthlysellingvat}</td>
                                            <td>{derivative.monthlysellingtotal}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getView() {
        let size = 7
        return <div>
            <Card
                title={'Interest Rate Change'}
                subtitle={'Manage interest rate change'}
                content={<Form label={false} fields={[
                    {
                        name: 'interest_rate', fullWidth: true, label: 'Interest Rate', title: 'Interest Rate', section: '', type: 't', size: 4, value: this.props.state.interest_rate, 
                        onChange: (arg) => { this.props.actions.setInputValue('interest_rate', arg.target.value); this.props.actions.calcTotal() }
                    },
                    {
                        name: 'effective_date', label: 'Effective Date', title: 'Effective Date', section: '', type: 'd', size: 4, value: this.props.state.effective_date, 
                        onSelect: (arg) => { this.props.actions.setInputValue('effective_date', arg) }
                    }
                ]} />}
            />
            <Card
                title={'Contract List'}
                subtitle={'Contracts Management List'}
                content={this.getTransactionItems()}
            />
        </div>
    }

    getConfirm() {
        
        return <div>
            <Card title={window.t.en('Interest Rate Details')}
        subtitle={window.t.en('Confirm interest rate details')}
        content={
                <div> <Fields labelsize='130'
        fields={[
            {size: 6, label: 'Interest Rate:', value: this.props.state.interest_rate},
            {size: 6, label: 'Affective Date', value: this.props.state.effective_date},
        ]}
        /></div>}
        />
        <Card
            title={'Contract List Review'}
            subtitle={'Contracts Management List'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <h5>{'Interest Rate Applied  List'}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.getTransactionItems()}
                    </Col>
                </Row>
            </Container>}
        />
        <Card
            title={'Supporting Documents'}
            subtitle={'Credit note uploaded supporting documents '}
            content={
                <PdfViewer
                    key={'payment_document'}
                    name={'payment_document'}
                    documents={this.props.state.upload.documents}
                />
            }
        />
        </div>
    }

    getLayout() {
        return <>
            {this.props.mode === "confirm" ? this.getConfirm() : this.getView()}
        </>
    }

    getErrorLayout() {
        return <Card
            title={'Error'}
            subtitle={'Connection did not respond  '}
            content={<div>
                <h4>Please check following:</h4>
                <ul>
                    <li>Make sure engine is connected</li>
                    <li>Make sure your not login twice</li>
                </ul>
                <i>Alternativily you can contact system administrator for further assistance.</i>
            </div>}
        />
    }

    render() {
        
        this.data = null
        try {
        } catch (error) {

        }
        
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.interestRateCreate,
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;
    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InterestRateCreate)