export const team = ''
export const name = ''
export const description = ''
export const parentname = ''
export const customername = ''
export const locale = ''
export const email = ''
export const phone = ''
export const teams = []
export const locales = []
export const clients = []
export const customers = []
export const team_structure = []
export const customer_structure = []
export const team_id = null
export const locale_id = null
export const client_id = null