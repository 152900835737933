import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SUBMIT_COGNITO_USER:
            return {
                ...state,
                signUp: {
                    ...state.signUp,
                    fields: action.payload
                }
            }
        case types.CREATE_COGNITO_USER_FAILED:
            return {
                ...state,
                signUp: {
                    ...state.signUp,
                    error: action.payload.error,
                    result: action.payload.result,
                    userData: action.payload.userData,
                    cognitoUser: action.payload.cognitoUser
                }
            }
        case types.CREATE_COGNITO_USER_SUCCEEDED:
            return {
                ...state,
                stage: types.CREATE_COGNITO_USER_SUCCEEDED,
                signUp: {
                    ...state.signUp,
                    error: action.payload.error,
                    result: action.payload.result,
                    userData: action.payload.userData,
                    cognitoUser: action.payload.cognitoUser
                }
            }
        case types.SUBMIT_REGISTRATION_CONFIRMATION:
            return {
                ...state,
                stage: types.SUBMIT_REGISTRATION_CONFIRMATION,
                confirmRegistration: {
                    ...state.signUp,
                    fields: action.payload
                }
            }
        case types.REGISTRATION_CONFIRMATION_FAILED:
            return {
                ...state,
                stage: types.REGISTRATION_CONFIRMATION_FAILED,
                confirmRegistration: {
                    ...state.confirmRegistration,
                    error: action.payload.error,
                    result: action.payload.result
                }
            }
        case types.REGISTRATION_CONFIRMATION_SUCCEEDED:
            return {
                ...state,
                stage: types.REGISTRATION_CONFIRMATION_SUCCEEDED,
                confirmRegistration: {
                    ...state.confirmRegistration,
                    error: action.payload.error,
                    result: action.payload.result
                }
            }
        case types.SUBMIT_RESEND_REGISTRATION_CONFIRMATION_CODE:
            return {
                ...state,
                stage: types.SUBMIT_RESEND_REGISTRATION_CONFIRMATION_CODE,
                resendConfirmationCode: {
                    ...state.resendConfirmationCode,
                    sending: action.payload
                }
            }
        case types.RESEND_REGISTRATION_CONFIRMATION_CODE_FAILED:
            return {
                ...state,
                stage: types.RESEND_REGISTRATION_CONFIRMATION_CODE_FAILED,
                resendConfirmationCode: {
                    ...state.resendConfirmationCode,
                    error: action.payload.error,
                    result: action.payload.result,
                    sending: action.payload.sending
                }
            }
        case types.RESEND_REGISTRATION_CONFIRMATION_CODE_SUCCEEDED:
            return {
                ...state,
                stage: types.RESEND_REGISTRATION_CONFIRMATION_CODE_SUCCEEDED,
                resendConfirmationCode: {
                    ...state.resendConfirmationCode,
                    error: action.payload.error,
                    resendsLeft: action.payload.resendsLeft,
                    result: action.payload.result,
                    sending: action.payload.sending
                }
            }
        case types.GET_COGNITO_USER:
            return {
                ...state,
                cognitoUser: action.payload
            }
/*         case types.GET_COGNITO_USER_DATA:
            return {
                ...state,
                userData: action.payload
            } */
/*         case types.GET_COGNITO_USER_AUTHENTICATION_DETAILS:
            return {
                ...state,
                authenticationDetails: action.payload
            } */
        case types.AUTHENTICATE_USER:
            return {
                ...state,
                authenticateUser: action.payload.result
            }
        case types.COGNITO_USER_AUTHENTICATION_FAILED:
            return {
                ...state,
                authenticateUser: action.payload
            }
        case types.COGNITO_USER_AUTHENTICATION_SUCCEEDED:
            return {
                ...state,
                authenticateUser: action.payload
            }
        case types.KEEPLIVE_USER_AUTHENTICATION_SUCCEEDED:
            return {
                ...state,
                authenticateUser: action.payload
            }
        default:
            return state
    }
}