import * as types from './types'

export const resetSelectedData = (component) => {

    window.glyco.log('resetSelectedData')
    window.glyco.log(component)
    window.glyco.log(component.name)
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_GRID_SELECTION,
            payload: {
                component: component.name
            }
        })
    }
}

export const confirmSelection = (step, component) => {
    return (dispatch, getState) => {

        const data = getState().components[component].selected.map(x => {
            return x.original
        })

        dispatch({
            type: types.CONFIRM_QUOTE_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: getState().components[component].selected.map(x => {
                    return x.original
                })
            }
        })
    }
}

export const resetSelection = (step, component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_QUOTE_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: []
            }
        })
    }
}


export const confirmRowSelection = (step, component) => {
    return (dispatch, getState) => {

        const data = getState().components[component].selected.map(x => {
            return x.original
        })
       
        dispatch({
            type: types.CONFIRM_QUOTE_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: data[0]
            }
        })
    }
}

export const resetRowSelection = (step, component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_QUOTE_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: []
            }
        })
    }
}

export const setComponentFieldInput = (component, prop, value) => {
    return (dispatch, getState) => {
        const _component = getState().components[component]

        dispatch({
            type: types.SET_QUOTE_FIELD_INPUT,
            payload: {
                component: _component.name,
                field: prop,
                input: value,
                validation: ''
            }
        })
    }
}