export let updatevalue = 0.00
export let statement = 0.00
export let discount = 0.00
export let selectedBusinessUnit =
{
    value: 0,
    text: ''
}
export let businessUnits =
    [
        {
            text: 'Loading...',
            value: ''
        }

    ]

export let payments = [
    {
        id: 0,
        date: '',
        eft: 0.00,
        amount: 0.00,
        discount: 0.00,
        total: 0.00
    }
]
export let credits = [
    {
        id: 0,
        date: '',
        invoicenumber: '',
        amount: 0.00,
        reason: '',
        total: 0.00
    }
]
export let others = [
    {
        id: 0,
        date: '',
        invoicenumber: '',
        amount: 0.00,
        reason: '',
        total: 0.00
    }
]

export let paymentsnotcredited = 0.00
export let creditsnotpassed = 0.00
export let recon_data = null
export let requisition_data = null
export let remittance_data = null
export let comments = null
