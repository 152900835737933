import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_CONTRACT_RESTRCTURE_COMPONENT_DATA:
        return {
            ...state,
            [action.payload.prop]:{
                ...state[action.payload.prop],
                data: action.payload.state,
                valid: true,
                loading: false,
            }
        }
        case types.SET_CONTRACT_RESTRCTURE_COMPONENT_GRID_DATA:
        return {
            ...state,
            grid: {
                ...state.grid,
                loading: false,
                data: action.payload
            }
        }
        case types.SET_CONTRACT_RESTRCTURE_COMPONENT_ROW_SELECTION:
        return {
            ...state,
            original: action.payload
        }
        case types.SET_BULK_RESTRUCTURE:
        return {
            ...state,
            is_bulk: action.payload
        }
        case types.SET_BULK_RESTRUCTURE_IMPORTED_ROWS:
        return {
            ...state,
            bulk_rows: action.payload
        }
        case types.RESET_BULK_UPLOAD_DATA:
        return {
            ...state,
            bulk_rows: []
        }
        case types.RESET_COMPONENT_STATE_DATA:
        return {
            ...state,
            original: null,
            grid: {
                ...state.grid,
                loading: false,
                data: []
            },
            fields: props.fields
        }
        default:
            return state
    }
}