import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import AutoComplete from '../../../controls/autocomplete'
import { Card, CardHeader, CardContent } from '@mui/material'

import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from './actions'
import * as styles from './styles'






var viewinvoice = false;

class VEHICLETRANSFER extends Component { 
    getView() {
        return <Card style={styles.row}>
                    <CardHeader
                        title={window.t.en('Vehicle Transfer ')}
                        subtitle={window.t.en('Capture Vehicle Transfer details')}
                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                <CardContent>
                    <Container style={styles.whitediv}>
                        
                        <Row>
                            <Col xl={6} style={styles.mainBoldTitle}>

                                <h2>Vehicle Transfer</h2>

                            </Col>
                        
                        </Row>


                        <Row>
                                <Col xl={12}>

                                    <CardHeader

                                    style={styles.contentBottomOffSet}
                                    title={window.t.en('Vehicle Transfer ')}
                                    subtitle={window.t.en('Capture Vehicle Transfer details')}
                                    actAsExpander={true}
                                    showExpandableButton={true}
                                    />

                                </Col>
                            </Row>


                            
                            <Row>

                                <Col xl={12}>
                                   

                                    
                                    <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Vehicle:'}</div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                            style={styles.inputWidth}
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'autocomplete_vehicle_transfer_id'} 
                                            key={'autocomplete_vehicle_transfer_id'}
                                            hintText={'Select Vehicle to be transfered'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'licenseplateno',
                                                value: 'vehicle_id'
                                            }}
                                            dataSource={this.props.state.vehicles}
                                            onNewRequest={
                                                (request, index) => {
                                                    this.props.actions.setVehicleAutoCompleteSelected(request)
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getVehichlesAutoCompleteEpic('')
                                                }
                                            }
                                            onUpdateInput={
                                                (search, dataSource, params) => {
                                                    this.props.actions.getVehichlesAutoCompleteEpic(search)
                                                }
                                            }
                                        />
                                    </div>
                                </div>





                                </Col>

                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Cost Center From:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                        
                                            fullWidth={true}
                                            id={'txtdescription'}
                                            hintText = 'Cost Center From'
                                            value = {this.props.state.selectedCustomerName}
                                        
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12} >

                               



                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Cost Center To:'}</div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                            style={styles.inputWidth}
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'autocomplete_customer_transfer_id'} 
                                            key={'autocomplete_customer_transfer_id'}
                                            hintText={'Select customer to be transfered'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'customername',
                                                value: 'customer_id'
                                            }}
                                            dataSource={this.props.state.customers}
                                            onNewRequest={
                                                (request, index) => {
                                                    this.props.actions.setNewCustomer(request)
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getCustomerAutoCompleteEpic('')
                                                }
                                            }
                                            onUpdateInput={
                                                (search, dataSource, params) => {
                                                    this.props.actions.getCustomerAutoCompleteEpic(search)
                                                }
                                            }
                                        />
                                    </div>
                                </div>


                            </Col>
                        </Row>


                        <Row>
                            <Col xl={12} style={styles.contentBottomOffSet}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Effective Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                                type="date"
                                           
                                            id={'txt_effectdate'}
                                            fullWidth={true}
                                            hintText='Date'
                                            container='inline'
                                            mode='landscape'
                                            value={this.props.state.effectdate}
                                            onChange={(event, value) => {
                                                this.props.actions.setInputValue(value)
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>


                        <Row>


                             <Col style={styles.contentBottomOffSet}>

                               < Button className={"global_button"} variant="contained"
                                    label="Save Transfer"
                                    primary={true}
                                    onClick={(event) => {
                                          
                                            window.glyco.log("Finalize fires")
                                            this.props.actions.finalizeTransferEpic()
                                        }}
                                        >{window.t.en('Save Transfer')}</Button>

                            </Col>


                        </Row>


                      
                        
                    
                    
                    
                        
                    </Container>

                    <Container >
                           
                
                   
                    
                </Container>
                
                         
                

                </CardContent>
        </Card>
    }

    render() {
        return (
            this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.vehicletransfer
          
          
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VEHICLETRANSFER)