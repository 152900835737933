import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Checkbox from '@mui/material/Checkbox'
// import TextField from '@mui/material/TextField'
import { Container, Row, Col } from 'react-grid-system'
import Card from '../../controls/card'
import TextField from '../../controls/textField'
import TreeSelect from '../../controls/treeselect'
import MultiSelect from '../../controls/multiselect'

import * as actions from './actions'
import * as styles from './styles'

class TeamStructure extends Component {
    
    componentDidMount() {
        this.props.actions.setInputData({prop: 'client_id', data: this.props.state.accounts.selectedAccount})
    }
    getGeneral() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                {/* <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Parent:'}</div>
                        <div style={styles.fieldContent}>
                            <TreeSelect
                                name={'parent'}
                                title={'parent'}
                                data={[{teamname: ''}]}
                                hierarchy={this.props.state.teams}
                                value={this.props.state.parentname}
                                multiple={false}
                                valuefield={'team_id'}
                                displayfield={'teamname'}
                                onSelect={(args) => {
                                    this.props.actions.setInputData({prop: 'parent_id', data: args.team_id})
                                    this.props.actions.setInputData({prop: 'parentname', data: args.teamname})
                                }}
                                onClick={() => {
                                    this.props.actions.getTeamsData()
                                }}
                                onFilter={(args) => //console.log()} 
                            /> 
                        </div>
                    </div>
                </Col> */}
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Client:'}</div>
                        <div style={styles.fieldContent}>
                            <MultiSelect
                                name={'client'}
                                title={window.t.en('Client')}
                                info={{
                                    show: false
                                }}
                                fullWidth={true}
                                fieldvalue={'client_id'}
                                fieldtext={'title'}
                                displayfields={['title', 'name']}
                                displaywidth={['75', '25']}
                                data={this.props.state.clients}
                                onSelect={(args) => {
                                    //console.log('args',args)
                                    //this.props.actions.setInputData({prop: 'client_id', data: args.client_id})
                                    this.props.actions.setInputData({prop: 'teamclients', data: args})
                                }}
                                onClick={() => {
                                    this.props.actions.getClientsData()
                                }
                                }
                                onFilter={(args) => {
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Team:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField
                                variant="outlined"
                                id={'teamname'}
                                hintText={'Enter Team Name'}
                                fullWidth={true}
                                multiLine={true}
                                value={this.props.state.name}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputData({prop: 'name', data: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Description:'}</div>
                        <div style={styles.fieldContent}>
                            
                            <TextField
                                variant="outlined"
                                id={'description'}
                                hintText={'Enter Team Description'}
                                fullWidth={true}
                                multiLine={true}
                                value={this.props.state.description}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputData({prop: 'description', data: event.target.value})
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={6}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Team Is Active:'}</div>
                        <div style={styles.fieldContent}>
                            <Checkbox
                                key={'clientstatus'}
                                labelPosition='right'
                                trackStyle={{
                                    backgroundColor: 'gray'
                                }}
                                trackSwitchedStyle={{
                                    backgroundColor: 'green'
                                }}
                                checked={this.props.state.active}
                                onChange={
                                    (event, isInputChecked) => {
                                        this.props.actions.setInputData({prop: 'active', data: isInputChecked})
                                    }
                                }
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getSettings() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
            </Row>
        </Container>
    }

    getLayout () {
        return <>
        <Card
            title={'General Info'}
            subtitle={''}
            content={this.getGeneral()} />
        </>
    }

    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: 
        { 
            ...state.teamstructure,
            accounts: {
                ...state.accounts
            } 
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamStructure)