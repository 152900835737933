export const purchaseorder = []
export const accountingitem = []
export const selectedPO = []
export const supplier = []
export const selectedCA = []
export const suppliername = ''
export const supplier_id = ''
export const invoicereference = ''
export const authnumber = ''
export const transactiontype = 'Invoice'
export const supplierinvoiceamount = '0.00'
export const amount_excl = '0.00'
export const amount_vat = '0.00'
export const amount_disc = '0.00'
export const amount_incl = '0.00'
export const invoice_evaluation_status= false
export const invoice_rejection_comment= ''
