import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import * as actions from './actions'

class _workflowstepper extends Component {
    componentWillMount() {
      this.props.actions.resetWorkflowTasks()
      if(this.props.workflow_id)
        this.props.actions.getWorkflowTasks(this.props.workflow_id)
    }
    
    render() {
      //console.log('workflow_stepperx >>>>>>>>>>', this.props.state.workflow_stepper)
        return this.props.state.workflow_stepper.length
        ? <Box sx={{ width: '100%' }}>
          <Stepper activeStep={this.props.state.workflow_stepper.findIndex(item => item.name === this.props.name)} alternativeLabel>
            {this.props.state.workflow_stepper.map((x,i) => (
              <Step key={'step_' + i}>
                <StepLabel>{x.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.controls }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(_workflowstepper)