import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, IconButton } from '@mui/material'

import Icon from '../../Icon/component'

import VisibilityIcon from '@mui/icons-material/Visibility';
import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewSupplierTransactions from '../../../controls/view_supplier_transaction'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Form from '../../../controls/form'
import Drawer from '../../../controls/drawer'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'

import Checkbox from '../../../controls/checkbox'
import CardControl from '../../../controls/card'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import * as styles from './styles'
import * as actions from './actions'

import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

class CreditorsPaymentCreate extends Component {

    componentDidMount() {
        this.props.actions.setInputValue('win_view_documents', false)
        this.props.actions.setValidationState('creditorsPaymentCreate')

        if (this.props.mode === "confirm") {
            //console.log('statex confirm',JSON.stringify(this.props.state))
            this.props.actions.genPaymentsDocuments(this.props.state)
        }
        else {
            // this.props.actions.setInputValue('supplier_id', null)
            // this.props.actions.setInputValue('credited_data', [])
            // this.props.actions.setInputValue('penalties_data', [])
            // this.props.actions.setInputValue('supplier_transactions', [])
            //console.log('statex',JSON.stringify(this.props.state))
            this.props.actions.resetForm()
        }
    }

    getDuplicateData() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Auth No</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Odometer</th>
                                    <th>Task No</th>
                                    <th>Booked By</th>
                                    <th>Booked Date</th>
                                    <th>Supplier</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    [{}].map(x => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.authnumber}</td>
                                            <td>{x.maintenancetype}</td>
                                            <td>{x.comments}</td>
                                            <td>{x.odometer}</td>
                                            <td>{x.tasknumber}</td>
                                            <td>{x.bookedby}</td>
                                            <td>{x.bookeddate}</td>
                                            <td>{x.suppliertype}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    invoiceItems() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>

                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Invoice Number</th>
                                    <th>Auth Number</th>
                                    <th>Description</th>
                                    <th>Invoice Date</th>
                                    <th>Invoice Amount</th>
                                    <th>Short Payment</th>
                                    <th>Pay Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.supplier_transactions.filter(x => x.pay_status).map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <th>
                                                <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                                                    //console.log('suppliertransactiondocumentx',x)
                                                    let documents = x.suppliertransactiondocument.map(x => {
                                                        return x.document
                                                    })
                                                    this.props.actions.setInputValue('transactionx', { ...x, documents: documents })
                                                    this.props.actions.setInputValue('win_view_documents', true)
                                                }}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </th>
                                            <td>{x.reference}</td>
                                            <td>{x.authnumber}</td>
                                            <td>{x.description}</td>
                                            <td>{moment(x.suppliertransactiondate).format('DD-MM-YYYY')}</td>
                                            <td>{x.amount}</td>
                                            <td>{x.shortpayment}</td>
                                            <td>{x.allocationamount}</td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={5}></th>
                                    <th>{_.sumBy(this.props.state.supplier_transactions.filter(x => x.pay_status), (d) => { return parseFloat(d.amount ? d.amount : 0) }).toFixed(2)}</th>
                                    <th>{_.sumBy(this.props.state.supplier_transactions.filter(x => x.pay_status), (d) => { return parseFloat(d.shortpayment ? d.shortpayment : 0) }).toFixed(2)}</th>
                                    <th>{_.sumBy(this.props.state.supplier_transactions.filter(x => x.pay_status), (d) => { return parseFloat(d.allocationamount ? d.allocationamount : 0) }).toFixed(2)}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getPenaltiesOther() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <td colSpan={5}>
                                        <Button className={"global_button"}
                                            primary={true}
                                            onClick={() => {
                                                this.props.actions.addPenaltiesBlankRow()
                                            }}
                                            icon={<Icon iclass={'material-icons'} iname={'plus_one'} />}
                                        >{('Add')}</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Date</th>
                                    <th>Reference</th>
                                    <th>Reason</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.penalties_data.map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>
                                                <IconButton key={'delete_forever_' + inx}
                                                    onClick={
                                                        () => {
                                                            this.props.actions.removePenaltiesRow(inx)
                                                        }
                                                    }>
                                                    <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
                                                </IconButton>
                                            </td>
                                            <td>
                                                <DateControl
                                                    id={'cre_date_' + inx}
                                                    key={'cre_date_' + inx}
                                                    label={'Select Transaction Date'}
                                                    name={'date'}
                                                    value={x.date}
                                                    onSelect={(args) => {
                                                        this.props.actions.setPenRowInputValue(inx, 'date', args)
                                                    }}
                                                    type={'date'}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'pen_reference_' + inx}
                                                    label={'Enter Reference'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.reference}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setPenRowInputValue(inx, 'reference', event.target.value)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'pen_reason_' + inx}
                                                    label={'Enter Reason'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.reason}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setPenRowInputValue(inx, 'reason', event.target.value)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'pen_amount_' + inx}
                                                    label={'Enter Amount'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.amount}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setPenRowInputValue(inx, 'amount', event.target.value)
                                                        this.props.actions.calcTotal()
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={4}></th>
                                    <th style={{ textAlign: 'right', paddingLeft: '20px' }}>Total: {_.sumBy(this.props.state.penalties_data, (d) => { return parseFloat(d.amount) })}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getPenaltiesOtherConfirm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Reference</th>
                                    <th>Reason</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.penalties_data.map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.date}</td>
                                            <td>{x.reference}</td>
                                            <td>{x.reason}</td>
                                            <td>{x.amount} </td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={3}></th>
                                    <th style={{ textAlign: 'right', paddingLeft: '20px' }}>Total: {_.sumBy(this.props.state.penalties_data, (d) => { return parseFloat(d.amount) })}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getPaymentsNotCredited() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <td colSpan={5}>
                                        <Button className={"global_button"}
                                            primary={true}
                                            onClick={() => {
                                                this.props.actions.addCreditedBlankRow()
                                            }}
                                            icon={<Icon iclass={'material-icons'} iname={'plus_one'} />}
                                        >{('Add')}</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Date</th>
                                    <th>Reference</th>
                                    <th>Reason</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.credited_data.map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>
                                                <IconButton key={'delete_forever_' + inx}
                                                    onClick={
                                                        () => {
                                                            this.props.actions.removeCreditedRow(inx)
                                                        }
                                                    }>
                                                    <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
                                                </IconButton>
                                            </td>
                                            <td>
                                                <DateControl
                                                    id={'cre_date_' + inx}
                                                    key={'cre_date_' + inx}
                                                    label={'Enter Transaction Date'}
                                                    name={'date'}
                                                    value={x.date}
                                                    onSelect={(args) => {
                                                        this.props.actions.setCreRowInputValue(inx, 'date', args)
                                                    }}
                                                    type={'date'}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'cre_reference_' + inx}
                                                    label={'Enter Reference'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.reference}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setCreRowInputValue(inx, 'reference', event.target.value)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'cre_reason_' + inx}
                                                    label={'Enter Reason'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.reason}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setCreRowInputValue(inx, 'reason', event.target.value)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'cre_amount_' + inx}
                                                    label={'Enter Amount'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.amount}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setCreRowInputValue(inx, 'amount', event.target.value)
                                                        this.props.actions.calcTotal()
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={4}></th>
                                    <th style={{ textAlign: 'right', paddingLeft: '20px' }}>Total: {_.sumBy(this.props.state.credited_data, (d) => { return parseFloat(d.amount) })}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getPaymentsNotCreditedConfirm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Reference</th>
                                    <th>Reason</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.credited_data.map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.date}</td>
                                            <td>{x.reference}</td>
                                            <td>{x.reason}</td>
                                            <td>{x.amount}</td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={3}></th>
                                    <th style={{ textAlign: 'right', paddingLeft: '20px' }}>Total: {_.sumBy(this.props.state.credited_data, (d) => { return parseFloat(d.amount) })}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    paymentSummary() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>

                    <Table className="table table-hover text-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>Statement Amount</th>
                                <th>Allocated Amount</th>
                                {/* <th>Short Payment</th> */}
                                <th>Credited Amount</th>
                                <th>Discount Percentage</th>
                                <th>Discount Amount</th>
                                <th>Payable Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <td>
                                <TextField
                                    id={'statement_amount'}
                                    key={'statement_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Statement Amount'}
                                    variant="outlined"
                                    value={this.props.state.statement_amount}
                                    onChange={(event) => {
                                        this.props.actions.setInputValue('statement_amount', event.target.value)
                                        this.props.actions.calcTotal()
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={'allocated_amount'}
                                    key={'allocated_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Allocated Amount'}
                                    variant="outlined"
                                    value={this.props.state.allocated_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //this.props.actions.setInputValue('statement_amount', event.target.value)
                                    }}
                                />
                            </td>
                            {/* <td>
                                <TextField
                                    id={'short_payment'}
                                    multiLine={false}
                                    width={160}
                                    label={'Short Payment'}
                                    variant="outlined"
                                    value={this.props.state.shortpayment_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                    }}
                                />
                            </td> */}
                            <td>
                                <TextField
                                    key={'credited_amount'}
                                    id={'credited_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Credited Amount'}
                                    variant="outlined"
                                    value={this.props.state.credited_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    key={'discount_percentage'}
                                    id={'discount_percentage'}
                                    multiLine={false}
                                    width={160}
                                    label={'Discount Percentage'}
                                    variant="outlined"
                                    value={this.props.state.discount_percentage}
                                    //disabled={true}
                                    onChange={(event) => {
                                        this.props.actions.setInputValue('discount_percentage', event.target.value)
                                        this.props.actions.calcTotal()
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    key={'discount_amount'}
                                    id={'discount_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Discount Amount'}
                                    variant="outlined"
                                    value={this.props.state.discount_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        this.props.actions.setInputValue('discount_amount', event.target.value)
                                        this.props.actions.calcTotal()
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    key={'payable_amount'}
                                    id={'payable_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Payable Amount'}
                                    variant="outlined"
                                    value={this.props.state.payable_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                    }}
                                />
                            </td>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    }

    paymentSummaryConfirm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <Table className="table table-hover text-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>Statement Amount</th>
                                <th>Allocated Amount</th>
                                <th>Short Payment</th>
                                <th>Discount Percentage</th>
                                <th>Discount Amount</th>
                                <th>Payable Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <td>{this.props.state.statement_amount}</td>
                            <td>{this.props.state.allocated_amount}</td>
                            <td>{this.props.state.shortpayment_amount}</td>
                            <td>{this.props.state.discount_percentage}</td>
                            <td>{this.props.state.discount_amount}</td>
                            <td>{this.props.state.payable_amount}</td>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    }

    getForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Invoice Date:'}</div>
                        <div style={styles.fieldContent}>

                            <DateControl
                                id={'date'}
                                key={'date'}
                                label={'Enter transaction date'}
                                name={'date'}
                                //error={this.props.state.fields.date.value ? false : true}
                                //helperText={this.props.state.fields.date.value ? '' : 'Enter transaction date'}
                                value={this.props.state.date}
                                onSelect={(args) => {
                                    this.props.actions.setInputValue('date', args)
                                }}
                                type={'date'}
                            />
                        </div>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Month:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField variant="outlined"
                                id={'month'}
                                label={'Enter transaction month'}
                                fullWidth={true}
                                multiLine={false}
                                //error={isNaN(this.props.state.fields.month.value) ? true : false}
                                //helperText={isNaN(this.props.state.fields.month.value) ? '' : 'Enter month (YYYYMM)'}
                                value={this.props.state.month}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('month', event.target.value)
                                }}
                            />
                        </div>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Invoice Number:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField variant="outlined"
                                id={'reference'}
                                label={'Enter Invoice Number'}
                                fullWidth={true}
                                multiLine={false}
                                //error={this.props.state.fields.invoice_number.value ? false : true}
                                //helperText={this.props.state.fields.invoice_number.value ? '' : 'Enter invoice number'}
                                value={this.props.state.invoice_number}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('invoice_number', event.target.value)
                                }}
                            />
                        </div>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Invoice Amount:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField variant="outlined"
                                id={'description'}
                                label={'Invoice Amount'}
                                fullWidth={true}
                                multiLine={false}
                                disabled={true}
                                //error={this.props.state.fields.invoice_amount.value ? false : true}
                                //helperText={this.props.state.fields.invoice_amount.value ? '' : 'Enter amount'}
                                value={this.props.state.invoice_amount}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('invoice_amount', event.target.value)
                                }}
                            />
                        </div>
                    </div>

                </Col>
            </Row>
        </Container>
    }

    viewForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <Card title={window.t.en('Invoice Details')}
                        subtitle={window.t.en('Capture supplier details')}
                        content={
                            <div>
                                <Fields labelsize='130'
                                    fields={[
                                        { size: 6, label: 'Supplier:', value: '' },
                                        { size: 6, label: 'Date', value: '' },
                                        { size: 6, label: 'Reference', value: '' },
                                        { size: 6, label: 'Description', value: '' },
                                    ]}
                                /></div>
                        }
                    />
                </Col>
                <Col xl={12}>
                    {/* {
                            this.data.step1.supplier_id && <ViewSupplier
                                min={true}
                                key={this.data.step1.supplier_id} 
                                id={this.data.step1.supplier_id} 
                            />
                        } */}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                </Col>
            </Row>
        </Container>
    }


    getTransactionItems(confirm) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        {
                            confirm
                                ? <Table className="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Invoice Number</th>
                                            <th>Auth Number</th>
                                            <th>Description</th>
                                            <th>Invoice Date</th>
                                            <th>Invoice Amount</th>
                                            {/* <th>Short Payment</th> */}
                                            <th>Pay Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={7}>
                                                <TextField
                                                    id={'short_payment'}
                                                    multiLine={false}
                                                    fullWidth={true}
                                                    label={'Enter Search'}
                                                    variant="outlined"
                                                    value={this.props.state.search}
                                                    onChange={(event) => {
                                                        //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        {
                                            this.props.state.supplier_transactions.filter(x => x.pay_status).map((x, inx) => {
                                                //console.log('supplier_transactionsx', x)
                                                return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                                    <td>
                                                        <b>{x.reference}</b> <br />
                                                        <code style={{ color: 'red', fontWeight: 600 }}>Is Original eNatis Document Received?</code>
                                                    </td>
                                                    <td>{x.authnumber}</td>
                                                    <td>{x.description}</td>
                                                    <td>{moment(x.suppliertransactiondate).format('DD-MM-YYYY')}</td>
                                                    <td>{x.amount}</td>
                                                    {/* <th>{x.shortpayment}</th> */}
                                                    <th> {parseFloat(x.allocationamount ? x.allocationamount : 0).toFixed(2)} </th>
                                                </tr>
                                            })
                                        }
                                        <tr>
                                            <th colSpan={4}></th>
                                            <th >{_.sumBy(this.props.state.supplier_transactions, (d) => { return parseFloat(d.amount ? d.amount : 0) }).toFixed(2)}</th>
                                            <th></th>
                                            {/* <th >{_.sumBy(this.props.state.supplier_transactions, (d) => { return parseFloat(d.shortpayment ? d.shortpayment : 0)}).toFixed(2)}</th> */}
                                            <th >{_.sumBy(this.props.state.supplier_transactions, (d) => { return parseFloat(d.allocationamount ? d.allocationamount : 0) }).toFixed(2)}</th>
                                        </tr>
                                    </tbody>
                                </Table>
                                : <Table className="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Invoice Number ...</th>
                                            <th>Auth Number</th>
                                            <th>Description</th>
                                            <th>Invoice Date</th>
                                            <th>Invoice Amount</th>
                                            {/* <th>Don't Pay</th> */}
                                            <th>Accept / Pay</th>
                                            <th>Apply Discount</th>
                                            <th>Pay Amount</th>
                                            <th>Discount Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={8}>
                                                <TextField
                                                    id={'short_payment'}
                                                    multiLine={false}
                                                    fullWidth={true}
                                                    label={'Enter Search'}
                                                    variant="outlined"
                                                    value={this.props.state.search}
                                                    onChange={(event) => {
                                                        //this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        {
                                            this.props.state.supplier_transactions.map((x, inx) => {
                                                //console.log('supplier_transactionsx', x)
                                                return x.transactiontype === 'Vehicle Purchase' ? <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                                    <th>
                                                        <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                                                            //console.log('suppliertransactiondocumentx',x)
                                                            let documents = x.suppliertransactiondocument.map(x => {
                                                                return x.document
                                                            })
                                                            this.props.actions.setInputValue('transactionx', { ...x, documents: documents })
                                                            this.props.actions.setInputValue('win_view_documents', true)
                                                        }}>
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </th>
                                                    <td>
                                                        <b>{x.reference}</b> <code style={{ color: 'red', fontWeight: 600 }}>Is Original eNatis Document Received?</code> <br />
                                                        <Checkbox
                                                            checked={x.enatis_received}
                                                            onCheck={(checked) => {
                                                                this.props.actions.setListInputValue('enatis_received', checked, inx)

                                                                this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'pay_status', false)
                                                                this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'allocationamount', 0)
                                                                this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', 0)
                                                                this.props.actions.calcTotal()
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{x.authnumber}</td>
                                                    <td>{x.description}</td>
                                                    <td>{moment(x.suppliertransactiondate).format('DD-MM-YYYY')}</td>
                                                    <td>{x.amount}</td>
                                                    <th>
                                                        {
                                                            x.enatis_received ? <Checkbox
                                                                checked={x.pay_status === true}
                                                                onCheck={(checked) => {
                                                                    let discount_percentage = this.props.state.discount_percentage
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'pay_status', checked)
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'allocationamount', checked ? (x.amount - (x.amount * (discount_percentage / 100))) : 0)
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'discountamount', checked ? (x.amount * (discount_percentage / 100)) : 0)
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', 0)
                                                                    this.props.actions.calcTotal()
                                                                }}
                                                            /> : ''
                                                        }
                                                    </th>
                                                    <th>
                                                        {
                                                            x.enatis_received ? <Checkbox
                                                                checked={x.apply_discount === true}
                                                                onCheck={(checked) => {
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'apply_discount', checked)
                                                                    this.props.actions.calcTotal()
                                                                }}
                                                            /> : ''
                                                        }
                                                    </th>
                                                    {/* <th>
                                                <TextField
                                                    id={'short_payment'}
                                                    multiLine={false}
                                                    width={160}
                                                    label={'Enter Short Payment'}
                                                    variant="outlined"
                                                    value={x.shortpayment}
                                                    disabled={!x.pay_status === true}
                                                    onChange={(event) => {
                                                        this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                                        this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'allocationamount', Number(x.amount ? x.amount : 0) - Number(event.target.value ? event.target.value : 0))
                                                        this.props.actions.calcTotal()
                                                    }}
                                                />
                                            </th> */}
                                                    <th>
                                                        {parseFloat(x.pay_status ? x.allocationamount : 0).toFixed(2)}
                                                    </th>
                                                    <th>
                                                        {parseFloat(x.pay_status ? x.discountamount : 0).toFixed(2)}
                                                    </th>
                                                </tr>
                                                    : <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                                        <th>
                                                            <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                                                                //console.log('suppliertransactiondocumentx',x)
                                                                let documents = x.suppliertransactiondocument.map(x => {
                                                                    return x.document
                                                                })
                                                                this.props.actions.setInputValue('transactionx', { ...x, documents: documents })
                                                                this.props.actions.setInputValue('win_view_documents', true)
                                                            }}>
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </th>
                                                        <td>{x.reference} </td>
                                                        <td>{x.authnumber}</td>
                                                        <td>{x.description}</td>
                                                        <td>{moment(x.suppliertransactiondate).format('DD-MM-YYYY')}</td>
                                                        <td>{x.amount}</td>
                                                        <th>
                                                            <Checkbox
                                                                checked={x.pay_status === true}
                                                                onCheck={(checked) => {
                                                                    let discount_percentage = x.apply_discount ? this.props.state.discount_percentage : 0
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'pay_status', checked)
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'allocationamount', checked ? discount_percentage ? (x.amount - (x.amount * (discount_percentage / 100))) : x.amount : 0)
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'discountamount', checked ? discount_percentage ? (x.amount * (discount_percentage / 100)) : 0 : 0)
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', 0)
                                                                    this.props.actions.calcTotal()
                                                                }}
                                                            />
                                                        </th>
                                                        <th>
                                                            <Checkbox
                                                                checked={x.apply_discount === true}
                                                                onCheck={(checked) => {
                                                                    let discount_percentage = checked ? this.props.state.discount_percentage : 0
                                                                    let pay_status = x.pay_status
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'apply_discount', checked)
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'allocationamount', pay_status ? discount_percentage ? (x.amount - (x.amount * (discount_percentage / 100))) : x.amount : 0)
                                                                    this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'discountamount', pay_status ? discount_percentage ? (x.amount * (discount_percentage / 100)) : 0 : 0)
                                                                    this.props.actions.calcTotal()
                                                                }}
                                                            />
                                                        </th>
                                                        {/* <th>
                                                <TextField
                                                    id={'short_payment'}
                                                    multiLine={false}
                                                    width={160}
                                                    label={'Enter Short Payment'}
                                                    variant="outlined"
                                                    value={x.shortpayment}
                                                    disabled={!x.pay_status === true}
                                                    onChange={(event) => {
                                                        this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'shortpayment', event.target.value)
                                                        this.props.actions.setSupplierTransalationInputValue(x.suppliertransaction_id, 'allocationamount', Number(x.amount ? x.amount : 0) - Number(event.target.value ? event.target.value : 0))
                                                        this.props.actions.calcTotal()
                                                    }}
                                                />
                                            </th> */}
                                                        <th>
                                                            {parseFloat(x.allocationamount ? x.allocationamount : 0).toFixed(2)}
                                                        </th>
                                                        <th>
                                                            {parseFloat(x.pay_status ? x.discountamount : 0).toFixed(2)}
                                                        </th>
                                                    </tr>
                                            })
                                        }
                                        <tr>    <th></th>
                                            <th colSpan={4}></th>
                                            <th >{_.sumBy(this.props.state.supplier_transactions, (d) => { return parseFloat(d.amount ? d.amount : 0) }).toFixed(2)}</th>
                                            <th></th>
                                            {/* <th >{_.sumBy(this.props.state.supplier_transactions, (d) => { return parseFloat(d.shortpayment ? d.shortpayment : 0)}).toFixed(2)}</th> */}
                                            <th >{_.sumBy(this.props.state.supplier_transactions, (d) => { return parseFloat(d.allocationamount ? d.allocationamount : 0) }).toFixed(2)}</th>

                                        </tr>
                                    </tbody>
                                </Table>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getView() {
        let size = 7
        return <div>
            <Card
                title={'Capture Creditor Invoice'}
                subtitle={'Creditor capture internal reference ' + this.props.state.preauth}
                content={<Form label={false} fields={[
                    {
                        name: 'select_supplier', title: 'Supplier Selection', section: '', type: 'dll', size: 4,
                        data: this.props.state.supplier_data, value: this.props.state.suppliername,
                        onSelect: (args) => {
                            this.props.actions.setInputValue('supplier_id', args.value)
                            this.props.actions.setInputValue('suppliername', args.text)
                            this.props.actions.getComponentSupplierTransaction(args.value)
                        },
                        onClick: (args) => { this.props.actions.getLookupData('supplier', 'suppliername', '') },
                        onFilter: (search) => {
                            if (!search) {
                                this.props.actions.getLookupData('supplier', 'suppliername', '')
                                this.props.actions.setInputValue('suppliername', '')
                                this.props.actions.setInputValue('supplier_id', null)
                            } else {
                                this.props.actions.getLookupData('supplier', 'suppliername', search)
                                const data = this.props.state.supplier_data;
                                const supplier = data.find(d => d.suppliername === search);
                                if (supplier) {
                                    this.props.actions.setInputValue('suppliername', search)
                                    this.props.actions.setInputValue('supplier_id', supplier?.supplier_id)
                                    this.props.actions.getComponentSupplierTransaction(supplier?.supplier_id)
                                }
                            }
                        }
                    },
                    {
                        name: 'select_business', title: 'Business Account', section: '', type: 'dll', size: 4,
                        data: this.props.state.client_data, value: this.props.state.client,
                        onSelect: (args) => {
                            this.props.actions.setInputValue('client_id', args.value)
                            this.props.actions.setInputValue('client', args.text)
                        },
                        onClick: (args) => { this.props.actions.getLookupData('client', 'title', '') },
                        onFilter: (search) => { 
                            if (!search){
                                this.props.actions.getLookupData('client', 'title', '')
                                this.props.actions.setInputValue('client', '')
                                this.props.actions.setInputValue('client_id', null)
                            } else {
                                this.props.actions.getLookupData('client', 'title', search)
                                const data = this.props.state.supplier_data;
                                const business = data.find(d => d.text === search);
                                if (business) {
                                    this.props.actions.setInputValue('client', search)
                                    this.props.actions.setInputValue('client_id', business.client_id)
                                }
                            }
                            this.props.actions.setInputValue('selected_data', null)
                            this.props.actions.setInputValue('accountingitem_id', null)
                            this.props.actions.setInputValue('itemname', '')
                        }

                    },
                    {
                        name: 'select_bank', title: 'GL Account', section: '', type: 'dll', size: 4,
                        data: this.props.state.accountingitem_data.filter(x => x.client_id == this.props.state.client_id), value: this.props.state.itemname,
                        altRendering: true,
                        onSelect: (args) => {
                            let selected_data = this.props.state.accountingitem_data.filter(x => x.accountingitem_id === args.value)
                            this.props.actions.setInputValue('selected_data', selected_data)
                            this.props.actions.setInputValue('accountingitem_id', args.value)
                            this.props.actions.setInputValue('itemname', args.text)
                        },
                        onClick: (args) => { this.props.actions.getAccountingLookupData('accountingitem', 'itemname', '') },
                        onFilter: (search) => {
                            if (!search) {
                                this.props.actions.getAccountingLookupData('accountingitem', 'itemname', '')
                                this.props.actions.setInputValue('selected_data', [])
                                this.props.actions.setInputValue('accountingitem_id', null)
                                this.props.actions.setInputValue('itemname', '')
                            } else {
                                this.props.actions.getAccountingLookupData('accountingitem', 'itemname', search)
                                const data = this.props.state.accountingitem_data;
                                let selected_data = data.find(x => x.itemname === search)
                                if (selected_data) {
                                    this.props.actions.setInputValue('selected_data', selected_data)
                                    this.props.actions.setInputValue('accountingitem_id', selected_data[0]?.accountingitem_id)
                                    this.props.actions.setInputValue('itemname', selected_data[0]?.itemname)
                                }
                            }
                        }
                    },
                    {
                        name: 'payment_date', label: 'Payment Date', title: 'Payment Date', section: '', type: 'd', size: 4, value: this.props.state.payment_date,
                        onSelect: (arg) => { this.props.actions.setInputValue('payment_date', arg) }
                    },
                    {
                        name: 'statement_date', label: 'Statement Date', title: 'Statement Date', section: '', type: 'd', size: 4, value: this.props.state.statement_date,
                        onSelect: (arg) => { this.props.actions.setInputValue('statement_date', arg) }
                    }
                ]} />}
            />
            {
                this.props.state.supplier_id
                    ? <>
                        <Card
                            title={'Upload Document'}
                            subtitle={'Upload creditor payment supporting document: '}
                            content={
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <Upload
                                                id={'upload_ctl'}
                                                prefix={'Creditor_Attach_'}
                                                header={false}
                                            />
                                        </Col>
                                    </Row></Container>}
                        />
                        <ViewSupplier key={'creditors_payment_supplier_' + this.props.state.supplier_id} id={this.props.state.supplier_id} />
                        <Card
                            title={'Invoice Item'}
                            subtitle={'Capture invoice line items'}
                            content={
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <h5>&nbsp;&nbsp;&nbsp;{'Penalties/Other'}</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            {this.getPenaltiesOther()}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <h5>&nbsp;&nbsp;&nbsp;{'Payments Not Credited'}</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            {this.getPaymentsNotCredited()}
                                        </Col>
                                    </Row>
                                </Container>}
                        />
                        <Card
                            title={'Payment Summary'}
                            subtitle={'Capture invoice line items'}
                            content={this.paymentSummary()}
                        />
                        <Card
                            title={'Supplier Transaction'}
                            subtitle={'Supplier Name'}
                            content={this.getTransactionItems()}
                        />
                    </>
                    : <></>
            }
        </div>
    }

    getConfirm() {

        return <div>
            <ViewSupplier key={this.props.state.supplier_id} id={this.props.state.supplier_id} />
            <Card
                title={'Invoice Item'}
                subtitle={'Capture invoice line items'}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.credited_data.length
                                ? <div>
                                    <Row>
                                        <Col xl={12}>
                                            <h5>&nbsp;&nbsp;&nbsp;{'Penalties/Other'}</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            {this.getPenaltiesOtherConfirm()}
                                        </Col>
                                    </Row>
                                </div>
                                : <div></div>
                        }
                        {
                            this.props.state.credited_data.length
                                ? <div>
                                    <Row>
                                        <Col xl={12}>
                                            <h5>&nbsp;&nbsp;&nbsp;{'Payments Not Credited'}</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            {this.getPaymentsNotCreditedConfirm()}
                                        </Col>
                                    </Row>
                                </div>
                                : <div></div>
                        }
                        <Row>
                            <Col xl={12}>
                                <h5>&nbsp;&nbsp;&nbsp;{'Selected Invoice List'}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.invoiceItems()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <h5>&nbsp;&nbsp;&nbsp;{'Payment Summary'}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.paymentSummaryConfirm()}
                            </Col>
                        </Row>
                    </Container>}
            />
            {/* <Card
            title={'Uploaded Invoice'}
            subtitle={'Skip if already uploaded on purchase order step '}
            content={
                <PdfViewer
                    key={'uploaded_document'}
                    name={'uploaded_document'}
                    documents={this.props.state.upload.documents}
                />}
        /> */}
            <Card
                title={'Account Documents'}
                subtitle={'Skip if already uploaded on purchase order step '}
                content={
                    <PdfViewer
                        key={'payment_document'}
                        name={'payment_document'}
                        documents={this.props.state.upload.documents.concat(this.props.state.payment_document)}
                    />
                }
            />
        </div>
    }

    getLayout() {
        //return this.getView() //getConfirm()
        return <>
            {this.props.mode === "confirm" ? this.getConfirm() : this.getView()}

            <Drawer
                onClose={(event, value) => {
                    this.props.actions.setInputValue('win_view_documents', false)
                }}
                anchor={'right'}
                width={'60%'}
                open={this.props.state.win_view_documents}
                title={'Transaction Documents'}
                subtitle={'For ' + this.props.state.transactionx.authnumber}
                content={
                    <PdfViewer
                        key={'document_viewer_approval'}
                        name={'booking'}
                        workflow={'fml_customer_quote'}
                        task={'tsk_purchase_order_review_and_send'}
                        documents={this.props.state.transactionx.documents ? this.props.state.transactionx.documents : []}
                        multiple={true}
                        edit={false}
                    />
                }
            />
        </>
    }

    getErrorLayout() {
        return <Card
            title={'Error'}
            subtitle={'Connection did not respond  '}
            content={<div>
                <h4>Please check following:</h4>
                <ul>
                    <li>Please ensure that the engine is connected</li>
                    <li>Please ensure that you do not log in twice</li>
                </ul>
                <i>Alternatively, you can contact the system administrator for further assistance.</i>
            </div>}
        />
    }

    render() {

        this.data = null
        try {
        } catch (error) {

        }

        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.creditorsPaymentCreate,
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditorsPaymentCreate)