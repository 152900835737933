export const data = []
export const selected = {}
export const loading = true
export const visible = false
export const mode = ''
export const team_id = 0
export const user_id = 0
export const selected_team_id = 0
export const selected_user_id = 0
export const task = {}
export const comments = ''
export const selectcomments = ''
export const taskLoading = true
export const reject_reason = ''
export const workflow_cancel_reason = [
    { 'Name': 'Duplicate Authorization' },
    { 'Name': 'Incorrect Value' },
    { 'Name': 'Incorrect Supplier Selected' },
    { 'Name': 'Vehicle Not At Supplier' },
    { 'Name': 'Customer Cancelled Purchase Order' },
    { 'Name': 'Re-Assessed' },
    { 'Name': 'Mechanical Check' },
    { 'Name': 'Incorrect Details' },
    { 'Name': 'Other' }
]

export const rejectDialog = false