export const TOGGLE_TEAM_FORM = 'TOGGLE_TEAM_FORM'
export const TOGGLE_TEAM_ACTIVATION = 'TOGGLE_TEAM_ACTIVATION'
export const TOGGLE_ASSIGNMENT_FORM = 'TOGGLE_ASSIGNMENT_FORM'

export const CLEAR_TEAM_DATA = 'CLEAR_TEAM_DATA'
export const LOAD_SELECTED_TEAM = 'LOAD_SELECTED_TEAM'
export const SAVE_TEAM_DATA = 'SAVE_TEAM_DATA'
export const GET_ALL_TEAMS = 'GET_ALL_TEAMS'
export const SET_ALL_TEAMS = 'SET_ALL_TEAMS'
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS'
export const SET_ALL_EVENTS = 'SET_ALL_EVENTS'
export const CHANGE_SELECTED_TEAM = 'CHANGE_SELECTED_TEAM'
export const SAVE_TEAM_MANAGEMENT_CHANGES = 'SAVE_TEAM_MANAGEMENT_CHANGES'
export const SAVED_TEAM_MANAGEMENT_CHANGES = 'SAVED_TEAM_MANAGEMENT_CHANGES'
export const SAVE_TEAM_ASSIGNMENT_CHANGES = 'SAVE_TEAM_ASSIGNMENT_CHANGES'
export const SAVED_TEAM_ASSIGNMENT_CHANGES = 'SAVED_TEAM_ASSIGNMENT_CHANGES'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const GET_TEAM_USERS = 'GET_TEAM_USERS'
export const SET_TEAM_USERS = 'SET_TEAM_USERS'
export const ASSIGN_USER_TO_TEAM = 'ASSIGN_USER_TO_TEAM'
export const UNASSIGN_USER_FROM_TEAM = 'UNASSIGN_USER_FROM_TEAM'
export const CHANGE_SELECTED_USER = 'CHANGE_SELECTED_USER'

export const GET_ALL_COMPONENTS = 'GET_ALL_COMPONENTS'
export const SET_ALL_COMPONENTS = 'SET_ALL_COMPONENTS'
export const GET_TEAM_COMPONENTS = 'GET_TEAM_COMPONENTS'
export const SET_TEAM_COMPONENTS = 'SET_TEAM_COMPONENTS'
export const ASSIGN_COMPONENT_TO_TEAM = 'ASSIGN_COMPONENT_TO_TEAM'
export const UNASSIGN_COMPONENT_FROM_TEAM = 'UNASSIGN_COMPONENT_FROM_TEAM'
export const CHANGE_SELECTED_COMPONENT = 'CHANGE_SELECTED_COMPONENT'


export const GET_TEAM_REPORTS = 'GET_TEAM_REPORTS'
export const SET_TEAM_REPORTS = 'SET_TEAM_REPORTS'
export const GET_ALL_REPORTS = 'GET_ALL_REPORTS'
export const SET_ALL_REPORTS = 'SET_ALL_REPORTS'
export const CHANGE_SELECTED_REPORT = 'CHANGE_SELECTED_REPORT'
export const ASSIGN_REPORT_TO_TEAM = 'ASSIGN_REPORT_TO_TEAM'
export const UNASSIGN_REPORT_FROM_TEAM = 'UNASSIGN_REPORT_FROM_TEAM'
export const ASSIGN_USER_TO_CUSTOMERS = 'ASSIGN_USER_TO_CUSTOMERS'
export const UNASSIGN_USER_TO_CUSTOMERS = 'UNASSIGN_USER_TO_CUSTOMERS'

export const SET_USER_TO_LINK = 'SET_USER_TO_LINK'
export const SET_CUSTOMER_TO_LINK = 'SET_CUSTOMER_TO_LINK'
export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS'
export const SET_ALL_CUSTOMERS = 'SET_ALL_CUSTOMERS'
export const GET_ALL_ACCOUNTS = 'GET_ALL_ACCOUNTS'
export const SET_ALL_ACCOUNTS = 'SET_ALL_ACCOUNTS'
export const GET_TEAM_ACCOUNTS = 'GET_TEAM_ACCOUNTS'
export const SET_TEAM_ACCOUNTS = 'SET_TEAM_ACCOUNTS'
export const SET_ALL_USER_CUSTOMERS = 'SET_ALL_USER_CUSTOMERS'
export const GET_ALL_USER_CUSTOMERS = 'GET_ALL_USER_CUSTOMERS'
export const ASSIGN_ACCOUNT_TO_TEAM = 'ASSIGN_ACCOUNT_TO_TEAM'
export const UNASSIGN_ACCOUNT_FROM_TEAM = 'UNASSIGN_ACCOUNT_FROM_TEAM'
export const CHANGE_SELECTED_ACCOUNT = 'CHANGE_SELECTED_ACCOUNT'

export const SET_TEAM_CLIENT_BUSINESSES = 'SET_TEAM_CLIENT_BUSINESSES'
export const SET_TEAM_CLIENT_BUSINESS_CUSTOMERS = 'SET_TEAM_CLIENT_BUSINESS_CUSTOMERS'

export const TOGGLE_BUSINESS_MODE = 'TOGGLE_BUSINESS_MODE'

export const GET_ACCOUNT_BUSINESSES = 'GET_ACCOUNT_BUSINESSES'
export const SET_ACCOUNT_BUSINESSES = 'SET_ACCOUNT_BUSINESSES'
export const CHANGE_SELECTED_BUSINESS = 'CHANGE_SELECTED_BUSINESS'

export const GET_TEAM_ACCOUNT_BUSINESSES = 'GET_TEAM_ACCOUNT_BUSINESSES'
export const SET_TEAM_ACCOUNT_BUSINESSES = 'SET_TEAM_ACCOUNT_BUSINESSES'

export const ASSIGN_ACCOUNT_BUSINESS_TO_TEAM = 'ASSIGN_ACCOUNT_BUSINESS_TO_TEAM'
export const UNASSIGN_ACCOUNT_BUSINESS_FROM_TEAM = 'UNASSIGN_ACCOUNT_BUSINESS_FROM_TEAM'

export const GET_ACCOUNT_BUSINESS_CUSTOMERS = 'GET_ACCOUNT_BUSINESS_CUSTOMERS'
export const SET_ACCOUNT_BUSINESS_CUSTOMERS = 'SET_ACCOUNT_BUSINESS_CUSTOMERS'

export const GET_TEAM_ACCOUNT_BUSINESS_CUSTOMERS = 'GET_TEAM_ACCOUNT_BUSINESS_CUSTOMERS'
export const SET_TEAM_ACCOUNT_BUSINESS_CUSTOMERS = 'SET_TEAM_ACCOUNT_BUSINESS_CUSTOMERS'

export const ASSIGN_ACCOUNT_BUSINESS_CUSTOMER_TO_TEAM = 'ASSIGN_ACCOUNT_BUSINESS_CUSTOMER_TO_TEAM'
export const UNASSIGN_ACCOUNT_BUSINESS_CUSTOMER_FROM_TEAM = 'UNASSIGN_ACCOUNT_BUSINESS_CUSTOMER_FROM_TEAM'

export const CHANGE_SELECTED_CUSTOMER = 'CHANGE_SELECTED_CUSTOMER'
export const GET_WORKQUEUE_USER_AND_TEAM_LINK = 'GET_WORKQUEUE_USER_AND_TEAM_LINK'
export const SET_WORKQUEUE_USER_AND_TEAM_LINK = 'SET_WORKQUEUE_USER_AND_TEAM_LINK'

export const ADD_NEW_TEAM_STRUCTURE = 'ADD_NEW_TEAM_STRUCTURE'
export const SET_WORKQUEUE_LINKED_TEAM = 'SET_WORKQUEUE_LINKED_TEAM'
export const SET_WORKQUEUE_LINKED_USER = 'SET_WORKQUEUE_LINKED_USER'
export const SAVED_TEAM_AND_USER_CHANGES = 'SAVED_TEAM_AND_USER_CHANGES'
export const SET_WORKQUEUE_SELECTED_WORKQUEUE_ID = 'SET_WORKQUEUE_SELECTED_WORKQUEUE_ID'

export const SET_SELECTED_TEAM_OPTION_STATE = 'SET_SELECTED_TEAM_OPTION_STATE'