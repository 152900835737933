import * as types from './types'

export const getMMCodeCPK = (mmcode) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MMCODE_CPK_BY_MMCODE,
            payload: mmcode
        })
    }
}

export const setMMCodeCPK = (response) => {
    return (dispatch, getState) => {
        let payload = {
            tyrecpk: response.data[0].tyrescpk,
            maintcpk: response.data[0].maintcpk,
            mmcode: response.data[0].mmcode
        }
        //console.log('################################### payload ', payload)
        dispatch({
            type: types.SET_MMCODE_CPK_BY_MMCODE,
            payload: payload
        })
    }
}
export const getCurrentInterestRate = (confirm_data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CURRENT_INTEREST_RATE,
            payload: confirm_data
        })
    }
}
export const setCurrentInterestRate = (payload, confirm_data) => {
    return (dispatch, getState) => {
        let deviation = confirm_data.data.data[0].data[0].context_data.data.data.data[0].data.data.data.data.data.confirm.vehicle.deviation
        let primerate = Number(payload.data[0].primerate) + Number(deviation)
        dispatch({
            type: types.SET_CURRENT_INTEREST_RATE,
            payload: primerate
        })
    }
}
export const loadPOListDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PROPS_ACCESSORY_PO_DOCUMENTS_LIST,
            payload: documents
        })
    }
}

export const loadQuoteListDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PROPS_QUOTE_DOCUMENTS_LIST,
            payload: documents
        })
    }
}

export const loadSchedule1ListDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PROPS_SCHEDULE1_DOCUMENTS_LIST,
            payload: documents
        })
    }
}


export const setAccessoriesList = (list) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACCESSORY_LIST_PROP,
            payload: list
        })
    }
}
export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PROPS_COMPONENT_FIELD_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setAccessoryValue = (parent, inx, value) => {
    return (dispatch, getState) => {
        const data = getState().workflowPOContractActivationChecklist.accessories
        const accessory = data[parent].accessory
        accessory[inx].totalprice = value
        data[parent].accessory = accessory
        dispatch({
            type: types.SET_ACCESSORY_LIST_PROP,
            payload: data
        })
    }
}
export const getLookupValue = (component, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PROPS_COMPONENT_LOOKUP_FIELD_VALUE,
            payload: {
                component: component.name,
                search: search,
                query: component.name + '=' + search
            }
        })
    }
}

export const setLookupData = (component, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PROPS_COMPONENT_LOOKUP_FIELD_VALUE,
            payload: {
                prop: component + '_list',
                data: payload.data,
            }
        })
    }
}

export const setLookupValue = (prop, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PROPS_COMPONENT_FIELD_VALUE,
            payload: {
                prop: prop,
                value: value,
                text: text
            }
        })
    }
}

export const loadPOReceipt = (documents) => {
    return (dispatch, getState) => {
        documents.forEach(x => {
            if (x.filename) {
                dispatch({
                    type: types.GET_ACCESSORY_PO_PREVIEW_DOCUMENTS,
                    payload: {
                        type: 'GET_ACCESSORY_PO_PREVIEW_DOCUMENTS',
                        key: 'quotes/' + x.filename
                    }
                })
            }
        })
    }
}

export const loadPODocuments = (documents) => {
    return (dispatch, getState) => {
        documents.forEach(x => {
            if (x.po_filename) {
                dispatch({
                    type: types.GET_ACCESSORY_PO_PREVIEW_DOCUMENTS,
                    payload: {
                        type: 'GET_ACCESSORY_PO_PREVIEW_DOCUMENTS',
                        key: 'quotes/' + x.po_filename
                    }
                })
            }
        })
    }
}

export const loadQuoteDocument = (documents) => {
    return (dispatch, getState) => {
        documents.forEach(x => {
            if (x.filename) {
                dispatch({
                    type: types.GET_ACCESSORY_QUOTE_PREVIEW_DOCUMENTS,
                    payload: {
                        type: 'GET_ACCESSORY_QUOTE_PREVIEW_DOCUMENTS',
                        key: 'quotes/' + x.filename
                    }
                })
            }
        })
    }
}

export const loadSchedule1Document = (documents) => {
    return (dispatch, getState) => {
        documents.forEach(x => {
            if (x.filename) {
                dispatch({
                    type: types.GET_ACCESSORY_SCHEDULE1_PREVIEW_DOCUMENTS,
                    payload: {
                        type: 'GET_ACCESSORY_SCHEDULE1_PREVIEW_DOCUMENTS',
                        key: 'quotes/' + x.filename
                    }
                })
            }
        })
    }
}

export const viewDocument = (data) => {
    let link = ''

    const filekey = data.data.document.key.replace('quotes/', '')
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        const pos = getState().workflowPOContractActivation._pos
        const payload = pos.map(x => {
            return filekey.indexOf('Receipt') < 0
                ? x.filename === filekey
                    ? {
                        ...x,
                        preview: link
                    }
                    : x
                : x.po_filename === filekey
                    ? {
                        ...x,
                        po: link
                    }
                    : x
        })

        dispatch({
            type: types.SET_PROPS_ACCESSORY_PO_DOCUMENTS_PREVIEW,
            payload: payload
        })
    }
}

export const setQuoteDocumentPreview = (data) => {
    let link = ''
    const filekey = data.data.document.key.replace('quotes/', '')
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        const quote = getState().workflowPOContractActivation._quote

        const payload = quote.map(x => {
            return x.filename === filekey
                    ? {
                        ...x,
                        preview: link
                    }
                    : x
        })

        dispatch({
            type: types.SET_PROPS_QUOTE_DOCUMENTS_PREVIEW,
            payload: payload
        })
    }
}
export const setSchedule1DocumentPreview = (data) => {
    let link = ''
    const filekey = data.data.document.key.replace('quotes/', '')
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        const schedule1 = getState().workflowPOContractActivation._schedule1
        const payload = schedule1.map(x => {
            return x.filename === filekey
                    ? {
                        ...x,
                        preview: link
                    }
                    : x
        })

        dispatch({
            type: types.SET_PROPS_SCHEDULE1_DOCUMENTS_LIST,
            payload: payload
        })
    }
}