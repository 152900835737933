import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"
import "react-table/react-table.css"

import Icon from '../Icon/component'
import Theme from '../../theme'

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import AutoComplete from '../../controls/autocomplete'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'

import * as actions from './actions'
import * as readComponentActions from '../ReadComponent/actions'
import * as styles from './styles'
import * as redirectActions from '../Redirect/actions'

import { Card, CardHeader, CardContent } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';

import * as masterComponentViewActions from '../../views/MasterComponentView/actions'



class WorkflowReconInvoice extends Component {
    componentDidMount() {
        this.props.actions.getQuoteTypesComponents(this.props.state.quotetypecomponent)
        //this.props.actions.getOptions('mmcodeoption', 'mmcode', 99, 1)
    }

    getCommands(component, row) {
        const id = row.original[component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_remove_' + component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.removeSingleRow(component, row)
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
            </IconButton>
        )
        return commands
    }

    handleRequestDelete() {
        alert('You clicked the delete button.');
    }



    handleOnRoadFee() {
        this.props.actions.addOnRoadFee(this.props.state.component)
    }

    renderEditable(field, value, row) {
        this.props.actions.loadQuoteGridItems(this.props.state.component, value, row)
    }

    getComponentList() {
        return this.props.state.itemtype.map(x => {
            return <MenuItem value={x.value} key={x.text} primaryText={window.t.en(x.title)} />
        })
    }

    selectionRenderer = (values) => {
        switch (values.length) {
            case 0:
                return '';
            case 1:
                return this.props.state.itemlist[values[0]].text;
            default:
                return `${values.length} names selected`;
        }
    }

    getItemList() {
        return this.props.state.itemlist.map(x => {
            return <MenuItem
                key={x.value}
                insetChildren={true}
                checked={this.props.state.itemlist.indexOf(x.value) > -1}
                value={x.value}
                primaryText={x.text}
            />
        });
    }

    getValue(component, row, read_only) {
        const field = component.component_field.filter(x => x.name === row.column.meta)[0]

        if (read_only || (row.index !== component.grid.data.length - 1)) {
            switch (field.name) {
                case 'component_id':
                case 'item_id':
                    return <TextField
                        key={component.data[field.name].key + '_' + row.index}
                        id={field.name + '_' + row.index}
                        value={row.value}
                        style={styles.textBox}
                        disabled={true}
                    />
                default:
                    return <TextField
                        key={component.data[field.name].key + '_' + row.index}
                        id={field.name + '_' + row.index}
                        hintText={window.t.en(field.title)}
                        inputStyle={styles.textBoxInput}
                        value={row.value}
                        style={styles.textBox}
                        disabled={true}
                    />
            }
        }
        else {
            switch (field.name) {
                case 'quantity':
                    return <TextField
                        key={component.data[field.name].key + '_' + row.index}
                        value={component.grid.data[row.index].quantity}
                        hintText={window.t.en(field.title)}
                        inputStyle={styles.textBoxInput}
                        style={styles.textBox}
                        onChange={
                            (event, value) => {
                                this.props.actions.setFieldValue(field, value, row)
                            }
                        }
                    />
                case 'sellingprice':
                    return <TextField
                        key={component.data[field.name].key + '_' + row.index}
                        id={field.name + '_' + row.index}
                        hintText={window.t.en(field.title)}
                        value={component.grid.data[row.index].sellingprice}
                        inputStyle={styles.textBoxInput}
                        style={styles.textBox}
                        onChange={
                            (event, value) => {
                                this.props.actions.setFieldValue(field, value, row)
                            }
                        }
                    />
                case 'costprice':
                    return <TextField
                        key={component.data[field.name].key + '_' + row.index}
                        id={field.name + '_' + row.index}
                        value={component.grid.data[row.index].costprice}
                        inputStyle={styles.textBoxInput}
                        hintText={window.t.en(field.title)}
                        style={styles.textBox}
                        disabled={true}
                    />
                case 'component_id':
                        window.glyco.log('component_id')
                        window.glyco.log(component.data[field.name].text)
                    return component.grid.data[row.index].itemtype
                        ? <TextField
                            key={component.data[field.name].key + '_' + row.index}
                            id={field.name + '_' + row.index}
                            value={component.grid.data[row.index].itemtype}
                            hintText={field.name}
                            style={styles.textBox}
                            onChange={
                                (event, value) => {
                                    this.props.actions.setQuoteTypesComponents(component, '')
                                }
                            }
                        />
                        : <Select
                            style={styles.inputWidth}
                            key={component.data[field.name].key + '_ddl_' + row.index}
                            id={field.name + '_' + row.index}
                            value={this.props.state.surrogateitems.data[field.name].text}
                            onChange={(event, index, value) => {
                                this.props.actions.setQuoteTypesComponents(component, event.target.value)
                            }}>
                            {
                                this.props.state.component.data.component_id.data.sort((x, y) => x.title - y.title).map(x => {
                                    return <MenuItem key={'dropdown_component_types_' + x.value} value={x.value} primaryText={window.t.en(x.title)}>{x.title}</MenuItem>
                                })
                            }
                        </Select>
                case 'item_id':
                    return component.grid.data[row.index].itemname
                        ? <TextField
                            key={component.data[field.name].key + '_' + row.index}
                            id={field.name + '_' + row.index}
                            value={row.value}
                            hintText={field.name}
                            style={styles.textBox}
                            disabled={true}
                        />
                        : <AutoComplete
                            hintText={'Select Item'}
                            listStyle={{ maxHeight: 200, overflow: 'auto' }}
                            popoverProps={{ style: { overflow: 'auto', width: 'auto' } }}
                            onNewRequest={
                                (chosenRequest, index) => {
                                    this.props.actions.setQuoteComponentItems(component, chosenRequest.value, chosenRequest.text)
                                    //this.props.actions.loadQuoteGridItems(component)
                                }
                            }
                            onUpdateInput={
                                (searchText, dataSource, params) => {
                                    this.props.actions.getQuoteComponentItems(component, searchText)
                                }
                            }
                            onClick={
                                (event) => {
                                    this.props.actions.getQuoteComponentItems(component, '')
                                }
                            }
                            filter={AutoComplete.fuzzyFilter}
                            fullWidth={true}
                            openOnFocus={true}
                            dataSource={this.props.state.component.data.item_id.data}
                            dataSourceConfig={{
                                text: 'text',
                                value: 'value'
                            }}
                        />
                default:
                    return <TextField
                        disabled={true}
                        hintText={window.t.en(field.title)}
                        inputStyle={styles.textBoxInput}
                        value={row.value}
                        style={styles.textBox}
                    />
            }
        }
    }

    getColumns(component) {
        const fields = component.component_field
            .filter(x => x.grid)
            .filter(x => !x.read_only)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: (x.related) ? x.display : x.name,
                    filterable: false,
                    Filter: ({ filter, onChange }) => (
                        <input
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                height: 25,
                                border: '1px solid #E6E6E6',
                                borderRadius: 25
                            }}
                        />
                    ),
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                        //backgroundColor: x.highlight ? styles.highlight : '',
                    },
                    style: {
                        verticalAlign: 'middle',
                        //backgroundColor: x.highlight ? styles.highlight : '',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black', //'#4A4A4A',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(component, row, x.read_only)
                    ),
                    Footer: (
                        this.getFooter(component, x.title)
                    )
                }
            })

        if (!this.props.task) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 50,
                Cell: (row) => (
                    this.getCommands(component, row)
                )
            })
        }
        return columns
    }


    getFooter(component, field) {
        const tot = _.sumBy(component.grid.data, (d) => { return parseFloat((d.totalprice) ? d.totalprice.toString().replace(/,/gi, '') : 0) })
        return (field == 'Total Price')
            ?
            <span>
                <strong>{'Running Total: '}</strong>
                {parseFloat(tot.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
            :
            <span>
                <strong></strong>{" "}
            </span>
    }

    getGrid(component) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                   < Button className={"global_button"} variant="contained"
                        label={'Add Item'}
                        primary={true}
                        disabled={
                            (component.grid.data.length)
                                ?
                                (component.grid.data[component.grid.data.length - 1].itemname) ? false : true
                                :
                                false
                        }
                        onClick={(event) => {
                            this.props.actions.addBlankRow(component)
                        }}
                        >{window.t.en('Add Item')}</Button>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <ReactTable
                        key={'grid_' + component.name + '_' + component.id}
                        data={component.grid.data}
                        columns={this.getColumns(component)}
                        filterable={false}
                        loading={component.grid.loading}
                        manual
                        onFetchData={
                            (state) => {
                                this.props.actions.getComponentGridData(component.name, this.props.link, state, this.props.filter, this.props.id)
                            }
                        }
                        getTrProps={(state, rowInfo) => {
                            return {
                                onClick: (event) => {
                                    this.props.actions.selectRow(component, rowInfo)

                                    if (this.props.toggle) {
                                        this.props.actions.toggleBottomSheet()
                                    }
                                },
                                style: {
                                    background: component.grid.selected
                                        ? component.grid.selected.filter(x => x.index === rowInfo.index)[0] ? Theme.palette.primary1Color : 'white'
                                        : 'white',
                                    color: component.grid.selected
                                        ? component.grid.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                                        : 'black'
                                }
                            }
                        }}
                        minRows={1}
                        showPagination={false}
                        showPaginationBottom={false}
                        style={{
                            height: '100%'
                        }}
                    >
                        {(state, render, instance) => {
                            return (
                                render()
                            )
                        }}
                    </ReactTable>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {
                        component.grid.data.length > 8
                            ?< Button className={"global_button"} variant="contained"
                                id={'btn_add_bt'}
                                label={'Add Item'}
                                primary={true}
                                disabled={
                                    (component.grid.data.length)
                                        ?
                                        (component.grid.data[component.grid.data.length - 1].itemname) ? false : true
                                        :
                                        false
                                }
                                onClick={(event) => {
                                    this.props.actions.addBlankRow(component)
                                }}
                                >{window.t.en('Add Item')}</Button>
                            : ''
                    }
                </Col>
            </Row>
        </Container>
    }

    getVehicleOptions(component) {
        return this.props.state.mmcodeoption.grid.data
            .filter(x => parseInt(x.costprice) === 0)
            .map(x => {
                return <Chip onClick={this.handleClick} style={styles.chip}>
                    <Avatar size={32}>S</Avatar>
                    {x.mmcodeoptionname}
                </Chip>
            })
    }

    render() {
        return <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('Features')}
                subtitle={window.t.en('Vehicle Standard Features and Fitments')}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {
                                this.props.state.chip_accessory.map(x => {
                                    return <Chip
                                        id={x.item_id}
                                        key={x.item_id}
                                        onClick={(event) => {
                                            this.props.actions.addSundryRow(this.props.state.component, x)
                                        }}
                                        style={styles.chip}>
                                        <Avatar size={32}>{'Ac'}</Avatar>
                                        {x.itemname}
                                    </Chip>
                                })
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {
                                this.props.state.chip_service.map(x => {
                                    return <Chip
                                        id={x.item_id}
                                        key={x.item_id}
                                        onClick={(event) => {
                                            this.props.actions.addSundryRow(this.props.state.component, x)
                                        }}
                                        style={styles.chip}>
                                        <Avatar size={32}>{'Se'}</Avatar>
                                        {x.itemname}
                                    </Chip>
                                })
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {
                                this.props.state.chip_sundry.map(x => {
                                    return <Chip
                                        id={x.item_id}
                                        key={x.item_id}
                                        onClick={(event) => {
                                            this.props.actions.addSundryRow(this.props.state.component, x)
                                        }}
                                        style={styles.chip}>
                                        <Avatar size={32}>{'Su'}</Avatar>
                                        {x.itemname}
                                    </Chip>
                                })
                            }
                        </Col>
                    </Row>
                </Container>
            </CardContent>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('Accessories')}
                subtitle={window.t.en('Add Vehicle Accessories, Sundry and Services ')}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">

                    <Row>
                        <Col xl={12}>
                            {this.getGrid(this.props.state.component)}
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowQuoteHeader,
            component: {
                ...state.components[ownProps.name]
            },
            surrogateitems: {
                ...state.components['surrogateitems']
            },
            mmcodeoption: {
                ...state.components['mmcodeoption']
            },
            quotetypecomponent: {
                ...state.components['quotetypecomponent']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...redirectActions, ...readComponentActions, ...masterComponentViewActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowReconInvoice)