export const event = ''
export const workflow = {}
export const workflow_task = {}
export const data = []
export const queue = {}
export const teams = []
export const search = false
export const loading = false
export const tab = 0
export const collapse = -1
export const value = ''
export const results = []
export const selectedtype = 1
export const tasktype = [
    {
        value: 1,
        text: 'Rebill',
    },
    {
        value: 2,
        text: 'Purchase Order',
    },
    {
        value: 3,
        text: 'FML Quote',
    },
    {
        value: 4,
        text: 'STR Quote',
    }]
export const task = {}
export const view_workflow = ''

export const filter_date = null
export const active_team = null

export const searchOpen = false



export const openOptions = false
export const openOptionsEl = null
export const openSearch = false
export const openSearchEl = null
export const openSettings = false
export const openSettingsEl = null
