import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../../Icon/component'




import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Upload from '../../Upload/component'
// import TextField from '@mui/material/TextField'

import TextField from '../../../controls/textField'
import * as actions from './actions'
import * as styles from './styles'

import IconButton from '@mui/material/IconButton';
import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'
import ImageUploader from '../../ImageUploader/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'

class DefleetDisposalApprovalCEO extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }
    VehicleDetails(data) {
        return <Card>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('Vehicle Information: ' + data.businessname)}
                subtitle={window.t.en('Selected vehicle details.')}
                icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                style={styles.row}
            >
            </CardHeader>
            <CardContent>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.licenseplateno}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Fleet Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fleetno}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Status:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vehiclestatus}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Odometer:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.closingodometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.make}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mgdescription}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mmcode}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle First Registration Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registrationdate ? moment(data.registrationdate).format('DD-MM-YYYY') : data.registrationdate}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Chassis Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.chassisnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Engine Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.enginenumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Fuel Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fueltype}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Colour:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.color}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Funder:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fundername ? data.fundername : 'Not Set'}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Supplier (OEM):'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliername ? data.suppliername: 'Not Set'}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }
    ServiceDealer(data) {
        return <div>
            <Card initiallyExpanded={false}>
                <CardHeader
                    actAsExpander={true}
                    showExpandableButton={true}
                    title={window.t.en('Dealer Information: ')}
                    subtitle={window.t.en('Vehicle service dealer details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'contract_vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent  expandable={true}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            data.map(x => {
                                return <div>
                                    <Row>
                                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.suppliername}</Col>
                                        <Col xl={3} style={styles.fieldLabel}>{'BEE Level:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beelevel}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Registration:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.registrationnumber}</Col>
                                        <Col xl={3} style={styles.fieldLabel}>{'BBE Score:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beescore}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={3} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.vatnumber}</Col>
                                        <Col xl={3} style={styles.fieldLabel}>{'BBE Type Number:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beetype}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={3} style={styles.fieldLabel}>{'BBE Certificate Received:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beecertificatereceived ? x.beecertificatereceived : 'Not Set'}</Col>
                                        <Col xl={3} style={styles.fieldLabel}>{'BEE Certificate Expry Date:'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{x.beecertificateexpdate ? x.beecertificateexpdate.split('T')[0] : 'Not Set'}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}><br /></Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <AdhocComponentFieldInput
                                                title={window.t.en('')}
                                                description={''}
                                                icon={''}
                                                id={'suppliercontact'}
                                                parent={'suppliercontact'}
                                                parentFilters={[
                                                    {
                                                        field: 'supplier_id',
                                                        value: x.supplier_id
                                                    }
                                                ]}
                                                child={'contact'}
                                                childFilters={[
                                                    {
                                                        field: 'contacttype',
                                                        value: 'Accounts2'
                                                    }
                                                ]}
                                                extractChildData={true}
                                                adhocFieldInput={[ //assumes Parent component fields if extractChild is set to false
                                                    'fullname', 'email', 'mobile', 'contacttype'
                                                ]}
                                                selectMode={false}
                                                addMode={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>{''}</Col>
                                    </Row>
                                </div>
                            })
                        }
                    </Container>
                </CardContent>
            </Card>
        </div>
    }
    defleetDocuments(documents) {
        return <Card initiallyExpanded={false}>
            <CardHeader
                actAsExpander={true}
                showExpandableButton={true}
                title={window.t.en('De-fleet Disposal Documents')}
                subtitle={window.t.en('De-fleet Disposal pack')}
                icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                style={styles.row}
            >
            </CardHeader>
            <CardContent  expandable={true}> 
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <PdfViewer
                                key={'quotation_viewer_'}
                                documents={documents}
                                height={600}
                                multiple={false}
                                edit={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col><br /></Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }
    techComments(data) {
        return data
            ? <div>
                <Card initiallyExpanded={false}>
                    <CardHeader
                        actAsExpander={true}
                        showExpandableButton={true}
                        title={window.t.en('Technical Manager Comments')}
                        subtitle={window.t.en('Techinical manage approval details. ')}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent  expandable={true}>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
                                <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }
    uploadDocuments(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Upload Documents')}
                    subtitle={window.t.en('Upload Documents.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'Disposal_documents_' + data.licenseplateno + '_'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    gmComments(data) {
        return data
            ? <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('General Manager Comments')}
                        subtitle={window.t.en('General manager approval details. ')}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
                                <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }
    ceoComments(data) {
        return data
            ? <div>
                <Card>
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('CEO Comments')}
                        subtitle={window.t.en('ceo approval details. ')}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
                                <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
            </div>
            : ''
    }
    disposalDetails(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Disposal Details')}
                    subtitle={window.t.en('Disposal Decision Details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Funder:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Funder Name'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.funder}
                                            onChange={(event, value) => {
                                               // this.props.actions.setValue('funder', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Funder Contract End:'}</div>
                                    <div style={styles.fieldContent}>
                                    <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Funder Name'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.contractenddate}
                                            onChange={(event, value) => {
                                               // this.props.actions.setValue('funder', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Funder RV:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.funder_rv}
                                            onChange={(event, value) => {
                                                //this.props.actions.setValue('funder_rv', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Funder Months TO-GO:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Months'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.funder_month_togo}
                                            onChange={(event, value) => {
                                               //this.props.actions.setValue('funder_month_togo', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Trade Value:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.trade_value}
                                            onChange={(event, value) => {
                                                
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Retail Value:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.retail_value}
                                            onChange={(event, value) => {
                                                
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>

                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><b>{'Market Value:'}</b></div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.market_value}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }
    getMarketValue(t, r) {
        let tr = parseFloat(t)
        let re = parseFloat(r)
        let val = (tr ? tr : re) ? (tr ? tr : re) : 0.00
        if (tr & re) {
            val = (tr + re) / 2
        }
        return parseFloat(val)
    }
    offerDetails(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Offer Details')}
                    subtitle={window.t.en('Disposal Offer Details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                        <Row><Col xl={12}><br /></Col>
                        </Row>
                        <Row style={styles.tableheader}>
                            <Col xl={1}>{''}</Col>
                            <Col xl={5}>{'Description'}</Col>
                            <Col xl={2}>{'Amount'}</Col>
                            <Col xl={2}>{'% of Trade'}</Col>
                            <Col xl={2}>{'% Achieved of Market'}</Col>
                        </Row>
                        {
                            data.offers
                                ? data.offers
                                    .map((x, i) => {
                                        return <div>
                                            <Row style={styles.tablerow}>
                                                <Col xl={1} style={styles.verticalMiddle}>
                                                    {/* <IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                                        onClick={
                                                            () => {
                                                                this.props.actions.removeOffer(x.id)
                                                            }
                                                        }>
                                                        <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                                    </IconButton> */}
                                                </Col>
                                                <Col xl={5}>
                                                    <TextField
                                                        id={'txtdescription'}
                                                        hintText={'Enter Description'}
                                                        fullWidth={true}
                                                        multiLine={false}
                                                        value={x.description}
                                                        onChange={(event, value) => {
                                                            
                                                        }}
                                                    />
                                                </Col>
                                                <Col style={styles.numericFields} xl={2}>
                                                    <TextField
                                                        id={'txtamount'}
                                                        hintText={'Enter Amount'}
                                                        fullWidth={true}
                                                        multiLine={false}
                                                        value={x.amount}
                                                        onChange={(event, value) => {
                                                           }}
                                                    />
                                                </Col>
                                                <Col style={styles.numericFields} xl={2}>
                                                    <TextField
                                                        id={'txtamount'}
                                                        hintText={'Enter Amount'}
                                                        fullWidth={true}
                                                        multiLine={false}
                                                        value={(x.trade)}
                                                        onChange={(event, value) => {
                                                            //this.props.actions.setValue('trade', value)
                                                        }}
                                                    />
                                                </Col>
                                                <Col style={styles.numericFields} xl={2}>
                                                    <TextField
                                                        id={'txtamount'}
                                                        hintText={'Enter Amount'}
                                                        fullWidth={true}
                                                        multiLine={false}
                                                        value={(x.market)}
                                                        onChange={(event, value) => {
                                                            //this.props.actions.setValue('market', value)
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    })
                                : <div>{'No Items Found...'}</div>
                        }
                        {/* <Row>
                            <Col>
                                <br />
                               < Button className={"global_button"} variant="contained"
                                    key={'add_more_button'}
                                    label={'+ Add'}
                                    primary={true}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        this.addBlankOffer()
                                    }}
                                >{window.t.en('xxxxx')}</Button>
                                <br />
                                <br />
                                <br />
                            </Col>
                        </Row> */}
                        <Row>
                            <Col xl={12}><br /></Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <CardHeader
                                    actAsExpander={false}
                                    showExpandableButton={false}
                                    title={window.t.en('Best Offer')}
                                    style={styles.row}
                                >
                                </CardHeader>
                            </Col>
                        </Row>
                        {
                            this.getBestOffer(data)
                                ? this.getBestOffer(data)
                                    .map((x, i) => {
                                        return <div>
                                            <Row style={styles.tablerow}>
                                                <Col xl={1} style={styles.verticalMiddle}>
                                                </Col>
                                                <Col xl={5}>
                                                    <TextField
                                                        id={'txtdescription'}
                                                        hintText={'Enter Description'}
                                                        fullWidth={true}
                                                        multiLine={false}
                                                        value={x.description}
                                                        onChange={(event, value) => {
                                                            //this.props.actions.setUpdateOfferItem(x.id, 'description', value)
                                                        }}
                                                    />
                                                </Col>
                                                <Col style={styles.numericFields} xl={2}>
                                                    <TextField
                                                        id={'txtamount'}
                                                        hintText={'Enter Amount'}
                                                        fullWidth={true}
                                                        multiLine={false}
                                                        value={x.amount}
                                                        onChange={(event, value) => {
                                                             }}
                                                    />
                                                </Col>
                                                <Col style={styles.numericFields} xl={2}>
                                                    <TextField
                                                        id={'txtamount'}
                                                        hintText={'Enter Amount'}
                                                        fullWidth={true}
                                                        multiLine={false}
                                                        value={(x.trade)}
                                                        onChange={(event, value) => {
                                                            //this.props.actions.setValue('trade', value)
                                                        }}
                                                    />
                                                </Col>
                                                <Col style={styles.numericFields} xl={2}>
                                                    <TextField
                                                        id={'txtamount'}
                                                        hintText={'Enter Amount'}
                                                        fullWidth={true}
                                                        multiLine={false}
                                                        value={(x.market)}
                                                        onChange={(event, value) => {
                                                            //this.props.actions.setValue('market', value)
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    })
                                : <div>{'No Items Found...'}</div>
                        }
                    </Container></CardContent>
            </Card>
        </div>
    }
    addBlankOffer() {
        var maxid = 0;
        this.props.state.offers.map(function (obj) {
            if (obj.id > maxid) maxid = obj.id;
        });
        this.props.actions.addBlankOffer((maxid ? Number(maxid) : 0) + 1)
    }
    getBestOffer(data) {
        var maxamount = 0;
        data.offers.map(function (obj) {
            if (Number(obj.amount) > Number(maxamount)) maxamount = obj.amount;
        });
        return data.offers.filter(x => Number(x.amount) == Number(maxamount))
    }
    settlementDetails(data,settle) {
        let customer = data.customer
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Settlement Details')}
                    subtitle={window.t.en('Disposal Settlement Details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Customer:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Name'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={customer.hierarchyname}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Yardlist Comments:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Comments'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={settle.yardlist_comments}
                                            onChange={(event, value) => {
                                                
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Settlement Excl. Vat:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={settle.settle_excl}
                                            onChange={(event, value) => {
                                                }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Settlement Incl.Vat:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={settle.settle_incl}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Settlement Excl. Vat less 4%:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Months'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={settle.settle_excl_vat_less}
                                            onChange={(event, value) => {
                                                //this.props.actions.setValue('settle_excl_vat_less',  (value))
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'CEO Comments:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Comments'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.ceo_comments_settle}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('ceo_comments_settle',  (value))
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Mantenance Fund Bal:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={settle.maint_fund_bal}
                                            onChange={(event, value) => {
                                                //this.props.actions.setValue('maint_fund_bal',  (value))
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Capital Equalisation Fund:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={settle.cap_eq_fund_bal}
                                            onChange={(event, value) => {
                                                
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Revised NBV-Capital Eq Incl. Vat:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={settle.nbv_capital_incl}
                                            onChange={(event, value) => {
                                                //this.props.actions.setValue('nbv_capital_incl',  (value))
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Commission:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={settle.commission}
                                            onChange={(event, value) => {
                                                 }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>

                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><b>{'Invoice Value Incl. Vat:'}</b></div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(settle.inv_value_incl)}
                                            onChange={(event, value) => {
                                                //this.props.actions.setValue('inv_value_incl', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}><br></br></Col>
                        </Row>
                        <Row>
                            <Col xl={6}><br></br></Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Diff. Between Best Offer & Settlement:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={settle.diff_best_offer_settle}
                                            onChange={(event, value) => {
                                                //this.props.actions.setValue('nbv_capital_incl',  (value))
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Diff. Between Best Offer, Settlement & Capital Eq:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}    
                                            value={settle.diff_best_offer_settle_cap_eq}
                                            onChange={(event, value) => {
                                                   }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                     </Container>
                </CardContent>
            </Card>
        </div>
    }

    profitLossDetails(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Profit/Loss Details')}
                    subtitle={window.t.en('Disposal Profit/Loss Details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Profit/Loss Excl. Eq Fund:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.profit_loss_excl_eq}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Profit/Loss:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.profit_loss}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Funder Monthly Rental:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Months'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.funder_rental}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'CEO Comments:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Comments'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.comments}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('comments', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                               {/*  <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Finance Comments:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Comments'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.finance_comments}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div> */}
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><b>{'Offer Increase:'}</b></div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtchecksheet_odometer'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.offer_increase}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }
    commentsView(data, step2) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Contract Termination Comments')}
                    subtitle={window.t.en('Capture comments')}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Termination Date:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtcomment'}
                                            fullWidth={true}
                                            disabled={true}
                                            multiLine={false}
                                            value={moment(data.incidentdate ? data.incidentdate.value : step2.completeddate).format('YYYY-MM-DD')}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Termination Odo:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtcomment'}
                                            disabled={true}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={data.millage ? data.millage.value : step2.odoreading}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Comments:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'txtcomment'}
                                            hintText={'Enter Comments'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.comments}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('comments', value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }
    approvalCommentsView() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Approval Comments')}
                    subtitle={window.t.en('Capture comments')}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                            <TextField
                                            id={'txtcomment'}
                                            fullWidth={true}
                                            multiLine={true}
                                            value={this.props.state.finance_comments}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('finance_comments',  (value))
                                            }}
                                        />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }
    renderView(data) {
        //console.log("renderView(data) {")
        //console.log(data)
        let steps = data
        let step1 = steps.step1 ? steps.step1.data : data.steps.data
        let step2 = steps.step2.data
        let step3 = steps.step3 ? steps.step3.data : step2
        let step14 = steps.step14
        let step15 = steps.step15 ? steps.step15 : ''
        let step16 = steps.step16 ? steps.step16 : ''
        let vehicle = step1.selectedVehicleDetails.data[0]
        let supplier = step3.selectedSupplierDetails ? step3.selectedSupplierDetails.data : []
        
        let step19 = steps.step19.data

        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('')}
                    subtitle={window.t.en('')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {this.VehicleDetails(vehicle)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.ServiceDealer(supplier)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.defleetDocuments(data.documents)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.techComments(step14)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {step15 ? this.gmComments(step15) : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {step16 ? this.ceoComments(step16) : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.commentsView(step3, step2)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.disposalDetails(step19)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.offerDetails(step19)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.settlementDetails(vehicle,step19)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.profitLossDetails(step19)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.approvalCommentsView()}
                            </Col>
                        </Row> 
                    </Container>
                </CardContent>
            </Card>
        </div>
    }
    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) { }
        return (
            workflow_queue_data
                ? this.renderView(workflow_queue_data)
                : <Loading Message={'Loading, Please wait...'} />
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetDisposalApprovalCEO
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetDisposalApprovalCEO)