import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.GET_ADHOC_FIELD_INPUT_DATA:
            return {
                ...state,
                [action.payload.key]: {
                    loading: true,
                    key: action.payload.key,
                    data: []
                }
            }
        case types.SET_ADHOC_FIELD_INPUT_DATA:
            return {
                ...state,
                [action.payload.key]: {
                    ...action.payload,
                    loading: false,
                    data: action.payload.data
                }
            }
        case types.ADD_ADHOC_FIELD_INPUT_DATA:
            return {
                ...state,
                [action.payload.key]: {
                    ...action.payload,
                    data: action.payload.data
                }
            }
        case types.SET_SUPPLIER_LIST_DATA:
            return {
                ...state,
                [action.payload.key]: action.payload.data
            }
        case types.SET_ADHOC_COMPONENT_FIELD_INPUT:
            return {
                ...state,
                [action.payload.key]: {
                    ...action.payload,
                    data: action.payload.data
                }
            }
        case types.SET_SUPPLIER_DROP_ITEM:
            return {
                ...state,
                [action.payload.prop]: {
                    value: action.payload.value,
                    text: action.payload.text
                }
            }
        case types.ADD_INVOICE_ITEM_BLANK_ROW:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        case types.REMOVE_INVOICE_ITEM_ROW:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        case types.SET_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SAVED_SUPPLIER_INVOICE_DATA:
            return {
                ...state,
                suppliertransaction_date: null,
                suppliertransaction_month: null,
                invoice_reference: null,
                total_vat: null,
                total_amount: null,
                invoice_description: null,
                nettvalue: null,
                payment_amount: null,
                settlement_discount: null,
                total_discount: null,
                settlement_discount_perc: null,
                total_payment: null,
                total_discount: null,
                sub_total: 0,
                vat_total: 0,
                none_total: 0,
                grand_total: 0
            }
            
        case types.VALIDATE_ADHOC_FIELD_INPUT_DATA:
            return state
        case types.REMOVE_ADHOC_FIELD_INPUT_DATA:
            return state
        default:
            return state
    }
}