export const SET_CURRENT_GRID_COMPONENT = 'SET_CURRENT_GRID_COMPONENT'
export const SET_QUOTE_GRID_DATA = 'SET_QUOTE_GRID_DATA'
export const GET_QUOTE_GRID_DATA = 'GET_QUOTE_GRID_DATA'
export const SET_QUOTE_GRID_PAGE = 'SET_QUOTE_GRID_PAGE'
export const SELECT_ROW = 'SELECT_ROW'
export const SELECT_MULTIPLE_ROWS = 'SELECT_MULTIPLE_ROWS'
export const SET_QUOTE_SELECT_ROW_PK = 'SET_QUOTE_SELECT_ROW_PK'
export const GET_QUOTE_DOCUMENT_LIST = 'GET_QUOTE_DOCUMENT_LIST'
export const GET_AWS_QUOTE_DOCUMENT_LIST = 'GET_AWS_QUOTE_DOCUMENT_LIST'
export const CLEAR_AWS_QUOTE_DOCUMENT_LIST = 'CLEAR_AWS_QUOTE_DOCUMENT_LIST'
export const SET_GRID_QUOTE_DOCUMENT_BYTES = 'SET_GRID_QUOTE_DOCUMENT_BYTES'
export const SET_AWS_QUOTE_DOCUMENT_LIST = 'SET_AWS_QUOTE_DOCUMENT_LIST'
export const SET_QUOTE_GRID_DOCUMENTS = 'SET_QUOTE_GRID_DOCUMENTS'
export const QUOTE_DOCUMENT_VIEWER = 'QUOTE_DOCUMENT_VIEWER'
export const SEARCH_PO_QUOTE_DATA = 'SEARCH_PO_QUOTE_DATA'
export const SET_PO_COMPONENT_GRID_DATA = 'SET_PO_COMPONENT_GRID_DATA'