import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import Grid from './grid'

import * as actions from './actions'

class RelatedGrid extends Component {

    getLayout() {
        //console.log('relatedgrid x',this.props)
        return <div>
            <Grid 
                id={this.props.fieldname}
                key={this.props.fieldname} 
                fieldname={this.props.fieldname} 
                model={this.props.model} 
                component={this.props.component} 
                readonly={this.props.readonly}
            />
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.relatedGrid
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedGrid)