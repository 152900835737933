import * as config from '../../config'

import * as actions from './actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as progressBarActions from '../ProgressBar/actions'

import * as types from './types'
import * as requestTypes from '../Request/types'

export const getScheduler = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_COMPONENT_REPORT_SCHEDULER_BY_USER_AND_REPORT)
        , delay(0)
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/scheduler/search?report_name=' + payload.report_name + '&created_by_user_id=' + payload.user_id,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'report',
                    
                },
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setScheduler(result.response),
                        requestActions.requestSucceeded(config.system.api + '/components/reportcategory/search')
                    ])
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/reportcategory/search', error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )



export const getReportGridData = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_REPORT_GRID_DATA_BY_REPORT_NAME)
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/report/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            }).pipe(
                mergeMap((result) => [
                    actions.setReportGridData(result.response),
                    progressBarActions.progress(false)
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )


export const saveSchedule = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.SAVE_COMPONENT_SCHEDULE_USER_DATA)
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/report/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            }).pipe(
                mergeMap((result) => [
                    actions.savedSchedule(result.response),
                    progressBarActions.progress(false)
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )


export const getTeamReports = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_REPORT_SUB_CATEGORY_LIST)
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/report/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            }).pipe(
                mergeMap((result) => {
                    return[
                        actions.setReportSubCategory(result.response.data),
                        progressBarActions.progress(false)
                    ]
                })
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )


export const getReportCategory = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_REPORT_CATEGORY_LIST)
    , mergeMap(({ payload}) =>
        ajax$({
            url: config.system.api + '/components/reportcategory/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => {
                return[
                    actions.setReportCategory(result.response),
                    progressBarActions.progress(false)
                ]
            })
            , startWith(progressBarActions.progress(true))
            , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
    )
    , catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)