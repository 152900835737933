import moment from 'moment'
import * as types from './types'

export const addContact = () => {
    return (dispatch, getState) => {
        let contacts = getState().accessoryMasterCapture.contacts
        contacts.push({editing: true})
        dispatch(setInputValue('contacts',contacts))
    }
}

export const delContact = (inx) => {
    return (dispatch, getState) => {
        let contacts = getState().accessoryMasterCapture.contacts
        let _contacts = []
        contacts.map((x,i) => {
            if(inx !== i) _contacts.push(x)
        })
        dispatch(setInputValue('contacts',_contacts))
    }
}


export const setContactInputValue = (data) => {
    return (dispatch, getState) => {
        let contacts = getState().accessoryMasterCapture.contacts
        contacts[data.inx][data.prop] = data.value
        dispatch(setInputValue('contacts',contacts))
    }
}

export const addAccessory = () => {
    return (dispatch, getState) => {
        let accessories = getState().accessoryMasterCapture.accessories
        accessories.push({editing: true,sellingprice:'0.00',costprice:'0.00',vatable: '',expirydate: moment().add(30, 'days').format('YYYY-MM-DD')})
        dispatch(setInputValue('accessories',accessories))
    }
}

export const delAccessory = (inx) => {
    return (dispatch, getState) => {
        let accessories = getState().accessoryMasterCapture.accessories
        let _accessories = []
        accessories.map((x,i) => {
            if(inx !== i) _accessories.push(x)
        })
        dispatch(setInputValue('accessories',_accessories))
    }
}

export const addSundry = () => {
    return (dispatch, getState) => {
        let sundries = getState().accessoryMasterCapture.sundries
        sundries.push({editing: true,sellingprice:'0.00',costprice:'0.00',vatable: '',expirydate: moment().add(30, 'days').format('YYYY-MM-DD')})
        dispatch(setInputValue('sundries',sundries))
    }
}

export const delSundry = (inx) => {
    return (dispatch, getState) => {
        let sundries = getState().accessoryMasterCapture.sundries
        let _sundries = []
        sundries.map((x,i) => {
            if(inx !== i) _sundries.push(x)
        })
        dispatch(setInputValue('sundries',_sundries))
    }
}

export const addService = () => {
    return (dispatch, getState) => {
        let services = getState().accessoryMasterCapture.services
        services.push({editing: true,sellingprice:'0.00',costprice:'0.00',vatable: '',expirydate: moment().add(30, 'days').format('YYYY-MM-DD')})
        dispatch(setInputValue('services',services))
    }
}

export const delService = (inx) => {
    return (dispatch, getState) => {
        let services = getState().accessoryMasterCapture.services
        let _services = []
        services.map((x,i) => {
            if(inx !== i) _services.push(x)
        })
        dispatch(setInputValue('services',_services))
    }
}

export const setAccessoryInputValue = (data) => {
    return (dispatch, getState) => {
        let accessories = getState().accessoryMasterCapture.accessories
        accessories[data.inx][data.prop] = data.value
        dispatch(setInputValue('accessories',accessories))
    }
}
export const setSundryInputValue = (data) => {
    return (dispatch, getState) => {
        let sundries = getState().accessoryMasterCapture.sundries
        sundries[data.inx][data.prop] = data.value
        dispatch(setInputValue('sundries',sundries))
    }
}
export const setServiceInputValue = (data) => {
    return (dispatch, getState) => {
        let services = getState().accessoryMasterCapture.services
        services[data.inx][data.prop] = data.value
        dispatch(setInputValue('services',services))
    }
}
export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_ACCESSORY_MASTER_CAPTURE_FORM,
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_ACCESSORY_MASTER_CAPTURE_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_ACCESSORY_MASTER_CAPTURE_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
                query: `?${field}=${search}`
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        
        let data = response.data.map(x => {
            return {...x, text: x[payload.field], value: x[payload.component + '_id']}
        })
        dispatch(setInputValue(payload.component + '_data', data))
    }
}

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_ACCESSORY_MASTER_CAPTURE,
            payload: payload
        })
    }
}

export const getSupplierAccessories = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_ACCESSORY_MASTER_SUPPLIER_ACCESSORIES_LIST,
            payload: data
        })
    }
}

export const setSupplierAccessories = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setSupplierAccessories payload', payload)
        //console.log('setSupplierAccessories response', response)
        
        let data = response.data.map(x => {
            return {...x, [payload.display]: x[payload.related.split(',')[0]][payload.display]}
        })
        dispatch(setInputValue(payload.field, data))
    }
}