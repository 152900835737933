import * as props from './props'
import * as types from './types'



export default (state = props, action) => {
    switch (action.type) {

        case types.SET_VEHICLES_ACTION:
        return {
            ...state,
            vehicles: action.payload
        }

        case types.SET_CUSTOMER_ACTION:
        return {
            ...state,
            customers: action.payload
        }

        case types.SET_VEHICLE_CUSTOMER_VALUE:
        return {
            ...state,
            selectedCustomerName: action.payload.selectedCustomerName,
            selectedCustomer: action.payload.selectedCustomer
        }

        case types.SET_NEW_CUSTOMER_VALUE:
        return {
            ...state,
            newCustomer : action.payload,
        }

        
        case types.SET_DATE_FIELD_INPUT_VALUE:
        return {
            ...state,
            effectdate : action.payload,
          
        }

        default:
            return state
    }
}