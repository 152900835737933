import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import AutoComplete from '../../../controls/autocomplete'
import Card from '../../../controls/card'

import * as _ from 'lodash'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDocuments extends Component {

    documentList() {

        const data = this.props.state.documents

        //console.log(data)
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            data.length
                            ? data.map(_list => {
                                return _list.deleted_at
                                    ? ''
                                    : <div>
                                        <Row>
                                            <Col xl={12} style={styles.rowHeader}>
                                                <br />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={12}>
                                                <h4 style={styles.hr}>{_list.rebill ? 'Rebill : ' : 'PO : '} {_list.licenseplateno + ' Auth ' + _list.authnumber + ' For ' + _list.purchaseordertype + ' @ R ' + _list.amount_incl}</h4> 
                                            </Col>
                                        </Row>
                                        <Row><Col xl={12}>
                                            {
                                            _list.documents.length
                                                    ? <PdfViewer
                                                        key={'document_viewer_main_history'}
                                                        name={'purchaseorder'}
                                                        workflow={'fml_customer_quote'}
                                                        task={'tsk_fml_quote_waiting_vehicle_delivery'}
                                                        documents={_list.documents}
                                                        edit={false}
                                                        multiple={false}
                                                        workflow_queue_data={null}
                                                    />
                                                    : <Loading message={'Retrieving vehicle history...'} />
                                            }
                                        </Col>
                                        </Row>
                                    </div>
                            })
                            : 'No Documents Found...'
                        }
                    </Container>
        </div>
    }

    getDocumentsList() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={10}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Select Vehicle Registration:'}</div>
                        <div style={styles.fieldContent}>
                            {/* <AutoComplete
                                style={styles.inputWidth}
                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                id={'autocomplete_purchase_order_vehicle_reg'}
                                key={'autocomplete_purchase_order_vehicle_reg'}
                                hintText={'Search vehicle registration'}
                                filter={AutoComplete.fuzzyFilter}
                                openOnFocus={true}
                                fullWidth={true}
                                getOptionLabel={(option) => option.text}
                                options={this.props.state.vehicles}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label={window.t.en('Search vehicle registration')}
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    />
                                )}
                                onChange={
                                    (event, request) => {
                                        //console.log('onChange')
                                        if(request)
                                        this.props.actions.getWorkflowDocuments(request.value)
                                    }
                                }
                                onOpen={
                                    (event) => {
                                        //console.log('onOpen')
                                        this.props.actions.getWorkflowVehicleList('')
                                    }
                                }
                                onInputChange={
                                    (event, search, params) => {
                                        //console.log('search >>>>>>>>>>>> ',search)
                                        this.props.actions.getWorkflowVehicleList(search)
                                    }
                                }
                            /> */}

                            <AutoComplete
                                name={'autocomplete_purchase_order_vehicle_reg_docs'}
                                title={window.t.en('Select Vehicle Registration_docs')}
                                info={{
                                    show: false
                                }}
                                fieldvalue={'vehicle_id'}
                                displayfields={['licenseplateno','makemodelyear']}
                                displaywidth={['20','80']}
                                fullWidth={true}
                                data={this.props.state.vehicles}
                                onSelect={(args) => {
                                    //console.log('onSelect Select Vehicle Registrati',args)
                                    this.props.actions.getWorkflowDocuments(args.value)
                                }}
                                onClick={() => {
                                    this.props.actions.getWorkflowVehicleList('')
                                }}
                                onFilter={(args) => {
                                    //console.log(args)
                                    this.props.actions.getWorkflowVehicleList(args)
                                }}
                            />
                        </div>
                    </div>
                    <br/>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {
                        this.props.state.documents.length && this.props.state.isOpen
                            ? this.documentList()
                            : ''
                    }

                </Col>
            </Row>
        </Container>
    }

    render() {
        
        return <Card 
                title={window.t.en('Documents')}
                subtitle={window.t.en('Authorization documents search.')}
                content={this.getDocumentsList()}
            />

        // return this.props.state.isOpen
        //     ? <Card initiallyExpanded={true}>
        //         <CardHeader
        //             actAsExpander={false}
        //             showExpandableButton={false}
        //             title={window.t.en('Documents')}
        //             subtitle={window.t.en('Authorization documents search.')}
        //             avatar={<Icon iclass={'material-icons'} iname={'history'} />}
        //         />
        //         <CardContent expandable={true}>
        //             {this.getDocumentsList()}
        //         </CardContent>
        //     </Card>
        //     : <div />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDocuments,
            workflowQueueTasks: {
                ...state.workflowQueueTasks
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDocuments)