import Button from '@mui/material/Button'

import IconButton from '@mui/material/IconButton'
import Icon from '../../Icon/component'

import AutoComplete from '../../../controls/autocomplete'
import DateControl from '../../../controls/dateControl'
import TextField from '../../../controls/textField'
import Card from '../../../controls/card'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'

import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as compActions from '../../Components/actions'
import Upload from '../../Upload/component'
import * as actions from './actions'
import * as styles from './styles'
import * as _ from 'lodash'

class SUPPLIERTRANSACTION extends Component {
    componentDidMount() {
        this.props.actions.resetForm()
        if(this?.props?.params?.id)
            this.props.actions.getInvoiceDetails(this?.props?.params?.id)
    }
    
    getView() {
        return <div>
            <Card
                title={('Capture Transactions...')}
                subtitle={('Capture supplier transactions')}
                content={
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={6}>
                            <Button variant="contained" className="btn-Accept"
                                primary={true}
                                onClick={() => {
                                    this.props.actions.resetForm()
                                }}
                            >{('Capture New')}</Button>
                        </Col>
                        <Col xl={6}></Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}><strong>{('Transaction Type')}</strong></div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        name={'dll_invoice_id'}
                                        title={('Search...')}
                                        info={{
                                            show: false
                                        }}
                                        filter={AutoComplete.fuzzyFilter}
                                        fullWidth={true}
                                        data={this.props.state.transaction_types}
                                        error={this.props.state.transaction_type.text ? "" : "Select transaction type"}
                                        value={this.props.state.transaction_type.text ? this.props.state.transaction_type.text :'' }
                                        onSelect={(args) => {
                                            if (args) {
                                                //console.log('transaction_typex args', args)
                                                this.props.actions.setLookupValue("transaction_type", args.value, args.text)
                                            }
                                        }
                                        }
                                        onClick={() =>
                                            this.props.actions.getAllTransactionTypes('')
                                        }
                                        onFilter={(args) =>{
                                            this.props.actions.getAllTransactionTypes(args)
                                            this.props.actions.setLookupValue("transaction_type", null, null)
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Creditor'}</div>
                                <div style={styles.fieldContent}>
                                    {
                                        this.props.state.viewinvoice ?
                                            <TextField
                                                id={'txt_suppliername'}
                                                key={'txt_suppliername'}// fktext removed
                                                fullWidth={true}
                                                disabled={true}
                                                variant="outlined"
                                                /* color="secondary" */
                                                value={this.props.state.supplier}
                                                onChange={
                                                    (event, value) => {
                                                    }
                                                }
                                            />
                                            : <AutoComplete
                                                name={'dll_supplier_id'}
                                                title={('Search...')}
                                                info={{
                                                    show: false
                                                }}
                                                fullWidth={true}
                                                filter={AutoComplete.fuzzyFilter}
                                                data={this.props.state.creditor_list}
                                                error={this.props.state.selected_supplier.text ? "" : "Select supplier"}
                                                value={this.props.state.selected_supplier.text ? this.props.state.selected_supplier.text : '' }
                                                onSelect={(args) => {
                                                    var _date = moment(new Date()).format('YYYYMM')
                                                    this.props.actions.setLookupValue("selected_supplier", args.value, args.text)
                                                    //this.props.actions.setInputValue('suppliertransaction_date', new Date())
                                                    // this.props.actions.setInputValue('suppliertransaction_month', _date)
                                                }
                                                }
                                                onClick={() =>
                                                    this.props.actions.getLookupValueData('')
                                                }
                                                onFilter={(args) =>{
                                                    this.props.actions.getLookupValueData(args)
                                                    this.props.actions.setLookupValue("selected_supplier", null, null)
                                                   }
                                               }
                                            />
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Date')}</div>
                                <div style={styles.fieldContent}>
                                    {
                                        this.props.state.viewinvoice ?
                                            <TextField
                                                id={'txtreference'}
                                                key={'txtreference'}
                                                label={('Enter Reference...')}
                                                fullWidth={true}
                                                variant="outlined"
                                                disabled={this.props.state.viewinvoice}
                                                error={this.props.state.suppliertransaction_date ? "" : "Select transaction date"}
                                                /* color="secondary" */
                                                value={this.props.state.suppliertransaction_date ? moment(this.props.state.suppliertransaction_date).format('YYYY/MM/DD') : null}
                                                onChange={
                                                    (event) => {
                                                    }
                                                }
                                            /> :
                                            <DateControl
                                                id={'txt_invoicedate'}
                                                key={'txt_invoicedate'}
                                                name={'txt_invoicedate'}
                                                disabled={this.props.state.viewinvoice}
                                                label={('Select Date...')}
                                                field={'suppliertransaction_date'}
                                                value={this.props.state.suppliertransaction_date}
                                                error={this.props.state.suppliertransaction_date ? "" : "Select transaction date"}
                                                onSelect={(args) => {
                                                    this.props.actions.setInputValue('suppliertransaction_date', args)
                                                }}
                                                type={'date'}
                                            />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Month')}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txt_suppliertransaction_month'}
                                        key={'txt_suppliertransaction_month'}
                                        label={('Enter Month...')}
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={this.props.state.viewinvoice}
                                        /* color="secondary" */
                                        value={this.props.state.suppliertransaction_month}
                                        error={this.props.state.suppliertransaction_month ? "" : "Select transaction month"}
                                        onChange={
                                            (event, value) => {
                                                this.props.actions.setInputValue('suppliertransaction_month', event.target.value)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Reference')}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtreference'}
                                        key={'txtreference'}
                                        label={'Enter Reference...'}
                                        fullWidth={true}
                                        variant="outlined"
                                        //disabled={this.props.state.viewinvoice}
                                        /* color="secondary" */
                                        value={this.props.state.invoice_reference}
                                        error={this.props.state.invoice_reference ? "" : "Enter reference"}
                                        onChange={
                                            (event) => {
                                                //console.log('invoice_referencex', event.target.value)
                                                this.props.actions.setInputValue('invoice_reference', event.target.value)
                                                //this.props.actions.getDuplicateReferenceCheck(event.target.value)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    {/* <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Customer Reference')}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtcustomer_reference'}
                                        key={'txtcustomer_reference'}
                                        label={('Enter Customer Reference...')}
                                        fullWidth={true}
                                        disabled={this.props.state.viewinvoice}
                                        variant="outlined"
                                         color="secondary" 
                                        value={this.props.state.customer_reference}
                                        onChange={
                                            (event, value) => {
                                                this.props.actions.setInputValue('customer_reference', event.target.value)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Description')}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtdescription'}
                                        key={'txtdescription'}
                                        label={'Enter Description...'}
                                        fullWidth={true}
                                        disabled={this.props.state.viewinvoice}
                                        error={this.props.state.invoice_reference ? "" : "Enter description"}
                                        variant="outlined"
                                        /* color="secondary" */
                                        value={this.props.state.invoice_description}
                                        onChange={
                                            (event, value) => {
                                                this.props.actions.setInputValue('invoice_description', event.target.value)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                } />
            <Card
                    title={('Capture Items')}
                    subtitle={('Capture transaction items')}
                    content={ <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <td colSpan={6}>
                                        <Button variant="contained" className="btn-Accept"
                                        disabled={this.props.state.viewinvoice}
                                        primary={true}
                                        onClick={() => {
                                            this.props.actions.addBlankRow()
                                        }}
                                        >{('Add Item')}</Button>
                                    </td>
                                </tr>
                            </thead>
                            <thead>
                                {
                                    this.props.state.transaction_type.text && this.props.state.transaction_type.text.includes('Payment')
                                    ? <tr>
                                        <th>{''}</th>
                                        <th style={{width: '300px'}}>{'Description'}</th>
                                        <th>{'Quantity'}</th>
                                        <th>{'Payment Amount'}</th>
                                        <th colSpan={2}>{'Settlement Discount'}</th>
                                        <th>{'Total'}</th>
                                        <th style={{width: '300px'}}>{'GL Account'}</th>
                                    </tr>
                                    : <tr>
                                        <th>{''}</th>
                                        <th style={{width: '300px'}}>{'Description'}</th>
                                        <th>{'Unit'}</th>
                                        <th>{'Quantity'}</th>
                                        <th>{'Unit Price'}</th>
                                        <th>{'Vatable'}</th>
                                        <th>{'VAT'}</th>
                                        <th>{'Total'}</th>
                                        <th style={{width: '300px'}}>{'GL Account'}</th>
                                    </tr>
                                }
                            </thead>
                            <tbody style={{ backgroundColor: '#fff', color: '#000' }}>
                                {
                                    this.props.state.invoice_items 
                                        ? this.props.state.invoice_items.map((x, inx) => {
                                        return this.props.state.transaction_type.text && this.props.state.transaction_type.text.includes('Payment')
                                        ? <tr style={{ backgroundColor: '#fff', color: '#000' }}>
                                            <td>
                                                <IconButton key={'delete_forever_' + inx}
                                                    onClick={
                                                        () => {
                                                            this.props.actions.removeRow(inx)
                                                        }
                                                    }>
                                                    <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
                                                </IconButton>
                                            </td>
                                            <td>
                                                <TextField
                                                    id={'txtitemdescription'}
                                                    key={'txtitemdescription'}
                                                    //label={'Description'}
                                                    fullWidth={true}
                                                    disabled={this.props.state.viewinvoice}
                                                    variant="outlined"
                                                    /* color="secondary" */
                                                    value={x.description}
                                                    onChange={(event) => {
                                                        //console.log('onChangex',event)
                                                            let value = event.target.value
                                                            this.props.actions.setItemValue({ inx: inx, field: 'description', value: value })
                                                        }
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id={'txtitemquantity'}
                                                    key={'txtitemquantity'}
                                                    //label={'Quantity'}
                                                    fullWidth={true}
                                                    disabled={true}
                                                    variant="outlined"
                                                    value={x.quantity}
                                                    onChange={
                                                        (event) => {
                                                            this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: event.target.value })
                                                            this.props.actions.calcTotals()
                                                        }
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id={'txtitemunitprice'}
                                                    key={'txtitemunitprice'}
                                                    //label={'Unit Price'}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    value={x.unitprice}
                                                    onChange={
                                                        (event) => {
                                                            this.props.actions.setItemValue({ inx: inx, field: 'unitprice', value: event.target.value })
                                                            this.props.actions.calcTotals()
                                                        }
                                                    }
                                                />
                                            </td>
                                            <td colSpan={2}>
                                                <TextField
                                                    id={'txtdiscount'}
                                                    key={'txtdiscount'}
                                                    //label={'VAT'}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    value={x.discount}
                                                    onChange={
                                                        (event) => {
                                                            this.props.actions.setItemValue({ inx: inx, field: 'discount', value: event.target.value })
                                                            this.props.actions.calcTotals()
                                                        }
                                                    }

                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id={'txtitemtotal'}
                                                    key={'txtitemtotal'}
                                                    //label={'Total'}
                                                    fullWidth={true}
                                                    disabled={true}
                                                    variant="outlined"
                                                    value={x.total}

                                                />
                                            </td>
                                            <td>
                                                {
                                                    this.props.state.viewinvoice ?
                                                        <TextField
                                                            id={'txt_itemaccount'}
                                                            key={'txt_itemaccount'}
                                                            label={'GL Account'}
                                                            fullWidth={true}
                                                            disabled={this.props.state.viewinvoice}
                                                            variant="outlined"
                                                            value={x.account_text}
                                                            onChange={() => {
                                                            }}
                                                        /> :
                                                        <AutoComplete
                                                            name={'dll_itemaccount'}
                                                            title={'Search...'}
                                                            info={{
                                                                show: false
                                                            }}
                                                            fullWidth={true}
                                                            filter={AutoComplete.fuzzyFilter}
                                                            data={this.props.state.glaccount_list}
                                                            value={x.account_text}
                                                            onSelect={(args) => {
                                                                this.props.actions.setItemValue({ inx: inx, field: 'account_id', value: args.value })
                                                                this.props.actions.setItemValue({ inx: inx, field: 'account_text', value: args.text })
                                                            }
                                                            }
                                                            onClick={() =>
                                                                this.props.actions.getContractAccountListData('')
                                                            }
                                                            onFilter={(args) =>
                                                                this.props.actions.getContractAccountListData(args)
                                                            }
                                                        />
                                                }
                                            </td>
                                            <td></td>
                                        </tr>
                                        : <tr style={{ backgroundColor: '#fff', color: '#000' }}>
                                        <td>
                                            <IconButton key={'delete_forever_' + inx}
                                                onClick={
                                                    () => {
                                                        this.props.actions.removeRow(inx)
                                                    }
                                                }>
                                                <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
                                            </IconButton>
                                        </td>
                                        <td>
                                            <TextField
                                                id={'txtitemdescription'}
                                                key={'txtitemdescription'}
                                                //label={'Description'}
                                                fullWidth={true}
                                                disabled={this.props.state.viewinvoice}
                                                variant="outlined"
                                                /* color="secondary" */
                                                value={x.description}
                                                onChange={(event) => {
                                                    //console.log('onChangex',event)
                                                        let value = event.target.value
                                                        this.props.actions.setItemValue({ inx: inx, field: 'description', value: value })
                                                    }
                                                }
                                            />
                                        </td>
                                    <td>
                                        <AutoComplete
                                            name={'dll_itemunit'}
                                            title={window.t.en('Search...')}
                                            info={{
                                                show: false
                                            }}
                                            fullWidth={true}
                                            data={this.props.state.unit_list}
                                            value={x.unit_text}
                                            onSelect={(args) => {
                                                if (args.value) {
                                                    this.props.actions.setItemValue({ inx: inx, field: 'unit', value: args.value })
                                                    this.props.actions.setItemValue({ inx: inx, field: 'unit_text', value: args.text })
                                                }
                                            }
                                            }
                                            onClick={() =>
                                                this.props.actions.getUnitMeasureList('')
                                            }
                                            onFilter={(args) =>
                                                this.props.actions.getUnitMeasureList(args)
                                            }
                                        />
                                    </td>
                                        <td>
                                            <TextField
                                                id={'txtitemquantity'}
                                                key={'txtitemquantity'}
                                                //label={'Quantity'}
                                                fullWidth={true}
                                                disabled={this.props.state.viewinvoice}
                                                variant="outlined"
                                                /* color="secondary" */
                                                value={x.quantity}
                                                onChange={
                                                    (event) => {
                                                        this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: event.target.value })
                                                        this.props.actions.calcTotals()
                                                        // let value = event.target.value
                                                        // var unitprice = Number(x.unitprice)
                                                        // var amount = unitprice ? Number(unitprice) * Number(value) : 0
                                                        // var vat = x.vat ? Number(amount) * 0.15 : 0
                                                        // var total = amount
                                                        // var vattable = vat ? true : false

                                                        // this.props.actions.setItemValue({ inx: inx, field: 'unitprice', value: unitprice })
                                                        // this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: value })
                                                        // this.props.actions.setItemValue({ inx: inx, field: 'vat', value: vat })
                                                        // this.props.actions.setItemValue({ inx: inx, field: 'amount', value: total })
                                                        // this.props.actions.setItemValue({ inx: inx, field: 'vattable', value: vattable })
                                                    }
                                                }
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                id={'txtitemunitprice'}
                                                key={'txtitemunitprice'}
                                                //label={'Unit Price'}
                                                fullWidth={true}
                                                disabled={this.props.state.viewinvoice}
                                                variant="outlined"
                                                value={x.unitprice}
                                                onChange={
                                                    (event) => {
                                                        this.props.actions.setItemValue({ inx: inx, field: 'unitprice', value: event.target.value })
                                                        this.props.actions.calcTotals()
                                                        // let value = event.target.value
                                                        // var unitprice = value
                                                        // var amount = Number(unitprice) * Number(x.quantity)
                                                        // var vat = x.vat ? Number(amount) * 0.15 : 0
                                                        // var total = amount
                                                        // var vattable = vat ? true : false

                                                        // this.props.actions.setItemValue({ inx: inx, field: 'unitprice', value: unitprice })
                                                        // this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: x.quantity })
                                                        // this.props.actions.setItemValue({ inx: inx, field: 'vat', value: vat })
                                                        // this.props.actions.setItemValue({ inx: inx, field: 'amount', value: total })
                                                        // this.props.actions.setItemValue({ inx: inx, field: 'vattable', value: vattable })
                                                    }
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Checkbox
                                                key={'chck_vattable'}
                                                id={'chck_vattable'}
                                                checked={x.vatable}
                                                disabled={this.props.state.viewinvoice}
                                                onClick={(event, checked) => {
                                                    //console.log('onChangex event',event.target.checked)
                                                    this.props.actions.setItemValue({ inx: inx, field: 'vatable', value: event.target.checked })
                                                    this.props.actions.calcTotals()
                                                    // this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: x.quantity })
                                                    // this.props.actions.setItemValue({ inx: inx, field: 'vat', value: vat })
                                                    // this.props.actions.setItemValue({ inx: inx, field: 'amount', value: total })
                                                    // this.props.actions.setItemValue({ inx: inx, field: 'vattable', value: vattable })

                                                }} />
                                        </td>
                                        <td>
                                            <TextField
                                                id={'txtitemvat'}
                                                key={'txtitemvat'}
                                                //label={'VAT'}
                                                fullWidth={true}
                                                disabled={true}
                                                variant="outlined"
                                                value={x.vat}

                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                id={'txtitemtotal'}
                                                key={'txtitemtotal'}
                                                //label={'Total'}
                                                fullWidth={true}
                                                disabled={true}
                                                variant="outlined"
                                                value={x.total}

                                            />
                                        </td>
                                        <td>
                                            {
                                                this.props.state.viewinvoice ?
                                                    <TextField
                                                        id={'txt_itemaccount'}
                                                        key={'txt_itemaccount'}
                                                        label={'GL Account'}
                                                        fullWidth={true}
                                                        disabled={this.props.state.viewinvoice}
                                                        variant="outlined"
                                                        value={x.account_text}
                                                        onChange={() => {
                                                        }}
                                                    /> :
                                                    <AutoComplete
                                                        name={'dll_itemaccount'}
                                                        title={'Search...'}
                                                        info={{
                                                            show: false
                                                        }}
                                                        fullWidth={true}
                                                        data={this.props.state.glaccount_list}
                                                        value={x.account_text}
                                                        onSelect={(args) => {

                                                            let glaccount = this.props.state.glaccount_list
                                                            .filter(x => x.accountingitem_id == args.value)
                                                            glaccount = glaccount.length ? glaccount[0] : {}
                                                            
                                                            //console.log('glaccountx list', this.props.state.glaccount_list)
                                                            //console.log('glaccountx gl', glaccount)
                                                            //console.log('glaccountx id', args)

                                                            this.props.actions.setItemValue({ inx: inx, field: 'vatable', value: glaccount.vatable ? true : false })
                                                            this.props.actions.calcTotals()

                                                            this.props.actions.setItemValue({ inx: inx, field: 'account_id', value: args.value })
                                                            this.props.actions.setItemValue({ inx: inx, field: 'account_text', value: args.text })
                                                        }
                                                        }
                                                        onClick={() =>
                                                            this.props.actions.getContractAccountListData('')
                                                        }
                                                        onFilter={(args) =>
                                                            this.props.actions.getContractAccountListData(args)
                                                        }
                                                    />
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    })
                                    : ''
                                }
                            </tbody>
                        </Table>
                        <hr />
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={7}> </Col>
                                <Col xl={5} style={styles.rightpanel}>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><strong>{('Sub-Total (Vattable)')}</strong></div>
                                                <div style={styles.numericFields}>
                                                    <TextField
                                                        id={'txtcredit'}
                                                        value={this.props.state.sub_total}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><strong>{('VAT')}</strong></div>
                                                <div style={styles.numericFields}>
                                                    <TextField
                                                        id={'txtvat'}
                                                        value={this.props.state.vat_total}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><strong>{('Sub-Total (Non-Vattable)')}</strong></div>
                                                <div style={styles.numericFields}>
                                                    <TextField
                                                        id={'txtnettvalue'}
                                                        value={this.props.state.none_total}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}><strong>{('Total')}</strong></div>
                                                <div style={styles.numericFields}>
                                                    <TextField
                                                        id={'txttotal'}
                                                        hintText='Total'
                                                        value={this.props.state.grand_total}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row><Col xl={12}> </Col></Row>
                                </Col>
                            </Row>
                            <Row><Col xl={12}><br></br></Col></Row>
                        </Container>
                        </div>
                    } />
            <Card
                title={('Documents')}
                subtitle={('Upload supporting documents')}
                content={ <Upload
                    disabled={this.props.state.viewinvoice}
                    id={'upload_ctl'}
                    prefix={'inv'}
                    header={false}
                    list={[{ value: 0, text: 'Supplier Invoice' }, { value: 0, text: 'Supplier Attachment' }]}
                /> }
                />
            <Container style={{ margin: 0 }} className="custom-container">
            <Row><Col xl={12}><br></br></Col></Row>
                <Row>
                    <Col xl={10}></Col>
                    <Col xl={2}>
                        < Button className={"global_button"} variant="contained" header={false}
                            disabled={this.props.state.viewinvoice}
                            onClick={() => {
                                var payload = {
                                    data: {
                                        invoice_items: this.props.state.invoice_items,
                                        selected_supplier: this.props.state.selected_supplier,
                                        transaction_type: this.props.state.transaction_type,
                                        suppliertransaction_date: this.props.state.suppliertransaction_date,
                                        suppliertransaction_month: this.props.state.suppliertransaction_month,
                                        invoice_reference: this.props.state.invoice_reference,
                                        invoice_description: this.props.state.invoice_description,
                                        total_vat: this.props.state.vat_total,
                                        total_amount: this.props.state.grand_total,
                                        nettvalue: this.props.state.sub_total,
                                        nonvattotal: this.props.state.none_total,


                                        payment_amount: this.props.state.payment_amount,
                                        total_discount: this.props.state.total_discount,
                                        selected_account: this.props.state.selected_account
                                    },
                                    documents: this.props.state.upload.documents,
                                    user: this.props.state.user
                                }
                                this.props.actions.saveSupplierInvoiceData(payload)
                            }}
                        >{('Save Transaction')}</Button>
                    </Col>
                </Row></Container>
        </div>
    }
    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.suppliertransaction,
            upload: {
                ...state.upload
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERTRANSACTION)