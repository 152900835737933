import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.UI_CONNECTED_TO_MESSENGER:
            return {
                ...state,
                connected: action.payload.connected
            }
        case types.UI_DISCONNECTED_FROM_MESSENGER:
            return {
                ...state,
                connected: action.payload.connected
            }
        case types.UI_FAILED_TO_CONNECT_TO_MESSENGER:
            return {
                ...state,
                error: action.payload
            }
        case types.ENGINE_CONNECTED_TO_MESSENGER:
            return {
                ...state,
                engine: true
            }
        case types.ENGINE_DISCONNECTED_FROM_MESSENGER:
            return {
                ...state,
                engine: false
            }
        default:
            return state
    }
}