import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, IconButton } from '@mui/material'

import Icon from '../../Icon/component'

import VisibilityIcon from '@mui/icons-material/Visibility';
import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewSupplierTransactions from '../../../controls/view_supplier_transaction'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Form from '../../../controls/form'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'
import Drawer from '../../../controls/drawer'

import Checkbox from '../../../controls/checkbox'
import CardControl from '../../../controls/card'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import * as styles from './styles'
import * as actions from './actions'

class CashbookArchiveReview extends Component {


    getTransactionItems(cashbook) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Audit Number</th>
                                    <th>Account Number</th>
                                    {/* <th>GL Link Number</th> */}
                                    <th>Cash Book Number</th>
                                    {/* <th>Reconciled Indicator</th> */}
                                    <th>Reference </th>
                                    <th>Date </th>
                                    {/* <th>Transaction Type</th> */}
                                    <th>Transaction Description</th>
                                    <th>Debit </th>
                                    <th>Credit </th>
                                    <th>Module </th>
                                    {/* <th>Sub Module</th>
                                    <th>Post Period</th>
                                    <th>User Date</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cashbook.cashbook.map((x,inx) => {
                                        return <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.audit_number} </td>
                                            <td>{x.accountingnumber}</td>
                                            {/* <td>{x.link_number}</td> */}
                                            <td>{x.cashbook_number}</td>
                                            {/* <td>{x.reconciled ? 'Yes' : 'No'}</td> */}
                                            <td>{x.reference}</td>
                                            <td>{moment(x.transaction_date).format('DD-MM-YYYY')}</td>
                                            {/* <td>{x.transaction_type}</td> */}
                                            <td>{x.item_type}</td>
                                            <td>{x.debit}</td>
                                            <td>{x.credit}</td>
                                            <td>{x.module}</td>
                                            {/* <td>{x.sub_module}</td>
                                            <td>{x.transaction_month}</td>
                                            <td>{moment(x.transaction_date).format('DD-MM-YYYY')}</td> */}
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }
    getConfirm(data) {
        
        return <div>
            <Card
            
            title={'Cash Book Transaction Parameters'}
            subtitle={'Cash Book Transaction Archiving Date Range'}
        content={
                <div> <Fields labelsize='130'
        fields={[
            {size: 6, label: 'Start Date:', value: data.cashbook.start_date},
            {size: 6, label: 'End Date', value: data.cashbook.end_date},
            {size: 6, label: 'CashBook Ref', value: data.cashbook_ref},
            {size: 6, label: 'Capture Date', value: data.datestamp},
            {size: 6, label: 'Created By', value: data.user[0].firstname},
        ]}
        /></div>}
        />
        <Card
            title={'Cash Book Transaction List Review'}
            subtitle={'Cash Book Transaction List'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <h5>{'Cash Book Transaction To Archive'}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.getTransactionItems(data.cashbook)}
                    </Col>
                </Row>
            </Container>}
        />
        </div>
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('CreditorsPaymentReviewx', data.context_data.data.data.data[0])
        data = data.context_data.data.data.data[0].step1

        //console.log('CreditorsPaymentReviewx step1', data)
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row>
        <Col xl={12}>
            {this.getConfirm(data)}
            </Col>
        </Row>
        </Container> 
    }

    render() {
        
        this.data = null
        try {
        } catch (error) {

        }
        
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.cashbookArchiveReview,
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashbookArchiveReview)