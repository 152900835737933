import moment from 'moment'
import validator from '../../validate'
import * as _ from 'lodash'

import * as appTypes from '../App/types'
import * as types from './types'
import * as readComponentActions from '../ReadComponent/actions'
import { getComponentTitle } from '../../helper';

export const loadComponent = (component, id, stop, surrogate) => {
    //console.log('getRelatedGrid loadComponent')
    return (dispatch, getState) => {

        if (!surrogate) {
            dispatch(getComponentSchema(component, id, stop))
            dispatch({
                type: types.FORM_LOAD_COMPONENT,
                payload: {
                    component: component,
                    data: getState().components[component].data
                }
            })
        }
        else {
            dispatch(getComponentData(component, id, surrogate))
        }
    }
}

export const saveComponentData = (component) => {
    const getData = () => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name]) {
                if (x.key) {
                    if (component.data[x.name] && component.data[x.name].input) {
                        obj[x.name] = component.data[x.name].input
                    }
                }
                else {
                    obj[x.name] = component.data[x.name] ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }
    
    const getValidation = () => {
        return component.component_field.reduce((obj, x) => {
            if (!component.data[x.name].validation.valid) {
                obj[x.name] = component.data[x.name].validation.message // !== '' ? component.data[x.name].input : null
            }
            return obj
        }, {})
    }

    return (dispatch, getState) => {
        dispatch(validateForm(component.name))

        const data = getData()
        let prompt_accept = getState().form.prompt_accept
        dispatch(redirectFine(false))
        if (getState().form.valid) 
        {
            let valid = data.finestatus_id === '1' && data.offender_id === '1' && data.offencelaw_id === '1' && data.person_id && !data.nominated
            if(component.name === 'finetransaction' && !prompt_accept && valid)
            {
                dispatch(redirectFineWin(true))

            }
            else
            {
                if(component.name === 'finetransaction' && prompt_accept && valid) {
                    dispatch({
                        type: types.GEN_COMPONENT_FINE_NOMINATION_FORM,
                        payload: {
                            type: 'GEN_COMPONENT_FINE_NOMINATION_FORM',
                            form: 'nomination',
                            data: data
                        }
                    })
                }

                dispatch({
                    type: types.SAVE_RELATED_FORM_COMPONENT_DATA,
                    payload: {
                        component: component,
                        data: data
                    }
                })
                
            }
        }
        else
        {
            for(let err of getState().form.errors){
                dispatch({
                    type: appTypes.SHOW_NOTIFICATION,
                    payload: {
                        type: 'danger',
                        title: 'System Notification',
                        additionalText: 'Field ' + err.errors[0].field + ' is ' +  err.errors[0].message,
                        overflowText: 'Action',
                        text: '',
                        autoHide: 10000,
                        timestamp: moment().format('h:mm A')
                    }
                })
            }
        }
    }
}

export const componentDataSaved = (component) => {
    return (dispatch) => {
        dispatch(readComponentActions.setReadComponentView('view', ''))
        // dispatch({
        //     type: types.COMPONENT_DATA_SAVED
        // })
        // todo
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: getComponentTitle(component) + ' successfully saved on ' + moment(new Date()).format('LLLL'),
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const componentDataSaveFailed = (component) => {
    return (dispatch) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Unable to save ' + getComponentTitle(component) + ' on ' + moment(new Date()).format('LLLL'),
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.COMPONENT_DATA_SAVE_FAILED
        })
    }
}

const validate = (schema, field, input) => {
    if (!schema[field.name]) {
        return {
            valid: true,
            errors: []
        }
    }

    const adhocSchema = {
        [field.name]: schema[field.name]
    }

    let adhocData = {}

    if (adhocSchema[field.name].filter(x => x.name === 'boolean').length) {
        adhocData = {
            [field.name]: input ? 'true' : 'false'
        }
    }
    else {
        adhocData = {
            [field.name]: input ? input.toString() : ''
        }
    }

    const result = validator().validate(adhocSchema, adhocData)

    return {
        ...result,
        message: result.errors.map(x => { return x.message }).join(', ')
    }
}

export const validateForm = (name) => {
    return (dispatch, getState) => {
        const component = getState().components[name]

        let valid = true
        let errors = []
        component.component_field.forEach((x) => {
            const result = validate(component.schema, x, component.data[x.name].input)

            if (!result.valid) {
                valid = false
                errors.push(result)
            }

            dispatch({
                type: types.SET_COMPONENT_RELATED_FIELD_INPUT,
                payload: {
                    component: component.name,
                    field: x.name,
                    input: x.component_field_display_type.name === 'toggle'
                        ? (component.data[x.name].input == 'undefined' || component.data[x.name].input == '' || component.data[x.name].input == 'null') ? false : component.data[x.name].input
                        : component.data[x.name].input,
                    validation: result
                }
            })
        })

        dispatch({
            type: types.SET_FORM_VALIDATION_RESULT,
            payload: {
                valid: valid,
                errors: errors
            }
        })
    }
}

export const resetForm = (component) => {
    return {
        type: types.RESET_FORM,
        payload: component
    }
}

export const getComponentSchema = (component, id, stop) => {
    return (dispatch, getState) => {
        
    //console.log('getRelatedGrid getComponentSchema related')
        if(getState().components[component]) {
        const pass = Object.keys(getState().components[component].schema).length
        !pass
            ? dispatch({
                type: types.GET_COMPONENT_RELATED_SCHEMA,
                payload: {
                    component: component,
                    id: id,
                    stop: stop
                }
            })
            : dispatch(getComponentRelatedRelationships(component, id, stop))
        }
    }
}

export const setComponentSchema = (component, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_RELATED_SCHEMA,
            payload: {
                component: component,
                schema: payload
            }
        })
    }
}

export const getComponentRelatedRelationships = (component, id, stop) => {
    //console.log('getRelatedGrid getComponentRelatedRelationships')
    return (dispatch, getState) => {
        const relatedFull = getState().components[component].relatedFull
        const pass = (relatedFull.hasMany || relatedFull.belongsTo)
        if (!stop) {
            !pass
                ? dispatch({
                    type: types.GET_COMPONENT_RELATED_RELATIONSHIPS,
                    payload: {
                        component: component,
                        id: id,
                        pass: pass
                    }
                })
                : dispatch(getComponentData(component, id))
        }
    }
}

export const setComponentRelationship = (component, payload) => {
    //console.log('getRelatedGrid setComponentRelationship')
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_RELATED_RELATIONSHIPS,
            payload: {
                component: component,
                relatedFull: payload
            }
        })
    }
}

export const redirectFine = (status) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_FINE_REDIRECT_STATUS,
            payload: status
        })
    }
}
export const redirectFineWin = (status) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_FINE_REDIRECT_WIN,
            payload: status
        })
    }
}

export const getTreeViewData = (field,datasource,value) => {
    return (dispatch) => {
        let data = {field: field,datasource: datasource,value:value,query: datasource + "/other", type: 'hierachy'}
        dispatch({
            type: types.GET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: data
        })
    }
}

export const getTreeData = (field, value) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_COMPONENT_TREE_SELECTION_DATA,
            payload: field.name + "/searchor?customer_id=" + value + "&" + field.name + "_id=" + value
        })
    }
}

export const setTreeViewData = (response, payload) => {
    return (dispatch) => {
        
        dispatch({
            type: types.SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: {
                prop: payload.field+"_data",
                data: response.hierachy
            }
        })
    }
}

export const downloadNominationForm = (data) => {
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())
    
    if(data.data.content) {
        saveByteArray([data.data.content.Body], data.data.document.filename)
    }

    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Fine redirect notmination downloading...',
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getComponentData = (name, id, surrogate) => {
    //console.log('getRelatedGrid getComponentData name', name)
    //console.log('getRelatedGrid getComponentData id', id)
    //console.log('getRelatedGrid getComponentData surrogate', surrogate)
    return (dispatch, getState) => {
        const component = getState().components[name]

        if (component && !isNaN(id) && id > 0) {
            const belongsTo = component.relatedFull.belongsTo
                ? component.relatedFull.belongsTo.map(x => {
                    return x.key
                })
                : []

            let hasMany = component.relatedFull.hasMany
                ? component.relatedFull.hasMany.filter(x => x.key.indexOf('document') > -1).map(x => {
                    return x.key
                })
                : []

            if (hasMany.length) {
                hasMany.forEach(x => {
                    hasMany = hasMany.concat(x + '.document')
                })
            }

            const relations = component.related === null ? [] : component.related.split(',')
            const related = _.uniq([...belongsTo, ...hasMany, ...relations])

            // const pk = !surrogate
            //     ? component.component_field.filter(x => x.key)[0].name
            //     : component.view_parameters
            //         ? component.view_parameters
            //         : component.component_field.filter(x => x.key)[0].name

            dispatch({
                type: types.GET_RELATED_FORM_COMPONENT_DATA,
                payload: {
                    component: component.name,
                    query: '?' + name + '_id=' + id,
                    id: id ? id : 0,
                    surrogate: surrogate
                }
            })
        }
    }
}

export const setComponentData = (name, data, id, surrogate) => {
    //console.log('getRelatedGrid setComponentData')
    const formatDate = (value) => {
        if (value === null) {
            return null
        }

        if (isNaN(Date.parse(value))) {
            const time = moment(value, 'HH:mm:ss', true)

            if (time.isValid()) {
                const date = moment().set({
                    'hour': time.get('hour'),
                    'minute': time.get('minute'),
                    'second': time.get('second')
                })

                return date.toDate();
            }

            return
        }

        return new Date(value)
    }

    const getInputValue = (field, data) => {
        if (!field.component_field_display_type) {
            return ''
        }

        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return data[field.name]
            case 'datePicker':
                return formatDate(data[field.name])
            case 'timePicker':
                return formatDate(data[field.name])
            case 'toggle':
                return (typeof data[field.name] != 'undefined' && data[field.name] != 'null') ? data[field.name] : false
            case 'hidden':
                return data[field.name] !== null ? data[field.name] : 0
            default:
                return data[field.name] === null ? '' : data[field.name]
        }
    }

    const getLookupData = (field, data) => {
        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                const key = field.name.replace('_id', '')
                const fields = field.display.split('|')
                const accessor = fields[0].split('.')[0]

                return [{
                    value: data[field.name] !== null ? data[field.name] : '',
                    text: fields.map(x => {
                        return x.split(',').map(y => {
                            return _.get(data[key], y.replace(accessor + '.', ''))
                        }).join(' ')
                    }).join(', ')
                }]
            default:
                return []
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[name]

        data = Object.keys(data).reduce((obj, x) => {
            const field = component.component_field.filter(y => y.name === x)[0]

            if (field) {

                const lookup = getLookupData(field, data)

                obj[x] = {
                    key: ''.concat('component_', component.name, '_field_', field.name, '_id_', id),
                    input: getInputValue(field, data),
                    data: lookup,
                    text: lookup.length ? lookup[0].text : '',
                    validation: {
                        valid: true,
                        errors: []
                    }
                }
            }
            else {
                obj[x] = data[x]
            }

            return obj
        }, {})
        
        //console.log('Form loadComponent FORM action3', data)
        dispatch({
            type: types.SET_COMPONENT_RELATED_DATA,
            payload: {
                name: name,
                data: data,
                surrogate: surrogate
            }
        })
    }
}


export const clearComponentData = (name, surrogate) => {
    return (dispatch, getState) => {
        const component = getState().components[name]

        let data = Object.keys(component.data).reduce((obj, x) => {
            const field = component.component_field.filter(y => y.name === x)[0]

            if (field) {
                obj[x] = {
                    key: ''.concat('component_', component.name, '_field_', field.name, '_id_', 0),
                    input: null,
                    data: [],
                    text: '',
                    validation: {
                        valid: true,
                        errors: []
                    }
                }
            }
            // else {
            //     obj[x] = data[x]
            // }

            return obj
        }, {})

        //console.log('Form loadComponent FORM action4', data)
        dispatch({
            type: types.SET_COMPONENT_RELATED_DATA,
            payload: {
                name: name,
                data: data,
                surrogate: surrogate
            }
        })
    }
}


export const setComponentFieldInput = (field, input) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = format(field, input)
        dispatch({
            type: types.SET_RELATED_FORM_COMPONENT_FIELD_INPUT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                validation: validate(component.schema, field, value)
            }
        })
    }
}

export const setComponentFieldInputAndText = (field, input, text) => {
    //console.log('setComponentFieldInputAndText', input, text)
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = format(field, input)
        dispatch({
            type: types.SET_COMPONENT_RELATED_FIELD_INPUT_AND_TEXT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                text: text,
                validation: validate(component.schema, field, value)
            }
        })
    }
}

export const getComponentFieldLookupData = (field, searchText, dataSource, params) => {
    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const payload = {
            component: component,
            field: field,
            related: '',
            query: searchText.length > 0 ? '?' + field.search + '=' + searchText : '',
            text: searchText
        }

        dispatch({
            type: types.GET_COMPONENT_RELATED_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return _.get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_RELATED_FIELD_LOOKUP_DATA,
            payload: {
                component: component.name,
                field: field.name,
                data: data
            }
        })
    }
}

export const getComponentFieldLookupDataContact = (field, searchText, id) => {
    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const payload = {
            component: component,
            field: field,
            related: '',
            query: '?customer_id='+id+'&'+ field.search + '=' + searchText,
            text: searchText
        }

        dispatch({
            type: types.GET_COMPONENT_CONTACT_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setComponentFieldLookupDataContact = (component, field, payload) => {
    const data = payload.data.map(x => {
        return {
            value: x.contact.contact_id,
            text: x.contact.fullname
        }
    })
    //console.log('setComponentFieldLookupDataContact',payload.data)
    //console.log('setComponentFieldLookupDataContact',data)
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_RELATED_FIELD_LOOKUP_DATA,
            payload: {
                component: component.name,
                field: field.name,
                data: data
            }
        })
    }
}