import * as types from './types'
import * as props from './props'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_WORKQUEUE_COMPONENT_INPUT_VALUE:
            return {
                ...state,
                speeddial: action.payload.value,
                speeddialEl: action.payload.el
            }
        case types.SET_WORKQUEUE_FORM_DATA:
            return {
                ...state,
                formdata: action.payload,
            }
        case types.SET_WORKQUEUE_ITEM_DATA:
            return {
                ...state,
                formitem: action.payload,
            }
        case types.SET_WORKQUEUE_USER_LIST:
            return {
                ...state,
                users: action.payload,
            }
        case types.SET_WORKQUEUE_DATA:
            return {
                ...state,
                workqueue: action.payload
            }
        case types.SET_WORKQUEUE_PROP_INPUT_VALUE:
            //console.log('SET_WORKQUEUE_PROP_INPUT_VALUE', action.payload)
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_WORKQUEUE_COMPONENT_GRID_FILTER:
            return {
                ...state,
                grid_filter: action.payload
            }
        default:
            return state
    }
}