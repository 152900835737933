import * as types from './types'
import * as headerTypes from '../types'
import moment from 'moment'
import * as appTypes from '../../App/types'
import * as headerAction from '../actions'
import _ from 'lodash'

export const setView = (data) => {
    return (dispatch, getState) => {
        //console.log('setView', data)
        dispatch({
            type: headerTypes.SET_STR_COMPONENT_VIEW,
            payload: data
        })
    }
}

export const updRentalDetails = (props, value) => {
    return (dispatch, getState) => {
        let restructure_veh = getState().workflowGanttChart.restructure_veh
        restructure_veh[props] = value
        restructure_veh['finance'] = restructure_veh['monthly_excl']
        restructure_veh['monthly_incl'] = (parseFloat(restructure_veh['monthly_excl']) * 1.15)
        restructure_veh['monthly_vat'] = (parseFloat(restructure_veh['monthly_incl']) * 0.15)
        restructure_veh['terminationdate'] = moment(restructure_veh.startdate).add(restructure_veh['period'], 'months').format('YYYY-MM-DD')
        dispatch({
            type: types.SET_STR_COMPONENT_RESTRUCTURE_DATA,
            payload: restructure_veh
        })
    }
}

export const setSubView = (data) => {
    return (dispatch, getState) => {
        //console.log('setView', data)
        dispatch({
            type: types.SET_STR_COMPONENT_SUB_VIEW,
            payload: data
        })
    }
}

export const resetForm = (data) => {
    return (dispatch, getState) => {
        //console.log('setView', data)
        dispatch({
            type: headerTypes.SET_STR_COMPONENT_VIEW,
            payload: data
        })
    }
}

export const saveReteCard = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_STR_COMPONENT_RATECARD,
            payload: {
                rate_category: getState().workflowGanttChart.ratecard,
                vehicle_make: getState().workflowGanttChart.vehicle_make,
                vehicle_description: getState().workflowGanttChart.vehicle_model,
                vehicle_mmcode: getState().workflowGanttChart.mmcode,
                client_id: 2
            }
        })
    }
}

export const saveReteCardDone = () => {
    return (dispatch, getState) => {
        dispatch(headerAction.getDashboard())
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                type: 'success',
                title: 'System Notification',
                additionalText: 'Rete card successfully saved',
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
