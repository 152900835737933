export const po_filepreview = ''
export const po_filename = ''
export const sign_filepreview = ''
export const sign_filename = ''
export const comments = ''
export const bookingdate = null
export const documents = []

export const po_date = ''
export const po_number = ''
export const po_amount = 0.00
export const customer_po = true
export const rebill_dispute = false
export const rebill_dispute_comments = ''
export const rebill_dispute_date = null

export const rebill_amount = 0.00
export const issupplier_correct = false

export const requested_termination_date = null
export const requested_termination_odo = null
export const requested_termination_comments = null

export const fields = {
    requested_termination_odo: {
      errorText: 'Please enter Termination Request Odo',
      state: 'getState()?.defleetAcceptRebill?.requested_termination_odo',
      valid: false,
      validations: [
        {
          schema: 'integer',
          required: true,
          maxLength: null,
          errorText: 'Please input a valid Termination Request Odo'
        },
        {
          schema: 'greaterThanZero',
          required: true,
          maxLength: null,
          errorText: 'Please input a valid Termination Request Odo greater than zero'
        }
      ]
    },
  
    requested_termination_date: {
      errorText: 'Please select the requested Termination Date',
      state: 'getState()?.defleetAcceptRebill?.requested_termination_date',
      valid: false,
    },
  
    amounts_match: {
      errorText: 'Rebill and PO Amounts do not match',
      state: 
        'Number(getState()?.defleetAcceptRebill?.po_amount) !== ' +
        'Number(getState()?.defleetAcceptRebill?.rebill_amount) &&' +
        '!getState()?.defleetAcceptRebill?.dispute_rebill',
      valid: false,
    },
  
    po_amount: {
      errorText: 'Please enter PO Amount',
      state: 'getState()?.defleetAcceptRebill?.po_amount',
      valid: false,
      validations: [
        {
          schema: 'decimal',
          required: true,
          maxLength: null,
          errorText: 'Please input a valid PO Amount'
        },
        {
          schema: 'greaterThanZero',
          required: true,
          maxLength: null,
          errorText: 'Please input a valid PO Amount greater than zero'
        }
      ]
    },
  
    po_number: {
      errorText: 'Please enter PO Number',
      state: 'getState()?.defleetAcceptRebill?.po_number',
      valid: false
    },
  
    po_date: {
      errorText: 'Please enter PO Date',
      state: 'getState()?.defleetAcceptRebill?.po_date',
      valid: false
    },
  
    customer_po: {
      errorText: 'Please enter Customer PO',
      state: 'getState()?.defleetAcceptRebill?.customer_po',
      valid: false
    },
  
    issupplier_correct: {
      errorText: 'Please confirm if the supplier is correct',
      state: 'getState()?.defleetAcceptRebill?.issupplier_correct',
      valid: false
    },
  
    rebill_dispute_comments: {
      errorText: 'Please enter Dispute Comments',
      state: 'getState()?.defleetAcceptRebill?.dispute_rebill' + 
             '? getState()?.defleetAcceptRebill?.rebill_dispute_comments ' +
            ': true',
      valid: false
    },
  
    rebill_dispute_date: {
      errorText: 'Please enter Dispute Date',
      state: 'getState()?.defleetAcceptRebill?.dispute_rebill' + 
             '? getState()?.defleetAcceptRebill?.rebill_dispute_date' +
             ': true',
      valid: false
    },
  
    documents: {
      errorText: 'Please add a document and select document type',
      data: false,
      valid: false,
      empty: true,
      popup: true,
      type: 'documents'
    },
  }
  
 