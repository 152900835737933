import Button from '@mui/material/Button'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import ReactTable from "react-table"
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as confirmactions from '../SUPPLIERPAYMENTCONFIRMATION/actions'
import * as styles from './styles'
import Card from '../../../controls/card'
import TextField from '@mui/material/TextField'
import ViewSupplier from '../../../controls/view_supplier'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import Recon from '../../../components/JSReport/component'
import Requisition from '../../../components/JSReport/component'
import Remittance from '../../../components/JSReport/component'
import * as _ from 'lodash'
let supplier_id = 0

class SUPPLIERPAYMENTCONFIRMATION extends Component {
    componentDidMount() {
        this.actions = this.props.actions;
        const meta = [
            {
                header: true,
                title: 'Supplier',
                description: 'Supplier',
                meta_data: this.props.state.loaded.selected_supplier.text
            },
            {
                title: 'Payment',
                description: 'Payment',
                meta_data: this.props.state.loaded.total_payable
            },
        ]
        const data = {
            ...this.props.state,
            datestamp: new Date(),
            //documents: this.props.state.upload.documents
        }
        //console.log("Check Object", data)
        this.props.actions.setupWorkflowTaskInput(data, meta, 'payment', 'tsk_payment_loading')
    }
    getGrid() {
        return <AdhocGrid
            key={'key_adhoc_grid_payment_confirmation'}
            name={'payment_grid'}
            uri={'/components/supplierpaymentconfirmation/other'}
            filterable={true}
            sortable={true}
            body={{
                type: 'get_completed_pos',
                user_id: this.props.state.user
            }}
            search={''}
            columns={[
                { accessor: 'supplier', Header: 'Supplier', filterable: false, width: 300 },
                { accessor: 'accountnumber', Header: 'Account No', width: 150, filterable: false },
                { accessor: 'suppliertype', Header: 'Supplier Type', width: 150, filterable: false },
                { accessor: 'payment', Header: 'Payment', width: 150, filterable: false },
                { accessor: 'nextapproval', Header: 'Next Approval', width: 200, filterable: false },
                {
                    accessor: 'supplier_id', Header: 'Documents', filterable: false, width: 350, Cell: row => {
                        return <div>
                            < Button className={"global_button"} variant="contained"
                                label="Requisition"
                                width="10%"
                                primary={true}
                                /* icon={<Icon iclass={'material-icons'} iname={'file'} />} */
                                onClick={(event) => {
                                    let data = {
                                        obj: row.original,
                                        document: 'Requisition',
                                        selectedBusinessUnit: this.props.state.selectedBusinessUnit,
                                        others: this.props.state.others,
                                        credits: this.props.state.credits,
                                        payments: this.props.state.payments,
                                        user: this.props.state.user
                                    }
                                    this.props.actions.getDocument(data)

                                }}
                            >{window.t.en('Requisition')}</Button>{'      '}
                            < Button className={"global_button"} variant="contained"
                                label="Recon"
                                width="10%"
                                primary={true}
                                /* icon={<Icon iclass={'material-icons'} iname={'file'} />} */
                                onClick={(event) => {
                                    let data = {
                                        obj: row.original,
                                        document: 'Recon',
                                        selectedBusinessUnit: this.props.state.selectedBusinessUnit,
                                        others: this.props.state.others,
                                        credits: this.props.state.credits,
                                        payments: this.props.state.payments,
                                        user: this.props.state.user
                                    }
                                    this.props.actions.getDocument(data)

                                }}
                            >{window.t.en('Recon')}</Button>{'      '}
                            < Button className={"global_button"} variant="contained"
                                label="Remittance"
                                width="10%"
                                primary={true}
                                /* icon={<Icon iclass={'material-icons'} iname={'file'} />} */
                                onClick={(event) => {
                                    let data = {
                                        obj: row.original,
                                        document: 'Remittance',
                                        selectedBusinessUnit: this.props.state.selectedBusinessUnit,
                                        others: this.props.state.others,
                                        credits: this.props.state.credits,
                                        payments: this.props.state.payments,
                                        user: this.props.state.user
                                    }
                                    this.props.actions.getDocument(data)
                                }}
                            >{window.t.en('Remittance')}</Button>
                        </div>
                    }
                }
            ]}
            paging={{
                page: 0,
                pageSize: 10,
                pages: 0,
                count: 0
            }}

            subComponent={row => {
                return <div key={'grid_payment_data_' + row.original['supplier_id']} style={{ padding: "2px" }}>
                    <Card
                        title={window.t.en('Invoices')}
                        subtitle={window.t.en('List of Invoices for Payment')}
                        content={<Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                            <ReactTable
                                manual
                                key={'key_grid_payment_details'}
                                name={'payment_grid_details'}
                                className={'-striped -highlight'}
                                data={row.original['items']}
                                columns={[
                                    { accessor: 'authnumber', Header: 'Auth Number', filterable: true, width: 100 },
                                    {
                                        accessor: 'date', Header: 'Date', width: 100, filterable: true, Cell: row => {
                                            return moment(row.original['date']).format("YYYY-MM-DD")
                                        }
                                    },
                                    { accessor: 'type', Header: 'Type', width: 100, filterable: true },
                                    { accessor: 'reference', Header: 'Reference', width: 100, filterable: true },
                                    { accessor: 'description', Header: 'Description', width: 300, filterable: true },
                                    { accessor: 'amount', Header: 'Amount', width: 100, filterable: true },
                                    { accessor: 'vat', Header: 'VAT', width: 100, filterable: true },
                                    { accessor: 'payment', Header: 'Payment', width: 100, filterable: true },
                                    { accessor: 'credit', Header: 'Credit', width: 100, filterable: true },
                                    { accessor: 'shortpay', Header: 'Short Pay', width: 100, filterable: true },


                                ]}
                                defaultPageSize={10}
                                filterable={false}
                                loading={false}
                                minRows={1}
                                //pageSize={grid.paging ? grid.paging.pageSize : 10}
                                pages={1}
                                showPageJump={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                style={{
                                    height: '100%'
                                }}
                                SubComponent={(x, i) => {
                                    return <div style={{ padding: "2px" }}>
                                        <Card
                                            title={window.t.en('Related Documents')}
                                            subtitle={window.t.en('List of invoice related documents')}
                                            content={<Container style={styles.whitediv}>
                                                <Row><Col xl={12}><br></br></Col></Row>
                                                {x.original['documents'].length ?
                                                    x.original['documents'].map((x, i) => {
                                                        return <div><Row><Col xl={1}></Col>
                                                            <Col xl={11}>
                                                                < Button className={"global_button"} variant="contained"
                                                                    key={'add_more_button'}
                                                                    label={x.description ? x.description : x.filename}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        this.props.actions.getSupplierTransactionDocument(x.filename)
                                                                    }}
                                                                >{x.description ? x.description : x.filename}</Button></Col>
                                                        </Row>
                                                            <Row><Col xl={12}><br></br></Col></Row>
                                                        </div>
                                                    })
                                                    :
                                                    <Row><Col xl={1}>{''}</Col></Row>
                                                }
                                            </Container>
                                            } />
                                    </div>
                                }}>
                            </ReactTable>
                        </Container>
                        } /></div>
            }}
        />
    }

    SupplierLatter(data, supplierage) {
        //console.log('SupplierLatter')
        //console.log(data)
        return data
            ? <Card
                title={window.t.en('Supplier Details')}
                subtitle={window.t.en('Supplier details.')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <div>{
                                data
                                    ?
                                    <div>
                                        <table style={styles.box}>
                                            <tr>
                                                <td style={styles.tdField}>{'BBE Level:'}</td>
                                                <td style={styles.tdValue}>{data.beelevel}</td>
                                                <td style={styles.tdField}>{' '}</td>
                                                <td style={styles.tdField}>{'BBE Score:'}</td>
                                                <td style={styles.tdValue}>{data.beescore}</td>
                                            </tr>
                                            <tr style={styles.trField}>
                                                <td style={styles.trField} colspan="5">{''}</td>
                                            </tr>
                                            <tr>
                                                <td style={styles.tdField}>{'BEE Cer Expry:'}</td>
                                                <td style={styles.tdValue}>{data.beecertificateexpdate ? data.beecertificateexpdate.split('T')[0] : 'Not Set'}</td>
                                                <td style={styles.tdField}>{' '}</td>
                                                <td style={styles.tdField}>{'BBE Type:'}</td>
                                                <td style={styles.tdValue}>{data.beetype}</td>
                                            </tr>
                                            {
                                                supplierage
                                                    ? <tbody>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.trField} colspan="5">{''}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.tdField}>{'Current:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.agedtotal0 ? parseFloat(supplierage.agedtotal0).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                            <td style={styles.tdField}>{' '}</td>
                                                            <td style={styles.tdField}>{'Purchase MTD:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.purchasesmtd ? parseFloat(supplierage.purchasesmtd).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.trField} colspan="5">{''}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.tdField}>{'30 Days:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.agedtotal30 ? parseFloat(supplierage.agedtotal30).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                            <td style={styles.tdField}>{' '}</td>
                                                            <td style={styles.tdField}>{'Purchase YTD:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.purchasesytd ? parseFloat(supplierage.purchasesytd).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.trField} colspan="5">{''}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.tdField}>{'60 Days:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.agedtotal60 ? parseFloat(supplierage.agedtotal60).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                            <td style={styles.tdField}>{' '}</td>
                                                            <td style={styles.tdField}>{'Purchase LTD:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.purchasesltd ? parseFloat(supplierage.purchasesltd).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.trField} colspan="5">{''}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.tdField}>{'90 Days:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.agedtotal90 ? parseFloat(supplierage.agedtotal90).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                            <td style={styles.tdField}>{' '}</td>
                                                            <td style={styles.tdField}>{'Total:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.agedtotal ? parseFloat(supplierage.agedtotal).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.trField} colspan="5">{''}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.tdField}>{'120 Days:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.agedtotal120 ? parseFloat(supplierage.agedtotal120).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                            <td style={styles.tdField}>{' '}</td>
                                                            <td style={styles.tdField}>{'Unallocated:'}</td>
                                                            <td style={styles.tdValue}>{supplierage.agedtotalunallocated ? parseFloat(supplierage.agedtotalunallocated).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                        </tr>
                                                        <tr style={styles.trField}>
                                                            <td style={styles.trField} colspan="5">{''}</td>
                                                        </tr>
                                                    </tbody>
                                                    : ''
                                            }

                                        </table>
                                        {
                                            supplierage
                                                ? <table style={styles.box1}><tbody>
                                                    <tr style={styles.trField}>
                                                        <td style={styles.tdField}>{'Spend Limit:'}</td>
                                                        <td style={styles.tdValue}>{supplierage.suplierspendlimit ? parseFloat(supplierage.suplierspendlimit).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                        <td style={styles.tdField}>{' '}</td>
                                                        <td style={styles.tdField}>{'Supplier Usage:'}</td>
                                                        <td style={styles.tdValue}>{supplierage.suplierusage ? parseFloat(supplierage.suplierusage).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }</div>
                        </Col>
                    </Row>
                </Container>} />
            : 'No Supplier Found'
    }

    getSupplierDetails() {
        return <div>{this.props.state.loaded.payment_list.length
            ? this.props.state.loaded.payment_list
                .map((x, i) => {
                    return <div>
                        <div key={'payment_data_' + x['supplier_id']} style={styles.row(i, true)}>
                            <div style={styles.column(120, false)}>
                                <strong>{'Amount: '}</strong> {parseFloat((x.amount ? x.amount : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                <strong>{'Vat: '}</strong> {parseFloat((x.vat ? x.vat : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                <strong>{'Total: '}</strong> {parseFloat((x.payment ? x.payment : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                <br />
                            </div>
                            <div style={styles.column(180, false)}>
                                <strong>{'Statement Amount: '}</strong> {parseFloat((x.statement ? x.statement : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                <strong>{'Amount Not Paying: '}</strong> {parseFloat((x.dontpay ? x.dontpay : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                <strong>{'Payments Not Credited: '}</strong> {parseFloat((x.amountpaid ? x.amountpaid : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                <br />
                                <br />
                            </div>
                            <div style={styles.column(200, false)}>
                                <strong>{'Settlement Dispute: '}</strong> {parseFloat((x.settlement ? x.settlement : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                <strong>{'Discount %: '}</strong> {parseFloat((x.discount ? x.discount : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                <strong>{'Total Payment: '}</strong> {parseFloat((x.payments ? x.payments : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                <br />
                            </div>
                            <div style={styles.column(300, true)}>
                                < Button className={"global_button"} variant="contained"
                                    label="Requisition"
                                    width="10%"
                                    primary={true}
                                    disabled={Number(x.statement) && Number(x.payments) ? false : true}
                                    /* icon={<Icon iclass={'material-icons'} iname={'file'} />} */
                                    onClick={() => {
                                        let data = {
                                            obj: x,
                                            document: 'Requisition',
                                            selectedBusinessUnit: this.props.state.selectedBusinessUnit,
                                            others: this.props.state.others,
                                            credits: this.props.state.credits,
                                            payments: this.props.state.payments,
                                            user: this.props.state.user
                                        }
                                        if (this.props.state.selectedBusinessUnit) {
                                            this.props.actions.getDocument(data)
                                        } else {
                                            this.props.actions.validateAccountSelection(data)
                                        }
                                    }}
                                >{window.t.en('Requisition')}</Button>{'      '}
                                < Button className={"global_button"} variant="contained"
                                    label="Recon"
                                    width="10%"
                                    primary={true}
                                    disabled={Number(x.statement) && Number(x.payments) ? false : true}
                                    /* icon={<Icon iclass={'material-icons'} iname={'file'} />} */
                                    onClick={() => {
                                        let data = {
                                            obj: x,
                                            document: 'Recon',
                                            selectedBusinessUnit: this.props.state.selectedBusinessUnit,
                                            others: this.props.state.others,
                                            credits: this.props.state.credits,
                                            payments: this.props.state.payments,
                                            user: this.props.state.user
                                        }
                                        if (this.props.state.selectedBusinessUnit) {
                                            this.props.actions.getDocument(data)
                                        } else {
                                            this.props.actions.validateAccountSelection()
                                        }
                                    }}
                                >{window.t.en('Recon')}</Button>{'      '}
                                < Button className={"global_button"} variant="contained"
                                    label="Remittance"
                                    width="10%"
                                    primary={true}
                                    disabled={Number(x.statement) && Number(x.payments) ? false : true}
                                    /* icon={<Icon iclass={'material-icons'} iname={'file'} />} */
                                    onClick={() => {
                                        let data = {
                                            obj: x,
                                            document: 'Remittance',
                                            selectedBusinessUnit: this.props.state.selectedBusinessUnit,
                                            others: this.props.state.others,
                                            credits: this.props.state.credits,
                                            payments: this.props.state.payments,
                                            user: this.props.state.user
                                        }
                                        if (this.props.state.selectedBusinessUnit) {
                                            this.props.actions.getDocument(data)
                                        } else {
                                            this.props.actions.validateAccountSelection()
                                        }
                                    }}
                                >{window.t.en('Remittance')}</Button></div>
                            <div style={styles.column(50, true)}>
                                < Button className={"global_button"} variant="contained"
                                    icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'forward'} />}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        //this.props.actions.getItemDetails(i, 'showdetails', this.props.datashowdetails)
                                        this.props.actions.toggleItemProps(i)

                                    }}
                                ><Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'forward'} /></Button></div>
                        </div>
                    </div>
                })
            : ''
        }</div>
    }
    getLayout() {
        this.actions = this.props.actions;
        return <div>
            <ViewSupplier key={this.props.state.loaded.selected_supplier.value} id={this.props.state.loaded.selected_supplier.value} />
            {this.getPaymentDetails()}
            {this.getUploadedDocuments()}
            {this.props.state.loaded.payment_list.length ? this.getRecon() : ''}
            {this.props.state.loaded.payment_list.length ? this.getRequisition() : ''}
            {this.props.state.loaded.payment_list.length ? this.getRemittance() : ''}
            {this.commentsView()}
        </div>
    }
    commentsView() {
        return <Card
            title={'Payment Comments'}
            subtitle={'Enter comments for payment'}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomments'}
                            label={'Enter comments...'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={this.props.state.comments}
                            onChange={(event) => {
                                let value = event.target.value
                                this.props.actions.setInputValue('comments', value)
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    getPaymentDetails() {
        return <Card
            title={window.t.en('Payment Details')}
            description={window.t.en('Supplier Payment Details')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Business Account:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{this.props.state.loaded.selectedBusinessUnit.text}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Statement Date:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{this.props.state.loaded.statement_date ? moment(this.props.state.loaded.statement_date).format("YYYY-MM-DD") : ''}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Payment Date:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{this.props.state.loaded.payment_date ? moment(this.props.state.loaded.payment_date).format("YYYY-MM-DD") : ''}</Col>
                </Row>
            </Container>} />
    }
    getUploadedDocuments() {
        return <Card
            title={window.t.en('Uploaded Documents')}
            description={window.t.en('Uploded supporting documents')}
            content={<div>{
                this.props.state.upload.documents
                    ? <PdfViewer
                        key={'document_viewer_uploaded_quotes'}
                        name={'Payment'}
                        workflow={'payment'}
                        task={'tsk_defleet_upload_quotes'}
                        documents={this.props.state.upload.documents}
                        edit={false}
                        workflow_queue_data={null}
                    />
                    : <Loading message={'Retrieving Document...'} />}</div>} />
    }
    getRecon() {
        return <Card
            title={window.t.en('Payment Recon')}
            subtitle={window.t.en('Payment reconciliation document')}
            key={'payment_recon'}
            id={'payment_recon'}
            content={<Recon id={'payment_recon'} key={'payment_recon'} shortid={'payment_recon'} data={this.props.state.loaded.recon_data} />} />
    }
    getRequisition() {
        return <Card
            title={window.t.en('Payment Requisition')}
            subtitle={window.t.en('Payment requisition document')}
            key={'payment_requisition'}
            id={'payment_requisition'}
            content={<Requisition id={'payment_requisition'} key={'payment_requisition'} shortid={'payment_requisition'} data={this.props.state.loaded.requisition_data} />} />
    }
    getRemittance() {
        return <Card
            title={window.t.en('Payment Remittance')}
            subtitle={window.t.en('Payment remittance document')}
            key={'payment_remittance'}
            id={'payment_remittance'}
            content={<Remittance id={'payment_remittance'} key={'payment_remittance'} shortid={'payment_remittance'} data={this.props.state.loaded.remittance_data} />} />
    }

    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.supplierpaymentconfirmation,
            loaded: {
                ...state.supplierpaymentmanagement,
            },
            user: {
                ...state.user
            },
            upload: {
                ...state.upload
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...confirmactions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERPAYMENTCONFIRMATION)