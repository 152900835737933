import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getAllCreditNotes = (search) => {
    return {
        type: types.GET_CREDIT_NOTE_LIST,
        payload: {
            search_text: search
        }
    }
}
export const setCreditNoteList = (payload) => {
    let data = []
    var items = [{description: '',account_text: '',account_id: 0,amount: 0,vat: 0,total: 0,key: 1}]
    data = payload.data.map(x => {
        if (x.suppliertransactionitem) {
            items = x.suppliertransactionitem.map((i, inx) => {
                return {
                    description: i.description,
                    account_text: i.itemname,// fktext removed
                    account_id: i.accountingitem_id,
                    amount: i.nettvalue,
                    vat: i.vat,
                    total: i.credit,
                    key: Number(inx) + 1,
                }
            })
        }
        return {
            text: x.supplier_ + ' | ' + x.reference ,
            value: x.reference,

            id: x.suppliertransaction_id,
            supplier_id: x.supplier_id,
            supplier_text: x.suppliername,// fktext removed
            suppliertransactiondate: x.suppliertransactiondate,
            suppliertransactionmonth: x.suppliertransactionmonth,
            supplierreference: x.reference,
            description: x.description,
            business_id: x.business_id,
            business_text: x.businessname,// fktext removed
            suppliertransactionitem: items
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'suppliercreditnote',
            field: 'reference'
        }
    }
}

export const getCreditorsListData  = (search) => {
    return {
        type: types.GET_CREDITORS_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getUnitMeasureListData = (search) => {
    return {
        type: types.GET_UNIT_MEASURE_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getBusinessListData = (search) => {
    return {
        type: types.GET_BUSINESS_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getContractAccountListData = (search) => {
    return {
        type: types.GET_CONTRA_ACCOUNT_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getItemAccountListData = (search) => {
    return {
        type: types.GET_ITEM_ACCOUNT_LIST,
        payload: {
            search_text: search,
        }
    }
}

export const setSupplierList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.suppliername + ' | ' + x.accountnumber ,
            value: x.supplier_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'suppliercreditnote',
            field: 'supplier_id'
        }
    }
}

export const setBusinessList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.businessname,
            value: x.business_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'suppliercreditnote',
            field: 'business_id'

        }
    }
}
export const setContraAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_DATA_LIST,
            payload: {
                data: data,
                component: 'suppliercreditnote',
                field: 'accountingitem_id'
            }
        })
    }
}

export const setItemAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'suppliercreditnote',
            field: 'accountingitem_id'
        }
    }
}
export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field,
            component: 'suppliercreditnote'
        }
    }
}


export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        const format = (field, input) => {
            const definition = getState().components.suppliercreditnote.component_field.filter(x => x.name === field)[0]

            if (definition) {
                switch (definition.component_field_display_type.name) {
                    case 'datePicker':
                        input = input.toString().trim()
                        const now = moment(new Date())
                        return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
                    case 'timePicker':
                        input = input.toString().trim()
                        return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
                    default:
                        return input
                }
            }
            else {
                return input
            }
        }

        dispatch({
            type: types.SET_ANY_FIELD_INPUT_VALUE,
            payload: {
                    value: format(field, value),
                    prop: field,
                    component: 'suppliercreditnote'
            }
        })
    }
}

export const setUnitMeasureList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.measurename,
            value: x.unitmeasure_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'suppliercreditnote',
            field: 'description'
        }
    }
}

export const saveData = (payload, validatefields,items) => {
    window.glyco.log("Test")
    var valid = true
    var text = ''
    for (var i = 0; i < validatefields.length; i++) {
        if (!validatefields[i].value) { valid = false; text += validatefields[i].field + " " }
    }
    for (var item = 0; item < items.length; item++) {
        if (!items[item].account_id) { valid = false; text =  " Check Line Items " }
    }
    
    if (!valid) {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'You missed a couple of fields [' + text + ']...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return {
            type: types.SAVE_CREDIT_NOTE_DATA,
            payload: {
                data: payload
            }
        }
    }
}
export const savedData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_CREDIT_NOTE_DATA
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Credit Note Capturing Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}



export const setItems = (data) => {
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'suppliercreditnote',
            field: 'suppliertransaction_id'
        }
    }
}
export const addBlankRow = () => {
    return (dispatch, getState) => {
        let data = getState().components.suppliercreditnote.data.suppliertransaction_id.data
        const payload = {
            data: data.concat({
                description: '',
                unit: '',
                quantity: 0,
                unitprice: 0,
                amount: 0,
                vat: 0,
                total: 0,
                key: data.length + 1,
                account_id: 0
            }),
            component: 'suppliercreditnote',
            field: 'suppliertransaction_id'
        }
        dispatch({
            type: types.ADD_ANY_BLANK_ROW,
            payload: payload
        })
    }
}

export const removeRow = (inx) => {
    return (dispatch, getState) => {
        let data = getState().components.suppliercreditnote.data.suppliertransaction_id.data.filter((x, i) => i !== (inx))

        dispatch({
            type: types.REMOVE_ANY_ITEM_ROW,
            payload: {
                data: data,
                component: 'suppliercreditnote',
                field: 'suppliertransaction_id'
            }
        })
    }
}

export const invalidEntry = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Please capture Reference and Line items...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const setItemValue = (payload) => {
    return (dispatch, getState) => {
        let data = getState().components.suppliercreditnote.data.suppliertransaction_id.data
        data[payload.inx][payload.field] = payload.value
        
        dispatch({
            type: types.SET_ANY_ITEM_INPUT_VALUE,
            payload: {
                data: data,
                component: 'suppliercreditnote',
                field: 'suppliertransaction_id'
            }
        })
    }
}
export const setDuplicateReferenceCheck = (payload) => {
    if (payload.data.length > 0) {
        return (dispatch, getState) => {
            dispatch({
                type: types.CN_DUPLICATE_FOUND,
                payload: {
                    component: 'suppliercreditnote'
                }
            })

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'The Reference Number already Exist!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else
    {
        return (dispatch, getState) => {
            dispatch({
                type: types.NO_CN_DUPLICATE_FOUND,
                payload: {
                    component: 'suppliercreditnote'
                }
            })
        }
    }
}
export const getDuplicateReferenceCheck = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CN_DUPLICATE_REFERENCE_CHECK,
            payload: {
                type: "GET_CN_DUPLICATE_REFERENCE_CHECK",
                reference: reference
            }
        })
    }
}

export const foundDuplicateReference = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'The Reference Number already Exist!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const getCreditNotePDF = (id) => {
    return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Generating Document...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })          
        
        dispatch({
            type: types.GEN_SUPPLIER_CREDIT_NOTE_PDF,
            payload: {
                type: "GEN_SUPPLIER_CREDIT_NOTE_PDF",
                id: id
            }
        })
    }
}

export const setSupplierCreditNotePDF = (data) => {
    window.glyco.log('setSupplierCreditNotePDF')
    window.glyco.log(data)

    let link = ''
   
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })          
    }
}

