export const loading = true
export let description = ''
export let category = ''
export let mmcode = ''
export let registrationdate = ''
export let yearmodel = ''
export let age = ''
export let tradevalue = ''
export let retailvalue = ''
export let purchasedate = ''
export let purchaseprice = ''
export let financetype = ''
export const currentTab = 0