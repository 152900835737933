import * as props from './props'
import * as types from './types'



export default (state = props, action) => {
    switch (action.type) {

        case types.SET_VEHICLES_ACTION:
        return {
            ...state,
            vehicles: action.payload
        }

        case types.SET_CUSTOMER_ACTION:
        return {
            ...state,
            customers: action.payload
        }

        case types.SET_VEHICLE_CUSTOMER_VALUE:
        return {
            ...state,
            selectedCustomerName: action.payload.selectedCustomerName,
            selectedCustomer: action.payload.selectedCustomer
        }

        case types.SET_RENEWAL_DETAIL:
        return {
            ...state,
        
            vehicleDescription :action.payload.makemodelyear,
            vehicleType :action.payload.khulavehicletype,
            vehicleStatusCat :action.payload.statuscategory,
            licenceDiscNumber  :action.payload.licensediscno,
            expirydate :action.payload.licenceexpirydate,
            licencedispatchdate  :action.payload.licencedispatchdate,
            transactionfee  :action.payload.licencetransfee,
            licencefee  :action.payload.licenceamount,
            penaltyfee :action.payload.licencepeniltyamount,
            coffee  :action.payload.cofamount,
            adminfee  :action.payload.adminfee,
            poNumber:action.payload.licenceponumber,
            odometer: action.payload.closingodometer,
            vehicle_id:action.payload.vehicle_id,
            customer_id:action.payload.customer_id,
            licenceauthority_id:action.payload.licenceauthority_id,
            vehicle_fktext:action.payload.vehicle_fktext,
            licenceauthority_fktext:action.payload.licenceauthority,
            client_id: action.payload.client_id
            

           
        }

        case types.SET_CALCULATED_TOTAL:
        return {
            ...state,
            totalamount: (action.payload.totalamount +  action.payload.value).toFixed(2)
            

           
        }

        case types.SET_NEW_CUSTOMER_VALUE:
        return {
            ...state,
            newCustomer : action.payload,
        }

        
        case types.SET_DATE_FIELD_INPUT_VALUE:
        return {
            ...state,
            effectdate : action.payload,
          
        }


        case types.SET_LICENCERENEWAL_FIELD_VALUE:
        return {
            ...state,
            [action.payload.prop]: action.payload.value
        }

        default:
            return state
    }
}