import * as types from './types'
import moment from 'moment'

export const setInputValue = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_FML_QUOTATION_DELIVERY_PO_INPUT_VALUE,
            payload: data
        })
    }
}

export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_FML_QUOTATION_DELIVERY_PO_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.component + '_id']
            }
        })
        dispatch(setInputValue({prop: payload.component + '_data', value: data}))
    }
}