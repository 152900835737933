import * as types from './types'
import * as appTypes from '../App/types'
import moment from 'moment'


export const showNotification = (msg) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: msg,
                overflowText: msg,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const addBlankUpload = (i) => {
    return (dispatch, getState) => {
        let limit = getState().upload.limit
        let documents = getState().upload.documents
        if(!getState().upload.documents[i])
        {
            documents.push({
                fileinx: moment(new Date()).format('YYYYMMDDhhmmssSSS'),
                filename: '',
                preview: '',
                description: '',
                inx: i ? i : 1
            })
        }
        if(documents.length === limit) {
            dispatch(showNotification('Maximum documents reached'))
        }else{
            dispatch({
                type: types.UPLOAD_COMPONENT_ADD_BLANK_UPLOAD,
                payload: documents
            })
        }
    }
}

export const resetAllDocuments = () => {
    return (dispatch, getState) => {

        dispatch({
            type: types.UPLOAD_COMPONENT_ADD_BLANK_UPLOAD,
            payload: []
        })
    }
}

export const resetDocuments = (inx) => {
    return (dispatch, getState) => {
        let documents = getState().upload.documents
        documents = documents.filter(x => x.fileinx !== inx)

        dispatch({
            type: types.UPLOAD_COMPONENT_ADD_BLANK_UPLOAD,
            payload: documents
        })
    }
}

export const setUploadLimit = (limit) => {
    return (dispatch) => {
        dispatch({
            type: types.UPLOAD_COMPONENT_SET_LIMIT,
            payload: limit
        })
    }
}

export const setValue = (id, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPLOAD_COMPONENT_SET_FIELD_VALUE,
            payload: {
                description: value,
                id: id
            }
        })
    }
}

export const setFileType = (id, value) => {
    return (dispatch, getState) => {
        dispatch(addBlankUpload(id))
        let data = getState().upload.documents
        data[id].description = value
        dispatch({
            type: types.SET_UPLOAD_COMPONENT_FILE_NAME_VALUE,
            payload: data
        })
    }
}
export const getLookupDataValues = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_UPLOAD_COMPONENT_DOCUMENTS_TYPES_LOOKUP_DATA,
            payload: {
                search: search
            }
        })
    }
}

export const setLookupDataValues = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_UPLOAD_COMPONENT_DOCUMENTS_TYPES_LOOKUP_DATA,
            payload: {
                data: payload.data.map(x => {
                    return {
                        value: x.documenttype_id,
                        text: x.typename
                    }
                })
            }
        })
    }
}

export const viewDocument = (data) => {
    let link = ''

    const filekey = data.data.document.key.replace('quotes/', '')
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        const grid = getState().components['purchaseorder'].grid.data

        const payload = grid.map(x => {
            return filekey.indexOf('Receipt') < 0
                ? x.filename === filekey
                    ? {
                        ...x,
                        preview: link
                    }
                    : x
                : x.po_filename === filekey
                    ? {
                        ...x,
                        po: link
                    }
                    : x
        })

        dispatch({
            type: types.SET_ACCESSORY_PO_DOCUMENTS_PREVIEW,
            payload: {
                component: 'purchaseorder',
                data: payload
            }
        })
    }
}

// export const setAcceptedDocuments = (documents, i, prefix) => {
//     const filename = prefix + '_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
//     return (dispatch, getState) => {
//         documents.forEach(file => {
//             const reader = new FileReader();
//             reader.readAsArrayBuffer(file);
//             reader.onload = (e) => {
//                 let fileAsBinaryString = reader.result;
//                 dispatch({
//                     type: types.UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER,
//                     payload: {
//                         content: fileAsBinaryString,
//                         filename: filename,
//                         type: file.type,
//                     }
//                 })
//             };
//         });

//         dispatch({
//             type: types.SET_UPLOAD_DOCUMENT_PREVIEW,
//             payload: {
//                 preview: documents[0].preview,
//                 filename: filename,
//                 inx: i,
//             }
//         })
//     }
// }

export const setAcceptedDocuments = (documents, id, prefix) => {
    const filename = prefix + '_' + moment(new Date()).format('YYMMDDhhmmss') + '.' + documents[0].name.split('.')[documents[0].name.split('.').length - 1]
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER,
                    payload: {
                        content: fileAsBinaryString,
                        filename: filename,
                        type: file.type,
                    }
                })

            };
        });

        dispatch({
            type: types.SET_UPLOAD_DOCUMENT_PREVIEW,
            payload: {
                preview: documents[0].preview,
                filename: filename,
                id: id,
            }
        })
    }
}



export const apiFileUploaderDone = (res) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Uplaod compelted',
                overflowText: 'Uplaod compelted',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const uploadAPI = (documents, id, prefix) => {
    const filename = prefix + '_' + moment(new Date()).format('YYMMDDhhmmss') + '.' + documents[0].name.split('.')[documents[0].name.split('.').length - 1]
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.API_COMPONENT_UPLOADER,
                    payload: {
                        content: fileAsBinaryString,
                        key: 'enkw/' + filename,
                        filename: 'enkw/' + filename,
                        filetype: file.type,
                        query: 'set_s3_document',
                    }
                })
            };
        });

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Uplaoding...',
                overflowText: 'Uplaoding...',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        // dispatch({
        //     type: types.SET_UPLOAD_DOCUMENT_PREVIEW,
        //     payload: {
        //         preview: documents[0].preview,
        //         filename: filename,
        //         id: id,
        //     }
        // })
    }
}

