import * as props from './props'
import * as types from './types'



export default (state = props, action) => {
    switch (action.type) {

        case types.SET_CUSTOMER_BANK_RECON_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_UPLOADED_EXCEL_FILE_BUSY:
            return {
                ...state,
                busy: action.payload
            }
        case types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS:
            return {
                ...state,
                rows: action.payload
            }
        case types.SET_UPLOADED_EXCEL_FILE_NAME:
            return {
                ...state,
                document: action.payload
            }
        default:
            return state
    }
}