import * as validatorTypes from '../Validator/types'

export const fields = {
    email: {
        id: 'email',
        name: 'email',
        errorText: '',
        hintText: 'Example: john@acme.co',
        floatingLabelText: 'Email',
        input: '',
        type: 'text',
        valid: false,
        empty: true,
        validators: [validatorTypes.IS_NOT_EMPTY, validatorTypes.IS_EMAIL]
    },
    phone: {
        id: 'phone',
        name: 'phone_number',
        errorText: '',
        hintText: 'Example: 0831231234',
        floatingLabelText: 'Phone',
        input: '',
        type: 'text',
        valid: false,
        empty: true,
        validators: [validatorTypes.IS_NOT_EMPTY, validatorTypes.IS_MOBILEPHONE]
    },
    keyword: {
        id: 'keyword',
        name: 'keyword',
        errorText: '',
        hintText: 'Example: sw0rD#fiGht',
        floatingLabelText: 'Password',
        input: '',
        type: 'password',
        valid: false,
        empty: true,
        validators: [validatorTypes.IS_NOT_EMPTY, validatorTypes.IS_STRONG_PASSWORD]
    },
    keywordAgain: {
        id: 'passwordAgain',
        name: 'passwordAgain',
        errorText: '',
        hintText: 'Example: sw0rD#fiGht',
        floatingLabelText: 'Password Again',
        input: '',
        type: 'password',
        valid: false,
        match: 'password',
        empty: true,
        validators: [validatorTypes.IS_NOT_EMPTY, validatorTypes.MATCHES]
    }
}

export const keyword = {
    score: 0,
    strength: 0,
    color: '',
    min: 0,
    max: 100,
    value: 0,
    match: false
}

export const valid = false