import * as types from './types'
import * as helper from '../../helpers/components'
//import * as documuntActions from '../Document/actions'

export const selectRow = (component, row) => {
    return (dispatch, getState) => {
        const handle = getState().components[component]

        const info = handle.bubble !== null
            ? handle.bubble.split(',').reduce((arr, x) => {
                const parts = x.split(':')
                const value = row.original[parts[1]]

                if (value) {
                    arr.push({
                        title: parts[0],
                        field: parts[1],
                        value: value
                    })
                }

                return arr
            }, [])
            : []

        const field = handle.component_field.filter(x => x.key === true)

        const val = row.original[field[0].name]
        const prop = field[0].name
        const data = {
            val: val,
            prop: prop
        }
        dispatch({
            type: types.SELECT_ROW,
            payload: {
                component: handle,
                rows: [row],
                info: {
                    title: helper.getComponentTitle(handle, row.original),
                    description: helper.getComponentDescription(handle, row.original),
                    bubble: info
                }
            }
        })

        dispatch({
            type: types.SET_QUOTE_SELECT_ROW_PK,
            payload: {
                component: handle,
                data: data
            }
        })
    }
}

export const selectMultipleRows = (component, rows) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SELECT_MULTIPLE_ROWS,
            payload: {
                component: component,
                rows: rows
            }
        })
    }
}


export const setComponentGridPage = (component, page) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTE_GRID_PAGE,
            payload: component,
            page: page
        })
    }
}

export const getComponentGridData = (component, link, paging, filter, id) => {
    return (dispatch, getState) => {
        const getRelated = () => {
            if (link) {
                const parent = getState().components[link.parent]
                const through = getState().components[link.through]

                const query = '?' + parent.component_field.filter(x => x.key)[0].name + '=' + link.id

                return {
                    component: through,
                    child: component,
                    query: query
                }
            }

            return undefined
        }

        const getQuery = () => {
            let query = paging.filtered.reduce((query, x) => {
                query.push(x.id.concat('=', x.value))
                return query
            }, [])

            return '?' + query.join('&')
        }

        const withRelatedId = (query) => {

            if (!id || !filter) {
                return query
            }

            const key = getState().components[filter].component_field.filter(x => x.key)[0]

            if (!key) {
                return query
            }

            return query.indexOf('?' > -1) && query.length > 1
                ? query + '&' + key.name + '=' + id
                : '?' + key.name + '=' + id
        }

        let query = withRelatedId(getQuery())
        if (filter)
            query = '?groupname=' + filter

        const related = getRelated()
        dispatch({
            type: types.GET_QUOTE_GRID_DATA,
            payload: {
                id: id,
                component: !related ? component : related.component,
                input: '',
                paging: paging,
                sorting: paging.sorted.length > 0
                    ? !paging.sorted[0].desc
                        ? paging.sorted[0].id
                        : '-' + paging.sorted[0].id
                    : '',
                query: !related ? query : related.query,
                related: component.related !== null ? component.related : '',
                child: !related ? {} : related.child
            }
        })
    }
}


export const setComponentGridData = (payload) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTE_GRID_DATA,
            payload: {
                component: payload.child.name ? payload.child : payload.component,
                data: payload.child.name
                    ? {
                        ...payload.data,
                        data: payload.data.data.map(x => {
                            return x[payload.child.name]
                        })
                    }
                    : payload.data
            }
        })
    }
}

export const getQuoteDocument = (id, quotenumber, documenttype) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_QUOTE_DOCUMENT_LIST,
            payload: {
                id: id,
                query: quotenumber,
                documenttype: documenttype,
                related: 'document'
            }
        })
    }
}

export const setQuoteDocumentList = (payload) => {
    const _data = payload.data.map(x => {
        return x.document
    })

    const data = _data.map(x => {
        return JSON.parse(x['content'])
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_AWS_QUOTE_DOCUMENT_LIST,
            payload: {
                key: data[0].document
            }
        })
    }
}


export const setDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_AWS_QUOTE_DOCUMENT_LIST,
            payload: documents
        })
    }
}

export const getClearDocuments = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_AWS_QUOTE_DOCUMENT_LIST,
            payload: {
                component: getState().components['document'],
                data: ""
            }
        })
    }
}

export const viewDocument = (data) => {
    let link = ''
   
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())
    saveByteArray([data.data.content.Body], data.data.document.key)
    
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_GRID_QUOTE_DOCUMENT_BYTES,
            payload: {
                component: getState().components['document'], //getFile(data),
                data: link
            }
        })
    }
    // return (dispatch, getState) => {
    //     dispatch({
    //         type: types.QUOTE_DOCUMENT_VIEWER,
    //         payload: {
    //             url: data.data.document.key, //getFile(data),
    //             type: data.data.document.extension
    //         }
    //     })
    // }
}


export const getQuoteDetails = (field, value) => {
    return (dispatch, getState) => {
        // dispatch({
        //     type: types.SET_PO_COMPONENT_FIELD_INPUT_VALUE,
        //     payload: {
        //         component: field.component.name,
        //         field: field.name,
        //         value: value
        //     }
        // })

        dispatch({
            type: types.SEARCH_PO_QUOTE_DATA,
            payload: {
                component: 'customerquote',
                field: field.name,
                query: '?quotenumber=' + value,
            }
        })
    }
}

export const setQuoteDetails = (component, payload) => {
    const data = {
        component: component,
        data: (typeof payload.data[0] === 'object') ? payload.data[0] : []
    }

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_COMPONENT_GRID_DATA,
            payload: data
        })
    }
}