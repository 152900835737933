import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.WORKFLOWS_GET_WORKFLOW_NAMES:
            return {
                ...action.payload,
                loading: true
            }
        case types.WORKFLOWS_SET_WORKFLOW_NAMES:
            return {
                ...action.payload,
                loading: false
            }
        case types.WORKFLOWS_GET_WORKFLOW:
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    loading: true
                }
            }
        case types.WORKFLOWS_SET_WORKFLOW:
            return {
                ...state,
                [action.payload.name]: {
                    ...action.payload,
                    loading: false
                }
            }
        default:
            return state
    }
}