export const EMPTY_TYPE = 'EMPTY_TYPE'
export const SET_CONTROL_VIEW_INPUT_DATA = 'SET_CONTROL_VIEW_INPUT_DATA'
export const GET_CONTROL_VIEW_SUPPLIER_TRANSACTION = 'GET_CONTROL_VIEW_SUPPLIER_TRANSACTION'
export const GET_CREDIT_NOTE_COMPONENT_LOOKUP_DATA = 'GET_CREDIT_NOTE_COMPONENT_LOOKUP_DATA'
export const SET_INTEREST_RATE_COMPONENT_INPUT_FIELD_VALUE = 'SET_INTEREST_RATE_COMPONENT_INPUT_FIELD_VALUE'

export const CONFIRM_INTEREST_RATE_CREATE = 'CONFIRM_INTEREST_RATE_CREATE'
export const GENERATE_CREDITORS_PAYMENT_DOCUMENTS = 'GENERATE_CREDITORS_PAYMENT_DOCUMENTS'
export const CONFIRM_CREDITORS_PAYMENT_WORKFLOW_CREATE = 'CONFIRM_CREDITORS_PAYMENT_WORKFLOW_CREATE'

export const GET_INTEREST_RATE_CONTRACTS = 'GET_INTEREST_RATE_CONTRACTS'
export const GET_INTEREST_RATE_PRIMERATE = 'GET_INTEREST_RATE_PRIMERATE'
export const GET_INTEREST_RATE_COMPONENT_LOOKUP_DATA = 'GET_INTEREST_RATE_COMPONENT_LOOKUP_DATA'
export const RESET_COMPONENT_INTERESTCHANGE_FORM = 'RESET_COMPONENT_INTERESTCHANGE_FORM'