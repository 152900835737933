import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SEARCH_COMPONENT_SET_TITLE:
            return {
                ...state,
                title: action.payload
            }
        case types.SEARCH_COMPONENT_SET_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            }
        default:
            return state
    }
}