import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'

export const setValue = (prop, value) => {
    return (dispatch, getState) => {       
            dispatch({
                type: types.SET_BILLING_VALUE_CHANGE,
                payload: {
                    prop: prop,
                    value: value
                }
            })
    }
}
export const reset = () => {
    return (dispatch, getState) => {       
            dispatch({
                type: types.RESET_BILLING_VALUES,
            })
    }
}
export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_BILLING_INITIATION,
            payload: payload
        })
    }
}