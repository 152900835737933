import * as types from './types'

export const getWorkflowTaskActionData = (workflow_queue_id, workflow_queue_task_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKFLOW_TASK_ACTION_DATA,
            payload: {
                workflow_queue_id: workflow_queue_id,
                workflow_queue_task_id: workflow_queue_task_id
            }
        })
    }
}

export const setWorkflowTaskActionData = (data, workflow_queue_id, workflow_queue_task_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_ACTION_DATA,
            payload: {
                workflow_queue_id: workflow_queue_id,
                workflow_queue_task_id: workflow_queue_task_id,
                data: data
            }
        })
    }
}

export const setupWorkflowTaskAction = (workflow, task) => {
    return (dispatch, getState) => {
        const selectedWorkflow = getState().workflows[getState().workflowQueueTasks.workflow.name]
        const selectedWorkflowTask = selectedWorkflow.workflow_task.filter(x => x.name === getState().workflowQueueTasks.workflow_task.name)[0]

        dispatch({
            type: types.SET_WORKFLOW_ACTION,
            payload: {
                ...getState().workflowQueueTasks,
                workflow: selectedWorkflow,
                workflow_task: selectedWorkflowTask
            }
        })
    }
}

export const rejectWorkflowQueueTask = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REJECT_WORKFLOW_QUEUE_TASK,
            payload: getState().workflowTaskAction.queue
        })
    }
}

export const approveworkflowQueueTask = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_WORKFLOW_QUEUE_TASK,
            payload: getState().workflowTaskAction.queue
        })
    }
}

export const startQuoteflowQueueTask = (workflow_name) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_MAIL_WORKFLOW_QUEUE_TASK,
            payload: getState().workflowTaskAction.queue
        })
    }
}