//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const subheader = {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '16px',
    color: 'gray',
    padding: '10px'
}

export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}

export const icon = {
    fontSize: '10px'
}

export const row = {
    fontSize: 16,
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
    //opacity: '0.5'
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
}
export const fieldContainer = {
    display: 'table',
    padding: 5
}


export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'top',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldTitleLeft = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'top',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const textBox = {
    width: '100%'
}

export const dropZone = {
    width: '90%',
    height: '300px',
    border: '1px solid red',
    padding: '10px'
}

export const numericFieldsHeader = {
    textAlign: 'right',
    fontWeight: 'bold',
}

export const numericFields = {
    textAlign: 'right',
}


export const textArea = {
    border: '1px solid red',
    padding: '0px',
    margin: '0px'
}

export const fieldLabelA = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingTop: 20,
    paddingRight: 10
}

export const fieldLabelDates = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingTop: 30,
    paddingRight: 10
}
export const itemrow = {
    fontSize: '16',
    borderBottom: '1px dashed gray',
}

export const headerrow = {
    backgroundColor: '#666',
}

export const errorBox = {
    width: '100%',
    backgroundColor: '#cbe9f7',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}