export const gridCounter = 0
export const additional = true
export const insurance = true
export const add_customer_win = false
export const quotationnotes = ''
export const vehicle_id = null
export const supplier_id = null
export const strratecard_id = null
export const transmission_id = null
export const contractunit_id = null
export const customer_id = null
export const waiver_id = null

export const delivery_address = {}
export const collection_address = {}
export const delivery_kms = null
export const collection_kms = null
export const delivery_cost = 0
export const collection_cost = 0

export const period = null
export const distance = null
export const excess = null
export const startdate = ''
export const enddate = ''
export const vehicle = ''
export const supplier = ''
export const strratecard = ''
export const transmission = ''
export const contractunit = ''
export const customer = ''
export const waiver = ''
export const waiver_code = ''

export const strratecards = []
export const supplier_data = []
export const vehicle_data = []
export const customer_data = []
export const transmission_data = []
export const contractunit_data = []
export const ratecategory_data = []
export const straccessory_data = []
export const collection_data = []
export const delivery_data = []
export const waiver_data = []
export const delivery = []
export const collection = []
export const accessory_list = []
export const strratecard_data = []
export const monthly_rental = 0.00
export const str_monthly_rental = 0.00
export const accessory_tot_daily = 0.00
export const accessory_tot_monthly = 0.00
export const str_daily_rental = 0.00
export const accessory_tot = 0.00
export const waiver_tot = 0.00
export const insurance_tot = 0.00
export const str_customer_selected ={}
export const monthly_excl = 0.00
export const monthly_vat = 0.00
export const monthly_incl = 0.00

// export const suppliers = []
// export const contractvehiclecategory = null
// export const strratecard = null
// export const mmcode = null
// export const unit = null
// export const rate = null
// export const straccessory_data = []
// export const sundry_list = []
// export const service_list = []
// export const customer_data = []
// export const mmcode_data = []
// export const ratecategory = null
// export const ratecategory_list = []
// export const vehicle = null
// export const vehicles_list = []
// export const contractvehiclecategory_data = []
// export const accessory_list = []

// let customer_data = this.props.state?.mm_customer?.customer_data[0];
// if (customer_data?.riskrating_id == '1') {
//     this.props.actions.toggleCustomerDialog(true, (customer_data?.customername) + ' ' + 'has a high risk rating therefore no contract quotations can be done.');
// } else if (parseFloat(customer_data?.facilityutilised > customer_data?.facilitylimit)) {
//     this.props.actions.toggleCustomerDialog(true, (customer_data?.customername) + ' ' + 'has a facility limit of' + ' ' + 'R' + (customer_data?.facilitylimit) + ' ' + 'that has been exceeded by' + ' ' + 'R' + (customer_data?.facilityutilised - customer_data?.facilitylimit) + '; Please Contact System Administrator/Immediate Supervisor' );
// } else if (new Date(customer_data?.facility_expiry_date) < new Date()) {
//     this.props.actions?.toggleCustomerDialog(true, (customer_data?.customername) + ' ' + 'requires a facility review due to the expiration of the facility on the ' + (new Date(customer_data?.facility_expiry_date)))
// } else {
//     component.actions.goToNextStep(this.props.state.task);
// }

export const fields = {
  documents: {
    errorText: 'Please add a document and select document type',
    data: false,
    valid: false,
    empty: true,
    popup: true,
    type: 'documents',
  },
  accessoryItem: {
    errorText: 'Please select Accessory',
    state: 'getState().captureQuotation.accessory_list.length > 0 ? getState().captureQuotation.accessory_list : true',
    data: false,
    valid: false,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['accessory_name']
  },
  rfqtime: {
    state: 'getState().captureQuotation.rfqtime',
    value: '',
    valid: false,
    errorText: 'Please select RFQ Time',
    empty: true
  },
  rfqdate: {
    state: 'getState().captureQuotation.rfqdate',
    value: '',
    valid: false,
    errorText: 'Please select RFQ Date',
    empty: true
  },
  waiver: {
    state: 'getState().captureQuotation.waiver',
    value: 'Please select Waiver',
    valid: false,
    errorText: 'Please select Waiver',
    empty: true
  },
  // collection_address: {
  //   state: 'getState().captureQuotation.collection_address',
  //   value: '',
  //   valid: false,
  //   errorText: 'Please select Collection Address',
  //   empty: true
  // },
  // delivery_address: {
  //   state: 'getState().captureQuotation.delivery_address',
  //   value: '',
  //   valid: false,
  //   errorText: 'Please select Delivery Address',
  //   empty: true
  // },
  contractunit: {
    state: 'getState().captureQuotation.contractunit',
    value: '',
    valid: false,
    errorText: 'Please select Contract Unit',
    empty: true
  },
  enddate: {
    state: 'getState().captureQuotation.enddate',
    value: '',
    valid: false,
    errorText: 'Please enter estimated Contract End Date',
    empty: true
  },
  startdate: {
    state: 'getState().captureQuotation.startdate',
    value: '',
    valid: false,
    errorText: 'Please enter estimated Contract Start Date',
    empty: true
  },
  excess: {
    state: 'getState().captureQuotation.excess',
    value: '',
    valid: false,
    errorText: 'Please enter Excess',
    empty: true,
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Excess'
      }
    ]
  },
  transmission: {
    state: 'getState().captureQuotation.transmission',
    value: '',
    valid: false,
    errorText: 'Please select Transmission Type',
    empty: true
  },
  strratecard: {
    state: 'getState().captureQuotation.strratecard',
    value: '',
    valid: false,
    errorText: 'Please select Rate Card',
    empty: true
  },
  distance: {
    state: 'getState().captureQuotation.distance',
    value: '',
    valid: false,
    errorText: 'Please enter Contract Distance',
    empty: true,
    validations: [
      {
        'schema': 'integer',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Contract Distance'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Contract Distance'
      }
    ]
  },
  contractperiod: {
    state: 'getState().captureQuotation.period',
    value: null,
    valid: false,
    errorText: 'Please enter Contract Period',
    empty: true,
    validations: [
      {
        'schema': 'integer',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Contract Period'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Contract Period'
      }
    ]
  },

  customerFacilityExpired: {
    customErrorText: "",
    errorText: "The selected contract's customer requires a facility review due to the expiration of the facility: Please Contact System Administrator/Immediate Supervisor",
    value: false,
    valid: false,
    empty: true,
    state: 'getState().captureQuotation?.str_customer_selected.length > 0 ? !(new Date(getState().captureQuotation?.str_customer_selected[0]?.facility_expiry_date) < new Date()) : true'
  },
  customerFacilityUtilised: {
      errorText: "The selected contract's customer has exceeded their facility utilised: Please Contact System Administrator/Immediate Supervisor",
      value: false,
      valid: false,
      empty: true,
      state: 'getState().captureQuotation?.str_customer_selected.length > 0 ? !(parseFloat(getState().captureQuotation?.str_customer_selected[0].facilityutilised) > parseFloat(getState().captureQuotation?.str_customer_selected[0]?.facilitylimit)) : true'
  },
  customerHighRisk: {
      errorText: "The selected contract's customer has a high risk rating therefore no contract deviations can be done: Please Contact System Administrator/Immediate Supervisor",
      value: false,
      valid: false,
      empty: true,
      state: "getState().captureQuotation?.str_customer_selected.length > 0 ? getState().captureQuotation?.str_customer_selected[0].riskrating_id !== 1 : true",
  },

  customer: {
    state: 'getState().captureQuotation.customer',
    value: '',
    valid: false,
    errorText: 'Please select a Customer',
    popup: true,
    empty: true
  }
}

// prop: {
//   state: 'getState().captureQuotation.',
//   value: '',
//   valid: false,
//   errorText: 'Please select enter',
//   empty: true
// },


// export const retailprice = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const costomerref = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const supplier_type = {
//     value: null,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const vehicleprice = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const discount = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }

// export const finance = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const primerate = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }

// export const interestrate = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const diviation = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }

// export const monthlyinclusive = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }


// export const startdate = {
//     value: null,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const enddate = {
//     value: null,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const excessrates = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const roipercentage = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const roi = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const rentalamount = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const validuntil = {
//     value: 30,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const quotefor = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const monthlyexc = {
//     value: null,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const monthlyvat = {
//     value: null,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const monthlyinc = {
//     value: null,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const rv = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const rvamount = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const strrate = {
//     value: 1,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const stramount = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const workingsubtotal = {
//     value: null,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const workingvat = {
//     value: null,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const workingrental = {
//     value: null,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const totalaccessory = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const adminfee = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const licensefee = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const tyres = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const maintenance = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const loadfactorperc = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const loadfactoramount = {
//     value: 0,
//     valid: true,
//     errorText: 'Required!!',
// }
// export const units = [
//     {
//         text: 'Months',
//         value: 'Monthly'
//     },
//     {
//         text: 'Weeks',
//         value: 'Weekly'
//     },
//     {
//         text: 'Days',
//         value: 'Daily'
//     }
// ]

// export const rates = [
//     {
//         text: 'KM',
//         value: 'Kms'
//     },
//     {
//         text: 'Hour',
//         value: 'Hours'
//     }
// ]
