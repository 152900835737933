export const SET_CURRENT_GRID_COMPONENT = 'SET_CURRENT_GRID_COMPONENT'
export const GET_COMPONENT_GRID_DATA = 'GET_COMPONENT_GRID_DATA'
export const SET_COMPONENT_GRID_DATA = 'SET_COMPONENT_GRID_DATA'
export const SET_COMPONENT_GRID_PAGE = 'SET_COMPONENT_GRID_PAGE'
export const SET_QUOTE_ITEM_GRID_SELECTED_ROW = 'SET_QUOTE_ITEM_GRID_SELECTED_ROW'
export const REMOVE_SINGLE_ROW = 'REMOVE_SINGLE_ROW'
export const SELECT_MULTIPLE_ROWS = 'SELECT_MULTIPLE_ROWS'
export const SET_COMPONENT_FIELD_INPUT = 'SET_COMPONENT_FIELD_INPUT'
export const LOAD_QUOTE_ITEM_TYPES = 'LOAD_QUOTE_ITEM_TYPES'
export const SET_QUOTE_ITEM_TYPES = 'SET_QUOTE_ITEM_TYPES'
export const SET_SELECTED_QUOTE_ITEM_TYPES = 'SET_SELECTED_QUOTE_ITEM_TYPES'
export const LOAD_QUOTE_TYPE_ITEMS_LIST = 'LOAD_QUOTE_TYPE_ITEMS_LIST'
export const SET_QUOTE_TYPE_ITEMS_LIST = 'SET_QUOTE_TYPE_ITEMS_LIST'
export const SET_SELECTED_QUOTE_ITEM_LIST = 'SET_SELECTED_QUOTE_ITEM_LIST'
export const SET_SELECTED_ITEM_LIST = 'SET_SELECTED_ITEM_LIST'
export const UPDATE_QUOTE_GRID_ITEMS = 'UPDATE_QUOTE_GRID_ITEMS'
export const QUOTE_GRID_ADD_BLANK_ROW = 'QUOTE_GRID_ADD_BLANK_ROW'
export const REMOVE_QUOTE_GRID_ITEM = 'REMOVE_QUOTE_GRID_ITEM'
export const SET_QUOTE_GRID_FIELD_INPUT_VALUE = 'SET_QUOTE_GRID_FIELD_INPUT_VALUE'
export const SET_COMPONENT_FIELD_DATA = 'SET_COMPONENT_FIELD_DATA'
export const SET_SELECTED_QUOTE_ITEM_TYPES_INPUT = 'SET_SELECTED_QUOTE_ITEM_TYPES_INPUT'
export const SET_SELECTED_QUOTE_ITEM_TYPES_TEXT = 'SET_SELECTED_QUOTE_ITEM_TYPES_TEXT'
export const GET_COMPONENT_FML_QUOTATION_ITEMS_LOOKUP_DATA = 'GET_COMPONENT_FML_QUOTATION_ITEMS_LOOKUP_DATA'
export const SET_SELECTED_COMPONENT_NAME_FROM_SELECTION = 'SET_SELECTED_COMPONENT_NAME_FROM_SELECTION'

export const GET_COMPONENT_FML_QUOTATION_CHIP_DATA_LIST = 'GET_COMPONENT_FML_QUOTATION_CHIP_DATA_LIST'
export const SET_COMPONENT_FML_QUOTATION_CHIP_DATA_LIST = 'SET_COMPONENT_FML_QUOTATION_CHIP_DATA_LIST'
export const GET_COMPONENT_FML_QUOTATION_ITEMS_DATA_LIST = 'GET_COMPONENT_FML_QUOTATION_ITEMS_DATA_LIST'
export const SET_COMPONENT_FML_QUOTATION_ITEMS_INPUT_VALUE = 'SET_COMPONENT_FML_QUOTATION_ITEMS_INPUT_VALUE'
export const GET_SUPPLIER_DATA= 'GET_SUPPLIER_DATA'
export const  GET_SUPPLIER_ACC_DATA = 'GET_SUPPLIER_ACC_DATA'
export const  GET_SUPPLIER_DISTANCE_DATA = 'GET_SUPPLIER_DISTANCE_DATA'
export const GET_SUPPLIER_HISTORY__DATA = 'GET_SUPPLIER_HISTORY__DATA'