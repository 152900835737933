import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getCustomerLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_COMPONENT_CUSTOMER_LIST_DATA,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customer/search?hierarchyname=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    // 'cognito_key': store.value.users[0].cognito_key,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setCustomerLookupData(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )



export const getContractTypeLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_COMPONENT_CONTRACT_TYPE_LIST_DATA,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/contracttype/search?contracttype=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    // 'cognito_key': store.value.users[0].cognito_key,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setContractTypeLookupData(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getProductTypeLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_COMPONENT_PRODUCT_TYPE_LIST_DATA,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/producttype/search?producttype=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setProductTypeLookupData(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getCustomerSettingData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_COMPONENT_SELECTED_CUSTOMER_SETTINGS_DATA,
    )
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype_id=' + payload.contracttype_id,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    // 'cognito_key': store.value.users[0].cognito_key,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setCustomerSettingData(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )



export const saveComponentData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.SAVE_COMPONENT_CUSTOMER_DATA)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customerderivative',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'client-id': store.value.accounts.selectedAccount,
                    'user-id': store.value.user.user_id,
                    'Content-Type': 'application/json'
                },
                body: payload.data
            })
                .pipe(
            mergeMap((result) => [
                    actions.componentDataSaved(payload.component),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivative/save', payload.response)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(actions.componentDataSaveFailed(payload.component)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

        