export const GET_BILLING_LIST = 'GET_BILLING_LIST'
export const SET_BILLING_LIST = 'SET_BILLING_LIST'

export const GET_BILLING_INVOICE_LIST = 'GET_BILLING_INVOICE_LIST'
export const GET_BILLING_FML_INVOICE_LIST = 'GET_BILLING_FML_INVOICE_LIST'
export const GET_BILLING_STR_INVOICE_LIST = 'GET_BILLING_STR_INVOICE_LIST'

export const SET_BILLING_INVOICE_LIST = 'SET_BILLING_INVOICE_LIST'
export const SET_BILLING_FML_INVOICE_LIST = 'SET_BILLING_FML_INVOICE_LIST'
export const SET_BILLING_STR_INVOICE_LIST = 'SET_BILLING_STR_INVOICE_LIST'

export const SET_INVOICE_SEARCH_TEXT = 'SET_INVOICE_SEARCH_TEXT'
export const SET_ANY_FIELD_INPUT_VALUE = 'SET_ANY_FIELD_INPUT_VALUE'
export const GENERATE_PREVIEW_INVOICE = 'GENERATE_PREVIEW_INVOICE'

export const BILLING_RERUN = 'BILLING_RERUN'
export const SET_BILLING_RERUN_FLAG = 'SET_BILLING_RERUN_FLAG'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const EXPORT_BILLING = 'EXPORT_BILLING'
export const GEN_CIS_EXCEL = 'GEN_CIS_EXCEL'

export const SET_BILLING_FIXED_INVOICE_LIST = 'SET_BILLING_FIXED_INVOICE_LIST'
export const SET_BILLING_EXCESS_INVOICE_LIST = 'SET_BILLING_EXCESS_INVOICE_LIST'
export const SET_BILLING_VARIABLE_INVOICE_LIST = 'SET_BILLING_VARIABLE_INVOICE_LIST'

export const SELECT_ALL = 'SELECT_ALL'
export const SEND_INVOICES = 'SEND_INVOICES'
export const LINK_INVOICES = 'LINK_INVOICES'
export const CUSTOM_EMAIL = 'CUSTOM_EMAIL'

export const BILLING_APPROVAL_LIST = 'BILLING_APPROVAL_LIST'
export const APPROVED_SELECT_ALL = 'APPROVED_SELECT_ALL'
export const APPROVE_BILLING = 'APPROVE_BILLING'
export const DUMMY_RUN = 'DUMMY_RUN'

export const GET_ODO_TEMPLATE = 'GET_ODO_TEMPLATE'

export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
export const SET_DEFAULT_TAB = 'SET_DEFAULT_TAB'
export const SET_MAIN_TAB = 'SET_MAIN_TAB'