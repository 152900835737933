import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import * as helper from '../../helpers/components'
import Icon from '../Icon/component'
import SurrogateView from '../SurrogateView/component'

import * as actions from './actions'
import * as styles from './styles'


import TextField from '@mui/material/TextField'
import AutoComplete from '../../controls/autocomplete'
import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'
import Iframe from 'react-iframe'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

class WorkflowPOReceiptVehicleComponent extends Component {
    componentDidMount() {
        //this.props.actions.loadBusiness(this.props.step,this.props.name)
    }

    getCustomer() {
        const customer = this.data.customer
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3}>{'Customer Name:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{customer.customername}</Col>
                    </Row>
                    <Row>
                        <Col xl={3}>{'Cost Centre:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{customer.costcentre}</Col>
                        <Col xl={3}>{'Registered Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{customer.registeredname}</Col>
                    </Row>
                    <Row>
                        <Col xl={3}>{'Account Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{customer.accountnumber}</Col>
                        <Col xl={3}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{customer.compregistrationnumber}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getVehicle() {   
        const vehicle = this.data.confirm.vehicle
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Requested vehicle on the quote')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3}>{'Vehicle Model:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{vehicle.makemodelyear}</Col>
                    </Row>
                    <Row>
                        <Col xl={3}>{'Vehicle MM Code:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{vehicle.mmcode}</Col>
                        <Col xl={3}>{'Vehicle Make:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{vehicle.make}</Col>
                    </Row>
                    <Row>
                        <Col xl={3}>{'Delivery Period:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{vehicle.deliveryperiod}</Col>
                        {/* <Col xl={3}>{'Vehicle First Registration Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{vehicle.regyear}</Col> */}
                    </Row>
                    <Row>
                        <Col xl={3}>{'Fuel Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{vehicle.fueltype}</Col>
                        <Col xl={3}>{'Status:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{''}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getForm() {
        this.field = this.component.component_field.reduce((obj, x) => {
            obj[x.name] = x
            return obj
        }, {})

        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Requested vehicle on the quote')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
            <Container style={{ margin: 0 }} className="custom-container">
            <Row>
            <Col xl={6}>
            <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Select Business Unit:'}</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        id={this.field.branch_id.name}
                                        key={this.field.branch_id.name}
                                        hintText={'Select Business Unit'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        //searchText={this.field.branch_id.search}
                                        dataSourceConfig={{
                                            text: 'businessname',
                                            value: 'business_id'
                                        }}
                                        dataSource={this.component.data.branch_id.data}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                this.props.actions.setLookupValue(this.field.branch_id, chosenRequest.business_id, chosenRequest.businessname)
                                                //this.props.actions.newBlankRow(component)
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.searchLookupValue(this.props.state.business.name, this.field.branch_id, '')
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.searchLookupValue(this.props.state.business.name, this.field.branch_id, search)
                                            }
                                        }
                                    //maxSearchResults={3}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Select Vehicle Receipt Date:'}</div>
                                <div style={styles.fieldContent}>
                                   <TextField
                                                type="date"
                                        id={this.field.orderdate.name}
                                        hintText='Select Vehicle Receipt Date'
                                        container='inline'
                                        mode='landscape'
                                        value={this.component.data.orderdate.input}
                                        defaultDate={this.component.data.orderdate.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue(this.field.orderdate, value)
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                </Col>
                
            <Col xl={6}>
                <Container >
                    {
                        this.props.state.document.data.content.text
                            ? <Row>

                                <Col xl={12}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Upload Vehicle Receipt:'}</div>
                                        <div style={styles.fieldContent}>
                                            {
                                                this.props.state.document.data.content.text
                                                    ? <Iframe url={this.props.state.document.data.content.text}
                                                        width="100%"
                                                        height="100%"
                                                        id="myId"
                                                        className="myClassname"
                                                        display="initial"
                                                        position="relative"
                                                        allowFullScreen />
                                                    : 'No File..'
                                            }
                                           < Button className={"global_button"} variant="contained"
                                                primary={true}
                                                label="Clear"
                                                onClick={(event) => {
                                                    this.props.actions.clearRFQDocument(this.component)
                                                }}
                                                >{window.t.en('Clear')}</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            : <Row>
                                <Col xl={12}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{'Upload OEM Vehicle Receipt:'}</div>
                                        <div style={styles.fieldContent}>
                                            <Dropzone
                                                ////accept={'/*'}
                                                style={styles.dropZone}
                                                hintText={'Upload'}
                                                onDrop={(files) => {
                                                    //component.actions.toggleDropzoneVisibility()
                                                }}
                                                onDropAccepted={(files) => {
                                                    this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                                }}
                                                onDropRejected={(files) => {
                                                    this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                                }}
                                            >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                            </Dropzone>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                    }
</Container>
            </Col>
            </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getView() {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Purchase Order Vehicle Receipt')}
                subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                {this.getCustomer()}
            </CardContent>
            <CardContent expandable={false}>
                {this.getVehicle()}
            </CardContent>
            <CardContent expandable={false}>
                {this.getForm()}
            </CardContent>
        </Card>
    }

    render() {
        //this.component = this.props.state
        this.step = this.props.step
        this.name = this.props.name
        this.component = this.props.state.component

        const workflow_queue_data = this.props.workflow_queue_data[0]
        this.data = workflow_queue_data.context_data.data.data.data[0].data.data

        const workflow = this.props.state.step.workflow.name

        return this.getView()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowQueueTasks,
            step: {
                ...state.workflowTaskInput
            },
            component: {
                ...state.components[ownProps.name]
            },
            document: {
                ...state.components['document']
            },
            business: {
                ...state.components['business']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOReceiptVehicleComponent)