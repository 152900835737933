import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_USER_TEAMS:
            return {
                ...state,
                teams: action.payload
            }
        case types.SET_HIERARCHY:
            return {
                ...state,
                tree: action.payload
            }
        case types.SET_CONTEXT_MENU_ANCHOREL:
            return {
                ...state,
                anchorEl: action.payload
            }
        case types.TOGGLE_HIERARCHY:
            return {
                ...state,
                toggled: !state.toggled
            }
        case types.HIDE_HIERARCHY:
            return {
                ...state,
                toggled: false
            }
        default:
            return state
    }
}