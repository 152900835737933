import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import sockets from 'socket.io-client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import {
    Avatar, ListItemAvatar,
    IconButton, Chip,
    Box,
    Typography,
    Tabs,
    Tab,
    Popover,
    LinearProgress,
    Badge,
    Button,
    List,
    ListItem,
    Divider,
    Collapse,
    ListItemText,
    Tooltip
} from '@mui/material';

import * as signedInActions from "./actions";
import * as cognitoActions from '../Cognito/actions'
import * as actionsWorkflow from '../WorkflowQueueTasks/actions'
import * as accountActions from "../Account/actions";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SyncIcon from '@mui/icons-material/Sync';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import PersonIcon from '@mui/icons-material/Person';
import {ReactComponent as UserAvatar} from '../../assets/img/svg/icons/user_avatar.svg'
import ImageIcon from "@mui/icons-material/Image";


import PeopleIcon from "@mui/icons-material/People";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ExpandNotificationsMore from '@mui/icons-material/ExpandMore';
import ExpandNotificationsLess from '@mui/icons-material/ExpandLess';
import BellIcon from 'react-bell-icon';
import LanguageIcon from '@mui/icons-material/Language';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import PollTwoToneIcon from '@mui/icons-material/PollTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import OpacityIcon from '@mui/icons-material/Opacity';
//import WhatshotIcon from '@mui/icons-material/Whatshot';
import PlaceIcon from '@mui/icons-material/Place';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import * as redirectActions from "../Redirect/actions";
import * as navigationMenuActions from "../NavigationMenu/actions";
import * as workqueueActions from "../Workqueue/actions";

import * as _ from 'lodash'
import * as styles from "./styles";

import { signOut } from 'aws-amplify/auth'

import * as config from '../../config'

let ui = sockets(config.system.messenger.uri)


class AppNotification extends Component {

    constructor(props) {
        super(props)

        this.state = {
            anchorEl: false,
            anchorEl2: false,
            escalationEl: false,
            escalation: ''
        }
    }
    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
      };
      handleEscalation = (event) => {
        this.props.actions.getWorkqueueList()
        this.setState({escalationEl: event.currentTarget});
      };
      handleEscalationClose = (event) => {
        this.setState({escalationEl: null});
      };
      handleEscalationItem = (item) => {
        this.setState({escalation: this.state.escalation === item ? '' : item});
      };
      handleEscalationItemClose = (item) => {
            this.setState({escalation: ''});
            this.setState({escalationEl: null});
            //this.props.actions.getItemData(item)
            this.props.actions.setWorkqueueItem(item)
            this.props.actions.route('/workqueue/'+item.workqueue_title)
      };
       handleClose = () => {
        this.setState({anchorEl: null});
      };
    
     handleClick2 = (event) => {
        this.setState({anchorEl2: event.currentTarget});
      };
       handleClose2 = () => {
        this.setState({anchorEl2: null});
      };
      
      handleSingout = () => {
        ui.disconnect(true)
        signOut()
            .then(data => {
                //console.log('handleSingout', data)
                this.props.actions.signoutCognitoUser()
            })
            .catch(err => {
                //console.log('handleSingout Err', err)
            });

    }

    languagePopover() {
        const open = Boolean(this.state.anchorEl);
        return <Popover
            className="language-popover"
            open={open}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            width={'300px'}
            sx={{width: '300px'}}>
            <List
                component="div"
                style={{ width: 300 }}
                className="list-group-flush text-left bg-transparent"
            >
                {
                    this.props.state.accounts.locales.map(x =>
                        <ListItem onClick={(event) => { this.props.actions.setSelectedSetting({ prop: 'locale', value: x.code }) }}>
                            <div className="align-box-row">
                                <Badge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    variant="dot">
                                    <LanguageIcon style={{ color: '#223750' }} />
                                </Badge>
                                <div className="pl-3">
                                    <span className="font-weight-bold d-block">
                                        {x.locale}
                                    </span>
                                </div>
                            </div>
                        </ListItem>
                    )
                }
            </List>

        </Popover>
    }

    escalationPopover() {
        const _workqueue = this.props.state.workqueue.length
            ? _.uniq(
                this.props.state.workqueue.reduce((arr, x) => {
                    if (x.workqueue_section) {
                        arr.push(x.workqueue_section)
                    }

                    return arr
                }, [])
            ) : []

        const open = Boolean(this.state.escalationEl);
        return <Popover
            className="language-popover"
            open={open}
            anchorEl={this.state.escalationEl}
            onClose={this.handleEscalationClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            width={'700px'}
            sx={{width: '700px'}}>
            <List
                component="div"
                style={{ width: 700 }}
                className="list-group-flush text-left bg-transparent"
            >
                {
                    _workqueue.length
                        ? _workqueue.map(x => {
                            return <div>
                                <ListItem button onClick={() => this.handleEscalationItem(x)} style={{ width: 700 }}>
                                    <ListItemText>
                                        <div className="align-box-row">
                                            <Badge variant="dot">
                                                {/* {x === 'Personnel' && <AssignmentIndIcon style={{ color: '#253053' }} />}
                                                {x === 'Vehicles Events' && <DirectionsCarIcon style={{ color: '#253053' }} />}
                                                {x === 'Technical Rebill Events' && <DirectionsCarIcon style={{ color: '#253053' }} />}
                                                {x === 'Maintenance' && <DirectionsCarIcon style={{ color: '#253053' }} />}
                                                {x === 'Technical Authorisation Events' && <PlaylistAddCheckIcon style={{ color: '#253053' }} />}
                                                {x === 'Technical Defleet Events' && <EventNoteIcon style={{ color: '#253053' }} />}
                                                {x === 'Fuel Events' && <LocalGasStationIcon style={{ color: '#253053' }} />}
                                                {x === 'Oil Events' && <OpacityIcon style={{ color: '#253053' }} />}
                                                {x === 'Tracking Events' && <PlaceIcon style={{ color: '#253053' }} />}
                                                {x === 'Workshop Events' && <EmojiTransportationIcon style={{ color: '#253053' }} />}
                                                {x === 'Fines Events' && <EventNoteIcon style={{ color: '#253053' }} />}
                                                {x === 'Request For Quotes' && <PlaylistAddCheckIcon style={{ color: '#253053' }} />} */}
                                                <PlaylistAddCheckIcon style={{ color: '#253053' }} />
                                            </Badge>
                                            <div className="pl-3">
                                                <span className="font-weight-bold d-block">{x}</span>
                                                <small className="pb-0 text-black-50 d-block">
                                                    {x + ' Event List'}
                                                </small>
                                            </div>
                                        </div>
                                    </ListItemText>
                                    <ListItemAvatar>
                                        <small className="pb-0 text-black-50 d-block" >
                                            <Tooltip arrow title="Informational" placement="top"><Chip variant="default" color="primary" size="small" label={_.sumBy(this.props.state.workqueue.filter(q => q.workqueue_section === x), (d) => { return parseInt(Number(d.workqueue_ind_info)) })} style={{ backgroundColor: '#59981A', width: 60 }} /></Tooltip>&nbsp;
                                            <Tooltip arrow title="Low" placement="top"><Chip variant="default" color="primary" size="small" label={_.sumBy(this.props.state.workqueue.filter(q => q.workqueue_section === x), (d) => { return parseInt(Number(d.workqueue_ind_low)) })} style={{ backgroundColor: '#eac41b', width: 60 }} /></Tooltip>&nbsp;
                                            <Tooltip arrow title="Moderate" placement="top"><Chip variant="default" color="primary" size="small" label={_.sumBy(this.props.state.workqueue.filter(q => q.workqueue_section === x), (d) => { return parseInt(Number(d.workqueue_ind_moderate)) })} style={{ backgroundColor: '#d27f0e', width: 60 }} /></Tooltip>&nbsp;
                                            <Tooltip arrow title="High" placement="top"><Chip variant="default" color="primary" size="small" label={_.sumBy(this.props.state.workqueue.filter(q => q.workqueue_section === x), (d) => { return parseInt(Number(d.workqueue_ind_high)) })} style={{ backgroundColor: '#bf1910', width: 60 }} /></Tooltip>&nbsp;
                                            <Tooltip arrow title="Critical" placement="top"><Chip variant="default" color="primary" size="small" label={_.sumBy(this.props.state.workqueue.filter(q => q.workqueue_section === x), (d) => { return parseInt(Number(d.workqueue_ind_critical)) })} style={{ backgroundColor: '#8d110a', width: 60 }} /></Tooltip>
                                        </small>
                                    </ListItemAvatar>
                                </ListItem>
                                {
                                    this.props.state.workqueue.filter(q => q.workqueue_section === x).map(f => {
                                        return <Collapse in={this.state.escalation === x} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding style={{ width: 600 }}>
                                                <ListItem style={{ paddingLeft: 10,width: 600 }} button onClick={() => this.handleEscalationItemClose(f)} >
                                                    <ListItemText>
                                                        <div className="align-box-row">
                                                            <Badge variant="dot">
                                                                <ArrowRightIcon style={{ color: '#253053' }} />
                                                            </Badge>
                                                            <div className="pl-3">
                                                                <span className="font-weight-normal d-block">
                                                                    {f.workqueue_title}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ListItemText>
                                                    <ListItemAvatar>
                                                        <small className="pb-0 text-black-50 d-block">
                                                            <Chip variant="default" color="primary" size="small" label={f.workqueue_ind_info ? f.workqueue_ind_info : 0} style={{ backgroundColor: '#59981A' }} />&nbsp;
                                                            <Chip variant="default" color="primary" size="small" label={f.workqueue_ind_low ? f.workqueue_ind_low : 0} style={{ backgroundColor: '#eac41b' }} />&nbsp;
                                                            <Chip variant="default" color="primary" size="small" label={f.workqueue_ind_moderate ? f.workqueue_ind_moderate : 0} style={{ backgroundColor: '#d27f0e' }} />&nbsp;
                                                            <Chip variant="default" color="primary" size="small" label={f.workqueue_ind_high ? f.workqueue_ind_high : 0} style={{ backgroundColor: '#bf1910' }} />&nbsp;
                                                            <Chip variant="default" color="primary" size="small" label={f.workqueue_ind_critical ? f.workqueue_ind_critical : 0} style={{ backgroundColor: '#8d110a' }} />
                                                        </small>

                                                    </ListItemAvatar>
                                                </ListItem>
                                            </List>
                                        </Collapse>
                                    })
                                }
                            </div>
                        })
                        : <ListItem button onClick={() => this.handleEscalationItem('')} style={{ width: 450 }}>
                        <ListItemText>
                            <div className="align-box-row">
                                <div className="pl-3">
                                    <span className="font-weight-bold d-block">{'No Items'}</span>
                                    <small className="pb-0 text-black-50 d-block">
                                        {'You do not have items'}
                                    </small>
                                </div>
                            </div>
                        </ListItemText>
                    </ListItem>
                }
            </List>

        </Popover>
    }

    bellPopover() {
        return <div className="d-flex align-items-center popover-header-wrapper">
            <Popover
                open={this.props.state.notification}
                anchorEl={this.props.state.anchorEl}
                onClose={(event, value) => {
                    event.preventDefault();
                    this.props.actions.toggleNotifications(false, null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                classes={{
                    paper: 'app-header-dots'
                }}
                width={'400px'}
                sx={{width: '400px'}}>
                <div className="popover-custom-lg overflow-hidden">
                    <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm ">
                        <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                        <div className="bg-composed-wrapper--content ">
                            <h4 className="font-size-xl font-weight-bold mb-2 ">
                                Notifications
                            </h4>
                            <p className="opacity-8 mb-0 font-size-l font-weight-bold">
                                You have <b className="text-danger">{this.props.state.notifications_items ? this.props.state.notifications_items.length : 0}</b> new notifications
                            </p>
                        </div>
                    </div>
                    <br />
                    <div className="height-280">
                        <List>
                            {
                                this.props.state.notifications_headers
                                    ? this.props.state.notifications_headers.map((h, index) => {
                                        let items = this.props.state.notifications_items.filter(f => f.workflow_id === h.workflow_id)
                                        return <List className="bg-BellNotification">
                                            <ListItem button
                                                className="bg-BellNotification"
                                                key={'user_workflow_notification_' + h.workflow_id}
                                                style={styles.liPrimary}
                                                onClick={() => {
                                                    this.props.actions.toggleNotificationsList(h.workflow.title);
                                                }}>
                                                <ListItemText
                                                    primary={
                                                        <Badge color="error" badgeContent={<div>{items.length}</div>}>
                                                            <strong style={{ marginRight: '25px', color: '#000 !important' }}>{h.workflow.title} {'  '}{'  '}</strong>
                                                        </Badge>
                                                    }
                                                />
                                                {this.props.state.openNotifications === h.workflow.title ? <ExpandNotificationsLess /> : <ExpandNotificationsMore />}
                                            </ListItem>
                                            <Collapse in={this.props.state.openNotifications === h.workflow.title} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding style={{ maxHeight: 290, overflow: 'auto' }}>
                                                    {
                                                        items.map((itm, index) => {
                                                            return <ListItem button style={styles.cardDropdown}>
                                                                <ListItemText

                                                                    key={'user_workflow_notification_item_' + itm.workflow_queue_task_id + index}
                                                                    onClick={() => {
                                                                        this.props.actions.route('/tasks')
                                                                        this.props.actions.setWorkflowQueueTasksSearchValue(itm.workflow_queue_id)
                                                                        this.props.actions.searchWorkflowQueueTasks()
                                                                        this.props.actions.handleTask(itm.workflow_task.workflow_task_team[0].team_id, itm.user_id, {
                                                                            'workflow_task_type#name': itm.workflow_task_type.name,
                                                                            'workflow#name': itm.workflow.name,
                                                                            'workflow#title': itm.workflow.title,
                                                                            'workflow_task#title': itm.workflow_task.title,
                                                                            'workflow_task#name': itm.workflow_task.name,
                                                                            'workflow_queue_task#workflow_queue_id': itm.workflow_queue_id,
                                                                            'workflow_queue_task#workflow_queue_task_id': itm.workflow_queue_task_id,
                                                                            'workflow_task#workflow_task_id': itm.workflow_task_id,
                                                                            'workflow#workflow_id': itm.workflow_id
                                                                        })
                                                                        this.props.actions.toggleNotifications(false, null)
                                                                    }}
                                                                    primary={<span style={{ color: 'black' }}>Task #{itm.workflow_queue_id}</span>}
                                                                    secondary={<small>{itm.notification}</small>}
                                                                />
                                                            </ListItem>
                                                        })
                                                    }
                                                </List>
                                            </Collapse>
                                        </List>
                                    })
                                    : ''
                            }
                        </List>
                    </div>
                </div>
            </Popover>
    </div>
    }

    profilePopOver() {
        const open2 = Boolean(this.state.anchorEl2);
        return <Popover
            open={open2}
            width={'300px'}
            anchorEl={this.state.anchorEl2}
            onClose={this.handleClose2}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            sx={{width: '300px'}}
            >
            <List
                component="div"
                className="list-group-flush text-left bg-transparent"
            >
                <ListItem onClick={(event, value) => {
                    event.preventDefault();
                    this.handleClose2()
                    this.props.actions.route("/profile");
                    this.props.actions.setNavigationMenu({
                        selectedItemMain: "Profile",
                        selectedItemMainDesc: "Profile",
                        selectedItemMainTitle: "Profile",
                    });
                }}>
                    <div className="align-box-row">
                        <Badge
                            onClick={(event, value) => {
                                event.preventDefault();
                                this.handleClose2()
                                this.props.actions.route("/profile");
                            }}
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            variant="dot">
                            {/* <PersonIcon style={{ color: '#253053' }} /> */}
                            <UserAvatar width={50} height={50} />
                        </Badge>
                        <div className="pl-3">
                            <span className="font-weight-bold d-block">
                                My Profile
                            </span>
                            <small className="pb-0 text-black-50 d-block">
                                {this.props.state.user.firstname} {this.props.state.user.lastname}
                            </small>
                        </div>
                    </div>
                </ListItem>
                {
                <ListItem onClick={(event, value) => {
                    event.preventDefault();
                    this.handleClose2()
                    this.props.actions.route("/hierarchy");
                }}>
                    <div className="align-box-row">
                        <Badge
                            onClick={(event, value) => {
                                event.preventDefault();
                                this.handleClose2()
                                this.props.actions.route("/hierarchy");
                            }}
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            variant="dot">
                            <AccountBalanceIcon style={{ color: '#253053' }} />
                        </Badge>
                        <div className="pl-3">
                            <span className="font-weight-bold d-block">
                                Customer Structure
                            </span>
                            <small className="pb-0 text-black-50 d-block">
                                {this.props.state.user.firstname} {this.props.state.user.lastname}
                            </small>
                        </div>
                    </div>
                </ListItem>
                }
                { 
                //  <ListItem onClick={(event, value) => {
                //     event.preventDefault();
                //     this.handleClose2()
                //     this.props.actions.route("/hierarchyteam");
                // }}>
                //     <div className="align-box-row">
                //         <Badge
                //             onClick={(event, value) => {
                //                 event.preventDefault();
                //                 this.handleClose2()
                //                 this.props.actions.route("/hierarchyteam");
                //             }}
                //             overlap="circle"
                //             anchorOrigin={{
                //                 vertical: 'bottom',
                //                 horizontal: 'right'
                //             }}
                //             variant="dot">
                //             <PeopleIcon style={{ color: '#253053' }} />
                //         </Badge>
                //         <div className="pl-3">
                //             <span className="font-weight-bold d-block">
                //                 Team Structure
                //             </span>
                //             <small className="pb-0 text-black-50 d-block">
                //                 {this.props.state.user.firstname} {this.props.state.user.lastname}
                //             </small>
                //         </div>
                //     </div>
                // </ListItem>
                }
                {
                    this.props.state.user.isadmin ?
                        <ListItem onClick={(event, value) => {
                            event.preventDefault();
                            this.handleClose2()
                            this.props.actions.setNavigationMenu({
                                selectedItemMain: "Team Management",
                                selectedItemMainDesc: "Team Management",
                                selectedItemMainTitle: "Team Management",
                            });
                            this.props.actions.route("/management");
                        }}>
                            <div className="align-box-row">
                                <Badge
                                    onClick={(event, value) => {
                                        event.preventDefault();
                                        this.handleClose2()
                                        this.props.actions.route("/management");
                                    }}
                                    //overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                //variant="dot"
                                >
                                    <SupervisorAccountIcon style={{ color: '#253053' }} />
                                </Badge>
                                <div className="pl-3">
                                    <span className="font-weight-bold d-block">
                                        Team Management
                                    </span>
                                    <small className="pb-0 text-black-50 d-block">
                                        Manage System Users
                                    </small>
                                </div>
                            </div>
                        </ListItem>

                        : ''
                }
                {
                    this.props.state.user.isadmin && 1 != 1 ?
                        <ListItem onClick={(event, value) => {
                            event.preventDefault();
                            this.handleClose2()
                            this.props.actions.route("/licenseeaccount");
                        }}>
                            <div className="align-box-row">
                                <Badge
                                    onClick={(event, value) => {
                                        event.preventDefault();
                                        this.handleClose2()
                                        this.props.actions.route("/licenseeaccount");
                                    }}
                                    //overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                //variant="dot"
                                >
                                    <SyncIcon style={{ color: '#253053' }} />
                                </Badge>
                                <div className="pl-3">
                                    <span className="font-weight-bold d-block">
                                        Licensee Account
                                    </span>
                                    <small className="pb-0 text-black-50 d-block">
                                        Create and Manage Licensee Account
                                    </small>
                                </div>
                            </div>
                        </ListItem>
                    : ''
                }
                {
                    this.props.state.user.user_team.filter(x => x.team.name === 'licensee_account').length  && 1 != 1 ?
                        <ListItem onClick={(event, value) => {
                            event.preventDefault();
                            this.handleClose2()
                            this.props.actions.route("/subaccount");
                        }}>
                            <div className="align-box-row">
                                <Badge
                                    onClick={(event, value) => {
                                        event.preventDefault();
                                        this.handleClose2()
                                        this.props.actions.route("/subaccount");
                                    }}
                                    //overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                //variant="dot"
                                >
                                    <SyncIcon style={{ color: '#253053' }} />
                                </Badge>
                                <div className="pl-3">
                                    <span className="font-weight-bold d-block">
                                        Sub Account
                                    </span>
                                    <small className="pb-0 text-black-50 d-block">
                                        Create and Manage Sub Account
                                    </small>
                                </div>
                            </div>
                        </ListItem>
                    : ''
                }
                {
                    this.props.state.user.user_team.filter(x => x.team.name === 'sub_account').length  && 1 != 1 ?
                        <ListItem onClick={(event, value) => {
                            event.preventDefault();
                            this.handleClose2()
                            this.props.actions.route("/reseller");
                        }}>
                            <div className="align-box-row">
                                <Badge
                                    onClick={(event, value) => {
                                        event.preventDefault();
                                        this.handleClose2()
                                        this.props.actions.route("/reseller");
                                    }}
                                    //overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                //variant="dot"
                                >
                                    <SyncIcon style={{ color: '#253053' }} />
                                </Badge>
                                <div className="pl-3">
                                    <span className="font-weight-bold d-block">
                                        Reseller Account
                                    </span>
                                    <small className="pb-0 text-black-50 d-block">
                                        Create and Manage Reseller Account
                                    </small>
                                </div>
                            </div>
                        </ListItem>
                    : ''
                }
                {
                    this.props.state.user.user_team.filter(x => x.team.name === 'reseller_account').length && 1 != 1 ?
                        <ListItem onClick={(event, value) => {
                            event.preventDefault();
                            this.handleClose2()
                            this.props.actions.route("/clientaccount");
                        }}>
                            <div className="align-box-row">
                                <Badge
                                    onClick={(event, value) => {
                                        event.preventDefault();
                                        this.handleClose2()
                                        this.props.actions.route("/clientaccount");
                                    }}
                                    //overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                //variant="dot"
                                >
                                    <SyncIcon style={{ color: '#253053' }} />
                                </Badge>
                                <div className="pl-3">
                                    <span className="font-weight-bold d-block">
                                        Client Account
                                    </span>
                                    <small className="pb-0 text-black-50 d-block">
                                        Create and Manage Client Account
                                    </small>
                                </div>
                            </div>
                        </ListItem>
                    : ''
                }
                {
                    this.props.state.user.isadmin  && 1 != 1 ?
                        <ListItem onClick={(event, value) => {
                            event.preventDefault();
                            this.handleClose2()
                            this.props.actions.setNavigationMenu({
                                selectedItemMain: "Xero Accounting",
                                selectedItemMainDesc: "Xero Accounting",
                                selectedItemMainTitle: "Xero Accounting",
                            });
                            this.props.actions.route("/xero");
                        }}>
                            <div className="align-box-row">
                                <Badge
                                    onClick={(event, value) => {
                                        event.preventDefault();
                                        this.handleClose2()
                                        this.props.actions.route("/xero");
                                    }}
                                    //overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                //variant="dot"
                                >
                                    <SyncIcon style={{ color: '#253053' }} />
                                </Badge>
                                <div className="pl-3">
                                    <span className="font-weight-bold d-block">
                                        Xero Accounting
                                    </span>
                                    <small className="pb-0 text-black-50 d-block">
                                        Manage Xero Data Integration
                                    </small>
                                </div>
                            </div>
                        </ListItem>
                    : ''
                }
                {
                    this.props.state.user.isadmin ?
                        <ListItem onClick={(event, value) => {
                            event.preventDefault();
                            this.handleClose2()
                            this.props.actions.setNavigationMenu({
                                selectedItemMain: "Release Notes",
                                selectedItemMainDesc: "Release Notes",
                                selectedItemMainTitle: "Release Notes",
                            });
                            this.props.actions.route("/releasenote");
                        }}>
                            <div className="align-box-row">
                                <Badge
                                    onClick={(event, value) => {
                                        event.preventDefault();
                                        this.handleClose2()
                                        this.props.actions.route("/releasenote");
                                    }}
                                    //overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                //variant="dot"import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
                                >
                                    <EventNoteOutlinedIcon style={{ color: '#253053' }} />
                                </Badge>
                                <div className="pl-3">
                                    <span className="font-weight-bold d-block">
                                        Release Notes
                                    </span>
                                    <small className="pb-0 text-black-50 d-block">
                                        Publish Release System Notes
                                    </small>
                                </div>
                            </div>
                        </ListItem>
                    : ''
                }
                <ListItem className="d-block text-center p-3"
                    onClick={(event) => {
                        event.preventDefault();
                        this.props.actions.signoutCognitoUser(this.props.state.cognitoUser)
                    }}>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn-primary">
                        <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={faSignOutAlt} />
                        </span>
                        {/* <span onClick={(event) => {
                event.preventDefault();
                                this.props.actions.signoutCognitoUser(this.props.state.cognitoUser)
                            }} className="btn-wrapper--label">Logout
                    </span> */}

                        <span
                            onClick={this.handleSingout}
                            // (event) => {
                            //     event.preventDefault();

                            //     //this.props.actions.signoutCognitoUser(this.props.state.cognitoUser)
                            // }}
                            className="btn-wrapper--label">Sign out
                        </span>
                    </Button>
                </ListItem>
            </List>
        </Popover>
    }

    render() {


        return <>
            <div className="d-flex align-items-center popover-header-wrapper">
                <span className="pr-2">
                 
                        <Button
                            onClick={(event, value) => {
                                event.preventDefault();
                                this.props.actions.route("/faq");
                                //this.props.actions.checkDocument360()
                            }}
                            className="btn-transition-none bg-neutral-first text-first font-size-lg p-0 d-inline-block-off shadow-none text-center d-44 position-relative"
                            style={{backgroundImage: "none !important", width: "50px", borderRadius: '28px !important'}}
                            sx={{ borderRadius: '28px !important'}}>
                            <HelpOutlineIcon style={{ color: 'blue' }} />
                        </Button>
                </span>
                <span className="pr-2">
                    <Badge
                        //variant="dot"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        onClick={(event, value) => {
                            event.preventDefault();
                            this.props.actions.toggleNotifications(true, event.currentTarget)
                        }}
                        overlap="circle"
                        color={'primary'}
                        style={{ color: '#fff' }} primary={true}
                        badgeContent={this.props.state.notifications_items ? this.props.state.notifications_items.length : 0}
                        //classes={{ badge: 'bg-success badge-circle' }}
                    >
                        <Button
                            className="btn-transition-none bg-neutral-first text-first font-size-lg p-0 d-inline-block-off shadow-none text-center d-44 position-relative"
                            style={{backgroundImage: "none !important", width: "50px", borderRadius: '28px !important'}}
                            sx={{ borderRadius: '28px !important'}}
                        >
                            <BellIcon
                                color="blue"
                                width='25'
                                active='false'
                                animationSpeed='0.6'
                                animate={this.props.state.notifications_items ? this.props.state.notifications_items.length : 0
                                } />
                            {/* <span>
                <NotificationsActiveTwoToneIcon />
              </span> */}
                        </Button>

                    </Badge>
                </span>
                {this.bellPopover()} 
                 <span className="pr-2">
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        color={'primary'}
                        style={{ color: '#fff' }} primary={true}
                        badgeContent={this.props.state.workqueue_count ? this.props.state.workqueue_count : 0}
                    >
                        <Button
                            onClick={this.handleEscalation}
                            className="btn-transition-none bg-neutral-first text-first font-size-lg p-0 d-inline-block-off shadow-none text-center d-44 position-relative"
                            style={{backgroundImage: "none !important", width: "50px", borderRadius: '28px !important'}}
                            sx={{ borderRadius: '28px !important'}}>
                            <ListAltIcon style={{ color: 'blue' }} />
                        </Button>
                    </Badge>
                </span>
                {this.escalationPopover()} 
                {/* <span className="pr-2">
                    <Badge
                        //variant="dot"
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        badgeContent=""
                    >
                        <Button
                            onClick={this.handleClick}
                            className="btn-transition-none bg-neutral-first text-first font-size-lg p-0 d-inline-block-off shadow-none border-0 text-center d-44 rounded position-relative"
                            style={{backgroundImage: "none !important", width: "100px"}}>
                            <LanguageIcon style={{ color: 'red' }} />
                        </Button>
                    </Badge>
                </span>
                {this.languagePopover()} */}
                {/* <span className="pr-2">
                    <Button
                        onClick={this.handleClick2}
                        className="bg-neutral-first text-first font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded btn-transition-none"
                        style={{backgroundImage: "none !important", width: "100px"}}>
                        <span>
                            <PeopleAltTwoToneIcon style={{ color: 'blue' }} />
                        </span>
                    </Button>
                    {this.profilePopOver()}
                </span> */}
            </div>
        </>
    }
}


const mapStateToProps = (state) => {
    return {
        state: {
            ...state.app,
            //...state.responsiveDrawer,
            ...state.connector,
            ...state.signedIn,
            accounts: {
                ...state.accounts,
            },
            user: {
                ...state.user,
            },
            nav: {
                ...state.navigationMenu
            },
            ...state.cognito,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            {
                ...signedInActions,
                ...redirectActions,
                ...cognitoActions,
                ...actionsWorkflow,
                ...accountActions,
                ...workqueueActions,
                ...navigationMenuActions
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNotification);