import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'

import * as styles from './styles'
import * as actions from './actions'
import moment from 'moment';
import TextField from '@mui/material/TextField'
import Upload from '../../Upload/component'
import MultiSelect from '../../../controls/multiselect'

class BillingSendInvoices extends Component {
    componentDidMount() {
        this.props.actions.reset()
    }

    getForm() {
        return <div>
            <Card title={window.t.en('CIS E-mail List')}
                subtitle={window.t.en('Include the below users in communication')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row><br /></Row>
                    <Row>
                        <Col xl={12}>
                            <MultiSelect
                                name={'autocomplete_cis_contacts'}
                                title={window.t.en('Select Users')}
                                info={{
                                    show: false
                                }}
                                fullWidth={true}
                                fieldvalue={'user_id'}
                                fieldtext={'email'}
                                displayfields={['firstname', 'email']}
                                displaywidth={['75', '25']}
                                data={this.props.state.ciscontacts}
                                onSelect={(args) => {
                                    //console.log('setSupplierAutoCompleteSelected', args)
                                    this.props.actions.setSelectedCISContactList(args)
                                }}
                                onClick={() =>
                                    this.props.actions.getCISContactsAutoCompleteItems('')
                                }
                                onFilter={(args) => {
                                    this.props.actions.getCISContactsAutoCompleteItems(args)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {this.uploadFile()}
                        </Col>
                    </Row>
                </Container>}
            />
            {
                this.commentsView()
            }
        </div>
    }

    uploadFile() {
        return <Row>
            <Col xl={12}>
                <br />
                <Upload
                    id={'upload_file'}
                    prefix={'Interest_file_upload_' + moment().format("YYYYMMDDmmss")}
                    header={false}
                    list={[{ value: 0, text: 'Interest File' }]}
                />
            </Col>
        </Row>
    }

    commentsView() {
        return <div>
            <Card
                title={window.t.en('Billing Comments')}
                subtitle={window.t.en('Capture comments')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomment'}
                                key={'txtcomment'}
                                label={window.t.en('Enter Comments...')}
                                fullWidth={true}
                                variant="outlined"
                                value={this.props.state.comments}
                                onChange={
                                    (event) => {
                                        this.props.actions.setValue('comments', event.target.value)
                                    }
                                }
                            /></Col>
                    </Row>
                </Container>} />
        </div>
    }
    render() {
        return this.getForm()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.billingsendinvoices,
            user: {
                ...state.user
            },
            upload: {
                ...state.upload
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingSendInvoices)