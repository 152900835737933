import * as types from './types'

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TRACKER_COMPONENT_INPUT_VALUE,
            payload: data
        })
    }
}

export const getTrackingData = (source) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_TRACKING_DATA,
            payload: source
        })
    }
}

export const setTrackingData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TRACKING_DATA,
            payload: {
                data: data,
                single: getState().tracker.source.single,
                source: getState().tracker.source.source,
                display: getState().tracker.source.display,
                icon: getState().tracker.source.icon,
                map: getState().tracker.source.map,
                latitude: getState().tracker.source.latitude,
                longitude: getState().tracker.source.longitude
            }
        })
    }
}

export const setTrackingSource = (source) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TRACKING_SOURCE,
            payload: source
        })
    }
}

export const locationSearch = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_TRACKER_ADDRESS_LOOKUP,
            payload: {
                location: search,
                type: "search_location"
            }
        })
    }
}

export const locationSearchDone = (results, payload) => {
    return (dispatch, getState) => {
            //console.log('addressSearchDone results', results)
            //console.log('addressSearchDone payload', payload)
            results = results.map(x => { return {...x, text: x.display_name, value: x.lat +','+ x.lon}})
            ///dispatch(setInputValue({ prop: payload.prop, value: results}))
            
        dispatch({
            type: types.SET_TRACKER_ADDRESS_LOOKUP,
            payload: results
        })
    }
}

export const setLocation = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_TRACKER_ADDRESS_LOCATION,
            payload: data
        })
    }
}

export const setMarker = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_TRACKER_ADDRESS_MARKER,
            payload: data
        })
    }
}