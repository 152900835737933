import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import { FormControlLabel, RadioGroup, Radio, Button } from "@mui/material";
import { Container, Row, Col } from 'react-grid-system'
import ViewQuotation from '../../controls/view_quotation'
import CustomerAddress from '../../controls/customer_address'
import TextField from '@mui/material/TextField'
import Fields from '../../controls/cardfields'
import ViewCustomer from '../../controls/view_customer'
import Card from '../../controls/card'
import PdfViewer from '../PDF/component'
import Loading from '../Loading/component'
import QuoteEdit from '../QuoteEdit/component'
import Select from '@mui/material/Select'
import Upload from '../Upload/component'

import * as config from '../../config'
import AutoComplete from '../../controls/autocomplete'
import * as styles from './styles'
import * as actions from './actions'

class WorkflowQuoteReview extends Component {

    componentDidMount() {
        let workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0].data
        this.props.actions.setInputValue({ prop: 'newused', value: 'New'})
        //console.log('workflow_queue_data componentWillMount', workflow_queue_data)
        this.props.actions.setComponentState(workflow_queue_data)
        this.props.actions.setInputValue({ prop: 'comments', value: '' })
        //this.props.actions.setVehicleInput({prop: 'distancepermonth', value: event.target.value, inx: this.props.inx})
    }

    getContract(data) {
        console.log('getContractx data', data)
        return <Card
            title={window.t.en('Quote Confirmation')}
            subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Contract Type:', value: data.contractname },
                        { size: 4, label: 'Vehicle Category:', value: data.customerderivativecategory },
                        { size: 4, label: ' Monthly Distance:', value: data.distancepermonth },
                        { size: 4, label: 'Contract Period:', value: data.contractperiod },
                        { size: 4, label: 'Quote For:', value: data.quotefor },
                        { size: 4, label: 'RFQ Date:', value: data.quotedate },
                        { size: 4, label: 'Vehicle Color:', value: data.color },
                        { size: 4, label: 'Vehicle Location:', value: data.location },
                        { size: 4, label: 'Special  Vehicle:', value: data.specialistvehicle },
                        { size: 4, label: 'Vehicle Group:', value: data.vehiclegroup },
                        { size: 4, label: ' Operating Terrain:', value: data.terrain },
                        { size: 4, label: 'Vehicle Usage:', value: data.usage },
                        // {size: 4, label: 'Comments:', value: data.comments},
                        // {size: 4, label: 'Quotatio Notes:', value: data.quotationotes},
                    ]}
                />
            }
        />
    }

    getCustomer(customer) {
        console.log('getContractx customer', customer)
        let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        //console.log('Customer',customer)
        let oq_key = `a45cc3095246cdd6895faaedad652e8744feaefedbfaa7d974e7d68acf726bf2fefb622b7d2cb01713a91717bfc76a655559783b7792c8621832b675fef5c693&filters=customer_id=${customer.customer_id}`

        return <div>
            <Card
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                content={
                    <ViewCustomer id={customer.customer_id} nocard={true} min={true} />
                    // <Fields nocard={true}
                    //     fields={[
                    //         { size: 4, label: 'Customer Name:', value: customer.customername },
                    //         { size: 4, label: 'Cost Centre:', value: customer.costcentre },
                    //         { size: 4, label: 'Account Number:', value: customer.accountnumber },
                    //         { size: 4, label: 'Contact Person Name:', value: contact.fullname },
                    //         { size: 4, label: 'Contact Number:', value: contact.mobile },
                    //         { size: 4, label: 'Contact Email:', value: contact.email },
                    //     ]}
                    // />
                }
            />
            <br /><br />
            <iframe src={`${config.oq.uri}${oq_key}`}
                height="550px"
                width="100%"
                style={{
                    border: "none",
                    margin: 0,
                    padding: '-25px',
                    alignSelf: "center",
                    //  boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), -4px -4px 30px 19px rgba(0, 0, 0, 0.1)" 
                }} />
            <br /><br />
            <CustomerAddress id={customer.customer_id} customername={customer.customername} />
        </div>
    }


    getAdjustments(data) {
        console.log('getAdjustmentsx data', data)
        console.log('getAdjustmentsx supplieraddress', this.props.state.supplieraddress)
        //console.log('Customer',customer)
        return <div>
            <Card
                title={window.t.en('Supplier Quote Adjustments')}
                subtitle={window.t.en('Allows adjusting vehicle price and uploading supporting documents.')}
                content={
                    <div>
                        <RadioGroup
                            row={true}
                            onChange={((event, value) => {
                                //this.props.actions.setInputValue('frequency', event.target.value);
                                console.log('getAdjustmentsx rad', event.target.value)
                                this.props.actions.setInputValue({ prop: 'supplierquoteadjustments', value: event.target.value })
                                //this.props.actions.setVehicleInput({prop: 'newused', value: event.target.value, inx: this.props.inx})
                                //this.setState({showDays: event.target.value === 'week' ? true : false});
                                //this.setState({showMonths: (event.target.value === 'month' || event.target.value === 'year') ? true : false});
                                //this.setState({showYears: event.target.value === 'year' ? true : false});
                            })}
                            defaultValue={this.props.state.supplierquoteadjustments}
                        >
                            <FormControlLabel
                                key={'No'}
                                value={'No'}
                                control={<Radio />}
                                label={'No'}
                            />
                            <FormControlLabel
                                key={'Yes'}
                                value={'Yes'}
                                control={<Radio />}
                                label={'Yes'}
                            />
                        </RadioGroup>
                        <div>
                            {
                                this.props.state.supplierquoteadjustments === 'Yes'
                                    ? <Container style={{ backgroundColor: '#f9f9f9' }}>
                                        <Row>
                                            <Col xl={12}>
                                                <br />
                                                <strong style={{ fontSize: '15px' }}>Select Supplier:</strong>
                                                <AutoComplete
                                                    name={'field_'}
                                                    title={'Select supplier for pricing'}
                                                    data={this.props.state.supplier_data}
                                                    value={this.props.state.suppliername}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    fieldvalue={'supplier_id'}
                                                    displayfields={['suppliername', 'accountnumber']}
                                                    displaywidth={['50', '50']}
                                                    // disabled={this.props.state.suppliername ? true : false}address
                                                    onSelect={(args) => {
                                                        let supplier = this.props.state.supplier_data.filter(x => x.supplier_id === args.value)
                                                        let supplieraddress = supplier.length ? supplier[0].supplieraddress ? supplier[0].supplieraddress[0] : { address: {} } : { address: {} }

                                                        this.props.actions.setInputValue({ prop: 'supplieraddress', value: supplieraddress })
                                                        this.props.actions.setInputValue({ prop: 'suppliername', value: args.text })
                                                        this.props.actions.setInputValue({ prop: 'supplier_id', value: args.value })
                                                    }}
                                                    onClick={() =>
                                                        this.props.actions.getLookupData('supplier', 'suppliername', '')
                                                    }
                                                    onFilter={(search) => {
                                                        if (search) {
                                                            this.props.actions.getLookupData('supplier', 'suppliername', search)
                                                            this.props.actions.setInputValue({ prop: 'suppliername', value: search })

                                                            let supplier = this.props.state.supplier_data.filter(x => x.supplier_name === search)
                                                            let supplieraddress = supplier.length ? supplier[0].supplieraddress ? supplier[0].supplieraddress[0] : { address: {} } : { address: {} }

                                                            this.props.actions.setInputValue({ prop: 'supplieraddress', value: supplieraddress })
                                                        } else {
                                                            this.props.actions.getLookupData('supplier', 'suppliername', '')
                                                            this.props.actions.setInputValue({ prop: 'supplier_id', value: null })
                                                            this.props.actions.setInputValue({ prop: 'suppliername', value: '' })
                                                            this.props.actions.setInputValue({ prop: 'supplieraddress', value: { address: {} } })
                                                        }

                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                {
                                                    this.props.state.supplieraddress.address.line1
                                                        ? <div style={styles.fieldContainer}>
                                                            <div style={styles.fieldContent}>
                                                                <strong style={{ fontSize: '15px' }}>Supplier Address:</strong>
                                                                <table>
                                                                    <tr>
                                                                        <td>Street Address</td>
                                                                        <td>{this.props.state.supplieraddress.address.line1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>City</td>
                                                                        <td>{this.props.state.supplieraddress.address.line2}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>State</td>
                                                                        <td>{this.props.state.supplieraddress.address.line3}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Postal Code</td>
                                                                        <td>{this.props.state.supplieraddress.address.postalcode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Country</td>
                                                                        <td>{this.props.state.supplieraddress.address.province}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <strong style={{ fontSize: '15px' }}>Upload Supporting Documents:</strong>
                                                <br />
                                                <br />
                                                <Upload
                                                    id={'supplier_quote'}
                                                    prefix={'supplier_quote'}
                                                    header={false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <strong style={{ fontSize: '15px' }}>New or Used Vehicle:</strong>
                                                <br />
                                                <RadioGroup
                                                    row={true}
                                                    onChange={((event, value) => {
                                                        //this.props.actions.setInputValue('frequency', event.target.value);

                                                        console.log('getAdjustmentsx rad', event.target.value)
                                                        this.props.actions.setInputValue({ prop: 'newused', value: event.target.value })
                                                        //this.setState({showDays: event.target.value === 'week' ? true : false});
                                                        //this.setState({showMonths: (event.target.value === 'month' || event.target.value === 'year') ? true : false});
                                                        //this.setState({showYears: event.target.value === 'year' ? true : false});
                                                    })}
                                                    defaultValue={this.props.state.newused}
                                                >
                                                    <FormControlLabel
                                                        key={'New'}
                                                        value={'New'}
                                                        control={<Radio />}
                                                        label={'New'}
                                                    />
                                                    <FormControlLabel
                                                        key={'Used'}
                                                        value={'Used'}
                                                        control={<Radio />}
                                                        label={'Used'}
                                                    />
                                                </RadioGroup>
                                            </Col>
                                        </Row>
                                    </Container>
                                    : ''
                            }
                        </div>

                    </div>
                }
            />
            {/* <CustomerAddress id={customer.customer_id} customername={customer.customername} /> */}
        </div>
    }

    getVehicles(vehicles) {
        //console.log('getVehicles vehicles', vehicles.length)
        return <Card
            title={window.t.en('Vehicle Quotation')}
            subtitle={window.t.en('Vehicle list quoted')}
            content={<Container style={{ margin: 0 }} className="custom-container">
            <Row>
            {
                vehicles.length
                    ? vehicles.map((x, i) => {
                        return <Col xl={12 / vehicles.length}>
                            <QuoteEdit
                                id={'quote_edit_' + x.quotenumber + '_' + i}
                                key={'quote_edit_' + x.quotenumber + '_' + i}
                                inx={i}
                                quoteadjustments={this.props.state.supplierquoteadjustments}
                                data={x}
                            />
                        </Col>
                    })
                    : 'No Vehicles'
            }
            </Row>
            </Container>
            }
        />
    }

    getDocuments(data) {
        let documents = data.documents.documents
        return <Card
            title={window.t.en('Customer RFQ')}
            subtitle={window.t.en('Uploaded Customer RFQ Document ')}
            content={
                documents.length
                    ? <PdfViewer
                        key={'quotation_viewer_'}
                        documents={documents}
                        multiple={true}
                        height={500}
                        edit={false}
                    />
                    : <Loading message={'Retrieving documents...'} />
            }
        />
    }

    getComments() {
        return <Card
            title={window.t.en('Quotation Notes')}
            subtitle={window.t.en('Enter Review Comments')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        {this.props.workflow_queue_data.workflow_queue_id && <ViewQuotation
                            key={this.props.workflow_queue_data.workflow_queue_id}
                            id={this.props.workflow_queue_data.workflow_queue_id}
                        />
                        }</Col>
                    <Col xl={12}>
                        <TextField
                            variant="outlined"
                            fullWidth={true}
                            margin="normal"
                            id={'txt_laodfactor'}
                            label={'Enter Notes'}
                            hintText={'Enter Load Factor %'}
                            value={this.props.state.comments}
                            multiline={true}
                            rows={5}
                            onChange={(event, value) => {
                                this.props.actions.setInputValue({ prop: 'comments', value: event.target.value })
                            }}
                        />
                    </Col>
                </Row>
            </Container>
            }
        />
    }

    getView(data) {
        return <div>
            {this.getCustomer(data.customer)}
            {this.getAdjustments(data)}
            {this.getContract(data)}
            {this.getVehicles(data.quotations)}
            {this.getDocuments(data)}
            {this.getComments()}
        </div>
    }

    render() {
        let workflow_queue_data = this.props.state
        //console.log('workflow_queue_data this.props', this.props.workflow_queue_data)
        //console.log('workflow_queue_data render', workflow_queue_data)
        return (
            workflow_queue_data.customer ? this.getView(workflow_queue_data) : 'Loading...'
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowQuoteReview
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowQuoteReview)