import * as colours from '@mui/material/colors'
import Theme from '../../theme'
import { Padding } from '@mui/icons-material'

export const back = {
    cursor: 'pointer',
    marginTop: 15,
    marginLeft: 15,
    width: 30,
    height: 30,
    // backgroundColor: Theme.red[500]
}

export const badge = {
    paddingTop: '5px',
    paddingLeft: '8px',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    float: 'right',
    top: '-50px',
    backgroundColor: colours.red[500],
}

export const divider = {
    marginTop: 15,
    marginBottom: 15
}

export const logoSize = {
    width: 240,
    padding: 20 
}

export const hoverColor = {
    "&.Mui-selected": {
        color: "#fff",
      backgroundColor: "transparent"
    },
    "&.Mui-focusVisible": {
        color: "#fff",
      backgroundColor: "transparent"
    },
    ":hover": {
        color: "#fff",
      backgroundColor: "transparent"
    }
}

export const hoverColorBidvest = {
    "&.Mui-selected": {
        color: "#152461",
      backgroundColor: "#FFE516",
      "div svg": {
        "path" : {fill: "#2947AE"},
        "g path": {fill: "#2947AE"}
    },
      ":hover": {
        color: "#152461",
      backgroundColor: "#FFE516",
      "div svg": {
          "path" : {fill: "#2947AE"},
          "g path": {fill: "#2947AE"}
      }
    }
    },
    "&.Mui-focusVisible": {
        color: "#152461",
      backgroundColor: "#FFE516",
      "div svg": {
        "path" : {fill: "#2947AE"},
        "g path": {fill: "#2947AE"}
    },
      ":hover": {
        color: "#152461",
      backgroundColor: "#FFE516",
      "div svg": {
          "path" : {fill: "#2947AE"},
          "g path": {fill: "#2947AE"}
      }
    }
    },
    ":hover": {
        color: "#152461",
        backgroundColor: "#FFE516",
        "div svg": {
            "path" : {fill: "#2947AE"},
            "g path": {fill: "#2947AE"}
        }
    },
    borderRadius: 8
}

export const theme = Theme

export const drawer = {
    //padding: 5,//
    fontSize: '10px',
    width: '375px',
    backgroundColor: 'green !important'
    //opacity: 0.95,
    //position: 'fixed',
    //top: '100px'
}


export const muiListCustom = {
    paddingLeft: '20px !important',
}

export const scrollbar = {
    backgroundColor: colours.red[500],
    width: 10
}

export const tabs = {
    height: '100%',
    //backgroundColor: colours.red[500]
}

export const tab = {
    height: '65px',
    // backgroundColor: colours.red[500]
}

export const liPrimary = {
    fontSize: '15px',
    fontWeight: 'bold',
}

export const subPrimary = {
    fontSize: '15px',
    fontWeight: 'bold',
    //backgroundColor:  colours.grey[400]
}

export const mmHeader = {
    fontSize: '14px',
    fontWeight: 'bold',
}

export const mmItem = {
    fontSize: '12px',
    //backgroundColor: 'gray',
}

export const liHeader = {
    //fontSize: '15px',
    ////backgroundColor: '#dcd6d7',
    //borderBottom: '1px solid red'
}

export const li = {
    //fontSize: '15px',
    //borderBottom: '1px solid red'
}

export const menuIcon = {
    //fontSize: 16,
    /*     width: 10,
        height: 10 */
}

export const icon = {
    // color: colours.red[500],
    fontSize: '15px',
    // marginTop: '5px'
}

export const icon_sm = {
    color: colours.grey[500],
    fontSize: '20px',
    marginTop: '10px'
}

export const smallAvatar = {
    width: '32px !important',
    height: '32px !important',
}


const drawerWidth = 280;

  export const  drawerPaper= {
    width: drawerWidth,
  }