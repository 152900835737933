import React, { Component, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button'

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
//Icons
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PublicIcon from "@mui/icons-material/Public";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {ReactComponent as VisibilityIcon} from '../../../assets/img/svg/icons/view_grid.svg';

import WorkflowQueueTaskDocuments from '../WorkflowQueueTaskDocuments/component'

import { green, orange, red, grey } from '@mui/material/colors'

import '../../../assets/css/react-vertical-timeline.css'

import { Card, CardHeader, CardContent,Box } from '@mui/material'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IconButton from '@mui/material/IconButton';

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import Section from '../../../controls/section'

import * as actions from './actions'
import * as config from '../../../config'
import * as styles from './styles'

const steps = [
    'Test',
    'Test',
    'Test',
    'Test',
    'Test',
    'Test',
  ]; 

class WorkflowTimeline extends Component {
    constructor(props) {
        super(props);
        this.workflowStepperRef = React.createRef();
      }

    componentDidMount() {
        this.props.actions.loadWorkflowTimeline(
            this.props.name,
            this.props.team_id,
            this.props.user_id,
            this.props.workflow_queue_id,
            this.props.is_visible,
            this.props.searchable
        )
    }

    getWorkflowTaskUserEvent(task) {
        if (task.failed) {
            return 'This task failed to execute and was requeued for processing by the workflow engine. The error was: ' + task.failure_message
        }

        if (task.cancelled) {
            return 'This task was cancelled by ' + task.cancelled_by.user.firstname + ' ' + task.cancelled_by.user.lastname
        }

        if (task.rejected) {
            return 'This task was rejected by ' + task.rejected_by.user.firstname + ' ' + task.rejected_by.user.lastname
        }

        if (task.reassigned) {
            return 'This task was reassigned by ' + task.reassigned_by.user.firstname + ' ' + task.reassigned_by.user.lastname
        }

        if (task.unassigned) {
            return 'This task was unassigned by ' + task.unassigned_by.user.firstname + ' ' + task.unassigned_by.user.lastname
        }

        if (task.approved) {
            return 'This task was approved by ' + task.approved_by.user.firstname + ' ' + task.approved_by.user.lastname
        }

        if (task.completed) {
            return 'This task was completed by ' + task.assigned_to.user.firstname + ' ' + task.assigned_to.user.lastname
        }

        return 'This task has not been handled yet.'
    }

    getWorkflowTaskEventIcon(task) {
        if (task.failed) {
            return 'cancel'
        }

        if (task.cancelled) {
            return 'not_interested'
        }

        if (task.rejected) {
            return 'error'
        }

        if (task.reassigned) {
            return 'subdirectory_arrow_left'
        }

        if (task.unassigned) {
            return 'replay'
        }

        if (task.approved) {
            return 'check_circle_outline'
        }

        if (task.completed) {
            return 'check_circle_outline'
        }

        return 'help_outline'
    }

    getWorkflowQueueTaskStateIconColor(task) {
        if (task.failed) {
            return red[500]
        }

        if (task.cancelled) {
            return grey[500]
        }

        if (task.rejected) {
            return orange[500]
        }

        if (task.reassigned) {
            return orange[500]
        }

        if (task.unassigned) {
            return orange[500]
        }

        if (task.approved) {
            return green[500]
        }

        if (task.completed) {
            return green[500]
        }

        return grey[500]
    }

    renderWorkflowTimelineQueueTasks(meta_data, tasks) {
        return !tasks.length
            ? <div />
            : <div>

                {
                    tasks.sort((x, y) => y.workflow_queue_task_id - x.workflow_queue_task_id).map((x, i) => {
                        //console.log('renderWorkflowTimelineQueueTasks', x)
                        let ico = this.getWorkflowTaskEventIcon(x)

                        return <div>
                            <div className="timeline-list mb-0">

                                {/* Header */}
                                <div className="timeline-item timeline-item-icon">
                                    <div className="timeline-item--content">
                                        {
                                            x.completed_date
                                            ? <div className="timeline-item--icon-wrapper rounded-pill bg-success text-white">
                                                <CheckIcon />
                                                </div>
                                            : <div className="timeline-item--icon-wrapper rounded-pill bg-info text-white">
                                                <ErrorOutline />
                                                </div>
                                        }
                                        <h4 className="timeline-item--label mb-2 font-weight-bold">
                                            {window.t.en('(#' + x.workflow_queue_id + '/' + x.order + ') ' + x.title)}
                                        </h4>
                                        <strong>Complete Date:</strong> {x.completed_date? moment(x.completed_date).format('YYYY-MM-DD HH:mm ZZ'): 'No completion date!'} {' '} <br />
                                        <strong>Created Date:</strong> {moment(x.created_at).format('YYYY-MM-DD HH:mm ZZ')} {' '} <br />
                                        {
                                            x.type !== 'service'
                                            ? <div>
                                                <strong>Assigned To:</strong> {x.assigned_to.user.firstname ? x.assigned_to.user.firstname + ' ' + x.assigned_to.user.lastname : '?'} {' '} <br />
                                                <strong>Assigned Team:</strong> {x.assigned_to.team.title ? x.assigned_to.team.title : '?'} {' '} <br />
                                                <strong>Referred By:</strong> {x.referred_by.firstname ? x.referred_by.firstname + ' ' + x.referred_by.lastname : '?'} {' '} <br />
                                              </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                                {
                                    !x.completed_date && x.type !== 'service'
                                        ? x.assigned_to.user.firstname //&& ico === 'help_outline'
                                            ? <div className="timeline-item timeline-item-icon">
                                                <div className="timeline-item--content">
                                                    {/* <div className="timeline-item--icon-wrapper bg-premium-dark text-white">
                                                        <EmojiEventsIcon />
                                                    </div> */}
                                                    <h4 className="timeline-item--label mb-2 font-weight-bold">
                                                        Assignment
                                                    </h4>
                                                    < Button variant="contained"
                                                        primary={true}
                                                        label={'Unassign'}
                                                        onClick={(event) => {
                                                            this.props.actions.unAssign(x.assigned_to.user.user_id, x.workflow_queue_task_id)
                                                        }} >{window.t.en('Unassign')}</Button>
                                                    <br />
                                                </div>
                                            </div>
                                        : ''
                                    : ''
                                }
                                {
                                    x.type !== 'service'
                                        ? <div className="timeline-item timeline-item-icon">
                                            <div className="timeline-item--content">
                                                {/* <div className="timeline-item--icon-wrapper bg-danger text-white">
                                                    <TodayIcon />
                                                </div> */}
                                                <h4 className="timeline-item--label mb-2 font-weight-bold">
                                                    Status
                                                </h4>
                                                {this.getWorkflowTaskUserEvent(x)}
                                            </div>
                                        </div>
                                        : ''
                                }
                                {/* Task Comments */}
                                <div className="timeline-item timeline-item-icon">
                                    <div className="timeline-item--content">
                                        {/* <div className="timeline-item--icon-wrapper bg-warning text-white">
                                            <CommentIcon />
                                        </div> */}
                                        <h4 className="timeline-item--label mb-2 font-weight-bold">
                                            Task Comments
                                        </h4>
                                        {
                                            x.cancelled
                                                ? x.cancelled_by.user.message
                                                : x.assigned_to.user.message
                                                    ? x.assigned_to.user.message
                                                    : x.assigned_to.team.message
                                                        ? x.assigned_to.team.message
                                                        : 'No task comments available.'
                                        }
                                    </div>
                                </div>

                                {/* Meta Data */}
                                <div className="timeline-item timeline-item-icon">
                                    <div className="timeline-item--content">
                                        {/* <div className="timeline-item--icon-wrapper rounded-pill bg-success text-white">
                                            <DataUsageIcon />
                                        </div> */}
                                        <h4 className="timeline-item--label mb-2 font-weight-bold">
                                            Meta Data
                                        </h4>
                                        {
                                            meta_data
                                                ? meta_data.split(', ').map(x => {
                                                    return <div>{x}<br /></div>
                                                })
                                                : <div>No meta data available.</div>
                                        }
                                    </div>
                                </div>
                                {
                                    x.documents > 0 && <div className="timeline-item timeline-item-icon">
                                    <div className="timeline-item--content">
                                        {/* <div className="timeline-item--icon-wrapper rounded-pill bg-info text-white">
                                            <DescriptionIcon />
                                        </div> */}
                                        <h4 className="timeline-item--label mb-2 font-weight-bold">
                                            Attachments
                                        </h4>
                                            {
                                                [
                                                    <WorkflowQueueTaskDocuments id={'workflow_timeline_documents_' + x.workflow_queue_task_id + '_' + i} key={'workflow_timeline_documents_' + x.workflow_queue_task_id + '_' + i} workflow_queue_task_id={x.workflow_queue_task_id} />
                                                ]
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    })
                }
            </div>
    }

    getWorkflowQueueStateBackgroundColor(workflow_queue) {
        if (workflow_queue.queued && !workflow_queue.completed)
            return { backgroundColor: orange[500] }

        if (workflow_queue.completed)
            return { backgroundColor: green[500] }

        if (workflow_queue.cancelled)
            return { backgroundColor: grey[500] }

        return { backgroundColor: '#fff' }
    }

    renderWorkflowTimelineQueues(queues) {
        return !queues
            ? <div />
            :

            <Timeline style={{ maxWidth: '95%', paddingTop: '0', paddingBottom: '0', marginTop: '0', marginBottom: '0' }}>
                {
                    queues.map((x, i) => {
                        //console.log('TimelineItem >>>>>>>>>>>>>>>>>>>', x)
                        return <TimelineItem

                            date={[
                                moment(x.created_at).format('YYYY-MM-DD HH:mm ZZ'),
                                <br />,
                                x.meta_data ? x.meta_data : 'Unspecified'
                                //x.meta_data ? x.meta_data.toString().substring(0, 100).replace(/null/g, '?') + '...' : 'Unspecified'
                            ]}
                            iconStyle={this.getWorkflowQueueStateBackgroundColor(x)}
                            icon={<Icon istyle={{ fontSize: '32px' }} iclass={'material-icons'} iname={'work'} />}
                        >
                            <Grid container spacing={0}>
                                <Grid item lg={6}>
                                    <div className="timeline-list timeline-list-offset timeline-list-offset-dot mb-0">
                                        <div className="timeline-item">
                                            <div className="timeline-item-offset"><b>{x.workflow_queue_tasks[0].workflow_queue_id}</b></div>
                                            <div className="timeline-item--content">
                                                <div className="timeline-item--icon bg-danger" />
                                                <h4 className="timeline-item--label mb-2 ">
                                                    <strong>{x.workflow_queue_tasks[0].title}</strong>
                                                </h4>
                                                    {/* <p>{x.workflow_queue_tasks[0].title}</p>  */}
                                                    <br />
                                                <h4 className="timeline-item--label mb-2 ">
                                                    <strong>Assign To:&nbsp;</strong>
                                                    <p>{x.workflow_queue_tasks[x.workflow_queue_tasks.length - 1] ? x.workflow_queue_tasks[x.workflow_queue_tasks.length - 1].assigned_to.user.firstname : ''}</p>
                                                </h4> <br />
                                                <h4 className="timeline-item--label mb-2 ">
                                                    <strong>Date Updated:&nbsp;</strong>
                                                    <p>{moment(x.created_at).format('YYYY-MM-DD HH:mm ZZ')}</p>
                                                </h4>  <br />
                                                <p>{x.meta_data}</p>
                                            </div>
                                            <div className="mt-2">
                                                <Button  className={"global_button"} variant="contained" primary={true}
                                                width={'120px'}
                                                // startIcon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'arrow_forward'} />}
                                                    onClick={(event) => {
                                                        this.props.actions.toggleTimelineTask(x.workflow_queue_tasks[0].workflow_queue_id)
                                                    }}
                                                >
                                                    View  <Icon iclass={'material-icons'} iname={'arrow_forward'} />
                                        </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item lg={6}>
                                    {x.workflow_queue_tasks[0].workflow_queue_id === this.props.state.timelinetask ? this.renderWorkflowTimelineQueueTasks(x.meta_data, x.workflow_queue_tasks) : ''}
                                </Grid>
                            </Grid>

                        </TimelineItem>
                    })
                }
            </Timeline >

    }

    getWorkflows(workflows) {
        return !workflows
            ? this.props.state.workflowQueueTasks.value.length > 0
                ? <div>Could not match any workflow queue task entries matching your search criteria "{this.props.state.workflowQueueTasks.value}".</div>
                : <div>The workflow engine queue is empty, no tasks were processed by the system as of yet.</div>
            : workflows.map((x, index) => {
                return <div style={{margin: '10px'}}>
                        {/* <Button  className={"global_button"} variant="contained" primary={true}
                            onClick={(event) => {
                                this.props.actions.getWorkflowTasks(x.workflow_id)
                                this.props.actions.togleTimeline(x.title)
                                this.props.actions.setSelectedWorkflowTimeLineQueue(index)
                            }}

                        >
                            <VisibilityIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
                            &nbsp; 
                            <span className="text-uppercase font-weight-bold font-size-xs" >
                                {x.title}
                            </span>
                        </Button> */}
                        <Chip 
                            avatar={<Avatar>{x.title[0]}</Avatar>}
                            label={x.title}
                            clickable
                            color="primary"
                            variant="outlined" 
                            style={{color:'#2C3C8F', fontSize: '15px'}}
                            onClick={(event) => {
                                this.props.actions.getWorkflowTasks(x.workflow_id)
                                this.props.actions.togleTimeline(x.title)
                                this.props.actions.setSelectedWorkflowTimeLineQueue(index)
                            }}
                        />
                    </div>
            })
    }

    workflowQueueDataExists() {
        try {
            const exists = this.props.state[this.props.name].data.data[0].workflow_queues
            return true
        }
        catch (error) {
            return false
        }
    }

    scrollStepper(offset){
        this.workflowStepperRef.current.scrollLeft += offset;
    }
    
    getWorkflowStepper(){
        return (this.props.state.workflow_stepper?.length ? this.props.state.workflow_stepper.length > 7 ? <div ref={this.workflowStepperRef} style={{width: '1000px', overflow: 'hidden', marginLeft: '20px', scrollBehavior: 'smooth'}}>
            <IconButton  sx={{position: 'absolute', left: '2%' }} onClick={() => this.scrollStepper(-100)} aria-label="arrowLeft">
        <ChevronLeftIcon />
      </IconButton>
      <IconButton sx={{position: 'absolute', right: '13%'}} onClick={() => this.scrollStepper(100)}  aria-label="arrowRight">
        <ChevronRightIcon />
      </IconButton>
            <Box sx={{width: '2000px'}}>
        <Stepper  activeStep={this.props.state.workflow_stepper?.length} alternativeLabel>
            {this.props.state.workflow_stepper.map((x, index) => (
        <Step key={x.workflow_task_id}>
            <StepLabel>{`Step ${index + 1}`}</StepLabel>
        </Step>
            ))}
        </Stepper>
    </Box></div> : 
    <div style={{width: '100%'}}>
    <Box>
<Stepper  activeStep={this.props.state.workflow_stepper?.length} alternativeLabel>
    {this.props.state.workflow_stepper.map((x, index) => (
<Step key={x.workflow_task_id}>
    <StepLabel>{`Step ${index + 1}`}</StepLabel>
</Step>
    ))}
</Stepper>
</Box></div> : <></>)
    }

    render() {
        return this.props.proper && this.props.name && this.props.state[this.props.name] && this.props.state[this.props.name].data && this.props.state[this.props.name].data.data ? this.renderWorkflowTimelineQueueTasks(this.props.state[this.props.name].data.data[0].workflow_queues[0].meta_data, this.props.state[this.props.name].data.data[0].workflow_queues[0].workflow_queue_tasks) : !this.props.name || !this.props.state[this.props.name]
            ? <Loading message={'Retrieving workflow timeline'} />
            : this.props.state[this.props.name].loading //&& this.props.state[this.props.name].isOpen
                ? <Loading message={'Retrieving workflow timeline'} />
                : !this.props.state[this.props.name].loading //&& this.props.state[this.props.name].isOpen
                    ? this.props.workflow_queue_id
                        ? this.renderWorkflowTimelineQueueTasks(this.props.state[this.props.name].data.data[0].workflow_queues[0].meta_data, this.props.state[this.props.name].data.data[0].workflow_queues[0].workflow_queue_tasks)
                        : this.props.team_id
                            ? !this.workflowQueueDataExists()
                                ? <Loading message={'Retrieving workflow timeline'} />
                                : this.renderWorkflowTimelineQueues(this.props.state[this.props.name].data.data[0].workflow_queues)
                            : <Card initiallyExpanded={true} style={{ marginBottom: '5px', fontWeight: '400' }}>
                                {/* <CardHeader 
                                    className='bg-workflowHeaderCard'
                                    actAsExpander={false}
                                    showExpandableButton={false}
                                    title={window.t.en('Workflow Time')}
                                    // subtitle={window.t.en('Displays the last 1000 workflow events in a timeline format matching your search criteria.')}
                                    // avatar={
                                    //     // <PublicIcon />
                                    // }
                                /> */}
                                    <Section title={'Workflow Time Line'} subtitle={'View workflow tasks history'}/>
                                <CardContent expandable={true} style={{display: 'flex', overflow: 'auto', marginTop: '15px'}}>
                                <Grid container spacing={3}>
                                                {this.getWorkflows(this.props.state[this.props.name].data.data)}
                                                </Grid>
                                    {/*<Grid container spacing={3}>
                                  
                                        <Grid item xl={10}>
                                            <div className="grid-menu grid-menu-5col">
                                                 <Card>
                                                    <Grid container spacing={0}>
                                                    </Grid>
                                                      
                                                </Card> 
                                            </div>
                                        </Grid>
                                    </Grid>*/}
                                </CardContent>

                                <Card initiallyExpanded={true} style={{ width: '100%', paddingTop: '0px', paddingLeft: '2px' }}>
                                    <CardContent expandable={true} >
                                        {
                                            this.props.state[this.props.name].data.data[this.props.state.selectedWorkflowTimeLineQueue]?
                                            this.renderWorkflowTimelineQueues(this.props.state[this.props.name].data.data[this.props.state.selectedWorkflowTimeLineQueue].workflow_queues)
                                            :'Loading....'
                                        }

                                    </CardContent>
                                </Card>
                            </Card>

                    : <div>loading {!this.props.state[this.props.name].loading}, Prop Name {this.props.name}, is Open {this.props.state[this.props.name].isOpen},</div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowTimeline,
            workflowQueueTasks: {
                ...state.workflowQueueTasks
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTimeline)