export const list = {
    fontSize : '1.15rem'
}
export const head = {
    fontSize : '1.60rem'
}

export const button = {
    width : '125px',
    height : '50px',
    fontSize : '1.3rem'
}
export const flex = {display: 'flex', justifyContent: 'flex-end'}

