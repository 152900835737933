export const GET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA = 'GET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA'
export const SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA = 'SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA'


export const SET_TEAM_COMPONENT_CURRENT_TAB = 'SET_TEAM_COMPONENT_CURRENT_TAB'
export const SET_TEAM_COMPONENT_FIELD_INPUT_VALUE = 'SET_TEAM_COMPONENT_FIELD_INPUT_VALUE'

export const SET_TEAM_COMPONENT_FIELD_INPUT_AND_TEXT_VALUE = 'SET_TEAM_COMPONENT_FIELD_INPUT_AND_TEXT_VALUE'
export const TOGGLE_TEAM_COMPONENT_DELETE_WIN_STATUS = 'TOGGLE_TEAM_COMPONENT_DELETE_WIN_STATUS'

export const GET_TEAM_COMPONENT_FIELD_LOOKUP_DATA = 'GET_TEAM_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_TEAM_COMPONENT_FIELD_LOOKUP_DATA = 'SET_TEAM_COMPONENT_FIELD_LOOKUP_DATA'

export const SAVE_TEAM_COMPONENT_CUSTOMER_DATA = 'SAVE_TEAM_COMPONENT_CUSTOMER_DATA'
export const SET_CONTROL_FORM_TREE_DATA = 'SET_CONTROL_FORM_TREE_DATA'