export const LOAD_WORKFLOW_VEHICLE_HISTORY = 'LOAD_WORKFLOW_VEHICLE_HISTORY'
export const LOAD_WORKFLOW_VEHICLE_LIST = 'LOAD_WORKFLOW_VEHICLE_LIST'

export const WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE = 'WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE'
export const WORKFLOW_TIMELINE_SET_WORKFLOW_TIMELINE = 'WORKFLOW_TIMELINE_SET_WORKFLOW_TIMELINE'
export const CLOSE_WORKFLOW_VEHICLE_HISTORY = 'CLOSE_WORKFLOW_VEHICLE_HISTORY'
export const SHOW_WORKFLOW_VEHICLE_HISTORY = 'SHOW_WORKFLOW_VEHICLE_HISTORY'

export const TOGGLE_WORKFLOW_VEHICLE_HISTORY = 'TOGGLE_WORKFLOW_VEHICLE_HISTORY'
export const GET_WORKFLOW_VEHICLE_LIST = 'GET_WORKFLOW_VEHICLE_LIST'
export const SET_WORKFLOW_VEHICLE_LIST = 'SET_WORKFLOW_VEHICLE_LIST'
export const GET_WORKFLOW_VEHICLE_HISTORY = 'GET_WORKFLOW_VEHICLE_HISTORY'
export const SET_WORKFLOW_VEHICLE_HISTORY = 'SET_WORKFLOW_VEHICLE_HISTORY'

export const SET_WS_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'SET_WS_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const RESET_WORKFLOW_VEHICLE_HISTORY = 'RESET_WORKFLOW_VEHICLE_HISTORY'
export const SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const GET_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'GET_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const SET_COMPONET_HISTORY_INPUT_FILEDS = 'SET_COMPONET_HISTORY_INPUT_FILEDS'

export const SET_COMPONENT_OPEN_CLOSE_SEARCH = 'SET_COMPONENT_OPEN_CLOSE_SEARCH'
