import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_USER_DATA:
            return {
                ...action.payload
            }
        case types.SET_USER_COMPONENT_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.USER_NOT_FOUND:
            return state
        default:
            return state
    }
}