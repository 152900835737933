export const tab = 0
export const info = null
export const vehicle = {}
export const additem_name = ''
export const open = true
export const showhistory = false
export const additem_win = true
export const vehicle_loading = true
export const supplier_monthly_spend = null
export const supplier_in_limit = true
export const content_collapse = true
export const service_only = true
export const auditdata = []
export const customeraddress = []
export const multimaintenance = []
export const workflow_stepper = []
export const supplier_transactions = []

export const line = ''
export const town = ''
export const province = ''
export const country = ''
export const postalcode = ''

export const del_address_id = 0
export const del_line = ''
export const del_town = ''
export const del_province = ''
export const del_country = ''
export const del_postalcode = ''

export const field = [
  
    {
        name: '',
        title: '',
        type: '',
        description: '',
        placeholder: '',
        required: '',
        variant: '',
        value: '',
        fullWidth: '',
        id: '',
        // autoComplete: '',
        // autoFocus: '',
        // classes: '',
        // color: '',
        // defaultValue: '',
        // disabled: false,
        // error: '',
        
        // FormHelperTextProps: '',
        // helperText: '',
        
        // InputLabelProps: '',
        // inputProps: '',
        // InputProps: '',
        // inputRef: '',
        // label: '',
        // margin: '',
        // multiline: '',
        // rows: '',
        // rowsMax: '',
        // select: '',
        // size: '',
        // SelectProps: '',
        
    },
   

]

export const hierarchy_data = null
export const checked_list = []
export const serviceplan = []
export const expand_list = []
export const hierarchy_selection = {}
export const hierarchy = [
    {
        id: 1,
        parent_id: null,
        name: 'A'
    },
    {
        id: 2,
        parent_id: null,
        name: 'B'
    },
    {
        id: 8,
        parent_id: null,
        name: 'C'
    },
    {
        id: 3,
        parent_id: 1,
        name: 'AA'
    },
    {
        id: 5,
        parent_id: 3,
        name: 'AAA'
    },
    {
        id: 6,
        parent_id: 3,
        name: 'AAA+'
    },
    {
        id: 7,
        parent_id: 6,
        name: 'AAAA'
    },
    {
        id: 4,
        parent_id: 2,
        name: 'BB'
    },
]
