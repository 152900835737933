
import styled from 'styled-components'
import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const emptyStyle = {
    fontSize: '30px'
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 2,
    verticalAlign: 'left'
}


export const action = {
    fontSize: '20px',
    color: colours.red[500]
}

export const searchIcon = {
    display: 'table-cell',
    width: '100px',
    float: 'right',
    textAlign: 'right'
}
export const searchInput = {
    display: 'table-cell',
    //width: '265px',
    float: 'right',
    textAlign: 'right'
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'left',
}

export const lineitems = {
    marginBottom: '1px',
    fontSize: '16px',
    textAlign: 'left',
}

export const actionHoverColor = {
    color: colours.red[500]
}

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}
export const right = {
    textAlign: 'right',
    float: 'right',
}

export const gridOptionsIcon = {

}


export const verticalMiddle = {
    verticalAlign: 'middle',
}

export const alternating = {
    paddingTop: '5px',
    backgroundColor: '#dedede'
}
export const nonealternating = {
    backgroundColor: 'transaparent'
}
export const Label = styled.label`

font-size: 25px;

`