export const CLOSE_WORKFLOW_DISPOSAL = 'CLOSE_WORKFLOW_DISPOSAL'
export const SHOW_WORKFLOW_DISPOSAL = 'SHOW_WORKFLOW_DISPOSAL'

export const TOGGLE_WORKFLOW_DISPOSAL = 'TOGGLE_WORKFLOW_DISPOSAL'
export const SET_DISPOSAL_COMPONENT_INPUT_FIELD = 'SET_DISPOSAL_COMPONENT_INPUT_FIELD'

export const GET_WORKFLOW_DISPOSAL_DATA_LIST = 'GET_WORKFLOW_DISPOSAL_DATA_LIST'
export const SET_WORKFLOW_DISPOSAL_DATA_LIST = 'SET_WORKFLOW_DISPOSAL_DATA_LIST'
export const GET_WORKFLOW_DISPOSAL_OPT_LIST = 'GET_WORKFLOW_DISPOSAL_OPT_LIST'
export const SET_WORKFLOW_DISPOSAL_OPT_LIST = 'SET_WORKFLOW_DISPOSAL_OPT_LIST'

export const SET_DISPOSAL_USER_COMMENTS_INPUT_FIELD = 'SET_DISPOSAL_USER_COMMENTS_INPUT_FIELD'
export const SET_DISPOSAL_USER_COMMENTS_INPUT = 'SET_DISPOSAL_USER_COMMENTS_INPUT'
export const SET_DISPOSAL_USER_SEEING_INPUT = 'SET_DISPOSAL_USER_SEEING_INPUT'
export const OPEN_VEHICLE_DISPOSAL_SCREEN = 'OPEN_VEHICLE_DISPOSAL_SCREEN'

export const DOWNLAOD_DISPOSAL_COMPONENT_DOCUMENT = 'DOWNLAOD_DISPOSAL_COMPONENT_DOCUMENT'
export const TOGGLE_WORKFLOW_DISPOSAL_INFO_SHEET = 'TOGGLE_WORKFLOW_DISPOSAL_INFO_SHEET'
export const EXPORT_YARDLIST_TO_EXCEL = 'EXPORT_YARDLIST_TO_EXCEL'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'

export const SET_DISPOSAL_COMPONENT_CHIP_FILTER = 'SET_DISPOSAL_COMPONENT_CHIP_FILTER'

export const GET_IMPORT_FILE_TEMPLATE_LIST = 'GET_IMPORT_FILE_TEMPLATE_LIST'
export const SET_IMPORT_FILE_TEMPLATE_LIST = 'SET_IMPORT_FILE_TEMPLATE_LIST'
export const RESET_IMPORT_COMPONENT_DATA = 'RESET_IMPORT_COMPONENT_DATA'
export const SET_UPLOADED_EXCEL_FILE_NAME = 'SET_UPLOADED_EXCEL_FILE_NAME'
export const SET_UPLOADED_EXCEL_FILE_BUSY = 'SET_UPLOADED_EXCEL_FILE_BUSY'
export const SET_IMPORTED_DATA_ROWS = 'SET_IMPORTED_DATA_ROWS'
export const RESET_IMPORT_DATA_SCREEN = 'RESET_IMPORT_DATA_SCREEN'

export const TOGGLE_ADD_VEHICLE_OFFER = 'TOGGLE_ADD_VEHICLE_OFFER'
export const SET_SELECTED_IMPORT_TEMPLATE = 'SET_SELECTED_IMPORT_TEMPLATE'
export const UPLOAD_IMPORT_FILE_DATA = 'UPLOAD_IMPORT_FILE_DATA'

export const POST_COMPONENT_DISPOSAL_DATA = 'POST_COMPONENT_DISPOSAL_DATA'
export const RESET_ALL_INFOSHEETS = 'RESET_ALL_INFOSHEETS'

export const SET_OTP_VEHICLE_LIST = 'SET_OTP_VEHICLE_LIST'
export const GET_BIDDERS_DISPOSAL_DATA_LIST = 'GET_BIDDERS_DISPOSAL_DATA_LIST'
export const SET_BIDDERS_DISPOSAL_DATA_LIST = 'SET_BIDDERS_DISPOSAL_DATA_LIST'
export const SET_DISPOSAL_CURRENT_TAB = 'SET_DISPOSAL_CURRENT_TAB'
export const GET_CHEEKSHEET_DOCUMENT = 'GET_CHEEKSHEET_DOCUMENT'
export const SET_UPLOAD_DOCUMENT_PREVIEW = 'SET_UPLOAD_DOCUMENT_PREVIEW'
export const GET_DISPOSAL_CHEEKSHEET_DOCUMENT = 'GET_DISPOSAL_CHEEKSHEET_DOCUMENT'
export const UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER = 'UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER'
export const UPLOAD_DISPOSAL_SIGNED_CHECKSHEET = 'UPLOAD_DISPOSAL_SIGNED_CHECKSHEET'

export const GET_DISPOSAL_INVOICE_DOCUMENT = 'GET_DISPOSAL_INVOICE_DOCUMENT'
