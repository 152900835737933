import React, { Component } from 'react'
import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../Icon/component'
import View from '../View/component'


class SurrogateView extends Component {
    render() {
        return <Card key={'surrogate_view_card_' + this.props.component.name} initiallyExpanded={true}> {/* initiallyExpanded={index === 0} */}
            <CardHeader
                //avatar={<Icon iclass={this.props.component.icon.unicode} iname={this.props.component.icon.name} />}
                title={this.props.component.title}
                subtitle={this.props.component.description}
                actAsExpander={true}
                showExpandableButton={true}
            //style={styles.row}
            />
            <CardContent expandable={true}>
                <View
                    key={'readComponent_surrogateView_' + this.props.name + this.props.id}
                    id={this.props.id}
                    name={this.props.name}
                    expanded={this.props.expanded}
                    stop={this.props.stop}
                    surrogate={true}
                />
            </CardContent>
        </Card>
    }
}

export default SurrogateView