export const GET_BUSINESS_CUSTOMER_HIERARCHY = 'GET_BUSINESS_CUSTOMER_HIERARCHY'
export const SET_BUSINESS_CUSTOMER_HIERARCHY = 'SET_BUSINESS_CUSTOMER_HIERARCHY'

export const OPEN_USER_LANGUAGE_SELECTION = 'OPEN_USER_LANGUAGE_SELECTION'
export const SET_TAB_VALUE = 'SET_TAB_VALUE'
export const SET_CLIENT_ID = 'SET_CLIENT_ID'

export const TOGGLE_PROFILE_MENU = 'TOGGLE_PROFILE_MENU'
export const SET_WORKQUEUE_COUNT = 'SET_WORKQUEUE_COUNT'
export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR'
export const TOGGLE_APP_BAR = 'TOGGLE_APP_BAR'
export const TOGGLE_APP_BAR_STATE = 'TOGGLE_APP_BAR_STATE'
export const TOGGLE_SIDE_BAR_STATE = 'TOGGLE_SIDE_BAR_STATE'
export const TOGGLE_ORGANISATION = 'TOGGLE_ORGANISATION'
export const TOGGLE_LANGUAGE = 'TOGGLE_LANGUAGE'
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS'
export const TOGGLE_NOTIFICATIONS_LIST = 'TOGGLE_NOTIFICATIONS_LIST'
export const TOGGLE_USER_PROFILE_MENUS = 'TOGGLE_USER_PROFILE_MENUS'
export const ANIMATED_NOTIFICATIONS_BUTTON = 'ANIMATED_NOTIFICATIONS_BUTTON'

export const GET_USER_WORKFLOW_NOTIFICATIONS_MENUS = 'GET_USER_WORKFLOW_NOTIFICATIONS_MENUS'
export const SET_USER_WORKFLOW_NOTIFICATIONS_MENUS = 'SET_USER_WORKFLOW_NOTIFICATIONS_MENUS'
export const SET_COMPONENT_USER_PROFILE_DASHBOARD_DATA = 'SET_COMPONENT_USER_PROFILE_DASHBOARD_DATA'
export const GET_COMPONENT_USER_PROFILE_DASHBOARD_DATA = 'GET_COMPONENT_USER_PROFILE_DASHBOARD_DATA'

export const SET_RIGHT_SIDE_REPORT_DATA = 'SET_RIGHT_SIDE_REPORT_DATA'

export const GET_DOCUMENT_PREVIEW_DATA = 'GET_DOCUMENT_PREVIEW_DATA'
export const SET_DOCUMENT_PREVIEW_DATA = 'SET_DOCUMENT_PREVIEW_DATA'
export const SET_SELECTED_DOCUMENT_PREVIEW = 'SET_SELECTED_DOCUMENT_PREVIEW'

export const UPDATE_USER_SELECTED_DOCUMENT_PREVIEW = 'UPDATE_USER_SELECTED_DOCUMENT_PREVIEW'

export const SET_WORKQUEUE_LIST_ITEMS = 'SET_WORKQUEUE_LIST_ITEMS'
export const GET_WORKQUEUE_LIST_ITEMS = 'GET_WORKQUEUE_LIST_ITEMS'

