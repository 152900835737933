import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_WORKFLOW_TASK_ACTION_DATA:
            return {
                ...state,
                workflow_queue: action.payload,
                loading: false
            }
        case types.SET_WORKFLOW_ACTION:
            return {
                ...state,
                event: action.payload.event,
                workflow: action.payload.workflow,
                workflow_task: action.payload.workflow_task,
                data: action.payload.data,
                queue: action.payload.queue,
                loading: false
            }
        default:
            return state
    }
}