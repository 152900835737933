import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Paper from '@mui/material/Paper'

import { Card, CardHeader, CardContent, CardAction, Step, Stepper, StepLabel, ButtonGroup, Menu, MenuItem, Button, List, ListItem, ListItemText, DialogTitle, Dialog, ListItemAvatar, Avatar }
    from '@mui/material'
import { Container, Row, Col } from 'react-grid-system'

import LinearProgress from '@mui/material/LinearProgress'

import WorkflowTaskApproval from '../WorkflowTaskApproval/component'
import WorkflowQuoteHeader from '../WorkflowQuoteHeader/component'
import WorkflowPOHeader from '../WorkflowPOHeader/component'
import POBooking from '../WorkflowPOHeader/POBooking/component'
import POBookingConfirm from '../WorkflowPOHeader/POBookingConfirm/component'
import POBookingInvoice from '../WorkflowPOHeader/POBookingInvoice/component'
import POBookingInvoiceConfirm from '../WorkflowPOHeader/POBookingInvoiceConfirm/component'
import WorkflowQuoteConfirm from '../WorkflowQuoteConfirm/component'
import WorkflowPOUploadQuote from '../WorkflowPOUploadQuote/component'
import WorkflowTaskInformation from '../WorkflowTaskInformation/component'
import WorkflowTaskStepComponentSingleCreator from '../WorkflowTaskStepComponentSingleCreator/component'
import WorkflowTaskStepComponentSingleSelector from '../WorkflowTaskStepComponentSingleSelector/component'
import WorkflowTaskStepComponentMultipleCreator from '../WorkflowTaskStepComponentMultipleCreator/component'
import WorkflowTaskStepComponentMultipleSelector from '../WorkflowTaskStepComponentMultipleSelector/component'
import WorkflowTaskStepComponentQuoteCreator from '../WorkflowTaskStepComponentQuoteCreator/component'
import WorkflowTaskStepQuoteSingleSelector from '../WorkflowTaskStepQuoteSingleSelector/component'
import WorkflowViewFMLQuoteConfirm from '../WorkflowViewFMLQuoteConfirm/component'

import WorkflowPOReceiptVehicle from '../WorkflowPOReceiptVehicle/component'
import WorkflowPOAccessoriesConfirm from '../WorkflowPOAccessoriesConfirm/component'
import WorkflowPOReceiptVehicleConfirm from '../WorkflowPOReceiptVehicleConfirm/component'
import WorkflowPOReceiptAccessories from '../WorkflowPOReceiptAccessories/component'
import WorkflowPOReceiptAccessoriesConfirm from '../WorkflowPOReceiptAccessoriesConfirm/component'
import WorkflowPOAccessories from '../WorkflowPOAccessories/component'
import WorkflowPODelivery from '../WorkflowPODelivery/component'
import WorkflowPOGRV from '../WorkflowPOGRV/component'
import WorkflowPOContractActivation from '../WorkflowPOContractActivation/component'
import WorkflowPOContractActivationConfirm from '../WorkflowPOContractActivationConfirm/component'
import WorkflowPOContractActivationChecklist from '../WorkflowPOContractActivationChecklist/component'
import WorkflowPOContractActivationVAF from '../WorkflowPOContractActivationVAF/component'
import WorkflowPOContractActivationVAFConfirm from '../WorkflowPOContractActivationVAFConfirm/component'
import WorkflowPODeliveryConfirm from '../WorkflowPODeliveryConfirm/component'
import WorkflowReconInvoice from '../WorkflowReconInvoice/component'
import WorkflowReconInvoiceConfirm from '../WorkflowReconInvoiceConfirm/component'
import CaptureSTRConfirm from '../WorkflowSTRHeader/CaptureSTRConfirm/component'
import CaptureSTR from '../WorkflowSTRHeader/CaptureSTR/component'
import Invoiceap from '../Invoiceap/component'
import ContractSelectionGrid from '../WorkflowContractRestructure/ContractSelectionGrid/component'
import ContractRestructure from '../WorkflowContractRestructure/ContractRestructure/component'
import InvoiceapConfirm from '../InvoiceapConfirm/component'
import InvoiceapReject from '../InvoiceapReject/component'
import InvoiceapRejectConfirm from '../InvoiceapRejectConfirm/component'
import ContractRestructureConfirm from '../WorkflowContractRestructure/ContractRestructureConfirm/component'
import CreateMMQuoteConfirm from '../WorkflowManageMaintenance/CreateMMQuoteConfirm/component'
import CreateMMQuote from '../WorkflowManageMaintenance/CreateMMQuote/component'
import BillingRunStart from '../WorkflowBillingRun/BillingRunStart/component'
import BillingRunStartConfirm from '../WorkflowBillingRun/BillingRunStartConfirm/component'
import WorkflowDefleet from '../WorkflowDefleet/component'
import DefleetGenerateChecksheet from '../WorkflowDefleet/DefleetGenerateChecksheet/component'
import DefleetUploadChecksheet from '../WorkflowDefleet/DefleetUploadChecksheet/component'
import DefleetUploadChecksheetConfirm from '../WorkflowDefleet/DefleetUploadChecksheetConfirm/component'
import DefleetCaptureQuote from '../WorkflowDefleet/DefleetCaptureQuote/component'
import DefleetUploadQuotes from '../WorkflowDefleet/DefleetUploadQuotes/component'
import DefleetUploadQuotesConfirm from '../WorkflowDefleet/DefleetUploadQuotesConfirm/component'
import WorkflowFMLQuote from '../WorkflowFMLQuote/component'
import WorkflowSTRQuote from '../WorkflowSTRQuote/component'
import WorkflowFMLReleaseNote from '../WorkflowFMLReleaseNote/component'
import WorkflowFMLAcceptReleaseNote from '../WorkflowFMLAcceptReleaseNote/component'
import CaptureQuotation from '../WorkflowSTRQuote/CaptureQuotation/component'

import WorkflowPayment from '../WorkflowPayment/component'
import WorkflowPaymentConfirm from '../WorkflowPayment/PaymentConfirm/component'
import WorkflowTaskHeader from '../../controls/header'

import StatementReview from '../WorkflowStatementRun/StatementReview/component'
import PaymentCapture from '../WorkflowCreditorsPayment/PaymentCapture/component'
import PurchaseOrderCapture from '../WorkflowCreditorsPayment/PurchaseOrderCapture/component'
import SupplierMasterCapture from '../SupplierMaster/SupplierMasterCapture/component'
import CustomerMasterCapture from '../CustomerMaster/CustomerMasterCapture/component'
import SalesProcessCapture from '../SalesProcess/SalesProcessCapture/component'	
import AccessoryMasterCapture from '../AccessoryMaster/AccessoryMasterCapture/component'
import VehicleMasterCapture from '../VehicleMaster/VehicleMasterCapture/component'
import STRQuotationApproval from '../WorkflowSTRQuote/tasks'

import CreditorsPaymentCreate from '../CreditorsPayment/CreditorsPaymentCreate/component'
import CreditNoteCreate from '../CreditNote/CreditNoteCreate/component'

import InterestRateCreate from '../InterestRate/InterestRateCreate/component'
import CashbookArchiveCreate  from '../CashbookArchive/CashbookArchiveCreate/component'

import CostCentreChangeCapture from '../CostCentreChange/CostCentreChangeCapture/component'
import OdometerChangeCapture from '../OdometerChange/OdometerChangeCapture/component'
import RegistrationChangeCapture from '../RegistrationChange/RegistrationChangeCapture/component'

import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Loading from '../Loading/component'
import Icon from '../Icon/component'
import WorkflowView from '../WorkflowView/component'

import CardControl from '../../controls/card'
import Workflow_Stepper from '../../controls/workflow_stepper'

import * as actions from './actions'
import * as styles from './styles'
import moment from 'moment'

class WorkflowTaskInput extends Component {
    componentDidMount() {
        //console.log('this.props.matchx componentWillMount',this.props.match)
        let props = this.props.match.path ? this.props.match.path.split('/') : []
        this.workflow = props[2] //this.props.match ? this.props.match.params.workflow : this.props.name
        this.task = props[3] //this.props.match ? this.props.match.params.task : this.props.step
        
        console.log('CustomErrorBoundaryx will mount',this.workflow, this.task)
        this.props.actions.clearWorkflowTaskInformation()
        //console.log('this.props.matchx componentWillMount',this.props.match, props)
        this.props.actions.getWorkflowTaskInformation(this.workflow, this.task)
        this.props.actions.setValidationState(null)

        // Set the validation state for workflows that start with a grid here
        const validationTasks = [
            'tsk_accessory_master_capture',
            'tsk_costcentre_change_capture',
            'tsk_fml_quote_create_customer_quote',
            'tsk_odometer_change_capture',
            'tsk_registration_change_capture',
            'tsk_vehicle_pricing_capture'
          ];

        if (validationTasks.includes(this.task)) {
            this.props.actions.setValidationState('workflowTaskInput')
        }
    }

    componentWillUnmount() {
        this.props.actions.clearWorkflowTaskInformation()
        this.props.actions.setValidationState(null)
    }

    getErrorMessage(displayType) {
        switch (displayType) {
            case 'single_component_row_creator':
                return 'Please complete the form below!'
            case 'single_component_row_selector':
                return 'Please make a selection!'
            case 'multiple_component_row_creator':
                return 'Please add one or more entries by completing the form below!'
            case 'multiple_component_row_selector':
                return 'Please make one or more selections!'
            case 'quote_component_creator':
                return 'Please capture the details!'
            case 'action_approval':
                return 'Please approve workflow step!'
            case 'po_receipt_vehicle':
                return 'Receipt supplier purchase order for accessories!'
            case 'po_accessories':
                return 'Create Accessory Purchase Order!'
            case 'po_delivery':
                return 'Create Vehicle Delivery!'
            case 'contract_release_note':
                return 'Release Note'
            case 'accept_release_note':
                return 'Accept Release Note'
            case 'contract_upload_vaf':
                return 'Download Vehicle Activation'
            case 'po_component_capture_booking':
                return 'Booking Details'
            case 'po_component_capture_invoice':
                return 'Capture Booking Quote Items'
            case 'str_quote_form':
                return 'STR Quote Form'
            case 'invoiceap_input':
                return 'Invoice AP'
            case 'invoice_recon':
                return 'Reconcille Invoice'
            case 'billing_run_start':
                return 'Billing Run Start'
            case 'comp_full_maintenance_lease_create':
                return 'Full Maintenance Lease Create.'
            case 'str_quotation_create':
                return 'Short Term Rental - Create Quotation'
            case 'creditors_payment_view':
                return 'Creditors Invoice Capture'
            default:
                return 'Unknown Component Display Type'
        }
    }

    getErrors(displayType, component) {
        return this.props.state.errors.filter(x => x.name === component.name)[0]
            ? this.getErrorMessage(displayType)
            : ''
    }

    getStatusIcon(component) {
        return this.props.state.errors.filter(x => x.name === component.name)[0]
            ? styles.componentStatusIconInvalid
            : styles.componentStatusIconValid
    }

    getStepComponents(stepIndex) {
        return this.props.state.task.workflow_task_step
            ? this.props.state.task.workflow_task_step
                .filter(x => x.order === stepIndex + 1)
                .map(x => {
                    return x.workflow_task_step_component
                })
                .sort((y, z) => y.order - z.order)
                .map(x => {
                    return x
                        .sort((y, z) => y.order - z.order)
                        .map(y => {
                            
                            console.log('CustomErrorBoundaryx display_type', y)
                            console.log('CustomErrorBoundaryx display_type', y.workflow_task_step_component_display_type.name)
                            //console.log('y.workflow_task_step_component_display_type.name',y.workflow_task_step_component_display_type.name)
                            switch (y.workflow_task_step_component_display_type.name) {
                                case 'single_component_row_creator':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Please complete the following form.')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <WorkflowTaskStepComponentSingleCreator
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'single_component_row_selector':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Please select a quote from the following list.')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <WorkflowTaskStepComponentSingleSelector
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    workflow_queue_data={this.props.state.workflow_queue}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'single_quote_row_selector':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title + ' #' + this.props.state.workflow_queue.workflow_queue_task_id + '-' + this.props.state.workflow_queue.workflow_queue_id)}
                                                subtitle={window.t.en('Please select and review the following.')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                {
                                                    this.props.state.workflow_queue.data &&
                                                    <WorkflowTaskStepQuoteSingleSelector
                                                        key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                        name={y.component.name}
                                                        workflow_queue_data={this.props.state.workflow_queue.data}
                                                        step={
                                                            this.props.state.task.workflow_task_step
                                                                .filter(x => x.order === stepIndex + 1)[0].name
                                                        }
                                                    />
                                                }
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'multiple_component_row_creator':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Please complete the following form. You can capture more than one row as needed.')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <WorkflowTaskStepComponentMultipleCreator
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'multiple_component_row_selector':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Please select one or more entries from the following list.')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <WorkflowTaskStepComponentMultipleSelector
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'quote_component_creator':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Quotation Options “Service’s, Sundry, Accessories”.')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <WorkflowTaskStepComponentQuoteCreator
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card>
                                        <br />
                                    </div>
                                case 'action_approval':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={y.component.title + ' Approve.'}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <WorkflowTaskApproval
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'quote_header':
                                    return <div>
                                        <WorkflowQuoteHeader
                                            key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            mode={'add'}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                    </div>
                                case 'str_quote_form':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Quotation')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <CaptureSTR
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    mode={'add'}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'po_component_row_creator':
                                    return <div>
                                        <WorkflowPOHeader
                                            key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            mode={'add'}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        {/* <WorkflowPOHeader
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    mode={'add'}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                /> */}
                                        {/* <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Vehicle plan booking')}
                                                avatar={
                                                    <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <WorkflowPOHeader
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    mode={'add'}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card> */}

                                        <br />
                                    </div>
                                case 'po_component_capture_booking':
                                    return <div>
                                        <POBooking
                                            key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            mode={'add'}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        {/* <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Vehicle plan booking')}
                                                avatar={
                                                    <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <POBooking
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    mode={'add'}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card> */}

                                        <br />
                                    </div>

                                case 'po_component_capture_invoice':
                                    console.log('componentDidMountx po_component_capture_invoice')
                                    return <div>
                                        <POBookingInvoice
                                            key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            workflow_queue_data={this.props.state.workflow_queue.data}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        {/* <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Vehicle Service Booking')}
                                                avatar={
                                                    <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                {
                                                    this.props.state.workflow_queue.data.length
                                                        ? <POBookingInvoice
                                                            key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                            name={y.component.name}
                                                            workflow_queue_data={this.props.state.workflow_queue.data}
                                                            step={
                                                                this.props.state.task.workflow_task_step
                                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                                            }
                                                        />
                                                        : <Loading message={'Retrieving data, please wait...'} />
                                                }
                                            </CardContent>
                                        </Card> */}

                                        <br />
                                    </div>
                                case 'po_component_quote_uploader':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Uoload Supplier Quote')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <WorkflowPOUploadQuote
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    mode={'add'}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'inbox_message_queue_action':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Inbox Message')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                {/* <WorkflowInboxMessage
                                                key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                name={y.component.name}
                                                mode={'add'}
                                                workflow_queue_data={this.props.state.workflow_queue.data}
                                                step={
                                                    this.props.state.task.workflow_task_step
                                                        .filter(x => x.order === stepIndex + 1)[0].name
                                                }
                                            /> */}
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'po_receipt_vehicle':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Upload OEM Vehicle Receipt and Send Vehicle Accessories Purchase Order')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true} style={styles.row}>
                                                {
                                                    this.props.state.workflow_queue.data &&
                                                    <WorkflowPOReceiptVehicle
                                                        key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                        name={y.component.name}
                                                        workflow_queue_data={this.props.state.workflow_queue.data}
                                                        step={
                                                            this.props.state.task.workflow_task_step
                                                                .filter(x => x.order === stepIndex + 1)[0].name
                                                        }
                                                    />
                                                }
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'po_accessories':
                                    return <div>
                                            <WorkflowPOAccessories
                                                key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                name={y.component.name}
                                                workflow_queue_data={this.props.state.workflow_queue.data[0]}
                                                step={
                                                    this.props.state.task.workflow_task_step
                                                        .filter(x => x.order === stepIndex + 1)[0].name
                                                }
                                            />
                                        <br />
                                    </div>
                                case 'po_delivery':
                                    return <WorkflowPODelivery
                                            key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            workflow_queue_data={this.props.state.workflow_queue.data[0]}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                case 'contract_release_note':
                                    return <WorkflowFMLReleaseNote
                                            key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            workflow_queue_data={this.props.state.workflow_queue.data[0]}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                case 'accept_release_note':
                                    return <WorkflowFMLAcceptReleaseNote
                                            key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            workflow_queue_data={this.props.state.workflow_queue.data[0]}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                case 'po_receipt_accessories':
                                    return <WorkflowPOReceiptAccessories
                                            key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            workflow_queue_data={this.props.state.workflow_queue.data[0]}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                case 'po_grv':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Vehicle And Accessories GRM')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                <WorkflowPOGRV
                                                    key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                    name={y.component.name}
                                                    step={
                                                        this.props.state.task.workflow_task_step
                                                            .filter(x => x.order === stepIndex + 1)[0].name
                                                    }
                                                />
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'contract_activation':
                                    return <WorkflowPOContractActivation
                                        key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                        name={y.component.name}
                                        workflow_queue_data={this.props.state.workflow_queue.data[0]}
                                        step={
                                            this.props.state.task.workflow_task_step
                                                .filter(x => x.order === stepIndex + 1)[0].name
                                        }
                                    />
                                case 'contract_activation_checklist':
                                    return <WorkflowPOContractActivation
                                        key={'workflow_task_step_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                        name={y.component.name}
                                        workflow_queue_data={this.props.state.workflow_queue.data[0]}
                                        step={
                                            this.props.state.task.workflow_task_step
                                                .filter(x => x.order === stepIndex + 1)[0].name
                                        }
                                    />
                                case 'contract_upload_vaf':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Vehicle Acceptance Form')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                {
                                                    this.props.state.workflow_queue.data.length
                                                        ? <WorkflowPOContractActivationVAF
                                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                            name={y.component.name}
                                                            workflow_queue_data={this.props.state.workflow_queue}
                                                            step={
                                                                this.props.state.task.workflow_task_step
                                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                                            }
                                                        />
                                                        : <Loading message={'Retrieving data, please wait...'} />
                                                }
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'invoiceap_input':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Creditor Invoice')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                {

                                                    <Invoiceap
                                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                        name={y.component.name}
                                                        workflow_queue_data={this.props.state.workflow_queue}
                                                        stepIndex={stepIndex}
                                                        step={
                                                            this.props.state.task.workflow_task_step
                                                                .filter(x => x.order === stepIndex + 1)[0].name
                                                        }
                                                    />

                                                }
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'invoiceap_rejection':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en(y.component.title)}
                                                subtitle={window.t.en('Creditor Invoice')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                {

                                                    <InvoiceapReject

                                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                        name={y.component.name}
                                                        workflow_queue_data={this.props.state.workflow_queue}
                                                        stepIndex={stepIndex}
                                                        step={
                                                            this.props.state.task.workflow_task_step
                                                                .filter(x => x.order === stepIndex + 1)[0].name
                                                        }
                                                    />

                                                }
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'contract_restructure_select_contract':
                                    return <div>
                                        {
                                            <ContractSelectionGrid
                                                key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                name={y.component.name}
                                                step={
                                                    this.props.state.task.workflow_task_step
                                                        .filter(x => x.order === stepIndex + 1)[0].name
                                                }
                                            />
                                        }
                                        <br />
                                    </div>
                                case 'contract_restructure_selected_contract':
                                    return <div>
                                        <CardContent expandable={true}>
                                                {
                                                    <ContractRestructure
                                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                        name={y.component.name}
                                                        step={
                                                            this.props.state.task.workflow_task_step
                                                                .filter(x => x.order === stepIndex + 1)[0].name
                                                        }
                                                    />
                                                }
                                            </CardContent>
                                        <br />
                                    </div>
                                case 'manage_maintenance_creator':
                                    return <div>
                                         <CreateMMQuote
                                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                        name={y.component.name}
                                                        step={
                                                            this.props.state.task.workflow_task_step
                                                                .filter(x => x.order === stepIndex + 1)[0].name
                                                        }
                                                    />
                                        <br />
                                    </div>
                                case 'fml_accept_quote':
                                    return <div>
                                        <WorkflowViewFMLQuoteConfirm
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            workflow_queue_data={this.props.state.workflow_queue}
                                            stepIndex={stepIndex}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'create_supplier_master':
                                    return <div>
                                        <SupplierMasterCapture
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'create_customer_master':
                                    return <div>
                                        <CustomerMasterCapture
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'create_sales_process':
                                    return <div>
                                        <SalesProcessCapture
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'accessory_master_capture':
                                    return <div>
                                        <AccessoryMasterCapture
                                            id={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                            data={
                                                this.props.state.task.workflow_task_step
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'vehicle_pricing_capture':
                                    return <div>
                                        <VehicleMasterCapture
                                            id={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                            data={
                                                this.props.state.task.workflow_task_step
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'costcentre_change_capture':
                                    return <div>
                                        <CostCentreChangeCapture
                                            id={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                            data={
                                                this.props.state.task.workflow_task_step
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'odometer_change_capture':
                                    return <div>
                                        <OdometerChangeCapture
                                            id={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                            data={
                                                this.props.state.task.workflow_task_step
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'registration_change_capture':
                                    return <div>
                                        <RegistrationChangeCapture
                                            id={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                            data={
                                                this.props.state.task.workflow_task_step
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'billing_run_start':
                                    return <div>
                                        <BillingRunStart
                                            key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={y.component.name}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'defleet_component_row_creator':
                                    return <WorkflowDefleet
                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                        name={y.component.name}
                                        workflow_queue_data={this.props.state.workflow_queue}
                                        stepIndex={stepIndex}
                                        step={
                                            this.props.state.task.workflow_task_step
                                                .filter(x => x.order === stepIndex + 1)[0].name
                                        }
                                    />
                                case 'payment_component_row_creator':
                                    return <WorkflowPayment
                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                        name={y.component.name}
                                        workflow_queue_data={this.props.state.workflow_queue}
                                        stepIndex={stepIndex}
                                        step={
                                            this.props.state.task.workflow_task_step
                                                .filter(x => x.order === stepIndex + 1)[0].name
                                        }
                                    />
                                case 'defleet_component_upload_checksheet':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en("De-fleet")}
                                                subtitle={window.t.en('Upload Check Sheet')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                {

                                                    <DefleetUploadChecksheet
                                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                        name={y.component.name}
                                                        workflow_queue_data={this.props.state.workflow_queue}
                                                        stepIndex={stepIndex}
                                                        step={
                                                            this.props.state.task.workflow_task_step
                                                                .filter(x => x.order === stepIndex + 1)[0].name
                                                        }
                                                    />

                                                }
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'defleet_component_capture_quote':
                                    return <div><DefleetCaptureQuote
                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                        name={y.component.name}
                                        workflow_queue_data={this.props.state.workflow_queue}
                                        stepIndex={stepIndex}
                                        step={
                                            this.props.state.task.workflow_task_step
                                                .filter(x => x.order === stepIndex + 1)[0].name
                                        }
                                    />
                                    </div>
                                case 'defleet_component_upload_quotes':
                                    return <DefleetUploadQuotes
                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                        name={y.component.name}
                                        workflow_queue_data={this.props.state.workflow_queue}
                                        stepIndex={stepIndex}
                                        step={
                                            this.props.state.task.workflow_task_step
                                                .filter(x => x.order === stepIndex + 1)[0].name
                                        }
                                    />

                                case 'comp_full_maintenance_lease_create':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                title={window.t.en("Full Maintenance Lease")}
                                                subtitle={window.t.en('Create full maintenance lease quotation')}
                                                // avatar={
                                                //     <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                // }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                {

                                                    <WorkflowFMLQuote
                                                        key={'WorkflowFMLQuote' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                        name={'WorkflowFMLQuote'}
                                                        task={'tsk_full_maintenance_lease_create'}
                                                        mode={"create"}
                                                        workflow_queue_data={this.props.state.workflow_queue}
                                                        stepIndex={stepIndex}
                                                        step={
                                                            this.props.state.task.workflow_task_step
                                                                .filter(x => x.order === stepIndex + 1)[0].name
                                                        }
                                                    />

                                                }
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div>
                                case 'str_quotation_create':
                                    //console.log('str_quotation_create')
                                    return <div>
                                        <WorkflowSTRQuote
                                            key={'WorkflowSTRQuote' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={'WorkflowSTRQuote'}
                                            task={'tsk_str_quotation_create_quote'}
                                            mode={"create"}
                                            workflow_queue_data={this.props.state.workflow_queue}
                                            stepIndex={stepIndex}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'creditors_payment_create':
                                    //console.log('creditors_payment_create')
                                    return <div>
                                        <CreditorsPaymentCreate
                                            key={'creditors_payment_create_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={'creditors_payment_create'}
                                            task={'tsk_creditors_payment_create'}
                                            mode={"create"}
                                            workflow_queue_data={this.props.state.workflow_queue}
                                            stepIndex={stepIndex}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'credit_note_create':
                                    //console.log('credit_note_create')
                                    return <div>
                                        <CreditNoteCreate
                                            key={'credit_note_create_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={'credit_note_create'}
                                            task={'tsk_credit_note_create'}
                                            mode={"create"}
                                            workflow_queue_data={this.props.state.workflow_queue}
                                            stepIndex={stepIndex}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'interest_rate_create':
                                    //console.log('interest_rate_create')
                                    return <div>
                                        <InterestRateCreate
                                            key={'interest_rate_create_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={'interest_rate_create'}
                                            task={'tsk_interest_rate_create'}
                                            mode={"create"}
                                            workflow_queue_data={this.props.state.workflow_queue}
                                            stepIndex={stepIndex}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'cashbook_archive_create':
                                    //console.log('interest_rate_create')
                                    return <div>
                                        <CashbookArchiveCreate
                                            key={'cashbook_archive_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                            name={'cashbook_archive'}
                                            task={'tsk_cashbook_archive_create'}
                                            mode={"create"}
                                            workflow_queue_data={this.props.state.workflow_queue}
                                            stepIndex={stepIndex}
                                            step={
                                                this.props.state.task.workflow_task_step
                                                    .filter(x => x.order === stepIndex + 1)[0].name
                                            }
                                        />
                                        <br />
                                    </div>
                                case 'view_statement_review':
                                    return <div>
                                            <StatementReview
                                                key={'WorkflowSTRQuote' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                name={'StatementReview'}
                                                task={'tsk_statement_review'}
                                                mode={"create"}
                                                workflow_queue_data={this.props.state.workflow_queue}
                                                stepIndex={stepIndex}
                                                step={
                                                    this.props.state.task.workflow_task_step
                                                        .filter(x => x.order === stepIndex + 1)[0].name
                                                }
                                            />
                                    </div>
                                case 'str_quotation_review':
                                case 'str_quotation_bookout':
                                case 'str_quotation_activation':
                                    return <div>
                                            <STRQuotationApproval
                                                key={'STRQuotationApproval' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                name={'STRQuotationApproval'}
                                                task={'str_quotation'}
                                                workflow_queue_data={this.props.state.workflow_queue}
                                                stepIndex={stepIndex}
                                                step={
                                                    this.props.state.task.workflow_task_step
                                                        .filter(x => x.order === stepIndex + 1)[0].name
                                                }
                                            />
                                    </div>
                                case 'creditors_payment_view':
                                    return <div>
                                            <PaymentCapture
                                                key={'PaymentCapture' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                name={'PaymentCapture'}
                                                task={'creditors_payment_view'}
                                                mode={"create"}
                                                workflow_queue_data={this.props.state.workflow_queue}
                                                stepIndex={stepIndex}
                                                step={
                                                    this.props.state.task.workflow_task_step
                                                        .filter(x => x.order === stepIndex + 1)[0].name
                                                }
                                            />
                                    </div>
                                case 'creditors_invoice_purchase_order_view':
                                    return <div>
                                            <PurchaseOrderCapture
                                                key={'PurchaseOrderCapture' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                name={'PurchaseOrderCapture'}
                                                task={'creditors_invoice_purchase_order_view'}
                                                mode={"create"}
                                                workflow_queue_data={this.props.state.workflow_queue}
                                                stepIndex={stepIndex}
                                                step={
                                                    this.props.state.task.workflow_task_step
                                                        .filter(x => x.order === stepIndex + 1)[0].name
                                                }
                                            />
                                    </div>
                                /* case 'defleet_component_review_and_approve_quote':
                                    return <div>
                                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                                            <CardHeader
                                                actAsExpander={true}
                                                //showExpandableButton={true}
                                                title={window.t.en("De-fleet"}
                                                subtitle={window.t.en('De-fleet Quote Approval'}
                                                avatar={
                                                    <Icon istyle={this.getStatusIcon(y.component)} iclass={y.component.icon.unicode} iname={y.component.icon.name} />
                                                }
                                            >
                                                {this.getErrors(y.workflow_task_step_component_display_type.name, y.component)}
                                            </CardHeader>
                                            <CardContent expandable={true}>
                                                {

                                                    <DefleetQuoteApproval
                                                        key={'workflow_task_steps_' + this.props.state.task.workflow_task_step.name + '_' + y.component.name}
                                                        name={y.component.name}
                                                        workflow_queue_data={this.props.state.workflow_queue}
                                                        stepIndex={stepIndex}
                                                        step={
                                                            this.props.state.task.workflow_task_step
                                                                .filter(x => x.order === stepIndex + 1)[0].name
                                                        }
                                                    />

                                                }
                                            </CardContent>
                                        </Card>

                                        <br />
                                    </div> */
                                default:
                                    return 'Unknown Component Display Type'
                            }
                        })
                })
            : <div>Loading...</div>
    }

    getSteps() {
        return this.props.state.task.workflow_task_step
            ? this.props.state.task.workflow_task_step
                .sort((x, y) => x.order - y.order)
                .map(x => {
                    return <Step key={'step_' + x.workflow_task_step_id}>
                        <StepLabel>{x.title}</StepLabel>
                    </Step>
                })
            : <Step>
                <StepLabel>Loading...</StepLabel>
            </Step>
    }

    getTitle() {
        //console.log('getTitle', this.props.state.nav.selectedItemMainTitle)
        return this.props.state.task.workflow_task_step
            ? this.props.state.task.title
            : 'Loading...'
    }

    getSubtitle() {
        return this.props.state.task.workflow_task_step
            ? this.props.state.task.description
            : 'Loading...'
    }

    getIcon() {
        return this.props.state.task.workflow_task_step
            ? this.props.state.task.icon.name
            : 'query_builder'
    }

    getProgress() {
        return this.props.state.progress.toString() + '% Completed'
    }

    getConfirmationStepComponentData(step, component) {
        return step.workflow_task_step_component
            .filter(x => x.component.name === component.name)
            .map(x => {
                x.data.map(x => {
                    return <WorkflowView
                        key={'confirmationStepComponentDataView_' + component.name}
                        name={component.name}
                        data={x}
                    />
                })
            })
    }
    
    getConfirmationStepComponents(step) {
        return step.workflow_task_step_component.map(x => {
            return <Card style={styles.workflowTaskStepComponentCard}>
                <CardContent expandable={true}>
                    {this.getConfirmationStepComponentData(step, x.component)}
                </CardContent>
            </Card>
        })
    }


    getConfirmationView() {
        const task = this.props.state.task.name
        //console.log('getConfirmationView task', task)
        switch (task) {
            case 'vehicle_master':
            case 'supplier_master':
            case 'customer_master':
            case 'driver_master':
            case 'purchase_order':
                return this.props.state.task.workflow_task_step
                    .sort((x, y) => x.order - y.order)
                    .map(x => {
                        return <div>
                            <Card style={styles.workflowTaskStepComponentCard}>
                                <CardHeader
                                    title={window.t.en(x.title)}
                                    subtitle={x.description}
                                    actAsExpander={true}
                                />
                                <CardContent expandable={true}>
                                    {this.getConfirmationStepComponents(x)}
                                </CardContent>
                            </Card>
                            <br />
                        </div>
                    })
            case 'registration_add':
                return this.props.state.task.workflow_task_step
                    .sort((x, y) => x.order - y.order)
                    .map(x => {
                        return <div>
                            <Card style={styles.workflowTaskStepComponentCard}>
                                <CardHeader
                                    title={window.t.en(x.title)}
                                    subtitle={x.description}
                                    // avatar={
                                    //     <Icon istyle={styles.confirmIcon} iclass={x.component.icon.unicode} iname={x.component.icon.name} />
                                    // }
                                    actAsExpander={false}
                                />
                                <CardContent expandable={false}>
                                    {this.getConfirmationStepComponents(x)}
                                </CardContent>
                            </Card>
                            <br />
                        </div>
                    })
            case 'tsk_fml_quote_accept_customer_quote':
                return this.props.state.workflow_queue.data
                    ? <WorkflowViewFMLQuoteConfirm
                    key={'tsk_fml_quote_accept_customer_quote'}
                    name={'tsk_fml_quote_accept_customer_quote'}
                    step={task}
                    confirm={'confirm'}
                    workflow_queue_data={this.props.state.workflow_queue}
                    data={this.props.state.task}
                    /> : ''
            case 'tsk_supplier_master_capture_details':
                return this.props.state.workflow_queue.data
                    ? <SupplierMasterCapture
                    key={'tsk_supplier_master_capture_details'}
                    name={'tsk_supplier_master_capture_details'}
                    step={task}
                    mode={'confirm'}
                    workflow_queue_data={this.props.state.workflow_queue}
                    data={this.props.state.task}
                    /> : ''
            case 'tsk_customer_master_capture_details':
                return this.props.state.workflow_queue.data
                    ? <CustomerMasterCapture
                    key={'tsk_customer_master_capture_details'}
                    name={'tsk_customer_master_capture_details'}
                    step={task}
                    mode={'confirm'}
                    workflow_queue_data={this.props.state.workflow_queue}
                    data={this.props.state.task}
                    /> : ''
            case 'tsk_accessory_master_capture':
                return this.props.state.workflow_queue.data
                    ? <AccessoryMasterCapture
                    id={'tsk_accessory_master_capture'}
                    key={'tsk_accessory_master_capture'}
                    name={'tsk_accessory_master_capture'}
                    step={task}
                    mode={'confirm'}
                    workflow_queue_data={this.props.state.workflow_queue}
                    taskdata={this.props.state.task}
                    data={
                        this.props.state.task.workflow_task_step
                    }
                    /> : ''
            case 'tsk_vehicle_pricing_capture':
                return this.props.state.workflow_queue.data
                    ? <VehicleMasterCapture
                    id={'tsk_vehicle_pricing_capture'}
                    key={'tsk_vehicle_pricing_capture'}
                    name={'tsk_vehicle_pricing_capture'}
                    step={task}
                    mode={'confirm'}
                    workflow_queue_data={this.props.state.workflow_queue}
                    taskdata={this.props.state.task}
                    data={
                        this.props.state.task.workflow_task_step
                    }
                    /> : ''
            case 'tsk_costcentre_change_capture':
                return this.props.state.workflow_queue.data
                    ? <CostCentreChangeCapture
                    id={'tsk_costcentre_change_capture'}
                    key={'tsk_costcentre_change_capture'}
                    name={'tsk_costcentre_change_capture'}
                    step={task}
                    mode={'confirm'}
                    workflow_queue_data={this.props.state.workflow_queue}
                    taskdata={this.props.state.task}
                    data={
                        this.props.state.task.workflow_task_step
                    }
                    /> : ''
            case 'tsk_odometer_change_capture':
                return this.props.state.workflow_queue.data
                    ? <OdometerChangeCapture
                    id={'tsk_odometer_change_capture'}
                    key={'tsk_odometer_change_capture'}
                    name={'tsk_odometer_change_capture'}
                    step={task}
                    mode={'confirm'}
                    workflow_queue_data={this.props.state.workflow_queue}
                    taskdata={this.props.state.task}
                    data={
                        this.props.state.task.workflow_task_step
                    }
                    /> : ''
            case 'tsk_registration_change_capture':
                return this.props.state.workflow_queue.data
                    ? <RegistrationChangeCapture
                    id={'tsk_registration_change_capture'}
                    key={'tsk_registration_change_capture'}
                    name={'tsk_registration_change_capture'}
                    step={task}
                    mode={'confirm'}
                    workflow_queue_data={this.props.state.workflow_queue}
                    taskdata={this.props.state.task}
                    data={
                        this.props.state.task.workflow_task_step
                    }
                    /> : ''
            case 'tsk_billing_run_start':
                return this.props.state.workflow_queue.data
                ? <BillingRunStartConfirm
                        key={'confirmationStepComponentDataView_' + this.props.state.task.name}
                        name={'billing_run'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                        data={this.props.state.task}
                    /> : ''
            case 'tsk_fml_quote_vehicle_receipt':
                return this.props.state.workflow_queue.data
                    ? <WorkflowPOReceiptVehicleConfirm
                        key={'confirmationStepComponentDataView_' + this.props.state.task.name}
                        name={'purchaseorder'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                        data={this.props.state.task}
                    />
                    : ''
            case 'tsk_fml_quote_create_accessory_po':
                return this.props.state.workflow_queue.data
                    ? <WorkflowPOAccessoriesConfirm
                        key={'confirmationStepComponentDataView_' + this.props.state.task.name}
                        name={'purchaseorder'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue.data[0]}
                        data={this.props.state.task}
                    />
                    : ''
            case 'tsk_fml_quote_receipt_accessory':
                return <WorkflowPOReceiptAccessoriesConfirm
                    key={'WorkflowPOReceiptAccessoriesConfirm_' + this.props.state.task.name}
                    name={'purchaseorder'}
                    task={task}
                    workflow_queue_data={this.props.state.workflow_queue.data[0]}
                    data={this.props.state.task}
                />
            case 'tsk_fml_quote_contract_activation':
                return <WorkflowPOContractActivationConfirm
                        key={'WorkflowPOContractActivationConfirm_' + this.props.state.task.name}
                        name={'purchaseorder'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue.data[0]}
                        data={this.props.state.task}
                    />
            case 'tsk_fml_quote_create_delivery_po':
                return <WorkflowPODeliveryConfirm
                        key={'WorkflowPODeliveryConfirm_' + this.props.state.task.name}
                        name={'purchaseorder'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue.data[0]}
                        data={this.props.state.task}
                    />
            case 'tsk_fml_quote_vaf':
                return this.props.state.workflow_queue.data
                    ? <WorkflowPOContractActivationVAFConfirm
                        key={'WorkflowPODeliveryConfirm_' + this.props.state.task.name}
                        name={'purchaseorder'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                        data={this.props.state.task}
                    />
                    : ''
            case 'tsk_purchase_order_request_for_quote':
                return this.props.state.workflow_queue.data
                    ? <POBookingConfirm
                        key={'POBookingConfirm_' + this.props.state.task.name}
                        name={'booking'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                        data={this.props.state.task}
                    />
                    : ''
            case 'tsk_rebill_request_for_quote':
                return this.props.state.workflow_queue.data
                    ? <POBookingConfirm
                        key={'POBookingConfirm_' + this.props.state.task.name}
                        name={'booking'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                        data={this.props.state.task}
                    />
                    : ''
            case 'tsk_purchase_order_capture_quote':
                return this.props.state.workflow_queue.data
                    ? <POBookingInvoiceConfirm
                        key={'POBookingInvoiceConfirm_' + this.props.state.task.name}
                        name={'booking'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_rebill_capture_quote':
                return this.props.state.workflow_queue.data
                    ? <POBookingInvoiceConfirm
                        key={'POBookingInvoiceConfirm_' + this.props.state.task.name}
                        name={'booking'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_invoice_ap_captureinput':
                return this.props.state.workflow_queue.data
                    ? <InvoiceapConfirm
                        key={'InvoiceapConfirm_' + this.props.state.task.name}
                        name={'invoiceap'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_invoice_ap_rejection':
                return this.props.state.workflow_queue.data
                    ? <InvoiceapRejectConfirm
                        key={'InvoiceapRejectConfirm_' + this.props.state.task.name}
                        name={'invoiceap'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_str_quote_create_customer_quote':
                return this.props.state.workflow_queue.data
                    ? <CaptureSTRConfirm
                        key={'CaptureSTRConfirm'}
                        name={'CaptureSTRConfirm'}
                        confirm={true}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_contract_restructure_select_contract':
                return this.props.state.workflow_queue.data
                    ? <ContractRestructureConfirm
                        key={'ContractRestructureConfirm'}
                        name={'ContractRestructureConfirm'}
                        confirm={true}
                        task={task}
                    />
                    : ''
            case 'tsk_mm_capture_quote':
                return this.props.state.workflow_queue.data
                    ? <CreateMMQuoteConfirm
                        key={'CreateMMQuoteConfirm'}
                        name={'CreateMMQuoteConfirm'}
                        confirm={true}
                        task={task}
                    />
                    : ''
            case 'tsk_defleet_generate_checksheet':
                return this.props.state.workflow_queue.data
                    ? <DefleetGenerateChecksheet
                        key={'defleetGenerateChecksheet_' + this.props.state.task.name}
                        name={'defleet'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_payment_loading':
                return this.props.state.workflow_queue.data
                    ? <WorkflowPaymentConfirm
                        key={'workflowPaymentConfirm_' + this.props.state.task.name}
                        name={'payment'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_defleet_upload_checksheet':
                return this.props.state.workflow_queue.data
                    ? <DefleetUploadChecksheetConfirm
                        key={'defleetUploadChecksheetConfirm' + this.props.state.task.name}
                        name={'defleet'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_defleet_capture_quote':
                return this.props.state.workflow_queue.data
                    ? <DefleetUploadQuotesConfirm
                        key={'defleetUploadQuotesConfirm' + this.props.state.task.name}
                        name={'defleet'}
                        task={task}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_statement_review':
                return this.props.state.workflow_queue.data
                    ? <StatementReview
                        key={'StatementReview' + this.props.state.task.name}
                        name={'Statement Review'}
                        task={task}
                        mode={"confirm"}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_fml_quote_release_note':
                return this.props.state.workflow_queue.data
                    ? <WorkflowFMLReleaseNote
                        key={'WorkflowFMLReleaseNote' + this.props.state.task.name}
                        name={'WorkflowFMLReleaseNote'}
                        task={task}
                        confirm={true}
                        workflow_queue_data={this.props.state.workflow_queue.data[0]}
                    />
                    : ''
            case 'tsk_fml_quote_accept_release_note':
                return this.props.state.workflow_queue.data
                    ? <WorkflowFMLAcceptReleaseNote
                        key={'WorkflowFMLAcceptReleaseNote' + this.props.state.task.name}
                        name={'WorkflowFMLAcceptReleaseNote'}
                        task={task}
                        confirm={true}
                        workflow_queue_data={this.props.state.workflow_queue.data[0]}
                    />
                    : ''
            case 'tsk_creditors_invoice_capture':
                return this.props.state.workflow_queue.data
                    ? <PaymentCapture
                        key={'PaymentCapture' + this.props.state.task.name}
                        name={'Payment Capture'}
                        task={task}
                        mode={"confirm"}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_creditors_invoice_purchase_order':
                return this.props.state.workflow_queue.data
                    ? <PurchaseOrderCapture
                        key={'PaymentCapture' + this.props.state.task.name}
                        name={'Payment Capture'}
                        task={task}
                        mode={"confirm"}
                        workflow_queue_data={this.props.state.workflow_queue}
                    />
                    : ''
            case 'tsk_full_maintenance_lease_create':
                return this.props.state.workflow_queue.data
                    ? <div>
                        <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
                            <CardHeader
                                actAsExpander={true}
                                //showExpandableButton={true}
                                title={window.t.en('Confirm Full Maintenance Lease')}
                                subtitle={window.t.en('Please verify that all details captured are correct and accurate')}
                                // avatar={
                                //     <Icon iclass={'customer-icons'} iname={'workflow'} />
                                // }
                            >
                            </CardHeader>
                            <CardContent expandable={true}>
                                <WorkflowFMLQuote
                                    key={'WorkflowFMLQuote' + this.props.state.task.name}
                                    name={'WorkflowFMLQuote'}
                                    mode={"confirm"}
                                    task={task}
                                    workflow_queue_data={this.props.state.workflow_queue}
                                />
                            </CardContent></Card></div>
                    : ''

            case 'tsk_str_quotation_create_quote':
                return this.props.state.workflow_queue.data
                    ? <div>
                        <WorkflowSTRQuote
                            key={'WorkflowSTRQuote' + this.props.state.task.name}
                            name={'WorkflowSTRQuoteConfirm'}
                            mode={"confirm"}
                            task={task}
                            workflow_queue_data={this.props.state.workflow_queue}
                        />
                </div>
                    : ''
            case 'str_quotation_create':
                return this.props.state.workflow_queue.data
                    ? <div>
                        <WorkflowSTRQuote
                            key={'WorkflowSTRQuote' + this.props.state.task.name}
                            name={'WorkflowSTRQuoteConfirm'}
                            mode={"confirm"}
                            task={task}
                            workflow_queue_data={this.props.state.workflow_queue}
                        />
                </div>
                    : ''
            case 'tsk_creditors_payment_create':
                return this.props.state.workflow_queue.data
                    ? <div>
                        <CreditorsPaymentCreate
                            key={'creditors_payment_create_confirm_' + this.props.state.task.name}
                            name={'creditors_payment_create_confirm'}
                            mode={"confirm"}
                            task={task}
                            workflow_queue_data={this.props.state.workflow_queue}
                        />
                </div>
                    : ''
            case 'tsk_credit_note_create':
                return this.props.state.workflow_queue.data
                    ? <div>
                        <CreditNoteCreate
                            key={'credit_note_create_' + this.props.state.task.name}
                            name={'credit_note_create'}
                            mode={"confirm"}
                            task={task}
                            workflow_queue_data={this.props.state.workflow_queue}
                        />
                </div>
                    : ''
            case 'tsk_interest_rate_create':
                return this.props.state.workflow_queue.data
                    ? <div>
                        <InterestRateCreate
                            key={'interest_rate_create_' + this.props.state.task.name}
                            name={'interest_rate_create'}
                            mode={"confirm"}
                            task={task}
                            workflow_queue_data={this.props.state.workflow_queue}
                        />
                </div>
                    : ''
            case 'tsk_cashbook_archive_create':
                return this.props.state.workflow_queue.data
                    ? <div>
                        <CashbookArchiveCreate
                            key={'cashbook_archive_create_' + this.props.state.task.name}
                            name={'cashbook_archive_create'}
                            mode={"confirm"}
                            task={task}
                            workflow_queue_data={this.props.state.workflow_queue}
                        />
                </div>
                    : ''
            case 'tsk_sales_process_capture_details':
                return this.props.state.workflow_queue.data
                    ? <div>
                        <SalesProcessCapture
                            key={'sales_process_capture_' + this.props.state.task.name}
                            name={'sales_process_capture'}
                            mode={"confirm"}
                            task={task}
                            workflow_queue_data={this.props.state.workflow_queue}
                        />
                </div>
                    : ''
            default:
                return <WorkflowQuoteConfirm
                    key={'confirmationStepComponentDataView_' + this.props.state.task.name}
                    name={'surrogateheader'}
                    data={this.props.state.task}
                />
        }
    }

    getTaskUsers(users) {
        return <> <Button className={"global_button"} variant="contained"
                    onClick={(event,) => {
                        this.props.actions.submitWorkflowTaskInput(this.props.state.user)
                    }}
                    style={{ marginRight: 0 }}
                >{'Confirm'}  <Icon iclass={'material-icons'} iname={'forward'} /></Button>
            {/* <ButtonGroup
                variant="contained"
                size="large"
                className={"global_button btn-success"}
                //className="btn-second m-2"
                color="primary"
                aria-label="split button">
               
                &nbsp;
                <Button variant="contained"
                    className="btn-transition-none px-2"
                    color="primary"
                    // size="small"
                    // aria-haspopup="true"
                    onClick={(event,) => {
                        this.props.actions.setToggleConfirm(true, event.currentTarget)
                    }}>
                    {/* <ArrowDropDownIcon /> 
                </Button>
            </ButtonGroup>

            {/* <Dialog style={{display: 'absolute', right: 20, bottom: 20}} anchorEl={this.props.state.confirmEl} onClose={() => {
                    this.props.actions.setToggleConfirm(false, null)
                }} aria-labelledby="simple-dialog-title" open={this.props.state.confirm}>
                <DialogTitle id="simple-dialog-title">{'Select Assign User'}</DialogTitle>
                <List style={{height: 400}}>
                    {users.map((su) => (
                    <ListItem button onClick={(event, ) => {
                        this.props.actions.submitWorkflowTaskInput(su)
                         }} key={su.firstname}>
                        <ListItemAvatar>
                        <Avatar>
                            <PersonIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={su.firstname + ' ' + su.lastname} secondary={<small style={{color: 'white'}}>{su.email}</small>} />
                    </ListItem>
                    ))}
                </List>
            </Dialog> 

            <Menu
                id="simple-menu2"
                anchorEl={this.props.state.confirmEl}
                keepMounted
                open={this.props.state.confirm}
                onClose={() => {
                    this.props.actions.setToggleConfirm(false, null)
                }}
                classes={{ list: 'p-0' }}>
                <div className="p-3">
                    {
                        users.map(ut => {
                            return <MenuItem className="pr-5 px-3 text-danger"
                                onClick={(event,) => {
                                    this.props.actions.submitWorkflowTaskInput(ut)
                                }}>
                                {'CONFIRM AND ASSIGN TO ' + ut.firstname}
                            </MenuItem>
                        })
                    }
                    <MenuItem className="pr-5 px-3 text-danger"
                        onClick={(event,) => {
                            this.props.actions.submitWorkflowTaskInput(null)
                        }}>
                        {'Confirm To Team'}
                    </MenuItem>
                </div>
            </Menu> */}
        </>
    }

    getTaskUser(user) {
        //console.log('getTaskUser', user)
        return user.length
            ? <>
                    <Button  className={"global_button"} variant="contained"
                        onClick={(event,) => {
                            this.props.actions.submitWorkflowTaskInput(user.length ? user[0] : user)
                            // this.props.actions.submitWorkflowTaskInput(user)
                        }}
                    >{'Assign Task To ' + user[0].firstname}   <Icon iclass={'material-icons'} iname={'forward'} /></Button>
                {/* <ButtonGroup
                    variant="contained"
                    //size="large"
                    className={"global_button btn-success"}
                    //className="btn-second m-2"
                    color="primary"
                    aria-label="split button">
                </ButtonGroup> */}
            </>
            : <><Button  className={"global_button"} variant="contained"
                        onClick={(event,) => {
                            this.props.actions.submitWorkflowTaskInput(this.props.state.user)
                        }}
                        
                        style={{ marginRight: 0 }}
                    >{'Confirm'}  <Icon iclass={'material-icons'} iname={'forward'} /></Button>
                {/* <ButtonGroup
                    variant="contained"
                    //size="large"
                    className={"global_button btn-success"}
                    //className="btn-second m-2"
                    color="primary"
                    aria-label="split button">
                    
                    {/* <Button
                    className="btn-transition-none px-2"
                    color="primary"
                    size="small"
                    aria-haspopup="true"
                    onClick={(event, ) => {
                        this.props.actions.setToggleConfirm(true, event.currentTarget)
                    }}>
                    <ArrowDropDownIcon />
                </Button> 
                </ButtonGroup> */}
            </>
    }

    getConfirmSection(component) {
        return <div>
            <div className="d-flex align-items-right justify-content-right flex-wrap" style={{ float: 'right' }}>
                <br />
                {
                    component.state.finished
                        ? (
                            <div>

                                <div className="d-flex align-items-right justify-content-right flex-wrap">
                                    <br />
                                    <Button
                                        label='Start Over'
                                        //size="large"
                                        className={"global_button btn-success"}
                                        disabled={component.state.stepIndex === 0}
                                        style={{ marginRight: 12 }}
                                        onClick={() => {
                                            component.actions.resetWorkflowTaskInput()
                                        }}
                                    >
                                        {window.t.en('Start Over')}  <Icon iclass={'material-icons'} iname={'clear'} /></Button>
                                    {
                                        component.connector
                                            ? component.connector.nextTask.length
                                                ? this.getTaskUser(component.connector.nextTask[0].users)
                                                : this.getTaskUsers([])
                                            : ''
                                    }

                                </div>
                            </div>
                        )
                        :
                        (
                            <div>
                                <Button  className={"global_button"} variant="contained"
                                    label="Back"
                                    primary={true}
                                    disabled={component.state.stepIndex === 0}
                                    style={{ marginRight: 12 }}
                                    onClick={() => {
                                        component.actions.goToPreviousStep()
                                    }}
                                >{window.t.en('Back')} <Icon iclass={'material-icons'} iname={'clear'} /></Button>
                                &nbsp;
                                <Button className={"global_button"} variant="contained"
                                    label={component.state.stepIndex === 2 ? 'Finish' : 'Next'}
                                    primary={true}
                                    onClick={() => {
                                        console.log('workflow task', this.props.state.task)
                                        component.actions.goToNextStep(this.props.state.task);
                                    }}
                                >{component.state.stepIndex === 2 ? 'Finish' : 'Next'} <Icon iclass={'material-icons'} iname={'forward'} /></Button>
                            </div>
                        )}
            </div>
        </div>
    }

    getView() {
        const component = {
            state: this.props.state,
            params: this.props.match.params,
            actions: this.props.actions,
            connector: this.props.state.workflow_task_connector ? this.props.state.workflow_task_connector[0] : null,
            styles: styles
        }
        let teams = []
        try {
            let team_id = component.state.task.workflow_task_team[0].team_id
            let team = component.state.workflowQueueTasks.teams.filter(x => x.team_id === team_id)[0]
            teams = this.props.state.teams[team.name]?.user_team
        }
        catch (err) {
        }

        console.log('CustomErrorBoundaryx render', component)
        //console.log('Workflow_Stepperx  state', component.state )
        //console.log('Workflow_Stepperx  task', component.state.task )
        //console.log('Workflow_Stepperx  data', component.state.workflowQueueTasks.workflow.workflow_id )
        return (
            <>
                <>
                    {/* <Row>
                        <Col xl={12} style={{marginRight: '0 40px'}}>
                            <WorkflowTaskHeader
                                title={window.t.en(this.getTitle())}
                                description={this.getSubtitle()}
                                content={
                                    <div elevation={1} style={{margin: '0 110px 0 40px'}}>
                                        <Stepper  activeStep={component.state.stepIndex} orientation="horizontal" style={component.styles.stepper}>
                                            {
                                                this.getSteps()
                                            }
                                        </Stepper>
                                    </div>
                                } />
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xl={12}> 
                                    <div elevation={1} style={{margin: '0 110px 0 40px'}}>
                                        <Stepper  activeStep={component.state.stepIndex} orientation="horizontal" style={component.styles.stepper}>
                                            {
                                                this.getSteps()
                                            }
                                        </Stepper>
                                    </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            
                            <Workflow_Stepper 
                                name={component.state.workflowQueueTasks.data['workflow_task#name']} 
                                order={component.state.workflowQueueTasks.data['workflow_task#order']} 
                                workflow_id={component.state.workflowQueueTasks.data['workflow#workflow_id']}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {
                                component.state.finished
                                    ? this.getConfirmationView()
                                    : this.getStepComponents(component.state.stepIndex)
                            }
                            {
                                component.connector 
                                    ? <CardControl
                                        title={'Next Task: ' + (component.connector.nextTask.length ? component.connector.nextTask[0].title : '')}
                                        subtitle={
                                            component.connector.nextTask.length
                                                ? component.connector.nextTask[0].users.length
                                                    ? 'Assign Task To: ' + component.connector.nextTask[0].users[0].firstname
                                                    : 'Task to be assign to you'
                                                : 'Task to be assign to you'
                                        }
                                        content={this.getConfirmSection(component)}
                                    /> : ''

                            }
                        </Col>
                    </Row>
                </>

                <Dialog
                    open={this.props.state.dialog_win}
                    style={{minWidth: '400px'}}
                    onClose={(event) => {
                        event.preventDefault()
                        this.props.actions.toggleDialog(false)
                    }}
                //classes={{ paper: 'shadow-lg rounded' }}
                >
                    <div className="text-center p-5">
                        <h4 className="font-weight-bold mt-4">
                            ERROR
                        </h4>
                        <p className="mb-0 font-size-lg text-muted">
                            {this.props.state.dialog_msg}
                        </p>
                        <div className="pt-4">
                            <Button
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleDialog(false)
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">Ok</span>
                            </Button>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    open={this.props.state.customer_inactive_win}
                    style={{minWidth: '400px'}}
                    onClose={(event) => {
                        event.preventDefault()
                        this.props.actions.toggleCustomerDialog(false)
                    }}
                //classes={{ paper: 'shadow-lg rounded' }}
                >
                    <div className="text-center p-5">
                        <h4 className="font-weight-bold mt-4">
                            Customer Alert
                        </h4>
                        <p className="mb-0 font-size-lg text-muted">
                           {this.props.state.customer_inactive_msg}
                        </p>
                        <div className="pt-4">
                            <Button
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleCustomerDialog(false)
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">Ok</span>
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }

    loading() {
        let loading = false

        if (this.props.state.loading) {
            return true
        }

        if (this.props.state.task && this.props.state.task.components) {
            for (let index = 0; index < this.props.state.task.components.length; index++) {
                try {
                    if (this.props.state.components[this.props.state.task.components[index]].loading) {
                        loading = true
                        break
                    }
                } catch (error) {

                }
            }

            return loading
        }
        else {
            return true
        }
    }

    render() {
    
        return this.loading()
            ? <div>{'WorkflowTaskInput'} </div> 
            : this.props.state.error
                ? 'Unable to retrieve task information, please try again...'
                : this.getView()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowTaskInput,
            workflowQueueTasks: {
                ...state.workflowQueueTasks
            },
            login: {
                ...state.login
            },
            teams: {
                ...state.teams
            },
            components: {
                ...state.components
            },
            user: {
                ...state.user
            },
            customer: {
                ...state.contractSelectionGrid
            },
            str_customer :{
                ...state.captureQuotation

            },
            mm_customer : { 
                ...state.createMMQuote
            },
            nav: {
                ...state.navigationMenu
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskInput)