
import * as _ from 'lodash'
import moment from 'moment'

import * as appTypes from '../App/types'
import * as types from './types'

export const refreshCISData = () => {
    return {
        type: types.REFRESH_CIS,
        payload: {
            type: 'REFRESH_CIS',
        }
    }
}

export const setCISData = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CIS_DATA,
            payload: {
                data: payload.data,
                prop: 'cisData'
            }
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'CIS Refreshed',
                overflowText: 'CIS Refreshed',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}


export const showAddDialog = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SHOW_CIS_ADD_DIALOG,
            payload: {
                data: payload,
                prop: 'addUser'
            }
        })
    }
}

export const showRemoveDialog = (payload, user_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SHOW_CIS_CONFIRM_DELETE_DIALOG,
            payload: {
                data: payload,
                prop: 'removeUser'
            }
        })
        dispatch({
            type: types.SHOW_CIS_CONFIRM_DELETE_DIALOG,
            payload: {
                data: user_id,
                prop: 'removeUserId'
            }
        })
    }
}

export const setUserInput = (prop, message) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CIS_USER_INPUT,
            payload: {
                data: message,
                prop: prop
            }
        })
    }
}


export const getCISExcelData = () => {
    return {
        type: types.GET_CIS_DOWNLOAD_DATA,
        payload: {
            type: 'GET_CIS_DOWNLOAD_DATA',
        }
    }
}

export const viewDocument = (data) => {
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf], data.data.key)

    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'CIS downloaded',
                overflowText: 'CIS downloaded',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}


/////sending email part


export const getCISSendEmailData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CIS_SEND_EMAIL_DATA,
            payload: {
                type: 'GET_CIS_SEND_EMAIL_DATA',
                userMessage: getState().cis.userMessage
            }
        })
    }
}


export const sendDocument = (data) => {

    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'CIS Report sent',
                overflowText: 'CIS Report sent',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(getCisHistoryTable())
    }
}



/////////////////////////////all user emails
export const getCISEmailData = () => {
    return {
        type: types.GET_CIS_EMAIL_DATA,
        payload: {
            type: 'GET_CIS_DOWNLOAD_DATA',
        }
    }
}

export const setCISEmailData = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CIS_EMAIL_DATA,
            payload: {
                data: payload.data,
                prop: 'cisMail'
            }
        })
    }
}

export const addCISEmail = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ADD_USER_LIST,
            payload: {
                type: 'ADD_USER_LIST',
                users: getState().cis.selectedUser
            }
        })
    }
}

export const removeCISEmail = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REMOVE_USER_FROM_LIST,
            payload: {
                type: 'REMOVE_USER_FROM_LIST',
                users: [{
                    user_id: id
                }]
            }
        })

        dispatch({
            type: types.SHOW_CIS_CONFIRM_DELETE_DIALOG,
            payload: {
                data: null,
                prop: 'removeUser'
            }
        })
        dispatch({
            type: types.SHOW_CIS_CONFIRM_DELETE_DIALOG,
            payload: {
                data: null,
                prop: 'removeUserId'
            }
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'User Removed',
                overflowText: 'User Removed',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getUser = () => {
    return {
        type: types.GET_USER_CIS_LIST,
        payload: {
            type: 'GET_USER_CIS_LIST',
        }
    }
}

export const setUser = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_CIS_LIST,
            payload: {
                data: payload.data,
                prop: 'user'
            }
        })
    }
}

export const setSelectedUser = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_CIS_LIST,
            payload: {
                data: payload,
                prop: 'selectedUser'
            }
        })
    }
}



//populate history table


export const getCisHistoryTable = () => {
    return {
        type: types.GET_CIS_HISTORY_DATA,
        payload: {
            type: 'GET_CIS_HISTORY_DATA',
        }
    }
}

export const setCisHistoryTable = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CIS_HISTORY_DATA,
            payload: {
                data: payload.data,
                prop: 'cisHistory'
            }
        })
    }
}


/////////////download cis history file
export const getCIShistoryFile = (key) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CIS_HISTORY_FILE,
            payload: {
                type: 'GET_CIS_HISTORY_FILE',
                key: key
            }
        })
    }
}


export const viewCIShistoryFile = (data) => {
    //console.log('download CIS  History>>>>>>>>>>>>>>>>>>>>>>>>', data)
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key.substring(11, data.data.document.key.length))

    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Downloading CIS',
                overflowText: 'Downloading CIS',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}