
export const amount = ''
export const labourrate = ''
export const discount = ''
export const supplierdocument = ''
export const selectedSupplierId = ''
export const selectedSupplierName = ''
export const maintpdf_preview = ''
export const geoVehiclePosition = []
export const servicebasket = []
export const suppliers = []
export const supplier_data = []
export const quotations = []
export const selectedsupplier = {}
export const selected_supplier = {}
export const customercontractderivative = null
export const limitwin = null

export const documents = []

export const filename = {
    errorText: 'Filename is required',
    value: '',
    valid: false,
    empty: true,
}
export const invoicepreview = {
    errorText: 'Upload supplier invoice',
    value: '',
    valid: false,
    empty: true,
}
export const quotenumber = {
    state: 'getState().poBookingInvoice.quotenumber',
    errorText: 'Quote number is required',
    value: '',
    valid: false,
    empty: true,
}
export const quotedate = {
    errorText: 'Quote date is required',
    value: '',
    valid: false,
    empty: true,
}

export const fields = {
    documents: {
        errorText: 'Please upload documents for each of your quotations',
        state: "getState().poBookingInvoice.quotations.length ? getState().upload.documents?.filter(d => d.filename !== '')?.length === getState().poBookingInvoice.quotations.length : true",
        valid: false,
        empty: true,
    },
    quotationItemPrice: {
        state: 'getState().quoteItems.quotations?.length ?  getState().quoteItems.quotations : true',
        errorText: 'Please select Unit Price for the quotation line item',
        validations: [
            {
                type: 'decimal',
                required: true,
                maxLength: null,
                errorText: 'Please enter a valid Unit Price for the quotation line item'
            },
            {
                type: 'greaterThanZero',
                required: true,
                maxLength: null,
                errorText: 'Please enter a valid Unit Price for the quotation line item'
            }
        ],
        valid: false,
        type: 'table',
        tableFields: ['Price']
    },
    quotationItemQuantity: {
        state: 'getState().quoteItems.quotations?.length ?  getState().quoteItems.quotations : true',
        errorText: 'Please enter Quantity for the quotation line item',
        validations: [
            {
                type: 'integer',
                errorText: 'Please enter a valid Quantity for the quotation line item'
            },
            {
                type: 'greaterThanZero',
                required: true,
                maxLength: null,
                errorText: 'Please enter a valid Quantity for the quotation line item'
            }
        ],
        valid: false,
        type: 'table',
        tableFields: ['Quantity']
    },
    quotationItemDescription: {
        state: 'getState().quoteItems.quotations?.length ?  getState().quoteItems.quotations : true',
        errorText: 'Please select Item Description for the quotation line item',
        valid: false,
        type: 'table',
        tableFields: ['Description']
    },
    quotationItemCategory: {
        state: 'getState().quoteItems.quotations?.length ?  getState().quoteItems.quotations : true',
        errorText: 'Please select Item Category for the quotation line item',
        valid: false,
        type: 'table',
        tableFields: ['Class']
    },
    quotationItems: {
        state: 'getState().poBookingInvoice.quotations.length !== getState().quoteItems.quotations.length ? false : true',
        errorText: 'Please enter at least one quotation line item',
        valid: false,
        empty: true,
    },
    quotationDate: {
        state: 'getState().poBookingInvoice.quotations?.length ?  getState().poBookingInvoice.quotations : true',
        errorText: 'Please enter a quotation date for quotation item',
        valid: false,
        type: 'table',
        tableFields: ['quotedate']
    },
    quotationNumber: {
        state: 'getState().poBookingInvoice.quotations?.length ?  getState().poBookingInvoice.quotations : true',
        errorText: 'Please enter a quote number for quotation item',
        valid: false,
        type: 'table',
        tableFields: ['quotenumber']
    },
    quotationSupplier: {
        errorText: 'Please select a supplier for quotation item',
        state: 'getState().poBookingInvoice.quotations?.length ?  getState().poBookingInvoice.quotations : true',
        valid: false,
        type: 'table',
        tableFields: ['supplier_name']
    },
    quotations: {
        errorText: 'Please add quotation before you proceed',
        state: 'getState().poBookingInvoice.quotations.length',
        valid: false,
        empty: true,
    },
    supplier_over_limit: {
        errorText: 'Selected supplier have exceeded its monthly spent',
        state: 'getState().controls.supplier_in_limit',
        data: false,
        valid: true,
        empty: true,
        popup: true,
    }
}
// export const validation = {
//     fields: [],
//     list: [
//         {
//             comp: 'poBookingItems',
//             prop: 'grid',
//             errorText: 'Please enter quote items'
//         },
//         {
//             comp: 'upload',
//             prop: 'documents',
//             errorText: 'Please enter quote items'
//         }
//     ],
//     component: 'poBookingInvoice',
//     valid: false
// }