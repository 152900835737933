import * as config from '../../config'

import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'

import * as types from './types'
import * as requestTypes from '../Request/types'

export const setUserAutoCompleteMenuItems = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_USER_AUTOCOMPLETE_MENU_ITEMS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/user/search' + payload.query +'&contractactive=true',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setUserAutoCompleteMenuItems(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getVehicleListAutoCompleteMenuItems = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_DEFLEET_VEHICLE_LIST_AUTOCOMPLETE_MENU_ITEMS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search' + payload.query + '&contractactive=true',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setVehicleListAutoCompleteMenuItems(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search' + payload.query)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
        
export const getDefleetTypes = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_DEFLEET_TYPES),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/terminationreason/search?=terminationreason=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setDefleetTypes(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search' + payload.query)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
        
export const getSelectedVehicleDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SELECTED_VEHICLE_DETAILS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search?contractactive=true&vehicle_id=' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'customercontract.customercontractderivative,customer.customercontact.contact,maintenancetransaction.maintenancetransactionitem,mmcode,lastposition',

                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setSelectedVehicleDetails(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
 
export const getSelectedSupplierDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SELECTED_SUPPLIER_DETAILS),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplier/search' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'suppliercontact.contact,supplieraddress.address,supplierdocument.document,supplierage',
                    
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setSelectedSupplierDetails(result.response, payload.inx),
                    requestActions.requestSucceeded(config.system.api + '/components/supplier/search')
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplier/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

        export const setSupplierAutoCompleteMenuItems = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
            ofType(types.GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS),
            delay(0)
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/supplier/search' + payload.query,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    },
                })
                    .pipe(
            mergeMap((result) => [
                        actions.setSupplierAutoCompleteMenuItems(result.response, payload.id),
                        requestActions.requestSucceeded(config.system.api + '/components/supplier/search' + payload.query)
                    ])
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplier/search', error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            ),
            catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )

            export const getSupplierAge = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
            action$.pipe(
                    ofType(types.GET_SUPPLIER_AGE),
                    delay(0)
                    ,mergeMap(({ payload }) =>
                        ajax$({
                            url: config.system.api + '/components/supplierage/search' + payload.query,
                            method: 'GET',
                            headers: {
                                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                            },
                        })
                            .pipe(
            mergeMap((result) => [
                                actions.setSupplierAge(result.response),
                                requestActions.requestSucceeded(config.system.api + '/components/supplier/search')
                            ])
                            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplier/search', error)))
                            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                    )
                    ),
                    catchError((error) =>
                        of$(routerActions.route('/support', false, error))
                    )
                )
                           
export const getSelectedVehicleContract = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_SELECTED_VEHICLE_CONTRACT),
    delay(0)
    ,mergeMap(({ payload }) =>
    ajax$({
        url: config.system.api + '/components/contractaccessory/other',
        method: 'POST',
        headers: {
            'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
            'Content-Type': 'application/json'
        },
        body: payload
    })
            .pipe(
            mergeMap((result) => [
                actions.setSelectedVehicleContract(result.response),
                requestActions.requestSucceeded(config.system.api + '/components/vehicle/search')
            ])
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)