import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Loading from '../../Loading/component'

import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import Card from '../../../controls/card'

import * as actions from './actions'

class POBookingSendRebill extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }

    getLayout() {
        return  <div>
                <ViewVehicle key={this.data.step1.vehicle_id} id={this.data.step1.vehicle_id} />         
                <ViewSupplier key={this.data.step1.supplier_id} id={this.data.step1.supplier_id} />
                <ViewMaintenance key={this.data.step1.maintenancetransaction_id} id={this.data.step1.maintenancetransaction_id} />
                {/* <Card
                    title={'Authorisation Approval'}
                    subtitle={'Complete the form below to create booking'}
                    content={this.renderView()}
                /> */}
            </div>
    }

    render() {
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data.length
            ? this.props.workflow_queue_data.context_data.data.data.data[0]
            : this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        
        return this.data
            ?  this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingSendRebill
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingSendRebill)