import * as props from './props'
import * as types from './types'
import * as cognitoTypes from '../Cognito/types'
import * as gridTypes from '../Grid/types'
import * as formTypes from '../Form/types'

import * as cognitoActions from '../Cognito/actions'

export default () => {
    return (dispatch) => {
        dispatch(cognitoActions.refreshUserAuthenticationToken())
    }
}