import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../Icon/component'

import * as actions from '../Form/actions'
import * as styles from './styles'


String.prototype.splice = function (idx, rem, str) {
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};


class WorkflowQuoteViewComponent extends Component {
    // componentDidMount() {
    //     this.props.actions.loadComponent(this.props.name, this.props.id)
    // }


    getLookupView(data) {

        return <div><Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={6} style={styles.fieldNameRight}>{'Customer Name'}</Col>
                <Col xl={6} style={styles.selectedFieldName}>{this.props.state.customer.selected[0].original.customername}</Col>
            </Row>
            <Row>
                <Col xl={6} style={styles.fieldNameRight}>{'Contract Type'}</Col>
                <Col xl={6} style={styles.selectedFieldName}>{data[0].form.contracttype}</Col>
            </Row>
            <Row>
                <Col xl={6} style={styles.fieldNameRight}>{'Vehicle Selection'}</Col>
                <Col xl={6} style={styles.selectedFieldName}>{data[0].form.categorydescription}</Col>
            </Row>
            <Row>
                <Col xl={6} style={styles.fieldNameRight}>{'Contract Distance P/M'}</Col>
                <Col xl={6} style={styles.selectedFieldName}>{parseFloat(data[0].form.distancepermonth).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' KM'}</Col>
            </Row>
            <Row>
                <Col xl={6} style={styles.fieldNameRight}>{'Contract Period (Months)'}</Col>
                <Col xl={6} style={styles.selectedFieldName}>{data[0].form.contractperiod + ' Months'}</Col>
            </Row>
            <Row>
                <Col xl={6} style={styles.fieldNameRight}>{'Contract Distance'}</Col>
                <Col xl={6} style={styles.selectedFieldName}>{parseFloat((data[0].form.contractperiod) * parseInt(data[0].form.distancepermonth)).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' KM'}</Col>
            </Row>
            <Row>
                <Col xl={6} style={styles.fieldNameRight}>{'Replace Vehicle'}</Col>
                <Col xl={6} style={styles.selectedFieldName}>{
                    data[0].form.quotefor_id === '1'
                        ? 'Additional Vehicle' 
                        : data[0].form.replace_vehicle ? data[0].form.replace_vehicle : data[0].form.licenseplateno 
                }</Col>
            </Row>
            {
                (this.props.step === 'stp_fml_quote_capture_quote_details')
                    ? <div>
                        <Row>
                            <Col xl={12}><br /></Col>
                        </Row>
                        <Row>
                            <Col xl={12}><br /></Col>
                        </Row>
                        <Row style={styles.rowBorder}>
                            <Col xl={3} style={styles.fieldNameRight}>
                                {'Vehicle Model:'}
                            </Col>
                            {data[0].grid.map(x => {
                                return <Col xl={9 / data[0].grid.length} style={styles.selectedFieldName}>
                                    {x.mmcode_fktext}
                                </Col>
                            })}
                        </Row>
                        <Row style={styles.rowBorder}>
                            <Col xl={3} style={styles.fieldNameRight}>
                                {'Vehicle MM Code:'}
                            </Col>
                            {data[0].grid.map(x => {
                                return <Col xl={9 / data[0].grid.length} style={styles.numericFieldName}>
                                    {
                                        x.mm_code.length > 6
                                            ? x.mm_code.splice(3, 0, "-").splice(6, 0, "-")
                                            : x.mm_code
                                    }
                                </Col>
                            })}
                        </Row>
                        <Row style={styles.rowBorder}>
                            <Col xl={3} style={styles.fieldNameRight}>
                                {'Retail Price:'}
                            </Col>
                            {data[0].grid.map(x => {
                                return <Col xl={9 / data[0].grid.length} style={styles.numericFieldName}>
                                    {parseFloat(x.retailprice).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                            })}
                        </Row>
                        <Row style={styles.rowBorder}>
                            <Col xl={3} style={styles.fieldNameRight}>
                                {'Discount:'}
                            </Col>
                            {data[0].grid.map(x => {
                                return <Col xl={9 / data[0].grid.length} style={styles.numericFieldName}>
                                    {parseFloat(x.discount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                            })}
                        </Row>
                        <Row style={styles.rowBorder}>
                            <Col xl={3} style={styles.fieldNameRight}>
                                {'Vehicle Price:'}
                            </Col>
                            {data[0].grid.map(x => {
                                return <Col xl={9 / data[0].grid.length} style={styles.numericFieldName}>
                                    {parseFloat(x.vehicleprice).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                            })}
                        </Row>
                        <Row style={styles.rowBorder}>
                            <Col xl={3} style={styles.fieldNameRight}>
                                {'Residual %:'}
                            </Col>
                            {data[0].grid.map(x => {
                                return <Col xl={9 / data[0].grid.length} style={styles.numericFieldName}>
                                    {x.rvpercentage}
                                </Col>
                            })}
                        </Row>
                        <Row style={styles.rowBorder}>
                            <Col xl={3} style={styles.fieldNameRight}>
                                {'Residual Amount:'}
                            </Col>
                            {data[0].grid.map(x => {
                                return <Col xl={9 / data[0].grid.length} style={styles.numericFieldName}>
                                    {parseFloat(x.rv_amount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                            })}
                        </Row>
                        <Row style={styles.rowBorder}>
                            <Col xl={3} style={styles.fieldNameRight}>
                                {'Delivery Period:'}
                            </Col>
                            {data[0].grid.map(x => {
                                return <Col xl={9 / data[0].grid.length} style={styles.numericFieldName}>
                                    {x.deliveryperiod + ' Days'}
                                </Col>
                            })}
                        </Row>
                    </div>
                    : ''
            }
        </Container>
        </div>
    }

    getFieldValue(field, index) {
        if (typeof this.data[field.name].input === 'object') {
            return ''
        }

        const value = this.data[field.name].input

        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return value
            case 'datePicker':
                return moment(value).format('LLLL') + ', ' + moment(value).fromNow()
            case 'hidden':
                return
            case 'timePicker':
                return moment(value).format('LLLL') + ', ' + moment(value).fromNow()
            case 'textBox':
                return value
            case 'toggle':
                return value
            default:
                return
        }
    }

    getField(field, index) {
        return this.getFieldValue(field, index)
    }

    getSection(section, index) {
        const fields = section.component_field
            .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
            .filter(x => !x.related) //x.grid &&
            .map((x, i) => {
                return <Row
                    key={'row_' + section.name + '_' + x.name + '_' + i}
                    className={'interactiveRow'}
                >
                    <Col xl={4} style={styles.selectedFieldName}>
                        {x.title}
                        {/* <Icon iclass={x.icon.unicode} iname={x.icon.name} /> &nbsp;&nbsp;{x.title.substring(0, 15)} {x.title.length > 15 ? '...' : ''} */}
                    </Col>
                    <Col>
                        {
                            this.getField(x, i)
                        }
                    </Col>
                </Row>
            })

        return <Card
                title={section.title}
                subtitle={section.description}
                content={fields}
            />
    }

    getSectionView() {
        return this.data
            ? this.component.sections
                .sort((x, y) => x.order - y.order)
                .map((x, i) => {
                    return this.getSection(x, i)
                })
            : 'Loading...'
    }

    render() {
        this.component = this.props.state

        this.data = this.props.data
        this.data = this.props.data
        this.workflow = this.props.workflow
        return (
            this.props.data
                ? this.getLookupView(this.data)
                : this.getSectionView()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.components[ownProps.name],
            customer: {
                ...state.components['customer']
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowQuoteViewComponent)