import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Checkbox from '@mui/material/Checkbox'

import * as styles from './styles'
import * as actions from './actions'

import numeral from 'numeral'

class QueuedTaskCount extends Component {
    componentDidMount() {
        //this.props.actions.getQueuedTaskCount(this.props.team_id)
    }

    render() {
        return <div style={styles.alignRight}><Checkbox
        //checked={this.props.state.invoices.selectedall}
        label={'Hide'}
        
        onClick={(event) => {
            //var flag = this.props.state.invoices.selectedall ? false : true
            //this.props.actions.selectall(flag)
        }} /></div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.queuedTaskCount }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueuedTaskCount)