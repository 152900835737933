import React, { Component, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//import { Routes as Switch, Route, Navigate, Link as RouterLink  } from 'react-router-dom';
//import { Route, Switch } from 'react-router'import {
import {
    BrowserRouter,
    Routes,
    Route,
    Link as RouterLink,
    Navigate,
    useNavigate, 
} from "react-router-dom";

//import '../../import'
import * as config from "../../config";
import CssBaseline from '@mui/material/CssBaseline';

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import ExecutiveDashboard from "../Dashboard/ExecutiveDashboard/component";
import GeneralDashboard from "../Dashboard/GeneralDashboard/component";
import VisualDashboard from "../Dashboard/VisualDashboard/component";
import ReadComponent from "../ReadComponent/component";
import SearchComponent from "../SearchComponent/component";
import NavigationMenu from "../NavigationMenu/component";
import Support from "../Support/component";
import ProgressBar from "../ProgressBar/component";
import WorkflowQueueTasks from "../WorkflowQueueTasks/component";
import Calendar from "../CalendarControl/component";
import CalculatorContainer from "../CalculatorContainer/component";
import Tracker from "../Tracker/component";
import Import from "../Import/component";
import TeamManagement from "../TeamManagement/component";
import AdhocComponentTester from "../Custom/AdhocComponentTester/component";
import BI from '../Oqlis/component'
import Orginisation from "../Orginisation/component";
import Language from "../Language/component"
import History from '../History/component'
import Profile from '../Profile/component'
import CardManagement from '../CardManagement/component'
import BulkQuotation from '../BulkQuotation/component'
import Xero from '../Xero/component'
import SubAccount from '../SubAccount/component'
import ClientAccount from '../ClientAccount/component'
import LicenseeAccount from '../LicenseeAccount/component'
import Workqueue from '../Workqueue/component'
import Permission from '../Permission/component'
import Disposal from '../WorkflowQueueTasks/WorkflowDisposal/component'
import PaymentApprovalRelease from '../WorkflowPayment/PaymentApprovalRelease/component'
import PaymentApprovalAuthorisation from '../WorkflowPayment/PaymentApprovalAuthorisation/component'
import PaymentAllocation from '../WorkflowPayment/PaymentAllocation/component'
import JSReport from '../JSReport/embed'
import SelectionControl from '../../controls/SelectionControl'
import ReleaseNote from '../ReleaseNote/component'
import Sap from '../FinanceImports/component'
import Events from '../Events/component'
import CIS from '../CIS/component'
import CreateReport from '../CreateReport/component'
import ReportScheduler from '../ReportScheduler/component'
import BudgetManagement from '../BudgetManagement/component'
import BusinessStructure from '../BusinessStructure/component'
import Reseller from '../Reseller/component'
import ENatis from '../eNatis/component'
import FAQ from '../FAQ/component'
import KnowledgeBase from '../KnowledgeBase/component'
import TeamManager from '../TeamManagement/TeamManager/component'

import { Auth } from 'aws-amplify';
import Leaflet from '../Leaflet/component'

import Dashboard from '../DashboardView/component'
import Report from '../ReportView/component'

import WorkflowTaskView from "../WorkflowTaskView/component";

import { Container, Row, Col } from "react-grid-system";
import AppBar from "@mui/material/AppBar";
import { Drawer } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars";

import Icon from "../Icon/component";

import * as historyActions from "../History/actions";
import * as navigationMenuActions from "../NavigationMenu/actions";
import * as appActions from "../App/actions";
import * as signedInActions from "./actions";
import * as redirectActions from "../Redirect/actions";
import * as businessCustomerActions from "../BusinessCustomers/actions";
import * as accountActions from '../Account/actions'
import * as workflowQueueTasksActions from '../WorkflowQueueTasks/actions'
import * as cognitoActions from '../Cognito/actions'
import * as releaseNoteActions from '../ReleaseNote/actions'
import * as BusinessIntelligenceActions from '../BusinessIntelligence/actions'
import TestComp from '../Test/component'

import AppProfile from "./profile"
import AppNotification from "./notifications"
import MegaMenu from './MegaMenu'

import { List, ListItem, TextField } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from '@mui/material/CardActions';
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { MenuList, Container as Div } from '@mui/material';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import HelpIcon from '@mui/icons-material/Help';
import GroupIcon from '@mui/icons-material/Group';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LanguageIcon from '@mui/icons-material/Language';
import BusinessIcon from '@mui/icons-material/Business';
import HistoryIcon from '@mui/icons-material/History';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandOrganisationLess from '@mui/icons-material/ExpandLess';
import ExpandOrganisationMore from '@mui/icons-material/ExpandMore';
import ExpandLanguageLess from '@mui/icons-material/ExpandLess';
import ExpandLanguageMore from '@mui/icons-material/ExpandMore';
import ExpandNotificationsMore from '@mui/icons-material/ExpandMore';
import ExpandNotificationsLess from '@mui/icons-material/ExpandLess';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import BellIcon from 'react-bell-icon';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {ReactComponent as TroubleshootIcon} from '../../assets/img/svg/icons/operation.svg';
import { ReactComponent as HomeIconCustom} from '../../assets/img/svg/icons/home.svg'



import Redirect from '../Redirect/component'
import Account from '../Account/component'


import InputBase from '@mui/material/InputBase';
import { styled, useTheme, alpha  } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

//import { Routes as Switch, Route, Navigate, Link as RouterLink  } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import * as styles from "./styles";
import { center } from "../WorkflowSTRQuote/WorkflowCreateQuote/styles";
import moment from 'moment';
import PublishNote from '../ReleaseNote/publishNote'

import Hierarchy from '../Hierarchy/component';
import HierarchyTeam from '../HierarchyTeam/component';
import HierarchyStaff from '../HierarchyStaff/component';

import CustomErrorBoundary from '../CustomErrorBoundary/component';
// import Typography from "@mui/material/Typography";
// import { styled  } from '@mui/material/styles';
// import { ReactComponent as HomeIconCustom} from '../../assets/img/svg/icons/home.svg'
// import { Link as RouterLink  } from 'react-router-dom';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// const CustomTextTypography = styled(Typography)(({ theme }) => ({
//     color: '#131B3A'
//   }));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginRight: '0px',
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - 260px)`
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - 260px)`,
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - 260px)`,
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - 260px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        },
    }),
    padding: '0px',
    backgroundColor: '#F3F3F3',
}));

const Content = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(() => ({
    backgroundColor: '#F3F3F3',
    padding: '0px',
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));


  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#5C6670 !important',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  const CustomTextTypography = styled(Typography)(({ theme }) => ({
    color: '#131B3A'
  }));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (

                <Typography>{children}</Typography>

            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

// const iconButtonElement = (
//     <IconButton
//         touch={true}
//         tooltip="more"
//         tooltipPosition="bottom-left"
//     >
//         <MoreVertIcon />
//     </IconButton>
// );


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    height: '100vh'
  }))


// const rightIconMenu = (
//     <Menu iconButtonElement={iconButtonElement}>
//         <MenuItem>Delete Notification</MenuItem>
//     </Menu>
// )



const SignedIn = (props) => {    
    let navigate = useNavigate();
    const theme = useTheme();
   // const { headerSearchHover, setHeaderSearchHover } = props;

    // const toggleHeaderSearchHover = () => {
    //   //setHeaderSearchHover(false);
    // }
    // this.state = {
    //     anchorEl: null,
    //     selectedIndex: 1,
    // }

    // handleClose = () => {
    //     this.setState({ anchorEl: null });
    // };

    // handleClick = event => {
    //     this.setState({ anchorEl: event.currentTarget });
    // };

    // componentDidMount() {
    //     //props.actions.getNavigationMenu();
    //     //props.actions.initUserNotifications(); //todo init notify
    //     props.actions.loadIntelligence('', '', '', '', 'reports')
    //     props.actions.loadIntelligence('', '', '', '', 'dashboard')
    //     props.actions.getWorkqueueList();
    //     props.actions.getReleaseNote()
    // }

    // componentDidCatch(error, info) {
    //     //props.actions.logErrorToRaygun(error);

    //     props.actions.route("/support", undefined, {
    //         message: error,
    //         info: info,
    //     });
    // }



    const getAppLogo = () => {
        // const { anchorEl } = this.state;

        return <AppBar position="static" style={{borderBottom: '1px solid white', backgroundColor: 'white'}}>
            <Toolbar>
                    <center>
                        <img
                            style={styles.logoSize}
                            // src={`${config.system.cdn}/${config.uri.header}`}
                            src={config.system.clientTheme !== 'bidvest' ? `${config.system.cdn}/${config.uri.header}` : require(`../../assets/img/${config.uri.header}`)}
                            onClick={(event, value) => {
                                event.preventDefault();
                                //console.log('defaultlandingpage',props.state.user.defaultlandingpage)
                                let defaultlandingpage = props.state.user.defaultlandingpage ? props.state.user.defaultlandingpage : 'tasks'
                                props.actions.route('/' + defaultlandingpage)
                            }}
                        />
                    </center>
                    {/* <div style={{ marginLeft: 'auto' }}>
                        <div className="app-header--pane">
                            <MenuIcon color="light" style={{textAlign: "right"}} />
                        </div>
                    </div> */}
            </Toolbar>
        </AppBar>
    }

    const getSearch = () => {
        return  <><Search style={{color: '#5C6670 !important'}}>
            <SearchIconWrapper style={{marginLeft: 20}}>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            style={{width: '300px', height: '40px', backgroundColor: ' #F8F9FB',color: '#5C6670 !important', borderRadius: 15, marginLeft: 20}}
            />
        </Search>
        <Box sx={{ flexGrow: 1 }} />
      </> 
    }

    const getMenuItemHeader = () => {
        //console.log('>>>>>>>>>>>>>>>>>>> this.props.state.workflowx <<<<<<<<<<<<<<<<<<<')
        //console.log('this.props.state.workflowx workflow', props.state.workflow)
        //console.log('this.props.state.workflowx nav', props.state.nav)
        //console.log('this.props.state.workflowx path', props.state.redirect.path)
        //console.log('this.props.state.workflowx redirect', props.state.redirect.path.indexOf('/workflow/') == -1)

        //console.log('this.props.state.workflowx header', props.state.redirect.path.indexOf('/view/') !== -1 )
        //console.log('this.props.state.workflowx header', props.state.redirect.path.split('/').length )
        //console.log('this.props.state.workflowx header /view/', props.state.redirect.path.split('/')[2])
        return <>
            <Box sx={{marginTop: 2, marginBottom: 2}}>
                <Stack spacing={0}>
                    <CustomTextTypography  sx={{fontSize: '24px', fontWeight: 700, lineHeight: '24px', marginLeft: '5px' }}>
                        {
                            props.state.redirect.path.indexOf('/workflow/') == -1 
                            ? props.state.nav.selectedItemMainTitle ? props.state.nav.selectedItemMainTitle : props.state.redirect.path.replace('/','') == 'bi'
                                ? 'Business Intelligence' :  props.state.redirect.path.replace('/','') == 'tasks' 
                                ? 'Workflow and Task' : props.state.redirect.path.replace('/','')
                            : props.state.workflow.input.task.title ? props.state.workflow.input.task.title : props.state.workflow.approval.task.title
                        }
                    </CustomTextTypography>
                    {/* {props.state.nav ? props.state.nav.selectedItemMainDesc ? props.state.nav.selectedItemMainDesc : 'Workflow and Tasks List' : 'Workflow and Tasks List'}
                     */}
                   
                <Breadcrumbs separator="/" aria-label="breadcrumb" style={{paddingTop: '10px'}}>
                        <RouterLink underline="hover" color="inherit" onClick={() => {
                                props.actions.route('/' + (props.state.su.defaultlandingpage ? props.state.su.defaultlandingpage : 'tasks'))
                              }}>
                         
                        <CustomTextTypography sx={{fontSize: '12px', fontWeight: 700, lineHeight: '24px', marginLeft: 1}}>
                            <HomeIconCustom style={{width: '16px', height: '16px', marginRight: 5, marginBottom: 5}} />Home
                            </CustomTextTypography>
                        </RouterLink>
                        {
                            props.state.redirect.path.indexOf('/workflow/') !== -1 
                            ? <RouterLink underline="hover" color="inherit" onClick={() => {
                                props.actions.route('/tasks')
                              }}>
                                <CustomTextTypography sx={{fontSize: '12px', fontWeight: 300, lineHeight: '24px'}}>
                                    {props.state.nav ? props.state.nav.selectedItemMainTitle ? props.state.nav.selectedItemMainTitle : 'Workflow and Tasks' : 'Workflow and Tasks'}
                                </CustomTextTypography>
                            </RouterLink>
                            :  props.state.redirect.path.indexOf('/view/') !== -1 && props.state.redirect.path.split('/').length == 4
                                ? <RouterLink underline="hover" color="inherit" onClick={() => {
                                    props.actions.route('/search/' + props.state.redirect.path.split('/')[2])
                                }}><CustomTextTypography sx={{fontSize: '12px', fontWeight: 300, lineHeight: '24px'}}>
                                    {props.state.nav ? props.state.nav.selectedItemMainTitle ? props.state.nav.selectedItemMainTitle : 'Workflow and Tasks' : 'Workflow and Tasks'}
                                </CustomTextTypography> </RouterLink>
                                : props.state.redirect.path.indexOf('/report') !== -1 || props.state.redirect.path.indexOf('/dashboard') !== -1 
                                ? <RouterLink underline="hover" color="inherit" onClick={() => {
                                    props.actions.resetReportDashboard()
                                }}><CustomTextTypography sx={{fontSize: '12px', fontWeight: 300, lineHeight: '24px'}}>
                                    {   props.state.nav 
                                            ? props.state.nav.selectedItemMainTitle 
                                                ? props.state.nav.selectedItemMainTitle 
                                                : props.state.redirect.path.replace('/','')
                                            : 'Home'
                                    }
                                </CustomTextTypography></RouterLink>
                                : <CustomTextTypography sx={{fontSize: '12px', fontWeight: 300, lineHeight: '24px'}}>
                                    {   props.state.nav 
                                            ? props.state.nav.selectedItemMainTitle 
                                                ? props.state.nav.selectedItemMainTitle 
                                                : props.state.redirect.path.replace('/','')
                                            : 'Home'
                                    }
                                </CustomTextTypography>
                        }
                        {
                            props.state.redirect.path.indexOf('/workflow/') != -1 
                                ? <CustomTextTypography sx={{fontSize: '12px', fontWeight: 300, lineHeight: '24px'}}>
                                        {props.state.workflow.input.task.title ? props.state.workflow.input.task.title : props.state.workflow.approval.task.title}
                                    </CustomTextTypography>
                                :  props.state.redirect.path.indexOf('/view/') !== -1 && props.state.redirect.path.split('/').length == 4
                                    ? <CustomTextTypography sx={{fontSize: '12px', fontWeight: 300, lineHeight: '24px'}}>
                                        {props.state.redirect.path.split('/')[1]}
                                    </CustomTextTypography>
                                    : props.state.redirect.path.indexOf('/report') !== -1 || props.state.redirect.path.indexOf('/dashboard') !== -1 
                                        ? <CustomTextTypography sx={{fontSize: '12px', fontWeight: 300, lineHeight: '24px'}}>{'Report View'}</CustomTextTypography>
                                        : ''
                        }
                    </Breadcrumbs> 
                    
                </Stack>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
        </>
    }

    const getAppBar = () => {
        return <AppBar position="relative" color="light" style={{ backgroundColor: '#fff', boxShadow: 'none'}}>
            <Toolbar >
                
                <div >
                <div  className="app-header--pane icon-color">
                        {getMenuItemHeader()}
                    </div>
                </div>
                <div>
                    <div className="app-header--pane">
                    </div>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                    <div className="app-header--pane">
                        {getSearch()}
                        <AppNotification />
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <AppProfile />
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    }
    
    const tabPanelLayout = () => {
        return <div style={{height: '500px !important', backgroundColor: '#131B3A', overflow: 'auto !important'}}>
            {/* {getAppLogo()} */}
            {/* {getTabs()} */}
            <TabPanel value={props.state.tabValue} index={0}>
                <NavigationMenu />
            </TabPanel>
            <TabPanel value={props.state.tabValue} index={1}>
                <History />
            </TabPanel>
        </div>

    }

    const getTabs = () => {
        return <Tabs
            className="bg-asteroid"
            value={props.state.tabValue}
            onChange={props.actions.handleTabChange}
            indicatorColor="primary"
            hoverColor="primary"
            variant="scrollable"
            scrollButtons="off"
            textColor="primary"
            aria-label="icon tabs example"
        >
            <Tab style={{ minWidth: "50%" }} icon={<MenuIcon color="light" />} aria-label="menu"  {...a11yProps(0)} />
            <Tab style={{ minWidth: "50%", marginLeft: "-0.5rem" }} icon={<StarBorderIcon color="light" />} aria-label="history" {...a11yProps(1)} />
            {/* <Tab style={{ minWidth: "50%", marginLeft: "-0.5rem" }} icon={<HistoryIcon color="light" />} aria-label="history" {...a11yProps(1)} /> */}
            {/* <Tab style={{ minWidth: "25%" }} icon={<PersonIcon color="secondary" />} aria-label="user_profile" {...a11yProps(2)} /> */}
        </Tabs>
    }

    const simpleBreadcrumbs = () => {
        return <div style={{margin: '10px 0px'}}>
            <Breadcrumbs separator="|" aria-label="Breadcrumb">
                <RouterLink color="inherit" to="/bi" style={styles.link}>
                    <HomeIcon style={styles.icon} color="primary" />
                    Home
                </RouterLink>
                {/* <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="Home"
                    icon={<HomeIcon fontSize="small" />}
                    onClick={(event, value) => {
                        event.preventDefault();
                        props.actions.route('/bi')
                    }}
                /> */}
                {/* {pathnames.map((value, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    return last ? (RouterLink
                        <Typography color="textPrimary" key={to} style={styles.link}>
                            {value}
                        </Typography>
                    ) : (
                            <RouterLink color="inherit" to={to} key={to}>
                                {value}
                            </RouterLink>
                        );
                })} */}
            </Breadcrumbs>
        </div>
    }

    const getContentPanel = () => {
            return <div style={{maxHeight: '500px !important'}}>
                {/* <br/> */}
                {/* <Breadcrumbs separator="/" aria-label="breadcrumb">
                        <RouterLink underline="hover" color="inherit" to="/bi">
                         
                        <CustomTextTypography sx={{fontSize: '12px', fontWeight: 700, lineHeight: '24px', marginLeft: 1}}>
                            <HomeIconCustom style={{width: '16px', height: '16px', marginRight: 5, marginBottom: 5}} />Home
                            </CustomTextTypography>
                        </RouterLink>
                        <CustomTextTypography sx={{fontSize: '12px', fontWeight: 300, lineHeight: '24px'}}>
                            {props.state.nav ? props.state.nav.selectedItemMainTitle ? props.state.nav.selectedItemMainTitle : 'Workflow and Tasks' : 'Workflow and Tasks'}
                        </CustomTextTypography>
                    </Breadcrumbs> 
                    <br/> */}
                <Redirect navigate={navigate} />
                <CustomErrorBoundary>
                <Routes>
                    <Route path="*" element={<Navigate to="/tasks" />} />
                    <Route path='/account' element={<Account />} />
                    <Route path='/faq' element={<FAQ />} />
                    <Route path="/management" element={<TeamManagement />} />
                    <Route path="/dashboard/executive" element={<ExecutiveDashboard />} />
                    <Route path="/dashboard/general" element={<GeneralDashboard />} />
                    <Route path="/dashboard/visual" element={<VisualDashboard />} />
                    <Route path="/tasks" element={<WorkflowQueueTasks />} />
                    <Route path="/search/:component" element={<SearchComponent />} />
                    <Route path="/view/:component" element={<ReadComponent />} />
                    <Route path="/view/:component/:id" element={<ReadComponent />} />
                    {/* <Route path="/view/customerderivative/:id" element={<ReadComponent />} /> */}
                    <Route path="/workflow/:workflow/:task/input" element={<WorkflowTaskView  match={props.state.redirect} />} />
                    <Route path="/workflow/:workflow/:task/action" element={<WorkflowTaskView  match={props.state.redirect} />} />
                    <Route path="/workflow/:workflow/:task/:team_id/:user_id/approval" element={<WorkflowTaskView match={props.state.redirect} />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/report" element={<Report />} />
                    <Route path="/map" element={<Tracker />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/calculator" element={<CalculatorContainer />} />
                    <Route path="/tester" element={<AdhocComponentTester />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/import" element={<Import />} />
                    <Route path="/orginisation" element={<Orginisation />} />
                    <Route path="/language" element={<Language />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/workqueue/:item" element={<Workqueue />} />
                    <Route path="/jsreport" element={<JSReport />} />
                    <Route path="/bulkquotation" element={<BulkQuotation />} />
                    <Route path="/cardmanagement" element={<CardManagement />} />
                    <Route path="/leaflet" element={<Leaflet />} />
                    <Route path="/disposal" element={<Disposal />} />
                    <Route path="/financeImport" element={<Sap />} />
                    <Route path="/cis" element={<CIS />} />
                    <Route path="/paymentrelease" element={<PaymentApprovalRelease />} />
                    <Route path="/paymentauthorisation" element={<PaymentApprovalAuthorisation />} />
                    <Route path="/paymentallocation" element={<PaymentAllocation />} />
                    <Route path="/LicenseeAccount" element={<LicenseeAccount />} />
                    <Route path="/SubAccount" element={<SubAccount />} />
                    <Route path="/ClientAccount" element={<ClientAccount />} />
                    <Route path="/xero" element={<Xero />} />
                    <Route path='/releasenote' element={<ReleaseNote />}/>
                    <Route path='/create_report' element={<CreateReport />}/>
                    <Route path='/testcomponent' element={<TestComp />}/>
                    <Route path='/events' element={<Events />}/>
                    <Route path='/schedule_report' element={<ReportScheduler />}/>
                    <Route path='/budgetmanagement' element={<BudgetManagement />}/>
                    <Route path='/businessstructure' element={<BusinessStructure />}/>
                    <Route path='/reseller' element={<Reseller />}/>
                    <Route path='/teammanager' element={<TeamManager />}/>
                    <Route path="/hierarchy" element={<Hierarchy />} />
                    <Route path="/hierarchyteam" element={<HierarchyTeam />} />
                    <Route path="/hierarchystaff" element={<HierarchyStaff />} />
                    <Route path="/enatis" element={<ENatis />} />
                    <Route path="/knowledgebase" element={<KnowledgeBase />} />
                    <Route path='/permission' element={<Permission />} title={''} />
                    <Route path="/bi"  element={<BI  displayType={'dashboard'}  oq_key={props.state.user.defaultdashboardkey} oq_theme={"AuthTheme"}  height={"850px"} />}/>
                    </Routes>
                    </CustomErrorBoundary>
                <ProgressBar />

                <div style={styles.versioning}>
                    UI v {config.system.version} &nbsp;&nbsp;&nbsp;&nbsp; API v {props.state.version ? props.state.version : "?"}
                </div>

                {/* //{this.getPopupMenus()} */}
            </div>
    }

    const getLayout = () => {
        //console.log('realeasex read ', props.state.user.readNote)
        //return <Box sx={{ flexGrow: 1 }}>
        return <Box sx={{ display: 'flex', maxWidth: '100vw' }}>
            <Grid container>
                {/* <Grid xs={12}>
                    <Item>{getAppBar()}</Item>
                </Grid> */}
                {/* <div style={{ borderBottom: '1px #253053 solid', padding: '0px', width: '100%'}}> */}
                
                <Grid container >
                    <Grid xs={12} style={{width: '100% !important', display: 'flex' }}>
                        <Item style={{ width: '300px !important', backgroundColor: '#131B3A'}} className="DrawerGlobal">
                            <div style={{overflow: 'auto', width: '300px !important', padding: '0px', height: '100%'}}>
                                {tabPanelLayout()}
                            </div>
                        </Item>
                        <Item style={{ width: '100%', height: '100vh', overflow:'auto',position: 'relative' }}>
                        <div style={{ padding: '0px', width: '100%'}}>
                        {props.state.user.readNote == false ? <PublishNote/>:''}
                    {getAppBar()}
                </div>
                
                    <div style={{overflow: 'auto', padding: '0px', height: '100vh !important', marginBottom: 10}}>
                                {getContentPanel()}
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
            </Grid>
          {/* <Grid xs="auto" style={{backgroundColor: '#131B3A', boxShadow: '10px 18px 20px #FFFFFF, -18px -15px 30px #D1D9E6' }}>
            <Item>
                <div style={{overflow: 'auto', padding: '0px', height: '100%'}}>
                    {tabPanelLayout()}
                </div>
            </Item>
          </Grid> */}
      </Box>
    }

    const getPermissionLayout = () => {
        return <div className="{classes.root}">
            <div style={styles.body}>
                <br />
                <Routes>
                    <Route path='*' element={<Permission />} title={''} />
                    <Route path='/permission' element={<Permission />} title={''} />
                </Routes>
            </div>
        </div>
    }

    const setBackground = (
        document.body.className = 'logged_out'
    )

    let user_team = props.state.user.user_team ? props.state.user.user_team : null
    return (
        user_team 
        ? user_team.length
            ? getLayout()
            : getPermissionLayout()
        : 'Retrieving your account information, please wait...'
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.app,
            ...state.connector,
            ...state.signedIn,
            accounts: {
                ...state.accounts,
            },
            workflow: {
                input: { ...state.workflowTaskInput },
                approval: { ...state.workflowTaskApproval },
            },
            user: {
                ...state.user,
            },
            nav: {
                ...state.navigationMenu
            },
            ...state.cognito,
            releaseNote : {
                ...state.releaseNote
            },
            su : {
                ...state.user
            },
            redirect : {
                ...state.redirect
            }

        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setResponsive: (isResponsive) => {
        //     dispatch(setResponsive(isResponsive))
        // },
        actions: bindActionCreators(
            {
                ...navigationMenuActions,
                ...historyActions,
                ...appActions,
                ...signedInActions,
                ...redirectActions,
                ...businessCustomerActions,
                ...accountActions,
                ...workflowQueueTasksActions,
                ...cognitoActions,
                ...releaseNoteActions,
                ...BusinessIntelligenceActions
            },
            dispatch
        ),
    };
};

//export default connect(mapStateToProps, mapDispatchToProps)(styled(styles.useStyles)(SignedIn));

export default connect(mapStateToProps, mapDispatchToProps)(SignedIn);
