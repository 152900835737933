import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const icon = {
    color: colours.red[500]
}

export const related = {
    //fontSize: '10px'
}

export const right = {
    float: 'right'
}

export const toggle = {
    width: '100%'
}

export const textBox = {
    width: '100%'
}


export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: 350,
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '80%'
}

export const headerButton = {
    width: 250
}

export const row = {
    fontSize: 16,
    //backgroundColor: '#dcd6d7',
    //opacity: '0.5'
}