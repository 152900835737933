import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'
import { TextField, Button, IconButton, Dialog, Tooltip } from '@mui/material'

import ReactTable from "react-table"
import "react-table/react-table.css"

import AutoComplete from '../../controls/autocomplete'
import Card from '../../controls/card'
import Icon from '../Icon/component'
import Loading from '../Loading/component'
import * as gridAction from '../Grid/actions'
import * as redirectActions from "../Redirect/actions";
import * as readActions from "../ReadComponent/actions";
import * as compActions from '../Components/actions'

import * as actions from './actions'
import * as styles from './styles'

class WorkqueueGrid extends Component {
    componentDidMount() {
        //this.props.actions.loadSubAccountData()
    }

    getCommands(row) {
        let workqueue_code = this.props.state.workqueue.workqueue_field_actions.map(x => x.workqueue_actions.workqueue_code)
        let field = this.props.state.workqueue.component.component_field.filter(x => x.key === true)[0]
        const id = row.original[field.name]
        let i = row.index
        let commands = []

        ////console.log('workqueue_code', workqueue_code)

        if(workqueue_code.includes('workflow'))
        commands.push(
            <Tooltip arrow title="Action Event With Workflow" placement="top">
            <IconButton key={'command_workflow_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
                tooltip={'Vehicle details'} color="primary"
                onClick={() => {
                    let workflow = this.props.state.workqueue.workqueue_field_actions.filter(x => x.workflow)
                    workflow = workflow.length ? workflow[0].workflow : {}
                    //console.log('workflow',workflow)
                    
                    this.props.actions.setPropData('record_id',id)
                    this.props.actions.setPropData('record_field',field)
                    this.props.actions.updateAck('Action by Workflow', this.props.state.workqueue.component.name,field.name, id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id)
                    
                    this.props.actions.route('/workflow/'+workflow.name+'/'+workflow.workflow_trigger[0].workflow_task.name+'/input')
                }}>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'directions_run'} />
            </IconButton>
            </Tooltip>
        )

        //if(this.props.state.workqueue.workqueue_action === 'ack_no_input')
        if(workqueue_code.includes('ack_no_input'))
        commands.push(
            <Tooltip arrow title="Acknowledge Event" placement="top">
            <IconButton key={'command_ack_no_input_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => {
                    //console.log('this.props.state.workqueue',this.props.state.workqueue)
                    this.props.actions.setPropData('record_id',id)
                    this.props.actions.setPropData('record_field',field)
                    this.props.actions.updateAck('',this.props.state.workqueue.component.name,field.name, id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id)
                }
            }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'visibility_off'} />
            </IconButton></Tooltip>
        )
        
       // if(this.props.state.workqueue.workqueue_action === 'ack_input')
        if(workqueue_code.includes('ack_input'))
        commands.push(
            <Tooltip arrow title="Acknowledge With Comments" placement="top">
            <IconButton key={'command_ack_input_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => { 
                //console.log('this.props.state.workqueue',this.props.state.workqueue)
                this.props.actions.setPropData('record_id',id)
                this.props.actions.setPropData('record_field',field)
                this.props.actions.setPropData('win_ack_input',true)
            }}>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'visibility'} />
            </IconButton></Tooltip>
        )
        
        //if(this.props.state.workqueue.workqueue_action === 'escalate')
        if(workqueue_code.includes('escalate'))
        commands.push(
            <Tooltip arrow title="Escalate Event" placement="top">
            <IconButton key={'command_escalate_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => { 
                //console.log('this.props.state.workqueue',this.props.state.workqueue)
                this.props.actions.setPropData('record_id',id)
                this.props.actions.setPropData('record_field',field)
                this.props.actions.setPropData('win_escalate',true)
            }}>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'warning'} />
            </IconButton></Tooltip>
        )
        
        //if(this.props.state.workqueue.workqueue_action === 'actions')
        if(workqueue_code.includes('actions'))
        commands.push(
            <Tooltip arrow title="Action Event" placement="top">
            <IconButton key={'command_actions_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => { 
                //console.log('this.props.state.workqueue',this.props.state.workqueue)
                this.props.actions.setPropData('record_id',id)
                this.props.actions.setPropData('record_field',field)
                this.props.actions.updateAck('Action By Update',this.props.state.workqueue.component.name,field.name, id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id)
                this.props.actions.setReadComponentView('form','', this.props.state.workqueue.component.name)
                this.props.actions.route(
                    '/view/'+this.props.state.workqueue.component.name+'/' + id
                )}
            }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'edit'} />
            </IconButton></Tooltip>
        )
        
        //if(this.props.state.workqueue.workqueue_action === 'cancel_input')
        if(workqueue_code.includes('cancel_input'))
        commands.push(
            <Tooltip arrow title="Cancel Event With Comments" placement="top">
            <IconButton key={'command_workqueue_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => { 
                //console.log('this.props.state.workqueue',this.props.state.workqueue)
                this.props.actions.setPropData('record_id',id)
                this.props.actions.setPropData('record_field',field)
                this.props.actions.updateAck('',this.props.state.workqueue.component.name,field.name, id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id)
                this.props.actions.setPropData('win_ack_input',true)
                //this.props.actions.setReadComponentView('form','', this.props.state.workqueue.component.name)
                //this.props.actions.route(
                //    '/view/'+this.props.state.workqueue.component.name+'/' + id
            }}>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'clear_all'} />
            </IconButton></Tooltip>
        )

       // if(this.props.state.workqueue.workqueue_action === 'cancel_no_input')
        if(workqueue_code.includes('cancel_no_input'))
        commands.push(
            <Tooltip arrow title="Cancel Event" placement="top">
            <IconButton key={'command_workqueue_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => { 
                //console.log('this.props.state.workqueue',this.props.state.workqueue)
                this.props.actions.setPropData('record_id',id)
                this.props.actions.setPropData('record_field',field)
                this.props.actions.updateAck('',this.props.state.workqueue.component.name,field.name, id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id)
                //this.props.actions.setReadComponentView('form','', this.props.state.workqueue.component.name)
                //this.props.actions.route(
                //    '/view/'+this.props.state.workqueue.component.name+'/' + id
                //)}
            }}>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'cancel'} />
            </IconButton></Tooltip>
        )
        
        //if(this.props.state.workqueue.workqueue_action === 'email')
        //if(workqueue_code.includes('email'))
        commands.push(
            <Tooltip arrow title="Send Email Notification" placement="top">
            <IconButton key={'command_workqueue_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => { 
                //console.log('this.props.state.workqueue',this.props.state.workqueue)
                this.props.actions.setPropData('record_id',id)
                this.props.actions.setPropData('record_field',field)
                this.props.actions.setPropData('win_email',true)
            }}>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'send'} />
            </IconButton></Tooltip>
        )
        
        //if(this.props.state.workqueue.workqueue_action === 're_assigning')
        if(workqueue_code.includes('re_assigning'))
        commands.push(
            <Tooltip arrow title="Re Assign Event" placement="top">
            <IconButton key={'command_workqueue_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => { 
                //console.log('this.props.state.workqueue',this.props.state.workqueue)
                this.props.actions.setPropData('record_id',id)
                this.props.actions.setPropData('record_field',field)
                this.props.actions.setPropData('win_re_assigning',true)
            }}>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'person_pin'} />
            </IconButton></Tooltip>
        )
        
        //if(this.props.state.workqueue.workqueue_action === 'send_message')
        if(workqueue_code.includes('send_message'))
        commands.push(
            <Tooltip arrow title="Send Message" placement="top">
            <IconButton key={'command_workqueue_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => { 
                //console.log('this.props.state.workqueue',this.props.state.workqueue)
                this.props.actions.setPropData('record_id',id)
                this.props.actions.setPropData('record_field',field)
                this.props.actions.setPropData('win_send_message',true)
            }}>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'message'} />
            </IconButton></Tooltip>
        )
        return commands
    }

    getValue(row) {   
        let field = this.props.state.workqueue.component.component_field.filter(x => x.name === row.column.meta)[0] 
        if(field.related && field.display) 
        {
            //console.log('getValuex field', field)
            //console.log('getValuex original', row.original)
            //console.log('getValuex related', field.related)
            //console.log('getValuex display', field.display)
        }

        var value =  field.related && field.display ? (row.original[field.display.split('.')[0]] ? row.original[field.display.split('.')[0]][field.display.split('.')[1]] : '') : row.original[field.name]
        if(field.related && field.display) {
            //console.log('getValuex value', value)
        }
            switch (field.component_field_data_type.name) {
                case 'month':
                    return moment(value).format('MM')
                case 'integer':
                    return value ? value : 0 
                case 'date':
                    return value ? moment(value).format('YYYY-MM-DD') : 'No date'
                case 'dateTime':
                    return value ? moment(value).format('YYYY-MM-DD HH:mm ZZ') : 'No date'
                case 'time':
                    return value ? moment(value).format('HH:mm ZZ') : 'No time'
                case 'timestamp':
                    return value ? moment(value).format('YYYY-MM-DD HH:mm ZZ') : 'No time'
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value

                    return value !== null
                        ? value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(value)
                        ? Number(value).toFixed(2).toString()
                        : value
                default:
                    return value
            }
    }

    getFormFieldLabel(row) {
          return <div
          style={{
            width: '100%',
            marginTop: '3px',
            verticalAlign: 'baseline',
            fontSize: '12px'
        }} >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.workqueue.component.component_field.filter(x => x.grid === true)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.name,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                   // width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 10,
                        letterSpacing: 0,
                        color: 'black',
                        textAlign:  'left',
                        boxSizing:' border-box',
                        position: 'relative',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        width: '100%',
                        scrollX: 'scroll'
                    },
                    Cell: (row) => (
                          this.getFormFieldLabel(row)
                    )
                }
            })

            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 120,
                width: 120,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        return columns
    }

    getGrid() {
        return <div>
            <ReactTable
                data={this.props.state.formdata.workqueue && this.props.state.grid_filter ? this.props.state.formdata.workqueue[this.props.state.grid_filter] : []}
                columns={this.getColumns()}
                manual
                loading={false}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
                onFetchData={
                    (state) => {
                        //this.props.actions.getComponentGridData(this.props.state.client, this.props.state.client.link, null, this.props.state.client.filter, this.props.state.client.id)
                    }
                }
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
            
        </div>
    }

    getAckInput() {
        return  <Dialog
                open={this.props.state.win_ack_input}
                style={{ width: '100% !important', maxWidth: '100% !important' }}
                
                maxWidth="xl">
                <Card title={window.t.en('Acknowledge Event')} 
                subtitle={<div>{'Acknowledge event by entering message'}</div>}
                content={<div className="text-center- p-1">
                    <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                Enter Comments
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth={true}
                                    id={'win_ack_input_input'}
                                    hintText={'Enter Comments'}
                                    value={this.props.state.win_ack_input_input}
                                    onChange={(event, value) => {
                                        this.props.actions.setPropData('win_ack_input_input',event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <hr />
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.setPropData('win_ack_input',false)
                        }}
                        className=" btn-pill mx-1">
                        <span className="btn-wrapper--label">Close</span>
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.updateAck('',this.props.state.workqueue.component.name,this.props.state.record_field.name, this.props.state.record_id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id,this.props.state.win_ack_input_input)
                            this.props.actions.setPropData('win_ack_input',false)
                        }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Acknowledge and Close</span>
                    </Button>
                    </div>
                </div>} />
            </Dialog>
                
    }

    getWinEmail() {
        return  <Dialog
                open={this.props.state.win_email}
                style={{ width: '100% !important', maxWidth: '100% !important' }}
                
                maxWidth="lg">
                <Card title={window.t.en('Send Email')} 
                subtitle={<div>{'Send email to notify selected user'}</div>}
                content={<div className="text-center- p-1">
                    <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                Select User
                            </Col>
                            <Col xl={12}>
                               
                            <AutoComplete
                                    name={'event_category'}
                                    title={'Select User'}
                                    data={this.props.state.users}
                                    value={this.props.state.selected_user}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    onSelect={(args) => {
                                        //console.log(args.value)
                                        //console.log(this.props.state.users)
                                        //console.log(this.props.state.users.filter(x => x.user_id === args.value))
                                        this.props.actions.setPropData('win_email_input', this.props.state.users.filter(x => x.user_id === args.value)[0].email)
                                        this.props.actions.setPropData('selected_user', args.text)
                                    }}
                                    onClick={() => this.props.actions.getUser('')}
                                    onFilter={(args) => this.props.actions.getUser(args)} />
                            </Col>
                            <Col xl={12}>
                                Or Enter Email Address
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth={true}
                                    id={'win_email_input'}
                                    hintText={'Enter Email'}
                                    value={this.props.state.win_email_input}
                                    onChange={(event, value) => {
                                        this.props.actions.setPropData('win_email',event.target.value)
                                    }}
                                />
                            </Col>
                            <Col xl={12}>
                                Enter Comments
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth={true}
                                    id={'win_ack_input_input'}
                                    hintText={'Enter Comments'}
                                    value={this.props.state.win_ack_input_input}
                                    onChange={(event, value) => {
                                        this.props.actions.setPropData('win_ack_input_input',event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <hr />
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.setPropData('win_email',false)
                        }}
                        className=" btn-pill mx-1">
                        <span className="btn-wrapper--label">Close</span>
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.updateAck('',this.props.state.workqueue.component.name,this.props.state.record_field.name, this.props.state.record_id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id,this.props.state.win_ack_input_input)
                            this.props.actions.setPropData('win_email',false)
                            this.props.actions.setPropData(this.props.state.selected_user ? this.props.state.selected_user : this.props.state.win_email_input,'',this.props.state.win_ack_input_input)
                       }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Send Email and Close</span>
                    </Button>
                    </div>
                </div>} />
            </Dialog>
    }

    getWinMessage() {
        return  <Dialog
                open={this.props.state.win_send_message}
                style={{ width: '100% !important', maxWidth: '100% !important' }}
                
                maxWidth="xl">
                <Card title={window.t.en('Send Message')} 
                subtitle={<div>{'Send message to notify selected user'}</div>}
                content={<div className="text-center- p-1">
                    <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                Select User
                            </Col>
                            <Col xl={12}>
                                
                                <AutoComplete
                                    name={'event_category'}
                                    title={'Select User'}
                                    data={this.props.state.users}
                                    value={this.props.state.selected_user}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    onSelect={(args) => this.props.actions.setPropData('selected_user', args.text)}
                                    onClick={() => this.props.actions.getUser('')}
                                    onFilter={(args) => this.props.actions.getUser(args)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                Enter Message
                            </Col>
                            <Col xl={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth={true}
                                    id={'win_send_message_input'}
                                    hintText={'Enter Email'}
                                    value={this.props.state.win_send_message_input}
                                    onChange={(event, value) => {
                                        this.props.actions.setPropData('win_send_message',event.target.value)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <hr />
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.setPropData('win_send_message',false)
                        }}
                        className=" btn-pill mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.updateAck('',this.props.state.workqueue.component.name,this.props.state.record_field.name, this.props.state.record_id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id,this.props.state.win_ack_input_input)
                            this.props.actions.setPropData('win_send_message',false)
                        }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Send Message and Close</span>
                    </Button>
                    </div>
                </div>} />
            </Dialog>
    }

    getReAssign() {
        return  <Dialog
                open={this.props.state.win_re_assigning}
                style={{ width: '100% !important', maxWidth: '100% !important' }}
               
                maxWidth="xl">
                <Card title={window.t.en('Re Assign')} 
                subtitle={<div>{'Re assign event to another user'}</div>}
                content={<div className="text-center- p-1">
                    <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                Select User
                            </Col>
                            <Col xl={12}>
                                <AutoComplete
                                    name={'event_category'}
                                    title={'Select User'}
                                    data={this.props.state.users}
                                    value={this.props.state.selected_user}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    onSelect={(args) => this.props.actions.setPropData('selected_user', args.text)}
                                    onClick={() => this.props.actions.getUser('')}
                                    onFilter={(args) => this.props.actions.getUser(args)} />
                            </Col>
                        </Row>
                    </Container>
                    <hr />
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.setPropData('win_re_assigning',false)
                        }}
                        className=" btn-pill mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.updateAck('Re Assign',this.props.state.workqueue.component.name,this.props.state.record_field.name, this.props.state.record_id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id,this.props.state.win_ack_input_input)
                            this.props.actions.setPropData('win_re_assigning',false)
                        }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Re Assign and Close</span>
                    </Button>
                    </div>
                </div>} />
            </Dialog>
    }


    getEscalate() {
        return  <Dialog
                open={this.props.state.win_escalate}
                style={{ width: '100% !important', maxWidth: '100% !important' }}
               
                maxWidth="xl">
                <Card title={window.t.en('Escalate Event')} 
                subtitle={<div>{'Escalate event to another user'}</div>}
                content={<div className="text-center- p-1">
                    <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                Select User
                            </Col>
                            <Col xl={12}>
                                <AutoComplete
                                    name={'event_category'}
                                    title={'Select User'}
                                    data={this.props.state.users}
                                    value={this.props.state.selected_user}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    onSelect={(args) => this.props.actions.setPropData('selected_user', args.text)}
                                    onClick={() => this.props.actions.getUser('')}
                                    onFilter={(args) => this.props.actions.getUser(args)} />
                            </Col>
                        </Row>
                    </Container>
                    <hr />
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.setPropData('win_escalate',false)
                        }}
                        className=" btn-pill mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                    <Button
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.sendEscalation()
                            //this.props.actions.updateAck(this.props.state.workqueue.component.name,this.props.state.record_field.name, this.props.state.record_id,this.props.state.workqueue.workqueue_title, this.props.state.user.user_id,this.props.state.win_ack_input_input)
                            this.props.actions.setPropData('win_escalate',false)
                        }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Escalate</span>
                    </Button>
                    </div>
                </div>} />
            </Dialog>
    }
    getLayout(data) {
        //console.log('getLayout',data)
        return <Card title={window.t.en(data?.params?.workqueue_name)} 
                subtitle={<div>{'Events Listing'}</div>}
                content={<div>
                    { this.getGrid() }
                    { this.getReAssign() }
                    { this.getWinEmail() }
                    { this.getWinMessage() }
                    { this.getAckInput() }
                    { this.getEscalate() }
                </div>} 
            />
    }

    render() {
        let data = null
        try {
            data = this.props.state.formdata
        } catch (error) {
            
        }
         return this.props.state.formdata
            ? this.getLayout(this.props.state.formdata)
            : <Loading message={'Loading data...'} />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workqueue,
            user: {
                ...state.user,
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...gridAction, ...compActions, ...redirectActions, ...readActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkqueueGrid)