import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import * as _ from 'lodash'
import AutoComplete from '../../../controls/autocomplete'
import Card from '../../../controls/card'
import Checkbox from '@mui/material/Checkbox'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import ReactTable from "react-table"
import { bindActionCreators } from 'redux'
import AdhocGrid from '../AdhocGrid/component'
import Icon from '../../Icon/component'
import * as actions from './actions'
import CommentsView from './comments'
import * as styles from './styles'
import DetailView from './view'
let supplier_id = 0

class SUPPLIERPAYMENTAPPROVAL extends Component {
    componentDidMount() {
        this.actions = this.props.actions;
        this.props.actions.resetComponent();
        let data = {
            supplier_id: 0,
            search_text: ''
        }
        this.props.actions.updateValue('search_text', '')
        //this.props.actions.getPaymentList(data);
    }
    getLayout(data) {
        //console.log("getLayout")
        let payment_list = data.step1.data.loaded.payment_list
        return <Card
            title={'Supplier Payment Approval'}
            subtitle={'Supplier Details'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {
                                payment_list.length
                                    ? payment_list
                                    .map((x, i) => {
                                        return <div>
                                            <div key={'payment_data_' + x['supplier_id']} style={styles.row(i, true)}>
                                                <div style={styles.column(300, false)}>
                                                    <strong>{'#' + x.supplier}</strong> {' ' + x.accountnumber}<br />
                                                    {x.suppliertype}<br />
                                                </div>
                                                <div style={styles.column(200, false)}>
                                                    <strong>{'SLA Signed Date: '}</strong> {x.dateslasigned ? moment(x.dateslasigned).format('YYYY-MM-DD') : 'Not Set'}<br />
                                                    <strong>{'SLA Expiry Date: '}</strong> {x.slaexpirydate ? moment(x.slaexpirydate).format('YYYY-MM-DD') : 'Not Set'}<br />
                                                    <strong>{'SLA Labour Rate/Hour: '}</strong> {x.slarateperhour ? x.slarateperhour : 'Not Set'}<br />
                                                    <br />
                                                </div>
                                                <div style={styles.column(200, false)}>
                                                    <strong>{'Supplier Limit: '}</strong> {parseFloat((x.suplierspendlimit ? x.suplierspendlimit : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                    <strong>{'Supplier Spend: '}</strong> {parseFloat((x.suplierusage ? x.suplierusage : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                    <strong>{'Balance: '}</strong> {parseFloat((x.suplierspendlimit ? x.suplierspendlimit - x.suplierusage : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                    <br />
                                                </div> <div style={styles.column(150, false)}>
                                                    <strong>{'Auth Amount: '}</strong> {parseFloat((x.authamount ? x.authamount : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                    <strong>{'Short Pay: '}</strong> {parseFloat((x.shortpay ? x.shortpay : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                    <strong>{'Discount %: '}</strong> {parseFloat((x.discount ? x.discount : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                    <strong>{'Total Payment: '}</strong> {parseFloat((x.payment ? x.payment : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                    
                                                </div>
                                                <div style={styles.column(250, false)}> <strong>{'Comments '}</strong><hr /> {<TextField
                                                    fullWidth={true}
                                                    hintText='Capture comments...'
                                                    value={x.gm_comments}
                                                    onChange={(event, value) => {
                                                        this.props.actions.updateValue('updatevalue', value)
                                                        x.gm_comments = value
                                                    }}
                                                    onBlur={(event, value) => {
                                                        let obj = {
                                                            parent_id: x.supplier_id,
                                                            type: x.items.length ? x.items[0].dataset : '',
                                                            column: 'acceptall',
                                                            value: x.selected ? false : true,
                                                            search_text: this.props.state.search_text
                                                        }
                                                        this.props.actions.getUpdateAllPaymentListSupplier(obj)
                                                    }}
                                                />}<br />
                                                    <br />
                                                </div>
                                                <div style={styles.column(250, false)}>
                                                < Button className={"global_button"} variant="contained"
                                                onClick={(event) => {
                                                    
                                                    this.props.actions.processPaymentGM(x)
                                                }}
                                            >{'Process Payment'}</Button>
                                                                       
                                                    <div>
                                                        <strong>{'Not Handled: [ ' + x.items.filter(x => x.status == "Not Handled").length + ' ]'}</strong><br/>
                                                        <strong>{'Approved: [ ' + x.items.filter(x => x.status == "Approved").length + ' ]'}</strong><br/>
                                                        <strong>{'Rejected: [ ' + x.items.filter(x => x.status == "Rejected").length + ' ]'}</strong><br/>                                         
                                                    </div>
                                                <br/></div>
                                            </div>
            
                                            {this.props.state.prop_inx === (i + 1) && <DetailView inx={(i + 1)} data={x} />}
                                            {x.showcomments && <CommentsView inx={(i + 1)} data={x} />}
            
            
                                        </div>
                                    })
                                    : <Row style={styles.row(0, false)}>
                                        <Col xl={12}>
                                            <div>{'No Records Found...'}</div>
                                        </Col>
                                    </Row>
                            }
                        </Col>

                    </Row>
                </Container>}
        />

    }
    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.step.context_data.data.data.data

        } catch (error) { }
        return (workflow_queue_data ? this.getLayout(workflow_queue_data) : window.t.en('Please try again, contact system administrator if this persist'))
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.supplierpaymentapproval,
            user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERPAYMENTAPPROVAL)