import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
            case types.SET_DIALOG:
                return {
                    ...state,
                    'open': action.payload
                }
        default:
            return state
    }
}