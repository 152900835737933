import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.TOGGLE_WORKFLOW_DISPOSAL:
            return {
                ...state,
                isOpen: !state.isOpen
            }
        case types.SET_WORKFLOW_DISPOSAL_DATA_LIST:
            return {
                ...state,
                yardlist: action.payload
            }
        case types.SET_OTP_VEHICLE_LIST:
            return {
                ...state,
                optlist_data: action.payload
            }
        case types.SET_DISPOSAL_CURRENT_TAB:
            return {
                ...state,
                tab: action.payload
            }
        case types.RESET_ALL_INFOSHEETS:
            return {
                ...state,
                inforsheet_view: false,
                inforsheet_edit: false,
                inforsheet_forum: false,
                inforsheet_documents: false,
                inforsheet_offers: false,
                inforsheet_disposal: false,
                inforsheet_settlement: false,
                inforsheet_decision: false
            }
        case types.SET_WORKFLOW_DISPOSAL_DATA_LIST:
            return {
                ...state,
                yardlist: action.payload
            }
        case types.SET_BIDDERS_DISPOSAL_DATA_LIST:
            return {
                ...state,
                bidders: action.payload
            }
        case types.SET_DISPOSAL_COMPONENT_CHIP_FILTER:
            return {
                ...state,
                chip_filter: action.payload
            }
        case types.SET_WORKFLOW_DISPOSAL_OPT_LIST:
            return {
                ...state,
                otp: action.payload
            }
        case types.TOGGLE_ADD_VEHICLE_OFFER:
            return {
                ...state,
                addoffer: action.payload
            }
        case types.SET_IMPORTED_DATA_ROWS:
            return {
                ...state,
                import_rows: action.payload
            }
        case types.SET_SELECTED_IMPORT_TEMPLATE:
            return {
                ...state,
                template: action.payload
            }
        case types.RESET_IMPORT_DATA_SCREEN:
            return {
                ...state,
                template: null,
                import_rows: [],
                import_filename: null
            }
        case types.SET_UPLOADED_EXCEL_FILE_NAME:
            return {
                ...state,
                import_filename: action.payload
            }
        case types.TOGGLE_WORKFLOW_DISPOSAL_INFO_SHEET:
            return {
                ...state,
                infosheet_inx: action.payload.inx,
                [action.payload.infosheet]: action.payload.status
            }
        case types.SET_DISPOSAL_COMPONENT_INPUT_FIELD:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        default:
            return state
    }
}