import parser from "cron-parser";
import _ from 'lodash';
import moment from 'moment';
import * as types from './types';
import * as appTypes from '../App/types';

export const setInputValue = (prop, value) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_REPORT_SCHEDULE_COMPONENT_FIELD_INPUT_VALUES,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setStateData = (prop, value) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_STATE_DATA,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const loadEditData = (data) => {
    data.week_day_sun = false;
    data.week_day_mon = false;
    data.week_day_tue = false;
    data.week_day_wed = false;
    data.week_day_thu = false;
    data.week_day_fri = false;
    data.week_day_sat = false;
    if (data.report_day) {
        let reportDate = data.report_day.replace(/{|}/g, "");
        let split = reportDate.split(',');
        split.forEach((weekDate, key) => {
            switch (weekDate) {
                case '0':
                    data.week_day_sun = true;
                    break;
                case '1':
                    data.week_day_mon = true;
                    break;
                case '2':
                    data.week_day_tue = true;
                    break;
                case '3':
                    data.week_day_wed = true;
                    break;
                case '4':
                    data.week_day_thu = true;
                    break;
                case '5':
                    data.week_day_fri = true;
                    break;
                case '6':
                    data.week_day_sat = true;
                    break;
            }
        });
    }

    return (dispatch) =>  {
        dispatch({
            type: types.SET_SCHEDULER_EDIT_DATA,
            payload: data
        })
    }
}

export const loadAddData = () => {
    return (dispatch) =>  {
        dispatch({
            type: types.SET_SCHEDULER_ADD_DATA,
            payload: {}
        })
    }
}

export const changeTabView = (tab) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STATE_DATA,
            payload: {
                prop: 'tab',
                value: tab
            }
        })
    }
}

export const setUserInput = (prop, message, saveReport) => {
    return (dispatch, getState) => {
        if (saveReport) {
            ['report_title', 'selected_report_type', 'key', 'sub_category', 'subcategory_id'].map(x => {
                dispatch({
                    type: types.SET_CREATE_USER_INPUT,
                    payload: {
                        data: null,
                        prop: x
                    }
                })
            })
        } else {
            dispatch({
                type: types.SET_CREATE_USER_INPUT,
                payload: {
                    data: message,
                    prop: prop
                }
            })
            let user = getState().user.user_id

            dispatch({
                type: types.SET_CREATE_USER_INPUT,
                payload: {
                    data: user,
                    prop: 'user'
                }
            })
        }

    }
}

export const getReportCatData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_REPORT_CATEGORIES
        })
    }
}

export const setReportCatData = (data) => {
    return (dispatch, getState) => {
        if (data.data.length) {
            let categories = data.data;
            // Loop through category and find all reports with this category
            if (getState().reportScheduler?.navReports) {
                getState().reportScheduler.navReports.forEach((v, i) => {
                    // Find category id of this report
                    if (v.subcategory_id) {
                        // Loop through each category and sub category abd find all reports with this sub category
                        categories.forEach((c, i) => {
                            if (c.subcategory) {
                                const subCategories = c.subcategory;
                                const subCategoryIndex = _.findIndex(subCategories, o => o.subcategory_id === v.subcategory_id);
                                if (subCategoryIndex !== -1) {
                                    if (subCategories[subCategoryIndex].reports) {
                                        subCategories[subCategoryIndex].reports.push(v);
                                    } else {
                                        subCategories[subCategoryIndex].reports = [v];
                                    }
                                }
                            }
                        });
                    }
                })
            }

            let finalCategories = [];
            categories.forEach((cat, i) => {
                const subReports = _.filter(cat.subcategory, o => o.reports !== undefined);
                if (subReports.length > 0) {
                    finalCategories.push(cat);
                }
            })

            dispatch({
                type: types.SET_STATE_DATA,
                payload: {
                    value: finalCategories,
                    prop: 'reportCategories'
                }
            })
        }
    }
}

export const saveSchedulerData = (data) => {
    let saveObj = {
        user_id: null,
        report_id: data.report_id,
        report_category_id: data.report_category_id,
        report_subcategory_id: data.report_subcategory_id,
        scheduler_name: data.scheduler_name,
        frequency: data.frequency,
        report_min: data.report_min,
        report_hour: data.report_hour,
        report_date: data.report_date,
        report_month: data.report_month,
        start_date: data.start_date,
        end_date: data.end_date,
        end_type: data.end_type,
        run_count: data.run_count,
        report_day: null,
        schedule_expression: null,
        next_ran_date: null
    }
    
    if (data?.scheduler_id && data?.scheduler_id > 0) {
        saveObj.scheduler_id = data.scheduler_id;
    }

    setWeekDayValues(saveObj, data);
    setSaveDatePickerValues(saveObj);
    setScheduleRange(saveObj);
    setCronExpression(saveObj);

    return (dispatch, getState) => {
        saveObj.user_id = getState().user.user_id;
        saveObj.client_id = getState().user.client_id;
        if (saveObj?.scheduler_id) {
            saveObj.executed_run_count = 0;
            saveObj.updated_by_user_id = getState().user.user_id;
        } else {
            saveObj.created_by_user_id = getState().user.user_id;
        }
        dispatch({
            type: types.SAVE_SCHEDULER,
            payload: saveObj
        })
    }
}

const setWeekDayValues = (obj, dataObj) => {
    if (obj.frequency === 'day') {
        obj.report_day = null;
        obj.report_date = null;
        obj.report_month = null
        obj.report_day_expression = null;
    } else if (obj.frequency === 'week') {
        let string = '{';
        let expression = '';
        if (dataObj.week_day_sun !== false) {
            string += '0,';
            expression += '0,';
        }
        if (dataObj.week_day_mon !== false) {
            string += '1,';
            expression += '1,';
        }
        if (dataObj.week_day_tue !== false) {
            string += '2,';
            expression += '2,';
        }
        if (dataObj.week_day_wed !== false) {
            string += '3,';
            expression += '3,';
        }
        if (dataObj.week_day_thu !== false) {
            string += '4,';
            expression += '4,';
        }
        if (dataObj.week_day_fri !== false) {
            string += '5,';
            expression += '5,';
        }
        if (dataObj.week_day_sat !== false) {
            string += '6,';
            expression += '6,';
        }
        string = string.substring(0, string.length - 1) + '}';
        obj.report_day = string;
        obj.report_date = null;
        obj.report_month = null;
        obj.report_day_expression = expression.substring(0, expression.length - 1);
    } else if (obj.frequency === 'month') {
        obj.report_day = null;
        obj.report_month = null;
        obj.report_day_expression = null;
    } else if (obj.frequency === 'year') {
        obj.report_day = null;
        obj.report_day_expression = null;
    }
}

const setSaveDatePickerValues = (obj) => {
    obj.start_date = obj.start_date ? moment(obj.start_date).format('YYYY-MM-DD') : null;
    obj.end_date = obj.end_date ? moment(obj.end_date).format('YYYY-MM-DD') : null;
}

const setScheduleRange = (obj) => {
    switch (obj.end_type) {
        case '3':
            obj.end_date = null;
            obj.run_count = null;
            break;
        case '2':
            obj.end_date = null;
            break;
        case '1':
            obj.run_count = null;
            break;
    }
}

const setCronExpression = (obj) => {
    // Get expression
    let expression = '';
    expression += obj.report_min ? obj.report_min : '*';
    expression += obj.report_hour ? ' ' + obj.report_hour : ' *';
    expression += obj.report_date ? ' ' + obj.report_date : ' *';
    expression += obj.report_month ? ' ' + obj.report_month : ' *';
    expression += obj.report_day_expression ? ' ' + obj.report_day_expression : ' *';
    obj.schedule_expression = expression;

    // Get next run date
    const interval = parser.parseExpression(obj.schedule_expression);
    obj.next_ran_date = moment(interval.next().toISOString()).format('YYYY-MM-DD HH:mm:ss');
}

export const deleteScheduler = (id) => {
    if (id > 0) {
        return (dispatch, getState) => {
            let saveObj = {};
            saveObj.scheduler_id = id;
            saveObj.updated_by_user_id = getState().user.user_id;
            saveObj.deleted_at = moment().format('YYYY-MM-DD');
            dispatch({
                type: types.SAVE_SCHEDULER,
                payload: saveObj
            })
        }
    }
}

export const getReportData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_REPORTS
        })
    }
}

export const setReportData = (data) => {
    return (dispatch, getState) => {
        const navReports = _.filter(data.data, o => o.oq_dashboard_key === null);
        //&& o.js_report === true
        if (navReports.length) {
            dispatch({
                type: types.SET_STATE_DATA,
                payload: {
                    value: navReports,
                    prop: 'navReports'
                }
            })
        }
    }
}

export const saveSchedulerDone = (payload) => {
    return (dispatch, getState) => {
        if (payload.deleted_at) {
            dispatch({
                type: types.SET_STATE_DATA,
                payload: {
                    value: 0,
                    prop: 'deleteSchedulerId'
                }
            })
    
            dispatch({
                type: types.SET_STATE_DATA,
                payload: {
                    value: false,
                    prop: 'confirmDeleteWindow'
                }
            })
            
            dispatch({
                type: types.GET_REPORTS_SCHEDULER_DATA
            })
        } else {
            dispatch({
                type: types.SET_STATE_DATA,
                payload: {
                    value: 0,
                    prop: 'tab'
                }
            })
        }

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Schedular saved successfully',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        //dispatch(changeTabView(0))
    }
}

export const getSchedulerData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_REPORTS_SCHEDULER_DATA
        })
    }
}

export const setSchedulerData = (response) => {
    //console.table(response)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STATE_DATA,
            payload: {
                prop: 'data',
                value: response.data
            }
        })
    }
}