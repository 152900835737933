import * as types from './types'
import * as teamManagerTypes from '../components/TeamManagement/TeamManager/types'
import moment from 'moment'
import * as quoteItems from '../components/WorkflowPOHeader/QuoteItems/actions'

import * as appTypes from '../components/App/types'

export const onInfo = (info) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_AUTOCOMPLETE_INFO_SHEET,
            payload: info
        })
    }
}

export const updateCustomerAddress = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_CUSTOMER_DELIVERY_ADDRESS,
            payload: data
        })
    }
}


export const updateCustomerAddressDone = (data) => {
    return (dispatch, getState) => {

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Delivery address details successfully updated',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const changeTabView = (inx) => {
    return (dispatch, getState) => {
        //console.log('changeTabView', inx)
        dispatch({
            type: types.SET_CURRENT_TAB_VIEW,
            payload: inx
        })
    }
}

export const getHierarchy = (data_id) => {

    return (dispatch, getState) => {
        
        dispatch({
            type: types.GET_CUSTOMER_HIE,
            payload: {
                type: 'GET_CUSTOMER_HIE',
                data_id: data_id
            }
        })
    }
}

export const setHierarchy = (data) => {
    let res = data.hierachy_data.map(x => x.results)
    let parent = res.filter(p => p.parent == null)
    let child = res.filter(p => p.parent !== null)

    let Children = child.map(x => (
        {
            alldata:x,
            customer_id: x.customer_id,
            label: x.rel_name,
            checked: true,
        }
    ))

    //console.log("Children", Children);
    //console.log("Children", Children);
    //console.log("Children", Children);

    let new_data = [{
        alldata: parent,
        ancestry: parent[0].ancestry,
        customer_id: parent[0].customer_id,
        level: parent[0].level,
        number_of_ancestors: parent[0].number_of_ancestors,
        checked: true,
        parent: null,
        label: parent[0].rel_name,
        start_of_ancestry: parent[0].start_of_ancestry,
        children: Children
    }]
    //console.log("hierachy_data_ : data.hierachy_data", new_data);
    return (dispatch, getState) => {
        
        dispatch({
            type: types.SET_CUSTOMER_HIE,
            payload: {
                data: new_data,
                prop: 'hierarchy_data',
                
            }
        })
    }
}

export const onChangeHierarchy = (selectedNodes, data, ind) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: types.SET_HIERARCHY_SELECTION_VALUE,
            payload: {
                data: data,
                prop: 'hierarchy_selection'
            }
        })
    }
}

export const toggleExpand = (id) => {
    return (dispatch, getState) => {
        let expand_list = getState().controls.expand_list
        if(expand_list.includes(id))
            expand_list = expand_list.filter(x => x !== id) 
        else 
            expand_list.push(id)
        dispatch({
            type: types.SET_CONTROL_TREE_SELECTION_EXPANDED_LIST,
            payload: expand_list
        })
    }
}
export const toggleChecked = (data,checked,multiple) => {
    return (dispatch, getState) => {
        let checked_list = getState().controls.checked_list
        if(checked)
            checked_list.push(data)
        else 
            checked_list = checked_list.filter(x => x.id !== data.id) 
            //console.log('toggleChecked actio',[data])
        dispatch({
            type: types.SET_CONTROL_TREE_SELECTION_CHECKED_LIST,
            payload: [data]
        })
    }
}
export const getComponentVehicleDate = (id) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: types.SET_CONTROL_VIEW_VEHICLE_DATA_BY_ID,
            payload: {}
        })

        dispatch({
            type: types.GET_CONTROL_VIEW_VEHICLE_DATA_BY_ID,
            payload: id
        })
    }
}

export const toggleVehicleHistory = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_VEHICLE_HISTORY_VIEW_PDF,
            payload: !status
        })
    }
}

export const toggleVehicleServiceHistory = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_VEHICLE_SERVICE_HISTORY_VIEW,
            payload: status
        })
    }
}

export const toggleCallpaseContent = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_CALLAPSE_CONTENT_CARD,
            payload: !status
        })
    }
}
export const toggleAddItemDialog = (status, name) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_ADD_NEW_ITEM_WINDOW,
            payload: {
                status: status,
                name: name
            }
        })
    }
}
export const setComponentVehicleDate = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_VEHICLE_DATA_BY_ID,
            payload: data.data[0]
        })
    }
}

export const getComponentSupplierDate = (id,accessory_id) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_MONTHLY_SPEND,
            payload: {
                spend: 0,
                limit: true
            }
        })

        dispatch({
            type: types.SET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID,
            payload: null
        })

        dispatch(getSupplierMonthlySpend(id))
        dispatch({
            type: types.GET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID,
            payload: {
                id : id ,
                accessory_id : accessory_id}
        })

    }
}

export const getComponentSupplierTransaction = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_SUPPLIER_TRANSACTION,
            payload: []
        })
        dispatch({
            type: types.GET_CONTROL_VIEW_SUPPLIER_TRANSACTION,
            payload: id
        })

    }
}

export const setComponentSupplierTransaction = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_SUPPLIER_TRANSACTION,
            payload: response.data
        })
    }
}

export const setSupplierTransalationPayStatus = (suppliertransaction_id, amount) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_SUPPLIER_TRANSACTION,
            payload: response.data
        })
    }
}

export const setSupplierTransalationInputValue = (suppliertransaction_id, field, amount) => {
    return (dispatch, getState) => {
        let supplier_transactions = getState().controls.supplier_transactions
        let _supplier_transactions = supplier_transactions.map(x => {
            return x.suppliertransaction_id == suppliertransaction_id ? {...x, [field]: amount} : x
        })
        dispatch({
            type: types.SET_CONTROL_VIEW_SUPPLIER_TRANSACTION,
            payload: _supplier_transactions
        })

    }
}

export const setComponentSupplierDate = (data,payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID,
            payload: data.data[0]
        })
        dispatch(
            getSupplierAccessoryHistoryData(payload.id,payload.accessory_id)
        )
        
    }
}

export const setTreeViewData = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_TREE_VIEW_INIT_DATA,
            payload: data
        })
    }
}

export const getSupplierMonthlySpend = (supplier_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_MONTHLY_SPEND,
            payload: {
                type: 'GET_SUPPLIER_MONTHLY_SPEND',
                supplier_id: supplier_id
            }
        })
    }
}





export const getWorkflowTasks = (workflow_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_STEPS,
            payload: []
        })
        dispatch({
            type: types.GET_WORKFLOW_TASK_STEPS,
            payload: workflow_id
        })
    }
}

export const setWorkflowTasks = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_STEPS,
            payload: response.data
        })
    }
}

export const resetWorkflowTasks = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_TASK_STEPS,
            payload: []
        })
    }
}

export const setSupplierMonthlySpend = (payload) => {
    return (dispatch, getState) => {
        //let selectedSupplier = x.selected.value ? this.props.state.selectedSupplierDetails.data.filter(b => b.supplier_id === x.selected.value) : []
      
        //console.log('payload.data',payload.data)
        dispatch({
            type: types.SET_SUPPLIER_MONTHLY_SPEND,
            payload: {
                spend: payload.data,
                limit: payload.data.length ? (parseInt(payload.data[0].curr_usage) < parseInt(payload.data[0].spendlimit)) : false
            }
        })
    }
}

export const getComponentCustomerDate = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CONTROL_VIEW_CUSTOMER_DATA_BY_ID,
            payload: id
        })
    }
}

export const setComponentCustomerDate = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_CUSTOMER_DATA_BY_ID,
            payload: data.data[0]
        })
    }
}

export const getComponentCustomerAddress = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CONTROL_VIEW_CUSTOMER_ADDRESS,
            payload: id
        })
    }
}

export const setAddressInput = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CUSTOMER_DELIVERY_ADDRESS_INPUT,
            payload: data
        })
    }
}

export const setComponentCustomerAddress = (data) => {
    console.log('getCustomerAddressx set', data.data)

    
    let customeraddress = data.data
    console.log('getCustomerAddressx customeraddress', customeraddress)
    let street = customeraddress.length ? customeraddress.filter(x => x?.address?.addresstype?.name == "Physical")  : []
    street = street.length ? street[0] : {address: {}}
    console.log('getCustomerAddressx street', street)

    
    console.log('getCustomerAddressx customeraddress', customeraddress)
    let delivery = customeraddress.length ? customeraddress.filter(x => x?.address?.addresstype?.name == "Delivery")  : []
    delivery = delivery.length ? delivery[0] : {address: {}}

    
    console.log('getCustomerAddressx delivery', delivery)
    console.log('getCustomerAddressx street', street)

    return (dispatch, getState) => {
        dispatch(setAddressInput({prop: 'line', value: street ? street.address.line1 : '' }))
        dispatch(setAddressInput({prop: 'town', value: street ? street.address.town : '' }))
        dispatch(setAddressInput({prop: 'province', value: street ? street.address.province : '' }))
        dispatch(setAddressInput({prop: 'country', value: street ? street.address.country : '' }))
        dispatch(setAddressInput({prop: 'postalcode', value: street ? street.address.postalcode : '' }))

        dispatch(setAddressInput({prop: 'del_address_id', value: delivery ? delivery.address.address_id : '' }))
        dispatch(setAddressInput({prop: 'del_line', value: delivery ? delivery.address.line1 : '' }))
        dispatch(setAddressInput({prop: 'del_line2', value: delivery ? delivery.address.line2 : '' }))
        dispatch(setAddressInput({prop: 'del_town', value:  delivery ? delivery.address.town : '' }))
        dispatch(setAddressInput({prop: 'del_province', value:  delivery ? delivery.address.province : '' }))
        dispatch(setAddressInput({prop: 'del_postalcode', value:  delivery ? delivery.address.postalcode : '' }))
    }
}

export const getComponentContractDate = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CONTROL_VIEW_CONTRACT_DATA_BY_ID,
            payload: id
        })
    }
}

export const setComponentContractDate = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_CONTRACT_DATA_BY_ID,
            payload: data.data[0]
        })
    }
}


export const getAutoCompleteDate = (data) => {
    return (dispatch, getState) => {
        //console.log('setAutoCompleteDatex get', data)
        dispatch({
            type: types.GET_CONTROL_VIEW_FORM_AUTOCOMPLETE_DATA,
            payload: data
        })
    }
}

export const setAutoCompleteDate = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setAutoCompleteDatex response', response)
        //console.log('setAutoCompleteDatex payload', payload)
        dispatch({
            type: types.SET_CONTROL_VIEW_INPUT_DATA,
            payload: {
                localstate: payload.localstate,
                prop: payload.datasource + '_data',
                data: response.data.map(x => {
                    return {...x,text: x[payload.text],value: x[payload.value]}
                })
            }
        })
    }
}

export const setAutoCompleteSelected = (field, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_INPUT_DATA,
            payload: {
                localstate: field.localstate,
                prop: field.datasource,
                data: data
            }
        })
    }
}
export const getComponentMaintenanceData = (id, taskno) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CONTROL_VIEW_MAINTENANCE_DATA_BY_ID,
            payload: {
                id: id,
                taskno: taskno,
            }
        })
    }
}

export const getComponentMultiMaintenanceData = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CONTROL_VIEW_MULTI_MAINTENANCE_DATA_BY_ID,
            payload: id
        })
    }
}

export const setComponentMultiMaintenanceData = (data) => {
    return (dispatch, getState) => {
        //console.log('setComponentMultiMaintenanceDatax', data)
        dispatch({
            type: types.SET_CONTROL_VIEW_MULTI_MAINTENANCE_DATA_BY_ID,
            payload: data.data
        })
    }
}

export const setComponentMaintenanceData = (data,taskno) => {
    return (dispatch, getState) => {

        if(data.data.length){

            dispatch({
                type: types.SET_CONTROL_VIEW_MAINTENANCE_DATA_BY_ID,
                payload: data.data[0]
            })
        }
        else {
            dispatch({
                type: types.GET_CONTROL_VIEW_MAINTENANCE_DATA_BY_TASKNO_ID,
                payload: taskno
            })
            
        }
    }
}

export const setComponentMaintenanceDataByTaskNo = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_MAINTENANCE_DATA_BY_ID,
            payload: data.data[0]
        })
    }
}


export const getVehicleMapoServicePlan = (serviceinterval,mmcode) => {
    //console.log('setVehicleMapoServicePlan id',serviceinterval,mmcode)
    mmcode = mmcode ? mmcode : '47032185'
    serviceinterval = serviceinterval ? serviceinterval.split(' ')[0] : ''
    serviceinterval = serviceinterval.replace('kms','')
    serviceinterval = serviceinterval.replace('km','')
    //console.log('setVehicleMapoServicePlan id',serviceinterval,mmcode)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MAPO_VEHICLE_SERVICE_PLAN,
            payload: {
                mmcode: mmcode,
                serviceinterval: serviceinterval
            }
        })
    }
}

export const setVehicleMapoServicePlan = (data) => {
    //console.log('setVehicleMapoServicePlan data',data)
    return (dispatch, getState) => {
        let mapo = []
        for(let x of data.data) {
            if(!mapo.filter(f => f.description == x.description).length)
                mapo.push(x);
        }
        dispatch({
            type: types.SET_MAPO_VEHICLE_SERVICE_PLAN,
            payload: mapo
        })
    }
}
export const setVehicleMapoServicePlanStatus = (inx, checked, x) => {
    //console.log('setVehicleMapoServicePlanStatus data',inx, checked)
    return (dispatch, getState) => {
        let data = getState().controls.serviceplan
        data[inx]['checked'] = checked

        if(checked) {
            dispatch(quoteItems.addNewRow('Service',x.description,x.part_qty,x.part_price))
        }

        dispatch({
            type: types.SET_MAPO_VEHICLE_SERVICE_PLAN,
            payload: data
        })
    }
}
export const getTreeSelectData = (field,datasource,value) => {
    return (dispatch) => {
        let data = {field: field, datasource: datasource, value: value, query: datasource + "/other", type: 'hierachy'}
        dispatch({
            type: types.GET_CONTROL_FORM_TREE_DATA,
            payload: data
        })
    }
}

export const setTreeSelectData = (response, payload) => {
    return (dispatch) => {
        let data = {
            datasource: payload.datasource,
            field: payload.field+"_data",
            data: response.hierachy
        }
        //console.log('setTreeSelectData',response.hierachy)
        //console.log('setTreeSelectData data',data)
        dispatch({
            type: teamManagerTypes.SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: data
        })
    }
}

export const setComponentFieldInputAndText = (field, value, display) => {
    return (dispatch) => {
        dispatch({
            type: teamManagerTypes.SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: {
                field: field + '_id',
                data: value
            }
        })
        dispatch({
            type: teamManagerTypes.SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: {
                field: field,
                data: display
            }
        })
    }
}
//SET_CONTROL_FORM_TREE_DATA

// export const getFormComponent = (component) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_CONTROL_FORM_COMPONENT_DATA,
//             payload: component
//         })
//     }
// }

// export const setFormComponent = (data) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_CONTROL_FORM_COMPONENT_DATA,
//             payload: data.data
//         })
//     }
// }
export const getSupplierAccessoryHistoryData = (id,accessory_id) => {
    return (dispatch, getState) => {
        //console.log('Suppliers IDxxxx',id)
           //console.log('Acessorry IDxxxx',accessory_id)
            dispatch({
                type: types.GET_SUPPLIER_ACCESSORY_DATA,
                payload: {
                    type: 'get_supplieraccessoryhistorydata',
                    supplier_id: id ? id : 0,
                    accessory_id: accessory_id ? accessory_id : 0
                }
            })
    }
}

export const setSupplierAccessoryHistoryData = (response) => {
    return (dispatch, getState) => {
        //console.log('setSupplierAccessoryHistoryData response',response)
        let supplier_data = getState().controls.supplier
         supplier_data = {...supplier_data,history:response}
        // dispatch({
        //     type: types.SET_SUPPLIER_ACCESSORY_HISTORY_DATA,
        //     payload: response.rows
        // })
        //console.log('setSupplierAccessoryHistoryData supplier_data',supplier_data)
        //console.log('setSupplierAccessoryHistoryData response.rows',response.rows)
        dispatch({
            type: types.SET_CONTROL_VIEW_SUPPLIER_DATA_BY_ID,
            payload: supplier_data
        })
    }
}


export const getComponentQuoteData = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CONTROL_VIEW_QUOTE_DATA_BY_ID,
            payload: id
        })
    }
}

export const setComponentQuoteData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_QUOTE_DATA_BY_ID,
            payload: data.data
        })
    }
}



export const getAuditData = (id, component) => {
    return (dispatch, getState) => {
        
        console.log('auditdatax get', {
            id: id,
            component: component,
            query: 'get_auditdata',
            field: component + '_id',
        })
        dispatch({
            type: types.GET_CONTROL_VIEW_AUDIT_DATA,
            payload: {
                query: 'get_auditdata',
                id: id,
                component: component,
                field: component + '_id',
            }
        })
    }
}

export const setAuditData = (data) => {
    return (dispatch, getState) => {
        console.log('auditdatax set', data)
        dispatch({
            type: types.SET_CONTROL_VIEW_AUDIT_DATA,
            payload: data
        })
    }
}

