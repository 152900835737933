
export const amount = ''
export const labourrate = ''
export const discount = ''
export const supplierdocument = ''
export const selectedSupplierId = ''
export const selectedSupplierName = ''
export const geoVehiclePosition = []
export const servicebasket = []
export const customercontractderivative = null

export const filename = {
    errorText: 'Filename is required',
    value: '',
    valid: false,
    empty: true,
}
export const invoicepreview = {
    errorText: 'Upload supplier invoice',
    value: '',
    valid: false,
    empty: true,
}
export const quotenumber = {
    errorText: 'Quote number is required',
    value: '',
    valid: false,
    empty: true,
}

export const comments = {
    errorText: 'Quote number is required',
    value: '',
    valid: false,
    empty: true,
}

export const validation = {
    fields: [],
    list: [
        {
            comp: 'poBookingItems',
            prop: 'grid',
            errorText: 'Please enter quote items'
        },
        {
            comp: 'upload',
            prop: 'documents',
            errorText: 'Please enter quote items'
        }
    ],
    component: 'poBookingInvoice',
    valid: false
}
export const selectedPurchaseOrderType = 4

export const purchaseOrderTypes = [
    {
        value: 1,
        text: 'Assessor',
        key: 'assessor',
        types: []
    },
    // {
    //     value: 2,
    //     text: 'Corporate Vehicle',
    //     key: 'corporate_vehicle',
    //     types: []
    // },
    // {
    //     value: 3,
    //     text: 'FML Transnet',
    //     key: 'fml_transnet',
    //     types: []
    // },
    {
        value: 4,
        text: 'De-fleet',
        key: 'vehicle_defleet',
        types: []
    },
    {
        value: 5,
        text: 'Panel Beaters',
        key: 'panel_beatears',
        types: []
    },
    {
        value: 6,
        text: 'Repairs',
        key: 'repairs',
        types: []
    },
    {
        value: 7,
        text: 'Service',
        key: 'service',
        types: []
    },
    // {
    //     value: 8,
    //     text: 'STR Transnet / Corporate',
    //     key: 'str_transnet_corporate',
    //     types: []
    // },
    // {
    //     value: 9,
    //     text: 'Subscriptions',
    //     key: 'subscriptions',
    //     types: []
    // },
    {
        value: 10,
        text: 'Towing',
        key: 'towing',
        types: []
    },
    {
        value: 11,
        text: 'Tyres',
        key: 'tyres',
        types: []
    }
]

export const users = []

export const selectedUser = {
    errorText: 'Copy selection field required',
    data: [],
    valid: false,
    empty: true,
}

export const selectedVehicleDetails = {
    errorText: 'Please select vehicle from the list',
    data: [],
    valid: false,
    empty: true,
}

export const selectedSupplierDetails = {
    errorText: 'Please select supplier from the list',
    data: [],
    valid: false,
    empty: true,
}

export const selectedSupplierSuspended = {
    errorText: 'Selected supplier is suspended please select another supplier',
    data: [],
    valid: false,
    empty: true,
}
export const overthelimit = {
    errorText: 'Selected supplier is over the limit, please another supplier',
    data: [],
    valid: false,
    empty: true,
}
export const vehicles = []
export const supplierage = []
export const bookinginfo = []
export const selectedVehicle = []
export const preAuth = null
export const geoSuppliers = null
export const vehicleserviceinfo = []
export const recent_auth = []
export const maintpdf_preview = ''
export const maintpdf_filename = ''

export const supplierAutoCompleteAmount = 1

export const supplierAutoCompletes = [
    {
        id: 0,
        data: [],
        selected: []
    }
]

export const defleetpdf_preview = ''
export const  defleetpdf_filename = ''
export const poBookingItems = []
export const send_authorisation = {
    errorText: 'Send Authorisation to Supplier',
    value: false,
    valid: false,
    empty: true,
}
export const issupplier_correct = {
    errorText: 'Is the correct supplier selected?',
    value: false,
    valid: false,
    empty: true,
}