import * as _ from 'lodash'
import * as changeCase from 'change-case'
import moment from 'moment'

import * as helper from '../../helpers/components'
import * as types from './types'

import * as appActions from '../App/actions'
import * as queueTasksActions from '../WorkflowQueueTasks/actions'
import * as triggerActions from '../WorkflowQueueTasks/WorkflowTriggers/actions'

export const preloadWorkflowComponents = () => {
    return (dispatch) => {
        //components used in custom workflows...
        const components = [
            'team',
            'businessIntelligence',
            //'business',
            //'color',
            'contracttype',
            //'contractvehiclecategory',
            //'contractvehiclecategorymmcodegroup',
            'customer',
            //'customercontract',
            //'customerquote',
            'document',
            //'financialinstitution',
            //'licenceauthority',
            'mmcode',
            //'mmcodeoption',
            '//ordertype',
            //'purchaseorder',
            //'quotefor',
            //'quotetypecomponent',
            'supplier',
            //'surrogateheader',
            //'surrogateitems',
            //'trackingtype',
            'user',
            'vehicle',
            //'accessory',
            //'sundry',
            //'service',
            //'person',
            //'suppliercontact',
            'customerderivativecategory',
            // 'inbox_message',
            'customerderivative',
            'title',
            'budgetmanagement',
            'contact',
            'tracking',
            'customertransaction',
            'customertrackingcompany',
            'customerbankrecon',
            'supplierbankrecon'
        ]

        components.forEach(x => {
            dispatch(getComponent(x))
        })
    }
}

export const getComponentNames = () => {
    return (dispatch) => {
        dispatch({
            type: types.COMPONENTS_GET_COMPONENT_NAMES
        })
    }
}

export const setComponentNames = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.COMPONENTS_SET_COMPONENT_NAMES,
            payload: data.reduce((obj, x) => {
                obj[x.name] = {
                    ...x,
                    loading: true
                }

                return obj
            }, {})
        })

        dispatch(preloadWorkflowComponents())
        dispatch(triggerActions.getWorkflowTriggers())
        dispatch(queueTasksActions.getWorkflowQueueTaskTeams())
    }
}

export const hardReloadComponent = (component) => {
    //console.log('hardReloadComponent', component)
    return (dispatch, getState) => {
        const definition = getState().components[component]
        if (definition) {
            dispatch({
                type: types.COMPONENTS_GET_COMPONENT,
                payload: component
            })
        }
        else {
            // window.Raygun.send(new Error('Unable to find component "' + component + '" in the state tree @ getComponent(component) in  /Components/actions.js'))
        }
    }
}

export const getComponent = (component) => {
    return (dispatch, getState) => {
        const definition = getState().components[component]

        if (definition) {
            
            if (definition.loading && !definition.retrieving) {
                dispatch({
                    type: types.COMPONENTS_GET_COMPONENT,
                    payload: component
                })
            }
        }
        else {
            // window.Raygun.send(new Error('Unable to find component "' + component + '" in the state tree @ getComponent(component) in  /Components/actions.js'))
        }
    }
}

export const setComponent = (data) => {
    const getComponentFieldStructure = (component) => {
        return component.component_field
            .reduce((data, y) => {
                data[y.name] = {
                    key: ''.concat('component_', component.name, '_field_', component.name),
                    input: helper.getDefaultValue(component),
                    data: [],
                    text: '',
                    validation: {
                        valid: true,
                        errors: [],
                        message: ''
                    }
                }

                return data
            }, {})
    }

    const getComponentStructure = (component) => {
        const data = component.component_field ? getComponentFieldStructure(component) : {}

        let headers = component.component_field.filter(x => x.grid).map(x => {
            return x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer'
            ?  {
                field: x.name, 
                headerName: x.title, 
                flex: 1,
                //editable: true,
                type: 'number',
                align: "center",
                renderCell: (params) => {
                    let _value = x.display ? params.row[x.display.split('.')[0]][x.display.split('.')[1]] : params.row[x.name]
                    switch (x.component_field_data_type.name) {
                        case 'timestamp':
                            _value = moment(_value).format('YYYY-DD-MM hh:mm')
                            break;
                    }
                  return _value // ? _value.replace('.',',') : 0 //<div className="rowitem">{_value}</div>;
                },
            }
            : {
                field: x.name, 
                headerName: x.title, 
                flex: 1,
                editable: true,
                renderCell: (params) => {
                    //console.log('params.row', params)
                    //console.log('params.component', component)
                    let _value = params.rowNode.type === 'group'
                        ? component?.grid?.data ? component.grid.data.filter(r => r[params.row.groupingField] == params.row.groupingKey)[0] : ''
                        : x.display ? params.row[x.display.split('.')[0]] ? params.row[x.display.split('.')[0]][x.display.split('.')[1]] : '' : ''
                    
                    //console.log('params.display', x.display)
                    //console.log('params._value', _value)
                    switch (x.component_field_data_type.name) {
                        case 'timestamp':
                            _value = moment(_value).format('YYYY-DD-MM hh:mm')
                            break;
                            case 'date':
                                _value = moment(_value).format('YYYY-DD-MM hh:mm')
                                break;
                                case 'boolean':
                                    _value = _value ? 'Yes' : 'No'
                                    break;
                    }
                  return _value //<div className="rowitem">{_value}</div>;
                },
            }
        })

        return {
            ...component,
            title: changeCase.titleCase(component.title),
            description: changeCase.sentenceCase(component.description),
            schema: {},
            info: {
                title: '',
                description: '',
                bubble: []
            },
            relatedFull: {},
            relationships: component.component_relationship
                .reduce((relationships, y) => {
                    if (y.component_through && y.component_through.component_id) {
                        relationships.push({
                            ...y.component_through,
                            linker: y.component_linker
                        })
                    }
                    else {
                        relationships.push({
                            ...y.component_linker,
                            linker: y.component_linker
                        })
                    }

                    return relationships
                }, [])
                .sort((y, z) => y.order - z.order),
            sections: component.component_field
                .reduce((sections, y) => {
                    if (y.component_section !== undefined) {
                        if (y.component_section && !sections.filter(z => z.name === y.component_section.name)[0]) {
                            sections.push({
                                ...y.component_section,
                                component_field: component.component_field
                                    .filter(z => z.component_section) //where there is actually a section
                                    .filter(z => z.component_section.name === y.component_section.name)
                            })
                        }
                    }

                    return sections
                }, [])
                .sort((y, z) => y.order - z.order),
            data: { ...data },
            empty: { ...data },
            selected: [],
            grid: {
                data: [],
                loading: true,
                headers: headers,
                paging: {
                    page: 0,
                    pageCount: 0,
                    pageSize: 10,
                    rowCount: 0
                },
                sorting: '',
                filtering: ''
            }
        }
    }

    const result = getComponentStructure(data[0])

    return (dispatch, getState) => {
        if (result.related) {
            const related = result.related.split(',')

            related.map(x => {
                let relation, parent, child

                if (x.indexOf('.') > -1) {
                    relation = x.split('.')
                    parent = relation[0]
                    child = relation[1]

                    if (parent) dispatch(getComponent(parent))
                    if (child) dispatch(getComponent(child))
                }
                else {
                    dispatch(getComponent(x))
                }
            })
        }

        if (result.view) {
            const views = result.view.split(',')

            views.map(x => {
                let relation, parent, child

                if (x.indexOf('.') > -1) {
                    relation = x.split('.')
                    parent = relation[0]
                    child = relation[1]

                    if (parent) dispatch(getComponent(parent))
                    if (child) dispatch(getComponent(child))
                }
                else {
                    dispatch(getComponent(x))
                }
            })
        }

        dispatch({
            type: types.COMPONENTS_SET_COMPONENT,
            payload: result
        })
    }
}

export const clearComponentData = (component) => {
    return (dispatch) => {
        dispatch({
            type: types.COMPONENTS_CLEAR_COMPONENT_DATA,
            payload: component
        })
    }
}