import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getBillingList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_BILLING_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/billingmodule/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setBillingList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
            , catchError((error) =>
                of$(routerActions.route('/support', false, error))
            ))

export const getFMLBillingInvoiceList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_BILLING_FML_INVOICE_LIST,
    )
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/billingmodule/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => [
                    actions.setBillingInvoiceList(result.response, payload.type),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            ))
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

export const getSTRBillingInvoiceList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_BILLING_STR_INVOICE_LIST,
    )
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/billingmodule/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => [
                    actions.setBillingInvoiceList(result.response, payload.type),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
    )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))


export const exportBillingList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.EXPORT_BILLING,
    )
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/billingmodule/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => [
                    actions.excelExport(result.response, payload.export),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
    )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

export const approvebilling = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.APPROVE_BILLING,
    )
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/billingmodule/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => [
                    actions.approvedBillingList(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
    )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))