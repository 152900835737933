export const GET_WORKFLOW_TASK_APPROVAL_INFORMATION = 'GET_WORKFLOW_TASK_APPROVAL_INFORMATION'
export const SET_WORKFLOW_TASK_APPROVAL_INFORMATION = 'SET_WORKFLOW_TASK_APPROVAL_INFORMATION'
export const CLEAR_WORKFLOW_TASK_APPROVAL_INFORMATION = 'CLEAR_WORKFLOW_TASK_APPROVAL_INFORMATION'

export const GET_WORKFLOW_TASK_APPROVAL_DATA = 'GET_WORKFLOW_TASK_APPROVAL_DATA'
export const SET_WORKFLOW_TASK_APPROVAL_DATA = 'SET_WORKFLOW_TASK_APPROVAL_DATA'
export const CLEAR_WORKFLOW_TASK_APPROVAL_DATA = 'CLEAR_WORKFLOW_TASK_APPROVAL_DATA'

export const CONFIRM_WORKFLOW_TASK_APPROVAL = 'CONFIRM_WORKFLOW_TASK_APPROVAL'

export const APPROVE_WORKFLOW_QUEUE_TASK = 'APPROVE_WORKFLOW_QUEUE_TASK'
export const APPROVE_MAIL_WORKFLOW_QUEUE_TASK = 'APPROVE_MAIL_WORKFLOW_QUEUE_TASK'
export const REJECT_WORKFLOW_QUEUE_TASK = 'REJECT_WORKFLOW_QUEUE_TASK'
export const OPEN_ESCALATION_TEAMS = 'OPEN_ESCALATION_TEAMS'
export const CLOSE_ESCALATION_TEAMS = 'CLOSE_ESCALATION_TEAMS'
export const ENCHOR_ESCALATION_TEAMS = 'ENCHOR_ESCALATION_TEAMS'
export const OPEN_REJECT_STEP = 'OPEN_REJECT_STEP'
export const CLOSE_REJECT_STEP = 'CLOSE_REJECT_STEP'
export const ESCALATION_TASK_TO_NEW_TEAMS = 'ESCALATION_TASK_TO_NEW_TEAMS'
export const EDIT_QUOTE_GET_DOCUMENT = 'EDIT_QUOTE_GET_DOCUMENT'
export const EDIT_QUOTE_SET_DOCUMENT = 'EDIT_QUOTE_SET_DOCUMENT'
export const EDIT_QUOTE_SET_PREVIEW_DOCUMENT_BYTES = 'EDIT_QUOTE_SET_PREVIEW_DOCUMENT_BYTES'
export const CLEAR_EDIT_QUOTE_DOCUMENT = 'CLEAR_EDIT_QUOTE_DOCUMENT'
export const CONFIRM_QUOTE_EDIT_ITEMS_SELECTION = 'CONFIRM_QUOTE_EDIT_ITEMS_SELECTION'
export const OPEN_VIEW_PDF_DOCUMENT = 'OPEN_VIEW_PDF_DOCUMENT'
export const CLOSE_VIEW_PDF_DOCUMENT = 'CLOSE_VIEW_PDF_DOCUMENT'
export const EDIT_QUOTE_GET_RFQ_DOCUMENT = 'EDIT_QUOTE_GET_RFQ_DOCUMENT'
export const DOWNLOAD_QUOTE_REVIEW_RFQ_DOCUMENTS = 'DOWNLOAD_QUOTE_REVIEW_RFQ_DOCUMENTS'
export const VIEW_DOWNLOAD_QUOTE_REVIEW_RFQ_DOCUMENTS = 'VIEW_DOWNLOAD_QUOTE_REVIEW_RFQ_DOCUMENTS'
export const DOWNLOAD_REVIEW_SCHEDULE_DOCUMENTS = 'DOWNLOAD_REVIEW_SCHEDULE_DOCUMENTS'

export const GET_NEXT_WORKFLOW_TEAM_USERS = 'GET_NEXT_WORKFLOW_TEAM_USERS'
export const SET_NEXT_WORKFLOW_TEAM_USERS = 'SET_NEXT_WORKFLOW_TEAM_USERS'
export const SET_APPROVAL_CONFIRM_TOGGLE_MENU_STATUS = 'SET_APPROVAL_CONFIRM_TOGGLE_MENU_STATUS'

export const GET_WORKFLOW_TASK_CONNECTOR_DATA = 'GET_WORKFLOW_TASK_CONNECTOR_DATA'
export const SET_WORKFLOW_TASK_CONNECTOR_DATA = 'SET_WORKFLOW_TASK_CONNECTOR_DATA'