import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import Checkbox from '../../../controls/checkbox'
import Button from "@mui/material/Button";
import Icon from '../../Icon/component'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import { Table } from '@mui/material'

import * as styles from './styles'
import * as actions from './actions'
import moment from 'moment';
import TextField from '@mui/material/TextField'
import Upload from '../../Upload/component'
import BillingReview from './reviewgrid'

import ReactQuill from 'react-quill'

class ReviewGrid extends Component {
    componentDidMount() {
        ///this.props.actions.reset()
    }

    getTotal(data, prop) {
        var sum = 0
        for(let x of data) {
            sum = sum + parseFloat(x[prop].toString().replace(/,/g, ''))
        }
        return this.getField(sum, 'numeric')
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : ''
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }

    getGrid() {
        return <AdhocGrid
            key={'adhoc_grid_billing_review_data'}
            name={'adhoc_grid_billing_review_data'}
            data={this.props.state.data}
            loading={this.props.state.data.length ? false : true}
            filterable={false}
            sortable={false}
            body={{
                collection: 'billing_review_data',
                searchText: ''
            }}
            height={'1000px'}
            search={''}
            columns={[
                {
                    accessor: 'invoicenumber', Header: 'Document', filterable: false, width: 150, Cell: row => {
                        return (
                            <div>
                                <Button className={"global_button"} variant="contained"
                                    primary={true}
                                    label="Preview"
                                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                                    onClick={(event) => {
                                        this.props.actions.previewInvoice(row.original['billingrunview_id'])
                                    }}
                                    >{window.t.en('Preview')}</Button>
                            </div>
                        )
                    }
                },
                { accessor: 'customername', Header: 'Debtor Name', width: 300, filterable: true },
                { accessor: 'licenseplateno', Header: 'Vehicle ', width: 120, filterable: true },
                { accessor: 'contractnumber', Header: 'Contract Number', width: 160, filterable: true },
                // { accessor: 'customertransactiondate', Header: 'Month', width: 160, filterable: true },S
                { accessor: 'startdate', Header: 'Invoice Start Date', width: 150, filterable: true, type: 'date' },
                { accessor: 'enddate', Header: 'Invoice End Date', width: 150, filterable: true },
                { accessor: 'invoicenumber', Header: 'Invoice Number', width: 150, filterable: true },
                { accessor: 'nettvalue', Header: 'Nett Value', width: 100, filterable: true },
                { accessor: 'vat', Header: 'VAT', width: 100, filterable: true },
                { accessor: 'amount', Header: 'Amount', width: 100, filterable: true },
            ]}
            paging={{
                page: 0,
                pageSize: 10,
                pages: 0,
                count: 0
            }}
            subComponent={row => {
                //console.log('row',row)
                return <div key={'grid_billing_module_data_approved_' + row.original['customertransaction_id']} style={{ padding: "2px" }}>
                    <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12} style={styles.title}>
                            {'Maintenance Progress Steps'}
                        </Col>
                        <Col xl={12}>  
                            <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                            <tr>
                                <th>Description</th>
                                <th>Account</th>
                                <th>Quantity</th>
                                <th>Unit</th>
                                <th>Unit Price</th>
                                <th>Amount</th>
                                <th>VAT</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    row.original.items.map(x => {
                                        return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.description}</td>
                                            <td>{x.itemname}</td>
                                            <td>{this.getField(x.quantity, 'numeric')}</td>
                                            <td>{x.unitmeasure}</td>
                                            <td>{this.getField(x.unitprice, 'numeric')}</td>
                                            <td>{this.getField(x.totalamount, 'numeric')}</td>
                                            <td>{this.getField(x.vat, 'numeric')}</td>
                                            <td>{this.getField((Number(x.totalamount) + Number(x.vat)), 'numeric')}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                            <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{_.sumBy(row.original.items, (d) => { return parseFloat(Number(d.unitprice)) }).toFixed(2)}</th>
                                <th>{_.sumBy(row.original.items, (d) => { return parseFloat(Number(d.totalamount)) }).toFixed(2)}</th>
                                <th>{_.sumBy(row.original.items, (d) => { return parseFloat(Number(d.vat)) }).toFixed(2)}</th>
                                <th>{_.sumBy(row.original.items, (d) => { return parseFloat(Number(d.totalamount) + Number(d.vat))}).toFixed(2)}</th>
                            </tr>
                            </thead>
                        </Table>
                        </Col>
                    </Row>
                    </Container>
                    </div>    
            }}
        />
    }

    getRefreshing () {
        return  <Row>
            <Col xl={12}>
                <div>
                    <h4>Billing Refreshing:</h4>
                    <ul>
                        <li>This will take couple of minutes</li>
                    </ul>
                    <i>We will let you know once the process is completed.</i>
                </div>
            </Col>
        </Row>
    }

    getLayout() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>{this.props.state.refreshing ? this.getRefreshing() : this.getGrid()}</Col>
            </Row>
        </Container>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.billingrefresh,
            user: {
                ...state.user
            },
            upload: {
                ...state.upload
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewGrid)