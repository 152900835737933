import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.ADHOC_GRID_LOAD_TO_STATE_TREE:
            return {
                ...state,
                [action.payload.name]: action.payload
            }
        case types.SET_COMPONENT_DATA_INPUT_VALUE:
            return {
                ...state,
                [action.payload.parent]: {
                    ...state[action.payload.parent],
                    data: action.payload.data,
                }
            }
        case types.ADHOC_GRID_GET_DATA:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    loading: true
                }
            }
        case types.ADHOC_GRID_SET_DATA:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    data: action.payload.data,
                    paging: action.payload.paging,
                    loading: false
                }
            }
        default:
            return state
    }
}