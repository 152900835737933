import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_FML_QUOTATION_GM_APPROVAL_INPUT_VALUE:
         return {
             ...state,
             [action.payload.prop]: action.payload.value 
         }
        default:
            return state
    }
}