import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.ADD_CALCULATOR:
            return {
                ...state,
                calculators: [...state.calculators, state.calculators.length]
            }
        case types.REMOVE_CALCULATOR:
            return {
                ...state,
                calculators: state.calculators.filter(x => x !== action.payload).sort()
            }
        default:
            return state
    }
}