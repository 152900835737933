import * as types from './types'

export const setupWorkflowTaskInput = (data, component, step) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CONFIRM_STR_HEADER_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: data
            }
        })
    }
}

// export const loadContractTypes = (component, step) => {
//     return (dispatch, getState) => {
//         let contracttype = step === 'stp_fml_quote_capture_quote_details'
//             ? 'Full Maintenance Lease'
//             : 'Short Term Rental'

//         const field = component.component_field.filter(x => x.name === 'contracttype_id')[0]
//         const payload = {
//             component: component,
//             model: 'contracttype',
//             field: field,
//             related: '',
//             query: '?contracttype=' + contracttype,
//         }

//         dispatch({
//             type: types.GET_COMPONENT_FIELD_LOOKUP_VALUE,
//             payload: payload
//         })
//     }
// }

// export const setStartdate = () => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'startdate',
//                 value: new Date()
//             }
//         })
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'enddate',
//                 value: new Date()
//             }
//         })
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'months',
//                 value: 0
//             }
//         })
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'days',
//                 value: 0
//             }
//         })
//     }
// }

// export const removeCachedGridRow = (component) => {

//     return (dispatch, getState) => {
//         dispatch({
//             type: types.CLEAR_QUOTE_VEHICLE_BLANK_ROW,
//             payload: {
//                 component: component.name
//             }
//         })

//         dispatch({
//             type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
//             payload: {
//                 component: 'document',
//                 field: 'content',
//                 value: '',
//             }
//         })

//         dispatch({
//             type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
//             payload: {
//                 component: 'surrogateheader',
//                 field: 'replace_vehicle',
//                 data: []
//             }
//         })
//     }
// }

// export const setValidationState = (validation) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_COMPONENT_VALIDATION_PROPS_FIELDS,
//             payload: validation
//         })
//     }
// }


// export const clearRFQDocument = (component) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
//             payload: {
//                 component: 'document',
//                 field: 'content',
//                 value: '',
//             }
//         })
//     }
// }

// export const toggleCustomerValues = (component, toggle) => {

//     return (dispatch, getState) => {
//         dispatch({
//             type: types.TOGGLE_CUSTOMER_VALUES,
//             payload: {
//                 component: component.name,
//                 state: toggle
//             }
//         })
//     }
// }

// export const toggleProps = (props, toggle) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.TOGGLE_CUSTOM_PROPS_VALUES,
//             payload: {
//                 props: props,
//                 state: toggle
//             }
//         })
//     }
// }

// export const togglePropsOp = (props, toggle) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.TOGGLE_OPS_CUSTOM_PROPS_VALUES,
//             payload: {
//                 props: props,
//                 state: toggle
//             }
//         })
//     }
// }

// export const toggleRateCard = (groupcomponent, mmcodefield, field, toggle) => {
//     var now = new Date();
//     var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_COMPONENT_LOOKUP_INPUT_VALUE,
//             payload: {
//                 component: field.component.name,
//                 field: field,
//                 value: toggle
//             }
//         })

//         if (!toggle) {

//             const component = getState().components[field.component.name]
//             dispatch({
//                 type: types.GET_RATECARD_COMPONENT_LOOKUP_DATA,
//                 payload: {
//                     component: component,
//                     field: mmcodefield.name,
//                     related: 'mmcode',
//                     query: '?contractvehiclecategory_id=' + component.data.contractvehiclecategory_id.input,
//                 }
//             })
//         }
//         else {
//             dispatch({
//                 type: types.QUOTE_VEHICLE_BLANK_ROW,
//                 payload: {
//                     component: field.component.name,
//                     data: []
//                 }
//             })
//         }
//     }
// }

// export const setRatecardMMCodes = (component, field, payload) => {

//     const getClosest = _.curry((counts, goal) => {
//         return counts
//             .reduce((prev, curr) => Math.abs(curr.distancepermonth - goal) < Math.abs(prev.distancepermonth - goal) ? curr : prev);
//     });

//     var findClosest = (x, arr) => {
//         var indexArr = arr.map(function (k) { return Math.abs(k - x) })
//         var min = Math.min.apply(Math, indexArr)
//         return arr[indexArr.indexOf(min)]
//     }


//     let list = []
//     const data = payload.data.map(x => {
//         return {
//             retailprice: parseFloat(x.retailprice),
//             vehicleprice: parseFloat(x.vehicleprice),
//             discount: parseFloat(x.discount),
//             mm_code: x.mmcode_fktext,
//             mmcode_id: x.mmcode_id,
//             mmcode_fktext: x.mmcode.makemodelyear,
//             distancepermonth: x.distancepermonth,
//             deliveryperiod: parseInt(x.deliveryperiod),
//             rvpercentage: parseInt(x.residualvalue),
//             rv_amount: (parseInt(x.residualvalue) / 100 * parseFloat(x.vehicleprice)).toFixed(2),
//             primerate: parseFloat(x.primerate),
//             deviation: parseFloat(x.deviation),
//             maintcpk: parseFloat(x.mmcode.maintcpk),
//             tyrecpk: parseFloat(x.mmcode.tyrescpk),
//             manualtotalmaintcpk: parseFloat(x.manualtotalmaintcpk),
//             financeexcess: parseFloat(x.financeexcess),
//             interestrate: parseFloat(x.primerate) + parseFloat(x.deviation),
//         }

//     })

//     const mmcodes = data.map(x => {
//         return x.mmcode_id
//     })

//     const distinct = Array.from(new Set(mmcodes))

//     for (let x of distinct) {
//         const _filter = data.filter(j => j.mmcode_id === x)
//         list.push(getClosest(_filter, component.data.distancepermonth.input))
//     }

//     return (dispatch, getState) => {
//         dispatch({
//             type: types.QUOTE_VEHICLE_BLANK_ROW,
//             payload: {
//                 component: component.name,
//                 data: list
//             }
//         })
//     }
// }

// export const toggleStockVehicle = (field, toggle) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_COMPONENT_LOOKUP_INPUT_VALUE,
//             payload: {
//                 component: field.component.name,
//                 field: field,
//                 value: toggle,
//             }
//         })
//     }
// }

// export const getLookupValueData = (component, field, search) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 field: field,
//                 component: component,
//                 query: field + '=' + search,
//             }
//         })
//     }
// }

// export const setLookupValueData = (component, field, payload) => {
//     return (dispatch, getState) => {

//         window.glyco.log('setLookupValueData')
//         window.glyco.log(payload.data)
//         const data = payload.data.map(x => {
//             return {
//                 value: x[component + '_id'],
//                 text:  x[field]
//             }
//         })

//         window.glyco.log(data)
//         dispatch({
//             type: types.SET_COMPONENT_LOOKUP_DATA_VALUES,
//             payload: {
//                 prop: component + '_data',
//                 value: data,
//             }
//         })
//     }
// }

// export const setLookupValue = (field, value, text) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field + '_id',
//                 value: value,
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: text,
//             }
//         })
//     }
// }

// export const getLookupSelectionCategory = (model, field, search) => {
//     return (dispatch, getState) => {

//         const component = getState().components[field.component.name]

//         const payload = {
//             component: component,
//             model: model,
//             field: field,
//             related: '',
//             query: '?categorydescription=' + search,
//         }

//         dispatch({
//             type: types.GET_WQH_COMPONENT_FIELD_LOOKUP_DATA,
//             payload: {
//                 component: component,
//                 model: model,
//                 field: field,
//                 related: '',
//                 query: '?categorydescription=' + search,
//             }
//         })
//     }
// }

// export const searchLookupValue = (model, field, search) => {
//     return (dispatch, getState) => {

//         const component = getState().components[field.component.name]

//         const payload = {
//             component: component,
//             model: model,
//             field: field,
//             related: '',
//             query: '?licenseplateno=' + search,
//         }

//         dispatch({
//             type: types.GET_WQH_COMPONENT_FIELD_LOOKUP_DATA,
//             payload: payload
//         })
//     }
// }

// export const setComponentFieldLookupData = (component, field, payload) => {

//     const fields = field.display.split('|')
//     const accessor = fields[0].split('.')[0]

//     const data = payload.data.map(x => {
//         return {
//             value: x[field],
//             text: fields.map(y => {
//                 return y.split(',').map(z => {
//                     return get(x, z.replace(accessor + '.', ''))
//                 }).join(' ')
//             }).join(', ')
//         }
//     })

//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
//             payload: {
//                 component: field.component.name,
//                 field: field,
//                 data: data
//             }
//         })
//     }
// }


// export const setComponentFieldLookupValue = (component, field, payload) => {

//     const fields = field.display.split('|')
//     const accessor = fields[0].split('.')[0]

//     const data = payload.data.map(x => {
//         return {
//             value: x[field],
//             text: fields.map(y => {
//                 return y.split(',').map(z => {
//                     return get(x, z.replace(accessor + '.', ''))
//                 }).join(' ')
//             }).join(', ')
//         }
//     })

//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: data[0].value
//             }
//         })
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: data[0].text
//             }
//         })
//     }
// }
// export const strBlankRow = (component) => {

//     return (dispatch, getState) => {
//         const contracttype_id = getState().components['contracttype'].data.contracttype_id.input
//         var now = new Date();
//         var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
//         const payload = {
//             component: component.name,
//             data: {
//                 surrogateheader_id: 0,
//                 startdate: moment(current).format('DD-MM-YYYY'),
//                 contractperiod: null,
//                 discount: null,
//                 deliveryperiod: null,
//                 contractdistance: null,
//                 contracttype_id: contracttype_id,
//                 interestrate: null,
//                 vehicleprice: null,
//                 distancepermonth: null,
//                 rvpercentage: null,
//                 deviation: null,
//                 contractvehiclecategory_id: null,
//                 mmcode_id: null,
//                 mmcode_fktext: null,
//                 tyrecpk: null,
//                 maintcpk: null,
//                 manualtotalmaintcpk: null,
//                 financeexcess: null,
//                 vehicle_fktext: null,
//                 vehicle_id: null //component.data.vehicle_id.input,
//             },
//         }

//         dispatch({
//             type: types.QUOTE_VEHICLE_BLANK_ROW,
//             payload: payload
//         })

//         // dispatch({
//         //     type: types.GET_QUOTE_VEHICLE_DETAILS,
//         //     payload: payload
//         // })
//     }
// }

// export const newBlankRow = (component) => {
//     let data = component.grid.data
//     var now = new Date();
//     var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
//     const payload = {
//         component: component.name,
//         data: data.concat({
//             surrogateheader_id: data.length,
//             startdate: moment(current).format('DD-MM-YYYY'),
//             contractperiod: null,
//             discount: null,
//             deliveryperiod: null,
//             contractdistance: null,
//             contracttype_id: component.data.contracttype_id.input,
//             interestrate: null,
//             vehicleprice: null,
//             distancepermonth: null,
//             rvpercentage: null,
//             deviation: null,
//             contractvehiclecategory_id: component.data.contractvehiclecategory_id.input,
//             mmcode_id: null,
//             mmcode_fktext: null,
//             tyrecpk: null,
//             maintcpk: null,
//             manualtotalmaintcpk: null,
//             financeexcess: null,
//             vehicle_fktext: null,
//             vehicle_id: null //component.data.vehicle_id.input,
//         }),
//     }

//     if (!data.length) {
//         return (dispatch, getState) => {
//             dispatch({
//                 type: types.QUOTE_VEHICLE_BLANK_ROW,
//                 payload: payload
//             })

//             dispatch({
//                 type: types.GET_QUOTE_VEHICLE_DETAILS,
//                 payload: payload
//             })
//         }
//     }
// }

// export const addBlankRow = (component) => {
//     let data = component.grid.data
//     var now = new Date();
//     var current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
//     const payload = {
//         component: component.name,
//         data: data.concat({
//             surrogateheader_id: data.length,
//             startdate: moment(current).format('DD-MM-YYYY'),
//             contractperiod: null,
//             discount: null,
//             deliveryperiod: null,
//             contractdistance: null,
//             contracttype_id: component.data.contracttype_id.input,
//             interestrate: null,
//             vehicleprice: null,
//             distancepermonth: null,
//             rvpercentage: null,
//             deviation: null,
//             contractvehiclecategory_id: component.data.contractvehiclecategory_id.input,
//             mmcode_id: null,
//             mmcode_fktext: null,
//             tyrecpk: null,
//             maintcpk: null,
//             manualtotalmaintcpk: null,
//             financeexcess: null,
//             vehicle_fktext: null,
//             vehicle_id: null,
//             mm_code: null,
//             retailprice: null,
//             quotefor_id: null,
//         }),
//     }

//     if (data.length < 3) {
//         return (dispatch, getState) => {
//             dispatch({
//                 type: types.QUOTE_VEHICLE_BLANK_ROW,
//                 payload: payload
//             })

//             dispatch({
//                 type: types.GET_QUOTE_VEHICLE_DETAILS,
//                 payload: payload
//             })
//         }
//     }
// }

// export const setQuoteVehicleDetails = (component, field, payload) => {

//     const fields = field.display.split('|')
//     const accessor = fields[0].split('.')[0]

//     const data = payload.data.map(x => {
//         return {
//             value: x[field],
//             text: fields.map(y => {
//                 return y.split(',').map(z => {
//                     return get(x, z.replace(accessor + '.', ''))
//                 }).join(' ')
//             }).join(', ')
//         }
//     })

//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
//             payload: {
//                 component: field.component.name,
//                 field: field,
//                 data: data
//             }
//         })
//     }
// }

// export const setWorkings = (component, field, payload) => {

//     const data = {
//         component: component,
//         data: payload.data ? [payload.data[0]] : []
//     }


//     return (dispatch, getState) => {
//         dispatch({
//             type: types.QUOTE_VEHICLE_BLANK_ROW,
//             payload: data
//         })
//     }
// }

// export const confirmSelection = (step, component) => {

//     return (dispatch, getState) => {

//         const data = [{
//             form: {
//                 contracttype_fktext: component.data.contracttype_id.text,
//                 customer_fktext: component.data.customer_id.text,
//                 vehicleprice: component.data.vehicleprice.input,
//                 retailprice: component.data.retailprice.input,
//                 distancepermonth: component.data.distancepermonth.input,
//                 rvpercentage: component.data.rvpercentage.input,
//                 mmcode_id: component.data.mmcode_id.input,
//                 vehicle_id: component.data.vehicle_id.input,
//                 surrogateheader_id: component.data.surrogateheader_id.input,
//                 mmcode_fktext: component.data.mmcode_id.text,
//                 mm_code: component.data.mm_code.input,
//                 vehicle_fktext: component.data.vehicle_id.text,
//                 startdate: component.data.startdate.input,
//                 contractperiod: component.data.contractperiod.input,
//                 rv_amount: component.data.rv_amount.input,
//                 interestrate: component.data.interestrate.input,
//                 tyrecpk: component.data.tyrecpk.input,
//                 comments: component.data.comments.input,
//                 service_tot: component.data.service_tot.input,
//                 tyres: component.data.tyres.input,
//                 adminfee: component.data.adminfee.input,
//                 monthly_vat: component.data.monthly_vat.input,
//                 waiver: component.data.waiver.input,
//                 quotedate: component.data.quotedate.input,
//                 quotenumber: component.data.quotenumber.input,
//                 contractvehiclecategory_id: component.data.contractvehiclecategory_id.input,
//                 supplier_id: component.data.supplier_id.input,
//                 contractvehiclecategory_fktext: component.data.contractvehiclecategory_id.text,
//                 replace_vehicle: component.data.replace_vehicle.input,
//                 supplier_fktext: component.data.supplier_fktext.input,
//                 deliveryperiod: component.data.deliveryperiod.input,
//                 contractdistance: component.data.contractdistance.input,
//                 discount: component.data.discount.input,
//                 deviation: component.data.deviation.input,
//                 rfq: component.data.rfq.input,
//                 accessory_tot: component.data.accessory_tot.input,
//                 finance: component.data.finance.input,
//                 licence: component.data.licence.input,
//                 roi: component.data.roi.input,
//                 monthly_incl: component.data.monthly_incl.input,
//                 stock_vehicle: component.data.stock_vehicle.input,
//                 months: component.data.months.input,
//                 customer_id: component.data.customer_id.input,
//                 primerate: component.data.primerate.input,
//                 maintcpk: component.data.maintcpk.input,
//                 pdf: component.data.pdf.input,
//                 sundry_tot: component.data.sundry_tot.input,
//                 maint: component.data.maint.input,
//                 service: component.data.service.input,
//                 monthly_excl: component.data.monthly_excl.input,
//                 monthly_str: component.data.monthly_str.input,
//                 enddate: component.data.enddate.input,
//                 days: component.data.days.input,
//                 contracttype_id: component.data.contracttype_id.input,
//                 manualtotalmaintcpk: component.data.manualtotalmaintcpk.input,
//                 financeexcess: component.data.financeexcess.input,
//                 accesskm: component.data.accesskm.input,
//                 validity: component.data.validity.input,
//                 quotefor_id: component.data.quotefor_id.input,

//             },
//             grid: component.grid.data
//         }]

//         const payload = {
//             step: step,
//             component: component.name,
//             selected: data
//         }

//         dispatch({
//             type: types.CONFIRM_QUOTE_HEADER_SELECTION,
//             payload: payload
//         })
//     }
// }

// export const resetSelection = (step, component) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.RESET_QUOTE_HEADER_SELECTION,
//             payload: {
//                 step: step,
//                 component: component,
//                 selected: []
//             }
//         })
//     }
// }

// export const setInputValue = (field, value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: value
//             }
//         })
//     }
// }

// export const decimalFormat = (field, value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: parseInt(value).toLocaleString()
//             }
//         })
//     }
// }

// export const setEndMonth = (field, value) => {
//     return (dispatch, getState) => {
//         let enddate = getState().captureSTR.startdate.value
//         let days = getState().captureSTR.days.value
//         enddate = moment(enddate).add(value, 'months');
//         enddate = moment(enddate._d).add(days, 'days');
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: value
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'enddate',
//                 value: enddate._d
//             }
//         })
//     }
// }


// export const setEndDays = (field, value) => {
//     return (dispatch, getState) => {
//         let months = getState().captureSTR.months.value
//         let enddate = getState().captureSTR.startdate.value
//         enddate = moment(enddate).add(value, 'days');
//         enddate = moment(enddate._d).add(months, 'months');
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: value
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'enddate',
//                 value: enddate._d
//             }
//         })
//     }
// }

// export const finValue = (surrogateitems) => {
//     return (dispatch, getState) => {

//         const component = getState().captureSTR

//         const _months = component.months.value
//         const _days = component.days.value
//         const _finance = component.rentalamount.value

//         const _accessory = surrogateitems ? surrogateitems.grid.data.filter(x => x.itemtype === 'Accessories') : 0
//         const _sundry = surrogateitems ? surrogateitems.grid.data.filter(x => x.itemtype === 'Sundry') : 0
//         const accessory_tot = _.sumBy(_accessory, (d) => { return parseFloat((d.totalprice) ? d.totalprice : 0) }).toFixed(2)
//         const sundry_tot = _.sumBy(_sundry, (d) => { return parseFloat((d.totalprice) ? d.totalprice : 0) }).toFixed(2)

//         const monthly_str = (parseFloat(_finance * _months) + parseFloat(((_months > 0 ? (_finance / 30) : _finance) * _days))).toFixed(2)
//         const monthly_excl = (parseFloat(monthly_str) + parseFloat(accessory_tot) + parseFloat(sundry_tot)).toFixed(2)

//         let monthly_vat = parseFloat(monthly_excl * 0.15).toFixed(2)
//         let monthly_incl = parseFloat(monthly_excl * 1.15).toFixed(2)

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'accessory_tot',
//                 value: accessory_tot
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'sundry_tot',
//                 value: sundry_tot
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_str',
//                 value: monthly_str
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_excl',
//                 value: monthly_excl
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_vat',
//                 value: monthly_vat
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_incl',
//                 value: monthly_incl
//             }
//         })
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'waiver_tot',
//                 value: 0.00
//             }
//         })
//     }
// }

// export const accValue = (field, fin, acc, sun, waiver) => {
//     return (dispatch, getState) => {

//         let _waiver = waiver ? waiver : 0
//         const sum = (parseFloat(fin) + parseFloat(acc) + parseFloat(sun) + parseFloat(_waiver)).toFixed(2)
//         let subtotal = sum
//         let vat = parseFloat(sum * 0.15).toFixed(2)
//         let total = parseFloat(sum * 1.15).toFixed(2)

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: acc
//             }
//         })


//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_excl',
//                 value: subtotal
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_vat',
//                 value: vat
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_incl',
//                 value: total
//             }
//         })
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'waiver_tot',
//                 value: parseFloat(_waiver)
//             }
//         })
//     }
// }

// export const sunValue = (field, fin, acc, sun, waiver) => {
//     return (dispatch, getState) => {

//         let _waiver = waiver ? waiver : 0
//         const sum = (parseFloat(fin) + parseFloat(acc) + parseFloat(sun) + parseFloat(_waiver)).toFixed(2)
//         let subtotal = sum
//         let vat = parseFloat(sum * 0.15).toFixed(2)
//         let total = parseFloat(sum * 1.15).toFixed(2)

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: sun
//             }
//         })


//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_excl',
//                 value: subtotal
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_vat',
//                 value: vat
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'monthly_incl',
//                 value: total
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'waiver',
//                 value: parseFloat(_waiver)
//             }
//         })
//     }
// }

// export const calcEndDate = (field, value) => {
//     return (dispatch, getState) => {
//         const component = getState().captureSTR
//         const _months = component.months.value
//         const _days = component.days.value

//         let enddate = component.startdate.value
//         enddate = moment(enddate).add(_days, 'days');
//         enddate = moment(enddate._d).add(_months, 'months');
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: value
//             }
//         })

//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'enddate',
//                 value: enddate._d
//             }
//         })
//     }
// }

// export const calcPeriod = (fromdate, todate) => {
//     return (dispatch, getState) => {
//         const _fromdate = moment(fromdate) //.format('YYYY-MM-DD')
//         const _todate = moment(todate) //.format('YYYY-MM-DD')
//         const _months = _todate.diff(_fromdate, "months")
//         let _days = _todate.diff(_fromdate, "days") + 1
//         _days = (_days - (_months * 30))
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'months',
//                 value: _months
//             }
//         })
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'days',
//                 value: _days
//             }
//         })
//     }
// }

// export const getQuotation = (field, value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SEARCH_WORKINGS_QUOTATION,
//             payload: {
//                 component: 'customerquote',
//                 field: field,
//                 query: '?quotenumber=' + value
//             }
//         })
//     }
// }

// export const calcContractDistance = (distancepermonth, contractperiod, field) => {
//     const distance = (distancepermonth && contractperiod) ? (parseInt(distancepermonth.toString().replace(/,/g, '')) * parseInt(contractperiod)) : 0
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: field,
//                 value: distance ? distance : ''
//             }
//         })
//     }
// }

// export const setAcceptedDocuments = (component, documents) => {
//     const rfq = 'RFQ_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
//     return (dispatch, getState) => {
//         documents.forEach(file => {
//             const reader = new FileReader();
//             reader.readAsArrayBuffer(file);
//             reader.onload = (e) => {
//                 let fileAsBinaryString = reader.result;
//                 dispatch({
//                     type: types.UPLOAD_RFQ_QUOTE_DOCUMENT,
//                     payload: {
//                         content: fileAsBinaryString,
//                         filename: rfq,
//                         type: file.type,
//                     }
//                 })
//             };
//         });
//         dispatch({
//             type: types.SET_STR_COMPONENT_FIELD_INPUT_VALUE,
//             payload: {
//                 prop: 'rfq',
//                 value: rfq
//             }
//         })

//         dispatch({
//             type: types.SET_RFQ_QUOTE_DOCUMENT_PREVIEW,
//             payload: {
//                 component: component.name,
//                 preview: documents[0].preview,
//                 filename: documents[0].name,
//             }
//         })
//     }
// }

// export const setRejectedDocuments = (component, documents) => {

//     return (dispatch, getState) => {
//         dispatch({
//             type: types.CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW,
//             payload: {
//                 component: component.name
//             }
//         })
//     }
// }

// export const laodComponentStepData = (step, component) => {
//     return (dispatch, getState) => {
//         const data = getState().workflowTaskInput.task.workflow_task_step
//             .filter(x =>
//                 x.name === step &&
//                 x.workflow_task_step_component.filter(y => y.component.name === component.name).length
//             )
//             .reduce((arr, x) => {
//                 if (x.workflow_task_step_component.filter(y => y.component.name === component.name && y.data.length).length) {
//                     arr.push(x.workflow_task_step_component.filter(y => y.component.name === component.name && y.data.length)[0].data)
//                 }

//                 return arr
//             }, [])

//         if (data.length) {
//             if (component.name === 'surrogateheader') {
//                 const payload = {
//                     component: component.name,
//                     data: data[0][0].grid
//                 }

//                 dispatch({
//                     type: types.QUOTE_VEHICLE_BLANK_ROW,
//                     payload: payload
//                 })
//             }
//             else {
//                 const payload = {
//                     component: component.name,
//                     selected: [{
//                         original: data[0][0]
//                     }]
//                 }

//                 dispatch({
//                     type: types.LOAD_COMPONET_GRID_SELECTED_DATA,
//                     payload: payload
//                 })
//             }
//         }
//     }
// }

// export const getVehicleOptions = (component, field, value, text) => {
//     return (dispatch, getState) => {

//         dispatch({
//             type: types.SET_COMPONENT_LOOKUP_INPUT_VALUE,
//             payload: {
//                 component: field.component.name,
//                 field: field,
//                 value: value,
//             }
//         })

//         dispatch({
//             type: types.SET_COMPONENT_FIELD_INPUT_TEXT,
//             payload: {
//                 component: field.component.name,
//                 field: field,
//                 value: text,
//             }
//         })

//         const payload = {
//             component: component.name,
//             model: 'contractvehiclecategory',
//             query: '?contractvehiclecategory_id=' + value,
//         }
//         dispatch({
//             type: types.GET_CONTRACT_CATEGORY_VEHICLE_OPTIONS,
//             payload: {
//                 component: component.name,
//                 model: 'contractvehiclecategory',
//                 query: '?contractvehiclecategory_id=' + value,
//             }
//         })
//     }
// }

// export const setVehicleOptions = (component, payload) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_CONTRACT_CATEGORY_VEHICLE_OPTIONS,
//             payload: {
//                 component: component.name,
//                 data: {
//                     component_id: 156,
//                     item_id: 10,
//                     itemtype: 'Service',
//                     itemname: 'Speed Buzzer',
//                     quantity: 1,
//                     quotetype_id: 156,
//                     surrogateitem_id: 1,
//                     totalprice: 16.05,
//                     unitprice: 16.05,
//                 }
//             }
//         })
//     }
// }

// export const getVehicleContractDetails = (field, value) => {
//     return (dispatch, getState) => {

//         dispatch({
//             type: types.GET_VEHICLE_CONTRACT_DETAILS,
//             payload: {
//                 field: field,
//                 value: value,
//                 related: 'vehicle'
//             }
//         })
//     }
// }

// export const setVehicleContractDetails = (field, payload) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
//             payload: {
//                 component: field.component.name,
//                 field: field,
//                 data: payload.data ? payload.data : []
//             }
//         })
//     }
// }

// export const getCategoryChipAccessories = (value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA,
//             payload: {
//                 value: value
//             }
//         })
//     }
// }

// export const setCategoryChipAccessories = (payload) => {
//     return (dispatch, getState) => {
//         const data = payload.data.map((x, i) => {
//             return {
//                 component_id: 161,
//                 item_id: x.accessory.accessory_id,
//                 itemtype: 'Accessories',
//                 itemname: x.accessory.accessoryname,
//                 quantity: 1,
//                 quotetype_id: 161,
//                 surrogateitem_id: i,
//                 totalprice: parseFloat(x.accessory.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 costprice: parseFloat(x.accessory.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 sellingprice: parseFloat(x.accessory.retailprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//           }
//         })
//         dispatch({
//             type: types.SET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA,
//             payload: data
//         })
//     }
// }

// export const getContractMandatoryAccessory = (value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_VEHICLE_DEFAULT_ACCESSORY_LIST,
//             payload: {
//                 value: value
//             }
//         })
//     }
// }

// export const setContractMandatoryAccessory = (payload) => {
//     return (dispatch, getState) => {
//         const data = payload.data.map((x, i) => {
//             return {
//                 component_id: 161,
//                 item_id: x.accessory.accessory_id,
//                 itemtype: 'Accessories',
//                 itemname: x.accessory.accessoryname,
//                 quantity: 1,
//                 quotetype_id: 161,
//                 surrogateitem_id: i,
//                 totalprice: parseFloat(x.accessory.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 costprice: parseFloat(x.accessory.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 sellingprice: parseFloat(x.accessory.retailprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//             }
//         })

//         dispatch({
//             type: types.SET_VEHICLE_DEFAULT_ACCESSORY_LIST,
//             payload: {
//                 component: 'surrogateitems',
//                 data: data
//             }
//         })
//     }
// }

// export const getContractMandatoryService = (value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_VEHICLE_DEFAULT_ACCESSORY_LIST,
//             payload: {
//                 value: value
//             }
//         })
//     }
// }

// export const setContractMandatoryService = (payload) => {
//     return (dispatch, getState) => {
//         const data = payload.data.map((x, i) => {
//             return {
//                 component_id: 161,
//                 item_id: x.service.service_id,
//                 itemtype: 'Service',
//                 itemname: x.service.servicedescription,
//                 quantity: 1,
//                 quotetype_id: 161,
//                 surrogateitem_id: i,
//                 totalprice: parseFloat(x.service.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 costprice: parseFloat(x.service.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 sellingprice: parseFloat(x.service.sellingprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//             }
//         })
//         dispatch({
//             type: types.SET_VEHICLE_SERVICE_SELECTION_CATEGORY_LOOKUP_DATA,
//             payload: data
//         })
//     }
// }

// export const getContractMandatorySundry = (value) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_VEHICLE_DEFAULT_ACCESSORY_LIST,
//             payload: {
//                 value: value
//             }
//         })
//     }
// }


// export const setContractMandatorySundry = (payload) => {
//     return (dispatch, getState) => {
//         const data = payload.data.map((x, i) => {
//             return {
//                 component_id: 161,
//                 item_id: x.sundry.sundry_id,
//                 itemtype: 'Sundry',
//                 itemname: x.sundry.description,
//                 quantity: 1,
//                 quotetype_id: 161,
//                 surrogateitem_id: i,
//                 totalprice: parseFloat(x.sundry.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 costprice: parseFloat(x.sundry.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//                 sellingprice: parseFloat(x.sundry.costprice.replace('/,/gi', '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//             }
//         })
//         dispatch({
//             type: types.SET_VEHICLE_SUNDRY_SELECTION_CATEGORY_LOOKUP_DATA,
//             payload: data
//         })
//     }
// }