import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import * as _ from 'lodash'
import { Container, Row, Col } from 'react-grid-system'
import { Table  } from '@mui/material';

import QuoteItems from '../QuoteItems/component'
import Iframe from 'react-iframe'

import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'
import Icon from '../../Icon/component'

import VehiclePlan from '../../../controls/view_vehicle plan'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewMaintenance from '../../../controls/view_maintenance'
import Card from '../../../controls/card'

import * as styles from './styles'
import * as actions from './actions'

class POBookingInvoiceConfirm extends Component {

    componentDidMount() {

        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.data[0].context_data.data.data.data.step1
        } catch (error) {

        }

        //console.log('this.props.statex', this.props.state)
       // debugger
        const data = {
            quote: this.props.state.quote.grid.filter(x => x.Description),
            filename: this.props.state.filename,
            documents: this.props.state.upload,
            quotenumber: this.props.state.quotenumber,
            quotedate: this.props.state.quotedate,
            supplier: this.props.state.quotations,
            quotations: this.props.state.items.quotations,
            images: this.props.state.imageUploader.images,
            selectedSupplierId: this.props.state.selectedSupplierId,
            selectedSupplierName: this.props.state.selectedSupplierName,
        }
        
        // const meta = [
        //     {
        //         header: true,
        //         title: 'Vehicle',
        //         description: 'Vehicle',
        //         meta_data: workflow_queue_data.data.vehicle[0].licenseplateno
        //     },
        //     {
        //         title: 'Supplier',
        //         description: 'Supplier',
        //         meta_data: workflow_queue_data.data.supplier[0].suppliername
        //     },
        //     {
        //         title: 'Auth Number',
        //         description: 'Pre Number',
        //         meta_data: workflow_queue_data.authnumber
        //     },
        //     {
        //         title: 'Booking Number',
        //         description: 'Booking Number',
        //         meta_data: workflow_queue_data.bookingnumber
        //     }
        // ]
        this.props.actions.setupWorkflowTaskInput(data, [], 'booking', 'tsk_purchase_order_review_and_send')
    }

    getQuotation(quotation, data) {
        return  <tr key={'quotation_inx_' + quotation.inx}>
            <td>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={4} style={{marginTop:'15px'}}>
                           <b>Supplier Name:</b> {quotation.supplier_name}
                        </Col>
                        <Col xl={4} style={{marginTop:'0px'}}>
                        <b>Supplier Quote Number:</b> {quotation.quotenumber}
                        </Col>
                        <Col xl={4} style={{marginTop:'0px'}}>
                        <b>Supplier Quote Date:</b> {quotation.quotedate}
                        </Col>
                        <Col xl={12}>
                            {/* {
                                quotation.supplier_id 
                                ? <Upload
                                    colsize={4}
                                    header={false}
                                    inx={quotation.id}
                                    btnlabel={'Upload Supplier Quote'}
                                    id={'upload_ctl_' + quotation.id}
                                    prefix={'supplier_quote_' + quotation.id}
                                    list={[{ value: 0, text: 'Supplier Attachment' }, { value: 0, text: 'Supplier Quotation' },{ value: 0, text: 'Pictures' }]}
                                /> 
                                : ''
                            } */}
                            
                            <PdfViewer
                                key={'document_viewer_uploaded_quotes'}
                                name={'De-fleet'}
                                workflow={'defleet'}
                                task={'tsk_defleet_upload_quotes'}
                                documents={this.props.state.upload.documents.filter(d => d.inx === quotation.id)}
                                edit={false}
                                workflow_queue_data={null}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                             {
                                quotation.supplier_id 
                                ?  <QuoteItems 
                                    read_only={true}
                                    px={quotation.id}
                                    mmcode={data.mmcode} 
                                    vattable={data.vattable}  
                                    labour_rate={data.labour_rate}  
                                    serviceinterval={data.serviceinterval} 
                                />
                                : ''
                            } 
                        </Col>
                    </Row>
                </Container>
            </td>
        </tr>
    }

    QutationForm() {
        const data = this.props.state.quote.grid.filter(x => x.Description)
        //console.log('this.props.state.upload.documents',this.props.state.upload.documents)
        return <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    
                                    <Table className="table" style={{border: '1px solid #DFDFDF'}}>
                                        <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                                        {
                                
                                            this.props.state.quotations.map((x,inx) => { 
                                                return this.getQuotation({...x, inx: inx},{            
                                                    vattable: this.props.state.supplier ? this.props.state.supplier.vatnumber ? true : false : true,
                                                    labour_rate: this.props.state.supplier ? this.props.state.supplier.slarateperhour : 0,
                                                    serviceinterval: this.data.serviceinterval ? this.data.serviceinterval : 0,
                                                    mmcode: this.data.mmcode ? this.data.mmcode : 0
                                                }) 
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                    
                                </Col>
                            </Row>
                {
                    this.props.state.items.quotations.length ? <Row>
                        <Col xl={9}>
                        </Col>
                        <Col xl={3}>
                            <table style={{width: '100%', fontSize: '16px', fontWeight: '600'}}>
                                <tr>
                                    <td>Vatable Total</td>
                                    <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.items.quotations.filter(x => x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                </tr>
                                <tr>
                                    <td>Non Vatable Total</td>
                                    <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.items.quotations.filter(x => !x.VAT), (d) => { return parseFloat((d['Total']) ? d['Total'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                </tr>
                                <tr>
                                    <td>VAT</td>
                                    <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.items.quotations, (d) => { return parseFloat((d['VAT']) ? d['VAT'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td style={{textAlign: 'right'}}>{_.sumBy(this.props.state.items.quotations, (d) => { return parseFloat((d['Amount']) ? d['Amount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                </tr>
                            </table>
                        </Col>
                    </Row> : ''
                }
                        </Container>
                    </Col>
                </Row>
            </Container>
    }

    // PartsBasket(data) {
    //     return <div>
    //         <Card>
    //             <CardHeader
    //                 actAsExpander={false}
    //                 showExpandableButton={false}
    //                 title={window.t.en('Service Parts Basket')}
    //                 subtitle={window.t.en('List of services required for this 90 Km vehicle service.')}
    //                 icon={<Icon iclass={'custom-icons'} iname={'contract_vehicle_category'} />}
    //                 style={styles.row}
    //             >
    //             </CardHeader>
    //             <CardContent>
    //                 <Container style={{ margin: 0 }} className="custom-container">
    //                     {/* <Row>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{'Ford'}</Col>
    //                         <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{'Ranger XLT 3.3L Diesel 2018'}</Col>
    //                     </Row> */}
    //                     <Row>
    //                         <Col xl={12} style={styles.fieldLabel}>{<br />}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={9} style={styles.leftLabel}>{'2.2L Diesel - Labour Times 4x2 Only:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{'1.50'}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={12} style={styles.fieldLabel}>{<br />}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={9} style={styles.leftLabel}>{'Engine oil - Drain oil and renew oil filter:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{'R - Replace'}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={9} style={styles.leftLabel}>{'Brake fluid:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{'R - Replace'}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={9} style={styles.leftLabel}>{'Fuel filter - Renew:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{'R - Replace'}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={9} style={styles.leftLabel}>{'Air conditioning / Pollen Filter:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{'R - Replace'}</Col>
    //                     </Row>
    //                     <Row>
    //                         <Col xl={9} style={styles.leftLabel}>{'Propeller shaft joints (4WD) - Grease:'}</Col>
    //                         <Col xl={3} style={styles.selectedFieldName}>{'(L - Lubricate'}</Col>
    //                     </Row>
    //                 </Container>
    //             </CardContent>
    //         </Card>
    //     </div>
    // }


    renderView() {
        
        return  <div>
        <ViewVehicle 
            key={this.data.vehicle_id} 
            id={this.data.vehicle_id} 
        />
        {/* <ViewMaintenance 
            key={this.data.maintenancetransaction_id} 
            id={this.data.maintenancetransaction_id} 
        /> */}
                <Card
                title={'Capture Supplier Quote #' + this.data.tasknumber}
                subtitle={'Complete the form below to create booking'}
                content={this.QutationForm()}
            />
            </div>
        // return <div>
        //     <Card>
        //         <CardHeader
        //             actAsExpander={false}
        //             showExpandableButton={false}
        //             title={window.t.en('Booking Done By: ' + workflow_queue_data.user.firstname + ',  Infleet Internal Pre-Auth Number Only:' + workflow_queue_data.preauthnumber)}
        //             subtitle={window.t.en('On ' + workflow_queue_data.datestamp)}
        //             style={styles.row}
        //         >
        //         </CardHeader>
        //         <CardContent>
        //             <Container style={{ margin: 0 }} className="custom-container">
        //                 <Row>
        //                     <Col xl={12}>
        //                         {this.VehicleDetails(data.vehicle[0])}
        //                     </Col>
        //                 </Row>
        //                 <Row>
        //                     <Col xl={12}>
        //                         {
        //                             data.supplier.length
        //                                 ? this.ServiceDealer(data.supplier)
        //                                 : 'Supplier was not selected.'
        //                         }
        //                     </Col>
        //                 </Row>
        //                 {/* {
        //                     data.purchaseOrderType.key === 'service'
        //                         ? <Row>
        //                             <Col xl={12}>
        //                                 {this.PartsBasket(data.booking)}
        //                             </Col>
        //                         </Row>
        //                         : ''
        //                 } */}
        //                 <Row>
        //                     <Col xl={12}>
        //                         {this.PurchaseOrderType(data)}
        //                     </Col>
        //                 </Row>
        //                 <Row>
        //                     <Col xl={12}>
        //                         {this.QuoteDocument()}
        //                     </Col>
        //                 </Row>
        //                 <Row>
        //                     <Col xl={12}>
        //                         {this.QutationForm()}
        //                     </Col>
        //                 </Row>
        //             </Container>
        //         </CardContent>
        //     </Card>
        // </div>
    }

    render() {
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.data[0].context_data.data.data.data.step1
        } catch (error) {

        }
        return this.data
            ? this.renderView()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.poBookingInvoice,
            quote: {
                ...state.poBookingItems,
            },
            imageUploader: {
                ...state.imageUploader,
            },
            upload: {
                ...state.upload,
            },
            items:{
                ...state.quoteItems
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(POBookingInvoiceConfirm)