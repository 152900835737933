import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import * as _ from 'lodash'
import { Card, CardHeader, CardContent } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import ReactTable from "react-table"
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as styles from './styles'







let supplier_id = 0

class SUPPLIERPAYMENTMANAGEMENTAPPROVAL extends Component {
    componentDidMount() {
        //   this.component = this.props.state
        this.actions = this.props.actions;
    }


    addBlankRow(type) {
        var maxid = 0;
        switch (type) {
            case 'payments':
                {
                    this.props.state.payments.map(function (obj) {
                        if (obj.id > maxid) maxid = obj.id;
                    });
                    this.props.actions.addBlankRow(type, (maxid ? Number(maxid) : 0) + 1)
                }
                break;
            case 'credits':
                {
                    this.props.state[type].map(function (obj) {
                        if (obj.id > maxid) maxid = obj.id;
                    });
                    this.props.actions.addBlankRow(type, (maxid ? Number(maxid) : 0) + 1)
                }
                break;
            case 'others':
                {
                    this.props.state[type].map(function (obj) {
                        if (obj.id > maxid) maxid = obj.id;
                    });
                    this.props.actions.addBlankRow(type, (maxid ? Number(maxid) : 0) + 1)
                }
                break;

        }
    }
    getTotals() {

        let payments = _.sumBy(this.props.state.payments, (d) => { return parseFloat(d.amount + d.discount) })
        //console.log(">>>>>>>>>>>>> payments <<<<<<<<<<<<")
        //console.log(payments)

        this.props.actions.updateValue('paymentsnotcredited', payments)
        this.props.actions.updateValue('paymentsnotcredited', payments)

        let credits = _.sumBy(this.props.state.credits, (d) => { return parseFloat(d.amount) })
        //console.log(">>>>>>>>>>>>> credits <<<<<<<<<<<<")
        //console.log(credits)

        this.props.actions.updateValue('creditsnotpassed', credits)
        this.props.actions.updateValue('creditsnotpassed', credits)

        let others = _.sumBy(this.props.state.others, (d) => { return parseFloat(d.amount) })
        //console.log(">>>>>>>>>>>>> others <<<<<<<<<<<<")
        //console.log(others)

        this.props.actions.updateValue('othercredits', others)
        this.props.actions.updateValue('othercredits', others)


    }

    getGrid() {
        return <Card initiallyExpanded={true} >
            <CardContent expandable={true}>
                <AdhocGrid
                    key={'key_adhoc_grid_payment'}
                    name={'payment_grid'}
                    uri={'/components/supplierpaymentmanagement/other'}
                    filterable={true}
                    sortable={true}
                    body={{
                        type: 'get_supplier_payments_approval',
                        team_id: this.props.team_id,
                        user: this.props.state.user
                    }}
                    search={''}
                    columns={[
                        { accessor: 'supplier', Header: 'Supplier', filterable: true, width: 300 },
                        { accessor: 'accountnumber', Header: 'Account No', width: 100, filterable: true },
                        { accessor: 'suppliertype', Header: 'Supplier Type', width: 150, filterable: true },
                        { accessor: 'amountpaid', Header: 'Not Credited', width: 150, filterable: true },
                        { accessor: 'statement', Header: 'Statement', width: 100, filterable: true },
                        { accessor: 'discount', Header: 'Discount %', width: 100, filterable: true },
                        { accessor: 'payments', Header: 'Pay', width: 100, filterable: true },
                        {
                            accessor: 'selected', Header: 'Select', filterable: false, width: 50, Cell: row => {
                                return (<div><Checkbox
                                    checked={false}
                                    onClick={(event) => {
                                        this.props.actions.approveItem(row.original)
                                    }} /></div>
                                )
                            }
                        },
                        {
                            accessor: 'comments', Header: 'Comments', width: 450, filterable: true, Cell: row => {
                                return (
                                    <div>
                                        <TextField
                                            fullWidth={true}
                                            value={''}
                                            onChange={(event, value) => {
                                            }}
                                        /></div>
                                )
                            }
                        },

                    ]}
                    paging={{
                        page: 0,
                        pageSize: 10,
                        pages: 0,
                        count: 0
                    }}
                    subComponent={row => {
                        return <div key={'grid_payment_data_' + row.original['supplier_id']} style={{ padding: "2px" }}>
                            <Card style={styles.card} style={styles.row}>
                                <CardHeader
                                    title={window.t.en('Invoices')}
                                    subtitle={window.t.en('List of Invoices for Payment')}
                                />
                                <CardContent>
                                    <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                        <ReactTable
                                            manual
                                            key={'key_grid_payment_details'}
                                            name={'payment_grid_details'}
                                            className={'-striped -highlight'}
                                            data={row.original['items']}
                                            columns={[
                                                { accessor: 'authnumber', Header: 'Auth Number', filterable: true, width: 100 },
                                                {
                                                    accessor: 'date', Header: 'Date', width: 100, filterable: true, Cell: row => {
                                                        return moment(row.original['date']).format("YYYY-MM-DD")
                                                    }
                                                },
                                                { accessor: 'type', Header: 'Type', width: 100, filterable: true },
                                                { accessor: 'reference', Header: 'Reference', width: 100, filterable: true },
                                                { accessor: 'description', Header: 'Description', width: 300, filterable: true },
                                                { accessor: 'payment', Header: 'Amount', width: 100, filterable: true },
                                                { accessor: 'credit', Header: 'Credit', width: 100, filterable: true },
                                                { accessor: 'shortpay', Header: 'Short Pay', width: 100, filterable: true },
                                                { accessor: 'payments', Header: 'Payable', width: 100, filterable: true },
                                                {
                                                    accessor: 'selected', Header: 'Select', filterable: false, width: 50, Cell: row => {
                                                        return (<div><Checkbox
                                                            checked={row.original['selected']}
                                                            onClick={(event) => {
                                                                //console.log(row)
                                                                this.props.actions.toggleItem(this.props.state.user.user_id, row.original)
                                                            }} /></div>
                                                        )
                                                    }
                                                },

                                                {
                                                    accessor: 'supplier_id', Header: 'Comments', width: 450, filterable: true, Cell: row => {
                                                        return (
                                                            <div>
                                                                <TextField
                                                                    fullWidth={true}
                                                                    value={''}
                                                                    onChange={(event, value) => {
                                                                        //console.log(row)
                                                                    }}
                                                                /></div>
                                                        )
                                                    }
                                                },

                                            ]}
                                            defaultPageSize={10}
                                            filterable={false}
                                            loading={false}
                                            minRows={1}
                                            //pageSize={grid.paging ? grid.paging.pageSize : 10}
                                            pages={1}
                                            showPageJump={true}
                                            showPaginationTop={false}
                                            showPaginationBottom={true}
                                            style={{
                                                height: '100%'
                                            }}
                                            SubComponent={x => {
                                                return <div key={'grid_payment_details_data_' + x.original['suppliertransaction_id']} style={{ padding: "2px" }}>
                                                    <Card style={styles.card}>
                                                        <CardHeader
                                                            title={window.t.en('Related Documents')}
                                                            subtitle={window.t.en('List of invoice related documents')}
                                                        />
                                                        <CardContent>
                                                            <Container style={styles.whitediv}>
                                                                <Row style={styles.tableheader}>
                                                                    <Col xl={12}>
                                                                        <Row><Col xl={12}></Col></Row>
                                                                        <Row>
                                                                            <Col xl={1}></Col>
                                                                            <Col xl={2}>{'Daily Check Sheet Doc'}
                                                                                <Checkbox
                                                                                    onCheck={(event, checked) => {
                                                                                        //console.log(x.original["documents"])
                                                                                    }} />
                                                                            </Col>
                                                                            <Col xl={2}>{'Authorisation Doc'}<Checkbox></Checkbox></Col>
                                                                            <Col xl={2}>{'Release Form'}<Checkbox></Checkbox></Col>
                                                                            <Col xl={2}>{'Invoice Doc'}<Checkbox></Checkbox></Col></Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row><Col xl={12}><br></br></Col></Row>
                                                                {
                                                                x.original['documents'].length ?
                                                                    x.original['documents'].map((x, i) => {
                                                                        return <div><Row><Col xl={1}></Col>
                                                                            <Col xl={3}>
                                                                               < Button className={"global_button"} variant="contained"
                                                                                    key={'add_more_button'}
                                                                                    label={x.description ? x.description : x.filename}
                                                                                    primary={true}
                                                                                    onClick={(event) => {
                                                                                        this.props.actions.getSupplierTransactionDocument(x.filename)
                                                                                    }}
                                                                                    >{x.description ? x.description : x.filename}</Button>
                                                                            </Col>
                                                                            <Col xl={8}>
                                                                                <TextField
                                                                                    fullWidth={false}
                                                                                    disabled={true}
                                                                                    value={moment(x.created_at ?  x.created_at : x.original['date']).format("YYYY-MM-DD")}
                                                                                    onChange={(event, value) => { }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl={12}><br></br></Col>
                                                                        </Row>
                                                                        </div>
                                                                    })
                                                                    :
                                                                    <Row><Col xl={1}>{''}</Col></Row>
                                                                }
                                                            </Container>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            }}
                                        >
                                        </ReactTable>
                                    </Container>
                                </CardContent>
                            </Card></div>
                    }}
                />
            </CardContent>
        </Card>
    }
    getCredits() {
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row><Col xl={12}><br /></Col></Row>
            <Row><Col xl={12}><b>{'CREDITS NOT YET PASSED'}</b></Col></Row>
            <Row><Col xl={12}><br /></Col></Row>
            <Row style={styles.tableheader}>
                <Col xl={1}>{''}</Col>
                <Col xl={1}>{'Date'}</Col>
                <Col xl={1}>{'Invoice Number'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                <Col xl={2}>{'Reason'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Total'}</Col>
            </Row>   {
                this.props.state.credits
                    ? this.props.state.credits
                        .map((x, i) => {
                            return <div>
                                <Row style={styles.tablerow}>
                                    <Col xl={1} style={styles.verticalMiddle}>
                                        <IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                            onClick={
                                                () => {
                                                    this.props.actions.removeRow('credits', x.id)
                                                    this.getTotals()
                                                }
                                            }>
                                            <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                    </Col>
                                    <Col xl={1}>
                                       <TextField
                                                type="date"
                                            id={'txt_transactiondate'}
                                            hintText='Date'
                                            container='inline'
                                            mode='landscape'
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('credits', x.id, 'date', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtdescription'}
                                            hintText={'Enter Invoice #'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={x.invoicenumber}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('credits', x.id, 'invoicenumber', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.amount)}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('credits', x.id, 'amount', value)
                                                this.getTotals()
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={2}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Reason'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.reason)}
                                            onChange={(event, value) => {

                                                this.props.actions.setUpdateItem('credits', x.id, 'reason', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={(x.total).toFixed(2)}
                                            onChange={(event, value) => {
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        })
                    : <div>{'No Items Found...'}</div>
            }
            <Row>
                <Col>
                    <br />
                   < Button className={"global_button"} variant="contained"
                        key={'add_more_button'}
                        label={'+ Add'}
                        primary={true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.addBlankRow('credits')
                        }}
                        >{window.t.en('+ Add')}</Button>
                    <br />
                    <br />
                    <br />
                </Col>
            </Row>
        </Container>
    }
    getPayments() {
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row><Col xl={12}><br /></Col></Row>
            <Row><Col xl={12}><b>{'PAYMENTS AND DISCOUNTS NOT YET CREDITED'}</b></Col></Row>
            <Row><Col xl={12}><br /></Col></Row>
            <Row style={styles.tableheader}>
                <Col xl={1}>{''}</Col>
                <Col xl={1}>{'Date'}</Col>
                <Col style={styles.numericFields} xl={1}>{'EFT'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
               {/*  <Col style={styles.numericFields} xl={1}>{'Discount'}</Col> */}
                <Col style={styles.numericFields} xl={1}>{'Total'}</Col>
            </Row>
            {
                this.props.state.payments
                    ? this.props.state.payments
                        .map((x, i) => {
                            return <div>
                                <Row style={styles.tablerow}>
                                    <Col xl={1} style={styles.verticalMiddle}>
                                        <IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                            onClick={
                                                () => {
                                                    this.props.actions.removeRow('payments', x.id)
                                                    this.getTotals()
                                                }
                                            }>
                                            <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                    </Col>
                                    <Col xl={1}>
                                       <TextField
                                                type="date"
                                            id={'txt_transactiondate'}
                                            hintText='Date'
                                            container='inline'
                                            mode='landscape'
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('payments', x.id, 'date', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={x.eft}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('payments', x.id, 'eft', value)
                                                this.getTotals()
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.amount)}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('payments', x.id, 'amount', value)
                                                this.getTotals()
                                            }}
                                        />
                                    </Col>
                                   {/*  <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Discount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.discount)}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('payments', x.id, 'discount', value)
                                                this.getTotals()
                                            }}
                                        />
                                    </Col> */}
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={(x.total).toFixed(2)}
                                            onChange={(event, value) => {
                                                //this.props.actions.setValue('market', value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        })
                    : <div>{'No Items Found...'}</div>
            }
            <Row>
                <Col>
                    <br />
                   < Button className={"global_button"} variant="contained"
                        key={'add_more_button'}
                        label={'+ Add'}
                        primary={true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.addBlankRow('payments')
                        }}
                        >{window.t.en('+ Add')}</Button>
                    <br />
                    <br />
                    <br />
                </Col>
            </Row>
        </Container>
    }
    getOthers() {
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row><Col xl={12}><br /></Col></Row>
            <Row><Col xl={12}><b>{'OTHER'}</b></Col></Row>
            <Row><Col xl={12}><br /></Col></Row>
            <Row style={styles.tableheader}>
                <Col xl={1}>{''}</Col>
                <Col xl={1}>{'Date'}</Col>
                <Col xl={1}>{'Invoice Number'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                <Col xl={2}>{'Reason'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Total'}</Col>
            </Row>
            {
                this.props.state.others
                    ? this.props.state.others
                        .map((x, i) => {
                            return <div>
                                <Row style={styles.tablerow}>
                                    <Col xl={1} style={styles.verticalMiddle}>
                                        <IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                            onClick={
                                                () => {

                                                    this.props.actions.removeRow('others', x.id)
                                                    this.getTotals()
                                                }
                                            }>
                                            <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                    </Col>
                                    <Col xl={1}>
                                       <TextField
                                                type="date"
                                            id={'txt_transactiondate'}
                                            hintText='Date'
                                            container='inline'
                                            mode='landscape'
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('others', x.id, 'date', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtdescription'}
                                            hintText={'Enter Invoice #'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={x.invoicenumber}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('others', x.id, 'invoicenumber', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.amount)}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('others', x.id, 'amount', value)
                                                this.getTotals()
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={2}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Reason'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(x.reason)}
                                            onChange={(event, value) => {
                                                this.props.actions.setUpdateItem('others', x.id, 'reason', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={(x.total).toFixed(2)}
                                            onChange={(event, value) => {
                                                //this.props.actions.setValue('market', value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        })
                    : <div>{'No Items Found...'}</div>
            }
            <Row>
                <Col>
                    <br />
                   < Button className={"global_button"} variant="contained"
                        key={'add_more_button'}
                        label={'+ Add'}
                        primary={true}
                        onClick={(event) => {
                            event.preventDefault()
                            this.addBlankRow('others')
                        }}
                        >{window.t.en('+ Add')}</Button>
                    <br />
                    <br />
                    <br />
                </Col>
            </Row>
        </Container>
    }
    getLayout() {
        this.actions = this.props.actions;

        return <Card style={styles.card} style={styles.row}>
            <CardHeader
                title={window.t.en('Payment Management')}
                subtitle={window.t.en('Manage Supplier Payments')}
                actAsExpander={true}
                showExpandableButton={true}
            />
            <CardContent >
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={12}>
                            {this.getGrid()}
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.supplierpaymentmanagementapproval,
            user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERPAYMENTMANAGEMENTAPPROVAL)