import * as actions from "./actions";
import * as progressBarActions from "../ProgressBar/actions";
import * as requestActions from "../Request/actions";
import * as routerActions from "../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../Request/types";
import * as config from "../../config";
import moment from "moment";


export const loadFAQ = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
  action$.pipe(
      ofType(types.GET_COMPONENT_FAQ_DATA),
      mergeMap(() =>
          ajax$({
              url: config.system.api + '/components/faq/search',
              method: 'GET',
              headers: {
                  'tokenx': store.value.user.user_id,
                  'auth': store.value.cognito.authenticateUser.token,
                  'salt': store.value.cognito.authenticateUser.jwt,
                  'datetime': store.value.cognito.authenticateUser.datetime,
              }
          })
          .pipe(
              mergeMap(payload => [
                  actions.loadFAQDone(payload.response),
                  requestActions.requestSucceeded(config.system.api + '/components/faq/search')
              ]),
              startWith(progressBarActions.progress(true)),
              catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/faq/search', error)))
      )),
      catchError((error) =>
          of$(routerActions.route('/support', false, error))
      ))
