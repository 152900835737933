import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Icon from '../Icon/component'
import BusinessIntelligence from '../../components/BusinessIntelligence/component'
import GeneralContentView from '../../views/GeneralContentView/component'
import GeneralGridView from '../../views/GeneralGridView/component'


import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import Checkbox  from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
// import TextField from '@mui/material/TextField';

import AutoComplete from '../../controls/autocomplete'
import TextField from '../../controls/textField'
import {
    Box,
    CardHeader,
    Divider,
    Typography,
} from '@mui/material';

import * as actions from './actions'
import * as accountActions from '../Account/actions'
import * as signedInActions from '../SignedIn/actions'

import * as styles from './styles'




class Profile extends Component {

    componentDidMount() {
        this.props.actions.getDashboardList()
        //console.log('this.props.state.user',this.props.state)
    }
    saveSelectValue = (e, id) => {
        let client = {}
        client.id = id;
        client.value = e.target.value
    }

    render() {
        return (
            <GeneralContentView
                content={
                    <div className={styles.root}>
                        <Grid container spacing={3} variant="outlined">
                            <Grid item xs={12} sm={6}>
                                <Card style={styles.cardDropdown}>
                                    <CardHeader className="bg-workflowHeaderCard"
                                        title="Profile Settings"
                                    />
                                    <CardContent>
                                        <GeneralGridView
                                            content={
                                                <Grid container spacing={3} variant="outlined">
                                                    <Grid item xs={12} sm={12} style={{display: "none"}}>
                                                        <Box
                                                            alignItems="center"
                                                            display="flex"
                                                            flexDirection="column"
                                                        >
                                                            <Avatar style={{ width: "86px", height: "86px" }}
                                                            // src={user.avatar}
                                                            />
                                                            <Typography
                                                                color="textPrimary"
                                                                gutterBottom
                                                                variant="h3"
                                                            >
                                                                {/* {user.name} */}
                                                            </Typography>
                                                            <Divider />
                                                            <Button
                                                                color="primary"

                                                                variant="text"
                                                            >
                                                                Upload picture
                                                            </Button>                                
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <form
                                                            autoComplete="off"
                                                            noValidate
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="First Name"
                                                                name="firstName"
                                                                onChange={(e) =>{
                                                                    this.props.actions.setUserInputField({prop: 'firstname', value: e.target.value})
                                                                }}
                                                                //disabled={false}
                                                                required
                                                                //value={this.props.state.profile.userDetails.firstName ? this.props.state.profile.userDetails.firstName:this.props.state.user.firstname}
                                                                variant="outlined"
                                                                className={styles.spacing}
                                                                defaultValue={this.props.state.user.firstname}
                                                                value={this.props.state.user.firstname}
                                                    
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                label="Last Name"
                                                                name="lastName"
                                                                disabled={false}
                                                                onChange={(e) =>{
                                                                    this.props.actions.setUserInputField({prop: 'lastname', value: e.target.value})
                                                                }}
                                                                defaultValue={this.props.state.user.lastname}
                                                                value={this.props.state.user.lastname}
                                                                variant="outlined"
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                label="Email Address"
                                                                name="email"
                                                                onChange={(e) =>{
                                                                    this.props.actions.setUserInputField({prop: 'email', value: e.target.value})
                                                                }}
                                                                value={this.props.state.user.email}
                                                                variant="outlined"
                                                            />
                                                            <TextField
                                                                fullWidth
                                                                label="Organisation"
                                                                name="phone"
                                                                disabled={true}
                                                                // onChange={(e) =>{
                                                                //     this.props.actions.setUserInputField({prop: 'email', value: e.target.value})
                                                                // }}
                                                                value={this.props.state.accountName}
                                                                variant="outlined"
                                                            />
                                                            <Checkbox  
                                                                label={"Reviced Notification"}
                                                                checked={this.props.state.user.notify_user} 
                                                                onChange={(event) => {
                                                                    this.props.actions.setUserInputField({prop: 'notify_user', value: event.target.checked})
                                                                }}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}/>
                                                                <b style={{fontWeight: 'normal'}}>Enable System Notification (Email*)</b>
                                                                <br/>
                                                            <Checkbox  
                                                                checked={this.props.state.user.demouser} 
                                                                onChange={(event) => {
                                                                    this.props.actions.setUserInputField({prop: 'demouser', value: event.target.checked})
                                                                }}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}/>
                                                                <b style={{fontWeight: 'normal'}}>Enable Workflow Demo Mode </b>
                                                            {/* <Button color="primary" variant="text"
                                                                onClick={()=>{
                                                                    this.props.actions.updateUserDetails()
                                                                }}
                                                            >
                                                                Save Changes
                                                            </Button> */}
                                                        </form>
                                                    </Grid>
                                                </Grid>
                                            }
                                            contentHeight={'323px'}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                                <Card style={styles.cardDropdown}>
                                    <CardHeader className="bg-workflowHeaderCard"
                                        title="Organisation Settings"
                                    />
                                    <CardContent>
                                        <GeneralGridView
                                            content={
                                                <FormControl variant="outlined" style={styles.MuiFormControl}>
                                                    {/* <Select
                                                        native
                                                        defaultValue={this.props.state.selectedAccount}
                                                        inputProps={{
                                                            name: 'title',
                                                            id: 'client_id',
                                                        }}
                                                        onChange={(event, value) => {      
                                                            //console.log('orginisation selectedOptions',event.target.selectedOptions[0])                                               
                                                            this.props.actions.setAccInputField({prop: 'selectedAccount', value: event.target.selectedOptions[0].value})
                                                            this.props.actions.setAccInputField({prop: 'accountName', value: event.target.selectedOptions[0].text})
                                                        }}
                                                        >
                                                        { this.props.state.accounts.map(x => { return <option label={x.title} value={x.client_id}>{x.title}</option> }) }
                                                    </Select> 
                                                    
                                                    <AutoComplete
                                                        name={'orginisation'}
                                                        title={'Please select your preferred organisation'}
                                                        data={this.props.state.accounts}
                                                        value={this.props.state.selectedAccount}
                                                        dataSourceConfig={{
                                                            text: 'title',
                                                            value: 'client_id'
                                                        }}
                                                        onSelect={(args) => {
                                                            this.props.actions.setAccInputField({prop: 'selectedAccount', value: args.value})
                                                            this.props.actions.setAccInputField({prop: 'accountName', value: args.text})
                                                        }}
                                                        onClick={() => {}}
                                                        onFilter={(args) => {}} />
                                                </FormControl>
                                            }
                                            //contentHeight={'350px'}
                                        />
                                        
                                        <GeneralGridView
                                            content={
                                                <FormControl variant="outlined" style={styles.MuiFormControl}>
                                                    <InputLabel shrink htmlFor="age-native-label-placeholder">
                                                        Please select your preferred Language
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        label="Language"
                                                        value={this.props.state.locale}
                                                        inputProps={{
                                                            name: 'Language',
                                                            id: 'code',
                                                        }}
                                                        onChange={(event, value) => { this.props.actions.setSelectedSetting({ prop: 'locale', value: event.target.value }) }}
                                                    >
                                                        {
                                                            this.props.state.locales.map(x => { return <option label={x.title} value={x.code}>{x.locale}</option> })
                                                        }

                                                    </Select>
                                                </FormControl>
                                            }
                                            //contentHeight={'200px'}
                                        />
                                        
                                        <GeneralGridView
                                            content={
                                                <FormControl variant="outlined" style={styles.MuiFormControl}>
                                                    <InputLabel shrink htmlFor="age-native-label-placeholder">
                                                        Please select your preferred dashboard
                                                </InputLabel>
                                                <Select
                                    native
                                    name="oqlis"
                                    //value={this.props.state.signedIn.oqliskey}
                                    inputProps={{
                                        name: 'dashboard_name',
                                        id: 'dashboard_key',
                                    }}
                                    style={{width: '400px'}}
                                    onChange={(event, value) => {
                                        //console.log(event.target.value)
                                       this.props.actions.updateUserDefaultDashboard(event.target.value)
                                    }}
                                >
                                    {
                                        //this.props.state.signedIn.oqlis? this.props.state.signedIn.oqlis.filter(x => x.oq_dashboard_key == this.props.state.user.defaultdashboardkey)[0].report_name:'loading...'
                                        this.props.state.user.defaultdashboardkey
                                        ? <option value={''} style={{color: 'grey'}}>
                                            {
                                                this.props.state.signedIn.oqlis.filter(x => x.oq_dashboard_key == this.props.state.user.defaultdashboardkey).length
                                                ? this.props.state.signedIn.oqlis.filter(x => x.oq_dashboard_key == this.props.state.user.defaultdashboardkey)[0].report_name
                                                : 'Please select...'
                                            }
                                        </option>
                                        : <option value={''} style={{color: 'grey'}}>Please select...</option>
                                    }
                                    
                                    {
                                        this.props.state.signedIn.oqlis.map(x => { return <option value={x.oq_dashboard_key}>{x.report_name}</option> })
                                    }
                                </Select>
                                                </FormControl>
                                            }
                                            //contentHeight={'200px'}
                                        />

<GeneralGridView
                                            content={
                                                <FormControl variant="outlined" style={styles.MuiFormControl}>
                                                    <InputLabel shrink htmlFor="age-native-label-placeholder">
                                                        Please select your preferred landing page
                                                </InputLabel>
                                                    <Select
                                                        native
                                                        name="landing"
                                                        placeholder="none"
                                                        onChange={(event, value) => {

                                                            this.props.actions.updateUserDefaultLandingPage(event.target.value)
                                                        }}
                                                    >
                                                        <option value={'task'}>Tasks Dashboard</option>
                                                        <option value={'bi'}>BI Dashboard</option>

                                                    </Select>
                                                </FormControl>
                                            }
                                           // contentHeight={'200px'}
                                        />
                                        <GeneralGridView
                                            content={
                                                <FormControl variant="outlined" style={styles.MuiFormControl}>
                                                    <InputLabel shrink htmlFor="age-native-label-placeholder">
                                                        Enable Automated FMS Decision Engine
                                                </InputLabel>
                                                <Select
                                                        native
                                                        name="landing"
                                                        placeholder="none"
                                                        
                                                    >
                                                        <option value={'Yes'}>Yes</option>
                                                        <option value={'No'}>No</option>

                                                    </Select>
                                                </FormControl>
                                            }
                                            //contentHeight={'200px'}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid> */}
                        </Grid>
                        <Grid item xs={12} sm={3} style={styles.save}>
                            <FormControl variant="outlined" style={styles.MuiFormControl} >
                                <Button 
                                variant="contained" 
                                className="primary" 
                                style={styles.buttonSize}
                                onClick={(event) => {
                                    this.props.actions.updateOrganisationSetting({
                                        type: 'UPDATE_USER_ACCOUNT_DEFAULT_SETTINGS',
                                        user: this.props.state.user,
                                        locale: this.props.state.locale,
                                        account: this.props.state.selectedAccount
                                    })
                                }}>
                                    Save Changes
                                </Button>
                            </FormControl>
                        </Grid>
                    </div>
                }
                contentHeight={'1300px'}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.accounts,
            user: {
                ...state.user
            },
            signedIn:{
                ...state.signedIn
            },
            profile: {
                ...state.userProfile
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...accountActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)