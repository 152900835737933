import * as types from './types'

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
    }
}

export const action = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.PO_SUBCOMPONENT_ACTION,
            payload: {}
        })
    }
}

export const setValue = (prop, value, valid) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: types.SET_CLOSE_BOOKING_COMPONENT_FIELD_INPUT_VALIDATE,
            payload: {
                prop: prop,
                valid: valid ? valid : (value ? true : false)
            }
        })

        dispatch({
            type: types.SET_CLOSE_BOOKING_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}