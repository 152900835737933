import moment from 'moment'
import * as types from './types'

export const addPricing = () => {
    return (dispatch, getState) => {
        let pricing = getState().vehicleMasterCapture.pricing
        pricing.push({editing: true,customers: [],sellingprice:'0.00',costprice:'0.00',vatable: 'VAT Inclusive',expirydate: moment().add(30, 'days').format('YYYY-MM-DD')})
        dispatch(setInputValue('pricing',pricing))
    }
}

export const addCustomerToPricing = (data) => {
    return (dispatch, getState) => {
        let pricing = getState().vehicleMasterCapture.pricing
        let customers = pricing[data.inx][data.prop]
        customers ? customers : []
        customers.push(data.value)

        dispatch(setPricingInputValue({inx: data.inx, prop: 'customers', value: customers}))
    }
}

export const delCustomerFromPricing = (pricing_inx, customer_inx) => {
    return (dispatch, getState) => {
        let pricing = getState().vehicleMasterCapture.pricing
        let customers = pricing[pricing_inx]['customers']
        let _customers = []
        customers.map((x,i) => {
            if(customer_inx !== i) _customers.push(x)
        })
        dispatch(setPricingInputValue({inx: pricing_inx, prop: 'customers', value: _customers}))
    }
}

export const delPricing = (inx) => {
    return (dispatch, getState) => {
        let pricing = getState().vehicleMasterCapture.pricing
        let _pricing = []
        pricing.map((x,i) => {
            if(inx !== i) _pricing.push(x)
        })
        dispatch(setInputValue('pricing',_pricing))
    }
}


export const setPricingInputValue = (data) => {
    return (dispatch, getState) => {
        let pricing = getState().vehicleMasterCapture.pricing
        pricing[data.inx][data.prop] = data.value
        dispatch(setInputValue('pricing',pricing))
    }
}

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_VEHICLE_MASTER_CAPTURE_FORM,
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_VEHICLE_MASTER_CAPTURE_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_VEHICLE_MASTER_CAPTURE_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
                query: `?${field}=${search}`
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        
        let data = response.data.map(x => {
            return {...x, text: x[payload.field], value: x[payload.component + '_id']}
        })
        dispatch(setInputValue(payload.component + '_data', data))
    }
}

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_VEHICLE_MASTER_CAPTURE,
            payload: payload
        })
    }
}

export const getSupplierAccessories = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_VEHICLE_MASTER_SUPPLIER_ACCESSORIES_LIST,
            payload: data
        })
    }
}

export const setSupplierAccessories = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setSupplierAccessories payload', payload)
        //console.log('setSupplierAccessories response', response)
        
        let data = response.data.map(x => {
            return {...x, [payload.display]: x[payload.related.split(',')[0]][payload.display]}
        })
        dispatch(setInputValue(payload.field, data))
    }
}