import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getAllReceipts = (search) => {
    return {
        type: types.GET_RECEIPT_LIST,
        payload: {
            search_text: search
        }
    }
}

export const setReceiptList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.customername + ' | ' + x.reference,
            value: x.reference,
            customer_id: x.customer_id,
            customer_text: x.customername, 
            customertransactiondate: x.customertransactiondate,
            customertransactionmonth: x.customertransactionmonth,
            customerreference: x.customerreference,
            description: x.description,
            business_id: x.business_id,
            business_text: x.businessname,
            transactiontype: x.transactiontype,
            accountingitem_text: x.itemname? x.itemname: '',
            amount: x.credit,
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerreceipt',
            field: 'reference'
        }
    }
}
export const getBusinessUnits = (search) => {
    return {
        type: types.GET_BUSINESS_UNIT_LIST,
        payload: {
            search_text: search,
            action: 'businessunit'
        }
    }
}

export const getLookupValueData = (search) => {
    return {
        type: types.GET_DEBTORS_LIST,
        payload: {
            search_text: search
        }
    }
}

export const getBusinessListData = (search) => {
    return {
        type: types.GET_BUSINESS_LIST,
        payload: {
            search_text: search
        }
    }
}

export const getContractAccountListData = (search) => {
    return {
        type: types.GET_CONTRA_ACCOUNT_LIST,
        payload: {
            search_text: search
        }
    }
}

export const getItemAccountListData = (search) => {
    return {
        type: types.GET_ITEM_ACCOUNT_LIST,
        payload: {
            search_text: search,
        }
    }
}

export const setCustomerList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.hierarchyname + ' | ' + x.accountnumber,
            value: x.customer_id,
            businessunit: x.businessunit
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerreceipt',
            field: 'customer_id'
        }
    }
}

export const setBusinessUnitList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.businessunit,
            value: x.businessunit,
            businessunit: x.businessunit
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerreceipt',
            field: 'customername'
        }
    }
}

export const setTransactionTypeList = (payload) => {
    let data = [{
        text: 'Increase',
        value: 'JC - Receipt Credit'
    }, {
        text: 'Decrease',
        value: 'JD – Receipt Debit'
    }]
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerreceipt',
            field: 'transactiontype'

        }
    }
}

export const setBusinessList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.businessname,
            value: x.business_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerreceipt',
            field: 'business_id'

        }
    }
}

export const setContraAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_DATA_LIST,
            payload: {
                data: data,
                component: 'customerreceipt',
                field: 'accountingitem_id'
            }
        })
    }
}

export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field,
            component: 'customerreceipt'
        }
    }
}

export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        const format = (field, input) => {
            const definition = getState().components.customerreceipt.component_field.filter(x => x.name === field)[0]

            if (definition) {
                switch (definition.component_field_display_type.name) {
                    case 'datePicker':
                        input = input.toString().trim()
                        const now = moment(new Date())
                        return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
                    case 'timePicker':
                        input = input.toString().trim()
                        return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
                    default:
                        return input
                }
            }
            else {
                return input
            }
        }

        dispatch({
            type: types.SET_ANY_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: format(field, value),
                component: 'customerreceipt'
            }
        })
    }
}

export const setInputValueToggle = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_FIELD_INPUT_TOGGLE,
            payload: {
                prop: field,
                value: value,
                component: 'customerreceipt'
            }
        })
    }
}
export const saveData = (payload, validatefields) => {
    var valid = true
    var text = ''
    for (var i = 0; i < validatefields.length; i++) {
        if (!validatefields[i].value) { valid = false; text += validatefields[i].field + " " }
    }
    if (!valid) {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'You missed a couple of fields [' + text + ']...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return {
            type: types.SAVE_RECEIPT_DATA,
            payload: {
                data: payload
            }
        }
    }
}

export const savedReceiptData = (data) => {
    window.glyco.log("SAVED_RECEIPT_DATA")
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_RECEIPT_DATA
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Receipt Capturing Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const invalidEntry = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Please capture Amount, Reference and Debtor...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setItems = (data) => {
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerreceipt',
            field: 'customertransaction_id'
        }
    }
}

export const setDuplicateReferenceCheck = (payload) => {
    if (payload.data.length > 0) {
        return (dispatch, getState) => {
            dispatch({
                type: types.RECEIPT_DUPLICATE_FOUND,
                payload: {
                    component: 'customerreceipt'
                }
            })

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'The Reference Number already Exist!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return (dispatch, getState) => {
            dispatch({
                type: types.RECEIPT_NO_DUPLICATE_FOUND,
                payload: {
                    component: 'customerreceipt'
                }
            })
        }
    }
}

export const getDuplicateReferenceCheck = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_RECEIPT_DUPLICATE_REFERENCE_CHECK,
            payload: {
                type: "GET_RECEIPT_DUPLICATE_REFERENCE_CHECK",
                reference: reference
            }
        })
    }
}

export const foundDuplicateReference = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'The Reference Number already Exist!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getMonthList = (search, business_unit) => {
    return {
        type: types.GET_RCPT_MONTH_LIST,
        payload: {
            action: "month",
            search_text: search,
            business_unit: business_unit
        }
    }
}

export const setReceiptMonthList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.customertransactionmonth.toString(),
            value: x.customertransactionmonth
        }
    })
    return {
        type: types.SET_RCPT_MONTH_LIST,
        payload: {
            data: data,
            component: "customerreceipt",
            prop: "customertransactionmonth"
        }
    }
}

export const getDataToReceipt = (month, business_unit) => {
    return {
        type: types.GET_RCPT_DATA_LIST,
        payload: {
            action: "data",
            month: month,
            business_unit: business_unit
        }
    }
}

export const saveAllocation = (data) => {
    return {
        type: types.GET_RCPT_DATA_LIST,
        payload: {
            action: "allocate",
            data: data
        }
    }
}

export const setReceiptDataList = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Action Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.SET_RCPT_MONTH_LIST,
            payload: {
                data: payload.data.list,
                component: "customerreceipt",
                prop: "customertransaction_id"
            }
        })
    }
}
export const getBalance = (payload) => {
    var data = payload
    let invoices = 0.00
    let credit = 0.00
    let selectAllInvoices = true
    let selectAllCredit = true
    data.map((x, i) => {
        if (!x.allocated && Number(x.credit) > 0) {
            selectAllInvoices = false
        }
        if (!x.allocated && Number(x.credit) < 0) {
            selectAllCredit = false
        }
        invoices += x.allocated ? (Number(x.credit) > 0 ? Number(x.credit) : 0) : 0
        credit += x.allocated ? (Number(x.credit) < 0 ? Number(x.credit) : 0) : 0
    })

    return (dispatch, getState) => {
        dispatch(setInputValue('amount', invoices))
        dispatch(setInputValue('nettvalue', credit))
        dispatch(setInputValue('allocationamount', invoices + credit))
        dispatch(setInputValueToggle('amount', selectAllInvoices))
        dispatch(setInputValueToggle('nettvalue', selectAllCredit))
    }
}

export const setFieldAllocation = (values, payload) => {
    var data = payload.customertransaction_id.data.map((x, i) => {
        return x.customertransaction_id === values.id && (values.field == 'credit') ?
            {
                ...x,
                valueupdated: true,
                credit: (values.value),
                nettvalue: (values.value),
                allocated: (values.value) != 0 ? true : false,
            } : x.customertransaction_id === values.id && (values.field == 'allocated') ?
                {
                    ...x,
                    valueupdated: true,
                    allocated: values.value,
                    credit: (values.value) ? (x.amount) : 0
                } : x
    })

    return (dispatch, getState) => {
        if (data != null) {

            dispatch({
                type: types.SET_BULK_RECEIPT_DATA,
                payload: {
                    data: data,
                    component: "customerreceipt",
                    prop: "customertransaction_id"
                }
            })
            dispatch(getBalance(data))
        }

    }
}

export const setFieldAllocationToggle = (values, payload) => {
    var full = ''
    var data = payload.customertransaction_id.data.map((x, i) => {
        return (values.field == 'credit' && (x.amount) < 0) ?
            {
                ...x,
                valueupdated: true,
                allocated: values.value,
                credit: (values.value) ? (x.amount) : 0
            } : (values.field == 'invoices' && (x.amount) > 0) ?
                {
                    ...x,
                    valueupdated: true,
                    allocated: values.value,
                    credit: (values.value) ? (x.amount) : 0
                } : x
    })

    return (dispatch, getState) => {
        if (data != null) {
            dispatch({
                type: types.SET_BULK_RECEIPT_DATA,
                payload: {
                    data: data,
                    component: "customerreceipt",
                    prop: "customertransaction_id"
                }
            })
            dispatch(getBalance(data))
        }

    }
}