import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'

import { red, grey } from '@mui/material/colors'

import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

class PaymentManagementComments extends Component {

    render() {
        //console.log('##################### COMMENTS #################')
        //console.log(this.props.inx)
        return <Card initiallyExpanded={true} style={{ backgroundColor: red[100] }}>
            <CardHeader
                style={{ backgroundColor: red[100] }}
                actAsExpander={false}
                showExpandableButton={false}
                title={'Participant Commetns'}
                subtitle={'Take note of the comments below, action if required.'}
                avatar={
                    <Icon iclass={'custom-icons'} iname={'workflow'} />
                }
            />
            <CardContent expandable={true} style={{ backgroundColor: 'white' }}>
                <Tabs>
                    <Tab label={'View Comments'}>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row style={{ backgroundColor: '#e8e8e8', fontWeight: 'bold', fontSize: '16px', padding: '5px' }}>
                                <Col xl={3}>
                                    {'Comment By'}
                                </Col>
                                <Col xl={3}>
                                    {'Comment Date'}
                                </Col>
                                <Col xl={6}>
                                    {'Comment'}
                                </Col>
                            </Row>
                            {
                                this.props.data.disposalcomments.map(x => {
                                    return <Row style={{ borderBottom: '1px solid black' }}>
                                    <Col xl={3}>
                                        {x.commentby}
                                    </Col>
                                    <Col xl={3}>
                                        {x.commentdate ? moment(x.commentdate).format('YYYY-MM-DD HH:mm') : ''}
                                    </Col>
                                    <Col xl={5}>
                                        {x.comment}
                                    </Col>
                                    <Col xl={1}>
                                        <Checkbox
                                            label={'Seeing'}
                                            checked={
                                                x.disposalcommentseein.filter(s => x.seeingby === this.props.state.user.firstname).length > 0
                                            }
                                            defaultValue={false}
                                            onCheck={(event, checked) => {
                                                event.preventDefault()
                                                this.props.actions.submitSeeingStatus(x.disposalcomments_id,this.props.state.user.firstname)
                                            }}
                                        />
                                    </Col>
                                    <Col xl={5}>
                                        {
                                            JSON.stringify(x.disposalcommentseein.map(x => { return x.seeingby }))
                                        }
                                    </Col>
                                </Row>
                                })
                            }
                        </Container>
                    </Tab>
                    <Tab label={'Add Comment'}>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    <TextField
                                        hintText={'Please enter your comments'}
                                        errorText={this.props.state.commentfield.length < 5 ? 'Please provide atleast 5 char.' : ''}
                                        multiLine={true}
                                        style={{width: '100%'}}
                                        value={this.props.state.commentfield}
                                        rows={2}
                                        onChange={(event, value) => {
                                            event.preventDefault()
                                            this.props.actions.setSetinputField({prop:'commentfield',value:value})
                                        }}
                                    />
                                </Col>
                               </Row> 
                               <Row>
                                <Col xl={12}>
                                   < Button className={"global_button"} variant="contained"
                                        label="Cancel"
                                        primary={false}
                                        //style={{ position: 'fixed', bottom: 0 }}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleItemProps(this.props.inx, 'showcomments', true)
                                            ///this.props.actions.closeComments()
                                        }}
                                        >{window.t.en('Cancel')}</Button>
                                    &nbsp;&nbsp;
                                   < Button className={"global_button"} variant="contained"
                                        label="Submit"
                                        primary={true}
                                        //style={{ position: 'fixed', bottom: 0 }}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.submitAndCloseComment(this.props.data.disposal_id,this.props.state.commentfield,this.props.state.user.firstname)
                                        }}
                                        >{window.t.en('Submit')}</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Tab>
                </Tabs>
            </CardContent>
        </Card>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.supplierpaymentmanagement,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentManagementComments)