import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
       case types.STR_COMPONENT_RESET_FORM:
        return {
            ...state,
            vehicle: null,
            datetime: '',
            customer: null,
            contractvehiclecategory: null,
            mmcode: null,
            supplier: null,
            vehicle_data: [],
            straccessory_data: [],
            sundry_list: [],
            service_list: [],
            customer_data: [],
            supplier_data: [],
            loadfactor_data: [],
            mmcode_data: [],
            contractvehiclecategory_data: [],
            accessory_lis: [],

            retailprice: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            costomerref: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            supplier_type: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            vehicleprice: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            discount: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },

            finance: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            primerate: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },

            interestrate: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            diviation: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },

            monthlyinclusive: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },

            contractperiod: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            startdate: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            enddate: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            excessrates: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            roipercentage: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            roi: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            rentalamount: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            validuntil: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            quotefor: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            monthlyexc: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            monthlyvat: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            monthlyinc: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            rv: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            rvamount: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            strrate: {
                value: 1,
                valid: true,
                errorText: 'Required!!',
            },
            stramount: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            workingsubtotal: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            workingvat: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            workingrental: {
                value: null,
                valid: true,
                errorText: 'Required!!',
            },
            totalaccessory: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            adminfee: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            licensefee: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            tyres: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            maintenance: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            loadfactorperc: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            },
            loadfactoramount: {
                value: 0,
                valid: true,
                errorText: 'Required!!',
            }
        }
       case types.SET_STR_COMPONENT_INPUT_FIELD_VALUE:
        return {
            ...state,
            [action.payload.prop]: {
                value: action.payload.value,
                valid: true,
                errorText: '',
            } 
        }
       case types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST:
        return {
            ...state,
            [action.payload.prop]: action.payload.value 
        }
       case types.STR_COMPONENT_SET_CURRENT_TAB_VIEW:
        return {
            ...state,
            tab: action.payload 
        }
        default:
            return state
    }
}