import * as types from './types'
import * as appTypes from '../App/types'
import moment from 'moment'  

export const setInputValue = (prop, value) => {
    return {
        type: types.SET_COMPONENT_ENATIS_INPUT_VALUE,
        payload: {
            prop: prop,
            value: value
        }
    }
}

export const saveEnatisData = (data) => {
    return (dispatch, getState ) => {
        dispatch({
            type: types.UPDATE_VEHICLE_BY_ENATIS,
            payload: {
                type: 'UPDATE_VEHICLE_BY_ENATIS',
                data: data
            }
        })
    }
}


export const getVehicleDetails = (vin_number) => {
    return (dispatch, getState ) => {
        console.log('vehicleDetailsx get', vin_number)
        dispatch({
            type: types.GET_VEHICLE_BY_VINNUMBER,
            payload: vin_number
        })
    }
}

export const setVehicleDetails = (response) => {
    return (dispatch, getState ) => {
        //console.log('response',response)
        console.log('vehicleDetailsx set', response)
        let vehicle = response.data.length ? response.data[0] : {}

        
        let customercontract = vehicle 
            ?  vehicle.customercontract    
                ? vehicle.customercontract.filter(x => x.activated == true) 
                : [] 
            : []

        let scan_data = getState().enatis.scan_data

        customercontract = customercontract.length ? customercontract[0] : {}
        scan_data = {
            ...scan_data,
            activated: customercontract.activated,
            vehicle_id: customercontract.vehicle_id,
            contractnumber: customercontract.contractnumber,
            //vehicle_registration: customercontract.licenseplateno,
            startdate: moment(customercontract.startdate).format('YYYY-MM-DD'),
            terminationdate: moment(customercontract.terminationdate).format('YYYY-MM-DD'),
            customername: customercontract.customer ? customercontract.customer.customername : '',
            accountnumber: customercontract.customer ? customercontract.customer.accountnumber : '',
        }
        dispatch(setInputValue('scan_data', scan_data))
        dispatch(setInputValue('vehicle', vehicle))
    }
}

export const saveEnatisDataDone = (response, payload) => {
    return (dispatch, getState ) => {
        //console.log('saveEnatisDataDonex response', response)
        //console.log('saveEnatisDataDonex payload', payload)
        dispatch(setInputValue('scan_data',null))
        
        dispatch(setInputValue('scan_text',''))
        if(response.data.length) {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    type: 'success',
                    title: 'System Notification',
                    additionalText: 'Scan data successfully save',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
        else{
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    type: 'danger',
                    title: 'System Notification',
                    additionalText: 'No vehicle update, vin number not found',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}

export const setScanText = (prop, value) => {
    return (dispatch, getState ) => {
        dispatch(setInputValue(prop,value))
        console.log('setScanTextx', value)
        let fields = value.split('%')
        console.log('setScanTextx', fields)
        let data = {}
        if(fields.length > 2) {
            if(fields[1].includes('RC1')){
                data = {
                    scanned_text: value,
                    scanned_by: getState().user.firstname,
                    form_type: fields[1],
                    vehicle_register: fields[6],
                    vehicle_make: fields[8],
                    vehicle_model: fields[9],
                    vin_number: fields[10],
                    engine_number: fields[11],
                    registration_data: fields[13],
                    vehicle_brn: fields[15],
                    vehicle_owner: fields[16],
                    vehicle_type: fields[7]
                }
                dispatch(setInputValue('scan_text',''))
                dispatch(setInputValue('scan_data',data))
                dispatch(getVehicleDetails(data.vin_number))
                if(getState().enatis.auto_save){
                    dispatch(saveEnatisData(data))
                }
            }
            else if(fields[1].includes('MVL')){
                data = {
                    scanned_text: value,
                    form_type: fields[1],
                    scanned_by: getState().user.firstname,
                    vehicle_registration: fields[6],
                    vehicle_register: fields[7],
                    vehicle_make: fields[9],
                    vehicle_model: fields[10],
                    vin_number: fields[12],
                    engine_number: fields[13],
                    license_expiry: fields[14],
                    vehicle_color: fields[11],
                    vehicle_type: fields[8]
                }
                dispatch(setInputValue('scan_data',data))
                dispatch(setInputValue('scan_text',''))
                dispatch(getVehicleDetails(data.vin_number))
                if(getState().enatis.auto_save){
                    dispatch(saveEnatisData(data))
                }
            }
            else {
                dispatch(setInputValue('scan_text',''))
                dispatch(setInputValue('scan_data',null))
                dispatch({
                    type: appTypes.SHOW_NOTIFICATION,
                    payload: {
                        type: 'danger',
                        title: 'System Notification',
                        additionalText: 'Invalid form type scanned',
                        overflowText: getState().login.fields.email.input,
                        autoHide: 10000,
                        timestamp: moment().format('h:mm A')
                    }
                })
            }
        }
        else{
            dispatch(setInputValue('scan_data',{}))
            //dispatch(setInputValue('scan_text',''))
        }
    }
}

export const setScanInputValue = (prop, value) => {
    return (dispatch, getState ) => {
        let scan_data = getState().enatis.scan_data
        scan_data[prop] = value
        dispatch(setInputValue('scan_data',scan_data))
    }
}