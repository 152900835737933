import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_STR_BOOKOUT_COMPONENT_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.STR_BOOKOUT_COMPONENT_RESET_FORM:
            return {
                registration: '',
                odometer: 0,
                startdate: null,
                enddate: null,
                comments: '',
                ponumber: '',
                rfqdate: null,
                rfqtime: null,
                deliverydate: null,
                yardlist: true,
                view_vehicle: {},
                view_vehicle_win: false,
                supplier_data: [],
                yardlist_vehicle: [],
                supplierratecard_data: []
            }
        default:
            return state
    }
}

