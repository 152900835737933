export const input = ''

export const fields = [
    {
        type: 'textbox',
        length: 200
    },
    {
        type: 'checkbox',
        length: 200
    }
]