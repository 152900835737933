import * as cognitoTypes from "../Cognito/types";
import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from 'moment'

import Card from '@mui/material/Card'

//Styling
import Container from "@mui/material/Container";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import IconButton from '@mui/material/IconButton'
import Icon from '../Icon/component'

import {MenuItem,Menu, Button} from '@mui/material'

import Grid from './grid'
import * as redirectActions from "../Redirect/actions";
import * as actions from "./actions";
import * as styles from './styles'

class Workqueue extends Component {

  componentDidMount() {
    //console.log('Workqueuex c',this.props)
    this.props.actions.getItemData(this.props.state.params.workqueue_title,this.props.state.formitem)
  }

  getVehicle() {
    return  <TableContainer component={Paper}>
    <Table  aria-label="simple table">
        <TableHead>
        <TableRow>
            <TableCell>Vehicle Registration</TableCell>
            <TableCell>Make and Model</TableCell>
            <TableCell>Fleet No</TableCell>
            <TableCell>Registration Date</TableCell>
            <TableCell>License Expiry</TableCell>
            <TableCell>Odometer</TableCell>
            {/*<TableCell>Status</TableCell>
             <TableCell style={{backgroundColor: '#B1D4E0', width: 60}} align="right">Good</TableCell>
            <TableCell style={{backgroundColor: '#56AEFF', width: 60}} align="right">Meduim</TableCell>
            <TableCell style={{backgroundColor: '#145DA0', width: 60}} align="right">Danger</TableCell> */}
            <TableCell style={{ width: 100}}>Action</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
            {
                this.props.state.formdata.length
                ? this.props.state.formdata.map(x => {
                    return <TableRow key={'foo'}>
                    <TableCell component="th" scope="row">
                        {x.licenseplateno}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {x.makemodelyear}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {x.fleetno}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {moment(x.registrationdate).format('YYYY')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {moment(x.licenceexpirydate).format('YYYY-MM-DD')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {x.closingodometer}
                    </TableCell>
                    {/*<TableCell style={{backgroundColor: '#B1D4E0', width: 60}} align="right">{0}</TableCell>
                     <TableCell style={{backgroundColor: '#56AEFF', width: 60}} align="right">{0}</TableCell>
                    <TableCell style={{backgroundColor: '#145DA0', width: 60}} align="right">{0}</TableCell> */}
                    <TableCell style={{ width: 100}}>
                        <IconButton key={'take_action'} style={styles.iconButton} iconStyle={styles.actionIcon}
                            tooltip={'Take Action'}
                            onClick={(event) => {
                                this.props.actions.setInputvalue(false, null)
                                this.props.actions.route('/view/vehicle/'+x.vehicle_id)
                                }
                            }>
                            <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'visibility'} />
                        </IconButton>
                        <IconButton key={'take_action'} style={styles.iconButton} iconStyle={styles.actionIcon}
                            tooltip={'Take Action'}
                            onClick={(event) => {
                                    this.props.actions.setInputvalue(true, event.currentTarget)
                                }
                            }>
                            <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'settings'} />
                        </IconButton>
                    </TableCell>
                </TableRow>
                })
                : 'Loading...'
            }
        </TableBody>
    </Table>
</TableContainer>

  }

  getGrid() {
    return  <Table Container component={Paper}>
    <Table  aria-label="simple table">
        <TableHead>
        <TableRow>
            <TableCell>Vehicle Registration</TableCell>
            <TableCell>Make and Model</TableCell>
            <TableCell>Fleet No</TableCell>
            <TableCell>Registration Date</TableCell>
            <TableCell>License Expiry</TableCell>
            <TableCell>Odometer</TableCell>
            {/*<TableCell>Status</TableCell>
             <TableCell style={{backgroundColor: '#B1D4E0', width: 60}} align="right">Good</TableCell>
            <TableCell style={{backgroundColor: '#56AEFF', width: 60}} align="right">Meduim</TableCell>
            <TableCell style={{backgroundColor: '#145DA0', width: 60}} align="right">Danger</TableCell> */}
            <TableCell style={{ width: 100}}>Action</TableCell>
        </TableRow>
        </TableHead>
    </Table>
</Table>

  }

  getPerson() {
    return  <TableContainer component={Paper}>
    <Table  aria-label="simple table">
        <TableHead>
        <TableRow>
            <TableCell>Full Name</TableCell>
            <TableCell>ID Number</TableCell>
            <TableCell>Employee Number</TableCell>
            <TableCell>Mobile Number</TableCell>
            <TableCell>Join Date</TableCell>
            <TableCell>Driver Tag Number</TableCell>
            <TableCell>License Expiry Date</TableCell>
            {/* <TableCell style={{backgroundColor: '#B1D4E0', width: 60}} align="right">Good</TableCell>
            <TableCell style={{backgroundColor: '#56AEFF', width: 60}} align="right">Meduim</TableCell>
            <TableCell style={{backgroundColor: '#145DA0', width: 60}} align="right">Danger</TableCell> */}
            <TableCell style={{ width: 100}}>Action</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
            {
                this.props.state.formdata.length
                ? this.props.state.formdata.map(x => {
                    return <TableRow key={'foo'}>
                    <TableCell component="th" scope="row">
                        {x.fullname}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {x.id_number}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {x.employee_number}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {x.mobile_number}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {x.start_date}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {x.tagnumber}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {x.lic_expiry_date}
                    </TableCell>
                     {/*<TableCell style={{backgroundColor: '#B1D4E0', width: 60}} align="right">{0}</TableCell>
                    <TableCell style={{backgroundColor: '#56AEFF', width: 60}} align="right">{0}</TableCell>
                    <TableCell style={{backgroundColor: '#145DA0', width: 60}} align="right">{0}</TableCell> */}
                    <TableCell style={{ width: 100}}>
                        <IconButton key={'take_action'} style={styles.iconButton} iconStyle={styles.actionIcon}
                            tooltip={'Take Action'}
                            onClick={(event) => {
                                this.props.actions.setInputvalue(false, null)
                                this.props.actions.route('/view/person/'+x.person_id)
                                }
                            }>
                            <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'visibility'} />
                        </IconButton>
                        <IconButton key={'take_action'} style={styles.iconButton} iconStyle={styles.actionIcon}
                            tooltip={'Take Action'}
                            onClick={(event) => {
                                    this.props.actions.setInputvalue(true, event.currentTarget)
                                }
                            }>
                            <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'settings'} />
                        </IconButton>
                    </TableCell>
                </TableRow>
                })
                : 'Loading...'
            }
        </TableBody>
    </Table>
</TableContainer>

  }

  getLayout() {
    return <div component="main" maxWidth="lg">
            <div>
                <Card style={{ backgroundColor: '#fff', padding: '25px' }}>
                    <h2>{this.props.state.params.workqueue_title} </h2>
                    <hr />
                    <div>
                        <Button
                            secondary={true} style={{backgroundColor: '#59981A', width: 200}}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.setGridFilter('informational')
                            }}>
                            {"Informational " + (this.props.state.formdata.workqueue['informational'] && this.props.state.grid_filter ? this.props.state.formdata.workqueue['informational'].length : 0)}
                        </Button>  
                        &nbsp;
                        <Button
                            secondary={true} style={{backgroundColor: '#eac41b', width: 200}}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.setGridFilter('low')
                            }}>
                            {"Low " + (this.props.state.formdata.workqueue['low'] && this.props.state.grid_filter ? this.props.state.formdata.workqueue['low'].length : 0)}
                        </Button> 
                        &nbsp;
                        <Button
                            secondary={true} style={{backgroundColor: '#d27f0e', width: 200}}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.setGridFilter('moderate')
                            }}>
                            {"Moderate " + (this.props.state.formdata.workqueue['moderate'] && this.props.state.grid_filter ? this.props.state.formdata.workqueue['moderate'].length : 0)}
                        </Button> 
                        &nbsp;
                        <Button
                            secondary={true} style={{backgroundColor: '#bf1910', width: 200}}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.setGridFilter('high')
                            }}>
                            {"High " + (this.props.state.formdata.workqueue['high'] && this.props.state.grid_filter ? this.props.state.formdata.workqueue['high'].length : 0)}
                        </Button>
                        &nbsp;
                        <Button
                            secondary={true} style={{backgroundColor: '#8d110a', width: 200}}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.setGridFilter('critical')
                            }}>
                            {"Critical " + (this.props.state.formdata.workqueue['critical'] && this.props.state.grid_filter ? this.props.state.formdata.workqueue['critical'].length : 0)}
                        </Button>
                    </div>
                    <hr/>
                    <h4>{this.props.state.grid_filter ? this.props.state.grid_filter.toUpperCase() : ''}</h4>
                    <Grid />
                 </Card>

           </div>

                 
           <Menu
              anchorEl={this.props.state.speeddialEl}
              id={'primary-search-account-menu'}
              style={{zIndex: 9999999}}
              keepMounted
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
             open={this.props.state.speeddial}
             onClose={(event) => {
                event.preventDefault()
               this.props.actions.setInputvalue(false, null)
            }}
            >
     
            <MenuItem onClick={(event) => {
                event.preventDefault()
                this.props.actions.setInputvalue(false, null)
            }}>
                Action By Workflow
            </MenuItem>
            <MenuItem onClick={(event) => {
                event.preventDefault()
                this.props.actions.setInputvalue(false, null)
            }}>
                Close Task
            </MenuItem>
            </Menu>
           
        </div>
    }

  render() {
    
    //console.log('Workqueuex r',this.props)
    //console.log('Workqueuex s',this.props.state)
        return this?.props?.state?.formdata?.workqueue ? this.getLayout() : '';
  }
}

const mapStateToProps = (state) => {
  return {
    state: {
        ...state.workqueue,
    }
    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...actions, ...redirectActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Workqueue);
