import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux' 

import { Dialog, Button } from '@mui/material';
import * as actions from "./actions";
import * as redirectActions from "../Redirect/actions";
import * as progressBarActions from '../ProgressBar/actions'

class CustomErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to show fallback UI
    console.error("Errorx getDerivedStateFromError:", error);
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Errorx componentDidCatch:", error);
    // Log error to an error reporting service if needed
    //console.error("Errorx captured:", error, errorInfo);
    //this.setState({ hasError: false, error: null })
  }
  
  handleOk = () => {
    this.setState({ hasError: false, error: null })
    this.props.actions.route('/tasks')
    this.props.actions.progress(false)
    this.props.actions.resetErrorState()
  }

  // componentWillUnmount () {//   
  //   sessionStorage.clear();
  //   localStorage.clear();
  //   this.setState({ hasError: false, error: null })
  //   this.props.actions.route('/tasks')
  //   this.props.actions.progress(false)
  // }

  render() {
    if (this.state.hasError) {
      // Customize your error message or UI
      return (
        <div>
          <Dialog open={this.state.hasError} >
                    <div className="text-center p-5">
                        <div className="avatar-icon-wrapper rounded-circle m-0">
                            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">

                            </div>
                        </div>
                        <h4 className="font-weight-bold mt-4">
                           System Error: 201
                        </h4>
                        <p className="mb-0 font-size-lg text-muted">
                        {this.state.error?.message}
                        </p>
                        <div className="pt-4">
                            {/* <button onClick={() => {
                                this.props.actions.route('/tasks')
                            }}>
                                Try Again
                            </button> */}
                           <Button
                                onClick={this.handleOk}
                                // onClick={(event) => {
                                //     this.props.actions.route('/tasks')
                                //     //this.setState({ hasError: false, error: null })
                                // }}
                                className="btn-neutral-secondary btn-pill mx-1">
                                <span className="btn-wrapper--label">Ok</span>
                            </Button>
                             {/* <Button
                                onClick={(event) => {
                                    this.props.actions.closePopupDialog(false)
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">Ok</span>
                            </Button> */}
                        </div>
                    </div>
                </Dialog>
        </div>
      );
    }

    return this.props.children; 
  }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...redirectActions, ...progressBarActions }, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(CustomErrorBoundary)

