import * as types from './types'
import * as appTypes from '../App/types'

import moment from 'moment'

import * as readComponentTypes from '../NavigationMenu/types'

let refreshUserNotifications = undefined

export const findUnique = (arr, predicate) => {
    var found = {};
    arr.forEach(d => {
        found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]);
}

export const setComponent = () => {
    return (dispatch) => {
        dispatch({
            type: types.NAVIGATION_MENU_GET_MENU
        })
    }
}

export const getNavigationMenu = () => {
    return (dispatch) => {
        dispatch({
            type: types.NAVIGATION_MENU_GET_MENU
        })
    }
}

export const setNavigationMenu = (data) => {
    //console.log('setNavigationMenu xxx', data)
    return (dispatch) => {
        dispatch({
            type: types.NAVIGATION_MENU_SET_MENU,
            payload: data
        })
        //dispatch(getUserNotifications()) //todo
    }
}

export const setNavigationMenuHeaders = (data) => {
    //console.log('setNavigationMenuHeadersx xxx', data)
    return (dispatch) => {
        dispatch({
            type: types.NAVIGATION_MENU_SET_HEADERS,
            payload: data
        })
        //dispatch(getUserNotifications()) //todo
    }
}

export const getStaticMenu = () => {
    return (dispatch) => {
        dispatch({
            type: types.STATIC_MENU_GET_MENU,
            payload: {
                type: 'STATIC_MENU_GET_MENU'
            }
        })
    }
}

export const setStaticMenu = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.STATIC_MENU_SET_MENU,
            payload: data
        })
    }
}

export const toggleMenus = (status, anchorEl) => {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_MEGA_MENUS,
            payload: {
                status: status,
                anchorEl: anchorEl
            }
        })
    }
}

export const toggleMenuState = (name, pos, anchorEl) => {
    return (dispatch, getState) => {
        let data = getState().navigationMenu.data.map(x => {
            return name === x.name
                ? { ...x, open: !x.open, position: pos, anchorEl: anchorEl }
                : { ...x, open: false, position: pos, anchorEl: anchorEl }
        })
        dispatch({
            type: types.SET_SELECTED_MENU_ITEM_TOGGLE_STATE,
            payload: data
        })
    }
}


export const toggleSubMenuState = (name, pos) => {
    return (dispatch, getState) => {
        let data = getState().navigationMenu.data.map(x => {
            return name === x.name
                ? { ...x, open: !x.open, position: pos }
                : { ...x, open: false, position: pos }
        })
        //console.log('toggleSubMenuState', data)
        dispatch({
            type: types.SET_SELECTED_SUB_MENU_ITEM_TOGGLE_STATE,
            payload: data
        })
    }
}

export const initUserNotifications = () => {
    return (dispatch, getState) => {
        if (!refreshUserNotifications) {
            refreshUserNotifications = setInterval(dispatch, (1000 * 5), getUserNotifications())
        } else {
            clearInterval(refreshUserNotifications);
            refreshUserNotifications = setInterval(dispatch, (1000 * 5), getUserNotifications())
        }
    }
}

export const getUserNotifications = () => {

    return (dispatch, getState) => {
        dispatch({
            type: types.GET_USER_WORKFLOW_NOTIFICATIONS_MENUS //todo
        })
        //    setInterval(dispatch, (1000 * 10), getUserNotifications())
    }
}

export const setUserNotifications = (payload) => {
    return (dispatch, getState) => {
        let headers = findUnique(payload.data, d => d.workflow_id)

        var notification = getState().navigationMenu.notifications_items

        // //console.log('setUserNotifications payload.data',  payload.data)
        // //console.log('setUserNotifications notification',  notification)

        // var new_notification = notification
        //     ? payload.data.filter(x => !notification.workflow_task_user_notification_id.includes(x.workflow_task_user_notification_id)) // !x.workflow_task_user_notification_id.any(notification.workflow_task_user_notification_id))
        //     : []

        dispatch({
            type: types.SET_USER_WORKFLOW_NOTIFICATIONS_MENUS,
            payload: {
                notifications_items: payload.data,
                notifications_headers: headers,
            }
        })

        // if(new_notification.length) {
        //     //console.log('new_notification >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', new_notification)
        //     for(let x of new_notification)
        //     {
        //         dispatch({
        //             type: appTypes.SHOW_NOTIFICATION,
        //             payload: {
        //                 title: 'New Notification',
        //                 additionalText: x.notification,
        //                 overflowText:  x.notification,
        //                 autoHide: 10000,
        //                 timestamp: moment().format('h:mm A')
        //             }
        //         })
        //     }
        // }
    }
}
export const getUserData = () => {
    return (dispatch, getState) => {
        if (!getState().app.loading) {
            return
        }

        dispatch({
            type: types.GET_USER_DATA
        })
    }
}

export const toggleRelationshipMenu = () => {
    return {
        type: readComponentTypes.TOGGLE_RELATIONSHIP_MENU
    }
}

export const toggleRelationshipMenuDrawer = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_RELATIONSHIP_MENU_DRAWER,
            payload: !val
        })
    }
}



export const toggleDashboardMenuState = (name, pos) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_DASHBOARD_MENU_ITEM_TOGGLE_STATE,
            payload: {
                name: name,
                data: !pos
            }
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_DASHBOARD_MENU_ITEM_TOGGLE_STATE,
            payload: {
                name: prop,
                data: value
            }
        })
    }
}
export const toggleDashboardMenus = (status, anchorEl, anchorName, menuName) => {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_DASH_MEGA_MENUS,
            payload: {
                status: status,
                anchorEl: anchorEl,
                anchorName: anchorName,
                menuName: menuName,
            }
        })

    }
}

export const setOqlisDashboard = (dashboard) => {
    return (dispatch, getState) => {

        dispatch({
            type: types.SET_SELECTED_DASHBOARD,
            payload: {
                name: 'oqlisMenuDashboard',
                key: dashboard
            }
        })
    }
}




export const toggleReportMenuState = (name, pos) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_DASHBOARD_MENU_ITEM_TOGGLE_STATE,
            payload: {
                name: name,
                data: !pos
            }
        })
    }
}

export const toggleReportMenus = (status, anchorEl, anchorName, menuName) => {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_DASH_MEGA_MENUS,
            payload: {
                status: status,
                [anchorName]: anchorEl,
                menuName: menuName
            }
        })

    }
}

export const setOqlisReport = (reportKey, isJs, x) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_DASHBOARD,
            payload: {
                name: 'oqlisMenuReport',
                key: reportKey,
                isJs: isJs,
                report_: {
                    ...x,
                    user: getState().user
                }
            }
        })
    }
}

export const checkDocument360 = () => {
    return (dispatch) => {
        dispatch({
            type: types.GET_DOCUMENT_360_TOKEN
        })
    }
}

export const getDocument360TokenDone = (payload) => {
    return (dispatch, getState) => {
        let authTokenURL = '';
        if (payload?.data && payload.data.success) {
            authTokenURL = `${process.env.REACT_APP_DOCUMENT_360_INFLEET_URI}?code=${payload.data?.code}`;
            window.open(authTokenURL, '_blank');
        } else {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'There are some issue in authentication of Document 360!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}
