import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Card from '../../../controls/card'
import Button from '@mui/material/Button'
import Upload from '../../Upload/component'
import Timeline from '../../WorkflowQueueTasks/WorkflowTimeline/component'

import * as _ from 'lodash'

import Loading from '../../Loading/component'
import TextField from '@mui/material/TextField'
import Drawer from '@mui/material/Drawer';

import IconButton from '@mui/material/IconButton';
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

import * as actionsWorkflow from '../../WorkflowQueueTasks/actions'
import * as actionsDownloadDocuments from '../../WorkflowQueueTasks/WorkflowQueueTaskDocuments/actions'
let vehicle = 0
let tasknumber = 0
let workflow = ''
class PaymentManagementView extends Component {

    render() {
        //console.log("this.props.items")
        //console.log(this.props.data)
        let data = this.props.data
        let exceptions = data.items ? data.items.filter(x => x.exception_type !== null) : []
        let payments = data.items ? data.items.filter(x => x.exception_type == null) : []
        return <div> {this.getSupplierDetails(data)}
            <br></br>
            {this.getSupplierAge(data)}
            <br></br>
            {
                exceptions.length ?
                    this.getPaymentExceptions(exceptions) : ''
            } {exceptions.length ? <br></br> : ''}
            {payments.length ?
                <Card
                    title={'Payment Transaction Details'}
                    subtitle={'Supplier invoice transaction.'}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={9}></Col>
                            <Col xl={1}>{/*  <strong>{'Accept All'}</strong>  */}</Col>
                            <Col xl={2}></Col>
                        </Row>
                        <Row>
                            <Col xl={9}> {/* <TextField
                                    fullWidth={false}
                                    hintText='Search text...'
                                    value={this.props.state.search_text}
                                    onChange={(event, value) => {
                                        this.props.actions.updateValue('search_text', value)
                                    }}
                                /> <RaisedButton
                                        icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'forward'} />}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            let data = {
                                                supplier_id: this.props.state.selected_supplier.value,
                                                search_text: this.props.state.search_text
                                            }
                                            this.props.actions.getPaymentList(data)

                                        }}
                                    /> */}
                            </Col>
                            <Col xl={1}>
                                {/*   <Checkbox
                                        checked={this.props.state.acceptall}
                                        onClick={(event, checked) => {
                                            this.props.actions.updateValue('acceptall', this.props.state.acceptall ? false : true)
                                            let obj = {
                                                parent_id: data.supplier_id,
                                                type: data.items.length ? data.items[0].dataset : '',
                                                column: 'acceptall',
                                                value: this.props.state.acceptall ? false : true,
                                                search_text: this.props.state.search_text
                                            }
                                            this.props.actions.getUpdateAllPaymentList(obj)
                                        }} /> */}
                            </Col>
                            <Col xl={2}> {/* <RaisedButton
                                    key={'add_more_button'}
                                    label={'Save Changes'}
                                    primary={true}
                                    onClick={(event) => {
                                        let obj = {
                                            type: 'payment_update',
                                            data: data,
                                            supplier_id: data.supplier_id,
                                            user: this.props.state.user
                                        }
                                        this.props.actions.getCommitData(obj)
                                    }}
                                />  */}</Col>
                        </Row>
                        {/*  <Row><Col xl={12}><hr /></Col></Row> */}
                        {
                            payments ?
                                payments.map((i, inx) => {
                                    return <div>
                                        <div key={'payment_data_details_' + i.suppliertransaction_id} style={styles.select_row_exception(true, i.exception_type)}>
                                            {
                                                i.authnumber || i.licenseplateno ? <div style={styles.column(150, false)}>
                                                    <strong>{'Auth #: ' + i.authnumber}</strong><br />
                                                    <strong>{'Reg #: ' + i.registration}</strong><br />
                                                    {i.tasknumber ? <strong>{'Task #: ' + i.tasknumber}</strong> : ''}<br />
                                                    {/*  {i.maintdescription}<br /> */}
                                                    <br />
                                                </div> : <div style={styles.column(150, false)}>{
                                                    i.documents.length ?
                                                        i.documents.map(d => {
                                                            return < Button className={"global_button"}
                                                                key={'add_more_button_' + i.suppliertransaction_id}
                                                                label={'Link Documents'}
                                                                primary={true}
                                                                onClick={(event) => {                                                                    
                                                                    this.props.actions.getSupplierTransactionDocument(d.filename)
                                                                }}
                                                                >{window.t.en((d.description ? d.description : d.filename))}</Button>
                                                        }) : <div><Row>
                                                            <Col xl={12}><strong>{'No Documents Found'}</strong><br />
                                                                <strong>{'Please Upload Documents'}</strong><br />
                                                                <Upload
                                                                    id={'upload_ctl_' + i.suppliertransaction_id}
                                                                    prefix={'inv'}
                                                                    header={false}
                                                                /></Col></Row>
                                                            <Row> <Col xl={12}>
                                                            
                                                            < Button className={"global_button"}
                                                                key={'add_more_button_' + i.suppliertransaction_id}
                                                                label={'Link Documents'}
                                                                primary={true}
                                                                onClick={(event) => {                                               
                                                                    this.props.actions.linkSupplierDocument(i)
                                                                }}
                                                                >{window.t.en('Link Documents')}</Button>
                                                            </Col></Row></div>
                                                }
                                                    </div>
                                            }
                                            <div style={styles.column(150, false)}>
                                                <strong>{'Invoice #: '}</strong> {i.reference}<br />
                                                <strong>{'Invoice Date: '}</strong> {moment(i.date).format('YYYY-MM-DD')} <br />
                                                <strong>{'Invoice Amount: '}</strong> {parseFloat(i.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                <strong>{'Auth Amount: '}</strong> {parseFloat(i.authamount.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                            </div>
                                            <div style={styles.column(150, false)}>
                                                <strong>{'Est. Repair Days: '}</strong> {parseFloat((i.repair_days ? i.repair_days : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                                                <strong>{'Date in for Repair: '}</strong> {i.dateinrepairs ? moment(i.dateinrepairs).format('YYYY-MM-DD') : ''} <br />
                                                <strong>{'Date Completed: '}</strong> {i.datecompleted ? moment(i.datecompleted).format('YYYY-MM-DD') : ''}<br />
                                                <strong>{'Actual Repair Days: '}</strong> {parseFloat((i.actualrepairdays ? i.actualrepairdays : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                <br />
                                            </div>
                                            <div style={styles.column(150, false)}>
                                                <strong>{'Penalty Days: '}</strong>{parseFloat((i.supplierpenaltydays ? i.supplierpenaltydays : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                                                <strong>{'Penalty Rate: '}</strong>{parseFloat((i.supplierpenaltyrate ? i.supplierpenaltyrate : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br />
                                                <strong>{'Penalty Amount: '}</strong>{parseFloat((i.supplierpenalty ? i.supplierpenalty : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                <strong>{'Short Pay: '}</strong> {parseFloat(i.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                                <br />
                                            </div>
                                            <div style={i.status == 'Rejected' ? styles.column_big(50, 0, 0, 1) :
                                                i.status == 'Not Handled' ? styles.column_big(50, 1, 0, 0) :
                                                    styles.column_big(50, 0, 1, 0)}>
                                                <strong>{i.status}</strong>
                                                <hr />
                                                {i.payment ? <strong>{parseFloat(i.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : i.payment}<br />
                                                {Number(i.shortpay) ? <strong>{-parseFloat(i.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : ''}
                                                <br />
                                            </div>

                                            {/*    <div style={styles.column(200, false)}>
                                                    <RaisedButton
                                                        label={'Task #' + i.tasknumber}
                                                        width="25%"
                                                        primary={tasknumber == i.tasknumber ? false : true}
                                                        onClick={(event) => {
                                                            vehicle = null
                                                            tasknumber = null
                                                            tasknumber = i.tasknumber
                                                            workflow = i.workflow
                                                            this.props.actions.searchWorkflowQueueTasks(tasknumber)
                                                        }}
                                                    /><br /><br />
                                                    <RaisedButton
                                                        label={'View History'}
                                                        width="25%"
                                                        primary={vehicle == i.vehicle_id ? false : true}
                                                        onClick={(event) => {
                                                            tasknumber = null
                                                            vehicle = i.vehicle_id
                                                            this.props.actions.getSelectedVehicleDetails(i.vehicle_id)
                                                        }}
                                                    /><br /><br />
                                                    <strong>{'Comments '}</strong><hr /> {<TextField
                                                        fullWidth={true}
                                                        hintText='Capture comments...'
                                                        value={i.gm_comments}
                                                        onChange={(event, value) => {
                                                            this.props.actions.updateValue('updatevalue', value)
                                                            i.gm_comments = value
                                                        }}
                                                        onBlur={(event, value) => {
                                                            let data = {
                                                                id: i.suppliertransaction_id,
                                                                parent_id: i.supplier_id,
                                                                type: i.dataset,
                                                                column: 'comments',
                                                                search_text: this.props.state.search_text
                                                            }
                                                            this.props.actions.getUpdatePaymentList(data)

                                                        }}
                                                    />}<br />
                                                    <br />
                                                </div>
                                                <div style={styles.column(10, false)}>
                                                </div>
                                                {Number(i.shortpay) ? <div style={styles.column_big(150, !i.selected, Number(i.shortpay), Number(i.totalpayment))}>
                                                    {i.payment ? <strong>{parseFloat(i.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : i.payment}<br />
                                                    {Number(i.shortpay) ? <strong>{'-' + parseFloat(i.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : i.shortpay}<hr />
                                                    {i.totalpayment ? <strong>{parseFloat(i.totalpayment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : i.totalpayment}<hr />
                                                    <RaisedButton
                                                        label={i.selected ? 'Reject Payment' : 'Accept Payment'}
                                                        primary={i.selected ? true : false}
                                                        onClick={(event) => {
                                                            event.preventDefault()
                                                            let data = {
                                                                id: i.suppliertransaction_id,
                                                                parent_id: i.supplier_id,
                                                                type: i.dataset,
                                                                column: 'accept',
                                                                search_text: this.props.state.search_text
                                                            }
                                                            this.props.actions.getUpdatePaymentList(data)
                                                        }}
                                                    />
                                                    <br />
                                                    <br />
                                                </div> :
                                                    <div style={styles.column_big(150, !i.selected, Number(i.shortpay), Number(i.totalpayment))}>
                                                        {i.totalpayment ? <strong>{parseFloat(i.totalpayment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : i.totalpayment}<hr />
                                                        <RaisedButton
                                                            label={i.selected ? 'Reject Payment' : 'Accept Payment'}
                                                            primary={i.selected ? true : false}
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                let data = {
                                                                    id: i.suppliertransaction_id,
                                                                    parent_id: i.supplier_id,
                                                                    type: i.dataset,
                                                                    column: 'accept',
                                                                    search_text: this.props.state.search_text
                                                                }
                                                                this.props.actions.getUpdatePaymentList(data)
                                                            }}
                                                        />
                                                        <br />
                                                        <br />
                                                    </div>
                                                } */}
                                            {
                                                i.authnumber || i.licenseplateno ? <div style={styles.column(50, true)}>
                                                    {/* <RaisedButton
                                                        label={'Details...'}
                                                        primary={true}
                                                        onClick={(event) => {
                                                            event.preventDefault()
                                                            this.props.actions.toggleItemDetails(inx + 1)
                                                        }}
                                                    /> */}</div> : ''
                                            }
                                        </div>
                                        {/*  {
                                                tasknumber == i.tasknumber && workflow == i.workflow &&
                                                <Timeline name={i.workflow}
                                                    team_id={1}
                                                    user_id={this.props.state.user.user_id}
                                                    workflow_queue_id={i.tasknumber}
                                                    is_visible={true}
                                                    searchable={true}
                                                />
                                            } */}

                                        {i.exception_type ?
                                            <div key={'payment_data_details_exception_' + i.suppliertransaction_id} style={styles.select_row_exception(true, i.exception_type)}>
                                                <div style={styles.column(600, false)}>
                                                    <strong>{'A reoccurrence has been picked up please see details... '}</strong><br />
                                                    {'A similar transaction of '}<strong>{i.parttype}</strong>{' was noticed within a '}<strong>{i.exception_type}</strong>{' that is irregular'}<br />

                                                </div>
                                            </div>
                                            : ''}
                                        <hr />
                                        {
                                            this.props.state.prop_drawer === (inx + 1) && <Drawer
                                                width={800}
                                                openSecondary={true}
                                                open={this.props.state.prop_drawer === (inx + 1)}
                                                onRequestChange={(event) => {
                                                    event.preventDefault()
                                                    this.props.actions.toggleItemDetails(inx + 1)
                                                }}>
                                                <br /> <br /> <br /> <br />
                                               {/*  <RaisedButton
                                                    label="Close"
                                                    primary={true}
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        this.props.actions.toggleItemDetails(inx + 1)
                                                    }}
                                                /> */}
                                                
                                                <br></br>
                                                <br></br>
                                                <Card content={<Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                i.vehicle.map(d => {
                                                                    return <Row><Col xl={12}>
                                                                        <div style={styles.column(300, false)}>
                                                                            <strong>{'Registration #: '}</strong> {d.licenseplateno}  <br />
                                                                            {d.makemodelyear}<br />
                                                                            <strong>{'Closing Odo: '}</strong> {parseFloat((d.closing_running_odo ? d.closing_running_odo : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                                        </div>
                                                                    </Col></Row>
                                                                })
                                                            }
                                                        </Container>} />

                                                <br></br>
                                                <Card  content={<Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                i.details.map(d => {
                                                                    return <Row><Col xl={12}>
                                                                        <div style={styles.column(300, false)}>
                                                                            <strong>{'Type: '}</strong> {d.itemtype}  <br />
                                                                            <strong>{'Description: '}</strong>  {d.itemdescription}  <br />
                                                                            <strong>{'Rebill: '}</strong>  {d.rebill ? 'Yes' : 'No'}  <br />
                                                                            <strong>{'Total Amount: '}</strong> {parseFloat((d.totalamount ? d.totalamount : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                            <hr></hr>
                                                                        </div>
                                                                    </Col></Row>
                                                                })
                                                            }
                                                        </Container> }/>

                                                <br></br>
                                                <Card  content={<Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                i.contract.map(d => {
                                                                    return <Row><Col xl={12}>
                                                                        <div style={styles.column(350, false)}>
                                                                            <strong>{d.contractnumber}</strong> <strong>{d.activated == true && d.terminated == false ? 'Active' : 'Terminated'}</strong><br />
                                                                            <strong>{'Start Date '}</strong>{moment(d.startdate).format('YYYY-MM-DD')} <br />
                                                                            <strong>{'End Date '}</strong>{moment(d.terminationdate).format('YYYY-MM-DD')} <br />
                                                                            <strong>{d.restructured == true ? 'Deviated' : ''}</strong><br />
                                                                        </div>
                                                                        <hr />
                                                                    </Col>
                                                                    </Row>
                                                                })
                                                            }
                                                        </Container> }/>

                                                <br></br>

                                                <Card  content={<Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                i.contract.map(d => {
                                                                    return <Row><Col xl={12}>{this.burnRate(d)}</Col></Row>
                                                                })
                                                            }
                                                        </Container>} />
                                                <br></br>
                                                <Card  content={ <Container style={{ margin: 0 }} className="custom-container">
                                                            <Row><Col xl={12}><br></br></Col></Row>
                                                            {
                                                                i.documents.map(d => {
                                                                    return <Row><Col xl={12}>
                                                                       {/*  <RaisedButton
                                                                            key={'add_more_button'}
                                                                            label={d.description ? d.description : d.filename}
                                                                            primary={true}
                                                                            onClick={(event) => {
                                                                                this.props.actions.getSupplierTransactionDocument(d.filename)
                                                                            }}
                                                                        /> */}<br></br>
                                                                    </Col></Row>
                                                                })
                                                            }
                                                        </Container> }/>
                                                <br></br>

                                                <Card  content={<Container style={{ margin: 0 }} className="custom-container">
                                                            <Row><Col xl={12}><br></br>
                                                                {/* <RaisedButton
                                                                    label={'Task #' + i.tasknumber}
                                                                    width="25%"
                                                                    primary={tasknumber == i.tasknumber ? false : true}
                                                                    onClick={(event) => {
                                                                        vehicle = null
                                                                        tasknumber = null
                                                                        tasknumber = i.tasknumber
                                                                        workflow = i.workflow
                                                                        this.props.actions.searchWorkflowQueueTasks(tasknumber)
                                                                    }}
                                                                /> */}{' '}
                                                                {/* <RaisedButton
                                                                    label={'View History'}
                                                                    width="25%"
                                                                    primary={vehicle == i.vehicle_id ? false : true}
                                                                    onClick={(event) => {
                                                                        tasknumber = null
                                                                        vehicle = i.vehicle_id
                                                                        this.props.actions.getSelectedVehicleDetails(i.vehicle_id)
                                                                    }}
                                                                /> */}
                                                                <br />
                                                                <br />
                                                            </Col></Row>
                                                            <Row><Col xl={12}><br></br>
                                                                {
                                                                    tasknumber == i.tasknumber && workflow == i.workflow &&
                                                                    <Timeline name={i.workflow}
                                                                        team_id={1}
                                                                        user_id={this.props.state.user.user_id}
                                                                        workflow_queue_id={i.tasknumber}
                                                                        is_visible={true}
                                                                        searchable={true}
                                                                    />
                                                                }
                                                                {
                                                                    vehicle == i.vehicle_id && <div>{this.props.state.selectedVehicleDetails.data.length ?
                                                                        ''//this.serviceHistory(this.props.state.selectedVehicleDetails.data[0])
                                                                        : <Loading message={'Retrieving history...'} />}</div>
                                                                }
                                                            </Col></Row>
                                                        </Container>} /> <br></br>
                                                <br></br>

                                                <Card  content={<Container style={{ margin: 0 }} className="custom-container">
                                                            <Row><Col xl={12}><br></br>
                                                                <TextField
                                                                    fullWidth={true}
                                                                    hintText='Capture comments...'
                                                                    value={i.gm_comments}
                                                                    onChange={(event, value) => {
                                                                        this.props.actions.updateValue('updatevalue', value)
                                                                        i.gm_comments = value
                                                                    }}
                                                                    onBlur={(event, value) => {
                                                                        let data = {
                                                                            id: i.suppliertransaction_id,
                                                                            parent_id: i.supplier_id,
                                                                            type: i.dataset,
                                                                            column: 'comments',
                                                                            search_text: this.props.state.search_text
                                                                        }
                                                                        this.props.actions.getUpdatePaymentList(data)

                                                                    }}
                                                                />
                                                            </Col></Row>

                                                        </Container>} />
                                                <br></br>
                                               {/*  <RaisedButton
                                                    label="Close"
                                                    primary={true}
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        this.props.actions.toggleItemDetails(inx + 1)
                                                    }}
                                                /> */}{'  |  '}
                                              {/*   <RaisedButton
                                                    label="ACCEPT PAYMENT"
                                                    primary={i.status == 'Approved' ? false : true}
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        let obj = {
                                                            type: 'approve_payment',
                                                            data: i,
                                                            supplier_id: 0,
                                                            user: this.props.state.user
                                                        }
                                                        this.props.actions.getCommitData(obj)
                                                    }}
                                                /> */}{'    '}
                                                {/* <RaisedButton
                                                    label="REJECT PAYMENT"
                                                    primary={i.status == 'Rejected' ? false : true}
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        let obj = {
                                                            type: 'reject_payment',
                                                            data: i,
                                                            supplier_id: 0,
                                                            user: this.props.state.user
                                                        }
                                                        this.props.actions.getCommitData(obj)
                                                    }}
                                                /> */}
                                            </Drawer>}
                                    </div>
                                })
                                : <Row><Col xl={12}>{'No Items Found...'}</Col></Row>}

                    </Container>
                    } />
                : ''}
            <Row><Col xl={12}><br></br></Col></Row>
            <Row><Col xl={12}><br></br></Col></Row>
            <Row><Col xl={12}><br></br></Col></Row>
        </div>
    }

    burnRate(contract) {
        let data = null

        try {
            data = contract
        }
        catch (err) { }
        return data
            ? <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12} style={styles.hr}><h3>{data.contractnumber}</h3></Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <div style={styles.column(300, false)}>
                            <strong>{'Period: '}</strong> {parseFloat((data.period ? data.period : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} <br />
                            <strong>{'Billings : '}</strong> {parseFloat((data.billingcount ? data.billingcount : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                            <strong>{'Period Remaining: '}</strong> {parseFloat((data.remainingbillingcount ? data.remainingbillingcount : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} style={styles.hr}><h3>{'Maintenance Fund'}</h3></Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <div style={styles.column(300, false)}>
                            <strong>{'Maintenance Monthly Income: '}</strong> {parseFloat((data.maintenancemonthlyselling ? data.maintenancemonthlyselling : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} <br />
                            <strong>{'Maintenance Income: '}</strong> {parseFloat((data.maintenanceincome ? data.maintenanceincome : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} <br />
                            <strong>{'Maintenance Expenditure : '}</strong> {parseFloat((data.maintenanceexpenditure ? data.maintenanceexpenditure : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            <hr></hr>
                            <strong>{'Tyres Monthly Income: '}</strong> {parseFloat((data.tyresmonthlyselling ? data.tyresmonthlyselling : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} <br />
                            <strong>{'Tyres Income: '}</strong> {parseFloat((data.tyresincome ? data.tyresincome : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} <br />
                            <strong>{'Tyres Expenditure : '}</strong> {parseFloat((data.tyresexpenditure ? data.tyresexpenditure : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            <hr></hr>
                            <strong>{'FD Take-on Excess: '}</strong> {parseFloat(((data.fd_takeon_excess ? data.fd_takeon_excess : 0)).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                            <strong>{'Excess Debit: '}</strong> {parseFloat(((data.excess_debit ? data.excess_debit : 0)).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                            <strong>{'Excess Credit: '}</strong> {parseFloat(((data.excess_credit ? data.excess_credit : 0)).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                            <strong>{'Maintenance Excess: '}</strong> {parseFloat(((data.fd_takeon_excess ? data.fd_takeon_excess : 0) + (data.excess_debit - data.excess_credit)).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                            <strong>{'Maintenance CPK : '}</strong> {parseFloat(data.maintenanceexcess.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                            <hr></hr>
                            <strong>{'Maintenance Fund Balance: '}</strong> {parseFloat((data.totalfundincome_includingexcess ? data.totalfundincome_includingexcess : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        </div>
                    </Col>
                </Row>
            </Container>

            : ''
    }
    getTotals() {

        let payments = _.sumBy(this.props.state.payments, (d) => { return parseFloat(d.amount) + parseFloat(d.discount) })
        //console.log(">>>>>>>>>>>>> payments <<<<<<<<<<<<")
        //console.log(payments)
        let credits = _.sumBy(this.props.state.credits, (d) => { return parseFloat(d.amount) })
        //console.log(">>>>>>>>>>>>> credits <<<<<<<<<<<<")
        //console.log(credits)

        let others = _.sumBy(this.props.state.others, (d) => { return parseFloat(d.amount) })
        //console.log(">>>>>>>>>>>>> others <<<<<<<<<<<<")
        //console.log(others)

        //console.log("(parseFloat(payments)+parseFloat(credits)+parseFloat(others)).toFixed(2)")

        //console.log((parseFloat(payments)).toFixed(2))
        //console.log(parseFloat(credits).toFixed(2))
        //console.log(parseFloat(others).toFixed(2))

        //console.log((parseFloat(payments) + parseFloat(credits) + parseFloat(others)).toFixed(2))
        return (parseFloat(payments) + parseFloat(credits) + parseFloat(others)).toFixed(2)

    }
    getPayable(s, c, d) {
        //s: Statement Amount, c: Payments/credit not credittedd, d: discount %

        //console.log("s", s)
        //console.log("c", c)
        //console.log("d", d)


        let st = s ? parseFloat(s) : 0.00
        let cr = c ? parseFloat(c) : 0.00
        let di = d ? parseFloat(d) : 0.00
        let tot = (st)
        let disc = (tot * (di / 100))

        //console.log("tot")
        //console.log(tot)

        //console.log("disc")
        //console.log(disc)

        //console.log("parseFloat(tot-disc).toFixed(2)")
        //console.log(parseFloat(tot - disc).toFixed(2))
        return parseFloat(tot - disc).toFixed(2)

    }
    addBlankRow(type) {
        var maxid = 0;
        switch (type) {
            case 'payments':
                {
                    this.props.state.payments.map(function (obj) {
                        if (obj.id > maxid) maxid = obj.id;
                    });
                    this.props.actions.addBlankRow(type, (maxid ? Number(maxid) : 0) + 1)
                }
                break;
            case 'credits':
                {
                    this.props.state[type].map(function (obj) {
                        if (obj.id > maxid) maxid = obj.id;
                    });
                    this.props.actions.addBlankRow(type, (maxid ? Number(maxid) : 0) + 1)
                }
                break;
            case 'others':
                {
                    this.props.state[type].map(function (obj) {
                        if (obj.id > maxid) maxid = obj.id;
                    });
                    this.props.actions.addBlankRow(type, (maxid ? Number(maxid) : 0) + 1)
                }
                break;

        }
    }
    getOthers(data) {
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row><Col xl={12}><br /></Col></Row>
            <Row><Col xl={12}><b>{'OTHER'}</b></Col></Row>
            <Row><Col xl={12}><br /></Col></Row>
            <Row style={styles.tableheader}>
                <Col xl={1}>{''}</Col>
                <Col xl={1}>{'Date'}</Col>
                <Col xl={1}>{'Invoice Number'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                <Col xl={2}>{'Reason'}</Col>
                <Col style={styles.numericFields} xl={1}>{'Total'}</Col>
            </Row>
            {
                data.length
                    ? data
                        .map((x, i) => {
                            return <div>
                                <Row style={styles.tablerow}>
                                    <Col xl={1} style={styles.verticalMiddle}>
                                        <IconButton key={'command_remove_' + x.id} style={styles.iconButton} iconStyle={styles.action}
                                            onClick={
                                                () => {

                                                    //this.props.actions.removeRow('others', x.id)
                                                }
                                            }>
                                            <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                    </Col>
                                    <Col xl={1}>
                                        <TextField
                                            id={'txtdescription'}
                                            hintText={'Date'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={moment(x.suppliertransactiondate).format("YYYY-MM-DD")}
                                            onChange={(event, value) => {
                                                //this.props.actions.setUpdateItem('others', x.id, 'invoicenumber', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtdescription'}
                                            hintText={'Invoice #'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={x.reference}
                                            onChange={(event, value) => {
                                                //this.props.actions.setUpdateItem('others', x.id, 'invoicenumber', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={(parseFloat(x.credit)).toFixed(2)}
                                            onChange={(event, value) => {
                                                //this.props.actions.setUpdateItem('others', x.id, 'amount', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={2}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Reason'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={''}
                                            onChange={(event, value) => {
                                                //this.props.actions.setUpdateItem('others', x.id, 'reason', value)
                                            }}
                                        />
                                    </Col>
                                    <Col style={styles.numericFields} xl={1}>
                                        <TextField
                                            id={'txtamount'}
                                            hintText={'Enter Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            disabled={true}
                                            value={(parseFloat(x.credit)).toFixed(2)}
                                            onChange={(event, value) => {
                                                //this.props.actions.setValue('market', value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        })
                    : <div>{'No Items Found...'}</div>
            }
        </Container>
    }
    getSupplierDetails(data) {
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row><Col xl={12}><br /></Col></Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.column(400, false)}>
                        <strong>{'Account Number'}</strong> {data.accountnumber ? data.accountnumber : 'Not Set'}<br />
                        <strong>{'Supplier'}</strong> {data.suppliername ? data.suppliername : 'Not Set'}<br />
                        <strong>{'Registration Number'}</strong> {data.registrationnumber ? data.registrationnumber : 'Not Set'}<br />
                        <strong>{'VAT Number'}</strong> {data.vatnumber ? data.vatnumber : 'Not Set'}<br />
                        <hr></hr>
                        <strong>{'BEE Cert. Expirytificate Received?'}</strong> {data.beecertificatereceived == true ? 'Yes' : data.beecertificatereceived == false ? 'No' : 'Not Set'}<br />
                        <strong>{'BEE Expiry Date'}</strong> {data.beecertificateexpdate ? moment(data.beecertificateexpdate).format('YYYY-MM-DD') : 'Not Set'}<br />
                        <strong>{'TAX Clearance Required?'}</strong> {data.taxclearancecertificaterequired == true ? 'Yes' : data.taxclearancecertificaterequired == false ? 'No' : 'Not Set'}<br />
                        <strong>{'TAX Clearance Received?'}</strong> {data.taxclearancecertificatereceived == true ? 'Yes' : data.taxclearancecertificatereceived == false ? 'No' : 'Not Set'}<br />
                        <strong>{'TAX Clearance Expiry Date'}</strong> {data.taxclearancecertificateexpirydate ? moment(data.taxclearancecertificateexpirydate).format('YYYY-MM-DD') : 'Not Set'}<br />
                        <hr></hr>
                        <strong>{'Contact Person'}</strong> {data.contactperson ? data.contactperson : 'Not Set'}<br />
                        <strong>{'Contact Number'}</strong> {data.contactnumber ? data.contactnumber : 'Not Set'}<br />
                        <strong>{'Contact Email'}</strong> {data.contactemail ? data.contactemail : 'Not Set'}<br />
                        <hr></hr>
                        <strong>{'Bank Name'}</strong> {data.bankname ? data.bankname : 'Not Set'}<br />
                        <strong>{'Account Name'}</strong> {data.bankaccountname ? data.bankaccountname : 'Not Set'}<br />
                        <strong>{'Account Number'}</strong> {data.bankaccountnumber ? data.bankaccountnumber : 'Not Set'}<br />
                        <strong>{'Branch Number'}</strong> {data.bankbranchnumber ? data.bankbranchnumber : 'Not Set'}<br />
                    </div>
                </Col>
            </Row>
        </Container>
    }
    getSupplierAge(data) {
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row><Col xl={12}><br /></Col></Row>
            <Row>
                <Col xl={12}>
                    <div>{
                        data
                            ?
                            <div>
                                <table style={styles.box}>
                                    <tr>
                                        <td style={styles.tdField}>{'BEE Level:'}</td>
                                        <td style={styles.tdValue}>{data.beelevel}</td>
                                        <td style={styles.tdField}>{' '}</td>
                                        <td style={styles.tdField}>{'BEE Score:'}</td>
                                        <td style={styles.tdValue}>{data.beescore}</td>
                                    </tr>
                                    <tr style={styles.trField}>
                                        <td style={styles.trField} colspan="5">{''}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tdField}>{'BEE Cert. Expiry:'}</td>
                                        <td style={styles.tdValue}>{data.beecertificateexpdate ? data.beecertificateexpdate.split('T')[0] : 'Not Set'}</td>
                                        <td style={styles.tdField}>{' '}</td>
                                        <td style={styles.tdField}>{'BEE Type:'}</td>
                                        <td style={styles.tdValue}>{data.beetype}</td>
                                    </tr>
                                    <tbody>
                                        <tr style={styles.trField}>
                                            <td style={styles.trField} colspan="5">{''}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.tdField}>{'Current:'}</td>
                                            <td style={styles.tdValue}>{data.agedtotal0 ? parseFloat(data.agedtotal0).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                            <td style={styles.tdField}>{' '}</td>
                                            <td style={styles.tdField}>{'Purchase MTD:'}</td>
                                            <td style={styles.tdValue}>{data.purchasesmtd ? parseFloat(data.purchasesmtd).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.trField} colspan="5">{''}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.tdField}>{'30 Days:'}</td>
                                            <td style={styles.tdValue}>{data.agedtotal30 ? parseFloat(data.agedtotal30).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                            <td style={styles.tdField}>{' '}</td>
                                            <td style={styles.tdField}>{'Purchase YTD:'}</td>
                                            <td style={styles.tdValue}>{data.purchasesytd ? parseFloat(data.purchasesytd).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.trField} colspan="5">{''}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.tdField}>{'60 Days:'}</td>
                                            <td style={styles.tdValue}>{data.agedtotal60 ? parseFloat(data.agedtotal60).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                            <td style={styles.tdField}>{' '}</td>
                                            <td style={styles.tdField}>{'Purchase LTD:'}</td>
                                            <td style={styles.tdValue}>{data.purchasesltd ? parseFloat(data.purchasesltd).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.trField} colspan="5">{''}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.tdField}>{'90 Days:'}</td>
                                            <td style={styles.tdValue}>{data.agedtotal90 ? parseFloat(data.agedtotal90).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                            <td style={styles.tdField}>{' '}</td>
                                            <td style={styles.tdField}>{'Total:'}</td>
                                            <td style={styles.tdValue}>{data.agedtotal ? parseFloat(data.agedtotal).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.trField} colspan="5">{''}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.tdField}>{'120 Days:'}</td>
                                            <td style={styles.tdValue}>{data.agedtotal120 ? parseFloat(data.agedtotal120).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                            <td style={styles.tdField}>{' '}</td>
                                            <td style={styles.tdField}>{'Unallocated:'}</td>
                                            <td style={styles.tdValue}>{data.agedtotalunallocated ? parseFloat(data.agedtotalunallocated).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                        </tr>
                                        <tr style={styles.trField}>
                                            <td style={styles.trField} colspan="5">{''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={styles.box1}><tbody>
                                    <tr style={styles.trField}>
                                        <td style={styles.tdField}>{'Spend Limit:'}</td>
                                        <td style={styles.tdValue}>{data.suplierspendlimit ? parseFloat(data.suplierspendlimit).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                        <td style={styles.tdField}>{' '}</td>
                                        <td style={styles.tdField}>{'Supplier Usage:'}</td>
                                        <td style={styles.tdValue}>{data.suplierusage ? parseFloat(data.suplierusage).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            : ''
                    }</div>
                </Col>
            </Row>
        </Container>
    }
    getPaymentExceptions(data) {
        let exceptions = data
        return <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
            <Row>
                <Col xl={9}></Col>
                <Col xl={1}>{/* <strong>{'Accept All'}</strong> */}</Col>
                <Col xl={2}></Col>
            </Row>
            <Row>
                <Col xl={9}> </Col>
                <Col xl={1}> </Col>
                <Col xl={2}> {/* <RaisedButton
                    key={'add_more_button'}
                    label={'Save Changes'}
                    primary={true}
                    onClick={(event) => {
                        let obj = {
                            type: 'payment_update',
                            data: data,
                            supplier_id: data.supplier_id,
                            user: this.props.state.user
                        }
                        this.props.actions.getCommitData(obj)
                    }}
                /> */} </Col>
            </Row>
            {/* <Row><Col xl={12}><hr /></Col></Row> */}
            {
                exceptions ?
                    exceptions.map((i, inx) => {
                        return <div>
                            <div key={'payment_data_details_exception_' + i.suppliertransaction_id} style={styles.select_row(true, null)}>
                                <div style={styles.column(200, false)}>
                                    <strong>{'Auth #: ' + i.authnumber}</strong><br />
                                    <strong>{'Reg #: ' + i.registration}</strong><br />
                                    {/*  {i.maintdescription}<br /> */}
                                    <br />
                                </div>
                                <div style={styles.column(150, false)}>
                                    <strong>{'Invoice #: '}</strong> {i.reference}<br />
                                    <strong>{'Invoice Date: '}</strong> {moment(i.date).format('YYYY-MM-DD')} <br />
                                    <strong>{'Invoice Amount: '}</strong> {parseFloat(i.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                    <strong>{'Auth Amount: '}</strong> {parseFloat(i.authamount.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                    <strong>{'Short Pay: '}</strong> {parseFloat(i.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />

                                </div>
                                <div style={styles.column(250, false)}>
                                    <strong>{'Est. Repair Days: '}</strong> {parseFloat((i.repair_days ? i.repair_days : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                                    <strong>{'Date in for Repair: '}</strong> {i.dateinrepairs ? moment(i.dateinrepairs).format('YYYY-MM-DD') : ''} <br />
                                    <strong>{'Date Completed: '}</strong> {i.datecompleted ? moment(i.datecompleted).format('YYYY-MM-DD') : ''}<br />
                                    <strong>{'Actual Repair Days: '}</strong> {parseFloat((i.actualrepairdays ? i.actualrepairdays : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                    <br />
                                </div>
                                <div style={styles.column(200, false)}>
                                    <strong>{'Penalty Days: '}</strong>{parseFloat((i.supplierpenaltydays ? i.supplierpenaltydays : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                                    <strong>{'Penalty Rate: '}</strong>{parseFloat((i.supplierpenaltyrate ? i.supplierpenaltyrate : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br />
                                    <strong>{'Penalty Amount: '}</strong>{parseFloat((i.supplierpenalty ? i.supplierpenalty : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                    <strong>{'Payment Amount: '}</strong>{parseFloat(i.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br />
                                    <br />
                                </div>
                                {/*    {
                                    i.exception_type ?
                                        <div style={styles.column(180, true)}>
                                            <RaisedButton
                                                label={'Related Task #' + i.prev_task}
                                                width="25%"
                                                primary={tasknumber == i.prev_task ? false : true}
                                                onClick={(event) => {
                                                    vehicle = null
                                                    tasknumber = null
                                                    tasknumber = i.prev_task
                                                    workflow = i.workflow
                                                    this.props.actions.searchWorkflowQueueTasks(tasknumber)
                                                }}
                                            /><br /><br />
                                            <RaisedButton
                                                label={'Task #' + i.tasknumber}
                                                width="25%"
                                                primary={tasknumber == i.tasknumber ? false : true}
                                                onClick={(event) => {
                                                    vehicle = null
                                                    tasknumber = null
                                                    tasknumber = i.tasknumber
                                                    workflow = i.workflow
                                                    this.props.actions.searchWorkflowQueueTasks(tasknumber)
                                                }}
                                            /><br /><br />
                                            <RaisedButton
                                                label={'View History'}
                                                width="25%"
                                                primary={vehicle == i.vehicle_id ? false : true}
                                                onClick={(event) => {
                                                    tasknumber = null
                                                    vehicle = i.vehicle_id
                                                    this.props.actions.getSelectedVehicleDetails(i.vehicle_id)
                                                }}
                                            />
                                            <br />
                                            <br />
                                        </div> : <div style={styles.column(180, true)}>
                                            <RaisedButton
                                                label={'Task #' + i.tasknumber}
                                                width="25%"
                                                primary={tasknumber == i.tasknumber ? false : true}
                                                onClick={(event) => {
                                                    vehicle = null
                                                    tasknumber = null
                                                    tasknumber = i.tasknumber
                                                    workflow = i.workflow
                                                    this.props.actions.searchWorkflowQueueTasks(tasknumber)
                                                }}
                                            /><br /><br />
                                            <RaisedButton
                                                label={'View History'}
                                                width="25%"
                                                primary={vehicle == i.vehicle_id ? false : true}
                                                onClick={(event) => {
                                                    tasknumber = null
                                                    vehicle = i.vehicle_id
                                                    this.props.actions.getSelectedVehicleDetails(i.vehicle_id)
                                                }}
                                            />
                                            <br />
                                            <br />
                                        </div>
                                }
                                <div style={styles.column(20, true)}></div>
                                <div style={styles.column(350, true)}>
                                    <strong>{'Comments '}</strong><hr /> <TextField
                                        fullWidth={true}
                                        hintText='Capture comments...'
                                        value={i.gm_comments}
                                        onChange={(event, value) => {
                                            this.props.actions.updateValue('updatevalue', value)
                                            i.gm_comments = value
                                        }}
                                        onBlur={(event, value) => {
                                            let data = {
                                                id: i.suppliertransaction_id,
                                                parent_id: i.supplier_id,
                                                type: i.dataset,
                                                column: 'comments',
                                                search_text: this.props.state.search_text
                                            }
                                            this.props.actions.getUpdatePaymentList(data)

                                        }}
                                    />

                                </div>
                                <div style={styles.column(10, false)}></div>
                                {Number(i.shortpay) ? <div style={styles.column_big(150, !i.selected, Number(i.shortpay), Number(i.totalpayment))}>
                                    {i.payment ? <strong>{parseFloat(i.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : i.payment}<br />
                                    {Number(i.shortpay) ? <strong>{'-' + parseFloat(i.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : i.shortpay}<hr />
                                    {i.totalpayment ? <strong>{parseFloat(i.totalpayment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : i.totalpayment}<hr />
                                    <RaisedButton
                                        label={i.selected ? 'Reject Payment' : 'Accept Payment'}
                                        primary={i.selected ? true : false}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            let data = {
                                                id: i.suppliertransaction_id,
                                                parent_id: i.supplier_id,
                                                type: i.dataset,
                                                column: 'accept',
                                                search_text: this.props.state.search_text
                                            }
                                            this.props.actions.getUpdatePaymentList(data)
                                        }}
                                    />
                                    <br />
                                    <br />
                                </div> :
                                    <div style={styles.column_big(150, !i.selected, Number(i.shortpay), Number(i.totalpayment))}>
                                        {i.totalpayment ? <strong>{parseFloat(i.totalpayment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> : i.totalpayment}<hr />
                                        <RaisedButton
                                            label={i.selected ? 'Reject Payment' : 'Accept Payment'}
                                            primary={i.selected ? true : false}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                let data = {
                                                    id: i.suppliertransaction_id,
                                                    parent_id: i.supplier_id,
                                                    type: i.dataset,
                                                    column: 'accept',
                                                    search_text: this.props.state.search_text
                                                }
                                                this.props.actions.getUpdatePaymentList(data)
                                            }}
                                        />
                                        <br />
                                        <br />
                                    </div>
                                } */}
                                {
                                    i.authnumber || i.licenseplateno ? <div style={styles.column(50, true)}>
                                       {/*  <RaisedButton
                                            label={'Details...'}
                                            primary={true}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.toggleItemDetails(inx + 1)
                                            }}
                                        /> */}</div> : ''
                                }
                            </div>
                            {
                                i.exception_type ?
                                    <div key={'payment_data_details_exception_' + i.suppliertransaction_id} style={styles.select_row_exception(true, i.exception_type)}>
                                        <div style={styles.column(650, false)}>
                                            <strong style={styles.color('red')} >{'TRANSACTION DETAILS'}</strong><br />
                                            {'A similar transaction type '}<strong>{i.parttype}</strong>{' was noticed within a '}<strong>{i.exception_type}</strong>{' that is irregular'}<br />
                                            <strong>{i.parttype + ' Replacement KM: '}</strong>{parseFloat((i.basket_replacement_km ? i.basket_replacement_km : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                                            <strong>{i.parttype + ' Replacement Period: '}</strong>{parseFloat((i.basket_replacement_month ? i.basket_replacement_month : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br /><br />

                                        </div>
                                        <div style={styles.column(450, false)}>
                                            <br />
                                            <strong>{'Previous Date: '}</strong>{moment(i.prev_trans_date).format('YYYY-MM-DD')}<br />
                                            <strong>{'Current Date: '}</strong>{moment(i.curr_trans_date).format('YYYY-MM-DD')}<br />
                                            <strong>{'Months Between: '}</strong>{parseFloat((i.months_between ? i.months_between : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br /><br />

                                        </div>
                                        <div style={styles.column(200, false)}>
                                            <br />
                                            <strong>{'Previous Odometer: '}</strong>{parseFloat((i.prev_trans_odo ? i.prev_trans_odo : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                                            <strong>{'Current Odometer: '}</strong>{parseFloat((i.curr_trans_odo ? i.curr_trans_odo : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}<br />
                                            <strong>{'Distance Between: '}</strong>{parseFloat((i.distance_between ? i.distance_between : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                        </div>
                                        {/*  {
                                            tasknumber == i.tasknumber && workflow == i.workflow &&
                                            <Timeline name={i.workflow}
                                                team_id={1}
                                                user_id={this.props.state.user.user_id}
                                                workflow_queue_id={i.tasknumber}
                                                is_visible={true}
                                                searchable={true}
                                            />
                                        }
                                        {
                                            tasknumber == i.prev_task && workflow == i.workflow &&
                                            <Timeline name={i.workflow}
                                                team_id={1}
                                                user_id={this.props.state.user.user_id}
                                                workflow_queue_id={i.prev_task}
                                                is_visible={true}
                                                searchable={true}
                                            />
                                        } */}
                                        {/*  
                                            vehicle == i.vehicle_id && <div>{this.props.state.selectedVehicleDetails.data.length ?
                                                this.serviceHistory(this.props.state.selectedVehicleDetails.data[0])
                                                : <Loading message={'Retrieving history...'} />}</div>
                                         */}
                                        <br />
                                        <br />
                                    </div>
                                    : ''}

                            {
                                this.props.state.prop_drawer === (inx + 1) && <Drawer
                                    width={800}
                                    openSecondary={true}
                                    open={this.props.state.prop_drawer === (inx + 1)}
                                    onRequestChange={(event) => {
                                        event.preventDefault()
                                        this.props.actions.toggleItemDetails((inx + 1))
                                    }}>
                                    <br /> <br /> <br /> <br />
                                    {/* <RaisedButton
                                        label="Close"
                                        primary={true}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleItemDetails((inx + 1))
                                        }}
                                    /> */}
                                    <br></br>
                                    <br></br>
                                    <Card content={<Container style={{ margin: 0 }} className="custom-container">
                                        {
                                            i.vehicle.map(d => {
                                                return <Row><Col xl={12}>
                                                    <div style={styles.column(300, false)}>
                                                        <strong>{'Registration #: '}</strong> {d.licenseplateno}  <br />
                                                        {d.makemodelyear}<br />
                                                        <strong>{'Closing Odo: '}</strong> {parseFloat((d.closing_running_odo ? d.closing_running_odo : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                    </div>
                                                </Col></Row>
                                            })
                                        }
                                    </Container>} />

                                    <br></br>
                                    <Card content={<Container style={{ margin: 0 }} className="custom-container">
                                        <Row>
                                            <Col xl={5}><strong>{'Description'}</strong></Col>
                                            <Col xl={1}><strong>{'Rebill'}</strong></Col>
                                            <Col xl={2}><strong>{'Amount'}</strong></Col>
                                            <Col xl={2}><strong>{'Discount'}</strong></Col>
                                            <Col xl={2}><strong>{'VAT'}</strong></Col>
                                        </Row>
                                        {
                                            i.details.map(d => {
                                                return <Row>
                                                    <Col xl={5}>{d.itemdescription}</Col>
                                                    <Col xl={1}>{d.rebill ? 'Yes' : 'No'}</Col>
                                                    <Col xl={2}>{parseFloat((d.amount ? d.amount : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                    <Col xl={2}>{parseFloat((d.discountamount ? d.discountamount : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                    <Col xl={2}>{parseFloat((d.vat ? d.vat : 0).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>

                                                </Row>
                                            })
                                        }
                                        <hr></hr>
                                        <Row>
                                            <Col xl={6}></Col>
                                            <Col xl={4}><strong>{'Total Amount: '}</strong> </Col>
                                            <Col xl={2}>{_.sumBy(i.details, (d) => { return parseFloat(Number(d.totalamount)) }).toFixed(2)}</Col>
                                        </Row>
                                    </Container>} />

                                    <br></br>
                                    <Card content={<Container style={{ margin: 0 }} className="custom-container">
                                        {
                                            i.contract.map(d => {
                                                return <Row><Col xl={12}>
                                                    <div style={styles.column(350, false)}>
                                                        <strong>{d.contractnumber}</strong> <strong>{d.activated == true && d.terminated == false ? 'Active' : 'Terminated'}</strong><br />
                                                        <strong>{'Start Date '}</strong>{moment(d.startdate).format('YYYY-MM-DD')} <br />
                                                        <strong>{'End Date '}</strong>{moment(d.terminationdate).format('YYYY-MM-DD')} <br />
                                                        <strong>{d.restructured == true ? 'Deviated' : ''}</strong><br />
                                                    </div>
                                                    <hr />
                                                </Col>
                                                </Row>
                                            })
                                        }
                                    </Container>} />
                                    <br></br>

                                    <Card content={<Container style={{ margin: 0 }} className="custom-container">
                                        {
                                            i.contract.map(d => {
                                                return <Row><Col xl={12}>{this.burnRate(d)}</Col></Row>
                                            })
                                        }
                                    </Container>} />

                                    <br></br>
                                    <Card content={<Container style={{ margin: 0 }} className="custom-container">
                                        <Row><Col xl={12}><br></br></Col></Row>
                                        {
                                            i.documents.map(d => {
                                                return <Row><Col xl={12}>
                                                    {/* <RaisedButton
                                                        key={'add_more_button'}
                                                        label={d.description ? d.description : d.filename}
                                                        primary={true}
                                                        onClick={(event) => {
                                                            this.props.actions.getSupplierTransactionDocument(d.filename)
                                                        }}
                                                    /> */}<br></br>
                                                </Col></Row>
                                            })
                                        }
                                    </Container>} />
                                    <br></br>

                                    <Card content={<Container style={{ margin: 0 }} className="custom-container">
                                        <Row><Col xl={12}><br></br>
                                            {
                                                i.exception_type ?
                                                    <div style={styles.column(600, true)}>
                                                       {/*  <RaisedButton
                                                            label={'Related Task #' + i.prev_task}
                                                            width="25%"
                                                            primary={tasknumber == i.prev_task ? false : true}
                                                            onClick={(event) => {
                                                                vehicle = null
                                                                tasknumber = null
                                                                tasknumber = i.prev_task
                                                                workflow = i.workflow
                                                                this.props.actions.searchWorkflowQueueTasks(tasknumber)
                                                            }}
                                                        /> */}{' '}
                                                        {/* <RaisedButton
                                                            label={'Task #' + i.tasknumber}
                                                            width="25%"
                                                            primary={tasknumber == i.tasknumber ? false : true}
                                                            onClick={(event) => {
                                                                vehicle = null
                                                                tasknumber = null
                                                                tasknumber = i.tasknumber
                                                                workflow = i.workflow
                                                                this.props.actions.searchWorkflowQueueTasks(tasknumber)
                                                            }}
                                                        /> */}{' '}
                                                        {/* <RaisedButton
                                                            label={'View History'}
                                                            width="25%"
                                                            primary={vehicle == i.vehicle_id ? false : true}
                                                            onClick={(event) => {
                                                                tasknumber = null
                                                                vehicle = i.vehicle_id
                                                                this.props.actions.getSelectedVehicleDetails(i.vehicle_id)
                                                            }}
                                                        /> */}
                                                        <br />
                                                        <br />
                                                    </div> : <div style={styles.column(600, true)}>
                                                        {/* <RaisedButton
                                                            label={'Task #' + i.tasknumber}
                                                            width="25%"
                                                            primary={tasknumber == i.tasknumber ? false : true}
                                                            onClick={(event) => {
                                                                vehicle = null
                                                                tasknumber = null
                                                                tasknumber = i.tasknumber
                                                                workflow = i.workflow
                                                                this.props.actions.searchWorkflowQueueTasks(tasknumber)
                                                            }}
                                                        /> */}{' '}
                                                        {/* <RaisedButton
                                                            label={'View History'}
                                                            width="25%"
                                                            primary={vehicle == i.vehicle_id ? false : true}
                                                            onClick={(event) => {
                                                                tasknumber = null
                                                                vehicle = i.vehicle_id
                                                                this.props.actions.getSelectedVehicleDetails(i.vehicle_id)
                                                            }}
                                                        /> */}
                                                        <br />
                                                        <br />
                                                    </div>
                                            }
                                        </Col></Row>
                                        <Row><Col xl={12}><br></br>
                                            {
                                                tasknumber == i.tasknumber && workflow == i.workflow &&
                                                <Timeline name={i.workflow}
                                                    team_id={1}
                                                    user_id={this.props.state.user.user_id}
                                                    workflow_queue_id={i.tasknumber}
                                                    is_visible={true}
                                                    searchable={true}
                                                />
                                            }
                                            {
                                                tasknumber == i.prev_task && workflow == i.workflow &&
                                                <Timeline name={i.workflow}
                                                    team_id={1}
                                                    user_id={this.props.state.user.user_id}
                                                    workflow_queue_id={i.prev_task}
                                                    is_visible={true}
                                                    searchable={true}
                                                />
                                            }
                                            {
                                                vehicle == i.vehicle_id && <div>{this.props.state.selectedVehicleDetails.data.length ?
                                                    ''//this.serviceHistory(this.props.state.selectedVehicleDetails.data[0])
                                                    : <Loading message={'Retrieving history...'} />}</div>
                                            }
                                        </Col></Row>
                                    </Container>} />
                                    <br></br>
                                    <Card content={<Container style={{ margin: 0 }} className="custom-container">
                                        <Row><Col xl={12}><br></br>
                                            <TextField
                                                fullWidth={true}
                                                hintText='Capture comments...'
                                                value={i.gm_comments}
                                                onChange={(event, value) => {
                                                    this.props.actions.updateValue('updatevalue', value)
                                                    i.gm_comments = value
                                                }}
                                                onBlur={(event, value) => {
                                                    let data = {
                                                        id: i.suppliertransaction_id,
                                                        parent_id: i.supplier_id,
                                                        type: i.dataset,
                                                        column: 'comments',
                                                        search_text: this.props.state.search_text
                                                    }
                                                    this.props.actions.getUpdatePaymentList(data)

                                                }}
                                            />
                                        </Col></Row>

                                    </Container>} />
                                    <br></br>
                                   {/*  <RaisedButton
                                        label="Close"
                                        primary={true}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleItemDetails(inx + 1)
                                        }}
                                    /> */}{'    '}
                                    {/* <RaisedButton
                                        label="ACCEPT PAYMENT"
                                        primary={true}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleItemDetails(inx + 1)
                                        }}
                                    /> */}{'    '}
                                    {/* <RaisedButton
                                        label="REJECT PAYMENT"
                                        primary={false}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleItemDetails(inx + 1)
                                        }}
                                    /> */}
                                </Drawer>}
                            <hr />
                            <br />
                            <br />
                        </div>
                    })

                    : <Row><Col xl={12}>{'No Exceptions Found...'}</Col></Row>}
        </Container>
    }

}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.supplierpaymentapproval,
            timeline: {
                ...state.workflowTimeline
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...actionsWorkflow, ...actionsDownloadDocuments }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentManagementView)