import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, IconButton } from '@mui/material'

import Icon from '../../Icon/component'

import VisibilityIcon from '@mui/icons-material/Visibility';
import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewSupplierTransactions from '../../../controls/view_supplier_transaction'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Form from '../../../controls/form'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'
import Drawer from '../../../controls/drawer'

import Checkbox from '../../../controls/checkbox'
import CardControl from '../../../controls/card'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import * as styles from './styles'
import * as actions from './actions'

class CreditorsPaymentAllocate extends Component {


    getPenaltiesOtherConfirm(penalties_data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Reference</th>
                                        <th>Reason</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        penalties_data.map((x,inx) => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                <td>{x.date}</td>
                                                <td>{x.reference}</td>
                                                <td>{x.reason}</td>
                                                <td>{x.amount} </td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <th colSpan={3}></th>
                                        <th style={{textAlign: 'right', paddingLeft: '20px'}}>Total: {_.sumBy(penalties_data, (d) => { return parseFloat(d.amount)})}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    } 

    getPaymentsNotCreditedConfirm(credited_data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Reference</th>
                                        <th>Reason</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        credited_data.map((x,inx) => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                <td>{x.date}</td>
                                                <td>{x.reference}</td>
                                                <td>{x.reason}</td>
                                                <td>{x.amount}</td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <th colSpan={3}></th>
                                        <th style={{textAlign: 'right', paddingLeft: '20px'}}>Total: {_.sumBy(credited_data, (d) => { return parseFloat(d.amount)})}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    } 

    invoiceItems(supplier_transactions) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    
                <div>
                    <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Invoice Number</th>
                                        <th>Auth Number</th>
                                        <th>Description</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice Amount</th>
                                        <th>Short Payment</th>
                                        <th>Pay Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        supplier_transactions.filter(x => x.pay_status).map((x,inx) => {
                                            return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <th>
                                                <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                                                        //console.log('suppliertransactiondocumentx',x)
                                                        let documents = x.suppliertransactiondocument.map(x => {
                                                            return x.document
                                                        })
                                                        this.props.actions.setInputValue('transactionx', {...x, documents: documents})
                                                        this.props.actions.setInputValue('win_view_documents', true)
                                                    }}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </th>
                                            <td>{x.reference}</td>
                                            <td>{x.authnumber}</td>
                                            <td>{x.description}</td>
                                            <td>{moment(x.suppliertransactiondate).format('DD-MM-YYYY')}</td>
                                            <td>{x.amount}</td>
                                            <td>{x.shortpayment}</td>
                                            <td>{x.allocationamount}</td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <th colSpan={5}></th>
                                        <th>{_.sumBy(supplier_transactions.filter(x => x.pay_status), (d) => { return parseFloat(d.amount ? d.amount : 0)}).toFixed(2)}</th>
                                        <th>{_.sumBy(supplier_transactions.filter(x => x.pay_status), (d) => { return parseFloat(d.shortpayment ? d.shortpayment : 0)}).toFixed(2)}</th>
                                        <th>{_.sumBy(supplier_transactions.filter(x => x.pay_status), (d) => { return parseFloat(d.allocationamount ? d.allocationamount : 0)}).toFixed(2)}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                </Col>
            </Row>
        </Container>
    } 
    
    paymentSummaryConfirm(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                <Table className="table table-hover text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>Statement Amount</th>
                            <th>Allocated Amount</th>
                            <th>Short Payment</th>
                            <th>Discount Percentage</th>
                            <th>Discount Amount</th>
                            <th>Payable Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                            <td>{data.statement_amount}</td>
                            <td>{data.allocated_amount}</td>
                            <td>{data.shortpayment_amount}</td>
                            <td>{data.discount_percentage}</td>
                            <td>{data.discount_amount}</td>
                            <td>{data.payable_amount}</td>
                    </tbody>
                </Table>
                </Col>
            </Row>
        </Container>
    }

    getConfirm(data) {
        return <div>
        <ViewSupplier key={data.supplier_id} id={data.supplier_id} />
        <Card
            title={'Allocate Item'}
            subtitle={'The following items will be allocated'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <Fields 
                            fields={[
                                {size: 6, label: 'Supplier Name', value: data.suppliername},
                                {size: 6, label: 'Client Name', value: data.client},
                                {size: 6, label: 'Payment Date', value: data.payment_date},
                                {size: 6, label: 'Statement Date', value: data.statement_date},
                                {size: 12, label: 'Comments', value: data.confirm.comments}
                            ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        &nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <h5>&nbsp;&nbsp;&nbsp;{'Selected Invoice List'}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.invoiceItems(data.supplier_transactions)}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <h5>&nbsp;&nbsp;&nbsp;{'Payment Summary'}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.paymentSummaryConfirm(data)}
                    </Col>
                </Row>
            </Container>}
        />
        <Card
            title={'Documents'}
            subtitle={'Skip if already uploaded on purchase order step '}
            content={<PdfViewer
                key={'odometer_change'}
                name={'odometer_change'}
                documents={data.documents}
                edit={false}
                multiple={false}
                workflow_queue_data={null}
            />}
        /><Card
        title={'Approval Comments '}
        subtitle={'Approval approval comments'}
        content={<Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={6}>
                    <TextField
                        variant='outlined'
                        id={'txt_allocationreference'}
                        label={'Enter Allocation Reference'}
                        fullWidth={true}
                        value={this.props.state.allocationreference}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('allocationreference', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={6}>
                    <TextField
                        variant='outlined'
                        id={'txt_allocationreason'}
                        label={'Enter Allocation Reason'}
                        fullWidth={true}
                        value={this.props.state.allocationreason}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('allocationreason', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={12}>
                    &nbsp;
                </Col>
                <Col xl={12}>
                    <TextField
                        variant='outlined'
                        id={'txt_comments'}
                        label={'Enter Comments'}
                        fullWidth={true}
                        value={this.props.state.comments}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('comments', event.target.value)
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
        </Container>
        } />
        </div>
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('CreditorsPaymentReviewx', data.context_data.data.data.data[0])
        data = data.context_data.data.data.data[0].step1
        //console.log('CreditorsPaymentReviewx step1', data)
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row>
        <Col xl={12}>
            {this.getConfirm(data)}

            <Drawer 
                onClose={(event, value) => {
                    this.props.actions.setInputValue('win_view_documents', false)
                }}
                anchor={'right'}
                width={'60%'}
                open={this.props.state.win_view_documents}
                title={'Transaction Documents'}
                subtitle={'For ' + this.props.state.transactionx.authnumber}
                content={
                    <PdfViewer
                        key={'document_viewer_approval'}
                        name={'booking'}
                        workflow={'fml_customer_quote'}
                        task={'tsk_purchase_order_review_and_send'}
                        documents={this.props.state.transactionx.documents ? this.props.state.transactionx.documents : []}
                        multiple={true}
                        edit={false}
                    />
                }
            />
            </Col>
        </Row>
        </Container> 
    }

    render() {
        
        this.data = null
        try {
        } catch (error) {

        }
        
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.creditorsPaymentAllocate
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditorsPaymentAllocate)