import * as types from './types'

export const reset = () => {
    return (dispatch) => {       
        dispatch({
            type: types.RESET_COMPONENT,
        })
    }
}
export const setValue = (prop, val) => {
    return (dispatch) => {       
        dispatch({
            type: types.SET_STATEMENT_REVIEW_INPUT_VALUE,
            payload: {prop:prop,val:val}
        })
    }
}