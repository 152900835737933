import moment from 'moment'
//import validator from '../../validate'
import * as validator from '../../helpers/validator'
import * as _ from 'lodash'

import * as appTypes from '../App/types'
import * as types from './types'
import * as redirectActions from '../Redirect/actions'
import * as readComponentActions from '../ReadComponent/actions'
import * as masterComponentViewActions from '../../views/MasterComponentView/actions'
import { getComponentTitle } from '../../helper';

export const loadComponent = (component, id, stop, surrogate) => {
    return (dispatch, getState) => {
        
        //console.log('Form loadComponent actions1', component, id,stop, surrogate)
        if (!surrogate) {
            dispatch(getComponentSchema(component, id, stop))
            dispatch({
                type: types.FORM_LOAD_COMPONENT,
                payload: {
                    component: component,
                    data: getState().components[component].data
                }
            })
        }
        else {
            dispatch(getComponentData(component, id, surrogate))
        }
    }
}

export const saveComponentData = (component) => {
    const getData = () => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name] && !x.surrogate ) {
                if (x.key) {
                    if (component.data[x.name] && component.data[x.name].input) {
                        obj[x.name] = component.data[x.name].input
                    }
                }
                else {
                    obj[x.name] = component.data[x.name] ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }
    
    const getValidation = () => {
        return component.component_field.reduce((obj, x) => {
            if (!component.data[x.name].validation.valid) {
                obj[x.name] = component.data[x.name].validation.message // !== '' ? component.data[x.name].input : null
            }
            return obj
        }, {})
    }

    return (dispatch, getState) => {
        
        //console.log('componentx saveComponentData', component)
        //dispatch(masterComponentViewActions.isLoading(true))
        dispatch(validateForm(component.name))

        const data = getData()

        if (getState().form.valid) 
        {
            //console.log('saveComponentData form.valid', data)
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Attempting to commit form data',
                    overflowText: 'Action',
                    text: '',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
            
            dispatch({ 
                type: types.SAVE_COMPONENT_DATA,
                payload: {
                    component: component,
                    data: data
                }
            })
        }
        else
        {  
            console.log('saveComponentData form.errors', getState().form.errors)
            dispatch(masterComponentViewActions.isLoading(false))
           // billingdayRef.current.focus();
            let err_field = getState().form.errors[0]
            console.log('saveComponentData form.errors', err_field)
            window.location.hash = '#sec_' + err_field.field;
            //err_field.field
            if(getState().form.errors.length) {
                dispatch({
                    type: appTypes.SHOW_NOTIFICATION,
                    payload: {
                        type: 'danger',
                        title: 'System Notification',
                        additionalText: 'Form validation failed, Please make sure all red fields are corrected',
                        overflowText: 'Action',
                        text: 'Please make sure all red fields are corrected',
                        autoHide: 10000,
                        timestamp: moment().format('h:mm A')
                    }
                })
            }
        }
    }
}

export const componentDataSaved = (component, result) => {
    return (dispatch) => {
        dispatch(masterComponentViewActions.isLoading(false))
        //console.log('componentDataSaved', result)
        if (result.response.severity === "ERROR") {
            const detailText = typeof result.response.detail === 'string'
                ? result.response.detail.replace('Key', ' ').replace('=', ' ').replace(/\)\(/g, ' ')
                : 	'Data could not be saved. Please ensure there are no special characters and that integer and numeric values are correctly placed.';

            const additionalText = result.response.column
                ? 'Error occurred when saving ' + result.response.table + ', ' + result.response.column + ' field is required. '
                : 'Error occurred when saving ' + detailText;

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'Error Notification',
                    additionalText: additionalText,
                    overflowText: 'Action',
                    text: 'Please fix issue and try again',
                    type: 'danger',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            });
        }
        else {
            
            if(component.name == 'customer') {
                dispatch({
                    type: types.LINK_USER_TO_NEW_CUSTOMER,
                    payload: {
                        component: 'user_customer',
                        data: {
                            client_id: result.response.client_id,
                            customer_id: result.response.customer_id,
                            customername: result.response.customername,
                            user_id: result.response.created_by_user_id
                        }
                    }
                })
            }


            dispatch(redirectActions.route("/search/"))
            dispatch(redirectActions.route("/search/" + component.name))

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: getComponentTitle(component) + ' ' + (component.title ? component.title.toLowerCase() : '') + ' successfully saved on ' + moment(new Date()).format('LLLL'),
                    overflowText: 'Action',
                    text: '',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}

export const saveUserCustomerDone = (results, payload) => {
    return (dispatch) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Customer ' + payload.data.customername + ' successfully linked to your profile ' + moment(new Date()).format('LLLL'),
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const componentDataSaveFailed = (component) => {
    return (dispatch) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Unable to save ' + getComponentTitle(component) + ' on ' + moment(new Date()).format('LLLL'),
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.COMPONENT_DATA_SAVE_FAILED
        })
    }
}

const validate = (schema, field, input) => {
    let result = []
    if(field.isrequired)
        //console.log('validate isrequired',schema, field, input)
    if(field.isrequired && field.form){
        return {
            valid: input ? true : false,
            field: field.title,
            message: input ? '' : field.title + ' is required',
        }
    }
    return {
        valid: true,
        field: field.title,
        message: '',
    }

    // if (!field.required) {
    //     return {
    //         valid: true,
    //         errors: []
    //     }
    // }else {
    //     if(!input && field.required)
    // }

    // // const adhocSchema = {
    // //     [field.name]: schema[field.name]
    // // }

    // // let adhocData = {}

    // // if (adhocSchema[field.name].filter(x => x.name === 'boolean').length) {
    // //     adhocData = {
    // //         [field.name]: input ? 'true' : 'false'
    // //     }
    // // }
    // // else {
    // //     adhocData = {
    // //         [field.name]: input ? input.toString() : ''
    // //     }
    // // }

    // // const result = validator().validate(adhocSchema, adhocData)

    // return {
    //     ...result,
    //     message: result.errors.map(x => { return x.message }).join(', ')
    // }
}

export const validateForm = (name) => {
    return (dispatch, getState) => {
        const component = getState().components[name]

        let valid = true
        let errors = []
        
        component.component_field.filter(x => !x.surrogate).forEach((x) =>  {

            let input = x.component_field_data_type.name === 'date' || x.component_field_data_type.name === 'timestamp' || x.component_field_data_type.name === 'dateTime'
                ? component.data[x.name] ? component.data[x.name].input ? component.data[x.name].input ? moment(component.data[x.name].input).format('DD-MM-YYYY') : null : '' : ''
                : component.data[x.name] ? component.data[x.name].input : ''

            let condition = true 
            
            if(x.display_condition) {
                let _display_condition = x.display_condition ? x.display_condition.replace('!','') : ''
                condition = x.display_condition 
                    ? x.display_condition.includes('!') 
                        ?  !component.data[_display_condition][_display_condition.includes('_id') ? 'text' : 'input'] 
                        :  component.data[_display_condition][_display_condition.includes('_id') ? 'text' : 'input'] 
                    : true

            }
            const result = (x.validation || x.isrequired) && x.form
                ? validator.schema(x.name, x.validation, input, condition ? x.isrequired : false) ? '' : x.errorText ? x.errorText : 'Invalid '+x.validation+' input value' : ''
            
            if (result) {
                valid = false
                errors.push({valid: true, message: result, field: x.name, value: input})
            }

            dispatch({
                type: types.SET_COMPONENT_FIELD_INPUT_VALIDATION,
                payload: {
                    component: component.name,
                    field: x.name,
                    input: x.component_field_display_type.name === 'toggle'
                        ? (input == 'undefined' || input == '' || input == 'null') ? false : input
                        : input,
                    validation: result
                }
            })
        })

        dispatch({
            type: types.SET_FORM_VALIDATION_RESULT,
            payload: {
                valid: valid,
                errors: errors
            }
        })
    }
}

export const resetForm = (component) => {
    return {
        type: types.RESET_FORM,
        payload: component
    }
}

export const getComponentSchema = (component, id, stop) => {
    return (dispatch, getState) => {
        
        if(getState().components[component]) {
        let _component = getState().components[component]

        const pass = _component.schema ? Object.keys(_component.schema).length : null
        
        //console.log('getRelatedGrid loadComponent actions2', component, id, stop, pass)
        !pass
            ? dispatch({
                type: types.GET_COMPONENT_SCHEMA,
                payload: {
                    component: component,
                    id: id,
                    stop: stop
                }
            })
            : dispatch(getComponentRelationships(component, id, stop))
        }
    }
}

export const setComponentSchema = (component, payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_SCHEMA,
            payload: {
                component: component,
                schema: payload
            }
        })
    }
}

export const getComponentRelationships = (component, id, stop) => {
    //console.log('getRelatedGrid getComponentRelationships Form')
    return (dispatch, getState) => {
        const relatedFull = getState().components[component].relatedFull
        const pass = (relatedFull.hasMany || relatedFull.belongsTo)

        if (!stop) {
            !pass
                ? dispatch({
                    type: types.GET_COMPONENT_RELATIONSHIPS,
                    payload: {
                        component: component,
                        id: id,
                        pass: pass
                    }
                })
                : dispatch(getComponentData(component, id))
        }
    }
}

export const setComponentRelationship = (component, payload) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_RELATIONSHIPS,
            payload: {
                component: component,
                relatedFull: payload
            }
        })
    }
}

export const redirectFine = (status) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_FINE_REDIRECT_STATUS,
            payload: status
        })
    }
}
export const redirectFineWin = (status) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_FINE_REDIRECT_WIN,
            payload: status
        })
    }
}

export const getTreeViewData = (field,datasource,value) => {
    return (dispatch) => {
        let data = {field: field, datasource: datasource, value: value, query: datasource + "/other", type: 'hierachy'}
        //console.log('getTreeViewData',data)
        dispatch({
            type: types.GET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: data
        })
    }
}

export const getTreeData = (field, value) => {
    return (dispatch) => {
        //console.log('getTreeData', field)
        dispatch({
            type: types.GET_COMPONENT_TREE_SELECTION_DATA,
            payload: field.name + "/searchor?customer_id=" + value + "&" + field.name + "_id=" + value
        })
    }
}

export const setTreeViewData = (response, payload) => {
    return (dispatch) => {
        
        //console.log('setTreeViewData', response)
        //console.log('setTreeViewData', payload)
        dispatch({
            type: types.SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: {
                prop: payload.datasource+"_data",
                data: response.hierachy
            }
        })
    }
}

export const downloadNominationForm = (data) => {
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())
    
    if(data.data.content) {
        saveByteArray([data.data.content.Body], data.data.document.filename)
    }

    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Fine redirect nomination downloading...',
                overflowText: 'Action',
                text: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getComponentData = (name, id, surrogate) => {
    return (dispatch, getState) => {
        const component = getState().components[name]

        if (component && !isNaN(id) && id > 0) {
            const belongsTo = component.relatedFull.belongsTo
                ? component.relatedFull.belongsTo.map(x => {
                    return x.key
                })
                : []

            let hasMany = component.relatedFull.hasMany
                ? component.relatedFull.hasMany.filter(x => x.key.indexOf('document') > -1).map(x => {
                    return x.key
                })
                : []

            if (hasMany.length) {
                hasMany.forEach(x => {
                    hasMany = hasMany.concat(x + '.document')
                })
            }

            const relations = component.related === null ? [] : component.related.split(',')
            const related = _.uniq([...belongsTo, ...hasMany, ...relations])

            const pk = !surrogate
                ? component.component_field.filter(x => x.key)[0].name
                : component.view_parameters
                    ? component.view_parameters
                    : component.component_field.filter(x => x.key)[0].name

            dispatch({
                type: types.GET_COMPONENT_DATA,
                payload: {
                    component: component.name,
                    query: '?' + pk + '=' + id,
                    id: id ? id : 0,
                    related: related.join(','),
                    surrogate: surrogate
                }
            })
        }
    }
}

export const setComponentData = (name, data, id, surrogate) => {
    const formatDate = (value) => {
        if (value === null) {
            return null
        }

        if (isNaN(Date.parse(value))) {
            const time = moment(value, 'HH:mm:ss', true)

            if (time.isValid()) {
                const date = moment().set({
                    'hour': time.get('hour'),
                    'minute': time.get('minute'),
                    'second': time.get('second')
                })

                return date.toDate();
            }

            return
        }

        return new Date(value)
    }

    const getInputValue = (field, data) => {
        if (!field.component_field_display_type) {
            return ''
        }

        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return data[field.name]
            case 'datePicker':
                return formatDate(data[field.name])
            case 'timePicker':
                return formatDate(data[field.name])
            case 'toggle':
                return (typeof data[field.name] != 'undefined' && data[field.name] != 'null') ? data[field.name] : false
            case 'hidden':
                return data[field.name] !== null ? data[field.name] : 0
            default:
                return data[field.name] === null ? '' : data[field.name]
        }
    }

    const getLookupData = (field, data) => {
        switch (field.component_field_display_type.name) {
            case 'autoComplete':
            case 'treeSelect':
                const key = field.name.replace('_id', '')
                const fields = field.display.split('|')
                const accessor = fields[0].split('.')[0]

                return [{
                    value: data[field.name] !== null ? data[field.name] : '',
                    text: fields.map(x => {
                        return x.split(',').map(y => {
                            return _.get(data[key], y.replace(accessor + '.', ''))
                        }).join(' ')
                    }).join(', ')
                }]
            default:
                return []
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[name]

        data = Object.keys(data).reduce((obj, x) => {
            const field = component.component_field.filter(y => y.name === x)[0]

            if (field) {

                const lookup = getLookupData(field, data)

                obj[x] = {
                    key: ''.concat('component_', component.name, '_field_', field.name, '_id_', id),
                    input: getInputValue(field, data),
                    data: lookup,
                    text: lookup.length ? lookup[0].text : '',
                    validation: {
                        valid: true,
                        errors: []
                    }
                }
            }
            else {
                obj[x] = data[x]
            }

            return obj
        }, {})

        //console.log('Form loadComponent FORM action2', data)
        dispatch({
            type: types.SET_COMPONENT_DATA,
            payload: {
                name: name,
                data: data,
                surrogate: surrogate
            }
        })
    }
}


export const clearComponentData = (name, surrogate) => {
    return (dispatch, getState) => {
        const component = getState().components[name]

        let data = Object.keys(component.data).reduce((obj, x) => {
            const field = component.component_field.filter(y => y.name === x)[0]

            if (field) {
                obj[x] = {
                    key: ''.concat('component_', component.name, '_field_', field.name, '_id_', 0),
                    input: null,
                    data: [],
                    text: '',
                    validation: {
                        valid: true,
                        errors: []
                    }
                }
            }
            // else {
            //     obj[x] = data[x]
            // }

            return obj
        }, {})

        //console.log('Form loadComponent FORM action1', data)
        dispatch({
            type: types.SET_COMPONENT_DATA,
            payload: {
                name: name,
                data: data,
                surrogate: surrogate
            }
        })
    }
}


export const setComponentFieldInput = (field, input) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            // case 'timePicker':
            //     input = input.toString().trim()
            //     return moment(input).format('HH:mm:ss')
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = format(field, input)
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                validation: validate(component.schema, field, value)
            }
        })
    }
}

export const setComponentFieldInputText = (field, input, text) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = format(field, input)
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_AND_TEXT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                text: text,
                validation: validate(component.schema, field, value)
            }
        })
    }
}
export const getComponentFieldLookupData = (field, searchText, dataSource, params) => {
    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const filter = component.filter ? '?' + component.filter + '&' : '?'
        const payload = {
            component: component,
            field: field,
            order: field.display ? field.display.split('.').length > 1 ? field.display.split('.')[1] : '' : '',
            related: '',
            query: searchText.length > 0 ? filter + field.search + '=' + searchText : filter,
            text: searchText
        }
        console.log('getComponentFieldLookupDatax get',payload)

        if(payload.field.component_reference.name) {
            dispatch({
                type: types.GET_COMPONENT_FIELD_LOOKUP_DATA,
                payload: payload
            })
        }
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    console.log('getComponentFieldLookupDatax set',component)
    const data = payload.data.map(x => {
        return {
            value: x[field.name] ? x[field.name] : x[field.component_reference.name.concat('_id')],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return _.get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    //console.log('argsx data', component.name , data)
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component.name,
                field: field.name,
                data: data
            }
        })
    }
}