import * as types from './types'
import * as appTypes from '../App/types'
import moment from 'moment'

export const setInputvalue = (value, el) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKQUEUE_COMPONENT_INPUT_VALUE,
            payload: {
                value: value,
                el: el
            }
        })
    }
}
export const loadWorkqueueItem = (item) => {
    return (dispatch, getState) => {
        //console.log('componentWillMount loadWorkqueueItem',item)
        dispatch(setPropData('params',item))
        dispatch(getItemData(item.workqueue_id,item.workqueue_title))
    }
}

export const setPropData = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKQUEUE_PROP_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}
export const setGridFilter = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKQUEUE_COMPONENT_GRID_FILTER,
            payload: filter
        })
    }
}
export const getUser = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKQUEUE_USER_LIST,
            payload: filter
        })
    }
}
export const setUser = (payload) => {
    //console.log('setUser',payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKQUEUE_USER_LIST,
            payload: payload.data.map(x => {return {...x, text: x.firstname + ' ' + x.lastname, value: x.user_id}})
        })
    }
}
export const getItemData = (workqueue_id, workqueue_title) => {
    //console.log('>>>>>>>>>>>>>>>>>> setFormData getItemData',workqueue_id, workqueue_title)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_WORKQUEUE_ITEM_DATA,
            payload: {
                workqueue_id: workqueue_id,
                workqueue_title: workqueue_title ? workqueue_title : workqueue_id,
            }
        })
    }
}

export const getFormData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('workqueuex SET_WORKQUEUE_DATA response',response)
        //console.log('workqueuex SET_WORKQUEUE_DATA payload',payload)
        //console.log('workqueuex SET_WORKQUEUE_DATA data',response.data[0])
        dispatch({
            type: types.SET_WORKQUEUE_DATA,
            payload: response.data[0]
        })
        dispatch({
            type: types.GET_WORKQUEUE_FORM_DATA,
            payload: {
                ...response,
                type: 'get_event_data'
            }
        })
    }
}

export const setFormData = (response) => {
    //console.log('workqueuex SET_WORKQUEUE_FORM_DATA',response)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKQUEUE_FORM_DATA,
            payload: response
        })
        if(response.workqueue.info)
            dispatch(setGridFilter('info'))
        else if(response.workqueue.low)
            dispatch(setGridFilter('low'))
        else if(response.workqueue.moderate)
            dispatch(setGridFilter('moderate'))
        else if(response.workqueue.high)
            dispatch(setGridFilter('high'))
        else if(response.workqueue.critical)
            dispatch(setGridFilter('critical'))
    }
}

export const updateAck = (status,component, field, id, workqueue_title,user_id,comments) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_EVENT_MANAGEMENT_ACK_STATUS,
            payload: {
                workqueue_title: workqueue_title,
                type: 'update_event_ack',
                comments: comments,
                component: component,
                user_id: user_id,
                status: status,
                field: field,
                id: id
            }
        })
    }
}

export const UpdateEventAckDone = (res, data) => {
    return (dispatch, getState) => {
        //console.log('componentWillMount loadWorkqueueItem UpdateEventAckDone',data)
        dispatch(getItemData(data.workqueue_title,''))
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Event successfully Updated',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const sendEmail = (to, name,message) => {
    return (dispatch) => {
        dispatch({
            type: types.SEND_EVENTS_MANAGMENT_EMAIL,
            payload: {
                to: to,
                key: "HyM9bxT5O",
                type: "send_email",
                subject: "Event Management - Notification",
                replyTo: "noreply@infleet.co.za",
                data: {
                    fullname: name,
                    message: message
                }
            }
            
        })
    }
}

export const sendEmailDone = (data) => {
    return (dispatch,getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Email sent',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
