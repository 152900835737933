import * as types from './types'
import moment from 'moment'

export const addContact = () => {
    return (dispatch, getState) => {
        let contacts = getState().customerMasterCapture.contacts
        contacts.push({})
        dispatch(setInputValue('contacts',contacts))
    }
}

export const delContact = (inx) => {
    return (dispatch, getState) => {
        let contacts = getState().customerMasterCapture.contacts
        delete contacts[inx]
        contacts = contacts.filter(x => x)
        dispatch(setInputValue('contacts',contacts))
    }
}


export const setContactInputValue = (data) => {
    return (dispatch, getState) => {
        let contacts = getState().customerMasterCapture.contacts
        contacts[data.inx][data.prop] = data.value
        dispatch(setInputValue('contacts',contacts))
    }
}

export const addAccContact = () => {
    return (dispatch, getState) => {
        let acccontact = getState().customerMasterCapture.acccontact
        contacts.push({})
        dispatch(setInputValue('acccontact',acccontact))
    }
}

export const delAccContact = (inx) => {
    return (dispatch, getState) => {
        let acccontact = getState().customerMasterCapture.acccontact
        delete contacts[inx]
        contacts = contacts.filter(x => x)
        dispatch(setInputValue('acccontact',acccontact))
    }
}


export const setAccContactInputValue = (data) => {
    return (dispatch, getState) => {
        let acccontact = getState().customerMasterCapture.acccontact
        contacts[data.inx][data.prop] = data.value
        dispatch(setInputValue('acccontact',acccontact))
    }
}


export const addAddress = () => {
    return (dispatch, getState) => {
        let address = getState().customerMasterCapture.address
        address.push({})
        dispatch(setInputValue('address',address))
    }
}

export const delAddress = (inx) => {
    return (dispatch, getState) => {
        let address = getState().customerMasterCapture.address
        delete address[inx]
        address = address.filter(x => x)
        dispatch(setInputValue('address',address))
    }
}

export const setAddressInputValue = (data) => { 
    return (dispatch, getState) => {
        let address = getState().customerMasterCapture.address
        address[data.inx][data.prop] = data.value
        dispatch(setInputValue('address',address))
    }
}
export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_CUSTOMER_MASTER_CAPTURE_FORM,
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_CUSTOMER_MASTER_CAPTURE_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CUSTOMER_MASTER_CAPTURE_LOOKUP_DATA,
            payload: {
                component: component,
                field: field,
                search: search,
                query: `?${field}=${search}&jobtitle=Account Executive`
            }
        })
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setLookupData response', response)
        //console.log('setLookupData payload', payload)
        dispatch(setInputValue(payload.component + '_data',response.data))
    }
}

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            //type: types.CONFIRM_CREDITORS_PAYMENT_CAPTURE,
            
            type: types.CONFIRM_CUSTOMER_MASTER_CAPTURE,
            payload: payload
        })
    }
}