import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment';

import { Card, CardHeader, CardContent, Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton } from '@mui/material';

import ReactTable from "react-table"
import "react-table/react-table.css"

import Icon from '../Icon/component'

import * as actions from './actions'
import * as styles from './styles'


class EventGrid extends Component {

    getCommands(row) {
        let field = this.props.state.grid_fields.filter(x => x.pk === true)[0]
        const id = row.original[field.name]
        let i = row.index
        let commands = []
        
        commands.push(
            <IconButton key={'command_view_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
                tooltip={'Vehicle details'} color="primary"
                onClick={() => {
                    //console.log('id',id)
                    this.props.actions.loadEvent(id)
                    this.props.actions.setInputValue({ prop: 'view', value: 'form' })
                    this.props.actions.setInputValue({ prop: 'tab', value: 1 })
                }}>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'edit'} />
            </IconButton>
        )
        
        
        commands.push(
            <IconButton key={'command_view_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'} color="primary"
            onClick={(event) => { 
                    //console.log('')
                    this.props.actions.loadEvent(id)
                    this.props.actions.setInputValue({ prop: 'view', value: 'view' })
                    this.props.actions.setInputValue({ prop: 'tab', value: 1 })
                }
            }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  color="primary" ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'visibility'} />
            </IconButton>
        )

        commands.push(
            <IconButton key={'delete_forever_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        // this.props.actions.showPopupDialog(true)
                        this.props.actions.confirmDelete({[field.name]: id})
                        //this.props.actions.getComponentGridData(this.component, this.props.link, this.component.grid.paging, this.props.filter, this.props.id)
                    }
                }>
                <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
            </IconButton>
        )

        return commands
    }

    getValue(row) {  
        let field = this.props.state.grid_fields.filter(x => x.name === row.column.meta)[0] 
        var value = row.original[field.related ? field.display : field.name]
            switch (field.type) {
                case 'month':
                    return moment(value).format('MM')
                case 'integer':
                    return value ? value : 0 
                case 'date':
                    return moment(value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(value).format('YYYY-MM-DD HH:mm')
                case 'time':
                    return moment(value).format('HH:mm')
                case 'timestamp':
                    return moment(value).format('YYYY-MM-DD HH:mm')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value

                    return value !== null
                        ? value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(value)
                        ? Number(value).toFixed(2).toString()
                        : value
                default:
                    return value
            }
    }

    getFormFieldLabel(row) {
          return <div
            style={{
                width: '100%',
                marginTop: '3px',
                verticalAlign: 'baseline',
                fontSize: '15px'
            }} >{this.getValue(row)}</div>
    }

    getColumns() {
        //console.log('his.props.state.grid_fields',this.props.state.grid_fields)
        const fields = this.props.state.grid_fields.filter(x => x.grid === true)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.display,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                   // width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        //: x.bold ? 'bold' : 'normal',
                        textAlign:  'left'
                    },
                    Cell: (row) => (
                          this.getFormFieldLabel(row)
                    )
                }
            })

            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 130,
                width: 130,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        return columns
    }

    getGrid() {
        return <div>
            <ReactTable
                data={this.props.state.workqueue}
                columns={this.getColumns()}
                manual
                loading={false}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
                onFetchData={
                    (state) => {
                        //this.props.actions.getComponentGridData(this.props.state.client, this.props.state.client.link, null, this.props.state.client.filter, this.props.state.client.id)
                    }
                }
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>

        </div>
    }

    getLayout() {
        return <Card initiallyExpanded={true}>
                <CardContent expandable={false}>
                   { this.getGrid() }
                </CardContent>
                
            <Dialog
                open={this.props.state.deleterecordwin}
                onClose={(event) => {
                    event.preventDefault()
                    this.props.actions.confirmDelete(null)
                    //this.props.actions.setCardData({prop: 'secretkey_dialog', data: false})
                }}
                //classes={{ paper: 'shadow-lg rounded' }}
                >
                <div className="text-center p-5">
                <h4 className="font-weight-bold mt-4">
                    Delete Record
                </h4>
                <strong className="mb-0 font-size-lg text-muted bold">
                   Are you sure, you want to deleted this record.
                </strong>
                <div className="pt-4">
                    {
                        !this.props.state.deletingrecord && <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.confirmDelete(null)
                                //this.props.actions.setCardData({prop: 'resetpin_dialog', data: false})
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Cancel</span>
                            </Button>
                    }
                    <Button 
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.deleteRecord(this.component.name,this.props.state.deleterecord)
                            //this.props.actions.setCardData({prop: 'resetpin_dialog', data: false})
                                this.props.actions.confirmDelete(null)
                        }}
                        disabled={this.props.state.deletingrecord}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">{this.props.state.deletingrecord ? 'Please wait..' : 'Delete'}</span>
                    </Button>
                </div>
                </div> 
          </Dialog>
            </Card>
    }

    render() {

        return this.props.state.workqueue ?this.getLayout() : 'Loading...'
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.events
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventGrid)