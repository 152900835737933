import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                sign_filename: '',
                sign_filepreview: '',
                po_filename: '',
                po_filepreview: '',
                po_date: '',
                po_number: '',
                po_amount: []
            }
        case types.SET_PO_DOCUMENT_PREVIEW:
            return {
                ...state,
                filename: action.payload.filename,
                filepreview: action.payload.preview,
            }
        case types.CLEAR_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW:
            return {
                ...state,
                filename: action.payload,
                filepreview: action.payload,
            }
        case types.SET_ACCEPT_REBILL_PO_COMPONENT_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        default:
            return state
    }
}