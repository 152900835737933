import * as types from './types'

export const getWorkflowNames = () => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOWS_GET_WORKFLOW_NAMES
        })
    }
}

export const setWorkflowNames = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOWS_SET_WORKFLOW_NAMES,
            payload: data.reduce((obj, x) => {
                obj[x.name] = {
                    loading: true
                }

                return obj
            }, {})
        })

        //dispatch(preloadWorkflowComponents())
    }
}

export const getWorkflow = (workflow) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOWS_GET_WORKFLOW,
            payload: workflow
        })
    }
}

export const setWorkflow = (data) => {
    const getWorkflowStructure = (workflow) => {
        return {
            ...workflow,
            highlight: false,
            loading: false,
            selected_task: workflow.workflow_trigger[0]
                ? workflow.workflow_trigger[0].workflow_task
                : workflow.workflow_task[0]
        }
    }

    return (dispatch) => {
        dispatch({
            type: types.WORKFLOWS_SET_WORKFLOW,
            payload: getWorkflowStructure(data[0])
        })
    }
}