import React, { Component, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Card, CardHeader, CardContent } from '@mui/material'

import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, AppBar, Toolbar } from "@mui/material";

import { Container, Row, Col } from 'react-grid-system'

//import Form from '../../Form/component'
import Form from '../TeamManager/form'
import Loading from '../../Loading/component'
import Icon from '../../Icon/component'
import MultiSelect from "../MultiSelect/component";

import ManageComponent from '../ManageComponent/component'
import TaskManager from '../../ManageWorkFlowTask/component'
import TeamStructure from '../../TeamStructure/component'

import * as actions from './actions'
import * as styles from './styles'
//import '../../../import'
import { setComponent } from '../../Components/actions'
import { componentDataSaved } from '../../Form/actions'
import Section from '../../../controls/section'
import { options } from 'numeral'

class TeamOptions extends React.Component {
    
    componentDidMount() {
        
        //console.log('getAllUsers componentWillMount')
        this.props.actions.getAllUsers()
        this.props.actions.getAllTeams()
        this.props.actions.getAllEvents()
        this.props.actions.getAllComponents()
        this.props.actions.getAllCustomers()
        this.props.actions.getAllAccounts()
        this.props.actions.getAllReports()
    }

    constructor(props) {
        super(props);
        this.state = {
          components: [],
        };
        this.state = {
            team: []
        };
        this.state = {
            user: []
        };
        this.state = {
            account: []
        };
        this.state = {
            business: []
        };
        this.state = {
            reports : []
        }
    }

    
    // Select a team
    handleToggleTeam = (event, selectedOption, reason) => {
        if (reason === "select-option" || reason === "selectOption") {
            this.setState({
                team: selectedOption
            });
            this.props.actions.changeSelectedTeam(selectedOption.team_id)
        }
        else if (reason === "clear") {
            this.setState({
                team: []
            });
        }

    }

    // Select a event
    handleToggleEvent = (event, selectedOption, reason) => {
        this.props.actions.getLinkedTeamAndUser(selectedOption.workqueue_id)
    }

    handleEventTeam = (event, selectedOption, reason) => {
        let team = this.props.state.allTeams.filter(x => x.team_id === selectedOption.team_id)[0]
        this.props.actions.addLinkTeam(team)
    }

    handleEventUser = (event, selectedOption, reason) => {
        let user = this.props.state.allUsers.filter(x => x.user_id === selectedOption.user_id)[0]
        this.props.actions.addLinkUser(user)
    }

    // Select an account
    handleToggleAccount = (event, selectedOption, reason) => {
        if (reason === "select-option" || reason === "selectOption") {
            this.setState({
                account: selectedOption
            });
            this.props.actions.changeSelectedAccount(selectedOption.client_id)
        }
        else if (reason === "clear") {
            this.setState({
                account: []
            });
        }

    }

    // Select an business
    handleToggleBusiness = (event, selectedOption, reason) => {
        if (reason === "select-option" || reason === "selectOption") {
            this.setState({
                business: selectedOption
            });
            this.props.actions.changeSelectedBusiness(selectedOption.business_id)
        }
        else if (reason === "clear") {
            this.setState({
                business: []
            });
        }

    }

    // Select a user
    handleToggleUser = (event, selectedOption, reason) => {
        if (reason === "select-option" || reason === "selectOption") {
            this.setState({
                user: true
            });
            this.props.actions.changeSelectedUser(selectedOption.user_id)
        }
        else if (reason === "clear") {
            this.setState({
                user: false
            });
        }

    }
    handleToggleReport = selectedOptions =>{
        this.setState({
            reports : selectedOptions
        })
    }
    handleClearReports = () =>{
        this.setState({
            reports : []
        })
    }
    handleSelectAllReports = (isSelected) => {
        const theState = this.props.state
        if(isSelected){
            this.setState({
                reports : theState.allReports ? theState.allReports.sort((x,y) => (x.report_name > y.report_name) ? 1 :-1) : []
            })
        }
        else {
            this.setState({
                reports : []
            });
        }
    }

    assignReports(reports){
        for(let i = 0; i < reports.length; i++){
            this.props.actions.changeSelectedReport(reports[i].report_id)
            this.props.actions.assignReportToTeam()
        }
    }
    // Selects all the components individually selected
    handleToggleOption = selectedOptions => {
        this.setState({
            components: selectedOptions
        });

    }

    // Clears the components selected
    handleClearOptions = () => {
        this.setState({
            components: []
        });
    }

    // Selects all the components when "Select All" is clicked
    handleSelectAll = isSelected => {
        if (isSelected) {
            this.setState({
                components: this.props.state.allComponents ? this.props.state.allComponents.sort((a, b) => (a.title > b.title) ? 1 : -1) : []
            });
        } else {
            this.setState({
                components: []
            });
        }
    }

    // Assigns the selected components to the selected team
    assignComponents(components) {
        for(var i = 0; i < components.length; i ++) {
            this.props.actions.changeSelectedComponent(components[i].component_id);
            this.props.actions.assignComponentToTeam()
        }
    }


    render() {
        const comp = this.props.state.allComponents ? this.props.state.allComponents.sort((a, b) => (a.title > b.title) ? 1 : -1) : []
        let components = this.state.components ; 
        let reports = this.state.reports
        const theReports = this.props.state.allReports ? this.props.state.allReports.sort((i,j) =>(i.report_name > j.report_name) ? 1 : -1) : []
        const appState = this.props.state
        return appState.allUsersLoading || appState.allTeamsLoading || appState.allAccountsLoading || appState.allComponentsLoading || appState.allReportsLoading || appState.allEventsLoading
            ? <Loading message={'Loading....'} />
            : <div expandable={false}>

        <AppBar position="static" style={{borderBottom: '1px solid white', backgroundColor: 'white', boxShadow: 'none'}}>
            <Toolbar style={{ backgroundColor: '#fff', boxShadow: 'none'}}>
                
                <div  className="app-header--pane icon-color">
                            <Button className={"global_button"} variant="contained"
                                label={
                                    this.props.state.edit ? 'Manage Teams' : 'Create Team'
                                }
                                primary={false}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.setInputValue('edit', this.props.state.edit ? false : true)
                                    this.props.actions.toggleTeamForm()
                                }}
                            >{this.props.state.edit ? 'Manage Teams' : 'Create Team'}</Button>

                            &nbsp;&nbsp;&nbsp;

                       <Button className={"global_button"} variant="contained"
                                label={'Users'}
                                // primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleAssignmentForm('users')
                                }}
                            >User Teams</Button>

                            &nbsp;&nbsp;&nbsp;

                        <Button className={"global_button"} variant="contained"
                                label={'Accounts'}
                                // primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleAssignmentForm('customer')
                                }}
                            >User Customers</Button>

                            &nbsp;&nbsp;&nbsp;

                        <Button className={"global_button"} variant="contained"
                                label={'Accounts'}
                                // primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleAssignmentForm('accounts')
                                }}
                            >Accounts</Button>

                            &nbsp;&nbsp;&nbsp;

                            <Button className={"global_button"} variant="contained"
                                label={'Components'}
                                // primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleAssignmentForm('components')
                                }}
                            >Components</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button className={"global_button"} variant="contained"
                                label={'Reports'}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleAssignmentForm('manage_components')
                                }}
                            >Manage Components</Button>
                            &nbsp;&nbsp;&nbsp;

                            <Button className={"global_button"} variant="contained"
                                label={'Components'}
                                // primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleAssignmentForm('tasks')
                                }}
                            >Manage Tasks</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button className={"global_button"} variant="contained"
                                label={'Reports'}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleAssignmentForm('reports')
                                }}
                            >Reports</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button className={"global_button"} variant="contained"
                                label={'Reports'}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleAssignmentForm('events')
                                }}
                            >Events</Button>
                        </div>
                <div>
                    <div className="app-header--pane">
                    </div>
                </div>
                <div style={{ marginLeft: 'auto', width: '200px' }}>
                    <div className="app-header--pane" style={{ width: '200px' }}>
                       
                    <div style={{ ...styles.right, ...styles.visibility(this.props.state.team || this.props.state.edit) }} >
                            <Button variant="contained"
                                label={'Save Changes'}
                                className={'global_button'}
                                // primary={true}
                                width={'200px'}
                                style={{width: '200px'}}
                                disabled={!this.props.state.changed}
                                onClick={(event) => {
                                    event.preventDefault()
                                    if(this.props.state.view === 'events'){
                                        this.props.actions.saveTeamAndUserChanges()
                                    }
                                    if(this.props.state.team && this.props.state.edit){
                                        this.props.actions.saveTeamData()
                                    }else{
                                        this.props.state.team
                                            ? this.props.actions.saveTeamManagementChanges()
                                            : this.props.actions.saveTeamAssignmentChanges()
                                    }
                                }}
                            >Save Changes</Button>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
                    <hr />
                    <div style={styles.visibility(this.props.state.team && !this.props.state.edit && !this.props.state.edit)}>
                        {this.props.state.allTeams.map(x => {
                            return <div key={'all_teams_' + x.team_id} style={styles.table}>
                                <div style={styles.cell}>
                                    {x.title + ' (' + x.description + ')'}
                                </div>
                                <div style={styles.right}>
                                    {/* <Button secondary={true}
                                        label={'Edit'}
                                        icon={<Icon iclass={'material-icons'} iname={x.active ? 'power_settings_new' : 'eject'} />}
                                        onClick={(event) => {
                                            this.props.actions.toggleTeamActivation(x.team_id)
                                        }}
                                    ><Icon iclass={'material-icons'} iname={'eject'} />{'Edit'}</Button> */}

                                    <Button secondary={true}
                                        label={x.active ? 'Deactivate' : 'Activate'}
                                        icon={<Icon iclass={'material-icons'} iname={x.active ? 'power_settings_new' : 'eject'} />}
                                        className={x.active ? 'team-deactivate' : 'team-active'}
                                        onClick={(event) => {
                                            this.props.actions.toggleTeamActivation(x.team_id)
                                        }}
                                    ><Icon iclass={'material-icons'} iname={x.active ? 'power_settings_new' : 'eject'} />{x.active ? 'Deactivate' : 'Activate'}</Button>
                                </div>
                            </div>
                        })}
                    </div>

                    <div style={styles.visibility(this.props.state.team && this.props.state.edit && this.props.state.edit)}>
                        <TeamStructure />

                        <br /><br />
{/* 
                        <div style={styles.commands}>
                            <Button className={"global_button"} variant="contained"
                                label="Save Team"
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.saveTeamData()
                                }}
                            >{window.t.en('Save Team')}</Button>
                        </div> */}
                    </div>

                    <div style={styles.visibility(this.props.state.assign)}>
                        {
                            this.props.state.view === 'tasks' && <div>
                                <Section title={'Workflow Task'} subtitle={'Manage workflow tasks'}  />
                                <TaskManager />
                                </div>
                        }
                        {
                            this.props.state.view === 'manage_components' && <div>
                            <Section title={'Manage Component'} subtitle={'Manage system component'}  />
                            <ManageComponent />
                                </div>
                        }
                        {
                            this.props.state.view === 'events' && <div key={'team_events_management'}>
                                   
                            <Section title={'Event'} subtitle={'Manage user events'}  />
                                            <Container style={{ margin: 0 }} className="custom-container">
                                                <Row>
                                                    <Col xl={1} style={{paddingTop: "28px"}}>
                                                        <strong>Events:</strong>
                                                    </Col>
                                                    <Col xl={11}>
                                                        <div style={{padding: "8px 0"}}>
                                                            <Autocomplete
                                                                id="event-select"
                                                                style={{ width: "100%" }}
                                                                options={this.props.state.allEvents}
                                                                getOptionLabel={(option) => `${option.workqueue_name}`}
                                                                onChange={this.handleToggleEvent}
                                                                renderOption={(props, option) => (
                                                                        <li {...props}>
                                                                            {`${option.workqueue_name}`}
                                                                        </li>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                    {...params}
                                                                    label={window.t.en('Please select event')}
                                                                    variant="outlined"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={1} style={{paddingTop: "28px"}}>
                                                        <strong>Team:</strong>
                                                    </Col>
                                                    <Col xl={11}>
                                                        <div style={{padding: "8px 0"}}>
                                                            <Autocomplete
                                                                id="team-select"
                                                                style={{ width: "100%" }}
                                                                options={this.props.state.allTeams}
                                                                getOptionLabel={(option) => `${option.title}`}
                                                                onChange={this.handleEventTeam}
                                                                renderOption={(props, option) => (
                                                                        <li {...props}>
                                                                            {`${option.title}`}
                                                                        </li>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                    {...params}
                                                                    label={window.t.en('Please select team')}
                                                                    variant="outlined"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={1} style={{paddingTop: "28px"}}>
                                                        <strong>User:</strong>
                                                    </Col>
                                                    <Col xl={11}>
                                                        <div style={{padding: "8px 0"}}>
                                                            <Autocomplete
                                                                id="user-select"
                                                                style={{ width: "100%" }}
                                                                options={this.props.state.allUsers}
                                                                getOptionLabel={(option) => `${option.firstname + ' ' + option.lastname + ' (' + option.email + ')'}`}
                                                                onChange={this.handleEventUser}
                                                                renderOption={(props, option) => (
                                                                        <li {...props}>
                                                                            {`${option.firstname + ' ' + option.lastname + ' (' + option.email + ')'}`}
                                                                        </li>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                    {...params}
                                                                    label={window.t.en('Please select user')}
                                                                    variant="outlined"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div style={styles.commands}>
                                                    <Button secondary={true}
                                                        label="Assign"
                                                        disabled={this.state.user ?  this.state.user === true ? false : true : true}
                                                        icon={<Icon iclass={'material-icons'} iname={'check_circle'} />}
                                                        className='team-activate'
                                                        onClick={(event) => {
                                                            this.props.actions.assignUserToTeam()
                                                        }}
                                                    ><Icon iclass={'material-icons'} iname={'check_circle'} />Assign</Button>
                                                </div>
                                            </Container>
                                      

                                    <br /><br />

                                    {this.props.state.event_teams.sort((x, y) => x.title - y.title).map(x => {
                                        return <div key={'assigned_team_' + x.team_id} style={styles.table}>
                                            <div style={styles.cell}>
                                                {x.title}
                                            </div>
                                            <div style={styles.right}>
                                                <Button secondary={true}
                                                    label="Unassign"
                                                    icon={<Icon iclass={'material-icons'} iname={'remove_circle'} />}
                                                    className='team-deactivate'
                                                    onClick={(event) => {
                                                        this.props.actions.rmEventTeam(x.team_id)
                                                    }}
                                                ><Icon iclass={'material-icons'} iname={'remove_circle'} />Unassign </Button>
                                            </div>
                                        </div>
                                    })}

                                    {this.props.state.event_users.sort((x, y) => x.firstname - y.firstname).map(x => {
                                        return <div key={'assigned_users_' + x.user_id} style={styles.table}>
                                            <div style={styles.cell}>
                                                {x.firstname + ' ' + x.lastname + ' (' + x.email + ')'}
                                            </div>
                                            <div style={styles.right}>
                                                <Button secondary={true}
                                                    label="Unassign"
                                                    icon={<Icon iclass={'material-icons'} iname={'remove_circle'} />}
                                                    className='team-deactivate'
                                                    onClick={(event) => {
                                                        this.props.actions.rmEventUser(x.user_id)
                                                    }}
                                                ><Icon iclass={'material-icons'} iname={'remove_circle'} />Unassign </Button>
                                            </div>
                                        </div>
                                    })}
                                </div>
                        }
                        {
                            this.props.state.view === 'users' && <div key={'team_user_management'}>
                                
                            <Section title={'User'} subtitle={'Manage users'}  />
                                    <Container style={{ margin: 0 }} className="custom-container">
                                                <Row>
                                                    <Col xl={1} style={{paddingTop: "28px"}}>
                                                        <strong>Team:</strong>
                                                    </Col>
                                                    <Col xl={11}>
                                                        <div style={{padding: "8px 0"}}>
                                                            <Autocomplete
                                                                id="team-select"
                                                                style={{ width: "100%" }}
                                                                options={this.props.state.allTeams}
                                                                getOptionLabel={(option) => `${option.title}`}
                                                                onChange={this.handleToggleTeam}
                                                                renderOption={(props, option) => (
                                                                        <li {...props}>
                                                                            {`${option.title}`}
                                                                        </li>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                    {...params}
                                                                    label={window.t.en('Please select team')}
                                                                    variant="outlined"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={1} style={{paddingTop: "28px"}}>
                                                        <strong>User:</strong>
                                                    </Col>
                                                    <Col xl={11}>
                                                        <div style={{padding: "8px 0"}}>
                                                            <Autocomplete
                                                                id="user-select"
                                                                style={{ width: "100%" }}
                                                                options={this.props.state.allUsers}
                                                                getOptionLabel={(option) => `${option.firstname + ' ' + option.lastname + ' (' + option.email + ')'}`}
                                                                onChange={this.handleToggleUser}
                                                                renderOption={(props, option) => (
                                                                        <li {...props}>
                                                                            {`${option.firstname + ' ' + option.lastname + ' (' + option.email + ')'}`}
                                                                        </li>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                    {...params}
                                                                    label={window.t.en('Please select user')}
                                                                    variant="outlined"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div style={styles.commands}>
                                                    <Button secondary={true}
                                                        label="Assign"
                                                        disabled={this.state.user ?  this.state.user === true ? false : true : true}
                                                        icon={<Icon iclass={'material-icons'} iname={'check_circle'} />}
                                                        className='team-activate'
                                                        onClick={(event) => {
                                                            this.props.actions.assignUserToTeam()
                                                        }}
                                                    ><Icon iclass={'material-icons'} iname={'check_circle'} />Assign</Button>
                                                </div>
                                            </Container>
                                            
                                 <br />

                                    {this.props.state.users.sort((x, y) => x.firstname - y.firstname).map(x => {
                                        return <div key={'assigned_users_' + x.user_id} style={styles.table}>
                                            <div style={styles.cell}>
                                                {x.firstname + ' ' + x.lastname + ' (' + x.email + ')'}
                                            </div>
                                            <div style={styles.right}>
                                                <Button secondary={true}
                                                    label="Unassign"
                                                    icon={<Icon iclass={'material-icons'} iname={'remove_circle'} />}
                                                    className='team-deactivate'
                                                    onClick={(event) => {
                                                        this.props.actions.unassignUserFromTeam(x.user_id)
                                                    }}
                                                ><Icon iclass={'material-icons'} iname={'remove_circle'} />Unassign </Button>
                                            </div>
                                        </div>
                                    })}
                                </div>
                        }
                        {
                            this.props.state.view === 'components' && <div key={'team_component_management'}>
                                    
                            <Section title={'Components'} subtitle={'Manage team component'}  />
                                                <Container style={{ margin: 0 }} className="custom-container">
                                                    <Row>
                                                        <Col xl={1} style={{paddingTop: "28px"}}>
                                                            <strong>Team:</strong>
                                                        </Col>
                                                        <Col xl={11}>
                                                            <div style={{padding: "8px 0"}}>
                                                                <Autocomplete
                                                                    id="team-select"
                                                                    style={{ width: "100%" }}
                                                                    options={this.props.state.allTeams}
                                                                    getOptionLabel={(option) => `${option.title}`}
                                                                    onChange={this.handleToggleTeam}
                                                                    renderOption={(props, option) => (
                                                                            <li {...props}>
                                                                                {`${option.title}`}
                                                                            </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                        {...params}
                                                                        label={window.t.en('Please select team')}
                                                                        variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={1} style={{paddingTop: "28px"}}>
                                                            <strong>Components:</strong>
                                                        </Col>
                                                        <Col xl={11}>
                                                            <div style={{padding: "8px 0"}}>
                                                                <MultiSelect
                                                                    limitTags={7}
                                                                    items={comp}
                                                                    selectedValues={components}
                                                                    onToggleOption={this.handleToggleOption}
                                                                    onClearOptions={this.handleClearOptions}
                                                                    onSelectAll={this.handleSelectAll}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={styles.commands}>
                                                        <Button secondary={true}
                                                            label="Assign"
                                                            disabled={this.state.components ?  (this.state.components).length > 0? false : true : true}
                                                            icon={<Icon iclass={'material-icons'} iname={'check_circle'} />}
                                                            className='team-activate'
                                                            onClick={(event) => {
                                                                this.assignComponents(components)
                                                            }}
                                                        ><Icon iclass={'material-icons'} iname={'check_circle'} /> Assign</Button>
                                                    </div>
                                                </Container>
                                   <br />

                                        {
                                            this.props.state.components.map
                                                ? this.props.state.components.sort((x, y) => x.title - y.title).map(x => {
                                                    return <div key={'assigned_components_' + x.component_id} style={styles.table}>
                                                        <div style={styles.cell}>
                                                            {x.title + ' (' + x.description + ')'}
                                                        </div>
                                                        <div style={styles.right}>
                                                            <Button secondary={true}
                                                                label="Unassign"
                                                                icon={<Icon iclass={'material-icons'} iname={'remove_circle'} />}
                                                                className='team-deactivate'
                                                                onClick={(event) => {
                                                                    this.props.actions.unassignComponentFromTeam(x.component_id)
                                                                }}
                                                            ><Icon iclass={'material-icons'} iname={'remove_circle'} /> Unassign</Button>
                                                        </div>
                                                    </div>
                                                })
                                                : <div />
                                        }
                                    </div>
                        }
                        {
                            this.props.state.view === 'reports' &&  <div key={'team_reports_management'}>
       
       <Section title={'Reports'} subtitle={'Link reports to team'}  />
                                                <Container style={{ margin: 0 }} className="custom-container">
                                                    <Row>
                                                        <Col xl={1} style={{paddingTop: "28px"}}>
                                                            <strong>Team:</strong>
                                                        </Col>
                                                        <Col xl={11}>
                                                            <div style={{padding: "8px 0"}}>
                                                                <Autocomplete
                                                                    id="team-select"
                                                                    style={{ width: "100%" }}
                                                                    options={this.props.state.allTeams}
                                                                    getOptionLabel={(option) => `${option.title}`}
                                                                    onChange={this.handleToggleTeam}
                                                                    renderOption={(props, option) => (
                                                                            <li {...props}>
                                                                                {`${option.title}`}
                                                                            </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                        {...params}
                                                                        label={window.t.en('Please select team')}
                                                                        variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={1} style={{paddingTop: "28px"}}>
                                                            <strong>Reports:</strong>
                                                        </Col>
                                                        <Col xl={11}>
                                                            <div style={{padding: "8px 0"}}>
                                                                <MultiSelect
                                                                    limitTags={7}
                                                                    items={theReports}
                                                                    selectedValues={reports}
                                                                    onToggleOption={this.handleToggleReport}
                                                                    onClearOptions={this.handleClearReports}
                                                                    onSelectAll={this.handleSelectAllReports}
                                                                    view ={appState.view}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={styles.commands}>
                                                        <Button secondary={true}
                                                            label="Assign"
                                                            disabled={this.state.reports ?  (this.state.reports).length > 0? false : true : true}
                                                            icon={<Icon iclass={'material-icons'} iname={'check_circle'} />}
                                                            className='team-activate'
                                                            onClick={(event) => {
                                                                //console.log("")
                                                                this.assignReports(reports)
                                                            }}
                                                        ><Icon iclass={'material-icons'} iname={'check_circle'} /> Assign</Button>
                                                    </div>
                                                </Container>
                                     
                                    <br />

                                        {
                                            this.props.state.reports
                                                ? this.props.state.reports.sort((x, y) => x.report_name - y.report_name).map(x => {
                                                    return <div key={'assigned_reports_' + x.report_id} style={styles.table}>
                                                        <div style={styles.cell}>
                                                            {x.report_name}
                                                        </div>
                                                        <div style={styles.right}>
                                                            <Button secondary={true}
                                                                label="Unassign"
                                                                icon={<Icon iclass={'material-icons'} iname={'remove_circle'} />}
                                                                className='team-deactivate'
                                                                onClick={(event) => {
                                                                    this.props.actions.unassignReportFromTeam(x.report_id)
                                                                }}
                                                            ><Icon iclass={'material-icons'} iname={'remove_circle'} /> Unassign</Button>
                                                        </div>
                                                    </div>
                                                })
                                                : <div />
                                        }
                                    </div>
                        }           
                                    
                        {
                            this.props.state.view === 'accounts' &&  <div key={'team_account_management'}>
                     
                     <Section title={'Account'} subtitle={'Manage accounts'}  />
                                                <Container style={{ margin: 0 }} className="custom-container">
                                                    <Row>
                                                        <Col xl={1} style={{padding: "16px"}}>
                                                            <strong>Mode:</strong>
                                                        </Col>
                                                        <Col xl={11}>
                                                            <div style={{padding: "4px 0"}}>
                                                                <Checkbox
                                                                    label={
                                                                        this.props.state.businessMode
                                                                            ? 'Allows assigning of businesses to teams. Users linked to these teams will be considered employees and can only see related data.'
                                                                            : 'Allows assigning of customers to teams. Users linked to these teams will be considered customers and can only see related data.'
                                                                    }
                                                                    checked={this.props.state.businessMode}
                                                                    onChange={(event, isInputChecked) => {
                                                                        this.props.actions.toggleBusinessMode(isInputChecked)
                                                                    }}
                                                                    labelPosition={'right'}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={1} style={{paddingTop: "28px"}}>
                                                            <strong>Team:</strong>
                                                        </Col>
                                                        <Col xl={11} >
                                                            <div style={{padding: "8px 0"}}>
                                                                <Autocomplete
                                                                    id="team-select"
                                                                    style={{ width: "100%" }}
                                                                    options={this.props.state.allTeams}
                                                                    getOptionLabel={(option) => `${option.title}`}
                                                                    onChange={this.handleToggleTeam}
                                                                    renderOption={(props, option) => (
                                                                            <li {...props}>
                                                                                {`${option.title}`}
                                                                            </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                        {...params}
                                                                        label={window.t.en('Please select team')}
                                                                        variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={1} style={{paddingTop: "28px"}}>
                                                            <strong>Account:</strong>
                                                        </Col>
                                                        <Col xl={11}>
                                                            <div style={{padding: "8px 0"}}>
                                                                <Autocomplete
                                                                    id="account-select"
                                                                    style={{ width: "100%" }}
                                                                    options={this.props.state.allAccounts}
                                                                    getOptionLabel={(option) => `${option.title + ' ' + ' (' + option.registrationnumber + ')'}`}
                                                                    onChange={this.handleToggleAccount}
                                                                    renderOption={(props, option) => (
                                                                            <li {...props}>
                                                                                {`${option.title + ' ' + ' (' + option.registrationnumber + ')'}`}
                                                                            </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                        {...params}
                                                                        label={window.t.en('Please select account')}
                                                                        variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={1} style={{paddingTop: "28px"}}>
                                                            <strong>Business:</strong>
                                                        </Col>
                                                            {
                                                                this.props.state.allBusinesses.length
                                                                     ?  <Col xl={11}>
                                                                            <div style={{padding: "8px 0"}}>
                                                                                <Autocomplete
                                                                                    id="business-select"
                                                                                    style={{ width: "100%" }}
                                                                                    options={this.props.state.allBusinesses}
                                                                                    getOptionLabel={(option) => `${option.businessname}`}
                                                                                    onChange={this.handleToggleBusiness}
                                                                                    renderOption={(props, option) => (
                                                                                            <li {...props}>
                                                                                                {`${option.businessname}`}
                                                                                            </li>
                                                                                    )}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                        {...params}
                                                                                        label={window.t.en('Please select business')}
                                                                                        variant="outlined"
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </Col>               
                                                                    :   <Col xl={11} style={{paddingTop: "28px"}}>
                                                                            This account has no associated businesses and cannot be assigned.
                                                                        </Col>
                                                            }
                                                    </Row>
                                                    {
                                                        !this.props.state.businessMode &&
                                                        <Row>
                                                            <Col xl={1}>
                                                                <strong>Customer:</strong>
                                                            </Col>
                                                            <Col xl={11}>
                                                                <Select
                                                                    value={
                                                                        this.props.state.selectedCustomer === 0 && this.props.state.allCustomers.length
                                                                            ? this.props.state.allCustomers[0].customer_id
                                                                            : this.props.state.selectedCustomer
                                                                    }
                                                                    onChange={(event, index, value) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.changeSelectedCustomer(event.target.value)
                                                                    }}
                                                                    style={styles.dropdown}
                                                                    autoWidth={false}
                                                                >
                                                                    {
                                                                        this.props.state.allCustomers.map(x => {
                                                                            return <MenuItem key={'all_customers_dropdown_menu_' + x.customer_id} value={x.customer_id}  >
                                                                                {x.registeredname + ' (' + x.costcentre + ')'}
                                                                            </MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                    }

                                                    <div style={styles.commands}>
                                                        <Button secondary={true}
                                                            label="Assign"
                                                            disabled={!this.props.state.allBusinesses.length}
                                                            icon={<Icon iclass={'material-icons'} iname={'check_circle'} />}
                                                            onClick={(event) => {
                                                                this.props.state.businessMode
                                                                    ? this.props.actions.assignAccountBusinessToTeam()
                                                                    : this.props.actions.assignAccountBusinessCustomerToTeam()
                                                            }}
                                                        ><Icon iclass={'material-icons'} iname={'check_circle'} /> Assign</Button>
                                                    </div>
                                                </Container>
                                <br />

                                        {
                                            this.props.state.businessMode
                                                ? this.props.state.accountBusinesses.map(x => {
                                                    return <div key={'assigned_account_businesses_' + x.team_id + '_' + '_' + x.client_id + '_' + x.business_id} style={styles.table}>
                                                        <div style={styles.cell}>
                                                            {x.client.title + '/' + x.business.businessname}
                                                        </div>
                                                        <div style={styles.right}>
                                                            <Button secondary={true}
                                                                label="Unassign"
                                                                icon={<Icon iclass={'material-icons'} iname={'remove_circle'} />}
                                                                onClick={(event) => {
                                                                    this.props.actions.unassignAccountBusinessFromTeam(x.team_id, x.client_id, x.business_id)
                                                                }}
                                                            ><Icon iclass={'material-icons'} iname={'remove_circle'} /> Unassign</Button>
                                                        </div>
                                                    </div>
                                                })
                                                : this.props.state.accountBusinessCustomers.map(x => {
                                                    return <div key={'assigned_account_business_customers_' + x.team_id + '_' + x.client_id + '_' + x.business_id + '_' + x.customer_id} style={styles.table}>
                                                        <div style={styles.cell}>
                                                            {x.client.title + '/' + x.business.businessname + '/' + x.customer.registeredname + ' (' + x.customer.costcentre + ')'}
                                                        </div>
                                                        <div style={styles.right}>
                                                            <Button secondary={true}
                                                                label="Unassign"
                                                                icon={<Icon iclass={'material-icons'} iname={'remove_circle'} />}
                                                                onClick={(event) => {
                                                                    this.props.actions.unassignAccountBusinessCustomerFromTeam(x.team_id, x.client_id, x.business_id, x.customer_id)
                                                                }}
                                                            ><Icon iclass={'material-icons'} iname={'remove_circle'} /> Unassign</Button>
                                                        </div>
                                                    </div>
                                                })
                                        }
                                    </div>
                        }          
                                    
                        {
                            this.props.state.view === 'customer' &&  <div key={'user_organisation_management'}>
                     
                     <Section title={'User Customer'} subtitle={'Manage user customers'}  />
                                                <Container style={{ margin: 0 }} className="custom-container">
                                                    <Row>
                                                        <Col xl={1} style={{paddingTop: "28px"}}>
                                                            <strong>User:</strong>
                                                        </Col>
                                                        <Col xl={11}>
                                                            <div style={{padding: "8px 0"}}>
                                                                <Autocomplete
                                                                    id="user-select"
                                                                    style={{ width: "100%" }}
                                                                    options={this.props.state.allUsers}
                                                                    getOptionLabel={(option) => `${option.firstname + ' ' + option.lastname + ' (' + option.email + ')'}`}
                                                                    onChange={(event, selectedOption) => {
                                                                        //console.log('selectedOption',selectedOption)
                                                                        if(selectedOption){
                                                                            let user = this.props.state.allUsers.filter(x => x.user_id === selectedOption.user_id)[0]
                                                                            //console.log('selectedOption user',user)
                                                                            this.props.actions.getUserCustomers(user.user_id)
                                                                        }
                                                                    }}
                                                                    renderOption={(props, option) => (
                                                                            <li {...props}>
                                                                                {`${option.firstname + ' ' + option.lastname + ' (' + option.email + ')'}`}
                                                                            </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                        {...params}
                                                                        label={window.t.en('Please select user')}
                                                                        variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={1} style={{paddingTop: "28px"}}>
                                                            <strong>Customer:</strong>
                                                        </Col>
                                                        <Col xl={11} >
                                                            <div style={{padding: "8px 0"}}>
                                                                <Autocomplete
                                                                    id="team-select"
                                                                    style={{ width: "100%" }}
                                                                    options={this.props.state.allCustomers}
                                                                    getOptionLabel={(option) => `${option.customername + ' (' + option.accountnumber + ')'}`}
                                                                    onChange={(event, selectedOption) => {
                                                                        if(selectedOption){
                                                                            //console.log('selectedOption cust', selectedOption)
                                                                            let customer = this.props.state.allCustomers.filter(x => x.customer_id === selectedOption.customer_id)[0]
                                                                            this.props.actions.setUserLinkToCustomers(customer.customer_id)
                                                                        }
                                                                        //this.props.actions.setUserLinkToCustomers(option.customer_id)
                                                                    }}
                                                                    renderOption={(props, option) => (
                                                                            <li {...props}>
                                                                                {`${option.customername + ' (' + option.accountnumber + ')'}`}
                                                                            </li>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                        {...params}
                                                                        label={window.t.en('Please select team')}
                                                                        variant="outlined"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    
                                                    <div style={styles.commands}>
                                                        <Button secondary={true}
                                                            label="Assign"
                                                            disabled={!this.props.state.toLinkCustomer && !this.props.state.toLinkUser}
                                                            icon={<Icon iclass={'material-icons'} iname={'check_circle'} />}
                                                            className='team-activate'
                                                            onClick={(event) => {
                                                                //console.log('assignUserFromCustomer',this.props.state.toLinkCustomer,this.props.state.toLinkUser)
                                                                this.props.actions.assignUserFromCustomer(this.props.state.toLinkCustomer,this.props.state.toLinkUser)
                                                                // this.props.state.businessMode
                                                                //     ? this.props.actions.assignAccountBusinessToTeam()
                                                                //     : this.props.actions.assignAccountBusinessCustomerToTeam()
                                                            }}
                                                        ><Icon iclass={'material-icons'} iname={'check_circle'} /> Assign</Button>
                                                    </div>
                                                </Container>
                                                <br />

                                        {
                                            this.props.state.allUserCustomers.map(x => {
                                                return <div key={'assigned_user_customer_' + x.user_customer_id} style={styles.table}>
                                                    <div style={styles.cell}>
                                                        {x.customer.customername + ' (' + x.customer.accountnumber + ')'}
                                                    </div>
                                                    <div style={styles.right}>
                                                        <Button secondary={true}
                                                            label="Unassign"
                                                            icon={<Icon iclass={'material-icons'} iname={'remove_circle'} />}
                                                            className='team-deactivate'
                                                            onClick={(event) => {
                                                                this.props.actions.unassignUserFromCustomer(x)
                                                            }}
                                                        ><Icon iclass={'material-icons'} iname={'remove_circle'} /> Unassign</Button>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                        }
                    </div>
                </div>
    }
    state = {
        age: '',
        name: 'hai',
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.teamOptions
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamOptions)