import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import * as config from '../../config'
import { Table } from "@mui/material";

import { Container, Row, Col } from 'react-grid-system'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'

import PdfViewer from '../PDF/component'
import Loading from '../Loading/component'

import * as actions from './actions'
import * as styles from './styles'
import ViewCustomer from '../../controls/view_customer'

class WorkflowPOContractActivationConfirm extends Component {
    componentDidMount() {
        this.props.actions.setupWorkflowTaskInput(this.props.state, 'customerquote', 'tsk_fml_quote_contract_activation')
    }

    getSystem(data) {
        //console.log('WorkflowPOContractActivation getSystem')
        return <Card 
                title={window.t.en('Quoted History ')}
                subtitle={window.t.en('List Of Steps')}
                content={
                    <Table className="table" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            <th className="text-left ">Step Name</th>
                            <th className="text-left ">System User</th>
                            <th className="text-left">Datetime</th>
                            <th className="text-left">Comments</th>
                        </thead>
                        <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                            <tr>
                                <td>{'Quote Created'}</td>
                                <td>{data.vehicle.assignto.firstname}</td>
                                <td>{moment(data.vehicle.quotedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.vehicle.comments}</td>
                            </tr>
                            <tr>
                                <td>{'Quote Review'}</td>
                                <td>{data.review.approvedby}</td>
                                <td>{moment(data.review.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.review.comments}</td>
                            </tr>
                            <tr>
                                <td>{'Quote Approve'}</td>
                                <td>{data.approval.approvedby}</td>
                                <td>{moment(data.approval.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.approval.comments}</td>
                            </tr>
                            <tr>
                                <td>{'Quote Accepted'}</td>
                                <td>{data.accepted.acceptedby}</td>
                                <td>{moment(data.accepted.acceptdate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.accepted.acceptedcomments}</td>
                            </tr>
                            {/* <tr>
                                <td>{'GM Approval'}</td>
                                <td>{data.gm.approvedby}</td>
                                <td>{moment(data.gm.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.gm.comments}</td>
                            </tr>
                            <tr>
                                <td>{'COO Approval'}</td>
                                <td>{data.coo.approvedby}</td>
                                <td>{moment(data.coo.approvedate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{data.coo.comments}</td>
                            </tr> */}
                        </tbody>
                    </Table>
                }
            />
    }

    getContract(data) {
        //console.log('WorkflowPOContractActivation getContract')
        return <Card 
                title={window.t.en('Quote Confirmation')}
                subtitle={window.t.en('Please Ensure All details Are Captured Correctly Before You Proceed')}
                content={
                    <Fields nocard={true}
                        fields={[
                        {size: 4, label: 'Contract Type:', value: data.contractname},
                        {size: 4, label: 'Vehicle Category:', value: data.customerderivativecategory},
                        {size: 4, label: ' Monthly Distance:', value: data.distancepermonth},
                        {size: 4, label: 'Contract Period:', value: data.contractperiod},
                        {size: 4, label: 'Quote For:', value: data.quotefor},
                        {size: 4, label: 'RFQ Date:', value: data.quotedate},
                        {size: 4, label: 'Vehicle Color:', value: data.color},
                        {size: 4, label: 'Vehicle Location:', value: data.location},
                        {size: 4, label: 'Special Vehicle:', value: data.specialistvehicle},
                        {size: 4, label: 'Vehicle Group:', value: data.vehiclegroup},
                        {size: 4, label: 'Operating Terrain:', value: data.terrain},
                        {size: 4, label: 'Vehicle Usage:', value: data.usage},
                        {size: 4, label: 'Comments:', value: data.comments},
                    ]}
                />
                }
            />
    }

    getCustomer(customer) {
        return <div>
        <ViewCustomer
            min={true}
            key={customer.customer_id}
            id={customer.customer_id}
        />
    </div>
}

    getSupplier(data) {
        //console.log('WorkflowPOContractActivation getSupplier')
        return <Card 
                title={window.t.en('Supplier Details')}
                subtitle={window.t.en('Selected Vehicle Supplier ')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Supplier Name:', value: data.suppliername},
                            {size: 4, label: 'Supplier Type:', value: data.suppliertype},
                            {size: 4, label: 'Account Number:', value: data.accountnumber},
                            {size: 4, label: 'Vat Number:', value: data.vatnumber},
                            {size: 4, label: 'Contact Person:', value: data.contactperson},
                            {size: 4, label: 'Contact Email:', value: data.contactemail},
                        ]}
                    />
                }
            />
    }

    getVehicles(data) {
        //console.log('WorkflowPOContractActivation getVehicles')
        return <Card 
                title={window.t.en('Quoted Vehicles ')}
                subtitle={window.t.en('Quoted Vehicle List')}
                content={
                    <Table className="table" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            <th className="text-left "></th>
                            <th className="text-left ">Vehicle Description</th>
                            <th className="text-left">Vehicle Pricing</th>
                            <th className="text-left">Residual Values</th>
                            <th className="text-right">Vehicle Component </th>
                            <th className="text-right">Rental</th>
                        </thead>
                        <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                        {
                            data.map((x, i) => {
                            return <> 
                                    <tr key={'vehicle_inx_' + i}>
                                        <td xl={3}>
                                            <img style={styles.vehicle} src={config.system.cdn + '/' + (x.worksheet.mmcode.imagefilename !== null ? x.worksheet.mmcode.imagefilename : 'unknown.png')} />
                                        </td>
                                        <td xl={3}>
                                            MM: {x.worksheet.mmcode.mmcode} <br />
                                            {x.worksheet.mmcode.make}<br />
                                            {x.worksheet.mmcode.cdescription}
                                        </td>
                                        <td xl={3} style={styles.numericFieldValues}>
                                            RP: {parseFloat(x.worksheet.retailprice)}<br />
                                            DS: {parseFloat(x.worksheet.discount)}<br />
                                            VP: {parseFloat(x.worksheet.vehicleprice)}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            RV %: {x.worksheet.rv}<br />
                                            RV: {x.worksheet.rv_amount}<br />
                                            {x.worksheet.deliveryperiod + ' Days'}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            Maint: {x.worksheet.maintenance}<br />
                                            Tyres: {x.worksheet.tyres}<br />
                                            Service: {x.worksheet.service}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            {x.worksheet.monthly_excl}<br />
                                            {x.worksheet.monthly_vat}<br />
                                            {x.worksheet.monthly_incl}
                                        </td>
                                    </tr>
                                </> 
                            })
                        }
                        </tbody>
                    </Table>
                }
            />
    }

    getDocuments(data) {
        //console.log('WorkflowPOContractActivation getDocuments')
        return <Card 
                title={window.t.en('Document')}
                subtitle={window.t.en('Uploaded Customer Supporting Documents')}
                content={
                    data.length
                    ? <PdfViewer
                        key={'uplaod_customer_viewer'}
                        documents={data}
                        multiple={true}
                        preview={'preview'}
                    />
                    : 'Loading...'
                }
            />
        }

    getVehicle(data) {
        //console.log('WorkflowPOContractActivation getVehicle')
        return <Card 
                title={window.t.en('Accepted Quote ' + data.quotenumber)}
                subtitle={window.t.en('Customer Quote Accepted')}
                content={
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Quote Number:', value: data.quotenumber},
                            {size: 4, label: 'System PO Number:', value: data.ponumber},

                            {size: 12, header: 'Vehicle Details'},
                            {size: 4, label: 'Vehicle Make:', value: data.mmcode.make},
                            {size: 4, label: 'Vehicle Model:', value: data.mmcode.cdescription},

                            {size: 4, label: 'MM Code:', value: data.mmcode.mmcode},
                            {size: 4, label: 'Transmission:', value: data.mmcode.manualauto},

                            {size: 4, label: 'Introduction Year:', value: data.mmcode.regyear},
                            {size: 4, label: 'Body Type:', value: data.mmcode.bodytype},

                            {size: 4, label: 'Contract Type:', value: data.contractname},
                            {size: 4, label: 'Vehicle Category:', value: data.categorydescription},

                            {size: 4, label: 'Monthly Distance', value: data.distancepermonth},
                            {size: 4, label: 'Contract Period:', value: data.contractperiod},

                            {size: 4, label: 'Rate Card:', value: data.ratecard},
                            {size: 4, label: 'Delivery Period:', value: data.deliveryperiod},
                            
                            {size: 12, header: 'Rental Breakdown'},
                            {size: 4, label: 'Retail Price:', value: data.retailprice},
                            {size: 4, label: 'Discount:', value: data.discount},

                            {size: 4, label: 'Interest Rate:', value: data.primerate},
                            {size: 4, label: 'Vehicle Price:', value: data.vehicleprice},

                            {size: 4, label: 'Residual %:', value: data.rv},
                            {size: 4, label: 'Residual Value:', value: data.rv_amount},

                            {size: 4, label: 'ROI %:', value: data.roipercentage},
                            {size: 4, label: 'ROI:', value: data.roi},

                            {size: 4, label: 'Tyre CPK:', value: data.tyrescpk},
                            {size: 4, label: 'Tyre:', value: data.tyres},

                            {size: 4, label: 'Maintenance CPK:', value: data.maintenancecpk},
                            {size: 4, label: 'Maintenance:', value: data.maintenance},

                            {size: 4, label: 'Admin Fee:', value: data.adminfee},
                            {size: 4, label: 'License Fee:', value: data.licence},
                            {size: 4, label: 'Service:', value: data.service},
                            {size: 4, label: 'Sundry:', value: data.sundry_tot},
                            
                            {size: 12, header: 'Monthly Rental'},
                            {size: 4, label: 'Rental Excl:', value: data.monthly_excl},

                            {size: 4, label: 'Vat:', value: data.monthly_vat},
                            {size: 4, label: 'Rental Incl:', value: data.monthly_incl},
                        ]}
                    />
                }
            />
    }

    getAccessories(data) {
        //console.log('WorkflowPOContractActivation getAccessories')
        return <Card 
                title={window.t.en('Accessory')}
                subtitle={window.t.en('Select Accessory Supplier')}
                content={
                    <Table className="table" >
                        <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                            <th className="text-left ">Accessory Description</th>
                            <th className="text-left">Supplier Name</th>
                            <th className="text-left">Cost Price</th>
                            <th className="text-left">Total Price</th>
                        </thead>
                        <tbody style={{zIndex: 1, fontWeight: 'normal'}}>
                        {
                            data.map((x, i) => {
                            return <> 
                                    <tr key={'vehicle_inx_' + i}>
                                        <td xl={3}>{x.accessory[0][x.accessory[0].itemtype]}</td>
                                        <td xl={3}>{x.accessory[0].supplier}</td>
                                        <td xl={3}>{x.accessory[0].costprice }</td>
                                        <td xl={3}>{x.accessory[0].totalprice}</td>
                                    </tr>
                                </> 
                            })
                        }
                        </tbody>
                    </Table>
                }
            />
    }

    getForm() {
        //console.log('WorkflowPOContractActivation getForm')
        return <Card 
                title={window.t.en('Activation')}
                subtitle={window.t.en('Activation Details')}
                content={
                    
                    <Fields nocard={true}
                        fields={[
                            {size: 4, label: 'Vehicle Retails:', value: this.props.state.retailprice},
                            {size: 4, label: 'Vehicle Discount:', value: this.props.state.discountamount},
                            {size: 4, label: 'RV %:', value: this.props.state.rv},
                            //{size: 4, label: 'Interest Rate %:', value: this.props.state.interestrate},
                            {size: 4, label: 'Sundry Total:', value: this.props.state.sundrytotal},
                            {size: 4, label: 'Amount (Incl):', value: this.props.state.oeminvoiceamount},
                            {size: 4, label: 'Invoice Number:', value: this.props.state.oeminvoicenumber},
                            {size: 4, label: 'Invoice Date:', value: this.props.state.oeminvoicedate},
                            {size: 4, label: 'Tyre CPK:', value: this.props.state.tyrecpk},
                            {size: 4, label: 'Maintenance CPK:', value: this.props.state.maintcpk},
                            {size: 4, label: 'Registration Number:', value: this.props.state.registrationnumber},
                            {size: 4, label: 'Register Number:', value: this.props.state.licensenumber},
                            {size: 4, label: 'Take On Odometer:', value: this.props.state.openingodometer},
                            {size: 4, label: 'First Payment Date:', value: this.props.state.firstpaymentdate},
                            {size: 4, label: 'VIN Number:', value: this.props.state.vinnumber},
                            {size: 4, label: 'Engine Number:', value: this.props.state.enginenumber},
                            {size: 4, label: 'Registration Date:', value: this.props.state.registrationdate},
                            {size: 4, label: 'License Authority:', value: this.props.state.licenceauthority},
                            {size: 4, label: 'License Expiry Date:', value: this.props.state.licenceexpirydate},
                            {size: 4, label: 'Region Activation Date:', value: this.props.state.regionactivationdate},
                            {size: 4, label: 'Activation Date:', value: this.props.state.activationdate},
                            {size: 4, label: 'Send Marketing:', value: this.props.state.send_marketing ? 'Yes':'No'},
                            {size: 4, label: 'Marketing Type:', value: this.props.state.marketingtype},
                            {size: 4, label: 'Tracking Fitted Date:', value: this.props.state.trackingdate},
                            {size: 4, label: 'Vehicle Tracking:', value: this.props.state.trackingtype},
                            {size: 4, label: 'Vehicle Insurance:', value: this.props.state.insurancecompany},
                        ]}
                    />
                }
            />
    }

    getChecklist() {
        return <Card 
                title={window.t.en('Mandatory Tick List')}
                subtitle={window.t.en('The following documents are required')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Signed Release Note? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                {this.props.state.signed_release_note ? 'Yes' : 'No'}
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Signed Vehicle Acceptance Form (VAF)? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                {this.props.state.signed_vaf ? 'Yes' : 'No'}
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Signed Proof of Delivery document ( POD)? *'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                                {this.props.state.signed_pod ? 'Yes' : 'No'}
                            </Col>
                        </Row>
                            
                        <Row>
                            <Col xl={6} style={styles.selectedFieldName}>{'Original Natis Registration Document received and in storage?'}</Col>
                            <Col xl={1} style={styles.selectedFieldName}>
                            {this.props.state.natis_registration_document ? 'Yes' : 'No'}
                            </Col>
                        </Row>
                    </Container>
                }
           />
    }

    getView(data) {
        return <div>
                {this.getSystem(data)}
                {this.getContract(data.vehicle)}
                {this.getCustomer(data.customer)}
                {this.getVehicles(data.quotations)}
                {this.getSupplier(data.supplier)}
                {this.getVehicle(data.vehicle)}
                {this.getAccessories(data.pos)}
                {this.getDocuments(data.documents)}
                {this.getForm()}
                {this.getChecklist()}
        </div>
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log('WorkflowPOContractActivationConfirm workflow_queue_data', workflow_queue_data)
        let data = null
        try {
            data = this.props.workflow_queue_data.context_data.data.data.data[0]
        }
        catch (err) { }
        
        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowPOContractActivation
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOContractActivationConfirm)