export const loading = true
export const vehicles = []
export const selectedVehicle = ''
export const selectedCustomerName = ''
export const selectedCustomer = ''
export const newCustomer = ''
export const customers = []
export const effectdate = ''
export const createdat = ''
export const updatedat = ''
export const saveTransferResponse = ''


