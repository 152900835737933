import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getAllJournals = (search) => {
    return {
        type: types.GET_JOURNAL_LIST,
        payload: {
            search_text: search
        }
    }
}

export const setJournalList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.customername + ' | ' + x.reference , 
            value: x.reference,
            customer_id: x.customer_id,
            customername: x.customername,  
            customertransactiondate: x.customertransactiondate,
            customertransactionmonth: x.customertransactionmonth,
            customerreference: x.customerreference,
            description: x.description,
            business_id: x.business_id,
            business_text: x.businessname, 
            transactiontype: x.transactiontype == 'JC - Journal Credit' ? 'Decrease' :'Increase',
            accountingitem_text: x.itemname? x.itemname:'', 
            amount: x.credit ? x.credit : x.debit,
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerjournal',
            field: 'reference'
        }
    }
}






export const getLookupValueData = (search) => {
    return {
        type: types.GET_DEBTORS_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getBusinessListData = (search) => {
    return {
        type: types.GET_BUSINESS_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getContractAccountListData = (search) => {
    return {
        type: types.GET_CONTRA_ACCOUNT_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getItemAccountListData = (search) => {
    return {
        type: types.GET_ITEM_ACCOUNT_LIST,
        payload: {
            search_text: search,
        }
    }
}

export const setCustomerList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.hierarchyname + ' | ' + x.accountnumber ,
            value: x.customer_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component:'customerjournal',
            field:'customer_id'
        }
    }
}
export const setTransactionTypeList = (payload) => {
    let data = [{
        text: 'Increase',
        value: 'JD – Journal Debit'
    },{
        text: 'Decrease',
        value: 'JC - Journal Credit'
    }]
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerjournal',
            field: 'transactiontype'

        }
    }
}

export const setBusinessList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.businessname,
            value: x.business_id
        }
    })
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerjournal',
            field: 'business_id'

        }
    }
}
export const setContraAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ANY_DATA_LIST,
            payload: {
                data: data,
                component: 'customerjournal',
                field: 'accountingitem_id'
            }
        })
    }
}

export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field,
            component:'customerjournal'
        }
    }
}

export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        const format = (field, input) => {
            const definition = getState().components.customerjournal.component_field.filter(x => x.name === field)[0]

            if (definition) {
                switch (definition.component_field_display_type.name) {
                    case 'datePicker':
                        input = input.toString().trim()
                        const now = moment(new Date())
                        return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
                    case 'timePicker':
                        input = input.toString().trim()
                        return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
                    default:
                        return input
                }
            }
            else {
                return input
            }
        }

        dispatch({
            type: types.SET_ANY_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: format(field, value),
                component: 'customerjournal'
            }
        })
    }
}


export const saveData = (payload, validatefields) => {
    var valid = true
    var text = ''
    for (var i = 0; i < validatefields.length; i++) {
        if (!validatefields[i].value) { valid = false; text += validatefields[i].field + " " }
    }
    if (!valid) {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'You missed a couple of fields [' + text + ']...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return {
            type: types.SAVE_JOURNAL_DATA,
            payload: {
                data: payload
            }
        }
    }
}

export const savedJournalData = (data) => {
    window.glyco.log("SAVED_JOURNAL_DATA")
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_JOURNAL_DATA
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Journal Capturing Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const invalidEntry = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Please capture Amount, Reference, Account and Debtor...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const setItems = (data) => {
    return {
        type: types.SET_ANY_DATA_LIST,
        payload: {
            data: data,
            component: 'customerjournal',
            field: 'customertransaction_id'
        }
    }
}
export const setDuplicateReferenceCheck = (payload) => {
    if (payload.data.length > 0) {
        return (dispatch, getState) => {
            dispatch({
                type: types.JOURNAL_DUPLICATE_FOUND,
                payload: {
                    component: 'customerjournal'
                }
            })

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'The Reference Number already Exist!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else
    {
        return (dispatch, getState) => {
            dispatch({
                type: types.JOURNAL_NO_DUPLICATE_FOUND,
                payload: {
                    component: 'customerjournal'
                }
            })
        }
    }
}
export const getDuplicateReferenceCheck = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_JOURNAL_DUPLICATE_REFERENCE_CHECK,
            payload: {
                type: "GET_JOURNAL_DUPLICATE_REFERENCE_CHECK",
                reference: reference
            }
        })
    }
}

export const foundDuplicateReference = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'The Reference Number already Exist!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}