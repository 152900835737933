import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import DateControl from '../../../controls/dateControl'

import * as actions from './actions'
import * as styles from './styles'

class ActivationSalesProcess extends Component {
   

    getView(data) {
        let documents = data?.documents?.documents ? data?.documents?.documents : []
        return <div>
        <Card
            title={"Customer Details"}
            subtitle={window.t.en('Enter new customer details')}
            content={<Fields 
                fields={[
                    {size: 4, label: 'Customer Name', value: data.customername},
                    {size: 4, label: 'Trading Name', value: data.tradingname},
                    {size: 4, label: 'Account Number', value: data.accountnumber},
                    {size: 4, label: 'VAT Number', value: data.vatnumber},
                    {size: 4, label: 'Cost Centre', value: data.costcentre},
                ]}
                />}
        /> 
        <Card
            title={"Customer Fica"}
            subtitle={window.t.en('Uploaded customer supporting documents')}
            content={<PdfViewer
                key={'customer_fica'}
                name={'customer_fica'}
                documents={documents}
                edit={false}
                multiple={false}
                workflow_queue_data={null}
            />}
        />
        </div>
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //consolex.log('ActivationSalesProcess', data.context_data.data.data.data[0].data[0])
        data = data.context_data.data.data.data[0].data[0]
        return <div>
            {this.getView(data.data.confirm)}
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.activationSalesProcess
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivationSalesProcess)