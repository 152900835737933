import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {

        case types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE_PROP:
        return {
            ...state,
            [action.payload.prop]: action.payload.data,
        } 
        case types.SET_VEHICLE_AMORT_SCHEDULE_DETAILS:
        return {
            ...state,
            vehicleamort: action.payload,
        } 
        case types.SET_COMPONENT_CONTRACT_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                }
            }
            case types.SET_ZERO_VALUE_ACCESSORY:
                return {
                  ...state,
                  [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                  },
                };
        default:
            return state
    }
}