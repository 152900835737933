import * as colours from '@mui/material/colors'
//import Theme from '../../theme'

export const icon = {
    fontSize: '34px'
}

export const field = {
    fontWeight: 'bold'
}

export const cardHeader = {
    textAlign: 'left',
    color: colours.green[500]
}

export const badge = {
    high: {
        top: 12,
        right: 12,
        backgroundColor: colours.red[500],
        fontSize: '25px',
    },
    medium: {
        top: 12,
        right: 12,
        backgroundColor: colours.amber[500],
        fontSize: '15px'
    },
    low: {
        top: 12,
        right: 12,
        backgroundColor: colours.green[500],
        fontSize: '10px'
    }
}

export const taskCard = {
    //backgroundColor: colours.amber100
    //backgroundColor: '#dcd6d7',
    //opacity: 0.85
}