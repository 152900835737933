import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as styles from './styles'
import * as actions from './actions'

import numeral from 'numeral'

class AssignedTaskCount extends Component {
    componentDidMount() {
        this.props.actions.getAssignedTaskCount(this.props.team_id, this.props.user_id)
    }

    render() {
        return !this.props.state['tasks_' + this.props.team_id + '_count']
            ? <div style={styles.counter}>...<div className="custom-task-header">...</div>
            </div>
            : <div style={styles.counter}>
                <b>{
                    this.props.state['tasks_' + this.props.team_id + '_count'] > 999
                        ? numeral(this.props.state['tasks_' + this.props.team_id + '_count']).format('0.0a')
                        : this.props.state['tasks_' + this.props.team_id + '_count']
                }</b>
            </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.assignedTaskCount }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedTaskCount)