export const _pos = []
export const _quote = []
export const _schedule1 = []
export const trackingtype_list = []
export const licenceauthority_list = []
export const accessories = []

export const trackingsupplier = ''
export const retailprice = ''
export const discountamount = ''
export const sundrytotal = ''
export const regionactivationdate = ''
export const registrationdate = ''
export const licensenumber = ''
export const registrationnumber = ''
export const licenceauthority_id = ''
export const vinnumber = ''
export const enginenumber = ''
export const trackingdate = ''
export const trackingtype_id = ''
export const openingodometer = ''
export const licenceexpirydate = ''
export const activationdate = ''
export const firstpaymentdate = ''
export const interestrate = 0
export const rv = ''

export const oeminvoiceamount = ''
export const oeminvoicenumber = ''
export const oeminvoicedate = ''


export const maintcpk = 0
export const tyrecpk = 0
export const maintcost = 0
export const tyrecost = 0
export const distancepermonth = 0
export const preowned = false