import * as props from './props'
import * as types from './types'
export default (state = props, action) => {
    switch (action.type) {
        case types.UPDATE_VALUE:
            return {
                ...state,
                [action.prop]: action.payload,
            }
        case types.SET_PAYMENT_CONFIRM_FIELD_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value,
            }
        case types.SET_BUSINESSLIST:
            return {
                ...state,
                businessUnits: action.payload,
            }  
        case types.SET_SELECTED_ITEM:
            return {
                ...state,
                [action.payload.prop]: action.payload.values,
            } 
        case types.ADD_BLANK:
            return {
                ...state,
                [action.payload.prop]: action.payload.obj,
            } 
        case types.UPDATE_ITEM_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.data,
            }
        default:
            return state
    }
}