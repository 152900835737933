import * as props from './props'
import * as types from './types'

export default (state = props, action) =>{
    switch(action.type){
        case types.SET_BUG_FIXES:
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.SET_NEW_FEATURE:
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.SET_ENHANCEMENTS:
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.SET_RELEASE_DATE:
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.SET_RELEASE_VERSION:
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.SET_ITEM:
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.SET_DESCRIPTION: 
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.SET_DIALOG_STATUS:
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.CLEAR_ALL:
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }
        case types.SET_RELEASE_NOTE:
            return{
                ...state,
                bugFixes : action.payload.bugFixes,
                description : action.payload.description,
                newFeatures : action.payload.newFeatures,
                releaseDate : action.payload.releaseDate,
                releaseEnhancements : action.payload.releaseEnhancements,
                releaseVersion : action.payload.releaseVersion,
                releaseNotes : action.payload.releaseNotes 
            }
        case types.CLEAR_RELEASE_NOTE:
            return{
                ...state,
                bugFixes :  action.payload.bugFixes,
                description : action.payload.description,
                newFeatures : action.payload.newFeatures,
                releaseDate : action.payload.releaseDate,
                releaseEnhancements : action.payload.releaseEnhancements,
                releaseVersion : action.payload.releaseVersion,
            }
        case types.SET_RELEASE_NOTE_TAB:
            return{
                ...state,
                tab: action.payload
            }
        case types.CLEAR_ITEM:
            return {
                ...state,
                item  : action.payload.item
            }
        case types.SET_NOTE:
            return{
                ...state,
                bugFixes : action.payload.bugFixes,
                description : action.payload.description,
                newFeatures : action.payload.newFeatures,
                releaseDate : action.payload.releaseDate,
                releaseEnhancements : action.payload.releaseEnhancements,
                releaseVersion : action.payload.releaseVersion
            }
        case types.SET_PAGE:
            return{
                ...state,
                page: action.payload.data
            }
        case types.SET_ROWS_PER_PAGE:
            return{
                ...state,
                rowsPerPage : action.payload.data
            }
        default:
            return state
    }
}