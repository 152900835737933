import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import moment from 'moment'
import _ from 'lodash'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Download from '@mui/icons-material/CloudDownload'
import ArrowDropRight from '@mui/icons-material/ArrowRight'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import ReactTable from "react-table"
import "react-table/react-table.css"
import Dropzone from 'react-dropzone'
import AutoComplete from '../../../controls/autocomplete'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Icon from '../../Icon/component'
import * as styles from './styles'
import * as actions from './actions'

import Card from '../../../controls/card'
import Checkbox from '@mui/material/Checkbox'

import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

class ContractSelectionGridComponent extends Component {


    componentDidMount() {
        this.props.actions.resetComponentData()
        this.props.actions.setValidationState('contractSelectionGrid')
    }

    getCommands(row) {
        //console.log('grid_rows', row)
        return <Checkbox
            label={''}
            size={'small'}
            checked={row.original.customercontract_id == (this.props.state.original ? this.props.state.original[0].customercontract_id : '')}
            onChange={
                (event, checked) => {
                    this.props.actions.selectRow(row.original, event.target.checked)
                }
            }
        />
    }


    getBulkLayout() {
        return   <Container style={{ margin: 0 }} className="custom-container">
        <Row>
            <Col xl={12}>
                <br />
            </Col>
        </Row>
        {
            !this.props.state.bulk_rows.length
                ? <Row>
                    <Col xl={12}>
                        <Dropzone
                            accept={'.xls,.xlsx'}
                            style={styles.dropZone}
                            hintText={'Upload'}
                            onDrop={() => {
                                //component.actions.toggleDropzoneVisibility()
                            }}
                            onDropAccepted={(files) => {
                                this.props.actions.setAcceptedDocuments(files)
                            }}
                            onDropRejected={() => {
                                //this.props.actions.resetDocuments(x.fileinx)
                            }}
                        >
                            <p style={styles.watermark}>
                                Drag and drop file here, or click to select file to upload.
                                        </p>
                        </Dropzone>
                    </Col>
                </Row>
                : <div><Row>
                    <Col xl={12}> < Button className={"global_button"}
                        secondary={true}
                        label={'Re Upload '}
                        onClick={() => {
                            this.props.actions.clearBulkRows()
                        }}
                        >{window.t.en('Re Upload ')}</Button></Col>
                </Row><Row>
                        <Col xl={12}> {this.getBulkGrid()}</Col>
                    </Row>
                </div>
        }
    </Container>

    }

    getBulkRestructure() {
        return this.getBulkLayout()
    }

    getBulkCellValue(row, inx) {
        //let rows = this.props.state.bulk_rows[0]
        return <label style={styles.textBox}>{this.props.state.bulk_rows[row.index][inx]}</label>
    }

    getBulkColumns() {
        let header = this.props.state.bulk_rows[0]
        let columns = header.map((x, i) => {
            return {
                meta: x,
                Header: x,
                accessor: x,
                filterable: true,
                Filter: ({ filter, onChange }) => (
                    <input
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                            width: '100%',
                            height: 25,
                            border: '1px solid #E6E6E6',
                            borderRadius: 25
                        }}
                    />
                ),
                width: x.width ? x.width : undefined,
                headerStyle: {
                    fontSize: '13px',
                    fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                    //backgroundColor: x.highlight ? styles.highlight : '',
                },
                style: {
                    verticalAlign: 'middle',
                    fontSize: 12,
                    letterSpacing: 0,
                    color: 'black',
                    fontWeight: 'normal',
                    //textAlign: x.align
                },
                Cell: (row) => (
                    this.getBulkCellValue(row, i)
                )
            }
        })
        return columns
    }
    getBulkGrid() {
        return <ReactTable
            key={'grid_booking_quote'}
            data={this.props.state.grid.data}
            columns={this.getBulkColumns()}
            filterable={true}
            loading={this.props.state.grid.loading}
            manual
            getTrProps={() => {
                return {
                    onClick: () => {
                        //this.props.actions.getRelatedRowItem(rowInfo, this.data)
                    },
                }
            }}
            onFetchData={
                (state) => {
                    this.props.actions.getRestructureGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            minRows={1}
            showPagination={false}
            showPaginationBottom={false}
            style={{
                height: '100%'
            }}
        >
            {(state, render) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }

    getGridRestructure() {
        return <ReactTable
            key={'grid_booking_quote'}
            data={this.props.state.grid.data}
            columns={this.getGridColumns()}
            filterable={true}
            loading={this.props.state.grid.loading}
            manual
            getTrProps={() => {
                return {
                    onClick: () => {
                        //this.props.actions.getRelatedRowItem(rowInfo, this.data)
                    },
                }
            }}
            onFetchData={
                (state) => {
                    this.props.actions.getRestructureGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            minRows={1}
            showPagination={false}
            showPaginationBottom={false}
            style={{
                height: '100%'
            }}
        >
            {(state, render) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }

    getValue(row, field) {
        //console.log('ContractSelectionGridComponentx row',row)
        //console.log('ContractSelectionGridComponentx field',field)
        switch (field.type) {
            case 'label':
                return <label style={styles.textBox}>
                    {
                        field.display 
                        ? this.props.state.grid.data[row.index][field.display.split('.')[0]][field.display.split('.')[1]]
                        : this.props.state.grid.data[row.index][field.name]
                    }
                </label>
            case 'date':
                return <label style={styles.textBox}>{moment(this.props.state.grid.data[row.index][field.name]).format('YYYY-MM-DD')}</label>
            case 'text':
                return <TextField
                    key={'field_' + row.index}
                    hintText={'Enter ' + field.name}
                    style={styles.textBox}
                    disabled={this.props.read_only}
                    value={this.props.state.grid.data[row.index][field.name]}
                    onChange={
                        (event, value) => {
                            this.props.actions.setInputValue(field.name, row.index, value)
                        }
                    }
                />
            case 'numeric':
                return <TextField
                    key={'field_' + row.index}
                    hintText={'Enter ' + field.name}
                    inputStyle={styles.textBoxInput}
                    style={styles.textBox}
                    disabled={this.props.read_only}
                    value={this.props.state.grid.data[row.index][field.name]}
                    onChange={
                        (event, value) => {
                            this.props.actions.setInputValue(field.name, row.index, value)
                        }
                    }
                />
            case 'toggle':
                return this.props.read_only
                    ? <TextField
                        key={'field_' + row.index}
                        hintText={' ' + field.name}
                        style={styles.textBox}
                        disabled={this.props.read_only}
                        value={this.props.state.grid.data[row.index][field.name] ? 'Yes' : 'No'}
                    />
                    : <Checkbox
                        
                        style={styles.toggle}
                        trackStyle={{
                            backgroundColor: 'gray'
                        }}
                        trackSwitchedStyle={this.props.read_only ? {
                            backgroundColor: 'gray'
                        }
                            : {
                                backgroundColor: 'green'
                            }}
                        disabled={this.props.read_only}
                        checked={this.props.state.grid.data[row.index][field.name]}
                        onChange={
                            () => {
                                this.props.actions.setInputValue(field.name, row.index, !this.props.state.grid.data[row.index][field.name])
                            }
                        } />
            case 'action':
                return < Button className={"global_button"}
                    secondary={true}
                    label={''}
                    icon={<Icon iclass={'material-icons'} iname={'history'} />}
                    onClick={() => {
                        this.props.actions.openHistory(row.index)
                    }}
                    ><Icon iclass={'material-icons'} iname={'history'} /></Button>
            case 'select':
                return this.props.read_only
                    ? <TextField
                        key={'field_' + row.index}
                        hintText={'Enter ' + field.name}
                        style={styles.textBox}
                        disabled={this.props.read_only}
                        value={this.props.state.grid.data[row.index][field.name]}
                    />
                    : <AutoComplete
                        hintText={'Select ' + field.name}
                        listStyle={{ maxHeight: 200, overflow: 'auto' }}
                        onNewRequest={
                            (chosenRequest) => {
                                this.props.actions.setSelectedLookupValue(field.name, row.index, chosenRequest.value, chosenRequest.text)
                            }
                        }
                        onUpdateInput={
                            (searchText) => {
                                this.props.actions.getLookupValues(field.component, field.name, row.index, field.related, searchText)
                            }
                        }
                        onClick={
                            () => {
                                this.props.actions.getLookupValues(field.component, field.name, row.index, field.related, '')
                            }
                        }
                        filter={AutoComplete.fuzzyFilter}
                        fullWidth={true}
                        openOnFocus={true}
                        //hintText={this.props.state.grid.data[row.index][field.name] ? this.props.state.grid.data[row.index][field.name] : ''}
                        dataSource={this.props.state[field.component] ? this.props.state[field.component] : null}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                    />
            default:
                return ''
        }
    }

    getGridColumns() {
        const fields = this.props.state.gridFields.filter(x => !x.hide)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.label,
                    accessor: x.name,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        <input
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                height: 25,
                                border: '1px solid #E6E6E6',
                                borderRadius: 25
                            }}
                        />
                    ),
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                        //backgroundColor: x.highlight ? styles.highlight : '',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: 'normal',
                        //textAlign: x.align
                    },
                    Cell: (row) => (
                        this.getValue(row, x)
                    ),
                    Footer: (
                        this.getFooter(x.name)
                    )
                }
            })

        if (!this.props.read_only) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 50,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        return columns
    }


    getFooter(field) {
        const total = field === 'Discount'
            ? _.sumBy(this.props.state.grid, (d) => { return parseFloat((d.DiscountAmount) ? d.DiscountAmount.toString().replace(/,/gi, '') : 0) })
            : _.sumBy(this.props.state.grid, (d) => { return parseFloat((d[field]) ? d[field].toString().replace(/,/gi, '') : 0) })
        return (field == 'Amount' || field == 'VAT' || field == 'Savings' || field == 'Discount')
            ?
            <span>
                <strong>{'Total ' + field + ': '}</strong>
                {
                    parseFloat(total.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            </span>
            :
            <span>
                <strong></strong>{" "}
            </span>
    }

    getGrid() {
        this.component = this.props.state.customercontract
        // ? this.props.component
        // : this.props.state.component
        window.glyco.log('this.props.state.contacts_list.data.data')
        //window.glyco.log(this.props.state.contacts_list.data.data)
        return <div>
            <Menu
                iconButtonElement={<IconButton><MoreVertIcon /></IconButton>}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <MenuItem
                    primaryText="Restructure Type"
                    rightIcon={<ArrowDropRight />}
                    menuItems={[
                        <MenuItem primaryText="Utilization Restructure" />,
                        <MenuItem primaryText="Accessory Restructure" />,
                        <Divider />,
                        <MenuItem primaryText="Bulk Restructure" onClick={
                            () => {
                                this.props.actions.setBulk();
                            }
                        } />,
                    ]}
                />

                <MenuItem
                    primaryText="Filter By"
                    rightIcon={<ArrowDropRight />}
                    menuItems={[
                        <MenuItem primaryText="Pending Acceptance" />,
                    ]}
                />
                <Divider />
                <MenuItem primaryText="Excel Export" leftIcon={<Download />} />
            </Menu>
            {
                this.props.state.is_bulk
                    ? <Card
                        title={'Bulk Contract Deviation'}
                        subtitle={'Import bulk deviation file'}
                        content={this.getBulkRestructure()}
                    />
                    : <Card
                        title={'Contract Deviation'}
                        subtitle={'Select contract to deviate'}
                        content={this.getGridRestructure()}
                    />
            }
        </div>
    }

    getLayout() {
        return this.getGrid()
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.contractSelectionGrid,
            customercontract: {
                ...state.components.customercontract
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSelectionGridComponent)