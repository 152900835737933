import * as props from './props'
import * as types from './types'
export default (state = props, action) => {
    switch (action.type) {
        case types.UPDATE_VALUE:
            return {
                ...state,
                [action.prop]: action.payload,
            }
        case types.RESET_COMPONENT:
            return {
                ...state,
                payment_list: []
            }
        case types.SET_PAYMENT_DATA_LIST_GM:
            return {
                ...state,
                payment_list: {} //action.payload.filter(x=> x.suppliertype.indexOf('Panel') >= 0)
            }
        case types.SET_EXCEPTION_DATA_LIST_GM:
            return { 
                ...state,
                exception_list: action.payload
            }
        case types.SET_PAYMENT_DATA_LIST_MAINT_GM:
            return {
                ...state,
                payment_list_maint: {} //action.payload.filter(x=> x.suppliertype.indexOf('Maintenance') >= 0)
            }
        case types.SET_PAYMENT_DATA_LIST_TYRES_GM:
            return {
                ...state,
                payment_list_tyres: {} //action.payload.filter(x=> x.suppliertype.indexOf('Tyres') >= 0)
            }
        case types.SET_BUSINESSLIST:
            return {
                ...state,
                businessUnits: action.payload,
            }
        case types.SET_SELECTED_ITEM:
            return {
                ...state,
                [action.payload.prop]: action.payload.values,
            }
        case types.ADD_BLANK:
            return {
                ...state,
                [action.payload.prop]: action.payload.obj,
            }
        case types.UPDATE_ITEM_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.data,
            }
        case types.SET_PROP_INDEX_GM:
            return {
                ...state,
                prop_inx: action.payload
            }
        case types.SET_PROP_DRAWER_INDEX_GM:
            return {
                ...state,
                prop_drawer: action.payload
            }
        default:
            return state
    }
}