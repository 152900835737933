import * as actions from './actions'
import * as teamActions from '../Teams/actions'
import * as accountActions from '../Account/actions'
import * as clientActions from '../Clients/actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as messengerTypes from '../Messenger/types'
import * as requestTypes from '../Request/types'
import { cognitoUser } from '../../cognito'
import * as config from '../../config'

export const getUser = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_USER),
    delay(500),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/user/query?cognito_key=' + payload, //store.value.cognito.authenticateUser.result.idToken.payload.sub, //cognitoUser.username,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'user_team.team,user_customer.customer,client' //.team_component'
                }
            }).pipe(
                mergeMap((response) => [
                    actions.setUser(response.response),
                    clientActions.getClientsList(),
                    teamActions.getTeamsList(response.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/query?cognito_key=' + response)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => [
                        actions.reTry(payload),
                        //of$(requestActions.requestFailed(config.system.api + '/components/user/query?cognito_key=' + payload, error))
                    ]
                )
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getUserCustomer = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_USER_CUSTOMER),
    delay(500),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/user/other', //store.value.cognito.authenticateUser.result.idToken.payload.sub, //cognitoUser.username,
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json' //.team_component'
                },
                body: payload
            }).pipe(
                mergeMap((response) => [
                    actions.setUserCustomer(response.response),
                    requestActions.requestSucceeded(config.system.api + '/components/user/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => [
                        actions.reTry(payload),
                    ]
                )
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )