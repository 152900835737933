import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {

        case types.SET_VEHICLE_SELECTION_CATEGORY_LOOKUP_DATA:
            return {
                ...state,
                chip_accessory: action.payload,
            }
        case types.SET_VEHICLE_SUNDRY_SELECTION_CATEGORY_LOOKUP_DATA:
            return {
                ...state,
                chip_sundry: action.payload,
            }
        case types.SET_VEHICLE_SERVICE_SELECTION_CATEGORY_LOOKUP_DATA:
            return {
                ...state,
                chip_service: action.payload,
            }
        case types.TOGGLE_CUSTOM_PROPS_VALUES:

            window.glyco.log('TOGGLE_CUSTOM_PROPS_VALUES')
            window.glyco.log(action.payload)
            return {
                ...state,
                [action.payload.props]: action.payload.state,
            }
        case types.SET_COMPONENT_FML_INPUT_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.value,
            }
        case types.RESET_COMPONENT_FML_INPUT_DATA:
            return {
                quotes: [0],
                chip_accessory: [],
                chip_service: [],
                chip_sundry: [],
                grid_data: [],
                vehiclecategory_data: [],
                customerderivative_data: [],
                vehicle_default_accessory: [],
                customerderivativecategory_data: [],
                contractvehiclecategory_data: [],
                customerderivativecategorymake_data: [],
                customerderivativecategorymmcode_data: [],
                vehiclegroup_data: [],
                vehicle_data: [],
                usage_data: [],
                color_data: [],
                terrain_data: [],
                quotefor_data: [],
                sub_asset_data: [],
                secondary_asset: [],
                specialistvehicle_data: [],
                interestratetype_data: [],

                bulkreference_data: [],
                customercontract_data: [],
                contractnumber: '',
                bulkreference: '',
                linkdeal: '',
                linkdeal_id: null,
                prev_bulkreference: false,
                location_data: [],
                _customerderivative: {},
                costomerref: null,
                financert57Pricing: null,
                contractperiod: '',
                distancepermonth: '',
                contractdistance: '',
                contracttype: '',
                customerderivative: '',
                customerderivativecategory: '',
                customerderivative_id: 0,
                customerderivativecategory_id: 0,
                chk_specialpricing: true,
                chk_mmcode: false,
                chk_usedvehicle: false,
                chk_cooperate: false,
                validation: props.validation,
                usage: null,
                quotedate: Date.now(),
                fields: props.fields
            }
        default:
            return state
    }
}