import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import Checkbox from '@mui/material/Checkbox'
import DateControl from '../../controls/dateControl'
import AutoComplete from '../../controls/autocomplete'

import TextField from '@mui/material/TextField'

import * as actions from './actions'
import * as styles from './styles'

import Iframe from 'react-iframe'
import Divider from '@mui/material/Divider'

import AdhocComponentFieldInput from '../Custom/AdhocComponentFieldInput/component'
import Loading from '../Loading/component'
import 'react-quill/dist/quill.snow.css'

class WorkflowPOContractActivationChecklist extends Component {

    render() {
        return (
        <Loading message={'Retrieving data, please wait...'} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowPOContractActivationChecklist
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOContractActivationChecklist)