import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.VIEW_COMPONENT_TOGGLE_SECTION_INFOSHEET:
            return {
                ...state,
                section_infosheet: action.payload.section_infosheet,
                infosheet_key: action.payload.infosheet_key,
                display_type: action.payload.display_type
            }
            
        case types.VIEW_RESET_COMPONENT:
            return {
                ...state,
                section_infosheet: false,
                infosheet_key: '',
                display_type: ''
            }

        case types.VIEW_COMPONENT_SET_INPUT_VALUES:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.VIEW_COMPONENT_TOGGLE_INFOR_VIEW:
            return {
                ...state,
                infoview_id: action.payload.id,
                infoview_name: action.payload.name,
                infoview_title: action.payload.title,
                infoview: action.payload.status
            }
        case types.VIEW_COMPONENT_CLOSE_INFOR_VIEW:
            return {
                ...state,
                infoview: action.payload
            }
        default:
            return state
    }
}



