export const completedTasksCard = {
    textAlign: 'left'
}

export const completedTasksVerticalAlignment = {
    verticalAlignment: 'middle',
    marginBottom: '5px',
}

export const fieldHeader = {
    fontWeight: 'bold'
}