import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReactTable from "react-table"
import "react-table/react-table.css"


import { Container, Row, Col } from 'react-grid-system'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'

import * as actions from './actions'
import * as styles from './styles'

import { Card, CardHeader, CardContent } from '@mui/material'


class STRItemGrid extends Component {

    getValue(row, field) {
        switch (field.type) {
            case 'text':
                return <TextField
                    key={'field_' + row.index}
                    hintText={'Enter ' + field.name}
                    style={styles.textBox}
                    disabled={true}
                    value={this.props.state.grid[row.index][field.name]}
                />
            case 'numeric':
                return <TextField
                    key={'field_' + row.index}
                    hintText={'Enter ' + field.name}
                    inputStyle={styles.textBoxInput}
                    style={styles.textBox}
                    value={this.props.state.grid[row.index][field.name]}
                    onChange={
                        (event, value) => {
                            this.props.actions.setSTRInputValue(row.index, value)
                        }
                    }
                />
            case 'checkbox':
                return <Checkbox
                    
                    style={styles.toggle}
                    trackStyle={{
                        backgroundColor: 'gray'
                    }}
                    trackSwitchedStyle={this.props.read_only ? {
                        backgroundColor: 'gray'
                    }
                        : {
                            backgroundColor: 'green'
                        }}
                    checked={this.props.state.grid[row.index][field.name]}
                    onChange={
                        (event, isInputChecked) => {
                            this.props.actions.setToggleInputValue(field.name, row.index, isInputChecked)
                        }
                    } />
            default:
                return ''
        }
    }

    getColumns() {
        const fields = this.props.state.fields
        return fields.map(x => {
            return {
                meta: x.name,
                Header: window.t.en(x.title),
                accessor: x.name,
                width: x.width ? x.width : undefined,
                headerStyle: {
                    fontSize: '13px',
                    fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                },
                style: {
                    verticalAlign: 'middle',
                    fontSize: 12,
                    letterSpacing: 0,
                    color: 'black', //'#4A4A4A',
                    fontWeight: x.bold ? 'normal' : 'normal',
                    textAlign: x.type === 'numeric'
                        ? 'right'
                        : 'left'
                },
                Cell: (row) => (
                    this.getValue(row, x)
                )
            }
        })
    }

    getGrid() {
        return <ReactTable
            key={'grid_str_items'}
            data={this.props.state.grid}
            columns={this.getColumns()}
            filterable={false}
            loading={!this.props.state.grid.length}
            manual
            onFetchData={
                (state) => {
                    this.props.actions.getComponentGridData()
                }
            }
            // getTrProps={(state, rowInfo) => {
            //     return {
            //         style: {
            //             background: component.grid.selected
            //                 ? component.grid.selected.filter(x => x.index === rowInfo.index)[0] ? Theme.palette.primary1Color : 'white'
            //                 : 'white',
            //             color: component.grid.selected
            //                 ? component.grid.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
            //                 : 'black'
            //         }
            //     }
            // }}
            minRows={1}
            showPagination={false}
            showPaginationBottom={false}
            style={{
                height: '100%'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }

    render() {
        return <Card initiallyExpanded={true} style={styles.workflowTaskStepComponentCard}>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('Accessories')}
                subtitle={window.t.en('Add Vehicle Accessories, Sundry and Services ')}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">

                    <Row>
                        <Col xl={12}>
                            {this.getGrid()}
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.strItemGrid,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(STRItemGrid)