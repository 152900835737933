import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.LOAD_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                [action.payload.name]: {
                    name: action.payload.name,
                    //team_id: action.payload.team_id,
                    //user_id: action.payload.user_id,
                    //workflow_queue_id: action.payload.workflow_queue_id,
                    loading: true,
                    //data: [],
                    //workflow_queues: [],
                    //workflow_queue: {},
                    isOpen: false,
                    searchable: action.payload.searchable
                }
            }
        case types.WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    loading: true,
                    data: [],
                    workflow_queues: [],
                    workflow_queue: {}
                }
            }
        case types.SET_WORKFLOW_VEHICLE_LIST:
            return {
                ...state,
                vehicles: action.payload
            }
        case types.SET_WS_MAINTENANCE_HOSTORY_PDF_DOCUMENT:
            return {
                ...state,
                preview: action.payload.preview
            }
        case types.SET_COMPONET_HISTORY_INPUT_FILEDS:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                vehicle: action.payload
            }
        case types.WORKFLOW_TIMELINE_SET_WORKFLOW_TIMELINE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    data: action.payload.data,
                    loading: false
                }
            }
        case types.TOGGLE_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                isOpen: action.payload.status === false? false:!state.isOpen
            }
        case types.SHOW_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                isOpen: true,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    isOpen: true
                }
            }
        case types.CLOSE_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                isOpen: false,
                vehicles: [],
                vehicles: [],
                [action.payload.name]: {
                    ...state[action.payload.name],
                    isOpen: false
                }
            }
        case types.RESET_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                vehicle: [],
                vehicles: []
            }
        default:
            return state
    }
}