import * as types from './types'
import validation from 'validator'

validation['isStrongPassword'] = (password) => {
    if (password.length === 0)
        return {
            type: types.VALIDATE_PASSWORD,
            payload: {
                valid: true,
                errorText: ''
            }
        }

    const hasLowerCase = /[a-z]/g.test(password)
    const hasUpperCase = /[A-Z]/g.test(password)
    const hasSpecialCharacter = /[~`!#$%^&*+=\-[\]\\',/{}|\\":<>?]/g.test(password)
    const hasNumber = /[0-9]/g.test(password)
    const hasLength = password.length >= 8 ? true : false

    return (hasLowerCase && hasUpperCase && hasSpecialCharacter && hasNumber && hasLength)
}

export const toggleSwitch = (field) => {
    return {
        type: types.TOGGLE_SWITCH,
        payload: {
            field: field,
            checked: !field.checked
        }
    }
}

export const togglePasswordVisibility = (field, checked, type) => {
    return {
        type: types.TOGGLE_PASSWORD_VISIBILITY,
        payload: {
            field: field,
            checked: checked,
            type: type
        }
    }
}

export const showPasswordStrength = (password) => {
    if (!password)
        return {
            type: types.SHOW_PASSWORD_STRENGTH,
            payload: 0
        }

    var score = 0
    const letters = {}

    for (var i = 0; i < password.length; i++) {
        letters[password[i]] = (letters[password[i]] || 0) + 1
        score += 5.0 / letters[password[i]]
    }

    const variations = {
        digits: /\d/.test(password),
        lower: /[a-z]/.test(password),
        upper: /[A-Z]/.test(password),
        nonWords: /\W/.test(password),
    }

    var variationCount = 0

    for (var check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0
    }

    score += (variationCount - 1) * 10

    return {
        type: types.SHOW_PASSWORD_STRENGTH,
        payload: parseInt(score, 10)
    }
}

export const setFormStatus = (valid) => {
    return {
        type: types.SET_FORM_STATUS,
        payload: valid
    }
}

export const validateForm = (fields, actions) => {
    //console.log("Fields", fields);
    return (dispatch) => {
        const invalidFields = Object.keys(fields).filter(x => !fields[x].valid && !fields[x].ignore)

        invalidFields.forEach(x => {
            dispatch(
                validateField(
                    fields[x],
                    fields[x].input,
                    fields[x] !== undefined
                        ? fields[fields[x].match]
                        : {},
                    false
                )
            )
        })

        invalidFields[0] === undefined
            ? dispatch(setFormStatus(true))
            : dispatch(setFormStatus(false))

        if (invalidFields[0] === undefined && actions !== undefined && actions.length > 0) {
            actions.forEach(x => {
                dispatch({
                    type: x.type,
                    payload: x.payload
                })
            })
        }
    }
}

export const validateField = (field, input, compare = {}, ignoreEmptyFields = true) => {
    const getPayload = (valid, errorText, empty = false) => {
        return {
            ...field,
            valid: valid,
            empty: empty,
            input: input,
            errorText: errorText
        }
    }

    return (dispatch) => {
        dispatch(setFormStatus(false))

        if (input.length === 0 && ignoreEmptyFields) {
            dispatch({
                type: types.VALIDATE_FIELD,
                payload: getPayload(
                    false,
                    '',
                    true
                )
            })

            return
        }

        const payload = field.validators.map((validator) => {
            switch (validator) {
                case types.IS_DECIMAL:
                    return getPayload(
                        validation.isDecimal(input),
                        'Valid decimal required.'
                    )
                case types.IS_EMAIL:
                    return getPayload(
                        validation.isEmail(input),
                        'Valid email address required.'
                    )
                case types.IS_NOT_EMPTY:
                    return getPayload(
                        !validation.isEmpty(input),
                        'Required.'
                    )
                case types.IS_NUMERIC:
                    return getPayload(
                        validation.isNumeric(input),
                        'Valid number required.'
                    )
                case types.IS_MOBILEPHONE:
                    return getPayload(
                        validation.isMobilePhone(input, 'en-ZA'),
                        'Valid mobile number required.'
                    )
                case types.IS_STRONG_PASSWORD:
                    return getPayload(
                        validation.isStrongPassword(input),
                        '8 characters with at least a number, lowercase, uppercase and special character.'
                    )
                case types.CONTAINS:
                    return getPayload(
                        validation.matches(input, compare.input, 'g'),
                        'Must contain value of ' + compare.floatingLabelText + '.'
                    )
                case types.MATCHES:
                    return getPayload(
                        input === compare.input,
                        'Must match ' + compare.floatingLabelText + '.'
                    )
                default:
                    return getPayload(
                        false,
                        'Unimplemented Validation Handler: ' + validator
                    )
            }
        })

        const invalid = payload.filter(x => !x.valid)

        dispatch({
            type: types.VALIDATE_FIELD,
            payload: invalid[0] !== undefined
                ? invalid[0]
                : getPayload(
                    true,
                    ''
                )
        })
    }


}

export const validationFailed = (message) => {
    return {
        type: types.VALIDATION_FAILED
    }
}

export const validationSucceeded = () => {
    return {
        type: types.VALIDATION_SUCCEEDED
    }
}