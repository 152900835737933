import * as types from './types'
import moment from 'moment'
import * as _ from 'lodash'

import * as docupload from '../Upload/actions'



export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_DEFLEET_COMPOMENT_STATE
        }),
        dispatch(docupload.resetAllDocuments())
    }
}


export const getDefleetNumber = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_DEFLEET_NUMBER,
            payload: {
                type: 'GET_DEFLEET_NUMBER'
            }
        })
    }
}

export const setDefleetNumber = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_NUMBER,
            payload: data.data
        })
    }
}

export const setTaskNumber = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TASK_NUMBER,
            payload: data
        })
    }
}


export const getDefleetTypes = (search) => {
    return (dispatch, getState) => {
        console.log('getDefleetTypesx search',search)
        dispatch({
            type: types.GET_DEFLEET_TYPES,
            payload: search
        })        
    }
}

export const setDefleetTypes = (response) => {
    return (dispatch, getState) => {
        console.log('getDefleetTypesx response',response.data)
        let data = response.data.map(x => {
            return {...x, text: x.terminationreason, value: x.terminationreason_id}
        })
        dispatch({
            type: types.SET_DEFLEET_TYPES_LIST,
            payload: data
        })
    }
}
export const resetDefleetTypes = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SELECT_DEFLEET_TYPE,
            payload: null
        })        
        dispatch(setValidationValue("selectedDefleetType", null))
    }
}

export const getDefleetReason = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_REASON,
        })        
    }
}

export const selectDefleetType = (type) => {
    return (dispatch, getState) => {
        const po = getState().workflowDefleetView.defleetTypes.filter(x => x.value === type)[0]
        dispatch({
            type: types.SELECT_DEFLEET_TYPE,
            payload: po
        })        
        dispatch(setValidationValue("selectedDefleetType",po))
    }
}
export const selectDefleetReason = (type) => {
    return (dispatch, getState) => {
        const po = getState().workflowDefleetView.defleetReason.filter(x => x.value === type)[0]
        dispatch({
            type: types.SELECT_DEFLEET_REASON,
            payload: po
        })        
        dispatch(setValidationValue("selectedDefleetReason",po))
    }
}
export const getUserAutoCompleteMenuItems = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_USER_AUTOCOMPLETE_MENU_ITEMS,
            payload: {
                query: '?firstname=' + value
            }
        })
    }
}

export const setUserAutoCompleteMenuItems = (result, selected) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_AUTOCOMPLETE_MENU_ITEMS,
            payload: selected
                ? result.data
                : result.data.length
                    ? result.data.map(x => {
                        return {
                            value: x.user_id,
                            text: x.firstname + ' ' + x.lastname
                        }
                    })
                    : []
        })
    }
}

export const setUserAutoCompleteSelected = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_AUTOCOMPLETE_SELECTED,
            payload: request
        })
    }
}


export const getVehicleListAutoCompleteMenuItems = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_DEFLEET_VEHICLE_LIST_AUTOCOMPLETE_MENU_ITEMS,
            payload: {
                query: '?licenseplateno=' + value
            }
        })
    }
}

export const setVehicleListAutoCompleteMenuItems = (result) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_VEHICLE_LIST_AUTOCOMPLETE_MENU_ITEMS,
            payload: result.data/* result.data.length
                ? result.data.map(x => {
                    return {
                        value: x.vehicle_id,
                        text: x.licenseplateno + ' (' + x.makemodelyear + ')'
                    }
                })
                : [] */
        })
    }
}

export const setVehicleAutoCompleteSelected = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_VEHICLE_AUTOCOMPLETE_SELECTED,
            payload: request
        })
        dispatch(setValidationValue('selectedVehicle',request))
    }
}

export const getSelectedVehicleDetails = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_VEHICLE_DETAILS,
            payload: {
                query: request.value
            }
        })
    }
}

export const getSelectedVehicleContract = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_VEHICLE_CONTRACT,
            payload: {
                vehicle_id: request.value,
                action: 'list'
            }
        })
    }
}

export const setSelectedVehicleDetails = (result) => {
    window.glyco.log('SET_SELECTED_VEHICLE_DETAILS')
    window.glyco.log(result)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_VEHICLE_DETAILS,
            payload: {
                data: result.data
            }
        })
    }
}
export const setSelectedVehicleContract = (result, vehicle_id) => {
    window.glyco.log('SET_SELECTED_VEHICLE_CONTRACT')
    window.glyco.log(result)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_VEHICLE_CONTRACT,
            payload: {
                data: result.data
            }
        })
        let contract_acc = getState().workflowDefleetView.selectedVehicleContractAccessories.data
        const con_total = _.sumBy(contract_acc, (d) => { return parseFloat(d.amount) }).toFixed(2)
        dispatch(setValue('accessories_total', parseFloat(con_total)))

    }
}
export const setSupplierAutoCompleteAmount = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_AUTOCOMPLETE_AMOUNT,

            query: '?licenseplateno=' + value
        })
    }
}

export const getSupplierAutoCompleteMenuItems = (value, id, type) => {
    let suppliertype = ''
    switch (type) {
        case 'Assessor':
            suppliertype = 'Roadside Assistance '
            break;
        case 'Repairs':
            suppliertype = 'Maintenance'
            break;
        /* case 'Service':
            suppliertype = 'Dealership'
            break; */
        case 'Towing':
            suppliertype = 'Roadside Assistance'
            break;
        case 'Tyres':
            suppliertype = 'Tyres'
            break;
        case 'Panel Beaters':
            suppliertype = 'Panel Beaters'
            break;
        default:
            suppliertype = ''
            break;
    }
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS,
            payload: {
                id: id,
                query: '?suppliername=' + value //suppliertype_fktext=' + suppliertype + 
            }
        })
    }
}

export const setSupplierAutoCompleteSelected = (request, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_AUTOCOMPLETE_SELECTED,
            payload: {
                id: id,
                request: request
            }
        })
    }
}

export const getSelectedSupplierDetails = (request, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_SUPPLIER_DETAILS,
            payload: {
                query: '?supplier_id=' + request.value,
                inx: id
            }
        })
    }
}

export const setSelectedSupplierDetails = (result, inx) => {
    return (dispatch, getState) => {

        dispatch({
            type: types.SET_SELECTED_SUPPLIER_DETAILS,
            payload: {
                data: result.data,
                inx: inx
            }
        })

        dispatch({
            type: types.SET_SUSPENDED_SUPPLIER_DETAILS,
            payload: {
                data: result.data[0].suspended ? null : result.data,
                status: result.data[0].suspended ? false : true,
                inx: inx
            }
        })
    }
}

export const addSupplierAutoComplete = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ADD_SUPPLIER_AUTOCOMPLETE
        })
    }
}

export const removeSupplierAutoComplete = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REMOVE_SUPPLIER_AUTOCOMPLETE,
            payload: getState().poHeader.supplierAutoCompletes.length - 1
        })
    }
}

export const setSupplierAutoCompleteMenuItems = (result, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS,
            payload: {
                id: id,
                data: result.data.length
                    ? result.data.map(x => {
                        return {
                            value: x.supplier_id,
                            text: x.suppliername + ' (' + x.suppliertype+ ')'// fktext removed
                        }
                    })
                    : []
            }
        })
    }
}


export const getSupplierListInRange = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST,
            payload: {
                type: 'GET_VEHICLE_BOOKING_SUPPLIER',
                vehicle: request.value,
                range: 6000,
                suppliertype: 'Dealership'
            }
        })
    }
}

export const setBookingSuppliers = (payload) => {

    window.glyco.log('setBookingSuppliers')
    window.glyco.log(payload.data)

    return (dispatch, getState) => {

        window.glyco.log('getState().poHeader.selectedVehicleDetails[0]')
        window.glyco.log(getState().poHeader.selectedVehicleDetails.data[0])
        dispatch({
            type: types.SET_GEO_MAP_SUPPLIERS,
            payload: {
                markers: payload.data.markers
            }
        })
        dispatch({
            type: types.SET_VEHICLE_SERVICE_INFORMATION,
            payload: payload.data.serviceinfo
        })
        dispatch({
            type: types.SET_VEHICLE_CURRENT_BOOKING_INFO,
            payload: {
                bookinginfo: payload.data.bookinginfo
            }
        })
        dispatch({
            type: types.GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT,
            payload: {
                type: 'GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT',
                data: {
                    vehicle: getState().poHeader.selectedVehicleDetails.data[0],
                    serviceinfo: payload.data.serviceinfo
                }
            }
        })
    }
}

export const getSupplierAge = (request, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_AGE,
            payload: {
                query: '?supplier_id=' + request.value
            }
        })
    }
}
export const setSupplierAge = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_AGE,
            payload: data.data
        })
    }
}

export const setMaintenanceHistoryPDF = (data) => {


    window.glyco.log('setMaintenanceHistoryPDF')
    window.glyco.log(data)

    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.Body], 'Vehicle Maintenance History.pdf')

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT,
            payload: {
                preview: link,
                filename: data.data.key.replace('quotes/', '')
            }
        })
    }
}
export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        }) 
       // dispatch(setValidationValue(prop,value))
    }
}

export const setValidationValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_VALIDATION_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const addBlankRow = (id) => {
    return (dispatch, getState) => {
        let _accessories = getState().workflowDefleetView.accessories
        _accessories.push(
            {
                id: id,
                accessoryname: '',
                amount: 0.00
            })
        dispatch({
            type: types.UPDATE_ACCESSORIES,
            payload: _accessories
        })
    }
}
export const removeRow = (id) => {
    return (dispatch, getState) => {
        let _accessories = getState().workflowDefleetView.accessories.filter(x => x.id !== id)
        dispatch({
            type: types.UPDATE_ACCESSORIES,
            payload: _accessories
        })
        let contract_acc = getState().workflowDefleetView.selectedVehicleContractAccessories.data
        const total = _.sumBy(getState().workflowDefleetView.accessories, (d) => { return parseFloat(d.amount) }).toFixed(2)
        const con_total = _.sumBy(contract_acc, (d) => { return parseFloat(d.amount) }).toFixed(2)
        dispatch(setValue('accessories_total', parseFloat(total) + parseFloat(con_total)))
    }
}

export const setUpdateAccessoriesItem = (id, prop, value) => {
    return (dispatch, getState) => {
        let _accessories = getState().workflowDefleetView.accessories
        let contract_acc = getState().workflowDefleetView.selectedVehicleContractAccessories.data
        var data = _accessories.map((x, i) => {
            return (x.id === id && (prop === 'accessoryname')) ?
                {
                    ...x,
                    accessoryname: value
                } : (x.id === id && (prop === 'amount')) ?
                    {
                        ...x,
                        amount: value,
                    } : x
        })
        const total = _.sumBy(data, (d) => { return parseFloat(d.amount) }).toFixed(2)
        const con_total = _.sumBy(contract_acc, (d) => { return parseFloat(d.amount) }).toFixed(2)
        dispatch({
            type: types.UPDATE_ACCESSORIES,
            payload: data
        })
        dispatch(setValue('accessories_total', parseFloat(total) + parseFloat(con_total)))
    }
}