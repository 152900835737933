import * as types from './types'
import * as appTypes from '../../App/types'
import * as formActions from '../../Form/actions'

import * as componentActions from '../../Components/actions'

import moment from 'moment'
import _ from 'lodash'


export const loadTeamData = () => {
    return (dispatch) => {
        dispatch(componentActions.hardReloadComponent('team'))
    }
}

export const getTeamData = (id) => {
    return (dispatch) => {
        dispatch(formActions.getComponentData('team', id, false))
    }
}


export const setComponentFieldInput = (field, input) => {
    //console.log('setComponentFieldInput',field, input)
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = input ? format(field, input) : ''

        dispatch({
            type: types.SET_TEAM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                validation: true // validate(component.schema, field, value)
            }
        })
    }
}

export const setComponentFieldInputAndText = (field, input, text) => {
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = format(field, input)
        dispatch({
            type: types.SET_TEAM_COMPONENT_FIELD_INPUT_AND_TEXT_VALUE,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                text: text,
                validation: validate(component.schema, field, value)
            }
        })
    }
}


export const changeTabView = (tab) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_TEAM_COMPONENT_CURRENT_TAB,
            payload: tab
        })
    }
}

export const toggleDeleteConfirm = (id) => {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_TEAM_COMPONENT_DELETE_WIN_STATUS,
            payload: id
        })
    }
}

export const getComponentFieldLookupData = (field, searchText, dataSource, params) => {
    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const payload = {
            component: component,
            field: field,
            related: '',
            query: searchText.length > 0 ? '?' + field.search + '=' + searchText : '',
            text: searchText
        }

        dispatch({
            type: types.GET_TEAM_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return _.get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch) => {
        dispatch({
            type: types.SET_TEAM_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component.name,
                field: field.name,
                data: data
            }
        })
    }
}


export const saveTeam = (component, user) => {
    //console.log('saveTeam',component)
    const getData = () => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name]) {
                if (x.key) {
                    if (component.data[x.name].input !== '') {
                        obj[x.name] = component.data[x.name].input
                    }
                }
                else {
                    obj[x.name] = component.data[x.name].input !== '' ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }

    return (dispatch, getState) => {
        let data = getData()
        
        //console.log('SAVE_TEAM_COMPONENT_CUSTOMER_DATA data',data)
        dispatch({
            type: types.SAVE_TEAM_COMPONENT_CUSTOMER_DATA,
            payload: {...data, client_id: 2 }
        })
    }
}

export const saveTeamDone = (payload) => {
    //console.log('saveSubAccountDone',payload)
    return (dispatch, getState) => {
        dispatch(componentActions.hardReloadComponent('reseller'))
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'License account save',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(changeTabView(0))
    }
}

// export const deleteReseller = (id) => {
//     return (dispatch) => {
//         dispatch({
//             type: types.DELETE_COMPONENT_RESELLER_RECORD,
//             payload: {reseller_id: id}
//         })
//     }
// }



// export const deleteResellerDone = (res) => {
//     return (dispatch, getState) => {
//         dispatch(getResellerData(0))
//         dispatch(changeTabView(0))
//         dispatch(loadTeamData())
//         dispatch({
//             type: appTypes.SHOW_NOTIFICATION,
//             payload: {
//                 title: 'System Notification',
//                 additionalText: 'Record successfully deleted',
//                 overflowText: getState().login.fields.email.input,
//                 autoHide: 10000,
//                 timestamp: moment().format('h:mm A')
//             }
//         })
//     }
// }


// export const getComponentFieldLookupData = (field, searchText, dataSource, params) => {
//     return (dispatch, getState) => {
//         const component = getState().components[field.component.name]
//         const payload = {
//             component: component,
//             field: field,
//             related: '',
//             query: searchText.length > 0 ? '?' + field.search + '=' + searchText : '',
//             text: searchText
//         }

//         dispatch({
//             type: types.GET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA,
//             payload: payload
//         })
//     }
// }

// export const setComponentFieldLookupData = (component, field, payload) => {
//     const fields = field.display.split('|')
//     const accessor = fields[0].split('.')[0]

//     const data = payload.data.map(x => {
//         return {
//             value: x[field.name],
//             text: fields.map(y => {
//                 return y.split(',').map(z => {
//                     return _.get(x, z.replace(accessor + '.', ''))
//                 }).join(' ')
//             }).join(', ')
//         }
//     })

//     return (dispatch) => {
//         dispatch({
//             type: types.SET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA,
//             payload: {
//                 component: component.name,
//                 field: field.name,
//                 data: data
//             }
//         })
//     }
// }



export const getTreeViewData = (field,datasource,value) => {
    return (dispatch) => {
        let data = {field: field,datasource: datasource,value:value,query: datasource + "/other", query: 'tree_select_data'}
        //console.log('getTreeViewData data',data)
        dispatch({
            type: types.GET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: {
                query: 'tree_select_data',
                value:value,
                data: data,
                field: field
            }
        })
    }
}

export const setTreeViewData = (response, payload) => {
    return (dispatch) => {
        //console.log('setTreeViewData payload',payload)
        //console.log('setTreeViewData response',response)
        dispatch({
            type: types.SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA,
            payload: {
                field: payload.field + '_data',
                data: response ? response.hierarchy : []
            },
        })
    }
}

const validate = (schema, field, input) => {
    let result = []
    if(!input && field.required){
        return {
            valid: false,
            field: field.title,
            message: field.title + ' is required',
        }
    }
    return {
        valid: true,
        field: field.title,
        message: '',
    }
}
