import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, IconButton  } from '@mui/material'

import Icon from '../../Icon/component'
import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewSupplierTransactions from '../../../controls/view_supplier_transaction'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Form from '../../../controls/form'
import Drawer from '../../../controls/drawer'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'

import Checkbox from '../../../controls/checkbox'
import CardControl from '../../../controls/card'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import * as styles from './styles'
import * as actions from './actions'

class CashbookArchiveCreate extends Component {

    componentDidMount() {
        this.props.actions.setValidationState('cashbookArchiveCreate')
        if(this.props.mode === "confirm") { 
            this.props.actions.setupWorkflowTaskInput(this.props.state, [], 'interest_rate', 'tsk_interest_rate_create')
        }
        else {
            this.props.actions.setInputValue('cashbook', [])
            this.props.actions.setInputValue('start_date', null)
            this.props.actions.setInputValue('end_date', null)
            
            //console.log('statex',JSON.stringify(this.props.state))
        }
    }

    
    getTransactionItems(confirm) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Audit Number</th>
                                    <th>Account Number</th>
                                    {/* <th>GL Link Number</th> */}
                                    <th>Cash Book Number</th>
                                    {/* <th>Reconciled Indicator</th> */}
                                    <th>Reference </th>
                                    <th>Date </th>
                                    {/* <th>Transaction Type</th> */}
                                    <th>Transaction Description</th>
                                    <th>Debit </th>
                                    <th>Credit </th>
                                    <th>Module </th>
                                    {/* <th>Sub Module</th>
                                    <th>Post Period</th>
                                    <th>User Date</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.cashbook.map((x,inx) => {
                                        return <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.audit_number} </td>
                                            <td>{x.accountingnumber}</td>
                                            {/* <td>{x.link_number}</td> */}
                                            <td>{x.cashbook_number}</td>
                                            {/* <td>{x.reconciled ? 'Yes' : 'No'}</td> */}
                                            <td>{x.reference}</td>
                                            <td>{moment(x.transaction_date).format('DD-MM-YYYY')}</td>
                                            {/* <td>{x.transaction_type}</td> */}
                                            <td>{x.item_type}</td>
                                            <td>{x.debit}</td>
                                            <td>{x.credit}</td>
                                            <td>{x.module}</td>
                                            {/* <td>{x.sub_module}</td>
                                            <td>{x.transaction_month}</td>
                                            <td>{moment(x.transaction_date).format('DD-MM-YYYY')}</td> */}
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getView() {
        let size = 7
        return <div>
            <Card
                title={'Cash Book Transaction Parameters'}
                subtitle={'Cash Book Transaction Archiving Date Range'}
                content={<Form label={false} fields={[
                    {
                        name: 'start_date', label: 'Start Date', title: 'Start Date', section: '', type: 'd', size: 4, value: this.props.state.start_date, 
                        onSelect: (arg) => { 
                            this.props.actions.setInputValue('start_date', arg)
                            this.props.actions.getCashBookTransactionList() }
                    },
                    {
                        name: 'end_date', label: 'End Date', title: 'End Date', section: '', type: 'd', size: 4, value: this.props.state.end_date, 
                        onSelect: (arg) => { 
                            this.props.actions.setInputValue('end_date', arg) 
                            this.props.actions.getCashBookTransactionList()
                        }
                    }
                ]} />
            }
            />
            <Card
                title={'Cash Book Transaction List'}
                subtitle={'Cash Book Transaction Archive List'}
                content={this.getTransactionItems()}
            />
        </div>
    }

    getConfirm() {
        
        return <div>
            <Card
                title={'Cash Book Transaction Parameters'}
                subtitle={'Cash Book Transaction Archiving Date Range'}
        content={
                <div> <Fields labelsize='130'
        fields={[
            {size: 6, label: 'Start Date:', value: this.props.state.start_date},
            {size: 6, label: 'End Date', value: this.props.state.end_date},
        ]}
        /></div>}
        />
        <Card
            title={'Cash Book Transaction List Review'}
            subtitle={'Cash Book Transaction List'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <h5>{'Cash Book Transaction To Archive'}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.getTransactionItems()}
                    </Col>
                </Row>
            </Container>}
        />
        </div>
    }

    getLayout() {
        return <>
            {this.props.mode === "confirm" ? this.getConfirm() : this.getView()}
        </>
    }

    getErrorLayout() {
        return <Card
            title={'Error'}
            subtitle={'Connection did not respond  '}
            content={<div>
                <h4>Please check following:</h4>
                <ul>
                    <li>Make sure engine is connected</li>
                    <li>Make sure your not login twice</li>
                </ul>
                <i>Alternativily you can contact system administrator for further assistance.</i>
            </div>}
        />
    }

    render() {
        
        this.data = null
        try {
        } catch (error) {

        }
        
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.cashbookArchiveCreate,
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;
    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashbookArchiveCreate)