import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_WORKFLOW_COMPONENT_INPUT_FIELD_VALUE:
            return {
                ...state,
                fields: state.fields.map(x =>
                    (x.name === action.payload.prop)
                        ? {
                            ...x,
                            value: action.payload.value,
                        }
                        : x
                )
            }
            case types.SET_WORKFLOW_COMPONENT_INPUT_FIELD_TEXT:
                return {
                    ...state,
                    fields: state.fields.map(x =>
                        (x.name === action.payload.prop)
                            ? {
                                ...x,
                                text: action.payload.value,
                            }
                            : x
                    )
                }
        case types.SET_WORKFLOW_COMPONENT_INPUT_LOOKUP_DATA:
            return {
                ...state,
                fields: state.fields.map(x =>
                    (x.name === action.payload.prop)
                        ? {
                            ...x,
                            data: action.payload.value,
                        }
                        : x
                )
            }
        case types.SET_WORKFLOW_FORM_COMPONENT_INPUT_LOOKUP_DATA:
            return {
                ...state,
                fields: state.fields.map(x =>
                    (x.name === action.payload.parent)
                        ? {
                            ...x,
                            fields: x.fields.map((f, i) =>
                                (i === action.payload.inx)
                                    ? f.map(z => {
                                        return (z.name === action.payload.prop)
                                            ? {
                                                ...z,
                                                data: action.payload.value,
                                            }
                                            : z
                                    })
                                    : f
                            )
                        }
                        : x
                )
            }
        case types.SET_SELECTED_VEHICLE_MMCODE_DATA:
            return {
                ...state,
                fields: state.fields.map(x =>
                    (x.name === action.payload.parent)
                        ? {
                            ...x,
                            fields: x.fields.map((f, i) =>
                                (i === action.payload.inx)
                                    ? {
                                        ...f,
                                        mmcode: action.payload.value,
                                    }
                                    : f
                            )
                        }
                        : x
                )
            }
        case types.SET_COMPONENT_SELECTED_LIST_LOOKUP_DATA:
            return {
                ...state,
                fields: state.fields.map(x => {
                    return (x.name === action.payload.prop.parent)
                        ? {
                            ...x, 
                            fields: x.fields.map((n, i) => {
                            return (i === action.payload.inx)
                                ? [
                                    ...n.map(f => {
                                        return (f.name === "accessories")
                                            ? {
                                                ...f,
                                                [action.payload.prop.prop]: action.payload.value,
                                            }
                                            : f
                                        })
                                    ]
                                : n
                            }
                            )
                           
                        }
                        : x
                    }
                )
            }
        case types.SET_WORKFLOW_FORM_INPUT_FIELD_VALUE:
            return {
                ...state,
                fields: state.fields.map(x =>
                    (x.name === action.payload.parent)
                        ? {
                            ...x,
                            fields: x.fields.map((f, i) =>
                                (i === action.payload.inx)
                                    ? f.map(z => {
                                        return (z.name === action.payload.prop)
                                            ? {
                                                ...z,
                                                value: action.payload.value,
                                            }
                                            : z
                                    })
                                    : f
                            )
                        }
                        : x
                )
            }
        case types.SET_WORKFLOW_FORM_INPUT_FIELD_TEXT:
            return {
                ...state,
                fields: state.fields.map(x =>
                    (x.name === action.payload.parent)
                        ? {
                            ...x,
                            fields: x.fields.map((f, i) =>
                                (i === action.payload.inx)
                                    ? f.map(z => {
                                        return (z.name === action.payload.prop)
                                            ? {
                                                ...z,
                                                text: action.payload.value,
                                            }
                                            : z
                                    })
                                    : f
                            )
                        }
                        : x
                )
            }
        case types.SET_CUSTOMER_DERIVATIVE_VALUES:
            return {
                ...state,
                derivative: action.payload
            }
        case types.SET_SELECTED_CUSTOMER_DATA:
            return {
                ...state,
                customer: action.payload
            }
        case types.SET_CONTRACT_REPLACE_VEHICLE_DATA:
            return {
                ...state,
                replacevehicle: action.payload
            }
        case types.SET_SELECTED_VEHICLE_CATEGORY:
            return {
                ...state,
                vehiclecategory: action.payload
            }
        case types.SET_SELECTED_QUOTE_FOR:
            return {
                ...state,
                quotefor: action.payload
            }
        case types.SET_SELECTED_VEHICLE_CATEGORY_CHIP:
            return {
                ...state,
                accessories_chip: action.payload
            }
        case types.SET_COMPONENT_LIST_LOOKUP_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        case types.ADD_COMPONENT_SUBFORM_FIELDS_DATA:
            return {
                ...state,
                fields: state.fields.map(x =>
                    (x.name === action.payload.prop)
                        ? {
                            ...x,
                            fields: action.payload.fields,
                        }
                        : x
                )
            }
        default:
            return state
    }
}