import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
       
       
        case types.SET_WS_MAINTENANCE_HOSTORY_PDF_DOCUMENT:
            return {
                ...state,
                preview: action.payload.preview
            }
        case types.SET_COMPONET_HISTORY_INPUT_FILEDS:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                vehicle: action.payload
            }
       
        case types.TOGGLE_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                isOpen: action.payload.status === false? false:!state.isOpen
            }
        case types.SHOW_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                isOpen: true,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    isOpen: true
                }
            }
        case types.CLOSE_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state,
                isOpen: false,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    isOpen: false
                }
            }
        case types.RESET_WORKFLOW_VEHICLE_HISTORY:
            return {
                ...state
            }
        default:
            return state
    }
}