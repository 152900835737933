export const workflow = {}
export const workflow_queue = {}
export const task = {}
export const stepIndex = 0
export const steps = 0
export const finished = false
export const progress = 0
export const errors = []
export const loading = true
export const reject = false
export const isopen = false
export const viewpdf = false
export const anchorEl = false
export const edit = false
export const next_team_users = []

export const confirmEl = null
export const confirm = false


