import * as types from './types'
import * as _ from 'lodash'

export const getUserCustomer = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CUSTOMER_STRUCTURE,
            payload: {
                type: 'user_customer',
                user_id: getState().user.user_id
            }
        })
    }
}

export const setUserCustomer = (payload) => {
    return (dispatch, getState) => {
        //console.log('setUserCustomer', payload)
        dispatch({
            type: types.SET_HIERARCHY,
            payload: payload
        })
    }
}

export const toggleCollapse = (customer_id) => {
    return (dispatch, getState) => {
        let data = getState().hierarchy.tree
        let _data = data.map(x => {
            return x.customer_id === customer_id ? {...x, collapse: !x.collapse} : x
        })
        //console.log('toggleCollapse customer_id',customer_id)
        //console.log('toggleCollapse _data',_data)
        dispatch({
            type: types.SET_HIERARCHY,
            payload: _data
        })
    }
}

export const contextMenu = (customer_id, anchorEl) => {
    return (dispatch, getState) => {
        let data = getState().hierarchy.tree
        let _data = data.map(x => {
            return x.customer_id === customer_id ? {...x, contextmenu: !x.contextmenu} : {...x, contextmenu: false}
        })
        //console.log('toggleCollapse customer_id',customer_id)
        //console.log('toggleCollapse _data',_data)
        dispatch({
            type: types.SET_HIERARCHY,
            payload: _data
        })
        dispatch({
            type: types.SET_CONTEXT_MENU_ANCHOREL,
            payload: anchorEl
        })
    }
}
export const toggleDetailWin = (customer_id) => {
    return (dispatch, getState) => {
        let data = getState().hierarchy.tree
        let _data = data.map(x => {
            return x.customer_id === customer_id ? {...x, detail_win: !x.detail_win} : {...x, detail_win: false}
        })
        //console.log('toggleCollapse customer_id',customer_id)
        //console.log('toggleCollapse _data',_data)
        dispatch({
            type: types.SET_HIERARCHY,
            payload: _data
        })
    }
}
export const hideHierarchy = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.HIDE_HIERARCHY
        })
    }
}
export const toggleHierarchy = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_HIERARCHY
        })
    }
}

export const getUserTeams = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_USER_TEAMS,
            payload: id
        })
    }
}

export const setUserTeams = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_TEAMS,
            payload: data[0].user_team.map(x => { return x.team_id }).join('.')
        })

        dispatch({
            type: types.GET_HIERARCHY,
            payload: data[0].user_team.map(x => { return x.team_id }).join('.')
        })
    }
}

export const getHierarchy = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_HIERARCHY
        })
    }
}

export const setHierarchy = (data) => {
    return (dispatch, getState) => {
        let hierarchy = []

        data.forEach(x => {
            const businessTeam = x.businessteam

            if (businessTeam.length) {
                businessTeam.forEach(y => {
                    _.set(hierarchy, y.businessname + '.' + y.customername, {
                        ...y.customer,
                        business_id: y.business_id
                    })
                })
            }
        })

        const recurse = (obj) => {
            return Object.keys(obj).map(x => {
                if (!obj[x].customer_id) {
                    return {
                        label: x,
                        value: {
                            business_id: obj[x].business_id
                        },
                        children: recurse(obj[x])
                    }
                }
                else {
                    return {
                        label: obj[x].businessunit!== 'null' ? obj[x].businessunit+ ' (' + obj[x].customername + ')' : obj[x].customername,
                        value: {
                            business_id: obj[x].business_id,
                            customer_id: obj[x].customer_id
                        }
                    }
                }
            })
        }

        //customername, costcentre, customerbusinessunit_fktext, registeredname, hierarchyname

        const final = Object.keys(hierarchy).map(x => {
            return {
                label: x,
                value: {
                    business_id: hierarchy[x].business_id
                },
                children: recurse(hierarchy[x])
            }
        })

        dispatch({
            type: types.SET_HIERARCHY,
            payload: final
        })
    }
}