import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT_SCHEDULER_FORM_FIELDS:
            return {
                ...state,
                schedule_win: false,
                schedulename: '',
                scheduletime: null,
                enddate: null,
                startdate: null,
                excel: false,
                email: false,
                mon: false,
                tue: false,
                wed: false,
                thu: false,
                fri: false,
                sat: false,
                sun: false,
            }
        case types.LAOD_SELECTED_SCHEDULE_FOR_VIEW_OR_EDIT:
            return {
                ...state,
                email: action.payload.schedule_email,
                enddate: action.payload.schedule_end,
                excel: action.payload.schedule_excel,
                fri: action.payload.schedule_fri,
                mon: action.payload.schedule_mon,
                schedulename: action.payload.schedule_name,
                sat: action.payload.schedule_sat,
                startdate: action.payload.schedule_start,
                sun: action.payload.schedule_sun,
                thu: action.payload.schedule_thu,
                scheduletime: action.payload.schedule_time,
                tue: action.payload.schedule_tue,
                wed: action.payload.schedule_wed,
            }
        case types.BI_READY:
            return {
                ...state,
                status: action.payload
            }
        case types.INTELLIGENCE_LOADING:
            return {
                ...state,
                report: action.payload
            }
        case types.SET_REPORT_CATEGORY_LIST:
            return {
                ...state,
                [action.name]: action.payload
            }
        case types.SET_REPORT_VIEW:
            return {
                ...state,
                report_view: action.payload
            }
        case types.SET_REPORT_KEY:
            return {
                ...state,
                report_key: action.payload
            }
        case types.SET_REPORT_GRID_DATA_BY_REPORT_NAME:
            return {
                ...state,
                report_data: action.payload
            }
        case types.SET_BASHBOARD_VIEW:
            return {
                ...state,
                dashboard_view: action.payload
            }
        case types.SET_SCHEDULE_WINDOW_OPEN_AND_CLOSE:
            return {
                ...state,
                schedule_win: action.payload
            }
        case types.SET_COMPONENT_REPORT_SCHEDULER_BY_USER_AND_REPORT:
            return {
                ...state,
                schedule_list: action.payload
            }
        case types.SET_REPORT_GRID_DATA_BY_FILTER:
            return {
                ...state,
                [action.name]: action.data
            }
        case types.SET_COMPONENT_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
            case types.SET_SUB_REPORT_CATEGORY_LIST:
            return {
                ...state,
                [action.name]: action.payload
            }
        default:
            return state
    }
}