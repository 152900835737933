import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AutoComplete from '../../../controls/autocomplete'

import Card from '../../../controls/card'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import ReactTable from "react-table"
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import * as compActions from '../../Components/actions'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as styles from './styles'



var viewsearchbox = false, cust_id = 0
class SUPPLIERTRANSACTIONCUSTOM extends Component {
    componentDidMount() {
        if (this.props.state.loading) this.props.actions.getComponent('suppliertransactioncustom')
    }

    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "pdfstatement") {
            return < Button className={"global_button"} variant="contained" header={false}
                label={'Download'}
                onClick={(event) => {
                    const data = {
                        month: row.value,
                        id: this.props.state.data.supplier_id.input
                    }
                    //this.props.actions.getCustomerStatementPDF(data)
                }}
            >{window.t.en('Download')}</Button>
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        }

        return columns
    }

    getGrid() {
        return <div>< Button className={"global_button"}
            secondary={true}
            label="Excel Export"
            icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
            onClick={(event) => {
                this.props.actions.excelExport(this.component)
            }}
        ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Excel Export</Button>
            <ReactTable
                key={'grid_' + this.component.name + '_' + this.component.component_id}
                data={this.props.state.data.suppliertransactioncustom_id.data}
                columns={this.getColumns()}
                defaultPageSize={this.component.grid.paging.pageSize}
                manual
                onFetchData={
                    (state) => {
                        //this.props.actions.getComponentGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                    }
                }
                getTrProps={(state, rowInfo) => {
                    return {
                        onClick: (event) => {
                            //this.props.actions.selectRow(this.component.name, rowInfo)

                            if (this.props.toggle) {
                                //this.props.actions.toggleInfoSheet()
                            }
                        },
                        style: {
                            background: this.component.selected && rowInfo
                                ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                                : 'white',
                            color: this.component.selected && rowInfo
                                ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                                : 'black'
                        }
                    }
                }}
                onResizedChange={(newResized, event) => {
                    //this.props.actions.resizeColumn(this.component.name, newResized)
                }}
                minRows={1}
                pages={this.component.grid.paging.pageCount}
                showPageJump={false}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable></div>
    }


    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <Card
            title={window.t.en('Supplier Transactions')}
            subtitle={window.t.en('Transactions')}
            content={<Container style={styles.whitediv}>
                <Row>
                    <Col xl={12}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Creditor:'}</div>
                            <div style={styles.fieldContent}>
                                <AutoComplete
                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    id={'dll_creditor_id'}
                                    key={'dll_creditor_id'}
                                    title={'Select a creditor...'}
                                    filter={AutoComplete.fuzzyFilter}
                                    openOnFocus={true}
                                    fullWidth={true}
                                    info={{
                                        show: false
                                    }}
                                    dataSourceConfig={{
                                        text: 'text',
                                        value: 'value'
                                    }}
                                    data={this.props.state.data.supplier_id.data}
                                    onSelect={
                                        (chosenRequest, index) => {
                                            this.props.actions.setLookupValue("supplier_id", chosenRequest.value, chosenRequest.text)
                                            this.props.actions.getAllTransactions(chosenRequest.value)
                                            viewsearchbox = true
                                            cust_id = Number(chosenRequest.value)
                                        }
                                    }
                                    onClick={
                                        (event) => {
                                            this.props.actions.getSupplierList('')
                                        }
                                    }
                                    onFilter={
                                        (search, dataSource, params) => {
                                            this.props.actions.getSupplierList(search)
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.getGrid()}
                    </Col>
                </Row>
            </Container>} />

    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.suppliertransactioncustom
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SUPPLIERTRANSACTIONCUSTOM)