
export const data = []
export const supplierdistance_data = []
export const suppliersdistance_data = []
export const suppliersacessoryhistory_data = []
export const supplieraccessory_data = {}
export const chip_accessory = []
export const distance = 0
export const components_list = [
    {
        component: 'Accessories'
    },
    {
        component: 'Service'
    },
    {
        component: 'Sundry'
    }
]

 export const components_selected = ''


 export const fields = [
    {
        name: 'itemtype',
        display: 'text',
        title: 'Item Type',
        type: 'autocomplete',
        field: 'quoteitemtype',
        component: 'quoteitemtype',
        width: 200,
    },
    {
        name: 'distance',
        display: 'text',
        title: 'Radius',
        type: 'textbox',
        component: 'text',
        width: 150,
    },
    {
        name: 'supplier',
        display: 'text',
        title: 'Location',
        type: 'autocomplete',
        field: 'suppliername',
        query:'vehiclepurchaseprovider=true',
        component: 'supplier',
        width: 350,
    },
    {
        name: 'itemdescription',
        display: 'text',
        title: 'Item Description',
        type: 'items',
        field: 'accessory',
        sundry: 'suppliersundrypricing.sundry,supplier.sundry#description,supplier#suppliername,sellingprice',
        accessory: 'supplieraccessorypricing.accessory,supplier.accessory#accessoryname,supplier#suppliername,sellingprice',
        service: 'supplierservicepricing.service,supplier.service#servicedescription,supplier#suppliername,sellingprice',
        width: 350,
    },
    {
        name: 'quantity',
        display: 'text',
        title: 'Quantity',
        type: 'textbox',
        component: 'text',
        width: 150,
    },
    {
        name: 'costprice',
        display: 'text',
        title: 'Cost Price',
        type: 'textbox',
        component: 'text',
        width: 150,
    },
    {
        name: 'sellingprice',
        display: 'text',
        title: 'Selling Price',
        type: 'textbox',
        component: 'text',
        width: 150,
    },
    {
        name: 'totalprice',
        display: 'text',
        title: 'Total Price',
        type: 'calculated',
        component: 'text',
        width: 150,
    },
    {
        name: 'retrofittable',
        display: 'OEM Fitted',
        title: 'OEM Fitted',
        type: 'checkbox',
        component: 'text',
        width: 100,
    },
    {
        name: 'nonevatable',
        display: 'None Vatable',
        title: 'Vatable',
        type: 'checkbox',
        component: 'text',
        width: 100,
    }
]
