export const GET_SUB_ACCOUNT_ACCOUNT_DATA = 'GET_SUB_ACCOUNT_ACCOUNT_DATA'
export const SET_SUB_ACCOUNT_ACCOUNT_DATA = 'SET_SUB_ACCOUNT_ACCOUNT_DATA'
export const SET_SUB_ACCOUNT_ACCOUNT_CURRENT_TAB = 'SET_SUB_ACCOUNT_ACCOUNT_CURRENT_TAB'
export const SET_SUB_ACCOUNT_COMPONENT_FIELD_INPUT = 'SET_SUB_ACCOUNT_COMPONENT_FIELD_INPUT'
export const SAVE_SUB_ACCOUNT_COMPONENT_CUSTOMER_DATA = 'SAVE_SUB_ACCOUNT_COMPONENT_CUSTOMER_DATA'
export const GET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA = 'GET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA = 'SET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA'

export const SET_COMPOENT_RELATED_GRID_DATA = 'SET_COMPOENT_RELATED_GRID_DATA'
export const GET_COMPOENT_RELATED_GRID_DATA = 'GET_COMPOENT_RELATED_GRID_DATA'
export const DEL_RELATED_DATA_BY_IDS = 'DEL_RELATED_DATA_BY_IDS'
export const UPD_RELATED_DATA_BY_IDS = 'UPD_RELATED_DATA_BY_IDS'
export const ADD_RELATED_DATA_BY_IDS = 'ADD_RELATED_DATA_BY_IDS'
export const TOGGLE_CRID_VIEW_WIN = 'TOGGLE_CRID_VIEW_WIN'

export const SAVE_RELATED_GRID_COMPONENT_DATA = 'SAVE_RELATED_GRID_COMPONENT_DATA'
export const SET_FORM_VALIDATION_RESULT = 'SET_FORM_VALIDATION_RESULT'
export const SET_COMPONENT_FIELD_INPUT = 'SET_COMPONENT_FIELD_INPUT'
export const COMPONENT_DATA_SAVE_FAILED = 'COMPONENT_DATA_SAVE_FAILED'