
export const speeddial = null
export const speeddialEl = null
export const formitem = null
export const workqueue = null
export const grid_filter = null
export const win_ack_input_input = null
export const win_ack_input = false


export const win_email_input = null
export const win_email = false

export const win_escalate = false
export const win_re_assigning = false
export const win_send_message = false
export const win_send_message_input = null


export const record_id = null
export const record_field = null

export const formdata = []
export const users = []
export const selected_user = ''