import _ from 'lodash'

export const getDefaultValue = (field) => {
    if (!field.component_field_display_type) {
        return ''
    }

    switch (field.component_field_display_type.name) {
        case 'autoComplete':
            return ''
        case 'datePicker':
            return new Date()
        case 'hidden':
            return 0
        case 'timePicker':
            return new Date()
        case 'textBox':
            return ''
        case 'toggle':
            return false
        default:
            return ''
    }
}

export const getComponentTitle = (component, data) => {
    return getComponentDisplayText(component, component ? component.concatTitle : '', data)
}

export const getComponentDescription = (component, data) => {
    return getComponentDisplayText(component, component.concatDescription, data)
}

const getComponentDisplayText = (component, key, data) => {
    if (!key && !data) return ''

    const fields = key.split(',').map(x => {
        
        if (x.indexOf('_id') > -1 && x.indexOf('.') > -1) {
            const field = component.component_field.filter(y => y.name === x.split('.').pop())[0]

            const accessors = field.display.split('|')
            const parts = accessors[0].split('.')[0]

            return accessors.map(y => {
                return y
                    .split(',')
                    .map(z => {
                        return data
                            ? _.get(component.data[parts], z.replace(parts + '.', ''))
                            : _.get(data[parts], z.replace(parts + '.', ''))
                    })
                    .join(' ')
            }).join(', ')
        }
        else {
            return data
                ? component ? data[x.replace(component.name + '.', '')] : ''
                : component.data[x.replace(component.name + '.', '')]
                    ? component.data[x.replace(component.name + '.', '')].input
                    : component[key]
        }
    })

    return fields.join(' ')
}