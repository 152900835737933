import * as colours from '@mui/material/colors'
import * as config from '../../../config'

export const view = {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0
}

export const card = {
    height: '100%'
}

export const scrollbar = {
    backgroundColor: colours.red[500],
    color: colours.red[500],
}

export const headerrow = {
    borderBottom: '1px dashed gray',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '8px'
}

export const itemrow = {
    fontSize: '16',
    borderBottom: '1px dashed gray',
}

export const spacer = {
    height: '65px',
    width: '100%',
}

export const headerBackground = (image) => {
    return image && image !== null
        ? {
            height: '130px',
            background: 'url(' + config.system.cdn + '/' + image + ')',
            backgroundSize: 1980,
            //opacity: 0.85,
        }
        : {
            height: '130px'
        }
}

export const confirmSelection = {
    textAlign: 'right'
}


export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}
export const selectedFieldName = {
    fontWeight: 'bold'
}

export const dropZone = {
    width: '85%',
    height: '300px',
    border: '1px solid red',
    padding: '10px'
}


export const rowTitle = {
    width: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    paddingRight: 10
}



export const textBox = {
    width: '100px'
}

export const error = {
    color: colours.red[600]
}

export const valid = {
    color: colours.green[500]
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    textAlign: 'left',
}

export const searchInput = {
    display: 'table-cell',
    //width: '265px',
    fontWeight: 'normal',
    textAlign: 'right'
}

export const searchIcon = {
    display: 'table-cell',
    width: '100px',
    textAlign: 'right'
}

export const left = {
    width: '100%',
    textAlign: 'left'
}

export const verticalMiddle = {
    verticalAlign: 'middle'
}

export const hidden = {
    display: 'none'
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 2,
    verticalAlign: 'left'
}


export const headerContainer = {
    width: '100%',
    display: 'flex'
}

export const headerIcon = {
    width: 40,
    height: 40,
    fontSize: 40,
    margin: '0 auto'
}

export const headerTitle = {
    background: 'white',
    //opacity: 0.8,
    fontWeight: 'bold',
    fontSize: '34',
    padding: 5,
    marginRight: 20
}

export const headerDescription = {
    background: 'white',
    //opacity: 0.8,
    //color: 'white',
    width: '100%',
    fontSize: '34',
    padding: 5,
    borderRight: '1px solid black'
}

export const content = {
    height: '100%'
}

export const flex = {
    display: 'flex'
}

export const toggle = {
    marginBottom: 16,
  }

  
export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldTitleBold = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10,
    fontWeight: 'bold'
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const rightpanel = {
    background: '#D1E0E6',
    borderLeft: '2px solid black'
}
export const fieldContentTot = {
    display: 'table-cell',
    width: '80px'
}
export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '19px',
}
export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}
export const numericFields = {
    textAlign: 'right',
}
export const whitediv = {
    backgroundColor: '#fff',
    padding: 2,
    verticalAlign: 'left'
}
//import Theme from '../../theme'
export const highlight = colours.red[200]

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const action = {
    fontSize: '15px',
    color: colours.red[500]
}

export const actionHoverColor = {
    color: colours.red[500]
}

export const gridOptionsIcon = {

}