import Button from '@mui/material/Button'
import * as _ from 'lodash'
import Card from '../../../controls/card'

import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as styles from './styles'
import * as gridActions from '../../Custom/AdhocGrid/actions'

import TextField from '../../../controls/textField'
import Checkbox from '@mui/material/Checkbox'


class ManageComponent extends Component {
    componentDidMount() {
        this.actions = this.props.actions;
    }

    getGrid() {
        return <AdhocGrid
            key={'componentmanager'}
            name={'componentmanager'}
            component={'/components/component/search'}
            filterable={true}
            sortable={true}
            style={{ fontSize: '14px' }}
            query={'?title='}
            columns={[
                // { accessor: 'name', Header: 'Name', width: 200, filterable: false, Cell: row => {
                //     return (
                //         <div>
                //             {row.original['name']}
                //         </div>
                //     )
                // }  },
                {
                    accessor: 'title', Header: 'Title', width: 300, filterable: false, Cell: row => {
                        return (
                            <div style={{ padding: '15px 0px' }}>
                                <br />
                                {row.original['title']}
                            </div>
                        )
                    }
                },
                {
                    accessor: 'component_category', Header: 'Category', filterable: false, width: 230, Cell: row => {
                        return (
                            <div style={{ padding: '15px 0px' }}>
                                <br />
                                {row.original['component_category'] ? row.original['component_category']['title'] : ''}
                            </div>
                        )
                    }
                },
                {
                    accessor: 'section', Header: 'Section', width: 200, filterable: false, Cell: row => {
                        return (
                            <div style={{ paddingTo: '0px 0px' }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    hintText={''}
                                    value={row.original['section']}
                                    onChange={(event, value) => {
                                        this.props.actions.updateComponent(row.original['component_id'], 'section', event.target.value)
                                        this.props.actions.adhocGridSetCellInputValue('componentmanager', 'section', row.viewIndex, event.target.value)
                                        //this.props.actions.getCostCentreChangeList(value)
                                    }}
                                />
                            </div>
                        )
                    }
                },
                {
                    accessor: 'related', Header: 'Related', width: 200, filterable: false, Cell: row => {
                        return (
                            <div>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    hintText={''}
                                    value={row.original['related']}
                                    onChange={(event, value) => {
                                        this.props.actions.updateComponent(row.original['component_id'], 'related', event.target.value)
                                        this.props.actions.adhocGridSetCellInputValue('componentmanager', 'related', row.viewIndex, event.target.value)
                                    }}
                                />
                            </div>
                        )
                    }
                },
                {
                    accessor: 'menu', Header: 'Menu', width: 100, filterable: false, Cell: row => {
                        return (
                            <div>
                                <br />
                                <Checkbox
                                    checked={row.original['menu']}
                                    onChange={(event, checked) => {
                                        this.props.actions.updateComponent(row.original['component_id'], 'menu', checked)
                                        this.props.actions.adhocGridSetCellInputValue('componentmanager', 'menu', row.viewIndex, checked)
                                    }} />
                            </div>
                        )
                    }
                },
                {
                    accessor: 'intelligent', Header: 'Dashboard', width: 100, filterable: false, Cell: row => {
                        return (
                            <div>
                                <br />
                                <Checkbox
                                    checked={row.original['intelligent']}
                                    onChange={(event, checked) => {
                                        this.props.actions.updateComponent(row.original['component_id'], 'intelligent', checked)
                                        this.props.actions.adhocGridSetCellInputValue('componentmanager', 'intelligent', row.viewIndex, checked)
                                    }} />
                            </div>
                        )
                    }
                },
                {
                    accessor: 'report', Header: 'Report', width: 100, filterable: false, Cell: row => {
                        return (
                            <div>
                                <br />
                                <Checkbox
                                    checked={row.original['report']}
                                    onChange={(event, checked) => {
                                        this.props.actions.updateComponent(row.original['component_id'], 'report', checked)
                                        this.props.actions.adhocGridSetCellInputValue('componentmanager', 'report', row.viewIndex, checked)
                                    }} />
                            </div>
                        )
                    }
                },
                {
                    accessor: 'dataview', Header: 'Grid', width: 100, filterable: false, Cell: row => {
                        return (
                            <div>
                                <br />
                                <Checkbox
                                    checked={row.original['dataview']}
                                    onChange={(event, checked) => {
                                        this.props.actions.updateComponent(row.original['component_id'], 'dataview', checked)
                                        this.props.actions.adhocGridSetCellInputValue('componentmanager', 'dataview', row.viewIndex, checked)
                                    }} />
                            </div>
                        )
                    }
                },
                {
                    accessor: 'createview', Header: 'Create', width: 100, filterable: false, Cell: row => {
                        return (
                            <div>
                                <br />
                                <Checkbox
                                    checked={row.original['createview']}
                                    onChange={(event, checked) => {
                                        this.props.actions.updateComponent(row.original['component_id'], 'createview', checked)
                                        this.props.actions.adhocGridSetCellInputValue('componentmanager', 'createview', row.viewIndex, checked)
                                    }} />
                            </div>
                        )
                    }
                },
                {
                    accessor: 'event_management', Header: 'Event', width: 100, filterable: false, Cell: row => {
                        return (
                            <div>
                                <br />
                                <Checkbox
                                    checked={row.original['event_management']}
                                    onChange={(event, checked) => {
                                        this.props.actions.updateComponent(row.original['component_id'], 'event_management', checked)
                                        this.props.actions.adhocGridSetCellInputValue('componentmanager', 'event_management', row.viewIndex, checked)
                                    }} />
                            </div>
                        )
                    }
                },
                {
                    accessor: 'custom', Header: 'Custom', width: 100, filterable: false, Cell: row => {
                        return (
                            <div>
                                <br />
                                <Checkbox
                                    checked={row.original['custom']}
                                    onChange={(event, checked) => {
                                        this.props.actions.updateComponent(row.original['component_id'], 'custom', checked)
                                        this.props.actions.adhocGridSetCellInputValue('componentmanager', 'custom', row.viewIndex, checked)
                                    }} />
                            </div>
                        )
                    }
                },
            ]}
            paging={{
                page: 0,
                pageSize: 10,
                pages: 0,
                count: 0
            }}
            subComponent={row => {
                return <div key={'component_fields_' + row.original['name']} style={{ padding: "2px" }}>
                    <Card title={window.t.en('Component Fields')}
                        subtitle={window.t.en('Validation (text, number, alphanumeric, phone, email)')}
                        content={<Container style={{ backgroundColor: '#fff', margin: '1px' }}>
                            <Row style={styles.tableheader}>
                                <Col xl={2}>{'Name'}</Col>
                                <Col xl={2}>{'Title'}</Col>
                                <Col xl={1}>{'Grid'}</Col>
                                <Col xl={1}>{'Form'}</Col>
                                <Col xl={1}>{'View'}</Col>
                                <Col xl={1}>{'Required'}</Col>
                                <Col xl={1}>{'max Length'}</Col>
                                <Col xl={1}>{'Validation'}</Col>
                                <Col xl={2}>{'Error Text'}</Col>
                            </Row>
                            {
                                row.original['component_field']
                                    ? row.original['component_field'].sort((x, y) => x.order - y.order) //.filter(x => !x.key)
                                        .map((x, i) => {
                                            return <div>
                                                <Row style={styles.tablerow}>
                                                    <Col xl={2}>
                                                        <br />{x.name}</Col>
                                                    <Col xl={2}>
                                                        <br />{x.title}</Col>
                                                    <Col xl={1}>
                                                        <br />
                                                        <Checkbox
                                                            checked={x.grid}
                                                            onChange={(event, checked) => {
                                                                this.props.actions.updateComponentField(x.component_field_id, 'grid', checked)
                                                                this.props.actions.adhocGridSetSubCellInputValue('componentmanager', 'component_field', 'grid', row.viewIndex, i, checked)
                                                            }} />
                                                    </Col>
                                                    <Col xl={1}>
                                                        <br />
                                                        <Checkbox
                                                            checked={x.form}
                                                            onChange={(event, checked) => {
                                                                this.props.actions.updateComponentField(x.component_field_id, 'form', checked)
                                                                this.props.actions.adhocGridSetSubCellInputValue('componentmanager', 'component_field', 'form', row.viewIndex, i, checked)
                                                            }} />
                                                    </Col>
                                                    <Col xl={1}>
                                                        <br />
                                                        <Checkbox
                                                            checked={x.view}
                                                            onChange={(event, checked) => {
                                                                this.props.actions.updateComponentField(x.component_field_id, 'view', checked)
                                                                this.props.actions.adhocGridSetSubCellInputValue('componentmanager', 'component_field', 'view', row.viewIndex, i, checked)
                                                            }} />
                                                    </Col>
                                                    <Col xl={1}>
                                                        <br />
                                                        <Checkbox
                                                            checked={x.system_required ? true : x.isrequired}
                                                            onChange={(event, checked) => {
                                                                if (x.system_required && !checked) {
                                                                   this.props.actions.showNotification('This field is required by the System, you cannot make this field optional');
                                                                }
                                                                if (!x.system_required) {
                                                                    this.props.actions.updateComponentField(x.component_field_id, 'isrequired', checked)
                                                                    this.props.actions.adhocGridSetSubCellInputValue('componentmanager', 'component_field', 'isrequired', row.viewIndex, i, checked)
                                                                }
                                                            }} />
                                                    </Col>
                                                    <Col xl={1}>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            hintText={''}
                                                            value={x.maxLength}
                                                            onChange={(event, value) => {
                                                                this.props.actions.updateComponentField(x.component_field_id, 'maxLength', event.target.value)
                                                                this.props.actions.adhocGridSetSubCellInputValue('componentmanager', 'component_field', 'maxLength', row.viewIndex, i, event.target.value)
                                                                //this.props.actions.setInputValue('customcostcentrechange_id', value)
                                                                //this.props.actions.getCostCentreChangeList(value)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xl={1}>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            hintText={''}
                                                            value={x.validation}
                                                            onChange={(event, value) => {
                                                                this.props.actions.updateComponentField(x.component_field_id, 'validation', event.target.value)
                                                                this.props.actions.adhocGridSetSubCellInputValue('componentmanager', 'component_field', 'validation', row.viewIndex, i, event.target.value)
                                                                //this.props.actions.setInputValue('customcostcentrechange_id', value)
                                                                //this.props.actions.getCostCentreChangeList(value)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xl={2}>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            hintText={''}
                                                            value={x.errorText}
                                                            onChange={(event, value) => {
                                                                this.props.actions.updateComponentField(x.component_field_id, 'errorText', event.target.value)
                                                                this.props.actions.adhocGridSetSubCellInputValue('componentmanager', 'component_field', 'errorText', row.viewIndex, i, event.target.value)
                                                                //this.props.actions.setInputValue('customcostcentrechange_id', value)
                                                                //this.props.actions.getCostCentreChangeList(value)
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        })
                                    : <div>{'No Items Found...'}</div>
                            }
                        </Container>} />
                </div>
            }}
        />
    }

    getLayout() {
        this.actions = this.props.actions;
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth={true}
                        hintText={'Enter search text'}
                        value={this.props.state.searchText}
                        onChange={(e, value) => {
                            // This will execute immediately
                            this.props.actions.setInputField('searchText', value);
                        }}
                        onDebounce={(value) => {
                            // This will be debounced
                            this.props.actions.adhocGridGetDataWithComponent(
                                'componentmanager',
                                '/components/component/search',
                                '?title=' + value
                            );
                        }}
                        debounceDelay={1000} // Optional: customize delay in milliseconds
                    />
                </Col>
                <Col xl={12}>{this.getGrid()}</Col>
            </Row></Container>
    }

    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.manageComponent
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...gridActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageComponent)