import * as types from './types'
import * as helper from '../../helpers/components'
import get from 'lodash'
import moment from 'moment'
import * as componentActions from '../Components/actions'
import * as appTypes from '../App/types'
import * as xlsx  from 'xlsx'

export const loadComponent = (name) => {
    return (dispatch) => {
        dispatch(componentActions.getComponent(name))
    }
}

export const setGridSelectedRow = (component, rows) => {
    return (dispatch) => {
        dispatch({
            type: types.SELECT_ROW,
            payload: {
                rows: [{...rows, original: rows.row}] ,
                info: component.info,
                component: component,
            }
        })
    }
}

export const showPopupDialog = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_CONFIRM_DIALOG,
            payload: {
                status: status
            }
        })
    }
}

export const okDialog = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'OK Clicked.',
                overflowText: "",
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const resizeColumn = (component, columns) => {
    return (dispatch, getState) => {
        columns.forEach(x => {
            dispatch({
                type: types.RESIZE_GRID_COLUMN,
                payload: {
                    component: component,
                    field: x.id,
                    width: x.value
                }
            })
        })
    }
}

export const selectRow = (component, row) => {
    return (dispatch, getState) => {
        const handle = getState().components[component]

        const info = handle.bubble !== null
            ? handle.bubble.split(',').reduce((arr, x) => {
                const parts = x.split(':')
                let value = undefined
                
                if (parts[1] ? parts[1].indexOf('.') > -1 : false) {
                    const fields = parts[1].split('.')
                    const arr = row.original[fields[0]]

                    if (arr) {
                        value = Array.isArray(arr)
                            ? arr[0] ? arr[0][fields[1]] : 'No Relation Specified'
                            : get(row.original, parts[1], undefined) ? get(row.original, parts[1], undefined) : undefined
                        value = !isNaN(value) && value
                            ? value.indexOf('.') > 1 || value.indexOf(',')
                                ? parseFloat(value).toFixed(2)
                                : value
                            : moment(value).isValid()
                                ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                                : value
                    }
                    else {
                        value = 'No Relation Specified'
                    }
                }
                else {
                    value = row.original[parts[1]]
                        ? moment(row.original[parts[1]]).isValid()
                            ? row.original[parts[1]] + ' ' + moment(row.original[parts[1]]).format('LLLL') + ', ' + moment(row.original[parts[1]]).fromNow()
                            : row.original[parts[1]]
                        : undefined
                }

                if (value) {
                    arr.push({
                        title: parts[0],
                        field: parts[1],
                        value: value
                    })
                }

                return arr
            }, [])
            : []

        dispatch({
            type: types.SELECT_ROW,
            payload: {
                component: handle,
                rows: [row],
                info: {
                    title: helper.getComponentTitle(handle, row.original),
                    description: helper.getComponentDescription(handle, row.original),
                    bubble: info
                }
            }
        })
    }
}

export const selectMultipleRows = (component, rows) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SELECT_MULTIPLE_ROWS,
            payload: {
                component: component,
                rows: rows
            }
        })
    }
}

export const setComponentGridPage = (component, page) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_GRID_PAGE,
            payload: component,
            page: page
        })
    }
}


export const updComponentFieldInput = (component,args, index, field,value) => {
    // //console.log('component',component) 
    // //console.log('args',args) 
    // //console.log('index',index) 
    // //console.log('field',field) 
    // //console.log('value',value) 
    return (dispatch, getState) => {
        dispatch({
            type: types.UPD_COMPONENT_GRID_ROW_FIELD_VALUE,
            payload: args,
        })
    }
}

export const confirmDelete = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_DELETE_ROW_FIELD_VALUE,
            payload: value
        })
    }
}

export const deleteInprogress = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DELETE_INPROGRESS,
            payload: value
        })
    }
}

export const exportInProgress = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_EXPORT_INPROGRESS,
            payload:  value
        })
    }
}

export const filterInProgress = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FILTER_INPROGRESS,
            payload: value
        })
    }
}

export const deleteRecord = (component,value) => {
    return (dispatch, getState) => {
        dispatch(deleteInprogress(true))
        dispatch({
            type: types.DEL_COMPONENT_GRID_ROW,
            payload: {
                component: component,
                data: value
            }
        })
        //dispatch(loadComponent(component))
    }
}

export const deleteRowDone = (response, payload) => {
    //console.log('deleteRowDone',response)
    //console.log('deleteRowDone payload',payload)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Selected record deleted.',
                overflowText: "",
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(deleteInprogress(false))
        dispatch(confirmDelete(null))
        
        dispatch(componentActions.hardReloadComponent(payload.component))
    }
}

export const setDowntimeVehicle = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_GRID_ROW_FIELD_VALUE,
            payload: payload,
        })
    }
}

export const getComponentGridData = (component, link, paging, filter, id, pageSize) => {
    console.log('setComponentGridDatax getComponentGridData')
    if (!paging) {
        paging = {
            page: 0,
            pageCount: 1,
            pageSize: pageSize ? pageSize : 10,
            rowCount: 0,
            sorted: [],
            filtered: []
        }
    }
    
    console.log('setComponentGridDatax paging', paging)
    return (dispatch, getState) => {
        
        component = component.name ? component : getState().components[component]

        const getRelated = () => {
            if (link) {
                const parent = getState().components[link.parent]
                const through = getState().components[link.through]

                const query = '?' + parent.component_field.filter(x => x.key)[0].name + '=' + link.id

                return {
                    component: through,
                    child: component,
                    query: query
                }
            }

            return undefined
        }

        const getQuery = (filters) => {
            //console.log('eventx filters',filters)
            let query = []
            try{
                filters.map((x) => {
                    query.push(x.field.concat('=', x.value))
                })
            }
            catch(err) {}

            return '?' + query.join('&')
        }

        const withRelatedId = (query) => {

            if (!id || !filter) {
                return query
            }

            const key = getState().components[filter].component_field.filter(x => x.key)[0]

            if (!key) {
                return query
            }

            return query.indexOf('?' > -1) && query.length > 1
                ? query + '&' + key.name + '=' + id
                : '?' + key.name + '=' + id
        }

        //let query = paging.filtered ? withRelatedId(getQuery()) : ''
        const related = getRelated(filter)
        let query = filter ? getQuery(filter) : ''

        console.log('setComponentGridDataxx bool',(typeof filter))
        //query = filter ? '?' + filter.field +'='+ filter.value : ''
        console.log('setComponentGridDataxx related', related)
        console.log('setComponentGridDataxx filter', filter)
        console.log('setComponentGridDataxx query', query)
        console.log('setComponentGridDataxx id', id)
        query = id ? withRelatedId(query) : query
        
        console.log('setComponentGridDataxx withRelatedId', query)
        // console.table('setComponentGridDatax dispatch', JSON.stringify({
        //     type: types.GET_COMPONENT_GRID_DATA,
        //     payload: {
        //         id: id,
        //         component: !related ? component : related.component,
        //         input: '',
        //         paging: paging,
        //         sorting: paging.sorted.length > 0
        //             ? !paging.sorted[0].desc
        //                 ? paging.sorted[0].id
        //                 : '-' + paging.sorted[0].id
        //             : '',
        //         query: !related ? query : related.query,
        //         related: component.related !== null ? component.related ? component.related : '' : '',
        //         child: !related ? {} : related.child
        //     }
        // }))
        dispatch({
            type: types.GET_COMPONENT_GRID_DATA,
            payload: {
                id: id,
                component: !related ? component : related.component,
                input: '',
                paging: paging,
                sorting: paging.sorted.length > 0
                    ? !paging.sorted[0].desc
                        ? paging.sorted[0].id
                        : '-' + paging.sorted[0].id
                    : '',
                query: !related ? query : related.query,
                related: component.related !== null ? component.related ? component.related : '' : '',
                child: !related ? {} : related.child
            }
        })
    }
}

export const getClientGridData = (component, link, paging, filter, id) => {
    console.log('setComponentGridDatax set', )
    console.log('setComponentGridDatax set component', component)
    console.log('setComponentGridDatax set filter', filter)
    if (!paging) {
        paging = {
            page: 0,
            pageCount: 1,
            pageSize: 10,
            rowCount: 0,
            sorted: [],
            filtered: []
        }
    }
    
    return (dispatch, getState) => {
        
        component = component.name ? component : getState().components[component]

        const getRelated = () => {
            if (link) {
                const parent = getState().components[link.parent]
                const through = getState().components[link.through]

                const query = '?' + parent.component_field.filter(x => x.key)[0].name + '=' + link.id

                return {
                    component: through,
                    child: component,
                    query: query
                }
            }

            return undefined
        }

        const getQuery = () => {
            let query = paging.filtered.reduce((query, x) => {
                query.push(x.id.concat('=', x.value))
                return query
            }, [])

            return '?' + query.join('&')
        }

        const withRelatedId = (query) => {

            if (!id || !filter) {
                return query
            }

            const key = getState().components[filter].component_field.filter(x => x.key)[0]

            if (!key) {
                return query
            }

            return query.indexOf('?' > -1) && query.length > 1
                ? query + '&' + key.name + '=' + id
                : '?' + key.name + '=' + id
        }

        let query = withRelatedId(getQuery())
        const related = getRelated()


        console.log('setComponentGridDatax set query', query)
        console.log('setComponentGridDatax set related', related)

        dispatch({
            type: types.GET_CLIENT_COMPONENT_GRID_DATA,
            payload: {
                id: id,
                component: !related ? component : related.component,
                input: '',
                paging: paging,
                sorting: paging.sorted.length > 0
                    ? !paging.sorted[0].desc
                        ? paging.sorted[0].id
                        : '-' + paging.sorted[0].id
                    : '',
                query: !related ? query : related.query,
                related: component.related !== null ? component.related ? component.related : '' : '',
                child: !related ? {} : related.child
            }
        })
    }
}

export const setComponentGridData = (payload) => {
    console.log('setComponentGridData payload',payload)
    return (dispatch, getState) => {
        // let headers = component.component_field.filter(x => x.grid).map(x => {
                
        //     return x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer'
        //     ?  {
        //         field: x.name, 
        //         headerName: x.title, 
        //         flex: 1,
        //         editable: true,
        //         type: 'numeric',
        //         align: "center",
        //         renderCell: (params) => {
        //             let _value = x.display ? params.row[x.display.split('.')[0]][x.display.split('.')[1]] : params.row[x.name]
        //             switch (x.component_field_data_type.name) {
        //                 case 'timestamp':
        //                     _value = moment(_value).format('YYYY-DD-MM hh:mm')
        //                     break;
        //             }
        //           return <div className="rowitem">{_value}</div>;
        //         },
        //     }
        //     : {
        //         field: x.name, 
        //         headerName: x.title, 
        //         flex: 1,
        //         editable: true,
        //         renderCell: (params) => {
        //             let _value = x.display ? params.row[x.display.split('.')[0]] ? params.row[x.display.split('.')[0]][x.display.split('.')[1]] : '' : params.row[x.name]
        //             switch (x.component_field_data_type.name) {
        //                 case 'timestamp':
        //                     _value = moment(_value).format('YYYY-DD-MM hh:mm')
        //                     break;
        //                     case 'date':
        //                         _value = moment(_value).format('YYYY-DD-MM hh:mm')
        //                         break;
        //                         case 'boolean':
        //                             _value = _value ? 'Yes' : 'No'
        //                             break;
        //             }
        //           return <div className="rowitem">{_value}</div>;
        //         },
        //     }
        // })

        dispatch({
            type: types.SET_COMPONENT_GRID_DATA,
            payload: {
                component: payload.child.name ? payload.child : payload.component,
                data: payload.child.name
                    ? {
                        ...payload.data,
                        data: payload.data.data.map(x => {
                            return x[payload.child.name]
                        })
                    }
                    : payload.data
            }
        })
    }
}


export const getExcelData = (component, link, paging, filter, id) => {
    window.glyco.log('getExcelData 1');
    window.glyco.log(paging);
    if (!paging) {
        paging = {
            page: 1,
            pageCount: 0,
            pageSize: 10,
            rowCount: 0,
            sorted: [],
            filtered: []
        }
    }
    window.glyco.log('getExcelData 2');
    window.glyco.log(paging);
    return (dispatch, getState) => {

        const getRelated = () => {
            if (link) {
                const parent = getState().components[link.parent]
                const through = getState().components[link.through]

                const query = '?' + parent.component_field.filter(x => x.key)[0].name + '=' + link.id

                return {
                    component: through,
                    child: component,
                    query: query
                }
            }

            return undefined
        }

        const getQuery = () => {
            if (!paging) {
                window.glyco.log('No paging found');
                return '';
            }
            let query = paging.filtered.reduce((query, x) => {
                query.push(x.id.concat('=', x.value))
                return query
            }, [])

            return '?' + query.join('&')
        }

        const withRelatedId = (query) => {

            if (!id || !filter) {
                return query
            }

            const key = getState().components[filter].component_field.filter(x => x.key)[0]

            if (!key) {
                return query
            }

            return query.indexOf('?' > -1) && query.length > 1
                ? query + '&' + key.name + '=' + id
                : '?' + key.name + '=' + id
        }

        let query = withRelatedId(getQuery())
        const related = getRelated()



        dispatch({
            type: types.GET_COMPONENT_EXCEL_DATA,
            payload: {
                id: id,
                component: !related ? component : related.component,
                input: '',
                paging: paging,
                sorting: paging.sorted.length > 0
                    ? !paging.sorted[0].desc
                        ? paging.sorted[0].id
                        : '-' + paging.sorted[0].id
                    : '',
                query: !related ? query : related.query,
                related: component.related !== null ? component.related : '',
                child: !related ? {} : related.child
            }
        })
    }
}

export const excelExport = (result) => {
    return (dispatch, getState) => {
        const component = result.component
        const data = result.data

        if (!component || !data) {
            return
        }

        const header = component.component_field
            .filter(x => !x.key)
            .filter(x => !x.related)
            .map(x => {
                return {
                    key: x.name,
                    name: x.title,
                    default: ' ',
                }
            })

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: component.name,
                data: data,
                header: header
            }
        })
    }
}

export const viewDocument = (data) => {

    window.glyco.log('viewDocument')
    window.glyco.log(data)


    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf], data.data.key)

    return (dispatch, getState) => {
        dispatch({
            type: types.VIEW_DOCUMENT,
            payload: ''
        })
    }
}



export const getGridExportDataExcel = (component) => {
    return (dispatch, getState) => {
        console.log('setGridExportDataExcelx component',component)
        dispatch({
            type: types.GET_COMPONENT_GRID_EXPORT_EXCEL_DATA,
            payload: component,
        })
    }
}

export const setGridExportDataExcel = (response, payload) => {
    return (dispatch, getState) => {
        console.log('setGridExportDataExcelx response',response)
        console.log('setGridExportDataExcelx payload',payload)
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Excel report downloading.',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        let component = getState().components[payload]
        let row_accessor = component.component_field.filter(x => x.grid).map(x => x.name)
        let row_headers = component.component_field.filter(x => x.grid).map(x => x.title)
        let excel_headers = [row_headers]
        
        console.log('setGridExportDataExcelx headers',row_accessor)
        console.log('setGridExportDataExcelx rows',excel_headers)
        let data = response.data
        for (let i = 0; i < data.length; i++) {
            let datarow = data[i];
            let rowData = [];
            for (let j of row_accessor) {
                if(j.type === 'date') {
                    rowData.push(moment(datarow[j]).format('YYYY-MM-DD'));
                }
                else
                    rowData.push(datarow[j]);
            }
            excel_headers.push(rowData);
        }

        const workbook = xlsx.utils.book_new();
        const worksheet = xlsx.utils.aoa_to_sheet(excel_headers);
        xlsx.utils.book_append_sheet(workbook, worksheet, payload + " data");

        const excelBuffer = xlsx.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        let filename = payload + '_' + moment().format('YYMMDDHHmm')+'.xlsx'
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
}