import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'


import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'

import * as inputActions from '../WorkflowTaskInput/actions'
import * as actions from './actions'
import * as styles from './styles'

import TextField from '@mui/material/TextField'
import Icon from '../Icon/component'
import Loading from '../Loading/component'
import Iframe from 'react-iframe'
import Divider from '@mui/material/Divider'
import 'react-quill/dist/quill.snow.css'

class ImageUploader extends Component {
    componentDidMount() {
        this.props.actions.resetAllDocuments()
    }

    getUploader() {
        return <Container style={{ margin: 0 }} className="custom-container">     <Row>
            <Col xl={12}>
                < Button className={"global_button"}
                    primary={true}
                    label={"Add Images"}
                    icon={<Icon iclass={'material-icons'} iname={'file_upload'} />}
                    onClick={(event) => {
                        this.props.actions.addBlankUpload()
                    }}
                    ><Icon iclass={'material-icons'} iname={'file_upload'} /> Add Images</Button>
            </Col>
        </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                {
                    this.props.state.images.map((x, i) => {
                        return x
                            ? <Col xl={2}>
                                {
                                    x.preview
                                        ? <Iframe url={x.preview}
                                            width="200px"
                                            height="100px"
                                            id={'receipt_' + x.filename}
                                            className="myClassname"
                                            display="initial"
                                            position="relative"
                                            allowFullScreen />
                                        : <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            hintText={'Upload'}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(files, x.fileinx, this.props.prefix)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.resetDocuments(x.fileinx)
                                            }}
                                        >
                                            <p style={styles.watermark}>
                                                Browse Image.
                                        </p>
                                        </Dropzone>
                                }
                                <br></br>
                               < Button className={"global_button"} variant="contained"
                                    primary={true}
                                    label="Remove"
                                    icon={<Icon iclass={'material-icons'} iname={'delete_forever'} />}
                                    onClick={(event) => {
                                        this.props.actions.resetDocuments(x.fileinx)
                                    }}
                                    ><Icon iclass={'material-icons'} iname={'delete_forever'} /> Remove</Button>
                            </Col>
                            : ''
                    })
                }
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
        </Container>
    }

    render() {
        return (
            this.getUploader()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.imageUploader,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploader)