import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Iframe from 'react-iframe'

import { Container, Row, Col } from 'react-grid-system'
import { Grid, Card, Button, CardHeader, CardContent, AppBar, Tabs, Tab, Box } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from 'react-responsive-modal';

import * as actions from './actions'
import * as styles from './styles'

import Icon from '../Icon/component'
import Loading from '../Loading/component'
import QuoteEdit from '../QuoteEdit/component'
import PdfViewer from '../PDF/component'

class PdfComponent extends Component {
    componentDidMount() {
        this.props.actions.crearDocuments()
            //console.log('this.props.state.user.defaultdocumentview >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.props.documents)
        
        if (this.props.multiple) {
            this.props.actions.setMultiDocuments(this.props.documents, this.props.prop)
            this.props.actions.getMultiDocuments(this.props.prop)
        }
        else {
            const doc = this.props.documents.length ? this.props.documents[0] : this.props.documents

            //console.log('this.props.state.user.defaultdocumentview >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.props.state.user.defaultdocumentview)
            //console.log(this.props.documents[0])
            switch(this.props.state.user.defaultdocumentview){
                case 'tabs':
                    this.props.actions.setMultiDocuments(this.props.documents, this.props.prop)
                    this.props.actions.getMultiDocuments(this.props.prop)
                    break
                case 'buttons':
                    break
                case 'preview':
                    this.props.actions.getQuotationPDF({ key: doc.key ? doc.key : ('quotes/' + doc.filename)})
                    break
                case 'openall':
                    this.props.actions.setMultiDocuments(this.props.documents, this.props.prop)
                    this.props.actions.getMultiDocuments(this.props.prop)
                    break
                case 'downloadall':
                    this.props.actions.setMultiDocuments(this.props.documents, this.props.prop)
                    this.props.actions.getMultiDocuments(this.props.prop)
                    break
                default:
                    this.props.actions.getQuotationPDF({ key: doc.key ? doc.key : ('quotes/' + doc.filename)})
                    break
            }
        }
    }

    getDocuments() {
        return this.props.state.document.data && this.props.state.document.data.key && !this.props.state.document.data.key.text
            ? <Loading message={'Retrieving your document...'} />
            : this.props.state.document.data && this.props.state.document.data.key.filename.toLowerCase().indexOf('.pdf') > 0
                ?  <Iframe url={this.props.state.document.data.key.text}
                    width="100%"
                    height={this.props.height ? this.props.height : '1000px'}
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                    allowFullScreen
                />
                : < Button className={"global_button"}
                secondary={true}
                label={"Add Document"}
                icon={<Icon iclass={'material-icons'} iname={'file_download'} />}
                onClick={(event) => {
                    this.props.actions.getQuotationPDF({ key: 'quotes/' + this.props.state.document.data.key.text })
                }}
            ><Icon iclass={'material-icons'} iname={'file_download'} /> {this.props.state.document.data && this.props.state.document.data.key ? this.props.state.document.data.key.text : ''}</Button>
    }

    getInxDocument(inx) {
        return this.props.state.documents.length
            ? <Iframe url={this.props.state.documents[inx].preview}
                width="100%"
                height={this.props.height ? this.props.height : '1000px'}
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
                allowFullScreen
            />
            : <Loading message={'Retrieving your document...'} />
    }

    multiDocuments() {
        let documents = this.props.prop ? this.props.state[this.props.prop] : this.props.state.documents
        //console.log('multiDocuments prop', this.props.prop)
        //console.log('multiDocuments documents', documents)
        return documents && documents.length
            ? documents.map((x, i) => {
                return x.filename
                    ? <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6} style={styles.fieldTitle}>{'Documents Name:'}</Col>
                            <Col xl={6} style={styles.leftLabelTitle}>{x.filetype}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {
                                    x.preview
                                        ? x.filename.toLowerCase().indexOf('.pdf') > 0
                                        ?  <Iframe url={x.preview}
                                            width="100%"
                                            height={this.props.height ? this.props.height : '1000px'}
                                            id="myId"
                                            label={x.filetype}
                                            title={x.filetype}
                                            className="myClassname"
                                            display="initial"
                                            position="relative"
                                            allowFullScreen
                                        />
                                        : < Button className={"global_button"}
                                        secondary={true}
                                        label={"Add Document"}
                                        icon={<Icon iclass={'material-icons'} iname={'file_download'} />}
                                        onClick={(event) => {
                                            this.props.actions.getQuotationPDF({ key: 'quotes/' + x.filename })
                                        }}
                                    ><Icon iclass={'material-icons'} iname={'file_download'} /> {x.filename}</Button>
                                        : <Loading message={'Retrieving your document...'} />
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col><br /></Col>
                        </Row>
                    </Container>
                    : null
            })
            : ''
    }

    getPreview() {
        //console.log('getPreview',this.props.documents)
        return <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {this.getDocuments()}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {
                                this.props.documents.length === 0
                                    ? ''
                                    : this.props.documents.map(x => {
                                        if (!x) return ''
                                        let label = x.documenttype === 'Worksheet' || x.documenttype === 'Quotation'
                                            ? x.documenttype + (x.quotenumber ? ' (' + x.quotenumber + ')' : '')
                                            : x.documenttype ? x.documenttype : x.filetype ? x.filetype : x.description

                                        return x ? < Button className={"global_button"}
                                            key={x.document_id ? x.document_id : 'document_id'}
                                            id={x.document_id ? x.document_id : 'document_id'}
                                            secondary={true}
                                            label={label}
                                            icon={<Icon iclass={'material-icons'} iname={'visibility'} />}
                                            onClick={(event) => {
                                                this.props.actions.getQuotationPDF({ key: x.relative_path ? x.filename : (x.key ? x.key : ('quotes/' + x.filename)) })
                                            }}
                                            ><Icon iclass={'material-icons'} iname={'visibility'} /> {label}</Button> : ''
                                    })
                            }
                            {
                                this.props.edit
                                    ? < Button className={"global_button"}
                                        secondary={true}
                                        label={"Edit Quotation"}
                                        icon={<Icon iclass={'material-icons'} iname={'create'} />}
                                        onClick={(event) => {
                                            this.props.actions.openEdit()
                                        }}
                                        ><Icon iclass={'material-icons'} iname={'create'} />Edit Quotation</Button>
                                    : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        }
        
    getButtons() {
        return <Grid container spacing={6}>
            <Grid item xl={7}>
              <div className="card-box-off">
                <div className="grid-menu grid-menu-3col">
                  <Grid container spacing={0}>
                    {
                        this.props.documents.map(x => {
                            return x ? <div>&nbsp;<Button className={"global_button"}
                                key={x.document_id ? x.document_id : 'document_id'}
                                id={x.document_id ? x.document_id : 'document_id'}
                                secondary={true}
                                label={x.filetype}
                                icon={<Icon iclass={'material-icons'} iname={'visibility'} />}
                                onClick={(event) => {
                                    this.props.actions.getQuotationPDF({ key: x.relative_path ? x.filename : (x.key ? x.key : ('quotes/' + x.filename)) })
                                }}
                                ><Icon iclass={'material-icons'} iname={'visibility'} /> {x.filetype}</Button>&nbsp;</div> : ''
                            })
                        }
                   </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
    }

    
    getTabs() {
        return <div>
                <Tabs 
                    //scrollButtons="on"
                    aria-label="scrollable force tabs example"
                    value={this.props.value}
                    //style={{width: '100% !important'}}
                    //variant="scrollable"
                    variant={"scrollable"}
                    scrollButtons={"on"}
                    onChange={
                        (event, value) => {
                            this.props.actions.onDocumentTabPreview(value)
                            //console.log(value)
                            let x = this.props.documents[value]

                            if(x.filename.toLowerCase().indexOf('.pdf') === -1) {
                                this.props.actions.getQuotationPDF({ key: 'quotes/' + x.filename })
                            }
                        }
                    }>
                    {
                        this.props.documents.map(x => {
                            return x ? <Tab  style={{minWidth: (100/this.props.documents.length) + '%'}}  label={(x.filetype ? x.filetype : x.documenttype)} id={x.filename} /> : <div></div>
                        })
                    }
                </Tabs>
            <div>
                <Box>{this.getInxDocument(this.props.state.tab)}</Box>
            </div>
      </div> 
    }

    getView() {
        ///return this.getTabs()
        switch(this.props.state.user.defaultdocumentview){
            case 'tabs deprecated':
                return this.getTabs()
            case 'buttons':
                return this.getButtons()
            case 'openall':
                return this.getButtons()
            case 'downloadall':
                return this.getButtons()
            case 'preview':
                return this.getPreview()
            default:
                return this.getPreview()
        }
    }

    getWithPreview() {
        ///return this.getTabs()
        switch(this.props.preview){
            case 'tabs deprecated':
                return this.getTabs()
            case 'buttons':
                return this.getButtons()
            case 'openall':
                return this.getButtons()
            case 'downloadall':
                return this.getButtons()
            case 'preview':
                return this.getPreview()
            default:
                return this.getPreview()
        }
    }

    getLayout() {
        return this.props.preview ? this.getWithPreview() : this.getView()
    }

    render() {
        return (
            this.props.documents.length
            ? this.props.multiple 
                ? this.multiDocuments()
                : this.getLayout()
            : ''    
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.pdf,
            task: {
                ...state.workflowTaskInput
            },
            document: {
                ...state.components['document']
            },
            user: {
                ...state.user
            },
            surrogateheader: {
                ...state.components['surrogateheader']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfComponent)