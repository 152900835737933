import * as actions from "./hooks";
import * as requestActions from "../Request/actions";
import * as routerActions from "../Redirect/actions";
import * as types from "./types";
import * as config from "../../config";


export const signupAmplify = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.SIGNUP_AMPLIFY),
        delay(0),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/cognito/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
                body: payload
            })
            .pipe(
                mergeMap((result) => [
                    actions.signupAmplifyDone(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/cognito/other')
                ]),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/cognito/other', error)))
            )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
