import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'
import * as _ from 'lodash'
import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../../Icon/component'

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox'
import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'
import PdfViewer from '../../PDF/component'
import Loading from '../../Loading/component'
import * as styles from './styles'
import * as actions from './actions'

class DefleetVehicleInfo extends Component {

    VehicleDetails(data) {
        //console.log('VehicleDetails >>>>>>>>>>>>>> ', data)
        //console.log('VehicleDetails vehiclestatus >>>>>>>>>>>>>> ', data.vehiclestatus)
        return <div>
            <Card initiallyExpanded={true}>
            <CardHeader
                actAsExpander={true}
                showExpandableButton={true}
                    title={window.t.en('Vehicle Information: ' + data.businessname)}
                    subtitle={window.t.en('Selected vehicle details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent expandable={true}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.licenseplateno}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Fleet Number:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.fleetno}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Status:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.vehiclestatus}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Odometer:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.closingodometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.make}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mgdescription}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.mmcode.mmcode}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle First Registration Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.registrationdate ? moment(data.registrationdate).format('DD-MM-YYYY') : data.registrationdate}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Chassis Number:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.chassisnumber}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Engine Number:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.enginenumber}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Fuel Type:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.fueltype}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Colour:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.color}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Funder:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.fundername? data.fundername: 'Not Set'}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Vehicle Supplier (OEM):'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.suppliername ? data.suppliername: 'Not Set'}</Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    VehicleCustomer(data) {
        return <div> {
            data ?
                <Card initiallyExpanded={false}>
                <CardHeader
                    actAsExpander={true}
                    showExpandableButton={true}
                        title={window.t.en('Vehicle Customer Information: ')}
                        subtitle={window.t.en('Selected vehicle customer details.')}
                        icon={<Icon iclass={'custom-icons'} iname={'debtor'} />}
                        style={styles.row}
                    >
                    </CardHeader>
                    <CardContent expandable={true}>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Customer Full Name:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.parentaccount}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Registered Name:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.registeredname}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Cost Centre:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.costcentre}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Account Number:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.accountnumber}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Business Unit:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.businessunit}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Customer Status:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.customeractive ? 'Is Active' : 'No Active'}</Col>
                            </Row>
                            <Row>
                                <Col xl={12} style={styles.fieldLabel}><br /></Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <AdhocComponentFieldInput
                                        title={window.t.en('')}
                                        description={''}
                                        icon={''}
                                        id={'customercontact'}
                                        parent={'customercontact'}
                                        parentFilters={[
                                            {
                                                field: 'customer_id',
                                                value: data.customer_id
                                            }
                                        ]}
                                        child={'contact'}
                                        childFilters={[
                                            {
                                                field: 'contacttype',
                                                value: ''
                                            }
                                        ]}
                                        extractChildData={true}
                                        adhocFieldInput={[ //assumes Parent component fields if extractChild is set to false
                                            'fullname', 'email', 'mobile', 'contacttype'
                                        ]}
                                        selectMode={false}
                                        addMode={false}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </CardContent>
                </Card>
                : ''}
        </div>
    }

    VehicleContract(data) {
        return data.length  ? <div>
            <Card initiallyExpanded={false}>
            <CardHeader
                actAsExpander={true}
                showExpandableButton={true}
                    title={window.t.en('Vehicle Contract Information: ')}
                    subtitle={window.t.en('Selected vehicle contract details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'contract_vehicle_category'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent  expandable={true}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Number:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data[0].contractnumber}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Status:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data[0].contractstatus}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Type:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data[0].contracttype}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Category:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{''}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Distance per Month:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data[0].distancepermonth).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Distance:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data[0].distance).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Projected Replacement Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data[0].terminationdate ? moment(data[0].terminationdate).format('DD/MM/YYYY') : 'Not Set'}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Projected Odometer:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data[0].terminationodometer ? data[0].terminationodometer : 0).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                        </Row>

                        {/* <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Next Service Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data.nextservicedate).format('DD/MM/YYYY')}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Next Service Odometer:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.nextserviceodometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Last Service Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data.lastservicedate).format('DD/MM/YYYY')}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Last Service Odometer:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.lastserviceodometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                        </Row> */}
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Opening Odometer Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data[0].openingodometerdate).format('DD/MM/YYYY')}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Opening Odometer:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data[0].openingodometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Replacement Date:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{moment(data[0].replacementdate).format('DD/MM/YYYY')}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Replacement Distance:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data[0].replacement_distance).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms'}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Warranty Status:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{'Not Set'}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Service Plan Status:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{'Not Set'}</Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div> : ''
    }
    TyresCount(data) {

        let derivative = null
        let contract = data.customercontract.filter(x => x.activated == true && x.terminated == false)

        //console.log("TyresCount(data) {", contract)
        try {
            derivative = contract[0].customercontractderivative[0]
        }
        catch (err) { }

        return <Card initiallyExpanded={false}>
            <CardHeader
                actAsExpander={true}
                showExpandableButton={true}
                title={window.t.en('Tyres')}
                subtitle={window.t.en('Vehicle tyres usage break down.')}
                icon={<Icon iclass={'custom-icons'} iname={'contract_vehicle_category'} />}
                style={styles.row}
            >
            </CardHeader>
            <CardContent expandable={true}>
                {
                    derivative
                        ? <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Tyre Quantity Front:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{derivative.tyrequantityfront ? derivative.tyrequantityfront : 'Not Set'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Tyre Quantity Rear:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{derivative.tyrequantityrear ? derivative.tyrequantityrear : 'Not Set'}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Tyres Used Front:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{derivative.tyresusedfront ? derivative.tyresusedfront : 'Not Set'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Tyres Used Rear:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{derivative.tyresusedrear ? derivative.tyresusedrear : 'Not Set'}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Tyres Used:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{(parseInt(derivative.tyresusedfront) + parseInt(derivative.tyresusedrear))}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Tyres Balance:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{((parseInt(derivative.tyrequantityrear) + parseInt(derivative.tyrequantityfront)) - (parseInt(derivative.tyresusedfront) + parseInt(derivative.tyresusedrear)))}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Amount Used:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{derivative.tyresexpenditure ? derivative.tyresexpenditure : 'Not Set'}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Fund Balance:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.tyresfundbalance ? data.tyresfundbalance : 'Not Set'}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Tyres Income:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.tyresincome}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{'Tyres Monthly Selling:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.tyresmonthlyselling}</Col>
                            </Row>
                            <Row>
                                <Col xl={3} style={styles.fieldLabel}>{'Tyres Selling:'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{data.tyresselling}</Col>
                                <Col xl={3} style={styles.fieldLabel}>{''}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{''}</Col>
                            </Row>
                        </Container>
                        : <h4>{'No Records Found.'}</h4>
                }
            </CardContent>
        </Card>
    }
    serviceHistory(vehicle) {

        let data = vehicle.maintenancetransaction.filter(x => x.deleted_at === null)
        const vehicle_id = vehicle.vehicle_id
        const makemodelyear = vehicle.makemodelyear
        const licenseplateno = vehicle.licenseplateno
        const imagefilename = vehicle.mmcode.imagefilename
        let customercontractderivative = []
        try {
            customercontractderivative = vehicle.customercontract[0].customercontractderivative
        }
        catch (err) {

        }
        let servicehistory = this.props.state.servicehistory  //,this.props.state.tyrehistory
        data = servicehistory ? data.filter(x => x.maintenancetype_fktext === 'Service' || x.maintenancetype === 'Repairs') : data 


        let list = data.filter(x => x.reference)

        let result = _.sortBy(list, [function (o) { return o.odometer; }])
        result = result.reduce(function (r, a) {
            r[a.odometer] = r[a.odometer] || [];
            r[a.odometer].push(a);
            return r;
        }, Object.create(null));

        list = Object.values(result)
        //list = list.filter(x => x.reference)
        data = data.filter(x => x.authnumber)
        data = _.sortBy(data, [function (o) { return o.datestamp; }])

        return <div>
            <Card initiallyExpanded={false}>
                <CardHeader
                    actAsExpander={true}
                    showExpandableButton={true}
                    title={window.t.en('Vehicle Maintencance History: ' + licenseplateno)}
                    subtitle={window.t.en('Selected vehicle service history details.')}
                    icon={<Icon iclass={'custom-icons'} iname={'history'} />}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent expandable={true}>

                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={8} style={styles.rowHeader}>
                            </Col>
                            <Col xl={2} style={styles.rowHeader}>
                                <Checkbox
                                    id={'servicehistory'}
                                    label="Service Only"
                                    value={this.props.state.servicehistory}
                                    onCheck={
                                        (event, checked) => {
                                            this.props.actions.chkHistoryType('servicehistory', checked)
                                        }
                                    }
                                />
                            </Col>
                            <Col xl={2} style={styles.rowHeader}>
                                < Button className={"global_button"}
                                    secondary={true}
                                    label="Download PDF"
                                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                                    onClick={(event) => {
                                        this.props.actions.downloadMaintanancePDF(data, list, vehicle_id, makemodelyear, licenseplateno, imagefilename, customercontractderivative)
                                    }}
                                    ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Download PDF</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.rowHeader}>
                                <br />
                            </Col>
                        </Row>
                        {
                            list.map(_list => {
                                return <div>
                                    <Row>
                                        <Col xl={12} style={styles.rowHeader}>
                                            <br />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl={12}>
                                            <h4 style={styles.hr}>{_list[0].reference + ': ' + _list[0].maintenancetype + ' @ ' + parseFloat(_list[0].odometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms' + ' On ' + moment(_list[0].capturedate).format('DD/MM/YYYY') + ' By ' + _list[0].supplier}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={2}>
                                            <strong>{'Item Type'}</strong>
                                        </Col>
                                        <Col xl={2}>
                                            <strong>{'Item Description'}</strong>
                                        </Col>
                                        <Col xl={2}>
                                            <strong>{'Qty'}</strong>
                                        </Col>
                                        <Col xl={2} style={styles.numericDiv}>
                                            <strong style={styles.numericDiv}>{'Invoice Amount'}</strong>
                                        </Col>
                                        <Col xl={2} style={styles.numericDiv}>
                                            <strong style={styles.numericDiv}>{'Approved Amount'}</strong>
                                        </Col>
                                        <Col xl={2} style={styles.numericDiv}>
                                            <strong style={styles.numericDiv}>{'Paid Amount'}</strong>
                                        </Col>
                                    </Row>
                                    {
                                        _list
                                            .map(x => {
                                                return parseInt(x.invoicedamount) && _list[0].deleted_at === null
                                                    ? <Row>
                                                        <Col xl={2}>
                                                            {x.maintenancetype}
                                                        </Col>
                                                        <Col xl={2}>
                                                            {x.maintdescription}
                                                        </Col>
                                                        <Col xl={2}>
                                                            {x.quantity}
                                                        </Col>
                                                        <Col xl={2} style={styles.numericFields}>
                                                            {parseFloat(x.invoicedamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </Col>
                                                        <Col xl={2} style={styles.numericFields}>
                                                            {parseFloat(x.approvedamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </Col>
                                                        <Col xl={2} style={styles.numericFields}>
                                                            {parseFloat(x.paidamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </Col>
                                                    </Row>
                                                    : ''
                                            })
                                    }
                                </div>
                            })
                        }
                    </Container>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            data.map(_list => {
                                return _list.maintenancetransactionitem.length
                                    ? <div>
                                        <Row>
                                            <Col xl={12} style={styles.rowHeader}>
                                                <br />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={12}>
                                                <h4 style={styles.hr}>{'Status: ' + _list.maintenancestatus}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {_list.ps === 2 ? "Paid" : "Pending"}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {"Rebill " + _list.rebill}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {_list.authnumber + ': ' + _list.maintenancetype_fktext + ' @ ' + parseFloat(_list.odometer).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Kms' + ' On ' + moment(_list.datestamp).format('DD/MM/YYYY') + ' By ' + _list.suppliername}</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={2}>
                                                <strong>{'Item Type'}</strong>
                                            </Col>
                                            <Col xl={2}>
                                                <strong>{'Item Description'}</strong>
                                            </Col>
                                            <Col xl={1}>
                                                <strong>{'Qty'}</strong>
                                            </Col>
                                            <Col xl={2} style={styles.numericDiv}>
                                                <strong style={styles.numericDiv}>{'Unit Price'}</strong>
                                            </Col>
                                            <Col xl={2} style={styles.numericDiv}>
                                                <strong style={styles.numericDiv}>{'Discount'}</strong>
                                            </Col>
                                            <Col xl={1} style={styles.numericDiv}>
                                                <strong style={styles.numericDiv}>{'VAT'}</strong>
                                            </Col>
                                            <Col xl={2} style={styles.numericDiv}>
                                                <strong style={styles.numericDiv}>{'Total'}</strong>
                                            </Col>
                                        </Row>
                                        {
                                            _list.maintenancetransactionitem.map(x => {
                                                return <Row>
                                                    <Col xl={2}>
                                                        {x.itemtype}
                                                    </Col>
                                                    <Col xl={2}>
                                                        {x.itemdescription}
                                                    </Col>
                                                    <Col xl={1}>
                                                        {x.quantity}
                                                    </Col>
                                                    <Col xl={2} style={styles.numericFields}>
                                                        {parseFloat(x.amount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </Col>
                                                    <Col xl={2} style={styles.numericFields}>
                                                        {parseFloat(x.discountamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </Col>
                                                    <Col xl={1} style={styles.numericFields}>
                                                        {parseFloat(x.vat).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </Col>
                                                    <Col xl={2} style={styles.numericFields}>
                                                        {parseFloat(x.totalamount).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </Col>
                                                </Row>
                                            })
                                        }
                                        <Row>
                                            <Col xl={10}>
                                            </Col>
                                            <Col xl={2} style={styles.numericFields}>
                                                <strong>{'Total'} {parseFloat(_list.totalamount).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</strong>
                                            </Col>
                                        </Row>
                                    </div>
                                    : ''
                            })
                        }

                    </Container>
                </CardContent>
            </Card>
        </div>
    }
    burnRate(contract) {
        let data = null

        try {
            data = contract[0].customercontractderivative[0]
        }
        catch (err) { }
        return data
            ? <Card initiallyExpanded={false}>
                <CardHeader
                    actAsExpander={true}
                    showExpandableButton={true}
                    title={window.t.en('Burn Rate')}
                    subtitle={window.t.en('Vehicle burn rate')}
                    style={styles.row}
                >
                </CardHeader>
                <CardContent expandable={true}>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12} style={styles.hr}><h3>{'Billing Summary'}</h3></Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Contract Period (Months):'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.period}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Current Billing Count:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.billingcount}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Remaining Billing Count:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.remainingbillingcount}</Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.hr}><h3>{'Maintenance Fund'}</h3></Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Maintenance Income:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.maintenanceincome}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Maintenance Expenditure:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.maintenanceexpenditure}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Maintenance Fund Balance:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.maintenancefundbalance}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Maintenance Monthly Income:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.maintenancemonthlyselling}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}><h3>{''}</h3></Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Maintenance Excess (CPK):'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.maintenanceexcess}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Maintenance and Finance Excess (CPK):'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.excess}</Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.hr}><h3>{'Tyre Fund & Usage'}</h3></Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Tyre Income:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.tyresincome}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Tyre  Expense:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.tyresexpenditure}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Tyre Fund Balance:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.tyresfundbalance}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Tyres Monthly Income:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.tyresmonthlyselling}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}><h3>{''}</h3></Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Tyres Allocated:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{(parseInt(data.tyrequantityfront) + parseInt(data.tyrequantityrear))}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Tyres Used (Not Rebilled):'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.tyresused}</Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Tyres Allocated Left:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.ltdtyresused ? data.ltdtyresused : 0}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Tyre Rebilled:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.rebilledtyresused ? data.rebilledtyresused : 0}</Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.hr}><h3>{'Utilisation Performance (Excess)'}</h3></Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'FD Take On Excess:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.fd_takeon_excess}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}><h3>{''}</h3></Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Excess Debits:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.excess_debit}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Excess Credits:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.excess_credit}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}><h3>{''}</h3></Col>
                        </Row>
                        <Row>
                            <Col xl={3} style={styles.fieldLabel}>{'Total Fund Income - Inclusive of Excess:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.totalfundincome_includingexcess}</Col>
                            <Col xl={3} style={styles.fieldLabel}>{'Total Fund Income - Exclusive of  Excess:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.totalfundincome_excludingexcess}</Col>
                        </Row>
                        <Row>
                            <Col xl={12}><h3>{''}</h3></Col>
                        </Row>
                        <Row>
                            <Col xl={3}><h3>{''}</h3></Col>
                            <Col xl={3}><h3>{''}</h3></Col>
                            <Col xl={3} style={styles.selectedFieldName}>{'TOTAL:'}</Col>
                            <Col xl={3} style={styles.selectedFieldName}>{data.totalfundbalance}</Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
            : ''
    }
    renderView(data) {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Vehicle Details ')}
                    subtitle={window.t.en('')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {this.VehicleDetails(data)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {
                                    data.customercontract.length
                                        ? this.VehicleContract(data.customercontract.filter(x => x.activated == true && x.terminated == false))
                                        : ''
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.VehicleCustomer(data.customer)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.serviceHistory(data)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {
                                    data.customercontract.length
                                        ? this.burnRate(data.customercontract.filter(x => x.activated == true && x.terminated == false))
                                        : ''
                                }
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    render() {
        const data = this.props.vehicleInfo
        return data
            ? this.renderView(data)
            : 'Select vehicle...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetVehicleInfo,
            workflowDefleetView: {
                ...state.workflowDefleetView,
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetVehicleInfo)