import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_PURCHASE_ORDER_INVOICE_ACTION:
            return {
                ...state,
                purchaseorder: action.payload
            }
        case types.SET_PURCHASE_ORDER_INVOICE_SELECTED_VALUE:
            return {
                ...state,
                selectedPO: action.payload
            }
        case types.SET_CONTRA_ACCOUNT_INVOICE_SELECTED_VALUE:
            return {
                ...state,
                selectedCA: action.payload
            }
        case types.SET_INVOICEAP_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_CONTRA_ACCOUNT_INVOICE_ACTION:
            return {
                ...state,
                accountingitem: action.payload
            }
        case types.SHOW_COMMENT_BOX:
        return {
            ...state,
            show_box: action.payload
        } 
            
        default:
            return state
    }
}