import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
import GeneralContentView from '../../views/GeneralContentView/component'

import Loading from '../Loading/component'
import Form from './form'
import Grid from './grid'
import View from './view'

import * as styles from './styles'
import * as actions from './actions'

class BudgetManagement extends Component {

    componentDidMount() {
        this.props.actions.changeTabView(0)
    }

    headerTitle () {
        return (
        <>
            <div className="app-page-title app-page-title--shadow" style={{ marginBottom: '0px',padding: '20px 65px'}}>
            <div>
                <div className="app-page-title--first">
                {(
                    <div className="app-page-title--iconbox d-70">
                    <div className="d-70 d-flex align-items-center justify-content-center display-1" 
                    style={{cursor: 'hand !important'}}
                    onClick={(event) => {
                        this.props.actions.route('/budgetmanagement')
                    }}>
                        <HomeWorkTwoToneIcon className="text-primary" />
                    </div>
                    </div>
                )}
                <div className="app-page-title--heading">
                    <h1>{'Budget Management'}</h1>
                    {(
                    <div className="app-page-title--description">
                        {'Budget Management List'}
                    </div>
                    )}
                </div>
                </div>

            </div>
            <div className="d-flex align-items-center">
            </div>
            </div>
            </>
        )
    }

    headers() {
        return (
            <div
                key={'detail_component_view_content_budget_management'}
                style={styles.content}>
                <div
                    key={'detail_component_view_title_budget_management'}
                    style={styles.title}>
                    <div style={styles.title_header}>
                        {this.headerTitle()}
                    </div>
                    <div style={styles.title_buttons}>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return <div>
                {/* {this.headers()} */}
                <Tabs
                    initialSelectedIndex={0}
                    value={this.props.state.tab}
                    inkBarStyle={{
                        height: 4
                    }}
                    onChange={(event, value) => {
                        for(let x of this.props.state.budgetmanagement.component_field){
                            this.props.actions.setComponentFieldInput(x,null)
                        }
                        this.props.actions.changeTabView(value)
                    }}
                >
                    <Tab style={{minWidth:"50%"}} label={"Data"} />
                    <Tab style={{minWidth:"50%"}} label={"Create"} />
                </Tabs>
                <br/>
                <div>
                    {this.props.state.tab === 0 && <Box><Grid /></Box>}
                    {this.props.state.tab === 1 && <Box><Form /></Box>}
                    {this.props.state.tab === 2 && <Box><View /></Box>}
                </div>

                <Dialog
                    open={this.props.state.delete_record_id ? true : false}
                    style={{ width: '100% !important', maxWidth: '100% !important' }}
                    fullWidth={true}
                    maxWidth="xl"
                    >
                    <div className="text-center p-5">
                    <div className="avatar-icon-wrapper rounded-circle m-0">
                    
                    </div>
                    <h4 className="font-weight-bold mt-4">
                        Delete
                    </h4>  
                    <p className="mb-0 font-size-lg text-muted">
                        Are you you want to delete selected record
                    </p>
                    <div className="pt-4">
                        <Button
                        onClick={(event) => {
                            this.props.actions.toggleDeleteConfirm(0)
                        }}
                        className="btn-pill mx-1">
                        <span className="btn-wrapper--label">Cancel</span>
                        </Button>
                        <Button 
                            onClick={(event) => {
                                this.props.actions.toggleDeleteConfirm(0)
                                this.props.actions.deleteBuget(this.props.state.delete_record_id)
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Delete</span>
                        </Button>
                    </div>
                    </div> 
                </Dialog>
            </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.budgetmanagement,
            budgetmanagement: {
                ...state.components.budgetmanagement
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetManagement)