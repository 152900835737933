export const SET_PROPS_QUOTE_DOCUMENTS_LIST = 'SET_PROPS_QUOTE_DOCUMENTS_LIST'
export const SET_PROPS_QUOTE_DOCUMENTS_PREVIEW = 'SET_PROPS_QUOTE_DOCUMENTS_PREVIEW'
export const GET_ACCESSORY_PO_PREVIEW_DOCUMENTS = 'GET_ACCESSORY_PO_PREVIEW_DOCUMENTS'
export const GET_ACCESSORY_QUOTE_PREVIEW_DOCUMENTS = 'GET_ACCESSORY_QUOTE_PREVIEW_DOCUMENTS'
export const SET_PROPS_ACCESSORY_PO_DOCUMENTS_LIST = 'SET_PROPS_ACCESSORY_PO_DOCUMENTS_LIST'
export const SET_PROPS_ACCESSORY_PO_DOCUMENTS_PREVIEW = 'SET_PROPS_ACCESSORY_PO_DOCUMENTS_PREVIEW'
export const SET_PROPS_SCHEDULE1_DOCUMENTS_LIST = 'SET_PROPS_SCHEDULE1_DOCUMENTS_LIST'
export const GET_ACCESSORY_SCHEDULE1_PREVIEW_DOCUMENTS = 'GET_ACCESSORY_SCHEDULE1_PREVIEW_DOCUMENTS'
export const SET_PROPS_COMPONENT_FIELD_VALUE = 'SET_PROPS_COMPONENT_FIELD_VALUE'
export const GET_PROPS_COMPONENT_LOOKUP_FIELD_VALUE = 'GET_PROPS_COMPONENT_LOOKUP_FIELD_VALUE'
export const SET_PROPS_COMPONENT_LOOKUP_FIELD_VALUE = 'SET_PROPS_COMPONENT_LOOKUP_FIELD_VALUE'
export const SET_ACCESSORY_LIST_PROP = 'SET_ACCESSORY_LIST_PROP'
export const GET_CURRENT_INTEREST_RATE = 'GET_CURRENT_INTEREST_RATE'
export const SET_CURRENT_INTEREST_RATE = 'SET_CURRENT_INTEREST_RATE'
export const GET_MMCODE_CPK_BY_MMCODE = 'GET_MMCODE_CPK_BY_MMCODE'
export const SET_MMCODE_CPK_BY_MMCODE = 'SET_MMCODE_CPK_BY_MMCODE'