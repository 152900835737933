import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                users: [],
                vehicles: [],
                supplierage: [],
                bookinginfo: [],
                selectedVehicle: [],
                geoSuppliers: null,
                vehicleserviceinfo: [],
                maintpdf_preview: '',
                defleetQuoteApprovalCEO: {},
                comments: {
                    errorText: '',
                    value: '',
                    valid: false,
                    empty: true,
                }
            }
        case types.SELECT_PURCHASE_ORDER_TYPE:
            return {
                ...state,
                selectedPurchaseOrderType: action.payload,
                supplierAutoCompletes: [{
                    id: 0,
                    data: [],
                    selected: []
                }]
            }
        case types.SET_USER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                users: action.payload
            }
        case types.SET_USER_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    data: action.payload,
                    valid: true
                }
            }
        case types.SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                vehicles: action.payload
            }
        case types.SET_VEHICLE_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                selectedVehicle: action.payload
            }
        case types.SET_COMPONENT_RECENT_MAINTENANCE_AUTHS:
            return {
                ...state,
                recent_auth: action.payload.data
            }
        case types.SET_SELECTED_VEHICLE_DETAILS:
            return {
                ...state,
                selectedVehicleDetails: {
                    ...state.selectedVehicleDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_SELECTED_SUPPLIER_DETAILS:
            return {
                ...state,
                selectedSupplierDetails: {
                    ...state.selectedSupplierDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_SUSPENDED_SUPPLIER_DETAILS:
            return {
                ...state,
                selectedSupplierSuspended: {
                    ...state.selectedSupplierSuspended,
                    data: action.payload.data,
                    valid: action.payload.status
                }
            }
        case types.SET_GEO_MAP_SUPPLIERS:
            return {
                ...state,
                geoSuppliers: action.payload
            }
        case types.SET_VEHICLE_SERVICE_INFORMATION:
            return {
                ...state,
                vehicleserviceinfo: action.payload
            }
        case types.SET_SUPPLIER_AGE:
            return {
                ...state,
                supplierage: action.payload
            }
        case types.SET_PRE_AUTHORISATION_NUMBER:
            return {
                ...state,
                preAuth: action.payload
            }
        case types.SET_VEHICLE_CURRENT_BOOKING_INFO:
            return {
                ...state,
                bookinginfo: action.payload.bookinginfo
            }
        case types.SET_SUPPLIER_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                supplierAutoCompletes: state.supplierAutoCompletes.map(x => {
                    if (x.id === action.payload.id) {
                        x.selected = action.payload.request
                    }

                    return x
                })
            }
        case types.SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                supplierAutoCompletes: state.supplierAutoCompletes.map(x => {
                    if (x.id === action.payload.id) {
                        x.data = action.payload.data
                    }

                    return x
                })
            }
        case types.ADD_SUPPLIER_AUTOCOMPLETE:
            return {
                ...state,
                supplierAutoCompletes: state.supplierAutoCompletes.concat({
                    id: state.supplierAutoCompletes.length,
                    data: [],
                    selected: []
                })
            }
        case types.REMOVE_SUPPLIER_AUTOCOMPLETE:
            return {
                ...state,
                supplierAutoCompletes: action.payload > 0
                    ? state.supplierAutoCompletes.filter(x => x.id !== action.payload)
                    : state.supplierAutoCompletes
            }

        case types.SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT:
            return {
                ...state,
                maintpdf_preview: action.payload.preview,
                maintpdf_filename: action.payload.filename,
            }
        case types.SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUES:
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                }
            }
        default:
            return state
    }
}