export const GET_QUOTE_DOCUMENTS = 'GET_QUOTE_DOCUMENTS'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const VIEW_DOCUMENT = 'VIEW_DOCUMENT'
export const QUOTE_DOCUMENT_VIEWER = 'QUOTE_DOCUMENT_VIEWER'
export const SET_QUOTE_DOCUMENT_BYTES = 'SET_QUOTE_DOCUMENT_BYTES'
export const OPEN_QUOTE_EDIT_VIEW = 'OPEN_QUOTE_EDIT_VIEW'
export const CLOSE_QUOTE_EDIT_VIEW = 'CLOSE_QUOTE_EDIT_VIEW'
export const SET_WORKFLOW = 'SET_WORKFLOW'
export const SET_TASK = 'SET_TASK'
export const PDF_QUOTE_GRID_ADD_ROW = 'PDF_QUOTE_GRID_ADD_ROW'
export const PDF_CLEAR_SURROGATEHEADER_GRID = 'PDF_CLEAR_SURROGATEHEADER_GRID'
export const PDF_REGENERATE_QUOTE = 'PDF_REGENERATE_QUOTE'
export const PDF_SUBMIT_EDIT_WORKFLOW_TASK_INPUT = 'PDF_SUBMIT_EDIT_WORKFLOW_TASK_INPUT'
export const SET_PDF_MULTIPLE_VIEW_DOCUMENTS = 'SET_PDF_MULTIPLE_VIEW_DOCUMENTS'
export const SET_PDF_MULTIPLE_VIEW_DOCUMENTS_WITH_PROP = 'SET_PDF_MULTIPLE_VIEW_DOCUMENTS_WITH_PROP'
export const SET_PDF_MULTIPLE_DOCUMENTS_PREVIEW = 'SET_PDF_MULTIPLE_DOCUMENTS_PREVIEW'
export const GET_PDF_MULTIPLE_VIEW_DOCUMENTS_PREVIEW = 'GET_PDF_MULTIPLE_VIEW_DOCUMENTS_PREVIEW'
export const SET_DOCUMENT_VIEW_PROP_FLAG = 'SET_DOCUMENT_VIEW_PROP_FLAG'
export const CLEAR_DOCUMENTS_PREVIEW = 'CLEAR_DOCUMENTS_PREVIEW'
export const SET_COMPONENT_DOCUMENT_PREVIEW_FLAG = 'SET_COMPONENT_DOCUMENT_PREVIEW_FLAG'
export const SET_CURRENT_DOCUMENT_PREVIEW_TAB = 'SET_CURRENT_DOCUMENT_PREVIEW_TAB'