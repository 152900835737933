import * as types from './types'
import * as appTypes from '../App/types'
import moment from 'moment'

export const signup_amplify = (data) => {
        // //console.log('signup_amplify hook',data)
        return (dispatch) => {
            dispatch({
                type: types.SIGNUP_AMPLIFY,
                payload: data
            })
        }
}

export const signupAmplifyDone = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                type: 'success',
                title: 'Account Verification',
                additionalText: 'Please enter your verification code',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
