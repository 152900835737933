import * as types from './types'
import validation from 'validator'

export const resetComponent = () => {
    return {
        type: types.VIEW_RESET_COMPONENT
    }
}

export const toggleInfoView = (status) => {
    return {
        type: types.VIEW_COMPONENT_TOGGLE_INFOR_VIEW,
        payload: status
    }
}
export const toggleSectionInfosheet = (section_infosheet,display_type,infosheet_key) => {
    //console.log('toggleSectionInfosheet',section_infosheet)
    return {
        type: types.VIEW_COMPONENT_TOGGLE_SECTION_INFOSHEET,
        payload: {
            section_infosheet: section_infosheet,
            infosheet_key: infosheet_key,
            display_type: display_type,
        }
    }
}
