import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import AutoComplete from '../../../controls/autocomplete'
import IconButton from '@mui/material/IconButton';

import Icon from '../../Icon/component'

import { Label } from './styles'
import * as actions from './actions'
import * as styles from './styles'

class ACCESSORY extends Component {
    render() {
        const supplier = this.props.state.supplier

        return <Card>
            <CardHeader
                title={window.t.en('Accessory Pricing Profile')}
                subtitle={window.t.en('Manage supplier accessory pricing)')}
                avatar={
                    <Icon iclass={'custom-icons'} iname={this.props.icon} />
                }
                style={styles.row}
            />
            <CardContent>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={5}>
                            <Label>{'Select Supplier:'}</Label>
                        </Col>
                        <Col xl={7}>
                            <AutoComplete
                                listStyle={{ maxHeight: 400 }}
                                id={'dll_supplier_id'}
                                key={'dll_supplier_id'}
                                hintText={'Select supplier'}
                                filter={AutoComplete.fuzzyFilter}
                                openOnFocus={true}
                                fullWidth={true}
                                dataSourceConfig={{
                                    text: 'suppliername',
                                    value: 'supplier_id'
                                }}
                                dataSource={this.props.state.supplier_list}
                                onNewRequest={
                                    (chosenRequest) => {
                                        this.props.actions.setLookupValue(chosenRequest.supplier_id, chosenRequest.suppliername)
                                    }
                                }
                                onClick={
                                    () => {
                                        this.props.actions.getLookupValueData('')
                                    }
                                }
                                onUpdateInput={
                                    (search) => {
                                        this.props.actions.getLookupValueData(search)
                                    }
                                }
                            />
                        </Col>
                    </Row>

                </Container>

            </CardContent>
            {
                this.props.state.accessory_list.length
                    ? <div>       <CardHeader
                        title={window.t.en('Accessory Pricing Profile')}
                        subtitle={window.t.en('Manage supplier accessory pricing')}
                        avatar={
                            <Icon iclass={'custom-icons'} iname={this.props.icon} />
                        }
                        style={styles.row}
                    />
                        <CardContent>
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row style={styles.hr}>
                                    <Col>
                                        <div key={'workflow_queue_task_search_input'} style={styles.searchInput}>
                                            <TextField
                                                onChange={(event) => {
                                                    this.props.actions.setSearchValue(supplier, event.target.value)
                                                }}
                                                key={'workflow_queue_task_search_input_text'}
                                                hintText={'Search...'}
                                                // onKeyPress={(event) => {
                                                //     if (event.key === 'Enter') {
                                                //         this.props.actions.searchWorkflowQueueTasks()
                                                //     }
                                                // }}
                                            />
                                        </div>
                                        {/* <div key={'workflow_queue_task_search_icon'} style={styles.searchIcon}>
                           < Button className={"global_button"} variant="contained"
                                icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.searchWorkflowQueueTasks()
                                }}
                            >{window.t.en('Search')}</Button>
                        </div> */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>

                                    </Col>
                                </Row>
                                <Row style={styles.hr}>
                                    <Col xl={1}>
                                        {'Action'}
                                    </Col>
                                    <Col xl={6}>
                                        {'Accessory Name'}
                                    </Col>
                                    <Col xl={2}>
                                        {'Selling Price'}
                                    </Col>
                                    <Col xl={2}>
                                        {'Cost Price'}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        {''}
                                    </Col>
                                </Row>
                                {
                                    this.props.state.accessory_list.filter(f => (f.accessory ? f.accessoryname : '').indexOf(this.props.state.search_text ? this.props.state.search_text : '') !== -1).map((x, i) => { 
                                        return <Row style={i % 2 ? styles.alternating : styles.nonealternating} key={'suppler_accesory_pricing_' + i}>
                                            <Col xl={1} style={styles.verticalMiddle}>
                                                <IconButton key={'command_remove_' + x.accessory_id} style={styles.iconButton} iconStyle={styles.action}
                                                    onClick={
                                                        () => {
                                                            this.props.actions.removeSingleRow(x)
                                                        }
                                                    }>
                                                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                                </IconButton>
                                            </Col>
                                            <Col xl={6} style={styles.lineitems}>
                                                {x.accessoryname // fktext removed 
                                                ? x.accessoryname // fktext removed 
                                                : <AutoComplete
                                                    hintText={'Select Accessory '}
                                                    listStyle={{ maxHeight: 200, overflow: 'auto' }}
                                                    popoverProps={{ style: { overflow: 'auto', width: 'auto' } }}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    fullWidth={true}
                                                    openOnFocus={true}
                                                    dataSource={this.props.state.accessory_selection}
                                                    dataSourceConfig={{
                                                        text: 'accessoryname',
                                                        value: 'accessory_id'
                                                    }}
                                                    onNewRequest={
                                                        (chosenRequest) => {
                                                            this.props.actions.setSelectedAccessory(x, chosenRequest.accessoryname, chosenRequest.accessory_id)
                                                        }
                                                    }
                                                    onUpdateInput={
                                                        (searchText) => {
                                                            this.props.actions.getAccessoriesList(searchText)
                                                        }
                                                    }
                                                    onClick={
                                                        () => {
                                                            this.props.actions.getAccessoriesList('')
                                                        }
                                                    }
                                                />
                                                
                                                }
                                            </Col>
                                            <Col xl={2}>
                                                <TextField
                                                    key={x.accessory_id + '_' + i}
                                                    disabled={!x.accessoryname} 
                                                    hintText={x.accessoryname}
                                                    value={x.sellingprice}
                                                    errorText={''}
                                                    onChange={
                                                        (event, value) => {
                                                            this.props.actions.updSupplierAccessorySellingPrice(i, value)
                                                        }
                                                    }
                                                />
                                            </Col>
                                            <Col xl={2}>
                                                <TextField
                                                    key={x.accessory_id + '_' + i}
                                                    disabled={!x.accessoryname}// fktext removed 
                                                    hintText={x.accessoryname}
                                                    value={x.costprice}
                                                    errorText={''}
                                                    onChange={
                                                        (event, value) => {
                                                            this.props.actions.updSupplierAccessoryCostPrice(i, value)
                                                        }
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    })
                                }
                                <Row>
                                    <Col>
                                        <br />
                                       < Button className={"global_button"} variant="contained"
                                            key={'add_more_button'}
                                            label={'Add More'}
                                            primary={true}
                                            onClick={() => {
                                                this.props.actions.addNewSupplierPricingItem(supplier)
                                            }}
                                            >{window.t.en('Add More')}</Button>
                                        <br />
                                        <br />
                                        <br />
                                    </Col>
                                </Row>
                            </Container>
                        </CardContent>
                    </div>
                    : <div></div>
            }
        </Card>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.accessory
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ACCESSORY)