export const data = []
export const staticMenu = []
export const loading = false
export const related = false
export const megamenu = false
export const dashMegamenu = false
export const anchorEl = null
export const dashAnchorEl = null
export const openDashboard = false
export const anchorEl1 = null
export const relatedMenu =false

export const reportMegamenu = false
export const reportAnchorEl = null
export const openReport = false
export const anchorEl2 = null
export const collapse = ''

export const notifications_headers = null
export const notifications_items = null
export const oqlisMenuReport = {
    isJs: null,
    name: '',
    key: '',
    report_data: null
}

export const oqlisMenuDashboard = {
    key: ''
}
