export const CREATE_TEAM = 'CREATE_TEAM'
export const UPDATE_TEAM = 'UPDATE_TEAM'
export const GET_TEAM_USERS = 'GET_TEAM_USERS'
export const DEACTIVATE_TEAM = 'DEACTIVATE_TEAM'
export const ACTIVATE_TEAM = 'ACTIVATE_TEAM'
export const ASSIGN_USER_TO_TEAM = 'ASSIGN_USER_TO_TEAM'
export const REMOVE_USER_FROM_TEAM = 'REMOVE_USER_FROM_TEAM'
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const DEACTIVATE_USER = 'DEACTIVATE_USER'
export const ACTIVATE_USER = 'ACTIVATE_USER'
export const SET_CURRENT_TEAM_MANAGEMENT_TAB = 'SET_CURRENT_TEAM_MANAGEMENT_TAB'