// import * as colours from '@mui/material/colors';
// import green from '@mui/material/colors/green';
//import { fade } from '@mui/material/utils/colorManipulator';
//import spacing from '@mui/material/spacing';

export default {
    //spacing: { ...spacing, iconSize: 15 },
    fontFamily: 'Roboto, sans-serif',
    palette: {
        // primary: {
        //     main: green[500],
        //   },
        primary1Color: '#d6d6d6',
        primary2Color: 'red',
        // primary3Color: colours.green[500],
        // accent1Color: colours.red[500],
        // accent2Color: colours.grey[100],
        // accent3Color: colours.grey[500],
        // textColor: colours.grey[500],
        // alternateTextColor: '#bd141a',
        // canvasColor: colours.grey[50],
        // borderColor: colours.red[500],
        // //disabledColor: fade(colours.grey[500], 0.3),
        // pickerHeaderColor: colours.cyan[500],
        // //clockCircleColor: fade(colours.grey[500], 0.07),
        // shadowColor: colours.grey[900]
    },
    styles: {
        font: {
            size: 15
        },
        card: {
            opacity: 0.95,
            padding: 0
        },
        cardHeader: {
            padding: 0
        },
        list: {
            padding: 10,
            backGroundColor: '#e8e8e8',
            listItem: {
                inner: {
                    padding: 10,
                    marginRight: 20,
                    paddingRight: 20
                },
                padding: 0,
                font: {
                    size: 15
                }
            },
            listItemHeader: {
                //backgroundColor: '#dcd6d7',
                fontSize: 15,
                padding: 0,
                font: {
                    size: 15
                }
            }
        },
        menuItem: {
            padding: 0
        }
    }
}