
import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'


export const getMMCodeByVIN = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vinnumber/mapo?vin=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 100
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.getServicePartBasket(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/vinnumber/mapo?vin' + payload)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vinnumber/mapo', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
    
export const getServicePartBasket = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_BOOKING_VEHICLE_SERVICE_PART_BASKET),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/serviceplan/search' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 100
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setServicePartBasket(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/serviceplan/search' + payload)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/serviceplan/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )  


export const getVehicleContract = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customercontract/search?vehicle_id=' + payload,
                related: 'customercontractderivative',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 100
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setVehicleContract(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customercontract//search?vehicle_id=' + payload)
                ])
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontract//search?vehicle_id=', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

            
export const getSelectedVehicleDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_SELECTED_VEHICLE_DETAILS),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/vehicle/search?contractactive=true&vehicle_id=' + payload.query,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'customercontract.customercontractderivative,customer.customercontact.contact,maintenancetransaction.maintenancetransactionitem,mmcode,lastposition',

            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setSelectedVehicleDetails(result.response, payload.query),
                requestActions.requestSucceeded(config.system.api + '/components/vehicle/search')
            ])
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)


    
export const getSupplierListData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_DEFLEET_SUPPLIERS_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/supplier/search?suppliername=' + payload,
            related: 'customercontractderivative',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'take': 100
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setSupplierListData(result.response),
                requestActions.requestSucceeded(config.system.api + '/components/customercontract/search?vehicle_id=' + payload)
            ])
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customercontract/search?vehicle_id=', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)