import * as types from './types'
import moment from 'moment'

export const toggleShowAll = (val) => {
    return (dispatch, getState) => {
        //console.log(val)
        dispatch({
            type: types.SET_COMPONENT_SHOW_ALL_EVENTS,
            payload: val
        })
    }
}

export const getEvents = (showall) => {
    return (dispatch, getState) => {
        let query = showall ? '?created_by_user_id=' + getState().user.user_id : ''
        dispatch({
            type: types.GET_CALENDAR_EVENTS,
            payload: query
        })
    }
}

export const setEvents = (payload) => {
    return (dispatch, getState) => {
        //console.log('events', payload.data)
        const events = payload.data.map(x => {
            return  {
                // allDay: true,
                // startDate: x.start,
                // endDate: x.end,
                // title: x.title,
                
                start: new Date(x.start),
                end: new Date(x.end),
                name: x.title,
                id: x.event_id,
                type:'task',
                progress: 0,
                styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
              }
        })

        dispatch({
            type: types.SET_CALENDAR_EVENTS,
            payload: events
        })
    }
}

export const getTasks = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_GANTT_TASKS,
        })
    }
}

export const setTasks = (payload) => {
    return (dispatch, getState) => {
        //console.log('taskslist', payload)
        
        const tasks = payload.map(x => {
            return  {
                start: new Date(parseInt(moment(x.startdate).format('YYYY')),parseInt(moment(x.startdate).format('MM')),parseInt(moment(x.startdate).format('DD'))), //new Date(x.startdate),
                end: new Date(parseInt(moment(x.enddate).format('YYYY')),parseInt(moment(x.enddate).format('MM')),parseInt(moment(x.enddate).format('DD')),0,0),
                name: x.task,
                id: x.task,
                progress: 100,
                type:'project',
                //progress: 0,  milestone, project ,task
                //isDisabled: false,
                //project: 'UserTaskList',
                styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d', overflow: 'auto' },
              }
        })
        
        dispatch({
            type: types.SET_GANTT_TASKS,
            payload: tasks
        })
    }
}