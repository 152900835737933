import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton'

import ReactTable from "react-table"
import "react-table/react-table.css"
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';


import {ReactComponent as DownloadCheckSheetIcon} from '../../../assets/img/svg/icons/download-checksheet.svg'
import {ReactComponent as VehicleDetailIcon} from '../../../assets/img/svg/icons/vehicle-details.svg'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowDisposalMail extends Component {
    componentDidMount() {
        this.props.actions.getOTP()
    }

    getTooplBar() {
        return <Toolbar>
                {/* <Select 
                value={1} 
                primary={true}
                onChange={
                    (event) => {
                        this.props.actions.setDataFilter(event.target.value)
                }}>
                    <MenuItem value={1} primaryText="View Yard List">{"View Yard List"}</MenuItem>
                    <MenuItem value={2} primaryText="View Sold Vehicles">{"View Sold Vehicles"}</MenuItem>
                </Select> */}
          
                <Button variant='contained' className='global_button'
                    label="Generate OTP"
                    primary={true}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.generateOPTList(this.props.state.user.firstname)
                    }} 
                    >{"Generate OTP"}</Button>
                {/* <IconMenu
                    iconButtonElement={
                        <IconButton touch={true}>
                            <NavigationExpandMoreIcon />
                        </IconButton>
                    }
                >
                    <MenuItem primaryText="Export List To Excel" />
                </IconMenu> */}
        </Toolbar>
    }

    getCommands(row) {
        let field = this.props.state.otp_fields.filter(x => x.key === true)[0]
        const id = row.original[field.name]
        const listnumber = row.original['listnumber']
        let i = row.index
        let commands = []

        commands.push(
            <IconButton key={'command_otp_list_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon('')}
                onClick={
                    () => {
                        this.props.actions.resetInfosheet()
                        this.props.actions.toggleInfoSheet(i, 'inforsheet_otp_list')
                        this.props.actions.loadOtpList(listnumber)
                    }
                }>
                {/* <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} /> */}
                <VehicleDetailIcon style={styles.gridOptionsIcon}/>
            </IconButton>
        )
        commands.push(
            <IconButton key={'command_otp_view_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon('')}
                onClick={
                    () => {
                        this.props.actions.exportOffers(listnumber)
                    }
                }>
                    
                <DownloadCheckSheetIcon style={styles.gridOptionsIcon}/>
                {/* <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'cloud_download'} /> */}
            </IconButton>
        )
        
        return commands
    }

    getFormFieldInput(row) {
        let field = this.props.state.otp_fields.filter(x => x.name === row.column.meta)[0]
        let index = row.index

        switch (field.type) {
            case 'date':
            case 'dateTime':
            case 'time':
            case 'timestamp':
                return <div>
                    <TextField
                        key={field.name + '_' + index}
                        //floatingLabelText={field.description}
                        //hintText='Click to select a date...'
                        container='inline'
                        mode='landscape'
                        //defaultDate={new Date()}
                        value={this.data[index][field.name] ? new Date(this.data[index][field.name]) : null}
                        // onChange={
                        //     (event, value) => {
                        //         //console.log('datetime',value)
                        //         this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:value}, index, field.name, value)
                        //     }
                        // }
                    />
                </div>
            case 'boolean':
                return <div>
                    <Checkbox
                        key={field.name + '_' + index}
                        //label={field.hint}
                        labelPosition='right'
                        //defaultToggled={false}
                        style={styles.toggle}
                        trackStyle={{
                            backgroundColor: 'gray'
                        }}
                        trackSwitchedStyle={{
                            backgroundColor: 'green'
                        }}
                        toggled={this.data[index][field.name] === true}
                        // onToggle={
                        //     (event, isInputChecked) => {
                        //         this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:isInputChecked}, index, field.name, isInputChecked)
                        //     }
                        // }
                    />
                </div>

            default:
                return <TextField
                key={field.name + '_' + index}
                //errorText=''
                //hintText={field.hint}
                //floatingLabelText={field.description}
                value={this.data[index][field.name]} 
                //defaultValue={''}
                //style={styles.textBox}
                // onChange={
                //     (event, value) => {
                //         this.props.actions.keyComponentFieldInput(
                //             this.component,
                //             {[pkey]:row.original[pkey],[field.name]:value}, 
                //             index, 
                //             field.name, 
                //             value)
                //     }
                // }
                // onBlur={
                // (event) => {
                //     this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:event.target.value}, index, field.name, event.target.value)
                // }
            //}
            />
            // <input type={"input"} 
            //         style={{
            //             width: '95%',
            //             height: 25,
            //             border: '1px solid #E6E6E6',
            //             borderRadius: 25,
            //             fontSize: 'small'
            //         }} 
            //         key={field.name + '_' + index}
            //         value={this.component.grid.data[index][field.name]} 
            //         onChange={(event) => {
            //                 this.props.actions.keyComponentFieldInput(
            //                     this.component,
            //                     {[pkey]:row.original[pkey],[field.name]:event.target.value}, 
            //                     index, 
            //                     field.name, 
            //                     event.target.value)
            //             }
            //         }
            //         onBlur={
            //         (event) => {
            //             this.props.actions.updComponentFieldInput(this.component,{[pkey]:row.original[pkey],[field.name]:event.target.value}, index, field.name, event.target.value)
            //         }
            //     }/>
        }
          
    }

    getValue(row) {
        let field = this.props.state.otp_fields.filter(x => x.name === row.column.meta)[0]
        if(!row.value) return row.value
            switch (field.type) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
    }

    getFormFieldLabel(row) {
          return <div
          style={{
            width: '100%',
            marginTop: '3px',
            verticalAlign: 'baseline',
            fontSize: '15px'
        }} >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.otp_fields.filter(x => x.key === false)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.name,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.right
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                            x.edit
                            ? this.getFormFieldInput(row)
                            : this.getFormFieldLabel(row)
                    )
                }
            })

            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        return columns
    }

    getGrid() {
        return <div style={{overflow: 'auto'}}>
            <p>
            {'OPT list to be email to bidders'}
            </p>
            {this.getTooplBar()}
            <ReactTable
                data={this.otp}
                columns={this.getColumns()}
                manual
                loading={this.loading}
                // onFetchData={
                //     (state) => {
                //         this.props.actions.getComponentGridData(this.component, this.props.link, state, this.props.filter, this.props.id)
                //     }
                // }
                // getTrProps={(state, rowInfo) => {
                //     return {
                //         onClick: (event) => {
                //             this.props.actions.selectRow(this.component.name, rowInfo)

                //             if (this.component.infosheet) {
                //                 this.props.actions.toggleInfoSheet()
                //             }
                //         },
                //         style: {
                //             background: this.component.selected && rowInfo
                //                 ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                //                 : 'white',
                //             color: this.component.selected && rowInfo
                //                 ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                //                 : 'black'
                //         }
                //     }
                // }}
                // onResizedChange={(newResized, event) => {
                //     this.props.actions.resizeColumn(this.component.name, newResized)
                // }}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div>
    }

    render() {
        this.data = this.props.state.yardlist
        this.otp = this.props.state.otp
        //console.log('this.props.state.yardlist.length',this.props.state.yardlist)
        return  this.getGrid()        
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalMail)