
export const newused = ''
export const supplier_data = []
export const supplieraddress = {address: {}}
export const supplierquoteadjustments = 'No'
// export const title = ''
// export const description = ''

export const fields = {
  roipercentage: {
    errorText: 'Please enter Return on Investment %',
    state: 'getState().workflowQuoteReview.quotations[0].roipercentage',
    data: false,
    valid: false,
    empty: true,
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Return on Investment %'
      }
    ]
  },
  adminfee: {
    errorText: 'Please enter Admin Fee',
    state: 'getState().workflowQuoteReview.quotations[0].adminfee',
    data: false,
    valid: false,
    empty: true,
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Admin Fee'
      }
    ]
  },
  service: {
    errorText: 'Please enter Service',
    state: 'getState().workflowQuoteReview.quotations[0].service',
    data: false,
    valid: false,
    empty: true,
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Service'
      }
    ]
  },
  licence: {
    errorText: 'Please enter Licence Fee',
    state: 'getState().workflowQuoteReview.quotations[0].licence',
    data: false,
    valid: false,
    empty: true,
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Licence Fee'
      }
    ]
  },

  tyrecpk: {
    errorText: 'Please enter Tyres CPK',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['tyrecpk'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Tyres CPK'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Tyres CPK'
      }
    ]
  },
  escalated_cpk: {
    errorText: 'Please enter Escalatated Maintenance CPK',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['escalated_cpk'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Escalatated Maintenance CPK'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Escalatated Maintenance CPK'
      }
    ]
  },
  maintenancecpk: {
    errorText: 'Please enter Normal Maintenance CPK',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['maintenancecpk'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Normal Maintenance CPK'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Normal Maintenance CPK'
      }
    ]
  },
  distancepermonth: {
    errorText: 'Please enter Distance per Month',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['distancepermonth'],
    validations: [
      {
        'schema': 'integer',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Distance per Month'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Distance per Month'
      }
    ]
  },
  deviation: {
    errorText: 'Please enter Deviation',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['deviation'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Deviation'
      }
    ]
  },
  deliveryperiod: {
    errorText: 'Please enter Delivery Period',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['deliveryperiod'],
    validations: [
      {
        'schema': 'integer',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Delivery Period'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Delivery Period'
      }
    ]
  },
  rv_amount: {
    errorText: 'Please enter Residual Value',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['rv_amount'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Residual Value'
      }
    ]
  },
  rv: {
    errorText: 'Please enter Residual Value',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['rv'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Residual Value'
      }
    ]
  },
  discount: {
    errorText: 'Please enter Discount',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['discount'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Discount'
      }
    ]
  },
  retailPrice: {
    errorText: 'Please enter Retail Price',
    state: 'getState().workflowQuoteReview.quotations',
    data: false,
    valid: false,
    empty: true,
    type: 'table',
    tableFields: ['retailprice'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Retail Price'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Retail Price'
      }
    ]
  },
  newused: {
    errorText: 'Please select New Or Used for the Vehicle ',
    state: 'getState()?.workflowQuoteReview?.supplierquoteadjustments === "Yes" ? getState()?.workflowQuoteReview?.newused : true',
    valid: false
  },
  documents: {
    errorText: 'Please upload a Document and select a Document Type ',
    state: 'getState()?.workflowQuoteReview?.supplierquoteadjustments === "Yes" ? getState().upload?.documents?.length && getState().upload.documentypes?.length  :true',
    valid: false,
    empty: true,
},


  supplier_id: {
    errorText: 'Please select a Supplier for Quote Adjustments',
    state: 'getState()?.workflowQuoteReview?.supplierquoteadjustments === "Yes" ? getState()?.workflowQuoteReview?.supplier_id : true',
    valid: false
  }
}