import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'

import { Table } from '@mui/material'

import * as styles from './styles'
import * as actions from './actions'
import moment from 'moment';
import TextField from '@mui/material/TextField'
import Button from "@mui/material/Button";
import Upload from '../../Upload/component'
import PreviewGrid from './previewgrid'
import Fields from '../../../controls/cardfields'
import MultiSelect from '../../../controls/multiselect'
import PdfViewer from '../../PDF/component'

import Dialog from '@mui/material/Dialog'

class BillingPreview extends Component {
    componentDidMount() {
        //console.log('getGridDatax prev mount')
        this.props.actions.reset()
        this.props.actions.getInvoiceData()
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data
            let billingtracking_id = this.data[0].step1.billingtracking_id
            this.props.actions.getBillingTracking(billingtracking_id)
        } catch (error) {

        }
    }

    
    getSteps() {
        return <Fields 
        fields={[
            {label: 'Billing Month', value: this?.props?.state?.billingtracking?.[0]?.billing_month},
            {label: 'Billing Task Number', value: this?.props?.state?.billingtracking?.[0]?.billing_taskno},
            {label: '', value: ''},
                   
            {label: 'Billing Pre File Date', value: moment(this?.props?.state?.billingtracking?.[0]?.billing_prep_date).format('YYYY-MM-DD HH:mm')},
            {label: 'Billing Pre File By', value: this?.props?.state?.billingtracking?.[0]?.billing_prep_by},
            {label: '', value: ''},
                   
            {label: 'Billing Odo File Date', value: moment(this?.props?.state?.billingtracking?.[0]?.billing_odo_date).format('YYYY-MM-DD HH:mm')},
            {label: 'Billing Odo File By', value: this?.props?.state?.billingtracking?.[0]?.billing_odo_by},
            {label: '', value: ''},
                   
            {label: 'Billing OOCP File Date', value: moment(this?.props?.state?.billingtracking?.[0]?.billing_oocp_date).format('YYYY-MM-DD HH:mm')},
            {label: 'Billing OOCP File By', value: this?.props?.state?.billingtracking?.[0]?.billing_oocp_by},
            {label: '', value: ''},
                   
            {label: 'Last Freshed Date', value: moment(this?.props?.state?.billingtracking?.[0]?.billing_refresh_date).format('YYYY-MM-DD HH:mm')},
            {label: 'Last Freshed By', value: this?.props?.state?.billingtracking?.[0]?.billing_refresh_by}
        ]}
        />
    }
    
    getDetails() {
        return <Card title={window.t.en('Progress Step')}
                subtitle={window.t.en('Billing steps')}
                content={this.getSteps()}
            />
    }
    
    getFiltes() {
        return <Table className="table table-hover text-nowrap mb-0">
        <thead>
        <tr>
            <th colSpan={2}>{'Invoice Status'}</th>
        </tr>
        </thead>
        <tbody>
            <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                <td>
                    <Checkbox
                        checked={this.props.state.chkpending}
                        label={'Total Pending'}
                        onCheck={(event) => {
                            this.props.actions.setCheckValue('chkpending', event)
                            this.props.actions.setValue('chkfilter', null)
                        }} />
                </td>
                <td>
                    {this.props.state.data.filter(x => x.send_status == null).length}
                </td>
            </tr>
            <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                <td>
                    <Checkbox
                        checked={this.props.state.chkqueued}
                        label={'Total Queued '}
                        onCheck={(event) => {
                            this.props.actions.setCheckValue('chkqueued', event)
                            this.props.actions.setValue('chkfilter', '0')
                        }} />
                </td>
                <td>
                {this.props.state.data.filter(x => x.send_status === '0' ).length}
                </td>
            </tr>
            <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                <td>
                    <Checkbox
                        checked={this.props.state.chksent}
                        label={'Total Sent'}
                        onCheck={(event) => {
                            this.props.actions.setCheckValue('chksent', event)
                            this.props.actions.setValue('chkfilter', '1')
                        }} />
                </td>
                <td>
                {this.props.state.data.filter(x => x.send_status === '1').length}
                </td>
            </tr>
            <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                <td>
                    <Checkbox
                        checked={this.props.state.chkcreated}
                        label={'Total Created '}
                        onCheck={(event) => {
                            this.props.actions.setCheckValue('chkcreated', event)
                            this.props.actions.setValue('chkfilter', '2')
                        }} />
                </td>
                <td>
                {this.props.state.data.filter(x => x.send_status === '2' ).length}
                </td>
            </tr>
        </tbody>
            <thead>
            <tr>
                <th colSpan={2}>{'Invoice Filters'}</th>
            </tr>
            </thead>
        <tbody>
            <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                <td colSpan={2}>
                    <TextField
                        id={'txtfilter'}
                        key={'txtfilter'}
                        label={'Filter Invoice List'}
                        fullWidth={true}
                        variant="outlined"
                        value={this.props.state.filter}
                        onChange={
                            (event) => {
                                this.props.actions.setValue('filter', event.target.value)
                            }
                        }
                    />
                </td>
            </tr>
        </tbody>
    </Table>
    
   

    }

    getRefreshDetails() {
        return  <Card title={window.t.en('Billing Summary')}
        subtitle={window.t.en('Billing details summary')}
        content={<Fields 
                fields={[
                    {label: 'Total Invoice Count', value: this?.props?.state?.data?.length},
                    {label: 'Total Invoice Amount (Excl)', value: _.sumBy(this.props.state.data, (d) => { return parseFloat(Number(d.subtotal) ? Number(d.subtotal) : 0.00) }).toFixed(2)},
                    {label: '', value: ''},
                   {label: 'FML Count ', value: this.props.state.data.filter(x => x.contracttype.includes('Billing FML')).length},
                    {label: 'FML Amount (Excl)', value: _.sumBy(this.props.state.data.filter(x => x.contracttype.includes('Billing FML')), (d) => { return parseFloat(Number(d.subtotal) ? Number(d.subtotal) : 0.00) }).toFixed(2)},
                    {label: '', value: ''},
                   {label: 'SAP Count', value: this.props.state.data.filter(x => x.contracttype.includes('Billing SAP')).length},
                    {label: 'SAP Amount (Excl)', value: _.sumBy(this.props.state.data.filter(x => x.contracttype.includes('Billing SAP')), (d) => { return parseFloat(Number(d.subtotal) ? Number(d.subtotal) : 0.00) }).toFixed(2)},
                    {label: '', value: ''},
                   {label: 'STR_NEW Count', value: this.props.state.data.filter(x => x.contracttype.includes('Billing STR')).length},
                    {label: 'STR_NEW Amount (Excl)', value: _.sumBy(this.props.state.data.filter(x => x.contracttype.includes('Billing STR')), (d) => { return parseFloat(Number(d.subtotal) ? Number(d.subtotal) : 0.00) }).toFixed(2)},
                    //{label: 'STR_SAP Count', value: this.props.state.data.filter(x => x.contracttype.includes('Billing STR')).length},
                    //{label: 'STR_SAP Amount (Excl)', value: _.sumBy(this.props.state.data.filter(x => x.contracttype.includes('Billing STR')), (d) => { return parseFloat(Number(d.subtotal) ? Number(d.subtotal) : 0.00) }).toFixed(2)},
                ]}
                />
            }
        />
    }

    
    getForm() {
        return <div>
            <Card 
                    title={window.t.en('Billing Parametes')}
                    subtitle={window.t.en('Filters and parameters')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                        <MultiSelect
                                            name={'autocomplete_purchase_order_copy_user'}
                                            title={window.t.en('Select copy user')}
                                            label={'BCC Copy User'}
                                            info={{
                                                show: false
                                            }}
                                            fullWidth={true}
                                            data={this.props.state.users}
                                            //value={this.props.state.fields.selectedUser.data ? this.props.state.fields.selectedUser.data.text : ''}
                                            onSelect={(args) => {
                                                this.props.actions.setUserAutoCompleteSelected(args,this.billingtracking_id)
                                            }}
                                            onClick={() => 
                                                this.props.actions.getUserAutoCompleteMenuItems('')
                                            }
                                            onFilter={(args) => {
                                                //this.props.actions.setUserAutoCompleteSelected({text: args, value: null},0)
                                                this.props.actions.getUserAutoCompleteMenuItems(args)
                                            }}/>
                             
                            </Col>
                        </Row>
                    </Container>} />
        </div>
    }

    getBilling() {
        return <Card title={window.t.en('Normal Billing')}
                subtitle={window.t.en('This is Monthly Billing, which includes prorate billing and termination excess')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    {/* <Row>
                        <Col xl={12}>
                            {
                                this.props.state.data
                                    ? <Fields 
                                    fields={[
                                        {label: 'Total Invoice Pending', value: this.props.state.data.filter(x => !x.send_status ).length},
                                        {label: 'Total Invoice Sent', value: this.props.state.data.filter(x => x.send_status ).length}
                                    ]}
                                />
                                : ''
                            }
                    </Col>
                    </Row> */}
                    <Row>
                        <Col xl={12}>
                                <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><PreviewGrid /></Col>
                    </Row>
                </Container>}
                headerAction={<div style={{float: 'right', paddingRight: 50}}>
                    
                    <Button
                            variant="contained"
                            color="primary"
                            className="btn-success"
                            onClick={(event, value) => {
                                this.props.actions.exportBillingInvoice(this.billingtracking_id)
                            }}
                            >
                            {"Export Billing Invoice"}
                        </Button>
                    &nbsp;
                    {/* <Button
                            variant="contained"
                            color="primary"
                            className="btn-success"
                            onClick={(event, value) => {
                                this.props.actions.getInvoiceData()
                                this.props.actions.getBillingTracking(this.billingtracking_id)
                            }}
                            >
                            {"Refresh Data"}
                        </Button>
                    &nbsp; */}

                    {
                        this.props.state.billingtracking.length 
                        ? this.props.state.billingtracking[0].billing_sending ? <Button
                            variant="contained"
                            color="primary"
                            className=""
                            onClick={(event, value) => {
                                this.props.actions.sendBillingInvoice(false)
                            }}
                            >
                            {"Stop Sending Invoice"}
                        </Button>
                        : <Button
                            variant="contained"
                            color="primary"
                            className="btn-success"
                            onClick={(event, value) => {
                                //this.props.actions.setValue('confirm_win', true)
                                //this.props.actions.sendBillingInvoice(true)
                                this.props.actions.sendBillingInvoice(true)
                            }}
                            >
                            {"Send Out Invoice"}
                        </Button>
                        : ''
                    }
                    </div>
                }
            />
    }
        
    commentsView() {
        return <div>
            <Card 
                    title={window.t.en('Billing Comments')}
                    subtitle={window.t.en('Capture comments')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <TextField
                                    id={'txtcomment'}
                                    key={'txtcomment'}
                                    label={window.t.en('Enter Comments...')}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.props.state.comments}
                                    onChange={
                                        (event) => {
                                            this.props.actions.setValue('comments', event.target.value)
                                        }
                                    }
                                /></Col>
                        </Row>
                    </Container>} />
        </div>
    }
    
    getDocuments() {
        return <Card title={window.t.en('Billing Documents')}
                subtitle={window.t.en('List of uploaded documents')}
                content={<div>
                    {
                        this.documents
                        ? <PdfViewer                    
                            key={'document_viewer_uploaded_file'}
                            name={'tsk_billing_run_start'}
                            workflow={'billing_run'}
                            task={'tsk_billing_run_start'}
                            documents={this.documents}
                            edit={false}
                            preview={'buttons'}
                        />
                        : ''
                    }
                    </div>
                }
            />
    }

    getLayout() {
        return <div><Container style={{ margin: 0 }} className="custom-container">
            {
                this.props.state.billingtracking.length
                ? <Row>
                    <Col xl={12}>{this.getDocuments()}</Col>
                    <Col xl={12}>{this.getDetails()}</Col>
                    <Col xl={12}>{this.getRefreshDetails()}</Col>
                    <Col xl={12}>{this.getForm()}</Col>
                    <Col xl={12}>{this.getFiltes()}</Col>
                    <Col xl={12}>{this.getBilling()}</Col>
                    {/* <Col xl={12}>{this.getExcess()}</Col> */}
                    <Col xl={12}>{this.commentsView()}</Col>
                </Row>
                :  <Row>
                    <Col xl={12}>{'Loading'}</Col>
                </Row>
            }
        </Container>
        
        <Dialog
            open={this.props.state.confirm_win}
            onClose={(event) => {
                event.preventDefault()
                this.props.actions.setValue('confirm_win', false)
            }}
            //classes={{ paper: 'shadow-lg rounded' }}
            >
             <div className="text-center p-5">
              <h4 className="font-weight-bold mt-4">
                BILLING START - CONFIRM
              </h4>
              <strong className="mb-0 font-size-lg text-muted bold">
                You are about to start sending invoices
              </strong>
              <p className="mb-0 font-size-lg text-muted">
                <TextField id="outlined-basic" label="Enter Current PIN" variant="outlined" />
              </p>
              <hr />
              <strong className="mb-0 font-size-lg text-muted bold">
                Are you sure
              </strong>
              {/* <p className="mb-0 font-size-lg text-muted">
                <TextField id="outlined-basic" label="Enter New PIN" variant="outlined" />
              </p> */}
              <div className="pt-4">
                <Button
                  onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setValue('confirm_win', false)
                    }}
                  className=" btn-pill mx-1">
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button 
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.setValue('confirm_win', false)
                        this.props.actions.sendBillingInvoice(true)
                    }}
                    className="btn-pill mx-1">
                    <span className="btn-wrapper--label">Ok</span>
                </Button>
              </div>
            </div> 
          </Dialog>
        </div> 
    }

    render() {
        
        this.data = null
        
        try {
            //console.log('this.props.workflow_queue_data',this.props.workflow_queue_data)
            this.data = this?.props?.workflow_queue_data?.context_data?.data?.data?.data
            this.billingtracking_id = this.data[0].step1.billingtracking_id
            this.documents = this.data[0].documents
        } catch (error) {

        }

        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.billingpreview,
            user: {
                ...state.user
            },
            upload: {
                ...state.upload
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingPreview)