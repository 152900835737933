import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.WORKFLOW_TRIGGERS_GET_WORKFLOW_TRIGGERS:
            return {
                ...state,
                loading: true
            }
        case types.WORKFLOW_TRIGGERS_SET_WORKFLOW_TRIGGERS:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case types.TOGGLE_WORKFLOW_TRIGGERS:
            return {
                ...state,
                isOpen: action.payload.status === false? false:!state.isOpen
            }
        case types.CLOSE_WORKFLOW_TRIGGERS:
            return {
                ...state,
                isOpen: false
            }
        default:
            return state
    }
}