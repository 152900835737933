import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_DEFLEET_CAPTUREQUOTE_COMPOMENT_STATE:
            return {
                ...state,                
                days:   {                        
                    ...state.days,
                   data: null,
                   value: '',
                   valid: false,
                   empty: true
               },              
               drivable:   {                        
                   ...state.drivable,
                  data: null,
                  value: null,
                  valid: false,
                  empty: true
              },              
              mechanicalcheck:   {                        
                  ...state.mechanicalcheck,
                 data: null,
                 value: null,
                 valid: false,
                 empty: true
             },           
             comments:   {                        
                  ...state.comments,
                 data: null,
                 value: '',
                 valid: false,
                 empty: true
             },
               fields: props.fields
            }           
            case types.SET_SUPPLIER_SUSPENDED_STATUS:
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        supplierStatus: {
                            ...state.fields.supplierStatus,
                            data: action.payload,
                            valid: !action.payload
                        }
                    }
                }
                
        case types.TOGGLE_SUPPLIER_SUSPENDED:
            return {
                ...state,
                suspended_win: action.payload
            }
        case types.SET_DEFLEET_CAPTUREQUOTE_VALIDATION_VALUE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.prop]: {
                        ...state.fields[action.payload.prop],
                        data: action.payload.value,
                        valid: action.payload.value ? true : false
                    }
                }
            }
            case types.SET_DEFLEET_SUPPLIER_MONTHLY_SPEND_USAGE:
            return {
                ...state,
                suppliermonthlyusage: action.payload
            }
        case types.SET_DEFLEET_SUPPLIER_MONTHLY_OVER_THE_LIMIT:
            return {
                ...state,
                overthelimit: {
                    ...state.overthelimit,
                    value: action.payload,
                    valid: action.payload
                }
            }
        case types.TOGGLE_DEFLEET_UPCOMING_SUPPLIER_SUSPENDED_STATUS:
            return {
                ...state,
                upcoming: {
                    upcoming: action.payload.upcoming,
                    tax: action.payload.tax,
                    bee: action.payload.bee,
                    sla: action.payload.sla
                }
            }
        case types.SELECT_PURCHASE_ORDER_TYPE:
            return {
                ...state,
                selectedPurchaseOrderType: action.payload,
                supplierAutoCompletes: [{
                    id: 0,
                    data: [],
                    selected: []
                }]
            }
        case types.SET_USER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                users: action.payload
            }
        case types.SET_USER_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    data: action.payload,
                    valid: true
                }
            }
        case types.SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                vehicles: action.payload
            }
        case types.SET_VEHICLE_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                selectedVehicle: action.payload
            }
        case types.SET_COMPONENT_RECENT_MAINTENANCE_AUTHS:
            return {
                ...state,
                recent_auth: action.payload.data
            }
        case types.SET_SELECTED_VEHICLE_DETAILS:
            return {
                ...state,
                selectedVehicleDetails: {
                    ...state.selectedVehicleDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_DEFLEET_SELECTED_SUPPLIER_DETAILS:
            return {
                ...state,
                selectedSupplierDetails: {
                    ...state.selectedSupplierDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_DEFLEET_SUSPENDED_SUPPLIER_DETAILS:
            return {
                ...state,
                supplier: action.payload.supplier,
                selectedSupplierSuspended: {
                    ...state.selectedSupplierSuspended,
                    data: action.payload.data,
                    valid: action.payload.status,
                    suspendedreason: action.payload.suspendedreason
                }
            }
        case types.SET_GEO_MAP_SUPPLIERS:
            return {
                ...state,
                geoSuppliers: action.payload
            }
        case types.SET_VEHICLE_SERVICE_INFORMATION:
            return {
                ...state,
                vehicleserviceinfo: action.payload
            }
        case types.SET_SUPPLIER_AGE:
            return {
                ...state,
                supplierage: action.payload
            }
        case types.SET_PRE_AUTHORISATION_NUMBER:
            return {
                ...state,
                preAuth: action.payload
            }
        case types.SET_VEHICLE_CURRENT_BOOKING_INFO:
            return {
                ...state,
                bookinginfo: action.payload.bookinginfo
            }
        /*  case types.SET_SUPPLIER_AUTOCOMPLETE_SELECTED:
             return {
                 ...state,
                 supplierAutoCompletes: state.supplierAutoCompletes.map(x => {
                     if (x.id === action.payload.id) {
                         x.selected = action.payload.request
                     }
 
                     return x
                 })
             } */
       /*  case types.SET_SUPPLIER_AUTOCOMPLETE_SELECTED:
            return {
                ...state,
                selectedSupplier: {
                    ...state.selectedSupplier,
                    data: action.payload,
                    valid: action.payload ? true : false
                }
            } */
        /* case types.SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                supplierAutoCompletes: state.supplierAutoCompletes.map(x => {
                    if (x.id === action.payload.id) {
                        x.data = action.payload.data
                    }

                    return x
                })
            } */

        case types.SET_CAPTUREQUOTE_SUPPLIERS:
            return {
                ...state,
                suppliers: action.payload.data
            }
        case types.ADD_SUPPLIER_AUTOCOMPLETE:
            return {
                ...state,
                supplierAutoCompletes: state.supplierAutoCompletes.concat({
                    id: state.supplierAutoCompletes.length,
                    data: [],
                    selected: []
                })
            }
        case types.REMOVE_SUPPLIER_AUTOCOMPLETE:
            return {
                ...state,
                supplierAutoCompletes: action.payload > 0
                    ? state.supplierAutoCompletes.filter(x => x.id !== action.payload)
                    : state.supplierAutoCompletes
            }

        case types.SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT:
            return {
                ...state,
                maintpdf_preview: action.payload.preview,
                maintpdf_filename: action.payload.filename,
            }
        case types.SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUES:
            return {
                ...state,
                [action.payload.prop]: {
                    ...state[action.payload.prop],
                    value: action.payload.value,
                    valid: action.payload.value ? true : false
                }
            }
        default:
            return state
    }
}