import * as props from './props'
import * as types from './types'
import * as config from '../../config'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_CONNECTOR:
            return {
                ...action.payload,
                ui: config.system.version
            }
        case types.CLEAR_CONNECTOR_STATE:
            return {
                ...props
            }
        default:
            return state
    }
}