
import moment from 'moment'

import * as types from './types'
import * as appTypes from '../../App/types'

export const reset = () => {
    return (dispatch) => {       
        dispatch({
            type: types.RESET_COMPONENT,
        })       
    }
}
export const getEnterestData = () => {
    //console.log('setValuex enterest_review_data')
    return (dispatch) => {       
        dispatch(setValue('refreshing',false))
        dispatch({
            type: types.GET_ENTEREST_DATA_REVIEW,
            payload: {
                collection: 'enterest_review_data'
            }
        })
    }
}

export const setValue = (prop, val) => {
    //console.log('setValuex',prop,val)
    return (dispatch) => {       
        dispatch({
            type: types.SET_STATEMENT_REVIEW_INPUT_VALUE,
            payload: {prop:prop,val:val}
        })
    }
}

export const downlaodEnterest = () => {
    return (dispatch, getState) => {       
        var data = getState().enterestReview.data
        dispatch({
            type: types.EXPORT_INTEREST_EXCEL_DATA,
            payload: {
                type: 'EXPORT_INTEREST_EXCEL_DATA',
                data: data
            }
        })
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Preparing interest export',
                overflowText: 'Preparing interest export',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const downloadEnterest = (data) => {
    return (dispatch, getState) => {   
        

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf], data.data.key)


        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Downloading enterest export',
                overflowText: 'Downloading enterest export',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}