import * as types from './types'

export const getWorkflowDocuments = (searchable) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_WORKFLOW_DOCUMENTS_LIST,
            payload: searchable
        })
    }
}

export const getWorkflowVehicleList = (searchable) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_WORKFLOW_DOCUMENT_VEHICLE_LIST,
            payload: searchable
        })
    }
}


export const downloadList = () => {
    return (dispatch) => {
        dispatch({
            type: types.GET_WORKFLOW_DOCUMENT_FROM_DOWNLAOD_TABLE,
            payload: {
                type: 'GET_WORKFLOW_DOCUMENT_FROM_DOWNLAOD_TABLE'
            }
        })
    }
}
export const setWorkflowVehicleList = (response) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKFLOW_DOCUMENT_VEHICLE_LIST,
            payload: response.data
        })
    }
}

export const setWorkflowDocuments = (response) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKFLOW_DOCUMENTS_LIST,
            payload: response
        })
    }
}

export const downloadDocument = (file) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.WORKFLOW_DOWNLOAD_DOCUMENTS,
            payload: {
                type: 'WORKFLOW_DOWNLOAD_DOCUMENTS',
                key: 'quotes/' + file
            }
        })
    }
}


export const viewDocument = (data) => {
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        dispatch({
            type: types.VIEW_DOCUMENT,
            payload: {
                url: data.data.document.key, //getFile(data),
                type: data.data.document.extension
            }
        })
    }
}
// export const getWorkflowVehicleHistory = (id) => {
//     return (dispatch) => {
//         dispatch({
//             type: types.GET_WORKFLOW_VEHICLE_HISTORY,
//             payload: id
//         })
//     }
// }

// export const setWorkflowVehicleHistory = (response) => {
//     return (dispatch) => {
//         dispatch({
//             type: types.SET_WORKFLOW_VEHICLE_HISTORY,
//             payload: response.data
//         })
//     }
// }

export const toggleWorkflowDocuments = (name, status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_WORKFLOW_DOCUMENTS,
            payload: {
                name: name,
                status: status
            }
        })
        
        dispatch({
            type: types.RESET_WORKFLOW_DOCUMENTS,
        })
    }
}

export const showWorkflowDocuments = (name) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SHOW_WORKFLOW_DOCUMENTS,
            payload: {
                name: name
            }
        })

        // if (getState().workflowTimeline[name].isOpen) {
        //     dispatch(getWorkflowTimeline(name))
        // }
    }
}

export const closeWorkflowVehicleHistory = (name) => {
    return (dispatch) => {
        dispatch({
            type: types.CLOSE_WORKFLOW_DOCUMENTS,
            payload: {
                name: name
            }
        })
    }
}