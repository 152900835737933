import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'




import * as actions from './actions'
import * as styles from './styles'
import * as compActions from '../../Components/actions'
var viewinvoice = false,id = 0

class CUSTOMERCREDITNOTE extends Component {
    componentDidMount() {
        if(this.props.state.loading) this.props.actions.getComponent('customercreditnote')
    }
    addNewCreditNote() {
        this.props.actions.setLookupValue("reference", '', '')
        this.props.actions.setItems([{ description: '', unit: '', quantity: 0, unitprice: 0, amount: 0, vat: 0, total: 0, key: 1, account_id: 0 }])
        this.props.actions.setInputValue('customer_id', '')
        this.props.actions.setInputValue('customername', '') 
        this.props.actions.setInputValue('customertransactiondate', new Date())
        var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')                                                          
        this.props.actions.setInputValue('customertransactionmonth', _date)
        this.props.actions.setInputValue('customerreference', '')
        this.props.actions.setInputValue('description', '')
        this.props.actions.setInputValue('business_id', '')
        this.props.actions.setInputValue('businessname', '')// fktext removed 
        this.updateTotals()
        viewinvoice = false
    }
    updateTotals() {
        this.props.actions.setInputValue('vat', this.calculateTotals().vat)
        this.props.actions.setInputValue('debit', this.calculateTotals().totalamount)
        this.props.actions.setInputValue('nettvalue', this.calculateTotals().amount)
        this.props.actions.setInputValue('amount', this.calculateTotals().totalamount)          
    }
    calculateTotals() {
        var data = this.props.state.data.customertransaction_id.data
        var vattable = 0;
        var nonvattable = 0;
        var vatamount = 0;
        data.map((x) => {
            if(Number(x.vat) > 0){ vattable += Number(x.amount) } else { nonvattable += Number(x.amount) }
            vatamount = Number(x.vat) > 0 ? vatamount += Number(x.vat) : vatamount
        });

        var amount = vattable + nonvattable;
        return {
            vatincl: vattable,
            vatexcl: nonvattable,
            totalamount: Number(amount) + Number(vatamount),
            vat: vatamount,
            amount: Number(amount)
        }
    }

    getView() {
        return <Card style={styles.card} style={styles.row}>
            <CardHeader
                title={window.t.en('Customer Credit Note ')}
                subtitle={window.t.en('Capture customer credit note')}
                actAsExpander={true}
                showExpandableButton={true}
            />
            <CardContent>
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={6}>
                        </Col>
                        <Col xl={6}>  {viewinvoice ?< Button className={"global_button"} variant="contained" header={false}
                                primary={true}
                                label={'Download PDF'}
                                onClick={() => {
                                    this.props.actions.getCreditNotePDF(id)
                                }}
                                >{window.t.en('Download PDF')}</Button> :''}
                           < Button className={"global_button"} variant="contained" header={false}
                                primary={true}
                                label={'New Credit Note'}
                                onClick={() => {
                                    this.addNewCreditNote()
                                }}
                                >{window.t.en('xNew Credit Notexxxxx')}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}><strong>Credit Note:</strong></div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        id={'dll_creditnote_id'}
                                        key={'dll_creditnote_id'}
                                        hintText={'Search by reference number...'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        dataSource={this.props.state.data.reference.data}
                                        onNewRequest={
                                            (chosenRequest) => {
                                                this.props.actions.setLookupValue("reference", chosenRequest.value, chosenRequest.text)

                                                if (chosenRequest.value) {
                                                    viewinvoice = true;
                                                    id = chosenRequest.id
                                                    this.props.actions.setInputValue('customer_id', chosenRequest.customer_id)
                                                    this.props.actions.setInputValue('customername', chosenRequest.customer_text)// fktext removed 
                                                    this.props.actions.setInputValue('customertransactiondate', new Date(chosenRequest.customertransactiondate))
                                                    this.props.actions.setInputValue('customertransactionmonth', chosenRequest.customertransactionmonth)
                                                    this.props.actions.setInputValue('customerreference', chosenRequest.customerreference)
                                                    this.props.actions.setInputValue('description', chosenRequest.description)
                                                    this.props.actions.setInputValue('business_id', chosenRequest.business_id)
                                                    this.props.actions.setInputValue('businessname', chosenRequest.business_text)  
                                                    if (chosenRequest.customertransactionitem) {
                                                        this.props.actions.setItems(chosenRequest.customertransactionitem)
                                                    }
                                                    else {
                                                        this.addNewCreditNote()
                                                    }
                                                }
                                            }
                                        }
                                        onClick={
                                            () => {
                                                this.props.actions.getAllCreditNotes('')
                                            }
                                        }
                                        onUpdateInput={
                                            (search) => {
                                                this.props.actions.getAllCreditNotes(search)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Debtor:'}</div>
                                <div style={styles.fieldContent}>
                                    {
                                        viewinvoice ?
                                            <TextField
                                                disabled={true}
                                                id={'txt_customername'}
                                                hintText='debtor'
                                                value={this.props.state.data.customer.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('customername', value)
                                                }}
                                            /> :
                                            <AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_debtor_id'}
                                                key={'dll_debtor_id'}
                                                hintText={'Select a debtor...'}
                                                filter={AutoComplete.fuzzyFilter}                                                                                                       
                                                errorText={this.props.state.data.customer_id.input ? null : '*'}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.customer_id.data}
                                                onNewRequest={
                                                    (chosenRequest) => {
                                                        var _date = moment(new Date()).format('YYYYMM')//format('MMMM YYYY')  
                                                        this.props.actions.setLookupValue("customer_id", chosenRequest.value, chosenRequest.text)
                                                        this.props.actions.setInputValue('description', _date)
                                                        this.props.actions.setInputValue('customertransactiondate', new Date())
                                                        this.props.actions.setInputValue('customertransactionmonth', _date)
                                                        this.props.actions.setItems([{ description: '', unit: '', quantity: 0, unitprice: 0, amount: 0, vat: 0, total: 0, key: 1, account_id: 0 }])
                                                    }
                                                }
                                                onClick={
                                                    () => {
                                                        this.props.actions.getDebtorsListData('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search) => {
                                                        this.props.actions.getDebtorsListData(search)
                                                    }
                                                }
                                            />
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Date:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                                type="date"
                                        disabled={viewinvoice}
                                        id={'txt_creditnotedate'}
                                        hintText='Date'
                                        container='inline'
                                        mode='landscape'                                                                                                       
                                        errorText={this.props.state.data.customertransactiondate.input ? null : '*'}
                                        value={this.props.state.data.customertransactiondate.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('customertransactiondate', value)                                            
                                            var _date = moment(new Date(value)).format('YYYYMM')  
                                            this.props.actions.setInputValue('customertransactionmonth', _date)
                                        }}
                                    />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Month:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                        /* disabled={true} */
                                        id={'txt_customertransactionmonth'}
                                        hintText='Month'                                          
                                        errorText={this.props.state.data.customertransactionmonth.input ? null : '*'}
                                        value={this.props.state.data.customertransactionmonth.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('customertransactionmonth', value)
                                        }}
                                    />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Our Reference:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                        disabled={viewinvoice}
                                        id={'txtreference'}
                                        hintText='Reference'                                        
                                        errorText={this.props.state.data.reference.input ? null : '*'}
                                        value={this.props.state.data.reference.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('reference', value)
                                            this.props.actions.getDuplicateReferenceCheck(value)
                                            //this.props.actions.checkExistingInvoice(this.props.state.data.invoicereference.input)
                                        }}
                                    />}
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Customer Reference:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                        disabled={viewinvoice}
                                        id={'txtcustomerreference'}
                                        hintText='Customer Reference'
                                        value={this.props.state.data.customerreference.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('customerreference', value)
                                        }}
                                    />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Description:'}</div>
                                <div style={styles.fieldContent}>
                                    {<TextField
                                        disabled={viewinvoice}
                                        id={'txtdescription'}
                                        errorText={this.props.state.data.description.input ? null : '*'}
                                        value={this.props.state.data.description.input}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue('description', value)
                                        }}
                                    />}
                                </div>
                            </div>
                        </Col>
                    </Row>
       {/*              <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Branch:'}</div>
                                <div style={styles.fieldContent}>
                                    {
                                        viewinvoice ?
                                            <TextField
                                                disabled={true}
                                                id={'txt_business_fktext'}
                                                hintText='Branch'
                                                value={this.props.state.data.business_fktext.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('business_fktext', value)
                                                }}
                                            /> :
                                            <AutoComplete
                                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                id={'dll_business_id'}
                                                key={'dll_business_id'}
                                                hintText={'Select a branch...'}
                                                filter={AutoComplete.fuzzyFilter}
                                                openOnFocus={true}
                                                fullWidth={true}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                dataSource={this.props.state.data.business_id.data}
                                                onNewRequest={
                                                    (chosenRequest, index) => {
                                                        this.props.actions.setLookupValue("business_id", chosenRequest.value, chosenRequest.text)
                                                        this.props.actions.setLookupValue("business_fktext", chosenRequest.value, chosenRequest.text)
                                                    }
                                                }
                                                onClick={
                                                    (event) => {
                                                        this.props.actions.getBusinessListData('')
                                                    }
                                                }
                                                onUpdateInput={
                                                    (search, dataSource, params) => {
                                                        this.props.actions.getBusinessListData(search)
                                                    }
                                                }
                                            />
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row> */}

                </Container>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <CardHeader
                                title={window.t.en('Items')}
                                subtitle={window.t.en('Capture items')}
                                actAsExpander={true}
                                showExpandableButton={true}
                            />
                        </Col>
                    </Row>
                </Container>
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={12}><br></br></Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <Row>
                                <Col xl={7}>
                                    <div style={styles.fieldContainer}>
                                        <div><strong>{'Description'}</strong></div>
                                    </div>
                                </Col>
                                <Col xl={5}><div style={styles.fieldContainer}>
                                    <div><strong>{'Unit'}</strong></div>
                                </div>
                                </Col>

                            </Row>
                        </Col>
                        <Col xl={4}>
                            <Row>
                                <Col xl={3}><div style={styles.fieldContainer}>
                                    <div><strong>{'Quantity'}</strong></div>
                                </div>
                                </Col>
                                <Col xl={3}><div style={styles.fieldContainer}>
                                    <div><strong>{'Unit Price'}</strong></div>
                                </div>
                                </Col>
                                <Col xl={2}><div style={styles.fieldContainer}>
                                    <div><strong>{'VAT'}</strong></div>
                                </div>
                                </Col>
                                <Col xl={4}><div style={styles.fieldContainer}>
                                    <div><strong>{'Total'}</strong></div>
                                </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={4}>
                            <Row>
                                <Col xl={8}><div style={styles.fieldContainer}>
                                    <div><strong>{'Contra Account'}</strong></div>
                                </div>
                                </Col>
                                <Col xl={3}><strong>{''}</strong></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br></br></Col>
                    </Row>
                    {
                        this.props.state.data.customertransaction_id.data
                            .map((x) => {
                                return <Row>
                                    <Col xl={4}>
                                        <Row>
                                            <Col xl={7}>
                                                <div style={styles.fieldContent}>
                                                    {<TextField
                                                        disabled={viewinvoice}
                                                        id={'txtitemdescription'}
                                                        hintText='Description'
                                                        value={x.description}                                                        
                                                        errorText={x.description ? null : '*'}
                                                        onChange={(event, value) => {
                                                            this.props.actions.setItemValue({ inx: x.key - 1, field: 'description', value: value })
                                                        }}
                                                    />}
                                                </div>
                                            </Col>
                                            <Col xl={5}> <div style={styles.fieldContent}>
                                                {viewinvoice ? <TextField
                                                    disabled={viewinvoice}
                                                    id={'txt_itemunit'}
                                                    hintText='Unit...'
                                                    value={x.unit_text}
                                                    onChange={() => {
                                                    }}
                                                /> : <AutoComplete
                                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        id={'dll_itemunit'}
                                                        key={'dll_itemunit'}
                                                        hintText={'Unit...'}
                                                        filter={AutoComplete.fuzzyFilter}                                                                                              
                                                        errorText={x.unit ? null : '*'}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.data.description.data}
                                                        onNewRequest={
                                                            (chosenRequest) => {
                                                                this.props.actions.setItemValue({ inx: x.key - 1, field: 'unit', value: chosenRequest.value })
                                                                this.props.actions.setItemValue({ inx: x.key - 1, field: 'unit_text', value: chosenRequest.text })
                                                            }
                                                        }
                                                        onClick={
                                                            () => {
                                                                this.props.actions.getUnitMeasureListData('')
                                                            }
                                                        }
                                                        onUpdateInput={
                                                            (search) => {
                                                                this.props.actions.getUnitMeasureListData(search)
                                                            }
                                                        }
                                                    />}
                                            </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl={4}>
                                        <Row>
                                            <Col xl={3}> <div style={styles.fieldContent}>
                                                {<TextField
                                                    disabled={viewinvoice}
                                                    id={'txtitemquantity'}
                                                    hintText='Quantity'                                                                                          
                                                    errorText={x.quantity ? null : '*'}
                                                    value={x.quantity}
                                                    onChange={(event, value) => {
                                                        var unitprice = Number(x.unitprice)
                                                        var amount = unitprice ? Number(unitprice) * Number(value) : 0
                                                        var vat = x.vat ? Number(amount) * 0.15 : 0
                                                        var total = amount
                                                        var vattable = vat ? true : false

                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'unitprice', value: unitprice })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'quantity', value: value })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vat', value: vat })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'amount', value: total })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vattable', value: vattable })
                                                        this.updateTotals()
                                                    }}
                                                />}
                                            </div>
                                            </Col>
                                            <Col xl={3}> <div style={styles.fieldContent}>
                                                {<TextField
                                                    disabled={viewinvoice}
                                                    id={'txtitemunitprice'}
                                                    hintText='Unit Price'                                                                                          
                                                    errorText={x.unitprice ? null : '*'}
                                                    value={x.unitprice}
                                                    onChange={(event, value) => {
                                                        var unitprice = value
                                                        var amount = Number(unitprice) * Number(x.quantity)
                                                        var vat = x.vat ? Number(amount) * 0.15 : 0
                                                        var total = amount
                                                        var vattable = vat ? true : false

                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'unitprice', value: unitprice })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'quantity', value: x.quantity })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vat', value: vat })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'amount', value: total })                                                        
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vattable', value: vattable })
                                                        this.updateTotals()
                                                    }}
                                                />}
                                            </div>
                                            </Col>
                                            <Col xl={2}> <div style={styles.fieldContent}>
                                                {<Checkbox
                                                    disabled={viewinvoice}
                                                    checked={x.vat ? true : false}
                                                    onClick={() => {
                                                        var unitprice = Number(x.unitprice)
                                                        var amount = Number(unitprice) * Number(x.quantity)
                                                        var vat = x.vat ? 0 : Number(x.amount) * 0.15
                                                        var total = Number(amount)
                                                        var vattable = vat ? true : false
                                                        
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'unitprice', value: unitprice })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'quantity', value: x.quantity })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vat', value: vat })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'amount', value: total })                                                 
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vattable', value: vattable })
                                                        this.updateTotals()

                                                    }} />}
                                            </div>
                                            </Col>
                                            <Col xl={4}> <div style={styles.fieldContent}>
                                                {<TextField
                                                    disabled={true}
                                                    id={'txtitemtotal'}
                                                    hintText='Total'
                                                    value={x.amount}
                                                    onChange={() => {
                                                    }}
                                                />}
                                            </div>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col xl={4}>
                                        <Row>
                                            <Col style={styles.hidden}> <div >
                                                {<TextField
                                                    disabled={true}
                                                    id={'txtitemvat'}
                                                    hintText='VAT'
                                                    value={x.vat}
                                                    onChange={(event, value) => {
                                                        var unitprice = Number(x.unitprice)
                                                        var amount = Number(unitprice) * Number(x.quantity)
                                                        var vat = Number(value)
                                                        var total = Number(amount)
                                                        var vattable = vat ? true : false

                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'unitprice', value: unitprice })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'quantity', value: x.quantity })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vat', value: vat })
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'amount', value: total })                                                 
                                                        this.props.actions.setItemValue({ inx: x.key - 1, field: 'vattable', value: vattable })
                                                        this.updateTotals()
                                                    }}
                                                />}
                                            </div>
                                            </Col>
                                            <Col xl={8}> <div style={styles.fieldContent}>
                                                {viewinvoice ? <TextField
                                                    disabled={viewinvoice}
                                                    id={'txt_itemaccount'}
                                                    hintText='Contra Account'
                                                    value={x.account_text}
                                                    onChange={() => {
                                                    }}
                                                /> :
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        id={'dll_itemaccount'}
                                                        key={'dll_itemaccount'}
                                                        hintText={'Account...'}
                                                        filter={AutoComplete.fuzzyFilter}                                                                                               
                                                        errorText={x.account_id ? null : '*'}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.data.accountingitem_id.data}
                                                        onNewRequest={
                                                            (chosenRequest) => {
                                                                this.props.actions.setItemValue({ inx: x.key - 1, field: 'account_id', value: chosenRequest.value })
                                                                this.props.actions.setItemValue({ inx: x.key - 1, field: 'account_text', value: chosenRequest.text })
                                                            }
                                                        }
                                                        onClick={
                                                            () => {
                                                                this.props.actions.getContractAccountListData('')
                                                            }
                                                        }
                                                        onUpdateInput={
                                                            (search) => {
                                                                this.props.actions.getContractAccountListData(search)
                                                            }
                                                        }
                                                    />}
                                            </div>
                                            </Col>
                                            <Col xl={3}>
                                                {
                                                    this.props.state.data.customertransaction_id.data.length > 1 ?
                                                       < Button className={"global_button"} variant="contained"
                                                            disabled={viewinvoice}
                                                            label={'Remove'}
                                                            primary={true}
                                                            onClick={() => {
                                                                this.props.actions.removeRow(x.key - 1)
                                                            }}
                                                            >{window.t.en('Remove')}</Button>
                                                        : ''
                                                }

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            })
                    }
                    <Row>
                        <Col xl={7}>
                        </Col>
                        <Col xl={5}>
                            <Row>
                                <Col xl={9}></Col><Col xl={3}>< Button className={"global_button"}
                                    disabled={viewinvoice}
                                    label={'Add Item'}
                                    primary={true}
                                    onClick={() => {
                                        this.props.actions.addBlankRow()
                                    }}
                                    >{window.t.en('Add Item')}</Button></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Row>
                    <Col xl={12}><br></br></Col>
                </Row>
                <Row>                        <Col xl={6}> </Col>
                    <Col xl={6} style={styles.rightpanel}>
                        <Row><Col xl={6}><br></br></Col></Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'Sub-Total (Vattable):'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txtcredit'}
                                            hintText='Sub-total (Vattable)'
                                            value={this.calculateTotals().vatincl}
                                            onChange={() => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'VAT:'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txtvat'}
                                            hintText='VAT'
                                            value={this.calculateTotals().vat}
                                            onChange={() => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'Sub-Total (Non-Vattable):'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txtnettvalue'}
                                            hintText='Sub-total (Non-Vattable)'
                                            value={this.calculateTotals().vatexcl}
                                            onChange={() => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}><strong>{'Total:'}</strong></div>
                                    <div style={styles.fieldContent}>
                                        {<TextField
                                            disabled={true}
                                            id={'txttotal'}
                                            hintText='Total'
                                            value={this.calculateTotals().totalamount}
                                            onChange={() => {
                                            }}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={10}><Upload
                            disabled={viewinvoice}
                            id={'upload_ctl'}
                            prefix={'inv'}
                            header={false}
                        /></Col>
                        <Col xl={2}>
                            <Row>

                                <Col xl={12}>
                                   < Button className={"global_button"} variant="contained" header={false}
                                        disabled={viewinvoice}
                                        label={'Save Credit Note'}
                                        onClick={() => {
                                            var validationfields =
                                            [
                                                {
                                                    value: this.props.state.data.customer_id.input,
                                                    field: "Debtor"
                                                },
                                                {
                                                    value: this.props.state.data.description.input,
                                                    field: "Description"
                                                },
                                                {
                                                    value: this.props.state.data.reference.input,
                                                    field: "Reference"
                                                }
                                            ]
                                            if (this.props.state.data.reference.text === true) {
                                                     var payload = {
                                                        data: this.props.state.data,
                                                        documents: this.props.state.upload.documents,
                                                        user:  this.props.state.user
                                                }
                                                this.props.actions.saveData(payload, validationfields,this.props.state.data.customertransaction_id.data)                                                
                                            }
                                            if (this.props.state.data.reference.text === false) {
                                                this.props.actions.foundDuplicateReference()
                                            } 
                                        }}
                                        >{window.t.en('Save Credit Note')}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.customercreditnote,
            upload: {
                ...state.upload
            }
            ,user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions ,...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERCREDITNOTE)