import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
import Card from '../../../controls/card'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Icon from '../../Icon/component'
import PdfViewer from '../../PDF/component'
import IconButton from '@mui/material/IconButton';
import "react-table/react-table.css"
import * as compActions from '../../Components/actions'

import * as actions from './actions'
import * as styles from './styles'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import { AppBar, Tabs, Tab } from '@mui/material'

let approval_list = []

class BILLINGMODULE extends Component {
    componentDidMount() {
            this.props.actions.setDefaultTab()
        if (this.props.state.loading) {
            this.props.actions.getComponent('billingmodule')
        }
    }
    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }
    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }
    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black', //'#4A4A4A',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        }

        return columns
    }

    getBillingGrid(type) {
        return <div> 
             <Tabs value={this.props.state.invoices.currentTab}
                    onChange={
                        (event, value) => {
                            this.props.actions.setCurrentTab(value)
                        }
                    }>
                    <Tab  style={{ width: '100%' }} label={'Check Billing'} id={'Check Billing'} />
                    <Tab style={{ width: '100%' }} label={'Approved Billing'} id={'Approved Billing'} />
                    <br></br>
                </Tabs>
                {this.props.state.invoices.currentTab ===0 && this.getBillingCheck(type)} 
                {this.props.state.invoices.currentTab ===1 && this.getApprovedBilling(type)}
        </div>
    }
    getApprovedBilling(type)
    {
        return <Container style={{ margin: 0 }} className="custom-container">
            <br></br>
                        <Row>
                            <Col xl={4}>
                            </Col>
                            <Col xl={1}>
                                <b>Select All: </b><Checkbox
                                    checked={this.props.state.invoices.approved_selectedall}
                                    onClick={(event) => {
                                        var flag = this.props.state.invoices.approved_selectedall ? false : true
                                        this.props.actions.approved_selectall(flag)
                                    }} />
                            </Col>
                            <Col xl={1}>
                                <b>Link: </b><Checkbox
                                    checked={this.props.state.invoices.linkinvoices}
                                    onClick={(event) => {
                                        var flag = this.props.state.invoices.linkinvoices ? false : true
                                        this.props.actions.linkinvoices(flag)
                                    }} />
                            </Col>
                            <Col xl={1}>
                                <b>Send: </b><Checkbox
                                    checked={this.props.state.invoices.sendinvoices}
                                    onClick={(event) => {
                                        var flag = this.props.state.invoices.sendinvoices ? false : true
                                        this.props.actions.sendinvoices(flag)
                                    }} />
                            </Col>
                            <Col xl={2}>
                                <b>Send to this email as well: </b><TextField
                                    id={'txtreference'}
                                    hintText='Custom email...'
                                    value={this.props.state.invoices.customemail}
                                    onChange={(event, value) => {
                                        this.props.actions.customemail(value)
                                    }}
                                />
                            </Col>
                            <Col xl={1}>
                                <b>Dummy Run: </b><Checkbox
                                    checked={this.props.state.invoices.dummy_run}
                                    onClick={(event) => {
                                        var flag = this.props.state.invoices.dummy_run ? false : true
                                        this.props.actions.dummyrun(flag)
                                    }} />
                            </Col>
                            <Col xl={2}>
                           < Button className={"global_button"} variant="contained"
                                    primary={true}
                                    key={type}                                    
                                    disabled={approval_list.length || this.props.state.adhoc["billing_module_approved_" + type] ? false : true}
                                    label="CANCEL"
                                    onClick={(event) => {                                        
                                        this.props.actions.setCancelBillingList(type, [])
                                    }}
                                    >{window.t.en('CANCEL')}</Button>
                                {'   '}
                               < Button className={"global_button"} variant="contained"
                                    primary={false}
                                    disabled={approval_list.length || this.props.state.adhoc["billing_module_approved_" + type] ? false : true}
                                    key={type}
                                    label="GENERATE"
                                    onClick={(event) => {
                                        var userdata = this.props.state.user
                                        this.props.actions.generateBilling(type, approval_list, this.props.state.invoices.approved_selectedall, userdata)
                                    }}
                                    >{window.t.en('GENERATE')}</Button>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <AdhocGrid
                                key={'key_adhoc_grid_billing_module_approved_' + type}
                                name={'billing_module_approved_' + type}
                                uri={'/components/billingmodule/other'}
                                filterable={true}
                                sortable={true}
                                body={{
                                    type: type,
                                    approved: true,
                                    list: [],
                                    searchText: ''
                                }}
                                search={''}
                                columns={[
                                    { accessor: 'licenseplateno', Header: 'Vehicle', width: 100, filterable: true }, 
                                    { accessor: 'customername', Header: 'Customer', width: 350, filterable: true },// fktext removed 
                                    { accessor: 'description', Header: 'Description', width: 200, filterable: true },
                                    { accessor: 'customerreference', Header: 'Customer Reference', width: 150, filterable: true },
                                    { accessor: 'invoicenumber', Header: 'Invoice #', width: 100, filterable: true },
                                    { accessor: 'nettvalue', Header: 'Nett Value', width: 100, filterable: true },
                                    { accessor: 'vat', Header: 'VAT', width: 100, filterable: true },
                                    { accessor: 'debit', Header: 'Debit', width: 100, filterable: true },
                                    { accessor: 'credit', Header: 'Credit', width: 100, filterable: true },
                                     {
                                        accessor: 'allocated', Header: 'Select', filterable: false, width: 50, Cell: row => {
                                            return (
                                                <div>
                                                    <Checkbox
                                                        checked={(row.original['allocated'] || this.props.state.invoices.approved_selectedall) ? true : false}
                                                        onClick={(event) => {
                                                            var flag = (row.original['allocated'] || this.props.state.invoices.approved_selectedall) ? false : true
                                                            row.original['allocated'] = flag
                                                            this.props.actions.approved_selectall(false)
                                                        }} />
                                                </div>
                                            )
                                        }
                                    }, 
                                    {
                                        accessor: 'invoicenumber', Header: 'Document', filterable: false, width: 150, Cell: row => {
                                            return (
                                                <div>
                                                   < Button className={"global_button"} variant="contained"
                                                        primary={true}
                                                        label="Preview"
                                                        icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                                                        onClick={(event) => {
                                                            this.props.actions.previewinvoice(row.original['invoicenumber'])
                                                        }}
                                                        >{window.t.en('Preview')}</Button>
                                                </div>
                                            )
                                        }
                                    },
                                ]}
                                paging={{
                                    page: 0,
                                    pageSize: 10,
                                    pages: 0,
                                    count: 0
                                }}
                                subComponent={row => {
                                    return <div key={'grid_billing_module_data_approved_' + row.original['customertransaction_id']} style={{ padding: "2px" }}>
                                        <Card   title={window.t.en('Billing Items')}
                                                subtitle={window.t.en('List of billing items')}
                                                content={<Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                                    <Row><Col xl={12}><br /></Col>
                                                    </Row>
                                                    <Row style={styles.tableheader}>
                                                        <Col xl={2}>{'Description'}</Col>
                                                        <Col xl={3}>{'Account'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Quantity'}</Col>
                                                        <Col xl={1}>{'Unit'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Unit Price'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'VAT'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Total'}</Col>
                                                    </Row>
                                                    {
                                                        row.original['items']
                                                            ? row.original['items']
                                                                .map((x, i) => {
                                                                    return <div>
                                                                        <Row style={styles.tablerow}>
                                                                            <Col xl={2}>{x.description}</Col>
                                                                            <Col xl={3}>{x.itemname}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.quantity.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col xl={1}>{x.unitmeasure}</Col> 
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.unitprice.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{(parseFloat(x.totalamount)).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.vat.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat((Number(x.totalamount) + Number(x.vat)).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                        </Row>
                                                                    </div>
                                                                })
                                                            : <div>{'No Items Found...'}</div>
                                                    }
                                                    <Row style={styles.tablerow}>
                                                        <Col xl={11}>{''}</Col>
                                                        <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['items'], (d) => { return parseFloat(Number(d.totalamount) + Number(d.vat)) }).toFixed(2)}</Col>
                                                    </Row>
                                                    <Row><Col xl={12}><br /></Col>
                                                    </Row>
                                                </Container>
                                           }/>
                                        <br />
                                        <Card 
                                                title={window.t.en('Billing History')}
                                                subtitle={window.t.en('List of billing')}
                                                content={
                                        <Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                                    <Row><Col xl={12}><br /></Col>
                                                    </Row>
                                                    <Row style={styles.tableheader}>
                                                        <Col xl={1}>{'Billing Date'}</Col>
                                                        <Col xl={2}>{'Description'}</Col>
                                                        <Col xl={2}>{'Business Unit '}</Col>
                                                        <Col xl={1}>{'Start Date'}</Col>
                                                        <Col xl={1}>{'End Date'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                                                        <Col xl={2}>{'Transaction Type'}</Col>
                                                        <Col xl={2}>{'Invoice #'}</Col>
                                                    </Row>
                                                    {
                                                        row.original['history']
                                                            ? row.original['history']
                                                                .map((x, i) => {
                                                                    return <div>
                                                                        <Row style={styles.tablerow}>
                                                                            <Col xl={1}>{moment(x.customertransactiondate).format('YYYY-MM-DD')}</Col>
                                                                            <Col xl={2}>{x.description}</Col>
                                                                            <Col xl={2}>{x.businessunit}</Col>
                                                                            <Col xl={1}>{moment(x.startdate).format('YYYY-MM-DD')}</Col>
                                                                            <Col xl={1}>{moment(x.enddate).format('YYYY-MM-DD')}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.amount.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col xl={2}>{x.transactiontype}</Col>
                                                                            <Col xl={2}>{x.invoicenumber}</Col>
                                                                        </Row>
                                                                    </div>
                                                                })
                                                            : <div>{'No Items Found...'}</div>
                                                    }
                                                    <Row style={styles.tablerow}>
                                                        <Col xl={11}>{''}</Col>
                                                        <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['history'], (d) => { return parseFloat(Number(d.amount)) }).toFixed(2)}</Col>
                                                    </Row>
                                                    <Row><Col xl={12}><br /></Col>
                                                    </Row>
                                                </Container>
                                        } />
                                        <br />
                                        <Card title={window.t.en('Contract History')}
                                                subtitle={window.t.en('List of Contracts')}
                                                content={<Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                                    <Row><Col xl={12}><br /></Col>
                                                    </Row>
                                                    <Row style={styles.tableheader}>
                                                        <Col xl={1}>{'Deal Number'}</Col>
                                                        <Col xl={1}>{'Deal Type'}</Col>
                                                        <Col xl={1}>{'Business Unit'}</Col>
                                                        <Col xl={1}>{'Activation Date'}</Col>
                                                        <Col xl={1}>{'Start Date'}</Col>
                                                        <Col xl={1}>{'End Date'}</Col>
                                                        <Col xl={1}>{'Period'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Incl KM'}</Col>
                                                        <Col xl={1}>{'Deviated'}</Col>
                                                        <Col xl={1}>{'Activated'}</Col>
                                                        <Col xl={1}>{'Terminated'}</Col>
                                                    </Row>
                                                    {
                                                        row.original['contracts']
                                                            ? row.original['contracts']
                                                                .map((x, i) => {
                                                                    return <div>
                                                                        <Row style={styles.tablerow}>
                                                                            <Col xl={1}>{x.dealnumber}</Col>
                                                                            <Col xl={1}>{x.dealtype}</Col>
                                                                            <Col xl={1}>{x.customer}</Col>
                                                                            <Col xl={1}>{moment(x.activationdate).format('YYYY-MM-DD')}</Col>
                                                                            <Col xl={1}>{moment(x.startdate).format('YYYY-MM-DD')}</Col>
                                                                            <Col xl={1}>{moment(x.enddate).format('YYYY-MM-DD')}</Col>
                                                                            <Col xl={1}>{x.period}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.inclkm.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col xl={1}>{x.restructure}</Col>
                                                                            <Col xl={1}>{x.activated}</Col>
                                                                            <Col xl={1}>{x.terminated}</Col>
                                                                        </Row>
                                                                    </div>
                                                                })
                                                            : <div>{'No Items Found...'}</div>
                                                    }
                                                    <Row style={styles.tablerow}>
                                                        <Col xl={11}>{''}</Col>
                                                        {/* <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['contracts'], (d) => { return parseFloat(Number(d.amount)) }).toFixed(2)}</Col> */}
                                                    </Row>
                                                    <Row><Col xl={12}><br /></Col>
                                                    </Row>
                                                </Container>
                                            } /><br />
                                        <Card 
                                                title={window.t.en('Contract Billing History')}
                                                subtitle={window.t.en('List of Contracts')}
                                                content={<Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                                    <Row><Col xl={12}><br /></Col>
                                                    </Row>
                                                    <Row style={styles.tableheader}>
                                                        <Col xl={1}>{'Deal Number'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Rental'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Admin Fee'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Licence fee'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Services'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Tyres'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Maintenance'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'ROI'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Excl'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'VAT'}</Col>
                                                        <Col style={styles.numericFields} xl={1}>{'Total Incl'}</Col>
                                                    </Row>
                                                    {
                                                        row.original['contracts']
                                                            ? row.original['contracts']
                                                                .map((x, i) => {
                                                                    return <div>
                                                                        <Row style={styles.tablerow}>
                                                                            <Col xl={1}>{x.dealnumber}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.rental.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.adminfee.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.lic.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.services.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.tyres.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.maint.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.roi.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.excl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.vat.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.incl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>

                                                                        </Row>
                                                                    </div>
                                                                })
                                                            : <div>{'No Items Found...'}</div>
                                                    }
                                                    <Row style={styles.tablerow}>
                                                        <Col xl={11}>{''}</Col>
                                                        {/* <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['contracts'], (d) => { return parseFloat(Number(d.amount)) }).toFixed(2)}</Col> */}
                                                    </Row>
                                                    <Row><Col xl={12}><br /></Col>
                                                    </Row>
                                                </Container>
                                           } /><br />
                                        <Card 
                                                title={window.t.en('Contract Documents')}
                                                subtitle={window.t.en('List of contract documents')}
                                                content={<Container style={styles.whitediv}>
                                                    <Row><Col xl={12}><br /></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={12}>
                                                            {
                                                                row.original['documents']
                                                                    ? <PdfViewer
                                                                        key={'document_viewer_main_history'}
                                                                        name={'billing_module_approved'}
                                                                        documents={row.original['documents']}
                                                                        relative_path={true}
                                                                        edit={false}
                                                                        multiple={false}
                                                                        workflow_queue_data={null}
                                                                    />
                                                                    : <div>{''}</div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Container>} />
                                    </div>
                                }}
                            />
                        </Row>
        </Container>
    }
    getBillingCheck(type)
    {
        return  <Container style={{ margin: 0 }} className="custom-container">
        <br></br>
        <Row>
            <Col xl={6}>
                < Button className={"global_button"} variant="contained"
                    primary={true}
                    key={type}
                    label="Load Billing"
                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                    onClick={(event) => {
                        this.props.actions.getBillingInvoices('', type)
                    }}
                >{window.t.en('Load Billing')}</Button>{'            '}< Button className={"global_button"}
                    primary={false}
                    label={"Refresh Billing"}
                    icon={<Icon iclass={'material-icons'} iname={'refresh'} />}
                    onClick={(event) => {
                        //rerun = 1
                        this.props.actions.getRerunBilling(type)
                    }}
                >{window.t.en('Refresh Billing')}</Button>{'            '}
                < Button className={"global_button"} variant="contained"
                    primary={true}
                    disabled={this.props.state.invoices.billingrerun ? false : true}
                    label={this.props.state.invoices.billingrerun ? "Export Headers" : "Refreshing..."}
                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                    onClick={(event) => {
                        this.props.actions.exportBilling(type, 'Headers')
                    }}
                >{window.t.en(this.props.state.invoices.billingrerun ? "Export Headers" : "Refreshing...")}</Button>{'            '}< Button className={"global_button"}
                    primary={true}
                    disabled={this.props.state.invoices.billingrerun ? false : true}
                    label={this.props.state.invoices.billingrerun ? "Export Items" : "Refreshing..."}
                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                    onClick={(event) => {
                        this.props.actions.exportBilling(type, 'Items')
                    }}
                >{window.t.en(this.props.state.invoices.billingrerun ? "Export Items" : "Refreshing...")}</Button>
            </Col>
            <Col xl={4}>
                < Button className={"global_button"} variant="contained"
                    primary={true}
                    disabled={this.props.state.invoices.billingrerun ? false : true}
                    label={this.props.state.invoices.billingrerun ? " ODO TEMPLATE" : "Refreshing..."}
                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                    onClick={(event) => {
                        this.props.actions.getOdoTemplateExport()
                    }}
                ><Icon iclass={'material-icons'} iname={'cloud_download'} />{this.props.state.invoices.billingrerun ? " ODO TEMPLATE" : "Refreshing..."}</Button>{'            '}
                < Button className={"global_button"}
                    primary={true}
                    disabled={this.props.state.invoices.billingrerun ? false : true}
                    label={this.props.state.invoices.billingrerun ? " CPK FILE" : "Refreshing..."}
                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                    onClick={(event) => {
                        this.props.actions.getCPKExport()
                    }}
                ><Icon iclass={'material-icons'} iname={'cloud_download'} /> {this.props.state.invoices.billingrerun ? " CPK FILE" : "Refreshing..."}</Button>
                {'            '}
                < Button className={"global_button"}
                    primary={true}
                    disabled={this.props.state.invoices.billingrerun ? false : true}
                    label={this.props.state.invoices.billingrerun ? " GET CIS" : "Refreshing..."}
                    icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                    onClick={(event) => {
                        this.props.actions.getCISExcelExport()
                    }}
                ><Icon iclass={'material-icons'} iname={'cloud_download'} /> {this.props.state.invoices.billingrerun ? " GET CIS" : "Refreshing..."}</Button>
            </Col>
            <Col xl={1}>
                <b>Select All: </b><Checkbox
                    checked={this.props.state.invoices.selectedall}
                    onClick={(event) => {
                        var flag = this.props.state.invoices.selectedall ? false : true
                        this.props.actions.selectall(flag)
                    }} />
            </Col>
            <Col xl={1}>
                < Button className={"global_button"} variant="contained"
                    primary={false}
                    disabled={this.props.state.invoices.approval_list || this.props.state.invoices.selectedall ? false : true}
                    key={type}
                    label="Approve"
                    onClick={(event) => {
                        approval_list = this.props.state.invoices.selectedall ?
                            this.props.state.adhoc["billing_module_" + type].data :
                            this.props.state.invoices.approval_list
                        var userdata = this.props.state.user
                        this.props.actions.setApprovedBillingList(type, approval_list, this.props.state.invoices.selectedall, userdata)
                        this.props.actions.dummyrun(true)
                    }}
                >{window.t.en('Approve')}</Button>
            </Col>
        </Row>
        <br></br>
        <Row>
            <AdhocGrid
                key={'key_adhoc_grid_billing_module_' + type}
                name={'billing_module_' + type}
                uri={'/components/billingmodule/other'}
                filterable={true}
                sortable={true}
                body={{
                    type: type,
                    approved: false,
                    list: [],
                    searchText: ''
                }}
                search={''}
                columns={[
                    { accessor: 'licenseplateno', Header: 'Vehicle', width: 100, filterable: true }, 
                    { accessor: 'customername', Header: 'Customer', width: 350, filterable: true }, 
                    { accessor: 'description', Header: 'Description', width: 200, filterable: true },
                    { accessor: 'customerreference', Header: 'Customer Reference', width: 150, filterable: true },
                    { accessor: 'invoicenumber', Header: 'Invoice #', width: 100, filterable: true },
                    { accessor: 'nettvalue', Header: 'Nett Value', width: 100, filterable: true },
                    { accessor: 'vat', Header: 'VAT', width: 100, filterable: true },
                    { accessor: 'debit', Header: 'Debit', width: 100, filterable: true },
                    { accessor: 'credit', Header: 'Credit', width: 100, filterable: true },
                    {
                        accessor: 'allocated', Header: 'Select', filterable: false, width: 50, Cell: row => {
                            return (
                                <div>
                                    <Checkbox
                                        checked={(row.original['allocated'] || this.props.state.invoices.selectedall) ? true : false}
                                        onClick={(event) => {
                                            var flag = (row.original['allocated'] || this.props.state.invoices.selectedall) ? false : true

                                            row.original['allocated'] = flag
                                            if (this.props.state.invoices.selectedall) this.props.actions.selectall(false)
                                            this.props.actions.billingapproval(flag ? 'addrow' : 'removerow', row.original, this.props.state.invoices.approval_list)
                                        }} />
                                </div>
                            )
                        }
                    },
                    {
                        accessor: 'invoicenumber', Header: 'Document', filterable: false, width: 150, Cell: row => {
                            return (
                                <div>
                                    < Button className={"global_button"} variant="contained"
                                        primary={true}
                                        label="Preview"
                                        icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
                                        onClick={(event) => {
                                            this.props.actions.previewinvoice(row.original['invoicenumber'])
                                        }}
                                    >{window.t.en('Preview')}</Button>
                                </div>
                            )
                        }
                    },
                ]}
                paging={{
                    page: 0,
                    pageSize: 10,
                    pages: 0,
                    count: 0
                }}
                subComponent={row => {
                    return <div key={'grid_billing_module_data_' + row.original['customertransaction_id']} style={{ padding: "2px" }}>
                        <Card 
                                title={window.t.en('Billing Items')}
                                subtitle={window.t.en('List of billing items')}
                                content={<Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                    <Row><Col xl={12}><br /></Col>
                                    </Row>
                                    <Row style={styles.tableheader}>
                                        <Col xl={2}>{'Description'}</Col>
                                        <Col xl={3}>{'Account'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Quantity'}</Col>
                                        <Col xl={1}>{'Unit'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Unit Price'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'VAT'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Total'}</Col>
                                    </Row>
                                    {
                                        row.original['items']
                                            ? row.original['items']
                                                .map((x, i) => {
                                                    return <div>
                                                        <Row style={styles.tablerow}>
                                                            <Col xl={2}>{x.description}</Col>
                                                            <Col xl={3}>{x.itemname}</Col>  
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.quantity.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col xl={1}>{x.unitmeasure}</Col>  
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.unitprice.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{(parseFloat(x.totalamount)).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.vat.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat((Number(x.totalamount) + Number(x.vat)).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                        </Row>
                                                    </div>
                                                })
                                            : <div>{'No Items Found...'}</div>
                                    }
                                    <Row style={styles.tablerow}>
                                        <Col xl={11}>{''}</Col>
                                        <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['items'], (d) => { return parseFloat(Number(d.totalamount) + Number(d.vat)) }).toFixed(2)}</Col>
                                    </Row>
                                    <Row><Col xl={12}><br /></Col>
                                    </Row>
                                </Container>
                             } />
                        <br />
                        <Card 
                                title={window.t.en('Billing History')}
                                subtitle={window.t.en('List of billing')}
                                content={<Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                    <Row><Col xl={12}><br /></Col>
                                    </Row>
                                    <Row style={styles.tableheader}>
                                        <Col xl={1}>{'Billing Date'}</Col>
                                        <Col xl={2}>{'Description'}</Col>
                                        <Col xl={2}>{'Business Unit '}</Col>
                                        <Col xl={1}>{'Start Date'}</Col>
                                        <Col xl={1}>{'End Date'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Amount'}</Col>
                                        <Col xl={2}>{'Transaction Type'}</Col>
                                        <Col xl={2}>{'Invoice #'}</Col>
                                    </Row>
                                    {
                                        row.original['history']
                                            ? row.original['history']
                                                .map((x, i) => {
                                                    return <div>
                                                        <Row style={styles.tablerow}>
                                                            <Col xl={1}>{moment(x.customertransactiondate).format('YYYY-MM-DD')}</Col>
                                                            <Col xl={2}>{x.description}</Col>
                                                            <Col xl={2}>{x.businessunit}</Col>
                                                            <Col xl={1}>{moment(x.startdate).format('YYYY-MM-DD')}</Col>
                                                            <Col xl={1}>{moment(x.enddate).format('YYYY-MM-DD')}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.amount.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col xl={2}>{x.transactiontype}</Col>
                                                            <Col xl={2}>{x.invoicenumber}</Col>
                                                        </Row>
                                                    </div>
                                                })
                                            : <div>{'No Items Found...'}</div>
                                    }
                                    <Row style={styles.tablerow}>
                                        <Col xl={11}>{''}</Col>
                                        <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['history'], (d) => { return parseFloat(Number(d.amount)) }).toFixed(2)}</Col>
                                    </Row>
                                    <Row><Col xl={12}><br /></Col>
                                    </Row>
                                </Container>
                          } />
                        <br />
                        <Card 
                                title={window.t.en('Contract History')}
                                subtitle={window.t.en('List of Contracts')}
                                content={<Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                    <Row><Col xl={12}><br /></Col>
                                    </Row>
                                    <Row style={styles.tableheader}>
                                        <Col xl={1}>{'Deal Number'}</Col>
                                        <Col xl={1}>{'Deal Type'}</Col>
                                        <Col xl={1}>{'Business Unit'}</Col>
                                        <Col xl={1}>{'Activation Date'}</Col>
                                        <Col xl={1}>{'Start Date'}</Col>
                                        <Col xl={1}>{'End Date'}</Col>
                                        <Col xl={1}>{'Period'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Incl KM'}</Col>
                                        <Col xl={1}>{'Deviated'}</Col>
                                        <Col xl={1}>{'Activated'}</Col>
                                        <Col xl={1}>{'Terminated'}</Col>
                                    </Row>
                                    {
                                        row.original['contracts']
                                            ? row.original['contracts']
                                                .map((x, i) => {
                                                    return <div>
                                                        <Row style={styles.tablerow}>
                                                            <Col xl={1}>{x.dealnumber}</Col>
                                                            <Col xl={1}>{x.dealtype}</Col>
                                                            <Col xl={1}>{x.customer}</Col>
                                                            <Col xl={1}>{moment(x.activationdate).format('YYYY-MM-DD')}</Col>
                                                            <Col xl={1}>{moment(x.startdate).format('YYYY-MM-DD')}</Col>
                                                            <Col xl={1}>{moment(x.enddate).format('YYYY-MM-DD')}</Col>
                                                            <Col xl={1}>{x.period}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.inclkm.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col xl={1}>{x.restructure}</Col>
                                                            <Col xl={1}>{x.activated}</Col>
                                                            <Col xl={1}>{x.terminated}</Col>
                                                        </Row>
                                                    </div>
                                                })
                                            : <div>{'No Items Found...'}</div>
                                    }
                                    <Row style={styles.tablerow}>
                                        <Col xl={11}>{''}</Col>
                                        {/* <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['contracts'], (d) => { return parseFloat(Number(d.amount)) }).toFixed(2)}</Col> */}
                                    </Row>
                                    <Row><Col xl={12}><br /></Col>
                                    </Row>
                                </Container>
                            }/><br />
                        <Card 
                                title={window.t.en('Contract Billing History')}
                                subtitle={window.t.en('List of Contracts')}
                                content={<Container style={{ backgroundColor: '#fff', margin: '10px' }}>
                                    <Row><Col xl={12}><br /></Col>
                                    </Row>
                                    <Row style={styles.tableheader}>
                                        <Col xl={1}>{'Deal Number'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Rental'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Admin Fee'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Licence fee'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Services'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Tyres'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Maintenance'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'ROI'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Excl'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'VAT'}</Col>
                                        <Col style={styles.numericFields} xl={1}>{'Total Incl'}</Col>
                                    </Row>
                                    {
                                        row.original['contracts']
                                            ? row.original['contracts']
                                                .map((x, i) => {
                                                    return <div>
                                                        <Row style={styles.tablerow}>
                                                            <Col xl={1}>{x.dealnumber}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.rental.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.adminfee.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.lic.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.services.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.tyres.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.maint.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.roi.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.excl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.vat.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                            <Col style={styles.numericFields} xl={1}>{parseFloat(x.incl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>

                                                        </Row>
                                                    </div>
                                                })
                                            : <div>{'No Items Found...'}</div>
                                    }
                                    <Row style={styles.tablerow}>
                                        <Col xl={11}>{''}</Col>
                                        {/* <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['contracts'], (d) => { return parseFloat(Number(d.amount)) }).toFixed(2)}</Col> */}
                                    </Row>
                                    <Row><Col xl={12}><br /></Col>
                                    </Row>
                                </Container>} />
                        <br />
                        <Card 
                                title={window.t.en('Contract Documents')}
                                subtitle={window.t.en('List of contract documents')}
                                content={<Container style={styles.whitediv}>
                                    <Row><Col xl={12}><br /></Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            {
                                                row.original['documents']
                                                    ? <PdfViewer
                                                        key={'document_viewer_main_history'}
                                                        name={'billing_module'}
                                                        documents={row.original['documents']}
                                                        relative_path={true}
                                                        edit={false}
                                                        multiple={false}
                                                        workflow_queue_data={null}
                                                    />
                                                    : <div>{''}</div>
                                            }
                                        </Col>
                                    </Row>
                                </Container> } />
                    </div>
                }}
            />
        </Row>
        </Container>
    }
    getLayout(type) {
        this.actions = this.props.actions;

        return <Card 
        title={window.t.en('Billing')}
        subtitle={window.t.en('List of Billing Invoices')}
        content={<Container style={styles.whitediv}>
                    <Row>
                        <Col xl={12}>
                            {this.getBillingGrid(type)}
                        </Col>
                    </Row>
                </Container>} />
    }
    getView() {

        return <div><Tabs centered value={this.props.state.invoices.mainTab}
            onChange={
                (event, value) => {
                    this.props.actions.setMainTab(value)
                }
            }>
            <Tab style={{ width: '100%' }} label={'Fixed Billing'} id={'Fixed Billing'} />
            <Tab style={{ width: '100%' }} label={'Termination Excess'} id={'Termination Excess'} />
            <Tab style={{ width: '100%' }} label={'Variable Billing'} id={'Variable Billing'} />
            <br></br>
        </Tabs>
        <Row> <Col xl={12}> <br></br> </Col> </Row>
        { this.props.state.invoices.mainTab === 0 && this.getLayout('fixed') }
        { this.props.state.invoices.mainTab === 1 && this.getLayout('excess')}
        { this.props.state.invoices.mainTab === 2 && this.getLayout('variable')}
        </div>
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.billingmodule,
            invoices: {
                ...state.billingmoduleview
            },
            upload: {
                ...state.upload
            }, user: {
                ...state.user
            },
            adhoc: {
                ...state.adhocGrid
            },

        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BILLINGMODULE)