
export const chipRow = {
    display: 'table',
    margin: 5
}

export const chip = {
    width: 200
}

export const chipContainer = {
    display: 'table-cell',
    width: 300,
    verticalAlign: 'middle'
}

export const chipDescription = {
    display: 'table-cell',
    verticalAlign: 'middle'
}

export const fullWidth = {
    width: '100%',
}

export const inputWidth = {
    width: '100%',
    fontSize: '16px',
}

export const fieldContainer = {
    display: 'table',
    padding: 5
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '20px',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '5px',
    border: '#f9cfcf',
    backgroundColor: '#D1E0E6',
}
export const rowItem = {
    width: '100%',
    borderBottom: '1px solid silver',
    padding: 5,
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'right',
}
export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}
export const numericFields = {
    textAlign: 'right',
}
export const whitediv = {
    backgroundColor: '#fff',
    padding: 2,
    verticalAlign: 'left'
}
export const selectedFieldName = {
    fontWeight: 'bold'
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const boxDefault = {
    textAlign: 'left'
}

export const boxError = {
    width: '100%',
    backgroundColor: '#f9cfcf',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '2px',
    textAlign: 'left',
}

export const tasksCard = {
    textAlign: 'left'
}

export const row = (index, table) => {
    return index % 2 === 0
        ? {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            backgroundColor: '#ffffe5',
            fontSize: '14px',
            width: '100%'
        }
        : {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            fontSize: '14px',
            width: '100%'
        }
}

export const column = (width, right) => {
    return width === 0
        ? {
            display: 'table-cell',
            padding: '2px',
            fontSize: '14px',
            verticalAlign: 'middle !important',
            textAlign: right ? 'right' : 'left'
        }
        : {
            display: 'table-cell',
            padding: '2px',
            fontSize: '14px',
            width: width,
            verticalAlign: 'middle !important',
            textAlign: right ? 'right' : 'left'
        }
}

export const pagingControls = {
    verticalAlign: 'middle',
    textAlign: 'right'
}

export const action = {
    fontSize: '15px',
}

export const actionIcon = (status) => {
    return status
    ? {
        fontSize: '20px',
        fontWeight: 'normal',
        color: '#1bc943',
        padding: '25px 10px',
        width: 40,
        fill: '#1bc943'
    }
    : {
        fontSize: '20px',
        fontWeight: 'normal',
        //color: '#1bc943',
        color: '#253053',
        padding: '25px 10px',
        width: 40,
        fill: '#2947AE'
    }
}

export const actionHoverColor = {
}

export const gridOptionsIcon = {
    color: '#2947AE',
    fontSize: '20px',
    fontWeight: 'normal',
    padding: '20px 10px',
    width: 40,
    fill: '#2947AE'
}

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const toggle = {
    width: '100%'
}

export const dropZone = {
    height: '200px',
    border: '2px solid red',
    padding: '2px'
}

export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}