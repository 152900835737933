import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    textAlign: 'right',
    //padding: 10
    //fontWeight: 'bold'
}

export const icon = {
    fontSize: '10px'
}

export const row = {
    fontSize: 15,
    //backgroundColor: '#dcd6d7',
    //opacity: '0.5'
}