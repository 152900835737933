import * as types from './types'
import moment from 'moment'

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_GENERATE_CHECKSHEET,
            payload: payload
        })
    }
}



export const generateChecksheet = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GENERATE_DEFLEET_CHECKSHEET,
            payload: {
                type: 'GENERATE_DEFLEET_CHECKSHEET',
                data: data
            }
        })
    }
}

export const setDefleetChecksheet = (data) => {
    
    //console.log("DEFLEET Check Sheet")
    //console.log(data)
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
        
        /* return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        } */
    }())

    saveByteArray([data.data.pdf.Body], 'Defleet Checksheet.pdf')

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_CHECKSHEET,
            payload: {
                preview: link,
                filename: data.data.key.replace('quotes/', '')
            }
        })
    }
}


export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_DEFLEET_UPLOADCHECKSHEET_STATE
        })
    }
}



export const setTaskNumber = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TASK_NUMBER,
            payload: data
        })
    }
}


export const setValue = (prop, value) => {
    return (dispatch, getState) => {       
        if ((prop.indexOf('date') >= 0 || prop.indexOf('checksheet_completedat') >= 0) && prop !== 'defleet_date_match' ) { 
            value = value.toString().trim()
            const now = moment(new Date())
            let date = new Date(moment(value).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            dispatch({
                type: types.SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUE,
                payload: {
                    prop: prop,
                    value: date
                }
            })         
        }
        else {
            dispatch({
                type: types.SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUE,
                payload: {
                    prop: prop,
                    value: value
                }
            })
        }
        dispatch(setValidationValue(prop, value))
    }
}

export const getSelectedVehicleDetails = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_VEHICLE_DETAILS,
            payload: {
                query: request.value
            }
        })
    }
}

export const setSelectedVehicleDetails = (result, vehicle_id) => {
    window.glyco.log('SET_SELECTED_VEHICLE_DETAILS')
    window.glyco.log(result)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_VEHICLE_DETAILS,
            payload: {
                data: result.data
            }
        })
    }
}

export const getDefleetLocationList  = (search) => {
    return {
        type: types.GET_DEFLEET_LOCATION,
        payload: {
            search: search,
            action: 'location'
        }
    }
}
export const setDefleetLocation = (res) => {
    let list = res.data.map(x => {
        return {
            text: x.town,
            value: x.town
        }
    })

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_LOCATION,
            payload: {
                data: list,
            }
        })
    }
}
export const setValidationValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_UPLOADCHECKSHEET_VALIDATION_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}