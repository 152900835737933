import React, { Component } from 'react'
import ReactTable from "react-table"
import "react-table/react-table.css"
import moment from 'moment'
import Icon from '../Icon/component'
import Theme from '../../theme'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import AutoComplete from '../../controls/autocomplete'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox';



import Loading from '../Loading/component'
import PdfViewer from '../PDF/component'


import * as actions from './actions'
import * as styles from './styles'

class InvoiceapRejectConfirm extends Component {




    componentDidMount() {

         this.props.actions.setupWorkflowTaskInput(this.props.state.upload.documents, 'supplierinvoice', 'stp_invoice_ap_rejection')
    }



    confirm() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Confirm Action')}
                    subtitle={window.t.en('')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                    <div style={styles.fieldContainer}>

                       
                                                

                            <p style={styles.actionConfirm}>

                           
                                    <span>You have chosen to resolve an invoice, press "confirm" to confirm your action</span>
                                     
                               
                            </p>

        


                    </div>
                                
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

   

    render() {


       
        
         return this.confirm()
       

    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.invoiceapRejectConfirm,
            invoiceap: {
                ...state.invoiceap
            } ,
            upload: {
                ...state.upload
            } 
        }
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceapRejectConfirm)
