import * as workflowTaskInput from '../WorkflowTaskInput/actions'
import * as workflowTaskApproval from '../WorkflowTaskApproval/actions'

export const resetErrorState = () => {
    return (dispatch, getState) => {
        dispatch(workflowTaskInput.clearWorkflowTaskInformation())
        dispatch(workflowTaskInput.clearWorkflowTaskActionData())
        dispatch(workflowTaskApproval.clearWorkflowTaskApprovalInformation())
        dispatch(workflowTaskApproval.clearWorkflowTaskApprovalData())
    }
}
