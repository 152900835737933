import * as types from './types'

export const getWorkflowTriggers = () => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOW_TRIGGERS_GET_WORKFLOW_TRIGGERS
        })
    }
}

export const setWorkflowTriggers = (payload) => {
    return (dispatch) => {
        dispatch({
            type: types.WORKFLOW_TRIGGERS_SET_WORKFLOW_TRIGGERS,
            payload: payload
        })
    }
}

export const toggleWorkflowTriggers = (status) => {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_WORKFLOW_TRIGGERS,
            payload:{
                // name: name,
                status: status
            }
        })
    }
}

export const closeWorkflowTriggers = () => {
    return (dispatch) => {
        dispatch({
            type: types.CLOSE_WORKFLOW_TRIGGERS
        })
    }
}