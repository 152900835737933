import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'


export const getCategory = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_COMPONENT_CUSTOMER_DERIVATIVE_CATEGORY_LIST),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/customerderivativecategory/search?customerderivative_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
            .pipe(
                mergeMap(response => [
                    actions.setCategory(response.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivativecategory/search')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivativecategory/search', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

export const saveCategory = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.SAVE_COMPONENT_CUSTOMER_DERIVATIVE_CATEGORY),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/customerderivativecategory',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(payload => [
                    actions.saveCategoryDone(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivativecategory')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivativecategory', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))


export const delCategory = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.DEL_COMPONENT_CUSTOMER_DERIVATIVE_CATEGORY),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/customerderivativecategory',
                method: 'DELETE',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(payload => [
                    actions.delCategoryDone(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivativecategory')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivativecategory', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

export const getLookupValueData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_COMPONENT_CUSTOMER_DERIVATIVE_LOOKUP_DATA),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/' + payload.table + '/search?' + payload.field + '=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': payload.related
                },
            })
            .pipe(
                mergeMap(result => [
                    actions.setLookupValueData(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivativecategory')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivativecategory', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

export const getMMCodeLookupValueData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_MMCODE_COMPONENT_CUSTOMER_DERIVATIVE_LOOKUP_DATA),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/mmcode/searchor?makemodelyear=' + payload.search + '&mmcode=' + payload.search,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': payload.related
                },
            })
            .pipe(
                mergeMap(result => [
                    actions.setLookupValueData(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivativecategory')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivativecategory', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

export const getOtherLookupValueData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_COMPONENT_CUSTOMER_DERIVATIVE_OTHER_LOOKUP_DATA),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/component/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(result => [
                    actions.setOtherLookupValueData(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivativecategory')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivativecategory', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

export const getDerivativeRelated = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.SAVE_COMPONENT_CUSTOMER_DERIVATIVE_CATEGORY_RELATED),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/' + payload.model,
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(result => [
                    //actions.setLookupValueData(result.response, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/customerderivativecategory')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivativecategory', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
