
import * as types from './types'
import moment from 'moment'

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
    }
}

export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BILLING_INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setAcceptedDocuments = (documents) => {
    const _file = 'Release_Form_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_BOOKING_RELEASE_FORM_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: _file,
                        type: file.type,
                    }
                })
            };
        });

        dispatch({
            type: types.SET_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW,
            payload: {
                preview: documents[0].preview,
                filename: _file,
            }
        })
    }
}

export const setRejectedDocuments = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW,
            payload: null
        })
    }
}

export const setUpdateOfferItem = (id, prop, value) => {
    return (dispatch, getState) => {
        let _offers = getState().defleetDisposal.offers
        var data = _offers.map((x, i) => {
            return (x.id === id && (prop === 'description')) ?
                {
                    ...x,
                    description: value
                } : (x.id === id && (prop === 'amount')) ?
                    {
                        ...x,
                        amount: value,
                    } : (x.id === id && (prop === 'trade')) ?
                        {
                            ...x,
                            trade: value,
                        } : (x.id === id && (prop === 'market')) ?
                            {
                                ...x,
                                market: value,
                            }: x
        })
        dispatch({
            type: types.UPDATE_OFFER,
            payload: data
        })
    }
}

export const addBlankOffer = (id) => {
    //console.log("Blank ID")
    //console.log(id)
    return (dispatch, getState) => {
        let _offers = getState().defleetDisposal.offers
        _offers.push(
            {
                id: id,
                description: '',
                amount: 0.00,
                trade: 0.00,
                market: 0.00
            })
        dispatch({
            type: types.UPDATE_OFFER,
            payload: _offers
        })
    }
}
export const removeOffer = (id) => {
    return (dispatch, getState) => {
        let _offers = getState().defleetDisposal.offers.filter(x => x.id !== id)
        dispatch({
            type: types.UPDATE_OFFER,
            payload: _offers
        })
    }
}