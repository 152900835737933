import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'

import * as styles from './styles'
import * as actions from './actions'
import TextField from '@mui/material/TextField'
import PdfViewer from '../../PDF/component'
import moment from 'moment';

class BillingRunStartConfirm extends Component {
    componentDidMount() {
        let obj = this.props.state
        const meta = [
            {
                header: true,
                title: 'Initiated On',
                description: 'Billing Initiated On',
                meta_data: moment().format('DD-MM-YYYY HH:mm')
            },
            {
                header: true,
                title: 'Initiated By',
                description: 'Billing Initiated By',
                meta_data: obj.user.firstname
            },
        ]
        this.props.actions.setupWorkflowTaskInput(obj, meta, 'billing_run', 'tsk_billing_run_start')
    }

    getForm() {
        return <div>
            <Card title={window.t.en('Billing Initiation Confirmation')}
                subtitle={window.t.en('Please Upload Odo File')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">                        
                        <Row>
                            <Col xl={12}>
                                {this.props.state.upload.documents ? this.uploadedFile(this.props.state.upload.documents) : ''}
                            </Col>
                        </Row>
                    </Container>
                } />
        </div>
    }

    uploadedFile(documents) {
        return <Row>
            <Col xl={12}>
                <br />
                <PdfViewer                    
                    key={'document_viewer_uploaded_file'}
                    name={'tsk_billing_run_start'}
                    workflow={'billing_run'}
                    task={'tsk_billing_run_start'}
                    documents={documents}
                    edit={false}
                    workflow_queue_data={null}
                />
            </Col>
        </Row>
    }

    commentsView() {
        return <div>
            <Card
                title={window.t.en('File Upload Comments')}
                subtitle={window.t.en('Capture comments')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomment'}
                                key={'txtcomment'}
                                label={window.t.en('Enter Comments...')}
                                fullWidth={true}
                                disabled={true}
                                variant="outlined"
                                value={this.props.state.comments}
                                onChange={
                                    (event) => {
                                    }
                                }
                            /></Col>
                    </Row>
                </Container>} />
        </div>
    }
    
    getConfirm() {
        return <div>
            <Card title={window.t.en('Billing Confirm')}
                subtitle={window.t.en('Billing start process')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <div>
                                    <h4>Billing Run: </h4>
                                    <ul>
                                        <li>This process is intended to run month-end invoices</li>
                                        <li>This process will not commit until transactions are approved</li>
                                        <li>You will able to revers this process before is proved</li>
                                    </ul>
                                </div>
                                <div>
                                    <h4>Billing run file required: </h4>
                                    <ul>
                                        <li>Odometer file</li>
                                        <li>Out of contract</li>
                                        <li>Billing Preparation</li>
                                    </ul>
                                </div>
                            </Col>
                            {
                                this.props.state.comments
                                ? <Col xl={12}>
                                    <div>
                                        <h4>Your Comments</h4>
                                        <ul>
                                            <li>{this.props.state.comments}</li>
                                        </ul>
                                    </div>
                                </Col>
                                : ''
                            }
                        </Row>
                    </Container>
                } />
        </div>
    }
    

    render() {
        return ([this.getConfirm()])
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.billinrunstart,
            user: {
                ...state.user
            },
            upload: {
                ...state.upload
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingRunStartConfirm)