import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_CONFIRM_FML_QUOTATION_REVIEW_INPUT:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.INIT_CONFIRM_FML_QUOTATION_REVIEW_STATE:
            const { fields, ...otherProps } = action.payload;
            return {
                ...state,
                ...otherProps
            }
        default:
            return state
    }
}