import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Dashboard from './dashboard';
import moment from 'moment';

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Icon } from '@mui/material';
import { Container, Row, Col } from 'react-grid-system'

import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import Fields from '../../controls/cardfields'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Grid from './grid';
import Form from './form';
import View from './view';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Card from '../../controls/card'
import * as actions from './actions'
import * as styles from './styles'

class Events extends Component {
    componentDidMount() {
        this.props.actions.reset_form()
        this.props.actions.loadEvents()
    }

    getEvents() {
        return <div>
            <Dashboard />
            {this.getDialog()}
            {this.getEventLog()}
        </div>
    }

    getHistory() {
        return <div>
            <br/> <br/>
          <TableContainer component={Paper}>
            <Table  aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Event Name</TableCell>
                    <TableCell>Event Log</TableCell>
                    <TableCell>Event Date</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        this.props.state.workqueue_calendar
                        ? this.props.state.workqueue_calendar.map(x => {
                            return <TableRow key={'table01'}>
                            <TableCell component="th" scope="row">
                                {x?.workqueue?.workqueue_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {x.workqueue_log}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {x.created_at}
                            </TableCell>
                        </TableRow>
                        })
                        : 'Loading...'
                    }
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    }

    getDaily()
    {
        return <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Schedule Time '}</div>
                            <div style={styles.fieldContent}>
                      
                                <DateControl
                                    id={'event_daily_schedule_time'}
                                    key={'event_daily_schedule_time'}
                                    name={'event_daily_schedule_time'}
                                    fullWidth={true}
                                    value={this.props.state.end_date}
                                    onSelect={(args) => {
                                        this.props.actions.setInputValue({prop: 'event_daily_schedule_time', value: args})
                                    }}
                                    type={'time'}
                                />
                                </div>
                        </div>
                    </Col>
                </Row>
                </Container>
    }

    getWeekly()
    {
        return <Container style={{ margin: 0 }} className="custom-container"><Row>
        <Col xl={12}>
            <div style={styles.fieldContainer}>
                <div style={styles.fieldTitle}>{'Schedule Week Days'}</div>
                <div style={styles.fieldContent}>
                    <div>
                       < Button className={"global_button"} variant="contained"
                            label="Mon"
                            labelPosition="before"
                            primary={!this.props.state.mon}
                            icon={<Icon iclass={'material-icons'} iname={this.props.state.mon ? 'done' : 'add'} />}
                            onClick={() => {
                                this.props.actions.setInputValue({ prop: 'mon', value: !this.props.state.mon })
                            }}
                            >{window.t.en('Mon')}</Button>
                       < Button className={"global_button"} variant="contained"
                            label="Tue"
                            labelPosition="before"
                            primary={!this.props.state.tue}
                            icon={<Icon iclass={'material-icons'} iname={this.props.state.tue ? 'done' : 'add'} />}
                            onClick={() => {
                                this.props.actions.setInputValue({ prop: 'tue', value: !this.props.state.tue })
                            }}
                            >{window.t.en('Tue')}</Button>
                       < Button className={"global_button"} variant="contained"
                            label="Wed"
                            labelPosition="before"
                            primary={!this.props.state.wed}
                            icon={<Icon iclass={'material-icons'} iname={this.props.state.wed ? 'done' : 'add'} />}
                            onClick={() => {
                                this.props.actions.setInputValue({ prop: 'wed', value: !this.props.state.wed })
                            }}
                            >{window.t.en('Wed')}</Button>
                       < Button className={"global_button"} variant="contained"
                            label="Thu"
                            labelPosition="before"
                            primary={!this.props.state.thu}
                            icon={<Icon iclass={'material-icons'} iname={this.props.state.thu ? 'done' : 'add'} />}
                            onClick={() => {
                                this.props.actions.setInputValue({ prop: 'thu', value: !this.props.state.thu })
                            }}
                            >{window.t.en('Thu')}</Button>
                       < Button className={"global_button"} variant="contained"
                            label="Fri"
                            labelPosition="before"
                            primary={!this.props.state.fri}
                            icon={<Icon iclass={'material-icons'} iname={this.props.state.fri ? 'done' : 'add'} />}
                            onClick={() => {
                                this.props.actions.setInputValue({ prop: 'fri', value: !this.props.state.fri })
                            }}
                            >{window.t.en('Fri')}</Button>
                       < Button className={"global_button"} variant="contained"
                            label="Sat"
                            labelPosition="before"
                            primary={!this.props.state.sat}
                            icon={<Icon iclass={'material-icons'} iname={this.props.state.sat ? 'done' : 'add'} />}
                            onClick={() => {
                                this.props.actions.setInputValue({ prop: 'sat', value: !this.props.state.sat })
                            }}
                            >{window.t.en('Sat')}</Button>
                       < Button className={"global_button"} variant="contained"
                            label="Sun"
                            labelPosition="before"
                            primary={!this.props.state.sun}
                            icon={<Icon iclass={'material-icons'} iname={this.props.state.sun ? 'done' : 'add'} />}
                            onClick={() => {
                                this.props.actions.setInputValue({ prop: 'sun', value: !this.props.state.sun })
                            }}
                        />
                    </div>
                </div>
            </div>
        </Col></Row></Container>
    }
    
    getMonthly()
    {
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row>
            <Col xl={12}>
                <div style={styles.fieldContainer}>
                    <div style={styles.fieldTitle}>{'Schedule Day '}</div>
                    <div style={styles.fieldContent}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth={true}
                                    id={'event_daily_schedule_date'}
                                    hintText={'Enter Monthly Inclusive'}
                                    value={this.props.state.period}
                                    onChange={(event, value) => {
                                        this.props.actions.setInputValue({prop: 'event_name', value: event.target.value})
                                    }}
                                />
                        </div>
                </div>
            </Col>
        </Row>
        </Container>
    }
    
    getDialog() {
        return <div>
              {
                <Dialog
                open={this.props.state.add_win}
                style={{ width: '60% !important', maxWidth: '60% !important' }}
                maxWidth="xl">
                <div className="text-center p-1">
                    <div>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col style={styles.subheaders} key={'col_'} xl={12}>{'Add New Event '}</Col>
                            </Row>
                            
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Select Event Category"}:</div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                                name={'event_category'}
                                                title={'Select category'}
                                                data={this.props.state.category_list}
                                                value={this.props.state.category}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                onSelect={(args) => this.props.actions.setInputValue({prop: 'category', value: args.text})}
                                                onClick={() => this.props.actions.getCategory('')}
                                                onFilter={(args) => this.props.actions.getCategory(args)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Event Start Date"}:</div>
                                        <div style={styles.fieldContent}>
                                            <DateControl
                                                id={'event_start_date'}
                                                key={'event_start_date'}
                                                name={'event_start_date'}
                                                fullWidth={true}
                                                value={this.props.state.start_date}
                                                onSelect={(args) => {
                                                    this.props.actions.setInputValue({prop: 'start_date', value: args})
                                                }}
                                                type={'date'}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Select Component"}:</div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                                name={'event_component'}
                                                title={'Select component'}
                                                data={this.props.state.component_list}
                                                value={this.props.state.component}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                onSelect={(args) => {
                                                    this.props.actions.setInputValue({prop: 'component', value: args.text})
                                                    this.props.actions.setInputValue({prop: 'component_id', value: args.value})
                                                }}
                                                onClick={() => this.props.actions.getComponent('')}
                                                onFilter={(args) => this.props.actions.getComponent(args)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Event End Date"}:</div>
                                        <div style={styles.fieldContent}>
                                            <DateControl
                                                id={'event_end_date'}
                                                key={'event_end_date'}
                                                name={'event_end_date'}
                                                fullWidth={true}
                                                value={this.props.state.end_date}
                                                onSelect={(args) => {
                                                    this.props.actions.setInputValue({prop: 'end_date', value: args})
                                                }}
                                                type={'date'}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Event Name"}:</div>
                                        <div style={styles.fieldContent}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth={true}
                                                id={'txt_monthly_inclusive'}
                                                hintText={'Event Name'}
                                                value={this.props.state.event_name}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue({prop: 'event_name', value: event.target.value})
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col style={styles.subheaders} key={'col_'} xl={12}>{'Add Event Schedule '}</Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Select Schedule"}:</div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                                name={'event_schedule'}
                                                title={'Select schedule'}
                                                data={this.props.state.schedule_list}
                                                value={this.props.state.schedule}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                onSelect={(args) => {
                                                    this.props.actions.setInputValue({prop: 'schedule', value: args.text})
                                                    this.props.actions.setInputValue({prop: 'schedule_id', value: args.value})
                                                }}
                                                onClick={() => this.props.actions.getSchedule('')}
                                                onFilter={(args) => this.props.actions.getSchedule(args)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    {this.props.state.schedule === "Daily" && this.getDaily()}
                                    {this.props.state.schedule === "Weekly" && this.getWeekly()}
                                    {this.props.state.schedule === "Monthly" && this.getMonthly()}
                                </Col>
                            </Row> */}
                            
                            <Row>
                                <Col style={styles.subheaders} key={'col_'} xl={12}>{'Add Event Action '}</Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Select Action"}:</div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                                name={'event_action'}
                                                title={'Select Action'}
                                                data={this.props.state.actions_list}
                                                value={this.props.state.action}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                onSelect={(args) => {
                                                    this.props.actions.setInputValue({prop: 'action', value: args.text})
                                                    this.props.actions.setInputValue({prop: 'action_id', value: args.value})
                                                }}
                                                onClick={() => this.props.actions.getActions('')}
                                                onFilter={(args) => this.props.actions.getActions(args)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    {this.props.state.action === "Ack" && <div>Ack will close the event once it is view</div>}
                                    {this.props.state.action === "Action" && <div>Action will allow to view the details in form and will requere updated information</div>}
                                    {this.props.state.action === "View" && <div>View is the same as Ack</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col style={styles.subheaders} key={'col_'} xl={12}>{'Add Event Escalation '}</Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Select Escalation Type"}:</div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                                name={'event_schedule'}
                                                title={'Select escalation'}
                                                data={this.props.state.escalation_list}
                                                value={this.props.state.escalation}
                                                dataSourceConfig={{
                                                    text: 'text',
                                                    value: 'value'
                                                }}
                                                onSelect={(args) => {
                                                    this.props.actions.setInputValue({prop: 'selected_escalation', value: []})
                                                    this.props.actions.setInputValue({prop: 'escalation', value: args.text})
                                                    this.props.actions.setInputValue({prop: 'escalation_id', value: args.value})
                                                }}
                                                onClick={() => this.props.actions.getEscalation('')}
                                                onFilter={(args) => this.props.actions.getEscalation(args)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={6}>
                                    {
                                    this.props.state.escalation === "Team" && <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Select Team To Add To Escalation"}:</div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                            name={'field_team'}
                                            title={'Select Team'}
                                            data={this.props.state.team_list}
                                            //value={this.props.state.team}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            onSelect={(args) => {
                                                this.props.actions.setInputValue({prop: 'team', value: args.text})
                                                this.props.actions.addTeamEscalation({prop: 'selected_escalation', value: this.props.state.team_list.filter(x => x.team_id === args.value)})
                                            }}
                                            onClick={() => this.props.actions.getTeam('')}
                                            onFilter={(args) => this.props.actions.getTeam(args)} />
                                        </div>
                                    </div>
                                    }
                                    {
                                    this.props.state.escalation === "User" && <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>{"Select User To Add To Escalation"}:</div>
                                        <div style={styles.fieldContent}>
                                            <AutoComplete
                                            name={'field_user'}
                                            title={'Select User'}
                                            data={this.props.state.user_list}
                                            //value={this.props.state.user}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            onSelect={(args) => {
                                                this.props.actions.setInputValue({prop: 'user_name', value: args.text})
                                                this.props.actions.addUserEscalation({prop: 'selected_escalation', value: this.props.state.user_list.filter(x => x.user_id === args.value)})
                                            }}
                                            onClick={() => this.props.actions.getUser('')}
                                            onFilter={(args) => this.props.actions.getUser(args)} />
                                        </div>
                                    </div>
                                    }
                                </Col>
                                <Col xl={12}>
                                    {
                                        this.props.state.escalation === "Team" 
                                        ? <Table className="table" >
                                                <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                                    <th className="text-left px-4" style={{width: "50px !important"}}></th>
                                                    <th className="text-left">Team Title</th>
                                                    <th className="text-left">Team Name</th>
                                                </thead>
                                                <tbody style={{zIndex:1}}>
                                                    {
                                                        this.props.state.selected_escalation.map(x => {
                                                            return <tr>
                                                                    <td className="text-left">
                                                                        <IconButton key={'command_remove_' + x.team_id} style={styles.iconButton} iconStyle={styles.action}
                                                                            onClick={
                                                                                () => {
                                                                                    this.props.actions.rmTeamEscalation({prop: 'selected_escalation', value: x.team_id})
                                                                                }
                                                                            }>
                                                                            <DeleteForeverIcon />
                                                                        </IconButton>
                                                                    </td>
                                                                    <td className="text-left">{x.title}</td>
                                                                    <td className="text-left">{x.description}</td>
                                                                </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            : <Table className="table" >
                                                <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                                    <th className="text-left px-4" style={{width: "50px !important"}}></th>
                                                    <th className="text-left">Firstname</th>
                                                    <th className="text-left">Lastname</th>
                                                    <th className="text-left">Email</th>
                                                    <th className="text-left">Phone</th>
                                                    <th className="text-left">Jobtitle</th>
                                                </thead>
                                                <tbody style={{zIndex:1}}>
                                                    {
                                                        this.props.state.selected_escalation.map(x => {
                                                            return <tr>
                                                                    <td className="text-left">
                                                                        <IconButton key={'command_remove_' + x.user_id} style={styles.iconButton} iconStyle={styles.action}
                                                                            onClick={
                                                                                () => {
                                                                                    this.props.actions.rmUserEscalation({prop: 'selected_escalation', value: x.user_id})
                                                                                }
                                                                            }>
                                                                            {/* 
                                                                            <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                                                             */}
                                                                            <DeleteForeverIcon />
                                                                        </IconButton>
                                                                    </td>
                                                                    <td className="text-left">{x.firstname}</td>
                                                                    <td className="text-left">{x.lastname}</td>
                                                                    <td className="text-left">{x.email}</td>
                                                                    <td className="text-left">{x.phone}</td>
                                                                    <td className="text-left">{x.jobtitle}</td>
                                                                </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                    }
                                </Col>
                            </Row>
                            {
                                this.props.state.component 
                                ? <Row>
                                    <Col style={styles.subheaders} key={'col_title'} xl={10}>{'Add Rule Event '}</Col>
                                    <Col xl={2} style={styles.subheaders} key={'col_add'}>
                                        <Button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.addRule()
                                            }}
                                            className="btn-pill mx-1">
                                            <span className="btn-wrapper--label">Add</span>
                                        </Button>
                                    </Col>
                                    <Col xl={12}>
                                    {
                                        this.props.state.rules.length 
                                        ? <Table className="table table-alternate-spaced mb-0" >
                                            <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                                <th className="text-left px-4" style={{width: "50px !important"}}></th>
                                                <th className="text-left"></th>
                                            </thead>
                                            <tbody style={{zIndex:1}}>
                                                {
                                                        this.props.state.rules.map(x => {
                                                            //console.log('component_field_data_type',x)
                                                            return <tr>
                                                                <td className="text-left">
                                                                    <IconButton key={'command_remove_' + x.inx} style={styles.iconButton} iconStyle={styles.action}
                                                                        onClick={
                                                                            () => {
                                                                                this.props.actions.removeRow(x.inx)
                                                                            }
                                                                        }>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </td>
                                                                <td className="px-4">
                                                                <Container style={{ margin: 0 }} className="custom-container">
                                                                    <Row>
                                                                        {/* <Col xl={12} style={{textAlign: "left"}}><h4>Rule Condition</h4></Col>
                                                                                <Col xl={12} style={{textAlign: "left"}}><hr /></Col> */}
                                                                        <Col xl={3}>
                                                                            <br/>
                                                                            <AutoComplete
                                                                                name={'field_' + x.inx}
                                                                                title={'Select field'}
                                                                                data={this.props.state.field_list}
                                                                                value={x.field_name}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'field', value: this.props.state.field_list.filter(x => x.component_field_id === args.value)})
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'field_id', value: args.value})
                                                                                    this.props.actions.setRuleValue({inx: x.inx, prop: 'field_name', value: args.text})
                                                                                }}
                                                                                onClick={() => this.props.actions.getField(this.props.state.component_id,'')}
                                                                                onFilter={(args) => this.props.actions.getField(this.props.state.component_id, args)} 
                                                                                />
                                                                        </Col>
                                                                        <Col xl={3}>
                                                                            <br/>
                                                                            { 
                                                                                x.field.length 
                                                                                ? <AutoComplete
                                                                                    name={'field_' + x.inx}
                                                                                    title={'Select Operator ' + x.field[0].component_field_data_type.name}
                                                                                    data={this.props.state.operator_list}
                                                                                    value={x.operator_text}
                                                                                    dataSourceConfig={{
                                                                                        text: 'text',
                                                                                        value: 'value'
                                                                                    }}
                                                                                    onSelect={(args) => {
                                                                                        this.props.actions.setRuleValue({inx: x.inx, prop: 'operator', value: this.props.state.operator_list.filter(x => x.workqueue_operator === args.text)})
                                                                                        this.props.actions.setRuleValue({inx: x.inx, prop: 'operator_id', value: args.value})
                                                                                        this.props.actions.setRuleValue({inx: x.inx, prop: 'operator_text', value: args.text})
                                                                                    }}
                                                                                    onClick={() => this.props.actions.getOperator(x.field[0].component_field_data_type.name, '')}
                                                                                    onFilter={(args) => this.props.actions.getOperator(x.field[0].component_field_data_type.name, args)} /> 
                                                                                : <div></div>
                                                                            }
                                                                        </Col>
                                                                        <Col xl={3}>
                                                                            {
                                                                                x.operator.length && x.operator[0].workqueue_operator_input
                                                                                ? 
                                                                                <div style={styles.fieldContainer}>
                                                                                    <div style={styles.fieldTitle}>{"Enter Condition Value"}:</div>
                                                                                    <div style={styles.fieldContent}>
                                                                                        <TextField
                                                                                    variant="outlined"
                                                                                    margin="normal"
                                                                                    fullWidth={true}
                                                                                    id={'txt_condition_' + x.inx}
                                                                                    hintText={'Enter Condition'}
                                                                                    value={x.condition}
                                                                                    onChange={(event, value) => {
                                                                                        this.props.actions.setRuleValue({inx: x.inx, prop: 'condition', value: event.target.value})
                                                                                    }}
                                                                                /> 
                                                                                </div></div>
                                                                                : ''
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                                </td>
                                                            </tr>                               
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        : <h4>No Items</h4>
                                    }
                                    </Col>
                                </Row>
                                : ''
                            }
                            {
                                this.props.state.component 
                                ? <>
                                    <Row>
                                        <Col style={styles.subheaders} key={'col_'} xl={12}>{'Event Indicator '}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={2}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{"Good"}:</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth={true}
                                                    id={'ind_good'}
                                                    hintText={'Good Indicator'}
                                                    value={this.props.state.ind_good}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue({prop: 'ind_good', value: event.target.value})
                                                    }}
                                                /> 
                                            </div></div>
                                        </Col>
                                        <Col xl={2}>
                                                                            
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{"Low"}:</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth={true}
                                                    id={'ind_low'}
                                                    hintText={'Low Indicator'}
                                                    value={this.props.state.ind_low}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue({prop: 'ind_low', value: event.target.value})
                                                    }}
                                                /> 
                                            </div></div>
                                        </Col>
                                        <Col xl={2}>
                                        
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{"Medium"}:</div>
                                            <div style={styles.fieldContent}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth={true}
                                                    id={'ind_medium'}
                                                    hintText={'Medium Indicator'}
                                                    value={this.props.state.ind_medium}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue({prop: 'ind_medium', value: event.target.value})
                                                    }}
                                                /> 
                                            </div></div>
                                        </Col>
                                        <Col xl={2}>
                                        
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{"High"}:</div>
                                            <div style={styles.fieldContent}>
                                            <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth={true}
                                                    id={'ind_high'}
                                                    hintText={'High Indicator'}
                                                    value={this.props.state.ind_high}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue({prop: 'ind_high', value: event.target.value})
                                                    }}
                                                /> 
                                            </div></div>
                                        </Col>
                                        <Col xl={2}>
                                        
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{"Critical"}:</div>
                                                <div style={styles.fieldContent}><TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth={true}
                                                    id={'ind_critical'}
                                                    hintText={'Critacal Indicator'}
                                                    value={this.props.state.ind_critical}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setInputValue({prop: 'ind_critical', value: event.target.value})
                                                    }}
                                                /> 
                                            </div></div>
                                        </Col>
                                    </Row> 
                                </>
                                : ''
                            }
                        </Container>
                    </div>
                    <br />
                    <hr />
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.setInputValue({ prop: 'add_win', value: false })
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Cancel</span>
                        </Button>
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.saveEvent(this.props.state)
                            }}
                            disabled={this.props.state.saving}
                            className="btn-pill mx-1">
                            <span className="btn-wrapper--label">{this.props.state.saving ? 'Saving..' : 'Save Event'}</span>
                        </Button>
                    </div>
                    <br />
                    <br />
                </div>
            </Dialog>
    }
        </div>
    }
    
    getEventLog() {
        return <div>
              {
                <Dialog
                open={this.props.state.log_win}
                style={{ width: '60% !important', maxWidth: '60% !important' }}
                maxWidth="xl">
                <div className="text-center p-1">
                    <div>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col style={styles.subheaders} key={'col_'} xl={12}>{'Event Log'}</Col>
                            </Row>
                           
                            <Row>
                                <Col xl={12}>
                                   
                                    <div>
                                    <TableContainer component={Paper}>
                                        <Table  aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <TableCell>Event Name</TableCell>
                                                <TableCell>Event Log</TableCell>
                                                <TableCell>Event Date</TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.props.state.workqueue_calendar
                                                    ? this.props.state.workqueue_calendar.filter(x => x.workqueue_id === this.props.state.workqueue_id).map(x => {
                                                        return <TableRow key={'foo'}>
                                                        <TableCell component="th" scope="row">
                                                            {x?.workqueue?.workqueue_name}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {x.workqueue_log}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {x.created_at}
                                                        </TableCell>
                                                    </TableRow>
                                                    })
                                                    : 'Loading...'
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <br />
                    <hr />
                    <div className="pt-4">
                        <Button
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.actions.setInputValue({ prop: 'log_win', value: false })
                            }}
                            className=" btn-pill mx-1">
                            <span className="btn-wrapper--label">Close</span>
                        </Button>
                    </div>
                    <br />
                    <br />
                </div>
            </Dialog>
    }
        </div>
    }

    getLayout() {
        return <div>
        <Tabs
            initialSelectedIndex={0}
            value={this.props.state.tab}
            inkBarStyle={{
                height: 4
            }}
            onChange={(event, value) => {
                this.props.actions.reset_form()
                this.props.actions.setInputValue({prop: 'tab', value: value})

            }}
        >
            <Tab style={{minWidth:"50%"}} label={"Data"} />
            <Tab style={{minWidth:"50%"}} label={"Create"} />
            {/* <Tab style={{minWidth:"20%"}} label={"Event Profile"} />
            <Tab style={{minWidth:"50%"}} label={"Event History"} /> */}
        </Tabs>
        <div>
            {this.props.state.tab === 0 && <Box><Grid /></Box>}
            {this.props.state.tab === 1 && this.props.state.view === 'form' && <Box><Form /></Box>}
            {this.props.state.tab === 1 && this.props.state.view === 'view' && <Box><View /></Box>}
            {this.props.state.tab === 2 && <Box>{this.getEvents()}</Box>}
            {this.props.state.tab === 3 && <Box>{this.getHistory()}</Box>}
        </div>
    </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.events 
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Events)