import * as types from './types'
import * as quoteItemsTypes from '../DefleetItems/types'
import * as quoteItemsActions from '../DefleetItems/actions'
import moment from 'moment'





export const resetComponent = (type) => {
    return (dispatch, getState) => {
        let quotations = getState().defleetUploadQuotes.quotations
        
        console.log('addQuotationRowx resetComponent', quotations)

        dispatch(quoteItemsActions.loadQuoteData([]))

        
        dispatch({
            type: quoteItemsTypes.ADD_QUOTE_ITEM_BLANK_ROW,
            payload: []
        })

        dispatch({
            type: types.RESET_DEFLEET_UPLOADQUOTE_COMPOMENT_STATE
        })
        dispatch({
            type: types.SET_COMPONENT_DEFLEET_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'quotations',
                value: []
            }
        })
    }
}

export const setQuotationInputValue = (quotation, props, value) => {
    return (dispatch, getState) => {
        let quotations = getState().defleetUploadQuotes.quotations
        ///quotation[props] = value
        quotations[quotation.inx][props] = value
        console.log('addQuotationRowx', quotations)
        dispatch({
            type: types.SET_COMPONENT_DEFLEET_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'quotations',
                value: quotations
            }
        })
    }
}

export const addQuotationRow = () => {
    return (dispatch, getState) => {
        let quotations = getState().defleetUploadQuotes.quotations
        quotations.push({id: moment().format('YYMMDDhhmmssSSS'), quotedate: moment().format('YYYY-MM-DD')})
        console.log('addQuotationRowx', quotations)
        dispatch({
            type: types.SET_COMPONENT_DEFLEET_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'quotations',
                value: quotations
            }
        })
    }
}

export const remQuotationRow = (id) => {
    return (dispatch, getState) => {
        console.log('addQuotationRowx id',id)
        let quotations = getState().defleetUploadQuotes.quotations.filter(x => x.id !== id)
        console.log('addQuotationRowx',id, quotations)
        dispatch({
            type: types.SET_COMPONENT_DEFLEET_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'quotations',
                value: quotations
            }
        })
    }
}

export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
        dispatch(setValidationValue(prop, value))
    }
}
export const getMMCodeByVIN = (vin) => {
    window.glyco.log('getMMCodeByVIN')
    window.glyco.log(vin)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER,
            payload: vin
        })
    }
}
export const getServicePartBasket = (mmcode, interval) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BOOKING_VEHICLE_SERVICE_PART_BASKET,
            payload: '?mmcode=' + mmcode + '&service_info_interval=' + interval
        })
    }
}
export const setServicePartBasket = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_VEHICLE_SERVICE_PART_BASKET,
            payload: response.data
        })
    }
}
export const getVehicleContract = (vehicle_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE,
            payload: vehicle_id
        })
    }
}
export const setVehicleContract = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE,
            payload: response.data
        })
    }
}
export const setAcceptedDocuments = (documents) => {
    const _file = 'VSBQ_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: _file,
                        type: file.type,
                    }
                })
            };
        });

        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'filename',
                value: _file
            }
        })
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'invoicepreview',
                value: documents[0].preview
            }
        })
    }
}
export const setRejectedDocuments = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW,
            payload: null
        })
    }
}
export const downloadDocument = (document) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.DOWNLOAD_DOCUMENT_DISCOUNT_LETTER,
            payload: {
                type: 'DOWNLOAD_DOCUMENT_DISCOUNT_LETTER',
                document: document
            }
        })
    }
}
export const setDiscountLetter = (data) => {
    let link = ''
    const filekey = data.data.document.key
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW,
            payload: {
                preview: link
            }
        })
    }
}
export const getVehicleLastPosition = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICLE_LASTPOSITION,
            payload: {
                type: 'GET_VEHICLE_LASTPOSITION',
                vehicle: id
            }
        })
    }
}
export const setVehicleLastPosition = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_VEHICLE_LASTPOSITION,
            payload: data.data
        })
    }
}
export const getSelectedVehicleDetails = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_VEHICLE_DETAILS,
            payload: {
                query: request.value
            }
        })
    }
}
export const setSelectedVehicleDetails = (result, vehicle_id) => {
    window.glyco.log('SET_SELECTED_VEHICLE_DETAILS')
    window.glyco.log(result)
    return (dispatch, getState) => {

        const curr_type = getState().poHeader.selectedPurchaseOrderType.key
        dispatch({
            type: types.SET_SELECTED_VEHICLE_DETAILS,
            payload: {
                data: result.data
            }
        })
    }
}
export const setQuoteItems = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_QUOTE_ITEMS,
            payload: data.data
        })
    }
}
export const setSupplierListData = (payload) => {
    let data = payload.data.map(x => {
        return {
            text: x.suppliername,
            value: x.supplier_id
        }
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_DEFLEET_SUPPLIERS_LIST,
            payload: data
        })
    }
}
export const setSelectedSupplierData = (supplier_id, supplier_name) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_DEFLEET_SELECTED_SUPPLIER,
            payload: {
                supplier_id: supplier_id,
                supplier_name: supplier_name
            }
        })
        //dispatch(setValidationValue('selectedSupplier', supplier_name))
        if (supplier_name && getState().defleetCaptureQuote.supplier.suppliername != supplier_name) {
            dispatch(toggleCorrectSupplier(true))
            //dispatch(setValue('correct_supplier', true))
        }
        else{
            //dispatch(setValue('correct_supplier', true))selectedSupplier
        }
    }
}
export const getSupplierListData = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_DEFLEET_SUPPLIERS_LIST,
            payload: search
        })
    }
}

export const setValidationValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_UPLOADQUOTE_VALIDATION_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}
export const toggleCorrectSupplier = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: "toggle_correct_supplier",
                value: value
            }
        })
        
    }
}
