
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Dialog,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip
} from '@mui/material';
import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';

import * as actions from './actions'
import * as styles from './styles'

class ReadComponent extends Component {
    componentDidMount() {
        //this.props.actions.toggleRelationshipMenu()
    }

    render() {
        this.component = this.props.state.component
        //console.log('Title Component ################################',this.props)
        return (
        <>
<div className="app-page-title app-page-title--shadow" style={{ marginBottom: '0px',padding: '20px 65px'}}>
  <div>
    <div className="app-page-title--first">
      {(
        <div className="app-page-title--iconbox d-70">
          <div className="d-70 d-flex align-items-center justify-content-center display-1">
            <HomeWorkTwoToneIcon className="text-primary" />
          </div>
        </div>
      )}
      <div className="app-page-title--heading">
        <h1>{this.props.component.title}</h1>
        {(
          <div className="app-page-title--description">
            {this.props.component.description}
          </div>
        )}
      </div>
    </div>
  </div>
  <div className="d-flex align-items-center">
    {/* {children} */}
    <Button
      variant="contained"
      //onClick={openUserMenu}
      size="small"
      className="btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3">
      <span className="btn-wrapper--label">Actions</span>
      <span className="btn-wrapper--icon">
        <FontAwesomeIcon
          icon={['fas', 'angle-down']}
          //className="opacity-5"
        />
      </span>
    </Button>
    {this.props.buttons}
    <Tooltip title="Add new entry">
      <Button
        variant="contained"
        size="small"
        className="d-40 btn-success"
        //onClick={toggle1}
        >
        <span className="btn-wrapper--icon">
          <FontAwesomeIcon icon={['fas', 'plus']} className="opacity-8" />
        </span>
      </Button>
    </Tooltip>
  </div>
</div>
</>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.readComponent,
            related: {
                ...state.components[state.readComponent.grid]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadComponent)
