import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import * as helper from '../../helpers/components'
import Icon from '../Icon/component'
import SurrogateView from '../SurrogateView/component'

import * as actions from '../Form/actions'
import * as styles from './styles'

import Loading from '../Loading/component'
import Document from '../Document/component'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

class WorkflowInboxMessageComponent extends Component {
    componentDidMount() {
        try {
            const inbox = this.props.data ? this.props.data : this.props.workflow_queue_data[0].data.data.data[0]
            this.props.actions.loadComponent(this.props.name, inbox.inbox_message_id)
        } catch (err) {

        }
    }

    getFieldValue(field, index) {
        const value = this.data[field.name]

        if (!value) return ''

        switch (field.component_field_display_type.name) {
            case 'datePicker':
            case 'timePicker':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : 'Not Set'
            case 'textBox':
                return field.component_field_data_type.name === 'decimal' || field.component_field_data_type.name === 'float'
                    ? value != null && value.length > 0 && !isNaN(value)
                        ? parseFloat(value).toFixed(2)
                        : value
                    : value
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            case 'toggle':
                const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value
                return value != null
                    ? value
                        ? toggle[0]
                        : toggle[1]
                    : 'Not Set'
            default:
                return ''
        }
    }

    getField(field, index) {
        return this.getFieldValue(field, index)
    }

    getSection(section, index) {
        const fields = section.component_field
            .filter(x => !x.related && !x.key)
            .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
            .reduce((arr, x, i) => {
                if (this.component.staggered) {
                    if (this.component.data[x.name].input !== -1) {
                        arr.push(
                            <Row
                                key={'row_' + section.name + '_' + x.name + '_' + i}
                                className={'interactiveRow'}
                            >
                                <Col xl={2} style={styles.selectedFieldName}>
                                    {x.title}
                                </Col>
                                <Col>
                                    {this.component.data[x.name].input === 1 ? 'Yes' : 'No'}
                                </Col>
                            </Row>
                        )
                    }
                }
                else {
                    arr.push(
                        <Row
                            key={'row_' + section.name + '_' + x.name + '_' + i}
                            className={'interactiveRow'}
                        >
                            <Col xl={2} style={styles.selectedFieldName}>
                                {x.title}
                            </Col>
                            <Col>
                                {this.getField(x, i)}
                            </Col>
                        </Row>
                    )
                }

                return arr
            }, [])

        return <Card key={'card_' + section.name} initiallyExpanded={true}> {/* initiallyExpanded={index === 0} */}
            <CardHeader
                avatar={<Icon iclass={section.icon.unicode} iname={section.icon.name} />}
                title={section.title}
                subtitle={section.description}
                actAsExpander={true}
                showExpandableButton={true}
                style={styles.row}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    {fields}
                </Container>
            </CardContent>
        </Card>
    }

    getSectionView() {
        return this.component.data
            ? this.component.sections
                .sort((x, y) => x.order - y.order)
                .map((x, i) => {
                    return this.getSection(x, i)
                })
            : 'Loading...'
    }

    getView() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col>
                    {this.getSectionView()}
                </Col>
            </Row>
             <Row className={'interactiveRow'}>
                <Col>
                    {
                        this.component.data.inbox_message_id.input
                            ? <Document component={this.component.name} upload={true} />
                            : 'Loading...'
                    }
                </Col>
            </Row> 
        </Container>
    }

    getOtherStuff() {
        if (!this.component.data) {
            return []
        }

        if (this.component.stop) {
            return []
        }

        if (this.component.view === null) {
            return []
        }

        const relations = this.component.view.split(',')

        return relations.map(x => {
            if (!this.component.data[x])
                return []

            const component = this.props.state.components[x]
            const key = component.component_field.filter(y => y.key)[0]
            const id = this.component.data[x][key.name]

            return <SurrogateView
                id={id}
                name={component.name}
                expanded={true}
                stop={true}
                component={component}
            />
        })
    }

    render() {
        this.component = this.props.state.component
        let data = null
        try{
        data = this.props.data
            ? this.props.data
            : this.props.workflow_queue_data.length
                ? this.props.workflow_queue_data[0].data.data.data[0]
                : null
        }
        catch(err)
        {}
                this.data = data
        return this.data
            ? [
                this.getView(),
                this.getOtherStuff()
            ]
            : <Loading message={'Retrieving data...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowTaskInput,
            component: {
                ...state.components[ownProps.name]
            },
            _component: {
                ...state.components['inbox_message']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowInboxMessageComponent)