export const type = 'UPLOAD_EXCEL_FILE_DATA'
export const rows = []
export const uploading = false
export const busy = false
export const document = null
export const template = null
export const exceptions = null
export const templates = []
export const bulk_headers = [{
    name: 'make',
    title: 'Vehicle Make',
},{
    name: 'model',
    title: 'Vehicle Model',
},{
    name: 'quantity',
    title: 'Quantity',
},{
    name: 'fuel',
    title: 'Fuel',
},{
    name: 'transmission',
    title: 'Transmission',
},{
    name: 'color',
    title: 'Color',
},{
    name: 'mmcode',
    title: 'M&M Code',
},{
    name: 'retail',
    title: 'Retail Price',
},{
    name: 'discount',
    title: 'Discount',
},{
    name: 'price',
    title: 'Vehicle Price',
},{
    name: 'period',
    title: 'Period',
},{
    name: 'kms',
    title: 'Inclusive Kms',
},{
    name: 'contractkms',
    title: 'Contract Kms',
},{
    name: 'rv',
    title: 'RV',
},{
    name: 'interest',
    title: 'Interest',
},{
    name: 'maintenance',
    title: 'Maintenance CPK',
},{
    name: 'tyres',
    title: 'Tyres CPK',
},{
    name: 'excl',
    title: 'Total Excl',
},{
    name: 'vat',
    title: 'VAT',
},{
    name: 'incl',
    title: 'Total Monthly Incl',
}]
export const bulk_rows = [{
    make: 'Nissan',
    model: 'NP300 SD 4x4',
    quantity: 1
},{
    make: 'Toyota',
    model: 'Hilux SD 4x4',
    quantity: 1
},{
    make: 'Isuzu',
    model: 'D-Max SD 4x4',
    quantity: 1
}]

