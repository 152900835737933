import { cognitoUser, cognitoUserPool, cognitoAuthenticationDetails } from '../../cognito'

import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'


import moment from 'moment'
import sockets from 'socket.io-client'

import * as config from '../../config'
import * as routerActions from '../Redirect/actions'
import * as accountActions from '../Account/actions'
import * as appActions from '../App/actions'
import * as userActions from '../User/actions'
import * as types from './types'


import * as appTypes from '../App/types'
import * as componentActions from '../Components/actions'
import * as workflowActions from '../Workflows/actions'
import JwtHelper from '../../helpers/jwt';

export const phoneNumberFormat = PhoneNumberFormat
export const phoneNumberUtil = PhoneNumberUtil.getInstance()

let refreshTokenTimer = undefined

export const refreshUserAuthenticationTokenOff = () => {
    return (dispatch, getState) => {
        if (!refreshTokenTimer) {
            refreshTokenTimer = true
            refreshTokenTimer = setInterval(dispatch, (1000 * 60), refreshUserAuthenticationToken())
        }
        else {
            refreshTokenTimer = undefined
            dispatch({
                type: appTypes.SHOW_SESSION_EXPIRED_DIALOG,
                payload: true
            })
        }
    }
}

export const refreshUserAuthenticationToken = () => {
    return (dispatch, getState) => {
        if (!refreshTokenTimer) {
            refreshTokenTimer = setInterval(dispatch, (1000 * 60 * 15), refreshUserAuthenticationTokenRefresh())
        }else {
            clearInterval(refreshTokenTimer);
            refreshTokenTimer = setInterval(dispatch, (1000 * 60 * 15), refreshUserAuthenticationTokenRefresh())
        }
    }
}

export const refreshUserAuthenticationTokenRefresh = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_SESSION_EXPIRED_DIALOG,
            payload: true
        })
        dispatch(appActions.sessionCountDown())
    }
}

export const setCognitoUserAuthenticationKeeplive = (payload) => {
    return async (dispatch, getState) => {
        if (payload.response.accessToken) {
            let token = payload.response.accessToken.jwtToken//result.getAccessToken().getJwtToken()
            let privateKey = moment().format("YYYYMMDD-hhmmss")
            ///const jwt = await tokenService.sign({ token: token }, privateKey);
            const jwt = await JwtHelper.encodeText(token, privateKey);
            let _payload = {
                error: {},
                result: payload.response,
                token: token,
                refresh: false,
                jwt: jwt, //jwt.sign({ token: token }, privateKey),
                datetime: privateKey,
                refresh: true
            }

            dispatch({
                type: types.KEEPLIVE_USER_AUTHENTICATION_SUCCEEDED,
                payload: {
                    error: {},
                    result: payload.response,
                    token: token,
                    refresh: false,
                    jwt: jwt, //.sign({ token: token }, privateKey),
                    datetime: privateKey,
                    refresh: true
                }
            })
        }
    }
}

export const getUserPool = () => {
    return {
        type: types.GET_COGNITO_USER_POOL,
        payload: {
            UserPoolId: config.cognito.userPoolId,
            ClientId: config.cognito.clientId
        }
    }
}

export const getCurrentCognitoUser = (refresh) => {
    return {
        type: types.GET_CURRENT_COGNITO_USER,
        payload: {
            refresh: refresh
        }
    }
}

export const getCognitoUserSession = () => {
    return (dispatch) => {
        cognitoUser.getSession((error, session) => {
            if (error) {
                dispatch({
                    type: types.GET_COGNITO_USER_SESSION_FAILED,
                    payload: {
                        result: error,
                        valid: false
                    }
                })
            }
            else {
                dispatch({
                    type: types.GET_COGNITO_USER_SESSION_SUCCEEDED,
                    payload: {
                        result: session,
                        valid: session.isValid()
                    }
                })
            }
        })
    }
}

let ui = sockets(config.system.messenger.uri)
export const signoutCognitoUser = () => {

    return (dispatch) => {
        // dispatch({
        //     type: types.SIGN_OUT_COGNITO_USER
        // })

        window.indexedDB.deleteDatabase('glyco_io')
        window.localStorage.clear()
        //window.location.reload(true)


        dispatch(appActions.userSignout())
        dispatch(routerActions.route('/'))
        window.location.href='/';
    }
}

export const startCognitoUserRegistration = () => {
    return {
        type: types.START_COGNITO_USER_REGISTRATION
    }
}

export const userSuccessfullSignIn = () => {
    
    //console.log('getUser setUser ACCOUNT_USER_AUTHENTICATION_SUCCEEDED>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>' )
    return {
        type: types.ACCOUNT_USER_AUTHENTICATION_SUCCEEDED
    }
}

export const submitCognitoUser = (payload) => {
    return {
        type: types.SUBMIT_COGNITO_USER,
        payload: payload
    }
}

export const getUserAttributes = (fields) => {
    return (dispatch) => {
        dispatch({
            type: types.COGNITO_CONFIGURATION_STARTED
        })

        const attributes =
            Object.keys(fields)
                .filter(x => fields[x].name.indexOf('password') === -1 && fields[x].name.indexOf('phone_number') === -1)
                .map(x => {
                    return {
                        Name: fields[x].name,
                        Value: fields[x].name === 'phone_number'
                            ? phoneNumberUtil.format(phoneNumberUtil.parse(fields[x].input, 'ZA'), phoneNumberFormat.E164)
                            : fields[x].input
                    }
                })

        dispatch({
            type: types.GET_COGNITO_USER_ATTRIBUTES,
            payload: attributes
        })

        dispatch({
            type: types.COGNITO_CONFIGURATION_SUCCEEDED
        })
    }
}

export const createCognitoUser = (userPool, username, password, attributes) => {
    return (dispatch) => {
        cognitoUserPool.signUp(username, password, attributes, null, (error, result) => {
            if (error) {
                dispatch({
                    type: types.CREATE_COGNITO_USER_FAILED,
                    payload: {
                        cognitoUser: {},
                        error: error,
                        result: result,
                        userData: {}
                    }
                })
            }
            else {
                dispatch({
                    type: types.CREATE_COGNITO_USER_SUCCEEDED,
                    payload: {
                        cognitoUser: result.user,
                        error: {},
                        result: result,
                        userData: {
                            Username: username,
                            Pool: userPool
                        }
                    }
                })

                dispatch(routerActions.route('/confirm'))
            }
        })
    }
}

export const submitRegistrationConfirmation = (payload) => {
    return {
        type: types.SUBMIT_REGISTRATION_CONFIRMATION,
        payload: payload
    }
}

export const confirmRegistration = (cognitoUser, verificationCode) => {
    return (dispatch, getState) => {
        cognitoUser.confirmRegistration(verificationCode, true, (error, result) => {
            if (error) {
                dispatch({
                    type: types.REGISTRATION_CONFIRMATION_FAILED,
                    payload: {
                        error: error,
                        result: result
                    }
                })
            }
            else {
                /*                 dispatch({
                                    type: types.REGISTRATION_CONFIRMATION_SUCCEEDED,
                                    payload: {
                                        error: {},
                                        result: result
                                    }
                                })
                 */
                dispatch({
                    type: types.PROCESS_COGNITO_USER_REGISTRATION,
                    payload: {
                        user: {
                            cognito_key: getState().cognito.signUp.result.userSub,
                            email: getState().cognito.signUp.fields.email.input,
                            phone: getState().cognito.signUp.fields.phone.input,
                        },
                        result: result
                    }
                })
            }
        })
    }
}

export const registrationConfirmationSucceeded = (result) => {
    return {
        type: types.REGISTRATION_CONFIRMATION_SUCCEEDED,
        payload: {
            error: {},
            result: result
        }
    }
}

export const submitResendConfirmationCode = () => {
    return {
        type: types.SUBMIT_RESEND_REGISTRATION_CONFIRMATION_CODE,
        payload: true
    }
}

export const resendConfirmationCode = (cognitoUser, resendsLeft) => {
    return (dispatch) => {
        cognitoUser.resendConfirmationCode((error, result) => {
            if (error) {
                dispatch({
                    type: types.RESEND_REGISTRATION_CONFIRMATION_CODE_FAILED,
                    payload: {
                        error: error,
                        result: result,
                        sending: false,
                    }
                })
            }
            else {
                dispatch({
                    type: types.RESEND_REGISTRATION_CONFIRMATION_CODE_SUCCEEDED,
                    payload: {
                        error: {},
                        resendsLeft: resendsLeft - 1,
                        result: result,
                        sending: false
                    }
                })
            }
        })
    }
}

export const submitCognitoUserAuthentication = (payload) => {
    return {
        type: types.SUBMIT_COGNITO_USER_AUTHENTICATION,
        payload: payload
    }
}

export const getCognitoUserAuthenticationDetails = (username, password) => {

    return {
        type: types.GET_COGNITO_USER_AUTHENTICATION_DETAILS,
        payload: {
            type: 'GET_COGNITO_USER_AUTHENTICATION_DETAILS',
            auth: {
                Username: username,
                Password: password
            }
        }
    }
}

export const setCognitoUserAuthenticationDetails_AMP = (payload) => {

    return async (dispatch, getState) => {
        //console.log('accountsx getuser', payload)
        
        console.log('amplifyx cognito', payload)
        if (payload.userSub) {
            //let token = payload.signInUserSession.idToken.jwtToken//result.getAccessToken().getJwtToken()
            let token = payload.credentials.sessionToken//result.getAccessToken().getJwtToken()
            let privateKey = moment().format("YYYYMMDD-hhmmss")
            const jwt = await JwtHelper.encodeText(token, privateKey);
            dispatch({
                type: types.COGNITO_USER_AUTHENTICATION_SUCCEEDED,
                payload: {
                    error: {},
                    result: payload.tokens,
                    token: token,
                    refresh: false,
                    jwt: jwt,//jwt.sign({ token: token }, privateKey),
                    datetime: privateKey,
                    refresh: true
                }
            }) //todo

            dispatch({
                type: types.GET_COGNITO_USER,
                payload: {
                    username: payload.userSub /// payload.response.idToken.payload.sub
                }
            })
            dispatch(userActions.getUser(payload.userSub))
            // dispatch(getCurrentCognitoUser(false))
            dispatch(accountActions.getLocaleList())
            // dispatch(componentActions.getComponentNames())
            // dispatch(workflowActions.getWorkflowNames()) //todo

        } else {
            dispatch({
                type: types.COGNITO_USER_AUTHENTICATION_FAILED,
                payload: {
                    error: payload.error,
                    result: {},
                    token: ''
                }
            })
        }
    }
}

export const setCognitoUserAuthenticationDetails = (payload) => {

    const xorEncrypt = (inputString, key) => {
        let encrypted = '';
        for (let i = 0; i < inputString.length; i++) {
            // XOR each character in the input with the key
            encrypted += String.fromCharCode(inputString.charCodeAt(i) ^ key.charCodeAt(i % key.length));
        }
        return encrypted;
    }

    return async (dispatch, getState) => {
        console.log('accountsx getuser', payload)
        if (payload.username) {
            let token = payload.signInUserSession.idToken.jwtToken//result.getAccessToken().getJwtToken()
            let privateKey = moment().format("YYYYMMDD-hhmmss")
            
            const jwt = await JwtHelper.encodeText(token, privateKey);

            dispatch({
                type: types.COGNITO_USER_AUTHENTICATION_SUCCEEDED,
                payload: {
                    error: {},
                    result: payload.attributes,
                    token: token,
                    refresh: false,
                    jwt: jwt,
                    datetime: privateKey,
                    refresh: true
                }
            }) //todo

            dispatch({
                type: types.GET_COGNITO_USER,
                payload: {
                    username: payload.attributes.sub /// payload.response.idToken.payload.sub
                }
            })
            dispatch(userActions.getUser(payload.attributes.sub))
            // dispatch(getCurrentCognitoUser(false))
            dispatch(accountActions.getLocaleList())
            // dispatch(componentActions.getComponentNames())
            // dispatch(workflowActions.getWorkflowNames()) //todo

        } else {
            dispatch({
                type: types.COGNITO_USER_AUTHENTICATION_FAILED,
                payload: {
                    error: payload.error,
                    result: {},
                    token: ''
                }
            })
        }
    }
}


export const updateUserAuthenticationDetails = (payload) => {
    return (dispatch, getState) => {
            dispatch({
                type: types.COGNITO_UPDATE_USER_AUTHENTICATION_DETAILS,
                payload: {
                    type: 'update_user_details',
                    data: payload
                }
            })
    }
}


export const updateUserAuthenticationDetailsDone = (res) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'User details successfully updated',
                overflowText: ' ',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const getCognitoUserData = (username) => {
    return {
        type: types.GET_COGNITO_USER_DATA,
        payload: username
    }
}

export const setCognitoUserDate = () => {
    return {
        type: types.SET_COGNITO_USER_DATA
    }
}

export const getCognitoUser = () => {
    return {
        type: types.GET_COGNITO_USER
    }
}

export const sendCognitoUserAuthenticationRequest = () => {
    return {
        type: types.SEND_COGNITO_USER_AUTHENTICATION_REQUEST
    }
}

export const authenticateCognitoUser = () => {
    return (dispatch, getState) => {
        cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
            onFailure: (error) => {
                dispatch({
                    type: types.COGNITO_USER_AUTHENTICATION_FAILED,
                    payload: {
                        error: error,
                        result: {},
                        token: ''
                    }
                })

                if (error.code === 'PasswordResetRequiredException') {
                    cognitoUser.forgotPassword({
                        onSuccess: function (result) {
                            dispatch({
                                type: types.FORGOT_PASSWORD_REQUEST_SENT,
                                payload: result
                            })

                            dispatch(routerActions.route('/forgot', false))
                        },
                        onFailure: function (error) {
                            dispatch({
                                type: types.FORGOT_PASSWORD_REQUEST_SENT,
                                payload: error
                            })
                        }
                    })
                }
            },
            onSuccess: (result, userConfirmationNecessary) => {
                dispatch({
                    type: types.COGNITO_USER_AUTHENTICATION_SUCCEEDED,
                    payload: {
                        error: {},
                        result: result,
                        token: result.getAccessToken().getJwtToken(),
                        refresh: false
                    }
                })

                dispatch({ type: types.SET_DEVICE_STATUS })
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                dispatch({
                    type: types.COGNITO_USER_AUTHENTICATION_NEW_PASSWORD_REQUIRED,
                    payload: userAttributes
                })

                dispatch(routerActions.route('/password', false))
            }
        })
    }
}


export const reauthenticateCognitoUser = () => {
    return (dispatch, getState) => {
        cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
            onFailure: (error) => {
                dispatch(signoutCognitoUser())
            },
            onSuccess: (result, userConfirmationNecessary) => {
                dispatch({
                    type: types.COGNITO_USER_AUTHENTICATION_SUCCEEDED,
                    payload: {
                        error: {},
                        result: result,
                        token: result.getAccessToken().getJwtToken(),
                        refresh: true
                    }
                })
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                dispatch(signoutCognitoUser())
            }
        })
    }
}

export const submitCognitoUserForgotPassword = () => {
    return (dispatch, getState) => {
        let forgotPassword = getState().forgotPassword.fields.forgotPassword.input
        let verificationCode = getState().forgotPassword.fields.verificationCode.input

        cognitoUser.confirmPassword(verificationCode, forgotPassword, {
            onSuccess: function (result) {
                dispatch(routerActions.route('/'))
            },
            onFailure: function (error) {
                dispatch(routerActions.route('/'))
            }
        })
    }
}

export const submitCognitoUserNewPassword = () => {
    return (dispatch, getState) => {
        let newPassword = getState().newPassword.fields.newPassword.input
        let userAttributes = getState().newPassword.userAttributes
        delete userAttributes.email_verified
        delete userAttributes.phone_number_verified
        // //console.log('submitCognitoUserNewPassword', {
        //     type: 'GET_COGNITO_USER_NEW_PASSWORD_AUTHENTICATION_DETAILS',
        //     newPassword: newPassword,
        //     userName: userAttributes.email,
        //     userAttributes: userAttributes
        // })
        dispatch({
            type: types.GET_COGNITO_USER_NEW_PASSWORD_AUTHENTICATION_DETAILS,
            payload: {
                type: 'GET_COGNITO_USER_NEW_PASSWORD_AUTHENTICATION_DETAILS',
                newPassword: newPassword,
                userName: userAttributes.email,
                userAttributes: userAttributes
            }
        })
    }
}

export const setCognitoUserNewPasswordDetails = (payload) => {
    //console.log('setCognitoUserNewPasswordDetails', payload)
    if (payload.response.success) {
        return async (dispatch, getState) => {
            let token = payload.response.accessToken.jwtToken//result.getAccessToken().getJwtToken()
            let privateKey = moment().format("YYYYMMDD-hhmmss")

            const jwt = await JwtHelper.encodeText(token, privateKey);
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Your password has been successfully reseted.',
                    overflowText: payload.response.idToken.payload.email,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })

            dispatch({
                type: types.COGNITO_USER_AUTHENTICATION_SUCCEEDED,
                payload: {
                    error: {},
                    result: payload.response,
                    token: token,
                    refresh: false,
                    jwt: jwt,//jwt.sign({ token: token }, privateKey),
                    datetime: privateKey,
                    refresh: true
                }
            })

            dispatch({
                type: types.GET_COGNITO_USER,
                payload: {
                    username: payload.response.idToken.payload.sub
                }
            })
            dispatch(componentActions.getComponentNames())
            dispatch(workflowActions.getWorkflowNames())

            dispatch(getCurrentCognitoUser(false))
        }
    } else {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'Error: System Notification',
                    additionalText: 'Password reset failed, please try again.',
                    overflowText: 'Or contact your system administrator',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}

export const setDeviceStatusRemembered = () => {
    return (dispatch) => {
        cognitoUser.setDeviceStatusRemembered({
            onFailure: (error) => {
                dispatch({
                    type: types.SET_DEVICE_STATUS_REMEMBERED_FAILED,
                    payload: {
                        error: error,
                        result: {}
                    }
                })
            },
            onSuccess: function (result) {
                dispatch({
                    type: types.SET_DEVICE_STATUS_REMEMBERED_SUCCEEDED,
                    payload: {
                        error: {},
                        result: result
                    }
                })
            }
        })
    }
}

export const setDeviceStatusNotRemembered = () => {
    return (dispatch) => {
        cognitoUser.setDeviceStatusNotRemembered({
            onFailure: (error) => {
                dispatch({
                    type: types.SET_DEVICE_STATUS_NOT_REMEMBERED_FAILED,
                    payload: {
                        error: error,
                        result: {}
                    }
                })
            },
            onSuccess: function (result) {
                dispatch({
                    type: types.SET_DEVICE_STATUS_NOT_REMEMBERED_SUCCEEDED,
                    payload: {
                        error: {},
                        result: result
                    }
                })
            }
        })
    }
}