import * as types from './types'
import * as helper from '../../helpers/components'
import * as workflowActions from '../WorkflowTaskStepComponentSingleSelector/actions'

export const selectRow = (component, row) => {
    return (dispatch, getState) => {
        
        //console.log('selectRowxx',component, row)
        const handle = getState().components[component]

        const info = handle.bubble !== null
            ? handle.bubble.split(',').reduce((arr, x) => {
                const parts = x.split(':')
                const value = row.original[parts[1]]

                if (value) {
                    arr.push({
                        title: parts[0],
                        field: parts[1],
                        value: value
                    })
                }

                return arr
            }, [])
            : []

        const field = handle.component_field.filter(x => x.key === true)

        const val = row.original[field[0].name]
        const prop = field[0].name
        const data = {
            val: val,
            prop: prop
        }

        dispatch({
            type: types.SELECT_WORKFLOW_GRID_ROW,
            payload: {
                component: handle,
                rows: [row],
                info: {
                    title: helper.getComponentTitle(handle, row.original),
                    description: helper.getComponentDescription(handle, row.original),
                    bubble: info
                }
            }
        })

        console.log('selectRowxxx dispatch1',{
            component: handle,
            rows: [row],
            info: {
                title: helper.getComponentTitle(handle, row.original),
                description: helper.getComponentDescription(handle, row.original),
                bubble: info
            }
        })

        console.log('selectRowxxx dispatch2',{
            component: handle,
            data: data
        })
        //console.log('selectRowx SET_SELECTED_WORKFLOW_GRID_ROW_PK',data)
        dispatch({
            type: types.SET_SELECTED_WORKFLOW_GRID_ROW_PK,
            payload: {
                component: handle,
                data: data
            }
        })
    }
}

export const selectMultipleRows = (component, rows) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SELECT_MULTIPLE_WORKFLOW_GRID_ROWS,
            payload: {
                component: component,
                rows: rows
            }
        })
    }
}


export const toggleView = (view,step,component) => {
    return (dispatch, getState) => {
        //console.log('toggleView wWorkflowGrid', view,step,component)
        dispatch(workflowActions.confirmSelection(step,component))
        dispatch(workflowActions.toggleView(view))
        
    }
}

export const confirmSelection = (step,component) => {
    return (dispatch, getState) => {
        dispatch(workflowActions.confirmSelection(step,component))
        
    }
}

export const setComponentGridPage = (component, page) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_WORKFLOW_COMPONENT_GRID_PAGE,
            payload: component,
            page: page
        })
    }
}

export const getComponentGridData_OFF = (component, link, paging, filter, id) => {
    return (dispatch, getState) => {
        const getRelated = () => {
            if (link) {
                const parent = getState().components[link.parent]
                const through = getState().components[link.through]

                const query = '?' + parent.component_field.filter(x => x.key)[0].name + '=' + link.id

                return {
                    component: through,
                    child: component,
                    query: query
                }
            }

            return undefined
        }

        const getQuery = () => {
            let query = paging.filtered.reduce((query, x) => {
                query.push(x.id.concat('=', x.value))
                return query
            }, [])

            return '?' + query.join('&')
        }

        const withRelatedId = (query) => {

            if (!id || !filter) {
                return query
            }

            const key = getState().components[filter].component_field.filter(x => x.key)[0]

            if (!key) {
                return query
            }

            return query.indexOf('?' > -1) && query.length > 1
                ? query + '&' + key.name + '=' + id
                : '?' + key.name + '=' + id
        }

        let query = '' //'?groupname=' + filter[0]._group //withRelatedId(getQuery())

        if (filter[0])
            query = '?groupname=' + filter[0]._group
        else
            query = withRelatedId(getQuery())
        const related = getRelated()

        query = query.indexOf('?' > -1) && query.length > 1
            ? query + '&deleted_at=null'
            : '?deleted_at=null'

            
        const payload = {
            id: id,
            component: !related ? component : related.component,
            input: '',
            paging: paging,
            sorting: paging.sorted.length > 0
                ? !paging.sorted[0].desc
                    ? paging.sorted[0].id
                    : '-' + paging.sorted[0].id
                : '',
            query: !related ? query : related.query,
            related: 'customeraddress.address,customercontact.contact', //component.related !== null ? component.related : '',
            child: !related ? {} : related.child
        }

        dispatch({
            type: types.GET_WORKFLOW_COMPONENT_GRID_DATA,
            payload: payload
        })
    }
}


export const getComponentGridData = (component, link, paging, filter, id) => {

    return (dispatch, getState) => {
        const getRelated = () => {
            if (link) {
                const parent = getState().components[link.parent]
                const through = getState().components[link.through]

                const query = '?' + parent.component_field.filter(x => x.key)[0].name + '=' + link.id

                return {
                    component: through,
                    child: component,
                    query: query
                }
            }

            return undefined
        }

        const getQuery = () => {
            let query = paging.filtered.reduce((query, x) => {
                query.push(x.id.concat('=', x.value))
                return query
            }, [])

            return '?' + query.join('&')
        }

        const withRelatedId = (query) => {

            if (!id || !filter) {
                return query
            }

            const key = getState().components[filter].component_field.filter(x => x.key)[0]

            if (!key) {
                return query
            }

            return query.indexOf('?' > -1) && query.length > 1
                ? query + '&' + key.name + '=' + id
                : '?' + key.name + '=' + id
        }

        let query = withRelatedId(getQuery())
        if (filter[0])
            query = '?groupname=' + filter[0].groupname

        const related = getRelated()

        //query = query.indexOf('?' > -1) && query.length > 1
        //? query + '&isactive=true'
        //: '?isactive=true'

        window.glyco.log('related')
        window.glyco.log(related)
        window.glyco.log(component.related !== null ? component.related : '')

        //console.log('WorkflowTaskStepComponentSingleSelector getComponentGridData', component)
        //console.log('WorkflowTaskStepComponentSingleSelector getComponentGridData OBJ', {
        //     id: id,
        //     component: !related ? component : related.component,
        //     input: '',
        //     paging: paging,
        //     sorting: paging.sorted.length > 0
        //         ? !paging.sorted[0].desc
        //             ? paging.sorted[0].id
        //             : '-' + paging.sorted[0].id
        //         : '',
        //     query: !related ? query : related.query,
        //     related: component.related !== null ? component.related : '',
        //     child: !related ? {} : related.child
        // })
        dispatch({
            type: types.GET_WORKFLOW_COMPONENT_GRID_DATA,
            payload: {
                id: id,
                component: !related ? component : related.component,
                input: '',
                paging: paging,
                sorting: paging.sorted.length > 0
                    ? !paging.sorted[0].desc
                        ? paging.sorted[0].id
                        : '-' + paging.sorted[0].id
                    : '',
                query: !related ? query : related.query,
                related: component.related !== null ? component.related : '',
                child: !related ? {} : related.child
            }
        })
    }
}


export const setComponentGridData = (payload) => {
    window.glyco.log('setComponentGridData')
    window.glyco.log(payload.data)

    return (dispatch) => {
        dispatch({
            type: types.SET_WORKFLOW_COMPONENT_GRID_DATA,
            payload: {
                component: payload.child.name ? payload.child : payload.component,
                data: payload.child.name
                    ? {
                        ...payload.data,
                        data: payload.data.data.map(x => {
                            return x[payload.child.name]
                        })
                    }
                    : payload.data
            }
        })
    }
}