import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox,CardHeader} from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import Section from '../../../controls/section'
import DateControl from '../../../controls/dateControl'

import * as actions from './actions'
import * as styles from './styles'

class SupplierMasterActivation extends Component {
   

    getView(data) {
        let documents = data?.documents?.documents ? data?.documents?.documents : []
        const contactsWithoutId = data.contacts.map(({ contacttype_id, ...cont }) => cont);
        const productsWithoutId = data.products.map(({ product_id, ...prod }) => prod);
        //console.log('contactsWithoutId12', contactsWithoutId)
        //console.log('productsWithoutId12', productsWithoutId)
        return <div>
        <Section
            title={"Supplier Details"}
            subtitle={window.t.en('Enter new supplier details')}
            /> 
            <Fields 
                fields={[
                    {size: 4, label: 'Supplier Name:', value: data.suppliername},
                    {size: 4, label: 'Trading Name:', value: data.tradingname},
                    {size: 4, label: 'Account Number:', value: data.accountnumber},
                    {size: 4, label: 'Registration Number:', value: data.registrationnumber},
                    {size: 4, label: 'VAT Number:', value: data.vatnumber},
                    {size: 4, label: 'Supplier Type:', value: data.suppliertype},
                    {size: 4, label: 'Company Type:', value: data.businesstype}

                ]}
                />
          <Section
            title={"Supplier Banking Details"}
            subtitle={window.t.en('Enter new supplier banking details')}
            /> 
            <Fields 
                fields={[
                    {size: 4, label: 'Name of Bank', value: data.fundername},
                    {size: 4, label: 'Account Name', value: data.bankaccountname},
                    {size: 4, label: 'Account Number', value: data.accountnumber},
                    {size: 4, label: 'Branch Code', value: data.bankbranchnumber},
                    {size: 4, label: 'Account Number', value: data.bankaccountnumber}
                ]}
                />
        <Section
            title={"Supplier Limits"}
            subtitle={window.t.en('Supplier limits')}
            /> 
           <Fields 
                fields={[
                    {size: 6, label: 'Monthly Limit:', value: data.monthlylimit}
                ]}
                />
            
        
        <Card
            title={"Supplier Address"}
            subtitle={window.t.en('Supplier address details')}
            content={<Fields data={data.address} columns={['Address Type', 'Address Line1', 'Address Line2', 'Address Line3', 'Address Line4']} />}
        /> 
        <Card
            title={"Supplier Contact"}
            subtitle={window.t.en('Supplier contact details')}
            content={<Fields data={contactsWithoutId}  columns={['Contact Type', 'Firstname', 'Lastname', 'Email Address', 'Contact Number']} />}
        /> 
        <Card
            title={"Supplier FICA"}
            subtitle={window.t.en('Uploaded supplier supporting documents')}
            content={<PdfViewer
                key={'supplier_fica'}
                name={'supplier_fica'}
                documents={documents}
                edit={false}
                multiple={false}
                workflow_queue_data={null}
            />}
        />
          <Card
            title={"Supplier Documents Checklist"}
            subtitle={window.t.en('Please ensure that all mandatory documents have been uploaded')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Proof of Insurance / Insurance Schedule? *'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <strong>{data.proof_of_insurance_recieved ? "YES" : "NO"}</strong>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Completed Service Provider Agreement? *'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <strong>{data.service_provider_agreement_recieved ? "YES" : "NO"}</strong>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Conflict of Interest Declaration? *'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <strong>{data.interest_decleration_recieved ? "YES" : "NO"}</strong>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Signed Supplier Acknowledgement of Procurement  and Anti Fraud Policies?'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <strong>{data.signed_proc_fraud_policies_recieved  ? "YES" : "NO"}</strong>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Landlords Waiver on File?'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <strong>{data.landlords_waiver_recieved ? "YES" : "NO"}</strong>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Confirmation of Banking Details / Cancelled Cheque?'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <strong>{data.confirmation_banking_details_recieved ? "YES" : "NO"}</strong>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Signed Code of Ethical Purchasing?'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <strong>{data.ethical_purchasing_recieved ? "YES" : "NO"}</strong>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Company Registration Document?'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <strong>{data.company_reg_document_recieved ? "YES" : "NO"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6} style={styles.selectedFieldName}>{'Valid Tax Clearance Certificates / Valid PIN? *'}</Col>
                        <Col xl={1} style={styles.selectedFieldName}>
                            <strong>{data.taxclearancecertificatereceived ? "YES" : "NO"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} style={styles.selectedFieldName}>
                            <br />
                        </Col>
                    </Row>
                </Container>
            }
       />
        </div>
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('SupplierMasterActivation', data.context_data.data.data.data[0].data[0])
        data = data.context_data.data.data.data[0].data[0]
        return <div>
            {this.getView(data.data.confirm)}
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.supplierMasterActivation
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierMasterActivation)