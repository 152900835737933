import React, { Component } from 'react'
import { connect } from 'react-redux'

import HomeWorkTwoToneIcon from '@mui/icons-material/Assessment';

class WFInHeader extends Component {

    getLayout() {
       return <div className="app-page-title app-page-title--shadow" style={{ marginBottom: '0px',padding: '20px 65px'}}>
            <div>
                <div className="app-page-title--first">
                {(
                    <div className="app-page-title--iconbox d-70">
                    <div className="d-70 d-flex align-items-center justify-content-center display-1">
                        <HomeWorkTwoToneIcon className="text-primary" />
                    </div>
                    </div>
                    )}
                    <div className="app-page-title--heading">
                        <h1>{this.props.title}</h1>
                        {
                            this.props.description ?
                                <div className="app-page-title--description">
                                    {this.props.description}
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center">
            {/* {children} */}
            </div>
        </div>
    }
render() {
    return this.getLayout()
 }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.empty }
    }
}

export default connect(mapStateToProps)(WFInHeader)
