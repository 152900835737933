import * as props from './props'
import * as types from './types'
export default (state = props, action) => {
    switch (action.type) {
        case types.UPDATE_VALUE:
            return {
                ...state,
                [action.prop]: action.payload,
            }
        case types.RESET_COMPONENT:
            return {
                ...state,
                payment_list: [],
                timeline: null,
                show_all: false
            }
        case types.SET_PAYMENT_DATA_LIST_GM:
            return {
                ...state,
                payment_list: action.payload
            }
        case types.SET_PROP_INDEX_GM_EXCEPTION:
            return {
                ...state,
                exception_prop_inx: action.payload
            }
        case types.TOGGLE_SHOW_ALL:
            return {
                ...state,
                show_all: action.payload ? false : true
            }
        case types.SET_PAYMENT_APPROVAL_DATA_LIST:
            return {
                ...state,
                payment_list: action.payload
            }
        case types.SET_MAIN_PAYMENT_DATA_GM:
            return {
                ...state,
                main_payment_list: action.payload
            }
        case types.SET_EXCEPTION_DATA_LIST_GM:
            return {
                ...state,
                exception_list: action.payload
            }
        case types.SET_PAYMENT_DATA_LIST_MAINT_GM:
            return {
                ...state,
                payment_list_maint: action.payload
            }
        case types.SET_PAYMENT_DATA_LIST_TYRES_GM:
            return {
                ...state,
                payment_list_tyres: action.payload
            }
        case types.SET_BUSINESSLIST:
            return {
                ...state,
                businessUnits: action.payload,
            }
        case types.SET_SELECTED_ITEM:
            return {
                ...state,
                [action.payload.prop]: action.payload.values,
            }
        case types.ADD_BLANK:
            return {
                ...state,
                [action.payload.prop]: action.payload.obj,
            }
        case types.UPDATE_ITEM_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.data,
            }
        case types.SET_PROP_INDEX_GM:
            return {
                ...state,
                prop_inx: action.payload
            }
        case types.SET_PROP_INDEX_SUB:
            return {
                ...state,
                sub_prop_inx: action.payload
            }
        case types.SET_PROP_DRAWER_INDEX_GM:
            return {
                ...state,
                prop_drawer: action.payload
            }
        case types.SET_SELECTED_VEHICLE_DETAILS_GM:
            return {
                ...state,
                selectedVehicleDetails: {
                    ...state.selectedVehicleDetails,
                    data: action.payload.data,
                    valid: true
                }
            }
        case types.SET_LOADED_PAYMENT_GM:
            return {
                ...state,
                [action.payload.data_type]: action.payload.data
            }
        case types.SET_GM_SUPPLIER_PAYMENT_LIST:
            return {
                ...state,
                supplier_list: action.payload
            }
        case types.SET_FILTERED_DATA:
            return {
                ...state,
                payment_list_filtered: action.payload
            }
        case types.SET_FILTERED_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.SET_PROP_INDEX_SUB_AUTH:
            return {
                ...state,
                sub_prop_inx: action.payload
            }
        case types.RESET_FILTERED_VALUES:
            return {
                ...state,
                payment_list_filtered: [],
                greaterOrLessThan: null,
                greaterOrLessThanAmount: null,
                highestOrLowest: null,
                highestOrLowestAmount: null,
                vehicleReoccurence: null,
                shortpayments: null,
                searchValue: null,
                invoicedGreaterThanAuth: null
            }
        default:
            return state
    }
}