import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { Card, CardHeader, CardContent, TextField, Button, IconButton } from '@mui/material'

import ReactTable from "react-table"
import "react-table/react-table.css"

import Icon from '../Icon/component'
import Loading from '../Loading/component'
import * as gridAction from '../Grid/actions'

import * as actions from './actions'
import * as styles from './styles'

class BusinessStructureGrid extends Component {

    getCommands(row) {
        let field = this.props.state.businessStructure.component_field.filter(x => x.key === true)[0]
        const id = row.original[field.name]
        let i = row.index
        let commands = []
        commands.push(
            <IconButton key={'command_view_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
                tooltip={'Vehicle details'} color="primary"
                onClick={
                    () => {
                        //for(let x of this.props.state.businessStructure.component_field){
                        //    //console.log(x.name)
                        //    if(row.original[x.name])
                        //    this.props.actions.setComponentFieldInput(x,row.original[x.name])
                        //}
                        this.props.actions.getbusinessStructureData(id)
                        this.props.actions.changeTabView(2)
                    }
                }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        commands.push(
            <IconButton key={'command_view_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
                tooltip={'Vehicle details'} color="primary"
                onClick={
                    () => {
                        //for(let x of this.props.state.businessStructure.component_field){
                        //    //console.log(x.name)
                        //    if(row.original[x.name])
                        //    this.props.actions.setComponentFieldInput(x,row.original[x.name])
                        //}
                        this.props.actions.getbusinessStructureData(id)
                        this.props.actions.changeTabView(1)
                    }
                }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'mode_edit'} />
            </IconButton>
        )
        
        commands.push(
            <IconButton key={'command_decision_' + field.name + '_' + id} style={styles.iconButton} iconStyle={styles.actionIcon}
            tooltip={'Manage card'}
            onClick={(event) => {
                    this.props.actions.toggleDeleteConfirm(id)
                }
            }>
                <Icon  istyle={{ fontSize: '22px', color: '#253053' }}  ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
            </IconButton>
        )
        

        return commands
    }

    getValue(row) {  
        let field = this.props.state.businessStructure.component_field.filter(x => x.name === row.column.meta)[0] 
        var value = field.related 
            ? row.original[field.display.split('.')[0]] ? row.original[field.display.split('.')[0]][field.display.split('.')[1]] : ''
            : row.original[field.name]
        
        //console.log(field.name,field)
            switch (field.component_field_data_type.name) {
                case 'month':
                    return moment(value).format('MM')
                case 'integer':
                    return value ? value : 0 
                case 'date':
                    return moment(value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value

                    return value !== null
                        ? value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(value)
                        ? Number(value).toFixed(2).toString()
                        : value
                default:
                    return value
            }
    }

    getFormFieldLabel(row) {
          return <div
          style={{
            width: '100%',
            marginTop: '3px',
            verticalAlign: 'baseline',
            fontSize: '15px'
        }} >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.businessStructure.component_field.filter(x => x.grid === true)
        //console.log('fields',fields)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.display,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                   // width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        //: x.bold ? 'bold' : 'normal',
                        textAlign:  'left'
                    },
                    Cell: (row) => (
                          this.getFormFieldLabel(row)
                    )
                }
            })

            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 110,
                width: 110,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        return columns
    }

    getGrid() {
        return <div>
            <ReactTable
                data={this.props.state.businessStructure.grid.data}
                columns={this.getColumns()}
                manual
                loading={this.loading}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
                onFetchData={
                    (state) => {
                        this.props.actions.getComponentGridData(this.props.state.businessStructure, this.props.state.businessStructure.link, null, this.props.state.businessStructure.filter, this.props.state.businessStructure.id)
                    }
                }
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>

        </div>
    }

    getLayout() {
        return 1 !== 1
            ? <Loading message={'Loading....'} />
            : <Card initiallyExpanded={true}>
                <CardHeader
                    avatar={<Icon iclass={'custom-icons'} iname={'organization'} />}
                    title={'Budget Management '}
                    subtitle={'Manage Budget Management.'}
                />
                <CardContent expandable={false}>
                   { this.getGrid() }
                </CardContent>
            </Card>
    }

    render() {
        return this.props.state.businessStructure.loading
                ? <Loading message={'Loading component...'} />
                : this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.businessStructure,
            businessStructure: {
                ...state.components.businessStructure
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...gridAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessStructureGrid)