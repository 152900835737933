import * as types from './types'
import * as routerActions from 'react-router-redux'

export const route = (path, _history, error) => {
    console.log('routex path', path)
    console.log('routex typeof', (typeof _history))
    console.log('routex _history', _history)
    console.log('routex error', error)
    return (dispatch) => {
        if(path == '/support') {
            console.log('Redirect Route Error', path, _history, error)
        } else {
            dispatch(routerActions.push(path)) 
        }

        if (error) {
            window.glyco.log(error)

            if (error.message && !error.info) {
                dispatch({
                    type: types.ROUTE,
                    payload: {
                        path: path,
                        history: _history,
                        error: {
                            message: error.message,
                            info: error.error ? error.error.stack : ''
                        }
                    }
                })
            }
            else {
                dispatch({
                    type: types.ROUTE,
                    payload: {
                        path: path,
                        history: _history,
                        error: {
                            message: error.message,
                            info: error.info.componentStack
                        }
                    }
                })
            }
        }
        else {
            dispatch({
                type: types.ROUTE,
                payload: {
                    path: path,
                    history: _history
                }
            })
        }
    }
}

export const logError = () => {

}