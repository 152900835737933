import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'

export const getWorkflowNamesEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.WORKFLOWS_GET_WORKFLOW_NAMES),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/workflow/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: {
                    query: 'workflow_names',
                    team_ids: store.value.accounts.teams.map(t => t.team_id)
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflowNames(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>{
            //console.log('#################################################################################################',error)
        }
           // of$(routerActions.route('/support', false, error))
        )
        )

export const getWorkflowEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.WORKFLOWS_GET_WORKFLOW)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/workflow/query?name=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'all'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflow(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/workflow/query?name=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/workflow/query?name=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>{
            //console.log('#################################################################################################',error)
        }
           // of$(routerActions.route('/support', false, error))
        )
        )
