export const BI_READY = 'BI_READY'
export const INTELLIGENCE_LOADING = 'INTELLIGENCE_LOADING'
export const INTELLIGENCE_LOADED = 'INTELLIGENCE_LOADED'

export const SET_REPORT_CATEGORY_LIST = 'SET_REPORT_CATEGORY_LIST'
export const GET_REPORT_CATEGORY_LIST = 'GET_REPORT_CATEGORY_LIST'
export const SET_REPORT_VIEW = 'SET_REPORT_VIEW'
export const SET_REPORT_KEY = 'SET_REPORT_KEY'
export const SET_BASHBOARD_VIEW = 'SET_BASHBOARD_VIEW'
export const GET_REPORT_GRID_DATA_BY_REPORT_NAME = 'GET_REPORT_GRID_DATA_BY_REPORT_NAME'
export const SET_REPORT_GRID_DATA_BY_REPORT_NAME = 'SET_REPORT_GRID_DATA_BY_REPORT_NAME'

export const SET_SCHEDULE_WINDOW_OPEN_AND_CLOSE = 'SET_SCHEDULE_WINDOW_OPEN_AND_CLOSE'
export const SET_COMPONENT_INPUT_FIELD_VALUE = 'SET_COMPONENT_INPUT_FIELD_VALUE'
export const SAVE_COMPONENT_SCHEDULE_USER_DATA = 'SAVE_COMPONENT_SCHEDULE_USER_DATA'

export const RESET_COMPONENT_SCHEDULER_FORM_FIELDS = 'RESET_COMPONENT_SCHEDULER_FORM_FIELDS'
export const LAOD_SELECTED_SCHEDULE_FOR_VIEW_OR_EDIT = 'LAOD_SELECTED_SCHEDULE_FOR_VIEW_OR_EDIT'

export const GET_COMPONENT_REPORT_SCHEDULER_BY_USER_AND_REPORT = 'GET_COMPONENT_REPORT_SCHEDULER_BY_USER_AND_REPORT'
export const SET_COMPONENT_REPORT_SCHEDULER_BY_USER_AND_REPORT = 'SET_COMPONENT_REPORT_SCHEDULER_BY_USER_AND_REPORT'
export const SET_REPORT_GRID_DATA_BY_FILTER = 'SET_REPORT_GRID_DATA_BY_FILTER'
export const GET_REPORT_SUB_CATEGORY_LIST = 'GET_REPORT_SUB_CATEGORY_LIST'
export const SET_SUB_REPORT_CATEGORY_LIST = 'SET_SUB_REPORT_CATEGORY_LIST'



