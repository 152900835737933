import _ from 'lodash'

import * as types from './types'
import * as config from '../../config'

export const readComponent = (name, surrogate) => {
    return (dispatch, getState) => {
        if (surrogate) return

        dispatch({
            type: types.CLEAR_COMPONENT_RELATIONSHIPS,
            component: name
        })

        dispatch({
            type: types.READ_COMPONENT,
            payload: name
        })
        //console.log('name', name)
        //console.log('getState().router.location >>>>>>>>>>>>>>>>>>>>>>>>>>>>>',getState().router)
        if(getState().router.location.pathname) {
            
          //console.log('getRelatedGrid getComponentRelationships 1022')
            if (getState().router.location.pathname.indexOf('/view/') > -1) {
                dispatch(getComponentRelationships(getState().components[name]))
                dispatch(getComponentTitle(getState().components[name]))
                dispatch(getComponentDescription(getState().components[name]))
                dispatch(getComponentImages(getState().components[name]))
                dispatch(getComponentDocuments(getState().components[name]))
            }
        }
    }
}

export const getComponentDescription = (component) => {
    return {
        type: types.READ_COMPONENT_SET_DESCRIPTION,
        payload: getComponentDisplayText(component, component.concatDescription)
    }
}

export const getComponentDocuments = (component) => {
    return {
        type: types.READ_COMPONENT_SET_DOCUMENTS
    }
}

export const getComponentImages = (component) => {
    const getValueByKey = (object, key) => {
        let result

        _.some(object, function matchKey(value, $key) {
            if ($key === key) {
                result = value
                return true
            } else if (_.isObject(value)) {
                return _.some(value, matchKey)
            }
        });

        return result
    }

    let images = getValueByKey(component.data, 'imagefilename')

    images = Array.isArray(images)
        ? images.map(x => {
            return config.system.cdn + x
        })
        : images
            ? [config.system.cdn + images]
            : []

    return {
        type: types.READ_COMPONENT_SET_IMAGES,
        payload: images.length ? images : []
    }
}

export const getComponentRelationships = (component) => {
    //console.log('getRelatedGrid getComponentRelationships getComponentRelationships')
    return (dispatch, getState) => {
        if (!component.relatedFull.hasMany) {
            dispatch({
                type: types.READ_COMPONENT_SET_RELATIONSHIPS,
                payload: []
            })

            return
        }

        const related = component.relatedFull.hasMany
            .filter(x => x.key.indexOf('document') === -1) //get all relationships besides documents
            .reduce((arr, x) => {
                const existingComponent = getState().components[x.key]

                if (existingComponent) {
                    arr.push(existingComponent)
                }

                return arr
            }, [])

        dispatch({
            type: types.READ_COMPONENT_SET_RELATIONSHIPS,
            payload: related
                ? related.sort((x, y) => {
                    const a = x.title.toUpperCase()
                    const b = y.title.toUpperCase()

                    return (a < b)
                        ? -1
                        : (a > b)
                            ? 1
                            : 0
                })
                : []
        })
    }
}

export const getComponentTitle = (component) => {
    return {
        type: types.READ_COMPONENT_SET_TITLE,
        payload: getComponentDisplayText(component, component ? component.concatTitle : '')
    }
}

export const setReadComponentView = (view, grid, component) => {
    return (dispatch, getState) => {
        /*         if (view === 'create') {
                    dispatch({
                        type: types.READ_COMPONENT_CLEAR_COMPONENT_DATA,
                        payload: component
                    })
                } */

        dispatch({
            type: types.READ_COMPONENT_SET_VIEW,
            payload: {
                view: view,
                grid: grid
            }
        })
    }
}

export const toggleRelatedComponentsMenu = () => {
    return {
        type: types.READ_COMPONENT_TOGGLE_RELATED_COMPONENTS_MENU
    }
}

const getComponentDisplayText = (component, key) => {
    if (key == null || !component) return ''

    const fields = key.split(',').map(x => {
        if (x.indexOf('_id') > -1 && x.indexOf('.') > -1) {
            const field = component.component_field.filter(y => y.name === x.split('.').pop())[0]
            if(!field) return ''
            const accessors = field.display ? field.display.split('|') : field.name
            const parts = accessors[0].split('.')[0]

            return accessors.map(y => {
                return y
                    .split(',')
                    .map(z => {
                        return _.get(component.data[parts], z.replace(parts + '.', ''))
                    })
                    .join(' ')
            }).join(', ')
        }
        else {
            return component.data[x.replace(component.name + '.', '')]
                ? component.data[x.replace(component.name + '.', '')].input
                : ''
        }
    })

    return fields.join(' ')
}

export const getRelatedComponentData = (component, related, key, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.READ_COMPONENT_GET_RELATED_COMPONENT_DATA,
            payload: {
                component: component,
                related: related,
                key: key,
                id: id
            }
        })
    }
}

export const setRelatedComponentData = (component, related, result) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.READ_COMPONENT_SET_RELATED_COMPONENT_DATA,
            payload: {
                component: component,
                related: related,
                result: result
            }
        })
    }
}