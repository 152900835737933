
import * as types from './types'

export const getTableRow = (id) => {
    return {
        type: types.GET_TABLE_ROW,
        payload: id
        
    }
}

export const setTableRow = (data) => {
    return {
        type: types.SET_TABLE_ROW,
        payload: data
    }
}

