import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.WORKFLOW_QUEUE_TASK_DOCUMENTS_SET_WORKFLOW_QUEUE_TASK_DOCUMENT_STATE:
            return {
                ...state,
                ['workflow_queue_task_id_' + action.payload]: {
                    loading: false,
                    data: []
                }
            }
        case types.WORKFLOW_QUEUE_TASK_DOCUMENTS_GET_WORKFLOW_QUEUE_TASK_DOCUMENTS:
            return {
                ...state,
                ['workflow_queue_task_id_' + action.payload]: {
                    loading: true,
                    data: []
                }
            }
        case types.WORKFLOW_QUEUE_TASK_DOCUMENTS_SET_WORKFLOW_QUEUE_TASK_DOCUMENTS:
            return {
                ...state,
                ['workflow_queue_task_id_' + action.payload.workflow_queue_task_id]: {
                    loading: false,
                    data: action.payload.data
                }
            }
        default:
            return state
    }
}