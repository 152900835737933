import * as actions from './actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as config from '../../config'



// export const getLookupValueData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
// action$.pipe(
//         ofType(types.GET_COMPONENT_CREATE_STR_DATA_FIELD_INPUT_LIST),
//         delay(0)
//         ,mergeMap(({ payload }) =>
//             ajax$({
//                 url: config.system.api + '/components/' + payload.table + '/search?' + payload.field + '=' + payload.search,
//                 method: 'GET',
//                 headers: {
//                     'tokenx': store.value.user.user_id,
//                    'auth': store.value.cognito.authenticateUser.token,
//                     'salt': store.value.cognito.authenticateUser.jwt,
//                     'datetime': store.value.cognito.authenticateUser.datetime,
//                     'related': payload.related
//                 },
//             })
//                 .pipe(
//             mergeMap((result) => [
//                     actions.setLookupValueData(result.response, payload),
//                     requestActions.requestSucceeded(config.system.api + '/components/' + payload.table + '/search?' + payload.field + '=' + payload.search)
//                 ])
//                 //,startWith(progressBarActions.progress(true))
//                 ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.table + '/search?' + payload.field + '=' + payload.search, error)))
//                 //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
//         )
//         )
//         ,catchError((error) =>
//             of$(routerActions.route('/support', false, error))
//         )
//         )


export const getDashboard = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_STR_DASHBOARD),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/strratecard/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
            mergeMap((result) => [
                    actions.setDashboard(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/' + payload.table + '/search?' + payload.field + '=' + payload.search)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.table + '/search?' + payload.field + '=' + payload.search, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
