export const contacttypes = [{ text: 'Fleet Manage', value: 1 }, { text: 'Fleet Account', value: 2 }]
export const addresstypes = [{ text: 'Physical', value: 1 }, { text: 'Postal', value: 2 }]
export const jobtitle_data = []
export const address = []
export const contacts = []
export const organisationtype_data = []
export const empty = true
export const customername = ''
export const tradingname = ''
export const accountnumber = ''
export const registrationnumber = ''
export const vatnumber = ''
export const costcentre = ''
export const bidvestgroup = false
export const non_reporting = false
export const creditlimit = ''
export const turnoverindividualtype_data = []
export const turnoverbusinesstype_data = []
export const sourceoffunds_data = []
export const sourceofwealth_data = []
export const country_data = []
export const relationship_period = ''
export const politically_exposed_person = false
export const domestic_prominent_influential_person = false
export const foreign_prominent_public_official = false
export const ultimate_beneficial_owner = ''
export const related_parties = ''
export const standard_industrial_classification = ''
export const enhanced_due_diligence = false
export const facilityrenewalperiod_data = []
export const title_data = []
export const gender_data = []
export const user_data = []
export const region_data = []
export const branch_data = []
export const facilitylastreviewdate = ''
export const facilitynextreviewdate = ''
export const individualturnover = ''
export const businessturnover = ''
export const acccontact = []

export const leadid = ''
export const contactnumber = ''
export const contactrole = ''
export const contactemail = ''
export const building = ''
export const streetaddress = ''
export const city = ''
export const province = ''
export const pcode = ''
export const registrationno = ''
export const contracttype_data = []
export const contracttype = ''
export const contracttype_id = ''

export const fields = {
  // costcentre: {
  //   state: 'getState().salesProcessCapture.costcentre',
  //   errorText: 'Please enter Cost Centre',
  //   valid: false,
  //   popup: false,
  // },
  // vatnumber: {
  //   state: 'getState().salesProcessCapture.vatnumber',
  //   errorText: 'Please enter VAT Number',
  //   valid: true,
  //   popup: false,
  // },
  // accountnumber: {
  //   state: 'getState().salesProcessCapture.accountnumber',
  //   errorText: 'Please enter Account Number',
  //   valid: false,
  //   popup: false,
  // },
  // tradingname: {
  //   state: 'getState().salesProcessCapture.tradingname',
  //   errorText: 'Please enter Trading Name',
  //   valid: false,
  //   popup: false,
  // },
  customername: {
    state: 'getState().salesProcessCapture.customername',
    errorText: 'Please enter Customer Name',
    valid: false,
    popup: false,
  },
};

// accessorySupplier: {
//   errorText: 'Please ensure Accessory Supplier is selected',
//   state: 'getState().workflowQuoteItemGrid.data',
//   data: false,
//   valid: false,
//   empty: true,
//   popup: false,
//   type: 'table',
//   tableFields: ['supplier'],
//   validations: [validatorTypes.IS_INT]
// },




