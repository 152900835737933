import * as actions from './actions'
import * as types from './types'
import * as config from '../../config'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'

export const savePublishReleaseNote = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.SAVE_RELEASE_NOTE,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/releasenote/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .pipe(
                    mergeMap((result) => {
                        return(
                            [
                                requestActions.requestSucceeded(config.system.api + '/components/releasenote/other')
                            ]
                        )
                    })
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/releasenote/other', error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) => {
            //console.log('#################################################################################################', error)
        }
            // of$(routerActions.route('/support', false, error))
        )
    )

export const updateReadNote = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
    types.UPDATE_READ_NOTE,
)
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/user/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => {
                    return(
                        [
                            requestActions.requestSucceeded(config.system.api + '/components/user/other')
                        ]
                    )
                })
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
    )
    , catchError((error) => {
        //console.log('#################################################################################################', error)
    }
        // of$(routerActions.route('/support', false, error))
    )
)

export const updateUserReadNote = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
    types.UPDATE_USER_READ_NOTE,
)
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/releasenote/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => {
                    return(
                        [
                            requestActions.requestSucceeded(config.system.api + '/components/releasenote/other')
                        ]
                    )
                })
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/releasenote/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
    )
    , catchError((error) => {
        //console.log('#################################################################################################', error)
    }
        // of$(routerActions.route('/support', false, error))
    )
)

export const getReleaseNote = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_RELEASE_NOTE),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/releasenote/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            }).pipe(
                mergeMap((payload) =>{
                    return  [
                        actions.setReleaseNote(payload.response),
                        requestActions.requestSucceeded(config.system.api + '/components/releasenote/search')
                    ]
                })
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/releasenote')))
            )
        )
        , catchError((error) =>{

        }  //of$(routerActions.route('/support', false, error))
        )
    )