import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'
import * as types from './types'

export const getWorkflowVehicleListEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_WORKFLOW_VEHICLE_LIST)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search?licenseplateno=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'client-id': store.value.accounts.selectedAccount,
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflowVehicleList(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search?licenseplateno=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search?licenseplateno=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

export const getWorkflowVehicleHistoryEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_WORKFLOW_VEHICLE_HISTORY)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search?vehicle_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'client-id': store.value.accounts.selectedAccount,
                    related: 'maintenancetransaction.maintenancetransactionitem, customercontract.customercontractderivative,mmcode'
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkflowVehicleHistory(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search?vehicle_id=' + payload)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search?vehicle_id=' + payload, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )