import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../Icon/component'

import * as actions from '../Form/actions'
import * as styles from './styles'

class WorkflowViewComponent extends Component {
    // componentDidMount() {
    //     this.props.actions.loadComponent(this.props.name, this.props.id)
    // }


    getLookupView(data) {
        window.glyco.log('getLookupView')
        window.glyco.log(data)

        return <Container style={{ margin: 0 }} className="custom-container">
            <Row><Col xl={2}><br /></Col></Row>
            <Row>
                <Col xl={2} style={styles.selectedFieldName}>
                    {'Item Type'}
                </Col>
                <Col xl={2} style={styles.selectedFieldName}>
                    {'Item Name'}
                </Col>
                <Col xl={2} style={styles.numericFields}>
                    {'Quantity'}
                </Col>
                <Col xl={2} style={styles.numericFields}>
                    {'Cost Price'}
                </Col>
                <Col xl={2} style={styles.numericFields}>
                    {'Selling Price'}
                </Col>
                <Col xl={2} style={styles.numericFields}>
                    {'Total Price'}
                </Col>
            </Row>
            {data.filter(x => x.itemname).map(x => {
                return <Row>
                    <Col xl={2}>
                        {x.itemtype}
                    </Col>
                    <Col xl={2}>
                        {x.itemname}
                    </Col>
                    <Col xl={2} style={styles.numericFieldName}>
                        {parseFloat(x.quantity).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Col>
                    <Col xl={2} style={styles.numericFieldName}>
                        {parseFloat(x.costprice.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Col>
                    <Col xl={2} style={styles.numericFieldName}>
                        {parseFloat(x.sellingprice.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Col>
                    <Col xl={2} style={styles.numericFieldName}>
                        {parseFloat(x.totalprice.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Col>
                </Row>
            })
            }
        </Container>
    }

    getFieldValue(field, index) {
        if (typeof this.data[field.name].input === 'object') {
            return ''
        }

        const value = this.data[field.name].input

        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return value
            case 'datePicker':
                return moment(value).format('LLLL') + ', ' + moment(value).fromNow()
            case 'hidden':
                return
            case 'timePicker':
                return moment(value).format('LLLL') + ', ' + moment(value).fromNow()
            case 'textBox':
                return value
            case 'toggle':
                return value
            default:
                return
        }
    }

    getField(field, index) {
        return this.getFieldValue(field, index)
    }

    getSection(section, index) {
        const fields = section.component_field
            .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
            .filter(x => !x.related) //x.grid &&
            .map((x, i) => {
                return <Row
                    key={'row_' + section.name + '_' + x.name + '_' + i}
                    className={'interactiveRow'}
                >
                    <Col xl={4} style={styles.selectedFieldName}>
                        {x.title}
                        {/* <Icon iclass={x.icon.unicode} iname={x.icon.name} /> &nbsp;&nbsp;{x.title.substring(0, 15)} {x.title.length > 15 ? '...' : ''} */}
                    </Col>
                    <Col>
                        {
                            this.getField(x, i)
                        }
                    </Col>
                </Row>
            })

        return <Card key={'card_' + section.name} initiallyExpanded={index === 0}>
            <CardHeader
                // avatar={<Icon iclass={section.icon.unicode} iname={section.icon.name} />}
                title={window.t.en(section.title)}
                subtitle={window.t.en(section.description)}
                actAsExpander={true}
                showExpandableButton={true}
                style={styles.row}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    {fields}
                </Container>
            </CardContent>
        </Card>
    }

    getSectionView() {
        return this.data
            ? this.component.sections
                .sort((x, y) => x.order - y.order)
                .map((x, i) => {
                    return this.getSection(x, i)
                })
            : 'Loading...'
    }

    render() {
        this.component = this.props.state

        this.data = this.props.data

        return (
            this.props.data
                ? this.getLookupView(this.data)
                : this.getSectionView()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: { ...state.components[ownProps.name] }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowViewComponent)