import * as types from './types'
import * as appTypes from '../App/types'
import * as userTypes from '../User/types'
import * as accTypes from '../Account/types'

import moment from 'moment'
import { toUpper } from 'lodash-es'


export const setAccInputField = (data) => {
    return (dispatch) => {
        dispatch({
            type: accTypes.SET_USER_ACCOUNT_DEFAULT_SETTING_INPUT,
            payload: data
        })
    }
}

export const setUserInputField = (data) => {
    return (dispatch) => {
        dispatch({
            type: userTypes.SET_USER_COMPONENT_INPUT_FIELD_VALUE,
            payload: data
        })
    }
}

export const updateUserDefaultLandingPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_USER_DEFAULTS,
            payload: {
                type: 'UPDATE_USER_DEFAULTS',
                user_id: getState().user.user_id,
                defaultlandingpage: page,
                name: 'defaultlandingpage'
            }
        })
    }
}

export const updateUserDefaultDashboard = (_dash) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_USER_DEFAULTS,
            payload: {
                type: 'UPDATE_USER_DEFAULTS',
                user_id: getState().user.user_id,
                defaultdashboardkey: _dash,
                name: 'defaultdashboardkey'
            }
        })
    }
}

export const updateUserDefaultLanguage = (locale) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_USER_DEFAULTS,
            payload: {
                type: 'UPDATE_USER_DEFAULTS',
                user_id: getState().user.user_id,
                defaultlocale: locale,
                name: 'defaultlocale'
            }
        })
    }
}

export const setUserDefaultDashboard = (paylaod) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'User Default has been updated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const getDashboardList = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_PROFILE_DASHBOARD_DATA
        })
    }
}
export const setDashboardList = (data) => {
    //console.log('setDashboardList', data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_PROFILE_DASHBOARD_DATA,
            payload: data.data
        })
    }
}


export const setUser = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: userTypes.SET_USER,
            payload: payload.response.data
        })

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'User Default has been updated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const updateUserDetails = () => {
    return (dispatch, getState) => {
        const lastname = getState().userProfile.userDetails.lastName
        const firstname = getState().userProfile.userDetails.firstName

        if(lastname && lastname.length > 0){
            dispatch({
                type: types.UPDATE_USER_DEFAULTS,
                payload: {
                    type: 'UPDATE_USER_DEFAULTS',
                    user_id: getState().user.user_id,
                    lastname : getState().userProfile.userDetails.lastName,
                    name: 'lastname'
                }
            })
        }
       if(firstname && firstname.length > 0){
            dispatch({
                type: types.UPDATE_USER_DEFAULTS,
                payload: {
                    type: 'UPDATE_USER_DEFAULTS',
                    user_id: getState().user.user_id,
                    firstname : getState().userProfile.userDetails.firstName,
                    name: 'firstname'
                }
            })
       }
    }
}
export const handleChange = (key,value) =>{
    return (dispatch,getState) =>{
        dispatch({
            type : types.SET_USER_DETAILS,
            payload : {
                data : {
                    ...getState().userProfile.userDetails,
                    [key] : value
                },
                prop : 'userDetails' 
            }
        })
    }
}