import Button from '@mui/material/Button'
import * as _ from 'lodash'
import Card from '../../../controls/card'

import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import Icon from '../../Icon/component'
import PdfViewer from '../../PDF/component'
import Upload from '../../Upload/component'
import * as actions from './actions'
import * as gridActions from '../AdhocGrid/actions'
import * as styles from './styles'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'
let maintenancetransaction_id = 0

let supplier_id = 0

class PURCHASEORDER extends Component {
    componentDidMount() {
        //   this.component = this.props.state
        this.actions = this.props.actions;
    }

    getGrid() {
        let updatedamt = false
        let updatedno = false
        return <AdhocGrid
            key={'key_adhoc_grid_purchaseorder'}
            name={'purchaseorder_grid'}
            uri={'/components/purchaseorder/other'}
            filterable={true}
            sortable={true}
            body={{
                type: 'get_completed_pos',
                supplier_id: supplier_id
            }}
            style={{fontSize: '14px'}}
            search={''}
            columns={[
                { accessor: 'suppliername', Header: 'Supplier', filterable: true, width: 230, Cell: row => {
                    return (
                        <div style={{padding: '10px'}}>
                            {row.original['suppliername']}<br/>
                            {row.original['maintenancetype']}
                        </div>
                    )
                } },
                { accessor: 'licenseplateno', Header: 'Vehicle Reg', width: 150, filterable: true, Cell: row => {
                    return (
                        <div style={{padding: '10px'}}>
                            {row.original['licenseplateno']}<br/>
                            Rebill: {row.original['rebill'] ? 'Yes' : 'No'} {row.original['ponumber']}
                        </div>
                    )
                }  },
                { accessor: 'authnumber', Header: 'Auth Number', width: 200, filterable: true, Cell: row => {
                    return (
                        <div style={{padding: '10px'}}>
                            {row.original['authnumber']}<br/>
                            Amount Incl: {row.original['totalamount']}
                        </div>
                    )
                }  },
                { accessor: 'completeddate', Header: 'Completed Date', width: 200, filterable: true, Cell: row => {
                    return (
                        <div style={{padding: '10px'}}>
                            {row.original['completeddate'] ? moment(row.original['completeddate']).format('YYYY-MM-DD') : ''}<br/>
                            Status: {row.original['maintenancestatus']}
                        </div>
                    )
                } },
                {
                    accessor: 'invoicedate', Header: 'Invoice Date', filterable: false, width: 180, Cell: row => {
                        return (
                            <div style={{margin:'-15px 0'}}>
                                <DateControl
                                    variant="outlined"
                                    margin="normal"
                                    id={'txt_transactiondate'}
                                    hintText='Date'
                                    label='Enter Date'
                                    container='inline'
                                    mode='landscape'
                                    value={row.original['invoicedate'] ? moment(new Date(row.original['invoicedate'])).format('YYYY-MM-DD') : null}
                                    onSelect={(args) => {
                                        this.props.actions.adhocGridSetCellInputValue('purchaseorder_grid', 'invoicedate', row.viewIndex, args)
                                    }}
                                    type={'date'}
                                />
                            </div>
                        )
                    }
                },
                {
                    accessor: 'invoicenumber', Header: 'Invoice #', filterable: false, width: 150, Cell: row => {
                        return <TextField
                            variant="outlined"
                            id={'txt_invoicenumber_' + row.viewIndex}
                            key={'txt_invoicenumber_' + row.viewIndex}
                            size={'small'}
                            label='Enter Invoice No'
                            value={row.original['invoicenumber']}
                            onChange={(event) => {
                                this.props.actions.adhocGridSetCellInputValue('purchaseorder_grid', 'invoicenumber', row.viewIndex, event.target.value)
                                //maintenancetransaction_id = row.original['maintenancetransaction_id']
                                //this.props.actions.refresh_invno(event.target.value)
                                //row.original['invoicenumber'] = (this.props.state.invoicenumber)
                            }}
                        />
                    }
                },
                {
                    accessor: 'invoiceamount', Header: 'Amount', filterable: false, width: 150, Cell: row => {
                        return <TextField
                            variant="outlined"
                            id={'txt_invoiceamount_' + row.viewIndex}
                            key={'txt_invoiceamount_' + row.viewIndex}
                            size={'small'}
                            label={'Enter Amount'}
                            value={row.original['invoiceamount']}
                            onChange={(event) => {
                                //maintenancetransaction_id = row.original['maintenancetransaction_id']
                                this.props.actions.adhocGridSetCellInputValue('purchaseorder_grid', 'invoiceamount', row.viewIndex, event.target.value)
                                //row.original['invoiceamount'] = (this.props.state.invoiceamount)
                            }}
                        />
                    }
                },
                {
                    accessor: 'ponumber', Header: 'Approve', filterable: false, width: 300, Cell: row => {
                        return (
                            <div style={{padding:'10px'}}> 
                                <Button variant="contained" 
                                    label=""
                                    //size={'small'}
                                    //primary={true}
                                    style={{width: '150px !important', margin: '2px'}}
                                    width={150}
                                    icon={<Icon iclass={'material-icons'} iname={'check'} />}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        //console.log('adhocGridx row.original', row.original)
                                        
                                        var data = {...row.original, user: this.props.state.user}

                                        if (!data.invoicenumber || !data.invoicedate || !data.invoiceamount) {
                                            this.props.actions.alert("Make sure all fields are completed for this item")
                                            return
                                        }
                                        
                                        // if (parseInt(row.original['totalamount'] ? row.original['totalamount'] : 0) <= parseInt(data.invoiceamount ? data.invoiceamount : 0)) {
                                        //     this.props.actions.alert("Invoice amount does not match authorised amount of " + parseInt(row.original['totalamount'] ? row.original['totalamount'] : 0) + " and " +parseInt(data.invoiceamount ? data.invoiceamount : 0))
                                        //     return
                                        // }

                                        if (!row.original['completeddate']) {
                                            this.props.actions.alert("Authorisation failed to post due to blank completed date field")
                                            return
                                        }

                                        this.props.actions.approvePO(data)
                                    }}
                                ><Icon iclass={'material-icons'} iname={'clear'} /></Button>
                                &nbsp;&nbsp;
                                <Button variant="contained"  
                                //size={'small'}
                                    label=""
                                    style={{width: '150px !important', margin: '2px'}}
                                    width={150}
                                    //primary={true}
                                    icon={<Icon iclass={'material-icons'} iname={'clear'} />}
                                    onClick={(event) => {
                                        this.props.actions.rejectsPO(row.original['maintenancetransaction_id'])
                                    }}
                                ><Icon iclass={'material-icons'} iname={'check'} /></Button>
                            </div>
                        )
                    }
                },
            ]}
            paging={{
                page: 0,
                pageSize: 10,
                pages: 0,
                count: 0
            }}
            subComponent={row => {
                return <div key={'grid_purchase_order_data_' + row.original['maintenancetransaction_id']} style={{ padding: "2px" }}>
                    <Card title={window.t.en('Purchase Order Items')}
                        subtitle={window.t.en('List of purchase order items')}
                        content={<Container style={{ backgroundColor: '#fff', margin: '1px' }}>
                            <Row style={styles.tableheader}>
                                <Col xl={4}>{'Item'}</Col>
                                <Col xl={4}>{'Description'}</Col>
                                <Col style={styles.numericFields} xl={1}>{'Unit Price'}</Col>
                                <Col style={styles.numericFields} xl={1}>{'Quantity'}</Col>
                                <Col style={styles.numericFields} xl={1}>{'Vat'}</Col>
                                <Col style={styles.numericFields} xl={1}>{'Total Price'}</Col>
                            </Row>
                            {
                                row.original['items']
                                    ? row.original['items']
                                        .map((x, i) => {
                                            return <div>
                                                <Row style={styles.tablerow}>
                                                    <Col xl={4}>{x.itemtype}</Col>
                                                    <Col xl={4}>{x.itemname}</Col>
                                                    <Col style={styles.numericFields} xl={1}>{parseFloat(x.quantity.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                    <Col style={styles.numericFields} xl={1}>{parseFloat(x.unitprice.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                    <Col style={styles.numericFields} xl={1}>{(parseFloat(x.amount * 0.15)).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                    <Col style={styles.numericFields} xl={1}>{parseFloat(x.amount.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                                </Row>
                                            </div>
                                        })
                                    : <div>{'No Items Found...'}</div>
                            }
                            <Row style={styles.tablerow}>
                                <Col xl={11}>{''}</Col>
                                <Col xl={1} style={styles.numericTotFields}>{_.sumBy(row.original['items'], (d) => { return parseFloat(d.amount) }).toFixed(2)}</Col>
                            </Row>
                        </Container>} />

                    <br />
                    <Card title={window.t.en('Purchase Order Documents')}
                        subtitle={window.t.en('List of purchase order documents')}
                        content={<Container style={styles.whitediv}>
                            <Row><Col xl={12}><br /></Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    {
                                        row.original['documents']
                                            ? <PdfViewer
                                                key={'document_viewer_main_history'}
                                                name={'purchaseorder_grid'}
                                                workflow={'fml_customer_quote'}
                                                task={'tsk_fml_quote_waiting_vehicle_delivery'}
                                                documents={row.original['documents']}
                                                edit={false}
                                                multiple={false}
                                                workflow_queue_data={null}
                                            />
                                            : <div>{''}</div>
                                    }
                                </Col>
                            </Row>
                        </Container>}
                    />
                    <br />
                    <Card title={window.t.en('Upload Documents')}
                        subtitle={window.t.en('Upload purchase order documents')}
                        content={<Container style={styles.whitediv}>
                            <Row><Col xl={12}><br /></Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <Upload
                                        id={'upload_ctl'}
                                        prefix={'po'}
                                        header={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    < Button className={"global_button"} variant="contained"
                                        label=""
                                        primary={true}
                                        icon={<Icon iclass={'material-icons'} iname={'check'} />}
                                        onClick={(event) => {
                                            this.props.actions.uploadPODocument(row.original['maintenancetransaction_id'], supplier_id)
                                        }}
                                    ><Icon iclass={'material-icons'} iname={'check'} />Upload Documents</Button>
                                </Col>
                            </Row>
                        </Container>} />
                </div>
            }}
        />
    }

    getLayout() {
        this.actions = this.props.actions;
        return <Card
            title={window.t.en('Process Purchase Orders')}
            subtitle={window.t.en('Post Supplier Invoices')}
            content={this.getGrid()} />
    }

    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.purchaseorder,
            view: {
                ...state.purchaseorderview
            },
            user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...gridActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PURCHASEORDER)