export const select_supplier = {}
export const client_data = []
export const customer_data = []
export const supplier_data = []
export const credited_data = []
export const penalties_data = []
export const payment_document = []
export const supplier_transactions = []
export const customer_transactions = []
export const statement_amount = '0.00'
export const shortpayment_amount = '0.00'
export const discount_percentage = '0.00'
export const discount_amount = '0.00'
export const credited_amount = '0.00'
export const payable_amount = '0.00'
export const payment_date = ''
export const supplier_id = ''
export const suppliername = ''
export const client_id = ''
export const title = ''
export const transactionx = {}
export const enatis_received = false

export const fields = {
  transactionCheckedAmount: {
    errorText: 'Please select the Credit Amount checkbox for Transaction to credit',
    state: 'getState().creditNoteCreate.customer_transactions?.length ?  getState().creditNoteCreate.customer_transactions.filter(x=>x.is_creditnote === true) : true',
    valid: false,
    type: 'table',
    tableFields: ['creditnote_amount'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Credit Amount'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Credit Amount'
      }
    ]
  },
  transactionChecked: {
    errorText: 'Please select the Credit Amount checkbox for Transaction to credit',
    state: 'getState().creditNoteCreate.customer_transactions?.find(x=>x.is_creditnote === true)?.length > 0',
    valid: false
  },

  documents: {
    errorText: 'Please add a document and select document type',
    data: false,
    valid: false,
    empty: true,
    popup: true,
    type: 'documents',
  },

  creditnote_action: {
    errorText: 'Please input Credit Note Action',
    state: 'getState().creditNoteCreate.creditnote_action',
    valid: false
  },
  creditnote_corrective: {
    errorText: 'Please input Credit Note Corrective Mesaure',
    state: 'getState().creditNoteCreate.creditnote_corrective',
    valid: false
  },
  creditnote_description: {
    errorText: 'Please input Credit Note Description',
    state: 'getState().creditNoteCreate.creditnote_description',
    valid: false
  },
  creditnote_date: {
    errorText: 'Please select Credit Note Date',
    state: 'getState().creditNoteCreate.creditnote_date',
    valid: false
  },
  business: {
    errorText: 'Please select Business Account',
    state: 'getState().creditNoteCreate.client_id',
    valid: false
  },
  customer: {
    errorText: 'Please select Customer',
    state: 'getState().creditNoteCreate.customer_id',
    valid: false
  }
}