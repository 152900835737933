import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
//import { Card, CardHeader, CardContent } from '@mui/material'
import { Table, Card, CardHeader, CardContent ,Button} from '@mui/material'
import { Container, Row, Col } from 'react-grid-system'
import moment from 'moment'
import IconButton from '@mui/material/IconButton';
import ReactTooltip from 'react-tooltip'

import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import ReassignTask from '../ReassignTask/component'
import WorkflowTimeline from '../WorkflowTimeline/component'

import {ReactComponent as SquarePlusIcon} from '../../../assets/img/svg/icons/square-plus.svg'
import {ReactComponent as ArrowSmallRightIcon} from '../../../assets/img/svg/icons/arrow-small-right.svg'
import {ReactComponent as CircleXMmarkIcon} from '../../../assets/img/svg/icons/circle-xmark.svg'

import * as workflowTimelineActions from '../WorkflowTimeline/actions'
import * as workflowQueueTaskActions from '../actions'
import * as reassignTaskActions from '../ReassignTask/actions'
import * as actions from './actions'
import * as styles from './styles'

class QueuedTasks extends Component {
    componentDidMount() {
        this.props.actions.getQueuedTasks(this.props.team_id, this.props.user_id, this.props.state.search.value ? this.props.state.search.value : '')
    }

    getExpandedStatus() {
        return this.props.state['tasks_' + this.props.team_id].data.length ? true : false
    }

    getTasks() {
        return <div className="p-4">
          <Table className="table">
          <tbody>
             {
                this.props.state['tasks_' + this.props.team_id].data.length
            ? this.props.state.reassignTask.visible && this.props.state.reassignTask.team_id === this.props.team_id && (this.props.state.reassignTask.mode === 'cancel_task' || this.props.state.reassignTask.mode === 'assign_task')
                ? <ReassignTask key={'queued_tasks_reassign_task_' + this.props.team_id + '_' + this.props.user_id} team_id={this.props.team_id} user_id={this.props.user_id} data={this.props.state['tasks_' + this.props.team_id].data} />
                : this.props.state['tasks_' + this.props.team_id].data
                    .sort((x, y) => y['workflow_queue_task#created_at'] - x['workflow_queue_task#created_at'])
                    .map((x, i) => {
                        return <tr>
                                                    <td className="text-center  text-warning">
                        <span> {'#' + x['workflow_queue_task#workflow_queue_id'] + '/' + (x['workflow_task#order'] ? x['workflow_task#order'] : '?')}</span>
                        </td>
                        <td>
                        <span>{moment(x['workflow_queue_task#created_at']).format('YYYY-MM-DD HH:mm')}</span>
                        </td>
                        <td>
                        <b>{x['workflow#title']}</b>
                        <span className="d-block text-black-50 font-size-sm">
                        {x['workflow_task#title']}
                        </span>
                        </td>
                        <td>
                        <small>{
                                    x['ref_user#firstname']
                                        ? <div style={styles.column(140, false)}>
                                            {x['ref_user#firstname'] + ' ' + x['ref_user#lastname']}
                                        </div>
                                        : <div style={styles.column(140, false)}>
                                            System
                                    </div>
                                }</small>
                        </td>
                        <td>
                        <div style={styles.column(500, false)}>
                                    {x['workflow_queue#meta_data'] ? x['workflow_queue#meta_data'] : ''}
                                </div>
                        </td>
                        <td>
                                <div style={styles.column(210, true)}>
                                    <IconButton
                                        tooltip={'Assign To Me'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.assignTask(this.props.team_id, this.props.user_id, x, 'Self-assigned task.', this.props.team_id, this.props.user_id)
                                            this.props.actions.assignTask(this.props.team_id, this.props.user_id, x, 'Self-assigned task.', this.props.team_id, this.props.user_id)
                                        }}
                                    >
                                        
                                        <SquarePlusIcon style={styles.actionIcon('')} />
                                        {/* <Icon istyle={{ fontSize: '22px', color: '#253053' }} color="primary" iclass={'material-icons'} iname={'library_add'} /> */}
                                    </IconButton>

                                    <IconButton
                                        tooltip={'Assign To'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.showReassignTask('assign_task', this.props.team_id, this.props.user_id, x)
                                        }}
                                    >
                                        <ArrowSmallRightIcon style={styles.actionIcon('')} />
                                        {/* <Icon istyle={{ fontSize: '22px', color: '#253053' }}  color="secondary" iclass={'material-icons'} iname={'forward'} /> */}
                                    </IconButton>

                                    <IconButton
                                        tooltip={'Cancel'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.showReassignTask('cancel_task', this.props.team_id, this.props.user_id, x)
                                        }}
                                    >
                                        
                                        <CircleXMmarkIcon  style={styles.actionIcon('')} />
                                        {/* <Icon istyle={{ fontSize: '22px', color: '#253053'}} iclass={'material-icons'} iname={'cancel'} /> */}
                                    </IconButton>

                                    {/* <IconButton
                                        tooltip={'Timeline'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.toggleWorkflowTimeline('workflow_queue_timeline_' + x['workflow_queue_task#workflow_queue_id'] + '_' + x['workflow_queue_task#workflow_queue_task_id'])
                                        }}
                                    >
                                        
                                        <PriceDetailsIcon />
                                        <Icon istyle={{ fontSize: '22px', color: '#253053'}} iclass={'material-icons'} iname={'timeline'} />
                                    </IconButton> */}
                                </div>
                                <div style={styles.column(0, false)}>
                                    {(x['workflow_queue_task#assigned_to_team_message']) &&
                                        [
                                            <a data-tip data-for={'tooltip_' + x['workflow_queue_task#workflow_queue_task_id']} data-tip='Referrer Comments' data-event='click focus'>
                                                <IconButton tooltip={'Referrer Comment'}>
                                                    <Icon istyle={{ fontSize: '22px', color: '#253053'}} iclass={'material-icons'} iname={'feedback'} />
                                                </IconButton>
                                            </a>,
                                            <ReactTooltip
                                                id={'tooltip_' + x['workflow_queue_task#workflow_queue_task_id']}
                                                globalEventOff='click'
                                            >
                                                {x['workflow_queue_task#assigned_to_team_message']}
                                            </ReactTooltip>
                                        ]
                                    }
                                </div>
                            
                            {/* <WorkflowTimeline
                                key={'workflow_queue_timeline_' + x['workflow_queue_task#workflow_queue_id'] + '_' + x['workflow_queue_task#workflow_queue_task_id']}
                                name={'workflow_queue_timeline_' + x['workflow_queue_task#workflow_queue_id'] + '_' + x['workflow_queue_task#workflow_queue_task_id']}
                                workflow_queue_id={x['workflow_queue_task#workflow_queue_id']}
                            /> */}
                        </td>
                        </tr>
                    })
            : <Row style={styles.row(0, false)}>
                <Col xl={12}>
                    {this.props.state['tasks_' + this.props.team_id].searching ? 'No tasks found.' : 'Your team currently have no tasks queued.'}
                </Col>
            </Row>
             }
             </tbody>
           </Table>
       </div>
    }

    getPagingControls() {
        return !this.props.state.reassignTask.visible
            ? <Row>
                <Col xl={12} style={styles.pagingControls}>
                    <IconButton
                        tooltip={'Previous'}
                        onClick={(event) => {
                            (this.props.state['tasks_' + this.props.team_id].paging.page - 1 === -1)
                                ? event.preventDefault()
                                : this.props.actions.getQueuedTasks(
                                    this.props.team_id,
                                    this.props.user_id,
                                    this.props.state.search.value ? this.props.state.search.value : '',
                                    {
                                        ...this.props.state['tasks_' + this.props.team_id].paging,
                                        page: this.props.state['tasks_' + this.props.team_id].paging.page - 1
                                    }
                                )
                        }}
                    >
                        <Icon istyle={{ fontSize: '22px' }} iclass={'material-icons'} iname={'navigate_before'} />
                    </IconButton>

                    <IconButton
                        tooltip={'Next'}
                        onClick={(event) => {
                            (this.props.state['tasks_' + this.props.team_id].paging.page + 1) === this.props.state['tasks_' + this.props.team_id].paging.pages
                                ? event.preventDefault()
                                : this.props.actions.getQueuedTasks(
                                    this.props.team_id,
                                    this.props.user_id,
                                    this.props.state.search.value ? this.props.state.search.value : '',
                                    {
                                        ...this.props.state['tasks_' + this.props.team_id].paging,
                                        page: this.props.state['tasks_' + this.props.team_id].paging.page + 1
                                    }
                                )
                        }}
                    >
                        <Icon istyle={{ fontSize: '22px' }} iclass={'material-icons'} iname={'navigate_next'} />
                    </IconButton>
                </Col>
            </Row>
            : <div />
    }

    render() {
        return !this.props.state['tasks_' + this.props.team_id] || this.props.state['tasks_' + this.props.team_id].loading
            ? <Loading message={this.props.state.search.value.length ? 'Searching...' : 'Retrieving your team\'s queued tasks...'} />
            : <div style={styles.tasksCard}>
                {/* <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Queued Tasks'}
                    subtitle={
                        this.props.state['tasks_' + this.props.team_id].searching
                            ? '(' + this.props.state['tasks_' + this.props.team_id].paging.count + ') tasks found matching "' + this.props.state['tasks_' + this.props.team_id].searched + '". Viewing page ' + (this.props.state['tasks_' + this.props.team_id].paging.page + 1) + ' of ' + this.props.state['tasks_' + this.props.team_id].paging.pages + '.'
                            : 'Your team has (' + this.props.state['tasks_' + this.props.team_id].paging.count + ') tasks waiting for assignment. Viewing page ' + (this.props.state['tasks_' + this.props.team_id].paging.page + 1) + ' of ' + (this.props.state['tasks_' + this.props.team_id].paging.pages ? this.props.state['tasks_' + this.props.team_id].paging.pages : '1') + '.'
                    }
                    avatar={<Icon istyle={{ color: '#f44336' }} iclass={'material-icons'} iname={'queue'} />}
                /> */}
                {
                        this.props.state['tasks_' + this.props.team_id].searching
                            ? '(' + this.props.state['tasks_' + this.props.team_id].paging.count + ') tasks found matching "' + this.props.state['tasks_' + this.props.team_id].searched + '". Viewing page ' + (this.props.state['tasks_' + this.props.team_id].paging.page + 1) + ' of ' + this.props.state['tasks_' + this.props.team_id].paging.pages + '.'
                            : 'Your team has (' + this.props.state['tasks_' + this.props.team_id].paging.count + ') tasks waiting for assignment. Viewing page ' + (this.props.state['tasks_' + this.props.team_id].paging.page + 1) + ' of ' + (this.props.state['tasks_' + this.props.team_id].paging.pages ? this.props.state['tasks_' + this.props.team_id].paging.pages : '1') + '.'
                    }
                    <div style={{ height: '850px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {this.getPagingControls()}
                        {this.getTasks()}
                    </div>
            </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.queuedTasks,
            timeline: {
                ...state.workflowTimeline
            },
            reassignTask: {
                ...state.reassignTask
            },
            search: {
                search: state.workflowQueueTasks.search,
                value: state.workflowQueueTasks.value
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            ...actions,
            ...workflowQueueTaskActions,
            ...reassignTaskActions,
            ...workflowTimelineActions
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueuedTasks)