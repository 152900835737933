import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import AutoComplete from '../../controls/autocomplete'

import TextField from '@mui/material/TextField'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'
import Iframe from 'react-iframe'


import Loading from '../Loading/component'
import * as actions from './actions'
import * as styles from './styles'

class WorkflowPOContractActivationVAFConfirm extends Component {
    componentDidMount() {
        const workflow_queue_data = this.props.workflow_queue_data

        const component = this.props.state.purchaseorder
        const data = {
            vaf_doc: this.props.state.filename,
            vaf_date: new Date(),
            vaf_comments: 'VAF Step Completed'
        }

        this.props.actions.setupWorkflowTaskInput(data, 'customerquote', 'tsk_fml_quote_vaf')

        try {
            const activation_document = workflow_queue_data.data[0].context_data.data.data.data[0].activation_document
            const documents = activation_document.filter(x => x.filetype === 'Vehicle Activation Form')
            this.props.actions.loadVAFDocuments(documents)
        }
        catch (err) { }
    }

    getSupplier(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Supplier Details')}
                subtitle={window.t.en('Vehicle supplier')}
                actAsExpander={false}
                style={styles.row}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliername}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.supliertype[0].suppliertype}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registrationnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'VAT number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vatnumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier BBE Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.beetype}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier BBE Level:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.beelevel}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getContract(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Quotation Details')}
                subtitle={window.t.en('Customer quote created for')}
                actAsExpander={false}
                style={styles.row}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Contract Period:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.contractperiod + ' Months'}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Contracted Distance per Month:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.distancepermonth).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Km'}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Contract Distance:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{parseFloat(data.contractdistance).toLocaleString('en-EG', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Km'}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Quotation Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{moment(data.quotedate).format('DD/MM/YYYY HH:mm')}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Contract Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.contracttype}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Category:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.categorydescription}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }


    getCustomer(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Customer Details')}
                subtitle={window.t.en('Customer quote created for')}
                actAsExpander={false}
                style={styles.row}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Customer Name:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{data.customername}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Cost Centre:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.costcentre}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Registered Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registeredname}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Account Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.accountnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.compregistrationnumber}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getVehicle(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Vehicle Details')}
                subtitle={window.t.en('Requested vehicle on the quote')}
                actAsExpander={false}
                style={styles.row}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{data.makemodelyear}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.mmcode}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.make}</Col>
                    </Row>
                    <Row>
                        {/* <Col xl={3} style={styles.fieldLabel}>{'Vehicle First Registration Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.regyear}</Col> */}
                        <Col xl={3} style={styles.fieldLabel}>{'Fuel Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.fueltype}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Delivery Period:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.deliveryperiod}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Status:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{''}</Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getForm(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Upload Vehicle Activation Form')}
                subtitle={window.t.en('Upload vehicle activation form ')}
                actAsExpander={false}
                style={styles.row}
            />
            <CardContent expandable={false}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            {'Vehicle Activation Form'}
                        </Col>
                        <Col xl={6}>
                            {'Uploaded Signed Vehicle Activation Form'}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            {
                                this.props.state.preview
                                    ? <Iframe url={this.props.state.preview}
                                        width="100%"
                                        height="300"
                                        id="myId2"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        allowFullScreen />
                                    : <Loading message={'Retrieving document preview, please wait...'} />
                            }
                        </Col>
                        <Col xl={6}>
                            {
                                this.props.state.document.data.content.text
                                    ? <Iframe url={this.props.state.document.data.content.text}
                                        width="100%"
                                        height="300"
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        allowFullScreen />
                                    : 'No File..'
                            }
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }


    getView(data) {
        return <Card style={styles.workflowTaskStepComponentCard} expandable={false}>
            <CardHeader
                title={window.t.en('Vehicle Acceptance Form')}
                subtitle={window.t.en('Upload signed vehicle acceptance form')}
                actAsExpander={false}
            />
            <CardContent expandable={false}>
                {this.getSupplier(data.confirm.supplier[0])}
            </CardContent>
            <CardContent expandable={false}>
                {this.getContract(data.confirm.vehicle.contract)}
            </CardContent>
            <CardContent expandable={false}>
                {this.getCustomer(data.customer)}
            </CardContent>
            <CardContent expandable={false}>
                {this.getVehicle(data.confirm.vehicle)}
            </CardContent>
            <CardContent expandable={false}>
                {this.getForm()}
            </CardContent>
        </Card>
    }

    render() {

        
        const workflow_queue_data = this.props.workflow_queue_data
        let data = null
        let activation_document = null

        try {
            const activation_document = workflow_queue_data.data[0].context_data.data.data.data[0].activation_document
            const _data = workflow_queue_data.data[0].context_data.data.data.data[0].data[0].context_data.data.data.data[0].data[0].context_data.data.data.data[0].data[0].context_data.data.data[0].data[0].data[0].context_data.data.data.data[0]
            data = _data.data.data.data.data.data
        }
        catch (err) {
        }

        this.component = this.props.state.purchaseorder
        this.field = this.component.component_field.reduce((obj, x) => {
            obj[x.name] = x
            return obj
        }, {})

        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )


        // const workflow_queue_data = this.props.workflow_queue_data
        // let data = null
        // let activation_document = null
        // try {
        //     const activation_document = workflow_queue_data.data[0].context_data.data.data.data[0].activation_document

        //     const _data = workflow_queue_data.data[0].context_data.data.data.data[0].data[0].context_data.data.data.data[0].data[0].context_data.data.data[0].data[0].data[0]
        //     data = _data.context_data.data.data.data[0].data[0].context_data.data.data.data[0].data.data.data.data.data
        // }
        // catch (err) {
        // }

        // this.component = this.props.state.purchaseorder
        // this.field = this.component.component_field.reduce((obj, x) => {
        //     obj[x.name] = x
        //     return obj
        // }, {})

        // return (
        //     data
        //         ? this.getView(data)
        //         : <Loading message={'Retrieving data, please wait...'} />
        // )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowPOContractActivationVAF,
            component: {
                ...state.components[ownProps.name]
            },
            document: {
                ...state.components['document']
            },
            supplier: {
                ...state.components['supplier']
            },
            purchaseorder: {
                ...state.components['purchaseorder']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOContractActivationVAFConfirm)