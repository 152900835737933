
import * as types from './types'

export const setupWorkflowTaskInput = (data, component, step) => {
    return (dispatch, getState) => {

        dispatch({
            type: types.CONFIRM_PO_ACCESSORY_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: data
            }
        })
    }
}