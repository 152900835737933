import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Card, CardHeader, CardContent } from '@mui/material'
import GeneralContentView from '../../views/GeneralContentView/component'
import Icon from '../Icon/component'
import * as actions from './actions'
import { Container, Row, Col } from 'react-grid-system'

class Support extends Component {
    getErrorInfo() {
        if (process.env.NODE_ENV === 'production')
            return []

        let elements = [<br />, <br />]

        if (this.props.state.error) {
            elements.push(
                <Row key={'row_error'}>
                    <Col xl={1}><strong>Error:</strong></Col>
                    <Col xl={11}>
                        <textarea cols="100" readOnly ows="2" defaultValue={this.props.state.error}></textarea>
                    </Col>
                </Row>
            )
        }

        if (this.props.state.info) {
            elements.push(
                <Row key={'row_info'}>
                    <Col xl={1}><strong>Info:</strong></Col>
                    <Col xl={11}>
                        <textarea cols="180" readOnly rows="30" defaultValue={this.props.state.info}></textarea>
                    </Col>
                </Row>
            )
        }

        return elements
    }

    render() {
        return <GeneralContentView
            title={window.t.en('Technical Support')}
            ///headerImage={'sunflower.png'}
            content={
                <Card
                    initiallyExpanded={true}
                >
                    <CardHeader
                        actAsExpander={false}
                        showExpandableButton={false}
                        title={window.t.en('Sorry...')}
                        subtitle={window.t.en('A technical error occured.')}
                        avatar={
                            <Icon istyle={{ color: 'red' }} iclass={'material-icons'} iname={'error'} />
                        }
                    />
                    <CardContent expandable={false}>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>This error was <strong>automatically</strong> reported to the development team. We will resolve this problem as soon as possible.</Col>
                            </Row>
                            <Row>
                                <Col xl={12}>&nbsp;</Col>
                            </Row>
                            {this.getErrorInfo()}
                        </Container>
                    </CardContent>
                </ Card>
            }
        />
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.support }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Support)