import * as types from './types'
import * as _ from 'lodash'
import * as formulas from 'formulajs'
import moment from 'moment'


export const setInitData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_REVIEW_QUOTATIONS_DATA,
            payload: data
        })
    }
}

export const setInputValue = (prop, value, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_REVIEW_FIELD_INPUT_DATA_VALUE,
            payload: {
                value: value,
                prop: prop,
                inx: inx
            }
        })
        dispatch(calulate_total(inx))
    }
}

export const setItemInputValue = (prop, value, i, inx) => {
    return (dispatch, getState) => {
        let list = getState().workflowReviewQuote.quotations[inx][prop]
        list[i].costprice = value
        dispatch({
            type: types.SET_COMPONENT_REVIEW_ITEMS_FIELD_INPUT_DATA_VALUE,
            payload: {
                value: list,
                prop: prop,
                inx: inx
            }
        })
        dispatch(calulate_total(inx))
    }
}
export const setvalue = (prop) => {
    return (dispatch, getState) => {
        
    }
}

export const calulate_total = (inx) => {
    return (dispatch, getState) => {
        let quotations = getState().workflowReviewQuote.quotations

        //Get all values
        let acc_tot = _.sumBy(quotations[inx].accessory_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)
        let ser_tot = _.sumBy(quotations[inx].service_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)
        let sun_tot = _.sumBy(quotations[inx].sundry_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)
        let contractperiod = quotations[inx].contract.fields.contractperiod.value
        let monthlyinclusive = quotations[inx].contract.fields.monthlyinclusive.value
        let maintenance = quotations[inx].maintcost
        let tyres = quotations[inx].tyrescost
        let licencefee = quotations[inx].licencefee
        let adminfee = quotations[inx].adminfee
        let rvamount = quotations[inx].rvamount
        let roiadj = quotations[inx].roiadj
        let roiperc = quotations[inx].roiperc


        //Begin calculations
        let interestrate = (parseFloat(quotations[inx].primerate) + parseFloat(quotations[inx].deviation)).toFixed(2)

        let vprice = (parseFloat(quotations[inx].cprice) - parseFloat(quotations[inx].dprice)).toFixed(2)
        let nett = parseFloat(vprice) + parseFloat(acc_tot) + parseFloat(sun_tot)

        let finance = formulas.PMT(((parseFloat(interestrate) / 100) / 12), contractperiod, -(nett), parseInt(rvamount), 0).toFixed(2)

        let _roi = parseFloat(finance) + parseFloat(maintenance) + parseFloat(tyres) + parseFloat(licencefee) + parseFloat(ser_tot) + parseFloat(adminfee)
        let roi = (parseFloat(roiadj) + (parseFloat(_roi) * (roiperc / 100))).toFixed(2)

        let financeexcess = (parseFloat(finance) / parseFloat(monthlyinclusive)).toFixed(2)
        let maintananceexcess = ((parseFloat(finance) + parseFloat(ser_tot) + parseFloat(adminfee) + parseFloat(licencefee)) / parseFloat(monthlyinclusive)).toFixed(2)


        let total = (
            parseFloat(finance) +
            parseFloat(maintenance) +
            parseFloat(tyres) +
            parseFloat(licencefee) +
            parseFloat(adminfee) +
            parseFloat(roi)).toFixed(2)

        quotations[inx].interestrate = interestrate
        quotations[inx].finance = finance
        quotations[inx].roi = roi
        quotations[inx].nett = nett
        quotations[inx].acc_tot = acc_tot
        quotations[inx].sun_tot = sun_tot
        quotations[inx].ser_tot = ser_tot
        quotations[inx].maintananceexcess = maintananceexcess
        quotations[inx].financeexcess = financeexcess
        quotations[inx].excess = (parseFloat(financeexcess) + parseFloat(maintananceexcess)).toFixed(2)

        
        quotations[inx].monthly_excl = total
        quotations[inx].monthly_vat = (parseFloat(total) * 0.15).toFixed(2)
        quotations[inx].monthly_incl = (parseFloat(total) * 1.15).toFixed(2)
        quotations[inx].monthly_str = ((parseFloat(total) * 1.15) * 1.14).toFixed(2)

        dispatch({
            type: types.SET_COMPONENT_REVIEW_QUOTATIONS_DATA,
            payload: quotations
        })
    }
}
