import React, { useState, useEffect, useMemo } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { adaptData } from "./data.adapter";
import {
    ReactDropdownTreeSelectContainer,
    ReactDropdownTreeSelectMemoized
  } from "./dropdownTree.container";
//import Selection2 from './selection2'
// import "./index.css";

import * as actions from './actions'

//import "./style.css";


//function

const SelectionControl = (props) => {

    const [dataFromServer, setDataFromServer] = useState(props.state.hierarchy_data);
    const [selectedValuesFromFormik, setSeletedValue] = useState([]);
    const { hierarchy_data, hierarchy_selection } = props.state

    // useEffect(() => {
    //     if(props.data_id){
    //         props.actions.getHierarchy(props.data_id)
    //     }
    // }, []);

    const adaptedData = useMemo(() => {
        if (hierarchy_data == null) return null;
        return adaptData(hierarchy_data, selectedValuesFromFormik);
        // Explicitly don't update data when field are value is updated.
      }, [hierarchy_data]);

    const handleChange = useMemo(
    () => (_, selectedValues) => {
      //console.log(selectedValues)
      props.onChange(selectedValues)
    
      setSeletedValue(selectedValues.map(val => val.label));
    },
    []
  );
  
  return (
      <div className="select-root">
          {/* <DropdownTreeSelect
              data={hierarchy_selection.length? hierarchy_selection:hierarchy_data}
              onChange={props.actions.onChangeHierarchy}
              className="mdl-demo"
              keepTreeOnSearch
              
          /> */}
    {
        hierarchy_data?
         <ReactDropdownTreeSelectContainer
              data={adaptedData}
              onChange={handleChange}
              mode="radioSelect"
          />:null
    }    
      </div>
  );
};

const mapStateToProps = (state) => {
    return {
        state: { ...state.controls }
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionControl)

