import React, { Component, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as changeCase from 'change-case'
import * as _ from 'lodash'

//import DocumentViewer from 'itsa-react-docviewer'
//import prettyFileIcons from 'pretty-file-icons'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent, CardActions, CardMedia, CardTitle, CardExpandable, Toolbar, ListItemText } from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'

import Divider from '@mui/material/Divider'
import HomeIcon from '@mui/icons-material/Home';
//For ToolBar Test
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu';
import FontIcon from '@mui/material/Icon'
import NavigationExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Icon from '../Icon/component'
import Form from '../Form/component'
import Grid from '../Grid/component'
import View from '../View/component'

import Document from '../Document/component'
import CustomerDerivativeCategory from '../CustomerDerivativeCategory/component'
import DetailComponentView from '../../views/DetailComponentView/component'
import * as helper from '../../helpers/components'

import { useParams  } from "react-router-dom";

import * as formActions from '../Form/actions'
import * as navigationMenuActions from '../NavigationMenu/actions'
import * as redirectActions from '../Redirect/actions'
import * as actions from './actions'
import * as styles from './styles'


import ACCOUNTINGITEMLINK from '../../components/Custom/ACCOUNTINGITEMLINK/component'
import CUSTOMERTRANSACTION from '../../components/Custom/CUSTOMERTRANSACTION/component'
import SUPPLIERTRANSACTION from '../../components/Custom/SUPPLIERTRANSACTION/component'
//import Title from './title'

// class ReadComponent extends Component {
//     componentDidMount() {
//         props.actions.toggleRelationshipMenu()
//     }
const ReadComponent = (props) => {   
    const _params = useParams(); 
    
    // useEffect(() => {
    //     props.actions.toggleRelationshipMenu()
    //     // //console.log('approute hook', props)
    //     // props.navigate(props.state.path,{params: props.state.path})
    // });

    const getCustomComponent = (component, params) => {
        return <div> 
            {component === "ACCOUNTINGITEMLINK" && <ACCOUNTINGITEMLINK params={params} />}
            {component === "CUSTOMERTRANSACTION" && <CUSTOMERTRANSACTION params={params} />}
            {component === "SUPPLIERTRANSACTION" && <SUPPLIERTRANSACTION params={params} />}
        </div>
    }

    const getToolbarButtons = (component) => {
        //console.log('getToolbarButtonsX component', component)
        return <div>
            { component.custom && props.state.view == 'form' ? '' : <Button variant="contained"
                icon={
                    props.state.view == 'form'
                        ? <Icon
                            iclass={'material-icons'}
                            iname={'save'}
                        />
                        : <Icon
                            iclass={'material-icons'}
                            iname={'mode_edit'}
                        />
                }
                className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
                label={props.state.view == 'form' || props.state.view == 'create' ? 'Save' : 'Edit'}
                primary={true}
                style={{
                    width: 100
                }}
                onClick={(event) => {
                    //console.log('componentx component', component)
                    //console.log('componentx actions', props.actions)
                    //console.log('componentx state', props.state)
                    props.state.view == 'form' || props.state.view == 'create'
                        ? props.actions.saveComponentData(component)
                        : props.actions.setReadComponentView('form', '')
                }}
                >{
                    props.state.view == 'form'
                        ? <Icon
                            iclass={'material-icons'}
                            iname={'save'}
                        />
                        : <Icon
                            iclass={'material-icons'}
                            iname={'mode_edit'}
                        />
                } {props.state.view == 'form' || props.state.view == 'create' ? 'Save' : 'Edit'}</Button>
                }
            {props.state.view !== 'view' &&
                <Button variant="contained"
                    icon={
                        <Icon
                            iclass={'material-icons'}
                            iname={'pageview'}
                        />
                    }    
                    className="btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"                    
                    primary={true}
                    style={{
                        marginLeft: 15,
                        width: 100
                    }}
                    onClick={(event) => {
                        props.actions.setReadComponentView('view', '')
                    }}
                ><Icon
                        iclass={'material-icons'}
                        iname={'pageview'}
                    />View</Button>
            }
        </div>
    }

    // getToolbar() {
    //     const title = helper.getComponentTitle(this.component)
    //     const description = helper.getComponentDescription(this.component)

    //     return <Toolbar>
    //         <div firstChild={true}>
    //         </div>
    //         <div>
    //             <FontIcon className="muidocs-icon-custom-sort" ></FontIcon>
                
    //            < Button 
    //                 variant="contained"
    //                 icon={
    //                     props.state.view == 'form'
    //                         ? <Icon
    //                             iclass={'material-icons'}
    //                             iname={'save'}
    //                         />
    //                         : <Icon
    //                             iclass={'material-icons'}
    //                             iname={'mode_edit'}
    //                         />
    //                 }
    //                 className="global_button btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
    //                 label={props.state.view == 'form' || props.state.view == 'create' ? 'Save' : 'Edit'}
    //                 primary={true}
    //                 onClick={(event) => {
    //                     props.state.view == 'form' || props.state.view == 'create'
    //                         ? props.actions.saveComponentData(this.component)
    //                         : props.actions.setReadComponentView('form', '')
    //                 }}
    //                 >{
    //                     props.state.view == 'form'
    //                         ? <Icon
    //                             iclass={'material-icons'}
    //                             iname={'save'}
    //                         />
    //                         : <Icon
    //                             iclass={'material-icons'}
    //                             iname={'mode_edit'}
    //                         />
    //                 } 
    //                 {props.state.view == 'form' || props.state.view == 'create' ? 'Save' : 'Edit'}</Button>
    //                 {props.state.view !== 'view' &&
    //                 <Button 
    //                     variant="contained"
    //                     icon={
    //                         <Icon
    //                             iclass={'material-icons'}
    //                             iname={'pageview'}
    //                         />
    //                     }
    //                     className="global_button btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
    //                     label={'View'}
    //                     primary={true}
    //                     onClick={(event) => {
    //                         props.actions.setReadComponentView('view', '')
    //                     }}
    //                     ><Icon
    //                     iclass={'material-icons'}
    //                     iname={'pageview'}
    //                 /> View</Button>
    //             }
    //             <Menu
    //                 iconButtonElement={
    //                     <IconButton touch={true}>
    //                         <NavigationExpandMoreIcon />
    //                     </IconButton>
    //                 }
    //             >
    //                 <MenuItem primaryText="Export Data to PDF" />
    //                 <MenuItem primaryText="Export Data to XLS" />
    //                 <MenuItem primaryText="Export Data to CSV" />
    //             </Menu>
    //         </div>
    //     </Toolbar>
    // }

    // getRelatedComponents() {
    //     //console.log('getRelatedComponents')
    //     return props.state.relationships
    //         .map((x,ind) => {
    //             return <Card key={x.name + '_related_search_component_grid_' + x.name + ind} initiallyExpanded={false}>
    //                 <CardHeader
    //                     avatar={<Icon iclass={x.icon.unicode} iname={x.icon.name} />}
    //                     title={x.title}
    //                     actAsExpander={true}
    //                     showExpandableButton={true}
    //                 />
    //                 <CardContent expandable={true}>
    //                     <Grid
    //                         key={x.name + '_related_search_component_grid_' + x.name + ind}
    //                         name={x.name}
    //                         filter={this.component.name}
    //                         id={props.match.params.id}
    //                     />
    //                 </CardContent>
    //             </Card>
    //         })
    // }

    const getRelatedList = (component) => {
        //console.log('getRelatedListx', component)
        const relatedList = component.relationships // props.state.relationships
            ? component.relationships.map((x, ind) => {
                return <span style={{padding: '3px'}}><Button
                            key={ind}
                            variant="contained"
                            //size="small"
                            //fullWidth={true}
                            //className="d-40 btn-default"
                            //onClick={toggle1}
                            //style={{ minWidth: '250px' }}
                            onClick={(event) => {
                                props.actions.setReadComponentView(x.name === 'customerderivativecategory' ? 'custom' : 'related', x.name)
                            }}
                >
                    {window.t.en(x.title)}
              </Button></span>
            })
            : ''
            //    <ListItem
            //         key={'readComponent_relatedList_' + x.name}
            //         primaryText={window.t.en(x.title)}
            //         onClick={(event) => {
            //             props.actions.setReadComponentView('related', x.name)
            //         }}
            //         // leftIcon={
            //         //     <Icon
            //         //         iclass={x.icon.unicode}
            //         //         iname={x.icon.name}
            //         //     />
            //         // }
            //     >
            //          <ListItemText primary="Vacation" secondary="July 20, 2014" />
            //     </ListItem>

        return relatedList
    }

    // getKeyPerformanceIndicators() {
    //     let arr = []

    //     for (let index = 1; index < 5; index++) {
    //         arr.push(
    //             <div key={'chart_example_div' + index} >
    //                 <Paper elevation={2} >
    //                     <img alt='Sample KPI' style={styles.kpiImage} src={require('../../assets/img/chart_' + index + '.png')} />
    //                 </Paper>
    //                 <br />
    //             </div>
    //         )
    //     }

    //     return arr
    // }

    // getImages() {
    //     return props.state.images
    //         ? props.state.images.map((x, ind) => {
    //             return <img key={ind} style={styles.image} alt='image' src={x} />
    //         })
    //         : <Icon  istyle={styles.placeholder} iclass={'material-icons'} iname={'help_outline'} />
    // }

    // getAvatar() {
    //     return props.state.images
    //         ? props.state.images.map((x, ind) => {
    //             return x
    //         })[0]
    //         : <Icon istyle={styles.placeholder} iclass={'material-icons'} iname={'help_outline'} />
    // }

    const getCurrentView = (component) => {
            let CustomComponent =  null
            console.log('_params99 id' ,_params.id, props.state.view )
            //console.log('CustomComponentx cc',component.name.toUpperCase(), props.state.view)
            switch (props.state.view) {
                case 'create':
                    CustomComponent = component.name.toUpperCase()
                    return component.custom
                        ? <CustomComponent />
                        : <div>
                            <Form
                                key={'readcomponent_create' + _params.component + _params.id}
                                name={_params.component}
                                empty
                            />
                        </div>
                case 'view':
                    return <View
                        key={'readComponent_View_' + _params.component + _params.id}
                        id={_params.id}
                        name={_params.component}
                        expanded={true}
                        surrogate={false}
                    />
                case 'form':
                    return component.custom
                        ? getCustomComponent(component.name.toUpperCase(), _params) 
                        : <Form
                            key={'readcomponent_form' + _params.component + _params.id}
                            name={_params.component}
                            id={_params.id}
                            related={props.state.relationships ? true : false}
                        />
                case 'related':
                    //console.log('CustomComponent component',component)
                    return  component.custom
                        ? <CustomComponent />
                        : <Card initiallyExpanded={true}>
                            <CardHeader
                                avatar={<Icon iclass={props.state.related.icon.unicode} iname={props.state.related.icon.name} />}
                                title={props.state.related.title}
                                subtitle={props.state.related.description}
                                actAsExpander={true}
                                showExpandableButton={true}
                            />
                            <CardContent expandable={true}>
                                { 
                                    _params.id ? <Grid
                                        key={props.state.grid + '_related_search_component_grid'}
                                        name={props.state.grid}
                                        filter={component.name}
                                        id={_params.id}
                                        component={props.state.related}
                                    />
                                    : 'Oops something went wrong :) '
                                }
                            </CardContent>
                        </Card>
                case 'custom':
                    return <CustomerDerivativeCategory />
                case 'documents':
                    return <Card initiallyExpanded={true}>
                        <CardHeader
                            avatar={<Icon iclass={props.state.related.icon.unicode} iname={props.state.related.icon.name} />}
                            title={props.state.related.title}
                            subtitle={props.state.related.description}
                            actAsExpander={true}
                            showExpandableButton={true}
                        />
                        <CardContent expandable={true}>
                             <Document component={component.name} />    
                        </CardContent>
                    </Card>
                default:
                    break
            }
    }

    // render() {
        
        const component = props.state.components[_params.component ? _params.component : props.state.grid ]

        //console.log('componentx component', component)
        //console.log('componentx _params', _params)
        //console.log('componentx _params component', _params.component)
        //console.log('componentx state', props.state.grid)
        return (
            component
            ? <>
            <DetailComponentView
                component={component}
                relationships={component.relationships ? component.relationships.length : []}
                menu={
                    props.state.relatedMenuIsVisible &&
                    <div>
                        <div>
                            <div firstChild={true}>
                                <br/>
                               {/* { 
                                    props.state.view === 'related' && <IconButton touch={true}
                                        onClick={(event) => {
                                            props.actions.setReadComponentView('view', props.match.params.component)
                                            props.actions.route(
                                                '/view/' + props.match.params.component + '/' + props.match.params.id
                                            )
                                        }}>
                                            <HomeIcon />
                                        </IconButton>
                                }
                                { 
                                     props.state.view === 'documents' && <IconButton touch={true}
                                         onClick={(event) => {
                                             props.actions.setReadComponentView('view', props.match.params.component)
                                             props.actions.route(
                                                 '/view/' + props.match.params.component + '/' + props.match.params.id
                                             )
                                         }}>
                                            <HomeIcon />
                                        </IconButton>
                                 } */}
                                {getRelatedList(component)}
                                <Button
                                    variant="contained"
                                    //size="small"
                                    //fullWidth={true}
                                    //className="d-40 btn-default"
                                    //style={{minWidth: '250px'}}
                                    onClick={(event) => {
                                        props.actions.setReadComponentView('documents', component.name)
                                    }}
                                    >
                                        {window.t.en('Documents')}
                                </Button>
                                <br/>
                            </div>
                        </div>
                    </div>
                }
                data={[
                    getCurrentView(component)
                ]}
                buttons={
                    getToolbarButtons(component)
                }
                // documents={
                //     <Document component={this.component.name} />
                // }
                // other={
                //     this.getKeyPerformanceIndicators()
                // }
            />
            </>
            : 'Loading...'
        )
    }


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.readComponent,
            // component: {
            //     ...state.components[_pop]
            //     //...state.components[ownProps.match.params.component]
            // },
            components: {
                ...state.components //[_pop]
            },
            related: {
                ...state.components[state.readComponent.grid]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...formActions, ...navigationMenuActions, ...redirectActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadComponent)