import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import PropTypes from 'prop-types';
import moment from 'moment';

///import AutoComplete from '../../../controls/autocomplete'

import NumberFormat from 'react-number-format';
import { Card, CardHeader, CardContent } from '@mui/material'

import Upload from '../Upload/component'
import Icon from '../Icon/component'
import Grid from '../Grid/component'
import AutoComplete from '../../controls/autocomplete'
import AutoCompleteContact from '../../controls/autocompletecontact'
import TreeSelect from '../../controls/treeselect'
import DateControl from '../../controls/dateControl'
import CheckBoxControl from '../../controls/checkbox'
import TextField from '../../controls/textField'

import * as actions from './actions'
import * as controlActions from '../../controls/actions'
import * as styles from './styles'


import "./tree_select.scss";
// import "./style.css";



function NumberFormatCustom(props) {
    const { inputRef, onChange, type, ...other } = props;
    
    switch (type) {
        case 'phone':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                isNumericString
                format="+27 (###) ###-####" 
                mask="_"
            />
        case 'amount':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                fixedDecimalScale
                isNumericString
            />
        case 'number':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                thousandSeparator={false}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                decimalScale={2}
                isNumericString
            />
        case 'month':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="##/##" placeholder="MM/YY" 
                mask={['M', 'M', 'Y', 'Y']}
                isNumericString
            />
        case 'card':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="#### #### #### ####" 
                mask="_"
                isNumericString
            />
        case 'idnumber':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="###### #### ###" 
                mask="_"
                isNumericString
            />
        case 'code':
            return <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                        name: props.name,
                        value: values.value,
                        },
                    });
                }}
                format="####"
                mask="_"
                isNumericString
            />
    } 
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


class RelatedForm extends Component {
    componentDidMount() {
        //console.log('getRelatedGrid componentDidMount')
        if(this.props.name) {
            this.props.actions.clearComponentData(this.props.name)
            if(this.props.id)
                this.props.actions.loadComponent(this.props.name, this.props.id)
        }
        // if(this.props.state.component.data.customer_id){
        //      this.props.actions.getHierarchy(this.props.state.component.data.customer_id.input)
        // }
    }

    getFormFieldValue(field, index) {
        if(!this.component.data[field.name]) return ''
        switch (field.component_field_display_type.name) {
            case 'treeSelect':
                return <TreeSelect
                    name={field.name}
                    title={field.hint}
                    data={[{customername: ''}]}
                    hierarchy={this.props.state[field.name+"_data"] ? this.props.state[field.name+"_data"] : []}
                    value={this.component.data[field.name].text}
                    multiple={false}
                    valuefield={field.display.split('.')[1]}
                    displayfield={field.display.split('.')[0]}
                    onSelect={(args) => {
                        //console.log('TreeSelect args',args)
                        //console.log('TreeSelect args',args[field.display.split('.')[1]])
                        //  this.props.actions.setComponentFieldInput(field, args[field.display.split('.')[1]])
                        this.props.actions.setComponentFieldInputAndText(field, args[field.display.split('.')[1]], args[field.display.split('.')[0]])
                    }}
                    onClick={() => {
                        //console.log('TreeSelect Data',this.props.state[field.name+"_data"])
                        this.props.actions.getTreeViewData(field.name, field.datasource, this.props.state.user.customer_id)
                    }}
                    onFilter={(args) => console.log()} 
                /> 
            case 'autoComplete':
                return this.component.data[field.name] ? <AutoComplete
                name={field.name}
                title={field.hint}
                // info={{
                //     show: true,
                //     component: field.component_reference.name,
                //     title: field.component_reference.title,
                //     field: field.name,
                //     value: this.component.data[field.name].input
                // }}
                //component_reference={field.component_reference.name}
                data={this.component.data[field.name].data}
                value={this.component.data[field.name].text}
                onSelect={(args) => this.props.actions.setComponentFieldInput(field, args.value)}
                onClick={() => {
                    //console.log('RelatedFormx component', this.component)
                    //console.log('RelatedFormx field', field)
                    //console.log('RelatedFormx name', this.component.name)
                    //console.log('RelatedFormx refe', field.component_reference.name)
                    //console.log('RelatedFormx field', field)
                    //console.log('RelatedFormx data', this.component.data[field.name].data)
                    this.props.actions.getComponentFieldLookupData(field, this.component.data[field.name].text ? this.component.data[field.name].text : '')
                }}
                onFilter={(args) => this.props.actions.getComponentFieldLookupData(field, args)} /> : ''
                case 'autoCompleteContact':
                    return this.component.data[field.name] ? <AutoComplete
                    name={field.name}
                    title={field.hint}
                    // info={{
                    //     show: true,
                    //     component: field.component_reference.name,
                    //     title: field.component_reference.title,
                    //     field: field.name,
                    //     value: this.component.data[field.name].input
                    // }}
                    //component_reference={field.component_reference.name}
                    data={this.component.data[field.name].data}
                    value={this.component.data[field.name].text}
                    onSelect={(args) => this.props.actions.setComponentFieldInput(field, args.value)}
                    onClick={() => {
                        //console.log('RelatedFormx component', this.component)
                        //console.log('RelatedFormx field', field)
                        //console.log('RelatedFormx name', this.component.name)
                        //console.log('RelatedFormx refe', field.component_reference.name)
                        //console.log('RelatedFormx field', field)
                        //console.log('RelatedFormx data', this.component.data[field.name].data)
                        //console.log('RelatedFormx props',this.props.modelx.id)
                        this.props.actions.getComponentFieldLookupDataContact(field, this.component.data[field.name].text ? this.component.data[field.name].text : '',this.props.modelx.id)
                    }}
                    onFilter={(args) => this.props.actions.getComponentFieldLookupDataContact(field, args,this.props.modelx.id)} /> : ''
                case 'datePicker':
                    //console.log('datePickerx',this.component.data[field.name])
                    //console.log('datePickerx',this.component.data[field.name].input ? moment(this.component.data[field.name].input).format('YYYY-MM-DD HH:mm') : '')
                    return <div>
                        <DateControl
                            id={this.component.data[field.name].key}
                            key={this.component.data[field.name].key}
                            name={this.component.data[field.name].key}
                            label={window.t.en(field.hint)}
                            field={field}
                            inputFormat="DD-MM-YYYY"
                            //value={this.component.data[field.name].input}
                            value={this.component.data[field.name].input}// ? moment(this.component.data[field.name].input).format('YYYY-MM-DD HH:mm') : ''}
                            onSelect={(args) => {
                                this.props.actions.setComponentFieldInput(field, args)
                            }}
                            type={'date'}
                        />
                    </div>
                case 'hidden':
                    return <input
                        key={'component_' + this.component.name + '_' + field.name + '_' + this.component.data[field.name]}
                        type='hidden'
                        value={this.component.data[field.name].input}
                    />
                case 'timePicker':
                    return <DateControl
                        id={this.component.data[field.name].key}
                        key={this.component.data[field.name].key}
                        name={this.component.data[field.name].key}
                        label={window.t.en(field.hint)}
                        field={field}
                        value={this.component.data[field.name].input}
                        setComponentFieldInput={
                            this.props.actions.setComponentFieldInput
                        }
                        type={'time'}
                    />
                case 'textBox':
                    return field.validation && field.validation !== 'email'
                    ? <TextField
                        id={this.component.data[field.name].key}
                        key={this.component.data[field.name].key}
                        label={window.t.en(field.hint)}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        //helperText={this.component.data[field.name].validation.message}
                        type={field.validation ? field.validation : 'text'}
                        error={
                            (field.component_field_data_type.name === 'integer' || field.component_field_data_type.name === 'decimal') && this.component.data[field.name].input 
                            ? !!Number(this.component.data[field.name].input) 
                            : this.component.data[field.name].validation.message ? true : false}
                        helperText={(field.component_field_data_type.name === 'integer' || field.component_field_data_type.name === 'decimal') && this.component.data[field.name].input ? 'Invalid numeric value' : this.component.data[field.name].validation.message}
                        value={this.component.data[field.name].input}
                        onChange={
                            (event, value) => {
                                this.props.actions.setComponentFieldInput(field, event.target.value)
                            }
                        }
                        //type="phone"
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                    : <TextField
                        id={this.component.data[field.name].key}
                        key={this.component.data[field.name].key}
                        label={window.t.en(field.hint)}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        //helperText={this.component.data[field.name].validation.message}
                        type={field.validation ? field.validation : 'text'}
                        error={
                            (field.component_field_data_type.name === 'integer' || field.component_field_data_type.name === 'decimal') && this.component.data[field.name].input 
                            ? !!Number(this.component.data[field.name].input) 
                            : this.component.data[field.name].validation.message ? true : false}
                        helperText={(field.component_field_data_type.name === 'integer' || field.component_field_data_type.name === 'decimal') && this.component.data[field.name].input ? 'Invalid numeric value' : this.component.data[field.name].validation.message}
                        value={this.component.data[field.name].input}
                        onChange={
                            (event, value) => {
                                this.props.actions.setComponentFieldInput(field, event.target.value)
                            }
                        }
                    />
                case 'toggle':
                    return <div>
                        <CheckBoxControl
                            key={this.component.data[field.name].key}
                            id={this.component.data[field.name].key}
                            checked={this.component.data[field.name].input != null ? this.component.data[field.name].input : false}
                            field={field}
                            label={field.isrequired ? field.hint + ' *' : field.hint}
                            // setComponentFieldInput={this.props.actions.setComponentFieldInput}
                            onCheck={(checked) => {
                                this.props.actions.setComponentFieldInput(field, checked)
                            }}
                        />
                        {
                            this.component.data[field.name].validation.message
                                ? [
                                    <br />,
                                    <div style={{ color: 'red' }}>{this.component.data[field.name].validation.message}</div>
                                ]
                                : ''
                        }
                    </div>
                default:
                    return
            }
    }

    getField(field, index, hidden) {
        return !hidden
            ? this.getFormFieldValue(field, index)
            : this.getFormFieldValue(field, index)
    }

    
    getSection(section, index) {
        let fields = section.component_field
            .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
            .filter(x => x.form)
            .map((x, i) => {
                switch (x.component_field_display_type.name) {
                    case 'hidden':
                        return this.getField(x, i, true)
                    case 'autoComplete':
                        return <Col key={'col_' + x.name} xl={6}>{this.getField(x, i)}</Col>
                    default:
                        return <Col key={'col_' + x.name} xl={6}>{this.getField(x, i)}</Col>
                }
            })

        let _res = fields.length ? fields[0].toString() : ''

        return _res ? <Card key={'card_' + section.name} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={section.icon.unicode} iname={section.icon.name} />}
                title={section.title}
                subtitle={section.description}
                actAsExpander={true}
                showExpandableButton={true}
                style={styles.row}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        {fields}
                    </Row>
                </Container>
            </CardContent>
        </Card> : ''
    }

    getUpload() {
        return <Card key={'card_upload'} initiallyExpanded={true}>
            <CardHeader
                //avatar={<Icon iclass={section.icon.unicode} iname={section.icon.name} />}
                title={window.t.en('File Upload')}
                subtitle={window.t.en('Upload related documents')}
                actAsExpander={true}
                showExpandableButton={true}
                style={styles.row}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Upload
                            id={'upload_ctl'}
                            prefix={'po'}
                            header={false}
                        />
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getRelationship(relationship, index) {
        return <Card key={'relationship_' + relationship.name}>
            <CardHeader
                title={relationship.title}
                subtitle={relationship.description}
                actAsExpander={true}
                showExpandableButton={true}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Grid
                            name={relationship.name}
                            filterable={true}
                            link={{
                                parent: this.props.name,
                                id: this.props.id,
                                through: relationship.linker.name
                            }}
                        />
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getForm() {
        const sections = this.component.sections
            .filter(x => x.component_field.filter(y => y.form).length)
            .map((x, i) => {
                return this.getSection(x, i)
            })

        return <div>
            {sections}
        </div> 
    }

    render() {
        this.component = this.props.state.component
        return (
            this.component.data ? this.getForm() : 'Loading...'
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: { ...state.relatedForm, component: { ...state.components[ownProps.name] }, user: {...state.user}}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...controlActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedForm)