import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'



export const getMMCodeVehicles = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_MMCODE_LOOKUP_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.model + '/search' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': payload.order,
                    'related': payload.related,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setComponentFieldLookupData(payload.component.name, payload.field, result.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/' + payload.model + '/search' + payload.query)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.model + '/search' + payload.query, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const getMMCodeDetails = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_VEHICLES),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/ratecard/search?mmcode_id=' + payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'makemodelyear',
                    'related': payload.related,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setMMCodeDetails(payload.component.name, payload.field, payload.inx, result.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/ratecard/search' + payload.query)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/ratecard/search' + payload.query, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )

      
export const getNonRateCardVehicle = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_NON_RATECARD_DETAILS),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/mmcode/search?mmcode_id=' + payload.value,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'specialpricing,discountedpricing',
                'order': '-regyear'
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setNonRateCardVehicle(payload.component, payload.inx, result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/mmcode/search?mmcode_id=' + payload.value)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mmcode/search?mmcode_id=' + payload.value, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )
        
    
export const searchByMMCode = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_SEARCH_MMCODE_DETAILS),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/mmcode/search?mmcode=' + payload.value,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'take': 1,
                'order': '-regyear'
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.getPrimerate(result.response, payload.field, payload.inx, ),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/mmcode/search?mmcode=' + payload.value)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mmcode/search?mmcode=' + payload.value, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )
        
     
export const getPrimerate = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_CURRENT_PRIMERATE),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/primerate/search',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                'order': '-primerate_id',
                'take': 1,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setByMMCode(result.response, payload.data, payload.field, payload.inx),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/primerate/search')
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/primerate/search', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )
        

export const getYardList = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_YARD_LIST_VEHICLE_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/vehicle/search?licenseplateno=' + payload.value,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setYardList(result.response),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/vehicle/search?licenseplateno=' + payload.value)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search?licenseplateno=' + payload.value, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    )
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)


export const getMMCodeListOff = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_QUOTE_COMPONENT_FML_MMCODE_DATA_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + (payload.mmcodes ? '/components/mmcode/in?mmcode=' + payload.mmcodes : '/components/mmcode/searchor?mmcode=' + payload.search + '&makemodelyear' + payload.search ),
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'isnotnull': 'rvcode',
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setMMCodeList(result, payload),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/mmcode/search?mmcode=' + payload.search)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mmcode/search?mmcode=' + payload.search, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    )
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)


export const getMMCodeList = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_QUOTE_COMPONENT_FML_MMCODE_DATA_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            //url: config.system.api + (payload.mmcodes ? '/components/mmcode/in?mmcode=' + payload.mmcodes : '/components/mmcode/searchor?mmcode=' + payload.search + '&makemodelyear' + payload.search ),
            url: config.system.api + '/components/mmcode/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json',
                //'isnotnull': 'rvcode',
            },
            body: payload
        })
            .pipe(
            mergeMap((result) => [
                actions.setMMCodeList(result, payload),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/mmcode/search?mmcode=' + payload.search)
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mmcode/search?mmcode=' + payload.search, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    )
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)
export const getMMCodeToGrid = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_FML_QUOTE_MMCODE_RATE_CARD_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/ratecard/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json',
            },
            body: payload
        })
            .pipe(
            mergeMap((result) => [
                actions.setMMCodeToGrid(result.response, payload),
                progressBarActions.progress(false),
                requestActions.requestSucceeded(config.system.api + '/components/mmcode/search?mmcode=')
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mmcode/search?mmcode=', error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    )
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)
