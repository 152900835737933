import React, { Component } from 'react'
import { connect } from 'react-redux'

import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
import Oqlis from '../components/Oqlis/component'

class WFInHeader extends Component {

    getLayout() {
        return this.props.content
            ? <div>
                <div className="app-page-title app-page-title--shadow-off" style={{ marginBottom: '1rem !important' }} >
                    <div>
                        <div className="app-page-title--first">
                            {(
                                <div className="app-page-title--iconbox d-0">
                                    <div className="d-70 d-flex align-items-center justify-content-center display-1">
                                        <HomeWorkTwoToneIcon className="text-primary" />
                                    </div>
                                </div>
                            )}
                            <div className="app-page-title--heading">
                                <h1>{this.props.title}</h1>
                                {(
                                    <div className="app-page-title--description">
                                        {this.props.description}
                                    </div>
                                )}
                            </div>
                            {/* <div className="dashboard-title">
                                <Oqlis
                                    displayType={'dashboard'}
                                    oq_key={"a057e777a9b0cb8a9e1ab9ac2d42a0d117d43f61ed8f0c870cf74d4851ebb238f96e247e01896d3e0474443e3de3cf20a156ac61829b40afbe2a1ad2b171ac22"}
                                    oq_theme={"kpi_theme"}
                                    height={'155px'}
                                    noheader={1}
                                />
                            </div> */}
                        </div>

                    </div>
                </div>
                <div className="app-page-title-shadow" style={{ marginTop: '-3rem', marginRight: '-3rem', marginBottom: '1rem !important', marginLeft: '-3rem' }}>
                    {this.props.content}
                </div>
            </div>
            : <div>
                <div className="app-page-title app-page-title--shadow" style={{ margin: '0 !important' }} >
                    <div>
                        <div className="app-page-title--first">
                            {(
                                <div className="app-page-title--iconbox d-0">
                                    <div className="d-70 d-flex align-items-center justify-content-center display-1">
                                        <HomeWorkTwoToneIcon className="text-primary" />
                                    </div>
                                </div>
                            )}
                            <div className="app-page-title--heading">
                                <h1>{this.props.title}</h1>
                                {(
                                    <div className="app-page-title--description">
                                        {this.props.description}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.empty }
    }
}

export default connect(mapStateToProps)(WFInHeader)
