import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import Checkbox from '../../../controls/checkbox'
import Button from "@mui/material/Button";
import Icon from '../../Icon/component'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import { Table } from '@mui/material'

import moment from 'moment';
import TextField from '@mui/material/TextField'
import Upload from '../../Upload/component'

import ReactQuill from 'react-quill'

import * as actions from './actions'
import * as styles from './styles'

class EnterestGrid extends Component {
    componentDidMount() {
    }

    getTotal(data, prop) {
        var sum = 0
        for(let x of data) {
            sum = sum + parseFloat(x[prop].toString().replace(/,/g, ''))
        }
        return this.getField(sum, 'numeric')
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : ''
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }

    getGrid() {
        return <AdhocGrid
            key={'adhoc_grid_enterest_review_data'}
            name={'adhoc_grid_enterest_review_data'}
            filterable={false}
            sortable={false}
            height={'1000px'}
            loading={this.props.state.data ? false : true}
            data={this.props.state.data}
            search={''}
            style={{
                background: '#90ee90',
                color: 'white'
            }}
            columns={[
                { accessor: 'customer_fktext', Header: 'Debtor Name', filterable: true },
                //{ accessor: 'customername', Header: 'Debtor Name', filterable: true },
                { accessor: 'invoicenumber', Header: 'Invoice Number', filterable: true },
                { accessor: 'customerreference', Header: 'Customer Reference', filterable: true },
                //{ accessor: 'licenceplateno', Header: 'Registration', filterable: true },
                { accessor: 'vehicle_fktext', Header: 'Registration', filterable: true },
                { accessor: 'contractnumber', Header: 'Contract Number', filterable: true },
                { accessor: 'age', Header: 'Age', filterable: true },
                { accessor: 'amount', Header: 'Amount', filterable: true },
                { accessor: 'debtamount', Header: 'Debt Amount', filterable: true },
            ]}
            paging={{
                page: 0,
                pageSize: 10,
                pages: 0,
                count: 0
            }}
        />
    }

    getRefreshing () {
        return  <Row>
            <Col xl={12}>
                <div>
                    <h4>Enterest Refreshing:</h4>
                    <ul>
                        <li>This will take couple of minutes</li>
                    </ul>
                </div>
            </Col>
        </Row>
    }
    
    getLayout() {
        //console.log('setValuex getLayout',this.props.state )
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>{this.props.state.refreshing ? this.getRefreshing() : this.getGrid()}</Col>
            </Row>
        </Container>
    }

    render() {
        
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.enterestReview
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterestGrid)