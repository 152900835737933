import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'

import { Card, CardHeader, CardContent, IconButton, Dialog, Button } from '@mui/material'

import * as helper from '../../helpers/components'
import Icon from '../Icon/component'
import SurrogateGrid from '../SurrogateGrid/component'
import SurrogateView from '../SurrogateView/component'

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RelatedGrid from '../RelatedGrid/component'
import Loading from '../Loading/component'

import Oqlis from '../Oqlis/component'
import ViewLabel from '../../controls/viewlabel'
import * as actions from '../Form/actions'
import * as viewActions from './actions'
import * as styles from './styles'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'


class RelatedView extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
        this.props.actions.loadComponent(this.props.name, this.props.id, this.props.stop, true)// this.props.surrogate)
    }

    getFieldValue(field, index) {
        if(!this.component.data) return ''
        let value = ''
        try {
            value = this.component.data[field.name] 
                ? this.component.data[field.name].input 
                : field.display ? this.component.selected[0].original[field.display.split('.')[0]][field.display.split('.')[1]] : ''
      
        } catch (error) {
            value = ''
        }
        
       // //console.log('getFieldValue',this.component.data[field.name])
        if (this.component.data[field.name] && typeof this.component.data[field.name].input === 'object') {
            const child = this.props.state.components[field.name]

            switch (field.component_field_display_type.name) {
                case 'autoComplete':
                    return  value
                case 'datePicker':
                case 'timePicker':
                    return value !== null
                        ? moment(value).format('YYYY-MM-DD hh:mm') //+ ', ' + moment(value).fromNow()
                        : 'Not Set'
                case 'toggle':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value
                    return value !== null
                        ? value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'html':
                    return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
                case 'image':
                    return <a href={"data:image/jpeg;base64," + value} download><img src={"data:image/jpeg;base64," + value} style={{ height: '500px',width: '500px' }} /></a>
                case 'textBox':
                    //EUGENE NEEDS TO CHECK THIS, NOT SUPPOSED TO BE TEXT BOX RELATED HERE....
                    return helper.getComponentTitle(child, value)
                default:
                    return ''
            }
        }
        if(field.name.indexOf('_fktext') >= 0 && field.component_reference){
            return value
        }
        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return   value 
            case 'datePicker':
            case 'timePicker':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD hh:mm') //+ ', ' + moment(value).fromNow()
                    : 'Not Set'
            case 'textBox':
                return field.component_field_data_type.name === 'decimal' || field.component_field_data_type.name === 'float'
                    ? value !== null && value.length > 0 && !isNaN(value)
                        ? parseFloat(value).toFixed(2)
                        : value
                    : value
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            case 'image':
                return <a href={"data:image/jpeg;base64," + value} download><img src={"data:image/jpeg;base64," + value} style={{ height: '500px',width: '500px' }} /></a>
            case 'toggle':
                const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value
                return value !== null
                    ? value
                        ? toggle[0]
                        : toggle[1]
                    : 'Not Set'
            default:
                return ''
        }
    }

    getField(field, index) {
        return this.getFieldValue(field, index)
    }

    getSection(section, index) {
        const fields = !section.feature
            ? section.component_field
                .filter(x => !x.related && !x.key && x.view)
                .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
                .reduce((arr, x, i) => {
                    
                    arr.push(
                        <>
                            <Col className={'custom-col-label'} xl={3}>
                                <label style={{textOverflow: 'ellipsis', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
                                    {x.title + ": "}
                                </label>  
                            </Col>
                            <Col className={'custom-col-label'} xl={3}>
                                <Typography variant="body1" component="div"
                                    style={{width: '300px', fontWeight: 'bold', textOverflow: 'ellipsis'}}>
                                    <label style={{width: '300px !important', textOverflow: 'ellipsis'}}>
                                    {this.getField(x, i)} 
                                    </label>  
                                </Typography>
                            </Col></>
                            // <Col className={'custom-col-label'} xl={6}>
                            //         <FormControlLabel
                            //             fullWidth={true}
                            //             control={
                            //                 <Typography variant="body1" component="div"
                            //                 style={{width: '300px', fontWeight: 'bold', textOverflow: 'ellipsis'}}>
                            //                     <label style={{width: '300px !important', textOverflow: 'ellipsis'}}>
                            //                     {this.getField(x, i)} 
                            //                     </label>  
                            //                 </Typography>
                            //             }
                            //             label={
                            //                 <label style={{textOverflow: 'ellipsis', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
                            //                     {x.title + ": "}
                            //                 </label>  
                            //             }
                            //             labelPlacement="start"
                            //         />
                            // </Col>
                    )
                    return arr
                }, [])
            : section.component_field
                .filter(x => !x.related && !x.key)
                .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
                .reduce((arr, x, i) => {
                        if (this.component.data[x.name].input !== -1) {
                            arr.push(
                                    <Col xl={6}>
                                        {x.title}
                                        {this.component.data[x.name].input === 1 ? 'Yes' : 'No'}
                                    </Col>
                            )
                        }
                    return arr
                }, [])

                //console.log("fields", JSON.stfields)
        return <Card key={'card_' + section.name} initiallyExpanded={true}> {/* initiallyExpanded={index === 0} */}
            <CardHeader
                avatar={<Icon iclass={section.icon.unicode} iname={section.icon.name} />}
                title={window.t.en(section.title)}
                subtitle={window.t.en(section.description)}
                actAsExpander={true}
                showExpandableButton={true}
                style={styles.row}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row className={'custom_view_text'}>
                        {fields}
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getSectionView() {
        return this.component.data
            ? this.component.sections
                .sort((x, y) => x.order - y.order)
                .map((x, i) => {
                    return this.getSection(x, i)
                })
            : 'Loading...'
    }

    render() {
        this.component = this.props.state.component

        return this.component.loading
            ? <Loading message={'Retrieving component data...'} />
            : this.getSectionView()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.view,
            component: {
                ...state.components[ownProps.name]
            },
            components: {
                ...state.components
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...viewActions,...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedView)