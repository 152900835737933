import * as types from './types'
import * as workflowTaskInputTypes from '../WorkflowTaskInput/types'
import * as formActions from '../Form/actions'

export const addComponentRow = (step, name) => {
    const getRowData = (component) => {
        return component.component_field.reduce((obj, x) => {
            obj[x.name] = component.data[x.name].input
            return obj
        }, {})
    }

    return (dispatch, getState) => {
        dispatch(formActions.validateForm(name))

        const component = getState().components[name]

        Object.keys(component.data).filter(x => !component.data[x].validation.valid).length
            ? dispatch({
                type: workflowTaskInputTypes.SHOW_COMPONENT_ERRORS,
                payload: [component]
            })
            : dispatch({
                type: types.ADD_COMPONENT_ROW,
                payload: {
                    step: step,
                    component: name,
                    row: getRowData(component)
                }
            })
    }
}

export const removeComponentRow = (step, component, index) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REMOVE_COMPONENT_ROW,
            payload: {
                step: step,
                component: component,
                index: index
            }
        })
    }
}