import * as finance from 'financejs'
import * as formulas from 'formulajs'

import * as types from './types'

export const createCalculatorState = (id) => {
    return {
        type: types.CREATE_CALCULATOR_STATE,
        payload: id
    }
}

export const removeCalculatorState = (id) => {
    return {
        type: types.REMOVE_CALCULATOR_STATE,
        payload: id
    }
}

export const getVehicles = (id, search) => {
    return {
        type: types.GET_VEHICLES,
        payload: {
            id: id,
            search: search
        }
    }
}

export const setVehicles = (id, payload) => {
    return {
        type: types.SET_VEHICLES,
        payload: {
            id: id,
            data: payload
                ? Array.isArray(payload) ? payload : [...payload]
                : []
        }
    }
}

export const reset = (id) => {
    return {
        type: types.RESET_FINANCIAL_CALCULATOR,
        payload: id
    }
}

export const calculate = (id) => {
    return (dispatch, getState) => {
        const calculator = getState().calculator.calculators.filter(x => x.id === id)[0]

        switch (calculator.solve) {
            case 1:
                dispatch(solveForMonthlyPayment(calculator))
                break
            case 2:
                dispatch(solveForPresentValue(calculator))
                break
            case 3:
                dispatch(solveForFutureValue(calculator))
                break
            case 4:
                dispatch(solveForInterestRatePerAnnum(calculator))
                break
            default:
                break
        }
    }
}

export const changeSelect = (id, field, value) => {
    const getDisabledField = () => {
        switch (value) {
            case 1:
                return {
                    monthlyPayment: true,
                    presentValue: false,
                    futureValue: false,
                    interestRatePerAnnum: false,
                    residualValue: false,
                    totalPayments: false,
                    totalInterest: false
                }
            case 2:
                return {
                    monthlyPayment: false,
                    presentValue: true,
                    futureValue: false,
                    interestRatePerAnnum: false,
                    residualValue: false,
                    totalPayments: false,
                    totalInterest: false
                }
            case 3:
                return {
                    monthlyPayment: false,
                    presentValue: false,
                    futureValue: true,
                    interestRatePerAnnum: false,
                    residualValue: false,
                    totalPayments: false,
                    totalInterest: false
                }
            case 4:
                return {
                    monthlyPayment: false,
                    presentValue: false,
                    futureValue: false,
                    interestRatePerAnnum: true,
                    residualValue: false,
                    totalPayments: false,
                    totalInterest: false
                }
        }
    }

    return (dispatch, getState) => {
        dispatch(reset(id))

        dispatch({
            type: types.CHANGE_SELECT,
            payload: {
                id: id,
                field: field,
                value: value,
                disabled: getDisabledField()
            }
        })
    }
}

export const setValue = (id, field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FIELD_VALUE,
            payload: {
                id: id,
                field: field,
                value: value
            }
        })
    }
}

export const setError = (id, field, value) => {
    return (dispatch, getState) => {
        const error = getState().calculator.errors.filter(x => x.value === value)[0]

        if (error) {
            dispatch({
                type: types.SET_ERROR,
                payload: {
                    id: id,
                    field: field,
                    value: error.text
                }
            })
        }
    }
}

export const resetValue = (id, field) => {
    return {
        type: types.RESET_FIELD_VALUE,
        payload: {
            id: id,
            field: field
        }
    }
}

export const solveForMonthlyPayment = (calculator) => {
    return (dispatch, getState) => {
        let principleDebt = 0.00
        let presentValue = parseFloat(calculator.presentValue)
        let futureValue = 0.00
        let periodInMonths = parseFloat(calculator.periodInMonths)
        let interestRatePerAnnum = parseFloat(calculator.interestRatePerAnnum / 100)
        let residualValue = parseFloat(calculator.residualValue)
        let monthlyPayment = 0.00
        let totalPayments = 0.00
        let totalInterest = 0.00

        futureValue = (presentValue * (residualValue / 100))
        principleDebt = (presentValue - futureValue)
        monthlyPayment = formulas.PMT((interestRatePerAnnum / 12), periodInMonths, -presentValue, futureValue, calculator.mode === 1 ? 1 : 0)

        totalPayments = (monthlyPayment * periodInMonths)
        totalInterest = (totalPayments - principleDebt)

        dispatch({
            type: types.CALCULATE,
            payload: {
                id: calculator.id,
                presentValue: presentValue.toFixed(2),
                futureValue: futureValue.toFixed(2),
                periodInMonths: periodInMonths,
                interestRatePerAnnum: interestRatePerAnnum.toFixed(2),
                monthlyPayment: monthlyPayment.toFixed(2),
                totalPayments: totalPayments.toFixed(2),
                totalInterest: totalInterest.toFixed(2)
            }
        })
    }
}

export const solveForPresentValue = (calculator) => {
    return (dispatch, getState) => {
        let presentValue = 0.00
        let futureValue = 0.00
        let periodInMonths = parseFloat(calculator.periodInMonths)
        let interestRatePerAnnum = parseFloat(calculator.interestRatePerAnnum / 100)
        let residualValue = parseFloat(calculator.residualValue)
        let monthlyPayment = parseFloat(calculator.monthlyPayment)
        let totalPayments = 0.00
        let principleDebt = 0.00
        let totalInterest = 0.00

        //PV(rate, periods, payment, future, type)

        //=PV(0,1025/12; 60; -15899,4763; 0)

        presentValue = Math.abs(
            formulas.PV(
                parseFloat(interestRatePerAnnum / 12),
                periodInMonths,
                monthlyPayment,
                0,
                calculator.mode === 1 ? 1 : 0
            )
        )

        futureValue = (presentValue * (residualValue / 100))
        principleDebt = (presentValue - futureValue)

        totalPayments = (monthlyPayment * periodInMonths)
        totalInterest = (totalPayments - principleDebt)

        dispatch({
            type: types.CALCULATE,
            payload: {
                id: calculator.id,
                presentValue: presentValue.toFixed(2),
                futureValue: futureValue.toFixed(2),
                periodInMonths: periodInMonths,
                interestRatePerAnnum: interestRatePerAnnum.toFixed(2),
                monthlyPayment: monthlyPayment.toFixed(2),
                totalPayments: totalPayments.toFixed(2),
                totalInterest: totalInterest.toFixed(2)
            }
        })
    }
}

export const solveForFutureValue = () => {
    //=FV(rate, nper, pmt, [pv], [type])

    return {
        type: types.SOLVE_FOR_FUTURE_VALUE
    }
}

export const solveForInterestRatePerAnnum = () => {
    return {
        type: types.SOLVE_FOR_INTEREST_RATE_PER_ANNUM
    }
}