
export const labourrate = ''
export const supplierdocument = ''
export const selectedSupplierId = ''
export const selectedSupplierName = ''
export const geoVehiclePosition = []
export const servicebasket = []
export const customercontractderivative = null
export const supplier_data = []
export const quotations = []
export const selected_supplier = {
    errorText: 'Please verify supplier selection',
    supplier_id: 0,
    supplier_name: ''
}

export const filename = {
    errorText: 'Filename is required',
    value: '',
    valid: false,
    empty: true,
}
export const invoicepreview = {
    errorText: 'Upload supplier invoice',
    value: '',
    valid: false,
    empty: true,
}
export const quotenumber = {
    errorText: 'Quote number is required',
    value: null,
    valid: false,
    empty: true,
}


export const validation = {
    fields: ['quotedate', 'quotenumber'],
    list: [
        {
            comp: 'defleetItems',
            prop: 'grid',
            errorText: 'Please enter quote items'
        },
        {
            comp: 'upload',
            prop: 'documents',
            errorText: 'Please upload documents'
        }
    ],
    component: 'defleetUploadQuotes',
    valid: false
}
export const selectedPurchaseOrderType = {
    value: 4,
    text: 'De-fleet',
    key: 'vehicle_defleet',
    types: []
}

export const purchaseOrderTypes = [
    {
        value: 1,
        text: 'Assessor',
        key: 'assessor',
        types: []
    },
    // {
    //     value: 2,
    //     text: 'Corporate Vehicle',
    //     key: 'corporate_vehicle',
    //     types: []
    // },
    // {
    //     value: 3,
    //     text: 'FML Transnet',
    //     key: 'fml_transnet',
    //     types: []
    // },
    {
        value: 4,
        text: 'De-fleet',
        key: 'vehicle_defleet',
        types: []
    },
    {
        value: 5,
        text: 'Panel Beaters',
        key: 'panel_beatears',
        types: []
    },
    {
        value: 6,
        text: 'Repairs',
        key: 'repairs',
        types: []
    },
    {
        value: 7,
        text: 'Service',
        key: 'service',
        types: []
    },
    // {
    //     value: 8,
    //     text: 'STR Transnet / Corporate',
    //     key: 'str_transnet_corporate',
    //     types: []
    // },
    // {
    //     value: 9,
    //     text: 'Subscriptions',
    //     key: 'subscriptions',
    //     types: []
    // },
    {
        value: 10,
        text: 'Towing',
        key: 'towing',
        types: []
    },
    {
        value: 11,
        text: 'Tyres',
        key: 'tyres',
        types: []
    }
]

export const users = []

export const selectedUser = {
    errorText: 'Copy selection field required',
    data: [],
    valid: false,
    empty: true,
}

export const selectedVehicleDetails = {
    errorText: 'Please select vehicle from the list',
    data: [],
    valid: false,
    empty: true,
}

export const selectedSupplierDetails = {
    errorText: 'Please select supplier from the list',
    data: [],
    valid: false,
    empty: true,
}

export const selectedSupplierSuspended = {
    errorText: 'Selected supplier is suspended please select another supplier',
    data: [],
    valid: false,
    empty: true,
}
export const overthelimit = {
    errorText: 'Selected supplier is over the limit, please another supplier',
    data: [],
    valid: false,
    empty: true,
}
export const vehicles = []
export const supplierage = []
export const bookinginfo = []
export const poBookingItems = []
export const selectedVehicle = []
export const preAuth = null
export const geoSuppliers = null
export const vehicleserviceinfo = []
export const recent_auth = []
export const maintpdf_preview = ''
export const maintpdf_filename = ''
export const toggle_correct_supplier = {
    errorText: '',
    value: false,
    valid: true,
    empty: true,
}

export const supplierAutoCompleteAmount = 1

export const supplierAutoCompletes = [
    {
        id: 0,
        data: [],
        selected: []
    }
]

export const quotedate = {
    errorText: 'Quote Date is required',
    value: null,
    valid: false,
    empty: true,
}

export const category = {
    errorText: 'Quote Date is required',
    value: 'De-fleet Repairs',
    valid: false,
    empty: true,
}

export const description = {
    errorText: 'Quote Date is required',
    value: 'De-fleet',
    valid: false,
    empty: true,
}

export const amount = {
    errorText: 'Quote Date is required',
    value: 0.00,
    valid: false,
    empty: true,
}

export const discount = {
    errorText: 'Quote Date is required',
    value: 0.00,
    valid: false,
    empty: true,
}
export const savings = {
    errorText: 'Quote Date is required',
    value: 0.00,
    valid: false,
    empty: true,
}
export const total = {
    errorText: 'Quote Date is required',
    value: 0.00,
    valid: false,
    empty: true,
}

export const vat = {
    errorText: 'Quote Date is required',
    value: 0.00,
    valid: false,
    empty: true,
}

export const totalamount = {
    errorText: 'Quote Date is required',
    value: 0.00,
    valid: false,
    empty: true,
}


export const fields = {

    documents: {
        errorText: 'Please upload documents for each of your quotations',
        state: 'getState().upload.documents?.length === getState().defleetUploadQuotes.quotations.length',
        valid: false,
        empty: true,
    },
    quotationLineItemPrice: {
        state: 'getState().quoteItems.quotations?.length ?  getState().quoteItems.quotations : true',
        errorText: 'Please enter Unit Price for the quotation line item',
        value: '',
        valid: false,
        empty: true,
        type: 'table',
        validations: [
            {
                type: 'decimal',
                errorText: 'Please enter a valid Unit Price for the quotation line item'
            },
            {
                type: 'greaterThanZero',
                required: true,
                maxLength: null,
                errorText: 'Please enter a valid Unit Price for the quotation line item'
            }
        ],
        tableFields: ['Price']
    },
    quotationLineItemQuantity: {
        state: 'getState().quoteItems.quotations?.length ?  getState().quoteItems.quotations : true',
        errorText: 'Please select Item Quantity for the quotation line item',
        value: '',
        valid: false,
        empty: true,
        type: 'table',
        validations: [
            {
                type: 'integer',
                errorText: 'Please enter a valid Quantity for the quotation line item'
            },
            {
                type: 'greaterThanZero',
                required: true,
                maxLength: null,
                errorText: 'Please enter a valid Quantity for the quotation line item'
            }
        ],
        tableFields: ['Quantity']
    },
    quotationLineItemDescription: {
        state: 'getState().quoteItems.quotations?.length ?  getState().quoteItems.quotations : true',
        errorText: 'Please select Item Description for the quotation line item',
        value: '',
        valid: false,
        empty: true,
        type: 'table',
        tableFields: ['Description']
    },
    quotationLineItemCategory: {
        state: 'getState().quoteItems.quotations?.length ?  getState().quoteItems.quotations : true',
        errorText: 'Please select Item Category for the quotation line item',
        value: '',
        valid: false,
        empty: true,
        type: 'table',
        tableFields: ['Class']
    },
    quotationLineItems: {
        state: ' getState().quoteItems.quotations.length > 0',
        errorText: 'Please enter at least one quotation line item',
        value: '',
        valid: false,
        empty: true,
    },
    quotationItemQuoteDate: {
        errorText: 'Please ensure Quote Date is input',
        state: 'getState().defleetUploadQuotes.quotations?.length > 0 ? getState().defleetUploadQuotes.quotations : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['quotedate']
    },
    quotationItemSupplierQuoteNo: {
        errorText: 'Please ensure Supplier Quote Number is input',
        state: 'getState().defleetUploadQuotes.quotations?.length > 0 ? getState().defleetUploadQuotes.quotations : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['quotenumber']
    },
    quotationItemSupplier: {
        errorText: 'Please ensure Supplier is selected',
        state: 'getState().defleetUploadQuotes.quotations?.length ? getState().defleetUploadQuotes.quotations : true',
        data: false,
        valid: false,
        empty: true,
        popup: false,
        type: 'table',
        tableFields: ['supplier_name']
    },
    quotationItems: {
        errorText: 'Please add a quotation before you proceed',
        state: 'getState().defleetUploadQuotes.quotations.length',
        valid: false,
        empty: true,
    }
}

export const quote_items_fields = [
    {
        name: 'Class',
        label: 'Item Category',
        type: 'select',
        width: 200,
        col: 2,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'maintenanceclass',
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Description',
        label: 'Description',
        type: 'select',
        width: 250,
        col: 2,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'maintenanceitem',
        related: 'maintenanceclass_id.Class_Id',
        text: null,
        value: null
    },
    {
        name: 'Quantity',
        label: 'Quantity',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Price',
        label: 'Unit Price',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Total',
        label: 'Total',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Savings',
        label: 'Savings',
        type: 'numeric',
        //: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Discount',
        label: 'Discount(%)',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Discount Amount',
        label: 'DiscountAmount',
        type: 'numeric',
        //width: '5%',
        col: 1,
        align: 'right',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'VAT',
        label: 'VAT',
        type: 'numeric',
        //: '5%',
        col: 1,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Amount',
        label: 'Total Amount',
        type: 'numeric',
        //width: '5%',
        col: 2,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Rebill',
        label: 'Rebill',
        type: 'toggle',
        // width: 100,
        col: 1,
        align: 'left',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Warranty',
        label: 'Warranty (Months)',
        type: 'numeric',
        //width: 100,
        col: 1,
        align: 'right',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    // {
    //     name: 'action',
    //     label: '',
    //     type: 'action',
    //     width: 100,
    //     align: 'right',
    //     hide: false,
    //     read_only: false,
    //     related: null,
    //     text: null,
    //     value: null
    // }
    {
        name: 'Comment',
        label: 'Comments',
        type: 'text',
        //width: '15%',
        col: 2,
        align: 'left',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'Vattable',
        label: 'Vattable',
        type: 'toggle',
        //width: 0,
        col: 1,
        align: 'left',
        hide: true,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'labourrate_exceeded',
        label: 'labourrate_exceeded',
        type: 'toggle',
        //width: 0,
        col: 1,
        align: 'right',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    }/* ,
    {
        name: 'Exceptions',
        label: 'Exceptions',
        type: 'exceptions',
        //width: 0,
        col: 1,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    } */
]