//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const addComponentRow = {
    textAlign: 'right'
}

export const deleteIcon = {
    fontSize: '34px'
}

export const deleteIconHoverColor = {
    color: Theme.palette.primary1Color
}

export const workflowTaskStepComponentCard = {
    margin: '0px'
}


export const confirmSelection = {
    textAlign: 'right'
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const right = {
    float: 'right'
}

export const textBox = {
    width: '100%'
}

export const max = {
    width: '100%'
}