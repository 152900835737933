import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_RELATED_FIELD_INPUT:
            return {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    [action.payload.field]: {
                        ...state[action.payload.component][action.payload.field],
                        input: action.payload.input,
                        validation: action.payload.validation
                    }
                }
            }
        case types.SET_COMPONENT_RELATED_FIELD_INPUT_AND_TEXT:
            //console.log('SET_COMPONENT_FIELD_INPUT_AND_TEXT', action.payload)
            let _data = {
                ...state,
                [action.payload.component]: {
                    ...state[action.payload.component],
                    [action.payload.field]: {
                        ...state[action.payload.component][action.payload.field],
                        input: action.payload.input,
                        text: action.payload.text,
                        validation: action.payload.validation
                    }
                }
            }
            //console.log('SET_COMPONENT_FIELD_INPUT_AND_TEXT _data', _data)
            return _data
        case types.FORM_LOAD_COMPONENT: {
            return {
                ...state,
                [action.payload.component]: action.payload.data
            }
        }
        case types.SET_COMPONENT_FINE_REDIRECT_WIN: {
            return {
                ...state,
                prompt_win: action.payload
            }
        }
        case types.SET_COMPONENT_FINE_REDIRECT_STATUS: {
            return {
                ...state,
                prompt_accept: action.payload
            }
        }
        case types.SET_COMPONENT_FORM_TREE_VIEW_CONTROL_DATA: {
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        }
        case types.SET_FORM_VALIDATION_RESULT:
            return {
                ...state,
                valid: action.payload.valid,
                errors: action.payload.errors
            }
        default:
            return state
    }
}