import * as colours from '@mui/material/colors'
import * as config from '../../../config'

export const selected = {
    backgroundColor: colours.red[100]
}

export const tableheader = {
    fontSize: '16px',
    backgroundColor: '#D1E0E6',
    fontWeight: 'bold',
    padding: '4px',
    borderTop: '1px solid black'
}

export const gridrow = {
    fontSize: '16px',
    paddingTop: '20px',
}
export const view = {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0
}

export const card = {
    height: '100%'
}

export const scrollbar = {
    backgroundColor: colours.red[500],
    color: colours.red[500],
}

export const errorBox = {
    backgroundColor: colours.red[500],
    color: colours.grey[50],
    fontWeight: 'bold',
}
export const spacer = {
    height: '65px',
    width: '100%',
}

export const headerBackground = (image) => {
    return image && image !== null
        ? {
            height: '130px',
            background: 'url(' + config.system.cdn + '/' + image + ')',
            backgroundSize: 1980,
            //opacity: 0.85,
        }
        : {
            height: '130px'
        }
}

export const headerContainer = {
    width: '100%',
    display: 'flex'
}

export const headerIcon = {
    width: 40,
    height: 40,
    fontSize: 40,
    margin: '0 auto'
}

export const headerTitle = {
    background: 'white',
    //opacity: 0.8,
    fontWeight: 'bold',
    fontSize: '34',
    padding: 5,
    marginRight: 20
}

export const headerDescription = {
    background: 'white',
    //opacity: 0.8,
    //color: 'white',
    width: '100%',
    fontSize: '34',
    padding: 5,
    borderRight: '1px solid black'
}

export const rightpanel = {
    background: '#D1E0E6',
    borderLeft: '2px solid black'
}

export const content = {
    height: '100%',
    display: 'flex',
    flexFlow: 'row wrap'
}

export const flex = {
    display: 'flex'
}


export const calculator = {
    width: '80%'
}

export const toggle = {
    marginBottom: 16,
  }

  
export const right = {
    float: 'right'
}

export const confirmSelection = {
    textAlign: 'right'
}


export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}
export const selectedFieldName = {
    fontWeight: 'bold'
}

export const dropZone = {
    width: '85%',
    height: '300px',
    border: '1px solid red',
    padding: '10px'
}

export const fieldContainer = {
    display: 'table',
}

export const fieldContainerRow = {
    display: 'table',
    borderBottom: '1px solid black'
}

export const center = {
    margin: 'auto',
    width: '100%',
}
export const centerContainer = {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell',
    width: '450px',
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10
}
export const fieldTitleLeft = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10
}
export const fieldTitleLeftB = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10,
    fontWeight: 'bold'
}
export const rowTitle = {
    width: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const fieldContentTot = {
    display: 'table-cell',
    width: '65%'
}

export const textBox = {
    width: '100%'
}

export const error = {
    color: colours.red[600]
}

export const valid = {
    color: colours.green[500]
}

export const subheaders = {
    backgroundColor: "#D1E0E6",
    fontSize: '16px',
    textAlign: 'left',
    paddingTop: '10px',
    paddingBottom: '10px',
    margin: '0px',
}

export const nomargin = {
    margin: '0px',
}
export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    textAlign: 'left',
}