import * as types from './types'
import * as appTypes from '../../App/types'
import moment  from 'moment'
import * as formulas from 'formulajs'

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {
        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_INTEREST_RATE_CREATE,
            payload: payload
        })
    }
}

export const getInterestRateContractsList = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_INTEREST_RATE_CONTRACTS,
            payload: { }
        })
    }
}

export const setInterestRateContractsList = (response) => {
    return (dispatch, getState) => {
        //console.log('setInterestRateContractsListx', response.data)
        dispatch(setInputValue('contracts',response.data))
        dispatch(calcTotal())
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_INTEREST_RATE_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const getLookupData = (component, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_INTEREST_RATE_COMPONENT_LOOKUP_DATA,
            payload: {
                component: component,
                query: search ? '?' + field + '=' + search : '',
                field: field
            }
        })
    }
}

export const getPrimeRate = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_INTEREST_RATE_PRIMERATE
        })
    }
}

export const setPrimeRate = (response) => {
    return (dispatch, getState) => {
        //console.log('interest_ratex',response.data)
        //dispatch(setInputValue('interest_rate',response.data[0].primerate))
        dispatch(setInputValue('primarate',response.data))
    }
}

export const setLookupData = (response, payload) => {
    return (dispatch, getState) => {
        dispatch(setInputValue(payload.component + '_data', response.data.map(x => {
            return {
                ...x,
                value: x[payload.component + '_id'],
                text: x[payload.field]
            }
        })))
    }
}

export const calcTotal = () => {
    return (dispatch, getState) => {
        let contracts = getState().interestRateCreate.contracts
        let interest_rate = getState().interestRateCreate.interest_rate
        let _contracts = contracts.map(x => {
            let derivative = x.customercontractderivative.length ? x.customercontractderivative[0] : {}
            //console.log(derivative.remainingbillingcount)

            //console.log('interest_ratex',interest_rate)
            //console.log('interest_ratex capitalbalance',derivative.capitalbalance)
            //console.log('interest_ratex remainingbillingcount',derivative.remainingbillingcount)

            if (derivative.interestrate) {
                let xinterestrate = parseFloat(derivative.interestrate) + parseFloat(interest_rate);
                let financeValue = formulas.PMT(((parseFloat(xinterestrate) / 100) / 12), derivative.remainingbillingcount, -(derivative.capitalbalance), parseInt(derivative.residualvalue), 0);
                console.log('PMT output:', financeValue);  // Debugging line
            
                let finance = (isNaN(financeValue) || financeValue === null) ? '0.00' : financeValue.toFixed(2);
            
                derivative['financemonthlyselling'] = parseFloat(finance).toFixed(2);
                derivative['xinterestrate'] = parseFloat(xinterestrate).toFixed(2);
            
                let sub_total = parseFloat(finance) + 
                    parseFloat(derivative.monthlyadminfee) + parseFloat(derivative.tyresmonthlyselling) +
                    parseFloat(derivative.maintenancemonthlyselling) + parseFloat(derivative.licenceselling);
            
                let rio = parseFloat(sub_total) * 0.12;
                sub_total = parseFloat(sub_total) + parseFloat(rio);
            
                derivative['monthlyreturnoninvestment'] = parseFloat(rio).toFixed(2);
                derivative['monthlysellingvat'] = parseFloat(sub_total * 0.15).toFixed(2);
            
                derivative['monthlysellingsubtotal'] = parseFloat(sub_total).toFixed(2);
                derivative['monthlysellingtotal'] = parseFloat(sub_total * 1.15).toFixed(2);
                derivative['monthlysellingvat'] = parseFloat(sub_total * 0.15).toFixed(2);
            }
            return {
                ...x,
                ['customercontractderivative']: [derivative]
            }
        })
        
        dispatch(setInputValue('contracts',_contracts))
        
    }
}

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT_INTERESTCHANGE_FORM,
        })
    }
}

