import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab } from '@mui/material'
import GeneralContentView from '../../views/GeneralContentView/component'
import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';

import Loading from '../Loading/component'
import Parameters from './parameters'
import Cards from './cards'
import View from './view'

import * as actions from './actions'
import * as styles from './styles'

class LicenseeAccount extends Component {

    headerTitle () {
        return (
        <>
            <div className="app-page-title app-page-title--shadow" style={{ marginBottom: '0px',padding: '20px 65px'}}>
            <div>
                <div className="app-page-title--first">
                {(
                    <div className="app-page-title--iconbox d-70">
                    <div className="d-70 d-flex align-items-center justify-content-center display-1" 
                    style={{cursor: 'hand !important'}}
                    onClick={(event) => {
                        this.props.actions.route('/LicenseeAccount')
                    }}>
                        <HomeWorkTwoToneIcon className="text-primary" />
                    </div>
                    </div>
                )}
                <div className="app-page-title--heading">
                    <h1>{'License Account'}</h1>
                    {(
                    <div className="app-page-title--description">
                        {'License Account List'}
                    </div>
                    )}
                </div>
                </div>

            </div>
            <div className="d-flex align-items-center">
            </div>
            </div>
            </>
        )
    }

    headers() {
        return (
            <div
                key={'detail_component_view_content_budget_management'}
                style={styles.content}>
                <div
                    key={'detail_component_view_title_budget_management'}
                    style={styles.title}>
                    <div style={styles.title_header}>
                        {this.headerTitle()}
                    </div>
                    <div style={styles.title_buttons}>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        ////console.log("Card Management",this.props.state)
        return <div>
            {this.headers()}
                        <Tabs
                            initialSelectedIndex={0}
                            value={this.props.state.tab}
                            inkBarStyle={{
                                height: 4
                            }}
                            onChange={(event, value) => {
                                ////console.log("Card Management",this.props.state)
                                
                                for(let x of this.props.state.licensee.component_field){
                                    this.props.actions.setComponentFieldInput(x,null)
                                }

                                this.props.actions.changeTabView(value)

                            }}
                        >
                            <Tab style={{minWidth:"50%"}} label={"Licensee Account"} />
                            <Tab style={{minWidth:"50%"}} label={"Create Licensee Account"} />
                        </Tabs>
                        <br/>
                        <div>
                            {this.props.state.tab === 0 && <Box><Cards /></Box>}
                            {this.props.state.tab === 1 && <Box><Parameters /></Box>}
                            {this.props.state.tab === 2 && <Box><View /></Box>}
                        </div>
                    </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.licenseeAccount,
            licensee: {
                ...state.components.licensee
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LicenseeAccount)