import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs, Box, Tab, Button, List, ListItem, Tooltip, Dialog, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import TextField from '../../../controls/textField'
import Checkbox from '../../../controls/checkbox'
import DateControl from '../../../controls/dateControl'

import * as actions from './actions'
import * as styles from './styles'

import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

class CustomerMasterCapture extends Component {
    componentDidMount() {
        this.props.actions.setValidationState('customerMasterCapture')
        if (this.props.mode === "confirm") {
            this.props.actions.setupWorkflowTaskInput(this.props.state, [], 'customer_master', 'tsk_customer_master_capture_details')
        }
        else {
            this.props.actions.resetForm()
        }
    }

    getDetails() {
        return <div> <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'customername'}
                        label={'Enter customer name'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.customername}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('customername', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'tradingname'}
                        label={'Enter trading name'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.tradingname}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('tradingname', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'accountnumber'}
                        label={'Enter account number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.accountnumber}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('accountnumber', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'vatnumber'}
                        label={'Enter VAT number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.vatnumber}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('vatnumber', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'costcentre'}
                        label={'Enter cost centre'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.costcentre}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('costcentre', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <Checkbox
                        id={'chk_bidvestgroup'}
                        key={'chk_bidvestgroup'}
                        name={'chk_bidvestgroup'}
                        field={'bidvestgroup'}
                        checked={this.props.state.bidvestgroup}
                        labelPlacement={'end'}
                        label={window.t.en('Group')}
                        onCheck={(checked) => {
                            this.props.actions.setInputValue('bidvestgroup', checked)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <Checkbox
                        id={'chk_non_reporting'}
                        key={'chk_non_reporting'}
                        name={'chk_non_reporting'}
                        field={'non_reporting'}
                        checked={this.props.state.non_reporting}
                        labelPlacement={'end'}
                        label={window.t.en('Non Reporting')}
                        onCheck={(checked) => {
                            this.props.actions.setInputValue('non_reporting', checked)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'organisationtype'}
                        title={'Select Customer Type'}
                        data={this.props.state.organisationtype_data}
                        value={this.props.state.organisationtype}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        displayfields={['organisationtype']}
                        fieldtext={'organisationtype'}
                        fieldvalue={'organisationtype_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('organisationtype', args.text)
                            this.props.actions.setInputValue('organisationtype_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('organisationtype', 'organisationtype', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('organisationtype', 'organisationtype', search)
                                this.props.actions.setInputValue('organisationtype', search)
                            } else {
                                this.props.actions.getLookupData('organisationtype', 'organisationtype', '')
                                this.props.actions.setInputValue('organisationtype', '')
                                this.props.actions.setInputValue('organisationtype_id', '')
                            }
                        }}
                    />
                </Col>
                {this.props.state.organisationtype && this.props.state.organisationtype.toLowerCase() === 'individual' && (
                    <>
                        <Col xl={4}>
                            <AutoComplete
                                name={'turnoverindividualtype'}
                                title={'Select Individual Type'}
                                data={this.props.state.turnoverindividualtype_data}
                                value={this.props.state.turnoverindividualtype}
                                dataSourceConfig={{
                                    text: 'text',
                                    value: 'value'
                                }}
                                displayfields={['turnoverindividualtype']}
                                fieldtext={'turnoverindividualtype'}
                                fieldvalue={'turnoverindividualtype_id'}
                                onSelect={(args) => {
                                    //console.log('onSelect args', args);
                                    this.props.actions.setInputValue('turnoverindividualtype', args.text);
                                    this.props.actions.setInputValue('turnoverindividualtype_id', args.value);
                                }}
                                onClick={() => this.props.actions.getLookupData('turnoverindividualtype', 'turnoverindividualtype', '')}
                                onFilter={(search) => {
                                    if (search) {
                                        this.props.actions.getLookupData('turnoverindividualtype', 'turnoverindividualtype', search)
                                        this.props.actions.setInputValue('turnoverindividualtype', search)
                                    } else {
                                        this.props.actions.getLookupData('turnoverindividualtype', 'turnoverindividualtype', '')
                                        this.props.actions.setInputValue('turnoverindividualtype', '')
                                        this.props.actions.setInputValue('turnoverindividualtype_id', '')
                                    }
                                }}
                            />
                        </Col>
                    </>
                )}
                {this.props.state?.organisationtype?.toLowerCase() === 'business' && (
                    <>
                        <Col xl={4}>
                            <AutoComplete
                                name={'turnoverbusinesstype'}
                                title={'Select Business Type'}
                                data={this.props.state.turnoverbusinesstype_data}
                                value={this.props.state.turnoverbusinesstype}
                                dataSourceConfig={{
                                    text: 'text',
                                    value: 'value'
                                }}
                                displayfields={['turnoverbusinesstype']}
                                fieldtext={'turnoverbusinesstype'}
                                fieldvalue={'turnoverbusinesstype_id'}
                                onSelect={(args) => {
                                    //console.log('onSelect args', args);
                                    this.props.actions.setInputValue('turnoverbusinesstype', args.text);
                                    this.props.actions.setInputValue('turnoverbusinesstype_id', args.value);
                                }}
                                onClick={() => {
                                    //console.log('onClick args', this.props.state.turnoverbusinesstype_data);
                                    this.props.actions.getLookupData('turnoverbusinesstype', 'turnoverbusinesstype', '');
                                }}
                                onFilter={(search) => {
                                    if (search) {
                                        this.props.actions.getLookupData('turnoverbusinesstype', 'turnoverbusinesstype', search)
                                        this.props.actions.setInputValue('turnoverbusinesstype', search)
                                    } else {
                                        this.props.actions.getLookupData('turnoverbusinesstype', 'turnoverbusinesstype', '')
                                        this.props.actions.setInputValue('turnoverbusinesstype', '')
                                        this.props.actions.setInputValue('turnoverbusinesstype_id', '')
                                    }
                                }}
                            />
                        </Col>
                        <Col xl={4} style={{ paddingTop: '20px' }}>
                            <TextField
                                variant="outlined"
                                id={'businessturnover'}
                                label={'Enter Turn Over'}
                                fullWidth={true}
                                multiLine={false}
                                value={this.props.state.businessturnover}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('businessturnover', event.target.value);
                                }}
                            />
                        </Col>
                    </>
                )}
                <Col xl={4}>
                    <AutoComplete
                        name={'user'}
                        title={'Select Account Executive'}
                        data={this.props.state.user_data}
                        value={this.props.state.firstname}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        fieldtext={'firstname'}
                        fieldvalue={'user_id'}
                        displayfields={['firstname', 'lastname', 'location']}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('firstname', args.text)
                            this.props.actions.setInputValue('user_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('user', 'firstname', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('user', 'firstname', search)
                                this.props.actions.setInputValue('firstname', search)
                            } else {
                                this.props.actions.getLookupData('user', 'firstname', '')
                                this.props.actions.setInputValue('firstname', '')
                                this.props.actions.setInputValue('user_id', null)
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'region'}
                        title={'Select a Region'}
                        data={this.props.state.region_data}
                        value={this.props.state.region}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        fieldtext={'region'}
                        fieldvalue={'region_id'}
                        displayfields={['region']}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('region', args.text)
                            this.props.actions.setInputValue('region_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('region', 'region', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('region', 'region', search)
                                this.props.actions.setInputValue('region', search)
                            } else {
                                this.props.actions.getLookupData('region', 'region', '')
                                this.props.actions.setInputValue('region', '')
                                this.props.actions.setInputValue('region_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'branch'}
                        title={'Select a Branch'}
                        data={this.props.state.branch_data}
                        value={this.props.state.branch}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        displayfields={['branch']}
                        fieldtext={'branch'}
                        fieldvalue={'branch_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('branch', args.text)
                            this.props.actions.setInputValue('branch_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('branch', 'branch', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('branch', 'branch', search)
                                this.props.actions.setInputValue('branch', search)
                            } else {
                                this.props.actions.getLookupData('branch', 'branch', '')
                                this.props.actions.setInputValue('branch', '')
                                this.props.actions.setInputValue('branch_id', '')
                            }
                        }}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    }

    getFicDetails() {
        return <div> <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'registrationnumber'}
                        label={'Enter company registration number'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.registrationnumber}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('registrationnumber', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'sourceoffunds'}
                        title={'Select Source Funds'}
                        data={this.props.state.sourceoffunds_data}
                        value={this.props.state.sourceoffunds}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        displayfields={['sourceoffunds']}
                        fieldtext={'sourceoffunds'}
                        fieldvalue={'sourceoffunds_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('sourceoffunds', args.text)
                            this.props.actions.setInputValue('sourceoffunds_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('sourceoffunds', 'sourceoffunds', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('sourceoffunds', 'sourceoffunds', search)
                                this.props.actions.setInputValue('sourceoffunds', search)
                            } else {
                                this.props.actions.getLookupData('sourceoffunds', 'sourceoffunds', '')
                                this.props.actions.setInputValue('sourceoffunds', '')
                                this.props.actions.setInputValue('sourceoffunds_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'sourceofwealth'}
                        title={'Select Source Wealth'}
                        data={this.props.state.sourceofwealth_data}
                        value={this.props.state.sourceofwealth}
                        displayfields={['sourceofwealth']}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        fieldtext={'sourceofwealth'}
                        fieldvalue={'sourceofwealth_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('sourceofwealth', args.text)
                            this.props.actions.setInputValue('sourceofwealth_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('sourceofwealth', 'sourceofwealth', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('sourceofwealth', 'sourceofwealth', search)
                                this.props.actions.setInputValue('sourceofwealth', search)
                            } else {
                                this.props.actions.getLookupData('sourceofwealth', 'sourceofwealth', '')
                                this.props.actions.setInputValue('sourceofwealth', '')
                                this.props.actions.setInputValue('sourceofwealth_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <AutoComplete
                        name={'country'}
                        title={'Select Trading Country'}
                        data={this.props.state.country_data}
                        value={this.props.state.country}
                        displayfields={['country']}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        fieldtext={'country'}
                        fieldvalue={'country_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('country', args.text)
                            this.props.actions.setInputValue('country_id', args.value)
                        }}
                        onClick={() => this.props.actions.getLookupData('country', 'country', '')}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('country', 'country', search)
                                this.props.actions.setInputValue('country', search)
                                this.props.actions.setInputValue('country_id', '')
                            } else {
                                this.props.actions.getLookupData('country', 'country', '')
                                this.props.actions.setInputValue('country', '')
                                this.props.actions.setInputValue('country_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'relationship_period'}
                        label={'Enter Relationship Period'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.relationship_period}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('relationship_period', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <Checkbox
                        id={'chk_politically_exposed_person'}
                        key={'chk_politically_exposed_person'}
                        name={'chk_politically_exposed_person'}
                        field={'politically_exposed_person'}
                        checked={this.props.state.politically_exposed_person}
                        labelPlacement={'end'}
                        label={window.t.en('Politically exposed person')}
                        onCheck={(checked) => {
                            this.props.actions.setInputValue('politically_exposed_person', checked)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <Checkbox
                        id={'chk_domestic_prominent_influential_person'}
                        key={'chk_domestic_prominent_influential_person'}
                        name={'chk_domestic_prominent_influential_person'}
                        field={'domestic_prominent_influential_person'}
                        checked={this.props.state.domestic_prominent_influential_person}
                        labelPlacement={'end'}
                        label={window.t.en('Domestic Prominent Influential Persons')}
                        onCheck={(checked) => {
                            this.props.actions.setInputValue('domestic_prominent_influential_person', checked)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <Checkbox
                        id={'chk_foreign_prominent_public_official'}
                        key={'chk_foreign_prominent_public_official'}
                        name={'chk_foreign_prominent_public_official'}
                        field={'foreign_prominent_public_official'}
                        checked={this.props.state.foreign_prominent_public_official}
                        labelPlacement={'end'}
                        label={window.t.en('Foreign Prominent Public Official')}
                        onCheck={(checked) => {
                            this.props.actions.setInputValue('foreign_prominent_public_official', checked)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <Checkbox
                        id={'chk_enhanced_due_diligence'}
                        key={'chk_enhanced_due_diligence'}
                        name={'chk_enhanced_due_diligence'}
                        field={'enhanced_due_diligence'}
                        checked={this.props.state.enhanced_due_diligence}
                        labelPlacement={'end'}
                        label={window.t.en('Enhanced due diligence')}
                        onCheck={(checked) => {
                            this.props.actions.setInputValue('enhanced_due_diligence', checked)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField
                        variant="outlined"
                        id={'related_parties'}
                        label={'Enter Related parties for the account'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.related_parties}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('related_parties', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'ultimate_beneficial_owner'}
                        label={'Enter Ultimate Benificial Owner'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.ultimate_beneficial_owner}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('ultimate_beneficial_owner', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4}>
                    <TextField variant="outlined"
                        id={'standard_industrial_classification'}
                        label={'Enter Standard Industrial Classification'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.standard_industrial_classification}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('standard_industrial_classification', event.target.value)
                        }}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    }

    getLimits() {
        return <div> <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4} style={{ paddingTop: '20px' }}>
                    <TextField variant="outlined"
                        id={'creditlimit'}
                        label={'Enter credit limit'}
                        fullWidth={true}
                        multiLine={false}
                        value={this.props.state.creditlimit}
                        onChange={(event, value) => {
                            this.props.actions.setInputValue('creditlimit', event.target.value)
                        }}
                    />
                </Col>
                <Col xl={4} style={{ paddingTop: '20px' }}>
                    <AutoComplete
                        name={'facilityrenewalperiod'}
                        title={'Select Facility Review Period'}
                        displayfields={['facilityrenewalperiod']}
                        data={this.props.state.facilityrenewalperiod_data}
                        value={this.props.state.facilityrenewalperiod}
                        dataSourceConfig={{
                            text: 'text',
                            value: 'value'
                        }}
                        fieldtext={'facilityrenewalperiod'}
                        fieldvalue={'facilityrenewalperiod_id'}
                        onSelect={(args) => {
                            //console.log('onSelect args',args)
                            this.props.actions.setInputValue('facilityrenewalperiod', args.text)
                            this.props.actions.setInputValue('facilityrenewalperiod_id', args.value)
                        }}
                        onClick={() => {
                            //console.log('onClick args',this.props.state.turnoverbusinesstype_data)
                            this.props.actions.getLookupData('facilityrenewalperiod', 'facilityrenewalperiod', '')
                        }}
                        onFilter={(search) => {
                            if (search) {
                                this.props.actions.getLookupData('facilityrenewalperiod', 'facilityrenewalperiod', search)
                                this.props.actions.setInputValue('facilityrenewalperiod', search)
                            } else {
                                this.props.actions.getLookupData('facilityrenewalperiod', 'facilityrenewalperiod', '')
                                this.props.actions.setInputValue('facilityrenewalperiod', '')
                                this.props.actions.setInputValue('facilityrenewalperiod_id', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4} >
                    <DateControl
                        id={'facilitylastrevieweddate'}
                        key={'facilitylastrevieweddate'}
                        label={'Facility Last Reviewed Date'}
                        value={this.props.state.facilitylastreviewdate}
                        onSelect={(args) => {
                            this.props.actions.setInputValue('facilitylastreviewdate', args)
                        }}
                        type={'date'}
                    />
                </Col>
                <Col xl={4}>
                    <DateControl
                        id={'facilitynextrevieweddate'}
                        key={'facilitynextrevieweddate'}
                        label={'Facility Next Review Date'}
                        value={this.props.state.facilitynextreviewdate}
                        onSelect={(args) => {
                            this.props.actions.setInputValue('facilitynextreviewdate', args)
                        }}
                        type={'date'}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    }

    getAddress() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} key={'col_add'}>
                        <Button
                            onClick={(event) => {
                                this.props.actions.addAddress()
                            }}
                            className="btn-pill-off">
                            <span className="btn-wrapper--label">Add Item</span>
                        </Button>
                    </Col>
                    <Col xl={10} ></Col>
                    <Col xl={12}>
                        {
                            this.props.state.address.length
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{ zIndex: 1 }}>
                                        {
                                            this.props.state.address.map((x, inx) => {

                                                return <tr>
                                                    <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                <Row>
                                                                    <Col xl={2} style={{ paddingTop: '20px' }}>
                                                                        <AutoComplete
                                                                            name={'field_' + inx}
                                                                            title={'Select Type'}
                                                                            data={this.props.state.addresstypes}
                                                                            value={x.addresstype}
                                                                            displayfields={['text']}
                                                                            fieldtext={'text'}
                                                                            fieldvalue={'value'}
                                                                            dataSourceConfig={{
                                                                                text: 'text',
                                                                                value: 'value'
                                                                            }}
                                                                            onSelect={(args) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'addresstype', value: args.text })
                                                                            }}
                                                                            onClick={() => console.log('')}
                                                                            onFilter={(search) => {
                                                                                if (search) { 
                                                                                    this.props.actions.setAddressInputValue({ inx: inx, prop: 'addresstype', value: search })
                                                                                } else {
                                                                                    this.props.actions.setAddressInputValue({ inx: inx, prop: 'addresstype', value: '' })
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={3}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'line1' + inx}
                                                                            label={'Enter Line1'}
                                                                            value={x.line1}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'line1', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'line2' + inx}
                                                                            label={'Enter Line2'}
                                                                            value={x.line2}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'line2', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'line3' + inx}
                                                                            label={'Enter Line3'}
                                                                            value={x.line3}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'line3', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'line4' + inx}
                                                                            label={'Enter Postal Code'}
                                                                            value={x.line4}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setAddressInputValue({ inx: inx, prop: 'line4', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={1}>
                                                                        <IconButton key={'command_address_delete_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                            onClick={
                                                                                () => {
                                                                                    this.props.actions.delAddress(inx)
                                                                                }
                                                                            }>
                                                                            <DeleteForeverIcon />
                                                                        </IconButton>
                                                                    </Col>
                                                                </Row>

                                                            }
                                                        </Container>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getContact() {
        return <div>

            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} key={'col_add'}>
                        <Button
                            onClick={(event) => {
                                this.props.actions.addContact()
                            }}
                            className="btn-pill-off">
                            <span className="btn-wrapper--label">Add Item</span>
                        </Button>
                    </Col>
                    <Col xl={10} ></Col>
                    <Col xl={12}>
                        {
                            this.props.state.contacts.length
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{ zIndex: 1 }}>
                                        {
                                            this.props.state.contacts.map((x, inx) => {

                                                return <tr>
                                                    <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                <Row>
                                                                    <Col xl={2} style={{ paddingTop: '15px' }}>
                                                                        <AutoComplete
                                                                            name={'field_' + inx}
                                                                            title={'Select Contact Type'}
                                                                            data={this.props.state.contacttypes}
                                                                            value={x.contacttype}
                                                                            displayfields={['text']}
                                                                            fieldtext={'text'}
                                                                            fieldvalue={'value'}
                                                                            dataSourceConfig={{
                                                                                text: 'text',
                                                                                value: 'value'
                                                                            }}
                                                                            onSelect={(args) => {
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'contacttype', value: args.text })
                                                                            }}
                                                                            onClick={() => {}}
                                                                            onFilter={(search) => {
                                                                                if (search) {
                                                                                    this.props.actions.setContactInputValue({ inx: inx, prop: 'contacttype', value: search })
                                                                                } else {
                                                                                    this.props.actions.setContactInputValue({ inx: inx, prop: 'contacttype', value: '' })
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'firstname' + inx}
                                                                            label={'Enter Firstname'}
                                                                            value={x.firstname}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'firstname', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'lastname' + inx}
                                                                            label={'Enter Lastname'}
                                                                            value={x.lastname}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'lastname', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={3}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'email' + inx}
                                                                            label={'Enter Email Address'}
                                                                            value={x.email}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'email', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            margin="normal"
                                                                            fullWidth={true}
                                                                            id={'telephone' + inx}
                                                                            label={'Enter Contact Number'}
                                                                            value={x.telephone}
                                                                            style={{ marginTop: 0 }}
                                                                            onChange={(event, value) => {
                                                                                this.props.actions.setContactInputValue({ inx: inx, prop: 'telephone', value: event.target.value })
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={1}>
                                                                        <div style={{ marginTop: '20px' }}>
                                                                            <IconButton key={'command_contact_delete_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                onClick={
                                                                                    () => {
                                                                                        this.props.actions.delContact(inx)
                                                                                    }
                                                                                }>
                                                                                <DeleteForeverIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            }
                                                        </Container>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    }
    getDocuments() {
        return <div>
            <Upload
                id={'customer'}
                prefix={'Customer'}
                header={false}
            /></div>
    }

    getCapture() {
        return <div>
            <Card
                title={"Customer Details"}
                subtitle={window.t.en('Enter new customer details')}
                content={this.getDetails()}
            />
            <Card
                title={"Customer Fic Details"}
                subtitle={window.t.en('Enter new customer fic details')}
                content={this.getFicDetails()}
            />
            <Card
                title={"Customer Facility Detail"}
                subtitle={window.t.en('Enter Customer Facility Detail')}
                content={this.getLimits()}
            />
            <Card
                title={"Customer Address"}
                subtitle={window.t.en('Enter customer address details')}
                content={this.getAddress()}
            />
            <Card
                title={"Customer Contacts"}
                subtitle={window.t.en('Enter customer contacts details')}
                content={this.getContact()}
            />
            <Card
                title={"Customer FICA"}
                subtitle={window.t.en('Upload customer supporting documents')}
                content={this.getDocuments()}
            />
        </div>
    }


    getConfirm() {
        //console.log('getConfirm', this.props.state.documents)
        return <div>
            <Card
                title={"Customer Details"}
                subtitle={window.t.en('Enter new customer details')}
                content={<Fields
                    fields={[
                        { size: 4, label: 'Customer Name:', value: this.props.state.customername },
                        { size: 4, label: 'Trading Name:', value: this.props.state.tradingname },
                        { size: 4, label: 'Account Number:', value: this.props.state.accountnumber },
                        { size: 4, label: 'Account Executive:', value: this.props.state.firstname },
                        { size: 4, label: 'Branch:', value: this.props.state.branch },
                        { size: 4, label: 'Region:', value: this.props.state.region },
                        { size: 4, label: 'VAT Number:', value: this.props.state.vatnumber },
                        { size: 4, label: 'Customer Type:', value: this.props.state.organisationtype },
                        { size: 4, label: 'Business Type:', value: this.props.state.turnoverbusinesstype },
                        { size: 4, label: 'Individual Type:', value: this.props.state.turnoverindividualtype },
                        { size: 4, label: 'Group:', value: this.props.state.bidvestgroup ? 'Yes' : 'No' },
                        { size: 4, label: 'Non Reporting:', value: this.props.state.non_reporting ? 'Yes' : 'No' }


                    ]}
                />}
            />
            <Card
                title={"Customer Fic Details"}
                subtitle={window.t.en('Enter new customer fic details')}
                content={<Fields
                    fields={[
                        { size: 4, label: 'Company Registration:', value: this.props.state.registrationnumber },
                        { size: 4, label: 'Source of Funds:', value: this.props.state.sourceoffunds },
                        { size: 4, label: 'Source of Wealth:', value: this.props.state.sourceofwealth },
                        { size: 4, label: 'Trading Country:', value: this.props.state.country },
                        { size: 4, label: 'Relationship Period:', value: this.props.state.relationship_period },
                        { size: 4, label: 'Politically Exposed Person:', value: this.props.state.politically_exposed_person ? 'Yes' : 'No' },
                        { size: 4, label: 'Domestic Prominent Influential Person:', value: this.props.state.domestic_prominent_influential_person ? 'Yes' : 'No' },
                        { size: 4, label: 'Foreign Prominent Public Person:', value: this.props.state.foreign_prominent_public_official ? 'Yes' : 'No' },
                        { size: 4, label: 'Enhanced Due Dilligence:', value: this.props.state.enhanced_due_diligence ? 'Yes' : 'No' },
                        { size: 4, label: 'Ultimate Benificial Owner:', value: this.props.state.ultimate_beneficial_owner },
                        { size: 4, label: 'Standard Industrial Classification:', value: this.props.state.standard_industrial_classification },

                    ]}
                />}
            />
            <Card
                title={"Customer Facility Detail"}
                subtitle={window.t.en('Customer Facility Detail')}
                content={<Fields
                    fields={[
                        { size: 4, label: 'Monthly Limit:', value: this.props.state.creditlimit },
                        { size: 4, label: 'Facility Renewal Period:', value: this.props.state.facilityrenewalperiod },
                        { size: 4, label: 'Facility Last Review Date:', value: this.props.state.facilitylastreviewdate },
                        { size: 4, label: 'Facility Next Review Date:', value: this.props.state.facilitynextreviewdate }
                    ]}
                />}
            />
            <Card
                title={"Customer Address"}
                subtitle={window.t.en('Customer address details')}
                content={<Fields data={this.props.state.address} columns={['Address Type', 'Address Line1', 'Address Line2', 'Address Line3', 'Address Line4']} />}
            />
            <Card
                title={"Customer Contacts"}
                subtitle={window.t.en('Customer contacts details')}
                content={<Fields data={this.props.state.contacts} columns={['Contact Type', 'Firstname', 'Lastname', 'Email Address', 'Contact Number']} />}
            />
            <Card
                title={"Customer Fica"}
                subtitle={window.t.en('Uploaded customer supporting documents')}
                content={<PdfViewer
                    key={'customer_fica'}
                    name={'customer_fica'}
                    documents={this.props.state.documents.documents}
                    edit={false}
                    multiple={false}
                    workflow_queue_data={null}
                />}
            />
            {/* <Card
            title={"Customer Contact"}
            subtitle={window.t.en('Enter customer contact details')}
            content={this.getContact()}
        /> 
        <Card
            title={"Customer Fica"}
            subtitle={window.t.en('Upload customer supporting documents')}
            content={this.getDocuments()}
        />  */}
        </div>
    }

    getLayout() {
        return <div>{
            this.props.mode === 'confirm'
                ? this.getConfirm()
                : this.getCapture()
        }
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.customerMasterCapture,
            documents: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;
    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMasterCapture)