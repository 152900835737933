import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { List, ListItem ,Link } from '@mui/material';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

//Icons
import { Dashboard, ImportContacts } from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import Divider from "@mui/material/Divider";

import Icon from "../Icon/component";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { grey } from "@mui/material/colors";
import {ReactComponent as BidvestLogo} from '../../assets/img/bidvest-white-logo.svg'

import * as actions from "./actions";
import * as BIActions from "../BusinessIntelligence/actions";
import * as oqActions from "../Oqlis/actions";
import * as redirectActions from "../Redirect/actions";
import * as cognitoActions from "../Cognito/actions";
import * as jsReprtActions from "../JSReport/actions";
import * as releaseNoteActions from '../ReleaseNote/actions'
import * as styles from "./styles";
import Menus from "./menu";

import * as config from '../../config'

import {
    Popover
} from '@mui/material';


//import NestedMenuItem from "material-ui-nested-menu-item";

import * as masterComponentViewActions from "../../views/MasterComponentView/actions";
import * as workflowQueueTasksActions from "../WorkflowQueueTasks/actions";
import * as readComponentActions from "../ReadComponent/actions";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

//import WhiteLablesListItem from "../../themes/customCSS";
const iconButtonElement = (
    <IconButton touch={true} tooltip="more" tooltipPosition="bottom-left">
        <MoreVertIcon color={grey[400]} />
    </IconButton>
);

const rightIconMenu = (
    <Menu iconButtonElement={iconButtonElement}>
        <MenuItem>Delete Notification</MenuItem>
    </Menu>
);

class NavigationMenu extends Component {
    constructor(props){
        super(props)
        this.state ={
            menuPosition: null,
            reportPosition: null,
            dashboardPosition: null,
        }
    }
    componentDidMount() {
        this.props.actions.getNavigationMenu();
        this.props.actions.getStaticMenu();
        //this.props.actions.initUserNotifications(); //todo
    }
     //[menuPosition, this.setState] = useState<any>(null);

     handleRightClick = (event = React.MouseEvent) => {



      if (this.state.menuPosition) {
        return;
      }
      event.preventDefault();

      let pos = {
        top: event.pageY,
        left: event.pageX
      }
      this.setState({menuPosition: pos});
    };

    handleReportRightClick = (event = React.MouseEvent) => {



        if (this.state.reportPosition) {
          return;
        }
        event.preventDefault();

        let pos = {
          top: event.pageY,
          left: event.pageX
        }
        this.setState({reportPosition: pos});
      };

      handleDashboardRightClick = (event = React.MouseEvent) => {



        if (this.state.dashboardPosition) {
          return;
        }
        event.preventDefault();

        let pos = {
          top: event.pageY,
          left: event.pageX
        }
        this.setState({dashboardPosition: pos});
      };

     handleItemClick = (event) => {
      this.setState(null);
    };

    // getAllMenus(){
    //     return <div onMouseOver={(e) => this.handleRightClick(e)} className={"custom-menu"}>
    //                     {
    //                         this.props.state.data.map((x) => {
    //                             return (
    //                                 <div>
    //                                     <NestedMenuItem
    //                                         id={'navigation_category_' + x.name}
    //                                         key={'navigation_category_' + x.name}
    //                                         style={{overflow: 'auto'}}
    //                                         rightIcon={false}
    //                                         label={
    //                                             <ListItem
    //                                                 button
    //                                                 className="bg-asteroid"
    //                                                 key={'navigation_menu_header_' + x.component_category_id}
    //                                             >
    //                                                 <ListItemIcon>
    //                                                     <Icon
    //                                                         iclass={x.icon.unicode}
    //                                                         iname={x.icon.name}
    //                                                     />
    //                                                 </ListItemIcon>
    //                                                 <ListItemText>{window.t.en(x.title)}</ListItemText>
    //                                                 <ArrowRightIcon fontSize="small" color="secondary" />
    //                                             </ListItem>
    //                                         }
    //                                         parentMenuOpen={!!this.state.menuPosition}
    //                                         onClick={(e) => this.handleItemClick(e)}

    //                                     >
    //                                         {this.getSections2(x.component_category_id, x.sections)}
    //                                         {this.getNoneSections1(x.sections)}


    //                                     </NestedMenuItem>
    //                                 </div>
    //                             )
    //                         })

    //                     }

    //             </div>
    // }

    // getNoneSections1(sections) {
    //     return sections.filter((x) => !x.section.title)
    //         .map((x) => {
    //             return x.section.components.map((c) => {
    //                 return <div className="cust-sub-menu">
    //                         <NestedMenuItem button
    //                             id={'navigation_menus_' + c.name}
    //                             key={'navigation_menus_' + c.name}
    //                             rightIcon={false}
    //                             className="bg-asteroid"
    //                             label={
    //                                 <MenuItem button
    //                                     className="bg-asteroid"
    //                                     >
    //                                     <ListItemIcon>
    //                                         <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
    //                                     </ListItemIcon>
    //                                     <ListItemText>{c.title}</ListItemText>
    //                                 </MenuItem>
    //                             }
    //                             parentMenuOpen={!!this.state.menuPosition}
    //                             onClick={(event, value) => {
    //                                 event.preventDefault();
    //                                 this.props.actions.setOqlisDashboard(null)
    //                                 this.props.actions.setOqlisReport(null)
    //                                 this.props.actions.route("/search/" + c.name);
    //                                 this.handleItemClick(event)

    //                             }}
    //                             >
    //                                        {null}
    //                         </NestedMenuItem>
    //                         </div>
    //             })



    //                 // <Container className="bg-asteroid" disableGutters={true}>
    //                 //     <Divider />
    //                 //     {
    //                 //         x.section.components.map((c) => {
    //                 //             return <MenuItem button
    //                 //                     className="bg-asteroid"
    //                 //                     onClick={(event, value) => {
    //                 //                         event.preventDefault();
    //                 //                         this.props.actions.setOqlisDashboard(null)
    //                 //                         this.props.actions.setOqlisReport(null)
    //                 //                         this.props.actions.route("/search/" + c.name);
    //                 //                         this.handleItemClick(event)
    //                 //                     }}>
    //                 //                     <ListItemIcon>
    //                 //                         <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
    //                 //                     </ListItemIcon>
    //                 //                     <ListItemText>{window.t.en(c.title)}</ListItemText>
    //                 //                 </MenuItem>

    //                 //         })
    //                 //     }

    //                 // </Container>
    //         })
    // }


    // getSections2(component_category_id, sections) {

    //     // let component_category_id = this.component_category_id
    //     // let components = this.components

    //     // <NestedMenuItem
    //     //     label={"Sub-Button 3"}
    //     //     parentMenuOpen={!!this.state.menuPosition}
    //     //     onClick={(e) => this.handleItemClick(e)}
    //     // >
    //     //     <MenuItem onClick={(e) => this.handleItemClick(e)}>Sub-Sub-Button 1</MenuItem>
    //     //     <MenuItem onClick={(e) => this.handleItemClick(e)}>Sub-Sub-Button 2</MenuItem>
    //     // </NestedMenuItem>
    //     return sections
    //         .filter((x) => x.section.title)
    //         .map((x) => {
    //             return (
    //                     <div className="cust-sub-menu">
    //                         <NestedMenuItem button
    //                             id={'navigation_category_' + x.section.name}
    //                             key={'navigation_category_' + x.section.name}
    //                             rightIcon={false}
    //                             className="bg-asteroid"
    //                             label={
    //                                 <MenuItem button
    //                                     className="bg-asteroid"
    //                                     >
    //                                     <ListItemIcon>
    //                                         <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
    //                                     </ListItemIcon>
    //                                     <ListItemText>{window.t.en(x.section.title)}</ListItemText>
    //                                     <ArrowRightIcon fontSize="small" color="secondary" />
    //                                 </MenuItem>
    //                             }
    //                             parentMenuOpen={!!this.state.menuPosition}
    //                             onMouseOver={(event)=>{
    //                                 this.props.actions.setOqlisReport(null)
    //                                 this.component_category_id = component_category_id
    //                                 this.components = x.section.components
    //                             }}
    //                             onClick={(event, value) => {
    //                                 event.preventDefault();
    //                                 this.props.actions.setOqlisReport(null)
    //                                 this.handleItemClick(event)

    //                             }}
    //                             >

    //                                { this.getComponents()}

    //                         </NestedMenuItem>
    //                         </div>
    //             )
    //         })
    // }

    getComponents() {
        let component_category_id = this.component_category_id
        let components = this.components
        return components && components.length
            ? components.map((x) => {
                return (
                    <ListItem
                        button
                        id={'navigation_category_' + x.name}
                        onClick={(event, value) => {
                            event.preventDefault();
                            this.props.actions.setOqlisDashboard(null)
                            this.props.actions.toggleMenus(false, null)
                            this.props.actions.route("/search/" + x.name);
                        }}
                        className="bg-asteroid"
                        key={
                            "navigation_menu_component_" +
                            component_category_id +
                            "_" +
                            x.component_id
                        }
                    >
                        <ListItemText primary={window.t.en(x.title)} />
                    </ListItem>
                );
            })
            : ''
    }

    getMegaMenus() {
        return <Popover
            id={'foo'}
            open={this.props.state.megamenu}
            anchorEl={this.props.state.anchorEl}
            onClose={(event, value) => {
                event.preventDefault();
                this.props.actions.toggleMenus(false, null)
                this.props.actions.toggleMenuState('Close all menus', event.clientY, null)
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            anchorPosition={{
                left: 500,
                top: 0
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}>

            <div className="">
                <Container className="bg-asteroid" disableGutters={true}>
                    <div />
                    <List component="div"  >
                        {/* <Typography
                  component="div"
                  className="pb-2 text-capitalize text-first font-size-lg">
                  <span>Dashboards</span>
                </Typography> */}
                        {this.getComponents(this.component_category_id, this.components)}
                    </List>

                </Container>
            </div>
        </Popover>

    }

    getNoneSections(sections) {

        return sections.filter((x) => !x.section.title)
            .map((x) => {
                return (
                    <Container className="bg-asteroid" disableGutters={true}>
                        <Divider />
                        {
                            x.section.components.map((c) => {
                                return <List id={c.title}>
                                    <ListItem button
                                        className="bg-asteroid"
                                        onClick={(event, value) => {
                                            event.preventDefault();
                                            this.props.actions.setOqlisDashboard(null)
                                            this.props.actions.setOqlisReport(null)
                                            this.props.actions.route("/search/" + c.name);
                                            this.props.actions.toggleMenuState('Close all menus', event.clientY, null)
                                        }}>
                                        <ListItemIcon>
                                            <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText>{window.t.en(c.title)}</ListItemText>
                                    </ListItem>
                                </List>
                            })
                        }

                    </Container>
                )
            })
    }


    getSections(component_category_id, sections) {
        return sections
            .filter((x) => x.section.title)
            .map((x) => {
                return (
                    <Container className="bg-asteroid" disableGutters={true}>
                        <Divider />
                        <List id={x.section.title}>
                            <MenuItem button
                                className="bg-asteroid"
                                onClick={(event, value) => {
                                    event.preventDefault();
                                    this.props.actions.setOqlisReport(null)
                                    this.component_category_id = component_category_id
                                    this.components = x.section.components
                                    this.props.actions.toggleMenus(true, event.currentTarget)
                                }}


                                >
                                <ListItemIcon>
                                    <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
                                </ListItemIcon>
                                <ListItemText>{window.t.en(x.section.title)}</ListItemText>
                                <ArrowRightIcon fontSize="small" color="secondary" />
                            </MenuItem>
                            {/* {this.getComponents(component_category_id, x.section.components)} */}
                        </List>
                    </Container>
                )
            })
    }

    getMenu() {
        return !this.props.state.data.length
            ? (
                <MenuItem className="bg-asteroid">
                    <ListItemText primary={"Loading..."} />
                    <ListItemIcon>
                        <CircularProgress color="secondary" />
                    </ListItemIcon>
                </MenuItem>
            )
            : (
                this.props.state.data.map((x) => {
                    return (
                        <div>
                            <MenuItem
                                button
                                onClick={(event, value) => {
                                    event.preventDefault();
                                    this.props.actions.toggleMenuState(x.name, event.clientY, event.currentTarget)
                                }}
                                onMouseEnter={(event) => {
                                    event.preventDefault();
                                    this.props.actions.toggleMenuState(x.name, event.clientY, event.currentTarget)
                                }}
                                className="bg-asteroid"
                                key={'navigation_menu_header_' + x.component_category_id}
                            >
                                <ListItemIcon>
                                    <Icon
                                        iclass={x.icon.unicode}
                                        iname={x.icon.name}
                                    />
                                </ListItemIcon>
                                <ListItemText>{window.t.en(x.title)}</ListItemText>
                                <ArrowRightIcon fontSize="small" color="secondary" />
                            </MenuItem>
                            <Popover
                                id={'pop_menu_el_' + x.name} transition
                                open={x.open}
                                anchorEl={x.anchorEl}
                                ///style={{width: '450px !important', minWidth: '450px !important', zIndex: 100}}
                                style={{zIndex: 100}}
                                onClose={(event, value) => {
                                    event.preventDefault();
                                    this.props.actions.toggleMenuState('Close all menus', event.clientY, null)
                                    ///this.props.actions.toggleMenus(false, null)

                                }}

                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                anchorPosition={{
                                    left: 500,
                                    top: 0
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}>

                                <div className="">
                                    {this.getSections(x.component_category_id, x.sections)}
                                    {this.getNoneSections(x.sections)}
                                </div>
                            </Popover>

                            {/* <Collapse
                                component="div"
                                in={x.open}
                                unmountOnExit
                                timeout={0}
                            >
                                {this.getSections(x.component_category_id, x.sections)}
                                {this.getNoneSections(x.sections)}
                            </Collapse> */}

                        </div>
                    );
                })
            );
    }

    getStaticMenu() {
        //console.log('getStaticMenu', this.props.state.staticMenu)
        return !this.props.state.staticMenu.length
            ? (
                ""
            )
            :   (
                <div>
                    <List component="div" aria-labelledby="nested-list-subheader"> {this.getDashboardMenu()}</List>
                    <List component="div" aria-labelledby="nested-list-subheader">   {this.getReportMenu()}</List>
                    {this.props.state.staticMenu.map((item) => {
                    return (
                        (this.props.state.accounts.teams.filter(x => x.name = 'system_administrators').length && item.visible == true)
                        ?   <MenuItem button className="bg-asteroid"
                                id={'navigation_category_' + item.name}
                                onClick={(event, value) => {
                                    event.preventDefault();
                                    switch (item.route) {
                                        case '/tasks':
                                            this.props.actions.setOqlisDashboard(null)
                                            this.props.actions.setOqlisReport(null)
                                            this.props.actions.route('/tasks')
                                            break;
                                        case '/releasenote':
                                            this.props.actions.clearReleaseNote()
                                            this.props.actions.route(item.route)
                                            break;
                                        case '/create_report':
                                             window.open(config.oq.address)
                                            this.props.actions.route(item.route)
                                            break;
                                        default:
                                            this.props.actions.route(item.route)
                                            break;
                                    }
                                }}>
                                    <ListItemIcon>
                                        <Icon
                                            iclass={item.icon.unicode}
                                            iname={item.icon.name}
                                        />
                                    </ListItemIcon>
                                    <ListItemText>{window.t.en(item.title)}</ListItemText>
                                </MenuItem>
                        :   <></>
                    );
                    })}
                </div>
            )
    }

    getRelatedMenu() {
        return this.props.state.relationships.length ? (
            [
                <div
                    style={styles.back}
                    onClick={(event) => {
                        event.preventDefault();
                        this.props.actions.toggleRelationshipMenu();
                    }}
                >
                    <Icon iclass={"custom-icons"} iname={"left_alt_arrow"} />
                </div>,
                this.props.state.relationships.map((x) => {
                    return (
                        <MenuItem
                            className="bg-asteroid"
                            key={"readComponent_relatedList_" + x.name}
                            primaryText={window.t.en(x.title)}
                            onClick={(event) => {
                                this.props.actions.setReadComponentView("related", x.name);
                            }}
                            leftIcon={<Icon iclass={x.icon.unicode} iname={x.icon.name} />}
                        />
                    );
                }),
            ]
        ) : (
                <MenuItem
                    key={"navigation_menu_related_list_item_loading"}
                    primaryTogglesNestedList={true}
                    style={styles.li}
                    primary={"Updating..."}
                    leftIcon={<CircularProgress size={25} />}
                />
            );
    }


    // getDashboardMenu() {
    //     return <div onMouseOver={(e) => this.handleDashboardRightClick(e)} className={"custom-menu"}>
    //         <NestedMenuItem
    //             button
    //             rightIcon={false}
    //             label={
    //                 <ListItem button
    //                     className="bg-asteroid"
    //                 >
    //                     <ListItemIcon>
    //                         <Dashboard color="secondary" />
    //                     </ListItemIcon>
    //                     <ListItemText>{window.t.en('Dashboards')}</ListItemText>
    //                     <ArrowRightIcon fontSize="small" color="secondary" />
    //                 </ListItem>
    //             }
    //             parentMenuOpen={!!this.state.dashboardPosition}
    //             onMouseOver={(event)=>{
    //                 event.preventDefault();

    //                 this.props.actions.toggleMenuState('Close all menus', event.clientY, null)
    //                 this.props.actions.loadIntelligence('', '', '', '', 'dashboard')
    //                 this.props.actions.toggleDashboardMenuState('openDashboard', this.props.state.openDashboard)
    //             }}
    //             onClick={(event, value) => {
    //                 event.preventDefault();
    //                 this.handleItemClick(event)
    //                 this.props.actions.toggleMenuState('Close all menus', event.clientY, null)
    //                 this.props.actions.loadIntelligence('', '', '', '', 'dashboard')
    //                 this.props.actions.toggleDashboardMenuState('openDashboard', this.props.state.openDashboard)
    //             }}
    //         >
    //              {this.getCategories(this.props.state.bi)}

    //         </NestedMenuItem>

    //     </div>
    // }

    // getCategories(biData) {

    //     return biData.dashboard ?
    //         biData.dashboard
    //             .sort((a, b) => (a.category > b.category) ? 1 : -1)
    //             .filter((x) => x.category)
    //             .map((x) => {

    //                 return (
    //                     <Container className="cust-sub-menu" disableGutters={true}>
    //                         {/* <Divider /> */}
    //                         <NestedMenuItem
    //                          rightIcon={true}
    //                          className="bg-asteroid"
    //                           label={
    //                             <MenuItem button
    //                                 className="bg-asteroid"
    //                                 >
    //                                 <ListItemIcon>
    //                                     <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
    //                                 </ListItemIcon>
    //                                 <ListItemText>{window.t.en(x.category)}</ListItemText>
    //                                 <ArrowRightIcon fontSize="small" color="secondary" />
    //                             </MenuItem>
    //                         }
    //                         parentMenuOpen={!!this.state.dashboardPosition}

    //                         onMouseOver={(event)=>{
    //                             event.preventDefault();
    //                             this.dashboard_category_id = x.report_category_id
    //                             this.dashboard_components = x.report
    //                             this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'dashAnchorEl', 'dashMegamenu', event, x.report_category_id)

    //                         // onMouseOver={(event)=>{
    //                         //     event.preventDefault();
    //                         //     this.dashboard_category_id = x.report_category_id
    //                         //     this.dashboard_components = x.report
    //                         //     this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'dashAnchorEl', 'dashMegamenu', event, x.report_category_id)

    //                         }}
    //                         >
    //                             {x.subcategory.length > 1  ? this.getDashboardSubCategories(x.subcategory) : this.getDashboardMegaMenus(x.subcategory[0].subcategory_id)}
    //                         </NestedMenuItem>
    //                     </Container>
    //                 )
    //             }) : '...loading'
    // }

    // getDashboardSubCategories(subCategory) {
    //     return subCategory.length < 1 ?

    //                     <Container className="cust-sub-menu" disableGutters={true}>
    //                         {/* <Divider /> */}
    //                         <NestedMenuItem
    //                             rightIcon={true}
    //                             className="bg-asteroid"
    //                             label={
    //                                 <MenuItem
    //                                     className="bg-asteroid"
    //                                     >
    //                                     <ListItemIcon>
    //                                         <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
    //                                     </ListItemIcon>
    //                                     <ListItemText>{window.t.en(subCategory.sub_category)}</ListItemText>
    //                                     <ArrowRightIcon fontSize="small" color="secondary" />
    //                                 </MenuItem>
    //                             }
    //                             parentMenuOpen={!!this.state.reportPosition}
    //                             // onMouseOver={(event) => {
    //                             //     event.preventDefault();
    //                             //     this.report_category_id = x.report_category_id
    //                             //     this.report_components = x.report
    //                             //     this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //                             // }}

    //                             // onClick={(event, value) => {
    //                             //     event.preventDefault();
    //                             //     this.report_category_id = x.report_category_id
    //             //     this.report_components = x.report
    //             //     this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //             // }}
    //             >
    //                 {this.getReportMegaMenus(subCategory.subcategory_id)}

    //             </NestedMenuItem>
    //         </Container>
    //         : subCategory
    //         .sort((a, b) => (a.sub_category > b.sub_category) ? 1 : -1)
    //         .map((sub, ind) => (
    //             <Container className="cust-sub-menu" disableGutters={true}>
    //                 {/* <Divider /> */}
    //                 <NestedMenuItem
    //                     rightIcon={true}
    //                     className="bg-asteroid"
    //                     label={
    //                         <MenuItem
    //                             className="bg-asteroid"
    //                         >
    //                             <ListItemIcon>
    //                                 <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
    //                             </ListItemIcon>
    //                             <ListItemText>{window.t.en(sub.sub_category)}</ListItemText>
    //                             <ArrowRightIcon fontSize="small" color="secondary" />
    //                         </MenuItem>
    //                     }
    //                     parentMenuOpen={!!this.state.reportPosition}
    //                 // onMouseOver={(event) => {
    //                 //     event.preventDefault();
    //                 //     this.report_category_id = x.report_category_id
    //                 //     this.report_components = x.report
    //                 //     this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //                 // }}

    //                 // onClick={(event, value) => {
    //                 //     event.preventDefault();
    //                 //     this.report_category_id = x.report_category_id
    //                 //     this.report_components = x.report
    //                 //     this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //                 // }}
    //                 >
    //                     {this.getDashboardMegaMenus(sub.subcategory_id)}

    //                 </NestedMenuItem>
    //             </Container>
    //         ))
    // }

    getDashboardMegaMenus(subcategory_id) {
        return <div className="">
            <Container className="bg-asteroid" disableGutters={true}>
                <div />
                <List component="div"  >
                    {this.getDashboardComponents(subcategory_id)}
                </List>

            </Container>
        </div>


    }

    getDashboardComponents(subcategory_id) {
        let dashboard_category_id = this.dashboard_category_id
        let dashboard_components = this.dashboard_components


        const new_dashboard = this.props.state.bi.new_dashboard

        return new_dashboard && new_dashboard.length
            ? new_dashboard
                .filter(r => r.oq_dashboard_key && r.subcategory_id == subcategory_id)
                .sort((a, b) => (a.report_name > b.report_name) ? 1 : -1)
                .map((x) => {
                    return (
                        <MenuItem
                            button
                            onClick={(event, value) => {
                                event.preventDefault();
                                this.props.actions.setOqlisDashboard(x.oq_dashboard_key, x)
                                this.props.actions.getDashboardDataUrl(x.oq_dashboard_key, x)
                               this.props.actions.setOqlisReport(null)
                                this.props.actions.toggleDashboardMenus(false, null, 'dashAnchorEl', 'dashMegamenu')
                                this.props.actions.route("/bi");
                            }}
                             className="bg-asteroid"
                            key={

                                dashboard_category_id + x.report_name

                            }
                        >
                            <ListItemText primary={window.t.en(x.report_name)} />
                        </MenuItem>
                    );
                })
            : ''
    }

    // getReportMenu() {
    //     return this.props.state.bi.new_report ?  <div  onMouseOver={(e) => this.handleReportRightClick(e)} className={"custom-menu"}>
    //         <NestedMenuItem
    //             rightIcon={false}
    //             button
    //             label={
    //                 <ListItem button
    //                     className="bg-asteroid"
    //                 >
    //                     <ListItemIcon>
    //                         <ImportContacts color="secondary" />
    //                     </ListItemIcon>
    //                     <ListItemText>{window.t.en('Reports')}</ListItemText>
    //                     <ArrowRightIcon fontSize="small" color="secondary" />
    //                 </ListItem>
    //             }
    //             parentMenuOpen={!!this.state.reportPosition}
    //             onMouseOver={(event) => {

    //                 this.props.actions.toggleMenuState('Close all menus', event.clientY, null)
    //                 this.props.actions.loadIntelligence('', '', '', '', 'reports')
    //                 this.props.actions.toggleReportMenuState('openReport', this.props.state.openReport)
    //             }}
    //             onClick={(event, value) => {
    //                 this.handleItemClick(event)
    //                 this.props.actions.toggleReportMenuState('openReport', this.props.state.openReport)
    //             }}

    //         >
    //            {this.props.state.bi.new_report ?this.getReportCategories(this.props.state.bi):""}
    //         </NestedMenuItem>
    //     </div>:'WTF'
    // }

    // getReportCategories(biData) {

    //     return biData.reports ?
    //             biData.reports
    //             .sort((a, b) => (a.category > b.category) ? 1 : -1)
    //             .filter((x) => x.category)
    //             .map((x, ind) => {
    //                 return ( <div style={{overflow: 'auto'}}>
    //                     <Container className="cust-sub-menu" style={{zIndex:999999}} disableGutters={true}>
    //                         {/* <Divider /> */}
    //                         <NestedMenuItem
    //                             rightIcon={true}
    //                             className="bg-asteroid"
    //                             label={
    //                                 <MenuItem
    //                                     className="bg-asteroid"
    //                                     >
    //                                     <ListItemIcon>
    //                                         <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
    //                                     </ListItemIcon>
    //                                     <ListItemText>{window.t.en(x.category)}</ListItemText>
    //                                     <ArrowRightIcon fontSize="small" color="secondary" />
    //                                 </MenuItem>
    //                             }
    //                             style={{zIndex:999999}}
    //                             parentMenuOpen={!!this.state.reportPosition}
    //                             onMouseOver={(event) => {
    //                                 event.preventDefault();
    //                                 // this.report_category_id = x.report_category_id
    //                                 // this.report_components = x.report
    //                                 //this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //                             }}

    //                             onClick={(event, value) => {
    //                                 event.preventDefault();
    //                                 // this.report_category_id = x.report_category_id
    //                                 // this.report_components = x.reportcategory.report
    //                                 //this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //                             }}
    //                         >
    //                           {x.subcategory.length > 1 ? this.getReportSubCategories(x.subcategory) :this.getReportMegaMenus(x.subcategory[0].subcategory_id) }

    //                         </NestedMenuItem>
    //                     </Container>
    //                     </div>
    //                 )

    //             }) : '...loading'
    // }

    // getReportSubCategories(subCategory) {
    //     return subCategory.length < 1 ?

    //                     <Container className="cust-sub-menu" disableGutters={true}>
    //                         {/* <Divider /> */}
    //                         <NestedMenuItem
    //                             rightIcon={true}
    //                             className="bg-asteroid"
    //                             label={
    //                                 <MenuItem
    //                                     className="bg-asteroid"
    //                                     >
    //                                     <ListItemIcon>
    //                                         <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
    //                                     </ListItemIcon>
    //                                     <ListItemText>{window.t.en(subCategory.sub_category)}</ListItemText>
    //                                     <ArrowRightIcon fontSize="small" color="secondary" />
    //                                 </MenuItem>
    //                             }
    //                             parentMenuOpen={!!this.state.reportPosition}
    //                             // onMouseOver={(event) => {
    //                             //     event.preventDefault();
    //                             //     this.report_category_id = x.report_category_id
    //                             //     this.report_components = x.report
    //                             //     this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //                             // }}

    //                             // onClick={(event, value) => {
    //                             //     event.preventDefault();
    //                             //     this.report_category_id = x.report_category_id
    //             //     this.report_components = x.report
    //             //     this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //             // }}
    //             >
    //                 {this.getReportMegaMenus(subCategory.subcategory_id)}

    //             </NestedMenuItem>
    //         </Container>
    //         : subCategory
    //         .sort((a, b) => (a.sub_category > b.sub_category) ? 1 : -1)
    //         .map((sub, ind) => (
    //             <Container className="cust-sub-menu" disableGutters={true}>
    //                 {/* <Divider /> */}
    //                 <NestedMenuItem
    //                     rightIcon={true}
    //                     className="bg-asteroid"
    //                     label={
    //                         <MenuItem
    //                             className="bg-asteroid"
    //                         >
    //                             <ListItemIcon>
    //                                 <RadioButtonUncheckedIcon fontSize="small" color="secondary" />
    //                             </ListItemIcon>
    //                             <ListItemText>{window.t.en(sub.sub_category)}</ListItemText>
    //                             <ArrowRightIcon fontSize="small" color="secondary" />
    //                         </MenuItem>
    //                     }
    //                     parentMenuOpen={!!this.state.reportPosition}
    //                 // onMouseOver={(event) => {
    //                 //     event.preventDefault();
    //                 //     this.report_category_id = x.report_category_id
    //                 //     this.report_components = x.report
    //                 //     this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //                 // }}

    //                 // onClick={(event, value) => {
    //                 //     event.preventDefault();
    //                 //     this.report_category_id = x.report_category_id
    //                 //     this.report_components = x.report
    //                 //     this.props.actions.toggleDashboardMenus(true, event.currentTarget, 'reportAnchorEl', 'reportMegamenu', event, x.report_category_id)
    //                 // }}
    //                 >
    //                     {this.getReportMegaMenus(sub.subcategory_id)}

    //                 </NestedMenuItem>
    //             </Container>
    //         ))
    // }




    getReportMegaMenus(subcategory_id) {

           return <div className="">
                <Container className="bg-asteroid" disableGutters={true}>
                    <div />
                    <List component="div"  >
                        {this.getReportComponents(subcategory_id)}
                    </List>

                </Container>
            </div>

    }
    getReportComponents(subcategory_id) {
        let report_category_id = this.report_category_id
        let report_components = this.report_components

        const report  = this.props.state.bi.new_report

        return report && report.length
            ? report
                .filter(r => r.oq_report_key && r.subcategory_id == subcategory_id)
                .sort((a, b) => (a.report_name > b.report_name) ? 1 : -1)
                .map((x, ind) =>
                    x.report_name.length > 0 ?
                        <MenuItem
                            button
                            onClick={(event, value) => {
                                event.preventDefault();
                                this.props.actions.clearDateFilter(event)
                                this.props.actions.resetReportData(event)
                                //this.props.actions.setOqlisReport(x)
                                if (x.js_report) this.props.actions.getJSReportData(x.report_view)
                                this.props.actions.setOqlisReport(x.oq_report_key, x.js_report, x)
                                this.props.actions.getDashboardDataUrl(x.oq_report_key, x)
                                this.props.actions.setOqlisDashboard(null)
                                this.props.actions.toggleDashboardMenus(false, null, 'reportAnchorEl', 'reportMegamenu')
                                this.props.actions.route("/bi");
                            }}
                            className="bg-asteroid"
                            key={report_category_id + x.report_name}
                        >
                            <ListItemText primary={window.t.en(x.report_name)} />
                        </MenuItem>
                     :<ListItem><ListItemText >{window.t.en('No Reports')}</ListItemText></ListItem>
                ):<ListItem><ListItemText primary={window.t.en('No Reports')} /></ListItem>

    }

    getLayout() {
        return <div>
            <Container disableGutters className="DrawerGlobal">
                {
                    this.props.state.data.length || this.props.state.staticMenu.length
                    ? <div style={{height: '100%'}} className="sidebar-background">
                        <div style={{ width: '300px' }}>
                    <div className="app-header--pane">
                    <img
                            style={styles.logoSize}
                            // src={`${config.system.cdn}/${config.uri.header}`}
                            src={`${config.system.cdn}/${config.uri.header}`}
                            onClick={(event, value) => {
                                event.preventDefault();
                                //console.log('defaultlandingpage',props.state.user.defaultlandingpage)
                                let defaultlandingpage = props.state.user.defaultlandingpage ? props.state.user.defaultlandingpage : 'tasks'
                                props.actions.route('/' + defaultlandingpage)
                            }}
                        /> 
                        {/* // : <BidvestLogo style={{width: '250px', height: '80px', marginLeft: '25px'}}/>} */}

                    </div>
                </div>
                            <Menus />
                        </div>
                    : <div style={{height: '100%'}} className="sidebar-background">
                        <List component="div" aria-labelledby="nested-list-subheader" color="light">
                            <ListItem  color="light">
                                <ListItemText primary={"Loading..."}  color="light"/>
                                <ListItemIcon>
                                    <CircularProgress color="light" />
                                </ListItemIcon>
                            </ListItem>
                        </List> 
                    </div>
                }
            </Container>
        </div>
    }

    getNewMenus() {
        return <div></div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.navigationMenu,
            ...state.redirect,
            ...state.readComponent,
            ...state.jsReport,
            accounts: {
                ...state.accounts,
            },
            user: {
                ...state.user
            },
            bi: {
                ...state.businessIntelligence
            },
            releaseNote : {
                ...state.releaseNote
            }
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            {
                ...actions,
                ...redirectActions,
                ...cognitoActions,
                ...masterComponentViewActions,
                ...readComponentActions,
                ...workflowQueueTasksActions,
                ...BIActions,
                ...oqActions,
                ...jsReprtActions,
                ...releaseNoteActions
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
