import * as types from './types'
import * as uploadActions from '../../Upload/actions'
import * as quoteItemsTypes from '../QuoteItems/types'
import * as quoteItemsActions from '../QuoteItems/actions'
import moment from 'moment'

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch(uploadActions.resetAllDocuments())
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
        console.log('setQuotationsx 1')
        dispatch(quoteItemsActions.setQuotations([]))

        console.log('setQuotationsx 2')
        
        dispatch({
            type: quoteItemsTypes.SET_QUOTATION_ITEMS_INPUT_DATA,
            payload: []
        })
        console.log('setQuotationsx 3')
        dispatch({
            type: types.SET_COMPONENT_PO_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'quotations',
                value: []
            }
        })
    }
}


export const resetPOComponent = (type) => {
    return (dispatch, getState) => {
        dispatch(uploadActions.resetAllDocuments())
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
        console.log('setQuotationsx 1')
        dispatch(quoteItemsActions.setQuotations([]))

        console.log('setQuotationsx 2')
        
        dispatch({
            type: quoteItemsTypes.SET_QUOTATION_ITEMS_INPUT_DATA,
            payload: []
        })
        console.log('setQuotationsx 3')
        dispatch({
            type: types.SET_COMPONENT_PO_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'quotations',
                value: []
            }
        })
    }
}

export const setQuotationInputValue = (quotation, props, value) => {
    return (dispatch, getState) => {
        let quotations = getState().poBookingInvoice.quotations
        quotation[props] = value
        quotations[quotation.inx][props] = value
        console.log('addQuotationRowx', quotations)
        //console.log('addQuotationRowx', quotations)
        dispatch({
            type: types.SET_COMPONENT_PO_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'quotations',
                value: quotations
            }
        })
    }
}

export const addQuotationRow = (documents) => {
    return (dispatch, getState) => {
        let quotations = getState().poBookingInvoice.quotations
        let _documents = getState().poBookingInvoice.documents.concat(documents)
        //quotations.push({id: moment().format('YYMMDDhhmmssSSS')})
        
        quotations.push({id: moment().format('YYMMDDhhmmssSSS'), quotedate: moment().format('YYYY-MM-DD')})
        //console.log('addQuotationRowx d', quotations)
        //console.log('addQuotationRowx docs', getState().upload.documents)
        dispatch({
            type: types.SET_COMPONENT_PO_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'documents',
                value: _documents
            }
        })
        dispatch({
            type: types.SET_COMPONENT_PO_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'quotations',
                value: quotations
            }
        })
    }
}

export const remQuotationRow = (id) => {
    return (dispatch, getState) => {
        console.log('addQuotationRowx id', id)
        let quotations = getState().poBookingInvoice.quotations.filter(x => x.id !== id)
        console.log('addQuotationRowx', quotations)
        dispatch({
            type: types.SET_COMPONENT_PO_QUOTE_FIELD_INPUT_VALUE,
            payload: {
                prop: 'quotations',
                value: quotations
            }
        })
    }
}

export const setValue = (prop, value, valid) => {
    return (dispatch, getState) => {
        
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALIDATE,
            payload: {
                prop: prop,
                valid: valid ? valid : (value ? true : false)
            }
        })
        
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const getMMCodeByVIN = (vin) => {
    window.glyco.log('getMMCodeByVIN')
    window.glyco.log(vin)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MAPO_VEHICLE_MMCODE_BY_VIN_NUMBER,
            payload: vin
        })
    }
}
export const getServicePartBasket = (mmcode, interval) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BOOKING_VEHICLE_SERVICE_PART_BASKET,
            payload: '?mmcode=' +  mmcode + '&service_info_interval=' + interval
        })
    }
}

export const setServicePartBasket = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_VEHICLE_SERVICE_PART_BASKET,
            payload: response.data
        })
    }
}

export const getVehicleContract = (vehicle_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE,
            payload: vehicle_id
        })
    }
}

export const setVehicleContract = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BOOKING_VEHICLE_CONTRACT_DEREVATIVE,
            payload: response.data
        })
    }
}

export const limitExceeded = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_SUPPLIER_LIMIT_EXCEEDED_WIN,
            payload: status
        })
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'limitexceeded',
                value: false
            }
        })
    }
}

export const setAcceptedDocuments = (quotation,document,  documentype, documents, id, prefix) => {
    const _file = prefix + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        let _documents = getState().poBookingInvoice.documents
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_DEALER_BOOKING_INVOCE_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: _file,
                        type: file.type,
                    }
                })
            };
        });
        
        //console.log('setAcceptedDocumentsx quotation', quotation)
        //console.log('setAcceptedDocumentsx documentype', documentype)
        //console.log('setAcceptedDocumentsx _file', _file)
        //console.log('setAcceptedDocumentsx _file', _file)
        //console.log('setAcceptedDocumentsx _documents', _documents)

        //console.log('setAcceptedDocumentsx document', document)

        _documents.filter()
        // dispatch({
        //     type: types.SET_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW,
        //     payload: {
        //         preview: documents[0].preview,
        //         filename: _file,
        //     }
        // })
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'filename',
                value: _file
            }
        })  
        dispatch({
            type: types.SET_BOOKING__INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'invoicepreview',
                value: documents[0].preview
            }
        })
    }
}

export const setRejectedDocuments = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_DEALER_BOOKING_INVOCE_DOCUMENT_PREVIEW,
            payload: null
        })
    }
}

export const downloadDocument = (document) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.DOWNLOAD_DOCUMENT_DISCOUNT_LETTER,
            payload: {
                type: 'DOWNLOAD_DOCUMENT_DISCOUNT_LETTER',
                document: document
            }
        })
    }
}

export const setDiscountLetter = (data) => {
    let link = ''
    const filekey = data.data.document.key
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DISCOUNT_LETTER_DOCUMENT_PREVIEW,
            payload: {
                preview: link
            }
        })
    }
}

export const getVehicleLastPosition = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICLE_LASTPOSITION,
            payload: {
                type: 'GET_VEHICLE_LASTPOSITION',
                vehicle: id
            }
        })
    }
}

export const setVehicleLastPosition = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_VEHICLE_LASTPOSITION,
            payload: data.data
        })
    }
}

export const getSupplierListData = (search) => {
    //console.log('setSupplierListDatax get', search)
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_QUOTATION_SUPPLIERS_LIST,
            payload: search
        })
    }
}


export const setSupplierListData = (payload) => {
    return (dispatch, getState) => {
        
        //console.log('setSupplierListDatax', payload.data)
        let data = payload.data.map(x => {
            return {
                text: x.suppliername,
                value: x.supplier_id
            }
        })
        //console.log('setSupplierListDatax', data)
        dispatch({
            type: types.SET_COMPONENT_QUOTATION_SUPPLIERS_LIST,
            payload: data
        })
    }
}

export const setSelectedSupplierData = (supplier_id, supplier_name) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_QUOTATION_SELECTED_SUPPLIER,
            payload: {
                supplier_id: supplier_id, 
                supplier_name: supplier_name
            }
        })
    }
}

export const setSelectedSupplierName = (supplier_id, supplier_name) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_QUOTATION_SELECTED_SUPPLIER_NAME,
            payload: {
                supplier_id: supplier_id, 
                supplier_name: supplier_name
            }
        })
    }
}