import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'

import AutoComplete from '../../controls/autocomplete'

import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../Icon/component'
import Grid from '../Grid/component'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowInputComponent extends Component {
    componentDidMount() {
        this.props.actions.loadComponent(this.props.name, this.props.id)
    }

    getFormFieldValue(field, index) {
        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return <AutoComplete
                    listStyle={{ maxHeight: 200, overflow: 'auto' }}
                    id={field.name}
                    key={field.name}
                    hintText={field.hint}
                    filter={AutoComplete.fuzzyFilter}
                    //floatingLabelText={field.description}
                    //openOnFocus={true}
                    fullWidth={true}
                    searchText={this.component.data[field.name].text}
                    dataSourceConfig={{
                        text: 'text',
                        value: 'value'
                    }}
                    dataSource={this.component.data[field.name].data}
                    errorText={this.component.data[field.name].validation.message}
                    onNewRequest={
                        (chosenRequest, index) => {
                            this.props.actions.setComponentFieldInput(field, chosenRequest.value)
                        }
                    }
                    onClick={
                        (event) => {
                            this.props.actions.getComponentFieldLookupData(field, this.component.data[field.name].text ? this.component.data[field.name].text : '')
                        }
                    }
                    onUpdateInput={
                        (searchText, dataSource, params) => {
                            this.props.actions.getComponentFieldLookupData(field, searchText, dataSource, params)
                        }
                    }
                //maxSearchResults={3}
                />
            case 'datePicker':
                return<TextField
                                                type="date"
                    key={this.component.data[field.name].key}
                    //floatingLabelText={field.description}
                    hintText='Landscape Inline Dialog'
                    container='inline'
                    mode='landscape'
                    //defaultDate={new Date()}
                    value={this.component.data[field.name].input}
                    onChange={
                        (event, value) => {
                            this.props.actions.setComponentFieldInput(field, value)
                        }
                    }
                />
            case 'hidden':
                return <input
                    key={'component_' + this.component.name + '_' + field.name + '_' + this.component.data[field.name]}
                    type='hidden'
                    //defaultValue={0}
                    value={this.component.data[field.name].input}
                />
            case 'timePicker':
                return  <TextField
                        type="time"
                    key={this.component.data[field.name].key}
                    //floatingLabelText={field.description}
                    format='24hr'
                    hintText='24hr Format'
                    //defaultTime={new Date()}
                    value={this.component.data[field.name].input}
                    onChange={
                        (event, value) => {
                            this.props.actions.setComponentFieldInput(field, value)
                        }
                    }
                />
            case 'textBox':
                return <TextField
                    key={this.component.data[field.name].key}
                    //errorText=''
                    hintText={field.hint}
                    //floatingLabelText={field.description}
                    value={this.component.data[field.name].input}
                    //defaultValue={''}
                    errorText={this.component.data[field.name].validation.message}
                    style={styles.textBox}
                    onChange={
                        (event, value) => {
                            this.props.actions.setComponentFieldInput(field, value)
                        }
                    }
                />
            case 'toggle':
                return <Checkbox
                    key={this.component.data[field.name].key}
                    //label={field.hint}
                    labelPosition='right'
                    //
                    style={styles.toggle}
                    trackStyle={{
                        backgroundColor: 'gray'
                    }}
                    trackSwitchedStyle={{
                        backgroundColor: 'green'
                    }}
                    checked={this.component.data[field.name].input != null ? this.component.data[field.name].input : false}
                    onChange={
                        (event, isInputChecked) => {
                            this.props.actions.setComponentFieldInput(field, isInputChecked)
                        }
                    }
                />
            default:
                return
        }
    }

    getField(field, index, hidden) {
        return !hidden
            ? <div style={styles.fieldContainer}>
                <div style={styles.fieldTitle}>{field.title}</div>
                <div style={styles.fieldContent}>{this.getFormFieldValue(field, index)}</div>
            </div>
            : this.getFormFieldValue(field, index)
    }

    getSection(section, index) {
        let fields = section.component_field
            .sort((x, y) => x.order !== null ? x.order - y.order : x.name < y.name)
            .filter(x => x.form)
            .map((x, i) => {
                switch (x.component_field_display_type.name) {
                    case 'hidden':
                        return this.getField(x, i, true)
                    case 'autoComplete':
                        return <Col key={'col_' + x.name} xl={12}>{this.getField(x, i)}</Col>
                    default:
                        return <Col key={'col_' + x.name} xl={12}>{this.getField(x, i)}</Col>
                }
            })

        return <Card key={'card_' + section.name} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={section.icon.unicode} iname={section.icon.name} />}
                title={section.title}
                subtitle={section.description}
                actAsExpander={true}
                showExpandableButton={true}
                style={styles.row}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        {fields}
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getRelationship(relationship, index) {
        return <Card key={'relationship_' + relationship.name}>
            <CardHeader
                title={relationship.title}
                subtitle={relationship.description}
                actAsExpander={true}
                showExpandableButton={true}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Grid
                            name={relationship.name}
                            filterable={true}
                            link={{
                                parent: this.props.name,
                                id: this.props.id,
                                through: relationship.linker.name
                            }}
                        />
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getForm() {
        const sections = this.component.sections
            .filter(x => x.component_field.filter(y => y.form).length)
            .map((x, i) => {
                return this.getSection(x, i)
            })

        return sections
    }

    render() {
        this.component = this.props.state.component

        return (
            this.getForm()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: { ...state.form, component: { ...state.components[ownProps.name] } }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowInputComponent)