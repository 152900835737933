import * as validatorTypes from '../Validator/types'

export const fields = {
    email: {
        id: 'email',
        name: 'email',
        key: true,
        errorText: '',
        hintText: 'Example: john@acme.co',
        floatingLabelText: 'Email',
        input: '',
        type: 'text',
        valid: false,
        empty: true,
        validators: [validatorTypes.IS_EMAIL, validatorTypes.IS_NOT_EMPTY]
    },
    keyword: {
        id: 'keyword',
        name: 'keyword',
        key: 'password',
        errorText: '',
        hintText: 'Example: sw0rD#fiGht',
        floatingLabelText: 'Password',
        input: '',
        type: 'password',
        valid: false,
        empty: true,
        validators: [validatorTypes.IS_STRONG_PASSWORD, validatorTypes.IS_NOT_EMPTY]
    }

}

export const fields1 = [
    {
        id: 'email',
        name: 'email',
        hidden:false,
        key: false,
        value: '',
        title: 'Email',
        label:'Email',
        placeholder:'Enter Email',
        errorText: '',
        hintText: 'Example: john@acme.co',
        floatingLabelText: 'Email',
        input: '',
        type: 'textBox',
        valid: false,
        empty: true,
        fullWidth: true,
        variant:"outlined",
        margin: "normal",
        required: true,
        validators: [validatorTypes.IS_EMAIL, validatorTypes.IS_NOT_EMPTY]
    },
    {
        id: 'password',
        name: 'password',
        title: 'Password',
        key: false,
        hidden:true,
        value: '',
        label:'Password',
        placeholder:'Enter Password',
        errorText: '',
        hintText: 'Example: sw0rD#fiGht',
        floatingLabelText: 'Password',
        input: '',
        type: 'textBox',
        valid: false,
        empty: true,
        fullWidth: true,
        variant:"outlined",
        margin: "normal",
        required: true,
        validators: [validatorTypes.IS_STRONG_PASSWORD, validatorTypes.IS_NOT_EMPTY]
    }

]


export const screen = 'login'
export const username = ''
export const valid = false
export const login = false
export const buffer = null
export const url = null
export const showPassword = false


export const locale = [
    {text: 'English',value: 'ar'},
    {text: 'Russia',value: 'ru'},
    {text: 'Arabic',value: 'ar'}
]