import * as actions from './actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'



export const getLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_FML_QUOTATION_ITEMS_DATA_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/' + payload.component + '/search?'+payload.query,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
            },
        })
        .pipe(
        mergeMap((result) => [
            actions.setLookupData(result.response, payload),
            progressBarActions.progress(false),
            requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search)
        ])
        ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search, error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)


export const getLookupItemsData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_FML_QUOTATION_ITEMS_LOOKUP_DATA),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/' + payload.component + '/in?supplier_id=' + payload.supplier_id,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'related': payload.related,
            },
        })
        .pipe(
        mergeMap((result) => [
            actions.setLookupItemsData(result.response, payload),
            progressBarActions.progress(false),
            requestActions.requestSucceeded(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search)
        ])
        ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search, error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const loadChipAccessory = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_FML_QUOTATION_CHIP_DATA_LIST),
    delay(0)
    ,mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/accessory/search',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'take': 6
            },
        })
            .pipe(
            mergeMap((result) => [
                actions.setChipAccessory(result.response, payload),
            ])
            //,startWith(progressBarActions.progress(true))
            ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/customerderivative/search?customer_id=' + payload.customer_id + '&contracttype=' + payload.search, error)))
            //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)
export const getSupplierData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_SUPPLIER_DATA),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplieraccessorypricing/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json',
                },
                body: payload
            })
            .pipe(
                mergeMap((result) => [
                    actions.setSupplierData(result.response, payload),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/supplieraccessorypricing/search')
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplieraccessorypricing/search', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )
        export const getSupplierDataAccessoryData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(
                ofType(types.GET_SUPPLIER_ACC_DATA),
                delay(0)
                ,mergeMap(({ payload }) =>
                    ajax$({
                        url: config.system.api + '/components/supplieraccessorypricing/other',
                        method: 'POST',
                        headers: {
                            'tokenx': store.value.user.user_id,
                            'auth': store.value.cognito.authenticateUser.token,
                            'salt': store.value.cognito.authenticateUser.jwt,
                            'datetime': store.value.cognito.authenticateUser.datetime,
                            'Content-Type': 'application/json',
                        },
                        body: payload
                    })
                    .pipe(
                        mergeMap((result) => [
                            actions.setSupplierDataAccessoryData(result.response, payload),
                            progressBarActions.progress(false),
                            requestActions.requestSucceeded(config.system.api + '/components/supplieraccessorypricing/search')
                        ])
                        //,startWith(progressBarActions.progress(true))
                        ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplieraccessorypricing/search', error)))
                        //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
                )
                ,catchError((error) =>
                    of$(routerActions.route('/support', false, error))
                )
                )
                export const getSupplierDistanceData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
                action$.pipe(
                        ofType(types.GET_SUPPLIER_DISTANCE_DATA),
                        delay(0)
                        ,mergeMap(({ payload }) =>
                            ajax$({
                                url: config.system.api + '/components/supplieraccessorypricing/other',
                                method: 'POST',
                                headers: {
                                    'tokenx': store.value.user.user_id,
                                    'auth': store.value.cognito.authenticateUser.token,
                                    'salt': store.value.cognito.authenticateUser.jwt,
                                    'datetime': store.value.cognito.authenticateUser.datetime,
                                    'Content-Type': 'application/json',
                                },
                                body: payload
                            })
                            .pipe(
                                mergeMap((result) => [
                                    actions.setSupplierDistanceData(result.response, payload),
                                    progressBarActions.progress(false),
                                    requestActions.requestSucceeded(config.system.api + '/components/supplieraccessorypricing/search')
                                ])
                                //,startWith(progressBarActions.progress(true))
                                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplieraccessorypricing/search', error)))
                                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                        )
                        )
                        ,catchError((error) =>
                            of$(routerActions.route('/support', false, error))
                        )
                        )
                        export const getSupplierAccessoryHistoryData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
                        action$.pipe(
                                ofType(types.GET_SUPPLIER_HISTORY__DATA),
                                delay(0)
                                ,mergeMap(({ payload }) =>
                                    ajax$({
                                        url: config.system.api + '/components/supplieraccessorypricing/other',
                                        method: 'POST',
                                        headers: {
                                            'tokenx': store.value.user.user_id,
                                            'auth': store.value.cognito.authenticateUser.token,
                                            'salt': store.value.cognito.authenticateUser.jwt,
                                            'datetime': store.value.cognito.authenticateUser.datetime,
                                            'Content-Type': 'application/json',
                                        },
                                        body: payload
                                    })
                                    .pipe(
                                        mergeMap((result) =>[
                                            actions.setSupplierAccessoryHistoryData(result.response, payload),
                                            progressBarActions.progress(false),
                                            requestActions.requestSucceeded(config.system.api + '/components/supplieraccessorypricing/search')
                                        ])
                                        //,startWith(progressBarActions.progress(true))
                                        ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/supplieraccessorypricing/search', error)))
                                        //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                                )
                                )
                                ,catchError((error) =>
                                    of$(routerActions.route('/support', false, error))
                                )
                                )
        