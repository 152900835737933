import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'
import * as types from './types'

export const getDisposalList = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_WORKFLOW_DISPOSAL_DATA_LIST),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search?yardlist_vehicle=true',
                method: 'GET',
                headers: {
                    'take': 200,
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'maintenancetransaction,customercontract.customercontractderivative,mmcode,vehicledocument.document,customer,disposalbidder,disposal,disposalcomments,vehiclestatus'
                }
            })
            .pipe(
                mergeMap(result => [
                    actions.setDisposalList(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search?licenseplateno=' + payload)
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search?licenseplateno=' + payload, error))),
                //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
            )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
            )
        )

 
export const getOTP = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_WORKFLOW_DISPOSAL_OPT_LIST),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/otp/search',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
            .pipe(
                mergeMap(result => [
                    actions.setOTP(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/otp/search')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/otp/search', error))),
                //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
            )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
            )
        )
       
export const submitAndCloseComment = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.SET_DISPOSAL_USER_COMMENTS_INPUT),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/disposalcomments/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(result => [
                    actions.setCommentsAndClose(result.response,payload),
                    requestActions.requestSucceeded(config.system.api + '')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error))),
                //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)) 
                )
            )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
            )   
        )
        
        
export const submitSeeingStatus = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.SET_DISPOSAL_USER_SEEING_INPUT),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/disposalcomments/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(result => [
                    actions.setCommentsAndClose(result.response,payload),
                    requestActions.requestSucceeded(config.system.api + '')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error))),
                //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ) 
    )

        
export const uploadImportData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.UPLOAD_IMPORT_FILE_DATA),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/importtemplate/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
        .pipe(
            mergeMap(result => [
                actions.uploadImportDataDone(result),
                requestActions.requestSucceeded(config.system.api + '/components/importtemplate/other')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/importtemplate/other', error))),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
        )
    )


export const postDisposalData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.POST_COMPONENT_DISPOSAL_DATA),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/disposal/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
        .pipe(
            mergeMap(result => [
                actions.postDisposalDataDone(result, payload),
                requestActions.requestSucceeded(config.system.api + '/components/disposal/other')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/disposal/other', error))),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
        )
    )

    
export const getBidders = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_BIDDERS_DISPOSAL_DATA_LIST),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/supplier/search?suppliertype_fktext=Bidder',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
        })
        .pipe(
            mergeMap(result => [
                actions.setBidders(result),
                requestActions.requestSucceeded(config.system.api + '/components/disposal/other')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/disposal/other', error))),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
        )
    ,catchError((error) =>
        of$(routerActions.route('/support', false, error))
        )
    )