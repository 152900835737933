import moment from 'moment'

import * as types from './types'
import * as appTypes from '../App/types'

import * as appActions from '../App/actions'
import * as accountActions from '../Account/actions'
import * as cognitoActions from '../Cognito/actions'
import * as routerActions from '../Redirect/actions'


export const getUser = (payload) => {
    console.log('getUser >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>' ,payload )
    return (dispatch, getState) => {
        if(payload){
            dispatch({
                type: types.GET_USER,
                payload: payload
            })
        }
        else{
            dispatch({
                type: types.USER_NOT_FOUND,
            })
        }
    }
}

export const reTry = (payload) => {
    console.log('getUser reTry >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>' ,payload )
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_USER,
            payload: payload
        })
    }
}

export const setUser = (results) => {
    return (dispatch, getState) => {
        //console.log('setUser setUser >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>' ,results.length,results)
        if (results.length) 
        {
            //console.log('setUser results>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>' ,results[0].is_active,results[0])
            if (results[0].is_active)
            {
                dispatch({
                    type: types.SET_COMPONENT_USER_DATA,
                    payload: results[0]
                })

                dispatch({
                    type: appTypes.SET_APP_USER_AUTH_STATUS,
                    payload: results.length ? true : false
                })

                if(!results[0].user_team.length ){
                    dispatch(routerActions.route('/permission'))
                }else{
                    dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title:  results[0].firstname + ' ' + results[0].lastname,
                            overflowText: '',
                            additionalText: 'Welcome back',
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    dispatch(appActions.configureApp())
                    
                    dispatch(accountActions.getUserAccounts(results[0].cognito_key))
                }
                dispatch(getUserCustomer())
            }
            else 
            {
                dispatch(cognitoActions.signoutCognitoUser())

                dispatch({
                    type: appTypes.SHOW_NOTIFICATION,
                    payload: {
                        title: 'System Notification',
                        overflowText: getState().login.fields.email.input,
                        additionalText: 'Your account is inactive. Please contact your system administrator for reactivation.',
                        autoHide: 10000,
                        timestamp: moment().format('h:mm A')
                    }
                })

                dispatch(appActions.clearApplicationCache())
            } 
        }
        else 
        {
            dispatch(cognitoActions.signoutCognitoUser())

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    overflowText: getState().login.fields.email.input,
                    additionalText: 'Your account is inactive. Please contact your system administrator for reactivation.',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })

            dispatch(appActions.clearApplicationCache())
        }
    }
}


export const getUserCustomer = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_USER_CUSTOMER,
            payload: {
                "type": "GET_USER_CUSTOMER",
                "user_id": getState().user.user_id
            }
        })
    }
}


export const setUserCustomer = (payload) => {
    //console.log('setUserCustomer >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>' ,payload )
    return (dispatch, getState) => {
        let user = { ...getState().user, customers: payload }
        dispatch({
            type: types.SET_USER,
            payload: user
        })
    }
}