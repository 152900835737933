export const CLEAR_WORKFLOW_TASK_INFORMATION = 'CLEAR_WORKFLOW_TASK_INFORMATION'
export const GET_WORKFLOW_TASK_INFORMATION = 'GET_WORKFLOW_TASK_INFORMATION'
export const SET_WORKFLOW_TASK_INFORMATION = 'SET_WORKFLOW_TASK_INFORMATION'
export const GET_WORKFLOW_TASK_ACTION_DATA = 'GET_WORKFLOW_TASK_ACTION_DATA'
export const SET_WORKFLOW_TASK_ACTION_DATA = 'SET_WORKFLOW_TASK_ACTION_DATA'
export const GET_WORKFLOW_TASK_TEAMS = 'GET_WORKFLOW_TASK_TEAMS'
export const SET_WORKFLOW_TASK_TEAMS = 'SET_WORKFLOW_TASK_TEAMS'
export const SET_WORKFLOW = 'SET_WORKFLOW'
export const SET_TASK = 'SET_TASK'
export const SET_TOTAL_STEPS = 'SET_TOTAL_STEPS'
export const RESET_WORKFLOW_TASK_WIZARD = 'RESET_WORKFLOW_TASK_WIZARD'
export const GET_TOTAL_STEPS = 'GET_TOTAL_STEPS'
export const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP'
export const GO_TO_PREVIOUS_STEP = 'GO_TO_PREVIOUS_STEP'
export const SHOW_COMPONENT_ERRORS = 'SHOW_COMPONENT_ERRORS'
export const SUBMIT_WORKFLOW_TASK_INPUT = 'SUBMIT_WORKFLOW_TASK_INPUT'
export const CONFIRM_WORKFLOW_TASK_APPROVAL = 'CONFIRM_WORKFLOW_TASK_APPROVAL'
export const CLEAR_WORKFLOW_TASK_ACTION_DATA = 'CLEAR_WORKFLOW_TASK_ACTION_DATA'
export const SET_COMPONENT_VALIDATION_PROPS_FIELDS = 'SET_COMPONENT_VALIDATION_PROPS_FIELDS'
export const GET_WORKFLOW_TASK_CONNECTOR_DATA = 'GET_WORKFLOW_TASK_CONNECTOR_DATA'
export const SET_WORKFLOW_TASK_CONNECTOR_DATA = 'SET_WORKFLOW_TASK_CONNECTOR_DATA'
export const SET_CONFIRM_TOGGLE_MENU_STATUS = 'SET_CONFIRM_TOGGLE_MENU_STATUS'
export const CLOSE_NOTIFICATION_DIALOG = 'CLOSE_NOTIFICATION_DIALOG'
export const TOGGLE_NOTIFICATION_DIALOG = 'TOGGLE_NOTIFICATION_DIALOG'
export const TOGGLE_CUSTOMER_NOTIFICATION_DIALOG = 'TOGGLE_CUSTOMER_NOTIFICATION_DIALOG'