import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_TEAM_HIERARCHY:
            return {
                ...state,
                tree: action.payload
            }
        default:
            return state
    }
}