import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import LinearProgress from '@mui/material/LinearProgress'

import * as actions from './actions'
import * as styles from './styles'

class ProgressBar extends Component {
    render() {
        const component = {
            state: this.props.state,
            actions: this.props.actions,
            styles: styles
        }

        return (
            <LinearProgress style={component.styles.progressBar(component.state.display)} mode="indeterminate" />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.progressBar }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar)