import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                filename: '',
                filepreview: '',
                comments: '',
                documents: []
            }
        case types.SET_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW:
        return {
            ...state,
            filename: action.payload.filename,
            filepreview: action.payload.preview,
        }
    case types.CLEAR_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW:
        return {
            ...state,
            filename: action.payload,
            filepreview: action.payload,
        }
    case types.SET_BILLING_INVOICE_COMPONENT_FIELD_INPUT_VALUE:
        return {
            ...state,
            [action.payload.prop]: action.payload.value
        }
    case types.UPDATE_OFFER:
        return {
            ...state,
            offers: action.payload
        }
    default:
            return state
    }
}