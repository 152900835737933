import * as types from './types'

export const confirmMultipleSelection = (step, component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CONFIRM_MULTIPLE_SELECTION,
            payload: {
                step: step,
                component: component,
                selected: getState().components[component].selected
                    .map(x => { return x.original })
            }
        })
    }
}

export const removeSelectedItem = (step, component, index) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REMOVE_SELECTED_ITEM,
            payload: {
                step: step,
                component: component,
                index: index
            }
        })
    }
}