import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as config from '../../config'
import * as actions from './actions'
import JSReport from '../JSReport/embed'
import Explore from './explore'
import Loading from '../Loading/component'
import * as styles from './styles'
import {ReactComponent as NewTaskIcon} from '../../assets/img/svg/icons/kpi/new_task.svg';
import {ReactComponent as CompletedTasksIcon} from '../../assets/img/svg/icons/kpi/completed_tasks.svg';
import {ReactComponent as AssignedTasksIcon} from '../../assets/img/svg/icons/kpi/assigned_task.svg';

import SVGLoader from '../../svgloader';

import { Container, Row, Col } from 'react-grid-system'

const src = config.oq.src
const cdn = config.system.cdn
const api_key = config.oq.api_key
const connectionName = config.oq.connection

const getIcon = (title, index) => {
    //console.log('title', title)
    switch (title) {
        case 'new_task':
            return 
        case 'new_tasks':
            <div key={index} className='icon-style-kpi'><NewTaskIcon/></div>
        case 'completed_task':
            <div key={index} className='icon-style-kpi'><CompletedTasksIcon/></div>
        case 'completed_tasks':
            <div key={index} className='icon-style-kpi'><CompletedTasksIcon/></div>
        default:
            return <div key={index} className='icon-style-kpi'><AssignedTasksIcon/></div>
    }
}


//http://172.30.1.94/api/dashboard?token=ba4155f1b314cfa7569a623f979eb12c7dbff3d53d39db0bc76af88c69ef0511c516a4a31a06983d446eb59e14b583ef7917eb2c4ab17ba36a8ae284e22c6e03
class Oqlis extends Component {

    componentWillMount(){
        this.props.actions.getDashboardDataUrl( this.props.oq_key,'')
        //this.props.actions.getKPIData(this.props.kpi_view, this.props.state.user.client_id)
        this.props.actions.getKPIData(this.props.kpi_view,this.props.kpi_view === 'get_workflow_tasks_stats3' ? this.props.state.user.user_id : this.props.state.user.client_id)
    }

    getIcon(key, svgFileName, svgStyling, isKPI){
        return <SVGLoader key={key} svgFileName={svgFileName} styleName={svgStyling} isKPI={isKPI} />
    }

    viewKPI() {
         //console.log('oq_view', this.props.state.user.client_id);
        const { displayType, oq_key, oq_theme, height, noheader } = this.props
        const { oqlisMenuDashboard, oqlisMenuReport, oqlisToken } = this.props.state
        let key = oqlisMenuDashboard.key ? oqlisMenuDashboard.key : oqlisMenuReport.key ? oqlisMenuReport.key : oq_key
        let source = key ? `${src}${displayType}?api_key=${api_key}&${displayType}=${key}${noheader ? `&noheader=${noheader}` : ''}${noheader ? `&noheader=${noheader}` : ''}${connectionName ? `&connection=${connectionName}` : ''}` : null
        
        //source = source + "&filters=customer_id = " + this.props.state.user.customers
        //const source = src + 'dashboard?token=' + oqlisToken
        
        return (
            this.props.kpi_view && this.props.state.kpiData ?
            <div style={{padding: '5px 15px'}}>
            <div style={{display: 'flex', position: 'relative'}}>{this.props.state.kpiData.map((x,i) => {
                return <div style={styles.taskCounter(this.props.isworkflow, i, this.props.state.kpiData.length)}>
                  <div style={styles.counterContainer}>
                    <div style={styles.counterCircle(this.props.isworkflow, i)}>
                      <span style={styles.counterNumber}>
                      {/* <img
                              style={{width: 30, height: 30, color:'#fff'}} 
                              src={`https://s3.eu-west-2.amazonaws.com/cdn.innov8.guru/icons/${x.title.toLowerCase().replace(/ /g, '_')}.svg`}
                              // src={`${config.system.cdn}/${x.title.toLowerCase().replace(/ /g, '')}.svg`}
                          /> */}
                          {this.getIcon(`${x.title.toLowerCase().replace(/ /g, '_')}_${i}`, x.title.toLowerCase().replace(/ /g, '_'), 'icon-style-kpi',true)}
                          </span>
                    </div>
                    <div style={styles.kpiTextWrapper}>
                    <span style={styles.counterNumber}><strong>{x.count}</strong></span>
                    <span style={styles.counterText}><div style={{fontSize: '20px', fontWeight: '400'}}>{x.title}</div></span>
                    </div>    
                  </div>
                </div>
              })}
            </div>
            </div>
            
            :
            
            <></>
        )
    }

    viewDashboard() {
         
        const { displayType, oq_key, oq_theme, height, noheader } = this.props
         const { oqlisMenuDashboard, oqlisMenuReport, oqlisToken } = this.props.state
        let key = oqlisMenuDashboard.key ? oqlisMenuDashboard.key : oqlisMenuReport.key ? oqlisMenuReport.key : oq_key
         const source = key ? `${src}${displayType}?api_key=${api_key}&${displayType}=${key}${noheader ? `&noheader=${noheader}` : ''}${noheader ? `&noheader=${noheader}` : ''}${connectionName ? `&connection=${connectionName}` : ''}` : null
        
        //const source = src + 'dashboard?token=' + oqlisToken
        // return (
        //     <div style={{ marginTop: '0px' }}>
        //         <Explore url={`${source}`} />
        //     </div>
        // )
        return (
            <div style={{ marginTop: '0px' }}>
                <iframe
                    src={`${source}`} width="100%"
                    height={height ? height : "950px"} style={{ border: "none", alignSelf: "center", }}>
                </iframe>
            </div >
        )
    }

    viewJSReport() {
        return <JSReport report={this.props.state.oqlisMenuReport} report_data={this.props.state.report_data} client_data={{theme: cdn +'/css/'+ this.props.state.accounts.accountTheme, logo: cdn +'/'+ this.props.state.accounts.accountLogo}} />
    }

    render() {
        return (
            <div>
                {this.props.state.oqlisMenuReport.isJs ? this.viewJSReport() : this.props.kpi_view ? this.viewKPI() : this.viewDashboard() }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.oqlis,
            ...state.navigationMenu,
            ...state.jsReport,
            accounts: {
                ...state.accounts
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, }
            , dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Oqlis)