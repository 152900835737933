import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton'

import { Container, Row, Col } from 'react-grid-system'
import ReactTable from "react-table"
import "react-table/react-table.css"
import Button from '@mui/material/Button'

import Drawer from '../../../controls/drawer'
import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import Upload from '../../Upload/component'

import * as actions from './actions'
import * as styles from './styles'

class WorkflowDisposalDocuments extends Component {

    getCommands(row) {
        let i = row.index
        let commands = []
        //console.log('getCommands Row',row)

        commands.push(
            <IconButton key={'command_download_' + i} style={styles.iconButton} iconStyle={styles.actionIcon('')}
                onClick={
                    () => {
                        //console.log('getCommands Row',row)
                        this.props.actions.downlaodDocument(row.original.document)
                        //this.props.actions.toggleInfoSheet(i, 'inforsheet_view')
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'cloud_download'} />
            </IconButton>
        )
        
        return commands
    }

    getValue(row) {
        let field = this.props.state.document_headers.filter(x => x.name === row.column.meta)[0]
            switch (field.type) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
    }

    getFormFieldLabel(row) {
          return <div
          style={{
            width: '100%',
            marginTop: '3px',
            verticalAlign: 'baseline',
            fontSize: '15px'
        }} >{this.getValue(row)}</div>
    }

    getColumns() {
        const fields = this.props.state.document_headers.filter(x => x.key === false)
        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: x.title,
                    accessor: x.display,
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        x.type !== 'boolean'
                            ? <input
                                onChange={event => onChange(event.target.value)}
                                value={filter ? filter.value : ''}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                            />
                            : <select
                                onChange={event => onChange(event.target.value)}
                                style={{
                                    width: '100%',
                                    height: 25,
                                    border: '1px solid #E6E6E6',
                                    borderRadius: 25
                                }}
                                value={filter ? filter.value : ''}
                            >
                                <option value={''}>Show All</option>
                                {
                                    x.toggle.split(',').map((x, i) => {
                                        return <option value={i === 0 ? 'true' : 'false'}>{x}</option>
                                    })
                                }
                            </select>
                    ),
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'middle',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.right
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getFormFieldLabel(row)
                    )
                }
            })

            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 80,
                width: 80,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        return columns
    }

    getGrid() {
        return <div>
        
            <ReactTable
                data={this.data.vehicledocument}
                columns={this.getColumns()}
                manual
                loading={this.loading}
                minRows={1}
                showPageJump={true}
                showPaginationTop={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div>
    }

    getLayout() {
        return <Drawer 
                onClose={(event, value) => {
                    this.props.actions.toggleInfoSheet(null,'inforsheet_documents')
                }}
                width={800}
                open={this.props.state.inforsheet_documents}
                title={'Document'}
                subtitle={'Vehicle document list'}
                content={this.getGrid()}
            />
            // <Drawer
            //     width={800}
            //     anchor={'right'}
            //     openSecondary={true}
            //     open={this.props.state.inforsheet_documents}
            //     onRequestChange={(event) => {
            //         event.preventDefault()
            //         this.props.actions.toggleInfoSheet(null,'inforsheet_documents')
            //     }}>
            //     <br /> <br /> 
            //     <Button 
            //         className={"global_button"} 
            //         variant="contained"
            //         className=""
            //         style={{ width: '120px'}}
            //         onClick={(event) => {
            //             event.preventDefault()
            //             this.props.actions.toggleInfoSheet(null,'inforsheet_documents')
            //         }}>
            //             {<Icon iclass={'material-icons'} iname={'close'} />} &nbsp; {window.t.en('Close')}
            //     </Button><br /> <br />
            //     {
            //         this.props.state.yardlist.length
            //             ? this.getGrid()
            //             : <Loading message={'Loading component...'} />
            //     }
            //     {
            //     <Container style={{ margin: 0 }} className="custom-container">
            //     <Row>
            //         <Col xl={12}>
            //             <Upload
            //                 id={'upload_ctl'}
            //                 prefix={'po'}
            //                 header={false}
            //             />
            //         </Col>
            //     </Row>
            //     <Row>
            //         <Col xl={12}>
            //             {
            //                 this.props.state.upload.documents.length
            //                 ? <Button
            //                     label="Upload"
            //                     primary={true}
            //                     onClick={(event) => {
            //                         event.preventDefault()
            //                         this.props.actions.uploadUserDocument(this.data.vehicle_id)
            //                     }}
            //                     >{"Upload"}</Button>
            //                 : ''
            //             }
            //         </Col>
            //     </Row>
            //     </Container>
            //     }
            // </Drawer>
    }

    render() {
        this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            },
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalDocuments)