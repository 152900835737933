import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import JSReport from '../JSReport/component'
// import Loading from '../Loading/component'
// import moment from 'moment'
import * as _ from 'lodash'

import * as actions from './actions'

class Embed extends Component {

    // getLayout(){
    //     const {report, report_data, client_data} = this.props
      
    //     let data = null
    //     if (report_data) {
            
    //         data = {}
    //         //     "account": client_data, 
    //         //     "reportheader": {
    //         //         "reportname": report.report_.report_name,
    //         //         "reportdescription": report.report_.report_desc,
    //         //         "reportid": moment().format('HHmmss') + ' - ' + report.report_.report_id,
    //         //         "reportdate": moment().format('DD/MM/YYYY'),
    //         //         "recordtotal": _.sumBy(report_data, (d) => { return parseFloat(d.amount ? d.amount : 0.00) }).toFixed(2),
    //         //         "recordcount": Number(report_data.length),
    //         //         "user": report.report_.user.firstname + ' ' + report.report_.user.lastname ,
    //         //         "logoname": ''
    //         //     },
    //         //     "reportdata": report_data
    //         // }
    //         return ''//<JSReport shortid={report.key} name={report.report_.name} data={data} report_id={report.report_.report_id}/>
    //     }
    // }

    render() {
        //const {report, report_data} = this.props
        return <h2>Foo</h2>// report_data? this.getLayout(): '' //<Loading />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.jsReport,
            ...state.navigationMenu
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Embed)