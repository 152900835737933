import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.GET_CIS_EMAIL_DATA:
        case types.SET_CIS_EMAIL_DATA:
        case types.SET_USER_CIS_LIST:
        case types.SHOW_CIS_ADD_DIALOG:
        case types.SET_CIS_USER_INPUT:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        case types.SET_CIS_HISTORY_DATA:
        case types.SHOW_CIS_CONFIRM_DELETE_DIALOG:
        case types.SET_CIS_DATA:
            return {
                ...state,
                loading: false,
                [action.payload.prop]: action.payload.data
            }
        default:
            return state
    }
}
