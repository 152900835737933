import * as types from './types'
import * as appTypes from '../../App/types'
import moment from 'moment'
import _ from 'lodash'
import * as formulas from 'formulajs'

import * as headerTypes from '../types'

export const setView = (data) => {
    return (dispatch, getState) => {
        //console.log('setView', data)
        dispatch({
            type: headerTypes.SET_STR_COMPONENT_VIEW,
            payload: data
        })
    }
}

export const addressSearch = (prop, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ADDRESS_LOOKUP,
            payload: {
                prop: prop,
                location: search,
                type: "search_location"
            }
        })
    }
}

export const addressSearchDone = (results, payload) => {
    return (dispatch, getState) => {
        //console.log('addressSearchDone results', results)
        //console.log('addressSearchDone payload', payload)
        results = results.map(x => { return { ...x, text: x.display_name, value: x.lat + ',' + x.lon } })
        dispatch(setInputValue({ prop: payload.prop, value: results }))
    }
}

export const getDistance = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_ADDRESS_DISTANCE,
            payload: {
                ...data,
                type: "get_distance"
            }
        })
    }
}

export const setDistance = (results, payload) => {
    return (dispatch, getState) => {
        let cost = parseFloat(results.kms) * 2.50
        dispatch(setInputValue({ prop: payload.prop + '_cost', value: cost }))
        dispatch(setInputValue({ prop: payload.prop + '_kms', value: results.kms }))
    }
}

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_COMPONENT_RESET_FORM,
        })
    }
}

export const changeTabView = (inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_COMPONENT_SET_CURRENT_TAB_VIEW,
            payload: inx
        })
    }
}

export const setupWorkflowTaskInput = (data, meta, component, step) => {
    return (dispatch, getState) => {

        const payload = {
            step: step,
            component: component,
            selected: data,
            meta: meta
        }

        dispatch({
            type: types.CONFIRM_WORKFLOW_CRAETE_STR_QUOTATION_HEADER_SELECTION,
            payload: payload
        })
    }
}
export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
            payload: data
        })
    }
}

export const setPropValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setInputListValue = () => {
    return (dispatch, getState) => {
        let list = getState().captureQuotation.accessory_list
        // list[data.inx].rateperday = data.value
        // list[data.inx].ratepermonth = (data.value * (365/12)).toFixed(2)
        let accessory_tot_daily = _.sumBy(list, (d) => { return parseFloat((Number(d.rateperday) ? Number(d.rateperday) : 0.00)) }).toFixed(2)
        let accessory_tot_monthly = _.sumBy(list, (d) => { return parseFloat((Number(d.ratepermonth) ? Number(d.ratepermonth) : 0.00)) }).toFixed(2)
        dispatch(setInputValue({ prop: 'accessory_tot_daily', value: accessory_tot_daily }))
        dispatch(setInputValue({ prop: 'accessory_tot_monthly', value: accessory_tot_monthly }))
    }
}

export const calc_totals = () => {
    return (dispatch, getState) => {
        let data = getState().captureQuotation


        let waiver_tot = data.waiver_code ? _.sumBy(data.ratecards, (d) => { return parseFloat(Number(d[data.waiver_code]) ? Number(d[data.waiver_code]) : 0.00) }).toFixed(2) : 0
        waiver_tot = waiver_tot ? (waiver_tot / (data.ratecards?.length ? data.ratecards?.length : 1)).toFixed(2) : 0

        // let accessory_tot_monthly = _.sumBy(data.accessory_list, (d) => { return parseFloat((Number(d.ratepermonth) ? Number(d.ratepermonth) : 0.00)) }).toFixed(2)
        // //console.log('calc_rental accessory_tot_monthly',accessory_tot_monthly)
        // //console.log('calc_rental str_monthly_rental',data.str_monthly_rental)
        // //console.log('calc_rental waiver_tot',data.waiver_tot)
        // //console.log('calc_rental insurance_tot',data.insurance_tot)

        let accessory_tot_monthly = parseFloat(data.accessory_tot_monthly)
        let delivery_cost = parseFloat(data.delivery_cost)
        let collection_cost = parseFloat(data.collection_cost)

        let monthly_excl = Number(data.str_monthly_rental) + Number(delivery_cost) + Number(collection_cost) + Number(waiver_tot) + Number(data.insurance_tot) + Number(accessory_tot_monthly)
        let monthly_vat = Number(Number(monthly_excl) * 0.15).toFixed(2)
        let monthly_incl = Number(Number(monthly_excl) * 1.15).toFixed(2)

        dispatch(setInputValue({ prop: 'waiver_tot', value: waiver_tot }))
        dispatch(setInputValue({ prop: 'monthly_excl', value: monthly_excl }))
        dispatch(setInputValue({ prop: 'monthly_vat', value: monthly_vat }))
        dispatch(setInputValue({ prop: 'monthly_incl', value: monthly_incl }))
    }
}

export const calc_rental = (ratecard) => {
    return (dispatch, getState) => {
        let avg_rental = _.sumBy(ratecard, (d) => { return parseFloat(Number(d.fml_monthly_rental) ? Number(d.fml_monthly_rental) : 0.00) }).toFixed(2)
        avg_rental = (avg_rental / ratecard?.length).toFixed(2)

        let avg_factor = _.sumBy(ratecard, (d) => { return parseFloat(Number(d.str_factor_percentage) ? Number(d.str_factor_percentage) : 0.00) }).toFixed(2)
        avg_factor = (avg_factor / ratecard?.length).toFixed(2)

        let avg_utilisation = _.sumBy(ratecard, (d) => { return parseFloat(Number(d.str_utilisation_rate) ? Number(d.str_utilisation_rate) : 0.00) }).toFixed(2)
        avg_utilisation = (avg_utilisation / ratecard?.length).toFixed(2)

        let str_monthly_rental = (avg_rental * (1 + (avg_factor / 100))).toFixed(2)
        let str_daily_rental = ((str_monthly_rental / (avg_utilisation / 100)) / (365 / 12)).toFixed(2)

        dispatch(setInputValue({ prop: 'str_monthly_rental', value: str_monthly_rental }))
        dispatch(setInputValue({ prop: 'str_daily_rental', value: str_daily_rental }))
        dispatch(setInputValue({ prop: 'ratecards', value: ratecard }))
    }
}

export const addNewItem = (data) => {
    return (dispatch, getState) => {
        const list = getState().captureQuotation.accessory_list
        const newList = [...list]

        newList.push({
            accessory_name: null,
            accessory_id: null,
            rateperday: null,
            ratepermonth: null,
            totalprice: null,
            totalincome: null
        })

        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
            payload: { prop: 'accessory_list', value: newList }
        })
    }
}

export const setAccesoryList = (data) => {
    return (dispatch, getState) => {
        const list = getState().captureQuotation.accessory_list
        const newList = [...list]

        let pmt_rv = data.value?.pmt_rv ?? 0
        let totalprice = data.value?.totalprice ?? 0
        let pmt_period = data.value?.pmt_period ?? 0
        let pmt_interestrate = data.value?.pmt_interestrate ?? 0

        let ratepermonth = formulas.PMT(((parseFloat(pmt_interestrate ? pmt_interestrate : 0) / 100) / 12), (pmt_period ? pmt_period : 0), - (totalprice ? totalprice : 0), (pmt_rv ? pmt_rv : 0), 0).toFixed(2)

        newList[data.inx].accessory_id = data.value?.value ?? null
        newList[data.inx].ratepermonth = ratepermonth !== 'NaN' ? ratepermonth : ''
        newList[data.inx].accessory_name = data?.text ?? ''
        newList[data.inx].totalprice = data.value?.value ? totalprice : ''

        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
            payload: { prop: 'accessory_list', value: newList }
        })
    }
}

export const removeSelectedAccessory = (data, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
            payload: { prop: 'gridCounter', value: getState().captureQuotation.gridCounter + 1 }
        })

        const list = getState().captureQuotation.accessory_list;
        const newList = [...list.slice(0, inx), ...list.slice(inx + 1)];

        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
            payload: { prop: 'accessory_list', value: newList }
        })
    };
};

export const calTotals = () => {
    return (dispatch, getState) => {
        let _rental = parseFloat(getState().strCreateQuote.rentalamount.value)
        let _roi = parseFloat(getState().strCreateQuote.roi.value)
        let _loadfactorperc = parseFloat(getState().strCreateQuote.loadfactorperc.value)
        let _accessory_list = getState().strCreateQuote.accessory_list
        let _totalaccessory = _.sumBy(_accessory_list, (d) => { return parseFloat((d.ratepermonth) ? d.ratepermonth : 0) }).toFixed(2)

        //console.log('>>>>>>>>>>>>>>>>> calTotals')
        //console.log(_totalaccessory)
        //console.log(_rental)
        //console.log(_roi)
        //console.log(_rental + _roi)
        let __stramount = (_rental + _roi + parseFloat(_totalaccessory)).toFixed(2)


        let _loadfactoramount = (__stramount * (_loadfactorperc / 100)).toFixed(2)
        let _total = (parseFloat(__stramount) + parseFloat(_loadfactoramount)).toFixed(2)

        //console.log(_total)
        dispatch(setInputValue({ prop: 'monthlyexc', value: _total }))
        dispatch(setInputValue({ prop: 'monthlyvat', value: (parseFloat(_total) * 0.15).toFixed(2) }))
        dispatch(setInputValue({ prop: 'monthlyinc', value: (parseFloat(_total) * 1.15).toFixed(2) }))
        dispatch(setInputValue({ prop: 'loadfactoramount', value: _loadfactoramount }))
    }
}

export const calWorkings = () => {
    return (dispatch, getState) => {
        let _retailprice = parseFloat(getState().strCreateQuote.retailprice.value)
        let _discount = parseFloat(getState().strCreateQuote.discount.value)
        let _primerate = parseFloat(getState().strCreateQuote.primerate.value)
        let _diviation = parseFloat(getState().strCreateQuote.diviation.value)
        let _period = parseInt(getState().strCreateQuote.contractperiod.value)
        let _strrate = parseFloat(getState().strCreateQuote.strrate.value)
        let _roipercentage = parseFloat(getState().strCreateQuote.roipercentage.value)
        let _loadfactorperc = parseFloat(getState().strCreateQuote.loadfactorperc.value)
        let _accessory_list = getState().strCreateQuote.accessory_list

        let _adminfee = parseFloat(getState().strCreateQuote.adminfee.value)
        let _licensefee = parseFloat(getState().strCreateQuote.licensefee.value)

        let _rv = parseFloat(getState().strCreateQuote.rv.value)

        //console.log(_accessory_list)

        let _totalaccessory = _.sumBy(_accessory_list, (d) => { return parseFloat((d.ratepermonth) ? d.ratepermonth : 0) }).toFixed(2)

        //console.log(_totalaccessory)

        let _interestrate = _primerate && _diviation ? (_primerate + _diviation).toFixed(2) : 0
        let _vehicleprice = (_retailprice - _discount).toFixed(2)

        let _rvamount = _rv && _retailprice ? (_retailprice * (_rv / 100)).toFixed(2) : 0
        let _nett = _vehicleprice

        //console.log('>>>>>>>>>>>>>> PMT')
        //console.log(_primerate)
        //console.log(_period)
        //console.log(_nett)
        //console.log(_rvamount)

        let _fin = formulas.PMT(((parseFloat(_interestrate ? _interestrate : 0) / 100) / 12), (_period ? _period : 0), -(_nett ? _nett : 0), (_rvamount ? _rvamount : 0), 0).toFixed(2)
        let _finance = parseFloat(_fin) && isFinite(_fin) ? parseFloat(_fin) : 0

        //console.log('>>>>>>>>>>>>>> _finance')
        //console.log(_fin)
        //console.log(_finance)

        let _sub_total = _finance + _adminfee + _licensefee

        let _roi = (_sub_total * (_roipercentage / 100)).toFixed(2)
        //console.log('>>>>>>>>>>>>>> _sub_total')
        //console.log(_sub_total)
        //console.log(_roi)

        let _workingsubtotal = (parseFloat(_sub_total) + parseFloat(_roi)).toFixed(2)
        let _workingvat = (_workingsubtotal * 0.15).toFixed(2)
        let _workingrental = (_workingsubtotal * 1.15).toFixed(2)
        let __stramount = (_workingsubtotal * _strrate).toFixed(2)
        let _loadfactoramount = (__stramount * (_loadfactorperc / 100)).toFixed(2)
        let _stramount = (parseFloat(__stramount) + parseFloat(_loadfactoramount)).toFixed(2)

        dispatch(setInputValue({ prop: 'rvamount', value: _rvamount }))
        dispatch(setInputValue({ prop: 'interestrate', value: _interestrate }))
        dispatch(setInputValue({ prop: 'vehicleprice', value: _vehicleprice }))
        dispatch(setInputValue({ prop: 'finance', value: _finance }))
        dispatch(setInputValue({ prop: 'roi', value: _roi }))
        dispatch(setInputValue({ prop: 'workingsubtotal', value: _workingsubtotal }))
        dispatch(setInputValue({ prop: 'workingvat', value: _workingvat }))
        dispatch(setInputValue({ prop: 'workingrental', value: _workingrental }))
        dispatch(setInputValue({ prop: 'stramount', value: _stramount }))
        dispatch(setInputValue({ prop: 'totalaccessory', value: _totalaccessory }))
        dispatch(setInputValue({ prop: 'loadfactoramount', value: _loadfactoramount }))
    }
}

export const getStartEndDate = (period) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({ prop: 'startdate', value: moment().format('YYYY-MM-DD') }))
        dispatch(setInputValue({ prop: 'enddate', value: moment().add(period, 'months').format('YYYY-MM-DD') }))
    }
}

export const calcRIO = (rental_amount, roi_perc) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({ prop: 'roi', value: (rental_amount * (roi_perc / 100)) }))
    }
}
export const setLookupValue = (prop, data, set = false) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
            payload: {
                value: data,
                prop: prop
            }
        })


        if (set && getState().strCreateQuote.customer_data?.length) {
            let _data = getState().strCreateQuote.customer_data[0].customerderivative
            let derivative = _data?.length ? _data[0] : null

            //console.log(derivative)
            //console.log(derivative.perioddefault)
            if (derivative) {
                dispatch(setInputValue({ prop: 'contractperiod', value: derivative.perioddefault }))
                dispatch(setInputValue({ prop: 'roipercentage', value: derivative.roiperc }))
                dispatch(setInputValue({ prop: 'monthlyinclusive', value: derivative.distancedefaultdiesel }))
                dispatch(setInputValue({ prop: 'adminfee', value: derivative.adminfee }))
                dispatch(setInputValue({ prop: 'licensefee', value: derivative.licencefee }))
                dispatch(setInputValue({ prop: 'loadfactorperc', value: derivative.loadfactor }))
                dispatch(getStartEndDate(derivative.perioddefault))
            }
        }
    }
}

export const getLookupValueData = (table, field, search, related = '') => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CREATE_STR_DATA_FIELD_INPUT_LIST,
            payload: {
                table: table,
                field: field,
                search: search,
                related: related
            }
        })
    }
}

export const getRateCard = (table, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CREATE_STR_RATECARD_LIST,
            payload: {
                table: table,
                field: field,
                search: search
            }
        })
    }
}


export const getRateCardVehicles = (table, field, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CREATE_STR_RATECARD_VEHICLE_LIST,
            payload: {
                table: table,
                field: field,
                search: search
            }
        })
    }
}

export const setRateCardVehicles = (payload) => {
    console.log('setRateCardVehicles',payload)
    return (dispatch) => {

        let data = payload.data.map(x => {
            return {
                ...x,
                text: x.strratecard,
                value: x.strratecard_id
            }
        })
        console.log('setRateCardVehicles data',data)
        dispatch(setPropValue('strratecards',data),)
        let _data = data.map(x => {return {strratecard: x.strratecard, vehicle_class: x.vehicle_class}})
        console.log('setRateCardVehicles _data',_data)
        data = _.uniqBy(_data, 'strratecard') ///_.uniq(_data)
        console.log('setRateCardVehicles strratecard',data)

        dispatch(setPropValue('strratecard_data', data))
    }
}

export const setRateCard = (response, payload) => {
    const findUnique = (arr, predicate) => {
        var found = {};
        arr.forEach(d => {
            found[predicate(d)] = d;
        });
        return Object.keys(found).map(key => found[key]);
    }

    return (dispatch, getState) => {
        //console.log('setRateCard',response.data)

        let data = response.data.map(x => {
            return {
                text: x[payload.field],
                value: x[payload.table + '_id']
            }
        })
        data = findUnique(data, d => d.text)
        //console.log('setRateCard data',data)
        dispatch({
            type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
            payload: {
                value: data,
                prop: 'ratecategory_list'
            }
        })
    }
}




// export const setSelectedRateCard = (response, payload) => {
//     return (dispatch, getState) => {
//         //console.log('setRateCard',response.data)

//         let data = response.data.map(x => {
//             return {
//                 ...x,
//                 text: x[payload.field],
//                 value: x[payload.table + '_id']
//             }
//         })

//         //console.log('setRateCard data',data)
//         dispatch({
//             type: types.SET_STR_COMPONENT_INPUT_FIELD_DATA_LIST,
//             payload: {
//                 value: data,
//                 prop: 'ratecategory_list'
//             }
//         })
//     }
// }

export const setLookupValueData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setLookupValueData response', response)
        //console.log('setLookupValueData payload', payload)
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.table + '_id']
            }
        })

        //console.log('setLookupValueData data', data)

        dispatch(setInputValue({ prop: payload.table + '_data', value: data }))
        // dispatch({
        //     type: types.SET_STR_COMPONENT_INPUT_FIELD_VALUE,
        //     payload: {
        //         value: data,
        //         prop: payload.table + '_data'
        //     }
        // })
    }
}

export const downloadWorking = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT,
            payload: {
                type: 'STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT',
                data: data
            }
        })
    }
}

export const saveSupplier = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.STR_COMPONENT_SAVE_SUPPLIER,
            payload: {
                type: 'str_save_supplier',
                data: getState().captureQuotation
            }
        })
    }
}

export const saveSupplierDone = () => {
    return (dispatch, getState) => {
        dispatch(setInputValue({ prop: 'saving_supplier', value: false }))
        dispatch(setInputValue({ prop: 'add_customer_win', value: false }))

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Customer saved successfully ',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const viewPdfDocument = (data) => {
    const getFile = () => {
        const blob = new Blob([data.data.content.Body], { type: 'octet/stream' })
        return window.URL.createObjectURL(blob)
    }

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()
            window.URL.revokeObjectURL(url)
        }
    }())

    window.glyco.log('data.data.document')
    window.glyco.log(data.data)

    saveByteArray([data.data.content.Body], 'STR Workings Preview.pdf')

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_STR_COMPONENT_DOWNLOAD_PDF_WORKINGS_DOCUMENT,
            payload: {
                url: data.data.document.key
            }
        })
    }
}