import * as validatorTypes from '../Validator/types'

export const fields = {
    code: {
        id: 'code',
        name: 'code',
        errorText: '',
        hintText: 'Example: 123456',
        floatingLabelText: 'Code',
        input: '',
        type: 'text',
        valid: false,
        empty: true,
        validators: [validatorTypes.IS_NOT_EMPTY, validatorTypes.IS_NUMERIC],
    }
}

export const valid = false