import * as actions from './actions'
import * as teamActions from '../Teams/actions'
import * as clientActions from '../Clients/actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import { cognitoUser } from '../../cognito'
import * as config from '../../config'


export const getSubAccount = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_SUB_ACCOUNT_ACCOUNT_DATA),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/sub_account/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
            .pipe(
                mergeMap(payload => [
                    //actions.setSubAccount(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/sub_account/query')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/sub_account/query', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
        
export const saveSubAccount = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.SAVE_SUB_ACCOUNT_COMPONENT_CUSTOMER_DATA),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/sub_account/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(payload => [
                    actions.saveSubAccountDone(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/sub_account/query')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/sub_account/query', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
        
        
export const getComponentFieldLookupData = (
    action$,
    store,
    { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
  ) =>
    action$.pipe(
      ofType(types.GET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA),
      delay(0),
      mergeMap(({ payload }) =>
        ajax$({
          url:
            config.system.api +
            "/components/" +
            payload.field.component_reference.name +
            "/search" +
            payload.query,
          method: "GET",
          headers: {
            tokenx: store.value.user.user_id,
            auth: store.value.cognito.authenticateUser.token,
            salt: store.value.cognito.authenticateUser.jwt,
            datetime: store.value.cognito.authenticateUser.datetime,
            "client-id": store.value.accounts.selectedAccount,
            related: "", //payload.related
            order: payload.field.search ? payload.field.search : "",
          },
        }).pipe(
          mergeMap((result) => [
            actions.setComponentFieldLookupData(
              payload.component,
              payload.field,
              result.response
            ),
            progressBarActions.progress(false),
            requestActions.requestSucceeded(
              config.system.api +
                "/components/" +
                payload.field.component_reference.name +
                "/search" +
                payload.query
            ),
          ]),
          //,startWith(progressBarActions.progress(true))
          catchError((error) =>
            of$(
              requestActions.requestFailed(
                config.system.api + "/components/component?client_id=2&menu=true",
                error
              )
            )
          ),
          //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
      ),
      catchError((error) => of$(routerActions.route("/support", false, error)))
    );
  