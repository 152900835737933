import * as types from './types'
import * as appTypes from '../App/types'

import * as componentActions from '../Components/actions'

import moment from 'moment'


export const setComponentFieldInput = (field, input) => {
    //console.log('setComponentFieldInput',field, input)
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = input ? format(field, input) : ''

        dispatch({
            type: types.SET_LICENSEE_COMPONENT_FIELD_INPUT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                validation: true // validate(component.schema, field, value)
            }
        })
    }
}
export const loadLicenseeAccountData = () => {
    return (dispatch, getState) => {
        dispatch(componentActions.hardReloadComponent('licensee'))
        // dispatch({
        //     type: types.GET_LICENSEE_ACCOUNT_DATA
        // })
    }
}
export const changeTabView = (tab) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_LICENSEE_ACCOUNT_CURRENT_TAB,
            payload: tab
        })
    }
}

export const setLicenseeAccount = (payload) => {
    //console.log('setLicenseeAccount',payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_LICENSEE_ACCOUNT_DATA,
            payload: payload
        })
    }
}

export const saveLicenseeDataDone = (payload) => {
    //console.log('saveLicenseeDataDone',payload)
    return (dispatch, getState) => {
        dispatch(componentActions.hardReloadComponent('licensee'))
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'License account save',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(changeTabView(0))
    }
}

export const saveLicenseeData = (component, user) => {
    //console.log('component',component)
    const getData = () => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name]) {
                if (x.key) {
                    if (component.data[x.name].input !== '') {
                        obj[x.name] = component.data[x.name].input
                    }
                }
                else {
                    obj[x.name] = component.data[x.name].input !== '' ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }

    return (dispatch, getState) => {
        let data = getData()
        
        //console.log('SAVE_LICENSEE_COMPONENT_CUSTOMER_DATA data',data)
        dispatch({
            type: types.SAVE_LICENSEE_COMPONENT_CUSTOMER_DATA,
            payload: {
                licensee: {...data, client_id: 2 }, 
                user: user,
                type: 'crud_licensee'
            }
        })
    }
}
