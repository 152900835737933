import React, { useState,useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent, TextField, Button, IconButton, Dialog, MenuItem, Menu, Select, Stack } from '@mui/material'
import { emphasize, styled, alpha } from '@mui/material/styles';
import {ReactComponent as VisibilityIcon} from '../../assets/img/svg/icons/view_grid.svg';
import {ReactComponent as EditIcon} from '../../assets/img/svg/icons/edit_grid.svg';
import {ReactComponent as DeleteForeverIcon} from '../../assets/img/svg/icons/delete_forever_grid.svg';
import {ReactComponent as SettingsIcon} from '../../assets/img/svg/icons/Configuration-data.svg';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import RelatedForm from '../../components/RelatedForm/component'
import Checkbox from '@mui/material/Checkbox'

import AutoComplete from '../../controls/autocomplete'

import ReactTable from "react-table"
import "react-table/react-table.css"

import Icon from '../Icon/component'
import Loading from '../Loading/component'
import * as gridAction from '../Grid/actions'

import * as actions from './actions'
import * as styles from './styles'

import { Tooltip, Box } from '@mui/material';
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    gridClasses,
    useGridApiRef
  } from '@mui/x-data-grid-premium';
  
  import { LicenseInfo } from '@mui/x-license-pro';
  import * as config from '../../config'
  
  
  LicenseInfo.setLicenseKey('0dc2c32f83edb803ade140958edb564aTz04MjQzNSxFPTE3MzcxOTI2NzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: 'red',//theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
  }));

  const CustomNoRowsOverlay = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">No Rows</Box>
    );
  }

  const CustomNoResultsOverlay = () =>{
    return(
    <Box display="flex" justifyContent="center" alignItems="center">No Results</Box>
    );
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton width={'200px'} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

//class RelatedGridView extends Component {
    
 function RelatedGridView(props) { 
    const apiRef = useGridApiRef();
    
    useEffect(() => {
        //props.actions.loadComponentData(props.component)
        if(props.model[props.fieldname]) {
        props.actions.loadComponentData(props.model[props.fieldname].component)
        props.actions.getRelatedGridData(props.model[props.fieldname])
        props.actions.setComponentInput({prop: props.fieldname, value: props.model[props.fieldname]})
        props.actions.setComponentInput({prop: 'edit_row_inx', value: -1})
        props.actions.setComponentInput({prop: 'counter', value: 0})
        }
      }, [])


    const getHeader = (row)  =>  {
        const fields = props.state[props.component].component_field.filter(x => x.grid === true)
        //console.log('fields', props.state[props.component].component_field.filter(x => x.grid === true))
        let headers = fields.map(x => {
          return x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer'
          ?  {
              field: x.name, 
              headerName: x.title, 
              headerClassName: 'datagrid_header',
              flex: 1,
              editable: false,
              filterable: true,
              //type: 'number',
              //align: "center",
              renderCell: (params) => {
                let _value = x.display ? params.row[x.display.split('.')[0]] ? params.row[x.display.split('.')[0]][x.display.split('.')[1]] : '' : params.row[x.name]
                switch (x.component_field_data_type.name) {
                    case 'timestamp':
                        _value =  _value ? moment(_value).format('YYYY-DD-MM hh:mm') : ''
                        break;
                }
                if(x.display ) {
                  //console.log('params.rowx', params.row)
                  //console.log('params.rowx display',x.display)
                  //console.log('params.rowx x',x)
                  //console.log('params.rowx v',_value)
                }
                return _value // ? _value.replace('.',',') : 0 //<div className="rowitem">{_value}</div>;
              },
          }
          : {
              field: x.name, 
              headerName: x.title, 
              headerClassName: 'datagrid_header',
              flex: 1,
              editable: false,
              renderCell: (params) => {
                let _value = x.display ? params.row[x.display.split('.')[0]] ? params.row[x.display.split('.')[0]][x.display.split('.')[1]] : '' : params.row[x.name]
                switch (x.component_field_data_type.name) {
                      case 'timestamp':
                          _value = _value ? moment(_value).format('YYYY-MM-DD') : ''
                          break;
                      case 'date':
                        //console.log('date _value', moment(_value).format('YYYY-MM-DD'))
                          _value = _value ? moment(_value).format('YYYY-MM-DD') : ''
                          break;
                      case 'boolean':
                          _value = _value == null ? '' : _value ? 'Yes' : 'No'
                          break;
                          default: 
                          _value = _value
                          break;

                  }
                  if(x.display ) {
                    //console.log('params.rowx', params.row)
                    //console.log('params.rowx display',x.display)
                    //console.log('params.rowx x',x)
                    //console.log('params.rowx v',_value)
                  }
                return _value //<div className="rowitem">{_value}</div>;
              },
          }
      })
      return headers
    }

    const getColumns = ()  =>  {
        return  [{
              field: 'action',
              headerName: 'Action',
              headerClassName: 'datagrid_header',
              sortable: false,
              filterable: false,
              disableExport: true,
              width: props.state.name !== 'customercontract' && props.state.name !== 'supplier' ? 150 : 60,
              renderCell: (params) => {
                //console.log('params', params);
                return params.rowNode.type === 'leaf' ? 
                <Stack direction="row" alignItems="center" spacing={0}>
              {
                <Tooltip title="Edit Card" placement="bottom">
                <IconButton color="primary" aria-label="upload picture" component="div" onClick={() => {
                    //console.log('___paramsx', params, '/view//' + params.id)
                    //console.log('___paramsx row',  params.row)
                    //console.log('___paramsx id',  params.row[props.component + '_id'])
                    //console.log('___paramsx component', props.component)
                    props.actions.toggleCrudView(true, params.row[props.component + '_id'], props.component)
                }}>
                <EditIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
              </IconButton>
              </Tooltip>
              }
              {
                <Tooltip title="Delete" placement="bottom">
                <IconButton color="primary" aria-label="upload picture" component="div" onClick={(event) => {
                  //console.log('delCustomerContactx row',params.row)
                  //console.log('delCustomerContactx prop',props.fieldname)
                  //console.log('delCustomerContactx com',params.row[props.fieldname], props.model[props.fieldname])
                  props.actions.delCustomerContact(params.row[props.fieldname], props.model[props.fieldname])
                }}>
                <DeleteForeverIcon width={20} height={20} style={styles.gridIconsTheme(config.system.clientTheme)}/>
              </IconButton>
              </Tooltip>
              }
              </Stack> : <></>
            }
        }].concat(getHeader()) //state.component.grid.headers)
    }


    const getData = ()  =>  {
        let data = props.state[props.component+"_data"] ? props.state[props.component+"_data"] : []
        return data
    }

    const getLoading = ()  =>  {
        let data = props.state[props.component+"_data"] ? props.state[props.component+"_data"] : []
        return data.length ? false : true
    }

    const getGrid = ()  =>  {
        return <div>
            {
                props.readonly ? '' : <Button variant="contained"
                    label={'Add Item'}
                    //className="d-0 py-0 px-0 w-auto mx-0 mr-0 mr-lg-0 mx-lg-3"
                    primary={true}
                    onClick={(event) => {
                        props.actions.toggleCrudView(true, null, props.component)
                        //props.actions.addCustomerContact(props.component + "_data", props.component + "_id", props.model)
                    }}
                >{window.t.en('Add Item')}</Button>
            }
            <br />
            <br />
<StripedDataGrid 
                        key={props.component}
                        //getRowId={(x, i) =>  {x[props.component + '_id']}}
                        getRowId={(x) =>  x[props.component + '_id']}
                        rows={getData()} 
                        rowHeight={38}
                        //loading={getLoading()}
                        // columnVisibilityModel={{card_id: false}}
                        columns={getColumns()}
                        //slots={{ toolbar: CustomToolbar, noRowsOverlay: CustomNoRowsOverlay, noResultsOverlay: CustomNoResultsOverlay }}
                        // onPaginationModelChange={(event) => {
                        //     props.actions.getComponentGridData(props.state.component, null, {...event,sorted: []}, null, null)
                        // }}
                        //pageSize={props.state[props.component+"_data"] ? props.state[props.component+"_data"].length : 0}
                        //rowsPerPageOptions={[5, 10, 20]}
                        //paginationMode={"server"}
                        //rowCount={props.state[props.component+"_data"] ? props.state[props.component+"_data"].length : 0}
                        pagination getRowClassName={(params) => {
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }}
                        //filterMode={'server'}
                        // onFilterModelChange={(event, model) => {
                        //   //console.log('eventx filter',event.items)
                        //   props.actions.filterInProgress(event.items)
                        //   props.actions.getComponentGridData(props.state.component, null, {...event,sorted: []}, event.items, null)
                        // }}
                        autoHeight={true}
                        disableColumnFilter
                        // unstable_headerFilters
                        // initialState={{
                        //     pagination: { paginationModel: { ...state.component.grid.paging } },
                        //     rowGrouping: {
                        //         model: [state.component.group],
                        //     },
                        //     // columns: {
                        //     //   columnVisibilityModel: { [state.component.group]: false, action: true},
                        //     // },
                        //     aggregation: {
                        //         model: { [state.component.aggregate]: 'sum' },
                        //     },
                        // }}
                        // groupingColDef={{
                        //  leafField: 'action'
                        // }}
                        //experimentalFeatures={{ aggregation: true, lazyLoading: true }}
                        // onExcelExportStateChange={(inProgress) => { 
                        //   //console.log('inProgress', inProgress)
                        //   if(inProgress === 'finished'){
                        //     props.actions.exportInProgress(false)
                        //   }else{
                        //     props.actions.exportInProgress(true)
                        //   }
                        // }}
                        apiRef={apiRef}
                    /> 

        </div>
    }
    
    const getLayout = ()  =>  {
        return 1 !== 1
            ? <Loading message={'Loading....'} />
            : <Card initiallyExpanded={true}>
                <CardHeader
                    avatar={<Icon iclass={'custom-icons'} iname={'organization'} />}
                    title={'Sub Accounts '}
                    subtitle={'Manage Sub accounts.'}
                />
                <CardContent expandable={false}>
                   {/* { getGrid() } */}
                </CardContent>
            </Card>
    } 

    const getCrud = ()  =>  {
        return <div>
        {
            props.state.cudview
            ? <Dialog
                open={props.state.cudview}
                style={{ width: '100% !important', maxWidth: '100% !important' }}
                fullWidth={true}
                maxWidth="lg"
                >
                <DialogTitle id="scroll-dialog-title">{props.state.cudview_title.toUpperCase()}</DialogTitle>
                <DialogContent dividers={true}>
                    {
                        props.state.cudview_title
                        ? <RelatedForm
                            key={'mastercomponentview_create_' + props.cudview_title + '_' + props.state.cudview_id}
                            name={props.state.cudview_title}
                            id={props.state.cudview_id}
                            modelx={props.model[props.fieldname]}
                        />
                        : <></>
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="secondary" 
                        className="btn-warning d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
                        onClick={(event) => {
                            event.preventDefault();
                            props.actions.toggleCrudView(false, null, null)
                            //props.actions.toggleInfoView(false,null,null,null)
                        }}>
                        Close
                    </Button>
                    <Button 
                        color="secondary" 
                        className="btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
                        onClick={(event) => {
                            event.preventDefault();
                            props.actions.saveComponentData(props.state[props.state.cudview_title + "_id"], props.state)
                        }}>
                        Save
                    </Button>
                </DialogActions>
            
            </Dialog>
            : 
            <></>
        }
    </div> 
    }

    return (
        <>
         {
            props.state[props.component].component_field
                ? [getGrid(),getCrud()]
                : <Loading message={'Loading component...'} />
         }
      </>
      )
    // render() {
    //     //console.log('props.state[props.component].component_field', props.state[props.component].component_field)
    //     return props.state[props.component].component_field
    //             ? [getGrid(),getCrud()]
    //             : <Loading message={'Loading component...'} />
    // }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.relatedGrid,
            [ownProps.component]: {
                ...state.components[ownProps.component]
            },
            component: { ...state.components[ownProps.name] }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...gridAction }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedGridView)