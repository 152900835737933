import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_STR_COMPONENT_INPUT_FIELD_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.STR_COMPONENT_SET_CURRENT_TAB_VIEW:
            return {
                ...state,
                tab: action.payload
            }
        case types.STR_COMPONENT_RESET_FORM:
            return {
                gridCounter: 0,
                additional: true,
                insurance: true,
                add_customer_win: false,
                quotationnotes: '',
                vehicle_id: null,
                supplier_id: null,
                strratecard_id: null,
                transmission_id: null,
                contractunit_id: null,
                customer_id: null,
                waiver_id: null,
                delivery_address: {},
                collection_address: {},
                delivery_kms: null,
                collection_kms: null,
                delivery_cost: 0,
                collection_cost: 0,
                period: '',
                distance: '',
                excess: '',
                startdate: '',
                enddate: '',
                vehicle: '',
                supplier: '',
                strratecard: '',
                transmission: '',
                contractunit: '',
                customer: '',
                waiver: '',
                waiver_code: '',
                strratecards: [],
                supplier_data: [],
                vehicle_data: [],
                customer_data: [],
                transmission_data: [],
                contractunit_data: [],
                ratecategory_data: [],
                straccessory_data: [],
                collection_data: [],
                delivery_data: [],
                waiver_data: [],
                delivery: [],
                collection: [],
                accessory_list: [],
                strratecard_data: [],
                monthly_rental: 0.00,
                str_monthly_rental: 0.00,
                accessory_tot_daily: 0.00,
                accessory_tot_monthly: 0.00,
                str_daily_rental: 0.00,
                accessory_tot: 0.00,
                waiver_tot: 0.00,
                insurance_tot: 0.00,
                str_customer_selected: {},
                monthly_excl: 0.00,
                monthly_vat: 0.00,
                monthly_incl: 0.00,
                fields: props.fields
            }
        default:
            return state
    }
}