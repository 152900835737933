import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Icon from '../Icon/component'
import BusinessIntelligence from '../../components/BusinessIntelligence/component'
import GeneralContentView from '../../views/GeneralContentView/component'

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import * as actions from './actions'
import * as styles from './styles'

import * as accountActions from '../Account/actions'

class Orginisation extends Component {

      saveSelectValue = (e, id) => {
        let client = {}
        client.id = id;
        client.value = e.target.value
      }
    render() {
        // const selectedValue = selection.value || '';
        // const id = selection.id;

        return (
            <GeneralContentView
                content={
                    <Card >
                        <CardContent  style={styles.cardDropdown}>
                        <FormControl variant="outlined"  style={styles.MuiFormControl}>
                            <InputLabel htmlFor="outlined-age-native-simple">Please select a orginisation</InputLabel>
                            <Select
                                // key={id}
                                native 
                                label="Orginisation"
                                value={this.props.state.selectedAccount}
                                inputProps={{
                                    name: 'title',
                                    id: 'client_id',
                                }}
                                onChange = {(event,value) => {this.props.actions.setSelectedAccount(event.target.value)}}
                            >
                                {
                                    this.props.state.accounts.map(x => {return <option value={x.client_id}>{x.title}</option>})
                                }

                            </Select>
                        </FormControl>
                        </CardContent>
                    </Card>

                }
                headerImage={''}
                title={window.t.en('Orginisation')}
                contentHeight={'750px'}
            />
           

        )
    }
}

const mapStateToProps = (state) => {
    return {
                            state: {...state.accounts}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
                            actions: bindActionCreators({...actions, ...accountActions}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orginisation)