import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent, Table, IconButton } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import ReactQuill from 'react-quill'

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import JSReport from '../components/JSReport/component'
import MinMap from '../components/MinMap/component'

import CardControl from './card'

import * as actions from './actions'
import * as styles from './styles'
import * as config from '../config'

class ViewVehicle extends Component {
    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm')
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }

    
    getLabel(label, value, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                <Typography variant="body1" component="div"
                    style={{  fontWeight: 'bold',fontSize: '14px' }}>
                    {this.getField(value, type)}
                </Typography>
            }
            label={
                <label style={{ width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                    {window.t.en(label)}:
                </label>
            }
            labelPlacement="start"
        />
    }

    getPlanView() {
        //console.log('getPlanView >>>>>>>>>>>>>> ', this.data)
        //console.log('getPlanView vehiclestatus >>>>>>>>>>>>>> ', this.data.vehiclestatus)
        return <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row className={"custom_view_text"}>
                            <Col xl={4}>
                                {this.getLabel('Vehicle Status', this.data.vehiclestatus ? this.data.vehiclestatus.vehiclestatus : '', 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Vehicle Odometer', this.data.closingodometer, 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Vehicle Make', this.data.mmcode.make, 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Vehicle Model', this.data.mmcode.makemodelyear, 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Vehicle MMCode', this.data.mmcode.mmcode, 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Fuel Type', this.data.mmcode.fueltype, 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Warranty Details', this.data.mmcode.warranty, 'text')}
                            </Col>
                            {/* <Col xl={4}>
                                {this.getLabel('Service Plan', this.data.mmcode.serviceplan, 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Warranty Distance', this.data.mmcode.warrantykm, 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Service Plan Distance', this.data.mmcode.serviceplandistance, 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Warranty Period', this.data.mmcode.warrantyplan, 'text')}
                            </Col>
                            <Col xl={4}>
                                {this.getLabel('Service Plan Period', this.data.mmcode.serviceplanperiod, 'text')}
                            </Col> */}
                        </Row>
                    </Container>
            </div>
    }

    getVehicleLayout() {
        return <div>
            {this.getPlanView()}
         </div> 
    }

    render() {
        this.data = {}
        try {
            this.data = this.props.state
        } catch (error) {

        }
        return this.data.mmcode ? this.getVehicleLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.controls.vehicle,
        }
    }
}

export default connect(mapStateToProps)(ViewVehicle)