import * as types from './types'
import * as teamManagerTypes from '../components/TeamManagement/TeamManager/types'
import moment from 'moment'
import * as quoteItems from '../components/WorkflowPOHeader/QuoteItems/actions'

export const onInfo = (info) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_AUTOCOMPLETE_INFO_SHEET,
            payload: info
        })
    }
}

export const getAutoCompleteDate = (data) => {
    return (dispatch, getState) => {
        //console.log('setAutoCompleteDatex get', data)
        dispatch({
            type: types.GET_CONTROL_VIEW_FORM_AUTOCOMPLETE_DATA,
            payload: data
        })
    }
}

export const setAutoCompleteDate = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setAutoCompleteDatex response', response)
        //console.log('setAutoCompleteDatex payload', payload)
        dispatch({
            type: types.SET_CONTROL_VIEW_INPUT_DATA,
            payload: {
                localstate: payload.localstate,
                prop: payload.datasource + '_data',
                data: response.data.map(x => {
                    return {...x,text: x[payload.text],value: x[payload.value]}
                })
            }
        })
    }
}

export const setAutoCompleteSelected = (field, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTROL_VIEW_INPUT_DATA,
            payload: {
                localstate: field.localstate,
                prop: field.datasource,
                data: data
            }
        })
    }
}



