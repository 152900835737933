import * as actions from "./actions";
import * as progressBarActions from "../ProgressBar/actions";
import * as requestActions from "../Request/actions";
import * as routerActions from "../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../Request/types";
import * as config from "../../config";

export const configureCognitoEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.SUBMIT_COGNITO_USER),
    //.debounceTime(500)
    mergeMap(({ payload }) => [actions.getUserAttributes(payload)]),
    startWith(progressBarActions.progress(false)),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const createCognitoUserEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.COGNITO_CONFIGURATION_SUCCEEDED),
    mergeMap(
      ({ payload }) =>
        of$(
          actions.createCognitoUser(
            store.value.cognito.userPool,
            store.value.cognito.signUp.fields.email.input,
            store.value.cognito.signUp.fields.password.input,
            store.value.cognito.signUp.attributes
          )
        ),
      startWith(progressBarActions.progress(true)),
      catchError((error) => of$(routerActions.route("/support", false, error)))
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const getCurrentCognitoUser = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) =>
  action$.pipe(
    ofType( types.COGNITO_USER_AUTHENTICATION_SUCCEEDED, types.GET_COGNITO_USER_SESSION_SUCCEEDED ),
    mergeMap(({ payload }) =>
        of$(actions.getCurrentCognitoUser(payload.refresh))
      //startWith(progressBarActions.progress(false)),
      //catchError((error) => {
      //  //console.log(error);
      //}) //  of$(routerActions.route("/support", false, error)))
    ),
    catchError((error) => {
      //console.log(
      //   "#################################################################################################",
      //   error
      // );
    })
    // of$(routerActions.route('/support', false, error)))
  );

export const confirmRegistrationEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.SUBMIT_REGISTRATION_CONFIRMATION),
    //.debounceTime(500)
    mergeMap(
      () =>
        of$(
          actions.confirmRegistration(
            store.value.cognito.signUp.cognitoUser,
            store.value.cognito.confirmRegistration.fields.code.input
          )
        ),
      startWith(progressBarActions.progress(true)),
      catchError((error) => of$(routerActions.route("/support", false, error)))
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const registrationConfirmedEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.REGISTRATION_CONFIRMATION_SUCCEEDED),
    mergeMap(
      (action) => of$(routerActions.route("/")),
      startWith(progressBarActions.progress(false)),
      catchError((error) => of$(routerActions.route("/support", false, error)))
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const resendRegistrationConfirmationCodeEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.SUBMIT_RESEND_REGISTRATION_CONFIRMATION_CODE),
    //.debounceTime(500)
    mergeMap(
      (action) =>
        of$(
          actions.resendConfirmationCode(
            store.value.cognito.signUp.cognitoUser,
            store.value.cognito.resendConfirmationCode.resendsLeft
          )
        ),
      startWith(progressBarActions.progress(true)),
      catchError((error) => of$(routerActions.route("/support", false, error)))
    ),
    startWith(progressBarActions.progress(false)),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const startCognitoUserRegistrationEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.START_COGNITO_USER_REGISTRATION),
    mergeMap(() => routerActions.route("/signup"))
  );

export const startCognitoUserAuthenticationEpic = (
  action$,
  store,
  { concat$, of$, ofType, catchError, switchMap }
) =>
  action$.pipe(
    ofType(types.SUBMIT_COGNITO_USER_AUTHENTICATION),
    switchMap((action) => [
      actions.getCognitoUserAuthenticationDetails(
        store.value.login.fields.email.input,
        store.value.login.fields.keyword.input
      ),
      //actions.getCognitoUserData(store.value.login.fields.email.input)
    ]),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const setCognitoUserDataEpic = (
  action$,
  store,
  { concat$, of$, mergeMap, ofType, catchError }
) =>
  action$.pipe(
    ofType(types.GET_COGNITO_USER_DATA),
    mergeMap((action) => [
      actions.getCognitoUser(),
      //actions.sendCognitoUserAuthenticationRequest()
    ]),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const authenticateCognitoUserEpic = (
  action$,
  store,
  { concat$, of$, mergeMap, ofType, catchError }
) =>
  action$.pipe(
    ofType(types.SEND_COGNITO_USER_AUTHENTICATION_REQUEST),
    mergeMap(
      (action) => of$(actions.authenticateCognitoUser()),
      catchError((error) => of$(alert(error)))
    ),
    catchError((error) => routerActions.route("/support", error))
  );

export const submitCognitoUserForgotPassword = (
  action$,
  store,
  { concat$, of$, mergeMap, ofType, catchError }
) =>
  action$.pipe(
    ofType(types.SUBMIT_VERIFICATION_PASSWORD),
    mergeMap(
      (action) => of$(actions.submitCognitoUserForgotPassword()),
      catchError((error) => of$(alert(error)))
    ),
    catchError((error) => routerActions.route("/support", error))
  );

// export const submitCognitoUserNewPassword = (
//   action$,
//   store,
//   { concat$, of$, mergeMap, ofType, catchError }
// ) =>
//   action$.pipe(
//     ofType(types.SUBMIT_NEW_PASSWORD),
//     mergeMap(
//       (action) => of$(actions.submitCognitoUserNewPassword()),
//       catchError((error) => of$(alert(error)))
//     ),
//     catchError((error) => routerActions.route("/support", error))
//   );




  export const submitCognitoUserNewPassword = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
  action$.pipe(
          ofType(types.SUBMIT_NEW_PASSWORD),
          mergeMap(() =>
            actions.submitCognitoUserNewPassword()
          ),
          catchError((error) =>
              of$(routerActions.route('/support', false, error))
          )
        )
  



export const setDeviceStatusEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.SET_DEVICE_STATUS),
    mergeMap(
      (action) =>
        of$(
          actions.setDeviceStatusNotRemembered()
          /*                 store.value.login.fields.remember.checked
                                    ? actions.setDeviceStatusRemembered()
                                    : actions.setDeviceStatusNotRemembered() */
        ),
      catchError((error) => of$(alert(error)))
    ),
    catchError((error) => routerActions.route("/support", error))
  );

export const getCurrentCognitoUserEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_COGNITO_USER_POOL),
    mergeMap(
      (action) => of$(actions.getCurrentCognitoUser()),
      catchError((error) => of$(alert(error)))
    ),
    catchError((error) => routerActions.route("/support", error))
  );

// export const getCognitoUserSessionEpic = ( action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
//   action$.pipe(
//     ofType(types.GET_CURRENT_COGNITO_USER),
//     mergeMap((action) => {
//       of$(
//         store.value.cognito.cognitoUser.username === undefined
//           ? routerActions.route("/")
//           : actions.getCognitoUserSession(store.value.cognito.cognitoUser)
//       ),
//         catchError((error) => of$(alert(error)));
//     }),
//     catchError((error) => routerActions.route("/support", error))
//   );

export const getCognitoUserStatusEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(
      types.GET_COGNITO_USER_SESSION_FAILED,
      types.GET_COGNITO_USER_SESSION_SUCCEEDED
    ),
    mergeMap(
      (action) =>
        of$(
          !store.value.cognito.session.valid
            ? routerActions.route("/")
            : routerActions.route("/")
        ),
      catchError((error) => of$(alert(error)))
    ),
    catchError((error) => routerActions.route("/support", error))
  );

export const signOutCognitoUserEpic = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.SIGN_OUT_COGNITO_USER),
    mergeMap((action) => routerActions.route("/")),
    catchError((error) => routerActions.route("/support", error))
  );

export const processCognitoUserRegistration = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.PROCESS_COGNITO_USER_REGISTRATION),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/user",
        method: "POST",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "Content-Type": "application/json",
        },
        body: payload.user,
      }).pipe(
        mergeMap((result) => [
          actions.registrationConfirmationSucceeded(payload.result),
          progressBarActions.progress(false),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ) //////take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const getCognitoUserAuthenticationDetails = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_COGNITO_USER_AUTHENTICATION_DETAILS),
    delay(0),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/cognito/other",
        method: "POST",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "Content-Type": "application/json",
        },
        body: payload,
      }).pipe(
        mergeMap((result) => [
          actions.setCognitoUserAuthenticationDetails(result),
          //progressBarActions.progress(false),
        ]),
        //startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        )
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const getCognitoUserAuthenticationKeeplive = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.KEEPLIVE_USER_SESSION),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/cognito/other",
        method: "POST",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "Content-Type": "application/json",
        },
        body: payload,
      }).pipe(
        mergeMap((result) => [
          actions.setCognitoUserAuthenticationKeeplive(result),
          progressBarActions.progress(false),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ) ////take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const getCognitoUserNewPasswordDetails = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.GET_COGNITO_USER_NEW_PASSWORD_AUTHENTICATION_DETAILS),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/cognito/other",
        method: "POST",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "Content-Type": "application/json",
        },
        body: payload,
      }).pipe(
        mergeMap((result) => [
          actions.setCognitoUserNewPasswordDetails(result),
          progressBarActions.progress(false),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ) ////take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );

export const updateUserAuthenticationDetails = (
  action$,
  store,
  { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.COGNITO_UPDATE_USER_AUTHENTICATION_DETAILS),
    mergeMap(({ payload }) =>
      ajax$({
        url: config.system.api + "/components/cognito/other",
        method: "POST",
        headers: {
            tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          "Content-Type": "application/json",
        },
        body: payload,
      }).pipe(
        mergeMap((result) => [
          actions.updateUserAuthenticationDetailsDone(result),
          progressBarActions.progress(false),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/component?client_id=2&menu=true",
              error
            )
          )
        ) ////take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );
