import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_RESOLVE_INVOICE_DOCUMENTS:
            return {
                ...state,
               resolvedocuments: action.payload
            }
        
            
        default:
            return state
    }
}