import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Container, Row, Col } from 'react-grid-system'
// import TextField from '@mui/material/TextField'
import * as styles from './styles'
import * as actions from './actions'
import PdfViewer from '../../PDF/component'
import Loading from '../../Loading/component'
import ViewVehicle from '../../../controls/view_vehicle'
import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'
import DateControl from '../../../controls/dateControl'
import TextField from '../../../controls/textField'

class DefleetContractTermination extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }
    defleetDocuments(documents) {
        return <Card
            title={window.t.en('De-fleet Documents')}
            description={window.t.en('De-fleet termination pack')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <PdfViewer
                            key={'quotation_viewer_'}
                            documents={documents}
                            height={600}
                            multiple={false}
                            edit={false}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    techComments(data) {
        return data
            ? <Card
                title={window.t.en('Technical Manager Comments')}
                description={window.t.en('Techinical manage approval details. ')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
                    </Row>
                </Container>} />
            : ''
    }
    gmComments(data) {
        return data
            ? <Card
                title={window.t.en('General Manager Comments')}
                description={window.t.en('General manager approval details. ')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
                    </Row>
                </Container>} />
            : ''
    }
    ceoComments(data) {
        return data
            ? <Card
                title={window.t.en('CEO Comments')}
                description={window.t.en('ceo approval details. ')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approved By:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Email:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Phone:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Approver Comments:'}</Col>
                        <Col xl={9} style={styles.selectedFieldName}>{data.comments}</Col>
                    </Row>
                </Container>} />
            : ''
    }
    commentsView() {
        return <Card
            title={window.t.en('Contract Termination Comments')}
            description={window.t.en('Capture comments')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={4}>
                                <DateControl
                                    id={'txt_termination_date'}
                                    key={'txt_termination_date'}
                                    name={'txt_termination_date'}
                                    label={window.t.en('Select termination date')}
                                    field={'terminationdate'}
                                    fullWidth={true}
                                    value={this.props.state.terminationdate}
                                    onSelect={(args) => {
                                        this.props.actions.setValue('terminationdate', args)
                                    }}
                                    type={'date'}
                                />
                    </Col>
                    <Col xl={4} style={{paddingTop:'15px'}}>
                                <TextField
                                    id={'txtcomment'}
                                    fullWidth={true}
                                    label={'Enter termination odometer'}
                                    multiLine={false}
                                    value={this.props.state.terminationodo}
                                    variant="outlined"
                                    onChange={(event) => {
                                        let value = event.target.value
                                        this.props.actions.setValue('terminationodo', value)
                                    }}
                                />
                    </Col>
                    <Col xl={4} style={{paddingTop:'15px'}}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}></div>
                            <div style={styles.fieldContent}>
                                <Checkbox
                                    id={'txt_odochecked'}
                                    key={'txt_odochecked'}
                                    name={'txt_odochecked'}
                                    field={'odochecked'}
                                    checked={this.props.state.odochecked}
                                    labelPlacement={'end'}
                                    label={window.t.en('Odometer checked')}
                                    // setComponentFieldInput={
                                    //     this.props.actions.setValue
                                    // }
                                    onCheck={(checked) => {
                                        this.props.actions.setValue('odochecked', checked)
                                    }}
                                /></div>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            label={'Enter Comments...'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={this.props.state.comments}
                            onChange={(event) => {
                                let value = event.target.value
                                this.props.actions.setValue('comments', value)
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    renderView(data) {
        let steps = data
        let step1 = steps.step1.data
        let step14 = steps.step14
        let step15 = steps.step15 ? steps.step15 : ''
        let step16 = steps.step16 ? steps.step16 : ''
        let vehicle = step1.selectedVehicleDetails.data[0]
        return <Container style={{ margin: 0 }} className="custom-container">
            <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
            <Row>
                <Col xl={12}>
                    {this.defleetDocuments(data.documents)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.techComments(step14)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {step15 ? this.gmComments(step15) : ''}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {step16 ? this.ceoComments(step16) : ''}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.commentsView()}
                </Col>
            </Row>
        </Container>
    }
    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) { }
        return (
            workflow_queue_data
                ? this.renderView(workflow_queue_data)
                : <Loading Message={'Loading, Please wait...'} />
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetContractTermination
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetContractTermination)