export const NOT_IMPLEMENTED = 'NOT_IMPLEMENTED'
export const CONFIRM_PO_HEADER_SELECTION = 'CONFIRM_PO_HEADER_SELECTION'
export const UPLOAD_BOOKING_RELEASE_FORM_DOCUMENT = 'UPLOAD_BOOKING_RELEASE_FORM_DOCUMENT'
export const SET_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW = 'SET_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW'
export const CLEAR_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW = 'CLEAR_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW'
export const SET_RELEASE_FORM_COMPONENT_FIELD_INPUT_VALUE = 'SET_RELEASE_FORM_COMPONENT_FIELD_INPUT_VALUE'
export const SET_ACCEPT_REBILL_PO_COMPONENT_FIELD_INPUT_VALUE = 'SET_ACCEPT_REBILL_PO_COMPONENT_FIELD_INPUT_VALUE'
export const RESET_COMPONENT = 'RESET_COMPONENT'
export const SET_PO_DOCUMENT_PREVIEW = 'SET_PO_DOCUMENT_PREVIEW'
export const GET_KPO_NUMBER = 'GET_KPO_NUMBER'
