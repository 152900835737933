import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import Modal from 'react-responsive-modal'
import Iframe from 'react-iframe'
import moment from 'moment'
import FontIcon from '@mui/material/Icon'
import { Card, CardHeader, CardContent, CardActions, Toolbar, Stepper, ButtonGroup, Menu, MenuItem, Button } from '@mui/material'
import Divider from '@mui/material/Divider'
import Loading from '../Loading/component'
import Icon from '../Icon/component'
import WorkflowView from '../WorkflowView/component'
import PdfViewer from '../PDF/component'
import QuoteEdit from '../QuoteEdit/component'
import WorkflowViewFMLQuoteConfirm from '../WorkflowViewFMLQuoteConfirm/component'
import WorkflowVehicleDeliveryConfirm from '../WorkflowVehicleDeliveryConfirm/component'
import WorkflowDownloadPreInspectionDocs from '../WorkflowDownloadPreInspectionDocs/component'
import WorkflowPOApprovalByGM from '../WorkflowPOApprovalByGM/component'
import WorkflowPOApprovalByCEO from '../WorkflowPOApprovalByCEO/component'
import WorkflowFMLCustomerPO from '../WorkflowFMLCustomerPO/component'
import WorkflowPOApprovalByCOO from '../WorkflowPOApprovalByCOO/component'
import WorkflowUploadPID from '../WorkflowUploadPID/component'
import WorkflowUploadPOD from '../WorkflowUploadPOD/component'
import WorkflowDownloadVAFDocument from '../WorkflowDownloadVAFDocument/component'
import POBookingApproval from '../WorkflowPOHeader/POBookingApproval/component'
import POBookingApprovalOps from '../WorkflowPOHeader/POBookingApprovalOps/component'
import POBookingSendAuth from '../WorkflowPOHeader/POBookingSendAuth/component'
import POBookingSendRebillAuth from '../WorkflowPOHeader/POBookingSendRebillAuth/component'
import POBookingApprovalGM from '../WorkflowPOHeader/POBookingApprovalGM/component'
import POBookingApprovalCEO from '../WorkflowPOHeader/POBookingApprovalCEO/component'
import POBookingInvoiceUpload from '../WorkflowPOHeader/POBookingInvoiceUpload/component'
import POBookingRebillInvoiceUpload from '../WorkflowPOHeader/POBookingRebillInvoiceUpload/component'
import PORebillApprovalByTech from '../WorkflowPOHeader/PORebillApprovalByTech/component'
import PORebillApprovalByOps from '../WorkflowPOHeader/PORebillApprovalByOps/component'
import PORebillApprovalByGM from '../WorkflowPOHeader/PORebillApprovalByGM/component'
import PORebillApprovalByCEO from '../WorkflowPOHeader/PORebillApprovalByCEO/component'
import POBookingSendRebill from '../WorkflowPOHeader/POBookingSendRebill/component'
import POCloseBooking from '../WorkflowPOHeader/POCloseBooking/component'
import POCloseRebillBooking from '../WorkflowPOHeader/POCloseRebillBooking/component'
import POBookingAcceptRebill from '../WorkflowPOHeader/POBookingAcceptRebill/component'
import POBookingSendCustomerRebillInvoice from '../WorkflowPOHeader/POBookingSendCustomerRebillInvoice/component'
import AcceptCustomerSignedSTR from '../WorkflowSTRHeader/AcceptCustomerSignedSTR/component'
import AcceptSupplierSTRQuote from '../WorkflowSTRHeader/AcceptSupplierSTRQuote/component'
import STRContractActivation from '../WorkflowSTRHeader/STRContractActivation/component'
import SendCustomerQuote from '../WorkflowSTRHeader/SendCustomerQuote/component'
import SendSupplierSTRPO from '../WorkflowSTRHeader/SendSupplierSTRPO/component'
import ApproveMMQuote from '../WorkflowManageMaintenance/ApproveQuote/component'
import ActivateMMContract from '../WorkflowManageMaintenance/ActivateMMContract/component'
import BillingRunStart from '../WorkflowBillingRun/BillingRunStart/component'
import BillingRunView from '../WorkflowBillingRun/BillingRunView/component'
import ContractRestructureApprove from '../WorkflowContractRestructure/ContractRestructureApprove/component'
import AdhocComponentFieldInput from '../Custom/AdhocComponentFieldInput/component'
import ReassignTask from '../WorkflowQueueTasks/ReassignTask/component'
import CancelledTasksApproval from '../CancelledTasksApproval/component'
import * as reassignTaskActions from '../WorkflowQueueTasks/ReassignTask/actions'
import DefleetQuoteApproval from '../WorkflowDefleet/DefleetQuoteApproval/component'
import DefleetQuoteApprovalGM from '../WorkflowDefleet/DefleetQuoteApprovalGM/component'
import DefleetQuoteApprovalCEO from '../WorkflowDefleet/DefleetQuoteApprovalCEO/component'
import DefleetSendRebill from '../WorkflowDefleet/DefleetSendRebill/component'
import DefleetAcceptRebill from '../WorkflowDefleet/DefleetAcceptRebill/component'
import DefleetSendRebillAuth from '../WorkflowDefleet/DefleetSendRebillAuth/component'
import DefleetCloseBooking from '../WorkflowDefleet/DefleetCloseBooking/component'
import DefleetContractTermination from '../WorkflowDefleet/DefleetContractTermination/component'
import DefleetTerminationRequest from '../WorkflowDefleet/DefleetTerminationRequest/component'
import DefleetTerminationRequestApproval from '../WorkflowDefleet/DefleetTerminationRequestApproval/component'
import DefleetTerminationRequestApprovalGM from '../WorkflowDefleet/DefleetTerminationRequestApprovalGM/component'
import DefleetTerminationRequestApprovalCEO from '../WorkflowDefleet/DefleetTerminationRequestApprovalCEO/component'
import DefleetRepairChecksheet from '../WorkflowDefleet/DefleetRepairChecksheet/component'
import DefleetDisposal from '../WorkflowDefleet/DefleetDisposal/component'
import DefleetUploadChecksheet from '../WorkflowDefleet/DefleetUploadChecksheet/component'
import BillingRefresh from '../WorkflowBillingRun/BillingRefresh/component'
import BillingPreview from '../WorkflowBillingRun/BillingPreview/component'
import BillingSendInvoices from '../WorkflowBillingRun/BillingSendInvoices/component'
import BillingInterest from '../WorkflowBillingRun/BillingInterest/component'
import BillingWeeklyCIS from '../WorkflowBillingRun/BillingWeeklyCIS/component'
import BillingOdoFile from '../WorkflowBillingRun/BillingOdoFile/component'
import BillingOocpFile from '../WorkflowBillingRun/BillingOocpFile/component'
import BillingRunPrep from '../WorkflowBillingRun/BillingRunPrep/component'
import DefleetDisposalApproval from '../WorkflowDefleet/DefleetDisposalApproval/component'
import DefleetDisposalApprovalCEO from '../WorkflowDefleet/DefleetDisposalApprovalCEO/component'
import DefleetRebillDispute from '../WorkflowDefleet/DefleetRebillDispute/component'
import WorkflowFMLQuote from '../WorkflowFMLQuote/component'
import WorkflowQuoteReview from '../WorkflowQuoteReview/component'
import WorkflowQuoteApprove from '../WorkflowQuoteApprove/component'
import DOAGenericApproval from '../WorkflowPOHeader/DOAGenericApproval/component'
import PaymentDOAApproval from '../WorkflowCreditorsPayment/PaymentDOAApproval/component'
import PaymentApprovalAdmin from '../WorkflowPayment/PaymentApprovalAdmin/component'
import PaymentApprovalTech from '../WorkflowPayment/PaymentApprovalTech/component'
import PaymentApprovalOPS from '../WorkflowPayment/PaymentApprovalOPS/component'
import PaymentApprovalGM from '../WorkflowPayment/PaymentApprovalGM/component'
import PaymentApprovalFinance from '../WorkflowPayment/PaymentApprovalFinance/component'
import PaymentApprovalRelease from '../WorkflowPayment/PaymentApprovalRelease/component'
import PaymentApprovalAuthorisation from '../WorkflowPayment/PaymentApprovalAuthorisation/component'
import PaymentAllocation from '../WorkflowPayment/PaymentAllocation/component'

import WorkflowFMLReleaseNote from '../WorkflowFMLReleaseNote/component'
import WorkflowFMLAcceptReleaseNote from '../WorkflowFMLAcceptReleaseNote/component'
import PaymentCapture from '../WorkflowCreditorsPayment/PaymentCapture/component'
import StatementReview from '../WorkflowStatementRun/StatementReview/component'
import EnterestReview from '../WorkflowStatementRun/EnterestReview/component'
import StatementPreview from '../WorkflowStatementRun/StatementPreview/component'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from '@mui/material/Paper'
import WorkflowTaskHeader from '../../controls/header'

import CardControl from '../../controls/card'

import * as actions from './actions'
import * as styles from './styles'
import DefleetQuoteApprovalOPS from '../WorkflowDefleet/DefleetQuoteApprovalOPS/component'
import CancelledTasksApprovalOPS from '../CancelledTasksApprovalOPS/component'
import CancelledTasksApprovalGM from '../CancelledTasksApprovalGM/component'
import WorkflowSTRQuotationReview from '../WorkflowSTRQuote/WorkflowReview/component'
import WorkflowSTRQuotationBookout from '../WorkflowSTRQuote/WorkflowBookout/component'
import WorkflowSTRQuotationActivation from '../WorkflowSTRQuote/WorkflowActivation/component'
import SupplierMasterActivation from '../SupplierMaster/SupplierMasterActivation/component'
import CustomerMasterActivation from '../CustomerMaster/CustomerMasterActivation/component'
import ActivationSalesProcess from '../SalesProcess/ActivationSalesProcess/component'
import AccessoryMasterActivation from '../AccessoryMaster/AccessoryMasterActivation/component'
import PricingMasterActivation from '../VehicleMaster/PricingMasterActivation/component'
import CreditorsPaymentReview from '../CreditorsPayment/CreditorsPaymentReview/component'
import CreditorsPaymentAllocate from '../CreditorsPayment/CreditorsPaymentAllocate/component'

import CostCentreChangeApprove from '../CostCentreChange/CostCentreChangeApprove/component'
import OdometerChangeApprove from '../OdometerChange/OdometerChangeApprove/component'
import RegistrationChangeApprove from '../RegistrationChange/RegistrationChangeApprove/component'
import CreditNoteReviewAndApprove from '../CreditNote/CreditNoteReview/component'
import CreditNoteManagementApprove from '../CreditNote/CreditNoteManagement/component'
import InterestRateReview from '../InterestRate/InterestRateReview/component'
import CashbookArchiveReview from '../CashbookArchive/CashbookArchiveReview/component'

import Workflow_Stepper from '../../controls/workflow_stepper'

class WorkflowTaskApproval extends Component {
    componentDidMount() {
        this.workflow = this.props.state.workflowQueueTasks.workflow.name
        this.task = this.props.state.workflowQueueTasks.workflow_task.name

    //    // //console.log('WorkflowTaskApproval >>>>>>>>>>>>>>>>>>',this.props)
    //     let amount = 0
        this.props.actions.getWorkflowTaskApprovalInformation(this.workflow, this.task)
    }

    getTeams(id) {
        const teams = Object.keys(this.props.state.teams).map(x => {
            return x
        })

        return teams
            ? teams.map(x => {
                return <MenuItem primaryText={this.props.state.teams[x].title} onClick={() => {
                    this.props.actions.closeEscalation(x, id)
                }} />
            })
            : <MenuItem primaryText="No Results" />
    }

    getTitle() {
        return this.props.state.task.workflow_task_step
            ? this.props.state.task.title
            : 'Loading...'
    }

    getSubtitle() {
        return this.props.state.task.workflow_task_step
            ? this.props.state.task.description
            : 'Loading...'
    }

    getIcon() {
        return this.props.state.task.workflow_task_step
            ? this.props.state.task.icon.name
            : 'query_builder'
    }

    getConfirmationStepComponentData(step, component) {
        return step.workflow_task_step_component
            .filter(x => x.component.name === component.name)
            .map(x => {
                return x.data.map(x => {
                    return <WorkflowView
                        key={'confirmationStepComponentDataView_' + component.name}
                        name={component.name}
                        data={x}
                    />
                })
            })
    }

    getWorkflowQuoteReview() {
        return <WorkflowQuoteReview
            id={'WorkflowQuoteReview'}
            task={this.task}
            name={this.props.name}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getConfirmationStepDocumentViewer(component, queue) {
        return <WorkflowQuoteApprove
            id={'WorkflowQuoteApprove'}
            task={this.task}
            name={this.props.name}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getSTRApproveCustomerQuote(component, queue) {
        window.glyco.log('getConfirmationStepDocumentViewer')
        window.glyco.log(queue)
        let documents = []
        try {
            documents = queue.context_data.data.data.data[0].documents
        } catch (error) {
        }
        return !documents
            ? <Loading message={'Retrieving documents...'} />
            : !documents.length
                ? <Loading message={'Retrieving documents...'} />
                : <PdfViewer
                    key={'confirmationStepComponentDataView_' + component.name}
                    name={component.name}
                    workflow={this.workflow}
                    task={this.task}
                    documents={documents}
                    workflow_queue_data={this.workflow_queue_data}
                />
    }

    // getSTRSendCustomerQuote(component, queue) {
    //     window.glyco.log('getConfirmationStepDocumentViewer')
    //     window.glyco.log(queue)
    //     let documents = []
    //     try {
    //         documents = queue.context_data.data.data.data[0].step.data[0].context_data.data.data.data[0].documents
    //     } catch (error) {
    //     }
    //     return !documents
    //         ? <Loading message={'Retrieving documents...'} />
    //         : !documents.length
    //             ? <Loading message={'Retrieving documents...'} />
    //             : <PdfViewer
    //                 key={'confirmationStepComponentDataView_' + component.name}
    //                 name={component.name}
    //                 workflow={this.workflow}
    //                 task={this.task}
    //                 documents={documents}
    //                 workflow_queue_data={this.workflow_queue_data}
    //             />
    // }

    getConfirmationSTRFinalApproval(component, queue) {

        window.glyco.log('getConfirmationStepDocumentViewer')
        window.glyco.log(queue)
        let documents = []
        try {
            const list = queue.context_data.data.data.data[0].documents
            documents = list
        } catch (error) { }

        window.glyco.log('documents')
        window.glyco.log(documents)

        return !documents
            ? <Loading message={'Retrieving documents...'} />
            : <PdfViewer
                key={'confirmationStepComponentDataView_' + component.name}
                name={component.name}
                workflow={this.workflow}
                task={this.task}
                documents={documents}

                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getConfirmationStepComponents(step) {
        return step.workflow_task_step_component.map(x => {
            return <Card style={styles.workflowTaskStepComponentCard}>
                <CardHeader
                    title={x.component.title}
                    showExpandableButton={true}
                    subtitle={x.component.description}
                    // avatar={
                    //     <Icon istyle={styles.confirmIcon} iclass={x.component.icon.unicode} iname={x.component.icon.name} />
                    // }
                    actAsExpander={true}
                />
                <CardContent expandable={true}>
                    {this.getConfirmationStepDocumentViewer(x.component)}
                </CardContent>
            </Card>
        })
    }

    getApprovalSubtitle() {
        const workflowTitle = this.props.state.task.workflow.title.toString().toUpperCase()
        const workflowTaskTitle = this.props.state.task.title.toString().toUpperCase()
        const date = moment(this.props.state.assigned_to_user_date).format('D MMMM YYYY')
        const time = moment(this.props.state.assigned_to_user_date).format('HH:mm ZZ')

        return 'You are viewing a task that requires your approval originating from workflow "' + workflowTitle + '",  task "' + workflowTaskTitle + '" queue. Submitted by USER on ' + date + ' at ' + time
    }

    getConfirmationView() {
        return this.props.state.task.workflow_task_step
            .sort((x, y) => x.order - y.order)
            .map(x => {
                return <div>
                    <Card style={styles.workflowTaskStepComponentCard}>
                        <CardHeader
                            title={x.title}
                            showExpandableButton={true}
                            subtitle={x.description}
                            // avatar={
                            //     <Icon istyle={styles.confirmIcon} iclass={x.component.icon.unicode} iname={x.component.icon.name} />
                            // }
                            actAsExpander={true}
                        />
                        <CardContent expandable={true}>
                            {this.getConfirmationStepComponents(x)}
                        </CardContent>
                    </Card>
                    <br />
                </div>
            })
    }

    getDocuments() {
        return this.props.state.document.data && !this.props.state.document.data.key.text
            ? <Loading message={'Retrieving documents...'} />
            : <Iframe url={this.props.state.document.data.key.text}
                width="100%"
                height="800px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
                allowFullScreen
            />
    }

    getConfirmationApprovalByGM(queue) {
        return <WorkflowPOApprovalByGM
            id={'WorkflowPOApprovalByGM_'}
            title={window.t.en('Approval By General Manager')}
            description={window.t.en('General Manager approval required')}
            name={this.props.name}
            workflow_queue_data={queue}
        />
    }

    getConfirmationPODocumentViewer(queue) {
        return <WorkflowFMLCustomerPO
            id={'WorkflowFMLCustomerPO'}
            title={window.t.en('Supplier Vehicle PO')}
            description={window.t.en('Send Supplier Vehicle PO ')}
            name={this.props.name}
            workflow_queue_data={queue}
        />
    }

    getConfirmationApprovalByCEO(queue) {
        return <WorkflowPOApprovalByCEO
                id={'WorkflowPOApprovalByCEO_'}
                title={window.t.en('Approval By CEO')}
                description={'CEO approval required'}
                name={this.props.name}
                workflow_queue_data={queue}
            />
    }

    getConfirmationApprovalByCOO(queue) {
        return <WorkflowPOApprovalByCOO
                id={'WorkflowPOApprovalByCEO_'}
                title={window.t.en('Approval By COO')}
                description={'COO approval required'}
                name={this.props.name}
                workflow_queue_data={queue}
            />
    }
    getConfirmationVehicleDelivery() {
        return <WorkflowVehicleDeliveryConfirm
                id={'WorkflowVehicleDeliveryConfirm_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getDownloadPreInspectionDocs() {
        return <WorkflowDownloadPreInspectionDocs
                id={'WorkflowVehicleDeliveryConfirm_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getDownloadVAF() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving vehicle delivery confirmation...'} />
            : <WorkflowDownloadVAFDocument
                id={'WorkflowVehicleDeliveryConfirm_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getUploadPreInspectionDocument() {
        return <WorkflowUploadPID
                id={'WorkflowVehicleDeliveryConfirm_PreInspection'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getUploadPODocument() {
        return <WorkflowUploadPOD
                id={'WorkflowVehicleDeliveryConfirm_PODocument'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getPOReviewAndSend(component, queue) {
        return <WorkflowViewFMLQuoteConfirm
            id={'WorkflowViewFMLQuoteConfirm_' + component}
            name={component}
            data={{
                quote: queue,
                suppliers: queue
            }}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getServiceBookingApprovalTech(component) {
        return <POBookingApproval
            id={'POBookingApproval_' + component}
            name={component}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getServiceBookingApprovalOps(component) {
        return <POBookingApprovalOps
            id={'POBookingApprovalOps_' + component}
            name={component}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getServiceBookingApprovalGM(component) {
        return <POBookingApprovalGM
            id={'POBookingApprovalGM'}
            name={component}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getServiceBookingApprovalCEO(component) {
        return <POBookingApprovalCEO
            id={'POBookingApprovalCEO'}
            name={component}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getServiceBookingSendAuthorosation(task) {
        return <POBookingSendAuth
            id={'POBookingSendAuth_View'}
            name={'POBookingSendAuth'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getServiceCloseBooking(task) {
        return <POCloseBooking
            id={'POCloseBooking_View'}
            name={'POCloseBooking'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getRebillCloseBooking(task) {
        return <POCloseRebillBooking
            id={'POCloseRebillBooking_View'}
            name={'POCloseRebillBooking'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getDefleetCloseBooking(task) {
        return <DefleetCloseBooking
            id={'DefleetCloseBooking_View'}
            name={'DefleetCloseBooking'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetContractTermination(task) {
        return <DefleetContractTermination
            id={'DefleetContractTermination_View'}
            name={'DefleetContractTermination'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetRepairChecksheet(task) {
        return <DefleetRepairChecksheet
            id={'DefleetRepairChecksheet_View'}
            name={'DefleetRepairChecksheet'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetDisposal(task) {
        return <DefleetDisposal
            id={'DefleetDisposal_View'}
            name={'DefleetDisposal'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetDisposalApproval(task) {
        return <DefleetDisposalApproval
            id={'DefleetDisposalApproval_View'}
            name={'DefleetDisposalApproval'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetDisposalApprovalCEO(task) {
        return <DefleetDisposalApprovalCEO
            id={'DefleetDisposalApprovalCEO_View'}
            name={'DefleetDisposalApprovalCEO'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetTerminationRequest(task) {
        return <DefleetTerminationRequest
            id={'DefleetTerminationRequest_View'}
            name={'DefleetTerminationRequest'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetTerminationRequestApproval(task) {
        return <DefleetTerminationRequestApproval
            id={'DefleetTerminationRequestApproval_View'}
            name={'DefleetTerminationRequestApproval'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetDefleetUploadChecksheet(task) {
        return <DefleetUploadChecksheet
            id={'DefleetUploadChecksheet_View'}
            name={'DefleetUploadChecksheet'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getBillingRefresh(task) {
        return <BillingRefresh
            id={'BillingRefresh_View'}
            name={'BillingRefresh'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getBillingPreview(task) {
        return <BillingPreview
            id={'BillingPreview_View'}
            name={'BillingPreview'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getBillingSendInvoices(task) {
        return <BillingSendInvoices
            id={'BillingSendInvoices_View'}
            name={'BillingSendInvoices'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getBillingInterest(task) {
        return <BillingInterest
            id={'BillingInterest_View'}
            name={'BillingInterest'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getBillingWeeklyCIS(task) {
        return <BillingWeeklyCIS
            id={'BillingWeeklyCIS_View'}
            name={'BillingWeeklyCIS'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getBillingOdoFile(task) {
        return <BillingOdoFile
            id={'BillingOdoFile_View'}
            name={'BillingOdoFile'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getBillingOocpFile(task) {
        return <BillingOocpFile
            id={'BillingOocpFile_View'}
            name={'BillingOocpFile'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getBillingPrep(task) {
        return <BillingRunPrep
            id={'BillingRunPrep_View'}
            name={'BillingRunPrep'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetTerminationRequestApprovalGM(task) {
        return <DefleetTerminationRequestApprovalGM
            id={'DefleetTerminationRequestApprovalGM_View'}
            name={'DefleetTerminationRequestApprovalGM'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getDefleetTerminationRequestApprovalCEO(task) {
        return <DefleetTerminationRequestApprovalCEO
            id={'DefleetTerminationRequestApprovalCEO_View'}
            name={'DefleetTerminationRequestApprovalCEO'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getPaymentApprovalTech(task) {
        return <PaymentApprovalTech
            id={'PaymentApprovalTech_View'}
            name={'PaymentApprovalTech'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getPaymentApprovalAdmin(task) {
        return <PaymentApprovalAdmin
            id={'PaymentApprovalAdmin_View'}
            name={'PaymentApprovalAdmin'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getPaymentApprovalOPS(task) {
        return <PaymentApprovalOPS
            id={'PaymentApprovalOPS_View'}
            name={'PaymentApprovalOPS'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getPaymentApprovalGM(task) {
        return <PaymentApprovalGM
            id={'PaymentApprovalGM_View'}
            name={'PaymentApprovalGM'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getPaymentApprovalFinance(task) {
        return <PaymentApprovalFinance
            id={'PaymentApprovalFinance_View'}
            name={'PaymentApprovalFinance'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getPaymentApprovalRelease(task) {
        return <PaymentApprovalRelease
            id={'PaymentApprovalRelease_View'}
            name={'PaymentApprovalRelease'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getPaymentApprovalAuthorisation(task) {
        return <PaymentApprovalAuthorisation
            id={'PaymentApprovalAuthorisation_View'}
            name={'PaymentApprovalAuthorisation'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getPaymentAllocation(task) {
        return <PaymentAllocation
            id={'PaymentAllocation_View'}
            name={'PaymentAllocation'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }
    getServiceBookingRebillSendAuthorosation(task) {
        return <POBookingSendRebillAuth
            id={'POBookingSendRebillAuth_View'}
            name={'POBookingSendRebillAuth'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getDefleetRebillSendAuthorisation(task) {
        return <DefleetSendRebillAuth
            id={'DefleetSendRebillAuth_View'}
            name={'DefleetSendRebillAuth'}
            task={task}
            workflow_queue_data={this.workflow_queue_data}
        />
    }

    getSendCustomerRebillTaxInvoice(task) {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <POBookingSendCustomerRebillInvoice
                id={'POBookingSendCustomerRebillInvoice_View'}
                name={'POBookingSendCustomerRebillInvoice'}
                task={task}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getUploadBookingInvoice() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving booking  confirmation...'} />
            : <POBookingInvoiceUpload
                id={'POBookingInvoiceUpload_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getUploadBookingRebillInvoice() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <POBookingRebillInvoiceUpload
                id={'POBookingInvoiceUpload_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getRebillApprovalByTech() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <PORebillApprovalByTech
                id={'POBookingInvoiceUpload_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getRebillApprovalByOps() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <PORebillApprovalByOps
                id={'POBookingInvoiceUpload_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getRebillApprovalByGM() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <PORebillApprovalByGM
                id={'POBookingInvoiceUpload_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getRebillApprovalByCEO() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <PORebillApprovalByCEO
                id={'POBookingInvoiceUpload_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getRebillSendToCustomer() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <POBookingSendRebill
                id={'POBookingSendRebill_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getDefleetRebillSendToCustomer() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <DefleetSendRebill
                id={'DefleetSendRebill_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getRebillAcceptFromCustomer() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <POBookingAcceptRebill
                id={'POBookingSendRebill_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getDefleetRebillAcceptFromCustomer() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <DefleetAcceptRebill
                id={'DefleetAcceptRebill_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getDefleetRebillDispute() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <DefleetRebillDispute
                id={'DefleetRebillDispute_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getSTRAcceptCustomerSignedQuote() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <AcceptCustomerSignedSTR
                id={'POBookingSendRebill_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getApproveMMQuote() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <ApproveMMQuote
                id={'ApproveMMQuote_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getActivateMMQuote() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <ActivateMMContract
                id={'ApproveMMQuote_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getStartBillingRun() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <BillingRunStart
                id={'BillingRunStart_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getViewBillingRun() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <BillingRunView
                id={'BillingRunStart_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getAcceptSupplierQuote() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <AcceptSupplierSTRQuote
                id={'AcceptSupplierSTRQuote_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getActivateSTRContract() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <STRContractActivation
                id={'STRContractActivation_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getSTRContractActivation() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <STRContractActivation
                id={'AcceptSupplierSTRQuote_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getSendSupplierSTRPO() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <SendSupplierSTRPO
                id={'AcceptSupplierSTRQuote_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getSendSupplierPO() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <SendSupplierSTRPO
                id={'AcceptSupplierSTRQuote_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getContractRestructureApprove() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <ContractRestructureApprove
                id={'ContractRestructureApprove_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getSTRSendCustomerQuote() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <SendCustomerQuote
                id={'SendCustomerQuote_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getCancelledTasksApproval() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CancelledTasksApproval
                id={'CancelledTasksApproval_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    
    getCancelledTasksApprovalOPS() {
        //console.log("this.workflow_queue_data",this.workflow_queue_data)
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CancelledTasksApprovalOPS
                id={'CancelledTasksApprovalOPS_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
	
    getCancelledTasksApprovalGM() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CancelledTasksApprovalGM
                id={'CancelledTasksApprovalGM_View'}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
	

    getDefleetQuoteApproval() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <DefleetQuoteApproval
                id={'DefleetQuoteApproval_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getDefleetQuoteApprovalOPS() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <DefleetQuoteApprovalOPS
                id={'DefleetQuoteApprovalOPS_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getDefleetQuoteApprovalGM() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <DefleetQuoteApprovalGM
                id={'DefleetQuoteApprovalGM_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getDefleetQuoteApprovalCEO() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <DefleetQuoteApprovalCEO
                id={'DefleetQuoteApprovalCEO_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getFullMaintenanceLeaseApproval() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <WorkflowFMLQuote
                id={'WorkflowFMLQuote_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getDOAGenericApproval() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <DOAGenericApproval
                id={'DOAGenericApproval_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getPaymentDOAApproval() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <PaymentDOAApproval
                id={'DOACreditorsApproval_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getStatementReview() {
        return <StatementReview
                id={'StatementReview_View'}
                task={this.task}
                name={this.props.name}
            />
    }
    getEnterestReview() {
        return <EnterestReview
                id={'EnterestReview_View'}
                task={this.task}
                name={this.props.name}
            />
    }
    getStatementPreview() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <StatementPreview
                id={'StatementPreview_View'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getSTRQuotationReview() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <WorkflowSTRQuotationReview
                id={'WorkflowSTRQuotationReview'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getSTRQuotationBookout() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <WorkflowSTRQuotationBookout
                id={'WorkflowSTRQuotationBookout'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getSTRQuotationActivation() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <WorkflowSTRQuotationActivation
                id={'WorkflowSTRQuotationActivation'}
                task={this.task}
                name={this.props.name}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getCreditorCapture() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <PaymentCapture
                key={'PaymentCapture' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getSupplierMasterActivation() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <SupplierMasterActivation
                key={'SupplierMasterActivation' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getCustomerMasterActivation() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CustomerMasterActivation
                key={'CustomerMasterActivation' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getActivationSalesProcess() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <ActivationSalesProcess
                key={'ActivationSalesProcess' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getAccessoryMasterActivation() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <AccessoryMasterActivation
                key={'AccessoryMasterActivation' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getPricingMasterActivation() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <PricingMasterActivation
                key={'PricingMasterActivation' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getCreditorsPaymentReview() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CreditorsPaymentReview
                key={'CreditorsPaymentReview' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    
    getCreditorsPaymentAllocate() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CreditorsPaymentAllocate
                key={'CreditorsPaymentAllocate' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    
    getCreditNoteReviewAndApprove() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CreditNoteReviewAndApprove
                key={'CreditNoteReviewAndApprove' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    
    getCashbookArchiveReviewAndApprove() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CashbookArchiveReview
                key={'CashbookArchiveReview' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    
    getCreditNoteManagementApprove() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CreditNoteManagementApprove
                key={'getCreditNoteManagementApprove' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    
    
    getInterestRateReviewAndApprove() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <InterestRateReview
                key={'InterestRateReview' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getFMLReleaseNote() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <WorkflowFMLReleaseNote
                key={'WorkflowFMLReleaseNote' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    
    getFMLAcceptReleaseNote() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <WorkflowFMLAcceptReleaseNote
                key={'WorkflowFMLAcceptReleaseNote' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }

    getCostcentreChangeActivation() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <CostCentreChangeApprove
                key={'CostCentreChangeApprove' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getRegistrationChangeActivation() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <RegistrationChangeApprove
                key={'RegistrationChangeApprove' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getOdometerChangeActivation() {
        return !this.workflow_queue_data
            ? <Loading message={'Retrieving confirmation view...'} />
            : <OdometerChangeApprove
                key={'OdometerChangeApprove' + this.props.name}
                name={this.props.name}
                task={this.task}
                mode={"confirm"}
                workflow_queue_data={this.workflow_queue_data}
            />
    }
    getLayout() {
        //console.log('this.task',this.task)
        switch (this.task) {
            case 'tsk_str_quote_accept_supplier_str_quote':
                return this.getAcceptSupplierQuote()
            case 'tsk_str_quote_send_supplier_po_for_str':
                return this.getSendSupplierPO()
            case 'tsk_str_quote_contract_activation':
                return this.getSTRContractActivation()
            case 'tsk_billing_run_view':
                return this.getViewBillingRun()
            case 'tsk_fml_quote_approve_customer_quote':
                return this.getWorkflowQuoteReview()
            case 'tsk_purchase_order_review_and_send':
                return this.getPOReviewAndSend(this.props.state, this.workflow_queue_data.data.data.data[0].data[0])
            case 'tsk_fml_quote_approve_and_send_po':
                return this.getConfirmationPODocumentViewer(this.workflow_queue_data)
            case 'tsk_fml_quote_approval_by_gm':
                return this.getConfirmationApprovalByGM(this.workflow_queue_data)
            case 'tsk_fml_quote_approval_by_coo':
                return this.getConfirmationApprovalByCOO(this.workflow_queue_data)
            case 'tsk_fml_quote_approval_by_ceo':
                return this.getConfirmationApprovalByCEO(this.workflow_queue_data)
            case 'tsk_fml_quote_waiting_vehicle_delivery':
                return this.getConfirmationVehicleDelivery()
            case 'tsk_fml_quote_download_and_preinspection':
                return this.getDownloadPreInspectionDocs()
            case 'tsk_fml_quote_vaf':
                return this.getDownloadVAF()
            case 'tsk_fml_quote_upload_preinspection_documents':
                return this.getUploadPreInspectionDocument()
            case 'tsk_creditors_invoice_capture':
                return this.getCreditorCapture()
            case 'tsk_fml_quote_upload_pod_documents':
                return this.getUploadPODocument()
            case 'tsk_purchase_order_reveiw_and_approve_quote_tech':
                return this.getServiceBookingApprovalTech()
            case 'tsk_purchase_order_reveiw_and_approve_quote_ops':
                return this.getServiceBookingApprovalOps()
            case 'tsk_purchase_order_reveiw_and_approve_quote_gm':
                return this.getServiceBookingApprovalGM()
            case 'tsk_purchase_order_reveiw_and_approve_quote_ceo':
                return this.getServiceBookingApprovalCEO()
            case 'tsk_purchase_order_send_authorisation':
                return this.getServiceBookingSendAuthorosation(this.task)
            case 'tsk_purchase_order_close_booking':
                return this.getServiceCloseBooking(this.task)
            case 'tsk_rebill_close_booking':
                return this.getRebillCloseBooking(this.task)
            case 'tsk_defleet_close_booking':
                return this.getDefleetCloseBooking(this.task)
            case 'tsk_defleet_contract_termination':
                return this.getDefleetContractTermination(this.task)
            case 'tsk_defleet_repair_checksheet':
                return this.getDefleetRepairChecksheet(this.task)
            case 'tsk_defleet_disposal':
                return this.getDefleetDisposal(this.task)
            case 'tsk_defleet_disposal_approve':
                return this.getDefleetDisposalApproval(this.task)
            case 'tsk_defleet_disposal_approve_ceo':
                return this.getDefleetDisposalApprovalCEO(this.task)
            case 'tsk_defleet_termination_request':
                return this.getDefleetTerminationRequest(this.task)
            case 'tsk_defleet_termination_request_approve_tech':
                return this.getDefleetTerminationRequestApproval(this.task)
            case 'tsk_defleet_termination_request_approve_gm':
                return this.getDefleetTerminationRequestApprovalGM(this.task)
            case 'tsk_defleet_upload_checksheet':
                return this.getDefleetDefleetUploadChecksheet(this.task)
            case 'tsk_billing_run_odofile':
                return this.getBillingOdoFile(this.task)
            case 'tsk_billing_run_oocpfile':
                return this.getBillingOocpFile(this.task)
            case 'tsk_billing_run_prep':
                return this.getBillingPrep(this.task)
            case 'tsk_billing_run_refresh':
                return this.getBillingRefresh(this.task)
            case 'tsk_billing_run_invoice':
                return this.getBillingPreview(this.task)
            case 'tsk_billing_run_cis':
                return this.getBillingWeeklyCIS(this.task)
            case 'tsk_defleet_termination_request_approve_ceo':
                return this.getDefleetTerminationRequestApprovalCEO(this.task)
            case 'tsk_payment_approve_invoice':
                return this.getPaymentApprovalAdmin(this.task)
            case 'tsk_payment_approval_tech':
                return this.getPaymentApprovalTech(this.task)
            case 'tsk_payment_approval_ops':
                return this.getPaymentApprovalOPS(this.task)
            case 'tsk_payment_approval_gm':
                return this.getPaymentApprovalGM(this.task)
            case 'tsk_payment_approval_finance':
                return this.getPaymentApprovalFinance(this.task)
            case 'tsk_payment_release':
                return this.getPaymentApprovalRelease()
            case 'tsk_payment_authorisation':
                return this.getPaymentApprovalAuthorisation()
            case 'tsk_payment_allocation':
                return this.getPaymentAllocation()
            case 'tsk_rebill_send_authorisation':
                return this.getServiceBookingRebillSendAuthorosation(this.task)
            case 'tsk_defleet_send_authorisation':
                return this.getDefleetRebillSendAuthorisation(this.task)
            case 'tsk_defleet_rebill_dispute':
                return this.getDefleetRebillDispute(this.task)
            case 'tsk_rebill_send_customer_invoice':
                return this.getSendCustomerRebillTaxInvoice(this.task)
            case 'tsk_purchase_order_upload_invoice':
                return this.getUploadBookingInvoice()
            case 'tsk_rebill_upload_invoice':
                return this.getUploadBookingRebillInvoice()
            case 'tsk_rebill_review_and_approve_tech':
                return this.getRebillApprovalByTech()
            case 'tsk_rebill_review_and_approve_ops':
                return this.getRebillApprovalByOps()
            case 'tsk_rebill_review_and_approve_gm':
                return this.getRebillApprovalByGM()
            case 'tsk_mm_approve_quote':
                return this.getApproveMMQuote()
            case 'tsk_mm_activate_contract':
                return this.getActivateMMQuote()
            case 'tsk_rebill_review_and_approve_ceo':
                return this.getRebillApprovalByCEO()
            case 'tsk_rebill_send_customer_rebill':
                return this.getRebillSendToCustomer()
            case 'tsk_rebill_accept_customer_rebill':
                return this.getRebillAcceptFromCustomer()
            case 'tsk_defleet_accept_customer_rebill':
                return this.getDefleetRebillAcceptFromCustomer()
            case 'tsk_str_quote_accept_customer_str_quote':
                return this.getSTRAcceptCustomerSignedQuote()
            case 'tsk_fml_quote_final_approve_customer_quote':
                return this.getConfirmationStepDocumentViewer(this.props.state, this.workflow_queue_data)
            case 'tsk_str_quote_approve_customer_quote':
                return this.getConfirmationStepDocumentViewer(this.props.state, this.workflow_queue_data)
            case 'tsk_str_quote_approve_str_quote':
                return this.getSTRApproveCustomerQuote(this.props.state, this.workflow_queue_data)
            case 'tsk_str_quote_send_customer_str_quote':
                return this.getSTRSendCustomerQuote(this.props.state, this.workflow_queue_data)
            case 'tsk_str_quote_final_approve_customer_quote':
                return this.getConfirmationSTRFinalApproval(this.props.state, this.workflow_queue_data)
            case 'tsk_str_quote_send_supplier_str_quote':
                return this.getSendSupplierSTRPO()
            case 'tsk_contract_restructure_approve':
                return this.getContractRestructureApprove()
            case 'tsk_workflow_cancelled_task_approval':
                return this.getCancelledTasksApproval()
            case 'tsk_workflow_cancelled_task_approve_ops':
                return this.getCancelledTasksApprovalOPS()
            case 'tsk_workflow_cancelled_task_approve_gm':
                return this.getCancelledTasksApprovalGM()
            case 'tsk_defleet_review_and_approve_tech':
                return this.getDefleetQuoteApproval()
            case 'tsk_defleet_review_and_approve_ops':
                return this.getDefleetQuoteApprovalOPS()
            case 'tsk_defleet_review_and_approve_gm':
                return this.getDefleetQuoteApprovalGM()
            case 'tsk_defleet_review_and_approve_ceo':
                return this.getDefleetQuoteApprovalCEO()
            case 'tsk_defleet_send_customer_rebill':
                return this.getDefleetRebillSendToCustomer()
            case 'tsk_full_maintenance_lease_review':
            case 'tsk_full_maintenance_lease_approve':
                return this.getFullMaintenanceLeaseApproval()
            case 'tsk_rebill_tech_payment_approval':
            case 'tsk_rebill_gm_payment_approval':
            case 'tsk_rebill_coo_payment_approval':
            case 'tsk_defleet_tech_payment_approval':
            case 'tsk_defleet_gm_payment_approval':
            case 'tsk_defleet_coo_payment_approval':
            case 'tsk_purchase_order_tech_payment_approval':
            case 'tsk_purchase_order_gm_payment_approval':
            case 'tsk_purchase_order_coo_payment_approval':
                return this.getDOAGenericApproval()
            case 'tsk_creditors_invoice_first_approval':
            case 'tsk_creditors_invoice_second_approval':
            case 'tsk_creditors_invoice_third_approval':
                return this.getPaymentDOAApproval()
            case 'tsk_statement_review':
                return this.getStatementReview()
            case 'tsk_enterest_run':
                return this.getEnterestReview()
            case 'tsk_statement_preview_and_send':
                return this.getStatementPreview()
            case 'tsk_str_quotation_review':
            case 'tsk_str_quotation_approve':
                return this.getSTRQuotationReview()
            case 'tsk_str_quotation_vehicle_bookout':
                return this.getSTRQuotationBookout()
            case 'tsk_str_quotation_activation':
                return this.getSTRQuotationActivation()
            case 'tsk_supplier_master_review_and_approve':
                return this.getSupplierMasterActivation()
            case 'tsk_customer_master_review_and_approve':
                return this.getCustomerMasterActivation()
            case 'tsk_sales_process_review_and_approve':
                return this.getActivationSalesProcess()
            case 'tsk_accessory_master_review_and_approve':
                return this.getAccessoryMasterActivation()
            case 'tsk_costcentre_change_review_and_approve':
                return this.getCostcentreChangeActivation()
            case 'tsk_odometer_change_review_and_approve':
                return this.getOdometerChangeActivation()
            case 'tsk_registration_change_review_and_approve':
                return this.getRegistrationChangeActivation()

            case 'tsk_vehicle_pricing_review_and_approve':
                return this.getPricingMasterActivation()
            case 'tsk_creditors_payment_create_review_and_approve':
                return this.getCreditorsPaymentReview()
            case 'tsk_creditors_payment_allocate':
                return this.getCreditorsPaymentAllocate()
            case 'tsk_credit_note_review_and_approve':
                return this.getCreditNoteReviewAndApprove()
            case 'tsk_cashbook_archive_review_and_approve':
                return this.getCashbookArchiveReviewAndApprove()
            case 'tsk_credit_note_management_approve':
                return this.getCreditNoteManagementApprove()
            case 'tsk_interest_rate_review_and_approve':
                return this.getInterestRateReviewAndApprove()
            case 'tsk_fml_quote_release_note':
                return this.getFMLReleaseNote()
            case 'tsk_fml_quote_accept_release_note':
                return this.getFMLAcceptReleaseNote()
            case 'Other':
                return this.getConfirmationStepDocumentViewer(this.props.state, this.workflow_queue_data)
            default:
                return <div>{'Content View Not Set For ' + this.task}</div>
        }
    }

    getTaskUsersOFF(users) {
        return <>
            <ButtonGroup
                variant="contained"
                
                primary={true}
                className={"global_button"}
                color="primary"
                aria-label="split button">
                <Button
                variant="contained"
                
                primary={true}
                className={"global_button"}
                    onClick={(event,) => {
                        this.props.actions.approveWorkflowQueueTask(
                            this.workflow, 
                            this.task, 
                            this.props.name,
                            this.props.state.user)
                    }}
                    style={{ marginRight: 0 }}
                >{'Confirm'}</Button>
                {/* <Button
                    className="btn-transition-none px-2"
                    color="primary"
                    size="small"
                    aria-haspopup="true"
                    onClick={(event,) => {
                        this.props.actions.setToggleConfirm(true, event.currentTarget)
                    }}>
                    <ArrowDropDownIcon />
                </Button> */}
            </ButtonGroup>
            <Menu
                id="simple-menu2"
                anchorEl={this.props.state.confirmEl}
                keepMounted
                open={this.props.state.confirm}
                onClose={() => {
                    this.props.actions.setToggleConfirm(false, null)
                }}
                classes={{ list: 'p-0' }}>
                <div className="p-3">
                    {
                        users.map(ut => {
                            return <MenuItem className="pr-5 px-3"
                                onClick={(event,) => {
                                    this.props.actions.approveWorkflowQueueTask(
                                        this.workflow, 
                                        this.task, 
                                        this.props.name, ut)
                                }}>
                                {'Confirm To ' + ut.firstname}
                            </MenuItem>
                        })
                    }
                    <MenuItem className="pr-0 px-0"
                        onClick={(event,) => {
                            this.props.actions.approveWorkflowQueueTask(
                                this.workflow, 
                                this.task, 
                                null)
                        }}>
                        Confirm
                </MenuItem>
                </div>
            </Menu>
        </>
    }

    getTaskUsers(users) {
        return <>
        <Button
            variant="contained"
            primary={true}
            className={"global_button"}
            onClick={(event,) => {
                this.props.actions.approveWorkflowQueueTask(
                    this.workflow, 
                    this.task, 
                    this.props.name,
                    this.props.state.user)
            }}
            style={{ marginRight: 0 }}
        >{'Confirm'}</Button>
        </>
    }

    getTaskUser(user) {
        return <>
            <ButtonGroup
                variant="contained"
                primary={true}
                className={"global_button"}
                //className="btn-second m-2"
                color="primary"
                aria-label="split button">
                <Button
                    className="btn-transition-none"
                    onClick={(event,) => {
                        this.props.actions.approveWorkflowQueueTask(
                            this.workflow, 
                            this.task, 
                            this.props.name, user ? user[0] : this.props.state.user) //user[0])
                    }}
                >{user ? ('Approve Task') : 'Approve Task'}</Button>
            </ButtonGroup>
        </>
    }

    getTaskUserTome(user) {
        return <>
            <ButtonGroup
                variant="contained"
                className={"global_button btn-success"}
                //className="btn-second m-2"
                color="primary"
                aria-label="split button">
                <Button
                    className="btn-transition-none"
                    onClick={(event,) => {
                        this.props.actions.approveWorkflowQueueTask(
                            this.workflow,
                            this.task, 
                            this.props.name, this.props.state.user) //user[0])
                    }}
                >{'Approve'}</Button>
            </ButtonGroup>
        </>
    }

    getConfirmSection(connector) {
        return connector
            ? <div>
                <div className="d-flex align-items-right justify-content-right flex-wrap" style={{ float: 'right' }}>
                    <br />
                    <FontIcon className="muidocs-icon-custom-sort" ></FontIcon>

                    <Button variant="contained"
                        primary={true}
                        className={"global_button"}
                        label="Reject Task"
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.showReassignTask('reject_task', this.props.state.workflowQueueTasks.team_id, this.props.state.workflowQueueTasks.user_id)
                        }} >
                        {window.t.en('Reject Task')}
                    </Button>
                    &nbsp;
                    {
                        this.connector.nextTask.length && this.connector.nextTeam.length
                            ? this.connector.nextTask[0].users.length
                                ? this.getTaskUser(this.connector.nextTask[0].users)
                                : this.getTaskUsers(this.connector.nextTeam[0].users)
                            : this.getTaskUser()
                    }
                </div>
            </div>
            : <div>
                <div className="d-flex align-items-right justify-content-right flex-wrap" style={{ float: 'right' }}>
                    <br />
                    <FontIcon className="muidocs-icon-custom-sort" ></FontIcon>
                    
                    <Button variant="contained" 
                        primary={true}
                        className={"global_button"}
                        label="Reject Task"
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.showReassignTask('reject_task', this.props.state.workflowQueueTasks.team_id, this.props.state.workflowQueueTasks.user_id)
                        }} >
                        {window.t.en('Reject Task')}
                    </Button>
                    &nbsp;
                    {
                        this.getTaskUserTome()
                    }
                </div>
            </div>
    }

    getView() {
        let teams = []

        try {
            let team_id = this.props.state.task.workflow_task_team[0].team_id
            //console.log('team_id',team_id)
            let team = this.props.state.workflowQueueTasks.teams.filter(x => x.team_id === team_id)[0]
            
            teams = team ? this.props.state.teams[team.name].user_team : []

        }
        catch (err) {
        }


        this.connector = this.props.state.workflow_task_connector ? this.props.state.workflow_task_connector[0] : null
        this.workflow_queue = this.props.state.workflow_queue
        this.workflow_queue_data = this.workflow_queue.data[0]
        //console.log('Workflow_Stepperx APPROVE state', this.props.state)
        //console.log('Workflow_Stepperx APPROVE task', this.props.state.task)
        //console.log('Workflow_Stepperx APPROVE data', this.workflow_queue_data)
        return <>
            <>
                {/* <Row>
                    <Col xl={12}>
                        <WorkflowTaskHeader
                            title={window.t.en(this.props.state.workflowQueueTasks.workflow_task.title + '  #' + this.props.state.workflowQueueTasks.queue.workflow_queue_id)}
                            description={window.t.en('Task ' + this.props.state.workflowQueueTasks.workflow_task.title + ', require your approval.  ')}
                        />
                        <Workflow_Stepper order={this.props.state.task.order} workflow_id={this.props.state.task.workflow_id}/>
                        {this.getLayout()}
                    </Col>
                </Row> */}
                <Row>
                    <Col xl={12}>
                        <Workflow_Stepper 
                            name={this.props.state.task.name} 
                            order={this.props.state.task.order} 
                            workflow_id={this.props.state.task.workflow_id}
                        />
                        {this.getLayout()}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {
                            !this.props.state.reassignTask.visible && this.connector
                                ? <CardControl
                                    title={this.connector.nextTask.length ? 'Next Task: ' + this.connector.nextTask[0].title : 'Approve task'}
                                    subtitle={
                                        this.connector.nextTask.length
                                            ? this.connector.nextTask[0].users.length
                                                ? 'Assign Task To: ' + this.connector.nextTask[0].users[0].firstname
                                                : 'Task to be assign to you'
                                            : 'Review and approve task'
                                    }
                                    content={this.getConfirmSection(this.connector)}
                                />
                                : <CardControl
                                    title={'Approve task'}
                                    subtitle={'Review and approve task'}
                                    content={this.getConfirmSection()}
                                />
                        }

                        {this.props.state.reassignTask.visible &&
                            <ReassignTask
                                key={'workflow_task_approval_' + this.workflow_queue_data.workflow_queue_task_id}
                                workflow_queue_task_id={this.workflow_queue_data.workflow_queue_task_id}
                                team_id={this.props.state.workflowQueueTasks.team_id}
                                user_id={this.props.state.workflowQueueTasks.user_id}
                            />
                        }
                    </Col>
                </Row>
            </>
            <Modal open={this.props.state.reject} little>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {/* <TextField
                                hintText="Enter message here"
                                errorText="Please Enter Reject Reason."
                                floatingLabelText="Please Enter Reject Reason."
                                multiLine={true}
                                rows={2}
                            /> */}
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className={"global_button"} variant="contained"
                                secondary={true}
                                label="Cancel"
                                style={styles.ButtonMargins}
                                icon={<Icon iclass={'material-icons'} iname={'clear'} />}
                                onClick={(event) => {
                                    this.props.actions.closeReject(event)
                                }}
                            ><Icon iclass={'material-icons'} iname={'clear'} /> Cancel</Button>
                            &nbsp;
                            <Button className={"global_button"} variant="contained"
                                primary={true}
                                label="Done"
                                style={styles.ButtonMargins}
                                icon={<Icon iclass={'material-icons'} iname={'done_all'} />}
                                onClick={() => {
                                    this.props.actions.rejectWorkflowQueueTask(this.props.state.task)
                                }}
                            ><Icon iclass={'material-icons'} iname={'done_all'} /> Done</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
            <Modal open={this.props.state.viewpdf} style={styles.popup} little>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <h2>{'Please localize this content view'}</h2>
                            {/* {this.getDocuments()} */}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            < Button className={"global_button"}
                                secondary={true}
                                label="Close Window"
                                icon={<Icon iclass={'material-icons'} iname={'cancel'} />}
                                onClick={() => {
                                    this.props.actions.getClearDocuments()
                                }}
                            ><Icon iclass={'material-icons'} iname={'cancel'} /> Close Window</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </>
    }

    loading() {
        let loading = false

        if (this.props.state.loading) {
            return true
        }

        if (this.props.state.task && this.props.state.task.components) {
            for (let index = 0; index < this.props.state.task.components.length; index++) {
                try {
                    if (this.props.state.components[this.props.state.task.components[index]].loading) {
                        loading = true
                        break
                    }
                } catch (error) {
                    loading = false
                    break
                }
            }

            if (!this.props.state.workflow_queue.data) {
                loading = true
            }
            else {
                if (!this.props.state.workflow_queue.data.length) {
                    loading = true
                }
            }

            return loading
        }
        else {
            return true
        }
    }

    render() {
        return this.loading()
            ? <Loading message={'Retrieving task information...'} />
            : this.props.state.error
                ? 'Unable to retrieve task information, please try again...'
                : this.getView()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowTaskApproval,
            workflowQueueTasks: {
                ...state.workflowQueueTasks
            },
            components: {
                ...state.components
            },
            input: {
                ...state.workflowTaskInput
            },
            teams: {
                ...state.teams
            },
            surrogateheader: {
                ...state.components['surrogateheader']
            },
            document: {
                ...state.components['document']
            },
            user: {
                ...state.user
            },
            reassignTask: {
                ...state.reassignTask
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...reassignTaskActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskApproval)