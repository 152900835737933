import * as types from './types'

export const getQueuedTaskCount = (team_id, user_id) => {
    return {
        type: types.GET_QUEUED_TASK_COUNT,
        payload: {
            team_id: team_id,
            user_id: user_id,
            body: {
                query: 'queued_tasks_count',
                team_id: team_id
            }
        }
    }
}

export const setQueuedTaskCount = (team_id, data) => {
    return {
        type: types.SET_QUEUED_TASK_COUNT,
        payload: {
            team_id: team_id,
            data: data
        }
    }
}