import * as types from './types'
import * as appTypes from '../App/types'

import moment from 'moment'

export const setInputValue = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_CUSTOMER_DERIVATIVE_CATEGORY_INPUT_VALUE,
            payload: data
        })
    }
}

export const toggleListValue = (data) => {
    return (dispatch, getState) => {
        let vehicle_category = getState().customerDerivativeCategory.vehicle_category
        vehicle_category = vehicle_category.map((x,i) => {return {...x,config: i === data.inx ? data.value : false}})
        dispatch(setInputValue({prop: data.prop, value: vehicle_category}))
    }
}

export const getCategory = () => {
    return (dispatch, getState) => {
        //console.log('getCategoryx',getState().components.customerderivative)
        let id = getState().components.customerderivative.selected[0].original.customerderivative_id
        dispatch({
            type: types.GET_COMPONENT_CUSTOMER_DERIVATIVE_CATEGORY_LIST,
            payload: id
        })
    }
}

export const setCategory = (response) => {
    return (dispatch, getState) => {
        dispatch(setInputValue({prop: 'vehicle_category', value: response.data}))
    }
}

export const setListValue = (data) => {
    return (dispatch, getState) => {
        let vehicle_category = getState().customerDerivativeCategory.vehicle_category
        vehicle_category[data.inx][data.prop] = data.value
        dispatch(setInputValue({prop: 'vehicle_category', value: vehicle_category}))
    }
}

export const setDataList = (data) => {
    return (dispatch, getState) => {
        let _list = getState().customerDerivativeCategory[data.prop]
        _list.push(data.value)
        dispatch(setInputValue({prop: data.prop, value: _list}))
    }
}

export const saveDataList = (data) => {
    //console.log('saveCategory', data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_COMPONENT_CUSTOMER_DERIVATIVE_CATEGORY_RELATED,
            payload: data
        })
    }
}

export const delDataList = (data) => {
    return (dispatch, getState) => {
        let _list = getState().customerDerivativeCategory[data.prop]
        _list = _list.filter(x => x.name !== data.value)
        dispatch(setInputValue({prop: data.prop, value: _list}))
    }
}

export const addBankCategory = (customerderivative_id) => {
    return (dispatch, getState) => {
        let vehicle_category = getState().customerDerivativeCategory.vehicle_category
        vehicle_category.push({editing: true, customerderivative_id: customerderivative_id,client_id: 0,categorymode: '',customerderivativecategory: '',customerderivativecategorydescription: ''})
        dispatch(setInputValue({prop: 'vehicle_category', value: vehicle_category}))
    }
}


export const saveCategory = (data) => {
    //console.log('saveCategory', data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_COMPONENT_CUSTOMER_DERIVATIVE_CATEGORY,
            payload: data
        })
    }
}

export const delCategory = (data) => {
    //console.log('delCategory', data)
    return (dispatch, getState) => {
        dispatch({
            type: types.DEL_COMPONENT_CUSTOMER_DERIVATIVE_CATEGORY,
            payload: data
        })
        dispatch(getCategory())
    }
}

export const saveCategoryDone = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Vehicle category saved',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(getCategory())
    }
}


export const delCategoryDone = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Vehicle category deleted',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(getCategory())
    }
}


export const getLookupValueData = (table, field, search, related = '') => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CUSTOMER_DERIVATIVE_LOOKUP_DATA,
            payload: {
                table: table,
                field: field,
                search: search,
                related: related
            }
        })
    }
}
export const getMMCodeLookupValueData = (table, field, search, related = '') => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MMCODE_COMPONENT_CUSTOMER_DERIVATIVE_LOOKUP_DATA,
            payload: {
                table: table,
                field: field,
                search: search,
                related: related
            }
        })
    }
}

export const getOtherLookupValueData = (table, field, search, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CUSTOMER_DERIVATIVE_OTHER_LOOKUP_DATA,
            payload: {
                ...data,
                table: table,
                field: field,
                search: search,
                query: 'component_distinct',
            }
        })
    }
}

export const setOtherLookupValueData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setOtherLookupValueData response', response)
        //console.log('setOtherLookupValueData payload', payload)
        let data = response.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.field] 
            }
        })
        dispatch(setInputValue({prop: payload.table + '_data', value: data}))
    }
}


export const setLookupValueData = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setLookupValueData response', response)
        //console.log('setLookupValueData payload', payload)
        let data = response.data.map(x => {
            return {
                ...x,
                text: x[payload.field],
                value: x[payload.table] + '_id'
            }
        })
        dispatch(setInputValue({prop: payload.table + '_data', value: data}))
    }
}
