import * as actions from "./actions";
import * as progressBarActions from "../ProgressBar/actions";
import * as requestActions from "../Request/actions";
import * as routerActions from "../Redirect/actions";
import * as types from "./types";
import * as requestTypes from "../Request/types";
import * as config from "../../config";

export const getClientsList = (
  action$,
  store,
  { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }
) =>
  action$.pipe(
    ofType(types.CLIENTS_GET_CLIENTS_LIST),
    mergeMap(() =>
      ajax$({
        url: config.system.api + "/components/client/query",
        method: "GET",
        headers: {
          tokenx: store.value.user.user_id,
          auth: store.value.cognito.authenticateUser.token,
          salt: store.value.cognito.authenticateUser.jwt,
          datetime: store.value.cognito.authenticateUser.datetime,
          related: "clientaddress.address",
        },
      }).pipe(
        mergeMap((payload) => [
          actions.setClientsList(payload.response),
          requestActions.requestSucceeded(
            config.system.api + "/components/client"
          ),
        ]),
        startWith(progressBarActions.progress(true)),
        catchError((error) =>
          of$(
            requestActions.requestFailed(
              config.system.api + "/components/client",
              error
            )
          )
        )//,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
      )
    ),
    catchError((error) => of$(routerActions.route("/support", false, error)))
  );
