export const visibility = (toggled) => {
    return toggled
        ? { display: 'block' }
        : { display: 'none' }
}

export const commands = {
    textAlign: 'right'
}

export const item = {
    display: 'flex'
}

export const flex = {
    width: '100%',
    display: 'flex'
}

export const table = {
    width: '100%',
    display: 'flex'
}

export const cell = {
    display: 'cell',
    width: '50%'
}

export const dropdown = {
    width: '100%'
}

export const right = {
    textAlign: 'right',
    display: 'cell',
    width: '50%'
}

export const fieldContainer = {
    display: 'table',
    padding: 4,
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '20px',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}


export const pullright = {
    textAlign: 'right',
    float: 'right',
    marginBottom: 30
}