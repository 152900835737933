import Button from '@mui/material/Button'
import { Card, CardHeader, CardContent } from '@mui/material'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Col, Container, Row } from 'react-grid-system'
import Iframe from 'react-iframe'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'
import WorkflowViewFMLQuoteConfirm from '../WorkflowViewFMLQuoteConfirm/component'
import * as actions from './actions'
import * as styles from './styles'





class WorkflowPOUploadQuote extends Component {
   
    getFMLUpload(component) {
        return <div>
            <Card style={styles.card}>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Upload')}
                    subtitle={window.t.en('Complete RFQ details for ')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        {
                            this.props.state.document.data.content.text
                                ? <Row>
                                    <Col xl={4}>{'Upload RFQ Document'}:</Col>
                                    <Col xl={7}>
                                        {
                                            this.props.state.document.data.content.text
                                                ? <Iframe url={this.props.state.document.data.content.text}
                                                    width="100%"
                                                    height="100%"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allowFullScreen />
                                                : 'No File..'
                                        }
                                    </Col>
                                    <Col xl={1}>
                                       < Button className={"global_button"} variant="contained"
                                            primary={true}
                                            label="Clear"
                                            onClick={(event) => {
                                                this.props.actions.clearRFQDocument(component)
                                            }}
                                            >{window.t.en('Clear')}</Button>
                                            </Col>
                                </Row>
                                : <Row>
                                    <Col xl={4}>{'Select RFQ Document'}:</Col>
                                    <Col xl={8}>
                                        <Dropzone
                                            ////accept={'/*'}
                                            style={styles.dropZone}
                                            onDrop={(files) => {
                                                //component.actions.toggleDropzoneVisibility()
                                            }}
                                            onDropAccepted={(files) => {
                                                this.props.actions.setAcceptedDocuments(this.props.state.document, files)
                                            }}
                                            onDropRejected={(files) => {
                                                this.props.actions.setRejectedDocuments(this.props.state.document, files)
                                            }}
                                        >
                                                                <p style={styles.watermark}>
                                                                    Drag and drop file here, or click to select file to upload.
                                                                </p>
                                        </Dropzone>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    setWorkflowQuote(component) {
        this.field = this.props.state.document.component_field.reduce((obj, x) => {
            obj[x.name] = x
            return obj
        }, {})

        this.getFMLUpload(component)
    }

    render() {
        const component = this.props.state.component

        this.data = this.props.state.step.task.workflow_task_step
            ? this.props.state.step.task.workflow_task_step
                .filter(x =>
                    x.name === this.props.step &&
                    x.workflow_task_step_component.filter(y => y.component.name === this.props.name).length
                )
                .reduce((arr, x) => {
                    if (x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length).length) {
                        arr.push(x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length)[0].data)
                    }

                    return arr
                }, [])
            : []


        return (
            this.data.length
                ? (
                    <div>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    <WorkflowViewFMLQuoteConfirm
                                        id={'strvehicle_0_' + this.field.surrogateheader_id}
                                        data={{
                                        quote: this.props.state.quote.grid.data,
                                        suppliers: this.props.state.component.grid.data
                                    }}
                                        name={this.props.name} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                   < Button className={"global_button"} variant="contained"
                                        label={'Change'}
                                        primary={true}
                                        style={styles.right}
                                        disabled={!component.grid.data.length}
                                        onClick={(event) => {
                                            this.props.actions.resetSelection(this.props.step, this.props.name)
                                        }}
                                        >{window.t.en('Change')}</Button>
                                </Col>
                            </Row>
                        </Container>

                        <br />
                    </div>
                )
                : (<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {this.setWorkflowQuote(component)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} style={styles.confirmSelection}>
                           < Button className={"global_button"} variant="contained"
                                label={'Done'}
                                primary={true}
                                disabled={!component.grid.data.length}
                                onClick={(event) => {
                                    this.props.actions.confirmSelection(this.props.step, component)
                                }}
                                >{window.t.en('Done')}</Button>
                        </Col>
                    </Row>
                </Container>
                )
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowQueueTasks,
            step: {
                ...state.workflowTaskInput
            },
            component: {
                ...state.components[ownProps.name]
            },
            document: {
                ...state.components['document']
            },
            quote: {
                ...state.components['customerquote']
            },
            supplier: {
                ...state.components['supplier']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...workflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPOUploadQuote)