import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as config from '../../config'
import * as actions from './actions'
import JSReport from '../JSReport/embed'
import Loading from '../Loading/component'
import * as styles from './styles'
import {ReactComponent as NewTaskIcon} from '../../assets/img/svg/icons/kpi/new_task.svg';
import {ReactComponent as CompletedTasksIcon} from '../../assets/img/svg/icons/kpi/completed_tasks.svg';
import {ReactComponent as AssignedTasksIcon} from '../../assets/img/svg/icons/kpi/assigned_task.svg';

class Explore extends Component {

    componentWillMount(){
        this.props.actions.getReportToken(this.props.url)
    }

    viewDashboard(oqlis_token_url) {
        return (
            <div style={{ marginTop: '0px' }}>
                <iframe
                    src={oqlis_token_url} width="100%"
                    height={"950px"} style={{ border: "none", alignSelf: "center", }}>
                </iframe>
            </div >
        )
    }


    render() {
        return this.props.state.oqlis.oqlis_token_url ? this.viewDashboard(this.props.state.oqlis.oqlis_token_url) : 'Please waiting...'
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            oqlis: {
                ...state.oqlis
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, }
            , dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Explore)