import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'



export const getLookupValueData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.GET_COMPONENT_CREATE_STR_DATA_FIELD_INPUT_LIST),
        delay(0)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.component + '/search?'+ payload.query,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
'salt': store.value.cognito.authenticateUser.jwt,
'datetime': store.value.cognito.authenticateUser.datetime,
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setLookupValueData(payload.component, payload.field, result.response),
                    //progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/' + payload.component + '/search?'+ payload.query)
                ])
                //,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.component + '/search?'+ payload.query, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


        export const getCustomerData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
        action$.pipe(
            ofType(types.GET_STR_CUSTOMER_DATA_LIST),
            delay(0)
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/customer/search?customer_id='+ payload.customer_id,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                            'auth': store.value.cognito.authenticateUser.token,
        'salt': store.value.cognito.authenticateUser.jwt,
        'datetime': store.value.cognito.authenticateUser.datetime,
        'related':'marketingtype'
                    },
                })
                    .pipe(
                    mergeMap((result) => [
                        actions.setCustomerData(result.response),
                        progressBarActions.progress(false),
                        requestActions.requestSucceeded(config.system.api + '/components/customer/search?customer_id='+ payload.customer_id)
                    ])
                    //,startWith(progressBarActions.progress(true))
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api +'/components/customer/search?customer_id='+ payload.customer_id, error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            ),
            catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
        )
