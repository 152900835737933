//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold',
    textAlign: 'left',
}

export const subheader = {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '16px',
    color: 'gray',
    padding: '10px'
}

export const icon = {
    fontSize: '10px'
}

export const row = {
    fontSize: 16,
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const numericFieldsHeader = {
    textAlign: 'right',
    fontWeight: 'bold',
}

export const numericFields = {
    textAlign: 'right',
}

export const itemrow = {
    fontSize: '16',
    borderBottom: '1px dashed gray',
}

export const headerrow = {
    backgroundColor: '#666',
}

export const vehicle = {
    width: '150px'
}

export const numericFieldValues = {
    textAlign: 'right',
}

export const fieldLabelA = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingTop: 10,
    paddingRight: 10
}

export const fieldLabelDates = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingTop: 10,
    paddingRight: 10
}