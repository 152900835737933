export const select_supplier = {}
export const client_data = []
export const supplier_data = []
export const credited_data = []
export const penalties_data = []
export const payment_document = []
export const supplier_transactions = []
export const statement_amount = '0.00'
export const shortpayment_amount = '0.00'
export const discount_percentage = '0.00'
export const discount_amount = '0.00'
export const credited_amount = '0.00'
export const payable_amount = '0.00'
export const payment_date = ''
export const supplier_id = ''
export const suppliername = ''
export const client_id = ''
export const title = ''
export const transactionx = {}
export const enatis_received = false

export const selected_data = {}
export const accountingitem_data = []
export const accountingitem_id = null
export const itemname = ''

export const fields = {
  supplier_transactions: {
    errorText: 'Please select Supplier Transaction',
    state: 'getState().creditorsPaymentCreate.supplier_transactions.find(x=>x.pay_status === true)',
    valid: false
  },

  discount_percentage: {
    errorText: 'Please input Discount Percentage',
    state: ' getState().creditorsPaymentCreate.supplier_transactions.find(x=>x.apply_discount === true) ? getState()?.creditorsPaymentCreate?.discount_percentage : true',
    valid: false,
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Discount Percentage'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Discount Percentage'
      }
    ]
  },
  statement_amount: {
    errorText: 'Please input Statement Amount',
    state: 'getState().creditorsPaymentCreate.statement_amount',
    valid: false,
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Statement Amount'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Statement Amount'
      }
    ]
  },

  creditedAmount: {
    errorText: 'Please input Credit Amount',
    state: 'getState().creditorsPaymentCreate.credited_data?.length ?  getState().creditorsPaymentCreate.credited_data : true',
    valid: false,
    type: 'table',
    tableFields: ['amount'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Credit Amount'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Credit Amount'
      }
    ]
  },
  creditedReason: {
    errorText: 'Please input Credit Reason',
    state: 'getState().creditorsPaymentCreate.credited_data?.length ?  getState().creditorsPaymentCreate.credited_data : true',
    valid: false,
    type: 'table',
    tableFields: ['reason']
  },
  creditedReference: {
    errorText: 'Please input Credit Reference',
    state: 'getState().creditorsPaymentCreate.credited_data?.length ?  getState().creditorsPaymentCreate.credited_data : true',
    valid: false,
    type: 'table',
    tableFields: ['reference']
  },
  creditedDate: {
    errorText: 'Please select Credit Date',
    state: 'getState().creditorsPaymentCreate.credited_data?.length ?  getState().creditorsPaymentCreate.credited_data : true',
    valid: false,
    type: 'table',
    tableFields: ['date']
  },

  penaltiesAmount: {
    errorText: 'Please input Penalties Amount',
    state: 'getState().creditorsPaymentCreate.penalties_data?.length ?  getState().creditorsPaymentCreate.penalties_data : true',
    valid: false,
    type: 'table',
    tableFields: ['amount'],
    validations: [
      {
        'schema': 'decimal',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Penalty Amount'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Penalty Amount'
      }
    ]
  },
  penaltiesReason: {
    errorText: 'Please input Penalties Reason',
    state: 'getState().creditorsPaymentCreate.penalties_data?.length ?  getState().creditorsPaymentCreate.penalties_data : true',
    valid: false,
    type: 'table',
    tableFields: ['reason']
  },
  penaltiesReference: {
    errorText: 'Please input Penalties Reference',
    state: 'getState().creditorsPaymentCreate.penalties_data?.length ?  getState().creditorsPaymentCreate.penalties_data : true',
    valid: false,
    type: 'table',
    tableFields: ['reference']
  },
  penaltiesDate: {
    errorText: 'Please select Penalties Date',
    state: 'getState().creditorsPaymentCreate.penalties_data?.length ?  getState().creditorsPaymentCreate.penalties_data : true',
    valid: false,
    type: 'table',
    tableFields: ['date']
  },

  documents: {
    errorText: 'Please add a document and select document type',
    data: false,
    valid: false,
    empty: true,
    popup: true,
    type: 'documents',
  },

  statement_date: {
    errorText: 'Please select Statement Date',
    state: 'getState().creditorsPaymentCreate.statement_date',
    valid: false
  },
  payment_date: {
    errorText: 'Please select Payment Date',
    state: 'getState().creditorsPaymentCreate.payment_date',
    valid: false
  },
  bank: {
    errorText: 'Please select GL Account',
    state: 'getState().creditorsPaymentCreate.accountingitem_id',
    valid: false
  },
  business: {
    errorText: 'Please select Business Account',
    state: 'getState().creditorsPaymentCreate.client_id',
    valid: false
  },
  supplier: {
    errorText: 'Please select Supplier',
    state: 'getState().creditorsPaymentCreate.supplier_id',
    valid: false
  },
}