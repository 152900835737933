import * as appTypes from '../App/types'
import * as types from './types'
import moment from 'moment'

export const setUserInput = (prop, message, saveReport) => {
    return (dispatch, getState) => {
        if (saveReport) {
            ['report_title', 'selected_report_type', 'key', 'sub_category','subcategory_id'].map(x => {
                dispatch({
                    type: types.SET_CREATE_USER_INPUT,
                    payload: {
                        data: null,
                        prop: x
                    }
                })
            })
        } else {
            dispatch({
                type: types.SET_CREATE_USER_INPUT,
                payload: {
                    data: message,
                    prop: prop
                }
            })
            let user = getState().user.user_id

            dispatch({
                type: types.SET_CREATE_USER_INPUT,
                payload: {
                    data: user,
                    prop: 'user'
                }
            })
        }

    }
}

export const getSubData = (prop) => {
    return (dispatch, getState) => {
        let _ofType = prop == 'Report'? 'reports': prop == 'Dashboard'? 'dashboard': null

        dispatch({
            type: types.GET_SUB_CARTEGORIES,
            payload: {
                type: 'GET_SUB_CARTEGORIES',
                cat_type: _ofType
            }
        })
    }
}



export const setSubData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUB_CARTGORIES,
            payload: {
                data: data.data.map(c => {
                    return{
                        'text': c.sub_category,
                        'value': c.subcategory_id,
                        'reportcategory_id': c.reportcategory_id
                    }
                }),
                prop: 'cartegory'
            }
        })
    }
}

export const saveReport = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_REPORT,
            payload: {
                type: 'SAVE_REPORT',
                data: getState().create_report
            }
        })
        dispatch(setUserInput('','',true))
    }
}


export const saveReportFeedback = (data) => {
    return (dispatch, getState) => {
       if(data.data){
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Report exist already',
                overflowText: 'Report exist already',
                autoHide: 10000,
                timestamp: moment().format('h:mm A'),
                type: 'warning'
            }
        })
       }else{
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Succesfully Saved',
                overflowText: 'Report saved',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
       }
    }
}

