import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'


import AutoComplete from '../../../controls/autocomplete'
import Checkbox from '../../../controls/checkbox'
import TextField from '../../../controls/textField'


import Icon from '../../Icon/component'

import * as actions from './actions'
import * as styles from './styles'

import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"
import "react-table/react-table.css"
import * as compActions from '../../Components/actions'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

class CUSTOMCOSTCENTRECHANGE extends Component {
    componentDidMount() {
        if (this.props.state.loading) {
            this.props.actions.getComponent('customcostcentrechange')
            this.props.actions.getCostCentreChangeList('')
        }
    }
    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "approved") {
            return <div>< Button className={"global_button"}
                disabled={row.original.approved ? true : false}
                label={row.original.approved ? "Approved" : "Approve"}
                primary={true}
                onClick={(event) => {

                    var obj = {
                        costcenterchange_id: row.original.customcostcentrechange_id,
                        customercontract_id: row.original.customercontract_id,
                        vehicle_id: row.original.vehicle_id,
                        newcostcentre_id: row.original.newcostcentre_id,
                        newcostcentre: row.original.newcostcentre,
                        contractnumber: row.original.contractnumber,
                        user: this.props.state.user
                    }
                    this.props.actions.approveCostCentreChange(obj)
                }}
            >{row.original.approved ? "Approved" : "Approve"}</Button>{' '}{row.original.approved ? ' '
                : < Button className={"global_button"} variant="contained"
                    disabled={row.original.approved ? true : false}
                    label={'Cancel'}
                    primary={true}
                    onClick={(event) => {
                        var obj = {
                            costcenterchange_id: row.original.customcostcentrechange_id,
                            contractnumber: row.original.contractnumber,
                            user: this.props.state.user
                        }
                        this.props.actions.cancelCostCentreChange(obj)
                    }}
                >{window.t.en('Cancel')}</Button>}</div>
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black', //'#4A4A4A',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        }

        return columns
    }

    getGrid() {
        return <div> < Button className={"global_button"}
            secondary={true}
            label="Excel Export"
            icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
            onClick={(event) => {
                this.props.actions.excelExport(this.component)
            }}
        ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Excel Export</Button>{<TextField
            variant="outlined"
            margin="normal"
            hintText={'Search by Contract/Reg Number...'}
            value={this.props.state.data.customcostcentrechange_id.input}
            //value={this.props.state.data.customerallocation_id.input}
            onChange={(event, value) => {
                this.props.actions.setInputValue('customcostcentrechange_id', value)
                this.props.actions.getCostCentreChangeList(value)
            }}
        />}{< Button className={"global_button"}
            icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
            onClick={(event) => {
                event.preventDefault()
                //this.props.actions.getFilterAllocationsData(this.props.state.data.customerallocation_id.input,cust_id)
                this.props.actions.getCostCentreChangeList(this.props.state.data.customcostcentrechange_id.input)
            }}
        ><Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} /></Button>}<ReactTable
            key={'grid_' + this.component.name + '_' + this.component.component_id}
            //className='-striped -highlight'
            data={this.props.state.data.customcostcentrechange_id.data}
            columns={this.getColumns()}
            defaultPageSize={this.component.grid.paging.pageSize}
            manual
            onFetchData={
                (state) => {
                }
            }
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.selectRow(this.component.name, rowInfo)

                        if (this.props.toggle) {
                            //this.props.actions.toggleInfoSheet()
                        }
                    },
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            onResizedChange={(newResized, event) => {
                //this.props.actions.resizeColumn(this.component.name, newResized)
            }}
            minRows={1}
            pages={this.component.grid.paging.pageCount}
            showPageJump={false}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable></div>
    }

    changeCostCenterTab(){
        return <Card style={styles.card} style={styles.row}>
        <CardHeader
            title={window.t.en('Change Cost Centre')}
            subtitle={window.t.en('Capture Cost Centre Change')}
            actAsExpander={true}
            showExpandableButton={true}
        />
        <CardContent> <Container style={styles.whitediv}>
            <Row>
                <Col xl={7}>
                    <Container style={styles.whitediv}>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Registration:'}</div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                        info={{
                                            show: false
                                        }}
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'dll_invoice_id'}
                                            key={'dll_invoice_id'}
                                            hintText={'Search for vehicle...'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            data={this.props.state.data.vehicle_id.data}
                                            onSelect={
                                                (chosenRequest, index) => {
                                                    this.props.actions.setLookupValue("vehicle_id", chosenRequest.value, chosenRequest.text)
                                                    //if (chosenRequest.value) {
                                                    this.props.actions.getVehicleDetails(chosenRequest.value)
                                                    //   this.props.actions.setInputValue('terminationdate', new Date())
                                                    // }
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getAllVehicles('')
                                                }
                                            }
                                            onFilter={
                                                (search, dataSource, params) => {
                                                    this.props.actions.getAllVehicles(search)
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row><Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Change From:'}</div>
                                <div style={styles.fieldContent} disabled={true} ><TextField
                                    variant="outlined"
                                    margin="normal"
                                    id={'txt_customername'}  
                                    hintText='Current Cost Centre'
                                    value={this.props.state.details.customer}
                                    fullWidth={true}
                                    onChange={(event, value) => {
                                    }}
                                />
                                </div>
                            </div>
                        </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Change To:'}</div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                        info={{
                                            show: false
                                        }}
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'dll_invoice_id'}
                                            key={'dll_invoice_id'}
                                            hintText={'Search for cost centre...'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            data={this.props.state.data.newcostcentre_id.data}
                                            onSelect={
                                                (chosenRequest, index) => {
                                                    this.props.actions.setLookupValue("newcostcentre_id", chosenRequest.value, chosenRequest.text)
                                                    //if (chosenRequest.value) {
                                                    //this.props.actions.getVehicleDetails(chosenRequest.value)
                                                    //this.props.actions.setInputValue('terminationdate', new Date())
                                                    //}
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getAllCostCentres('')
                                                }
                                            }
                                            onFilter={
                                                (search, dataSource, params) => {
                                                    this.props.actions.getAllCostCentres(search)
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        
                    </Container>
                </Col>
                <Col xl={5} style={styles.rightpanel}>
                    <Row>
                        <Col xl={12}>
                            <Row style={styles.headerrow}>
                                <Col xl={2}>
                                    {'Contract'}
                                </Col>
                                <Col xl={8}>
                                    {'Changed To'}
                                </Col>
                                <Col xl={2}>
                                    {'Date'}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={styles.itemrow}>
                        <Col xl={12}>
                            <Row>
                                <Col xl={2}>
                                    {'Contract #'}
                                </Col>
                                <Col xl={8}>
                                    {'Changed To...'}
                                </Col>
                                <Col xl={2}>
                                    {'Date'}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                </Row>
            </Container>
                style={{ textAlignLast: 'right' }}
                <Row><Col xl={10}>
                </Col>
                    <Col xl={2} style={{ textAlignLast: 'right' }}>
                        < Button className={"global_button"} variant="contained"
                            header={false}
                            //disabled={this.props.state.data.newcostcentre_id.input && this.props.state.data.vehicle_id.input ? false : true }
                            label={'Save Change'}
                            style={{ textAlignLast: 'right' }}
                            onClick={(event) => {
                                var obj = {
                                    values: {
                                        customercontract_id: this.props.state.details.customercontract_id,
                                        currentcostcentre_id: this.props.state.details.customer_id,
                                        vehicle_id: this.props.state.data.vehicle_id.input,
                                        newcostcentre_id: this.props.state.data.newcostcentre_id.input,
                                    },
                                    user: this.props.state.user
                                }
                                this.props.actions.saveCostCentreChange(obj)
                            }}
                        >{window.t.en('Save Change')}</Button>
                    </Col>
                </Row>
        </CardContent>
    </Card>

    }

    gridTab(){
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row>
            <Col xl={12}>
                {this.getGrid()}
            </Col>
        </Row>
    </Container>
    }

    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <div>
            <Tabs value={this.props.state.details.currentTab}
                    onChange={
                        (event, value) => {
                            this.props.actions.setCurrentTab(value)
                        }
                    }>
                <Tab label={'Cost Centre Change List'} id={'Cost Centre Change List'} />
                <Tab label={'Change Cost Centre'} id={'Change Cost Centre'} />
            </Tabs>
            {this.props.state.details.currentTab ===0 && this.gridTab()} 
            {this.props.state.details.currentTab ===1 && this.changeCostCenterTab()}
        </div>
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.customcostcentrechange,
            details: {
                ...state.costcentrechangecomponentview,
            },
            upload: {
                ...state.upload
            }, user: {
                ...state.user
            },

        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMCOSTCENTRECHANGE)