import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import GeneralContentView from '../../views/GeneralContentView/component'

import Loading from '../Loading/component'
import TeamOptions from './TeamOptions/component'
import UserOptions from './UserOptions/component'
import Events from '../Events/component'

import * as actions from './actions'

class TeamManagement extends Component {

    getTabs() {
      return <div style={{paddingBottom: '15px'}}>
            <Tabs
                initialSelectedIndex={0}
                value={this.props.state.tab}
                inkBarStyle={{
                    height: 4
                }}
                indicatorColor="primary"
                onChange={(event, value) => {
                    this.props.actions.changeTeamsTab(value)
                }}
            >
                <Tab className='' style={{minWidth:"33%"}} label={'Teams'} />
                <Tab style={{minWidth:"33%"}} label={'Users'} />
                <Tab style={{minWidth:"33%"}} label={'Events'} />
            </Tabs>
            <div>
                {this.props.state.tab === 0 && <Box><br/><TeamOptions /></Box>}
                {this.props.state.tab === 1 && <Box><br/><UserOptions /></Box>}
                {this.props.state.tab === 2 && <Box><br/><Events /></Box>}
            </div>
        </div>  
    }
    render() {
        return this.props.state.user.loading || this.props.state.team.loading
            ? <Loading message={'Retrieving information, please wait...'} />
            : <GeneralContentView
                content={
                    this.getTabs()
                }
                headerImage={''}
                title={''}
                //contentHeight={'750px'}
            />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.teamManagement,
            user: {
                ...state.components['user']
            },
            team: {
                ...state.components['team']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamManagement)