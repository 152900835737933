export const tile_view = false
export const create_mode = false
export const toggle_card_status = false 
export const cardmorewin = false 
export const cardmorewinel = null 
export const cardsettingswin = true 
export const cardsettingswinel = null 
export const cardstatuswin = false 
export const cardstatuswinel = null 
export const cardnotificationwin = false 
export const cardtransactionview = false 
export const cardsettings = false 
export const cardnotificationwinel = null 
export const cardnotificationtitle = ''
export const update_card_status = ''

export const secretkey_dialog = false

export const input = {}
export const tab = 0
export const mandatetab = ''
export const status_reason = ''
export const cards = []
export const card = null
export const others = []
export const vehicles = []
export const drivers = []
export const costcentre = []
export const card_search = ''
export const cards_header = [
    {
      name: "card_id",
      display: "card_id",
      title: "card_id",
      key: true,
      type: "text",
      width: 120,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "cardname",
      display: "cardname",
      title: "Card Name",
      key: false,
      type: "text",
      width: 300,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "cardnumber",
      display: "cardnumber",
      title: "Card Number",
      key: false,
      type: "text",
      width: 220,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "cardtype",
      display: "cardtype",
      title: "Card Type",
      key: false,
      type: "text",
      width: 220,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "product",
      display: "product",
      title: "Card Product",
      key: false,
      type: "text",
      width: 220,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "cardstatus",
      display: "cardstatus",
      title: "Card Status",
      key: false,
      type: "text",
      width: 180,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "loyalty",
      display: "loyalty",
      title: "Loyalty Card",
      key: false,
      type: "text",
      width: 180,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "expirymonth",
      display: "expirymonth",
      title: "Card Expiry Month",
      key: false,
      type: "text",
      width: 160,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "expiryyear",
      display: "expiryyear",
      title: "Card Expiry Year",
      key: false,
      type: "text",
      width: 140,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "created_at",
      display: "created_at",
      title: "Card Created On",
      key: false,
      type: "date",
      width: 220,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    }
  ];

  
export const card_transactions = []
export const transaction_header = [
    {
      name: "card_id",
      display: "card_id",
      title: "card_id",
      key: true,
      type: "text",
      width: 120,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "refnumber",
      display: "refnumber",
      title: "Ref Number",
      key: false,
      type: "text",
      width: 220,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "merchantname",
      display: "merchantname",
      title: "Merchant Name",
      key: false,
      type: "text",
      width: 180,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "vehicledescription",
      display: "vehicledescription",
      title: "Vehicle",
      key: false,
      type: "text",
      width: 180,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "totalamount",
      display: "totalamount",
      title: "Amount",
      key: false,
      type: "text",
      width: 300,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "calculatedquantity",
      display: "calculatedquantity",
      title: "Quantity",
      key: false,
      type: "text",
      width: 300,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    },
    {
      name: "transactiondate",
      display: "transactiondate",
      title: "Transaction Date",
      key: false,
      type: "text",
      width: 220,
      edit: false,
      bold: true,
      right: false,
      toggle: "",
    }
  ];


export const cardnotifications = [
    {
        title: 'Card Expections',
        count: 2,
        items: [
            {
                title: 'Card Limit Exceeded',
                date: '2020/12/05',
                count: 0,
                description: 'Cards that have exceeded the monthly limit'
            },
            {
                title: 'Overfill',
                date: '2020/12/05',
                count: 0,
                description: 'Cards exceeded fill up limit'
            }
        ]
    }
]  




export const sections = [
    {
        title: 'Account Details',
        description: 'Account details',
        fields: [
            {
                name: 'BusinessAccountName',
                title: 'Business Account Name',
                date: '2020/12/05',
                description: 'Business Account'
            },
            {
                name: 'BusinessAccountNumber',
                title: 'Business Account Number',
                date: '2020/12/05',
                description: 'Business Number'
            },
            {
                name: 'CostCenter',
                title: 'Cost Center Name',
                date: '2020/12/05',
                description: 'Cost Center Name'
            },
            {
                name: 'CostCenterCode',
                title: 'Cost Center Code',
                date: '2020/12/05',
                description: 'Cost Center Code'
            }
        ]
    },
    {
        title: 'Card Limit',
        description: 'Card limit',
        fields: [
            {
                name: 'CardLimit',
                title: 'Card Limit',
                date: '2020/12/05',
                description: 'Card Limit'
            },
            {
                name: 'FacilityLimit',
                title: 'Facility Limit',
                date: '2020/12/05',
                description: 'Facility Limit'
            },
            {
                name: 'New Allocated Amount',
                title: 'New Allocated Amount',
                date: '2020/12/05',
                description: 'New Allocated Amount'
            },
            {
                name: 'ActualAllocatedAmount',
                title: 'Actual Allocated ',
                date: '2020/12/05',
                description: 'Actual Allocated Amount'
            },
            {
                name: 'AvailableAmount',
                title: 'Available Amount',
                date: '2020/12/05',
                description: 'Available Amount'
            },
        ]
    },
    {
        title: 'Card Embossing',
        description: 'Card Embossing',
        fields: [
            {
                name: 'Line1',
                title: 'Line 1',
                date: '2020/12/05',
                description: 'Line 1'
            },
            {
                name: 'Line2',
                title: 'Line 2',
                date: '2020/12/05',
                description: 'Line 2'
            },
        ]
    }
]  




export const cardParameters = [
    {
        title: 'Restrictions',
        description: 'Restrictions',
        fields: [
            {
                name: 'MinFillLimit',
                title: 'Min Fill Limit',
                date: '2020/12/05',
                description: 'Min Fill Limit'
            },
            {
                name: 'MaxFillLimit',
                title: 'Max Fill Limit',
                date: '2020/12/05',
                description: 'Max Fill Limit'
            },
            {
                name: 'FacilityFillLimit',
                title: 'Min Facility Limit',
                date: '2020/12/05',
                description: 'Min Facility Limit'
            },
            {
                name: 'MaxFacilityFillLimit',
                title: 'Max Facility Limit',
                date: '2020/12/05',
                description: 'Max Facility Limit'
            },
        ]
    },
    {
        title: 'Expection',
        description: 'Expection',
        fields: [
            {
                name: 'allow',
                title: 'Allow Over Limit By',
                date: '2020/12/05',
                description: 'Allow Over Limit By'
            }
        ]
    }
]  