import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import { Container, Row, Col } from 'react-grid-system'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import MultiSelect from '../../controls/multiselect'
import AutoComplete from '../../controls/autocomplete'
import DateControl from '../../controls/dateControl'
import Card from '../../controls/card'
import Fields from '../../controls/cardfields'

import Grid from './grid'
import Config from './form'
import * as actions from './actions'
import * as styles from './styles'

class CUSTOMERDERIVATIVECATEGORYCONFIG extends Component {

    componentDidMount() {
        this.props.actions.setInputValue({prop: 'customerderivativecategorymake_data', value: []})
        this.props.actions.setInputValue({prop: 'customerderivativecategorymmcode_data', value: []})
        this.props.actions.getLookupValueData('customerderivativecategorymake', 'vehiclemake', '&customerderivativecategory_id=' + this.props.id)
        this.props.actions.getLookupValueData('customerderivativecategorymmcode', 'vehiclemmcode', '&customerderivativecategory_id=' + this.props.id)
    }
    
    getPricing() {
        return  <div className="text-center">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Minimum Vehicle Pricing"}:</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth={true}
                                        id={'txt_min_vehicle_price'}
                                        hintText={'Enter Minimum Vehicle Price'}
                                        value={this.props.state.min_vehicle_price}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({prop: 'min_vehicle_price', value: event.target.value})
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Maximun Vehicle Price"}:</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth={true}
                                        id={'txt_max_vehicle_price'}
                                        hintText={'Enter Maximun Vehicle Price'}
                                        value={this.props.state.max_vehicle_price}
                                        onChange={(event, value) => {
                                            this.props.actions.setInputValue({prop: 'max_vehicle_price', value: event.target.value})
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    getMakes() {
        return  <div className="text-center">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select Make"}:</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        name={'event_action'}
                                        title={'Select Vehicle Make Model'}
                                        data={this.props.state.vehiclemake_data}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        //discdate={'discdate'}
                                        //fieldvalue={'mmcode_id'}
                                        //displayfields={['make', 'regyear']}
                                        //displaywidth={['20', '60', '20']}
                                        onSelect={(args) => {
                                            //console.log('args vehiclemake', args)
                                            this.props.actions.setDataList({prop: 'customerderivativecategorymake_data', value: {vehiclemake: args.text}})
                                            this.props.actions.saveDataList({model: 'customerderivativecategorymake', vehiclemake: args.text, customerderivativecategory_id: this.props.id})

                                            //this.props.actions.setInputValue({prop: 'action_id', value: args.value})
                                    
                                        }}
                                        onClick={() => this.props.actions.getLookupValueData('vehiclemake', 'vehiclemake', '')}
                                        onFilter={(args) => this.props.actions.getLookupValueData('vehiclemake', 'vehiclemake', args)} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            {
                                this.props.state.customerderivativecategorymake_data.length 
                                ? <Grid data={this.props.state.customerderivativecategorymake_data} field="vehiclemake" title="Makes" prop="customerderivativecategorymake_data"/>
                                : ''
                            }
                        
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    getMMCode() {
        return  <div className="text-center">
            <div>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{"Select MMCode"}:</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        name={'event_action'}
                                        title={'Select Vehicle MMCode'}
                                        data={this.props.state.mmcode_data}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        discdate={'discdate'}
                                        fieldvalue={'mmcode_id'}
                                        displayfields={['mmcode', 'makemodelyear', 'regyear']}
                                        displaywidth={['20', '60', '20']}
                                        onSelect={(args) => {
                                            //console.log('args', args)
                                            this.props.actions.setDataList({prop: 'customerderivativecategorymmcode_data', value: {vehiclemmcode: args.text}, id: this.props.id})
                                            this.props.actions.saveDataList({model: 'customerderivativecategorymmcode', vehiclemmcode: args.text, customerderivativecategory_id: this.props.id})

                                            //this.props.actions.setInputValue({prop: 'action_id', value: args.value})
                                    
                                        }}
                                        onClick={() => this.props.actions.getMMCodeLookupValueData('mmcode', 'mmcode', '')}
                                        onFilter={(args) => this.props.actions.getMMCodeLookupValueData('mmcode', 'mmcode', args)} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <Container style={{ margin: 0 }} className="custom-container">
                                {
                                    this.props.state.customerderivativecategorymmcode_data.length 
                                    ? <Grid data={this.props.state.customerderivativecategorymmcode_data}  field="vehiclemmcode" title="MMCode" prop="customerderivativecategorymmcode_data" />
                                    : ''
                                }
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    getLayout() {
        return <div>
            <Card title={window.t.en('Contract Price Range')} 
            subtitle={<div>{'Set max and min price range for this contract'}</div>}
            content={this.getPricing()} />
            <Card title={window.t.en('Contract Makes')} 
            subtitle={<div>{'Set allowed makes for this contract'}</div>}
            content={this.getMakes()} />
            <Card title={window.t.en('Contract MMCode')} 
            subtitle={<div>{'Set allowed mmcode for this contract'}</div>}
            content={this.getMMCode()} />
            </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.customerDerivativeCategory
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERDERIVATIVECATEGORYCONFIG)