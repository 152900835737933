export const vinnumber = ''
export const poddate = ''

export const validation = {
  fields: [],
  component: 'workflowUploadPOD',
  list: [],
  valid: false
}

export const fields = {
  documents: {
    errorText: 'Please add a document and select document type',
    data: false,
    valid: false,
    empty: true,
    popup: true,
    type: 'documents',
  },

  podDate: {
    id: 'txt_poddate',
    name: 'txt_poddate',
    errorText: 'Please enter Proof of Delivery Date',
    state: 'getState().workflowUploadPOD.poddate',
    data: false,
    valid: false,
    empty: true,
    section: 'Proof of Delivery Document'
  },
};