import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Header from '../WorkflowTaskInput/header';
import * as actions from './actions'
import * as styles from './styles'

class FAQ extends Component {
    componentDidMount() {
        this.props.actions.loadFAQ()
    }

    getLayout() {
        return <div>
          {
            this.props.state.faq.map(x => {
              return  <Accordion expanded={this.props.state.expanded === x.faq_id} onChange={() => this.props.actions.tagglePanel('expanded', x.faq_id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 'bold' }}>
                  {x.title}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ul>
                    <li>{x.description}</li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
            })
          }
           
          </div>
    }

    render() {
        return (
            <div>
                <br />
                <Header title={'FAQ'} description={'Frequently Asked Questions'} />
                <br />
                {this.getLayout()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.faq }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ)