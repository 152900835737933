import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import TextField from './textField'
import CardControl from './card'
import ReactQuill from 'react-quill'
import * as actions from './actions'
import * as config from '../config'
import * as styles from './styles'

class CustomerAddress extends Component {
    componentDidMount() {
        console.log('getCustomerAddressx id', this.props.id)
        this.props.actions.getComponentCustomerAddress(this.props.id)
    }
    
    getCustomerAddress() {
        return <div>
        <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'streetaddress'}
                            value={this.props.state.line1}
                            style={styles.textBox}
                            label = {'Street Address:'}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'line1', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'city'}
                            value={this.props.state.town}
                            style={styles.textBox}
                            label = {'City:'}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'town', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'state'}
                            value={this.props.state.province}
                            style={styles.textBox}
                            label = {'State:'}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'province', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'postalcode'}
                            value={this.props.state.country}
                            style={styles.textBox}
                            label = {'Country:'}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'country', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'country'}
                            value={this.props.state.postalcode}
                            style={styles.textBox}
                            label = {'Postal Code:'}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'postalcode', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
            </Row>
        </Container>
         </div> 
    }
    
    
    getCustomerAddressDelivery() {
        return <div>
        <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'streetaddress'}
                            value={this.props.state.del_line}
                            style={styles.textBox}
                            label = {'Street Address:'}
                            fullWidth={true}
                            error={this.props.state.del_line ? '' : 'Field is required'}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'del_line', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'streetaddress'}
                            value={this.props.state.del_line2}
                            style={styles.textBox}
                            label = {'Street Address:'}
                            fullWidth={true}
                            error={this.props.state.del_line2 ? '' : 'Field is required'}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'del_line2', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'city'}
                            value={this.props.state.del_town}
                            style={styles.textBox}
                            label = {'City:'}
                            fullWidth={true}
                            error={this.props.state.del_town ? '' : 'Field is required'}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'del_town', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'state'}
                            value={this.props.state.del_province}
                            style={styles.textBox}
                            label = {'State:'}
                            fullWidth={true}
                            error={this.props.state.del_province ? '' : 'Field is required'}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'del_province', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
                {/* <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'postalcode'}
                            value={this.props.state.del_country}
                            style={styles.textBox}
                            label = {'Country:'}
                            fullWidth={true}
                            error={this.props.state.del_country ? '' : 'Field is required'}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'del_country', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col> */}
                <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'country'}
                            value={this.props.state.del_postalcode}
                            style={styles.textBox}
                            label = {'Postal Code:'}
                            fullWidth={true}
                            error={this.props.state.del_postalcode ? '' : 'Field is required'}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setAddressInput({prop: 'del_postalcode', value: event.target.value })
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />
                </Col>
                <Col xl={4} style={{paddingTop: '15px'}}>
                    <Button
                        onClick={(event) => {
                            var data = {
                                type: 'update_customer',
                                address_id: this.props.state.del_address_id,
                                customer_id: this.props.id,
                                line: this.props.state.del_line,
                                line2: this.props.state.del_line2,
                                town: this.props.state.del_town,
                                province: this.props.state.del_province,
                                country: this.props.state.del_country,
                                postalcode: this.props.state.del_postalcode

                            }
                            this.props.actions.updateCustomerAddress(data)
                        }}
                        className="btn-pill-off">
                        <span className="btn-wrapper--label">Update Address</span>
                    </Button>
                </Col>
            </Row>
        </Container>
         </div> 
    }
    getLayout() {
        return <div>
            {/* <CardControl 
                    title={'Customer Address' + this.props.customername}
                    subtitle={'Street Address '}
                    content={this.getCustomerAddress()}
                    callapse={true}
                /> */}
            <CardControl 
                    title={' '}
                    subtitle={'Delivery Address '}
                    content={this.getCustomerAddressDelivery()}
                    callapse={true}
                />
         </div> 
    }

    render() {
        return this.props.id ? this.getLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddress)