import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Table } from '@mui/material'
import { Card, CardHeader, CardContent, Typography, FormControlLabel } from '@mui/material'
import { Container, Row, Col } from 'react-grid-system'
import ReactQuill from 'react-quill'
  
class _cardfields extends Component {
    
    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : ''
                    
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }
    
    getLabel(label, value, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                this.props.nobold ? <Typography variant="body1" component="div"
                    style={{  fontWeight: 'normal',fontSize: '14px', width: '100%' }}>
                    {this.getField(value, type)}
                </Typography>
                : <Typography variant="body1" component="div"
                style={{  fontWeight: 'bold',fontSize: '14px', width: '100%' }}>
                {this.getField(value, type)}
            </Typography>
            }
            label={
                label ? this.props.leftalign ? <label style={{ width: '200px', textAlign: 'left', paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                    {label.includes(":") ? label : label.concat(':')}
                </label>
                : <label style={{ width: '200px', textAlign: 'right',paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                    {label.includes(":") ? label : label.concat(':')}
                </label> : ''
            }
            labelPlacement="start"
        />
    }

    // getLabel(label, value, type = '') {
    //     return <FormControlLabel
    //         fullWidth={true}
    //         control={
    //             <Typography variant="body1" component="div"
    //             style={{fontWeight: 'bold'}}>
    //                 {this.getField(value, type)}
    //             </Typography>
    //         }
    //         label={
    //             <label style={{width: '200px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
    //                 {label}
    //             </label>  
    //         }
    //         labelPlacement="start"
    //     />
    // }

    getFields() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row className={"custom_view_text"}>
                {
                    this.props.fields.map(x => {
                        return x.header 
                        ? <Col style={{borderBotton: '1px solid gray',textAlign: 'left'}} xl={x.size ? x.size : 6}>
                            <strong>{x.header}</strong><br/>
                        </Col> 
                        : <Col xl={x.size ? x.size : 4} style={{textAlign: 'left'}}>
                            {this.getLabel(x.label, x.value, x.type)}
                        </Col>
                    })
                }
            </Row> 
        </Container>
    }

    viewTable() {
        return <div>
             <Table className="table table-hover text-nowrap mb-0">
                <thead>
                    <tr>
                        {
                            this.props.columns.map(col => { return <th>{col}</th>})
                        }
                    </tr>
                </thead>
                <tbody style={{fontWeight: 'normal',fontSize: '10px'}}>
                    {
                        this.props.props
                        ? this.props.data.map(x => {
                            return <tr>
                                {
                                    Object.values(this.props.props).map(prop => {
                                        let _prop = prop.split(',')
                                        return _prop.length === 1
                                            ? <td>{prop.includes('#') ? moment(x[prop.replace('#')]).format('YYYY-MM-DD') : x[prop]}</td>
                                            : <td>{x[_prop[0]] ? _prop[1] : _prop[2]}</td>
                                    })
                                }
                            </tr>
                        })
                        : this.props.data.map(x => {
                            return <tr>
                                {
                                    Object.values(x).map((key,inx) => {return this.props.shift ? this.props.shift.includes(inx) ? '' : <td>{key}</td> : <td>{key}</td>})
                                }
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
    }

    viewFields() {
        return this.props.nocard === true ? <>{this.getFields()}</> : <Card style={{padding: '0px !important',boxShadow:'none'}}>
            <CardContent>
                {this.getFields()}
            </CardContent>
        </Card>
    }

    render() {
        return this.props.data
        ? this.viewTable()
        : this.viewFields()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.empty }
    }
}

export default connect(mapStateToProps)(_cardfields)