import * as cognitoTypes from '../Cognito/types'
import * as messengerTypes from '../Messenger/types'
import * as requestTypes from '../Request/types'
import * as appTypes from '../App/types'
import * as gridActions from '../Grid/actions'
import * as cognitoActions from '../Cognito/actions'
import * as types from './types'

export const logErrorToRaygun = (error) => {
    // return (dispatch, getState) => {
    //     dispatch({
    //         type: 'LOG_ERROR_TO_RAYGUN'
    //     })

    //     try {
    //         throw error
    //     } catch (e) {
    //         if (process.env.NODE_ENV === 'production' && window.Raygun) {  //
    //             window.Raygun.init('oHyuAzztxKulwy40QIagOFF')
    //             //window.Raygun.send(e)
    //             window.Raygun.send({
    //                 error: e,
    //                 customData: {
    //                     email: getState().user.email,
    //                     firstName: getState().user.firstname,
    //                     fullName: getState().user.lastname
    //                 }
    //               })
    //         }
    //     }
    // }
}

export const setAppAction = (action) => {
    return {
        type: types.SET_APP_ACTION,
        payload: action
    }
}

export const initMsal = (status) => {
    return (dispatch, getState) => {
        console.error('accountsx status', status);
        dispatch({
            type: types.SET_INIT_USE_MSAL,
            payload: status
        })
    }
}


export const disconectMessenger = () => {
    return (dispatch, getState) => {
        dispatch({
            type: messengerTypes.CLIENT_DISCONNECT_FROM_MESSENGER
        })
        dispatch({
            type: messengerTypes.CONNECT_USER_TO_MESSENGER
        })
    }
}

export const configureApp = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CONFIGURE_APP
        })
    }
}

export const changeLocale = (lang) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_APPLICATION_USER_LOCALE,
            payload: lang
        })
    }
}

export const sessionCountDown = () => {
    return (dispatch, getState) => {
        let counter = getState().app.countdown - 1
        dispatch({
            type: types.SESSION_EXPIRY_TIMER_COUNT_DOWN,
            payload: getState().app.countdown - 1
        })
    }
}

export const closeExpiryDialog = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.TOGGLE_EXPIRY_DIALOG,
            payload: status
        })
    }
}

export const closePopupDialog = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_CONFIRM_DIALOG,
            payload: {
                status: status
            }
        })
        dispatch(gridActions.okDialog())
    }
}
export const showDialog = () => {
    return {
            type: types.SET_SHOW_DIALOG_NOTIFICATIOB,
            payload: {
                status: true
            }
    }
}

export const showLoggedDialog = (loggedTwice) => {
    return {
            type: types.SET_SHOW_DIALOG_NOTIFICATION_LOGGED_TWICE,
            payload: {
                status: loggedTwice
            }
    }
}
export const getUserAgent = () => {
    return {
        type: types.GET_USER_AGENT,
        payload: {
            userAgent: navigator.userAgent,
            mobile: navigator.userAgent.toLowerCase().indexOf('mobi') > -1 ? true : false,
            valid: true
        }
    }
}

export const getUserLocale = () => {
    return {
        type: types.GET_USER_LOCALE,
        payload: navigator.userLanguage
    }
}

export const userSignout = () => {
    return {
        type: types.USER_SIGN_OUT_COGNITO_USER,
    }
}

export const setLoadingStatus = (payload) => {
    return {
        type: types.SET_LOADING_STATUS,
        payload: payload
    }
}

export const renewApplicationCache = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RENEW_APPLICATION_CACHE,
        })
    }
}

export const clearApplicationCache = () => {
    return (dispatch, getState) => {
        dispatch(cognitoActions.signoutCognitoUser())
    }
}