import * as actions from './actions'
import * as teamActions from '../Teams/actions'
import * as clientActions from '../Clients/actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import { cognitoUser } from '../../cognito'
import * as config from '../../config'


export const getLicenseeAccount = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_LICENSEE_ACCOUNT_DATA),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/licensee/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                }
            })
            .pipe(
                mergeMap(payload => [
                    actions.setLicenseeAccount(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/licensee/query')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/licensee/query', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
        
export const saveLicenseeData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.SAVE_LICENSEE_COMPONENT_CUSTOMER_DATA),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/licensee/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(payload => [
                    actions.saveLicenseeDataDone(payload.response),
                    requestActions.requestSucceeded(config.system.api + '/components/licensee/query')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/licensee/query', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
        