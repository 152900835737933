import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent, CardActions, CardMedia, CardTitle, CardExpandable } from '@mui/material'

import Button from '@mui/material/Button'


import Tooltip from '@mui/material/Tooltip'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import IconButton from '@mui/material/IconButton';

import Icon from '../Icon/component'
import View from '../View/component'

import * as actions from './actions'
import * as routerActions from '../Redirect/actions'
import * as styles from './styles'
import moment from 'moment'

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete'

import Routing from "./RoutingMachine";

import 'react-leaflet-markercluster/dist/styles.min.css'
import "./styles.css";

class Leaflet extends Component {
    constructor() {
        super();
        this.state = {
          lat: 17.4,
          lng: 78.4,
          zoom: 7,
          isMapInit: false
        };
      }
    
      saveMap = map => {
        this.map = map;
        this.setState({
          isMapInit: false
        });
      };

    componentDidMount() {
        this.props.actions.getTrackingData(this.props.state.source)
    }

    getMarkers(position) {
        return this.props.state.data.length
            ? this.props.state.data
                .map(x => {
                    return <Marker position={
                        [x[this.props.state.source.latitude], x[this.props.state.source.longitude]]
                    }>
                        <Popup>
                            <span onClick={(event) => {
                                this.props.actions.route('/view/' + this.props.state.source.source + '/' + x[this.props.state.source.source + '_id'])
                            }}>
                                <div>
                                    <strong>
                                        <Icon iname={x.icon} iclass='custom-icons' /> {x.display}
                                    </strong>
                                </div>
                            </span>
                        </Popup>
                    </Marker>
                })
            : <Marker position={position}>
                <Popup>
                    <span>Loading tracking data...</span>
                </Popup>
            </Marker>
    }
    
  getMarkers() {
    let points = [{longitude:'28.064790000',latitude:'-25.994702000'},
    {longitude:'29.785238000',latitude:'-28.558762000'},
    {longitude:'18.465000000',latitude:'-33.953650000'},
    {longitude:'30.103143000',latitude:'-29.439667000'},
    {longitude:'29.983905000',latitude:'-28.410571000'},
    {longitude:'32.002355000',latitude:'-28.779215000'},
    {longitude:'18.591647000',latitude:'-33.961165000'},
    {longitude:'30.005747000',latitude:'-29.194800000'},
    {longitude:'29.349473000',latitude:'-23.469473000'},
    {longitude:'29.782810000',latitude:'-28.556952000'},
    {longitude:'29.994286000',latitude:'-29.357381000'},
    {longitude:'29.789286000',latitude:'-28.542762000'},
    {longitude:'31.100847000',latitude:'-29.661742000'},
    {longitude:'27.367365000',latitude:'-29.472833000'},
    {longitude:'32.027429000',latitude:'-28.752286000'},
    {longitude:'30.350333000',latitude:'-29.609476000'},
    {longitude:'30.796952000',latitude:'-27.767524000'},
    {longitude:'25.606952000',latitude:'-33.914048000'},
    {longitude:'30.997810360',latitude:'-29.879869460'},
    {longitude:'28.497429000',latitude:'-26.210905000'},
    {longitude:'28.497381000',latitude:'-26.211143000'},
    {longitude:'28.064559940',latitude:'-25.994712830'},
    {longitude:'30.983596800',latitude:'-29.897733690'},
    {longitude:'28.064655300',latitude:'-25.994644170'},
    {longitude:'29.561714000',latitude:'-28.462048000'},
    {longitude:'32.048714000',latitude:'-28.784762000'},
    {longitude:'31.029524000',latitude:'-29.753286000'},
    {longitude:'31.888912200',latitude:'-28.776285170'},
    {longitude:'31.009695050',latitude:'-29.902771000'},
    {longitude:'25.627143000',latitude:'-33.961143000'},
    {longitude:'31.021190000',latitude:'-29.845667000'},
    {longitude:'28.064571000',latitude:'-25.994905000'},
    {longitude:'28.198714000',latitude:'-26.152286000'},
    {longitude:'32.037476000',latitude:'-28.828810000'},
    {longitude:'30.992095000',latitude:'-29.896286000'},
    {longitude:'25.538619000',latitude:'-32.093238000'},
    {longitude:'25.533286000',latitude:'-32.085905000'},
    {longitude:'30.963197710',latitude:'-29.937644960'},
    {longitude:'31.907000000',latitude:'-28.766095000'},
    {longitude:'31.905857000',latitude:'-28.760762000'},
    {longitude:'27.889571000',latitude:'-32.972238000'},
    {longitude:'32.059000000',latitude:'-28.830857000'},
    {longitude:'25.118762000',latitude:'-31.489429000'},
    {longitude:'31.003048000',latitude:'-29.856429000'},
    {longitude:'28.382048000',latitude:'-28.018333000'},
    {longitude:'32.077524000',latitude:'-28.792571000'},
    {longitude:'28.283333000',latitude:'-25.712952000'},
    {longitude:'32.079190000',latitude:'-28.792905000'},
    {longitude:'32.028857000',latitude:'-28.785905000'},
    {longitude:'32.047143000',latitude:'-28.751714000'},
    {longitude:'30.376966480',latitude:'-29.592866900'},
    {longitude:'31.029476000',latitude:'-29.753095000'},
    {longitude:'30.991143000',latitude:'-29.890381000'},
    {longitude:'30.373619000',latitude:'-29.595095000'},
    {longitude:'29.768000000',latitude:'-28.555286000'},
    {longitude:'30.990667000',latitude:'-29.890333000'},
    {longitude:'30.811000000',latitude:'-27.762000000'},
    {longitude:'32.037635800',latitude:'-28.827554700'},
    {longitude:'28.048286000',latitude:'-26.209381000'},
    {longitude:'30.991128920',latitude:'-29.890504840'},
    {longitude:'32.044235230',latitude:'-28.753351210'},
    {longitude:'31.895420070',latitude:'-28.776748660'},
    {longitude:'18.434714000',latitude:'-34.081048000'},
    {longitude:'30.990524000',latitude:'-29.890333000'},
    {longitude:'30.990619000',latitude:'-29.890333000'},
    {longitude:'30.990940090',latitude:'-29.890502930'},
    {longitude:'31.888937000',latitude:'-28.776550290'},
    {longitude:'28.064333000',latitude:'-25.994524000'},
    {longitude:'30.990810000',latitude:'-29.890429000'},
    {longitude:'31.896857000',latitude:'-28.753619000'},
    {longitude:'31.912275310',latitude:'-28.768314360'},
    {longitude:'30.991000000',latitude:'-29.890524000'},
    {longitude:'30.991143000',latitude:'-29.890571000'},
    {longitude:'20.330095000',latitude:'-33.254905000'},
    {longitude:'30.991146090',latitude:'-29.890563970'},
    {longitude:'31.889072420',latitude:'-28.776565550'},
    {longitude:'30.991095000',latitude:'-29.890619000'},
    {longitude:'32.028333000',latitude:'-28.786476000'},
    {longitude:'32.052810000',latitude:'-28.788667000'},
    {longitude:'30.990857000',latitude:'-29.890429000'},
    {longitude:'30.991000000',latitude:'-29.890667000'},
    {longitude:'28.064095000',latitude:'-25.994667000'},
    {longitude:'28.064143000',latitude:'-25.994524000'},
    {longitude:'28.064238000',latitude:'-25.994476000'},
    {longitude:'28.064143000',latitude:'-25.994476000'},
    {longitude:'30.991000000',latitude:'-29.890429000'},
    {longitude:'28.064000000',latitude:'-25.994619000'},
    {longitude:'30.990905000',latitude:'-29.890429000'},
    {longitude:'28.064333000',latitude:'-25.994476000'},
    {longitude:'30.980000000',latitude:'-29.907381000'},
    {longitude:'30.990905000',latitude:'-29.890476000'},
    {longitude:'29.800013000',latitude:'-28.535511000'},
    {longitude:'30.991238000',latitude:'-29.890619000'},
    {longitude:'28.064048000',latitude:'-25.994714000'},
    {longitude:'28.064000000',latitude:'-25.994524000'},
    {longitude:'29.792930600',latitude:'-28.543039320'},
    {longitude:'29.980831150',latitude:'-27.761327740'},
    {longitude:'31.034685000',latitude:'-29.868185000'},
    {longitude:'28.202995300',latitude:'-26.171831130'},
    {longitude:'31.028095000',latitude:'-29.847810000'}]
    return points.map((x,i) => {
        return <Marker position={[x.latitude, x.longitude]}>
          
          <Popup>
                    <div></div>
                </Popup>
        </Marker>
    })
}


    render() {
        const position = [this.props.state.latitude, this.props.state.longitude]

        return (
            <div>
                <div style={styles.sources}>
                    <div>
                        <Tooltip arrow title={'Location'} placement="left">
                            <IconButton
                                tooltip={'Location'}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.setInputValue({prop: 'open_search', value: !this.props.state.open_search})
                                }}
                            >
                                <Icon istyle={{ fontSize: '22px', color: '#253053' }} color="primary" iclass={'material-icons'} iname={'location_searching'} />
                            </IconButton>
                        </Tooltip>
                        <br />
                        <Tooltip arrow title={'Location'} placement="left">
                            <IconButton
                                tooltip={'Location'}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.setInputValue({prop: 'open_directions', value: !this.props.state.open_directions})
                                }}
                            >
                                <Icon istyle={{ fontSize: '22px', color: '#253053' }} color="primary" iclass={'material-icons'} iname={'directions'} />
                            </IconButton>
                        </Tooltip>
                        <br />
                        {
                    this.props.state.open_search
                    ? <div style={{padding: 10, backgroundColor: 'white !important'}}>
                            <Paper elevation={3} 
                                children={<div style={{padding: 10, backgroundColor: 'white !important'}}><AutoComplete
                                id="combo-box-demo"
                                listStyle={{ maxHeight: 400 }}
                                style={{ position: 'fixed', width: 400, right: 80, top: 100, backgroundColor: 'white' }}
                                getOptionLabel={(option) => option.text}
                                options={this.props.state.address_data ? this.props.state.address_data : []}
                                renderInput={(params) => <TextField {...params} label="Search location" variant="outlined" />}
                                
                                onOpen={
                                    (chosenRequest, index) => {
                                        //this.props.onClick()
                                        //console.log("searching event",chosenRequest)
                                    }
                                }
                                onInputChange={
                                    (event, search) => {
                                        //console.log("searching event",search)
                                        this.props.actions.locationSearch(search)
                                        //this.props.onFilter ? this.props.onFilter(search) : this.props.onUpdateInput(search)
                                    }
                                }
                                onChange={
                                    (event, request) => {
                                        //console.log("searching request", request)
                                        if(request){
                                        this.props.actions.setLocation({latitude: request.lat, longitude: request.lon, zoom: 15})
                                        this.props.actions.setMarker([{currentlatitude: request.lat, currentlongitude: request.lon, display: request.text, icon: 'my_location'}])

                                        }
                                        // if(search && search.length > 2)
                                        // this.props.actions.addressSearch('delivery_data',search)
                                    }
                                }
                            /> </div>}
                            /> 
                        </div>
                        : <div></div>
                    }{
                    this.props.state.open_directions
                    ? <div style={{padding: 10, backgroundColor: 'white !important'}}>
                            <Paper elevation={3} 
                                children={<div style={{padding: 10, backgroundColor: 'white !important'}}>
                                    <AutoComplete
                                id="combo-box-demo"
                                listStyle={{ maxHeight: 400 }}
                                style={{ position: 'fixed', width: 400, right: 80, top: 100, backgroundColor: 'white' }}
                                getOptionLabel={(option) => option.text}
                                options={this.props.state.address_data ? this.props.state.address_data : []}
                                renderInput={(params) => <TextField {...params} label="Search start location" variant="outlined" />}
                                
                                onOpen={
                                    (chosenRequest, index) => {
                                        //this.props.onClick()
                                        //console.log("searching event",chosenRequest)
                                    }
                                }
                                onInputChange={
                                    (event, search) => {
                                        //console.log("searching event",search)
                                        this.props.actions.locationSearch(search)
                                        //this.props.onFilter ? this.props.onFilter(search) : this.props.onUpdateInput(search)
                                    }
                                }
                                onChange={
                                    (event, request) => {
                                        //console.log("searching request", request)

                                        this.props.actions.setLocation({latitude: request.lat, longitude: request.lon, zoom: 15})
                                        this.props.actions.setMarker([{currentlatitude: request.lat, currentlongitude: request.lon, display: 'WTF', icon: 'my_location'}])
                                        // if(search && search.length > 2)
                                        // this.props.actions.addressSearch('delivery_data',search)
                                    }
                                }
                            /> 
                            <br/>
                            <AutoComplete
                                id="combo-box-demo"
                                listStyle={{ maxHeight: 400 }}
                                style={{ position: 'fixed', width: 400, right: 80, top: 160, backgroundColor: 'white' }}
                                getOptionLabel={(option) => option.text}
                                options={this.props.state.address_data ? this.props.state.address_data : []}
                                renderInput={(params) => <TextField {...params} label="Search destination" variant="outlined" />}
                                
                                onOpen={
                                    (chosenRequest, index) => {
                                        //this.props.onClick()
                                        //console.log("searching event",chosenRequest)
                                    }
                                }
                                onInputChange={
                                    (event, search) => {
                                        //console.log("searching event",search)
                                        this.props.actions.locationSearch(search)
                                        //this.props.onFilter ? this.props.onFilter(search) : this.props.onUpdateInput(search)
                                    }
                                }
                                onChange={
                                    (event, request) => {
                                        //console.log("searching request", request)

                                        this.props.actions.setLocation({latitude: request.lat, longitude: request.lon, zoom: 15})
                                        this.props.actions.setMarker([{currentlatitude: request.lat, currentlongitude: request.lon, display: 'WTF', icon: 'my_location'}])
                                        // if(search && search.length > 2)
                                        // this.props.actions.addressSearch('delivery_data',search)
                                    }
                                }
                            /> </div>}
                            /> 
                        </div>
                        : <div></div>
                    }
                    </div>

                    {
                        this.props.state.sources.map(x => {
                            return <div>
                                <Tooltip arrow title={x.title} placement="left">
                                    <IconButton
                                        tooltip={x.title}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.props.actions.setTrackingSource(x)
                                            this.props.actions.getTrackingData(x)
                                        }}
                                    >
                                        <Icon istyle={{ fontSize: '22px', color: '#253053' }} color="primary" iclass={'material-icons'} iname={x.icon} />
                                    </IconButton>
                                </Tooltip>
                                <br />
                            </div>
                        })
                    }

                    {/*                     <IconButton tooltip={'Road Side Assistance'}>
                        <Icon istyle={{fontSize: '32px'}} iname={'communication'} iclass='custom-icons' />
                    </IconButton>

                    <br />

                    <IconButton tooltip={'Infleet Branches'}>
                        <Icon
                            istyle={{
                                fontSize: '32px'
                            }}
                            iname={'organization'}
                            iclass='custom-icons'
                        />
                    </IconButton> */}
                </div>
                <Map center={position} zoom={this.props.state.zoom} style={styles.tracker}  ref={this.saveMap}> 
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    <MarkerClusterGroup wrapperOptions={{ enableDefaultStyle: true }}>
                        {this.getMarkers()}
                    </MarkerClusterGroup>
                    {this.state.isMapInit && <Routing map={this.map} />} 
                </Map>
            </div>
        )
    }


}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.tracker,
            component: {
                ...state.components['vehicle']
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...routerActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaflet)