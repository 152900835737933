import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        // case types.RESET_COMPONENT:
        //     return {
        //         ...state,
        //         users: [],
        //         vehicles: [],
        //         supplierage: [],
        //         bookinginfo: [],
        //         selectedVehicle: [],
        //         selectedSupplier: null,
        //         vehicleserviceinfo: [],
        //         maintpdf_preview: '',
        //     }
        case types.SET_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.val
            }
        case types.SET_PRE_AUTHORISATION_NUMBER:
            return {
                ...state,
                preauth: action.payload
            }
        case types.SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                suppliers: action.payload
            }
        case types.SET_USER_AUTOCOMPLETE_MENU_ITEMS:
            return {
                ...state,
                users: action.payload
            }
        case types.RESET_COMPOMENT_STATE:
            return {
                ...state,
                invoice_number: '',
                fields: {
                    ...state.fields,
                    supplier_id: {
                        errorText: 'Pontential duplicate detected',
                        value: null,
                        valid: true,
                        empty: true,
                    },
                    suppliername: {
                        errorText: 'Select purchase order type',
                        value: null,
                        valid: false,
                        empty: true,
                    },
                    user_id: {
                        errorText: 'Select purchase order type',
                        value: null,
                        valid: false,
                        empty: true,
                    },
                    firstname: {
                        errorText: 'Select purchase order type',
                        value: null,
                        valid: false,
                        empty: true,
                    },
                    date: {
                        errorText: 'Select purchase order type',
                        value: null,
                        valid: false,
                        empty: true,
                    },
                    month: {
                        errorText: 'Select purchase order type',
                        value: null,
                        valid: false,
                        empty: true,
                    },
                    reference: {
                        errorText: 'Select purchase order type',
                        value: null,
                        valid: false,
                        empty: true,
                    },
                    description: {
                        errorText: 'Select purchase order type',
                        value: null,
                        valid: false,
                        empty: true,
                    },
                    invoice_number :
                    {
                        errorText: 'Select purchase order type',
                        value: null,
                        valid: false,
                        empty: true,
                    }
                }
            }
        default:
            return state
    }
}