export const container = (settings = {}) => {
    return {
        width: settings.width ? settings.width : 500,
        height: 30,
        display: 'table',
        //border: '1px solid black'
    }
}

export const selected = {
    display: 'table-cell',
    height: 30,
    verticalAlign: 'middle',
    textAlign: 'center'
}

export const arrow = {
    display: 'table-cell',
    width: 30,
    borderLeft: '1px solid black',
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: 'red'
}

export const icon = {
    color: 'red'
}

export const tree = (toggled, settings = {}) => {
    return {
        width: settings.width ? settings.width : 500,
        position: 'absolute',
        display: toggled ? 'block' : 'none',
        height: 300,
        backgroundColor: 'white',
        border: '1px solid black',
        marginTop: 5,
        overflowY: 'scroll',
        padding: 2
    }
}

export const child = (index) => {
    return {
        //display: index === 5 ? 'table' : 'none',
        display: 'table',
        marginLeft: index,
        marginBottom: 2
    }
}

export const toggle = {
    display: 'table-cell',
    width: 10,
    //borderTop: '1px solid black',
    //borderLeft: '1px solid black',
    //borderBottom: '1px solid black',
}

export const label = {
    display: 'table-cell'
}

export const input = {
    width: '100%',
    height: 30,
    padding: 2,
    fontFamily: 'roboto'
}

export const root = {
    background: "white",
    display: "inline-block",
    borderRadius: 16,
    
  }
  export const expand = (theme) => {
    return {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short
    })
}
  }
  export const expandOpen = {
    transform: "rotate(180deg)"
  }
  export const avatar = {
    backgroundColor: "#ECECF4"
  }

  export const paper = {
    // position: 'absolute',
    display: "block",
    width: '50%',
    backgroundColor: '#fff',
    // border: '2px solid #000',
    boxShadow: '10px',
    padding: '10px',
    // top: '200px',
    margin: 'auto',
  }



export const image = {
    width: 300
}