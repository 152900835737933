export const title = ''
export const description = ''
export const bubble = {}
export const bottomSheetIsOpen = false
export const dashboard = ''

export const loading = false

export const tab = 0

export const tabs = [
    {
        label: 'Data',
        value: 0
    },
/*     {
        label: 'Visualisations',
        value: 1
    }, */
    {
        label: 'Documents',
        value: 2
    },
/*     {
        label: 'Related',
        value: 3
    } */
]