import moment from 'moment'
import * as _ from 'lodash'
import { ExcelRenderer, OutTable } from 'react-excel-renderer'
import * as types from './types'
import * as appTypes from '../../App/types'

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_BANK_RECON_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const updMatchTransactions = (data) => {
    return (dispatch, getState) => {
        //console.log('updMatchTransactionsx', data)

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Updating bank recon transaction',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.SET_SUPPLIER_BANK_RECON_MATCH_TRANSACTION,
            payload: {
                data: data,
            }
        })
    }
}
export const updMatchTransactionsDone = () => {
    return (dispatch, getState) => {
        dispatch(setInputValue('win_match',false))
        dispatch(setInputValue('win_unmatch',null))
        let rows = getState().supplierbankrecon.rows
        dispatch(getPaymentData(rows))
        
            
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Updating bank recon transaction completed',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const uploadFileData = (data) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> uploadFileDataDone', data)
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA_DONE,
            payload: true
        })
        
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA_ERROR,
            payload: null
        }) 
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA,
            payload: {
                type: data.type,
                rows: data.rows,
                template: data.template,
                document: data.document,
            }
        })
    }
}
export const updateTransactionsRecon = (cash_book_id, statement_amount, transaction_amount, balance) => {
    return (dispatch, getState) => {
        statement_amount = statement_amount.toString().replace('-','')
        //console.log('updateTransactionsReconx ==', parseInt(statement_amount), parseInt(transaction_amount))
        if(parseInt(statement_amount) == parseInt(transaction_amount))
        {
            
            
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Updating bank recon transaction',
                    overflowText: '',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
            dispatch({
                type: types.UPD_SUPPLIER_TRANSACTION_RECON,
                payload: {
                    cash_book_id: cash_book_id,
                    reconciled_at: moment().format('YYYY-MM-DD HH:mm'),
                    reconciled_by: '',
                    balance: balance,
                    reconciled: true
                }
            })
        }
        else{
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    type: 'danger',
                    title: 'System Notification',
                    additionalText: 'Statement amount and transaction amount does not match',
                    overflowText: '',
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}

export const unReconTransactionsRecon = (cash_book_id) => {
    return (dispatch, getState) => {
        
            
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Updating bank recon transaction',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.UPD_SUPPLIER_TRANSACTION_RECON,
            payload: {
                cash_book_id: cash_book_id,
                reconciled_at: null,
                reconciled_by: null,
                reconciled: false,
                balance: 0.00
            }
        })
    }
}
export const unmatchReconTransactionsRecon = (cash_book_id, reference) => {
    return (dispatch, getState) => {
            
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Updating bank recon transaction',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.SET_SUPPLIER_BANK_RECON_MATCH_TRANSACTION,
            payload: {
                data: {
                cash_book_id: cash_book_id,
                reference: reference,
                ref_cb_match_arch: null
            }}
        })
        
        //dispatch(clearItemMatch(reference))
    }
}

export const clearItemMatch = (reference) => {
    return (dispatch, getState) => {
        let rows = getState().supplierbankrecon.rows
        let index = rows.findIndex(x => x['3'] === reference)
            
        if(rows[index]) {
            rows[index]['matcht'] = {}
        }
        
        dispatch({
            type: types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS,
            payload: rows
        })
    }
}

export const updateTransactionsReconDone = (response) => {
    return (dispatch, getState) => {
        let rows = getState().supplierbankrecon.rows
        
        //console.log('unmatchx response',response)
        //console.log('unmatchx rows',rows)
        dispatch(getPaymentData(rows))
        dispatch(setInputValue('win_unmatch',null))
            
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Updating bank recon transaction completed',
                overflowText: '',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const getTransactionWithSameAmount = (amount) => {
    return (dispatch, getState) => {
        //console.log('setTransactionWithSameAmountx amount', amount)
        //console.log('setTransactionWithSameAmountx amount', amount.toString().replace('-',''))
        amount = amount ? amount.toString().replace('-','') : ''
        dispatch({
            type: types.GET_SUPPLIER_TRANSACTION_BY_AMOUNT,
            payload: amount
        })
    }
}

export const setTransactionWithSameAmount = (response, payload) => {
    return (dispatch, getState) => {
        //console.log('setTransactionWithSameAmountx', response.data)
        dispatch(setInputValue('match_transactions',response.data))
    }
}
export const getPaymentData = (bank_data) => {
    return (dispatch, getState) => {
        for(let x of bank_data) {
            //console.log('setPaymentDatax x', x)
            
            let amnt = x['2'] 
            amnt = amnt ? amnt.toString().replace('-','') : '0'
            //console.log('setPaymentDatax amnt', amnt)
            let _amnt = parseFloat(amnt).toFixed('2')
       
            dispatch({
                type: types.GET_SUPPLIER_TRANSACTION_BANK_RECON,
                payload: {
                    reference: x['3'],
                    contract_amount: amnt
                }
            })
        }
    }
}
// export const setPaymentData = (response, payload) => {
//     return (dispatch, getState) => {
//         let rows = getState().supplierbankrecon.rows
//         //console.log('setPaymentDatax rows', rows)
//         //console.log('setPaymentDatax response', response)
//         //console.log('setPaymentDatax payload', payload)
//         //for(let x of bank_data) {
//             let index = rows.findIndex(x => x['3'] === payload)
//             //console.log('setPaymentDatax index', index)
//             //console.log('setPaymentDatax rows[index]', rows[index])
//             if(rows[index])
//             rows[index]['matcht'] = response.data.length ? response.data[0] : {}
            
//         //}

//         dispatch({
//             type: types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS,
//             payload: rows
//         })
//     }
// }

export const setPaymentData = (response, payload) => {
    return (dispatch, getState) => {
        let rows = getState().supplierbankrecon.rows
        //console.log('setPaymentDatax res', response)
        //console.log('setPaymentDatax payload', payload)
        let _reference = payload.reference ? payload.reference : payload
        
        if(response.length) {
            //console.log('setPaymentDatax payload', response[0].reference)
            let index = rows.findIndex(x => x['3'] === _reference)
            
            //console.log('setPaymentDatax index', index)
            if(rows[index]) {
                rows[index]['matcht'] = response.length ? response[0] : {}
                //console.log('setPaymentDatax matcht', rows[index]['matcht'])
            }
        }else {
            let index = rows.findIndex(x => x['3'] === _reference)
            if(rows[index]) {
                rows[index]['matcht'] = {}
            }
        }
        dispatch({
            type: types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS,
            payload: rows
        })
    }
}
export const uploadFileDataDone = (data) => {
    return (dispatch, getState) => {
        //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> uploadFileDataDone', data)
        
        if(data.response.data.status === 'done') 
        {
            dispatch({
                type: types.UPLOAD_EXCEL_FILE_DATA_DONE,
                payload: false
            })    
            dispatch(resetUpload())
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'File import successfully completed...',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }else {
            
            dispatch({
                type: types.UPLOAD_EXCEL_FILE_DATA_ERROR,
                payload: JSON.stringify(data.response.data.status)
            }) 
        }
    }
}

export const resetUpload = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS,
            payload: []
        })
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_NAME,
            payload: null
        })
        dispatch({
            type: types.SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA,
            payload: null
        })
        dispatch({
            type: types.UPLOAD_EXCEL_FILE_DATA_ERROR,
            payload: null
        }) 
    }
}

export const setAcceptedDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_BUSY,
            payload: true
        })

        documents.forEach(file => {
            //console.log('File',file)
            ExcelRenderer(file, (err, resp) => {
                if (err) {
                    //console.log(err);
                }
                else {
                    //console.log(resp.rows.filter(x => x.length).filter(x => x.length > 1))

                    //console.log('setPaymentDatax resp.rows', resp.rows)

                    let bank_data = resp.rows.filter(x => x.length).filter(x => x.length > 1)
                    bank_data.shift()
                    bank_data = bank_data.filter(x => x['2']).filter(x => parseInt(x['2']) < 0)
                    dispatch({
                        type: types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS,
                        payload: bank_data
                    })
                    //console.log('setPaymentDatax bank_data', bank_data)
                    dispatch(getPaymentData(bank_data))
                }
            });
        });
        //console.log('SET_UPLOADED_EXCEL_FILE_NAME')
        //console.log(documents[0])
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_NAME,
            payload: {
                preview: documents[0].preview,
                filename: documents[0].name,
            }
        })
        dispatch({
            type: types.SET_UPLOADED_EXCEL_FILE_BUSY,
            payload: false
        })
    };
}
