import * as types from './types'

export const getConnector = () => {
    return {
        type: types.GET_CONNECTOR
    }
}

export const setConnector = (payload) => {
    return {
        type: types.SET_CONNECTOR,
        payload: payload
    }
}