import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getAllInvoices = (search) => {
    return {
        type: types.GET_INVOICE_LIST,
        payload: {
            search_text: search
        }
    }
}
export const setInvoiceList = (payload) => {
    let data = []
    var items = [{ description: '', unit: '', quantity: 0, unitprice: 0, amount: 0, vat: 0, total: 0, key: 1, account_id: 0 }]
    data = payload.data.map(x => {
        if (x.customertransactionitem) {
            items = x.customertransactionitem.map((i, inx) => {
                return {
                    description: i.description,
                    unit: i.unitmeasure_id,
                    quantity: i.quantity,
                    unitprice: i.unitprice,
                    amount: (Number(i.quantity) * Number(i.unitprice)),
                    vat: i.vat,
                    total: Number(i.totalamount),
                    key: Number(inx) + 1,
                    account_id: i.accountingitem_id,
                    account_text: i.itemname,  
                    unit_text: i.unit_fktext,
                    vattable: i.vattable
                }
            })
        }

        return {
            text: x.customer_fktext + ' | ' + x.reference,
            value: x.customertransaction_id,
             id: x.customertransaction_id,
            customer_id: x.customer_id,
            customer: x.customer_fktext,
            customertransaction_date: x.customertransactiondate,
            customertransaction_month: x.customertransactionmonth,
            customer_reference: x.customerreference,
            invoice_description: x.description,
            invoice_reference: x.reference,
            transactiontype: x.transactiontype,
            customertransactionitem: items 
        }
    })
    return {
        type: types.SET_LIST_DATA,
        payload: {
            data: data,
            key: 'invoice_list'
        }
    }
}
export const getAllTransactionTypes = (search) => {
    return {
        type: types.GET_TRANSACTION_TYPE_LIST,
        payload: {
            search_text: search,
            type: 'customer'
        }
    }
}
export const setTransactionTypeList = (payload) => {
    let data = payload.data.map(x => {
        return {
            text: x.transactiontype,
            value: x.transactiontype
        }
    })

    return {
        type: types.SET_LIST_DATA,
        payload: {
            data: data,
            key: 'transaction_types'
        }
    }
}
export const getLookupValueData = (search) => {
    return {
        type: types.GET_DEBTORS_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getVehicleList = (search,customer_id) => {
    return {
        type: types.GET_VEHICLE_LIST,
        payload: {
            search_text: search,
            customer_id:customer_id
        }
    }
}
export const getUnitMeasureListData = (search) => {
    return {
        type: types.GET_UNIT_MEASURE_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getBusinessListData = (search) => {
    return {
        type: types.GET_BUSINESS_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getContractAccountListData = (search) => {
    return {
        type: types.GET_DEBTORS_ACCOUNT_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getItemAccountListData = (search) => {
    return {
        type: types.GET_ITEM_ACCOUNT_LIST,
        payload: {
            search_text: search,
        }
    }
}
export const getDiscountAccountListData = (search) => {
    return {
        type: types.GET_DISCOUNT_ACCOUNT_LIST,
        payload: {
            search_text: search,
        }
    }
}
export const setCustomerList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.customername + ' | ' + x.accountnumber,
            value: x.customer_id
        }
    })
    return {
        type: types.SET_LIST_DATA,
        payload: {
            data: data,
            key: 'debtor_list'
        }
    }
}

export const setVehicleList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.licenseplateno,
            value: x.vehicle_id
        }
    })
    return {
        type: types.SET_LIST_DATA,
        payload: {
            data: data,
            key: 'vehicle_list'
        }
    }
}
export const setBusinessList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.businessname,
            value: x.business_id
        }
    })
    return {
        type: types.SET_ANY_BUSINESS_LIST,
        payload: {
            data: data,
            component: 'customertransaction'

        }
    }
}
export const setUnitMeasureList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.measurename,
            value: x.unitmeasure_id
        }
    })
    return {
        type: types.SET_LIST_DATA,
        payload: {
            data: data,
            key: 'unit_list'
        }
    }
}
export const setContraAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            ...x,
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })

    return {
        type: types.SET_LIST_DATA,
        payload: {
            data: data,
            key: 'glaccount_list'
        }
    }
}
export const setItemAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })
    return {
        type: types.SET_ITEM_ACCOUNT_LIST,
        payload: {
            data: data
        }
    }
}
export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_DROP_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field
        }
    }
}
export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_NEW_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value //format(field, value)
            }
        })
    }
}

export const saveCustomerInvoiceData = (payload) => {
    return (dispatch, getState) => {
        console.log('payloadx', payload)
        let error = ""
        if(payload.data.invoice_items.length){
            for(let x of payload.data.invoice_items) {
                if(!x.account_id)
                    error = "item GL account required"
                if(!x.total)
                    error = "item total amount missing"
                if(!x.unitprice && !payload.data.transaction_type.text && ['RC - Receipt','PM - Payment'].includes(payload.data.transaction_type.text))
                    error = "item unit price required"
                if(!x.unit_text && !payload.data.transaction_type.text && ['RC - Receipt','PM - Payment'].includes(payload.data.transaction_type.text))
                    error = "item unit required"
                if(!x.description)
                    error = "item description required"
            }
        }
        if(!payload.data.invoice_items.length)
            error = "Please add items before you proceed"
        if(!payload.data.customertransaction_month)
            error = "Please enter transaction month"
        if(!payload.data.invoice_reference)
            error = "Please enter invoice reference"
        if(!payload.data.customer_reference)
            error = "Please enter customer reference"
        if(!payload.data.customertransaction_date)
            error = "Please enter transaction date"
        if(!payload.data.invoice_description)
            error = "Please enter description"
        if(!payload.data.selected_customer.value)
            error = "Please select customer"
        if(!payload.data.transaction_type.value)
            error = "Please select transaction type"
        if(error) {
            
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: error,
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    type: 'danger',
                    timestamp: moment().format('h:mm A')
                }
            })
        }
        else {
            dispatch({
                type: types.SAVE_CUSTOMER_INVOICE_DATA,
                payload: {
                    data: payload
                }
            })
        }
    }
}

export const savedCustomerInvoiceData = (data, resp) => {
    let type = resp.data.data.transaction_type.value
    return (dispatch, getState) => {
        if (data != null) {
            if (data.id) {
                // if (type.indexOf('IN ') >= 0) dispatch(getInvoicePDF(Number(data.id)))
                // if (type.indexOf('CR ') >= 0) dispatch(getCreditNotePDF(Number(data.id)))

                //console.log('savedCustomerInvoiceDatax',data)
                dispatch(getInvoicePDF(data.id))
                dispatch({
                    type: types.SAVED_CUSTOMER_INVOICE_DATA
                })

                dispatch(resetForm())
                
                dispatch({
                    type: appTypes.SHOW_NOTIFICATION,
                    payload: {
                        title: 'System Notification',
                        additionalText: 'Capturing Successful!',
                        overflowText: getState().login.fields.email.input,
                        autoHide: 10000,
                        timestamp: moment().format('h:mm A')
                    }
                })
            }
        }
        else {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Capturing Failed!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}
export const setInvoiceItems = (data) => {
    const payload = {
        data: data,
        prop: 'invoice_items',
    }
    return (dispatch, getState) => {
        dispatch({
            type: types.ADD_ROW,
            payload: payload
        })
    }
}

// export const addBlankRow = () => {
//     return (dispatch, getState) => {
//         let data = getState().customertransaction.invoice_items
//         const payload = {
//             data: data.concat({
//                 description: '',
//                 unit: '',
//                 quantity: 0,
//                 unitprice: 0,
//                 amount: 0,
//                 vat: 0,
//                 total: 0,
//                 key: data.length + 1,
//                 account_id: 0
//             }),
//             prop: 'invoice_items',
//         }
//         dispatch({
//             type: types.ADD_ROW,
//             payload: payload
//         })
//     }
// }

export const addBlankRow = () => {
    return (dispatch, getState) => {
        let data = getState().customertransaction.invoice_items
        const payload = {
            data: data.concat({
                description: '',
                unit: '',
                quantity: 1,
                unitprice: 0,
                amount: 0,
                vat: 0,
                total: 0,
                vatable: true,
                discount: 0,
                account_id: 0
            }),
            prop: 'invoice_items',
        }
        dispatch({
            type: types.ADD_ROW,
            payload: payload
        })
    }
}

export const removeRow = (inx) => {
    return (dispatch, getState) => {
        let data = getState().customertransaction.invoice_items.filter((x, i) => i !== (inx))

        dispatch({
            type: types.REMOVE_ROW,
            payload: {
                data: data,
                prop: 'invoice_items',
            }
        })
    }
}
export const sendInvalidInvoice = (payload) => {
    if (payload.data != null) {
        return {
            type: types.SET_INVOICE_INVALID,
            payload: {
                valid: false
            }
        }
    }
}
export const invalidEntry = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Please capture Reference and Line items...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const setItemValue = (payload) => {
    return (dispatch, getState) => {
        let data = getState().customertransaction.invoice_items
        data[payload.inx][payload.field] = payload.value
        dispatch({
            type: types.ADD_ROW,
            payload: {
                data: data,
                prop: 'invoice_items',
            }
        })
    }
}
export const setDuplicateReferenceCheck = (payload) => {
    if (payload.data.length > 0) {
        return (dispatch, getState) => {
            dispatch({
                type: types.INVOICE_DUPLICATE_FOUND,
                payload: {
                    component: 'customertransaction'
                }
            })

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'The Reference Number already Exist!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return (dispatch, getState) => {
            dispatch({
                type: types.INVOICE_NO_DUPLICATE_FOUND,
                payload: {
                    component: 'customertransaction'
                }
            })
        }
    }
}
export const getDuplicateReferenceCheck = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_INVOICE_DUPLICATE_REFERENCE_CHECK,
            payload: {
                type: "GET_INVOICE_DUPLICATE_REFERENCE_CHECK",
                reference: reference
            }
        })
    }
}
export const foundDuplicateReference = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'The Reference Number already Exist!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const getInvoicePDF = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        let vehicle_id  = getState().customertransaction.vehicle_id.value
        dispatch({
            type: types.GEN_CUSTOMER_INVOICE_PDF,
            payload: {
                type: "GEN_CUSTOMER_INVOICE_PDF",
                id: id,
                vehicle_id: vehicle_id
            }
        })
    }
}
export const setCustomerInvoicePDF = (data) => {
    //window.glyco.log('setCustomerCreditNotePDF')
    //window.glyco.log(data)
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    //console.log('setCustomerInvoicePDFx', data.data)

    if(data.data.pdf) {
        saveByteArray([data.data.pdf.Body], data.data.key)
    }
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}


export const getCreditNotePDF = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GEN_CUSTOMER_CREDIT_NOTE_PDF,
            payload: {
                type: "GEN_CUSTOMER_CREDIT_NOTE_PDF",
                id: id
            }
        })
    }
}


export const getInvoiceDetails = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_CUSTOMERTRANSACTION_INVOICE_DETAILS,
            payload: id
        })
    }
}


export const setInvoiceDetails = (results) => {
    return (dispatch, getState) => {
        //console.log('setInvoiceDetailsx',results)
        
        dispatch(setLookupValue("transaction_type", 0, results.data[0].transactiontype))
        dispatch(setLookupValue("selected_customer", results.data[0].customer_id, results.data[0].customer.customername + ' | ' + results.data[0].customer.accountnumber))
        //dispatch(getLookupValueData(results.data[0].customer_id))
        //dispatch(getAllTransactionTypes(results.data[0].transactiontype))

        dispatch(setInputValue('invoice_description',  results.data[0].description))
        dispatch(setInputValue('customer_reference',  results.data[0].customerreference))
        dispatch(setInputValue('invoice_reference',  results.data[0].reference))
        dispatch(setInputValue('customertransaction_month',  results.data[0].customertransactionmonth))
        dispatch(setInputValue('customertransaction_date', moment(results.data[0].customertransactiondate).format('YYYY-MM-DD')))

        dispatch(setInputValue('sub_total',  results.data[0].nettvalue))
        dispatch(setInputValue('vat_total',  results.data[0].vat))
        dispatch(setInputValue('none_total',  0))
        dispatch(setInputValue('grand_total',  results.data[0].amount))
        dispatch({
            type: types.SET_COMPONENT_CUSTOMERTRANSACTION_DATA,
            payload: {
                prop: 'invoice_items',
                data: results.data[0].customertransactionitem.map(x => {
                    return {
                        description: x.description,
                        total: x.totalamount,
                        quantity: x.quantity,
                        unitprice: x.unitprice,
                        vatable: x.vattable,
                        vat: x.vat,

                        unit_text: x.unitmeasure.unitmeasure,
                        account_text: x.accountingitem.itemdescription,

                        unit: x.unitmeasure_id,
                        account_id: x.accountingitem_id,
                    }
                })

            }
        })
    }
}

export const setCustomerCreditNotePDF = (data) => {
    window.glyco.log('setCustomerCreditNotePDF')
    window.glyco.log(data)

    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    if(data.data.pdf) {
        saveByteArray([data.data.pdf.Body], data.data.key)
    }
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}


export const calcTotals = () => {
    return (dispatch, getState) => {
        let ispayment = getState().customertransaction.transaction_type ? ['RC - Reciept','PM - Payment'].includes(getState().customertransaction.transaction_type.text) : false
        let invoice_items = getState().customertransaction.invoice_items
        let sub_total = 0
        let vat_total = 0
        let none_total = 0
        let grand_total = 0
        for (let i = 0; i < invoice_items.length; i++) {
            let quantity = Number(invoice_items[i].quantity ? invoice_items[i].quantity : 0)
            quantity = ispayment ? 1 : quantity

            if(Number(invoice_items[i].unitprice ? invoice_items[i].unitprice : 0) === 0 || quantity === 0) continue
            var discount = Number(invoice_items[i].discount ? invoice_items[i].discount : 0) 
            var amount = (Number(invoice_items[i].unitprice ? invoice_items[i].unitprice : 0) * quantity)  - Number(discount)
            var vat = ispayment ? 0 : invoice_items[i].vatable ? Number(amount ? amount : 0) * 0.15 : 0
            var total = (amount + vat)

            sub_total = sub_total + ((ispayment || invoice_items[i].vatable) ? amount : 0)
            none_total = grand_total + ((ispayment || invoice_items[i].vatable) ? 0 : amount)

            vat_total = vat_total + vat
            grand_total = grand_total + total

            invoice_items[i].vat = vat
            invoice_items[i].total = total
            invoice_items[i].amount = amount
        }
        const payload = {
            data: invoice_items,
            prop: 'invoice_items',
        }
        dispatch({
            type: types.ADD_ROW,
            payload: payload
        })
        dispatch(setInputValue('sub_total',Number(sub_total).toFixed(2)))
        dispatch(setInputValue('none_total',Number(none_total).toFixed(2)))
        dispatch(setInputValue('vat_total',Number(vat_total).toFixed(2)))
        dispatch(setInputValue('grand_total',Number(grand_total).toFixed(2)))
    }
}

export const resetForm = () => {
    return (dispatch) => {
        dispatch(setLookupValue("transaction_type", null, null))
        dispatch(setLookupValue("selected_customer", null, null))
        dispatch(setInputValue('invoice_reference', ''))
        dispatch(setInputValue('customer_reference', ''))
        dispatch(setInputValue('invoice_description', ''))
        dispatch(setInputValue('sub_total', 0))
        dispatch(setInputValue('vat_total', 0))
        dispatch(setInputValue('none_total', 0))
        dispatch(setInputValue('grand_total', 0))
        
        // dispatch(setInputValue('customer_id', null))
        // dispatch(setInputValue('customer', null))
        dispatch(setInputValue('customertransaction_date', moment().format('MM/DD/YYYY')))
        dispatch(setInputValue('customertransaction_month', moment().format('YYYYMM')))
        dispatch(setInputValue('viewinvoice', false))
        dispatch(setInputValue('invoice_items', []))
    }
}


