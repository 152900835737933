import * as types from './types'

import * as uploadActions from '../../Upload/actions'
import * as quoteItemsTypes from '../../WorkflowPOHeader/QuoteItems/types'
import * as quoteItemsActions from '../../WorkflowPOHeader/QuoteItems/actions'

import moment from 'moment'


export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_DEFLEET_CAPTUREQUOTE_COMPOMENT_STATE
        })
    }
}

export const resetPOComponent = (type) => {
    return (dispatch, getState) => {
        dispatch(uploadActions.resetAllDocuments())
        
        console.log('setQuotationsx 1')
        dispatch(quoteItemsActions.setQuotations([]))

        console.log('setQuotationsx 2')
        
        dispatch({
            type: quoteItemsTypes.SET_QUOTATION_ITEMS_INPUT_DATA,
            payload: []
        })
    }
}

export const getPreAuthNumber = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PRE_AUTHORISATION_NUMBER,
            payload: {
                type: 'GET_PRE_AUTHORISATION_NUMBER'
            }
        })
    }
}

export const setPreAuthNumber = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PRE_AUTHORISATION_NUMBER,
            payload: data.data
        })
    }
}

export const selectPurchaseOrderType = (type) => {
    return (dispatch, getState) => {
        const po = getState().poHeader.purchaseOrderTypes.filter(x => x.value === type)[0]
        dispatch({
            type: types.SELECT_PURCHASE_ORDER_TYPE,
            payload: po
        })
    }
}
export const getUserAutoCompleteMenuItems = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_USER_AUTOCOMPLETE_MENU_ITEMS,
            payload: {
                query: '?firstname=' + value
            }
        })
    }
}

export const setUserAutoCompleteMenuItems = (result, selected) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_AUTOCOMPLETE_MENU_ITEMS,
            payload: selected
                ? result.data
                : result.data.length
                    ? result.data.map(x => {
                        return {
                            value: x.user_id,
                            text: x.firstname + ' ' + x.lastname
                        }
                    })
                    : []
        })
    }
}

export const setUserAutoCompleteSelected = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_USER_AUTOCOMPLETE_SELECTED,
            payload: request
        })
    }
}


export const getVehicleListAutoCompleteMenuItems = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS,
            payload: {
                query: '?licenseplateno=' + value
            }
        })
    }
}

export const setVehicleListAutoCompleteMenuItems = (result, selected) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS,
            payload: selected
                ? result.data
                : result.data.length
                    ? result.data.map(x => {
                        return {
                            value: x.vehicle_id,
                            text: x.licenseplateno + ' (' + x.makemodelyear + ')'
                        }
                    })
                    : []
        })
    }
}

export const setVehicleAutoCompleteSelected = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_VEHICLE_AUTOCOMPLETE_SELECTED,
            payload: request
        })
    }
}

export const getSelectedVehicleDetails = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_VEHICLE_DETAILS,
            payload: {
                query: request.value
            }
        })
    }
}

export const setSelectedVehicleDetails = (result, vehicle_id) => {
    window.glyco.log('SET_SELECTED_VEHICLE_DETAILS')
    window.glyco.log(result)
    return (dispatch, getState) => {

        const curr_type = getState().poHeader.selectedPurchaseOrderType.key
        dispatch({
            type: types.SET_SELECTED_VEHICLE_DETAILS,
            payload: {
                data: result.data
            }
        })
    }
}

export const setSupplierAutoCompleteAmount = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_AUTOCOMPLETE_AMOUNT,

            query: '?licenseplateno=' + value
        })
    }
}
export const getSupplierAutoCompleteMenuItems = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CAPTUREQUOTE_SUPPLIERS,
            payload: {
                query: '?suppliername=' + value + '&accountnumber=' + value + '&suppliertype=' + value 
            }
        })
    }
}

export const setSupplierAutoCompleteSelected = (request) => {
    //console.log("selectedSupplier >>>>")
    //console.log(request)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_AUTOCOMPLETE_SELECTED,
            payload: request
        })
        if (request.value) {
            dispatch(getSelectedSupplierDetails(request))
        }        
        dispatch(setValidationValue("selectedSupplier", request))
    }
}

export const getSelectedSupplierDetails = (request) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_SUPPLIER_DETAILS_DEFLEET,
            payload: {
                query: '?supplier_id=' + request.value
            }
        })
    }
}

export const toggleSupplierSuspended = (suspended) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_SUPPLIER_SUSPENDED,
            payload: suspended
        })
    }
}

export const setSelectedSupplierDetails = (result) => {
    //console.log("resultresultresultresult",result)
    return (dispatch, getState) => {

        dispatch({
            type: types.SET_DEFLEET_SELECTED_SUPPLIER_DETAILS,
            payload: {
                data: result.data
            }
        })
        let suspended = result.data.length ? result.data[0].suspended : true

        dispatch(toggleSupplierSuspended(suspended))

        dispatch({
            type: types.SET_SUPPLIER_SUSPENDED_STATUS,
            payload: suspended
        })

        dispatch({
            type: types.SET_DEFLEET_SUSPENDED_SUPPLIER_DETAILS,
            payload: {
                supplier: result.data[0],
                data: suspended ? false : result.data,
                status: suspended ? false : true,
                suspendedreason: result.data.length ? result.data[0].suspendedreason : '',
            }
        })
        let sla = result.data[0].slqexpirydate ? moment(result.data[0].slqexpirydate).diff(moment(), "days") : 0
        let bee = result.data[0].beecertificateexpdate ? moment(result.data[0].beecertificateexpdate).diff(moment(), "days") : 0
        let tax = result.data[0].taxclearancecertificateexpirydate ? moment(result.data[0].taxclearancecertificateexpirydate).diff(moment(), "days") : 0

        dispatch({
            type: types.TOGGLE_DEFLEET_UPCOMING_SUPPLIER_SUSPENDED_STATUS,
            payload: {
                upcoming: ((sla > 0 && sla < 30) || (bee > 0 && bee < 30) || (tax > 0 && tax < 30)),
                sla: (sla > 0 && sla < 30),
                bee: (bee > 0 && bee < 30),
                tax: (tax > 0 && tax < 30)
            }
        })
    }
}

export const addSupplierAutoComplete = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ADD_SUPPLIER_AUTOCOMPLETE
        })
    }
}

export const removeSupplierAutoComplete = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.REMOVE_SUPPLIER_AUTOCOMPLETE,
            payload: getState().poHeader.supplierAutoCompletes.length - 1
        })
    }
}

export const setSupplierAutoCompleteMenuItems = (result) => {
    //console.log("resultresultresultresultresultresultresult")
    //console.log(result)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CAPTUREQUOTE_SUPPLIERS,
            payload: {
                data: result.data
            }
        })

    }
}


export const getSupplierListInRange = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST,
            payload: {
                type: 'GET_VEHICLE_BOOKING_SUPPLIER',
                vehicle: request.value,
                range: 6000,
                suppliertype: 'Dealership'
            }
        })
    }
}

export const setBookingSuppliers = (payload) => {

    window.glyco.log('setBookingSuppliers')
    window.glyco.log(payload.data)

    return (dispatch, getState) => {

        window.glyco.log('getState().poHeader.selectedVehicleDetails[0]')
        window.glyco.log(getState().poHeader.selectedVehicleDetails.data[0])
        dispatch({
            type: types.SET_GEO_MAP_SUPPLIERS,
            payload: {
                markers: payload.data.markers
            }
        })
        dispatch({
            type: types.SET_VEHICLE_SERVICE_INFORMATION,
            payload: payload.data.serviceinfo
        })
        dispatch({
            type: types.SET_VEHICLE_CURRENT_BOOKING_INFO,
            payload: {
                bookinginfo: payload.data.bookinginfo
            }
        })
        dispatch({
            type: types.GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT,
            payload: {
                type: 'GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT',
                data: {
                    vehicle: getState().poHeader.selectedVehicleDetails.data[0],
                    serviceinfo: payload.data.serviceinfo
                }
            }
        })
    }
}

export const getSupplierAge = (request, inx) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_AGE,
            payload: {
                query: '?supplier_id=' + request.value
            }
        })
    }
}
export const setSupplierAge = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUPPLIER_AGE,
            payload: data.data
        })
    }
}

export const setMaintenanceHistoryPDF = (data) => {


    window.glyco.log('setMaintenanceHistoryPDF')
    window.glyco.log(data)

    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.Body], 'Vehicle Maintenance History.pdf')

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT,
            payload: {
                preview: link,
                filename: data.data.key.replace('quotes/', '')
            }
        })
    }
}

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUES,
            payload: {
                prop: prop,
                value: value
            }
        })
        dispatch(setValidationValue(prop, value))
    }
}


export const setValidationValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DEFLEET_CAPTUREQUOTE_VALIDATION_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}


export const toggleUpcomingSupplierSuspended = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_DEFLEET_UPCOMING_SUPPLIER_SUSPENDED_STATUS,
            payload: {
                upcoming: status,
                sla: status,
                bee: status,
                tax: status
            }
        })
    }
}
