import * as types from './types'
import * as _ from 'lodash'

export const setPurchaseorderinvoiceAutoCompleteSelected = (request) => {
    window.glyco.log('setPurchaseorderinvoiceAutoCompleteSelected')
    window.glyco.log(request)
    return (dispatch, getState) => {
        const data = request.data.map(x => {
            return {
                text: x.ponumber  + ' | Vehicle: ' + x.licenseplateno+ ' | ' + x.purchaseordertype + ' | ' + x.purchaseorderstatus,
                value: x.purchaseorder_id,
                ponumber: x.ponumber ,
                amount_excl: x.amount_excl,
                amount_vat: x.amount_vat,
                amount_incl: x.amount_incl,
                amount_disc: x.amount_disc,
                invoicenumber: x.invoicenumber,
                supplierinvoiceamount: Number(x.amount_incl)
            }
        })
        dispatch({
            type: types.SET_PURCHASE_ORDER_INVOICE_ACTION,
            payload: data
        })
    }
}

export const setPurchaseorderinvoiceAutoCompleteSelectedValue = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PURCHASE_ORDER_INVOICE_SELECTED_VALUE,
            payload: request
        })
    }
}

export const getPurchaseorderinvoiceAutoCompleteEpic = (id, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PURCHASE_ORDER_INVOICE_ACTION,
            payload: {
                id: id,
                search: search
            }
        })
    }
}

export const getSupplierAutoCompleteEpic = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_INVOICE_ACTION,
            payload: search
        })
    }
}

export const setSupplierAutoComplete = (request) => {
    return (dispatch, getState) => {
        const data = request.data.map(x => {
            return {
                text: x.suppliername  + ' | Acc#: ' + x.accountnumber + ' | Type: ' + x.suppliertype,
                value: x.supplier_id,
                name:x.suppliername 
            }
        })
        dispatch({
            type: types.SET_INVOICEAP_FIELD_VALUE,
            payload: {
                prop: 'supplier',
                value: data
            } 
        })
    }
}

export const setSelectedAupplier = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_INVOICEAP_FIELD_VALUE,
            payload: {
                prop: 'supplier_id',
                value: request.value
            } 
        })
        
        dispatch({
            type: types.SET_INVOICEAP_FIELD_VALUE,
            payload: {
                prop: 'suppliername',
                value: request.name
            } 
        })
    }
}

 export const setContraaccountinvoiceAutoCompleteSelectedValue = (request) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CONTRA_ACCOUNT_INVOICE_SELECTED_VALUE,
            payload: request
        })
    }
}

export const setContraaccountinvoiceAutoCompleteSelected = (request) => {
    return (dispatch, getState) => {
        const data = request.data.map(x => {
            return {
                itemdescription: x.itemdescription,
                accountingitem_id: x.accountingitem_id
            }
        })
        const accounts = _.uniqBy(data, 'itemdescription')
        dispatch({
            type: types.SET_CONTRA_ACCOUNT_INVOICE_ACTION,
            payload: accounts
        })
    }
}
export const getContraaccountinvoiceAutoCompleteEpic = (search) => {
    
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CONTRA_ACCOUNT_INVOICE_ACTION,
            payload: search
        })
    }
} 

export const setValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_INVOICEAP_FIELD_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
} 


export const getPurchaseOrderSearch = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_PURCHASE_ORDERS_SEARCH,
            payload: {
                type: "GET_PURCHASE_ORDERS_SEARCH",
                search: payload.search,
                id: payload.id,
            }
        })
    }
}