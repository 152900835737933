import * as colours from '@mui/material/colors'
import * as config from '../../../config'


export const workflowTaskStepComponentCard = {
    textAlign: 'left',
}

export const textBox = {
    width: '100%'
}

export const view = {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0
}

export const card = {
    height: '100%'
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 10,
    textAlign: 'left',
}

export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldLabel = {
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
}

export const fieldLabelBold = {
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    fontWeight: 'bold',
    border: '1px solid black',
    paddingRight: 2
}

export const selectedFieldName = {
    fontWeight: 'bold',
    textAlign: 'left'
}