import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
//import Checkbox from '@mui/material/Checkbox'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'

import AutoComplete from '../../../controls/autocomplete'
import Checkbox from '../../../controls/checkbox'
import TextField from '../../../controls/textField'


import * as compActions from '../../Components/actions'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as styles from './styles'
import Card from '../../../controls/card'

var viewsearchbox = false, cust_id = 0
class CUSTOMERSTATEMENT extends Component {
    componentDidMount() {
        if (this.props.state.loading) this.props.actions.getComponent('customerstatement')
    }

    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "pdfstatement") {
            return < Button className={"global_button"} variant="contained" header={false}
                label={'Download'}
                onClick={(event) => {
                    const data = {
                        month: row.value,
                        id: this.props.state.data.customer_id.input,
                    }
                    this.props.actions.getCustomerStatementPDF(data)
                }}
            >{window.t.en('Download')}</Button>
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        }

        return columns
    }

    getGrid() {
        const data = this.props.state.data.customerstatement_id.data
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row style={styles.headerrow} >
                    <Col xl={3}>
                        {'Contact Type'}
                    </Col>
                    <Col xl={3}>
                        {'Full Name'}
                    </Col>
                    <Col xl={3}>
                        {'E-Mail'}
                    </Col>
                    <Col xl={3}>
                        {'Select'}
                    </Col>
                </Row>
                {
                    data.map(x => {
                        return <Row style={styles.itemrow}>
                            <Col xl={3}>
                                {x.contacttype}
                            </Col>
                            <Col xl={3}>
                                {x.fullname}
                            </Col>
                            <Col xl={3}>
                                {x.email}
                            </Col>
                            <Col xl={3}>
                                <Checkbox
                                    checked={x.allow_mail}
                                    onCheck={(event) => {
                                        var check = x.allow_mail ? false : true
                                        var values = {
                                            id: x.contact_id,
                                            field: 'allow_mail',
                                            value: check
                                        }
                                        this.props.actions.setFieldValue(values, data)
                                    }} />
                            </Col>
                        </Row>
                    })
                }
                <br></br>
                <Row><Col xl={12}>{' '}</Col></Row>
                <Row>
                    <Col xl={2}>
                        <b>Send: </b><Checkbox
                            checked={this.props.state.data.statementmonth.input ? true : false}
                            onCheck={(event) => {
                                var check = this.props.state.data.statementmonth.input ? false : true
                                this.props.actions.setLookupValue("statementmonth", check, check)
                            }} />
                    </Col>
                    <Col xl={2}>
                        <b>Link: </b><Checkbox
                            checked={this.props.state.data.csvstatement.input ? true : false}
                            onCheck={(event) => {
                                var check = this.props.state.data.csvstatement.input ? false : true
                                this.props.actions.setLookupValue("csvstatement", check, check)
                            }} />
                    </Col>
                    <Col xl={2}>
                        <b>PDF: </b><Checkbox
                            checked={this.props.state.data.pdfstatement.input ? true : false}
                            onCheck={(event) => {
                                var check = this.props.state.data.pdfstatement.input ? false : true
                                this.props.actions.setLookupValue("pdfstatement", check, check)
                            }} />
                    </Col>
                    <Col xl={2}>
                        <b>Excel: </b><Checkbox
                            checked={this.props.state.data.excelstatement.input ? true : false}
                            onCheck={(event) => {
                                var check = this.props.state.data.excelstatement.input ? false : true
                                this.props.actions.setLookupValue("excelstatement", check, check)
                            }} />
                    </Col>
                    <Col xl={2}>
                        <b>Send to this email: </b><TextField
                            variant="outlined"
                            margin="normal"
                            id={'txtreference'}
                            name={'txtreference'}
                            hintText='Custom email...'
                            value={this.props.state.data.customerstatement_id.input}
                            onChange={(event) => {
                                let value = event.target.value
                                this.props.actions.setLookupValue("customerstatement_id", value, value)
                            }}
                        />
                    </Col>
                    <Col xl={2}>
                    <br/><br/>
                       {cust_id ? < Button className={"global_button"} variant="contained" header={false}
                            disabled={cust_id ? false : true}
                            label={window.t.en('Generate Statement')}
                            onClick={(event) => {
                                var object = {
                                    customer_id: this.props.state.data.customer_id.input,
                                    data: this.props.state.data.customerstatement_id.data,
                                    send: this.props.state.data.statementmonth.input,
                                    link: this.props.state.data.csvstatement.input,
                                    pdf: this.props.state.data.pdfstatement.input,
                                    excel: this.props.state.data.excelstatement.input,
                                    custom: this.props.state.data.customerstatement_id.input

                                }
                                this.props.actions.getAdhocStatement(object)
                            }}
                        >{window.t.en('Generate Statement')}</Button> : ''}
                        <div>
                        {/* <br></br>
                        < Button className={"global_button"} header={false}
                                label={window.t.en('SAP Statement')}
                                onClick={(event) => {
                                    this.props.actions.getCustomerBillingSAPStatement(this.props.state.data.customerstatement_id.input)
                                }}
                            >{window.t.en('SAP Statement')}</Button><br></br><br></br>
                            < Button className={"global_button"} header={false}
                                label={window.t.en('TFR Statement')}
                                onClick={(event) => {
                                    this.props.actions.getCustomerBillingTFRStatements(this.props.state.data.customerstatement_id.input)
                                }}
                            >{window.t.en('TFR Statement')}</Button> */}
                            </div>
                    </Col>
                </Row>
            </Container>
        </div>
    }


    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <Card
            title={window.t.en('Customer Statement')}
            subtitle={window.t.en('Statements')}
            content={<Container style={styles.whitediv}>
                <Row>
                    {
                        this.props.state.user.email.includes("innov8") ? <Col xl={5}>< Button className={"global_button"} header={false}
                            label={'Generate EXCEL Statements'}
                            onClick={(event) => {
                                this.props.actions.getCustomerBillingStatementsExcel()
                            }}
                        >{window.t.en('Generate EXCEL Statements')}</Button>&nbsp;
                            < Button variant="contained" className="btn-Accept"
                                label={'Generate Statements'}
                                onClick={(event) => {
                                    this.props.actions.getCustomerBillingStatements()
                                }}
                            >{window.t.en('Generate Statements')}</Button>&nbsp;
                            < Button variant="contained" className="btn-Accept"
                                label={'DOWNLOAD REBILLS'}
                                onClick={(event) => {
                                    this.props.actions.getRebills()
                                }}
                            >{window.t.en('DOWNLOAD REBILLS')}</Button>&nbsp; < Button className={"global_button"} variant="contained" header={false}
                            label={'GET TIME'}
                            onClick={(event) => {
                                this.props.actions.getTimetz()
                            }}
                        >{window.t.en('GET TIME')}</Button>&nbsp;<TextField
                        variant="outlined"
                        margin="normal"
                        id={'txtreference'}
                        name={'txtreference'}
                        hintText='Custom email...'
                        disabled={true}
                        value={this.props.state.view.timetz}
                        onChange={(event) => {
                        }}
                    />< Button className={"global_button"} variant="contained" header={false}
                            label={'SET TIME'}
                            onClick={(event) => {
                                this.props.actions.postTimetz()
                            }}
                        >{window.t.en('SET TIME')}</Button>
                        </Col> :
                            <Col></Col>
                    }
                </Row>
                <Row><Col xl={12}><br></br></Col> </Row>
                <Row>
                    <Col xl={9}>
                        <div style={styles.fieldContainer}>
                            <div style={styles.fieldTitle}>{'Debtor:'}</div>
                            <div style={styles.fieldContent}>

                                <AutoComplete
                                    name={'dll_debtor_id'}
                                    title={window.t.en('Search...')}
                                    info={{
                                        show: false
                                    }}
                                    fullWidth={true}
                                    data={this.props.state.data.customer_id.data}
                                    onSelect={(args) => {
                                        //console.log("argsargsargsargsargs", args)
                                        this.props.actions.setLookupValue("customer_id", args ? args.text.split(' | ').length ? args.text.split(' | ')[1] : null : null, args.text)

                                        this.props.actions.getallcontacts(args.value)
                                        this.props.actions.setLookupValue("statementmonth", true, true)
                                        this.props.actions.setLookupValue("csvstatement", false, false)
                                        this.props.actions.setLookupValue("pdfstatement", true, true)
                                        this.props.actions.setLookupValue("excelstatement", true, true)
                                        this.props.actions.setLookupValue("customerstatement_id", this.props.state.user.email, this.props.state.user.email)

                                        viewsearchbox = true
                                        cust_id = Number(args.value)
                                    }
                                    }
                                    onClick={() =>
                                        this.props.actions.getCustomerList('')
                                    }
                                    onFilter={(args) =>
                                        this.props.actions.getCustomerList(args)
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xl={3}>

                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.getGrid()}
                    </Col>
                </Row>
            </Container>} />

    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.customerstatement,
            view: {
                ...state.customerstatementview
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERSTATEMENT)