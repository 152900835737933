export const GET_COMPLETED_POS = 'GET_COMPLETED_POS'
export const APPROVE_PO = 'APPROVE_PO'
export const REJECT_PO = 'REJECT_PO'
export const UPLOAD_PO_DOCUMENTS = 'UPLOAD_PO_DOCUMENTS'
export const GET_POSUPPLIERLIST = 'GET_POSUPPLIERLIST'
export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const REFRESH_INVAMT = 'REFRESH_INVAMT'
export const REFRESH_INVNO = 'REFRESH_INVNO'
export const UPDATE_VALUE = 'UPDATE_VALUE'
export const TOGGLE_ITEM = 'TOGGLE_ITEM'
export const GET_BUSINESSLIST = 'GET_BUSINESSLIST'
export const SET_BUSINESSLIST = 'SET_BUSINESSLIST'
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'
export const GET_PAYMENT_DOCUMENT = 'GET_PAYMENT_DOCUMENT'
export const GET_EXCEL_GRID_EXPORT = 'GET_EXCEL_GRID_EXPORT'
export const ADD_BLANK = 'ADD_BLANK'
export const UPDATE_ITEM_VALUE = 'UPDATE_ITEM_VALUE'
export const APPROVE_ITEM = 'APPROVE_ITEM'
export const PROCESS_PAYMENT = 'PROCESS_PAYMENT'
export const GENERATE_PAYMENT_NOTIFICATION = 'GENERATE_PAYMENT_NOTIFICATION'
export const SET_PAYMENT_SELECTED_ITEM = 'SET_PAYMENT_SELECTED_ITEM'
export const UPDATE_ITEM = 'UPDATE_ITEM'
export const GET_SUPPLIER_TRANSACTION_DOCUMENT = 'GET_SUPPLIER_TRANSACTION_DOCUMENT'
export const UPDATE_ITEM_ADMIN = 'UPDATE_ITEM_ADMIN'
export const APPROVE_ITEM_ADMIN = 'APPROVE_ITEM_ADMIN'
export const TOGGLE_ITEM_ADMIN = 'TOGGLE_ITEM_ADMIN'
export const TOGGLE_DONTPAY_ITEM = 'TOGGLE_DONTPAY_ITEM'
export const TOGGLE_DONTPAY_ITEM_ADMIN = 'TOGGLE_DONTPAY_ITEM_ADMIN'
export const DOCUMENT_CHECK = 'DOCUMENT_CHECK'
export const TOGGLE_ITEM_MANAGEMENT = 'TOGGLE_ITEM_MANAGEMENT'

export const GET_PAYMENT_DOCUMENT_BY_ID = 'GET_PAYMENT_DOCUMENT_BY_ID'
export const GET_PAYMENT_DATA_LIST = 'GET_PAYMENT_DATA_LIST'
export const SET_PAYMENT_DATA_LIST = 'SET_PAYMENT_DATA_LIST'
export const SET_PROP_INDEX = 'SET_PROP_INDEX'
export const SET_PROP_DRAWER_INDEX = 'SET_PROP_DRAWER_INDEX'
export const SET_AUTOMATED_SUPPLIER_PAYMENT_LIST = 'SET_AUTOMATED_SUPPLIER_PAYMENT_LIST'
export const GET_AUTOMATED_SUPPLIER_PAYMENT_LIST = 'GET_AUTOMATED_SUPPLIER_PAYMENT_LIST'
export const TOGGLE_SHORTPAY_ITEM = 'TOGGLE_SHORTPAY_ITEM'
export const TOGGLE_CREDIT_ITEM = 'TOGGLE_CREDIT_ITEM'
export const SET_PAYMENT_FIELD_INPUT_VALUE = 'SET_PAYMENT_FIELD_INPUT_VALUE'

export const GET_PAYMENT_DATA_LIST_ADMIN = 'GET_PAYMENT_DATA_LIST_ADMIN'
export const SET_PAYMENT_DATA_LIST_ADMIN = 'SET_PAYMENT_DATA_LIST_ADMIN'
export const LINK_SUPPLIER_DOCUMENT = 'LINK_SUPPLIER_DOCUMENT'


export const GET_PAYMENT_DATA_LIST_MAINT = 'GET_PAYMENT_DATA_LIST_MAINT'
export const SET_PAYMENT_DATA_LIST_MAINT = 'SET_PAYMENT_DATA_LIST_MAINT'

export const GET_PAYMENT_DATA_LIST_TYRES = 'GET_PAYMENT_DATA_LIST_TYRES'
export const SET_PAYMENT_DATA_LIST_TYRES = 'SET_PAYMENT_DATA_LIST_TYRES'
export const RESET_COMPONENT = 'RESET_COMPONENT'
export const SET_PAYMENT_MAIN_DATA = 'SET_PAYMENT_MAIN_DATA'
export const COMMIT_PAYMENT = 'COMMIT_PAYMENT'
export const RESET_PAYMENT_COMPOMENT_STATE = 'RESET_PAYMENT_COMPOMENT_STATE'
export const SET_PAYMENT_VALIDATION_VALUE = 'SET_PAYMENT_VALIDATION_VALUE'
export const SET_PAYMENT_PROP_INDEX = 'SET_PAYMENT_PROP_INDEX'
export const CLEAR_REPORT = 'CLEAR_REPORT'