import * as actions from './actions'
import * as types from './types'

import * as config from '../../config'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as requestTypes from '../Request/types'


export const getWorkFlowTasksEpic = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.GET_WORKFLOW_TASKS
    ),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/workflow_task/other',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'auth': store.value.cognito.authenticateUser.token
                },
                body: {
                    ...payload
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setWorkFlowTasks(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/component/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )


export const updateWorkflowTaskUser = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.UPDATE_WORKFLOW_TASK_USER
    ),
        mergeMap(({payload}) =>
            ajax$({
                url: config.system.api + '/components/workflow_task/other',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'auth': store.value.cognito.authenticateUser.token
                },
                body: {
                    ...payload
                }
            })
                .pipe(
            mergeMap((result) => [
                    actions.setUpdateWorkflowTaskUser(result.response),
                    requestActions.requestSucceeded(config.system.api + '/components/component/other')
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component/other', error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
)
      

