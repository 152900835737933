import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
// import TextField from '@mui/material/TextField'
/* import AutoComplete from '../../../controls/autocomplete' */

import Icon from '../../Icon/component'

import AutoComplete from '../../../controls/autocomplete'
import DateControl from '../../../controls/dateControl'
import TextField from '../../../controls/textField'
import Card from '../../../controls/card'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'

import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as compActions from '../../Components/actions'
import Upload from '../../Upload/component'
import * as actions from './actions'
import * as styles from './styles'
import * as _ from 'lodash'


class CUSTOMERTRANSACTION extends Component {
    componentDidMount() {
        this.props.actions.resetForm()
        if(this?.props?.params?.id)
            this.props.actions.getInvoiceDetails(this?.props?.params?.id)
    }
 
    getView() {
        return <div>
            <Card
                title={('Capture Transactions')}
                subtitle={('Capture customer transactions')}
                content={
                <Container style={styles.whitediv}>
                    <Row>
                        <Col xl={6}>
                            <Button className={"global_button"} variant="contained" header={false}
                                primary={true}
                                onClick={() => {
                                    this.props.actions.resetForm()
                                }}
                            >{('Capture New')}</Button>
                        </Col>
                        <Col xl={6}></Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}><strong>{('Transaction Type')}</strong></div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                            name={'dll_invoice_id'}
                                            title={('Search...')}
                                            info={{
                                                show: false
                                            }}
                                            fullWidth={true}
                                            data={this.props.state.transaction_types}
                                            error={this.props.state.transaction_type.text ? "" : "Select transaction type"}
                                            value={this.props.state.transaction_type.text ? this.props.state.transaction_type.text : ''}
                                            onSelect={(args) => {
                                                if (args) {
                                                    this.props.actions.setLookupValue("transaction_type", args.value, args.text)
                                                    if(['RC - Reciept','PM - Payment'].includes(args.text)) {
                                                        this.props.actions.setInputValue("invoice_items", [])
                                                        this.props.actions.addBlankRow()
                                                    }
                                                }
                                            }
                                            }
                                            onClick={() =>
                                                this.props.actions.getAllTransactionTypes('')
                                            }
                                            onFilter={(args) => {
                                                this.props.actions.setLookupValue("transaction_type", null, null)
                                                this.props.actions.getAllTransactionTypes(args)
                                            }
                                            }
                                        />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Debtor / Customer')}</div>
                                <div style={styles.fieldContent}>
                                   <AutoComplete
                                                name={'dll_debtor_id'}
                                                title={('Search...')}
                                                info={{
                                                    show: false
                                                }}
                                                fullWidth={true}
                                                data={this.props.state.debtor_list}
                                                value={this.props.state.selected_customer.text ? this.props.state.selected_customer.text : ''}
                                                error={this.props.state.selected_customer.text ? "" : "Select customer"}
                                                onSelect={(args) => {
                                                    ////console.log("argsargsargsargs")
                                                    ////console.log(args)

                                                    //var _date = moment(new Date()).format('YYYYMM')
                                                    this.props.actions.setLookupValue("selected_customer", args.value, args.text)
                                                    //this.props.actions.setInputValue('customertransaction_date', new Date())
                                                    //this.props.actions.setInputValue('customertransaction_month', _date)
                                                    //this.props.actions.setInvoiceItems([{ description: '', unit: '', quantity: 0, unitprice: 0, amount: 0, vat: 0, total: 0, key: 1, account_id: 0 }])
                                                }
                                                }
                                                onClick={() =>
                                                    this.props.actions.getLookupValueData('')
                                                }
                                                onFilter={(args) =>{
                                                    this.props.actions.getLookupValueData(args)
                                                    this.props.actions.setLookupValue("selected_customer", null, null)
                                                }
                                                }
                                            />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Transaction Date')}</div>
                                <div style={styles.fieldContent}>
                                    <DateControl
                                        id={'txt_invoicedate'}
                                        key={'txt_invoicedate'}
                                        name={'txt_invoicedate'}
                                        label={('Select Date...')}
                                        field={'customertransaction_date'}
                                        error={this.props.state.customertransaction_date ? "" : "Enter transaction date"}
                                        value={this.props.state.customertransaction_date}
                                        onSelect={(args) => {
                                            this.props.actions.setInputValue('customertransaction_date', args)
                                        }}
                                        type={'date'}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Transaction Month')}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txt_customertransaction_month'}
                                        key={'txt_customertransaction_month'}
                                        label={('Enter Month...')}
                                        fullWidth={true}
                                        variant="outlined"
                                        /* color="secondary" */
                                        error={this.props.state.customertransaction_month ? "" : "Enter transaction month"}
                                        value={this.props.state.customertransaction_month}
                                        onChange={
                                            (event, value) => {
                                                this.props.actions.setInputValue('customertransaction_month', event.target.value)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Our Reference')}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtreference'}
                                        key={'txtreference'}
                                        label={'Enter Reference...'}
                                        fullWidth={true}
                                        variant="outlined"
                                        error={this.props.state.invoice_reference ? "" : "Enter reference"}
                                        /* color="secondary" */
                                        value={this.props.state.invoice_reference}
                                        onChange={
                                            (event) => {
                                                //console.log("invoice_reference", event)
                                                this.props.actions.setInputValue('invoice_reference', event.target.value)
                                                this.props.actions.getDuplicateReferenceCheck(event.target.value)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Customer Reference')}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtcustomer_reference'}
                                        key={'txtcustomer_reference'}
                                        label={'Enter Customer Reference...'}
                                        fullWidth={true}
                                        variant="outlined"
                                        /* color="secondary" */
                                        error={this.props.state.customer_reference ? "" : "Enter customer reference"}
                                        value={this.props.state.customer_reference}
                                        onChange={
                                            (event, value) => {
                                                this.props.actions.setInputValue('customer_reference', event.target.value)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Description')}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'txtdescription'}
                                        key={'txtdescription'}
                                        label={('Enter Description...')}
                                        fullWidth={true}
                                        variant="outlined"
                                        /* color="secondary" */
                                        error={this.props.state.invoice_description ? "" : "Enter description"}
                                        value={this.props.state.invoice_description}
                                        onChange={
                                            (event, value) => {
                                                this.props.actions.setInputValue('invoice_description', event.target.value)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                {this.props.state.invoice_description && this.props.state.invoice_description.includes('Fuel') &&
                            <Row>
                               <Col xl={12}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{('Select Vehicle Registration')}</div>
                                <div style={styles.fieldContent}>
                                   <AutoComplete
                                                name={'dll_vehicle_id'}
                                                title={('Search...')}
                                                info={{
                                                    show: false
                                                }}
                                                fullWidth={true}
                                                data={this.props.state.vehicle_list}
                                                value={this.props.state.licenseplateno.text ? this.props.state.licenseplateno.text : ''}
                                                onSelect={(args) => {
                                                    ////console.log("argsargsargsargs")
                                                    ////console.log(args)

                                                    //var _date = moment(new Date()).format('YYYYMM')
                                                    this.props.actions.setLookupValue("licenseplateno", args.value, args.text)
                                                    this.props.actions.setLookupValue("vehicle_id", args.value, args.text)
                                                    //this.props.actions.setInputValue('customertransaction_date', new Date())
                                                    //this.props.actions.setInputValue('customertransaction_month', _date)
                                                    //this.props.actions.setInvoiceItems([{ description: '', unit: '', quantity: 0, unitprice: 0, amount: 0, vat: 0, total: 0, key: 1, account_id: 0 }])
                                                }
                                                }
                                                onClick={() =>
                                                    this.props.actions.getVehicleList('',this.props.state.selected_customer.value)
                                                }
                                                onFilter={(args) =>{
                                                    this.props.actions.getVehicleList(args,this.props.state.selected_customer.value)
                                                    this.props.actions.setLookupValue("licenseplateno", null, null)
                                                    this.props.actions.setLookupValue("vehicle_id", null, null)
                                                }
                                                }
                                            />
                                </div>
                            </div>
                        </Col>
                            </Row>
                }

                </Container>

                } 
            />
            
            <Card
            title={('Capture Items')}
            subtitle={('Capture transaction items')}
            content={ 
                <div>
                <Table className="table table-hover text-nowrap mb-0">
                    <thead>
                        <tr>
                            <td colSpan={6}>
                                <Button variant="contained" className="btn-Accept"
                                onClick={() => {
                                    this.props.actions.addBlankRow()
                                }}
                                >{('Add Item')}</Button>
                            </td>
                        </tr>
                    </thead>
                    <thead>
                        {
                            this.props.state.transaction_type.text && ['RC - Receipt','PM - Payment'].includes(this.props.state.transaction_type.text)
                            ? <tr>
                                <th>{''}</th>
                                <th style={{width: '300px'}}>{'Description'}</th>
                                {/* <th>{'Quantity'}</th> */}
                                <th colSpan={2}>{this.props.state.transaction_type.text.split(' - ')[1] + ' Amount'}</th>
                                {/* <th colSpan={2}>{'Settlement Discount'}</th> */}
                                <th colSpan={2}>{'Total'}</th>
                                <th  colSpan={2} style={{width: '300px'}}>{'GL Account'}</th>
                            </tr>
                            : <tr>
                                <th>{''}</th>
                                <th style={{width: '300px'}}>{'Description'}</th>
                                <th style={{width: '200px'}}>{'Unit'}</th>
                                <th>{'Quantity'}</th>
                                <th>{'Unit Price'}</th>
                                <th>{'Vatable'}</th>
                                <th>{'VAT'}</th>
                                <th>{'Total'}</th>
                                <th style={{width: '300px'}}>{'GL Account'}</th>
                            </tr>
                        }
                    </thead>
                    <tbody style={{ backgroundColor: '#fff', color: '#000' }}>
                        {
                            this.props.state.invoice_items 
                                ? this.props.state.invoice_items.map((x, inx) => {
                                return this.props.state.transaction_type.text && ['RC - Receipt','PM - Payment'].includes(this.props.state.transaction_type.text)
                                ? <tr style={{ backgroundColor: '#fff', color: '#000' }}>
                                    <td>
                                        <IconButton key={'delete_forever_' + inx}
                                            onClick={
                                                () => {
                                                    this.props.actions.removeRow(inx)
                                                }
                                            }>
                                            <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                    </td>
                                    <td>
                                        <TextField
                                            id={'txtitemdescription'}
                                            key={'txtitemdescription'}
                                            //label={'Description'}
                                            fullWidth={true}
                                            variant="outlined"
                                            /* color="secondary" */
                                            value={x.description}
                                            onChange={(event) => {
                                                //console.log('onChangex',event)
                                                    let value = event.target.value
                                                    this.props.actions.setItemValue({ inx: inx, field: 'description', value: value })
                                                }
                                            }
                                        />
                                    </td>
                                    {/* <td>
                                        <TextField
                                            id={'txtitemquantity'}
                                            key={'txtitemquantity'}
                                            //label={'Quantity'}
                                            fullWidth={true}
                                            disabled={true}
                                            variant="outlined"
                                            value={x.quantity}
                                            onChange={
                                                (event) => {
                                                    this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: event.target.value })
                                                    this.props.actions.calcTotals()
                                                }
                                            }
                                        />
                                    </td> */}
                                    <td colSpan={2}>
                                        <TextField
                                            id={'txtitemunitprice'}
                                            key={'txtitemunitprice'}
                                            //label={'Unit Price'}
                                            fullWidth={true}
                                            variant="outlined"
                                            value={x.unitprice}
                                            onChange={
                                                (event) => {
                                                    this.props.actions.setItemValue({ inx: inx, field: 'unitprice', value: event.target.value })
                                                    this.props.actions.calcTotals()
                                                }
                                            }
                                        />
                                    </td>
                                    <td colSpan={2}>
                                        <TextField
                                            id={'txtitemtotal'}
                                            key={'txtitemtotal'}
                                            //label={'Total'}
                                            fullWidth={true}
                                            disabled={true}
                                            variant="outlined"
                                            value={x.total}

                                        />
                                    </td>
                                    <td colSpan={2}> 
                                                <AutoComplete
                                                    name={'dll_itemaccount'}
                                                    title={'Search...'}
                                                    info={{
                                                        show: false
                                                    }}
                                                    fullWidth={true}
                                                    data={this.props.state.glaccount_list}
                                                    value={x.account_text}
                                                    onSelect={(args) => {
                                                        this.props.actions.setItemValue({ inx: inx, field: 'account_id', value: args.value })
                                                        this.props.actions.setItemValue({ inx: inx, field: 'account_text', value: args.text })
                                                    }
                                                    }
                                                    onClick={() =>
                                                        this.props.actions.getContractAccountListData('')
                                                    }
                                                    onFilter={(args) =>
                                                        this.props.actions.getContractAccountListData(args)
                                                    }
                                                />
                                    </td>
                                    <td></td>
                                </tr>
                                : <tr style={{ backgroundColor: '#fff', color: '#000' }}>
                                <td>
                                        <IconButton key={'delete_forever_' + inx}
                                            onClick={
                                                () => {
                                                    this.props.actions.removeRow(inx)
                                                }
                                            }>
                                            <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
                                        </IconButton>
                                </td>
                                <td>
                                    <TextField
                                        id={'txtitemdescription'}
                                        key={'txtitemdescription'}
                                        //label={'Description'}
                                        fullWidth={true}
                                        variant="outlined"
                                        /* color="secondary" */
                                        value={x.description}
                                        onChange={(event) => {
                                            //console.log('onChangex',event)
                                                let value = event.target.value
                                                this.props.actions.setItemValue({ inx: inx, field: 'description', value: value })
                                            }
                                        }
                                    />
                                </td>
                                    <td>
                                        <AutoComplete
                                            name={'dll_itemunit'}
                                            title={window.t.en('Search...')}
                                            info={{
                                                show: false
                                            }}
                                            fullWidth={true}
                                            data={this.props.state.unit_list}
                                            value={x.unit_text}
                                            onSelect={(args) => {
                                                if (args.value) {
                                                    this.props.actions.setItemValue({ inx: inx, field: 'unit', value: args.value })
                                                    this.props.actions.setItemValue({ inx: inx, field: 'unit_text', value: args.text })
                                                }
                                            }
                                            }
                                            onClick={() =>
                                                this.props.actions.getUnitMeasureListData('')
                                            }
                                            onFilter={(args) =>
                                                this.props.actions.getUnitMeasureListData(args)
                                            }
                                        />
                                    </td>
                                <td>
                                    <TextField
                                        id={'txtitemquantity'}
                                        key={'txtitemquantity'}
                                        //label={'Quantity'}
                                        fullWidth={true}
                                        variant="outlined"
                                        /* color="secondary" */
                                        value={x.quantity}
                                        onChange={
                                            (event) => {
                                                this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: event.target.value })
                                                this.props.actions.calcTotals()
                                                // let value = event.target.value
                                                // var unitprice = Number(x.unitprice)
                                                // var amount = unitprice ? Number(unitprice) * Number(value) : 0
                                                // var vat = x.vat ? Number(amount) * 0.15 : 0
                                                // var total = amount
                                                // var vattable = vat ? true : false

                                                // this.props.actions.setItemValue({ inx: inx, field: 'unitprice', value: unitprice })
                                                // this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: value })
                                                // this.props.actions.setItemValue({ inx: inx, field: 'vat', value: vat })
                                                // this.props.actions.setItemValue({ inx: inx, field: 'amount', value: total })
                                                // this.props.actions.setItemValue({ inx: inx, field: 'vattable', value: vattable })
                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <TextField
                                        id={'txtitemunitprice'}
                                        key={'txtitemunitprice'}
                                        //label={'Unit Price'}
                                        fullWidth={true}
                                        variant="outlined"
                                        value={x.unitprice}
                                        onChange={
                                            (event) => {
                                                this.props.actions.setItemValue({ inx: inx, field: 'unitprice', value: event.target.value })
                                                this.props.actions.calcTotals()
                                                // let value = event.target.value
                                                // var unitprice = value
                                                // var amount = Number(unitprice) * Number(x.quantity)
                                                // var vat = x.vat ? Number(amount) * 0.15 : 0
                                                // var total = amount
                                                // var vattable = vat ? true : false

                                                // this.props.actions.setItemValue({ inx: inx, field: 'unitprice', value: unitprice })
                                                // this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: x.quantity })
                                                // this.props.actions.setItemValue({ inx: inx, field: 'vat', value: vat })
                                                // this.props.actions.setItemValue({ inx: inx, field: 'amount', value: total })
                                                // this.props.actions.setItemValue({ inx: inx, field: 'vattable', value: vattable })
                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <Checkbox
                                        key={'chck_vattable'}
                                        id={'chck_vattable'}
                                        checked={x.vatable}
                                        onClick={(event, checked) => {
                                            //console.log('onChangex event',event.target.checked)
                                            this.props.actions.setItemValue({ inx: inx, field: 'vatable', value: event.target.checked })
                                            this.props.actions.calcTotals()
                                            // this.props.actions.setItemValue({ inx: inx, field: 'quantity', value: x.quantity })
                                            // this.props.actions.setItemValue({ inx: inx, field: 'vat', value: vat })
                                            // this.props.actions.setItemValue({ inx: inx, field: 'amount', value: total })
                                            // this.props.actions.setItemValue({ inx: inx, field: 'vattable', value: vattable })

                                        }} />
                                </td>
                                <td>
                                    <TextField
                                        id={'txtitemvat'}
                                        key={'txtitemvat'}
                                        //label={'VAT'}
                                        fullWidth={true}
                                        disabled={true}
                                        variant="outlined"
                                        value={x.vat}

                                    />
                                </td>
                                <td>
                                    <TextField
                                        id={'txtitemtotal'}
                                        key={'txtitemtotal'}
                                        //label={'Total'}
                                        fullWidth={true}
                                        disabled={true}
                                        variant="outlined"
                                        value={x.total}

                                    />
                                </td>
                                <td>
                                            <AutoComplete
                                                name={'dll_itemaccount'}
                                                title={'Search...'}
                                                info={{
                                                    show: false
                                                }}
                                                fullWidth={true}
                                                data={this.props.state.glaccount_list}
                                                value={x.account_text}
                                                onSelect={(args) => {
                                                    
                                                    let glaccount = this.props.state.glaccount_list
                                                    .filter(x => x.accountingitem_id == args.value)
                                                    glaccount = glaccount.length ? glaccount[0] : {}
                                                    
                                                    //console.log('glaccountx list', this.props.state.glaccount_list)
                                                    //console.log('glaccountx gl', glaccount)
                                                    //console.log('glaccountx id', args)
                                                    
                                                    this.props.actions.setItemValue({ inx: inx, field: 'vatable', value: glaccount.vatable ? true : false })
                                                    this.props.actions.calcTotals()

                                                    this.props.actions.setItemValue({ inx: inx, field: 'account_id', value: args.value })
                                                    this.props.actions.setItemValue({ inx: inx, field: 'account_text', value: args.text })
                                                }
                                                }
                                                onClick={() =>
                                                    this.props.actions.getContractAccountListData('')
                                                }
                                                onFilter={(args) =>
                                                    this.props.actions.getContractAccountListData(args)
                                                }
                                            />
                                </td>
                                <td></td>
                            </tr>
                            })
                            : ''
                        }
                    </tbody>
                </Table>
                <hr />
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={7}> </Col>
                        <Col xl={5} style={styles.rightpanel}>
                            <Row>
                                <Col xl={12}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}><strong>{('Sub-Total (Vattable)')}</strong></div>
                                        <div style={styles.numericFields}>
                                            <TextField
                                                id={'txtcredit'}
                                                value={this.props.state.sub_total}
                                                onChange={() => {
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}><strong>{('VAT')}</strong></div>
                                        <div style={styles.numericFields}>
                                            <TextField
                                                id={'txtvat'}
                                                value={this.props.state.vat_total}
                                                onChange={() => {
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}><strong>{('Sub-Total (Non-Vattable)')}</strong></div>
                                        <div style={styles.numericFields}>
                                            <TextField
                                                id={'txtnettvalue'}
                                                value={this.props.state.none_total}
                                                onChange={() => {
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}><strong>{('Total')}</strong></div>
                                        <div style={styles.numericFields}>
                                            <TextField
                                                id={'txttotal'}
                                                value={this.props.state.grand_total}
                                                onChange={() => {
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row><Col xl={12}> </Col></Row>
                        </Col>
                    </Row>
                    <Row><Col xl={12}><br></br></Col></Row>
                </Container>
                </div>
            } />


                    
            <Card
                title={('Documents')}
                subtitle={('Upload supporting documents')}
                content={ <Upload
                    id={'upload_ctl'}
                    prefix={'inv'}
                    header={false}
                    list={[{ value: 0, text: 'Supplier Invoice' }, { value: 0, text: 'Supplier Attachment' }]}
                /> }
                />
                <Container style={{ margin: 0 }} className="custom-container">
            <Row><Col xl={12}><br></br></Col></Row>
                <Row>
                    <Col xl={10}></Col>
                    <Col xl={2}>
                        < Button className={"global_button"} variant="contained" header={false}
                            onClick={() => {
                                
                                let vat = this.props.state.invoice_items.length ? _.sumBy(this.props.state.invoice_items, (d) => { return parseFloat(Number(d.vat) ? Number(d.amount) * 0.15 : 0.00) }).toFixed(2) : 0.00
                                this.props.actions.setInputValue('total_vat', vat)
                                let total = this.props.state.invoice_items.length ? _.sumBy(this.props.state.invoice_items, (d) => { return parseFloat(Number(d.vat) ? Number(d.amount) + Number(d.amount) * 0.15 : Number(d.amount)) }).toFixed(2) : 0.00
                                this.props.actions.setInputValue('total_amount', total)
                                let nett = total - vat
                                this.props.actions.setInputValue('nettvalue', nett)

                                var payload = {
                                    data: {
                                        invoice_items: this.props.state.invoice_items,
                                        selected_customer: this.props.state.selected_customer,
                                        transaction_type: this.props.state.transaction_type,
                                        customertransaction_date: this.props.state.customertransaction_date,
                                        customertransaction_month: this.props.state.customertransaction_month,
                                        customer_reference: this.props.state.customer_reference,
                                        invoice_reference: this.props.state.invoice_reference,
                                        invoice_description: this.props.state.invoice_description,
                                        customer_id: this.props.state.selected_customer.value,
                                        // total_vat: this.props.state.total_vat,
                                        // total_amount: this.props.state.total_amount,
                                        // nettvalue: this.props.state.nettvalue
                                        
                                        total_vat: this.props.state.vat_total,
                                        total_amount: this.props.state.grand_total,
                                        nettvalue: this.props.state.sub_total,
                                        nonvattotal: this.props.state.none_total,
                                    },
                                    documents: this.props.state.upload.documents,
                                    user: this.props.state.user
                                }
                                this.props.actions.saveCustomerInvoiceData(payload)
                                
                            }}
                        >{('Save Transaction')}</Button>
                    </Col>
                </Row></Container>

        </div>
    }
    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.customertransaction,
            upload: {
                ...state.upload
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERTRANSACTION)