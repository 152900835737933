export let cognitoUser = {}
export let cognitoUserData = {}
export let cognitoUserPool = {}
export let cognitoUserAttributes = {}
export let cognitoAuthenticationDetails = {}

export const cognito = (store) => (next) => (action) => {
    try {
        switch (action.type) {
            default:
                break
        }

        next(action)
    } catch (error) {
        throw new Error(error)
    }
}