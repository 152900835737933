import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import Checkbox from '../../../controls/checkbox'
import Button from "@mui/material/Button";

import moment from 'moment';
import TextField from '../../../controls/textField'
import Section from '../../../controls/section'
import Upload from '../../Upload/component'
import BillingReview from './reviewgrid'
import PdfViewer from '../../PDF/component'

import * as styles from './styles'
import * as actions from './actions'

class BillingRefresh extends Component {
    componentDidMount() {
        //console.log('getGridDatax mount')
        this.props.actions.reset()
        this.props.actions.getGridData()
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data
            let billingtracking_id = this.data[0].step1.billingtracking_id
            this.props.actions.getBillingTracking(billingtracking_id)
        } catch (error) {

        }
    }

    getTaskDetails() {
        return <Fields 
        fields={[
            {label: 'Billing Month', value: this?.props?.state?.billingtracking?.[0]?.billing_month},
            {label: 'Billing Task Number', value: this?.props?.state?.billingtracking?.[0]?.billing_taskno},
            {label: '', value: ''},
                    
            {label: 'Billing Pre File Date', value: moment(this?.props?.state?.billingtracking?.[0]?.billing_prep_date).format('YYYY-MM-DD HH:mm')},
            {label: 'Billing Pre File By', value: this?.props?.state?.billingtracking?.[0]?.billing_prep_by},
            {label: '', value: ''},
                    
            {label: 'Billing Odo File Date', value: moment(this?.props?.state?.billingtracking?.[0]?.billing_odo_date).format('YYYY-MM-DD HH:mm')},
            {label: 'Billing Odo File By', value: this?.props?.state?.billingtracking?.[0]?.billing_odo_by},
            {label: '', value: ''},
                    
            {label: 'Billing OOCP File Date', value: moment(this?.props?.state?.billingtracking?.[0]?.billing_oocp_date).format('YYYY-MM-DD HH:mm')},
            {label: 'Billing OOCP File By', value: this?.props?.state?.billingtracking?.[0]?.billing_oocp_by},
            {label: '', value: ''},
                    
            {label: 'Last Freshed Date', value: moment(this?.props?.state?.billingtracking?.[0]?.billing_refresh_date).format('YYYY-MM-DD HH:mm')},
            {label: 'Last Freshed By', value: this?.props?.state?.billingtracking?.[0]?.billing_refresh_by}
        ]}
        />
    }

    getRefreshDetails() {
        return <div>
            
        <Section title={window.t.en('Billing Summary')}
        subtitle={window.t.en('Billing details summary')}/>

        <Fields 
                fields={[
                    {label: 'Total Invoice Count', value: this?.props?.state?.data?.length},
                    {label: 'Total Invoice Amount (Excl)', value: _.sumBy(this.props.state.data, (d) => { return parseFloat(Number(d.amount) ? Number(d.amount) : 0.00) }).toFixed(2)},
                    {label: '', value: ''},
                    {label: 'FML Count ', value: this.props.state.data.filter(x => x.description.includes('Billing FML')).length},
                    {label: 'FML Amount (Excl)', value: _.sumBy(this.props.state.data.filter(x => x.description.includes('Billing FML')), (d) => { return parseFloat(Number(d.amount) ? Number(d.amount) : 0.00) }).toFixed(2)},
                    {label: '', value: ''},
                    {label: 'SAP Count', value: this.props.state.data.filter(x => x.description.includes('Billing SAP')).length},
                    {label: 'SAP Amount (Excl)', value: _.sumBy(this.props.state.data.filter(x => x.description.includes('Billing SAP')), (d) => { return parseFloat(Number(d.amount) ? Number(d.amount) : 0.00) }).toFixed(2)},
                    {label: '', value: ''},
                    {label: 'Adhoc Rental Count', value: this.props.state.data.filter(x => x.description.includes('Billing STR')).length},
                    {label: 'Adhoc Rental Amount (Excl)', value: _.sumBy(this.props.state.data.filter(x => x.description.includes('Billing STR')), (d) => { return parseFloat(Number(d.amount) ? Number(d.amount) : 0.00) }).toFixed(2)},
                    //{label: 'STR_SAP Count', value: this.props.state.data.filter(x => x.contracttype.includes('Billing STR')).length},
                    //{label: 'STR_SAP Amount (Excl)', value: _.sumBy(this.props.state.data.filter(x => x.contracttype.includes('Billing STR')), (d) => { return parseFloat(Number(d.subtotal) ? Number(d.subtotal) : 0.00) }).toFixed(2)},
                ]}
                />
                </div> 
    }

    getDocuments() {
        return <Card title={window.t.en('Billing Documents')}
                subtitle={window.t.en('List of uploaded documents')}
                content={<div>
                    {
                        this.documents
                        ? <PdfViewer                    
                            key={'document_viewer_uploaded_file'}
                            name={'tsk_billing_run_start'}
                            workflow={'billing_run'}
                            task={'tsk_billing_run_start'}
                            documents={this.documents}
                            edit={false}
                            preview={'buttons'}
                        />
                        : ''
                    }
                    </div>
                }
            />
    }

    getTask() {
        return <Card title={window.t.en('Task Details')}
                subtitle={window.t.en('Task details')}
                content={this.getTaskDetails()}
            />
    }
    
    getRefresh() {
        return this.getRefreshDetails()
    }
    
    getBilling() {
        return <div><Card title={window.t.en('Normal Billing')}
                subtitle={window.t.en('This is Monthly Billing, which includes prorata billing and termination excess')}
                content={<BillingReview />}
                headerAction={<div style={{float: 'right'}}>
                <Button
                        variant="contained"
                        color="primary"
                        className="btn-success"
                        onClick={(event, value) => {
                            this.props.actions.refreshBilling(this.billingtracking_id)
                        }}
                        >
                        {"Refresh Billing "}
                    </Button>
                    &nbsp;
                    <Button
                            variant="contained"
                            color="primary"
                            className="btn-success"
                            onClick={(event, value) => {
                                this.props.actions.exportBillingItems(this.billingtracking_id)
                            }}
                            >
                            {"Export Billing Items "}
                        </Button>
                    </div>
                }
            />
        </div> 
    }

    getExcess() {
        return <Card title={window.t.en('Utilization Billing')}
                subtitle={window.t.en('This is Quarterly Utilization Billing')}
                content={<Container style={{ margin: 0 }} className="custom-container"></Container>}
                headerAction={<div style={{float: 'right'}}>
                    <Button
                            variant="contained"
                            color="primary"
                            className="btn-success"
                            onClick={(event, value) => {
                                //console.log('Button Click')
                            }}
                            >
                            {"Export Header Items "}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="btn-success"
                            onClick={(event, value) => {
                                //console.log('Button Click')
                            }}
                            >
                            {"Export Line Items "}
                        </Button>
                    </div>
                }
            />
            }

    getForm() {
        return <Card title={window.t.en('Billing File Upload')}
                subtitle={window.t.en('Upload Billing File')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">   
                        
                        {
                            this.props.state.fields.refreshed.value
                                ?
                                <Row>
                                    <Col xl={12}> <div style={styles.redbox}>
                                        <h2>{'Billing Refresh Approval'}</h2>
                                        <div style={styles.redboxtext}>
                                            {'Please note that when you approve this task, you acknowledge that billing has been checked and no issues are outstanding'}<br /><br />
                                            <Checkbox
                                                id={'txt_finalbilling'}
                                                key={'txt_finalbilling'}
                                                name={'txt_finalbilling'}
                                                field={'finalbilling'}
                                                checked={this.props.state.fields.checked.value}
                                                labelPlacement={'end'}
                                                label={window.t.en('Please confirm that Billing has been checked')}
                                                onCheck={(checked) => {
                                                    this.props.actions.setValue('checked', checked)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    </Col>
                                </Row> : ''
                        }
                        <Row>
                            <Col xl={12}>
                               
                            </Col>
                        </Row>
                    </Container>
                } 
                />
    }
    
    uploadFile() {
        return <Card 
            title={window.t.en('Upload Confirmed File')}
            subtitle={window.t.en('Upload Billing Reviewd File')}
            content={<Container style={{ margin: 0 }} className="custom-container"> <Row>
                <Col xl={12}>
                    <br/>
                    <Upload
                        id={'upload_file'}
                        prefix={'Billing_Check_upload_' + moment().format("YYYYMMDDmmss")}
                        header={false}
                        list={[{ value: 0, text: 'Billing Check File' }]}
                    />
                </Col>
            </Row>
            </Container>
            }
        />
    }
    
    commentsView() {
        return <div>
            <Card 
                title={window.t.en('Refresh Billing Comments')}
                subtitle={window.t.en('Capture comments')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomment'}
                                key={'txtcomment'}
                                label={window.t.en('Enter Comments...')}
                                fullWidth={true}
                                variant="outlined"
                                value={this.props.state.comments}
                                onChange={
                                    (event) => {
                                        this.props.actions.setValue('comments', event.target.value)
                                    }
                                }
                            /></Col>
                    </Row>
                </Container>} />
        </div>
    }

    getLayout() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>{this.getDocuments()}</Col>
                <Col xl={12}>{this.getTask()}</Col>
                <Col xl={12}>{this.getRefresh()}</Col>
                <Col xl={12}>{this.getBilling()}</Col>
                {/* <Col xl={12}>{this.getExcess()}</Col> */}
                <Col xl={12}>{this.uploadFile()}</Col>
                <Col xl={12}>{this.commentsView()}</Col>
            </Row>
        </Container>
    }

    render() {
        
        
        this.data = null
        try {
            //console.log('this.props.workflow_queue_data',this.props.workflow_queue_data)
            this.data = this.props.workflow_queue_data.context_data.data.data.data
            this.billingtracking_id = this.data[0].step1.billingtracking_id
            this.documents = this.data[0].documents
        } catch (error) {

        }


        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.billingrefresh,
            user: {
                ...state.user
            },
            upload: {
                ...state.upload
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingRefresh)