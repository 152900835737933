import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import AutoComplete from '../../../controls/autocomplete'
import { Card, CardHeader, CardContent } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import ReactTable from "react-table"
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import * as compActions from '../../Components/actions'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as styles from './styles'







var viewinvoice = false, id = 0

class DISPOSAL extends Component {
    componentDidMount() {
        if (this.props.state.loading) {
            this.props.actions.getComponent('disposal')
        }
    }
    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "approved") {
            return< Button className={"global_button"} variant="contained"
                disabled={row.original.approved ? true : false}
                label={row.original.approved ? "Approved" : "Approve"} 
                primary={true}
                onClick={(event) => {
                    this.props.actions.approveTermination(Number(row.original.disposal_id))
                }}
                >{row.original.approved ? "Approved" : "Approve"}</Button>
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold', 
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black', //'#4A4A4A',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        }

        return columns
    }

    getGrid() {
        return <div> < Button className={"global_button"}
            secondary={true}
            label="Excel Export"
            icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
            onClick={(event) => {
                //this.props.actions.excelExport(this.component)
            }}
            ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Excel Export</Button>{<TextField
        hintText={'Search by registration number...'}
        value={this.props.state.data.disposal_id.input}
        //value={this.props.state.data.customerallocation_id.input}
        onChange={(event, value) => {
            this.props.actions.getTerminationList(value)
        }}
    />}{< Button className={"global_button"}
    icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
    onClick={(event) => {
        event.preventDefault()
        //this.props.actions.getFilterAllocationsData(this.props.state.data.customerallocation_id.input,cust_id)
        ////console.log("this.props.state.data.disposal_id.data")
        this.props.actions.getTerminationList(this.props.state.data.disposal_id.input)
   }}
><Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} /></Button>}<ReactTable
            key={'grid_' + this.component.name + '_' + this.component.component_id}
            //className='-striped -highlight'
            data={this.props.state.data.disposal_id.data}
            columns={this.getColumns()}
            defaultPageSize={this.component.grid.paging.pageSize}
            manual
            onFetchData={
                (state) => {
                }
            }
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.selectRow(this.component.name, rowInfo)

                        if (this.props.toggle) {
                            //this.props.actions.toggleInfoSheet()
                        }
                    },
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            onResizedChange={(newResized, event) => {
                //this.props.actions.resizeColumn(this.component.name, newResized)
            }}
            minRows={1}
            pages={this.component.grid.paging.pageCount}
            showPageJump={false}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable></div>
    }


    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <Tabs>
            <Tab label={'Termination List'}>
            <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {this.getGrid()}
                        </Col>
                    </Row>
            </Container>
            </Tab>
            <Tab label={'Capture Termination'}>
                <Card style={styles.card} style={styles.row}>
                    <CardHeader
                        title={window.t.en('Vehicle Termination ')}
                        subtitle={window.t.en('Capture Termination')}
                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                    <CardContent> <Container style={styles.whitediv}>
                        <Row>
                            <Col xl={4}>
                                <Container style={styles.whitediv}>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Registration:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        id={'dll_invoice_id'}
                                                        key={'dll_invoice_id'}
                                                        hintText={'Search for vehicle...'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.data.vehicle_id.data}
                                                        onNewRequest={
                                                            (chosenRequest, index) => {
                                                                this.props.actions.setLookupValue("vehicle_id", chosenRequest.value, chosenRequest.text)
                                                                if (chosenRequest.value) {
                                                                    this.props.actions.getVehicleDetails(chosenRequest.value)
                                                                }
                                                            }
                                                        }
                                                        onClick={
                                                            (event) => {
                                                                this.props.actions.getAllVehicles('')
                                                            }
                                                        }
                                                        onUpdateInput={
                                                            (search, dataSource, params) => {
                                                                this.props.actions.getAllVehicles(search)
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}><br></br></Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Terminated:'}</div>
                                            <div style={styles.fieldContent}>
                                                <Checkbox
                                                    checked={this.props.state.data.approved.input ? true : false}
                                                    onClick={(event) => {
                                                        var value = this.props.state.data.approved.input ? true : false;
                                                        if (value) {
                                                            this.props.actions.setInputValue('disposaldate')
                                                            this.props.actions.setInputValue('approved', false)
                                                        } else {
                                                            this.props.actions.setInputValue('approved', true)
                                                            this.props.actions.setInputValue('disposaldate', new Date())
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    {/* <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Status Category:'}</div>
                                            <div style={styles.fieldContent}>
                                                <AutoComplete
                                                    listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    id={'dll_business_id'}
                                                    key={'dll_business_id'}
                                                    hintText={'Status Category...'}
                                                    errorText={this.props.state.data.vehicle_id.input ? null : '*'}
                                                    filter={AutoComplete.fuzzyFilter}
                                                    openOnFocus={true}
                                                    fullWidth={true}
                                                    dataSourceConfig={{
                                                        text: 'text',
                                                        value: 'value'
                                                    }}
                                                    dataSource={this.props.state.data.vehicle_id.data}
                                                    onNewRequest={
                                                        (chosenRequest, index) => {
                                                            this.props.actions.setLookupValue("vehicle_id", chosenRequest.value, chosenRequest.text)
                                                            if (chosenRequest.value) {
                                                                this.props.actions.getVehicleDetails(chosenRequest.value)
                                                                //console.log("moment(new Date()).format('YYYY')")
                                                                //console.log(moment(new Date()).format('YYYY'))
                                                            }
                                                        }
                                                    }
                                                    onClick={
                                                        (event) => {
                                                            this.props.actions.getAllVehicles('')
                                                        }
                                                    }
                                                    onUpdateInput={
                                                        (search, dataSource, params) => {
                                                            this.props.actions.getAllVehicles(search)
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row> */}
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Odometer:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                id={'txt_customername'}
                                                hintText='Odometer'
                                                value={this.props.state.data.odometer.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('odometer', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Termination Date:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                type="date"
                                                id={'txt_invoicedate'}
                                                hintText='Date'
                                                container='inline'
                                                mode='landscape'
                                                value={this.props.state.data.disposaldate.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('disposaldate', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    {/* <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Sold To:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                id={'txt_customer_fktext'}
                                                hintText='Sold To'
                                                value={''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row> */}
                                    {/* <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Sold Reference:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                id={'txt_customer_fktext'}
                                                hintText='Sold Reference'
                                                value={''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row> */}
                                    {/* <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Selling Price:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                id={'txt_customer_fktext'}
                                                hintText='Selling Price'
                                                value={this.props.state.data.retailvalue.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('retailvalue', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row> */}
                                    <Row><Col xl={9}></Col><Col xl={3}>
                                       < Button className={"global_button"} variant="contained"
                                            disabled={this.props.state.data.vehicle_id.input && this.props.state.data.disposaldate.input ? false : true}
                                            header={false}
                                            label={'Save Termination'}
                                            onClick={(event) => {
                                                this.props.actions.saveDisposal(this.props.state.data)
                                            }}
                                            >{window.t.en('Save Termination')}</Button>
                                    </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xl={8} style={styles.rightpanel}>
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Description:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        disabled={true}
                                                        id={'txt_customername'}
                                                        hintText='Description'
                                                        value={this.props.state.details.description ? this.props.state.details.description : ''}
                                                        onChange={(event, value) => {
                                                            //this.props.actions.setInputValue('customer_fktext', value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Type:'}</div>
                                                <div style={styles.fieldContent}><TextField
                                                    disabled={true}
                                                    id={'txt_customername'}
                                                    hintText='Vehicle Type'
                                                    value={this.props.state.details.category ? this.props.state.details.category : ''}
                                                    onChange={(event, value) => {
                                                        //this.props.actions.setInputValue('customer_fktext', value)
                                                    }}
                                                />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'M&M Code:'}</div>
                                                <div style={styles.fieldContent}><TextField
                                                    disabled={true}
                                                    id={'txt_customername'}
                                                    hintText={'M&M Code'}
                                                    value={this.props.state.details.mmcode ? this.props.state.details.mmcode : ''}
                                                    onChange={(event, value) => {
                                                        //this.props.actions.setInputValue('customer_fktext', value)
                                                    }}
                                                />
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle First Registration Date:'}</div>
                                                <div style={styles.fieldContent}><TextField
                                                    disabled={true}
                                                    id={'txt_customername'}
                                                    hintText='Vehicle First Registration Date'
                                                    value={this.props.state.details.registrationdate ? this.props.state.details.registrationdate : ''}
                                                    onChange={(event, value) => {
                                                        //this.props.actions.setInputValue('customer_fktext', value)
                                                    }}
                                                />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Year Model:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                disabled={true}
                                                id={'txt_customername'}
                                                hintText='Year Model'
                                                value={this.props.state.details.yearmodel ? this.props.state.details.yearmodel : ''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Age (Months):'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                disabled={true}
                                                id={'txt_customername'}
                                                hintText='Age'
                                                value={this.props.state.details.registrationdate ? (Number(moment(new Date()).format('YYYY')) - Number(moment(this.props.state.details.registrationdate).format('YYYY')))*12 : ''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Trade Value:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                disabled={true}
                                                id={'txt_customername'}
                                                hintText='Trade Value'
                                                value={this.props.state.details.tradevalue ? this.props.state.details.tradevalue : ''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Retail Value:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                disabled={true}
                                                id={'txt_customername'}
                                                hintText='Retail Value'
                                                value={this.props.state.details.retailvalue ? this.props.state.details.retailvalue : ''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Purchase Date:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                disabled={true}
                                                id={'txt_customername'}
                                                hintText='Purchase Date'
                                                value={this.props.state.details.purchasedate ? this.props.state.details.purchasedate : ''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Purchase Price:'}</div>
                                                <div style={styles.fieldContent}><TextField
                                                    disabled={true}
                                                    id={'txt_customername'}
                                                    hintText='Purchase Price'
                                                    value={this.props.state.details.purchaseprice ? this.props.state.details.purchaseprice : ''}
                                                    onChange={(event, value) => {
                                                        //this.props.actions.setInputValue('customer_fktext', value)
                                                    }}
                                                />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Finance Type:'}</div>

                                            <div style={styles.fieldContent}> <TextField
                                                disabled={true}
                                                id={'txt_customername'}
                                                hintText='Finance Type'
                                                value={this.props.state.details.financetype ? this.props.state.details.financetype : ''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                    </CardContent>
                </Card>
            </Tab>
        </Tabs>
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.disposal,
            details: {
                ...state.disposalcomponentview,
            },
            upload: {
                ...state.upload
            }, user: {
                ...state.user
            },

        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DISPOSAL)