export const title = ''
export const date_from = null
export const date_to = null
export const description = ''
export const display_type = ''
export const infosheet_key = ''
export const infoview = false
export const infoview_id = null
export const infoview_name = null
export const infoview_title = null
export const section_infosheet = false