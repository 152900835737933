import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import Drawer from '../../../controls/drawer'
import Card from '../../../controls/card'
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDisposalEdit extends Component {

    componentDidMount() {
        this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
        if(this.data) {
            //console.log('componentWillMount',this.data)
            this.props.actions.setSetinputField({prop: 'retailvalue', value: this.data.retailvalue})
            this.props.actions.setSetinputField({prop: 'tradevalue', value: this.data.tradevalue})
            this.props.actions.setSetinputField({prop: 'marketvalue', value: this.data.marketvalue})
        }
    }
    
        getViewForm() {     
            return  <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>{'VIN Number:'}</Col><Col xl={6}>{this.data.chassisnumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'MM Code:'}</Col><Col xl={6}>{this.data.mmcode.mmcode}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Registration Year:'}</Col><Col xl={6}>{moment(this.data.registrationdate).format('YYYY-MM-DD')}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}><hr /></Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Vehicle Registration:'}</Col><Col xl={6}>{this.data.licenseplateno}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Vehicle Description:'}</Col><Col xl={6}>{this.data.makemodelyear}</Col>
                    </Row>
                </Container>
        }
            
        getEditForm() {    
            return <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                                <b>{'Vehicle Retails Price:*'}</b>
                                    <TextField
                                        variant="outlined" 
                                        id={'txtretailvalue'}
                                        hintText={'Enter Retails Price'}
                                        fullWidth={true}
                                        value={this.props.state.retailvalue}
                                        onChange={(event, value) => {
                                            this.props.actions.setSetinputField({prop: 'retailvalue', value: event.target.value})
                                            this.props.actions.calcMarketVal()
                                        }}
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                                <b>{'Vehicle Trade Price:*'}</b>
                                    <TextField
                                        variant="outlined" 
                                        id={'txttradevalue'}
                                        hintText={'Enter Trade Amount'}
                                        fullWidth={true}
                                        value={this.props.state.tradevalue}
                                        onChange={(event, value) => {
                                            this.props.actions.setSetinputField({prop: 'tradevalue', value: event.target.value})
                                            this.props.actions.calcMarketVal()
                                        }}
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                                <b>{'Market Value:'}</b>
                                    <TextField
                                        variant="outlined" 
                                        id={'txtmarketvalue'}
                                        hintText={'Enter Market Value'}
                                        fullWidth={true}
                                        disabled={true}
                                        value={this.props.state.marketvalue}
                                    />
                        </Col>
                    </Row>
                </Container>
        }

        getView() {
            return <div> 
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    General Details
                    </div>
                    <p className="text-black-50">Vehicle details</p>
                </div>
                {this.getViewForm()}
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Vehicle Price
                    </div>
                    <p className="text-black-50">Vehicle price details</p>
                </div>
                {this.getEditForm()}
            </div>
        }

    getLayout() {
        return  <Drawer 
            onClose={(event, value) => {
                this.props.actions.toggleInfoSheet(null,'inforsheet_edit')
            }}
            open={this.props.state.inforsheet_edit}
            title={'Vehicle Price'}
            subtitle={'Capture vehilce pricing details'}
            content={this.getView()}
            buttons={<div>
                <Button variant='contained' className='global_button'
                     label="Update Details"
                     primary={true}
                     onClick={(event) => {
                         event.preventDefault()
                         this.props.actions.updateVehiclePrice(this.data.vehicle_id)
                     }}
                 >
                 {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {window.t.en('Update Details')}</Button>
            </div>}
        />
        // return <Drawer
        //         width={800}
        //         anchor={'right'}
        //         openSecondary={true}
        //         open={this.props.state.inforsheet_edit}
        //         onRequestChange={(event) => {
        //             event.preventDefault()
        //             this.props.actions.toggleInfoSheet(null,'inforsheet_edit')
        //         }}>
        //         {
        //             this.getView()
        //         }
        //     </Drawer>
    }

    render() {
        this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalEdit)