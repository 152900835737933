import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
//import * as lodash from 'lodash'
//import validator from '../../validate'

export const resetForm = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.MM_COMPONENT_RESET_FORM
        })
    }
}

export const getMMLookupValueData = (component, field, desc, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                field: field,
                component: component,
                description: desc,
                query: field + '=' + search,
            }
        })
    }
}

export const setMMLookupValueData = (component, field, description, payload) => {
    return (dispatch, getState) => {

        //console.log('setLookupValueDatax ....',payload.data)
        const data = payload.data.map(x => {
            return {
                ...x,
                value: x[component + '_id'],
                text:  x[field] //+ ' (' + x[description] + ')'
            }
        })

        //console.log('setLookupValueDatax data',data)
        dispatch({
            type: types.SET_MM_COMPONENT_LOOKUP_DATA_VALUES,
            payload: {
                prop: component + '_data',
                value: data,
            }
        })
    }
}

export const setMMLookupValue = (field, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: field + '_id',
                value: value,
            }
        })

        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: text,
            }
        })
    }
}

export const setEndDate = (field, value) => {

    return (dispatch, getState) => {
        let period = getState().createMMQuote.period.value
        let enddate = moment(value).add(period, 'months').format('YYYY-MM-DD');
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: enddate
            }
        })
    }
}



export const setMMInputValue = (field, value) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: value
            }
        })
    }
}


export const setMMIItemnputValue = (i, value) => {


    return (dispatch, getState) => {
        const component = getState().createMMQuote
      
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_ITEM_INPUT_VALUE,
            payload: {
                value: value,
                component: component,
                index: i
            }
        })
    }
}

export const setAccItemInputValue = (inx, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_ACCESSORY_VALUE,
            payload: {
                inx: inx,
                value: value
            }
        })
    }
}

export const decimalFormat = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: parseInt(value).toLocaleString()
            }
        })
    }
}


export const loadServiceData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_MM_SERVICES_DATA_LIST,
            payload: {
                query: 'Tracking,Administration Fee,VSC MM Tracking'
            }
        })
    }
}

export const setServiceData = (payload) => {
    return (dispatch, getState) => {

        window.glyco.log('setServiceData')
        window.glyco.log(payload)
        dispatch({
            type: types.SET_MM_COMPONENT_LOOKUP_DATA_VALUES,
            payload: {
                prop: 'mm_service_data',
                value: payload,
            }
        })
    }
}

export const setSelectedService = (service, checked) => {
    //console.log('addService service', service)
    //console.log('addService checked', checked)
    return (dispatch, getState) => {
        let services = getState().createMMQuote.mm_service_data.map(x => {
                return service.service_id == x.service_id ? {...x, checked: checked} : x
        })

        dispatch({
            type: types.SET_MM_COMPONENT_LOOKUP_DATA_VALUES,
            payload: {
                prop: 'mm_service_data',
                value: services,
            }
        })
    }
}

export const setSelectedPrice = (service, sellingprice) => {
    //console.log('addService service', service)
    //console.log('addService sellingprice', sellingprice)
    return (dispatch, getState) => {
        let services = getState().createMMQuote.mm_service_data.map(x => {
                return service.service_id == x.service_id ? {...x, sellingprice: sellingprice} : x
        })
        
        dispatch({
            type: types.SET_MM_COMPONENT_LOOKUP_DATA_VALUES,
            payload: {
                prop: 'mm_service_data',
                value: services,
            }
        })
    }
}

export const calTotal = () => {
    return (dispatch, getState) => {
        const services =  getState().createMMQuote.mm_service_data
        //console.log('calTotal')
        //console.log('calTotal services',services)
        //const tracking_tot = !data.length ? 0 : _.sumBy(data.filter(x => x.servicedescription.indexOf('Tracking') !== -1), (d) => { return parseFloat((d.sellingprice.toString().replace(/,/g, '')) ? d.sellingprice.toString().replace(/,/g, '') : 0) })//..toFixed(2)
        const service_tot = !services.length ? 0 : _.sumBy(services.filter(x => x.checked), (d) => { return parseFloat((d.sellingprice.toString().replace(/,/g, '')) ? d.sellingprice.toString().replace(/,/g, '') : 0) })//..toFixed(2)
                
        //console.log('calTotal service_tot',service_tot)
        const monthly_excl  = parseFloat(service_tot ? service_tot : 0)
        const monthly_vat  = (parseFloat(monthly_excl) * 0.15)
        const monthly_incl  = (parseFloat(monthly_excl) * 1.15)

        //console.log('calTotal monthly_incl',monthly_incl)
        // dispatch({
        //     type: types.SET_MM_COMPONENT_LOOKUP_DATA_VALUES,
        //     payload: {
        //         prop: 'mm_service_selected',
        //         value: data,
        //     }
        // })

        // dispatch({
        //     type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
        //     payload: {
        //         prop: 'tracking',
        //         value: tracking_tot
        //     }
        // })

        // dispatch({
        //     type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
        //     payload: {
        //         prop: 'service',
        //         value: service_tot
        //     }
        // })
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'monthly_excl',
                value: monthly_excl
            }
        })
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'monthly_vat',
                value: monthly_vat
            }
        })
        dispatch({
            type: types.SET_MM_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: 'monthly_incl',
                value: monthly_incl
            }
        })
    }
}