import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, Dialog, CardHeader } from '@mui/material'

import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'

//import TextField from '@mui/material/TextField'
import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import PaymentItem from '../PaymentItem/component'
import * as styles from './styles'
import * as actions from './actions'

import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

class PurchaseOrderCapture extends Component {


    componentDidMount() {
        this.props.actions.setValidationState('purchaseOrderCapture')
        if (this.props.mode === "confirm") {

            let obj = this.props.state
            const meta = [
                {
                    header: true,
                    title: 'Supplier',
                    description: 'Supplier',
                    meta_data: this.props.state.fields.supplier_fktext.value
                },
                {
                    header: true,
                    title: 'Reference',
                    description: 'Reference',
                    meta_data: this.props.state.fields.reference.value
                },
                {
                    header: true,
                    title: 'Description',
                    description: 'Description',
                    meta_data: this.props.state.fields.description.value
                },
            ]
            this.props.actions.setupWorkflowTaskInput(obj, meta, 'creditors_invoice', 'tsk_creditors_invoice_purchase_order')
        }
        else {
            this.props.actions.resetComponent()
            this.props.actions.getPreAuthNumber()
        }
    }

    getDuplicateData(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Auth No</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Odometer</th>
                                    <th>Task No</th>
                                    <th>Booked By</th>
                                    <th>Booked Date</th>
                                    <th>Supplier</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map(x => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.authnumber}</td>
                                            <td>{x.maintenancetype_fktext}</td>
                                            <td>{x.comments}</td>
                                            <td>{x.odometer}</td>
                                            <td>{x.tasknumber}</td>
                                            <td>{x.bookedby}</td>
                                            <td>{x.bookeddate}</td>
                                            <td>{x.supplier_fktext}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={4} >
                    <AutoComplete
                        name={'autocomplete_purchase_order_'}
                        title={window.t.en('Select supplier')}
                        info={{
                            show: false
                        }}
                        label={'Select Supplier'}
                        fullWidth={true}
                        fieldvalue={'supplier_id'}
                        displayfields={['suppliername', 'accountnumber', 'suppliertype_fktext']}
                        displaywidth={['100', '50', '50']}
                        data={this.props.state.suppliers}
                        value={this.props.state.fields.supplier_fktext.value ?? ''}
                        onSelect={(args) => {
                            this.props.actions.setInputValue('supplier_id', args.value)
                            this.props.actions.setInputValue('supplier_fktext', args.text)
                        }}
                        onClick={() => {
                            this.props.actions.setInputValue('supplier_id', '')
                            this.props.actions.setInputValue('supplier_fktext', '')
                            this.props.actions.getSupplierAutoCompleteMenuItems('')
                        }}
                        onFilter={(search) => {
                            if (search) {
                                const data = this.props.state.suppliers
                                const supplier = data.find(s => s.suppliername === search)
                                if (supplier){
                                    this.props.actions.getSupplierAutoCompleteMenuItems(search)
                                    this.props.actions.setInputValue('supplier_id', supplier.supplier_id)
                                    this.props.actions.setInputValue('supplier_fktext', search)
                                }
                            } else {
                                this.props.actions.getSupplierAutoCompleteMenuItems('')
                                this.props.actions.setInputValue('supplier_id', '')
                                this.props.actions.setInputValue('supplier_fktext', '')
                            }
                        }}
                    />
                </Col>
                <Col xl={4} >
                    <div style={{ marginLeft: '10px', marginTop: '-5px' }}>
                        <DateControl
                            id={'date'}
                            key={'date'}
                            label={'Enter Purchase Order Date'}
                            name={'date'}
                            error={this.props.state.fields.date.value ? false : true}
                            helperText={this.props.state.fields.date.value ? '' : 'Enter incident date'}
                            value={this.props.state.fields.date.value}
                            onSelect={(args) => {
                                this.props.actions.setInputValue('date', args)
                            }}
                            type={'date'}
                        />
                    </div>
                </Col>
                <Col xl={4}  >
                    <div style={{ marginLeft: '10px', marginTop: '20px' }}>
                        <TextField variant="outlined"
                            id={'reference'}
                            key={'poc_reference'}
                            label={'Enter Reference'}
                            fullWidth={true}
                            multiLine={false}
                            error={this.props.state.fields.reference.value ? false : true}
                            helperText={this.props.state.fields.reference.value ? '' : 'Enter reference/ invoice number'}
                            value={this.props.state.fields.reference.value}
                            onChange={(event, value) => {
                                this.props.actions.setInputValue('reference', event.target.value)
                            }}
                        />
                    </div>
                </Col>
                <Col xl={4}>
                    <div style={{ marginLeft: '0px', marginTop: '-10px' }}>
                        <TextField variant="outlined"
                            id={'description'}
                            key={'poc_description'}
                            label={'Enter description'}
                            fullWidth={true}
                            multiLine={false}
                            error={this.props.state.fields.description.value ? false : true}
                            helperText={this.props.state.fields.description.value ? '' : 'Enter description'}
                            value={this.props.state.fields.description.value}
                            onChange={(event, value) => {
                                this.props.actions.setInputValue('description', event.target.value)
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    }

    viewForm() {
        return <div>

            {
                this.props.state.fields.supplier_id.value && <ViewSupplier
                    min={true}
                    key={this.props.state.fields.supplier_id.value}
                    id={this.props.state.fields.supplier_id.value}
                />
            }

            <Container style={{ margin: 0 }} className="custom-container">
                <Row >
                    <Col xl={12} >
                        <CardHeader style={{ backgroundColor: '#ff4545', color: 'white' }}
                            actAsExpander={false}
                            showExpandableButton={false}
                            title={<div style={{ fontSize: "18px", color: "white" }}>{window.t.en('Invoice Details')} </div>}
                            subheader={<div style={{ fontSize: "13px", color: "white" }}>{window.t.en('Capture supplier details')}</div>}>
                        </CardHeader>
                        <Fields labelsize='130'

                            fields={[
                                { size: 4, label: 'Supplier:', value: this.props.state.fields.supplier_fktext.value },
                                { size: 4, label: 'Date', value: this.props.state.fields.date.value },
                                //{size: 12, label: 'Month:', value: this.props.state.fields.month.value},
                                { size: 4, label: 'Reference', value: this.props.state.fields.reference.value },
                                { size: 4, label: 'Description', value: this.props.state.fields.description.value },
                            ]}
                        />
                    </Col>
                    <Col xl={12} style={{ textAlign: 'left' }}></Col>
                </Row>
                <Row>
                    <Col xl={12}></Col>
                </Row>
            </Container>
        </div>
    }

    getView() {
        return <div>
            <Card
                title={'Purchase Order'}
                subtitle={'Purchase Order internal reference ' + this.props.state.preauth}
                content={this.getForm()}
            />
            {
                this.props.state.fields.supplier_id.value && <ViewSupplier
                    min={true}
                    key={this.props.state.fields.supplier_id.value}
                    id={this.props.state.fields.supplier_id.value}
                />
            }
            <Card
                title={'Purchase Order Item'}
                subtitle={'Purchase order line items'}
                content={<PaymentItem />}
            />
            <Card
                title={'Purchase Order Document'}
                subtitle={'Purchase order supporting document: '}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'Creditor_Attach_'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                    </Container>}
            />
        </div>
    }

    getConfirm() {
        //console.log('this.props.state.upload.documents', this.props.state.upload.documents)
        return <div>
            <Card
                title={'Purchase Order'}
                subtitle={'Purchase order internal reference ' + this.props.state.preauth}
                content={this.viewForm()}
            />
            <Card
                title={'Purchase Order Item'}
                subtitle={'Capture invoice line items'}
                content={<PaymentItem read_only={true} mode={this.props.mode} />}
            />
            <Card
                title={'Uploaded Document'}
                subtitle={'Upload creditor payment supporting document: '}
                content={<PdfViewer
                    key={'document_viewer_uploaded_file'}
                    name={'tsk_billing_run_start'}
                    workflow={'billing_run'}
                    task={'tsk_billing_run_start'}
                    documents={this.props.state.upload.documents}
                    edit={false} />}
            />
        </div>
    }

    getLayout() {
        return this.props.mode === "confirm" ? this.getConfirm() : this.getView()
    }

    getErrorLayout() {
        return <Card
            title={'Error'}
            subtitle={'Connection did not respond  '}
            content={<div>
                <h4>Please check following:</h4>
                <ul>
                    <li>Please ensure that the engine is connected</li>
                    <li>Please ensure that you do not log in twice</li>
                </ul>
                <i>Alternatively, you can contact the system administrator for further assistance.</i>
            </div>}
        />
    }

    render() {
        return (this.props.state.preauth ? this.getLayout() : this.getErrorLayout())
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.purchaseOrderCapture,
            upload: {
                ...state.upload
            },
            items: {
                ...state.paymentItem
            }
        }
    }
}


const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;
    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderCapture)
