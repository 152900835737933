import moment from 'moment'

import * as types from './types'
import * as appTypes from '../../App/types'

export const reset = () => {
    return (dispatch) => {       
        dispatch({
            type: types.RESET_COMPONENT,
        })
    }
}

export const setValue = (prop, val) => {
    //console.log(prop,val)
    return (dispatch) => {       
        dispatch({
            type: types.SET_STATEMENT_REVIEW_INPUT_VALUE,
            payload: {prop:prop,val:val}
        })
    }
}

export const getStatementData = () => {
    return (dispatch) => {    
        dispatch({
            type: types.GET_STATEMENT_PREVIEW_DATA,
            payload: {
                collection: 'statement_preview_data',
                searchText: ''
            }
        })
    }
}

export const sendStatement = (pin) => {
    return (dispatch) => {    
        dispatch({
            type: types.QUEUE_STATEMENT_SEND_OUT,
            payload: {
                type: 'QUEUE_STATEMENT_SEND_OUT',
                pin: pin
            }
        })
    }
}
export const statementsQueued = () => {
    return (dispatch) => {    
        
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'Statement Queued',
                additionalText: 'Statement are going out',
                overflowText: 'Statement are going out',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const getStatement = (id,email) => {
    return (dispatch) => {    
        dispatch({
            type: types.GET_STATEMENT_PREVIEW,
            payload: {
                type: 'GET_STATEMENT_PREVIEW',
                email: email,
                id: id
            }
        }) 
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating statement preview',
                overflowText: 'Generating statement preview',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}


export const ExportStatementData = () => {
    return (dispatch, getState) => {       
        
        var data = getState().statementPreview.data
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Preparing statements export',
                overflowText: 'Preparing statements export',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.EXPORT_STATEMENT_PREVIEW_DATA,
            payload: {
                type: 'EXPORT_STATEMENT_PREVIEW_DATA',
                data: data
            }
        })
    }
}
export const setStatementData = (payload) => {
    //console.log('setStatementData >>>>>>>>>>>>>>>>>',payload)
    return (dispatch) => {       
        dispatch({
            type: types.SET_STATEMENT_PREVIEW_DATA,
            payload: payload.data
        })
    }
}

export const downloadStatement = (data) => {
    return (dispatch, getState) => {   
        

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf], data.data.key)


        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Downloading statements export',
                overflowText: 'Downloading statements export',
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const previewStatement = (data) => {
    return (dispatch, getState) => {   
        

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    for(let x of data.data.document){
        saveByteArray([x.body], x.filename)
    }

    if(data.data.document.length)
    dispatch({
        type: appTypes.SHOW_NOTIFICATION,
        payload: {
            title: 'System Notification',
            additionalText: 'Downloading statement preview',
            overflowText: 'Downloading statement preview',
            autoHide: 10000,
            timestamp: moment().format('h:mm A')
        }
    })
    else
    dispatch({
        type: appTypes.SHOW_NOTIFICATION,
        payload: {
            title: 'System Notification',
            additionalText: 'Statement preview emailed to you',
            overflowText: 'Statement preview emailed to you',
            autoHide: 10000,
            timestamp: moment().format('h:mm A')
        }
    })
    }
}