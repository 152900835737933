import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent, Table, IconButton } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import ReactQuill from 'react-quill'

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import JSReport from '../components/JSReport/component'
import MinMap from '../components/MinMap/component'


import TextField from './textField'
import Checkbox  from '@mui/material/Checkbox';

import CardControl from './card'

import * as actions from './actions'
import * as styles from './styles'
import * as config from '../config'
import { service_only } from './props'

class ViewVehicle extends Component {
        componentWillMount() {
            //console.log('getComponentVehicleDate >>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
            this.props.actions.getComponentVehicleDate(this.props.id)
        }
    
        getField(value, type) {
            try {
                switch (type) {
                    case 'date':
                        return value !== null
                            ? moment(value).format('YYYY-MM-DD')
                            : ''
                    case 'bool':
                        return value === true ? 'Yes' : 'No'
                    case 'noy':
                        return value == '1' ? 'Yes' : value == '0' ? 'Optional' : 'No'
                    case 'datetime':
                        return value !== null ? moment(value).format('YYYY-MM-DD HH:mm') : ''
                    case 'time':
                        return value !== null
                            ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                            : ''
                    case 'html':
                        return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
                    default:
                        return value
                }
            }
            catch (err) {
                return value
            }
        }
        getLabel(label, value, type = '') {
            return <FormControlLabel
                fullWidth={true}
                control={
                    <Typography variant="body1" component="div"
                        style={{  fontWeight: 'bold',fontSize: '14px' }}>
                        {this.getField(value, type)}
                    </Typography>
                }
                label={
                    <label style={{ width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                        {window.t.en(label)}:
                    </label>
                }
                labelPlacement="start"
            />
        }
    
        currency(value) {
            return parseFloat((value ? value : 0.00).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
    
        number(value) {
            return parseInt(value ? value : 0)
        }
    
        burnRate(contract) {
            let data = null
    
            try {
                data = contract.customercontractderivative[0]
            }
            catch (err) { }
            return data
                ?
                <div>
                    <Row>
                        <Col xl={12} style={styles.title}>
                            {'Maintenance Fund'}
                        </Col>
                        <Col xl={4}>{this.getLabel('Fund Income', this.currency(data.maintenanceincome))}</Col>
                        <Col xl={4}>{this.getLabel('Fund Expense', this.currency(data.maintenanceexpenditure))}</Col>
                        <Col xl={4}>{this.getLabel('Monthly Income', this.currency(data.maintenancemonthlyselling))}</Col>
                        <Col xl={4}>{this.getLabel('Fund Balance', this.currency(data.maintenancefundbalance))}</Col>
                        <Col xl={4}>{this.getLabel('Excess Debit', this.currency(data.excess_debit))}</Col>
                        <Col xl={4}>{this.getLabel('Excess Credit', this.currency(data.excess_credit))}</Col>
                        <Col xl={4}>{this.getLabel('Take On Excess', this.currency(data.fd_takeon_excess))}</Col>
                        <Col xl={12} style={styles.title}>
                            {'Tyre Fund & Usage'}
                        </Col>
                        <Col xl={4}>{this.getLabel('Fund Income', this.currency(data.tyresincome))}</Col>
                        <Col xl={4}>{this.getLabel('Fund Expense', this.currency(data.tyresexpenditure))}</Col>
                        <Col xl={4}>{this.getLabel('Monthly Income', this.currency(data.tyresmonthlyselling))}</Col>
                        <Col xl={4}>{this.getLabel('Fund Balance', this.currency(data.tyresfundbalance))}</Col>
                        <Col xl={4}>{this.getLabel('Tyres Allocated', this.number(data.tyrequantityfront)+this.number(data.tyrequantityrear))}</Col>
                        <Col xl={4}>{this.getLabel('Used (Not Rebilled)', this.number(data.tyresused))}</Col>
                        <Col xl={4}>{this.getLabel('Tyres Balance', (this.number(data.tyrequantityfront)+this.number(data.tyrequantityrear)) - this.number(data.tyresused))}</Col>
                        <Col xl={4}>{this.getLabel('Tyre Rebilled', this.number(data.rebilledtyresused))}</Col>
                        <Col xl={12} style={styles.title}>
                            {'Total Fund Balance'}
                        </Col>
                        <Col xl={4}>{this.getLabel('Fund Income', this.currency(data.fundincome))}</Col>
                        <Col xl={4}>{this.getLabel('Fund Expense', this.currency(data.totalexpenditure))}</Col>
                        <Col xl={4}>{this.getLabel('Fund Balance', this.currency(data.fundbalanceexcludingexcess))}</Col>
                        <Col xl={4}>{this.getLabel('Excess Debit', this.currency(data.excess_debit))}</Col>
                        <Col xl={4}>{this.getLabel('Excess Credit', this.currency(data.excess_credit))}</Col>
                        <Col xl={4}>{this.getLabel('Take On Excess', this.currency(data.fd_takeon_excess))}</Col>
                        <Col xl={4}><b>{this.getLabel('Total Fund Balance', this.currency(data.fundbalanceincludingexcess))}</b></Col>
                        <Col xl={12}>{''}</Col>
                        <Col xl={4}>{this.getLabel('OOCP Penalty', this.currency(data.oocp_penalty))}</Col>
                        <Col xl={4}>{this.getLabel('Capital Equalisation', this.currency(data.capital_equalisation))}</Col>
                        <Col xl={4}>{this.getLabel('Final Capital Equalisation', this.currency(data.total_capital_equalisation))}</Col>
                    </Row>
                </div>
                : ''
        }
    
        getVehicleView() {
            //console.log('this.data getVehicleView >>>>>>>>>>>>>>',this.data)
            //console.log('this.data getVehicleView >>>>>>>>>>>>>>',this.data.customercontract)
            let customercontract = this.data.customercontract ? this.data.customercontract.filter(x => x.activated == true) : []
            customercontract = customercontract.length ? customercontract[0] : {}
            return <div>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={8}>
                                    {
                                        this.data.mmcode
                                            ? <Container style={{ margin: 0 }} className="custom-container">
                                                <Row className={"custom_view_text"}>
                                                    <Col xl={6}>
                                                        {this.getLabel('Business Unit', this.data.customer.businessunit)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Cost Centre', this.data.customer.costcentre)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Registration', this.data.licenseplateno)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Fleet No', this.data.fleetno)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Model', this.data.mmcode.model)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Make', this.data.mmcode.make)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('MMCode', this.data.mmcode.mmcode)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Model Code', this.data.mmcode.modelcode)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Chassis Number', this.data.chassisnumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Engine Number', this.data.enginenumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Takeon Odometer', this.data.takeonodo)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Odometer', this.data.closingodometer)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Last Service Date', this.data.lastservicedate, 'date')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Last Service Odometer', this.data.lastserviceodometer)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Next Service Date', this.data.nextservicedate, 'date')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Next Service Odometer', this.data.nextserviceodometer)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Last Tyre Date', this.data.tyresfitteddate, 'date')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Last Tyre Odometer', this.data.tyrefittedodometer)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Status', this.data.vehiclestatus ? this.data.vehiclestatus.vehiclestatus : '')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Sub Status', this.data.statuscategory ? this.data.statuscategory.statuscategory : '')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('First Registration Date', this.data.registrationdate, 'date')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('License Expiry Date', this.data.licenceexpirydate, 'date')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Introduction Date', this.data.mmcode.introdate)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Discontinue Date', this.data.mmcode.discdate)}
                                                    </Col>
    
                                                    <Col xl={12} style={styles.title}>
                                                        {'Customer Details'}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Customer Name', this.data.customer.customername)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Cost Centre', this.data.customer.costcentre)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Registration Number', this.data.customer.compregistrationnumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Account Number', this.data.customer.accountnumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Maintenance Number', this.data.customer.vatnumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('VAT Type', this.data.customer.taxnumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Credit Exposure', this.data.customer.creditexposure)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Facility Utilised', this.data.customer.facilityutilised)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Suspended', this.data.customer.customersuspended ? 'Yes' : 'No')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Status', this.data.customer.status)}
                                                    </Col>

                                                    <Col xl={12} style={styles.title}>
                                                        {'Contract Details'}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract Number', customercontract.contractnumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract Type', customercontract.contracttype_fktext)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Distance Per Month', customercontract.distancepermonth)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract Period', customercontract.period)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Start date', customercontract.startdate ? moment(customercontract.startdate).format('YYYY-MM-DD') : '')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('End Date', customercontract.terminationdate ? moment(customercontract.terminationdate).format('YYYY-MM-DD') : '')}
                                                    </Col>
                                                    

                                                    <Col xl={12} style={styles.title}>
                                                        {'Maintenance, Service and Warranty Details'}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Maintenance Type', this.data.mmcode.smplan)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Service (Month)', this.data.mmcode.service_mnth)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Maintenance (Month)', this.data.mmcode.serviceplanperiod)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Maintenance (KM)', this.data.mmcode.serviceplandistance)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Service Interval 1', this.data.mmcode.serviceinterval)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Service Interval 2', this.data.mmcode.serviceinterval2)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Warranty (Month)', this.data.mmcode.warrantyplan)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Warranty Distance', this.data.mmcode.warrantykm)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Warranty', this.data.mmcode.warranty)}
                                                    </Col>
                                                    
                                                    <Col xl={12} style={styles.title}>
                                                        {'Recent Authorisation'}
                                                    </Col>
    {/* 
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Registration', this.data.licenseplateno)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Make', this.data.makemodelyear)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Fleet No', this.data.fleetno)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('First Registration Date', this.data.registrationdate, 'date')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Chassis Number', this.data.chassisnumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Engine Number', this.data.enginenumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Business Unit', this.data.customer.businessunit)}
                                                    </Col>
                                                    <Col xl={12} style={styles.title}>
                                                        {'Other Details'}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Odometer', this.data.closingodometer)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Takeon Odometer', this.data.takeonodo)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Status', this.data.vehiclestatus ? this.data.vehiclestatus.vehiclestatus : '')} 
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Status', this.data.vehicletype ? this.data.vehicletype.vehicletype : '')} 
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Funder', this?.data?.financialinstitution?.fundername)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('License Expiry Date', this.data.licenceexpirydate, 'date')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Fuel Type', this.data?.fueltype?.fueltype)} 
                                                    </Col>
                                                    <Col xl={12} style={styles.title}>
                                                        {'Contract Details'}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract Number', this?.customercontract?.contractnumber)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract Status', this?.customercontract?.contractstatus?.contractstatus)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract Start', this?.customercontract?.startdate, 'date')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract End', this?.customercontract?.terminationdate, 'date')}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract Type', this?.customercontract?.contracttype)} 
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract Customer', this?.data.customer?.customername)} 
                                                    </Col>
    
                                                    <Col xl={6}>
                                                        {this.getLabel('Contract Period', this?.customercontract?.period)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Distance Per Month', this?.customercontract?.distancepermonth)}
                                                    </Col>
    
                                                    <Col xl={6}>
                                                        {this.getLabel('Biiling Count', this?.customercontract?.customercontractderivative[0]?.billingcount)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Remaining Billing Count', this?.customercontract?.customercontractderivative[0]?.remainingbillingcount)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Tyre Quantity Front', this?.customercontract?.customercontractderivative[0]?.tyrequantityfront)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Tyre Quantity Rear', this?.customercontract?.customercontractderivative[0]?.tyrequantityrear)}
                                                    </Col>
                                                    <Col xl={12} style={styles.title}>
                                                        {'MM Code Details'}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('MM Code', this.data.mmcode.mmcode)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Transmission', this.data.mmcode.manualauto)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Body Type', this.data.mmcode.bodytype)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Discontinue Date', this.data.mmcode.discdate)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Rate Category', this.data.mmcode.sapvehiclecat)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Vehicle Category', this?.data.mmcode?.vehiclecategory?.vehiclecategory)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Tyre Size Front', this.data.mmcode.fronttyresize)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Tyre Size Rear', this.data.mmcode.reartyresize)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Tyre Life Span Front', this.data.mmcode.tyrelifefront)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Tyre Life Span Rear', this.data.mmcode.tyreliferear)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Warranty Km', this.data.mmcode.warrantykm)}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.getLabel('Service Interval', this.data.mmcode.serviceinterval)}
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                            : ''
                                    }
                                </Col>
                                <Col xl={4}>
                                    <img style={styles.vehicle} src={config.system.cdn + '/' + (this.data.mmcode ? this.data.mmcode ? this.data.mmcode.imagefilename : 'diskdrive/nisamr003.png' : 'diskdrive/nisamr003.png')} />
                                    <hr />
                                    <MinMap key={this.props.id} desc={this.data.licenseplateno} />
                                </Col>
                            </Row>
                            <Row>
                                
                            <Col xl={12}>
                                                <div className="table-responsive-md">
                                                    <Table className="table table-hover text-nowrap mb-0" style={{width: '100% !impoertant'}}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width: '20% !impoertant'}}>Description</th>
                                                                <th style={{width: '10% !impoertant'}}>Supplier</th>
                                                                <th style={{width: '10% !impoertant'}}>Created By</th>
                                                                <th style={{width: '10% !impoertant'}}>Auth </th>
                                                                <th style={{width: '10% !impoertant'}}>Auth Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.data.maintenancetransaction.filter(x => x.authnumber).splice(0, 3).map(x => {
                                                                    return <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                                        <td>{x.maintenancetype} <br/>{x.maintdescription}</td>
                                                                        <td>{x.supplier ? x.supplier.suppliername : ''}</td>
                                                                        <td>{x.bookedby} <br />{this.getField(x.created_at, 'datetime')}</td>
                                                                        <td><b>{x.authnumber}</b> <br /> {this.getField(x.authdate, 'datetime')}</td>
                                                                        <td>{parseFloat((x.totalamount ? x.totalamount : 0.00).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                <Col xl={12} style={styles.title}>
                                    {'Fuel Information'}
                                </Col>
                                <Col xl={4}>{this.getLabel('Fuel Type', this.data.mmcode.fueltype)}</Col>
                                <Col xl={4}>{this.getLabel('Fuel Average OEM', this.currency(this.data.mmcode.fuelavg))}</Col>			
                                <Col xl={4}>{this.getLabel('Fuel Average OEM Urban', this.currency(this.data.mmcode.fuelurban))}</Col>
                                <Col xl={4}>{this.getLabel('Fuel Average OEM Highway', this.currency(this.data.mmcode.fuelhway))}</Col>
                            </Row>
                            <Row>
                                <Col xl={12} style={styles.title}>
                                    {'Category And Engine Details'}
                                </Col>
                                <Col xl={4}>{this.getLabel('Vehicle Category', this.data.mmcode.bodytype)}</Col>
                                <Col xl={4}>{this.getLabel('Vehicle Class', this.data.mmcode.mmvehicleclass)}</Col>
                                <Col xl={4}>{this.getLabel('Engine Type', this.data.mmcode.enginetype)}</Col>
                                <Col xl={4}>{this.getLabel('Engine Capacity', this.data.mmcode.cubiccapacity)}</Col>
                                <Col xl={4}>{this.getLabel('Ignition Type', this.data.mmcode.ignition)}</Col>
                                <Col xl={4}>{this.getLabel('Transmission', this.data.mmcode.manualauto)}</Col>
                                <Col xl={4}>{this.getLabel('Gearbox	', this.data.mmcode.nogears)}</Col>
                                <Col xl={4}>{this.getLabel('Hybrid', this.data.mmcode.hybrid, 'noy')}</Col>
                                <Col xl={12} style={styles.title}>
                                    {'Tyre, Rims and Break System'}
                                </Col>
                                <Col xl={4}>{this.getLabel('Front Tyre Size', this.data.mmcode.fronttyresize)}</Col>
                                <Col xl={4}>{this.getLabel('Rear Tyre Size', this.data.mmcode.reartyresize)}</Col>
                                <Col xl={4}>{this.getLabel('Front Tyre Life Span', this.data.mmcode.tyrelifefront)}</Col>
                                <Col xl={4}>{this.getLabel('Rear Tyre Life Span', this.data.mmcode.tyreliferear)}</Col>
                                <Col xl={4}>{this.getLabel('Front Rim Type', this.data.mmcode.rimfront)}</Col>
                                <Col xl={4}>{this.getLabel('Rear Rim Type', this.data.mmcode.rimrear)}</Col>
                                <Col xl={4}>{this.getLabel('Front Brake', this.data.mmcode.braketypefront)}</Col>
                                <Col xl={4}>{this.getLabel('Rear Brake', this.data.mmcode.braketyperear)}</Col>
                                <Col xl={4}>{this.getLabel('Spare Size', this.data.mmcode.sparesize)}</Col>
                                <Col xl={4}>{this.getLabel('Run Flat', this.data.mmcode.runflat, 'noy')}</Col>
                                <Col xl={12} style={styles.title}>
                                    {'Dimensions'}
                                </Col>
                                <Col xl={4}>{this.getLabel('Vehicle Lenght', this.data.mmcode.vlength)}</Col>
                                <Col xl={4}>{this.getLabel('Vehicle Width', this.data.mmcode.vwidth)}</Col>
                                <Col xl={4}>{this.getLabel('Vehicle Height', this.data.mmcode.vheight)}</Col>
                                <Col xl={4}>{this.getLabel('Ride Height', this.data.mmcode.rem, 'noy')}</Col>
                                <Col xl={4}>{this.getLabel('Vehicle Mass', this.data.mmcode.gcm)}</Col>
                                <Col xl={4}>{this.getLabel('Vehicle Gross', this.data.mmcode.gvm)}</Col>
                            </Row>
                            
                            <Row>
                                <Col xl={12}>
                                </Col>
                            </Row>
                            {
                                this.maintenancetransaction.length
                                    ? <div>
                                        <Row>
                                            <Col xl={12} style={styles.title}>
                                                {'Vehicle Open Maintenance Bookings or Authorisations'}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <div className="table-responsive-md">
                                                    <Table className="table table-hover text-nowrap mb-0" style={{width: '100% !impoertant'}}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width: '20% !impoertant'}}>Description</th>
                                                                <th style={{width: '10% !impoertant'}}>Supplier</th>
                                                                <th style={{width: '10% !impoertant'}}>Created By</th>
                                                                <th style={{width: '10% !impoertant'}}>Auth </th>
                                                                <th style={{width: '10% !impoertant'}}>Auth Amount</th>
                                                                <th style={{width: '10% !impoertant'}}>Task No</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.maintenancetransaction.map(x => {
                                                                    return <tr style={{fontWeight: 'normal',fontSize: '14px'}}>
                                                                        <td>{x.maintenancetype} <br/>{x.maintdescription}</td>
                                                                        <td>{x.suppliername}</td>
                                                                        <td>{x.bookedby} <br />{this.getField(x.created_at, 'datetime')}</td>
                                                                        <td>{x.authnumber} <br /> {this.getField(x.authdate, 'datetime')}</td>
                                                                        <td>{parseFloat((x.totalamount ? x.totalamount : 0.00).toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                                        <td>{x.tasknumber}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    : ''
                            }
                            {this.burnRate(this.customercontract)}
                            <Row>
                                <Col xl={12} style={styles.title}>
                                    {'Vehicle Maintenance History'}
                                    <IconButton touch={true} style={{float: 'right'}}
                                    onClick={() => {
                                        this.props.actions.toggleVehicleHistory(this.props.state.showhistory)
                                    }}>
                                        {this.props.state.showhistory ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox  
                                                checked={this.props.state.service_only} 
                                                onChange={(event) => {
                                                    this.props.actions.toggleVehicleServiceHistory(event.target.checked)
                                                }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}/>
                                        }
                                        label={"Service History Only "}
                                    />
                                </Col>
                            </Row>
                            {
                            
                                this.props.state.showhistory && this.data
                                ? <Row>
                                    <Col xl={12}>
                                        {
                                            this.props.state.service_only
                                            ?  <JSReport id={this.props.id + '_service'} key={this.props.id + '_service'} shortid={'HyLkl2xod'} name={'Maintenance_View_History'} data={{...this.data, service_only: this.props.state.service_only, user: this.props.state.user }} />
                                            :  <JSReport id={this.props.id + '_history'} key={this.props.id + '_history'} shortid={'H1mAv1uJd'} name={'Maintenance_View_Service'} data={{...this.data, service_only: this.props.state.service_only, user: this.props.state.user }} />
                                        }
                                    </Col>
                                    </Row>
                                : ''
                            }
                        </Container>
               
            </div>
        }
    
    
        minView() {
            //console.log('this.data minView >>>>>>>>>>>>>>',this.data)
            //console.log('this.data minView >>>>>>>>>>>>>>',this.data.vehiclestatus)
            return <div>
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12}>
                                    {
                                        this.data.mmcode
                                            ? <Container style={{ margin: 0 }} className="custom-container">
                                                <Row className={"custom_view_text"}>
                                                    <Col xl={4}>
                                                        {this.getLabel('Vehicle Registration', this.data.licenseplateno)}
                                                    </Col>
                                                    <Col xl={4}>
                                                        {this.getLabel('Vehicle Make', this.data.makemodelyear)}
                                                    </Col>
                                                    <Col xl={4}>
                                                        {this.getLabel('Fleet No', this.data.fleetno)}
                                                    </Col>
                                                    <Col xl={4}>
                                                        {this.getLabel('First Registration Date', this.data.registrationdate, 'date')}
                                                    </Col>
                                                    <Col xl={12} style={styles.title}>
                                                        {'Other Details'}
                                                    </Col>
                                                    <Col xl={4}>
                                                        {this.getLabel('Vehicle Odometer', this.data.closingodometer)}
                                                    </Col>
                                                    <Col xl={4}>
                                                        {this.getLabel('License Expiry Date', this.data.licenceexpirydate, 'date')}
                                                    </Col>
                                                    <Col xl={4}>
                                                        {this.getLabel('Status', this.data.vehiclestatus ? this.data.vehiclestatus.vehiclestatus : '')}
                                                    </Col>
                                                    <Col xl={4}>
                                                        {this.getLabel('Sub Status', this.data.statuscategory ? this.data.statuscategory.statuscategory : '')}
                                                    </Col>
                                                    <Col xl={4}>
                                                        {this.getLabel('Next Service Date', this.data.nextservicedate, 'date')}
                                                    </Col>
                                                    <Col xl={4}>
                                                        {this.getLabel('Next Service Odometer', this.data.nextserviceodometer)}
                                                    </Col>
                                                    {/* {
                                                        this.customercontract
                                                        ? <>
                                                            <Col xl={12} style={styles.title}>
                                                                {'Contract Details'}
                                                            </Col>
                                                            <Col xl={4}>
                                                                {this.getLabel('Contract Number', this?.customercontract?.contractnumber)}
                                                            </Col>
                                                            <Col xl={4}>
                                                                {this.getLabel('Contract Status', this?.customercontract?.contractstatus?.contractstatus)}
                                                            </Col>
                                                            <Col xl={4}>
                                                                {this.getLabel('Contract Type', this?.customercontract?.contracttype)}
                                                            </Col>
                                                            <Col xl={4}>
                                                                {this.getLabel('Contract Customer', this?.data.customer?.customername)}
                                                            </Col>
    
                                                            <Col xl={4}>
                                                                {this.getLabel('Contract Period', this?.customercontract?.period)}
                                                            </Col>
                                                            <Col xl={4}>
                                                                {this.getLabel('Distance Per Month', this?.customercontract?.distancepermonth)}
                                                            </Col>
    
                                                            <Col xl={4}>
                                                                {this.getLabel('Biiling Count', this?.customercontract?.customercontractderivative[0]?.billingcount)}
                                                            </Col>
                                                            <Col xl={4}>
                                                                {this.getLabel('Remaining Billing Count', this?.customercontract?.customercontractderivative[0]?.remainingbillingcount)}
                                                            </Col>
                                                            </>
                                                        : ''
                                                    } */}
    
                                                </Row>
                                            </Container>
                                            : ''
                                    }
                                </Col>
                            </Row>
                        </Container>
               
            </div>
        }
        getvehicleHistory() {  
            return  <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col>
                        {
                            this.data.mmcode
                                ? <Container style={{ margin: 0 }} className="custom-container">
                                    <Row className={"custom_view_text"}>
                                        <Col xl={4}>
                                            {this.getLabel('Vehicle Registration', this.data.licenseplateno)}
                                        </Col>
                                        <Col xl={4}>
                                            {this.getLabel('Vehicle Make', this.data.makemodelyear)}
                                        </Col>
                                        <Col xl={4}>
                                            {this.getLabel('Fleet No', this.data.fleetno)}
                                        </Col>
                                        <Col xl={4}>
                                            {this.getLabel('First Registration Date', this.data.registrationdate, 'date')}
                                        </Col>
                                        <Col xl={12} style={styles.title}>
                                            {'Other Details'}
                                        </Col>
                                        <Col xl={4}>
                                            {this.getLabel('Vehicle Odometer', this.data.closingodometer)}
                                        </Col>
                                        <Col xl={4}> {this.getLabel('Remaining Odometer',parseInt(this?.customercontract?.distance) - parseInt(this?.data?.closingodometer))?? 0}
                                        </Col>
                                        <Col xl={4}>
                                            {this.getLabel('License Expiry Date', this.data.licenceexpirydate, 'date')}
                                        </Col>
                                        <Col xl={4}>
                                            {this.getLabel('Status', this.data.vehiclestatus ? this.data.vehiclestatus.vehiclestatus : '')}
                                        </Col>
                                        <Col xl={4}>
                                            {this.getLabel('Sub Status', this.data.statuscategory ? this.data.statuscategory.statuscategory : '')}
                                        </Col>
                                        <Col xl={4}>
                                            {this.getLabel('Next Service Date', this.data.nextservicedate, 'date')}
                                        </Col>
                                        <Col xl={4}>
                                            {this.getLabel('Next Service Odometer', this.data.nextserviceodometer)}
                                        </Col>
                                     {
                                            this.customercontract
                                            ? <>
                                                <Col xl={12} style={styles.title}>
                                                    {'Contract Details'}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Contract Number', this?.customercontract?.contractnumber)}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Contract Status', this?.customercontract?.contractstatus?.contractstatus)}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Contract Type', this?.customercontract?.contracttype)}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Contract Customer', this?.data.customer?.customername)}
                                                </Col>
    
                                                <Col xl={4}>
                                                    {this.getLabel('Contract Period', this?.customercontract?.period)}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Distance Per Month', this?.customercontract?.distancepermonth)}
                                                </Col>
    
                                                <Col xl={4}>
                                                    {this.getLabel('Biiling Cycle', this?.customercontract?.customercontractderivative[0]?.billingcount)}
                                                </Col>
                                                <Col xl={4}>
                                                    {this.getLabel('Remaining Billing Cycle', this?.customercontract?.customercontractderivative[0]?.remainingbillingcount)}
                                                </Col>
                                                </>
                                            : ''
                                        }
                            </Row>
                            <Row>
                                <Col xl={12} style={styles.title}>
                                    {'Vehicle Maintenance History'}
                                    <IconButton touch={true} style={{ float: 'right' }}
                                        onClick={() => {
                                            this.props.actions.toggleVehicleHistory(this.props.state.showhistory);
                                        }}>
                                        {this.props.state.showhistory ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </Col>
                                {this.props.state.showhistory &&
                                    <Col xl={12}>
                                        {this.props.state.service_only
                                            ? <JSReport id={this.props.id + '_service'} key={this.props.id + '_service'} shortid={'HyLkl2xod'} name={'Maintenance_View_History'} data={{ ...this.data, service_only: this.props.state.service_only, user: this.props.state.user }} />
                                            : <JSReport id={this.props.id + '_history'} key={this.props.id + '_history'} shortid={'H1mAv1uJd'} name={'Maintenance_View_Service'} data={{ ...this.data, service_only: this.props.state.service_only, user: this.props.state.user }} />
                                        }
                                    </Col>
                                }
                            </Row>
                            </Container>
                                : ''
                                    }
                    </Col>
                </Row>
            </Container>
    
    </div>
    }  
    
        getVehicleLayout() {
            return <div>
                {this.props.restructure_view ?   <CardControl 
                    title={'Vehicle Details  '}
                    subtitle={'Registration: ' + this.data.licenseplateno}
                    content={this.getvehicleHistory()}
                    callapse={true}
                /> :  <CardControl 
                title={'Vehicle Details  '}
                subtitle={'Registration: ' + this.data.licenseplateno}
                content={this.props.min ? this.minView() : this.getVehicleView()}
                callapse={true}
            /> }
             </div> 
        }
    
        render() {
            this.data = {}
            this.maintenancetransaction = []
            try {
                this.data = this.props.state
                let customercontract = this.props.state.customercontract.filter(x => x.activated === true)[0]
                this.customercontract = customercontract ? customercontract : this.props.state.customercontract[0]
                this.maintenancetransaction = this.props.state.maintenancetransaction.filter(x => x.maintenancestatus.maintenancestatus === 'Open' && x.deleted_at === null)  
            } catch (error) {
    
            }
            return this.data.licenseplateno ? this.getVehicleLayout() : ''
        }
    }
    
    const mapStateToProps = (state) => {
        return {
            state: {
                ...state.controls.vehicle,
                showhistory: state.controls.showhistory,
                service_only: state.controls.service_only,
                user: state.user
            }
        }
    }
    
    const mapDispatchToProps = (dispatch) => {
        return {
            actions: bindActionCreators({ ...actions }, dispatch)
        }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(ViewVehicle)