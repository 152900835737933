
import * as types from './types'
import moment from 'moment'

export const resetComponent = (type) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.RESET_COMPONENT,
            payload: null
        })
    }
}

export const setValue = (prop, value, valid) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BILLING_INVOICE_COMPONENT_FIELD_INPUT_VALIDATE,
            payload: {
                prop: prop,
                valid: valid ? valid : (value ? true : false)
            }
        })

        dispatch({
            type: types.SET_BILLING_INVOICE_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setAcceptedDocuments = (documents) => {
    const _file = 'Release_Form_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_BOOKING_RELEASE_FORM_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: _file,
                        type: file.type,
                    }
                })
            };
        });
        
        dispatch({
            type: types.SET_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW,
            payload: {
                preview: documents[0].preview,
                filename: _file,
            }
        })
    }
}

export const setRejectedDocuments = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW,
            payload: null
        })
    }
}
