import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
//import validator from '../../validate'

export const clearRFQDocument = (component) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_RECEIPT_DOCUMENT_PREVIEW,
            payload: {
                component: 'document',
                field: 'content',
                value: '',
            }
        })
    }
}

export const setAcceptedDocuments = (component, documents) => {
    const rfq = 'VehicleReceipt_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_RECEIPT_DOCUMENT,
                    payload: {
                        content: fileAsBinaryString,
                        filename: rfq,
                        type: file.type,
                    }
                })
            };
        });
        // dispatch({
        //     type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
        //     payload: {
        //         component: 'surrogateheader',
        //         field: 'rfq',
        //         value: rfq
        //     }
        // })

        const pload = {
            component: component.name,
            preview: documents[0].preview,
            filename: documents[0].name,
        }
        dispatch({
            type: types.SET_RECEIPT_DOCUMENT_PREVIEW,
            payload: pload
        })
    }
}

export const setRejectedDocuments = (component) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_RECEIPT_DOCUMENT_PREVIEW,
            payload: {
                component: component.name
            }
        })
    }
}

export const searchLookupValue = (model, field, search) => {
    return (dispatch, getState) => {

        const component = getState().components[field.component.name]

        const payload = {
            component: component,
            model: model,
            field: field,
            query: '?businessname=' + search,
        }

        dispatch({
            type: types.GET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setLookupData = (component, field, payload) => {

    // const fields = field.display.split('|')
    // const accessor = fields[0].split('.')[0]

    // const data = payload.data.map(x => {
    //     return {
    //         value: x[field.name],
    //         text: fields.map(y => {
    //             return y.split(',').map(z => {
    //                 return get(x, z.replace(accessor + '.', ''))
    //             }).join(' ')
    //         }).join(', ')
    //     }
    // })

    const pload = {
        component: field.component.name,
        field: field.name,
        data: payload.data
    }

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: field.component.name,
                field: field.name,
                data: payload.data
            }
        })
    }
}


export const setLookupValue = (field, value, text) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value,
            }
        })

        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_TEXT,
            payload: {
                component: field.component.name,
                field: field.name,
                value: text,
            }
        })
    }
}

export const setValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_FIELD_INPUT_VALUE,
            payload: {
                component: field.component.name,
                field: field.name,
                value: value
            }
        })
    }
}

// export const loadBusiness = (step, name) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_CUSTOMER_BUSINESS,
//             payload: {
//                 component: component.name,
//                 field: field,
//                 query: ''
//             }
//         })
//     }
// }

// export const setBusiness = (field, payload) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: types.SET_COMPONENT_FIELD_LOOKUP_DATA,
//             payload: {
//                 component: field.component.name,
//                 field: field.name,
//                 data: payload.data
//             }
//         })
//     }
// }