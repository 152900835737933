
import * as colours from '@mui/material/colors'
export const right = {
    width: '100%',
    textAlign: 'right'
}

export const left = {
    width: '100%',
    textAlign: 'left'
}

export const verticalMiddle = {
    verticalAlign: 'middle'
}

export const hidden = {
    display: 'none'
}

/* export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 1,
    verticalAlign: 'left'
} */


export const headerContainer = {
    width: '100%',
    display: 'flex'
}

export const headerIcon = {
    width: 40,
    height: 40,
    fontSize: 40,
    margin: '0 auto'
}

export const headerTitle = {
    background: 'white',
    //opacity: 0.8,
    fontWeight: 'bold',
    fontSize: '34',
    padding: 5,
    marginRight: 20
}

export const headerDescription = {
    background: 'white',
    //opacity: 0.8,
    //color: 'white',
    width: '100%',
    fontSize: '34',
    padding: 5,
    borderRight: '1px solid black'
}

export const content = {
    height: '100%'
}

export const flex = {
    display: 'flex'
}

export const toggle = {
    marginBottom: 16,
  }

  
export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '200px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingLeft: 15,
    
}

export const fieldTitleBold = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10,
    fontWeight: 'bold'
}

export const fieldContent = {
    display: 'table-cell',
    width: '75%',
    marginRight: '12%'
}

export const card = {
    height: '100%'
}
export const rightpanel = {
    background: '#D1E0E6',
    borderLeft: '2px solid black'
}
export const fieldContentTot = {
    display: 'table-cell',
    width: '80px'
}
export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '19px',
}

export const tableheader = {
    fontWeight: 'bold',
    fontSize: '16px',
    borderBottom: '2px solid red',
}
export const tablerow = {
    fontSize: '15px',
    background: 'white',
}
export const alttablerow = {
    fontSize: '15px',
    background: '#D1E0E6',
}
export const tablerowr = {
    fontSize: '15px',
    textAlign: 'right',
    borderBottom: '1px dash gray',
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'right',
}
export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}
export const numericFields = {
    textAlign: 'right',
}
export const numericTotFields = {
    textAlign: 'right',
    fontWeight: 'bold',
    borderTop: '2px solid gray',
}
export const whitediv = {
    backgroundColor: '#fff',
    paddingLeft: 2,
    paddingRight: 0,
    padddingTop: 7,
    paddingBottom: 10,
    verticalAlign: 'left',
    marginBottom: 10,
}
//import Theme from '../../theme'

export const gridOptions = {

}

export const highlight = colours.red[200]

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const action = {
    fontSize: '15px',
    color: colours.red[500]
}

export const actionHoverColor = {
    color: colours.red[500]
}

export const gridOptionsIcon = {

}

export const mainBoldTitle = {
    
    fontSize: '18px',
    fontWeight: 'Bold',
    
}

export const contentBottomOffSet = {

   marginBottom: 50
       
}

export const contentTopOffSet = {

   marginTop: 10

}
export const inputWidth = {
    width: '100%'
}


export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const selectedFieldName = {
    fontWeight: 'bold'
}


export const box = {
    width: '100%',
    backgroundColor: '#fffbe5',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}

export const box1 = {
    width: '100%',
    backgroundColor: '#cbe9f7',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}


export const tdValue = {
    width: '20%',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'right',
}
export const tdField = {
    width: '20%',
    textAlign: 'left',
}


export const trField = {
    width: '100%',
    borderTop: '1px solid gray',
    padding: 2,
}


export const column = (width, right) => {
    return width === 0
        ? {
            display: 'table-cell',
            padding: '2px',
            fontSize: '14px',
            verticalAlign: 'middle !important',
            textAlign: right ? 'right' : 'left'
        }
        : {
            display: 'table-cell',
            padding: '2px',
            fontSize: '14px',
            
            width: width,
            verticalAlign: 'middle !important',
            textAlign: right ? 'right' : 'left'
        }
}

export const row = (index, table) => {
    return index % 2 === 0
        ? {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            backgroundColor: '#ffffe5',
            fontSize: '14px',
            width: '100%'
        }
        : {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            fontSize: '14px',
            width: '100%'
        }
}
export const select_row = (table, inx) => {
    return {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            fontSize: '14px',
            backgroundColor: inx ? '#ffffe5 !important' :'white !important',
            width: '100%'
        }
}

export const select_row_exception = (table, ex) => {
    return {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            fontSize: '18px',
            backgroundColor: ex ? '#ffffe5 !important' :'#fff',
            width: '100%'
        }
}


export const column_big = (width, to_handle, accepted, rejected) => {
    return  {
            display: 'table-cell',
            padding: '2px',
            fontSize: '20px',
            backgroundColor: rejected ? 'lightpink' : to_handle ? 'lightgrey' : (accepted ? '#c8e6c9' : 'white !important'),
            width: width,
            verticalAlign: 'middle !important',
            textAlign: 'center'
        }
}

export const color = (value) => {
    return  {
            color: value,
            "text-decoration": "underline"
        }
}