import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux' 

import * as actions from './actions'
import { Dialog, Card,
     CardHeader, CardContent,
     Button
    } from '@mui/material'

import moment from 'moment'
import * as style from './styles'
import Loading from '../Loading/component'
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined'
class PublishNote extends Component {
    componentWillMount(){
            this.props.actions.getReleaseNote()
    }
  
    getPublishNote(){
        const note = this.props.state.releaseNote
        //console.log('realeasex note ', this.props.state.releaseNote)
        return(

            <Card>
                <CardHeader
                    avatar={<NotesOutlinedIcon/>}
                    title={window.t.en('PUBLISH RELEASE NOTE ')}
                    titleTypographyProps={{variant:'h6'}}
                />
                <CardContent>
                    {note.releaseDate ? <div> 
                        <div>
                        <p  style={style.list}>{`${note.releaseVersion.toUpperCase()}`}</p>
                        <p  style={style.list}>{`Date : ${moment(note.releaseDate).format('DD MMMM YYYY')}`}</p>
                    </div>
                    <div>
                        <h6  style={style.head}>About</h6>
                        <p  style={style.list}>{note.description}</p>
                    </div>
                    {note.newFeatures && note.newFeatures.length > 0 ?
                        <div>
                            <h6  style={style.head}>New Features</h6>
                            <ul>
                                {note.newFeatures.map(feature =>
                                    <li style={style.list}>{feature}</li>)}
                            </ul>
                        </div> : 
                        <></>
                    }
                    {note.bugFixes && note.bugFixes.length > 0 ?
                        <div>
                            <h6  style={style.head}>Bug Fixes</h6>
                            <ul>
                                {note.bugFixes.map(bug =>
                                    <li  style={style.list}>{bug}</li>)}
                            </ul>
                        </div> :
                        <></>
                    }
                    {note.releaseEnhancements && note.releaseEnhancements.length > 0 ?
                        <div>
                            <h6 style={style.head}>Enhancements</h6>
                            <ul>
                                {note.releaseEnhancements.map(enhancements =>
                                    <li style={style.list}>{enhancements}</li>)}
                            </ul>
                        </div> :
                         <></>
                    }
                    <br/>
                    <div style={style.flex}>
                        <Button size='large'
                            style={style.button}
                            onClick = {()=>{
                                this.props.actions.setDialogStatus()
                                this.props.actions.updateUserReadNote()
                            }}
                        >
                            {"Got It!"}
                        </Button>
                    </div>
                    </div> : <Loading message={'Retrieving information, please wait...'} />}
                    
                </CardContent>
            </Card>
        )
    }
    render(){
        //console.log('realeasex win ', this.props.state.releaseNote)
        return(
            <Dialog
            open={this.props.state.releaseNote.dialogState}
            fullWidth={true}
            maxWidth="lg"
            BackdropProps={{
                style: {
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                },
              }}              
        >{this.getPublishNote()}</Dialog>
        )
    }
}
const mapStateToProps = (state) => {
    return { 
       state : {
           ...state
       }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PublishNote)
