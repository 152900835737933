import * as types from './types'
import * as profileTypes from '../Profile/types'
import * as navActions from '../NavigationMenu/actions'
import * as workqueueActions from '../Workqueue/actions'

import * as resetReportAction from '../ReportView/actions'
import * as resetDashboardAction from '../DashboardView/actions'

import * as appTypes from '../App/types'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import * as _ from 'lodash'

export const findUnique = (arr, predicate) => {
    var found = {};
    arr.forEach(d => {
        found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]);
}

export const resetReportDashboard = () => {
    return (dispatch) => {
        dispatch(resetReportAction.resetReportView())
        dispatch(resetDashboardAction.resetDashboardView())
    }
}

export const checkDocument360 = () => {
    return (dispatch) => {
        dispatch(navActions.checkDocument360())
    }
}

export const setWorkqueueItem = (item) => {
    return (dispatch) => {
        dispatch(workqueueActions.loadWorkqueueItem(item))
    }
}

export const getBusinessCustomerHierarchy = () => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_BUSINESS_CUSTOMER_HIERARCHY'
        })
    }
}


export const openLanguageSelection = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.OPEN_USER_LANGUAGE_SELECTION,
            payload: val
        })
    }
}


export const refreshNotification = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_USER_WORKFLOW_NOTIFICATIONS_MENUS
        })
    }
}
    export const toggleNotifications = (status, anchorEl) => {
        return (dispatch) => {
            dispatch({
                type: types.TOGGLE_NOTIFICATIONS,
                payload: {
                    status: status,
                    anchorEl: anchorEl
                } 
            })
            dispatch({
                type: types.GET_USER_WORKFLOW_NOTIFICATIONS_MENUS  //todo
            })
        }
    }

    export const toggleUserProfileMenu = (status, anchorEl) => {
        return (dispatch) => {
            dispatch({
                type: types.TOGGLE_USER_PROFILE_MENUS,
                payload: {
                    status: status,
                    anchorEl: anchorEl
                } 
            })
        }
    }

    export const toggleNotificationsList = (val) => {
        return (dispatch, getState) => {
            dispatch({
                type: types.TOGGLE_NOTIFICATIONS_LIST,
                payload: getState().signedIn.openNotifications ? '' : val
            })
        }
    }

export const toggleOrganisation = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_ORGANISATION,
            payload: !val
        })
    }
}

export const toggleLanguage = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_LANGUAGE,
            payload: !val
        })
    }
}
export const toggleSideBar = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_SIDE_BAR,
            payload: !val
        })
    }
}
export const toggleSideBarState = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_SIDE_BAR_STATE,
            payload: !val
        })
    }
}
export const toggleAppBar = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_APP_BAR,
            payload: !val
        })
    }
}
export const toggleAppBarState = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.TOGGLE_APP_BAR_STATE,
            payload: !val
        })
    }
}
export const handleTabChange = (event, newValue) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TAB_VALUE,
            value: newValue,
            name: 'tabValue'
        })
    }
}

export const setBusinessCustomerHierarchy = (data) => {
    let allBusinesses = []
    let allCustomers = []

    const getTree = (data) => {
        let hierarchy = []
        let temp = {}

        const getFixedHierarchyName = (val) => {
            let arr = val.split('.').map(z => {
                return !isNaN(z)
                    ? 'C' + z
                    : z
            })

            return arr.join('.')
        }

        data.forEach(x => {
            x.business.customer.forEach(y => {
                hierarchy.push(getFixedHierarchyName(y.hierarchyname))
            })
        })

        hierarchy = _.uniq(hierarchy)
        hierarchy.sort()

        window.glyco.log(hierarchy)

        hierarchy.forEach(x => {
            let customer = {}
            let customers = data.filter(y => y.business.customer.filter(z => z.hierarchyname === x))

            customers.forEach((y) => {
                y.business.customer.forEach(z => {
                    if (getFixedHierarchyName(z.hierarchyname) === x) customer = z
                })
            })

            if (Object.keys(customer).length && customer.constructor === Object) {
                _.set(temp, x, customer)
            }
        })

        const recurse = (obj) => {
            return Object.keys(obj).map((x, i) => {
                if (!Array.isArray(obj[x]) && !obj[x].customer_id) {
                    return {
                        label: x,
                        value: uuidv4(),
                        children: Object.keys(obj[x]).length && obj[x].constructor === Object && obj[x] !== null
                            ? recurse(obj[x])
                            : []
                    }
                }
                else {
                    allBusinesses.push(obj[x].business_id)
                    allCustomers.push(obj[x].customer_id)

                    return {
                        label: obj[x].businessunit!== 'null'
                            ? obj[x].businessunit + ' (' + obj[x].customername + ')'
                            : obj[x].customername,
                        value: uuidv4() + '_' + obj[x].business_id + '_' + obj[x].customer_id,
                        children: []
                    }
                }
            })
        }

        const final = Object.keys(temp).map((x, i) => {
            if (!Array.isArray(temp[x]) && !temp[x].customer_id) {
                return {
                    label: x,
                    value: uuidv4(),
                    children: Object.keys(temp[x]).length && temp[x].constructor === Object && temp[x] !== null
                        ? recurse(temp[x])
                        : []
                }
            }
            else {
                allBusinesses.push(temp[x].business_id)
                allCustomers.push(temp[x].customer_id)

                return {
                    label: temp[x].businessunit !== 'null'
                        ? temp[x].businessunit + ' (' + temp[x].customername + ')'
                        : temp[x].customername,
                    value: uuidv4() + '_' + temp[x].business_id + '_' + temp[x].customer_id,
                    children: []
                }
            }
        })

        return final
    }

    const result = getTree(data)

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_BUSINESS_CUSTOMER_HIERARCHY,
            payload: {
                data: data,
                hierarchy: result,
                allBusinesses: _.uniq(allBusinesses.filter(Boolean)).join('.'),
                allBusinesses: _.uniq(allCustomers.filter(Boolean)).join('.')
            }
        })
    }
}

// export const getUserNotifications = () => {

//     return (dispatch, getState) => {
//         dispatch({
//             type: types.GET_USER_WORKFLOW_NOTIFICATIONS_MENUS
//         })
//     }
// } 

// export const initUserNotifications = () => {
//     return (dispatch, getState) => {
//         dispatch(getUserNotifications())
//     }
// } 

export const setUserNotifications = (payload) => {
    return (dispatch) => {
        let headers = findUnique(payload.data, d => d.workflow_id)
        dispatch({
            type: types.SET_USER_WORKFLOW_NOTIFICATIONS_MENUS,
            payload: {
                notifications_items: payload.data,
                notifications_headers: headers,
            }
        })

    }
}


export const geAlltDashboardList = () => {
    return (dispatch, getState) =>  {
        dispatch({
            type: types.GET_COMPONENT_USER_PROFILE_DASHBOARD_DATA,
            payload:{
                type: types.GET_COMPONENT_USER_PROFILE_DASHBOARD_DATA
            }
        })
    }
}
export const setDashboardList = (data) => {
    ////console.log('setDashboardList', data)
    return (dispatch, getState) =>  {
        dispatch({
            type: types.SET_COMPONENT_USER_PROFILE_DASHBOARD_DATA,
            payload: data.data
        })
    }
}

//SET_RIGHT_SIDE_REPORT_DATA

export const setRightSideList = (data, length) => {
    //console.log('setDashboardList', length)
    return (dispatch, getState) =>  {
        dispatch({
            type: types.SET_RIGHT_SIDE_REPORT_DATA,
            payload: data,
            name: 'reportData'
        })

        dispatch({
            type: types.SET_RIGHT_SIDE_REPORT_DATA,
            payload: length,
            name: 'report_length'
        })
    }  
}

export const getWorkqueueList = () => {
    return (dispatch, getState) => {
        //console.log('GET_WORKQUEUE_LIST_ITEMS')
        dispatch({
            type: types.GET_WORKQUEUE_LIST_ITEMS,
            // payload: {
            //     type: 'get_user_events',
            //     user_id: getState().user.user_id,
            // }
        })
    }
}

export const setWorkqueueList = (response) => {
    //console.log('setWorkqueueList',response)
    return (dispatch, getState) => {
        let workqueue_count = 0
        for(let x of response.data) {
            workqueue_count = workqueue_count + (x.workqueue_ind_info ? x.workqueue_ind_info : 0 + 
                x.workqueue_ind_low ? x.workqueue_ind_low : 0 + 
                x.workqueue_ind_high ? x.workqueue_ind_high : 0 + 
                x.workqueue_ind_moderate ? x.workqueue_ind_moderate : 0 + 
                x.workqueue_ind_critical ? x.workqueue_ind_critical : 0 )
        }

        dispatch({
            type: types.SET_WORKQUEUE_LIST_ITEMS,
            payload: response.data
        })
        dispatch({
            type: types.SET_WORKQUEUE_COUNT,
            payload: workqueue_count
        })
    }
}

export const getDocumentPrevData = () => {
    
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_DOCUMENT_PREVIEW_DATA,
        })
    }
}

export const setSelectedDocumentPreview = (val) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_DOCUMENT_PREVIEW,
            payload: val,
            name: 'selected_document_preview'
        })

        dispatch({
            type: profileTypes.UPDATE_USER_DEFAULTS,
            payload: {
                type: 'UPDATE_USER_DEFAULTS',
                user_id: getState().user.user_id,
                defaultdocumentview: val.toLowerCase(),
                name: 'defaultdocumentview'
            },
            
        })
    }
}

export const setDocumentPrevData = (val) => {

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_DOCUMENT_PREVIEW_DATA,
            payload: val.data,
            name: 'document_preview'
        })
    }
}
