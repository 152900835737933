import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getPaymentData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
            types.GET_CUSTOMER_TRANSACTION_BANK_RECON,
        )
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/cash_book/query?reference=' + payload.reference + '&contract_amount=' + payload.contract_amount,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                        'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                    }
                })
                    .pipe(
                mergeMap((result) => [
                        actions.setPaymentData(result.response, payload),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    ,startWith(progressBarActions.progress(true))
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
            )


export const updateTransactionsRecon = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
            types.UPD_CUSTOMER_TRANSACTION_RECON,
        )
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/cash_book',
                    method: 'POST',
                    headers: {
                        'tokenx': store.value.user.user_id,
                        'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                        'Content-Type': 'application/json'
                    },
                    body: payload
                })
                    .pipe(
                mergeMap((result) => [
                        actions.updateTransactionsReconDone(result.response, payload),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    ,startWith(progressBarActions.progress(true))
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
            )

export const getTransactionWithSameAmount = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
            types.GET_CUSTOMER_TRANSACTION_BY_AMOUNT,
        )
            ,mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/cash_book/search?contract_amount=' + payload,
                    method: 'GET',
                    headers: {
                        'tokenx': store.value.user.user_id,
                        'auth': store.value.cognito.authenticateUser.token,
                        'salt': store.value.cognito.authenticateUser.jwt,
                        'datetime': store.value.cognito.authenticateUser.datetime,
                    }
                })
                    .pipe(
                mergeMap((result) => [
                        actions.setTransactionWithSameAmount(result.response, payload),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    ,startWith(progressBarActions.progress(true))
                    ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            )
            ,catchError((error) =>
                of$(routerActions.route('/support', false, error))
            )
            )

export const updMatchTransactions = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.SET_CUSTOMER_BANK_RECON_MATCH_TRANSACTION,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/cash_book',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload.data
            })
                .pipe(
                    mergeMap((result) => [
                        actions.updMatchTransactionsDone(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )
            