
export const search_text = ''
export const vehiclemake_data = []
export const customerderivativecategorymake_data = []
export const customerderivativecategorymmcode_data = []
export const mmcode_data = []
export const vehicle_category = []
export const category_mode = [{text: 'Strict',value:0},{text: 'Flexible',value:1}]

export const fields = [
    {
        grid: true,
        name: 'name',
        title: 'Name',
        display: 'name',
    }
]
