import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_ACCESSORY_MASTER_CAPTURE_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.RESET_COMPONENT_ACCESSORY_MASTER_CAPTURE_FORM:
            return {
                contacttypes: [{text: 'Fleet Manage', value: 1},{text: 'Fleet Account', value: 2}],
                vatable_data: [{text: 'VAT Inclusive', value: 1},{text: 'VAT Exclusive', value: 2}],
                services: [],
                sundries: [],
                accessories: [],
                contacts: [],
                sundry_data: [],
                service_data: [],
                accessory_data: [],
                supplier: {},
                fields: props.fields
            }
        default:
            return state
    }
}