import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getAllInvoices = (search) => {
    return {
        type: types.GET_SUPPLIER_INVOICE_LIST,
        payload: {
            search_text: search
        }
    }
}

export const getUnitMeasureList = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIERTRANSACTION_UNIT_MEASURE_LIST,
            payload: {
                search_text: search
            }
        })
    }
}


export const getInvoiceDetails = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_SUPPLIERTRANSACTION_INVOICE_DETAILS,
            payload: id
        })
    }
}


export const setInvoiceDetails = (results) => {
    return (dispatch, getState) => {
        //console.log('setInvoiceDetailsx',results)
        
        dispatch(setLookupValue("transaction_type", 0, results.data[0].transactiontype))
        dispatch(setLookupValue("selected_supplier", results.data[0].supplier_id, results.data[0].supplier.suppliername + ' | ' + results.data[0].supplier.accountnumber))
        //dispatch(getLookupValueData(results.data[0].customer_id))
        //dispatch(getAllTransactionTypes(results.data[0].transactiontype))

        dispatch(setInputValue('invoice_description',  results.data[0].description))
        dispatch(setInputValue('customer_reference',  results.data[0].reference))
        dispatch(setInputValue('invoice_reference',  results.data[0].reference))
        dispatch(setInputValue('customertransaction_month',  results.data[0].customertransactionmonth))
        dispatch(setInputValue('customertransaction_date', moment(results.data[0].customertransactiondate).format('YYYY-MM-DD')))

        dispatch(setInputValue('sub_total',  results.data[0].nettvalue))
        dispatch(setInputValue('vat_total',  results.data[0].vat))
        dispatch(setInputValue('none_total',  0))
        dispatch(setInputValue('grand_total',  results.data[0].amount))


        let invoice_items = results.data[0].suppliertransactionitem.map(x => {
            return {
                description: x.description,
                total: x.totalamount,
                quantity: x.quantity,
                unitprice: x.unitprice,
                vatable: x.vattable,
                vat: x.vat,

                unit_text: x.unitmeasure ? x.unitmeasure.unitmeasure : '',
                account_text: x.accountingitem ? x.accountingitem.itemdescription : '',

                unit: x.unitmeasure_id,
                account_id: x.accountingitem_id,
            }
        })

        //console.log('setInvoiceDetailsx suppliertransactionitem',results.data[0].suppliertransactionitem)
        //console.log('setInvoiceDetailsx invoice_items',invoice_items)
        dispatch(setInputValue('invoice_items', invoice_items))
    }
}

export const setUnitMeasureList = (payload) => {
    return (dispatch, getState) => {
        let data = []
        data = payload.data.map(x => {
            return {
                text: x.measurename,
                value: x.unitmeasure_id
            }
        })
        dispatch(setInputValue('unit_list', data))
    }
}

export const setInvoiceList = (payload) => {
    let data = []
    var items = [{ description: '', account_text: '', account_id: 0, amount: 0, vat: 0, total: 0, key: 1 }]
    data = payload.data.filter(r => r.reference != "").map(x => {/* 
        if (x.suppliertransactionitem) {
            items = x.suppliertransactionitem.map((i, inx) => {
                return {
                    description: i.description,
                    account_text: i.accountingitem_fktext,
                    account_id: i.accountingitem_id,
                    amount: i.nettvalue,
                    vat: i.vat,
                    total: i.credit,
                    key: Number(inx) + 1,
                }
            })
        } */
        return {
            text: x.supplier_fktext + ' | ' + x.reference,
            value: x.reference,

            /*  id: x.suppliertransaction_id,
             supplier_id: x.supplier_id,
             supplier_text: x.supplier_fktext,
             suppliertransactiondate: x.suppliertransactiondate,
             suppliertransactionmonth: x.suppliertransactionmonth,
             supplierreference: x.reference,
             description: x.description,
             business_id: x.business_id,
             business_text: x.business_fktext,
             suppliertransactionitem: items */
        }
    })
    return {
        type: types.SET_SUPPLIER_LIST_DATA,
        payload: {
            data: data,
            key: "invoice_list"
        }
    }
}

export const getLookupValueData = (search) => {
    return {
        type: types.GET_CREDITORS_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getBusinessListData = (search) => {
    return {
        type: types.GET_BUSINESS_LIST,
        payload: {
            search_text: search
        }
    }
}
export const getContractAccountListData = (search) => {
    return {
        type: types.GET_ITEM_ACCOUNT_LIST,
        payload: {
            search_text: search,
        }
    }
}
export const getItemAccountListData = (search) => {
    return {
        type: types.GET_ITEM_ACCOUNT_LIST,
        payload: {
            search_text: search,
        }
    }
}
export const getDiscountAccountListData = (search) => {
    return {
        type: types.GET_DISCOUNT_ACCOUNT_LIST,
        payload: {
            search_text: search,
        }
    }
}
export const getDeliveryChargeAccountListData = (search) => {
    return {
        type: types.GET_DELIVERY_CHARGE_ACCOUNT_LIST,
        payload: {
            search_text: search,
        }
    }
}

export const setSupplierList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.suppliername,
            value: x.supplier_id
        }
    })
    return {
        type: types.SET_SUPPLIER_LIST_DATA,
        payload: {
            data: data,
            key: 'creditor_list'
        }
    }
}
export const setBusinessList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.businessname,
            value: x.business_id
        }
    })
    return {
        type: types.SET_BUSINESS_LIST,
        payload: {
            data: data
        }
    }
}
export const setContraAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })
    return {
        type: types.SET_SUPPLIER_LIST_DATA,
        payload: {
            data: data,
            key: 'glaccount_list'
        }
    }
}
export const setDiscountAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })
    return {
        type: types.SET_DISCOUNT_ACCOUNT_LIST,
        payload: {
            data: data
        }
    }
}
export const setDeliveryChargeAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })
    return {
        type: types.SET_DELIVERY_CHARGE_ACCOUNT_LIST,
        payload: {
            data: data
        }
    }
}
export const setItemAccountList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            ...x,
            text: x.itemdescription,
            value: x.accountingitem_id
        }
    })
    return {
        type: types.SET_SUPPLIER_LIST_DATA,
        payload: {
            data: data,
            key: 'glaccount_list'
        }
    }
}
export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_SUPPLIER_DROP_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field
        }
    }
}


export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: value //format(field, value)
            }
        })
    }
}

export const saveSupplierInvoiceData = (payload) => {
    return (dispatch, getState) => {
        console.log('payloadx', payload)
        let error = ""
        if(payload.data.invoice_items.length){
            for(let x of payload.data.invoice_items) {
                if(!x.account_id)
                    error = "item GL account required"
                if(!x.total)
                    error = "item total amount missing"
                if(!x.unitprice && !payload.data.transaction_type.text && ['RC - Receipt','PM - Payment'].includes(payload.data.transaction_type.text))
                    error = "item unit price required"
                if(!x.unit_text && !payload.data.transaction_type.text && ['RC - Receipt','PM - Payment'].includes(payload.data.transaction_type.text))
                    error = "item unit required"
                if(!x.description)
                    error = "item description required"
            }
        }

        
        if(!payload.data.invoice_items.length)
            error = "Please add items before you proceed"
        if(!payload.data.suppliertransaction_month)
            error = "Please enter transaction month"
        if(!payload.data.invoice_reference)
            error = "Please enter invoice reference"
        // if(!payload.data.customer_reference)
        //     error = "Please enter customer reference"
        if(!payload.data.suppliertransaction_date)
            error = "Please enter transaction date"
        if(!payload.data.invoice_description)
            error = "Please enter description"
        if(!payload.data.selected_supplier.value)
            error = "Please select customer"
        if(!payload.data.transaction_type.value)
            error = "Please select transaction type"
        if(error) {
            
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: error,
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    type: 'danger',
                    timestamp: moment().format('h:mm A')
                }
            })
        }
        else {
            dispatch({
                type: types.SAVE_SUPPLIER_INVOICE_DATA,
                payload: {
                    data: payload
                }
            })
        }
    }
}

export const savedSupplierInvoiceData = (data) => {
    window.glyco.log("SAVED_SUPPLIER_INVOICE_DATA")
    window.glyco.log(data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVED_SUPPLIER_INVOICE_DATA
        })
        dispatch(resetForm())

        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Invoice Capturing Successful!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setItemValue = (payload) => {
    return (dispatch, getState) => {
        let data = getState().suppliertransaction.invoice_items
        data[payload.inx][payload.field] = payload.value
        dispatch({
            type: types.ADD_INVOICE_ITEM_BLANK_ROW,
            payload: {
                data: data,
                prop: 'invoice_items',
            }
        })
    }
}

export const checkExistingInvoice = (search) => {
    return {
        type: types.CHECK_EXISTING_INVOICE,
        payload: {
            search_text: search,
        }
    }
}


export const sendInvalidInvoice = (payload) => {
    if (payload.data != null) {
        return {
            type: types.SET_INVOICE_INVALID,
            payload: {
                valid: false
            }
        }
    }
}
export const invalidEntry = () => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Please capture Creditor, Reference and Line items...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const setDuplicateReferenceCheck = (payload) => {
    if (payload.data.length > 0) {
        return (dispatch, getState) => {
            dispatch({
                type: types.SUPPLIER_INVOICE_DUPLICATE_FOUND,
                payload: {
                    component: 'suppliertransaction'
                }
            })

            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'The Reference Number already Exist!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return (dispatch, getState) => {
            dispatch({
                type: types.SUPPLIER_INVOICE_NO_DUPLICATE_FOUND,
                payload: {
                    component: 'suppliertransaction'
                }
            })
        }
    }
}
export const getDuplicateReferenceCheck = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_SUPPLIER_INVOICE_DUPLICATE_REFERENCE_CHECK,
            payload: {
                type: "GET_SUPPLIER_INVOICE_DUPLICATE_REFERENCE_CHECK",
                reference: reference
            }
        })
    }
}

export const foundDuplicateReference = (reference) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'The Reference Number already Exist!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

export const getInvoicePDF = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })

        dispatch({
            type: types.GEN_SUPPLIER_INVOICE_PDF,
            payload: {
                type: "GEN_SUPPLIER_INVOICE_PDF",
                id: id
            }
        })
    }
}

export const setSupplierInvoicePDF = (data) => {
    window.glyco.log('setSupplierInvoicePDF')
    window.glyco.log(data)
    let link = ''
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}
export const getAllTransactionTypes = (search) => {
    return {
        type: types.GET_SUPPLIER_TRANSACTION_TYPE_LIST,
        payload: {
            search_text: search,
            type: 'supplier'
        }
    }
}
export const setTransactionTypeList = (payload) => {
    let data = payload.data.map(x => {
        return {
            text: x.transactiontype,
            value: x.transactiontype
        }
    })

    return {
        type: types.SET_SUPPLIER_LIST_DATA,
        payload: {
            data: data,
            key: 'transaction_types'
        }
    }
}

export const setInvoiceItems = (data) => {
    const payload = {
        data: data,
        prop: 'invoice_items',
    }
    return (dispatch, getState) => {
        dispatch({
            type: types.ADD_INVOICE_ITEM_BLANK_ROW,
            payload: payload
        })
    }
}
export const addBlankRow = () => {
    return (dispatch, getState) => {
        let data = getState().suppliertransaction.invoice_items
            data.push({
                description: '',
                unit: '',
                quantity: 1,
                unitprice: 0,
                amount: 0,
                vat: 0,
                total: 0,
                vatable: true,
                discount: 0,
                account_id: 0
            })
        dispatch(setInputValue('invoice_items', data))
        // dispatch({
        //     type: types.ADD_INVOICE_ITEM_BLANK_ROW,
        //     payload: payload
        // })
    }
}
export const calcTotals = () => {
    return (dispatch, getState) => {
        let ispayment = getState().suppliertransaction.transaction_type ? getState().suppliertransaction.transaction_type.text.includes('Payment') : false
        let invoice_items = getState().suppliertransaction.invoice_items
        let sub_total = 0
        let vat_total = 0
        let none_total = 0
        let grand_total = 0
        for (let i = 0; i < invoice_items.length; i++) {
            let quantity = Number(invoice_items[i].quantity ? invoice_items[i].quantity : 0)
            quantity = ispayment ? 1 : quantity

            if(Number(invoice_items[i].unitprice ? invoice_items[i].unitprice : 0) === 0 || quantity === 0) continue
            var discount = Number(invoice_items[i].discount ? invoice_items[i].discount : 0) 
            var amount = Number(invoice_items[i].unitprice ? invoice_items[i].unitprice : 0) * quantity
            var vat = ispayment ? 0 : invoice_items[i].vatable ? Number(amount ? amount : 0) * 0.15 : 0
            var total = (amount + vat) - discount

            sub_total = sub_total + invoice_items[i].vatable ? amount : 0
            none_total = grand_total + invoice_items[i].vatable ? 0 : amount

            vat_total = vat_total + vat
            grand_total = grand_total + total

            invoice_items[i].vat = vat
            invoice_items[i].total = total
        }
        const payload = {
            data: invoice_items,
            prop: 'invoice_items',
        }
        dispatch({
            type: types.ADD_INVOICE_ITEM_BLANK_ROW,
            payload: payload
        })
        dispatch(setInputFieldValue('sub_total',Number(sub_total).toFixed(2)))
        dispatch(setInputFieldValue('none_total',Number(none_total).toFixed(2)))
        dispatch(setInputFieldValue('vat_total',Number(vat_total).toFixed(2)))
        dispatch(setInputFieldValue('grand_total',Number(grand_total).toFixed(2)))
    }
}
export const resetForm = () => {
    return (dispatch) => {
        dispatch(setLookupValue("transaction_type", null, null))
        dispatch(setLookupValue("selected_supplier", null, null))
        dispatch(setInputValue('suppliertransaction_date', moment(new Date()).format('YYYY-MM-DD HH:mm')))
        dispatch(setInputValue('suppliertransaction_month', moment(new Date()).format('YYYYMM')))
        dispatch(setInputValue('invoice_reference', ''))
        dispatch(setInputValue('invoice_description', ''))
        dispatch(setInputValue('invoice_items', []))
        dispatch(setInputValue('grand_total', 0))
        dispatch(setInputValue('viewinvoice', false))
    }
}
export const removeRow = (inx) => {
    return (dispatch, getState) => {
        let data = getState().suppliertransaction.invoice_items.filter((x, i) => i !== (inx))
        dispatch({
            type: types.REMOVE_INVOICE_ITEM_ROW,
            payload: {
                data: data,
                prop: 'invoice_items',
            } 
        })
    }
}

export const setInputFieldValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: value
            }
        })
    }
}
