import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { v1 as uuidv1 } from 'uuid'
import { get } from 'lodash'

import FontIcon from '@mui/material/Icon'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Toolbar } from '@mui/material'
//import { MapsPlace } from 'material-u/svg-icons'
import MapsPlace from '@mui/icons-material/Map';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

import Box from '@mui/material/Box';
import Icon from '../../components/Icon/component' 
import BusinessIntelligence from '../../components/BusinessIntelligence/component'
import Form from '../../components/Form/component'
//import InfoSheet from '../../components/InfoSheet/component'

import * as actions from './actions'
import * as componentActions from '../../components/Components/actions'
import * as businessIntelligenceActions from '../../components/BusinessIntelligence/actions'

import * as styles from './styles'
import Oqlis from '../../components/Oqlis/component'
import ACCESSORY from '../../components/Custom/ACCESSORY/component'
import CUSTOMERDERIVATIVE from '../../components/Custom/CUSTOMERDERIVATIVE/component'
import SUPPLIERPAYMENT from '../../components/Custom/SUPPLIERPAYMENT/component'
import SUPPLIERPAYMENTREVERSAL from '../../components/Custom/SUPPLIERPAYMENTREVERSAL/component'
import SUPPLIERPAYMENTALLOCATION from '../../components/Custom/SUPPLIERPAYMENTALLOCATION/component'
//import CUSTOMERINVOICE from '../../components/Custom/CUSTOMERINVOICE/component'
import CUSTOMERTRANSACTION from '../../components/Custom/CUSTOMERTRANSACTION/component'
import SUPPLIERTRANSACTION from '../../components/Custom/SUPPLIERTRANSACTION/component'
import TRIALBALANCE_RPT from '../../components/Custom/TRIALBALANCE_RPT/component'
import PROFITLOSS_RPT from '../../components/Custom/PROFITLOSS_RPT/component'
import CASHFLOW_RPT from '../../components/Custom/CASHFLOW_RPT/component'
import CASH_BOOK from '../../components/Custom/CASH_BOOK/component'
import CUSTOMERBANKRECON from '../../components/Custom/CUSTOMERBANKRECON/component'
import SUPPLIERBANKRECON from '../../components/Custom/SUPPLIERBANKRECON/component'
import BANK_RECONCILIATION from '../../components/Custom/BANK_RECONCILIATION/component'
import BALANCESHEET_RPT from '../../components/Custom/BALANCESHEET_RPT/component'
import CUSTOMERRECEIPT from '../../components/Custom/CUSTOMERRECEIPT/component'
import CUSTOMERALLOCATION from '../../components/Custom/CUSTOMERALLOCATION/component'
import SUPPLIERALLOCATION from '../../components/Custom/SUPPLIERALLOCATION/component'
import CUSTOMERREVERSERECEIPT from '../../components/Custom/CUSTOMERREVERSERECEIPT/component'
import CUSTOMERCREDITNOTE from '../../components/Custom/CUSTOMERCREDITNOTE/component'
import CUSTOMERJOURNAL from '../../components/Custom/CUSTOMERJOURNAL/component'
import VEHICLETRANSFER from '../../components/Custom/VEHICLETRANSFER/component'
import LICENCERENEWAL from '../../components/Custom/LICENCERENEWAL/component'
import CUSTOMERSTATEMENT from '../../components/Custom/CUSTOMERSTATEMENT/component'
import SUPPLIERTRANSACTIONCUSTOM from '../../components/Custom/SUPPLIERTRANSACTIONCUSTOM/component'
import SUPPLIERCREDITNOTE from '../../components/Custom/SUPPLIERCREDITNOTE/component'
import SUPPLIERJOURNAL from '../../components/Custom/SUPPLIERJOURNAL/component'
import GENERALLEDGERCASHBOOK from '../../components/Custom/GENERALLEDGERCASHBOOK/component'
import PURCHASEORDER from '../../components/Custom/PURCHASEORDER/component'
import ACCOUNTINGITEMLINK from '../../components/Custom/ACCOUNTINGITEMLINK/component'
import CUSTOMERTRANSACTIONCUSTOM from '../../components/Custom/CUSTOMERTRANSACTIONCUSTOM/component'
import DISPOSAL from '../../components/Custom/DISPOSAL/component'
import CUSTOMERCONTACTCUSTOM from '../../components/Custom/CUSTOMERCONTACTCUSTOM/component'
import TRIALBALANCE from '../../components/Custom/TRIALBALANCE/component'
import CONTRACTTERMINATION from '../../components/Custom/CONTRACTTERMINATION/component'
import CUSTOMCOSTCENTRECHANGE from '../../components/Custom/CUSTOMCOSTCENTRECHANGE/component'
import CUSTOMERDERIVATIVECATEGORY from '../../components/CustomerDerivativeCategory/component'
import CUSTOMREGISTRATIONCHANGE from '../../components/Custom/CUSTOMREGISTRATIONCHANGE/component'
import BILLINGMODULE from '../../components/Custom/BILLINGMODULE/component'
import WorkflowFMLQuote from '../../components/WorkflowFMLQuote/component'
import DISPOSALDECISION from '../../components/Custom/DISPOSALDECISION/component'
import SUPPLIERPAYMENTMANAGEMENT from '../../components/Custom/SUPPLIERPAYMENTMANAGEMENT/component'
import SUPPLIERPAYMENTMANAGEMENTAPPROVAL from '../../components/Custom/SUPPLIERPAYMENTMANAGEMENTAPPROVAL/component'

import Typography from "@mui/material/Typography";
import { styled  } from '@mui/material/styles';
import { ReactComponent as HomeIconCustom} from '../../assets/img/svg/icons/home.svg'
import { Link as RouterLink  } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
const CustomTextTypography = styled(Typography)(({ theme }) => ({
    color: '#131B3A'
  }));

import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
import StarBorderIcon from '@mui/icons-material/StarBorder';

class MasterComponentView extends Component {
    componentDidMount() {

        // this.props.actions.setTab(this.props.component.custom)
        this.props.actions.changeMasterComponentTab(0, this.props.component)

        const selected = this.getSelectedIntelligenceEntry('dashboard')

        if (selected) {
            this.props.actions.setSelectedDashboard(selected.component_intelligence_id)
            this.props.actions.displayDashboard(selected.key)
            this.props.actions.loadIntelligence(selected.key.replace(/-/gi, ''), selected.key, this.props.state.name)
        }

        const selectedReport = this.getSelectedIntelligenceEntry('report')

        if (selectedReport) {
            this.props.actions.setSelectedReport(selected.component_intelligence_id)
            this.props.actions.displayReport(selected.key)
            this.props.actions.loadIntelligence(selected.key.replace(/-/gi, ''), selected.key, this.props.state.name)
        }
    }

    getSelectedIntelligenceEntry(type) {
        return this.props.component
            ? this.props.component.component_intelligence
                ? this.props.component.component_intelligence.length
                    ? this.props.component.component_intelligence
                        .filter(x => x.component_intelligence_type.name === type)
                        .sort((x, y) => x.order - y.order)[0]
                    : undefined
                : undefined
            : undefined
    }

    getIntelligenceList(type) {
        return this.props.component.component_intelligence.length
            ? this.props.component.component_intelligence
                .filter(x => x.component_intelligence_type.name === type)
                .sort((x, y) => x.order - y.order)
                .map((x, i) => {
                    return <MenuItem
                        key={'intel_list_' + this.props.component.name + '_' + x.name + '_' + i + '_' + x.component_intelligence_type.name}
                        value={x.component_intelligence_id}
                        primaryText={window.t.en(x.title)}
                        onClick={(event) => {
                            event.preventDefault()

                            if (x.component_intelligence_type.name == 'report') {
                                this.props.actions.setSelectedReport(x.component_intelligence_id)
                                this.props.actions.displayReport(x.key)
                            }

                            if (x.component_intelligence_type.name == 'dashboard') {
                                this.props.actions.setSelectedDashboard(x.component_intelligence_id)
                                this.props.actions.displayDashboard(x.key)
                            }

                            this.props.actions.loadIntelligence(x.key.replace(/-/gi, ''), x.key, this.props.component.name)
                        }}
                    >{window.t.en(x.title)}</MenuItem>
                })
            : []
    }

    getBubble() {
        return this.props.component.info.bubble.map((x, ind) => {
            return <ListItem key={uuidv1() + ind} primaryText={window.t.en(x.title + ': ' + x.value)} leftIcon={<MapsPlace />} />
        })
    }

    getDashboards() {
        return <div>
            <Toolbar>
                <div firstChild={true}>
                    &nbsp;
                </div>
                <div>
                    <FontIcon className="muidocs-icon-custom-sort" ></FontIcon>
                    
                    {
                        this.props.state.value &&
                        <Select value={0}>
                            {this.getIntelligenceList('dashboard')}
                        </Select>
                    }
                </div>
            </Toolbar>

            {this.props.state.dashboard &&
                <BusinessIntelligence
                    component={this.props.component.name}
                    container={this.props.state.dashboard.replace(/-/gi, '')}
                    uuid={this.props.state.dashboard}
                />
            }
        </div>
    }

    getReports() {
        return <div>
            <Toolbar>
                <div firstChild={true}>
                    &nbsp;
             </div>
                <div>
                    <FontIcon className="muidocs-icon-custom-sort" ></FontIcon>
                    
                    {
                        this.props.state.reportValue &&
                        <Select value={0}>
                            {this.getIntelligenceList('report')}
                        </Select>
                    }
                </div>
            </Toolbar>

            {this.props.state.report &&
                <BusinessIntelligence
                    component={this.props.component.name}
                    container={this.props.state.report.replace(/-/gi, '')}
                    uuid={this.props.state.report}
                />
            }
        </div>
    }

    getCustomComponent() {
        const component = this.props.component.name.toUpperCase()
        //console.log('getCustomComponentx', component)
        return <div> 
            {component === "WorkflowFMLQuote" && <WorkflowFMLQuote />}
            {component === "ACCESSORY" && <ACCESSORY />}
            {component === "CUSTOMERDERIVATIVE" && <CUSTOMERDERIVATIVE />}
            {component === "SUPPLIERPAYMENTREVERSAL" && <SUPPLIERPAYMENTREVERSAL />}
            {component === "SUPPLIERPAYMENT" && <SUPPLIERPAYMENT />}
            {component === "SUPPLIERPAYMENTALLOCATION" && <SUPPLIERPAYMENTALLOCATION />}
            {component === "ACCOUNTINGITEMLINK" && <ACCOUNTINGITEMLINK />}
            {component === "CUSTOMERTRANSACTION" && <CUSTOMERTRANSACTION />}
            {component === "SUPPLIERTRANSACTION" && <SUPPLIERTRANSACTION />}
            {component === "TRIALBALANCE_RPT" && <TRIALBALANCE_RPT />}
            {component === "PROFITLOSS_RPT" && <PROFITLOSS_RPT />}
            {component === "CASHFLOW_RPT" && <CASHFLOW_RPT />}
            {component === "CASH_BOOK" && <CASH_BOOK />}
            {component === "CUSTOMERBANKRECON" && <CUSTOMERBANKRECON />}
            {component === "SUPPLIERBANKRECON" && <SUPPLIERBANKRECON />}
            {component === "BANK_RECONCILIATION" && <BANK_RECONCILIATION />}
            {component === "BALANCESHEET_RPT" && <BALANCESHEET_RPT />}
            {component === "CUSTOMERRECEIPT" && <CUSTOMERRECEIPT />}
            {component === "CUSTOMERALLOCATION" && <CUSTOMERALLOCATION />}
            {component === "SUPPLIERALLOCATION" && <SUPPLIERALLOCATION />}
            {component === "CUSTOMERCREDITNOTE" && <CUSTOMERCREDITNOTE />}
            {component === "CUSTOMERREVERSERECEIPT" && <CUSTOMERREVERSERECEIPT />}
            {component === "CUSTOMERJOURNAL" && <CUSTOMERJOURNAL />}
            {component === "VEHICLETRANSFER" && <VEHICLETRANSFER />}
            {component === "LICENCERENEWAL" && <LICENCERENEWAL />}
            {component === "CUSTOMERSTATEMENT" && <CUSTOMERSTATEMENT />}
            {component === "SUPPLIERTRANSACTIONCUSTOM" && <SUPPLIERTRANSACTIONCUSTOM />}
            {component === "SUPPLIERCREDITNOTE" && <SUPPLIERCREDITNOTE />}
            {component === "SUPPLIERJOURNAL" && <SUPPLIERJOURNAL />}
            {component === "GENERALLEDGERCASHBOOK" && <GENERALLEDGERCASHBOOK />}
            {component === "PURCHASEORDER" && <PURCHASEORDER />}
            {component === "CUSTOMERTRANSACTIONCUSTOM" && <CUSTOMERTRANSACTIONCUSTOM />}
            {component === "DISPOSAL" && <DISPOSAL />}
            {component === "CUSTOMERCONTACTCUSTOM" && <CUSTOMERCONTACTCUSTOM />}
            {component === "TRIALBALANCE" && <TRIALBALANCE />}
            {component === "CONTRACTTERMINATION" && <CONTRACTTERMINATION />}
            {component === "CUSTOMCOSTCENTRECHANGE" && <CUSTOMCOSTCENTRECHANGE />}
            {component === "CUSTOMREGISTRATIONCHANGE" && <CUSTOMREGISTRATIONCHANGE />}
            {component === "BILLINGMODULE" && <BILLINGMODULE />}
            {component === "DISPOSALDECISION" && <DISPOSALDECISION />}
            {component === "SUPPLIERPAYMENTMANAGEMENT" && <SUPPLIERPAYMENTMANAGEMENT />}
            {component === "CUSTOMERDERIVATIVECATEGORY" && <CUSTOMERDERIVATIVECATEGORY />}
            {component === "SUPPLIERPAYMENTMANAGEMENTAPPROVAL" && <SUPPLIERPAYMENTMANAGEMENTAPPROVAL />}
        </div>
    }

    getComponentForm() { 
        return <div>
            <br />
            <Form
                key={'mastercomponentview_create' + this.props.component.name + '_' + this.props.component.component_id}
                name={this.props.component.name}
            />
        </div>
    }

    getData() {
        return <div>
            {this.props.data}
        </div>
    }

    getBiView(key, theme) {
        return <div className="tab-oqlis">
            <Oqlis
                displayType={'dashboard'}
                oq_key={key}
                oq_theme={theme}
            />
        </div>
    }

    getView(tab) {
        //console.log('getCustomComponentx tab', tab)
        //console.log('getCustomComponentx name', this.props.component.name)
        //console.log('getCustomComponentx custom', this.props.component.custom)
        switch (tab) {
            case 'Data':
                //console.log('getCustomComponentx case Data')
            return this.getData()
            case 'Dashboards':
                return <div>
                    {this.props.component.oq_dashboard_key?this.getBiView(this.props.component.oq_dashboard_key, this.props.component.oq_theme): 'No dashboard available....'}
                </div>

            case 'Reports':
                return <div>
                {this.props.component.oq_report_key?this.getBiView(this.props.component.oq_report_key, this.props.component.oq_theme): 'No report available....'}
            </div>
            case 'Create':
                return this.props.component.custom
                    ? this.getCustomComponent()
                    : this.getComponentForm()
            default:
                return <div>No tab label specified. Unknown view.</div>
        }
    }

    getKPI() {
        const kpi = this.getSelectedIntelligenceEntry('kpi_header')

        return kpi
            ? <BusinessIntelligence
                component={this.props.component.name}
                container={kpi.key.replace(/-/gi, '')}
                uuid={kpi.key}
                size={{
                    width: '1150px',
                    height: '130px',
                    marginLeft: 'auto'
                }}
            />
            : <div />
    }

    getInfoSheet() {
        const componentHasInfoProperties = get(this.props, 'component.info.title', undefined)

        return componentHasInfoProperties
            ? <div />
            // <InfoSheet
            //     title={window.t.en(this.props.component.info.title.length ? this.props.component.info.title : this.props.component.title)}
            //     content={
            //         <List>
            //             {this.getBubble()}
            //         </List>
            //     }
            // />
            : <div />
    }

    headerTitle (tab) {
        this.component = this.props.state.component
        //console.log('this.componentx tab', tab)
        //console.log('this.componentx', this.props.component)
        return (
        <>
            <div className="app-page-title app-page-title--shadow" style={{ marginBottom: '0px',padding: '22px 65px'}}>
            <div>
                {/* <div className="app-page-title--first">
                {(
                    <div className="app-page-title--iconbox d-100">
                        <div className="d-70 d-flex align-items-center justify-content-center display-2">
                            <HomeWorkTwoToneIcon className="text-primary" style={{ width: '200px' }} />
                            
                        </div>
                    </div>
                )}
                <div className="app-page-title--heading">
                    <StarBorderIcon style={{ color: 'orange'}}  onClick={() => //console.log('HomeWorkTwoToneIconx')} /> <h1>{this.props.component.title}</h1>
                    {(
                    <div className="app-page-title--description">
                        {this.props.component.description}
                    </div>
                    )}
                </div>
                </div> */}
            </div>
            <div className="d-flex align-items-center">
                
            <div style={{ textAlign: 'right' }}>
                {
                    tab && !this.props.component.custom
                    ? <Button  variant="contained"
                    icon={<Icon iclass={'material-icons'} iname={'save'} />}
                    label={'Save'}
                    //primary={'color'}
                    disabled={this.props.state.loading}
                    className="global_button btn-success d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
                    style={{
                        width: 100
                    }}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.saveComponentData(this.props.component)
                    }}
                    >
                        {<Icon
                                iclass={'material-icons'}
                                iname={'save'}
                            />} &nbsp; {window.t.en(this.props.state.loading ? 'Saving Data' : 'Save Data')}</Button>
                    : ''
                }
               
            </div>
            </div>
            </div>
            </>
        )
    }
    render() {
        ////console.log("COMPONENT COMPONENT COMPONENT",this.props.component);
        
        let tabs = [
            {
                label: 'Data',
                title: 'Data',
                value: 0,
                highlight: true,
                visible: this.props.component.dataview
            },
            {
                label: 'Dashboards',
                title: 'Dashboards',
                value: 1,
                highlight: true,
                visible: this.props.component.intelligent
            },
            {
                label: 'Reports',
                title: 'Reports',
                value: 2,
                highlight: true,
                visible: this.props.component.report
            },
            {
                label: 'Create',
                title: this.props.component.custom && !this.props.component.dataview ? this.props.component.title : 'Create',
                value: 3,
                highlight: true,
                visible: this.props.component.createview
            }
        ]

        return <div
            key={'master_component_view_content_' + this.props.component.name} style={styles.content}>
            {this.props.component.oq_kpi ?
                <Oqlis
                    displayType={'dashboard'}
                    oq_key={this.props.component.oq_kpi}
                    kpi_view={this.props.component.kpi_view}
                    oq_theme={"kpi_theme"}
                    height={'150px'}
                    noheader={1}
                /> : ''
            }
            <br/>
            {

                this.props.component.title
                    ? <div key={'master_component_view_title_' + this.props.component.name} style={styles.title}>
                      {this.headerTitle(this.props.state.tab)}  {/*window.t.en(this.props.component.title)} */}
                    </div>
                    : <br />
            }
            {/* <div key={'master_component_view_kpi_' + this.props.component.name} style={styles.kpi}>
                {this.getKPI()}
            </div> */}

            <div key={'master_component_view_tabs_' + this.props.component.name} style={styles.tabs}>
                <Tabs
                    initialSelectedIndex={0}
                    value={this.props.state.tab}
                    inkBarStyle={{
                        height: 4
                    }}
                    color="primary" 
                    indicatorColor="primary"
                    onChange={(event, value) => {
                        this.props.actions.changeMasterComponentTab(value, this.props.component)
                    }}
                >
                    {
                         tabs
                         .filter(x => x.visible)
                         .sort((x, y) => x.value - y.value)
                         .map((x,i) => {
                             return <Tab color="primary" key={i} style={{minWidth: (100/tabs.filter(x => x.visible).length) + '%'}} label={x.title } />
                         })
                    }
                </Tabs>
                <div>
                    {
                         tabs
                         .filter(x => x.visible)
                         .sort((x, y) => x.value - y.value)
                         .map((x,i) => {
                             return (this.props.state.tab === i && <Box key={i} p={1}>{this.getView(x.label)}</Box>)
                         })
                    }
                </div>
                {this.getInfoSheet()}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.masterComponentView,
            nav: {
                ...state.navigationMenu
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...componentActions, ...businessIntelligenceActions }
            , dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterComponentView)