export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA'
export const GET_DASHBOARD_URL = 'GET_DASHBOARD_URL'
export const SET_DASHBOARD_URL = 'SET_DASHBOARD_URL'
export const GET_KPI_DATA = 'GET_KPI_DATA'
export const SET_KPI_DATA = 'SET_KPI_DATA'
export const GET_OQLIS_REPORT_TOKEN = 'GET_OQLIS_REPORT_TOKEN'
export const SET_OQLIS_REPORT_TOKEN = 'SET_OQLIS_REPORT_TOKEN'


