import * as types from './types'

export const adhocGridLoadToStateTree = (name, uri, body, columns, paging, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ADHOC_GRID_LOAD_TO_STATE_TREE,
            payload: {
                name: name,
                uri: uri,
                data: [],
                body: body,
                loading: false,
                columns: columns,
                paging: paging,
                search: search
            }
        })
    }
}

export const adhocGridGetDataWithComponent = (name, component, query) => {
    return (dispatch, getState) => {
        //console.log('adhocGridGetDataWithComponentx',name, component, query )
        dispatch({
            type: types.ADHOC_GRID_LOAD_COMPONENT_DATA,
            payload: {
                name: name,
                query: query,
                component: component,
                data: [],
                loading: false,
            }
        })
    }
}

export const adhocGridGetData = (name, uri, body, paging, state, search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.ADHOC_GRID_GET_DATA,
            payload: {
                name: name,
                uri: uri,
                body: {
                    ...body,
                    paging: paging,
                    state: {
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted,
                        filtered: state.filtered
                    },
                    search: search
                }
            }
        })
    }
}

export const adhocGridSetData = (name, data) => {
    return (dispatch, getState) => {
        //console.log('adhocGridSetData', {
        //     name: name,
        //     data: data.data,
        //     paging: data.paging
        // })
        dispatch({
            type: types.ADHOC_GRID_SET_DATA,
            payload: {
                name: name,
                data: data.data,
                paging: data.paging
            }
        })
    }
}

export const adhocGridSetCellInputValue = (parent, prop, inx, value) => {
    return (dispatch, getState) => {
        let data = getState().adhocGrid[parent].data
        data[inx][prop] = value
        dispatch({
            type: types.SET_COMPONENT_DATA_INPUT_VALUE,
            payload: {
                parent: parent,
                data: data
            }
        })

    }
}

export const adhocGridSetSubCellInputValue = (parent, sub, prop, inx, i, value) => {
    return (dispatch, getState) => {
        //console.log('adhocGridSetSubCellInputValuex', parent, sub, prop, inx, i, value)
        let data = getState().adhocGrid[parent].data
        data[inx][sub][i][prop] = value
        //console.log('adhocGridSetSubCellInputValuex record' , data[inx][sub][i][prop])
        dispatch({
            type: types.SET_COMPONENT_DATA_INPUT_VALUE,
            payload: {
                parent: parent,
                data: data
            }
        })

    }
}