// export const SOLVE_FOR_MONTHLY_PAYMENT = 'SOLVE_FOR_MONTHLY_PAYMENT'
// export const SOLVE_FOR_PRESENT_VALUE = 'SOLVE_FOR_PRESENT_VALUE'
// export const SOLVE_FOR_FUTURE_VALUE = 'SOLVE_FOR_FUTURE_VALUE'
// export const SOLVE_FOR_INTEREST_RATE_PER_ANNUM = 'SOLVE_FOR_INTEREST_RATE_PER_ANNUM'
// export const SET_FIELD_VALUE = 'SET_FIELD_VALUE'
// export const RESET_FIELD_VALUE = 'RESET_FIELD_VALUE'
// export const SET_ERROR = 'SET_ERROR'
// export const SET_VALID = 'SET_VALID'
// export const CHANGE_SELECT = 'CHANGE_SELECT'
// export const CALCULATE = 'CALCULATE'
// export const RESET_FINANCIAL_CALCULATOR = 'RESET_FINANCIAL_CALCULATOR'
// export const CREATE_CALCULATOR_STATE = 'CREATE_CALCULATOR_STATE'
// export const REMOVE_CALCULATOR_STATE = 'REMOVE_CALCULATOR_STATE'
// export const GET_VEHICLES = 'GET_VEHICLES'
// export const SET_FORM_MMCODE_DETAILS = 'SET_FORM_MMCODE_DETAILS'
// export const GET_COMPONENT_MMCODE_LOOKUP_DATA = 'GET_COMPONENT_MMCODE_LOOKUP_DATA'
// export const SET_COMPONENT_MMCODE_LOOKUP_DATA = 'SET_COMPONENT_MMCODE_LOOKUP_DATA'
// export const SET_COMPONENT_FIELD_INPUT = 'SET_COMPONENT_FIELD_INPUT'
export const GET_PO_SUPPLIER_COMPONENT_FIELD_LOOKUP_DATA = 'GET_PO_SUPPLIER_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_PO_SUPPLIER_COMPONENT_FIELD_LOOKUP_DATA = 'SET_PO_SUPPLIER_COMPONENT_FIELD_LOOKUP_DATA'
export const SET_PO_SUPPLIER_COMPONENT_FIELD_INPUT_VALUE = 'SET_PO_SUPPLIER_COMPONENT_FIELD_INPUT_VALUE'
export const SET_PO_SUPPLIER_COMPONENT_FIELD_INPUT_TEXT = 'SET_PO_SUPPLIER_COMPONENT_FIELD_INPUT_TEXT'
export const SET_PO_SUPPLIER_COMPONENT_GRID_DATA = 'SET_PO_SUPPLIER_COMPONENT_GRID_DATA'
export const GET_PO_SUPPLIER_SELECTED_DETAILS = 'GET_PO_SUPPLIER_SELECTED_DETAILS'
export const SET_PO_SUPPLIERS_SELECTED_COMPONENT_GRID_DATA = 'SET_PO_SUPPLIERS_SELECTED_COMPONENT_GRID_DATA'