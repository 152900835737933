import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.LOAD_WORKFLOW_TIMELINE:
            return {
                ...state,
                [action.payload.name]: {
                    name: action.payload.name,
                    team_id: action.payload.team_id,
                    user_id: action.payload.user_id,
                    workflow_queue_id: action.payload.workflow_queue_id,
                    loading: true,
                    data: [],
                    workflow_queues: [],
                    workflow_queue: {},
                    isOpen: action.payload.is_visible,
                    searchable: action.payload.searchable
                }
            }
        case types.WORKFLOW_TIMELINE_GET_WORKFLOW_TIMELINE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    loading: true,
                    data: [],
                    workflow_queues: [],
                    workflow_queue: {}
                }
            }
        case types.WORKFLOW_TIMELINE_SET_WORKFLOW_TIMELINE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    data: action.payload.data,
                    loading: false
                }
            }
        case types.TOGGLE_WORKFLOW_TIMELINE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    isOpen: action.payload.status === false? false:!state[action.payload.name].isOpen
                }
            }
        case types.SHOW_WORKFLOW_TIMELINE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    isOpen: true
                }
            }     
        case types.WORKFLOW_TIMELINE_SET_SELECTED_WORKFLOW_TIMELINE_QUEUE:
            return {
                ...state,
                selectedWorkflowTimeLineQueue: action.payload.index
            }
        case types.CLOSE_WORKFLOW_TIMELINE:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    isOpen: false
                }
            }
        case types.TOGGLE_WORKFLOW_TIMELINE_STATE_BY_TITLE:
            return {
                ...state,
                timeline: action.payload
            }
        case types.TOGGLE_WORKFLOW_TIMELINE_TASK_BY_TASKNUMBER:
            return {
                ...state,
                timelinetask: action.payload
            }
            case types.SET_WORKFLOW_TASK_STEPS:
        //console.log('SET_WORKFLOW_TASK_STEPS',action.payload)
        return {
            ...state,
            workflow_stepper: action.payload,
        }
        default:
            return state
    }
}