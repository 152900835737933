import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system'
// import TextField from '@mui/material/TextField'
import QuoteItems from '../../WorkflowPOHeader/QuoteItems/component'
import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import * as styles from './styles'
import * as actions from './actions'
import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'
import TextField from '../../../controls/textField'
import PoQuotations from '../../../controls/poQuotations'

import ViewVehicle from '../../../controls/view_vehicle'


class DefleetQuoteApproval extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
    }

    checksheetComments(data) {
        return <Card title={window.t.en('Check Sheet Comments')}
            description={window.t.en('Comments captured on upload Check Sheet')}
            content={<Container style={{ margin: 0 }} className="custom-container">                
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Uploaded By: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user[0].firstname + " " + data.user[0].lastname}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Uploaded Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user[0].email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user[0].phone}</Col>
                </Row>
                <Row> <Col xl={12}><br></br></Col></Row>      
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Check Sheet Completed By: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.completedby}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Check Sheet Completed Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.data.completeddate).format('YYYY-MM-DD')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Location:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.location}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Odometer Reading:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.odoreading}</Col>
                </Row>
                <Row> <Col xl={12}><br></br></Col></Row>   
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            label={'No Comments...'}
                            disabled={true}
                            fullWidth={true}
                            variant="outlined"
                            multiLine={false}
                            value={data.data.comments}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            </Container>}
        />
    }
    quotesComments(data) {
        return <Card title={window.t.en('Quotes Comments')}
            description={window.t.en('Comments captured on quotes & upload')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                 <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Quote Number: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.data.quotenumber}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Quote Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.data.quotedate).format("YYYY-MM-DD")}</Col>

                </Row>
                <Row> <Col xl={12}><br></br></Col></Row>
                 <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Captured By: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname + " " + data.user.lastname}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Captured Date: '}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user.email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                </Row>
                <Row> <Col xl={12}><br></br></Col></Row> 
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            hintText={'No Comments'}
                            disabled={true}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={data.data.comments.value}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
    }
    commentsView(steps) {
        return <div>
            <Card
                title={window.t.en('Rebill ')}
                description={window.t.en('Rebill summary')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'De-fleet Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step2.data && steps.step2.data.defleetdate ? steps.step2.data.defleetdate : steps.step2.datestamp).format("YYYY-MM-DD")}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step1.data.defleetNumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Repair Days:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{(steps.step3.data.days.value)}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Warranty:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step3.warranty}</Col>
                    </Row>
                </Container>} />
            <Card
                title={window.t.en('Uploaded Documents')}
                description={window.t.en('Uploaded Documents for Rebill')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {
                                steps.documents
                                    ? <PdfViewer
                                        key={'document_viewer_approval'}
                                        name={'booking'}
                                        workflow={'fml_customer_quote'}
                                        task={'tsk_defleet_review_and_approve_tech'}
                                        documents={steps.documents}
                                        edit={false}
                                        workflow_queue_data={this.props.workflow_queue_data}
                                    />
                                    : <Loading message={'Retrieving Documents...'} />
                            }
                        </Col>
                    </Row>
                </Container>
                } />
            <Row>
                <Col xl={12}><br></br></Col>
            </Row>
            <Card
                title={window.t.en('Approval Comments')}
                description={window.t.en('Enter approval comments for Rebill')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row style={styles.hidden} >
                        <Col xl={12}>
                            <Checkbox
                                id={'txt_send_authorisation'}
                                key={'txt_send_authorisation'}
                                name={'txt_send_authorisation'}
                                field={'send_authorisation'}
                                checked={this.props.state.send_authorisation.value}
                                labelPlacement={'end'}
                                label={window.t.en('Send Authorisation to Supplier')}
                                // setComponentFieldInput={
                                //     this.props.actions.setValue
                                // }
                                onCheck={(checked) => {
                                    this.props.actions.setValue('send_authorisation', checked)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <Checkbox
                                id={'txt_issupplier_correct'}
                                key={'txt_issupplier_correct'}
                                name={'txt_issupplier_correct'}
                                field={'issupplier_correct'}
                                checked={this.props.state.issupplier_correct.value}
                                labelPlacement={'end'}
                                label={window.t.en('Is the correct supplier selected')}
                                // setComponentFieldInput={
                                //     this.props.actions.setValue
                                // }
                                onCheck={(checked) => {
                                    this.props.actions.setValue('issupplier_correct', checked)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomments'}
                                label={'Enter comments...'}
                                fullWidth={true}
                                multiLine={false}
                                variant="outlined"
                                value={this.props.state.comments.value}
                                onChange={(event) => {
                                    this.props.actions.setValue('comments', event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                </Container>} />
        </div>
    }
    commentsWriteoff(steps, vehicle) {
        //console.log('commentsWriteoffx',steps)
        return <div>
            <Card
                title={window.t.en('Rebill ')}
                description={window.t.en('Rebill summary')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'De-fleet Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{moment(steps.step1.data && steps.step1.data.defleetrequestdate ? steps.step1.data.defleetrequestdate : steps.step1.datestamp).format("YYYY-MM-DD")}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{steps.step1.data.defleetNumber}</Col>
                    </Row>
                </Container>} />
            <Card
                title={window.t.en('Rebill Document')}
                description={window.t.en('Document for Rebill')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {
                                steps.documents
                                    ? <PdfViewer
                                        key={'document_viewer_approval'}
                                        name={'booking'}
                                        workflow={'fml_customer_quote'}
                                        task={'tsk_defleet_review_and_approve_tech'}
                                        documents={steps.documents}
                                        edit={false}
                                        workflow_queue_data={this.props.workflow_queue_data}
                                    />
                                    : <Loading message={'Retrieving Documents...'} />
                            }
                        </Col>
                    </Row>
                </Container>} />
            <Card
                title={window.t.en('Approval Comments')}
                description={window.t.en('Enter approval comments for Rebill')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <TextField
                                id={'txtcomments'}
                                hintText={'Enter comments'}
                                fullWidth={true}
                                multiLine={false}
                                variant="outlined"
                                value={this.props.state.comments.value}
                                onChange={(event) => {
                                    let value = event.target.value
                                    this.props.actions.setValue('comments', value)
                                }}
                            />
                        </Col>
                    </Row>
                </Container>} />
        </div>
    }

    quoteItems(vehicle, data) {
        const maint = vehicle.maintenancetransaction
        const licenseplateno = vehicle.licenseplateno
        return <Card
            title={window.t.en('Quote Items')}
            description={window.t.en('Quote items captured.')}
            content={<QuoteItems workflow_queue_data={maint} workflow_quote_data={data} read_only={true} licenseplateno={licenseplateno} />}
        />
    }
    SupplierLatter(data) {
        return data
            ? <Card
                title={window.t.en('Supplier Details')}
                description={data.suppliername}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Name:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliername}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.suppliertype}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Registration Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.registrationnumber}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.vatnumber}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Limit:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.supplierage && data.supplierage.length ? data.supplierage[0].suplierspendlimit : ''}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Supplier Usage:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.supplierage && data.supplierage.length ? data.supplierage[0].suplierusage : ''}</Col>
                    </Row>
                </Container>} />
            : 'No Supplier Found'
    }
    techPerson(data) {
        return data
            ? <Card
                title={window.t.en('Booking Team')}
                description={window.t.en('Details of person who booked the service. ')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Schedule Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.datestamp}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Booking Type:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.data.purchaseOrderType.text}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Booking Description:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.data.booking.servicedescription}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Booked Date:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.data.booking.servicedate}</Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={styles.fieldLabel}>{'Booking Done By:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.firstname} {data.user.lastname}</Col>
                        <Col xl={3} style={styles.fieldLabel}>{'Contact Number:'}</Col>
                        <Col xl={3} style={styles.selectedFieldName}>{data.user.phone}</Col>
                    </Row>
                </Container>} />
            : ''
    }
    LabourRates(supplier) {
        return <Card
            title={window.t.en('Labour Rates')}
            description={window.t.en('Customer labour rates.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Spares Discount:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.sparesdiscount ? supplier.sparesdiscount : 'Not Set'}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Labour Rate Per Hour:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.labourrateperhour ? supplier.labourrateperhour : 'Not Set'}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'In Warranty Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.inwarrantylabourrate ? supplier.inwarrantylabourrate : 'Not Set'}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Out of Warranty Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.outwarrantylabourrate ? supplier.outwarrantylabourrate : 'Not Set'}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Special Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.speciallabourrate ? supplier.speciallabourrate : 'Not Set'}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'After Hours Labour Rate:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{supplier.afterhourslabourrate ? supplier.afterhourslabourrate : 'Not Set'}</Col>
                </Row>
            </Container>} />
    }
    renderView(data) {
        let step1 = data.step1 ? data.step1.data : data
        let step2 = data.step2 ? data.step2 : data
        let step3 = data.step3 ? data.step3 : data
        let vehicle = step1.selectedVehicleDetails.data[0]
        let supplier = data.step3 ? step3.supplier : {}
        return <div>{step1.write_off ? <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    {this.commentsWriteoff(data, vehicle)}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <br />
                </Col>
            </Row>
        </Container> : <Container style={{ margin: 0 }} className="custom-container">
                <ViewVehicle key={vehicle.vehicle_id} id={vehicle.vehicle_id} />
                {/* <Row>
                    <Col xl={12}>
                        {this.SupplierLatter(step3.supplier)}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {data.step3 ? this.LabourRates(step3.supplier) : ''}
                    </Col>
                </Row> */}
                <Row>
                    <Col xl={12}>
                        <PoQuotations quotations={step3.quotations} />
                        {/* {data.step3 ? vehicle ? this.quoteItems(vehicle, step3.quotations) : '' : ''} */}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.checksheetComments(step2)}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.quotesComments(step3)}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {this.commentsView(data)}
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>

        }

        </div>
    }
    render() {
        let workflow_queue_data = null
        let validate = false
        try {

            //console.log("this.props.task", this.props.task)
            validate = this.props.task === this.props.workflow_queue_data.workflow_task.name
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]// ? this.props.workflow_queue_data.context_data.data.data.data[0] : this.props.workflow_queue_data.data.data.data[0].data.confirm

            //console.log("workflow_queue_data", workflow_queue_data)
            //console.log("validate", validate)
        } catch (error) {

        }

        return validate
            ? this.renderView(workflow_queue_data)
            : 'Select vehicle...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.defleetQuoteApproval
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefleetQuoteApproval)