
export const grid = []
export const accessory_total = 0
export const fields = [
    // {
    //     name: 'checkbox',
    //     label: '',
    //     type: 'checkbox',
    //     width: 100,
    //     hide: false,
    //     text: null,
    //     value: true
    // },
    {
        name: 'accessoryname',
        label: 'Accessory Name',
        type: 'text',
        width: 400,
        align: 'left',
        read_only: true,
        text: null,
        value: null
    },
    {
        name: 'rateperday',
        label: 'Rate Per Day',
        type: 'numeric',
        width: 250,
        align: 'left',
        read_only: true,
        text: null,
        value: 0.00
    },
    {
        name: 'totalprice',
        label: 'Total Price',
        type: 'text',
        width: 230,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: 0.00
    }
]

