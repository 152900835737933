import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as _ from 'lodash'
import moment from 'moment'
import { Table } from '@mui/material'
import { Container, Row, Col } from 'react-grid-system'
import TextField from '@mui/material/TextField'

import Loading from '../../Loading/component'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewMaintenance from '../../../controls/view_maintenance'
import Card from '../../../controls/card'
import PdfViewer from '../../PDF/component'
import Fields from '../../../controls/cardfields'
import Checkbox from '../../../controls/checkbox'

import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import ReactQuill from 'react-quill'

import * as styles from './styles'
import * as actions from './actions'

class PaymentDOAApproval extends Component {
    componentDidMount() {
        this.props.actions.resetComponent()
    //     this.props.actions.setValue('issupplier_correct', false)
    }


    getComments() {
        return <Card title={window.t.en('Approval Comments')}
                        subtitle={window.t.en('Capture approval comments')}
                        content={<TextField
                        id={'txtcomments'}
                        variant="outlined" 
                        label={'Enter approval comments'}
                        fullWidth={true}
                        multiLine={true}
                        value={this.props.state.comments}
                        onChange={(event, value) => {
                        this.props.actions.setValue('comments', event.target.value)
                    }}
                />
            }
        />
    }

    viewInvoice(data) {
        return <Card title={window.t.en('Capture Creditor Invoice')}
                    subtitle={window.t.en('Capture supplier details')}
                content={<Fields  nocard={true}
                fields={[
                    {size: 4, label: 'Captured By', value: data.user.firstname},
                    {size: 4, label: 'Date', value: data.date},
                    {size: 4, label: 'Month:', value: data.month},
                    {size: 4, label: 'Reference', value: data.reference},
                    {size: 4, label: 'Description', value: data.description},
                ]}
                />
            }
        />
    }

     getSupplier(data) {
        return data.supplier_id 
            ? <ViewSupplier
                min={true}
                key={data.supplier_id} 
                id={data.supplier_id} 
            />
            : ''
    }

    getTotal(data, prop) {
       var sum = 0
       for(let x of data) {
           sum = sum + parseFloat(x[prop].toString().replace(/,/g, ''))
       }
       return this.getField(sum, 'numeric')
   }

   getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : ''
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }
    getLabel(label, prop, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                <Typography variant="body1" component="div"
                style={{fontWeight: 'bold'}}>
                    {this.getField(this.props.state[prop], type)}
                </Typography>
            }
            label={
                <label style={{width: '200px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
                    {label}
                </label>  
            }
            labelPlacement="start"
        />
    }
    viewItems(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col  xl={12}>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                            <tr>
                                <th>Description</th>
                                <th>Unit</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>VAT</th>
                                <th>Total</th>
                                <th>Account</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    data.items.map(x => {
                                        return <tr  style={{fontWeight: 'normal',fontSize: '14px'}}>
                                            <td>{x.Description}</td>
                                            <td>{x.unitmeasure}</td>
                                            <td>{this.getField(x.unitprice, 'numeric')}</td>
                                            <td>{x.quantity}</td>
                                            <td>{this.getField(x.vat, 'numeric')}</td>
                                            <td>{this.getField(x.total, 'numeric')}</td>
                                            <td>{x.accountingitem}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                            <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>{this.getTotal(data.items, 'unitprice')}</th>
                                <th></th>
                                <th>{this.getTotal(data.items, 'vat')}</th>
                                <th>{this.getTotal(data.items, 'total')}</th>
                                <th></th>
                            </tr>
                            </thead>
                        </Table>
                     </Col>
                 </Row>
                 <Row>
                     <Col xl={12}>
                     </Col>
                 </Row>
             </Container>
     }

    getLayout() {
        //console.log('this.data.documents',this.data.documents)
        return  <div>
                {/* <ViewVehicle key={step1.vehicle_id} id={step1.vehicle_id} />         
                <ViewMaintenance key={step1.maintenancetransaction_id} id={step1.maintenancetransaction_id} /> */}
                
                {this.viewInvoice(this.data.step1)}
                {this.getSupplier(this.data.step1)}
                <Card
                    title={'Capture Creditor Items'}
                    subtitle={'Creditor capture internal reference '}
                    content={this.viewItems(this.data.step1)}
                />
                <Card
                    title={'Uploaded Document'}
                    subtitle={'Upload creditor payment supporting document: '}
                    content={<PdfViewer             
                        key={'document_viewer_uploaded_file'}
                        name={'tsk_billing_run_start'}
                        workflow={'billing_run'}
                        task={'tsk_billing_run_start'}
                        documents={this.data.documents}
                        edit={false} />}
                />
                {this.getComments()}
            </div>
    }

    render() {
        this.data = null
        try {
            this.data = this.props.workflow_queue_data.context_data.data.data.data
        } catch (error) {

        }
        
        return this.data
            ?  this.getLayout()
            :  <Loading message={'Retrieving data, please wait...'} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.paymentDOAApproval
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDOAApproval)