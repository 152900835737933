import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_CUSTOMER_MASTER_CAPTURE_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.RESET_COMPONENT_CUSTOMER_MASTER_CAPTURE_FORM:
            return {
                ...state,
                contacttypes : [{ text: 'Fleet Manage', value: 1 }, { text: 'Fleet Account', value: 2 }],
                addresstypes : [{ text: 'Physical', value: 1 }, { text: 'Postal', value: 2 }],
                jobtitle_data : [],
                address : [],
                contacts : [],
                organisationtype_data : [],
                empty : true,
                customername : '',
                tradingname : '',
                accountnumber : '',
                registrationnumber : '',
                vatnumber : '',
                costcentre : '',
                bidvestgroup : false,
                non_reporting : false,
                creditlimit : '',
                turnoverindividualtype_data : [],
                turnoverbusinesstype_data : [],
                sourceoffunds_data : [],
                sourceofwealth_data : [],
                country_data : [],
                relationship_period : '',
                politically_exposed_person : false,
                domestic_prominent_influential_person : false,
                foreign_prominent_public_official : false,
                ultimate_beneficial_owner : '',
                related_parties : '',
                standard_industrial_classification : '',
                enhanced_due_diligence : false,
                facilityrenewalperiod_data : [],
                title_data : [],
                gender_data : [],
                user_data : [],
                region_data : [],
                branch_data : [],
                facilitylastreviewdate : '',
                facilitynextreviewdate : '',
                individualturnover : '',
                businessturnover : '',
                acccontact : [],
                organisationtype :'',
                organisationtype_id :'',
                turnoverindividualtype: '',
                turnoverindividualtype_id : '',
                turnoverbusinesstype: '',
                turnoverbusinesstype_id : '',
                firstname: '',
                user_id: '',
                region : '',
                region_id : '',
                branch: '',
                branch_id: '',
                sourceoffunds: '',
                sourceoffunds_id : '',
                sourceofwealth: '',
                sourceofwealth_id: '',
                country : '',
                country_id: '',
                facilityrenewalperiod: '',
                facilityrenewalperiod_id: '',
                fields: props.fields
            }
        default:
            return state
    }
}