export const report_data = null
export const end_date = null
export const report_type = null
export const start_date = null
export const report_type_value = null
export const itemdescription = null
export const accountingitem_id = null
export const accountingitem = {}
export const accountingitem_data = []
export const report_type_data = [
    {text: 'Reconciled Transactions', value: true},
    {text: 'Unreconciled Transactions', value: false},
    {text: 'All Transactions', value: null}
]
export const columns = [
    {name: 'entry_date', title: 'Transaction Date', type: 'date'},
    {name: 'reference', title: 'Transaction Reference', type: 'text'},
    {name: 'item_type', title: 'Transaction Description', type: 'text'},
    {name: 'contract_amount', title: 'Transaction Amount', type: 'numeric'},
    {name: 'balance', title: 'Balance', type: 'numeric'}
]
export const excel_headers = [{
    name: 'transaction_date',
    title: 'Transaction Date',
    type: 'date'
},{
    name: 'reference',
    title: 'Transaction Reference',
    type: 'text'
},{
    name: 'item_type',
    title: 'Transaction Description',
    type: 'text'
},{
    name: 'contract_amount',
    title: 'Transaction Amount',
    type: 'text'
},{
    name: 'reconciled',
    title: 'Reconciled/Unreconciled',
    type: 'text'
},{
    name: 'balance',
    title: 'Balance',
    type: 'text'
}]
