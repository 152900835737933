import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'
import Icon from '../Icon/component'
import { Card, CardHeader, CardContent } from '@mui/material'
import "react-table/react-table.css"

import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import ReactQuill from 'react-quill'



import * as actions from './actions'
import * as styles from './styles'

class ResellerView extends Component {

    getFieldValue(field, index) {
        if(!this.props.state.reseller.data) return ''
        let value = ''
      
        try {
            value = this.props.state.reseller.data[field.name] 
                ? this.props.state.reseller.data[field.name].text
                    ? this.props.state.reseller.data[field.name].text 
                    : this.props.state.reseller.data[field.name].input
                : '' 
      
        } catch (error) {
        }
        
        
        switch (field.component_field_display_type.name) {
            case 'autoComplete':
                return value 
            case 'datePicker':
            case 'timePicker':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD hh:mm')
                    : 'Not Set'
            case 'textBox':
                return field.component_field_data_type.name === 'decimal' || field.component_field_data_type.name === 'float'
                    ? value !== null && value.length > 0 && !isNaN(value)
                        ? parseFloat(value).toFixed(2)
                        : value
                    : value
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            case 'image':
                return <a href={"data:image/jpeg;base64," + value} download><img src={"data:image/jpeg;base64," + value} style={{ height: '500px',width: '500px' }} /></a>
            case 'toggle':
                const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : value
                return value !== null
                    ? value
                        ? toggle[0]
                        : toggle[1]
                    : 'Not Set'
            default:
                return value
        }
    }


    getField(field, index) {
        return this.getFieldValue(field, index)
    }
    
    toggleField(field) {
        let show = true
        let toggle_name = null
        try{
            toggle_name = this.props.state.reseller.data.budgetlevel_id.data.filter(x => x.value === this.props.state.reseller.data.budgetlevel_id.input)[0].text
        }
        catch(err){}

        if(field.name === 'customer_id' && toggle_name !== 'Cost Centre') 
            return false
        else if(field.name === 'vehicle_id' && toggle_name !== 'Registration Number') 
            return false
        else 
            return true
    }

    getSection(section) {
        let fields = this.props.state.reseller.component_field
            .filter(x => x.form === true)
            .filter(x => x.component_section_id === section.component_section_id)
            .map((x, i) => {
                
                //return <Col style={styles.visibility(this.toggleField(x))} key={'col_' + x.name} xl={6}>{this.getField(x, i)}</Col>
                return <Col className={'custom-col-label'} xl={6}  style={styles.visibility(this.toggleField(x))}>
                        <FormControlLabel
                            fullWidth={true}
                            control={
                                <Typography variant="body1" component="div"
                                style={{fontWeight: 'bold'}}>
                                    {this.getField(x, i)} 
                                </Typography>
                            }
                            label={
                                <label style={{width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top'}}>
                                    {x.title + ": "}
                                </label>  
                            }
                            labelPlacement="start"
                        />
                </Col>
            })

        return <Card key={'card_' + section.name} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={'material-icons'} iname={'edit'} />}
                title={section.title}
                subtitle={section.description}
            />
            <CardContent expandable={true}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        {fields}
                    </Row>
                </Container>
            </CardContent>
        </Card>
    }

    getForm() {
        return this.props.state.reseller.component_section
            .map((x) => {
                return this.getSection(x)
            })
    }
    
    render() {
        return [this.getForm()]
    }

    // render() {
        
    //     return this.props.state.reseller.component_section
    //             ? [this.getForm(),this.getSave()]
    //             : <Loading message={'Loading component...'} />
    // }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.reseller,
            reseller: {
                ...state.components.reseller
            },
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResellerView)