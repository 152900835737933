
export const dummyrun = true
export const finalbilling = false
export const comments = null
export const refreshed = false
export const ciscontacts = []
export const suppliercontacts = []
export const maintenanceclass = []
export const selectedciscontacts = null
export const fields = {
    refreshed: {
        errorText: 'Please Refresh Billing.',
        value: null,
        valid: false,
        empty: true,
    },
    checked: {
        errorText: 'Please Confirm Billing Check',
        value: null,
        valid: false,
        empty: true,
    },
}