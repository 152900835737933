import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONET_BILLING_RUN_DATA:
        return {
            ...state,
            billingdata: action.payload
        }
        case types.SET_INVOICE_PDF_DOCUMENT_VIEW:
        return {
            ...state,
            preview: action.payload.url
        }
        case types.SET_INVOICE_SEARCH_TEXT:
        return {
            ...state,
            searchtext: action.payload
        }
        default:
            return state
    }
}