import * as colours from "@mui/material/colors";
import * as config from "../../config";

import Theme from "../../theme";

// export const content = {
//     marginTop: 80,
//     height: '100%',
//     paddingLeft: 25,
//     paddingRight: 25
// }

// export const title = {
//     fontSize: 30,
//     fontWeight: 'bold',
//     marginBottom: 25,
//     display: 'table',
//     width: '100%'
// }

export const content = {
  marginTop: 10,
  height: "100%",
  paddingLeft: 0,
  paddingRight: 0,
};

export const title = {
  fontSize: 30,
  fontWeight: "bold",
  marginBottom: 10,
  display: "table",
  width: "100%",
};

export const title_header = {
  display: "table-cell",
  width: "100%",
};

export const title_buttons = {
  display: "table-cell",
  width: 400,
  textAlign: "right",
};

export const kpi = {
  backgroundColor: "#FFFFFF",
  boxShadow: "0 0 10px 0 rgba(0,0,0,0.05)",
  borderRadius: 2,
  marginBottom: 25,
  display: "none",
  height: 100,
  width: "100%",
  color: "black",
};

export const relatedmenus = {
  backgroundColor: "#FFFFFF",
  boxShadow: "0 0 10px 0 rgba(0,0,0,0.05)",
  borderRadius: 2,
  marginBottom: 25,
  display: "block",
  height: 60,
  width: "100%",
  color: "black",
};
export const kpi_image = (image) => {
    
  return image && image !== null && image.length > 0
    ? {
        display: "table-cell",
        width: 250,
        height: 80,
        background:
          "url(" +
          config.system.cdn +
          (image.charAt(0) === "/" ? image : "/" + image) +
          ") no-repeat",
        backgroundSize: 200,
        backgroundColor: "white",
        opacity: 0.97,
      }
    : {
        display: "table-cell",
        width: 0,
        height: 0,
      };
};

export const kpi_text = {
  width: 300,
  display: "table-cell",
  verticalAlign: "middle",
  fontSize: 20,
  color: "#EB3F31",
  //letterSpacing: 0,
  textAlign: "left",
  //lineHeight: 24
};

export const kpi_separator = {
  display: "table-cell",
  width: 10,
  borderLeft: "6px solid #EB3F31",
};

export const kpi_elements = {
  display: "table-cell",
};

export const kpi_download = {
  display: "table-cell",
  width: 100,
  backgroundColor: "red",
  verticalAlign: "middle",
  textAlign: "center",
  cursor: "pointer",
};

export const icon = {
  fontSize: 30,
  color: "white",
  cursor: "pointer",
};

export const tabs = {
  fontSize: "12px important!",
};

export const view = {
  width: "100%",
  height: "100%",
  padding: 0,
  margin: 0,
};

export const scrollbar = {
  backgroundColor: colours.red[500],
  color: colours.red[500],
};

export const spacer = {
  height: "65px",
  width: "100%",
};

export const dashboard = {
  display: "flex",
};

export const dashboard_menu = {
  width: 300,
};

export const dashboard_split = {
  width: 20,
};

export const dashboard_content = {
  width: "100%",
};

export const headerBackground = (image) => {
  return image && image !== null && image.length > 0
    ? {
        height: "130px",
        background:
          "url(" +
          config.system.cdn +
          (image.charAt(0) === "/" ? image : "/" + image) +
          ") no-repeat",
        backgroundSize: 260,
        backgroundColor: "white",
        opacity: 0.97,
        //borderBottom: '1px solid black',
        //display: 'flex',
        //position: 'fixed'
      }
    : {
        //borderBottom: '1px solid black',
        height: "120px",
      };
};

export const headerContainer = {
  width: "100%",
  height: "100%",
  display: "flex",
  backgroundColor: "white",
  opacity: 0.75,
};

export const headerIcon = {
  width: 40,
  height: 40,
  fontSize: 40,
  margin: "0 auto",
};

export const split = {
  display: "flex",
};

export const menu = {
  width: "275px",
  fontSize: "10px !important",
  padding: "0px",
  margin: "0px",
  //marginLeft: '10px',
  //borderLeft: '1px solid red',
  //borderBottom: '1px solid red',
};

export const data = {
  width: "100%"
  
};

export const headerTitle = {
  fontSize: "34",
  fontWeight: "bold",
  padding: 5,
  marginRight: 20,
  backgroundColor: "white",
  //borderBottom: '1px solid black',
  //borderRight: '1px solid black'
};

export const headerDescription = {
  fontSize: "34",
  fontWeight: "normal",
  padding: 5,
  marginRight: 20,
  backgroundColor: "white",
  //borderLeft: '1px solid black',
  //borderBottom: '1px solid black',
  //borderRight: '1px solid black'
};

/* export const content = {
    width: '100%',
    height: '100%'
}
 */
export const layout = {
  display: "flex",
};

export const related = {
  //width: '300px'
};

export const flex = {
  display: "flex",
};
