
export const tab = 0
export const datetime = ''
export const customer = null
export const contractvehiclecategory = null
export const strratecard = null
export const mmcode = null
export const unit = null
export const rate = null
export const supplier = null
export const vehicle_data = []
export const straccessory_data = []
export const sundry_list = []
export const service_list = []
export const customer_data = []
export const supplier_data = []
export const loadfactor_data = []
export const mmcode_data = []
export const customers = []
export const str_customer_selected = {}
export const ratecategory = null
export const ratecategory_list = []
export const vehicle = null
export const vehicles_list = []
export const contractvehiclecategory_data = []
export const accessory_list = []

export const retailprice = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const costomerref = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const supplier_type = {
    value: null,
    valid: true,
    errorText: 'Required!!',
}
export const vehicleprice = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const discount = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}

export const finance = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const primerate = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}

export const interestrate = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const diviation = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}

export const monthlyinclusive = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}

export const contractperiod = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const startdate = {
    value: null,
    valid: true,
    errorText: 'Required!!',
}
export const enddate = {
    value: null,
    valid: true,
    errorText: 'Required!!',
}
export const excessrates = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const roipercentage = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const roi = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const rentalamount = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const validuntil = {
    value: 30,
    valid: true,
    errorText: 'Required!!',
}
export const quotefor = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const monthlyexc = {
    value: null,
    valid: true,
    errorText: 'Required!!',
}
export const monthlyvat = {
    value: null,
    valid: true,
    errorText: 'Required!!',
}
export const monthlyinc = {
    value: null,
    valid: true,
    errorText: 'Required!!',
}
export const rv = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const rvamount = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const strrate = {
    value: 1,
    valid: true,
    errorText: 'Required!!',
}
export const stramount = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const workingsubtotal = {
    value: null,
    valid: true,
    errorText: 'Required!!',
}
export const workingvat = {
    value: null,
    valid: true,
    errorText: 'Required!!',
}
export const workingrental = {
    value: null,
    valid: true,
    errorText: 'Required!!',
}
export const totalaccessory = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const adminfee = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const licensefee = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const tyres = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const maintenance = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const loadfactorperc = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const loadfactoramount = {
    value: 0,
    valid: true,
    errorText: 'Required!!',
}
export const units = [
    {
        text: 'Months',
        value: 'Monthly'
    },
    {
        text: 'Weeks',
        value: 'Weekly'
    },
    {
        text: 'Days',
        value: 'Daily'
    }
]

export const rates = [
    {
        text: 'KM',
        value: 'Kms'
    },
    {
        text: 'Hour',
        value: 'Hours'
    }
]
