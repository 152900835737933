import React, { Component } from 'react'
import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../Icon/component'
import Grid from '../Grid/component'

class SurrogateGrid extends Component {
    render() {
        return <Card key={'surrogate_grid_card_' + this.props.component.name} initiallyExpanded={true}>
            <CardHeader
                avatar={<Icon iclass={this?.props?.component?.icon?.unicode} iname={this?.props?.component?.icon?.name} />}
                title={this.props.component.title}
                subtitle={this.props.component.description}
                actAsExpander={true}
                showExpandableButton={true}
            />
            <CardContent expandable={true}>
                <Grid
                    key={'readComponent_surrogateGrid_' + this.props.name + this.props.id}
                    id={this.props.id}
                    name={this.props.name}
                    filter={this.props.component.name}
                    stop={this.props.stop}
                    data={this.props.data}
                    surrogate={true}
                />
            </CardContent>
        </Card>
    }
}

export default SurrogateGrid