import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import * as config from "./config"

i18n
  .use(HttpApi)
  .use(initReactI18next)
  // .init({
  //   lng: "en",
  //   fallbackLng: "en",
  //   preload: ["en","ar"],
  //   ns: ["translations"],
  //   defaultNS: "translations",
  //   debug: false,
  //   backend: {
  //       //loadPath:  "https://i18next.s3.eu-west-2.amazonaws.com/locales/{{lng}}/{{ns}}.json"
  //       loadPath: config.system.cdn + "/locales/{{lng}}/{{ns}}.json"
  //   }
  // });

export default i18n;


// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import HttpApi from "i18next-http-backend";

// i18n
//   .use(HttpApi)
//   .use(initReactI18next)
//   .init({
//     lng: "en",
//     fallbackLng: "en",
//     preload: ["en","ar"],
//     ns: ["translations"],
//     defaultNS: "translations",
//     debug: false,
//     backend: {
//       loadPath: "https://d3hm7jpcmtpzbz.cloudfront.net/locales/{{lng}}/{{ns}}.json",
//     }
//   });

// export default i18n;
