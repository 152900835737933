import * as props from './props'
import * as types from './types'
    
export default (state = props, action) => {
    switch (action.type) {
        case types.SET_AMPLIFY_AUTH_STATE:
            return {
                ...state,
                SignedIn: action.payload.state,
                user: action.payload.user
            }
        case types.CREATE_SINGUP_USER_ENTRY:
            return {
                ...state,
                singup: true
            }
        case types.SET_AMPLIFY_AUTH_SESSION:
            return {
                ...state,
                session: action.payload
            }
        default:
            return { ...state }
    }
}