import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getAllVehicles = (search) => {
    return {
        type: types.GET_TERM_VEHICLE_LIST,
        payload: {
            action: 'registration',
            search_text: search
        }
    }
}
export const setVehicleList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.contractnumber ? x.licenseplateno + ' | ' + x.contractnumber + ' | ' + x.contracttype : x.licenseplateno + ' | ' + x.contracttype, 
            value: x.customercontract_id,
        }
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TERM_VEHICLE_LIST,
            payload: {
                data: data,
                component: 'contracttermination',
                field: 'customercontract_id'
            }
        })
    }
}


export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field,
            component: 'contracttermination'
        }
    }
}

export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        const format = (field, input) => {
            const definition = getState().components.contracttermination.component_field.filter(x => x.name === field)[0]

            if (definition) {
                switch (definition.component_field_display_type.name) {
                    case 'datePicker':
                        input = input.toString().trim()
                        const now = moment(new Date())
                        return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
                    case 'timePicker':
                        input = input.toString().trim()
                        return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
                    default:
                        return input
                }
            }
            else {
                return input
            }
        }

        dispatch({
            type: types.SET_ANY_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: format(field, value),
                component: 'contracttermination'
            }
        })
    }
}

export const getVehicleDetails = (id) => {
    return {
        type: types.GET_TERM_VEHICLE_DETAILS,
        payload: {
            id: id,
            action: 'details'
        }
    }
}
export const saveTermination = (data) => {
    return {
        type: types.SAVE_TERMINATION_DATA,
        payload: {
            id: 0,
            data: data,
            action: 'capture'
        }
    }
}
export const savedData = (id) => {
    if (id == 0) {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Something went wrong, your wish could not be granted!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Action Successful!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
            dispatch(getTerminationList(''))
        }
    }
}

export const setVehicleDetails = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TERM_VEHICLE_DETAILS,
            payload: {
                data: payload.data[0]
            }
        })
    }
}
export const getTerminationList = (searchtext) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_TERM_LIST,
            payload: {
                search: searchtext,
                action: 'list'
            }
        })
    }
}

export const setTerminationList = (payload) => {
    var data = payload.data.map((x, i) => {
        return {
            ...x,
            licenseplateno: x.licenseplateno 
        }
    })
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TERM_LIST,
            payload: {
                data: data,
                component: 'contracttermination',
                field: 'contracttermination_id'
            }
        })
    }
}
export const approveTermination = (approve) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_TERM,
            payload: {
                data: approve,
                action: 'approve'
            }
        })
    }
}

export const cancelTermination = (cancel) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CANCEL_TERM,
            payload: {
                data: cancel,
                action: 'cancel'
            }
        })
    }
}

export const approvedTermination = (data) => {
    return (dispatch, getState) => {
        if (data) {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Action Successful!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
            if (data.length) {
                for (var x of data) {
                    dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'Preparing ' + x.type + ' Document...',
                            overflowText: getState().login.fields.email.input,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })
                    if (x.type.includes('Fuel')) {
                        dispatch(getInvoicePDF(x.id))
                    } else {
                        dispatch(getTermInvoicePDF(x.id))
                    }
                }
            }
            dispatch(getTerminationList(''))
        }
    }

}

export const getInvoicePDF = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.GEN_CUSTOMER_INVOICE_PDF,
            payload: {
                type: "GEN_CUSTOMER_INVOICE_PDF",
                id: id
            }
        })
    }
}

export const getTermInvoicePDF = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Generating Document...',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch({
            type: types.GEN_TERMINATION_INVOICE_PDF,
            payload: {
                type: "GEN_TERMINATION_INVOICE_PDF",
                id: id
            }
        })
    }
}
export const setFieldValue = (values, payload) => {
    var data = payload.contracttermination_id.data.map((x, i) => {
        return x.disposal_id === values.id && (values.field == 'generateinvoice') ?
            {
                ...x,
                generateinvoice: values.value,
            } : (x.disposal_id === values.id && values.field == 'invoiceamount') ?
                {
                    ...x,
                    invoiceamount: values.value,
                } : x
    })
    return (dispatch, getState) => {
        if (data != null) {
            dispatch({
                type: types.SET_ANY_VALUE_DATA,
                payload: {
                    data: data,
                    component: 'contracttermination',
                    field: 'contracttermination_id'
                }
            })
        }

    }
}
export const excelExport = (component) => {
    return (dispatch, getState) => {
        const header = [{
            key: 'licenseplateno', 
            name: 'Registration',
            default: ' ',
        }, {
            key: 'contractnumber',
            name: 'Contract Number',
            default: '0',
        }, {
            key: 'contracttype',
            name: 'Contract Type',
            default: ' ',
        }, {
            key: 'startdate',
            name: 'Contract Start Date',
            default: ' ',
        }, {
            key: 'enddate',
            name: 'Contract End Date',
            default: ' ',
        }, {
            key: 'odometer',
            name: 'Termination Odometer',
            default: '0',
        }, {
            key: 'terminationdate',
            name: 'Termination Date',
            default: '',
        }]

        dispatch({
            type: types.GET_EXCEL_GRID_EXPORT,
            payload: {
                type: 'GET_EXCEL_GRID_EXPORT',
                component: component.name,
                data: component.data.contracttermination_id.data,
                header: header
            }
        })
    }
}

export const setTermInvoicePDF = (data) => {
    let link = ''

    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'octet/stream' })
            const url = window.URL.createObjectURL(blob)

            a.href = url
            a.download = name
            a.click()

            window.URL.revokeObjectURL(url)
        }
    }())

    saveByteArray([data.data.pdf.Body], data.data.key)
    return (dispatch, getState) => {
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'Document Generated!',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
    }
}

