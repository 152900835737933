import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import CardControl from './card'
import ReactQuill from 'react-quill'

import * as actions from './actions'
import * as styles from './styles'
import { textAlign } from '../components/QuoteEdit/styles'

class ViewSupplier extends Component {
    componentDidMount() {
        this.props.actions.getComponentSupplierDate(this.props.id,this.props.accessory_id)
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : ''
                    
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }
    
    getLabel(label, value, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                <Typography variant="body1" component="div"
                    style={{  fontWeight: 'bold',fontSize: '14px' }}>
                    {this.getField(value, type)}
                </Typography>
            }
            label={
                <label style={{ width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                    {window.t.en(label)}:
                </label>
            }
            labelPlacement="start"
        />
    }
    getMinLabel(label, value, type = '') {
        return <FormControlLabel
        fullWidth={true}
        control={
            <Typography variant="body1" component="div"
                style={{  fontWeight: 'bold',fontSize: '14px' }}>
                {this.getField(value, type)}
            </Typography>
        }
        label={
            <label style={{ width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                {window.t.en(label)}:
            </label>
        }
        labelPlacement="start"
    />
}

    getView() {
        //console.log('this.data getSupplierView >>>>>>>>>>>>>>',this.data)
        return <div >
            <Container style={{ margin: 0 }} className="custom-container">
            <Row className={"custom_view_text"} >
                    <Col xl={4}>
                        {this.getLabel('Supplier Name', this.data.suppliername)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Supplier Type', this?.data.suppliertype?.suppliertype)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Account Number', this.data.accountnumber)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('VAT Number', this.data.vatnumber)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('BEE Type', this.data.beetype)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('BEE Expiry Date', this.data.beecertificateexpdate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('SLA Expiry Date', this.data.slaexpirydate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Tax Expiry Date', this.data.taxclearancecertificateexpirydate, 'date')}
                    </Col>
                
                </Row> 
                {
                    this.data.supplierage.length
                    ? <Row>
                    <Col xl={12} style={styles.title}>
                        {'Supplier Age'}
                    </Col>
                </Row>
                : ''
                }
                {
                    this.data.supplierage.length
                    ?  <Row className={"custom_view_text"}>
                        <Col xl={4}>
                            {this.getLabel('Monthly Limit', (this.supplier_monthly_spend ? this.supplier_monthly_spend.spendlimit : 0), 'numeric')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Monthly Spent', (this.supplier_monthly_spend ? this.supplier_monthly_spend.curr_usage : 0), 'numeric')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Aged Total', this.data.supplierage[0].agedtotal,'numeric')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Aged Total 10', this.data.supplierage[0].agedtotal0,'numeric')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Aged Total 30', this.data.supplierage[0].agedtotal30,'numeric')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Aged Total 60', this.data.supplierage[0].agedtotal60,'numeric')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Aged Total 90', this.data.supplierage[0].agedtotal90,'numeric')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Aged Total 120', this.data.supplierage[0].agedtotal120,'numeric')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('Aged Total Unallocated', this.data.supplierage[0].agedtotalunallocated,'numeric')}
                        </Col>
                    </Row> 
                    : ''
                }
                <Row>
                    <Col xl={12} style={styles.title}>
                        {'Labour Rate and Discount'}
                    </Col>
                </Row>
                <Row>
                    <Col xl={4}>
                        {this.getLabel('Labour', this.data.labourrateperhour,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Special Labour', this.data.speciallabourrate,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('In Warranty', this.data.inwarrantylabourrate,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Out Of Warranty', this.data.outwarrantylabourrate,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('SLA Rate per Hour', this.data.slarateperhour,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('After Hours Labour', this.data.afterhourslabourrate,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Service Rate', this.data.servicediscount,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Repair Discount', this.data.repairdiscount,'numeric')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Service Discount', this.data.servicediscount,'numeric')}
                    </Col>
                    <Col xl={12} style={styles.title}>
                        {'Contact Details'}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Contact Number', this.data.contactnumber)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Email Address', this.data.contactemail)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Contact Person',  this.data.contactperson)}
                    </Col>
                    <Col xl={12} style={styles.title}>
                        {'Address Details'}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Street Name', this.data.address_line1)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Building Name', this.data.address_line4)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Surburb', this.data.address_suburb)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Town', this.data.address_town)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Postal Code', this.data.address_code)}
                    </Col>
                    <Col xl={12} style={styles.title}>
                        {'Compliance Details'}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Suspended Status', this.data.suspended, 'bool')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('PoPi Compliance', this.data.popi_compliance, 'bool')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Suspended Reason', this.data.suspendedreason)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('VAT Number', this.data.vatnumber)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('BEE Expiry Date', this.data.beecertificateexpdate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('SLA Expiry Date', this.data.slaexpirydate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Tax Expiry Date', this.data.taxclearancecertificateexpirydate, 'date')}
                    </Col>
                    {
                       this.data.history ? this.data.history.map(x => {
                            return <Col xl={4}>
                                {
                                    x.costprice 
                                }
                                </Col>
                        }) : ''
                    } 
                
                </Row> 
            </Container>
         </div> 
    }


    minView() {
        return <div style={{ textAlign: "center" }} >
            <Container style={{ margin: 0 }} className="custom-container">
                <Row >
                    <Col xl={4} >
                        {this.getMinLabel('Supplier Name', this.data.suppliername)}
                    </Col>
                    <Col xl={4}>
                        {this.getMinLabel('Supplier Type', this?.data?.suppliertype?.suppliertype)}
                    </Col>
                    <Col xl={4}>
                        {this.getMinLabel('Account Number', this.data.accountnumber)}
                    </Col>
                    <Col xl={4}>
                        {this.getMinLabel('VAT Number', this.data.vatnumber)}
                    </Col>
                    <Col xl={4}>
                        {this.getMinLabel('BEE Type', this.data.beetype)}
                    </Col>
                    <Col xl={4}>
                        {this.getMinLabel('BEE Expiry Date', this.data.beecertificateexpdate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getMinLabel('SLA Expiry Date', this.data.slaexpirydate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getMinLabel('Tax Expiry Date', this.data.taxclearancecertificateexpirydate, 'date')}
                    </Col>
                
                </Row> 
            </Container>
         </div> 
    }
    
    getSuspended() {
        return <div>
        <Container style={{ margin: 0 }} className="custom-container">
                <Row className={"custom_view_text"}>
                    <Col xl={4}>
                        {this.getLabel('Suspended Status', this.data.suspended, 'bool')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('PoPi Compliance', this.data.popi_compliance, 'bool')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Suspended Reason', this.data.suspendedreason)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('VAT Number', this.data.vatnumber)}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('BEE Expiry Date', this.data.beecertificateexpdate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('SLA Expiry Date', this.data.slaexpirydate, 'date')}
                    </Col>
                    <Col xl={4}>
                        {this.getLabel('Tax Expiry Date', this.data.taxclearancecertificateexpirydate, 'date')}
                    </Col>
                
                </Row> 
            </Container>
        </div> 
    }
    
    getSuspensionDue() {
        let sla = this.data.slaexpirydate ?  moment(this.data.slaexpirydate).diff(moment(), "days") : 0
        let bee = this.data.beecertificateexpdate ?  moment(this.data.beecertificateexpdate).diff(moment(), "days") : 0
        let tax = this.data.taxclearancecertificateexpirydate ?  moment(this.data.taxclearancecertificateexpirydate).diff(moment(), "days") : 0
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                {
                    sla > 0 && sla < 30 && <Row>
                        <Col xl={4}>
                            {this.getLabel('Supplier will be suspended due to SLA in ', sla + ' days')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('SLA Expiry Date', this.data.slaexpirydate, 'date')}
                        </Col>
                    </Row> 
                }
                {
                    bee > 0 && bee < 30 && <Row>
                        <Col xl={4}>
                            {this.getLabel('Supplier will be suspended due SLA in ', bee + ' days')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('SLA Expiry Date', this.data.beecertificateexpdate)}
                        </Col>
                    </Row> 
                }
                {
                    tax > 0 && tax < 30 && <Row>
                        <Col xl={4}>
                            {this.getLabel('Supplier will be suspended due SLA in ', tax + ' days')}
                        </Col>
                        <Col xl={4}>
                            {this.getLabel('SLA Expiry Date', this.data.taxclearancecertificateexpirydate)}
                        </Col>
                    </Row> 
                }
            </Container>
        </div> 
    }

    getSuspensionDue() {
        let sla = this.data.slqexpirydate ?  moment(this.data.slqexpirydate).diff(moment(), "days") : 0
        let bee = this.data.beecertificateexpdate ?  moment(this.data.beecertificateexpdate).diff(moment(), "days") : 0
        let tax = this.data.taxclearancecertificateexpirydate ?  moment(this.data.taxclearancecertificateexpirydate).diff(moment(), "days") : 0

        return <div>
            {
                ((sla > 0 && sla < 30) || (bee > 0 && bee < 30) || (tax > 0 && tax < 30)) && <CardControl 
                    title={'Suspension Due Soon'}
                    subtitle={'Supplier will be suspended with in 30 days due to:'}
                    callapse={true}
                    content={
                        <Container style={{ margin: 0 }} className="custom-container">
                            {
                                sla > 0 && sla < 30 && <Row>
                                    <Col xl={4}>
                                        {this.getLabel('SLA in ', sla + ' days')}
                                    </Col>
                                    <Col xl={4}>
                                        {this.getLabel('SLA Expiry Date', this.data.slqexpirydate, 'date')}
                                    </Col>
                                </Row> 
                            }
                            {
                                bee > 0 && bee < 30 && <Row>
                                    <Col xl={4}>
                                        {this.getLabel('Bee in ', bee + ' days')}
                                    </Col>
                                    <Col xl={4}>
                                        {this.getLabel('Bee Expiry Date', this.data.beecertificateexpdate, 'date')}
                                    </Col>
                                </Row> 
                            }
                            {
                                tax > 0 && tax < 30 && <Row>
                                    <Col xl={4}>
                                        {this.getLabel('Tax in ', tax + ' days')}
                                    </Col>
                                    <Col xl={4}>
                                        {this.getLabel('Tax Expiry Date', this.data.taxclearancecertificateexpirydate, 'date')}
                                    </Col>
                                </Row> 
                            }
                        </Container>
                    }
                />
            }
        </div> 
    }

    getLayout() {
        return <div>
            {/* {this.getSuspensionDue()} */}
            {
                !this.data.suspended || this.data.popi_compliance
                ? <CardControl 
                    title={'Supplier'}
                    subtitle={'Supplier Name: ' + this.data.suppliername}
                    content={this.props.min ? this.minView() : this.getView()}
                    callapse={true}
                />
                : <CardControl error={true}
                title={'Supplier Suspended'}
                subtitle={'Supplier Name: ' + this.data.suppliername}
                content={this.getSuspended()}
                callapse={true}
            />
            }
         </div> 
    }

    render() {
        this.data = null
        try {
            this.data = this.props.state.supplier
            this.supplier_monthly_spend = this.props.state.supplier_monthly_spend[0]
        } catch (error) {
            
        }
        return this.data && this.props.id ? this.getLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSupplier)