import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import * as _ from 'lodash'
import Card from '../../../controls/card'
import AutoComplete from '../../../controls/autocomplete'
import Checkbox from '../../../controls/checkbox'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import "react-table/react-table.css"
import { bindActionCreators } from 'redux'
import Icon from '../../Icon/component'
import * as actions from './actions'
import * as styles from './styles'
import ViewSupplier from '../../../controls/view_supplier'
import Table from '@mui/material/Table'
import Drawer from '@mui/material/Drawer';
import PdfViewer from '../../PDF/component'
import ViewVehicle from '../../../controls/view_vehicle'
import Loading from '../../Loading/component'
import Upload from '../../Upload/component'
let supplier_id = 0
let payment_list = []

class PaymentAllocation extends Component {
    componentDidMount() {
        this.actions = this.props.actions;
        this.props.actions.resetComponent();
        this.props.actions.getPaymentListData()
    }
    getLayout(data) {
        //console.log("getLayout >>>")
        //console.log(data)
        return this.getPaymentHeader(data)
    }
    getSupplierDetails(data) {
        //console.log("datadatadatadatadatadata")
        //console.log(data)
        return <ViewSupplier key={data.step1.data.payment_data[0].supplier_id} id={data.step1.data.payment_data[0].supplier_id} />

    }
    getPaymentDocuments(data) {
        return <Card
            title={'Payment Documents'}
            subtitle={'Payment Supporting Documents'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <PdfViewer
                                key={'quotation_viewer_'}
                                documents={data.documents}
                                //height={600}
                                multiple={false}
                                edit={false}
                            />
                        </Col>
                    </Row>
                </Container>} />

    }
    getPaymentHeader(data) {
        let payments = data
        return <div>
             <Card
                title={'Upload Bank Statement'}
                subtitle={'Please upload the bank statement(s) reflecting these payments'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <Upload
                                    id={'upload_ctl_'}
                                    prefix={'bank_statement_'}
                                    header={false}
                                    list={[{ value: 0, text: 'Supplier Attachment' }, { value: 0, text: 'Bank Statement' }]}
                                />
                        </Col>
                    </Row>
                </Container>} />
             <Card
            title={'Payment Allocation'}
            subtitle={'Payment details'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    {payments && payments.length ?
                        <Row>
                            <div className="table-responsive-md">
                                <Table className="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Supplier</th>
                                            <th>Supplier Account #</th>
                                            <th>Supplier Type</th>
                                            {/* <th>Supplier Email</th>
                                            <th>Bank Name</th>
                                            <th>Account Name</th>
                                            <th>Account #</th> */}
                                            <th>Payment</th>
                                            <th>< Button className={"btn-Accept"} variant="contained"
                                                key={'accept_button'}
                                                primary={true}
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    this.props.actions.setPaymentListData(payments);
                                                    this.props.actions.getUpdateAllList(payments, 'accept')
                                                    //this.props.actions.setFilteredList()
                                                }}
                                            >{window.t.en('ALLOCATE ALL')}</Button>{'    '}< Button className={"btn-danger"} variant="contained"
                                                key={'reject_button'}
                                                primary={true}
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    this.props.actions.setPaymentListData(payments);
                                                    this.props.actions.getUpdateAllList(payments, 'reject')
                                                }}
                                            >{window.t.en('UNALLOCATE ALL')}</Button></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            payments.map((i, inx) => {
                                                return <tr>
                                                    <td>{i.suppliername}</td>
                                                    <td>{i.accountnumber}</td>
                                                    <td>{i.suppliertype}</td>
                                                    {/* <td>{i.contactemail}</td>
                                                    <td>{i.bankname}</td>
                                                    <td>{i.bankaccountname}</td>
                                                    <td>{i.bankaccountnumber}</td> */}
                                                    <td>{parseFloat(i.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td> <div style={styles.column_big(200, 0, 0, 0)}>
                                                        < Button style={
                                                            i.ps == 1 ?
                                                                styles.not_handled :
                                                                i.status == 'Rejected' ? styles.rejected :
                                                                    i.status == 'Approved' ? styles.approved :
                                                                        styles.not_handled
                                                        } variant="contained"
                                                            key={'reject_button'}
                                                            primary={true}
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                this.props.actions.setPaymentListData(payments);
                                                                this.props.actions.toggleItemProps(inx + 1)
                                                            }}
                                                        >{window.t.en(i.ps == 1 || i.status == null ? "View Details" :  i.status == 'Rejected' ? "Un-Allocated" : i.status == 'Approved' ? "Allocated" : i.status)}</Button>
                                                    </div>
                                                        {
                                                            this.props.state.prop_inx === (inx + 1) && <Drawer
                                                                anchor={'right'}
                                                                openSecondary={true}
                                                                open={this.props.state.prop_inx === (inx + 1)}
                                                                onRequestChange={(event) => {
                                                                    event.preventDefault()
                                                                    this.props.actions.toggleItemProps(inx + 1)
                                                                }}>
                                                                <div style={styles.drawer_size}>

                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                    <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                        key={'close_button'}
                                                                        primary={true}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                            this.props.actions.toggleItemProps(inx + 1)
                                                                        }}
                                                                    >{window.t.en('Close')}</Button></Col></Row>

                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                    {this.getDrawerInfo(i, payments)}
                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                    <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                        key={'close_button'}
                                                                        primary={true}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                            this.props.actions.toggleItemProps(inx + 1)
                                                                        }}
                                                                    >{window.t.en('Close')}</Button>{/* {'  |  '}< Button className={"btn-Accept"} variant="contained"
                                                                        key={'accept_button'}
                                                                        primary={true}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                            this.props.actions.setPaymentListData(payments);
                                                                            this.props.actions.getUpdatedList(i, 'accept')
                                                                        }}
                                                                    >{window.t.en('APPROVE PAYMENT')}</Button>{'    '}< Button className={"btn-danger"} variant="contained"
                                                                        key={'reject_button'}
                                                                        primary={true}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                            this.props.actions.setPaymentListData(payments);
                                                                            this.props.actions.getUpdatedList(i, 'reject')
                                                                        }}
                                                                    >{window.t.en('REJECT PAYMENT')}</Button> */}</Col></Row>
                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                </div>
                                                            </Drawer>}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>

                                </Table>
                                <Row><Col xl={12}></Col></Row>
                                <Row>
                                    <Col xl={10}></Col>
                                    <Col xl={2}>< Button className={"btn"} variant="contained"
                                            key={'allocate_button'}
                                            primary={true}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.allocatePayment(payments);
                                                //this.props.actions.getUpdateAllList(payments, 'reject')
                                            }}
                                        >{window.t.en('SUBMIT')}</Button>
                                    </Col>
                                </Row>
                            </div>

                        </Row>
                        : <strong>{'No Data Found. We will keep retrying...'}</strong>}</Container>} />
        </div>
    }
    getSubDrawerInfo(i) {
        return <div>
            <Row>
                <Col xl={2}><div style={styles.fieldTitle}><strong>{window.t.en('Registration Number')}</strong></div></Col>
                <Col xl={2}><div style={styles.fieldTitle}><strong>{window.t.en('Authorisation Number')}</strong></div></Col>
                <Col xl={2}><div style={styles.fieldTitle}><strong>{window.t.en('Authorised Amount')}</strong></div></Col>
                <Col xl={2}><div style={styles.fieldTitle}><strong>{window.t.en('Invoiced Amount')}</strong></div></Col>
                <Col xl={2}><div style={styles.fieldTitle}><strong>{window.t.en('Short Pay')}</strong></div></Col>
                <Col xl={2}><div style={styles.fieldTitle}><strong>{window.t.en('Payment Amount')}</strong></div></Col>
            </Row>
            <Row>
                <Col xl={2}>
                    <div style={styles.column_big(200, 0, 0, 0)}>
                        <TextField
                            id={'statement_amount'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={i.registration}
                            onChange={(event) => {
                            }}
                        />
                    </div>
                </Col>
                <Col xl={2}>
                    <div style={styles.column_big(200, 0, 0, 0)}>
                        <TextField
                            id={'authnumber'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={i.authnumber}
                            onChange={(event) => {
                            }}
                        />
                    </div>
                </Col>
                <Col xl={2}>
                    <div style={styles.column_big(200, 0, 0, 0)}>
                        <TextField
                            id={'payment'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={i.payment ? parseFloat(i.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : i.payment}
                            onChange={(event) => {
                            }}
                        />
                    </div>
                </Col>
                <Col xl={2}>
                    <div style={styles.column_big(200, 0, 1, 0)}>
                        <TextField
                            id={'totalincl'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={i.totalincl ? parseFloat(i.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : i.totalincl}
                            //disabled={true}
                            onChange={(event) => {
                            }}
                        />
                    </div>
                </Col>
                <Col xl={2}>
                    <div style={styles.column_big(200, 1, 0, 0)}>
                        <TextField
                            id={'shortpay'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={i.shortpay ? parseFloat(i.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : i.shortpay}
                            onChange={(event) => {
                            }}
                        />
                    </div>
                </Col>
                <Col xl={2}>
                    <div style={styles.column_big(200, 0, 0, 1)}>
                        <TextField
                            id={'totalpayment'}
                            fullWidth={true}
                            multiLine={false}
                            variant="outlined"
                            value={i.totalpayment ? parseFloat(i.totalpayment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : i.totalpayment}
                            //disabled={true}
                            onChange={(event) => {
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row><Col xl={2}><br></br></Col></Row>
            <ViewVehicle key={i.vehicle_id} id={i.vehicle_id} />
            <Card
                title={'Payment Documents'}
                subtitle={'Payment Supporting Documents'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row><Col xl={2}><br></br></Col></Row>
                    <Row>
                        <Col xl={12}>
                            {i.documents.length ?
                                <PdfViewer
                                    id={'document_viewer_main_history_sub_'}
                                    key={'document_viewer_main_history_sub_'}
                                    name={'purchaseorder_grid_sub_'}
                                    documents={i.documents}
                                    edit={false}
                                    multiple={false}
                                    workflow_queue_data={null}
                                /> : 'No Documents Found...'
                            }
                        </Col>
                    </Row>
                </Container>} />
            <Card
                title={'Comments'}
                subtitle={'Comments.'}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row><Col xl={12}><br></br>
                            <TextField
                                fullWidth={true}
                                label='Capture comments...'
                                value={i.manager_comments}
                                variant="outlined"
                                onChange={(event) => {
                                }}
                                onBlur={(event) => {
                                    let value = event.target.value
                                    i.manager_comments = value
                                    this.props.actions.getUpdatedList(i, i.rejected ? 'reject' : 'accept')
                                }}
                            />
                        </Col></Row>
                    </Container>} />
        </div>
    }
    getPaymentRequest(data) {
        return <div><Card
            title={'Request Comments'}
            subtitle={'Payment request comments'}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{window.t.en('Requested By')}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.step2.user.firstname + ' ' + data.step2.user.lastname}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{window.t.en('Date Requested')}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.step2.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.step2.user.email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.step2.user.phone}</Col>
                </Row>
                <Row><Col xl={2}><br></br></Col></Row>
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            label={'No Comments...'}
                            disabled={true}
                            fullWidth={true}
                            variant="outlined"
                            multiLine={false}
                            value={data.step2.data.comments}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
        </div>
    }
    getPaymentReview(data) {
        return <div><Card
            title={'Review Comments'}
            subtitle={'Payment review comments'}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{window.t.en('Reviewed By')}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.step3.user.firstname + ' ' + data.step3.user.lastname}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{window.t.en('Date Reviewed')}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{moment(data.step3.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                </Row>
                <Row>
                    <Col xl={3} style={styles.fieldLabel}>{'Email:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.step3.user.email}</Col>
                    <Col xl={3} style={styles.fieldLabel}>{'Phone:'}</Col>
                    <Col xl={3} style={styles.selectedFieldName}>{data.step3.user.phone}</Col>
                </Row>
                <Row><Col xl={2}><br></br></Col></Row>
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txtcomment'}
                            label={'No Comments...'}
                            disabled={true}
                            fullWidth={true}
                            variant="outlined"
                            multiLine={false}
                            value={data.step3.data.comments}
                            onChange={(event, value) => {
                            }}
                        />
                    </Col>
                </Row>
            </Container>} />
        </div>
    }
    getExceptions(data) {
        let exceptions = data.filter(x => x.has_exceptions == true)
        return <Card
            title={(exceptions.length ? '' : 'No ') + 'Payment Exceptions (Recurrence)'}
            subtitle={'Payment with exceptions'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <div className="table-responsive-md">
                            <Table className="table table-hover text-nowrap mb-0">

                                <thead>
                                    <tr>
                                        <th>Auth #</th>
                                        <th>Reg #</th>
                                        <th>Description</th>
                                        <th>Invoice #</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice Amount</th>
                                        <th>Auth Amount</th>
                                        <th>Short Pay</th>
                                        <th>Payment</th>
                                        <th>< Button className={"btn-Accept"} variant="contained"
                                            key={'accept_button'}
                                            primary={true}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.getUpdateAllList(data, 'accept')
                                            }}
                                        >{window.t.en('APPROVE ALL')}</Button>{'    '}< Button className={"btn-danger"} variant="contained"
                                            key={'reject_button'}
                                            primary={true}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.getUpdateAllList(data, 'reject')
                                            }}
                                        >{window.t.en('REJECT ALL')}</Button></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {exceptions && exceptions.length
                                        ? exceptions.map((i, inx) => {
                                            return <tr>
                                                <td>{i.authnumber}</td>
                                                <td>{i.registration}</td>
                                                <td>{i.description}</td>
                                                <td>{i.reference}</td>
                                                <td>{moment(i.date).format('YYYY-MM-DD')}</td>
                                                <td>{parseFloat(i.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{parseFloat(i.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>
                                                    <div style={styles.column_big(150, Number(i.shortpay), 0, 0)}>
                                                        <TextField
                                                            fullWidth={false}
                                                            hintText='Shortpay'
                                                            value={parseFloat(i.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            variant="outlined"
                                                            onChange={(event) => {
                                                            }}
                                                            onBlur={(event) => {
                                                                let data = {
                                                                    id: i.suppliertransaction_id,
                                                                    parent_id: i.supplier_id,
                                                                    type: i.dataset,
                                                                    value: i.shortpay,
                                                                    column: 'shortpay',
                                                                    search_text: this.props.state.search_text
                                                                }
                                                                this.props.actions.getUpdatePaymentList(data)

                                                            }}
                                                        /></div>
                                                </td>
                                                <td>
                                                    <div style={styles.column_big(150, 0, i.rejected ? 0 : Number(i.totalpayment), i.rejected ? Number(i.totalpayment) : 0)}>
                                                        <TextField
                                                            fullWidth={false}
                                                            value={i.totalpayment ? parseFloat(i.totalpayment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : i.totalpayment}
                                                            variant="outlined"
                                                            onChange={(event) => { }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={styles.column_big(200, 0, 0, 0)}>
                                                        {/* <Tooltip TransitionComponent={Zoom} title="View Details">
                                                            <Button className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    this.props.actions.toggleItemProps(inx + 1)

                                                                }}>
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                    className="font-size-sm"
                                                                />
                                                            </Button>
                                                        </Tooltip> */}
                                                        < Button style={
                                                            i.ps == 1 ?
                                                                styles.not_handled :
                                                                i.status == 'Rejected' ? styles.rejected :
                                                                    i.status == 'Approved' ? styles.approved :
                                                                        styles.not_handled
                                                        } variant="contained"
                                                            key={'reject_button'}
                                                            primary={true}
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                this.props.actions.toggleItemProps(inx + 1)
                                                            }}
                                                        >{window.t.en(i.ps == 1 || i.status == null ? "Not Handled" : i.status)}</Button>
                                                    </div>
                                                    {
                                                        this.props.state.prop_inx === (inx + 1) && <Drawer
                                                            anchor={'right'}
                                                            openSecondary={true}
                                                            open={this.props.state.prop_inx === (inx + 1)}
                                                            onRequestChange={(event) => {
                                                                event.preventDefault()
                                                                this.props.actions.toggleItemProps(inx + 1)
                                                            }}>
                                                            <div style={styles.drawer_size}>

                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                    key={'close_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.toggleItemProps(inx + 1)
                                                                    }}
                                                                >{window.t.en('Close')}</Button></Col></Row>

                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                {this.getDrawerInfo(i)}
                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                    key={'close_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.toggleItemProps(inx + 1)
                                                                    }}
                                                                >{window.t.en('Close')}</Button>{'  |  '}<Button style={styles.task_button} variant="contained"
                                                                    key={'task_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.toggleItemProps(inx + 1)
                                                                    }}
                                                                >{window.t.en('Task #')}</Button>{'    '}< Button className={"btn-Accept"} variant="contained"
                                                                    key={'accept_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.getUpdatedList(i, 'accept')
                                                                    }}
                                                                >{window.t.en('APPROVE PAYMENT')}</Button>{'    '}< Button className={"btn-danger"} variant="contained"
                                                                    key={'reject_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.getUpdatedList(i, 'reject')
                                                                    }}
                                                                >{window.t.en('REJECT PAYMENT')}</Button></Col></Row>
                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                            </div>


                                                        </Drawer>}
                                                </td>
                                            </tr>
                                        }) : 'No records found...'
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Row>
                </Container>} />
    }
    getAuthExceptions(data) {
        let authexceptions = data.filter(x => x.totalincl > x.authamount)
        return <Card
            title={(authexceptions.length ? '' : 'No ') + 'Payment Exceptions (Auth vs Invoiced Amount)'}
            subtitle={'Payment with exceptions'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <div className="table-responsive-md">
                            <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Auth #</th>
                                        <th>Reg #</th>
                                        <th>Description</th>
                                        <th>Invoice #</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice Amount</th>
                                        <th>Auth Amount</th>
                                        <th>Short Pay</th>
                                        <th>Payment</th>
                                        <th>< Button className={"btn-Accept"} variant="contained"
                                            key={'accept_button'}
                                            primary={true}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.getUpdateAllList(data, 'accept')
                                            }}
                                        >{window.t.en('APPROVE ALL')}</Button>{'    '}< Button className={"btn-danger"} variant="contained"
                                            key={'reject_button'}
                                            primary={true}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.getUpdateAllList(data, 'reject')
                                            }}
                                        >{window.t.en('REJECT ALL')}</Button></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {authexceptions && authexceptions.length
                                        ? authexceptions.map((i, inx) => {
                                            return <tr>
                                                <td>{i.authnumber}</td>
                                                <td>{i.registration}</td>
                                                <td>{i.description}</td>
                                                <td>{i.reference}</td>
                                                <td>{moment(i.date).format('YYYY-MM-DD')}</td>
                                                <td>{parseFloat(i.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{parseFloat(i.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>
                                                    <div style={styles.column_big(150, Number(i.shortpay), 0, 0)}>
                                                        <TextField
                                                            fullWidth={false}
                                                            hintText='Shortpay'
                                                            value={parseFloat(i.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            variant="outlined"
                                                            onChange={(event) => {
                                                            }}
                                                            onBlur={(event) => {
                                                                let data = {
                                                                    id: i.suppliertransaction_id,
                                                                    parent_id: i.supplier_id,
                                                                    type: i.dataset,
                                                                    value: i.shortpay,
                                                                    column: 'shortpay',
                                                                    search_text: this.props.state.search_text
                                                                }
                                                                this.props.actions.getUpdatePaymentList(data)

                                                            }}
                                                        /></div>
                                                </td>
                                                <td>
                                                    <div style={styles.column_big(150, 0, i.rejected ? 0 : Number(i.totalpayment), i.rejected ? Number(i.totalpayment) : 0)}>
                                                        <TextField
                                                            fullWidth={false}
                                                            value={i.totalpayment ? parseFloat(i.totalpayment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : i.totalpayment}
                                                            variant="outlined"
                                                            onChange={(event) => { }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={styles.column_big(200, 0, 0, 0)}>
                                                        {/* <Tooltip TransitionComponent={Zoom} title="View Details">
                                                            <Button className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    this.props.actions.toggleItemProps(inx + 1)

                                                                }}>
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                    className="font-size-sm"
                                                                />
                                                            </Button>
                                                        </Tooltip> */}
                                                        < Button style={
                                                            i.ps == 1 ?
                                                                styles.not_handled :
                                                                i.status == 'Rejected' ? styles.rejected :
                                                                    i.status == 'Approved' ? styles.approved :
                                                                        styles.not_handled
                                                        } variant="contained"
                                                            key={'reject_button'}
                                                            primary={true}
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                this.props.actions.toggleItemProps(inx + 1)
                                                            }}
                                                        >{window.t.en(i.ps == 1 || i.status == null ? "Not Handled" : i.status)}</Button>
                                                    </div>
                                                    {
                                                        this.props.state.prop_inx === (inx + 1) && <Drawer
                                                            anchor={'right'}
                                                            openSecondary={true}
                                                            open={this.props.state.prop_inx === (inx + 1)}
                                                            onRequestChange={(event) => {
                                                                event.preventDefault()
                                                                this.props.actions.toggleItemProps(inx + 1)
                                                            }}>
                                                            <div style={styles.drawer_size}>

                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                    key={'close_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.toggleItemProps(inx + 1)
                                                                    }}
                                                                >{window.t.en('Close')}</Button></Col></Row>

                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                {this.getDrawerInfo(i)}
                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                    key={'close_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.toggleItemProps(inx + 1)
                                                                    }}
                                                                >{window.t.en('Close')}</Button>{'  |  '}<Button style={styles.task_button} variant="contained"
                                                                    key={'task_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.toggleItemProps(inx + 1)
                                                                    }}
                                                                >{window.t.en('Task #')}</Button>{'    '}< Button className={"btn-Accept"} variant="contained"
                                                                    key={'accept_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.getUpdatedList(i, 'accept')
                                                                    }}
                                                                >{window.t.en('APPROVE PAYMENT')}</Button>{'    '}< Button className={"btn-danger"} variant="contained"
                                                                    key={'reject_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.getUpdatedList(i, 'reject')
                                                                    }}
                                                                >{window.t.en('REJECT PAYMENT')}</Button></Col></Row>
                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                            </div>


                                                        </Drawer>}
                                                </td>
                                            </tr>
                                        }) : 'No records found...'
                                    }
                                </tbody>
                            </Table>
                        </div>

                    </Row>
                </Container>} />


    }
    getSortedTransactions() {
        return <Card
            title={'Transactions Analysis'}
            subtitle={'Payment with sorting order'}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2}><br></br></Col></Row>
                    <Row>
                        <Col xl={2}>
                            <AutoComplete
                            name={'autocomplete_purchase_order_'}

                            title={window.t.en('Greater/Less Than...')}
                            info={{
                                show: false
                            }}
                            fullWidth={true}
                            data={[
                                { text: "Greater Than", value: 'greater' },
                                { text: "Less Than", value: 'lesser' }
                            ]}
                            onSelect={(args) => {
                                this.props.actions.updateFilterValue("greaterOrLessThan", args.value)
                            }}
                            onClick={() => {
                            }
                            }
                            onFilter={(args) => {
                            }
                            } /></Col>
                        <Col xl={2}><AutoComplete
                            name={'autocomplete_purchase_order_'}
                            title={window.t.en('Highest/Lowest...')}
                            info={{
                                show: false
                            }}
                            fullWidth={true}
                            data={[{
                                text: "Highest to Lowest",
                                value: 'highest'
                            }, {
                                text: "Lowest to Highest",
                                value: 'lowest'
                            }
                            ]}
                            //displayfields={['suppliername', 'accountnumber', 'suppliertype_fktext']}
                            //displaywidth={['100', '50', '50']}
                            onSelect={(args) => {
                                this.props.actions.updateFilterValue("highestOrLowest", args.value)
                            }}
                            onClick={() => {
                                //this.props.actions.getSupplierAutoCompleteMenuItems('')
                            }
                            }
                            onFilter={(args) => {
                                //this.props.actions.setSupplierAutoCompleteSelected({ text: args, value: null })
                                //this.props.actions.getSupplierAutoCompleteMenuItems(args)
                            }
                            } /></Col>
                        <Col xl={2}><Checkbox
                            id={'dontpay'}
                            key={'dontpay'}
                            name={'dontpay'}
                            field={'dontpay'}
                            labelPlacement={'end'}
                            label={window.t.en('Invoiced Amount Greater Than Authorised Amount?')}
                            checked={this.props.state.invoicedGreaterThanAuth}
                            onCheck={(checked) => {
                                this.props.actions.updateFilterValue("invoicedGreaterThanAuth", this.props.state.invoicedGreaterThanAuth ? false : true)

                            }}
                        /></Col>
                        <Col xl={2}></Col>
                        <Col xl={2}></Col>
                        <Col xl={2}><div style={styles.fieldTitle}></div></Col>
                    </Row>
                    <Row>
                        <Col xl={2}><br></br></Col></Row>
                    <Row>
                        <Col xl={2}>
                            <TextField
                                id={'statement_amount'}
                                fullWidth={true}
                                label={window.t.en('Enter amount...')}
                                multiLine={false}
                                variant="outlined"
                                value={this.props.state.greaterOrLessThanAmount}
                                onChange={(event) => {
                                    this.props.actions.updateFilterValue("greaterOrLessThanAmount", event.target.value)
                                }}
                            />
                        </Col>
                        <Col xl={2}>
                            <TextField
                                id={'statement_amount'}
                                fullWidth={true}
                                label={window.t.en('Enter value - Top(x)...')}
                                multiLine={false}
                                variant="outlined"
                                value={this.props.state.highestOrLowestAmount}
                                onChange={(event) => {
                                    this.props.actions.updateFilterValue("highestOrLowestAmount", event.target.value)
                                }}
                            />
                        </Col>
                        <Col xl={2}>
                            <Checkbox
                                id={'dontpay'}
                                key={'dontpay'}
                                name={'dontpay'}
                                field={'dontpay'}
                                labelPlacement={'end'}
                                label={window.t.en('More Than One Payments')}
                                checked={this.props.state.vehicleReoccurence}
                                onCheck={(checked) => {
                                    this.props.actions.updateFilterValue("vehicleReoccurence", this.props.state.vehicleReoccurence ? false : true)

                                }}
                            />
                        </Col>
                        <Col xl={2}>
                            <Checkbox
                                id={'dontpay'}
                                key={'dontpay'}
                                name={'dontpay'}
                                field={'dontpay'}
                                labelPlacement={'end'}
                                label={window.t.en('Short Payments')}
                                checked={this.props.state.shortpayments}
                                onCheck={(checked) => {
                                    this.props.actions.updateFilterValue("shortpayments", this.props.state.shortpayments ? false : true)

                                }}
                            />
                        </Col>
                        <Col xl={2}>
                            <TextField
                                id={'statement_amount'}
                                fullWidth={true}
                                multiLine={false}
                                label={window.t.en('Search by Reg, Auth Number...')}
                                variant="outlined"
                                value={this.props.state.searchValue}
                                onChange={(event) => {
                                    this.props.actions.updateFilterValue("searchValue", event.target.value)
                                }}
                            />
                        </Col>

                        <Col xl={2}>
                            < Button className={"global_button"} variant="contained"
                                key={'refreshReports'}
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.setFilteredList()
                                }}
                            >{window.t.en('Apply Changes')}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={2}><br></br></Col></Row>
                    {this.props.state.payment_list_filtered && this.props.state.payment_list_filtered.length ?
                        <Row>
                            <Col xl={12}>
                            <div className="table-responsive-md">
                                <Table className="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Auth #</th>
                                            <th>Reg #</th>
                                            <th>Description</th>
                                            <th>Invoice #</th>
                                            <th>Invoice Date</th>
                                            <th>Invoice Amount</th>
                                            <th>Auth Amount</th>
                                            <th>Short Pay</th>
                                            <th>Payment</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.state.payment_list_filtered && this.props.state.payment_list_filtered.length
                                            ? this.props.state.payment_list_filtered.map((i, inx) => {
                                                return <tr>
                                                    <td>{i.authnumber}</td>
                                                    <td>{i.registration}</td>
                                                    <td>{i.description}</td>
                                                    <td>{i.reference}</td>
                                                    <td>{moment(i.date).format('YYYY-MM-DD')}</td>
                                                    <td>{parseFloat(i.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>{parseFloat(i.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>
                                                        <div style={styles.column_big(150, Number(i.shortpay), 0, 0)}>
                                                            <TextField
                                                                fullWidth={false}
                                                                hintText='Shortpay'
                                                                value={parseFloat(i.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                variant="outlined"
                                                                onChange={(event) => {
                                                                }}
                                                                onBlur={(event) => {
                                                                    let data = {
                                                                        id: i.suppliertransaction_id,
                                                                        parent_id: i.supplier_id,
                                                                        type: i.dataset,
                                                                        value: i.shortpay,
                                                                        column: 'shortpay',
                                                                        search_text: this.props.state.search_text
                                                                    }
                                                                    this.props.actions.getUpdatePaymentList(data)

                                                                }}
                                                            /></div>
                                                    </td>
                                                    <td>
                                                        <div style={styles.column_big(150, 0, i.rejected ? 0 : Number(i.totalpayment), i.rejected ? Number(i.totalpayment) : 0)}>
                                                            <TextField
                                                                fullWidth={false}
                                                                value={i.totalpayment ? parseFloat(i.totalpayment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : i.totalpayment}
                                                                variant="outlined"
                                                                onChange={(event) => { }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={styles.column_big(200, 0, 0, 0)}>
                                                            {/* <Tooltip TransitionComponent={Zoom} title="View Details">
                                                            <Button className="btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    this.props.actions.toggleItemProps(inx + 1)

                                                                }}>
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                    className="font-size-sm"
                                                                />
                                                            </Button>
                                                        </Tooltip> */}
                                                            < Button style={
                                                                i.ps == 1 ?
                                                                    styles.not_handled :
                                                                    i.status == 'Rejected' ? styles.rejected :
                                                                        i.status == 'Approved' ? styles.approved :
                                                                            styles.not_handled
                                                            } variant="contained"
                                                                key={'reject_button'}
                                                                primary={true}
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    this.props.actions.toggleItemProps(inx + 1)
                                                                }}
                                                            >{window.t.en(i.ps == 1 || i.status == null ? "Not Handled" : i.status)}</Button>
                                                        </div>
                                                        {
                                                            this.props.state.prop_inx === (inx + 1) && <Drawer
                                                                anchor={'right'}
                                                                openSecondary={true}
                                                                open={this.props.state.prop_inx === (inx + 1)}
                                                                onRequestChange={(event) => {
                                                                    event.preventDefault()
                                                                    this.props.actions.toggleItemProps(inx + 1)
                                                                }}>
                                                                <div style={styles.drawer_size}>

                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                    <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                        key={'close_button'}
                                                                        primary={true}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                            this.props.actions.toggleItemProps(inx + 1)
                                                                        }}
                                                                    >{window.t.en('Close')}</Button></Col></Row>

                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                    {this.getDrawerInfo(i)}
                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                    <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                        key={'close_button'}
                                                                        primary={true}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                            this.props.actions.toggleItemProps(inx + 1)
                                                                        }}
                                                                    >{window.t.en('Close')}</Button>{'  |  '}<Button style={styles.task_button} variant="contained"
                                                                        key={'task_button'}
                                                                        primary={true}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                            this.props.actions.toggleItemProps(inx + 1)
                                                                        }}
                                                                    >{window.t.en('Task #')}</Button>{'    '}< Button className={"btn-Accept"} variant="contained"
                                                                        key={'accept_button'}
                                                                        primary={true}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                            this.props.actions.getUpdatedList(i, 'accept')
                                                                        }}
                                                                    >{window.t.en('APPROVE PAYMENT')}</Button>{'    '}< Button className={"btn-danger"} variant="contained"
                                                                        key={'reject_button'}
                                                                        primary={true}
                                                                        onClick={(event) => {
                                                                            event.preventDefault()
                                                                            this.props.actions.getUpdatedList(i, 'reject')
                                                                        }}
                                                                    >{window.t.en('REJECT PAYMENT')}</Button></Col></Row>
                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                    <Row><Col xl={2}><br></br></Col></Row>
                                                                </div>


                                                            </Drawer>}
                                                    </td>
                                                </tr>
                                            }) : 'No records found...'
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            </Col>
                        </Row>
                        : ''}
                </ Container>} />
    }
    getDrawerInfo(i, data) {
        return <div>
            <Card
                title={'Creditors Comments'}
                subtitle={'Creditors admin comments'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row><Col xl={12}><br></br>
                        <TextField
                            fullWidth={true}
                            label='No Comments...'
                            value={i.comments}
                            variant="outlined"
                            onChange={(event) => {
                            }}
                            onBlur={(event) => {
                            }}
                        />
                    </Col></Row>
                </Container>} />
            <Card
                title={'Technical Manager Comments'}
                subtitle={'Technical Manager Approval comments'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row><Col xl={12}><br></br>
                        <TextField
                            fullWidth={true}
                            label='No Comments...'
                            value={i.manager_comments}
                            variant="outlined"
                            onChange={(event) => {
                            }}
                            onBlur={(event) => {
                            }}
                        />
                    </Col></Row>
                </Container>} />
            <Card
                title={'GM Operations Comments'}
                subtitle={'GM Operations Approval comments'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row><Col xl={12}><br></br>
                        <TextField
                            fullWidth={true}
                            label='No Comments...'
                            value={i.ops_comments}
                            variant="outlined"
                            onChange={(event) => {
                            }}
                            onBlur={(event) => {
                            }}
                        />
                    </Col></Row>
                </Container>} />
            <Card
                title={'Finance Review Comments'}
                subtitle={'Finance review comments'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row><Col xl={12}><br></br>
                        <TextField
                            fullWidth={true}
                            label='No Comments...'
                            value={i.finance_comments}
                            variant="outlined"
                            onChange={(event) => {
                            }}
                            onBlur={(event) => {
                            }}
                        />
                    </Col></Row>
                </Container>} />
            <Card
                title={'Payment Documents - ' + i.suppliername}
                subtitle={i.suppliertype}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row><Col xl={2}><br></br></Col></Row>
                    <Row>
                        <Col xl={12}>
                            <PdfViewer
                                id={'document_viewer_main_history_'}
                                key={'document_viewer_main_history_'}
                                name={'purchaseorder_grid_'}
                                documents={
                                    i.statement ?  [
                                        {
                                            filename: i.recon,
                                            filetype: 'Recon',
                                            description: 'Recon'
                                        },
                                        {
                                            filename: i.requisition,
                                            filetype: 'Requisition',
                                            description: 'Requisition'
                                        },
                                        {
                                            filename: i.remittance,
                                            filetype: 'Remittance',
                                            description: 'Remittance'
                                        },
                                        {
                                            filename: i.statement,
                                            filetype: 'Statement',
                                            description: 'Statement'
                                        }
                                    ] : [
                                        {
                                            filename: i.recon,
                                            filetype: 'Recon',
                                            description: 'Recon'
                                        },
                                        {
                                            filename: i.requisition,
                                            filetype: 'Requisition',
                                            description: 'Requisition'
                                        },
                                        {
                                            filename: i.remittance,
                                            filetype: 'Remittance',
                                            description: 'Remittance'
                                        }
                                    ]
                                }
                                edit={false}
                                multiple={false}
                                workflow_queue_data={null}
                            />
                        </Col>
                    </Row>
                </Container>} />
            <Card
                title={'Payment Transactions (' + i.items.length + ' Transactions Found)'}
                subtitle={'Transaction Details'}
                content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <div className="table-responsive-md">
                            <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Auth #</th>
                                        <th>Reg #</th>
                                        <th>Description</th>
                                        <th>Invoice #</th>
                                        <th>Invoice Date</th>
                                        {/* <th>Invoice Amount</th>
                                        <th>Auth Amount</th> */}
                                        <th>Short Pay</th>
                                        <th>Payment</th>
                                        <th>< Button className={"btn-Accept"} variant="contained"
                                            key={'accept_button'}
                                            primary={true}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.getSubUpdateAllList(i, 'accept')
                                                //this.props.actions.setFilteredList()
                                            }}
                                        >{window.t.en('APPROVE ALL')}</Button>{'    '}< Button className={"btn-danger"} variant="contained"
                                            key={'reject_button'}
                                            primary={true}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.getSubUpdateAllList(i, 'reject')
                                                //this.props.actions.setFilteredList()
                                            }}
                                        >{window.t.en('REJECT ALL')}</Button></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {i.items
                                        ? i.items.map((d, inx) => {
                                            return <tr>
                                                <td>{d.authnumber}</td>
                                                <td>{d.registration}</td>
                                                <td>{d.description}</td>
                                                <td>{d.reference}</td>
                                                <td>{moment(d.date).format('YYYY-MM-DD')}</td>
                                                {/* <td>{d.totalincl ? parseFloat(d.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}</td>
                                                <td>{d.totalincl ? parseFloat(d.totalincl.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}</td>
                                                 */}<td>
                                                    <div style={styles.column_big(150, Number(i.shortpay), 0, 0)}>
                                                        <TextField
                                                            fullWidth={false}
                                                            disabled={true}
                                                            hintText='Shortpay'
                                                            value={d.shortpay ? parseFloat(d.shortpay.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}
                                                            variant="outlined"
                                                            onChange={(event) => {
                                                            }}
                                                            onBlur={(event) => {
                                                            }}
                                                        /></div>
                                                </td>
                                                <td>
                                                    <div style={styles.column_big(150, 0, d.rejected ? 0 : Number(d.totalpayment), d.rejected ? Number(d.totalpayment) : 0)}>
                                                        <TextField
                                                            fullWidth={false}
                                                            value={d.payment ? parseFloat(d.payment.toString().replace(/,/g, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00}
                                                            variant="outlined"
                                                            onChange={(event) => { }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={styles.column_big(200, 0, 0, 0)}>
                                                        < Button style={
                                                            d.ps == 1 ?
                                                                styles.not_handled :
                                                                d.status == 'Rejected' ? styles.rejected :
                                                                    d.status == 'Approved' ? styles.approved :
                                                                        styles.not_handled
                                                        } variant="contained"
                                                            key={'reject_button'}
                                                            primary={true}
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                this.props.actions.setPaymentListData(data);
                                                                this.props.actions.subtoggleItemProps(inx + 1)
                                                            }}
                                                        >{window.t.en((d.status == 'Rejected' || d.status == 'Approved' ? d.status : "View Details"))}</Button>
                                                    </div>
                                                    {
                                                        this.props.state.sub_prop_inx === (inx + 1) && <Drawer
                                                            anchor={'right'}
                                                            openSecondary={true}
                                                            open={this.props.state.sub_prop_inx === (inx + 1)}
                                                            onRequestChange={(event) => {
                                                                event.preventDefault()
                                                                this.props.actions.subtoggleItemProps(inx + 1)
                                                            }}>
                                                            <div style={styles.drawer_size}>

                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                    key={'close_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.subtoggleItemProps(inx + 1)
                                                                    }}
                                                                >{window.t.en('Close')}</Button></Col></Row>

                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                {this.getSubDrawerInfo(d)}
                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                <Row><Col xl={12}>< Button className={"global_button"} variant="contained"
                                                                    key={'close_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.subtoggleItemProps(inx + 1)
                                                                    }}
                                                                > {window.t.en('Close')}</Button>{'  |  '}< Button className={"btn-Accept"} variant="contained"
                                                                    key={'accept_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.getSubUpdatedList(d, 'accept')
                                                                    }}
                                                                >{window.t.en('APPROVE PAYMENT')}</Button>{'    '}< Button className={"btn-danger"} variant="contained"
                                                                    key={'reject_button'}
                                                                    primary={true}
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        this.props.actions.getSubUpdatedList(d, 'reject')
                                                                    }}
                                                                >{window.t.en('REJECT PAYMENT')}</Button></Col></Row>
                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                                <Row><Col xl={2}><br></br></Col></Row>
                                                            </div>
                                                        </Drawer>}
                                                </td>
                                            </tr>
                                        }) : 'No records found...'
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Row>

                </Container>} />
            <Card
                title={'Comments'}
                subtitle={'Comments.'}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row><Col xl={12}><br></br>
                            <TextField
                                fullWidth={true}
                                label='Capture comments...'
                                value={i.financemanager_comments}
                                variant="outlined"
                                onChange={(event) => {
                                }}
                                onBlur={(event) => {
                                    let value = event.target.value
                                    i.financemanager_comments = value
                                    this.props.actions.getUpdatedList(i, i.rejected ? 'reject' : 'accept')
                                }}
                            />
                        </Col></Row>
                    </Container>} />
        </div>
    }
    renderView(data) {
        return this.getLayout(data)
    }
    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.state.payment_list

        } catch (error) { }
        return (workflow_queue_data ? this.renderView(workflow_queue_data) : window.t.en('Please try again, contact system administrator if this persist'))
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.paymentAllocation,
            user: {
                ...state.user
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAllocation)