import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.GET_COMPONENT_TILE_DATA:
            return {
                ...state,
                loading: true
            }
        case types.SET_COMPONENT_TILE_DATA:
            return {
                ...state,
                loading: false,
                paging: {
                    ...state.paging,
                    page: action.payload.data.paging.page,
                    pageCount: action.payload.data.paging.pageCount,
                    pageSize: action.payload.data.paging.pageSize,
                    rowCount: action.payload.data.paging.rowCount,
                },
                data: action.payload.data.data
            }
        default:
            return state
    }
}