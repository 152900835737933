export const vehicle = null
export const description = ''
export const supplier = ''
export const financialinstitution = ''

export const comments = ''
export const receiptdate = ''
export const poddate = ''
export const pocomments = ''
export const instruction = ''

export const supplier_id = 0
export const financialinstitution_id = 0

export const branch_data = []
export const dealer_data = []
export const supplier_data = []
export const financialinstitution_data = []


export const fields = {
  quotationStep: {
    name: 'Quote Form',
    errorText: 'Please accept the quote',
    state: 'getState().workflowViewFMLQuoteConfirm?.vehicle?.report_data',
    data: false,
    valid: false,
    empty: true,
    section: 'Quote Form',
    popup: true
  },
  vrDate: {
    id: 'txtvrddate',
    name: 'txtvrddate',
    errorText: 'Please select Vehicle Date Required: Must be in the future',
    state: 'getState().workflowViewFMLQuoteConfirm.receiptdate',
    data: false,
    valid: false,
    empty: true,
    section: 'Quote Form'
  },
  poDate: {
    id: 'txtpoddate',
    name: 'txtpoddate',
    errorText: 'Please select Purchase Order Date',
    state: 'getState().workflowViewFMLQuoteConfirm.poddate',
    data: false,
    valid: false,
    empty: true,
    section: 'Quote Form'
  },
  // delivery: {
  //   id: 'txtinstruction',
  //   name: 'txtinstruction',
  //   errorText: 'Please enter Vehicle Delivery Address',
  //   state: 'getState().workflowViewFMLQuoteConfirm.instruction',
  //   data: false,
  //   valid: false,
  //   empty: true,
  //   section: 'Quote Form'
  // },
  // dealer: {
  //   id: 'ddl_dealer',
  //   name: 'ddl_dealer',
  //   errorText: 'Please select Dealer',
  //   state: 'getState().workflowViewFMLQuoteConfirm.dealer',
  //   data: false,
  //   valid: false,
  //   empty: true,
  //   section: 'Quote Form',
  // },
  oemSupplier: {
    id: 'ddl_supplier',
    name: 'ddl_supplier',
    errorText: 'Please select Dealer/OEM Supplier',
    state: 'getState().workflowViewFMLQuoteConfirm.supplier',
    data: false,
    valid: false,
    empty: true,
    section: 'Quote Form'
  },
  vehicleFunder: {
    id: 'ddl_funder',
    name: 'ddl_funder',
    errorText: 'Please select Vehicle Funder',
    state: 'getState().workflowViewFMLQuoteConfirm.financialinstitution',
    data: false,
    valid: false,
    empty: true,
    section: 'Quote Form',
  },
}