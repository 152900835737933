export const  loading = true
export const  renewalDetail = {}
export const  selectedVehicle = ''
export const vehicles = []
export const vehicleDescription = ''
export const vehicleType = ''
export const vehicleStatusCat = ''
export const licenceDiscNumber  = ''
export const expirydate =''
export const licencedispatchdate  =''
export const transactionfee  = '0.00'
export const licencefee  = '0.00'
export const penaltyfee = '0.00'
export const coffee  = '0.00'
export const cofrequired  = ''
export const adminfee  = '0.00'
export const totalamount= '0.00'
export const poNumber= ''
export const odometer= ''
export const vehicle_id= 0
export const customer_id= 0
export const licenceauthority_id= 0
export const licenseplateno= ''
export const licenceauthority=''
export const client_id= 2
export const renewdate = ''
export const  selectedCustomer = ''
export const newCustomer = ''
export const  customers = []
export const  effectdate = ''
export const  comment = ''
export const selectedCustomerName = ''
export const  country = ''
export const province = ''


// export const const createdat = ''
// export const const updatedat = ''
// export const const saveTransferResponse = ''


