
import * as config from './config'
const awsmobile = {
    "aws_project_region": config.cognito.region, 
    "aws_cognito_identity_pool_id": config.cognito.identityPoolId, 
    "aws_cognito_region": config.cognito.region,
    "aws_user_pools_id": config.cognito.userPoolId,
    "aws_user_pools_web_client_id": config.cognito.clientId,
    "oauth": {}
};
export default awsmobile;