export const accounts = []
export const teams = []
export const selectedAccount = 2
export const loading = true
export const accountName = ''
export const selectedBusinesses = []
export const selectedCustomers = []
export const locales = []
export const locale = ''
export const selectedLocale = null
export const accountTheme = null
export const accountLogo = null