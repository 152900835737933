import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

export const getCustomerList = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_CUSTOMER_LIST,
    )
        , mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/customer/searchor?hierarchyname=' + payload.search_text + '&accountnumber=' + payload.search_text,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'order': 'accountnumber'
                }
            })
                .pipe(
                    mergeMap((result) => [
                        actions.setCustomerList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    , startWith(progressBarActions.progress(true))
                    , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
                )
        )
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
    )


export const getallstatementcontacts = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_STATEMENT_CONTACT_LIST,
    )
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/customercontactcustom/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => [
                    actions.setStatementContactList(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            ))
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

export const gettimetz = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(ofType(
        types.GET_TIMETZ,
    )
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/testtz/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => [
                    actions.setTimetz(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            ))
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))

        
export const posttimetz = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
    types.POST_TIMETZ,
)
, mergeMap(({ payload }) =>
    ajax$({
        url: config.system.api + '/components/testtz/other',
        method: 'POST',
        headers: {
            'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
            'salt': store.value.cognito.authenticateUser.jwt,
            'datetime': store.value.cognito.authenticateUser.datetime,
            'Content-Type': 'application/json'
        },
        body: payload
    })
        .pipe(
            mergeMap((result) => [
                //actions.setTimetz(result.response),
                requestActions.requestSucceeded(config.system.api + '')
            ])
            , startWith(progressBarActions.progress(true))
            , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
            //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        ))
    , catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))