import * as actions from './actions'
import * as types from './types'

import * as config from '../../config'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as requestTypes from '../Request/types'

// 
export const getDashboardEpic = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.SET_DASHBOARD_DATA),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/oqlis/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.getDashboardData( result.response),
                progressBarActions.progress(false)
            ]),
            //startWith(progressBarActions.progress(true)),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)


export const getDashboardDataUrl = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.GET_DASHBOARD_URL),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/oqlis/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.setDashboardDataUrl(result),
                progressBarActions.progress(false)
            ]),
            // startWith(progressBarActions.progress(true)),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getKPIData = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.GET_KPI_DATA),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/component/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body:  {
                query: 'kpi_data',
                view_name: payload.view_name,
                client_id: payload.client_id
            }
        }).pipe(
            mergeMap((result) => [
                actions.setKPIData(result),
                progressBarActions.progress(false)
            ]),
            // startWith(progressBarActions.progress(true)),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/component?client_id=2&menu=true', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)

export const getReportToken = (action$, store, { ajax$, of$, startWith, map, mergeMap, delay, catchError, ofType, takeUntil }) => action$.pipe(
    ofType(types.GET_OQLIS_REPORT_TOKEN),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/report/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        }).pipe(
            mergeMap((result) => [
                actions.setReportToken(result.response, payload),
                progressBarActions.progress(false)
            ]),
            // startWith(progressBarActions.progress(true)),
            //takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/report/other', error)))
        )
    ),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    )
)