import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
       case types.SET_COMPONENT_STR_DASHBOARD:
        return {
            ...state,
            dashboard: action.payload 
        }
        case types.SET_STR_COMPONENT_VIEW:
         return {
             ...state,
             [action.payload.prop]: action.payload.value
         }
       case types.SET_COMPONENT_STR_DASHBOARD:
        return {
            ...state,
            view: action.payload.value
        }
        default:
            return state
    }
}