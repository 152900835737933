export const right = {
    width: '100%',
    textAlign: 'right'
}

export const verticalMiddle = {
    verticalAlign: 'middle'
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 2,
    verticalAlign: 'left'
}