import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_VAT_DOCUMENT_PREVIEW:
            return {
                ...state,
                preview: action.payload.preview,
                filename: action.payload.filename, 
            }
        case types.SET_MAIN_LEASE_DOCUMENT_PREVIEW:
            return {
                ...state,
                lease_preview: action.payload.preview,
                lease_filename: action.payload.filename, 
            }
        case types.SET_ACTIVATION_DOCUMENT_PREVIEW:
            return {
                ...state,
                activation_preview: action.payload.preview,
                activation_filename: action.payload.filename, 
            }
        default:
            return state
    }
}