
export const recieveddate = ''
export const accessory_list = []

export const regno = ''
export const vin = ''
export const engineno = ''
export const comments = ''
export const send_marketing = ''
export const marketingtype = ''
export const marketingtype_id = null
export const customerdata = {}
export const marketingtype_data = []

export const fields = {
  documents: {
    errorText: 'Please add a document and select document type',
    data: false,
    valid: false,
    empty: true,
    popup: true,
    type: 'documents',
  },

  enddate: {
    errorText: 'Please enter End Date',
    state: 'getState().activateMMContract.enddate',
    valid: false,
  },
  startdate: {
    errorText: 'Please enter Start Date',
    state: 'getState().activateMMContract.startdate',
    valid: false,
  },
  licenseexpiry: {
    errorText: 'Please enter Licence Expiry',
    state: 'getState().activateMMContract.licenseexpiry',
    valid: false,
  },
  odometer: {
    errorText: 'Please enter Odometer',
    state: 'getState().activateMMContract.odometer',
    valid: false,
    validations: [
      {
        'schema': 'integer',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Odometer'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Odometer'
      }
    ]
  },
  period: {
    errorText: 'Please enter Contract Period',
    state: 'getState().activateMMContract.period',
    valid: false,
    validations: [
      {
        'schema': 'integer',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Contract Period'
      },
      {
        'schema': 'greaterThanZero',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Contract Period'
      }
    ]
  },
  registration: {
    errorText: 'Please enter Vehicle Registration',
    state: 'getState().activateMMContract.registration',
    valid: false,
  }
}