import * as colours from '@mui/material/colors'
import Theme from '../../../theme'

export const theme = Theme

export const icon = {
    color: colours.red[500]
}

export const related = {
    //fontSize: '10px'
}

export const fieldContainer = {
    display: 'table',
    padding: 5
}

export const fieldTitle = {
    width: 400,
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '70%'
}

export const headerButton = {
    width: 250
}

export const right = {
    float: 'right'
}

export const toggle = {
    width: '100%'
}

export const textBox = {
    width: '100%'
}


export const row = (index, table) => {
    return index % 2 === 0
        ? {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            backgroundColor: '#ffffe5',
            fontSize: '14px',
            width: '100%'
        }
        : {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            fontSize: '14px',
            width: '100%'
        }
}

export const card = {
    height: '100%'
}

export const hidden = {
    display: 'none'
}

export const rightpanel = {
    background: '#cbe9f7',
    border: '2px solid #cbe9f7'
}

export const whitediv = {
    backgroundColor: '#fff',
    padding: 2,
    verticalAlign: 'left'
}

export const tableheader = {
    fontWeight: 'bold',
    fontSize: '16px',
    borderBottom: '2px solid grey',    
    marginLeft: '13px',
    marginRight: '13px'
}

export const buttonRight = {    
    verticalAlign: 'right'
}

export const numericFields = {
    textAlign: 'right',
}
export const redbox = {
    backgroundColor: colours.red[500],
    border: '2px solid red'
}

export const greenbox = {
    backgroundColor: colours.green[500],
    border: '2px solid red'
}

export const redboxtext = {
    fontSize: '16px',
}

export const box = {
    width: '100%',
    backgroundColor: '#fffbe5',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}

export const box1 = {
    width: '100%',
    backgroundColor: '#cbe9f7',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}


export const tdValue = {
    width: '20%',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'right',
}
export const tdField = {
    width: '20%',
    textAlign: 'left',
}


export const trField = {
    width: '100%',
    borderTop: '1px solid gray',
    padding: 2,
    
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const selectedFieldName = {
    fontWeight: 'bold'
}


export const action = {
    fontSize: '15px',
    color: colours.red[500]
}

export const actionHoverColor = {
    color: colours.red[500]
}

export const column = (width, right) => {
    return width === 0
        ? {
            display: 'table-cell',
            padding: '2px',
            fontSize: '14px',
            verticalAlign: 'middle !important',
            textAlign: right ? 'right' : 'left'
        }
        : {
            display: 'table-cell',
            padding: '2px',
            fontSize: '14px',
            
            width: width,
            verticalAlign: 'middle !important',
            textAlign: right ? 'right' : 'left'
        }
}
export const column_big = (width, dontpay, shortpay, value) => {
    return  {
            display: 'table-cell',
            padding: '2px',
            fontSize: '20px',
            backgroundColor: dontpay ? 'lightpink' : shortpay ? 'lightgrey' : (value ? '#c8e6c9' : 'white !important'),
            width: width,
            verticalAlign: 'middle !important',
            textAlign: 'center'
        }
}

export const gridOptionsIcon = {

}

export const iconButton = {
    height: '10px',
    padding: 0,
    margin: 0
}

export const select_row = (table, inx) => {
    return {
            display: table ? 'table' : 'block',
            verticalAlign: 'middle',
            fontSize: '14px',
            backgroundColor: inx ? '#ffffe5 !important' :'white !important',
            width: '100%'
        }
}


export const tablerow = {
    fontSize: '15px',
    background: 'white',
}
export const alttablerow = {
    fontSize: '15px',
    background: '#D1E0E6',
}
export const tablerowr = {
    fontSize: '15px',
    textAlign: 'right',
    borderBottom: '1px dash gray',
}

export const verticalMiddle = {
    verticalAlign: 'middle'
}
export const title = {
    width: '100%',
    backgroundColor: '#D1E0E6',
    padding: 5,
    margin: 10,
    textAlign: 'left',
}
