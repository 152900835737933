export let updatevalue = 0.00
export let statement = 0.00
export let selectedBusinessUnit =
{
    value: 0,
    text: ''
}
export let businessUnits =
    [
        {
            text: 'Loading...',
            value: ''
        }

    ]

export let payments = []
export let credits = []
export let others = []
export let comments = ''

export let paymentsnotcredited = 0.00
export let creditsnotpassed = 0.00
export let othercredits = 0.00
export let documents = []

export const isOpen = false
export const payment_list = []
/* export const main_payment_list = []
export const paymentcomment_list = []
export const payment_list_admin = []
export const paymentcomment_list_admin = []
export const payment_list_maint = []
export const paymentcomment_list_maint = []
export const payment_list_tyres = []
export const paymentcomment_list_tyres = [] */

export const commentfield = ''
export const prop_inx = null
export const prop_inx_docs = false
export const prop_drawer= null
export const supplier_list = [{
    value: 0,
    text: 'Loading...'
}]
export const selected_supplier =
{
    value: 0,
    text: ''
}

export const search_text = null
export const settlement = 0.00
export const statement_date = null
export const payment_date = null
export const remittance_data = null
export const recon_data = null
export const requisition_data = null
export const technical_payment = true
export const discount_perc = null
export const discount = 0.00
export const statement_amount = null
export const not_paying = null
export const total_payable = null
export const short_pay = null
export const selected_invoices = null
export const accept_all = null
export const refresh_documents = false
export const previewDocuments = false

export const fields = {   
    selectedSupplier: {
        errorText: 'Please select supplier from the list',
        data: null,
        valid: false,
        empty: true
    }  ,
    supplierSuspended: {
        errorText: 'Supplier has been suspended!',
        data: null,
        valid: false,
        empty: true
    },
    saveChanges: {
        errorText: 'Please save your changes',
        data: null,
        valid: false,
        empty: true
    },
    documents: {
        errorText: 'Please upload statement',
        state: 'getState().upload.documents.length',
        valid: false,
        empty: true,
    }
}

