// export const GET_COMPONENT_FIELD_LOOKUP_DATA = 'GET_COMPONENT_FIELD_LOOKUP_DATA'
// export const SET_COMPONENT_FIELD_LOOKUP_DATA = 'SET_COMPONENT_FIELD_LOOKUP_DATA'
// export const SET_COMPONENT_FIELD_INPUT_VALUE = 'SET_COMPONENT_FIELD_INPUT_VALUE'
// export const SET_COMPONENT_LOOKUP_INPUT_VALUE = 'SET_COMPONENT_LOOKUP_INPUT_VALUE'
// export const QUOTE_VEHICLE_BLANK_ROW = 'QUOTE_VEHICLE_BLANK_ROW'
// export const GET_QUOTE_VEHICLE_DETAILS = 'GET_QUOTE_VEHICLE_DETAILS'
// export const TOGGLE_CUSTOMER_VALUES = 'TOGGLE_CUSTOMER_VALUES'
// export const CONFIRM_QUOTE_HEADER_SELECTION = 'CONFIRM_QUOTE_HEADER_SELECTION'
// export const RESET_QUOTE_HEADER_SELECTION = 'RESET_QUOTE_HEADER_SELECTION'
// export const SET_COMPONENT_FIELD_VALUE = 'SET_COMPONENT_FIELD_VALUE'
// export const UPLOAD_RFQ_QUOTE_DOCUMENT = 'UPLOAD_RFQ_QUOTE_DOCUMENT'
// export const RESET_UPLOAD_RFQ_QUOTE_DOCUMENT = 'RESET_UPLOAD_RFQ_QUOTE_DOCUMENT'
// export const SET_RFQ_QUOTE_DOCUMENT_PREVIEW = 'SET_RFQ_QUOTE_DOCUMENT_PREVIEW'
// export const CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW = 'CLEAR_RFQ_QUOTE_DOCUMENT_PREVIEW'
// export const LOAD_COMPONET_GRID_SELECTED_DATA = 'LOAD_COMPONET_GRID_SELECTED_DATA'
// export const CLEAR_QUOTE_VEHICLE_BLANK_ROW = 'CLEAR_QUOTE_VEHICLE_BLANK_ROW'
// export const GET_COMPONENT_FIELD_LOOKUP_VALUE = 'GET_COMPONENT_FIELD_LOOKUP_VALUE'
// export const SET_COMPONENT_FIELD_INPUT_TEXT = 'SET_COMPONENT_FIELD_INPUT_TEXT'
// export const SEARCH_WORKINGS_QUOTATION = 'SEARCH_WORKINGS_QUOTATION'
// export const SET_COMPONENT_WORKINGS_GRID_DATA = 'SET_COMPONENT_WORKINGS_GRID_DATA'
export const GET_PO_COMPONENT_FIELD_LOOKUP_VALUE = 'GET_PO_COMPONENT_FIELD_LOOKUP_VALUE'
export const SET_PO_COMPONENT_FIELD_INPUT_VALUE = 'SET_PO_COMPONENT_FIELD_INPUT_VALUE'
export const SET_PO_COMPONENT_FIELD_INPUT_TEXT = 'SET_PO_COMPONENT_FIELD_INPUT_TEXT'
export const SET_PO_COMPONENT_FIELD_LOOKUP_DATA = 'SET_PO_COMPONENT_FIELD_LOOKUP_DATA'
export const CLEAR_PO_QUOTE_DOCUMENT_PREVIEW = 'CLEAR_PO_QUOTE_DOCUMENT_PREVIEW'
export const SET_PO_QUOTE_DOCUMENT_PREVIEW = 'SET_PO_QUOTE_DOCUMENT_PREVIEW'
export const SET_PO_UPLOAD_QUOTE_DOCUMENT = 'SET_PO_UPLOAD_QUOTE_DOCUMENT'
export const GET_PO_COMPONENT_FIELD_LOOKUP_DATA = 'GET_PO_COMPONENT_FIELD_LOOKUP_DATA'
export const SEARCH_PO_QUOTE_DATA = 'SEARCH_PO_QUOTE_DATA'
export const CONFIRM_QUOTE_HEADER_SELECTION = 'CONFIRM_QUOTE_HEADER_SELECTION'
export const SET_PO_COMPONENT_GRID_DATA = 'SET_PO_COMPONENT_GRID_DATA'
export const SET_PO_SUPPLIER_BLANK_ROW = 'SET_PO_SUPPLIER_BLANK_ROW'
