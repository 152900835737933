import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import moment  from 'moment'

import { green, red } from '@mui/material/colors'
import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import Icon from '../../Icon/component'

import Dialog from '@mui/material/Dialog'
import { Table } from '@mui/material'
import Dropzone from 'react-dropzone'
import Card from '../../../controls/card'
import TextField from '../../../controls/textField'

import * as actions from './actions'
import * as styles from './styles'

class CUSTOMERBANKRECON extends Component {
    componentDidMount() {
        this.props.actions.resetUpload()
        this.props.actions.setInputValue('rows',[])
        //this.props.actions.getProfitlossData(moment().format('YYYYMM'),moment().format('YYYYMM'))
     }
  
                  
    getRowGrid() {
        //console.log('getRowGridx rows',this.props.state.rows)
         return <Container style={{ margin: 0 }} className="custom-container">
            {
                this.props.state.rows.map(x => {
                    //console.log('getRowGridx',x)
                    //console.log('getRowGridx x.matcht ',x.matcht )
                    return <Row>
                        <Col xl={5}>
                            <table style={{background: '#f5f5f7', width: '100%', border: '1px solid #ffc926', margin: '15px', padding: '15px'}}>
                                <tr>
                                    <td style={{padding: '15px'}}>
                                        {x['0'] ? x['0'] : '...'} <br/>
                                        <b style={{fontSize: '15px'}}>MT: {x['3']}</b><br/>
                                        {x['4']}
                                    </td>
                                    <td style={{padding: '15px', width: '130px'}}>
                                        <small>Spent</small><br/>
                                        <b style={{fontSize: '15px'}}>{parseFloat(x['2']) < 0 ? x['2'] : '0.00'}</b><br/>
                                    </td>
                                    <td style={{padding: '15px', width: '130px'}}>
                                        <small>Received</small><br/>
                                        <b style={{fontSize: '15px'}}>{parseFloat(x['2']) < 0 ? '0.00' : x['2']}</b><br/>
                                    </td>
                                </tr>
                            </table>
                        </Col>
                        <Col xl={2}>
                            
                            <table style={{width: '100%', height: '100%' }}>
                                <tr>
                                    <td style={{textAlign: 'center', verticalAlign: 'middle'}}>
                                        {
                                            x.matcht 
                                            ? x.matcht.cash_book_id
                                                ? x.matcht.reconciled
                                                ? <Button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    this.props.actions.unReconTransactionsRecon(x.matcht.cash_book_id)
                                                }}
                                                variant="contained"
                                                className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                                                >
                                                {'Reconciled'}
                                            </Button> 
                                            : <><Button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.updateTransactionsRecon(x.matcht.cash_book_id,x['2'],x.matcht.contract_amount,x['5'])
                                            }}
                                            variant="contained"
                                            className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                                            >
                                            {'Ok'}
                                        </Button> <br/> {x.matcht.reconciled || !x.matcht.cash_book_id ? '' : <b><a href="#" style={{color: 'blue'}} onClick={() => { 
                                                this.props.actions.setInputValue('unmatchx',x)
                                                this.props.actions.setInputValue('unmatch_refence',x.matcht.reference)
                                                this.props.actions.setInputValue('win_unmatch',x.matcht.cash_book_id) }}>view</a></b>} </>
                                            : <Button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    this.props.actions.setInputValue('statement_reference',x['3'])
                                                    this.props.actions.getTransactionWithSameAmount(x['2'])
                                                    this.props.actions.setInputValue('win_match',true)
                                                }}
                                                variant="contained"
                                                className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                                                >
                                                {'Match'}
                                            </Button>  
                                            :  <Button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    this.props.actions.setInputValue('statement_reference',x['3'])
                                                    this.props.actions.getTransactionWithSameAmount(x['2'])
                                                    this.props.actions.setInputValue('win_match',true)
                                                }}
                                                variant="contained"
                                                className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 "
                                                >
                                                {'Match'}
                                            </Button>  
                                        }
                                    </td>
                                </tr>
                            </table>
                        </Col>
                        <Col xl={5}>
                            {
                                x.matcht 
                                ? <table style={{background: '#e5f9ed', width: '100%', border: '1px solid #1bc943', margin: '15px', padding: '15px'}}>
                                    <tr>
                                        <td style={{padding: '15px'}}>
                                            {x.matcht.transaction_date ? moment(x.matcht.transaction_date).format('YYYY-MM-DD') : '?'} <br/>
                                            <b style={{fontSize: '15px'}}>{x.matcht.reference}</b><br/>
                                            {x.matcht.description}
                                        </td>
                                        <td style={{padding: '15px', width: '130px'}}>
                                            <small>Spent</small><br/>
                                            <b style={{fontSize: '15px'}}>{x.matcht.credit}</b><br/>
                                        </td>
                                        <td style={{padding: '15px', width: '130px'}}>
                                            <small>Received</small><br/>
                                            <b style={{fontSize: '15px'}}>{x.matcht.debit}</b><br/>
                                        </td>
                                    </tr>
                                </table>
                                : <table style={{background: '#e5f9ed', width: '100%', border: '1px solid #1bc943', margin: '15px', padding: '15px'}}>
                                <tr>
                                    <td style={{padding: '15px'}}>
                                        ...<br/>
                                        <b style={{fontSize: '15px'}}>{'No Match'}</b><br/>
                                        {'No transaction found '}
                                    </td>
                                    <td style={{padding: '15px', width: '130px'}}>
                                        <small>Spent</small><br/>
                                        <b style={{fontSize: '15px'}}>{0}</b><br/>
                                    </td>
                                    <td style={{padding: '15px', width: '130px'}}>
                                        <small>Received</small><br/>
                                        <b style={{fontSize: '15px'}}>{0}</b><br/>
                                    </td>
                                </tr>
                            </table>
                            }
                        </Col>
                    </Row>
                })
            }
         </Container>
     }
 
     getAcceptedFiles() {
        return this.props.state.rows.length
            ? <Card
            title={window.t.en('Imported File Data')}
            subtitle={window.t.en('Please ensure that the data import match the one the file you imported before you action.')}
            content={
                <div style={{ verticalAlign: 'middle', margin: '1px', overflow: 'auto' }}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={11}>
                            {this.getRowGrid()}
                        </Col>
                    </Row>
                </Container>
            </div>
        }/>
        : <div />
    }
 
     matchWin() {
        //console.log('match_transactionsx', this.props.state.match_transactions)
        return <Dialog
       open={this.props.state.win_match}
       style={{ width: '100% !important', maxWidth: '100% !important' }}
       fullWidth={true}
       maxWidth="lg"
       onClose={(event) => {
           event.preventDefault()
           this.props.actions.setInputValue('win_match',false)
       }}
       //classes={{ paper: 'shadow-lg rounded' }}
       >
        <div className="text-center p-5">
         <h4 className="font-weight-bold mt-4">
           Match Transaction
         </h4>
         <strong>Statement Reference: {this.props.state.statement_reference}</strong>
         {
            this.props.state.match_transactions.filter(x => !x.reconciled).length
            ? <p className="mb-0 font-size-lg text-muted">
            <Table className="table table-hover text-nowrap mb-0">
                    <thead>
                <tr>
                            <th></th>
                            <th>Invoice Number</th>
                            <th>Reference</th>
                            <th>Transaction Date</th>
                            <th>Description</th>
                            <th>Transaction Month</th>
                            <th>Invoice Amount</th>
                        </tr></thead>
                {
                    this.props.state.match_transactions.filter(x => !x.reconciled).map(x => {
                        return <tr>
                            <td>
                            <Button 
                                onClick={(event) => {
                                    event.preventDefault()
                                    let data = {
                                        cash_book_id: x.cash_book_id,
                                        reference: this.props.state.statement_reference,
                                        ref_cb_match_arch: x.reference
                                    }
                                    //console.log('updMatchTransactionsx',data)
                                    this.props.actions.updMatchTransactions(data)
                                }}
                                className=" btn-pill mx-1">
                                <span className="btn-wrapper--label">Match</span>
                            </Button></td>
                            <td>{x.invoicenumber}</td>
                            <td>{x.reference}</td>
                            <td>{x.transaction_date}</td>
                            <td>{x.description}</td>
                            <td>{x.transaction_month}</td>
                            <td>{x.contract_amount}</td>
                        </tr>
                    })
                }</Table>
            </p>
            : <p>
                <code style={{color: 'red'}}>No Transaction found with same amount</code>
            </p>
        }
         <div className="pt-4">
           <Button 
               onClick={(event) => {
                   event.preventDefault()
                   this.props.actions.setInputValue('win_match',false)
               }}
               className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3">
               <span className="btn-wrapper--label">Close</span>
           </Button>
         </div>
       </div> 
     </Dialog>
    }

     unmatchWin() {
        //console.log('unmatchx x', this.props.state.unmatchx)
        return <Dialog
       open={this.props.state.win_unmatch ? true : false}
       style={{ width: '100% !important', maxWidth: '100% !important' }}
       fullWidth={true}
       maxWidth="lg"
       onClose={(event) => {
           event.preventDefault()
           this.props.actions.setInputValue('win_unmatch',null)
       }}
       //classes={{ paper: 'shadow-lg rounded' }}
       >
        <div className="text-center p-5">
         <h4 className="font-weight-bold mt-4">
           Un Match Transaction
         </h4>

         {
            this.props.state.unmatchx.matcht ? <Container style={{ margin: 0 }} className="custom-container">
         <Row>
                        <Col xl={6}>
                            <table style={{background: '#f5f5f7', width: '100%', border: '1px solid #ffc926', margin: '15px', padding: '15px'}}>
                                <tr>
                                    <td style={{padding: '15px'}}>
                                        {this.props.state.unmatchx['0'] ? this.props.state.unmatchx['0'] : '...'} <br/>
                                        <b style={{fontSize: '15px'}}>{this.props.state.unmatchx['3']}</b><br/>
                                        {this.props.state.unmatchx['4']}
                                    </td>
                                    <td style={{padding: '15px', width: '130px'}}>
                                        <small>Spent</small><br/>
                                        <b style={{fontSize: '15px'}}>{parseFloat(this.props.state.unmatchx['2']) < 0 ? this.props.state.unmatchx['2'] : '0.00'}</b><br/>
                                    </td>
                                    <td style={{padding: '15px', width: '130px'}}>
                                        <small>Received</small><br/>
                                        <b style={{fontSize: '15px'}}>{parseFloat(this.props.state.unmatchx['2']) < 0 ? '0.00' : this.props.state.unmatchx['2']}</b><br/>
                                    </td>
                                </tr>
                            </table>
                        </Col>
                        <Col xl={6}>
                            {
                               <table style={{background: '#e5f9ed', width: '100%', border: '1px solid #1bc943', margin: '15px', padding: '15px'}}>
                                    <tr>
                                        <td style={{padding: '15px'}}>
                                            {this.props.state.unmatchx.matcht.transaction_date ? moment(this.props.state.unmatchx.matcht.transaction_date).format('YYYY-MM-DD') : '?'} <br/>
                                            <b style={{fontSize: '15px'}}>MT: {this.props.state.unmatchx.matcht.reference}</b><br/>
                                            {this.props.state.unmatchx.matcht.description}
                                        </td>
                                        <td style={{padding: '15px', width: '130px'}}>
                                            <small>Spent</small><br/>
                                            <b style={{fontSize: '15px'}}>{this.props.state.unmatchx.matcht.credit}</b><br/>
                                        </td>
                                        <td style={{padding: '15px', width: '130px'}}>
                                            <small>Received</small><br/>
                                            <b style={{fontSize: '15px'}}>{this.props.state.unmatchx.matcht.debit}</b><br/>
                                        </td>
                                    </tr>
                                </table>
                            }
                        </Col>
                    </Row>
                    </Container>
                    : ''
                        }
                        
         <strong>To un match the transaction save the reference on the below field input: {this?.props?.state?.unmatchx?.matcht?.ref_cb_match_arch}</strong>
         


         <TextField
            id={'txttransactionreference'}
            variant={"outlined"}
            disabled={true}
            fullWidth={true}
            label={'Transaction Prev Reference'}
            value={this?.props?.state?.unmatchx?.matcht?.ref_cb_match_arch}
            onChange={(event, value) => {
                this.props.actions.setInputValue('unmatch_refence',event.target.value)
            }}
        />
         <div className="pt-4">
           <Button 
               onClick={(event) => {
                   event.preventDefault()
                   this.props.actions.setInputValue('win_unmatch',null)
               }}
               variant="contained"
               className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
               >
               <span className="btn-wrapper--label">Close</span>
           </Button>
           <Button 
               onClick={(event) => {
                   event.preventDefault()
                   this.props.actions.unmatchReconTransactionsRecon(this.props.state.unmatchx.matcht.cash_book_id, this?.props?.state?.unmatchx?.matcht?.ref_cb_match_arch)
                  
               }}
               disabled={this?.props?.state?.unmatchx?.matcht?.ref_cb_match_arch ? false : true}
               variant="contained"
               className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3">
               <span className="btn-wrapper--label">Save</span>
           </Button>
         </div>
       </div> 
     </Dialog>
    }
    getRejectedFiles() {
        return !this.props.state.busy && !this.props.state.rows.length
            ? <Card
            title={window.t.en('Import Failed')}
            subtitle={window.t.en('Please review the reason to why the file failed to import and action accordingly!')}
            content={<Button className={"global_button"} variant="contained"
                        label={'Upload Again'}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.actions.uploadAgain()
                        }}
                        >{window.t.en('Upload Again')}</Button>
                    }/>
            : <div />
    }

     getImportFile() {
        return <div><Card
            title={window.t.en('Import Bank Statement')}
            subtitle={window.t.en('ImportBank Statement Data ')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    {
                        !this.props.state.document
                            ? <Row>
                                <Col xl={12}>
                                    <Dropzone
                                        accept={'.xls,.xlsx'}
                                        style={styles.dropZone}
                                        onDrop={(files) => {
                                            //this.props.actions.notifyUser('Checking documents...')
                                        }}
                                        onDropAccepted={(files) => {
                                            this.props.actions.setAcceptedDocuments(files)
                                        }}
                                        onDropRejected={(files) => {
                                            //this.props.actions.notifyUser('(' + files.length + ') files were rejected.')
                                            //this.props.actions.setRejectedDocuments(files, this.props.state.component.name)
                                        }}
                                    >
                                        <p style={styles.watermark}>
                                            Drag and drop the files you would like to upload inside this box. please ensure that your are uploading the correct file format:
                                            <br />
                                            .xls and .xlsx
                                        </p>
                                    </Dropzone>
                                </Col>
                            </Row>
                            : <Row>
                                <Col xl={12}>
                                    <div style={styles.fieldContainer}>
                                        <div style={styles.fieldTitle}>
                                            {'Reset Imported File:'}
                                            <div>
                                                <Icon istyle={{ color: green[500], fontSize: '16px' }} iclass={'material-icons'} iname={'done'} />
                                                &nbsp;
                                                <strong>{'File Name:'} </strong>{this.props.state.document.filename}
                                            </div>
                                        </div>
                                        <div style={styles.fieldContent}>< Button className={"global_button"} variant="contained"
                                            label={'Reset Upload'}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                this.props.actions.resetUpload()
                                            }}
                                            >{window.t.en('Reset Upload')}</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                    }
                </Container>
            }
        /> 
            {this.props.state.document && !this.props.state.busy && this.getAcceptedFiles()}
            {this.props.state.document && !this.props.state.busy && this.getRejectedFiles()}
        </div> 
     }
 
     
     render() {
         return [
             this.getImportFile(),this.matchWin(),this.unmatchWin()
         ]
     }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.customerbankrecon
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERBANKRECON)