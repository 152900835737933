import * as colours from '@mui/material/colors'

export const counter = {
    width: 30,
    height: 30,
    textAlign: 'center',
    verticalAlign: 'middle',
    //borderBottom: '5px solid',
    //borderColor: colours.red[500],
    display: 'table-cell', 
    paddingRight: '5px',
    borderRadius: '100px',
    fontWeight: '400'
}