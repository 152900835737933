import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from './actions'

const Redirect = (props) => {    
    useEffect(() => {
        props.navigate(props.state.path,{params: props.state.path})
    });

    return;
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.redirect
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Redirect)