import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Tabs, Box, Tab, Button, List, ListItem, Tooltip, Dialog, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'
import ViewSupplier from '../../../controls/view_supplier'

import * as actions from './actions'
import * as styles from './styles'
import * as config from '../../../config'

import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

class AccessoryMasterCapture extends Component {
    componentDidMount() {

        if (this.props.mode === "confirm") {
            //console.log('this.props.state',this.props.state)
            this.props.actions.setupWorkflowTaskInput(this.props.state, [], 'accessory_master', 'tsk_accessory_master_capture')
        }
        else {
            this.props.actions.setValidationState('accessoryMasterCapture')

            let supplier = this.props.data[0].workflow_task_step_component[0].data[0]
            this.props.actions.resetForm()
            this.props.actions.setInputValue('supplier', supplier)

            this.props.actions.getSupplierAccessories({ field: 'sundries', component: 'suppliersundrypricing', supplier_id: supplier.supplier_id, related: 'sundry,supplier', display: 'description' })
            this.props.actions.getSupplierAccessories({ field: 'services', component: 'supplierservicepricing', supplier_id: supplier.supplier_id, related: 'service,supplier', display: 'servicedescription' })
            this.props.actions.getSupplierAccessories({ field: 'accessories', component: 'supplieraccessorypricing', supplier_id: supplier.supplier_id, related: 'accessory,supplier', display: 'accessoryname' })
        }
    }

    getSupplier() {
        return <div>
            <ViewSupplier
                key={this.supplier.supplier_id}
                id={this.supplier.supplier_id}
            />
            <div>
                <iframe src={`${config.oq.uri}15d13cd0296a2619be49ffcd9917e1b4679b5480f50c7d8617c89291076d1ee6710c554b250f035ca0d60683fc534273a3c88ba5cc8a171ed24ccbbb4e94e68d&filters=supplier_id=%27${this.supplier.supplier_id}%27#`}
                    height="200px"
                    width="100%"
                    style={{
                        border: "none",
                        margin: 0,
                        //padding: '-25px',
                        alignSelf: "center",
                    }} />
            </div>
        </div>
    }

    getAccessories() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} key={'col_add'}>
                        <Button
                            onClick={(event) => {
                                this.props.actions.addAccessory()
                            }}
                            className="btn-pill-off">
                            <span className="btn-wrapper--label">Add Item</span>
                        </Button>
                    </Col>
                    <Col xl={10} ></Col>
                    <Col xl={12}>
                        {
                            this.props.state.accessories.length
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold-">
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{ zIndex: 1 }}>
                                        {
                                            this.props.state.accessories.map((x, inx) => {

                                                return <tr>
                                                    <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                x.editing
                                                                    ? <Row>
                                                                        <Col xl={3} style={{ paddingTop: '20px' }}>
                                                                            <AutoComplete
                                                                                name={'field_' + inx}
                                                                                title={'Select Type'}
                                                                                data={this.props.state.accessory_data}
                                                                                value={x.accessoryname}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                disabled={x.accessoryname ? true : false}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'accessoryname', value: args.text })
                                                                                    this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'accessory_id', value: args.value })
                                                                                }}
                                                                                onClick={() =>
                                                                                    this.props.actions.getLookupData('accessory', 'accessoryname', '')
                                                                                }
                                                                                onFilter={(args) =>
                                                                                    this.props.actions.getLookupData('accessory', 'accessoryname', args)
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '0px' }}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'costprice' + inx}
                                                                                label={'Cost Price'}
                                                                                value={x.costprice}
                                                                                style={{ marginTop: 0 }}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'costprice', value: event.target.value })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '0px' }}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'sellingprice' + inx}
                                                                                label={'Selling Price'}
                                                                                value={x.sellingprice}
                                                                                style={{ marginTop: 0 }}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'sellingprice', value: event.target.value })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '2px' }}>

                                                                            <DateControl
                                                                                id={'expirydate' + inx}
                                                                                key={'expirydate' + inx}
                                                                                label={'Expiry Date'}
                                                                                value={x.expirydate}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'expirydate', value: args })
                                                                                }}
                                                                                type={'date'}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '25px' }}>
                                                                            <AutoComplete
                                                                                name={'field_' + inx}
                                                                                title={'Select VAT Type'}
                                                                                data={this.props.state.vatable_data}
                                                                                value={x.vatable}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'vatable', value: args.text })
                                                                                }}
                                                                                onClick={() => {}}
                                                                                onFilter={(search) =>{
                                                                                    if (search){
                                                                                        const data = this.props.state.vatable_data
                                                                                        const vatable = data.find(v => v.text === search)
                                                                                        if (vatable) this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'vatable', value: search })
                                                                                    } else {
                                                                                        this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'vatable', value: '' })
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={1}>
                                                                            <div style={{ marginTop: '20px' }}>
                                                                                <IconButton
                                                                                    key={'command_add_accessory_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                    onClick={
                                                                                        () => {
                                                                                            this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'editing', value: false })
                                                                                        }
                                                                                    }>
                                                                                    <SaveIcon />
                                                                                </IconButton>
                                                                                &nbsp;&nbsp;
                                                                                <IconButton
                                                                                    key={'command_remove_accessory_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                    onClick={
                                                                                        () => {
                                                                                            this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'accessoryname', value: '' })
                                                                                            this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'costprice', value: '' })
                                                                                            this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'sellingprice', value: '' })
                                                                                            this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'expirydate', value: '' })
                                                                                            this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'vatable', value: '' })
                                                                                            // this.props.actions.setAccessoryInputValue({inx: inx, prop: 'editing', value: false})
                                                                                            this.props.actions.delAccessory(inx);
                                                                                        }
                                                                                    }>
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    : <Row>
                                                                        <Col xl={3}>
                                                                            {x.accessoryname}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.costprice}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.sellingprice}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {moment(x.expirydate).format('YYYY-MM-DD')}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.vatable}
                                                                        </Col>
                                                                        <Col xl={1}>
                                                                            {
                                                                                <div style={{ marginTop: '20px' }}>
                                                                                    <IconButton key={'command_edit_accessory_' + x.inx} style={styles.delButton} iconStyle={styles.action}
                                                                                        onClick={
                                                                                            () => {
                                                                                                this.props.actions.setAccessoryInputValue({ inx: inx, prop: 'editing', value: true })
                                                                                            }
                                                                                        }>
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                    &nbsp;&nbsp;
                                                                                    <IconButton key={'command_delete_accessory_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                        onClick={
                                                                                            () => {
                                                                                                this.props.actions.delAccessory(inx)
                                                                                            }
                                                                                        }>
                                                                                        <DeleteForeverIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                            }
                                                        </Container>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getSundries() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} key={'col_add'}>
                        <Button
                            onClick={(event) => {
                                this.props.actions.addSundry()
                            }}
                            className="btn-pill-off">
                            <span className="btn-wrapper--label">Add Item</span>
                        </Button>
                    </Col>
                    <Col xl={10} ></Col>
                    <Col xl={12}>
                        {
                            this.props.state.sundries.length
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold-">
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{ zIndex: 1 }}>
                                        {
                                            this.props.state.sundries.map((x, inx) => {

                                                return <tr>
                                                    <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                x.editing
                                                                    ? <Row>
                                                                        <Col xl={3} style={{ paddingTop: '20px' }}>
                                                                            <AutoComplete
                                                                                name={'field_' + inx}
                                                                                title={'Select Type'}
                                                                                data={this.props.state.sundry_data}
                                                                                value={x.description}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                disabled={x.description ? true : false}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setSundryInputValue({ inx: inx, prop: 'description', value: args.text })
                                                                                    this.props.actions.setSundryInputValue({ inx: inx, prop: 'sundry_id', value: args.value })
                                                                                }}
                                                                                onClick={() =>
                                                                                    this.props.actions.getLookupData('sundry', 'description', '')
                                                                                }
                                                                                onFilter={(args) =>
                                                                                    this.props.actions.getLookupData('sundry', 'description', args)
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '0px' }}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'costprice' + inx}
                                                                                label={'Cost Price'}
                                                                                value={x.costprice}
                                                                                style={{ marginTop: 0 }}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setSundryInputValue({ inx: inx, prop: 'costprice', value: event.target.value })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '0px' }}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'sellingprice' + inx}
                                                                                label={'Selling Price'}
                                                                                value={x.sellingprice}
                                                                                style={{ marginTop: 0 }}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setSundryInputValue({ inx: inx, prop: 'sellingprice', value: event.target.value })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '2px' }}>
                                                                            <DateControl
                                                                                id={'expirydate' + inx}
                                                                                key={'expirydate' + inx}
                                                                                label={'Expiry Date'}
                                                                                value={x.expirydate}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setSundryInputValue({ inx: inx, prop: 'expirydate', value: args })
                                                                                }}
                                                                                type={'date'}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '25px' }}>
                                                                            <AutoComplete
                                                                                name={'field_' + inx}
                                                                                title={'Select VAT Type'}
                                                                                data={this.props.state.vatable_data}
                                                                                value={x.vatable}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setSundryInputValue({ inx: inx, prop: 'vatable', value: args.text })
                                                                                }}
                                                                                onClick={() => {}}
                                                                                onFilter={(search) =>{
                                                                                    if (search){
                                                                                        const data = this.props.state.vatable_data
                                                                                        const vatable = data.find(v => v.text === search)
                                                                                        if (vatable) this.props.actions.setSundryInputValue({ inx: inx, prop: 'vatable', value: search })
                                                                                    } else {
                                                                                        this.props.actions.setSundryInputValue({ inx: inx, prop: 'vatable', value: '' })
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={1}>
                                                                            <div style={{ marginTop: '20px' }}>
                                                                                <IconButton
                                                                                    key={'command_add_sundry_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                    onClick={
                                                                                        () => {
                                                                                            this.props.actions.setSundryInputValue({ inx: inx, prop: 'editing', value: false })
                                                                                        }
                                                                                    }>
                                                                                    <SaveIcon />
                                                                                </IconButton>
                                                                                &nbsp;&nbsp;
                                                                                <IconButton
                                                                                    key={'command_remove_sundry_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                    onClick={
                                                                                        () => {
                                                                                            this.props.actions.setSundryInputValue({ inx: inx, prop: 'description', value: '' })
                                                                                            this.props.actions.setSundryInputValue({ inx: inx, prop: 'costprice', value: '' })
                                                                                            this.props.actions.setSundryInputValue({ inx: inx, prop: 'sellingprice', value: '' })
                                                                                            this.props.actions.setSundryInputValue({ inx: inx, prop: 'expirydate', value: '' })
                                                                                            this.props.actions.setSundryInputValue({ inx: inx, prop: 'vatable', value: '' })
                                                                                            // this.props.actions.setSundryInputValue({inx: inx, prop: 'editing', value: false})
                                                                                            this.props.actions.delSundry(inx);
                                                                                        }
                                                                                    }>
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                        </Col>

                                                                    </Row>
                                                                    : <Row>
                                                                        <Col xl={3}>
                                                                            {x.description}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.costprice}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.sellingprice}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {moment(x.expirydate).format('YYYY-MM-DD')}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.vatable}
                                                                        </Col>
                                                                        <Col xl={1}>
                                                                            {
                                                                                <div style={{ marginTop: '20px' }}>
                                                                                    <IconButton key={'command_edit_sundry_' + x.inx} style={styles.delButton} iconStyle={styles.action}
                                                                                        onClick={
                                                                                            () => {
                                                                                                this.props.actions.setSundryInputValue({ inx: inx, prop: 'editing', value: true })
                                                                                            }
                                                                                        }>
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                    &nbsp;&nbsp;
                                                                                    <IconButton key={'command_delete_sundry_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                        onClick={
                                                                                            () => {
                                                                                                this.props.actions.delSundry(inx)
                                                                                            }
                                                                                        }>
                                                                                        <DeleteForeverIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                            }
                                                        </Container>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getServices() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={2} key={'col_add'}>
                        <Button
                            onClick={(event) => {
                                this.props.actions.addService()
                            }}
                            className="btn-pill-off">
                            <span className="btn-wrapper--label">Add Item</span>
                        </Button>
                    </Col>
                    <Col xl={10} ></Col>
                    <Col xl={12}>
                        {
                            this.props.state.services.length
                                ? <Table className="table table-alternate-spaced mb-0" >
                                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold-">
                                        <th className="text-left"></th>
                                    </thead>
                                    <tbody style={{ zIndex: 1 }}>
                                        {
                                            this.props.state.services.map((x, inx) => {

                                                return <tr>
                                                    <td>
                                                        <Container style={{ margin: 0 }} className="custom-container">
                                                            {
                                                                x.editing
                                                                    ? <Row>
                                                                        <Col xl={3} style={{ paddingTop: '20px' }}>
                                                                            <AutoComplete
                                                                                name={'field_' + inx}
                                                                                title={'Select Type'}
                                                                                data={this.props.state.service_data}
                                                                                value={x.servicedescription}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                disabled={x.servicedescription ? true : false}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setServiceInputValue({ inx: inx, prop: 'servicedescription', value: args.text })
                                                                                    this.props.actions.setServiceInputValue({ inx: inx, prop: 'service_id', value: args.value })
                                                                                }}
                                                                                onClick={() =>
                                                                                    this.props.actions.getLookupData('service', 'servicedescription', '')
                                                                                }
                                                                                onFilter={(args) =>
                                                                                    this.props.actions.getLookupData('service', 'servicedescription', args)
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '0px' }}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'costprice' + inx}
                                                                                label={'Cost Price'}
                                                                                value={x.costprice}
                                                                                style={{ marginTop: 0 }}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setServiceInputValue({ inx: inx, prop: 'costprice', value: event.target.value })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '0px' }}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                margin="normal"
                                                                                fullWidth={true}
                                                                                id={'sellingprice' + inx}
                                                                                label={'Selling Price'}
                                                                                value={x.sellingprice}
                                                                                style={{ marginTop: 0 }}
                                                                                onChange={(event, value) => {
                                                                                    this.props.actions.setServiceInputValue({ inx: inx, prop: 'sellingprice', value: event.target.value })
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '2px' }}>
                                                                            <DateControl
                                                                                id={'expirydate' + inx}
                                                                                key={'expirydate' + inx}
                                                                                label={'Expiry Date'}
                                                                                value={x.expirydate}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setServiceInputValue({ inx: inx, prop: 'expirydate', value: args })
                                                                                }}
                                                                                type={'date'}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={2} style={{ paddingTop: '25px' }}>
                                                                            <AutoComplete
                                                                                name={'field_' + inx}
                                                                                title={'Select VAT Type'}
                                                                                data={this.props.state.vatable_data}
                                                                                value={x.vatable}
                                                                                dataSourceConfig={{
                                                                                    text: 'text',
                                                                                    value: 'value'
                                                                                }}
                                                                                onSelect={(args) => {
                                                                                    this.props.actions.setServiceInputValue({ inx: inx, prop: 'vatable', value: args.text })
                                                                                }}
                                                                                onClick={() => {}}
                                                                                onFilter={(search) =>{
                                                                                    if (search){
                                                                                        const data = this.props.state.vatable_data
                                                                                        const vatable = data.find(v => v.text === search)
                                                                                        if (vatable) this.props.actions.setServiceInputValue({ inx: inx, prop: 'vatable', value: search })
                                                                                    } else {
                                                                                        this.props.actions.setServiceInputValue({ inx: inx, prop: 'vatable', value: '' })
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xl={1}>
                                                                            <div style={{ marginTop: '20px' }}>
                                                                                <IconButton
                                                                                    key={'command_add_service_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                    onClick={
                                                                                        () => {
                                                                                            this.props.actions.setServiceInputValue({ inx: inx, prop: 'editing', value: false })
                                                                                        }
                                                                                    }>
                                                                                    <SaveIcon />
                                                                                </IconButton>
                                                                                &nbsp;&nbsp;
                                                                                <IconButton
                                                                                    key={'command_remove_service' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                    onClick={
                                                                                        () => {
                                                                                            this.props.actions.setServiceInputValue({ inx: inx, prop: 'servicedescription', value: '' })
                                                                                            this.props.actions.setServiceInputValue({ inx: inx, prop: 'costprice', value: '' })
                                                                                            this.props.actions.setServiceInputValue({ inx: inx, prop: 'sellingprice', value: '' })
                                                                                            this.props.actions.setServiceInputValue({ inx: inx, prop: 'expirydate', value: '' })
                                                                                            this.props.actions.setServiceInputValue({ inx: inx, prop: 'vatable', value: '' })
                                                                                            // this.props.actions.setServiceInputValue({inx: inx, prop: 'editing', value: false})
                                                                                            this.props.actions.delService(inx);
                                                                                        }
                                                                                    }>
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    : <Row>
                                                                        <Col xl={3}>
                                                                            {x.servicedescription}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.costprice}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.sellingprice}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {moment(x.expirydate).format('YYYY-MM-DD')}
                                                                        </Col>
                                                                        <Col xl={2}>
                                                                            {x.vatable}
                                                                        </Col>
                                                                        <Col xl={1}>
                                                                            {
                                                                                <div style={{ marginTop: '20px' }}>
                                                                                    <IconButton key={'command_edit_service_' + x.inx} style={styles.delButton} iconStyle={styles.action}
                                                                                        onClick={
                                                                                            () => {
                                                                                                this.props.actions.setServiceInputValue({ inx: inx, prop: 'editing', value: true })
                                                                                            }
                                                                                        }>
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                    &nbsp;&nbsp;
                                                                                    <IconButton key={'command_delete_service_' + inx} style={styles.delButton} iconStyle={styles.action}
                                                                                        onClick={
                                                                                            () => {
                                                                                                this.props.actions.delService(inx)
                                                                                            }
                                                                                        }>
                                                                                        <DeleteForeverIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                            }
                                                        </Container>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <h4></h4>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    }

    getDocuments() {
        return <div>
            <Upload
                id={'supplier'}
                prefix={'Supplier'}
                header={false}
            /></div>
    }

    getCapture() {
        return <div>
            {this.getSupplier()}
            <Card
                title={"Supplier Accessories"}
                subtitle={window.t.en('Supplier accessories pricing')}
                content={this.getAccessories()}
            />
            <Card
                title={"Supplier Sundries"}
                subtitle={window.t.en('Supplier sundries pricing')}
                content={this.getSundries()}
            />
            <Card
                title={"Supplier Services"}
                subtitle={window.t.en('Supplier services pricing')}
                content={this.getServices()}
            />
            <Card
                title={"Support Documents"}
                subtitle={window.t.en('Upload supplier supporting documents')}
                content={this.getDocuments()}
            />
        </div>
    }


    getConfirm() {
        return <div>
            {this.getSupplier()}
            <Card
                title={"Supplier Accessories"}
                subtitle={window.t.en('Supplier accessories pricing')}
                content={<Fields data={this.props.state.accessories} props={['accessoryname', 'costprice', 'sellingprice', 'expirydate', 'vatable',]} columns={['Accessory Name', 'Cost Price', 'Selling Price', 'Expiry Date', 'Vatable']} />}
            />
            <Card
                title={"Supplier Sundries"}
                subtitle={window.t.en('Supplier sundries pricing')}
                content={<Fields data={this.props.state.sundries} props={['description', 'costprice', 'sellingprice', 'expirydate', 'vatable',]} columns={['Sundry Name', 'Cost Price', 'Selling Price', 'Expiry Date', 'Vatable']} />}
            />
            <Card
                title={"Supplier Services"}
                subtitle={window.t.en('Supplier services pricing')}
                content={<Fields data={this.props.state.services} props={['servicedescription', 'costprice', 'sellingprice', 'expirydate', 'vatable',]} columns={['Service Name', 'Cost Price', 'Selling Price', 'Expiry Date', 'Vatable']} />}
            />
            <Card
                title={"Supplier Documents"}
                subtitle={window.t.en('Uploaded supplier supporting documents')}
                content={<PdfViewer
                    key={'supplier_pricing'}
                    name={'supplier_pricing'}
                    documents={this.props.state.documents.documents}
                    edit={false}
                    multiple={false}
                    workflow_queue_data={null}
                />}
            />
        </div>
    }

    getLayout() {
        return <div>{
            this.props.mode === 'confirm'
                ? this.getConfirm()
                : this.getCapture()
        }
        </div>
    }

    render() {
        this.supplier = this.props.data[0].workflow_task_step_component[0].data[0]
        //console.log('AccessoryMasterCapturex this.supplier',this.supplier)
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.accessoryMasterCapture,
            documents: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;
    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessoryMasterCapture)