import { blue, pink, red } from "@mui/material/colors";
import { createTheme } from '@mui/material/styles';
 
const color1 = "#C7FFFA";
const color2 = "#1565C0";
const color3 = "#1976D2";
const color4 = "#1E88E5";

const primaryColor = "#253053";
const primaryIconColor = "#C7FFFA"
const primaryIconColorr = "#253053"
const secondaryColor = "#000000"
const primaryCardContentColor = "#C7FFFA"
const primaryCardContentColorr = "#253053"
//const secondaryColor = "#515152"
const primaryHoverColor = "#07a3ed"

const primaryTextColor = "#fff"

export const theme = createTheme({
  // palette: {
  //   primary: {
  //     light: blue[300],
  //     main: red[500],
  //     dark: blue[700],
  //   },
  //   secondary: {
  //     light: pink[300],
  //     main: "#11cb5f",
  //     dark: pink[700],
  //   },
  // },    
    containerMaxWidth: '100%',
    palette: {
        //height: "1200px",
        //type: "light",
        primary: {
            light: primaryColor,
            main: primaryColor,
            dark: primaryColor,
            contrastText: "#fff",
        },
        secondary: {
            light: primaryIconColor,
            main: "#131B3A",
            dark: primaryIconColor,
            contrastText: "#fff",
        },
        light: {
            light: "#fff",
            main: "#fff",
            dark: "#fff",
            contrastText: "#fff",
        },
    },
    components: {
        MuiButton: {
          styleOverrides: {
            root: {
              backgroundColor: "#253053",
              color: "#fff"
            }
          }
        },
        MuiCardHeader: {
          styleOverrides: {
            root: {
              backgroundColor: "#253053",
              color: "#fff"
            }
          }
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              backgroundColor: "#131B3A"
            },
            indicator: {
                borderTop: "0px red solid",
                borderBottom: "0px red solid",
                //color: "red",
                height: "2px"
            },
            "&:hover": {
              backgroundColor: "#131B3A"
            },
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              color: "#fff",
              "&.Mui-selected": {
                color: "#fff"
              }
            }
          }
        }
      }
    // components: {
    //     MuiTabs: {
    //         styleOverrides: {
    //             indicator: {
    //                 backgroundColor: 'orange',
    //                 height: 3,
    //             },
    //         },
    //     },
    // },
    // overrides: {
    //   // you declare you override default material-ui styles
    //   MuiTab: {
    //     styleOverrides: {
    //       root: {
    //         '&.Mui-selected': {
    //           color: 'red'
    //         }
    //       }
    //     }
    //   },
    //   MuiTabs: {
    //     root: {
    //       indicator: {
    //         backgroundColor: 'red'
    //       }
    //     }
    //   },
    //     MuiCssBaseline: {
    //       '@global': {
    //           '*::-webkit-scrollbar': {
    //               width: '0.4em'
    //             },
    //             '*::-webkit-scrollbar-track': {
    //               '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    //             },
    //             '*::-webkit-scrollbar-thumb': {
    //               backgroundColor: 'rgba(0,0,0,.1)',
    //               outline: '1px solid slategrey'
    //             }
    //       },
    //   },
    //   MuiAppBar: {
    //       root: {
    //           "&.MuiAppBar-root": {
    //               backgroundColor: 'white !important',
    //               marginRight: '2px',
    //               color: 'black !important'
    //           },
    //       }
    //   },
    //   MuiAutocomplete: {
    //       root: {
    //           "&.MuiSvgIcon-root": {
    //               backgroundColor: 'black !important',
    //               marginRight: '2px',
    //               color: 'black !important'
    //           },
    //       }
    //   },
    //   MuiSvgIcon: {
    //       root: {
    //           "&.MuiSvgIcon-root": {
    //               backgroundColor: 'black !important',
    //               marginRight: '2px',
    //               color: 'black !important'
    //           },
    //       }
    //   },
    //   MuiButton: {
    //       root: {
    //           "&.MuiButton-root": {
    //               backgroundColor: primaryColor,
    //               minWidth: "100px",
    //               border: 0,
    //               marginRight: '2px',
    //               color: primaryTextColor,
    //               "&:hover": {
    //                   backgroundColor: primaryHoverColor,
    //                   border: 0,
    //                   color: primaryTextColor,
    //               },
    //           },
    //       },
    //   },
    //   MuiBackdrop: {
    //       root: {
    //           "&.MuiBackdrop-root": {
    //               backgroundColor: 'transparent',
    //           },
    //       },
    //   },
    //   MuiButton: {
    //       root: {
    //           "&.MuiButton-root": {
    //               backgroundColor: primaryColor,
    //               minWidth: "100px",
    //               border: 0,
    //               marginRight: '2px',
    //               color: primaryTextColor,
    //               "&:hover": {
    //                   backgroundColor: primaryHoverColor,
    //                   border: 0,
    //                   color: primaryTextColor,
    //               },
    //           },
    //       },
    //   },
    //   MuiCheckbox:{
    //       // root:{
    //       //     backgroundColor: primaryColor,
    //       // },
    //       colorPrimary:{
    //           color: primaryColor,
    //       },
    //       MuiChecked: {
    //           backgroundColor: primaryColor,
    //       }
    //   },
    //   MuiInput: {
    //       formControl: {
    //           "label + &": {
    //               margin: 5,
    //               width: "100%",
    //           },
    //           MuiDrawer: {
    //               paper: {
    //                   //background: '#18202c',
    //                   //overflowY: true,
    //                   minWidth: "300px",
    //               },
    //           },
    //           MuiFormControl: {
    //               width: "100%",
    //           },
    //           MuiListItemText: {
    //               root: {
    //                   color: primaryTextColor,
    //                   backgroundColor: primaryColor,
    //               },
    //           },
    //           MuiList: {
    //               root: {
    //                   backgroundColor: primaryColor,
    //                   border: 0,
    //                   color: primaryTextColor,
    //               },
    //           },
    //       },

    //       MuiFormControl: {
    //           width: "100%",
    //       },
    //       MuiChip: {
    //           root: {
    //               color: primaryTextColor,
    //               backgroundColor: primaryColor,
    //           },
    //       }, 
    //   },
    //   MuiFormControl: {
    //       width: "100%",
    //   },
    //   MuiListItemText: {
    //       root: {
    //           backgroundColor: primaryColor,
    //           color: primaryTextColor,
    //           "&.MuiListItem-root": {
    //               backgroundColor: primaryColor,
    //               color: primaryTextColor,
    //           },
    //       },
    //   },
    //   MuiListItemIcon: {
    //       root: {
    //           minWidth : '38px',
    //       },
    //   },
    //   MuiListItem: {
    //       root: {
    //           "&.MuiListItem-root": {
    //               backgroundColor: primaryColor,
    //               border: 0,
    //               color: primaryTextColor,
    //           },
    //       },

    //   },
    //   MuiChip: {
    //       root: {
    //           color: primaryTextColor,
    //           backgroundColor: primaryColor,
    //       },
    //   },
    //   MuiToolbar: {
    //       root: {
    //           backgroundColor: '#fff',
    //           color: secondaryColor,
    //       },
    //   }, 
    //   MuiList: {
    //       padding: {
    //           paddingTop: '0px',
    //           paddingBottom: '0px',
    //       },
    //       root: {
    //           backgroundColor: primaryColor,
    //           border: 0,
    //           color: primaryTextColor,
    //           "&:hover": {
    //               backgroundColor: primaryHoverColor,
    //               color: primaryTextColor,
    //           },
    //       }, 
    //   },
    //   MuiTabs: {
    //         root: {
    //             backgroundColor: primaryColor,
    //             border: 0,
    //             color: primaryTextColor,
    //             "&:MuiTabs-scrollable": {
    //                 overflow: "none",
    //             },
    //             ".MuiTabs-scrollable": {
    //                 overflow: "none",
    //             }
    //         },
    //         ".MuiTabs-root": {
    //             backgroundColor: primaryColor,
    //             color: primaryTextColor,
    //         },
    //         scroller: {
    //           "&:MuiTabs-scrollable": {
    //               overflow: "none",
    //           },
    //           "&.MuiTabs-scrollable": {
    //               overflow: "none",
    //           }
    //         },
    //   },
    //   MuiTab: {
    //       root: {
    //           backgroundColor: primaryColor,
    //           border: 0,
    //           color: primaryTextColor,
    //           //borderBottom: "2px solid",
    //           "&:hover": {
    //               border: 0,
    //               color: primaryTextColor,
    //               borderBottom: "2px solid red",

    //           },
    //           "&.MuiTab-selected": {
    //               backgroundColor: primaryColor,
    //               borderTop: "2px solid red",
    //               borderBottom: "2px solid red",
    //               color: primaryTextColor,
    //           },
    //           "&:MuiTab-root": {
    //               backgroundColor: primaryColor,
    //               color: primaryTextColor,
    //           },
    //           ".MuiTab-root": {
    //               backgroundColor: primaryColor,
    //               color: primaryTextColor,
    //           },
    //       },
    //       ".MuiTab-root": {
    //           backgroundColor: primaryColor,
    //           color: primaryTextColor,
    //       },
    //   },
    //   MuiCardHeader: {
    //       root: {
    //           "&.MuiCardHeader-root": {
    //               backgroundColor: primaryColor,
    //               border: 0,
    //               borderBottom: "2px solid white",
    //               color: primaryTextColor,
    //           },
    //           "&:MuiCardHeader-subheader": {
    //               backgroundColor: primaryColor,
    //               border: 0,
    //               color: primaryTextColor,
    //               borderBottom: "2px solid white",
    //           },
    //       },
    //   },
    //   MuiCardContent : {
    //       root: {
    //           backgroundColor: primaryTextColor,
    //           color: secondaryColor,
    //       },
    //   },
    //   MuiButtonBase: {
    //       root: {
    //           color: primaryTextColor, 
    //       },
    //       "&.MuiButtonBase-root": {
    //           color: primaryTextColor,
    //           backgroundColor: primaryColor,
    //           paddingLeft: 20,
    //       } 
    //   },
    //   MuiTypography: {
    //       root: {
    //           color: primaryTextColor, 
    //       },
    //       "&.MuiTypography-root": {
    //           color: primaryTextColor,
    //           backgroundColor: primaryColor,
    //           paddingLeft: 20,
    //       } 
    //   },
    //   MuiIcon: {
    //       root: {
    //           color: primaryIconColor
    //       },
    //   },
    //   MuiSvgIcon: {
    //       root: {
    //           color: primaryIconColor,
    //           colorSecondary: primaryIconColor
    //       },
    //   },  
    //   MuiFormControlLabel: {
    //       root: {
    //           "&.MuiFormControlLabel-root": {
    //               paddingLeft: 20,
    //               verticalAlign: 'top'
    //           },
    //       },
    //       "&:MuiFormControlLabel-label": {
    //           width: "300px",
    //           textAlign: "right",
    //           verticalAlign: 'top'
    //       },
    //   },
    //   MuiListSubheader: {
    //       root: {
    //           color: primaryTextColor,
    //       }, 
    //   },
    //   MuiDialog: {
    //       backgroundColor: primaryIconColor,
    //       MuiPaper: {
    //           root:{
    //               backgroundColor: primaryIconColor,
    //           }
              
    //       },
    //   },
    // },
});