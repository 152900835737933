import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.GET_ADHOC_FIELD_INPUT_DATA:
            return {
                ...state,
                [action.payload.key]: {
                    loading: true,
                    key: action.payload.key,
                    data: []
                }
            }
        case types.SET_ADHOC_FIELD_INPUT_DATA:
            return {
                ...state,
                [action.payload.key]: {
                    ...action.payload,
                    loading: false,
                    data: action.payload.data
                }
            }
        case types.ADD_ADHOC_FIELD_INPUT_DATA:
            return {
                ...state,
                [action.payload.key]: {
                    ...action.payload,
                    data: action.payload.data
                }
            }
        case types.SET_ADHOC_COMPONENT_FIELD_INPUT:
            return {
                ...state,
                [action.payload.key]: {
                    ...action.payload,
                    data: action.payload.data
                }
            }
        case types.SET_LIST_DATA:
            return {
                ...state,
                [action.payload.key]: action.payload.data
            }
        case types.SET_COMPONENT_CUSTOMERTRANSACTION_DATA:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        case types.SET_NEW_INPUT_VALUE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            }
        case types.ADD_ROW:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        case types.REMOVE_ROW:
            return {
                ...state,
                [action.payload.prop]: action.payload.data
            }
        case types.SET_NEWITEM_INPUT_VALUE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    [action.payload.field]: {
                        ...state[action.payload.key][action.payload.field],
                        [action.payload.field]: action.payload.data
                    }
                }
            }
        case types.SET_DROP_ITEM:
            return {
                ...state,
                [action.payload.prop]: {
                    value: action.payload.value,
                    text: action.payload.text
                }
            }
        case types.SAVED_CUSTOMER_INVOICE_DATA:
            return {
                ...state,
                invoice_reference: null,
                customer_reference: null,
                invoice_description: null,
                licenseplateno: {},
                sub_total: 0,
                vat_total: 0,
                none_total: 0,
                grand_total: 0
            }
        case types.VALIDATE_ADHOC_FIELD_INPUT_DATA:
            return state
        case types.REMOVE_ADHOC_FIELD_INPUT_DATA:
            return state
        default:
            return state
    }
}