import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ReactTable from "react-table"
//import "../SUPPLIERPAYMENTAPPROVAL/node_modules/react-table/react-table.css"

import Loading from '../../Loading/component'

import * as actions from './actions'

class AdhocGrid extends Component {
    componentDidMount() {
        this.props.actions.adhocGridLoadToStateTree(this.props.name, this.props.component ? this.props.component : this.props.uri, this.props.body, this.props.columns, this.props.paging, this.props.search)
    }

    render() {
        const grid = this.props.state[this.props.name]

        return !grid
            ? <Loading message={'Configuring grid, just a moment...'} />
            : <div>
                <ReactTable
                manual
                key={'react_table_' + this.props.name}
                data={this.props.data ? this.props.data : grid.data}
                columns={grid.columns}
                defaultPageSize={grid.paging ? grid.paging.pageSize : 10}
                filterable={this.props.filterable}
                loading={this.props.loading ? this.props.loading : grid.loading}
                minRows={1}
                //pageSize={grid.paging ? grid.paging.pageSize : 10}
                pages={grid.paging ? grid.paging.pages : 1}
                showPageJump={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                style={{
                    height: this.props.height ? this.props.height : '100%',
                    width: '100%'
                }} 
                width='100%'
                getTrProps={(state, rowInfo, instance) => {
                    if (rowInfo) {
                      return {
                        style: Number(rowInfo.row.send_status ? rowInfo.row.send_status : 0)  > 0? {
                          background: '#90ee90',
                          color: '#000'
                        } : {}
                      }
                    }
                    return {};
                  }}
                SubComponent={this.props.subComponent}
                onFetchData={
                    this.props.onFetchData
                        ? this.props.onFetchData
                        : (state) => {
                            if (this.props.uri) {
                                this.props.actions.adhocGridGetData(this.props.name, this.props.uri, this.props.body, grid.paging, state, grid.search)
                            }else{
                                this.props.actions.adhocGridGetDataWithComponent(this.props.name, this.props.component, this.props.query)
                            }
                        }
                }
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.adhocGrid
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdhocGrid)