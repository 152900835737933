import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_EXCEL_FILE_IMPORTED_DATA_ROWS:
            return {
                ...state,
                rows: action.payload
            }
        case types.SET_SELECTED_EXCEL_FILE_TEMPLATE_DATA:
            return {
                ...state,
                template: action.payload
            }
        case types.SET_EXCEL_FILE_TEMPLATE_DATA_LIST:
            return {
                ...state,
                templates: action.payload
            }
        case types.SET_UPLOADED_EXCEL_FILE_NAME:
            return {
                ...state,
                document: action.payload
            }
        case types.SET_UPLOADED_EXCEL_FILE_BUSY:
            return {
                ...state,
                busy: action.payload
            }
        case types.UPLOAD_EXCEL_FILE_DATA_DONE:
            return {
                ...state,
                uploading: action.payload
            }
        case types.SET_INPUT:
            return {
                ...state,
                [action.payload.name]: action.payload.data
            }
        default:
            return state
    }
}