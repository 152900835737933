import * as colours from '@mui/material/colors'

export const fullWidth = {
    width: '100%',
}
export const inputWidth = {
    width: '100%',
    fontSize: '16px',
}

export const row = {
    padding: 5
}
export const fieldContainer = {
    display: 'table',
    margin: 4,
    padding: 4
} 

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '5px',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const tdValue = {
    width: '20%',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'right',
}
export const tdField = {
    width: '20%',
    textAlign: 'left',
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const checkbox = {
    display: 'table-cell',
    verticalAlign: 'middle',
    width: '300px',
    fontWeight: 'bold'
}

export const hr = {
    width: '100%',
    borderBottom: '2px solid red',
    padding: 5,
    textAlign: 'left',
}


export const title = {
    width: '100%',
    backgroundColor: '#D1E0E6',
    padding: 5,
    margin: 10,
    textAlign: 'left',
}

export const trField = {
    width: '100%',
    borderTop: '1px solid gray',
    padding: 2,
}

export const toggle = {
    verticalAlign: 'middle',
    display: 'table-cell',
    paddingTop: '16px',
}

export const vehicle = {
    height: '250px !important',
    width: '300px !important',
    border: '2px solid #fffbe5',
}

export const box = {
    width: '100%',
    backgroundColor: '#fffbe5',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}
export const box1 = {
    width: '100%',
    backgroundColor: '#cbe9f7',
    color: '#000',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}
export const box2 = {
    width: '100%',
    backgroundColor: '#d8fdd1',
    color: '#5c3c00',
    borderBottom: '2px solid #fffbe5',
    padding: 5,
    marginBottom: '10px',
    textAlign: 'left',
}

export const textBox = {
    paddingLeft: 30,
    textAlign: 'right',
    float: 'right',
}

export const textBoxInput = {
    textAlign: 'right',
}