import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Tabs, Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import DateControl from '../../../controls/dateControl'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewCustomer from '../../../controls/view_customer'
import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

import * as actions from './actions'
import * as styles from './styles'

class CostCentreChangeCapture extends Component {
    componentDidMount() {

        this.props.actions.setValidationState('costCentreChangeCapture')
        if (this.props.mode === "confirm") {
            this.props.actions.setupWorkflowTaskInput(this.props.state, [], 'costcentre_change', 'tsk_costcentre_change_capture')
        }
        else {
            let vehicle = this.props.data[0].workflow_task_step_component[0].data[0]
            this.props.actions.resetForm()
        }
    }

    // componentWillUnmount() {
    //     this.props.actions.resetForm()
    // }

    getVehicle() {
        return <div>
            <ViewVehicle
                key={this.vehicle.vehicle_id}
                id={this.vehicle.vehicle_id}
            />
        </div>
    }

    getForm() {
        return <div>
            <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12} style={{ paddingTop: '15px' }}>
                        <h5>CostCentre Details</h5>
                        <ul>
                            <li>Select a new costcentre to move the vehicle to</li>
                        </ul>
                    </Col>
                    <Col xl={6} style={{ paddingTop: '15px' }}>
                        <AutoComplete
                            name={'field_'}
                            title={'Select Vehicle New CostCenter'}
                            data={this.props.state.customer_data}
                            value={this.props.state.costcentre}
                            dataSourceConfig={{
                                text: 'text',
                                value: 'value'
                            }}
                            fieldvalue={'customer_id'}
                            displayfields={['costcentre', 'customername']}
                            displaywidth={['20', '80']}
                            onSelect={(args) => {
                                this.props.actions.setInputValue('costcentre', args.text)
                                this.props.actions.setInputValue('customer_id', args.value)
                            }}
                            onClick={() => {
                                this.props.actions.getLookupData('customer', 'costcentre', '')
                            }
                            }
                            onFilter={(search) => {
                                if (search) {
                                    this.props.actions.getLookupData('customer', 'costcentre', search)
                                    this.props.actions.setInputValue('costcentre', search)
                                } else {
                                    this.props.actions.getLookupData('customer', 'costcentre', '')
                                    this.props.actions.setInputValue('costcentre', '')
                                    this.props.actions.setInputValue('customer_id', null)
                                }
                            }}
                        />
                    </Col>

                    <Col xl={6} style={{ paddingTop: '10px' }}>

                        <DateControl
                            id={'txtcostcentrechangedate'}
                            key={'txtcostcentrechangedate'}
                            label='Select CostCentre Change Date'
                            name={'costcentrechangedate'}
                            value={this.props.state.costcentrechangedate}
                            defaultDate={this.props.state.costcentrechangedate}
                            onSelect={(args) => {
                                //console.log('args date',args)
                                this.props.actions.setInputValue('costcentrechangedate', args)
                            }}
                            type={'date'}
                        />
                    </Col>
                    <Col xl={12} style={{ paddingTop: '15px' }}></Col>
                    <Col xl={12} style={{ paddingTop: '15px' }}>
                        {
                            this.props.state.customer_id
                                ? <ViewCustomer
                                    key={this.props.state.customer_id}
                                    id={this.props.state.customer_id}
                                />
                                : ''
                        }

                    </Col>
                </Row>
            </Container>
        </div>
    }

    getDocuments() {
        return <div>
            <Upload
                id={'registration_change'}
                prefix={'Registration_Change'}
                header={false}
            /></div>
    }

    getCapture() {
        return <div>
            {this.getVehicle()}
            <Card
                title={"Vehicle CostCentre Change Details"}
                subtitle={window.t.en('Enter new vehicle registration number')}
                content={this.getForm()}
            />
            <Card
                title={"Support Documents"}
                subtitle={window.t.en('Upload supplier supporting documents')}
                content={this.getDocuments()}
            />
        </div>
    }


    getConfirm() {
        return <div>
            {this.getVehicle()}
            {/* <Card
            title={"New Vehicle CostCentre"}
            subtitle={window.t.en('Confirm new vehicle costcentre details')}
            content={<ViewCustomer
                key={this.props.state.customer_id }
                id={this.props.state.customer_id }
            />}
        />  */}
            <ViewCustomer
                key={this.props.state.customer_id}
                id={this.props.state.customer_id}
                title={"New Vehicle CostCentre"}
                subtitle={window.t.en('Confirm new vehicle costcentre details')}
            />
            <Card
                title={"Vehicle Registration Supporting Documents"}
                subtitle={window.t.en('Uploaded vehicle registration supporting documents')}
                content={<PdfViewer
                    key={'registration_change'}
                    name={'registration_change'}
                    documents={this.props.state.documents.documents}
                    edit={false}
                    multiple={false}
                    workflow_queue_data={null}
                />}
            />
        </div>
    }

    getLayout() {
        return <div>{
            this.props.mode === 'confirm'
                ? this.getConfirm()
                : this.getCapture()
        }
        </div>
    }

    render() {
        this.vehicle = this.props.data[0].workflow_task_step_component[0].data[0]
        //console.log('CostCentreChangeCapturex this.vehicle',this.vehicle)
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.costCentreChangeCapture,
            documents: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;
    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CostCentreChangeCapture)