export const comments = ''
export const allocationreason = ''
export const allocationreference = ''
export const transactionx = {}
export const win_view_documents = false

export const fields = {
  allocationreason: {
    errorText: 'Please input Allocation Reason',
    state: 'getState().creditorsPaymentCreate.allocationreason',
    valid: false
  },
  allocationreference: {
    errorText: 'Please input Allocation Reference',
    state: 'getState().creditorsPaymentCreate.allocationreference',
    valid: false
  },
}