import * as types from "./types";

export const showReassignTask = (
  mode,
  team_id,
  user_id,
  task,
  dialogStatus
) => {
  return (dispatch) => {
    dispatch({
      type: types.REASSIGN_TASK_SHOW,
      payload: {
        mode: mode,
        team_id: team_id,
        user_id: user_id,
        task: task,
      },
    });

    if (mode === "reject_task") {
      dispatch(setToggleRejectConfirm(true));
    }
  };
};

export const setToggleRejectConfirm = (status) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_REJECT_DIALOG,
            payload: {
                confirm: status,
            }
        })
    }
  };


export const setSelectedLookupValue = (val) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_SELECTED_WORKFLOW_REJECT_REASON,
      payload: val,
    });
  };
};

export const hideReassignTask = () => {
  return (dispatch) => {
    dispatch({
      type: types.REASSIGN_TASK_HIDE,
    });
  };
};

export const getTeams = () => {
  return (dispatch) => {
    dispatch({
      type: types.REASSIGN_TASK_GET_TEAMS,
    });
  };
};

export const setTeams = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.REASSIGN_TASK_SET_TEAMS,
      payload: data,
    });
  };
};

export const getTask = (workflow_queue_task_id) => {
  return (dispatch) => {
    dispatch({
      type: types.REASSIGN_TASK_GET_TASK,
      payload: workflow_queue_task_id,
    });
  };
};

export const setTask = (task) => {
  return (dispatch) => {
    dispatch({
      type: types.REASSIGN_TASK_SET_TASK,
      payload: task[0],
    });
  };
};

export const selectTeam = (team_id) => {
  return (dispatch) => {
    dispatch({
      type: types.REASSIGN_TASK_SELECT_TEAM,
      payload: team_id,
    });
  };
};

export const selectUser = (user_id) => {
  return (dispatch) => {
    dispatch({
      type: types.REASSIGN_TASK_SELECT_USER,
      payload: user_id,
    });
  };
};

export const setReassignTaskComment = (value, task) => {
  return (dispatch, getState) => {
    //console.log("setReassignTaskComment", task);
    //console.log("Comment value", value);
    let comments = value;
    try {
      if (!comments && task) {
        switch (task) {
          case "tsk_fml_quote_upload_preinspection_documents":
            comments = getState().workflowUploadPID.preinspectioncomments;
            break;
        case 'tsk_creditors_invoice_first_approval':
        case 'tsk_creditors_invoice_second_approval':
        case 'tsk_creditors_invoice_third_approval':
            comments = getState().paymentDOAApproval.comments;
            break;
          case "tsk_purchase_order_reveiw_and_approve_quote_tech":
            comments = getState().poBookingApproval.comments;
            break;
          case "tsk_purchase_order_reveiw_and_approve_quote_ops":
            comments = getState().poBookingApprovalOps.comments;
            break;
          case "tsk_purchase_order_reveiw_and_approve_quote_gm":
            comments = getState().poBookingApprovalOps.comments;
            break;
          case "tsk_purchase_order_reveiw_and_approve_quote_ceo":
            comments = getState().poBookingApprovalCEO.comments;
            break;
          case "tsk_rebill_upload_invoice":
            comments = getState().poBookingRebillInvoiceUpload.comments;
            break;
          case "tsk_rebill_review_and_approve_tech":
            comments = getState().poRebillApprovalByTech.comments;
            break;
          case "tsk_rebill_review_and_approve_ops":
            comments = getState().poRebillApprovalByOps.comments;
            break;
          case "tsk_purchase_order_close_booking":
            comments = getState().poCloseBooking.comments;
            break;
          case "tsk_mm_activate_contract":
            comments = getState().activateMMContract.comments;
            break;
          case "tsk_rebill_review_and_approve_gm":
            comments = getState().poRebillApprovalByGM.comments;
            break;
          case "tsk_rebill_review_and_approve_ceo":
            comments = getState().poRebillApprovalByCEO.comments;
            break;
          case "tsk_purchase_order_send_authorisation":
            comments = getState().poBookingSendAuth.comments;
            break;
          case "tsk_rebill_send_authorisation":
            comments = getState().poBookingSendRebill.comments;
            break;
          case "tsk_rebill_close_booking":
            comments = getState().poCloseRebillBooking.comments;
            break;
          case "tsk_defleet_close_booking":
            comments = getState().defleetCloseBooking.comments;
            break;
          case "tsk_defleet_contract_termination":
            comments = getState().defleetContractTermination.comments;
            break;
          case "tsk_defleet_termination_request":
            comments = getState().defleetTerminationRequest.comments;
            break;
          case "tsk_defleet_repair_checksheet":
            comments = getState().defleetRepairChecksheet.comments;
            break;
          case "tsk_defleet_disposal":
            comments = getState().defleetDisposal.comments;
            break;
          case "tsk_defleet_disposal_approve":
            comments = getState().defleetDisposalApproval.comments;
            break;
          case "tsk_defleet_disposal_approve_ceo":
            comments = getState().defleetDisposalApprovalCEO.comments;
            break;
          case "tsk_defleet_termination_request_approve_tech":
            comments = getState().defleetTerminationRequestApproval.comments;
            break;
          case "tsk_defleet_termination_request_approve_gm":
            comments = getState().defleetTerminationRequestApproval.comments;
            break;
          case "tsk_defleet_termination_request_approve_ceo":
            comments = getState().defleetTerminationRequestApproval.comments;
            break;
          case "tsk_payment_approve_invoice":
            comments =  getState().paymentApprovalAdmin
            break;
          case "tsk_payment_approval_finance":
            comments =  getState().paymentApprovalFinance
            break;
          case "tsk_payment_approval_gm":
            comments = getState().paymentApprovalGM
            break;
          case "tsk_payment_approval_ops":
            comments = getState().paymentApprovalOPS
            break;
          case "tsk_payment_approval_tech":
            comments = getState().paymentApprovalTech
            break;
          case "tsk_payment_release":
            comments = getState().paymentApprovalRelease
            break;
          case "tsk_payment_authorisation":
            comments = getState().paymentApprovalAuthorisation
            break;
          case "tsk_rebill_tech_payment_approval":
          case "tsk_rebill_gm_payment_approval":
          case "tsk_rebill_coo_payment_approval":
          case "tsk_defleet_tech_payment_approval":
          case "tsk_defleet_gm_payment_approval":
          case "tsk_defleet_coo_payment_approval":
          case "tsk_purchase_order_tech_payment_approval":
          case "tsk_purchase_order_gm_payment_approval":
          case "tsk_purchase_order_coo_payment_approval":
            comments = getState().doaGenericApproval.comments;
            break;
          case "tsk_defleet_upload_checksheet":
            comments = getState().defleetUploadChecksheet.comments.value;
            break;
          case "tsk_rebill_accept_customer_rebill":
            comments = getState().poBookingAcceptRebill.comments;
            break;
          case "tsk_defleet_accept_customer_rebill":
            comments = getState().defleetAcceptRebill.comments;
            break;
          case "tsk_defleet_rebill_dispute":
            comments = getState().defleetRebillDispute.comments;
            break;
          case "tsk_defleet_review_and_approve_tech":
            comments = getState().defleetQuoteApproval.comments;
            break;
          case "tsk_defleet_review_and_approve_ops":
            comments = getState().defleetQuoteApprovalOPS.comments;
            break;
          case "tsk_defleet_review_and_approve_gm":
            comments = getState().defleetQuoteApprovalGM.comments;
            break;
          case "tsk_defleet_review_and_approve_ceo":
            comments = getState().defleetQuoteApprovalCEO.comments;
            break;
          case "tsk_str_quote_accept_customer_str_quote":
            comments = getState().acceptCustomerSignedSTR.comments;
            break;
          case "tsk_fml_quote_upload_pod_documents":
            comments = getState().workflowUploadPOD.comments;
            break;
          case "tsk_fml_quote_waiting_vehicle_delivery":
            comments = getState().workflowVehicleDeliveryConfirm.comments;
            break;
          default:
            return "";
        }
      }
    } catch (error) {
      //console.log("setReassignTaskComment error", error);
    }
    //console.log("setReassignTaskComment comments", comments);
    dispatch({
      type: types.REASSIGNMENT_TASK_SET_COMMENT,
      payload: comments,
    });
  };
};

export const setSelectedReassignTaskComment = (value) => {
  return (dispatch) => {
    dispatch({
      type: types.REASSIGNMENT_TASK_SET_SELECTION_COMMENT,
      payload: value,
    });
  };
};
