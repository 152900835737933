import * as validatorTypes from '../Validator/types'
import * as types from './types'
import * as props from './props'

export default (state = props, action) => {
    switch (action.type) {
        case validatorTypes.TOGGLE_SWITCH:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.field.id]: {
                        ...action.payload.field,
                        checked: action.payload.checked
                    }
                }
            }
        case validatorTypes.TOGGLE_PASSWORD_VISIBILITY:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.field.id]: {
                        ...action.payload.field,
                        type: action.payload.type
                    }
                }
            }
        case validatorTypes.VALIDATE_FIELD:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.payload.id]: {
                        ...action.payload,
                        input: action.payload.input,
                        empty: action.payload.empty,
                        errorText: action.payload.valid ? '' : action.payload.errorText
                    }
                }
            }
        case validatorTypes.SET_FORM_STATUS:
            return {
                ...state,
                valid: action.payload
            }
            
        case types.TOGGLE_LOGIN_AND_RESETPASSWORD_SCREEN:
            return {
                ...state,
                screen: action.payload
            }
        case types.TOGGLE_SHOW_PASSWORD:
            return {
                ...state,
                showPassword: action.payload
            }
        case types.SET_JSREPORT_BUFFER_STREAM_OBJECT:
            return {
                ...state,
                url: action.payload.url,
                buffer: action.payload.buffer
            }
        default:
            return state
    }
}