import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import AutoComplete from '@mui/material/Autocomplete'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import NavigationExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import {
    Grid,
    Dialog,
    TextField,
    IconButton
} from '@mui/material';

import Icon from '../components/Icon/component'
import View from '../components/View/component'
import * as actions from './actionscontact'
import { styled } from '@mui/material/styles';

const TextFieldx = styled(TextField)({
    "& .MuiFormLabel-root": {
        fontSize: '14px'
    },
    "& .MuiFormLabel-filled": {
        color: "black",
        fontWeight: 600,
        fontSize: '14px'
    },
    root: {
        "& .MuiFormLabel-filled": {
          color: "black",
          fontWeight: 600
        }
    }
});

class _autocompletecontact extends Component {

    getAutoComplete() {
        return <div style={{margin: '10px 0px'}}>
            <AutoComplete
            id={this.props.name}
            key={this.props.name}
            disabled={this.props.disabled ? true : false}
            //width={this.props.width ? this.props.width : null}
            sx={this.props.sx ? this.props.sx : {}}
            style={this.props.style ? this.props.style : {}}
            inputStyle={this.props.inputStyle ? this.props.inputStyle : {}}
            autoHighlight
            fullWidth={false}
            size={"small"}
            options={this.props.data ? this.props.data : this.props.dataSource}
            getOptionLabel={(option) => {
                //console.log('getOptionLabelx xx',option)
                return option[this.props.fieldtext ? this.props.fieldtext : 'text']
            }}
            renderInput={(params) => {
                let _params = this.props.value ? {
                    ...params,
                    inputProps: {
                        ...params.inputProps,
                        value: this.props.value,
                        style: {fontSize: 14}
                    }
                } : params
                
                return <TextFieldx
                    {..._params}
                    error={this.props.error}
                    name={this.props.name}
                    label={this.props.title ? this.props.title : this.props.hintText ? this.props.hintText : ''}
                    //helperText={this.props.helperText}
                    style={{fontSize: 14}}
                    fullWidth={true}
                    size={"small"}
                    variant="outlined"
                />
            }}
            helperText={this.props.title}
            onOpen={
                (chosenRequest, index) => {
                    this.props.onClick()
                }
            }
            onInputChange={
                (event, search) => {
                    this.props.onFilter ? this.props.onFilter(search) : this.props.onUpdateInput(search)
                }
            }
            onChange={
                (event, request) => {
                    //console.log("//console.log(request) request", request)
                    //console.log("//console.log(request) event", event)
                    //console.log("//console.log(request) data", this.props.data)
                    if (request)
                        this.props.onNewRequest 
                            ? this.props.onNewRequest({ 
                                value: this.props.fieldvalue ? request[this.props.fieldvalue] : request.value, 
                                text: this.props.fieldtext ? request[this.props.fieldtext] : request.text 
                            }) 
                            : this.props.onSelect(this.props.returnAllFields 
                                ? request 
                                :  { 
                                    value: this.props.fieldvalue ? request[this.props.fieldvalue] : request.value, 
                                    text: this.props.fieldtext ? request[this.props.fieldtext] : request.text 
                                })
                }
            }
        />
            {
                this.props.info
                ? this.props.info.show && <IconButton touch={true} style={{ position: 'absolute', right: '80px',top: '0px' }}
                    onClick={() => {
                        this.props.actions.onInfo(this.props.info)
                    }}>
                    {/* <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'pageview'} /> */}
                    <VisibilityIcon />
                </IconButton>
                : ''
            }
            {
                this.props.onCustomerIcon
                ? <IconButton touch={true} style={{ position: 'absolute', right: '-14px', top: '10px' }}
                    onClick={() => {
                        this.props.onCustomerIcon()
                    }}>
                    <AddIcon />
                </IconButton>
                : ''


            }
            {
                this.props.state.info
                    ? this.props.state.info.component === this.props.component_reference && <Dialog
                        scroll="body"
                        maxWidth="lg"
                        style={{ minWidth: '50%' }}
                        open={this.props.state.info !== null}
                        onClose={() => {
                            this.props.actions.onInfo(null)
                        }}
                        classes={{ paper: 'modal-content border-0 p-4' }}
                    >
                        <div className="p-4">
                            <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <a
                                        href="#/"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.props.actions.onInfo(null)
                                        }}
                                        className="font-size-xxl d-block mb-4">
                                        {this.props.state.info ? this.props.state.info.title : ''}
                                    </a>
                                    {
                                        this.props.state.info && <View
                                            key={'readComponent_View_' + this.props.state.info.component + '_' + this.props.state.info.value}
                                            id={this.props.state.info.value}
                                            name={this.props.state.info.component}
                                            expanded={true}
                                            surrogate={false}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </Dialog>
                    : ''
            }
        </div>
    }

    getWithFieldsAutoComplete() {
        let displaywidth = this.props.displaywidth
        return <div style={{margin: '20px 0px'}}>
            <AutoComplete
                id={this.props.name}
                autoHighlight
                multiple={this.props.multiple}
                disableCloseOnSelect={this.props.disableCloseOnSelect}
                fullWidth={false}
                inputProps={{style: {fontSize: 14}}}
                size={"small"}
                disabled={this.props.disabled ? true : false}
                sx={this.props.sx ? this.props.sx : {}}
                style={this.props.style ? this.props.style : {}}
                inputStyle={this.props.inputStyle ? this.props.inputStyle : {}}
                options={this.props.data ? this.props.data : this.props.dataSource}
                getOptionLabel={
                    (option) => {
                        //console.log('_autocompletex getOptionLabel',option)
                        let optionText = ''
                        for(let t of this.props.displayfields)
                        {
                            optionText += option[t]
                        }
                        return optionText
                    }
                }
                renderOption={(props, option) => {
                    //console.log('_autocompletex renderOption',option)
                    return <React.Fragment>
                        <table style={{ width: '100%' }} >
                            <tr>
                                {
                                    this.props.checkbox 
                                    ? <td style={{width: '30px'}}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={option?.[this.props.selected]?.length}
                                        />
                                    </td>
                                    : ''
                                }
                                {
                                    this.props.discdate
                                    ? <td style={{ textAlign: 'left', fontWeight: option[this.props.discdate] ? 'normal' : 'bold',color: option[this.props.discdate] ? '#FF1010' : '#000000'}} {...props}>
                                        <label >{option[this.props.displayfields[0]]}</label><br />
                                        {
                                            this.props.displayfields.map((x, i) => {
                                                return i === 0 ? '' : <small style={{ width: displaywidth ? displaywidth[i] + '%' : (100 / (this.props.displayfields.length - 1) + '%'),fontWeight: option[this.props.discdate] ? 'normal' : 'bold',color: option[this.props.discdate] ? '#FF1010' : '#000000'}}>{option[x]}  </small>
                                            })
                                        }
                                        {
                                            option[this.props.discdate] 
                                            ? <div><small >Discontinued Date {option[this.props.discdate]}</small></div> : ''
                                        }
                                    </td>
                                    : option[this.props.descfields]
                                    ? moment().isAfter(option[this.props.descfields] ? moment(option[this.props.descfields]).format('YYYY-MM-DD') : '', 'day')
                                        ? <td style={{textAlign: 'left',background: 'red', color: 'white'}} {...props}>
                                            <label>{option[this.props.displayfields[0]]}</label><br />
                                            {
                                                this.props.displayfields.map((x, i) => {
                                                    return i === 0 ? '' : <small style={{ width: displaywidth ? displaywidth[i] + '%' : (100 / (this.props.displayfields.length - 1) + '%') }}>{option[x]}</small>
                                                })
                                            }
                                            {
                                                this.props.descfields
                                                ? <div><small>{option[this.props.descfields] ? moment(option[this.props.descfields]).format('YYYY-MM-DD') : ''}</small></div> : ''
                                            }
                                        </td>
                                        :<td style={{textAlign: 'left'}} {...props}>
                                            <label>{option[this.props.displayfields[0]]}</label><br />
                                            {
                                                this.props.displayfields.map((x, i) => {
                                                    return i === 0 ? '' : <small style={{ width: displaywidth ? displaywidth[i] + '%' : (100 / (this.props.displayfields.length - 1) + '%') }}>{option[x]}    </small>
                                                })
                                            }
                                            {
                                                this.props.descfields
                                                ? <div><small>{option[this.props.descfields] ? moment(option[this.props.descfields]).format('YYYY-MM-DD') : ''}</small></div> : ''
                                            }
                                        </td>
                                    : <td style={{textAlign: 'left'}} {...props}>
                                    <div>{option[this.props.displayfields[0]]}<br />
                                        {
                                            this.props.displayfields.map((x, i) => {
                                                return i === 0 ? '' : <small style={{ width: displaywidth ? displaywidth[i] + '%' : (100 / (this.props.displayfields.length - 1) + '%') }}>{option[x]}    </small>
                                            })
                                        }
                                    </div>
                                </td>
                                }
                            </tr>
                        </table>
                    </React.Fragment>
                }}
                renderInput={(params) => {
                    let _params = this.props.checkbox ? {
                        ...params,
                        inputProps: {
                            ...params.inputProps,
                            value: this?.props?.value,
                            style: {fontSize: 14}
                        }
                    } : this.props.value ? {
                        ...params,
                        inputProps: {
                            ...params.inputProps,
                            value: this?.props?.value,
                            style: {fontSize: 14}
                        }
                    } : params

                    return <TextFieldx
                        {..._params}
                        error={this.props.required ? this.props.value === undefined ? false : this.props.value ? false : true: false}
                        name={this.props.name}
                        label={this.props.title ? this.props.title : this.props.hintText ? this.props.hintText : ''}
                        helperText={this.props.helperText}
                        style={{fontSize: 14}}
                        fullWidth={true}
                        size={"small"}
                        variant="outlined"
                    />
                }}
                helperText={this.props.title}
                onOpen={
                    (chosenRequest, index) => {
                        this.props.onClick()
                    }
                }
                onInputChange={
                    (event, search) => {
                        this.props.onFilter ? this.props.onFilter(search) : this.props.onUpdateInput(search)
                    }
                }
                onChange={
                    (event, request) => {
                        //console.log('_autocompletex onChange request',request)
                        //console.log('_autocompletex onChange event',event)
                        if (request) {
                            this.props.onSelect(this.props.returnAllFields ? request : { value: this.props.fieldvalue ? request[this.props.fieldvalue] : request.value, text: this.props.displayfields ? request[this.props.displayfields[0]] : request.text })
                        }
                        //this.props.onNewRequest?this.props.onNewRequest({value:request.value,text:request.text}):this.props.onSelect({value:request.value,text:request.text})
                    }
                }
            />
            
            {
                this.props.onCustomerIcon
                ? <IconButton touch={true} style={{ position: 'absolute', right: '-14px', top: '10px' }}
                    onClick={() => {
                        this.props.onCustomerIcon()
                    }}>
                    <AddIcon />
                </IconButton>
                : ''
            }
        </div>
    }

    getLayout() {
        return this.props.displayfields
            ? this.getWithFieldsAutoComplete()
            : this.getAutoComplete()
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.controls }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(_autocompletecontact)