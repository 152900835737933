import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment';
import { Container, Row, Col } from 'react-grid-system'
import * as _ from 'lodash'
import Loading from '../Loading/component'
import PdfViewer from '../PDF/component'

import TextField from '@mui/material/TextField';
import Card from './../../controls/card'

import * as actions from './actions'
import * as styles from './styles'
import ViewVehicle from './../../controls/view_vehicle'
import ViewMaintenance from './../../controls/view_maintenance'
import Upload from '../Upload/component'


class CancelledTasksApproval extends Component {

    cancelDetails(data) {
        return data
            ? <div>
                <Card
                    title={window.t.en('Cancellation Details')}
                    subtitle={window.t.en('Cancellation Information')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                        <Col xl={2} style={styles.fieldLabel}><b>{'Workflow Type:'}</b></Col>
                        <Col xl={4} style={styles.selectedFieldName}>{data.cancelled_workflow}</Col>
                    </Row>
                    <Row>
                        <Col xl={2} style={styles.fieldLabel}><b>{'Workflow Step:'}</b></Col>
                        <Col xl={4} style={styles.selectedFieldName}>{data.cancelled_task}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}><br></br></Col>
                    </Row>
                    <Row>
                        <Col xl={2} style={styles.fieldLabel}>{'Reject Reason:'}</Col>
                        <Col xl={4} style={styles.selectedFieldName}>{data.selectcomments}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'User Comments:'}</Col>
                        <Col xl={4} style={styles.selectedFieldName}>{data.comments}</Col>
                    </Row>
                    <Row>
                        <Col xl={2} style={styles.fieldLabel}>{'Reject Date:'}</Col>
                        <Col xl={4} style={styles.selectedFieldName}>{moment(data.rejected_data).format('YYYY-MM-DD HH:mm')}</Col>
                        <Col xl={2} style={styles.fieldLabel}>{'Reject By:'}</Col>
                        <Col xl={4} style={styles.selectedFieldName}>{data.rejected_by}</Col>
                    </Row>
                    </Container>} />
            </div>
            : ''
    }
    docDetails(data) {
        return data.data.documents.length
            ? <div>
                <Card
                    title={window.t.en('Related Documents ')}
                    subtitle={window.t.en('Supporting Documents')}
                    content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {
                                    data.data.documents
                                        ? <PdfViewer
                                            key={'document_viewer_approval'}
                                            name={'booking'}
                                            workflow={'fml_customer_quote'}
                                            task={'tsk_purchase_order_review_and_send'}
                                            documents={data.data.documents}
                                            edit={false}
                                            default_document={'Authorisation'}
                                            workflow_queue_data={this.props.workflow_queue_data}
                                        />
                                        : <Loading message={'Retrieving Booking Document...'} />
                                }
                            </Col>
                        </Row>
                    </Container>} />
            </div>
            : ''
    }
    taskDetails(data) {
        const step = data.data.step1
        return data
            ? <div>
                <Card
                    title={window.t.en('Task Details ')}
                    subtitle={window.t.en('Details for cancellation')}
                    content={<div><Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'Description:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{step.po}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Created Date:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{step.preauthdate}</Col>
                            </Row>
                            <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'Pre Auth Number:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{step.preauthnumber}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Vehicle Registration:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{step.vehicle}</Col>
                            </Row>
                            <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'Supplier:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{step.supplier}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Supplier Type:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{step.datestamp}</Col>
                            </Row>
                    </Container>

                    {
                            data.data.step2.quotetotal
                                ? <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={2} style={styles.fieldLabel}>{'Quote Date:'}</Col>
                                        <Col xl={4} style={styles.selectedFieldName}>{moment(data.data.step2.datestamp).format('YYYY-MM-DD HH:mm')  }</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'Quoted Amount Excl:'}</Col>
                                        <Col xl={4} style={styles.selectedFieldName}>{data.data.step2.quotetotal.total_amount_exl}</Col>
                                    </Row>
                                    <Row>
                                        <Col xl={2} style={styles.fieldLabel}>{'Discounted Amount:'}</Col>
                                        <Col xl={4} style={styles.selectedFieldName}>{data.data.step2.quotetotal.total_amount_dis}</Col>
                                        <Col xl={2} style={styles.fieldLabel}>{'Quoted Amount Inc:'}</Col>
                                        <Col xl={4} style={styles.selectedFieldName}>{data.data.step2.quotetotal.total_amount_inc}</Col>
                                    </Row>

                                </Container>
                                : ''
                        }
                        {
                            data.data.step2.rebill_items
                            ? <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={12} style={styles.selectedFieldName}><br /></Col>
                            </Row>
                            <Row>
                                <Col xl={2} style={styles.selectedFieldName}>{'Class'}</Col>
                                <Col xl={3} style={styles.selectedFieldName}>{'Description'}</Col>
                                <Col xl={1} style={styles.selectedFieldName}>{'Rebill'}</Col>
                                <Col xl={1} style={styles.selectedFieldName}>{'Quantity'}</Col>
                                <Col xl={1} style={styles.selectedFieldName}>{'Price'}</Col>
                                <Col xl={1} style={styles.selectedFieldName}>{'Discount'}</Col>
                                <Col xl={1} style={styles.selectedFieldName}>{'VAT'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{'Amount'}</Col>
                            </Row>
                            {
                                data.data.step2.rebill_items && data.data.step2.rebill_items.map(x => {
                                    return <Row>
                                        <Col xl={2}>{x.Class}</Col>
                                        <Col xl={3}>{x.Description}</Col>
                                        <Col xl={1}>{x.Rebill ? 'Yes' : 'No'}</Col>
                                        <Col xl={1}>{x.Quantity}</Col>
                                        <Col xl={1}>{x.Price}</Col>
                                        <Col xl={1}>{x.DiscountAmount}</Col>
                                        <Col xl={1}>{x.VAT}</Col>
                                        <Col xl={2}>{x.Amount}</Col>
                                    </Row>
                                })
                            }
                              {
                                    data.data.step2.auth_items && data.data.step2.auth_items.map(x => {
                                        return <Row>
                                            <Col xl={2}>{x.Class}</Col>
                                            <Col xl={3}>{x.Description}</Col>
                                            <Col xl={1}>{x.Rebill ? 'Yes' : 'No'}</Col>
                                            <Col xl={1}>{x.Quantity}</Col>
                                            <Col xl={1}>{x.Price}</Col>
                                            <Col xl={1}>{x.DiscountAmount}</Col>
                                            <Col xl={1}>{x.VAT}</Col>
                                            <Col xl={2}>{x.Amount}</Col>
                                        </Row>
                                    })
                                }
                            </Container>
                            : ''
                        }
                    </div>} />
            </div>
            : ''
    }
    defleetTaskDetails(data) {
        const step = data.data.step3 ? data.data.step3 : data.data.step2 ? data.data.step2 : data.data.step1
        //console.log(step)
        return step
            ? <div>
                <Card
                    title={window.t.en('Task Details ')}
                    subtitle={window.t.en('Task Cancellation Information')}
                    content={<div><Container style={{ margin: 0 }} className="custom-container">
                         <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'Created Date:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{moment(step.datestamp).format('YYYY-MM-DD HH:mm')}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Created By:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{step.user.length ? step.user[0].firstname + ' ' + step.user[0].lastname  : step.user.firstname + ' ' + step.user.lastname }</Col>
                            </Row>
                            <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'Defleet Number:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.data.defleetNumber}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Defleet Type:'}</Col>
                                <Col xl={4} style={styles.selectedFieldName}>{data.data.step1.data.selectedDefleetType.text}</Col>
                            </Row>          
                    </Container>
                        {
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={12} style={styles.selectedFieldName}><br /></Col>
                                </Row>
                                {data.data.step3 ?
                                    <Row>
                                        <Col xl={2} style={styles.selectedFieldName}>{'Class'}</Col>
                                        <Col xl={3} style={styles.selectedFieldName}>{'Description'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Rebill'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Quantity'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Price'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'Discount'}</Col>
                                        <Col xl={1} style={styles.selectedFieldName}>{'VAT'}</Col>
                                        <Col xl={2} style={styles.selectedFieldName}>{'Amount'}</Col>
                                    </Row> : ''}
                                {data.data.step3 ?
                                    data.data.step3.rebill_items.map(x => {
                                        return <Row>
                                            <Col xl={2}>{x.Class}</Col>
                                            <Col xl={3}>{x.Description}</Col>
                                            <Col xl={1}>{'Yes'}</Col>
                                            <Col xl={1}>{x.Quantity}</Col>
                                            <Col xl={1}>{x.Price}</Col>
                                            <Col xl={1}>{x.DiscountAmount}</Col>
                                            <Col xl={1}>{x.VAT}</Col>
                                            <Col xl={2}>{x.Amount}</Col>
                                        </Row>
                                    })
                                    : ''
                                }
                            </Container>
                        } </div>} />
                <br></br>
                <ViewVehicle key={data.data.step1.data.selectedVehicleDetails.data[0].vehicle_id} id={data.data.step1.data.selectedVehicleDetails.data[0].vehicle_id} />
            </div>
            : ''
    }
    getForm(data) {
        return <Card
            title={window.t.en('Comments')}
            subtitle={window.t.en('Enter comments')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            id={'txt_podcomments'}
                            label={'Enter Comments'}
                            variant="outlined"
                            fullWidth={true}
                            value={this.props.state.comments}
                            onChange={(event, value) => {
                                this.props.actions.setValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>} />
    }
    uploadDocuments() {
        return <Card 
        title={'Cancellation Supporting Documents '}
        subtitle={'Please upload any supporting documents.'}
        content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <Upload
                                    id={'upload_ctl'}
                                    prefix={'cancellation_supporting_documents'}
                                    header={false}
                                />
                            </Col>
                        </Row>
                    </Container>}/>
    }

    getDefleet(data) {
        //console.log("Switch for De-fleet",data)
        let step = data.data.step1.step ? data.data.step1.step : ''
        return <Card
            title={window.t.en('Task Cancellation')}
            subtitle={window.t.en('Details of the task cancellation')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                {this.cancelDetails(data)}
                {step == 'Generate Check Sheet' || step == 'Generate Checksheet' ? this.defleetTaskDetails(data) : this.taskDetails(data)}
                {this.docDetails(data)}
                {this.uploadDocuments()}
                {this.getForm()}
            </Container>} />
    }

    getAuth(data) {
        //console.log("Switch for Auth",data)
        //console.log("data.step1.maintenancetransaction_id",data.data.step1.maintenancetransaction_id)
        return <Container style={{ margin: 0 }} className="custom-container">
                {this.cancelDetails(data)}
                <ViewMaintenance key={data.data.step1.maintenancetransaction_id} id={data.data.step1.maintenancetransaction_id} />
                {this.getForm()}
            </Container>
    }

    getDefault(data) {
        return <Card 
        title={'Cancellation '}
        subtitle={'Workflow task to cancel.'}
        content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={4}>
                                <b>Task</b> {data.cancelled_task}
                            </Col>
                            <Col xl={4}>
                                <b>Workflow</b> {data.cancelled_workflow}
                            </Col>
                            <Col xl={4}>
                                <b>Comments</b> {data.comments}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>
                                <b>Rejected By</b> {data.rejected_by}
                            </Col>
                            <Col xl={4}>
                                <b>Reject Date</b> {data.rejected_data}
                            </Col>
                            <Col xl={4}>
                                <b>Reject Reason</b> {data.rejected_reason}
                            </Col>
                            <Col xl={4}>
                                <b>Comments</b> {data.selectcomments}
                            </Col>
                        </Row>
                    </Container>}/>
    }

    getView(data) {
        //console.log("Switch for De-fleet",data)
        //let step = data.data.step1.step ? data.data.step1.step : ''
        switch(data.cancelled_workflow)
        {
            case 'Prepare a Customer Rebill Request':
            case 'Prepare a Technical Purchase Order':
                return this.getAuth(data)
            case 'Defleet':
                return this.getDefleet(data)
            //case 'Adhoc Quotation':
            //    return this.getAdhocQuotation(data)
            default:
                return this.getDefault(data)
        }
    }

    render() {
        const workflow_queue_data = this.props.workflow_queue_data
        //console.log('workflow_queue_data.context_data',workflow_queue_data.context_data)
        let _data = null
        try{
            _data = workflow_queue_data.context_data.data.data.data.length 
                ? workflow_queue_data.context_data.data.data.data[0]
                : workflow_queue_data.context_data.data.data.data
        }
        catch(err) {

        }
        //console.log('workflow_queue_data.context_data _data',_data)
        let data = null
        try {
            data = {
                data: _data,
                rejected_reason: workflow_queue_data.rejected_reason,
                selectcomments: workflow_queue_data.data.selectcomments,
                comments: workflow_queue_data.data.comments,
                rejected_by: workflow_queue_data.data.user[0].firstname,
                rejected_data: workflow_queue_data.created_at,
                cancelled_workflow: workflow_queue_data.data.workflow.title,
                cancelled_task: workflow_queue_data.data.workflow_task.title,
            }
        }
        catch (err) { }

        return (
            data
                ? this.getView(data)
                : 'Loading, Please wait...'
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.cancelledTasksApproval
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelledTasksApproval)