import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField'

import { Card, CardHeader, CardContent } from '@mui/material'

import Icon from '../Icon/component'
import Theme from '../../theme'
import WorkflowInput from '../WorkflowInput/component'
import WorkflowView from '../WorkflowView/component'

import * as actions from './actions'
import * as styles from './styles'
import component from '../Grid/component';

class WorkflowTaskStepComponentSingleCreator extends Component {

    render() {
        this.component = this.props.state.input

        this.step = this.component.task.workflow_task_step.filter(x =>
            x.name === this.props.step &&
            x.workflow_task_step_component.filter(y => y.component.name === this.props.name).length
        )

        this.data = this.step.reduce((arr, x) => {
            if (x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length).length) {
                arr.push(x.workflow_task_step_component.filter(y => y.component.name === this.props.name && y.data.length)[0].data)
            }

            return arr
        }, [])

        this.fields = this.step.reduce((arr, x) => {
            if (x.workflow_task_step_component.filter(y => y.component.name === this.props.name).length) {
                arr.push(x.workflow_task_step_component.filter(y => y.component.name === this.props.name)[0].fields)
            }

            return arr
        }, [])

        window.glyco.log('this.props.state.teams')
        window.glyco.log(this.props.state.teams)
        this.queue = Object.keys(this.props.state.teams)
            .reduce((data, x) => {
                window.glyco.log(x)
                if (this.props.state.teams[x].workflow_queue_task && this.props.state.teams[x].workflow_queue_task.filter(y =>
                    y.workflow_queue_id === this.props.state.workflowQueueTasks.queue.workflow_queue_id &&
                    y.workflow_queue_task_id === this.props.state.workflowQueueTasks.queue.workflow_queue_task_id
                ).length) {
                    data = this.props.state.teams[x].workflow_queue_task.filter(y =>
                        y.workflow_queue_id === this.props.state.workflowQueueTasks.queue.workflow_queue_id &&
                        y.workflow_queue_task_id === this.props.state.workflowQueueTasks.queue.workflow_queue_task_id
                    )[0]//.context_data.data.data.data
                }

                return data
            }, [])

        return (
            this.data.length
                    ?
                    (<div>
                        <Container style={{ margin: 0 }} className="custom-container"><Row>
                            <Col xl={11.5}>
                                <WorkflowView
                                    key={'view_' + this.props.state.component.name}
                                    name={this.props.state.component.name}
                                    data={this.data[0][0]}
                                />
                            </Col>
                        </Row>
                            <Row>
                                <Col xl={12}>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                   < Button className={"global_button"} variant="contained"
                                        label={'Change'}
                                        primary={true}
                                        style={styles.right}
                                        //disabled={!this.component.selected.length}
                                        onClick={(event) => {
                                            this.props.actions.resetSelection(this.props.step, this.props.name)
                                        }}
                                        >{window.t.en('Change')}</Button>
                                </Col>
                            </Row>
                        </Container>
                        </div>)
                    :
                    ( <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <WorkflowInput
                                    key={'workflowTaskInput_form' + this.props.name + this.props.id}
                                    name={this.props.name}
                                    id={this.props.id}
                                    related={false}
                                    fields={this.fields}
                                    data={this.queue}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} style={styles.addComponentRow}>
                               < Button className={"global_button"} variant="contained"
                                    label={'Confirm'}
                                    primary={true}
                                    onClick={(event) => {
                                        this.props.actions.confirmSingleRow(this.props.step, this.props.name)
                                    }}
                                    >{window.t.en('Confirm')}</Button>
                            </Col>
                        </Row>
                    </Container>
                    </div>)
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            input: {
                ...state.workflowTaskInput
            },
            component: {
                ...state.components[ownProps.name]
            },
            teams: {
                ...state.teams
            },
            workflowQueueTasks: {
                ...state.workflowQueueTasks
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskStepComponentSingleCreator)