import * as actions from './actions'
import * as teamActions from '../Teams/actions'
import * as clientActions from '../Clients/actions'
import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../Request/types'
import { cognitoUser } from '../../cognito'
import * as config from '../../config'


export const getCards = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.GET_COMPONENT_CARD_MANAGEMENT_DATA),
        mergeMap(() =>
            ajax$({
                url: config.system.api + '/components/card/query',
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'carditem.mandateitem,cardvehicle.vehicle,carddriver.person,cardcostcentre.customer,cardtransaction',
                    'take': 100
                }
            })
            .pipe(
                mergeMap(payload => [
                    actions.setCardData({prop: 'cards', data: payload.response}),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/card/query')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
        
export const getCard = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.REFRESH_SELECTED_CARD_MANAGEMENT_MANDATE),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/card/query?card_id=' + payload,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'related': 'carditem.mandateitem,cardvehicle.vehicle,carddriver.person,cardcostcentre.customer,cardtransaction',
                    ///'related': 'carditem.mandateitem,cardvehicle.vehicle,carddriver.person',
                    'take': 100
                }
            })
            .pipe(
                mergeMap(payload => [
                    actions.setCardData({prop: 'card', data: payload.response[0]}),
                    requestActions.requestSucceeded(config.system.api + '/components/card/query')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/user/query', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
        
export const getMandateCategory = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_CARD_MANAGEMENT_MANDATE_CATEGORY_DATA),
    mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/mandatecategory/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'related': 'mandateitem',
                'take': 25
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setCardData({prop: 'others', data: payload.response}),
                requestActions.requestSucceeded(config.system.api + '/components/mandatecategory/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/mandatecategory/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

      
export const getVehicles = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_CARD_MANAGEMENT_VEHICLE_DATA),
    mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/vehicle/query',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'take': 25
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setCardData({prop: 'vehicles', data: payload.response}),
                requestActions.requestSucceeded(config.system.api + '/components/vehicle/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const getCostCenter = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_CARD_MANAGEMENT_COSTCENTER_DATA),
    mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/customer/query?businessunit=TPL',  
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'take': 25
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setCardData({prop: 'costcentre', data: payload.response}),
                requestActions.requestSucceeded(config.system.api + '/components/vehicle/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

export const getCardLookupData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_CARD_LOOKUP_DATA),
    mergeMap(({payload}) =>
        ajax$({
            url: config.system.api + '/components/'+payload.model+'/search?'+payload.query+payload.display+'='+payload.search,
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'related': payload.component,
                'take': 25
            }
        })
        .pipe(
            mergeMap(response => [
                actions.setCardData({prop: payload.model, data: response.response.data}),
                requestActions.requestSucceeded(config.system.api + '/components/'+payload.model+'/search?'+payload.display+'='+payload.search)
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/'+payload.model+'/search?'+payload.display+'='+payload.search, error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))

          
export const getDrivers = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_COMPONENT_CARD_MANAGEMENT_DRIVER_DATA),
    mergeMap(() =>
        ajax$({
            url: config.system.api + '/components/person/query?client_id=6',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'take': 25
            }
        })
        .pipe(
            mergeMap(payload => [
                actions.setCardData({prop: 'drivers', data: payload.response}),
                requestActions.requestSucceeded(config.system.api + '/components/person/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/person/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))


export const postCardData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
    action$.pipe(
        ofType(types.POST_COMPONENT_CARD_MANAGEMENT_DATA),
        mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/card/other',
                method: 'POST',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
            .pipe(
                mergeMap(result => [
                    actions.postCardDataDone(result, payload),
                    requestActions.requestSucceeded(config.system.api + '/components/disposal/other')
                ]),
                startWith(progressBarActions.progress(true)),
                catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/disposal/other', error)))
        )),
        catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
    

        
export const getCardTransaction = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
    ofType(types.GET_CARD_MANAGEMENT_TRANSACTION_LIST_DATA),
    mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/cardtransaction/query?card_id=',
            method: 'GET',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
        })
        .pipe(
            mergeMap(result => [
                actions.setCardTransaction(result, payload),
                requestActions.requestSucceeded(config.system.api + '/components/cardtransaction/query')
            ]),
            startWith(progressBarActions.progress(true)),
            catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/cardtransaction/query', error)))
    )),
    catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))