import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import Checkbox from '../../../controls/checkbox'
import Button from "@mui/material/Button";
import Icon from '../../Icon/component'
import AdhocGrid from '../../Custom/AdhocGrid/component'
import { Table } from '@mui/material'

import moment from 'moment';
import TextField from '@mui/material/TextField'
import Upload from '../../Upload/component'
import BillingReview from '../BillingRefresh/reviewgrid'

import ReactQuill from 'react-quill'

import * as actions from './actions'

class PreviewGrid extends Component {
    componentDidMount() {
    }

    getTotal(data, prop) {
        var sum = 0
        for(let x of data) {
            sum = sum + parseFloat(x[prop].toString().replace(/,/g, ''))
        }
        return this.getField(sum, 'numeric')
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : ''
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }

    getGrid() {
        return <AdhocGrid
            key={'adhoc_grid_billing_review_data'}
            name={'adhoc_grid_billing_review_data'}
            ////uri={'/components/collection/other'}
            filterable={false}
            sortable={false}
            body={{
                collection: 'billing_preview_data_off',
                searchText: ''
            }}
            height={'1000px'}
            loading={this.props.state.data ? false : true}
            data={
                this.props.state.chkfilter 
                    ? this.props.state.data.filter(x => x.send_status == this.props.state.chkfilter && 
                        (x.transreference.includes(this.props.state.filter) || x.registration.includes(this.props.state.filter))) 
                    : this.props.state.data.filter(x => x.transreference.includes(this.props.state.filter) || x.registration.includes(this.props.state.filter))
            }
            search={''}
            style={{
                background: '#90ee90',
                color: 'white'
            }}
            columns={[
                {
                    accessor: 'invoicenumber', Header: 'Document', filterable: false, width: 200, Cell: row => {
                        return (
                            <div>
                                <Button className={"global_button"} variant="contained"
                                    primary={true}
                                    label="View"
                                    onClick={(event) => {
                                        this.props.actions.previewInvoice(row.original['customer_transaction_id'],false)
                                    }}
                                    >{window.t.en('View')}</Button>
                                    &nbsp;
                                <Button className={"global_button"} variant="contained"
                                    primary={true}
                                    label="Email"
                                    onClick={(event) => {
                                        this.props.actions.previewInvoice(row.original['customer_transaction_id'],true)
                                    }}
                                    >{window.t.en('Email')}</Button>
                            </div>
                        )
                    }
                },
                { accessor: 'hierarchyname', Header: 'Debtor Name',width: 300, filterable: true },
                // { accessor: 'businessunit', Header: 'Business Unit', filterable: true },
                { accessor: 'registration', Header: 'Registration ', width: 120, filterable: true },
                { accessor: 'contractnumber', Header: 'Contract Number', width: 160, filterable: true },
                { accessor: 'transreference', Header: 'Invoice Number', width: 150, filterable: true },
                // { accessor: 'customerstransactiondate', Header: 'Date', width: 160, filterable: true },
                { accessor: 'startdate', Header: 'Invoice Start Date', width: 150, filterable: true },
                { accessor: 'enddate', Header: 'Invoice End Date', width: 150, filterable: true },
                { accessor: 'rate', Header: 'Rate', width: 100, filterable: true },
                { accessor: 'unit', Header: 'Unit', width: 100, filterable: true },
                { accessor: 'total', Header: 'Invoice Amount', width: 120, filterable: true },
                { accessor: 'contactperson', Header: 'Contact Person', width: 130, filterable: true },
                // { accessor: 'contactemail', Header: 'Contact Email', width: 100, filterable: true },
                { accessor: 'send_status', Header: 'Status', width: 100, filterable: true },
            ]}
            paging={{
                page: 0,
                pageSize: 10,
                pages: 0,
                count: 0
            }}
        />
    }

    getRefreshing () {
        return  <Row>
            <Col xl={12}>
                <div>
                    <h4>Billing Refreshing:</h4>
                    <ul>
                        <li>This will take couple of minutes</li>
                    </ul>
                    <i>We will let you know once the process is completed.</i>
                </div>
            </Col>
        </Row>
    }

    getLayout() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>{this.props.state.refreshing ? this.getRefreshing() : this.getGrid()}</Col>
            </Row>
        </Container>
    }

    render() {
        
        return this.getLayout()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.billingpreview,
            user: {
                ...state.user
            },
            upload: {
                ...state.upload
            },
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewGrid)