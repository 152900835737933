import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) { 
        case types.QUOTE_DOCUMENT_VIEWER:
        return {
            ...state,
            downloaded: action.payload
        }
        default:
            return state
    }
}