import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Tabs,Box, Tab, Button, List, ListItem, Tooltip, Dialog, TextField, IconButton, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import { Container, Row, Col } from 'react-grid-system'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PdfViewer from '../../PDF/component'
import AutoComplete from '../../../controls/autocomplete'
import Upload from '../../Upload/component'
import Card from '../../../controls/card'
import Fields from '../../../controls/cardfields'
import DateControl from '../../../controls/dateControl'
import ViewSupplier from '../../../controls/view_supplier'

import * as actions from './actions'
import * as styles from './styles'

class AccessoryMasterActivation extends Component {

    componentDidMount() {
        this.props.actions.resetForm()
    }

    componentWillUnmount() {
        this.props.actions.resetForm()
    }
   
    getView(data) {
        let confirm = data?.confirm
        //console.log('AccessoryMasterActivationx confirm', confirm)
        return data ? <div>
            <ViewSupplier
                key={confirm.supplier.supplier_id}
                id={confirm.supplier.supplier_id}
            />
            <Card
                title={"Supplier Accessories"}
                subtitle={window.t.en('Supplier accessories pricing')}
                content={<Fields labelsize='150' data={confirm.accessories} props={['accessoryname','costprice','sellingprice','expirydate','vatable',]} columns={['Accessory Name', 'Cost Price', 'Selling Price', 'Expiry Date', 'Vatable']} />}
            /> 
            <Card
                title={"Supplier Sundries"}
                subtitle={window.t.en('Supplier sundries pricing')}
                content={<Fields labelsize='150' data={confirm.sundries} props={['description','costprice','sellingprice','expirydate','vatable',]} columns={['Sundry Name', 'Cost Price', 'Selling Price', 'Expiry Date', 'Vatable']} />}
            /> 
            <Card
                title={"Supplier Services"}
                subtitle={window.t.en('Supplier services pricing')}
                content={<Fields labelsize='150' data={confirm.services} props={['servicedescription','costprice','sellingprice','expirydate','vatable',]} columns={['Service Name', 'Cost Price', 'Selling Price', 'Expiry Date', 'Vatable']} />}
            /> 
            <Card
                title={"Supplier Documents"}
                subtitle={window.t.en('Uploaded supplier supporting documents')}
                content={<PdfViewer
                    key={'supplier_pricing'}
                    name={'supplier_pricing'}
                    documents={confirm.documents.documents}
                    edit={false}
                    multiple={false}
                    workflow_queue_data={null}
                />}
            />
            <Card
            title={'Approval Comments '}
            subtitle={'Approval approval comments'}
            content={<Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12}>
                        <TextField
                            variant='outlined'
                            id={'txt_comments'}
                            label={'Enter Comments'}
                            fullWidth={true}
                            value={this.props.state.comments}
                            onChange={(event, value) => {
                                this.props.actions.setInputValue('comments', event.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container>
            } />
        </div> : ''
    }

    getLayout() {
        let data = this.props.workflow_queue_data
        //console.log('AccessoryMasterActivationx', data.context_data.data.data.data[0])
        data = data.context_data.data.data.data[0]
        return <div>
            {this.getView(data)}
        </div>
    }

    render() {
        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.accessoryMasterActivation
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessoryMasterActivation)