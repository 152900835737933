import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'


import { Container, Row, Col } from 'react-grid-system'

import Checkbox from '../../../controls/checkbox'
import TextField from '../../../controls/textField'

import Card from '../../../controls/card'

import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import ImageUploader from '../../ImageUploader/component'
import Icon from '../../Icon/component'
import Upload from '../../Upload/component'
import ViewCustomer from '../../../controls/view_customer'
import AutoComplete from '../../../controls/autocomplete'

import * as styles from './styles'
import * as actions from './actions'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'

class ActivateMMContract extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
         
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0].step2.step1
        } catch (error) { }

        //console.log('componentWillMount workflow_queue_data', workflow_queue_data)
        if (workflow_queue_data) {
            this.props.actions.setValue('registration', workflow_queue_data.vehicle.licenseplateno)
            this.props.actions.setValue('odometer', workflow_queue_data.vehicle.takeonodo)
            this.props.actions.setValue('period', workflow_queue_data.period)
            this.props.actions.setValue('startdate', moment(workflow_queue_data.startdate).format('YYYY-MM-DD').toString())
            this.props.actions.setValue('enddate', moment(workflow_queue_data.enddate).format('YYYY-MM-DD').toString())
            this.props.actions.setValue('licenseexpiry', moment(workflow_queue_data.vehicle.licenseexpiry).format('YYYY-MM-DD').toString())
            this.props.actions.setAccessoryList('accessory_list', workflow_queue_data.items)
        }
        this.props.actions.getCustomerData(workflow_queue_data.customer.customer_id)
    }
    
    CustomerDetails(customer) {
        return <div>
            <ViewCustomer
                min={true}
                key={customer.customer_id}
                id={customer.customer_id}
            />
        </div>
    }


    Vehicledetails(data) {
        return <div>
        <Card 
            title={('Vehicle Model')}
            subtitle={('Vehicle model details.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Registration:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.vehicle.licenseplateno}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Register:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.vehicle.licensediscno}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Chassis Number:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.vehicle.chassisnumber}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Engine Number:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.vehicle.enginenumber}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.mmcode.mmcode}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Make:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.mmcode.make}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Model:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.mmcode.model}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Variant:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.mmcode.variant}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Vehicle Type:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.mmcode.bodytype}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Fuel Type:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.mmcode.fueltype}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Transmission:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.mmcode.manualauto}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'RT57 Vehicle:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{parseInt(data.mmcode.rt57price) > 0 ? 'Yes' : 'No'}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Introduction Year:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.mmcode.introdate}</Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Discontinue Year:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>{data.mmcode.discdate}</Col>
                        </Row>
                    </Container>
            }/>
        </div>
    }

    AccessoriesList(data) {
        let accessory_list = null
        try {
            accessory_list = this.props.state.accessory_list
        } catch (error) { }

        return accessory_list
            ?  <Card 
                title={('Items Summary')}
                subtitle={('LIst of items for manage maintenance.')}
                content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row style={styles.headerrow}>
                            <Col xl={8}>
                                {'Item Description'}
                            </Col>
                            <Col xl={2} style={styles.numericFields}>
                                {'Cost Price'}
                            </Col>
                            <Col xl={2} style={styles.numericFields}>
                                {'Selling Price'}
                            </Col>
                        </Row>
                        {accessory_list.map((x, i) => {
                            return <Row >
                                <Col xl={8}>
                                    {x.servicedescription}
                                </Col>
                                <Col xl={2} style={styles.numericFields}>
                                    {x.costprice}
                                    {/* <TextField
                                        id={'txt_costprice_' + i.toString()}
                                        value={x.costprice}
                                        onChange={(event, value) => {
                                            this.props.actions.updAccessoryValue('costprice', i, value, accessory_list)
                                        }}
                                    /> */}
                                </Col>
                                <Col xl={2} style={styles.numericFields}>
                                    {x.sellingprice}
                                    {/* <TextField
                                        id={'txt_sellingprice_' + i.toString()}
                                        value={x.sellingprice}
                                        onChange={(event, value) => {
                                            this.props.actions.updAccessoryValue('sellingprice', i, value, accessory_list)
                                        }}
                                    /> */}
                                </Col>
                            </Row>
                        })
                        }
                    </Container>
                
            }/>
            : 'Loading...'
    }

    viewForm() {
        return  <Card 
            title={('Activation Form')}
            subtitle={('List of items for manage maintenance.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth={true}
                                        label={'Vehicle Registration'}
                                        margin="normal"
                                        id={'txtregistration'}
                                        hintText={'Confirm Vehicle Registration'}
                                        value={this.props.state.registration}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue('registration', event.target.value)
                                        }}
                                    />
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        fullWidth={true}
                                        variant="outlined"
                                        margin="normal"
                                        label={'Contract Period'}
                                        id={'txt_period'}
                                        hintText={'Confirm Contract Period'}
                                        container='inline'
                                        mode='landscape'
                                        value={this.props.state.period}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue('period', event.target.value)
                                        }}
                                    />
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth={true}
                                        margin="normal"
                                        id={'txtodometer'}
                                        label={'Vehicle Takeon Odometer'}
                                        hintText={'Enter Vehicle Odometer'}
                                        value={this.props.state.odometer}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue('odometer', event.target.value)
                                        }}
                                    />
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        fullWidth={true}
                                        variant="outlined"
                                        margin="normal"
                                        type="date"
                                        id={'txtlicenseexpiry'}
                                        error={this.props.state.licenseexpiry === ''}
                                        hintText='Select License Expiry Date'
                                        container='inline'
                                        mode='landscape'
                                        label={'License Expiry Date'}
                                        value={this.props.state.licenseexpiry ?? new Date()}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue('licenseexpiry', event.target.value)
                                        }}
                                        minDate={new Date()}
                                    />
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        fullWidth={true}
                                        variant="outlined"
                                        margin="normal"
                                        type="date"
                                        id={'txtstartdate'}
                                        error={this.props.state.startdate === ''}
                                        hintText='Select Contract Start Date'
                                        container='inline'
                                        mode='landscape'
                                        label={'Contract Start Date'}
                                        value={this.props.state.startdate}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue('startdate', event.target.value)
                                        }}
                                        minDate={new Date()}
                                    />
                        </Col>
                        <Col xl={4}>
                                    <TextField
                                        fullWidth={true}
                                        variant="outlined"
                                        margin="normal"
                                        type="date"
                                        id={'txtenddate'}
                                        error={this.props.state.enddate === ''}
                                        hintText='Select Contract End Date'
                                        container='inline'
                                        mode='landscape'
                                        label={'Contract End Date'}
                                        value={this.props.state.enddate}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue('enddate', event.target.value)
                                        }}
                                        minDate={new Date()}
                                    />
                        </Col>
                        <Col xl={4}>
                    <Checkbox
                        id={'chk_send_marketing'}
                        key={'chk_send_marketing'}
                        name={'chk_send_marketing'}
                        field={'send_marketing'}
                        checked={this.props.state.send_marketing}
                        labelPlacement={'end'}
                        label={('Send Marketing')}
                        onCheck={(checked) => {
                            this.props.actions.setValue('send_marketing', checked)
                        }}
                    />
                 </Col>
                 {this.props.state.send_marketing && (<Col xl={4}>
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400 }}
                                                        id={'dll_marketingtype_id'}
                                                        key={'dll_marketingtype_id'}
                                                        hintText={'Select Marketing Type from the List'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        value={this.props.state.marketingtype}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.marketingtype_data}
                                                        onNewRequest={
                                                            (chosenRequest, index) => {
                                                                this.props.actions.setValue('marketingtype', chosenRequest.text)
                                                                this.props.actions.setValue('marketingtype_id', chosenRequest.value)
                                                            }
                                                        }
                                                        onClick={
                                                            (event) => {
                                                                this.props.actions.getMMLookupValueData('marketingtype', 'marketingtype', '')
                                                            }
                                                        }
                                                        onUpdateInput={
                                                            (search, dataSource, params) => {
                                                                this.props.actions.getMMLookupValueData('marketingtype', 'marketingtype', search)
                                                            }
                                                        }
                                                    />
                                        </Col>)}
                    </Row>
                </Container>
            }/>

    }


    uploadForm() {
        return <Card 
        title={('Activation Form')}
        subtitle={('List of items for manage maintenance.')}
        content={<Container style={{ margin: 0 }} className="custom-container">

                    <Row>
                        <Col xl={12}>
                            <Upload
                                id={'upload_ctl'}
                                prefix={'po'}
                                header={false}
                            />
                        </Col>
                    </Row>
                </Container>
            }/>

    }

    RentalSummary(data) {
        return <Card 
        title={('Rental Summary')}
        subtitle={('Contract details.')}
        content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Quote Number:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {data.quotenumber}
                            </Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Contract Type:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {data.contracttype}
                            </Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Start Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {data.startdate}
                            </Col>
                            <Col xl={2} style={styles.fieldLabel}>{'End Date:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {data.enddate}
                            </Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Period:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {data.period}
                            </Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Sub Total:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {parseFloat(data.monthly_excl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                            <Col xl={2} style={styles.fieldLabel}>{'VAT Amount:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {parseFloat(data.monthly_vat).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Total Monthly:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                                {parseFloat(data.monthly_incl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                        </Row>
                    </Container>
            }/>
    }

    documentView(documents) {
        return <Card 
        title={('Document List')}
        subtitle={('.')}
        content={<Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {
                                documents
                                    ? <PdfViewer
                                        key={'document_viewer_approval'}
                                        name={'booking'}
                                        workflow={'fml_customer_quote'}
                                        task={'tsk_purchase_order_review_and_send'}
                                        documents={documents}
                                        edit={false}
                                        default_document={'Quotation'}
                                        workflow_queue_data={this.props.workflow_queue_data}
                                    />
                                    : <Loading message={'Retrieving Booking Document...'} />
                            }
                        </Col>
                    </Row>
                </Container>
            }/>
    }

    renderView(data) {
        return <div>
            {this.CustomerDetails(data.step2.step1.customer)}
            {this.Vehicledetails(data.step2.step1)}
            {this.RentalSummary(data.step2.step1)}
            {this.AccessoriesList(data.step2.step1.items)}
            {this.documentView(data.step2.document)}
            {this.viewForm()}
            {this.uploadForm()}
        </div>
    }

    render() {
        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) {    }
        
        //console.log('workflow_queue_data', workflow_queue_data)
        return workflow_queue_data
            ? this.renderView(workflow_queue_data)
            : 'Loading...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.activateMMContract
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateMMContract)