
export const dummyrun = true
export const finalbilling = false
export const comments = ''
export const refreshing = false
export const refreshed = false
export const billingtracking = [] 
export const data = [] 
export const fields = {
    refreshed: {
        errorText: 'Please Refresh Billing.',
        value: null,
        valid: false,
        empty: true,
    },
    checked: {
        errorText: 'Please Confirm Billing Check',
        value: null,
        valid: false,
        empty: true,
    },
}