import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Table } from '@mui/material'
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import CardControl from './card'
import CardField from './cardfields'

import PdfViewer from '../components/PDF/component'

import ReactQuill from 'react-quill'

import * as actions from './actions'
import * as styles from './styles'

class MultipleMaintenance extends Component {
    componentDidMount() {
        //console.log('setComponentMultiMaintenanceDatax >>>>>',this.props.id)
        this.props.actions.getComponentMultiMaintenanceData(this.props.id)
    }

    getField(value, type) {
        switch (type) {
            case 'date':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD')
                    : ''
            case 'numeric':
                return value !== null
                    ? parseFloat(value).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : ''
            case 'bool':
                return value === true
                    ? 'Yes'
                    : 'No'
            case 'datetime':
                return value !== null
                    ? moment(value).format('YYYY-MM-DD HH:mm') 
                    : ''
            case 'time':
                return value !== null
                    ? moment(value).format('LLLL') + ', ' + moment(value).fromNow()
                    : ''
            case 'html':
                return <ReactQuill style={{ height: '250px' }} modules={{ toolbar: false }} readOnly={true} value={value} />
            default:
                return value
        }
    }
    
    getLabel(label, prop, type = '') {
        return <FormControlLabel
            fullWidth={true}
            control={
                <Typography variant="body1" component="div"
                    style={{  fontWeight: 'bold',fontSize: '14px' }}>
                    {this.getField(this.props.state[prop], type)}
                </Typography>
            }
            label={
                <label style={{ width: '250px', textAlign: 'right', paddingRight: '10px', height: '100%', verticalAlign: 'top',fontSize: '14px' }}>
                    {window.t.en(label)}:
                </label>
            }
            labelPlacement="start"
        />
    }

    getTotal(data, prop) {
        var sum = 0
        for(let x of data) {
            sum = sum + parseFloat(x[prop].toString().replace(/,/g, ''))
        }
        return this.getField(sum, 'numeric')
    }


    getQuotation(quotations) {
        return <div>
            {quotations.map(quotation => { 
                //console.log('quotationx', quotation)
            let documents = quotation.maintenancedocument.map(x => x.document)
            //console.log('quotationx documents', documents)
            return quotation.maintenancetransactionitem.length
            ? <Container style={{ margin: 0 }} className="custom-container">
                <Row>
                    <Col xl={12} style={{marginTop:'15px'}}>
                        
                        <CardField nocard={true}
                            fields={[
                                {size: 4, label: 'Auth Number:', value: quotation.authnumber},
                                {size: 4, label: 'Booking Days:', value: quotation.bookingdays},
                                {size: 4, label: 'Description:', value: quotation.maintdescription},

                                {size: 4, label: 'Quote Number:', value: quotation.quotenumber},
                                {size: 4, label: 'Quote Date:', value: moment(quotation.quotedate).format('YYYY-MM-DD') },
                            ]}
                        />
                    </Col>
                    <Col xl={12} style={{marginTop:'15px'}}>
                        <CardField nocard={true}
                            fields={[
                                {size: 4, label: 'Supplier Name:', value: quotation.supplier ? quotation.supplier.suppliername : ''},
                                {size: 4, label: 'Merchant Code:', value: quotation.supplier ? quotation.supplier.merchantcode : ''},
                                {size: 4, label: 'Account Number:', value: quotation.supplier ? quotation.supplier.accountnumber : ''},
                                {size: 4, label: 'VAT Number:', value: quotation.supplier ? quotation.supplier.vatnumber : ''},
                                {size: 4, label: 'BEE Type:', value: quotation.supplier ? quotation.supplier.beetype : ''},
                                {size: 4, label: 'BEE Expiry Date:', value: quotation.supplier ? moment(quotation.supplier.beecertificateexpdate).format('YYYY-MM-DD') : ''},
                                {size: 4, label: 'SLA Expiry Date:', value: quotation.supplier ? moment(quotation.supplier.slaexpirydate).format('YYYY-MM-DD') : ''},
                                {size: 4, label: 'Tax Expiry Date:', value: quotation.supplier ? moment(quotation.supplier.taxclearancecertificateexpirydate).format('YYYY-MM-DD') : ''},
                            ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                    <Table className="table table-hover text-nowrap mb-0" style={{width: '100% !important'}}>
                <colgroup>
                    <col width="200px" />
                    <col width="300px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                    <col width="130px" />
                </colgroup>
                <thead>
                    <tr>
                        <th style={{width: '30% !important'}}>Item Category</th>
                        <th style={{width: '30% !important'}}>Description</th>
                        <th style={{width: '5% !important'}}>Quantity</th>
                        <th style={{width: '5% !important'}}>Unit Price </th>
                        <th style={{width: '5% !important'}}>Total</th>
                        <th style={{width: '5% !important'}}>Savings</th>
                        <th style={{width: '5% !important'}}>Discount(%)</th>
                        <th style={{width: '5% !important'}}>Discount</th>
                        <th style={{width: '5% !important'}}>VAT</th>
                        <th style={{width: '5% !important'}}>Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        quotation.maintenancetransactionitem.map(x => {
                            return <tr>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.itemtype}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.itemdescription}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.quantity}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.unitprice}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.amount}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.savings}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.discountpercentage}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.discountamount}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.vat}</div></td>
                                <td><div style={{fontWeight: 400, fontSize:'16px'}}>{x.totalamount}</div></td>
                            </tr>
                        })
                    }
                    <tr style={{background: '#DFDFDF'}}>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '150px !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '250px !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.maintenancetransactionitem, (d) => { return parseFloat((d['amount']) ? d['amount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.maintenancetransactionitem, (d) => { return parseFloat((d['savings']) ? d['savings'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}> </td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.maintenancetransactionitem, (d) => { return parseFloat((d['discountamount']) ? d['discountamount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.maintenancetransactionitem, (d) => { return parseFloat((d['vat']) ? d['vat'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{paddingLeft: '20px', fontWeight: '500', width: '5% !important'}}>{_.sumBy(quotation.maintenancetransactionitem, (d) => { return parseFloat((d['totalamount']) ? d['totalamount'].toString().replace(/,/gi, '') : 0) }).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                </tbody>
            </Table>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <PdfViewer
                            key={'document_viewer_uploaded_quotes'}
                            name={'De-fleet'}
                            workflow={'defleet'}
                            task={'tsk_defleet_upload_quotes'}
                            documents={documents}
                            edit={false}
                            workflow_queue_data={null}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <br />
                    </Col>
                </Row>
            </Container> : ''
        })}
            </div>
    }

    getView() {
        return  <Container style={{ margin: 0 }} className="custom-container">
                    {
                    this.props.state.bookedby
                    ? <Row>
                        <Col xl={12} style={styles.title}>
                            {'Maintenance Progress Steps'}
                        </Col>
                    </Row>
                    : ''
                    }
                    <Row>
                    {
                    this.props.state.bookedby
                    ? <Col xl={12}>
                    <div className="table-responsive-md">
                        <Table className="table table-hover text-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th>Step</th>
                                    <th>Actioned By</th>
                                    <th>Actioned At</th>
                                    <th>Description</th>
                                </tr>
                                </thead>
                            <tbody style={{fontWeight: 'normal',fontSize: '14px'}}>
                                {
                                    this.props.state.bookedby && <tr>
                                        <td>{"Booking"}</td>
                                            <td>{this.props.state.bookedby}</td>
                                            <td>{this.getField(this.props.state.bookeddate, 'datetime')}</td>
                                            <td>{this.props.state.comments}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.capturedby && <tr>
                                        <td>{"Capture Quote"}</td>
                                            <td>{this.props.state.capturedby}</td>
                                            <td>{this.getField(this.props.state.capturedate, 'datetime')}</td>
                                            <td>{this.props.state.quotenumber}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.approvedby && <tr>
                                        <td>{"Tech Approval"}</td>
                                            <td>{this.props.state.approvedby}</td>
                                            <td>{this.getField(this.props.state.approvedate, 'datetime')}</td>
                                            <td>{this.props.state.approvedcomments}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.opsapprovedby && <tr>
                                        <td>{"GM Approval"}</td>
                                            <td>{this.props.state.opsapprovedby}</td>
                                            <td>{this.getField(this.props.state.opsapprovedate, 'datetime')}</td>
                                            <td>{this.props.state.opscomments}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.gmapprovedby && <tr>
                                        <td>{"COO Approval"}</td>
                                            <td>{this.props.state.gmapprovedby}</td>
                                            <td>{this.getField(this.props.state.gmapprovedate, 'datetime')}</td>
                                            <td>{this.props.state.gmcomments}</td>
                                        </tr>
                                }
                                {
                                    this.props.state.ceoapprovedby && <tr>
                                        <td>{"CEO Approval"}</td>
                                            <td>{this.props.state.ceoapprovedby}</td>
                                            <td>{this.getField(this.props.state.ceoapprovedate, 'datetime')}</td>
                                            <td>{this.props.state.ceocomments}</td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                    </Col>
                    : ''
                    }
                    </Row>
                    {
                        this.props.state.multimaintenance
                            ? <Row>
                                    <Col xl={12}>
                                        {this.getQuotation(this.props.state.multimaintenance)}
                                    </Col>
                                </Row>  
                            : ''
                    }

                    {
                    this.props.state.maintenancedocument 
                    ? <>
                        <Row>
                            <Col xl={12}>
                                <hr />
                            </Col>
                        </Row>    
                        <Row>
                            <Col xl={12} style={styles.title}>
                                {'Maintenance Documents'}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {
                                    this.props.state.maintenancedocument 
                                    ?  <PdfViewer
                                        key={'quotation_viewer_' + this.props.id}
                                        id={'quotation_viewer_' + this.props.id}
                                        documents={this.props.state.maintenancedocument.sort((x, y) => x.maintenancedocument_id - y.maintenancedocument_id).map(x => {
                                            return {
                                                filename: x.document.filename,filetype: x.document.description
                                            }})
                                        }
                                        multiple={false}
                                        height={600}
                                        edit={false}
                                    />
                                    : ''
                                }
                            </Col>
                        </Row>
                    </>
                    : ''
                    }                
                    </Container>

    }

    getLayout() {
        return <div>
            <CardControl 
                title={'Maintenance Details  ' + (this.props.state.authnumber ? this.props.state.authnumber : '')}
                subtitle={''}
                content={this.getView()}
                callapse={true}
            />
         </div> 
    }

    render() {
        return this.props.state ? this.getLayout() : ''
    }
}

const mapStateToProps = (state) => {
    return {
        state: { 
            ...state.controls
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleMaintenance)