export const empty = true
export const signed_customer_quotation = false
export const po_supplier = false
export const invoice_supplier = false
export const accessories_po = false
export const accessories_job_cards = false
export const accessories_suppliers = false
export const sundries_po = false
export const sundries_invoices = false
export const pre_inspection = false
export const registration_doc = false
export const tracker_certificate = false
export const insurance_certificate = false


export const fields = {
    insurance_certificate: {
        errorText: 'Insurance certificate is required',
        value: false,
        valid: true,
        empty: true,
        state: "getState().workflowFMLReleaseNote.insurance_certificate",
    },
    tracker_certificate: {
        errorText: 'Tracker certificate is required',
        value: false,
        valid: true,
        empty: true,
        state: "getState().workflowFMLReleaseNote.tracker_certificate",
    },
    registration_doc: {
        errorText: 'Registration (Natis Documents) is required',
        value: false,
        valid: true,
        empty: true,
        state: "getState().workflowFMLReleaseNote.registration_doc",
    },
    pre_inspection: {
        errorText: 'Pre inspection delivery document (PID) is required',
        value: false,
        valid: true,
        empty: true,
        state: "getState().workflowFMLReleaseNote.pre_inspection",
    },
    invoice_supplier: {
        errorText: 'Invoice Supplier / Dealer is required',
        value: false,
        valid: true,
        empty: true,
        state: "getState().workflowFMLReleaseNote.invoice_supplier",
    },
    po_supplier: {
        errorText: 'PO Supplier / Dealer is required',
        value: false,
        valid: true,
        empty: true,
        state: "getState().workflowFMLReleaseNote.po_supplier",
    },
    signed_customer_quotation: {
        errorText: 'Signed customer quotation is required',
        value: false,
        valid: true,
        empty: true,
        state: "getState().workflowFMLReleaseNote.signed_customer_quotation",
    }
}