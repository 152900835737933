import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Container, Row, Col } from 'react-grid-system'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'

import TextField from '@mui/material/TextField'
import AutoComplete from '../../../controls/autocomplete'
import Card from '../../../controls/card'
import IconButton from '@mui/material/IconButton';

import Form from '../../Form/component'
import Icon from '../../Icon/component'

import { Label } from './styles'
import * as actions from './actions'
import * as styles from './styles'

class CUSTOMERDERIVATIVE extends Component {
    render() {
        //console.log('CUSTOMERDERIVATIVEx')
        return <Card
                title={window.t.en('Customer Derivative')}
                subtitle={window.t.en('Set customer derivative')}
                content={
                    <div>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Select Customer:'}</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400 }}
                                        id={'dll_customer'}
                                        key={'dll_customer'}
                                        hintText={'Select Customer'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        dataSource={this.props.state.customer_list}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                this.props.actions.setLookupValue({prop: 'customer_id', value: chosenRequest.value, input: chosenRequest.value, text: chosenRequest.value})
                                                //this.props.actions.setLookupValue({prop: 'hierarchyname', value: chosenRequest.text})
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.getCustomerLookupData('')
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.getCustomerLookupData(search)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            </Col>
                            <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Contract Type:'}</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400 }}
                                        id={'contracttype'}
                                        key={'contracttype'}
                                        hintText={'Select Contract Type'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        dataSource={this.props.state.contracttype_list}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                this.props.actions.setLookupValue({prop: 'contracttype_id', value: chosenRequest.value, input: chosenRequest.value, text: chosenRequest.value})
                                                this.props.actions.setLookupValue({prop: 'contracttype', value: chosenRequest.text})
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.getContractTypeLookupData('')
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.getContractTypeLookupData(search)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Contract Name:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth={true}
                                            margin="normal"
                                            id={'txt_contractname'}
                                            hintText={'Enter Contract Name'}
                                            value={this.props.state.component.customerderivative.data.contractname.text}
                                            onChange={(event, value) => {
                                                this.props.actions.setLookupValue({ prop: 'contractname', value: event.target.value, input: event.target.value, text: event.target.value })
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Product Type:'}</div>
                                <div style={styles.fieldContent}>
                                    <AutoComplete
                                        listStyle={{ maxHeight: 400 }}
                                        id={'producttype'}
                                        key={'producttype'}
                                        hintText={'Select Product Type'}
                                        filter={AutoComplete.fuzzyFilter}
                                        openOnFocus={true}
                                        fullWidth={true}
                                        dataSourceConfig={{
                                            text: 'text',
                                            value: 'value'
                                        }}
                                        dataSource={this.props.state.producttype_list}
                                        onNewRequest={
                                            (chosenRequest, index) => {
                                                this.props.actions.setLookupValue({prop: 'producttype_id', value: chosenRequest.value, input: chosenRequest.value, text: chosenRequest.value})
                                                this.props.actions.setLookupValue({prop: 'producttype', value: chosenRequest.text})
                                            }
                                        }
                                        onClick={
                                            (event) => {
                                                this.props.actions.getProductTypeLookupData('')
                                            }
                                        }
                                        onUpdateInput={
                                            (search, dataSource, params) => {
                                                this.props.actions.getProductTypeLookupData(search)
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'User RT57 Rate Card:'}</div>
                                    <div style={styles.fieldContent}>
                                        <Checkbox
                                            checked={this.props.state.component.customerderivative.data.financert57Pricing.input}
                                            label={'Additional Vehicle' }
                                            onChange={(event, checked) => {
                                                this.props.actions.setLookupValue({ prop: 'financert57Pricing', value: !this.props.state.component.customerderivative.data.financert57Pricing.input, input: !this.props.state.component.customerderivative.data.financert57Pricing.input})
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    
                    <Form
                        key={'customerderivative_form'}
                        name={'customerderivative'}
                        related={false}
                        empty
                    />
                </div>
            }
        />
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.customerderivative,
            component: {
                ...state.components
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CUSTOMERDERIVATIVE)