import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Icon from '../Icon/component'
import BusinessIntelligence from '../BusinessIntelligence/component'
import GeneralContentView from '../../views/GeneralContentView/component'

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import * as actions from './actions'
import * as styles from './styles'

import * as accountActions from '../Account/actions'

class Language extends Component {
    render() {

        return (
            <GeneralContentView
                content={
                    <Card >
                        <CardContent  style={styles.cardDropdown}>
                        <FormControl variant="outlined"  style={styles.MuiFormControl}>
                            <InputLabel htmlFor="outlined-age-native-simple">Please select a language</InputLabel>
                            <Select
                            native
                            label="Language"
                            value={this.props.state.locale}
                            inputProps={{
                                name: 'Language',
                                id: 'code',
                            }}
                            onChange={(event, value) => { this.props.actions.setSelectedSetting({prop: 'locale',value: event.target.value})}}
                        >
                            {
                                this.props.state.locales.map(x => { return <option value={x.code}>{x.locale}</option> })
                            }

                        </Select>
                        </FormControl>
                        </CardContent>
                    </Card>

                }
                headerImage={''}
                title={window.t.en('Language')}
                contentHeight={'750px'}
            />
           

        )
    }
}

const mapStateToProps = (state) => {
    return {
                            state: {...state.accounts}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
                            actions: bindActionCreators({...actions, ...accountActions}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Language)