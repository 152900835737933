import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import * as config from '../../config'

import Button from '@mui/material/Button'
import { Table } from "@mui/material";
import { Container, Row, Col } from 'react-grid-system'

import AutoComplete from '../../controls/autocomplete'
//import TextField from '@mui/material/TextField'
import TextField from '../../controls/textField'
import DateControl from '../../controls/dateControl'
import Fields from '../../controls/cardfields'
import Card from '../../controls/card'
import ViewQuotation from '../../controls/view_quotation'
import ViewCustomer from '../../controls/view_customer'

import PdfViewer from '../PDF/component'
import Upload from '../Upload/component'
import Icon from '../Icon/component'

import Loading from '../Loading/component'
import * as actions from './actions'
import * as styles from './styles'
import { textAlign } from '../QuoteEdit/styles'

import * as workflowTaskInputActions from '../WorkflowTaskInput/actions'


class WorkflowViewFMLQuoteConfirm extends Component {

    componentDidMount() {
        try {
            this.props.actions.setValidationState('workflowViewFMLQuoteConfirm')
            if (this.props.confirm) {
                const data = this.props.state
                this.props.actions.setupWorkflowTaskInput(data, null, 'customerquote', 'stp_fml_quote_accept_customer_quote')
            } else {
                //this.props.actions.resetComponent()

            }

            this.props.actions.setInputValue({ prop: 'poddate', value: moment().format('YYYY-MM-DD') })
           // this.props.actions.setInputValue({ prop: 'receiptdate', value: moment().format('YYYY-MM-DD hh:mm') })
        }
        catch (err) { }
    }

    componentWillUnmount() {
        this.props.actions.setValidationState(null)
    }

    getContract(data) {
        return <Card
            title={window.t.en('Quote Confirmation')}
            subtitle={window.t.en('Please ensure all details are captured correctly before you proceed')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Contract Type:', value: data.contractname },
                        { size: 4, label: 'Vehicle Category:', value: data.customerderivativecategory },
                        { size: 4, label: ' Monthly Distance:', value: data.distancepermonth },
                        { size: 4, label: 'Contract Period:', value: data.contractperiod },
                        { size: 4, label: 'Quote For:', value: data.quotefor },
                        { size: 4, label: 'RFQ Date:', value: data.quotedate },
                        { size: 4, label: 'Vehicle Color:', value: data.color },
                        { size: 4, label: 'Vehicle Location:', value: data.location },
                        { size: 4, label: 'Special Vehicle:', value: data.specialistvehicle },
                        { size: 4, label: 'Vehicle Group:', value: data.vehiclegroup },
                        { size: 4, label: 'Operating Terrain:', value: data.terrain },
                        { size: 4, label: 'Vehicle Usage:', value: data.usage },
                        { size: 12, label: 'Comments:', value: data.comments },
                    ]}
                />
            }
        />
    }

    getCustomerOld(data) {
        return <Card
            title={window.t.en('Customer Details')}
            subtitle={window.t.en('Customer quote created for')}
            content={
                <Fields nocard={true}
                    fields={[
                        { size: 4, label: 'Customer Name:', value: data.customername },
                        { size: 4, label: 'Contact Person Name:', value: data.contactperson },
                        { size: 4, label: 'Customer Full Name:', value: data.hierarchyname },
                        { size: 4, label: 'Contact Number:', value: data.suppliercontact && data.suppliercontact.length ? data.suppliercontact[0].contact ? data.suppliercontact[0].contact.fullname ? data.suppliercontact[0].contact.fullname : '' : '' : '' },
                        { size: 4, label: 'Cost Centre:', value: data.suppliercontact && data.suppliercontact.length ? data.suppliercontact[0].contact ? data.suppliercontact[0].contact.mobile ? data.suppliercontact[0].contact.mobile : '' : '' : '' },
                        { size: 4, label: 'Contact Email:', value: data.suppliercontact && data.suppliercontact.length ? data.suppliercontact[0].contact ? data.suppliercontact[0].contact.email ? data.suppliercontact[0].contact.email : '' : '' : '' },
                    ]}
                />
            }
        />
    }


    getCustomer(customer) {
        let contact = customer.customercontact ? customer.customercontact.length ? customer.customercontact[0].contact : {} : {}
        let oq_key = 'a45cc3095246cdd6895faaedad652e8744feaefedbfaa7d974e7d68acf726bf2fefb622b7d2cb01713a91717bfc76a655559783b7792c8621832b675fef5c693'
        return <Card
            title={window.t.en('Customer Details')}
            subtitle={window.t.en('Customer quote created for')}
            content={
                <div>
                    <ViewCustomer id={customer.customer_id} nocard={true} min={true} />
                    {/* <Fields nocard={true}
                        fields={[
                            { size: 4, label: 'Customer Name:', value: customer.customername },
                            { size: 4, label: 'Cost Centre:', value: customer.costcentre },
                            { size: 4, label: 'Account Number:', value: customer.accountnumber },
                            { size: 4, label: 'Contact Person Name:', value: contact.fullname },
                            { size: 4, label: 'Contact Number:', value: contact.mobile },
                            { size: 4, label: 'Contact Email:', value: contact.email }
                        ]}
                    /> */}
                    <br /><br />
                    <iframe src={`${config.oq.uri}${oq_key}`}
                        height="550px"
                        width="100%"
                        style={{
                            border: "none",
                            margin: 0,
                            padding: '-25px',
                            alignSelf: "center",
                            //  boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), -4px -4px 30px 19px rgba(0, 0, 0, 0.1)" 
                        }} />
                </div>
            }
        />
    }

    getVehicles(data) {
        return <Card
            title={window.t.en('Vehicle List')}
            subtitle={window.t.en('Selected vehicle for quote')}
            content={
                <Table className="table" >
                    <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
                        <th className="text-left "></th>
                        <th className="text-left ">Vehicle Description</th>
                        <th className="text-left">Vehicle Pricing</th>
                        <th className="text-right">Residual Values</th>
                        <th className="text-right">Vehicle Component </th>
                        <th className="text-right">Rental</th>
                    </thead>
                    <tbody style={{ zIndex: 1, fontWeight: 'normal' }}>
                        {
                            data.map((x, i) => {
                                return <>
                                    <tr style={{ textAlign: 'left' }} key={'vehicle_inx_' + i}>
                                        <td xl={3}>
                                            <img style={styles.vehicle} src={config.system.cdn + '/' + (x.worksheet.mmcode.imagefilename !== null ? x.worksheet.mmcode.imagefilename : 'unknown.png')} />
                                        </td>
                                        <td xl={3}>
                                            MM: {x.worksheet.mmcode.mmcode}  <strong>{x.worksheet.quotenumber}</strong> <br />
                                            {x.worksheet.mmcode.make}<br />
                                            {x.worksheet.mmcode.cdescription}
                                        </td>
                                        <td xl={3}>
                                            RP: {parseFloat(x.worksheet.retailprice)}<br />
                                            DS: {parseFloat(x.worksheet.discount)}<br />
                                            VP: {parseFloat(x.worksheet.vehicleprice)}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            RV %: {x.worksheet.rv}<br />
                                            RV: {x.worksheet.rv_amount}<br />
                                            {x.worksheet.deliveryperiod + ' Days'}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            Maint: {x.worksheet.maintenance}<br />
                                            Tyres: {x.worksheet.tyres}<br />
                                            Service: {x.worksheet.service}
                                        </td>
                                        <td xl={1} style={styles.numericFieldValues}>
                                            {x.worksheet.monthly_excl}<br />
                                            {x.worksheet.monthly_vat}<br />
                                            {x.worksheet.monthly_incl}
                                        </td>
                                    </tr>
                                    <tr key={'vehicle_inx_' + i}>
                                        <td xl={12} colSpan={6} style={{ textAlign: 'right' }}>
                                            <Button className={"global_button"} variant="contained"
                                                label="Add Item"
                                                labelPosition="before"
                                                primary={!this.props.state.sun}
                                                style={{ marginLeft: 20, fontSize: 16 }}
                                                icon={<Icon iclass={'material-icons'} iname={'add'} />}
                                                onClick={(event) => {
                                                    this.props.actions.setInputValue({ prop: 'vehicle', value: x })
                                                    //console.log('vehicle data receiptdate', x)
                                                    let deliveryperiod = x.worksheet.deliveryperiod ? parseInt(x.worksheet.deliveryperiod) : 0

                                                    this.props.actions.setInputValue({ prop: 'receiptdate', value: moment().add(deliveryperiod, 'days').format('YYYY-MM-DD') })
                                                }}
                                            >
                                                Accept Quote {x.worksheet.quotenumber}
                                            </Button>
                                        </td>
                                    </tr>
                                </>
                            })
                        }
                    </tbody>
                </Table>
            }
        />
    }


    getForm(data) {
        return <Card
            title={window.t.en('Quote Form')}
            subtitle={window.t.en('Complete form the form')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <Fields nocard={true}
                                fields={[
                                    { size: 4, label: 'Quote Number:', value: data.worksheet.quotenumber },
                                    { size: 4, label: 'Vehicle MM Code:', value: data.worksheet.mmcode.mmcode },
                                    { size: 4, label: 'Vehicle Make:', value: data.worksheet.mmcode.make },
                                    { size: 4, label: 'Vehicle Model:', value: data.worksheet.mmcode.cdescription },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><hr /></Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <AutoComplete
                                variant="outlined"
                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                id={'ddl_funder'}
                                key={'ddl_funder'}
                                hintText={'Select Vehicle Funder '}
                                label={'Select Vehicle Funder'}
                                selectOnFocus
                                filter={AutoComplete.fuzzyFilter}
                                openOnFocus={true}
                                fullWidth={true}
                                dataSourceConfig={{
                                    text: 'text',
                                    value: 'value'
                                }}
                                fieldvalue={'value'}
                                displayfields={['text']}
                                value={this.props.state.financialinstitution}
                                dataSource={this.props.state.financialinstitution_data}
                                disabled={this.props.confirm ? true : false}
                                onSelect={
                                    (args, index) => {
                                        this.props.actions.setInputValue({ prop: 'financialinstitution_id', value: args.value })
                                        this.props.actions.setInputValue({ prop: 'financialinstitution', value: args.text })
                                    }
                                }
                                onClick={
                                    (event) => {
                                        this.props.actions.getLookupValueData('financialinstitution', 'fundername', '')
                                    }
                                }
                                onFilter={
                                    (search, dataSource, params) => {
                                        if (search) {
                                            this.props.actions.getLookupValueData('financialinstitution', 'fundername', search)
                                            this.props.actions.setInputValue({ prop: 'financialinstitution', value: search })
                                        } else {
                                            this.props.actions.setInputValue({ prop: 'financialinstitution_id', value: null })
                                            this.props.actions.setInputValue({ prop: 'financialinstitution', value: '' })
                                        }

                                    }
                                }
                            />
                        </Col>

                        <Col xl={4}>
                            <AutoComplete
                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                id={'ddl_supplier'}
                                key={'ddl_supplier'}
                                hintText={'Select Dealer/OEM'}
                                filter={AutoComplete.fuzzyFilter}
                                openOnFocus={true}
                                fullWidth={true}
                                label={'Select OEM Supplier'}
                                dataSourceConfig={{
                                    text: 'text',
                                    value: 'value'
                                }}
                                fieldvalue={'value'}
                                displayfields={['text']}
                                value={this.props.state.supplier}
                                dataSource={this.props.state.supplier_data}
                                disabled={this.props.confirm ? true : false}
                                onSelect={
                                    (args, index) => {
                                        this.props.actions.setInputValue({ prop: 'supplier_id', value: args.value })
                                        this.props.actions.setInputValue({ prop: 'supplier', value: args.text })
                                        
                                        this.props.actions.setInputValue({ prop: 'dealer_id', value: args.value })
                                        this.props.actions.setInputValue({ prop: 'dealer', value: args.text })
                                    }
                                }
                                onClick={
                                    (event) => {
                                        this.props.actions.getLookupValueData('supplier', 'suppliername', '')
                                    }
                                }
                                onFilter={
                                    (search, dataSource, params) => {
                                        if (search) {
                                            this.props.actions.getLookupValueData('supplier', 'suppliername', search)
                                            this.props.actions.setInputValue({ prop: 'supplier', value: search })
                                        } else {
                                            this.props.actions.setInputValue({ prop: 'supplier_id', value: null })
                                            this.props.actions.setInputValue({ prop: 'supplier', value: '' })
                                        }
                                    }
                                }
                            />
                        </Col>
                        {/* <Col xl={4}>
                            <AutoComplete
                                listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                id={'ddl_dealer'}
                                key={'ddl_dealer'}
                                hintText={'Select Supplier/Dealer'}
                                filter={AutoComplete.fuzzyFilter}
                                selectOnFocus
                                openOnFocus={true}
                                fullWidth={true}
                                label={'Select Supplier/Dealer'}
                                dataSourceConfig={{
                                    text: 'text',
                                    value: 'value'
                                }}
                                fieldvalue={'value'}
                                displayfields={['text']}
                                value={this.props.state.dealer}
                                disabled={this.props.confirm ? true : false}
                                dataSource={this.props.state.dealer_data}
                                onSelect={
                                    (args, index) => {
                                        this.props.actions.setInputValue({ prop: 'dealer_id', value: args.value })
                                        this.props.actions.setInputValue({ prop: 'dealer', value: args.text })
                                    }
                                }
                                onClick={
                                    (event) => {
                                        this.props.actions.getLookupValueData('supplier', 'suppliername', '', 'dealer')
                                    }
                                }
                                onFilter={
                                    (search, dataSource, params) => {
                                        if (search) {
                                            this.props.actions.getLookupValueData('supplier', 'suppliername', search, 'dealer')
                                            this.props.actions.setInputValue({ prop: 'dealer', value: search })
                                        } else {
                                            this.props.actions.setInputValue({ prop: 'dealer_id', value: null })
                                            this.props.actions.setInputValue({ prop: 'dealer', value: '' })
                                        }

                                    }
                                }
                            />
                        </Col> */}
                        <Col xl={4} style={{ marginTop: '-5px' }}>
                            <DateControl
                                id={'txtpoddate'}
                                key={'txtpoddate'}
                                label={'Select purchase order date'}
                                name={'txtpoddate'}
                                disabled={this.props.confirm ? true : false}
                                value={this.props.state.poddate}
                                onSelect={(args) => {
                                    this.props.actions.setInputValue({ prop: 'poddate', value: args })
                                }}
                                type={'date'}
                            />
                        </Col>
                        <Col xl={4} style={{ marginTop: '-5px' }}>
                            <DateControl
                                id={'txtvrddate'}
                                key={'txtvrddate'}
                                label={'Select date vehicle required'}
                                name={'txtvrddate'}
                                disabled={this.props.confirm ? true : false}
                                value={this.props.state.receiptdate}
                                onSelect={(args) => {
                                    this.props.actions.setInputValue({ prop: 'receiptdate', value: args })
                                }}
                                type={'date'}
                            />
                        </Col>
                        <Col xl={4}>
                            <TextField
                                variant="outlined"
                                id={'txtcomments'}
                                hintText={'Enter comments'}
                                fullWidth={true}
                                multiLine={true}
                                disabled={this.props.confirm ? true : false}
                                value={this.props.state.pocomments}
                                label={'PO Comments'}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue({ prop: 'pocomments', value: event.target.value })
                                }}
                            />
                        </Col>
                        <Col xl={12}>
                            <TextField
                                variant="outlined"
                                id={'txtinstruction'}
                                hintText={'Enter delivery instruction'}
                                fullWidth={true}
                                multiLine={true}
                                label={'Enter delivery instruction'}
                                disabled={this.props.confirm ? true : false}
                                value={this.props.state.instruction}
                                rows={2}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue({ prop: 'instruction', value: event.target.value })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            {this.props.workflow_queue_data.workflow_queue_id && <ViewQuotation
                                key={this.props.workflow_queue_data.workflow_queue_id}
                                id={this.props.workflow_queue_data.workflow_queue_id}
                            />
                            }</Col>
                        <Col xl={12}>
                            <TextField
                                variant="outlined"
                                id={'txtyocomments'}
                                hintText={'Enter Your comments'}
                                label={'Quotation Notes'}
                                fullWidth={true}
                                multiLine={true}
                                rows={5}
                                disabled={this.props.confirm ? true : false}
                                value={this.props.state.comments}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue({ prop: 'comments', value: event.target.value })
                                }}
                            />
                        </Col>
                    </Row>
                </Container>

            }
        />
    }

    getFormView(data) {
        return <Card
            title={window.t.en('Quote Form')}
            subtitle={window.t.en('Complete form')}
            content={
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <Fields nocard={true}
                                fields={[
                                    { size: 4, label: 'Vehicle Make:', value: data.worksheet.mmcode.make },
                                    { size: 4, label: 'Vehicle Model:', value: data.worksheet.mmcode.cdescription },
                                    { size: 4, label: 'Vehicle MM Code:', value: data.worksheet.mmcode.mmcode },
                                    { size: 4, label: 'Quotation Number:', value: data.worksheet.quotenumber },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}><hr /></Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <Fields nocard={true}
                                fields={[
                                    { size: 4, label: 'Select Vehicle Funder:', value: this.props.state.financialinstitution },
                                    { size: 4, label: 'Select OEM Supplier:', value: this.props.state.supplier },
                                    { size: 4, label: 'Select Dealer:', value: this.props.state.dealer },
                                    { size: 4, label: 'Vehicle Delivery Address:', value: this.props.state.instruction },
                                    { size: 4, label: 'PO Comments:', value: this.props.state.pocomments },
                                    { size: 4, label: 'Date Customer Order Received:', value: this.props.state.poddate },
                                    { size: 4, label: 'Date Vehicle Required:', value: this.props.state.receiptdate },
                                    { size: 4, label: 'Your Comments:', value: this.props.state.comments },
                                ]}
                            />
                        </Col>
                    </Row>
                </Container>
            }
        />
    }

    getDocuments() {
        return <Card
            title={window.t.en('Document')}
            subtitle={window.t.en('Upload Supporting Documents (Optional)')}
            content={
                <Upload
                    id={'fml_accept'}
                    prefix={'FML_Customer_Accept_'}
                    header={false}
                //list={[{ value: 0, text: 'Customer RFQ' },{ value: 0, text: 'Customer PO' },{ value: 0, text: 'Supplier Quotation' }]}
                />
            }
        />
    }

    getViewDocuments() {
        return <Card
            title={window.t.en('Document')}
            subtitle={window.t.en('Uploaded Customer Supporting Documents')}
            content={
                this.props.state.documents.documents
                    ? <PdfViewer
                        key={'uplaod_customer_viewer'}
                        documents={this.props.state.documents.documents}
                        preview={'preview'}
                    />
                    : 'Loading...'
            }
        />
    }

    getView(data) {
        return <div>
            {this.getContract(data.quotations[0].report_data)}
            {this.getCustomer(data.quotations[0].report_data.customer)}
            {
                this.props.confirm ? <div>
                    {this.getFormView(this.props.state.vehicle)}
                    {this.getViewDocuments()}
                </div>
                    : <div>
                        {this.getVehicles(data.quotations)}
                        {this.props.state.vehicle && this.getForm(this.props.state.vehicle)}
                        {this.getDocuments()}
                    </div>
            }
        </div>
    }

    render() {
        let workflow_queue_data = this.props.workflow_queue_data
        //console.log('WorkflowViewFMLQuoteConfirm', workflow_queue_data)
        let data = null

        try {
            data = workflow_queue_data.data[0].context_data.data.data.data[0]
        }
        catch (err) { }

        //console.log('WorkflowViewFMLQuoteConfirm data', data)
        return (
            data
                ? this.getView(data)
                : <Loading message={'Retrieving data, please wait...'} />
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowViewFMLQuoteConfirm,
            documents: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;

    return {
        actions: bindActionCreators(
            { ...actions, ...otherWorkflowTaskInputActions },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowViewFMLQuoteConfirm)