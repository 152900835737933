import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'

import { Container, Row, Col } from 'react-grid-system'

import { Table, Button, IconButton } from '@mui/material'

import Icon from '../../Icon/component'

import VisibilityIcon from '@mui/icons-material/Visibility';
import Loading from '../../Loading/component'
import Card from '../../../controls/card'
import MultiSelect from '../../../controls/multiselect'
import AutoComplete from '../../../controls/autocomplete'
import ViewVehicle from '../../../controls/view_vehicle'
import ViewSupplier from '../../../controls/view_supplier'
import ViewCustomer from '../../../controls/view_customer'
import ViewSupplierTransactions from '../../../controls/view_supplier_transaction'
import SupplierSpend from '../../../controls/view_supplier_spend'
import Fields from '../../../controls/cardfields'
import Form from '../../../controls/form'
import Drawer from '../../../controls/drawer'
import Upload from '../../Upload/component'
import PdfViewer from '../../PDF/component'

import Checkbox from '../../../controls/checkbox'
import CardControl from '../../../controls/card'

import TextField from '../../../controls/textField'
import DateControl from '../../../controls/dateControl'

import * as styles from './styles'
import * as actions from './actions'

import * as workflowTaskInputActions from '../../WorkflowTaskInput/actions'

class CreditNoteCreate extends Component {

    componentDidMount() {
        this.props.actions.setInputValue('win_view_documents', false)
        if (this.props.mode === "confirm") {
            this.props.actions.setupWorkflowTaskInput(this.props.state, [], 'credit_note', 'tsk_credit_note_create')
        }
        else {
            this.props.actions.resetForm()
            this.props.actions.setValidationState('creditNoteCreate')
            //console.log('statex',JSON.stringify(this.props.state))
        }
    }

    getDuplicateData() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Auth No</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Odometer</th>
                                    <th>Task No</th>
                                    <th>Booked By</th>
                                    <th>Booked Date</th>
                                    <th>Supplier</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    [{}].map(x => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.authnumber}</td>
                                            <td>{x.maintenancetype}</td>
                                            <td>{x.comments}</td>
                                            <td>{x.odometer}</td>
                                            <td>{x.tasknumber}</td>
                                            <td>{x.bookedby}</td>
                                            <td>{x.bookeddate}</td>
                                            <td>{x.suppliertype}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    invoiceItems() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>

                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Invoice Number</th>
                                    <th>Auth Number</th>
                                    <th>Description</th>
                                    <th>Invoice Date</th>
                                    <th>Invoice Amount</th>
                                    <th>Credited Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.customer_transactions.filter(x => x.is_creditnote).map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.reference}</td>
                                            <td>{x.authnumber}</td>
                                            <td>{x.description}</td>
                                            <td>{moment(x.suppliertransactiondate).format('DD-MM-YYYY')}</td>
                                            <td>{x.amount}</td>
                                            <td>{x.creditnote_amount}</td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={5}></th>
                                    <th>{_.sumBy(this.props.state.customer_transactions.filter(x => x.is_creditnote), (d) => { return parseFloat(d.creditnote_amount ? d.creditnote_amount : 0) }).toFixed(2)}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getPenaltiesOther() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <td colSpan={5}>
                                        <Button className={"global_button"}
                                            primary={true}
                                            onClick={() => {
                                                this.props.actions.addPenaltiesBlankRow()
                                            }}
                                            icon={<Icon iclass={'material-icons'} iname={'plus_one'} />}
                                        >{('Add')}</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Date</th>
                                    <th>Reference</th>
                                    <th>Reason</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.penalties_data.map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>
                                                <IconButton key={'delete_forever_' + inx}
                                                    onClick={
                                                        () => {
                                                            this.props.actions.removePenaltiesRow(inx)
                                                        }
                                                    }>
                                                    <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
                                                </IconButton>
                                            </td>
                                            <td>
                                                <DateControl
                                                    id={'cre_date_' + inx}
                                                    key={'cre_date_' + inx}
                                                    label={'Select Transaction Date'}
                                                    name={'date'}
                                                    value={x.date}
                                                    onSelect={(args) => {
                                                        this.props.actions.setPenRowInputValue(inx, 'date', args)
                                                    }}
                                                    type={'date'}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'pen_reference_' + inx}
                                                    label={'Enter Reference'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.reference}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setPenRowInputValue(inx, 'reference', event.target.value)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'pen_reason_' + inx}
                                                    label={'Enter Reason'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.reason}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setPenRowInputValue(inx, 'reason', event.target.value)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'pen_amount_' + inx}
                                                    label={'Enter Amount'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.amount}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setPenRowInputValue(inx, 'amount', event.target.value)
                                                        this.props.actions.calcTotal()
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={4}></th>
                                    <th style={{ textAlign: 'right', paddingLeft: '20px' }}>Total: {_.sumBy(this.props.state.penalties_data, (d) => { return parseFloat(d.amount) })}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getPenaltiesOtherConfirm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Reference</th>
                                    <th>Reason</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.penalties_data.map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.date}</td>
                                            <td>{x.reference}</td>
                                            <td>{x.reason}</td>
                                            <td>{x.amount} </td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={3}></th>
                                    <th style={{ textAlign: 'right', paddingLeft: '20px' }}>Total: {_.sumBy(this.props.state.penalties_data, (d) => { return parseFloat(d.amount) })}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getPaymentsNotCredited() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <td colSpan={5}>
                                        <Button className={"global_button"}
                                            primary={true}
                                            onClick={() => {
                                                this.props.actions.addCreditedBlankRow()
                                            }}
                                            icon={<Icon iclass={'material-icons'} iname={'plus_one'} />}
                                        >{('Add')}</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Date</th>
                                    <th>Reference</th>
                                    <th>Reason</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.credited_data.map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>
                                                <IconButton key={'delete_forever_' + inx}
                                                    onClick={
                                                        () => {
                                                            this.props.actions.removeCreditedRow(inx)
                                                        }
                                                    }>
                                                    <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
                                                </IconButton>
                                            </td>
                                            <td>
                                                <DateControl
                                                    id={'cre_date_' + inx}
                                                    key={'cre_date_' + inx}
                                                    label={'Enter Transaction Date'}
                                                    name={'date'}
                                                    value={x.date}
                                                    onSelect={(args) => {
                                                        this.props.actions.setCreRowInputValue(inx, 'date', args)
                                                    }}
                                                    type={'date'}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'cre_reference_' + inx}
                                                    label={'Enter Reference'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.reference}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setCreRowInputValue(inx, 'reference', event.target.value)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'cre_reason_' + inx}
                                                    label={'Enter Reason'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.reason}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setCreRowInputValue(inx, 'reason', event.target.value)
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <TextField variant="outlined"
                                                    id={'cre_amount_' + inx}
                                                    label={'Enter Amount'}
                                                    fullWidth={true}
                                                    multiLine={false}
                                                    value={x.amount}
                                                    onChange={(event, value) => {
                                                        this.props.actions.setCreRowInputValue(inx, 'amount', event.target.value)
                                                        this.props.actions.calcTotal()
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={4}></th>
                                    <th style={{ textAlign: 'right', paddingLeft: '20px' }}>Total: {_.sumBy(this.props.state.credited_data, (d) => { return parseFloat(d.amount) })}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getPaymentsNotCreditedConfirm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        <Table className="table table-hover text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Reference</th>
                                    <th>Reason</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.state.credited_data.map((x, inx) => {
                                        return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                            <td>{x.date}</td>
                                            <td>{x.reference}</td>
                                            <td>{x.reason}</td>
                                            <td>{x.amount}</td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <th colSpan={3}></th>
                                    <th style={{ textAlign: 'right', paddingLeft: '20px' }}>Total: {_.sumBy(this.props.state.credited_data, (d) => { return parseFloat(d.amount) })}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    }

    paymentSummary() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>

                    <Table className="table table-hover text-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>Statement Amount</th>
                                <th>Allocated Amount</th>
                                {/* <th>Short Payment</th> */}
                                <th>Credited Amount</th>
                                <th>Discount Percentage</th>
                                <th>Discount Amount</th>
                                <th>Payable Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <td>
                                <TextField
                                    id={'short_payment'}
                                    multiLine={false}
                                    width={160}
                                    label={'Statement Amount'}
                                    variant="outlined"
                                    value={this.props.state.statement_amount}
                                    onChange={(event) => {
                                        this.props.actions.setInputValue('statement_amount', event.target.value)
                                        this.props.actions.calcTotal()
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={'short_payment'}
                                    multiLine={false}
                                    width={160}
                                    label={'Allocated Amount'}
                                    variant="outlined"
                                    value={this.props.state.allocated_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //this.props.actions.setInputValue('statement_amount', event.target.value)
                                    }}
                                />
                            </td>
                            {/* <td>
                                <TextField
                                    id={'short_payment'}
                                    multiLine={false}
                                    width={160}
                                    label={'Short Payment'}
                                    variant="outlined"
                                    value={this.props.state.shortpayment_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                    }}
                                />
                            </td> */}
                            <td>
                                <TextField
                                    id={'credited_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Credited Amount'}
                                    variant="outlined"
                                    value={this.props.state.credited_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //console.log('')
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={'discount_percentage'}
                                    multiLine={false}
                                    width={160}
                                    label={'Discount Percentage'}
                                    variant="outlined"
                                    value={this.props.state.discount_percentage}
                                    //disabled={true}
                                    onChange={(event) => {
                                        this.props.actions.setInputValue('discount_percentage', event.target.value)

                                        this.props.actions.calcTotal()
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={'discount_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Discount Amount'}
                                    variant="outlined"
                                    value={this.props.state.discount_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        this.props.actions.setInputValue('discount_amount', event.target.value)
                                        this.props.actions.calcTotal()
                                    }}
                                />
                            </td>
                            <td>
                                <TextField
                                    id={'payable_amount'}
                                    multiLine={false}
                                    width={160}
                                    label={'Payable Amount'}
                                    variant="outlined"
                                    value={this.props.state.payable_amount}
                                    disabled={true}
                                    onChange={(event) => {
                                        //console.log('')
                                    }}
                                />
                            </td>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    }

    getForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Invoice Date:'}</div>
                        <div style={styles.fieldContent}>

                            <DateControl
                                id={'date'}
                                key={'date'}
                                label={'Enter transaction date'}
                                name={'date'}
                                //error={this.props.state.fields.date.value ? false : true}
                                //helperText={this.props.state.fields.date.value ? '' : 'Enter transaction date'}
                                value={this.props.state.date}
                                onSelect={(args) => {
                                    this.props.actions.setInputValue('date', args)
                                }}
                                type={'date'}
                            />
                        </div>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Month:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField variant="outlined"
                                id={'month'}
                                label={'Enter transaction month'}
                                fullWidth={true}
                                multiLine={false}
                                //error={isNaN(this.props.state.fields.month.value) ? true : false}
                                //helperText={isNaN(this.props.state.fields.month.value) ? '' : 'Enter month (YYYYMM)'}
                                value={this.props.state.month}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('month', event.target.value)
                                }}
                            />
                        </div>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Invoice Number:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField variant="outlined"
                                id={'reference'}
                                label={'Enter Invoice Number'}
                                fullWidth={true}
                                multiLine={false}
                                //error={this.props.state.fields.invoice_number.value ? false : true}
                                //helperText={this.props.state.fields.invoice_number.value ? '' : 'Enter invoice number'}
                                value={this.props.state.invoice_number}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('invoice_number', event.target.value)
                                }}
                            />
                        </div>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div style={styles.fieldContainer}>
                        <div style={styles.fieldTitle}>{'Invoice Amount:'}</div>
                        <div style={styles.fieldContent}>
                            <TextField variant="outlined"
                                id={'description'}
                                label={'Invoice Amount'}
                                fullWidth={true}
                                multiLine={false}
                                disabled={true}
                                //error={this.props.state.fields.invoice_amount.value ? false : true}
                                //helperText={this.props.state.fields.invoice_amount.value ? '' : 'Enter amount'}
                                value={this.props.state.invoice_amount}
                                onChange={(event, value) => {
                                    this.props.actions.setInputValue('invoice_amount', event.target.value)
                                }}
                            />
                        </div>
                    </div>

                </Col>
            </Row>
        </Container>
    }

    viewForm() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <Card title={window.t.en('Invoice Details')}
                        subtitle={window.t.en('Capture customer details')}
                        content={
                            <div>
                                <Fields labelsize='130'
                                    fields={[
                                        { size: 6, label: 'Customer:', value: '' },
                                        { size: 6, label: 'Date', value: '' },
                                        { size: 6, label: 'Reference', value: '' },
                                        { size: 6, label: 'Description', value: '' },
                                    ]}
                                /></div>
                        }
                    />
                </Col>
                <Col xl={12}>
                    {/* {
                            this.data.step1.supplier_id && <ViewSupplier
                                min={true}
                                key={this.data.step1.supplier_id} 
                                id={this.data.step1.supplier_id} 
                            />
                        } */}
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                </Col>
            </Row>
        </Container>
    }


    getTransactionItems(confirm) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    <div>
                        {
                            confirm
                                ? <Table className="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Invoice Number</th>
                                            <th>Auth Number</th>
                                            <th>Description</th>
                                            <th>Invoice Date</th>
                                            <th>Invoice Amount</th>
                                            {/* <th>Short Payment</th> */}
                                            <th>Pay Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.state.customer_transactions.filter(x => x.is_creditnote).map((x, inx) => {
                                                return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                                    <td><b>{x.reference}</b></td>
                                                    <td>{x.authnumber}</td>
                                                    <td>{x.description}</td>
                                                    <td>{moment(x.customertransactiondate).format('DD-MM-YYYY')}</td>
                                                    <td>{x.creditnote_amount}</td>
                                                    <th> {'YES'} </th>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                : <Table className="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Invoice Number ...</th>
                                            <th>Auth Number</th>
                                            <th>Description</th>
                                            <th>Invoice Date</th>
                                            <th>Invoice Amount</th>
                                            <th>Credit Amount</th>
                                            <th></th>
                                            <th>Credited Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={8}>
                                                <TextField
                                                    id={'short_payment'}
                                                    multiLine={false}
                                                    fullWidth={true}
                                                    label={'Enter Search'}
                                                    variant="outlined"
                                                    value={this.props.state.search}
                                                    onChange={(event) => {
                                                        //console.log('')
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        {
                                            this.props.state.customer_transactions.map((x, inx) => {
                                                return <tr style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                                    <td>{x.reference} </td>
                                                    <td>{x.authnumber}</td>
                                                    <td>{x.description}</td>
                                                    <td>{moment(x.customertransactiondate).format('DD-MM-YYYY')}</td>
                                                    <td>{x.amount}</td>
                                                    <th>
                                                        <Checkbox
                                                            checked={x.is_creditnote === true}
                                                            onCheck={(checked) => {
                                                                this.props.actions.setListInputValue('is_creditnote', !x.is_creditnote, inx)
                                                                if (!x.is_creditnote) {
                                                                    this.props.actions.setListInputValue('creditnote_amount', 0, inx)
                                                                    this.props.actions.setListInputValue('credited_amount', 0, inx)
                                                                }
                                                            }}
                                                        />
                                                    </th>
                                                    <td>
                                                        <TextField variant="outlined"
                                                            id={'credit_note_' + inx}
                                                            label={'Enter Credit Note'}
                                                            fullWidth={true}
                                                            multiLine={false}
                                                            disabled={x.is_creditnote ? false : true}
                                                            value={x.creditnote_amount ? x.creditnote_amount : '0.00'}
                                                            onChange={(event, value) => {
                                                                this.props.actions.setListInputValue('creditnote_amount', event.target.value, inx)
                                                                //this.props.actions.setPenRowInputValue(inx, 'amount', event.target.value)
                                                                this.props.actions.checkCreditNoteAmount(x.amount, x.creditnote_amount, inx)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    }

    getView() {
        let size = 7
        return <div>
            <Card
                title={'Capture Credit Note'}
                subtitle={'Credit note capture internal reference '}
                content={<Form label={false} fields={[
                    {
                        name: 'select_customer', title: 'Customer Selection', section: '', type: 'dll', size: 4,
                        data: this.props.state.customer_data, value: this.props.state.customername,
                        onSelect: (args) => {
                            this.props.actions.setInputValue('customer_id', args.value)
                            this.props.actions.setInputValue('customername', args.text)
                            this.props.actions.getComponentCustomerTransaction(args.value)
                        },
                        onClick: () => { this.props.actions.getLookupData('customer', 'customername', '') },
                        onFilter: (search) => {
                            if (search) {
                                this.props.actions.getLookupData('customer', 'customername', search)
                                this.props.actions.setInputValue('customername', search)
                            } else {
                                this.props.actions.setInputValue('customer_id', null)
                                this.props.actions.setInputValue('customername', '')
                            }
                        }

                    },
                    {
                        name: 'select_business', title: 'Business Account', section: '', type: 'dll', size: 4,
                        data: this.props.state.client_data, value: this.props.state.client,
                        onSelect: (args) => {
                            this.props.actions.setInputValue('client_id', args.value)
                            this.props.actions.setInputValue('client', args.text)
                        },
                        onClick: () => { this.props.actions.getLookupData('client', 'title', '') },
                        onFilter: (search) => {
                            if (search) {
                                this.props.actions.getLookupData('client', 'title', search)
                                this.props.actions.setInputValue('client', search)
                            } else {
                                this.props.actions.setInputValue('client_id', null)
                                this.props.actions.setInputValue('client', '')
                            }
                        }

                    },
                    {
                        name: 'creditnote_date', label: 'Credit Note Date', title: 'credit_note_create Date', section: '', type: 'd', size: 4, value: this.props.state.creditnote_date,
                        onSelect: (arg) => { this.props.actions.setInputValue('creditnote_date', arg) }
                    },
                    {
                        name: 'creditnote_description', fullWidth: true, label: 'Credit Note Description', title: 'Credit Note Description', section: '', type: 't', size: 12, value: this.props.state.creditnote_description,
                        onChange: (arg) => { this.props.actions.setInputValue('creditnote_description', arg.target.value) }
                    },
                    {
                        name: 'creditnote_corrective', fullWidth: true, label: 'Credit Note Corrective Measure', title: 'Credit Note Corrective Measure', section: '', type: 't', size: 12, value: this.props.state.creditnote_corrective,
                        onChange: (arg) => { this.props.actions.setInputValue('creditnote_corrective', arg.target.value) }
                    },
                    {
                        name: 'creditnote_action', fullWidth: true, label: 'Credit Note Action', title: 'Credit Note Action', section: '', type: 't', size: 12, value: this.props.state.creditnote_action,
                        onChange: (arg) => { this.props.actions.setInputValue('creditnote_action', arg.target.value) }
                    },
                ]} />}
            />
            {
                this.props.state.customer_id
                    ? <>
                        <Card
                            title={'Upload Document'}
                            subtitle={'Upload creditor payment supporting document: '}
                            content={
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <Upload
                                                id={'upload_ctl'}
                                                prefix={'Creditor_Attach_'}
                                                header={false}
                                            />
                                        </Col>
                                    </Row></Container>}
                        />
                        {/* <ViewCustomer key={this.props.state.customer_id} id={this.props.state.customer_id} /> */}
                        <Card
                            title={'Customer Transaction'}
                            subtitle={'Customer Name'}
                            content={this.getTransactionItems()}
                        />
                    </>
                    : <></>
            }
        </div>
    }

    getConfirm() {

        return <div>
            <ViewCustomer key={this.props.state.customer_id} id={this.props.state.customer_id} />
            <Card
                title={'Invoice Item'}
                subtitle={'Capture invoice line items'}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                <h5>&nbsp;&nbsp;&nbsp;{'Selected Invoice List'}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.invoiceItems()}
                            </Col>
                        </Row>
                    </Container>}
            />
            <Card
                title={'Supporting Documents'}
                subtitle={'Credit note uploaded supporting documents '}
                content={
                    <PdfViewer
                        key={'payment_document'}
                        name={'payment_document'}
                        documents={this.props.state.upload.documents}
                    />
                }
            />
        </div>
    }

    getLayout() {
        //return this.getView() //getConfirm()
        return <>
            {this.props.mode === "confirm" ? this.getConfirm() : this.getView()}

            <Drawer
                onClose={(event, value) => {
                    this.props.actions.setInputValue('win_view_documents', false)
                }}
                anchor={'right'}
                width={'60%'}
                open={this.props.state.win_view_documents}
                title={'Transaction Documents'}
                subtitle={'For ' + this.props.state.transactionx.authnumber}
                content={
                    <PdfViewer
                        key={'document_viewer_approval'}
                        name={'booking'}
                        workflow={'fml_customer_quote'}
                        task={'tsk_purchase_order_review_and_send'}
                        documents={this.props.state.transactionx.documents ? this.props.state.transactionx.documents : []}
                        multiple={true}
                        edit={false}
                    />
                }
            />
        </>
    }

    getErrorLayout() {
        return <Card
            title={'Error'}
            subtitle={'Connection did not respond  '}
            content={<div>
                <h4>Please check following:</h4>
                <ul>
                    <li>Make sure engine is connected</li>
                    <li>Make sure your not login twice</li>
                </ul>
                <i>Alternativily you can contact system administrator for further assistance.</i>
            </div>}
        />
    }

    render() {

        this.data = null
        try {
        } catch (error) {

        }

        return this.getLayout()
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.creditNoteCreate,
            upload: {
                ...state.upload
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setupWorkflowTaskInput, ...otherWorkflowTaskInputActions } = workflowTaskInputActions;
    return {
        actions: bindActionCreators({ ...actions, ...otherWorkflowTaskInputActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteCreate)