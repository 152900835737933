import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"

import Icon from '../../Icon/component'

import { Container, Row, Col } from 'react-grid-system'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'

import TextField from '../../../controls/textField'
import AutoComplete from '../../../controls/autocomplete'
import CardField from '../../../controls/cardfields'

import * as actions from './actions'
import * as styles from './styles'
import Card from '../../../controls/card'

class PaymentItem extends Component {
    componentDidMount() {
        if (this.props.mode !== "confirm") {
            this.props.actions.clearGridItems()
            this.props.actions.setupComponent()
            this.props.actions.setLabourRate(false)
            this.props.actions.toggleLabourRate(false)
            try {
                const quote = this.props.workflow_quote_data
                if (quote.length) {
                    this.props.actions.loadQuoteData(quote)
                }
            } catch (err) { }
        }
        //console.log('PaymentItem this.props.data', this.props.data)
        if (this.props.data)
            this.props.actions.loadQuoteData(this.props.data)
    }

    getCommands(row) {
        //const id = row.original[component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(<div style={{ paddingTop: '35px' }}>
            <IconButton key={'command_remove_' + row.index} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.removeSingleRow(row.index)
                        this.props.actions.updateCounter()
                    }
                }>
                <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'delete_forever'} />
            </IconButton></div>
        )
        return commands
    }
    getValue(row, field) {
        switch (field.type) {
            case 'text':
                return <TextField
                    key={'field_' + row.index + '_' + field.name}
                    id={'id_field_' + row.index + '_' + field.name}
                    //label={field.name}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    margin="normal"
                    hintText={field.name}
                    style={styles.textBox}
                    disabled={this.props.read_only}
                    value={this.props.state.grid[row.index][field.name]}
                    onChange={
                        (event, value) => {
                            this.props.actions.setInputValue(field.name, row.index, event.target.value, this.vattable, this.auto_rebill_tyres)
                        }
                    }
                />
            case 'numeric':
                return <TextField
                    key={'key_field_' + row.index + '_' + field.name}
                    id={'id_field_' + row.index + '_' + field.name}
                    //label={field.name}
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth={true}
                    hintText={field.name}
                    style={!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? styles.redbox : styles.textBox : styles.textBox}
                    disabled={this.props.read_only}
                    value={this.props.state.grid[row.index][field.name]}
                    onChange={
                        (event, value) => {
                            let _val = isNaN(event.target.value) ? 0 : event.target.value
                            this.props.actions.setInputValue(field.name, row.index, _val, this.vattable, this.auto_rebill_tyres)


                            if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                                this.props.actions.setInputValue('labourrate_exceeded', row.index, true, this.vattable, false)
                                //this.props.actions.setLabourRate(true)
                                // this.props.actions.toggleLabourRate(true) 
                            }
                            else {
                                this.props.actions.setInputValue('labourrate_exceeded', row.index, false, this.vattable, false)
                            }
                        }
                    }
                    onBlur={
                        (event, value) => {
                            //let _val = event.target.value === '' || event.target.value === null || event.target.value === undefined ? 0 : isNaN(event.target.value) ? 0 : event.target.value
                            //this.props.actions.setInputValue(field.name, row.index, _val, this.vattable, this.auto_rebill_tyres)
                            // if (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false) {
                            //     this.props.actions.setInputValue('labourrate_exceeded', row.index, true, this.vattable, false)
                            // }
                            // else {
                            //     this.props.actions.setInputValue('labourrate_exceeded', row.index, false, this.vattable, false)
                            // }
                            // if (this.props.state.grid.length && this.props.state.grid.filter(x => x.labourrate_exceeded == true).length > 0 || (!this.props.read_only ? (this.props.state.grid[row.index].Description ? (this.props.state.grid[row.index].Description).toLowerCase() : '').indexOf("labour") >= 0 && field.name === 'Price' && Number(this.props.state.grid[row.index][field.name]) > Number(this.labour_rate) ? true : false : false)) {
                            //     this.props.actions.setLabourRate(true)
                            //     this.props.actions.toggleLabourRate(true)
                            // }
                            // else {
                            //     this.props.actions.setLabourRate(false)
                            //     this.props.actions.toggleLabourRate(false)
                            // }
                        }
                    }
                />
            case 'toggle':
                return this.props.read_only
                    ? <TextField
                        key={'field_' + row.index + '_' + field.name}
                        id={'field_' + row.index + '_' + field.name}
                        hintText={' ' + field.name}
                        style={styles.textBox}
                        size="small"
                        margin="normal"
                        fullWidth={true}
                        disabled={this.props.read_only}
                        value={this.props.state.grid[row.index][field.name] ? 'Yes' : 'No'}
                    />
                    : <div style={{ paddingTop: '25px' }}><Checkbox
                        key={'field_' + row.index + '_' + field.name}
                        id={'field_' + row.index + '_' + field.name}

                        style={styles.toggle}
                        trackStyle={{
                            backgroundColor: 'gray'
                        }}
                        trackSwitchedStyle={this.props.read_only ? {
                            backgroundColor: 'gray'
                        }
                            : {
                                backgroundColor: 'green'
                            }}
                        disabled={this.props.read_only}
                        checked={this.props.state.grid[row.index][field.name]}
                        onChange={
                            (event, isInputChecked) => {
                                this.props.actions.setInputValue(field.name, row.index, !this.props.state.grid[row.index][field.name], this.vattable, this.auto_rebill_tyres)
                            }
                        }
                    />
                    </div>
            case 'exceptions':
                return this.props.read_only
                    ? ''
                    : <div key={'grid_purchase_order_data_' + row.index + '_' + field.name} style={{ padding: "2px" }}>

                        <Row>
                            <Col xl={1}>{'Task'}</Col>
                            <Col xl={2}>{'Supplier'}</Col>
                            <Col xl={3}>{'Date'}</Col>
                            <Col xl={3}>{'Odometer'}</Col>
                            <Col xl={3}>{'Description'}</Col>
                        </Row>


                    </div>
            case 'action':
                return < Button className={"global_button"}
                    secondary={true}
                    key={'field_' + row.index + '_' + field.name}
                    id={'field_' + row.index + '_' + field.name}
                    label={''}
                    icon={<Icon iclass={'material-icons'} iname={'history'} />}
                    onClick={(event) => {
                        this.props.actions.openHistory(row.index)
                    }}
                ><Icon iclass={'material-icons'} iname={'history'} /></Button>
            case 'select':
                return this.props.read_only
                    ? <TextField
                        size="small"
                        margin="normal"
                        key={'field_' + row.index + '_' + field.name}
                        id={'field_' + row.index + '_' + field.name}
                        hintText={field.name}
                        style={styles.textBox}
                        disabled={this.props.read_only}
                        value={this.props.state.grid[row.index][field.name]}
                    />
                    : <div style={{ marginTop: '-5px' }}><AutoComplete
                        key={'field_' + row.index + '_' + field.name}
                        id={'id_field_' + row.index + '_' + field.name}
                        name={'id_field_' + row.index + '_' + field.name}
                        title={this.props.state.grid[row.index][field.name] ? this.props.state.grid[row.index][field.name] : ''}
                        info={{
                            show: false
                        }}
                        selectOnFocus
                        size="small"
                        style={styles.ac}
                        value={this.props.state.grid[row.index][field.name]}
                        fullWidth={true}
                        margin="normal"
                        data={this.props.state[field.component + '_data']}
                        onSelect={(args) => {
                            if (field.name === 'Description') {
                                //console.log(">>>>>>>>>> Begin Checking.....", args)
                                this.props.actions.checkExceptions(args.text);// ( itemdesc character varying, currentodo bigint, bookingdate date, vehicleid bigint)
                            }
                            this.props.actions.setSelectedLookupValue(field.name, row.index, args.value, args.text)
                        }}
                        onClick={() => {
                            this.props.actions.getLookupValues(field.component, field.name, row.index, field.related, '')
                            if (field.name === 'Class') {
                                this.props.actions.setSelectedLookupValue('Description', row.index, undefined, undefined)
                            }
                        }}
                        onFilter={(search) => {
                            if (field.name === 'unitmeasure' || field.name === 'itemdescription') {
                                const searchField = this.props.state[field.component + '_data'].find(x => x.text === search)
                                if (search) {
                                    this.props.actions.getLookupValues(field.component, field.name, row.index, field.related, search)
                                    if (searchField) this.props.actions.setSelectedLookupValue(field.name, row.index, searchField?.value, search)
                                } else {
                                    this.props.actions.getLookupValues(field.component, field.name, row.index, field.related, '')
                                    this.props.actions.setSelectedLookupValue(field.name, row.index, null, '')
                                }
                            }
                            if (field.name === 'Class') {
                                this.props.actions.setSelectedLookupValue('Description', row.index, undefined, undefined)
                            }
                        }}
                    />
                    </div>
            default:
                return ''
        }
    }
    getGridColumns() {
        const fields = this.props.state.fields.filter(x => !x.hide)
        let columns =
            fields.map((x, i) => {
                return {
                    id: x.name + i + '_' + this.props.state.counter,
                    key: x.name + i + '_' + this.props.state.counter,
                    meta: x.name,
                    Header: x.label,
                    accessor: x.name,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        width: x.width ? x.width : undefined,
                    },
                    Cell: (row) => (
                        this.getValue(row, x)
                    ),
                    Footer: (
                        this.getFooter(x.name)
                    )
                }
            })

        if (!this.props.read_only) {
            columns.unshift({
                accessor: 'options',
                filterable: false,
                maxWidth: 40,
                width: 40,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        return columns
    }
    getFooter(field) {
        const total = _.sumBy(this.props.state.grid, (d) => { return parseFloat((d[field]) ? d[field].toString().replace(/,/gi, '') : 0) })
        return (field == 'total' || field == 'vat' || field == 'unitprice')
            ?
            <span>
                {
                    parseFloat(total.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            </span>
            :
            <span>
                <strong></strong>{" "}
            </span>
    }
    getGrid() {
        return <div>

            {
                this.props.read_only
                    ? ''
                    : < Button variant="contained"
                        label={'Add Item'}
                        className="d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
                        primary={true}
                        onClick={(event) => {
                            this.props.actions.addBlankRow(this.auto_rebill)
                        }}
                    >{window.t.en('Add Item')}</Button>
            }
            <hr />
            <ReactTable
                key={'grid_booking_quote'}
                id={'grid_booking_quote'}
                data={this.props.state.grid}
                columns={this.getGridColumns()}
                filterable={false}
                loading={this.props.state.loading}
                manual
                getTrProps={(state, rowInfo) => {
                    return {
                        onClick: (event) => {
                            //this.props.actions.getRelatedRowItem(rowInfo, this.data)
                        }
                    }
                }}
                minRows={1}
                showPagination={false}
                showPaginationBottom={false}
                style={{
                    height: '100%'
                }}
            >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable>
        </div >
    }

    render() {
        return <div className="quote-item">{this.getGrid()}</div>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.paymentItem
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentItem)