export let updatevalue = 0.00
export let statement = 0.00
export let discount = 0.00
export let selectedBusinessUnit =
{
    value: 0,
    text: ''
}
export let businessUnits =
    [
        {
            text: 'Loading...',
            value: ''
        }

    ]

export let payments = [
    {
        id: 0,
        date: '',
        eft: 0.00,
        amount: 0.00,
        discount: 0.00,
        total: 0.00
    }
]
export let credits = [
    {
        id: 0,
        date: '',
        invoicenumber: '',
        amount: 0.00,
        reason: '',
        total: 0.00
    }
]
export let others = [
    {
        id: 0,
        date: '',
        invoicenumber: '',
        amount: 0.00,
        reason: '',
        total: 0.00
    }
]

export let paymentsnotcredited = 0.00
export let creditsnotpassed = 0.00
export let othercredits = 0.00




export const isOpen = false
export const payment_list = []
export const paymentcomment_list = []
export const payment_list_admin = []
export const paymentcomment_list_admin = []
export const payment_list_maint = []
export const paymentcomment_list_maint = []
export const payment_list_tyres = []
export const paymentcomment_list_tyres = []

export const commentfield = ''
export const prop_inx = null
export const prop_drawer= null
export const supplier_list = [{
    value: 0,
    text: 'Loading...'
}]
export const selected_supplier =
{
    value: 0,
    text: ''
}

export const search_text = null
export const settlement = 0.00
export const exceptions = 0
