//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const watermark = {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '16px',
    color: 'gray',
    fontWeight: 'bold',
}

export const icon = {
    fontSize: '10px'
}

export const row = {
    fontSize: 15,
    textAlign: 'left',
    //backgroundColor: '#dcd6d7',
}

export const workflowTaskStepComponentCard = {
    textAlign: 'left',
}
export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldTitleLeft = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const textBox = {
    width: '100%'
}

export const dropZone = {
    width: '90%',
    height: '300px',
    border: '1px solid red',
    padding: '10px'
}


export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldLabelA = {
  width: '500px',
  display: 'table-cell',
  verticalAlign: 'bottom',
  fontSize: '16px',
  textAlign: 'right',
  paddingTop: 20,
  paddingRight: 10
}