export const new_registration = null
export const customer_data = []

export const costcentre = ''
export const costcentre_id = ''
export const customer_id =''
export const costcentrechangedate  = ''


export const fields = { 
    documents: {
        errorText: 'Please add a document and select document type',
        data: false,
        valid: false,
        empty: true,
        popup: true,
        type: 'documents',
      },
      costcentrechangedate: {
        errorText: 'Please select  CostCentre Change Date',
        valid:false,
        state: 'getState().costCentreChangeCapture?.costcentrechangedate'
    },
    customer_id: {
        errorText: 'Please select Vehicle New CostCenter',
        valid:false,
        state: 'getState().costCentreChangeCapture?.customer_id'
    }
}