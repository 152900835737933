import * as types from './types'
import moment from 'moment'

export const setValue = (field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FIELD_VALUE,
            payload: {
                prop: field,
                value: value
            }
        })
    }
}
