import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Card, CardHeader, CardContent } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import WorkflowQueueTaskDocuments from '../WorkflowQueueTaskDocuments/component'
import moment from 'moment'
import Icon from '../../Icon/component'
import AdhocGrid from '../../Custom/AdhocGrid/component'

import WorkflowTimeline from '../WorkflowTimeline/component'

import * as actions from './actions'
import * as styles from './styles'

class CompletedTasks extends Component {
    getTaskCount() {
        return this.props.state.grid['adhoc_grid_workflow_queue_tasks_completed_tasks_' + this.props.team_id] && this.props.state.grid['adhoc_grid_workflow_queue_tasks_completed_tasks_' + + this.props.team_id].data.length
            ? this.props.state.grid['adhoc_grid_workflow_queue_tasks_completed_tasks_' + this.props.team_id].paging.count
            : 0
    }

    getExpandedStatus() {
        return !this.props.state.grid['adhoc_grid_workflow_queue_tasks_completed_tasks_' + this.props.team_id] || this.props.state.grid['adhoc_grid_workflow_queue_tasks_completed_tasks_' + this.props.team_id].data.length
            ? true
            : false
    }

    render() {
        return <Card initiallyExpanded={false} style={styles.completedTasksCard}>
            <CardHeader
                actAsExpander={true}
                showExpandableButton={true}
                title={window.t.en('My Team\'s Completed Tasks & Timeline')}
                subtitle={window.t.en('View your team\'s completed tasks, and the last 1000 workflows related to your team and yourself in a timeline format.')}
                avatar={
                    <Icon istyle={{ color: '#4CAF50' }} iclass={'material-icons'} iname={'check_box'} />
                }
            />
            <CardContent expandable={true}>
                <Tabs>
                    <Tab label={'My Team\'s Completed Tasks'} >
                        <AdhocGrid
                            key={'key_adhoc_grid_workflow_queue_tasks_completed_tasks_' + this.props.team_id}
                            name={'adhoc_grid_workflow_queue_tasks_completed_tasks_' + this.props.team_id}
                            uri={'/components/workflow_queue_task/other'}
                            filterable={true}
                            sortable={true}
                            body={{
                                query: 'completed_tasks',
                                team_id: this.props.team_id
                            }}
                            search={this.props.state.search.search ? this.props.state.search.value : ''}
                            columns={[
                                {
                                    accessor: 'workflow_queue_task#workflow_queue_task_id', Header: 'Ref', filterable: false, width: 75, Cell: row => {
                                        return (
                                            <div>
                                                <span>
                                                    {'#' + row.original['workflow_queue_task#workflow_queue_id'] + '/' + (row.original['workflow_task#order'] ? row.original['workflow_task#order'] : '?')}
                                                </span>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    accessor: 'workflow_queue#header_meta_data', Header: 'Header', filterable: true, width: 200, Cell: row => {
                                        return (
                                            <div>
                                                <span>
                                                    {row.original['workflow_queue#header_meta_data'] ? row.original['workflow_queue#header_meta_data'] : 'Unspecified'}
                                                </span>
                                            </div>
                                        )
                                    }
                                },
                                { accessor: 'workflow#title', Header: 'Workflow', filterable: true, width: 150 },
                                { accessor: 'workflow_task#title', Header: 'Task', filterable: true, width: 175 },
                                {
                                    accessor: 'ref_user#firstname', Header: 'Originated By', filterable: false, width: 175, Cell: row => {
                                        return (
                                            <div>
                                                <span>
                                                    {row.original['ref_user#firstname'] ? row.original['ref_user#firstname'] + ' ' + row.original['ref_user#lastname'] : 'System'}
                                                </span>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    accessor: 'user#firstname', Header: 'Completed By', filterable: false, width: 175, Cell: row => {
                                        return (
                                            <div>
                                                <span>
                                                    {row.original['user#firstname'] ? row.original['user#firstname'] + ' ' + row.original['user#lastname'] : 'System'}
                                                </span>
                                            </div>
                                        )
                                    }
                                },
                                { accessor: 'workflow_queue_task#created_at', Header: 'Created', filterable: false, width: 155, Cell: (row) => (moment(row.value).format('YYYY-MM-DD HH:mm ZZ')) },
                                { accessor: 'workflow_queue_task#queued_date', Header: 'Queued', filterable: false, width: 155, Cell: (row) => (moment(row.value).format('YYYY-MM-DD HH:mm ZZ')) },
                                { accessor: 'workflow_queue_task#success_date', Header: 'Completed', filterable: false, width: 155, Cell: (row) => (moment(row.value).format('YYYY-MM-DD HH:mm ZZ')) },
                                { accessor: 'workflow_task#estimated_duration', Header: 'Allotted TTC', filterable: false, width: 100, Cell: (row) => moment.duration({ minutes: row.value }).asMinutes() + ' minutes' },
                                { accessor: 'workflow_queue_task#actual_duration', Header: 'Actual TTC', filterable: false, width: 100, Cell: (row) => moment.duration({ minutes: row.value }).asMinutes() + ' minutes' },
                                { accessor: 'workflow_queue#meta_data', Header: 'Meta Data', filterable: true },
                            ]}
                            paging={{
                                page: 0,
                                pageSize: 10,
                                pages: 0,
                                count: 0
                            }}
                            subComponent={row => {
                                return <div key={'grid_sub_component_meta_data_' + row.original['workflow_queue_task#workflow_queue_task_id']} style={{ padding: "20px" }}>
                                    {
                                        [
                                            row.original['workflow_queue#meta_data']
                                                ? row.original['workflow_queue#meta_data']
                                                    .split(', ')
                                                    .map(x => {
                                                        return <div>{x}<br /></div>
                                                    })
                                                : <div>No meta data available.</div>,
                                            <br />,
                                            <br />,
                                            row.original.documents > 0
                                                ? <WorkflowQueueTaskDocuments key={'completed_tasks_documents_' + row.original['workflow_queue_task#workflow_queue_task_id']} workflow_queue_task_id={row.original['workflow_queue_task#workflow_queue_task_id']} />
                                                : 'This task did not generate any documents.'
                                        ]
                                    }
                                </div>
                            }}
                        />
                    </Tab>
                    <Tab label={'My Team\'s Timeline'} >
                        <WorkflowTimeline key={'workflow_timeline_team_only' + this.props.team_id + '_' + this.props.user_id} name={'workflow_timeline_team_only' + this.props.team_id + '_' + this.props.user_id} team_id={this.props.team_id} is_visible={true} searchable={true} />
                    </Tab>
                    <Tab label={'My Timeline'} >
                        <WorkflowTimeline key={'workflow_timeline_team_and_user_' + this.props.team_id + '_' + this.props.user_id} name={'workflow_timeline_team_and_user_' + this.props.team_id + '_' + this.props.user_id} team_id={this.props.team_id} user_id={this.props.user_id} is_visible={true} searchable={true} />
                    </Tab>
                </Tabs>
            </CardContent>
        </Card>
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.completedTasks,
            grid: state.adhocGrid,
            search: {
                search: state.workflowQueueTasks.search,
                value: state.workflowQueueTasks.value
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedTasks)