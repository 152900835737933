import { MapLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet-control-geocoder";
import { withLeaflet } from "react-leaflet";

class Routing extends MapLayer {
  createLeafletElement() {
    const { map } = this.props;
    let leafletElement = L.Routing.control({
    //   waypoints: [
    //     L.latLng(-25.994832, 28.064076),
    //     L.latLng(-33.689151, 18.451175)
    //   ],
      lineOptions: {
        styles: [
          {
            color: "red",
            opacity: 0.6,
            weight: 4
          }
        ]
      },
      addWaypoints: true,
      draggableWaypoints: false,
      fitSelectedRoutes: true,
      showAlternatives: false,
      serviceUrl: 'https://osrm-uatfalms.bidvestbank.co.za/route/v1',
      geocoder: L.Control.Geocoder.nominatim({serviceUrl:'https://route-uatfalms.bidvestbank.co.za/nominatim/'})
        //https://nominatim.openstreetmap.org/search?q=hoylake%20%2C%20johannesburg&limit=5&format=json&addressdetails=1
    }).addTo(map.leafletElement);
    
    return leafletElement.getPlan();
  }
}
export default withLeaflet(Routing);
