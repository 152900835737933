export const tracker = {
    //top: '65px',
    height: window.innerHeight - 65
}

export const sources = {
    position: 'absolute',
    top: '100px',
    right: '10px',
    height: '150px',
    zIndex: 10000
}