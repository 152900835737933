//import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const speedDialIcon = {
    /*     fontSize: '35px',
        marginTop: '3px' */
}

export const related = {
    //fontSize: '10px'
}

export const headerButton = {
    width: 250
}

export const triggers = {
    position: 'fixed',
    right: '75px',
    top: '38px',
    zIndex: 1000
}

export const dashboard = {
    margin: '0 auto',
    width: '500px',
    height: '500px'
}

export const MuiFormControl= {
    width: "30%",
}

export const cardDropdown = {
    backgroundColor:"white"
  }