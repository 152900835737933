export const title = ''
export const description = ''

export const vinnumber = ''
export const enginenumber = ''
export const registrationnumber = ''
export const openingodometer = ''
export const registrationdate = ''
export const licenceexpirydate = ''

export const validation = {
  fields: [],
  component: 'workflowPOReceiptAccessories',
  list: [],
  valid: false
}

export const fields = {
  licenseExpiryDate: {
    id: 'dtLicenceExpiryDate',
    name: 'dtLicenceExpiryDate',
    errorText: 'Please enter License Expiry Date',
    state: 'getState().workflowPOReceiptAccessories.licenceexpirydate',
    data: false,
    valid: false,
    empty: true,
    section: 'Vehicle Details'
  },
  registrationDate: {
    id: 'dtRegistrationDate',
    name: 'dtRegistrationDate',
    errorText: 'Please enter Registration Date',
    state: 'getState().workflowPOReceiptAccessories.registrationdate',
    data: false,
    valid: false,
    empty: true,
    section: 'Vehicle Details'
  },
  odo: {
    id: 'txtodo',
    name: 'txtodo',
    errorText: 'Please enter Opening Odometer',
    state: 'getState().workflowPOReceiptAccessories.openingodometer',
    data: false,
    valid: false,
    empty: true,
    section: 'Vehicle Details',
    validations: [
      {
        'schema': 'integer',
        'required': true,
        'maxLength': null,
        'errorText': 'Please input a valid Opening Odometer'
      }
    ]
  },
  registrationNumber: {
    id: 'txtRegnumber',
    name: 'txtRegnumber',
    errorText: 'Please enter Registration Number',
    state: 'getState().workflowPOReceiptAccessories.registrationnumber',
    data: false,
    valid: false,
    empty: true,
    section: 'Vehicle Details'
  },
  engineNumber: {
    id: 'txtEngine',
    name: 'txtEngine',
    errorText: 'Please enter Vehicle Engine Number',
    state: 'getState().workflowPOReceiptAccessories.enginenumber',
    data: false,
    valid: false,
    empty: true,
    section: 'Vehicle Details'
  },
  vin: {
    id: 'txtVIN',
    name: 'txtVIN',
    errorText: 'Please enter Vehicle VIN Number',
    state: 'getState().workflowPOReceiptAccessories.vinnumber',
    data: false,
    valid: false,
    empty: true,
    section: 'Vehicle Details'
  },
  // invoiceDescription: {
  //   errorText: 'Please input Invoice Description',
  //   state: 'getState().uploadAccessory.documents.filedescription',
  //   data: 'Purchase Order Invoice',
  //   valid: true,
  //   empty: true,
  //   popup: false,
  // },
  invoiceDate: {
    errorText: 'Please input Invoice Date',
    state: "getState().uploadAccessory.documents?.length ? getState().uploadAccessory.documents[0]?.invoicedate : true",
    data: false,
    valid: false,
    empty: true,
    popup: false,
  },
  invoiceNumber: {
    errorText: 'Please input Invoice Number',
    state: "getState().uploadAccessory.documents?.length ? getState().uploadAccessory.documents[0]?.invoicenumber : true",
    data: false,
    valid: false,
    empty: true,
    popup: false,
  },
  poInvoice: {
    errorText: 'Please upload PO Invoice',
    state: "getState().uploadAccessory.documents?.length && getState().uploadAccessory.documents[0]?.filename ? true : false",
    data: false,
    valid: false,
    empty: true,
    popup: true,
  },
};