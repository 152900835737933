import * as types from './types'
import * as appTypes from '../App/types'

import * as componentActions from '../Components/actions'

import moment from 'moment'
import _ from 'lodash'


export const setComponentFieldInput = (field, input) => {
    //console.log('setComponentFieldInput',field, input)
    const format = (field, input) => {
        switch (field.component_field_display_type.name) {
            case 'datePicker':
                input = input.toString().trim()
                const now = moment(new Date())
                return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
            case 'timePicker':
                input = input.toString().trim()
                return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
            default:
                return input
        }
    }

    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const value = input ? format(field, input) : ''

        dispatch({
            type: types.SET_SUB_ACCOUNT_COMPONENT_FIELD_INPUT,
            payload: {
                component: component.name,
                field: field.name,
                input: value,
                validation: true // validate(component.schema, field, value)
            }
        })
    }
}
export const loadSubAccountData = () => {
    return (dispatch) => {
        dispatch(componentActions.hardReloadComponent('sub_account'))
    }
}
export const changeTabView = (tab) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_SUB_ACCOUNT_ACCOUNT_CURRENT_TAB,
            payload: tab
        })
    }
}

export const setLicenseeAccount = (payload) => {
    //console.log('setLicenseeAccount',payload)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SUB_ACCOUNT_ACCOUNT_DATA,
            payload: payload
        })
    }
}

export const saveSubAccountDone = (payload) => {
    //console.log('saveSubAccountDone',payload)
    return (dispatch, getState) => {
        dispatch(componentActions.hardReloadComponent('licensee'))
        dispatch({
            type: appTypes.SHOW_NOTIFICATION,
            payload: {
                title: 'System Notification',
                additionalText: 'License account save',
                overflowText: getState().login.fields.email.input,
                autoHide: 10000,
                timestamp: moment().format('h:mm A')
            }
        })
        dispatch(changeTabView(0))
    }
}

export const saveSubAccount = (component, user) => {
    //console.log('component',component)
    const getData = () => {
        return component.component_field.reduce((obj, x) => {
            if (component.data[x.name]) {
                if (x.key) {
                    if (component.data[x.name].input !== '') {
                        obj[x.name] = component.data[x.name].input
                    }
                }
                else {
                    obj[x.name] = component.data[x.name].input !== '' ? component.data[x.name].input : null
                }
            }

            return obj
        }, {})
    }

    return (dispatch, getState) => {
        let data = getData()
        
        //console.log('SAVE_SUB_ACCOUNT_COMPONENT_CUSTOMER_DATA data',data)
        dispatch({
            type: types.SAVE_SUB_ACCOUNT_COMPONENT_CUSTOMER_DATA,
            payload: {
                sub_account: {...data, client_id: 2 }, 
                user: user,
                type: 'crud_subaccount'
            }
        })
    }
}

export const getComponentFieldLookupData = (field, searchText, dataSource, params) => {
    return (dispatch, getState) => {
        const component = getState().components[field.component.name]
        const payload = {
            component: component,
            field: field,
            related: '',
            query: searchText.length > 0 ? '?' + field.search + '=' + searchText : '',
            text: searchText
        }

        dispatch({
            type: types.GET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA,
            payload: payload
        })
    }
}

export const setComponentFieldLookupData = (component, field, payload) => {
    const fields = field.display.split('|')
    const accessor = fields[0].split('.')[0]

    const data = payload.data.map(x => {
        return {
            value: x[field.name],
            text: fields.map(y => {
                return y.split(',').map(z => {
                    return _.get(x, z.replace(accessor + '.', ''))
                }).join(' ')
            }).join(', ')
        }
    })

    return (dispatch) => {
        dispatch({
            type: types.SET_SUB_ACCOUNT_COMPONENT_FIELD_LOOKUP_DATA,
            payload: {
                component: component.name,
                field: field.name,
                data: data
            }
        })
    }
}