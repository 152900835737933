import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'

import TextField from '@mui/material/TextField'

import Drawer from '../../../controls/drawer'
import Card from '../../../controls/card'
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

class WorkflowDisposalSettlement extends Component {
    componentDidMount() {
        this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
        if(this.data) {
            //console.log('componentWillMount',this.data.funder_settlement_amount)
            this.props.actions.setSetinputField({prop: 'funder_settlement_amount', value: this.data.funder_settlement_amount})
            this.props.actions.setSetinputField({prop: 'retailvalue', value: this.data.retailvalue})
            this.props.actions.setSetinputField({prop: 'acc_depreciation', value: this.data.acc_depreciation})
        }else
         this.props.actions.setSetinputField({prop: 'funder_settlement_amount', value: 0})
    }

    getViewForm() {     
        return <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={6}>{'Vehicle Registration:'}</Col><Col xl={6}>{this.data.licenseplateno}</Col>
                    </Row>
                    <Row>
                        <Col xl={6}>{'Vehicle Description:'}</Col><Col xl={6}>{this.data.makemodelyear}</Col>
                    </Row>
                    <Row>
                        <Col xl={12}><hr /></Col>
                    </Row>
                   {/* <Row>
                       <Col xl={6}>{'Maintenance Fund Balance:'}</Col><Col xl={6}>{this.data.interestrate}</Col>
                   </Row>
                   <Row>
                       <Col xl={6}>{'Capital Equalisation Fund:'}</Col><Col xl={6}>{this.data.interestrate}</Col>
                   </Row>
                    <Row>
                        <Col xl={12}></Col>
                    </Row> */}
           </Container>
       }

    getEditForm() {     
         return <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            <b>{'Vehicle Cost Excl Vat:*'}</b>
                            <TextField
                                variant="outlined" 
                                id={'txtRetailsPrice'}
                                hintText={'Enter Cost Excl Vat'}
                                fullWidth={true}
                                value={this.props.state.retailvalue}
                                onChange={(event, value) => {
                                    this.props.actions.setSetinputField({prop: 'retailvalue', value: event.target.value})
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <b>{'Vehicle Acc Depreciation:*'}</b>
                            <TextField
                                variant="outlined" 
                                id={'txtDiscountPrice'}
                                hintText={'Enter Acc Depreciation'}
                                fullWidth={true}
                                value={this.props.state.acc_depreciation}
                                onChange={(event, value) => {
                                    this.props.actions.setSetinputField({prop: 'acc_depreciation', value: event.target.value})
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <b>{'Settlement Excl Vat:*'}</b>
                            <TextField
                                variant="outlined" 
                                id={'txtrv'}
                                hintText={'Enter Settlement Excl Vat'}
                                fullWidth={true}
                                value={this.props.state.funder_settlement_amount}
                                onChange={(event, value) => {
                                    this.props.actions.setSetinputField({prop: 'funder_settlement_amount', value: event.target.value})
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <b>{'Settlement Excl Vat less 4%:'}</b>
                            <TextField
                                variant="outlined" 
                                id={'txtinterestrate'}
                                hintText={'Settlement Excl Vat less 4%'}
                                fullWidth={true}
                                disabled={true}
                                value={this.props.state.funder_settlement_amount ? this.props.state.funder_settlement_amount * 0.4 : 0} 
                            />
                        </Col>
                    </Row>
               <Row>
                   <Col xl={12}>
                       
                   <div className="divider" />
                   </Col>
                   <Col xl={12}>
                   </Col>
                </Row>
            </Container>
        }

        getView() {
            return <Card
                title={'Settlement Details'}
                subtitle={'Capture vehilce settlement details'}
                content={this.getEditForm()}
            />
        }
        
        getView() {
            return <div> 
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Vehicle Details
                    </div>
                    <p className="text-black-50">Vehicle details</p>
                </div>
                {this.getViewForm()}
                <div className="divider" />
                <br />
                <div className="text-center">
                    <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Settlement Details
                    </div>
                    <p className="text-black-50">Vehicle settlement details</p>
                </div>
                {this.getEditForm()}
            </div>
        }

        
        getLayout() {
            return <Drawer 
            onClose={(event, value) => {
                this.props.actions.toggleInfoSheet(null,'inforsheet_settlement')
            }}
            sx={{width: 800}}
            open={this.props.state.inforsheet_settlement}
            title={'Settlement Details'}
            subtitle={'Capture vehilce settlement details'}
            content={this.getView()}
            buttons={<div>
                  <Button variant='contained' className='global_button'
                    label="Update Settlement Details"
                    primary={true}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.updateSettlementDetails(this.data.vehicle_id)
                    }}
                    >
                    {<Icon iclass={'material-icons'} iname={'save'} />} &nbsp; {window.t.en('Update Settlement Details')}
                </Button>
                    &nbsp;
                <Button variant='contained' className='global_button'
                    primary={true}
                    style={{ width: '120px'}}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.actions.toggleInfoSheet(null,'inforsheet_settlement')
                    }}>
                        {<Icon iclass={'material-icons'} iname={'close'} />} &nbsp; {window.t.en('Close')}
                </Button>
            </div>}
            />
        }
        render() {
            this.data = this.props.state.yardlist[this.props.state.infosheet_inx]
            return this.getLayout()
        }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.workflowDisposal,
            user: {
                ...state.user
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDisposalSettlement)