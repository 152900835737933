import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.SET_COMPONENT_PROFILE_DASHBOARD_DATA:
            return {
                ...state,
                oqlis: action.payload
            }
        case types.SET_USER_DETAILS:
            return{
                ...state,
                [action.payload.prop] : action.payload.data
            }

        default:
            return state
    }
}

