import * as colours from '@mui/material/colors'
import * as config from '../../config'

export const content = {
    marginTop: 10,
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0
}

export const title = {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 10
}

export const title_sm = {
    fontSize: 16,
    marginBottom: 10
}

export const kpi = {
    background: '#FFFFFF',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.05)',
    borderRadius: 2,
    marginBottom: 25
}

export const tab = (highlight) => {
    return highlight
        ? {
            fontSize: 20,
            fontWeight: 'normal',
            color: '#4A4A4A',
            backgroundColor: 'white',
            letterSpacing: 0.71,
            textAlign: 'center',
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.05)',
            borderRadius: 2
        }
        : {
            fontSize: 20,
            fontWeight: 'normal',
            color: '#4A4A4A',
            backgroundColor: '#F1F1F1',
            letterSpacing: 0.71,
            textAlign: 'center'
        }
}

export const tabs = {
    fontSize: '12px important!'
}

export const view = {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0
}

export const dashboard = {
    display: 'flex'
}

export const dashboard_menu = {
    width: 300
}

export const dashboard_split = {
    width: 20
}

export const dashboard_content = {
    width: '100%'
}

export const scrollbar = {
    backgroundColor: colours.red[500],
    color: colours.red[500],
}

export const spacer = {
    height: '65px',
    width: '100%',
}

export const headerBackground = (image) => {
    return image && image !== null && image.length > 0
        ? {
            height: '130px',
            //background: 'url(' + config.system.cdn + '/vehicle_header_grayscale.jpg)',
            background: 'url(' + config.system.cdn + '/' + image + ')',
            backgroundSize: 1980,
            backgroundColor: 'white',
            opacity: 0.96,
            //borderBottom: '1px solid black',
            //display: 'flex',
            //position: 'fixed'
        }
        : {
            //borderBottom: '1px solid black',
            height: '130px'
        }
}

export const headerKpi = {
    width: '1150px',
    height: '130px'
}

export const headerContainerKpi = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    opacity: '0.94'
}

export const headerContainer = {
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: 'white',
    opacity: '0.94'
}

export const headerIcon = {
    width: 40,
    height: 40,
    fontSize: 40,
    margin: '0 auto'
}

export const headerTitle = {
    background: 'white',
    //opacity: 0.8,
    fontWeight: 'bold',
    fontSize: '34',
    padding: 5,
    marginRight: 20
}

export const headerDescription = {
    background: 'white',
    //opacity: 0.8,
    //color: 'white',
    width: '100%',
    fontSize: '34',
    padding: 5,
    borderRight: '1px solid black'
}

export const flex = {
    display: 'flex'
}