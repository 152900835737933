import * as types from './types'
import * as cognitoActions from '../Cognito/actions'

export const setInputValue = (prop, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_ACTIVE_DIRECTORY_USER_INPUT_VALUE,
            payload: {
                prop: prop,
                value: value
            }
        })
    }
}

export const setAmplifyState = (auth) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_AMPLIFY_AUTH_STATE,
            payload: auth
        })
    }
}

export const setAmplifySession = (session) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_AMPLIFY_AUTH_SESSION,
            payload: session
        })
    }
}

export const signup = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CREATE_SINGUP_USER_ENTRY,
        })
    }
}


export const login = (user) => {
    return (dispatch, getState) => {
        dispatch(cognitoActions.setCognitoUserAuthenticationDetails(user))
    }
}