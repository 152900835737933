import * as types from './types'

export const setNewFeature = (data) =>{
    return (dispatch,getState) =>{
        const item = getState().releaseNote.item
        if(item.length > 0){
            const features = [...getState().releaseNote.newFeatures]
            const append = item[item.length - 1] === '.' ? item : item + '.'
            features.push(append)
            dispatch({
                type : types.SET_NEW_FEATURE,
                payload : {
                    data : features,
                    prop : 'newFeatures'
                }
            })
        }
        
    }
}

export const setEnhancements = (data) =>{
    return (dispatch,getState) =>{
        const item = getState().releaseNote.item
        if(item.length > 0){
            const features = [...getState().releaseNote.releaseEnhancements]
            const append = item[item.length - 1] === '.' ? item : item + '.'
            features.push(append)
            dispatch({
                type : types.SET_ENHANCEMENTS,
                payload : {
                    data : features,
                    prop : 'releaseEnhancements'
                }
            })
        }
        
    }
}


export const setBugFix = (data) => {
    return(dispatch,getState) =>{
        const item = getState().releaseNote.item
        if(item.length > 0){
            const bugsFixed = [...getState().releaseNote.bugFixes]
            const append = item[item.length - 1] === '.' ? item : item + '.'
            bugsFixed.push(append)
            dispatch({
                type : types.SET_BUG_FIXES,
                payload : {
                    data : bugsFixed,
                    prop : 'bugFixes'
                }
            })
        }
        
    }
}

export const setReleaseDate = (date)=>{
    return(dispatch,getState) =>{
        dispatch({
            type : types.SET_RELEASE_DATE,
            payload : {
                data : date,
                prop : 'releaseDate'
            }
        })
    }
}
export const setReleaseVersion = (data) =>{
    return(dispatch,getState) =>{
        dispatch({
            type : types.SET_RELEASE_VERSION,
            payload : {
                data : data,
                prop : 'releaseVersion'
            }
        })
    }
}

export const setItem = (data) =>{
    return(dispatch,getState) =>{
        dispatch({
            type : types.SET_ITEM,
            payload : {
                data : data,
                prop : 'item'
            }
        })
    }
}
const joinItems = (data) =>{
    return data.join('+')
}
export const saveReleaseNote = () => {
    
    return (dispatch,getState) =>{
        const release = getState().releaseNote
        dispatch({
            type : types.SAVE_RELEASE_NOTE,
            payload :{
                type : types.SAVE_RELEASE_NOTE,
                releaseversion : release.releaseVersion,
                releasedescription : release.description,
                releasedate : release.releaseDate,
                releasebugfixes : joinItems(release.bugFixes),
                releasenewfeatures : joinItems(release.newFeatures),
                releaseEnhancements : joinItems(release.releaseEnhancements)
            }
        })
    }
}
export const setDescription = (data) =>{
    return(dispatch,getState) =>{
        dispatch({
            type : types.SET_DESCRIPTION,
            payload : {
                data : data,
                prop : 'description'
            }
        })
    }
}

export const setDialogStatus = () =>{
    return(dispatch, getState) =>{
        dispatch({
            type : types.SET_DIALOG_STATUS,
            payload : {
                data : !getState().releaseNote.dialogState,
                prop : 'dialogState'
            }
        })
    }
}
export const removeLastItem = (section) =>{
    return(dispatch, getState)=>{
        getState().releaseNote[section].pop()
        dispatch({
            type : types.REMOVE_LAST

        })
    }
}

export const removeAll = (section) =>{
    return (dispatch,getState) =>{
        dispatch({
            type : types.CLEAR_ALL,
            payload : {
                data: [],
                prop: section 
            }
        })
    }
}

export const updateReadNote = () =>{
    return (dispatch, getState) =>{
        dispatch({
            type : types.UPDATE_READ_NOTE,
            payload : {
                type : types.UPDATE_READ_NOTE,
            }
        })
    }
}

export const updateUserReadNote = () =>{
    return (dispatch,getState) => {
        dispatch({
            type : types.UPDATE_USER_READ_NOTE,
            payload : {
                type : types.UPDATE_USER_READ_NOTE,
                user_id : getState().user.user_id,
                readNote : true,
            }
        })
    }
}

export const getReleaseNote = () => {
    //console.log('realeasex get ')
    return (dispatch,getState) => {
        dispatch({
            type : types.GET_RELEASE_NOTE
        })
    }
}

export const setReleaseNote = (data) =>{
    return (dispatch, getState) => {
        
        //console.log('realeasex set ', data)
        if(data.data.length > 0){
            const last = data.data[data.data.length - 1]
            dispatch({
                type : types.SET_RELEASE_NOTE,
                payload : {
                    bugFixes : last.releasebugfixes.length > 0 ? last.releasebugfixes.split('+') : [],
                    description : last.releasedescription,
                    newFeatures : last.releasenewfeatures.length > 0 ? last.releasenewfeatures.split('+') : [],
                    releaseDate : last.releasedate,
                    releaseEnhancements : last.releaseEnhancements.length > 0 ?last.releaseEnhancements.split('+') : [],
                    releaseVersion : last.releaseversion,
                    releaseNotes : data.data
                }
            })
            
            dispatch(setDialogStatus())
        }
    }
}

export const clearReleaseNote = () =>{
    return(dispatch,getState) =>{
        dispatch({
            type : types.CLEAR_RELEASE_NOTE,
            payload :{
                bugFixes : [],
                description : '',
                newFeatures : [],
                releaseDate : '',
                releaseEnhancements:[],
                releaseVersion :''
            }
        })
    }
}
export const changeReleaseNoteTab = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_RELEASE_NOTE_TAB,
            payload: id
        })
    }
}

export const clearItem = () =>{
    return (dispatch,getState) =>{
        dispatch({
            type : types.CLEAR_ITEM,
            payload : {
                item : ''
            }
        })
    }
}

export const setNote = (data) =>{
    return (dispatch,getState) =>{
        dispatch({
            type : types.SET_NOTE,
            payload : {
                bugFixes : data.releasebugfixes.length > 0 ? data.releasebugfixes.split('+') : [],
                description : data.releasedescription,
                newFeatures : data.releasenewfeatures.length > 0 ? data.releasenewfeatures.split('+') : [],
                releaseDate : data.releasedate,
                releaseEnhancements : data.releaseEnhancements.length > 0 ?data.releaseEnhancements.split('+') : [],
                releaseVersion : data.releaseversion,
            }
        })
    }
}
export const handleChangePage = (data) =>{
    return(dispatch,getState) =>{
        dispatch({
            type : types.SET_PAGE,
            payload : {
                data : data
            }
        })
    }
}
export const handleChangeRowsPerPage = (data) =>{
    return(dispatch,getState) =>{
        dispatch({
            type : types.SET_ROWS_PER_PAGE,
            payload : {
                data : data
            }
        })
    }
}