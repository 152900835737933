import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'


import { Container, Row, Col } from 'react-grid-system'
//import { Card, CardHeader, CardContent } from '@mui/material'

import Card from '../../../controls/card'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import ViewQuotation from '../../../controls/view_quotation'


import Loading from '../../Loading/component'
import PdfViewer from '../../PDF/component'
import ImageUploader from '../../ImageUploader/component'
import Icon from '../../Icon/component'
import Upload from '../../Upload/component'

import * as styles from './styles'
import * as actions from './actions'

import AdhocComponentFieldInput from '../../Custom/AdhocComponentFieldInput/component'

class ApproveMMQuote extends Component {

    componentDidMount() {
        this.props.actions.resetComponent()
    }
   QuoteNotes() {
        return <Card 
        title={window.t.en('Quotation Notes')}
        subtitle={window.t.en('Please ensure that you view these notes before you proceed')}
        content={
                    <Row>
                        <Col xl={12}>
                            {this.props.workflow_queue_data.workflow_queue_id && <ViewQuotation
                                key={this.props.workflow_queue_data.workflow_queue_id}
                                id={this.props.workflow_queue_data.workflow_queue_id}
                            />}
                        </Col>
                    </Row>
        }
    />
   }
    
    CustomerDetails(customer) {
        return <div>
                <Card 
                    title={window.t.en('Contract Summary')}
                    subtitle={window.t.en('Contract details.')}
                    content={
                            <Container style={{ margin: 0 }} className="custom-container">
                                <Row>
                                    <Col xl={2} style={styles.fieldLabel}>{'Customer Name:'}</Col>
                                    <Col xl={2} style={styles.selectedFieldName}>{customer.customername}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'Business Unit:'}</Col>
                                    <Col xl={2} style={styles.selectedFieldName}>{customer.businessunit}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'Account Number:'}</Col>
                                    <Col xl={2} style={styles.selectedFieldName}>{customer.accountnumber}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'Cost Centre:'}</Col>
                                    <Col xl={2} style={styles.selectedFieldName}>{customer.costcentre}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'Account Number:'}</Col>
                                    <Col xl={2} style={styles.selectedFieldName}>{customer.accountnumber}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'VAT Number:'}</Col>
                                    <Col xl={2} style={styles.selectedFieldName}>{customer.vatnumber}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'Customer Active:'}</Col>
                                    <Col xl={2} style={styles.selectedFieldName}>{customer.customeractive ? 'Active' : 'Not Active'}</Col>
                                    <Col xl={2} style={styles.fieldLabel}>{'Customer Suspended:'}</Col>
                                    <Col xl={2} style={styles.selectedFieldName}>{customer.customersuspended ? 'Suspended' : 'Not Suspended'}</Col>
                                </Row>
                            <Row>
                                <Col xl={12} ><br/></Col>
                            </Row>
                            <Row>
                                <Col xl={12}>
                                    <AdhocComponentFieldInput
                                        title={window.t.en('')}
                                        description={''}
                                        icon={''}
                                        id={'customercontact'}
                                        parent={'customercontact'}
                                        parentFilters={[
                                            {
                                                field: 'customer_id',
                                                value: customer.customer_id
                                            }
                                        ]}
                                        child={'contact'}
                                        childFilters={[
                                            {
                                                field: 'contacttype',
                                                value: ''
                                            }
                                        ]}
                                        extractChildData={true}
                                        adhocFieldInput={[ //assumes Parent component fields if extractChild is set to false
                                            'fullname', 'email', 'mobile', 'contacttype'
                                        ]}
                                        selectMode={true}
                                        addMode={true}
                                    />
                                </Col>
                            </Row>
                            </Container>
                        }
                    />
            </div>
    }

    Vehicledetails(mmcode) {
        //console.log('MMCODE',mmcode)
        return <div>
        <Card 
                        title={window.t.en('Contract Summary')}
                        subtitle={window.t.en('Contract details.')}
            content={
                        <Container style={{ margin: 0 }} className="custom-container">
                            <Row>
                                <Col xl={2} style={styles.fieldLabel}>{'Vehicle MM Code:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{mmcode.mmcode}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Vehicle Make Model:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{mmcode.makemodelyear}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Vehicle Type:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{mmcode.bodytype}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Fuel Type:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{mmcode.fueltype}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Transmission:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{mmcode.manualauto}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'RT57 Vehicle:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{parseInt(mmcode.rt57price) > 0 ? 'Yes' : 'No'}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Introduction Year:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{mmcode.introdate}</Col>
                                <Col xl={2} style={styles.fieldLabel}>{'Discontinue Year:'}</Col>
                                <Col xl={2} style={styles.selectedFieldName}>{mmcode.discdate}</Col>
                            </Row>
                        </Container>
                    }/>
            </div>
    }

    AccessoriesList(data) {
        window.glyco.log('AccessoriesList',data)
        window.glyco.log(data)
        return  <Card 
        title={window.t.en('Items Summary')}
        subtitle={window.t.en('LIst of items for manage maintenance.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row style={styles.headerrow}>
                            <Col xl={8}>
                                {'Item Description'}
                            </Col>
                            <Col xl={2} style={styles.numericFields}>
                                {'Cost Price'}
                            </Col>
                            <Col xl={2} style={styles.numericFields}>
                                {'Selling Price'}
                            </Col>
                        </Row>
                        {data.map((x, i) => {
                            return <Row >
                                <Col xl={8}>
                                    {x.servicedescription}
                                </Col>
                                <Col xl={2} style={styles.numericFields}>
                                    {parseFloat(x.costprice.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                                <Col xl={2} style={styles.numericFields}>
                                    {parseFloat(x.sellingprice.toString()).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Col>
                            </Row>
                        })
                        }
                    </Container>
                }/>
    }

    RentalSummary(data) {
        return <div> 
            <Card 
        title={window.t.en('Rental Summary')}
        subtitle={window.t.en('Contract details.')}
            content={<Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={2} style={styles.fieldLabel}>{'Sub Total:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                            {parseFloat(data.monthly_excl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                            <Col xl={2} style={styles.fieldLabel}>{'VAT Amount:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                            {parseFloat(data.monthly_vat).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                            <Col xl={2} style={styles.fieldLabel}>{'Total Monthly:'}</Col>
                            <Col xl={2} style={styles.selectedFieldName}>
                            {parseFloat(data.monthly_incl).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Col>
                        </Row>
                        </Container>
                    }/>
            </div>
    }

    documentView(documents) {
        return <Card 
        title={window.t.en('Document List')}
        subtitle={window.t.en('Contract documents')}
        content={
            <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {
                        documents
                            ? <PdfViewer
                                key={'document_viewer_approval'}
                                name={'booking'}
                                workflow={'fml_customer_quote'}
                                task={'tsk_purchase_order_review_and_send'}
                                documents={documents}
                                edit={false}
                                default_document={'Quotation'}
                                workflow_queue_data={this.props.workflow_queue_data}
                            />
                            : <Loading message={'Retrieving Booking Document...'} />
                    }
                </Col>
            </Row>
        </Container>
                   }/>
    }

    renderView(data) {
        return <div>
            
                    <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                            <Col xl={12}>
                                {this.QuoteNotes()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.CustomerDetails(data.step1.customer)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.Vehicledetails(data.step1.mmcode)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.RentalSummary(data.step1)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.AccessoriesList(data.step1.items)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.documentView(data.document)}
                            </Col>
                        </Row>
                    </Container>
        </div>
    }

    render() {

        let workflow_queue_data = null
        try {
            workflow_queue_data = this.props.workflow_queue_data.context_data.data.data.data[0]
        } catch (error) {

        }
        //console.log('ApproveMMQuote',workflow_queue_data)
        return workflow_queue_data.step1
            ? this.renderView(workflow_queue_data)
            : 'Loading...'
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.approveMMQuote
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveMMQuote)