import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import sockets from 'socket.io-client'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import {
  Avatar,
  Button,
  Drawer,
  Tooltip,
  FormControl,
  Popover,
  Badge,
  List,
  ListItem,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {ReactComponent as UserAvatar} from '../../assets/img/svg/icons/user_avatar.svg'
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PeopleIcon from "@mui/icons-material/People";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LanguageIcon from '@mui/icons-material/Language';




import Select from '@mui/material/Select';
import * as signedInActions from "./actions";
import * as accountActions from "../Account/actions";
import * as profileActions from "../Profile/actions";
import * as redirectActions from "../Redirect/actions";
import * as cognitoActions from '../Cognito/actions'
import * as navigationMenuActions from "../NavigationMenu/actions";


import { signOut } from 'aws-amplify/auth'


import { styled } from "@mui/material/styles";
import * as styles from "./styles";
import { thisExpression } from '@babel/types';

import * as config from '../../config'


let ui = sockets(config.system.messenger.uri)


class AppProfile extends Component {

  constructor(props) {
    super(props)

    this.state = {
        anchorEl: false,
        anchorEl2: false,
        escalationEl: false,
        escalation: '',
        mainEvent: {}
    }
}
    componentDidMount() {
        this.props.actions.getDashboardList()
        this.props.actions.getDocumentPrevData()
        this.props.actions.geAlltDashboardList() 
    }

    handleClick = (event) => {
      this.setState({anchorEl: event.target});
    };

    handleClose = () => {
      this.setState({anchorEl: null});
    };

    handleClick2 = (event) => {
      this.setState({mainEvent: event});
      this.setState({anchorEl2: event.currentTarget});
    };
     handleClose2 = () => {
      this.setState({anchorEl2: null});
    };
    
    handleSingout = () => {
      ui.disconnect(true)
      signOut()
          .then(data => {
              //console.log('handleSingout', data)
              this.props.actions.signoutCognitoUser()
          })
          .catch(err => {
              //console.log('handleSingout Err', err)
          });

  }

  languagePopover() {
    const open = Boolean(this.state.anchorEl);
    return <Popover
        className="language-popover"
        open={open}
        anchorEl={this.state.anchorEl}
        onClose={this.handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        width={'300px'}
        sx={{width: '300px'}}>
        <List
            component="div"
            style={{ width: 300 }}
            className="list-group-flush text-left bg-transparent"
        >
            {
                this.props.state.accounts.locales.map(x =>
                    <ListItem onClick={(event) => { this.props.actions.setSelectedSetting({ prop: 'locale', value: x.code }) }}>
                        <div className="align-box-row">
                            <Badge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                variant="dot">
                                <LanguageIcon style={{ color: '#223750' }} />
                            </Badge>
                            <div className="pl-3">
                                <span className="font-weight-bold d-block">
                                    {x.locale}
                                </span>
                            </div>
                        </div>
                    </ListItem>
                )
            }
        </List>

    </Popover>
}

    profilePopOver() {
      const open2 = Boolean(this.state.anchorEl2);
      return <Popover
          open={open2}
          width={'300px'}
          anchorEl={this.state.anchorEl2}
          onClose={this.handleClose2}
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
          }}
          transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
          }}
          sx={{width: '300px'}}
          >
          <List
              component="div"
              className="list-group-flush text-left bg-transparent"
          >
              <ListItem onClick={(event, value) => {
                  event.preventDefault();
                  this.handleClose2()
                  this.props.actions.route("/profile");
              }}>
                  <div className="align-box-row">
                      <Badge
                          onClick={(event, value) => {
                              event.preventDefault();
                              this.handleClose2()
                              this.props.actions.setNavigationMenuHeaders({
                                  selectedItemMain: "My Profile",
                                  selectedItemMainDesc: "My Profile",
                                  selectedItemMainTitle: "My Profile",
                              });
                              this.props.actions.route("/profile");
                          }}
                          overlap="circle"
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                          }}
                          variant="dot">
                          {/* <PersonIcon style={{ color: '#253053' }} /> */}
                          <UserAvatar width={50} height={50}/>
                      </Badge>
                      <div className="pl-3">
                          <span className="font-weight-bold d-block">
                              My Profile
                          </span>
                          <small className="pb-0 text-black-50 d-block">
                              {this.props.state.user.firstname} {this.props.state.user.lastname}
                          </small>
                      </div>
                  </div>
              </ListItem>
              {
              <ListItem onClick={(event, value) => {
                  event.preventDefault();
                  this.handleClose2()
                  this.props.actions.route("/hierarchy");
                  this.props.actions.setNavigationMenuHeaders({
                      selectedItemMain: "Organisation Structure",
                      selectedItemMainDesc: "Organisation Structure",
                      selectedItemMainTitle: "Organisation Structure",
                  });
              }}>
                  <div className="align-box-row">
                      <Badge
                          onClick={(event, value) => {
                              event.preventDefault();
                              this.handleClose2()
                              this.props.actions.route("/hierarchy");
                          }}
                          overlap="circle"
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                          }}
                          variant="dot">
                          <AccountBalanceIcon style={{ color: '#253053' }} />
                      </Badge>
                      <div className="pl-3">
                          <span className="font-weight-bold d-block">
                              Customer Structure
                          </span>
                          <small className="pb-0 text-black-50 d-block">
                              {this.props.state.user.firstname} {this.props.state.user.lastname}
                          </small>
                      </div>
                  </div>
              </ListItem>
              }
              { 
            //    <ListItem onClick={(event, value) => {
            //       event.preventDefault();
            //       this.handleClose2()
            //       this.props.actions.setNavigationMenuHeaders({
            //           selectedItemMain: "Team Structure",
            //           selectedItemMainDesc: "Team Structure",
            //           selectedItemMainTitle: "Team Structure",
            //       });
            //       this.props.actions.route("/hierarchyteam");
            //   }}>
            //       <div className="align-box-row">
            //           <Badge
            //               onClick={(event, value) => {
            //                   event.preventDefault();
            //                   this.handleClose2()
            //                   this.props.actions.route("/hierarchyteam");
            //               }}
            //               overlap="circle"
            //               anchorOrigin={{
            //                   vertical: 'bottom',
            //                   horizontal: 'right'
            //               }}
            //               variant="dot">
            //               <PeopleIcon style={{ color: '#253053' }} />
            //           </Badge>
            //           <div className="pl-3">
            //               <span className="font-weight-bold d-block">
            //                   Team Structure
            //               </span>
            //               <small className="pb-0 text-black-50 d-block">
            //                   {this.props.state.user.firstname} {this.props.state.user.lastname}
            //               </small>
            //           </div>
            //       </div>
            //   </ListItem>
              }
              {
                  this.props.state.user.isadmin ?
                      <ListItem onClick={(event, value) => {
                          event.preventDefault();
                          this.handleClose2()
                          this.props.actions.setNavigationMenuHeaders({
                              selectedItemMain: "Team Management",
                              selectedItemMainDesc: "Team Management",
                              selectedItemMainTitle: "Team Management",
                          });
                          this.props.actions.route("/management");
                      }}>
                          <div className="align-box-row">
                              <Badge
                                  onClick={(event, value) => {
                                      event.preventDefault();
                                      this.handleClose2()
                                      this.props.actions.route("/management");
                                  }}
                                  //overlap="circle"
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                  }}
                              //variant="dot"
                              >
                                  <SupervisorAccountIcon style={{ color: '#253053' }} />
                              </Badge>
                              <div className="pl-3">
                                  <span className="font-weight-bold d-block">
                                      Team Management
                                  </span>
                                  <small className="pb-0 text-black-50 d-block">
                                      Manage System Users
                                  </small>
                              </div>
                          </div>
                      </ListItem>

                      : ''
              }
              {
                  this.props.state.user.isadmin && 1 != 1 ?
                      <ListItem onClick={(event, value) => {
                          event.preventDefault();
                          this.handleClose2()
                          this.props.actions.setNavigationMenuHeaders({
                              selectedItemMain: "License Account",
                              selectedItemMainDesc: "License Account",
                              selectedItemMainTitle: "License Account",
                          });
                          this.props.actions.route("/licenseeaccount");
                      }}>
                          <div className="align-box-row">
                              <Badge
                                  onClick={(event, value) => {
                                      event.preventDefault();
                                      this.handleClose2()
                                      this.props.actions.route("/licenseeaccount");
                                  }}
                                  //overlap="circle"
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                  }}
                              //variant="dot"
                              >
                                  <SyncIcon style={{ color: '#253053' }} />
                              </Badge>
                              <div className="pl-3">
                                  <span className="font-weight-bold d-block">
                                      Licensee Account
                                  </span>
                                  <small className="pb-0 text-black-50 d-block">
                                      Create and Manage Licensee Account
                                  </small>
                              </div>
                          </div>
                      </ListItem>
                  : ''
              }
              {
                  this.props.state.user.user_team.filter(x => x.team.name === 'licensee_account').length  && 1 != 1 ?
                      <ListItem onClick={(event, value) => {
                          event.preventDefault();
                          this.handleClose2()
                          this.props.actions.setNavigationMenuHeaders({
                              selectedItemMain: "Sub Account",
                              selectedItemMainDesc: "Sub Account",
                              selectedItemMainTitle: "Sub Account",
                          });
                          this.props.actions.route("/subaccount");
                      }}>
                          <div className="align-box-row">
                              <Badge
                                  onClick={(event, value) => {
                                      event.preventDefault();
                                      this.handleClose2()
                                      this.props.actions.route("/subaccount");
                                  }}
                                  //overlap="circle"
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                  }}
                              //variant="dot"
                              >
                                  <SyncIcon style={{ color: '#253053' }} />
                              </Badge>
                              <div className="pl-3">
                                  <span className="font-weight-bold d-block">
                                      Sub Account
                                  </span>
                                  <small className="pb-0 text-black-50 d-block">
                                      Create and Manage Sub Account
                                  </small>
                              </div>
                          </div>
                      </ListItem>
                  : ''
              }
              {
                  this.props.state.user.user_team.filter(x => x.team.name === 'sub_account').length  && 1 != 1 ?
                      <ListItem onClick={(event, value) => {
                          event.preventDefault();
                          this.handleClose2()
                          this.props.actions.setNavigationMenuHeaders({
                              selectedItemMain: "Reseller Account",
                              selectedItemMainDesc: "Reseller Account",
                              selectedItemMainTitle: "Reseller Account",
                          });
                          this.props.actions.route("/reseller");
                      }}>
                          <div className="align-box-row">
                              <Badge
                                  onClick={(event, value) => {
                                      event.preventDefault();
                                      this.handleClose2()
                                      this.props.actions.route("/reseller");
                                  }}
                                  //overlap="circle"
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                  }}
                              //variant="dot"
                              >
                                  <SyncIcon style={{ color: '#253053' }} />
                              </Badge>
                              <div className="pl-3">
                                  <span className="font-weight-bold d-block">
                                      Reseller Account
                                  </span>
                                  <small className="pb-0 text-black-50 d-block">
                                      Create and Manage Reseller Account
                                  </small>
                              </div>
                          </div>
                      </ListItem>
                  : ''
              }
              {
                  this.props.state.user.user_team.filter(x => x.team.name === 'reseller_account').length && 1 != 1 ?
                      <ListItem onClick={(event, value) => {
                          event.preventDefault();
                          this.handleClose2()
                          this.props.actions.setNavigationMenuHeaders({
                              selectedItemMain: "Client Account",
                              selectedItemMainDesc: "Client Account",
                              selectedItemMainTitle: "Client Account",
                          });
                          this.props.actions.route("/clientaccount");
                      }}>
                          <div className="align-box-row">
                              <Badge
                                  onClick={(event, value) => {
                                      event.preventDefault();
                                      this.handleClose2()
                                      this.props.actions.route("/clientaccount");
                                  }}
                                  //overlap="circle"
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                  }}
                              //variant="dot"
                              >
                                  <SyncIcon style={{ color: '#253053' }} />
                              </Badge>
                              <div className="pl-3">
                                  <span className="font-weight-bold d-block">
                                      Client Account
                                  </span>
                                  <small className="pb-0 text-black-50 d-block">
                                      Create and Manage Client Account
                                  </small>
                              </div>
                          </div>
                      </ListItem>
                  : ''
              }
              {
                  this.props.state.user.isadmin  && 1 != 1 ?
                      <ListItem onClick={(event, value) => {
                          event.preventDefault();
                          this.handleClose2()
                          this.props.actions.setNavigationMenuHeaders({
                              selectedItemMain: "Xero Account",
                              selectedItemMainDesc: "Xero Account",
                              selectedItemMainTitle: "Xero Account",
                          });
                          this.props.actions.route("/xero");
                      }}>
                          <div className="align-box-row">
                              <Badge
                                  onClick={(event, value) => {
                                      event.preventDefault();
                                      this.handleClose2()
                                      this.props.actions.route("/xero");
                                  }}
                                  //overlap="circle"
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                  }}
                              //variant="dot"
                              >
                                  <SyncIcon style={{ color: '#253053' }} />
                              </Badge>
                              <div className="pl-3">
                                  <span className="font-weight-bold d-block">
                                      Xero Accounting
                                  </span>
                                  <small className="pb-0 text-black-50 d-block">
                                      Manage Xero Data Integration
                                  </small>
                              </div>
                          </div>
                      </ListItem>
                  : ''
              }
              {
                  this.props.state.user.isadmin ?
                      <ListItem onClick={(event, value) => {
                          event.preventDefault();
                          this.handleClose2()
                          this.props.actions.setNavigationMenuHeaders({
                              selectedItemMain: "Release Note",
                              selectedItemMainDesc: "Release Note",
                              selectedItemMainTitle: "Release Note",
                          });
                          this.props.actions.route("/releasenote");
                      }}>
                          <div className="align-box-row">
                              <Badge
                                  onClick={(event, value) => {
                                      event.preventDefault();
                                      this.handleClose2()
                                      this.props.actions.route("/releasenote");
                                  }}
                                  //overlap="circle"
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right'
                                  }}
                              //variant="dot"import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
                              >
                                  <EventNoteOutlinedIcon style={{ color: '#253053' }} />
                              </Badge>
                              <div className="pl-3">
                                  <span className="font-weight-bold d-block">
                                      Release Notes
                                  </span>
                                  <small className="pb-0 text-black-50 d-block">
                                      Publish Release System Notes
                                  </small>
                              </div>
                          </div>
                      </ListItem>
                  : ''
              }
{
            //     <ListItem onClick={(event, value) => {
            //       event.preventDefault();
            //       this.handleClick(this.state.mainEvent);
            //       this.handleClose2()
            //     }}>
            //       <div className="align-box-row">
            //           <Badge
            //               onClick={(event, value) => {
            //                   event.preventDefault();
            //                   this.handleClick(this.state.mainEvent);
            //                   this.handleClose2()
            //                 }}
            //               //overlap="circle"
            //               anchorOrigin={{
            //                   vertical: 'bottom',
            //                   horizontal: 'right'
            //               }}
            //           //variant="dot"import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
            //           >
            //               <LanguageIcon style={{ color: '#253053' }} />
            //           </Badge>
            //           <div className="pl-3">
            //               <span className="font-weight-bold d-block">
            //                   Language
            //               </span>
            //               <small className="pb-0 text-black-50 d-block">
            //                   Manage Language
            //               </small>
            //           </div>
            //       </div>
            //   </ListItem>
              }
              {
                <ListItem onClick={(event, value) => {
                  event.preventDefault();
                  this.handleClose2()
                  this.props.actions.toggleUserProfileMenu(true, event.currentTarget)
              }}>
                  <div className="align-box-row">
                      <Badge
                          onClick={(event, value) => {
                              event.preventDefault();
                              this.handleClose2()
                              this.props.actions.toggleUserProfileMenu(true, event.currentTarget)
                          }}
                          //overlap="circle"
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                          }}
                      //variant="dot"import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
                      >
                          <SettingsIcon style={{ color: '#253053' }} />
                      </Badge>
                      <div className="pl-3">
                          <span className="font-weight-bold d-block">
                              Settings
                          </span>
                          <small className="pb-0 text-black-50 d-block">
                              Manage Settings
                          </small>
                      </div>
                  </div>
              </ListItem>
              }
              
              <ListItem className="d-block text-center p-3"
                  onClick={(event) => {
                      event.preventDefault();
                      this.props.actions.signoutCognitoUser(this.props.state.cognitoUser)
                  }}>
                  <Button
                      variant="contained"
                      size="small"
                      className="btn-primary">
                      <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={faSignOutAlt} />
                      </span>
                      {/* <span onClick={(event) => {
              event.preventDefault();
                              this.props.actions.signoutCognitoUser(this.props.state.cognitoUser)
                          }} className="btn-wrapper--label">Logout
                  </span> */}

                      <span
                          onClick={this.handleSingout}
                          // (event) => {
                          //     event.preventDefault();

                          //     //this.props.actions.signoutCognitoUser(this.props.state.cognitoUser)
                          // }}
                          className="btn-wrapper--label">Sign out
                      </span>
                  </Button>
              </ListItem>
          </List>
      </Popover>
  }

 render () {
    return <>
        <div className="profilePic">
        <Button
        variant="text"
        onClick={(event, value) => {
            event.preventDefault();
            this.handleClick2(event);
            // this.props.actions.toggleUserProfileMenu(true, event.currentTarget)
        }}
        className="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
        disableRipple>
        <div className="d-block p-0 avatar-icon-wrapper">
            {/* <StyledBadge
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-success badge-circle border-0' }}
            variant="dot">
            <div className="avatar-icon rounded">
            <img src={'https://d1z1yg99cg50xo.cloudfront.net/enkw.jpg'} alt="" />
            </div>
            </StyledBadge> */}
            {/* <Avatar alt="" style={{width: '50px',height: '50px'}} src="https://d1z1yg99cg50xo.cloudfront.net/enkw.jpg" /> */}
            <UserAvatar width={50} height={50} />
            {/* <PersonIcon style={{ color: '#253053' }} /> */}

        </div>

        <div className="d-none d-xl-block pl-2" >
            <div style={{color:'black'}} className="text-black-50">{this.props.state.user.firstname} {this.props.state.user.lastname}</div>
            <small className="text-black-50">{this.props.state.accounts.accountName}</small>
        </div>
        <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon icon={faAngleDown} className="opacity-5" />
        </span>
        </Button>

        </div>
        
        {this.profilePopOver()}
        {this.languagePopover()}
        

        <Drawer 
        anchor={'right'}
        open={this.props.state.profileMenus}
        onClose={(event, value) => {
            event.preventDefault();
            this.props.actions.toggleUserProfileMenu(false, null)
        }}
        >
        <div className="app-drawer-content-off" style={{width: '500px'}}>
          <Tooltip arrow title="Close drawer" placement="left">
            <Button
              size="small"
                        onClick={(event, value) => {
                            event.preventDefault();
                            this.props.actions.toggleUserProfileMenu(false, null)
                        }}
              className="close-drawer-btn bg-white p-0 d-40"
              id="CloseDrawerTooltip">
              <div>
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </div>
            </Button>
          </Tooltip>
          <div className="vh-100 shadow-overflow">
              <div className="divider" />
              <div className="p-4">
                <div className="text-center">
                  <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Default Organisation Settings
                  </div>
                  <p className="text-black-50">Select your organisation default settings </p>
                </div>
                <div className="rounded p-3 bg-secondary">
                  <div className="task-wrapper">
                    <div className="task-item">
                      <div className="align-box-row">
                        <div>
                          <b>Choose Your Default Dashboard</b>
                          <p className="text-black-50 mt-1 mb-0">
                            <FormControl variant="outlined" style={styles.MuiFormControl}>
                                <Select
                                    native
                                    name="oqlis"
                                    inputProps={{
                                        name: 'dashboard_name',
                                        id: 'dashboard_key',
                                    }}
                                    style={{width: '400px'}}
                                    onChange={(event, value) => {
                                        //console.log(event.target.value)
                                       this.props.actions.updateUserDefaultDashboard(event.target.value)
                                    }}
                                >
                                    {
                                        this.props.state.user.defaultdashboardkey && this.props.state.oqlis
                                        ? <option value={''} style={{color: 'grey'}}>
                                            {
                                                this.props.state.oqlis.filter(x => x.oq_dashboard_key == this.props.state.user.defaultdashboardkey).length
                                                ? this.props.state.oqlis.filter(x => x.oq_dashboard_key == this.props.state.user.defaultdashboardkey)[0].report_name
                                                : 'Please select...'
                                            }
                                        </option>
                                        : <option value={''} style={{color: 'grey'}}>Please select...</option>
                                    }
                                    
                                    {
                                       this.props.state.oqlis? this.props.state.oqlis.map(x => { return <option value={x.oq_dashboard_key}>{x.report_name}</option> }):<option value={''} style={{color: 'grey'}}>Please select...</option>
                                    }
                                </Select>
                            </FormControl>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="task-item">
                      <div className="align-box-row">
                        <div>
                          <b>Default Startup Page</b>
                          <p className="text-black-50 mt-1 mb-0">
                                <FormControl variant="outlined">
                                    <Select
                                    native
                                    style={{width: '400px'}}
                                    label="Default Screen"
                                    onChange={(event, value) => {
                                        //console.log(event.target.value)
                                        this.props.actions.updateUserDefaultLandingPage(event.target.value)
                                    }}
                                    >
                                    <option value={''} style={{color: 'grey'}}>
                                        {
                                            this.props.state.user.defaultlandingpage === 'bi' 
                                            ? 'BI Dashboard'
                                            : this.props.state.user.defaultlandingpage === 'tasks'
                                            ? 'Tasks Dashboard' : 'Please select...'
                                        }
                                    </option>
                                    <option value={'tasks'}>Tasks Dashboard</option>
                                    <option value={'bi'}>BI Dashboard</option>
                                    </Select>
                                    
                                </FormControl>
                          </p>
                        </div>
                      </div>
                    </div> 
                    <div className="task-item">
                      <div className="align-box-row">
                        <div>
                          <b>Default Language</b>
                          <p className="text-black-50 mt-1 mb-0">
                            <FormControl variant="outlined" style={styles.MuiFormControl}>
                                <Select
                                    native
                                    label="Language"
                                    style={{width: '400px'}}
                                    value={this.props.state.accounts.locale}
                                    inputProps={{
                                        name: 'Language',
                                        id: 'code',
                                    }}
                                    onChange={(event, value) => { this.props.actions.setSelectedSetting({ prop: 'locale', value: event.target.value });
                                     this.props.actions.updateUserDefaultLanguage(event.target.value) }}
                                >
                                <option value={''} style={{color: 'grey'}}>Please select...</option>
                                    {
                                        this.props.state.accounts.locales.map(x => { return <option value={x.code}>{x.locale}</option> })
                                    }

                                </Select>
                            </FormControl>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="task-item">
                      <div className="align-box-row">
                        <div>
                          <b>Default Document Preview</b>
                          <p className="text-black-50 mt-1 mb-0">
                            <FormControl variant="outlined" style={styles.MuiFormControl}>
                                <Select
                                    native
                                    label="Document Preview"
                                    style={{width: '400px'}}
                                    className="option-letter"
                                    // value={this.props.state.user.defaultdocumentview}
                                    inputProps={{
                                        name: 'Document Preview',
                                        id: 'code',
                                    }}
                                    onChange={(event, value) => this.props.actions.setSelectedDocumentPreview(event.target.value )}
                                >
                                <option  value={this.props.state.user.defaultdocumentview} style={{color: 'grey'}}>{this.props.state.user.defaultdocumentview?this.props.state.user.defaultdocumentview:'Please select...'}</option>
                                    {
                                        this.props.state.document_preview?this.props.state.document_preview.map(x => { return <option key={x.document_user_id} value={x.name}>{x.title}</option> }):''
                                    }

                                </Select>
                            </FormControl>
                          </p>
                        </div>
                      </div>
                    </div>
                    </div>
                </div>
             


             
                <div className="text-center">
                  <div className="font-weight-bold font-size-lg mb-0 text-black">
                    Card Management
                  </div>
                  <p className="text-black-50">Card management default settings</p>
                </div>
                <div className="rounded p-3 bg-secondary">
                  <div className="task-wrapper">
                    <div className="task-item">
                      <div className="align-box-row">
                        <div>
                          <b> Enable Automated FMS Decision Engine</b>
                          <p className="text-black-50 mt-1 mb-0">
                            <FormControl variant="outlined" style={styles.MuiFormControl}>
                                <Select
                                    native
                                    name="landing"
                                    placeholder="none"
                                    style={{width: '400px'}}
                                        
                                    >
                                    <option value={''} style={{color: 'grey'}}>Please select...</option>
                                        <option value={'Yes'}>Yes</option>
                                        <option value={'No'}>No</option>

                                    </Select>
                            </FormControl>
                          </p>
                        </div>
                      </div>
                    </div>
                    </div>
                </div>
             
              </div>
              <div className="divider" />
          </div>
        </div>
        </Drawer>
        </>
 }
}


const mapStateToProps = (state) => {
    return {
        state: {
            ...state.app,
            //...state.responsiveDrawer,
            ...state.connector,
            ...state.signedIn,
            accounts: {
                ...state.accounts,
            },
            user: {
                ...state.user,
            },
            nav: {
                ...state.navigationMenu
            },
            ...state.cognito ,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            {
                ...signedInActions,
                ...accountActions,
                ...profileActions,
                ...redirectActions,
                ...cognitoActions,
                ...navigationMenuActions
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppProfile);