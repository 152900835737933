export const SELECT_PURCHASE_ORDER_TYPE = 'SELECT_PURCHASE_ORDER_TYPE'
export const SET_USER_AUTOCOMPLETE_MENU_ITEMS = 'SET_USER_AUTOCOMPLETE_MENU_ITEMS'
export const GET_USER_AUTOCOMPLETE_MENU_ITEMS = 'GET_USER_AUTOCOMPLETE_MENU_ITEMS'
export const SET_USER_AUTOCOMPLETE_SELECTED = 'SET_USER_AUTOCOMPLETE_SELECTED'
export const SET_SUPPLIER_AUTOCOMPLETE_AMOUNT = 'SET_SUPPLIER_AUTOCOMPLETE_AMOUNT'
export const SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS = 'SET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS'
export const GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS = 'GET_SUPPLIER_AUTOCOMPLETE_MENU_ITEMS'
export const SET_SUPPLIER_AUTOCOMPLETE_SELECTED = 'SET_SUPPLIER_AUTOCOMPLETE_SELECTED'
export const ADD_SUPPLIER_AUTOCOMPLETE = 'ADD_SUPPLIER_AUTOCOMPLETE'
export const REMOVE_SUPPLIER_AUTOCOMPLETE = 'REMOVE_SUPPLIER_AUTOCOMPLETE'

export const GET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS = 'GET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS'
export const SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS = 'SET_VEHICE_LIST_AUTOCOMPLETE_MENU_ITEMS'
export const SET_VEHICLE_AUTOCOMPLETE_SELECTED = 'SET_VEHICLE_AUTOCOMPLETE_SELECTED'
export const GET_SELECTED_VEHICLE_DETAILS = 'GET_SELECTED_VEHICLE_DETAILS'
export const SET_SELECTED_VEHICLE_DETAILS = 'SET_SELECTED_VEHICLE_DETAILS'
export const GET_SELECTED_SUPPLIER_DETAILS = 'GET_SELECTED_SUPPLIER_DETAILS'
export const SET_SELECTED_SUPPLIER_DETAILS = 'SET_SELECTED_SUPPLIER_DETAILS'
export const SET_SUSPENDED_SUPPLIER_DETAILS = 'SET_SUSPENDED_SUPPLIER_DETAILS'
export const GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST = 'GET_SELECTED_VEHICLE_SUPPLIERS_IN_RANGE_LIST'
export const SET_VEHICLE_SERVICE_INFORMATION = 'SET_VEHICLE_SERVICE_INFORMATION'
export const SET_GEO_MAP_SUPPLIERS = 'SET_GEO_MAP_SUPPLIERS'
export const RESET_COMPONENT = 'RESET_COMPONENT'
export const GET_SUPPLIER_AGE = 'GET_SUPPLIER_AGE'
export const SET_SUPPLIER_AGE = 'SET_SUPPLIER_AGE'
export const GET_PRE_AUTHORISATION_NUMBER = 'GET_PRE_AUTHORISATION_NUMBER'
export const SET_PRE_AUTHORISATION_NUMBER = 'SET_PRE_AUTHORISATION_NUMBER'
export const GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'GEN_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const SET_COMPONENT_VALIDATION_PROPS_FIELDS = 'SET_COMPONENT_VALIDATION_PROPS_FIELDS'
export const SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT = 'SET_MAINTENANCE_HOSTORY_PDF_DOCUMENT'
export const SET_VEHICLE_CURRENT_BOOKING_INFO = 'SET_VEHICLE_CURRENT_BOOKING_INFO'
export const SET_COMPONENT_RECENT_MAINTENANCE_AUTHS = 'SET_COMPONENT_RECENT_MAINTENANCE_AUTHS'
export const SET_SUPPLIER_MONTHLY_SPEND_USAGE = 'SET_SUPPLIER_MONTHLY_SPEND_USAGE'
export const GET_SUPPLIER_MONTHLY_SPEND_USAGE = 'GET_SUPPLIER_MONTHLY_SPEND_USAGE'
export const SET_SUPPLIER_MONTHLY_OVER_THE_LIMIT = 'SET_SUPPLIER_MONTHLY_OVER_THE_LIMIT'
export const RESET_PO_COMPOMENT_STATE = 'RESET_PO_COMPOMENT_STATE'

export const SET_SUPPLIE_SUSPENDED_STATUS = 'SET_SUPPLIE_SUSPENDED_STATUS'
export const TOGGLE_SUPPLIE_SUSPENDED_WIN = 'TOGGLE_SUPPLIE_SUSPENDED_WIN'
export const TOGGLE_UPCOMING_SUPPLIER_SUSPENDED_STATUS = 'TOGGLE_UPCOMING_SUPPLIER_SUSPENDED_STATUS'

export const GET_PO_CUSTOMER_CONTACTS_AUTOCOMPLETE_DATA = 'GET_PO_CUSTOMER_CONTACTS_AUTOCOMPLETE_DATA'
export const SET_PO_CUSTOMER_CONTACTS_AUTOCOMPLETE_DATA = 'SET_PO_CUSTOMER_CONTACTS_AUTOCOMPLETE_DATA'
export const GET_PO_SUPPLIER_CONTACTS_AUTOCOMPLETE_DATA = 'GET_PO_SUPPLIER_CONTACTS_AUTOCOMPLETE_DATA'
export const SET_PO_SUPPLIER_CONTACTS_AUTOCOMPLETE_DATA = 'SET_PO_SUPPLIER_CONTACTS_AUTOCOMPLETE_DATA'

export const SET_SELECTED_PO_SUPPLIER_CONTACTS_DATA = 'SET_SELECTED_PO_SUPPLIER_CONTACTS_DATA'
export const SET_SELECTED_PO_CUSTOMER_CONTACTS_DATA = 'SET_SELECTED_PO_CUSTOMER_CONTACTS_DATA'

export const TOGGLE_WIN_SELECT_MAINTENANCE_TYPE = 'TOGGLE_WIN_SELECT_MAINTENANCE_TYPE'
export const TOGGLE_WIN_DUPLICATE_MAINTENANCE = 'TOGGLE_WIN_DUPLICATE_MAINTENANCE'
export const GET_MAINTENANCE_TRANSACTION_BY_REGISTRATION = 'GET_MAINTENANCE_TRANSACTION_BY_REGISTRATION'
export const SET_MAINTENANCE_DUPLICATED_TRANSACTION = 'SET_MAINTENANCE_DUPLICATED_TRANSACTION'
export const SELECT_MAINTENANCE_CLASS_TYPE = 'SELECT_MAINTENANCE_CLASS_TYPE'
export const GET_MAINTENANCE_CLASS_ITEMS = 'GET_MAINTENANCE_CLASS_ITEMS'
export const SET_MAINTENANCE_CLASS_ITEMS = 'SET_MAINTENANCE_CLASS_ITEMS'
export const SELECT_MAINTENANCE_VEHICLE = 'SELECT_MAINTENANCE_VEHICLE'
export const GET_MAINTENANCE_TRANSACTION_BY_CLASS = 'GET_MAINTENANCE_TRANSACTION_BY_CLASS'
export const GET_COMPONENT_PO_SUPPLIER_TYPES = 'GET_COMPONENT_PO_SUPPLIER_TYPES'
export const SET_COMPONENT_PO_SUPPLIER_TYPES = 'SET_COMPONENT_PO_SUPPLIER_TYPES'
export const SET_PRE_TICKET_NUMBER = 'SET_PRE_TICKET_NUMBER'