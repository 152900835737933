import * as actions from './actions'
import * as progressBarActions from '../../ProgressBar/actions'
import * as requestActions from '../../Request/actions'
import * as routerActions from '../../Redirect/actions'
import * as types from './types'
import * as requestTypes from '../../Request/types'
import * as config from '../../../config'

        
export const getposupplierlist = (action$, store, { ajax$, of$ }) =>
action$.ofType(
    types.GET_POSUPPLIERLIST,
)
    .mergeMap(({ payload }) =>
        ajax$({
            //url: config.system.api + '/components/customertransaction/search?transactiontype=RC&reference='+ payload.search_text,
            url: config.system.api + '/components/supplier/searchor?suppliername=' + payload.search_text + '&accountnumber=' + payload.search_text,
            method: 'GET',
            headers: {
                'auth': store.getState().cognito.authenticateUser.token,
                'salt': store.getState().cognito.authenticateUser.jwt,
                'datetime': store.getState().cognito.authenticateUser.datetime,
                'take': 50,
                'order':'suppliername'
            }
        })
            .mergeMap(result => [
              //  actions.setposupplierlist(result.response),
                requestActions.requestSucceeded(config.system.api + '')
            ])
            .startWith(progressBarActions.progress(true))
            .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
            .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    .catch((error) =>
        of$(routerActions.route('/support', false, error))
    )

    export const toggleItem = (action$, store, { ajax$, of$ }) =>
    action$
        .ofType(types.TOGGLE_ITEM)
        .debounceTime(500)
        .mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplierpaymentapprovalgm/other',
                method: 'POST',
                headers: {
                    'auth': store.getState().cognito.authenticateUser.token,
                    'salt': store.getState().cognito.authenticateUser.jwt,
                    'datetime': store.getState().cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .mergeMap(result => [
                   // actions.refreshbysupplier(payload),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                .startWith(progressBarActions.progress(true))
                .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        .catch((error) =>
            of$(routerActions.route('/support', false, error))
        ) 
        
        export const approveItem = (action$, store, { ajax$, of$ }) =>
        action$
            .ofType(types.APPROVE_ITEM_GM)
            .debounceTime(500)
            .mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/supplierpaymentapprovalgm/other',
                    method: 'POST',
                    headers: {
                        'auth': store.getState().cognito.authenticateUser.token,
                        'salt': store.getState().cognito.authenticateUser.jwt,
                        'datetime': store.getState().cognito.authenticateUser.datetime,
                        'Content-Type': 'application/json'
                    },
                    body: payload
                })
                    .mergeMap(result => [
                        actions.setPaymentList(result.response),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    .startWith(progressBarActions.progress(true))
                    .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            .catch((error) =>
                of$(routerActions.route('/support', false, error))
            ) 
            
            
         
      export const processPayment = (action$, store, { ajax$, of$ }) =>
        action$
            .ofType(types.PROCESS_PAYMENT)
            .debounceTime(500)
            .mergeMap(({ payload }) =>
                ajax$({
                    url: config.system.api + '/components/supplierpaymentapprovalgm/other',
                    method: 'POST',
                    headers: {
                        'auth': store.getState().cognito.authenticateUser.token,
                        'salt': store.getState().cognito.authenticateUser.jwt,
                        'datetime': store.getState().cognito.authenticateUser.datetime,
                        'Content-Type': 'application/json'
                    },
                    body: payload
                })
                    .mergeMap(result => [
                       // actions.processedSupplier(payload),
                        requestActions.requestSucceeded(config.system.api + '')
                    ])
                    .startWith(progressBarActions.progress(true))
                    .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                    .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
            )
            .catch((error) =>
                of$(routerActions.route('/support', false, error))
            ) 
            
         
                  
        
export const getBusinessList = (action$, store, { ajax$, of$ }) =>
action$.ofType(
    types.GET_BUSINESSLIST,
)
    .mergeMap(({ payload }) =>
        ajax$({
            //url: config.system.api + '/components/customertransaction/search?transactiontype=RC&reference='+ payload.search_text,
            url: config.system.api + '/components/client/searchor?title=' + payload.search_text,
            method: 'GET',
            headers: {
                'auth': store.getState().cognito.authenticateUser.token,
                'salt': store.getState().cognito.authenticateUser.jwt,
                'datetime': store.getState().cognito.authenticateUser.datetime,
                'take': 50,
                'order':'title'
            }
        })
            .mergeMap(result => [
              //  actions.setBusinessList(result.response),
                requestActions.requestSucceeded(config.system.api + '')
            ])
            .startWith(progressBarActions.progress(true))
            .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
            .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    .catch((error) =>
        of$(routerActions.route('/support', false, error))
    )

    export const updateItem = (action$, store, { ajax$, of$ }) =>
    action$
        .ofType(types.UPDATE_ITEM_GM)
        .debounceTime(500)
        .mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplierpaymentapprovalgm/other',
                method: 'POST',
                headers: {
                    'auth': store.getState().cognito.authenticateUser.token,
                    'salt': store.getState().cognito.authenticateUser.jwt,
                    'datetime': store.getState().cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .mergeMap(result => [
                    actions.setPaymentList(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                .startWith(progressBarActions.progress(true))
                .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        .catch((error) =>
            of$(routerActions.route('/support', false, error))
        ) 

        


    export const getExceptionList = (action$, store, { ajax$, of$ }) =>
    action$
        .ofType(types.GET_EXCEPTION_DATA_LIST_GM)
        .debounceTime(500)
        .mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/supplierpaymentapprovalgm/other',
                method: 'POST',
                headers: {
                    'auth': store.getState().cognito.authenticateUser.token,
                    'salt': store.getState().cognito.authenticateUser.jwt,
                    'datetime': store.getState().cognito.authenticateUser.datetime,
                    'Content-Type': 'application/json'
                },
                body: payload
            })
                .mergeMap(result => [
                    actions.setExceptionList(result.response),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                .startWith(progressBarActions.progress(true))
                .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        .catch((error) =>
            of$(routerActions.route('/support', false, error))
        )
export const getSelectedVehicleDetails = (action$, store, { ajax$, of$ }) =>
    action$
        .ofType(types.GET_SELECTED_VEHICLE_DETAILS_GM)
        .debounceTime(250)
        .mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/vehicle/search?vehicle_id=' + payload.query,
                method: 'GET',
                headers: {
                    'auth': store.getState().cognito.authenticateUser.token,
                    'salt': store.getState().cognito.authenticateUser.jwt,
                    'datetime': store.getState().cognito.authenticateUser.datetime,
                    'related': 'maintenancetransaction.maintenancetransactionitem',

                },
            })
                .mergeMap(result => [
                    //actions.setSelectedVehicleDetails(result.response, payload.query),
                    requestActions.requestSucceeded(config.system.api + '/components/vehicle/search')
                ])
                .catch((error) => of$(requestActions.requestFailed(config.system.api + '/components/vehicle/search', error)))
                .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        .catch((error) =>
            of$(routerActions.route('/support', false, error))
        )

        
export const getCommitDataGM = (action$, store, { ajax$, of$ }) =>
action$.ofType(
    types.COMMIT_PAYMENT_GM,
)
    .mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/supplierpaymentapprovalgm/other',
            method: 'POST',
            headers: {
                'auth': store.getState().cognito.authenticateUser.token,
                'salt': store.getState().cognito.authenticateUser.jwt,
                'datetime': store.getState().cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .mergeMap(result => [
                //actions.setPaymentList(result.response),
                requestActions.requestSucceeded(config.system.api + '')
            ])
            .startWith(progressBarActions.progress(true))
            .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
            .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    .catch((error) =>
        of$(routerActions.route('/support', false, error))
    )

    
export const getSupplierList = (action$, store, { ajax$, of$ }) =>
action$.ofType(
    types.GET_GM_SUPPLIER_PAYMENT_LIST,
)
    .mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/supplier/search?suppliername=' + payload.search_text,
            method: 'GET',
            headers: {
                'auth': store.getState().cognito.authenticateUser.token,
                'salt': store.getState().cognito.authenticateUser.jwt,
                'datetime': store.getState().cognito.authenticateUser.datetime,
                'order': 'suppliername',
            }
        })
            .mergeMap(result => [
                actions.setSupplierList(result.response),
                requestActions.requestSucceeded(config.system.api + '')
            ])
            .startWith(progressBarActions.progress(true))
            .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
            .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    .catch((error) =>
        of$(routerActions.route('/support', false, error))
    )

    export const getCommitDataRefresh = (action$, store, { ajax$, of$ }) =>
action$.ofType(
    types.COMMIT_PAYMENT_REFRESH,
)
    .mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/supplierpaymentapprovalgm/other',
            method: 'POST',
            headers: {
                'auth': store.getState().cognito.authenticateUser.token,
                'salt': store.getState().cognito.authenticateUser.jwt,
                'datetime': store.getState().cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .mergeMap(result => [
                actions.setPaymentList(result.response),
                requestActions.requestSucceeded(config.system.api + '')
            ])
            .startWith(progressBarActions.progress(true))
            .catch((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
            .takeUntil(action$.ofType(requestTypes.CANCEL_REQUESTS))
    )
    .catch((error) =>
        of$(routerActions.route('/support', false, error))
    )
    

    export const updatePaymentData = (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
        types.UPDATE_PAYMENT_DATA_VALUES,
    )
    , mergeMap(({ payload }) =>
        ajax$({
            url: config.system.api + '/components/supplierpaymentapprovalgm/other',
            method: 'POST',
            headers: {
                'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                'salt': store.value.cognito.authenticateUser.jwt,
                'datetime': store.value.cognito.authenticateUser.datetime,
                'Content-Type': 'application/json'
            },
            body: payload
        })
            .pipe(
                mergeMap((result) => [
                    //actions.setPaymentList(result.response, payload.type),
                    requestActions.requestSucceeded(config.system.api + '')
                ])
                , startWith(progressBarActions.progress(true))
                , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
                //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
            ))
        , catchError((error) =>
            of$(routerActions.route('/support', false, error))
        ))
        
export const getPaymentList =  (action$, store, { ajax$, of$, startWith,  map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(ofType(
    types.GET_FRESH_DATA,
)
, mergeMap(({ payload }) =>
    ajax$({
        url: config.system.api + '/components/supplierpaymentapprovalgm/other',
        method: 'POST',
        headers: {
            'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
            'salt': store.value.cognito.authenticateUser.jwt,
            'datetime': store.value.cognito.authenticateUser.datetime,
            'Content-Type': 'application/json'
        },
        body: payload
    })
        .pipe(
            mergeMap((result) => [
                actions.setPaymentList(result.response),
                requestActions.requestSucceeded(config.system.api + '')
            ])
            , startWith(progressBarActions.progress(true))
            , catchError((error) => of$(requestActions.requestFailed(config.system.api + '', error)))
            //, take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        ))
    , catchError((error) =>
        of$(routerActions.route('/support', false, error))
    ))