import * as types from './types'
import moment from 'moment'

export const addBlankUpload = (i, po) => {
    return (dispatch, getState) => {
        let documents = getState().uploadAccessory.documents
        if (documents) {
            documents.push({
                fileinx: moment(new Date()).format('YYYYMMDDhhmmssSSS'),
                filename: '',
                preview: '',
                ponumber: po,
                description: '',
                invoicenumber: '',
                invoicedate: '',
                filedescription: '',
                inx: i ? i : 1
            })
        } else {
            documents = [{
                fileinx: moment(new Date()).format('YYYYMMDDhhmmssSSS'),
                filename: '',
                preview: '',
                ponumber: po,
                description: '',
                invoicenumber: '',
                invoicedate: '',
                filedescription: '',
                inx: i ? i : 1
            }]
        }
        dispatch({
            type: types.UPLOAD_COMPONENT_ADD_BLANK_UPLOAD,
            payload: documents
        })
    }
}

export const cleareDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.CLEAR_COMPONENT_PRE_LOAD_UPLOADED_DOCUMENTS,
            payload: null
        })
    }
}


export const preLoadDocuments = (documents) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPLOAD_COMPONENT_PRE_LOAD_UPLOADED_DOCUMENTS,
            payload: documents
        })
    }
}

export const resetDocuments = (inx) => {
    return (dispatch, getState) => {
        let documents = getState().uploadAccessory.documents
        documents = documents.filter(x => x.fileinx !== inx)

        dispatch({
            type: types.UPLOAD_COMPONENT_ADD_BLANK_UPLOAD,
            payload: documents
        })
    }
}

export const setUploadValue = (prop, id, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPLOAD_COMPONENT_SET_FIELD_VALUE,
            payload: {
                value: value,
                prop: prop,
                id: id
            }
        })
    }
}

export const viewDocument = (data) => {
    let link = ''

    const filekey = data.data.document.key.replace('quotes/', '')
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        const grid = getState().components['purchaseorder'].grid.data

        const payload = grid.map(x => {
            return filekey.indexOf('Receipt') < 0
                ? x.filename === filekey
                    ? {
                        ...x,
                        preview: link
                    }
                    : x
                : x.po_filename === filekey
                    ? {
                        ...x,
                        po: link
                    }
                    : x
        })

        dispatch({
            type: types.SET_ACCESSORY_PO_DOCUMENTS_PREVIEW,
            payload: {
                component: 'purchaseorder',
                data: payload
            }
        })
    }
}

export const setAcceptedDocuments = (documents, id, ponumber, prefix) => {
    const filename = prefix + '_' + moment(new Date()).format('YYMMDDhhmmss') + '.pdf'
    return (dispatch, getState) => {
        documents.forEach(file => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
                let fileAsBinaryString = reader.result;
                dispatch({
                    type: types.UPLOAD_MULTIPLE_DOCUMENT_COMPONENT_UPLOADER,
                    payload: {
                        content: fileAsBinaryString,
                        filename: filename,
                        type: file.type,
                    }
                })
            };
        });

        dispatch({
            type: types.SET_UPLOAD_DOCUMENT_PREVIEW,
            payload: {
                preview: documents[0].preview,
                filename: filename,
                ponumber: ponumber,
                id: id,
            }
        })
    }
}


export const setupWorkflowSubmitInput = (id) => {
    return (dispatch, getState) => {
        const receipts = getState().uploadAccessory.documents.filter(x => x.ponumber === id)
        const pos = getState().components['purchaseorder'].grid.data
        const data = pos.map(x => {
            return {
                ...x,
                documents: getState().uploadAccessory.documents.filter(b => b.ponumber === x.ponumber)
            }
        })

        dispatch({
            type: types.CONFIRM_ACCESSORY_RECEIPT_PO_DOCUMENT_UPLOAD,
            payload: {
                step: 'stp_fml_quote_receipt_accessory',
                component: 'purchaseorder',
                selected: data
            }
        })
    }
}

export const submitWorkflowTaskInput = () => {
    return (dispatch, getState) => {
        const getTaskData = () => {
            const task = getState().workflowTaskInput.task
            //const queued = getState().workflowQueueTasks.queue
            const data = task.workflow_task_step.reduce((arr, x) => {
                x.workflow_task_step_component.forEach(y => {
                    arr.push(y.data)
                })

                return arr
            }, [])

            return {
                team_id: task.workflow_task_team ? task.workflow_task_team[0].team_id : 2,
                user_id: getState().user.user_id,
                event: types.SUBMIT_ACCESSORY_DOCUMENT_UPDATE_WORKFLOW_TASK_INPUT,
                workflow: {
                    workflow_id: task.workflow.workflow_id,
                    name: task.workflow.name,
                    title: task.workflow.title
                },
                workflow_task: {
                    workflow_task_id: task.workflow_task_id,
                    name: task.name,
                    title: task.title
                },
                data: data,
                queue: getState().workflowQueueTasks.queue
            }
        }


        dispatch({
            type: types.SUBMIT_ACCESSORY_DOCUMENT_UPDATE_WORKFLOW_TASK_INPUT,
            payload: getTaskData()
        })
    }
}



export const loadPODocuments = (documents) => {
    return (dispatch, getState) => {
        documents.forEach(x => {
            if (x.po_filename) {
                dispatch({
                    type: types.GET_ACCESSORY_PO_DOCUMENTS,
                    payload: {
                        key: 'quotes/' + x.po_filename
                    }
                })
            }
        })
    }
}

export const getDocumetnsPreview = (documents) => {
    return (dispatch, getState) => {

        documents.forEach(x => {
            dispatch({
                type: types.GET_ACCESSORY_PO_RECEIPT_DOCUMENT_PREVIEW,
                payload: {
                    type: 'GET_ACCESSORY_PO_RECEIPT_DOCUMENT_PREVIEW',
                    key: 'quotes/' + x.filename
                }
            })
        })
    }
}


export const setDocumentPreview = (data) => {
    let link = ''
    const filekey = data.data.document.key.replace('quotes/', '')
    const saveByteArray = (function () {
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        return (data, name) => {
            const blob = new Blob(data, { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            link = url
        }
    }())

    saveByteArray([data.data.content.Body], data.data.document.key)

    return (dispatch, getState) => {
        dispatch({
            type: types.SET_PO_ACCESSORY_DOCUMENT_PREVIEW,
            payload: {
                preview: link,
                filename: filekey,
            }
        })
    }
}