import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'


export const getVehichlesAutoCompleteEpic = (request) => {
  
    window.glyco.log('The get vehicles method fires')

    return (dispatch, getState) => { 
        
                dispatch({
                    type: types.GET_VEHICLES_ACTION,
                    payload: request
                   
                })
    }
       
    
    
}



export const getCustomerAutoCompleteEpic = (request) => {
    
      window.glyco.log('The get customer method fires')
  
      return (dispatch, getState) => { 
          
                  dispatch({
                      type: types.GET_CUSTOMER_ACTION,
                      payload: request
                     
                  })
      }
         
      
      
  }



export const setVehichlesAutoCompleteEpic = (data) => {
    
    window.glyco.log('Set vehicles fires')
    window.glyco.log(data)
  
    return (dispatch, getState) => { 
        
                dispatch({
                    type: types.SET_VEHICLES_ACTION,
                    payload: data.data
                })
    }
               

      
  }


  export const setCustomerAutoCompleteEpic = (data) => {
    
    window.glyco.log('Set CUSTOMERS fires')
    window.glyco.log(data)
  
    return (dispatch, getState) => { 
        
                dispatch({
                    type: types.SET_CUSTOMER_ACTION,
                    payload: data.data
                })
    }
               

      
  }




export const setVehicleAutoCompleteSelected = (request) => {

    window.glyco.log('setVehicleAutoCompleteSelected')
    window.glyco.log(request)

    return (dispatch, getState) => {

        const selectedCustomerData = {

            selectedCustomerName : request.customername,
            selectedCustomer : request

        }
        
           
        dispatch({
            type: types.SET_VEHICLE_CUSTOMER_VALUE,
            payload:  selectedCustomerData
        })
    }
}


export const setNewCustomer = (request) => {
    
        window.glyco.log('set New Customer')
        window.glyco.log(request)
    
        return (dispatch, getState) => {
    
           
            const newCustomer =  request

            dispatch({
                type: types.SET_NEW_CUSTOMER_VALUE,
                payload: newCustomer
            })
        }
    }
    

    

    export const finalizeTransferEpic = () => {
        
          window.glyco.log('The finalizeTransferEpic fires')
      


          return (dispatch, getState) => { 

                const transferData = {
                    
                        updateData: getState().vehicletransfer.selectedCustomer,
                        transferedVehicleData:  getState().vehicletransfer.newCustomer
                }

                transferData.transferedVehicleData.effectdate =  getState().vehicletransfer.effectdate

                window.glyco.log('The data from getstate')
                window.glyco.log(transferData)
                
                dispatch({
                    type: types.FINALIZE_VEHICLE_TRANSFER_ACTION,
                    payload: transferData
                })

          }
             
          
          
      }


      export const setFinalizeTransferResponse = (data) => {
          window.glyco.log(data)

        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Vehicle transfer Successful!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
      }

      export const setInputValue = (value) => {

        window.glyco.log('The date value')
        window.glyco.log(value)

        return (dispatch, getState) => {
            dispatch({
                type: types.SET_DATE_FIELD_INPUT_VALUE,
                payload: value
            })
        }
    }



    

