export const contacttypes = [{ text: 'Fleet Manage', value: 1 }, { text: 'Fleet Account', value: 2 }]
export const addresstypes = [{ text: 'Physical', value: 1 }, { text: 'Postal', value: 2 }]
export const jobtitle_data = []
export const address = []
export const contacts = []
export const organisationtype_data = []
export const empty = true
export const customername = ''
export const tradingname = ''
export const accountnumber = ''
export const registrationnumber = ''
export const vatnumber = ''
export const costcentre = ''
export const bidvestgroup = false
export const non_reporting = false
export const creditlimit = ''
export const turnoverindividualtype_data = []
export const turnoverbusinesstype_data = []
export const sourceoffunds_data = []
export const sourceofwealth_data = []
export const country_data = []
export const relationship_period = ''
export const politically_exposed_person = false
export const domestic_prominent_influential_person = false
export const foreign_prominent_public_official = false
export const ultimate_beneficial_owner = ''
export const related_parties = ''
export const standard_industrial_classification = ''
export const enhanced_due_diligence = false
export const facilityrenewalperiod_data = []
export const title_data = []
export const gender_data = []
export const user_data = []
export const region_data = []
export const branch_data = []
export const facilitylastreviewdate = ''
export const facilitynextreviewdate = ''
export const individualturnover = ''
export const businessturnover = ''
export const acccontact = []

export const fields = {
  documents: {
    errorText: 'Please add a document and select document type',
    data: false,
    valid: false,
    empty: true,
    popup: true,
    type: 'documents',
  },

  telephone: {
    errorText: 'Please input Contact Number',
    state: 'getState().customerMasterCapture.contacts.length > 0 ? getState().customerMasterCapture.contacts : true',
    data: false,
    valid: false,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['telephone']
  },
  email: {
    errorText: 'Please input Contact Email',
    state: 'getState().customerMasterCapture.contacts.length > 0 ? getState().customerMasterCapture.contacts : true',
    data: false,
    valid: false,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['email'],
    validations: [{'schema':'email', 'required':true, 'maxLength':null, 'errorText': 'Please input a valid email'}]
  },
  lastname: {
    errorText: 'Please input Contact Last Name',
    state: 'getState().customerMasterCapture.contacts.length > 0 ? getState().customerMasterCapture.contacts : true',
    data: false,
    valid: false,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['lastname']
  },
  firstname: {
    errorText: 'Please input Contact First Name',
    state: 'getState().customerMasterCapture.contacts.length > 0 ? getState().customerMasterCapture.contacts : true',
    data: false,
    valid: false,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['firstname']
  },
  contacttype: {
    errorText: 'Please input Contact Type',
    state: 'getState().customerMasterCapture.contacts.length > 0 ? getState().customerMasterCapture.contacts : true',
    data: false,
    valid: false,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['contacttype']
  },
  contact: {
    errorText: 'Please add at least one Contact',
    state: 'getState().customerMasterCapture.contacts.length > 0',
    data: false,
    valid: false,
    empty: true,
    popup: true,
  },

  addressPostalCode: {
    errorText: 'Please input Address Postal Code',
    state: 'getState().customerMasterCapture.address.length > 0 ? getState().customerMasterCapture.address : true',
    data: false,
    valid: true,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['line4'],
    validations : [{
      'schema': 'integer',
      'required': true,
      'maxLength': null,
      'errorText': 'Please input a valid Address Postal Code'
  }]
  },
  addressLine3: {
    errorText: 'Please input Address Line 3',
    state: 'getState().customerMasterCapture.address.length > 0 ? getState().customerMasterCapture.address : true',
    data: false,
    valid: true,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['line3']
  },
  addressLine2: {
    errorText: 'Please input Address Line 2',
    state: 'getState().customerMasterCapture.address.length > 0 ? getState().customerMasterCapture.address : true',
    data: false,
    valid: true,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['line2']
  },
  addressLine1: {
    errorText: 'Please input Address Line 1',
    state: 'getState().customerMasterCapture.address.length > 0 ? getState().customerMasterCapture.address : true',
    data: false,
    valid: false,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['line1']
  },
  addressType: {
    errorText: 'Please select Address Type',
    state: 'getState().customerMasterCapture.address.length > 0 ? getState().customerMasterCapture.address : true',
    data: false,
    valid: false,
    empty: true,
    popup: false,
    type: 'table',
    tableFields: ['addresstype']
  },
  address: {
    errorText: 'Please add at least one address',
    state: 'getState().customerMasterCapture.address.length > 0',
    data: false,
    valid: false,
    empty: true,
    popup: true,
  },

  facilitynextrevieweddate: {
    state: 'getState().customerMasterCapture.facilitynextreviewdate',
    errorText: 'Please input Facility Next Reviewed Date',
    valid: false,
    popup: false,
  },
  facilitylastrevieweddate: {
    state: 'getState().customerMasterCapture.facilitylastreviewdate',
    errorText: 'Please input Facility Last Reviewed Date',
    valid: false,
    popup: false,
  },
  facilityrenewalperiod: {
    state: 'getState().customerMasterCapture.facilityrenewalperiod_id',
    errorText: 'Please select Facility Renewal Period',
    valid: false,
    popup: false,
  },
  creditlimit: {
    state: 'getState().customerMasterCapture.creditlimit',
    errorText: 'Please input Credit Limit',
    validations : [{
      'schema': 'decimal',
      'required': true,
      'maxLength': null,
      'errorText': 'Please input a valid Credit Limit'
  },
  {
      'schema': 'greaterThanZero',
      'required': true,
      'maxLength': null,
      'errorText': 'Please input a valid Credit Limit greater than Zero'
  }],
    valid: false,
    popup: false,
  },

  standard_industrial_classification: {
    state: 'getState().customerMasterCapture.standard_industrial_classification',
    errorText: 'Please input Standard Industrial Classification',
    valid: false,
    popup: false,
  },
  ultimate_beneficial_owner: {
    state: 'getState().customerMasterCapture.ultimate_beneficial_owner',
    errorText: 'Please input Ultimate Beneficial Owner',
    valid: false,
    popup: false,
  },
  relationship_period: {
    state: 'getState().customerMasterCapture.relationship_period',
    errorText: 'Please input Relationship Period',
    validations : [{
      'schema': 'integer',
      'required': true,
      'maxLength': null,
      'errorText': 'Please input a valid Relationship Period'
  },
  {
      'schema': 'greaterThanZero',
      'required': true,
      'maxLength': null,
      'errorText': 'Please input a valid Relationship Period greater than Zero'
  }],
    valid: false,
    popup: false,
  },
  country_id: {
    state: 'getState().customerMasterCapture.country',
    errorText: 'Please select Trading Country',
    valid: false,
    popup: false,
  },
  sourceofwealth: {
    state: 'getState().customerMasterCapture.sourceofwealth_id',
    errorText: 'Please select Source Wealth',
    valid: false,
    popup: false,
  },
  sourceoffunds: {
    state: 'getState().customerMasterCapture.sourceoffunds_id',
    errorText: 'Please select Source Funds',
    valid: false,
    popup: false,
  },
  registrationnumber: {
    state: 'getState().customerMasterCapture.registrationnumber',
    errorText: 'Please input Company Registration Number',
    valid: false,
    popup: false,
  },


  branch: {
    state: 'getState().customerMasterCapture.branch_id',
    errorText: 'Please select Branch',
    valid: false,
    popup: false,
  },
  region: {
    state: 'getState().customerMasterCapture.region_id',
    errorText: 'Please select Region',
    valid: false,
    popup: false,
  },
  user_id: {
    state: 'getState().customerMasterCapture.user_id',
    errorText: 'Please select Account Executive',
    valid: false,
    popup: false,
  },
  businessturnover: {
    state: "getState().customerMasterCapture?.organisationtype ? (getState().customerMasterCapture.organisationtype === 'Business' ? getState().customerMasterCapture?.businessturnover : true) : true",
    errorText: 'Please enter Turn Over',
    validations : [{
      'schema': 'decimal',
      'required': true,
      'maxLength': null,
      'errorText': 'Please input a valid Turn Over'
  },
  {
      'schema': 'greaterThanZero',
      'required': true,
      'maxLength': null,
      'errorText': 'Please input a valid Turn Over greater than Zero'
  }],
    valid: false,
    popup: false,
  },
  turnoverbusinesstype: {
    state: "getState().customerMasterCapture?.organisationtype ? (getState().customerMasterCapture.organisationtype === 'Business' ? getState().customerMasterCapture?.turnoverbusinesstype : true) : true",
    errorText: 'Please select Business Type',
    valid: false,
    popup: false,
  },
  turnoverindividualtype: {
    state: "getState().customerMasterCapture?.organisationtype ? (getState().customerMasterCapture.organisationtype === 'Individual' ? getState().customerMasterCapture?.turnoverindividualtype : true) : true",
    errorText: 'Please select Individual Type',
    valid: false,
    popup: false,
  },
  organisationtype: {
    state: 'getState().customerMasterCapture.organisationtype',
    errorText: 'Please select Customer Type',
    valid: false,
    popup: false,
  },
  costcentre: {
    state: 'getState().customerMasterCapture.costcentre',
    errorText: 'Please enter Cost Centre',
    valid: false,
    popup: false,
  },
  vatnumber: {
    state: 'getState().customerMasterCapture.vatnumber',
    errorText: 'Please enter VAT Number',
    valid: true,
    popup: false,
    validations : [{
      'schema': 'integer',
      'required': true,
      'maxLength': null,
      'errorText': 'Please input a valid VAT Number'
  }]
  },
  accountnumber: {
    state: 'getState().customerMasterCapture.accountnumber',
    errorText: 'Please enter Account Number',
    valid: false,
    popup: false,
  },
  tradingname: {
    state: 'getState().customerMasterCapture.tradingname',
    errorText: 'Please enter Trading Name',
    valid: false,
    popup: false,
  },
  customername: {
    state: 'getState().customerMasterCapture.customername',
    errorText: 'Please enter Customer Name',
    valid: false,
    popup: false,
  }
};

// accessorySupplier: {
//   errorText: 'Please ensure Accessory Supplier is selected',
//   state: 'getState().workflowQuoteItemGrid.data',
//   data: false,
//   valid: false,
//   empty: true,
//   popup: false,
//   type: 'table',
//   tableFields: ['supplier'],
//   validations: [validatorTypes.IS_INT]
// },




