import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as _ from 'lodash'
import moment from 'moment'

import IconButton from '@mui/material/IconButton';
import { Container, Row, Col } from 'react-grid-system'

import AutoComplete from '../../../controls/autocomplete'

import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
//import { Card, CardHeader, CardContent } from '@mui/material'


import Card from '../../../controls/card'
import DateControl from '../../../controls/dateControl'


import Dropzone from 'react-dropzone'
import Iframe from 'react-iframe'

import Button from '@mui/material/Button'
import Icon from '../../Icon/component'

import * as styles from './styles'
import * as actions from './actions'

function findUnique(arr, predicate) {
    var found = {};
    arr.forEach(d => {
        found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]);
}

class WorkflowCreateQuote extends Component {

    componentDidMount() {

        if (this.props.mode === 'confirm') {
            const data = {
                ...this.props.state,
                datetime: moment().format('YYYY-MM-DD HH:mm')
            }

            const meta = []

            this.props.actions.setupWorkflowTaskInput(data, meta, 'full_maintenance_lease', 'tsk_full_maintenance_lease_create')
        }
    }
    getFormFieldValue(field, i) {
        switch (field.type) {
            case 'autoComplete':
                return <div><AutoComplete
                    listStyle={{ maxHeight: 200, overflow: 'auto' }}
                    id={field.name}
                    key={field.name}
                    title={window.t.en(field.title)}
                    filter={AutoComplete.fuzzyFilter}
                    //floatingLabelText={field.description}
                    openOnFocus={true}
                    fullWidth={true}
                    //searchText={field.text}
                    data={field.data}
                    errorText={field.message}

                    
                    fieldvalue={[field.data_value]}
                    displayfields={[field.data_text]}
                    onSelect={
                        (chosenRequest, index) => {
                            this.props.actions.setInputValue(field, chosenRequest[field.data_value], i, chosenRequest[field.data_text])
                        }
                    }
                    onClick={
                        () => {
                            this.props.actions.getInputookupData(field, '', i)
                        }
                    }
                    onFilter={(args) => {
                        this.props.actions.getInputookupData(field, args, i)
                    }}
                />
                    {
                        field.error && field.valid
                            ? [
                                <br />,
                                <div style={{ color: 'red' }}>{field.error}</div>
                            ]
                            : ''
                    }
                </div>
            case 'datePicker':
                return <div>
                    <DateControl
                        id={field.key}
                        key={field.key}
                        label='Select date'
                        name={field.key}
                        error={field.value ? false : true}
                        helperText={field.value ? '' : 'Enter date'}
                        value={field.value}
                        onSelect={(args) => {
                            this.props.actions.setInputValue(field, args)
                        }}
                        type={'date'}
                    />

                    {
                        field.error && field.valid
                            ? [
                                <br />,
                                <div style={{ color: 'red' }}>{field.error}</div>
                            ]
                            : ''
                    }
                </div>
            case 'hidden':
                return <input
                    key={'component_' + this.component.name + '_' + field.name + '_' + this.component.data[field.name]}
                    type='hidden'
                    //defaultValue={0}
                    value={this.component.data[field.name].input}
                />
            case 'timePicker':
                return <div>
                <TextField
                type="time"
                        key={this.component.data[field.name].key}
                        //floatingLabelText={field.description}
                        format='24hr'
                        hintText='24hr Format'
                        //defaultTime={new Date()}
                        value={this.component.data[field.name].input}
                        onChange={
                            (event, value) => {
                                this.props.actions.setInputValue(field, value)
                            }
                        }
                    />

                    {
                        field.error && field.valid
                            ? [
                                <br />,
                                <div style={{ color: 'red' }}>{field.error}</div>
                            ]
                            : ''
                    }
                </div>
            case 'textBox':
                return <div><TextField
                    key={field.name}
                    id={field.name}
                    label={window.t.en(field.title)}
                    value={field.value}
                    //errorText={field.error}
                    variant="outlined" 
                    disabled={field.disabled}
                    style={styles.textBox}
                    onChange={
                        (event, value) => {
                            this.props.actions.setInputValue(field, event.target.value, i)
                            if (field.formula)
                                this.props.actions.doFormulaInputValue(field, field.formula, i)
                        }
                    }
                />
                    {
                        field.error && field.valid
                            ? [
                                <br />,
                                <div style={{ color: 'red' }}>{field.error}</div>
                            ]
                            : ''
                    }
                </div>
            case 'upload':
                return <div>
                    {
                        field.value
                            ? <Iframe url={field.value}
                                width="90%"
                                height="150px"
                                id={field.key}
                                className="myClassname"
                                display="initial"
                                position="relative"
                                allowFullScreen />
                            : <Dropzone
                                accept={'.doc,.docx,.jpeg,.jpg,.pdf,.png,.xls,.xlsx.'}
                                style={styles.dropZone}
                                hintText={'Upload'}
                                onDrop={(files) => {
                                    //component.actions.toggleDropzoneVisibility()
                                }}
                                onDropAccepted={(files) => {
                                    this.props.actions.setAcceptedDocuments(field, files)
                                }}
                                onDropRejected={(files) => {
                                    this.props.actions.resetDocuments(field)
                                }}
                            >
                                <p style={styles.watermark}>
                                    Drag and drop file here, or click to select file to upload.
                </p>
                            </Dropzone>
                    }
                    {
                        field.error && field.valid
                            ? [
                                <br />,
                                <div style={{ color: 'red' }}>{field.error}</div>
                            ]
                            : ''
                    }
                </div>
            case 'toggle':
                return <div>
                    <Checkbox
                        key={field.name}
                        //label={field.hint}
                        labelPosition='right'
                        //
                        style={styles.toggle}
                        trackStyle={{
                            backgroundColor: 'gray'
                        }}
                        trackSwitchedStyle={{
                            backgroundColor: 'green'
                        }}
                        checked={field.value ? field.value : false}
                        onChange={
                            (event, isInputChecked) => {
                                this.props.actions.setInputValue(field, isInputChecked)
                            }
                        }
                    />

                    {
                        field.error && field.valid
                            ? [
                                <br />,
                                <div style={{ color: 'red' }}>{field.error}</div>
                            ]
                            : ''
                    }
                </div>
            default:
                return
        }
    }

    getField(field, i) {
        return !field.hidden
            ? <div style={styles.fieldContainer}>
                <div style={styles.fieldTitle}>{window.t.en(field.required ? field.title + '*' : field.title)}</div>
                <div style={styles.fieldContent}>{this.getFormFieldValue(field, i)}</div>
            </div>
            : this.getFormFieldValue(field, i)
    }

    getFormField(field, i) {
        return !field.hidden
            ? <div style={styles.fieldContainer}>
                <div style={styles.formTitle}>{window.t.en(field.required ? field.title + '*' : field.title)}</div>
                <div style={styles.formContent}>{this.getFormFieldValue(field, i)}</div>
            </div>
            : this.getFormFieldValue(field, i)
    }

    getListFields(field, props, inx, i) {
        return <div style={styles.fieldContainer}>
            <div style={styles.formTitle}>{field[props.text]}</div>
            <div style={styles.formContent}>
                <TextField
                    key={field.accessoryname}
                    id={field.accessoryname}
                    disabled={field.disabled}
                    value={field.costprice}
                    style={styles.textBox}
                    onChange={
                        (event, value) => {
                            this.props.actions.setAccessoryValue(field, props, value, inx, i)
                        }
                    }
                /></div>
        </div>
    }

    getAccessory(field, i) {
        return <div><AutoComplete
            listStyle={{ maxHeight: 200, overflow: 'auto' }}
            id={field.name}
            key={field.name}
            title={"Select to add " + field.name}
            filter={AutoComplete.fuzzyFilter}
            //floatingLabelText={field.description}
            openOnFocus={true}
            fullWidth={true}
            data={this.props.state[field.prop]}
            
            fieldvalue={[field.value]}
            displayfields={[field.text]}
            onSelect={
                (chosenRequest) => {
                    //this.props.actions.setInputValue(field, chosenRequest[field.data_value], i, chosenRequest[field.data_text])
                    this.props.actions.setSelectedListLookupData(field, chosenRequest[field.value], i)
                }
            }
            onClick={
                () => {
                    this.props.actions.getListLookupData(field, '', i)
                }
            }
            onFilter={(args) => {
                this.props.actions.getListLookupData(field, args, i)
            }}
        />
        </div>
    }

    getQuote(s) {
        return  <Container style={styles.minheight}>
        {
            s.fields
                ? <Row>
                    <Col xl={12}>

                        <div>
                           < Button className={"global_button"} variant="contained"
                                primary={true}
                                label="Add Vehicle"
                                icon={<Icon iclass={'material-icons'} iname={'plus_one'} />}
                                disabled={s.fields.length > 2}
                                onClick={(event) => {
                                    this.props.actions.addVehicle(s, this.props.state.form)
                                }}
                                >{window.t.en('Add Vehicle')}</Button>
                        </div>
                        {
                            s.fields.map((m, i) => {

                                return <div><div style={styles.content}>
                                    <Container style={{ margin: 0 }} className="custom-container">
                                        <Row style={styles.frmHeader}>
                                            <Col key={'col_' + s.name} xl={10}>{s.title}</Col>
                                            <Col xl={2} style={styles.remove}>
                                                <Icon
                                                    iname="delete_forever"
                                                    iclass="material-icons"
                                                    istyle={{
                                                        fontSize: '16px',
                                                    }}
                                                    onClick={(event) => {
                                                        this.props.actions.removeVehicle(s, i)
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {
                                            m.filter(z => z.section === 'Vehicle Details').map(fld => {
                                                return <Row>
                                                    <Col key={'col_' + fld.name} xl={12}>{this.getFormField(fld, i)}</Col>
                                                </Row>
                                            })
                                        }
                                        <Row style={styles.frmHeader}>
                                            <Col key={'col_' + s.name} xl={12}>
                                                <Card
                                                    title={"Accessory"}
                                                    content={
                                                        <Container style={styles.minheight}>
                                                            {
                                                                this.props.state.accessories_chip.length
                                                                    ? this.props.state.accessories_chip.map(x => {
                                                                        return <Chip onClick={this.handleClick} style={styles.chip}>
                                                                            <Avatar size={32}>{'Acc'}</Avatar>
                                                                            {x.accessoryname}
                                                                        </Chip>
                                                                    })
                                                                    : ''
                                                            }
                                                        </Container>
                                                    }
                                                    />
                                            </Col>
                                        </Row>
                                        <Row style={styles.frmHeader}>
                                            <Col key={'col_' + s.name} xl={12}>{"Vehicle Accessories"}</Col>
                                        </Row>
                                        <Row style={styles.frmHeader}>
                                            <Col key={'col_' + s.name} xl={12}>{this.getAccessory({ parent: "vehicles", name: "Accessory", prop: "accessory_list", table: "accessory", value: "accessory_id", text: "accessoryname" }, i)}</Col>
                                        </Row>
                                        {
                                            m.filter(z => z.name === 'accessories')[0].accessory_list
                                                ? m.filter(z => z.name === 'accessories')[0].accessory_list.map((x, _i) => {
                                                    return <Row>
                                                        <Col key={'col_' + s.name} xl={2}>

                                                            <IconButton key={'command_remove_accessory_' + i} style={styles.iconButton} iconStyle={styles.action}
                                                                onClick={
                                                                    () => {
                                                                        this.props.actions.removeSelectedItemRow(x, { parent: "vehicles", name: "Accessory", prop: "accessory_list", table: "accessory", value: "accessory_id", text: "accessoryname" }, i, _i)
                                                                    }
                                                                }>
                                                                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                                            </IconButton></Col>
                                                        <Col key={'col_' + x.accessoryname} xl={10}>{this.getListFields(x, { parent: "vehicles", name: "Accessory", prop: "accessory_list", table: "accessory", value: "accessory_id", text: "accessoryname" }, i, _i)}</Col>
                                                    </Row>
                                                })
                                                : ''
                                        }
                                        <Row style={styles.frmtotal}>
                                            <Col key={'col_' + s.name} xl={12} style={styles.amountfield}>{"Accessories Total: R " + _.sumBy(m.filter(z => z.name === 'accessories')[0].accessory_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)}</Col>
                                        </Row>
                                        <Row style={styles.frmHeader}>
                                            <Col key={'col_' + s.name} xl={12}>{"Vehicle Services"}</Col>
                                        </Row>
                                        <Row style={styles.frmHeader}>
                                            <Col key={'col_' + s.name} xl={12}>{this.getAccessory({ parent: "vehicles", name: "Service", prop: "service_list", table: "service", value: "service_id", text: "servicedescription" }, i)}</Col>
                                        </Row>
                                        {
                                            m.filter(z => z.name === 'accessories')[0].service_list
                                                ? m.filter(z => z.name === 'accessories')[0].service_list.map((x, _i) => {
                                                    return <Row>
                                                        <Col key={'col_' + s.name} xl={2}>

                                                            <IconButton key={'command_remove_service_' + i} style={styles.iconButton} iconStyle={styles.action}
                                                                onClick={
                                                                    () => {
                                                                        this.props.actions.removeSelectedItemRow(x, { parent: "vehicles", name: "Service", prop: "service_list", table: "service", value: "service_id", text: "servicedescription" }, i, _i)
                                                                    }
                                                                }>
                                                                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                                            </IconButton></Col>
                                                        <Col key={'col_' + x.accessoryname} xl={10}>{this.getListFields(x, { parent: "vehicles", name: "Service", prop: "service_list", table: "service", value: "service_id", text: "servicedescription" }, i, _i)}</Col>
                                                    </Row>
                                                })
                                                : ''
                                        }
                                        <Row style={styles.frmtotal}>
                                            <Col key={'col_' + s.name} xl={12} style={styles.amountfield}>{"Services Total: R " + _.sumBy(m.filter(z => z.name === 'accessories')[0].service_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)}</Col>
                                        </Row>
                                        <Row style={styles.frmHeader}>
                                            <Col key={'col_' + s.name} xl={12}>{"Vehicle Sundries"}</Col>
                                        </Row>
                                        <Row style={styles.frmHeader}>
                                            <Col key={'col_' + s.name} xl={12}>{this.getAccessory({ parent: "vehicles", name: "Sundry", prop: "sundry_list", table: "sundry", value: "sundry_id", text: "description" }, i)}</Col>
                                        </Row>
                                        {
                                            m.filter(z => z.name === 'accessories')[0].sundry_list
                                                ? m.filter(z => z.name === 'accessories')[0].sundry_list.map((x, _i) => {
                                                    return <Row>
                                                        <Col key={'col_' + s.name} xl={2}>

                                                            <IconButton key={'command_remove_sundry_' + i} style={styles.iconButton} iconStyle={styles.action}
                                                                onClick={
                                                                    () => {
                                                                        this.props.actions.removeSelectedItemRow(x, { parent: "vehicles", name: "Sundry", prop: "sundry_list", table: "sundry", value: "sundry_id", text: "description" }, i, _i)
                                                                    }
                                                                }>
                                                                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'delete_forever'} />
                                                            </IconButton></Col>
                                                        <Col key={'col_' + x.accessoryname} xl={10}>{this.getListFields(x, { parent: "vehicles", name: "Sundry", prop: "sundry_list", table: "sundry", value: "sundry_id", text: "description" }, i, _i)}</Col>
                                                    </Row>
                                                })
                                                : ''
                                        }
                                        <Row style={styles.frmtotal}>
                                            <Col key={'col_' + s.name} xl={12} style={styles.amountfield}>{"Sundries Total: R " + _.sumBy(m.filter(z => z.name === 'accessories')[0].sundry_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)}</Col>
                                        </Row>
                                    </Container>
                                </div>
                                </div>
                            })
                        }
                    </Col>
                </Row>
                : <Row>
                    <Col xl={8}>
                        <Container style={{ margin: 0 }} className="custom-container">
                            {
                                this.props.state.fields.filter(f => f.section === s.section).map(x => {
                                    return <Row>
                                        <Col key={'col_' + x.name} xl={12}>{this.getField(x)}</Col>
                                    </Row>
                                })
                            }
                        </Container>
                    </Col>
                    <Col xl={4} style={styles.rightpanel}>
                        {
                            this.props.state.customer && s.section === "Contract Details"
                                ? <div>
                                    <h3>{'Customer Details'}</h3>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Customer Name, ' + this.props.state.customer.hierarchyname}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Cost Centre, ' + this.props.state.customer.costcentre}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Customer Region, ' + this.props.state.customer.region}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Contact Person, ' + this.props.state.customer.contactperson}</div>
                                    </div>
                                </div>
                                : <div>{''}</div>
                        }
                        {
                            this.props.state.derivative && s.section === "Contract Details"
                                ? <div>
                                    <h3>{'Customer Derivative'}</h3>
                                    <div style={styles.fieldContainer}>
                                        <div>{'ROI Percentage, ' + this.props.state.derivative.roiperc}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Default Load Factor, ' + this.props.state.derivative.loadfactor}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Default Vehicle Color, ' + this.props.state.derivative.colourchoice}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Admin Fee, R' + this.props.state.derivative.adminfee}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'First Payment Date, ' + this.props.state.derivative.firstpaymentdate}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Period From , ' + this.props.state.derivative.periodmin + ' To ' + this.props.state.derivative.periodmax}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Distance Petrol From , ' + this.props.state.derivative.distanceminpetrol + ' To ' + this.props.state.derivative.distancemaxpetrol}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Distance Diesel From , ' + this.props.state.derivative.distancemindiesel + ' To ' + this.props.state.derivative.distancemaxdiesel}</div>
                                    </div>
                                </div>
                                : <div>{''}</div>
                        }
                        {
                            this.props.state.quotefor && s.section === "Replace Vehicle"
                                ? <div>
                                    <h3>{'Quote For'}</h3>
                                    <div style={styles.fieldContainer}>
                                        <div>{this.props.state.quotefor.quotefor}</div>
                                    </div>
                                </div>
                                : <div>{''}</div>
                        }
                        {
                            this.props.state.replacevehicle && s.section === "Replace Vehicle"
                                ? <div>
                                    <h3>{'Replace Vehicle Detail'}</h3>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Registration, ' + this.props.state.replacevehicle.licenseplateno}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Contract No, ' + this.props.state.replacevehicle.contractnumber}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Contract Type, ' + this.props.state.replacevehicle.contracttype}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Vehicle, ' + this.props.state.replacevehicle.makemodelyear}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Customer, ' + this.props.state.replacevehicle.customername}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Status, ' + this.props.state.replacevehicle.contractstatus}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Start, ' + this.props.state.replacevehicle.startdate + ' End, ' + this.props.state.replacevehicle.terminationdate}</div>
                                    </div>
                                    <div style={styles.fieldContainer}>
                                        <div>{'Monthly Rent, ' + this.props.state.replacevehicle.monthly_incl}</div>
                                    </div>
                                </div>
                                : <div>{''}</div>
                        }
                        {
                            s.section === "RFQ Details"
                                ? <div>
                                    <h3>{'RFQ Details'}</h3>
                                    {/* <div style={styles.fieldContainer}>
                                        <div>{'RFQ Date, '} {this.props.state.fields.filter(x => x.name === "rfqdate")[0].value ? moment(this.props.state.fields.filter(x => x.name === "rfqdate")[0].value).format("YYYY-MM-DD") : " Not Selected"}</div>
                                    </div> */}
                                    <div style={styles.fieldContainer}>
                                        <div>{'RFQ Document Upload, '} {this.props.state.fields.filter(x => x.name === "rfqdocument")[0].value ? "YES" : "NO"}</div>
                                    </div>
                                </div>
                                : <div>{''}</div>
                        }
                    </Col>
                </Row>
        }
    </Container>

    }

    getForm() {
        return <div>
            {
                findUnique(this.props.state.fields, d => d.section).map(s => {
                    return <div style={styles.minheight}>
                        <Card
                            title={s.section}
                            subtitle={window.t.en('Contract details')}
                            content={this.getQuote(s)}
                        /> 
                        </div>
                })
            }
        </div>
    }

    getVehicleAccessory(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row style={styles.itemsheader}>
                <Col xl={12}>{"Accessories"}</Col>
            </Row>
            <Row style={styles.itemscolheader}>
                <Col xl={6}>{"Accessory Name"}</Col>
                <Col style={styles.txtright} xl={6}>{"Selling Price"}</Col>
            </Row>
            {
                data.accessory_list.map(x => {
                    return <Row style={styles.itemsrow}>
                        {/* <Col xl={5}>{x.accessorytype_fktext}</Col> */}
                        <Col xl={6}>{x.accessoryname}</Col>
                        <Col style={styles.txtright} xl={6}>{x.costprice}</Col>
                    </Row>
                })
            }
            <Row style={styles.itemsfooter}>
                <Col xl={12}>{"Total " + _.sumBy(data.accessory_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)}</Col>
            </Row>
        </Container>
    }

    getVehicleService(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row style={styles.itemsheader}>
                <Col xl={12}>{"Services"}</Col>
            </Row>
            <Row style={styles.itemscolheader}>
                <Col xl={6}>{"Service Name"}</Col>
                <Col style={styles.txtright} xl={6}>{"Selling Price"}</Col>
            </Row>
            {
                data.service_list.map(x => {
                    return <Row style={styles.itemsrow}>
                        {/* <Col xl={5}>{"General"}</Col> */}
                        <Col xl={6}>{x.servicedescription}</Col>
                        <Col style={styles.txtright} xl={6}>{x.costprice}</Col>
                    </Row>
                })
            }
            <Row style={styles.itemsfooter}>
                <Col xl={12}>{"Total " + _.sumBy(data.service_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)}</Col>
            </Row>
        </Container>
    }
    getVehicleSundry(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row style={styles.itemsheader}>
                <Col xl={12}>{"Sundries"}</Col>
            </Row>
            <Row style={styles.itemscolheader}>
                <Col xl={6}>{"Sundry Name"}</Col>
                <Col style={styles.txtright} xl={6}>{"Selling Price"}</Col>
            </Row>
            {
                data.sundry_list.map(x => {
                    return <Row style={styles.itemsrow}>
                        {/* <Col xl={5}>{"General"}</Col> */}
                        <Col xl={6}>{x.description}</Col>
                        <Col style={styles.txtright} xl={6}>{x.costprice}</Col>
                    </Row>
                })
            }
            <Row style={styles.itemsfooter}>
                <Col xl={12}>{"Total " + _.sumBy(data.sundry_list, (d) => { return parseFloat(d.costprice) }).toFixed(2)}</Col>
            </Row>
        </Container>
    }
    getCards(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}></Col>
            </Row>
            <Row>
                <Col xl={12}>{this.getVehicleAccessory(data)}</Col>
            </Row>
            <Row>
                <Col xl={12}><br /></Col>
            </Row>
            <Row>
                <Col xl={12}>{this.getVehicleService(data)}</Col>
            </Row>
            <Row>
                <Col xl={12}><br /></Col>
            </Row>
            <Row>
                <Col xl={12}>{this.getVehicleSundry(data)}</Col>
            </Row>
        </Container>
    }

    getContractView(fields) {
        return <Card
                    title={"Contract Parameters"}
                    subtitle={window.t.en('contract details quoted parameters')}
                    content={
                        <Container style={{ margin: 0 }} className="custom-container">
                        {
                            fields.map(f => {
                                return <Row>
                                    <Col style={styles.viewTitle} xl={4}>{f.title + ":"}</Col>
                                    <Col style={styles.viewValue} xl={8}>{f.text ? f.text : f.value}</Col>
                                </Row>
                            })
                        }
                        </Container>
                    }
                /> 

    }
    getFieldValue(field) {

        switch (field.type) {
            case 'datePicker':
            case 'data':
                return field.value !== null
                    ? moment(field.value).format('LLLL') + ', ' + moment(field.value).fromNow()
                    : 'Not Set'
            default:
                return field.value
        }
    }
    getRFQ(getRFQ) {
        return <Card
                    title={"RFQ Details"}
                    subtitle={window.t.en('RFQ date and uploaded document')}
                    content={
                        <Container style={{ margin: 0 }} className="custom-container">
                            {
                                getRFQ.map(f => {
                                    return <Row>
                                        <Col style={styles.viewTitle} xl={4}>{f.title + ":"}</Col>
                                        {
                                            f.type === 'upload'
                                                ? <Col style={styles.viewValue} xl={8}>{this.getFormFieldValue(f, 0)}</Col>
                                                : <Col style={styles.viewValue} xl={8}>{f.text ? f.text : f.value}</Col>
                                        }
                                    </Row>
                                })
                            }
                        </Container>
                    }
                /> 
    }
    getViewOff() {
        return <Card
        title={"Confirm FML Quotation"}
        subtitle={window.t.en('Enter comments')}
                    content={this.getViews()}
                    /> 
    }

    getView() {
        //console.log(this.props.state)
        let vehicles = this.props.state.fields.filter(x => x.section === "Vehicle Details")
        let contract = this.props.state.fields.filter(x => x.section === "Contract Details")
        let rfq = this.props.state.fields.filter(x => x.section === "RFQ Details")
        //this.getContractView(contract)
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>{this.getContractView(contract)}</Col>
            </Row>
            <Row>
                <Col xl={12}>{this.getRFQ(rfq)}</Col>
            </Row>
            <Row>
                {
                    vehicles[0].fields.map((v, q) => {
                        let len = vehicles[0].fields.length
                        let xl = parseInt(12 / len)
                        let fields = v.filter(x => x.section === "Vehicle Details")
                        let items = v.filter(x => x.section === "Vehicle Accessory")[0]
                        let makemodelyear = v.filter(x => x.name === "makemodelyear")[0]
                        return <Col xl={xl}>
                                <Card
                                    title={"RFQ Details"}
                                    subtitle={window.t.en('RFQ date and uploaded document')}
                                    content={
                                        <Container style={{ margin: 0 }} className="custom-container">
                                            <Col>
                                                <Row>
                                                    <Container style={{ margin: 0 }} className="custom-container">
                                                        {
                                                            fields.map((f, i) => {
                                                                return f.title ? <Row style={styles.lines}>
                                                                    <Col style={styles.viewTitle} xl={6}>{f.title + ":"}</Col>
                                                                    <Col style={styles.viewValue} xl={6}>{f.text ? f.text : f.value}</Col>
                                                                </Row> : ''
                                                            })
                                                        }
                                                    </Container>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    {this.getCards(items)}
                                                </Row>
                                            </Col>
                                        </Container>
                                    }
                                /> 

                        </Col>
                    })
                }
            </Row>
        </Container>
    }

    getLayout() {
        return <div>{
            this.props.mode === 'confirm'
                ? this.getView()
                : this.getForm()
        }<br /><br /><br /><br /><br /><br />
        </div>
    }

    render() {
        return (
            this.getLayout()
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.workflowCreateQuote
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowCreateQuote)