//import * as colours from '@mui/material/colors'

export const selectedIcon = {
    width: 16
}

export const selectedFieldName = {
    fontWeight: 'bold'
}

export const fieldLabel = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const inputWidth = {
    width: '100%'
}

export const fieldContainer = {
    display: 'table',
}

export const fieldTitle = {
    width: '300px',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: '16px',
    textAlign: 'right',
    paddingRight: 10
}

export const fieldContent = {
    display: 'table-cell',
    width: '65%'
}

export const row = {
    //backgroundColor: '#dcd6d7',
    padding: 10,
    textAlign: 'left',
}
export const lintitems = {
    backgroundColor: '#eaf6ff',
    borderBottom: '2px solid gray',
    padding: 10
}
export const rowHeader = {
    fontWeight: 'bold',
    fontSize: '19px',
}

export const numericFields = {
    textAlign: 'right',
}

export const numericDiv = {
    textAlign: 'right',
    width: '100%',
}

export const fieldLabelA = {
    width: '500px',
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontSize: '16px',
    textAlign: 'right',
    paddingTop: 20,
    paddingRight: 10
}
export const checkbox = {
    display: 'table-cell',
    verticalAlign: 'middle',
    width: '300px',
    fontWeight: 'bold'
}
