import * as types from './types'
import moment from 'moment'
import _ from 'lodash'

export const setToggleInputValue = (field, inx, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_STR_INPUT_FIELD_VALUE,
            payload: {
                field: field,
                value: value,
                inx: inx
            }
        })
    }
}

export const setSTRInputValue = (inx, value) => {
    return (dispatch, getState) => {
        let grid = getState().strItemGrid.grid
        let header = getState().captureSTR
        const _fromdate = moment(header.startdate.value)
        const _todate = moment(header.enddate.value) 
        let _days = _todate.diff(_fromdate, "days") + 1


        grid[inx].rateperday = value
        grid[inx].totalprice = (parseFloat(value) * parseInt(_days)).toFixed(2)
        
        const accessory_tot = _.sumBy(grid, (d) => { return parseFloat((d.totalprice) ? d.totalprice : 0) }).toFixed(2)
        dispatch({
            type: types.SET_COMPONENT_STR_DEFAULT_ACCESSORY_LIST,
            payload: grid
        })      
        
        dispatch({
            type: types.SET_COMPONENT_STR_DEFAULT_ACCESSORY_TOTAL,
            payload: accessory_tot
        })
    }
}

export const updSTRGridItems = () => {
    return (dispatch, getState) => {
        let header = getState().captureSTR
        
        const _fromdate = moment(header.startdate.value)
        const _todate = moment(header.enddate.value) 
        let _days = _todate.diff(_fromdate, "days")
        
        const grid = getState().strItemGrid.grid.map(x => {
            return {
                ...x,
                totalprice: x.rateperday ? (x.rateperday * _days) : null
            }
        })

        const accessory_tot = _.sumBy(grid, (d) => { return parseFloat((d.totalprice) ? d.totalprice : 0) }).toFixed(2)
        dispatch({
            type: types.SET_COMPONENT_STR_DEFAULT_ACCESSORY_LIST,
            payload: grid
        }) 

        dispatch({
            type: types.SET_COMPONENT_STR_DEFAULT_ACCESSORY_TOTAL,
            payload: accessory_tot
        })
    }
}
export const getComponentGridData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_COMPONENT_STR_DEFAULT_ACCESSORY_LIST,
        })
    }
}

export const setComponentGridData = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_STR_DEFAULT_ACCESSORY_LIST,
            payload: payload.data
        })
    }
}
