import * as colours from '@mui/material/colors'
import Theme from '../../theme'

export const theme = Theme

export const icon = {
    color: colours.red[500]
}

export const image = {
    width: '100%'
}

export const placeholder = {
    textAlign: 'middle',
    fontSize: '150px'
}

export const related = {
    //fontSize: '10px'
}

export const documentButton = {
    width: '50px'
}

export const documentCard = {
    margin: '5px'
}

export const documentCardTitle = {
    fontWeight: 'bold'
}

export const headerButton = {
    width: 250
}

export const card = {
    /*     marginLeft: 20,
        marginRight: 20 */
    //opacity: 0.85
}

export const document = {
    width: '50px'
}

export const tab = {
    textTransform: 'capatilize',
    //whiteSpace: 'nowrap'
}

export const tabIcon = {
    fontSize: 24,
    width: 24,
    height: 24
}

export const label = {
    fontSize: 10
}

export const kpiIcon = {
    color: colours.red[500],
    fontSize: 75,
    textAlign: 'center',
    verticalAlign: 'middle'
}

export const kpiImage = {
    width: '100%',
    height: 175,
}

export const headerBackdrop = (src) => {
    return {
        background: 'url(' + src + ') no-repeat',
        height: '175px',
        backgroundSize: '275px',
        backgroundPosition: 'left top',
        //opacity: 0.5
    }
}

export const kpi = {
    backgroundColor: colours.grey[50],
    color: colours.grey[900],
    width: '100%',
    height: 150,
    fontSize: 45,
    fontWeight: 'bold',
    marginBottom: 15,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    //opacity: 0.85
}

export const indicator = {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block'
}

export const submenu = {
    //padding: '0px',
    //margin: '0px'
}

export const subliprimary = {
    fontSize: '15px',
    fontWeight: 'bold',
}

export const subli = {
    fontSize: '15px',
    marginBottom: '0px',
    padding: '0px'
}

export const subliicon = {
    color: colours.red[500],
    fontSize: '20px',
    marginTop: '10px'
}