import * as actions from './actions'

import * as progressBarActions from '../ProgressBar/actions'
import * as requestActions from '../Request/actions'
import * as routerActions from '../Redirect/actions'
import * as types from './types'
import * as formTypes from '../Form/types'
import * as requestTypes from '../Request/types'
import * as config from '../../config'

export const setReadComponentData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(formTypes.SET_COMPONENT_DATA),
        mergeMap((action) => [
            actions.readComponent(action.payload.name, action.payload.surrogate)
        ])
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
)

export const getRelatedComponentData = (action$, store, { ajax$, of$, startWith,   map, mergeMap, delay, catchError, ofType, takeUntil }) =>
action$.pipe(
        ofType(types.READ_COMPONENT_GET_RELATED_COMPONENT_DATA)
        ,mergeMap(({ payload }) =>
            ajax$({
                url: config.system.api + '/components/' + payload.related.name + '/search?' + payload.key + '=' + payload.id,
                method: 'GET',
                headers: {
                    'tokenx': store.value.user.user_id,
                    'auth': store.value.cognito.authenticateUser.token,
                    'salt': store.value.cognito.authenticateUser.jwt,
                    'datetime': store.value.cognito.authenticateUser.datetime,
                    'take': 1,
                    'page': 1
                },
            })
                .pipe(
            mergeMap((result) => [
                    actions.setRelatedComponentData(payload.component, payload.related, result.response),
                    progressBarActions.progress(false),
                    requestActions.requestSucceeded(config.system.api + '/components/' + payload.related.name + '/search?' + payload.component.component_field.filter(x => x.key)[0].name + '=' + payload.id)
                ])
                ,startWith(progressBarActions.progress(true))
                ,catchError((error) => of$(requestActions.requestFailed(config.system.api + '/components/' + payload.related.name + '/search?' + payload.component.component_field.filter(x => x.key)[0].name + '=' + payload.id, error)))
                //,take.Until(action$.ofType(requestTypes.CANCEL_REQUESTS))
        )
        )
        ,catchError((error) =>
            of$(routerActions.route('/support', false, error))
        )
        )