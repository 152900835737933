
export const GET_CUSTOMER_STATEMENT_LIST = 'GET_CUSTOMER_STATEMENT_LIST'
export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST'
export const SET_ANY_DATA_LIST = 'SET_ANY_DATA_LIST'
export const SET_CUSTOMER_STATEMENT_LIST = 'SET_CUSTOMER_STATEMENT_LIST'
export const GEN_CUSTOMER_STATEMENT_PDF = 'GEN_CUSTOMER_STATEMENT_PDF'
export const SET_ANY_SELECTED_ITEM = 'SET_ANY_SELECTED_ITEM'
export const GEN_CUSTOMER_BILLING_INVOICE = 'GEN_CUSTOMER_BILLING_INVOICE'
export const GEN_CUSTOMER_BILLING_STATEMENT = 'GEN_CUSTOMER_BILLING_STATEMENT'
export const GEN_CUSTOMER_SAP_BILLING_STATEMENT = 'GEN_CUSTOMER_SAP_BILLING_STATEMENT'
export const GEN_CUSTOMER_TFR_BILLING_STATEMENT = 'GEN_CUSTOMER_TFR_BILLING_STATEMENT'
export const GEN_CUSTOMER_BILLING_STATEMENT_EXCEL = 'GEN_CUSTOMER_BILLING_STATEMENT_EXCEL'
export const GET_STATEMENT_CONTACT_LIST = 'GET_STATEMENT_CONTACT_LIST'
export const SET_CUSTOMER_TRANSACTION_LIST = 'SET_CUSTOMER_TRANSACTION_LIST'
export const SET_CUSTOMER_TRANSACTION_DATA = 'SET_CUSTOMER_TRANSACTION_DATA'
export const GENERATE_CUSTOMER_STATEMENT = 'GENERATE_CUSTOMER_STATEMENT'
export const GET_REBILLS = 'GET_REBILLS'
export const SET_STATEMENT_VALUE = 'SET_STATEMENT_VALUE'
export const GET_TIMETZ = 'GET_TIMETZ'
export const SET_TIMETZ = 'SET_TIMETZ'
export const POST_TIMETZ = 'POST_TIMETZ'