export const pins = []
export const events = []
export const showall = true
export const type = [
    {
        text: 'My Events'
    },
    {
        text: 'All Events'
    }
]

export const tasks = []
    export const tasksoff = [
    {
      start: new Date(2022, 6, 30),
      end: new Date(2023, 1, 20),
      name: 'ND154425 - CRN STR',
      id: 'Task 0',
      type:'task',
      progress: 80,
      isDisabled: true,
      styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    },
    {
      start: new Date(2022, 10, 30),
      end: new Date(2023, 3, 20),
      name: 'ND168547 - CRN STR',
      id: 'Task 0',
      type:'task',
      progress: 40,
      isDisabled: true,
      styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    },
    {
      start: new Date(2022, 6, 30),
      end: new Date(2023, 5, 20),
      name: 'ND22548 - CRN STR',
      id: 'Task 0',
      type:'task',
      progress: 60,
      isDisabled: true,
      styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    },
    {
      start: new Date(2022, 10, 22),
      end: new Date(2022, 10, 22),
      name: 'Service - ND68452',
      id: 'Task 0',
      type:'task',
      progress: 100,
      isDisabled: true,
      styles: { progressColor: '#039dfc', progressSelectedColor: '#039dfc' },
    },
    {
      start: new Date(2022, 10, 9),
      end: new Date(2022, 10, 10),
      name: 'Tyre - ND757521',
      id: 'Task 0',
      type:'task',
      progress: 100,
      isDisabled: true,
      styles: { progressColor: '#039dfc', progressSelectedColor: '#039dfc' },
    },
    {
      start: new Date(2022, 9, 20),
      end: new Date(2022, 10, 2),
      name: 'Engine - ND799548',
      id: 'Task 0',
      type:'task',
      progress: 100,
      isDisabled: true,
      styles: { progressColor: '#039dfc', progressSelectedColor: '#039dfc' },
    }
]