import moment from 'moment'
//import validator from '../validate'
import * as _ from 'lodash'

import * as appTypes from '../../App/types'
import * as types from './types'

export const getAllVehicles = (search) => {
    return {
        type: types.GET_VEHICLE_LIST,
        payload: {
            search_text: search
        }
    }
}
export const setVehicleList = (payload) => {
    let data = []
    data = payload.data.map(x => {
        return {
            text: x.licenseplateno + ' | ' + x.makemodelyear,
            value: x.vehicle_id,
        }
    })
    return {
        type: types.SET_VEHICLE_LIST,
        payload: {
            data: data
        }
    }
}


export const setLookupValue = (field, value, text) => {
    return {
        type: types.SET_ANY_SELECTED_ITEM,
        payload: {
            value: value,
            text: text,
            prop: field,
            component: 'disposal'
        }
    }
}

export const setInputValue = (field, value) => {
    return (dispatch, getState) => {
        const format = (field, input) => {
            const definition = getState().components.disposal.component_field.filter(x => x.name === field)[0]

            if (definition) {
                switch (definition.component_field_display_type.name) {
                    case 'datePicker':
                        input = input.toString().trim()
                        const now = moment(new Date())
                        return new Date(moment(input).set({ 'hour': now.get('hour'), 'minute': now.get('minute'), 'second': now.get('second') }).format('YYYY-MM-DD HH:mm:ss'))
                    case 'timePicker':
                        input = input.toString().trim()
                        return new Date(moment(input).format('YYYY-MM-DD HH:mm:ss'))
                    default:
                        return input
                }
            }
            else {
                return input
            }
        }

        dispatch({
            type: types.SET_ANY_FIELD_INPUT_VALUE,
            payload: {
                prop: field,
                value: format(field, value),
                component: 'disposal'
            }
        })
    }
}

export const getVehicleDetails = (id) => {
    return {
        type: types.GET_VEHICLE_DETAILS,
        payload: {
            id: id
        }
    }
}
export const saveDisposal = (data) => {
    return {
        type: types.SAVE_DISPOSAL_DATA,
        payload: {
            id: 0,
            data: data
        }
    }
}
export const savedData = (id) => {
    if (id == 0) {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Something went wrong, your wish could not be granted!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
    else {
        return (dispatch, getState) => {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Disposal Saved Successful!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }
}

export const setVehicleDetails = (payload) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_VEHICLE_DETAILS,
            payload: {
                data: payload[0]
            }
        })
    }
}
export const getTerminationList = (searchtext) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_TERMINATION_LIST,
            payload: {
                    search:searchtext,
                    list: true
              
            }
        })
    }
}

export const setTerminationList = (payload) => {
    var data = payload.data.map((x, i) => {
        return {
                ...x,
                vehicle_fktext: x.licenseplateno
            }
    }) 
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_TERMINATION_LIST,
            payload: {
                data: data
            }
        })
    }
}
export const approveTermination = (approve) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.APPROVE_TERMINATION,
            payload: {
              
                    approve: approve
               
            }
        })
    }
}

export const approvedTermination = (id) => {    
    return (dispatch, getState) => {
        if (id) {
            dispatch({
                type: appTypes.SHOW_NOTIFICATION,
                payload: {
                    title: 'System Notification',
                    additionalText: 'Termination Approval Successful!',
                    overflowText: getState().login.fields.email.input,
                    autoHide: 10000,
                    timestamp: moment().format('h:mm A')
                }
            })
        }
    }

}