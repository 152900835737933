export const RESET_COMPONENT = 'RESET_COMPONENT'
export const SET_BILLING_VALUE_CHANGE = 'SET_BILLING_VALUE_CHANGE'
export const RESET_REFRESH_VALUES = 'RESET_REFRESH_VALUES'
export const GET_BILLING_TRACKING = 'GET_BILLING_TRACKING'
export const SET_BILLING_TRACKING = 'SET_BILLING_TRACKING'
export const GENERATE_BILLING_INVOICE_FOR_PREVIEW = 'GENERATE_BILLING_INVOICE_FOR_PREVIEW'
export const SEND_CUSTOMER_BILLING_INVOICE = 'SEND_CUSTOMER_BILLING_INVOICE'
export const GET_BILLING_INVOICE_DATA = 'GET_BILLING_INVOICE_DATA'
export const SET_BILLING_INVOICE_DATA = 'SET_BILLING_INVOICE_DATA'
export const RESET_BILLING_VALUE_CHANGE = 'RESET_BILLING_VALUE_CHANGE'
export const EXPORT_BILLING_INVOICE_LIST = 'EXPORT_BILLING_INVOICE_LIST'
export const GET_BILLING_BCC_COPY_USERS = 'GET_BILLING_BCC_COPY_USERS'
export const SET_BILLING_BCC_COPY_USERS = 'SET_BILLING_BCC_COPY_USERS'
export const SET_BILLING_BCC_COPY_SELECTED_USERS = 'SET_BILLING_BCC_COPY_SELECTED_USERS'
export const SAVE_BILLING_BCC_COPY_SELECTED_USERS = 'SAVE_BILLING_BCC_COPY_SELECTED_USERS'
