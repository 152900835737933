import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {
        case types.RESET_COMPONENT:
            return {
                ...state,
                filename: '',
                filepreview: '',
                comments: '',
                documents: [],
                date_in_for_repairs: null,
                days_in_for_repairs: 0,
                checksheet_completedby: '',
                checksheet_completeddate: null,
                completeddate: null,
                days_over: 0,
                invoice_number: '',
                odometer: '',
                supplier_inv_comments: '',
                comments: '',
                supplier_invoicenumber: '',
                supplier_inv_amount: null,
                supplier_inv_comments: ''
            }
        case types.SET_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW:
        return {
            ...state,
            filename: action.payload.filename,
            filepreview: action.payload.preview,
        }
    case types.CLEAR_BOOKING_RELEASE_FORM_DOCUMENT_PREVIEW:
        return {
            ...state,
            filename: action.payload,
            filepreview: action.payload,
        }
    case types.SET_BUSINESS_DAYS:
        return {
            ...state,
            days_in_for_repairs: action.payload.days,
            date_in_for_repairs: action.payload.dateFrom,
            completeddate: action.payload.dateTo,
            days_over: action.payload.days_over,
            supplier_inv_amount: action.payload.rate * action.payload.days_over
        }
    case types.SET_DEFLEET_FIELD_INPUT_VALUE:
        return {
            ...state,
            [action.payload.prop]: action.payload.value
        }
        default:
            return state
    }
}