


import React, { Component } from 'react'
import ReactTable from "react-table"
import "react-table/react-table.css"
import moment from 'moment'
import Icon from '../Icon/component'
import Theme from '../../theme'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import AutoComplete from '../../controls/autocomplete'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox';



import Loading from '../Loading/component'
import PdfViewer from '../PDF/component'


import * as actions from './actions'
import * as styles from './styles'

class invoiceap extends Component {




    componentDidMount() {

        window.glyco.log('The sent index')
        window.glyco.log(this.props.stepIndex)
        window.glyco.log(this.props.step)
    }

    
    invoiceapForm() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Creditor Invoice AP')}
                    subtitle={window.t.en('Select Purchase Order from the list below.')}
                style={styles.row}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                        <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Supplier Name:'}</div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                            style={styles.inputWidth}
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'autocomplete_supplier_name_id'}
                                            key={'autocomplete_supplier_name_id'}
                                            hintText={'Search supplier'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            dataSource={this.props.state.supplier}
                                            onNewRequest={
                                                (request, index) => {
                                                    this.props.actions.setSelectedAupplier(request)                                                    
                                                    //this.props.actions.getPurchaseorderBySupplierAutoCompleteEpic(request.value)
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getSupplierAutoCompleteEpic('')
                                                }
                                            }
                                            onUpdateInput={
                                                (search, dataSource, params) => {
                                                    this.props.actions.getSupplierAutoCompleteEpic(search)
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                
                            </Col>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Contra Account:'}</div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                            style={styles.inputWidth}
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'autocomplete_contra_account_id'} //accountingitem_id
                                            key={'autocomplete_contra_account_id'}//accountingitem_id
                                            hintText={'Select Contra Account Name'}//itemdescription
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'itemdescription',
                                                value: 'accountingitem_id'
                                            }}
                                            dataSource={this.props.state.accountingitem}
                                            onNewRequest={
                                                (request, index) => {
                                                    this.props.actions.setContraaccountinvoiceAutoCompleteSelectedValue(request)
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    this.props.actions.getContraaccountinvoiceAutoCompleteEpic('')
                                                }
                                            }
                                            onUpdateInput={
                                                (search, dataSource, params) => {
                                                    this.props.actions.getContraaccountinvoiceAutoCompleteEpic(search)
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                
                            </Col>
                        </Row>
                        <Row>
                        <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Purchase Order Number:'}</div>
                                    <div style={styles.fieldContent}>
                                        <AutoComplete
                                            style={styles.inputWidth}
                                            listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            id={'autocomplete_purchase_order_id'}
                                            key={'autocomplete_purchase_order_id'}
                                            hintText={'Search Purchase Order Number'}
                                            filter={AutoComplete.fuzzyFilter}
                                            openOnFocus={true}
                                            fullWidth={true}
                                            dataSourceConfig={{
                                                text: 'text',
                                                value: 'value'
                                            }}
                                            dataSource={this.props.state.purchaseorder}
                                            onNewRequest={
                                                (request, index) => {
                                                    this.props.actions.setPurchaseorderinvoiceAutoCompleteSelectedValue(request)
                                                }
                                            }
                                            onClick={
                                                (event) => {
                                                    var data={
                                                        id: this.props.state.supplier_id,
                                                        search:''
                                                    }
                                                    this.props.actions.getPurchaseOrderSearch(data)
                                                    //this.props.actions.getPurchaseorderinvoiceAutoCompleteEpic(this.props.state.supplier_id, '')
                                                }
                                            }
                                            onUpdateInput={
                                                (search, dataSource, params) => {
                                                    var data = {
                                                        id: this.props.state.supplier_id,
                                                        search: search
                                                    }
                                                    this.props.actions.getPurchaseOrderSearch(data)
                                                    //this.props.actions.getPurchaseorderinvoiceAutoCompleteEpic(this.props.state.supplier_id, search)
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                
                            </Col>                           
                            <Col xl={6}>
                            <div style={styles.fieldContainer}>
                                <div style={styles.fieldTitle}>{'Invoice Number'}</div>
                                <div style={styles.fieldContent}>
                                    <TextField
                                        id={'supplierinvoicenumber'}
                                        hintText={'Supplier Invoice Number'}
                                        fullWidth={true}
                                        multiLine={false}
                                        value={this.props.state.invoicereference}
                                        onChange={(event, value) => {
                                            this.props.actions.setValue('invoicereference', value)
                                        }}
                                    />
                                </div>
                            </div>
                           
                        </Col>

                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Supplier Invoice Total'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'supplierinvoiceamount'}
                                            hintText={'Supplier Invoice Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.supplierinvoiceamount}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('supplierinvoiceamount', value)
                                            }}
                                        />
                                    </div>
                                </div>
                               
                            </Col>
                            <Col xl={6}>
                             <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Transaction Type'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'transactiontype'}
                                            hintText={'Transaction Type'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.transactiontype}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('transactiontype', value)
                                            }}
                                        />
                                    </div>
                                </div>
                                </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Amount Excl'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'amount_excl'}
                                            hintText={'Amount Ex vat'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.selectedPO.amount_excl}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('amount_excl', value)
                                            }}
                                        />
                                    </div>
                                </div>
                               
                            </Col>
                            <Col xl={6}>
                             <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Amount Vat:'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'amount_vat'}
                                            hintText={'Amount Vat'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.selectedPO.amount_vat}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('amount_vat', value)
                                            }}
                                        />
                                    </div>
                                </div>
                                </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Discount Amount'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'amount_disc'}
                                            hintText={'Discount Amount'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.selectedPO.amount_disc}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('amount_disc', value)
                                            }}
                                        />
                                    </div>
                                </div>
                               
                            </Col>
                            <Col xl={6}>
                             <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Amount Incl'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'amount_incl'}
                                            hintText={'Amount Inclusive'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.selectedPO.amount_incl}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('amount_incl', value)
                                            }}
                                        />
                                    </div>
                                </div>
                                </Col>
                        </Row>
                    </Container>                   
                </CardContent>
            </Card>
        </div>
    }

    getPoItemsView() {
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row><Col xl={2}><br /></Col></Row>
            <Row>
                <Col xl={2} style={styles.selectedFieldName}>
                    {'Item Type'}
                </Col>
                <Col xl={2} style={styles.selectedFieldName}>
                    {'Item Name'}
                </Col>
                <Col xl={2} style={styles.numericFields}>
                    {'Quantity'}
                </Col>              
                <Col xl={2} style={styles.numericFields}>
                    {'Unit Price'}
                </Col>
                <Col xl={2} style={styles.numericFields}>
                    {'Vat'}
                </Col>
                <Col xl={2} style={styles.numericFields}>
                    {'Total Price'}
                </Col>
            </Row>
            {
                this.props.state.selectedPO.purchaseorderitem
                ? this.props.state.selectedPO.purchaseorderitem.map(x => {
                return <Row>
                    <Col xl={2}>
                        {x.itemtype}
                    </Col>
                    <Col xl={2}>
                        {x.itemname}
                    </Col>
                    <Col xl={2} style={styles.numericFieldName}>
                                {parseFloat(x.quantity).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Col>
                    <Col xl={2} style={styles.numericFieldName}>
                        {parseFloat(x.unitprice.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Col>
                    <Col xl={2} style={styles.numericFieldName}>
                        {
                            x.vat_amount
                            ? parseFloat(x.vat_amount.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            : (parseFloat(x.amount.toString().replace(/,/gi, '')) * 0.15).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

                            }
                    </Col>
                    <Col xl={2} style={styles.numericFieldName}>
                        {parseFloat(x.amount.toString().replace(/,/gi, '')).toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Col>
                </Row>
            })
            : 'No Line items Records'
            }
        </Container>
    }

    invDocument() {
        let documents = null
        window.glyco.log('this.props.state.selectedPO.purchaseorderdocument')
        window.glyco.log(this.props.state.selectedPO.purchaseorderdocument)
        try{
            documents = this.props.state.selectedPO.purchaseorderdocument.map(x => {
                window.glyco.log('x')
                window.glyco.log(x)
                return {
                    filename: x.document.filename,
                    filetype: x.document.description,
                    documenttype: x.document.description,
                }
            })

        }catch(err){
        }

        window.glyco.log('invDocument')
        window.glyco.log(documents)

        return documents
        ? <Card>
            <CardHeader
                actAsExpander={false}
                showExpandableButton={false}
                title={window.t.en('Documents')}
                subtitle={window.t.en('Documents list ')}
                style={styles.row}
            >
            </CardHeader>
            <CardContent>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {
                                documents
                                    ? <PdfViewer
                                        key={'quotation_viewer_'}
                                        documents={documents}
                                        multiple={true}
                                        height={500}
                                        edit={false}
                                    />
                                    : <Loading message={'Retrieving Purchase Order document...'} />
                            }
                        </Col>
                    </Row>
                </Container>
            </CardContent>
        </Card>
        : ''
    }

    view() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Create Supplier Invoice')}
                    subtitle={window.t.en('')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                        <Row>
                            <Col xl={12}>
                                {this.invoiceapForm()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.getPoItemsView()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.invDocument()}
                            </Col>
                        </Row>

                         
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

    confirm() {
        return <div>
            <Card>
                <CardHeader
                    actAsExpander={false}
                    showExpandableButton={false}
                    title={window.t.en('Confirm Invoice')}
                    subtitle={window.t.en('')}
                >
                </CardHeader>
                <CardContent>
                    <Container style={{ margin: 0 }} className="custom-container">
                    <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Supplier Invoice Total'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'supplierinvoiceamount'}
                                            hintText={'Supplier Invoice Total'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.supplierinvoiceamount}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('supplierinvoiceamount', value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Purchase Order Total'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'amount_incl'}
                                            hintText={'Amount Inclusive'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.selectedPO.amount_incl}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('amount_incl', value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={styles.fieldContainer}>
                                    <div style={styles.fieldTitle}>{'Balance Total'}</div>
                                    <div style={styles.fieldContent}>
                                        <TextField
                                            id={'balancetotal'}
                                            hintText={'Balance Total'}
                                            fullWidth={true}
                                            multiLine={false}
                                            value={this.props.state.selectedPO.amount_incl - this.props.state.supplierinvoiceamount}
                                            onChange={(event, value) => {
                                                this.props.actions.setValue('balancetotal', value)
                                            }}
                                        />
                                    </div>
                                </div>
                                
                              
                                <div style={styles.radiocontainer} >

                                        <div style={styles.radiotitle}>Toggle the button to either accept or reject this invoice</div>

                                        <div style={styles.fieldContainer}>
                                          
                                            <div style={styles.fieldContent}>
                                            
                                         
                                            <Checkbox 

                                                label="Toggle to accept or reject"
                                                onChange={(event,isInputChecked) => {
                                                
                                                    window.glyco.log(isInputChecked)
                                                    this.props.actions.setValue('invoice_evaluation_status', isInputChecked)
                                            }}
                                            />
                                        
                                            </div>


                                        </div>
                                        
                                        <span style={styles.offsetcommentbox} />

                                        {
                                            this.props.state.invoice_evaluation_status
                                            ? <div style={styles.fieldContainer} >
            
                                            <div style={styles.fieldTitle}>{'Reason to reject'}</div>
                                            <div style={styles.fieldContent}>
                                            
                                                <TextField
                                                    id={'invoicereject'}
                                                    value = {this.props.state.invoice_rejection_comment}
                                                    hintText={'Type your reason for rejecting this invoice'}
                                                   
                                                    fullWidth={true}
                                                    multiLine={false}
                                                 
                                                    onChange={(event, value) => {
                                                    this.props.actions.setValue('invoice_rejection_comment', value)
                                            }}
                                                    
                                                  
                                                />
                                            
                                            </div>
                                        </div>
                                        :<div/>
                                        }

                                        <span style={styles.offsetcommentbox} />
       

                                        


                                </div>

                              


                               
                       
                        <Row>

                            <Col xl={12}>
                                {}
                            </Col>
                        </Row>
                    </Container>
                </CardContent>
            </Card>
        </div>
    }

   

    render() {
        return this.props.stepIndex === 1
        ? this.confirm()
        : this.view();

    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.invoiceap }
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(invoiceap)
