import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { Container, Row, Col } from 'react-grid-system'
import { Card, CardHeader, CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import AutoComplete from '../../../controls/autocomplete'


import Icon from '../../Icon/component'

import * as actions from './actions'
import * as styles from './styles'

import _ from 'lodash'

import IconButton from '@mui/material/IconButton';

import ReactTable from "react-table"
import "react-table/react-table.css"
import * as compActions from '../../Components/actions'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

var viewinvoice = false, id = 0

class CONTRACTTERMINATION extends Component {
    componentDidMount() {
        if (this.props.state.loading) {
            this.props.actions.getComponent('contracttermination')
            this.props.actions.getTerminationList('')
        }
    }
    getCommands(row) {
        const id = row.original[this.component.component_field.filter(x => x.key)[0].name]

        let commands = []

        commands.push(
            <IconButton key={'command_view_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                onClick={
                    () => {
                        this.props.actions.setReadComponentView('view', this.component.name)

                        this.props.actions.changeDetailComponentTab(0)
                        this.props.actions.clearDetailComponentVisualisations()

                        this.props.actions.route(
                            '/view/' + this.component.name + '/' + id
                        )
                    }
                }>
                <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'pageview'} />
            </IconButton>
        )

        if (this.props.commands) {
            commands.push(
                <IconButton key={'command_edit_' + this.component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            this.props.actions.setReadComponentView('form', this.component.name)
                            this.props.actions.route('/view/' + this.component.name + '/' + id)
                        }
                    }>
                    <Icon istyle={styles.gridOptionsIcon} ihovercolor={styles.actionHoverColor} iclass={'material-icons'} iname={'mode_edit'} />
                </IconButton>
            )
        }

        return commands
    }

    getValue(row, related) {
        const field = this.component.component_field.filter(x => x.name === row.column.meta)[0]
        if (related) {
            return row.value
        }
        else if (field.name == "approved") {
            return<div>< Button className={"global_button"}
                disabled={row.original.approved ? true : false}
                label={row.original.approved ? "Approved" : "Approve"}
                primary={true}
                onClick={(event) => {

                    var obj = {
                        disposal_id: Number(row.original.disposal_id),
                        termdate: (row.original.disposaldate),
                        vehicleid: Number(row.original.vehicle_id),
                        odometer: Number(row.original.odometer),
                        contractnumber: (row.original.contractnumber),
                        contract_id: (row.original.customercontract_id),
                        user: this.props.state.user,
                        amount: row.original.invoiceamount,
                        fuel: row.original.generateinvoice,
                        earlyterm: (row.original.earlytermination  && (row.original.contracttype.includes('Full') || row.original.contracttype.includes('Manage'))) || (row.original.contracttype.includes('Full')||row.original.contracttype.includes('Manage') ) == false ? true :false
                    }
                    this.props.actions.approveTermination(obj)
                }}
                >{row.original.approved ? "Approved" : "Approve"}</Button>{' '}{row.original.approved ? ' ' :< Button className={"global_button"} variant="contained"
            disabled={row.original.approved ? true : false}
            label={'Cancel'}
            primary={true}
            onClick={(event) => {

                var obj = {
                    disposal_id: Number(row.original.disposal_id),
                    termdate: (row.original.disposaldate),
                    vehicleid: Number(row.original.vehicle_id),
                    odometer: Number(row.original.odometer),
                    contractnumber: (row.original.contractnumber),
                    contract_id: (row.original.customercontract_id),
                    user: this.props.state.user,
                    amount: row.original.invoiceamount,
                    fuel: row.original.generateinvoice,
                    earlyterm: (row.original.earlytermination  && (row.original.contracttype.includes('Full') || row.original.contracttype.includes('Manage'))) || (row.original.contracttype.includes('Full')||row.original.contracttype.includes('Manage') ) == false ? true :false
                }
                this.props.actions.cancelTermination(obj)
            }}
            >{window.t.en('Cancel')}</Button>}</div>
        }else if (field.name == "generateinvoice") {
            return <div>{row.original.contracttype == 'Short Term Rental' &&  row.original.approved ? (row.original.invoiceamount ? <TextField
            hintText={'Fuel Amount...'}
            disabled={true}
            value={row.original.invoiceamount}
            onChange={(event, value) => { }}
        /> : '') : row.original.contracttype == 'Short Term Rental' ? <Checkbox
                disabled={row.original.approved ? true : false}
                checked={row.original.generateinvoice ? true : false}
                onClick={(event) => {
                    var values = {
                        id: row.original.disposal_id,
                        value: row.original.generateinvoice ? false : true,
                        field: "generateinvoice"
                    }
                    var data = this.props.state.data
                    this.actions.setFieldValue(values, data)
                }} /> : ''} {row.original.generateinvoice ? <TextField

                    hintText={'Fuel Amount...'}
                    value={row.original.invoiceamount}
                    onChange={(event, value) => {
                        var values = {
                            id: row.original.disposal_id,
                            value: value,
                            field: "invoiceamount"
                        }
                        var data = this.props.state.data
                        this.actions.setFieldValue(values, data)
                    }}
                /> : ''}</div>
        }
        else {

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle !== null ? field.toggle.split(',') : row.value

                    return row.value !== null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getColumns(surrogate) {
        const fields = this.component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: x.related && !surrogate ? x.display : x.name,
                    filterable: false,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    style: {
                        verticalAlign: 'left',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black', //'#4A4A4A',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    Cell: (row) => (
                        this.getValue(row, x.related)
                    )
                }
            })

        if (!this.props.task && !surrogate) {
            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row)
                )
            })
        }
        else {
            if (this.props.mode === 'select' && this.props.type === 'multiple') {
            }
        }

        return columns
    }

    getGrid() {
        return <div> < Button className={"global_button"}
            secondary={true}
            label="Excel Export"
            icon={<Icon iclass={'material-icons'} iname={'cloud_download'} />}
            onClick={(event) => {
                this.props.actions.excelExport(this.component)
            }}
            ><Icon iclass={'material-icons'} iname={'cloud_download'} /> Excel Export</Button>{<TextField
            hintText={'Search by Contract/Reg Number...'}
            value={this.props.state.data.contracttermination_id.input}
            //value={this.props.state.data.customerallocation_id.input}
            onChange={(event, value) => {
                this.props.actions.setInputValue('contracttermination_id', value)
                this.props.actions.getTerminationList(value)
            }}
        />}{< Button className={"global_button"}
            label="Refresh"
           // icon={<Icon istyle={{ color: 'white' }} iclass={'material-icons'} iname={'search'} />}
            onClick={(event) => {
                event.preventDefault()
                this.props.actions.getTerminationList(this.props.state.data.contracttermination_id.input)
            }}
            >{window.t.en('Refresh')}</Button>}<ReactTable
            key={'grid_' + this.component.name + '_' + this.component.component_id}
            //className='-striped -highlight'
            data={this.props.state.data.contracttermination_id.data}
            columns={this.getColumns()}
            defaultPageSize={this.component.grid.paging.pageSize}
            manual
            onFetchData={
                (state) => {
                }
            }
            getTrProps={(state, rowInfo) => {
                return {
                    onClick: (event) => {
                        //this.props.actions.selectRow(this.component.name, rowInfo)

                        if (this.props.toggle) {
                            //this.props.actions.toggleInfoSheet()
                        }
                    },
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            onResizedChange={(newResized, event) => {
                //this.props.actions.resizeColumn(this.component.name, newResized)
            }}
            minRows={1}
            pages={this.component.grid.paging.pageCount}
            showPageJump={false}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
                {(state, render, instance) => {
                    return (
                        render()
                    )
                }}
            </ReactTable></div>
    }


    getView() {
        this.component = this.props.state
        this.actions = this.props.actions;
        return <Tabs>
            <Tab label={'Termination List'}>
                <Container style={{ margin: 0 }} className="custom-container">
                    <Row>
                        <Col xl={12}>
                            {this.getGrid()}
                        </Col>
                    </Row>
                </Container>
            </Tab>
            <Tab label={'Capture Termination'}>
                <Card style={styles.card} style={styles.row}>
                    <CardHeader
                        title={window.t.en('Customer Contract Termination ')}
                        subtitle={window.t.en('Capture Termination')}
                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                    <CardContent> <Container style={styles.whitediv}>
                        <Row>
                            <Col xl={7}>
                                <Container style={styles.whitediv}>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Registration:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <AutoComplete
                                                        listStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        id={'dll_invoice_id'}
                                                        key={'dll_invoice_id'}
                                                        hintText={'Search for vehicle...'}
                                                        filter={AutoComplete.fuzzyFilter}
                                                        openOnFocus={true}
                                                        fullWidth={true}
                                                        dataSourceConfig={{
                                                            text: 'text',
                                                            value: 'value'
                                                        }}
                                                        dataSource={this.props.state.data.customercontract_id.data}
                                                        onNewRequest={
                                                            (chosenRequest, index) => {
                                                                this.props.actions.setLookupValue("customercontract_id", chosenRequest.value, chosenRequest.text)
                                                                if (chosenRequest.value) {
                                                                    this.props.actions.getVehicleDetails(chosenRequest.value)
                                                                    this.props.actions.setInputValue('terminationdate', new Date())
                                                                    //this.props.actions.setInputValue('lastodometer', this.props.state.details.lastodometer)
                                                                }
                                                            }
                                                        }
                                                        onClick={
                                                            (event) => {
                                                                this.props.actions.getAllVehicles('')
                                                            }
                                                        }
                                                        onUpdateInput={
                                                            (search, dataSource, params) => {
                                                                this.props.actions.getAllVehicles(search)
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>   
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Last Odo Reading:'}</div>
                                            <div style={styles.fieldContent} ><TextField
                                                id={'txt_customername'}  
                                                hintText='Last Odo Reading'
                                                disabled={true}
                                                value={this.props.state.details.lastodometer}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('lastodometer', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>                                 
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Termination Odometer:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                id={'txt_customername'}  
                                                hintText='Termination Odometer'
                                                value={this.props.state.data.odometer.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('odometer', value)                                                    
                                                    this.props.actions.setInputValue('vehicle_id', this.props.state.details.vehicle_id)
                                                    this.props.actions.setInputValue('customercontract_id', this.props.state.details.customercontract_id)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Termination Date:'}</div>
                                            <div style={styles.fieldContent}>
                                            <TextField
                                                type="date"
                                                id={'txt_invoicedate'}
                                                hintText='Date'
                                                container='inline'
                                                mode='landscape'
                                                value={this.props.state.data.terminationdate.input}
                                                onChange={(event, value) => {
                                                    this.props.actions.setInputValue('terminationdate', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>                                    
                                    <Row><Col xl={9}></Col><Col xl={3}>
                                       < Button className={"global_button"} variant="contained"
                                            disabled={this.props.state.data.customercontract_id.input && this.props.state.data.terminationdate.input && this.props.state.data.odometer.input ? false : true}
                                            header={false}
                                            label={'Save Termination'}
                                            onClick={(event) => {
                                                var obj = {
                                                    values: this.props.state.data,
                                                    user: this.props.state.user
                                                }
                                                this.props.actions.saveTermination(obj)
                                            }}
                                            >{window.t.en('Save Termination')}</Button>
                                    </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xl={5} style={styles.rightpanel}>
                                <Container style={{ margin: 0 }} className="custom-container">
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Description:'}</div>
                                                <div style={styles.fieldContent}>
                                                    <TextField
                                                        disabled={true}
                                                        id={'txt_customername'} //  removed 
                                                        hintText='Description'
                                                        value={this.props.state.details.description ? this.props.state.details.description : ''}
                                                        onChange={(event, value) => {
                                                            //this.props.actions.setInputValue('customer_fktext', value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle Type:'}</div>
                                                <div style={styles.fieldContent}><TextField
                                                    disabled={true}
                                                    id={'txt_customername'} 
                                                    hintText='Vehicle Type'
                                                    value={this.props.state.details.category ? this.props.state.details.category : ''}
                                                    onChange={(event, value) => {
                                                        //this.props.actions.setInputValue('customer_fktext', value)
                                                    }}
                                                />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'M&M Code:'}</div>
                                                <div style={styles.fieldContent}><TextField
                                                    disabled={true}
                                                    id={'txt_customername'}  
                                                    hintText={'M&M Code'}
                                                    value={this.props.state.details.mmcode ? this.props.state.details.mmcode : ''}
                                                    onChange={(event, value) => {
                                                        //this.props.actions.setInputValue('customer_fktext', value)
                                                    }}
                                                />
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <div style={styles.fieldContainer}>
                                                <div style={styles.fieldTitle}>{'Vehicle First Registration Date:'}</div>
                                                <div style={styles.fieldContent}><TextField
                                                    disabled={true}
                                                    id={'txt_customername'} // fktext removed 
                                                    hintText='Vehicle First Registration Date'
                                                    value={this.props.state.details.registrationdate ? this.props.state.details.registrationdate : ''}
                                                    onChange={(event, value) => {
                                                        //this.props.actions.setInputValue('customer_fktext', value)
                                                    }}
                                                />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Year Model:'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                disabled={true}
                                                id={'txt_customername'}  
                                                hintText='Year Model'
                                                value={this.props.state.details.yearmodel ? this.props.state.details.yearmodel : ''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row><Col xl={12}>
                                        <div style={styles.fieldContainer}>
                                            <div style={styles.fieldTitle}>{'Age (Months):'}</div>
                                            <div style={styles.fieldContent}><TextField
                                                disabled={true}
                                                id={'txt_customername'} 
                                                hintText='Age'
                                                value={this.props.state.details.registrationdate ? (Number(moment(new Date()).format('YYYY')) - Number(moment(this.props.state.details.registrationdate).format('YYYY'))) * 12 : ''}
                                                onChange={(event, value) => {
                                                    //this.props.actions.setInputValue('customer_fktext', value)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                    </CardContent>
                </Card>
            </Tab>
        </Tabs>
    }

    render() {
        return (
            this.props.state.loading ? 'loading...' : this.getView()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: {
            ...state.components.contracttermination,
            details: {
                ...state.disposalcomponentview.details,
            },
            upload: {
                ...state.upload
            }, user: {
                ...state.user
            },

        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...compActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CONTRACTTERMINATION)