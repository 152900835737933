import * as props from './props'
import * as types from './types'

export default (state = props, action) => {
    switch (action.type) {        
        case types.SET_BILLING_RERUN_FLAG:
            return {
                ...state,
                billingrerun: action.payload
            }
        case types.SELECT_ALL:
            return {
                ...state,
                selectedall: action.payload
            }
        case types.LINK_INVOICES:
            return {
                ...state,
                linkinvoices: action.payload
            }
            case types.SET_DEFAULT_TAB:
                return {
                    ...state,
                    currentTab: 0,
                    mainTab: 0
                }
        case types.SET_CURRENT_TAB:
            return {
                ...state,
                currentTab: action.payload
            }
        case types.SET_MAIN_TAB:
            return {
                ...state,
                mainTab: action.payload
            }
        case types.SEND_INVOICES:
            return {
                ...state,
                sendinvoices: action.payload
            }
        case types.CUSTOM_EMAIL:
            return {
                ...state,
                customemail: action.payload
            }
        case types.BILLING_APPROVAL_LIST:
            return {
                ...state,
                approval_list: action.payload
            }
        case types.APPROVED_SELECT_ALL:
            return {
                ...state,
                approved_selectedall: action.payload
            }
        case types.DUMMY_RUN:
                return {
                    ...state,
                    dummy_run: action.payload
            }
                default:
            return state
    }
}