import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Card, CardHeader, CardContent, CardActions, CardMedia, CardTitle, CardExpandable } from '@mui/material'

import Button from '@mui/material/Button'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

//import Form from '../../Form/component'
import UserStructure from '../../UserStructure/component'
import Grid from '../grid'
import Icon from '../../Icon/component'
import Loading from '../../Loading/component'
import Checkbox from '../../../controls/checkbox'

import * as actions from './actions'
import * as styles from './styles'

class UserOptions extends Component {
    componentDidMount() {
        this.props.actions.getAllUsers()
    }

    render() {
        return this.props.state.allUsersLoading
            ? <Loading message={'Loading...'} />
            : <Card initiallyExpanded={true}>
                <CardHeader
                    avatar={<Icon iclass={'custom-icons'} iname={'organization'} />}
                    title={window.t.en('User Options')}
                    subtitle={window.t.en('Provides several options for managing your users.')}
                    actAsExpander={true}
                    showExpandableButton={true}
                />
                <CardContent expandable={true}>
                    <div style={styles.table}>
                        <div style={styles.cell}>
                           < Button className={"global_button"} variant="contained"
                                label={
                                    this.props.state.edit ? 'View Users' : 'Create User'
                                }
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleUserForm()
                                }}
                                >{this.props.state.edit ? 'View Users' : 'Create User'}</Button>

                            &nbsp;&nbsp;&nbsp;

                            < Button className={"global_button"} variant="contained"
                                label={'Manage Users'}
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.toggleManagementForm()
                                }}
                                >Manage Users</Button>
                        </div>
                        
                        <div style={{ ...styles.right, ...styles.visibility(!this.props.state.user && this.props.state.edit) }} >
                           < Button  variant="contained"
                                label={'Save Changes'}
                                primary={true}
                                disabled={!this.props.state.changed}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.saveUserManagementChanges()
                                }}
                                >Save Changes</Button>
                        </div>
                        <div style={{ ...styles.right, ...styles.visibility(this.props.state.user && this.props.state.edit) }} >
                           < Button  variant="contained"
                                label={'Save Changes'}
                                primary={true}
                                disabled={!this.props.state.changed}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.saveNewUserChanges()
                                }}
                                >Save Changes</Button>
                        </div>
                    </div>

                    <br /><br />

                    <div style={styles.visibility(this.props.state.user && !this.props.state.edit)}>
                        <Grid
                            key={'administration_user_grid'}
                            name={'user'} commands={false}
                            toggle
                        />
                    </div>

                    <div style={styles.visibility(this.props.state.user && this.props.state.edit)}>
                        {/* <Form
                            key={'administration_user_form'}
                            name={'user'}
                            related={false}
                            empty
                        /> */}
                        <UserStructure />

                        <br /><br />

                        {/*<div style={styles.commands}>
                           < Button className={"global_button"} variant="contained"
                                label="Cancel"
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.hideUserModal()
                                }}
                                >Cancel</Button>

                            &nbsp;&nbsp;&nbsp;

                           < Button className={"global_button"} variant="contained"
                                label="Save"
                                primary={true}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.actions.saveUserData()
                                }}
                                >Save User</Button>
                        </div>*/}
                    </div>

                    <div style={styles.visibility(this.props.state.manage)}>
                        <Card>
                            <CardHeader
                                title={window.t.en('Manage Users')}
                                subtitle={window.t.en('Activate, deactive and reset user passwords.')}
                                actAsExpander={false}
                                showExpandableButton={false}
                            />
                        </Card>

                        <br /><br />

                        {this.props.state.allUsers.map(x => {
                            return <div key={'all_users_' + x.user_id} style={styles.table}>
                                <div style={styles.cell}>
                                    {x.firstname + ' ' + x.lastname + ' (' + x.email + ')'}
                                </div>
                                <br />
                                <div style={styles.right}>
                                    < Button secondary={true}
                                        label={x.is_active ? 'Deactivate' : 'Activate'}
                                        icon={<Icon iclass={'material-icons'} iname={x.is_active ? 'power_settings_new' : 'eject'} />}
                                        className={x.active ? 'team-deactivate' : 'team-active'}
                                        onClick={(event) => {
                                            this.props.actions.toggleUserActivation(x.user_id)
                                        }}
                                        ><Icon iclass={'material-icons'} iname={x.is_active ? 'power_settings_new' : 'eject'} /></Button>

                                    &nbsp;&nbsp;

                                    <Button className={"global_button"} secondary={true}
                                        label="Reset Password"
                                        disabled={!x.is_active}
                                        icon={<Icon iclass={'material-icons'} iname={'https'} />}
                                        onClick={(event) => {
                                            this.props.actions.resetUserPassword(x.cognito_key)
                                        }}
                                    ><Icon iclass={'material-icons'} iname={'https'} /></Button>
{/*
                                    &nbsp;&nbsp;

                                    < Button secondary={true}
                                        label={x.isadmin ? 'Deactivate' : 'Activate'}
                                        icon={<Icon iclass={'material-icons'} iname={x.isadmin ? 'power_settings_new' : 'eject'} />}
                                        onClick={(event) => {
                                            this.props.actions.toggleUserAdmin(x.user_id)
                                        }}
                                        ><Icon iclass={'material-icons'} iname={x.isadmin ? 'power_settings_new' : 'eject'} /></Button> */}
                                </div>
                            </div>
                        })}
                    </div>
                </CardContent>
            </Card >
    }
}

const mapStateToProps = (state) => {
    return {
        state: { ...state.userOptions }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOptions)