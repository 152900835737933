import moment from 'moment'
import * as _ from 'lodash'

import * as adhocGridActions from '../Custom/AdhocGrid/actions'
import * as assignedTaskCount from './AssignedTaskCount/actions'
import * as assignedTasksActions from './AssignedTasks/actions'
import * as completedTaskCountActions from './CompletedTaskCount/actions'
import * as queuedTaskCountActions from './QueuedTaskCount/actions'
import * as queuedTasksActions from './QueuedTasks/actions'
import * as routerActions from '../Redirect/actions'
import * as workflowTimelineActions from './WorkflowTimeline/actions'
import * as signedInActions from '../SignedIn/actions'

import * as types from './types'
import * as appTypes from '../App/types'

export const clearWorkflowQueueTasks = (task) => {
    return (dispatch) => {
        dispatch({
            type: types.CLEAR_WORKFLOW_QUEUE_TASKS,
        })
    }
}

export const setFilterDate = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FILTER_DATE,
            payload: value
        })
    }
}

export const openOptions = (value, el) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_COMPONENT_OPEN_CLOSE_OPTIONS,
            payload: {
                value: value,
                el: el
            }
        })
    }
}

export const openSearch = (value, el) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_OPEN_CLOSE_SEARCH,
            payload: {
                value: value,
                el: el
            }
        })
    }
}


export const openSettings = (value, el) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_COMPONENT_OPEN_CLOSE_SETTINGS,
            payload: {
                value: value,
                el: el
            }
        })
    }
}

export const changeWorkflowTab = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CURRENT_WORKFLOW_ACTIVE_TAB,
            payload: value
        })
    }
}

export const toggleWorkflowTasks = (value, team_id, user_id) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CURRENT_WORKFLOW_TASKS_COLLAPSE,
            payload: value
        })
        
        dispatch(refreshflowTasksCount(team_id, user_id))
    }
}

export const refreshflowTasksCount = (team_id, user_id) => {
    return (dispatch, getState) => {
        dispatch(queuedTaskCountActions.getQueuedTaskCount(team_id, user_id))
        dispatch(assignedTaskCount.getAssignedTaskCount(team_id, user_id))
        dispatch(completedTaskCountActions.getCompletedTaskCount(team_id, user_id))
    }
}

export const refreshTeamData = (message) => {
    return (dispatch, getState) => {
        const executeRefresh = (team_id, current_user_id) => {
            dispatch(queuedTaskCountActions.getQueuedTaskCount(team_id, current_user_id))
            dispatch(assignedTaskCount.getAssignedTaskCount(team_id, current_user_id))
            dispatch(completedTaskCountActions.getCompletedTaskCount(team_id, current_user_id))
            dispatch(assignedTasksActions.getAssignedTasks(team_id, current_user_id, ''))
            dispatch(queuedTasksActions.getQueuedTasks(team_id, current_user_id, ''))
           

            Object.keys(getState().adhocGrid)
                .filter(x => x === 'adhoc_grid_workflow_queue_tasks_completed_tasks_' + team_id)
                .forEach(x => {
                    dispatch(
                        adhocGridActions.adhocGridGetData(
                            x,
                            '/components/workflow_queue_task/other',
                            getState().adhocGrid[x].body,
                            {
                                page: 0,
                                pageSize: 10,
                                pages: 0,
                                count: 0
                            },
                            {
                                page: 0,
                                pageSize: 10,
                                pages: 0,
                                count: 0
                            },
                            ''
                        )
                    )
                })
        }

        const notifyTeam = (event, team, current_user) => {
            switch (event) {
                case 'CANCEL_TASK':
                    dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'A workflow was cancelled, reloading data in team "' + team.title + '".',
                            overflowText: current_user.firstname + ' ' + current_user.lastname,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    break
                case 'UNASSIGN_TASK':
                    dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'A task was unassigned and is available to you in team "' + team.title + '".',
                            overflowText: current_user.firstname + ' ' + current_user.lastname,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })
                default:
                    break
            }
        }

        const notifyUser = (event, assigned_to_user_id, current_user, team) => {
            if (assigned_to_user_id !== current_user.user_id) {
                return
            }

            switch (event) {
                case 'ASSIGN_TASK':
                    dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'A task was assigned to you in team "' + team.title + '".',
                            overflowText: current_user.firstname + ' ' + current_user.lastname,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    break
                case 'REASSIGN_TASK':
                    dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'A task was reassigned to you in team "' + team.title + '".',
                            overflowText: current_user.firstname + ' ' + current_user.lastname,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    break
                case 'REJECT_TASK':
                    dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'A task was rejected and assigned to you in team "' + team.title + '".',
                            overflowText: current_user.firstname + ' ' + current_user.lastname,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    break
                case 'REQUEUE_TASK':
                    dispatch({
                        type: appTypes.SHOW_NOTIFICATION,
                        payload: {
                            title: 'System Notification',
                            additionalText: 'A task was requeued due to execution failure and is assigned to you in team "' + team.title + '".',
                            overflowText: current_user.firstname + ' ' + current_user.lastname,
                            autoHide: 10000,
                            timestamp: moment().format('h:mm A')
                        }
                    })

                    break
                default:
                    break
            }
        }

        const user = getState().user
        const accounts = getState().accounts

        //where be user details? probably still logging in at this point, ignore updating current user.
        if (!user || accounts.loading) {
            return
        }

        const ref_team_id = message.data.team_id
        const ref_user_id = message.data.user_id
        const assigned_to_team_id = message.data.assigned_to_team_id
        const assigned_to_user_id = message.data.assigned_to_user_id
        const event = message.data.event

        const current_user_id = user.user_id
        const current_user_teams = accounts.teams.map(t => t.team_id)


        //data is whack, must always return the following at least to commence team data refresh and notifications
        if (!event || !ref_team_id || !ref_user_id || !current_user_id || !current_user_teams) {
            return
        }

        //this is a workflow/task cancellation or unassignment
        if (event && ref_team_id && ref_team_id && !assigned_to_team_id && !assigned_to_user_id) {
            const team = accounts.teams.filter(x => x.team_id === ref_team_id)[0]
            const user = getState().user

            if (ref_team_id !== assigned_to_team_id && user && team && current_user_teams.includes(ref_team_id)) {
                executeRefresh(ref_team_id, current_user_id)
                notifyTeam(event, team, user)
            }
        }

        //this is a workflow task assigned to a team, but not to a user as yet
        if (event && ref_team_id && ref_team_id && assigned_to_team_id && !assigned_to_user_id && current_user_teams.includes(assigned_to_team_id)) {
            const team = accounts.teams.filter(x => x.team_id === assigned_to_team_id)[0]
            const user = getState().user

            if (team && user) {
                executeRefresh(assigned_to_team_id, current_user_id)
                notifyTeam(event, team, user)
            }

            //if the referring team is different from the assigned team, refresh their team data too
            if (ref_team_id !== assigned_to_team_id && user && current_user_teams.includes(ref_team_id)) {
                executeRefresh(ref_team_id, current_user_id)
            }
        }

        //this is a workflow task assigned, reassigned, rejected or requeued (due to task execution failure) to a user in a team.
        if (event && ref_team_id && ref_user_id && assigned_to_team_id && assigned_to_user_id && current_user_teams.includes(assigned_to_team_id)) {
            const team = getState().accounts.teams.filter(x => x.team_id === assigned_to_team_id)[0]
            const user = getState().user

            if (team && user) {
                executeRefresh(assigned_to_team_id, current_user_id)
                notifyUser(event, assigned_to_user_id, user, team)
            }

            //if the referring team is different from the assigned team, refresh their team data too
            if (ref_team_id !== assigned_to_team_id && user && current_user_teams.includes(ref_team_id)) {
                executeRefresh(ref_team_id, current_user_id)
            }
        }
    }
}

export const getWorkflowQueueTaskTeams = () => {
    return (dispatch, getState) => {
        if (!getState().workflowQueueTasks.teams.length) {
            dispatch({
                type: types.GET_WORKFLOW_QUEUE_TASK_TEAMS
            })
        }
        
    }
}

export const setWorkflowQueueTaskTeams = (teams) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKFLOW_QUEUE_TASK_TEAMS,
            payload: teams
        })
    }
}

export const toggleWorkflowView = (view) => {
    return (dispatch, getState) => {
        let curr_view = getState().workflowQueueTasks.view_workflow
        let _view = curr_view == view ? '' : view
        dispatch({
            type: types.SET_WORKFLOW_QUEUE_TASK_CURRENT_VIEW,
            payload: _view
        })
    }
}

export const setWorkflowQueueTasksSearchValue = (value) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKFLOW_QUEUE_TASKS_SEARCH_VALUE,
            payload: value
        })
    }
}

export const setWorkflowQueueTasksActiveTeam = (value) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKFLOW_QUEUE_TASKS_ACTIVE_TEAM,
            payload: value
        })
    }
}

export const setWorkflowQueueTasksDateSearch = (value) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKFLOW_QUEUE_TASKS_DATE_SEARCH,
            payload: value
        })
    }
}

 export const searchWorkflowQueueTasks = () => {
    return (dispatch, getState) => {
        const search = getState().workflowQueueTasks.value
        const filter_date = getState().workflowQueueTasks.filter_date

        window.glyco.log('> Searching for: ' + search)
        window.glyco.log('> Searching on: ' + filter_date)

        dispatch({
            type: types.SEARCH_WORKFLOW_QUEUE_TASKS,
            payload: search
        })

        const user_id = getState().user.user_id
        const teams = getState().accounts.teams
        const timelines = getState().workflowTimeline

        Object.keys(timelines)
            .filter(x => timelines[x].searchable && timelines[x].isOpen)
            .forEach(x => {
                dispatch(workflowTimelineActions.getWorkflowTimeline(x))
            })

        teams.forEach(x => {
            dispatch(queuedTasksActions.getQueuedTasks(x.team_id, user_id, search.length ? search : '', filter_date))
            dispatch(assignedTasksActions.getAssignedTasks(x.team_id, user_id, search.length ? search : '', filter_date))
        })

        Object.keys(getState().adhocGrid)
            .filter(x => x.indexOf('adhoc_grid_workflow_queue_tasks_completed_tasks_') > -1)
            .forEach(x => {
                dispatch(
                    adhocGridActions.adhocGridGetData(
                        x,
                        '/components/workflow_queue_task/other',
                        getState().adhocGrid[x].body,
                        {
                            page: 0,
                            pageSize: 10,
                            pages: 0,
                            count: 0
                        },
                        {
                            page: 0,
                            pageSize: 10,
                            pages: 0,
                            count: 0
                        },
                        search.length ? search : ''
                    )
                )
            })
    }
}

export const setWorkflowQueueTasksSearchResults = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_WORKFLOW_QUEUE_TASKS_SEARCH_RESULTS,
            payload: data
        })
    }
}

const getData = (event, team_id, user_id, data, comments, assigned_team_id, assigned_user_id,selectcomments) => {
    return {
        team_id: team_id,
        user_id: user_id,
        assigned_team_id: assigned_team_id ? assigned_team_id : undefined,
        assigned_user_id: assigned_user_id ? assigned_user_id : undefined,
        event: event ? event : 'UNKNOWN_EVENT',
        comments: comments ? comments : '',
        selectcomments: selectcomments ? selectcomments : '',
        workflow: {
            workflow_id: data['workflow#workflow_id'],
            name: data['workflow#name'],
            title: data['workflow#title']
        },
        workflow_task: {
            workflow_task_id: data['workflow_task#workflow_task_id'],
            name: data['workflow_task#name'],
            title: data['workflow_task#title']
        },
        data: data ? data : {},
        queue: {
            workflow_queue_id: data['workflow_queue_task#workflow_queue_id'],
            workflow_queue_task_id: data['workflow_queue_task#workflow_queue_task_id']
        }
    }
}

export const assignTask = (team_id, user_id, data, comments, assigned_team_id, assigned_user_id) => {
    return (dispatch) => {
        dispatch({
            type: types.ASSIGN_TASK,
            payload: getData(types.ASSIGN_TASK, team_id, user_id, data, comments, assigned_team_id, assigned_user_id)
        })
    }
}

export const reassignTask = (team_id, user_id, data, comments, assigned_team_id, assigned_user_id) => {
    return (dispatch) => {
        dispatch({
            type: types.REASSIGN_TASK,
            payload: getData(types.REASSIGN_TASK, team_id, user_id, data, comments, assigned_team_id, assigned_user_id)
        })
    }
}

export const unassignTask = (team_id, user_id, data, comments) => {
    return (dispatch) => {
        dispatch({
            type: types.UNASSIGN_TASK,
            payload: getData(types.UNASSIGN_TASK, team_id, user_id, data, comments)
        })
    }
}

export const cancelTask = (team_id, user_id, data, comments, selectcomments) => {
    return (dispatch) => {
        dispatch({
            type: types.CANCEL_TASK,
            payload: getData(types.CANCEL_TASK, team_id, user_id, data, comments, null, null, selectcomments)
        })
    }
}

export const rejectTask = (team_id, user_id, data, comments, assigned_team_id, assigned_user_id) => {
    return (dispatch, getState) => {
        //console.log('comments',comments)
        //console.log('rejectTask',data)
        //console.log('rejectTask get taskname',data['workflow_task#name'])
        try {
            if (!comments) {
              switch (data['workflow_task#name']) {
                case "tsk_fml_quote_upload_preinspection_documents":
                  comments = getState().workflowUploadPID.preinspectioncomments;
                  break;
                case "tsk_purchase_order_reveiw_and_approve_quote_tech":
                  comments = getState().poBookingApproval.comments;
                  break;
                case "tsk_purchase_order_reveiw_and_approve_quote_ops":
                  comments = getState().poBookingApprovalOps.comments;
                  break;
                case "tsk_purchase_order_reveiw_and_approve_quote_gm":
                  comments = getState().poBookingApprovalOps.comments;
                  break;
                case "tsk_purchase_order_reveiw_and_approve_quote_ceo":
                  comments = getState().poBookingApprovalCEO.comments;
                  break;
                case "tsk_rebill_upload_invoice":
                  comments = getState().poBookingRebillInvoiceUpload.comments;
                  break;
                case "tsk_rebill_review_and_approve_tech":
                  comments = getState().poRebillApprovalByTech.comments;
                  break;
                case "tsk_rebill_review_and_approve_ops":
                  comments = getState().poRebillApprovalByOps.comments;
                  break;
                case "tsk_purchase_order_close_booking":
                  comments = getState().poCloseBooking.comments;
                  break;
                case "tsk_mm_activate_contract":
                  comments = getState().activateMMContract.comments;
                  break;
                case "tsk_rebill_review_and_approve_gm":
                  comments = getState().poRebillApprovalByGM.comments;
                  break;
                case "tsk_rebill_review_and_approve_ceo":
                  comments = getState().poRebillApprovalByCEO.comments;
                  break;
                case "tsk_purchase_order_send_authorisation":
                  comments = getState().poBookingSendAuth.comments;
                  break;
                case "tsk_rebill_send_authorisation":
                  comments = getState().poBookingSendRebill.comments;
                  break;
                case "tsk_rebill_close_booking":
                  comments = getState().poCloseRebillBooking.comments;
                  break;
                case "tsk_defleet_close_booking":
                  comments = getState().defleetCloseBooking.comments;
                  break;
                case "tsk_defleet_contract_termination":
                  comments = getState().defleetContractTermination.comments;
                  break;
                case "tsk_defleet_termination_request":
                  comments = getState().defleetTerminationRequest.comments;
                  break;
                case "tsk_defleet_repair_checksheet":
                  comments = getState().defleetRepairChecksheet.comments;
                  break;
                case "tsk_defleet_disposal":
                  comments = getState().defleetDisposal.comments;
                  break;
                case "tsk_defleet_disposal_approve":
                  comments = getState().defleetDisposalApproval.comments;
                  break;
                case "tsk_defleet_disposal_approve_ceo":
                  comments = getState().defleetDisposalApprovalCEO.comments;
                  break;
                case "tsk_defleet_termination_request_approve_tech":
                  comments = getState().defleetTerminationRequestApproval.comments;
                  break;
                case "tsk_defleet_termination_request_approve_gm":
                  comments = getState().defleetTerminationRequestApproval.comments;
                  break;
                case "tsk_defleet_termination_request_approve_ceo":
                  comments = getState().defleetTerminationRequestApproval.comments;
                  break;
                case "tsk_payment_approve_invoice":
                  comments =  getState().paymentApprovalAdmin
                  break;
                case "tsk_payment_approval_finance":
                  comments =  getState().paymentApprovalFinance
                  break;
                case "tsk_payment_approval_gm":
                  comments = getState().paymentApprovalGM
                  break;
                case "tsk_payment_approval_ops":
                  comments = getState().paymentApprovalOPS
                  break;
                case "tsk_payment_approval_tech":
                  comments = getState().paymentApprovalTech
                  break;
                case "tsk_payment_release":
                  comments = getState().paymentApprovalRelease
                  break;
                case "tsk_payment_authorisation":
                  comments = getState().paymentApprovalAuthorisation
                  break;
                case "tsk_rebill_tech_payment_approval":
                case "tsk_rebill_gm_payment_approval":
                case "tsk_rebill_coo_payment_approval":
                case "tsk_defleet_tech_payment_approval":
                case "tsk_defleet_gm_payment_approval":
                case "tsk_defleet_coo_payment_approval":
                case "tsk_purchase_order_tech_payment_approval":
                case "tsk_purchase_order_gm_payment_approval":
                case "tsk_purchase_order_coo_payment_approval":
                  comments = getState().doaGenericApproval.comments;
                  break;
                case "tsk_defleet_upload_checksheet":
                  comments = getState().defleetUploadChecksheet.comments.value;
                  break;
                case "tsk_rebill_accept_customer_rebill":
                  comments = getState().poBookingAcceptRebill.comments;
                  break;
                case "tsk_defleet_accept_customer_rebill":
                  comments = getState().defleetAcceptRebill.comments;
                  break;
                case "tsk_defleet_rebill_dispute":
                  comments = getState().defleetRebillDispute.comments;
                  break;
                case "tsk_defleet_review_and_approve_tech":
                  comments = getState().defleetQuoteApproval.comments;
                  break;
                case "tsk_defleet_review_and_approve_ops":
                  comments = getState().defleetQuoteApprovalOPS.comments;
                  break;
                case "tsk_defleet_review_and_approve_gm":
                  comments = getState().defleetQuoteApprovalGM.comments;
                  break;
                case "tsk_defleet_review_and_approve_ceo":
                  comments = getState().defleetQuoteApprovalCEO.comments;
                  break;
                case "tsk_str_quote_accept_customer_str_quote":
                  comments = getState().acceptCustomerSignedSTR.comments;
                  break;
                case "tsk_fml_quote_upload_pod_documents":
                  comments = getState().workflowUploadPOD.comments;
                  break;
                case "tsk_fml_quote_waiting_vehicle_delivery":
                  comments = getState().workflowVehicleDeliveryConfirm.comments;
                  break;
                default:
                    comments = getState().reassignTask.comments;
                    break;
              }
            }
          } catch (error) {
            //console.log("rejectTask error", error);
          }

          
        //console.log("rejectTask comments", comments);
        let reject_data = getData(types.REJECT_TASK, team_id, user_id, data, comments, assigned_team_id, assigned_user_id)
        //console.log("rejectTask reject_data", reject_data);
        dispatch({
            type: types.REJECT_TASK,
            payload: reject_data
        })
    }
}

export const handleTask = (team_id, user_id, data) => {
    return (dispatch) => {
        dispatch({
            type: types.HANDLE_TASK,
            payload: getData(types.HANDLE_TASK, team_id, user_id, data)
        })

        let route

        if (data['workflow_task_type#name'] == 'approval') {
            route = '/workflow/' + data['workflow#name'] + '/' + data['workflow_task#name'] + '/' + team_id + '/' + user_id + '/' + data['workflow_task_type#name']
        }
        else {
            route = '/workflow/' + data['workflow#name'] + '/' + data['workflow_task#name'] + '/' + data['workflow_task_type#name']
        }

        dispatch(routerActions.route(route))
    }
}