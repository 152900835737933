import * as types from './types'
import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'

export const setValue = (prop, value) => {
    return (dispatch, getState) => {       
            dispatch({
                type: types.SET_BILLING_VALUE_CHANGE,
                payload: {
                    prop: prop,
                    value: value
                }
            })
    }
}
export const reset = () => {
    return (dispatch, getState) => {       
            dispatch({
                type: types.RESET_REFRESH_VALUES,
            })
    }
}
export const getCISContactsAutoCompleteItems = (search) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.GET_CIS_CONTACTS_AUTOCOMPLETE_DATA,
            payload: {
                search: search,
            }
        })
    }
}
export const setCISContactsAutoCompleteItems = (response) => {
    //console.log('setCISContactsAutoCompleteItems >>>>>>>>>>>>>>>>>>>.',response)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_CIS_CONTACTS_AUTOCOMPLETE_DATA,
            payload: response.data
        })
    }
}
export const setSelectedCISContactList = (data) => {

    //console.log('setSelectedCISContactList >>>>>>>>>>>>>>>>>>>>>>>>>> ', data)
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_SELECTED_CIS_CONTACTS_DATA,
            payload: data
        })
    }
}
