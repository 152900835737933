import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button'
// import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton';

import Card from '../../controls/card'
import TextField from '../../controls/textField'
import Section from '../../controls/section'
import DateControl from '../../controls/dateControl'
import AutoComplete from '../../controls/autocomplete'

import Icon from '../Icon/component'
import * as config from '../../config'
import * as styles from './styles'
import * as actions from './actions'
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
// String.prototype.splice = function (idx, rem, str) {
//     return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem))
// }
class QuoteEdit extends Component {

    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         open: false,
    //         checked: false,
    //         ticked: false,
    //         accessories: []
    //     };
    // }

    // handleOpen = () => {
    //     this.setState({ open: true });
    // };

    // handleClose = () => {
    //     this.setState({ open: false });
    // };
    // handleTick = (item, ticked) => {
    //     this.setState({ ticked: !this.state.ticked });
    //     this.setState({ accessories: [...this.state.accessories, item] });

    //     // let arrayList = []
    //     // if(ticked === true){
    //     //     this.setState({ accessories: [...this.state.accessories,item] });
    //     // }else{
    //     //     for(let acc of this.state.accessories){
    //     //         arrayList = acc.filter(x => x.item_id != item.item_id)
    //     //     }
    //     //     this.setState({ accessories: arrayList });
    //     //     
    //     // }
    // }


    // componentDidMount() {
    //     if (this.props.workflow === 'customer_fml_quote') {
    //         this.props.actions.setupWorkflowTaskInput(
    //             this.props.state.component,
    //             this.props.workflow,
    //             this.props.task,
    //             this.props.workflow_queue_data
    //         )
    //     }
    // }

    // updateCheck() {
    //     this.setState((oldState) => {
    //         return {
    //             checked: !oldState.checked,
    //         };
    //     });
    // }

    getLayout(data) {
        return <Container style={{ margin: 0 }} className="custom-container">
        <Row>
            <Col xl={12}>
                <div style={styles.fieldContainer}>
                    <div style={styles.fieldContent}>
                        <img style={styles.vehicle} src={config.system.cdn + '/' + (data.mmcode.imagefilename !== null ? data.mmcode.imagefilename : 'unknown.png')} />
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xl={12}>
            <Section
                title={data.mmcode.make + ' - ' + data.mmcode.mmcode} // + this.props.state.customer.selected[0].original.parentaccount + '')}
                subtitle={data.mmcode.cdescription}
                />
                </Col>
        </Row>
        <Row>
            <Col xl={12}>
                <br></br>
                </Col>
        </Row>
        <Row>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'retailprice'}
                            value={data.retailprice}
                            style={styles.textBox}
                            label = {'Retail Price:'}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled={this.props.quoteadjustments == 'Yes' ? false : true  }
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'retailprice', value: event.target.value, inx: this.props.inx})
                            }}
                            onBlur={(event) => {
                                //this.props.actions.calcValues(component)
                            }}
                        />

            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'this.field.discount.name'}
                            value={data.discount}
                            style={styles.textBox}
                            label = {'Discount:'}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled={this.props.quoteadjustments == 'Yes' ? false : true  }
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'discount', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('discount', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            //     //this.props.actions.decimalFormat(this.field.discount, this.props.inx, event.target.value)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'vehicleprice'}
                            label = {'Vehicle Price'}
                            value={parseFloat(data.vehicleprice)}
                            style={styles.textBox}
                            fullWidth={true}
                            disabled={true}
                            inputStyle={styles.textBoxInput}
                        />

            </Col>
        </Row>
        <Row>
            <Col xl={4}>

                        <TextField
                            variant="outlined" 
                            id={'rv'}
                            value={data.rv}
                            label={'Residual Value'}
                            fullWidth={true}
                            style={styles.textBox}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'rv', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('rv', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            // }}
                        />

            </Col>
            <Col xl={4}>
                        <TextField
                        label={'Residual Amount:'}
                            variant="outlined" 
                            id={'rv_amount'}
                            value={parseFloat(data.rv_amount).toLocaleString()}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled
                        // onChange={(event, value) => {
                        //     this.props.actions.setValue('rv_amount, this.props.inx, value)
                        // }}
                        // onBlur={(event) => {
                        //     this.props.actions.calcValues(component)
                        // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'deliveryperiod'}
                            label={'Delivery Period:'}
                            value={data.deliveryperiod}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('deliveryperiod', this.props.inx, event.target.value)
                            // }}
                        />
            </Col>
        </Row>
        <Row>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'primerate'}
                            label={'Prime Rate:'}
                            value={data.primerate}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled={true}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'primerate', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('primerate', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'deviation'}
                            label={'Interest Adjustment:'}
                            value={data.deviation}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'deviation', value: event.target.value, inx: this.props.inx})
                            }}
                            //disabled={true}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('deviation', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'interestrate'}
                            label={'Interest Rate:'}
                            fullWidth={true}
                            value={data.interestrate}
                            disabled={true}
                            style={styles.textBox}
                            inputStyle={styles.textBoxInput}
                        />
            </Col>
        </Row>

        <Row>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'distancepermonth'}
                            value={data.distancepermonth}
                            style={styles.textBox}
                            fullWidth={true}
                            label={'Distance/Month:'}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'distancepermonth', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('maintcpk', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            // }}
                        />

            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'maintcpk'}
                            value={data.maintenancecpk}
                            label={'Normal Maintenance CPK:'}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'maintenancecpk', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('maintcpk', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'maintcpk'}
                            value={data.escalated_cpk}
                            label={'Escalated Maintenance CPK:'}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'escalated_cpk', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('maintcpk', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'tyrecpk'}
                            label={'Tyre CPK:'}
                            value={data.tyrescpk}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'tyrescpk', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('tyrecpk', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            // }}
                        />
            </Col>
        </Row>
        {
            data.accessory_list.length
                ? <Row>
            <Col xl={12}>
                &nbsp;<Divider />
                &nbsp;
                </Col>
                <Col xl={12}>
                
                <Section
                    title={window.t.en('Accessories')} // + this.props.state.customer.selected[0].original.parentaccount + '')}
                    subtitle={'Quoted accessory list'}
                    />
                    </Col>
            </Row>
            : ''
        }
        <Row>
            <Col xl={12}>
                <br></br>
                </Col>
        </Row>
        {
            data.accessory_list
                ? data.accessory_list
                    .map((x, i) => {
                        console.log('accessory_listx', x)
                        return <Row>
                            <Col xl={4}>
                                <TextField
                                            variant="outlined" 
                                    id={'accessories_' + i + '_' + x.itemtype_id}
                                    value={x.supplier}
                                    style={styles.textBox}
                                    fullWidth={true}
                                    label={'Accessory Supplier'}
                                    disabled
                                    inputStyle={styles.textBoxInput}
                                />
                            </Col>
                            <Col xl={4}>
                                <TextField
                                    variant="outlined" 
                                    id={'accessories_' + i + '_' + x.itemtype_id}
                                    value={x.quantity}
                                    style={styles.textBox}
                                    fullWidth={true}
                                    label={'Quantity'}
                                    inputStyle={styles.textBoxInput}
                                    disabled={true}

                                />
                            </Col>
                            <Col xl={4}>
                                <TextField
                                            variant="outlined" 
                                    id={'accessories_' + i + '_' + x.itemtype_id}
                                    value={x.totalprice}
                                    style={styles.textBox}
                                    fullWidth={true}
                                    label={x.accessory}
                                    inputStyle={styles.textBoxInput}
                                    onChange={(event, value) => {
                                        this.props.actions.setVehicleInput({prop: 'accessory_list', value: event.target.value, inx: this.props.inx, item: i})
                                    }}

                                />
                            </Col>
                        </Row>
                    })
                : ''
        }
        {
            data.accessory_list.length
                ?  <Row>
                <Col xl={12}>
                    <TextField
                        variant="outlined" 
                        id={'accessory_tot'}
                        label={'Accessory Total:'}
                        disabled={true}
                        style={styles.textBox}
                        fullWidth={true}
                        inputStyle={styles.textBoxInput}
                        value={parseFloat(data.accessory_tot)}
                    />
                </Col>
            </Row>
            : ''
        }
        {
            data.sundry_list.length
                ?  <Row>
            <Col xl={12}>
                &nbsp;<Divider />
                &nbsp;
                </Col>
                <Col xl={12}>
                
                <Section
                    title={window.t.en('Sundry')} // + this.props.state.customer.selected[0].original.parentaccount + '')}
                    subtitle={'Quoted sundry list'}
                    />
                    </Col>
            <Col xl={12}>
                <br></br>
                </Col>
            </Row>
            : ''
        }
        {
            data.sundry_list
                ? data.sundry_list
                    .map((x, i) => {
                        console.log('sundry_listx', x)
                        return <Row>
                            
                            <Col xl={4}>
                                <TextField
                                            variant="outlined" 
                                    id={'accessories_' + i + '_'}
                                    value={x.supplier}
                                    style={styles.textBox}
                                    fullWidth={true}
                                    label={'Sundry Supplier'}
                                    inputStyle={styles.textBoxInput}
                                    disabled={true}

                                />
                            </Col>
                            <Col xl={4}>
                                <TextField
                                    variant="outlined" 
                                    id={'accessories_' + i + '_'}
                                    value={x.quantity}
                                    style={styles.textBox}
                                    fullWidth={true}
                                    label={'Quantity'}
                                    inputStyle={styles.textBoxInput}
                                    disabled={true}

                                />
                            </Col>

                            <Col xl={4}>
                                        <TextField
                                                    variant="outlined" 
                                            id={'sundries_' + x.sundry}
                                            value={x.totalprice}
                                            label={(x.sundry ? x.sundry : 'Sundry') + ':'}
                                            style={styles.textBox}
                                            fullWidth={true}
                                            inputStyle={styles.textBoxInput}
                                            disabled
                                            onChange={(event, value) => {
                                                this.props.actions.setVehicleInput({prop: 'sundry_list', value: event.target.value, inx: this.props.inx, item: i})
                                            }}
                                        />
                            </Col>
                            <Col xl={4}>

            </Col>
                        </Row>
                        
                    })
                : ''
        }
        
        {
            data.sundry_list.length
                ?  <Row>
                <Col xl={12}>
                    <TextField
                        variant="outlined" 
                        id={'sundry_tot'}
                        label={'Sundry Total:'}
                        disabled={true}
                        style={styles.textBox}
                        fullWidth={true}
                        inputStyle={styles.textBoxInput}
                        value={parseFloat(data.sundry_tot)}
                    />
                    </Col>
            </Row>
            : ''
        }
        {
            data.service_list.length
                ?  <Row>
            <Col xl={12}>
                &nbsp;<Divider />
                &nbsp;
                </Col>
                <Col xl={12}>
                
                <Section
                title={window.t.en('Services')} 
                subtitle={'Quoted service list'}/> 
                    </Col>
            <Col xl={12}>
                <br></br>
                </Col>
            </Row>
            : ''
        }
        {
            data.service_list
                ? data.service_list
                    .map((x, i) => {
                        console.log('service_listx', x)
                        return <Row key={'service_list_item_row_' + this.props.inx + '_' + i + '_' + x.item_id}>
                            
                            
                            <Col xl={4}>
                                <TextField
                                            variant="outlined" 
                                    id={'service_' + i + '_'}
                                    value={x.supplier}
                                    style={styles.textBox}
                                    fullWidth={true}
                                    label={'Sundry Supplier'}
                                    inputStyle={styles.textBoxInput}
                                    disabled={true}

                                />
                            </Col>
                            <Col xl={4}>
                                <TextField
                                    variant="outlined" 
                                    id={'service_' + i + '_'}
                                    value={x.quantity}
                                    style={styles.textBox}
                                    fullWidth={true}
                                    label={'Quantity'}
                                    inputStyle={styles.textBoxInput}
                                    disabled={true}

                                />
                            </Col>
                            <Col xl={4}>
                                <TextField
                                    variant="outlined" 
                                    id={'service_list_item_' + this.props.inx + '_' + i + '_' + x.item_id}
                                    key={'service_list_item_' + this.props.inx + '_' + i + '_' + x.item_id}
                                    value={x.totalprice.toLocaleString('en-EG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    style={styles.textBox}
                                    fullWidth={true}
                                    label={x.service + '.:'}
                                    inputStyle={styles.textBoxInput}
                                    disabled
                                    onChange={(event, value) => {
                                        this.props.actions.setVehicleInput({prop: 'service_list', value: event.target.value, inx: this.props.inx, item: i})
                                    }}
                                />
                            </Col>
                        </Row>
                    })
                : ''
        }
        <Row>
            <Col xl={12}>
                &nbsp;<Divider />
                &nbsp;
                </Col>
        </Row>
        {
            data.service_list.length
                ?   <Row>
            <Col xl={12}>
                <TextField
                    variant="outlined" 
                    id={'service_tot'}
                    label={'Service Total:'}
                    disabled={true}
                    style={styles.textBox}
                    fullWidth={true}
                    inputStyle={styles.textBoxInput}
                    value={parseFloat(data.service_tot)}
                />
            </Col>
        </Row>
        : ''
    }
        <Row>
            <Col xl={12}>
               
            <Section
                title={window.t.en('Rental Breakdown')} // + this.props.state.customer.selected[0].original.parentaccount + '')}
                subtitle={'Rental component details'}/>
                </Col>
        </Row>
        <Row>
            <Col xl={12}>
                <br></br>
                </Col>
        </Row>
        <Row>
            <Col xl={12}>
                <br></br>
                </Col>
        </Row>
        <Row>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'finance'}
                            label={'Finance:'}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled={true}
                            value={parseFloat(data.finance)}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'maint'}
                            label={'Normal Monthly Maintenance:'}
                            style={styles.textBox}
                            inputStyle={styles.textBoxInput}
                            fullWidth={true}
                            disabled={true}
                            value={data.normalmaintenance}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('maint', this.props.inx, event.target.value)
                            //     this.props.actions.calcCpk(this.field.maintcpk, this.props.inx, data.distancepermonth, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            //     this.props.actions.decimalFormat(this.field.maint, this.props.inx, event.target.value)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'maint'}
                            label={'Escalated Monthly Maintenance:'}
                            style={styles.textBox}
                            inputStyle={styles.textBoxInput}
                            fullWidth={true}
                            disabled={true}
                            value={data.maintenance}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('maint', this.props.inx, event.target.value)
                            //     this.props.actions.calcCpk(this.field.maintcpk, this.props.inx, data.distancepermonth, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            //     this.props.actions.decimalFormat(this.field.maint, this.props.inx, event.target.value)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'tyres'}
                            label={'Monthly Tyres:'}
                            style={styles.textBox}
                            inputStyle={styles.textBoxInput}
                            fullWidth={true}
                            disabled={true}
                            value={data.tyres}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('tyres', this.props.inx, event.target.value)
                            //     this.props.actions.calcCpk(this.field.tyrecpk, this.props.inx, data.distancepermonth, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            //     this.props.actions.decimalFormat(this.field.tyres, this.props.inx, event.target.value)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'licence'}
                            style={styles.textBox}
                            label={'License Fee:'}
                            inputStyle={styles.textBoxInput}
                            fullWidth={true}
                            value={data.licence}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'licence', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('licence', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            //     this.props.actions.decimalFormat(this.field.licence, this.props.inx, event.target.value)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'service'}
                            label={'Service:'}
                            style={styles.textBox}
                            inputStyle={styles.textBoxInput}
                            fullWidth={true}
                            value={data.service}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'service', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('service', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            //     this.props.actions.decimalFormat(this.field.service, this.props.inx, event.target.value)
                            // }}
                        />
            </Col>

            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'adminfee'}
                            label={'Admin Fee:'}
                            style={styles.textBox}
                            inputStyle={styles.textBoxInput}
                            fullWidth={true}
                            value={data.adminfee}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'adminfee', value: event.target.value, inx: this.props.inx})
                            }}
                            // onChange={(event, value) => {
                            //     this.props.actions.setValue('adminfee', this.props.inx, event.target.value)
                            // }}
                            // onBlur={(event) => {
                            //     this.props.actions.calcValues(component)
                            //     this.props.actions.decimalFormat(this.field.adminfee, this.props.inx, event.target.value)
                            // }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'roi'}
                            label={'Return on Investment %:'}
                            style={styles.textBox}
                            inputStyle={styles.textBoxInput}
                            fullWidth={true}
                            value={parseFloat(data.roipercentage)}
                            onChange={(event, value) => {
                                this.props.actions.setVehicleInput({prop: 'roipercentage', value: event.target.value, inx: this.props.inx})
                            }}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'roi'}
                            label={'Return on Investment:'}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled={true}
                            value={parseFloat(data.returnoninvestment)}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'monthly_excl'}
                            label={'Monthly Subtotal:'}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled={true}
                            value={parseFloat(data.monthly_excl)}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'monthly_vat'}
                            label={'Vat:'}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled={true}
                            value={parseFloat(data.monthly_vat)}
                        />
            </Col>
            <Col xl={4}>
                        <TextField
                            variant="outlined" 
                            id={'monthly_incl'}
                            label={'Monthly Rental Total:'}
                            style={styles.textBox}
                            fullWidth={true}
                            inputStyle={styles.textBoxInput}
                            disabled={true}
                            value={parseFloat(data.monthly_incl)}
                        />
            </Col>
        </Row>

    </Container>

    }

    getSteps(data) {
        return <div>
                <h4>{'Quote Number ' + data.quotenumber} </h4>
                <hr />
                {this.getLayout(data)} 
            </div>
    }

    render() {
        //console.log('this.props.data', this.props.data)
        return <Container style={{ margin: 0 }} className="custom-container">
            <Row>
                <Col xl={12}>
                    {this.getSteps(this.props.data)}
                </Col>
            </Row>

        </Container>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.quoteEdit
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteEdit)