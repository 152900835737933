export const grid = {
    errorText: 'Please select contract',
    pageSize: 12,
    data: [],
    loading: true,
    valid: false,
    empty: true,
}

export const gridFields = [
    {
        name: 'contractnumber',
        label: 'Contract Number',
        type: 'label',
        width: 200,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'maintenanceclass',
        related: null,
        text: null,
        value: null
    },
    {
        name: 'contracttype',
        display: 'contracttype.contracttype',
        label: 'Contract Type',
        type: 'label',
        width: 250,
        align: 'left',
        hide: false,
        read_only: false,
        component: 'maintenanceitem',
        related: 'maintenanceclass_id.Class_Id',
        text: null,
        value: null
    },
    {
        name: 'licenseplateno',
        label: 'Vehicle',
        type: 'label',
        width: 150,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'makemodelyear',
        label: 'Make Model',
        type: 'label',
        width: 150,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'startdate',
        label: 'Start Date',
        type: 'date',
        width: 130,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'terminationdate',
        label: 'End Date',
        type: 'date',
        width: 150,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'distancepermonth',
        label: 'Inclusive Kms',
        type: 'label',
        width: 150,
        align: 'right',
        hide: false,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'period',
        label: 'Period (M)',
        type: 'label',
        width: 150,
        align: 'right',
        hide: true,
        read_only: false,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'customername_fktext',
        display: 'customer.customername',
        label: 'Customer Name',
        type: 'label',
        width: 120,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'monthly_incl',
        label: 'Monthly Rental',
        type: 'label',
        width: 150,
        align: 'right',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'utilisation_status',
        label: 'Suggestion',
        type: 'label',
        width: 150,
        align: 'left',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'recomended_kms',
        label: 'Suggeseted KMs',
        type: 'label',
        width: 180,
        align: 'left',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    },
    {
        name: 'recomended_period',
        label: 'Suggeseted Period',
        type: 'label',
        width: 180,
        align: 'left',
        hide: false,
        read_only: true,
        related: null,
        text: null,
        value: null
    }
]

export const fields = {
    customerFacilityExpired: {
        customErrorText: "",
        errorText: "The selected contract's customer requires a facility review due to the expiration of the facility: Please Contact System Administrator/Immediate Supervisor",
        value: false,
        valid: false,
        empty: true,
        state: 'getState().contractSelectionGrid?.original?.length > 0 ? !(new Date(getState().contractSelectionGrid?.original[0].customer?.facility_expiry_date) < new Date()) : true'
    },
    customerFacilityUtilised: {
        errorText: "The selected contract's customer has exceeded their facility utilised: Please Contact System Administrator/Immediate Supervisor",
        value: false,
        valid: false,
        empty: true,
        state: 'getState().contractSelectionGrid?.original?.length > 0 ? !(parseFloat(getState().contractSelectionGrid?.original[0].customer?.facilityutilised) > parseFloat(getState().contractSelectionGrid?.original[0].customer?.facilitylimit)) : true'
    },
    customerHighRisk: {
        errorText: "The selected contract's customer has a high risk rating therefore no contract deviations can be done: Please Contact System Administrator/Immediate Supervisor",
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractSelectionGrid?.original?.length > 0 ? getState().contractSelectionGrid?.original[0].customer.riskrating_id !== 1 : true",
    },
    contract: {
        errorText: 'Please select a contract',
        value: false,
        valid: false,
        empty: true,
        state: "getState().contractSelectionGrid?.original?.length > 0",
    },
}

export const is_bulk = false
export const bulk_rows = []
export const original = null