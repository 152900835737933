import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import IconButton from '@mui/material/IconButton';
import Icon from '../Icon/component'

import ReactTable from "react-table"
import "react-table/react-table.css"

import Theme from '../../theme'

import * as actions from './actions'
import * as styles from './styles'
import * as readComponentActions from '../ReadComponent/actions'
import * as infoSheetActions from '../InfoSheet/actions'
import * as redirectActions from '../Redirect/actions'
import * as masterComponentViewActions from '../../views/MasterComponentView/actions'

class WorkflowGridComponent extends Component {

    getCommands(row, component) {
        
        const id = row.original[component.component_field.filter(x => x.key)[0].name]
        let commands = []

            commands.push(
            
                <IconButton key={'command_view_' + component.name + '_' + id} style={styles.iconButton} iconStyle={styles.action}
                    onClick={
                        () => {
                            //console.log('selectRowxxx view',component.name, row)
                            this.props.actions.selectRow(component.name, row)
                            this.props.actions.toggleView('view',this.props.step, component.name)
                        }
                    }>
                    <Icon istyle={{ fontSize: '22px', color: '#253053' }} iclass={'material-icons'} iname={'pageview'} />
                </IconButton>
            )

        return commands
    }

    getValue(row, component, _field) {
        if (_field.related) {
            return row.value
        }
        else {
            const field = component.component_field.filter(_field => _field.name === row.column.meta)[0]

            switch (field.component_field_data_type.name) {
                case 'date':
                    return moment(row.value).format('YYYY-MM-DD')
                case 'dateTime':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'time':
                    return moment(row.value).format('HH:mm ZZ')
                case 'timestamp':
                    return moment(row.value).format('YYYY-MM-DD HH:mm ZZ')
                case 'boolean':
                    const toggle = field.toggle && field.toggle && field.toggle !== null ? field.toggle.split(',') : field.value
                    return row.value != null
                        ? row.value
                            ? toggle[0]
                            : toggle[1]
                        : 'Not Set'
                case 'decimal':
                    return !isNaN(row.value)
                        ? Number(row.value).toFixed(2).toString()
                        : row.value
                default:
                    return row.value
            }
        }
    }

    getOptions(field) {
        const component = field.display.split('.')[0]
        const prop = field.display.split('.')[1]
        const data = this.props.state.component.grid.data
        let arr = data.reduce((x, y) => x.includes(y) ? x : [...x, y], [])
        let options = arr.map(x => {
            return <option value={x[component][field.name]}>{x[component][prop]}</option>
        })
        return options
    }

    getColumns(component) {
        const fields = component.component_field
            .filter(x => x.grid)
            .sort((x, y) => x.order - y.order)

        let columns =
            fields.map(x => {
                return {
                    meta: x.name,
                    Header: window.t.en(x.title),
                    accessor: (x.related) ? x.display : x.name,
                    id: (x.related) ? x.display : x.name,
                    width: x.width ? x.width : undefined,
                    headerStyle: {
                        fontSize: '13px',
                        fontWeight: 'bold', //x.bold ? 'bold' : 'normal',
                        //backgroundColor: x.highlight ? styles.highlight : '',
                    },
                    style: {
                        verticalAlign: 'middle',
                        //backgroundColor: x.highlight ? styles.highlight : '',
                        fontSize: 12,
                        letterSpacing: 0,
                        color: 'black', //'#4A4A4A',
                        fontWeight: x.bold ? 'bold' : 'normal',
                        textAlign: x.component_field_display_type.name !== 'autoComplete' && (x.component_field_data_type.name === 'decimal' || x.component_field_data_type.name === 'integer' || x.component_field_data_type.name === 'bigInteger')
                            ? 'right'
                            : 'left'
                    },
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                        <input
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                height: 25,
                                border: '1px solid #E6E6E6',
                                borderRadius: 25
                            }}
                        />
                    ),
                    Cell: (row) => (
                        this.getValue(row, component, x)
                    )
                }
            })
            
            columns.unshift({
                //Header: '',
                accessor: 'options',
                filterable: false,
                maxWidth: 100,
                width: 100,
                Cell: (row) => (
                    this.getCommands(row, component)
                
                )
            })

        return columns
    }

    getGrid(component) {
        return <ReactTable
            key={'grid_' + component.name + '_' + component.component_id}
            //className='-striped -highlight'
            data={component.grid.data}
            columns={this.getColumns(component)}
            defaultPageSize={component.grid.paging.pageSize}
            filterable={true}
            loading={component.grid.loading}
            manual
            onFetchData={
                (state) => {
                    this.props.actions.getComponentGridData(component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            getTrProps={(state, rowInfo) => {
                console.log('getTrPropsx sel', this.component.selected)
                console.log('getTrPropsx row', rowInfo)
                return {
                    onClick: (event) => {
                        //console.log('shosho selectRowx',rowInfo)
                        
                        this.props.actions.selectRow(component.name, rowInfo)

                    },
                    // style: {
                    //     background: component.selected.length
                    //         ? component.selected.filter(x => x.index === rowInfo.index)[0] ? Theme.palette.primary1Color : 'white'
                    //         : 'white',
                    //     color: component.selected.length
                    //         ? component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                    //         : 'black'
                    // }
                    style: {
                        background: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? '#FAFAFA' : 'white' //Theme.palette.primary1Color
                            : 'white',
                        color: this.component.selected && rowInfo
                            ? this.component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            minRows={1}
            pages={component.grid.paging.pageCount}
            showPageJump={true}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }

    render() {
        const component = this.props.component
            ? this.props.component
            : this.props.state.component

        return <ReactTable
            key={'grid_' + component.name + '_' + component.component_id}
            //className='-striped -highlight'
            data={component.grid.data}
            columns={this.getColumns(component)}
            defaultPageSize={component.grid.paging.pageSize}
            filterable={true}
            loading={component.grid.loading}
            manual
            onFetchData={
                (state) => {
                    this.props.actions.getComponentGridData(component, this.props.link, state, this.props.filter, this.props.id)
                }
            }
            getTrProps={(state, rowInfo) => {
                console.log('getTrPropsx sel', component.selected)
                console.log('getTrPropsx row', rowInfo)
                return {
                    onClick: (event) => {
                        //console.log('shosho selectRowx 2',rowInfo)
                        console.log('selectRowxxx select',component.name, rowInfo)
                        console.log('selectRowxxx confirm',this.props.step, component.name)
                        this.props.actions.selectRow(component.name, rowInfo)
                        this.props.actions.confirmSelection(this.props.step, component.name)

                    },
                    style: {
                        background: component.selected.length && rowInfo
                            ? component.selected.filter(x => x.index === rowInfo.index)[0] ? Theme.palette.primary1Color : 'white'
                            : 'white',
                        color: component.selected.length && rowInfo
                            ? component.selected.filter(x => x.index === rowInfo.index)[0] ? 'white' : 'black'
                            : 'black'
                    }
                }
            }}
            minRows={1}
            pages={component.grid.paging.pageCount}
            showPageJump={true}
            showPaginationTop={false}
            style={{
                height: '100%'
            }}
        >
            {(state, render, instance) => {
                return (
                    render()
                )
            }}
        </ReactTable>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state,
            component: {
                ...state.components[ownProps.name]
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, ...redirectActions, ...readComponentActions, ...masterComponentViewActions, ...infoSheetActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowGridComponent)