export const CONFIRM_GENERATE_CHECKSHEET = 'CONFIRM_GENERATE_CHECKSHEET'
export const GENERATE_DEFLEET_CHECKSHEET = 'GENERATE_DEFLEET_CHECKSHEET'
export const SET_DEFLEET_CHECKSHEET = 'SET_DEFLEET_CHECKSHEET'
export const RESET_THIS_COMPONENT = 'RESET_THIS_COMPONENT'
export const SET_TASK_NUMBER = 'SET_TASK_NUMBER'
export const SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUE = 'SET_DEFLEET_COMPONENT_FIELD_INPUT_VALUE'
export const GET_SELECTED_VEHICLE_DETAILS = 'GET_SELECTED_VEHICLE_DETAILS'
export const SET_SELECTED_VEHICLE_DETAILS = 'SET_SELECTED_VEHICLE_DETAILS'
export const GET_DEFLEET_LOCATION = 'GET_DEFLEET_LOCATION'
export const SET_DEFLEET_LOCATION = 'SET_DEFLEET_LOCATION'
export const RESET_DEFLEET_UPLOADCHECKSHEET_STATE = 'RESET_DEFLEET_UPLOADCHECKSHEET_STATE'
export const SET_DEFLEET_UPLOADCHECKSHEET_VALIDATION_VALUE = 'SET_DEFLEET_UPLOADCHECKSHEET_VALIDATION_VALUE'