import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'

import Card from '../../../controls/card'
import Checkbox from '../../../controls/checkbox'

import * as styles from './styles'
import * as actions from './actions'
import moment from 'moment';
import TextField from '@mui/material/TextField'
import Upload from '../../Upload/component'
import Dropzone from 'react-dropzone'


import Button from '@mui/material/Button'
import ReactTable from "react-table"
import Icon from '../../Icon/component'

import { green, red } from '@mui/material/colors'


class BillingRunPrep extends Component {
    componentDidMount() {
        this.props.actions.reset()
    }

    getForm() {
        return <div>
            <Card title={window.t.en('Billing Prep')}
                subtitle={window.t.en('Upload Billing Prep File')}
                content={
                    <Container style={{ margin: 0 }} className="custom-container">
                         {/* <Row>
                            <Col xl={12}>
                                <Checkbox
                                    id={'txt_dummyrun'}
                                    key={'txt_dummyrun'}
                                    name={'txt_dummyrun'}
                                    field={'dummyrun'}
                                    checked={this.props.state.dummyrun}
                                    labelPlacement={'end'}
                                    label={window.t.en('Weekly Dummy Billing?')}
                                    onCheck={(checked) => {
                                        this.props.actions.setValue('dummyrun', checked)
                                    }}
                                />
                            </Col>
                        </Row>
                       {
                            this.props.state.dummyrun
                                ?
                                <Row>
                                    <Col xl={12}> <div style={styles.redbox2}>
                                        <h2>{'Weekly Dummy Billing'}</h2>
                                        <div style={styles.redboxtext}>
                                            {'Please Note that the following steps will be skipped for Weekly Dummy Biilling: '}<br /><br />
                                            {'1. ODO File Import'} <br />
                                            {'2. OOCP File Import'} <br />
                                        </div>
                                    </div>

                                    </Col>
                                </Row> : ''
                        }
                        {
                            !this.props.state.dummyrun
                                ?
                                <Row>
                                    <Col xl={12}> <div style={styles.redbox}>
                                        <h2>{'Final Billing'}</h2>
                                        <div style={styles.redboxtext}>
                                            {'Please note that this will initiate a complete billing process '}<br /><br />
                                            <Checkbox
                                                id={'txt_finalbilling'}
                                                key={'txt_finalbilling'}
                                                name={'txt_finalbilling'}
                                                field={'finalbilling'}
                                                checked={this.props.state.finalbilling}
                                                labelPlacement={'end'}
                                                label={window.t.en('Please confirm that you are Initiating the Final Monthly Billing')}
                                                onCheck={(checked) => {
                                                    this.props.actions.setValue('finalbilling', checked)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    </Col>
                                </Row> : ''
                        } */}
                        <Row>
                            <Col xl={12}>
                                <div>
                                    <h4>Billing preparation file:</h4>
                                    <ul>
                                        <li>Make sure its an excel file</li>
                                        <li>Make sure file si correct before continue</li>
                                    </ul>
                                    <i>This file is not for processing but it will be used validate billing.</i>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                {this.uploadFile()}
                            </Col>
                        </Row>
                    </Container>
                } />
        </div>
    }
    
    uploadFile() {
        return  <Row>
            <Col xl={12}>
                <br/>
                <Upload
                    id={'upload_file'}
                    prefix={'Billing_prep_upload_' + moment().format("YYYYMMDDmmss")}
                    header={false}
                    list={[{ value: 0, text: 'Billing Prep File' },{ value: 0, text: 'Odometer File' },{ value: 0, text: 'OOCP File' },{ value: 0, text: 'Other File' }]}
                />
            </Col>
        </Row>
    }

    commentsView() {
        return <div>
                <Card 
                    title={window.t.en('File Upload Comments')}
                    subtitle={window.t.en('Capture comments')}
                    content={<Container style={{ margin: 20 }} className="custom-container">
                        <Row>
                            <Col xl={12} style={{ marginTop: -25 }} >
                                <TextField
                                    id={'txtcomment'}
                                    key={'txtcomment'}
                                    label={window.t.en('Enter Comments...')}
                                    fullWidth={true}
                                    variant="outlined"
                                    value={this.props.state.comments}
                                    onChange={
                                        (event) => {
                                            this.props.actions.setValue('comments', event.target.value)
                                        }
                                    }
                                /></Col>
                        </Row>
                    </Container>
                } 
            />
        </div>
    }

    render() {
        return ([
            this.getForm(),
            this.commentsView()
        ])
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: {
            ...state.billinrunstart
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingRunPrep)